/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header25.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  height: 70%;
  display: flex;
  justify-content: center;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
  }
`

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0 0 80px 0;
  flex-direction: column;
  min-height: ${({ isSocials }) => (isSocials ? '330px' : 'auto')};
  position: relative;
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '0 0 80px 0' : '0 0 60px 0')};
  }
  & ${S.Container} {
    margin-bottom: 0;
  }
  .socials-block {
    display: inline-block;
    position: absolute;
    right: 30px;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      width: 90%;
      right: unset;
      transform: unset;
      position: initial;
      padding: 0 20px;
    }
  }
  .socials-wrapper {
    display: inline-flex;
    flex-direction: column;
    @media only screen and (max-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: 50px 0 0 0;
    }
    @media only screen and (max-width: 610px) {
      width: 100%;
    }
    .link {
      width: 36px;
      height: 36px;
      fill: ${getStyleForProp('socialColor')};
      border-radius: ${getStyleForProp('iconRadius')};
      border: 1px solid ${getStyleForProp('socialBorder')};
    }
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 1000px;
  text-align: center;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1210px) and (min-width: 1025px) {
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }
  @media only screen and (min-width: 1025px) {
    max-width: 1000px;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    margin-bottom: 30px;
    h1,
    h2,
    p {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const SubTitle = styled.h2`
  color: ${getStyleForProp('subTitle')};
  ${getTextStyleByAlignment};
  font-weight: 400;
  padding-top: 10px;
`

const Description = styled.p`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  max-width: 1000px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 30px 0;
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('socialColor')
}))``

export default {
  WmCustomContainer,
  StyledContainer,
  HeaderContent,
  Title,
  SubTitle,
  Description,
  Icon
}
