export const validation = {
  headingMaxChar: 50,
  paragraphMaxChar: 200,
  blockTitleMaxChar: 30,
  blockParagraphMaxChar: 100,
  blocks: {
    min: 0,
    max: 6
  },
  slides: {
    min: 1,
    max: 10
  }
}

export const slickSliderSettings = {
  dots: true,
  arrows: false,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1
}
