/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_BUTTON_DATA':
        {
          const { link, text } = action.value.btnData
          draft.btnUrl = link
          draft.btnText = text
        }
        break
      case 'REMOVE_BUTTON':
        draft.btnUrl = ''
        draft.btnText = ''
        break
      case 'ADD_BUTTON':
        draft.btnText = initialState.btnText
        draft.btnUrl = initialState.btnUrl
        break
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions
        draft.imgCropParams = {}
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_FOOTER_TITLE':
        draft.footerTitle = action.value
        break
      case 'CHANGE_FOOTER_TITLE_ALIGNMENT':
        draft.footerTitleAlignment = action.value
        break
      case 'CHANGE_FOOTER_PARAGRAPH':
        draft.footerParagraph = action.value
        break
      case 'CHANGE_FOOTER_PARAGRAPH_ALIGNMENT':
        draft.footerParagraphAlignment = action.value
        break
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'ADD_LINK': {
        draft.additionalUrl = initialState.additionalUrl
        draft.additionalText = initialState.additionalText
        break
      }
      case 'REMOVE_LINK': {
        draft.additionalUrl = ''
        draft.additionalText = ''
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break
    }
  })
}
