export const validation = {
  headingMaxChar: 200,
  paragraphMaxChar: 600,
  stepParagraphMaxChar: 500,
  stepInfoMaxChar: 100,
  steps: {
    min: 0,
    max: 12
  }
}

export const SINGLE_BUTTON = {
  buttonUrl: 'http://builder.renderforestsites.com',
  buttonText: 'LEARN MORE'
}
