import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    background: p => p.tertiaryDark,
    icon: p => `rgba(${hexToRgb(p.primaryLight)}, 1)`,
    blockTitle: p => `rgba(${hexToRgb(p.primaryLight)}, 1)`,
    blockParagraph: p => `rgba(${hexToRgb(p.accentWhite)}, 1)`,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    background: p => p.tertiaryLight,
    icon: p => `rgba(${hexToRgb(p.primaryDark)}, 1)`,
    blockTitle: p => `rgba(${hexToRgb(p.primaryDark)}, 1)`,
    blockParagraph: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    background: p => p.tertiaryExtraLight,
    icon: p => `rgba(${hexToRgb(p.primaryDark)}, 1)`,
    blockTitle: p => `rgba(${hexToRgb(p.primaryDark)}, 1)`,
    blockParagraph: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
