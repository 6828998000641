import { projectContainerClassName } from '@website/conf/consts'

const BUTTON_HEIGHT = 44

export const getFlyoutCoords = buttonEl => {
  const renderingContainer = document.querySelector(
    `.${projectContainerClassName}`
  )

  if (!buttonEl || !renderingContainer) {
    return {}
  }

  const { top: buttonTop, left: buttonLeft } = buttonEl.getBoundingClientRect()
  const { top: renderingContainerTop, left: renderingContainerLeft } =
    renderingContainer.getBoundingClientRect()

  const left = buttonLeft - renderingContainerLeft
  const top =
    renderingContainer.scrollTop +
    buttonTop -
    renderingContainerTop +
    BUTTON_HEIGHT

  return { top, left }
}
