import React from 'react'
import S from './Clients4.styled'

export const getSlidesToShow = (slidesLength, breakpoint) => {
  const getters = {
    default: () => (slidesLength === 2 ? 2 : Math.min(5, slidesLength - 1)),
    1250: () => (slidesLength === 2 ? 2 : Math.min(3, slidesLength - 1)),
    768: () => 2,
    550: () => 1
  }
  const getter = getters[breakpoint] || getters.default

  if (slidesLength === 1) {
    return 1
  }
  return getter(slidesLength, breakpoint)
}

export const getSliderSettings = (isEditing, slidesLength) => ({
  dots: false,
  autoplay: !isEditing,
  accessibility: !isEditing,
  speed: 500,
  slidesToScroll: 1,
  infinite: slidesLength > 1,
  slidesToShow: getSlidesToShow(slidesLength),
  responsive: [
    {
      breakpoint: 1250,
      settings: {
        slidesToShow: getSlidesToShow(slidesLength, 1250)
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: getSlidesToShow(slidesLength, 768)
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: getSlidesToShow(slidesLength, 550)
      }
    }
  ],

  prevArrow: slidesLength > 1 && (
    <div className="arrowButton">
      <S.PrevIcons />
    </div>
  ),
  nextArrow: slidesLength > 1 && (
    <div className="arrowButton">
      <S.NextIcons />
    </div>
  ),
  swipeToSlide: !isEditing && slidesLength > 1,
  swipe: !isEditing && slidesLength > 1,
  className: 'slider mySlider'
})
