/* @flow */
import { socialActionMaker } from '@website/components/ActionMakers'
import { addSocial } from '@website/components/CommonActions'

export default dispatcher => {
  return {
    addSocial,
    getSocialActions: socialActionMaker(dispatcher)
  }
}
