/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Text15.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-top: ${({ isEditing }) => isEditing && '120px'};
  @media only screen and (max-width: 1024px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  .control-container.hovered {
    box-shadow: 0px 0px 0px 1px #363f5a;
  }
  .rf-add-btn {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            color: #363f5a;
            border-color: #363f5a;
          `
        : css``}
    @media only screen and (max-width: 580px) {
      padding: 14px 20px;
      box-sizing: border-box;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  width: 90%;
  justify-content: center;
  -webkit-filter: drop-shadow(0px 3px 10px rgba(41, 55, 97, 0.2));
  filter: drop-shadow(0px 3px 10px rgba(41, 55, 97, 0.2));
  border-top-right-radius: ${getStyleForProp('borderRadius')};
  border-bottom-right-radius: ${getStyleForProp('borderRadius')};
  align-items: center;
  background: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/text15-1.2.0.svg')
    repeat-y -1px top/cover;
  padding-top: 0;
  @media only screen and (max-width: 1024px) {
    padding-right: 30px;
    padding-left: 60px;
    width: 100%;
  }
  @media only screen and (max-width: 650px) {
    background: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/text15-1.4.0.svg')
      repeat-y -1px top/cover;
    padding-right: 20px;
    padding-left: 40px;
  }
`

const ContentWrapper = styled.div`
  width: 90%;
  max-width: 1160px;
  margin: 40px auto;
  display: flex;
  align-items: center;
  ${({ theme }) =>
    css`
      flex-direction: ${theme.orientation === 'RIGHT' ? 'row-reverse' : 'row'};
    `}

  @media only screen and (max-width: 1024px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
    width: 100%;
    box-sizing: border-box;
    margin: 50px auto 40px auto;
  }
  @media only screen and (max-width: 650px) {
    width: 90%;
    margin: 40px auto;
  }
`

const TextImageContent = styled.div`
  width: 40%;
  max-width: 380px;
  height: 400px;
  position: relative;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'LEFT' ? '0 40px 0 0' : '0 0 0 40px'};
    `}
  .styled-controls {
    z-index: 5;
  }
  .image-container {
    position: absolute;
    width: 53%;
    position: absolute;
    background-color: white;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 440px;
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT'
          ? '50px auto auto auto'
          : 'auto auto 50px auto'};
      `}
    ${({ isEditing, isOneImage }) =>
      css`
        height: ${!isEditing && isOneImage ? '400px' : '440px'};
      `}
  }
  @media only screen and (max-width: 650px) {
    max-width: 380px;
    height: auto;
    ${({ isEditing, isOneImage }) =>
      css`
        padding-top: ${!isEditing && isOneImage ? '88%' : '100%'};
      `}
    ${({ theme, isEditing }) =>
      css`
        margin: ${theme.orientation === 'RIGHT' && !isEditing
          ? '20px 23px 0 3px'
          : theme.orientation === 'RIGHT' && isEditing
          ? '20px 23px 40px 3px'
          : 'auto 23px 40px 3px'};
      `}
  }
  .image-1 {
    z-index: 2;
    .styled-controls {
      left: auto;
      right: 0;
    }
  }
  .image-2 {
    z-index: 1;
  }
  .add-button-wrap {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    @media only screen and (max-width: 580px) {
      bottom: -38px;
    }
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 117%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 84%;
    transform: rotate(9deg);
    height: initial;
  }
  .lazy-figure {
    padding-top: 124.5%;
    height: initial;
    box-shadow: 0px 3px 9px rgba(41, 55, 97, 0.2);
    border-radius: ${getStyleForProp('borderRadius')};
    img {
      border-radius: ${getStyleForProp('borderRadius')};
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin: 0;
  ${getTextStyleByAlignment};
  &:hover {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            &.light-content:not(.warning-max):not(.warning-near) {
              box-shadow: 0px 0px 0px 1px #363f5a;
            }
          `
        : css``}
  }
  &:focus {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            &.light-content:not(.warning-max):not(.warning-near) {
              outline: 1px solid #363f5a;
            }
          `
        : css``}
  }
`

const Paragraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-top: 20px;
  ${getTextStyleByAlignment};
  &:hover {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            &.light-content:not(.warning-max):not(.warning-near) {
              box-shadow: 0px 0px 0px 1px #363f5a;
            }
          `
        : css``}
  }
  &:focus {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            &.light-content:not(.warning-max):not(.warning-near) {
              outline: 1px solid #363f5a;
            }
          `
        : css``}
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  ${({ isOneImage }) =>
    css`
      ${isOneImage
        ? css`
            &.image-1 {
              width: 73%;
              top: 2%;
              left: 50%;
              transform: translateX(-50%);
              .LazyLoad {
                transform: rotate(-9deg);
              }
            }
          `
        : css`
            &.image-2 {
              width: 65%;
              top: 0;
              left: 0;
              .LazyLoad {
                transform: rotate(-9deg);
              }
            }

            &.image-1 {
              width: 73%;
              top: 14%;
              right: 0;
              .LazyLoad {
                transform: rotate(9deg);
              }
            }
          `}
    `}
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const RightContentWrap = styled.div`
  width: 60%;
  max-width: 670px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    box-sizing: border-box;
  }
`

const ParagraphsWrap = styled.div`
  ${({ isBlocksExist }) =>
    isBlocksExist
      ? css`
          border-bottom: 1px solid ${getStyleForProp('border')};
          padding-bottom: 30px;
        `
      : css``}

  .control-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      margin-top: 0;
    }
  }
`

const BlockItemWrap = styled.div`
  height: 100%;
  display: flex;
  margin: auto;
  align-items: flex-start;
  box-sizing: border-box;
  word-break: break-word;
  border-right: 1px solid ${getStyleForProp('border')};
  @media only screen and (max-width: 650px) {
    border-right: none;
    position: relative;
  }
`

const Block = styled.div`
  margin-top: 20px;
  @media only screen and (max-width: 490px) {
    position: relative;
    &:not(:last-child) {
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        height: 1px;
        width: 84px;
        background-color: ${getStyleForProp('blockParagraph')};
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
`

const BlockWrap = styled.div`
  display: flex;
  margin-left: 15px;
  max-width: 125px;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  padding-right: 15px;
  box-sizing: border-box;
  transform: translateX(-1px);
  z-index: 1;
  @media only screen and (max-width: 650px) {
    padding-bottom: 20px;
    min-width: 125px;
    border-bottom: 1px solid ${getStyleForProp('border')};
    margin-left: 15px;
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  max-width: 1470px;
  flex-wrap: wrap;
  @media only screen and (max-width: 650px) {
    width: min-content;
    flex-direction: column;
    align-items: flex-end;
  }
  ${Block} {
    &:last-child {
      ${BlockItemWrap} {
        border-right: none;
      }
      ${BlockWrap} {
        border-bottom: none;
      }
    }
  }
  @media only screen and (max-width: 580px) {
    > div {
      > .rf-add-btn {
        width: 134px;
      }
    }
  }
`

const BlockTitle = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-weight: 500;
  margin-bottom: ${({ isEditing }) =>
    isEditing ? 'calc(0.3rem + 6px)' : '0.3rem'};
  &:after {
    left: 0;
  }
  &:hover {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            &.light-content:not(.warning-max):not(.warning-near) {
              box-shadow: 0px 0px 0px 1px #363f5a;
            }
          `
        : css``}
  }
  &:focus {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            &.light-content:not(.warning-max):not(.warning-near) {
              outline: 1px solid #363f5a;
            }
          `
        : css``}
  }
`

const BlockParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-weight: 700;
  &:hover {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            &.light-content:not(.warning-max):not(.warning-near) {
              box-shadow: 0px 0px 0px 1px #363f5a;
            }
          `
        : css``}
  }
  &:focus {
    ${({ theme }) =>
      theme.colorMode === 'DARK'
        ? css`
            &.light-content:not(.warning-max):not(.warning-near) {
              outline: 1px solid #363f5a;
            }
          `
        : css``}
  }
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  > div {
    width: 38px;
    height: 38px;
    svg {
      max-width: 38px;
      max-height: 38px;
      fill: ${getStyleForProp('icon')};
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  ${({ isIconOnly }) =>
    isIconOnly
      ? css`
          width: 68px;
          align-items: center;
        `
      : css`
          width: 53px;
          align-items: flex-end;
        `}
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`

export default {
  StyledContainer,
  WmCustomContainer,
  ContentWrapper,
  Title,
  Paragraph,
  TextImageContent,
  ImageWrapper,
  Img,
  ControlContainer,
  ParagraphsWrap,
  RightContentWrap,
  BlocksWrapper,
  Block,
  BlockItemWrap,
  BlockTitle,
  BlockParagraph,
  IconContainer,
  IconWrap,
  BlockWrap
}
