/* @flow */
import React from 'react'
import styled from 'styled-components'
import LinkWrapper from '../LinkWrapper'
import AppStoreBadge from './app_store.svg'
import GoolgePlayBadge from './google_play.svg'

import './index.sass'
const Anchor = styled.a``

const badgeNames = {
  apple: AppStoreBadge,
  google: GoolgePlayBadge
}

class StoreBadge extends React.Component {
  render() {
    const { badgeName, url } = this.props
    return (
      <LinkWrapper as={Anchor} url={url}>
        <div className="store-badge">
          <img src={badgeNames[badgeName]} alt="Badge " />
        </div>
      </LinkWrapper>
    )
  }
}

export default StoreBadge
