/* @flow */
import React, {
  useContext,
  useRef,
  useEffect,
  memo
} from 'react'
import S from './Feature38.styled'
import { validation } from './Feature38.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { useResponsive } from '@shared/hooks'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'
import Buttons from '@website/common/components/Buttons'

const Feature38 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      subTitle,
      subTitleAlignment,
      buttons,
      images,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      paragraph1,
      paragraph1Alignment
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const imagesCount = images.length
  const isOnlyLeft =
    !subTitle && !paragraph && !paragraph1 && buttons.length === 0
  const imageWrapperRef = useRef(null)
  const imageRef = useRef(null)
  const containerRef = useRef(null)
  const imagesContainerRef = useRef(null)
  const isMobile = useResponsive(600)


  useEffect(() => {
    const scrollCallback = () => {
      const { current: imageEl } = imageRef
      const { current: containerEl } = containerRef
      const { current: imagesContainerEl } = imagesContainerRef

      if (!imageEl || !containerEl) {
        return
      }

      const windowHeight = window.innerHeight
      const containerWidth = containerEl.offsetWidth
      const imagesContainerWidth = imagesContainerEl.offsetWidth
      const imageWidth = imageEl.offsetWidth
      const containerTop = containerEl.getBoundingClientRect().top

      if (containerTop > windowHeight || containerTop < -windowHeight || containerTop > 60 || isMobile) {
        return
      }

      const transformPercent = images.length === 1 ? (60 - containerTop) * containerWidth / 5000 : ((60 - containerTop) * imagesContainerWidth) * 0.12/ imageWidth

      requestAnimationFrame(() => {
        imageEl.style.transform = `translateY(${transformPercent}px)`
      })
    }

    const throttledScrollCallback = () => {
      if (!throttledScrollCallback.timeout) {
        throttledScrollCallback.timeout = requestAnimationFrame(() => {
          scrollCallback()
          throttledScrollCallback.timeout = null
        })
      }
    }

    document.addEventListener('scroll', throttledScrollCallback)

    return () => {
      document.removeEventListener('scroll', throttledScrollCallback)
    }
  }, [imageRef, containerRef, imageWrapperRef])


  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer
        isOnlyLeft={isOnlyLeft}
        isEditing={isEditing}
        imagesCount={imagesCount}
      >
        <S.ContentWrap isEditing={isEditing}>
          <EditableContent
            as={S.Title}
            text={title}
            alignment={titleAlignment}
            required={!paragraph}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading54"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <Show when={[!isOnlyLeft]}>
            <S.ContentBox isEditing={isEditing}>
              <EditableContent
                as={S.Paragraph}
                text={paragraph}
                required={!title}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <EditableContent
                as={S.SubTitle}
                text={subTitle}
                maxCount={validation.subTitleMaxChar}
                className="WM_GLOBAL_heading32"
                onChange={Actions.changeSubTitle}
                alignment={subTitleAlignment}
                changeAlignment={Actions.changeSubtitleAlignment}
              />
              <EditableContent
                as={S.AdditionalParagraph}
                text={paragraph1}
                alignment={paragraph1Alignment}
                maxCount={validation.paragraph1MaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph1}
                changeAlignment={Actions.changeParagraph1Alignment}
              />
              <S.ButtonBlock>
                <Buttons data={buttons} />
              </S.ButtonBlock>
            </S.ContentBox>
          </Show>
        </S.ContentWrap>
        <S.TextImageContent isEditing={isEditing} ref={containerRef}>
          <S.ImagesWrap>
            <S.ImagesContainer
              isEditing={isEditing}
              imagesCount={imagesCount}
              ref={imagesContainerRef}
            >
              <Mapper
                data={images}
                render={(image, idx) => {
                  return (
                    <S.ImageWrapper
                      imagesCount={imagesCount}
                      ref={imageWrapperRef}
                      isEditing={isEditing}
                    >
                      <S.ControlContainer
                        className={`feature36image-${idx + 1}`}
                        ref={imageRef}
                      >
                        <ControlsWithImageCropper
                          actions={Actions.imageActions(
                            idx,
                            image.imageUrl,
                            imagesCount
                          )}
                          style={{ width: '100%' }}
                          key={idx}
                        >
                          <Image
                            as={S.Img}
                            sizes={image.imgDimensions}
                            defaultImgSrc={image.imageUrl}
                            asProps={{
                              imgCropParams: image.imgCropParams
                            }}
                            alt="CTA images"
                          />
                        </ControlsWithImageCropper>
                      </S.ControlContainer>
                    </S.ImageWrapper>
                  )
                }}
              />
            </S.ImagesContainer>
            <AddButton
              onAdd={() => Actions.addBlock(images.length)}
              toShow={imagesCount < validation.images.max}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_image_label')}
            </AddButton>
          </S.ImagesWrap>
        </S.TextImageContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature38
