import styled, { css } from 'styled-components'

import { scrollBarStyles } from '@editor/common/styles'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`

export const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #252e48;
  padding: 16px;
  flex-shrink: 0;
`

export const List = styled.div`
  ${scrollBarStyles};
  overflow-y: scroll;
  flex-grow: 1;
  padding-bottom: 30px;

  ${({ showShadow }) =>
    showShadow
      ? css`
          box-shadow: inset 0 5px 12px 0 #3271e61f;
        `
      : ''};
`

export const ListItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #545f7e;
  border-bottom: 1px solid #c1c9e0;
  padding: 15px 10px;
  margin: 0 11px 0 15px;

  & > p {
    max-width: 250px;
  }

  .copy-icon-tooltip {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
    svg {
      display: none;
    }
  }

  &:hover {
    background-color: #eef5ff;
    .copy-icon-tooltip {
      svg {
        fill: #8493bd;
        display: block;

        &:hover {
          fill: #545f7e;
        }
      }
    }
  }
`

export const MoreDetails = styled.div`
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  box-shadow: 0 -3px 12px 0 #0052e01a;
`

export const MoreDetailsButton = styled.button`
  background-color: #ffffff;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: #3271e6;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
`

export const LoaderWrapper = styled.div`
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);

  ${({ isLoading }) =>
    isLoading
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`

export const NoResults = styled.div`
  font-size: 18px;
  color: #252e48;
  font-weight: 700;
  padding: 100px 20px 0;
  text-align: center;
`

export const TooltipContent = styled.p`
  width: 50px;
  text-align: center;
`
