/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const addSlide = () => dispatcher({ type: 'ADD_SLIDE' })

  const changeSlideTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeSlideContent = (newContent: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_CONTENT',
      value: { newContent, idx }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addSlide,
    changeTitle: _changeTitle,
    changeSlideTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeParagraph: _changeParagraph,
    changeSlideContent,
    slideActions: cropableImageModalActionMaker(dispatcher)
  }
}
