// @flow
import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import LibPopup from '@renderforest/rf-ui-library/organism/Popup'
import { GlobalStyles } from './styled'

import { selectSemrushSidebarVisibilityState } from '@selectors'

const SemrushDependedPopup = ({ className, ...props }) => {
  const isSemrushSidebarVisible = useSelector(
    selectSemrushSidebarVisibilityState
  )

  const newClassName = isSemrushSidebarVisible
    ? `${className} semrush-sidebar-adapted`
    : className
  return (
    <>
      <GlobalStyles />
      <LibPopup {...props} className={newClassName} />
    </>
  )
}

export default memo(SemrushDependedPopup)
