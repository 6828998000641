// @flow

import React, { memo, useContext } from 'react'
import { ThemeProvider } from 'styled-components'

import * as Styled from './styled'
import Components from '@website/components'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { MobileDetectContext } from '@contexts'
import { useSelector } from 'react-redux'
import { selectEventsData } from '@selectors'

const ComponentsDropdownItem = ({ label, component }) => {
  const isMobile = useContext(MobileDetectContext)
  const eventsData = useSelector(selectEventsData)
  const Comp = Components[component.id]
  const { data = {} } = component

  const { eventId } = component

  const eventData = eventId
    ? eventsData.find(event => event.id === eventId)
    : eventsData[0]

  return (
    <ThemeProvider
      theme={{
        colorMode: component.colorMode,
        orientation: component.orientation
      }}
    >
      <>
        <Styled.GlobalStyles />
        <TooltipWrapper
          text={!isMobile ? 'text' : ''}
          position="left"
          offsets={{ x: 10 }}
          className="components-dropdown-tooltip"
          content={
            !isMobile ? (
              <Styled.Wrapper>
                <Styled.ComponentPreview>
                  <Comp data={data} eventData={eventData} />
                </Styled.ComponentPreview>
              </Styled.Wrapper>
            ) : null
          }
        >
          {label}
        </TooltipWrapper>
      </>
    </ThemeProvider>
  )
}

export default memo(ComponentsDropdownItem)
