import { hexToRgb } from '@website/common/utils'

export default {
  DARK: {
    paragraph: p => p.accentWhite,
    hamburger: p => p.accentWhite,
    menuItemColor: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    borderColor: p => `rgba(${hexToRgb(p.tertiaryNormal)}, 0.2)`
  },
  LIGHT: {
    paragraph: p => p.tertiaryDark,
    hamburger: p => p.tertiaryDark,
    menuItemColor: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryLight,
    borderColor: p => `rgba(${hexToRgb(p.tertiaryNormal)}, 0.2)`
  },
  EXTRALIGHT: {
    paragraph: p => p.tertiaryDark,
    hamburger: p => p.tertiaryDark,
    menuItemColor: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryExtraLight,
    borderColor: p => `rgba(${hexToRgb(p.tertiaryNormal)}, 0.2)`
  }
}
