/* @flow */
import React from 'react'

import * as Styled from './styled'

const RippleLoading = ({
  className = '',
  color = '#3271E6',
  text,
  isSmall
}) => (
  <Styled.RippleLoaderContainer className={className}>
    <Styled.Ellipsis color={color} isSmall={isSmall}>
      <div />
      <div />
      <div />
      <div />
    </Styled.Ellipsis>
    {text && <span style={{ paddingTop: 10 }}>{text}</span>}
  </Styled.RippleLoaderContainer>
)

export default RippleLoading
