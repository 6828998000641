import styled, { css } from 'styled-components'

export const Controls = styled.div`
  display: flex;
  padding-left: 2px;

  & > div:not(:last-child) {
    margin-right: 6px;
  }

  @media only screen and (max-width: 768px) {
    padding: 25px 0;
    justify-content: center;
    align-items: center;

    & > div:not(:last-child) {
      margin-right: 18px;
    }
  }
`

export const Control = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 5px;
  border: 1px solid #c1c9e0;
  box-sizing: border-box;
  transition: all 100ms ease;
  cursor: pointer;

  ${({ isSelected }) =>
    isSelected
      ? css`
          box-shadow: 0 0 0 1px #3271e6;
        `
      : css`
          &:hover {
            box-shadow: 0 0 0 1px #3271e6;
          }
        `};

  @media only screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  &:hover {
    box-shadow: 0 0 0 1px #3271e6;
  }
`
