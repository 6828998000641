/* @flow */
import {
  imageModalActionMaker,
  simpleButtonActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeSupTitle = (newSupTitle: string) => {
    dispatcher({
      type: 'CHANGE_SUPTITLE',
      value: newSupTitle
    })
  }

  const changeSupTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUPTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const addPlayButton = () => {
    dispatcher({ type: 'ADD_PLAY_BUTTON' })
  }

  const removePlayButton = () => ({ type: 'REMOVE_PLAY_BUTTON' })

  const playBtnActions = text => [
    ...simpleButtonActionMaker(dispatcher)(text),
    {
      toShow: true,
      handler: () => dispatcher(removePlayButton()),
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label')
    }
  ]

  return {
    imageActions: imageModalActionMaker(dispatcher),
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSupTitle,
    changeSupTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addPlayButton,
    playBtnActions
  }
}
