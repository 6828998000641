/* @flow */
import {
  linkActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockParagraph = (paragraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { paragraph, idx }
    })
  }
  const changeBlockTitle = (title: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { title, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitle,
    changeBlockParagraph,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    imageActions: (idx: number, imageUrl: string) =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'post2',
        additionalArgs: { idx },
        showRemove: false,
        imageUrl
      })
  }
}
