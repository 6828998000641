// @flow
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'

import type { TMainControlsProps } from './types'
import MainControlsDesktop from './MainControlsDesktop'
import MainControlsMobile from './MainControlsMobile'
import CompRemovePopup from './CompRemovePopup'
import { scrollToDuplicatedComp } from './utils'
import { useHistoryWithSearch } from '@hooks'
import { COMPONENT_CATEGORIES } from '@editor/conf/consts'
import { DispatchContext, MobileDetectContext } from '@contexts'
import {
  getAllPages,
  getCurrentPageComponents,
  selectIsWebsiteMultilingual
} from '@selectors'
import ProjectActions from '@actions/project/project'
import {
  removeComponent,
  duplicateComponent,
  removePage
} from '@actions/project'
import { EDITOR_BASE, PAGE_BASE } from '@editor/conf/routes'

const MainControls = ({
  idx,
  category,
  isUpMovementAllowed,
  isDownMovementAllowed,
  isOrientationAllowed,
  isDuplicateAllowed,
  isDuplicateDisabled,
  isComponentAnimated,
  isAnimationEnabled,
  isWebsiteMultilingual,
  projectPages,
  pageComponents,
  removePage,
  removeComponent,
  duplicateComponent
}: TMainControlsProps) => {
  const [isSlideUpOpen, setSlideUpOpenState] = useState(false)
  const [isRemovePopupOpen, setRemovePopupState] = useState(false)

  const isMobile = useContext(MobileDetectContext)
  const componentDispatcher = useContext(DispatchContext)
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(EDITOR_BASE)
  const {
    params: { page }
  } = useRouteMatch(PAGE_BASE)

  const _page = `/${page}`
  const pagePath = page === 'home' ? '/' : `/${page}`
  const isHomePage = _page === '/home'
  const isHeader = category === COMPONENT_CATEGORIES.HEADER

  const isRemoveBtnVisible = useMemo(() => {
    const compCount = pageComponents.length

    if (isWebsiteMultilingual && isHeader) {
      return false
    }

    if (isHomePage && isHeader) {
      return projectPages.length === 1 && compCount === 1
    }

    if (isHeader) {
      return compCount === 1
    }

    return true
  }, [
    pageComponents,
    projectPages,
    isHomePage,
    isHeader,
    isWebsiteMultilingual
  ])

  const closeSlideUp = useCallback(() => {
    setSlideUpOpenState(false)
  }, [])

  const onOrientationChange = useCallback(() => {
    componentDispatcher(ProjectActions.changeOrientation())
    isMobile && closeSlideUp()
  }, [isMobile, componentDispatcher])

  const onAnimationChange = useCallback(() => {
    componentDispatcher(ProjectActions.setComponentAnimatedState())
    isMobile && closeSlideUp()
  }, [isMobile, componentDispatcher])

  const onReplace = useCallback(() => {
    history.push({
      pathname: `${url}/edit/${page}/replace-component/${idx}`,
      activeCompCategory: category
    })
    isMobile && closeSlideUp()
  }, [history, page, idx, isMobile, url])

  const _removeComponent = useCallback(() => {
    const isLastComp = idx === 0

    removeComponent(idx, pagePath)

    if (isLastComp && !isHomePage) {
      removePage(_page)
      history.replace(`${url}/edit/home`)
    }
  }, [idx, url, isHomePage, pagePath, _page, removeComponent, removePage])

  const onRemove = useCallback(() => {
    isMobile && closeSlideUp()

    if (isHeader && !isHomePage) {
      setRemovePopupState(true)
      return
    }

    _removeComponent()
  }, [isHeader, isHomePage, isMobile, _removeComponent])

  const onDuplicate = useCallback(() => {
    duplicateComponent(pagePath, idx)
    setTimeout(() => {
      scrollToDuplicatedComp(idx + 1)
    }, 100)
    isMobile && closeSlideUp()
  }, [pagePath, idx, isMobile, duplicateComponent])

  const commonProps = {
    isOrientationAllowed,
    isDuplicateAllowed,
    isRemoveBtnVisible,
    isComponentAnimated,
    isAnimationEnabled,
    onReplace,
    onRemove,
    onDuplicate,
    onOrientationChange,
    onAnimationChange
  }

  return (
    <>
      {isMobile ? (
        <MainControlsMobile
          isSlideUpOpen={isSlideUpOpen}
          setSlideUpOpenState={setSlideUpOpenState}
          {...commonProps}
        />
      ) : (
        <MainControlsDesktop
          idx={idx}
          isUpMovementAllowed={isUpMovementAllowed}
          isDownMovementAllowed={isDownMovementAllowed}
          isDuplicateDisabled={isDuplicateDisabled}
          {...commonProps}
        />
      )}
      <CompRemovePopup
        idx={idx}
        isOpen={isRemovePopupOpen}
        setRemovePopupState={setRemovePopupState}
        removeComponent={_removeComponent}
      />
    </>
  )
}

const mapStateToProps = state => ({
  projectPages: getAllPages(state),
  pageComponents: getCurrentPageComponents(state),
  isWebsiteMultilingual: selectIsWebsiteMultilingual(state)
})

const mapDispatchToProps = {
  removePage,
  removeComponent,
  duplicateComponent
}

export default connect(mapStateToProps, mapDispatchToProps)(MainControls)
