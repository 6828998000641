export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 300,
  tabTextMaxChar: 30,
  features: {
    min: 1,
    max: 3,
    title: { required: true }
  }
}
