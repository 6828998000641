export const validation = {
  headingMaxChar: 70,
  subTitleMaxChar: 30,
  paragraphMaxChar: 200,
  title: { required: true },
  images: {
    min: 1,
    max: 3
  },
  buttons: {
    min: 0,
    max: 2
  },
  headerSocials: {
    min: 0,
    max: 6
  }
}
