/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.videos[idx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.videos[idx].titleAlignment = alignment
        break
      }
      case 'ADD_BLOCK':
        draft.videos.push(initialState.videos[0])
        break
      case 'REMOVE_VIDEO':
        const { idx } = action.value
        draft.videos.splice(idx, 1)
        break
      case 'CHANGE_VIDEO_URL': {
        const { url, idx } = action.value
        draft.videos[idx].url = url
        break
      }
    }
  })
}
