/* @flow */
import styled, { css } from 'styled-components'

import THEME from './CTA1.theme'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  background-attachment: fixed !important;
  @media only screen and (min-width: 769px) {
    padding-top: ${({ isEditing }) => isEditing && '150px'};
  }
  @media only screen and (max-width: 768px) {
    background-attachment: initial !important;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  .buttons-block {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1025px) {
    max-width: 900px;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
    margin-top: 30px;
    h1,
    p {
      margin-bottom: 0;
      margin-top: 0;
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 15px;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
  ${({ isTextGradient }) =>
    isTextGradient
      ? css`
          background: linear-gradient(
            90deg,
            ${getStyleForProp('gradientColorFrom')} 0,
            ${getStyleForProp('gradientColorTo')} 100%
          );
          background-clip: text;
          background-size: 100% auto;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:before,
          &:after {
            -webkit-background-clip: initial;
            -webkit-text-fill-color: initial;
            background: initial;
          }
          & .link-wrapper {
            position: initial;
          }
        `
      : ''}
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  margin-right: auto;
  margin-left: auto;
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

export default {
  StyledContainer,
  WmCustomContainer,
  ContentWrapper,
  Title,
  Description
}
