/* @flow */
import produce from 'immer'
import { validation } from './Pricing6_2.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        break
      case 'REMOVE_BLOCK':
        draft.slides.splice(action.value.idx, 1)
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.slides[idx].imageUrl = url
        draft.slides[idx].imgDimensions = dimensions || {}
        draft.slides[idx].imgCropParams = {}
        break
      }
      case 'REMOVE_MENU_ITEM':
        const { idx: idx__, activeIdx: activeIdx__ } = action.value
        if (state.slides[idx__].menu.length === validation.slides.menu.min) {
          break
        }
        draft.slides[idx__].menu.splice(activeIdx__, 1)
        break
      case 'ADD_MENU_ITEM':
        draft.slides[action.value].menu.push(initialState.slides[0].menu[0])
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_SLIDE_TITLE':
        const { newTitle, idx } = action.value
        draft.slides[idx].slideTitle = newTitle
        break
      case 'CHANGE_ITEM_DATA':
        const { data, itemIdx, activeSlideIdx, keyInStore } = action.value
        draft.slides[activeSlideIdx].menu[itemIdx][keyInStore] = data
        break
      case 'CHANGE_BADGE_COLOR':
        const { slideIdx, menuIdx, color, badgeIdx } = action.value
        draft.slides[slideIdx].menu[menuIdx].badge[badgeIdx].color = color
        break
      case 'REMOVE_BADGE':
        const {
          slideIdx: slideIdx_,
          menuIdx: menuIdx_,
          badgeIdx: badgeIdx_
        } = action.value
        draft.slides[slideIdx_].menu[menuIdx_].badge.splice(badgeIdx_, 1)
        break
      case 'CHANGE_BADGE_NAME':
        const {
          slideIdx: slideIdx__,
          menuIdx: menuIdx__,
          badgeIdx: badgeIdx__,
          newName
        } = action.value
        draft.slides[slideIdx__].menu[menuIdx__].badge[badgeIdx__].name =
          newName
        break
      case 'ADD_BADGE_ITEM':
        const { slideIdx: slideIdx___, menuIdx: menuIdx___ } = action.value
        draft.slides[slideIdx___].menu[menuIdx___].badge.push(
          initialState.slides[0].menu[0].badge[0]
        )
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
        break
      }
      case 'CHANGE_SLIDE_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].slideTitleAlignment = alignment
        break
      }
    }
  })
}
