/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'ADD_SLIDE': {
        const activeIdx = action.value

        draft.slides = [
          ...draft.slides.slice(0, activeIdx + 1),
          initialState.slides[0],
          ...draft.slides.slice(activeIdx + 1)
        ]
        break
      }
      case 'REMOVE_SLIDE': {
        const { idx } = action.value
        draft.slides.splice(idx, 1)
        break
      }
      case 'CHANGE_SLIDER_TITLE': {
        const { idx, newTitle } = action.value
        draft.slides[idx].title = newTitle
        break
      }
      case 'CHANGE_SLIDER_PARAGRAPH': {
        const { idx, newParagraph } = action.value
        draft.slides[idx].paragraph = newParagraph
        break
      }
      case 'CHANGE_SLIDE_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].slideTitleAlignment = alignment
        break
      }
      case 'CHANGE_SLIDE_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].slideParagraphAlignment = alignment
        break
      }
    }
  })
}
