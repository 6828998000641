export const validation = {
  headingMaxChar: 50,
  paragraphMaxChar: 100,
  blockParagraphMaxChar: 100,
  socials: {
    min: 0,
    max: 6
  },
  blocks: {
    min: 0,
    max: 2
  },
  buttons: {
    min: 0,
    max: 2
  }
}

export const BUTTON_INITIAL_STATE = [
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]
