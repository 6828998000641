/* @flow */
import React, { useContext, useState, useEffect, useRef, memo } from 'react'
import S from './Header57.styled'
import THEME from './Header57.theme'
import { validation } from './Header57.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import If from '@website/common/components/Conditional'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import Image from '@website/common/components/Image'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import ControlsWithModalOpener from '@website/common/components/Controlled/ControlsWithModalOpener'
import { scrollBottom } from '@website/common/utils'

const Header57 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      footerTitle,
      footerTitleAlignment,
      footerParagraph,
      footerParagraphAlignment,
      imageUrl,
      imgDimensions,
      imgCropParams,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      additionalText,
      additionalUrl
    },
    hasNextComponent
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const imageRef = useRef(null)
  const containerRef = useRef(null)

  useEffect(() => {
    const scrollCallback = () => {
      const scrollY = window.scrollY
      const { current: imageEl } = imageRef
      const { current: containerEl } = containerRef
      if (!imageEl || !containerEl) {
        return
      }
      const containerHeight = containerEl.offsetHeight
      if (scrollY > containerHeight) {
        return
      }
      const scrollPercent = scrollY / containerHeight
      const newTransformY = scrollPercent * 22 - 22
      imageEl.style.transform = `translateY(${newTransformY}%)`
    }
    document.addEventListener('scroll', scrollCallback)
    return () => {
      document.removeEventListener('scroll', scrollCallback)
    }
  }, [imageRef, containerRef])

  return (
    <S.StyledContainer ref={containerRef} isEditing={isEditing}>
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight}>
        <S.HeaderContent>
          <S.TopContent>
            <S.TitleWrapper>
              <EditableContent
                text={title}
                isEditing={isEditing}
                alignment={titleAlignment}
                required={validation.title.required}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading96"
                as={S.Title}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </S.TitleWrapper>
              <Show when={[paragraph, additionalText]}>
                <S.Line hasParagraph={paragraph} />
              </Show> 
              <Show when={[paragraph, additionalText, hasNextComponent]}> 
                <S.RightContent hasContent={!!paragraph || !!additionalText} isEditing={isEditing}>
                  <EditableContent
                    text={paragraph}
                    as={S.Paragraph}
                    alignment={paragraphAlignment}
                    maxCount={validation.paragraphMaxChar}
                    className="WM_GLOBAL_paragraph"
                    onChange={Actions.changeParagraph}
                    changeAlignment={Actions.changeParagraphAlignment}
                  />
                  <AdditionalLink
                    additionalLinkText={additionalText}
                    additionalLinkUrl={additionalUrl}
                  />
                  <If
                    condition={hasNextComponent}
                    then={() => (
                      <S.BottomContent
                        isEditing={isEditing}
                        onClick={() => scrollBottom(isEditing)}
                      >
                        <S.DownButton role="button">
                          <S.ScrollIcon
                            size="xlarge"
                            name="glyph-arrow_downward"
                          />
                        </S.DownButton>
                      </S.BottomContent>
                    )}
                  />
                </S.RightContent>
              </Show>
          </S.TopContent>
          <S.ImageWrapper isEditing={isEditing}>
            <S.ControlContainer>
              <ControlsWithModalOpener
                 actions={Actions.getImageActions({
                  keyForSizes: 'header57',
                  showRemove: false,
                  imageUrl
                })}
                style={{ width: '100%' }}
                showCrop = {false}
              >
                <Image
                  className="parallax-image"
                  as={S.Img}
                  sizes={imgDimensions}
                  defaultImgSrc={imageUrl}
                  asProps={{ imgCropParams, ref: imageRef }}
                  alt="Hero illustration"
                />
              </ControlsWithModalOpener>
            </S.ControlContainer>
          </S.ImageWrapper>
          <Show when={[footerTitle, footerParagraph]}>
            <S.HeaderFooter>
              <S.HeaderFooterContainer>
                <EditableContent
                  as={S.FooterTitle}
                  text={footerTitle}
                  alignment={footerTitleAlignment}
                  maxCount={validation.footerParagraphMaxChar}
                  className="WM_GLOBAL_heading32"
                  onChange={Actions.changeFooterTitle}
                  changeAlignment={Actions.changeFooterTitleAlignment}
                />
                <EditableContent
                  as={S.FooterParagraph}
                  text={footerParagraph}
                  alignment={footerParagraphAlignment}
                  maxCount={validation.footerParagraphMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={Actions.changeFooterParagraph}
                  changeAlignment={Actions.changeFooterParagraphAlignment}
                />
              </S.HeaderFooterContainer>
            </S.HeaderFooter>
          </Show>
        </S.HeaderContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

Header57.contextType = DispatchContext

export default Header57
