/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature25.styled'
import MorphRight from './morphs/Feature25_morph_right'
import MorphLeft from './morphs/Feature25_morph_left'
import ActionMaker from './Actions'
import { validation } from './Feature25.consts'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const Feature25 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      buttons,
      blocks
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionMaker(dispatcher)
  return (
    <S.StyledContainer>
      <S.MorphLeftWrapper>
        <MorphLeft />
      </S.MorphLeftWrapper>
      <S.MorphRightWrapper>
        <MorphRight />
      </S.MorphRightWrapper>
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.titleMaxChar}
          onChange={Actions.changeTitle}
          className="WM_GLOBAL_heading42"
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Description}
          maxCount={validation.paragraphMaxChar}
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
          className="WM_GLOBAL_paragraph18"
        />
        <Buttons data={buttons} />
        <S.BlocksWrapper isFourBlock={blocks.length === 4 ? true : false}>
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => (
              <Show when={[block.title, block.paragraph]}>
                <S.Block key={idx} idx={idx} isEditing={isEditing}>
                  <ControlsWithModalOpener
                    actions={Actions.blockActions({
                      idx,
                      showRemove: blocks.length > validation.blocks.min
                    })}
                    style={{ height: '100%', width: '100%' }}
                  >
                    <S.BlockItemWrap>
                      <S.IconContainer>
                        <ControlsWithModalOpener
                          actions={Actions.getIconActions({
                            idx,
                            showRemove: false
                          })}
                          style={{ height: 'auto' }}
                        >
                          <Icon name={block.iconName} size="xlarge" className="block-icon"/>
                        </ControlsWithModalOpener>
                      </S.IconContainer>
                      <S.BlockWrap>
                        <EditableContent
                          as={S.BlockTitle}
                          text={block.title}
                          alignment={block.blockTitleAlignment}
                          maxCount={validation.blockTitleMaxChar}
                          required={!block.paragraph}
                          onChange={(newBlockTitle: string) =>
                            Actions.changeBlockTitle(newBlockTitle, idx)
                          }
                          className="WM_GLOBAL_heading20"
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(alignment, idx)
                          }
                        />
                        <EditableContent
                          as={S.BlockParagraph}
                          alignment={block.blockParagraphAlignment}
                          text={block.paragraph}
                          required={!block.title}
                          maxCount={validation.blockParagraphMaxChar}
                          onChange={(newBlockParagraph: string) =>
                            Actions.changeBlockParagraph(newBlockParagraph, idx)
                          }
                          className="WM_GLOBAL_paragraph14"
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                      </S.BlockWrap>
                    </S.BlockItemWrap>
                  </ControlsWithModalOpener>
                </S.Block>
              </Show>
            )}
          />
        </S.BlocksWrapper>
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addItem}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size={16} />
        {translate('add_item_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Feature25
