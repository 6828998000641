import React from 'react'
import * as Styled from './styled'

const SkeletonSecondSection = () => (
  <Styled.SecondSection>
    <Styled.LeftBlock>
      <Styled.ImageSkeleton />
    </Styled.LeftBlock>
    <Styled.RightBlock>
      <Styled.TextSkeleton className="first-text-skeleton" />
      <Styled.TextSkeleton className="second-text-skeleton" />
      <Styled.TextSkeleton className="third-text-skeleton" />
      <Styled.TextSkeleton className="fourth-text-skeleton" />
      <Styled.ButtonSkeleton />
    </Styled.RightBlock>
  </Styled.SecondSection>
)

export default SkeletonSecondSection
