// @flow

import React, { memo, useContext } from 'react'
import * as Styled from './styled'
import { MobileDetectContext } from '@contexts'
import type { TPopupProps } from './types'
import MobilePopup from './MobilePopup'

const Popup = ({
  title,
  isOpen,
  onClose,
  mobileProps,
  children,
  ...restPopupProps
}: TPopupProps) => {
  const isMobile = useContext(MobileDetectContext)

  return isMobile ? (
    <MobilePopup
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      {...mobileProps}
    >
      {children}
    </MobilePopup>
  ) : (
    <Styled.Popup isOpen={isOpen} onClose={onClose} {...restPopupProps}>
      {title ? (
        <Styled.PopupHeader>
          <h3>{title}</h3>
        </Styled.PopupHeader>
      ) : null}
      {children}
    </Styled.Popup>
  )
}

export default memo(Popup)
