/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_SUBTITLE_ALIGNMENT': {
        draft.subtitleAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_DATE_ALIGNMENT': {
        draft.dateAlignment = action.value
        break
      }
      case 'CHANGE_LOCATION_ALIGNMENT': {
        draft.locationAlignment = action.value
        break
      }
      case 'CHANGE_BACKGROUND_IMAGE': {
        const {
          backgroundTopImage,
          backgroundBottomImage,
          backgroundThumbnail
        } = action.value
        draft.backgroundTopImage = backgroundTopImage
        draft.backgroundBottomImage = backgroundBottomImage
        draft.backgroundThumbnail = backgroundThumbnail
        break
      }
    }
  })
}
