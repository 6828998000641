/* @flow */

export default dispatcher => {
  const changeSliderTitle = (newTitle: string, idx) => {
    dispatcher({
      type: 'CHANGE_SLIDER_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeSliderParagraph = (newParagraph: string, idx) => {
    dispatcher({
      type: 'CHANGE_SLIDER_PARAGRAPH',
      value: { newParagraph, idx }
    })
  }

  const changeSliderTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDER_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeSliderParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDER_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const addSlide = (activeIdx: number) => {
    dispatcher({
      type: 'ADD_SLIDE',
      value: activeIdx
    })
  }

  const removeSlide = idx => {
    dispatcher({
      type: 'REMOVE_SLIDE',
      value: { idx }
    })
  }

  return {
    changeSliderTitle,
    changeSliderParagraph,
    changeSliderTitleAlignment,
    changeSliderParagraphAlignment,
    addSlide,
    removeSlide
  }
}
