import { useReducer } from 'react'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'

const modalsInitialState = (() =>
  Object.keys(MODAL_TYPES).reduce(
    (state, key) => ({
      ...state,
      [MODAL_TYPES[key]]: { isOpen: false }
    }),
    {}
  ))()

const reducer = (state, action) => {
  const { modalType, modalState } = action.value
  switch (action.type) {
    case 'DROP_MODALS_DATA':
      return initialState
    case 'SET_MODAL_DATA':
      return {
        ...state,
        [modalType]: {
          ...(state[modalType] || {}),
          ...modalState
        }
      }
    default:
      return state
  }
}

export const useControlModals = (initialState = modalsInitialState) => {
  const [modalStates, dispatch] = useReducer(reducer, initialState)

  const setModalState = (modalType, modalState) => {
    dispatch({
      type: 'SET_MODAL_DATA',
      value: {
        modalType,
        modalState
      }
    })
  }

  return [modalStates, setModalState]
}
