//@flow
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
  memo,
  useEffect
} from 'react'

import * as Styled from './styled'
import ActionGetter from './Actions'
import { TLanguagesMenuCoreProps } from './types'
import { Controls } from '@website/common/components/Controlled'
import ArrowDownIcon from '@website/common/assets/svgr-icons/keyboard_arrow_down.svg'
import { EditingContext } from '@contexts'
import { getFlagUrl } from '@website/common/utils/website-languages'
import { useResponsive } from '@shared/hooks'

const LanguagesMenuCore = ({
  websiteLanguages,
  websiteActiveLanguageCode,
  onItemClick
}: TLanguagesMenuCoreProps) => {
  const [isMenuOpen, setOpenedState] = useState(false)
  const menuRef = useRef(null)
  const mobileMenuRef = useRef(null)

  const isSmallSizes = useResponsive(1024)
  const { isEditing } = useContext(EditingContext)
  const { data, languageMenuLayout = 'full' } = websiteLanguages

  useEffect(() => {
    if (!isSmallSizes) return

    if (isMenuOpen && mobileMenuRef.current) {
      setTimeout(() => {
        mobileMenuRef.current.scrollIntoView({ behavior: 'smooth' })
      }, 100)
    }
  }, [isSmallSizes, isMenuOpen, mobileMenuRef])

  const isOnlyText = languageMenuLayout === 'text'
  const isOnlyFlag = languageMenuLayout === 'flag'
  const isFull = languageMenuLayout === 'full'

  const toggle = useCallback(() => {
    setOpenedState(!isMenuOpen)
  }, [isMenuOpen])

  const closeMenu = useCallback(() => {
    setOpenedState(false)
  }, [])

  const _onItemClick = useCallback(
    languageCode => {
      onItemClick(languageCode)
      !isSmallSizes && closeMenu()
    },
    [isSmallSizes, onItemClick]
  )

  const activeLanguages = useMemo(
    () =>
      data.filter(
        language =>
          language.status === 'active' &&
          language.lang.code !== websiteActiveLanguageCode
      ),
    [data, websiteActiveLanguageCode]
  )

  const menuItems = useMemo(
    () =>
      activeLanguages.map(({ lang: { name, flagCode, code }, order }, idx) => {
        const isActive = code === websiteActiveLanguageCode

        return (
          <Styled.MenuItem
            key={idx}
            order={order}
            isFull={isFull}
            onClick={() => _onItemClick(code)}
            isActive={isActive}
          >
            {!isOnlyText ? (
              <Styled.Flag
                src={getFlagUrl(flagCode)}
                alt={name}
                isActive={isActive}
              />
            ) : null}
            {!isOnlyFlag ? <p>{name}</p> : null}
          </Styled.MenuItem>
        )
      }),
    [
      activeLanguages,
      isOnlyText,
      isOnlyFlag,
      onItemClick,
      websiteActiveLanguageCode
    ]
  )

  const selectedLanguage = useMemo(
    () => data.find(({ lang }) => lang.code === websiteActiveLanguageCode),
    [data, websiteActiveLanguageCode]
  )

  const { name, flagCode } = selectedLanguage?.lang || {}

  return (
    <Styled.LanguagesMenuContainer
      className="languages-menu-container"
      isMenuOpen={isMenuOpen}
    >
      <Controls actions={ActionGetter()}>
        <Styled.SelectedLanguageContainer
          ref={menuRef}
          isFull={isFull}
          isMenuOpen={isMenuOpen}
          isEditing={isEditing}
          onClick={toggle}
        >
          {!isOnlyText ? (
            <Styled.Flag src={getFlagUrl(flagCode)} alt={name} />
          ) : null}
          {!isOnlyFlag ? <p>{name}</p> : null}
          <ArrowDownIcon />
        </Styled.SelectedLanguageContainer>
      </Controls>
      {isSmallSizes ? (
        <Styled.MobileLanguagesMenu ref={mobileMenuRef} isOpen={isMenuOpen}>
          {menuItems}
        </Styled.MobileLanguagesMenu>
      ) : isMenuOpen ? (
        <Styled.LanguagesMenu
          containerRef={menuRef.current}
          menuItems={menuItems}
          onClose={closeMenu}
          backdropDisablingSelectors={['.languages-menu-settings-control']}
          className="languages-menu"
        />
      ) : null}
    </Styled.LanguagesMenuContainer>
  )
}

export default memo(LanguagesMenuCore)
