import { translate } from '@editor/common/utils/translations'

export const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  additionalGuests: [],
  dynamicFields: []
}

export const INITIAL_GUEST_DATA = { firstName: '', lastName: '' }

export const YES = 'yes'
export const NO = 'no'
export const CLOSED = 'closed'

export const FIRST_NAME_VALIDATION_MESSAGE =
  translate('please_enter_a_valid_name') ||
  'Name is too long or has invalid symbols'

export const LAST_NAME_VALIDATION_MESSAGE =
  translate('please_enter_a_valid_name') ||
  'Last name is too long or has invalid symbols'
