/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PrivacyPolicy2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import { secondary } from '@website/common/styles/colors'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  background-color: ${getStyleForProp('backgroundColor')};
  ${({ isEditing }) =>
    !isEditing &&
    css`
      padding-top: 0;
    `}
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
  .control-container:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
  .image-state-overlay {
    z-index: 9;
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`

const WMCustomContainer = styled(WMContainer)`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1000px;
  box-sizing: border-box;
  background-color: ${getStyleForProp('cardBackground')};
  border-radius: ${getStyleForProp('cardRadius')};
  padding-top: 80px;
  padding-bottom: 60px;
  margin-top: 50px;
  @media only screen and (max-width: 1024px) {
    width: calc(100% - 80px);
    margin-top: 40px;
    margin-right: 40px;
    margin-left: 40px;
    padding: 40px 40px 20px 40px;
  }
  @media only screen and (max-width: 457px) {
    width: calc(100% - 40px);
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 20px;
    padding-left: 20px;
  }
`

const BlocksContainer = styled.div`
  width: 100%;
  max-width: 800px;
  border-top: 1px solid ${getStyleForProp('border')};
  padding-top: 20px;
`

const AdditionalBlockWrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  position: relative;
  > div {
    width: 100%;
  }
`

const AdditionalBlockParagraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('additionalBlockParagraph')};
  ${getTextStyleByAlignment};
`

const ListItemWrap = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  & > .control-container {
    padding-top: 20px;
  }
`

const Block = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
  > div:nth-child(3) {
    margin-top: 30px;
  }
  > div {
    margin-top: 0;
  }
  > .control-container {
    margin-bottom: 30px;
    margin-bottom: 20px;
    width: 100%;
    p,
    h2 {
      margin-top: 0;
    }
  }
`

const ControlContainer = styled.div`
  .control-container {
    width: 100%;
  }
`

const BlockWrap = styled.article``

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
`

const ListItemParagraph = styled.p`
  position: relative;
  box-sizing: border-box;
  padding-left: 20px;
  color: ${getStyleForProp('blockParagraph')};

  &:after {
    content: '';
    position: absolute;
    max-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: 5px;
    left: 0;
    background: ${({ theme }) =>
      `linear-gradient(0, ${theme.palette.primaryDark}, ${theme.palette.primaryLight})`};
  }
`

const AddButtonsWrapper = styled.div`
  display: flex;
`

const TitleContainer = styled.div`
  ${getBackgroundStyles(THEME)};
  display: flex;
  justify-content: center;
  width: 100%;
  padding: ${({ withBackground }) =>
    withBackground ? '80px 40px' : '80px 40px 0 40px'};
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  @media only screen and (max-width: 457px) {
    padding: ${({ withBackground }) =>
      withBackground ? '60px 20px' : '60px 20px 0 20px'};
  }

  & > .control-container {
    margin: 0 auto;
    width: 100%;
    box-sizing: border-box;
    @media only screen and (min-width: 1025px) {
      max-width: 800px;
    }
    & > h1 {
      margin: 0;
      max-width: unset;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin: 0 auto;
  max-width: 800px;
  ${getTextStyleByAlignment};
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  BlocksContainer,
  ControlContainer,
  BlockWrap,
  Block,
  BlockTitle,
  BlockParagraph,
  ListItemWrap,
  ListItemParagraph,
  AdditionalBlockWrapper,
  AdditionalBlockParagraph,
  AddButtonsWrapper,
  TitleContainer,
  Title
}
