/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature31.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  .buttons-block {
    margin-left: -15px;
  }

  @media only screen and (min-width: 1281px) {
    padding: ${({ isEditing }) => (isEditing ? '130px 0 80px 0' : ' 80px 0')};
  }

  @media only screen and (max-width: 1280px) {
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'LEFT'
          ? 'column'
          : 'column-reverse'};
      `}

    justify-content: center;
  }
`

const BlockItemWrap = styled.div`
  height: 100%;
  display: flex;
  box-sizing: border-box;
  word-break: break-word;
`

const ImageContentWrapper = styled.div`
  width: 640px;
  min-width: 570px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-bottom: 40px;
  @media only screen and (min-width: 1281px) {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? css`
              margin-right: 50px;
            `
          : css`
              margin-left: 50px;
            `}
      `}
  }
  @media only screen and (max-width: 1440px) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1280px) {
    width: 100%;
    max-width: 570px;
    min-width: initial;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
`

const TextContentWrapper = styled.div`
  width: 50%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > :first-child {
    padding-top: 0;
  }
  @media only screen and (max-width: 1280px) {
    width: 100%;
    max-width: 570px;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  & > .control-container {
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
  ${({ theme }) =>
    css`
      ${theme.orientation === 'LEFT'
        ? css`
            @media only screen and (max-width: 1280px) {
              margin-top: 50px;
            }
            @media only screen and (max-width: 992px) {
              margin-top: 40px;
            }
          `
        : css`
            @media only screen and (max-width: 1280px) {
              margin-bottom: 50px;
            }
            @media only screen and (max-width: 992px) {
              margin-bottom: 40px;
            }
          `}
    `}
`

const Title = styled.h1`
  margin-bottom: 0;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  ${({ isTextGradient }) =>
    isTextGradient
      ? css`
          background: linear-gradient(
            90deg,
            ${getStyleForProp('gradientColorFrom')} 0,
            ${getStyleForProp('gradientColorTo')} 100%
          );
          background-clip: text;
          background-size: 100% auto;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:before,
          &:after {
            -webkit-background-clip: initial;
            -webkit-text-fill-color: initial;
            background: initial;
          }
          & .link-wrapper {
            position: initial;
          }
        `
      : ''}
`

const Paragraph = styled.p`
  padding-top: 20px;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

const Block = styled.div`
  width: 48%;
  max-width: 315px;
  min-width: 250px;
  margin: 15px 0 10px 0;
  box-sizing: border-box;
  @media only screen and (min-width: 651px) {
    ${({ areThreeBlock }) =>
      areThreeBlock &&
      css`
        &:nth-child(3) {
          margin-right: auto;
          margin-left: auto;
        }
      `}
    &:nth-child(even) {
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: 1280px) {
    width: 100%;
    max-width: 250px;
    margin: 15px 0 5px 0;
  }
  @media only screen and (max-width: 650px) {
    max-width: 315px;
    margin: 5px 0;
  }
  @media only screen and (max-width: 457px) {
    max-width: initial;
  }
  .control-container {
    display: flex;
    justify-content: flex-start;
  }
  > .control-container {
    padding: 6px 10px;
    box-sizing: border-box;
    > .styled-controls {
      left: initial;
      right: 0;
    }
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
  margin-bottom: -25px;
  @media only screen and (max-width: 1280px) {
    margin-bottom: -20px;
    margin-right: auto;
    margin-left: auto;
    max-width: 520px;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 30px;
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  text-align: left;
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 10px;
  width: 100%;
  text-align: left;
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ isEditing }) => (isEditing ? '0' : ' 20px 25px auto auto')};
  > div {
    width: 38px;
    height: 38px;
    svg {
      max-width: 38px;
      max-height: 38px;
      fill: ${getStyleForProp('icon')};
    }
  }
  .control-container {
    margin: 0 30px;
  }
`

const IconContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: ${({ isEditing }) => (isEditing ? '20px 25px 20px 0' : '0')};
`

const BlockWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 15px 0;
  box-sizing: border-box;
  @media only screen and (max-width: 450px) {
    width: auto;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 10px;
    p {
      padding-top: 0;
    }
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  .lazy-figure {
    border-radius: ${getStyleForProp('borderRadius')};
    box-shadow: ${({ theme }) =>
      `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  }
  &.image-1 {
    padding-top: 100%;
    .lazy-figure {
      padding-top: 100%;
    }
  }
  &.image-2 {
    padding-top: 150%;
    transform: translateY(25%);
    .lazy-figure {
      padding-top: 150%;
    }
  }
  &.image-3 {
    padding-top: 52.5%;
    .lazy-figure {
      padding-top: 52.5%;
    }
  }
  &.image-4 {
    padding-top: 81.81%;
    transform: translateY(36%);
    .lazy-figure {
      padding-top: 81.81%;
    }
  }
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
`

const ImageWrapper = styled.div`
  &.image-wrapper-1 {
    width: 54.6875%;
    margin-bottom: 20px;
    margin-right: 20px;
    @media only screen and (max-width: 650px) {
      margin-bottom: 10px;
      margin-right: 10px;
    }
  }
  &.image-wrapper-2 {
    width: 34.375%;
    margin-bottom: 20px;
    @media only screen and (max-width: 650px) {
      margin-bottom: 10px;
    }
  }
  &.image-wrapper-3 {
    width: 62%;
    margin-right: 20px;
    @media only screen and (max-width: 650px) {
      margin-right: 10px;
    }
  }
  &.image-wrapper-4 {
    width: 34.375%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: ${getStyleForProp('borderRadius')};
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextContentWrapper,
  ImageContentWrapper,
  Paragraph,
  Title,
  BlocksWrapper,
  Block,
  BlockItemWrap,
  BlockTitle,
  BlockParagraph,
  IconContainer,
  IconWrap,
  BlockWrap,
  ControlContainer,
  ImageWrapper,
  Img
}
