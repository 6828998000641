// @flow
import React, { useCallback, useRef, useEffect, memo } from 'react'
import Form from './Form'
import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'

import { MODAL_TYPES } from '../consts'

const ExtendedUrlModal = ({
  text,
  link,
  limitText,
  setModalState,
  isHypertextSelected,
  areSpacesAllowed,
  handler
}) => {
  const selectionRef = useRef()
  const rangeRef = useRef()

  useEffect(() => {
    if (isHypertextSelected) {
      selectionRef.current = document.getSelection()
      rangeRef.current = selectionRef.current.getRangeAt(0)
    }
  }, [])

  const restoreSelection = useCallback(() => {
    selectionRef.current.removeAllRanges()
    selectionRef.current.addRange(rangeRef.current)
  }, [selectionRef, rangeRef])

  const closeModal = useCallback(() => {
    isHypertextSelected && restoreSelection()
    setModalState(MODAL_TYPES.extendedUrlModal, {
      isOpen: false,
      isHypertextSelected: false,
      areSpacesAllowed: false
    })
  }, [setModalState, isHypertextSelected])

  const _handler = useCallback(
    (...args) => {
      setTimeout(() => {
        handler(...args)
      })
    },
    [handler]
  )

  return (
    <Styled.Popup
      className="extended-url-modal"
      onClose={closeModal}
      header={
        <Styled.PopupHeader>{translate('settings_label')}</Styled.PopupHeader>
      }
      isOpen
    >
      <Styled.PopupContent>
        <Form
          text={text}
          link={link}
          limitText={limitText}
          isLinkTextInputDisabled={isHypertextSelected}
          areSpacesAllowed={areSpacesAllowed}
          handler={_handler}
          onCancel={closeModal}
        />
      </Styled.PopupContent>
    </Styled.Popup>
  )
}

export default memo(ExtendedUrlModal)
