import styled from 'styled-components'
import LibPopup from '@renderforest/rf-ui-library/organism/Popup'

export const Popup = styled(LibPopup)`
  height: 100%;
  & > div {
    padding: 0;
    max-height: 90%;

    & > div {
      padding: 30px;
    }

    @media only screen and (max-width: 768px) {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;

      & > div {
        overflow: hidden;
        padding: 0;
      }
    }
  }
`

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Title = styled.h3`
  font-size: 20px;
  color: #252e48;
  text-align: center;
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
    padding: 20px;
    border-bottom: 1px solid #c1c9e0;
  }
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding: 30px 0 10px;

  button {
    height: 40px;
    padding: 11px 30px;

    &:first-child {
      margin-right: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
`
