/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header30.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  position: relative;
  padding: 0;
  & ${S.Container} {
    margin-bottom: 0;
  }

  .dots-wrap {
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
  .slick-slider-container {
    margin: 0 !important;
  }
  .slick-slide > div {
    margin: 0 !important;
    height: 100% !important;
    font-size: 0;
  }
  .slick-list {
    height: 100% !important;
    padding-top: ${({ isEditing }) => (isEditing ? '10px' : '0')};
  }
  .mySlider {
    overflow: hidden;
    width: 100vw;
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset}px`};
  width: 100%;
  max-width: initial;
  display: flex;
  justify-content: space-between;
  position: relative;
  ${({ theme }) =>
    css`
      flex-direction: ${theme.orientation === 'RIGHT' ? 'row-reverse' : 'row'};
    `}
  @media only screen and (max-width: 767px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
  }
  .socials-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0 0 0;
    box-sizing: border-box;
    position: absolute;
    bottom: 20px;
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            right: 20px;
          `
        : css`
            left: 20px;
          `}
    z-index: 2;
    @media only screen and (max-width: 767px) {
      margin: 20px 0 0 0;
    }
  }
  .socials-wrapper {
    display: flex;
    flex-direction: column;
    .link {
      width: 28px;
      height: 28px;
      border: 1px solid ${getStyleForProp('socialColor')};
      color: ${getStyleForProp('socialColor')};
      border-radius: ${getStyleForProp('iconRadius')};
      margin: 6px;
      fill: ${getStyleForProp('socialColor')};
    }
  }
`

const HeaderContentWrapper = styled.div`
  width: 48%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 40px;
  padding-top: 40px;
  @media only screen and (max-width: 1024px) {
    width: 50%;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    ${({ theme }) =>
      css`
        padding: ${theme.orientation === 'LEFT'
          ? '40px'
          : '40px 40px 80px 40px'};
      `}
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
    ${({ theme }) =>
      css`
        padding-bottom: ${theme.orientation === 'RIGHT' && '60px'};
      `}
  }
  .add-button-wrap {
    > div {
      height: 50px;
      box-sizing: border-box;
      padding: 16px 30px;
    }
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 84%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 20px;
    & > h1 {
      margin-bottom: 0;
    }
    & > h2 {
      margin-top: 0;
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 130px;
  max-height: 70px;
  display: flex;
  position: relative;
  z-index: 3;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 650px) {
    max-width: initial;
  }
  &::after {
    right: initial;
    left: 0;
  }
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-top: 14px;
  width: 100%;
`

const SliderWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  position: relative;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

const SliderItemWrapper = styled.div`
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: ${({ isEditing }) => (isEditing ? '50px 0 0 0' : '0')};
  .LazyLoad {
    padding-top: 100%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
  }
  .control-container {
    height: 100%;
    width: 100%;
  }
  @media only screen and (min-width: 768px) {
    img {
      ${({ isEditing }) =>
        !isEditing &&
        css`
          transform: translateY(-1.5px);
        `}
    }
  }
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Logo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Content = styled.div`
  display: flex;
`

const AddButtonWrap = styled.div`
  position: absolute;
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
          left: 30px;
        `
      : css`
          right: 30px;
        `}
  bottom: 20px;
  z-index: 2;
  transition: all 0.1s linear;
  @media only screen and (max-width: 1100px) and (min-width: 767px),
    (max-width: 500px) {
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            left: 10px;
          `
        : css`
            right: 10px;
          `}
    .add-button-wrap div {
      padding: 10px 8px;
    }
  }
`

const Dot = styled.button`
  border: none;
  padding: 0;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: 1px solid #5690ff;
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('socialColor')
}))``

export default {
  WmCustomContainer,
  SliderWrapper,
  SliderItemWrapper,
  HeaderContentWrapper,
  Content,
  ContentContainer,
  ImageWrapper,
  Img,
  Logo,
  StyledContainer,
  Title,
  Paragraph,
  AddButtonWrap,
  Dot,
  Icon
}
