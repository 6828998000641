/* @flow */

/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_BLOCK':
        if (action.value === 1) {
          draft.images.push(initialState.images[1])
        } else {
          draft.images.push(initialState.images[2])
        }
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.images.splice(idx, 1)
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        if (!url) {
          return
        }
        if (idx === null) {
          draft.imageUrl = url
          draft.imgDimensions = dimensions
          draft.imgCropParams = {}
        } else {
          draft.images[idx].imageUrl = url
          draft.images[idx].imgDimensions = dimensions || {}
          draft.images[idx].imgCropParams = {}
        }
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        if (idx === null) {
          draft.imgCropParams = imgCropParams
        } else {
          draft.images[idx].imgCropParams = imgCropParams
        }
        break
      }
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH1':
        draft.paragraph1 = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT':
        draft.paragraphAlignment = action.value
        break
      case 'CHANGE_PARAGRAPH1_ALIGNMENT':
        draft.paragraph1Alignment = action.value
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft[`additionalUrl${linkIdx + 1}`] = linkData.link
        draft[`additionalText${linkIdx + 1}`] = linkData.text
        break
      case 'ADD_LINK': {
        const { idx } = action.value
        draft[`additionalUrl${idx + 1}`] = initialState.additionalUrl2
        draft[`additionalText${idx + 1}`] = initialState.additionalText2
        break
      }
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft[`additionalUrl${idx + 1}`] = ''
        draft[`additionalText${idx + 1}`] = ''
        break
      }
      case 'SET_COMPONENT_ANIMATED_STATE':
        draft.isAnimated = !draft.isAnimated
        break
      case 'REMOVE_ARROW':
        draft.hasArrow = false
        break
      case 'ADD_ARROW':
        draft.hasArrow = true
        break
    }
  })
}
