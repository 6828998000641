/* @flow */
import React, { useContext, memo } from 'react'
import { validation } from './Feature9_2.consts'
import S from './Feature9_2.styled'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import IosBrowserBar from '@website/common/assets/iosBrowser.svg'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Buttons from '@website/common/components/Buttons'

const Feature9_2 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      customCode,
      buttons,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WMCustomContainer>
        <Show when={[title, paragraph]}>
          <S.HeaderContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Description}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.HeaderContent>
        </Show>
        <S.WebpageWrap>
          <S.BrowserBar src={IosBrowserBar} alt="browser bar " />
          <EditableContent
            as={S.Code}
            text={customCode}
            className="WM_GLOBAL_paragraph14"
            required={validation.customCode.required}
            onChange={Actions.changeCode}
          />
        </S.WebpageWrap>
        <Buttons data={buttons} />
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature9_2
