/* @flow */

export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 60,
  blockParagraphMaxChar: 150,
  priceMaxChar: 7,
  plans: {
    min: 1,
    max: 5,
    title: { required: true },
    price: { required: true }
  }
}
