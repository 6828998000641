/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const addImage = (key: string) => {
    dispatcher({
      type: 'ADD_IMAGE',
      value: { key }
    })
  }

  const removeImage = (key: string) => {
    dispatcher({
      type: 'REMOVE_IMAGE',
      value: { key }
    })
  }

  const imageActions = (key: string, imageUrl: string, imagesCount: number) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'header51',
      showRemove: false,
      additionalArgs: { key, imagesCount },
      imageUrl
    }),
    {
      toShow: imagesCount > 1,
      handler: () => {
        removeImage(key)
      },
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label')
    }
  ]

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    imageActions,
    addImage
  }
}
