/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text9.styled'
import { validation } from './Text9.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const Text9 = memo(props => {
  const {
    data: {
      blocks,
      buttons,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.Row>
        <Mapper
          data={blocks}
          render={(blockItem: string, blockIdx: number) => (
            <ControlsWithModalOpener
              actions={Actions.blockActions(blockIdx, blocks.length)}
            >
              <S.BlockWrap key={blockIdx}>
                <S.Block>
                  <S.IconWrapper>
                    <ControlsWithModalOpener
                      actions={Actions.iconActions(blockIdx)}
                    >
                      <S.Circle>
                        <S.Icon size="xxlarge" name={blockItem.iconName} />
                      </S.Circle>
                    </ControlsWithModalOpener>
                  </S.IconWrapper>
                  <EditableContent
                    as={S.Title}
                    text={blockItem.title}
                    alignment={blockItem.titleAlignment}
                    maxCount={validation.titleMaxChar}
                    className="WM_GLOBAL_paragraph18"
                    onChange={(newTitle: string) => {
                      Actions.changeBlockTitle(newTitle, blockIdx)
                    }}
                    changeAlignment={(alignment: string) =>
                      Actions.changeBlockTitleAlignment(alignment, blockIdx)
                    }
                  />
                </S.Block>
              </S.BlockWrap>
            </ControlsWithModalOpener>
          )}
        />
      </S.Row>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
      <Buttons data={buttons} />
    </S.StyledContainer>
  )
})

export default Text9
