/* @flow */
import produce from 'immer'
import { validation } from './Slider2.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        return
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        return
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }

      case 'CHANGE_SLIDE_TITLE':
        {
          const { newTitle, idx } = action.value
          draft.slides[idx].slideTitle = newTitle
        }
        return
      case 'CHANGE_SLIDE_CONTENT':
        {
          const { newContent, idx } = action.value
          draft.slides[idx].slideContent = newContent
        }
        return
      case 'CHANGE_IMAGE':
        {
          const { url, idx, dimensions } = action.value
          draft.slides[idx].imgUrl = url
          draft.slides[idx].imgDimensions = dimensions
          draft.slides[idx].imgCropParams = {}
        }
        return
      case 'REMOVE_IMAGE':
        if (state.slides.length === validation.slides.min) {
          return
        }
        draft.slides.splice(action.value.idx, 1)
        return
      case 'CHANGE_ITEM_TITLE':
        draft.textItems[action.value.idx].title = action.value.newItemTitle
        return
      // case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
      //   const { alignment, idx: idx } = action.value
      //   draft.textItems[action.value.idx].titleAlignment = alignment
      //   break
      // }

      // case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
      //   const { alignment, idx: idx } = action.value
      //   draft.textItems[action.value.idx].paragraphAlignment = alignment
      //   break
      // }
      case 'ADD_BLOCK':
        draft.textItems.push(initialState.textItems[0])
        return
      case 'REMOVE_BLOCK':
        draft.textItems.splice(action.value, 1)
        return
      case 'CHANGE_ITEM_PARAGRAPH':
        draft.textItems[action.value.idx].paragraph =
          action.value.newItemParagraph
        return
      case 'CHANGE_ICON':
        {
          const { idx, iconName } = action.value
          draft.textItems[idx].icon = iconName
        }
        return
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        return
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        return
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({ ...initialState.buttons[0], type: btnType })
        } else {
          draft.buttons.push({ ...initialState.buttons[0], type: btnType })
        }
        return
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
      }
    }
  })
}
