/* @flow */
import { addIcon } from '@website/components/CommonActions'
import { iconActionMaker } from '@website/components/ActionMakers'

export default dispatcher => {
  const changeBlockTitle = (title, blockIdx) =>
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { blockIdx, title }
    })

  const changeBlockParagraph = (paragraph, blockIdx) =>
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { blockIdx, paragraph }
    })

  const changeBlockTitleAlignment = (alignment, blockIdx) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  const changeBlockParagraphAlignment = (alignment, blockIdx) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  return {
    addIcon: idx => dispatcher(addIcon(idx)),
    changeBlockTitle,
    changeBlockParagraph,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    iconActions: iconActionMaker(dispatcher)
  }
}
