// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import { TControlProps } from './types'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { secondary } from '@website/common/styles/colors'

const Control = ({
  IconComp,
  tooltipText,
  isSelected,
  onClick,
  controlRef,
  className,
  isDisabled
}: TControlProps) => {
  return (
    <TooltipWrapper
      text={tooltipText}
      position="bottom"
      color={secondary.ExtraDark}
      className={className}
    >
      <Styled.Control
        isSelected={isSelected}
        isDisabled={isDisabled}
        onClick={onClick}
        ref={controlRef}
      >
        <IconComp />
      </Styled.Control>
    </TooltipWrapper>
  )
}

export default memo(Control)
