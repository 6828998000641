import styled, { css } from 'styled-components'
import { secondary, primary, white } from '@website/common/styles/colors'
import { Button } from '@editor/containers/edit/containers/settings/styled'
import CommonLinkButton from '@renderforest/rf-ui-library/molecules/LinkButton'

// HELPERS

const IFRAME_STYLES = {
  desktop: css`
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  `,
  tablet: css`
    position: absolute;
    width: 768px;
    height: 922px;
    top: -96px;
    transform: scale(0.572);
    @media only screen and (max-width: 1440px) and (max-height: 800px) {
      transform: scale(0.5066);
      top: -97px;
    }
    @media only screen and (max-width: 1366px) and (max-height: 800px) {
      transform: scale(0.435);
      top: -161px;
    }
  `,
  mobile: css`
    position: absolute;
    width: 374px;
    height: 715px;
    top: 56px;
    left: 50.1%;
    transform-origin: left;
    transform: scale(0.815) translateX(-50%);
    border-bottom-left-radius: 42px;
    border-bottom-right-radius: 42px;
    @media only screen and (max-width: 1440px) and (max-height: 800px),
      (max-height: 800px) {
      top: 4px;
      left: 50.1%;
      height: 713px;
      transform: scale(0.635) translateX(-50%);
    }
    @media only screen and (max-height: 680px) {
      top: -30px;
      left: 50.06%;
      height: 719px;
      transform: scale(0.565) translateX(-50%);
    }
  `
}
const DEVICE_FRAME_STYLES = {
  tablet: css`
    width: 596px;
    top: -58px;
    transform: translateX(-50%) scale(0.79);
    @media only screen and (max-width: 1440px) and (max-height: 800px) {
      transform: translateX(-50%) scale(0.7);
    }
    @media only screen and (max-width: 1366px) and (max-height: 800px) {
      transform: translateX(-50%) scale(0.6);
      top: -120px;
    }
  `,
  mobile: css`
    width: 336px;
    top: 30px;
    transform: translateX(-50%);
    @media only screen and (max-width: 1440px) and (max-height: 800px),
      (max-height: 800px) {
      transform: translateX(-50%) scale(0.78);
      top: -12px;
    }
    @media only screen and (max-height: 680px) {
      transform: translateX(-50%) scale(0.7);
      top: -40px;
    }
  `
}

const getIframeStyles = ({ deviceType }) => IFRAME_STYLES[deviceType] || ''
const getDeviceFrameStyles = ({ deviceType }) =>
  DEVICE_FRAME_STYLES[deviceType] || ''

// END OF HELPERS

// STYLED COMPONENTS

export const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const PreviewContent = styled.div`
  display: flex;
  flex-grow: 1;
`

export const NavbarContainer = styled.div`
  z-index: 1;
  position: relative;
  padding: 10px 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: 0 6px 12px 0 rgba(0, 82, 224, 0.1);
  background: ${white};
  justify-content: space-between;
  @media only screen and (max-width: 480px) {
    padding: 10px;
    .button-wrap {
      > div {
        font-size: 15px;
      }
      padding: 8px 10px;
    }
  }
  .preview-btn {
    white-space: normal;
    width: 39px;
    height: 39px;
    border-radius: 5px;
    background: linear-gradient(180deg, ${white} 0%, #edf5ff 100%);
    border: 1px solid #c1c9e0;
    div {
      padding: 0;
      svg {
        fill: #545f7e;
      }
    }
    &:hover {
      background: ${white};
      border: 1px solid #c1c9e0;
      div {
        svg {
          fill: #252e48;
        }
      }
    }
  }
`

export const Link = styled.div`
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${secondary.Normal};
  text-transform: uppercase;
  & > i {
    margin-right: 10px;
  }
  &:focus {
    outline: 1px solid #387dff;
  }
`

export const DeviceIconWrapper = styled.div`
  margin: 10px;
  cursor: pointer;
`

export const ApplyButtonWrapper = styled(Button)`
  color: ${white};
  margin: 0 15px;
  text-transform: uppercase;
  background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
    0% no-repeat padding-box;
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #0052e080;
    transition: all 0.2s ease-out;
  }
`

const CommonSectionStyles = css`
  display: flex;
  width: 33%;
  @media only screen and (max-width: 768px) {
    width: auto;
    justify-content: center;
  }
`

export const Devices = styled.div`
  justify-content: center;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  ${CommonSectionStyles}
`

export const LeftSection = styled.div`
  justify-content: flex-start;
  ${CommonSectionStyles}
`

export const RightSection = styled.div`
  justify-content: flex-end;
  align-items: center;
  ${CommonSectionStyles}
`

export const DeviceMockupContainer = styled.div`
  background-color: ${primary.Light};
  position: relative;
  width: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`

export const DeviceFrame = styled.img`
  position: absolute;
  left: 50%;
  ${getDeviceFrameStyles};
`

export const Iframe = styled.iframe`
  ${getIframeStyles};
  border: none;
`

export const IphoneSpeaker = styled.img`
  position: absolute;
  z-index: 1;
  width: 150px;
  top: 36px;
  @media only screen and (max-width: 1366px) and (max-height: 780px) {
    width: 90px;
    top: 65px;
  }
`

export const PreviewLoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LinkButton = styled.button`
  padding: 0;
  background: transparent;
  border: 0;
  display: flex;
  color: #545f7e;
  font-size: 14px;
  align-items: center;
  font-weight: 600;
  position: relative;
  span {
    padding-left: 20px;
  }
  svg {
    position: absolute;
    fill: #545f7e;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: left 300ms ease;
  }
  &:hover {
    cursor: pointer;
    color: #252e48;

    svg {
      fill: #252e48;
      left: -5px;
    }
  }
`
