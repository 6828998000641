/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break

      case 'ADD_ROW': {
        draft.gallery.push(initialState.gallery[1])
        draft.sixthItemParagraph = initialState.sixthItemParagraph
        draft.seventhItemParagraph = initialState.seventhItemParagraph
        break
      }

      case 'REMOVE_ROW': {
        draft.gallery.splice(1)
        draft.sixthItemParagraph = ''
        draft.seventhItemParagraph = ''
        break
      }

      case 'CHANGE_IMAGE': {
        const { url, idx, galleryRowIdx, dimensions } = action.value
        draft.gallery[galleryRowIdx][idx].imgUrl = url
        draft.gallery[galleryRowIdx][idx].imgDimensions = dimensions || {}
        draft.gallery[galleryRowIdx][idx].imgCropParams = {}
        break
      }
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_SIXTH_PARAGRAPH':
        draft.sixthItemParagraph = action.value
        break
      case 'CHANGE_SIXTH_PARAGRAPH_ALIGNMENT': {
        draft.sixthItemParagraphAlignment = action.value
        break
      }
      case 'CHANGE_SEVENTH_PARAGRAPH':
        draft.seventhItemParagraph = action.value
        break
      case 'CHANGE_SEVENTH_PARAGRAPH_ALIGNMENT': {
        draft.seventhItemParagraphAlignment = action.value
        break
      }
      case 'ADD_LEFT_ARROW': {
        draft.leftArrow = true
        break
      }
      case 'REMOVE_LEFT_ARROW': {
        draft.leftArrow = false
        break
      }

      case 'ADD_RIGHT_ARROW': {
        draft.rightArrow = true
        break
      }
      case 'REMOVE_RIGHT_ARROW': {
        draft.rightArrow = false
        break
      }

      case 'SET_IMG_CROP_PARAMS': {
        const { idx, imgCropParams, galleryRowIdx } = action.value
        draft.gallery[galleryRowIdx][idx].imgCropParams = imgCropParams
        break
      }
      case 'SET_COMPONENT_ANIMATED_STATE':
        draft.isAnimated = !draft.isAnimated
        break
    }
  })
}
