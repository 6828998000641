/* @flow */
import styled, { css } from 'styled-components'
import THEME from './CTA11.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  padding: ${({ isEditing }) => (isEditing ? '120px 0 80px 0' : '80px 0')};
  ${getBackgroundStyles(THEME)};
  @media only screen and (max-width: 768px) {
    padding: 60px 0;
    overflow: hidden;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

const Content = styled.div`
  width: 100%;
  max-width: 550px;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'LEFT' ? '0 60px 0 0' : '0 0 0 60px'};
    `}
  @media only screen and (max-width: 992px) {
    text-align: center;
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'LEFT' ? '0 0 60px 0' : '60px 0 0 0'};
      `}
  }
  @media only screen and (max-width: 550px) {
    ${({ theme }) =>
      css`
        transform-origin: ${theme.orientation === 'LEFT' ? 'bottom' : 'top'};
        margin: ${theme.orientation === 'RIGHT' ? '-30px 0 0 0' : '0'};
      `}
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin: 0 0 30px 0;
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  font-weight: 500;
`

const BadgeContainer = styled.div`
  margin: 30px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 310px;
  @media only screen and (max-width: 1023px) {
    width: 100%;
    max-width: initial;
    justify-content: center;
    flex-wrap: wrap;
    a {
      margin: 10px;
    }
  }
`

/*-------------*/
const PhoneWrap = styled.div`
  position: relative;
  z-index: 1;
`

const Phone = styled.div`
  width: 256px;
  margin: 0 auto;
  position: relative;
  border: 10px solid ${getStyleForProp('phone')};
  box-sizing: border-box;
  border-radius: 38px;
  font-size: 0;
  height: 530px;
  .control-container {
    width: 100%;
  }
  .lazy-figure {
    width: 100%;
    height: initial;
    position: relative;
    padding-top: 216.1%;
    border-radius: 24px;
  }
`

const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 24px;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const DinamicWrap = styled.div`
  width: 134px;
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  .rf-iphone-dimanic-svg path {
    fill: ${getStyleForProp('phone')};
  }
`

const AndroidWrap = styled.div`
  width: 210px;
  transform: translateX(-40px);
  .lazy-figure {
    padding-top: 213%;
    position: relative;
    border-radius: 24px;
  }
`

const MockupsWrap = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(20px);
  @media only screen and (max-width: 550px) {
    transform: translateX(20px) scale(0.8);
    ${({ theme }) =>
      css`
        transform-origin: ${theme.orientation === 'LEFT' ? 'bottom' : 'top'};
        margin: ${theme.orientation === 'LEFT' ? '-60px 0 0 0' : '0'};
      `}
  }
  .image-state-overlay,
  .failed-image-container {
    z-index: 1;
  }
`

const ButtonsBlock = styled.div`
  padding: 20px 0 0 0;
  margin: 0 0 0 -15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 992px) {
    justify-content: center;
    margin: 0 auto;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
  .add-button-wrap {
    margin: 0 15px;
    > div {
      width: 100%;
      box-sizing: border-box;
      height: 50px;
      padding: 16px 30px;
    }
  }
`

const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (max-width: 992px) {
    justify-content: center;
    margin: 0 auto;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BtnWrapper = styled.div`
  margin: 15px;
  @media only screen and (max-width: 550px) {
    max-width: 300px;
    margin: 15px 0;
  }
  .control-container {
    width: 100%;
  }
  .link-wrapper {
    width: 100%;
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      getStyleForProp(props.isTertiary ? 'raisedShadow' : 'buttonShadow')};
    ${props =>
      getStyleForProp(
        props.isTertiary ? 'buttonBackgroundTertiary' : 'buttonBackground'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    max-width: 300px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`

export default {
  Container,
  WmCustomContainer,
  Section,
  Title,
  Paragraph,
  BadgeContainer,
  PhoneWrap,
  Phone,
  MobileImg,
  DinamicWrap,
  AndroidWrap,
  MockupsWrap,
  Content,
  ButtonsBlock,
  ButtonsWrap,
  ButtonsContainer,
  BtnWrapper
}
