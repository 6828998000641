/* @flow */
import React, { useContext, useState } from 'react'
import * as S from './Slider4.styled'
import { validation } from './Slider4.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import StoreBadge from '@website/common/components/StoreBadge'
import IphoneDinamic from '@website/common/components/IphoneDinamic'
import Slider from '../CenteredSlider'
import SlideCounts from './SlideCounts'
import { translate } from '@editor/common/utils/translations'

const Slider4 = ({
  data: {
    backgroundImgUrl,
    bgOverlayTransparency,
    backgroundImgDimensions,
    title,
    titleAlignment,
    appleStoreLink,
    googlePlayLink,
    slides
  }
}) => {
  const [activeIdx, setActiveIdx] = useState(parseInt(slides.length / 2))
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)

  const showRemove = slides.length > validation.slides.min

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <WMContainer>
        <EditableContent
          text={title}
          as={S.Title}
          alignment={titleAlignment}
          maxCount={validation.titleMaxChar}
          className="WM_GLOBAL_heading32"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <S.BadgeContainer isEditing={isEditing} isTitle={title.length !== 0}>
          <If
            condition={!!googlePlayLink}
            then={() => (
              <S.BadgeControlContainer>
                <ControlsWithModalOpener
                  actions={Actions.storeIconActions(
                    'googlePlayLink',
                    googlePlayLink
                  )}
                >
                  <StoreBadge
                    className="badge-item-wrap"
                    badgeName="google"
                    url={googlePlayLink}
                  />
                </ControlsWithModalOpener>
              </S.BadgeControlContainer>
            )}
            otherwise={() => (
              <AddButton
                onAdd={Actions.addStoreLink('googlePlayLink')}
                toShow={true}
                rounded
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                Google play
              </AddButton>
            )}
          />
          <If
            condition={!!appleStoreLink}
            then={() => (
              <ControlsWithModalOpener
                actions={Actions.storeIconActions(
                  'appleStoreLink',
                  appleStoreLink
                )}
              >
                <StoreBadge
                  badgeName="apple"
                  url={appleStoreLink}
                  className="badge-item-wrap"
                />
              </ControlsWithModalOpener>
            )}
            otherwise={() => (
              <AddButton
                onAdd={Actions.addStoreLink('appleStoreLink')}
                toShow={true}
                rounded
                style={{ margin: '0 0 0 10px' }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                App store
              </AddButton>
            )}
          />
        </S.BadgeContainer>
        <S.SliderContainer>
          <S.DinamicWrap>
            <IphoneDinamic />
          </S.DinamicWrap>
          <S.PhoneWrap>
            <S.Phone />
          </S.PhoneWrap>
          <Slider
            itemProps={{
              width: 241,
              height: 518,
              sizes: {
                large: 250,
                medium: 210,
                small: 210
              },
              additionalOffset: 10
            }}
            breakPoints={[1370, 1025]}
            itemMaxWidth={250}
            onBeforeChange={setActiveIdx}
          >
            {slides.map((slide: string, idx: number) => (
              <ControlsWithImageCropper
                key={idx}
                hideControls={idx !== activeIdx}
                toPropagate={false}
                actions={Actions.mockupActions(
                  idx,
                  showRemove,
                  slide.imageUrl,
                  { x: 0, y: 55 }
                )}
              >
                <Image
                  withLazy={false}
                  as={S.Img}
                  alt="Slider image"
                  sizes={slide.imgDimensions}
                  defaultImgSrc={slide.imageUrl}
                  asProps={{
                    imgCropParams: slide.imgCropParams
                  }}
                />
              </ControlsWithImageCropper>
            ))}
          </Slider>
        </S.SliderContainer>
        <SlideCounts
          activeSlideIdx={activeIdx + 1}
          slidesCount={slides.length}
        />
        <AddButton
          onAdd={() => Actions.addSlide(activeIdx)}
          toShow={slides.length < validation.slides.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </WMContainer>
    </S.StyledContainer>
  )
}

export default Slider4
