// @flow
import React from 'react'
import {
  Redirect,
  Switch,
  Route,
  useRouteMatch,
  useHistory
} from 'react-router-dom'
import { connect } from 'react-redux'

import StartCreationContainer from './StartCreationContainer'
import ChooseComponent from '@containers/choose-component'
import ChooseTemplate from '@containers/choose-template'
import { isProjectLoaded, hasComponents } from '@selectors'
import { EDITOR_BASE } from '@editor/conf/routes'
import AiWebsiteBuilder from '@containers/ai-website-builder'

const mapStateToProps = state => ({
  isProjectLoaded: isProjectLoaded(state),
  hasComponents: hasComponents(state)
})

const StartCreation = ({ isProjectLoaded, hasComponents }) => {
  const {
    location: { search = '' }
  } = useHistory()

  const { url } = useRouteMatch(EDITOR_BASE)
  const { path } = useRouteMatch()
  const shouldRedirect = isProjectLoaded && hasComponents

  return shouldRedirect ? (
    <Redirect to={`${url}/edit${search}`} />
  ) : (
    <>
      <Switch>
        <Route path={`${path}/ai`}>
          <AiWebsiteBuilder />
        </Route>
        <Route path="/">
          <StartCreationContainer />
        </Route>
      </Switch>
      <Switch>
        <Route
          path={`${path}/add-component/:page/:idx`}
          render={props => <ChooseComponent {...props} actionType="add" />}
        />
        <Route path={`${path}/choose-template`}>
          <ChooseTemplate />
        </Route>
      </Switch>
    </>
  )
}

export default connect(mapStateToProps)(StartCreation)
