export const BUTTON_INITIAL_STATE = [
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]

export const validation = {
  headingMaxChar: 50,
  paragraphMaxChar: 200,
  subTitleMaxChar: 30,
  title: { required: true },
  blockTitle: { required: true },
  blockTitleMaxChar: 20,
  blockSubTitleMaxChar: 5,
  blockParagraphMaxChar: 100,
  blocks: {
    min: 2,
    max: 3
  },
  buttons: {
    min: 0,
    max: 2
  },
  headerSocials: {
    min: 0,
    max: 6
  }
}
