/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header28.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0' : `${topOffset}px 0 0 0`};
  & ${S.Container} {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 650px) {
    padding: ${({ topOffset, isEditing }) =>
      isEditing ? '0' : `${topOffset}px 0 0 0`};
  }
  @media only screen and (max-width: 768px) {
    ${({ isEditing }) =>
      !isEditing &&
      css`
        margin-bottom: -70px;
      `}
  }
`

const HeaderContent = styled.div`
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  max-width: 340px;
  padding: ${({ onlySocials }) =>
    onlySocials ? '160px 20px' : '160px 20px 50px 20px'};
  box-sizing: border-box;
  margin: 0 10% 0 0;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
      align-self: flex-start;
      margin: 0 0 0 10%;
    `}
  @media only screen and (max-width: 768px) {
    padding: ${({ isEditing }) =>
      isEditing ? '70px 20px 20px 20px;' : '140px 20px 20px 20px;'};
    ${({ isEditing }) =>
      !isEditing &&
      css`
        transform: translateY(-70px);
      `}
  }
  @media only screen and (max-width: 650px) {
    margin: 0 auto;
  }
  @media only screen and (max-width: 420px) {
    width: 86%;
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 20px;
    &:nth-child(2) {
      margin-bottom: 0px;
    }
    h2 {
      padding-top: 0;
    }
  }
  .socials-block {
    display: flex;
    flex-direction: column;
    margin: 50px 0 0 0;
    align-items: center;
    @media only screen and (max-width: 767px) {
      margin: 30px 0 0 0;
    }
  }
  .socials-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: ${({ onlySocials }) => (onlySocials ? '0' : '7vw 0 0 0')};
    & > .control-container:nth-child(n + 2) {
      .styled-controls {
        left: initial;
        right: 0;
      }
    }
    @media only screen and (max-width: 768px) {
      margin: 60px 0 0 0;
    }
    .link {
      width: 26px;
      height: 26px;
      fill: ${getStyleForProp('socialColor')};
      @media only screen and (max-width: 650px) {
        font-size: 1rem;
      }
    }
  }
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('socialColor')
}))``

export default {
  StyledContainer,
  HeaderContent,
  Title,
  Paragraph,
  Icon
}
