/* @flow */
import React, { useState, useRef, useContext } from 'react'
import { connect } from 'react-redux'
import ControlsWithModalOpener from '../ControlsWithModalOpener'

import { openLoginPopup } from '@actions/editor'
import { selectIsUserAuthorized } from '@selectors'
import { EditingContext } from '@contexts'
import { prepareActions } from './helper'
import type { TControlsWithImageCropperProps } from './type'

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})
const mapDispatchToProps = { openLoginPopup }

const ControlsWithImageCropper = ({
  children,
  isUserAuthorized,
  openLoginPopup,
  ...controlProps
}: TControlsWithImageCropperProps) => {
  const [sizes, setSizes] = useState({})
  const imgRef = useRef(null)

  const { props: imgProps } = children
  const { isEditing } = useContext(EditingContext)

  if (!isEditing) {
    return <children.type {...imgProps} />
  }

  const getCropperType = () => {
    const { current } = imgRef
    let type = 'rect'
    if (current) {
      const lazyFigure = current.getElementsByClassName('lazy-figure')[0]
      if (lazyFigure) {
        const style = getComputedStyle(lazyFigure)
        if (
          style.borderRadius === '50%' ||
          ![
            style.borderBottomLeftRadius,
            style.borderBottomRightRadius,
            style.borderTopLeftRadius,
            style.borderTopRightRadius
          ].some(radius => radius !== '50%')
        ) {
          type = 'circle'
        }
      }
    }
    return type
  }

  const onLoad = ({ width, height }): void => {
    imgProps && imgProps.onLoad && imgProps.onLoad({ width, height })
    if (!sizes.width) {
      setSizes({
        height,
        width
      })
    }
  }

  const childrenProps = {
    ...{
      ...imgProps,
      asProps: {
        ...imgProps.asProps,
        onAnimationEnd: onLoad
      }
    }
  }

  const { defaultImgSrc, sizes: imgDimensions } = childrenProps
  const { imgCropParams = {} } = childrenProps.asProps

  const cropperType = getCropperType()

  const controlActions = prepareActions({
    sizes,
    cropperType,
    imgCropParams,
    isUserAuthorized,
    controlActions: controlProps.actions,
    imgSrc: defaultImgSrc,
    imgDimensions,
    openLoginPopup
  })

  return (
    <ControlsWithModalOpener {...controlProps} actions={controlActions}>
      <div style={{ height: '100%' }} ref={imgRef}>
        <children.type {...childrenProps} />
      </div>
    </ControlsWithModalOpener>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlsWithImageCropper)
