/* @flow */
import { COLOR_MODES } from '@editor/conf/consts'
import { isPrimitiveValue } from '@editor/common/utils'

const _getPageComponents = (pages: Array<Object>, activePagePath: string) => {
  const activePage = pages.find(page => page.path === activePagePath)

  return activePage.components
}

const _getNextColorMode = (
  pages: Array<Object>,
  compIdx: number,
  activePagePath: string
) => {
  const components = _getPageComponents(pages, activePagePath)
  const { LIGHT, EXTRALIGHT } = COLOR_MODES
  if (components[compIdx]) {
    const { colorMode } = components[compIdx]
    return colorMode === LIGHT ? EXTRALIGHT : LIGHT
  }
  return LIGHT
}

/**
 * @description Gets added or replaced component colorMode.
 * Returns next component's next colorMode, in case if `colormode===''`
 * Returns empty string in case if component doesn't have colorMode.
 * Returns component colorMode in case if colorMode is any of `MODES`.
 * @returns string
 **/
export const getColorMode = (
  pages: Array<Object>,
  atIndex: number,
  colorMode: string | undefined,
  activePagePath: string
) => {
  if (colorMode === '') {
    return _getNextColorMode(pages, atIndex, activePagePath)
  }
  if (!colorMode) {
    return ''
  }
  return colorMode
}

// HEADER REPLACEMENT

const FORCED_REPLACEMENT_PAIRS = [
  { key: 'logoSrc', pairKey: 'logoDimensions' },
  {
    key: 'backgroundImgUrl',
    pairKey: 'backgroundImgDimensions'
  }
]

export const transferHeaderProps = (
  oldHeaderProps,
  newHeaderDefaultProps,
  oldHeaderDefaultProps
) => {
  const recipientKeys = Object.keys(newHeaderDefaultProps)

  return recipientKeys.reduce((resultObj, key) => {
    const isPrimitiveValues =
      isPrimitiveValue(oldHeaderProps[key]) &&
      isPrimitiveValue(newHeaderDefaultProps[key])

    const isOldHeaderPropChanged =
      oldHeaderProps[key] !== oldHeaderDefaultProps[key]
    const hasDonorValue =
      oldHeaderProps.hasOwnProperty(key) && oldHeaderProps[key]
    const shouldReplace =
      isPrimitiveValues && hasDonorValue && isOldHeaderPropChanged
    const forcedReplacementPair = FORCED_REPLACEMENT_PAIRS.find(
      pair => pair.key === key
    )

    const replaceableProps = shouldReplace
      ? {
          [key]: oldHeaderProps[key],
          ...(forcedReplacementPair
            ? {
                [forcedReplacementPair.pairKey]:
                  oldHeaderProps[forcedReplacementPair.pairKey]
              }
            : {})
        }
      : {
          // resultObj[key] - if already replaced due to pair
          [key]: resultObj[key] || newHeaderDefaultProps[key]
        }

    return {
      ...resultObj,
      ...replaceableProps
    }
  }, {})
}
