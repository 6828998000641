// default sizes for images in various devices

const header1 = [500, 500, 500, 500]

//const backgroundImgSizes = [800, 1200, 1600, 2200]

const fullScreenBackgroundImgSizes = [1000, 1500, 1800, 2200]

const navbarLogoSizes = [500, 500, 500, 500]

const messengerAvatarSizes = [400]

const faviconSizes = [128]

const socialImageSizes = [1600]

const email4 = [600, 900, 1200, 1200]

const RSVP3Image = [550, 600, 600, 600]

const RSVP3CardImage = [700, 1000, 1200, 1200]

const RSVP5CardImage = [700, 1000, 1200, 1200]

const feature6 = [700, 1000, 1000, 1000]

const feature7 = [700, 1000, 1000, 1400]

const feature8 = [700, 1000, 1800, 1800]

const feature11 = [700, 1000, 1000, 1500]

const feature11_2 = [500, 560, 800, 1000]

const feature14 = [700, 1000, 1300, 1800]

const feature14_2 = [400, 600, 1000, 1000]

const feature15 = [500, 800, 800, 1000]

const feature16 = [800, 1000, 1000, 1000]

const feature16_2 = [600, 600, 600, 600]

const feature17 = [500, 700, 700, 700]

const feature18 = [600, 1400, 1000, 1400]

const feature19 = [600, 700, 700, 1000]

const feature20 = [600, 700, 700, 1000]

const feature21 = [600, 700, 700, 1000]

const feature22 = [600, 700, 700, 1000]

const feature28 = [700, 700, 700, 1000]

const feature29 = [700, 700, 1200, 1200]

const feature31 = [600, 600, 800, 800]

const feature32 = [600, 600, 800, 800]

const feature34 = [500, 900, 700, 900]

const feature36Image1 = [800, 1000, 1000, 1000]

const feature36Image2 = [700, 900, 900, 900]

const feature36Image3 = [500, 800, 800, 800]

const feature36Image4 = [400, 750, 750, 750]

const feature18LeftImage = [500, 900, 700, 900]

const feature37 = [500, 800, 800, 800]

const feature38Image1 = [800, 800, 1000, 1000]

const feature38Image2 = [800, 800, 1000, 1000]


const text3 = [700, 1000, 1000, 1000]

const text15 = [598, 598, 598, 598]

const text16 = [600, 800, 1200, 1200]

const text17 = [400, 400, 400, 400]

const text18BgImage = [800, 1000, 1000, 1500]

const text18Image = [550, 600, 600, 600]

const text21 = [1200, 1600, 2400, 2400]

const text22 = [600, 800, 650, 650]

const pricing6 = [800, 1000, 1000, 1500]

const Pricing6_2 = [600, 1400, 1000, 1400]

const team1 = [500, 500, 500, 500]

const team2 = [600, 600, 600, 600]

const team4 = [500, 600, 600, 700]

const team5 = [700, 700, 700, 700]

const team6 = [300, 400, 400, 400]

const team7 = [500, 600, 800, 900]

const team8 = [600, 600, 600, 600]

const team9LargeImg = [800, 1000, 1200, 1200]

const team9SmallImg = [400, 400, 400, 400]

const testimonial1LogoSizes = [600, 600, 600, 600]

const testimonial2LogoSizes = [600, 600, 600, 600]

const testimonial1ClientImgSizes = [500, 500, 500, 500]

const testimonial2ClientImgSizes = [500, 500, 500, 500]

const testimonial4ClientImgSizes = [500, 500, 500, 500]

const testimonial6ClientImgSizes = [500, 500, 500, 500]

const testimonial7ClientImgSizes = [500, 500, 500, 500]

const steps1 = [700, 1000, 1500, 1500]

const steps2 = [700, 1000, 1000, 1000]

const steps5 = [1000, 1000, 1000, 1000]

const steps7 = [700, 1000, 1500, 1500]

const paypal2 = [600, 1200, 1200, 1200]

const paypal5 = [600, 600, 600, 600]

const post1 = [700, 1000, 1000, 1000]

const post2 = [1000, 1000, 1000, 1000]

const post3 = [500, 600, 600, 700]

const payPal1Logo = [300, 300, 300, 300]

const payPal1Main = [670, 1200, 1200, 1200]

const slider1 = [700, 1000, 1000, 1000]

const slider2 = [1000, 1000, 1000, 1000]

const slider3 = [1000, 1000, 1000, 1000]

const slider4 = [500, 500, 500, 500]

const slider5 = [700, 1000, 1500, 1500]

const slider6 = [700, 1400, 1400, 1400]

const portfolio1 = [900, 1000, 1000, 1000]

const portfolio2 = [1000, 1000, 1500, 1500]

const portfolio4 = [1000, 1000, 2000, 2300]

const portfolio5 = [500, 800, 1000, 1200]

const portfolio6 = [800, 600, 600, 500]

const portfolio7 = [500, 700, 700, 700]

const portfolio8 = [500, 700, 700, 700]

const portfolio9 = [500, 700, 800, 1000]

const Portfolio10 = [400, 600, 600, 600]

const Portfolio11 = [400, 600, 600, 600]

const portfolio12 = [500, 700, 700, 700]

const portfolio13Small = [800, 800, 500, 500]

const portfolio13Large = [800, 800, 1400, 830]

const portfolio6MainImage = [1200, 800, 600, 500]

const portfolio6_2 = [800, 800, 800, 800]

const header5Mockup = [600, 600, 600, 600]

const header5_2Mockup = [600, 600, 600, 600]

const header5_2MockupDesktop = [600, 600, 1000, 1000]

const header6Mockup = [600, 600, 600, 600]

const header6_2Mockup = [600, 600, 600, 600]

const header7ImgSizes = [600, 1000, 1300, 1500]

const header8MockupSizes = [500, 500, 500, 500]

const header8ImgSizes = [500, 700, 1000, 1500]

const header9ImgSizes = [600, 800, 900, 1000]

const header11MockupSizes = [600, 900, 900, 900]

const header15ImgSizes = [600, 600, 600, 600]

const header24MockupSizes = [600, 900, 900, 900]

const header29 = [500, 700, 700, 700]

const header30SliderImage = [700, 700, 1200, 1600]

const header36 = [500, 700, 700, 800]

const header37newsImage = [800, 1800, 900, 700]

const header37SliderImages = [350, 700, 800, 1500]

const Header38rightImage = [800, 1500, 2048, 1400]

const header38SliderImages = [800, 1800, 900, 700]

const header42CenterImage = [800, 1000, 1000, 1000]

const header42LeftImage = [500, 500, 500, 500]

const header42RightImage = [500, 500, 500, 500]

const header44MainImage = [1200, 1200, 1200, 800]

const header44BlockImages = [500, 500, 500, 500]

const header47 = [600, 600, 800, 800]

const header48 = [500, 500, 700, 700]

const header51 = [300, 300, 500, 600]

const header52LargeImageSizes = [500, 800, 1000, 1000]

const header52SmallImageSizes = [400, 450, 600, 600]

const header54 = [500, 700, 700, 800]

const header57 = [800, 1200, 1600, 2200]


const clientsLogos = [300, 300, 300, 300]

const feature12 = [800, 800, 800, 800]

const feature12_2 = [500, 500, 700, 1000]

const header18 = [800, 800, 800, 800]

const header19 = [500, 500, 500, 500]

const header22 = [600, 900, 1000, 1200]

const header26 = [700, 700, 1200, 1600]

const header53 = [500, 500, 500, 500]

const CTA6 = [500, 800, 800, 900]

const CTA7 = [800, 800, 1000, 1200]

const CTA8 = [400, 400, 500, 600]

const CTA9 = [600, 700, 700, 900]

const CTA10 = [600, 700, 700, 900]

const CTA11 = [400, 600, 600, 600]

const CTA11_2 = [400, 600, 600, 600]

const CTA12 = [400, 400, 400, 400]

const CTA13 = [598, 598, 598, 598]

const CTA14 = [600, 600, 600, 600]

const contact10 = [500, 800, 1100, 1100]

const contact13 = [500, 800, 1100, 1100]

const RSVP2CenterImage = [500, 500, 600, 600]

const RSVP2card = [800, 1000, 1200, 1200]

const RSVP4SmallImage = [400, 400, 400, 400]

const RSVP4LargeImage = [800, 1000, 1200, 1200]

const RSVP1CenterImage = [500, 500, 600, 600]

const RSVP1Card = [800, 1000, 1200, 1200]

// generate object for background image dimensions
const _f = (mobileHeight, tabletHeight) => [
  {
    width: 400,
    height: mobileHeight,
    fit: 'cover'
  },
  {
    width: 600,
    height: tabletHeight,
    fit: 'cover'
  },
  {
    width: 1600
  },
  {
    width: 2200
  }
]

// TODO: to be checked the Header50
// id: _f(mobileHeight, tabletHeight),

export const backgroundImgSizes = {
  default: [
    { width: 800 },
    { width: 1200 },
    {
      width: 1600
    },
    {
      width: 2200
    }
  ],
  Header1: _f(650, 500),
  Header2: _f(550, 450),
  Header4: _f(550, 550),
  Header5_2: _f(800, 650),
  Header6_2: _f(850, 800),
  Header7: _f(850, 850),
  Header8: _f(850, 690),
  Header9: _f(800, 760),
  Header11: _f(840, 810),
  Header13: _f(680, 590),
  Header14: _f(600, 570),
  Header15: _f(730, 770),
  Header16: _f(800, 900),
  Header17: _f(280, 340),
  Header21: _f(300, 300),
  Header23: _f(750, 750),
  Header25: _f(850, 720),
  Header27: _f(260, 260),
  Header28: _f(550, 550),
  Header30: _f(800, 1000),
  Header31: _f(340, 450),
  Header32: _f(390, 540),
  Header33: _f(420, 400),
  Header34: _f(1600, 1650),
  Header35: _f(600, 560),
  Header36: _f(950, 1050),
  Header39: _f(380, 380),
  Header40: _f(410, 330),
  Header41: _f(410, 400),
  Header43: _f(1700, 1100),
  Header44: _f(1650, 1700),
  Header45: _f(1200, 1200),
  Header46: _f(800, 900),
  Header47: _f(900, 880),
  Header48: _f(1050, 1070),
  Header49: _f(500, 450),
  Header51: _f(800, 900),
  Header52: _f(1150, 1450),
  header54: _f(1350, 1200),
  header57: _f(1920, 1200)
}

export {
  CTA6,
  CTA7,
  CTA8,
  CTA9,
  CTA10,
  CTA11,
  CTA11_2,
  CTA12,
  CTA13,
  CTA14,
  header1,
  header18,
  header19,
  header22,
  header26,
  feature12,
  feature12_2,
  clientsLogos,
  navbarLogoSizes,
  fullScreenBackgroundImgSizes,
  header5Mockup,
  header5_2Mockup,
  header5_2MockupDesktop,
  header6Mockup,
  header6_2Mockup,
  header7ImgSizes,
  header8MockupSizes,
  header9ImgSizes,
  header8ImgSizes,
  header11MockupSizes,
  header15ImgSizes,
  header24MockupSizes,
  header29,
  header30SliderImage,
  header36,
  header37newsImage,
  header37SliderImages,
  Header38rightImage,
  header38SliderImages,
  header42CenterImage,
  header42LeftImage,
  header42RightImage,
  header44MainImage,
  header44BlockImages,
  header47,
  header48,
  header51,
  header52LargeImageSizes,
  header52SmallImageSizes,
  header53,
  header54,
  header57,
  feature6,
  feature7,
  feature8,
  feature11,
  feature11_2,
  feature14,
  feature14_2,
  feature15,
  feature16,
  feature16_2,
  feature17,
  feature18,
  feature18LeftImage,
  feature19,
  feature20,
  feature21,
  feature22,
  feature28,
  feature29,
  feature31,
  feature32,
  feature34,
  feature36Image1,
  feature36Image2,
  feature36Image3,
  feature36Image4,
  feature37,
  feature38Image1,
  feature38Image2,
  text3,
  text15,
  text16,
  text17,
  text18BgImage,
  text18Image,
  text21,
  text22,
  pricing6,
  Pricing6_2,
  email4,
  RSVP4SmallImage,
  RSVP4LargeImage,
  RSVP3Image,
  RSVP3CardImage,
  RSVP5CardImage,
  team1,
  team2,
  team4,
  team5,
  team6,
  team7,
  team8,
  team9LargeImg,
  team9SmallImg,
  steps1,
  steps2,
  steps5,
  steps7,
  post1,
  post2,
  post3,
  slider1,
  slider2,
  slider3,
  slider4,
  slider5,
  slider6,
  paypal2,
  paypal5,
  portfolio1,
  portfolio2,
  portfolio4,
  portfolio5,
  portfolio6,
  portfolio7,
  portfolio8,
  portfolio9,
  Portfolio10,
  Portfolio11,
  portfolio12,
  portfolio13Small,
  portfolio13Large,
  portfolio6MainImage,
  portfolio6_2,
  payPal1Logo,
  payPal1Main,
  testimonial1LogoSizes,
  testimonial2LogoSizes,
  testimonial1ClientImgSizes,
  testimonial2ClientImgSizes,
  testimonial4ClientImgSizes,
  testimonial6ClientImgSizes,
  testimonial7ClientImgSizes,
  contact10,
  contact13,
  messengerAvatarSizes,
  faviconSizes,
  socialImageSizes,
  RSVP1CenterImage,
  RSVP2CenterImage,
  RSVP1Card,
  RSVP2card
}
