/* @flow */
import React, { useContext, useState, useCallback, memo } from 'react'
import S from './Countdown1.styled'
import ActionGetter from './Actions'
import { validation } from './Countdown1.consts'
import If from '@website/common/components/Conditional'
import DatePicker from '@website/common/components/DatePicker'
import { DispatchContext, EditingContext, MobileDetectContext } from '@contexts'
import Mapper from '@website/common/components/Mapper'
import { getDate } from '@website/common/utils'
import EditableContent from '@website/common/components/EditableContent'
import useCountdownDatePicker from '@website/common/hooks/useCountdownDatePicker'

const Countdown1 = memo(props => {
  const {
    data: {
      dateLabels,
      countdownDate,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const _countdownDate = countdownDate || new Date().toISOString()

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const isMobile = useContext(MobileDetectContext)
  const [datePickerVisibility, setDatePickerVisibility] = useState(false)
  const [formatedDates, setFormatedDates] = useState(getDate(_countdownDate))
  const [ datePickerAnchorEl, setDatePickerAnchorEl ] = useState(null)

  const _submitDate = useCallback(
    date => {
      setDatePickerVisibility(false)
      Actions.changeDate(date.toISOString())
    },
    [_countdownDate]
  )

  useCountdownDatePicker(_countdownDate, setFormatedDates)

  const handleDatePickerOpen = useCallback(
    (e) => {
      if(!isEditing) {
        return
      }
      setDatePickerAnchorEl(e.currentTarget)
      setDatePickerVisibility(true)
    },
    []
  )

  const closeDatePicker = useCallback(() => {
    setDatePickerVisibility(false)
  }, [])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <If
        condition={datePickerVisibility}
        then={() => (
          <DatePicker
            anchorEl={datePickerAnchorEl}
            submitDate={_submitDate}
            closeDatePicker={closeDatePicker}
            selectedDays={_countdownDate}
          />
        )}
      />
      <S.WmCustomContainer>
        <S.DateContainer isEditing={isEditing}>
          <Mapper
            data={Object.keys(formatedDates)}
            render={(formatedDate, idx) => {
              return (
                <React.Fragment key={idx}>
                  <S.DateWrap className="formatedDate">
                    <S.Time onClick={handleDatePickerOpen}>
                      <p className="WM_GLOBAL_primary-font">
                        {formatedDates[formatedDate]}
                      </p>
                    </S.Time>
                    <EditableContent
                      text={isMobile ? dateLabels[idx][0] : dateLabels[idx]}
                      as={S.Date}
                      maxCount={validation.dateLabelsMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(newDateLabel: string) => {
                        Actions.changeDateLabel(newDateLabel, idx)
                      }}
                      areControlsHidden
                    />
                  </S.DateWrap>
                  <If
                    condition={idx < 3}
                    then={() => (
                      <S.TimeDotsWrap className="time-dots">
                        <S.TimeDots>:</S.TimeDots>
                      </S.TimeDotsWrap>
                    )}
                  />
                </React.Fragment>
              )
            }}
          />
        </S.DateContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Countdown1
