import styled from 'styled-components'
import THEME from './NoHeader.theme'
import { styleProvider } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  position: relative;
  background-color: ${getStyleForProp('background')};
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
  height: ${({ topOffset, isEditing }) =>
    isEditing ? 'auto' : `${topOffset}px`};

  .header-nav-bar {
    z-index: ${({ isEditing }) => (isEditing ? 21 : 19)};
  }
`

export default { Container, WmCustomContainer }
