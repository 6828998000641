/* @flow */
import React, { useContext, memo } from 'react'
import S from './PrivacyPolicy2.styled'
import ActionGetter from './Actions'
import { validation } from './PrivacyPolicy2.consts'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { translate } from '@editor/common/utils/translations'

const PrivacyPolicy2 = memo(
  ({
    data: {
      title,
      titleAlignment,
      additionalBlockTitle,
      additionalBlockParagraph,
      additionalBlockParagraphAlignment,
      blocks,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)

    return (
      <S.StyledContainer isEditing={isEditing}>
        <S.TitleContainer
          backgroundImgUrl={backgroundImgUrl}
          backgroundImgDimensions={backgroundImgDimensions}
          bgOverlayTransparency={bgOverlayTransparency}
          withBackground={backgroundImgUrl}
        >
          <EditableContent
            as={S.Title}
            alignment={titleAlignment}
            text={title}
            maxCount={validation.title}
            required
            className="WM_GLOBAL_heading54"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
        </S.TitleContainer>
        <S.WMCustomContainer>
          <Show when={[additionalBlockTitle, additionalBlockParagraph]}>
            <S.AdditionalBlockWrapper>
              <EditableContent
                text={additionalBlockParagraph}
                alignment={additionalBlockParagraphAlignment}
                as={S.AdditionalBlockParagraph}
                maxCount={validation.additionalBlockParagraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeAdditionalBlockParagraph}
                changeAlignment={Actions.changeAdditionBlockParagraphAlignment}
              />
            </S.AdditionalBlockWrapper>
          </Show>
          <S.BlocksContainer>
            <Mapper
              data={blocks}
              render={(block: string, idx: number) => {
                return (
                  <Show when={[block.title, block.paragraph]}>
                    <S.BlockWrap>
                      <S.ControlContainer>
                        <ControlsWithModalOpener
                          actions={Actions.blockActions({
                            idx,
                            showRemove: blocks.length > validation.block.min
                          })}
                          alignment="right"
                        >
                          <S.Block isEditing={isEditing}>
                            <EditableContent
                              text={block.title}
                              as={S.BlockTitle}
                              alignment={block.blockTitleAlignment}
                              required={!block.paragraph}
                              maxCount={validation.blockTitleMaxChar}
                              className="WM_GLOBAL_heading32"
                              onChange={(newTitle: string) =>
                                Actions.changeBlockTitle(newTitle, idx)
                              }
                              changeAlignment={(alignment: string) =>
                                Actions.changeBlockTitleAlignment(
                                  alignment,
                                  idx
                                )
                              }
                            />
                            <EditableContent
                              text={block.paragraph}
                              as={S.BlockParagraph}
                              alignment={block.blockParagraphAlignment}
                              maxCount={validation.blockParagraphMaxChar}
                              required={!block.title}
                              className="WM_GLOBAL_paragraph18"
                              onChange={(newParagraph: string) =>
                                Actions.changeBlockParagraph(newParagraph, idx)
                              }
                              changeAlignment={(alignment: string) =>
                                Actions.changeBlockParagraphAlignment(
                                  alignment,
                                  idx
                                )
                              }
                            />
                            {block.list.length ? (
                              <Mapper
                                data={block.list}
                                render={(
                                  listItem: string,
                                  listItemIdx: number
                                ) => {
                                  return (
                                    <Show when={[listItem]}>
                                      <S.ListItemWrap isEditing={isEditing}>
                                        <ControlsWithModalOpener
                                          actions={Actions.listItemActions(
                                            idx,
                                            listItemIdx,
                                            blocks[idx].list.length >
                                              validation.listItem.min
                                          )}
                                          alignment="right"
                                        >
                                          <EditableContent
                                            text={listItem}
                                            as={S.ListItemParagraph}
                                            maxCount={
                                              validation.blockParagraphMaxChar
                                            }
                                            className="WM_GLOBAL_paragraph18"
                                            onChange={(newListItem: string) =>
                                              Actions.changeListItem(
                                                newListItem,
                                                idx,
                                                listItemIdx
                                              )
                                            }
                                          />
                                        </ControlsWithModalOpener>
                                      </S.ListItemWrap>
                                    </Show>
                                  )
                                }}
                              />
                            ) : null}
                          </S.Block>
                        </ControlsWithModalOpener>
                      </S.ControlContainer>
                      <S.AddButtonsWrapper>
                        <AddButton
                          onAdd={() => Actions.addBlock(idx)}
                          toShow={blocks.length < validation.block.max}
                          style={{
                            margin: '20px 30px 15px 0'
                          }}
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_block_label')}
                        </AddButton>
                        <AddButton
                          onAdd={() => Actions.addListItem(idx)}
                          toShow={block.list.length < validation.listItem.max}
                          style={{
                            margin: '20px 0 15px 0'
                          }}
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_list_label')}
                        </AddButton>
                      </S.AddButtonsWrapper>
                    </S.BlockWrap>
                  </Show>
                )
              }}
            />
          </S.BlocksContainer>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default PrivacyPolicy2
