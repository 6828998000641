// @flow

import styled from 'styled-components'

import { getTextEllipsisStyles } from '../../styled'

export const MessageText = styled.p`
  white-space: pre-wrap;
  font-size: 14px;
  color: #111111;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
`

export const MessageWrapper = styled.div`
  max-width: 70%;
  border-radius: 2px 10px 10px;
  padding: 7px 14px;
  box-sizing: border-box;
  background: white;
`

export const AvatarWrapper = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 10px;
  .image-state-overlay {
    border-radius: 50%;
  }
`

export const MessageWithAvatar = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  & > .time {
    text-align: center;
    color: #949494;
    font-size: 10px;
    margin-bottom: 15px;
  }

  & > div {
    display: flex;
    width: 100%;
    flex-shrink: 0;
  }
  &.facebook-message {
    ${MessageWrapper} {
      background: #f1f0f0;
    }
  }
`

export const MessageWithName = styled.div`
  display: flex;
  flex-shrink: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`

export const MessageWithNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 7px 14px 6px;
  box-sizing: border-box;
  position: relative;
  background: white;
  border-radius: 0px 8px 8px;
  & > h5 {
    ${getTextEllipsisStyles};
    font-size: 13px;
    font-weight: 500;
    color: #999999;
    margin: 10px 0;
  }
  & > :before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid white;
    border-radius: 3px 0 0;
  }
  & > .time {
    text-align: right;
    margin-top: 5px;
    font-size: 12px;
    color: #949494;
    margin-right: -4px;
  }
`
