/* @flow */
import React, { useContext, memo } from 'react'
import S from './Basic4.styled'
import { validation } from './Basic4.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'

const Basic4 = memo(
  ({
    data: {
      title,
      titleAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WMCustomContainer>
          <EditableContent
            required
            as={S.Title}
            text={title}
            isUnderlineHidden
            alignment={titleAlignment}
            maxCount={validation.maxTitleChar}
            className="WM_GLOBAL_heading72"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Basic4
