/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Feature26.styled'
import { validation } from './Feature26.consts'
import ActionGetter from './Actions'
import MorphRight from './morphs/Feature26_morph_right'
import MorphLeft from './morphs/Feature26_morph_left'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Show from '@website/common/components/Show/Show'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Feature26 = memo(
  ({
    data: { title, paragraph, titleAlignment, paragraphAlignment, blocks }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const { isEditing } = useContext(EditingContext)
    const [hoveredBlockIdx, setHoveredBlockIdx] = useState(null)

    const Actions = ActionGetter(dispatcher)
    return (
      <S.StyledContainer>
        <S.MorphLeftWrapper>
          <MorphLeft />
        </S.MorphLeftWrapper>
        <S.MorphRightWrapper>
          <MorphRight />
        </S.MorphRightWrapper>
        <S.WmCustomContainer isEditing={isEditing}>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.titleMaxChar}
            onChange={Actions.changeTitle}
            className="WM_GLOBAL_heading42"
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Description}
            maxCount={validation.paragraphMaxChar}
            onChange={Actions.changeParagraph}
            className="WM_GLOBAL_paragraph18"
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <S.BlocksWrapper isFourBlock={blocks.length === 4}>
            <Mapper
              data={blocks}
              render={(block: string, idx: number) => (
                <Show
                  key={idx}
                  idx={idx}
                  isEditing={isEditing}
                  when={[
                    block.title,
                    block.additionalUrl,
                    block.additionalText
                  ]}
                >
                  <S.ControlContainer idx={idx} order={blocks.length - idx} isEditing={isEditing}>
                    <ControlsWithModalOpener
                      actions={Actions.blockActions({
                        idx,
                        showRemove: blocks.length > validation.blocks.min
                      })}
                      style={{ height: '100%', width: '100%' }}
                    >
                      <S.Block key={idx} isHover={hoveredBlockIdx === idx}>
                        <S.BlockItemWrap>
                          <S.IconContainer>
                            <ControlsWithModalOpener
                              actions={Actions.iconActions(idx)}
                              style={{
                                height: 'auto'
                              }}
                            >
                              <S.IconWrap
                                isEditing={isEditing}
                                className="icon_wrap"
                              >
                                <Icon name={block.iconName} />
                              </S.IconWrap>
                            </ControlsWithModalOpener>
                          </S.IconContainer>
                          <S.BlockWrap>
                            <EditableContent
                              text={block.title}
                              as={S.BlockTitle}
                              alignment={block.blockTitleAlignment}
                              required={!(block.additionalText && block.additionalUrl)}
                              maxCount={validation.blockTitleMaxChar}
                              onChange={(newTitle: string) =>
                                Actions.changeBlockTitle(newTitle, idx)
                              }
                              className="WM_GLOBAL_heading24"
                              changeAlignment={(alignment: string) =>
                                Actions.changeBlockTitleAlignment(
                                  alignment,
                                  idx
                                )
                              }
                            />
                            <If
                              condition={
                                block.additionalText && block.additionalUrl
                              }
                              otherwise={() => (
                                <AddButton
                                  onAdd={() => Actions.addLink(idx)}
                                  toShow={block.additionalText === ''}
                                >
                                  <Icon
                                    name="glyph-add"
                                    className="icon"
                                    size="normal"
                                  />
                                  {translate('add_link_label')}
                                </AddButton>
                              )}
                              then={() => (
                                <S.AnchorWrapper
                                  isHover={hoveredBlockIdx === idx}
                                >
                                  <ControlsWithModalOpener
                                    actions={Actions.linkActions({
                                      isAdditionalLink: true,
                                      linkIdx: idx,
                                      showRemove: block.title,
                                      linkData: {
                                        text: block.additionalText,
                                        url: block.additionalUrl
                                      }
                                    })}
                                  >
                                    <LinkWrapper
                                      url={block.additionalUrl}
                                      text={block.additionalText}
                                      linkProps={{
                                        className:
                                          'WM_GLOBAL_secondary-font blockText learn-more-link',
                                        onMouseOver: () => {
                                          setHoveredBlockIdx(idx)
                                        },
                                        onMouseLeave: () => {
                                          setHoveredBlockIdx(null)
                                        }
                                      }}
                                    >
                                      <Icon
                                        size="large"
                                        name="keyboard_arrow_right"
                                        className="arrow-right"
                                      />
                                    </LinkWrapper>
                                  </ControlsWithModalOpener>
                                </S.AnchorWrapper>
                              )}
                            />
                          </S.BlockWrap>
                        </S.BlockItemWrap>
                      </S.Block>
                    </ControlsWithModalOpener>
                  </S.ControlContainer>
                </Show>
              )}
            />
          </S.BlocksWrapper>
        </S.WmCustomContainer>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={blocks.length < validation.blocks.max}
          style={{
            marginTop: '2rem',
            position: 'relative',
            zIndex: 4
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_item_label')}
        </AddButton>
      </S.StyledContainer>
    )
  }
)

Feature26.contextType = DispatchContext

export default Feature26
