/* @flow */
import React, { useContext } from 'react'
import S from './Slider6.styled'
import { EditingContext } from '@contexts'

const SlideCounts = ({ activeSlideIdx, slidesCount }) => {
  const { isEditing } = useContext(EditingContext)

  if (!isEditing || !activeSlideIdx || !slidesCount) {
    return null
  }

  return (
    <S.SlideCountContainer>
      Slide {activeSlideIdx} of {slidesCount}
    </S.SlideCountContainer>
  )
}

export default SlideCounts
