import styled, { css } from 'styled-components'

const redColor = '#eb0c00'

const ErrorMessage = styled.p`
  position: absolute;
  bottom: -18px;
  color: ${redColor};
  > svg {
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }
`

const Asterisk = styled.span`
  font-size: 14px;
  padding-left: 5px;
`

const CheckboxListWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 30px;
  ${({ isError }) =>
    isError
      ? css`
          label {
            &:before {
              border-color: ${redColor};
            }
          }
          .asterisk {
            color: ${redColor};
          }
        `
      : css``};
  ${ErrorMessage} {
    font-size: 12px;
    font-weight: 400;
  }
  + .rsvp-input {
    margin-top: 30px;
  }
`

const CheckboxTitle = styled.h4`
  font-weight: 400 !important;
  word-break: break-word;
  margin-bottom: 0;
`

const CheckboxTitleWrap = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
`

const CheckboxItemWrap = styled.div`
  display: flex;
  column-gap: 30px;
  flex-wrap: wrap;
  margin-bottom: -15px;

  @media only screen and (min-width: 590px) {
    ${({ hasOnlyFourItems }) =>
      hasOnlyFourItems
        ? css`
            max-width: 350px;
          `
        : ''}
  }

  @media only screen and (max-width: 457px) {
    flex-direction: column;
  }
`

const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

export default {
  CheckboxListWrap,
  CheckboxItemWrap,
  CheckboxTitleWrap,
  Asterisk,
  CheckboxTitle,
  ErrorMessage,
  ErrorIcon
}
