/* @flow */

export const validation = {
  titleMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 90,
  blockParagraphMaxChar: 800,
  actions: {
    min: 1,
    max: 3
  },
  buttons: {
    min: 0,
    max: 2
  }
}
