/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text18.styled'
import Show from '@website/common/components/Show/Show'
import { validation } from './Text18.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const Text18 = memo(props => {
  const {
    data: { title,titleTextGradient, paragraph, content, images, titleAlignment }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      isBgImage={images.bgImage.imageUrl}
      isCenterImage={images.centerImage.imageUrl}
    >
      {images.bgImage.imageUrl ? (
        <S.ImageWrap>
          <S.ImageFigureBG>
            <ControlsWithImageCropper
              actions={Actions.imgActions('bgImage')}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                alt="Section img"
                defaultImgSrc={images.bgImage.imageUrl}
                asProps={{ imgCropParams: images.bgImage.imgCropParams }}
                sizes={images.bgImage.imgDimensions}
              />
            </ControlsWithImageCropper>
          </S.ImageFigureBG>
        </S.ImageWrap>
      ) : (
        <AddButton toShow onAdd={() => Actions.addImage('bgImage')}>
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_image_label')}
        </AddButton>
      )}
      {images.centerImage.imageUrl ? (
        <S.ImageWrap
          className="avatarImage"
          isBgImage={images.bgImage.imageUrl}
          isEditing={isEditing}
        >
          <S.ImageFigure hasPrevImage={!!images.bgImage.imageUrl}>
            <ControlsWithImageCropper
              actions={Actions.imgActions('centerImage')}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                alt="Section img"
                defaultImgSrc={images.centerImage.imageUrl}
                asProps={{ imgCropParams: images.centerImage.imgCropParams }}
                sizes={images.centerImage.imgDimensions}
              />
            </ControlsWithImageCropper>
          </S.ImageFigure>
        </S.ImageWrap>
      ) : (
        <AddButton toShow onAdd={() => Actions.addImage('centerImage')}>
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_image_label')}
        </AddButton>
      )}
      <S.WmCustomContainer>
        <Show when={[title]}>
          <S.Content isEditing={isEditing} isParagraphExists={paragraph}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              isTextGradient={titleTextGradient}
              isGradientControlHidden={false}
              as={S.Title}
              data-text={title}
              hasAlignment={true}
              maxCount={validation.maxTitleChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading"
              changeAlignment={Actions.changeTitleAlignment}
              changeTextGradientStatus={Actions.changeTitleGradientStatus}
            />
          </S.Content>
        </Show>
        <S.ContentWrapper>
          {content.map((item: string, idx: number) => (
            <S.TextItemWrap key={idx}>
              <S.TextItem>
                <EditableContent
                  as={S.BlockTitle}
                  required={!item.paragraph}
                  text={item.title}
                  alignment={item.titleAlignment}
                  maxCount={validation.contentTitleMaxChar}
                  className="WM_GLOBAL_primary-font WM_GLOBAL_heading20"
                  onChange={(newTitle: string) => {
                    Actions.changeBlockTitle(newTitle, idx)
                  }}
                  changeAlignment={(alignment: string) =>
                    Actions.changeBlockTitleAlignment(alignment, idx)
                  }
                />
                <EditableContent
                  text={item.paragraph}
                  as={S.BlockParagraph}
                  isEditing={isEditing}
                  required={!item.title}
                  alignment={item.paragraphAlignment}
                  maxCount={validation.contentParagraphMaxChar}
                  className="WM_GLOBAL_secondary-font WM_GLOBAL_paragraph18"
                  onChange={(newParagraph: string) => {
                    Actions.changeBlockParagraph(newParagraph, idx)
                  }}
                  changeAlignment={(alignment: string) =>
                    Actions.changeBlockParagraphAlignment(alignment, idx)
                  }
                />
                <AddButton
                  onAdd={() => Actions.removeBlock(idx)}
                  toShow={content.length > validation.content.min}
                  style={{ marginTop: '50px' }}
                >
                  <Icon name="glyph-remove" className="icon" size="normal" />
                  {translate('remove_block_label')}
                </AddButton>
              </S.TextItem>
            </S.TextItemWrap>
          ))}
        </S.ContentWrapper>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={content.length < validation.content.max}
          style={{ marginTop: '50px' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text18
