// @flow

import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { useHistoryWithSearch } from '@hooks'
import { translate } from '@editor/common/utils/translations'

import PageItem from './PageItem'
import PageAddingPopup from '../PageAddingPopup'
import * as Styled from './styled'
import type { TContentProps } from './types'

import {
  removePage,
  duplicatePage,
  createNewPage
} from '@redux/actions/project'
import { selectAllPagePaths } from '@redux/selectors'
import { generateDuplicatedPagePath } from '../utils'
import DeletePopup from './components/DeletePopup'
import { EDITOR_BASE, PAGE_BASE } from '@editor/conf/routes'

const mapStateToProps = state => ({
  pagePaths: selectAllPagePaths(state)
})

const mapDispatchToProps = {
  removePage,
  duplicatePage,
  createNewPage
}

const Content = ({
  pagePaths,
  removePage,
  duplicatePage,
  createNewPage,
  closePagesFlyOut
}: TContentProps) => {
  const routeMatch = useRouteMatch(PAGE_BASE)
  const page = routeMatch ? routeMatch.params.page || '' : '~'
  const baseUrl = useRouteMatch(EDITOR_BASE).url
  const urlToPages = `${baseUrl}/edit`
  const history = useHistoryWithSearch()

  const [isPopupOpen, setPopupState] = useState(false)
  const [pageToDelete, setPageToDelete] = useState('')
  const [isDeletePopupOpen, setDeletePopupState] = useState(false)

  const pagePathFromRoute = `/${page}`

  const openDeletePopup = useCallback((path: string) => {
    setDeletePopupState(true)
    setPageToDelete(path)
  }, [])

  const closeDeletePopup = useCallback(() => {
    setDeletePopupState(false)
    setPageToDelete('')
  }, [])

  const handlePageRemove = useCallback(
    (path: string): void => {
      // if removing active page
      if (path === pagePathFromRoute) {
        const currentPageIdx = pagePaths.indexOf(pagePathFromRoute)
        const prevPagePath = pagePaths[currentPageIdx - 1]

        history.replace(`${urlToPages}${prevPagePath}`)
      }

      removePage(path)
    },
    [removePage, pagePaths, pagePathFromRoute, history]
  )

  const handlePageDuplicate = useCallback(
    (path: string): void => {
      const newPagePath = generateDuplicatedPagePath(pagePaths, path)
      duplicatePage(path, newPagePath)
      history.push(`${urlToPages}${newPagePath}/`)
    },
    [pagePaths, duplicatePage, history, urlToPages]
  )

  const handlePageClick = useCallback(
    (path: string): void => {
      history.push(`${urlToPages}${path}`)
    },
    [history, urlToPages]
  )

  const handleAddClick = useCallback(() => {
    setPopupState(true)
  }, [])

  const handlePageAddingPopupClose = useCallback(() => {
    setPopupState(false)
  }, [])

  const navigateToSettings = useCallback(
    (path: string) => {
      closePagesFlyOut()
      const pathFormatted = path === '/' ? '/home' : path
      history.push(`${baseUrl}/edit${pathFormatted}/page-settings`)
    },
    [closePagesFlyOut]
  )

  const navigateToNewPage = useCallback(
    (pageName: string) => {
      handlePageAddingPopupClose()
      history.push(`${urlToPages}/${pageName}/`)
    },
    [history, urlToPages, handlePageAddingPopupClose]
  )

  const handleSave = useCallback(
    (pageName: string) => {
      const cb = () => navigateToNewPage(pageName)
      createNewPage(pageName, cb)
    },
    [navigateToNewPage]
  )

  return (
    <Styled.Container id="pages-dropdown-container">
      <Styled.Header>{translate('pages_label')}</Styled.Header>
      <Styled.Content>
        <Styled.Pages>
          {pagePaths.map(path => (
            <PageItem
              path={path}
              isActive={path === pagePathFromRoute}
              removePage={openDeletePopup}
              duplicatePage={handlePageDuplicate}
              onClick={handlePageClick}
              navigateToSettings={navigateToSettings}
            />
          ))}
        </Styled.Pages>
      </Styled.Content>
      <Styled.Footer>
        <Styled.AddButton role="button" onClick={handleAddClick}>
          <span>
            <b>+</b> {translate('add_new')}
          </span>
        </Styled.AddButton>
        {isPopupOpen ? (
          <PageAddingPopup
            onSave={handleSave}
            onClose={handlePageAddingPopupClose}
            pagePaths={pagePaths}
          />
        ) : null}
        <DeletePopup
          isOpen={isDeletePopupOpen}
          pagePath={pageToDelete}
          handleDelete={handlePageRemove}
          onClose={closeDeletePopup}
        />
      </Styled.Footer>
    </Styled.Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Content)
