/* @flow */
import styled from 'styled-components'
import { styleProvider } from '@website/common/utils'
import THEME from '../CTA13.theme'

const getStyleForProp = styleProvider(THEME)

export const MorphRightWrapper = styled.div`
  width: 44%;
  position: absolute;
  z-index: 4;
  top: -26%;
  right: -21%;
  @media only screen and (max-width: 450px) {
    top: -46%;
  }
  svg {
    width: 100%;
  }
  .a {
    fill: ${getStyleForProp('imageSecondaryColor')};
  }
  .b {
    fill: ${getStyleForProp('imagePrimaryColor')};
  }
  .c {
    fill: ${getStyleForProp('imageTertiaryColor')};
  }
`
