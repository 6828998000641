/* @flow */
import React, { useContext, memo } from 'react'
import { validation } from './Portfolio3.consts'
import S from './Portfolio3.styled'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import ActionGetter from './Actions'
import DotsSVG from '@website/common/components/Dots'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'

const Portfolio3 = memo(props => {
  const {
    data: { title, paragraph, videoUrl, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WMCustomContainer>
        <S.HeadingWrap>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.maxTitleChar}
            className="WM_GLOBAL_heading"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Description}
            maxCount={validation.maxParagraphChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.HeadingWrap>
        <S.VideoContainer>
          <S.Dots className="right">
            <DotsSVG />
          </S.Dots>
          <S.Dots className="left">
            <DotsSVG />
          </S.Dots>
          <S.SquareBox className="right" />
          <S.SquareBox className="left" />
          <ControlsWithModalOpener
            actions={Actions.videoActions(false, videoUrl)}
            style={{ width: '100%' }}
          >
            <S.VideoContent>
              <S.VideoWrap>
                <Overlay />
                <LazyVideoPlayer
                  key={videoUrl}
                  className="iframe-video"
                  controls
                  width="100%"
                  height="100%"
                  url={videoUrl}
                />
              </S.VideoWrap>
            </S.VideoContent>
          </ControlsWithModalOpener>
        </S.VideoContainer>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Portfolio3
