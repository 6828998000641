/* @flow */
import React, { memo } from 'react'

const Wave = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="152" fill="none">
    <path
      d="M1218.8 72.1551C995.538 54.7924 355.726 12.6588 0 0V342H2500V115.195C2206.18 118.571 1479.25 92.4091 1218.8 72.1551Z"
      fill="white"
    />
  </svg>
)

export default memo(Wave)
