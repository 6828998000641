// @flow

import React, { useEffect, useCallback, useState, memo } from 'react'
import ReactDOM from 'react-dom'
import { useBackdropClick } from '@hooks'
import * as Styled from './styled'

import type { TSlideUpProps } from './types'

const SlideLeft = ({
  isOpen: isOpenFromProps,
  className = '',
  children,
  onClose,
  backdropDisablingSelectors = []
}: TSlideUpProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useBackdropClick(() => {
    setIsOpen(false)
  }, backdropDisablingSelectors)

  useEffect(() => {
    setIsOpen(isOpenFromProps)
  }, [isOpenFromProps])

  const onAnimationEnd = useCallback(() => {
    if (!isOpen && onClose) {
      onClose()
    }
  }, [isOpen])

  return isOpenFromProps
    ? ReactDOM.createPortal(
        <>
          <Styled.GlobalStyles />
          <Styled.SlideLeftContainer className={className}>
            <Styled.SlideLeft
              isOpen={isOpen}
              className={`${className}_content`}
              onAnimationEnd={onAnimationEnd}
              ref={ref}
            >
              {children}
            </Styled.SlideLeft>
          </Styled.SlideLeftContainer>
        </>,
        document.body
      )
    : null
}
export default memo(SlideLeft)
