// @flow

import React, { memo } from 'react'
import Image from '@website/common/components/Image'
import * as Styled from './styled'
import { Avatar } from '../styled'

const getCurrentTime = () => {
  const date = new Date()
  const hours = date.getHours()
  const minutes = date.getMinutes()
  return `${hours}:${minutes}`
}

export const Facebook = memo(({ text, avatar, imgCropParams, avatarSizes }) => (
  <Styled.MessageWithAvatar className="facebook-message">
    <p className="time">{getCurrentTime()}</p>
    <div>
      <Styled.AvatarWrapper>
        <Image
          as={Avatar}
          sizes={avatarSizes}
          asProps={{ imgCropParams }}
          defaultImgSrc={avatar}
          alt="Web page "
        />
      </Styled.AvatarWrapper>
      <Styled.MessageWrapper>
        <Styled.MessageText>{text}</Styled.MessageText>
      </Styled.MessageWrapper>
    </div>
  </Styled.MessageWithAvatar>
))

export const Whatsapp = memo(({ text, name }) => (
  <Styled.MessageWithName>
    <Styled.MessageWithNameWrapper>
      <h5>{name}</h5>
      <Styled.MessageText>{text}</Styled.MessageText>
      <p className="time">{getCurrentTime()}</p>
    </Styled.MessageWithNameWrapper>
  </Styled.MessageWithName>
))

export const Telegram = memo(({ text, name }) => (
  <Styled.MessageWithName>
    <Styled.MessageWithNameWrapper>
      <h5>{name}</h5>
      <Styled.MessageText>{text}</Styled.MessageText>
      <p className="time">{getCurrentTime()}</p>
    </Styled.MessageWithNameWrapper>
  </Styled.MessageWithName>
))

export const Viber = memo(({ text, avatar, imgCropParams, avatarSizes }) => (
  <Styled.MessageWithAvatar>
    <p className="time">{getCurrentTime()}</p>
    <div>
      <Styled.AvatarWrapper>
        <Image
          as={Avatar}
          sizes={avatarSizes}
          asProps={{ imgCropParams }}
          defaultImgSrc={avatar}
          alt="Web page "
        />
      </Styled.AvatarWrapper>
      <Styled.MessageWrapper>
        <Styled.MessageText>{text}</Styled.MessageText>
      </Styled.MessageWrapper>
    </div>
  </Styled.MessageWithAvatar>
))
