import { hexToRgb } from '@website/common/utils'

export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.8)`,
    contentGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.6)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.8)`,
    contentGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.6)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.9)`,
    contentGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.6)`
  }
}
