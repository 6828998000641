/* @flow */
import {
  buttonModalActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  addButton
} from '@website/components/CommonActions'

export default dispatcher => {
  const removeSlide = slideIdx => ({
    type: 'REMOVE_SLIDE',
    value: { slideIdx }
  })

  const addSlide = () => ({
    type: 'ADD_SLIDE'
  })

  return {
    changeTitle,
    changeParagraph,
    addButton,
    addSlide,
    removeSlide,
    getSliderActions: cropableImageModalActionMaker(dispatcher),
    getBtnActions: buttonModalActionMaker(dispatcher)
  }
}
