/* @flow */
import React, { useContext, memo } from 'react'
import S from './Pricing8.styled'
import { validation } from './Pricing8.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'
import Buttons from '@website/common/components/Buttons'

const Pricing8 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      blocks,
      buttons,
      paragraph,
      paragraphAlignment,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.HeaderContainer>
          <EditableContent
            text={title}
            as={S.Heading}
            required={!paragraph}
            alignment={titleAlignment}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            required={!title}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.HeaderContainer>
        <S.CardsContainer>
          <Mapper
            data={blocks}
            render={(block, blockIndex) => {
              return (
                <S.CardControlsWrapper key={blockIndex}>
                  <ControlsWithModalOpener
                    key={blockIndex}
                    actions={Actions.cardActions({
                      idx: blockIndex,
                      showRemove: blocks.length > validation.blocks.min
                    })}
                    style={{ width: '100%' }}
                  >
                    <S.PriceCard className="pricing-card">
                      <S.CardContent className="card-content">
                        <EditableContent
                          text={block.blockTitle}
                          as={S.Title}
                          alignment={block.blockTitleAlignment}
                          required={!block.blockDescription}
                          maxCount={validation.blockTitleMaxChar}
                          onChange={newTitle =>
                            Actions.changeBlockTitle(newTitle, blockIndex)
                          }
                          className="WM_GLOBAL_heading20"
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(
                              alignment,
                              blockIndex
                            )
                          }
                        />
                        <EditableContent
                          as={S.Description}
                          text={block.blockDescription}
                          alignment={block.blockDescriptionAlignment}
                          maxCount={validation.blockParagraphMaxChar}
                          required={!block.blockTitle}
                          onChange={newDescription =>
                            Actions.changeBlockDescription(
                              newDescription,
                              blockIndex
                            )
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockDescriptionAlignment(
                              alignment,
                              blockIndex
                            )
                          }
                          className="WM_GLOBAL_paragraph18"
                        />
                      </S.CardContent>
                      <S.PriceWrapper>
                        <EditableContent
                          as={S.Price}
                          alignment={block.blockPriceAlignment}
                          text={block.blockPrice}
                          maxCount={validation.priceMaxChar}
                          onChange={newPrice =>
                            Actions.changeBlockPrice(newPrice, blockIndex)
                          }
                          className="WM_GLOBAL_heading20"
                          changeAlignment={(alignment: string) =>
                            Actions.changePriceAlignment(alignment, blockIndex)
                          }
                        />
                      </S.PriceWrapper>
                      <S.Line />
                    </S.PriceCard>
                  </ControlsWithModalOpener>
                </S.CardControlsWrapper>
              )
            }}
          />
        </S.CardsContainer>
        <AddButton
          style={{ marginTop: '1rem' }}
          onAdd={Actions.addBlock}
          toShow={blocks.length < validation.blocks.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_item_label')}
        </AddButton>
        <Buttons data={buttons} />
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Pricing8
