/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.description = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_LIST_ITEM':
        const { idx, newItem } = action.value
        draft.features[idx] = newItem
        break
      case 'REMOVE_LIST_ITEM':
        draft.features.splice(action.value, 1)
        break
      case 'ADD_NEW_LIST_ITEM':
        draft.features.push(initialState.features[0])
        break
      case 'CHANGE_ICON':
        draft.icon = action.value.iconName
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({ ...initialState.buttons[0], type: btnType })
        } else {
          draft.buttons.push({ ...initialState.buttons[0], type: btnType })
        }
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
        break
      }
      case 'CHANGE_IMAGE':
        {
          const { url, dimensions } = action.value
          draft.mobileImgUrl = url
          draft.mobileImgDimensions = dimensions
          draft.imgCropParams = {}
        }
        break
      case 'CHANGE_MOCKUP_COLOR':
        draft.mockupColor === 'light'
          ? (draft.mockupColor = 'dark')
          : (draft.mockupColor = 'light')
        break
      case 'MAKE_MOCKUP_LIGHT':
        draft.mockupColor = 'light'
        break
      case 'MAKE_MOCKUP_DARK':
        draft.mockupColor = 'dark'
        break
    }
  })
}
