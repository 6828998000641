//@flow
import React, { useCallback, useContext, useRef, useState, memo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ControlModalsContext } from '@contexts'
import FlyOutMenu from './FlyOutMenu'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import { translate } from '@editor/common/utils/translations'
import FlyoutContent from '@renderforest/text-wizard-ai/lib'
import { projectContainerClassName } from '@website/conf/consts'
import { openLoginPopup } from '@actions'
import { selectIsUserAuthorized } from '@selectors'

import TextWizardIcon from './TextWizardIcon.svg'
import * as Styled from './styled'
import { TTextWizardAiControlProps } from './types'
import { DEFAULT_TEXT, MIN_TEXT_COUNT } from './consts'

const TextWizardAiControl = ({
  editableEl,
  text,
  maxCount,
  onChange
}: TTextWizardAiControlProps) => {
  const controlRef = useRef(null)
  const [isFlyoutOpened, setFlyoutOpenedState] = useState(false)
  const { setModalState } = useContext(ControlModalsContext)

  const isUserAuthorized = useSelector(selectIsUserAuthorized)
  const dispatch = useDispatch()

  const handleUseTextChange = useCallback(
    text => {
      onChange && onChange(text)
    },
    [onChange]
  )
  const isEmptyText = text === DEFAULT_TEXT

  const openTextWizardAiWindow = useCallback(assistantCommand => {
    const wizardParams = {
      isOpen: true,
      maxCount,
      handleUseTextChange
    }

    if (!isEmptyText) {
      wizardParams.text = text
      wizardParams.assistantCommand = assistantCommand
    }

    setModalState(MODAL_TYPES.textWizardAIWindow, wizardParams)
  }, [])

  const setDataAttributeToEditableEl = useCallback(() => {
    document
      .querySelector('[data-hovered="true"]')
      ?.removeAttribute('data-hovered')
    editableEl && editableEl.parentNode.setAttribute('data-hovered', 'true')
  }, [editableEl])

  const onClick = useCallback(
    e => {
      e.stopPropagation()
      if (isEmptyText) {
        if (!isUserAuthorized) {
          dispatch(openLoginPopup())
          return
        }
        setDataAttributeToEditableEl()
        openTextWizardAiWindow()
      } else {
        setFlyoutOpenedState(isFlyoutOpened => !isFlyoutOpened)
      }
    },
    [isEmptyText, isUserAuthorized, setDataAttributeToEditableEl]
  )

  const closeFlyout = useCallback(() => {
    setFlyoutOpenedState(false)
  }, [])

  const onFlyoutItemClick = useCallback(
    command => {
      if (!isUserAuthorized) {
        dispatch(openLoginPopup())
        return
      }
      setDataAttributeToEditableEl()
      openTextWizardAiWindow && openTextWizardAiWindow(command)
    },
    [openTextWizardAiWindow, isUserAuthorized, setDataAttributeToEditableEl]
  )

  const renderingContainer = document.querySelector(
    `.${projectContainerClassName}`
  )

  const isLessThanMinCount = text.length < MIN_TEXT_COUNT
  const isMoreThanMaxCount = maxCount <= text.length
  const tooltipText = isFlyoutOpened ? '' : translate('text_wizard_ai')

  return (
    <>
      <Styled.GlobalStyles />
      <TooltipWrapper text={tooltipText}>
        <Styled.Control
          ref={controlRef}
          onClick={onClick}
          className="text-wizard-ai-control"
        >
          <img src={TextWizardIcon} alt="text-wizard" />
        </Styled.Control>
      </TooltipWrapper>
      <FlyOutMenu
        isOpen={isFlyoutOpened}
        onClose={closeFlyout}
        renderingContainer={renderingContainer}
        backdropDisablingSelectors={['.text-wizard-ai-control']}
        className="text-wizard-ai-control-flyout"
        anchorEl={controlRef.current}
      >
        <FlyoutContent
          shouldOpenWindow
          isOpenChatVisible
          isEngagingVisible={false}
          isMakeShorterVisible={!isLessThanMinCount}
          isMakeLongerVisible={!isMoreThanMaxCount}
          onItemClick={onFlyoutItemClick}
        />
      </FlyOutMenu>
    </>
  )
}

export default memo(TextWizardAiControl)
