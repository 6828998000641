export const validation = {
  titleMaxChar: 50,
  paragraphMaxChar: 150,
  tabTitleMaxChar: 20,
  tabTitle: { required: true },
  galleryTab: {
    min: 2,
    max: 5
  },
  galleryRows: {
    min: 1,
    max: 6
  }
}
