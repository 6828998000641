import styled from 'styled-components'

export const TopContentContainer = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid #dee3f0;
  padding-bottom: 15px;

  .input-container {
    border-radius: 5px;

    & > input {
      height: 40px;
    }
  }
`

export const KeywordWrapper = styled.div`
  width: 50%;
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const SearchWrapper = styled.div`
  width: 50%;
  height: fit-content;
  padding: 10px 0;
  display: flex;
  justify-content: flex-start;

  .tooltip-wrapper {
    margin-right: 20px;
  }
`

export const KeywordLabel = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #252e48;
`

export const Keyword = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #252e48;
  margin: 0 10px;
  max-width: 415px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const PinIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c1c9e0;
  border-radius: 5px;
  box-sizing: border-box;

  svg * {
    fill: #545f7e;
  }

  :hover {
    cursor: pointer;
    color: #252e48;

    svg * {
      fill: #252e48;
    }
  }
`

export const TooltipContent = styled.p`
  width: 110px;
  text-align: center;
`
