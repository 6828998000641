import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'
import LibPopup from '@renderforest/rf-ui-library/organism/Popup'

export const Popup = styled(LibPopup)`
  & > div {
    width: 100%;
    max-width: 740px;
    max-height: 95vh;
    padding: 0;

    @media only screen and (max-height: 800px) {
      max-width: 620px;
    }
    & > div {
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    & > div {
      max-height: 90%;
      max-width: 90vw;
    }
  }
  .video-banner {
    width: 100%;
    margin-top: 30px;
    border-radius: 20px;
    display: block;
  }
`

export const Content = styled.div`
  width: 100%;
  position: relative;
  padding: 40px 0 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SliderContainer = styled.div`
  position: relative;
  padding: 0;
  width: 100%;
  .slick-arrow {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    top: 220px;
    z-index: 1;
    &:hover {
      &.slick-prev {
        & svg {
          transform: translateX(-3px);
        }
      }
      &.slick-next {
        & svg {
          transform: translateX(3px);
        }
      }
      & svg {
        fill: ${secondary.SemiDark};
      }
    }
    & svg {
      transition: all 100ms linear;
      fill: ${secondary.Normal};
      width: 25px;
      height: auto;
    }
  }
  .slick-prev {
    left: 10px;
  }
  .slick-next {
    right: 10px;
  }
  .slick-dots {
    li {
      margin: 0;
      & > button:before {
        opacity: 1;
        color: #8493bd;
        font-size: 55px;
      }
      &.slick-active > button:before {
        color: #3271e6;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .slick-arrow {
      display: none !important;
    }
  }
`

export const SlideWrapper = styled.div`
  box-sizing: border-box;
  padding: 0 40px;
  @media only screen and (max-width: 768px) {
    padding: 0 10px;
  }
`

export const Title = styled.h3`
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: ${secondary.ExtraDark};
  text-align: center;
  padding: 0 40px;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 31px;
    padding: 0 10px;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  margin-top: 30px;
  border-radius: 20px;
  aspect-ratio: 1.736;
`

export const SecondaryTitle = styled.h4`
  color: ${secondary.ExtraDark};
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  margin-top: 30px;
  text-align: center;
  white-space: pre-wrap;
  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 26px;
  }
`

export const Description = styled.p`
  max-width: 600px;
  font-size: 16px;
  line-height: 20px;
  color: ${secondary.ExtraDark};
  text-align: center;
  white-space: pre-wrap;
  margin: 20px auto 10px auto;
  @media only screen and (max-width: 768px) {
    padding: 0 6px;
  }
`
