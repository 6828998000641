/* @flow */
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeSubTitle,
  changeTitleAlignment,
  removeBlock
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'


export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeSubTitle = (newSubTitle: string) => {
    dispatcher(changeSubTitle(newSubTitle))
  }

  const changeParagraph1 = (newParagraph1: string) => {
    dispatcher ( {
      type:'CHANGE_PARAGRAPH1',
      value: newParagraph1
    })
  }
  
  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeSubtitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraph1Alignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH1_ALIGNMENT',
      value: alignment
    })
  }

  const addBlock = (idx) => {
    dispatcher({
      type: 'ADD_BLOCK',
      value: { idx }
    })
  }

  const addArrow = () => {
    dispatcher({
      type: 'ADD_ARROW'
    })
  }


  const imageActions = (
    idx: number,
    blockIdx: string,
    imageUrl: string,
    mediaFlyoutShift
  ) =>  {
    const keyForSizes = idx === 0 ? 'header52LargeImageSizes': 'header52SmallImageSizes'
    return ([
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes,
      showRemove: false,
      additionalArgs: { idx, blockIdx },
      imageUrl,
      mediaFlyoutShift
    })
  ])
}

  const removeArrow = [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        dispatcher({ type: 'REMOVE_ARROW' })
      },
      toShow: true
    }
  ]


  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeSubTitle: _changeSubTitle,
    changeSubtitleAlignment,
    changeParagraph1,
    changeParagraph1Alignment,
    addBlock,
    removeBlock,
    imageActions,
    blockActions: removeBlockActionMaker(dispatcher),
    addArrow,
    removeArrow,
  }
}
