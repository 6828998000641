// @flow

import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import { selectUserWithLoadingStatus, selectUserAvatar } from '@selectors'

const UserInfo = ({ userData, userAvatar }) => {
  const [isBeforeAtOverflowing, setBeforeAtOverflowingState] = useState(false)
  const beforeAtRef = useRef(null)

  const { firstName = '', email = '' } = userData?.data

  const beforeAt = email.substring(0, email.indexOf('@'))
  const afterAt = email.substring(email.indexOf('@'), email.length)

  useEffect(() => {
    const el = beforeAtRef.current

    if (!el) return false

    setBeforeAtOverflowingState(el.clientWidth < el.scrollWidth)
  }, [beforeAtRef])

  return (
    <Styled.Container>
      <Styled.Avatar $data={userAvatar} />
      <Styled.NameAndEmail>
        <h5>{firstName}</h5>
        <p>
          <p ref={beforeAtRef} className="beforeAt">
            {beforeAt}
          </p>
          <p className="afterAt">
            {isBeforeAtOverflowing ? '...' : null}
            {afterAt}
          </p>
        </p>
      </Styled.NameAndEmail>
    </Styled.Container>
  )
}

const mapStateToProps = state => ({
  userData: selectUserWithLoadingStatus(state),
  userAvatar: selectUserAvatar(state)
})

export default connect(mapStateToProps)(UserInfo)
