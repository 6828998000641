/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA1.styled'
import { validation } from './CTA1.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Buttons from '@website/common/components/Buttons'

const CTA1 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      titleTextGradient,
      buttons,
      paragraph,
      paragraphAlignment,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <Show when={[title, paragraph, ...buttons]}>
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer>
          <S.ContentWrapper>
            <EditableContent
              text={title}
              as={S.Title}
              isTextGradient={titleTextGradient}
              isGradientControlHidden={false}
              alignment={titleAlignment}
              required={!paragraph}
              maxCount={validation.titleMaxChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading42"
              changeAlignment={Actions.changeTitleAlignment}
              changeTextGradientStatus={Actions.changeTitleGradientStatus}
            />
            <EditableContent
              text={paragraph}
              as={S.Description}
              alignment={paragraphAlignment}
              required={!title}
              maxCount={validation.paragraphMaxChar}
              onChange={Actions.changeParagraph}
              className="WM_GLOBAL_paragraph18"
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <Buttons data={buttons} componentDispatcher={dispatcher} />
          </S.ContentWrapper>
        </S.WmCustomContainer>
      </S.StyledContainer>
    </Show>
  )
})

export default CTA1
