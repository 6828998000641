/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_TEXT_GRADIENT': {
        draft.titleTextGradient = action.value
        break
      }
      case 'CHANGE_LIST_ITEM':
        draft.features[action.value.idx].paragraph = action.value.newItem
        break
      case 'REMOVE_LIST_ITEM':
        draft.features.splice(action.value, 1)
        break
      case 'ADD_NEW_ITEM':
        draft.features.push(initialState.features[0])
        break
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions || {}
        draft.imgCropParams = {}
        break
      case 'CHANGE_ICON': {
        const { idx, iconName } = action.value
        draft.features[idx].icon = iconName
        break
      }
      case 'CHANGE_BUTTON_DATA':
        const { btnData } = action.value
        draft.buttonUrl = btnData.link
        draft.buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BUTTON':
        draft.buttonText = initialState.buttonText
        draft.buttonUrl = initialState.buttonUrl
        break
      case 'SET_IMG_CROP_PARAMS':
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
    }
  })
}
