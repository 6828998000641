/* @flow */
import React from 'react'
import Swipe from '../Swipe'
import S from './Slider3.styled'
import { validation } from './Slider3.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import DotsSVG from '@website/common/components/Dots'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, withEditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { withDispatchContext, withSiteId } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const ContainerWithEditingContext = withEditingContext(S.StyledContainer)

class Slider3 extends React.PureComponent<void, void> {
  renderDots = (isActive: boolean) => <S.Dot isActive={isActive} />
  renderLeftIcon = () => (
    <S.IconContainer>
      <Icon
        className="left"
        color="white"
        size="middle"
        name="arrow_small_left"
      />
    </S.IconContainer>
  )

  renderRightIcon = () => (
    <S.IconContainer>
      <Icon
        className="right"
        color="white"
        size="middle"
        name="arrow_small_right"
      />
    </S.IconContainer>
  )

  renderContent = Actions => (idx: number, stopAutoPlay: Function) => {
    const {
      data: { slides }
    } = this.props
    const showRemove = slides.length > validation.slides.min
    return (
      <S.FigureContainer>
        <S.SlideImgContainer>
          <S.ControlContainer>
            <ControlsWithImageCropper
              toPropagate={false}
              style={{ width: '100%', height: '100%' }}
              actions={Actions.getSliderActions({
                keyForSizes: 'slider3',
                showRemove,
                additionalArgs: { slideIdx: idx },
                clickHandlers: {
                  change: stopAutoPlay,
                  crop: stopAutoPlay
                },
                imageUrl: slides[idx].imageUrl
              })}
            >
              <Image
                as={S.Img}
                alt="Slider image"
                sizes={slides[idx].imgDimensions}
                defaultImgSrc={slides[idx].imageUrl}
                asProps={{
                  imgCropParams: slides[idx].imgCropParams
                }}
              />
            </ControlsWithImageCropper>
          </S.ControlContainer>
        </S.SlideImgContainer>
      </S.FigureContainer>
    )
  }

  render() {
    const {
      data: {
        slides,
        title,
        paragraph,
        buttons,
        titleAlignment,
        paragraphAlignment
      },
      componentDispatcher
    } = this.props
    const Actions = ActionGetter(componentDispatcher)

    return (
      <ContainerWithEditingContext isSingleSlide={slides.length === 1}>
        <S.WmCustomContainer>
          <Show when={[title]}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleChar}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
          </Show>
          <Show when={[paragraph]}>
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.maxParagraphChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </Show>
          <Buttons data={buttons} />
          <S.ImageSlideContainer>
            <Swipe
              dataArray={slides}
              renderDots={this.renderDots}
              renderContent={this.renderContent(Actions)}
              renderLeftIcon={this.renderLeftIcon}
              renderRightIcon={this.renderRightIcon}
            />
            <S.Dots className="right">
              <DotsSVG />
            </S.Dots>
            <S.Dots className="left">
              <DotsSVG />
            </S.Dots>
            <S.SquareBox className="right" />
            <S.SquareBox className="left" />
          </S.ImageSlideContainer>
          <AddButton
            style={{ marginTop: '2rem' }}
            onAdd={Actions.addSlide}
            toShow={slides.length < validation.slides.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_slide_label')}
          </AddButton>
        </S.WmCustomContainer>
      </ContainerWithEditingContext>
    )
  }
}

const Slider3WithContexts = withDispatchContext(withSiteId(Slider3))

export default Slider3WithContexts
