/* @flow */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Assets from '@editor/common/assets/start-creation'
import { addAnalyticsEvent } from '@editor/common/utils'
// import If from '@website/common/components/Conditional'
// import { primary } from '@website/common/styles/colors'
// import Modal from '@website/common/components/Modal'
// import Icon from '@website/common/components/Icon'
// import Text from '@website/common/components/Text'
import { useHistoryWithSearch } from '@hooks'
import * as S from './styled'
import { EDITOR_BASE } from '@editor/conf/routes'
import AICard from './AICard'
import { useResponsive } from '@shared/hooks'
import { dropHistory } from '@actions'

// const modalContent = () => (
//   <iframe
//     width="100%"
//     height="100%"
//     frameBorder="0"
//     title="Tutorial"
//     allowFullScreen
//     allow="autoplay; fullscreen"
//     src="https://player.vimeo.com/video/344336720"
//   />
// )

const StartCreation = () => {
  //const [showTutorial, setTutorialStatus] = useState(false)
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(EDITOR_BASE)
  const isSmallSizes = useResponsive(992)
  const dispatch = useDispatch()

  const addComponent = () => {
    addAnalyticsEvent('SiteMaker Blank', 'Add Component')

    history.replace(`${url}/start-creation/add-component/home/0`)
  }

  const templatePresetsClick = () => {
    addAnalyticsEvent('SiteMaker Blank', 'Load Preset')

    history.replace(`${url}/start-creation/choose-template`)
  }

  const aiWebsiteBuilderClick = () => {
    addAnalyticsEvent('SiteMaker Blank', 'AI generate website')

    history.push(`${url}/start-creation/ai`)
  }

  useEffect(() => {
    dispatch(dropHistory())
  }, [])

  return (
    <S.WMStartCreation className="wm-start-creation">
      <S.GlobalStyles />
      <S.CreationHeading>{translate('start_page_title')}</S.CreationHeading>
      <S.CreationParagraph>
        {translate('start_creation_sub_title')}
      </S.CreationParagraph>
      <S.ButtonsWrapper className="wm-start-btns">
        <S.CreationButtonsWrapper>
          <S.CreationButton
            id="start-from-scratch"
            tabIndex="0"
            role="button"
            onClick={addComponent}
          >
            <S.IconWrapper>
              <img src={Assets.PlusIcon} className="plus-icon" />
            </S.IconWrapper>
            <S.TextsWrapper>
              <S.ButtonTitle>
                {translate('start_from_scratch_title')}
              </S.ButtonTitle>
              <S.ButtonParagraph>
                {translate('start_from_scratch_subtitle')}
              </S.ButtonParagraph>
            </S.TextsWrapper>
          </S.CreationButton>
          <S.CreationButton
            id="start-with-template"
            tabIndex="0"
            role="button"
            onClick={templatePresetsClick}
          >
            <S.IconWrapper>
              <img src={Assets.PenIcon} className="pen-icon" />
              <S.Line className="line" />
            </S.IconWrapper>
            <S.TextsWrapper>
              <S.ButtonTitle>
                {translate('start_with_template_title')}
              </S.ButtonTitle>
              <S.ButtonParagraph>
                {translate('start_with_template_subtitle')}
              </S.ButtonParagraph>
            </S.TextsWrapper>
          </S.CreationButton>
        </S.CreationButtonsWrapper>
        <S.Divider>{translate('or_label')}</S.Divider>
        <S.AICreationButton
          id="start-with-ai"
          tabIndex="0"
          role="button"
          onClick={aiWebsiteBuilderClick}
        >
          {isSmallSizes ? <S.AIImage src={Assets.StartWithAi} /> : <AICard />}
          <S.TextsWrapper>
            <S.ButtonTitle>{translate('start_with_ai_title')}</S.ButtonTitle>
            <S.ButtonParagraph>
              {translate('start_with_ai_subtitle')}
            </S.ButtonParagraph>
          </S.TextsWrapper>
        </S.AICreationButton>
      </S.ButtonsWrapper>

      {/*<S.WMCreationTutorial className="wm-creation-tutorial">*/}
      {/*  <Text>*/}
      {/*    {translate('wonder_how_editor_works_label')}*/}
      {/*    <Text onClick={() => setTutorialStatus(true)}>*/}
      {/*      <Icon*/}
      {/*        name="play_circle_filled"*/}
      {/*        size="normal"*/}
      {/*        color={primary.Dark}*/}
      {/*        className="play_icon"*/}
      {/*      />*/}
      {/*      {translate('watch_a_tutorial_label')}*/}
      {/*    </Text>*/}
      {/*  </Text>*/}
      {/*</S.WMCreationTutorial>*/}
      {/*<If*/}
      {/*  condition={showTutorial}*/}
      {/*  then={() => (*/}
      {/*    <Modal*/}
      {/*      renderContent={modalContent}*/}
      {/*      wrapClassname="tutorial-modal"*/}
      {/*      onModalClose={() => setTutorialStatus(false)}*/}
      {/*    />*/}
      {/*  )}*/}
      {/*/>*/}
    </S.WMStartCreation>
  )
}

export default StartCreation
