/* @flow */
import React from 'react'

type TTransformationOption = {
  value: string,
  Comp: React.Element,
  props: Array<string>
}

type TTransformationOptions = Array<TTransformationOption>

export const replaceValueInText = (
  text: string,
  replacedText: string,
  replaceValue: string,
  Comp
) => {
  return text
    .replace(replacedText, `$${replacedText}$`)
    .split('$')
    .map((item, index) => {
      if (item.includes(replacedText)) {
        return Comp ? <Comp key={index}>{replaceValue}</Comp> : replaceValue
      }
      return item
    })
}

export const replaceVariableWithStyles = (
  text: string,
  replaceValue: HTMLSpanElement,
  styles: Object
) => {
  const replacedText = '%s'

  return text
    .replace(/%s/gi, `$${replacedText}$`)
    .split('$')
    .map((item, index) => {
      if (item.includes(replacedText)) {
        return (
          <span style={styles} key={index}>
            {replaceValue}
          </span>
        )
      }

      return item
    })
}

export const replaceTextWithLink = (
  text: string,
  replaceValue: HTMLSpanElement,
  link: string,
  styles: Object,
  blank?: boolean
) => {
  const replacedText = '%s'

  return text
    .replace(/%s/gi, `$${replacedText}$`)
    .split('$')
    .map((item, index) => {
      if (item.includes(replacedText)) {
        return (
          <a
            href={link}
            style={styles}
            key={index}
            target={blank ? '_blank' : '_self'}
            rel="noreferrer"
          >
            {replaceValue}
          </a>
        )
      }

      return item
    })
}

export const transformTextVariables = (
  text: string,
  options: TTransformationOptions
) => {
  if (!Array.isArray(options)) {
    return text
  }

  const splittedText = text.split('%s')

  const transformedContent = options.reduce(
    (acc, { value, Comp, props = {} }, i) => {
      const lastIndex = acc.length - 1
      const nextSplittedText = splittedText[i + 1]

      if (typeof acc[lastIndex] === 'string' && !Comp) {
        acc[lastIndex] += value + nextSplittedText
        return acc
      }

      if (Comp) {
        const wrappedValue = <Comp {...props}>{value}</Comp>
        acc.push(wrappedValue, nextSplittedText)
      } else {
        acc.push(`${value}${nextSplittedText}`)
      }

      return acc
    },
    [splittedText[0]]
  )

  return <>{transformedContent}</>
}

export const capitalizeFirstLetter = str => {
  if (typeof str !== 'string') {
    return ''
  }

  return str.replace(str.charAt(0), str.charAt(0).toUpperCase())
}
