/* @flow */
import React from 'react'
import Slider from 'react-slick'
import S from './Header37.styled'
import THEME from './Header37.theme'
import ActionGetter from './Actions'
import { slickSliderSettings, validation } from './Header37.consts'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import NavBar from '@website/common/components/NavBar'
import AddButton from '@website/common/components/AddButton'
import { getFormatedNumber } from '@website/common/utils'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import {
  EditingContext,
  DispatchContext,
  withEditingContext
} from '@contexts'
import '../Swipe/swipe-slider-styles.css'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import SocialLinks from '@website/common/components/SocialLinks'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const ContainerWithEditingContext = withEditingContext(S.Container)
class Header37 extends React.PureComponent<void, void> {
  dispatcher = this.context
  sliderRef: null
  state = { activeSlideIdx: 0, navBarHeight: 0 }

  handleHeightChange = height => {
    this.setState({ navBarHeight: height })
  }

  getPrevIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + slides.length - 1) % slides.length
  }
  getNextIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + 1) % slides.length
  }
  onPrevClick = () => {
    const { activeSlideIdx } = this.state
    this.changeActiveSlideIndex(this.getPrevIdx(activeSlideIdx))
    this.sliderRef.slickPrev()
  }

  onNextClick = () => {
    const { activeSlideIdx } = this.state
    this.changeActiveSlideIndex(this.getNextIdx(activeSlideIdx))
    this.sliderRef.slickNext()
  }

  changeActiveSlideIndex = (activeSlideIdx: number) =>
    this.setState({
      activeSlideIdx
    })

  getSliderCounter = () => {
    const { activeSlideIdx } = this.state
    const { slides } = this.props.data
    //start counter from 1
    const activeIdx = activeSlideIdx + 1
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.SliderIdx isEditing={isEditing}>
              <span className="active-slide-idx">
                {`${getFormatedNumber(activeIdx)}`}
              </span>
              <span className="slide-count">{`/${getFormatedNumber(
                slides.length
              )}`}</span>
            </S.SliderIdx>
          )
        }}
      </EditingContext.Consumer>
    )
  }

  renderSlideImg = Actions => (slide, idx) => {
    const { slides } = this.props.data
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => (
          <S.SliderItemWrapper isEditing={isEditing}>
            <S.ControlContainer>
              <ControlsWithImageCropper
                key={idx}
                toPropagate={false}
                actions={Actions.sliderActions({
                  showRemove: slides.length > 1,
                  idx,
                  imageUrl: slide.url
                })}
              >
                <Image
                  as={S.Img}
                  defaultImgSrc={slide.url}
                  sizes={slide.imgDimensions}
                  alt="Section image"
                  asProps={{ imgCropParams: slide.imgCropParams }}
                />
              </ControlsWithImageCropper>
            </S.ControlContainer>
          </S.SliderItemWrapper>
        )}
      </EditingContext.Consumer>
    )
  }

  renderSlider = Actions => {
    const { slides } = this.props.data
    return (
      <S.SliderWrapper className="image-wrap">
        <>
          <Slider
            {...slickSliderSettings}
            className="slider mySlider"
            ref={ref => (this.sliderRef = ref)}
            afterChange={activeSlideIdx => this.setState({ activeSlideIdx })}
          >
            {slides.map(this.renderSlideImg(Actions))}
          </Slider>
        </>
        {this.renderActionsBar(slides.length, () => Actions.addSlide())}
      </S.SliderWrapper>
    )
  }

  renderTextContent = Actions => {
    const {
      data: {
        subTitle,
        title,
        paragraph,
        buttons,
        socials,
        subTitleAlignment,
        titleAlignment,
        paragraphAlignment
      }
    } = this.props
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.HeaderContentWrapper>
              <S.HeaderContent isEditing={isEditing}>
                <EditableContent
                  text={subTitle}
                  as={S.SubTitle}
                  isEditing={isEditing}
                  alignment={subTitleAlignment}
                  maxCount={validation.subTitleMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={(newSubTitle: string) =>
                    this.dispatcher(Actions.changeSubTitle(newSubTitle))
                  }
                  changeAlignment={Actions.changeSubTitleAlignment}
                />
                <EditableContent
                  text={title}
                  as={S.Title}
                  alignment={titleAlignment}
                  required={validation.title.required}
                  maxCount={validation.headingMaxChar}
                  className="WM_GLOBAL_heading42"
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  isEditing={isEditing}
                  alignment={paragraphAlignment}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
                <Buttons data={buttons} />
                  <SocialLinks
                    className="header-socials"
                    socials={socials}
                    socialsMaxCount={validation.socials.max}
                  />
              </S.HeaderContent>
            </S.HeaderContentWrapper>
          )
        }}
      </EditingContext.Consumer>
    )
  }

  renderActionsBar = (slidesLength, addSlide) => {
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.SliderArrowsInfo isHidden={!isEditing && slidesLength === 1}>
              {this.getSliderCounter()}
              <AddButton
                onAdd={addSlide}
                toShow={slidesLength < validation.slides.max}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_slide_label')}
              </AddButton>
              <S.SliderActionButtons
                className="actions-bar"
                id={'slider-' + slidesLength}
              >
                <S.IconContainer
                  className="wm-slider-btn left"
                  onClick={this.onPrevClick}
                >
                  <Icon
                    color="white"
                    size={58}
                    mobileSize={36}
                    name="icon-arrow_small_left"
                  />
                </S.IconContainer>
                <S.IconContainer
                  className="wm-slider-btn right"
                  onClick={this.onNextClick}
                >
                  <Icon
                    color="white"
                    size={58}
                    mobileSize={36}
                    name="icon-arrow_small_right"
                  />
                </S.IconContainer>
              </S.SliderActionButtons>
            </S.SliderArrowsInfo>
          )
        }}
      </EditingContext.Consumer>
    )
  }

  render() {
    const {
      data: {
        logoSrc,
        logoColor,
        logoWidth,
        logoDimensions,
        newsTitle,
        newsParagraph,
        newsTitleAlignment,
        newsParagraphAlignment,
        imageUrl,
        imgCropParams,
        imgDimensions,
        additionalText,
        additionalUrl
      }
    } = this.props

    const Actions = ActionGetter(this.context)

    return (
      <ContainerWithEditingContext topOffset={this.state.navBarHeight}>
        <NavBar
          handleHeightChange={this.handleHeightChange}
          alwaysShowBackground
          theme={THEME}
          logoProps={{
            logoSrc: logoSrc,
            logoColor: logoColor,
            logoWidth: logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <S.WmCustomContainer className="header-37-container">
          {this.renderTextContent(Actions)}
          <S.SliderAndNewsContainer>
            {this.renderSlider(Actions)}
            <S.NewsContainer>
              <S.NewsBlock>
                <EditingContext.Consumer>
                  {({ isEditing }) => (
                    <EditableContent
                      text={newsTitle}
                      as={S.NewsTitle}
                      isEditing={isEditing}
                      alignment={newsTitleAlignment}
                      required={validation.newsTitle.required}
                      maxCount={validation.newsTitleMaxChar}
                      className="WM_GLOBAL_heading42"
                      onChange={Actions.changeNewsTitle}
                      changeAlignment={Actions.changeNewsTitleAlignment}
                    />
                  )}
                </EditingContext.Consumer>
                <EditableContent
                  text={newsParagraph}
                  as={S.NewsParagraph}
                  alignment={newsParagraphAlignment}
                  maxCount={validation.newsParagraphMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={Actions.changeNewsParagraph}
                  changeAlignment={Actions.changeNewsParagraphAlignment}
                />
                <AdditionalLink
                  additionalLinkText={additionalText}
                  additionalLinkUrl={additionalUrl}
                />
              </S.NewsBlock>
              <S.ImageWrap>
                <S.ImageFigure>
                  <ControlsWithImageCropper
                    actions={Actions.imgActions(imageUrl)}
                    style={{ width: '100%' }}
                  >
                    <Image
                      as={S.Image}
                      alt="news"
                      defaultImgSrc={imageUrl}
                      asProps={{ imgCropParams }}
                      sizes={imgDimensions}
                    />
                  </ControlsWithImageCropper>
                </S.ImageFigure>
              </S.ImageWrap>
            </S.NewsContainer>
          </S.SliderAndNewsContainer>
        </S.WmCustomContainer>
      </ContainerWithEditingContext>
    )
  }
}

Header37.contextType = DispatchContext

export default Header37
