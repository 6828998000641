export const PAYPAL_BUTTONS_SETTINGS = {
  yellow: {
    color: '#000000',
    background: '#ffc439',
    hoveredBackground: '#efb836',
    shadow: 'rgba(255, 196, 57, 0.5)',
    icon: 'dark'
  },
  grey: {
    color: '#000000',
    background: '#eeeeee',
    hoveredBackground: '#dddddd',
    shadow: 'rgba(0, 0, 0, 0.2)',
    icon: 'dark'
  },
  black: {
    color: '#ffffff',
    background: '#2c2e2f',
    hoveredBackground: '#3f4244',
    shadow: 'rgba(44, 46, 47, 0.5)',
    icon: 'light'
  },
  blue: {
    color: '#ffffff',
    background: '#009cde',
    hoveredBackground: '#02b3f2',
    shadow: 'rgba(0, 156, 222, 0.5)',
    icon: 'light'
  }
}
