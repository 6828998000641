/* @flow */
import styled, { css } from 'styled-components'
import { styleProvider } from '@website/common/utils'

const getContainerPadding = props =>
  props.isEditing && props.type === 'noheader' ? '0 20px' : '0 20px'

const NavbarWrapper = styled.div`
  width: 100%;
  background: ${({ showBackground, Theme, theme }) => {
    const getStyleForProp = styleProvider(Theme)
    return showBackground ? getStyleForProp('navbarBg')({ theme }) : ''
  }};
  padding-top: ${({ isEditing }) => (isEditing ? '120px' : '0')};
  @media only screen and (max-width: 768px) {
    padding-top: ${({ isEditing }) => (isEditing ? '120px' : '0')};
  }
  box-shadow: ${({ showShadow }) =>
    showShadow ? '0 0px 8px rgba(0, 0, 0, 0.1)' : 'none'};
  ${({ isFixed }) =>
    isFixed
      ? css`
          position: fixed !important;
          z-index: 9;
          top: 0;
          left: 0;
          right: 0;
        `
      : ''};

  .rf-add-btn {
    white-space: nowrap;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1500px;
  position: relative;
  padding: ${getContainerPadding};
  margin: 0 auto;
  z-index: 19;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  .lazy-figure {
    font-size: 0;
  }
  @media only screen and (max-width: 1440px) {
    width: 100%;
    max-width: 1200px;
  }
  @media only screen and (max-width: 1024px) {
    ${({ isLogoRemoved }) => isLogoRemoved && 'justify-content: flex-end'};
  }
`

const LogoContainer = styled.div`
  transition: padding 0.3s linear;
  padding: ${({ decreasePadding }) => (decreasePadding ? '10px 0' : '20px 0')};
  @media only screen and (max-width: 768px) {
    padding: 10px 0;
  }
  ${({ isEditing }) =>
    isEditing
      ? ''
      : css`
          .logo-image {
            transition: width 0.05s linear;
          }
        `}
  .failed-image-container {
    min-width: 120px;
    min-height: 80px;
  }
`

const LogoColorChooser = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px 0 10px;
  font-family: 'Montserrat', sans-serif;

  & > p {
    font-size: 14px;
    font-weight: 600;
    color: #545f7e;
    flex-shrink: 0;
    margin-right: 10px;
  }

  & > div {
    &:not(:last-child) {
      margin-right: 5px;
    }
  }
`

const LogoColorItem = styled.div`
  display: flex;
  align-items: center;
  & > img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-sizing: border-box;
    border: ${({ isSelected }) =>
      `1px solid ${isSelected ? '#c1c9e0' : 'transparent'}`};
  }

  &:hover {
    & > img {
      border: 1px solid #c1c9e0;
    }
  }
`

export default {
  NavbarWrapper,
  Container,
  LogoContainer,
  LogoColorItem,
  LogoColorChooser
}
