/* @flow */
import styled, {css} from 'styled-components'

import THEME from './Clients1.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};

  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : '80px 0')};
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const LogosWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -20px auto 0 auto;
  padding-top: 50px;
  @media only screen and (max-width: 1100px) {
    padding-top: 40px;
  }
`

const LogoFigure = styled.div`
  width: 24%;
  min-width: 210px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  .failed-image-container {
    width: 160px;
    height: 120px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ isEditing }) => (isEditing ? 'padding-top: 50px' : null)};
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  padding-top: 20px;
`

const Img = styled.img`
  width: 100%;
  height: auto;
  transition: all 0.1s linear;
  max-width: 160px;
  max-height: 120px;
  display: block;
  object-fit: contain;
  &:hover {
    opacity: 1;
  }
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}

`

export default {
  Img,
  StyledContainer,
  WmCustomContainer,
  TextContainer,
  LogosWrap,
  LogoFigure,
  Title,
  Description
}
