/* @flow */
import React, { useContext, memo } from 'react'
import Swipe from '../Swipe'
import ActionGetter from './Actions'
import S from './Testimonials1.styled'
import { validation } from './Testimonials1.consts'
import Image from '@website/common/components/Image'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import Arrow from '@editor/common/assets/svgr-icons/arrow_big_left.svg'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { translate } from '@editor/common/utils/translations'
import { openUrl, getExternalUrl } from '@website/common/utils'

const Testimonials1 = memo(props => {
  const {
    data: {
      slides,
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  const renderLeftIcon = () => (
    <TooltipWrapper text="Previous" color="#252E48" position="top">
      <S.SliderArrow isEditing={isEditing} className="wm-arrow left">
        <Arrow />
      </S.SliderArrow>
    </TooltipWrapper>
  )
  const renderRightIcon = () => (
    <TooltipWrapper text="Next" color="#252E48" position="top">
      <S.SliderArrow isRight isEditing={isEditing} className="wm-arrow right">
        <Arrow />
      </S.SliderArrow>
    </TooltipWrapper>
  )
  const renderDots = (isActive: boolean) => <S.Dot isActive={isActive} />
  const renderContent = (idx: number) => {
    const {
      data: { slides }
    } = props
    return (
      <S.SliderWrapper>
        <ControlsWithModalOpener
          actions={Actions.sliderActions({
            idx,
            showRemove: slides.length > validation.slides.min
          })}
          style={{ width: '100%' }}
        >
          <S.SlideContainer className="WM_GLOBAL_secondary-font">
            <If
              condition={slides[idx].imageUrl}
              then={() => (
                <ControlsWithModalOpener
                  toPropagate={false}
                  actions={Actions.getClientsActions({
                    showRemove: true,
                    logoIdx: idx,
                    keyForSizes: 'testimonial1LogoSizes',
                    imageUrl: slides[idx].imageUrl,
                    link: slides[idx].clientUrl
                  })}
                >
                  <S.ImageFigure>
                    <Image
                      as={S.Img}
                      alt="Slider image"
                      sizes={slides[idx].imgDimensions}
                      defaultImgSrc={slides[idx].imageUrl}
                      withLazy={false}
                      asProps={{
                        onClick: () =>
                          !!slides[idx].clientUrl &&
                          openUrl(getExternalUrl(slides[idx].clientUrl))
                      }}
                      hasUrl={!!slides[idx].clientUrl}
                    />
                  </S.ImageFigure>
                </ControlsWithModalOpener>
              )}
            />
            <AddButton
              onAdd={() => Actions.addSliderLogo(idx)}
              toShow={!slides[idx].imageUrl}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_image_label')}
            </AddButton>
            <S.TextContainer>
              <EditableContent
                as={S.SliderParagraph}
                maxCount={validation.maxSlideContentChar}
                text={slides[idx].slideContent}
                alignment={slides[idx].slideContentAlignment}
                onChange={(newContent: string) => {
                  Actions.changeSlideContent(newContent, idx)
                }}
                changeAlignment={(alignment: string) =>
                  Actions.changeSlideContentAlignment(alignment, idx)
                }
              />
            </S.TextContainer>
            <S.ClientWrap>
              <If
                condition={slides[idx].customerImgUrl}
                then={() => (
                  <S.ClientImageWrapper>
                    <ControlsWithModalOpener
                      actions={Actions.clientImageActions({
                        idx,
                        keyForSizes: 'testimonial1ClientImgSizes',
                        imageUrl: slides[idx].customerImgUrl
                      })}
                    >
                      <Image
                        as={S.ClientImg}
                        alt="Client avatar"
                        sizes={slides[idx].customarImgDimensions}
                        defaultImgSrc={slides[idx].customerImgUrl}
                        withLazy={false}
                      />
                    </ControlsWithModalOpener>
                  </S.ClientImageWrapper>
                )}
              />
              <AddButton
                onAdd={() => Actions.addClientImage(idx)}
                toShow={!slides[idx].customerImgUrl}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_image_label')}
              </AddButton>
              <S.ClientInfo>
                <EditableContent
                  as={S.ClientName}
                  maxCount={validation.maxCustomerNameChar}
                  alignment={slides[idx].clientNameAlignment}
                  isEditing={isEditing}
                  onChange={(newClientName: string) => {
                    Actions.changeClientName(newClientName, idx)
                  }}
                  text={slides[idx].customerName}
                  changeAlignment={(alignment: string) =>
                    Actions.changeClinetNameAlignment(alignment, idx)
                  }
                />
                <EditableContent
                  as={S.CLientPosition}
                  maxCount={validation.maxCustomerPositionChar}
                  alignment={slides[idx].clientPositionAlignment}
                  onChange={(newClientPosition: string) => {
                    Actions.changeClientPosition(newClientPosition, idx)
                  }}
                  text={slides[idx].customerPosition}
                  changeAlignment={(alignment: string) =>
                    Actions.changeClientPositionAlignment(alignment, idx)
                  }
                />
              </S.ClientInfo>
            </S.ClientWrap>
          </S.SlideContainer>
        </ControlsWithModalOpener>
      </S.SliderWrapper>
    )
  }

  return (
    <S.StyledContainer
      isEditing={isEditing}
      isTextsDeleted={!title && !paragraph}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          as={S.Title}
          alignment={titleAlignment}
          maxCount={validation.maxTitleChar}
          className="WM_GLOBAL_heading"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          as={S.Paragraph}
          alignment={paragraphAlignment}
          maxCount={validation.maxParagraphChar}
          className="WM_GLOBAL_paragraph"
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.ImageSlideContainer isEditing={isEditing}>
          <Swipe
            dataArray={slides}
            renderDots={renderDots}
            renderContent={renderContent}
            renderLeftIcon={renderLeftIcon}
            renderRightIcon={renderRightIcon}
            arrowControlClassName="slider-arrow-control"
          />
        </S.ImageSlideContainer>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={slides.length < validation.slides.max}
          style={{ marginTop: '2rem' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Testimonials1
