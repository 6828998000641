/* @flow */
import React, { useContext, useCallback, useState, memo } from 'react'
import S from './Header28.styled'
import THEME from './Header28.theme'
import { validation } from './Header28.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import SingleButton from '@website/common/components/SingleButton'
import SocialLinks from '@website/common/components/SocialLinks'

const Header28 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      backgroundImgDimensions,
      bgOverlayTransparency,
      btnText,
      socials,
      btnUrl
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const onlySocials = !(title || paragraph || btnText) && !isEditing
  const [navBarHeight, setNavBarHeight] = useState(0)
  const handleHeightChange = useCallback(height => {
    setNavBarHeight(height)
  }, [])
  return (
    <S.StyledContainer
      isEditing={isEditing}
      topOffset={navBarHeight}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <NavBar
        handleHeightChange={handleHeightChange}
        alwaysShowBackground
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <Show when={[title, paragraph, btnText, socials.length > 0]}>
        <S.HeaderContent onlySocials={onlySocials} isEditing={isEditing}>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <SingleButton btnUrl={btnUrl} btnText={btnText} />
          <SocialLinks
            className="header-socials"
            socials={socials}
            componentDispatcher={dispatcher}
            socialsMaxCount={validation.socials.max}
          />
        </S.HeaderContent>
      </Show>
    </S.StyledContainer>
  )
})

export default Header28
