import styled, { createGlobalStyle } from 'styled-components'
import { accentRed } from '@website/common/styles/colors'

export const GlobalStyles = createGlobalStyle`
  .broken-link-tooltip  {
    margin-left: 3px;
  }
`

export const WarningText = styled.p`
  color: ${accentRed};
  font-size: 14px;
  text-align: center;
`
