// @flow
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TSubdomainInputProps } from './types'
import Input from '@website/common/components/Input'
import Notification from './Notification'
import { RENDERFOREST_SITES } from './consts'
import { validateSubdomain } from './utils'

import { saveSubdomain, setSubdomainErrorMessage } from '@actions/editor'
import {
  selectSubdomainValue,
  selectIsSubdomainSaving,
  selectSubdomainErrorMessage
} from '@selectors'

const SubdomainInput = ({
  value,
  fromPublishPopup,
  isSaving,
  subdomainFromStore,
  errorMessage,
  setValue,
  saveAndPublish,
  saveSubdomain,
  setSubdomainErrorMessage
}: TSubdomainInputProps) => {
  const [isSuccessIconVisible, setSuccessIconVisibility] = useState(true)

  const isSubdomainChanged = value !== subdomainFromStore
  const showSuccessIcon =
    isSuccessIconVisible && subdomainFromStore && !isSubdomainChanged

  useEffect(
    () => () => {
      if (errorMessage) {
        setSubdomainErrorMessage(null)
      }
    },
    [errorMessage]
  )

  const onRemoveIconClick = useCallback(() => {
    setSubdomainErrorMessage(null)
    setValue('')
  }, [fromPublishPopup, subdomainFromStore])

  const onBlur = useCallback(() => {
    if (subdomainFromStore) {
      setSuccessIconVisibility(true)
    }
  }, [subdomainFromStore])

  const onFocus = useCallback(() => {
    if (subdomainFromStore) {
      setSuccessIconVisibility(false)
    }
  }, [subdomainFromStore])

  const onInputChange = useCallback(
    e => {
      if (errorMessage) {
        setSubdomainErrorMessage(null)
      }

      setValue(e.target.value)
    },
    [errorMessage, setSubdomainErrorMessage]
  )

  const handleClick = action =>
    useCallback(() => {
      const { isValid, message } = validateSubdomain(value)
      if (isSaving) {
        return
      }
      if (!isValid) {
        setSubdomainErrorMessage(message)
        return
      }
      action(value)
    }, [value, setSubdomainErrorMessage, action])

  const onClick = fromPublishPopup
    ? handleClick(saveAndPublish)
    : handleClick(saveSubdomain)

  const onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        onClick()
      }
    },
    [onClick]
  )

  return (
    <Styled.SubdomainInputContainer>
      <Styled.InputBlock>
        <Styled.InputForm>
          <Styled.Dots>
            <div />
            <div />
            <div />
          </Styled.Dots>
          <Styled.InputWrapper className="subdomain-input-wrapper">
            <Input
              value={value}
              borderColor="#8493BD"
              borderHoverColor="#545F7E"
              error={errorMessage}
              onChange={onInputChange}
              onBlur={onBlur}
              onFocus={onFocus}
              onKeyPress={onKeyPress}
              onRemoveIconClick={onRemoveIconClick}
            />
            {showSuccessIcon && (
              <Styled.SuccessIcon
                name="rounded_check"
                color="green"
                size="small"
              />
            )}
          </Styled.InputWrapper>
          <Styled.RenerforestSites>
            {RENDERFOREST_SITES}
          </Styled.RenerforestSites>
        </Styled.InputForm>
      </Styled.InputBlock>
      <Notification fromPublishPopup={fromPublishPopup} />
      <Styled.Button
        disabled={!fromPublishPopup && !isSubdomainChanged}
        onClick={onClick}
      >
        {fromPublishPopup
          ? translate('publish_label')
          : translate('save_label')}
      </Styled.Button>
    </Styled.SubdomainInputContainer>
  )
}

const mapStateToProps = state => ({
  subdomainFromStore: selectSubdomainValue(state),
  isSaving: selectIsSubdomainSaving(state),
  errorMessage: selectSubdomainErrorMessage(state)
})

const mapDispatchToProps = {
  saveSubdomain,
  setSubdomainErrorMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(SubdomainInput)
