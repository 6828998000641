export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 60,
  blockParagraphMaxChar: 1000,
  featureIconText: {
    min: 1,
    max: 12
  }
}
