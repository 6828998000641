// @flow

import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'
import { StyledTriangleProps, StyledTooltipWrapperProps } from './types'

const getTooltipPosition = ({
  coords: { x, y }
}: StyledTooltipWrapperProps) => {
  return css`
    top: ${y}px;
    left: ${x}px;
  `
}

const getTriangleStyles = ({ position }: StyledTriangleProps) => {
  const styles = {
    top: css`
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%) rotate(0deg);
    `,
    left: css`
      right: 0;
      top: 50%;
      transform: translate(76%, -50%) rotate(-90deg);
    `,
    right: css`
      left: 0;
      top: 50%;
      transform: translate(-76%, -50%) rotate(90deg);
    `,
    bottom: css`
      top: 0;
      left: 50%;
      transform: translate(-50%, -100%) rotate(180deg);
    `
  }

  return styles[position]
}

export const StyledTooltipContent = styled.div`
  position: fixed;
  display: flex;
  z-index: 100001;
  flex-direction: column;
  ${getTooltipPosition};
`

export const StyledTooltip = styled.p`
  max-width: 18em;
  margin: 0;
  order: 1;
  padding: 10px;
  border-radius: 0.2em;
  color: white;
  background-color: ${({ color }) => color || secondary.ExtraDark};
  font-size: 12px;
  box-shadow: 0px 0px 9px #497ef733;
  position: relative;
`

export const StyledTriangle = styled.span`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 8px 7px 0 7px;
  border-color: ${({ color }) =>
    `${color || secondary.ExtraDark} transparent transparent transparent`};
  ${getTriangleStyles}
`
