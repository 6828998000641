import styled, { createGlobalStyle } from 'styled-components'
import { scrollBarStyles } from '@editor/common/styles'

export const GlobalStyles = createGlobalStyle`
  .user-flyout_container {
    box-shadow: 0px 3px 12px #3A86FF33;
    border-radius: 5px;
  }
`

export const Content = styled.div`
  width: 250px;
  box-sizing: border-box;
  background-color: white;
`
export const ScrollableContent = styled.div`
  overflow-y: overlay;
  max-height: 500px;
  ${scrollBarStyles}
`
