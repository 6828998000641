// @flow
import React, { useCallback, useContext } from 'react'
import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'
import Crown from '@editor/common/assets/svgr-icons/crown.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { addAnalyticsEvent } from '@editor/common/utils'
import { UpgradePopupContext } from '@contexts'
import { useResponsive } from '@shared/hooks'

const UpgradeButton = () => {
  const { setUpgradePopupState } = useContext(UpgradePopupContext)
  const isMobilOrTablet = useResponsive(1024)

  const openUpgradePopup = useCallback(() => {
    setUpgradePopupState({ isOpen: true })
    addAnalyticsEvent('SiteMaker Subscription', 'upgrade-icon-click')
  }, [])

  return isMobilOrTablet ? (
    <Styled.MobileUpgradeButton onClick={openUpgradePopup}>
      <Styled.IconWrapper>
        <Crown />
      </Styled.IconWrapper>
      {translate('upgrade_label', true)}
    </Styled.MobileUpgradeButton>
  ) : (
    <TooltipWrapper
      text={`✨${translate('upgrade_label', true)} ✨`}
      position="bottom"
      color="#363F5A"
      offsets={{ x: -5 }}
    >
      <Styled.UpgradeButton onClick={openUpgradePopup}>
        <Crown />
      </Styled.UpgradeButton>
    </TooltipWrapper>
  )
}

export default UpgradeButton
