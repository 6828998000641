import styled from 'styled-components'

import { globalColors } from '@renderforest/rf-ui-library/consts/colors'
import { mediaMaxWidth, mediaMinWidth } from '@editor/common/utils/styled'
import { BREAKPOINT_800 } from '@containers/edit/consts'

export const YourColorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
  ${mediaMaxWidth(BREAKPOINT_800)`
    flex-wrap: nowrap;
    overflow: scroll;
  `};
`

export const Color = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
  border-radius: ${({ isSelected }) => (isSelected ? '12px' : '5px')};
  border: 0.5px solid ${globalColors.secondaryExtraLight};
  ${mediaMaxWidth(BREAKPOINT_800)`
    width: 30px;
    height: 30px;
  `}
`

export const ColorWrap = styled.div`
  position: relative;
  margin: 0 2px 5px 0;
  border: ${({ isSelected }) =>
    isSelected
      ? `2px solid ${globalColors.primaryDark}`
      : '2px solid transparent'};
  border-radius: ${({ isSelected }) => (isSelected ? '13px' : '3px')};
  cursor: pointer;
  ${mediaMinWidth(BREAKPOINT_800 + 1)`
    &:hover {
      border-radius: 13px;
      transition: all 0.1s linear;
    }
    &:hover ${Color} {
      border-radius: 12px;
      transition: all 0.1s linear;
    }
  `};
`
export const PaletteWrapper = styled.div`
  position: relative;
  border: ${({ isActive }) =>
    isActive ? `2px solid ${globalColors.primarySemiDark}` : 'none'};
  border-radius: 8px;
  box-shadow: ${({ isActive }) => (isActive ? '0 3px 6px #387dff33' : 'none')};
  margin: 0 0 20px;
  cursor: pointer;
  &:first-child {
    margin-top: 5px;
  }
  ${mediaMinWidth(BREAKPOINT_800 + 1)`
    &:hover {
      box-shadow: 0 3px 6px #387dff33;
      color: ${globalColors.secondaryDark};
      transform: scale(1.05);
      transition: transform 0.2s ease;
    }
  `};
  ${mediaMaxWidth(BREAKPOINT_800)`
    width: 130px;
    flex-shrink: 0;
    margin: 0 10px 0 0;
    &:first-child {
      margin-top: 0;
    }
  `}
`

export const Palette = styled.div`
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  border: ${({ isActive }) =>
    isActive
      ? `2px solid ${globalColors.secondaryExtraLight}`
      : `1px solid ${globalColors.secondaryExtraLight}`};
  border-radius: 6px;
  overflow: hidden;
`

export const PaletteColor = styled.div`
  width: 25px;
  height: 18px;
  flex-grow: 1;
`
