/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Testimonials5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb,
  hexToRgbA
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)
const CONTAINER_OPACITY = 0.6

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  .quote_svg__from {
    stop-color: ${({ theme }) => theme.palette.primaryLight};
  }
  .quote_svg__to {
    stop-color: ${({ theme }) => theme.palette.primaryDark};
  }
  .control-container {
    width: 100%;
  }
`

export const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
`

export const TestimonialsContainer = styled.div`
  position: relative;
  width: 100%;
  padding: 40px;
  max-width: 1300px;
  background: ${props =>
    hexToRgbA(getStyleForProp('backgroundColor')(props), CONTAINER_OPACITY)};
  backdrop-filter: blur(15px);
  border-radius: ${({ theme }) => (theme.look === 'SHARP' ? '0' : '10px')};
  border: 1px solid ${getStyleForProp('border')};
  box-sizing: border-box;
  box-shadow: ${({ theme }) =>
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  @media only screen and (max-width: 450px) {
    padding: 30px 20px;
  }
  @media only screen and (min-width: 1025px) {
    .slick-list {
      height: ${({ height }) =>
        height ? `${height + 2}px !important` : 'auto'};
      overflow: hidden;
    }
    .slick-slide {
      height: ${({ height }) => (height ? `${height + 2}px` : 'auto')};
      overflow-y: hidden;
    }
  }

  .slick-arrow {
    display: none !important;
  }
  .slider {
    width: 100%;
  }
  .slick-slide > div {
    margin: 0;
  }
`

export const SliderContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`

export const SlideWrapper = styled.div`
  width: 100%;
  display: flex !important;
  padding: 5px;
  box-sizing: border-box;
`

export const Title = styled.h1`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 0;
  font-weight: 600;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('heading')};
  font-weight: 400;
  margin-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const DotsWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @media only screen and (max-width: 1024px) {
    position: initial;
    transform: initial;
    right: initial;
    top: initial;
    flex-direction: row;
    flex-shrink: 0;
    margin: 40px auto 0 auto;
  }
`

export const DotWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  cursor: pointer;
  box-sizing: border-box;
`

export const Dot = styled.div`
  width: 13px;
  height: 13px;
  clip-path: circle(40%);
  cursor: pointer;
  box-sizing: border-box;
  background: ${({ isActive, theme }) =>
    isActive
      ? `linear-gradient(0, ${theme.palette.primaryDark}, ${theme.palette.primaryLight})`
      : `rgba(${hexToRgb(getStyleForProp('paragraph')({ theme }))}, 0.5)`};
  @media only screen and (min-width: 1025px) {
    &:last-child {
      margin-bottom: ${({ isEditing }) => (isEditing ? '50px' : '0px')};
    }
  }
`

export const Info = styled.div`
  width: 90%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  > :first-child {
    padding-top: 0;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
  }
  & .slide-title {
    ${({ isEditing }) =>
      isEditing
        ? css`
            @media only screen and (max-width: 1024px) {
              margin-top: 80px;
            }
          `
        : ''}
  }
  & .slide-paragraph {
    margin-bottom: ${({ isEditing }) => (isEditing ? '20px' : '0')};
  }
  .control-container {
    h1,
    p {
      margin-top: 0;
      margin-bottom: 0;
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .control-container:nth-child(2) {
    margin-bottom: 20px;
    @media only screen and (max-width: 1024px) {
      margin-top: 100px;
    }
  }
`
export const QuoteWrapper = styled.div`
  width: 46px;
  margin-bottom: 30px;
`

export const ActionsWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
`

export const SlideActionButtons = styled.div`
  display: flex;
  @media only screen and (max-width: 490px) {
    flex-direction: column;
    align-items: flex-start;
  }
  .rf-add-btn:first-child {
    margin-right: 20px;
    @media only screen and (max-width: 490px) {
      margin: 10px 0 0;
    }
  }
`

export const SliderArrow = styled.div`
  display: flex;
  width: 42px;
  min-width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #c1c9e0;
  box-shadow: 0 6px 12px 0 #3271e626;

  & > svg {
    fill: #545f7e;
  }

  ${({ isPrev }) =>
    isPrev
      ? css`
          & > svg {
            transform: rotate(0deg);
          }
        `
      : css`
          & > svg {
            transform: rotate(180deg);
          }
        `}

  &:hover {
    & > svg {
      fill: #252e48;
    }
  }
`

export const SliderArrowWrapperDesktop = styled.div`
  display: flex;
  transform: none;
  & ${SliderArrow} {
    margin: 0 10px;
    transform: rotate(90deg);
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const SliderArrowWrapperMobile = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 1;
  display: flex;
  & ${SliderArrow} {
    &:last-child {
      margin-left: 20px;
    }
  }
  @media only screen and (min-width: 1024px) {
    display: none;
  }
`
