import { isOpenedWithHash } from '@editor/common/utils'
import { translate } from '@editor/common/utils/translations'

export const TABS_DATA = [
  {
    label: translate('general_label'),
    value: 'general'
  },
  ...(isOpenedWithHash()
    ? []
    : [
        {
          label: translate('domain_label'),
          value: 'domain'
        }
      ]),
  {
    label: translate('marketing_integrations_label'),
    value: 'integrations'
  },
  {
    label: translate('cookies_label'),
    value: 'cookies'
  },
  {
    label: translate('languages_label'),
    value: 'languages'
  }
]

export const SETTINGS_FOOTER_TIMER = 10000 // with milliseconds
export const SETTINGS_FOOTER_DEFAULT_TEXT =
  'All changes saved. To see all changes, please publish your site.'

export const DISCARD_DIALOG_BUTTONS = {
  cancel: translate('cancel_label'),
  confirm: translate('discard_label', true)
}
export const DISCARD_DIALOG_TITLE = translate(
  'are_you_sure_discard_changes_label'
)
export const LEAVE_TAB_DIALOG_BUTTONS = {
  cancel: translate('stay_label'),
  confirm: translate('leave_label', true)
}
export const DIALOG_CONTENT = translate('unsaved_changes_will_be_lost_label')

export const UPGRADE_LABEL =
  'https://static.rfstat.com/renderforest/images/website_maker_images/label-upgrade.svg'
