/* @flow */
import styled from 'styled-components'

import THEME from './Cookie.theme'
import { styleProvider } from '@website/common/utils'
import { scrollBarStyles } from '@editor/common/styles'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 20px 80px;
  background-color: ${getStyleForProp('background')};
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  box-shadow: 0px -1px 54px rgba(0, 0, 0, 0.3);
  @media only screen and (max-width: 992px) {
    padding: 20px;
  }
`

const CookieWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const Msg = styled.p`
  color: ${getStyleForProp('message')};
  font-size: 16px;
  margin-right: 20px;
  max-height: 100px;
  overflow-y: auto;
  padding-right: 10px;

  ${scrollBarStyles}

  @media only screen and (max-width: 992px) {
    margin-right: 0;
    max-height: 120px;
  }
`

const Button = styled.button`
  border-radius: ${getStyleForProp('buttonRadius')};
  border: none;
  ${getStyleForProp('buttonBackground')};
  background-repeat: no-repeat;
  text-decoration: none;
  max-width: 240px;
  min-width: 100px;
  padding: 0 30px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  text-align: center;
  display: inline-flex;
  flex-shrink: 0;
  height: 50px;
  cursor: pointer;
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 16px;
  }
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 992px) {
    margin: 20px 0 30px;
    width: 100%;
    max-width: 335px;
  }
`

export default {
  Container,
  CookieWrap,
  Msg,
  Button
}
