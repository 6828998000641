/* @flow */
import styled from 'styled-components'
import LibIcon from '@website/common/components/Icon'
import { getTextStyleByAlignment } from '@website/common/utils'

const Container = styled.div`
  word-break: break-word;
  display: flex;
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '150px 0 0 0' : '0')};
  @media only screen and (max-width: 768px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 0 0' : '0')};
  }
  .row-remove {
    color: white;
    padding: 10px;
  }
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  article:nth-child(4) {
    @media only screen and (min-width: 769px) {
      .item-info {
        > .control-container:first-child {
          .styled-controls {
            right: 0px;
            left: initial;
          }
        }
      }
    }
  }
`

const Item = styled.article`
  width: 24%;
  display: flex;
  flex-grow: 1;
  position: relative;
  font-size: 0;
  &:hover {
    .item-info {
      opacity: 1;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 45%;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
  .LazyLoad {
    width: 100%;
  }
`

const ControlContainer = styled.div`
  position: relative;
  padding-top: 140%;
  width: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 140%;
    height: initial;
  }
`

const ItemInfo = styled.div`
  display: ${({ withoutTexts }) => (withoutTexts ? 'none' : 'flex')};
  flex-direction: column;
  justify-content: flex-end;
  opacity: ${props => (props.isEditing ? '1' : '0')};
  @media only screen and (max-width: 1024px) {
    opacity: 1;
  }
  transition: all 0.2s ease-in;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 30px;
  height: 180px;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(to top, #080808, transparent);
  @media only screen and (max-width: 650px) {
    height: 150px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .add-button-wrap {
    text-align: left !important;
    margin-top: 10px;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.palette.accentWhite};
  ${getTextStyleByAlignment};
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const AddButtonWrapper = styled.div`
  display: flex;
  margin: 20px auto;
`

const LinkContainer = styled.div`
  margin-top: 8px;
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: ${({ theme }) => theme.palette.accentWhite};
  }
  .learn-more-link {
    align-items: center;
    .arrow-right {
      margin-left: 0;
      padding-left: 5px;
      min-width: 24px;
      transition: transform linear 0.15s;
    }
    &:hover {
      opacity: 0.7;
      .arrow-right {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.accentWhite
}))``

export default {
  Container,
  Row,
  Item,
  Img,
  ItemInfo,
  Title,
  LinkContainer,
  ControlContainer,
  Icon,
  AddButtonWrapper,
  RowWrapper
}
