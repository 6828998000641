/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header15.styled'
import THEME from './Header15.theme'
import { validation } from './Header15.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import NavBar from '@website/common/components/NavBar'
import Socials from '@website/common/components/Socials'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, SiteIdContext, EditingContext } from '@contexts'
import AddButton from '@website/common/components/AddButton'
import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import YourLogo from '@website/common/assets/YourLogo.svg'
import SingleButton from '@website/common/components/SingleButton'
import { translate } from '@editor/common/utils/translations'

const Header15 = memo(props => {
  const {
    data: {
      paragraph,
      paragraphAlignment,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      backgroundImgDimensions,
      contentImage,
      btnText,
      socials,
      btnUrl
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const siteId = useContext(SiteIdContext)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const Actions = ActionGetter(dispatcher)
  const isNavbarMissing = !isEditing && socials.length === 0 && !logoSrc
  return (
    <S.StyledContainer
      isEditing={isEditing}
      isNavbarMissing={isNavbarMissing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      topOffset={navBarHeight}
    >
      <NavBar
        isEditing={isEditing}
        handleHeightChange={setNavBarHeight}
        alwaysShowBackground
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || socials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={socials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.WmCustomContainer>
        <S.HeaderContent>
          <If
            condition={contentImage.imgUrl}
            otherwise={() => (
              <AddButton
                onAdd={Actions.addImage}
                style={{
                  marginBottom: '20px'
                }}
                medium
                toShow
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_image_label')}
              </AddButton>
            )}
            then={() => (
              <S.ContentImage>
                <ControlsWithModalOpener
                  actions={Actions.imageActions({
                    showRemove: !!contentImage.imgUrl,
                    keyForSizes: 'header15ImgSizes',
                    siteId,
                    imageUrl: contentImage.imgUrl
                  })}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    alt="Logo"
                    sizes={contentImage.imgDimensions}
                    defaultImgSrc={contentImage.imgUrl || YourLogo}
                  />
                </ControlsWithModalOpener>
              </S.ContentImage>
            )}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Description}
            required={validation.paragraph.required}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <SingleButton btnUrl={btnUrl} btnText={btnText} />
        </S.HeaderContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header15
