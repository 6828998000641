/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Portfolio1.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  padding: 80px 0;
  background-color: ${getStyleForProp('background')};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1100px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
  @media only screen and (max-width: 457px) {
    padding: 60px 0;
  }
  .portfolio-wrap {
    width: calc(100% + 60px);
    @media only screen and (max-width: 767px) {
      width: calc(100% + 44px);
    }
  }
  .failed-image-container {
    min-height: 200px;
  }
  .image-state-overlay {
    min-height: 100px;
  }
  .add-button-wrap {
    margin-top: 10px;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h1 {
      margin-bottom: 0;
    }
  }
`

const PreviewModeContainer = styled.div`
  z-index: ${({ isEditing }) => (isEditing ? 50 : 2147483649)};
  .slick-arrow.slick-prev {
    display: none !important;
  }
  .slick-arrow.slick-next {
    display: none !important;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 30px;
`

const PortfolioImg = styled.img`
  border-radius: ${getStyleForProp('cardRadius')};
  cursor: pointer;
`

const PortfolioImgWrapper = styled.div`
  ${({ imgRatio }) =>
    imgRatio
      ? css`
          position: relative;
          padding-top: ${imgRatio * 100}%;
          & > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
          ${PortfolioImg} {
            object-fit: cover;
          }
        `
      : ''};
`

const PortfolioImges = styled.img`
  height: 130px;
  width: 130px;
  border-radius: ${getStyleForProp('cardRadius')};
`

const LightBoxSelectedImage = styled.img`
  height: 100%;
  border-radius: ${getStyleForProp('cardRadius')};
  object-fit: contain;
  margin: 0 auto;
`

const LightBoxImage = styled.img`
  height: ${props => `${props.lightBoxImageWidth}px`};
  width: ${props => `${props.lightBoxImageWidth}px`};
  border-radius: ${getStyleForProp('cardRadius')};
  ${props => props.selected && 'border:2px solid white'}
`

const IconContainer = styled.div`
  width: 36px;
  min-width: 36px;
  margin: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const SlideIndexIndicator = styled.div`
  position: absolute;
  color: white;
  left: 0;
  padding: 10px;
  font-size: 14px;
`

const CustomSlickSLiderContainer = styled.div`
  background: ${getStyleForProp('bg')};
  .lightbox-image-wrap {
    .failed-image-container {
      width: 130px;
      height: 130px;
      @media only screen and (max-width: 480px) {
        width: 80px;
        height: 80px;
      }
    }
  }
  img {
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

export default {
  Container,
  Title,
  LightBoxImage,
  Description,
  PortfolioImgWrapper,
  PortfolioImg,
  IconContainer,
  PortfolioImges,
  LightBoxSelectedImage,
  SlideIndexIndicator,
  PreviewModeContainer,
  CustomSlickSLiderContainer
}
