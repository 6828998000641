// @flow

import React, { memo } from 'react'
import { ThemeProvider } from 'styled-components'
import CookieBanner from '@website/common/components/CookieBanner'
import ComponentIdWrapper from '@website/common/components/ComponentIdWrapper'
import COMPONENTS from '@website/components'
import RenderforestFooter from '@website/components/RenderforestFooter'

import {
  getTagNameByCategory,
  getSecondComponentMode
} from '@website/common/utils'
import { GlobalStyle } from '@src/shared/globals'
import { RSVP_COMP_CATEGORY } from '@editor/conf/consts'
import * as Styled from './styled'

const PageComponents = ({
  components,
  siteId,
  cookieSettings,
  events,
  isForPreview,
  isPaid
}) => {
  const gdpr = cookieSettings?.gdpr || {}
  const simple = cookieSettings?.simple || {}
  const showCookieConsent = gdpr.isVisible || simple.isVisible
  const cookieConsentProps = gdpr.isVisible ? gdpr : simple
  const secondComponentMode = getSecondComponentMode(components)

  return (
    <Styled.Container>
      <GlobalStyle />
      <div className="components-section">
        {components.map((component, i) => {
          const Comp = COMPONENTS[component.id]
          const HTMLTagName = getTagNameByCategory(component.category)

          const additionalProps =
            i === 0
              ? {
                  hasNextComponent: !!components[i + 1]
                }
              : {}

          if (component.category === RSVP_COMP_CATEGORY) {
            const { eventId } = component

            const eventData = eventId
              ? events.find(event => event.id === eventId)
              : events[0]

            if (eventData) {
              additionalProps.eventData = eventData
              additionalProps.uniqId = component.uniqId
            }
          }

          return (
            <>
              <HTMLTagName key={component.uniqId}>
                <ComponentIdWrapper
                  id={`comp-${i}`}
                  idWithUniqKey={`comp-${component.uniqId}`}
                >
                  <ThemeProvider
                    theme={{
                      colorMode: component.colorMode,
                      orientation: component.orientation,
                      secondComponentMode
                    }}
                  >
                    <Comp data={component.data} {...additionalProps} />
                  </ThemeProvider>
                </ComponentIdWrapper>
              </HTMLTagName>
            </>
          )
        })}
      </div>
      <ThemeProvider
        theme={{
          colorMode: components[0].colorMode,
          secondComponentMode
        }}
      >
        <Styled.Footer>
          {showCookieConsent ? (
            <CookieBanner siteId={siteId} {...cookieConsentProps} />
          ) : null}
          {isPaid || isForPreview ? null : <RenderforestFooter />}
        </Styled.Footer>
      </ThemeProvider>
    </Styled.Container>
  )
}

export default memo(PageComponents)
