import React, { memo } from 'react'

import * as Styled from './styled'

const ToolbarItem = ({
  label,
  icon,
  hasNew,
  isActive,
  onItemClick,
  sidebarActiveItem
}) => (
  <Styled.ToolbarItem
    isActive={isActive}
    onClick={onItemClick}
    sidebarActiveItem={sidebarActiveItem}
  >
    <Styled.IconWrapper hasNew={hasNew} isActive={isActive}>
      {icon}
    </Styled.IconWrapper>
    <Styled.Label isActive={isActive}>{label}</Styled.Label>
  </Styled.ToolbarItem>
)

export default memo(ToolbarItem)
