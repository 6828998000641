export const BOLD_COMMAND = 'bold'
export const ITALIC_COMMAND = 'italic'
export const UNDERLINE_COMMAND = 'underline'
export const LINK_COMMAND = 'createlink'
export const UNLINK_COMMAND = 'unlink'
export const DEFAULT_TEXT = 'Your text'
export const BOLD_NODE_NAME = 'B'
export const ITALIC_NODE_NAME = 'I'
export const UNDERLINE_NODE_NAME = 'U'
export const LINK_NODE_NAME = 'A'
