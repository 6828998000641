/* @flow */
import styled from 'styled-components'
import THEME from './Portfolio3.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
`

const WMCustomContainer = styled(WMContainer)``

const HeadingWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  padding-top: 20px;
`

const PortfolioImg = styled.img`
  border-radius: ${getStyleForProp('cardRadius')};
`

const SquareBox = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 200px;
  height: 200px;
  background: ${({ theme }) => theme.palette.tertiarySemiLight};
  opacity: 0.2;
  position: absolute;
  &.right {
    right: -144px;
    top: 6px;
  }
  &.left {
    left: -140px;
    bottom: 12px;
  }
`

const VideoContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: hidden;
  position: relative;
  z-index: 1;
`

const VideoContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 50px auto 0 auto;
  position: relative;
  @media only screen and (max-width: 1200px) {
    margin-top: 40px;
  }
`

const Dots = styled.div`
  position: absolute;
  z-index: 1;
  &.right {
    right: -200px;
    top: -50px;
  }
  &.left {
    left: -200px;
    bottom: -50px;
  }
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    padding-top: 56.25%;
  }
  .iframe-video {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  Dots,
  HeadingWrap,
  Title,
  Description,
  PortfolioImg,
  SquareBox,
  VideoContent,
  VideoContainer,
  VideoWrap
}
