export const COUNTRIES_DATA = [
  {
    iso: 'US',
    name: 'United States',
    flag: 'flags/US.jpg'
  },
  {
    iso: 'UK',
    name: 'United Kingdom',
    flag: 'flags/GB.jpg'
  },
  {
    iso: 'AU',
    name: 'Australia',
    flag: 'flags/AU.jpg'
  },
  {
    iso: 'DE',
    name: 'Germany',
    flag: 'flags/DE.jpg'
  },
  {
    iso: 'FR',
    name: 'France',
    flag: 'flags/FR.jpg'
  },
  {
    iso: 'ES',
    name: 'Spain',
    flag: 'flags/ES.jpg'
  },
  {
    iso: 'AF',
    name: 'Afghanistan',
    flag: 'flags/AF.jpg'
  },
  {
    iso: 'AL',
    name: 'Albania',
    flag: 'flags/AL.jpg'
  },
  {
    iso: 'DZ',
    name: 'Algeria',
    flag: 'flags/DZ.jpg'
  },
  {
    iso: 'AO',
    name: 'Angola',
    flag: 'flags/AO.jpg'
  },
  {
    iso: 'AR',
    name: 'Argentina',
    flag: 'flags/AR.jpg'
  },
  {
    iso: 'AM',
    name: 'Armenia',
    flag: 'flags/AM.jpg'
  },
  {
    iso: 'AT',
    name: 'Austria',
    flag: 'flags/AT.jpg'
  },
  {
    iso: 'AZ',
    name: 'Azerbaijan',
    flag: 'flags/AZ.jpg'
  },
  {
    iso: 'BS',
    name: 'Bahamas',
    flag: 'flags/BS.jpg'
  },
  {
    iso: 'BH',
    name: 'Bahrain',
    flag: 'flags/BH.jpg'
  },
  {
    iso: 'BD',
    name: 'Bangladesh',
    flag: 'flags/BD.jpg'
  },
  {
    iso: 'BY',
    name: 'Belarus',
    flag: 'flags/BY.jpg'
  },
  {
    iso: 'BE',
    name: 'Belgium',
    flag: 'flags/BE.jpg'
  },
  {
    iso: 'BZ',
    name: 'Belize',
    flag: 'flags/BZ.jpg'
  },
  {
    iso: 'BO',
    name: 'Bolivia',
    flag: 'flags/BO.jpg'
  },
  {
    iso: 'BA',
    name: 'Bosnia and Herzegovina',
    flag: 'flags/BA.jpg'
  },
  {
    iso: 'BW',
    name: 'Botswana',
    flag: 'flags/BW.jpg'
  },
  {
    iso: 'BR',
    name: 'Brazil',
    flag: 'flags/BR.jpg'
  },
  {
    iso: 'BN',
    name: 'Brunei',
    flag: 'flags/BN.jpg'
  },
  {
    iso: 'BG',
    name: 'Bulgaria',
    flag: 'flags/BG.jpg'
  },
  {
    iso: 'CV',
    name: 'Cabo Verde',
    flag: 'flags/CV.jpg'
  },
  {
    iso: 'KH',
    name: 'Cambodia',
    flag: 'flags/KH.jpg'
  },
  {
    iso: 'CM',
    name: 'Cameroon',
    flag: 'flags/CM.jpg'
  },
  {
    iso: 'CA',
    name: 'Canada',
    flag: 'flags/CA.jpg'
  },
  {
    iso: 'CV',
    name: 'Cape Verde',
    flag: 'flags/CV.jpg'
  },
  {
    iso: 'CL',
    name: 'Chile',
    flag: 'flags/CL.jpg'
  },
  {
    iso: 'CO',
    name: 'Colombia',
    flag: 'flags/CO.jpg'
  },
  {
    iso: 'CR',
    name: 'Costa Rica',
    flag: 'flags/CR.jpg'
  },
  {
    iso: 'HR',
    name: 'Croatia',
    flag: 'flags/HR.jpg'
  },
  {
    iso: 'CY',
    name: 'Cyprus',
    flag: 'flags/CY.jpg'
  },
  {
    iso: 'CZ',
    name: 'Czech Republic',
    flag: 'flags/CZ.jpg'
  },
  {
    iso: 'CD',
    name: 'Democratic Republic of the Congo',
    flag: 'flags/CD.jpg'
  },
  {
    iso: 'DK',
    name: 'Denmark',
    flag: 'flags/DK.jpg'
  },
  {
    iso: 'DO',
    name: 'Dominican Republic',
    flag: 'flags/DO.jpg'
  },
  {
    iso: 'EC',
    name: 'Ecuador',
    flag: 'flags/EC.jpg'
  },
  {
    iso: 'EG',
    name: 'Egypt',
    flag: 'flags/EG.jpg'
  },
  {
    iso: 'SV',
    name: 'El Salvador',
    flag: 'flags/SV.jpg'
  },
  {
    iso: 'EE',
    name: 'Estonia',
    flag: 'flags/EE.jpg'
  },
  {
    iso: 'ET',
    name: 'Ethiopia',
    flag: 'flags/ET.jpg'
  },
  {
    iso: 'FI',
    name: 'Finland',
    flag: 'flags/FI.jpg'
  },
  {
    iso: 'GE',
    name: 'Georgia',
    flag: 'flags/GE.jpg'
  },
  {
    iso: 'GH',
    name: 'Ghana',
    flag: 'flags/GH.jpg'
  },
  {
    iso: 'GR',
    name: 'Greece',
    flag: 'flags/GR.jpg'
  },
  {
    iso: 'GT',
    name: 'Guatemala',
    flag: 'flags/GT.jpg'
  },
  {
    iso: 'GY',
    name: 'Guyana',
    flag: 'flags/GY.jpg'
  },
  {
    iso: 'HT',
    name: 'Haiti',
    flag: 'flags/HT.jpg'
  },
  {
    iso: 'HN',
    name: 'Honduras',
    flag: 'flags/HN.jpg'
  },
  {
    iso: 'HK',
    name: 'Hong Kong',
    flag: 'flags/HK.jpg'
  },
  {
    iso: 'HU',
    name: 'Hungary',
    flag: 'flags/HU.jpg'
  },
  {
    iso: 'IS',
    name: 'Iceland',
    flag: 'flags/IS.jpg'
  },
  {
    iso: 'IN',
    name: 'India',
    flag: 'flags/IN.jpg'
  },
  {
    iso: 'ID',
    name: 'Indonesia',
    flag: 'flags/ID.jpg'
  },
  {
    iso: 'IE',
    name: 'Ireland',
    flag: 'flags/IE.jpg'
  },
  {
    iso: 'IL',
    name: 'Israel',
    flag: 'flags/IL.jpg'
  },
  {
    iso: 'IT',
    name: 'Italy',
    flag: 'flags/IT.jpg'
  },
  {
    iso: 'JM',
    name: 'Jamaica',
    flag: 'flags/JM.jpg'
  },
  {
    iso: 'JP',
    name: 'Japan',
    flag: 'flags/JP.jpg'
  },
  {
    iso: 'JO',
    name: 'Jordan',
    flag: 'flags/JO.jpg'
  },
  {
    iso: 'KZ',
    name: 'Kazakhstan',
    flag: 'flags/KZ.jpg'
  },
  {
    iso: 'KW',
    name: 'Kuwait',
    flag: 'flags/KW.jpg'
  },
  {
    iso: 'LV',
    name: 'Latvia',
    flag: 'flags/LV.jpg'
  },
  {
    iso: 'LB',
    name: 'Lebanon',
    flag: 'flags/LB.jpg'
  },
  {
    iso: 'LY',
    name: 'Libya',
    flag: 'flags/LY.jpg'
  },
  {
    iso: 'LT',
    name: 'Lithuania',
    flag: 'flags/LT.jpg'
  },
  {
    iso: 'LU',
    name: 'Luxembourg',
    flag: 'flags/LU.jpg'
  },
  {
    iso: 'MG',
    name: 'Madagascar',
    flag: 'flags/MG.jpg'
  },
  {
    iso: 'MY',
    name: 'Malaysia',
    flag: 'flags/MY.jpg'
  },
  {
    iso: 'MT',
    name: 'Malta',
    flag: 'flags/MT.jpg'
  },
  {
    iso: 'MU',
    name: 'Mauritius',
    flag: 'flags/MU.jpg'
  },
  {
    iso: 'MX',
    name: 'Mexico',
    flag: 'flags/MX.jpg'
  },
  {
    iso: 'MD',
    name: 'Moldova',
    flag: 'flags/MD.jpg'
  },
  {
    iso: 'MN',
    name: 'Mongolia',
    flag: 'flags/MN.jpg'
  },
  {
    iso: 'ME',
    name: 'Montenegro',
    flag: 'flags/ME.jpg'
  },
  {
    iso: 'MA',
    name: 'Morocco',
    flag: 'flags/MA.jpg'
  },
  {
    iso: 'MZ',
    name: 'Mozambique',
    flag: 'flags/MZ.jpg'
  },
  {
    iso: 'NA',
    name: 'Namibia',
    flag: 'flags/NA.jpg'
  },
  {
    iso: 'NP',
    name: 'Nepal',
    flag: 'flags/NP.jpg'
  },
  {
    iso: 'NL',
    name: 'Netherlands',
    flag: 'flags/NL.jpg'
  },
  {
    iso: 'NZ',
    name: 'New Zealand',
    flag: 'flags/NZ.jpg'
  },
  {
    iso: 'NI',
    name: 'Nicaragua',
    flag: 'flags/NI.jpg'
  },
  {
    iso: 'NG',
    name: 'Nigeria',
    flag: 'flags/NG.jpg'
  },
  {
    iso: 'NO',
    name: 'Norway',
    flag: 'flags/NO.jpg'
  },
  {
    iso: 'OM',
    name: 'Oman',
    flag: 'flags/OM.jpg'
  },
  {
    iso: 'PK',
    name: 'Pakistan',
    flag: 'flags/PK.jpg'
  },
  {
    iso: 'PA',
    name: 'Panama',
    flag: 'flags/PA.jpg'
  },
  {
    iso: 'PY',
    name: 'Paraguay',
    flag: 'flags/PY.jpg'
  },
  {
    iso: 'PE',
    name: 'Peru',
    flag: 'flags/PE.jpg'
  },
  {
    iso: 'PH',
    name: 'Philippines',
    flag: 'flags/PH.jpg'
  },
  {
    iso: 'PL',
    name: 'Poland',
    flag: 'flags/PL.jpg'
  },
  {
    iso: 'PT',
    name: 'Portugal',
    flag: 'flags/PT.jpg'
  },
  {
    iso: 'RO',
    name: 'Romania',
    flag: 'flags/RO.jpg'
  },
  {
    iso: 'RU',
    name: 'Russia',
    flag: 'flags/RU.jpg'
  },
  {
    iso: 'SA',
    name: 'Saudi Arabia',
    flag: 'flags/SA.jpg'
  },
  {
    iso: 'SN',
    name: 'Senegal',
    flag: 'flags/SN.jpg'
  },
  {
    iso: 'RS',
    name: 'Serbia',
    flag: 'flags/RS.jpg'
  },
  {
    iso: 'SG',
    name: 'Singapore',
    flag: 'flags/SG.jpg'
  },
  {
    iso: 'SK',
    name: 'Slovakia',
    flag: 'flags/SK.jpg'
  },
  {
    iso: 'SI',
    name: 'Slovenia',
    flag: 'flags/SI.jpg'
  },
  {
    iso: 'ZA',
    name: 'South Africa',
    flag: 'flags/ZA.jpg'
  },
  {
    iso: 'KR',
    name: 'South Korea',
    flag: 'flags/KR.jpg'
  },
  {
    iso: 'LK',
    name: 'Sri Lanka',
    flag: 'flags/LK.jpg'
  },
  {
    iso: 'SE',
    name: 'Sweden',
    flag: 'flags/SE.jpg'
  },
  {
    iso: 'CH',
    name: 'Switzerland',
    flag: 'flags/CH.jpg'
  },
  {
    iso: 'TW',
    name: 'Taiwan',
    flag: 'flags/TW.jpg'
  },
  {
    iso: 'TH',
    name: 'Thailand',
    flag: 'flags/TH.jpg'
  },
  {
    iso: 'TT',
    name: 'Trinidad and Tobago',
    flag: 'flags/TT.jpg'
  },
  {
    iso: 'TN',
    name: 'Tunisia',
    flag: 'flags/TN.jpg'
  },
  {
    iso: 'TR',
    name: 'Turkey',
    flag: 'flags/TR.jpg'
  },
  {
    iso: 'UA',
    name: 'Ukraine',
    flag: 'flags/UA.jpg'
  },
  {
    iso: 'AE',
    name: 'United Arab Emirates',
    flag: 'flags/AE.jpg'
  },
  {
    iso: 'UY',
    name: 'Uruguay',
    flag: 'flags/UY.jpg'
  },
  {
    iso: 'VE',
    name: 'Venezuela',
    flag: 'flags/VE.jpg'
  },
  {
    iso: 'VN',
    name: 'Vietnam',
    flag: 'flags/VN.jpg'
  },
  {
    iso: 'ZM',
    name: 'Zambia',
    flag: 'flags/ZM.jpg'
  },
  {
    iso: 'ZW',
    name: 'Zimbabwe',
    flag: 'flags/ZW.jpg'
  }
]
