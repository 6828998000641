/* @flow */
import React from 'react'
import S from './Pricing6.styled'
import ActionGetter from './Actions'
import { validation } from './Pricing6.consts'
import { KEY_CODES } from '@website/conf/consts'
import Image from '@website/common/components/Image'
import Dots from '@website/common/components/LongDots'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import ColorPicker from '@website/common/components/ColorPicker'
import EditableContent from '@website/common/components/EditableContent'
import { EditingContext, withDispatchContext } from '@contexts'
import './Pricing6.styles.sass'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

class Pricing6 extends React.PureComponent<void, void> {
  state = {
    animate: 0,
    activeIdx: 0,
    selectedMenuIdx: null,
    selectedBadgeIdx: null
  }
  removeAnimation = () =>
    setTimeout(() => {
      this.setState({
        animate: false
      })
    }, 300)

  setSelectedBadgeIdx = (selectedMenuIdx, selectedBadgeIdx) => {
    this.setState({ selectedMenuIdx, selectedBadgeIdx })
  }
  slidePrevious = () => {
    const { activeIdx } = this.state
    const {
      data: { slides }
    } = this.props
    const prevIdx = (activeIdx + slides.length - 1) % slides.length
    this.setState({ activeIdx: prevIdx, animate: true })
  }
  slideNext = () => {
    const { activeIdx } = this.state
    const {
      data: { slides }
    } = this.props
    const nextIdx = (activeIdx + 1) % slides.length
    this.setState({ activeIdx: nextIdx, animate: true })
  }
  renderLeftIcon = () => (
    <S.IconContainer
      className="wm-slider-btn"
      onClick={this.slidePrevious}
      tabIndex="0"
      onKeyDown={e => {
        if (e.keyCode === KEY_CODES.ENTER) {
          this.slidePrevious()
        }
      }}
    >
      <S.Icon size="large" name="keyboard_arrow_left" />
    </S.IconContainer>
  )
  renderRightIcon = () => (
    <S.IconContainer
      className="wm-slider-btn"
      onClick={this.slideNext}
      tabIndex="0"
      onKeyDown={e => {
        if (e.keyCode === KEY_CODES.ENTER) {
          this.slideNext()
        }
      }}
    >
      <S.Icon size="large" name="keyboard_arrow_right" />
    </S.IconContainer>
  )
  beforeRemove = () => {
    const { activeIdx } = this.state
    if (activeIdx !== 0) {
      this.setState({ activeIdx: activeIdx - 1 })
    }
  }
  closeColorPicker = () =>
    this.setState({ menuIdx: null, selectedBadgeIdx: null })

  renderContent = () => {
    const {
      data: { slides },
      componentDispatcher
    } = this.props

    const { animate, activeIdx, selectedMenuIdx, selectedBadgeIdx } = this.state
    const Actions = ActionGetter(componentDispatcher)
    return (
      <div className="slider-item">
        <ControlsWithModalOpener
          actions={Actions.sliderActions({
            idx: activeIdx,
            clickHandlers: { remove: this.beforeRemove },
            showRemove: slides.length > validation.slides.min,
            keyForSizes: 'pricing6',
            imageUrl: slides[activeIdx].imageUrl
          })}
          style={{ width: '100%' }}
        >
          <S.SlideContainer animate={animate}>
            <div className="img-figure">
              <Image
                as={S.Img}
                alt="Slider item"
                onLoad={this.removeAnimation}
                sizes={slides[activeIdx].imgDimensions}
                defaultImgSrc={slides[activeIdx].imageUrl}
              />
              <S.DotsWrap>
                <Dots />
              </S.DotsWrap>
            </div>
            <S.TextContainer>
              <S.Count>0{activeIdx + 1}</S.Count>
              <EditableContent
                as={S.SliderTitle}
                text={slides[activeIdx].slideTitle}
                required={validation.slides.slideTitle.required}
                maxCount={validation.maxHeadingChar}
                className="WM_GLOBAL_heading96"
                onChange={(newTitle: string) =>
                  componentDispatcher(
                    Actions.changeSlideTitle(newTitle, activeIdx)
                  )
                }
              />
              <div className="menu-content">
                {slides[activeIdx].menu.map((item, idx) => {
                  return (
                    <div className="menu-item-wrapper" key={idx}>
                      <ControlsWithModalOpener
                        style={{ width: '100%' }}
                        actions={Actions.menuItemActions(
                          idx,
                          activeIdx,
                          slides[activeIdx].menu.length
                        )}
                      >
                        <div className="menu-item">
                          <S.MenuItemTop className="title-price">
                            <div className="title-badge-wrap">
                              <EditableContent
                                as={S.ItemName}
                                text={item.title}
                                maxCount={validation.maxSubtitleChar}
                                className="WM_GLOBAL_secondary-font"
                                onChange={(newItemName: string) =>
                                  componentDispatcher(
                                    Actions.changeItemTitle(
                                      newItemName,
                                      activeIdx,
                                      idx
                                    )
                                  )
                                }
                              />
                              <Show when={[item.badge.length > 0]}>
                                <div className="badge-wrapper">
                                  {item.badge.map((item, badgeIdx) => {
                                    return (
                                      <div key={badgeIdx}>
                                        <div className="badge-item-wrapper">
                                          <ControlsWithModalOpener
                                            actions={Actions.getBadgeActions(
                                              activeIdx,
                                              idx,
                                              badgeIdx,
                                              this.setSelectedBadgeIdx
                                            )}
                                          >
                                            <S.Badge color={item.color}>
                                              <EditableContent
                                                as={S.Div}
                                                text={item.name}
                                                maxCount={
                                                  validation.maxBagdeChar
                                                }
                                                className="WM_GLOBAL_secondary-font"
                                                onChange={(name: string) =>
                                                  componentDispatcher(
                                                    Actions.changeBadgeName(
                                                      name,
                                                      activeIdx,
                                                      idx,
                                                      badgeIdx
                                                    )
                                                  )
                                                }
                                              />
                                              <If
                                                condition={
                                                  selectedMenuIdx === idx &&
                                                  selectedBadgeIdx === badgeIdx
                                                }
                                                then={() => (
                                                  <ColorPicker
                                                    color={item.color}
                                                    onColorChoose={color =>
                                                      componentDispatcher(
                                                        Actions.changeBadgeColor(
                                                          activeIdx,
                                                          idx,
                                                          badgeIdx,
                                                          color
                                                        )
                                                      )
                                                    }
                                                    closeColorPicker={
                                                      this.closeColorPicker
                                                    }
                                                  />
                                                )}
                                              />
                                            </S.Badge>
                                          </ControlsWithModalOpener>
                                        </div>
                                      </div>
                                    )
                                  })}
                                  <AddButton
                                    onAdd={() =>
                                      componentDispatcher(
                                        Actions.addBadgeItem(activeIdx, idx)
                                      )
                                    }
                                    toShow={
                                      slides[activeIdx].menu[idx].badge.length <
                                      validation.slides.menu.bagde.max
                                    }
                                    style={{ padding: '3px 8px' }}
                                  >
                                    +
                                  </AddButton>
                                </div>
                              </Show>
                            </div>
                            <EditableContent
                              as={S.ItemPrice}
                              text={item.price}
                              required={validation.slides.menu.price.required}
                              maxCount={validation.maxPriceChar}
                              className="WM_GLOBAL_secondary-font"
                              onChange={(newPrice: string) =>
                                componentDispatcher(
                                  Actions.changeItemPrice(
                                    newPrice,
                                    activeIdx,
                                    idx
                                  )
                                )
                              }
                            />
                          </S.MenuItemTop>
                          <div className="info">
                            <EditableContent
                              as={S.ItemParagraph}
                              text={item.paragraph}
                              maxCount={validation.maxParagraphChar}
                              className="WM_GLOBAL_secondary-font"
                              onChange={(newParagraph: string) =>
                                componentDispatcher(
                                  Actions.changeItemParagraph(
                                    newParagraph,
                                    activeIdx,
                                    idx
                                  )
                                )
                              }
                            />
                            <EditableContent
                              as={S.ItemInfo}
                              text={item.info}
                              maxCount={validation.maxInfoChar}
                              className="WM_GLOBAL_secondary-font"
                              onChange={(newItemInfo: string) =>
                                componentDispatcher(
                                  Actions.changeItemInfo(
                                    newItemInfo,
                                    activeIdx,
                                    idx
                                  )
                                )
                              }
                            />
                          </div>
                        </div>
                      </ControlsWithModalOpener>
                    </div>
                  )
                })}
                <AddButton
                  onAdd={() =>
                    componentDispatcher(Actions.addMenuItem(activeIdx))
                  }
                  toShow={
                    slides[activeIdx].menu.length < validation.slides.menu.max
                  }
                  style={{ marginTop: '2rem' }}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_item_label')}
                </AddButton>
              </div>
              <If
                condition={slides.length > 1}
                then={() => (
                  <div className="btns-wrap">
                    {this.renderLeftIcon()}
                    {this.renderRightIcon()}
                  </div>
                )}
              />
              <EditingContext.Consumer>
                {({ isEditing }) => {
                  return (
                    <S.SlideCounter isEditing={isEditing}>
                      0{activeIdx + 1}
                    </S.SlideCounter>
                  )
                }}
              </EditingContext.Consumer>
            </S.TextContainer>
          </S.SlideContainer>
        </ControlsWithModalOpener>
        <AddButton
          onAdd={() => componentDispatcher(Actions.addSlide())}
          toShow={slides.length < validation.slides.max}
          style={{
            margin: '2rem'
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </div>
    )
  }
  render() {
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.Container isEditing={isEditing}>
              <div className="pricing-6-container">{this.renderContent()}</div>
            </S.Container>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

const Pricing6WithDispatchContext = withDispatchContext(Pricing6)

export default Pricing6WithDispatchContext
