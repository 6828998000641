/* @flow */
import React, { useContext, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { selectIsUserAuthorized } from '@selectors'
import { openLoginPopup } from '@actions/editor'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import { EditingContext, ControlModalsContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import { navbarLogoSizes } from '@editor/conf/imageSizes'
import { getFlyoutCoords } from './utils'
import { projectContainerClassName } from '@website/conf/consts'

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

const AddLogoButton = ({ isUserAuthorized, openLoginPopup, action }) => {
  const buttonRef = useRef(null)
  const { isEditing } = useContext(EditingContext)
  const { modalStates, setModalState } = useContext(ControlModalsContext)

  const className = 'add-logo-button'
  const isFlyoutOpen = modalStates[MODAL_TYPES.mediaModal].isOpen

  const { top: flyoutTop, left: flyoutLeft } = getFlyoutCoords(
    buttonRef.current
  )

  const openPopup = useCallback(
    () =>
      setModalState(MODAL_TYPES.mediaModal, {
        isOpen: !isFlyoutOpen,
        targetRef: buttonRef,
        renderingContainer: projectContainerClassName,
        isFlyoutPositionFixed: false,
        alignment: 'left',
        shift: { x: flyoutLeft, y: flyoutTop },
        type: LINK_TYPES.logo,
        optimizationDimensions: navbarLogoSizes,
        withImgSizes: false,
        handler: action,
        className: 'navbar-logo-change-flyout',
        isRCOutclickDisabled: false,
        backdropDisablingSelectors: [`.${className}`]
      }),
    [isFlyoutOpen, buttonRef, action, flyoutTop, flyoutLeft]
  )

  return (
    <If
      condition={isEditing}
      then={() => (
        <AddButton
          toShow={true}
          buttonRef={buttonRef}
          className={className}
          onAdd={!isUserAuthorized ? openLoginPopup : openPopup}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_logo_label')}
        </AddButton>
      )}
    />
  )
}

export default connect(mapStateToProps, { openLoginPopup })(AddLogoButton)
