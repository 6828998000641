import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .serp-features-flyout {
    padding: 15px 0;
  }
  
  .serp-features-flyout_container {
    cursor: default;
    z-index: 10001;
    width: 185px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 3px 10px #0052E026;
    background-color: white;
  }
  
  .serp-option-tooltip {
    display: flex;
    padding: 0 15px;

    &:not(:last-child) {
      padding-bottom: 15px;
    }
  }
`

export const FlyOutOption = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;

  svg {
    fill: #8697be;
    margin-right: 15px;
  }
`
