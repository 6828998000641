import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import { TEXT, COLORS, STYLES } from '@containers/edit/consts'
import ToolbarItem from './ToolbarItem'
import TextIcon from '@editor/common/assets/svgr-icons/text_icon.svg'
import ColorIcon from '@editor/common/assets/svgr-icons/color_palette_icon.svg'
import StyleIcon from '@editor/common/assets/svgr-icons/star_icon.svg'

import { selectSidebarActiveItem } from '@selectors'
import { changeActiveSidebarItem } from '@actions/editor'
import ToolbarItemContent from './ToolbarItemContent'
import { useBackdropClick } from '@hooks'
import { translate } from '@editor/common/utils/translations'

const Toolbar = ({
  sidebarActiveItem,
  changeActiveSidebarItem,
  toolbarContent
}) => {
  const [isOpened, setOpenedState] = useState(false)

  const toolbarRef = useBackdropClick(() => {
    setOpenedState(false)
  }, ['.color-picker', '.color-picker-container', '.action-buttons'])

  const onItemClick = useCallback(
    value => {
      if (value === sidebarActiveItem) {
        setOpenedState(false)
        return
      }

      changeActiveSidebarItem(value)
      setOpenedState(true)
    },
    [sidebarActiveItem]
  )

  return (
    <Styled.Toolbar ref={toolbarRef}>
      <Styled.ToolbarItemsWrapper>
        <ToolbarItem
          label={translate('text_label')}
          icon={<TextIcon />}
          onItemClick={() => onItemClick(TEXT)}
          isActive={sidebarActiveItem === TEXT}
          sidebarActiveItem={sidebarActiveItem}
        />
        <ToolbarItem
          label={translate('colors_label')}
          icon={<ColorIcon />}
          onItemClick={() => onItemClick(COLORS)}
          isActive={sidebarActiveItem === COLORS}
          sidebarActiveItem={sidebarActiveItem}
        />
        <ToolbarItem
          label={translate('styles_label')}
          icon={<StyleIcon />}
          onItemClick={() => onItemClick(STYLES)}
          isActive={sidebarActiveItem === STYLES}
          sidebarActiveItem={sidebarActiveItem}
        />
      </Styled.ToolbarItemsWrapper>
      <ToolbarItemContent isOpened={isOpened}>
        {toolbarContent}
      </ToolbarItemContent>
    </Styled.Toolbar>
  )
}

const mapStateToProps = state => ({
  sidebarActiveItem: selectSidebarActiveItem(state)
})

export default connect(mapStateToProps, { changeActiveSidebarItem })(Toolbar)
