import { translate } from '@editor/common/utils/translations'

export const PAYPAL_COLORS = 'paypal-colors'
export const YOUR_COLORS = 'your-colors'

export const PAYPAL_COLORS_RADIO_DATA = [
  {
    label: translate('paypal_colors_label'),
    value: PAYPAL_COLORS
  }
]

export const YOUR_COLORS_RADIO_DATA = [
  {
    label: translate('your_colors_label'),
    value: YOUR_COLORS
  }
]

export const BUTTONS_STYLES = ['primary', 'secondary']
export const CUSTOM_BUTTONS_STYLES = ['yellow', 'blue', 'black', 'grey']
