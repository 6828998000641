/* @flow */
import React, { useContext, useState, memo } from 'react'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'

import S from './Header19.styled'
import THEME from './Header19.theme'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import { isMobileDevice } from '@website/common/utils'
import { validation } from './Header19.consts'
import NavBar from '@website/common/components/NavBar'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import YourLogo from '@website/common/assets/YourLogo.svg'
import ScrollRect from '@website/common/components/ScrollBottomBg'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import SingleButton from '@website/common/components/SingleButton'

import {
  scrollBottom,
  isYoutubeUrl,
  getYoutubeThumbnail
} from '@website/common/utils'
import { DispatchContext, EditingContext } from '@contexts'

const Header19 = memo(props => {
  const [navBarHeight, setNavBarHeight] = useState(0)

  const {
    data: {
      logoSrc,
      btnText,
      btnUrl,
      logoColor,
      logoWidth,
      paragraph,
      paragraphAlignment,
      contentImage,
      logoDimensions,
      backgroundVideoUrl,
      bgOverlayTransparency
    },
    hasNextComponent
  } = props
  const dispatcher = useContext(DispatchContext)
  const isMobile = isMobileDevice()
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.Container
      isEditing={isEditing}
      backgroundImgUrl={getYoutubeThumbnail(backgroundVideoUrl)}
    >
      <>
        <S.Overlay
          bgOverlayTransparency={bgOverlayTransparency}
          isBgImgOrVideoExists={!!backgroundVideoUrl}
        />
        <If
          condition={hasNextComponent}
          then={() => (
            <S.ScrollToNext onClick={() => scrollBottom(isEditing)}>
              <ScrollRect />
              <S.Icon name="icon-arrow_small_bottom" size="large" />
            </S.ScrollToNext>
          )}
        />
        <S.NavBarWrapper>
          <NavBar
            handleHeightChange={setNavBarHeight}
            theme={THEME}
            logoProps={{
              logoSrc,
              logoColor,
              logoWidth,
              logoSizes: logoDimensions
            }}
          />
        </S.NavBarWrapper>
        <S.VideoContainer style={{ pointerEvents: 'none' }}>
          <If
            condition={isMobile && isYoutubeUrl(backgroundVideoUrl)}
            otherwise={() => (
              <LazyVideoPlayer
                muted
                loop
                playing={isMobile ? false : true}
                controls={false}
                style={{
                  right: '0',
                  bottom: '0',
                  minWidth: '100%',
                  minHeight: '100%',
                  position: 'absolute'
                }}
                url={backgroundVideoUrl}
              />
            )}
          />
        </S.VideoContainer>
        <S.WmCustomContainer topOffset={navBarHeight}>
          <S.HeaderContent>
            <Show when={[contentImage.imgUrl, isEditing]}>
              <S.ContentImage>
                <ControlsWithModalOpener
                  actions={Actions.imageActions({
                    showRemove: !!contentImage.imgUrl,
                    keyForSizes: 'header19',
                    imageUrl: contentImage.imgUrl
                  })}
                  style={{ width: '100%' }}
                >
                  <If
                    condition={contentImage.imgUrl}
                    then={() => (
                      <Image
                        as={S.Img}
                        alt="Logo"
                        sizes={contentImage.imgDimensions}
                        defaultImgSrc={contentImage.imgUrl}
                      />
                    )}
                    otherwise={() => <S.Img src={YourLogo} alt="Your Logo" />}
                  />
                </ControlsWithModalOpener>
              </S.ContentImage>
            </Show>
            <EditableContent
              text={paragraph}
              as={S.Description}
              alignment={paragraphAlignment}
              required={validation.paragraph.required}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <SingleButton btnUrl={btnUrl} btnText={btnText} />
          </S.HeaderContent>
        </S.WmCustomContainer>
      </>
    </S.Container>
  )
})

export default Header19
