// @flow

import React, { memo, useEffect, useMemo } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'

import * as Styled from './styled'
import Dropdown from '@renderforest/rf-ui-library/molecules/Dropdown'
import { getComponentsDropdownOptions } from '../utils'
import { ProjectLinksContext, EditingContext } from '@contexts'
import { HeaderNavbarContextWrapper } from '@contexts/HeaderNavBar.context'

import {
  getProjectLinks,
  getVisualParams,
  selectAllPagesData
} from '@selectors'
import { translate } from '@editor/common/utils/translations'

const ComponentsDropdown = ({ selectedPage, value, onChange }) => {
  const visualParams = useSelector(getVisualParams)
  const projectLinks = useSelector(getProjectLinks)
  const pages = useSelector(selectAllPagesData)
  const _selectedPage = selectedPage === '/home' ? '/' : selectedPage
  const page = pages.find(page => page.path === _selectedPage)

  const { components } = page || {}

  const dropdownOptions = useMemo(
    () => getComponentsDropdownOptions(components),
    [components]
  )

  useEffect(() => {
    const hasOption =
      dropdownOptions.findIndex(option => option.value === value) !== -1
    if (!hasOption) {
      onChange('')
    }
  }, [value, dropdownOptions])

  return (
    <ThemeProvider
      theme={{
        look: visualParams.look,
        palette: visualParams.palette,
        fontSize: visualParams.textSize,
        primaryFont: visualParams.primaryFont,
        secondaryFont: visualParams.secondaryFont
      }}
    >
      <HeaderNavbarContextWrapper>
        <EditingContext.Provider value={{ isEditing: false }}>
          <ProjectLinksContext.Provider value={projectLinks}>
            <Styled.InputContainer>
              <Dropdown
                key={selectedPage}
                label={translate('to_which_component_label')}
                value={value}
                placeholder={translate('select_component_label')}
                className="components-dropdown"
                onChange={onChange}
                options={dropdownOptions}
                maxHeight={120}
              />
            </Styled.InputContainer>
          </ProjectLinksContext.Provider>
        </EditingContext.Provider>
      </HeaderNavbarContextWrapper>
    </ThemeProvider>
  )
}

export default memo(ComponentsDropdown)
