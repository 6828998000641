/* @flow */
import produce from 'immer'
import { generateRandomNumber } from '@website/common/utils'
import { validation } from './Feature5.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_DESCRIPTION':
        draft.description = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE':
        const { newTitle, idx } = action.value
        draft.featureBlocks[idx].title = newTitle
        break
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.featureBlocks[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_LIST_ITEM':
        const { blockIdx, listIdx, newBlockListTitle } = action.value
        if (!newBlockListTitle) {
          draft.featureBlocks[blockIdx].featureList.splice(listIdx, 1)
        } else {
          draft.featureBlocks[blockIdx].featureList[listIdx] = newBlockListTitle
        }
        break
      case 'ADD_BLOCK':
        draft.featureBlocks.push(initialState.featureBlocks[0])
        break
      case 'ADD_BLOCK_LIST_ITEM':
        const randomIndex = generateRandomNumber(
          initialState.featureBlocks[0].featureList.length
        )
        draft.featureBlocks[action.value].featureList.push(
          initialState.featureBlocks[0].featureList[randomIndex]
        )
        break
      case 'REMOVE_BLOCK': {
        if (state.featureBlocks.length === validation.featureBlocks.min) {
          return
        }
        const { idx } = action.value
        draft.featureBlocks.splice(idx, 1)
        break
      }
      case 'REMOVE_BLOCK_LIST_ITEM': {
        const { blockIdx, listIdx } = action.value
        draft.featureBlocks[blockIdx].featureList.splice(listIdx, 1)
        break
      }
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({ ...initialState.buttons[0], type: btnType })
        } else {
          draft.buttons.push({ ...initialState.buttons[0], type: btnType })
        }
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
