import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => `${p.tertiaryDark}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    cardLayerBackground: p => `rgba(${hexToRgb(p.primaryLight)}, 0.6)`,
    featureTitle: p => p.accentWhite,
    featureParagraph: p => p.accentWhite,
    anchor: p => p.accentWhite,
    raisedShadow: () => 'none',
    buttonShadow: () => 'none'
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => `${p.tertiaryLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    cardLayerBackground: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.6)`,
    featureTitle: p => p.accentWhite,
    featureParagraph: p => p.accentWhite,
    anchor: p => p.accentWhite
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    cardLayerBackground: p => `rgba(${hexToRgb(p.primaryDark)}, 0.6)`,
    featureTitle: p => p.accentWhite,
    featureParagraph: p => p.accentWhite,
    anchor: p => p.accentWhite
  }
}
