/* @flow */

import {
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'

import {
  changeBlockTitle,
  changeBlockParagraph
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

const addBlock = (idx: number) => ({
  type: 'ADD_BLOCK',
  value: { idx }
})

const addImageBlock = (idx: number) => ({
  type: 'ADD_IMAGE_BLOCK',
  value: { idx }
})

const removeImageBlock = (idx: number) => ({
  type: 'REMOVE_IMAGE_BLOCK',
  value: { idx }
})

export default dispatcher => {
  const changeAdditionalBlockTitle = (newTitle: string) =>
    dispatcher({
      type: 'CHANGE_ADDITIONAL_BLOCK_TITLE',
      value: newTitle
    })

  const changeAdditionalBlockParagraph = (newParagraph: string) =>
    dispatcher({
      type: 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH',
      value: newParagraph
    })

  const imageActions = (idx: number, imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'text16',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    }),
    {
      toShow: true,
      handler: () => dispatcher(removeImageBlock(idx)),
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label')
    }
  ]

  const _changeBlockTitle = (newBlockTitle, idx) => {
    dispatcher(changeBlockTitle(newBlockTitle, idx))
  }

  const _changeBlockParagraph = (newBlockParagraph, idx) => {
    dispatcher(changeBlockParagraph(newBlockParagraph, idx))
  }

  const changeBlockTitleAlignment = (alignment, idx) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment, idx) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeBlockTitle: _changeBlockTitle,
    changeBlockParagraph: _changeBlockParagraph,
    changeAdditionalBlockTitle,
    changeAdditionalBlockParagraph,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    imageActions,
    addBlock: idx => dispatcher(addBlock(idx)),
    addImageBlock: idx => dispatcher(addImageBlock(idx)),
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
