// @flow

import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import { translate } from '@editor/common/utils/translations'
import { setEventCreationPopupVisibilityState } from '@actions/editor'
import { RSVP_COMP_CATEGORY } from '@editor/conf/consts'

import { selectEventCreationPopupVisibilityState } from '@selectors'
import { PAGE_BASE } from '@editor/conf/routes'
import { useHistoryWithSearch } from '@hooks'

import { EVENT_CREATION_STEPS, EVENT_ILLUSTRATION } from './consts'
import * as Styled from './styled'
import { TEventCreationPopupProps } from './types'

const EventCreationPopup = ({
  isOpen,
  setEventCreationPopupVisibilityState
}: TEventCreationPopupProps) => {
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(PAGE_BASE)

  const onClose = useCallback(() => {
    setEventCreationPopupVisibilityState(false)
  }, [])

  const onCreateEventBtnClick = useCallback(() => {
    onClose()
    history.push({
      pathname: `${url}/add-component/${1}`,
      activeCompCategory: RSVP_COMP_CATEGORY,
      hasOpenedFromSidebar: true
    })
  }, [])

  return (
    <Styled.EventCreationPopup
      isOpen={isOpen}
      onClose={onClose}
      isMobileFullScreen
    >
      <Styled.PopupContent>
        <Styled.Illustration src={EVENT_ILLUSTRATION} />
        <Styled.Heading>{translate('event_creation_title')}</Styled.Heading>
        <Styled.SecondaryInfoText>
          {translate('event_creation_subtitle')}
        </Styled.SecondaryInfoText>
        <Styled.StepsSection>
          {EVENT_CREATION_STEPS.map((step, idx) => (
            <Styled.Step key={step}>
              <Styled.StepIcon>{idx + 1}</Styled.StepIcon>
              {step}
            </Styled.Step>
          ))}
        </Styled.StepsSection>
        <Styled.Button type="purple" onClick={onCreateEventBtnClick}>
          {translate('create_today_label', true)}
        </Styled.Button>
      </Styled.PopupContent>
    </Styled.EventCreationPopup>
  )
}

const mapStateToProps = (state: TAppState) => ({
  isOpen: selectEventCreationPopupVisibilityState(state)
})

export default connect(mapStateToProps, {
  setEventCreationPopupVisibilityState
})(EventCreationPopup)
