/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_COPYRIGHT':
        draft.copyrightText = action.value
        break
      case 'CHANGE_LABEL':
        draft.formText = action.value
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'REMOVE_SOCIAL':
        const { idx: socialIdx } = action.value
        draft.socials.splice(socialIdx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        const { idx, link } = action.value
        draft.socials[idx].socialUrl = link
        break
      case 'CHANGE_SOCIAL_ICON': {
        const { idx: idx, iconName } = action.value
        draft.socials[idx].icon = iconName
        break
      }
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
      case 'CHANGE_PLACEHOLDER_TEXT':
        draft.placeholder = action.value
        break
      case 'REMOVE_FORM':
        draft.buttonText = ''
        break
      case 'ADD_FORM':
        draft.buttonText = initialState.buttonText
        break
      case 'CHANGE_TITLE_ALIGNMENT':
        draft.formTextAlignment = action.value
        break
    }
  })
}
