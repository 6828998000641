import { translate } from '@editor/common/utils/translations'

import {
  Mercury,
  Neptune,
  Saturn,
  Uranus,
  Pluto,
  Elara,
  Metis,
  Europa,
  Earth,
  Calisto,
  Moon,
  Io,
  Phobos,
  Mars,
  Deimos,
  Pasiphae,
  Venus,
  Jupiter,
  Taygete,
  Lysithea,
  Themisto,
  Ananke,
  Amalthea,
  Sinope,
  Thebe,
  Ganymede,
  Andromeda,
  Corvus,
  Circinus,
  Virgo,
  Sculptor,
  Tucana,
  Draco,
  Sagittarius,
  Ursa,
  Venatici,
  Palette1,
  Palette2,
  Palette3,
  Palette4,
  Palette5,
  Palette6,
  Palette7,
  Palette8,
  Palette9,
  Palette10,
  Palette11,
  Palette12,
  Palette13,
  Palette14,
  Palette15,
  Palette16,
  Palette17,
  Palette18,
  Palette19,
  Palette20,
  Palette21,
  Palette22,
  Palette23,
  Palette24,
  Palette25,
  Palette26,
  Palette27,
  Palette28,
  Palette29
} from './palettes'

export const alternatePalettes = {
  Palette26,
  Palette27,
  Palette28,
  Palette29,
  Palette22,
  Palette23,
  Palette24,
  Palette25,
  Palette16,
  Palette17,
  Palette18,
  Palette19,
  Palette20,
  Palette21,
  Palette11,
  Palette12,
  Palette13,
  Palette14,
  Palette15,
  Palette6,
  Palette7,
  Palette8,
  Palette9,
  Palette10,
  Palette1,
  Palette2,
  Palette3,
  Palette4,
  Palette5,
  Pluto,
  Mercury,
  Neptune,
  Saturn,
  Uranus,
  Elara,
  Metis,
  Europa,
  Earth,
  Calisto,
  Moon,
  Io,
  Phobos,
  Mars,
  Deimos,
  Pasiphae,
  Venus,
  Jupiter,
  Taygete,
  Lysithea,
  Themisto,
  Ananke,
  Amalthea,
  Sinope,
  Thebe,
  Ganymede,
  Andromeda,
  Corvus,
  Circinus,
  Virgo,
  Sculptor,
  Tucana,
  Draco,
  Sagittarius,
  Ursa,
  Venatici
}

export const fontPairs = [
  {
    name: 'Vogue',
    letterCase: 'lowercase',
    primary: 'Bodoni Moda Bold',
    secondary: 'Prata Regular'
  },
  {
    name: 'Chérie',
    letterCase: 'lowercase',
    primary: 'Kurale Regular',
    secondary: 'Baskerville Regular'
  },
  {
    name: 'Highlight',
    letterCase: 'lowercase',
    primary: 'Zilla Slab Highlight Regular',
    secondary: 'Quicksand Regular'
  },
  {
    name: 'Monet',
    letterCase: 'lowercase',
    primary: 'IM FELL English SC Regular',
    secondary: 'Alice Regular'
  },
  {
    name: 'Writebox',
    letterCase: 'lowercase',
    primary: 'BioRhyme Regular',
    secondary: 'Space Mono Regular'
  },
  {
    name: 'Spectrum',
    letterCase: 'lowercase',
    primary: 'Staatliches Regular',
    secondary: 'Raleway Regular'
  },
  {
    name: 'Sketchup',
    letterCase: 'lowercase',
    primary: 'Fredericka the Great Regular',
    secondary: 'Overpass Regular'
  },
  {
    name: 'Hobbit',
    letterCase: 'lowercase',
    primary: 'Caveat Brush Regular',
    secondary: 'Asap Regular'
  },
  {
    name: 'Delaware',
    letterCase: 'lowercase',
    primary: 'Yeseva One Regular',
    secondary: 'Josefin Sans Regular'
  },
  {
    name: 'Liberta',
    letterCase: 'lowercase',
    primary: 'Quicksand Regular',
    secondary: 'Quicksand Regular'
  },
  {
    name: 'Letter',
    letterCase: 'lowercase',
    primary: 'Homemade Apple Regular',
    secondary: 'Raleway Regular'
  },
  {
    name: 'Stronger',
    letterCase: 'lowercase',
    primary: 'Black Han Sans Regular',
    secondary: 'Roboto Mono Regular'
  },
  {
    name: 'Fairy Tale',
    letterCase: 'lowercase',
    primary: 'Rye Regular',
    secondary: 'Alice Regular'
  },
  {
    name: 'Donut',
    letterCase: 'lowercase',
    primary: 'Fredoka One Regular',
    secondary: 'Lato Regular'
  },
  {
    name: 'Bolder',
    letterCase: 'lowercase',
    primary: 'Rubik Mono One Regular',
    secondary: 'Roboto Regular'
  },
  {
    name: 'Tallvetica',
    letterCase: 'lowercase',
    primary: 'Amatic SC Regular',
    secondary: 'Andika Regular'
  },
  {
    name: 'Typeface',
    letterCase: 'lowercase',
    primary: 'Cardo Regular',
    secondary: 'Josefin Sans Regular'
  },
  {
    name: 'Modern',
    letterCase: 'lowercase',
    primary: 'Cinzel Regular',
    secondary: 'Fauna One Regular'
  },
  {
    name: 'Grace Signature',
    letterCase: 'lowercase',
    primary: 'Sacramento Regular',
    secondary: 'Alice Regular'
  },
  {
    name: 'Printing',
    letterCase: 'lowercase',
    primary: 'Special Elite Regular',
    secondary: 'Open Sans Regular'
  },
  {
    name: 'Moulin',
    letterCase: 'lowercase',
    primary: 'Monoton Regular',
    secondary: 'Poppins Regular'
  },
  {
    name: 'Marker',
    letterCase: 'lowercase',
    primary: 'Permanent Marker Regular',
    secondary: 'Open Sans Regular'
  },
  {
    name: 'Philosophy',
    letterCase: 'lowercase',
    primary: 'Philosopher Regular',
    secondary: 'Mukta Regular'
  },
  {
    name: 'Black',
    letterCase: 'lowercase',
    primary: 'Ultra Regular',
    secondary: 'Slabo27px Regular'
  },
  {
    name: 'Titled',
    letterCase: 'lowercase',
    primary: 'Abril Fatface Regular',
    secondary: 'Poppins Regular'
  },
  {
    name: 'Article',
    letterCase: 'lowercase',
    primary: 'Cardo Bold',
    secondary: 'Slabo27px Regular'
  },
  {
    name: 'Playfair',
    letterCase: 'lowercase',
    primary: 'Playfair Display Regular',
    secondary: 'Lato Regular'
  },
  {
    name: 'Crispy',
    letterCase: 'lowercase',
    primary: 'Oswald Bold',
    secondary: 'Eczar Regular'
  },
  {
    name: 'Christmas Card',
    letterCase: 'lowercase',
    primary: 'Great Vibes Regular',
    secondary: 'Alice Regular'
  },
  {
    name: 'Corporate',
    letterCase: 'lowercase',
    primary: 'Montserrat Bold',
    secondary: 'Montserrat Light'
  },
  {
    name: 'Casual',
    letterCase: 'lowercase',
    primary: 'Roboto Slab Bold',
    secondary: 'Lato Regular'
  },
  {
    name: 'Techy',
    letterCase: 'uppercase',
    primary: 'Roboto Condensed Bold',
    secondary: 'Roboto Regular'
  },
  {
    name: 'Monotype',
    letterCase: 'lowercase',
    primary: 'Poppins Bold',
    secondary: 'Poppins Light'
  },
  {
    name: 'Minimal',
    letterCase: 'lowercase',
    primary: 'Rubik Light',
    secondary: 'Rubik Light'
  },
  {
    name: 'Serious',
    letterCase: 'lowercase',
    primary: 'Merriweather Bold',
    secondary: 'Merriweather Regular'
  },
  {
    name: 'Elegant',
    letterCase: 'lowercase',
    primary: 'Playfair Display SC Regular',
    secondary: 'Lato Light'
  },
  {
    name: 'Handwritten',
    letterCase: 'lowercase',
    primary: 'Parisienne Regular',
    secondary: 'Raleway Light'
  },
  {
    name: 'Playful',
    letterCase: 'lowercase',
    primary: 'Chelsea Market',
    secondary: 'Chelsea Market'
  },
  {
    name: 'Headline',
    letterCase: 'lowercase',
    primary: 'Titan One Regular',
    secondary: 'Open Sans SemiBold'
  },
  {
    name: 'Script',
    letterCase: 'lowercase',
    primary: 'Pacifico Regular',
    secondary: 'Lato Regular'
  },
  {
    name: 'Square',
    letterCase: 'lowercase',
    primary: 'Russo One Regular',
    secondary: 'Lato Regular'
  }
]

export const LOOKS = {
  ROUNDED: 'ROUNDED',
  CORNERED: 'CORNERED',
  SHARP: 'SHARP'
}

export const COLOR_MODES = {
  LIGHT: 'LIGHT',
  EXTRALIGHT: 'EXTRALIGHT',
  DARK: 'DARK'
}

//   --- used in Controlled component, which in its turn used in components ---
export const LINK = {
  TO_PAGE: 'to page',
  TO_SECTION: 'to section',
  EXTERNAL_LINK: 'External link'
}

export const COMPONENT_CATEGORY_MAP = {
  header: { name: 'Header', order: 0 },
  feature: { name: 'Features', order: 1 },
  text: { name: 'Text', order: 2 },
  privacyPolicy: { name: 'Privacy policy', order: 3 },
  cta: { name: 'Call to actions', order: 4 },
  pricing: { name: 'Pricing', order: 5 },
  team: { name: 'Team', order: 6 },
  testimonial: { name: 'Testimonials', order: 7 },
  client: { name: 'Clients', order: 8 },
  step: { name: 'Steps', order: 9 },
  post: { name: 'Posts', order: 10 },
  slider: { name: 'Slider', order: 11 },
  portfolio: { name: 'Portfolio', order: 12 },
  video: { name: 'Videos', order: 13 },
  music: { name: 'Music', order: 14 },
  faq: { name: 'FAQ', order: 15 },
  email: { name: 'Email form', order: 16 },
  contact: { name: 'Contacts', order: 17 },
  footer: { name: 'Footer', order: 18 }
}

export const COMPONENT_CATEGORIES = {
  FAQ: 'faq',
  TEAM: 'team',
  STEP: 'step',
  HEADER: 'header',
  SLIDER: 'slider',
  PRICING: 'pricing',
  FEATURE: 'feature',
  CONTACT: 'contact',
  TEXT: 'text',
  CTA: 'cta',
  PORTFOLIO: 'portfolio',
  CLIENT: 'client',
  EMAIL: 'email',
  TESTIMONIAL: 'testimonial',
  POSTS: 'posts',
  VIDEOS: 'videos',
  MUSIC: 'music',
  FOOTER: 'footer',
  PRIVACY_POLICY: 'privacyPolicy',
  RSVP: 'rsvp'
}

export const NO_HEADER_COMPONENT_ID = 'NoHeader'

export const RF_SUBSCIRBER_LIMIT = 0 // check for greater value then 0, means have at least 1 bought item

export const MAX_HISTORY_COUNT = 20

export const TABS = {
  SETTINGS: 'Settings',
  PROFILE: 'Profile',
  PAGES: 'Pages',
  ADDONS: 'Widgets'
}

export const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/mpeg',
  'video/quicktime',
  'video/3gpp',
  'video/MP2T',
  'video/x-flv',
  'video/ogg',
  'application/x-troff-msvideo',
  'video/avi',
  'video/msvideo',
  'video/x-msvideo'
]
export const IMAGE_MIME_TYPES = [
  'image/svg+xml',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/vnd.microsoft.icon'
]

export const ALLOWED_IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif'
]

export const IMAGE_MIME_TO_EXT = {
  'image/jpeg': '.jpg',
  'image/png': '.png',
  'image/bmp': '.bmp',
  'image/gif': '.gif',
  'image/tiff': '.tiff',
  'application/postscript': '.eps',
  'image/svg+xml': '.svg',
  'image/webp': '.webp'
}

export const CURRENT_LANGUAGE =
  typeof window !== 'undefined' && window.CURRENT_LANGUAGE
export const logoEditorLink =
  '/logo-maker/editor?utm_source=website_editor&utm_medium=logo_button&utm_campaign=logo_from_website'
export const videoEditorLink = `/${CURRENT_LANGUAGE}/templates?utm_source=website_editor&utm_medium=video_button&utm_campaign=video_from_website`

export const API_STATUS_CODES = {
  AUTHORIZATION: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  WRONG_AUTHORIZATION: 432,
  MAINTENANCE: 503
  // TODO: Add status codes
}

export const SUCCESS_SAVED_MESSAGE = translate('settings_success_message')
export const SAVED_MESSAGE = translate('saved_label')

export const EMPTY_PAGE_META = {
  title: '',
  keywords: '',
  description: ''
}

export const NEW_COMPONENTS = ['Header57', 'Feature38']
export const HELP_SUPPORT_LINK = 'https://help.renderforest.com/support/home'

export const RSVP_COMP_CATEGORY = 'rsvp'

export const FREE_USER_GUEST_LIMIT = 30

export const UPGRADE_REFERRER = 'website-builder'
export const AI_WIZARD_UPGRADE_REFERRER = 'website-builder-ai-wizard'
export const MAKE_MULTILINGUAL_REFERRER = 'website-builder-make-multilingual'
export const RSVP_MULTILINGUAL_HINT_UPGRADE_REFERRER = 'rsvp-multilingual-hint'

export const RSVP_EMAIL_DEFAULT_SENDER_NAME = 'Renderforest Team'

export const TRANSLATING = 'TRANSLATING'
export const TRANSLATED = 'TRANSLATED'
