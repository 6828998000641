/* @flow */
import styled from 'styled-components'
import Container from '@website/common/components/Container'

const StyledContainer = styled(Container)`
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '150px 0 0 0' : '0')};
  @media only screen and (max-width: 768px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 0 0' : '0')};
  }
  .row-remove {
    color: white;
    padding: 10px;
  }
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
`

const Item = styled.div`
  width: 24%;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    width: 45%;
  }
  .LazyLoad {
    width: 100%;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
const AddButtonWrapper = styled.div`
  display: flex;
  margin: 20px auto;
`

export default {
  StyledContainer,
  RowWrapper,
  AddButtonWrapper,
  Row,
  Item,
  Img,
  ControlContainer
}
