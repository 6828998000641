// @flow
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TSearchInputProps } from './types'
import { scrollToTopOfContent } from '@containers/choose-template/utils'
import InputComponent from '@website/common/components/Input'
import { INITIAL_ACTIVE_CATEGORY_INFO } from '@redux/consts'
import {
  selectTemplatesActiveCategoryInfo,
  selectTemplateSearchTerm
} from '@selectors'
import {
  setTemplatesActiveCategoryInfo,
  setTemplateSearchTerm,
  fetchTemplates
} from '@actions/editor'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'

const SearchInput = ({
  isSticky,
  searchTerm,
  activeCategoryInfo,
  fetchTemplates,
  setTemplateSearchTerm,
  setTemplatesActiveCategoryInfo
}: TSearchInputProps) => {
  const [value, setValue] = useState('')

  const activeCatId = activeCategoryInfo.activeCategory.id

  useEffect(() => {
    setValue(searchTerm)
  }, [searchTerm])

  const onSearch = useCallback(() => {
    if (activeCatId !== 1) {
      setTemplatesActiveCategoryInfo(INITIAL_ACTIVE_CATEGORY_INFO)
    }

    setTemplateSearchTerm(value)
    fetchTemplates(value, 0)
    scrollToTopOfContent()
  }, [value])

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        onSearch()
      }
    },
    [onSearch]
  )

  const onSearchChange = useCallback(e => {
    setValue(e.target.value)
  }, [])

  const onRemoveIconClick = useCallback(() => {
    setValue('')
    setTemplateSearchTerm('')
    fetchTemplates('', 0)
  }, [setTemplateSearchTerm, fetchTemplates])

  return (
    <Styled.InputWrapper isSticky={isSticky}>
      <InputComponent
        value={value}
        placeholder={translate('search_label')}
        borderColor="#C1C9E0"
        borderHoverColor="#8493BD"
        onKeyPress={handleKeyPress}
        onChange={onSearchChange}
        onRemoveIconClick={onRemoveIconClick}
      />
      <Styled.SearchButton onClick={onSearch}>
        <Icon
          name="search_tiny"
          size="medium"
          color="#545f7e"
        />
      </Styled.SearchButton>
    </Styled.InputWrapper>
  )
}

const mapStateToProps = state => ({
  searchTerm: selectTemplateSearchTerm(state),
  activeCategoryInfo: selectTemplatesActiveCategoryInfo(state)
})

const mapDispatchToProps = {
  fetchTemplates,
  setTemplateSearchTerm,
  setTemplatesActiveCategoryInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchInput)
