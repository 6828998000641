/* @flow */
import { useContext } from 'react'
import { EditingContext } from '@contexts'

const Show = ({ when, children }) => {
  const { isEditing } = useContext(EditingContext)
  const falsy = elem => !elem
  if (!isEditing && when.every(falsy)) {
    return null
  }
  return children
}

export default Show
