/* @flow */
/* eslint-disable max-statements */

import React, { useContext, useState, useEffect, memo } from 'react'
import {
  validation,
  defaultPlaceholders,
  EMPTY_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  NAME,
  EMAIL,
  PHONE,
  SUBJECT,
  MESSAGE
} from './Contact12.consts'
import S from './Contact12.styled'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import { secondary } from '@website/common/styles/colors'
import EditableContent from '@website/common/components/EditableContent'
import { removeEmptyFields } from '@website/common/utils'
import SocialLinks from '@website/common/components/SocialLinks'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import { isValidEmail } from '@website/common/utils'
import SubmitButton from '@website/common/components/SubmitButton/SubmitButton'

import {
  DispatchContext,
  EditingContext,
  SiteHashContext,
  SiteIdContext
} from '@contexts'
import { contactApi } from '@website/common/api'

const initialState = {
  name: '',
  email: '',
  phone: '',
  subject: '',
  message: ''
}

const Contact12 = memo(
  ({
    data: {
      title,
      titleAlignment,
      buttonText,
      placeholders,
      labels,
      socials,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const { isEditing } = useContext(EditingContext)
    const siteId = useContext(SiteIdContext)
    const siteHash = useContext(SiteHashContext)
    const Actions = ActionGetter(dispatcher)
    const [nameErrorMessage, setNameErrorMessage] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')

    const [inputData, setInputData] = useState(
      isEditing ? { ...placeholders } : initialState
    )
    const [successState, setSuccessState] = useState(false)
    const [timer, setTimer] = useState(null)

    useEffect(() => {
      if (timer) {
        setTimer(null)
      }
    })

    useEffect(() => {
      if (isEditing) {
        setInputData({ ...placeholders })
      }
    }, [placeholders])

    const handleNameError = () => {
      if (!inputData.name) {
        setNameErrorMessage(EMPTY_FIELD_MESSAGE)
      }
    }

    const handleEmailError = () => {
      if (!inputData.email) {
        setEmailErrorMessage(EMPTY_FIELD_MESSAGE)
      }
      if (inputData.email && !isValidEmail(inputData.email)) {
        setEmailErrorMessage(INVALID_EMAIL_MESSAGE)
      }
    }

    const evtHandlerGetter = (type: string) => {
      const evtHandlers = {
        onChange: (e: Event) => {
          setInputData({ ...inputData, [type]: e.target.value })
          if (type === NAME) {
            setNameErrorMessage('')
          }
          if (type === EMAIL) {
            setEmailErrorMessage('')
          }
        }
      }
      evtHandlers.onBlur = (e: Event) => {
        if (isEditing) {
          const value = e.target.value || defaultPlaceholders[type]
          if (value === placeholders[type]) {
            return
          }
          Actions.changePlaceHolderText(type, value)
          setInputData({ ...inputData, [type]: value })
        } else {
          if (type === NAME) {
            handleNameError()
          }
          if (type === EMAIL) {
            handleEmailError()
          }
        }
      }
      return evtHandlers
    }

    const sendData = () => {
      if (isEditing) {
        return
      }
      handleNameError()
      handleEmailError()
      if (isValidEmail(inputData.email) && inputData.name) {
        contactApi(
          siteId,
          removeEmptyFields({
            ...inputData,
            siteHash
          })
        )
          .then(() => {
            setSuccessState(true)
            setTimer(
              setTimeout(() => {
                setInputData(initialState)
                setSuccessState(false)
              }, 2000)
            )
          })
          .catch(console.warn)
      }
    }

    const getPlaceHolderText = key => (placeholders && placeholders[key]) || ''

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
        isEditing={isEditing}
      >
        <S.WmCustomContainer
          isSocialBlock={socials.length}
          isEditing={isEditing}
        >
          <S.CardContainer isSocialBlock={socials.length} isEditing={isEditing}>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              maxCount={validation.headingMaxChar}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
              className="WM_GLOBAL_heading"
              required
            />
            <S.ContentWrapper>
              <S.FormContainer
                isSocialBlock={socials.length}
                isEditing={isEditing}
              >
                <S.InputsWrapper>
                  <S.InputContainer>
                    <Icon
                      color={secondary.Normal}
                      size="normal"
                      name="icon-asterisk"
                      className="required-icon"
                    />
                    <EditableContent
                      text={labels.name}
                      as={S.Label}
                      isEditing={isEditing}
                      maxCount={validation.labelMaxChar}
                      required={validation.name.required}
                      onChange={(newLabel: string) => {
                        Actions.changeLabel(NAME, newLabel)
                      }}
                      className="WM_GLOBAL_paragraph14"
                      for="name"
                    />
                    <S.Input
                      value={inputData.name}
                      placeholder={isEditing ? '' : getPlaceHolderText(NAME)}
                      isEditing={isEditing}
                      aria-label="Name input"
                      className="WM_GLOBAL_secondary-font"
                      {...evtHandlerGetter(NAME)}
                      isError={nameErrorMessage}
                    />
                    {nameErrorMessage && (
                      <S.ErrorMessage>
                        <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                        {nameErrorMessage}
                      </S.ErrorMessage>
                    )}
                  </S.InputContainer>
                  <S.InputContainer>
                    <Icon
                      color={secondary.Normal}
                      size="normal"
                      name="icon-asterisk"
                      className="required-icon"
                    />
                    <EditableContent
                      text={labels.email}
                      as={S.Label}
                      maxCount={validation.labelMaxChar}
                      isEditing={isEditing}
                      required={validation.email.required}
                      onChange={(newLabel: string) => {
                        Actions.changeLabel(EMAIL, newLabel)
                      }}
                      className="WM_GLOBAL_paragraph14"
                      for="email"
                    />
                    <S.Input
                      value={inputData.email}
                      placeholder={isEditing ? '' : getPlaceHolderText(EMAIL)}
                      isEditing={isEditing}
                      aria-label="Email input"
                      className="WM_GLOBAL_secondary-font"
                      {...evtHandlerGetter(EMAIL)}
                      isError={emailErrorMessage}
                    />
                    {emailErrorMessage && (
                      <S.ErrorMessage>
                        <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                        {emailErrorMessage}
                      </S.ErrorMessage>
                    )}
                  </S.InputContainer>
                </S.InputsWrapper>
                <S.InputsWrapper>
                  <S.InputContainer>
                    <EditableContent
                      text={labels.phone}
                      as={S.Label}
                      maxCount={validation.labelMaxChar}
                      required={validation.phone.required}
                      isEditing={isEditing}
                      onChange={(newLabel: string) => {
                        Actions.changeLabel(PHONE, newLabel)
                      }}
                      className="WM_GLOBAL_paragraph14"
                      for="phone"
                    />
                    <S.Input
                      value={inputData.phone}
                      placeholder={isEditing ? '' : getPlaceHolderText(PHONE)}
                      isEditing={isEditing}
                      aria-label="Email input"
                      className="WM_GLOBAL_secondary-font"
                      {...evtHandlerGetter(PHONE)}
                    />
                  </S.InputContainer>
                  <S.InputContainer>
                    <EditableContent
                      text={labels.subject}
                      as={S.Label}
                      maxCount={validation.labelMaxChar}
                      required={validation.subject.required}
                      isEditing={isEditing}
                      onChange={(newLabel: string) => {
                        Actions.changeLabel(SUBJECT, newLabel)
                      }}
                      className="WM_GLOBAL_paragraph14"
                      for="subject"
                    />
                    <S.Input
                      value={inputData.subject}
                      placeholder={isEditing ? '' : getPlaceHolderText(SUBJECT)}
                      isEditing={isEditing}
                      aria-label="Email input"
                      className="WM_GLOBAL_secondary-font"
                      {...evtHandlerGetter(SUBJECT)}
                    />
                  </S.InputContainer>
                </S.InputsWrapper>
                <S.TextAreaContainer>
                  <EditableContent
                    text={labels.message}
                    as={S.Label}
                    maxCount={validation.labelMaxChar}
                    required={validation.message.required}
                    isEditing={isEditing}
                    onChange={(newLabel: string) => {
                      Actions.changeLabel(MESSAGE, newLabel)
                    }}
                    className="WM_GLOBAL_paragraph14"
                    for="message"
                  />
                  <S.TextArea
                    rows={8}
                    value={inputData.message}
                    placeholder={isEditing ? '' : getPlaceHolderText(MESSAGE)}
                    isEditing={isEditing}
                    data-gramm_editor="false" // for grammarly extension
                    aria-label="Message input"
                    className="WM_GLOBAL_secondary-font"
                    {...evtHandlerGetter(MESSAGE)}
                  />
                </S.TextAreaContainer>
              </S.FormContainer>
              <SocialLinks
                className="header-socials"
                socials={socials}
                socialsMaxCount={validation.socials.max}
              />
            </S.ContentWrapper>
            <SubmitButton
              buttonText={buttonText}
              submitFormData={sendData}
              showSubmitNotification={successState}
            />
          </S.CardContainer>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Contact12
