/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { tabIdx, newTitle } = action.value
        draft.galleryTab[tabIdx].title = newTitle
        break
      }
      case 'ADD_ROW': {
        const { galleryTabIdx, galleryRowIdx } = action.value
        draft.galleryTab[galleryTabIdx].gallery.splice(
          galleryRowIdx + 1,
          0,
          initialState.galleryTab[0].gallery[0]
        )
        break
      }
      case 'ADD_TAB':
        draft.galleryTab.push(initialState.galleryTab[0])
        break
      case 'REMOVE_BLOCK':
        const { idx: tabIdx } = action.value
        draft.galleryTab.splice(tabIdx, 1)
        break
      case 'REMOVE_ROW':
        {
          const { galleryTabIdx, galleryRowIdx } = action.value
          draft.galleryTab[galleryTabIdx].gallery.splice(galleryRowIdx, 1)
        }
        break
      case 'CHANGE_IMAGE': {
        const { url, galleryTabIdx, galleryRowIdx, itemIdx, dimensions } =
          action.value
        draft.galleryTab[galleryTabIdx].gallery[galleryRowIdx][itemIdx].imgUrl =
          url
        draft.galleryTab[galleryTabIdx].gallery[galleryRowIdx][
          itemIdx
        ].imgDimensions = dimensions || {}
        draft.galleryTab[galleryTabIdx].gallery[galleryRowIdx][
          itemIdx
        ].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, galleryTabIdx, galleryRowIdx, itemIdx } =
          action.value
        draft.galleryTab[galleryTabIdx].gallery[galleryRowIdx][
          itemIdx
        ].imgCropParams = imgCropParams
      }
    }
  })
}
