/* @flow */
import React from 'react'
import Swipe from '../Swipe'
import S from './Feature18.styled'
import { validation } from './Feature18.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import { secondary } from '@website/common/styles/colors'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled/'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { DispatchContext, withEditingContext } from '@contexts'
import SocialLinks from '@website/common/components/SocialLinks'
import { translate } from '@editor/common/utils/translations'

const ContainerWithEditingContext = withEditingContext(S.Container)
const ImageContainerWithEditingContext = withEditingContext(S.ImageContainer)
const SliderItemWrapperWithEditingContext = withEditingContext(
  S.SliderItemWrapper
)
const SliderWrapperWithEditingContext = withEditingContext(S.SliderWrapper)

class Feature18 extends React.PureComponent<void, void> {
  renderDots = (isActive: boolean) => <S.Dot isActive={isActive} />
  renderContent = Actions => (slideIdx: number, stopAutoPlay: Function) => {
    const { slides } = this.props.data
    return (
      <SliderItemWrapperWithEditingContext>
        <S.ControlContainer>
          <ControlsWithImageCropper
            key={slideIdx}
            toPropagate={false}
            actions={Actions.sliderActions({
              showRemove: slides.length > 1,
              slideIdx,
              clickHandler: stopAutoPlay,
              imageUrl: slides[slideIdx].url
            })}
          >
            <Image
              as={S.Img}
              defaultImgSrc={slides[slideIdx].url}
              sizes={slides[slideIdx].imgDimensions}
              alt="Section image"
              asProps={{
                className: 'text-image',
                imgCropParams: slides[slideIdx].imgCropParams
              }}
            />
          </ControlsWithImageCropper>
        </S.ControlContainer>
      </SliderItemWrapperWithEditingContext>
    )
  }

  renderSlider = Actions => {
    const { slides, socials } = this.props.data
    return (
      <SliderWrapperWithEditingContext className="image-wrap">
        <>
          <Swipe
            dataArray={slides}
            renderDots={this.renderDots}
            renderContent={this.renderContent(Actions)}
          />
        </>
        <SocialLinks
          className="header-socials"
          socials={socials}
          socialsMaxCount={validation.socials.max}
        />
        <S.AddButtonWrap>
          <AddButton
            style={{
              background: '#fff',
              color: secondary.Dark,
              borderColor: secondary.Dark
            }}
            onAdd={Actions.addSlide}
            toShow={slides.length < validation.slides.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_slide_label')}
          </AddButton>
        </S.AddButtonWrap>
      </SliderWrapperWithEditingContext>
    )
  }

  renderTextContent = Actions => {
    const {
      data: { title, paragraph, titleAlignment, paragraphAlignment, mainImage }
    } = this.props
    return (
      <S.HeaderContentWrapper>
        <ImageContainerWithEditingContext>
          <S.ImageWrapper>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading32"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <S.ControlContainer>
              <ControlsWithImageCropper
                actions={Actions.imgActions(mainImage.url)}
              >
                <Image
                  as={S.Img}
                  defaultImgSrc={mainImage.url}
                  sizes={mainImage.imgDimensions}
                  alt="Header image"
                  asProps={{
                    className: 'text-image',
                    imgCropParams: mainImage.imgCropParams
                  }}
                />
              </ControlsWithImageCropper>
            </S.ControlContainer>
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.ImageWrapper>
        </ImageContainerWithEditingContext>
      </S.HeaderContentWrapper>
    )
  }

  renderSocialIcon = Actions => (social, idx) => {
    return (
      <ControlsWithModalOpener
        key={idx}
        actions={Actions.socialActions(idx, social.socialUrl)}
        alignment="right"
      >
        <LinkWrapper
          url={social.socialUrl}
          linkProps={{ 'aria-label': 'Social link' }}
        >
          <S.Icon size="middle" name={social.icon} />
        </LinkWrapper>
      </ControlsWithModalOpener>
    )
  }

  render() {
    const Actions = ActionGetter(this.context)
    return (
      <ContainerWithEditingContext>
        <S.WmCustomContainer className="feature-18-container">
          {this.renderTextContent(Actions)}
          {this.renderSlider(Actions)}
        </S.WmCustomContainer>
      </ContainerWithEditingContext>
    )
  }
}

Feature18.contextType = DispatchContext

export default Feature18
