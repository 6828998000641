export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    activeTab: p => p.primaryDark,
    inactiveTab: p => p.tertiaryNormal,
    background: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    activeTab: p => p.primaryDark,
    inactiveTab: p => p.tertiaryNormal,
    background: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    activeTab: p => p.primaryDark,
    inactiveTab: p => p.tertiaryNormal,
    background: p => p.tertiaryExtraLight
  }
}
