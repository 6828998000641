/* @flow */
import styled from 'styled-components'
import THEME from './Header9.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0 0 80px 0;
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: 457px) {
    padding: 0 0 60px 0;
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: 60px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  ${({ isEditing }) => (isEditing ? 'margin-top: 10px;' : null)}
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  @media only screen and (max-width: 1420px) {
    .buttons-block {
      margin-left: -15px;
    }
  }
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  .lazy-figure {
    padding-top: 62.25%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    & > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  > :first-child {
    margin-top: 0;
  }
`

const HeaderContent = styled.div`
  width: 50%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 60px;
  @media only screen and (max-width: 1200px) {
    margin-right: 0;
    width: 100%;
    max-width: 800px;
    align-items: center;
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
      margin-left: 0;
      padding-top: 0;
    }
  }
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
  .iframe-video {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 1200px) {
    padding-top: 56.25%;
  }
`

const VideoContent = styled.div`
  width: 50%;
  max-width: 800px;
  @media only screen and (max-width: 1200px) {
    margin-top: 50px;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
  }
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  margin-left: 15px;
  @media only screen and (max-width: 1420px) {
    margin-left: 0;
  }
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  margin-left: 15px;
  padding-top: 20px;
  @media only screen and (max-width: 1420px) {
    margin-left: 0;
  }
`

const NotebookWrap = styled.div`
  width: 100%;
  height: 100%;
`

const Img = styled.img`
  border-radius: ${getStyleForProp('badgeRadius')};
`

export default {
  Img,
  StyledContainer,
  WmCustomContainer,
  VideoWrap,
  VideoContent,
  HeaderContent,
  Title,
  Description,
  NotebookWrap
}
