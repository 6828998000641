/* @flow */
import styled from 'styled-components'
import THEME from './Header35.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  box-sizing: border-box;
  word-break: break-word;
  background-color: ${getStyleForProp('background')};
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0 0 120px 0' : `${topOffset}px 0 80px 0`};
  @media only screen and (max-width: 1200px) {
    padding: ${({ isEditing, topOffset }) =>
      isEditing ? '0 0 100px 0' : `${topOffset}px 0 80px 0`};
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: 60px;
  }
`

const BgContainer = styled.div`
  ${getBackgroundStyles(THEME)};
`

const WmCustomContainer = styled(WMContainer)`
  margin-top: -140px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    margin: -150px auto 0 auto;
  }
  @media only screen and (max-width: 768px) {
    margin: -220px auto 0 auto;
    padding: 70px 30px 0 30px;
  }
  @media only screen and (max-width: 457px) {
    margin: -220px auto 0 auto;
    padding: 70px 20px 0 20px;
  }
`

const AddBtnWrap = styled.div`
  position: absolute;
  bottom: -100px;
`

const HeaderContent = styled.div`
  text-align: center;
  padding: 120px 0 180px 0;
  @media only screen and (max-width: 650px) {
    padding: 60px 0 180px 0;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const TextsWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  & > .control-container {
    width: 100%;
  }
  > *:first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const BlocksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }
`

const ControlWrapper = styled.div`
  display: flex;
  margin: 10px;
  width: 19%;
  max-width: 300px;
  flex-grow: 1;
  & > .control-container {
    display: flex;
  }
  @media only screen and (max-width: 1200px) {
    width: 45%;
    max-width: 320px;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
    max-width: 300px;
    margin: 10px 0;
  }
`

const BlockItem = styled.article`
  width: 100%;
  height: 100%;
  min-height: 234px;
  padding: 40px 20px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: ${getStyleForProp('raisedShadow')};
  background: white;
  & > .control-container {
    width: 100%;
    & > h2 {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 430px) {
    padding: 20px;
  }
  .link-wrapper {
    margin-top: 0;
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  width: 100%;
  ${getTextStyleByAlignment};
`

const Circle = styled.div`
  width: 66px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('iconRadius')};
  background: ${getStyleForProp('buttonBackground')};
  pointer-events: none;
`

const IconWrapper = styled.div`
  width: 66px;
  height: 66px;
  margin: 0 0 15px 0;
  user-select: none;
`

export default {
  Container,
  WmCustomContainer,
  BgContainer,
  HeaderContent,
  TextsWrap,
  Title,
  Paragraph,
  BlocksContainer,
  ControlWrapper,
  BlockItem,
  BlockTitle,
  IconWrapper,
  Circle,
  AddBtnWrap
}
