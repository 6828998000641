/* @flow */
import {
  iconActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  addIcon,
  changeTitle,
  removeBlock,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeDescription = newDescr => {
    dispatcher({
      type: 'CHANGE_DESCRIPTION',
      value: newDescr
    })
  }

  const changeBlockContent = (newContent: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_CONTENT',
      value: { newContent, idx }
    })
  }

  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx }
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeContentAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_CONTENT_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  return {
    addIcon: idx => dispatcher(addIcon(idx)),
    addBlock: () => dispatcher(addBlock()),
    removeBlock,
    changeTitle: _changeTitle,
    changeDescription,
    changeBlockTitle,
    changeBlockContent,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeContentAlignment,
    getIconActions: iconActionMaker(dispatcher),
    featureActions: removeBlockActionMaker(dispatcher)
  }
}
