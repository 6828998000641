/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'REMOVE_SOCIAL':
        const { idx: socialIdx } = action.value
        draft.socials.splice(socialIdx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        const { idx, link } = action.value
        draft.socials[idx].socialUrl = link
        break
      case 'CHANGE_SOCIAL_ICON': {
        const { idx: idx, iconName } = action.value
        draft.socials[idx].icon = iconName
        break
      }
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_PLACEHOLDER_TEXT':
        const { key, value } = action.value
        if (draft.placeholders) {
          draft.placeholders[key] = value
        } else {
          draft.placeholders = { [key]: value }
        }
        break
      case 'CHANGE_LABEL': {
        const { key, newLabel } = action.value
        if (draft.labels) {
          draft.labels[key] = newLabel
        } else {
          draft.labels = { [key]: newLabel }
        }
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT':
        draft.titleAlignment = action.value
        break
    }
  })
}
