import React, { useCallback, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { selectSemrushKeywordsData } from '@selectors'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import SemrushFilterAscIcon from '@editor/common/assets/svgr-icons/semrush-filter_asc-icon.svg'
import SemrushFilterDescIcon from '@editor/common/assets/svgr-icons/semrush-filter_desc-icon.svg'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import SERPFeaturesFlyout from './components/SERPFeaturesFlyout'
import { filterColumns } from './utils'
import { COLUMNS, COMPONENTS_BY_COLUMN } from './consts'
import TooltipContent from '../../TooltipContent'
import * as Styled from './styled.js'
import { useResponsive } from '@shared/hooks'

const KeywordTable = ({
  className = '',
  sortingInfo,
  onSortingInfoChange,
  keywordsData,
  targetRef,
  isTableHeadSticky,
  isFeaturesFlyoutOpened,
  setFeaturesFlyoutOpenedState
}) => {
  const [featuresFlyoutOptions, setFeaturesFlyoutOptions] = useState([])
  const [serpFeturesRef, setSerpFetures] = useState(null)

  const { payload = {} } = keywordsData
  const { columnNames = [], rows = [] } = payload

  const isSmallSize = useResponsive(1024)

  const columns = useMemo(() => {
    return filterColumns(columnNames, COLUMNS)
  }, [columnNames])

  const handleColumnClick = useCallback(
    (columnKey, isSortable) => {
      if (isSortable) {
        const isAscending =
          sortingInfo.columnKey !== columnKey
            ? sortingInfo.isAscending
            : !sortingInfo.isAscending

        onSortingInfoChange({
          columnKey,
          isAscending
        })
      }
    },
    [sortingInfo]
  )

  const onSERPHiddenFeatuesClick = useCallback(
    (target, hiddenSERPFeatures) => {
      setFeaturesFlyoutOptions(hiddenSERPFeatures)
      setSerpFetures(target)
      if (isFeaturesFlyoutOpened && serpFeturesRef !== target) {
        return
      }
      setFeaturesFlyoutOpenedState(
        isFeaturesFlyoutOpened => !isFeaturesFlyoutOpened
      )
    },
    [serpFeturesRef, isFeaturesFlyoutOpened]
  )

  const closeFeaturesFLyout = useCallback(() => {
    setFeaturesFlyoutOpenedState(false)
  }, [])

  return (
    <Styled.TableContainer fullHeight={!rows.length} className={className}>
      <Styled.Table ref={targetRef} isSticky={isTableHeadSticky}>
        <Styled.TableHead isSticky={isTableHeadSticky}>
          <tr className="first-block" key="column">
            {columns.map(
              ({ isSortable, label, key, tooltipText, tooltipTitle, link }) => (
                <Styled.ColumnName
                  key={label}
                  isActive={sortingInfo.columnKey === key}
                  isSortable={isSortable}
                  onClick={() => {
                    handleColumnClick(key, isSortable)
                  }}
                >
                  <Styled.ColumnHead>
                    {label}
                    {label !== 'Keyword' && !isSmallSize ? (
                      <TooltipWrapper
                        text={
                          <TooltipContent
                            title={tooltipTitle}
                            description={tooltipText}
                            link={link}
                          />
                        }
                        className="info-icon-tooltip"
                      >
                        <InfoIcon />
                      </TooltipWrapper>
                    ) : null}
                    {isSortable ? (
                      <Styled.IconWrapper
                        isActive={sortingInfo.columnKey === key}
                      >
                        {sortingInfo.isAscending ? (
                          <SemrushFilterAscIcon />
                        ) : (
                          <SemrushFilterDescIcon />
                        )}
                      </Styled.IconWrapper>
                    ) : null}
                  </Styled.ColumnHead>
                </Styled.ColumnName>
              )
            )}
          </tr>
        </Styled.TableHead>
        {rows.length ? (
          <>
            <tbody>
              {rows.map((row, i) => (
                <tr className="second-block" key={i}>
                  {row.map((item, idx) => {
                    const key = columns[idx].key
                    const Comp = COMPONENTS_BY_COLUMN[key]
                    const additionalProps = {}

                    if (key === 'Fk') {
                      additionalProps.onSERPHiddenFeatuesClick =
                        onSERPHiddenFeatuesClick
                    }

                    return (
                      <td key={idx}>
                        {Comp ? (
                          <Comp data={item} {...additionalProps} />
                        ) : (
                          item
                        )}
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
            <SERPFeaturesFlyout
              targetRef={serpFeturesRef}
              onClose={closeFeaturesFLyout}
              isOpen={isFeaturesFlyoutOpened}
              options={featuresFlyoutOptions}
            />
          </>
        ) : null}
      </Styled.Table>
      {!rows.length ? (
        <Styled.NoResultsWrapper>
          <Styled.NoResults>
            {translate('semrush_keywords_noresults_label')}
          </Styled.NoResults>
        </Styled.NoResultsWrapper>
      ) : null}
    </Styled.TableContainer>
  )
}

const mapStateToProps = state => ({
  keywordsData: selectSemrushKeywordsData(state)
})

export default connect(mapStateToProps)(KeywordTable)
