// @flow

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import {
  getProjectData,
  selectIsInitialLogoApplying,
  selectIsUserAuthorized
} from '@selectors'
import {
  applySitePreset,
  initializeSitePreset
} from '@actions/project/site-preset'
import { getQueryStringParam } from '@website/common/utils'

import Preview from '../../Preview'
import { translate } from '@editor/common/utils/translations'

const mapStateToProps = state => ({
  projectData: getProjectData(state),
  isInitialLogoApplying: selectIsInitialLogoApplying(state),
  isUserAuthorized: selectIsUserAuthorized(state)
})

const SitePresetPreview = ({
  projectData,
  isInitialLogoApplying,
  applySitePreset,
  initializeSitePreset,
  isUserAuthorized
}) => {
  const { search } = useLocation()
  const { lang } = useParams()
  const logoId = getQueryStringParam('logoId')

  useEffect(() => {
    if (!!logoId && isUserAuthorized) {
      initializeSitePreset(logoId)
    }
  }, [isUserAuthorized])

  const iframeSrc = `${window.location.origin}/website-maker/preview/${lang}/site-preset/${search}`

  return (
    <Preview
      iframeSrc={iframeSrc}
      projectData={{
        isLoading: isInitialLogoApplying,
        payload: {
          data: projectData
        }
      }}
      siteHash={projectData?.hash}
      applyPreset={applySitePreset}
      showOpenInNewTabBtn={false}
      btnLabel={translate('edit_label', true)}
    />
  )
}

export default connect(mapStateToProps, {
  applySitePreset,
  initializeSitePreset
})(SitePresetPreview)
