/* @flow */
import React, { useContext } from 'react'
import './Swipe.styles.sass'
import Slider from 'react-slick'
import './swipe-slider-styles.css'
import { slickSliderSettings } from './swipe.config'
import If from '@website/common/components/Conditional'
import Mapper from '@website/common/components/Mapper'
import { EditingContext } from '@contexts'

export const Swipe = ({
  dataArray,
  renderDots,
  autoplaySpeed = 3000,
  slideSpeed = 300,
  renderContent,
  renderLeftIcon,
  renderRightIcon,
  verticalSwiping = false,
  arrowControlClassName
}) => {
  const [srcIdx, forceSetIdx] = React.useState(0)
  const { isEditing } = useContext(EditingContext)
  const nextIdx = (srcIdx + 1) % dataArray.length
  const prevIdx = (srcIdx + dataArray.length - 1) % dataArray.length

  const sliderSettings = {
    ...slickSliderSettings,
    autoplaySpeed,
    speed: slideSpeed,
    autoplay: !isEditing,
    swipeToSlide: !isEditing,
    swipe: !isEditing,
    accessibility: !isEditing,
    verticalSwiping: verticalSwiping
  }
  const sliderRef = React.useRef(null)

  const doSwipe = (index: number) => {
    forceSetIdx(index)
    sliderRef.current.slickGoTo(index)
  }
  const onSlideChange = (e: Event) => {
    const newImgIndex = e === 'left' ? nextIdx : prevIdx
    forceSetIdx(newImgIndex)
  }

  const leftIcon = () => (
    <div className={arrowControlClassName} onClick={() => doSwipe(prevIdx)}>
      {renderLeftIcon()}
    </div>
  )

  const rightIcon = () => (
    <div className={arrowControlClassName} onClick={() => doSwipe(nextIdx)}>
      {renderRightIcon()}
    </div>
  )

  const dots = () => (
    <div className="dots-wrap">
      <div className="rf-image-slider-dots-container">
        <Mapper
          data={dataArray}
          render={(_, idx) => {
            const isActive = idx === srcIdx
            return (
              <div key={idx} onClick={() => doSwipe(idx)}>
                {renderDots(isActive)}
              </div>
            )
          }}
        />
      </div>
    </div>
  )

  const sliderContent = (_: any, index: number) => (
    <div className="rf-image-slider" key={index}>
      {renderContent(index)}
    </div>
  )

  return (
    <React.Fragment>
      <If
        then={leftIcon}
        className="prev"
        condition={!!renderLeftIcon && dataArray.length > 1}
      />
      <div className="slick-slider-container">
        <Slider
          className="swipe-slider"
          ref={sliderRef}
          {...sliderSettings}
          onSwipe={onSlideChange}
          afterChange={forceSetIdx}
        >
          {dataArray.map(sliderContent)}
        </Slider>
      </div>
      <If
        then={rightIcon}
        condition={!!renderRightIcon && dataArray.length > 1}
      />
      <If
        then={dots}
        className="next"
        condition={!!renderDots && dataArray.length > 1}
      />
    </React.Fragment>
  )
}
