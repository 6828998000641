// @flow

export const getRightOffsetFromActive = (
  idx: number,
  activeIdx: number,
  length: number
): number => (idx + length - activeIdx) % length

export const getLeftOffsetFromActive = (
  idx: number,
  activeIdx: number,
  length: number
): number => (activeIdx + length - idx) % length
