/* @flow */
import React, { PureComponent } from 'react'
import S from './Form.styled'
import ActionGetter from '../Actions'
import { validation } from '../Contact5.consts'
import { compose } from '@website/common/utils'
//inputComponents
import DateInputs from './DateInput'
import ServiceInput from './ServiceInput'
import DetailsInput from './DetailsInput'
import PersonalDataInput from './PersonalDataInput'
//lib components
import { isRequiredField } from './Form.util'
import If from '@website/common/components/Conditional'
import EditableContent from '@website/common/components/EditableContent'
import SubmitButton from '@website/common/components/SubmitButton/SubmitButton'
import { isValidEmail } from '@website/common/utils'
import { submitContactFormAPI } from '@website/common/api'
import {
  withDispatchContext,
  withEditingContext,
  withSiteId,
  withSiteHash
} from '@contexts'
//style
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/lib/style.css'

const INITIAL_STATE = {
  isMessageVisible: false,
  showSubmitNotification: false,
  visibilityMessages: {
    service: false,
    fullName: false,
    email: false,
    phone: false,
    details: false
  },
  formData: {
    date: '',
    time: '', //string ex 13:00 PM
    service: '',
    fullName: '',
    email: '',
    phone: '',
    details: '',
    siteHash: ''
  }
}
class Form extends PureComponent<void, void> {
  datePicker: null
  state = INITIAL_STATE

  showMessage = () => this.setState({ isMessageVisible: true })
  hideSubmitNotification = () =>
    this.setState({ showSubmitNotification: false })

  handleSubmitSuccess = () => {
    this.setState({ ...INITIAL_STATE, showSubmitNotification: true })
    setTimeout(this.hideSubmitNotification, 4000)
  }

  areValidFields = () => {
    const { formData } = this.state
    const { requiredFields } = this.props.data
    const invalidFieldsCount = Object.keys(formData).filter(
      key => !formData[key] && isRequiredField(key, requiredFields)
    ).length
    if (formData.email) {
      return invalidFieldsCount === 0 && isValidEmail(formData.email)
    }
    return invalidFieldsCount === 0
  }

  submitFormData = () => {
    const { siteId, siteHash } = this.props
    const { formData } = this.state
    if (!siteId) {
      return
    }

    if (this.areValidFields()) {
      submitContactFormAPI(siteId, {
        ...formData,
        siteHash
      }).then(this.handleSubmitSuccess)
    } else {
      this.showMessage()
    }
  }

  setFormValue = (value, key) => {
    const { formData } = this.state
    const { visibilityMessages } = this.state
    this.setState({
      formData: { ...formData, [key]: value }
    })
    this.setState({
      visibilityMessages: { ...visibilityMessages, [key]: false }
    })
  }

  setVisibilityMessages = key => {
    const { visibilityMessages } = this.state
    const { formData } = this.state
    if (formData[key] === '') {
      this.setState({
        visibilityMessages: { ...visibilityMessages, [key]: true }
      })
    }
    if (!isValidEmail(formData.email)) {
      this.setState({
        visibilityMessages: { ...visibilityMessages, email: true }
      })
    }
  }

  renderFormInput = As => {
    const { data: form } = this.props
    const { formData, visibilityMessages, isMessageVisible } = this.state
    const { requiredFields } = form
    return (
      <As
        data={form}
        formData={formData}
        visibilityMessages={visibilityMessages}
        requiredFields={requiredFields}
        setFormValue={this.setFormValue}
        isMessageVisible={isMessageVisible}
        setVisibilityMessages={this.setVisibilityMessages}
      />
    )
  }
  render() {
    const { data: form, isEditing, componentDispatcher } = this.props
    const { requiredFields } = form
    const Actions = ActionGetter(componentDispatcher)
    const { formData, showSubmitNotification, isMessageVisible } = this.state
    return (
      <S.FormContainer
        showSelectInputError={
          !isEditing &&
          isMessageVisible &&
          isRequiredField('service', requiredFields) &&
          !formData.service
        }
        onClick={this.onBackdropClick}
        isEditing={isEditing}
      >
        <EditableContent
          text={form.title}
          as={S.FormTitle}
          alignment={form.titleAlignment}
          className="WM_GLOBAL_heading32"
          maxCount={validation.headingMaxChar}
          onChange={newTitle =>
            componentDispatcher(Actions.changeFormData(newTitle, 'title'))
          }
          changeAlignment={Actions.changeTitleAlignment}
        />
        <DateInputs
          data={form}
          formData={formData}
          setFormValue={this.setFormValue}
          isMessageVisible={isMessageVisible}
          isEditing={isEditing}
        />
        {this.renderFormInput(ServiceInput)}
        {this.renderFormInput(PersonalDataInput)}
        {this.renderFormInput(DetailsInput)}
        <S.FormButtonWrapper>
          <SubmitButton
            buttonText={form.buttonText}
            submitFormData={this.submitFormData}
            showSubmitNotification={showSubmitNotification}
          />
        </S.FormButtonWrapper>
        <If
          condition={isEditing}
          then={() => <S.Hint>*indicates a required field</S.Hint>}
        />
      </S.FormContainer>
    )
  }
}

export default compose(
  withEditingContext,
  withDispatchContext,
  withSiteId,
  withSiteHash
)(Form)
