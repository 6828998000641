/* @flow */
import {
  cropableImageModalActionMaker,
  paypalButtonModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeParagraph,
  changeParagraphAlignment,
  changeTitleAlignment
} from '@website/components/CommonActions'
import { validation } from '../../PayPal2/PayPal2.consts'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeName = (newName: string) => {
    dispatcher({
      type: 'CHANGE_NAME',
      value: newName
    })
  }

  const changeNameAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: alignment
    })
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const addLink = (idx: number) =>
    dispatcher({
      type: 'ADD_LINK',
      value: { idx }
    })

  const removeImage = (idx: number) =>
    dispatcher({
      type: 'REMOVE_IMAGE',
      value: { idx }
    })

  const addSlide = (activeIdx: number) => {
    dispatcher({
      type: 'ADD_SLIDE',
      value: activeIdx
    })
  }

  const imageActions = (
    imageUrl: string,
    idx: number,
    slidesCount: number,
    beforeRemove
  ) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'paypal2',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    }),
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        beforeRemove && beforeRemove()
        removeImage(idx)
      },
      toShow: slidesCount > validation.slides.min
    }
  ]

  return {
    changeName,
    addLink,
    changeNameAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraph: _changeParagraph,
    changeParagraphAlignment: _changeParagraphAlignment,
    addSlide,
    imageActions,
    paypalButtonModalActions: paypalButtonModalActionMaker(dispatcher)
  }
}
