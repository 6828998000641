import styled from 'styled-components'

export const GridLayout = styled.div`
  display: flex;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
`

export const Column = styled.div`
  flex-grow: 1;
  width: 100%;
  @media only screen and (min-width: 1201px) {
    max-width: calc(50% - 13.3px);
  }
  &:not(:last-child) {
    margin-right: ${({ gap }) => `${gap}px`};
  }
`

export const ColumnItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ gap }) => `${gap}px`};
  }
  @media only screen and (max-width: 1200px) {
    &:last-child {
      margin-bottom: ${({ gap }) => `${gap}px`};
    }
  }
`
