/* @flow */
import React from 'react'
import Slider from 'react-slick'
import ReactDOM from 'react-dom'
import './Portfolio1.styles.sass'
import S from './Portfolio1.styled'
import { validation } from './Portfolio1.consts'
import ActionGetter from './Actions'
import { KEY_CODES } from '@website/conf/consts'
import Icon from '@website/common/components/Icon'
import { useKeyPress } from '@hooks'
import Image from '@website/common/components/Image'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ArrowLeftIcon from '@website/common/assets/svgr-icons/keyboard_arrow_left.svg'
import ArrowRightIcon from '@website/common/assets/svgr-icons/keyboard_arrow_right.svg'
import CloseIcon from '@website/common/assets/svgr-icons/close.svg'
import {
  lightBoxImageWidth,
  lightBoxMainSliderSettings,
  lightboxImagesSliderSettings
} from './Portfolio1.config'
import { withEditingContext } from '@contexts'

export const PortfolioLightBox = ({
  isEditing,
  dispatcher,
  portfolioImages,
  closePreviewMode,
  lightBoxActiveImgIndex
}) => {
  const [activeIndex, forceSetIdx] = React.useState(lightBoxActiveImgIndex)

  const mainSliderRef = React.useRef(null)
  const portfoliImgesSliderRef = React.useRef(null)
  const Actions = ActionGetter(dispatcher)
  const showRemoveAction =
    portfolioImages.length > validation.portfolioImages.min

  const onPrevClick = () => {
    mainSliderRef.current.slickPrev()
  }
  const onNextClick = () => {
    mainSliderRef.current.slickNext()
  }

  useKeyPress({
    withBuffer: true,
    buffer: [
      { code: 'left', action: onPrevClick },
      { code: 'right', action: onNextClick }
    ]
  })
  useKeyPress({
    onPress: closePreviewMode,
    withBuffer: false,
    keyCode: KEY_CODES.ESC
  })
  const onImgSelect = (index: number) => {
    mainSliderRef.current.slickGoTo(index)
    forceSetIdx(index)
  }
  const onSlideChange = (index: number) => {
    portfoliImgesSliderRef.current.slickGoTo(index)
    forceSetIdx(index)
  }
  const getSelectedSlideIndex = () =>
    `${portfolioImages.length + 1}/${activeIndex + 1}`

  const render = () => (
    <S.CustomSlickSLiderContainer className="custom-slick-slider-container">
      <S.IconContainer
        className="wm-slider-btn rf-close"
        onClick={closePreviewMode}
      >
        <Icon color="white" size="large" SvgComp={CloseIcon} />
      </S.IconContainer>

      <S.SlideIndexIndicator>{getSelectedSlideIndex()}</S.SlideIndexIndicator>

      <div className="main-slider-wrapper">
        <div className="main-slider-container">
          <S.IconContainer className="wm-slider-btn left" onClick={onPrevClick}>
            <Icon color="white" size="xxlarge" SvgComp={ArrowLeftIcon} />
          </S.IconContainer>

          <Slider
            ref={mainSliderRef}
            initialSlide={activeIndex}
            afterChange={onSlideChange}
            {...lightBoxMainSliderSettings}
          >
            {portfolioImages.map((pic: string, picIdx: number) => (
              <div className="port-img-wrap" key={picIdx}>
                <Image
                  as={S.LightBoxSelectedImage}
                  defaultImgSrc={pic.imgUrl}
                  alt="Portfolio image"
                  sizes={pic.imgDimensions}
                  withLazy={false}
                  asProps={{
                    selected: picIdx === activeIndex,
                    className: 'lightbox-selected-image'
                  }}
                />
              </div>
            ))}
          </Slider>

          <S.IconContainer
            className="wm-slider-btn right"
            onClick={onNextClick}
          >
            <Icon color="white" size="xxlarge" SvgComp={ArrowRightIcon} />
          </S.IconContainer>
        </div>

        <div className="carousel-slider">
          <Slider
            className="slider-nav"
            ref={portfoliImgesSliderRef}
            {...lightboxImagesSliderSettings()}
          >
            {portfolioImages.map((picture: string, index: number) => (
              <div className="port-img-wrap img-col lightbox-image-wrap">
                <ControlsWithModalOpener
                  toPropagate={false}
                  actions={Actions.imageActions(
                    index,
                    showRemoveAction,
                    picture.imgUrl,
                    { x: 0, y: -225 }
                  )}
                  isRelativeToWrapper
                >
                  <Image
                    as={S.LightBoxImage}
                    defaultImgSrc={picture.imgUrl}
                    alt="Portfolio image"
                    sizes={picture.imgDimensions}
                    withLazy={false}
                    asProps={{
                      onClick: () => onImgSelect(index),
                      selected: index === activeIndex,
                      lightBoxImageWidth: lightBoxImageWidth()
                    }}
                  />
                </ControlsWithModalOpener>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </S.CustomSlickSLiderContainer>
  )
  return ReactDOM.createPortal(
    <S.PreviewModeContainer
      className="preview-mode-container"
      isEditing={isEditing}
    >
      {render()}
    </S.PreviewModeContainer>,
    document.body
  )
}

export default withEditingContext(PortfolioLightBox)
