import React, { memo, useEffect } from 'react'
import {
  StyledLoaderContainer,
  StyledGearsContainer,
  StyledSyncText,
  LoadingCorn
} from './styled'
import { cacheImage } from '@editor/common/utils'
import { CORN_ICON_URL } from './consts'

const RfLoader = ({ text, background, className, isVisible }) => {
  useEffect(() => {
    cacheImage(CORN_ICON_URL)
  }, [])

  return isVisible ? (
    <StyledLoaderContainer background={background} className={className}>
      <StyledGearsContainer>
        <LoadingCorn />
        {text && <StyledSyncText>{text}</StyledSyncText>}
      </StyledGearsContainer>
    </StyledLoaderContainer>
  ) : null
}

export default memo(RfLoader)
