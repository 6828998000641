import styled from 'styled-components'

export const WizardWindowContainer = styled.div`
  .wizard-window {
    right: 20px;
    bottom: 20px;
    top: auto;
    left: auto;

    @media only screen and (max-width: 800px) {
      bottom: 75px;
    }
  }
`
