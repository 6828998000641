import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
    @keyframes slide-up {
        from {transform: translateY(100%) }
        to {transform: translateY(0)}
    }
    
    @keyframes slide-down {
        from {transform: translateY(0)}
        to {transform: translateY(100%)}
    }
`

export const SlideUpContainer = styled.div`
  position: fixed;
  width: 100vw;
  z-index: 51;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #252e4880;

  .settings-customization-window-mobile {
    display: flex;
    flex-direction: column;
  }
`

export const SlideUp = styled.div`
  max-height: 95%;
  box-shadow: 0px -6px 12px #387dff1a;
  position: absolute;
  left: 0;
  width: 100%;
  animation-name: ${({ isOpen }) => (isOpen ? 'slide-up' : 'slide-down')};
  animation-duration: 0.3s;
  bottom: 0;
  transform: translateY(0);
`
