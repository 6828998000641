/* @flow */
const debounce = (func, wait, immediate) => {
  let timeout
  return function (...args) {
    const later = function () {
      timeout = null
      if (!immediate) {
        func(...args)
      }
    }

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    const callNow = immediate && !timeout
    if (callNow) {
      func(...args)
    }
  }
}

export default debounce
