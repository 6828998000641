/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeBlockTitle,
  changeDescription,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'
export default dispatcher => {
  const removeBlockListItem = (blockIdx, listIdx) => ({
    type: 'REMOVE_BLOCK_LIST_ITEM',
    value: { blockIdx, listIdx }
  })

  const changeBlockListItem = (newBlockListTitle, blockIdx, listIdx) =>
    dispatcher({
      type: 'CHANGE_BLOCK_LIST_ITEM',
      value: { blockIdx, listIdx, newBlockListTitle }
    })

  const addBlockListItem = idx =>
    dispatcher({ type: 'ADD_BLOCK_LIST_ITEM', value: idx })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeDescription(paragraph))

  const _changeBlockTitle = blockIdx => newBlockTitle => {
    dispatcher(changeBlockTitle(newBlockTitle, blockIdx))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: () => dispatcher(addBlock()),
    changeTitle: _changeTitle,
    changeDescription: _changeParagraph,
    changeBlockTitle: _changeBlockTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockListItem,
    blockActions: removeBlockActionMaker(dispatcher),
    addBlockListItem,
    listActions: (blockIdx, listIdx) => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeBlockListItem(blockIdx, listIdx))
      }
    ]
  }
}
