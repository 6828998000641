import produce from 'immer'

import { EVENTS_INITIAL_DATA } from '../consts'

export default (state = EVENTS_INITIAL_DATA, action) =>
  produce(state, draft => {
    const { value, type } = action
    switch (type) {
      case 'SET_EVENTS_DATA':
        draft.data = value
        break
      case 'SET_EVENTS_LOADING_STATE':
        draft.isEventsLoading = value
        break
      case 'SET_EVENT_CREATING_STATE':
        draft.isEventCreating = value
        break
      case 'SET_EVENT_SUCCESS_MESSAGE':
        draft.successMessage = value
        break
      case 'SET_EVENT_FORM':
        draft.data[0].form = value
        break
      case 'SET_EVENT_SETTINGS':
        draft.data[0].settings = value
        break
      case 'SET_DETAILS_DATA':
        draft.data[0].details = value
        break
      case 'SET_POPUPS_DATA':
        draft.data[0].popups = value
        break
      case 'SET_TRANSLATION_LOADING_STATE':
        draft.isTranslationLoading = value
        break
      case 'SET_EVENT_TRANSLATION_DATA':
        const { eventId, data, lang } = value

        draft.translations = {
          ...state.translations,
          [eventId]: { ...state.translations?.[eventId], [lang]: data }
        }
        break
      case 'SET_GUESTS_LIST_LOADING_STATE':
        draft.guestsList.isLoading = value
        break
      case 'SET_GUESTS_LIST_PAGINATION_LOADING_STATE':
        draft.guestsList.isPaginationLoading = value
        break
      case 'SET_GUESTS_LIST_FILTERS':
        draft.guestsList.filters = {
          ...draft.guestsList.filters,
          ...value
        }
        break
      case 'SET_GUESTS_LIST_DATA':
        {
          const { results } = value
          draft.guestsList.data = results
        }
        break
      case 'REPLACE_GUEST_LIST_PAGE_DATA':
        {
          const { results, limit, offset } = value
          draft.guestsList.data = [
            ...draft.guestsList.data.slice(0, offset),
            ...results,
            ...draft.guestsList.data.slice(offset + limit)
          ]
        }
        break
      case 'PUSH_GUEST_LIST_DATA':
        draft.guestsList.data.push(...value)
        break
      case 'SET_TOTAL_GUESTS_COUNT':
        draft.guestsList.totalGuestsCount = value
        break
      case 'SET_TOTAL_RESULTS_COUNT':
        draft.guestsList.totalResultsCount = value
        break
      case 'SET_GUESTS_RESULT_COUNTS':
        draft.guestsList.resultCounts = value
        break
      case 'REMOVE_GUEST':
        {
          const id = value
          draft.guestsList.data = draft.guestsList.data.filter(
            guest => guest.id !== id
          )
        }
        break
      case 'SET_FORM_DATA_FROM_REVISION':
        draft.guestsList.formDataFromRevision = value
        break
      case 'SET_CONFIRMATION_EMAIL_CONTENT':
        draft.data[0].emailData = {
          ...draft.data[0].emailData,
          confirm: action.value
        }
        break
      case 'SET_REGRET_EMAIL_CONTENT':
        draft.data[0].emailData = {
          ...draft.data[0].emailData,
          regret: action.value
        }
        break
      case 'SET_EMAIL_SETTINGS':
        draft.data[0].emailSettings = action.value
        break
      default:
        return state
    }
  })
