/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text11.styled'
import ActionGetter from './Actions'
import { validation } from './Text11.consts'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Text11 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      info,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
        isEditing={isEditing}
      >
        <S.WmCustomContainer>
          <S.Content>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.titleMaxChar}
              required={validation.title.required}
              className="WM_GLOBAL_heading42"
              onChange={(newTitle: string) => Actions.changeTitle(newTitle)}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.paragraphMaxChar}
              required={validation.paragraph.required}
              className="WM_GLOBAL_paragraph18"
              onChange={(newParagraph: string) =>
                Actions.changeParagraph(newParagraph)
              }
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.Content>
          <Show when={[info.length !== 0]}>
            <S.InfoContainer>
              <S.InfoWrapper>
                <Mapper
                  data={info}
                  render={(item: string, idx: number) => {
                    return (
                      <Show when={[item.title, item.paragraph]}>
                        <S.ControlWrapper key={idx}>
                          <ControlsWithModalOpener
                            actions={Actions.removeBlock({
                              idx,
                              showRemove: info.length > validation.info.min
                            })}
                          >
                            <S.InfoItem isEditing={isEditing}>
                              <EditableContent
                                text={item.title}
                                alignment={item.titleAlignment}
                                as={S.Count}
                                required={info.length === 2}
                                maxCount={validation.countMaxChar}
                                className="WM_GLOBAL_heading54"
                                onChange={(newTitle: string) =>
                                  Actions.changeBlockTitle(newTitle, idx)
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockTitleAlignment(
                                    alignment,
                                    idx
                                  )
                                }
                              />
                              <EditableContent
                                text={item.paragraph}
                                alignment={item.paragraphAlignment}
                                as={S.InfoTitle}
                                maxCount={validation.blockTitleMaxChar}
                                required={info.length === 2}
                                className="WM_GLOBAL_heading20"
                                onChange={(newParagraph: string) =>
                                  Actions.changeBlockParagraph(
                                    newParagraph,
                                    idx
                                  )
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockParagraphAlignment(
                                    alignment,
                                    idx
                                  )
                                }
                              />
                            </S.InfoItem>
                          </ControlsWithModalOpener>
                        </S.ControlWrapper>
                      </Show>
                    )
                  }}
                />
              </S.InfoWrapper>
              <AddButton
                onAdd={Actions.addBlock}
                toShow={info.length < validation.info.max}
                style={{ marginTop: '20px' }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_block_label')}
              </AddButton>
            </S.InfoContainer>
          </Show>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Text11
