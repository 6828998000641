// @flow
/* eslint-disable max-statements */

import React, { useMemo, useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import { List } from '../components'
import * as Styled from './styled'
import ResetChangesIcon from '@editor/common/assets/svgr-icons/Reset_changes_icon.svg'
import PreviewIcon from '@editor/common/assets/svgr-icons/Preview_icon.svg'
import WidgetsIcon from '@editor/common/assets/svgr-icons/widget_icon.svg'
import ShareIcon from '@editor/common/assets/svgr-icons/share_link_icon.svg'
import SettingsIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'
import QuestionIcon from '@editor/common/assets/svgr-icons/Question_icon.svg'
import EventIcon from '@editor/common/assets/svgr-icons/rsvp_event_icon.svg'
import { WebsiteStatusMobile } from '../components/PublishOptions/WebsiteStatus'

import {
  openLinkSharePopup,
  setEventCreationPopupVisibilityState,
  setResetChangesPopupState
} from '@actions/editor'
import {
  hasChangesAfterPublish,
  selectSitePublishedState,
  selectDomain,
  isSubscriber,
  selectWasWebsiteMultilingual,
  selectHasWebsiteEvent
} from '@selectors'
import { useHistoryWithSearch } from '@hooks'
import { openUrl } from '@website/common/utils'
import { getResetChangesTooltipText } from '../components/PublishOptions/ResetChanges/utils'

import type { TTopLinksProps } from './types'
import { PAGE_BASE } from '@editor/conf/routes'
import { HELP_SUPPORT_LINK } from '@editor/conf/consts'

const TopLinks = ({
  isSitePublished,
  isUserSubscribed,
  hasDomain,
  hasChangesAfterPublish,
  isContributor,
  isUserAuthorized,
  wasWebsiteMultilingual,
  hasWebsiteEvent,
  openLinkSharePopup,
  closeSlideLeft,
  openLoginPopup,
  setResetChangesPopupState,
  setEventCreationPopupVisibilityState
}: TTopLinksProps) => {
  const [isWebsiteStatusPopupOpen, setWebsiteStatusPopupState] = useState(false)
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(PAGE_BASE)

  const isResetChangesDisabled = !isSitePublished || !hasChangesAfterPublish
  const isWebsiteStatusDisabled = hasDomain && !isSitePublished
  const resetChangesTooltipText = getResetChangesTooltipText(
    isSitePublished,
    hasChangesAfterPublish
  )
  const websiteStatusTooltipText = isWebsiteStatusDisabled
    ? translate('status_will_be_available_after_publishing_label')
    : ''

  const openWebsiteStatusPopup = useCallback(() => {
    if (isWebsiteStatusDisabled) {
      return
    }
    setWebsiteStatusPopupState(true)
  }, [isWebsiteStatusDisabled])

  const closeWebsiteStatusPopup = useCallback(() => {
    setWebsiteStatusPopupState(false)
  }, [])

  const openResetChangesPopup = useCallback(() => {
    if (isResetChangesDisabled) {
      return
    }
    closeSlideLeft()
    setResetChangesPopupState({ isOpen: true })
  }, [isResetChangesDisabled, closeSlideLeft])

  const navigateToEvent = useCallback(() => {
    closeSlideLeft()

    if (hasWebsiteEvent) {
      history.push(`${url}/event-settings/details`)
    } else {
      setEventCreationPopupVisibilityState(true)
    }
  }, [url, closeSlideLeft, hasWebsiteEvent])

  const navigateToWidgets = useCallback(() => {
    closeSlideLeft()
    history.push(`${url}/widgets`)
  }, [url, closeSlideLeft])

  const navigateToSettings = useCallback(() => {
    closeSlideLeft()
    history.push(`${url}/settings`)
  }, [closeSlideLeft])

  const handleLinkShareClick = useCallback(() => {
    closeSlideLeft()
    openLinkSharePopup()
  }, [closeSlideLeft])

  const handleHelpClick = useCallback(() => {
    closeSlideLeft()
    openUrl(HELP_SUPPORT_LINK)
  }, [closeSlideLeft])

  const itemsForContributor = useMemo(
    () => [
      // {
      //   IconComp: EventIcon,
      //   text: 'Event',
      //   onClick: navigateToEvent
      // },
      {
        IconComp: WidgetsIcon,
        text: 'Widgets',
        onClick: navigateToWidgets
      },
      {
        IconComp: SettingsIcon,
        text: 'Settings',
        onClick: navigateToSettings
      }
    ],
    [navigateToWidgets, navigateToSettings, navigateToEvent]
  )

  const itemsAuthorized = useMemo(
    () => [
      {
        IconComp: PreviewIcon,
        text: translate('website_status_label'),
        showSubscribeIcon: !isUserSubscribed,
        isDisabled: isWebsiteStatusDisabled,
        tooltipText: websiteStatusTooltipText,
        onClick: openWebsiteStatusPopup
      },
      ...(wasWebsiteMultilingual
        ? []
        : [
            {
              IconComp: ResetChangesIcon,
              text: translate('reset_changes_label'),
              isDisabled: isResetChangesDisabled,
              tooltipText: resetChangesTooltipText,
              onClick: openResetChangesPopup
            }
          ]),
      {
        IconComp: EventIcon,
        text: translate('event_label'),
        onClick: navigateToEvent
      },
      {
        IconComp: WidgetsIcon,
        text: translate('widgets_label'),
        onClick: navigateToWidgets
      },
      {
        IconComp: ShareIcon,
        text: translate('share_label'),
        onClick: handleLinkShareClick
      },
      {
        IconComp: SettingsIcon,
        text: translate('settings_label'),
        onClick: navigateToSettings
      }
    ],
    [
      navigateToWidgets,
      handleLinkShareClick,
      navigateToSettings,
      wasWebsiteMultilingual
    ]
  )

  const itemsUnauthorized = useMemo(
    () => [
      {
        IconComp: EventIcon,
        text: translate('event_label'),
        onClick: openLoginPopup
      },
      {
        IconComp: WidgetsIcon,
        text: translate('widgets_label'),
        onClick: openLoginPopup
      },
      {
        IconComp: SettingsIcon,
        text: translate('settings_label'),
        onClick: openLoginPopup
      },
      {
        IconComp: QuestionIcon,
        text: translate('help_and_support_label'),
        onClick: handleHelpClick
      }
    ],
    []
  )

  const items = isContributor
    ? itemsForContributor
    : isUserAuthorized
    ? itemsAuthorized
    : itemsUnauthorized

  return (
    <Styled.TopLinksContent>
      <List items={items} />
      <WebsiteStatusMobile
        isOpen={isWebsiteStatusPopupOpen}
        onClose={closeWebsiteStatusPopup}
      />
    </Styled.TopLinksContent>
  )
}

const mapStateToProps = state => ({
  isSitePublished: selectSitePublishedState(state),
  hasChangesAfterPublish: hasChangesAfterPublish(state),
  hasDomain: !!selectDomain(state),
  isUserSubscribed: isSubscriber(state),
  wasWebsiteMultilingual: selectWasWebsiteMultilingual(state),
  hasWebsiteEvent: selectHasWebsiteEvent(state)
})

const mapDispatchToProps = {
  openLinkSharePopup,
  setResetChangesPopupState,
  setEventCreationPopupVisibilityState
}

export default connect(mapStateToProps, mapDispatchToProps)(TopLinks)
