/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA4.styled'
import { validation } from './CTA4.consts'
import ActionMaker from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const CTA4 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttons,
      actions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionMaker(dispatcher)
  return (
    <Show
      when={[
        title,
        titleAlignment,
        paragraph,
        paragraphAlignment,
        buttons.length > 0,
        actions.length > 0
      ]}
    >
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer className="cta-items-container">
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            maxCount={validation.titleMaxChar}
            onChange={Actions.changeTitle}
            className="WM_GLOBAL_heading"
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Description}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            onChange={Actions.changeParagraph}
            className="WM_GLOBAL_paragraph"
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
          <S.ActionItemsWrap>
            <Mapper
              data={actions}
              render={(cta: string, idx: number) => (
                <S.Cta key={idx}>
                  <ControlsWithModalOpener
                    actions={Actions.ctaActions(idx)}
                    style={{ height: '100%' }}
                  >
                    <S.CtaItemWrap>
                      <ControlsWithModalOpener
                        actions={Actions.getIconActions({
                          idx,
                          showRemove: false
                        })}
                        style={{ height: 'auto' }}
                      >
                        <S.IconWrap>
                          <S.Icon name={cta.iconName} size="xxlarge" />
                        </S.IconWrap>
                      </ControlsWithModalOpener>
                      <EditableContent
                        as={S.CtaTitle}
                        text={cta.title}
                        alignment={cta.blockTitleAlignment}
                        maxCount={validation.blockTitleMaxChar}
                        onChange={(newCtaTitle: string) =>
                          Actions.changeCtaTitle(newCtaTitle, idx)
                        }
                        className="WM_GLOBAL_primary-font"
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                    </S.CtaItemWrap>
                  </ControlsWithModalOpener>
                </S.Cta>
              )}
            />
          </S.ActionItemsWrap>
        </S.WmCustomContainer>
        <AddButton
          onAdd={Actions.addItem}
          toShow={actions.length < validation.actions.max}
          style={{
            marginTop: '2rem'
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_item_label')}
        </AddButton>
      </S.StyledContainer>
    </Show>
  )
})

export default CTA4
