/* @flow */
import { validation } from '../Team8.consts'
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  addBlock,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const changeClientData = (data: string, idx: number, keyInStore: string) => {
    dispatch({
      type: 'CHANGE_CLIENT_DATA',
      value: { data, idx, keyInStore }
    })
  }
  const changeItemParagraph = (paragraph: string, idx: number) => {
    dispatch(changeClientData(paragraph, idx, 'paragraph'))
  }

  const changeClientName = (name: string, idx: number) => {
    dispatch(changeClientData(name, idx, 'name'))
  }

  const changeClientPosition = (position: string, idx: number) => {
    dispatch(changeClientData(position, idx, 'position'))
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _addBlock = () => {
    dispatch(addBlock())
  }

  const mockupActions = (
    idx: number,
    blocksCount: number,
    imageUrl: string
  ) => [
    ...cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'team8',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    }),
    ...removeBlockActionMaker(dispatch)({
      idx,
      showRemove: blocksCount > validation.blocks.min
    })
  ]

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const changeNameAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changePositionAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_POSITION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    addBlock: _addBlock,
    changeItemParagraph,
    changeClientName,
    changeClientPosition,
    mockupActions,
    changeNameAlignment,
    changePositionAlignment,
    changeParagraphAlignment,
    changeTitleAlignment: _changeTitleAlignment
  }
}
