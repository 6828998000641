import styled, { createGlobalStyle } from 'styled-components'
import { IMAGE_UPLOAD_FAIL_BACKGROUND } from '@website/conf/asset-urls'

export const GlobalStyles = createGlobalStyle`
  @keyframes fadeInImg {
    from {
      opacity: 0;
    }
   to {
      opacity: 1;
   }
  }
.img-loading {
   opacity: 0;
   width: 100%;
   height: auto;
}
.img-loaded {
   animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
   position: relative;
   opacity: 0;
   animation-fill-mode: forwards;
   animation-duration: 1.5s;
   animation-delay: 0.1s;

}
.LazyLoad {
   height: 100%
}
.lazy-figure {
   position: relative
}
`

export const Figure = styled.figure`
  height: 100%;
  width: 100%;
  overflow: hidden;
`

export const StyledImg = styled.img``

export const FailedImageContainer = styled(Figure)`
  display: block;
  background-image: url(${IMAGE_UPLOAD_FAIL_BACKGROUND});
  background-position: center;
  background-size: contain;
  & > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
  }
`
