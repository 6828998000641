import http from '@shared/ApiFactory'

export const complaintMessageApi = (siteId, data) =>
  http.POST(`/api/v1/sites/form/compliance/${siteId}/`, data)

// export const complaintMessageApi = (siteId, data) =>
//   http.POST(
//     `https://www.renderforest.com/api/v1/sites/form/compliance/${siteId}`,
//     data
//   )
