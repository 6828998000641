// @flow

import React from 'react'
import { connect } from 'react-redux'

import { getContributerSignOutPopupState } from '@selectors/index'
import ContributerSignOutPopup from '@editor/common/components/ContributerSignOutPopup.js'

const Popups = ({ isContributerSignOutPopupOpen }) => {
  return <>{isContributerSignOutPopupOpen && <ContributerSignOutPopup />}</>
}

const mapStateToProps = (state: TAppState) => ({
  isContributerSignOutPopupOpen: getContributerSignOutPopupState(state)
})

export default connect(mapStateToProps)(Popups)
