/* @flow */
import React, { useContext, useMemo, memo } from 'react'
import * as Styled from './ProjectLinks.styled'
import { ProjectLinksContext, EditingContext } from '@contexts'
import LinkWrapper from '../LinkWrapper'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import SettingsIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { secondary } from '@website/common/styles/colors'
import { translate } from '@editor/common/utils/translations'

const LinksDropDown = ({
  containerRef,
  Actions,
  closeDropDownCb,
  lastVisibleLinkIndex
}) => {
  //Header additional links(in more dropdown).
  const { headerLinks } = useContext(ProjectLinksContext)
  const { isEditing } = useContext(EditingContext)

  const menuItems = useMemo(
    () =>
      headerLinks.slice(lastVisibleLinkIndex).map((link, idx) => {
        const activeLinkIdx = idx + lastVisibleLinkIndex

        return (
          <Styled.DropdownLink>
            <Styled.NavbarGlobalStyles />
            <LinkWrapper text={link.text} url={link.link} />
            {isEditing ? (
              <Styled.ActionsWrapper>
                <TooltipWrapper
                  text={translate('settings_label')}
                  color={secondary.ExtraDark}
                >
                  <SettingsIcon
                    onClick={e => {
                      e.stopPropagation()
                      Actions.dropDownLinkActions.edit(activeLinkIdx, link)
                    }}
                  />
                </TooltipWrapper>
                <TooltipWrapper
                  text={translate('delete_label')}
                  color={secondary.ExtraDark}
                >
                  <DeleteIcon
                    onClick={e => {
                      e.stopPropagation()
                      Actions.dropDownLinkActions.remove(activeLinkIdx)
                      headerLinks.length === 0 && closeDropDownCb()
                    }}
                  />
                </TooltipWrapper>
              </Styled.ActionsWrapper>
            ) : null}
          </Styled.DropdownLink>
        )
      }),
    [headerLinks, lastVisibleLinkIndex, Actions]
  )

  return (
    <Styled.DropdownLinks
      containerRef={containerRef}
      className="project-links-dropdown"
      menuItems={menuItems}
      onClose={closeDropDownCb}
    />
  )
}

export default memo(LinksDropDown)
