export const validation = {
  maxTitleChar: 100,
  maxSubtitleChar: 200,
  maxBlockTitleChar: 200,
  maxBlockParagraphChar: 2000,
  content: {
    min: 1,
    max: 15
  }
}
