// @flow
import React, { useMemo, useCallback, useState, memo } from 'react'
import { connect } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TTemplateThumbnailProps } from './types'
import { DIALOG_BUTTONS } from '../../../../consts'
import ApplyIcon from '@editor/common/assets/svgr-icons/check.svg'
import PreviewIcon from '@editor/common/assets/svgr-icons/Preview_icon.svg'
import ThumbnailImage from '@editor/common/components/ThumbnailImage'
import {
  Divider,
  ThumbnailButtons,
  ThumbnailButtonWrapper,
  ThumbnailButtonsWrapper
} from '@editor/common/styled-components/choose-component-template'
import ConfirmDialog from '@editor/common/components/ConfirmDialog-old'

import { EDITOR_BASE } from '@editor/conf/routes'
import { addAnalyticsEvent } from '@editor/common/utils'
import { useHistoryWithSearch } from '@hooks'
import { useTemplateApply } from '@hooks/templates'
import { getTemplateById, setApplyTemplateId } from '@actions/editor'
import { hasComponents, selectApplyTemplateId } from '@selectors'
import { WithTooltip } from '@editor/common/components/WithTooltip'
import { useResponsive } from '@shared/hooks'

const TEMPLATE_APPLY_CONFIRM_QUESTION = translate(
  'new_template_on_created_site_label'
)
const TEMPLATE_APPLY_CONFIRM_MESSAGE = translate(
  'prev_changes_will_be_lost_label'
)
const lang = window.CURRENT_LANGUAGE

const getIsOnlyBtnsShown = () => ['pt', 'jp', 'ru', 'es'].includes(lang)

const TemplateThumbnail = ({
  applyTemplateId,
  setApplyTemplateId,
  template,
  hasComponents,
  getTemplateById
}: TTemplateThumbnailProps) => {
  const [showConfirmDialog, setConfirmDialogStatus] = useState(false)
  const isMobileOrTablet = useResponsive(768)

  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(EDITOR_BASE)
  const { state = {}, pathname } = useLocation()

  const { id, name, thumbnail } = template

  const _templateId = id === applyTemplateId ? id : null
  useTemplateApply(_templateId)

  const applyTemplate = useCallback(() => {
    setApplyTemplateId(id)
    getTemplateById(id)
  }, [id, setApplyTemplateId, getTemplateById])

  const onApply = useCallback(() => {
    if (hasComponents) {
      setConfirmDialogStatus(true)
    } else {
      applyTemplate()
    }
  }, [hasComponents, applyTemplate])

  const onPreview = useCallback(() => {
    history.push({
      pathname: `${url}/preview-project/template/${id}`,
      state: { prevPath: pathname, backTo: state.backTo }
    })

    addAnalyticsEvent('SiteMaker Presets List', name, 'preview')
  }, [id, name, history, pathname, state])

  const onConfirm = useCallback(() => {
    applyTemplate()
    setConfirmDialogStatus(false)
  }, [applyTemplate])

  const closeConfirmDialog = useCallback(() => {
    setConfirmDialogStatus(false)
  }, [])

  const isBtnsShorten = useMemo(
    () => getIsOnlyBtnsShown() && !isMobileOrTablet,
    [isMobileOrTablet]
  )

  return (
    <>
      <ConfirmDialog
        isOpen={showConfirmDialog}
        title={TEMPLATE_APPLY_CONFIRM_QUESTION}
        content={TEMPLATE_APPLY_CONFIRM_MESSAGE}
        buttons={DIALOG_BUTTONS}
        onConfirm={onConfirm}
        onCancel={closeConfirmDialog}
      />
      <Styled.TemplateThumbnail>
        <Styled.ThumbnailImageWrapper>
          <ThumbnailImage src={thumbnail} imageRatio={0.75} />
          <ThumbnailButtonsWrapper>
            <ThumbnailButtons>
              <ThumbnailButtonWrapper>
                {isBtnsShorten ? (
                  <WithTooltip text={translate('preview_label')}>
                    <Styled.PreviewButton
                      isBtnShorten={isBtnsShorten}
                      onClick={onPreview}
                    >
                      <PreviewIcon />
                    </Styled.PreviewButton>
                  </WithTooltip>
                ) : (
                  <Styled.PreviewButton
                    isBtnShorten={isBtnsShorten}
                    onClick={onPreview}
                  >
                    <PreviewIcon />
                    <p>{translate('preview_label')}</p>
                  </Styled.PreviewButton>
                )}
              </ThumbnailButtonWrapper>
              <Divider isLong />
              <ThumbnailButtonWrapper>
                {isBtnsShorten ? (
                  <WithTooltip text={translate('apply_label')}>
                    <Styled.ApplyButton
                      isBtnShorten={isBtnsShorten}
                      onClick={onApply}
                    >
                      <ApplyIcon />
                    </Styled.ApplyButton>
                  </WithTooltip>
                ) : (
                  <Styled.ApplyButton
                    isBtnShorten={isBtnsShorten}
                    onClick={onApply}
                  >
                    <ApplyIcon />
                    <p>{translate('apply_label')}</p>
                  </Styled.ApplyButton>
                )}
              </ThumbnailButtonWrapper>
            </ThumbnailButtons>
          </ThumbnailButtonsWrapper>
        </Styled.ThumbnailImageWrapper>
        <Styled.Name>{name}</Styled.Name>
      </Styled.TemplateThumbnail>
    </>
  )
}

const mapStateToProps = state => ({
  hasComponents: hasComponents(state),
  applyTemplateId: selectApplyTemplateId(state)
})

const mapDispatchToProps = {
  getTemplateById,
  setApplyTemplateId
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplateThumbnail)
