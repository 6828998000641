/* @flow */

import {
  cropableImageModalActionMaker,
  removeBlockActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeBlockTitle = (newTitle: string, idx: number, itemIdx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx, itemIdx }
    })

  const changeParagraph = (
    newParagraph: string,
    idx: number,
    itemIdx: number
  ) =>
    dispatcher({
      type: 'CHANGE_PARAGRAPH',
      value: { newParagraph, idx, itemIdx }
    })

  const changeLinkData = value => ({
    type: 'CHANGE_LINK_DATA',
    value
  })

  const removeLink = value => () => ({
    type: 'REMOVE_LINK',
    value
  })

  const changeBlockTitleAlignment = (
    alignment: string,
    idx: numberitem,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx, itemIdx }
    })
  }

  const changeBlockParagraphAlignment = (
    alignment: string,
    idx: number,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx, itemIdx }
    })
  }

  const linkActions = (idx: number, itemIdx: number, linkData) => [
    ...linkActionMaker(dispatcher)({
      isAdditionalLink: true,
      idx,
      itemIdx,
      linkData,
      actions: {
        change: (_, newLinkData) =>
          changeLinkData({ idx, itemIdx, newLinkData }),
        remove: removeLink({ idx, itemIdx })
      }
    })
  ]

  const addLink = (idx: number, itemIdx: number) =>
    dispatcher({
      type: 'ADD_LINK',
      value: { idx, itemIdx }
    })

  const imageActions = (idx: number, itemIdx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      showRemove: false,
      additionalArgs: { idx, itemIdx },
      keyForSizes: 'feature37',
      imageUrl
    })

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeLinkData,
    linkActions,
    addLink,
    imageActions,
    changeBlockTitle,
    changeParagraph,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
