/* @flow */
import { validation } from '../Header27.consts'
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  changeParagraph,
  changeParagraphAlignment,
  addBlock,
  removeBlock
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockTitle = (idx: number, newTitle: string) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { idx, newTitle }
    })
  }

  const changeBlockParagraph = (idx: number, newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { idx, newParagraph }
    })
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const blockActions = (idx: number, blocksCount: number) => [
    ...removeBlockActionMaker(dispatcher)({
      idx,
      showRemove: blocksCount > validation.blocks.min
    })
  ]

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  return {
    changeParagraph: _changeParagraph,
    changeBlockTitle,
    changeBlockParagraph,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    addBlock,
    removeBlock,
    blockActions
  }
}
