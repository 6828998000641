import styled, { css } from 'styled-components'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import { secondary, accentGreen } from '@website/common/styles/colors'

const CONNECT_DOMAIN_POPUP_MOBILE_BG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/mobile_offline_status_connect_domain_bg.jpg'
const CONNECT_DOMAIN_POPUP_DESKTOP_BG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/desktop_offline_status_connect_domain_bg.jpg'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px;
  width: 100%;
  box-sizing: border-box;
  & > p {
    font-size: 14px;
    color: ${secondary.SemiDark};
    line-height: 18px;
    margin: 5px 0;
  }
  @media only screen and (max-width: 768px) {
    padding: 10px 20px;
  }
`

export const SwitcherWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0;
  .website-status-switcher {
    margin: 0 5px;
  }
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`

export const Label = styled.span`
  font-size: 14px;
  ${({ isSelected }) =>
    isSelected
      ? css`
          color: ${secondary.ExtraDark};
          cursor: default;
          font-weight: 500;
        `
      : css`
          color: ${secondary.SemiDark};
          cursor: pointer;
        `}
`

export const ConnectDomainPopup = styled(Popup)`
  & > div {
    padding: 0;
    & > div {
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const ConnectDomainContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 50px 30px;
  align-items: center;
  justify-content: center;
  background-image: url(${CONNECT_DOMAIN_POPUP_DESKTOP_BG});
  background-size: cover;
  background-repeat: no-repeat;
  max-width: 450px;
  @media only screen and (max-width: 768px) {
    max-width: unset;
    background-image: url(${CONNECT_DOMAIN_POPUP_MOBILE_BG});
  }
`

export const ConnectDomainPopupTitle = styled.h3`
  font-size: 20px;
  color: ${secondary.ExtraDark};
  font-weight: 700;
  margin-bottom: 20px;
  text-align: center;
`
export const ConnectDomainPopupDescription = styled.p`
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: ${secondary.ExtraDark};
  margin-bottom: 30px;
`
