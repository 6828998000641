// @flow

import React, { useCallback } from 'react'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import LinkButton from '@renderforest/rf-ui-library/molecules/LinkButton'
import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'
import { DEVICES } from './config'
import { getQueryStringParam } from '@website/common/utils'
import { WithTooltip } from '@editor/common/components/WithTooltip'
import ArrowLeft from '@editor/common/assets/svgr-icons/arrow_small_left.svg'

const TopBar = ({
  applyPreset,
  activeDevice,
  changeDevice,
  goBack,
  openInNewTab,
  showOpenInNewTabBtn,
  btnLabel = translate('apply_label', true)
}) => {
  const isTemplate = !!applyPreset
  const backUrl = getQueryStringParam('backUrl')

  const handleBackClick = useCallback(() => {
    if (isTemplate && backUrl) {
      const backUrlDecoded = decodeURIComponent(backUrl)
      window.location.href = `/${backUrlDecoded}`
      return
    }
    goBack()
  }, [backUrl, isTemplate, goBack])

  return (
    <Styled.NavbarContainer>
      <Styled.LeftSection>
        {goBack && (
          <Styled.LinkButton className="button-wrap" onClick={handleBackClick}>
            <ArrowLeft />
            <span>{translate('back_label')}</span>
          </Styled.LinkButton>
        )}
      </Styled.LeftSection>

      <Styled.Devices>
        {Object.keys(DEVICES).map(deviceKey => {
          const device = DEVICES[deviceKey]
          const color = activeDevice === deviceKey ? 'primaryDark' : 'secondary'
          return (
            <Styled.DeviceIconWrapper
              onClick={() => {
                if (deviceKey !== activeDevice) {
                  changeDevice(deviceKey)
                }
              }}
              key={deviceKey}
            >
              <Icon size="huge" color={color} name={device.iconName} />
            </Styled.DeviceIconWrapper>
          )
        })}
      </Styled.Devices>

      <Styled.RightSection>
        {showOpenInNewTabBtn && (
          <WithTooltip text={translate('preview_in_new_tab_label')}>
            <LinkButton
              className="preview-btn"
              type="transparent"
              color="secondary"
              iconProps={{
                name: 'new_tab',
                size: 'small'
              }}
              onClick={openInNewTab}
            />
          </WithTooltip>
        )}

        {isTemplate && (
          <Styled.ApplyButtonWrapper onClick={applyPreset}>
            {backUrl ? translate('edit_label', true) : btnLabel}
          </Styled.ApplyButtonWrapper>
        )}
      </Styled.RightSection>
    </Styled.NavbarContainer>
  )
}

export default TopBar
