/* @flow */
import React from 'react'

import {
  changeTitle,
  changeParagraph,
  changeMockupColor,
  makeMockupLight,
  makeMockupDark,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

import MockupColorSwitcher from '@website/common/components/MockupColorSwitcher'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

export default dispatcher => {
  const changeSubTitle = (newSubTitle: string) => {
    dispatcher({
      type: 'CHANGE_SUB_TITLE',
      value: newSubTitle
    })
  }

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _mockupActions = ({ mockupColor, imageUrl }) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'header24MockupSizes',
      showRemove: false,
      imageUrl
    }),
    {
      renderer: () => (
        <MockupColorSwitcher
          onChoose={() => dispatcher(changeMockupColor())}
          onFirstOptionSelect={() => dispatcher(makeMockupLight())}
          onSecondOptionSelect={() => dispatcher(makeMockupDark())}
          isSecondOptionSelected={mockupColor === 'dark'}
        />
      )
    }
  ]

  return {
    changeTitle: _changeTitle,
    changeSubTitle,
    changeParagraph: _changeParagraph,
    mockupActions: _mockupActions,
    changeSubTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment
  }
}
