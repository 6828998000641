import styled, { css, createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .filter-flyout_container {
    box-shadow: 0 6px 12px #3271E61A;
    border-radius: 10px;
  }
`

export const Header = styled.div`
  padding: 40px 30px 10px;
  width: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    padding: 40px 20px 10px;
  }
`

export const Title = styled.h3`
  font-size: 20px;
  color: #252e48;
  font-weight: 600;
  text-align: center;
`

export const SearchContainer = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const FilterButton = styled.button`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent linear-gradient(180deg, #ffffff 0%, #edf5ff 100%) 0 0
    no-repeat;
  border: 1px solid #c1c9e0;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  transition: all 300ms ease;
  margin-left: 10px;
  flex-shrink: 0;

  svg {
    fill: #545f7e;
  }

  &:hover {
    background: #ffffff;
    box-shadow: 0 3px 6px #3271e633;
    svg {
      fill: #252e48;
    }
  }
`

export const FilterItem = styled.li`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  background-color: #ffffff;
  padding: 11px 20px;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  transition: all 300ms ease;

  ${({ isActive }) =>
    isActive
      ? css`
          color: #387dff;
          font-weight: 500;
        `
      : css`
          color: #545f7e;

          &:hover {
            padding-left: 25px;
            font-weight: 500;
            color: #252e48;
          }
        `};

  &:hover {
    background-color: #eef5ff;
  }
`

export const FiltersList = styled.ul`
  width: 120px;
  border: 1px solid #c1c9e0;
  border-radius: 10px;
  background-color: #ffffff;
  overflow: hidden;
  list-style-type: none;

  .arrow-icon {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    border-radius: 10px 10px 0 0;

    .arrow-icon {
      display: block;
      position: absolute;
      top: 16px;
      left: 20px;
      transform: rotate(-90deg);
      fill: #545f7e;
    }
  }
`

export const FiltersListTitle = styled.p`
  display: none;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 50px;
    font-size: 14px;
    color: #363f5a;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const SelectedSign = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: #3271e6;
  border-radius: 50%;
  border: 2px solid #ffffff;
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`
