/* @flow */
import styled from 'styled-components'
import THEME from './Spotify4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
  text-align: center;
`

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '100px 0 80px 0' : '80px 0')};
  @media only screen and (max-width: 1300px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : '80px 0')};
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
`

const TextsContainer = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  > :last-child {
    margin-bottom: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    p,
    h1 {
      padding: 0;
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

const MusicWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0;
  margin: 10px auto 0 auto;
  @media only screen and (max-width: 650px) {
    margin: 10px auto 0 auto;
  }
`

const MusicContent = styled.div`
  width: 500px;
  min-width: 500px;
  border-radius: ${getStyleForProp('badgeRadius')};
  position: relative;
  margin: 30px 15px 0 15px;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 30px 0 0 0;
    max-width: 500px;
    min-width: initial;
  }
`

const IframeWrapper = styled.div`
  width: 100%;
  position: relative;
  iframe {
    width: 100%;
    height: 380px;
    border-radius: ${getStyleForProp('badgeRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

export default {
  WmCustomContainer,
  StyledContainer,
  Title,
  Paragraph,
  MusicContent,
  IframeWrapper,
  MusicWrapper,
  TextsContainer
}
