/* @flow */
import React, { useState, useContext } from 'react'
import Mapper from '@website/common/components/Mapper'
import ActionGetter from './Actions'
import { useBackdropClick } from '@hooks'
import If from '@website/common/components/Conditional'
import EditableContent from '@website/common/components/EditableContent'
import S from './Contact5.styled'
import { validation } from './Contact5.consts'
import CloseIcon from '@website/common/assets/svgr-icons/close.svg'
import { DispatchContext } from '@contexts'

const WorkingHours = ({ workingHours }) => {
  const dispatcher = useContext(DispatchContext)
  const [showPopup, setPopupStatus] = useState(false)
  const openPopup = () => {
    if (!showPopup) {
      setPopupStatus(true)
    }
  }
  const containerRef = useBackdropClick(
    () => setPopupStatus(false),
    ['.working-hours']
  )
  const Actions = ActionGetter(dispatcher)

  return (
    <S.WorkingHoursContainer
      onClick={openPopup}
      ref={containerRef}
      className="working-hours"
    >
      <If
        condition={showPopup}
        then={() => (
          <>
            <S.CloseIcon
              SvgComp={CloseIcon}
              size="large"
              className="close-icon"
              onClick={() => setPopupStatus(false)}
            />
            <EditableContent
              text={workingHours.title}
              as={S.WorkingHoursTitle}
              maxCount={validation.workingHoursTitleMaxChar}
              onChange={newTitle => {
                Actions.changeWorkingHoursTitle(newTitle)
              }}
            />
            <Mapper
              data={workingHours.days}
              render={(day, idx) => {
                return (
                  <S.WorkingDayContainer
                    className="working-day-container"
                    key={idx}
                  >
                    <EditableContent
                      text={day.label}
                      as={S.Day}
                      maxCount={validation.daysMaxChar}
                      onChange={newLabel => {
                        Actions.changeWorkingHoursLabel(idx, newLabel)
                      }}
                    />
                    <EditableContent
                      text={day.value}
                      as={S.Hour}
                      maxCount={validation.daysMaxChar}
                      onChange={value => {
                        Actions.changeWorkingHoursValue(idx, value)
                      }}
                    />
                  </S.WorkingDayContainer>
                )
              }}
            />
          </>
        )}
        otherwise={() => (
          <S.WorkingHoursButtonContainer>
            <S.WorkingHoursIcon size="large" name="icon-time_outlined" />
            <EditableContent
              text={workingHours.buttonText}
              as={S.WorkingHoursButtonText}
              maxCount={validation.workingHoursTitleMaxChar}
              asProps={{
                style: { marginLeft: 20 }
              }}
              onChange={buttonText => {
                Actions.changeWorkingHoursButtonText(buttonText)
              }}
            />
          </S.WorkingHoursButtonContainer>
        )}
      />
    </S.WorkingHoursContainer>
  )
}

export default WorkingHours
