import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    mainColor: p => p.primaryLight,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    backgroundImageColor: p => p.primaryDark,
    raisedShadow: () => 'none',
    buttonShadow: () => 'none'
  },
  LIGHT: {
    mainColor: p => p.primaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => p.tertiaryLight,
    backgroundImageColor: p => p.primaryDark
  },
  EXTRALIGHT: {
    mainColor: p => p.primaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => p.tertiaryExtraLight,
    backgroundImageColor: p => p.primaryDark
  }
}
