import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    layerBackground: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.6)`,
    socialColor: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    layerBackground: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.6)`,
    socialColor: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    layerBackground: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.6)`,
    socialColor: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight
  }
}
