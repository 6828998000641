/* @flow */
import {
  iconActionMaker,
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const changeTitleGradientStatus = (newTitleTextGradient: string) => {
    dispatch({
      type: 'CHANGE_TITLE_TEXT_GRADIENT',
      value: newTitleTextGradient
    })
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const addItem = () => {
    dispatch({ type: 'ADD_ITEM' })
  }

  const imageActions = (idx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'slider6',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    })

  return {
    changeBlockTitle,
    changeBlockParagraph,
    addItem,
    changeTitleGradientStatus,
    blockActions: removeBlockActionMaker(dispatch),
    getIconActions: iconActionMaker(dispatch),
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    imageActions
  }
}
