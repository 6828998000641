/* @flow */
import {
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  addIcon,
  addBlock,
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'
import { translate } from '@editor/common/utils/translations'
export default dispatcher => {
  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { idx, newTitle }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const changeBlockTitleAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  return {
    addIcon: idx => dispatcher(addIcon(idx)),
    addBlock: _addBlock,
    changeBlockTitle,
    changeTitle: _changeTitle,
    changeBlockTitleAlignment,
    blockActions: removeBlockActionMaker(dispatcher),
    iconActions: iconActionMaker(dispatcher)
  }
}
