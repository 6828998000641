/* @flow */

import React, { useState, useEffect, useRef, useCallback, memo } from 'react'
import { translate } from '@editor/common/utils/translations'

import If from '@website/common/components/Conditional'
import { RippleLoading } from '@editor/common/components/Loaders'
import TopBar from './TopBar'
import * as Styled from './styled'

import { DEVICES } from './config'
import { primary } from '@website/common/styles/colors'
import { openUrl } from '@website/common/utils'

const Preview = ({
  iframeSrc,
  projectData,
  siteHash,
  websiteLanguagesData,
  goBack,
  applyPreset,
  btnLabel,
  showOpenInNewTabBtn = true
}) => {
  const [activeDevice, setDevice] = useState(Object.keys(DEVICES)[0])
  const [iframeLoaded, setLoadStatus] = useState(false)
  const { isLoading: isProjectLoading, payload } = projectData
  const iframeRef = useRef(null)

  useEffect(() => {
    if (!isProjectLoading && payload) {
      window.projectData = payload
    }
  }, [isProjectLoading, payload])

  useEffect(() => {
    if (siteHash) {
      window.siteHash = siteHash
    }
  }, [siteHash])

  useEffect(() => {
    if (websiteLanguagesData) {
      window.websiteLanguagesData = websiteLanguagesData
    }
  }, [websiteLanguagesData])

  const device = DEVICES[activeDevice]
  const showLoader = isProjectLoading || !iframeLoaded

  const scrollToTop = useCallback(() => {
    const frameWindow = iframeRef.current.contentWindow
    const frameHtmlNode = frameWindow.document.querySelector('html')

    frameHtmlNode.setAttribute('style', 'scroll-behavior: unset;')
    setTimeout(() => {
      frameWindow.scrollTo(0, 0)
      frameHtmlNode.removeAttribute('style')
    })
  }, [iframeRef])

  const changeDevice = type => {
    scrollToTop()
    setDevice(type)
  }

  return (
    <Styled.PreviewContainer>
      <TopBar
        activeDevice={activeDevice}
        goBack={goBack}
        applyPreset={applyPreset}
        openInNewTab={() => {
          openUrl(iframeSrc)
        }}
        changeDevice={changeDevice}
        btnLabel={btnLabel}
        showOpenInNewTabBtn={showOpenInNewTabBtn}
      />
      <Styled.DeviceMockupContainer>
        <If
          condition={showLoader}
          then={() => (
            <Styled.PreviewLoaderContainer>
              <RippleLoading
                color={primary.Dark}
                text={translate('preparing_for_preview_label')}
              />
            </Styled.PreviewLoaderContainer>
          )}
        />
        <If
          condition={device.mockupSrc}
          then={() => (
            <Styled.DeviceFrame
              deviceType={activeDevice}
              src={device.mockupSrc}
              alt="device mockup"
            />
          )}
        />
        {!isProjectLoading && (
          <Styled.Iframe
            src={iframeSrc}
            deviceType={activeDevice}
            ref={iframeRef}
            title="Preview website"
            style={{
              border: 'none',
              visibility: iframeLoaded ? 'visible' : 'hidden'
            }}
            onLoad={() => {
              setLoadStatus(true)
            }}
          />
        )}
      </Styled.DeviceMockupContainer>
    </Styled.PreviewContainer>
  )
}

export default memo(Preview)
