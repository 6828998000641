// @flow

import React, { useState, useContext } from 'react'
import * as S from './styled'
import {
  EMPTY_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  SUCCESS_MESSAGE
} from './consts'
import { subscribeAPI } from '@website/common/api'
import { EditingContext, SiteHashContext, SiteIdContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { isValidEmail } from '@website/common/utils'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import SuccessIcon from '@website/common/assets/success_icon_green.svg'

const SubscribeForm = ({ actions, buttonText, placeholder, theme }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [email, setEmail] = useState('')

  const { isEditing } = useContext(EditingContext)
  const siteId = useContext(SiteIdContext)
  const siteHash = useContext(SiteHashContext)

  const hideMessage = (): void => {
    setSuccessMessage('')
  }

  const handleSubscriptionSuccess = (): void => {
    setEmail('')
    setSuccessMessage(SUCCESS_MESSAGE)
    setTimeout(hideMessage, 4000)
  }

  const handleEmailChange = (e: SyntheticEvent): void => {
    if (isEditing) {
      actions.placeHolderAction(e.target.value)
      return
    }
    setEmail(e.target.value)
    setSuccessMessage('')
    setErrorMessage('')
  }

  const validateEmail = () => {
    if (email === '') {
      setErrorMessage(EMPTY_FIELD_MESSAGE)
    }
    if (email !== '' && !isValidEmail(email)) {
      setErrorMessage(INVALID_EMAIL_MESSAGE)
    }
  }

  const handleError = () => {
    if (isEditing) {
      return
    }
    validateEmail()
  }

  const handleSubscribe = isEditing => (): void => {
    if (!siteId || isEditing) {
      return
    }
    const body = {
      email,
      siteHash
    }
    setSuccessMessage('')
    validateEmail()
    if (isValidEmail(email)) {
      setSuccessMessage(SUCCESS_MESSAGE)
      subscribeAPI(siteId, body)
        .then(handleSubscriptionSuccess)
        .catch(console.warn)
    }
  }

  return (
    <>
      <form onSubmit={e => e.preventDefault()}>
        <S.InputWrapper className="wm-email-input-wrapper">
          <S.Input
            type="text"
            isError={errorMessage}
            value={isEditing ? placeholder : email}
            placeholder={isEditing ? '' : placeholder}
            isEditing={isEditing}
            className="wm-email-input WM_GLOBAL_secondary-font"
            aria-label="Email input"
            onChange={handleEmailChange}
            compTheme={theme}
            onBlur={handleError}
          />
          {errorMessage && (
            <S.ErrorMessage className="error-message">
              {' '}
              <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
              {errorMessage}
            </S.ErrorMessage>
          )}
        </S.InputWrapper>
        <ControlsWithModalOpener actions={actions.buttonActions}>
          <S.Button
            onClick={handleSubscribe(isEditing)}
            className="WM_GLOBAL_secondary-font wm-email-btn"
            compTheme={theme}
          >
            {buttonText}
          </S.Button>
        </ControlsWithModalOpener>
      </form>
      {successMessage && (
        <S.SuccessMessage className="success-message">
          {' '}
          <S.SuccessIcon src={SuccessIcon} alt="Success icon" />
          {successMessage}
        </S.SuccessMessage>
      )}
    </>
  )
}

export default SubscribeForm
