/* @flow */
import styled from 'styled-components'
import THEME from './Portfolio4.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  font-size: 0;
  padding-bottom: 0;
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : ' 80px 0')};
  }

  @media only screen and (max-width: 650px) {
    padding-bottom: 0;
  }
  .lazy-figure {
    height: initial;
    padding-top: 56.25%;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  @media only screen and (max-width: 1024px) {
    padding-bottom: 40px;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    p,
    h1 {
      padding-top: 0;
    }
  }
`

const PortfolioImageFigure = styled.div`
  font-size: 0;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  padding-top: 20px;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const ControlContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 56.25%;
    height: initial;
  }
`

export default {
  Img,
  StyledContainer,
  PortfolioImageFigure,
  Title,
  Description,
  ControlContainer,
  TextContent
}
