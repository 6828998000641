/* @flow */

module.exports = {
  id: 'Cookie',
  isUnique: false,
  label: 'Cookie',
  // category: 'cookie',
  thumbnail: '',
  colorMode: '',
  defaultProps: {
    message: 'This website uses cookies to give you the best experience.',
    buttonText: 'okay'
  }
}
