// @flow
import { translate } from '@editor/common/utils/translations'

export const INITIAL_ACTIVE_CATEGORY = { id: 1, name: translate('all_label') }
export const INITIAL_ACTIVE_SUBCATEGORY = { id: null, name: '' }
export const INITIAL_ACTIVE_CATEGORY_INFO = {
  activeCategory: INITIAL_ACTIVE_CATEGORY,
  activeSubcategory: INITIAL_ACTIVE_SUBCATEGORY
}
export const INITIAL_NAVBAR_LINKS = [{ link: '/', text: 'Home' }]

const TEMPLATES_INFO_INITIAL_STATE: TAppTemplateInfoState = {
  searchTerm: '',
  totalCount: 0,
  categories: {
    isLoading: false,
    payload: []
  },
  templates: {
    isLoading: false,
    payload: []
  },
  templatePresetCategories: [],
  newestTemplates: [],
  activeCategoryInfo: INITIAL_ACTIVE_CATEGORY_INFO
}

export const RESET_CHANGES_POPUP_INITIAL_STATE = {
  isOpen: false,
  isLoading: false,
  isSuccess: false,
  isFailed: false,
  errorMessage: ''
}

export const EDITOR_INITIAL_STATE = {
  user: { isLoading: true },
  hasChangesAfterPublish: false,
  hasPremiumFeature: false,
  resetChangesPopupState: RESET_CHANGES_POPUP_INITIAL_STATE,
  loginPopupState: false,
  sharedLinkData: {
    isLoading: false,
    payload: {
      projectId: null,
      expireDate: '',
      link: ''
    }
  },
  shareLinkPopupState: false,
  gdprSettingsCustomizationWindowState: false,
  mobilePagesPopupState: false,
  mobileProfilePopupState: false,
  contributorSignoutPopupState: false,
  statusChangeAndPublishPopupState: false,
  currentUserName: '',
  isProjectSyncing: false,
  subscriptions: [],
  tariffPlans: {
    isLoading: true,
    payload: {}
  },
  upgradePrices: [],
  templatesInfo: TEMPLATES_INFO_INITIAL_STATE,
  applyTemplateId: null,
  siteData: {},
  mediaLimits: null,
  addComponentIndex: -1,
  showWarningModal: null,
  showMaintenanceModal: false,
  pendingRevisionCount: 0,
  isProjectLoaded: false,
  templatesData: {},
  publishingProject: false,
  siteId: null,
  imagesUploadInfo: {},
  projectFileUrls: {},
  countries: [],
  activeSidebarItem: '',
  isMainSidebarExpanded: true,
  publishPopupState: false,
  domains: {
    rows: [],
    count: 0,
    isLoading: false,
    searchQuery: ''
  },
  subdomain: {
    isSaving: false,
    errorMessage: null
  },
  ownDomain: {
    isLoading: false,
    errorMessage: '',
    sslSuccessMessage: '',
    sslErrorMessage: '',
    configured: {
      isConfigured: false,
      isLoading: false,
      message: ''
    },
    isCongratsPopupOpen: false
  },
  settings: {
    isLoading: false,
    successMessage: '',
    marketingSuccessMessages: {
      googleAnalyticsSuccessMessage: '',
      googleTagManagerSuccessMessage: '',
      googleAdSenseSuccessMessage: '',
      authDigitalSellersSuccessMessage: '',
      facebookPixelSuccessMessage: '',
      yandexMetricaSuccessMessage: ''
    }
  },
  siteOnlineStatus: {
    isLoading: false,
    isFailed: false
  },
  activePagePath: '/',
  globalErrorMessage: '',
  websiteActiveLanguageCode: '',
  isWebsiteLanguageChanging: false,
  possibleLanguagesData: {
    data: [],
    isLoading: false
  },
  isSemrushKeywordPopupOpen: false,
  isSemrushLoginWindowOpen: false,
  semrushSidebar: {
    isPinned: false,
    isOpen: false
  },
  isEventCreationPopupOpen: false,
  timeZonesData: [],
  isLogoApplying: false
}

export const EMPTY_SITE_DATA = {
  createdAt: '',
  domain: null,
  id: null,
  name: 'new website',
  order: null,
  paid: false,
  state: null,
  status: 1,
  subdomain: null,
  thumbnail: '',
  updatedAt: '',
  userId: null,
  isOnline: false,
  isRfDomain: false
}

export const EMPTY_PROJECT_DATA = {
  visualParams: {
    primaryFont: 'Montserrat Bold',
    secondaryFont: 'Montserrat Light',
    palette: {
      primaryDark: '#7367F0',
      primaryLight: '#CE9FFC',
      secondaryDark: '#4975F3',
      secondaryLight: '#97ABFF',
      tertiaryDark: '#293761',
      tertiaryNormal: '#6375A9',
      tertiarySemiLight: '#B2C0E8',
      tertiaryLight: '#F5F7FF',
      tertiaryExtraLight: '#FBFCFF',
      accentWhite: '#FFFFFF'
    },
    textSize: 50,
    look: 'ROUNDED'
  },
  extraLinksLabel: 'More',
  headerLinks: INITIAL_NAVBAR_LINKS,
  footerLinks: INITIAL_NAVBAR_LINKS,
  pages: [
    {
      meta: { title: '', keywords: '', description: '', useForAll: false },
      path: '/',
      components: []
    }
  ],
  generator: '1.1.137'
}

export const META_SETTINGS_INITIAL_DATA = {
  isLoading: false,
  data: {
    email: '',
    facebookPixelCode: '',
    yandexMetricaId: '',
    googleAnalyticsId: '',
    googleAdSenseId: '',
    googleTagManagerId: '',
    authDigitalSellersContent: '',
    favicon: '',
    socialImage: ''
  }
}

export const WIDGETS_INITIAL_DATA = {
  isLoading: false,
  data: {
    messengers: {
      settings: {
        alignment: 'right'
      },
      data: []
    },
    chats: {
      data: []
    }
  }
}

export const COOKIE_SETTINGS_INITIAL_DATA = {
  isLoading: false,
  data: {}
}

export const WEBSITE_LANGUAGES_DATA = {
  data: [],
  isLoaded: false,
  isSuccessPopupOpen: false
}

export const SEMRUSH_KEYWORD_SEARCH_INITIAL_DATA = {
  loginInfo: {
    isLoading: false,
    isLoggedIn: false
  },
  data: {
    isOvered: false,
    isLoading: false,
    hasLimitExceeded: false,
    isPaginationLoading: false
  },
  queries: {
    phrase: '',
    database: 'US',
    activeTab: 'phrase_fullsearch',
    sortingInfo: {
      columnKey: 'Nq',
      isAscending: false
    }
  }
}

export const EVENTS_INITIAL_DATA = {
  isEventsLoading: false,
  isEventCreating: false,
  isTranslationLoading: false,
  successMessage: '',
  data: [],
  translations: {},
  guestsList: {
    isLoading: false,
    isPaginationLoading: false,
    data: [],
    totalResultsCount: 0,
    totalGuestsCount: 0,
    resultCounts: {
      answers: {},
      languages: []
    },
    filters: {
      searchStr: '',
      answer: 'all',
      language: 'all',
      sortingInfo: {
        columnName: 'date',
        isAscending: false
      }
    },
    formDataFromRevision: {}
  }
}

export const COMPONENTS_INITIAL_DATA = {
  componentCategoriesInfo: {
    isLoading: false,
    data: []
  },
  componentsList: {
    isLoading: false,
    payload: []
  },
  componentsData: {
    isLoading: false,
    payload: []
  }
}

export const UPLOAD_PATH =
  typeof FILE_UPLOAD_PATH !== 'undefined'
    ? FILE_UPLOAD_PATH
    : '//user-media-upload.local.renderforest.com/api/v3'
