/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx: idx__ } = action.value
        draft.blocks.splice(idx__, 1)
        break
      }
      case 'ADD_IMAGE_BLOCK':
        draft.images.push(initialState.images[0])
        break
      case 'REMOVE_IMAGE_BLOCK': {
        const { idx: idx__ } = action.value
        draft.images.splice(idx__, 1)
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.images[idx].imageUrl = url
        draft.images[idx].imgDimensions = dimensions || {}
        draft.images[idx].imgCropParams = {}
        break
      }

      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({ ...initialState.buttons[0], type: btnType })
        } else {
          draft.buttons.push({ ...initialState.buttons[0], type: btnType })
        }
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.images[idx].imgCropParams = imgCropParams
        break
      }
      case 'CHANGE_ICON':
        const { idx: idx_, iconName } = action.value
        draft.blocks[idx_].iconName = iconName
        break
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.blocks[idx].iconName = ''
        break
      }
      case 'ADD_ICON':
        draft.blocks[action.value].iconName = 'edit'
        break
      case 'CHANGE_BLOCK_TITLE':
        draft.blocks[action.value.idx].title = action.value.newBlockTitle
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        draft.blocks[action.value.idx].paragraph =
          action.value.newBlockParagraph
        break
    }
  })
}
