/* @flow */
import styled from 'styled-components'
import THEME from './Pricing5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  > :first-child {
    padding-top: 0;
  }
`
const HeaderContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const CardsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  padding-top: 40px;
`

const CardControlsWrapper = styled.article`
  margin: 10px 0 0 0;
  width: 100%;
  max-width: 800px;
`

const PriceWrapper = styled.div`
  width: 100%;
  max-width: 220px;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    max-width: unset;
    justify-content: center;
    padding: 20px;
  }
`

const Heading = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  & + p {
    margin-top: 20px;
  }
`

const Paragraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  margin-top: 0px;
  ${getTextStyleByAlignment};
`

const Title = styled.h1`
  width: 100%;
  font-size: 32px;
  color: ${({ isPrimary, theme }) =>
    isPrimary ? theme.palette.accentWhite : theme.palette.tertiaryDark};
  font-weight: bold;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  width: 100%;
  color: ${({ isPrimary, theme }) =>
    isPrimary ? theme.palette.accentWhite : theme.palette.tertiaryNormal};
  padding: 4px 0;
  ${getTextStyleByAlignment};
`

const CardContent = styled.div`
  transform: ${({ isPrimary }) => (isPrimary ? 'translateX(-5px)' : 'initial')};
  padding: 32px 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 620px;
  position: relative;
  border-right: 4px dotted;
  border-right-color: ${getStyleForProp('dotColor')};
  @media only screen and (max-width: 940px) {
    transform: ${({ isPrimary }) =>
      isPrimary ? 'translateX(-4px)' : 'initial'};
  }
  @media only screen and (max-width: 600px) {
    transform: ${({ isPrimary }) =>
      isPrimary ? 'translateX(0px)' : 'initial'};
  }
  @media only screen and (max-width: 600px) {
    transform: 'initial';
    border-right: none;
    border-bottom: 4px dotted;
    border-bottom-color: ${getStyleForProp('dotColor')};
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Price = styled.p`
  width: 100%;
  font-size: 32px;
  color: ${({ isPrimary, theme }) =>
    isPrimary ? theme.palette.accentWhite : theme.palette.tertiaryDark};
  ${getTextStyleByAlignment};
`

const PriceCard = styled.div`
  transform: ${({ isPrimary }) => (isPrimary ? 'scale(1.03)' : 'initial')};
  align-items: center;
  display: flex;
  align-items: stretch;
  position: relative;
  background: ${({ isPrimary }) =>
    isPrimary ? getStyleForProp('primaryBlock') : '#ffffff'};
  border-radius: ${getStyleForProp('borderRadius')};
  border: ${getStyleForProp('border')};
  box-shadow: ${getStyleForProp('raisedShadow')};
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
  .control-container {
    width: 100%;
  }
`

const Scale = styled.div`
  display: ${({ isPrimary }) => (isPrimary ? 'block' : 'none')};
  width: 20px;
`

export default {
  HeaderContainer,
  CardsContainer,
  CardControlsWrapper,
  PriceWrapper,
  Title,
  Heading,
  Description,
  StyledContainer,
  WmCustomContainer,
  Paragraph,
  PriceCard,
  Price,
  CardContent,
  Scale
}
