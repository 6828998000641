/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Portfolio6_2.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  width: 100%;
  position: relative;
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
  .LazyLoad {
    width: 100%;
  }
  > :first-child {
    padding-top: 0;
  }
`

const LeftLine = styled.div`
  width: 10px;
  height: 420px;
  background-color: ${getStyleForProp('subTitle')};
  position: absolute;
  left: 0;
  top: 0;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 6px;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 860px;
  margin: 0 auto 50px auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 40px;
    max-width: 100%;
  }
  & > .control-container {
    margin-bottom: 30px;
    p,
    h2 {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('title')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const SubTitle = styled.h2`
  color: ${getStyleForProp('subTitle')};
  margin-bottom: 0;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const RatioWrapper = styled.div`
  margin: 10px;
  @media only screen and (max-width: 768px) {
    margin: 7px;
  }
  @media only screen and (max-width: 600px) {
    margin: 4px;
  }
  &.ratio-wrapper-1,
  &.ratio-wrapper-4 {
    width: 60%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  &.ratio-wrapper-2,
  &.ratio-wrapper-3 {
    width: 30%;
    @media only screen and (max-width: 768px) {
      width: 40%;
      flex-grow: 1;
    }
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
    overflow: hidden;
  }
  .lazy-figure {
    height: initial;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  &.img-wrapper-1,
  &.img-wrapper-4 {
    .lazy-figure,
    .ControlContainer-1,
    .ControlContainer-4 {
      padding-top: 50%;
    }
  }
  &.img-wrapper-2,
  &.img-wrapper-3 {
    .lazy-figure,
    .ControlContainer-2,
    .ControlContainer-3 {
      padding-top: 100%;
    }
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const GridWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: calc(100% + 76px);
  margin-left: -38px;
  font-size: 0;
  &:first-child {
    margin-top: -10px;
  }
  &:last-child {
    margin-bottom: -10px;
    @media only screen and (max-width: 457px) {
      margin-bottom: -5px;
    }
  }
  @media only screen and (max-width: 1024px) {
    display: flex;
    flex-wrap: wrap;
    width: 108%;
    margin-left: -4%;
  }
  @media only screen and (max-width: 767px) {
    width: 102%;
    margin-left: -1%;
  }
  @media only screen and (max-width: 457px) {
    width: calc(100% + 8px);
    margin-left: -4px;
  }
`

const AddButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 374px) {
    flex-direction: column;
    align-items: center;
  }
`

export default {
  StyledContainer,
  HeaderContent,
  Title,
  SubTitle,
  Paragraph,
  Img,
  GridWrapper,
  LeftLine,
  AddButtonsWrap,
  RatioWrapper,
  ImageWrapper,
  ControlContainer
}
