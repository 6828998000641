/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_IMAGE':
        {
          const { idx, url, dimensions } = action.value
          draft.images[idx].imgUrl = url
          draft.images[idx].imgDimensions = dimensions
          draft.images[idx].imgCropParams = {}
        }
        break
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx } = action.value
          draft.images[idx].imgCropParams = imgCropParams
        }
        break
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.images[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_PRICE': {
        const { idx, newPrice } = action.value
        draft.images[idx].price = newPrice
        break
      }
      case 'CHANGE_PRICE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.images[idx].priceAlignment = alignment
        break
      }
      case 'CHANGE_TEXT': {
        const { idx, newTitle } = action.value
        draft.images[idx].title = newTitle
        break
      }
      case 'REMOVE_LIST_ITEM':
        draft.blocks.splice(action.value, 1)
        break
      case 'ADD_NEW_ITEM':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'CHANGE_ICON':
        const { idx, iconName } = action.value
        draft.blocks[idx].iconName = iconName
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnData } = action.value
        draft.buttonUrl = btnData.link
        draft.buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BUTTON':
        draft.buttonText = initialState.buttonText
        draft.buttonUrl = initialState.buttonUrl
        break
    }
  })
}
