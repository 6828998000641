import styled from 'styled-components'

export const WarningContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: rgba(255, 255, 255, 0.9);

  h3 {
    color: #363f5a;
    font-size: 20px;
    margin: 30px 0;
  }

  button {
    padding: 11px 30px;
  }
`
