/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TEXT':
        const { newText, idx } = action.value
        if (newText === '') {
          draft.ctaContent.splice(idx, 1)
          break
        }
        draft.ctaContent[idx].text = newText
        break
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions || {}
        draft.imgCropParams = {}
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({ ...initialState.buttons[0], type: btnType })
        } else {
          draft.buttons.push({ ...initialState.buttons[0], type: btnType })
        }
        break
      case 'ADD_TITLE':
        draft.ctaContent.push(initialState.ctaContent[1])
        break
      case 'ADD_PARAGRAPH':
        draft.ctaContent.push(initialState.ctaContent[0])
        break
      case 'CHANGE_TEXT_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.ctaContent[idx].textAlignment = alignment
        break
      }
    }
  })
}
