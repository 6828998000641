import React from 'react'
import { withTheme } from 'styled-components'

const SvgCircle = ({
  className,
  done = 0,
  max = 24,
  radius = 71,
  strokeWidth = 6,
  theme
}) => {
  const size = (radius + strokeWidth) * 2
  const length = Math.ceil(2 * radius * Math.PI)
  const { palette } = theme
  const remainingLength =
    length - Math.ceil(2 * radius * Math.PI) * (done / max)
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="linearGradient-1" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={palette.primaryDark} />
          <stop offset="100%" stopColor={palette.primaryLight} />
        </linearGradient>
      </defs>
      <g>
      <circle
          className="circle"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          strokeWidth={strokeWidth}
        />
        <circle
          className="circle--bg"
          r={radius}
          cx={radius + strokeWidth}
          cy={radius + strokeWidth}
          stroke="url(#linearGradient-1)"
          strokeWidth={strokeWidth + 1}
          strokeDasharray={length}
          strokeDashoffset={remainingLength}
          strokeLinecap="round"
        />
      </g>
    </svg>
  )
}
export default withTheme(SvgCircle)
