/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockText = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { idx, newTitle }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    addBlock: () => {
      dispatcher(addBlock())
    },
    changeBlockText,
    changeBlockTitleAlignment,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
