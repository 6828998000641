/* @flow */
import React from 'react'
import { connect } from 'react-redux'
import Icon from '@website/common/components/Icon'
import Text from '@website/common/components/Text'
import { uploadFiles } from '@actions/editor'
import { cacheImage } from '@editor/common/utils'
import { ALLOWED_IMAGE_MIME_TYPES, VIDEO_MIME_TYPES } from '@editor/conf/consts'
import { secondary } from '@website/common/styles/colors'
import * as Styled from './styled'
import { translate } from '@editor/common/utils/translations'

const fileTypes = {
  image: ALLOWED_IMAGE_MIME_TYPES,
  video: VIDEO_MIME_TYPES
}

class MediaUploadButton extends React.Component<*, *> {
  ref: null

  beginUploadProcess = (e: Event) => {
    const { accept, uploadType, insert, dispatch, onError } = this.props
    const file = e.target.files[0]
    const isInvalidFile =
      !(file instanceof File) ||
      (accept && accept.indexOf(file.type) === -1) ||
      (uploadType && fileTypes[uploadType].indexOf(file.type) === -1)

    if (isInvalidFile) {
      onError && onError()
      return
    }

    const blobUrl = URL.createObjectURL(file)
    const onFileUploadEnd = (res: IUploadResultItem) => {
      if (this.ref) {
        this.ref.value = null
      }
      cacheImage(res.filePath)
        .then(() => {
          insert(res.filePath, res)
        })
        .catch(console.log)
    }
    const onStart = () => {
      insert(blobUrl)
    }

    dispatch(uploadFiles(file, onFileUploadEnd, onStart, onError))
  }

  handleClick = e => {
    if (this.props.disabled) {
      return
    }
    if (this.props.stopPropagation) {
      e.stopPropagation()
    }
    this.ref.click()
  }

  render() {
    const {
      className,
      children,
      uploadType,
      accept,
      disabled = false
    } = this.props
    const _accept = accept || fileTypes[uploadType].join(', ')
    return (
      <Styled.MediaUploadButtonWrapper
        disabled={disabled}
        className={className}
        onClick={this.handleClick}
      >
        {children ? (
          children
        ) : (
          <>
            <Icon color={secondary.Dark} name="icon-upload" size="xlarge" />
            <Text className="button-text">{translate('upload_label')}</Text>
          </>
        )}
        <input
          hidden
          type="file"
          accept={_accept}
          ref={ref => {
            ref && ref.addEventListener('input', this.beginUploadProcess)
            this.ref = ref
          }}
        />
      </Styled.MediaUploadButtonWrapper>
    )
  }
}

export default connect()(MediaUploadButton)
