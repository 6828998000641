//@flow
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  useContext
} from 'react'
import { connect } from 'react-redux'

import { openLoginPopup } from '@actions/editor'
import { BackgroundSidebarContext, ControlModalsContext } from '@contexts'
import { selectIsUserAuthorized } from '@selectors'
import ReplaceIcon from '@editor/common/assets/svgr-icons/replace_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import TransparencyIcon from '@editor/common/assets/svgr-icons/Transparency_icon.svg'
import RangeSlider from '@editor/common/components/RangeSlider'
import { translate } from '@editor/common/utils/translations'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import { _debounce } from '@website/common/utils'

import { Control, ControlsBar } from '../components'
import { RemoveIcon } from '../styled'
import BackgroundImageControl from './BackgroundImageControl'
import BackgroundVideoControl from './BackgroundVideoControl'
import * as Styled from './styled'
import type { TBackgroundControlsChildProps } from './types'

const BackgroundControlsDesktop = ({
  componentUniqId,
  backgroundImage,
  backgroundVideo,
  bgOverlayTransparency,
  isBgImageAllowed,
  isBgVideoAllowed,
  isBgTransparencyAllowed,
  hasBgImageOrVideo,
  isUserAuthorized,
  hasLimitedBackgrounds,
  onMediaRemove,
  onRangeSliderChange,
  openLoginPopup,
  openImageFlyout,
  openVideoFlyout
}: TBackgroundControlsChildProps) => {
  const [isExpanded, setExpandedState] = useState(hasBgImageOrVideo)
  const [isTransparencyFlyoutOpen, setTransparencyFlyoutState] = useState(false)
  const replaceControlRef = useRef(null)
  const bgImageControlRef = useRef(null)

  const { open: openBackgroundSidebar } = useContext(BackgroundSidebarContext)
  const { modalStates } = useContext(ControlModalsContext)
  const isFlyoutOpen = modalStates[MODAL_TYPES.mediaModal].isOpen
  const bgChangeControlClassName = `bg-change-control-${componentUniqId}`

  const commonState = {
    isOpen: !isFlyoutOpen,
    isFlyoutPositionFixed: false,
    alignment: 'right',
    renderingContainer: bgChangeControlClassName
  }

  // after uploading expand controls bar
  useEffect(() => {
    if (hasBgImageOrVideo) {
      setExpandedState(true)
    }
  }, [hasBgImageOrVideo])

  const onReplaceImage = useCallback(() => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }

    if (hasLimitedBackgrounds) {
      openBackgroundSidebar()
      return
    }

    openImageFlyout({
      targetRef: replaceControlRef,
      shift: { x: 0, y: 35 },
      ...commonState
    })
  }, [
    commonState,
    isUserAuthorized,
    commonState,
    replaceControlRef,
    openImageFlyout,
    openLoginPopup
  ])

  const onReplaceVideo = useCallback(() => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }

    openVideoFlyout({
      targetRef: replaceControlRef,
      shift: { x: 0, y: 35 },
      ...commonState
    })
  }, [
    commonState,
    replaceControlRef,
    isUserAuthorized,
    openVideoFlyout,
    openLoginPopup
  ])

  const _openImageFlyout = useCallback(() => {
    openImageFlyout({
      targetRef: bgImageControlRef,
      shift: { x: 0, y: 40 },
      ...commonState
    })
  }, [
    bgImageControlRef,
    commonState,
    openImageFlyout,
    hasLimitedBackgrounds,
    openBackgroundSidebar
  ])

  const _onMediaRemove = useCallback(
    e => {
      onMediaRemove(e)
      setExpandedState(false)
    },
    [onMediaRemove]
  )

  const onTransparencyControlClick = useCallback(() => {
    setTransparencyFlyoutState(!isTransparencyFlyoutOpen)
  }, [isTransparencyFlyoutOpen])

  const tooltipContent = useMemo(() => {
    return (
      <Styled.TransparencyFlyoutContent>
        <p>{translate('transparency_label')}</p>
        <RangeSlider
          value={1 - bgOverlayTransparency}
          max={1}
          step={0.01}
          onChange={_debounce(onRangeSliderChange, 150)}
        />
      </Styled.TransparencyFlyoutContent>
    )
  }, [onRangeSliderChange, bgOverlayTransparency])

  return (
    <ControlsBar
      tooltipText={
        !isExpanded
          ? `${
              isBgVideoAllowed
                ? translate('background_video_label')
                : translate('background_image_label')
            }`
          : ''
      }
      isExpanded={isExpanded}
      showControlIcon={false}
      setExpandedState={setExpandedState}
      isDefaultPrevented={!hasBgImageOrVideo}
      isTooltipHidden={isFlyoutOpen}
      hasLimitedBackgrounds={hasLimitedBackgrounds}
    >
      <Styled.Controls>
        <Styled.GlobalStyles />
        {isBgImageAllowed ? (
          <BackgroundImageControl
            backgroundImage={backgroundImage}
            className={bgChangeControlClassName}
            bgImageControlRef={bgImageControlRef}
            openImageFlyout={_openImageFlyout}
            hasLimitedBackgrounds={hasLimitedBackgrounds}
          />
        ) : isBgVideoAllowed ? (
          <BackgroundVideoControl
            backgroundVideo={backgroundVideo}
            className={bgChangeControlClassName}
            openVideoFlyout={openVideoFlyout}
          />
        ) : null}
        {isExpanded && hasBgImageOrVideo ? (
          <>
            <Control
              IconComp={ReplaceIcon}
              tooltipText={
                isFlyoutOpen
                  ? ''
                  : `${
                      isBgVideoAllowed
                        ? translate('replace_video_label')
                        : translate('replace_image_label')
                    }`
              }
              onClick={isBgVideoAllowed ? onReplaceVideo : onReplaceImage}
              controlRef={replaceControlRef}
              className={`${bgChangeControlClassName} bg-change-control`}
            />
            {hasBgImageOrVideo && isBgTransparencyAllowed ? (
              <TooltipWrapper
                disableHover
                isOpen={isTransparencyFlyoutOpen && isExpanded}
                content={tooltipContent}
                offsets={{ y: 20 }}
                position="bottom"
                className="transparency-control"
                color="#252E48"
                onClose={() => setTransparencyFlyoutState(false)}
              >
                <Control
                  IconComp={TransparencyIcon}
                  tooltipText={
                    !isTransparencyFlyoutOpen
                      ? translate('adjust_transparency_label')
                      : ''
                  }
                  isSelected={isTransparencyFlyoutOpen}
                  onClick={onTransparencyControlClick}
                />
              </TooltipWrapper>
            ) : null}
            {!hasLimitedBackgrounds ? (
              <Control
                IconComp={RemoveIcon}
                tooltipText={`${
                  isBgVideoAllowed
                    ? translate('delete_video_label')
                    : translate('delete_image_label')
                }`}
                onClick={_onMediaRemove}
              />
            ) : null}
          </>
        ) : null}
      </Styled.Controls>
    </ControlsBar>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

const mapDispatchToProps = { openLoginPopup }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundControlsDesktop)
