/* @flow */
import React, { useRef, memo } from 'react'

import S from '../Steps8.styled'
import useOnScreen from '@website/common/hooks/useOnScreen'

const Circle = memo(({ isEditing, idx, isZero }) => {
  const animatedRef = useRef(null)
  const isOnScreen = useOnScreen(animatedRef, { triggerOnce: true })

  return (
    <S.Circle isEditing={isEditing} ref={animatedRef} isOnScreen={isOnScreen}>
      {isZero ? `0${idx + 1}` : idx + 1}
    </S.Circle>
  )
})

export default Circle
