import React, { memo } from 'react'
import * as Styled from './animationStyled'

const StatusAnimationWrapper = () => (
  <Styled.StatusAnimationWrapper>
    <span />
    <div className="wrapper-1">
      <span />
    </div>
    <div className="wrapper-2">
      <span />
    </div>
    <div className="wrapper-3">
      <span />
    </div>
    <div className="wrapper-4">
      <span />
    </div>
    <div className="wrapper-5">
      <span />
    </div>
  </Styled.StatusAnimationWrapper>
)

export default memo(StatusAnimationWrapper)
