/* @flow */
import React, { useContext } from 'react'
import S from './Feature11.styled'
import { validation } from './Feature11.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'

import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import WMContainer from '@website/common/components/WMContainer'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext, EditingContext } from '@contexts'
import './Feature11.styles.sass'
import { translate } from '@editor/common/utils/translations'

const Feature11 = props => {
  const {
    data: { title, paragraph, features, imageUrl, buttons, imgDimensions }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <EditingContext.Consumer>
      {({ isEditing }) => {
        return (
          <S.Container isEditing={isEditing}>
            <WMContainer className="feature11-container">
              <Show when={[title, paragraph, features.length > 0, ...buttons]}>
                <div className="feature-content feature-item">
                  <div className="content-wrapper">
                    <Show when={[title]}>
                      <EditableContent
                        text={title}
                        as={S.Title}
                        maxCount={validation.headingMaxChar}
                        onChange={(newTitle: string) =>
                          dispatcher(Actions.changeTitle(newTitle))
                        }
                        className="WM_GLOBAL_heading72"
                      />
                    </Show>
                    <Show when={[paragraph]}>
                      <EditableContent
                        text={paragraph}
                        as={S.Paragraph}
                        maxCount={validation.paragraphMaxChar}
                        onChange={(newParagraph: string) =>
                          dispatcher(Actions.changeParagraph(newParagraph))
                        }
                        className="WM_GLOBAL_paragraph18"
                      />
                    </Show>
                    <Show when={[features.length > 0]}>
                      <ul className="list">
                        <Mapper
                          data={features}
                          render={(feature: string, idx: number) => (
                            <Show key={idx} when={[feature.paragraph]}>
                              <div className="list-item-wrap" key={idx}>
                                <S.IconWrap>
                                  <ControlsWithModalOpener
                                    actions={Actions.iconActions({
                                      idx,
                                      showRemove: false
                                    })}
                                  >
                                    <S.Icon name={feature.icon} size="large" />
                                  </ControlsWithModalOpener>
                                </S.IconWrap>
                                <ControlsWithModalOpener
                                  actions={Actions.featureActions(
                                    idx,
                                    features.length
                                  )}
                                  key={idx}
                                >
                                  <EditableContent
                                    toPropogate={false}
                                    text={feature.paragraph}
                                    as={S.ListItem}
                                    maxCount={validation.checkmarkMaxChar}
                                    onChange={(newContent: string) =>
                                      dispatcher(
                                        Actions.changeListItem(newContent, idx)
                                      )
                                    }
                                    className="list-item WM_GLOBAL_secondary-font"
                                  />
                                </ControlsWithModalOpener>
                              </div>
                            </Show>
                          )}
                        />
                        <AddButton
                          onAdd={() => dispatcher(Actions.addNewItem())}
                          toShow={features.length < validation.features.max}
                          style={{
                            marginTop: '2rem'
                          }}
                          wide
                          large
                        >
                          +
                        </AddButton>
                      </ul>
                    </Show>
                    <Show when={[...buttons]}>
                      <S.ButtonsWrap className="btns-wrap WM_GLOBAL_secondary-font">
                        <Mapper
                          data={buttons}
                          render={(btn: string, btnIdx: number) => {
                            return (
                              <ControlsWithModalOpener
                                key={btnIdx}
                                actions={Actions.btnActions(btnIdx, {
                                  text: btn.buttonText,
                                  link: btn.buttonUrl
                                })}
                              >
                                <S.LinkWrapper
                                  isTertiary={btn.type === 'tertiary'}
                                >
                                  <LinkWrapper
                                    linkProps={{
                                      className: 'rf-comp-btn'
                                    }}
                                    text={btn.buttonText}
                                    url={btn.buttonUrl}
                                  />
                                </S.LinkWrapper>
                              </ControlsWithModalOpener>
                            )
                          }}
                        />
                        <AddButton
                          onAdd={() => dispatcher(Actions.addButton())}
                          toShow={buttons.length < validation.buttons.max}
                          rounded
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_button_label')}
                        </AddButton>
                      </S.ButtonsWrap>
                    </Show>
                  </div>
                </div>
              </Show>
              <div className="image-wrap feature-item">
                <ControlsWithModalOpener
                  actions={Actions.imgActions({
                    showRemove: false,
                    keyForSizes: 'feature11',
                    imageUrl
                  })}
                  style={{ width: '100%', height: '100%' }}
                >
                  <div className="image-figure">
                    <Image
                      as={S.Img}
                      defaultImgSrc={imageUrl}
                      sizes={imgDimensions}
                      alt="Section img"
                    />
                  </div>
                </ControlsWithModalOpener>
              </div>
            </WMContainer>
          </S.Container>
        )
      }}
    </EditingContext.Consumer>
  )
}

export default Feature11
