/* @flow */

import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TEXT': {
        const { blockIdx, text } = action.value
        draft.blocks[blockIdx].title = text
        break
      }
      case 'CHANGE_BLOCK_NAME':
        const { blockIdx, name } = action.value
        draft.blocks[blockIdx].name = name
        break

      case 'CHANGE_BLOCK_DESCRIPTION': {
        const { blockIdx, description } = action.value
        draft.blocks[blockIdx].description = description
        break
      }

      case 'CHANGE_BLOCK_NAME_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.blocks[blockIdx].blockNameAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_DESCRIPTION_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.blocks[blockIdx].blockDescriptionAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.blocks[blockIdx].blockTitleAlignment = alignment
        break
      }

      case 'CHANGE_CLIENT_LOGO': {
        const { url, imgDimensions } = action.value
        draft.logoImgUrl = url
        draft.logoImgDimensions = imgDimensions || {}
        break
      }
      case 'ADD_CLIENT_LOGO': {
        draft.logoImgUrl = initialState.logoImgUrl
        draft.logoImgDimensions = initialState.logoImgDimensions
        break
      }
      case 'REMOVE_CLIENT_LOGO': {
        draft.logoImgUrl = ''
        draft.logoImgDimensions = {}
        break
      }
      case 'ADD_LINK': {
        draft.additionalUrl = initialState.additionalUrl
        draft.additionalText = initialState.additionalText
        break
      }
      case 'REMOVE_LINK': {
        draft.additionalUrl = ''
        draft.additionalText = ''
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break

      case 'CHANGE_IMAGE': {
        const { url, dimensions } = action.value
        draft.largeImgUrl = url
        draft.largeImgDimensions = dimensions
        draft.largeImgCropParams = {}
        break
      }

      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams } = action.value
        draft.largeImgCropParams = imgCropParams
        break
      }

      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'CHANGE_ICON':
        const { idx: idx_, iconName } = action.value
        draft.blocks[idx_].iconName = iconName
        break
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.blocks[idx].iconName = ''
        break
      }
      case 'ADD_ICON':
        draft.blocks[action.value].iconName = 'edit'
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_PAYPAL_BUTTON_DATA':
        draft.paypalBtnDefaultProps = action.value.btnData
        break
      case 'CHANGE_CLIENT_URL':
        draft.clientUrl = action.value.link
        break
    }
  })
}
