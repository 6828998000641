/* @flow */

import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_NAME':
        draft.name = action.value
        break
      case 'CHANGE_NAME_ALIGNMENT': {
        draft.nameAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'ADD_LINK': {
        draft.additionalUrl = initialState.additionalUrl
        draft.additionalText = initialState.additionalText
        break
      }
      case 'REMOVE_LINK': {
        draft.additionalUrl = ''
        draft.additionalText = ''
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break
      case 'CHANGE_IMAGE':
        {
          const { url, dimensions, idx } = action.value
          draft.slides[idx].imageUrl = url
          draft.slides[idx].imgDimensions = dimensions
          draft.slides[idx].imageUrlImgCropParams = {}
        }
        break
      case 'SET_IMG_CROP_PARAMS':
        {
          const { idx, imgCropParams } = action.value
          draft.slides[idx].imgCropParams = imgCropParams
        }
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break

      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break

      case 'REMOVE_IMAGE': {
        const { idx } = action.value
        draft.slides.splice(idx, 1)
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        if (!url) {
          break
        }
        draft.slides[idx].imageUrl = url
        draft.slides[idx].imgDimensions = dimensions || {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
        break
      }
      case 'ADD_SLIDE': {
        const activeIdx = action.value
        draft.slides = [
          ...draft.slides.slice(0, activeIdx + 1),
          initialState.slides[0],
          ...draft.slides.slice(activeIdx + 1)
        ]
        break
      }
      case 'CHANGE_PAYPAL_BUTTON_DATA':
        draft.paypalBtnDefaultProps = action.value.btnData
        break
    }
  })
}
