/* @flow */

import {
  TABS,
  IMAGE_MIME_TYPES,
  VIDEO_MIME_TYPES
} from '@editor/conf/consts'

import { analyticsEvent } from '@editor/common/utils/analytics'
import { getHash } from '@shared/utils'

//   ---- used in components ----
const omitProp = (obj: any, _prop: string) =>
  Object.keys(obj).reduce((newObj, prop) => {
    if (prop === _prop) {
      return newObj
    }

    return {
      ...newObj,
      [prop]: obj[prop]
    }
  }, {})

/**
 * Filters given props or array of props from given object and returns
 * filtered one
 * @param {object} obj Object to filter props from
 * @param {*} _props Single prop or array of props to filter
 * @returns {object} Filtered object
 */
export const omit = (obj: any, _props: string | Array<string>) => {
  if (Array.isArray(_props)) {
    return _props.reduce(omitProp, obj)
  }
  return omitProp(obj, _props)
}

// TODO: rename this function to generateUniqId
export const generateHoverId = (): string =>
  Math.random().toString(36).substring(7)

export const arrayize = obj => Object.keys(obj).map(key => obj[key])

//   ---- used in editor ----
/**
 * Make actions undoable
 * @param {TAction} action
 */
export const undoable = action => ({ ...action, undoable: true })

//   ---- used in components ----

export const isMobileMenuTab = activeTab =>
  !!activeTab && Object.values(TABS).findIndex(tab => tab === activeTab) >= 0

//   ---- used in editor ----
export const postMaxSizeMessage = user => `
Dear ${user.firstName}, Looks Like your file is too large for your plan.your plan allows to upload up to ${user.postMaxSize} mb single files.
And ${user.uploadMaxFileSize} mb for total upload size with multiple files.If you want to upload more than ${user.uploadMaxFileSize} mb of files, with a bigger size of multiple file upload, please upgrade
to one of our plans.`

export const mediaLibLimitMessage =
  user => `Dear ${user.firstName} your have reached maximum size of your media library.
To upload more files, please upgrade you plan or remove some files
from library.`

export const getFileType = mime => {
  const isVideo = VIDEO_MIME_TYPES.find(mimeType => mimeType === mime)
  const isImage = IMAGE_MIME_TYPES.find(mimeType => mimeType === mime)
  if (isVideo) {
    return 'videos'
  }
  if (isImage) {
    return 'images'
  }
  return null
}

export const isPrimitiveValue = value => Object(value) !== value

//  ---- maybe are not being used anywhere, but leave in editor utils ----
// export const transferObjectPrimitiveValues = (donorObj, recipientObj) => {
//   const recipientKeys = Object.keys(recipientObj)
//   const obj = recipientKeys.reduce((resultObj, key) => {
//     const isPrimitiveValues =
//       isPrimitiveValue(donorObj[key]) && isPrimitiveValue(recipientObj[key])
//     const hasDonorValue = donorObj.hasOwnProperty(key) && donorObj[key]
//
//     const value =
//       isPrimitiveValues && hasDonorValue ? donorObj[key] : recipientObj[key]
//
//     return {
//       ...resultObj,
//       [key]: value
//     }
//   }, {})
//   return obj
// }

export const addAnalyticsEvent = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string | number
) => analyticsEvent(eventCategory, eventAction, eventLabel)

//   ---- used in editor ----
export const generateUniqId = () => {
  const randomNumber = Math.floor(Math.random() * 1000)
  return `${(+new Date() + randomNumber).toString(16)}`
}

export const isPlainObject = value =>
  Object.prototype.toString.call(value) === '[object Object]'

//     ---- used in editor ----
/**
 * This function will deep replace property of object "target" with corresponding value from "source"
 * Condition: target[key] === source[target[key]] && when(source[target[key]]) === true
 *
 * @param {object} n - target object
 * @param {object} n - source object
 * @param {function} n - when function
 * @return {object} joint object
 *
 * @example
 *
 *    leftJoin({key: someId}, {someId: value}) -> { key: value }
 */
export const leftJoin = (target, source, when = () => true) => {
  // in case if source is invalid
  if (!isPlainObject(source) || !Object.keys(source).length) {
    return target
  }
  // if target is primitive value, it may be join id
  if (isPrimitiveValue(target)) {
    return source.hasOwnProperty(target) && when(source[target])
      ? source[target]
      : target
  }
  // else if target is Array, process it recoursive
  else if (Array.isArray(target)) {
    return target.map(item => leftJoin(item, source, when))
  }
  // else if target is not a plain object, stop the function to avoid problems
  else if (!isPlainObject(target)) {
    return target
  }

  // process recoursive
  const targetKeys = Object.keys(target)
  const joint = {}
  targetKeys.forEach(key => {
    const targetValue = target[key]
    joint[key] = leftJoin(targetValue, source, when)
  })
  return joint
}

export const cacheImage = (src: string): Promise<string> => {
  return new Promise((res, rej) => {
    const img = new Image()
    img.addEventListener('load', () => {
      res(src)
    })
    img.addEventListener('error', e => {
      rej(e.message)
    })
    img.src = src
  })
}

export const isBlobUrl = url => url.indexOf('blob') !== -1

export const getHashFromParentWindow = (key: string) => {
  if (!global.window || !global.window.parent) {
    return ''
  }
  const p = global.window.parent.location.search
    .substr(1)
    .split('&')
    .map(pair => pair.split('='))
    .find(pair => pair[0] === key)

  if (p) {
    return p[1]
  }
}

export const isOpenedWithHash = () => {
  return !!getHash()
}

export const removeUrlQueryParams = (...params) => {
  const url = window.location.href
  const newUrl = url.split('?')[0]
  const queryString = url.split('?')[1]

  if (!queryString) {
    return url
  }

  const queryParams = queryString.split('&')

  const queryParamsFiltered = queryParams.filter(param => {
    const paramName = param.split('=')[0]
    return params.indexOf(paramName) === -1
  })

  const newQueryString = queryParamsFiltered.join('&')

  window.history.pushState(
    'object',
    document.title,
    `${newUrl}?${newQueryString}`
  )
}

export const insertItem = (
  arr: Array<string>,
  idx: number,
  item: string
): Array<string> => [...arr.slice(0, idx), item, ...arr.slice(idx)]

export const replaceItem = (
  arr: Array<string>,
  idx: number,
  item: string
): Array<string> => arr.map((_item, _idx) => (_idx === idx ? item : _item))

export const navigateToEdit = history => (url, search = '') =>
  url.includes('new')
    ? history.replace({ pathname: `${url}/edit/home`, search })
    : history.push({ pathname: `${url}/edit/home`, search })