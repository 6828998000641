/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Team4.theme'
import {
  styleProvider,
  getBackground,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 782px) {
        padding-top: 150px;
      }
    `}
`

const WMCustomContainer = styled(WMContainer)`
  > :first-child {
    padding-top: 0;
  }
`

const HeadingsContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -40px;
  padding-top: 50px;
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
  @media only screen and (max-width: 767px) {
    margin-bottom: -30px;
  }
`

const TeamItemWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: ${({ isEven }) => (isEven ? 'row-reverse' : 'row')};
  margin: ${({ isEven }) => (isEven ? '40px 0 30px 0' : '70px 0 0 0')};
  @media only screen and (max-width: 992px) {
    margin: ${({ isEven }) => (isEven ? '40px 0 30px 0' : '70px 0 0 0')};
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

const ItemImageWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  max-width: 350px;
  position: relative;
  @media only screen and (max-width: 1024px) {
    max-width: 320px;
  }
  @media only screen and (max-width: 457px) {
    max-width: calc(100% - 20px);
  }
`

const ControlWrapper = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 767px) {
    margin: 0;
  }
  & > .control-container {
    width: 100%;
    max-width: 750px;
    @media only screen and (max-width: 767px) {
      max-width: 450px;
      padding: 50px 0 30px 0;
    }
  }
  &:nth-child(odd) ${ItemImageWrapper} {
    @media only screen and (max-width: 1024px) {
      margin-left: 30px;
      margin-right: 0;
    }

    @media only screen and (max-width: 457px) {
      margin-left: 20px;
    }
  }
  &:nth-child(even) ${ItemImageWrapper} {
    @media only screen and (max-width: 1024px) {
      margin-right: 30px;
      margin-left: 0;
    }
    @media only screen and (max-width: 457px) {
      margin-right: 20px;
    }
  }
`

const ControlContainer = styled.div`
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ImgBgBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: ${getStyleForProp('cardRadius')};
  border: 8px solid ${getStyleForProp('iconColor')};
  background-color: transparent;
  box-sizing: border-box;
  ${props =>
    props.isEven
      ? css`
          bottom: -30px;
          right: -30px;
        `
      : css`
          top: -30px;
          left: -30px;
        `}
  @media only screen and (max-width: 457px) {
    ${props =>
      props.isEven
        ? css`
            bottom: -20px;
            right: -20px;
          `
        : css`
            top: -20px;
            left: -20px;
          `}
  }
`

const ItemTextWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  flex-grow: 1;
  margin: ${({ isEven }) => (isEven ? '0 4vw 0 0' : '0 0 0 4vw')};
  @media only screen and (max-width: 767px) {
    margin: ${({ isEven }) => (isEven ? '70px 0 0 0' : '40px 0 0 0')};
  }
  @media only screen and (max-width: 457px) {
    max-width: 100%;
  }
  .control-container {
    margin-bottom: 20px;
    width: 100%;
    p,
    h2,
    h3 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const SocialsWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(-12px);
  .link {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
  }
  .rf-add-btn {
    padding: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: initial;
    }
  }
`

const TeamName = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const TeamPosition = styled.h3`
  color: ${getStyleForProp('iconColor')};
  margin: 12px 0 20px 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const TeamParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 30px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('iconColor')
}))``

export default {
  StyledContainer,
  HeadingsContainer,
  WMCustomContainer,
  Title,
  Paragraph,
  ControlWrapper,
  TeamItemWrap,
  ItemImageWrapper,
  ItemTextWrapper,
  SocialsWrapper,
  TeamName,
  TeamPosition,
  TeamParagraph,
  ImgBgBorder,
  Img,
  Row,
  ControlContainer,
  Icon
}
