/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header45.styled'
import THEME from './Header45.theme'
import { validation } from './Header45.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import If from '@website/common/components/Conditional'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import Buttons from '@website/common/components/Buttons'
import SocialLinks from '@website/common/components/SocialLinks'
import { translate } from '@editor/common/utils/translations'

const Header45 = memo(
  ({
    data: {
      subTitle,
      title,
      paragraph,
      subTitleAlignment,
      titleAlignment,
      paragraphAlignment,
      blocks,
      buttons,
      logoSrc,
      logoColor,
      videoUrl,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      bgOverlayTransparency,
      backgroundImgDimensions,
      headerSocials
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const [navBarHeight, setNavBarHeight] = useState(0)
    const getIconsCount = blocks => {
      const iconsCount = blocks.reduce((acc, current) => {
        return acc + Number(!!current.iconName)
      }, 0)
      return iconsCount
    }
    const iconsCount = getIconsCount(blocks)

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
        isEditing={isEditing}
      >
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <S.WMCustomContainer topOffset={navBarHeight}>
          <S.HeaderContent>
            <S.TopContent>
              <S.TopContentWrapper>
                <EditableContent
                  text={subTitle}
                  alignment={subTitleAlignment}
                  maxCount={validation.subTitleMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  as={S.SubTitle}
                  onChange={Actions.changeSubTitle}
                  changeAlignment={Actions.changeSubTitleAlignment}
                />
                <EditableContent
                  isTitleOnly={!(paragraph || subTitle || buttons.length)}
                  text={title}
                  alignment={titleAlignment}
                  required={validation.title.required}
                  maxCount={validation.headingMaxChar}
                  isEditing={isEditing}
                  className="WM_GLOBAL_heading54"
                  data-text={title}
                  as={S.Title}
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  alignment={paragraphAlignment}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
                <Buttons data={buttons} />
                <SocialLinks
                  className="header-socials"
                  socials={headerSocials}
                  socialsMaxCount={validation.headerSocials.max}
                />
              </S.TopContentWrapper>
            </S.TopContent>
            <S.BottomContent>
              <S.BottomContentWrap
                isVideoExists={!!videoUrl}
                isEditing={isEditing}
              >
                <S.BlocksWrap isVideoExists={!!videoUrl} isEditing={isEditing}>
                  {blocks.map((block, idx) => {
                    const showRemove = blocks.length > validation.blocks.min

                    return (
                      <S.ControlContainer
                        key={idx}
                        isEditing={isEditing}
                        isTwoBlock={blocks.length === 2}
                      >
                        <ControlsWithModalOpener
                          actions={Actions.blockActions({
                            idx,
                            showRemove
                          })}
                          style={{ width: '100%' }}
                          alignment="right"
                        >
                          <S.ItemWrapper>
                            <S.BlocksTitileContainer
                              alignment={block.blockTitleAlignment}
                            >
                              <EditableContent
                                text={block.subTitle}
                                as={S.BlockSubTitle}
                                maxCount={validation.blockSubTitleMaxChar}
                                className="WM_GLOBAL_heading32"
                                onChange={(newSubTitle: string) =>
                                  Actions.changeBlockSubTitle(newSubTitle, idx)
                                }
                                isEditing={isEditing}
                                isAllIconsMissing={iconsCount === 0}
                              />
                              <EditableContent
                                text={block.title}
                                as={S.BlockTitle}
                                alignment={block.blockTitleAlignment}
                                required={validation.blockTitle.required}
                                maxCount={validation.blockTitleMaxChar}
                                className="WM_GLOBAL_paragraph18"
                                onChange={(newTitle: string) =>
                                  Actions.changeBlockTitle(newTitle, idx)
                                }
                                isEditing={isEditing}
                                isAllIconsMissing={iconsCount === 0}
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockTitleAlignment(
                                    alignment,
                                    idx
                                  )
                                }
                              />
                            </S.BlocksTitileContainer>
                            <EditableContent
                              text={block.paragraph}
                              as={S.BlockParagraph}
                              alignment={block.blockParagraphAlignment}
                              maxCount={validation.blockParagraphMaxChar}
                              className="WM_GLOBAL_paragraph14"
                              onChange={(newTitle: string) =>
                                Actions.changeBlockParagraph(newTitle, idx)
                              }
                              changeAlignment={(alignment: string) =>
                                Actions.changeBlockParagraphAlignment(
                                  alignment,
                                  idx
                                )
                              }
                            />
                          </S.ItemWrapper>
                        </ControlsWithModalOpener>
                      </S.ControlContainer>
                    )
                  })}
                  <S.AddBlockButtonWrapper>
                    <AddButton
                      onAdd={Actions.addBlock}
                      toShow={blocks.length < validation.blocks.max}
                      style={{
                        margin: '30px 0'
                      }}
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      {translate('add_block_label')}
                    </AddButton>
                  </S.AddBlockButtonWrapper>
                </S.BlocksWrap>
                <If
                  condition={videoUrl}
                  otherwise={() => (
                    <Show when={[isEditing]}>
                      <S.AddVideoButtonWrapper>
                        <AddButton
                          onAdd={() => Actions.addVideo()}
                          toShow={true}
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_video_label')}
                        </AddButton>
                      </S.AddVideoButtonWrapper>
                    </Show>
                  )}
                  then={() => (
                    <S.VideoControlWrapper>
                      <ControlsWithModalOpener
                        actions={Actions.videoActions(true, videoUrl)}
                        style={{ width: '100%' }}
                      >
                        <S.VideoContent className="video-content">
                          <Overlay />
                          <div className="video-wrap">
                            <LazyVideoPlayer
                              key={videoUrl}
                              style={{
                                top: 0,
                                left: 0,
                                position: 'absolute'
                              }}
                              controls
                              width="100%"
                              height="100%"
                              url={videoUrl}
                            />
                          </div>
                        </S.VideoContent>
                      </ControlsWithModalOpener>
                    </S.VideoControlWrapper>
                  )}
                />
              </S.BottomContentWrap>
            </S.BottomContent>
          </S.HeaderContent>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Header45
