/* @flow */
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'


export default dispatcher => {
  const addBlock = (idx: number) => {
    dispatcher({
      type: 'ADD_BLOCK',
      value: { idx }
    })
  }

  const removeBlock = (idx: number) => {
    dispatcher({
      type: 'REMOVE_BLOCK',
      value: { idx }
    })
  }

  const changeTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeParagraph = (newParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH',
      value: { newParagraph, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeLinkData = value => {
    dispatcher({
      type: 'CHANGE_LINK_DATA',
      value
    })
  }

  const imageActions = (idx: number, imgIdx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'portfolio9',
      showRemove: false,
      additionalArgs: { idx, imgIdx },
      imageUrl
    })

  return {
    changeLinkData,
    addBlock,
    imageActions,
    changeTitle,
    changeParagraph,
    removeBlock,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
