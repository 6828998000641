/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Post2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h2 {
      padding: 0;
      margin-bottom: 0;
    }
  }
`

const PostsWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: -22px 0;
  padding-top: 50px;
  @media only screen and (max-width: 1100px) {
    padding-top: 40px;
    flex-direction: column;
    align-items: center;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.2;
  padding-top: 20px;
`

const PostItemWrap = styled.article`
  width: 100%;
  max-width: 410px;
  margin: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('borderRadius')};
  border: ${getStyleForProp('border')};
  @media only screen and (max-width: 1440px) {
    margin: 14px;
  }
  @media only screen and (max-width: 1100px) {
    max-width: 688px;
    margin: 22px 0;
  }
  .lazy-figure {
    border-top-left-radius: ${getStyleForProp('borderRadius')};
    border-top-right-radius: ${getStyleForProp('borderRadius')};
  }
  & > div > .control-container {
      margin-bottom: 20px;
      p,
      h2 {
        margin-bottom: 0;
      }
    }
  }
  .link-wrapper {
    margin-left: auto;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const PostItemContent = styled.div`
  border-bottom-left-radius: ${getStyleForProp('borderRadius')};
  border-bottom-right-radius: ${getStyleForProp('borderRadius')};
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #fff;
  flex-grow: 1;
  .link-section {
    margin-top: auto;
    align-self: flex-start;
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .learn-more-link {
    color: ${({ theme }) => theme.palette.primaryDark};
    svg {
      fill: ${({ theme }) => theme.palette.primaryDark};
    }
  }
  @media only screen and (max-width: 1440px) and (min-width: 1100px) {
    padding: 30px 14px;
  }
  @media only screen and (max-width: 1100px) {
    .link-section {
      margin: auto;
      justify-content: center;
    }
  }
  @media only screen and (max-width: 400px) {
    padding: 30px 14px;
  }
`

const FigureContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin: 0 auto;
`

const PostItemImgWrap = styled.div`
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
`

const PostTitle = styled.h2`
  color: ${getStyleForProp('postTitle')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 22px;
  font-size: 20px;
`

const PostParagraph = styled.p`
  color: ${getStyleForProp('postParagraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  margin-bottom: 20px;
`

export default {
  Container,
  StyledContainer,
  WmCustomContainer,
  PostsWrap,
  PostItemImgWrap,
  Title,
  Paragraph,
  PostItemWrap,
  PostTitle,
  Img,
  PostItemContent,
  PostParagraph,
  FigureContainer
}
