import produce from 'immer'

import { META_SETTINGS_INITIAL_DATA } from '../consts'

export default (state = META_SETTINGS_INITIAL_DATA, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_META_LOADING_STATUS':
        draft.isLoading = action.value
        break
      case 'SET_META_SETTINGS':
        draft.data = action.value
        break
      default:
        return draft
    }
  })
