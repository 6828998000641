/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature35.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgbA
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
        `
      : css``};
  @media only screen and (max-width: 700px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding-top: 70px;
          `
        : css``};
  }
`

const stylesByAlignment = {
  default: '',
  left: css`
    text-align: start;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: end;
  `
}

const getStyleByAlignment = ({ alignment }) => stylesByAlignment[alignment]

const Circle = styled.div`
  width: 96px;
  height: 96px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: ${getStyleForProp('background')};
  border-radius: ${getStyleForProp('iconRadius')};
  pointer-events: none;

  & > div {
    height: 51px;
    width: 51px;
    & > svg {
      max-height: 51px;
      max-width: 51px;
      fill: ${getStyleForProp('icon')};
    }
  }
`

const AnimationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 200px;
  margin-bottom: 30px;
  position: relative;

  svg {
    path#blob {
      fill: ${props => hexToRgbA(getStyleForProp('blob')(props), 0.5)};
    }
    defs linearGradient stop:nth-child(1) {
      stop-color: ${getStyleForProp('blobGradientStart')};
    }
    defs linearGradient stop:nth-child(2) {
      stop-color: ${getStyleForProp('blobGradientEnd')};
    }
  }
`

const Icon = styled(LibIcon).attrs(({ theme, isArrow }) => ({
  color: isArrow
    ? getStyleForProp('anchor')({ theme })
    : theme.palette.accentWhite
}))`
  margin-left: ${({ isArrow }) => (isArrow ? '5px' : 0)};
`

const BlockItemWrap = styled.article`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 350px;
  margin-left: 38px;
  margin-right: 38px;
  margin-top: 80px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  @media only screen and (max-width: 1440px) {
    max-width: calc(33% - 75px);
  }
  @media only screen and (max-width: 1024px) {
    max-width: calc(50% - 25px);
    margin-left: 0px;
    margin-right: 0px;
    &:nth-child(2n-2) {
      margin-left: 50px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 100%;
    margin-top: 40px;
    margin-left: 0px !important;
  }
  > p:first-child,
  > div:first-child,
  > h6:first-child {
    margin-top: 0;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  &:nth-child(4n + 1) {
    .figures-wrapper {
      div:first-child {
        border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
        background-color: ${getStyleForProp('firstIcon')};
      }
      div:nth-child(2) {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '4px 4px 4px 100%' : '0 0 0 100%'};
        background-color: ${getStyleForProp('secondIcon')};
      }
      div:nth-child(3) {
        border-radius: 20px;
        background-color: ${getStyleForProp('thirdIcon')};
      }
      div:nth-child(4) {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '4px 4px 20px 20px' : '0 0 20px 20px'};
        background-color: ${getStyleForProp('fourthIcon')};
      }
    }
  }
  &:nth-child(4n + 2) {
    .figures-wrapper {
      div:first-child {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '20px 20px 4px 4px ' : '20px 20px 0 0 '};
        background-color: ${getStyleForProp('fourthIcon')};
      }
      div:nth-child(2) {
        border-radius: 20px;
        background-color: ${getStyleForProp('firstIcon')};
      }
      div:nth-child(3) {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '4px 100% 4px 4px' : '0 100% 0 0'};
        background-color: ${getStyleForProp('thirdIcon')};
      }
      div:nth-child(4) {
        border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
        background-color: ${getStyleForProp('secondIcon')};
      }
    }
  }
  &:nth-child(4n + 3) {
    .figures-wrapper {
      div:first-child {
        border-radius: 20px;
        background-color: ${getStyleForProp('firstIcon')};
      }
      div:nth-child(2) {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '4px 100% 4px 4px' : '0 100% 0 0'};
        background-color: ${getStyleForProp('thirdIcon')};
      }
      div:nth-child(3) {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '20px 4px 4px 20px' : '20px 0 0 20px'};
        background-color: ${getStyleForProp('secondIcon')};
      }
      div:nth-child(4) {
        border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
        background-color: ${getStyleForProp('fourthIcon')};
      }
    }
  }
  &:nth-child(4n + 4) {
    .figures-wrapper {
      div:first-child {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '20px 20px 4px 4px ' : '20px 20px 0 0 '};
        background-color: ${getStyleForProp('thirdIcon')};
      }
      div:nth-child(2) {
        border-radius: 20px;
        background-color: ${getStyleForProp('secondIcon')};
      }
      div:nth-child(3) {
        border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
        background-color: ${getStyleForProp('firstIcon')};
      }
      div:nth-child(4) {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '4px 4px 100% 4px' : '0 0 100% 0'};
        background-color: ${getStyleForProp('fourthIcon')};
      }
    }
  }
  .control-container > .control-container {
    width: 100%;
    margin-bottom: 20px;
    & + .control-container {
      margin-bottom: 0px;
    }
  }
`

const Title = styled.h2`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getStyleByAlignment}
  margin-bottom: 0;

  & + .link-section {
    margin-top: 0px;
  }
  & + p {
    margin-top: 20px;
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getStyleByAlignment}
`

const BlocksWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: -80px;
  margin-left: auto;
  margin-right: auto;
  max-width: ${({ isFourBlock }) => (isFourBlock ? '1030px' : '')};

  @media only screen and (max-width: 1100px) {
    align-items: flex-start;
    text-align: center;
  }
  @media only screen and (max-width: 768px) {
    margin-top: -50px;
  }
  @media only screen and (max-width: 550px) {
    margin-top: -40px;
  }
  .link-section {
    margin-top: 30px;
  }
  .learn-more-link {
    color: ${getStyleForProp('anchor')};
    svg {
      fill: ${getStyleForProp('anchor')};
    }
  }
`

export default {
  StyledContainer,
  BlockItemWrap,
  AnimationContainer,
  Circle,
  Title,
  Description,
  BlocksWrap,
  Icon
}
