/* @flow */
import styled from 'styled-components'
import THEME from './Feature12.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  display: flex;
  flex-direction: column;
  padding: 0;
  background-color: ${getStyleForProp('background')};
`

const Title = styled.h4`
  text-align: center;
  color: ${getStyleForProp('heading')};
`

const Paragraph = styled.p`
  color: #fff;
  text-align: center;
  color: ${getStyleForProp('paragraph')};
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

const Item = styled.div`
  background: ${props => {
    const bgColor = getStyleForProp('overlay')(props)
    const bgColorLight = getStyleForProp('overlayLight')(props)
    if (!props.backgroundImg) {
      const overLay = props.isEven
        ? 'overlayWithoutOpacity'
        : 'overlayLightWithoutOpacity'
      return `${getStyleForProp(overLay)(props)}`
    }
    if (props.isEven) {
      return `linear-gradient(${bgColor},${bgColor}),
      url(${props.backgroundImg});`
    } else {
      return `linear-gradient(${bgColorLight},${bgColorLight}),
      url(${props.backgroundImg});`
    }
  }}};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
`

const ItemParagraph = styled.p`
  color: #fff;
  margin: 0 0 15px 0;
`

const ItemTitle = styled.p`
  color: #fff;
  margin: 15px 0;
`

const LinkWrap = styled.div`
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-weight: 600 !important;
  }
`
export default {
  Container,
  Row,
  Item,
  Title,
  ItemParagraph,
  ItemTitle,
  LinkWrap,
  Paragraph
}
