/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_LINK_DATA':
        const { idx, link } = action.value
        draft.links[idx] = link
        break
      case 'CHANGE_MAP_DATA':
        draft.mapSrc = action.value.mapSrc
        draft.geoLocation = action.value.geoLocation
        break
      case 'ADD_LINK':
        draft.links.push(initialState.links[0])
        break
      case 'REMOVE_LINK':
        draft.links.splice(action.value, 1)
        break
      case 'CHANGE_TITLE_ALIGNMENT':
        draft.titleAlignment = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT':
        draft.paragraphAlignment = action.value
        break
    }
  })
}
