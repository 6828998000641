export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    blockTitle: p => p.primaryLight,
    background: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    blockTitle: p => p.primaryDark,
    background: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    blockTitle: p => p.primaryDark,
    background: p => p.tertiaryExtraLight
  }
}
