/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text1.styled'
import { validation } from './Text1.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'

const Text1 = memo(
  ({
    data: {
      quote,
      quoteAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WMCustomContainer>
          <S.QuoteWrap isEditing={isEditing}>
            <EditableContent
              text={quote}
              alignment={quoteAlignment}
              as={S.Quote}
              required={true}
              maxCount={validation.maxQuoteChar}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeQuote}
              changeAlignment={Actions.changeQuoteAlignment}
            />
          </S.QuoteWrap>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Text1
