/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_BLOCK_TITLE':
        const { title, blockIdx } = action.value
        draft.feature[blockIdx].title = title
        break
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { paragraph, blockIdx } = action.value
        draft.feature[blockIdx].paragraph = paragraph
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.feature[blockIdx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.feature[blockIdx].blockParagraphAlignment = alignment
        break
      }
      case 'ADD_LINK': {
        const { idx } = action.value
        draft.feature[idx].additionalUrl = initialState.feature[0].additionalUrl
        draft.feature[idx].additionalText =
          initialState.feature[0].additionalText
        break
      }
      case 'ADD_ICON':
        draft.feature[action.value].icon = 'edit'
        break
      case 'REMOVE_ICON':
        draft.feature[action.value.idx].icon = ''
        break
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.feature[idx].additionalUrl = ''
        draft.feature[idx].additionalText = ''
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.feature[linkIdx].additionalUrl = linkData.link
        draft.feature[linkIdx].additionalText = linkData.text
        break
      case 'CHANGE_ICON': {
        const { idx, iconName } = action.value
        draft.feature[idx].icon = iconName
        break
      }
    }
  })
}
