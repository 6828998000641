import React from 'react'
import * as Styled from './styled'
import ArrowLeft from '@editor/common/assets/svgr-icons/arrow_small_left.svg'
import ArrowRight from '@editor/common/assets/svgr-icons/arrow_small_right.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { secondary } from '@website/common/styles/colors'

export const PrevEditArrow = ({ onClick, style, className }) => (
  <Styled.ArrowWrapper style={style} className={className} onClick={onClick}>
    <TooltipWrapper text="Previous" position="top" color={secondary.ExtraDark}>
      <ArrowLeft />
    </TooltipWrapper>
  </Styled.ArrowWrapper>
)

export const NextEditArrow = ({ onClick, style, className }) => (
  <Styled.ArrowWrapper style={style} className={className} onClick={onClick}>
    <TooltipWrapper text="Next" position="top" color={secondary.ExtraDark}>
      <ArrowRight />
    </TooltipWrapper>
  </Styled.ArrowWrapper>
)
