/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header32.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0;
  & ${S.Container} {
    margin-bottom: 0;
    nav {
      margin-right: 30px;
    }
  }
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding: ${({ withoutContent }) => (withoutContent ? '240px 0' : '180px 0')};
  @media only screen and (max-width: 1024px) {
    padding: ${({ withoutContent }) =>
      withoutContent ? '120px 40px' : '180px 40px'};
  }
  @media only screen and (max-width: 457px) {
    padding: 90px 20px;
  }
`

const HeaderContentContainer = styled.div`
  width: 90%;
  max-width: 560px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: ${getStyleForProp('contentGradient')};
  padding: 50px 80px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: ${getStyleForProp('raisedShadow')};
  & > .control-container {
    width: 100%;
    margin-bottom: 20px;
    > h2 {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding: 50px 20px;
  }
  .buttons-block {
    position: absolute;
    left: 50%;
  }
  ${props =>
    props.onlyButton
      ? css`
          .buttons-block {
            top: 50%;
            transform: translate(-50%, -50%);
          }
        `
      : css`
          .buttons-block {
            bottom: -22px;
            transform: translateX(-50%);
          }
        `}
  > *:first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.h2`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  font-weight: 500;
  padding-top: 20px;
  margin: 0 0 10px 0;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContentContainer,
  Title,
  Paragraph
}
