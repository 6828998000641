/* @flow */
import styled, { css } from 'styled-components'
import THEME from './CTA12.theme'
import {
  styleProvider,
  getBackground,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1100px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  > p:first-child,
  > div:first-child {
    padding-top: 0;
  }
`

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
  text-align: center;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
  > p:first-child {
    padding-top: 0;
  }
  h2,
  p {
    width: 100%;
  }
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h2 {
      margin-top: 0;
      padding-top: 0;
    }
    .styled-control {
      z-index: 2;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin: 0 auto;
  ${getTextStyleByAlignment};
`

const BlockTitle = styled.p`
  color: ${getStyleForProp('blockTitle')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const InfoTitle = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    &.rf-reverse {
      flex-direction: column-reverse;
    }
  }
  &.first-block {
    margin-top: -10px;
    padding-top: 40px;
    @media only screen and (max-width: 1024px) {
      padding-top: 30px;
    }
  }
  &.second-block {
    margin-bottom: -10px;
    @media only screen and (max-width: 450px) {
      margin-bottom: -6px;
    }
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 992px) {
    max-width: 400px;
    margin-right: -20px;
    margin-left: -20px;
  }
`

const BlockItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  ${({ order, isEditing }) =>
    isEditing &&
    css`
      z-index: ${order};
    `}
  @media only screen and (max-width: 1200px) {
    width: 200px;
  }
  @media only screen and (max-width: 450px) {
    padding: 6px;
    width: 165px;
  }
  @media only screen and (max-width: 370px) {
    width: 155px;
  }
  @media only screen and (max-width: 350px) {
    width: 140px;
  }
`

const BlockContent = styled.div`
  width: 140px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1;
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
      padding-top: 0;
    }
  }
`

const IconWrapper = styled.div`
  user-select: none;
  text-align: center;
`

const Circle = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ImageWrapper = styled.div`
  width: 100%;
  font-size: 0;
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
  .lazy-figure {
    position: relative;
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ControlContainer = styled.div`
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${getStyleForProp('backgroundDark')};
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const InfoWrapper = styled.div`
  width: 500px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  @media only screen and (max-width: 1200px) {
    width: 400px;
  }
  @media only screen and (max-width: 450px) {
    width: 333px;
    padding: 6px;
  }
  @media only screen and (max-width: 370px) {
    width: 314px;
  }
  @media only screen and (max-width: 350px) {
    width: 282px;
  }
`

const InfoContent = styled.div`
  width: 100%;
  background: #fff;
  padding: 40px 50px 30px 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: ${getStyleForProp('cardRadius')};
  .link-section {
    margin-top: 30px;

    .link {
      color: ${getStyleForProp('anchor')};
    }
  }

  .arrow-right {
    svg {
      fill: ${getStyleForProp('anchor')};
    }
  }
  @media only screen and (max-width: 450px) {
    padding: 20px;
  }
  @media only screen and (max-width: 350px) {
    padding: 20px 10px;
  }
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: getStyleForProp('anchor')({ theme })
}))``

export default {
  StyledContainer,
  WmCustomContainer,
  Content,
  Title,
  Paragraph,
  BlockTitle,
  BlocksContainer,
  BlocksWrapper,
  BlockItem,
  IconWrapper,
  Circle,
  ImageWrapper,
  Img,
  BlockContent,
  InfoWrapper,
  InfoTitle,
  InfoContent,
  ControlContainer,
  Icon
}
