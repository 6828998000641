/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature32.styled'
import { validation } from './Feature32.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Image from '@website/common/components/Image'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { translate } from '@editor/common/utils/translations'

const Feature32 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      features,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.titleMaxChar}
          required={!paragraph}
          onChange={Actions.changeTitle}
          className="WM_GLOBAL_heading42"
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Description}
          maxCount={validation.paragraphMaxChar}
          required={!title}
          onChange={Actions.changeParagraph}
          className="WM_GLOBAL_paragraph18"
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.ItemsWrap areFourBlock={features.length === 4}>
          <Mapper
            data={features}
            render={(feature: string, idx: number) => {
              return (
                <S.Feature>
                  <ControlsWithModalOpener
                    key={idx}
                    actions={Actions.featureActions(
                      idx,
                      features.length,
                      feature.imgUrl
                    )}
                  >
                    <S.FeatureItemWrap>
                      <Image
                        as={S.Img}
                        sizes={feature.imgDimensions}
                        defaultImgSrc={feature.imgUrl}
                        alt="Feature image"
                      />
                      <EditableContent
                        as={S.FeatureTitle}
                        text={feature.title}
                        alignment={feature.featureTitleAlignment}
                        maxCount={validation.blockTitleMaxChar}
                        required={!feature.paragraph}
                        className="WM_GLOBAL_heading32"
                        onChange={(newBlockTitle: string) =>
                          Actions.changeFeatureTitle(newBlockTitle, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeFeatureTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        as={S.FeatureParagraph}
                        text={feature.paragraph}
                        alignment={feature.featureParagraphAlignment}
                        maxCount={validation.blockParagraphMaxChar}
                        required={!feature.title}
                        className="WM_GLOBAL_paragraph18"
                        onChange={(newBlockParagraph: string) =>
                          Actions.changeFeatureParagraph(newBlockParagraph, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeFeatureParagraphAlignment(
                            alignment,
                            idx
                          )
                        }
                      />
                      <AdditionalLink
                        idx={idx}
                        additionalLinkText={feature.additionalText}
                        additionalLinkUrl={feature.additionalUrl}
                      />
                    </S.FeatureItemWrap>
                  </ControlsWithModalOpener>
                </S.Feature>
              )
            }}
          />
        </S.ItemsWrap>
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addItem}
        toShow={features.length < validation.features.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Feature32
