/* @flow */
import styled from 'styled-components'
import THEME from './Feature12_2.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0;
`

const TextContent = styled.div`
  width: 100%;
  margin: 80px auto 50px auto;
  max-width: 800px;
  box-sizing: border-box;
  margin: ${({ isEditing }) =>
    isEditing ? '100px auto 50px auto' : '80px auto 40px auto'};
  @media only screen and (max-width: 1024px) {
    box-sizing: border-box;
    max-width: initial;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 767px) {
    margin: ${({ isEditing }) =>
      isEditing ? '100px auto 40px auto' : '40px auto'};
  }
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    width: 100%;
    margin-top: 30px;
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: #fff;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
const AddButtonWrapper = styled.div`
  display: flex;
  margin: 20px auto;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Item = styled.article`
  background: ${props => {
    const bgColor = getStyleForProp('overlay')(props)
    const bgColorLight = getStyleForProp('overlayLight')(props)
    if (!props.backgroundImg) {
      const overLay = props.isEven
        ? 'overlayWithoutOpacity'
        : 'overlayLightWithoutOpacity'
      return `${getStyleForProp(overLay)(props)}`
    }
    if (props.isEven) {
      return `linear-gradient(${bgColor},${bgColor}),
      url(${props.backgroundImg});`
    } else {
      return `linear-gradient(${bgColorLight},${bgColorLight}),
      url(${props.backgroundImg});`
    }
  }};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  box-sizing: border-box;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: relative;
    padding-top: 56.25%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  .item-icon {
    @media only screen and (max-width: 1100px) {
      font-size: 4rem;
    }
    @media only screen and (max-width: 992px) {
      font-size: 3rem;
    }
  }
`

const ItemWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  max-width: 640px;
  @media only screen and (max-width: 767px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    div {
      margin: 0 auto;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const ItemTitle = styled.p`
  color: #fff;
  width: 100%;
  ${getTextStyleByAlignment};
  margin: 15px 0;
`

const LinkWrap = styled.div`
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
  }
  .learn-more-link {
    font-weight: 500;
    align-items: center;
    .arrow-right {
      margin-left: 0;
      padding-left: 5px;
      min-width: 24px;
      transition: transform linear 0.15s;
    }
    &:hover {
      opacity: 0.7;
      .arrow-right {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
  svg {
    transform: translate3d(0, 0, 0);
  }
`

export default {
  Container,
  StyledContainer,
  LinkWrap,
  Row,
  ItemWrapper,
  Item,
  TextContent,
  Title,
  ItemTitle,
  AddButtonWrapper,
  RowWrapper,
  Paragraph
}
