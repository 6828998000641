// @flow
/* eslint-disable max-statements */
import { useLayoutEffect, useState, useRef, useCallback } from 'react'

type TOptionsType = {
  alignmentX: 'left' | 'right',
  alignmentY: 'top' | 'bottom',
  extraOffsetX: number,
  extraOffsetY: number
}

const DEFAULT_OPTIONS = {
  alignmentX: 'left',
  alignmentY: 'top',
  extraOffsetX: 0,
  extraOffsetY: 0
}

const DEFAULT_COORDS = {
  top: -1000,
  left: -1000
}

export default (
  container: HTMLElement,
  anchorEl: HTMLElement,
  targetRef: { current: HTMLElement },
  options: TOptionsType = {}
) => {
  const [coords, setCoords] = useState(DEFAULT_COORDS)
  const { alignmentX, alignmentY, extraOffsetX, extraOffsetY } = {
    ...DEFAULT_OPTIONS,
    ...options
  }
  const anchorLeftRef = useRef(
    anchorEl ? anchorEl.getBoundingClientRect().left : 0
  )

  const calculateCoords = useCallback(() => {
    const targetEl = targetRef.current
    if (!targetEl || !anchorEl) {
      return
    }
    const { width: targetWidth, height: targetHeight } =
      targetEl.getBoundingClientRect()
    const {
      top: anchorTop,
      left: anchorLeft,
      height: anchorHeight,
      width: anchorWidth
    } = anchorEl.getBoundingClientRect()

    const {
      top: containerTop,
      left: containerLeft,
      width: containerWidth
    } = container.getBoundingClientRect()
    const containerScrollTop = container.scrollTop
    const containerScrollHeight = container.scrollHeight

    const anchorTopBorderOffset = containerScrollTop + anchorTop - containerTop
    const anchorLeftBorderOffset = anchorLeft - containerLeft

    // top offset calculation
    const topOffsetForTopAlignment =
      anchorTopBorderOffset - targetHeight - extraOffsetY
    const topOffsetForBottomAlignment =
      anchorTopBorderOffset + anchorHeight + extraOffsetY
    const hasEnoughSpaceFromTop = topOffsetForTopAlignment > 0
    const hasEnoughSpaceFromBottom =
      containerScrollHeight - topOffsetForBottomAlignment - targetHeight > 0

    const top =
      alignmentY === 'top'
        ? hasEnoughSpaceFromTop
          ? topOffsetForTopAlignment
          : topOffsetForBottomAlignment
        : hasEnoughSpaceFromBottom
        ? topOffsetForBottomAlignment
        : topOffsetForTopAlignment
    // end of top offset calculation

    // left offset calculation
    const leftOffsetForLeftAlignment = anchorLeftBorderOffset + extraOffsetX
    const leftOffsetForRightAlignment =
      anchorLeftBorderOffset - targetWidth + anchorWidth - extraOffsetX
    const hasEnoughSpaceFromLeft = leftOffsetForRightAlignment > 0
    const hasEnoughSpaceFromRight =
      leftOffsetForLeftAlignment + targetWidth < containerWidth

    const noEnoughSpaceFromBothSides =
      !hasEnoughSpaceFromRight && !hasEnoughSpaceFromLeft
    const leftOffsetForCenterAlignment = (containerWidth - targetWidth) / 2

    const left = noEnoughSpaceFromBothSides
      ? leftOffsetForCenterAlignment
      : alignmentX === 'left'
      ? hasEnoughSpaceFromRight
        ? leftOffsetForLeftAlignment
        : leftOffsetForRightAlignment
      : hasEnoughSpaceFromLeft
      ? leftOffsetForRightAlignment
      : leftOffsetForLeftAlignment

    // end of left offset calculation

    setCoords({
      top,
      left
    })
  }, [anchorEl, targetRef])

  useLayoutEffect(calculateCoords, [calculateCoords])

  useLayoutEffect(() => {
    if (!anchorEl) {
      return
    }
    const newAnchorLeft = anchorEl.getBoundingClientRect().left
    if (newAnchorLeft !== anchorLeftRef.current) {
      calculateCoords()
      anchorLeftRef.current = newAnchorLeft
    }
  })

  return coords
}
