/* @flow */
import styled, { css } from 'styled-components'
import { ICON_SIZES } from './consts'

const getIconStyles = (size, color) => {
  if (!size) {
    return css``
  } else {
    const _size = !isNaN(size) ? `${size}px` : ICON_SIZES[size] || '1rem'
    return css`
      display: flex;
      width: ${_size};
      height: ${_size};
      & > svg {
        pointer-events: none;
        width: 100%;
        height: 100%;
        max-width: ${_size};
        max-height: ${_size};
        fill: ${color};
      }
    `
  }
}
export const SvgIcon = styled.div`
  ${({ size = 'medium', color, mobileSize }) =>
    css`
      ${getIconStyles(size, color)};
      @media only screen and (max-width: 766px) {
        ${getIconStyles(mobileSize, color)}
      }
    `}
`
