/* @flow */
import React, { useContext, memo } from 'react'
import S from './Pricing1.styled'
import { validation } from './Pricing1.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Pricing1 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      options,
      buttonUrl,
      paragraph,
      paragraphAlignment,
      planPrice,
      priceAlignment,
      buttonText,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.Content>
            <Show when={[title]}>
              <EditableContent
                as={S.Title}
                text={title}
                alignment={titleAlignment}
                className="WM_GLOBAL_heading42"
                maxCount={validation.headingMaxChar}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Description}
                alignment={paragraphAlignment}
                className="WM_GLOBAL_paragraph18"
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.Content>
        </Show>
        <S.PricingCardWrap>
          <S.PriceCard>
            <EditableContent
              as={S.Price}
              text={planPrice}
              alignment={priceAlignment}
              maxCount={validation.priceMaxChar}
              className="WM_GLOBAL_heading"
              onChange={(newPrice: string) => Actions.changePrice(newPrice)}
              changeAlignment={Actions.changePriceAlignment}
            />
            <S.CardBody className="WM_GLOBAL_secondary-font">
              <Mapper
                data={options}
                render={(option, optionIdx) => (
                  <ControlsWithModalOpener
                    key={optionIdx}
                    actions={Actions.optionAction(optionIdx)}
                  >
                    <Show when={[option]}>
                      <S.OptionItem>
                        <S.Icon size="large" name="check" />
                        <EditableContent
                          text={option}
                          as={S.Option}
                          maxCount={validation.optionMaxChar}
                          onChange={(newOption: string) =>
                            Actions.changeOption(newOption, optionIdx)
                          }
                          className="WM_GLOBAL_paragraph18"
                        />
                      </S.OptionItem>
                    </Show>
                  </ControlsWithModalOpener>
                )}
              />
              <AddButton
                onAdd={Actions.addOption}
                toShow={options.length < validation.options.max}
                style={{
                  margin: '10px 0'
                }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_item_label')}
              </AddButton>
            </S.CardBody>
            <S.CardFooter className="card-footer">
              <ControlsWithModalOpener
                style={{ width: '100%' }}
                actions={Actions.btnActions(
                  0,
                  {
                    text: buttonText,
                    link: buttonUrl
                  },
                  false
                )}
              >
                <LinkWrapper
                  text={buttonText}
                  url={buttonUrl}
                  linkProps={{
                    className: 'rf-comp-btn WM_GLOBAL_secondary-font'
                  }}
                />
              </ControlsWithModalOpener>
            </S.CardFooter>
          </S.PriceCard>
        </S.PricingCardWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Pricing1
