// @flow

import styled, { css } from 'styled-components'
import { OPENER_BUTTON_STYLES } from './consts'

export const getTextEllipsisStyles = () => css`
  max-width: 213px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const getStyleForProp =
  (propName: string) =>
  ({ type }) =>
    (OPENER_BUTTON_STYLES[type] || {})[propName]

export const OpenerButton = styled.div`
  box-shadow: rgba(0, 0, 0, 0.3) 0px 3px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`

export const OpenerIcon = styled.img`
  width: 25px;
  height: auto;
`

export const MessengerFlyout = styled.div`
  transition: transform 0.1s ease-out;
`

export const Messenger = styled.div`
  position: relative;
  width: min-content;
  ${({ isForPreview, isOpen, alignment }) => css`
    display: flex;
    flex-direction: column;
    .messenger-close-button {
      display: ${isForPreview ? 'none' : 'block'};
    }
    ${OpenerButton} {
      transition: transform 0.1s ease-out;
      transform: scale(${isOpen ? 1.2 : 1});
      background: ${getStyleForProp('background')};
      ${isForPreview ? 'margin-left: auto' : ''};
    }
    ${OpenerIcon} {
      height: ${getStyleForProp('size')}px;
    }
    ${MessengerFlyout} {
      ${isForPreview
        ? css`
            margin-bottom: 15px;
          `
        : css`
            position: fixed;
            z-index: 20;
            top: unset;
            bottom: 15px;
            ${alignment === 'left'
              ? css`
                  left: 80px;
                `
              : css`
                  right: 80px;
                `};
            transform: translateY(0) scale(${isOpen ? 1 : 0});
            @media only screen and (max-width: 460px) {
              ${alignment === 'left'
                ? css`
                    left: 3vw;
                  `
                : css`
                    right: 3vw;
                  `};
            }
          `}
    }
  `}
`
