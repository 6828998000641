export const createGridData = (columnsCount, elements) => {
  const gridData = []

  elements.forEach((element, idx) => {
    const columnIndex = idx % columnsCount

    if (!gridData[columnIndex]) {
      gridData[columnIndex] = []
    }

    gridData[columnIndex].push(element)
  })

  return gridData
}
