/* @flow */

export const validation = {
  titleMaxChar: 50,
  paragraphMaxChar: 300,
  maxCustomerNameChar: 30,
  maxCustomerPositionChar: 30,
  maxSlideContentChar: 350,
  testimonials: {
    min: 3,
    max: 10
  }
}
