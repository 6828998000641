/* @flow */
import React, { useContext, memo } from 'react'
import S from './Video1.styled'
import { validation } from './Video1.consts'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import DotsSVG from '@website/common/components/Dots'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import WMContainer from '@website/common/components/WMContainer'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'

const Video1 = memo(
  ({
    data: { title, paragraph, titleAlignment, paragraphAlignment, videoUrl }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)

    return (
      <S.StyledContainer isEditing={isEditing}>
        <WMContainer>
          <Show when={[title, paragraph]}>
            <S.TextContent>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  as={S.Title}
                  maxCount={validation.maxTitleChar}
                  className="WM_GLOBAL_heading"
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  alignment={paragraphAlignment}
                  as={S.Description}
                  maxCount={validation.maxParagraphChar}
                  className="WM_GLOBAL_paragraph"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
            </S.TextContent>
          </Show>
          <S.VideoContainer>
            <S.Dots className="right">
              <DotsSVG />
            </S.Dots>
            <S.Dots className="left">
              <DotsSVG />
            </S.Dots>
            <S.SquareBox className="right" />
            <S.SquareBox className="left" />
            <S.VideoControlWrapper>
              <ControlsWithModalOpener
                actions={Actions.videoActions(false, videoUrl)}
                style={{ width: '100%' }}
              >
                <S.VideoContent>
                  <S.VideoWrap>
                    <Overlay />
                    <LazyVideoPlayer
                      key={videoUrl}
                      style={{
                        top: 0,
                        left: 0,
                        position: 'absolute'
                      }}
                      controls
                      width="100%"
                      height="100%"
                      url={videoUrl}
                    />
                  </S.VideoWrap>
                </S.VideoContent>
              </ControlsWithModalOpener>
            </S.VideoControlWrapper>
          </S.VideoContainer>
        </WMContainer>
      </S.StyledContainer>
    )
  }
)

export default Video1
