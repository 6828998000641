/* @flow */
import styled from 'styled-components'
import THEME from './Header40.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0;
  position: relative;
  @media only screen and (min-width: 1025px) {
    background-attachment: fixed !important;
  }
  @media only screen and (max-width: 768px) {
    padding-top: ${({ topOffset }) => `${topOffset - 80}px`};
  }
  nav {
    margin-right: 30px;
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset - 30}px`};
  }
`

const WmCustomContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    min-height: initial;
    padding: 80px 40px;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 20px;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const HeaderContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
  padding: 250px 0;
  @media only screen and (max-width: 1024px) {
    padding: 0;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 1000px;

  .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-bottom: ${({ onlyTitle, isEditing }) =>
    onlyTitle || isEditing ? '0' : '20px'};
`

export default {
  StyledContainer,
  Container,
  WmCustomContainer,
  HeaderContentContainer,
  HeaderContent,
  Title
}
