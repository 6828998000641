/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header55.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  MOBILE_SIZE,
  TABLET_SIZE,
  LARGE_SIZE
} from './../../../../src/shared/globals'

export const CUSTOM_SIZE = 650

export const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  background-attachment: fixed !important;
  background-position: center;
  flex-direction: column;
  padding: 0;
  position: relative;
  overflow: hidden;
  ${getBackgroundStyles(THEME)};
  @media only screen and (max-width: ${CUSTOM_SIZE}px) {
    padding: 0;
  }
`

export const WaveWrapper = styled.div`
  left: 0;
  position: absolute;
  right: 0;
  top: -185px;
  z-index: 1;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    top: -205px;
  }
  @media only screen and (max-width: ${TABLET_SIZE}px) {
    top: -195px;
  }
  @media only screen and (max-width: 660px) {
    top: -175px;
  }
  svg {
    width: 100%;
    filter: drop-shadow(1px 1px 2px ${getStyleForProp('border')});
    path {
      fill: ${getStyleForProp('wave')};
    }
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  ${({ isEditing }) => isEditing && 'padding-top: 20px'};

  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    height: 0;
    padding-top: 122.787%;
  }

  .control-container {
    width: 100%;
  }

  img {
    width: 100%;
  }
`

export const WMCustomContainer = styled(WMContainer)`
  position: relative;
  ${({ isEditing }) =>
    isEditing ? 'padding: 30px 0 80px' : 'padding: 140px 0 80px'};
  @media only screen and (max-width: ${TABLET_SIZE}px) {
    padding-top: 110px;
  }
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    padding-bottom: 60px;
  }
`

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  width: 100%;
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
          flex-direction: row;
        `
      : css`
          flex-direction: row-reverse;
        `}
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
    width: 100%;
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            flex-direction: column;
          `
        : css`
            flex-direction: column-reverse;
          `}
  }
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    padding: 0 20px;
  }
`

export const ContentContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 95%;
  max-width: 1400px;
  position: relative;
  margin: 0 auto;
  @media only screen and (max-width: 1440px) {
    width: 90%;
    max-width: 1200px;
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 40px;
    width: 100%;
  }
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    padding: 0 20px;
  }
  .add-button-wrap {
    display: flex;
  }
  .link-section {
    margin-top: 20px;

    .link {
      color: ${getStyleForProp('link')};
    }
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  max-width: 1300px;
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
  ${({ isTextGradient }) =>
    isTextGradient
      ? css`
          background: linear-gradient(
            90deg,
            ${getStyleForProp('gradientColorFrom')} 0,
            ${getStyleForProp('gradientColorTo')} 100%
          );
          background-clip: text;
          background-size: 100% auto;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:before,
          &:after {
            -webkit-background-clip: initial;
            -webkit-text-fill-color: initial;
            background: initial;
          }
          & .link-wrapper {
            position: initial;
          }
        `
      : ''}
  & + h2 {
    margin-top: 5px;
  }
`

export const SubTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  max-width: 1300px;
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
  ${({ isTextGradient }) =>
    isTextGradient
      ? css`
          background: linear-gradient(
            90deg,
            ${getStyleForProp('gradientColorFrom')} 0,
            ${getStyleForProp('gradientColorTo')} 100%
          );
          background-clip: text;
          background-size: 100% auto;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:before,
          &:after {
            -webkit-background-clip: initial;
            -webkit-text-fill-color: initial;
            background: initial;
          }
          & .link-wrapper {
            position: initial;
          }
        `
      : ''}
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.5em;
  max-width: 1300px;
  padding-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const ControlContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  outline: none;
  ${({ isEditing }) =>
    !isEditing
      ? css`
          @media only screen and (min-width: ${CUSTOM_SIZE + 1}px) {
            min-width: 170px;
            width: 100%;
          }
        `
      : css`
          min-width: 170px;
          width: 100%;
          @media only screen and (max-width: ${LARGE_SIZE}px) and (min-width: ${CUSTOM_SIZE +
            1}px) {
            width: 170px;
            min-width: 170px;
          }
        `};

  & > .control-container {
    width: 100%;
  }
  @media only screen and (min-width: ${CUSTOM_SIZE + 1}px) {
    margin-top: 80px;
  }
  @media only screen and (max-width: ${CUSTOM_SIZE}px) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`
export const ItemWrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex !important;
  flex-direction: column;
  position: relative;
  width: 100%;
  ${({ isEditing }) => (isEditing ? 'padding: 10px' : '')};

  & > .control-container {
    margin-top: 20px;
    width: 100%;
    & > p {
      margin-top: ${({ isEditing }) => (isEditing ? '0' : '10px')};
    }
  }

  & > :first-child {
    margin-top: 0;
  }
`

export const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  min-width: 100%;
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  margin-top: ${({ isEditing }) => (isEditing ? '20px' : '10px')};
  width: 100%;
  ${getTextStyleByAlignment};
`

export const BlocksWrap = styled.div`
  column-gap: 20px;
  display: flex;
  justify-content: space-between;
  max-width: 810px;
  width: 100%;

  @media only screen and (min-width: ${LARGE_SIZE + 1}px) {
    max-width: 800px;
    margin-right: -20px;
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: ${CUSTOM_SIZE}px) {
    align-items: center;
    flex-direction: column;
    margin-top: 40px;
  }
`

export const LeftContent = styled.div`
  width: 100%;
  max-width: 755px;
  @media only screen and (min-width: ${LARGE_SIZE + 1}px) {
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            margin-right: 80px;
          `
        : css`
            margin-left: 80px;
          `}
  }
  & > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    min-width: 100%;
    margin-bottom: 20px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    max-width: 100%;
    width: 100%;
  }
  .buttons-block {
    padding-top: 30px;
    @media only screen and (max-width: ${MOBILE_SIZE}px) {
      .add-button-wrap {
        width: max-content;
      }
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css``
      : css`
          .buttons-block {
            margin-left: -15px;
          }
        `};
`

export const RightContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 610px;
  width: 100%;
  @media only screen and (max-width: 1444px) {
    width: 66%;
  }
  @media only screen and (max-width: 1366px) {
    width: 68%;
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    max-width: 100%;
    width: 100%;
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            padding-top: 50px;
          `
        : css`
            padding-top: 0px;
            margin-bottom: 50px;
          `}
  }
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            padding-top: 40px;
          `
        : css`
            padding-top: 0px;
            margin-bottom: 40px;
          `}
  }
  .add-button-wrap {
    margin-top: 20px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  min-width: 100%;
  & > .control-container {
    min-width: 100%;
  }
`

export const HiddenTitle = styled.div`
  min-width: 100%;
  margin-bottom: 0 !important;
  visibility: hidden;
`

export const BottomContentWrapper = styled.div`
  background-color: ${getStyleForProp('wave')};
  position: relative;
  padding-bottom: 80px;
  width: 100%;
  z-index: 2;
  @media only screen and (max-width: ${CUSTOM_SIZE}px) {
    padding-bottom: 60px;
  }
`

export const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
`
