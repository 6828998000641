/* @flow */
import styled, { css } from 'styled-components'
import Icon from '@website/common/components/Icon'
import THEME from './Email5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (min-width: 769px) {
        padding-top: 140px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const ElementsWrap = styled.div`
  width: 71px;
  height: 71px;
  display: flex;
  justify-content: space-between;
  align-items: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const Element = styled.div`
  width: 35px;
  height: 35px;
  border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '2px' : '')};
  &:first-child {
    border-bottom-left-radius: 100%;
    background-color: ${getStyleForProp('firstElement')};
  }
  &:nth-child(2) {
    border-top-right-radius: 100%;
    background-color: ${getStyleForProp('secondElement')};
  }
  &:nth-child(3) {
    border-top-left-radius: 50%;
    border-bottom-left-radius: 50%;
    background-color: ${getStyleForProp('thirdElement')};
  }
  &:nth-child(4) {
    border-radius: 50%;
    background-color: ${getStyleForProp('fourthElement')};
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 30px;
  ${getTextStyleByAlignment};
`

const InputWrapper = styled.div`
  position: relative;
  .required-icon {
    position: absolute;
    right: 10px;
    bottom: 5px;
    padding: 10px;
    color: ${({ theme }) => theme.palette.tertiaryDark};
    @media only screen and (max-width: 767px) {
      right: 4px;
    }
  }
`

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding-right: 40px;
  padding-left: 30px;
  height: 50px;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('buttonRadius')};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
`

const Card = styled.div`
  width: 100%;
  max-width: 598px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 40px 20px;
  background: ${({ theme }) =>
    `rgba(${hexToRgb(getStyleForProp('card')({ theme }))}, 0.8)`};
  border: 1px solid ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('cardRadius')};
`

const FormContainer = styled.div`
  width: 100%;
  max-width: 450px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  position: relative;
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
    }
  }
  .button-container {
    margin-top: 10px;
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  > div {
    svg {
      max-width: 12px;
      max-height: 12px;
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
`

const RequiredIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.palette.tertiaryNormal
}))`
  line-height: 0;
`

const ErrorMessage = styled.div`
  position: absolute;
  top: 52px;
  color: #eb0c00;
  font-size: 14px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 5px;
  }
`

const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

const SuccessMessage = styled.p`
  color: #048900;
  font-size: '18px';
  position: absolute;
  bottom: -25px;
  display: flex;
  align-items: center;
  text-align: start;
  white-space: nowrap;
`

const SuccessIcon = styled.img`
  width: 19px;
  margin-right: 10px;
`

export default {
  WmCustomContainer,
  Title,
  Input,
  StyledContainer,
  Paragraph,
  InputContainer,
  Card,
  ElementsWrap,
  Element,
  FormContainer,
  InputWrapper,
  RequiredIcon,
  ErrorMessage,
  ErrorIcon,
  SuccessMessage,
  SuccessIcon
}
