/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Morph = () => {
  return (

    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="150.989" height="227.437" viewBox="0 0 150.989 227.437">
      <defs>
        <clipPath id="clip-path">
          <rect width="150.989" height="227.437" fill="none" />
        </clipPath>
      </defs>
      <g id="Repeat_Grid_16" data-name="Repeat Grid 16" opacity="0.2" clip-path="url(#clip-path)">
        <g transform="translate(-12378 -10750)">
          <circle id="Ellipse_50" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10750)">
          <circle id="Ellipse_50-2" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10750)">
          <circle id="Ellipse_50-3" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10750)">
          <circle id="Ellipse_50-4" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10750)">
          <circle id="Ellipse_50-5" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10750)">
          <circle id="Ellipse_50-6" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10750)">
          <circle id="Ellipse_50-7" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12378 -10723)">
          <circle id="Ellipse_50-8" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10723)">
          <circle id="Ellipse_50-9" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10723)">
          <circle id="Ellipse_50-10" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10723)">
          <circle id="Ellipse_50-11" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10723)">
          <circle id="Ellipse_50-12" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10723)">
          <circle id="Ellipse_50-13" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10723)">
          <circle id="Ellipse_50-14" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12378 -10696)">
          <circle id="Ellipse_50-15" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10696)">
          <circle id="Ellipse_50-16" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10696)">
          <circle id="Ellipse_50-17" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10696)">
          <circle id="Ellipse_50-18" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10696)">
          <circle id="Ellipse_50-19" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10696)">
          <circle id="Ellipse_50-20" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10696)">
          <circle id="Ellipse_50-21" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12378 -10669)">
          <circle id="Ellipse_50-22" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10669)">
          <circle id="Ellipse_50-23" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10669)">
          <circle id="Ellipse_50-24" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10669)">
          <circle id="Ellipse_50-25" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10669)">
          <circle id="Ellipse_50-26" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10669)">
          <circle id="Ellipse_50-27" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10669)">
          <circle id="Ellipse_50-28" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12378 -10642)">
          <circle id="Ellipse_50-29" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10642)">
          <circle id="Ellipse_50-30" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10642)">
          <circle id="Ellipse_50-31" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10642)">
          <circle id="Ellipse_50-32" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10642)">
          <circle id="Ellipse_50-33" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10642)">
          <circle id="Ellipse_50-34" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10642)">
          <circle id="Ellipse_50-35" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12378 -10615)">
          <circle id="Ellipse_50-36" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10615)">
          <circle id="Ellipse_50-37" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10615)">
          <circle id="Ellipse_50-38" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10615)">
          <circle id="Ellipse_50-39" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10615)">
          <circle id="Ellipse_50-40" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10615)">
          <circle id="Ellipse_50-41" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10615)">
          <circle id="Ellipse_50-42" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12378 -10588)">
          <circle id="Ellipse_50-43" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10588)">
          <circle id="Ellipse_50-44" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10588)">
          <circle id="Ellipse_50-45" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10588)">
          <circle id="Ellipse_50-46" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10588)">
          <circle id="Ellipse_50-47" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10588)">
          <circle id="Ellipse_50-48" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10588)">
          <circle id="Ellipse_50-49" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12378 -10561)">
          <circle id="Ellipse_50-50" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10561)">
          <circle id="Ellipse_50-51" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10561)">
          <circle id="Ellipse_50-52" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10561)">
          <circle id="Ellipse_50-53" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10561)">
          <circle id="Ellipse_50-54" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10561)">
          <circle id="Ellipse_50-55" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10561)">
          <circle id="Ellipse_50-56" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12378 -10534)">
          <circle id="Ellipse_50-57" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12355 -10534)">
          <circle id="Ellipse_50-58" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12332 -10534)">
          <circle id="Ellipse_50-59" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12309 -10534)">
          <circle id="Ellipse_50-60" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12286 -10534)">
          <circle id="Ellipse_50-61" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12263 -10534)">
          <circle id="Ellipse_50-62" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
        <g transform="translate(-12240 -10534)">
          <circle id="Ellipse_50-63" data-name="Ellipse 50" cx="3.5" cy="3.5" r="3.5" transform="translate(12378 10750)" />
        </g>
      </g>
    </svg>
  )
}

export default withTheme(Morph)
