/* @flow */

module.exports = {
  id: 'Clients4',
  thumbnailRatio: 0.34,
  isUnique: false,
  label: 'Clients4',
  category: 'client',
  colorMode: 'DARK',
  customPalette: {
    primaryDark: '#2A932A',
    primaryLight: '#B1E200',
    secondaryDark: '#5BB25B',
    secondaryLight: '#D0ED65',
    tertiaryDark: '#161A1D',
    tertiaryNormal: '#8A8C8E',
    tertiarySemiLight: '#C4C5C6',
    tertiaryLight: '#B1E200',
    tertiaryExtraLight: '#FFFFFF',
    accentWhite: '#FFFFFF'
  },
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/clients4-1.1.0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/clients4m-1.1.0.jpg',
  metaData: {
    title: {
      _type: 'title',
      _min: 0,
      _max: 100,
      _value: 'Our valuable partners'
    },
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 300,
      _value:
        'The trustworthy relationship built over the years with our partners makes us unstoppable. Meet the most valuable partners below.'
    },
    titleAlignment: 'center',
    paragraphAlignment: 'center',
    backgroundImgUrl: {
      _type: 'backgroundImage',
      _dimensionsKey: 'backgroundImgDimensions',
      _optimizationSizes: [
        { _width: 800 },
        { _width: 1200 },
        { _width: 1600 },
        { _width: 2200 }
      ],
      _min: 0,
      _max: 2048,
      _value: ''
    },
    backgroundImgDimensions: {},
    bgOverlayTransparency: 0,
    slides: {
      _type: 'list',
      _min: 1,
      _max: 10,
      _value: [
        {
          imageUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 300 },
              { _width: 300 },
              { _width: 300 },
              { _width: 300 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png'
          },
          clientUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: ''
          }
        },
        {
          imageUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 300 },
              { _width: 300 },
              { _width: 300 },
              { _width: 300 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png'
          },
          clientUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: ''
          }
        },
        {
          imageUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 300 },
              { _width: 300 },
              { _width: 300 },
              { _width: 300 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png'
          },
          clientUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: ''
          }
        },
        {
          imageUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 300 },
              { _width: 300 },
              { _width: 300 },
              { _width: 300 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png'
          },
          clientUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: ''
          }
        },
        {
          imageUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 300 },
              { _width: 300 },
              { _width: 300 },
              { _width: 300 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png'
          },
          clientUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: ''
          }
        },
        {
          imageUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 300 },
              { _width: 300 },
              { _width: 300 },
              { _width: 300 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png'
          },
          clientUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: ''
          }
        }
      ]
    }
  },
  defaultProps: {
    title: 'Our valuable partners',
    paragraph:
      'The trustworthy relationship built over the years with our partners makes us unstoppable. Meet the most valuable partners below.',
    titleAlignment: 'center',
    paragraphAlignment: 'center',
    backgroundImgUrl: '',
    backgroundImgDimensions: {},
    bgOverlayTransparency: 0,
    slides: [
      {
        imageUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-1.0.0.png'
        },
        clientUrl: ''
      },
      {
        imageUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-2.0.0.png'
        },
        clientUrl: ''
      },
      {
        imageUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-3.0.0.png'
        },
        clientUrl: ''
      },
      {
        imageUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-4.0.0.png'
        },
        clientUrl: ''
      },
      {
        imageUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-5.0.0.png'
        },
        clientUrl: ''
      },
      {
        imageUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/clients4-6.0.0.png'
        },
        clientUrl: ''
      }
    ]
  }
}
