export default {
  DARK: {
    iconColor: p => p.primaryLight,
    blockTitle: p => p.accentWhite,
    background: p => `${p.tertiaryDark}`,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    iconColor: p => p.primaryDark,
    blockTitle: p => p.primaryDark,
    background: p => `${p.tertiaryLight}`,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    iconColor: p => p.primaryDark,
    blockTitle: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
