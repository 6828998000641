import styled, { css } from 'styled-components'

const BUTTON_STYLES_BY_TYPE = {
  primary: css`
    background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
      0% no-repeat padding-box;
    &:hover {
      background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 9px #0052e080;
      transition: all 0.2s ease-out;
    }
    color: #ffffff;
  `,
  secondary: css`
    color: #3271e6;
    background: #ffffff;
    &:hover {
      background: #eef5ff;
    }
  `,
  tertiary: css`
    color: #3271e6;
    background: #eef5ff;
    &:hover {
      color: #ffffff;
      background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%);
    }
  `,
  purple: css`
    color: #ffffff;
    box-shadow: 0 6px 12px #766de880;
    background: transparent linear-gradient(180deg, #8c83f7 0%, #4136c1 100%) 0
      0 no-repeat padding-box;
    &:hover {
      background: transparent linear-gradient(180deg, #b5afff 0%, #9b94fc 100%)
        0 0 no-repeat padding-box;
    }
  `
}

export const getDisabledStyles = ({ disabled }) =>
  disabled
    ? css`
        opacity: 0.5;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
      `
    : ``

export const getButtonStylesByType = ({ type }) => BUTTON_STYLES_BY_TYPE[type]

export const Button = styled.button`
  ${getDisabledStyles};
  ${getButtonStylesByType};
  padding: 11px 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
`
