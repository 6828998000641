import styled from 'styled-components'

export const KD = styled.div`
  display: flex;
  align-items: center;

  .na {
    color: #545f7e;
    font-size: 14px;
  }
`

export const Circle = styled.div`
  border-radius: 50%;
  height: 12px;
  width: 12px;
  background-color: ${({ color }) => color};
  margin-left: 10px;
`
