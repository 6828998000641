/* @flow */
import React, { useContext, memo } from 'react'
import S from './Music3.styled'
import { validation } from './Music3.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'

const isIframe = url => {
  return url.startsWith('<iframe')
}

const Music3 = memo(props => {
  const {
    data: { title, paragraph, musicUrl, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer>
      <Show when={[title, paragraph]}>
        <S.TextContent isEditing={isEditing}>
          <Show when={[title]}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleChar}
              className="WM_GLOBAL_heading54"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
          </Show>
          <Show when={[paragraph]}>
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.maxParagraphChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </Show>
        </S.TextContent>
      </Show>
      <S.MusicWrapper>
        <ControlsWithModalOpener
          actions={Actions.musicActions(musicUrl)}
          style={{ width: '100%' }}
        >
          <S.MusicContent>
            {isIframe(musicUrl) ? (
              <S.IframeWrapper>
                <Overlay />
                <div dangerouslySetInnerHTML={{ __html: musicUrl }} />
              </S.IframeWrapper>
            ) : (
              <S.IframeWrapper>
                <Overlay />
                <iframe
                  scrolling="no"
                  frameBorder="no"
                  src={`https://w.soundcloud.com/player/?url=${musicUrl}`}
                  title="Embedded music"
                />
              </S.IframeWrapper>
            )}
          </S.MusicContent>
        </ControlsWithModalOpener>
      </S.MusicWrapper>
    </S.StyledContainer>
  )
})

export default Music3
