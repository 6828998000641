/* @flow */
import React, { useCallback, useEffect, useState, useContext } from 'react'

import {
  getIsBackwardDirection,
  getActiveParentNodes,
  getActiveChildNodes
} from './utils'
import ReplacedLinks from './ReplacedLinks'
import EditableContent from './EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ActionGetter from './Actions'
import { ControlModalsContext, EditingContext } from '@contexts'
import * as Styled from './styled'

const WithControls = props => {
  const [editableEl, setEditableEl] = useState(null)
  const {
    alignment,
    isTextGradient,
    changeAlignment,
    changeTextGradientStatus,
    areControlsHidden,
    isLinkControlHidden,
    isUnderlineHidden,
    isTextFormatingHidden,
    isGradientControlHidden,
    isTypingDisabled = false,
    onChange,
    customActions,
    controlsAlignment,
    onDoubleClick,
    maxCount
  } = props

  const [activeNodesNames, setActiveNodesNames] = useState([])
  const { isEditing } = useContext(EditingContext)
  const { modalStates: { EXTENDED_URL_MODAL } = {} } =
    useContext(ControlModalsContext)

  const updateActiveNodeNames = useCallback(() => {
    const selection = document.getSelection()
    const isCaret = selection?.type === 'Caret'

    const element = getIsBackwardDirection()
      ? selection.extentNode
      : selection.anchorNode

    const parentNodesNames = isCaret
      ? getActiveChildNodes(editableEl)
      : getActiveParentNodes(element)

    setActiveNodesNames(parentNodesNames)
  }, [editableEl])

  const onMouseEnter = useCallback(() => {
    const selection = document.getSelection()
    const element = getIsBackwardDirection()
      ? selection.extentNode
      : selection.anchorNode
    const isRangeSelection = selection?.type === 'Range'
    const isSelectedAllChildren = editableEl === selection.anchorNode
    const activeNodesNames =
      isRangeSelection && editableEl.contains(element) && !isSelectedAllChildren
        ? getActiveParentNodes(element)
        : getActiveChildNodes(editableEl)

    setActiveNodesNames(activeNodesNames)
  }, [editableEl])

  const preventShortcuts = useCallback(e => {
    if (e.metaKey || e.ctrlKey) {
      if (e.key === 'b' || e.key === 'i') {
        e.preventDefault()
      }
    }
  }, [])

  useEffect(() => {
    if (editableEl && isEditing) {
      editableEl.addEventListener('mouseup', updateActiveNodeNames)
      editableEl.addEventListener('mouseenter', onMouseEnter)
      editableEl.addEventListener('touchend', updateActiveNodeNames)
      editableEl.addEventListener('keydown', preventShortcuts)
    }

    return () => {
      if (editableEl && isEditing) {
        editableEl.removeEventListener('mouseup', updateActiveNodeNames)
        editableEl.removeEventListener('mouseenter', onMouseEnter)
        editableEl.removeEventListener('touchend', updateActiveNodeNames)
        editableEl.removeEventListener('keydown', preventShortcuts)
      }
    }
  }, [editableEl, isEditing])

  const _onChange = EXTENDED_URL_MODAL?.isOpen ? () => void 0 : onChange
  const additionalHoverElement =
    typeof document !== 'undefined' &&
    document.querySelector('.text-wizard-ai-control-flyout')

  return areControlsHidden ? (
    <EditableContent
      editableEl={editableEl}
      toPropogate={false}
      setRef={setEditableEl}
      {...props}
    />
  ) : (
    <>
      <Styled.GlobalStyles />
      <ControlsWithModalOpener
        actions={ActionGetter({
          editableEl,
          alignment,
          isTextGradient,
          isTypingDisabled,
          changeAlignment,
          changeTextGradientStatus,
          isLinkControlHidden,
          isUnderlineHidden,
          isTextFormatingHidden,
          isGradientControlHidden,
          activeNodesNames,
          updateActiveNodeNames,
          customActions,
          onChange,
          maxCount
        })}
        alignment={controlsAlignment}
        className="editable-control-container"
        additionalHoverElement={additionalHoverElement}
      >
        <EditableContent
          editableEl={editableEl}
          toPropogate={false}
          setRef={setEditableEl}
          {...props}
          onChange={_onChange}
        />
        {isEditing && isTypingDisabled && onDoubleClick ? (
          <Styled.DoubleClickLayer onDoubleClick={onDoubleClick} />
        ) : null}
        {!isEditing && <ReplacedLinks containerEl={editableEl} />}
      </ControlsWithModalOpener>
    </>
  )
}

export default WithControls
