import FeaturedSnippetIcon from '@editor/common/assets/svgr-icons/semrush/featured_snippet_icon.svg'
import FaqIcon from '@editor/common/assets/svgr-icons/semrush/faq_icon.svg'
import HotelsIcon from '@editor/common/assets/svgr-icons/semrush/hotels_icon.svg'
import ShoppingCartIcon from '@editor/common/assets/svgr-icons/semrush/shopping_cart_icon.svg'
import LocalPackIcon from '@editor/common/assets/svgr-icons/semrush/local_pack_icon.svg'
import ImagePackIcon from '@editor/common/assets/svgr-icons/semrush/image_pack_icon.svg'
import FlightsIcon from '@editor/common/assets/svgr-icons/semrush/flights_icon.svg'
import SiteLinksIcon from '@editor/common/assets/svgr-icons/semrush/site_links_icon.svg'
import VideoCarouselIcon from '@editor/common/assets/svgr-icons/semrush/video_carousel_icon.svg'
import CarouselIcon from '@editor/common/assets/svgr-icons/semrush/carousel_icon.svg'
import PeopleAlsoAskIcon from '@editor/common/assets/svgr-icons/semrush/people_ask_icon.svg'
import ReviewsIcon from '@editor/common/assets/svgr-icons/semrush/reviews_icon.svg'
import TwitterIcon from '@editor/common/assets/svgr-icons/semrush/twitter_icon.svg'
import TopStoriesIcon from '@editor/common/assets/svgr-icons/semrush/top_stories.svg'
import ImageIcon from '@editor/common/assets/svgr-icons/semrush/image_icon.svg'
import JobsIcon from '@editor/common/assets/svgr-icons/semrush/jobs_icon.svg'
import KnowledgePanelIcon from '@editor/common/assets/svgr-icons/semrush/knowledge_panel_icon.svg'
import VideoIcon from '@editor/common/assets/svgr-icons/semrush/video_icon.svg'
import FeaturedVideoIcon from '@editor/common/assets/svgr-icons/semrush/featured_video_icon.svg'
import AdwordsTopIcon from '@editor/common/assets/svgr-icons/semrush/adwords_top_icon.svg'
import AdwordsBottomIcon from '@editor/common/assets/svgr-icons/semrush/adwords_bottom_icon.svg'
import InstantAnswerIcon from '@editor/common/assets/svgr-icons/semrush/instant_answer_icon.svg'

export const VISIBLE_SERP_FEATURES_COUNT = 4

export const SERP_FEATURES_DATA = [
  {
    code: '0',
    name: 'Instant answer',
    tooltip:
      "A direct answer to a user's search query that is usually displayed at the top of organic search results in the form of a gray-bordered box.",
    IconComp: InstantAnswerIcon
  },
  {
    code: '1',
    name: 'Knowledge panel',
    tooltip:
      'A block with brief information related to a searched topic that appears to the right of organic search results.',
    IconComp: KnowledgePanelIcon
  },
  {
    code: '2',
    name: 'Carousel',
    tooltip:
      'A row of horizontally scrollable images displayed at the top of search results.',
    IconComp: CarouselIcon
  },
  {
    code: '3',
    name: 'Local pack',
    tooltip:
      'A map with three local results that appears at the top of organic search results for a local search query.',
    IconComp: LocalPackIcon
  },
  {
    code: '4',
    name: 'Top stories',
    tooltip:
      "A card-style snippet presenting up to three news-related results relevant to user's search query, which is usually displayed between organic search results.",
    IconComp: TopStoriesIcon
  },
  {
    code: '5',
    name: 'Image pack',
    tooltip:
      'A collection of images related to a search query that is usually displayed between organic search results.',
    IconComp: ImagePackIcon
  },
  {
    code: '6',
    name: 'Site links',
    tooltip:
      'A set of links to other pages of a website that is displayed under the main organic search result and for brand-related search queries.',
    IconComp: SiteLinksIcon
  },
  {
    code: '7',
    name: 'Reviews',
    tooltip:
      'Organic search results marked with star ratings and including the number of reviews the star rating is based on.',
    IconComp: ReviewsIcon
  },
  {
    code: '8',
    name: 'Tweet',
    tooltip:
      'A card-style snippet displaying the most recent tweets related to a search query.',
    IconComp: TwitterIcon
  },
  {
    code: '9',
    name: 'Video',
    tooltip:
      'Video results with a thumbnail displayed along with other organic search results.',
    IconComp: VideoIcon
  },
  {
    code: '10',
    name: 'Featured video',
    tooltip:
      'A video result to a search query that is displayed at the top of all organic search results.',
    IconComp: FeaturedVideoIcon
  },
  {
    code: '11',
    name: 'Featured Snippet',
    tooltip:
      "A short answer to a user's search query with a link to the third-party website it is taken from that appears at the top of all organic search results.",
    IconComp: FeaturedSnippetIcon
  },
  {
    code: '13',
    name: 'Image',
    tooltip:
      'An image result with a thumbnail displayed along with other organic search results.',
    IconComp: ImageIcon
  },
  {
    code: '14',
    name: 'AdWords top',
    tooltip:
      'A series of ads (up to 4) that appear at the top of the first search results page.',
    IconComp: AdwordsTopIcon
  },
  {
    code: '15',
    name: 'AdWords bottom',
    tooltip:
      'A series of ads (up to 4) that appear at the bottom of the first search results page.',
    IconComp: AdwordsBottomIcon
  },
  {
    code: '16',
    name: 'Shopping ads',
    tooltip:
      "A row of horizontally scrollable paid shopping results that appear at the top of a search results page for a brand or product search query, and include the website's name, pricing, and product image.",
    IconComp: ShoppingCartIcon
  },
  {
    code: '17',
    name: 'Hotels Pack',
    tooltip:
      'A block that displays hotels related to a search query. Hotel results include information on prices and rating, and allows users to check availability for certain dates.',
    IconComp: HotelsIcon
  },
  {
    code: '18',
    name: 'Jobs search',
    tooltip:
      'A number of job listings related to a search query that appear at the top of the search results page. Job listings include the job title, the company offering the job, a site where the listing was posted, etc.',
    IconComp: JobsIcon
  },
  {
    code: '20',
    name: 'Video Carousel',
    tooltip:
      'A row of horizontally scrollable videos displayed among search results.',
    IconComp: VideoCarouselIcon
  },
  {
    code: '21',
    name: 'People also ask',
    tooltip:
      'A series of questions that may relate to a search query that appears in an expandable grid box labeled "People also ask" between search results.',
    IconComp: PeopleAlsoAskIcon
  },
  {
    code: '22',
    name: 'FAQ',
    tooltip:
      'A list of questions related to a particular search that shows up for a particular organic search result. When clicked on, each of the questions reveals the answer.',
    IconComp: FaqIcon
  },
  {
    code: '23',
    name: 'Flights',
    tooltip:
      'A block that displays flights related to a search query. Flight results include information on flight dates, duration, the number of transfers and prices. Data is taken from Google Flights.',
    IconComp: FlightsIcon
  }
]
