// new colors
export const primaryAnonym = '#6d9fff'
export const white = '#ffffff'
// primary
export const primary = {
  ExtraLight: '#f5fafe',
  Light: '#eef5ff',
  SemiLight: '#cfe0ff',
  Normal: '#b9cfff',
  SemiDark: '#5690ff',
  Dark: '#387dff',
  ExtraDark: '#0052e0'
}

// secondary
export const secondary = {
  UltraLight: '#edeff6',
  ExtraLight: '#dee3f0',
  Light: '#c1c9e0',
  SemiLight: '#9aa6c8',
  Normal: '#7683a8',
  SemiDark: '#545f7e',
  Dark: '#363f5a',
  ExtraDark: '#252e48'
}

export const tertiary = {
  Dark: '#293761',
  Normal: '#6375A9',
  SemiLight: '#B2C0E8',
  Light: '#F5F7FF',
  ExtraLight: '#FBFCFF'
}

// accents
export const accentGreen = '#4fdc6f'
export const accentPurple = '#766de8'
export const accentYellow = '#F7CF06'
export const accentRed = '#FF4C4C'
export const warningColor = '#E79E00'
export const warningBgColor = '#fef8e6'
