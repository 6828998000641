import { translate } from '@editor/common/utils/translations'

import { SUBDOMAIN_REGEXP, SUBDOMAIN_CHAR_LIMIT } from './consts'

export const validateSubdomain = subdomain => {
  if (!subdomain) {
    return {
      isValid: false,
      message: translate('this_field_cannot_be_empty_label')
    }
  }
  if (!SUBDOMAIN_REGEXP.test(subdomain)) {
    return {
      isValid: false,
      message: translate('subdomain_required_symbols_label')
    }
  }
  if (subdomain.length > SUBDOMAIN_CHAR_LIMIT) {
    return {
      isValid: false,
      message: translate('subdomain_validation_message_error_label')
    }
  }

  return { isValid: true, message: '' }
}
