import React, { useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import {
  Content,
  ContentHeader,
  ContentTitle,
  ContentSubTitle,
  ContentMainSection,
  MainContentContainer
} from '@containers/edit/components/SubSidebarContent/styled'
import { getLook, selectSemrushSidebarVisibilityState } from '@selectors'
import { changeLook } from '@actions/editor'
import { LOOKS } from '@editor/conf/consts'

import Image from '@editor/common/assets/svgr-icons/Image.svg'
import backgroundImg from '@editor/common/assets/background.svg'
import { useResponsive } from '@shared/hooks'
import { translate } from '@editor/common/utils/translations'
import { getSemrushDependedScreenBreakpoint } from '@editor/common/utils/styled'
import { BREAKPOINT_800 } from '@containers/edit/consts'

const StylesContent = ({ look, isSemrushSidebarVisible, changeLook }) => {
  const [showAnimation, setAnimationState] = useState(false)
  const breakpoint = getSemrushDependedScreenBreakpoint(
    BREAKPOINT_800,
    isSemrushSidebarVisible
  )
  const isSmallSizes = useResponsive(breakpoint)

  const isRounded = useMemo(() => look === LOOKS.ROUNDED, [look])
  const isSemiRounded = useMemo(() => look === LOOKS.CORNERED, [look])

  useEffect(() => {
    setAnimationState(true)
  }, [look])

  return (
    <Content>
      <ContentHeader>
        <ContentTitle>{translate('styles_label')}</ContentTitle>
        <ContentSubTitle>{translate('corner_radius_label')}</ContentSubTitle>
        <Styled.CornerRadiusWrapper>
          <Styled.CornerRadiusItem
            onClick={() => changeLook(LOOKS.ROUNDED)}
            isActive={look === LOOKS.ROUNDED}
            isCircle
          />
          <Styled.CornerRadiusItem
            onClick={() => changeLook(LOOKS.CORNERED)}
            isActive={look === LOOKS.CORNERED}
            isRoundedCorner
          />
          <Styled.CornerRadiusItem
            onClick={() => changeLook(LOOKS.SHARP)}
            isActive={look === LOOKS.SHARP}
          />
        </Styled.CornerRadiusWrapper>
      </ContentHeader>
      {!isSmallSizes && (
        <ContentMainSection>
          <MainContentContainer>
            <Styled.ImageContainer background={backgroundImg}>
              <Styled.ImageWrapper
                showAnimation={showAnimation}
                isRounded={isRounded}
                isSemiRounded={isSemiRounded}
                onAnimationEnd={() => setAnimationState(false)}
              >
                <Image />
              </Styled.ImageWrapper>
              <Styled.Buttons
                showAnimation={showAnimation}
                isRounded={isRounded}
                isSemiRounded={isSemiRounded}
              >
                <div />
                <div />
              </Styled.Buttons>
            </Styled.ImageContainer>
          </MainContentContainer>
        </ContentMainSection>
      )}
    </Content>
  )
}

const mapStateToProps = state => ({
  look: getLook(state),
  isSemrushSidebarVisible: selectSemrushSidebarVisibilityState(state)
})

export default connect(mapStateToProps, { changeLook })(StylesContent)
