import styled from 'styled-components'

import { mediaMaxWidth } from '@editor/common/utils/styled'
import {
  SEMRUSH_SIDEBAR_WIDTH,
  SEMRUSH_SIDEBAR_ANIMATION_TIME
} from '@editor/common/components/SemrushSidebar'
const headerHeight = 70
const mobileHeaderHeight = 120
const mobileToolbarHeight = 55

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`

export const WMContainer = styled.div`
  height: 100%;
  flex-grow: 1;
  width: ${({ theme }) =>
    theme.isSemrushSidebarVisible
      ? `calc(100% - ${SEMRUSH_SIDEBAR_WIDTH}px)`
      : '100%'};
  transition: width ${SEMRUSH_SIDEBAR_ANIMATION_TIME}ms ease-out;
`

export const WMMainSection = styled.div`
  position: relative;
  display: flex;
  height: calc(100% - ${headerHeight}px);
  ${mediaMaxWidth(800)`
    height: calc(100% - ${mobileHeaderHeight}px - ${mobileToolbarHeight}px);
  `}
`

export const EditorContainer = styled.div`
  position: relative;
  flex-grow: 1;
  transition: all 0.15s ease-out;
  overflow: auto;
`
