/* @flow */
import React, { useContext, memo } from 'react'
import S from './Contact8.styled'
import THEME from './Contact8.theme'
import { validation } from './Contact8.consts'
import ActionGetter from './Actions'
import { SubscribeForm } from '../SubscribeForm'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import {
  Controls,
  ControlsWithModalOpener
} from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { DispatchContext, EditingContext } from '@contexts'
import { isValidEmail, isValidPhoneNumber } from '@website/common/utils'
import SocialLinks from '@website/common/components/SocialLinks'
import { getMapSrcWithLatLng } from '@website/common/utils'

const Contact8 = memo(props => {
  const renderEmailOrNumber = (isEditing, key) => Actions => {
    const { data } = props
    const text = data[key]
    if (isEditing || (!isValidEmail(text) && !isValidPhoneNumber(text))) {
      return (
        <EditableContent
          areControlsHidden
          as={S.BlockParagraph}
          text={text}
          isEditing={isEditing}
          maxCount={validation.contactMaxChar}
          className="WM_GLOBAL_paragraph14"
          onChange={(newParagraph: string) => {
            Actions.changeBlockParagraph(newParagraph, key)
          }}
        />
      )
    }
    const hrefPrefix = isValidEmail(text) ? 'mailto:' : 'tel:'
    return (
      <S.A href={`${hrefPrefix}${text}`} className="WM_GLOBAL_paragraph14">
        {text}
      </S.A>
    )
  }
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttonText,
      placeholderText,
      socials,
      contact1,
      contact2,
      contact3,
      mapSrc,
      geoLocation
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const _mapSrc = geoLocation ? getMapSrcWithLatLng(geoLocation) : mapSrc

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <S.Content>
          <EditableContent
            isEditing={isEditing}
            text={paragraph}
            alignment={titleAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph14"
            as={S.Paragraph}
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={title}
            alignment={paragraphAlignment}
            required={validation.title.required}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading32"
            as={S.Title}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Show when={[buttonText]}>
            <S.FormWrapper>
              <If
                condition={buttonText}
                otherwise={() => (
                  <AddButton
                    onAdd={Actions.addForm}
                    type="icon"
                    large
                    toShow
                    style={{ marginTop: '20px' }}
                  >
                    +
                  </AddButton>
                )}
                then={() => (
                  <Controls
                    actions={Actions.formActions()}
                    style={{ width: '100%' }}
                  >
                    <S.SubscribeFormWrap>
                      <SubscribeForm
                        actions={{
                          buttonActions: Actions.btnActions(buttonText),
                          placeHolderAction: Actions.changePlaceHolderText
                        }}
                        buttonText={buttonText}
                        placeholder={placeholderText}
                        theme={THEME}
                      />
                    </S.SubscribeFormWrap>
                  </Controls>
                )}
              />
            </S.FormWrapper>
          </Show>
          <Show when={[contact1 || contact2 || contact3]}>
            <S.ContactsWrapper>
              {renderEmailOrNumber(isEditing, 'contact1')(Actions)}
              <Show when={[contact1 || contact2]}>
                <S.ContactItems>
                  {renderEmailOrNumber(isEditing, 'contact2')(Actions)}
                  <Show when={[contact3]}>
                    <S.Line />
                    {renderEmailOrNumber(isEditing, 'contact3')(Actions)}
                  </Show>
                </S.ContactItems>
              </Show>
            </S.ContactsWrapper>
          </Show>
          <SocialLinks
            className="header-socials"
            socials={socials}
            socialsMaxCount={validation.socials.max}
          />
        </S.Content>
        <S.MapWrapper className="map-wrap">
          <ControlsWithModalOpener
            actions={Actions.getMapActions(mapSrc, geoLocation)}
            style={{ width: '100%', height: '100%' }}
          >
            <Overlay />
            <S.Map
              title="Map"
              src={_mapSrc}
              frameBorder="0"
              allowFullScreen=""
            />
          </ControlsWithModalOpener>
        </S.MapWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Contact8
