/* @flow */
import React, { useContext, memo } from 'react'
import S from './Steps6.styled'
import { validation } from './Steps6.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Steps6 = memo(props => {
  const {
    data: {
      title,
      blocks,
      titleAlignment,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
      isEditing={isEditing}
    >
      <S.WmCustomContainer>
        <EditableContent
          as={S.Title}
          alignment={titleAlignment}
          text={title}
          maxCount={validation.headingMaxChar}
          className="WM_GLOBAL_heading42"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <S.BlocksWrapper
          isEditing={isEditing}
          hasTitle={!!title.length}
          isFourBlock={blocks.length === 4}
        >
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => (
              <S.BlockWrap key={idx}>
                <ControlsWithModalOpener
                  alignment="right"
                  actions={Actions.blockActions({
                    idx,
                    showRemove: blocks.length > validation.blocks.min
                  })}
                  style={{ width: '100%' }}
                >
                  <S.Block>
                    <S.Count isEven={idx % 2 === 0}>{idx + 1}</S.Count>
                    <EditableContent
                      as={S.BlockTitle}
                      text={block.title}
                      alignment={block.titleAlignment}
                      maxCount={validation.blockHeadingMaxChar}
                      required={validation.blocks.title.required}
                      className="WM_GLOBAL_heading20"
                      onChange={(newTitle: string) => {
                        Actions.changeBlockText(newTitle, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx)
                      }
                    />
                  </S.Block>
                </ControlsWithModalOpener>
              </S.BlockWrap>
            )}
          />
        </S.BlocksWrapper>
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Steps6
