/* @flow */
import React, { useCallback, useContext } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TFooterProps } from './types'
import { MobileDetectContext } from '@contexts'
import { addMultipleComponents } from '@actions/project'
import AddIcon from '@editor/common/assets/svgr-icons/Plus_icon.svg'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

const Footer = ({
  baseUrl,
  selectedComponents,
  onDeselect,
  addMultipleComponents,
  navigateToEditor
}: TFooterProps) => {
  const {
    params: { idx, page }
  } = useRouteMatch()
  const history = useHistory()
  const isMobile = useContext(MobileDetectContext)
  const selectedComponentsCount = selectedComponents.length

  const onAdd = useCallback(() => {
    const { search } = history.location
    const pagePath = page === 'home' ? '/' : `/${page}`
    const url = `${baseUrl}/edit/${page}${search}`
    const cb = () => navigateToEditor(url, idx)

    addMultipleComponents(pagePath, idx, selectedComponents, cb)
  }, [idx, page, selectedComponents, addMultipleComponents, navigateToEditor])

  const translationLabel =
    selectedComponentsCount > 1
      ? 'multiple_components_selected_label'
      : 'one_component_selected_label'

  return (
    <Styled.Footer isVisible={selectedComponentsCount}>
      {selectedComponentsCount ? (
        <Styled.SelectedInfo>
          {transformTextVariables(translate(translationLabel), [
            {
              value: selectedComponentsCount,
              Comp: BoldComp
            }
          ])}
        </Styled.SelectedInfo>
      ) : null}
      <Styled.DeselectButton onClick={onDeselect}>
        {translate('deselect_label')}
      </Styled.DeselectButton>
      <Styled.AddButton onClick={onAdd}>
        <AddIcon /> {translate('add_label')}{' '}
        {selectedComponentsCount && isMobile
          ? `(${selectedComponentsCount})`
          : null}
      </Styled.AddButton>
    </Styled.Footer>
  )
}

const mapDispatchToProps = { addMultipleComponents }

export default connect(null, mapDispatchToProps)(Footer)
