import {
  Keyword,
  Intent,
  TrendChart,
  KD,
  SERPFeatures,
  Results
} from './components'

export const COLUMNS = [
  {
    data_key: 'Keyword',
    label: 'Keyword',
    key: 'Ph'
  },
  {
    data_key: 'Intent',
    label: 'Intent',
    key: 'In',
    tooltipText:
      'The purpose of a search in a search engine. Intents can be read by the search engine algorithms to show the proper results and SERP features.',
    tooltipTitle: 'Intent'
  },
  {
    data_key: 'Related Relevance',
    label: 'Related %',
    key: 'Rr',
    isSortable: true,
    tooltipText:
      'This metric measures how closely related a specific keyword is to your target one. The higher the percentage, the more relevant this keyword is to the researched one.',
    tooltipTitle: 'Related %'
  },
  {
    data_key: 'Search Volume',
    label: 'Volume',
    key: 'Nq',
    isSortable: true,
    tooltipText:
      'The average number of monthly searches for a given keyword over a 12-month period. Use sort icon to display results in ascending or descending order.',
    tooltipTitle: 'Volume'
  },
  {
    data_key: 'Trends',
    label: 'Trend',
    key: 'Td',
    tooltipText:
      'The interest of searchers in a given keyword during the period of 12 months. The metric is based on changes in the number of queries per month.',
    tooltipTitle: 'Trend'
  },
  {
    data_key: 'Keyword Difficulty Index',
    label: 'KD %',
    key: 'Kd',
    isSortable: true,
    tooltipText:
      'Keyword Difficulty shows you how hard it would be for a website to rank organically in the Google top 10 for the analyzed keyword. The higher the percentage, the harder it will be to achieve high rankings.',
    tooltipTitle: 'Keyword Difficulty'
  },
  {
    data_key: 'CPC',
    label: 'CPC (USD)',
    key: 'Cp',
    isSortable: true,
    tooltipText:
      'Average price in USD advertisers pay for a user’s click on an ad triggered by a given keyword (Google Ads). Use sort icon to display results in ascending or descending order.',
    tooltipTitle: 'Cost Per Click'
  },
  {
    data_key: 'Competition',
    label: 'Com.',
    key: 'Co',
    isSortable: true,
    tooltipText:
      'The level of competition between advertisers bidding on a given keyword within their PPC campaigns. Competitive Density is shown on a scale from 0 to 1.00 with 1.00 being the most difficult to rank for.',
    tooltipTitle: 'Competitive Density'
  },
  {
    data_key: 'Keywords SERP Features',
    label: 'SERP Features',
    key: 'Fk',
    tooltipText:
      'SERP features are any results on a Google Search Engine Results Page (SERP) that is not a traditional organic result. Hover on an icon to learn what SERP feature that is.',
    tooltipTitle: 'SERP Features'
  },
  {
    data_key: 'Number of Results',
    label: 'Results',
    key: 'Nr',
    isSortable: true,
    tooltipText:
      'The number of URLs displayed in organic search results for the given keyword.',
    tooltipTitle: 'Results'
  }
]

export const COMPONENTS_BY_COLUMN = {
  Ph: Keyword,
  In: Intent,
  Td: TrendChart,
  Kd: KD,
  Fk: SERPFeatures,
  Nr: Results
}
