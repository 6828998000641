/* @flow */
import React, { memo, useCallback } from 'react'
import produce from 'immer'

import RemoveIcon from '@website/common/assets/svgr-icons/close.svg'
import Validatable from '@website/common/lib/ValidatableHOC'
import { isValidName } from '@website/common/utils'
import {
  FIRST_NAME_VALIDATION_MESSAGE,
  LAST_NAME_VALIDATION_MESSAGE
} from './consts'
import _RSVPInput from '../RSVPInput'
import * as Styled from './styled'
import { TAdditionalGuestProps } from './types'
import { getInitialGuestData } from './utils'

const RSVPInput = Validatable(_RSVPInput)

const AdditionalGuests = ({
  guestBtn,
  firstName,
  lastName,
  additionalGuestCount,
  additionalGuests = [],
  setFormData,
  formErrors,
  register
}: TAdditionalGuestProps) => {
  const isBtnDisabled = additionalGuests.length >= additionalGuestCount

  const onBtnClick = () => {
    if (isBtnDisabled) return

    setFormData(
      produce(draft => {
        draft.additionalGuests.push(getInitialGuestData())
      })
    )
  }

  const onInputChange = useCallback(
    (key, idx) => value => {
      setFormData(
        produce(draft => {
          draft.additionalGuests[idx][key] = value
        })
      )
    },
    []
  )

  const onRemoveIconClick = idx => {
    setFormData(
      produce(draft => {
        draft.additionalGuests.splice(idx, 1)
      })
    )
  }

  return (
    <>
      <Styled.AdditionalGuestButton
        disabled={isBtnDisabled}
        onClick={onBtnClick}
        className="WM_GLOBAL_paragraph14"
      >
        {guestBtn.text}
      </Styled.AdditionalGuestButton>
      {additionalGuests.map((additionalGuest, idx) => {
        const { id } = additionalGuest
        return (
          <Styled.InputPair key={id}>
            <RSVPInput
              isRequired
              value={additionalGuest.firstName}
              label={firstName.text}
              errorMessage={formErrors[`additionalGuest${id}_firstName`]}
              {...register({
                fieldName: `additionalGuest${id}_firstName`,
                initialValue: additionalGuest.firstName,
                onChange: onInputChange('firstName', idx),
                validationOptions: {
                  isRequired: true,
                  firstName: {
                    validator: isValidName,
                    errorMessage: FIRST_NAME_VALIDATION_MESSAGE
                  }
                }
              })}
            />
            <RSVPInput
              isRequired
              value={additionalGuest.lastName}
              label={lastName.text}
              errorMessage={formErrors[`additionalGuest${id}_lastName`]}
              {...register({
                fieldName: `additionalGuest${id}_lastName`,
                initialValue: additionalGuest.lastName,
                onChange: onInputChange('lastName', idx),
                validationOptions: {
                  isRequired: true,
                  lastName: {
                    validator: isValidName,
                    errorMessage: LAST_NAME_VALIDATION_MESSAGE
                  }
                }
              })}
            />
            <RemoveIcon
              className="remove-icon"
              onClick={() => onRemoveIconClick(idx)}
            />
          </Styled.InputPair>
        )
      })}
    </>
  )
}

export default memo(AdditionalGuests)
