// @flow

import React, { useMemo, useCallback } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { useHistoryWithSearch } from '@hooks'
import { translate } from '@editor/common/utils/translations'

import { List } from '../../components'
import BuyIcon from '@editor/common/assets/svgr-icons/Buy_icon.svg'
import ConnectIcon from '@editor/common/assets/svgr-icons/Connect_icon.svg'
import QuestionIcon from '@editor/common/assets/svgr-icons/Question_icon.svg'
import MyAccountIcon from '@editor/common/assets/svgr-icons/My_Account_icon.svg'
import { openDomainPage } from '@actions/editor'
import { isSubscriber } from '@selectors'
import { getLocalizedURL } from '@editor/common/utils/translations'

import type { TMoreLinksProps } from './types'
import * as Styled from './styled'
import { openUrl } from '@website/common/utils'
import { EDITOR_BASE, PAGE_BASE } from '@editor/conf/routes'
import { HELP_SUPPORT_LINK } from '@editor/conf/consts'

const path = window.CURRENT_HOST || window.location.origin
const mapStateToProps = state => ({
  isUserSubscribed: isSubscriber(state)
})
const mapDispatchToProps = {
  openDomainPage
}

const MoreLinks = ({
  isUserSubscribed,
  closeDropdown,
  openDomainPage
}: TMoreLinksProps) => {
  const { url } = useRouteMatch(EDITOR_BASE)
  const { url: urlForSettings } = useRouteMatch(PAGE_BASE) || {}
  const history = useHistoryWithSearch()

  const goToSettings = useCallback(() => {
    const _url = urlForSettings || `${url}/edit/home`

    closeDropdown()
    history.push(`${_url}/settings/domain`)
  }, [url])

  const goToMyAccount = useCallback(() => {
    closeDropdown()
    openUrl(`${path}${getLocalizedURL('/profile')}`)
  }, [path])

  const handleBuyClick = useCallback(() => {
    goToSettings()
    openDomainPage()
  }, [goToSettings, openDomainPage])

  const handleHelpClick = useCallback(() => {
    closeDropdown()
    openUrl(HELP_SUPPORT_LINK)
  }, [closeDropdown])

  const items = useMemo(
    () => [
      ...(isUserSubscribed
        ? [
            {
              IconComp: BuyIcon,
              text: translate('buy_domain_label'),
              onClick: handleBuyClick
            }
          ]
        : []),
      {
        IconComp: ConnectIcon,
        text: translate('connect_domain_label'),
        onClick: goToSettings
      },
      {
        IconComp: MyAccountIcon,
        text: translate('my_account_label'),
        onClick: goToMyAccount
      },
      {
        IconComp: QuestionIcon,
        text: translate('help_and_support_label'),
        onClick: handleHelpClick
      }
    ],
    [isUserSubscribed, handleBuyClick, goToSettings, handleHelpClick]
  )

  return (
    <Styled.Container>
      <List title={translate('more_label')} items={items} />
    </Styled.Container>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreLinks)
