/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature14_2.styled'
import { validation } from './Feature14_2.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const Feature14_2 = memo(props => {
  const {
    data: { title, paragraph, features, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <S.Container isEditing={isEditing}>
      <S.TopBlockWrapper>
        <Mapper
          data={features.slice(0, 2)}
          render={(feature: string, featureIdx: number) => {
            return (
              <S.BlockItemWrap key={featureIdx}>
                <S.ControlContainer>
                  <ControlsWithImageCropper
                    actions={Actions.imageActions(featureIdx, feature.imgUrl)}
                    style={{ width: '100%' }}
                  >
                    <Image
                      as={S.Img}
                      alt="item img"
                      sizes={feature.imgDimensions}
                      defaultImgSrc={feature.imgUrl}
                      asProps={{
                        imgCropParams: feature.imgCropParams
                      }}
                    />
                  </ControlsWithImageCropper>
                </S.ControlContainer>
              </S.BlockItemWrap>
            )
          }}
        />
      </S.TopBlockWrapper>
      <Show when={[title, paragraph]}>
        <S.TextContent>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Description}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <S.Line />
        </S.TextContent>
      </Show>
      <S.BottomBlockWrapper>
        <Mapper
          data={features.slice(2)}
          render={(feature: string, featureIdx: number) => {
            return (
              <S.BlockItemWrap key={featureIdx}>
                <S.ControlContainer>
                  <ControlsWithImageCropper
                    actions={Actions.imageActions(
                      featureIdx + 2,
                      feature.imgUrl
                    )}
                    style={{ width: '100%' }}
                  >
                    <Image
                      as={S.Img}
                      alt="item img"
                      sizes={feature.imgDimensions}
                      defaultImgSrc={feature.imgUrl}
                      asProps={{
                        imgCropParams: feature.imgCropParams
                      }}
                    />
                  </ControlsWithImageCropper>
                </S.ControlContainer>
              </S.BlockItemWrap>
            )
          }}
        />
      </S.BottomBlockWrapper>
    </S.Container>
  )
})

export default Feature14_2
