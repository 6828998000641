/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header57.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  padding: 0;
  ${getBackgroundStyles(THEME)};
  position: relative;
  ${({ isEditing }) =>
    isEditing
      ? css`
          .header-nav-bar {
            margin-bottom: 30px;
          }
        `
      : css``};
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  padding-top: ${({ topOffset }) => `${topOffset + 6}px`};
  @media only screen and (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: ${({ topOffset }) => `${topOffset - 5}px`};
  }
  @media only screen and (max-width: 458px) {
    padding-top: ${({ topOffset }) => `${topOffset - 15}px`};
  }
`

const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: ${getStyleForProp('line')};
    position: absolute;
    left: 0;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`

const TopContent = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 1540px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  & > .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
    > :only-child {
      padding-bottom: 30px;
    }
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Line = styled.div`
  width: 1px;
  min-width: 1px;
  background-color: ${getStyleForProp('line')};

  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 2px;
    margin-top: 40px;
    ${({ hasParagraph }) => (!hasParagraph && `display: none`)};
    & + div {
      padding-top: 30px;
    }
  }
`

const RightContent = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 20px;
  text-align: left;
  padding: 40px 0 40px 50px;
  box-sizing: border-box;
  align-self: center;
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    & > h2,
    p {
      max-width: 100%;
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    align-self: flex-start;
    margin: 0;
    padding: 40px 0;
    max-width: initial;
    border-left: none;
  }
  ${({ hasContent,  isEditing }) =>
  !isEditing && !hasContent
    ? css`
        padding: 0  40px 30px 0;
        @media only screen and (max-width: 1024px) {
          padding: 60px 0 0 0;
        }
        @media only screen and (max-width: 458px) {
          padding: 50px 0 0 0;
        }
      `
    : css`
      width: 100%;
      max-width: 370px;
      @media only screen and (max-width: 1024px) {
        padding: 40px 0;
      }
    `};
  .learn-more-link {
    color: ${getStyleForProp('link')};
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
  .add-button-wrap {
    > div {
      height: 50px;
      box-sizing: border-box;
      padding: 16px 30px;
    }
  }
`

const TitleWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  align-self: center;
  padding: 40px 50px 40px 0;
  @media only screen and (max-width: 1024px) {
    > h1 {
      padding-bottom: 0;
    }
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 40%;
  @media only screen and (max-width: 1024px) {
    padding-top: 63%;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 89%;
  }
  @media only screen and (max-width: 458px) {
    padding-top: 112%;
  }
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 40%;
    height: initial;
    @media only screen and (max-width: 1024px) {
      padding-top: 63%;
    }
    @media only screen and (max-width: 768px) {
      padding-top: 89%;
    }
    @media only screen and (max-width: 458px) {
      padding-top: 112%;
    }
  
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  @media only screen and (max-width: 1024px) {
    ${({ isEditing }) => (isEditing && `margin-top: 40px`)};
  }
`

const Img = styled.img`
  width: 100%;
  height: 130%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  transform: translateY(-22%);
`
const HeaderFooter = styled.div`
  padding: 20px 40px;
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid ${getStyleForProp('line')};
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const HeaderFooterContainer = styled.div`
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 122px;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 20px;
  }
  h2 {
    margin-bottom: 0;
  }
  & > p {
    margin-left: auto;
  }
  .control-container {
    width: 100%;
    &:nth-child(2):nth-last-child(1) {
      max-width: 286px;
    }
    & > p {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    & > p {
      margin-left: 0;
      width: 100%;
    }
  }
`
const FooterTitle = styled.h2`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const FooterParagraph = styled.p`
  width: 286px;
  min-width: 286px;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

export const BottomContent = styled.div`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  z-index: 1;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  right: 40px;
  bottom: 0;
  transform: translate(100%, 50%);
  border: 2px solid ${getStyleForProp('link')};
  @media only screen and (max-width: 1550px) {
    transform: translate(0, 50%);
  }
  @media only screen and (max-width: 458px) {
    width: 60px;
    height: 60px;
    right: 20px;
  }
`

export const DownButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  @keyframes nextComponentArrow {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @media only screen and (max-width: 458px) {
    max-width: 60px;
    max-height: 60px;
    > div, svg {
      width: 24px;
      height: 24px;
    }
  }
`

export const ScrollIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('link')
}))``

export default {
  BottomContent,
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  TitleWrapper,
  Title,
  Paragraph,
  ImageWrapper,
  Img,
  ControlContainer,
  TopContent,
  RightContent,
  Line,
  HeaderFooterContainer,
  HeaderFooter,
  FooterTitle,
  FooterParagraph,
  ScrollIcon,
  DownButton
}
