/* @flow */
import styled from 'styled-components'

import THEME from './Video3.theme'
import Container from '@website/common/components/Container'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing ? 'padding-top: 130px;' : 'padding-top: 80px;'}
  @media only screen and (max-width: 767px) {
    ${({ isEditing }) =>
      isEditing ? 'padding-top: 130px;' : 'padding-top: 60px;'}
  }
`
const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    p {
      margin-top: 0;
    }
  }
`

const VideosWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -60px auto;
  padding-top: 50px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    margin: -30px auto 0 auto;
    padding-top: 40px;
  }
`

const VideoBlock = styled.div`
  width: 40%;
  max-width: 1000px;
  margin: 60px auto;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin: 30px auto 0 auto;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  width: 100%;
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const PortfolioImg = styled.img`
  border-radius: ${getStyleForProp('cardRadius')};
`

const SquareBox = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 200px;
  height: 200px;
  background: ${({ theme }) => theme.palette.tertiarySemiLight};
  opacity: 0.2;
  position: absolute;
  width: 100%;
  height: 100%;
  right: -30px;
  top: -30px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const VideoContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: hidden;
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
  .iframe-video {
    z-index: 1;
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Dots = styled.div`
  position: absolute;
  right: -60px;
  top: 180px;
  @media only screen and (max-width: 1400px) {
    top: 120px;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

export default {
  StyledContainer,
  VideoContainer,
  VideosWrapper,
  VideoBlock,
  VideoWrap,
  Dots,
  Title,
  Description,
  PortfolioImg,
  SquareBox,
  VideoContent
}
