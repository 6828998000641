/* @flow */
import { eventSettingsActionMaker } from '@website/components/ActionMakers'
import {
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeSubtitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeDateAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_DATE_ALIGNMENT',
      value: alignment
    })
  }

  const changeLocationAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_LOCATION_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBackgroundImage = backgroundImage => {
    dispatcher({
      type: 'CHANGE_BACKGROUND_IMAGE',
      value: backgroundImage
    })
  }

  return {
    changeSubtitleAlignment,
    changeDateAlignment,
    changeLocationAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    eventSettingsActionMaker,
    changeBackgroundImage
  }
}
