import React, { memo } from 'react'

import * as Styled from './styled'
import {
  PrimaryButton,
  SecondaryButton
} from '@editor/common/styled-components/buttons'
import { translate } from '@editor/common/utils/translations'

const Footer = ({ onCancel, onSave, isSaveDisabled }) => (
  <Styled.Footer>
    <SecondaryButton onClick={onCancel}>
      {translate('cancel_label')}
    </SecondaryButton>
    <PrimaryButton onClick={onSave} disabled={isSaveDisabled}>
      {translate('save_label', true)}
    </PrimaryButton>
  </Styled.Footer>
)

export default memo(Footer)
