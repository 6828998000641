/* @flow */
import { changeTitle, changeParagraph, changeTitleAlignment,
  changeParagraphAlignment } from '@website/components/CommonActions'

export default dispatcher => {
  const changeQuoteAuthor = (newQuoteAuthor: string) => {
    dispatcher({
      type: 'CHANGE_QUOTE_AUTHOR',
      value: newQuoteAuthor
    })
  }

  const changeQuoteAuthorPosition = (newQuoteAuthorPosition: string) => {
    dispatcher({
      type: 'CHANGE_QUOTE_AUTHOR_POSITION',
      value: newQuoteAuthorPosition
    })
  }

  const changeQuote = (newQuote: string) => {
    dispatcher({
      type: 'CHANGE_QUOTE',
      value: newQuote
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeQuoteAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_QUOTE_ALIGNMENT',
      value: alignment
    })
  }

  const changeQuoteAuthorAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_QUOTE_AUTHOR_ALIGNMENT',
      value: alignment
    })
  }

  const changeQuoteAuthorPositionAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_QUOTE_AUTHOR_POSITION_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeQuote,
    changeQuoteAuthor,
    changeQuoteAuthorPosition,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeQuoteAlignment,
    changeQuoteAuthorAlignment,
    changeQuoteAuthorPositionAlignment
  }
}
