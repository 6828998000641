/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Steps7.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const stylesByAlignment = {
  default: '',
  left: css`
    text-align: left;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: right;
  `
}

const getStyleByAlignment = ({ alignment }) => stylesByAlignment[alignment]

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;

  .rf-image-slider {
    > div {
      > .styled-controls {
        right: 0 !important;
        left: initial;
      }
    }
  }
  .link-section {
    align-items: center;
    margin-top: 20px;
  }
  .learn-more-link {
    color: ${({ theme }) => theme.palette.primaryDark};
    svg {
      fill: ${({ theme }) => theme.palette.primaryDark};
    }
  }
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 918px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  padding-top: ${({ isEditing }) => (isEditing ? '60px' : '0')};
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  & > .control-container {
    margin-bottom: 30px;
  }

  .slide-container {
    margin: 50px;
  }

  .control-container {
    width: 100%;
  }

  .slick-slide {
    ${({ slidesLength }) =>
      slidesLength < 5 ? `min-width: 200px !important;` : ''}
  }
  .slick-slide > div {
    margin: 10px 10px 50px 10px;
  }

  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : '')};
  ${getStyleByAlignment}
  ${({ isEditing }) =>
    isEditing ? 'margin-bottom: 0;' : 'margin-bottom: 30px;'}

  @media only screen and (min-width: 1024px) {
    ${({ isEditing }) => (isEditing ? '' : 'margin-bottom: 50px;')}
  }
`

const Tooltip = styled.p`
  width: 100%;
  padding: 25px;
  color: ${getStyleForProp('tooltipText')};
  ${getStyleByAlignment}
  background-color: ${({ theme }) =>
    `rgba(${hexToRgb(theme.palette.primaryDark)}, 0.80)`};
  transition: 0.5s;
  box-sizing: border-box;
  border-bottom-left-radius: ${getStyleForProp('cardRadius')};
  border-bottom-right-radius: ${getStyleForProp('cardRadius')};

  ${({ isEditing }) =>
    isEditing
      ? ''
      : 'position: absolute; bottom: 0; transform: translateY(103%)'};

  @media only screen and (max-width: 1024px) {
    margin-top: 5px;
    position: relative;
    transition: 0s;
    border-radius: ${getStyleForProp('cardRadius')};
    transform: translateY(0%);
  }
  @media only screen and (max-width: 760px) {
    padding: 10px;
  }
`

const IconContainer = styled.button`
  border: none;
  padding: 0;
  width: 36px;
  min-width: 36px;
  margin: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${getStyleForProp('buttonBackground')};
  border-radius: ${getStyleForProp('iconRadius')};
  .left {
    transform: translateX(-1px);
  }

  .right {
    transform: translateX(1px);
  }

  &:focus {
    outline: none;
  }

  @media only screen and (max-width: 760px) {
    display: none;
  }
`
const LineContainer = styled.div`
  overflow: unset;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 30px;
  height: 10px;
  ${({ slidesLength }) =>
    slidesLength >= 5
      ? `width: calc(-40px + ${13 * slidesLength}%);`
      : `width: ${110 * slidesLength + 20}px;`}
  @media only screen and (max-width: 1024px) {
    opacity: 0 !important;
  }
`

const Line = styled.span`
  position: absolute;
  width: 100%;
  height: 10px;
  background-color: ${getStyleForProp('sliderLine')};
`

const ProgressLine = styled.span`
  position: absolute;
  height: 10px;
  background-color: ${getStyleForProp('sliderLineActive')};
  transition: 0.5s;

  ${({ slidesLength, dotActive }) =>
    `width: calc((100 / ${slidesLength - 1}) * ${dotActive}%);`}
`

const Dot = styled.button`
  padding: 0;
  border: none;
  width: 35px;
  height: 35px;
  font-size: 14px;
  font-weight: 600;
  margin: 0 5px 10px !important;
  cursor: pointer;
  border-radius: ${getStyleForProp('iconRadius')};
  &:focus {
    outline: none;
  }
  @media only screen and (min-width: 760px) {
    width: 45px;
    height: 45px;
    font-size: 20px;
  }
`

const SliderWrapper = styled.div`
  display: flex !important;
  align-items: center;
  flex-direction: column;
  min-width: ${({ slidesCount }) =>
    slidesCount === 2 ? '200px' : slidesCount < 5 ? '163px' : '110px'};
  color: ${({ isActive }) =>
    !isActive
      ? getStyleForProp('sliderParagraph')
      : getStyleForProp('sliderLineActive')};

  & p {
    @media only screen and (max-width: 1024px) {
      transition: 0s;
    }
  }
  ${Dot} {
    animation-delay: background 2s;
    border: none !important;
    box-sizing: border-box;

    ${({ isActive }) =>
      !isActive
        ? css`
            margin-bottom: 15px !important;
            background-color: ${getStyleForProp('sliderLine')};
            color: ${getStyleForProp('buttonText')};
            transform: translateY(5px);
            @media only screen and (max-width: 760px) {
              width: 31px;
              height: 31px;
              margin-bottom: 14px !important;
              transform: translateY(2px);
            }
          `
        : css`
            width: 35px;
            height: 35px;
            background: ${getStyleForProp('buttonBackground')};
            color: ${getStyleForProp('buttonTextActive')};
            transform: translateY(0px);
            @media only screen and (min-width: 760px) {
              width: 50px;
              height: 50px;
              transform: translateY(3px);
            }
          `}
  }
`

const SliderParagraph = styled.p`
  color: ${getStyleForProp('sliderParagraph')};
  width: 100%;
  ${getStyleByAlignment}
  line-height: 1.4;
  ${({ isEditing }) => (isEditing ? 'padding-bottom: 0;' : 'margin-top: 30px;')}
`

const MobileDotContainer = styled.div`
  display: none;

  ${({ activeSlideIdx }) =>
    activeSlideIdx
      ? css`
          transform: translateX(${-activeSlideIdx * 100}%);
        `
      : ''}
  ${SliderWrapper} {
    width: 100%;
    min-width: 100%;
    padding: 10px 0px 40px 0px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 1024px) {
    display: flex;
  }
`

const StepNumber = styled.p`
  ${({ isEditing }) => !isEditing && 'display: none !important;'}
  color: ${getStyleForProp('sliderParagraph')};
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

const NavigationText = styled.p`
  max-width: 110px;
  ${({ slidesCount }) =>
    slidesCount < 5 ? `max-width: 165px !important;` : ''}
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  min-width: 75px;
  text-align: center;
  font-weight: 600;
  transition: 0.33s;
  ${({ isEditing }) => (!isEditing ? 'cursor: pointer;' : '')}
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const TextContainer = styled.div`
  width: 100%;
  padding-top: ${({ isEditing }) => (isEditing ? '60px' : '0')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  word-break: break-word;

  .control-container {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
`
const SlideBoxContainer = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
`
const SlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 100%;
  top: 0;
  opacity: 0;

  .lazy-figure {
    overflow: hidden;
    border-radius: ${getStyleForProp('cardRadius')};
  }
  @media only screen and (max-width: 1024px) {
    min-height: unset;
  }
  transform: ${({ idx }) => `translateX(-${idx * 100}%)`};
`

const HiddenContainer = styled.div`
  width: 100%;
  overflow: hidden;
  ${getStyleForProp('cardRadius')};
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  visibility: visible;
  border-radius: ${getStyleForProp('cardRadius')};
  ${({ isEditing }) =>
    isEditing
      ? 'padding-top: 51.375%;'
      : 'overflow: hidden;transition: transform 0.33s;'};
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    &:nth-last-child(1) {
      height: auto;
      bottom: 0;
      top: auto;
      @media only screen and (max-width: 1024px) {
        position: relative;
      }
    }
  }

  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
    overflow: hidden;
  }

  .lazy-figure {
    padding-top: 51.375%;
    height: initial;

    & .img-loaded {
      animation-duration: 0s !important;
    }
  }

  &:hover {
    ${Tooltip} {
      ${({ isEditing }) => (isEditing ? '' : 'transform: translateY(0%);')}
    }
  }
`

const StepContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

const SliderContent = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  border-radius: ${getStyleForProp('cardRadius')};
  ${({ isEditing }) =>
    isEditing ? 'padding: 1px; box-sizing: border-box;' : ''};
  .activeStep {
    position: relative;
    z-index: 2;
    animation-name: showStep;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }
  @keyframes showStep {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const ImageSlideContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  .slick-slider {
    position: relative;
    z-index: 2;
    ${({ slidesLength }) =>
      slidesLength < 5
        ? `.slick-slide{
       min-width: 165px !important;
    } `
        : ''}
    ${({ slidesLength }) =>
      slidesLength === 2 &&
      `.slick-slide{
     min-width: 200px !important;
  } `}
  }
`
const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin: 5px;
  }
  &:empty {
    display: none;
  }
`

const ImageFigure = styled.div`
  width: 100%;
  font-size: 0;
  border-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const ArrowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const Arrow = styled.div`
  width: 36px;
  height: 36px;
  position: absolute;
  margin-top: 25.7%;
  transform: translateY(-50%);
  border-radius: ${getStyleForProp('iconRadius')};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  background: ${getStyleForProp('buttonBackground')};
  ${({ isClickable }) =>
    !isClickable &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  &:nth-child(1) {
    left: -56px;
    @media only screen and (max-width: 1024px) {
      left: 0px;
    }
  }
  &:nth-child(2) {
    right: -56px;
    @media only screen and (max-width: 1024px) {
      right: 0px;
    }
  }
`
export default {
  Dot,
  MobileDotContainer,
  LineContainer,
  Line,
  ProgressLine,
  NavigationText,
  StepNumber,
  Title,
  SliderContent,
  StepContainer,
  StyledContainer,
  WmCustomContainer,
  Tooltip,
  HiddenContainer,
  TextContainer,
  IconContainer,
  SliderWrapper,
  SlideContainer,
  SlideBoxContainer,
  SliderParagraph,
  ControlContainer,
  ImageSlideContainer,
  ImageFigure,
  Img,
  Arrow,
  ArrowContainer,
  ButtonsContainer
}
