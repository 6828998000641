/* @flow */

export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  priceTitleMaxChar: 150,
  perMonthMaxChar: 150,
  priceMaxChar: 7,
  optionMaxChar: 80,
  plans: {
    min: 1,
    max: 6,
    options: {
      min: 0,
      max: 15
    }
  }
}
