/* @flow */
import {
  iconActionMaker,
  linkActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  addLink,
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  changeBlockTitle,
  removeBlock
} from '@website/components/CommonActions'
export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeBlockTitle = (newTitle, idx) => {
    dispatch(changeBlockTitle(newTitle, idx))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addLink: idx => dispatch(addLink(idx)),
    addBlock: () => dispatch(addBlock()),
    removeBlock,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle: _changeBlockTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    iconActions: idx => [
      ...iconActionMaker(dispatch)({
        idx,
        showRemove: false
      })
    ],
    linkActions: linkActionMaker(dispatch),
    blockActions: removeBlockActionMaker(dispatch)
  }
}
