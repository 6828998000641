//@flow
import React, { memo } from 'react'
import { translate } from '@editor/common/utils/translations'

import SearchContent from '../SearchContent'
import ResultContent from '../ResultContent'
import type { TProps } from './types'
import * as Styled from './styled'

const SemrushKeyword = ({
  hasResults,
  wrapperRef,
  onScroll,
  commonProps,
  searchPageProps,
  resultsPageProps
}: TProps) => (
  <Styled.SemrushKeyword ref={wrapperRef} onScroll={onScroll}>
    {hasResults ? (
      <ResultContent {...commonProps} {...resultsPageProps} />
    ) : (
      <>
        <Styled.Heading>
          {translate('semrush_keyword_magic_tool_title')}
        </Styled.Heading>
        <SearchContent {...commonProps} {...searchPageProps} />
      </>
    )}
  </Styled.SemrushKeyword>
)

export default memo(SemrushKeyword)
