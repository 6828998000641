/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_ICON':
        const { idx: idx_, iconName } = action.value
        draft.blocks[idx_].iconName = iconName
        break
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.blocks[idx].iconName = ''
        break
      }
      case 'ADD_ICON':
        draft.blocks[action.value].iconName = 'edit'
        break
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        return
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.blocks[blockIdx].blockTitleAlignment = alignment
        break
      }
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        return
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        return
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
