// @flow
import React, { memo, useCallback } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TOptionsProps } from './types'
import useAuthCallback from '@hooks/useAuthCallback'

import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon.svg'
import SettingIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'
import DuplicateIcon from '@editor/common/assets/svgr-icons/page_duplicate_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { useResponsive } from '@shared/hooks'
import { SettingsIcon } from './styled'

const OptionItem = memo(({ tooltipText, icon, onClick }) => {
  const isMobile = useResponsive(768)

  return isMobile ? (
    <Styled.OptionItem role="button" onClick={onClick}>
      {icon}
    </Styled.OptionItem>
  ) : (
    <TooltipWrapper text={tooltipText}>
      <Styled.OptionItem role="button" onClick={onClick}>
        {icon}
      </Styled.OptionItem>
    </TooltipWrapper>
  )
})

const Options = ({
  pagePath,
  removePage,
  duplicatePage,
  navigateToSettings
}: TOptionsProps) => {
  const isHome = pagePath === '/home'
  const originalPath = isHome ? '/' : pagePath

  const handleDuplicate = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      duplicatePage(originalPath)
    },
    [originalPath, duplicatePage]
  )

  const handleRemove = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      removePage(originalPath)
    },
    [originalPath, removePage]
  )

  const handleSettingsClick = useAuthCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
      navigateToSettings(originalPath)
    },
    [originalPath, navigateToSettings]
  )

  return (
    <Styled.OptionsContainer>
      <OptionItem
        icon={<DuplicateIcon />}
        tooltipText={translate('duplicate_label')}
        onClick={handleDuplicate}
      />
      <OptionItem
        icon={<Styled.SettingsIcon />}
        tooltipText={translate('settings_label')}
        onClick={handleSettingsClick}
      />
      {!isHome ? (
        <OptionItem
          icon={<DeleteIcon />}
          tooltipText={translate('delete_label')}
          onClick={handleRemove}
        />
      ) : null}
    </Styled.OptionsContainer>
  )
}

export default memo(Options)
