export const ICON_SIZES = {
  xsmall: '0.5rem',
  small: '0.625rem',
  xnormal: '0.8rem',
  normal: '1rem',
  middle: '1.2rem',
  large: '1.5rem',
  xlarge: '2rem',
  xxlarge: '3rem',
  xxmlarge: '4rem',
  xxlLarge: '5rem'
}

export const SOCIAL_ICONS = new Set([
  'icon-youtube',
  'icon-linkedin',
  'icon-vk',
  'icon-vimeo',
  'icon-twitch',
  'icon-odnoklassniki',
  'icon-facebook',
  'icon-pinterest',
  'icon-twitter',
  'icon-instagram',
  'icon-soundcloud',
  'icon-spotify'
])
