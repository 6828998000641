/* @flow */
import { simpleButtonActionMaker } from '@website/components/ActionMakers'
import { changeTitle, changeParagraph } from '@website/components/CommonActions'
export default dispatcher => {
  const changeCode = (newCode: string) => ({
    type: 'CHANGE_CODE',
    value: newCode
  })

  return {
    changeTitle,
    changeCode,
    changeParagraph,
    btnActions: simpleButtonActionMaker(dispatcher)
  }
}
