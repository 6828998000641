/* @flow */

import {
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = (title: string) => dispatcher(changeTitle(title))


  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment
  }
}
