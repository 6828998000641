/* @flow */
import {
  socialActionMaker,
  buttonModalActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addButton
} from '@website/components/CommonActions'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import LinkIcon from '@editor/common/assets/svgr-icons/Link_icon.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeName = (newName: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_NAME',
      value: { newName, idx }
    })
  }

  const changePosition = (newPosition: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_POSITION',
      value: { newPosition, idx }
    })
  }

  const changeItemParagraph = (paragraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_ITEM_PARAGRAPH',
      value: { paragraph, idx }
    })
  }

  const addMember = () => {
    dispatcher({ type: 'ADD_MEMBER' })
  }

  const addSocial = (teamIdx: number) => {
    dispatcher({
      type: 'ADD_SOCIAL',
      value: teamIdx
    })
  }

  const imageActions = (
    teamIdx: number,
    initialUrl: string,
    imageUrl: string
  ) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'team1',
      showRemove: false,
      additionalArgs: { teamIdx },
      imageUrl
    }),
    {
      IconComp: LinkIcon,
      tooltipText: translate('add_link_label'),
      handler: link => {
        dispatcher({
          type: 'CHANGE_IMG_LINK',
          value: { link, teamIdx }
        })
      },
      modal: {
        type: MODAL_TYPES.urlModal,
        props: {
          link: initialUrl,
          type: LINK_TYPES.link
        }
      }
    }
  ]

  const teamActions = (teamIdx: number, toShow: boolean) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () =>
        dispatcher({
          type: 'REMOVE_MEMBER',
          value: teamIdx
        }),
      toShow
    }
  ]

  const socialActions = (
    socialIdx: number,
    socialUrl: string,
    teamIdx: number
  ) => socialActionMaker(dispatcher)(socialIdx, socialUrl, { teamIdx })

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeNameAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changePositionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_POSITION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }  

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeName,
    addMember,
    addSocial,
    addButton,
    changePosition,
    changeItemParagraph,
    teamActions,
    imageActions,
    socialActions,
    btnActions: buttonModalActionMaker(dispatcher),
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNameAlignment,
    changePositionAlignment,
    changeBlockParagraphAlignment
  }
}
