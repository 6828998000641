/* @flow */
import styled, { css, createGlobalStyle } from 'styled-components'

import { projectContainerClassName } from '@website/conf/consts'

import { FLYOUT_HEIGHT } from './consts'

const getFlyoutPositions = ({ buttonEl }) => {
  const scrollable = document.querySelector(`.${projectContainerClassName}`)

  if (!scrollable || !buttonEl) {
    return ''
  }

  const { top: scrollableTop, left: scrollableLeft } =
    scrollable.getBoundingClientRect()
  const { top: buttonTop, left: buttonLeft } = buttonEl.getBoundingClientRect()

  const top = scrollable.scrollTop + buttonTop - scrollableTop - FLYOUT_HEIGHT
  const left = buttonLeft - scrollableLeft

  return css`
    top: ${top}px;
    left: ${left}px;
  `
}

export const GlobalStyles = createGlobalStyle`
  .logo-change-flyout_container {
    ${getFlyoutPositions};
  }
`

export const Figure = styled.div`
  display: block;
  position: relative;
  @media only screen and (max-width: 768px) {
    margin-right: 20px;
  }
  .failed-image-container {
    min-width: 120px;
    min-height: 80px;
  }
`
