/* @flow */
import React, { useContext, memo } from 'react'
import S from './RSVP5.styled'
import RSVP5Card from './RSVP5.card'
import { EditingContext } from '@contexts'
import RSVPRegistrationPopup from '@website/common/components/RSVPRegistrationPopup'
import RSVPForm from '@website/common/components/RSVPForm'
import useEventRegistration from '@website/common/hooks/useEventRegistration'
import { useResponsive } from '@shared/hooks'

const RSVP5 = memo(props => {
  const {
    data: {
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      cardImage,
      subtitleAlignment,
      titleAlignment,
      paragraphAlignment,
      dateAlignment,
      locationAlignment
    },
    eventData,
    uniqId
  } = props

  const eventCardMetaData = {
    cardImage,
    subtitleAlignment,
    titleAlignment,
    paragraphAlignment,
    dateAlignment,
    locationAlignment
  }

  const { isEditing } = useContext(EditingContext)
  const isMobileOrTablet = useResponsive(1024)
  const {
    onClick: onRSVPBtnClick,
    isLoading,
    formOpenStatus,
    additionalGuestCount,
    isRegistrationClosedPopupOpen,
    closeRegistrationClosedPopup,
    setGuestLimit,
    setFormOpenStatus
  } = useEventRegistration(
    eventData.id,
    eventData.form.additionalGuestNumber,
    isMobileOrTablet
  )

  const registrationClosedPopupData = eventData.popups.closed

  return (
    <S.ComponentWrapper
      isEditing={isEditing}
      formOpenStatus={formOpenStatus}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <S.BlocksWrapper>
        <S.StyledContainer
          isEditing={isEditing}
          formOpenStatus={formOpenStatus}
        >
          <S.WmCustomContainer>
            <RSVP5Card
              detailsData={eventData.details}
              metaData={eventCardMetaData}
              onRSVPBtnClick={onRSVPBtnClick}
              isLoading={isLoading}
            />
          </S.WmCustomContainer>
        </S.StyledContainer>
        <RSVPForm
          isEditing={isEditing}
          compId={uniqId}
          eventId={eventData.id}
          formData={eventData.form}
          popupsData={eventData.popups}
          detailsData={eventData.details}
          additionalGuestCount={additionalGuestCount}
          setGuestLimit={setGuestLimit}
          setFormOpenStatus={setFormOpenStatus}
          needToScrollAfterClosing={isMobileOrTablet}
        />
        {!isEditing ? (
          <RSVPRegistrationPopup
            isPopupOpen={isRegistrationClosedPopupOpen}
            title={registrationClosedPopupData.title.text}
            paragraph={registrationClosedPopupData.paragraph.text}
            onClose={closeRegistrationClosedPopup}
          />
        ) : null}
      </S.BlocksWrapper>
    </S.ComponentWrapper>
  )
})

export default RSVP5
