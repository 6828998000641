/* @flow */
/* eslint-disable max-statements */
import React, { useState, useCallback, useContext } from 'react'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import { isValidEmail } from '@website/common/utils'
import * as Styled from './FormContent.styled'
import { complaintMessageApi } from '../api'
import { SiteHashContext, SiteIdContext } from '@contexts'
import ErrorIcon from '@website/common/assets/svgr-icons/error.svg'

import {
  REQUIRED_FIELD_ERROR,
  INVALID_EMAIL_ERROR,
  MAX_TEXT_LIMIT,
  TEXT_LIMIT_ERROR
} from '../consts'

const FormContent = ({ onClose, onSuccess, onError }) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [comment, setComment] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [nameLengthErrorMessage, setNameLengthErrorMessage] = useState('')
  const [commentErrorMessage, setCommentErrorMessage] = useState('')
  const [commentLengthErrorMessage, setCommentLengthErrorMessage] = useState('')
  const siteId = useContext(SiteIdContext)
  const siteHash = useContext(SiteHashContext)

  const handleEmailChange = useCallback((e: SyntheticEvent): void => {
    setEmail(e.target.value)
    setEmailErrorMessage('')
  }, [])

  const handleNameChange = useCallback((e: SyntheticEvent): void => {
    if (e.target.value.length > MAX_TEXT_LIMIT) {
      setNameLengthErrorMessage(TEXT_LIMIT_ERROR)
      return
    }
    setName(e.target.value)
    setNameErrorMessage('')
    setNameLengthErrorMessage('')
  }, [])

  const handleCommentChange = useCallback((e: SyntheticEvent): void => {
    if (e.target.value.length > MAX_TEXT_LIMIT) {
      setCommentLengthErrorMessage(TEXT_LIMIT_ERROR)
      return
    }
    setComment(e.target.value)
    setCommentErrorMessage('')
    setCommentLengthErrorMessage('')
  }, [])

  const handleNamePaste = (e: SyntheticEvent): void => {
    e.preventDefault()
    const copiedText = e.clipboardData.getData('text')
    const currentText = `${name}${copiedText}`
    if (nameErrorMessage) {
      setNameErrorMessage('')
    }
    if (currentText.length > MAX_TEXT_LIMIT) {
      setName(currentText.slice(0, MAX_TEXT_LIMIT))
      setNameLengthErrorMessage(TEXT_LIMIT_ERROR)
    } else {
      setName(currentText)
    }
  }

  const handleCommentPaste = (e: SyntheticEvent): void => {
    e.preventDefault()
    const copiedText = e.clipboardData.getData('text')
    const currentText = `${comment}${copiedText}`
    if (commentErrorMessage) {
      setCommentErrorMessage('')
    }
    if (currentText.length > MAX_TEXT_LIMIT) {
      setComment(currentText.slice(0, MAX_TEXT_LIMIT))
      setCommentLengthErrorMessage(TEXT_LIMIT_ERROR)
    } else {
      setComment(currentText)
    }
  }

  const validateForm = () => {
    let isValid = true

    if (email === '') {
      setEmailErrorMessage(REQUIRED_FIELD_ERROR)
      isValid = false
    } else if (!isValidEmail(email)) {
      setEmailErrorMessage(INVALID_EMAIL_ERROR)
      isValid = false
    }

    if (name === '') {
      isValid = false
      setNameErrorMessage(REQUIRED_FIELD_ERROR)
    }

    if (name.length > MAX_TEXT_LIMIT) {
      isValid = false
    }

    if (comment === '') {
      isValid = false
      setCommentErrorMessage(REQUIRED_FIELD_ERROR)
    }

    if (comment.length > MAX_TEXT_LIMIT) {
      isValid = false
    }

    return isValid
  }

  const sendForm = () => {
    const isValid = validateForm()
    if (!isValid) {
      return
    }
    complaintMessageApi(siteId, {
      email,
      name,
      comment,
      siteHash
    })
      .then(() => {
        onSuccess()
        onClose()
      })
      .catch(err => {
        onError(err.message, err.status)
        onClose()
      })
  }

  return (
    <>
      <Styled.FormContentWrapper>
        <Styled.Title>What’s wrong with this website?</Styled.Title>
        <Styled.Paragraph>
          Please complete the form below for your complaints and our support
          team will review this and get in touch with you soon.
        </Styled.Paragraph>
        <Styled.FieldContainer hasValidationError={!!emailErrorMessage}>
          <Styled.Label>E-mail</Styled.Label>
          <Styled.Input
            placeholder="Please enter your email"
            onChange={handleEmailChange}
            value={email}
          />
          <Styled.FormMessage>{emailErrorMessage}</Styled.FormMessage>
        </Styled.FieldContainer>
        <Styled.FieldContainer
          hasValidationError={!!nameErrorMessage}
          hasWarning={MAX_TEXT_LIMIT - name.length < 6}
          hasLimitError={!!nameLengthErrorMessage}
        >
          <Styled.Label>Name</Styled.Label>
          <Styled.Input
            placeholder="Please enter your full name"
            value={name}
            onChange={handleNameChange}
            onPaste={handleNamePaste}
          />
          <Styled.FormMessage>{nameErrorMessage}</Styled.FormMessage>

          <If
            condition={!!nameLengthErrorMessage}
            then={() => (
              <Styled.LimitTextMessageContainer>
                <Styled.LimitTextMessageWrap>
                  <Icon size="12" color="#ff4c4c" SvgComp={ErrorIcon} />
                  <p>{nameLengthErrorMessage}</p>
                </Styled.LimitTextMessageWrap>
              </Styled.LimitTextMessageContainer>
            )}
          />
        </Styled.FieldContainer>
        <Styled.FieldContainer
          className="comment-field-coontainer"
          hasValidationError={!!commentErrorMessage}
          hasWarning={MAX_TEXT_LIMIT - comment.length < 6}
          hasLimitError={!!commentLengthErrorMessage}
        >
          <Styled.Label>Comment</Styled.Label>
          <Styled.TextArea
            placeholder="Please write a clear explanation of how this website is disturbing you"
            value={comment}
            onChange={handleCommentChange}
            onPaste={handleCommentPaste}
          />
          <Styled.FormMessage className="required-comment">
            {commentErrorMessage}
          </Styled.FormMessage>
          <Styled.LimitTextMessageContainer>
            <If
              condition={!!commentLengthErrorMessage}
              then={() => (
                <Styled.LimitTextMessageWrap className="comment-limit-message">
                  <Icon size="12" color="#ff4c4c" SvgComp={ErrorIcon} />
                  <p>{commentLengthErrorMessage}</p>
                </Styled.LimitTextMessageWrap>
              )}
            />
            <Styled.LimitTextCharacterWrap>
              {comment.length}/{MAX_TEXT_LIMIT}
            </Styled.LimitTextCharacterWrap>
          </Styled.LimitTextMessageContainer>
        </Styled.FieldContainer>
        <Styled.ButtonsWrapper>
          <Styled.CancelButton onClick={onClose}>Cancel</Styled.CancelButton>
          <Styled.SendButton onClick={sendForm}>SEND</Styled.SendButton>
        </Styled.ButtonsWrapper>
        <Styled.InfoMessage>
          By sending, you confirm that the information provided is correct. You
          also understand that Renderforest has no duty to take any action.
        </Styled.InfoMessage>
      </Styled.FormContentWrapper>
    </>
  )
}

export default FormContent
