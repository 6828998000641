export const validation = {
  titleMaxChar: 60,
  paragraphMaxChar: 800,
  blockTitleMaxChar: 20,
  blockParagraphMaxChar: 30,
  title: { required: true },
  images: {
    min: 1,
    max: 2
  },
  blocks: {
    min: 0,
    max: 3
  }
}
