// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import { TTabContentProps } from './types'
import { UPGRADE_LABEL } from '@containers/edit/containers/settings/consts'

const TabContent = ({
  imgSrc,
  title,
  description,
  showUpgradeLabel
}: TTabContentProps) => (
  <Styled.TabContent>
    <img src={imgSrc} alt={title} className="gdpr-tab-img" />
    <Styled.TextBlock>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Description>{description}</Styled.Description>
    </Styled.TextBlock>
    {showUpgradeLabel ? (
      <Styled.UpgradeLabel src={UPGRADE_LABEL} alt="upgrade" />
    ) : null}
  </Styled.TabContent>
)

export default memo(TabContent)
