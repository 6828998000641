/* @flow */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from '@website/common/components/Modal'
import styled from 'styled-components'
import './index.sass'
import { closeWarningModal } from '@actions/editor'
import { isWarningModalOpened } from '@selectors'
import { translate } from '@editor/common/utils/translations'

const WarningModalButton = styled.div`
  border-radius: 50px;
  background-repeat: no-repeat;
  text-decoration: none;
  text-transform: uppercase;
  max-width: 260px;
  padding: 0 40px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  cursor: pointer;
  border: none;
  margin: 50px auto;
  color: white;
  display: block;
`

const mapStateToProps = (state: TAppState) => {
  return {
    warningModalText: isWarningModalOpened(state)
  }
}

class WarningModal extends Component<*, *> {
  content = () => {
    const { warningModalText } = this.props
    return <div className="modal-content-text">{warningModalText}</div>
  }
  footer = () => (
    <WarningModalButton
      className="warning-button"
      onClick={() => this.props.dispatch(closeWarningModal())}
    >
      {translate('ok_got_it_label')}
    </WarningModalButton>
  )
  render() {
    const { dispatch } = this.props

    return (
      <Modal
        renderFooter={this.footer}
        renderContent={this.content}
        onModalClose={() => dispatch(closeWarningModal())}
      />
    )
  }
}

export default connect(mapStateToProps)(WarningModal)
