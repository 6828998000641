/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Steps1.theme'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  hexToRgb,
  getBackgroundStyles
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import { getTextStyleByAlignment } from '@website/common/utils'
const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .slick-slide > div {
    margin: 25px 25px;
    @media only screen and (max-width: 650px) {
      margin: 10px 15px 25px 15px;
    }
  }
  .rf-image-slider {
    @media only screen and (max-width: 550px) {
      width: 100%;
    }
  }
  .rf-image-slider-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    @media only screen and (max-width: 650px) {
      padding: 1rem;
    }
  }
  > :first-child {
    margin-top: 0;
  }
  .add-button-wrap {
    margin-top: 20px;
  }
  .dots-wrap {
    bottom: 37px;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const ImageSlideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  position: relative;
  margin-bottom: -40px;
  @media only screen and (max-width: 1024px) {
    width: calc(100% - 96px);
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
`

const FigureContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin: 0 auto;
`

const FigureImg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  margin-top: 20px;
`

const IconContainer = styled.button`
  padding: 0;
  border: none;
  width: 36px;
  min-width: 36px;
  margin: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${getStyleForProp('iconRadius')};
  ${getStyleForProp('buttonBackground')};
  .left {
    transform: translateX(-1px);
  }
  .right {
    transform: translateX(1px);
  }

  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 760px) {
    display: none;
  }
`

const SliderTitle = styled.h2`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 5px;
`

const SliderParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Dot = styled.button`
  padding: 0;
  border: none;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: 1px solid #5690ff;
  }
`

const TextContainer = styled.div`
  background: white;
  padding: 30px;
  border-bottom-left-radius: ${getStyleForProp('cardRadius')};
  border-bottom-right-radius: ${getStyleForProp('cardRadius')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h2 {
      margin-bottom: 0;
    }
  }
`

const SlideContainer = styled.article`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-bottom-left-radius: ${getStyleForProp('cardRadius')};
  border-bottom-right-radius: ${getStyleForProp('cardRadius')};
  margin: 50px 40px 40px 40px;
  font-size: 0;
  .lazy-figure {
    border-top-left-radius: ${getStyleForProp('cardRadius')};
    border-top-right-radius: ${getStyleForProp('cardRadius')};
  }
  @media only screen and (max-width: 992px) {
    margin: 40px;
  }
  @media only screen and (max-width: 600px) {
    margin: 40px 0 20px 0;
  }
  ${({ length }) => (length === 1 ? 'margin-bottom: 0 !important;' : '')};
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-shadow: ${getStyleForProp('raisedShadow')};
`

export default {
  Dot,
  Title,
  StyledContainer,
  WmCustomContainer,
  Paragraph,
  SliderTitle,
  Img,
  TextContainer,
  ImageSlideContainer,
  FigureContainer,
  FigureImg,
  IconContainer,
  ImageContainer,
  SlideContainer,
  SliderParagraph
}
