export default {
  DARK: {
    dotsBackground: p => p.accentWhite,
    dots: p => p.accentWhite,
    date: p => p.accentWhite,
    time: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    dotsBackground: p => p.accentWhite,
    dots: p => p.primaryDark,
    date: p => p.primaryDark,
    time: p => p.primaryDark,
    background: p => p.tertiaryLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    dotsBackground: p => p.accentWhite,
    dots: p => p.tertiaryDark,
    date: p => p.tertiaryDark,
    time: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
