import styled from 'styled-components'
import THEME from './SingleButton.theme'
import { styleProvider } from '../../utils'
const getStyleForProp = styleProvider(THEME)

const ButtonsBlock = styled.div`
  display: flex;
  padding-top: 30px;
  .add-button-wrap {
    > div {
      height: 50px;
      box-sizing: border-box;
      padding: 16px 30px;
    }
  }
`
const Button = styled.div`
  display: flex;
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${getStyleForProp('buttonShadow')};
    ${getStyleForProp('buttonBackground')};
    background-repeat: no-repeat;
    text-decoration: none;
    max-width: 300px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`

export default {
  Button,
  ButtonsBlock
}
