// @flow

import React, { memo } from 'react'
import ReactDOM from 'react-dom'
import * as Styled from './styled'
import type { TFlyOutProps } from './types'
import useRelativeCoordinates from '@website/common/hooks/useRelativeCoordinates'
import { useBackdropClick } from '@hooks'

const FlyOut = ({
  isOpen = false,
  children,
  className = '',
  renderingContainer,
  anchorEl,
  backdropDisablingSelectors,
  onClose
}: TFlyOutProps) => {
  const flyoutRef = useBackdropClick(onClose, backdropDisablingSelectors)

  const coords = useRelativeCoordinates(
    renderingContainer,
    anchorEl,
    flyoutRef,
    { alignmentY: 'bottom', alignmentX: 'left' }
  )

  const containerClassName = className ? `${className}_container` : ''
  return ReactDOM.createPortal(
    <Styled.FlyOutWrapper
      isOpen={isOpen}
      offsets={coords}
      className={className}
    >
      <div ref={flyoutRef} className={containerClassName}>
        <div>{children}</div>
      </div>
    </Styled.FlyOutWrapper>,
    renderingContainer || document.body
  )
}

export default memo(FlyOut)
