/* @flow */
/* eslint-disable max-statements */

import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_SUB_TITLE':
        draft.subTitle = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_SUB_TITLE_ALIGNMENT': {
        draft.subTitleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BUTTON_DATA':
        {
          const { link, text } = action.value.btnData
          draft.btnUrl = link
          draft.btnText = text
        }
        break
      case 'REMOVE_BUTTON':
        draft.btnUrl = ''
        draft.btnText = ''
        break
      case 'ADD_BUTTON':
        draft.btnText = initialState.btnText
        draft.btnUrl = initialState.btnUrl
        break
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'REMOVE_SOCIAL':
        draft.socials.splice(action.value.idx, 1)
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.socials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.socials[idx].icon = iconName
        }
        break
      case 'CHANGE_IMAGE':
        {
          const { slideIdx, imgIdx, url, dimensions } = action.value
          const imgLabel = imgIdx === 0 ? 'img1' : 'img2'
          draft.slides[slideIdx][imgLabel].url = url
          draft.slides[slideIdx][imgLabel].dimensions = dimensions
          draft.slides[slideIdx][imgLabel].imgCropParams = {}
        }
        break
      case 'REMOVE_SLIDE':
        const { slideIdx: slideIdx_ } = action.value
        draft.slides.splice(slideIdx_, 1)
        break
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, slideIdx, imgIdx } = action.value
        const imgLabel = imgIdx === 0 ? 'img1' : 'img2'
        draft.slides[slideIdx][imgLabel].imgCropParams = imgCropParams
      }
    }
  })
}
