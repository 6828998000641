/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.steps[idx].stepTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.steps[idx].stepDescriptionAlignment = alignment
        break
      }
      case 'CHANGE_STEP_DATA': {
        const { idx, keyInStore, data } = action.value
        draft.steps[idx][keyInStore] = data
        break
      }
      case 'CHANGE_IMAGE':
        const { idx, url, dimensions } = action.value
        draft.steps[idx].stepImageUrl = url
        draft.steps[idx].stepImgSizes = dimensions || {}
        draft.steps[idx].imgCropParams = {}
        break
      case 'ADD_BLOCK':
        draft.steps.push(initialState.steps[0])
        break
      case 'REMOVE_BLOCK':
        draft.steps.splice(action.value.idx, 1)
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.steps[idx].imgCropParams = imgCropParams
      }
    }
  })
}
