/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_IMAGE':
        {
          const { blockIdx, idx, url, dimensions } = action.value
          draft.images[blockIdx][idx].imgUrl = url
          draft.images[blockIdx][idx].imgDimensions = dimensions
          draft.images[blockIdx][idx].imgCropParams = {}
        }
        break
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx, blockIdx } = action.value
          draft.images[blockIdx][idx].imgCropParams = imgCropParams
        }
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({ ...initialState.buttons[0], type: btnType })
        } else {
          draft.buttons.push({ ...initialState.buttons[0], type: btnType })
        }
        break
    }
  })
}
