/* @flow */

/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_BLOCK': {
        const { idx } = action.value
        draft.blocks[`block${idx}`] = initialState.blocks[`block${idx}`]
        break
      }
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks[`block${idx}`] = null
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, blockIdx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.blocks[blockIdx].images[idx].imageUrl = url
        draft.blocks[blockIdx].images[idx].imgDimensions = dimensions || {}
        draft.blocks[blockIdx].images[idx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx, blockIdx } = action.value
        draft.blocks[blockIdx].images[idx].imgCropParams = imgCropParams
        break
      }
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_SUBTITLE':
        draft.subTitle = action.value
        break
      case 'CHANGE_SUBTITLE_ALIGNMENT':
        draft.subTitleAlignment = action.value
        break
      case 'CHANGE_PARAGRAPH1':
        draft.blocks['block2'].paragraph1 = action.value
        break
      case 'CHANGE_PARAGRAPH1_ALIGNMENT':
        draft.blocks['block2'].paragraph1Alignment = action.value
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        {
          const { url, dimensions } = action.value
          draft.backgroundImgUrl = url
          draft.backgroundImgDimensions = dimensions
        }
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_LINK_DATA':
        const { linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break
      case 'ADD_LINK': {
        draft.additionalUrl = initialState.additionalUrl
        draft.additionalText = initialState.additionalText
        break
      }
      case 'REMOVE_LINK': {
        draft.additionalUrl = ''
        draft.additionalText = ''
        break
      }
      case 'REMOVE_ARROW':
        draft.hasArrow = false
        break
      case 'ADD_ARROW':
        draft.hasArrow = true
        break
      case 'SET_COMPONENT_ANIMATED_STATE':
        draft.isAnimated = !draft.isAnimated
        break
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoWidth = null
        draft.logoDimensions = {}
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'ADD_SOCIAL':
        draft.headerSocials.push(initialState.headerSocials[0])
        break
      case 'REMOVE_SOCIAL':
        draft.headerSocials.splice(action.value.idx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.headerSocials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.headerSocials[idx].icon = iconName
        }
        break
    }
  })
}
