/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PayPal2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import LibIcon from '@website/common/components/Icon'
import PrevIconLeft from '@website/common/assets/svgr-icons/keyboard_arrow_left.svg'
import NextIconRight from '@website/common/assets/svgr-icons/keyboard_arrow_right.svg'

const getStyleForProp = styleProvider(THEME)

const WMCustomContainer = styled(WMContainer)`
  max-width: 1200px;

  .add-button-wrap {
    display: flex;
    justify-content: center;
  }
`

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
        `
      : css``};
  @media only screen and (max-width: 700px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding-top: 70px;
          `
        : css``};
  }
`

const ContentConainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: ${({ theme }) =>
    theme.orientation === 'LEFT' ? 'row' : 'row-reverse'};
  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: ${({ theme }) =>
      theme.orientation === 'LEFT' ? 'column-reverse' : 'column'};
  }
`

const LeftContainer = styled.div`
  width: 100%;
  max-width: 600px;
  ${({ isEditing }) => (isEditing ? 'margin-bottom: 125px' : '0')};
  ${({ hasMargin, isEditing }) =>
    hasMargin && isEditing ? 'margin-bottom: 45px' : '0'};

  ${WMCustomContainer} {
    display: flex;
    flex-direction: column;
  }
  .add-button-wrap {
    text-align: left;
  }
  @media (max-width: 1024px) {
    padding-right: 0px;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .control-container + h1 {
    margin-top: 20px;
  }

  & > .control-container {
    figure {
      padding-bottom: 66.668%;
      border-radius: ${getStyleForProp('cardRadius')};
    }
  }

  & ${WMCustomContainer} {
    padding: 0;
  }
`

const RightContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const RightContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  max-width: 520px;
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? 'padding-left: 80px;'
      : 'padding-right: 80px;'};
  @media (max-width: 1024px) {
    padding-left: 0px;
    width: 100%;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            padding-left: 0px;
            margin-bottom: 40px;
          `
        : css`
            padding-right: 0px;
            margin-top: 40px;
          `};
  }

  .control-container {
    margin-bottom: 30px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
`

const Img = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const ImageFigure = styled.div`
  font-size: 0;
  width: 100%;
  margin: 2px;
  position: relative;
  box-sizing: border-box;
  object-fit: cover;
  .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('title')};
  ${({ isEditing }) =>
    !isEditing ? 'margin-bottom: 20px' : 'margin-bottom: 0'};
  ${getTextStyleByAlignment}
`

const Name = styled.p`
  color: ${getStyleForProp('title')};
  ${({ isEditing }) =>
    !isEditing ? 'margin-bottom: 10px' : 'margin-bottom: 0'};
  ${getTextStyleByAlignment}
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 20px;
`
const PriceCount = styled.p`
  color: ${getStyleForProp('price')};
  opacity: 1;
  margin-bottom: 0;
`

const PrevPriceCount = styled.p`
  color: ${getStyleForProp('price')};
  text-decoration: line-through;
  opacity: 1;
  margin-bottom: 0;
`

const PayButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;
  margin-top: 30px;

  & form > button {
    margin: 0 !important;
  }

  & .add-button-wrap > div {
    margin-top: 0 !important;
  }

  .link-wrapper {
    color: ${getStyleForProp('link')};
    svg {
      fill: ${getStyleForProp('link')};
    }
  }

  .control-container {
    margin-bottom: 0;
  }
`

const RightContainerParagraph = styled.p`
  ${({ isEditing }) => (!isEditing ? 'margin-top: 10px;' : '')}
  color: ${getStyleForProp('title')};
  ${getTextStyleByAlignment};
`

const Count = styled.p`
  color: ${getStyleForProp('iconText')};
  ${getTextStyleByAlignment};
  font-weight: 800;
  width: 100%;
  margin-top: 20px;
`

const WMSliderWrapper = styled.div`
  min-width: 220px;
  position: relative;
  width: calc(100% - 30px);
  max-width: calc(600px - 30px) !important;
  height: 0;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20% !important;

  @media only screen and (max-width: 768px) {
    width: calc(100% - 40px);
    max-width: calc(600px - 40px) !important;
  }

  @media only screen and (max-width: 550px) {
    min-width: auto;
  }

  .slick-prev {
    left: -22px !important;
  }

  .slick-next {
    right: -17px !important;
  }

  .slick-arrow {
    height: 36px;
    display: block !important;
  }

  .slick-list {
    height: 0;
    padding-top: 4px;
    padding-bottom: 22%;
  }

  .add-button-wrap {
    margin-top: 30px;
  }

  .slick-slide > div {
    margin: 0;
  }
`

const InnerSliderItem = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  background: #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  ${({ activeSlideIdx }) =>
    activeSlideIdx
      ? css`
          position: relative;
          &:before {
            content: '';
            position: absolute;
            border-radius: calc(${getStyleForProp('cardRadius')} + 4px);
            background-color: ${getStyleForProp('background')};
            border: 2px solid ${getStyleForProp('link')};
            top: -4px;
            left: -4px;
            right: -4px;
            bottom: -4px;
          }
        `
      : css``}

  & > figure {
    padding-top: 66.67%;
    border-radius: ${getStyleForProp('cardRadius')};

    & > img {
      object-fit: cover;
    }
  }
  &:hover {
    outline: none;
  }
  &:focus figure {
    border: 2px solid;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 768px) {
    margin-left: 5px;
    margin-right: 5px;
  }
`

const SliderCount = styled.p`
  ${({ isEditing }) =>
    isEditing
      ? css`
          text-align: center;
          margin-top: 20px;
          color: ${getStyleForProp('title')};
        `
      : 'display: none'};
`

const LargeImageWrapper = styled.div`
  margin-bottom: 20px;
  figure {
    padding-bottom: 66.668%;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ArrowButton = styled.div``

const PrevIcon = styled(PrevIconLeft)`
  fill: ${getStyleForProp('iconText')};
  width: 24px;
  height: 36px;
  min-width: 20px;
`

const NextIcon = styled(NextIconRight)`
  fill: ${getStyleForProp('iconText')};
  width: 24px;
  height: 36px;
`

export default {
  StyledContainer,
  WMCustomContainer,
  WMSliderWrapper,
  ContentConainer,
  LeftContainer,
  LargeImageWrapper,
  RightContainerParagraph,
  PayButtonsContainer,
  RightContainer,
  RightContent,
  ImageWrapper,
  Img,
  Count,
  InnerSliderItem,
  SliderCount,
  ArrowButton,
  PrevIcon,
  NextIcon,
  ImageFigure,
  PriceContainer,
  PriceCount,
  PrevPriceCount,
  Title,
  Name
}
