/* @flow */
import React from 'react'
import S from './Socials.styled'
import AddButton from '../AddButton'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'

import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import LinkWrapper from '../LinkWrapper'
import Mapper from '../Mapper'

import { socialsMaxCount } from './Socials.consts'
import { EditingContext } from '@contexts'
import { useResponsive } from '@shared/hooks'

const Socials = (props: TNavBar) => {
  const {
    className,
    data: socials,
    layout,
    componentDispatcher
  } = props
  const Actions = ActionGetter(componentDispatcher)
  const isSmallSizes = useResponsive(1024)

  return (
    <EditingContext.Consumer>
      {({ isEditing }) => {
        if (!isEditing && !(socials && socials.length)) {
          return null
        }
        return (
          <S.SocialWrapper className={className}>
            <S.SocialIcons
              layout={layout}
              isEditing={isEditing}
              className="socialIcons"
            >
              <Mapper
                data={socials}
                render={(social, idx) => {
                  return (
                    <ControlsWithModalOpener
                      key={idx}
                      actions={Actions.getSocialActions(idx, social.socialUrl)}
                      style={{ justifyContent: 'center' }}
                      isRelativeToWrapper={isSmallSizes}
                    >
                      <LinkWrapper
                        url={social.socialUrl}
                        linkProps={{ 'aria-label': 'Social link' }}
                      >
                        <Icon size="middle" name={social.icon} />
                      </LinkWrapper>
                    </ControlsWithModalOpener>
                  )
                }}
              />
            </S.SocialIcons>
            <AddButton
              toShow={socials.length < socialsMaxCount}
              onAdd={() => componentDispatcher(Actions.addSocial())}
              type="icon"
              large
              style={{ textAlign: 'left' }}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
            </AddButton>
          </S.SocialWrapper>
        )
      }}
    </EditingContext.Consumer>
  )
}

export default Socials
