//@flow
import React, { memo } from 'react'

import type { TPayPalSettingsPopupProps } from './types'
import PayPalSettingsPopupComp from './PayPalSettingsPopup'
import { ConfirmDialogProvider } from '@contexts/ConfirmDialog.context'
import ConfirmDialog from '@editor/common/components/ConfirmDialog/ConfirmDialog'

const PayPalSettingsPopup = (props): TPayPalSettingsPopupProps => (
  <ConfirmDialogProvider>
    <PayPalSettingsPopupComp {...props} />
    <ConfirmDialog />
  </ConfirmDialogProvider>
)

export default memo(PayPalSettingsPopup)
