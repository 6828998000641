/* @flow */
import {
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeStepData = (data: string, idx: number, keyInStore: string) => ({
    type: 'CHANGE_STEP_DATA',
    value: { data, idx, keyInStore }
  })
  const changeBlockTitle = (title: string, idx: number) => {
    dispatcher(changeStepData(title, idx, 'stepTitle'))
  }

  const changeBlockParagraph = (paragraph: string, idx: number) => {
    dispatcher(changeStepData(paragraph, idx, 'stepParapgraph'))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockStepInfoAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_STEP_INFO_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockStepTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_STEP_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockStepParagraphAlignment = (
    alignment: string,
    idx: number
  ) => {
    dispatcher({
      type: 'CHANGE_STEP_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockYear = (year: string, idx: number) => {
    dispatcher(changeStepData(year, idx, 'stepInfo'))
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addBlock: () => dispatcher(addBlock()),
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitle,
    changeBlockYear,
    changeBlockParagraph,
    changeBlockStepInfoAlignment,
    changeBlockStepTitleAlignment,
    changeBlockStepParagraphAlignment,
    iconActions: iconActionMaker(dispatcher),
    stepActions: removeBlockActionMaker(dispatcher)
  }
}
