/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Wave = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="3000"
    height="335"
    viewBox="0 0 3000 335"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_15723"
          data-name="Rectangle 15723"
          width="3000"
          height="335"
          transform="translate(2163 138)"
        />
      </clipPath>
    </defs>
    <g
      id="Mask_Group_2"
      dataName="Mask Group 2"
      transform="translate(-2163 -138)"
      clipPath="url(#clip-path)"
    >
      <path
        id="Path_9160"
        dataName="Path 9160"
        d="M5970,1981.4s571.03-143.687,1428.011,52.553,1571.989,0,1571.989,0v478.638H5970Z"
        transform="translate(-3807 -1792.662)"
      />
    </g>
  </svg>
)

export default withTheme(Wave)
