// @flow

import React, { useEffect, useState, memo } from 'react'

import AICircle from '@editor/common/assets/AI_circle.svg'

import * as Styled from './styled'
import {
  FINISHED,
  SITE_GENERATING_DURATION_SEC,
  AI_WEBSITE_DATA_FETCHING_STATES
} from './consts'
import AnimationSvg from './Animation.svg'
const AIWebsiteLoader = ({ loadingState }) => {
  const { localStorage } = window
  const currentPercentage =
    parseInt(localStorage.getItem('loadingPercentage')) || 1
  const [loadingPercentage, setLoadingPercentage] = useState(currentPercentage)

  useEffect(() => {
    if (loadingState === FINISHED) {
      setLoadingPercentage(100)
      localStorage.setItem('loadingPercentage', 0)
    }
  }, [loadingState])

  useEffect(() => {
    const percentageRatio = SITE_GENERATING_DURATION_SEC / 100
    if (loadingPercentage >= 99) {
      return
    }

    const intervalId = setInterval(() => {
      setLoadingPercentage(_loadingPercentage => {
        if (_loadingPercentage >= 99) {
          clearInterval(intervalId)
          return _loadingPercentage
        }

        const nextPercent = _loadingPercentage + 1

        localStorage.setItem('loadingPercentage', nextPercent)
        return nextPercent
      })
    }, percentageRatio)

    return () => {
      clearInterval(intervalId)
      localStorage.setItem('loadingPercentage', 0)
    }
  }, [])

  return (
    <Styled.Loader>
      <Styled.GlobalStyle />
      <Styled.TextImageContent>
        <img src={AnimationSvg} className="waveImg" alt="loader" />
        <Styled.ImageWrapper>
          <Styled.AnimationContainer>
            <Styled.LoaderSpinner>
              <Styled.Percentage>{loadingPercentage}%</Styled.Percentage>
              <img src={AICircle} className="first-circle" alt="circle" />
            </Styled.LoaderSpinner>
          </Styled.AnimationContainer>
        </Styled.ImageWrapper>
        <Styled.LoaderText>
          {AI_WEBSITE_DATA_FETCHING_STATES[loadingState]}
        </Styled.LoaderText>
      </Styled.TextImageContent>
    </Styled.Loader>
  )
}

export default memo(AIWebsiteLoader)
