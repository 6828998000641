import React, { useCallback, useRef, useState } from 'react'

const Scrollable = ({ childrenRef, children }) => {
  const contentRef = useRef(null)
  const [showShadow, setShadowState] = useState(false)

  const handleScroll = useCallback(() => {
    const el = childrenRef?.current || contentRef.current

    if (!el) {
      return
    }

    const { scrollTop } = el

    if (scrollTop > 0) {
      setShadowState(true)
      return
    }

    setShadowState(false)
  }, [contentRef.current, childrenRef?.current])

  return (
    <>
      {React.cloneElement(children, {
        showShadow,
        onScroll: handleScroll,
        ref: childrenRef || contentRef
      })}
    </>
  )
}

export default Scrollable
