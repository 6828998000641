import { addScriptToHtml } from '@website/common/utils'

const helpers = {
  livechat: {
    getScriptHtml: chatId => `
                window.__lc = window.__lc || {};
                  window.__lc.license = ${chatId};
                  (function() {
                  var lc = document.createElement('script'); lc.type = 'text/javascript'; lc.async = true;
                  lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
                  var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(lc, s);
                })();`,
    getNoScriptHtml: chatId => `
            <a href="https://www.livechatinc.com/chat-with/${chatId}" rel="nofollow">
                Chat with us
            </a>
            , powered by 
            <a
              href="https://www.livechatinc.com/?welcome"
              rel="noopener nofollow"
              target="_blank"
            >
              LiveChat
            </a>
        `
  },
  freshchat: {
    getScriptHtml: chatId => `
              function initFreshChat() {
                window.fcWidget.init({
                  token: "${chatId}",
                  host: "https://wchat.freshchat.com"
                });
              }
              function initialize(i,t){var e;i.getElementById(t)?initFreshChat():((e=i.createElement("script")).id=t,e.async=!0,e.src="https://wchat.freshchat.com/js/widget.js",e.onload=initFreshChat,i.head.appendChild(e))}function initiateCall(){initialize(document,"freshchat-js-sdk")}initiateCall();`
  },
  intercom: {
    getScriptHtml: chatId => `
              var APP_ID = "${chatId}";
              
              window.intercomSettings = {
                  app_id: APP_ID
                };
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            `
  },
  olark: {
    getScriptHtml:
      chatId => `(function(o,l,a,r,k,y){if(o.olark)return; r="script";y=l.createElement(r);r=l.getElementsByTagName(r)[0]; y.async=1;y.src="//"+a;r.parentNode.insertBefore(y,r); y=o.olark=function(){k.s.push(arguments);k.t.push(+new Date)}; y.extend=function(i,j){y("extend",i,j)}; y.identify=function(i){y("identify",k.i=i)}; y.configure=function(i,j){y("configure",i,j);k.c[i]=j}; k=y._={s:[],t:[+new Date],c:{},l:a}; })(window,document,"static.olark.com/jsclient/loader.js");
              /* custom configuration goes here (www.olark.com/documentation) */
              olark.identify('${chatId}');
            `
  }
}

export const connectChatScript = chats => {
  const primaryChat = chats?.data?.find(chat => chat.isPrimary)
  if (!primaryChat) {
    return
  }
  const _helpers = helpers[primaryChat.name]
  switch (primaryChat.name) {
    case 'livechat':
      {
        const scriptHtml = _helpers.getScriptHtml(primaryChat.key)
        const noScriptHtml = _helpers.getNoScriptHtml(primaryChat.key)
        addScriptToHtml(scriptHtml)
        const noScript = document.createElement('noscript')
        noScript.innerHTML = noScriptHtml
        document.body.appendChild(noScript)
      }
      break
    case 'jivochat':
      {
        const jivoChatScript = document.createElement('script')
        jivoChatScript.src = `//code.jivosite.com/widget/${primaryChat.key}`
        jivoChatScript.async = true
        document.body.appendChild(jivoChatScript)
      }
      break
    case 'olark':
    case 'intercom':
    case 'freshchat':
      {
        const scriptHtml = _helpers.getScriptHtml(primaryChat.key)
        addScriptToHtml(scriptHtml)
      }
      break
    default:
      return null
  }
}

export const getChatScript = chats => {
  const primaryChat = chats?.data?.find(chat => chat.isPrimary)
  if (!primaryChat) {
    return ''
  }
  const _helpers = helpers[primaryChat.name]
  switch (primaryChat.name) {
    case 'livechat': {
      const scriptHtml = _helpers.getScriptHtml(primaryChat.key)
      const noScriptHtml = _helpers.getNoScriptHtml(primaryChat.key)

      return `
        <script type="text/javascript">${scriptHtml}</script>
        <noscript>${noScriptHtml}</noscript>
      `
    }
    case 'jivochat':
      return `<script type="text/javascript" async src="//code.jivosite.com/widget/${primaryChat.key}"></script>`
    case 'freshchat':
    case 'intercom':
    case 'olark': {
      const scriptHtml = _helpers.getScriptHtml(primaryChat.key)
      return `<script type="text/javascript">${scriptHtml}</script>`
    }
    default:
      return ''
  }
}
