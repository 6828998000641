/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Text22.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const LARGE_DESCTOP_SIZE = 1440
const LARGE_SIZE = 1024
const TEMPLATE_TABLET_SIZE = 800
const TABLET_SIZE = 768
const MOBILE_SIZE = 457

export const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 150px;
          @media only screen and (max-width: 700px) {
            padding-top: 100px;
          }
        `
      : ''};
`

export const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 175px;
  @media only screen and (max-width: ${LARGE_DESCTOP_SIZE}px) {
    column-gap: 94px;
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    max-width: 1200px;
    flex-direction: column-reverse;
    align-items: center;
  }

  .control-container {
    margin-bottom: 30px;
    width: 100%;

    h1,
    p {
      margin-top: 0;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    margin-right: 0;
  }
  @media only screen and (max-width: ${TEMPLATE_TABLET_SIZE}px) {
    max-width: 100%;
  }
`

export const ControlWrapper = styled.div`
  flex: 1 1 24%;
  max-width: 33%;
  display: flex;
  justify-content: center;

  & > .control-container {
    width: 100%;
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    width: 80%;
  }
  @media only screen and (max-width: ${TEMPLATE_TABLET_SIZE}px) {
    width: 100%;
    max-width: 100%;
  }
`

export const BlockContainer = styled.div`
  @media only screen and (max-width: ${TEMPLATE_TABLET_SIZE}px) {
    width: 100%;
  }
`

export const BlockWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  border-top: 1px solid ${getStyleForProp('paragraph')};
  margin-top: 40px;
  padding-top: 40px;
  column-gap: 20px;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    margin-top: 40px;
    justify-content: center;
    padding-right: 0;
  }
  @media only screen and (max-width: ${TEMPLATE_TABLET_SIZE}px) {
    max-width: 100%;
    row-gap: 30px;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    min-width: 300px;
    margin-top: 30px;
    padding-top: 30px;
  }
  & > .control-container {
    margin-bottom: 0;
  }
  .add-button-wrap {
    margin: auto auto auto 20px;
  }
`

export const BlockItem = styled.div`
  width: 100%;
  text-align: center;
  ${({ isEditing }) => (isEditing ? 'padding: 20px' : '')};
  box-sizing: border-box;
  & > p:first-child {
    margin-top: 0;
  }
  & > .control-container:nth-last-child(1) {
    margin-bottom: 0;
  }
  @media only screen and (max-width: ${TEMPLATE_TABLET_SIZE}px) {
    padding: ${({ isEditing }) => (isEditing ? '20px' : '0')};
  }
`

export const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
  & + p {
    margin-top: 10px;
  }
`

export const BlockParagraph = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;

  & + p {
    margin-top: 20px;
  }
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.6;
`

export const ImageWrap = styled.div`
  width: 100%;
  max-width: 414px;
  font-size: 0;
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: ${LARGE_SIZE}px) {
    width: inherit;
    max-width: inherit;
    min-width: 400px;
    width: 100%;
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 600px) {
    min-width: auto;
  }

  & > svg {
    fill: ${getStyleForProp('blockTitle')};
    position: absolute;
    z-index: 2;
    right: 20px;
    top: 0px;
    width: 100px;
    height: 100px;
    @media only screen and (max-width: ${LARGE_SIZE}px) {
      right: calc(50% - 176px);
    }
    @media only screen and (max-width: ${MOBILE_SIZE}px) {
      right: calc(50% - 130px);
      width: 70px;
      height: 70px;
    }
  }
`

export const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

export const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  max-width: 347px;
  margin: 14px auto 0;
  border-radius: 250px 250px 0 0;
  border-bottom-left-radius: ${getStyleForProp('cardRadius')};
  border-bottom-right-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 600px) {
    width: 90%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 453px;
    @media only screen and (max-width: 600px) {
      padding-top: 130%;
    }
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: 250px 250px 0 0;
    border-bottom-left-radius: ${getStyleForProp('cardRadius')};
    border-bottom-right-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    & > img {
      width: 100%;
      height:100%;
      object-fit: cover;
    }
  }
  .control-container {
    margin-bottom: 0;
  }
`
export const NetContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
  opacity: 0.5;
  & > svg > path {
    stroke: ${getStyleForProp('paragraph')};
  }
  & > svg:nth-last-child(1) {
    display: none;
    @media only screen and (max-width: ${LARGE_SIZE}px) {
      display: block;
    }
    @media only screen and (max-width: ${TABLET_SIZE - 1}px) {
      display: none;
    }
  }
`
