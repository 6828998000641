/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Contact9.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  .control-container {
    width: 100%;
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 779px) {
        padding-top: 150px;
      }
    `}
`
const WMCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > .control-container:first-child {
    max-width: 700px;
    margin-bottom: 50px;
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
    }
    h1 {
      margin-bottom: 0;
    }
  }
`

const FeatureItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -20px auto -30px auto;
  ${({ areFourBlock }) =>
    areFourBlock &&
    css`
      @media only screen and (min-width: 1135px) {
        > div:nth-child(4) {
          .styled-controls {
            right: 0;
            left: initial;
          }
        }
      }
    `}
  @media only screen and (max-width: 1134px) and (min-width: 769px) {
    > div:nth-child(3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
  }
`

const ItemWrap = styled.div`
  padding: 20px;
  box-sizing: border-box;
  word-break: break-word;
  width: ${({ isMoreThanFour }) => (isMoreThanFour ? '30%' : '23%')};
  min-width: 250px;
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  > .control-container {
    padding-top: 30px;
    padding-bottom: 30px;
    .control-container {
      margin-bottom: 20px;
      h2,
      p {
        margin-bottom: 0;
      }
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  max-width: 700px;
  margin: 0 auto 50px auto;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const BlockTitle = styled.h2`
  width: 100%;
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  margin-bottom: ${({ isEditing }) => (isEditing ? '16px' : '10px')};
`

const A = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 10px;
  transition: all 0.1s linear;
  &:hover {
    opacity: 0.7;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  FeatureItemWrap,
  Title,
  BlockTitle,
  BlockParagraph,
  ItemWrap,
  A
}
