import { CURRENT_LANGUAGE } from '@editor/conf/consts'

// TODO: change isUpper to an option like { isUpper: boolean }
export function translate(key, isUpper = false) {
  const translatedLabel =
    (typeof window !== 'undefined' && window?.translations?.[key]) || ''

  if (isUpper) {
    return translatedLabel.toUpperCase()
  }

  return translatedLabel
}

export const getLocalizedURL = url =>
  CURRENT_LANGUAGE === 'en' ? url : `/${CURRENT_LANGUAGE}${url}`
