/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature3.styled'
import { validation } from './Feature3.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { translate } from '@editor/common/utils/translations'

const Feature3 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      featureBlocks,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WMCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.headingMaxChar}
          onChange={(newTitle: string) => Actions.changeTitle(newTitle)}
          className="WM_GLOBAL_heading"
          changeAlignment={Actions.changeTitleAlignment}
        />

        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Description}
          maxCount={validation.paragraphMaxChar}
          onChange={(newParagraph: string) =>
            Actions.changeParagraph(newParagraph)
          }
          className="WM_GLOBAL_paragraph"
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.BlocksWrap
          blockCount={featureBlocks.length}
          isForBlock={featureBlocks.length === 4}
        >
          <Mapper
            data={featureBlocks}
            render={(featureBlock, idx) => {
              return (
                <Show
                  key={idx}
                  when={[
                    featureBlock.primaryTitle,
                    featureBlock.secondaryTitle,
                    featureBlock.additionalText,
                    featureBlock.additionalUrl
                  ]}
                >
                  <S.FeatureItem isForBlock={featureBlocks.length === 4}>
                    <ControlsWithModalOpener
                      actions={Actions.featureActions({
                        idx,
                        showRemove:
                          featureBlocks.length !== validation.featureBlocks.min
                      })}
                    >
                      <EditableContent
                        text={featureBlock.primaryTitle}
                        alignment={featureBlock.primaryTitleAlignment}
                        as={S.BlockCount}
                        maxCount={validation.blockTitleMaxChar}
                        onChange={(newBlockCountTitle: string) =>
                          Actions.changeBlockPrimaryTitle(
                            newBlockCountTitle,
                            idx
                          )
                        }
                        className="WM_GLOBAL_primary-font"
                        changeAlignment={(alignment: string) =>
                          Actions.changePrimaryTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        text={featureBlock.secondaryTitle}
                        alignment={featureBlock.secondaryTitleAlignment}
                        as={S.BlockCountTitle}
                        maxCount={validation.blockSubTitleMaxChar}
                        onChange={(newBlockCountTitle: string) =>
                          Actions.changeBlockSecondaryTitle(
                            newBlockCountTitle,
                            idx
                          )
                        }
                        className="WM_GLOBAL_primary-font"
                        changeAlignment={(alignment: string) =>
                          Actions.changeSecondaryTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        text={featureBlock.description}
                        alignment={featureBlock.descriptionAlignment}
                        as={S.BlockDescription}
                        maxCount={validation.blockParagraphMaxChar}
                        onChange={(newContent: string) =>
                          Actions.changeBlockContent(idx, newContent)
                        }
                        className="WM_GLOBAL_secondary-font"
                        changeAlignment={(alignment: string) =>
                          Actions.changeDescriptionAlignment(alignment, idx)
                        }
                      />
                      <AdditionalLink
                        idx={idx}
                        additionalLinkText={featureBlock.additionalText}
                        additionalLinkUrl={featureBlock.additionalUrl}
                      />
                    </ControlsWithModalOpener>
                  </S.FeatureItem>
                </Show>
              )
            }}
          />
        </S.BlocksWrap>
        <AddButton
          style={{ marginTop: '2rem' }}
          onAdd={Actions.addBlock}
          toShow={featureBlocks.length < validation.featureBlocks.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature3
