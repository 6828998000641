import React, { memo } from 'react'
import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'
import { SEMRUSH_ICON_URL } from './consts'

const InitialContent = () => (
  <Styled.InitialContent>
    <Styled.Image src={SEMRUSH_ICON_URL} alt="semrush arrow"></Styled.Image>
    <Styled.Text>{translate('semrush_sidebar_initial_view_text')}</Styled.Text>
  </Styled.InitialContent>
)

export default memo(InitialContent)
