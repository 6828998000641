import styled from 'styled-components'

export const getHiddenWrapper = Wrapper => styled(Wrapper)`
  height: 0px;
  visibility: hidden;
  overflow: hidden;
  & * {
    height: 0;
  }
`
