import styled, { createGlobalStyle } from 'styled-components'
import { white, primary, secondary } from '@website/common//styles/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'
import StartCreationBackground from '@editor/common/assets/start-page-background.png'

const stylesObj = {
  de: {
    cardHeight: '129px'
  },
  default: {
    cardHeight: '104px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const GlobalStyles = createGlobalStyle`
  .tutorial-modal {
    .rf-modal-wrap {
      background: none;
      max-width: unset !important;
      width: 70%;
      height: 70%;
      @media only screen and (max-width: 600px) {
        width: 100%;
        height: 50%;
        transform: scale(0.8);
      }
      .rf-modal-Content {
        height: 100%;        
      }
    }
  }
`

export const WMStartCreation = styled.div`
  width: 100%;
  height: 100%;
  min-height: 680px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  background: url(${StartCreationBackground}) no-repeat;
  background-size: cover;

  @media only screen and (max-width: 992px) {
    min-height: 580px;
  }
`
export const CreationHeading = styled.div`
  width: 90%;
  margin: 160px auto 0 auto;
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  color: ${secondary.Dark};
  span {
    font-weight: 700;
  }
  @media only screen and (max-width: 992px) {
    font-size: 20px;
    margin: 60px auto 0 auto;
  }
`
export const CreationParagraph = styled.div`
  width: 90%;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: ${secondary.SemiDark};
  margin: 15px auto 50px auto;
  span {
    font-weight: 700;
  }
  @media only screen and (max-width: 992px) {
    font-size: 14px;
    margin: 10px auto 30px auto;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

export const CreationButton = styled.div`
  width: 375px;
  height: ${getStylesByLang('cardHeight')};
  display: flex;
  gap: 22px;

  align-items: center;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 82, 224, 0.12);
  border: 2px solid ${primary.Light};
  border-radius: 16px;
  background-color: ${white};
  padding: 22px;
  cursor: pointer;
  @media only screen and (max-width: 992px) {
    width: 100%;
    max-width: 350px;
    height: 140px;
    flex-direction: row;
    height: initial;
    padding: 20px 14px;
  }
  .plus-icon-wrapper {
    width: 52px;
    height: 52px;
    border-radius: 12px;
    background: #eceefb;
  }
  .pen-icon-wrapper {
    width: 52px;
    height: 52px;
    border-radius: 12px;
    background: #eceefb;
  }
  &:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 82, 224, 0.18);

    @media only screen and (min-width: 1025px) {
      .plus-icon {
        transition-duration: 0.5s;
        transform: rotate(90deg);
      }
      .pen-icon {
        transition-duration: 0.5s;
        transform: rotate(-90deg);
      }
      .line {
        width: 28px;
      }
    }
  }
`

export const AICreationButton = styled.div`
  width: 375px;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 22px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px 0px rgba(0, 82, 224, 0.12);
  border: 2px solid ${primary.Light};
  border-radius: 16px;
  background-color: ${white};
  padding: 22px;
  cursor: pointer;

  @media only screen and (max-width: 992px) {
    width: 100%;
    max-width: 350px;
    height: initial;
    padding: 20px 14px;
  }

  &:hover {
    box-shadow: 0 2px 12px 0 rgba(0, 82, 224, 0.18);

    .first-template {
      transform: translateY(-207px);
    }
    .second-template {
      transform: translateY(207px);
    }

    .first-circle,
    .second-circle,
    .third-circle,
    .fourth-circle,
    .fifth-circle {
      transform: scale(1.7);
    }
  }
`

export const TextsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 992px) {
    max-width: 280px;
  }
`

export const ButtonTitle = styled.div`
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  color: ${secondary.Dark};
  margin-bottom: 5px;
  @media only screen and (max-width: 992px) {
    font-size: 16px;
    margin-bottom: 5px;
    text-align: initial;
  }
`

export const ButtonParagraph = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${secondary.SemiDark};
  @media only screen and (max-width: 992px) {
    text-align: initial;
  }
`

export const AIImage = styled.img`
  box-shadow: 0 3px 6px rgba(0, 82, 224, 0.2);
  border-radius: 12px;
  width: 299px;
  height: 114px;
`

export const Line = styled.div`
  position: absolute;
  bottom: 5px;
  left: 12px;
  height: 2px;
  width: 0;
  transition: width 0.5s;
  background-color: #766de8;
  margin-top: 2px;
`

export const IconWrapper = styled.div`
  position: relative;
  width: 52px;
  height: 52px;
  border-radius: 12px;
  background: #eceefb;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transition-duration: 0.5s;
    transform: rotate(0deg);
  }
  @media only screen and (max-width: 992px) {
    width: 36px;
    height: 36px;

    img {
      width: 32px;
      height: 32px;
    }
  }
`

export const Divider = styled.div`
  width: 40px;
  text-align: center;
  color: ${secondary.SemiLight};
  position: relative;
  margin: auto 22px;
  @media only screen and (max-width: 992px) {
    margin: 14px auto 11px auto;
  }
  &:after,
  &:before {
    content: '';
    position: absolute;
    width: 2px;
    height: 65px;
    left: 50%;
    transform: translateX(-50%);
    @media only screen and (max-width: 992px) {
      width: 65px;
      height: 2px;
      top: 8px;
      bottom: initial;
      transform: translateX(0);
    }
  }
  &:after {
    top: -75px;
    background: transparent linear-gradient(0deg, #d2e0fc 0%, #ffffff 100%) 0%
      0% no-repeat;
    @media only screen and (max-width: 992px) {
      right: -65px;
      top: 8px;
      left: unset;
      bottom: initial;
      background: transparent linear-gradient(to right, #d2e0fc, #ffffff) 0% 0%
        no-repeat;
    }
  }
  &:before {
    bottom: -75px;
    background: transparent linear-gradient(180deg, #d2e0fc 0%, #ffffff 100%) 0%
      0% no-repeat;
    @media only screen and (max-width: 992px) {
      background: transparent linear-gradient(to right, #ffffff, #d2e0fc) 0% 0%
        no-repeat;
      left: -65px;
    }
  }
`

export const CreationButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 600px;
  margin: 0 auto;
  gap: 32px;
`

export const AICard = styled.div`
  position: relative;
  width: 330px;
  height: 127px;
  border-radius: 12px;
  overflow: hidden;

  .first-circle,
  .second-circle,
  .third-circle,
  .fourth-circle,
  .fifth-circle,
  .first-template,
  .second-template {
    position: absolute;
    transition: transform 2s ease;
  }

  .fifth-circle,
  .second-template {
    z-index: 1;
  }

  .first-template,
  .second-template {
    width: 114px;
    height: 312px;
  }

  .ai-circle {
    position: absolute;
    z-index: 6;
    top: 34px;
    left: 17px;
  }

  .first-circle {
    z-index: 5;
    top: 34px;
    left: 21px;
    width: 60px;
    height: 60px;
  }

  .second-circle {
    z-index: 4;
    top: 23px;
    left: 10px;
    width: 82px;
    height: 82px;
  }

  .third-circle {
    z-index: 3;
    top: 10px;
    left: -3px;
    width: 108px;
    height: 108px;
  }

  .fourth-circle {
    z-index: 2;
    top: -1px;
    left: -14px;
    width: 130px;
    height: 130px;
  }

  .fifth-circle {
    top: -11px;
    left: -24px;
    width: 150px;
    height: 150px;
  }

  .first-template {
    right: 104px;
    top: 16px;
    z-index: 5;
  }

  .second-template {
    right: -20px;
    bottom: 5px;
  }
`
