import styled from 'styled-components'

import { styles } from '@website/common/styles'

export const IconButton = styled.button`
  ${styles.noSelect};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  box-sizing: border-box;
  max-width: 100%;
  border: none;
`
