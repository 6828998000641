/* @flow */
import {
  cropableImageModalActionMaker,
  clientLogoActionMaker,
  removeBlockActionMaker,
  iconActionMaker,
  paypalButtonModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitleAlignment,
  changeParagraph,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import { addIcon, addBlock } from '@website/components/CommonActions'

export default dispatcher => {
  const addLogo = () => {
    dispatcher({
      type: 'ADD_CLIENT_LOGO'
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockName = (name: string, blockIdx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_NAME',
      value: { blockIdx, name }
    })

  const changeBlockText = (text: string, blockIdx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_TEXT',
      value: { blockIdx, text }
    })

  const changeBlockDescription = (description: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_DESCRIPTION',
      value: { blockIdx, description }
    })
  }

  const changeBlockNameAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_NAME_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  const changeBlockDescriptionAlignment = (
    alignment: string,
    blockIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_DESCRIPTION_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  const logoActions = (imageUrl: string, link: string) =>
    clientLogoActionMaker(dispatcher)({
      showRemove: true,
      idx: null,
      keyForSizes: 'payPal1Logo',
      imageUrl,
      link
    })

  const getLargeImgActions = (imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'payPal1Main',
      showRemove: false,
      imageUrl
    })

  return {
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraph: _changeParagraph,
    changeParagraphAlignment: _changeParagraphAlignment,
    addIcon: idx => dispatcher(addIcon(idx)),
    addBlock: () => dispatcher(addBlock()),
    addLogo,
    changeBlockText,
    changeBlockName,
    changeBlockDescription,
    changeBlockNameAlignment,
    changeBlockTitleAlignment,
    changeBlockDescriptionAlignment,
    logoActions,
    getLargeImgActions,
    iconActions: iconActionMaker(dispatcher),
    blockActions: removeBlockActionMaker(dispatcher),
    paypalButtonModalActions: paypalButtonModalActionMaker(dispatcher)
  }
}
