/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header53.theme'
import {
  styleProvider,
  hexToRgbA,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 0;
  ${({ backgroundImgUrl }) =>
    backgroundImgUrl
      ? css`
          @media only screen and (max-width: 769px) {
            background: url(${backgroundImgUrl});
            height: auto;
            background-repeat: no-repeat;
            background-size: auto 180vh;
            background-position: center;
            padding: ${({ isEditing }) => (isEditing ? '90px 0 130px 0' : '')};
          }
          @media only screen and (max-width: 457px) {
            background-size: auto 140vh;
          }
        `
      : ''}
  @media only screen and (max-width: 769px) {
    height: auto;
  }

  .control-container {
    max-width: 1200px;
  }

  .socials-wrapper {
    .control-container:nth-last-child(1) .styled-controls,
    .control-container:nth-last-child(2) .styled-controls {
      right: 0;
      left: unset;
    }
  }

  .socials-block {
    position: sticky;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    backdrop-filter: blur(25px);
    background: ${getStyleForProp('socialBlock')};
    border-top: 1px solid ${getStyleForProp('socialBorder')};
    justify-content: center;
    & .link-wrapper svg {
      fill: ${getStyleForProp('paragraph')};
    }
  }

  .editable-control-container {
    width: 100%;
    margin-bottom: 30px;
  }

  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .buttons-block {
      .control-container {
        width: 100%;
      }
      & > div {
        width: 100%;
      }
      & .link-wrapper {
        width: 100%;
        a {
          width: 100%;
        }
      }
    }
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({
    bgOverlayTransparency,
    isBgImgOrVideoExists,
    ...rest
  }) => {
    const transparency = isBgImgOrVideoExists ? bgOverlayTransparency : 1
    return hexToRgbA(getStyleForProp('backgroundColor')(rest), transparency)
  }};
`

const HeaderFooter = styled.div`
  padding: 10px;
  box-sizing: border-box;
`

const WmCustomContainer = styled(WMContainer)`
  ${({ isEditing }) => (isEditing ? 'margin-top: 10px;' : null)}
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
`

const ContentImage = styled.div`
  margin-bottom: 30px;
`

const Img = styled.img`
  max-width: 250px;
  max-height: 150px;
  width: 100%;
  object-fit: contain;
  @media only screen and (max-width: 500px) {
    max-width: 170px;
  }
`

const HeaderContent = styled.div`
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 80px;
  @media only screen and (max-width: 457px) {
    padding-bottom: 60px;
  }
  & > .control-container:nth-child(1) {
    margin-bottom: 20px;
    width: 100%;

    & > h1 {
      margin-bottom: 0;
    }
  }

  & > .control-container:nth-child(2) {
    margin-bottom: 30px;
    width: 100%;

    & > p {
      margin-bottom: 0;
      max-width: unset;
    }
  }

  .add-button-wrap > div {
    padding: 16px 30px;
  }
`

const supTitle = styled.p`
  color: ${getStyleForProp('supHeader')};
  width: 100%;
  max-width: 1200px;
  margin-bottom: ${({ isEditing }) => (isEditing ? '0' : '20px')};
  ${getTextStyleByAlignment};
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: ${({ isEditing }) => (isEditing ? '0' : '20px')};
  width: 100%;
  max-width: 1200px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: ${({ isEditing }) => (isEditing ? '0' : '20px')};
  width: 100%;
  max-width: 1200px;
  ${getTextStyleByAlignment};
  line-height: 1.4;
  @media only screen and (max-width: 430px) {
    max-width: initial;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  .buttons-block {
    padding-top: 0 !important;
  }
  .buttons-wrapper {
    column-gap: 30px;
    display: flex;
    justify-content: center;
    row-gap: 20px;
  }
`

const Button = styled.div`
  display: flex;
  column-gap: 20px;
  border-radius: ${getStyleForProp('buttonRadius')};
  border: 2px solid ${getStyleForProp('paragraph')};
  color: ${getStyleForProp('paragraph')};
  max-width: 300px;
  height: 50px;
  padding: 0px 30px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
  margin: 0 auto;
  ${({ isEditing }) => (isEditing && 'width: 100%;': '')};
  & > .control-container {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
  }
  & > div:nth-child(1) {
    height: 16px;
    width: 15px;
  }
  & a {
    pointer-events: none;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 195px;
    font-size: 14px;
  }
  & > div {
    transform: translateX(2px);
    & svg {
      fill: ${getStyleForProp('paragraph')};
    }
  }
`

const BottomContent = styled.div`
  z-index: 1;
  margin: auto auto 0 auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DownButton = styled.div`
  padding: 0 20px 20px;
  @media only screen and (max-width: 1200px) {
    padding: 0 20px 10px;
  }
`

const VideoContainer = styled.div`
  pointer-events: none;
  .react-player {
    height: 100% !important;
    overflow: hidden;
  }
  iframe {
    transform: scale(1.33);
    @media only screen and (min-width: 1800px) {
      transform: scale(2);
    }
    @media only screen and (max-width: 1200px) {
      transform: scale(2);
    }
    @media only screen and (max-width: 992px) {
      transform: scale(3);
    }
  }
`

export default {
  HeaderFooter,
  Overlay,
  WmCustomContainer,
  StyledContainer,
  VideoContainer,
  HeaderContent,
  DownButton,
  BottomContent,
  ContentImage,
  Img,
  supTitle,
  Title,
  Paragraph,
  ButtonsContainer,
  Button
}
