import React from 'react'
import IconButton from '@website/common/components/IconButton'
import { ARROW_COLOR, ARROW_SIZE } from './consts.js'

export const PrevArrow = ({ onClick, style, className }) => (
  <div style={style} className={className}>
    <IconButton
      onClick={onClick}
      iconProps={{
        name: 'arrow_small_left',
        color: ARROW_COLOR,
        size: ARROW_SIZE
      }}
    />
  </div>
)

export const NextArrow = ({ onClick, style, className }) => (
  <div style={style} className={className}>
    <IconButton
      onClick={onClick}
      iconProps={{
        name: 'arrow_small_right',
        color: ARROW_COLOR,
        size: ARROW_SIZE
      }}
    />
  </div>
)
