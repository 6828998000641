/* @flow */
import { validation } from '../Feature19.consts'
import {
  iconActionMaker,
  buttonModalActionMaker,
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  addButton,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeListItem = (newItem: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_LIST_ITEM',
      value: { newItem, idx }
    })

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addButton,
    changeTitle: newTitle => dispatcher(changeTitle(newTitle)),
    changeParagraph: newParagraph => dispatcher(changeParagraph(newParagraph)),
    changeListItem,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    featureActions: (idx: number, ftLenght: number) =>
      removeBlockActionMaker(dispatcher)({
        idx,
        showRemove: ftLenght > validation.features.min
      }),
    addNewItem: () => dispatcher({ type: 'ADD_NEW_ITEM' }),
    iconActions: iconActionMaker(dispatcher),
    btnActions: buttonModalActionMaker(dispatcher),
    imgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        showRemove: false,
        keyForSizes: 'feature19',
        imageUrl
      })
  }
}
