/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Countdown1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  .datePicker {
    width: 290px;
    @media only screen and (max-width: 768px) {
      top: initial;
      bottom: -10%;
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
          @media only screen and (max-width: 768px) {
            padding-top: 40px;
          }
        `
      : css``}
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    align-items: center;
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }
  .DayPicker-Day {
    &:focus {
      outline: none !important;
    }
  }
  .DayPicker-Day--selected {
    color: white !important;
    background-color: rgb(56, 125, 255) !important;
    font-weight: 700;
  }
  .DayPicker-Day--today {
    background-color: rgba(56, 125, 255, 0.15) !important;
    font-weight: 500 !important;
    color: initial !important;
  }
  .clockerContainer {
    display: flex;
    justify-content: space-around;
  }
  .DayPicker {
    width: 100%;
  }
  .DayPicker-Day {
    min-width: 36px;
    box-sizing: border-box;
  }
`

const DateContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 650px;
  @media only screen and (max-width: 1024px) {
    max-width: 460px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 320px;
  }
`

const DateTitle = styled.h4`
  color: ${getStyleForProp('heading')};
  text-align: center;
`

const DateWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-right: 6px;
  padding-left: 6px;
  box-sizing: border-box;
  width: 130px;
  @media only screen and (max-width: 650px) {
    padding: 0 4px;
  }
  &:first-child {
    max-width: 210px;
  }

  & > .control-container {
    margin-top: 10px;

    & > p {
      margin-top: 0;
    }
  }

  &:last-child {
    .styled-controls {
      right: 0;
      left: auto;
    }
  }
`

const Time = styled.div`
  width: 114px;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: ${getStyleForProp('time')};
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  background-color: white;
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: ${({ theme }) =>
    theme.look !== 'SHARP'
      ? `0 9px 22px 0 ${`rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`}`
      : ''};
  @media only screen and (max-width: 1024px) {
    font-size: 26px;
    width: 90px;
    height: 80px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 26px;
    width: 60px;
    height: 52px;
  }
  p {
    position: relative;
    z-index: 1;
  }
`

const Date = styled.p`
  width: 100%;
  color: ${getStyleForProp('date')};
  font-weight: 400 !important;
  text-align: center;
  margin-top: 10px;
`

const TimeDotsWrap = styled.div`
  font-weight: 600;
  display: flex;
  justify-content: center;
`

const TimeDots = styled.span`
  color: ${getStyleForProp('dots')};
  font-size: 40px;
  font-weight: 600;
  padding-top: 25px;
  @media only screen and (max-width: 1280px) {
    font-size: 30px;
    padding-top: 30px;
  }
  @media only screen and (max-width: 1024px) {
    padding-top: 22px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 20px;
    padding-top: 13px;
  }
`

export default {
  WmCustomContainer,
  DateContainer,
  StyledContainer,
  DateWrap,
  Time,
  Date,
  DateTitle,
  TimeDotsWrap,
  TimeDots
}
