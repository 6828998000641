// @flow

import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import ErrorSvg from '@website/common/assets/error-circle.svg'
import { translate } from '@editor/common/utils/translations'
import { selectGlobalErrorMessage } from '@selectors'
import { setGlobalErrorMessage } from '@actions/editor'

import * as Styled from './styled'
import type { TErrorPopupProps } from './types'

const ErrorPopup = ({
  errorMessage,
  onClose,
  setGlobalErrorMessage
}: TErrorPopupProps) => {
  const handleClose = useCallback(() => {
    setGlobalErrorMessage('')
    onClose && onClose()
  }, [setGlobalErrorMessage])

  return (
    <Styled.ErrorPopup
      className="global-error-popup"
      isOpen={!!errorMessage}
      onClose={handleClose}
      isMobileFullScreen
    >
      <Styled.ErrorPopupContent>
        <Styled.ErrorImage src={ErrorSvg} alt="Error" />
        <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>
        <Styled.Button onClick={handleClose}>
          {translate('got_it_label')}
        </Styled.Button>
      </Styled.ErrorPopupContent>
    </Styled.ErrorPopup>
  )
}

const mapStateToProps = state => ({
  errorMessage: selectGlobalErrorMessage(state)
})

const mapDispatchToProps = { setGlobalErrorMessage }

export default connect(mapStateToProps, mapDispatchToProps)(ErrorPopup)
