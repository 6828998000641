/* @flow */
import React, { useContext } from 'react'
import { EditingContext } from '@contexts'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'
import Mapper from '@website/common/components/Mapper'
import LibIcon from '@website/common/components/Icon'
import { DispatchContext } from '@contexts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import S from './SocialLinks.styled'

const SocialLinks = React.memo(
  ({ socials, socialsMaxCount, layout }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const isEditing = useContext(EditingContext)

    return (
      <S.SocialsBlock className="socials-block">
        <S.SocialsWrapper
          layout={layout}
          isEditing={isEditing}
          className="socials-wrapper"
        >
          <Mapper
            data={socials}
            render={(social, idx) => (
              <ControlsWithModalOpener
                key={idx}
                actions={Actions.getSocialActions(idx, social.socialUrl)}
                style={{ justifyContent: 'center' }}
              >
                <LinkWrapper
                  url={social.socialUrl}
                  linkProps={{ 'aria-label': 'Social link' }}
                >
                  <LibIcon size="middle" name={social.icon} />
                </LinkWrapper>
              </ControlsWithModalOpener>
            )}
          />
        </S.SocialsWrapper>
        <AddButton
          toShow={socials.length < socialsMaxCount}
          onAdd={Actions.addSocial}
          type="icon"
          large
        >
          <Icon name="glyph-add" className="icon" size="normal" />
        </AddButton>
      </S.SocialsBlock>
    )
  }
)

export default SocialLinks
