// @flow
import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TSavingChanges } from './types'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { selectIsRevisionPending } from '@selectors'
import { translate } from '@editor/common/utils/translations'

const LoadingNutImg =
  'https://static.rfstat.com/renderforest/images/website_maker_images/loading-nut.svg'

const SavingChanges = ({ isExpanded, isSaving }: TSavingChanges) => {
  const [isVisible, changeVisibility] = useState(false)
  const timeoutRef = useRef(null)

  useEffect(() => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }

    if (isSaving) {
      changeVisibility(true)
    } else {
      timeoutRef.current = setTimeout(() => changeVisibility(false), 1000)
    }
  }, [isSaving, timeoutRef.current])

  return (
    <Styled.SavingChanges isExpanded={isExpanded} isVisible={isVisible}>
      {isSaving ? (
        <Styled.LoadingNut>
          <img src={LoadingNutImg} alt="saving" />
        </Styled.LoadingNut>
      ) : (
        <Icon name="check" color="green" size="medium" />
      )}
      {isExpanded ? (
        <Styled.LoadingText>
          {isSaving
            ? translate('saving_changes_label')
            : translate('changes_saved_label')}
        </Styled.LoadingText>
      ) : null}
    </Styled.SavingChanges>
  )
}

const mapStateToProps = state => ({
  isSaving: selectIsRevisionPending(state)
})

export default connect(mapStateToProps)(SavingChanges)
