import React from 'react'
import HomeIcon from '@editor/common/assets/svgr-icons/Home_icon.svg'
import { HomeOptionLabel } from './styled'

export const getPagesDropdownOptions = pages => {
  const homeOptionLabel = (
    <HomeOptionLabel>
      <HomeIcon />
      <span>home</span>
    </HomeOptionLabel>
  )
  return pages.map(({ path }) => ({
    label: path.substr(1) || homeOptionLabel,
    value: path
  }))
}
