/* @flow */
import React, { PureComponent } from 'react'
import { validation } from './Feature12.consts'
import S from './Feature12.styled'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { getBackgroundImage } from '@website/common/utils'
import { withDispatchContext, SiteIdContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import './Feature12.styles.sass'
import { translate } from '@editor/common/utils/translations'

class Feature12 extends PureComponent<void, void> {
  state = { modal: false }

  render() {
    const {
      data: { title, paragraph, features },
      componentDispatcher
    } = this.props
    const Actions = ActionGetter(componentDispatcher)
    return (
      <S.Container className="feature-12-container">
        <SiteIdContext.Consumer>
          {siteId => (
            <>
              <Show when={[title, paragraph]}>
                <div className="feature-12-heading">
                  <EditableContent
                    text={title}
                    as={S.Title}
                    maxCount={validation.headingMaxChar}
                    onChange={(newTitle: string) =>
                      componentDispatcher(Actions.changeTitle(newTitle))
                    }
                    className="WM_GLOBAL_heading"
                  />
                  <EditableContent
                    text={paragraph}
                    as={S.Paragraph}
                    maxCount={validation.paragraphMaxChar}
                    onChange={(newParagraph: string) =>
                      componentDispatcher(Actions.changeParagraph(newParagraph))
                    }
                    className="WM_GLOBAL_paragraph"
                  />
                </div>
              </Show>
              {Object.keys(features).map((key, idx) => {
                const featureRow = features[key]
                return (
                  <S.Row key={idx}>
                    {featureRow.map((featureItem: string, itemIdx: number) => {
                      return (
                        <S.Item
                          className="item-controls-wrapper"
                          key={itemIdx}
                          backgroundImg={getBackgroundImage(
                            featureItem.backgroundImgDimensions,
                            featureItem.backgroundImg
                          )}
                          isEven={itemIdx % 2 !== idx}
                        >
                          <ControlsWithModalOpener
                            actions={Actions.bgActions(
                              featureItem.backgroundImg,
                              key,
                              itemIdx
                            )}
                            style={{ width: '100%' }}
                          >
                            <div className="item-wrapper">
                              <If
                                condition={!!featureItem.icon}
                                otherwise={() => (
                                  <AddButton
                                    onAdd={() =>
                                      componentDispatcher(
                                        Actions.addIcon(key, itemIdx)
                                      )
                                    }
                                    type="icon"
                                    large
                                    toShow
                                    style={{ marginRight: '1rem' }}
                                  >
                                    +
                                  </AddButton>
                                )}
                                then={() => (
                                  <ControlsWithModalOpener
                                    actions={Actions.iconActions(key, itemIdx)}
                                  >
                                    <Icon
                                      size="xxlLarge"
                                      color="white"
                                      name={featureItem.icon}
                                    />
                                  </ControlsWithModalOpener>
                                )}
                              />
                              <EditableContent
                                text={featureItem.title}
                                as={S.ItemTitle}
                                className="WM_GLOBAL_heading32"
                                maxCount={validation.blockTitleMaxChar}
                                onChange={(newTitle: string) =>
                                  componentDispatcher(
                                    Actions.changeBlockTitle(
                                      newTitle,
                                      key,
                                      itemIdx
                                    )
                                  )
                                }
                              />
                              <EditableContent
                                text={featureItem.content}
                                as={S.ItemParagraph}
                                maxCount={validation.blockParagraphMaxChar}
                                className="WM_GLOBAL_paragraph18"
                                onChange={(paragraph: string) =>
                                  componentDispatcher(
                                    Actions.changeBlockParagraph(
                                      paragraph,
                                      key,
                                      itemIdx
                                    )
                                  )
                                }
                              />
                              <S.LinkWrap>
                                <ControlsWithModalOpener
                                  actions={Actions.linkActions(key, itemIdx, {
                                    text: featureItem.additionalText,
                                    url: featureItem.additionalUrl
                                  })}
                                >
                                  <LinkWrapper
                                    url={featureItem.additionalUrl}
                                    text={featureItem.additionalText}
                                    linkProps={{
                                      className: 'WM_GLOBAL_secondary-font'
                                    }}
                                  />
                                </ControlsWithModalOpener>
                                <AddButton
                                  onAdd={() =>
                                    componentDispatcher(
                                      Actions.addLink(key, itemIdx)
                                    )
                                  }
                                  toShow={featureItem.additionalText === ''}
                                >
                                  <Icon
                                    name="glyph-add"
                                    className="icon"
                                    size="normal"
                                  />
                                  {translate('add_link_label')}
                                </AddButton>
                              </S.LinkWrap>
                            </div>
                          </ControlsWithModalOpener>
                        </S.Item>
                      )
                    })}
                  </S.Row>
                )
              })}
              <AddButton
                onAdd={() => componentDispatcher(Actions.addRow())}
                toShow={Object.keys(features).length < validation.features.max}
                style={{ margin: '1rem' }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_block_label')}
              </AddButton>
            </>
          )}
        </SiteIdContext.Consumer>
      </S.Container>
    )
  }
}

const Feature12WithDispatchContext = withDispatchContext(Feature12)

export default Feature12WithDispatchContext
