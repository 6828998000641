/* @flow */

export const validation = {
  nameMaxChar: 30,
  countMaxChar: 8,
  paragraphMaxChar: 300,
  slides: {
    min: 2,
    max: 15
  }
}

export const sliderSettings = {
  dots: false,
  arrows: true,
  autoplaySpeed: 2000,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1
}
