/* @flow */
import styled from 'styled-components'

import THEME from './Feature4.theme'
import { styleProvider } from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 80px 0;
  background-color: ${getStyleForProp('background')};
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    padding: 60px 0;
  }
`

const Title = styled.h4`
  color: ${getStyleForProp('heading')};
  margin-left: 15px;
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  text-align: left;
  margin-left: 15px;
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
`

const ListItem = styled.p`
  color: ${getStyleForProp('paragraph')};
  display: flex;
  align-items: center;
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.secondaryDark
}))`
  padding-right: 10px;
  min-width: 34px;
`

const MainIcon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.primaryDark
}))``

const IconWrap = styled.div`
  width: 360px;
  height: 360px;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  background: ${({ theme }) => theme.palette.accentWhite};
  color: ${({ theme }) => theme.palette.primaryDark};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 500px) {
    width: 280px;
    height: 280px;
  }
`

const ButtonsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    margin: 0 0 0 -15px;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    align-items: center;
  }
  .add-button-wrap {
    margin: 15px;
    @media only screen and (max-width: 992px) {
      margin: 15px 0;
    }
    > div {
      width: 100%;
      height: 50px;
      padding: 16px 30px;
      box-sizing: border-box;
    }
  }
`
const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    align-items: center;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BtnWrapper = styled.div`
  margin: 15px;
  @media only screen and (max-width: 992px) {
    max-width: 300px;
    margin: 15px 0;
  }
  .control-container {
    width: 100%;
  }
  .link-wrapper {
    width: 100%;
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      getStyleForProp(props.isTertiary ? 'raisedShadow' : 'buttonShadow')};
    ${props =>
      getStyleForProp(
        props.isTertiary ? 'buttonBackgroundTertiary' : 'buttonBackground'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    max-width: 300px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
`

export default {
  Container,
  Title,
  Description,
  ListItem,
  IconWrap,
  ButtonsWrap,
  ButtonsContainer,
  ButtonsBlock,
  BtnWrapper,
  MainIcon,
  Icon
}
