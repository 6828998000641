/* @flow */
import React, { memo } from 'react'
import S from './Portfolio13.styled'
import Image from '@website/common/components/Image'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const ImageItem = memo(
  ({
    isEditing,
    alt,
    className,
    imgDimensions,
    imgUrl,
    imgCropParams,
    id,
    imageActions,
    isAnimated,
    onClick
  }) => {
    return (
      <S.ImageFrame className={className} id={id} isEditing={isEditing}>
        <S.ImageWrapper isEditing={isEditing} isAnimated={isAnimated}>
          <S.ControlContainer>
            <ControlsWithImageCropper
              actions={imageActions}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                isEditing={isEditing}
                sizes={imgDimensions}
                defaultImgSrc={imgUrl}
                asProps={{
                  imgCropParams,
                  onClick: onClick
                }}
                alt={alt}
              />
            </ControlsWithImageCropper>
          </S.ControlContainer>
        </S.ImageWrapper>
      </S.ImageFrame>
    )
  }
)

export default ImageItem
