/* @flow */
import React, { useContext, memo } from 'react'
import S from './Steps9.styled'
import { validation } from './Steps9.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Steps9 = memo(props => {
  const {
    data: {
      blocks,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WMCustomContainer>
        <S.BlocksWrap
          areFourBlock={blocks.length === 4}
        >
          <Mapper
            data={blocks}
            render={(block, idx) => (
              <S.BlockItemWrap key={idx}>
                <ControlsWithModalOpener
                  actions={Actions.blockActions({
                    idx,
                    showRemove:
                      blocks.length !== validation.blocks.min
                  })}
                  style={{ alignItems: 'center' }}
                >
                  <S.Order className="WM_GLOBAL_heading72">{idx < 9 ? `0${idx + 1}` : idx + 1}</S.Order>
                  <EditableContent
                    text={block.blockTitle}
                    as={S.BlockTitle}
                    required={!block.blockParagraph}
                    maxCount={validation.blockTitleMaxChar}
                    onChange={(newBlockTitle: string) =>
                      Actions.changeBlockTitle(newBlockTitle, idx)
                    }
                    className="WM_GLOBAL_heading20"
                    alignment={block.blockTitleAlignment}
                    changeAlignment={Actions.changeBlockTitleAlignment(idx)}
                  />
                  <EditableContent
                    text={block.blockParagraph}
                    as={S.BlockParagraph}
                    required={!block.blockTitle}
                    maxCount={validation.blockParagraphMaxChar}
                    onChange={(newBlockParagraph: string) =>
                      Actions.changeBlockParagraph(idx, newBlockParagraph)
                    }
                    className="WM_GLOBAL_paragraph18"
                    alignment={block.blockParagraphAlignment}
                    changeAlignment={Actions.changeBlockParagraphAlignment(idx)}
                  />
                </ControlsWithModalOpener>
              </S.BlockItemWrap>
            )}
          />
        </S.BlocksWrap>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={blocks.length < validation.blocks.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Steps9
