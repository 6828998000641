/* @flow */
import {
  addButton,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeCtaTitle = (newCtaTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_CTA_TITLE',
      value: { newCtaTitle, idx }
    })
  }
  const changeCtaParapgraph = (newCtaParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_CTA_PARAGRAPH',
      value: { newCtaParagraph, idx }
    })
  }
  const removeItem = (idx: number) => {
    dispatcher({
      type: 'REMOVE_ITEM',
      value: idx
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }


  return {
    addButton,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeCtaTitle,
    changeCtaParapgraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    addItem: () => {
      dispatcher({ type: 'ADD_ITEM' })
    },
    ctaActions: (idx: number) => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeItem(idx))
      }
    ]
  }
}
