export const getMaxHeight = elements => {
  let maxHeight = 0
  for (let i = 0; i < elements.length; i++) {
    const elHeight = elements[i].offsetHeight
    if (maxHeight < elHeight) {
      maxHeight = elHeight
    }
  }
  return maxHeight + 1
}

export const setHeight = (elements, height) => {
  for (let i = 0; i < elements.length; i++) {
    if (height) {
      elements[i].style.height = `${height + 1}px` // +1 is for floating point numbers
    }
  }
}
