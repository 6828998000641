/* @flow */
import React, { useContext, memo } from 'react'
import S from './Clients2.styled'
import { validation } from './Clients2.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'
import { openUrl, getExternalUrl } from '@website/common/utils'

const Clients2 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      clientsLogos,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const showRemove = clientsLogos.length > validation.clientsLogos.min

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <S.WmCustomContainer isEditing={isEditing}>
        <Show when={[title, paragraph]}>
          <S.CliensContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.headingMaxChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading"
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Description}
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                className="WM_GLOBAL_paragraph"
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.CliensContent>
        </Show>
        <S.LogosWrap>
          <Mapper
            data={clientsLogos}
            render={(logo: string, logoIdx: number) => {
              return (
                <S.CompanyLogosFigure key={logoIdx}>
                  <ControlsWithModalOpener
                    actions={Actions.getClientsActions({
                      showRemove,
                      logoIdx,
                      keyForSizes: 'clientsLogos',
                      imageUrl: logo.logoUrl,
                      link: logo.clientUrl,
                      withImgSizes: false,
                      mediaFlyoutShift:
                        logoIdx > 5 ? { x: 0, y: -225 } : undefined
                    })}
                  >
                    <Image
                      as={S.Img}
                      alt="Trusted logos"
                      sizes={logo.logoDimensions}
                      defaultImgSrc={logo.logoUrl}
                      asProps={{
                        onClick: () =>
                          !!logo.clientUrl &&
                          openUrl(getExternalUrl(logo.clientUrl))
                      }}
                      hasUrl={!!logo.clientUrl}
                    />
                  </ControlsWithModalOpener>
                </S.CompanyLogosFigure>
              )
            }}
          />
          <AddButton
            onAdd={Actions.addLogo}
            toShow={clientsLogos.length < validation.clientsLogos.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_logo_label')}
          </AddButton>
        </S.LogosWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Clients2
