/* @flow */
import { HEADERS, METHODS } from './ApiConfig'

const SM_API_PATH = 'site-maker-api'
const DEFAULT_LANGUAGE = 'en'
const language =
  (typeof window !== 'undefined' && window.CURRENT_LANGUAGE) || DEFAULT_LANGUAGE

// this interface is useful, f.e., in case of detecting instance type of error
export class ApiError extends Error {
  constructor({ status, message, code }) {
    super(`${message}`)
    this.status = status
    this.code = code
  }
}

const firstStageSuccess = res => {
  if (res.status >= 400) {
    throw new ApiError(res)
  }
  return res
}
const __fetch =
  (method: string) =>
  (path: string, body?: Object, extraHeaders?: Object = {}) => {
    const headers = { ...extraHeaders }
    if (path.includes(SM_API_PATH)) {
      headers.language = language
    }
    return fetch(path, {
      method,
      credentials: 'include',
      headers: { ...HEADERS[method], ...headers },
      ...(!!body ? { body: JSON.stringify(body) } : {})
    })
      .then(res => {
        if (res.status === 204) {
          //204 has no content
          return true
        }

        const totalCount = res.headers.get('x-total-count')

        const response = res.json()

        if (!totalCount) {
          return response
        }

        return response.then(resJson => ({
          ...resJson,
          totalCount: parseInt(totalCount, 10)
        }))
      })
      .then(firstStageSuccess)
      .catch(e => {
        throw new ApiError({ status: 400, message: e.message })
      })
  }

export default METHODS.reduce(
  (acc, meth) => ({ ...acc, [meth]: __fetch(meth) }),
  {}
)
