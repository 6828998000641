/* @flow */
import React, { useContext, memo } from 'react'
import S from './Portfolio10.styled'
import { validation } from './Portfolio10.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import If from '@website/common/components/Conditional'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Portfolio10 = memo(props => {
  const {
    data: { gallery }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.Container isEditing={isEditing}>
      {gallery.map((galleryRow, idx) => {
        return (
          <S.RowWrapper>
            <S.Row key={idx}>
              {galleryRow.map((galleryItem: string, itemIdx: number) => {
                return (
                  <S.Item isEditing={isEditing} key={itemIdx}>
                    <S.ControlContainer>
                      <ControlsWithImageCropper
                        actions={Actions.imageActions(
                          idx,
                          itemIdx,
                          galleryItem.backgroundImg
                        )}
                        key={itemIdx}
                        style={{ width: '100%' }}
                      >
                        <Image
                          as={S.Img}
                          sizes={galleryItem.backgroundImgDimensions}
                          defaultImgSrc={galleryItem.backgroundImg}
                          asProps={{
                            imgCropParams: galleryItem.imgCropParams
                          }}
                          alt="Portfolio image"
                        />
                      </ControlsWithImageCropper>
                    </S.ControlContainer>
                    <S.ItemInfo
                      className="item-info"
                      isEditing={isEditing}
                      withoutTexts={
                        !isEditing &&
                        !(galleryItem.title || galleryItem.additionalText)
                          ? true
                          : false
                      }
                    >
                      <EditableContent
                        text={galleryItem.title}
                        alignment={galleryItem.titleAlignment}
                        as={S.Title}
                        isEditing={isEditing}
                        className="WM_GLOBAL_paragraph14"
                        maxCount={validation.titleMaxChar}
                        onChange={(newTitle: string) => {
                          Actions.changeTitle(newTitle, idx, itemIdx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(
                            alignment,
                            idx,
                            itemIdx
                          )
                        }
                      />
                      <If
                        condition={
                          galleryItem.additionalText &&
                          galleryItem.additionalUrl
                        }
                        otherwise={() => (
                          <AddButton
                            onAdd={() => {
                              Actions.addLink(idx, itemIdx)
                            }}
                            toShow={galleryItem.additionalText === ''}
                          >
                            <Icon
                              name="glyph-add"
                              className="icon"
                              size="normal"
                            />
                            {translate('add_link_label')}
                          </AddButton>
                        )}
                        then={() => (
                          <S.LinkContainer>
                            <ControlsWithModalOpener
                              actions={Actions.linkActions(idx, itemIdx, {
                                text: galleryItem.additionalText,
                                url: galleryItem.additionalUrl
                              })}
                            >
                              <LinkWrapper
                                url={galleryItem.additionalUrl}
                                text={galleryItem.additionalText}
                                linkProps={{
                                  className:
                                    'WM_GLOBAL_paragraph14 learn-more-link'
                                }}
                              >
                                <S.Icon
                                  size="large"
                                  name="keyboard_arrow_right"
                                  className="arrow-right"
                                />
                              </LinkWrapper>
                            </ControlsWithModalOpener>
                          </S.LinkContainer>
                        )}
                      />
                    </S.ItemInfo>
                  </S.Item>
                )
              })}
            </S.Row>
            <If
              condition={isEditing}
              then={() => (
                <S.AddButtonWrapper>
                  <AddButton
                    onAdd={() => {
                      Actions.addRow(idx)
                    }}
                    toShow={
                      Object.keys(gallery).length < validation.gallery.max
                    }
                    style={{
                      margin: '0 0.5rem'
                    }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_row_label')}
                  </AddButton>
                  <AddButton
                    onAdd={() => {
                      Actions.removeRow(idx)
                    }}
                    toShow={
                      Object.keys(gallery).length > validation.gallery.min
                    }
                    style={{
                      margin: '0 0.5rem'
                    }}
                  >
                    <Icon name="glyph-remove" className="icon" size="normal" />
                    {translate('remove_row_label')}
                  </AddButton>
                </S.AddButtonWrapper>
              )}
            />
          </S.RowWrapper>
        )
      })}
    </S.Container>
  )
})

export default Portfolio10
