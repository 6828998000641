// @flow

import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import * as CONSTS from './consts'
import type { TPopupProps } from './types'
import ResetOption from './ResetOption'
import LanguagesBlock from './LanguagesBlock'
import { PopupsLoader } from '@editor/common/components/Loaders'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { RESET_CHANGES_POPUP_INITIAL_STATE } from '@redux/consts'
import {
  selectSitePublishUrl,
  selectResetChangesPopupState,
  selectWebsiteLanguagesData
} from '@selectors'
import { resetProjectChanges } from '@actions/project'
import { setResetChangesPopupState } from '@actions/editor'
import { addAnalyticsEvent } from '@editor/common/utils'

const { RESET_OPTIONS } = CONSTS

const ResetChangesPopup = ({
  resetChangesPopupState: { isLoading, isSuccess, isFailed, errorMessage },
  sitePublishUrl,
  websiteLanguagesData,
  setResetChangesPopupState,
  resetProjectChanges
}: TPopupProps) => {
  const allLanguages = websiteLanguagesData.map(({ lang }) => lang.name)
  const [selectedOptions, setSelectedOptions] = useState(['pages'])
  const [isAgreementTooltipOpened, setAgreementTooltipState] = useState(false)
  const [isAgreementSelected, setAgreementStatus] = useState(false)
  const [languagesToReset, setLanguagesToResetList] = useState(allLanguages)

  const isWebsiteMultilingual = websiteLanguagesData.length > 1
  const isAllOptionsSelected = selectedOptions.length === RESET_OPTIONS.length
  const isAllOptionsDeselected = selectedOptions.length === 0
  const isResetButtonDisabled = !isAgreementSelected || isAllOptionsDeselected
  const resetButtonTooltipText = isAllOptionsDeselected
    ? CONSTS.EMPTY_STATE_TOOLTIP_TEXT
    : ''
  const agreementTooltipText =
    !isAgreementSelected && !isAllOptionsDeselected
      ? CONSTS.AGREEMENT_REQUIRED_MESSAGE
      : ''

  useEffect(() => {
    if (isAllOptionsDeselected) {
      setAgreementStatus(false)
    }
  }, [isAllOptionsDeselected])

  const closePopup = useCallback(() => {
    setResetChangesPopupState(RESET_CHANGES_POPUP_INITIAL_STATE)
  }, [])

  const handleOptionChange = useCallback(
    (_, value, isChecked) => {
      const newSelectedOptions = isChecked
        ? [...selectedOptions, value]
        : selectedOptions.filter(option => option !== value)

      setSelectedOptions(newSelectedOptions)
    },
    [selectedOptions]
  )

  // const handleContentChange = useCallback(
  //   (_, value, isChecked) => {
  //     if (isWebsiteMultilingual) {
  //       const languagesToReset = isChecked ? allLanguages : []
  //       setLanguagesToResetList(languagesToReset)
  //     }
  //
  //     handleOptionChange(_, value, isChecked)
  //   },
  //   [handleOptionChange, isWebsiteMultilingual, allLanguages]
  // )

  const handleSelectAllChange = useCallback((_, value, isChecked) => {
    const newSelectedOptions = isChecked
      ? RESET_OPTIONS.map(option => option.value)
      : []
    setSelectedOptions(newSelectedOptions)
  }, [])

  const handleAgreementChange = useCallback((_, value, isChecked) => {
    setAgreementStatus(isChecked)
  }, [])

  const handleReset = useCallback(() => {
    if (isResetButtonDisabled) {
      return
    }
    resetProjectChanges(selectedOptions)
    addAnalyticsEvent('SiteMaker Reset Changes', 'reset-button-click')
  }, [isResetButtonDisabled, selectedOptions, resetProjectChanges])

  return (
    <Styled.Popup
      isOpen
      isMobileFullScreen
      showCloseButton={isFailed || isSuccess}
      onClose={closePopup}
      className="reset-changes-popup"
    >
      <Styled.Container>
        <PopupsLoader
          isLoading={isLoading}
          isSuccess={isSuccess}
          isFailed={isFailed}
          errorText={errorMessage}
          loadingText={translate('resetting_your_changes_label')}
          successText={translate('website_has_been_successfully_reset')}
          onClick={closePopup}
        />
        <Styled.Header>
          <Styled.Title>{CONSTS.POPUP_TITLE}</Styled.Title>
          <Styled.InfoText>
            {CONSTS.POPUP_INFO_TEXT}{' '}
            <a href={sitePublishUrl} target="_blank" rel="noreferrer">
              {sitePublishUrl}
            </a>
          </Styled.InfoText>
          <Styled.OptionBlock>
            <div>
              <Styled.OptionLabel>
                {translate('select_all_label')}
              </Styled.OptionLabel>
            </div>
            <div>
              <Styled.Checkbox
                isChecked={isAllOptionsSelected}
                item={{}}
                onChange={handleSelectAllChange}
              />
            </div>
          </Styled.OptionBlock>
        </Styled.Header>
        <Styled.Content>
          <ResetOption
            option={RESET_OPTIONS[0]}
            isChecked={selectedOptions.indexOf(RESET_OPTIONS[0].value) !== -1}
            onChange={handleOptionChange}
          />
          {/*{isWebsiteMultilingual ? (*/}
          {/*  <LanguagesBlock*/}
          {/*    languagesToReset={languagesToReset}*/}
          {/*    allLanguages={allLanguages}*/}
          {/*    websiteLanguagesData={websiteLanguagesData}*/}
          {/*    isDisabled={*/}
          {/*      selectedOptions.indexOf(RESET_OPTIONS[0].value) === -1*/}
          {/*    }*/}
          {/*    setLanguagesToReset={setLanguagesToResetList}*/}
          {/*    setSelectedOptions={setSelectedOptions}*/}
          {/*  />*/}
          {/*) : null}*/}
          <ResetOption
            option={RESET_OPTIONS[1]}
            isChecked={selectedOptions.indexOf(RESET_OPTIONS[1].value) !== -1}
            onChange={handleOptionChange}
          />
          <ResetOption
            option={RESET_OPTIONS[2]}
            isChecked={selectedOptions.indexOf(RESET_OPTIONS[2].value) !== -1}
            onChange={handleOptionChange}
          />
        </Styled.Content>
        <Styled.Footer>
          <Styled.AgreementBlock>
            {!isAllOptionsDeselected ? (
              <>
                <div>
                  <TooltipWrapper
                    disableHover
                    onClose={() => {
                      setAgreementTooltipState(false)
                    }}
                    isOpen={isAgreementTooltipOpened}
                    text={agreementTooltipText}
                  >
                    <Styled.AgreementCheckbox
                      disabled
                      isChecked={isAgreementSelected}
                      onChange={handleAgreementChange}
                      item={{
                        label: '',
                        value: 'agreement'
                      }}
                    />
                  </TooltipWrapper>
                </div>
                <p>{CONSTS.AGREEMENT_LABEL}</p>
              </>
            ) : null}
          </Styled.AgreementBlock>
          <Styled.ActionButtonsWrapper>
            <Styled.CancelButton type="transparent" onClick={closePopup}>
              {translate('cancel_label')}
            </Styled.CancelButton>
            <TooltipWrapper text={resetButtonTooltipText} position="top">
              <div
                role="button"
                onClick={() => {
                  setTimeout(() => setAgreementTooltipState(true))
                }}
              >
                <Styled.SaveButton
                  type="secondary"
                  disabled={isResetButtonDisabled}
                  onClick={handleReset}
                >
                  {translate('reset_label', true)}
                </Styled.SaveButton>
              </div>
            </TooltipWrapper>
          </Styled.ActionButtonsWrapper>
        </Styled.Footer>
      </Styled.Container>
    </Styled.Popup>
  )
}

const mapStateToProps = state => ({
  websiteLanguagesData: selectWebsiteLanguagesData(state),
  resetChangesPopupState: selectResetChangesPopupState(state),
  sitePublishUrl: selectSitePublishUrl(state)
})
const mapDispatchToProps = { resetProjectChanges, setResetChangesPopupState }

export default connect(mapStateToProps, mapDispatchToProps)(ResetChangesPopup)
