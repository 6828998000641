// @flow

import React, { useContext } from 'react'
import { ControlModalsContext } from '@contexts'
import { MODAL_TYPES } from './consts'
import UrlModal from './UrlModal'
import MediaChangeModal from './MediaChangeModal'
import ExtendedUrlModal from './ExtendedUrlModal'
import TextModal from './TextModal'
import MapModal from './MapModal'
import SoundcloudModal from './SoundcloudModal'
import SpotifyModal from './SpotifyModal'
import ImageCropModal from './ImageCropModal'
import IconModal from './IconModal'
import LanguagesMenuFlyout from './LanguagesMenuFlyout'
import PayPalSettingsPopup from './PayPalSettingsPopup'
import TransparencyFlyout from './TransparencyFlyout'
import TextWizardAI from './TextWizardAI'

export default () => {
  const { modalStates, setModalState } = useContext(ControlModalsContext)

  return (
    <div className="wm-modal-types">
      {modalStates[MODAL_TYPES.mediaModal].isOpen && <MediaChangeModal />}
      {modalStates[MODAL_TYPES.soundcloudModal].isOpen && (
        <SoundcloudModal
          {...modalStates[MODAL_TYPES.soundcloudModal]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.spotifyModal].isOpen && (
        <SpotifyModal
          {...modalStates[MODAL_TYPES.spotifyModal]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.urlModal].isOpen && (
        <UrlModal
          {...modalStates[MODAL_TYPES.urlModal]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.extendedUrlModal].isOpen && (
        <ExtendedUrlModal
          {...modalStates[MODAL_TYPES.extendedUrlModal]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.iconModal].isOpen && (
        <IconModal
          {...modalStates[MODAL_TYPES.iconModal]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.textModal].isOpen && (
        <TextModal
          {...modalStates[MODAL_TYPES.textModal]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.mapModal].isOpen && (
        <MapModal
          {...modalStates[MODAL_TYPES.mapModal]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.imageCrop].isOpen && (
        <ImageCropModal
          {...modalStates[MODAL_TYPES.imageCrop]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.languagesMenuFlyout].isOpen && (
        <LanguagesMenuFlyout />
      )}
      {modalStates[MODAL_TYPES.paypalSettingsPopup].isOpen && (
        <PayPalSettingsPopup
          {...modalStates[MODAL_TYPES.paypalSettingsPopup]}
          setModalState={setModalState}
        />
      )}
      {modalStates[MODAL_TYPES.transparencyFlyout].isOpen && (
        <TransparencyFlyout />
      )}
      {modalStates[MODAL_TYPES.textWizardAIWindow].isOpen && <TextWizardAI />}
    </div>
  )
}
