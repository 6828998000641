/* @flow */
import styled from 'styled-components'
import THEME from './Header45.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgbA,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`
const WMCustomContainer = styled(WMContainer)`
  width: 100%;
  max-width: none;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  @media only screen and (max-width: 1024px) {
    padding: ${({ topOffset }) => `${topOffset}px`} 0 0 0;
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset}px`};
  }
  .socials-block {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      right: 40px;
    }
    @media only screen and (max-width: 992px) {
      width: 100%;
      align-items: flex-start;
      right: unset;
      transform: unset;
      position: initial;
      box-sizing: border-box;
      margin-left: -15px;
    }
  }
  .socials-wrapper {
    display: inline-flex;
    flex-direction: column;
    @media only screen and (max-width: 992px) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: ${({ isEditing }) => (isEditing ? '30px 0 0 0' : '25px 0 0 0')};
    }
    @media only screen and (max-width: 610px) {
      width: 100%;
    }
    .link {
      fill: ${getStyleForProp('socialColor')};
    }
  }
  .buttons-block {
    margin-left: -15px;
  }
`

const HeaderContent = styled.div`
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  padding-top: 80px;
  @media only screen and (max-width: 650px) {
    padding-top: 40px;
  }
  > :first-child {
    padding-top: 0;
  }
`

const SubTitle = styled.p`
  color: ${getStyleForProp('subTitle')};
  font-weight: 700;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin: auto auto 0 auto;
  @media only screen and (min-width: 992px) {
    margin: ${({ isTitleOnly, isEditing }) =>
      isTitleOnly && !isEditing ? '120px auto 160px auto' : '0'};
  }
  padding-top: 10px;
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin: auto;
  line-height: 1.5em;
  padding-top: 20px;
`

const ButtonWrap = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ isTwoBlock }) =>
    isTwoBlock ? 'calc(50% - 30px)' : 'calc(33% - 30px)'};
  outline: none;
  max-width: ${({ isTwoBlock }) => (isTwoBlock ? '420px' : '256px')};
  outline: none;
  margin-bottom: 30px;
  & > .control-container {
    padding-top: 10px;
  }
  @media only screen and (max-width: 768px) {
    margin-right: initial;
    width: 100%;
    align-items: center;
    max-width: 100%;
    margin-bottom: 40px;
  }
`
const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
`

const BlocksTitileContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: ${({ alignment }) =>
    alignment === 'left'
      ? 'flex-start'
      : alignment === 'right'
      ? 'flex-end'
      : 'center'};

  & > .control-container {
    margin-bottom: 20px;
    h3,
    h2 {
      margin-bottom: 0;
    }
    &:nth-last-child(1) {
      h2 {
        width: 100%;
      }
    }
  }
`

const BlockTitle = styled.h2`
  display: inline-block;
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 10px;
  ${getTextStyleByAlignment};
`

const BlockSubTitle = styled.h3`
  display: inline-block;
  color: ${getStyleForProp('subTitle')};
  margin-bottom: 10px;
  margin-right: 10px;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  line-height: 1.5em;
  ${getTextStyleByAlignment};
`

const BlocksWrap = styled.div`
  width: ${({ isVideoExists, isEditing }) =>
    !isVideoExists && !isEditing ? '100%' : 'calc(100% - 345px)'};
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 30px 40px 0;
  box-sizing: border-box;
  @media only screen and (max-width: 1500px) {
    width: ${({ isVideoExists, isEditing }) =>
      !isVideoExists && !isEditing ? '90%' : 'calc(90% - 345px)'};
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 457px) {
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const TopContent = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: auto;
  position: relative;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const TopContentWrapper = styled.div`
  width: 80%;
  max-width: 642px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    max-width: 470px;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    max-width: 100%;
  }

  & > .control-container {
    display: block;
  }
`

const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 165px;
  margin-top: 75px;
  background: ${props =>
    hexToRgbA(getStyleForProp('backgroundColor')(props), 0.6)};
  backdrop-filter: blur(15px);
  border: 1px solid ${getStyleForProp('border')};
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    margin-top: 50px;
  }
`

const BottomContentWrap = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1400px;
  @media only screen and (max-width: 1500px) {
    width: 100%;
    justify-contnet: flex-end;
    max-width: none;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`

const VideoControlWrapper = styled.div`
  width: 330px;
  @media only screen and (min-width: 1025px) {
    margin-left: 15px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const AddVideoButtonWrapper = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    .add-button-wrap {
      margin-bottom: 30px;
    }
  }
`

const AddBlockButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1024px) {
    margin-right: 30px;
  }
`

const VideoContent = styled.div`
  overflow: hidden;
  position: relative;
  z-index: 1;
  .video-content {
    width: 100%;
  }
  .video-wrap {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    margin: 0 auto;
    .iframe-video {
      border: none;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
`
const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

export default {
  StyledContainer,
  WMCustomContainer,
  HeaderContent,
  Title,
  SubTitle,
  Paragraph,
  ButtonWrap,
  ControlContainer,
  ItemWrapper,
  BlocksTitileContainer,
  BlockSubTitle,
  BlockTitle,
  BlockParagraph,
  BlocksWrap,
  TopContent,
  TopContentWrapper,
  BottomContent,
  BottomContentWrap,
  VideoControlWrapper,
  VideoContent,
  AddVideoButtonWrapper,
  AddBlockButtonWrapper,
  Overlay
}
