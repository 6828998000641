/* @flow */
import React, { useContext, memo } from 'react'
import { validation } from './Text20.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import * as S from './Text20.styled'
import { translate } from '@editor/common/utils/translations'

const Text20 = memo(props => {
  const {
    data: {
      blocks,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WMCustomContainer>
        <Mapper
          data={blocks}
          render={(block: string, idx: number) => (
            <S.ItemWrap key={idx} isEditing={isEditing}>
              <ControlsWithModalOpener
                actions={Actions.blockActions({
                  idx,
                  showRemove: blocks.length > validation.blocks.min
                })}
              >
                <S.IconWrapper>
                  <If
                    condition={!!block.iconName}
                    otherwise={() => (
                      <AddButton
                        onAdd={() => Actions.addIcon(idx)}
                        type="icon"
                        large
                        toShow
                      >
                        +
                      </AddButton>
                    )}
                    then={() => (
                      <ControlsWithModalOpener
                        actions={Actions.iconActions({ idx })}
                      >
                        <S.Circle>
                          <S.Icon size="xxlLarge" name={block.iconName} />
                        </S.Circle>
                      </ControlsWithModalOpener>
                    )}
                  />
                </S.IconWrapper>
                <EditableContent
                  as={S.BlockTitle}
                  text={block.title}
                  required
                  alignment={block.blockTitleAlignment}
                  maxCount={validation.blockTitleMaxChar}
                  className="WM_GLOBAL_heading20"
                  onChange={(newTitle: string) => {
                    Actions.changeBlockTitle(newTitle, idx)
                  }}
                  changeAlignment={(alignment: string) =>
                    Actions.changeBlockTitleAlignment(alignment, idx)
                  }
                />
              </ControlsWithModalOpener>
            </S.ItemWrap>
          )}
        />
      </S.WMCustomContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '20px'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Text20
