import { createGlobalStyle } from 'styled-components'

import {
  SEMRUSH_SIDEBAR_WIDTH,
  SEMRUSH_SIDEBAR_ANIMATION_TIME
} from '@editor/common/components/SemrushSidebar/consts'

export const GlobalStyles = createGlobalStyle`
  @media only screen and (min-width: 1200px) {
    .semrush-sidebar-adapted, .semrush-sidebar-adapted > span {
      width: calc(100% - ${SEMRUSH_SIDEBAR_WIDTH}px);
      transition: width ${SEMRUSH_SIDEBAR_ANIMATION_TIME}ms ease-out;
    }
  }
`
