/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'

const keysForSizes = {
  bgImage: 'text18BgImage',
  centerImage: 'text18Image'
}

export default dispatcher => {
  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeBlockParagraph = (newParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newParagraph, idx }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const changeTitleGradientStatus = (newTitleTextGradient: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_TEXT_GRADIENT',
      value: newTitleTextGradient
    })
  }

  const addImage = (imgName: string) => {
    dispatcher({
      type: 'ADD_IMAGE',
      value: { imgName }
    })
  }

  const imgActions = (imgName: string) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: keysForSizes[imgName],
      showRemove: true,
      additionalArgs: { imgName }
    })
  ]

  const removeBlock = (idx: string) => {
    dispatcher({
      type: 'REMOVE_BLOCK',
      value: idx
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: _addBlock,
    addImage,
    changeTitleGradientStatus,
    changeTitle: _changeTitle,
    changeBlockTitle,
    changeBlockParagraph,
    removeBlock,
    imgActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment
  }
}
