import styled, { css } from 'styled-components'
import THEME from './theme'

import { hexToRgb, styleProvider } from '@website/common/utils'
const getStyleForProp = styleProvider(THEME)

export const RSVPOptionWrapper = styled.div`
  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 0;
    form {
      column-gap: 50px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 520px;

      > div {
        max-width: 235px;
        word-break: break-word;
      }
    }
  }
  label {
    width: initial;
  }
  .radio-button-list {
    .radio-buttons-title {
      h4 {
        font-weight: 600;
        text-align: center;
      }
    }
  }
`

export const FormContainer = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${getStyleForProp('backgroundColor')};
  padding: 30px 30px 80px 30px;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    padding: 50px 40px 80px 40px;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding: 30px 20px 60px 20px;
  }
  label,
  .checkbox-list-title,
  .radio-buttons-title {
    color: ${getStyleForProp('label')};
  }

  input {
    color: ${({ theme }) => theme.palette.tertiaryDark};
    border: ${({ theme }) =>
      `1px solid ${`rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`}`};
    font-weight: 400;
    &:focus {
      border-color: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
  .asterisk {
    color: ${getStyleForProp('label')};
  }
  .error-state {
    input {
      border: 1px solid #eb0c00;
    }
    .asterisk {
      color: #eb0c00;
    }
  }

  ${({ isEditing }) =>
    isEditing
      ? css`
          display: flex;
          .form-wrapper {
            position: relative;
            &:after {
              content: '';
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
        `
      : css`
          display: none;
        `}
`

export const FormWrapper = styled.div`
  width: 100%;
  max-width: 520px;
  .dynamic-fields {
    margin-top: 30px;
  }
  .rsvp-option {
    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      label {
        font-size: 18px !important;
      }
    }
    .radio-buttons-title {
      font-size: 20px;
      font-weight: 700;
    }
  }
`

export const SubmitButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('buttonRadius')};
  white-space: nowrap;
  ${getStyleForProp('buttonBackground')};
  max-width: 300px;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: center;
  height: 50px;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 400 !important;
  background-repeat: no-repeat !important;
  font-size: 14px !important;
  border: none;
  margin: 30px auto 0 auto;

  .spinner {
    position: absolute;
    right: 15px;
    top: 15px;
  }
`

export const InputPair = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 15px;

  &:nth-last-child(1) {
    margin-bottom: 0;
  }

  ${({ hasNotFirstChildMargin }) =>
    hasNotFirstChildMargin
      ? ''
      : css`
          & > div:first-child {
            @media only screen and (min-width: 458px) {
              margin-right: 20px;
            }
          }
        `};

  @media only screen and (max-width: 457px) {
    flex-direction: column;
  }

  .error-state {
    div {
      &:last-child {
        top: 78px;
        bottom: auto;
      }
    }
  }

  .remove-icon {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 55px;
    right: -25px;
    cursor: pointer;
    fill: ${getStyleForProp('label')};
    &:hover {
      fill: ${getStyleForProp('removeIcon')};
    }
    @media only screen and (max-width: 457px) {
      right: -20px;
    }
  }
  + .dynamic-fields {
    > .radio-button-list,
    > .rsvp-checkbox-list {
      margin-top: 30px;
    }
  }
`

export const AdditionalGuestButton = styled.button`
  display: block;
  border: none;
  background: transparent;
  font-weight: 600 !important;
  margin: 30px auto;
  color: ${getStyleForProp('additionalButton')};
  cursor: pointer;
  + ${InputPair} {
    margin-top: -20px;
  }
  + .dynamic-fields {
    > div:first-child {
      margin-top: 0;
    }
  }

  :disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }
`

export const DynamicFields = styled.div`
  .rsvp-input,
  .rsvp-checkbox-list,
  .radio-button-list {
    margin-bottom: 30px;
  }
  .rsvp-input + .rsvp-input {
    margin-top: -10px;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  .control-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
  }
`

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #eb0c00;
  margin-top: 20px;

  > img {
    margin-right: 5px;
  }
`
