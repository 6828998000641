/* @flow */
import styled from 'styled-components'
import THEME from './Portfolio12.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  padding: ${({ isEditing }) => (isEditing ? '140px 0 8px 0' : '80px 0 8px 0')};
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) =>
      isEditing ? '140px 0 8px 0' : '60px 0 8px 0'};
  }
  .row-remove {
    color: white;
    padding: 10px;
  }
`

const Headings = styled.div`
  text-align: center;
  width: 90%;
  max-width: 900px;
  margin: 0 auto 40px auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    h1 {
      margin-bottom: 0;
    }
  }

  & > .control-container:nth-child(1) {
    margin-bottom: 20px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const AddButtonWrapper = styled.div`
  display: flex;
  margin: 20px auto;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`

const BlockTitle = styled.p`
  color: ${getStyleForProp('blockTitle')};
  width: 100%;
  font-weight: 600;
  position: absolute;
  ${getTextStyleByAlignment};
  z-index: 1;
  bottom: 30px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  @media only screen and (max-width: 458px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Item = styled.div`
  width: 20%;
  display: flex;
  flex-grow: 1;
  position: relative;
  margin: 8px;
  @media only screen and (min-width: 1023px) {
    &:first-child {
      margin-left: 16px;
    }
    &:last-child {
      margin-right: 16px;
    }
  }
  @media only screen and (max-width: 1023px) {
    width: 45%;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
  & > .control-container {
    position: absolute;
    z-index: 1;
    width: calc(100% - 60px);
    bottom: 30px;
    left: 30px;
    ${BlockTitle} {
      position: static;
    }
  }
  .LazyLoad {
    width: 100%;
    overflow: hidden;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    transition: all 0.5s linear;
  }
  &:hover .lazy-figure {
    transform: scale(1.2);
  }

  & > .control-container {
    position: absolute;
    z-index: 1;
    bottom: 30px;
    left: 30px;
    right: 30px;
    & > p {
      position: static;
      padding: 0;
    }
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    & > .styled-controls {
      z-index: 10;
    }
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export default {
  StyledContainer,
  Row,
  AddButtonWrapper,
  RowWrapper,
  Headings,
  Title,
  Paragraph,
  Item,
  BlockTitle,
  Img,
  ControlContainer
}
