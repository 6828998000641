// const AE = 'https://static.rfstat.com/renderforest/images/website_maker_images/ae.svg'

const imgPath =
  'https://static.rfstat.com/renderforest/images/website_maker_images/'

export const LANGUAGES = [
  {
    iconUrl: `${imgPath}gb.svg`,
    langCode: 'en',
    text: 'English'
  },
  {
    iconUrl: `${imgPath}br.svg`,
    langCode: 'pt',
    text: 'Português'
  },
  {
    iconUrl: `${imgPath}es.svg`,
    langCode: 'es',
    text: 'Español'
  },
  {
    iconUrl: `${imgPath}de.svg`,
    langCode: 'de',
    text: 'Deutsch'
  },
  {
    iconUrl: `${imgPath}fr.svg`,
    langCode: 'fr',
    text: 'Français'
  },
  {
    iconUrl: `${imgPath}jp.svg`,
    langCode: 'jp',
    text: '日本語'
  },
  {
    iconUrl: `${imgPath}tr.svg`,
    langCode: 'tr',
    text: 'Türkçe'
  },
  {
    iconUrl: `${imgPath}ru.svg`,
    langCode: 'ru',
    text: 'Русский'
  }
]
