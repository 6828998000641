import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const SemrushKeyword = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  overflow: auto;
`

export const Heading = styled.h1`
  color: ${secondary.ExtraDark};
  font-size: 32px;
`

export const LoaderWrapper = styled.div`
  width: 100%;
  padding: 25px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3px;
  border-left: 1px solid #c1c9e0;
  border-right: 1px solid #c1c9e0;

  ${({ isLoading }) =>
    isLoading
      ? css`
          visibility: visible;
        `
      : css`
          visibility: hidden;
        `}
`
