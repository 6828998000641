/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import { changeTitle, changeParagraph, changeTitleAlignment, changeParagraphAlignment } from '@website/components/CommonActions'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import LinkIcon from '@editor/common/assets/svgr-icons/Link_icon.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeName = (newName: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_NAME',
      value: { newName, idx }
    })

  const changePosition = (newPosition: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_POSITION',
      value: { newPosition, idx }
    })
  }

  const addMember = () => {
    dispatcher({ type: 'ADD_MEMBER' })
  }

  const memberActions = (
    memberIdx: number,
    showRemove: boolean,
    link: string,
    imageUrl: string
  ) => [
    {
      IconComp: LinkIcon,
      tooltipText: translate('add_link_label'),
      handler: link => {
        dispatcher({
          type: 'CHANGE_MEMBER_LINK',
          value: { link, memberIdx }
        })
      },
      modal: {
        type: MODAL_TYPES.urlModal,
        props: {
          type: LINK_TYPES.link,
          link: link
        }
      }
    },
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'team2',
      showRemove,
      additionalArgs: { memberIdx },
      imageUrl
    })
  ]

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeNameAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changePositionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_POSITION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeName,
    addMember,
    changePosition,
    memberActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNameAlignment,
    changePositionAlignment
  }
}
