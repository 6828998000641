/* @flow */

export const validation = {
  titleMaxChar: 100,
  paragraphMaxChar: 300,
  blockTitleMaxChar: 50,
  blockParagraphMaxChar: 90,
  features: {
    min: 1,
    max: 9
  }
}

export const ADDITIONAL_LINK_INITIAL_STATE = {
  additionalUrl: 'http://builder.renderforestsites.com',
  additionalText: 'Learn more'
}
