import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    price: p => p.primaryLight,
    link: p => p.primaryLight,
    background: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    icon: p => `rgba(${hexToRgb(p.primaryLight)}, 1)`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.9)`,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    price: p => p.primaryDark,
    link: p => p.primaryDark,
    background: p => p.tertiaryLight,
    backgroundColor: p => p.tertiaryLight,
    icon: p => `rgba(${hexToRgb(p.primaryDark)}, 1)`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.9)`,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    price: p => p.primaryDark,
    link: p => p.primaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    icon: p => `rgba(${hexToRgb(p.primaryDark)}, 1)`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.9)`,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  }
}
