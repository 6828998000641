import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const CircleProgressLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: rgba(255, 255, 255, 0.7);

  .circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation: circle-progress-loader 1.1s infinite ease;
  }

  .loading-text {
    font-size: 16px;
    font-weight: 600;
    color: ${secondary.ExtraDark};
    margin-top: 40px;
    max-width: 290px;
    text-align: center;
  }

  @keyframes circle-progress-loader {
    0%,
    100% {
      box-shadow: 0 -33px 0 0 rgba(50, 113, 230, 0.2),
        24px -20px 0 0 rgba(50, 113, 230, 0.3),
        24px 7px 0 0 rgba(50, 113, 230, 0.4), 0 22px 0 0 rgba(50, 113, 230, 0.6),
        -24px 7px 0 0 rgba(50, 113, 230, 0.8),
        -24px -20px 0 0 rgba(50, 113, 230, 1);
    }
    16.7% {
      box-shadow: 0 -33px 0 0 rgba(50, 113, 230, 1),
        24px -20px 0 0 rgba(50, 113, 230, 0.2),
        24px 7px 0 0 rgba(50, 113, 230, 0.3), 0 22px 0 0 rgba(50, 113, 230, 0.4),
        -24px 7px 0 0 rgba(50, 113, 230, 0.6),
        -24px -20px 0 0 rgba(50, 113, 230, 0.8);
    }
    33.4% {
      box-shadow: 0 -33px 0 0 rgba(50, 113, 230, 0.8),
        24px -20px 0 0 rgba(50, 113, 230, 1),
        24px 7px 0 0 rgba(50, 113, 230, 0.2), 0 22px 0 0 rgba(50, 113, 230, 0.3),
        -24px 7px 0 0 rgba(50, 113, 230, 0.4),
        -24px -20px 0 0 rgba(50, 113, 230, 0.6);
    }
    50.1% {
      box-shadow: 0 -33px 0 0 rgba(50, 113, 230, 0.6),
        24px -20px 0 0 rgba(50, 113, 230, 0.8),
        24px 7px 0 0 rgba(50, 113, 230, 1), 0 22px 0 0 rgba(50, 113, 230, 0.2),
        -24px 7px 0 0 rgba(50, 113, 230, 0.3),
        -24px -20px 0 0 rgba(50, 113, 230, 0.4);
    }
    66.8% {
      box-shadow: 0 -33px 0 0 rgba(50, 113, 230, 0.4),
        24px -20px 0 0 rgba(50, 113, 230, 0.6),
        24px 7px 0 0 rgba(50, 113, 230, 0.8), 0 22px 0 0 rgba(50, 113, 230, 1),
        -24px 7px 0 0 rgba(50, 113, 230, 0.2),
        -24px -20px 0 0 rgba(50, 113, 230, 0.3);
    }
    83.5% {
      box-shadow: 0 -33px 0 0 rgba(50, 113, 230, 0.3),
        24px -20px 0 0 rgba(50, 113, 230, 0.4),
        24px 7px 0 0 rgba(50, 113, 230, 0.6), 0 22px 0 0 rgba(50, 113, 230, 0.8),
        -24px 7px 0 0 rgba(50, 113, 230, 1),
        -24px -20px 0 0 rgba(50, 113, 230, 0.2);
    }
  }
`
