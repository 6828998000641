import styled, { css } from 'styled-components'

import { secondary, white } from '@website/common/styles/colors'
import LibSlideLeft from '@editor/common/lib/SlideLeft/SlideLeft'

export const SlideLeft = styled(LibSlideLeft)`
  height: 700px;
  bottom: unset;
  top: 90px;
  z-index: 25;
  display: flex;
  flex-direction: column;
  background-color: ${white};
  border-radius: 10px 0 0 10px;
  border: 1px solid ${secondary.Light};
  box-sizing: border-box;
`

export const Header = styled.div`
  padding: 20px 20px 0;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${secondary.Light};
    padding-bottom: 15px;

    & > span {
      font-size: 14px;
      font-weight: 600;
      color: ${secondary.ExtraDark};
      user-select: none;
    }

    & > svg {
      fill: ${secondary.SemiDark};
      cursor: pointer;

      &:hover {
        fill: ${secondary.ExtraDark};
      }
    }
  }
`

export const List = styled.div`
  overflow: scroll;
  padding: 20px;

  @media only screen and (max-width: 768px) {
    height: 280px;
    display: flex;
    justify-content: center;
    row-gap: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
  }
`

export const ListItem = styled.div`
  width: 192px;
  height: 126px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: 1px solid ${secondary.Light};
  border-radius: 5px;
  box-sizing: border-box;
  padding: 5px 0;
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 20px;
  }

  @media only screen and (max-width: 768px) {
    height: 126px !important;
    display: flex;
    padding-top: 5px;

    &:not(:first-child) {
      margin-top: 0;
    }
  }

  & > img {
    width: 100%;
    object-fit: contain;
  }
  ${({ isActive }) =>
    isActive
      ? css`
          box-shadow: 0 0 0 1px #ffffff, 0 0 0 2px #3271e6;
        `
      : css`
          &:hover {
            box-shadow: 0 0 0 1px #3271e6;
          }
        `};

  @media only screen and (max-width: 768px) {
    width: 150px;
    height: 100px;
  }
`
