/* @flow */
import styled from 'styled-components'
export const Image = styled.img`
  width: 140px;
  height: 140px;
  margin-bottom: 20px;
  animation: scale-image 700ms ease-out;
  @keyframes scale-image {
    0% {
      transform: scale(0.5);
    }
    90% {
      transform: scale(1.1);
    }
    }
    100% {
      transform: scale(1);
    }
  }

`
export const Title = styled.p`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #363f5a;
  text-align: center;
  margin-bottom: 10px;
  max-width: 300px;
`
export const SuccessContnetWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 22px;
  margin-bottom: 20px;
`
