import styled from 'styled-components'

export const Content = styled.div`
  padding: 20px 10px;
  text-align: center;
  @media only screen and (max-width: 768px) {
    padding: 20px 0;
  }
`

export const ConfirmQuestion = styled.div`
  width: 335px;
  text-align: center;
  font-size: 18px;
  color: #363f5a;
  & > span {
    font-weight: 500;
    word-break: break-all;
    max-width: 220px;
    display: inline-block;
    vertical-align: bottom;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media only screen and (max-width: 415px) {
    width: 244px;
  }
`

const Button = styled.button`
  margin-top: 30px;
  padding: 13px 30px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
`

export const CancelBtn = styled(Button)`
  margin-right: 5px;
  background-color: transparent;
  color: #545f7e;
  &:hover {
    background-color: #eef5ff;
  }
`

export const DeleteButton = styled(Button)`
  margin-left: 5px;
  background: linear-gradient(180deg, #ff706b 0%, #ff4141 100%) 0% 0% no-repeat;
  color: #ffffff;
  &:hover {
    background: linear-gradient(180deg, #ff8170 0%, #ff5a5a 100%) 0% 0%
      no-repeat;
  }
`

export const ButtonsWrapper = styled.div`
  justify-content: center;
  @media only screen and (max-width: 768px) {
    display: flex;
  }
`
