/* @flow */
import React, { useContext, memo } from 'react'
import S from './Steps4.styled'
import { validation } from './Steps4.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Steps4 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      steps,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <S.WmCustomContainer>
        <S.TextContainer>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Description}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.TextContainer>
        <S.StepsContainer className="WM_GLOBAL_secondary-font">
          <Mapper
            data={steps}
            render={(step: string, idx: number) => (
              <S.StepsWrap key={idx}>
                <Show
                  when={[step.stepInfo, step.stepTitle, step.stepParapgraph]}
                >
                  <ControlsWithModalOpener
                    alignment="right"
                    style={{ width: '100%' }}
                    actions={Actions.stepActions({
                      idx,
                      showRemove: steps.length > validation.steps.min
                    })}
                  >
                    <S.StepItemWrap>
                      <S.StepInfoWrapper isEditing={isEditing}>
                        <EditableContent
                          text={step.stepInfo}
                          as={S.StepInfo}
                          alignment={step.stepInfoAlignment}
                          maxCount={validation.monthMaxChar}
                          onChange={(newStepInfo: string) => {
                            Actions.changeBlockYear(newStepInfo, idx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockStepInfoAlignment(alignment, idx)
                          }
                        />
                      </S.StepInfoWrapper>
                      <S.StepContent isEditing={isEditing}>
                        <S.Circle />
                        <EditableContent
                          as={S.StepTitle}
                          text={step.stepTitle}
                          alignment={step.stepTitleAlignment}
                          maxCount={validation.blockTitleMaxChar}
                          onChange={(title: string) =>
                            Actions.changeBlockTitle(title, idx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockStepTitleAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                        <EditableContent
                          as={S.StepsParagraph}
                          text={step.stepParapgraph}
                          alignment={step.stepParagraphAlignment}
                          maxCount={validation.blockParagraphMaxChar}
                          onChange={(paragraph: string) => {
                            Actions.changeBlockParagraph(paragraph, idx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockStepParagraphAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                      </S.StepContent>
                    </S.StepItemWrap>
                  </ControlsWithModalOpener>
                </Show>
              </S.StepsWrap>
            )}
          />
        </S.StepsContainer>
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={steps.length < validation.steps.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_step_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Steps4
