import { translate } from '@editor/common/utils/translations'

export const ICON_FILTERS = {
  ALL: 0,
  SOCIAL: 1,
  GLYPH: 2,
  OUTLINE: 3
}

export const ICON_FILTER_OPTIONS = [
  {
    label: translate('all_label'),
    value: ICON_FILTERS.ALL
  },
  {
    label: translate('social_label'),
    value: ICON_FILTERS.SOCIAL
  },
  {
    label: translate('glyph_label'),
    value: ICON_FILTERS.GLYPH
  },
  {
    label: translate('outline_label'),
    value: ICON_FILTERS.OUTLINE
  }
]
