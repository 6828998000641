/* @flow */
import {
  changeCopyright,
  changeParagraph
} from '@website/components/CommonActions'
import { privacyPolicyAction } from '@website/components/ActionMakers'

export default dispatcher => {
  const _changeCopyright = (newValue: string) => {
    dispatcher(changeCopyright(newValue))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  return {
    changeCopyright: _changeCopyright,
    changeParagraph: _changeParagraph,
    privacyPolicyAction
  }
}
