import { translate } from '@editor/common/utils/translations'

//TODO: convert to links
import Minimalistic from './minimalistic.png'
import Elegant from './elegant.png'
import Creative from './creative.png'
import Professional from './professional.png'

export const NAME_CATEGORY_CHAR_LIMIT = 120
export const DESCRIPTION_CHAR_LIMIT = 1000

export const STEPS = [
  translate('industry_label'),
  translate('business_name_label'),
  translate('style_label')
]

export const TITLES = [
  translate('which_category_best_fits_your_business_label'),
  translate('whats_the_name_of_your_business_label'),
  translate('which_style_stands_out_as_your_favorite_label')
]

export const DESCRIPTIONS = [
  translate(
    'select_the_category_that_best_suits_your_needs_and_well_tailor_unique_solutions_label'
  ),
  translate(
    'share_the_name_of_your_business_to_let_us_generate_personalized_content_label'
  ),
  translate(
    'select_your_preferred_style_and_well_craft_the_perfect_blend_of_colors_layout_and_more_label'
  )
]

export const CARDS_DATA = [
  {
    title: translate('minimalistic_label'),
    value: 'minimalistic',
    imageSrc: Minimalistic,
    fontStyle: '300 20px Montserrat, san-serif'
  },
  {
    title: translate('elegant_label'),
    value: 'elegant',
    imageSrc: Elegant,
    fontStyle: '400 23px Philosopher, san-serif'
  },
  {
    title: translate('creative_label'),
    value: 'creative',
    imageSrc: Creative,
    fontStyle: '400 20px Homemade Apple, san-serif'
  },
  {
    title: translate('professional_label'),
    value: 'professional',
    imageSrc: Professional,
    fontStyle: '300 20px Rubik, san-serif'
  }
]
