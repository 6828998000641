import { createSelector } from 'reselect'
import { FREE_USER_GUEST_LIMIT } from '@editor/conf/consts'
import { selectWebsiteSubscriptionPlan } from './editor'
import {
  DETAILS_FIELDS_META,
  POPUPS_FIELDS_META,
  FORM_STATIC_FIELDS_META
} from './consts'

export const selectEvents = state => state?.events

export const selectEventLoadingState = state =>
  selectEvents(state).isEventsLoading

export const selectEventCreatingState = state =>
  selectEvents(state).isEventCreating

export const selectEventSuccessMessage = state =>
  selectEvents(state).successMessage

export const selectEventsData = createSelector(
  [selectEvents],
  events => events.data
)

export const selectEventTranslationState = createSelector(
  [selectEvents],
  events => events.isTranslationLoading
)

export const selectHasWebsiteEvent = createSelector(
  [selectEventsData],
  data => !!data.length
)

// At this moment we have only one event, so we need events[0]
export const selectEvent = createSelector(
  [selectEventsData],
  eventsData => eventsData[0]
)

export const selectEventId = createSelector(
  [selectEvent],
  eventData => eventData?.id
)

export const selectIsRsvpGuestUnlimited = createSelector(
  [selectWebsiteSubscriptionPlan],
  subscription => subscription.rsvpGuestUnlimited
)

export const selectSettingsData = createSelector(
  [selectEvent],
  eventData => eventData?.settings
)

export const selectFormData = createSelector(
  [selectEvent],
  eventData => eventData.form
)

export const selectPopupsData = createSelector(
  [selectEvent],
  eventData => eventData.popups
)

export const selectDetailsData = createSelector(
  [selectEvent],
  eventData => eventData.details
)

export const selectTranslations = createSelector(
  [selectEvents, selectEventId],
  (eventsData, eventId) => eventsData.translations[eventId]
)

const processPopupsData = data =>
  POPUPS_FIELDS_META.map(({ key, inputType, field }) => {
    const dataItem = data[key]

    return {
      key: `${key}_${field}`,
      translationId: dataItem[field].id,
      isVisible: dataItem[field] === 'buttonText' ? dataItem.showButton : true,
      inputType
    }
  })

const processFormStaticData = data =>
  FORM_STATIC_FIELDS_META.map(({ key, inputType, field }) => {
    const dataItem = field
      ? data[key].options?.find(item => item.name === field)
      : data[key]
    const { id, isVisible = true } = dataItem

    return {
      key: field ? `${key}_${field}` : key,
      translationId: id,
      inputType,
      isVisible
    }
  })

const processFormDynamicData = (data, type = 'question', currentData = []) => {
  return data.reduce(
    (acc, item, idx) => {
      if (item.id) {
        acc.push({
          key: type,
          isDynamicField: true,
          translationId: item.id,
          inputType: 'input',
          idx: idx + 1,
          isVisible: true
        })
      }

      return item.options
        ? processFormDynamicData(item.options, 'answer', acc)
        : acc
    },
    [...currentData]
  )
}

const processDetailsData = data =>
  DETAILS_FIELDS_META.map(({ key, inputType }) => ({
    key,
    translationId: data[key].id,
    inputType,
    isVisible: true
  }))

export const selectTranslationDataForUI = createSelector(
  [selectFormData, selectPopupsData, selectDetailsData],
  (form, popups, details) => {
    const { dynamicFields, ...rest } = form

    const detailsData = processDetailsData(details)
    const popupsData = processPopupsData(popups)
    const formStaticData = processFormStaticData(rest)
    const formDynamicData = processFormDynamicData(dynamicFields)

    return {
      details: detailsData,
      form: [...formStaticData, ...formDynamicData],
      popups: popupsData
    }
  }
)

export const selectGuestsList = createSelector(
  [selectEvents],
  events => events.guestsList
)

export const selectIsGuestsListLoading = createSelector(
  [selectGuestsList],
  guestsList => guestsList.isLoading
)

export const selectIsGuestListPaginationLoading = createSelector(
  [selectGuestsList],
  guestsList => guestsList.isPaginationLoading
)

export const selectGuestsListData = createSelector(
  [selectGuestsList],
  guestsList => guestsList.data
)

export const selectGuestsListFilters = createSelector(
  [selectGuestsList],
  guestsList => guestsList.filters
)

export const selectGuestListSortingInfo = createSelector(
  [selectGuestsListFilters],
  filters => filters.sortingInfo
)

export const selectTotalGuestsCount = createSelector(
  [selectGuestsList],
  guestsList => guestsList.totalGuestsCount
)

export const selectTotalResultsCount = createSelector(
  [selectGuestsList],
  guestsList => guestsList.totalResultsCount
)

export const selectGuestsListLength = createSelector(
  [selectGuestsListData],
  data => data.length
)

export const selectRsvpGuestLimit = createSelector(
  [selectWebsiteSubscriptionPlan, selectIsRsvpGuestUnlimited],
  (websiteSubscriptionPlan, isUnlimited) =>
    isUnlimited
      ? Infinity
      : websiteSubscriptionPlan.rsvpGuestLimit || FREE_USER_GUEST_LIMIT
)

export const selectIsGuestListUpgradeVisible = createSelector(
  [selectRsvpGuestLimit, selectTotalGuestsCount],
  (rsvpGuestLimit, totalGuestsCount) => totalGuestsCount > rsvpGuestLimit
)

export const selectGuestsCountsByAnswer = createSelector(
  [selectGuestsList],
  guestsList => guestsList.resultCounts.answers || {}
)

export const selectGuestsCountsByLanguage = createSelector(
  [selectGuestsList],
  guestsList => guestsList.resultCounts.languages || []
)

export const selectFormDataFromRevision = createSelector(
  [selectGuestsList],
  guestsList => guestsList.formDataFromRevision
)

export const selectEmailSettings = createSelector(
  [selectEvent],
  eventData => eventData.emailSettings || {}
)

export const selectEmailConfirmationIsEnabled = createSelector(
  [selectEmailSettings],
  emailSettings => emailSettings?.isConfirmationEnabled || false
)

export const selectRegretEmailIsEnabled = createSelector(
  [selectEmailSettings],
  emailSettings => emailSettings?.isRegretEnabled || false
)

export const selectConfirmationEmailData = createSelector(
  [selectEvent],
  eventData => eventData.emailData?.confirm
)

export const selectRegretEmailData = createSelector(
  [selectEvent],
  eventData => eventData.emailData?.regret
)
