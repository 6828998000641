/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_BLOCK':
        draft.featureIconText.push(initialState.featureIconText[0])
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_DESCRIPTION':
        draft.description = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE':
        const { newTitle, idx } = action.value
        draft.featureIconText[idx].title = newTitle
        break
      case 'CHANGE_BLOCK_CONTENT':
        const { newContent, idx: _idx } = action.value
        draft.featureIconText[_idx].content = newContent
        break
      case 'CHANGE_CONTENT_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.featureIconText[idx].blockContentAlignment = alignment
        break
      }
      case 'REMOVE_BLOCK':
        const { idx: idx__ } = action.value
        draft.featureIconText.splice(idx__, 1)
        break
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.featureIconText[linkIdx].additionalUrl = linkData.link
        draft.featureIconText[linkIdx].additionalText = linkData.text
        break
      case 'CHANGE_ICON':
        const { idx: idx_, iconName } = action.value
        draft.featureIconText[idx_].icon = iconName
        break
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.featureIconText[idx].additionalUrl = ''
        draft.featureIconText[idx].additionalText = ''
        break
      }
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.featureIconText[idx].icon = ''
        break
      }
      case 'ADD_ICON':
        draft.featureIconText[action.value].icon = 'edit'
        break
      case 'ADD_LINK': {
        const { additionalUrl, additionalText } =
          initialState.featureIconText[0]
        const { idx } = action.value
        draft.featureIconText[idx].additionalUrl = additionalUrl
        draft.featureIconText[idx].additionalText = additionalText
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
