import styled, { css, createGlobalStyle } from 'styled-components'
import { primary, secondary } from '@website/common/styles/colors'

export const GlobalStyles = createGlobalStyle`
  .publish-options-flyout_container {
    width: 360px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 3px 10px #0052E026;
    background-color: white;
  }
`

const ITEM_STYLES = css`
  display: flex;
  flex-direction: row;
  height: 60px;
  padding: 0 20px 0px 35px;
  background: #ffffff;
  cursor: pointer;
  color: ${secondary.SemiDark};
  align-items: center;
  font-size: 14px;
  user-select: none;
  border-top: ${({ withTopBorder }) =>
    withTopBorder ? `1px solid ${secondary.Light}` : ''};
  & > span {
    flex-grow: 1;
  }
`

export const PublishOptionItem = styled.div`
  ${ITEM_STYLES};
  transition: padding 0.3s ease;
  & > svg {
    fill: ${secondary.SemiDark};
    margin-right: 15px;
  }
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          color: ${secondary.Light};
          & > svg {
            fill: ${secondary.Light};
          }
        `
      : css`
          &:hover {
            padding: 0 20px 0 40px;
            background: ${primary.Light};
            color: ${secondary.ExtraDark};
            font-weight: 500;
            & > svg {
              fill: ${secondary.ExtraDark};
            }
          }
        `};
`

export const PublishedDate = styled.div`
  ${ITEM_STYLES};
  cursor: default;
  background-color: #edeff680;
`

export const CrownGear = styled.img`
  width: 45px;
  height: auto;
  margin-left: 10px;
`
