export const validation = {
  maxTitleChar: 120,
  maxParagraphChar: 1500,
  slides: {
    min: 1,
    max: 10
  },
  buttons: {
    min: 0,
    max: 2
  }
}