/* @flow */
import React, { useContext, memo } from 'react'
import S from './Clients3.styled'
import { validation } from './Clients3.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'
import { openUrl, getExternalUrl } from '@website/common/utils'

const Clients3 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      clientsLogos,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const showRemove = clientsLogos.length > validation.clientsLogos.min

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <S.WmCustomContainer>
        <S.CardWrapper>
          <S.Card>
            <S.TextContainer>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                required={!paragraph}
                maxCount={validation.headingMaxChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading"
                changeAlignment={Actions.changeTitleAlignment}
              />
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  as={S.Description}
                  alignment={paragraphAlignment}
                  required={!title}
                  maxCount={validation.paragraphMaxChar}
                  onChange={Actions.changeParagraph}
                  className="WM_GLOBAL_paragraph"
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
            </S.TextContainer>
            <S.LogosWrap areFiveBlocks={clientsLogos.length === 5}>
              <Mapper
                data={clientsLogos}
                render={(logo: string, logoIdx: number) => {
                  return (
                    <S.LogoFigure key={logoIdx}>
                      <ControlsWithModalOpener
                        actions={Actions.getClientsActions({
                          showRemove,
                          logoIdx,
                          keyForSizes: 'clientsLogos',
                          imageUrl: logo.logoUrl,
                          link: logo.clientUrl
                        })}
                      >
                        <Image
                          as={S.Img}
                          alt="Trusted logos"
                          sizes={logo.logoDimensions}
                          defaultImgSrc={logo.logoUrl}
                          asProps={{
                            onClick: () =>
                              !!logo.clientUrl &&
                              openUrl(getExternalUrl(logo.clientUrl))
                          }}
                          hasUrl={!!logo.clientUrl}
                        />
                      </ControlsWithModalOpener>
                    </S.LogoFigure>
                  )
                }}
              />
            </S.LogosWrap>
            <AddButton
              onAdd={Actions.addLogo}
              toShow={clientsLogos.length < validation.clientsLogos.max}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_logo_label')}
            </AddButton>
          </S.Card>
        </S.CardWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Clients3
