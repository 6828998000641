/* @flow */
import React, { useContext, memo } from 'react'
import S from './Basic1.styled'
import { EditingContext } from '@contexts'

const Basic1 = memo(props => {
  const {
    data: { backgroundImgUrl, backgroundImgDimensions, bgOverlayTransparency }
  } = props

  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    />
  )
})

export default Basic1
