// @flow

import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import Popup from '@editor/common/lib/Popup'
import Button from '@editor/common/lib/Button'
import * as Styled from './styled'
import * as CONSTS from './consts'
import { selectStatusChangeAndPublishPopupState } from '@selectors'
import { closeStatusChangeAndPublishPopup } from '@actions/editor'
import { publishProject } from '@actions/project'
import { translate } from '@editor/common/utils/translations'

import type { TStatusChangeAndPublishPopupProps } from './types'

const TITLE = translate('want_visitors_see_website_popup_title')
const DESCRIPTION = translate('want_visitors_see_website_popup_description')
const OFFLINE_BUTTON_TEXT = translate('publish_and_stay_offline_label')
const ONLINE_BUTTON_TEXT = translate('publish_and_go_online_label', true)

const StatusChangeAndPublishPopup = ({
  isOpen,
  publishProject,
  closeStatusChangeAndPublishPopup
}: TStatusChangeAndPublishPopupProps) => {
  const handlePublish = useCallback((needToGoOnline: boolean): void => {
    publishProject(needToGoOnline)
    closeStatusChangeAndPublishPopup()
  }, [])

  return (
    <Popup isOpen={isOpen} onClose={closeStatusChangeAndPublishPopup}>
      <Styled.CenteredContentWrapper>
        <Styled.PopupContentWrapper>
          <Styled.Title>{TITLE}</Styled.Title>
          <Styled.Description>{DESCRIPTION}</Styled.Description>
          <Styled.ButtonsWrapper>
            <Button type="secondary" onClick={() => handlePublish(false)}>
              {OFFLINE_BUTTON_TEXT}
            </Button>
            <Button type="primary" onClick={() => handlePublish(true)}>
              {ONLINE_BUTTON_TEXT}
            </Button>
          </Styled.ButtonsWrapper>
        </Styled.PopupContentWrapper>
      </Styled.CenteredContentWrapper>
    </Popup>
  )
}

const mapStateToProps = state => ({
  isOpen: selectStatusChangeAndPublishPopupState(state)
})

const mapDispatchToProps = {
  publishProject,
  closeStatusChangeAndPublishPopup
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatusChangeAndPublishPopup)
