/* @flow */
import styled from 'styled-components'
import THEME from './Video4.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: 600px) {
    padding: 60px 0;
  }
  .LazyLoad {
    width: 100%;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 900px;
  text-align: center;
  > :first-child {
    margin-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('title')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('title')};
  font-weight: 500;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin: 20px 0 0 0;
`

const AddButtonWrap = styled.div`
  display: flex;
  @media only screen and (max-width: 1023px) {
    justify-content: center;
  }
`
const VideosContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${props => (props.isForBlock ? '900px' : '1200px')};
  margin-top: -50px;
  padding-top: 50px;
  @media only screen and (max-width: 1023px) {
    margin-top: -40px;
    padding-top: 40px;
  }
`

const VideoItemWrap = styled.div`
  width: ${props => (props.isForBlock ? '45%' : '30%')};
  flex-grow: ${props => (props.isForBlock ? '1' : '')};
  margin: 50px 1vw 0 1vw;
  border-radius: ${getStyleForProp('cardRadius')};
  &:only-child {
    width: 80%;
    max-width: 800px;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1023px) {
    width: 45%;
    margin: 40px 2vw 0 2vw;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 40px 0 0 0;
  }
`

const PlayerWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin-bottom: 20px;
  position: relative;
  border-radius: ${getStyleForProp('cardRadius')};
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  iframe {
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  Paragraph,
  AddButtonWrap,
  VideosContainer,
  PlayerWrapper,
  VideoItemWrap,
  Overlay,
  BlockTitle
}
