/* @flow */
import styled from 'styled-components'
import { getTextStyleByAlignment } from '@website/common/utils'

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${props => (props.isEditing ? '100px 0 0 0' : '0')};
  background-color: ${({ theme }) => theme.palette.primaryDark};
`

const BlocksWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
  > *:first-child {
    margin-top: 0;
  }
  .add-button-wrap {
    position: relative;
    z-index: 5;
  }
`

const Block = styled.article`
  background-color: ${({ theme }) => theme.palette.primaryDark};
  position: relative;
  width: 30%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  padding: 80px 40px;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 20px;
  }
  .link-section {
    margin-top: 20px;
  }
  .learn-more-link {
    color: #ffffff;
    svg {
      fill: #ffffff;
    }
  }
  .control-container {
    margin-bottom: 30px;
    &.editable-control-container {
      width: 100%;
      h2,
      p {
        margin-top: 0;
      }
    }
  }
`

const BlockLayer = styled.div`
  opacity: ${props => (props.isEven ? '0.2' : '0')};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
`

const Title = styled.h2`
  color: #ffffff;
  z-index: 1;
  width: 100%;
  font-weight: 600;
  margin: 20px 0 0 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: #ffffff;
  width: 100%;
  line-height: 1.4;
  z-index: 1;
  margin-top: 20px;
  ${getTextStyleByAlignment};
`

export default {
  Container,
  Block,
  BlocksWrap,
  BlockLayer,
  Title,
  Paragraph
}
