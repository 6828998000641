// @flow
import React, { memo, useCallback, useContext, useMemo, useRef } from 'react'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

import { BoldComp } from '@editor/common/styled-components/simple-tags'
import * as Styled from './styled'
import type { TComponentThumbnailProps } from './types'
import { ThumbnailButtons } from './components'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import ThumbnailImage from '@editor/common/components/ThumbnailImage'
import ThumbnailNewLabel from '@editor/common/assets/thumbnail_new_label.svg'
import AnimationLabel from '@editor/common/assets/svgr-icons/Animated_component_label.svg'
import { COMPONENT_CATEGORIES, NEW_COMPONENTS } from '@editor/conf/consts'
import { MobileDetectContext } from '@contexts'

const showName = NODE_ENV === 'development'

const ComponentThumbnail = ({
  componentMeta,
  selectedIndex,
  isCompReplacing,
  isMultiSelectionActive,
  onClick,
  onSelect,
  addOrReplace,
  isLargerGrid
}: TComponentThumbnailProps) => {
  const isMobile = useContext(MobileDetectContext)
  const isSelected = selectedIndex !== -1
  const videoRef = useRef(null)

  const {
    id,
    thumbnail,
    thumbnailRatio,
    category,
    thumbnailVideo,
    hasAnimation
  } = componentMeta
  const isHeader = category === COMPONENT_CATEGORIES.HEADER
  const isFooter = category === COMPONENT_CATEGORIES.FOOTER
  const isRSVP = category === COMPONENT_CATEGORIES.RSVP

  const tooltipText = useMemo(() => {
    const _category =
      category === COMPONENT_CATEGORIES.RSVP
        ? category.toUpperCase()
        : category.charAt(0).toUpperCase() + category.slice(1)

    const hasTooltipText =
      (isMobile &&
        isMultiSelectionActive &&
        (isHeader || isFooter || isRSVP)) ||
      (isMultiSelectionActive &&
        !isSelected &&
        (isHeader || isFooter || isRSVP))

    return hasTooltipText
      ? transformTextVariables(translate('can_only_one_category'), [
          {
            value: _category,
            Comp: BoldComp
          }
        ])
      : ''
  }, [
    isHeader,
    isFooter,
    isMultiSelectionActive,
    isSelected,
    isMobile,
    category
  ])

  const onMouseEnter = useCallback(() => {
    videoRef.current && videoRef.current.play()
  }, [videoRef])

  const onMouseLeave = useCallback(() => {
    videoRef.current && videoRef.current.pause()
  }, [videoRef])

  return (
    <TooltipWrapper text={tooltipText}>
      <Styled.ComponentThumbnailWrapper
        isSelected={isSelected}
        isMultiSelectionActive={isMultiSelectionActive}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Styled.ComponentThumbnail>
          {showName && <p>{id}</p>}
          {hasAnimation ? (
            <Styled.AnimationSign>
              <TooltipWrapper
                text={!isMobile ? translate('animated_label') : ''}
                position="bottom"
              >
                <AnimationLabel />
              </TooltipWrapper>
            </Styled.AnimationSign>
          ) : null}
          <ThumbnailImage src={thumbnail} imageRatio={thumbnailRatio} />
          {NEW_COMPONENTS.indexOf(id) !== -1 && !isSelected && (
            <Styled.NewLabel src={ThumbnailNewLabel} alt="new label" />
          )}
        </Styled.ComponentThumbnail>
        {thumbnailVideo && !isMobile ? (
          <video
            ref={videoRef}
            playsInline
            muted
            loop
            src={thumbnailVideo}
            className="video-thumb"
          />
        ) : null}
        <ThumbnailButtons
          key={isLargerGrid}
          isCompReplacing={isCompReplacing}
          isSmallerGrid={!isLargerGrid}
          onSelect={onSelect}
          addOrReplace={addOrReplace}
        />
        {isSelected ? <Styled.Index>{selectedIndex + 1}</Styled.Index> : null}
        <Styled.Overlay isSelected={isSelected} />
      </Styled.ComponentThumbnailWrapper>
    </TooltipWrapper>
  )
}

export default memo(ComponentThumbnail)
