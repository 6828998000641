/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature36.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-top: ${({ isEditing }) => isEditing && '160px'};
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => isEditing && '140px'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: ${({ isOnlyLeft, isEditing }) =>
    isOnlyLeft && !isEditing ? 'center' : 'space-between'};
  align-items: center;
  padding-top: 0;
  ${({ imagesCount }) =>
    imagesCount === 1 &&
    css`
      > div:first-child {
        > div:first-child {
          transform: ${({ theme }) =>
            theme.orientation === 'LEFT'
              ? 'translateX(12%)'
              : 'translateX(20%)'};
          @media only screen and (max-width: 1024px) {
            transform: translateX(12%);
          }
        }
      }
      > div:nth-child(2) {
        @media only screen and (min-width: 1024px) {
          transform: ${({ isEditing, theme }) =>
            theme.orientation === 'LEFT' && isEditing
              ? 'translateX(0%)'
              : 'translateX(0%)'};
              : 'translateX(15%)'};
        }
      }
    `}
  @media only screen and (max-width: 1024px) {
    flex-direction: ${({ theme }) =>
      theme.orientation === 'RIGHT' ? 'column-reverse' : 'column'};
  }
  .learn-more-link {
    color: ${getStyleForProp('link')};
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
  > div:first-child {
    @media only screen and (min-width: 1441px) {
      width: ${({ isOnlyLeft, isEditing }) =>
        isOnlyLeft && !isEditing ? '100%' : '64%'};
    }
    @media only screen and (max-width: 1440px) and (min-width: 1025px) {
      width: ${({ isOnlyLeft, isEditing }) =>
        isOnlyLeft && !isEditing ? '100%' : 'calc(100% - 435px)'};
    }
  }
`

const TextImageContent = styled.div`
  width: 64%;
  max-width: 920px;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: 1440px) {
    width: calc(100% - 435px);
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  transition: transform 0.3s ease-out;
  @media only screen and (max-width: 1024px) {
    transform: none !important;
  }
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    height: initial;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
`

const AdditionalParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

const ImageWrapper = styled.div`
  position: relative;
  ${({ imagesCount }) =>
    imagesCount === 3
      ? css`
          &:nth-child(2) {
            transform: translate(60%, -29%);
            figure {
              transform: rotate(3deg) scale(0.94);
            }
          }
          &:first-child {
            transform: translate(2.5%, -150%);
          }
        `
      : imagesCount === 2
      ? css`
          &:nth-child(2) {
            figure {
              transform: rotate(3deg) scale(0.94);
            }
          }
          &:first-child {
            transform: translate(2.5%, -92.5%);
          }
        `
      : css`
          &:first-child {
            transform: translate(2.5%, -71%);
          }
        `}
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    transform: none;
  }
  &:first-child {
    width: 34.2%;
    > div {
      padding-top: 66%;
      .lazy-figure {
        padding-top: 66%;
      }
    }
  }
  &:nth-child(2) {
    width: 39.13%;
    > div {
      padding-top: 125%;
      .lazy-figure {
        padding-top: 125%;
      }
    }
  }
  &:nth-child(3) {
    width: 21.79%;
    transform: translateX(-296%);
    > div {
      padding-top: 127.5%;
      .lazy-figure {
        padding-top: 127.5%;
      }
    }
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: ${getStyleForProp('cardRadius')};
`

const ContentWrap = styled.div`
  width: 32.1%;
  height: 100%;
  max-width: 450px;
  @media only screen and (max-width: 1440px) {
    width: 395px;
    max-width: initial;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
    ${({ theme }) =>
      theme.orientation === 'RIGHT'
        ? css`
            margin-bottom: 40px;
          `
        : css`
            margin-top: 40px;
          `};
  }
  @media only screen and (min-width: 1025px) {
    margin-top: 30px;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
  }
  .link-section {
    margin-top: 30px;
  }
`
const MainImageFigure = styled.div`
  width: 42%;
  max-width: 385px;
  margin-right: auto;
  margin-left: auto;
  transform: translateX(0%);
  position: relative;
  .LazyLoad {
    position: relative;
    padding-top: 138.6%;
    height: auto;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('cardRadius')};
  }
  .add-button-wrap {
    position: absolute;
    left: -25%;
    top: 10%;
    @media only screen and (max-width: 650px) {
      left: -45%;
      top: 5%;
    }
  }
  .rf-add-btn {
    padding: 15px;
    .icon {
      margin-right: auto;
    }
  }
`
const MainImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: ${getStyleForProp('cardRadius')};
`

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: ${({ imagesCount }) =>
    imagesCount === 3 ? '-31%' : imagesCount === 2 ? '-44%' : '-22.5%'};
  ${({ isAnimated, isOnScreen, isEditing }) =>
    isAnimated &&
    isOnScreen &&
    !isEditing &&
    css`
      figure {
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
      }
      > div {
        &:first-child {
          figure {
            animation-name: feature36DownUp;
            animation-duration: 4s;
            animation-delay: -3s;
            &:hover {
              animation-play-state: paused;
            }
          }
        }
        &:nth-child(2) {
          figure {
            animation-name: feature36DownUpRotate;
            animation-duration: 3s;
            animation-delay: -1.8s;
            &:hover {
              animation-play-state: paused;
            }
          }
        }
        &:nth-child(3) {
          figure {
            animation-name: feature36DownUp;
            animation-duration: 4.5s;
            animation-delay: -4s;
            &:hover {
              animation-play-state: paused;
            }
          }
        }
      }
    `}
  @keyframes feature36DownUp {
    0% {
      transform: translateY(0px);
    }

    100% {
      transform: translateY(-12px);
      @media only screen and (max-width: 1150px) {
        transform: translateY(-9px);
      }
      @media only screen and (max-width: 768px) {
        transform: translateY(-6px);
      }
    }
  }
  @keyframes feature36DownUpRotate {
    0% {
      transform: translateY(0px) rotate(3deg) scale(0.94);
    }

    100% {
      transform: translateY(-12px) rotate(3deg) scale(0.94);
      @media only screen and (max-width: 1150px) {
        transform: translateY(-9px) rotate(3deg) scale(0.94);
      }
      @media only screen and (max-width: 768px) {
        transform: translateY(-6px) rotate(3deg) scale(0.94);
      }
    }
  }
`

const AdditionalBlockWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
  }
  .link-section {
    margin-top: 30px;
  }
  ${({ imagesCount }) =>
    imagesCount !== 3 &&
    css`
      margin-top: 30px;
    `}
`

const AdditionalBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  max-width: 386px;
  margin-right: 0;
  @media only screen and (min-width: 1025px) {
    ${({ imagesCount }) =>
      imagesCount === 3 &&
      css`
        transform: translate(105px, -70px);
        margin-bottom: -70px;
      `}
  }
  @media only screen and (max-width: 1440px) and (min-width: 1025px) {
    ${({ imagesCount }) =>
      imagesCount === 3 &&
      css`
        width: 57%;
        transform: translate(32%, -50px);
        margin-bottom: -50px;
      `}
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
  }
`
const ArrowWrapper = styled.div`
  position: absolute;
  width: 31%;
  z-index: 2;
  left: -38%;
  top: 4%;
  @media only screen and (max-width: 1325px) and (min-width: 1025px) {
    width: 25%;
    top: -1%;
    left: -34%;
  }
  @media only screen and (max-width: 768px) {
    width: 25%;
    height: 80px;
    top: 2%;
    left: -34%;
  }
  @media only screen and (max-width: 650px) {
    height: 80px;
  }
  @media only screen and (max-width: 550px) {
    width: 22%;
    height: 60px;
    top: 0;
  }
  svg.rotated-arrow {
    width: 100%;
    height: 100%;
    path {
      fill: ${getStyleForProp('heading')};
    }
  }
`

const ImagesWrap = styled.div`
  width: 100%;
  > .add-button-wrap {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  AdditionalParagraph,
  Paragraph,
  TextImageContent,
  ImageWrapper,
  Img,
  ControlContainer,
  ContentWrap,
  MainImageFigure,
  MainImg,
  ImagesContainer,
  AdditionalBlockWrap,
  AdditionalBlock,
  ArrowWrapper,
  ImagesWrap
}
