/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text6.styled'
import { validation } from './Text6.consts'
import Icon from '@website/common/components/Icon'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'

const Text6 = memo(props => {
  const dispatcher = useContext(DispatchContext)
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      icon,
      additionalText,
      additionalUrl,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <Show when={[title, paragraph, icon, additionalText, additionalUrl]}>
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WMCustomContainer className="text-icon-container">
          <S.TextCompWrap>
            <If
              condition={!!icon}
              otherwise={() => (
                <AddButton onAdd={Actions.addIcon} type="icon" large toShow>
                  +
                </AddButton>
              )}
              then={() => (
                <S.IconWrap>
                  <ControlsWithModalOpener actions={Actions.iconActions({})}>
                    <S.Circle>
                      <Icon name={icon} color="white" size="large" />
                    </S.Circle>
                  </ControlsWithModalOpener>
                </S.IconWrap>
              )}
            />
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleCount}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.maxParagraph}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <AdditionalLink
              index={null}
              additionalLinkText={additionalText}
              additionalLinkUrl={additionalUrl}
            />
          </S.TextCompWrap>
        </S.WMCustomContainer>
      </S.StyledContainer>
    </Show>
  )
})

export default Text6
