import styled from 'styled-components'

import LibPopup from '@renderforest/rf-ui-library/organism/Popup'
import LibButton from '@editor/common/lib/Button'

export const EventCreationPopup = styled(LibPopup)`
  z-index: 1000;
  & > div {
    padding: 0;
    max-height: 96%;
    max-width: 580px;
    & > div {
      padding: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const PopupContent = styled.div`
  padding: 40px 70px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`

export const Heading = styled.h1`
  color: #252e48;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 20px auto 10px;
  text-align: center;
  max-width: 360px;
`

export const SecondaryInfoText = styled.p`
  color: #545f7e;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  max-width: 460px;
  font-weight: 400;
  margin-bottom: 30px;
`

export const Button = styled(LibButton)`
  padding: 11px 30px;
  margin-top: 20px;
`

export const Step = styled.div`
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #545f7e;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

export const StepsSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const StepIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-weight: 700;
  color: white;
  text-align: center;
  line-height: 20px;
  margin-right: 10px;
  background: linear-gradient(180deg, #d973d9 0%, #b08def 100%);
  box-shadow: inset 0px 2px 2px rgba(255, 255, 255, 0.44);
`

export const Illustration = styled.img`
  width: 200px;
  height: 200px;
`
