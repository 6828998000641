/* @flow */

export const validation = {
  maxTitleChar: 120,
  maxParagraphChar: 1500,
  maxSlideContentChar: 1500,
  maxCustomerNameChar: 50,
  maxCustomerPositionChar: 50,
  slides: {
    min: 1,
    max: 10
  }
}