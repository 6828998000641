/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Portfolio14.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const LARGE_DESCTOP_SIZE = 1440
const LARGE_SIZE = 1024
const TABLET_SIZE = 768
const MOBILE_SIZE = 457
const IMAGE_RATIO = '107.514%'

export const getStyleForProp = styleProvider(THEME)

const generateBorderRadius = ({ TLradius, TRradius, BLradius, BRradius }) => `
  border-top-left-radius: ${TLradius || 0};
  border-top-right-radius: ${TRradius || 0};
  border-bottom-left-radius: ${BLradius || 0};
  border-bottom-right-radius: ${BRradius || 0};
`

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: ${TABLET_SIZE + 1}px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : ' 80px 0')};
  }
`

export const WmCustomContainer = styled(WMContainer)`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > div:first-child {
    padding-top: 0;
  }
  .add-button-wrap {
    margin: 40px 0;
  }
`
export const HeadingWrap = styled.div`
  margin: 0 auto;
  width: 100%;
  & > p:first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

export const HeadingParagraph = styled.div`
  & > p:first-child {
    padding-top: 0;
  }
`

export const BlockContentWrap = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 30px;
  width: 100%;
  row-gap: 10px;
  display: flex;
  flex-direction: column;
  ${({ theme }) =>
    generateBorderRadius({
      TLradius: getStyleForProp('borderRadius')({ theme }),
      TRradius: getStyleForProp('borderRadius')({ theme }),
      BLradius: 0,
      BRradius: 0
    })};
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    padding: 20px;
  }
  & > .control-container {
    margin-bottom: 0px;
    width: 100%;
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 50px;
  width: 100%;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    margin-bottom: 40px;
  }
`

export const BlocksWrap = styled.div`
  column-gap: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${({ isFourBlock }) => (isFourBlock ? '800px' : '1400px')};
  margin-top: -45px;
  padding-top: 45px;
  position: relative;
  row-gap: 20px;
  width: 100%;
  @media only screen and (max-width: ${LARGE_DESCTOP_SIZE}px) {
    max-width: ${({ isFourBlock }) => (isFourBlock ? '800px' : '1200px')};
  }
`
export const BlockImgWrap = styled.div`
  font-size: 0;
  position: relative;
  width: 100%;
  @media only screen and (max-width: 587px) {
    max-width: initial;
  }
`

export const BlockItemWrap = styled.div`
  align-items: center;
  border-radius: ${getStyleForProp('borderRadius')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column-reverse;
  flex-grow: 1;
  max-width: 453px;
  position: relative;
  text-align: center;
  width: ${({ isFourBlock }) => (isFourBlock ? '34%' : '30%')};
  ${BlockContentWrap} {
    background-color: ${getStyleForProp('oddBlockBG')};
  }
  .paintLine {
    display: none;
    path {
      stroke: ${getStyleForProp('paintLine')};
    }
  }
  .paintLine2 {
    display: none;
    path {
      stroke: ${getStyleForProp('paintLine2')};
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          & > .control-container {
            box-sizing: border-box;
            flex-direction: column-reverse;
            padding: 20px;
          }
          &:nth-child(odd) > .control-container {
            ${BlockContentWrap} {
              background-color: ${getStyleForProp('blockBG')};
            }
          }
          &:nth-child(3) {
            .paintLine2 {
              display: block;
              position: absolute;
              right: 20px;
              top: -3px;
              z-index: 2;
              @media only screen and (max-width: ${LARGE_SIZE}px) {
                  display: none;
              }
            }
          }
          &:nth-child(4) {
            .paintLine {
              display: block;
              left: 0;
              position: absolute;
              top: 0;
              z-index: 2;
              @media only screen and (max-width: ${LARGE_SIZE}px) {
                  display: none;
              }
            }
          }
          &:nth-child(3n - 1) > .control-container {
            flex-direction: column;
            ${BlockContentWrap} {
              ${({ theme }) =>
                generateBorderRadius({
                  TLradius: 0,
                  TRradius: 0,
                  BLradius: getStyleForProp('borderRadius')({ theme }),
                  BRradius: getStyleForProp('borderRadius')({ theme })
                })};
            }
            .lazy-figure {
              ${({ theme }) =>
                generateBorderRadius({
                  TLradius: getStyleForProp('borderRadius')({ theme }),
                  TRradius: getStyleForProp('borderRadius')({ theme }),
                  BLradius: 0,
                  BRradius: 0
                })};
            }
          }
        `
      : css`
          box-shadow: ${getStyleForProp('raisedShadow')};
          &:nth-child(3) {
            .paintLine2 {
              display: block;
              position: absolute;
              right: 0px;
              top: -23px;
              z-index: 2;
              width: 113px;
            }
          }
          &:nth-child(4) {
            .paintLine {
              display: block;
              position: absolute;
              top: -15px;
              left: -15px;
              z-index: 2;
              width: 248px;
            }
          }
          &:nth-child(odd) {
            ${BlockContentWrap} {
              background-color: ${getStyleForProp('blockBG')};
            }
          }
          &:nth-child(3n - 1) {
            flex-direction: column;
            ${BlockContentWrap} {
              ${({ theme }) =>
                generateBorderRadius({
                  TLradius: 0,
                  TRradius: 0,
                  BLradius: getStyleForProp('borderRadius')({ theme }),
                  BRradius: getStyleForProp('borderRadius')({ theme })
                })};
            }
            .lazy-figure {
              ${({ theme }) =>
                generateBorderRadius({
                  TLradius: getStyleForProp('borderRadius')({ theme }),
                  TRradius: getStyleForProp('borderRadius')({ theme }),
                  BLradius: 0,
                  BRradius: 0
                })};
            }
          }
        `};

  & > .control-container {
    height: 100%;
    width: 100%;
  }

  .lazy-figure {
    border-bottom-left-radius: ${getStyleForProp('borderRadius')};
    border-bottom-right-radius: ${getStyleForProp('borderRadius')};
    height: initial;
    padding-top: ${IMAGE_RATIO};
  }
  @media only screen and (max-width: ${LARGE_DESCTOP_SIZE}px) {
    max-width: 386px;
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    width: 40%;
    max-width: 301px;
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) and (min-width: 587px) {
      &:nth-child(4n - 1) {
          ${BlockContentWrap} {
              background-color: ${getStyleForProp('oddBlockBG')};
          }
      }
      &:nth-child(4n) {
          ${BlockContentWrap} {
              background-color: ${getStyleForProp('blockBG')};
          }
      }
      &:nth-child(6n + 3) {
          ${BlockContentWrap} {
              ${({ theme }) =>
                      generateBorderRadius({
                          TLradius: 0,
                          TRradius: 0,
                          BLradius: getStyleForProp('borderRadius')({ theme }),
                          BRradius: getStyleForProp('borderRadius')({ theme })
                      })};

          }
          .lazy-figure {
              ${({ theme }) =>
                      generateBorderRadius({
                          TLradius: getStyleForProp('borderRadius')({ theme }),
                          TRradius: getStyleForProp('borderRadius')({ theme }),
                          BLradius: 0,
                          BRradius: 0
                      })};
          }

      }
      ${({ isEditing }) =>
        isEditing
          ? css`
             &:nth-child(4n) {
                 ${BlockContentWrap} {
                     background-color: ${getStyleForProp('blockBG')};
                 }
             }
              &:nth-child(6n + 3) {
                  > .control-container {
                      flex-direction: column;
                  }
              }
          ` : css`
              &:nth-child(6n + 3) {
                  flex-direction: column;
              }
          `
    }
  } 
  @media only screen and (max-width: 587px) {
      width: 100%;
      max-width: 100%;
  }
`

export const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockParagraph')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

export const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  line-height: 1.4;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const ControlContainer = styled.div`
  position: relative;
  padding-top: ${IMAGE_RATIO};
  width: 100%;
  .control-container {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .LazyLoad {
    position: absolute;
    height: initial;
    left: 0;
    top: 0;
    width: 100%;
  }
  .lazy-figure {
    padding-top: ${IMAGE_RATIO};
    height: initial;
  }
`

export const Img = styled.img`
  display: block;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
`
