// @flow

import http from '@shared/ApiFactory'
import { SM_API_PATH } from '@shared/ApiConfig'
import objToQs from '@editor/common/utils/obj-to-qs'

type TComponentsListApiParams = {
  limit?: number,
  offset?: number,
  categoryId?: string,
  search?: string
}
type TComponentIds = Array<string>

export const getComponentCategoriesAPI = () =>
  http.GET(`${SM_API_PATH}/components-categories`)

export const getComponentsListAPI = (
  generatorVersion: string,
  options: TComponentsListApiParams
) => {
  const queryString = objToQs(options)
  return http.GET(`${SM_API_PATH}/components${queryString}`, null, {
    editorVersion: generatorVersion
  })
}

export const getComponentsDataAPI = (ids: TComponentIds) =>
  http.GET(`${SM_API_PATH}/components/data?ids=${JSON.stringify(ids)}`)
