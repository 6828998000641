/* @flow */
import styled, {css} from 'styled-components'

import THEME from './Clients2.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: ${({ isEditing }) => (isEditing ? '120px 0 100px' : '80px 0')};
  @media only screen and (max-width: 457px) {
    padding: 60px 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${({ isEditing }) => (isEditing ? 'padding-top: 50px' : null)};
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const CompanyLogosFigure = styled.div`
  width: 24%;
  min-width: 210px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  .lazy-figure {
    display: flex;
    align-items: center;
  }
`

const CliensContent = styled.div`
  width: 45%;
  @media only screen and (max-width: 992px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 40px 0;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
  padding-top: 20px;
`

const LogosWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('cardRadius')};
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  &:only-child {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
  }
  .control-container {
    height: 100%;
    max-height: 100px;
  }
  .failed-image-container {
    width: 140px;
    max-height: 100px;
  }
`

const Img = styled.img`
  width: 100%;
  height: auto;
  transition: all 0.1s linear;
  max-width: 140px;
  max-height: 100px;
  display: block;
  object-fit: contain;
  &:hover {
    opacity: 1;
  }
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
`

export default {
  Img,
  StyledContainer,
  WmCustomContainer,
  CompanyLogosFigure,
  CliensContent,
  Title,
  Description,
  LogosWrap
}
