// @flow

import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as Styled from '../styled'
import WebsiteStatusDesktop from './WebsiteStatusDesktop'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import WebsiteStatusIcon from '@editor/common/assets/svgr-icons/Preview_icon.svg'
import CrownIcon from '@editor/common/assets/crown_gear.svg'
import { translate } from '@editor/common/utils/translations'

import {
  selectDomain,
  selectSitePublishedState,
  isSubscriber
} from '@selectors'
import type { TWebsiteStatusProps } from './types'

const WebsiteStatus = ({
  hasDomain,
  isUserSubscribed,
  isSitePublished
}: TWebsiteStatusProps) => {
  const [isOpen, setOpenedState] = useState(false)
  const isOptionDisabled = hasDomain && !isSitePublished
  const tooltipText = isOptionDisabled
    ? translate('status_will_be_available_after_publishing_label')
    : ''

  const toggle = () => {
    !isOptionDisabled && setOpenedState(!isOpen)
  }

  return (
    <TooltipWrapper
      offsets={{ x: 0, y: 8 }}
      position="bottom"
      text={tooltipText}
      mobileTimer={7000}
    >
      <>
        <Styled.PublishOptionItem onClick={toggle} disabled={isOptionDisabled}>
          <WebsiteStatusIcon />
          <span>{translate('website_status_label')}</span>
          {!isUserSubscribed ? <Styled.CrownGear src={CrownIcon} /> : null}
        </Styled.PublishOptionItem>
        {!isOptionDisabled ? <WebsiteStatusDesktop isOpen={isOpen} /> : null}
      </>
    </TooltipWrapper>
  )
}

const mapStateToProps = state => ({
  isUserSubscribed: isSubscriber(state),
  hasDomain: !!selectDomain(state),
  isSitePublished: selectSitePublishedState(state)
})

export default connect(mapStateToProps)(WebsiteStatus)
