import { hexToRgb } from '@website/common/utils'

export default {
  DARK: {
    text: p => p.accentWhite,
    backgroundColor: p => p.tertiaryDark,
    borderColor: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`,
    mobileSelectedContainerBgColor: p => `rgba(${hexToRgb(p.accentWhite)}, 0.1)`
  },
  LIGHT: {
    text: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryLight,
    borderColor: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`,
    mobileSelectedContainerBgColor: p =>
      `rgba(${hexToRgb(p.tertiaryDark)}, 0.1)`
  },
  EXTRALIGHT: {
    text: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryExtraLight,
    borderColor: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`,
    mobileSelectedContainerBgColor: p =>
      `rgba(${hexToRgb(p.tertiaryDark)}, 0.1)`
  }
}
