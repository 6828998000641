// @flow

import React, {
  useEffect,
  useContext,
  memo,
  useCallback,
  useState,
  useRef
} from 'react'

import { EditingContext } from '@contexts'
import SecondaryControls from '@editor/common/components/SecondaryControls'
import { createHoverEvent, CONTROL_HOVER_EVENT } from './consts'
import { StyledControlContainer } from './styled'
import type { TControlsProps } from './types'

const Controls = ({
  hideControls,
  actions,
  alignment,
  children,
  style,
  className,
  isRelativeToWrapper,
  additionalHoverElement,
  ...rest
}: TControlsProps) => {
  const { isEditing } = useContext(EditingContext)
  const [isHovered, setHoveredState] = useState(false)
  const [wrapperEl, setWrapperEl] = useState(null)
  const timerRef = useRef(null)

  const hover = useCallback(() => {
    setHoveredState(true)
  }, [])

  const unHover = useCallback(() => {
    setHoveredState(false)
  }, [])

  const handleMouseOver = useCallback(
    e => {
      e.stopPropagation()
      clearTimeout(timerRef.current)
      const event = createHoverEvent({ hoveredEl: wrapperEl })
      document.dispatchEvent(event)
      hover()
    },
    [hover, wrapperEl]
  )

  const handleMouseLeave = useCallback(
    e => {
      if (
        e.toElement &&
        additionalHoverElement &&
        additionalHoverElement.contains(e.toElement)
      ) {
        return
      }
      e.stopPropagation()
      timerRef.current = setTimeout(unHover, [500])
    },
    [unHover, additionalHoverElement]
  )

  const handleAnyControlHover = useCallback(
    event => {
      const { hoveredEl } = event.detail
      if (hoveredEl === wrapperEl) {
        return
      }
      clearTimeout(timerRef.current)
      unHover()
    },
    [wrapperEl, unHover]
  )

  useEffect(() => {
    document.addEventListener(CONTROL_HOVER_EVENT, handleAnyControlHover)
    return () => {
      document.removeEventListener(CONTROL_HOVER_EVENT, handleAnyControlHover)
    }
  }, [handleAnyControlHover])

  useEffect(() => {
    if (wrapperEl) {
      wrapperEl.addEventListener('touchstart', handleMouseOver, false)
      wrapperEl.addEventListener('mouseover', handleMouseOver, false)
      wrapperEl.addEventListener('mouseleave', handleMouseLeave, false)
      if (additionalHoverElement) {
        additionalHoverElement.addEventListener(
          'mouseleave',
          handleMouseLeave,
          false
        )
      }
    }

    return () => {
      if (wrapperEl) {
        wrapperEl.removeEventListener('touchstart', handleMouseOver, false)
        wrapperEl.removeEventListener('mouseover', handleMouseOver, false)
        wrapperEl.removeEventListener('mouseleave', handleMouseLeave, false)
        if (additionalHoverElement) {
          additionalHoverElement.removeEventListener(
            'mouseleave',
            handleMouseLeave,
            false
          )
        }
      }
    }
  }, [handleMouseOver, handleMouseLeave, wrapperEl, additionalHoverElement])

  if (!actions || !actions.length || !isEditing) {
    return children
  }

  return (
    <StyledControlContainer
      hideControls={hideControls}
      className={`control-container ${isHovered ? 'hovered' : ''} ${
        className ? className : ''
      }`}
      style={style}
      hovered={isHovered}
      ref={setWrapperEl}
      {...rest}
    >
      {children}
      {isHovered ? (
        <SecondaryControls
          targetEl={wrapperEl}
          actions={actions}
          hideControls={hideControls}
          alignment={alignment}
          isRelativeToWrapper={isRelativeToWrapper}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        />
      ) : null}
    </StyledControlContainer>
  )
}

export default memo(Controls)
