import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    subTitle: p => p.primaryLight,
    blockHeading: p => p.tertiaryDark,
    paragraph: p => p.accentWhite,
    dateText: p => p.accentWhite,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, .3)`,
    backgroundColor: p => p.tertiaryDark,
    cardBackgroundColor: p => p.tertiaryDark,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    subTitle: p => p.primaryDark,
    blockHeading: p => p.tertiaryLight,
    paragraph: p => p.tertiaryDark,
    dateText: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, .3)`,
    backgroundColor: p => p.tertiaryLight,
    cardBackgroundColor: p => p.tertiaryLight,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    subTitle: p => p.primaryDark,
    blockHeading: p => p.accentWhite,
    paragraph: p => p.tertiaryDark,
    dateText: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, .3)`,
    backgroundColor: p => p.tertiaryExtraLight,
    cardBackgroundColor: p => p.tertiaryExtraLight,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`
  }
}
