/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature15_2.styled'
import ActionGetter from './Actions'
import { validation } from './Feature15_2.consts'
import Show from '@website/common/components/Show'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import If from '@website/common/components/Conditional'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Feature15_2 = memo(props => {
  const {
    data: {
      title,
      subTitle,
      paragraph,
      ctaText,
      titleAlignment,
      subTitleAlignment,
      paragraphAlignment,
      ctaTextAlignment,
      features,
      buttonUrl,
      buttonText
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <Show when={[title, paragraph, subTitle]}>
          <S.HeadingWrap>
            <Show when={[title, subTitle]}>
              <S.HeadingParagraph>
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  as={S.Title}
                  maxCount={validation.headingMaxChar}
                  className="WM_GLOBAL_heading54"
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <EditableContent
                  text={subTitle}
                  alignment={subTitleAlignment}
                  as={S.SubTitle}
                  maxCount={validation.subHeadingMaxChar}
                  className="WM_GLOBAL_heading32"
                  onChange={Actions.changeSubTitle}
                  changeAlignment={Actions.changeSubTitleAlignment}
                />
              </S.HeadingParagraph>
            </Show>
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.HeadingWrap>
        </Show>
        <S.BlocksWrap
          isTwoBlock={
            features.length === 2 || features.length === 4 ? true : false
          }
        >
          <Mapper
            data={features}
            render={(item: string, idx: number) => {
              const withoutParagraph = !item.paragraph
              return (
                <S.BlockItemWrap
                  key={idx}
                  withoutParagraph={withoutParagraph && !isEditing}
                >
                  <Show when={[item.badgeText]}>
                    <S.Badge>
                      <EditableContent
                        text={item.badgeText}
                        as={S.BadgeText}
                        maxCount={validation.badgeMaxChar}
                        className="WM_GLOBAL_secondary-font"
                        onChange={(newBadgeText: string) =>
                          Actions.changeBadgeText(newBadgeText, idx)
                        }
                      />
                    </S.Badge>
                  </Show>
                  <div className="block-img-wrap">
                    <S.ControlContainer>
                      <ControlsWithImageCropper
                        actions={Actions.featureActions(
                          idx,
                          features.length,
                          item.imgUrl
                        )}
                        style={{ width: '100%' }}
                      >
                        <Image
                          as={S.Img}
                          alt="item img"
                          sizes={item.imgDimensions}
                          defaultImgSrc={item.imgUrl}
                          asProps={{ imgCropParams: item.imgCropParams }}
                        />
                      </ControlsWithImageCropper>
                    </S.ControlContainer>
                    <EditableContent
                      text={item.count}
                      alignment={item.countAlignment}
                      as={S.Count}
                      maxCount={validation.countMaxChar}
                      className="WM_GLOBAL_secondary-font count"
                      onChange={(newCountText: string) =>
                        Actions.changeCountText(newCountText, idx)
                      }
                      changeAlignment={(alignment: string) =>
                        Actions.changeCountAlignment(alignment, idx)
                      }
                    />
                  </div>

                  <Show when={[item.paragraph]}>
                    <S.BlockContentWrap>
                      <EditableContent
                        text={item.paragraph}
                        as={S.BlockParagraph}
                        alignment={item.blockParagraphAlignment}
                        maxCount={validation.blockParagraphMaxChar}
                        className="WM_GLOBAL_secondary-font"
                        onChange={(newItemParagraph: string) =>
                          Actions.changeItemParagraph(newItemParagraph, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }
                      />
                    </S.BlockContentWrap>
                  </Show>
                </S.BlockItemWrap>
              )
            }}
          />
        </S.BlocksWrap>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={features.length < validation.features.max}
          style={{ margin: '40px 0' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
        <If
          condition={ctaText || buttonText || buttonUrl}
          otherwise={() => (
            <AddButton
              onAdd={Actions.addBottomBlock}
              toShow
              style={{ margin: '20px 0' }}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_text_block_label')}
            </AddButton>
          )}
          then={() => (
            <S.CtaWrapContainer>
              <ControlsWithModalOpener
                actions={Actions.joinBlockAction}
                style={{ width: '100%' }}
              >
                <S.CtaWrap>
                  <EditableContent
                    text={ctaText}
                    alignment={ctaTextAlignment}
                    as={S.CtaText}
                    maxCount={validation.ctaHeadingMaxChar}
                    className="WM_GLOBAL_secondary-font"
                    onChange={(newCtaText: string) =>
                      Actions.changeCtaText(newCtaText)
                    }
                    changeAlignment={Actions.changeCtaTextAlignment}
                  />
                  <S.BtnWrap>
                    <ControlsWithModalOpener
                      actions={Actions.btnActions(
                        0,
                        {
                          text: buttonText,
                          link: buttonUrl
                        },
                        false
                      )}
                    >
                      <LinkWrapper
                        url={buttonUrl}
                        text={buttonText}
                        linkProps={{
                          className: 'WM_GLOBAL_secondary-font'
                        }}
                      />
                    </ControlsWithModalOpener>
                  </S.BtnWrap>
                </S.CtaWrap>
              </ControlsWithModalOpener>
            </S.CtaWrapContainer>
          )}
        />
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature15_2
