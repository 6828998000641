// @flow

import React, { memo } from 'react'
import {
  EditingContext,
  MobileDetectContext,
  SiteIdContext,
  SiteHashContext,
  ProjectLinksContext,
  ExtraLinksLabelContext,
  ControlModalsContext,
  GDPRPrivacyPolicyContext,
  WebsiteLanguagesContext
} from '@contexts'
import { translate } from '@editor/common/utils/translations'

import { HeaderNavbarContextWrapper } from '@contexts/HeaderNavBar.context'
import { useResponsive } from '@shared/hooks'

const Providers = ({
  children,
  projectLinks,
  extraLinksLabel,
  siteId,
  siteHash,
  gdprPrivacyPolicy,
  websiteLanguagesData
}) => {
  const isMobile = useResponsive(768)

  return (
    <EditingContext.Provider value={{ isEditing: false }}>
      <MobileDetectContext.Provider value={isMobile}>
        <ControlModalsContext.Provider value={{}}>
          <HeaderNavbarContextWrapper>
            <SiteIdContext.Provider value={siteId}>
              <SiteHashContext.Provider value={siteHash}>
                <ExtraLinksLabelContext.Provider
                  value={extraLinksLabel || translate('more_label')}
                >
                  <ProjectLinksContext.Provider value={projectLinks}>
                    <GDPRPrivacyPolicyContext.Provider
                      value={gdprPrivacyPolicy}
                    >
                      <WebsiteLanguagesContext.Provider
                        value={websiteLanguagesData}
                      >
                        {children}
                      </WebsiteLanguagesContext.Provider>
                    </GDPRPrivacyPolicyContext.Provider>
                  </ProjectLinksContext.Provider>
                </ExtraLinksLabelContext.Provider>
              </SiteHashContext.Provider>
            </SiteIdContext.Provider>
          </HeaderNavbarContextWrapper>
        </ControlModalsContext.Provider>
      </MobileDetectContext.Provider>
    </EditingContext.Provider>
  )
}

export default memo(Providers)
