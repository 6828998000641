/* @flow */
import React from 'react'
import ReactDOM from 'react-dom'
import ReactPlayer from 'react-player'
import * as S from './styled'
import CloseIcon from '@website/common/assets/svgr-icons/close.svg'
import { KEY_CODES } from '@website/conf/consts'
import { useKeyPress } from '@hooks'

const removeCurrentTimeFromUrl = (url: string) => {
  const regExp = /(&)?t=([0-9-_]+)&?/
  return url.replace(regExp, '')
}

const VideoPopup = ({ url, closeVideoPopup }) => {
  useKeyPress({
    onPress: closeVideoPopup,
    withBuffer: false,
    keyCode: KEY_CODES.ESC
  })

  if (typeof window === 'undefined') {
    return null
  }

  const video = () => (
    <>
      <S.CloseIcon
        color="white"
        SvgComp={CloseIcon}
        size="xlarge"
        onClick={closeVideoPopup}
      />
      <S.VideoElem>
        <ReactPlayer
          loop
          url={removeCurrentTimeFromUrl(url)}
          playing={true}
          controls={true}
          style={{ height: '100%', width: '100%' }}
        />
      </S.VideoElem>
    </>
  )

  return ReactDOM.createPortal(
    <S.VideoPopupContainer windowHeight={window.innerHeight}>
      {video()}
    </S.VideoPopupContainer>,
    document.body
  )
}

export default VideoPopup
