/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_IMAGE':
        {
          const { url, idx, dimensions } = action.value
          draft.images[idx].imgUrl = url
          draft.images[idx].imgDimensions = dimensions || {}
          draft.images[idx].imgCropParams = {}
        }
        break
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx } = action.value
          draft.images[idx].imgCropParams = imgCropParams
        }
        break
      case 'CHANGE_BUTTON_DATA':
        {
          const { link, text } = action.value.btnData
          draft.btnUrl = link
          draft.btnText = text
        }
        break
      case 'REMOVE_BUTTON':
        draft.btnUrl = ''
        draft.btnText = ''
        break
      case 'ADD_BUTTON':
        draft.btnText = initialState.btnText
        draft.btnUrl = initialState.btnUrl
        break
    }
  })
}
