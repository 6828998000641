/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Contact1.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 779px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  @media only screen and (max-width: 1680px) and (min-width: 1280px) {
    max-width: 1040px;
  }
`

const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 50px auto;
  max-width: 1000px;
  width: 100%;
  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    width: 100%;
    p {
      padding-top: 0;
    }
  }
  .control-container:nth-child(2) {
    margin-top: 20px;
  }
`

const EmailTextWrap = styled.div`
  display: flex;
  margin-top: 16px;
`

const ContactItemsWrap = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  align-items: center;
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
    justify-content: center;
  }
  & > div {
    flex: 1 1 26%;
  }
  & > .control-container {
    padding: 10px;
    @media only screen and (max-width: 1280px) {
      margin-bottom: 40px;
    }
    p {
      margin-top: 0 !important;
    }
    & > div {
      margin-top: 0 !important;
    }
  }
`

const SocialsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    margin-bottom: 0;
  }
`

const Title = styled.h1`
  max-width: 1000px;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const SocialTitle = styled.p`
  font-size: 16px;
  color: ${getStyleForProp('heading')};
  margin-bottom: 6px;
  text-align: center;
`

const EmailWrap = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.palette.primaryDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:only-child {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 1280px) {
    margin-top: 40px;
  }
`

const EmailTitle = styled.p`
  color: ${getStyleForProp('paragraph')};
  font-size: 16px;
  margin-top: 2px;
  font-weight: 400;
`
const Email = styled.a`
  color: ${getStyleForProp('mailText')};
  font-size: 16px;
  margin-top: 2px;
  text-decoration: none;
  font-weight: 400;
`
const EmailTo = styled.a`
  color: ${({ theme }) => theme.palette.primaryDark};
  text-decoration: none;
`

const SocialItems = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 16px;
  @media only screen and (max-width: 800px) {
    margin-top: 30px;
  }
  .link {
    border-radius: ${getStyleForProp('iconRadius')};
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
    ${getStyleForProp('buttonBackground')};
  }
  .rf-add-btn {
    padding: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: initial;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextWrap,
  SocialsWrap,
  EmailTextWrap,
  ContactItemsWrap,
  Title,
  Paragraph,
  SocialItems,
  SocialTitle,
  EmailWrap,
  EmailTitle,
  EmailTo,
  Email
}
