import styled, { createGlobalStyle } from 'styled-components'
import Button from '@renderforest/rf-ui-library/atoms/Button'

const MOBILE_SIZE = 767

export const GlobalStyles = createGlobalStyle`
  .maintenance-popup > div {
    @media only screen and (max-width: ${MOBILE_SIZE}px) {
      max-width: 96vw;
      width: 100vw;
    }
    padding: 0;
    > div {
      padding: 0;
    }
  }
`

export const StyledContainer = styled.div`
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    padding: 50px 13px 30px;
    width: 100%;
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 50px 40px;
  max-width: 560px;
  width: 560px;
  box-sizing: border-box;
`

export const StyledHeader = styled.h3`
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    font-size: 18px;
  }
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #353e5a;
`

export const StyledContent = styled.p`
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    font-size: 14px;
  }
  font-size: 16px;
  line-height: 22px;
  color: #363f5a;
  margin-top: 15px;
  text-align: center;
`

export const StyledButton = styled(Button)`
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    margin-top: 30px;
  }
  width: 100px;
  margin-top: 35px;
`
