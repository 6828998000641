/* @flow */
import React, { useContext, memo, useMemo } from 'react'
import S from './PayPal4.styled'
import ActionGetter from './Actions'
import { validation } from './PayPal4.consts'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { EditingContext, DispatchContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'
import { PayPalButtonForm } from '@website/common/components/PayPalButton'
import { getCurrencySign } from '@website/common/utils'

const PayPal4 = memo(props => {
  const {
    data: {
      supTitle,
      supTitleAlignment,
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      blocks,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      primaryPlanIdx
    },
    componentId
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const hasBlocksIcon = useMemo(() =>
    blocks.some(elem => elem.iconName, [blocks])
  )

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WMCustomContainer>
        <EditableContent
          isEditing={isEditing}
          text={supTitle}
          alignment={supTitleAlignment}
          required={!title && !paragraph}
          maxCount={validation.supTitleMaxChar}
          className="WM_GLOBAL_heading20"
          as={S.SupParagraph}
          onChange={Actions.changeSupTitle}
          changeAlignment={Actions.changeSupTitleAlignment}
        />

        <EditableContent
          text={title}
          alignment={titleAlignment}
          required={!supTitle && !paragraph}
          as={S.Title}
          maxCount={validation.titleMaxChar}
          className="WM_GLOBAL_heading42"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          isEditing={isEditing}
          text={paragraph}
          alignment={paragraphAlignment}
          required={!supTitle && !title}
          maxCount={validation.paragraphMaxChar}
          className="WM_GLOBAL_paragraph18"
          as={S.SubParagraph}
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.PayPalItemWrap
          isFourBlock={blocks.length === 4}
          isEditing={isEditing}
        >
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => {
              const isPrimary = idx === primaryPlanIdx
              const isSelling = block.paypalBtnProps.type === 'selling'
              const {
                currency,
                productName,
                headline,
                productPrice,
                productDiscountedPrice
              } = block.paypalBtnProps

              const paypalBtnDefaultProps = block.paypalBtnProps
              return (
                <ControlsWithModalOpener
                  key={idx}
                  actions={Actions.cardAction({
                    idx,
                    showStar: !isPrimary && blocks.length > 1,
                    showRemove: blocks.length > validation.blocks.min
                  })}
                >
                  <S.PayPalItemCardWrapp
                    isPrimary={isPrimary}
                    isEditing={isEditing}
                  >
                    <S.PayPalItemCard
                      isEditing={isEditing}
                      isPrimary={isPrimary}
                    >
                      <Show when={[!!block.iconName, headline, productName]}>
                        <S.IconTitileContainer isEditing={isEditing}>
                          <S.IconContainer hasBlocksIcon={hasBlocksIcon}>
                            <If
                              condition={!!block.iconName}
                              otherwise={() => (
                                <AddButton
                                  onAdd={() => Actions.addIcon(idx)}
                                  type="icon"
                                  large
                                  toShow
                                >
                                  +
                                </AddButton>
                              )}
                              then={() => (
                                <article>
                                  <ControlsWithModalOpener
                                    actions={Actions.iconActions({ idx })}
                                  >
                                    <S.Circle>
                                      <S.Icon
                                        size="xxlarge"
                                        name={block.iconName}
                                        isPrimary={isPrimary}
                                      />
                                    </S.Circle>
                                  </ControlsWithModalOpener>
                                </article>
                              )}
                            />
                          </S.IconContainer>
                          <EditableContent
                            as={S.ItemTitle}
                            text={isSelling ? productName : headline}
                            required
                            alignment={block.titleAlignment}
                            className="WM_GLOBAL_heading20"
                            isTypingDisabled
                            isTextFormatingHidden
                            customActions={Actions.paypalButtonModalActions(
                              paypalBtnDefaultProps,
                              componentId,
                              { idx }
                            )}
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockTitleAlignment(alignment, idx)
                            }
                          />
                        </S.IconTitileContainer>
                      </Show>
                      <EditableContent
                        as={S.ItemName}
                        isEditing={isEditing}
                        text={block.name}
                        required={!block.description}
                        alignment={block.nameAlignment}
                        maxCount={validation.blockDescriptionMaxChar}
                        onChange={(newName: string) =>
                          Actions.changeBlockName(newName, idx)
                        }
                        className="WM_GLOBAL_paragraph14"
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockNameAlignment(alignment, idx)
                        }
                      />
                      {isSelling ? (
                        <>
                          <S.PriceContainer isEditing={isEditing}>
                            <EditableContent
                              as={S.PriceCount}
                              text={`${getCurrencySign(
                                currency
                              )} ${productPrice}`}
                              className="WM_GLOBAL_heading32"
                              isTypingDisabled
                              isTextFormatingHidden
                              customActions={Actions.paypalButtonModalActions(
                                paypalBtnDefaultProps,
                                componentId,
                                { idx }
                              )}
                            />
                            {productDiscountedPrice ? (
                              <EditableContent
                                as={S.PrevPriceCount}
                                text={`${getCurrencySign(
                                  currency
                                )} ${productDiscountedPrice}`}
                                className="WM_GLOBAL_heading20"
                                isTypingDisabled
                                isTextFormatingHidden
                                customActions={Actions.paypalButtonModalActions(
                                  paypalBtnDefaultProps,
                                  componentId,
                                  { idx }
                                )}
                              />
                            ) : null}
                          </S.PriceContainer>
                          <EditableContent
                            as={S.ItemDescription}
                            isPrimary={isPrimary}
                            text={block.description}
                            required={!block.name}
                            alignment={block.descriptionAlignment}
                            maxCount={validation.blockNameMaxChar}
                            className="WM_GLOBAL_paragraph14"
                            onChange={(newDescription: string) =>
                              Actions.changeBlockDescription(
                                newDescription,
                                idx
                              )
                            }
                            changeAlignment={(alignment: string) =>
                              Actions.changeDescriptionAlignment(alignment, idx)
                            }
                          />
                        </>
                      ) : null}
                      <S.BlockList>
                        <Mapper
                          data={block.list}
                          render={(listItem, listIdx) => (
                            <ControlsWithModalOpener
                              key={listIdx}
                              style={{ display: 'block' }}
                              actions={Actions.listActions(
                                idx,
                                listIdx,
                                block.list.length > validation.blockList.min
                              )}
                            >
                              <S.ListItem isPrimary={isPrimary}>
                                <S.Icon size="large" name="check" />
                                <EditableContent
                                  text={listItem}
                                  maxCount={validation.checkmarkMaxChar}
                                  as={S.BlockListItem}
                                  className="WM_GLOBAL_paragraph18"
                                  onChange={(newBlockListTitle: string) =>
                                    Actions.changeBlockListItem(
                                      newBlockListTitle,
                                      idx,
                                      listIdx
                                    )
                                  }
                                />
                              </S.ListItem>
                            </ControlsWithModalOpener>
                          )}
                        />
                        <AddButton
                          style={{
                            marginTop: '1rem'
                          }}
                          toShow={block.list.length < validation.blockList.max}
                          onAdd={() => Actions.addBlockListItem(idx)}
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_item_label')}
                        </AddButton>
                      </S.BlockList>
                      <S.PayButtonsContainer isPrimary={isPrimary}>
                        <PayPalButtonForm
                          data={blocks[idx].paypalBtnProps}
                          compId={componentId}
                          reducerProps={{ idx }}
                        />
                        <AdditionalLink
                          idx={idx}
                          additionalLinkText={block.additionalText}
                          additionalLinkUrl={block.additionalUrl}
                        />
                      </S.PayButtonsContainer>
                    </S.PayPalItemCard>
                  </S.PayPalItemCardWrapp>
                </ControlsWithModalOpener>
              )
            }}
          />
        </S.PayPalItemWrap>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={blocks.length < validation.blocks.max}
          style={{
            marginTop: '40px'
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default PayPal4
