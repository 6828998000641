// @flow

import React, { PureComponent, Node } from 'react'
import { createPortal } from 'react-dom'
import { StyledTooltipContent, StyledTooltip, StyledTriangle } from './styled'
import type { TooltipWrapperPropsType, TooltipWrapperStateType } from './types'
import { getAvailablePositionInfo } from './utils'
import { POSITIONS } from '@website/common/components/TooltipWrapper/consts'

class TooltipWrapper extends PureComponent<
  TooltipWrapperPropsType,
  TooltipWrapperStateType
> {
  wrapperRef: HTMLDivElement | null = null
  tooltipRef: HTMLDivElement | null = null
  timer = undefined
  delayTimer = undefined

  state: TooltipWrapperStateType = {
    isOpen: this.props.isOpen || false,
    position: this.props.position | POSITIONS.TOP,
    coords: {
      x: 0,
      y: 0
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this.close, true)
    window.addEventListener('mouseup', e => {
      if (
        this.tooltipRef &&
        this.wrapperRef &&
        !this.wrapperRef.contains(e.target) &&
        !this.tooltipRef.contains(e.target)
      ) {
        this.close()
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        isOpen: this.props.isOpen
      })
    }
  }

  setPosition = (): void => {
    const { wrapperRef, tooltipRef } = this

    const { position, coords } = getAvailablePositionInfo(
      wrapperRef,
      tooltipRef,
      this.props.position,
      this.props.offsets
    )
    this.setState({ coords, position })
  }

  handleWrapperRef = (el: HTMLDivElement): void => {
    this.wrapperRef = el
  }

  handleTooltipRef = (el: HTMLDivElement): void => {
    this.tooltipRef = el
    this.setPosition()
  }

  open = (): void => {
    const { delay = 0 } = this.props

    this.delayTimer = setTimeout(() => {
      this.setState({
        isOpen: true
      })
    }, delay)
  }

  close = (): void => {
    clearTimeout(this.delayTimer)

    this.setState({
      isOpen: false
    })
    this.props.onClose && this.props.onClose()
  }

  toggle = (): void => {
    this.setState((state: TooltipWrapperStateType) => ({
      isOpen: !state.isOpen
    }))
  }

  handleTouchStart = (): void => {
    this.toggle()
    // @ts-ignore
    clearTimeout(this.timer)
    this.timer = setTimeout(this.close, this.props.mobileTimer || 4000)
  }

  render(): Node {
    const {
      children,
      className = '',
      text,
      color,
      withClick = false,
      content,
      offsets,
      disableHover
    } = this.props
    const { isOpen, position, coords } = this.state
    const hideTooltip = !text && !content

    if (React.Children.count(children) !== 1) {
      return null
    }

    const listeners = disableHover
      ? {}
      : withClick
      ? { onClick: this.toggle }
      : {
          onMouseEnter: this.open,
          onMouseLeave: this.close,
          onTouchStart: this.handleTouchStart
        }

    return (
      <>
        <div className={className} ref={this.handleWrapperRef} {...listeners}>
          {children}
        </div>
        {isOpen && !hideTooltip
          ? createPortal(
              <StyledTooltipContent
                ref={this.handleTooltipRef}
                coords={coords}
                offsets={offsets}
                className={`${className}_content`}
              >
                {content ? (
                  content
                ) : (
                  <>
                    <StyledTooltip color={color}>{text}</StyledTooltip>
                    <StyledTriangle position={position} color={color} />
                  </>
                )}
              </StyledTooltipContent>,
              document.body
            )
          : null}
      </>
    )
  }
}

export default TooltipWrapper
