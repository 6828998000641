/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header44.styled'
import THEME from './Header44.theme'
import { validation } from './Header44.consts'
import ActionGetter from './Actions'
import Frame from './Header44_frame'
import NavBar from '@website/common/components/NavBar'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import SocialLinks from '@website/common/components/SocialLinks'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const Header44 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      blocks,
      buttons,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      bgOverlayTransparency,
      backgroundImgDimensions,
      mainImageUrl,
      imgCropParams,
      imgDimensions,
      headerSocials
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const [navBarHeight, setNavBarHeight] = useState(0)

    const showRemove = blocks.length > validation.blocks.min

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
        isEditing={isEditing}
      >
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <S.WMCustomContainer topOffset={navBarHeight}>
          <S.HeaderContent>
            <S.TopContent>
              <S.LeftContent>
                <S.ImageWrapper>
                  <S.FrameWrapper>
                    <Frame />
                  </S.FrameWrapper>
                  <S.ImageControlContainer className="main-image">
                    <ControlsWithImageCropper
                      actions={Actions.getImgActions(mainImageUrl)}
                      style={{ width: '100%' }}
                    >
                      <Image
                        as={S.Img}
                        sizes={imgDimensions}
                        defaultImgSrc={mainImageUrl}
                        asProps={{ imgCropParams }}
                        alt="Hero illustration"
                      />
                    </ControlsWithImageCropper>
                  </S.ImageControlContainer>
                </S.ImageWrapper>
              </S.LeftContent>
              <S.RightContent>
                <EditableContent
                  text={title}
                  required
                  alignment={titleAlignment}
                  maxCount={validation.headingMaxChar}
                  isEditing={isEditing}
                  className="WM_GLOBAL_heading42"
                  as={S.Title}
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  alignment={paragraphAlignment}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
                <Buttons data={buttons} />
                <SocialLinks
                  className="header-socials"
                  socials={headerSocials}
                  socialsMaxCount={validation.headerSocials.max}
                />
              </S.RightContent>
            </S.TopContent>
            <S.BottomContent>
              <S.BlocksWrap
                isFourBlock={blocks.length === 4}
                isEditing={isEditing}
              >
                <Mapper
                  data={blocks}
                  render={(block: string, idx: number) => {
                    return (
                      <S.ControlContainer
                        key={idx}
                        isEditing={isEditing}
                        isThreeBlock={blocks.length === 3}
                      >
                        <ControlsWithModalOpener
                          actions={Actions.blockActions({
                            idx,
                            showRemove
                          })}
                          style={{ width: '100%' }}
                          alignment="right"
                        >
                          <S.ItemWrapper>
                            <S.ItemImageWrapper>
                              <S.ImageControlContainer className="block-image">
                                <ControlsWithImageCropper
                                  actions={Actions.blockImageActions({
                                    idx,
                                    imageUrl: block.imgUrl
                                  })}
                                  style={{ width: '100%' }}
                                >
                                  <Image
                                    as={S.Img}
                                    sizes={block.imgDimensions}
                                    defaultImgSrc={block.imgUrl}
                                    asProps={{
                                      imgCropParams: block.imgCropParams
                                    }}
                                    alt="Block image"
                                  />
                                </ControlsWithImageCropper>
                              </S.ImageControlContainer>
                            </S.ItemImageWrapper>
                            <S.ItemContentWrapper>
                              <EditableContent
                                text={block.title}
                                as={S.BlockTitle}
                                alignment={block.blockTitleAlignment}
                                required={validation.blockTitle.required}
                                maxCount={validation.blockTitleMaxChar}
                                className="WM_GLOBAL_heading20"
                                onChange={(newTitle: string) =>
                                  Actions.changeBlockTitle(newTitle, idx)
                                }
                                isIcon={block.iconName}
                                isEditing={isEditing}
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockTitleAlignment(
                                    alignment,
                                    idx
                                  )
                                }
                              />
                              <EditableContent
                                text={block.paragraph}
                                as={S.BlockParagraph}
                                alignment={block.blockParagraphAlignment}
                                maxCount={validation.blockParagraphMaxChar}
                                className="WM_GLOBAL_paragraph14"
                                onChange={(newTitle: string) =>
                                  Actions.changeBlockParagraph(newTitle, idx)
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockParagraphAlignment(
                                    alignment,
                                    idx
                                  )
                                }
                              />
                              <AdditionalLink
                                idx={idx}
                                additionalLinkText={block.additionalText}
                                additionalLinkUrl={block.additionalUrl}
                              />
                            </S.ItemContentWrapper>
                          </S.ItemWrapper>
                        </ControlsWithModalOpener>
                      </S.ControlContainer>
                    )
                  }}
                />
              </S.BlocksWrap>
              <AddButton
                onAdd={Actions.addBlock}
                toShow={blocks.length < validation.blocks.max}
                style={{
                  margin: '30px 0'
                }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_block_label')}
              </AddButton>
            </S.BottomContent>
          </S.HeaderContent>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Header44
