/* @flow */
import React from 'react'
import Slider from 'react-slick'
import S from './Header38.styled'
import THEME from './Header38.theme'
import ActionGetter from './Actions'
import { validation, slickSliderSettings } from './Header38.consts'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import NavBar from '@website/common/components/NavBar'
import AddButton from '@website/common/components/AddButton'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { getFormatedNumber } from '@website/common/utils'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled/'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import {
  EditingContext,
  withDispatchContext,
  MobileDetectContext
} from '@contexts'
import '../Swipe/swipe-slider-styles.css'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

class Header38 extends React.PureComponent<void, void> {
  sliderRef: null
  state = { activeSlideIdx: 0, navBarHeight: 0 }

  handleHeightChange = height => {
    this.setState({ navBarHeight: height })
  }

  getPrevIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + slides.length - 1) % slides.length
  }
  getNextIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + 1) % slides.length
  }
  onPrevClick = () => {
    const { activeSlideIdx } = this.state
    this.changeActiveSlideIndex(this.getPrevIdx(activeSlideIdx))
    this.sliderRef.slickPrev()
  }

  onNextClick = () => {
    const { activeSlideIdx } = this.state
    this.changeActiveSlideIndex(this.getNextIdx(activeSlideIdx))
    this.sliderRef.slickNext()
  }

  beforeRemove = () => {
    const { activeSlideIdx } = this.state
    if (activeSlideIdx !== 0) {
      this.setState({ activeSlideIdx: activeSlideIdx - 1 })
    }
  }

  changeActiveSlideIndex = (activeSlideIdx: number) =>
    this.setState({
      activeSlideIdx
    })

  getSliderCounter = () => {
    const { activeSlideIdx } = this.state
    const { slides } = this.props.data

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.SliderIdx isEditing={isEditing}>
              <span className="active-slide-idx">
                {`${getFormatedNumber(activeSlideIdx + 1)}`}
              </span>
              <span className="slide-count">{`/${getFormatedNumber(
                slides.length
              )}`}</span>
            </S.SliderIdx>
          )
        }}
      </EditingContext.Consumer>
    )
  }

  renderSlideImg = Actions => {
    const { slides } = this.props.data
    const { activeSlideIdx } = this.state
    const { img1, img2 } = slides[activeSlideIdx]

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => (
          <S.SliderItemsContainer
            isOneSlide={slides.length === 1}
            isEditing={isEditing}
          >
            <S.SliderItemWrapper isEditing={isEditing}>
              <S.ControlContainer>
                <ControlsWithImageCropper
                  toPropagate={false}
                  actions={Actions.sliderActions({
                    idx: activeSlideIdx,
                    key: 'img1',
                    imageUrl: img1.url
                  })}
                >
                  <Image
                    as={S.Img}
                    defaultImgSrc={img1.url}
                    sizes={img1.imgDimensions}
                    alt="Section image"
                    asProps={{ imgCropParams: img1.imgCropParams }}
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.SliderItemWrapper>
            <S.SliderItemWrapper isEditing={isEditing}>
              <S.ControlContainer>
                <ControlsWithImageCropper
                  toPropagate={false}
                  actions={Actions.sliderActions({
                    idx: activeSlideIdx,
                    key: 'img2',
                    imageUrl: img2.url
                  })}
                >
                  <Image
                    as={S.Img}
                    defaultImgSrc={img2.url}
                    sizes={img2.imgDimensions}
                    alt="Section image"
                    asProps={{ imgCropParams: img2.imgCropParams }}
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.SliderItemWrapper>
          </S.SliderItemsContainer>
        )}
      </EditingContext.Consumer>
    )
  }

  renderSlider = Actions => {
    const { slides } = this.props.data
    return (
      <S.SliderWrapper>
        <Slider
          {...slickSliderSettings}
          className="slider mySlider"
          ref={ref => (this.sliderRef = ref)}
        >
          {this.renderSlideImg(Actions)}
        </Slider>
        <S.SliderAddRemoveButtons>
          <AddButton
            onAdd={() => Actions.addSlide()}
            toShow={slides.length < validation.slides.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_slide_label')}
          </AddButton>
          <AddButton
            onAdd={() => {
              this.beforeRemove()
              Actions.removeSlide(this.state.activeSlideIdx)
            }}
            toShow={slides.length > validation.slides.min}
          >
            <Icon name="glyph-remove" className="icon" size="normal" />
            {translate('remove_slide_label')}
          </AddButton>
        </S.SliderAddRemoveButtons>
        {this.renderActionsBar(slides.length)}
      </S.SliderWrapper>
    )
  }

  renderTextContent = Actions => {
    const {
      data: {
        title,
        paragraph,
        buttons,
        socialText,
        socials,
        titleAlignment,
        paragraphAlignment
      }
    } = this.props
    return (
      <S.LeftContent>
        <S.HeaderContent>
          <EditingContext.Consumer>
            {({ isEditing }) => (
              <S.HGroup>
                <EditableContent
                  text={title}
                  as={S.Title}
                  isEditing={isEditing}
                  alignment={titleAlignment}
                  required={validation.title.required}
                  maxCount={validation.headingMaxChar}
                  className="WM_GLOBAL_heading42"
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  isEditing={isEditing}
                  alignment={paragraphAlignment}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </S.HGroup>
            )}
          </EditingContext.Consumer>
          <Buttons data={buttons} />
          <S.SocialWrap>
            <EditableContent
              text={socialText}
              as={S.SocialText}
              maxCount={validation.socialTextMaxChar}
              className="WM_GLOBAL_paragraph14"
              onChange={Actions.changeSocialText}
            />
            <S.SocialIcons>
              <Mapper data={socials} render={this.renderSocialIcon(Actions)} />
            </S.SocialIcons>
            <AddButton
              onAdd={() => Actions.addSocial()}
              toShow={socials.length < validation.socials.max}
              type="icon"
              large
              style={{ marginTop: '10px' }}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
            </AddButton>
          </S.SocialWrap>
          {this.renderSlider(Actions)}
        </S.HeaderContent>
      </S.LeftContent>
    )
  }

  renderSocialIcon = Actions => (social, idx) =>
    (
      <MobileDetectContext.Consumer>
        {isMobile => (
          <ControlsWithModalOpener
            key={idx}
            actions={Actions.socialActions(idx, social.socialUrl)}
            alignment={!isMobile || idx <= 3 ? 'left' : 'right'}
          >
            <LinkWrapper
              url={social.socialUrl}
              linkProps={{ 'aria-label': 'Social link' }}
            >
              <S.SocialIcon size="middle" name={social.icon} />
            </LinkWrapper>
          </ControlsWithModalOpener>
        )}
      </MobileDetectContext.Consumer>
    )

  renderActionsBar = slidesLength => {
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.SliderArrowsInfo isHidden={!isEditing && slidesLength === 1}>
              <S.SliderActionButtons
                className="actions-bar"
                id={'slider-' + slidesLength}
              >
                <S.IconContainer
                  className="wm-slider-btn left"
                  onClick={this.onPrevClick}
                >
                  <S.SliderArrowIcons
                    size={26}
                    mobileSize={36}
                    name="icon-arrow_small_left"
                  />
                </S.IconContainer>
                {this.getSliderCounter()}
                <S.IconContainer
                  className="wm-slider-btn right"
                  onClick={this.onNextClick}
                >
                  <S.SliderArrowIcons
                    size={26}
                    mobileSize={36}
                    name="icon-arrow_small_right"
                  />
                </S.IconContainer>
              </S.SliderActionButtons>
            </S.SliderArrowsInfo>
          )
        }}
      </EditingContext.Consumer>
    )
  }

  render() {
    const {
      data: {
        logoSrc,
        logoColor,
        logoWidth,
        logoDimensions,
        imageUrl,
        imgCropParams,
        imgDimensions
      },
      componentDispatcher
    } = this.props

    const Actions = ActionGetter(componentDispatcher)

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.Container topOffset={this.state.navBarHeight}>
              <NavBar
                handleHeightChange={this.handleHeightChange}
                alwaysShowBackground
                theme={THEME}
                logoProps={{
                  logoSrc: logoSrc,
                  logoColor: logoColor,
                  logoWidth: logoWidth,
                  logoSizes: logoDimensions
                }}
              />
              <S.WmCustomContainer isEditing={isEditing}>
                {this.renderTextContent(Actions)}
                <S.RightContent>
                  <S.RightImageContainer isEditing={isEditing}>
                    <S.ImageWrap>
                      <S.ImageFigure>
                        <ControlsWithImageCropper
                          actions={Actions.imgActions(imageUrl)}
                          style={{ width: '100%' }}
                        >
                          <Image
                            as={S.Image}
                            alt="news"
                            defaultImgSrc={imageUrl}
                            asProps={{ imgCropParams }}
                            sizes={imgDimensions}
                          />
                        </ControlsWithImageCropper>
                      </S.ImageFigure>
                    </S.ImageWrap>
                  </S.RightImageContainer>
                </S.RightContent>
              </S.WmCustomContainer>
            </S.Container>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}
const Header38withDispatch = withDispatchContext(Header38)

export default Header38withDispatch
