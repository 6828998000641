/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header27.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgbA,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0 0 160px 0' : `${topOffset}px 0 160px 0`};
  @media only screen and (max-width: 768px) {
    padding-bottom: 34px;
    background: ${getStyleForProp('background')};
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: 20px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ isOnlyBlocks }) => (isOnlyBlocks ? '100px' : '40px')};
  padding-bottom: ${({ isOnlyBlocks }) => (isOnlyBlocks ? '100px' : '40px')};
  @media only screen and (max-width: 768px) {
    padding-top: ${({ isOnlyBlocks }) => (isOnlyBlocks ? '14vw' : '40px')};
    padding-bottom: ${({ isOnlyBlocks }) => (isOnlyBlocks ? '14vw' : '40px')};
  }
`

const HeaderContent = styled.div`
  padding: ${({ isOnlyBlocks }) =>
    isOnlyBlocks ? '0' : '120px 40px 100px 40px'};
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    padding: 100px 40px;
    background: ${({ bgOverlayTransparency, backgroundImgUrl, ...rest }) => {
      const bgColor = hexToRgbA(
        getStyleForProp('backgroundColor')(rest),
        bgOverlayTransparency
      )

      if (!backgroundImgUrl) {
        return `${getStyleForProp('background')(rest)}`
      }

      return `linear-gradient(${bgColor}, ${bgColor}), url(${backgroundImgUrl})`
    }};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  & > .control-container {
    width: 100%;
    margin: 0 auto;
    @media only screen and (min-width: 1025px) {
      max-width: 800px;
    }
    & > h1 {
      width: 100%;
      max-width: unset;
      margin: 0;
    }
  }
`

const Description = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
`

const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -10px;
  @media only screen and (max-width: 1023px) {
    flex-wrap: wrap;
  }
`

const ControlWrapper = styled.div`
  display: flex;
  margin: 6px 6px 0 6px;
  width: 19%;
  max-width: 300px;
  min-width: 200px;
  flex-grow: 1;
  & > .control-container {
    display: flex;
  }
  @media only screen and (max-width: 1023px) {
    width: ${props => (props.isFourOrTwoBlock ? '100%' : '19%')};
    margin: 6px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 260px;
  }
  @media only screen and (max-width: 430px) {
    margin: 10px 0 0 0;
    max-width: initial;
  }
`

const BlockItem = styled.article`
  width: 100%;
  height: 100%;
  padding: 40px 30px 20px 30px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${getStyleForProp('cardRadius')};
  ${props =>
    props.isEven
      ? css`
          background: ${getStyleForProp('cardBgLight')};
        `
      : css`
          background: ${getStyleForProp('cardBg')};
        `}
  @media only screen and (max-width: 430px) {
    padding: 20px;
  }
  & > .control-container {
    width: 100%;
    & > h2 {
      margin-bottom: 0;
    }
    + .control-container {
      margin-top: 10px;
      margin-bottom: 30px;
    }
    p {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .link-section {
    margin-top: auto;
  }
  .learn-more-link {
    color: ${({ theme }) => theme.palette.accentWhite};
    svg {
      fill: ${({ theme }) => theme.palette.accentWhite};
    }
  }
`

const BlockTitle = styled.h2`
  width: 100%;
  color: ${getStyleForProp('blockText')};
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockText')};
  ${getTextStyleByAlignment};
  margin: 10px 0 30px 0;
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Description,
  BlocksContainer,
  ControlWrapper,
  BlockItem,
  BlockTitle,
  BlockParagraph
}
