/* @flow */
import React, { useContext, memo } from 'react'
import S from './Steps2.styled'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { validation } from './Steps2.consts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const isEven = (idx: number) => idx % 2 === 1
const Steps2 = memo(props => {
  const {
    data: { title, paragraph, imageSteps, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.headingMaxChar}
          className="WM_GLOBAL_heading"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Description}
          maxCount={validation.paragraphMaxChar}
          className="WM_GLOBAL_paragraph"
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.StepsWrap>
          <Mapper
            data={imageSteps}
            render={(step: string, idx: number) => (
              <ControlsWithModalOpener
                key={idx}
                style={{ width: '100%' }}
                actions={Actions.stepActions({
                  idx,
                  showRemove: imageSteps.length > validation.imageSteps.min
                })}
              >
                <S.StepItemRow toReverse={isEven(idx)} isEditing={isEditing}>
                  <S.StepItemFigureWrap
                    className="figure-counter-wrap"
                    isLastItem={imageSteps.length - 1 === idx}
                    isEven={isEven(idx)}
                  >
                    <S.StepCounter position={isEven(idx)}>
                      {idx + 1}
                    </S.StepCounter>
                    <S.FigureContainer>
                      <S.StepItemFigure>
                        <ControlsWithImageCropper
                          actions={Actions.imageActions(idx, step.stepImageUrl)}
                          style={{ width: '100%', height: '100%' }}
                        >
                          <Image
                            as={S.StepImg}
                            alt="Step image"
                            sizes={step.stepImgSizes}
                            asProps={{ imgCropParams: step.imgCropParams }}
                            defaultImgSrc={step.stepImageUrl}
                          />
                        </ControlsWithImageCropper>
                      </S.StepItemFigure>
                    </S.FigureContainer>
                  </S.StepItemFigureWrap>
                  <S.StepItemContent isEven={isEven(idx)}>
                    <EditableContent
                      text={step.stepHeading}
                      as={S.StepTitle}
                      alignment={step.titleAlignment}
                      maxCount={validation.blockTitleMaxChar}
                      onChange={(newStepTitle: string) => {
                        Actions.changeBlockTitle(newStepTitle, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx)
                      }
                      className="WM_GLOBAL_heading20"
                    />
                    <EditableContent
                      as={S.StepDescription}
                      text={step.stepDescription}
                      alignment={step.paragraphAlignment}
                      maxCount={validation.blockParagraphMaxChar}
                      onChange={(content: string) => {
                        Actions.changeBlockContent(content, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockParagraphAlignment(alignment, idx)
                      }
                      className="WM_GLOBAL_paragraph18"
                    />
                  </S.StepItemContent>
                </S.StepItemRow>
              </ControlsWithModalOpener>
            )}
          />
        </S.StepsWrap>
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={imageSteps.length < validation.imageSteps.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_feature_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Steps2
