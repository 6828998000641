/* @flow */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { MAP_CONTAINER, YVN } from './consts'
import { injectMapScript, addressSplitter } from './utils'
import { MODAL_TYPES } from '../consts'
import Input from '@website/common/components/Input'
import {
  PrimaryButton,
  SecondaryButton
} from '@editor/common/styled-components/buttons'
import { _debounce, getAddressFromQuery } from '@website/common/utils'

//TODO: Use reusable GoogleMap component and remove all duplicated parts of the code
const MapModal = ({
  initialMapSrc,
  initialGeoLocation,
  setModalState,
  handler
}) => {
  const [mapSrc, setMapSrc] = useState(initialMapSrc)
  const [geoLocation, setGeoLocation] = useState({ ...initialGeoLocation })
  const [inputValue, setInputValue] = useState(getAddressFromQuery(mapSrc))

  const onScriptLoad = () => {
    const isGeoLocationObjEmpty = Object.keys(geoLocation).length === 0

    const map = new google.maps.Map(document.getElementById(MAP_CONTAINER), {
      zoom: 16,
      center: isGeoLocationObjEmpty ? YVN : geoLocation
    })

    const marker = new google.maps.Marker({
      map,
      position: isGeoLocationObjEmpty ? YVN : geoLocation,
      draggable: true
    })

    const input = document.querySelector('.map-search-input')
    const searchBox = new google.maps.places.SearchBox(input)
    const geocoder = new google.maps.Geocoder()

    if (isGeoLocationObjEmpty) {
      geocoder.geocode(
        { address: getAddressFromQuery(mapSrc) },
        (results, status) => {
          if (status === 'OK') {
            map.setCenter(results[0].geometry.location)
            marker.setPosition(results[0].geometry.location)
          }
        }
      )
    }

    const changeAddress = event => {
      const latlng = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            const address = addressSplitter(results[0].formatted_address)

            setMapSrc(`https://maps.google.com/maps?q=${address}&output=embed`)
            setGeoLocation(latlng)
            setInputValue(results[0].formatted_address)
          } else {
            console.log('No results found')
          }
        } else {
          console.log('Geocoder failed due to: ' + status)
        }
      })
    }

    const changePlace = () => {
      const places = searchBox.getPlaces()

      if (places.length === 0) {
        return
      }

      const bounds = new google.maps.LatLngBounds()

      places.forEach(place => {
        if (!place.geometry) {
          console.log('Returned place contains no geometry')
          return
        }

        marker.setPosition(place.geometry.location)

        const address = addressSplitter(place.formatted_address)
        const lat = place.geometry.location.lat()
        const lng = place.geometry.location.lng()

        setMapSrc(`https://maps.google.com/maps?q=${address}&output=embed`)
        setGeoLocation({ lat, lng })

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport)
        } else {
          bounds.extend(place.geometry.location)
        }

        map.fitBounds(bounds)
      })
    }

    map.addListener('click', event => changeAddress(event))
    marker.addListener('dragend', event => changeAddress(event))
    searchBox.addListener('places_changed', changePlace)
  }

  useEffect(() => {
    injectMapScript(onScriptLoad)
  }, [])

  const onClose = useCallback(() => {
    setModalState(MODAL_TYPES.mapModal, { isOpen: false })
  }, [])

  const onSave = useCallback(() => {
    handler({ mapSrc, geoLocation })
    onClose()
  }, [mapSrc, geoLocation, handler])

  const { current: onDebounceSearch } = useRef(
    _debounce(input => {
      google.maps.event.trigger(input, 'keydown', {
        keyCode: 13
      })
    }, 500)
  )

  const onChange = useCallback(e => {
    setInputValue(e.target.value)
    onDebounceSearch(e.target)
  }, [])

  const onRemoveIconClick = useCallback(() => {
    setInputValue('')
  }, [])

  return (
    <Styled.MapModal isOpen={true} onClose={onClose} isMobileFullScreen>
      <Styled.Title>{translate('choose_location_label')}</Styled.Title>
      <Styled.InputWrapper>
        <Styled.InputLabel>
          {translate('search_on_Google_Maps_label')}
        </Styled.InputLabel>
        <Input
          className="map-search-input"
          value={inputValue}
          placeholder={translate('search_label')}
          iconName="search_tiny"
          borderColor="#C1C9E0"
          borderHoverColor="#8493BD"
          onChange={onChange}
          onRemoveIconClick={onRemoveIconClick}
        />
      </Styled.InputWrapper>
      <Styled.MapContainer id={MAP_CONTAINER} />
      <Styled.ButtonsWrapper>
        <SecondaryButton onClick={onClose}>
          {translate('cancel_label')}
        </SecondaryButton>
        <PrimaryButton onClick={onSave}>
          {translate('save_label', true)}
        </PrimaryButton>
      </Styled.ButtonsWrapper>
    </Styled.MapModal>
  )
}

export default MapModal
