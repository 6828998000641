/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Header8.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)
const stylesByColor = {
  default: '',
  dark: css`
    filter: brightness(0.22);
  `,
  light: css`
    filter: brightness(1);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0 0 80px 0;
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
  .web-page-item-wrap {
    box-shadow: ${getStyleForProp('raisedShadow')};
    border-radius: 10px;
  }
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  @media only screen and (max-width: 1250px) {
    width: 100%;
  }
  @media only screen and (max-width: 600px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
  }
  > :first-child {
    padding-top: 0;
  }
`

const WebpageWrap = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media only screen and (max-width: 1023px) {
    transform: translateX(6%);
    margin: 0 auto -30px auto;
  }
  @media only screen and (max-width: 600px) {
    margin: 0 auto -160px auto;
    transform: translateX(2%);
  }
  .image-state-overlay,
  .failed-image-container {
    z-index: 1;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
      margin-left: 0;
      padding-top: 0;
    }
  }
  @media only screen and (min-width: 1025px) {
    max-width: 600px;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
`

const Phone = styled.div`
  width: 248px;
  min-width: 248px;
  position: relative;
  padding: 16px 24px 40px 24px;
  box-sizing: border-box;
  border-radius: 38px;
  font-size: 0;
  min-height: 470px;
  transform: translate(-48px, 52px);
  z-index: 1;
  @media only screen and (max-width: 1023px) {
    transform: translate(-60px, 14px) scale(0.8);
  }
  @media only screen and (max-width: 800px) {
    transform: translate(-90px, -19px) scale(0.7);
  }
  @media only screen and (max-width: 600px) {
    transform: translate(-90px, -86px) scale(0.4);
  }
  &:before {
    content: ' ';
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    border-radius: 24px;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header-8-mobile-mockup-1.png');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  &:after {
    content: '';
    width: 45%;
    height: 35px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-54%);
    z-index: 1;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/phone-dinamic-min.png');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 200%;
  }
  .lazy-figure {
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 24px;
  }
`

const WebImg = styled.div`
  flex: none;
  width: 76%;
  font-size: 0;
  margin-top: 50px;
  padding-top: 30px;
  position: relative;
  border-radius: 10px;
  box-shadow: rgb(37 43 52 / 12%) 0px 9px 22px 0px;
  &:before {
    content: ' ';
    width: 100%;
    height: 30px;
    z-index: 1;
    top: 1px;
    left: 0;
    border-radius: 10px 10px 0 0;
    position: absolute;
    background-repeat: no-repeat;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header8-browser-top-layer.svg');
    background-position: left top;
    background-size: cover;
    ${getStyleByColor};
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 650px) {
    padding-top: 18px;
    &:before {
      height: 18px;
    }
  }
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 52%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: 0 0 10px 10px;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const TopImageWrap = styled.div`
  width: 100%;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  > img {
    @media only screen and (max-width: 600px) {
      height: 15px;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  WebpageWrap,
  Title,
  Description,
  Phone,
  WebImg,
  MobileImg,
  Img,
  TopImageWrap
}
