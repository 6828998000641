/* @flow */
/* eslint-disable max-statements */

import produce from 'immer'
import { validation, LIST_ITEM_INITIAL_STATE } from './PrivacyPolicy2.consts'

export default function reducer(state, action: any) {
  return produce(state, draft => {
    const initialState = action.__initialData.defaultProps
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_ADDITIONAL_BLOCK_TITLE':
        draft.additionalBlockTitle = action.value
        break
      case 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH':
        draft.additionalBlockParagraph = action.value
        break
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { idx, newParagraph } = action.value
        draft.blocks[idx].paragraph = newParagraph
        break
      }
      case 'ADD_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx + 1, 0, initialState.blocks[0])
        break
      }
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'ADD_LIST_ITEM': {
        const { idx } = action.value
        draft.blocks[idx].list.push(LIST_ITEM_INITIAL_STATE)
        break
      }
      case 'REMOVE_LIST_ITEM': {
        const { idx, listItemIdx } = action.value
        if (draft.blocks[idx].list.length === validation.listItem.min) {
          return
        }
        draft.blocks[idx].list.splice(listItemIdx, 1)
        break
      }
      case 'CHANGE_LIST_ITEM': {
        const { newListItem, idx, listItemIdx } = action.value
        draft.blocks[idx].list[listItemIdx] = newListItem
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH_ALIGNMENT': {
        draft.additionalBlockParagraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockParagraphAlignment = alignment
        break
      }
    }
  })
}
