/* @flow */

export const validation = {
  team: {
    min: 1,
    max: 15
  },
  socials: {
    min: 0,
    max: 6
  },
  headingMaxChar: 100,
  paragraphMaxChar: 300,
  blockTitleMaxChar: 50,
  blockSubTitleMaxChar: 50,
  blockParagraphMaxChar: 500
}
