import React, { useContext, memo } from 'react'
import S from './Feature12_2.styled'
import { validation } from './Feature12_2.consts'
import ActionGetter from './Actions'
import LinkWrapper from '@website/common/components/LinkWrapper'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import { getBackgroundImage } from '@website/common/utils'
import { DispatchContext, SiteIdContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Feature12_2 = memo(props => {
  const {
    data: { title, paragraph, features, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const siteId = useContext(SiteIdContext)

  return (
    <S.StyledContainer className="feature-12_2-container">
      <Show when={[title, paragraph]}>
        <S.TextContent isEditing={isEditing}>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.headingMaxChar}
            onChange={Actions.changeTitle}
            className="WM_GLOBAL_heading"
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Paragraph}
            maxCount={validation.paragraphMaxChar}
            onChange={Actions.changeParagraph}
            className="WM_GLOBAL_paragraph"
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.TextContent>
      </Show>
      {Object.keys(features).map((key, idx) => {
        const featureRow = features[key]
        return (
          <S.RowWrapper>
            <S.Row key={idx}>
              {featureRow.map((featureItem: string, itemIdx: number) => {
                return (
                  <S.Item
                    key={itemIdx}
                    backgroundImg={getBackgroundImage(
                      featureItem.backgroundImgDimensions,
                      featureItem.backgroundImg
                    )}
                    isEven={itemIdx % 2 !== idx}
                  >
                    <ControlsWithModalOpener
                      actions={Actions.bgActions(
                        featureItem.backgroundImg,
                        key,
                        itemIdx,
                        Object.keys(features).length,
                        siteId
                      )}
                      style={{ width: '100%' }}
                    >
                      <S.ItemWrapper>
                        <If
                          condition={!!featureItem.icon}
                          otherwise={() => (
                            <AddButton
                              onAdd={() => Actions.addIcon(key, itemIdx)}
                              type="icon"
                              large
                              toShow
                            >
                              +
                            </AddButton>
                          )}
                          then={() => (
                            <ControlsWithModalOpener
                              actions={Actions.iconActions(key, itemIdx)}
                            >
                              <div>
                                <Icon
                                  size="xxlLarge"
                                  color="white"
                                  className="item-icon"
                                  name={featureItem.icon}
                                />
                              </div>
                            </ControlsWithModalOpener>
                          )}
                        />
                        <EditableContent
                          text={featureItem.title}
                          as={S.ItemTitle}
                          alignment={featureItem.blockTitleAlignment}
                          className="WM_GLOBAL_heading32"
                          maxCount={validation.blockTitleMaxChar}
                          onChange={(newTitle: string) => {
                            Actions.changeBlockTitle(newTitle, key, itemIdx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(
                              alignment,
                              key,
                              itemIdx
                            )
                          }
                        />
                        <If
                          condition={
                            featureItem.additionalText &&
                            featureItem.additionalUrl
                          }
                          otherwise={() => (
                            <AddButton
                              onAdd={() => Actions.addLink(key, itemIdx)}
                              toShow={featureItem.additionalText === ''}
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                              {translate('add_link_label')}
                            </AddButton>
                          )}
                          then={() => (
                            <S.LinkWrap>
                              <ControlsWithModalOpener
                                actions={Actions.linkActions(key, itemIdx, {
                                  text: featureItem.additionalText,
                                  url: featureItem.additionalUrl
                                })}
                              >
                                <LinkWrapper
                                  url={featureItem.additionalUrl}
                                  text={featureItem.additionalText}
                                  linkProps={{
                                    className:
                                      'WM_GLOBAL_secondary-font learn-more-link'
                                  }}
                                >
                                  <Icon
                                    size="large"
                                    name="keyboard_arrow_right"
                                    color="white"
                                    className="arrow-right"
                                  />
                                </LinkWrapper>
                              </ControlsWithModalOpener>
                            </S.LinkWrap>
                          )}
                        />
                      </S.ItemWrapper>
                    </ControlsWithModalOpener>
                  </S.Item>
                )
              })}
            </S.Row>
            <If
              condition={isEditing}
              then={() => (
                <S.AddButtonWrapper>
                  <AddButton
                    onAdd={() => Actions.addRow(idx)}
                    toShow={
                      Object.keys(features).length < validation.features.max
                    }
                    style={{ margin: '0 0.5rem' }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_row_label')}
                  </AddButton>
                  <AddButton
                    onAdd={() => Actions.removeRow(key)}
                    toShow={
                      Object.keys(features).length > validation.features.min
                    }
                    style={{ margin: '0 0.5rem' }}
                  >
                    <Icon name="glyph-remove" className="icon" size="normal" />
                    {translate('remove_row_label')}
                  </AddButton>
                </S.AddButtonWrapper>
              )}
            />
          </S.RowWrapper>
        )
      })}
    </S.StyledContainer>
  )
})

export default Feature12_2
