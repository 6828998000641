import styled from 'styled-components'

import Popup from '@renderforest/rf-ui-library/organism/Popup'

export const MapModal = styled(Popup)`
  & > div {
    max-height: 90%;
    max-width: 90%;
    padding: 30px 30px 0;
    & > div {
      padding: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    & > div {
      padding: 30px 20px 0;
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #252e48;
  text-align: center;
`

export const InputWrapper = styled.div`
  margin: 30px 0 20px;
  input {
    width: 100%;
  }
`

export const InputLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #545f7e;
  margin-bottom: 5px;
`

export const MapContainer = styled.div`
  width: 400px;
  height: 250px;
  border-radius: 5px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  & > button:first-child {
    margin-right: 30px;
  }
`
