import styled, { createGlobalStyle, css } from 'styled-components'


const getItemSizes = ({ offsetFromCenter, additionalOffset = 30 }) => {
  switch (offsetFromCenter) {
    case 0:
      return css`
        transform: scale(1);
        margin: 0 5px;
        z-index: 3;
      `
    case NaN:
      return css`
        transform: scale(0.95);
        opacity: 0.7;
        position: relative;
      `
    case 1:
    case -1:
      return css`
        transform: scale(0.95);
        opacity: 0.7;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          display: inline-block;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 2;
          cursor: pointer;
        }
      `
    default:
      return css`
        transform: scale(0.95);
      `
  }
}

export const ItemWrapper = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
`

export const Item = styled.div`
  width: 100%;
  @media only screen and (max-width: 767px) {
    width: 80%;
    margin: auto;
  }
  display: flex;
  .control-container {
    width: 100%;
  }
  .lazy-figure {
    height: 100%;
    width: 100%;
  }
  ${getItemSizes};
  transition: ${({ animationDuration }) => {
    const duration = animationDuration / 1000
    return css`
      transform ${duration}s
    `
  }};
`

export const GlobalStyles = createGlobalStyle`
  .testimonials4-slider {
    .slick-arrow {
      display: block !important;
      font-size: inherit;
      width: 36px;
      height: 36px;
      line-height: 1;
      z-index: 2;
      &:before {
        display: none;
      }
      button {
        width: 36px;
        height: 36px;
      }
    }
    .slick-prev {
      left: -58px;
      transform: translate(0, calc(-50% + 25px));
      div {
        transform: translateX(-2px);
      }
      @media only screen and (max-width: 1560px) {
        left: 40px;
      }
      @media only screen and (max-width: 767px) {
        left: -19px;
      }
    }
    .slick-next {
      transform: translate(0, calc(-50% + 25px));
      right: -58px;
      div {
        transform: translateX(2px);
      }
      @media only screen and (max-width: 1560px) {
        right: 40px;
      }
      @media only screen and (max-width: 767px) {
        right: -19px;
      }
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
    }
    .slick-slide > div {
      margin: 50px 0 1px 0;
    }
  }
`
