/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Text21.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  position: relative;
  padding-top: ${({ isEditing }) => isEditing && '150px'};
  @media only screen and (max-width: 650px) {
    padding-top: ${({ isEditing }) => isEditing && '120px'};
  }
`

export const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  padding-top: 0;
  @media only screen and (max-width: 1440px) {
    max-width: 1200px;
  }
  @media only screen and (max-width: 992px) {
    align-items: center;
  }
`

export const HeaderContent = styled.div`
  text-align: left;
  width: 100%;
  @media only screen and (max-width: 992px) {
    max-width: 100%;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('title')};
  ${getTextStyleByAlignment};
  margin-bottom: 50px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
`

export const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  ${({ isEditing }) => isEditing && 'padding-top: 20px'};

  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    padding-top: 41.667%;
  }
`

export const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 90px;
  row-gap: 20px;
  padding-top: 20px;
  &:empty {
    padding-top: 0;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
