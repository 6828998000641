export const validation = {
  subtitleMaxChar: 200,
  titleMaxChar: 200,
  paragraphMaxChar: 400,
  blockParagraphMaxChar: 100,
  blockTitleMaxChar: 100,
  blocks: {
    min: 1,
    max: 8
  }
}

export const ADDITIONAL_URL_DEFAULT_DATA = {
  additionalText: 'Learn more',
  additionalUrl: 'http://builder.renderforestsites.com'
}
