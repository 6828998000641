/* @flow */
import React, { useContext, useState, useEffect, memo } from 'react'
import * as S from './Header55.styled'
import THEME from './Header55.theme'
import { validation } from './Header55.consts'
import { countUp } from './Header55.utils'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import NavBar from '@website/common/components/NavBar'
import Icon from '@website/common/components/Icon'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Buttons from '@website/common/components/Buttons'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Socials from '@website/common/components/Socials'
import { translate } from '@editor/common/utils/translations'

const Header55 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      titleTextGradient,
      subTitle,
      subTitleAlignment,
      subTitleTextGradient,
      paragraph,
      paragraphAlignment,
      buttons,
      blocks,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      headerSocials,
      bgOverlayTransparency,
      backgroundImgDimensions,
      imageUrl,
      imgDimensions,
      imgCropParams
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  // useEffect(() => {
  //   if (!isEditing) {
  //     countUp(ANIMATION_SPEED)
  //   }
  // }, [])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || headerSocials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={headerSocials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.WMCustomContainer topOffset={navBarHeight} isEditing={isEditing}>
        <S.ContentWrapper bgOverlayTransparency={bgOverlayTransparency}>
          <S.LeftContent>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              isTextGradient={titleTextGradient}
              isGradientControlHidden={false}
              required={!subTitle && !paragraph}
              maxCount={validation.headingMaxChar}
              isEditing={isEditing}
              className="WM_GLOBAL_heading42"
              data-text={title}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
              changeTextGradientStatus={Actions.changeTitleGradientStatus}
            />
            <EditableContent
              text={subTitle}
              alignment={subTitleAlignment}
              as={S.SubTitle}
              isTextGradient={subTitleTextGradient}
              isGradientControlHidden={false}
              required={!title && !paragraph}
              maxCount={validation.headingMaxChar}
              isEditing={isEditing}
              className="WM_GLOBAL_heading42"
              data-text={title}
              onChange={Actions.changeSubTitle}
              changeAlignment={Actions.changeSubTitleAlignment}
              changeTextGradientStatus={Actions.changeSubTitleGradientStatus}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              required={!subTitle && !title}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <Buttons data={buttons} />
            <Show when={[blocks.length > 0]}>
              <S.BlocksWrap
                isEditing={isEditing}
                areTwoBlock={blocks.length === 2}
              >
                {blocks.map((block, idx) => {
                  const showRemove = blocks.length > validation.blocks.min
                  return (
                    <S.ControlContainer key={idx} isEditing={isEditing}>
                      <ControlsWithModalOpener
                        actions={Actions.blockActions({
                          idx,
                          showRemove
                        })}
                      >
                        <S.ItemWrapper isEditing={isEditing}>
                          <EditableContent
                            isEditing={isEditing}
                            text={block.blockTitle}
                            as={S.BlockTitle}
                            // data-target={block.blockTitle}
                            required={!block.blockParagraph}
                            alignment={block.blockTitleAlignment}
                            maxCount={validation.blockTitleMaxChar}
                            className="WM_GLOBAL_heading42 counting-numbers"
                            onChange={(newTitle: string) => {
                              Actions.changeBlockTitle(newTitle, idx)
                            }}
                            // isIcon={block.iconName}
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockTitleAlignment(alignment, idx)
                            }
                          />
                          <EditableContent
                            isEditing={isEditing}
                            text={block.blockParagraph}
                            as={S.BlockParagraph}
                            required={!block.blockTitle}
                            alignment={block.blockParagraphAlignment}
                            maxCount={validation.blockParagraphMaxChar}
                            className="WM_GLOBAL_paragraph18"
                            onChange={(newParagraph: string) => {
                              Actions.changeBlockParagraph(newParagraph, idx)
                            }}
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockParagraphAlignment(
                                alignment,
                                idx
                              )
                            }
                          />
                        </S.ItemWrapper>
                      </ControlsWithModalOpener>
                    </S.ControlContainer>
                  )
                })}
              </S.BlocksWrap>
              <AddButton
                onAdd={Actions.addBlock}
                toShow={blocks.length < validation.blocks.max}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_block_label')}
              </AddButton>
            </Show>
          </S.LeftContent>
          <S.RightContent>
            <S.ImageWrapper isEditing={isEditing}>
              <ControlsWithImageCropper
                actions={Actions.imgActions(imageUrl)}
                // style={{ width: '100%' }}
              >
                <Image
                  as={S.Img}
                  alt="Section img"
                  defaultImgSrc={imageUrl}
                  asProps={{ imgCropParams }}
                  sizes={imgDimensions}
                />
              </ControlsWithImageCropper>
            </S.ImageWrapper>
          </S.RightContent>
        </S.ContentWrapper>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Header55
