// @flow
import React from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TFooterProps } from './types'
import SuccessPopup from '../SuccessPopup'
import DoneIcon from '@editor/common/assets/svgr-icons/Done.svg'
import { useResponsive } from '@shared/hooks'
import { selectSettingsSuccessMessage } from '@selectors'
import { setSettingsSuccessMessage } from '@actions/editor'
import { translate } from '@editor/common/utils/translations'

//TODO: Get rid of this component and use SaveAndDiscardFooter from common components
const Footer = ({
  successMessage,
  isSaveButtonDisabled,
  hasBorder = true,
  className,
  onDiscard,
  onSave,
  setSettingsSuccessMessage
}: TFooterProps) => {
  const isSmallSizes = useResponsive(830)
  return (
    <Styled.Footer hasBorder={hasBorder} className={className}>
      {isSmallSizes ? (
        <SuccessPopup
          successMessage={successMessage}
          setSuccessMessage={setSettingsSuccessMessage}
        />
      ) : (
        <Styled.SavingInfo show={!!successMessage}>
          <DoneIcon />
          {successMessage}
        </Styled.SavingInfo>
      )}
      <div>
        <Styled.DiscardButton onClick={onDiscard}>
          {translate('discard_label')}
        </Styled.DiscardButton>
        <Styled.SaveButton onClick={onSave} disabled={isSaveButtonDisabled}>
          {translate('save_label')}
        </Styled.SaveButton>
      </div>
    </Styled.Footer>
  )
}

const mapStateToProps = state => ({
  successMessage: selectSettingsSuccessMessage(state)
})

export default connect(mapStateToProps, { setSettingsSuccessMessage })(Footer)
