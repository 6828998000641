// @flow

import React, { useEffect } from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import Providers from './Page/Providers'
import {
  SitePreview,
  TemplatePreview,
  SitePresetPreview,
  AIPresetPreview
} from './containers'

const EMPTY_STORE = createStore(() => ({}))

const Preview = () => {
  const { path } = useRouteMatch()

  useEffect(() => {
    window.removeGoogleOneTapLogin && window.removeGoogleOneTapLogin()
  }, [])

  return (
    <Provider store={EMPTY_STORE}>
      <Providers>
        <Switch>
          <Route
            path={`${path}/template/:templateId/:page?`}
            component={TemplatePreview}
          />
          <Route path={`${path}/site/:siteId/:page?`} component={SitePreview} />
          <Route path={`${path}/site-preset/`} component={SitePresetPreview} />
          <Route path={`${path}/ai-preset/`} component={AIPresetPreview} />
        </Switch>
      </Providers>
    </Provider>
  )
}

export default Preview
