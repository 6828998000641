// @flow

import {
  selectHasWebsiteEvent,
  getSiteId,
  selectEvent,
  selectIsUserAuthorized
} from '@selectors'
import { addEventIdToRsvpComponents } from '@editor/common/utils/project-data'
import { createEvent, setEventsData } from '../events'
import { createNewProject } from '../project'
import { RSVP_COMP_CATEGORY } from '@editor/conf/consts'

// TODO: move all template actions to this file

export const applyPreset =
  (data: TPresetData) => async (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const hasWebsiteEvent = selectHasWebsiteEvent(state)
    const { events: presetEvent, data: presetData, siteName } = data
    const isUserAuthorized = selectIsUserAuthorized(state)
    const needToCreateProject = isUserAuthorized && siteId === 'new'
    const hasPresetEvent = presetEvent && !!presetEvent.length
    const hasRsvpComponent = presetData.pages.some(page =>
      page.components.some(
        component => component.category === RSVP_COMP_CATEGORY
      )
    )

    // eslint-disable-next-line no-unused-vars
    const { id, settings, ...initialEventData } = hasPresetEvent
      ? presetEvent[0]
      : []

    if (hasPresetEvent && !hasWebsiteEvent && hasRsvpComponent) {
      dispatch(setEventsData([presetEvent[0]]))
    }

    const { visualParams, ...presetDataWithoutVisualParams } = presetData

    if (hasWebsiteEvent && hasRsvpComponent) {
      const { id } = selectEvent(state)
      presetDataWithoutVisualParams.pages = addEventIdToRsvpComponents(
        presetData.pages,
        id
      )
    }

    const needToCreateEvent =
      isUserAuthorized &&
      !hasWebsiteEvent &&
      hasRsvpComponent &&
      hasPresetEvent

    dispatch({
      type: 'SET_VISUAL_PARAMS',
      value: visualParams
    })

    dispatch({
      type: 'APPLY_TEMPLATE_PRESET',
      value: presetDataWithoutVisualParams,
      needToSendRevision: !needToCreateProject,
      undoable: !needToCreateEvent
    })

    needToCreateProject && (await dispatch(createNewProject(siteName)))
    needToCreateEvent && dispatch(createEvent({ event: initialEventData }))
  }
