/* @flow */
import React, { useContext, memo } from 'react'
import S from './Header32.styled'
import THEME from './Header32.theme'
import ActionGetter from './Actions'
import { validation } from './Header32.consts'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import Socials from '@website/common/components/Socials'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import SingleButton from '@website/common/components/SingleButton'

const Header32 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      backgroundImgUrl,
      backgroundImgDimensions,
      btnText,
      btnUrl,
      socials
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const withoutContent = !(title || paragraph || btnText)
  const onlyButton = !(title || paragraph)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      transparent
    >
      <NavBar
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || socials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={socials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.WmCustomContainer withoutContent={withoutContent && !isEditing}>
        <Show when={[title, paragraph, btnText]}>
          <S.HeaderContentContainer onlyButton={onlyButton && !isEditing}>
            <EditableContent
              text={paragraph}
              as={S.Paragraph}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              required={validation.title.required}
              maxCount={validation.titleMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <SingleButton
              className="button-wrap"
              btnUrl={btnUrl}
              btnText={btnText}
            />
          </S.HeaderContentContainer>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header32
