/* @flow */
import React, { useContext, memo } from 'react'
import S from './Steps5.styled'
import { validation } from './Steps5.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const Steps5 = memo(props => {
  const {
    data: { title, paragraph, steps, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <Show when={[title]}>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
        </Show>
        <Show when={[paragraph]}>
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Description}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </Show>
        <S.StepsWrap>
          <Mapper
            data={steps}
            render={(step: string, idx: number) => (
              <React.Fragment key={idx}>
                <div className={idx === 1 ? 'next-step show' : 'hide'} />
                <S.StepItemWrap>
                  <S.FigureContainer>
                    <S.ItemImgWrap>
                      <ControlsWithImageCropper
                        style={{ height: '100%', width: '100%' }}
                        actions={Actions.imageActions(idx, step.stepImageUrl)}
                      >
                        <Image
                          as={S.StepImg}
                          alt="Step image"
                          sizes={step.stepImgSizes}
                          asProps={{ imgCropParams: step.imgCropParams }}
                          defaultImgSrc={step.stepImageUrl}
                        />
                      </ControlsWithImageCropper>
                    </S.ItemImgWrap>
                  </S.FigureContainer>
                  <Show when={[step.stepTitle, step.stepDescription]}>
                    <S.StepItemContent>
                      <EditableContent
                        text={step.stepTitle}
                        as={S.StepTitle}
                        alignment={step.stepTitleAlignment}
                        maxCount={validation.blockTitleMaxChar}
                        className="WM_GLOBAL_heading20"
                        onChange={(title: string) => {
                          Actions.changeBlockTitle(title, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        text={step.stepDescription}
                        as={S.StepDescription}
                        alignment={step.stepDescriptionAlignment}
                        maxCount={validation.blockParagraphMaxChar}
                        className="WM_GLOBAL_paragraph18"
                        onChange={(paragraph: string) => {
                          Actions.changeBlockParagraph(paragraph, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }
                      />
                    </S.StepItemContent>
                  </Show>
                </S.StepItemWrap>
                <S.NextIcon
                  className={
                    idx === 0 || idx === 1 ? 'next-step show' : 'next-step hide'
                  }
                >
                  <S.Icon size="xlarge" name="keyboard_arrow_right" />
                </S.NextIcon>
              </React.Fragment>
            )}
          />
        </S.StepsWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Steps5
