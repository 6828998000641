/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Slider1.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  background-color: ${getStyleForProp('background')};
  @media only screen and (max-width: 600px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 40px 0' : '40px 0')};
  }
`

const Title = styled.h4`
  color: ${getStyleForProp('heading')};
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  white-space: pre-wrap;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`

const MainSliderImg = styled.img`
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: ${getStyleForProp('raisedShadow')};
`

const SliderContainer = styled.div`
  padding: ${({ isEditing }) => (isEditing ? '50px 0 140px 0' : '0 0 140px 0')};
  @media only screen and (max-width: 1024px) {
    padding: ${({ isEditing }) => (isEditing ? '50px 0 0 0' : '0')};
  }
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const SliderImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`

const IconContainer = styled.div`
  width: 36px;
  min-width: 36px;
  margin: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${getStyleForProp('iconRadius')};
  ${getStyleForProp('buttonBackground')};
`

const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 0 -15px;
`

export const LinkWrapper = styled.div`
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      getStyleForProp(props.isTertiary ? 'raisedShadow' : 'buttonShadow')};
    ${props =>
      getStyleForProp(
        props.isTertiary ? 'buttonBackgroundTertiary' : 'buttonBackground'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    max-width: 260px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
    margin: 10px;
    @media only screen and (max-width: 700px) {
      width: 100%;
      margin: 10px 0;
    }
    @media only screen and (max-width: 650px) {
      height: 40px;
      line-height: 40px;
    }
  }
`

export default {
  Container,
  Title,
  Paragraph,
  SliderContainer,
  MainSliderImg,
  SliderImg,
  IconContainer,
  ButtonsWrap,
  LinkWrapper
}
