/* @flow */
import React, { useContext, memo } from 'react'
import S from './Pricing3.styled'
import { validation } from './Pricing3.consts'
import ActionGetter from './Actions'
import Badge from '@website/common/components/Badge'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Show from '@website/common/components/Show/Show'
import { translate } from '@editor/common/utils/translations'

const Pricing3 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      plans,
      primaryPlanIdx
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer>
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.PricingHeaderContainer>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Heading}
                alignment={titleAlignment}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.PricingHeaderContainer>
        </Show>
        <S.PricingCardsContainer>
          {plans.map((plan, planIndex) => {
            const isPrimary = planIndex === primaryPlanIdx
            return (
              <S.PriceCardWrapper isPrimary={isPrimary} key={planIndex}>
                <ControlsWithModalOpener
                  actions={Actions.cardAction({
                    idx: planIndex,
                    showStar: !isPrimary,
                    showRemove: plans.length > validation.plans.min
                  })}
                >
                  <S.PriceCard
                    isPrimary={isPrimary}
                    isEditing={isEditing}
                    className="WM_GLOBAL_secondary-font"
                  >
                    <If condition={isPrimary} then={() => <Badge />} />
                    <S.ContentBody isPrimary={isPrimary}>
                      <S.PricingCardHeader>
                        <ControlsWithModalOpener
                          actions={Actions.iconActions({
                            idx: planIndex,
                            showRemove: false
                          })}
                        >
                          <S.Icon
                            name={plan.icon}
                            size="xxlarge"
                            isPrimary={isPrimary}
                          />
                        </ControlsWithModalOpener>
                        <EditableContent
                          text={plan.title}
                          as={S.Title}
                          maxCount={validation.priceTitleMaxChar}
                          onChange={(value: string) => {
                            Actions.changePlanTitle(value, planIndex)
                          }}
                          className="WM_GLOBAL_paragraph14"
                        />
                        <EditableContent
                          as={S.Price}
                          text={plan.price}
                          maxCount={validation.priceMaxChar}
                          onChange={(value: string) => {
                            Actions.changePlanPrice(value, planIndex)
                          }}
                          className="WM_GLOBAL_heading54"
                        />
                        <EditableContent
                          as={S.PerDate}
                          text={plan.perDate}
                          maxCount={validation.perMonthMaxChar}
                          onChange={(newDate: string) => {
                            Actions.changePerDate(newDate, planIndex)
                          }}
                          className="WM_GLOBAL_paragraph18"
                        />
                      </S.PricingCardHeader>
                      <S.PricingCardcontent>
                        {plan.options.map((option, optionIdx) => (
                          <ControlsWithModalOpener
                            style={{ width: '100%' }}
                            actions={Actions.optionActions(
                              planIndex,
                              optionIdx
                            )}
                            key={optionIdx}
                          >
                            <EditableContent
                              text={option}
                              as={S.Option}
                              toPropogate={false}
                              maxCount={validation.optionMaxChar}
                              onChange={value =>
                                Actions.changeOptnValue(
                                  planIndex,
                                  optionIdx,
                                  value
                                )
                              }
                              className="WM_GLOBAL_paragraph14"
                            />
                          </ControlsWithModalOpener>
                        ))}
                        <AddButton
                          style={{
                            margin: '10px 0'
                          }}
                          onAdd={() => Actions.addOption(planIndex)}
                          toShow={
                            plan.options.length < validation.plans.options.max
                          }
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_item_label')}
                        </AddButton>
                      </S.PricingCardcontent>
                      <S.ButtonWrapper isPrimary={isPrimary}>
                        <ControlsWithModalOpener
                          actions={Actions.btnActions(
                            planIndex,
                            {
                              text: plan.buttonText,
                              link: plan.buttonUrl
                            },
                            false
                          )}
                          style={{ width: '100%' }}
                        >
                          <LinkWrapper
                            text={plan.buttonText}
                            url={plan.buttonUrl}
                            linkProps={{
                              className: 'rf-comp-btn'
                            }}
                          />
                        </ControlsWithModalOpener>
                      </S.ButtonWrapper>
                    </S.ContentBody>
                  </S.PriceCard>
                </ControlsWithModalOpener>
              </S.PriceCardWrapper>
            )
          })}
        </S.PricingCardsContainer>
        <AddButton
          style={{ marginTop: '1.5rem' }}
          onAdd={Actions.addBlock}
          toShow={plans.length < validation.plans.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Pricing3
