/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Morph = ({ theme }) => {
  const { look, palette } = theme
  const isSharp = look === 'SHARP'
  const from = palette.primaryLight
  const to = palette.primaryDark
  return (
    <svg
      width="692px"
      height="495px"
      viewBox="0 0 692 495"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="14.907241%"
          x2="50%"
          y2="96.0553649%"
          id="linearGradient-1"
        >
          <stop stopColor={from} offset="0%" />
          <stop stopColor={isSharp ? from : to} offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g
          id="header-24-768x1024"
          transform="translate(-30.000000, -466.000000)"
          fill="url(#linearGradient-1)"
        >
          <path
            d="M32.6021678,711.129528 C23.4431225,671.377937 38.8785164,626.105659 72.3462727,602.705487 C107.78849,577.973189 154.775556,579.489982 197.629709,584.615359 C240.483863,589.740736 285.330715,597.212566 325.628844,581.507036 C374.11904,562.735083 404.350327,514.452003 448.934963,488.062592 C509.977491,451.389592 593.141126,462.126887 647.705076,507.743507 C702.269026,553.360127 728.110898,628.197273 720.443264,698.986178 C713.057696,769.584778 674.799289,835.176431 621.923706,882.743299 C563.038824,936.004904 482.638716,968.92402 403.724305,959.010749 C360.398016,953.793814 318.887319,934.228794 286.666555,904.705172 C261.36208,881.177832 246.475803,846.930675 219.577008,825.299185 C191.255726,802.166828 156.298609,810.578008 123.236922,801.716553 C79.9722312,789.896486 42.8217982,755.496841 32.6021678,711.129528 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  )
}

export default withTheme(Morph)
