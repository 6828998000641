/* @flow */
import React, { useState, useContext, memo, useCallback, useRef } from 'react'
import S from './Slider6.styled'
import { validation } from './Slider6.consts'
import ActionMaker from './Actions'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { slickSliderSettings } from './Slider6.consts'
import Slider from 'react-slick'
import '../Swipe/swipe-slider-styles.css'
import SlideCounts from './SlideCounts'
import Image from '@website/common/components/Image'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import Arrow from '@editor/common/assets/svgr-icons/arrow_big_left.svg'
import { translate } from '@editor/common/utils/translations'

const Slider6 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      blocks,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      slides
    }
  } = props
  const [activeSlideIdx, setActiveSlideIdx] = useState(0)

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionMaker(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const slickSliderRef = useRef(null)

  const onPrevClick = useCallback(() => {
    slickSliderRef.current.slickPrev()
  }, [slickSliderRef.current])

  const onNextClick = useCallback(() => {
    slickSliderRef.current.slickNext()
  }, [slickSliderRef.current])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      isOneSlide={slides.length === 1}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer isEditing={isEditing}>
        <S.ImageContentWrapper>
          <Slider
            {...slickSliderSettings}
            key={slides.length}
            swipeToSlide={!isEditing}
            swipe={!isEditing}
            accessibility={!isEditing}
            autoplay={!isEditing}
            className="slider"
            beforeChange={(_, newIdx) => {
              setActiveSlideIdx(newIdx)
            }}
            ref={slickSliderRef}
          >
            {slides.map((slide, idx) => (
              <S.SliderItemWrapper isEditing={isEditing}>
                <S.ControlContainer>
                  <ControlsWithImageCropper
                    toPropagate={false}
                    actions={Actions.getSliderActions({
                      idx,
                      imageUrl: slide.imgUrl
                    })}
                  >
                    <Image
                      as={S.Img}
                      defaultImgSrc={slide.imgUrl}
                      sizes={slide.imgDimensions}
                      alt="Section image"
                      asProps={{
                        className: 'text-image',
                        imgCropParams: slide.imgCropParams
                      }}
                    />
                  </ControlsWithImageCropper>
                </S.ControlContainer>
              </S.SliderItemWrapper>
            ))}
          </Slider>
          <SlideCounts
            activeSlideIdx={activeSlideIdx + 1}
            slidesCount={slides.length}
          />
          <If
            condition={isEditing && slides.length > 1}
            then={() => (
              <>
                <TooltipWrapper
                  text={translate('previous_label')}
                  color="#252E48"
                  position="top"
                  className="left slider-arrow-control"
                >
                  <S.ArrowIconContainer onClick={onPrevClick}>
                    <Arrow />
                  </S.ArrowIconContainer>
                </TooltipWrapper>
                <TooltipWrapper
                  text={translate('next_label')}
                  color="#252E48"
                  position="top"
                  className="right slider-arrow-control"
                >
                  <S.ArrowIconContainer isRight onClick={onNextClick}>
                    <Arrow />
                  </S.ArrowIconContainer>
                </TooltipWrapper>
              </>
            )}
          />
          <If
            condition={isEditing}
            then={() => (
              <S.ActionButtonsWrapper>
                <AddButton
                  onAdd={Actions.addSlide}
                  toShow={slides.length < validation.slides.max}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_slide_label')}
                </AddButton>
                <AddButton
                  onAdd={() => Actions.removeSlide(activeSlideIdx)}
                  toShow={slides.length > validation.slides.min}
                >
                  <Icon name="glyph-remove" className="icon" size="normal" />
                  {translate('remove_slide_label')}
                </AddButton>
              </S.ActionButtonsWrapper>
            )}
          />
        </S.ImageContentWrapper>
        <S.TextContentWrapper
          isOneSlide={slides.length === 1}
          isEditing={isEditing}
        >
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            required={!paragraph}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Paragraph}
            required={!title}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <S.BlocksWrapper>
            <Mapper
              data={blocks}
              render={(block: string, idx: number) => {
                return (
                  <Show when={[block.title, block.paragraph]}>
                    <S.Block
                      key={idx}
                      areThreeBlock={blocks.length === 3}
                      areFiveBlock={blocks.length === 5}
                    >
                      <ControlsWithModalOpener
                        actions={Actions.blockActions({
                          idx,
                          showRemove: blocks.length > validation.blocks.min
                        })}
                        style={{ height: '100%', width: '100%' }}
                        alignment="right"
                      >
                        <S.BlockWrap>
                          <S.IconContainer isEditing={isEditing}>
                            <ControlsWithModalOpener
                              actions={Actions.getIconActions({
                                idx,
                                showRemove: false
                              })}
                            >
                              <S.IconWrap isEditing={isEditing}>
                                <Icon name={block.iconName} />
                              </S.IconWrap>
                            </ControlsWithModalOpener>
                          </S.IconContainer>
                          <EditableContent
                            as={S.BlockTitle}
                            text={block.title}
                            alignment={block.titleAlignment}
                            isEditing={isEditing}
                            required={!block.paragraph}
                            maxCount={validation.blockTitleMaxChar}
                            onChange={(newBlockTitle: string) =>
                              Actions.changeBlockTitle(newBlockTitle, idx)
                            }
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockTitleAlignment(alignment, idx)
                            }
                            className="WM_GLOBAL_heading20"
                          />
                          <EditableContent
                            as={S.BlockParagraph}
                            text={block.paragraph}
                            alignment={block.paragraphAlignment}
                            isEditing={isEditing}
                            required={!block.title}
                            isBlockTitle={block.title}
                            maxCount={validation.blockParagraphMaxChar}
                            onChange={(newBlockParagraph: string) =>
                              Actions.changeBlockParagraph(
                                newBlockParagraph,
                                idx
                              )
                            }
                            className="WM_GLOBAL_paragraph14"
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockParagraphAlignment(
                                alignment,
                                idx
                              )
                            }
                          />
                        </S.BlockWrap>
                      </ControlsWithModalOpener>
                    </S.Block>
                  </Show>
                )
              }}
            />
          </S.BlocksWrapper>
          <AddButton
            onAdd={Actions.addItem}
            toShow={blocks.length < validation.blocks.max}
            style={{
              marginTop: '2rem'
            }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.TextContentWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Slider6
