// @flow

import objToQs from '@editor/common/utils/obj-to-qs'
import type { TGuestListFilters } from '@types'

type TGusetListQueryOptions = TGuestListFilters & {
  offset: number,
  limit: number
}

const DEFAULT_ANSWER_VALUE = 'all'
const DEFAULT_LANGUAGE_VALUE = 'all'

export const buildGuestListQuery = ({
  searchStr,
  answer,
  language,
  offset,
  limit,
  sortingInfo: { columnName, isAscending }
}: TGusetListQueryOptions) => {
  const sort = isAscending ? 'asc' : 'desc'
  const queryObj = {
    sort,
    limit,
    offset,
    sort_by: columnName
  }

  if (searchStr) {
    queryObj.search = searchStr
  }
  if (answer && answer !== DEFAULT_ANSWER_VALUE) {
    queryObj.answer = answer
  }
  if (language && language !== DEFAULT_LANGUAGE_VALUE) {
    queryObj.lang = language
  }

  return objToQs(queryObj)
}
