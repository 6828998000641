//@flow
import React, { useCallback, useState, memo, useRef } from 'react'

import * as Styled from './styled'
import type { TPayPalSettingsPopupProps } from './types'
import {
  INITIAL_DONATING_ERROR_MESSAGES,
  INITIAL_SELLING_ERROR_MESSAGES,
  SELLING_EMPTY_DATA,
  DONATING_EMPTY_DATA
} from './consts'
import { MODAL_TYPES } from '../consts'
import Form from './Form'
import Button from '@editor/common/lib/Button'
import { isValidEmail } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'
import { areObjectsEqual } from './utils'
import { useDiscardDialog } from '@hooks/useDiscardDialog'
import { usePopupCustomMouseDown } from '@hooks'

const PayPalSettingsPopup = ({
  compId,
  popupFormData,
  handler,
  setModalState
}): TPayPalSettingsPopupProps => {
  const isInitialSelling = popupFormData.type === 'selling'
  const isInitialDonating = popupFormData.type === 'donating'

  const initialErrorMessages = isInitialSelling
    ? INITIAL_SELLING_ERROR_MESSAGES
    : INITIAL_DONATING_ERROR_MESSAGES

  const initialSellingData = isInitialSelling
    ? popupFormData
    : SELLING_EMPTY_DATA
  const initialDonatingData = isInitialDonating
    ? popupFormData
    : DONATING_EMPTY_DATA

  const [sellingData, setSellingData] = useState(initialSellingData)
  const [donatingData, setDonatingData] = useState(initialDonatingData)

  const [activeType, setActiveType] = useState(popupFormData.type)
  const [errorMessages, setErrorMessages] = useState(initialErrorMessages)

  const popupRef = useRef(null)
  const isSelling = activeType === 'selling'

  const closePopup = useCallback(() => {
    setModalState(MODAL_TYPES.paypalSettingsPopup, {
      isOpen: false
    })
  }, [])

  const formData = isSelling ? sellingData : donatingData
  const hasChanges = !areObjectsEqual(formData, popupFormData)

  const onClose = useDiscardDialog(closePopup, {
    id: 'paypal',
    hasChanges
  })

  usePopupCustomMouseDown(popupRef, '.paypal-settings-popup', onClose)

  const onSave = () => {
    let isFormValid = true

    Object.keys(errorMessages).forEach(inputKey => {
      if (formData[inputKey] === '') {
        setErrorMessages(errorMessages => ({
          ...errorMessages,
          [inputKey]: translate('this_field_cannot_be_empty_label')
        }))
        isFormValid = false
      }
    })

    if (formData.email && !isValidEmail(formData.email)) {
      setErrorMessages(errorMessages => ({
        ...errorMessages,
        email: translate('please_enter_a_valid_email')
      }))

      isFormValid = false
    }

    if (!isFormValid) return

    if (areObjectsEqual(formData, popupFormData)) {
      closePopup()
      return
    }

    handler && handler(formData)
    closePopup()
  }

  return (
    <Styled.Popup
      isOpen
      onClose={onClose}
      isMobileFullScreen
      className="paypal-settings-popup"
    >
      <Styled.PopupContent ref={popupRef}>
        <Styled.Title>{translate('paypal_settings_title')}</Styled.Title>
        <Form
          compId={compId}
          activeType={activeType}
          sellingData={sellingData}
          donatingData={donatingData}
          setActiveType={setActiveType}
          setSellingData={setSellingData}
          setDonatingData={setDonatingData}
          errorMessages={errorMessages}
          setErrorMessages={setErrorMessages}
        />
        <Styled.Buttons>
          <Button type="secondary" onClick={onClose}>
            {translate('cancel_label')}
          </Button>
          <Button type="primary" onClick={onSave}>
            {translate('save_label', true)}
          </Button>
        </Styled.Buttons>
      </Styled.PopupContent>
    </Styled.Popup>
  )
}

export default memo(PayPalSettingsPopup)
