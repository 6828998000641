/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header1.styled'
import THEME from './Header1.theme'
import ActionGetter from './Actions'
import { validation } from './Header1.consts'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import NavBar from '@website/common/components/NavBar'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import Buttons from '@website/common/components/Buttons'
import { DispatchContext, EditingContext } from '@editor/common/contexts'
import Show from '@website/common/components/Show'
import { openUrl, getExternalUrl } from '@website/common/utils'


const Header1 = memo(props => {
  const {
    data: {
      title,
      logoSrc,
      logoWidth,
      logoColor,
      buttons,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      companyLogos,
      logoDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)

  const { isEditing } = useContext(EditingContext)

  const Actions = ActionGetter(dispatcher)

  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
      isEditing={isEditing}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc: logoSrc,
          logoColor: logoColor,
          logoWidth: logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer
        topOffset={navBarHeight}
        isLogoExist={companyLogos.length !== 0}
        isEditing={isEditing}
      >
        <S.HeaderContent>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            onChange={Actions.changeTitle}
            className="WM_GLOBAL_heading"
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Description}
            maxCount={validation.paragraphMaxChar}
            onChange={Actions.changeParagraph}
            className="WM_GLOBAL_paragraph"
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
        </S.HeaderContent>
        <Show when={[companyLogos.length]}>
          <S.CompanyLogosWrap
            isStart={companyLogos.length > 5}
            isEditing={isEditing}
          >
            <Mapper
              data={companyLogos}
              render={(logo: string, logoIdx: number) => (
                <S.CompanyLogosFigure key={logoIdx}>
                  <ControlsWithModalOpener
                    key={logoIdx}
                    actions={Actions.getClientsActions({
                      showRemove: true,
                      logoIdx,
                      keyForSizes: 'header1',
                      imageUrl: logo.logoUrl,
                      link: logo.clientUrl,
                      withImgSizes:  false,
                      mediaFlyoutShift: { x: 0, y: -225 }
                      })
                    }
                  >
                    <Image
                      as={S.Img}
                      defaultImgSrc={logo.logoImg}
                      sizes={logo.companyLogoDimensions}
                      asProps={{
                          onClick: () =>
                          !!logo.clientUrl &&
                            openUrl(getExternalUrl(logo.clientUrl))
                        }}
                        hasUrl={!!logo.clientUrl}    

                    />
                  </ControlsWithModalOpener>
                </S.CompanyLogosFigure>
              )}
            />
            <AddButton
              onAdd={Actions.addLogo}
              toShow={companyLogos.length < validation.companyLogos.max}
              type="icon"
              large
            >
              +
            </AddButton>
          </S.CompanyLogosWrap>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header1
