// @flow

import React, { memo } from 'react'
import * as Styled from './styled'

import type { TSwitcherProps } from './types'

const Switcher = ({
  isChecked,
  isLoading,
  isFailed,
  size,
  className = '',
  checkedBgColor,
  onChange
}: TSwitcherProps) => {
  const handleSwitcherClick = () => {
    if (isLoading) {
      return
    }

    onChange && onChange(!isChecked)
  }

  return (
    <Styled.Switcher
      className={className}
      size={size}
      isChecked={isChecked}
      isFailed={isFailed}
      isLoading={isLoading}
      checkedBgColor={checkedBgColor}
      onClick={handleSwitcherClick}
    >
      <div>
        <div>
          {isLoading ? <Styled.LoadingThumb /> : <Styled.StaticThumb />}
        </div>
      </div>
    </Styled.Switcher>
  )
}

export default memo(Switcher)
