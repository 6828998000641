//@flow
import React, { useRef, memo, useContext, useEffect } from 'react'

import TransparencyIcon from '@editor/common/assets/svgr-icons/Transparency_icon.svg'
import { ControlModalsContext, MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import { StyledControlItem } from '@website/common/components/Controlled/Controls/styled'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import TooltipWrapper from '@website/common/components/TooltipWrapper'

import type { TProps } from './types'

const CONTROL_CLASSNAME = 'secondary-transparency-control'

const TransparencyControl = ({
  bgOverlayTransparency,
  transparencyFlyoutAlignment,
  transparencyFlyoutShift,
  handler
}: TProps) => {
  const isMobile = useContext(MobileDetectContext)
  const { modalStates, setModalState } = useContext(ControlModalsContext)
  const controlRef = useRef(null)

  const isFlyoutOpen = modalStates[MODAL_TYPES.transparencyFlyout].isOpen

  const tooltipText = isFlyoutOpen ? '' : translate('adjust_transparency_label')

  useEffect(
    () => () => {
      !isMobile &&
        setModalState(MODAL_TYPES.transparencyFlyout, {
          isOpen: false
        })
    },
    []
  )

  const onControlClick = () => {
    setModalState(MODAL_TYPES.transparencyFlyout, {
      isOpen: !isFlyoutOpen,
      targetRef: controlRef,
      alignment: transparencyFlyoutAlignment || 'center',
      shift: transparencyFlyoutShift || { x: 0, y: 57 },
      isFlyoutPositionFixed: false,
      controlClassName: CONTROL_CLASSNAME,
      bgOverlayTransparency,
      handler
    })
  }

  return (
    <TooltipWrapper text={tooltipText}>
      <StyledControlItem
        ref={controlRef}
        className={CONTROL_CLASSNAME}
        onClick={onControlClick}
      >
        <TransparencyIcon />
      </StyledControlItem>
    </TooltipWrapper>
  )
}

export default memo(TransparencyControl)
