/* @flow */

export const validation = {
  titleMaxChar: 100,
  maxNameChar: 40,
  maxPositionChar: 40,
  maxDescriptionChar: 200,
  blocks: {
    min: 1,
    max: 8
  }
}
