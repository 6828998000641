/* @flow */
import React from 'react'
import Slider from 'react-slick'
import S from './Feature28.styled'
import { validation } from './Feature28.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import '../Swipe/swipe-slider-styles.css'
import { sliderSettings } from './consts'
import Arrow from './Arrows'
import Morph from './Feature28_morph'
import LeftArrowIcon from '@website/common/assets/svgr-icons/keyboard_arrow_left.svg'
import RightArrowIcon from '@website/common/assets/svgr-icons/keyboard_arrow_right.svg'
import { translate } from '@editor/common/utils/translations'

const nonEmptyFieldsCount = (...args) => {
  const sum = args.reduce((acc, current) => {
    return acc + Number(!!current)
  }, 0)
  return sum
}

const subBlocksItemsCount = subBlocks => {
  if (subBlocks.length === 0) {
    return 0
  }
  const filledRowsCounts = subBlocks.map(
    subBlock =>
      Number(!!subBlock.subBlockTitle) + Number(!!subBlock.subBlockValue)
  )
  const sumOfSubBlocks = filledRowsCounts.reduce((acc, current) => {
    return acc + current
  }, 0)
  return sumOfSubBlocks
}

class Feature28 extends React.PureComponent {
  state = { activeTabIdx: 0, animate: 0 }

  removeAnimation = () =>
    setTimeout(() => {
      this.setState({
        animate: false
      })
    }, 300)

  setActiveTabIdx = idx => {
    this.setState({ activeTabIdx: idx, animate: true })
  }

  beforeRemove = () => {
    const { activeTabIdx } = this.state
    if (activeTabIdx !== 0) {
      this.setState({ activeTabIdx: activeTabIdx - 1 })
    }
  }

  checkIsLastField = () => {
    const activeTabIdx = this.state.activeTabIdx
    const {
      imgUrl,
      sliderTitle,
      sliderParagraph,
      blockTitle,
      blockValue,
      subBlocks
    } = this.props.data.slides[activeTabIdx]
    const sumOfField =
      nonEmptyFieldsCount(
        imgUrl,
        sliderTitle,
        sliderParagraph,
        blockTitle,
        blockValue
      ) + subBlocksItemsCount(subBlocks)
    return sumOfField === 1
  }

  onPrevArrowClick = () => {
    const { activeTabIdx } = this.state

    this.setState({
      activeTabIdx: activeTabIdx - 1,
      animate: true
    })
  }

  onNextArrowClick = () => {
    const { activeTabIdx } = this.state

    this.setState({
      activeTabIdx: activeTabIdx + 1,
      animate: true
    })
  }

  onSwipe = direction => {
    const { activeTabIdx } = this.state
    const { slides } = this.props.data

    if (direction === 'left') {
      if (activeTabIdx === slides.length - 1) {
        return
      }

      this.onNextArrowClick()
      return
    }

    if (direction === 'right') {
      if (activeTabIdx === 0) {
        return
      }

      this.onPrevArrowClick()
    }
  }

  render() {
    const {
      data: {
        title,
        paragraph,
        titleAlignment,
        paragraphAlignment,
        slides,
        backgroundImgUrl,
        bgOverlayTransparency,
        backgroundImgDimensions
      }
    } = this.props

    const Actions = ActionGetter(this.context)
    const { activeTabIdx, animate } = this.state
    const isLastItem = this.checkIsLastField()

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.StyledContainer
              isEditing={isEditing}
              backgroundImgUrl={backgroundImgUrl}
              backgroundImgDimensions={backgroundImgDimensions}
              bgOverlayTransparency={bgOverlayTransparency}
            >
              <S.HeaderWrap
                isEditing={isEditing}
                bgOverlayTransparency={bgOverlayTransparency}
              >
                <Show when={[title, paragraph]}>
                  <S.Headings>
                    <EditableContent
                      text={title}
                      alignment={titleAlignment}
                      as={S.Title}
                      required
                      maxCount={validation.titleMaxChar}
                      className="WM_GLOBAL_heading42"
                      onChange={(newTitle: string) =>
                        Actions.changeTitle(newTitle)
                      }
                      changeAlignment={Actions.changeTitleAlignment}
                    />
                    <EditableContent
                      text={paragraph}
                      alignment={paragraphAlignment}
                      as={S.Paragraph}
                      maxCount={validation.paragraphMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(newParagraph: string) =>
                        Actions.changeParagraph(newParagraph)
                      }
                      changeAlignment={Actions.changeParagraphAlignment}
                    />
                  </S.Headings>
                </Show>
                <Slider
                  onSwipe={this.onSwipe}
                  {...sliderSettings}
                  prevArrow={
                    <Arrow
                      toShow={activeTabIdx !== 0}
                      SvgComp={LeftArrowIcon}
                      className="left"
                      callbackFunction={this.onPrevArrowClick}
                    />
                  }
                  nextArrow={
                    <Arrow
                      toShow={activeTabIdx !== slides.length - 1}
                      SvgComp={RightArrowIcon}
                      className="right"
                      callbackFunction={this.onNextArrowClick}
                    />
                  }
                >
                  {slides.map((slide, idx) => {
                    const showRemove = slides.length > validation.slides.min
                    return (
                      <S.ControlContainer key={idx} isEditing={isEditing}>
                        <ControlsWithModalOpener
                          actions={Actions.blockActions({
                            idx,
                            beforeRemove: this.beforeRemove,
                            showRemove
                          })}
                          style={{ width: '100%' }}
                        >
                          <S.ItemWrapper
                            onClick={() => this.setActiveTabIdx(idx)}
                            isActive={activeTabIdx === idx}
                          >
                            <If
                              condition={!!slide.iconName}
                              otherwise={() => (
                                <AddButton
                                  onAdd={() => Actions.addIcon(idx)}
                                  style={{
                                    margin: '0px'
                                  }}
                                  type="icon"
                                  large
                                  toShow
                                >
                                  +
                                </AddButton>
                              )}
                              then={() => (
                                <S.IconWrapper>
                                  <ControlsWithModalOpener
                                    actions={Actions.iconActions(idx)}
                                  >
                                    <S.Circle>
                                      <Icon size={35} name={slide.iconName} />
                                    </S.Circle>
                                  </ControlsWithModalOpener>
                                </S.IconWrapper>
                              )}
                            />
                            <EditableContent
                              isLinkControlHidden
                              text={slide.title}
                              as={S.MenuTitle}
                              required={validation.menuTitle.required}
                              maxCount={validation.menuTitleMaxChar}
                              className="WM_GLOBAL_paragraph18"
                              onChange={(newTitle: string) =>
                                Actions.changeMenuTitle(newTitle, idx)
                              }
                            />
                          </S.ItemWrapper>
                        </ControlsWithModalOpener>
                      </S.ControlContainer>
                    )
                  })}
                </Slider>
                <AddButton
                  onAdd={Actions.addBlock}
                  toShow={slides.length < validation.slides.max}
                  style={{
                    margin: '30px 0'
                  }}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_tab_label')}
                </AddButton>
              </S.HeaderWrap>
              <S.BottomContentWrap animate={animate}>
                <Show when={[slides[activeTabIdx].imgUrl]}>
                  <S.ImgControlContainer isEditing={isEditing}>
                    <If
                      condition={!!slides[activeTabIdx].imgUrl}
                      otherwise={() => (
                        <AddButton
                          onAdd={() => Actions.addImageBlock(activeTabIdx)}
                          style={{
                            marginTop: '50px'
                          }}
                          toShow
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_image_label')}
                        </AddButton>
                      )}
                      then={() => (
                        <S.ImageWrapper>
                          <ControlsWithImageCropper
                            style={{ width: '100%' }}
                            actions={Actions.imageActions(
                              activeTabIdx,
                              slides[activeTabIdx],
                              slides[activeTabIdx].imgUrl
                            )}
                          >
                            <Image
                              as={S.Img}
                              alt="Feature image"
                              role="tabpanel"
                              onLoad={this.removeAnimation}
                              asProps={{
                                imgCropParams:
                                  slides[activeTabIdx].imgCropParams
                              }}
                              defaultImgSrc={slides[activeTabIdx].imgUrl}
                              sizes={slides[activeTabIdx].imgDimensions}
                            />
                          </ControlsWithImageCropper>
                        </S.ImageWrapper>
                      )}
                    />
                  </S.ImgControlContainer>
                </Show>
                <Show
                  when={[
                    !isLastItem || (!slides[activeTabIdx].imgUrl && isLastItem)
                  ]}
                >
                  <S.DescriptionContainer>
                    <S.DescriptionWrapper isImage={slides[activeTabIdx].imgUrl}>
                      <S.LineTop>
                        <Morph />
                      </S.LineTop>
                      <S.LineBottom>
                        <Morph />
                      </S.LineBottom>
                      <EditableContent
                        text={slides[activeTabIdx].sliderTitle}
                        alignment={slides[activeTabIdx].sliderTitleAlignment}
                        as={S.SliderTitle}
                        maxCount={validation.sliderTitleMaxChar}
                        required={isLastItem}
                        className="WM_GLOBAL_heading20"
                        onChange={(newTitle: string) => {
                          Actions.changeSliderTitle(newTitle, activeTabIdx)
                        }}
                        changeAlignment={(alignment: string) => {
                          Actions.changeSliderTitleAlignment(
                            alignment,
                            activeTabIdx
                          )
                        }}
                      />
                      <EditableContent
                        text={slides[activeTabIdx].sliderParagraph}
                        alignment={
                          slides[activeTabIdx].sliderParagraphAlignment
                        }
                        as={S.SliderParagraph}
                        maxCount={validation.sliderParagraphMaxChar}
                        required={isLastItem}
                        className="WM_GLOBAL_paragraph14"
                        onChange={(newTitle: string) => {
                          Actions.changeSliderParagraph(newTitle, activeTabIdx)
                        }}
                        changeAlignment={(alignment: string) => {
                          Actions.changeSliderParagraphAlignment(
                            alignment,
                            activeTabIdx
                          )
                        }}
                      />
                      <S.SecondBlockContainer>
                        <S.SecondBlockTitleWrap>
                          <EditableContent
                            text={slides[activeTabIdx].blockTitle}
                            as={S.BlockTitle}
                            required={isLastItem}
                            className="WM_GLOBAL_heading20"
                            maxCount={validation.blockTitleMaxChar}
                            onChange={(newTitle: string) => {
                              Actions.changeBlockTitle(newTitle, activeTabIdx)
                            }}
                          />
                          <EditableContent
                            text={slides[activeTabIdx].blockValue}
                            as={S.BlockParagraph}
                            required={isLastItem}
                            className="WM_GLOBAL_heading20"
                            maxCount={validation.blockValueMaxChar}
                            onChange={(newTitle: string) => {
                              Actions.changeBlockValue(newTitle, activeTabIdx)
                            }}
                          />
                        </S.SecondBlockTitleWrap>
                        <S.SubBlockContainer>
                          {slides[activeTabIdx].subBlocks.map((block, idx) => {
                            return (
                              <ControlsWithModalOpener
                                actions={Actions.subBlockItemActions(
                                  idx,
                                  activeTabIdx,
                                  slides[activeTabIdx].subBlocks.length
                                )}
                                style={{ width: '100%' }}
                              >
                                <S.SubBlockWrap>
                                  <EditableContent
                                    text={block.subBlockTitle}
                                    as={S.BlockTitle}
                                    required={isLastItem}
                                    className="WM_GLOBAL_paragraph14"
                                    maxCount={validation.subBlockTitleMaxChar}
                                    onChange={(newTitle: string) =>
                                      Actions.changeSubBlockTitle(
                                        newTitle,
                                        activeTabIdx,
                                        idx
                                      )
                                    }
                                  />
                                  <EditableContent
                                    text={block.subBlockValue}
                                    as={S.BlockParagraph}
                                    required={isLastItem}
                                    className="WM_GLOBAL_paragraph14"
                                    maxCount={validation.subBlockValueMaxChar}
                                    onChange={(newTitle: string) =>
                                      Actions.changeSubBlockValue(
                                        newTitle,
                                        activeTabIdx,
                                        idx
                                      )
                                    }
                                  />
                                </S.SubBlockWrap>
                              </ControlsWithModalOpener>
                            )
                          })}
                          <AddButton
                            onAdd={() => Actions.addSubBlockItem(activeTabIdx)}
                            toShow={
                              slides[activeTabIdx].subBlocks.length <
                              validation.slides.subBlocks.max
                            }
                            style={{ marginBottom: '10px' }}
                          >
                            <Icon
                              name="glyph-add"
                              className="icon"
                              size="normal"
                            />
                            {translate('add_item_label')}
                          </AddButton>
                        </S.SubBlockContainer>
                      </S.SecondBlockContainer>
                    </S.DescriptionWrapper>
                  </S.DescriptionContainer>
                </Show>
              </S.BottomContentWrap>
            </S.StyledContainer>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

Feature28.contextType = DispatchContext

export default Feature28
