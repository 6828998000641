/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Portfolio11.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      padding-top: 120px;
      @media only screen and (max-width: 1040px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
  background-color: ${getStyleForProp('background')};
  .row-remove {
    color: white;
    padding: 10px;
  }
`

const Headings = styled.div`
  width: 90%;
  margin: 0 auto 50px auto;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 993px) {
    max-width: 900px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 40px;
    max-width: 100%;
  }

  > :last-child {
    margin-bottom: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    h1 {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

const TabControlContainer = styled.div`
  margin: 6px;
`

const TabsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const Tab = styled.div`
  ${({ isActive, ...rest }) =>
    isActive
      ? `
        box-shadow: ${getStyleForProp('raisedShadow')(rest)};
        background: white;
    `
      : `box-shadow: none;
        background: transparent;`};
  border-radius: ${getStyleForProp('cardRadius')};
  padding: 10px 14px;
  cursor: pointer;
  max-width: 180px;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0 3px 11px 0 rgba(41, 55, 97, 0.08);
  }
`

const BlockTitle = styled.p`
  font-weight: 500;
  ${({ isActive, ...rest }) =>
    isActive
      ? `
      color: ${getStyleForProp('activeTab')(rest)};
    `
      : `color: ${getStyleForProp('inactiveTab')(rest)};`};
`

const GalleryTab = styled.div`
  display: ${({ isActive }) => (isActive ? 'block' : 'none')};
  margin-bottom: -15px;
  @media only screen and (max-width: 550px) {
    margin-bottom: -8px;
  }
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const AddButtonWrapper = styled.div`
  display: flex;
  margin: 20px auto;
`

const Item = styled.div`
  width: 24%;
  max-width: 200px;
  display: flex;
  flex-grow: 1;
  padding: 15px;
  box-sizing: border-box;
  border-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 768px) {
    width: 45%;
    max-width: initial;
  }
  @media only screen and (max-width: 550px) {
    padding: 8px;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  @media only screen and (max-width: 550px) {
    & > .control-container {
      .styled-controls {
        left: -8px;
      }
    }
  }
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    position: relative;
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
`

export default {
  StyledContainer,
  Headings,
  Row,
  AddButtonWrapper,
  RowWrapper,
  Item,
  Img,
  Title,
  Paragraph,
  BlockTitle,
  TabsContainer,
  Tab,
  GalleryTab,
  ControlContainer,
  TabControlContainer
}
