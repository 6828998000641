/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header35.styled'
import THEME from './Header35.theme'
import { validation } from './Header35.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Socials from '@website/common/components/Socials'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import Show from '@website/common/components/Show/Show'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const Header35 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      buttons,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      bgOverlayTransparency,
      logoWidth,
      logoDimensions,
      backgroundImgDimensions,
      socials,
      blocks
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.Container topOffset={navBarHeight} isEditing={isEditing}>
      <NavBar
        handleHeightChange={setNavBarHeight}
        alwaysShowBackground
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || socials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={socials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.BgContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.HeaderContent isEditing={isEditing}>
          <Show when={[title, paragraph, ...buttons]}>
            <S.TextsWrap>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                required={validation.title.required}
                maxCount={validation.titleMaxChar}
                className="WM_GLOBAL_heading42"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <Buttons data={buttons} />
            </S.TextsWrap>
          </Show>
        </S.HeaderContent>
      </S.BgContainer>
      <S.WmCustomContainer>
        <S.BlocksContainer>
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => {
              return (
                <S.ControlWrapper key={idx}>
                  <ControlsWithModalOpener
                    actions={Actions.blockActions(idx, blocks.length)}
                    style={{ width: '100%' }}
                  >
                    <S.BlockItem>
                      <If
                        condition={!!block.iconName}
                        otherwise={() => (
                          <AddButton
                            onAdd={() => Actions.addIcon(idx)}
                            style={{
                              margin: '20px'
                            }}
                            type="icon"
                            large
                            toShow
                          >
                            +
                          </AddButton>
                        )}
                        then={() => (
                          <S.IconWrapper>
                            <ControlsWithModalOpener
                              actions={Actions.iconActions({ idx })}
                            >
                              <S.Circle>
                                <Icon
                                  color="white"
                                  size="xlarge"
                                  name={block.iconName}
                                />
                              </S.Circle>
                            </ControlsWithModalOpener>
                          </S.IconWrapper>
                        )}
                      />
                      <EditableContent
                        text={block.title}
                        as={S.BlockTitle}
                        alignment={block.blockTitleAlignment}
                        required={validation.blocks.title.required}
                        maxCount={validation.blockTitleMaxChar}
                        className="WM_GLOBAL_heading20"
                        onChange={(newTitle: string) => {
                          Actions.changeBlockTitle(idx, newTitle)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                    </S.BlockItem>
                  </ControlsWithModalOpener>
                </S.ControlWrapper>
              )
            }}
          />
        </S.BlocksContainer>
        <If
          condition={isEditing}
          then={() => (
            <S.AddBtnWrap>
              <AddButton
                onAdd={Actions.addBlock}
                toShow={blocks.length < validation.blocks.max}
                style={{ margin: '40px 0' }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_block_label')}
              </AddButton>
            </S.AddBtnWrap>
          )}
        />
      </S.WmCustomContainer>
    </S.Container>
  )
})

export default Header35
