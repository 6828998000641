/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header52.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  padding-top: 0;
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      flex-direction: row-reverse;
    `}
  justify-content: ${({ isEditing, isTwoBlock }) =>
    !isEditing && isTwoBlock ? 'center' : 'space-between'};
  padding-top: ${({ topOffset }) => `${topOffset + 90}px`};
  align-items: flex-start;
  @media only screen and (max-width: 1024px) {
    padding-top: ${({ topOffset }) => `${topOffset + 70}px`};
    flex-direction: ${({ theme }) =>
      theme.orientation === 'RIGHT' ? 'column-reverse' : 'column'};
    justify-content: center;
    align-items: center;
  }
  .link {
    color: ${getStyleForProp('link')};
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      > div:not(:nth-child(2)) {
        transform: scale(1.05);
        @media only screen and (min-width: 1025px) {
          min-height: 400px;
        }
      }
    `}
`

const ImageFrame = styled.div`
  width: 100%;
  position: relative;
  transition: transform 0.3s ease-out;
  @media only screen and (max-width: 1024px) {
    transform: none !important;
  }
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    height: initial;
    transform: initial;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 30px;
  ${getTextStyleByAlignment};
`

const SubTitle = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const AdditionalParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

const FirstImageWrapper = styled.div`
  position: relative;
  width: 30.85%;
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
  }
  &:first-child {
    width: 71.71%;
    align-self: end;
    margin-bottom: -40%;
    > div {
      padding-top: 106%;
      .lazy-figure {
        padding-top: 106%;
      }
    }
  }
  &:nth-child(2) {
    width: 35.85%;
    transform: translateY(-50%);
    position: relative;
    z-index: 2;
    margin-bottom: -10%;
    > div {
      padding-top: 106%;
      .lazy-figure {
        padding-top: 106%;
      }
    }
  }
  &:nth-child(3) {
    width: 35.85%;
    transform: translate(130%, -2%);
    > div {
      padding-top: 106%;
      .lazy-figure {
        padding-top: 106%;
      }
    }
  }
`

const SecondImageWrapper = styled.div`
  position: relative;
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
  }
  &:first-child {
    width: 71%;
    > div {
      padding-top: 123%;
      .lazy-figure {
        padding-top: 123%;
      }
    }
  }
  &:nth-child(2) {
    width: 48.73%;
    align-self: end;
    transform: translateY(-125%);
    margin-bottom: -54%;
    > div {
      padding-top: 110%;
      .lazy-figure {
        padding-top: 110%;
      }
    }
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: ${getStyleForProp('cardRadius')};
`

const ContentWrap = styled.div`
  width: 27.91%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 390px;
  @media only screen and (max-width: 1366px) {
    min-height: 350px;
  }
  @media only screen and (max-width: 1230px) {
    min-height: 320px;
  }
  @media only screen and (min-width: 1025px) {
    ${({ isEditing, isLeftBlockMissing, isRightBlockMissing, theme }) =>
      (isLeftBlockMissing && !isEditing && theme.orientation === 'LEFT') ||
      (isRightBlockMissing && !isEditing && theme.orientation === 'RIGHT')
        ? css`
            margin-right: 80px;
          `
        : (isRightBlockMissing && !isEditing && theme.orientation === 'LEFT') ||
          (isLeftBlockMissing && !isEditing && theme.orientation === 'RIGHT')
        ? css`
            margin-left: 80px;
          `
        : css``}
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    min-height: initial;
    max-width: initial;
  }
  > :last-child {
    margin-bottom: 0;
  }
  > .control-container {
    width: 100%;
    margin-bottom: 30px;
    p,
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .link-section {
    display: flex;
    justify-content: center;
  }
`

const ImagesContainer = styled.div`
  ${({ isEditing }) =>
    isEditing
      ? css`
          width: calc(100% - 20px);
          padding: 10px;
        `
      : css`
          width: 100%;
        `}
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  ${({ isAnimated, isOnScreen, isEditing }) =>
    isAnimated &&
    isOnScreen &&
    !isEditing &&
    css`
      figure {
        animation-name: header52DownUp;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate-reverse;
      }
    `}
  @keyframes header52DownUp {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-12px);
    }
  }
  .rf-add-btn {
    padding: 15px;
    .icon {
      margin-right: auto;
    }
  }
`

const FirstCollage = styled.div`
  width: 30.83%;
  @media only screen and (min-width: 1025px) {
    ${({ isBlockEmpty }) =>
      isBlockEmpty &&
      css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 578px;
    ${({ theme }) =>
      theme.orientation === 'RIGHT'
        ? css`
            margin-top: 40px;
          `
        : css`
            margin-bottom: 40px;
          `}
  }
  ${ImagesContainer} {
    > .add-button-wrap {
      position: absolute;
      top: 80%;
      left: 12%;
    }
    > div {
      &:nth-child(2) {
        figure {
          animation-duration: 3s;
          animation-delay: -3s;
          &:hover {
            animation-play-state: paused;
          }
        }
      }
      &:nth-child(3) {
        figure {
          animation-duration: 4.5s;
          animation-delay: -4s;
          &:hover {
            animation-play-state: paused;
          }
        }
      }
    }
  }
  ${({ isSecondBlockEmpty }) =>
    isSecondBlockEmpty &&
    css`
      > .control-container {
        box-shadow: none;
      }
    `}
`

const SecondCollage = styled.div`
  width: 34.58%;
  ${ImagesContainer} {
    > div {
      &:nth-child(2) {
        figure {
          animation-duration: 4s;
          animation-delay: -1.8s;
          &:hover {
            animation-play-state: paused;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 1025px) {
    ${({ isBlockEmpty }) =>
      isBlockEmpty &&
      css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 553px;
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            margin-top: 40px;
          `
        : css`
            margin-bottom: 40px;
          `}
  }
  ${({ isFirstBlockEmpty }) =>
    isFirstBlockEmpty &&
    css`
      > .control-container {
        box-shadow: none;
      }
    `}
`

const AdditionalBlockWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 71%;
  margin-top: 20px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const AdditionalBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .control-container {
    margin-top: 20px;
    margin-bottom: 10px;
  }
`

const ArrowWrapper = styled.div`
  position: absolute;
  width: 20%;
  top: 60%;
  left: 10%;
  @media only screen and (max-width: 1024px) {
    top: 70%;
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          > div {
            > svg:first-child {
              width: 100%;
              transform: rotate(-95deg);
              path {
                fill: ${getStyleForProp('heading')};
              }
            }
          }
        `
      : css`
          svg {
            width: 100%;
            transform: rotate(-95deg);
            path {
              fill: ${getStyleForProp('heading')};
            }
          }
        `}
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  AdditionalParagraph,
  SubTitle,
  FirstCollage,
  SecondCollage,
  FirstImageWrapper,
  SecondImageWrapper,
  Img,
  ImageFrame,
  ContentWrap,
  ImagesContainer,
  AdditionalBlockWrap,
  AdditionalBlock,
  ArrowWrapper
}
