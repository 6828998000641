import styled, { css } from 'styled-components'

export const Control = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fafc;
  border: 1px solid #c1c9e0;
  border-radius: 5px;
  transition: all 100ms ease;
  box-sizing: border-box;
  cursor: pointer;

  svg {
    fill: #545f7e;
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          border: 1px solid #3271e6;
          svg {
            fill: #252e48;
          }
        `
      : ''};

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.5;
          pointer-events: none;
          cursor: default;
        `
      : css`
          &:hover {
            svg {
              fill: #252e48;
            }
          }
        `};

  &:hover {
    border: 1px solid #3271e6;
  }
`
