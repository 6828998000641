/* @flow */
import React, { useContext, memo } from 'react'
import S from './Pricing7.styled'
import { validation } from './Pricing7.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Show from '@website/common/components/Show/Show'
import { translate } from '@editor/common/utils/translations'

const Pricing7 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      plans,
      primaryPlanIdx,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.PricingHeaderContainer>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Heading}
                alignment={titleAlignment}
                required={!paragraph}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading42"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                required={!title}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.PricingHeaderContainer>
        </Show>
        <S.PricingCardsContainer isFourBlock={plans.length === 4}>
          {plans.map((plan, planIndex) => {
            const isPrimary = planIndex === primaryPlanIdx
            return (
              <S.PriceCardWrapper
                isPrimary={isPrimary}
                key={planIndex}
                isEditing={isEditing}
              >
                <ControlsWithModalOpener
                  actions={Actions.cardAction({
                    idx: planIndex,
                    showStar: !isPrimary,
                    showRemove: plans.length > validation.plans.min
                  })}
                >
                  <S.PriceCard isPrimary={isPrimary} isEditing={isEditing}>
                    <S.PricingCardHeader isPrimary={isPrimary}>
                      <S.LabelContainer isPrimary={isPrimary}>
                        <S.LabelBorder>
                          <S.Label isPrimary={isPrimary}>
                            <EditableContent
                              areControlsHidden
                              as={S.LabelTitle}
                              text={plan.labelTitle}
                              required={true}
                              maxCount={validation.labelTitleMaxChar}
                              onChange={(value: string) => {
                                Actions.changeLabelTitle(value, planIndex)
                              }}
                              className="WM_GLOBAL_heading32"
                            />
                            <EditableContent
                              areControlsHidden
                              as={S.LabelParagraph}
                              text={plan.labelParagraph}
                              maxCount={validation.labelParagraphMaxChar}
                              onChange={(newDate: string) => {
                                Actions.changeLabelParagraph(newDate, planIndex)
                              }}
                              className="WM_GLOBAL_paragraph14"
                            />
                          </S.Label>
                        </S.LabelBorder>
                      </S.LabelContainer>
                      <EditableContent
                        text={plan.cardTitle}
                        as={S.CardTitle}
                        isEditing={isEditing}
                        alignment={plan.cardTitleAlignment}
                        maxCount={validation.cardTitle}
                        onChange={(value: string) => {
                          Actions.changeCardTitle(value, planIndex)
                        }}
                        className="WM_GLOBAL_heading32"
                        changeAlignment={(alignment: string) =>
                          Actions.changeCardTitleAlignment(alignment, planIndex)
                        }
                      />
                    </S.PricingCardHeader>
                    <S.PricingCardButtomContainer>
                      <S.PricingCardButtomContent>
                        <S.PricingCardContent>
                          {plan.options.map((option, optionIdx) => (
                            <ControlsWithModalOpener
                              style={{ width: '100%' }}
                              actions={Actions.optionActions(
                                planIndex,
                                optionIdx
                              )}
                              key={optionIdx}
                            >
                              <S.OptionWrapper>
                                <EditableContent
                                  text={option}
                                  as={S.Option}
                                  toPropogate={false}
                                  maxCount={validation.optionMaxChar}
                                  className="WM_GLOBAL_paragraph18"
                                  onChange={value =>
                                    Actions.changeOptnValue(
                                      planIndex,
                                      optionIdx,
                                      value
                                    )
                                  }
                                />
                              </S.OptionWrapper>
                            </ControlsWithModalOpener>
                          ))}
                          <AddButton
                            style={{
                              margin: '10px 0'
                            }}
                            onAdd={() => Actions.addOption(planIndex)}
                            toShow={
                              plan.options.length < validation.plans.options.max
                            }
                          >
                            <Icon
                              name="glyph-add"
                              className="icon"
                              size="normal"
                            />
                            {translate('add_item_label')}
                          </AddButton>
                        </S.PricingCardContent>
                        <S.ButtonWrapper isPrimary={isPrimary}>
                          <ControlsWithModalOpener
                            actions={Actions.btnActions(
                              planIndex,
                              {
                                text: plan.buttonText,
                                link: plan.buttonUrl
                              },
                              false
                            )}
                            style={{ width: '100%' }}
                          >
                            <LinkWrapper
                              text={plan.buttonText}
                              url={plan.buttonUrl}
                              linkProps={{
                                className: 'rf-comp-btn'
                              }}
                            />
                          </ControlsWithModalOpener>
                        </S.ButtonWrapper>
                      </S.PricingCardButtomContent>
                    </S.PricingCardButtomContainer>
                  </S.PriceCard>
                </ControlsWithModalOpener>
              </S.PriceCardWrapper>
            )
          })}
        </S.PricingCardsContainer>
        <AddButton
          style={{ marginTop: '1.5rem' }}
          onAdd={Actions.addBlock}
          toShow={plans.length < validation.plans.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Pricing7
