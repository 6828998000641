export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 500,
  headerSocials: {
    min: 0,
    max: 6
  }
}

export const BUTTON_INITIAL_STATE = [
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]
