import styled, { css } from 'styled-components'
import { styleByLang } from '@editor/common/utils/style-by-lang'

import StarIcon from '@editor/common/assets/svgr-icons/star_icon.svg'
import _EventIcon from '@editor/common/assets/svgr-icons/event_category_icon.svg'

const stylesObj = {
  tr: {
    maxWidth: '140px'
  },
  default: {
    maxWidth: '100%'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const CategoryWrapper = styled.li`
  position: relative;
  width: 100%;
  border-bottom: 1px solid #c1c9e0;
  list-style-type: none;
  &:first-child {
    .arrow {
      display: none;
    }
  }
`

export const Category = styled.div`
  padding: 18px 20px;
  box-sizing: border-box;
  cursor: pointer;
  @media only screen and (min-width: 1024px) {
    p {
      max-width: ${getStylesByLang('maxWidth')};
    }
  }
  .arrow {
    width: 8px;
    height: 5px;
    position: absolute;
    right: 27px;
    top: 27px;
    transition: transform 300ms ease;
    ${({ isSubcategoriesOpen }) =>
      isSubcategoriesOpen
        ? css`
            fill: #252e48;
            transform: none;
          `
        : css`
            fill: #545f7e;
            transform: rotate(180deg);
          `};
  }

  & > div {
    display: flex;
    margin-right: 20px;
    align-items: center;
    transition: transform 300ms;

    p {
      color: #545f7e;
      font-size: 14px;
    }

    svg {
      fill: #545f7e;
      padding-right: 10px;
    }
  }

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #eef5ff;
          & > div {
            transform: translateX(5px);
            p {
              color: #252e48;
              font-weight: 500;
            }
            svg {
              fill: #252e48;
            }
          }
        `
      : ''};

  &:hover {
    background-color: #eef5ff;
    & > div {
      transform: translateX(5px);
      p {
        color: #252e48;
      }
      svg {
        fill: #252e48;
      }
    }

    .arrow {
      fill: #252e48;
    }
  }
`

export const SubCategoriesWrapper = styled.div`
  height: ${({ isOpen, height }) => (isOpen ? `${height}px` : '0px')};
  overflow: hidden;
  transition: height 300ms ease;
`

export const SubCategories = styled.ul`
  overflow: hidden;
  padding: 0 25px 20px 34px;
`

export const SubCategory = styled.li`
  @media only screen and (max-width: 768px) {
    white-space: nowrap;
  }
  position: relative;
  padding-top: 20px;
  border-left: 1px solid #cfe0ff;
  box-sizing: border-box;
  list-style-type: none;
  cursor: pointer;

  p {
    color: ${({ isActive }) => (isActive ? '#252E48' : '#545f7e')};
    font-weight: ${({ isActive }) => (isActive ? '500' : '400')};
    font-size: 14px;
    padding-left: 30px;
    box-sizing: border-box;
    max-width: 155px;
    transition: transform 300ms;
    @media only screen and (min-width: 768px) and (max-width: 1270px) {
      max-width: none;
    }
  }

  .curved-detail {
    position: absolute;
    fill: #cfe0ff;
    left: -1px;
    top: 50%;
    transform: translateY(-12%);
  }

  &:last-child {
    border-left: 1px solid transparent;
  }

  &:last-child {
    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: 0;
      width: 1px;
      height: 50%;
      background: #cfe0ff;
    }
  }

  &:hover {
    p {
      color: #252e48;
      transform: translateX(5px);
    }
  }
`

export const NonProfitIcon = styled(StarIcon)`
  margin: 0 2px;
  width: 20px;
  height: 20px;
`

export const EventIcon = styled(_EventIcon)`
  width: 22px;
  height: auto;
`
