/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  removeBlock
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockContent = (idx, newContent) => {
    dispatcher({
      type: 'CHANGE_BLOCK_CONTENT',
      value: { idx, newContent }
    })
  }

  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeTitleAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeParagraphAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: () => dispatcher(addBlock()),
    removeBlock,
    changeTitle,
    changeBlockTitle,
    changeBlockContent,
    featureActions: removeBlockActionMaker(dispatcher),
    changeTitleAlignment,
    changeParagraphAlignment
  }
}
