export const validation = {
  titleMaxChar: 100,
  paragraphMaxChar: 200,
  blocks: {
    min: 1,
    max: 5
  },
  blockTitleMaxChar: 50,
  blockSubTitleMaxChar: 50,
  blockParagraphMaxChar: 300
}
