/* @flow */
import React from 'react'
import Slider from 'react-slick'
import ReactDOM from 'react-dom'
import S from './lightBox.styled.js'
import Icon from '@website/common/components/Icon'
import { useKeyPress } from '@hooks'
import { KEY_CODES } from '@website/conf/consts'
import Image from '@website/common/components/Image'
import { withEditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import ArrowLeftIcon from '@website/common/assets/svgr-icons/keyboard_arrow_left.svg'
import ArrowRightIcon from '@website/common/assets/svgr-icons/keyboard_arrow_right.svg'
import CloseIcon from '@website/common/assets/svgr-icons/close.svg'
import './lightBox.sass'
import {
  lightBoxImageWidth,
  lightBoxMainSliderSettings,
  lightboxImagesSliderSettings
} from './Config'

export const PortfolioLightBox = ({
  isEditing,
  portfolioImages,
  closePreviewMode,
  lightBoxActiveImgIndex
}) => {
  const [activeIndex, forceSetIdx] = React.useState(lightBoxActiveImgIndex)

  const mainSliderRef = React.useRef(null)
  const portfoliImgesSliderRef = React.useRef(null)

  const onPrevClick = () => {
    mainSliderRef.current.slickPrev()
  }
  const onNextClick = () => {
    mainSliderRef.current.slickNext()
  }

  useKeyPress({
    withBuffer: true,
    buffer: [
      { code: 'left', action: onPrevClick },
      { code: 'right', action: onNextClick }
    ]
  })

  useKeyPress({
    onPress: closePreviewMode,
    withBuffer: false,
    keyCode: KEY_CODES.ESC
  })

  const onImgSelect = (index: number) => {
    mainSliderRef.current.slickGoTo(index)
    forceSetIdx(index)
  }
  const onSlideChange = (index: number) => {
    portfoliImgesSliderRef.current.slickGoTo(index)
    forceSetIdx(index)
  }
  const getSelectedSlideIndex = () =>
    `${activeIndex + 1}/${portfolioImages.length}`

  const render = () => (
    <S.CustomSSContainer>
      <S.IconContainer className="rf-close" onClick={closePreviewMode}>
        <Icon color="white" size="large" SvgComp={CloseIcon} />
      </S.IconContainer>
      <S.MainSliderWrapper>
        <S.MainSliderContainer>
          <S.TopSliderContainer>
            <S.IconContainer className="left" onClick={onPrevClick}>
              <Icon color="white" size="xlarge" SvgComp={ArrowLeftIcon} />
            </S.IconContainer>
            <Slider
              ref={mainSliderRef}
              initialSlide={activeIndex}
              afterChange={onSlideChange}
              {...lightBoxMainSliderSettings}
            >
              {portfolioImages.map((pic: string, picIdx: number) => (
                <S.ImageWrap key={picIdx}>
                  <Image
                    as={S.LightBoxSelectedImage}
                    defaultImgSrc={pic.imgUrl}
                    alt="Portfolio image"
                    sizes={pic.imgDimensions}
                    asProps={{
                      selected: picIdx === activeIndex,
                      className: 'lightbox-selected-image'
                    }}
                    withLazy={false}
                  />
                </S.ImageWrap>
              ))}
            </Slider>
            <S.SlideIndexIndicator>
              {getSelectedSlideIndex()}
            </S.SlideIndexIndicator>
            <S.IconContainer className="right" onClick={onNextClick}>
              <Icon color="white" size="xlarge" SvgComp={ArrowRightIcon} />
            </S.IconContainer>
          </S.TopSliderContainer>
        </S.MainSliderContainer>

        <S.CarouselSlider lightBoxImageWidth={lightBoxImageWidth}>
          <Slider
            ref={portfoliImgesSliderRef}
            {...lightboxImagesSliderSettings}
          >
            {portfolioImages.map((picture: string, index: number) => (
              <S.PortImgWrap
                key={index}
                selected={index === activeIndex}
                lightBoxImageWidth={lightBoxImageWidth}
                isEditing={isEditing}
              >
                <Image
                  as={S.LightBoxImage}
                  defaultImgSrc={picture.imgUrl}
                  sizes={picture.imgDimensions}
                  alt="Portfolio image"
                  withLazy={false}
                  asProps={{
                    onClick: () => onImgSelect(index),
                    lightBoxImageWidth
                  }}
                />
              </S.PortImgWrap>
            ))}
          </Slider>
        </S.CarouselSlider>
      </S.MainSliderWrapper>
    </S.CustomSSContainer>
  )
  return ReactDOM.createPortal(
    <S.PreviewModeContainer
      className="portfolio11-lightBox"
      isEditing={isEditing}
    >
      {render()}
    </S.PreviewModeContainer>,
    document.body
  )
}

export default withEditingContext(PortfolioLightBox)
