import { useRef, useEffect } from 'react'

export const useDidUpdateEffect = (callback, dependencies) => {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      return callback()
    }

    didMountRef.current = true
  }, dependencies)
}
