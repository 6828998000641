/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Contact8.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: ${props => (props.isEditing ? '110px 0 0 0' : '0')};
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: initial;
  padding: 0;
  @media only screen and (max-width: 1023px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }
  .socials-block {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 50px 0 0 0;
  }
  .socials-wrapper {
    display: inline-flex;
    .link {
      width: 24px;
      height: 24px;
      fill: ${getStyleForProp('social')};
    }
  }
`

const Content = styled.div`
  position: relative;
  width: 46%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 120px 3vw;
  box-sizing: border-box;
  background-color: ${getStyleForProp('background')};
  @media only screen and (max-width: 1280px) {
    padding: 80px 40px;
  }
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
  > .control-container {
    width: 100%;
    p,
    h1 {
      padding: 0;
      margin: 0;
    }
  }
  > .control-container:nth-child(2) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 20px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 100%;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-top: 10px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1023px) {
    margin-top: ${props => (props.isEditing ? '12px' : '0')};
  }
`

const FormWrapper = styled.div`
  width: 95%;
  max-width: 400px;
  margin: 40px 0 0 0;
  .FormMessage {
    width: 100%;
    bottom: 58px;
    transform: translateX(-50%);
    @media only screen and (max-width: 650px) {
      bottom: 55px;
    }
  }
  .success-message {
    bottom: 56px;
  }
`

const MapWrapper = styled.div`
  width: 52%;
  flex-grow: 1;
  position: relative;
  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: 70vw;
  }
  @media only screen and (max-width: 500px) {
    height: 100vw;
  }
`

const Map = styled.iframe`
  width: 100%;
  height: 100%;
`

const ContactsWrapper = styled.div`
  width: 100%;
  margin: 45px 0 0 0;
  > .control-container {
    margin: 0 10px 10px 10px;
    @media only screen and (max-width: 1024px) {
      margin-left: 0px;
    }
    p {
      margin: 0;
      padding: 0;
    }
  }
`

const ContactItems = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & > p {
    margin: 0 10px;
  }
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('heading')};
  margin-bottom: 10px;
`

const A = styled.a`
  text-decoration: none;
  display: block;
  margin: 0 10px;
  color: ${getStyleForProp('heading')};
  transition: all 0.1s linear;
  &:hover {
    opacity: 0.7;
  }
`

const Line = styled.div`
  width: 1px;
  height: 19px;
  background: ${getStyleForProp('heading')};
`

const SubscribeFormWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    .wm-email-btn {
      margin: 0;
    }
    .wm-email-input-wrapper {
      margin: 0 0 30px 0;
      max-width: initial;
      min-width: 280px;
      transform: unset;
    }
    .wm-email-input {
      padding: 0 20px;
    }
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('social')
}))``

export default {
  StyledContainer,
  WmCustomContainer,
  SubscribeFormWrap,
  Title,
  Paragraph,
  Content,
  ContentContainer,
  FormWrapper,
  MapWrapper,
  Map,
  ContactsWrapper,
  BlockParagraph,
  A,
  ContactItems,
  Line,
  Icon
}
