export const validation = {
  titleMaxChar: 50,
  paragraphMaxChar: 100,
  blockTitleMaxChar: 30,
  blockParagraphMaxChar: 60,
  blocks: {
    min: 2,
    max: 30
  },
  buttons: {
    min: 0,
    max: 2
  }
}
