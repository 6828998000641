/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature6.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: ${getStyleForProp('orientation')};
  justify-content: space-between;
  padding-top: ${({ isEditing }) => (isEditing && '140px')};
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  padding-top: 20px;
`

const ContentWrap = styled.div`
  width: 40%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  .buttons-block {
    margin-left: -15px;
  }
  > p:first-child,
  > div:first-child {
    padding-top: 0;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
    &:first-child {
      ${({ theme }) =>
    css`
          margin: ${theme.orientation === 'RIGHT'
        ? '40px 0 0 0'
        : '0 0 60px 0'};
        `}
    }
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const ImageFigure = styled.div`
  text-align: center;
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: 0 auto;
  }
  .LazyLoad {
    padding-top: 56.25%;
    position: relative;
  }
  .control-container {
    height: initial !important;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const ImageWrap = styled.div`
  width: 45%;
  max-width: 540px;
  @media only screen and (max-width: 992px) {
    width: 100% !important;
    &:first-child {
      ${({ theme }) =>
    css`
          margin: ${theme.orientation === 'RIGHT'
        ? '40px 0 0 0'
        : '0 0 40px 0'};
        `}
    }
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

export default {
  Img,
  StyledContainer,
  WmCustomContainer,
  Title,
  Description,
  ContentWrap,
  ImageWrap,
  ImageFigure
}
