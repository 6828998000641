export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  title: { required: true },
  buttons: {
    min: 0,
    max: 2
  },
  companyLogos: {
    min: 0,
    max: 10
  }
}
