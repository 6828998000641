/* @flow */

export const validation = {
  headingMaxChar: 100,
  paragraphMaxChar: 400,
  clientsLogos: {
    min: 2,
    max: 12
  }
}
