/* @flow */
import { simpleButtonActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changePlaceHolderText,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeDate = value => {
    dispatcher({
      type: 'CHANGE_DATE',
      value
    })
  }

  const changeDateTitle = (newDateTitle: string) => {
    dispatcher({
      type: 'CHANGE_DATE_TITLE',
      value: newDateTitle
    })
  }

  const changeDateLabel = (dateLabelValue: string, dateLabelIdx: number) => {
    dispatcher({
      type: 'CHANGE_DATE_LABEL',
      value: { dateLabelValue, dateLabelIdx }
    })
  }

  const removeForm = () => {
    dispatcher({
      type: 'REMOVE_FORM'
    })
  }

  const addForm = () => {
    dispatcher({
      type: 'ADD_FORM'
    })
  }

  const formActions = () => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeForm())
    }
  ]

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changePlaceHolderText = (placeholder: string) =>
    dispatcher(changePlaceHolderText(placeholder))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeDataTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_DATA_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changePlaceHolderText: _changePlaceHolderText,
    changeDate,
    changeParagraph: _changeParagraph,
    changeDateTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeDataTitleAlignment,
    changeDateLabel,
    addForm: () => dispatcher(addForm()),
    formActions,
    btnActions: simpleButtonActionMaker(dispatcher)
  }
}
