//@flow
import React, { memo } from 'react'

import { translate } from '@editor/common/utils/translations'

import { NAME_CATEGORY_CHAR_LIMIT, DESCRIPTION_CHAR_LIMIT } from '../consts'
import { InputBG } from '../styled'
import * as Styled from './styled'

type TProps = {
  name: string,
  description: string,
  setName: (name: string) => void,
  setDescription: (description: string) => void
}

const NameDescriptionInputs = ({
  name,
  description,
  setName,
  setDescription
}: TProps) => {
  const onNameChange = e => {
    const { value } = e.target

    if (value.length >= NAME_CATEGORY_CHAR_LIMIT) {
      return
    }

    setName(e.target.value)
  }

  const onDescriptionChange = e => {
    const { value } = e.target

    if (value.length >= DESCRIPTION_CHAR_LIMIT) {
      return
    }

    setDescription(e.target.value)
  }

  return (
    <>
      <Styled.NameInputWrapper>
        <Styled.NameInput
          value={name}
          placeholder={translate('enter_your_business_name_placeholder')}
          onChange={onNameChange}
        />
        <InputBG />
      </Styled.NameInputWrapper>
      <Styled.DescriptionInputWrapper>
        <Styled.DescriptionInput
          value={description}
          placeholder={translate(
            'describe_your_business_in_a_few_sentences_placeholder'
          )}
          onChange={onDescriptionChange}
        />
        <InputBG />
      </Styled.DescriptionInputWrapper>
    </>
  )
}

export default memo(NameDescriptionInputs)
