/* @flow */
import React from 'react'
import * as Styled from './ErrorContent.styled'

const ErrorContent = ({ message, icon, title }) => {
  return (
    <Styled.ErrorContnetWrapper>
      <Styled.Image src={icon} alt="Response image" />
      <Styled.Title>{title}</Styled.Title>
      <Styled.Message>{message}</Styled.Message>
    </Styled.ErrorContnetWrapper>
  )
}

export default ErrorContent
