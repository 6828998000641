// @flow

import React, { useContext } from 'react'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import Message from './Message'
import * as Styled from './styled'
import {
  BUTTON_ICONS,
  MESSENGER_DEFAULT_PROPS,
  MESSENGER_STYLES
} from '../consts'
import { getButtonUrl, getAvatarProps } from './utils'
import { EditingContext } from '@contexts'

const SingleMessenger = ({
  type,
  avatar,
  optimizedAvatar,
  imgCropParams,
  name,
  subtitle,
  message,
  buttonText,
  value,
  onClose
}) => {
  const { url: avatarUrl, dimensions: avatarDimensions } = getAvatarProps(
    avatar,
    optimizedAvatar
  )
  const { isEditing } = useContext(EditingContext)

  return (
    <Styled.SingleMessenger type={type}>
      <Styled.Header>
        <Styled.CloseButton
          className="messenger-close-button"
          onClick={onClose}
        >
          <Icon
            color={MESSENGER_STYLES[type].closeIconColor}
            name="close"
            size="middle"
          />
        </Styled.CloseButton>
        <Styled.AvatarWrapper>
          <Image
            withLazy={!isEditing}
            as={Styled.Avatar}
            asProps={{ imgCropParams }}
            sizes={avatarDimensions}
            defaultImgSrc={avatarUrl}
            alt="avatar"
          />
        </Styled.AvatarWrapper>
        <Styled.HeaderTexts>
          <h5>{name}</h5>
          <p>{subtitle}</p>
        </Styled.HeaderTexts>
      </Styled.Header>
      <Styled.Content>
        <Message
          type={type}
          imgCropParams={imgCropParams}
          avatar={avatarUrl}
          avatarSizes={avatarDimensions}
          text={message}
          name={name}
        />
      </Styled.Content>
      <Styled.Footer>
        <Styled.Button href={getButtonUrl(type, value)} target="_blank">
          <img src={BUTTON_ICONS[type]} alt={type} />
          <span>{buttonText}</span>
        </Styled.Button>
      </Styled.Footer>
    </Styled.SingleMessenger>
  )
}

SingleMessenger.defaultProps = MESSENGER_DEFAULT_PROPS

export default SingleMessenger
