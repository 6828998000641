/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature38.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-top: ${({ isEditing }) => isEditing && '160px'};
  @media only screen and (max-width: 768px) {
    padding-top: ${({ isEditing }) => isEditing && '140px'};
    ${({ theme }) =>
      theme.orientation === 'RIGHT'
        ? css``
        : css`
            padding-bottom: 140px;
          `}
  }
  @media only screen and (max-width: 1024px) {
    ${({ theme }) =>
    theme.orientation === 'RIGHT'
      ? css``
      : css`
          padding-bottom: 110px;
        `}
  }
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => isEditing && '140px'};
    ${({ theme }) =>
      theme.orientation === 'RIGHT'
        ? css`
            padding-bottom: 80px;
        `
        : css`
            padding-bottom: 60px;
          `}
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  column-gap: 60px;
  justify-content: ${({ isOnlyLeft, isEditing }) =>
    isOnlyLeft && !isEditing ? 'center' : 'space-between'};
  align-items: center;
  padding-top: 0;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      flex-direction: row-reverse;
    `}
  @media only screen and (max-width: 1024px) {
    ${({ theme }) =>
      theme.orientation === 'RIGHT'
        ? css`
            flex-direction: column-reverse;
          `
        : css`
            flex-direction: column;
          `}
    row-gap: 40px;
  }

  .learn-more-link {
    color: ${getStyleForProp('link')};
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
`

const TextImageContent = styled.div`
  width: 100%;
  max-width: 600px;
  position: relative;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: 1440px) {
    width: calc(100% - 435px);
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 30px;
    ${({ theme }) =>
    theme.orientation === 'RIGHT'
      ? css`
        margin-bottom: 30px;
      `
      : css`
        `}

  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;

  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    height: initial;
  }
`

const AdditionalParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const ContentBox = styled.div`
  padding-left: 30px;
  padding-top: 10px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ isEditing }) => isEditing && 'row-gap: 30px'};
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    content: '';
    width: 1px;
    height: 100%;
    background-color: ${getStyleForProp('line')};
  }
  &:before {
    position: absolute;
    left: -1px;
    top: -2px;
    content: '';
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: ${getStyleForProp('line')};
  }
  .control-container > p {
    margin-top: 0;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 20px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 10px;
  }
  p:first-child {
    margin-top: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  & + h2 {
    margin-top: 30px;
  }
`

const SubTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  margin: 0;
  ${getTextStyleByAlignment}
`

const ImageWrapper = styled.div`
  position: relative;
  transition: all 0.5s ease;
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    transform: none;
  }
  width: 100%;
  max-width: 332px;
  & > div {
    padding-top: 155%;
    .lazy-figure {
      padding-top: 155%;
    }
  }

  &:nth-child(1):nth-last-child(1) {
    @media only screen and (max-width: 457px) {
      margin-top: -40px;
    }
  }

  &:nth-child(1):nth-last-child(2) {
    margin-right: -52px;
    transform: translateY(12%);
    margin-bottom: 56px;
    z-index: 2;
    @media only screen and (max-width: 600px) {
      margin-bottom: 10px;
    }
  }
  &:nth-child(2) {
    transform: translateY(-5%);
  }
  > div {
    transition: all 0.01s ease;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: unset;
  transition: all 0.01s ease;
`

const ContentWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  max-width: 733px;
  @media only screen and (max-width: 1440px) {
    max-width: 540px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    row-gap: 30px;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
  }
  .link-section {
    margin-top: 30px;
  }
`

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const ImagesWrap = styled.div`
  width: 100%;
  > .add-button-wrap {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const ButtonBlock = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 650px) {
    justify-content: center;
  }
  .buttons-block {
    @media only screen and (max-width: 650px) {
      justify-content: center;
    }
  }
  .buttons-wrapper {
    @media only screen and (max-width: 650px) {
      display: flex;
      justify-content: center;
    }
    @media only screen and (min-width: 651px) {
      margin-left: -20px;
    }
  }
  &:empty {
    display: none;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  SubTitle,
  AdditionalParagraph,
  Paragraph,
  TextImageContent,
  ImageWrapper,
  Img,
  ContentBox,
  ControlContainer,
  ContentWrap,
  ImagesContainer,
  ImagesWrap,
  ButtonBlock
}
