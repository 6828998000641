import React, { memo } from 'react'

import { CARDS_DATA } from '../consts'
import * as Styled from './styled'
import Card from './Card'

const StyleCards = ({ style, setStyle }) => (
  <Styled.CardsWrapper>
    {CARDS_DATA.map((cardData, index) => (
      <Card
        key={index}
        cardData={cardData}
        isActive={style === cardData.value}
        setStyle={setStyle}
      />
    ))}
  </Styled.CardsWrapper>
)

export default memo(StyleCards)
