import styled from 'styled-components'

export const Controls = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 6px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 20px;

    & > div:not(:last-child) {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
`
