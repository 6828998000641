/* @flow */
import React, { useContext, useRef, memo } from 'react'
import S from './Video5.styled'
import { validation } from './Video5.consts'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import ActionGetter from './Actions'
import AnimatedSvg1 from './AnimatedSvg1'
import AnimatedSvg2 from './AnimatedSvg2'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import useOnScreen from '@website/common/hooks/useOnScreen'
import Buttons from '@website/common/components/Buttons'

const Video5 = memo(
  ({
    data: {
      title,
      paragraph,
      videoUrl,
      buttons,
      titleAlignment,
      paragraphAlignment,
      isAnimated,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const animatedRef = useRef(null)
    const isOnScreen = useOnScreen(animatedRef)
    const isAnimationEnabled = isAnimated && isOnScreen && !isEditing
    const key = isAnimationEnabled ? 'animated' : 'not animated'

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
        isButton={buttons.length}
      >
        <S.WmCustomContainer>
          <S.TextContent>
            <EditableContent
              text={title}
              as={S.Title}
              required={!paragraph}
              maxCount={validation.maxTitleChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              alignment={titleAlignment}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Description}
              required={!title}
              maxCount={validation.maxParagraphChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              alignment={paragraphAlignment}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.TextContent>
          <S.VideoContainer ref={animatedRef}>
            <AnimatedSvg1 key={key} isAnimationEnabled={isAnimationEnabled} />
            <AnimatedSvg2
              key={key + 1}
              isAnimationEnabled={isAnimationEnabled}
            />
            <S.VideoControlWrapper>
              <ControlsWithModalOpener
                actions={Actions.videoActions(false, videoUrl)}
                style={{ width: '100%' }}
              >
                <S.VideoContent>
                  <S.VideoWrap>
                    <Overlay />
                    <LazyVideoPlayer
                      key={videoUrl}
                      style={{
                        top: 0,
                        left: 0,
                        position: 'absolute'
                      }}
                      controls
                      width="100%"
                      height="100%"
                      url={videoUrl}
                    />
                  </S.VideoWrap>
                </S.VideoContent>
              </ControlsWithModalOpener>
            </S.VideoControlWrapper>
          </S.VideoContainer>
          <Buttons data={buttons} />
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Video5
