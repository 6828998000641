/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature13.styled'
import { validation } from './Feature13.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import { isFeaturesEmpty } from './Feature13.helper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'

const Feature13 = memo(props => {
  const {
    data: { feature }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <Show when={[!isFeaturesEmpty(feature)]}>
      <S.Container isEditing={isEditing}>
        <div>
          <S.BlocksWrap>
            <Mapper
              data={feature}
              render={(featureBlock, idx) => {
                return (
                  <S.Block key={idx}>
                    <S.BlockLayer isEven={idx % 2 === 1} />
                    <If
                      condition={!!featureBlock.icon}
                      otherwise={() => (
                        <AddButton
                          onAdd={() => Actions.addIcon(idx)}
                          style={{
                            zIndex: '1',
                            position: 'relative'
                          }}
                          type="icon"
                          large
                          toShow
                        >
                          +
                        </AddButton>
                      )}
                      then={() => (
                        <ControlsWithModalOpener
                          actions={Actions.iconActions({ idx })}
                        >
                          <Icon
                            size="xxlarge"
                            color="white"
                            name={featureBlock.icon}
                          />
                        </ControlsWithModalOpener>
                      )}
                    />
                    <EditableContent
                      text={featureBlock.title}
                      alignment={featureBlock.blockTitleAlignment}
                      as={S.Title}
                      maxCount={validation.titleMaxChar}
                      onChange={(newTitle: string) =>
                        Actions.changeBlockTitle(newTitle, idx)
                      }
                      className="WM_GLOBAL_heading42"
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx)
                      }
                    />
                    <EditableContent
                      className="WM_GLOBAL_secondary-font"
                      text={featureBlock.paragraph}
                      alignment={featureBlock.blockParagraphAlignment}
                      as={S.Paragraph}
                      maxCount={validation.paragraphMaxChar}
                      onChange={(newParagraph: string) =>
                        Actions.changeBlockParagraph(newParagraph, idx)
                      }
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockParagraphAlignment(alignment, idx)
                      }
                    />
                    <AdditionalLink
                      idx={idx}
                      additionalLinkText={featureBlock.additionalText}
                      additionalLinkUrl={featureBlock.additionalUrl}
                    />
                  </S.Block>
                )
              }}
            />
          </S.BlocksWrap>
        </div>
      </S.Container>
    </Show>
  )
})

export default Feature13
