/* @flow */
import styled from 'styled-components'
import THEME from './Feature15.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 80px 0;
  background-color: ${getStyleForProp('background')};
`
const Title = styled.h4`
  color: ${getStyleForProp('heading')};
  text-align: center;
`

const SubTitle = styled.p`
  color: ${getStyleForProp('heading')};
  text-align: center;
  font-weight: 400;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  font-weight: 400;
`

const BlockItemWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  text-align: center;
  flex-grow: 1;
  max-width: 400px;
  width: 30%;
  box-sizing: border-box;
  position: relative;
  .LazyLoad {
    position: relative;
    padding-top: 100%;
    height: initial;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 340px;
    margin: 10px;
  }
  @media only screen and (max-width: 1020px) {
    width: 100%;
    max-width: 400px;
    margin: 50px auto 0 auto;
  }
`

const Badge = styled.div`
  padding: 0 24px;
  height: 60px;
  box-sizing: border-box;
  border-radius: ${getStyleForProp('borderRadius')};
  background: ${getStyleForProp('badgeBg')};
  position: absolute;
  top: 14px;
  left: -14px;
  z-index: 1;
  display: flex;
  align-items: center;
`

const BadgeText = styled.p`
  color: #fff;
  font-weight: bold;
  font-size: 20px;
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  line-height: 1.4;
`

const CtaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 20px 0 0 0;
  padding: 20px 30px;
  box-sizing: border-box;
  background: ${props => {
    const bgLeft = getStyleForProp('bgLeft')(props)
    const bgRight = getStyleForProp('bgRight')(props)
    return `linear-gradient(to right, ${bgLeft},${bgRight})`
  }};
  border-radius: ${getStyleForProp('borderRadius')};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
`

const CtaText = styled.p`
  color: ${({ theme }) => theme.palette.accentWhite};
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`

const Img = styled.img`
  border-top-left-radius: ${getStyleForProp('borderRadius')};
  border-top-right-radius: ${getStyleForProp('borderRadius')};
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const Count = styled.div`
  font-size: 72px;
  font-weight: bold;
  color: #fff;
  position: absolute;
  bottom: -18px;
  left: 10px;
`
const BtnWrap = styled.div`
  margin: 0 0 0 20px;
  &:only-child {
    margin: 0 auto;
  }
  @media only screen and (max-width: 768px) {
    margin: 20px 0 0 0;
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    background: #fff;
    color: ${getStyleForProp('buttonColor')};
    text-decoration: none;
    max-width: 260px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
    @media only screen and (max-width: 650px) {
      height: 40px;
      line-height: 40px;
    }
  }
`
export default {
  Container,
  Title,
  SubTitle,
  Description,
  BlockItemWrap,
  Badge,
  Count,
  BadgeText,
  BlockParagraph,
  CtaWrap,
  CtaText,
  BtnWrap,
  Img
}
