/* @flow */
import {
  iconActionMaker,
  buttonModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  addButton,
  changeParagraph
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'
export default dispatcher => {
  const changeListItem = (newItem: string, idx: number) => ({
    type: 'CHANGE_LIST_ITEM',
    value: { newItem, idx }
  })
  const removeListItem = (idx: number) => ({
    type: 'REMOVE_LIST_ITEM',
    value: idx
  })

  return {
    addButton,
    changeTitle,
    changeListItem,
    changeParagraph,
    iconActions: iconActionMaker(dispatcher),
    addNewListItem: () => ({ type: 'ADD_NEW_LIST_ITEM' }),
    getBtnActions: buttonModalActionMaker(dispatcher),
    listActions: (idx, toShow) => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeListItem(idx)),
        toShow
      }
    ]
  }
}
