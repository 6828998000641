import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const CenteredContentWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
`

export const PopupContentWrapper = styled.div`
  display: flex;
  max-width: 495px;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px 40px 30px;
  @media only screen and (max-width: 768px) {
    padding: 20px;
  }
`

export const Title = styled.h3`
  font-size: 20px;
  color: ${secondary.ExtraDark};
  line-height: 26px;
  font-weight: 600;
  text-align: center;
`

export const Description = styled.p`
  font-size: 14px;
  line-height: 18px;
  margin-top: 20px;
  color: ${secondary.ExtraDark};
  text-align: center;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  & > button:first-child {
    margin-right: 15px;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    & > button:first-child {
      margin-right: 0;
      margin-top: 20px;
    }
  }
`
