import styled from 'styled-components'

import { scrollBarStyles } from '@editor/common/styles'

export const TemplatesCategoriesWrapper = styled.div`
  position: relative;
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  z-index: 2;
  background-color: #ffffff;
  border-right: 1px solid #8493bd;
  box-shadow: 0 6px 10px #387dff33;
`

export const Header = styled.div`
  height: 60px;
  padding: 0 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  h2 {
    font-size: 20px;
    color: #252e48;
  }
`

export const Categories = styled.ul`
  ${scrollBarStyles};
  overflow-y: auto;
  flex-grow: 1;
  border-top: 1px solid #8493bd;
  @media only screen and (max-width: 1024px) {
    border-top: none;
  }
`
