/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header9.styled'
import THEME from './Header9.theme'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import ActionGetter from './Actions'
import { validation } from './Header9.consts'
import Image from '@website/common/components/Image'
import NavBar from '@website/common/components/NavBar'
import If from '@website/common/components/Conditional'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { DispatchContext, SiteIdContext, EditingContext } from '@contexts'
import Buttons from '@website/common/components/Buttons'

const Header9 = memo(props => {
  const {
    data: {
      title,
      imgUrl,
      logoSrc,
      buttons,
      logoColor,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoWidth,
      logoDimensions,
      iframeVideoUrl,
      imgUrlDimensions,
      backgroundImgDimensions,
      backgroundImgUrl,
      imgCropParams = {},
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const siteId = useContext(SiteIdContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight} isEditing={isEditing}>
        <S.HeaderContent>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Description}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
        </S.HeaderContent>
        <S.VideoContent className="video-content" isEditing={isEditing}>
          <div>
            <If
              condition={iframeVideoUrl}
              then={() => (
                <ControlsWithModalOpener
                  actions={Actions.mediaActions(iframeVideoUrl, siteId)}
                  style={{ width: '100%' }}
                >
                  <S.VideoWrap>
                    <Overlay />
                    <LazyVideoPlayer
                      className="iframe-video"
                      controls
                      width="100%"
                      height="100%"
                      url={iframeVideoUrl}
                    />
                  </S.VideoWrap>
                </ControlsWithModalOpener>
              )}
            />
            <If
              condition={imgUrl}
              then={() => (
                <ControlsWithImageCropper
                  actions={Actions.mediaActionsWithCrop(imgUrl)}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    defaultImgSrc={imgUrl}
                    sizes={imgUrlDimensions}
                    alt="header element"
                    asProps={{
                      imgCropParams: imgCropParams || {}
                    }}
                  />
                </ControlsWithImageCropper>
              )}
            />
          </div>
        </S.VideoContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})
Header9.contextType = DispatchContext

export default Header9
