export const validation = {
  headingMaxChar: 30,
  paragraphMaxChar: 500,
  title: { required: true },
  buttons: {
    min: 0,
    max: 2
  },
  headerSocials: {
    min: 0,
    max: 7
  }
}