import React from 'react'
import { withTheme } from 'styled-components'

const LeftTriangle = ({ theme }) => {
  const { palette } = theme
  const color = palette.primaryDark
  return (
    <svg
      className="left-triangle"
      width="1074px"
      height="601px"
      viewBox="0 0 1074 601"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        opacity="0.103171"
      >
        <polygon
          id="Rectangle-Copy-75"
          fill={color}
          points="0 0 1074 601 0 601"
        />
      </g>
    </svg>
  )
}

export default withTheme(LeftTriangle)
