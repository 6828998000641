/* @flow */
import {
  buttonModalActionMaker,
  subscribtionCardActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeCardData = (planIdx: number, data: string, keyInStore: string) =>
    dispatcher({
      type: 'CHANGE_CARD_DATA',
      value: { keyInStore, planIdx, data }
    })

  const changePlanTitle = (title: string, planIndex: number) =>
    changeCardData(planIndex, title, 'title')

  const changePlanPrice = (price: string, planIdx: number) =>
    changeCardData(planIdx, price, 'price')

  const changePerDate = (date: string, planIdx: number) =>
    changeCardData(planIdx, date, 'perDate')

  const changeOptionValue = (
    newOption: string,
    planIdx: number,
    optionIdx: number
  ) =>
    dispatcher({
      type: 'CHANGE_CARD_OPTION',
      value: { newOption, optionIdx, planIdx }
    })

  const addOption = (planIndex: number) =>
    dispatcher({
      type: 'ADD_OPTION',
      value: planIndex
    })

  const removeOption = (planIdx: number, optionIdx: number) =>
    dispatcher({
      type: 'REMOVE_OPTION',
      value: { planIdx, optionIdx }
    })

  const optionActions = (planIdx: number, optionIdx: number) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeOption(planIdx, optionIdx))
    }
  ]

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addOption,
    optionActions,
    changePerDate,
    changePlanTitle,
    changePlanPrice,
    changeOptionValue,
    addBlock: _addBlock,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    btnActions: buttonModalActionMaker(dispatcher),
    cardAction: subscribtionCardActionMaker(dispatcher)
  }
}
