// @flow

import React, { useContext, useCallback } from 'react'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import Warning from './Warning'
import {
  LinkWrapperContext,
  EditingContext,
  MobileDetectContext
} from '@contexts'
import {
  isInternalUrl,
  removeLastSlash,
  addTrailingSlash,
  getUrlWithSearch,
  getFormattedUrl
} from './utils'
import { getExternalUrl, scrollToTop } from '@website/common/utils'

import './index.sass'

const LinkWrapper = ({
  url: urlFromProps,
  text,
  linkProps = {},
  className = '',
  children
}) => {
  const { isEditing } = useContext(EditingContext)
  const isMobile = useContext(MobileDetectContext)
  const { routeBase } = useContext(LinkWrapperContext)

  const url = getFormattedUrl(urlFromProps, isEditing) || ''

  const routeUrl =
    typeof window !== 'undefined' ? useRouteMatch(routeBase).url : routeBase

  const history = useHistory()
  const { search } = history.location

  const _isInternalUrl = isInternalUrl(url)

  const routeUrlFormatted = removeLastSlash(routeUrl)
  const urlWithTrailingSlash = addTrailingSlash(url)
  const urlWithSearch = getUrlWithSearch(urlWithTrailingSlash, search)

  const internalUrl = `${routeUrlFormatted}${urlWithSearch}`

  const _linkProps = {
    ...linkProps,
    className: `${linkProps.className || ''} link`
  }

  const handleLinkClick = useCallback(() => {
    if (url.indexOf('#') === -1) {
      scrollToTop()
    }
  }, [url])

  const preventLinkRedirect = useCallback(
    e => {
      if (isMobile && isEditing) {
        e.preventDefault()
      }
    },
    [isMobile, isEditing]
  )

  return (
    <div className={`link-wrapper ${className}`}>
      {_isInternalUrl ? (
        <HashLink
          {..._linkProps}
          smooth
          to={internalUrl}
          onClick={handleLinkClick}
        >
          {text}
          {children}
        </HashLink>
      ) : (
        <a
          {..._linkProps}
          target={url === '#' || url.includes('tel:') ? '_self' : '_blank'}
          rel="noopener noreferrer"
          href={getExternalUrl(url)}
          onClick={preventLinkRedirect}
        >
          {text}
          {children}
        </a>
      )}
      <Warning url={url} />
    </div>
  )
}

export default LinkWrapper
