// @flow

import React, { useContext, memo } from 'react'
import { useSelector } from 'react-redux'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import Icon from '@website/common/components/Icon'
import { EditingContext } from '@contexts'
import { selectAllPagesData } from '@selectors'
import * as Styled from './styled'
import { secondary, accentRed } from '@website/common/styles/colors'
import {
  getPageFromLink,
  getComponentFromLink
} from '@editor/common/utils/project-links'
import { isInternalUrl } from '../utils'
import { getUniqIdFromUrlSection, isCompExistInPage } from './utils'
import { translate } from '@editor/common/utils/translations'

const Warning = ({ url }) => {
  const { isEditing } = useContext(EditingContext)
  if (!isInternalUrl(url) || !isEditing) {
    return null
  }

  const pages = useSelector(selectAllPagesData)

  const pagePath = getPageFromLink(url)
  const toComp = getComponentFromLink(url)
  const compId = toComp ? getUniqIdFromUrlSection(toComp) : ''

  const path = pagePath === '/home' || pagePath === '/home/' ? '/' : pagePath
  const page = pages.find(page => page.path === path)

  const isInvalidPage = !page
  const isInvalidComp = compId && !isCompExistInPage(page, compId)

  return isInvalidPage || isInvalidComp ? (
    <>
      <Styled.GlobalStyles />
      <TooltipWrapper
        className="broken-link-tooltip"
        text={
          <Styled.WarningText>
            {translate('you_have_broken_link_label')}
          </Styled.WarningText>
        }
      >
        <Icon name="glyph-warning" size="middle" color={accentRed} />
      </TooltipWrapper>
    </>
  ) : null
}

export default memo(Warning)
