/* @flow */

type TMapper = {
  data: Array<any>,
  render: (a: any, idx: number, arr: Array<any>) => void
}

export default function Mapper(props: TMapper) {
  const { data, render } = props
  if (Array.isArray(data)) {
    return data.map(render)
  }
  throw Error('Provided `data` prop is not of type `array`')
}
