//@flow
import React, { memo } from 'react'
import {
  CountriesSearchDropdown,
  KeywordTabs
} from '@editor/common/components/SemrushCommon'
import * as Styled from './styled.js'

const TabsSection = ({
  activeTab,
  database,
  onTabChange,
  onDatabaseChange
}) => {
  return (
    <Styled.TabsContainer>
      <KeywordTabs
        isHorizontalView
        activeTab={activeTab}
        onTabChange={onTabChange}
      />
      <Styled.CountriesDropdownWrapper>
        <CountriesSearchDropdown
          activeValue={database}
          setActiveValue={onDatabaseChange}
        />
      </Styled.CountriesDropdownWrapper>
    </Styled.TabsContainer>
  )
}

export default memo(TabsSection)
