/* @flow */
import styled from 'styled-components'

import THEME from './Slider5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import LibIcon from '@website/common/components/Icon'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  @media only screen and (min-width: 1024px) {
    padding: ${({ isOneSlide }) => (isOneSlide ? '80px 0' : '80px 0 125px 0')};
  }
  @media only screen and (max-width: 1023px) {
    padding-bottom: 65px;
  }
  @media only screen and (max-width: 650px) {
    padding-bottom: 45px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ isEditing }) => (isEditing ? 'margin-top: 60px;' : null)}
  .dots-wrap {
    bottom: 0;
    @media only screen and (max-width: 1023px) {
      bottom: 60px;
    }
  }
  .slick-slide > div {
    margin: 10px 6px 50px 6px;
    @media only screen and (max-width: 768px) {
      margin: 10px 0 50px 0;
    }
  }
  > :first-child {
    padding-top: 0;
  }
`

const ImageSlideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  max-width: 950px;
  position: relative;
  margin: -50px auto;
  padding-top: 50px;
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
  @media only screen and (max-width: 420px) {
    max-width: 320px;
  }
  .figure-img {
    width: 100%;
    height: 100%;
  }
  .rf-image-slider {
    @media only screen and (max-width: 550px) {
      width: 100%;
    }
    .rf-image-slider-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      @media only screen and (max-width: 650px) {
        padding: 1rem;
      }
    }
  }
`

const FigureContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin: 0 auto;
`

const ImgFigure = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  padding-top: 20px;
  position: relative;
  z-index: 2;
`

const HeaderTextsContainer = styled.div`
  text-align: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const IconContainer = styled.button`
  padding: 0;
  border: none;
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${getStyleForProp('iconRadius')};
  background: transparent;
  @media only screen and (max-width: 760px) {
    width: 30px;
    min-width: 30px;
  }
  @media only screen and (max-width: 550px) {
    position: absolute;
    top: 41%;
    z-index: 2;
    &.right {
      right: -45px;
    }
    &.left {
      left: -45px;
    }
  }
  @media only screen and (max-width: 457px) {
    &.right {
      right: -35px;
    }
    &.left {
      left: -35px;
    }
  }
  i {
    color: ${getStyleForProp('iconColor')};
  }
  &:focus {
    outline: none;
  }
`

const SliderTitle = styled.h2`
  color: ${({ theme }) => theme.palette.accentWhite};
  ${getTextStyleByAlignment};
  font-weight: 500;
`

const Dot = styled.button`
  padding: 0;
  border: none;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: 1px solid #5690ff;
  }
`

const TextContainer = styled.div`
  background: ${getStyleForProp('iconColor')};
  width: 100%;
  max-width: 580px;
  padding: 30px 40px;
  box-sizing: border-box;
  border-radius: ${getStyleForProp('cardRadius')};
  ${getTextStyleByAlignment};
  position: absolute;
  right: -40px;
  bottom: -60px;
  @media only screen and (max-width: 1023px) {
    position: initial;
    right: initial;
    bottom: initial;
    max-width: initial;
    margin: 10px 0 0 0;
  }
  @media only screen and (max-width: 500px) {
    padding: 20px;
  }
  & > .control-container {
    width: 100%;
    h2 {
      margin-bottom: 0;
    }
  }
`

const SlideContainer = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  margin: 40px;
  position: relative;
  font-size: 0;
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
  }
  @media only screen and (max-width: 1023px) {
    box-shadow: none;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 767px) {
    margin: 40px 0px 20px 0px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-shadow: ${getStyleForProp('raisedShadow')};
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('iconColor')
}))``

const SliderText = styled.h2`
  color: ${({ theme }) => theme.palette.accentWhite};
  ${getTextStyleByAlignment};
  font-weight: 500;
  margin-bottom: 5px;
`
export default {
  WmCustomContainer,
  ImageSlideContainer,
  FigureContainer,
  ImgFigure,
  Dot,
  HeaderTextsContainer,
  Title,
  Paragraph,
  StyledContainer,
  SliderTitle,
  SliderText,
  TextContainer,
  IconContainer,
  ImageContainer,
  SlideContainer,
  Icon
}
