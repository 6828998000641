/* @flow */
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'

import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  removeBlock
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }
  
  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }
  
  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }


  const imageActions = (
    idx: number,
    blocksCount: number,
    imageUrl: string,
    mediaFlyoutShift
  ) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'CTA13',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl,
      mediaFlyoutShift
    }),
    {
      toShow: blocksCount > 1,
      handler: () => dispatcher(removeBlock(idx)),
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label')
    }
  ]

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraph: _changeParagraph,
    changeParagraphAlignment: _changeParagraphAlignment,
    addBlock: () => dispatcher(addBlock()),
    removeBlock,
    imageActions,
    blockActions: removeBlockActionMaker(dispatcher),
    getImgActions: cropableImageModalActionMaker(dispatcher)({
      showRemove: false
    })
  }
}
