/* @flow */
import {
  simpleButtonActionMaker,
  mapModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changePlaceHolderText,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const removeForm = () =>
    dispatcher({
      type: 'REMOVE_FORM'
    })

  const addForm = () =>
    dispatcher({
      type: 'ADD_FORM'
    })

  const formActions = () => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: removeForm
    }
  ]

  const changeBlockParagraph = (newParagraph: string, key: string) =>
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newParagraph, key }
    })


  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changePlaceHolderText = (placeholder: string) =>
    dispatcher(changePlaceHolderText(placeholder))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addForm,
    formActions,
    changeBlockParagraph,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changePlaceHolderText: _changePlaceHolderText,
    getMapActions: mapModalActionMaker(dispatcher),
    btnActions: simpleButtonActionMaker(dispatcher),
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment
  }
}
