import styled, { css } from 'styled-components'
import {
  secondary,
  accentRed,
  warningColor
} from '@website/common/styles/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const stylesObj = {
  ru: {
    getBtnWidth: '220px',
    editBtnWidth: '150px'
  },
  es: {
    getBtnWidth: '230px',
    editBtnWidth: '150px'
  },
  fr: {
    getBtnWidth: '210px',
    editBtnWidth: '160px'
  },
  de: {
    getBtnWidth: '260px',
    editBtnWidth: '160px'
  },
  default: {
    getBtnWidth: '190px',
    editBtnWidth: '130px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 40px;
  justify-content: space-around;
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    padding: 0 10px;
  }
  @media only screen and (max-width: 380px) {
    margin-bottom: 10px;
  }
`

export const DateInput = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  height: 40px;
  padding: 20px;
  box-sizing: border-box;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${secondary.ExtraLight};
    `}
  border: ${({ error }) =>
    `1px solid ${error ? accentRed : secondary.ExtraLight}`};
  position: relative;
  color: ${secondary.SemiLight};
  cursor: pointer;
  @media only screen and (max-width: 550px) {
    height: 40px;
  }
`

export const TimeInput = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${secondary.SemiLight};
  padding: 20px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
      background-color: ${secondary.ExtraLight};
    `}
  border: ${({ error }) =>
    `1px solid ${error ? accentRed : secondary.ExtraLight}`};
  position: relative;
  ${({ isOpen }) =>
    isOpen &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
  .react-datepicker-wrapper {
    width: 80%;
    position: absolute;
    @media only screen and (max-width: 768px) {
      width: 93%;
    }
    .react-datepicker__input-container {
      input {
        color: ${({ selected }) =>
          selected ? secondary.Dark : secondary.SemiDark};
      }
    }
  }
  svg {
    position: absolute;
    right: 10px;
  }
`

export const InputText = styled.span`
  color: ${({ selected }) => (selected ? secondary.Dark : '#C1C9E0')};
`

export const LinkExpirationMessgae = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  text-align: center;
  .info-text {
    margin-left: 10px;
    font-size: 14px;
  }
  .link-info-text {
    color: #353e5a;
    margin-left: 10px;
    font-size: 16px;
    @media only screen and (max-width: 480px) {
      font-size: 14px;
    }
    .expire-date {
      font-weight: bold;
    }
  }
`

export const LinkEditButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 480px) {
    margin-bottom: 10px;
  }
`

export const Link = styled.span`
  color: '#353e5a';
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  width: 270px;
  margin: 20px auto;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
  display: block;
  @media only screen and (max-width: 480px) {
    font-size: 12px;
  }
`
export const ExpiredLinkMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${warningColor};
  justify-content: center;
  font-size: 14px;
  color: #756ce9;
  font-weight: bold;
  padding-bottom: 10px;
  @media only screen and (max-width: 480px) {
    padding-bottom: 0;
  }
  .error-icon {
    margin-right: 5px;
  }
`

export const Container = styled.div`
  .popup-text-container {
    .text-title {
      @media only screen and (min-width: 581px) and (max-width: 769px) {
        width: 100%;
      }
    }
  }
  .edit-link-button {
    margin: 0 10px;
    height: 40px;
    position: relative;
    width: ${getStylesByLang('editBtnWidth')};
  }
  .get-link-button {
    margin: 0 auto;
    position: relative;
    height: 40px;
    width: ${getStylesByLang('getBtnWidth')};
  }
`
