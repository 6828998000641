/* @flow */
import React, { useContext, memo } from 'react'
import S from './Team3.styled'
import { validation } from './Team3.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'

const Team3 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      quote,
      quoteAlignment,
      quoteAuthor,
      quoteAuthorAlignment,
      quoteAuthorPosition,
      quoteAuthorPositionAlignment,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.TeamDescription>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Description}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.TeamDescription>
        </Show>
        <Show when={[quote, quoteAuthor, quoteAuthorPosition]}>
          <S.QuoteWrap>
            <S.Icon size="xxlLarge" name="format_quote" />
            <EditableContent
              text={quote}
              as={S.Quote}
              alignment={quoteAlignment}
              maxCount={validation.quoteMaxChar}
              className="WM_GLOBAL_primary-font"
              onChange={Actions.changeQuote}
              changeAlignment={Actions.changeQuoteAlignment}
            />
            <S.AuthorInfo>
              <EditableContent
                text={quoteAuthor}
                as={S.Name}
                alignment={quoteAuthorAlignment}
                maxCount={validation.authorNameMaxChar}
                className="WM_GLOBAL_secondary-font"
                onChange={Actions.changeQuoteAuthor}
                changeAlignment={Actions.changeQuoteAuthorAlignment}
              />
              <EditableContent
                text={quoteAuthorPosition}
                as={S.Position}
                alignment={quoteAuthorPositionAlignment}
                isEditing={isEditing}
                maxCount={validation.authorPositionMaxChar}
                className="WM_GLOBAL_secondary-font"
                onChange={Actions.changeQuoteAuthorPosition}
                changeAlignment={Actions.changeQuoteAuthorPositionAlignment}
              />
            </S.AuthorInfo>
          </S.QuoteWrap>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Team3
