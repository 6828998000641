//@flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TDropdownItemProps } from './types'

const DropdownItem = ({ data }: TDropdownItemProps) => (
  <Styled.DropdownItem>
    <img src={`https://static.rfstat.com/${data.flag}`} alt={data.name} />
    <p>{data.name}</p>
  </Styled.DropdownItem>
)

export default memo(DropdownItem)
