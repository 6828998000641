//@flow
import React, { memo, useMemo } from 'react'

import * as Styled from './styled'
import type { TProps } from '../types'
import { SERP_FEATURES_DATA, VISIBLE_SERP_FEATURES_COUNT } from './consts'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import TooltipContent from '../../../../TooltipContent'
import { useResponsive } from '@shared/hooks'

export const SERPFeatures = ({ data, onSERPHiddenFeatuesClick }: TProps) => {
  const isSmallSize = useResponsive(1024)

  const serpFeatures = useMemo(() => {
    return SERP_FEATURES_DATA.filter(serpFeature =>
      data.split(',').includes(serpFeature.code)
    )
  }, [data])

  const areSomeSERPFeaturesHidden =
    serpFeatures.length > VISIBLE_SERP_FEATURES_COUNT

  const visibleSERPFeatures = useMemo(
    () =>
      areSomeSERPFeaturesHidden
        ? serpFeatures.slice(0, VISIBLE_SERP_FEATURES_COUNT - 1)
        : serpFeatures,
    [areSomeSERPFeaturesHidden, serpFeatures]
  )
  const hiddenSERPFeatures = useMemo(
    () => serpFeatures.slice(VISIBLE_SERP_FEATURES_COUNT - 1),
    [serpFeatures]
  )

  return serpFeatures.length ? (
    <Styled.SERPFeatures>
      {visibleSERPFeatures.map((serpFeature, idx) => {
        const { IconComp, tooltip, name } = serpFeature

        return (
          <TooltipWrapper
            text={
              isSmallSize ? (
                ''
              ) : (
                <TooltipContent title={name} description={tooltip} />
              )
            }
            key={idx}
          >
            <IconComp key={idx} />
          </TooltipWrapper>
        )
      })}
      {areSomeSERPFeaturesHidden ? (
        <Styled.HiddenSERPFeaturesCount
          className="hidden-serp-features"
          onClick={e => {
            onSERPHiddenFeatuesClick(e.target.parentNode, hiddenSERPFeatures)
          }}
        >
          <span>+{hiddenSERPFeatures.length}</span>
        </Styled.HiddenSERPFeaturesCount>
      ) : null}
    </Styled.SERPFeatures>
  ) : (
    <Styled.No>No</Styled.No>
  )
}

export default memo(SERPFeatures)
