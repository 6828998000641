/* @flow */
import React, { memo, useCallback } from 'react'
import S from './RSVPRadioButtonList.styled'
import RSVPRadioButton from '@website/common/components/RSVPRadioButton'
import type { TRSVPRadioButtonList } from './RSVPRadioButtonList.type'

const RSVPRadioButtonList = memo(
  ({
    title,
    items,
    checkedItem,
    onChange,
    isRequired = true,
    className,
    labelClassName
  }: TRSVPRadioButtonList) => {
    const handleChange = useCallback(
      value => {
        onChange && onChange(value)
      },
      [onChange]
    )
    return (
      <S.SectionWrap className="radio-button-list">
        <S.RadioButtonTitleWrap className="radio-buttons-title">
          <S.RadioButtonsTitle className={className}>
            {title}
            <S.Asterisk>
              {isRequired && title ? <span>*</span> : null}
            </S.Asterisk>
          </S.RadioButtonsTitle>
        </S.RadioButtonTitleWrap>
        <S.OptionsWrap hasOnlyFourItems={items.length === 4}>
          {items.map(item => {
            const isChecked = checkedItem === item.value
            return (
              <RSVPRadioButton
                key={item.value}
                value={item.value}
                label={item.label}
                isChecked={isChecked}
                labelClassName={labelClassName}
                onChange={handleChange}
              />
            )
          })}
        </S.OptionsWrap>
      </S.SectionWrap>
    )
  }
)

export default RSVPRadioButtonList
