//@flow
import React, { memo, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'

import * as Styled from './styled'
import type { TContentProps } from '../types'
import LanguagesListItem from './LanguagesListItem'
import SettingsIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'
import { translate } from '@editor/common/utils/translations'
import { useHistoryWithSearch } from '@hooks'
import { PAGE_BASE } from '@editor/conf/routes'

const Content = ({ languagesData, closeLanguagesDropdown }: TContentProps) => {
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(PAGE_BASE)

  const navigateToSettings = useCallback(() => {
    closeLanguagesDropdown()
    history.push(`${url}/settings/languages`)
  }, [closeLanguagesDropdown])

  return (
    <Styled.Content>
      <Styled.Header>
        <Styled.Title>{translate('your_website_languages')}</Styled.Title>
      </Styled.Header>
      <Styled.LanguagesList>
        {languagesData.map((language, idx) => (
          <LanguagesListItem
            key={idx}
            language={language}
            closeLanguagesDropdown={closeLanguagesDropdown}
          />
        ))}
      </Styled.LanguagesList>
      <Styled.Footer>
        <Styled.SettingsButton type="tertiary" onClick={navigateToSettings}>
          <SettingsIcon />
          {translate('settings_label')}
        </Styled.SettingsButton>
      </Styled.Footer>
    </Styled.Content>
  )
}

export default memo(Content)
