/* @flow */
import React, { useContext, memo } from 'react'
import S from './Spotify5.styled'
import { validation } from './Spotify5.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { convertSpotifyUrlToEmbedUrl } from '@website/common/utils'

const Spotify5 = memo(props => {
  const dispatcher = useContext(DispatchContext)
  const {
    data: {
      title,
      paragraph,
      name,
      musicUrl,
      titleAlignment,
      paragraphAlignment,
      nameAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[name, title, paragraph]}>
          <S.TextContent>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleChar}
              className="WM_GLOBAL_heading20"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.maxParagraphChar}
              className="WM_GLOBAL_heading54"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <EditableContent
              text={name}
              alignment={nameAlignment}
              as={S.Name}
              maxCount={validation.maxNameChar}
              className="WM_GLOBAL_heading32"
              onChange={Actions.changeName}
              changeAlignment={Actions.changeNameAlignment}
            />
          </S.TextContent>
        </Show>
        <S.MusicWrapper>
          <ControlsWithModalOpener
            actions={Actions.musicActions({
              isSingleTrack: true,
              url: musicUrl
            })}
            style={{ width: '100%' }}
          >
            <S.MusicContent>
              <Overlay />
              <S.IframeWrapper isEditing={isEditing}>
                <iframe
                  scrolling="no"
                  frameBorder="no"
                  src={convertSpotifyUrlToEmbedUrl(musicUrl)}
                  title="Embedded music"
                />
              </S.IframeWrapper>
            </S.MusicContent>
          </ControlsWithModalOpener>
        </S.MusicWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Spotify5
