/* @flow */
import styled from 'styled-components'
import THEME from './Header6.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  ${getBackgroundStyles(THEME)};
  word-break: break-word;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .mockups-wrap {
    .image-state-overlay,
    .failed-image-container {
      z-index: 1;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  text-align: left;
  @media only screen and (max-width: 1300px) {
    text-align: center;
  }
`

const Description = styled.h2`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  @media only screen and (max-width: 1300px) {
    text-align: center;
  }
`

const BadgeContainer = styled.div`
  margin: ${({ isEditing }) => (isEditing ? '2rem 0 0 -10px' : '2rem 0')};
`

/*-------------*/
const PhoneWrap = styled.div`
  position: relative;
  z-index: 1;
`

const Phone = styled.div`
  width: 220px;
  margin: 0 auto;
  position: relative;
  border: 10px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 38px;
  font-size: 0;
  min-height: 400px;
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 200%;
  }
  .lazy-figure {
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 24px;
  }
`

const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const LockScreen = styled.div`
  background-color: #f1f2f4;
  width: 2px;
  height: 60px;
  border-radius: 0 4px 4px 0;
  position: absolute;
  top: 72px;
  right: -12px;
`

const SilentMode = styled.div`
  background-color: #f1f2f4;
  width: 2px;
  height: 14px;
  border-radius: 4px 0 0 4px;
  position: absolute;
  top: 8%;
  left: -12px;
`

const VoiceWrap = styled.div`
  position: absolute;
  top: 15%;
  left: -12px;
`

const VoiceBtn = styled.div`
  background-color: #f1f2f4;
  width: 2px;
  height: 41px;
  border-radius: 4px 0 0 4px;
  margin: 6px 0 0 0;
`

const Dinamic = styled.img`
  width: 60%;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`
/*-------------*/
const AndroidPhoneWrap = styled.div`
  position: relative;
  z-index: 1;
`

const AndroidPhone = styled.div`
  width: 220px;
  margin: 0 auto;
  position: relative;
  border-top-width: 22px;
  border-bottom-width: 22px;
  border-left-width: 10px;
  border-right-width: 10px;
  border-style: solid;
  border-color: #1b1b1b;
  box-sizing: border-box;
  border-radius: 30px;
  font-size: 0;
  min-height: 400px;
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 200%;
  }
  .lazy-figure {
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 8px;
  }
`

const AndroidMobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const AndroidLockScreen = styled.div`
  background-color: #1b1b1b;
  width: 2px;
  height: 34px;
  border-radius: 0 4px 4px 0;
  position: absolute;
  top: 30%;
  right: -12px;
`

const AndroidSilentMode = styled.div`
  background-color: #1b1b1b;
  width: 2px;
  height: 30px;
  border-radius: 4px 0 0 4px;
  position: absolute;
  top: 34%;
  left: -12px;
`

const AndroidVoiceBtn = styled.div`
  position: absolute;
  top: 15%;
  left: -12px;
  background-color: #1b1b1b;
  width: 2px;
  height: 60px;
  border-radius: 4px 0 0 4px;
`

const AndroidDinamic = styled.img`
  width: 70%;
  position: absolute;
  top: -14px;
  left: 50%;
  transform: translateX(-50%);
`

export default {
  Container,
  WmCustomContainer,
  Section,
  Title,
  Description,
  BadgeContainer,
  PhoneWrap,
  Phone,
  MobileImg,
  LockScreen,
  Dinamic,
  VoiceWrap,
  VoiceBtn,
  SilentMode,
  AndroidPhoneWrap,
  AndroidPhone,
  AndroidMobileImg,
  AndroidLockScreen,
  AndroidDinamic,
  AndroidVoiceBtn,
  AndroidSilentMode
}
