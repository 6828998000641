//@flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TProps } from '../types'
import { formatLargeNumber } from './utils'
import SearchIcon from '@editor/common/assets/svgr-icons/Search.svg'

export const Results = ({ data }: TProps) => (
  <Styled.Result>
    <SearchIcon />
    {formatLargeNumber(parseInt(data))}
  </Styled.Result>
)

export default memo(Results)
