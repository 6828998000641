/* @flow */
import styled from 'styled-components'
import THEME from './Feature14_2.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '80px 0 0 0' : '0')};
  background-color: ${getStyleForProp('background')};
  font-size: 0;
  padding-top: ${({ isEditing }) => (isEditing ? '140px' : null)};
  @media only screen and (max-width: 992px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 0 0' : '0')};
  }
`

const TextContent = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 60px 40px;
  box-sizing: border-box;
  > p:first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Line = styled.div`
  width: 60px;
  height: 4px;
  background: ${getStyleForProp('lineBg')};
  margin: 30px auto 0 auto;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  font-weight: 400;
  margin-top: 20px;
`

const TopBlockWrapper = styled.div`
  display: flex;
`

const BottomBlockWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }
`

const BlockItemWrap = styled.div`
  width: 45%;
  height: 100%;
  flex-grow: 1;
  .lazy-figure {
    padding-top: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

export default {
  Container,
  TextContent,
  Title,
  Description,
  BlockItemWrap,
  Img,
  TopBlockWrapper,
  BottomBlockWrapper,
  Line,
  ControlContainer
}
