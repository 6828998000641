/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Text7.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  position: relative;
  overflow: hidden;
  .left-triangle {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    @media only screen and (max-width: 600px) {
      bottom: -250px;
    }
  }
  .right-triangle {
    position: absolute;
    right: 0;
    bottom: 0;
    @media only screen and (max-width: 600px) {
      bottom: -100px;
    }
  }
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  ${({ isEditing }) => (isEditing ? 'padding-top: 60px;' : null)}
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 20px;
`

const Paragraph = styled.p`
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 40px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  & > div {
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`

const TextItemWrap = styled.article`
  width: 100%;
  max-width: calc(50% - 25px);
  padding: 20px 0px 20px 0;
  box-sizing: border-box;
  word-break: break-word;
  &:last-child {
    padding-bottom: 0;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    padding: 30px 0 0 0;
  }
  @media only screen and (min-width: 769px) {
    &:nth-last-child(2) {
      padding-bottom: 0;
    }
  }
  & .control-container {
    margin-bottom: 0px !important;
    width: 100%;
    & > .control-container {
      h2 {
        margin-bottom: 0;
      }
      p {
        margin-top: 0;
      }
    }
    & > .control-container:nth-child(1) {
      margin-bottom: 22px !important;
    }
  }
`

const TextItem = styled.div`
  border-left: 4px solid ${getStyleForProp('anchor')};
  padding: 0 0 0 26px;
  text-align: left;
  .control-container {
    margin-bottom: 30px;
    .control-container:nth-last-child(1) {
      margin-bottom: 0px;
    }
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }

  & > .control-container {
    padding-top: 20px;
  }
`

const BlockTitle = styled.h2`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('heading')};
`

const BlockParagraph = styled.p`
  ${getTextStyleByAlignment};
  width: 100%;
  margin-top: 20px;
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Paragraph,
  Content,
  ContentWrapper,
  TextItemWrap,
  BlockParagraph,
  BlockTitle,
  TextItem
}
