/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_TEXT_GRADIENT': {
        draft.titleTextGradient = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE':
        draft.content[action.value.idx].title = action.value.newTitle
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        draft.content[action.value.idx].paragraph = action.value.newParagraph
        break
      case 'ADD_BLOCK':
        draft.content.push(initialState.content[0])
        break
      case 'REMOVE_BLOCK': {
        draft.content.splice(action.value, 1)
        break
      }
      case 'ADD_IMAGE': {
        const { imgName } = action.value
        draft.images[imgName] = initialState.images[imgName]
        break
      }
      case 'REMOVE_IMAGE': {
        const { imgName } = action.value
        draft.images[imgName].imageUrl = ''
        draft.images[imgName].imgDimensions = {}
        draft.images[imgName].imgCropParams = ''
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, imgName, dimensions } = action.value
        if (!url) {
          return
        }
        draft.images[imgName].imageUrl = url
        draft.images[imgName].imgDimensions = dimensions || {}
        draft.images[imgName].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, imgName } = action.value
        draft.images[imgName].imgCropParams = imgCropParams
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.content[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.content[idx].paragraphAlignment = alignment
        break
      }
    }
  })
}
