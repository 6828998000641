// @flow

import React, { memo, useRef, useEffect, useState } from 'react'
import * as Styled from './ImgStateOverlay.styled'
import type { TImgStateOverlayProps } from './types'
import DottedCircleLoader from '@editor/common/assets/svgr-icons/Dotted_circular_loader.svg'
import AttentionImage from '@editor/common/assets/svgr-icons/Attention_icon_red.svg'
import { translate } from '@editor/common/utils/translations'

const ImgStateOverlay = ({
  isLoading,
  failed,
  loaded
}: TImgStateOverlayProps) => {
  const ref = useRef(null)
  const [refSizes, setRefSizes] = useState({ width: 0, height: 0 })

  useEffect(() => {
    // the delay is for image painting
    setTimeout(() => {
      const { width, height } = ref.current?.getBoundingClientRect() || {}
      setRefSizes({ width, height })
    }, 500)
  }, [ref.current, loaded])

  const hideOverlay = !isLoading && !failed

  // TODO: Remove after all images will have initial size
  const { width: currentWidth, height: currentHeight } =
    ref.current?.getBoundingClientRect() || {}
  const { width, height } = refSizes
  const minOfSizes =
    !width || !height
      ? Math.min(currentWidth, currentHeight)
      : Math.min(width, height)

  return (
    <Styled.StateOverlay
      className="image-state-overlay"
      ref={ref}
      minOfSizes={minOfSizes}
      failed={failed}
      hide={hideOverlay}
    >
      <Styled.Content>
        {isLoading ? (
          <>
            <DottedCircleLoader className="circle-loader" />
            <Styled.LoadingMessage>
              {translate('uploading_label')}...
            </Styled.LoadingMessage>
          </>
        ) : failed ? (
          <>
            <AttentionImage className="attention-icon" />
            <Styled.ErrorMainMessage>
              {translate('upload_failed_label')}
            </Styled.ErrorMainMessage>
            <Styled.ErrorSecondaryMessage>
              Something went wrong, please try again
            </Styled.ErrorSecondaryMessage>
          </>
        ) : null}
      </Styled.Content>
    </Styled.StateOverlay>
  )
}

export default memo(ImgStateOverlay)
