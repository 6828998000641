/* @flow */
import {
  changeParagraph,
  changeTitle,
  changeTitleAlignment,
  changeParagraphAlignment,
  changePlaceHolderText
} from '@website/components/CommonActions'
import { simpleButtonActionMaker } from '@website/components/ActionMakers'

export default dispatcher => {
  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changePlaceHolderText = (placeholder: string) =>
    dispatcher(changePlaceHolderText(placeholder))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changePlaceHolderText: _changePlaceHolderText,
    buttonActions: simpleButtonActionMaker(dispatcher),
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment
  }
}
