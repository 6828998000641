import styled, { keyframes, css } from 'styled-components'

import { globalColors } from '@renderforest/rf-ui-library/consts/colors'
import { mediaMaxWidth } from '@editor/common/utils/styled'
import { BREAKPOINT_800 } from '@containers/edit/consts'

const scale = keyframes`
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`

export const CornerRadiusWrapper = styled.div`
  display: flex;
  padding: 5px 0 15px;
  ${mediaMaxWidth(BREAKPOINT_800)`
    padding-top: 5px;
  `};
`

export const CornerRadiusItem = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  background-color: ${({ isActive }) =>
    isActive ? globalColors.primaryDark : globalColors.secondaryExtraLight};
  box-shadow: ${({ isActive }) => (isActive ? '0 3px 3px #387dff4d' : 'none')};
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    width: ${({ isCircle }) => (isCircle ? '20px' : '18px')};
    height: ${({ isCircle }) => (isCircle ? '20px' : '18px')};
    border: ${({ isActive }) =>
      isActive
        ? `2px solid ${globalColors.white}`
        : `2px solid ${globalColors.secondarySemiLight}`};
    border-radius: ${({ isCircle, isRoundedCorner }) =>
      isCircle ? '10px' : isRoundedCorner ? '4px' : '0'};
    box-sizing: border-box;
    margin: ${({ isCircle }) => (isCircle ? '8px' : '9px')};
  }
  &:hover {
    background-color: ${globalColors.primaryDark};
    box-shadow: 0 3px 3px #387dff4d;
    transform: scale(1.05);
    transition: transform 0.4s ease;
    &:after {
      border: 2px solid ${globalColors.white};
    }
  }
  ${mediaMaxWidth(BREAKPOINT_800)`
    width: 50px;
    height: 50px;
    &:after {
      width: 24px;
      height: 24px;
      border-radius: ${({ isCircle, isRoundedCorner }) =>
        isCircle ? '12px' : isRoundedCorner ? '5px' : '0'};
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  `};
`

export const ImageContainer = styled.div`
  width: 140px;
  height: 155px;
  position: relative;
  background-image: ${({ background }) => `url(${background})`};
  background-repeat: no-repeat;
  background-position-y: 15px;
  border-top: 1px solid ${globalColors.secondaryExtraLight};
`

export const ImageWrapper = styled.div`
  width: 60px;
  height: 60px;
  position: relative;
  margin: 30px auto 0;
  box-sizing: border-box;
  border: 2px solid ${globalColors.secondarySemiLight};
  border-radius: ${({ isRounded, isSemiRounded }) =>
    isRounded || isSemiRounded ? '5px' : 0};

  svg {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 1px solid ${globalColors.white};
    border-radius: ${({ isRounded, isSemiRounded }) =>
      isRounded || isSemiRounded ? '4px' : 0};
  }
  animation: ${({ showAnimation }) =>
    showAnimation
      ? css`
          ${scale} 1s linear forwards;
        `
      : ''};
`

export const Buttons = styled.div`
  display: flex;
  position: absolute;
  left: 27px;
  bottom: 15px;
  div {
    width: 40px;
    height: 12px;
    background-color: ${globalColors.secondarySemiLight};
    border-radius: ${({ isRounded, isSemiRounded }) =>
      isRounded ? '10px' : isSemiRounded ? '3px' : 0};
    animation: ${({ showAnimation }) =>
      showAnimation
        ? css`
            ${scale} 1s linear forwards;
          `
        : ''};
  }
  div:nth-child(2) {
    opacity: 0.5;
    margin-left: 6px;
  }
`
