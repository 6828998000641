/* @flow */

export const validation = {
  titleMaxChar: 50,
  paragraphMaxChar: 200,
  slides: {
    min: 2,
    max: 3
  }
}
export const slickSliderSettings = {
  dots: false,
  arrows: false,
  autoplay: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1
}

export const BUTTON_INITIAL_STATE = [
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]
