/* @flow */
import styled, { css } from 'styled-components'

import THEME from './CTA10.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import LibIcon from '@website/common/components/Icon'
import { getBackground } from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  position: relative;
  padding-top: ${props => props.isEditing && '150px'};
  @media only screen and (max-width: 600px) {
    padding-top: ${props => props.isEditing && '100px'};
  }
  .LazyLoad {
    width: 100%;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  & > div:first-child {
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT' ? '0 0 0 2vw' : '0 2vw 0 0'};
      `}
    @media only screen and (max-width: 1023px) {
      margin: 0;
    }
  }
  @media only screen and (max-width: 1023px) {
    align-items: center;
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column'
          : 'column-reverse'};
      `}
    & > div:first-child {
      ${({ theme }) =>
        css`
          margin: ${theme.orientation === 'RIGHT'
            ? '0 auto 40px auto'
            : '40px auto 0 auto'};
        `}
    }
  }
`

const HeaderContent = styled.div`
  text-align: left;
  width: 100%;
  max-width: 460px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1023px) {
    text-align: center;
    max-width: initial;
    .buttons-block {
      justify-content: center;
    }
  }
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('title')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const InfoP = styled.p`
  color: ${getStyleForProp('info')};
  font-size: 20px;
  font-weight: 500;
  margin-top: ${({ isEditing }) => (isEditing ? '11px' : '0')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const RatioWrapper = styled.div`
  position: relative;
  margin: 10px;
  width: 45%;
  max-width: 320px;
  flex-grow: 1;
  &:hover .lazy-figure {
    transform: scale(1.2);
  }
  &:first-child,
  &:nth-child(2) {
    margin-top: 0;
  }
  &:nth-child(3),
  &:nth-child(4) {
    margin-bottom: 0;
  }
  .lazy-figure {
    transition: all 0.5s ease-in-out;
  }
  &:hover {
    .item-info {
      opacity: 1;
    }
    .item-title,
    .item-paragraph,
    .item-badge {
      transform: initial;
      opacity: 1;
    }
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
    max-width: initial;
    margin: 10px 0;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  .LazyLoad {
    overflow: hidden;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    border-radius: ${getStyleForProp('cardRadius')};
  }
  .lazy-figure {
    position: relative;
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
    transition: all 0.5s linear;
  }
  img {
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ImageControlContainer = styled.div`
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
`

const Info = styled.div`
  opacity: ${props => (props.isEditing ? '1' : '0')};
  display: ${({ withoutTexts }) => (withoutTexts ? 'none' : 'flex')};
  flex-direction: column;
  @media only screen and (max-width: 1200px) {
    opacity: 1;
  }
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 80px 20px 20px 20px;
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) =>
    `linear-gradient(to top, ${theme.palette.primaryDark}, transparent)`};
  border-bottom-left-radius: ${getStyleForProp('borderRadius')};
  border-bottom-right-radius: ${getStyleForProp('borderRadius')};
  > .control-container:nth-child(2) {
    margin-top: 15px;
    p {
      margin-top: 0;
    }
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: ${getStyleForProp('borderRadius')};
`

const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) =>
    css`
      justify-content: ${theme.orientation === 'RIGHT'
        ? 'flex-end'
        : 'flex-start'};
    `}
  width: 100%;
  max-width: 700px;
  font-size: 0;
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`

const BlocksWrapper = styled.div`
  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 30px;
  @media only screen and (max-width: 1023px) {
    margin-top: -10px;
    margin-bottom: -10px;
  }
  .add-button-wrap {
    text-align: left;
    @media only screen and (max-width: 1023px) {
      text-align: center;
    }
  }
`

const ControlContainer = styled.div`
  margin: 20px 0;
  @media only screen and (max-width: 1023px) {
    margin: 30px 0 0 0;
  }
`

const BlockItem = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
`

const BlockTitle = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (max-width: 1023px) {
    margin: 10px 0;
  }
`

const IconWrap = styled.div`
  margin: 0 16px 0 0;
  user-select: none;
  @media only screen and (max-width: 1023px) {
    margin: 0;
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('icon')
}))`
  @media only screen and (max-width: 1023px) {
    font-size: 2rem;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  Paragraph,
  Img,
  GridWrapper,
  RatioWrapper,
  ImageWrapper,
  BlocksWrapper,
  BlockItem,
  BlockTitle,
  IconWrap,
  Icon,
  ControlContainer,
  ImageControlContainer,
  Info,
  InfoP
}
