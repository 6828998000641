// @flow
import React, { useEffect, useState, memo } from 'react'

import * as Styled from './styled'
import type { TBackToTopButtonProps } from './types'
import Icon from '@website/common/components/Icon'

export const hasPrimaryChat = chats =>
  chats && ~chats.data.findIndex(chat => chat.isPrimary)

export const hasAddedMessengersOnRight = messengers =>
  messengers &&
  ~messengers.data.findIndex(messenger => messenger.isActive) &&
  messengers.settings.alignment === 'right'

const BackToTopButton = ({ widgets }: TBackToTopButtonProps) => {
  const [showButton, setButtonState] = useState(false)
  const { chats, messengers } = widgets

  const isBtnCentered =
    hasPrimaryChat(chats) || hasAddedMessengersOnRight(messengers)

  const changeVisibility = () => {
    if (window.scrollY > 400) {
      setButtonState(true)
      return
    }

    setButtonState(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', changeVisibility)

    return () => {
      window.removeEventListener('scroll', changeVisibility)
    }
  }, [])

  const onClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <Styled.ButtonWrapper isCentered={isBtnCentered}>
      <Styled.Button show={showButton} onClick={onClick}>
        <Icon size="xlarge" name="arrow_small_top" />
      </Styled.Button>
    </Styled.ButtonWrapper>
  )
}

export default memo(BackToTopButton)
