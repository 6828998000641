// @flow

export const getUniqIdFromUrlSection = (toComp: string): string =>
  toComp.replace('comp-', '')

export const isCompExistInPage = (page, compId) => {
  if (!page || !page.components) {
    return false
  }

  const hasCompWithUniqId =
    page.components.findIndex(comp => comp.uniqId === compId) !== -1
  const hasCompWithIdx = !!page.components[compId]
  return hasCompWithUniqId || hasCompWithIdx
}
