import styled, { css } from 'styled-components'

export const TabsWrapper = styled.div`
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  z-index: 0;
  width: fit-content;
  & > div {
    display: flex;
  }
  ${({ isHorizontalView }) =>
    isHorizontalView
      ? css`
          height: 39px;
          margin-top: 20px;
          @media only screen and (max-width: 1024px) {
            margin-top: 30px;
          }
          border: 1px solid #c1c9e0;
          border-radius: 5px;
        `
      : css`
          flex-direction: column;
          padding: 0 15px 10px;
        `};
`

export const Tab = styled.div`
  font-size: 14px;
  line-height: 17px;
  ${({ isActive }) =>
    isActive
      ? css`
          background: #eef5ff;
          border: 1px solid #3271e6;
          border-radius: 4px;
          font-weight: 600;
          color: #3271e6;
          z-index: 1;
        `
      : css`
          font-weight: 500;
          color: #545f7e;
          position: relative;
        `}

  :hover {
    cursor: pointer;
  }
`

export const HorizontalTab = styled(Tab)`
  padding: 12px 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-child {
    &:after {
      content: '';
      position: absolute;
      top: 12px;
      right: -1px;
      width: 1px;
      height: 18px;
      background-color: #c1c9e0;
    }
  }

  &:nth-child(3) {
    &:before {
      content: '';
      position: absolute;
      left: -1px;
      top: 12px;
      width: 1px;
      height: 18px;
      background-color: #c1c9e0;
    }
  }
`

export const VerticalTab = styled(Tab)`
  padding: 10px;
  text-align: center;
  margin-bottom: 5px;
  border: 1px solid #c1c9e0;
  border-radius: 5px;
`

export const TooltipContent = styled.span`
  width: 100%;
  display: inline-block;
  text-align: center;
  @media only screen and (max-width: 768px) {
    width: 150px;
  }
`
