/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_BLOCK_TITLE': {
        const { blockIdx, newTitle } = action.value
        draft.blocks[blockIdx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { blockIdx, newParagraph, key } = action.value
        draft.blocks[blockIdx][key] = newParagraph
        break
      }
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.blocks[idx].blockTitleALignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignmentKey, alignment } = action.value
        draft.blocks[idx][alignmentKey] = alignment
        break
      }
    }
  })
}
