/* @flow */
import styled from 'styled-components'

import THEME from './Footer.theme'
import { styleProvider } from '../../utils'

const getStyleForProp = styleProvider(THEME)

const Anchor = styled.a`
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  margin: 10px;
  color: ${getStyleForProp('paragraph')};
`

const Wrap = styled.div`
  display: inline-block;
`

const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  .add-button-wrap {
    margin-left: 30px;
  }
`

export default { Anchor, Wrap, LinkContainer }
