export const validation = {
  title: { required: true },
  titleMaxChar: 24,
  paragraphMaxChar: 100,
  dateTitleMaxChar: 150,
  dateLabelsMaxChar: 20,
  labelTextMaxChar: 80,
  headerSocials: {
    min: 0,
    max: 7
  }
}
