export default {
  DARK: {
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.primaryLight,
    background: p => p.tertiaryDark,
    price: p => p.accentWhite,
    info: p => p.accentWhite,
    countColor: p => p.tertiaryNormal,
    slideCounter: p => p.accentWhite
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.primaryDark,
    background: p => p.tertiaryLight,
    price: p => p.tertiaryDark,
    countColor: p => p.tertiarySemiLight,
    slideCounter: p => p.tertiaryDark
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.primaryDark,
    background: p => p.tertiaryExtraLight,
    price: p => p.tertiaryDark,
    countColor: p => p.tertiarySemiLight,
    slideCounter: p => p.tertiaryDark
  }
}
