import styled from 'styled-components'

export const Footer = styled.div`
  width: 100%;
  height: ${({ isVisible }) => (isVisible ? '60px' : '0')};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  z-index: 3;
  background-color: #ffffff;
  padding: 0 30px;
  box-sizing: border-box;
  border-top: ${({ isVisible }) => (isVisible ? '1px solid #8493bd' : 'none')};
  box-shadow: 0px -6px 10px #387dff1a;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  overflow: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: all 300ms ease-out;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`

export const SelectedInfo = styled.p`
  color: #545f7e;
  font-size: 14px;
  margin-right: 20px;
  padding: 11px 20px;
  border-right: 1px solid #dee3f0;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const Button = styled.button`
  width: fit-content;
  white-space: nowrap;
  height: 40px;
  padding: 8px 20px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-out;
`

export const AddButton = styled(Button)`
  color: #ffffff;
  background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
    0% no-repeat;
  padding: 8px 20px 8px 15px;
  svg {
    fill: #ffffff;
    margin-right: 10px;
    flex-shrink: 0;
  }
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #518dff 100%) 0%
      0% no-repeat;
  }
  @media only screen and (max-width: 768px) {
    width: auto;
    min-width: 121px;
    svg {
      margin-right: 5px;
    }
  }
`

export const DeselectButton = styled(Button)`
  color: #3271e6;
  margin-right: 10px;
  background-color: #ffffff;
  &:hover {
    background-color: #eef5ff;
  }
  @media only screen and (max-width: 768px) {
    margin-right: 30px;
  }
`
