/* @flow */

module.exports = {
  id: 'Footer7',
  isUnique: true,
  thumbnailRatio: 0.274524158,
  label: 'Footer7',
  category: 'footer',
  colorMode: 'DARK',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/footer7-2.0.0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/footer7m-2.0.0.jpg',
  metaData: {
    copyrightText: {
      _type: 'text',
      _min: 0,
      _max: 50,
      _value: ''
    },
    buttonText: {
      _type: 'buttonLabel',
      _min: 1,
      _value: 'SUBSCRIBE'
    },
    placeholder: {
      _type: 'text',
      _value: 'Enter your email'
    },
    formText: {
      _type: 'text',
      _min: 0,
      _max: 30,
      _value: 'Get our latest news.'
    },
    formTextAlignment: 'center',
    socials: {
      _type: 'list',
      _min: 0,
      _max: 7,
      _value: [
        {
          icon: 'icon-facebook',
          socialUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'https://www.facebook.com'
          }
        },
        {
          icon: 'icon-twitter',
          socialUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'https://www.twitter.com'
          }
        },
        {
          icon: 'icon-linkedin',
          socialUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'https://www.linkedin.com'
          }
        },
        {
          icon: 'icon-instagram',
          socialUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'https://www.instagram.com'
          }
        },
        {
          icon: 'icon-youtube',
          socialUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'https://www.youtube.com'
          }
        },
        {
          icon: 'icon-pinterest',
          socialUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'https://www.pinterest.com'
          }
        }
      ]
    }
  },
  defaultProps: {
    copyrightText: '',
    buttonText: 'SUBSCRIBE',
    placeholder: 'Enter your email',
    formText: 'Get our latest news.',
    formTextAlignment: 'center',
    socials: [
      {
        icon: 'icon-facebook',
        socialUrl: 'https://www.facebook.com'
      },
      {
        icon: 'icon-twitter',
        socialUrl: 'https://www.twitter.com'
      },
      {
        icon: 'icon-linkedin',
        socialUrl: 'https://www.linkedin.com'
      },
      {
        icon: 'icon-instagram',
        socialUrl: 'https://www.instagram.com'
      },
      {
        icon: 'icon-youtube',
        socialUrl: 'https://www.youtube.com'
      },
      {
        icon: 'icon-pinterest',
        socialUrl: 'https://www.pinterest.com'
      }
    ]
  }
}
