/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './FAQ3.styled'
import { validation } from './FAQ3.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Card from './Card.js'
import { translate } from '@editor/common/utils/translations'

const FAQ3 = memo(props => {
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [activeQuestionIdx, setActiveQuestionIdx] = useState(null)

  const rotateCard = idx => () => {
    const isTheSame = idx === activeQuestionIdx
    setActiveQuestionIdx(isTheSame ? null : idx)
  }

  const renderFaqCards = (data, odd, isEditing, Actions) => {
    return (
      <>
        {data.map((faq: string, idx: number) => {
          return idx % 2 === odd ? (
            <Card
              isEditing={isEditing}
              data={faq}
              key={idx}
              rotateCard={() => rotateCard(idx)}
              isActive={idx === activeQuestionIdx}
              faqActions={Actions.faqActions(idx, data.length)}
              changeQuestion={(newQuestion: string) =>
                Actions.changeQuestion(newQuestion, idx)
              }
              changeAnswer={(newAnswer: string) =>
                Actions.changeAnswer(newAnswer, idx)
              }
            />
          ) : null
        })}
      </>
    )
  }

  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      faqs,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <WMContainer>
        <Show when={[title, paragraph]}>
          <S.Hgroup>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading32"
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.maxParagraphChar}
              onChange={Actions.changeParagraph}
              className="WM_GLOBAL_paragraph18"
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.Hgroup>
        </Show>
        <S.FaqsWrapper>
          <div>{renderFaqCards(faqs, 0, isEditing, Actions)}</div>
          <div>{renderFaqCards(faqs, 1, isEditing, Actions)}</div>
        </S.FaqsWrapper>
        <AddButton
          onAdd={Actions.addFaq}
          toShow={faqs.length < validation.faqs.max}
          style={{ marginTop: '20px' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_faq_label')}
        </AddButton>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default FAQ3
