import styled from 'styled-components'

export const BadgeWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 20px;
`

export const NewBadge = styled.img`
  width: 35px;
  height: auto;
`

export const BadgeStar = styled.img`
  position: absolute;
  height: auto;
  &.star-1 {
    width: 7px;
    top: 1%;
    left: -14%;
    animation: appear-1 2.5s ease-out infinite;
    @keyframes appear-1 {
      0% {
        transform: scale(0);
      }
      20% {
        transform: scale(1);
      }
      40% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }
  }
  &.star-2 {
    width: 5px;
    top: -24%;
    left: 9%;
    animation: appear-2 2.5s ease-out infinite;
    @keyframes appear-2 {
      0% {
        transform: scale(0);
      }
      40% {
        transform: scale(0);
      }
      60% {
        transform: scale(1);
      }
      80% {
        transform: scale(0);
      }
      100% {
        transform: scale(0);
      }
    }
  }
  &.star-3 {
    width: 5px;
    top: 51%;
    right: -16%;
    animation: appear-3 2.5s ease-out infinite;
    @keyframes appear-3 {
      0% {
        transform: scale(0);
      }
      20% {
        transform: scale(0);
      }
      40% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
  }
  &.star-4 {
    width: 7px;
    width: 7px;
    top: 69%;
    right: -5%;
    animation: appear-4 2.5s ease-out infinite;
    @keyframes appear-4 {
      0% {
        transform: scale(0);
      }
      60% {
        transform: scale(0);
      }
      80% {
        transform: scale(1);
      }
      100% {
        transform: scale(0);
      }
    }
  }
`
