export const COLUMNS_BY_TAB = {
  phrase_fullsearch: [
    'Keyword',
    'Intent',
    'Search Volume',
    'Trends',
    'Keyword Difficulty Index',
    'CPC',
    'Competition',
    'Keywords SERP Features',
    'Number of Results'
  ],
  phrase_related: [
    'Keyword',
    'Intent',
    'Related Relevance',
    'Search Volume',
    'Trends',
    'Keyword Difficulty Index',
    'CPC',
    'Competition',
    'Keywords SERP Features',
    'Number of Results'
  ],
  phrase_questions: [
    'Keyword',
    'Intent',
    'Search Volume',
    'Trends',
    'Keyword Difficulty Index',
    'CPC',
    'Competition',
    'Number of Results'
  ]
}
