/* @flow */
import React, { useContext, memo } from 'react'
import * as S from './Feature37.styled'
import { validation } from './Feature37.consts'
import ActionGetter from './Actions'
import Element from '@website/common/assets/svgr-icons/StarElement2.svg'
import { DispatchContext, EditingContext } from '@contexts'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import If from '@website/common/components/Conditional'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Feature37 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      blocks,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.WmCustomContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <Show when={[title]}>
        <S.TitleBlock isEditing={isEditing} hasTitle={title}>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.titleMaxChar}
            onChange={Actions.changeTitle}
            className="WM_GLOBAL_heading42"
            changeAlignment={Actions.changeTitleAlignment}
          />
          <S.ElementWrapper isEditing={isEditing}>
            <S.ElementLine />
            <Element />
          </S.ElementWrapper>
        </S.TitleBlock>
      </Show>
      <Mapper
        data={blocks}
        render={(block: string, idx: number) => {
          return (
            <S.BlockWrapper key={idx} className={'block-wrapper' + idx}>
              <S.MapWrapper className="map-wrapper">
                <Mapper
                  data={block}
                  render={(item: string, itemIdx: number) => {
                    return (
                      <S.Col key={itemIdx}>
                        <S.ControlContainer>
                          <ControlsWithImageCropper
                            actions={Actions.imageActions(
                              idx,
                              itemIdx,
                              item.imgUrl
                            )}
                            style={{ width: '100%' }}
                          >
                            <Image
                              as={S.Img}
                              sizes={item.imgDimensions}
                              defaultImgSrc={item.imgUrl}
                              asProps={{
                                imgCropParams: item.imgCropParams
                              }}
                              alt="Portfolio image"
                            />
                          </ControlsWithImageCropper>
                        </S.ControlContainer>
                        <Show
                          when={[
                            item.paragraph,
                            item.title,
                            item.additionalText && item.additionalUrl
                          ]}
                        >
                          <S.BlockContent isEditing={isEditing}>
                            <S.Content isEditing={isEditing}>
                              <EditableContent
                                text={item.title}
                                as={S.Title}
                                alignment={item.blockTitleAlignment}
                                maxCount={validation.titleMaxChar}
                                className="WM_GLOBAL_heading20"
                                onChange={(newTitle: string) =>
                                  Actions.changeBlockTitle(
                                    newTitle,
                                    idx,
                                    itemIdx
                                  )
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockTitleAlignment(
                                    alignment,
                                    idx,
                                    itemIdx
                                  )
                                }
                              />
                              <EditableContent
                                text={item.paragraph}
                                alignment={item.blockParagraphAlignment}
                                as={S.Paragraph}
                                maxCount={validation.paragraphMaxChar}
                                className="WM_GLOBAL_paragraph18"
                                onChange={(newTitle: string) =>
                                  Actions.changeParagraph(
                                    newTitle,
                                    idx,
                                    itemIdx
                                  )
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockParagraphAlignment(
                                    alignment,
                                    idx,
                                    itemIdx
                                  )
                                }
                              />
                              <If
                                condition={
                                  item.additionalText && item.additionalUrl
                                }
                                otherwise={() => (
                                  <AddButton
                                    onAdd={() => Actions.addLink(idx, itemIdx)}
                                    toShow={item.additionalText === ''}
                                    style={{ marginTop: '20px' }}
                                  >
                                    <Icon
                                      name="glyph-add"
                                      className="icon"
                                      size="normal"
                                    />
                                    {translate('add_link_label')}
                                  </AddButton>
                                )}
                                then={() => (
                                  <S.AnchorWrapper>
                                    <ControlsWithModalOpener
                                      actions={Actions.linkActions(
                                        idx,
                                        itemIdx,
                                        {
                                          text: item.additionalText,
                                          url: item.additionalUrl
                                        }
                                      )}
                                    >
                                      <LinkWrapper
                                        url={item.additionalUrl}
                                        text={item.additionalText}
                                        linkProps={{
                                          className:
                                            'WM_GLOBAL_paragraph14 learn-more-link'
                                        }}
                                      >
                                        <S.Icon
                                          size="large"
                                          name="keyboard_arrow_right"
                                          className="arrow-right"
                                        />
                                      </LinkWrapper>
                                    </ControlsWithModalOpener>
                                  </S.AnchorWrapper>
                                )}
                              />
                            </S.Content>
                          </S.BlockContent>
                        </Show>
                      </S.Col>
                    )
                  }}
                />
              </S.MapWrapper>
            </S.BlockWrapper>
          )
        }}
      />
    </S.WmCustomContainer>
  )
})

export default Feature37
