import styled, { css } from 'styled-components'
import { accentGreen, secondary } from '@website/common/styles/colors'

const ANIMATION_TIME = 5000
const ANIMATION_DELAY = 500

const getAnimation = animationName => css`
  animation: ${animationName} ${ANIMATION_TIME}ms infinite linear;
  animation-delay: ${ANIMATION_DELAY}ms;
`

export const StatusAnimationWrapper = styled.div`
  @keyframes status-big-circle-animation {
    4% {
      transform: translateX(-30px);
    }
    5% {
      transform: translateX(-27px);
    }
    6% {
      transform: translateX(-30px);
    }
    50% {
      transform: translateX(-30px);
    }
    55% {
      transform: translateX(0px);
    }
  }

  @keyframes status-bg-animation {
    4% {
      background-color: ${secondary.Normal};
    }
    50% {
      background-color: ${secondary.Normal};
    }
    55% {
      background-color: ${accentGreen};
    }
  }

  @keyframes status-circle-animation {
    4% {
      transform: translateX(0px) scale(0.8);
      opacity: 0;
    }
    10% {
      transform: translateX(25px) scale(1.2);
      opacity: 1;
    }
    15% {
      transform: translateX(35px) scale(0.8);
      opacity: 0;
    }
  }
  @keyframes status-circle-wrapper-1-animation {
    4% {
      transform: rotate(-90deg);
    }
    10% {
      transform: rotate(-75deg);
    }
    15% {
      transform: rotate(-60deg);
    }
  }
  @keyframes status-circle-wrapper-2-animation {
    4% {
      transform: rotate(-90deg);
    }
    10% {
      transform: rotate(-105deg);
    }
    15% {
      transform: rotate(-120deg);
    }
  }
  @keyframes status-circle-wrapper-3-animation {
    4% {
      transform: rotate(-135deg);
    }
    10% {
      transform: rotate(-150deg);
    }
    15% {
      transform: rotate(-165deg);
    }
  }
  @keyframes status-circle-wrapper-4-animation {
    4% {
      transform: rotate(180deg);
    }
    10% {
      transform: rotate(165deg);
    }
    15% {
      transform: rotate(150deg);
    }
  }
  @keyframes status-circle-wrapper-5-animation {
    4% {
      transform: rotate(160deg);
    }
    10% {
      transform: rotate(145deg);
    }
    15% {
      transform: rotate(130deg);
    }
  }

  ${getAnimation('status-bg-animation')};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 65px;
  height: 35px;
  background-color: ${accentGreen};
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 3px;
  box-sizing: border-box;
  & > span {
    ${getAnimation('status-big-circle-animation')};
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    background-color: white;
  }
  & > div {
    width: 40px;
    height: 13px;
    position: absolute;
    transform-origin: 0 0;
    display: flex;
    align-items: center;
    & > span {
      ${getAnimation('status-circle-animation')};
      height: 11px;
      width: 11px;
      border-radius: 50%;
      background-color: red;
      opacity: 0;
    }
  }
  .wrapper-1 {
    ${getAnimation('status-circle-wrapper-1-animation')};
    top: 0;
    left: 25px;
    transform: rotate(-90deg);
    & > span {
      background-color: #7fddc6;
    }
  }
  .wrapper-2 {
    ${getAnimation('status-circle-wrapper-2-animation')};
    top: 0;
    left: 7px;
    transform: rotate(-90deg);
    & > span {
      background-color: #9d9efa;
    }
  }
  .wrapper-3 {
    ${getAnimation('status-circle-wrapper-3-animation')};
    top: 10px;
    left: 0px;
    transform: rotate(-135deg);
    & > span {
      background-color: #f8a9e1;
    }
  }
  .wrapper-4 {
    ${getAnimation('status-circle-wrapper-4-animation')};
    top: 22px;
    left: 2px;
    transform: rotate(180deg);
    & > span {
      background-color: #f8e1ac;
    }
  }
  .wrapper-5 {
    ${getAnimation('status-circle-wrapper-5-animation')};
    top: 40px;
    left: 12px;
    transform: rotate(160deg);
    & > span {
      background-color: #9ed2fa;
    }
  }
`
