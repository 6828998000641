import styled, { css } from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'

const tableCellBorder = '1px solid #c1c9e0'

export const TableContainer = styled.div`
  width: 100%;
  ${({ fullHeight }) =>
    fullHeight
      ? css`
          height: 100%;
        `
      : ''};
  margin-top: 20px;
`

export const Table = styled.table`
  border-radius: 5px 5px 0px 0px;
  border-spacing: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${secondary.ExtraDark};
  position: relative;

  .first-block {
    background: #f8fbff;
    font-weight: 600;

    td {
      padding: 20px 10px;
      border: ${tableCellBorder};
      border-right: 0;
      ${({ isSticky }) =>
        isSticky
          ? ''
          : css`
              border-bottom: 0;
            `};

      &:first-child {
        width: 100%;
        border-radius: 5px 0px 0px 0px;

        &:before {
          content: '';
          border-radius: 0% 0% 100% 0%;
          position: absolute;
          left: -4px;
          top: 0px;
          width: 6px;
          height: 4px;
          transform: rotate(120deg);
          background-color: white;
        }
      }
      &:last-child {
        border-radius: 0px 5px 0px 0px;
        border-right: ${tableCellBorder};

        &:after {
          content: '';
          border-radius: 0% 100% 0% 0%;
          position: absolute;
          right: -4px;
          top: -2px;
          width: 9px;
          height: 4px;
          transform: rotate(45deg);
          background-color: white;
        }
      }
    }
  }

  .second-block {
    background-color: ${white};
    padding: 15px 10px;
    color: ${secondary.ExtraDark};

    td {
      padding: 20px 10px;
      border: ${tableCellBorder};
      border-right: 0;
      border-bottom: 0;

      &:last-child {
        border-right: ${tableCellBorder};
      }
    }
    &:last-child {
      td {
        border-bottom: ${tableCellBorder};

        ${({ isSticky }) =>
          isSticky
            ? ''
            : css`
                &:first-child {
                  border-radius: 0 0 0 5px;
                }
                &:last-child {
                  border-radius: 0 0 5px 0;
                }
              `};
      }
    }

    &:hover {
      background-color: #eef5ff;
    }
  }
`

export const TableHead = styled.thead`
  position: sticky;
  top: 0;
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: -1px;
    width: 100%;
    height: 1px;
    background-color: white;
  }

  ${({ isSticky }) =>
    isSticky
      ? css`
          z-index: 1;
          box-shadow: 0px 5px 15px rgb(56 125 255 / 15%);
        `
      : css``};
`

export const ColumnName = styled.td`
  ${({ isActive }) =>
    isActive
      ? css`
          background-color: #eef5ff;
        `
      : ''}

  ${({ isSortable }) =>
    isSortable
      ? css`
          cursor: pointer;
        `
      : css`
          cursor: default;
        `};
`

export const ColumnHead = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;

  .info-icon-tooltip {
    display: flex;
    align-items: center;
    cursor: pointer;

    & > svg {
      fill: #9aa6c8;
    }
  }
`

export const IconWrapper = styled.div`
  margin-left: 5px;
  ${({ isActive }) =>
    isActive
      ? css`
          svg {
            fill: #545f7e;
          }
        `
      : css`
          svg {
            fill: #8493bd;
          }
        `}

  :hover {
    cursor: pointer;
  }
`

export const NoResultsWrapper = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid #c1c9e0;
`

export const NoResults = styled.span`
  margin-top: -90px;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #252e48;
`
