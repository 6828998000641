import { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { selectTemplatesData } from '@selectors'
import { applyPreset } from '@actions'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { addAnalyticsEvent, navigateToEdit } from '@editor/common/utils'
import { removeQueryStringParam } from '@website/common/utils'
import { EDITOR_BASE } from '@editor/conf/routes'

export const useTemplateApply = (templateId, isApplyCliked = true) => {
  const { url } = useRouteMatch(EDITOR_BASE)
  const history = useHistory()
  const _search = removeQueryStringParam(history.location.search, 'backUrl')

  const templateData = useSelector(
    state => selectTemplatesData(state)[templateId]
  )

  const dispatch = useDispatch()

  useEffect(() => {
    if (templateData?.data && isApplyCliked) {
      dispatch(applyPreset(templateData.data))
      addAnalyticsEvent(
        'SiteMaker Presets List',
        templateData.data.name,
        'choose'
      )
      navigateToEdit(history)(url, _search)
    }
  }, [templateData, isApplyCliked, _search])
}
