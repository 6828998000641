import styled from 'styled-components'

export const Header = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  z-index: 1;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    box-shadow: 0 6px 10px #387dff33;
    & > div {
      margin-left: 0;
    }
  }
`

export const Title = styled.p`
  width: 240px;
  font-size: 16px;
  color: #252e48;
  font-weight: 600;
  padding: 20px 25px;
  z-index: 1;
  background-color: #ffffff;
  border-right: 1px solid #8493bd;
  border-bottom: 1px solid #8493bd;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    width: auto;
    border-right: none;
    padding: 20px;
  }
`

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding-left: 25px;
  border-bottom: 1px solid #8493bd;
  box-shadow: 0 6px 10px #387dff33;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    box-shadow: none;
  }
`

export const Overlay = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
