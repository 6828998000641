import React from 'react'

export const getDropDownOptions = countries =>
  countries.map(country => ({
    component: (
      <>
        <img
          src={`https://static.rfstat.com/${country.flag}`}
          className="phone-number-flag"
        />
        <span>+{country.phonecode}</span>
      </>
    ),
    label: `+${country.phonecode}`,
    value: `+${country.phonecode}`,
    code: `+${country.phonecode}`
  }))

export const splitPhoneNumber = phoneNumber => {
  if (!phoneNumber) {
    return {}
  }

  const arr = phoneNumber.split('-')
  return {
    code: arr[0],
    number: arr[1]
  }
}
