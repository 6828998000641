/* @flow */
import React, { PureComponent } from 'react'
import S from './Form.styled'
import ActionGetter from '../Actions'
import { validation } from '../Contact5.consts'
import { LabelRenderer, isRequiredField } from './Form.util'
//lib components
import SelectMenu from '@website/common/components/SelectMenu'
import EditableContent from '@website/common/components/EditableContent'
import { withDispatchContext, withEditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { EMPTY_FIELD_MESSAGE } from '../Contact5.consts'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'

class ServiceInput extends PureComponent<void, void> {
  state = { isInputClicked: false }

  setInputState = () => {
    this.setState({ isInputClicked: true })
  }

  setSelectedService = idx => {
    const {
      data: { services },
      setFormValue
    } = this.props
    setFormValue(services[idx], 'service')
  }

  render() {
    const { data, formData, isEditing, requiredFields, componentDispatcher } =
      this.props
    const Actions = ActionGetter(componentDispatcher)
    const { services, serviceLabel } = data
    return (
      <S.ServiceInputContainer className="service-input-container">
        <LabelRenderer
          text={serviceLabel}
          label="service"
          isEditing={isEditing}
          dispatcher={componentDispatcher}
          isRequired={isRequiredField('service', requiredFields)}
          className="WM_GLOBAL_secondary-font"
        />
        <S.SelectMenuWrapper
          className="input-container WM_GLOBAL_secondary-font"
          onClick={this.setInputState}
        >
          <SelectMenu
            fullWidth
            tabIndex="0"
            options={services}
            showAddButton={true}
            onChange={this.setSelectedService}
            defaultSelectedIdx={0}
            onOptionAdd={Actions.addServiceOption}
            rowRenderer={(row, idx) => (
              <ControlsWithModalOpener
                style={{ width: '100%' }}
                actions={Actions.serviceOptionActions(idx)}
              >
                <EditableContent
                  text={row}
                  as={S.Row}
                  onChange={(service: string) => {
                    Actions.changeService(service, idx)
                  }}
                  className="WM_GLOBAL_secondary-font"
                  maxCount={validation.serviceMaxChar}
                  areControlsHidden
                  toPropogate
                />
              </ControlsWithModalOpener>
            )}
            renderSelected={() => formData.service}
          />
          {true && (
            <S.ErrorMessage>
              {' '}
              <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
              {EMPTY_FIELD_MESSAGE}
            </S.ErrorMessage>
          )}
        </S.SelectMenuWrapper>
      </S.ServiceInputContainer>
    )
  }
}

export default withEditingContext(withDispatchContext(ServiceInput))
