/* @flow */
import React, { memo, useCallback, useState } from 'react'

import Controls from '@website/common/components/Controlled/Controls'
import { getFormIdByEventId } from '@website/common/utils/event'
import { generateUniqId } from '@editor/common/utils'

import RSVPRegistrationPopup from '../RSVPRegistrationPopup'
import ActionGetter from './Actions'
import * as Styled from './styled'
import type { TRSVPFormProps } from './types'
import { getInitialState, getRegistrationPopupContentProps } from './utils'
import RSVPFormContent from './RSVPFormContent'
import useEventSettingsOpener from '@hooks/useEventSettingsOpener'

const RSVPForm = ({
  compId,
  eventId,
  formData: eventFormData,
  popupsData,
  detailsData,
  additionalGuestCount,
  isFormDivided,
  setGuestLimit,
  setFormOpenStatus,
  needToScrollAfterClosing = true,
  formCloseDelay,
  onRegistrationPopupClose
}: TRSVPFormProps) => {
  const initialState = getInitialState(eventFormData)

  const [formData, setFormData] = useState(initialState)
  const [isSubmitting, setSubmittingState] = useState(false)
  const [registrationPopupType, setRegistrationPopupType] = useState('')
  const [formKey, setFormKey] = useState(generateUniqId())

  const Actions = ActionGetter()

  const closeForm = useCallback(() => {
    const formId = getFormIdByEventId(eventId)
    const form = document.getElementById(formId)
    const comp = document.getElementById(`comp-${compId}`)

    setGuestLimit(null)
    form.style.display = 'none'
    needToScrollAfterClosing
      ? comp.scrollIntoView({
          behavior: 'smooth'
        })
      : setFormOpenStatus && setFormOpenStatus('closed')
  }, [needToScrollAfterClosing])

  const _closeForm = () => {
    closeForm()
    onRegistrationPopupClose && onRegistrationPopupClose(false)
    setFormKey(generateUniqId())
  }

  const setFormInitialDataAndClose = () => {
    setFormData(initialState)
    _closeForm()
  }

  const closeRegistrationPopup = useCallback(() => {
    setRegistrationPopupType('')
    onRegistrationPopupClose && onRegistrationPopupClose(true)

    formCloseDelay
      ? setTimeout(setFormInitialDataAndClose, formCloseDelay)
      : setFormInitialDataAndClose()
  }, [formCloseDelay, setFormInitialDataAndClose])

  const { openDashboard } = useEventSettingsOpener('forms')

  return (
    <Styled.Container className="form-container">
      <Controls
        actions={Actions.eventSettingsActionMaker({
          tabName: 'forms',
          onControlClick: openDashboard
        })}
      >
        <RSVPFormContent
          formKey={formKey}
          eventId={eventId}
          formData={formData}
          isSubmitting={isSubmitting}
          setFormData={setFormData}
          eventFormData={eventFormData}
          isFormDivided={isFormDivided}
          setSubmittingState={setSubmittingState}
          additionalGuestCount={additionalGuestCount}
          setRegistrationPopupType={setRegistrationPopupType}
        />
      </Controls>
      <RSVPRegistrationPopup
        isPopupOpen={!!registrationPopupType}
        onClose={closeRegistrationPopup}
        detailsData={detailsData}
        {...getRegistrationPopupContentProps(popupsData, registrationPopupType)}
      />
    </Styled.Container>
  )
}

export default memo(RSVPForm)
