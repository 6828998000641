/* @flow */
import styled, { css, createGlobalStyle } from 'styled-components'
import THEME from './ProjectLinks.theme'
import { styleProvider } from '../../utils'
import LibIcon from '@website/common/components/Icon'
import Menu from '@website/common/components/Menu'
import { secondary, white } from '@website/common/styles/colors'

const ALIGNMENT_STYLES = {
  left: css`
    justify-content: flex-start;
  `,
  right: css`
    justify-content: flex-end;
  `,
  middle: css`
    justify-content: center;
  `
}

const NavbarGlobalStyles = createGlobalStyle`
  .header-nav-bar {
    z-index: 21;
  }
`

const getStyleForProp = styleProvider(THEME)

const getLinkContainerAlignment = ({ alignment }) => ALIGNMENT_STYLES[alignment]

const Wrap = styled.div`
  display: inline-block;
  position: relative;
  ${({ activePageType }) =>
    activePageType === 'line_bottom' && 'border-bottom:1px solid black'};
  ${({ activePageType }) =>
    activePageType === 'line_top' && 'border-top:1px solid black'};
  ${({ activePageType }) =>
    activePageType === 'pointer' &&
    css`
      &::after {
        content: ' ';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: ${getStyleForProp('paragraph')};
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
      }
    `}
  .link {
    cursor: pointer;
    text-decoration: none;
    text-overflow: ellipsis;
    max-width: 250px;
    display: block;
    overflow: hidden;
    width: 100%;
    padding: 10px;
    color: ${getStyleForProp('paragraph')};
    white-space: nowrap;
    transition: opacity 0.1s linear;
    &:hover {
      opacity: 0.6;
    }
  }
`

const LinkContainer = styled.nav`
  display: flex;
  align-items: center;
  padding: 10px 0;
  @media only screen and (max-width: 1024px) {
    display: none;
  }

  .add-button-wrap {
    margin-left: 20px;
  }
`

const Desktop = styled.div`
  display: flex;
  align-items: center;
  ${getLinkContainerAlignment};
  width: 100%;
  margin: 0px 20px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }

  .header-socials {
    padding: 10px 0;
  }
`

const DropDownContainer = styled.div`
  cursor: pointer;
  text-transform: uppercase;
  color: ${getStyleForProp('paragraph')};
  max-width: 270px;
  position: relative;
`

const MoreLinks = styled.div`
  margin-left: 10px;
  align-items: center;
  display: flex;
  padding: 10px 0;
  white-space: nowrap;
  word-break: initial;
`

const MobileLinks = styled.div`
  box-sizing: border-box;
  margin: 50px 0 30px 15px;
  .header-socials {
    padding-top: 25px;
  }

  .socialIcons {
    .link {
      fill: ${getStyleForProp('paragraph')};
    }
  }

  @media only screen and (max-width: 550px) {
    margin: 50px 20px 30px 20px;
  }
`

const MobileLink = styled.div`
  display: flex;
  width: 80%;

  & .link-wrapper {
    width: 100%;
  }

  .add-button-wrap {
    margin: 20px;
  }

  .link {
    cursor: pointer;
    text-decoration: none;
    margin: 20px 0 20px 100px;
    color: ${getStyleForProp('paragraph')};
    @media only screen and (max-width: 1024px) {
      margin: 0;
      width: 100%;
      padding: 15px 0px 15px 20px;
    }
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    margin: 0;
  }
`

const IconContainer = styled.div`
  position: absolute;
  right: 0;
  z-index: 3;
  padding: 15px;
  cursor: pointer;
  svg {
    fill: ${getStyleForProp('hamburger')};
  }
`

const MobileLinkContainer = styled.div`
  top: 0;
  bottom: 0;
  z-index: 50;
  width: 100%;
  display: none;
  position: fixed;
  background-color: ${getStyleForProp('backgroundColor')};
  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

export const MobileNavBarContent = styled.div`
  height: 100%;
  overflow-y: scroll;
`

//hamburger
const MobileNavbarContainer = styled.div`
  margin: 10px 0 10px auto;
  z-index: 2;
  display: block;
  color: ${getStyleForProp('hamburger')};
`
const Div = styled.div`
  text-transform: initial;
`

const Mobile = styled.div`
  display: none;
  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

const HamburgerIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('hamburger')
}))``

const MoreArrowIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('paragraph')
}))`
  margin-top: 4px;
  flex-shrink: 0;
`

const DropdownLinks = styled(Menu)`
  max-height: 400px;
  overflow: auto;
  background-color: ${getStyleForProp('backgroundColor')};
  min-width: 200px;
  max-width: 300px;
  border: 1px solid ${getStyleForProp('borderColor')};
  border-radius: ${({ theme }) =>
    theme.look === 'ROUNDED'
      ? '10px'
      : theme.look === 'CORNERED'
      ? '5px'
      : '0'};
  box-shadow: 0 3px 12px #00000029;
`

const DropdownLink = styled.div`
  width: 100%;
  height: 50px;
  padding: 15px 10px 15px 20px;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  .link-wrapper {
    max-width: 210px;
    padding-right: 10px;
  }

  .link {
    width: 100%;
    cursor: pointer;
    text-transform: initial;
    text-decoration: none;
    color: ${getStyleForProp('paragraph')};
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    transition: all 0.1s linear;
    &:hover {
      opacity: 0.6;
    }
  }
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;

  & > div:first-child {
    margin-right: 10px;
    svg {
      width: 22px;
      height: 22px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
    fill: ${({ theme }) =>
      theme.colorMode === 'DARK' ? white : secondary.SemiDark};

    &:hover {
      fill: ${({ theme }) =>
        theme.colorMode === 'DARK' ? secondary.Light : secondary.ExtraDark};
    }
  }
`

export {
  Div,
  Mobile,
  MobileLinks,
  IconContainer,
  MoreLinks,
  MobileLink,
  MobileNavbarContainer,
  DropDownContainer,
  LinkContainer,
  Wrap,
  MobileLinkContainer,
  Desktop,
  HamburgerIcon,
  MoreArrowIcon,
  DropdownLinks,
  DropdownLink,
  ActionsWrapper,
  NavbarGlobalStyles
}
