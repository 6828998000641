/* @flow */
import styled from 'styled-components'

import THEME from './Header7.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0;
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  padding-bottom: 80px;
  @media only screen and (max-width: 457px) {
    padding-bottom: 60px;
  }
  > :first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    h2 {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.h2`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
`

const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1025px) {
    max-width: 600px;
  }
  > :first-child {
    padding-top: 0;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const WebpageWrap = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 20px auto 0 auto;
  overflow: hidden;
  padding: 30px 0 0 0;
  font-size: 0;
  @media only screen and (max-width: 992px) {
    margin-top: 10px;
  }
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 62.25%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    padding-top: 62.25%;
    height: initial;
  }
`

const BrowserBar = styled.img``

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default {
  Img,
  StyledContainer,
  WmCustomContainer,
  WebpageWrap,
  BrowserBar,
  HeaderContent,
  Title,
  Description
}
