/* @flow */
import React, { useCallback, useEffect, memo, useRef, useContext } from 'react'
import ReactDOM from 'react-dom'
import * as Styled from './styled'
import { projectContainerClassName } from '@website/conf/consts'
import useRelativeCoordinates from '@website/common/hooks/useRelativeCoordinates'
import { EditingContext } from '@contexts'

const Menu = ({
  menuItems,
  containerRef,
  onClose,
  className = '',
  backdropDisablingSelectors
}) => {
  const menuRef = useRef(null)
  const { isEditing } = useContext(EditingContext)

  const renderingContainer =
    typeof document !== 'undefined' &&
    (document.querySelector(`.${projectContainerClassName}`) || document.body)

  const coords = useRelativeCoordinates(
    renderingContainer,
    containerRef,
    menuRef,
    { alignmentX: 'right', alignmentY: 'bottom' }
  )

  const handleOutsideClick = useCallback(
    e => {
      const selectors = backdropDisablingSelectors
        ? backdropDisablingSelectors.map(selector =>
            document.querySelector(selector)
          )
        : []

      const hasPreventedSelector = selectors.some(selector =>
        selector ? selector.contains(e.target) : false
      )

      if (
        hasPreventedSelector ||
        (containerRef && containerRef.contains(e.target)) ||
        (menuRef.current && menuRef.current.contains(e.target))
      ) {
        return
      }
      onClose()
    },
    [containerRef, backdropDisablingSelectors, onClose, menuRef]
  )

  useEffect(() => {
    document.addEventListener('mouseup', handleOutsideClick)

    return () => {
      document.removeEventListener('mouseup', handleOutsideClick)
    }
  }, [])

  const menuRenderingContainer = isEditing ? renderingContainer : containerRef

  return ReactDOM.createPortal(
    <Styled.Menu
      isEditing={isEditing}
      _coords={coords}
      className={className}
      ref={menuRef}
      containerRef={containerRef}
    >
      {menuItems.map((item, idx) => (
        <React.Fragment key={idx}>{item}</React.Fragment>
      ))}
    </Styled.Menu>,
    menuRenderingContainer
  )
}

export default memo(Menu)
