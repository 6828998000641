/* @flow */
import React, { useContext, useState, useCallback, useMemo, memo } from 'react'
import S from './Countdown2.styled'
import ActionGetter from './Actions'
import { validation } from './Countdown2.consts'
import If from '@website/common/components/Conditional'
import DatePicker from '@website/common/components/DatePicker'
import { DispatchContext, EditingContext, MobileDetectContext } from '@contexts'
import Mapper from '@website/common/components/Mapper'
import { getDate } from '@website/common/utils'
import EditableContent from '@website/common/components/EditableContent'
import SvgCircle from './SvgCircle.js'
import useCountdownDatePicker from '@website/common/hooks/useCountdownDatePicker'

const addYears = (date, years) => {
  date.setFullYear(date.getFullYear() + years)
  return date
}

const Countdown2 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      dateLabels,
      countdownDate,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const _countdownDate = countdownDate || new Date().toISOString()

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const isMobile = useContext(MobileDetectContext)
  const [datePickerVisibility, setDatePickerVisibility] = useState(false)
  const [formatedDates, setFormatedDates] = useState(getDate(_countdownDate))
  const [ datePickerAnchorEl, setDatePickerAnchorEl ] = useState(null)

  const _submitDate = useCallback(
    date => {
      setDatePickerVisibility(false)
      Actions.changeDate(date.toISOString())
    },
    [_countdownDate]
  )

  useCountdownDatePicker(_countdownDate, setFormatedDates)

  const handleDatePickerOpen = useCallback(
    (e) => {
      if(!isEditing) {
        return
      }
      setDatePickerAnchorEl(e.currentTarget)
      setDatePickerVisibility(true)
    },
    []
  )

  const closeDatePicker = useCallback(() => {
    setDatePickerVisibility(false)
  }, [])

  const endDate = useMemo(() => addYears(new Date(), 1), [])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <If
        condition={datePickerVisibility}
        then={() => (
          <DatePicker
            anchorEl={datePickerAnchorEl}
            submitDate={_submitDate}
            closeDatePicker={closeDatePicker}
            selectedDays={_countdownDate}
            endDate={endDate}
          />
        )}
      />
      <S.WmCustomContainer>
        <S.DateContainer isEditing={isEditing}>
          <Mapper
            data={Object.keys(formatedDates)}
            render={(formatedDate, idx) => {
              return (
                <S.DateWrapper key={idx}>
                  <SvgCircle
                    max={idx === 0 ? 365 : idx === 1 ? 24 : 60}
                    done={formatedDates[formatedDate]}
                    className="line"
                  />
                  <S.Time onClick={handleDatePickerOpen}>
                    <p className="WM_GLOBAL_primary-font">
                      {formatedDates[formatedDate]}
                    </p>
                  </S.Time>
                  <EditableContent
                    text={isMobile ? dateLabels[idx][0] : dateLabels[idx]}
                    as={S.Date}
                    maxCount={validation.dateLabelsMaxChar}
                    className="WM_GLOBAL_heading20"
                    onChange={(newDateLabel: string) => {
                      Actions.changeDateLabel(newDateLabel, idx)
                    }}
                    areControlsHidden
                  />
                </S.DateWrapper>
              )
            }}
          />
        </S.DateContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.headingMaxChar}
          isEditing={isEditing}
          className="WM_GLOBAL_heading42"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Countdown2
