// @flow
import React, { useCallback, useContext, useEffect, memo } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'

import { getLocalizedURL } from '@editor/common/utils/translations'
import { UPGRADE_REFERRER } from '@editor/conf/consts'
import { openUrl } from '@website/common/utils'
import { UpgradePopupContext } from '@contexts'
import { fetchDataAfterSubscribe, upgradeSubscriptionPlan } from '@actions'
import { selectWebsiteSubscriptionPlan, getSiteId } from '@selectors'

import {
  RECURRING_ACTIVE,
  RECURRING_INACTIVE,
  TARIFF_PLAN_IDS,
  UPGRADE_THANK_YOU_URL
} from './consts'
import PopupCore from './PopupCore'
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect'

const Upgrade = connect(null, { upgradeSubscriptionPlan })(
  memo(({ upgradeSubscriptionPlan, referrer }) => {
    const upgradeCallback = () => {
      openUrl(UPGRADE_THANK_YOU_URL, true)
    }

    const onUpgradeButtonClick = useCallback((newTariffPlanId, isYearly) => {
      upgradeSubscriptionPlan(
        newTariffPlanId,
        isYearly,
        referrer,
        upgradeCallback
      )
    }, [])

    return <PopupCore onUpgradeClick={onUpgradeButtonClick} isUpgrade />
  })
)

const Subscribe = memo(({ referrer }) => {
  const siteId = useSelector(getSiteId)

  const onUpgradeBtnClick = useCallback(
    (tariffId: number) => {
      const url = getLocalizedURL(
        `/subscription/${tariffId}?site_id=${siteId}&ref=${referrer}`
      )
      openUrl(url)
    },
    [siteId, referrer]
  )

  return <PopupCore onUpgradeClick={onUpgradeBtnClick} />
})

const UpgradePopup = () => {
  const {
    upgradePopupState: { showUpgradeFlow, referrer = UPGRADE_REFERRER },
    setUpgradePopupState
  } = useContext(UpgradePopupContext)
  const {
    tariffId: currentTariffId = TARIFF_PLAN_IDS.free,
    recurring = RECURRING_INACTIVE
  } = useSelector(selectWebsiteSubscriptionPlan)
  const dispatch = useDispatch()

  const isRecurringActive = recurring === RECURRING_ACTIVE
  const _showUpgradeFlow = showUpgradeFlow && isRecurringActive

  const closeUpgradePopup = useCallback(
    () => setUpgradePopupState({ isOpen: false }),
    []
  )

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      dispatch(fetchDataAfterSubscribe())
    }
  }, [fetchDataAfterSubscribe])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.addEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [handleVisibilityChange])

  useDidUpdateEffect(() => {
    closeUpgradePopup()
  }, [currentTariffId])

  return _showUpgradeFlow ? (
    <Upgrade referrer={referrer} />
  ) : (
    <Subscribe referrer={referrer} />
  )
}

export default memo(UpgradePopup)
