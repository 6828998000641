// @flow

import React, { memo } from 'react'
import Popup from '@editor/common/lib/Popup'
import { translate } from '@editor/common/utils/translations'
import Content from './Content'

const WebsiteStatusMobile = ({ isOpen, onClose }) => (
  <Popup
    isOpen={isOpen}
    title={translate('website_status_label')}
    mobileProps={{ className: 'website-status-mobile-popup' }}
    onClose={onClose}
  >
    <Content />
  </Popup>
)

export default memo(WebsiteStatusMobile)
