//@flow
import React, { useCallback, useContext, memo } from 'react'
import { useParams } from 'react-router-dom'

import { TLanguagesMenuProps } from './types'
import LanguagesMenuCore from './LanguagesMenuCore'
import { EditingContext } from '@contexts'
import { useHistoryWithSearch } from '@hooks'

const LanguagesMenu = ({ websiteLanguages }: TLanguagesMenuProps) => {
  const { siteId: siteIdFromRoute, lang: websiteActiveLanguageCode } =
    useParams()
  const history = useHistoryWithSearch()

  const { isEditing } = useContext(EditingContext)

  const onItemClick = useCallback(
    languageCode => {
      if (websiteActiveLanguageCode === languageCode) return

      if (isEditing) {
        history.push(`/${siteIdFromRoute}/${languageCode}/edit/home`)
        return
      }

      history.push(`/preview/${languageCode}/site/${siteIdFromRoute}`)
      window.parent && window.parent.setLangInUrl(languageCode)
    },
    [isEditing, websiteActiveLanguageCode, siteIdFromRoute]
  )

  return (
    <LanguagesMenuCore
      websiteLanguages={websiteLanguages}
      websiteActiveLanguageCode={websiteActiveLanguageCode}
      onItemClick={onItemClick}
    />
  )
}

export default memo(LanguagesMenu)
