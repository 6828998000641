import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    linksColor: p => p.accentWhite,
    background: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryDark,
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    layerBackground: p => `rgba(${hexToRgb(p.primaryLight)}, 0.9)`
  },
  LIGHT: {
    linksColor: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    navbarBg: p => p.tertiaryLight,
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    layerBackground: p => `rgba(${hexToRgb(p.primaryDark)}, 0.9)`
  },
  EXTRALIGHT: {
    linksColor: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    layerBackground: p => `rgba(${hexToRgb(p.tertiarySemiLight)}, 0.8)`
  }
}
