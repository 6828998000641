
import React from 'react'

const Arrow = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_215_27)">
            <path d="M1.80818 6.3122C1.61247 6.1116 1.50293 5.84245 1.50293 5.5622C1.50293 5.28195 1.61247 5.0128 1.80818 4.8122C2.01152 4.61517 2.28354 4.505 2.56668 4.505C2.84982 4.505 3.12184 4.61517 3.32518 4.8122L7.49918 8.9362L11.6742 4.8072C11.8775 4.61017 12.1495 4.5 12.4327 4.5C12.7158 4.5 12.9878 4.61017 13.1912 4.8072C13.3869 5.00779 13.4964 5.27695 13.4964 5.5572C13.4964 5.83745 13.3869 6.1066 13.1912 6.3072L8.26318 11.1842C8.06044 11.3858 7.78612 11.499 7.50018 11.499C7.21424 11.499 6.93992 11.3858 6.73718 11.1842L1.80818 6.3122Z" fill="black"/>
        </g>
        <defs>
            <clipPath id="clip0_215_27">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    )
}

export default Arrow
