/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text15.styled'
import ActionGetter from './Actions'
import { validation } from './Text15.consts'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import { getCurrentScreenSize } from '@website/common/utils'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Text15 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      images,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      blocks
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.ContentWrapper>
          <S.TextImageContent
            isEditing={isEditing}
            isOneImage={images.length === 1}
          >
            <Mapper
              data={images}
              render={(image, idx) => {
                return (
                  <S.ImageWrapper
                    className={'image-' + (idx + 1)}
                    key={idx}
                    isOneImage={images.length === 1}
                  >
                    <S.ControlContainer>
                      <ControlsWithImageCropper
                        actions={Actions.imageActions(
                          idx,
                          images.length,
                          image.imageUrl
                        )}
                        style={{ width: '100%' }}
                        key={idx}
                      >
                        <Image
                          as={S.Img}
                          sizes={image.imgDimensions}
                          defaultImgSrc={image.imageUrl}
                          asProps={{
                            imgCropParams: image.imgCropParams
                          }}
                          alt="Text images"
                        />
                      </ControlsWithImageCropper>
                    </S.ControlContainer>
                  </S.ImageWrapper>
                )
              }}
            />
            <AddButton
              onAdd={Actions.addImageBlock}
              toShow={images.length < validation.images.max}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              Add image
            </AddButton>
          </S.TextImageContent>
          <S.RightContentWrap>
            <S.ParagraphsWrap isBlocksExist={blocks.length !== 0}>
              <EditableContent
                as={S.Title}
                alignment={titleAlignment}
                text={title}
                required={validation.title.required}
                maxCount={validation.titleMaxChar}
                className="WM_GLOBAL_heading42"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                as={S.Paragraph}
                alignment={paragraphAlignment}
                text={paragraph}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </S.ParagraphsWrap>
            <S.BlocksWrapper isThreeBlock={blocks.length % 3 === 1}>
              <Mapper
                data={blocks}
                render={(block: string, idx: number) => (
                  <S.Block key={idx}>
                    <ControlsWithModalOpener
                      actions={Actions.blockActions({
                        idx
                      })}
                      style={{ height: '100%', width: '100%' }}
                    >
                      <S.BlockItemWrap>
                        <Show when={[block.iconName]}>
                          <S.IconContainer
                            isIconOnly={!block.title && !block.paragraph}
                          >
                            <If
                              condition={!!block.iconName}
                              otherwise={() => (
                                <AddButton
                                  onAdd={() => Actions.addIcon(idx)}
                                  style={{
                                    margin: '0px'
                                  }}
                                  type="icon"
                                  large
                                  toShow
                                >
                                  +
                                </AddButton>
                              )}
                              then={() => (
                                <ControlsWithModalOpener
                                  actions={Actions.iconActions({ idx })}
                                >
                                  <S.IconWrap>
                                    <Icon name={block.iconName} />
                                  </S.IconWrap>
                                </ControlsWithModalOpener>
                              )}
                            />
                          </S.IconContainer>
                        </Show>
                        <Show when={[block.title || block.paragraph]}>
                          <S.BlockWrap
                            isIconExist={block.iconName}
                            isEditing={isEditing}
                          >
                            <EditingContext.Consumer>
                              {({ isEditing }) => {
                                return (
                                  <EditableContent
                                    as={S.BlockTitle}
                                    text={block.title}
                                    isEditing={isEditing}
                                    maxCount={validation.blockParagraphMaxChar}
                                    onChange={value => {
                                      Actions.changeBlockTitle(value, idx)
                                    }}
                                    className="WM_GLOBAL_paragraph12"
                                  />
                                )
                              }}
                            </EditingContext.Consumer>
                            <EditableContent
                              as={S.BlockParagraph}
                              text={block.paragraph}
                              maxCount={validation.blockTitleMaxChar}
                              onChange={value => {
                                Actions.changeBlockParagraph(value, idx)
                              }}
                              className="WM_GLOBAL_heading20"
                            />
                          </S.BlockWrap>
                        </Show>
                      </S.BlockItemWrap>
                    </ControlsWithModalOpener>
                  </S.Block>
                )}
              />
              <AddButton
                onAdd={Actions.addBlock}
                toShow={blocks.length < validation.blocks.max}
                style={{
                  marginTop: '2rem',
                  marginLeft: '20px'
                }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_item_label')}
              </AddButton>
            </S.BlocksWrapper>
          </S.RightContentWrap>
        </S.ContentWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text15
