/* @flow */
import styled, { css } from 'styled-components'

const getLabelColor = error => {
  if (error) {
    return css`
      &:after {
        color: #ff4c4c;
      }
    `
  }
  return css`
    &:after {
      color: #7683a7;
    }
  `
}

const getInputsBorderColor = (
  hasValidationError,
  hasWarning,
  hasLimitError
) => {
  if (hasValidationError || hasLimitError) {
    return css`
      border: 1px solid #ff4c4c;
    `
  }
  if (hasWarning) {
    return css`
      border: 1px solid #e79e00;
    `
  }
  return css`
    border: 1px solid #dde3f0;
  `
}

const getLimitCharacterColor = (hasLimitError, hasWarning) => {
  if (hasLimitError) {
    return css`
      color: #ff4c4c;
    `
  }
  if (hasWarning) {
    return css`
      color: #e79e00;
    `
  }
  return css`
    color: #7683a8;
  `
}

export const Title = styled.div`
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #363f5a;
  margin-bottom: 10px;
  text-align: center;
  @media only screen and (max-width: 650px) {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 8px;
  }
`

export const Paragraph = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #545f7e;
  margin-bottom: 30px;
  text-align: center;
  @media only screen and (max-width: 650px) {
    margin-bottom: 20px;
  }
`

export const Label = styled.div`
  display: inline-block;
  width: max-content;
  font-size: 14px;
  line-height: 18px;
  color: #545f7e;
  font-weight: 500;
  margin-bottom: 5px;
  padding-left: 8px;
  position: relative;
  &:after {
    content: '*';
    position: absolute;
    top: 0;
    right: -10px;
  }
`

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px 0 15px;
  height: 40px;
  font-size: 12px;
  -webkit-appearance: none;
  border-radius: 10px;
  border: 1px solid #dde3f0;
  color: #363f5a;
  font-family: inherit;
  &:focus {
    border: none;
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
  }
`

export const TextArea = styled.textarea`
  box-sizing: border-box;
  width: 100%;
  padding: 14px 30px 0 15px;
  height: 148px;
  max-height: 140px;
  font-size: 12px;
  -webkit-appearance: none;
  border-radius: 10px;
  border: 1px solid #dde3f0;
  color: #363f5a;
  resize: none;
  font-family: inherit;
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
  }
  &:focus {
    border: none;
    outline: none;
  }
`
export const FormMessage = styled.p`
  color: #ff4c4c;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: -18px;
  left: 8px;
  z-index: 2;
  &.required-comment {
    bottom: 0;
  }
`
export const LimitTextCharacterWrap = styled.div`
  position: absolute;
  right: 0;
  color: #7683a8;
  font-size: 12px;
  font-weight: 500;
  z-index: 1;
`

export const FieldContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
  position: relative;
  border: none !important;
  ${Label} {
    &:after {
      color: #7683a7;
    }
  }
  ${Input}, ${TextArea} {
    border: 1px solid #dde3f0;
  }
  &.comment-field-coontainer {
    margin-bottom: 10px;
  }
  ${({ hasValidationError, hasWarning, hasLimitError }) => css`
    ${Label} {
      ${getLabelColor(hasValidationError)};
    }
    ${Input}, ${TextArea} {
      ${getInputsBorderColor(hasValidationError, hasWarning, hasLimitError)};
    }
    ${LimitTextCharacterWrap} {
      ${getLimitCharacterColor(hasLimitError, hasWarning)};
    }
  `}
`

export const FormContentWrapper = styled.div`
  max-height: 90vh;
  overflow: auto;
  padding: 0 40px;
  @media only screen and (max-width: 650px) {
    padding: 0 20px;
    max-height: 98vh;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 10px;
`

export const CancelButton = styled.button`
  font-size: 14px;
  line-height: 50px;
  font-weight: 500;
  color: #387dff;
  padding: 0 30px;
  border: none;
  outline: none;
  background-color: none;
  margin-right: 20px;
  background-color: #ffffff;
  cursor: pointer;
  font-weight: 500;
  border-radius: 10px;
  font-family: inherit;
  @media only screen and (max-width: 650px) {
    margin-right: 10px;
  }
  &:hover {
    background-color: #eef5ff;
  }
`

export const SendButton = styled.button`
  font-size: 14px;
  line-height: 50px;
  font-weight: 500;
  color: #ffffff;
  border-radius: 10px;
  padding: 0 30px;
  border: none;
  outline: none;
  background: linear-gradient(-179deg, #5690ff 0, #387dff 97%);
  cursor: pointer;
  font-weight: 500;
  &:hover {
    background-image: linear-gradient(-179deg, #6ea0ff 5%, #518dff 97%);
    box-shadow: 0 3px 9px 0 rgb(56 125 255 / 50%);
  }
`
export const LimitTextMessageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  margin-top: 5px;
  min-height: 15px;
`
export const LimitTextMessageWrap = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 500;
  color: #ff4c4c;
  div {
    min-width: 12px;
    transform: translateY(2px);
  }
  &.comment-limit-message {
    p {
      margin-right: 35px;
    }
  }
  p {
    margin-left: 5px;
  }
`

export const InfoMessage = styled.p`
  color: #7683a8;
  font-size: 10px;
  font-weight: 500;
  text-align: center;
`
