/* @flow  */
const lightboxImageSizeforDesctop = 115
const lightboxImageSizeforMobile = 80
import { isLandscapeMobile } from '@website/common/utils'

export const lightBoxImageWidth =
  typeof window !== 'undefined' &&
  (window.innerWidth <= 480 || isLandscapeMobile())
    ? lightboxImageSizeforMobile
    : lightboxImageSizeforDesctop

export const lightBoxMainSliderSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  speed: 100,
  className: 'custom-slick-slider'
}

export const lightboxImagesSliderSettings = {
  infinite: false,
  touchMove: true,
  slidesToScroll: 4,
  slidesToShow:
    typeof window !== 'undefined'
      ? Math.round(window.innerWidth / lightBoxImageWidth) - 1
      : 1,
  className: 'slider variable-width '
}
