// @flow

import React from 'react'
import { connect } from 'react-redux'
import * as Styled from './styled'
import {
  getSubDomain,
  selectNewSubdomain,
  selectSitePublishedState,
  selectSitePublishUrl
} from '@selectors'
import { BoldComp } from '@editor/common/styled-components/simple-tags'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import type { TNotificationProps } from './types'

const Notification = ({
  fromPublishPopup,
  isSitePublished,
  sitePublishUrl,
  subdomain,
  newSubdomain
}: TNotificationProps) => {
  const showWarningNotification = isSitePublished && !subdomain
  const showInfoNotification =
    !fromPublishPopup && subdomain && newSubdomain && newSubdomain !== subdomain

  if (!showWarningNotification && !showInfoNotification) {
    return null
  }

  return (
    <Styled.NotificationWrapper isWarning={showWarningNotification}>
      {showWarningNotification ? <Styled.WarningIcon /> : <Styled.InfoIcon />}
      <Styled.Notification>
        {showWarningNotification ? (
          fromPublishPopup ? (
            <>
              <span>Note: </span>
              Your website is available on <span>{sitePublishUrl}</span>. Due to
              the system enhancements, it will be updated to the{' '}
              <span>entered subdomain</span> after publishing.
            </>
          ) : (
            <>
              <span>Note: </span>
              Your website is available on <span>{sitePublishUrl}</span>. We
              recommend customizing it for a better experience.
            </>
          )
        ) : null}
        {showInfoNotification ? (
          <>
            {transformTextVariables(
              translate('website_will_be_available_after_publishing_label'),
              [
                {
                  value: translate(
                    'website_will_be_available_after_publishing_label_tr1'
                  ),
                  Comp: BoldComp
                }
              ]
            )}
          </>
        ) : null}
      </Styled.Notification>
    </Styled.NotificationWrapper>
  )
}

const mapStateToProps = state => ({
  isSitePublished: selectSitePublishedState(state),
  sitePublishUrl: selectSitePublishUrl(state),
  subdomain: getSubDomain(state),
  newSubdomain: selectNewSubdomain(state)
})

export default connect(mapStateToProps)(Notification)
