/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header47.styled'
import THEME from './Header47.theme'
import { validation } from './Header47.consts'
import ActionGetter from './Actions'
import Rhombus from './Rhombus'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import Image from '@website/common/components/Image'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SocialLinks from '@website/common/components/SocialLinks'
import { isValidEmail, isValidPhoneNumber } from '@website/common/utils'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const Header47 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      socials,
      blocks,
      buttons,
      imageUrl,
      imgDimensions,
      imgCropParams,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const [navBarHeight, setNavBarHeight] = useState(0)
    const isBottomContnetExist =
      buttons.length ||
      blocks.length ||
      blocks.find(block => block.paragraph !== ' ')

    return (
      <S.ContentWrap>
        <S.StyledContainer
          backgroundImgUrl={backgroundImgUrl}
          backgroundImgDimensions={backgroundImgDimensions}
          bgOverlayTransparency={bgOverlayTransparency}
          isEditing={isEditing}
          isBottomContnetExist={isBottomContnetExist}
        >
          <NavBar
            handleHeightChange={setNavBarHeight}
            theme={THEME}
            logoProps={{
              logoSrc,
              logoColor,
              logoWidth,
              logoSizes: logoDimensions
            }}
          />
          <S.WmCustomContainer>
            <S.HeaderContent topOffset={navBarHeight}>
              <S.RightElements>
                <S.Lines />
                <S.SmallRhombusWrapper>
                  <Rhombus />
                </S.SmallRhombusWrapper>
              </S.RightElements>
              <S.LeftContent>
                <S.Trellis>
                  <div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                  <div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </S.Trellis>
                <S.RhombusWrapper>
                  <Rhombus />
                </S.RhombusWrapper>
                <S.ImageWrapper>
                  <S.ControlContainer isEditing={isEditing}>
                    <ControlsWithImageCropper
                      actions={Actions.getImgActions}
                      style={{ width: '100%' }}
                    >
                      <Image
                        as={S.Img}
                        sizes={imgDimensions}
                        defaultImgSrc={imageUrl}
                        asProps={{ imgCropParams }}
                        alt="Hero illustration"
                      />
                    </ControlsWithImageCropper>
                  </S.ControlContainer>
                </S.ImageWrapper>
              </S.LeftContent>
              <S.RightContent>
                <EditableContent
                  text={title}
                  as={S.Title}
                  required={!paragraph}
                  alignment={titleAlignment}
                  maxCount={validation.headingMaxChar}
                  className="WM_GLOBAL_heading42"
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  required={!title}
                  alignment={paragraphAlignment}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
                <SocialLinks
                  className="header-socials"
                  socials={socials}
                  socialsMaxCount={validation.socials.max}
                />
              </S.RightContent>
            </S.HeaderContent>
          </S.WmCustomContainer>
        </S.StyledContainer>
        <Show when={[isBottomContnetExist]}>
          <S.StyledContainerBottom>
            <S.WmBottomCustomContainer>
              <S.EmptyLeftContent />
              <S.ButtomContentWrapper>
                <S.ContactItemWrap isEditing={isEditing}>
                  <Mapper
                    data={blocks}
                    render={(block: string, idx: number) => (
                      <Show when={[block.paragraph]}>
                        <S.ItemWrap key={idx}>
                          <ControlsWithModalOpener
                            className="contact-item-control"
                            actions={Actions.blockActions({
                              idx,
                              showRemove: blocks.length > validation.blocks.min
                            })}
                            style={{ width: '100%' }}
                          >
                            <S.IconWrapper>
                              <ControlsWithModalOpener
                                actions={Actions.iconActions(idx)}
                              >
                                <S.Icon size="large" name={block.iconName} />
                              </ControlsWithModalOpener>
                            </S.IconWrapper>
                            <If
                              condition={
                                isEditing ||
                                (!isValidEmail(block.paragraph) &&
                                  !isValidPhoneNumber(block.paragraph))
                              }
                              otherwise={() => (
                                <S.A
                                  href={`${
                                    isValidEmail(block.paragraph)
                                      ? 'mailto:'
                                      : 'tel:'
                                  }${block.paragraph}`}
                                  className="WM_GLOBAL_paragraph18"
                                >
                                  {block.paragraph}
                                </S.A>
                              )}
                              then={() => (
                                <EditableContent
                                  as={S.BlockParagraph}
                                  text={block.paragraph}
                                  alignment={block.blockParagraphAlignment}
                                  maxCount={validation.blockParagraphMaxChar}
                                  isEditing={isEditing}
                                  className="WM_GLOBAL_paragraph18"
                                  onChange={(newParagraph: string) =>
                                    Actions.changeBlockParagraph(
                                      newParagraph,
                                      idx
                                    )
                                  }
                                  changeAlignment={(alignment: string) =>
                                    Actions.changeBlockParagraphAlignment(
                                      alignment,
                                      idx
                                    )
                                  }
                                />
                              )}
                            />
                          </ControlsWithModalOpener>
                        </S.ItemWrap>
                      </Show>
                    )}
                  />
                  <AddButton
                    onAdd={Actions.addBlock}
                    toShow={blocks.length < validation.blocks.max}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_item_label')}
                  </AddButton>
                </S.ContactItemWrap>
                <Buttons data={buttons} />
              </S.ButtomContentWrapper>
            </S.WmBottomCustomContainer>
          </S.StyledContainerBottom>
        </Show>
      </S.ContentWrap>
    )
  }
)

export default Header47
