/* @flow */
import {
  iconActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const removeSlide = slideIdx =>
    dispatcher({
      type: 'REMOVE_SLIDE',
      value: { slideIdx }
    })

  const addSlide = () =>
    dispatcher({
      type: 'ADD_SLIDE'
    })

  const changeSlideContent = (newContent: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_SLIDE_CONTENT',
      value: { newContent, idx }
    })

  const changeItemTitle = (newItemTitle: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_ITEM_TITLE',
      value: { newItemTitle, idx }
    })

  const changeItemParagraph = (newItemParagraph: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_ITEM_PARAGRAPH',
      value: { newItemParagraph, idx }
    })

  // const changeBlockTitleAlignment = (alignment: string, idx: number) => {
  //   dispatcher({
  //     type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
  //     value: { alignment, idx }
  //   })
  // }

  // const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
  //   dispatcher({
  //     type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
  //     value: { alignment, idx }
  //   })
  // }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const addBlock = () => dispatcher({ type: 'ADD_BLOCK' })

  const getBlockActions = (idx: number) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        dispatcher({
          type: 'REMOVE_BLOCK',
          value: idx
        })
      }
    }
  ]

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _addButton = () => dispatcher(addButton())

  return {
    addSlide,
    addBlock,
    removeSlide,
    changeItemTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    getBlockActions,
    changeSlideContent,
    changeItemParagraph,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    // changeBlockTitleAlignment,
    // changeBlockParagraphAlignment,
    getIconActions: iconActionMaker(dispatcher),
    getSliderActions: cropableImageModalActionMaker(dispatcher)
  }
}
