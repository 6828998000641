/* @flow */
import React, { useContext, memo } from 'react'
import S from './Portfolio9.styled'
import { validation } from './Portfolio9.consts'
import { DispatchContext, EditingContext } from '@contexts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { translate } from '@editor/common/utils/translations'

const Portfolio9 = memo(({ data: { blocks } }) => {
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <Mapper
          data={blocks}
          render={(block: string, idx: number) => {
            return (
              <S.BlockContainer key={idx}>
                <S.BlocksWrapper>
                  <S.Content>
                    <EditableContent
                      text={block.title}
                      alignment={block.titleAlignment}
                      as={S.Title}
                      maxCount={validation.titleMaxChar}
                      required={validation.blocks.title.required}
                      className="WM_GLOBAL_heading32"
                      onChange={(newTitle: string) => {
                        Actions.changeTitle(newTitle, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx)
                      }
                    />
                    <EditableContent
                      text={block.paragraph}
                      alignment={block.paragraphAlignment}
                      as={S.Paragraph}
                      maxCount={validation.paragraphMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(newParagraph: string) => {
                        Actions.changeParagraph(newParagraph, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockParagraphAlignment(alignment, idx)
                      }
                    />
                    <AdditionalLink
                      idx={idx}
                      additionalLinkText={block.additionalText}
                      additionalLinkUrl={block.additionalUrl}
                    />
                  </S.Content>
                  <Mapper
                    data={block.images}
                    render={(img: string, imgIdx: number) => {
                      return (
                        <S.ImageWrapper
                          className={'image-' + imgIdx}
                          key={imgIdx}
                        >
                          <S.ControlContainer>
                            <ControlsWithImageCropper
                              actions={Actions.imageActions(
                                idx,
                                imgIdx,
                                img.imgUrl
                              )}
                              style={{ width: '100%' }}
                            >
                              <Image
                                as={S.Img}
                                sizes={img.ImgDimensions}
                                defaultImgSrc={img.imgUrl}
                                asProps={{
                                  imgCropParams: img.imgCropParams
                                }}
                                alt="Portfolio image"
                              />
                            </ControlsWithImageCropper>
                          </S.ControlContainer>
                        </S.ImageWrapper>
                      )
                    }}
                  />
                </S.BlocksWrapper>
                <If
                  condition={isEditing}
                  then={() => (
                    <S.AddButtonsWrap>
                      <AddButton
                        onAdd={() => Actions.addBlock(idx)}
                        toShow={blocks.length < validation.blocks.max}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_block_label')}
                      </AddButton>
                      <AddButton
                        onAdd={() => {
                          Actions.removeBlock(
                            idx,
                            blocks.length > validation.blocks.min
                          )
                        }}
                        toShow={blocks.length > validation.blocks.min}
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        <Icon
                          name="glyph-remove"
                          className="icon"
                          size="normal"
                        />
                        {translate('remove_block_label')}
                      </AddButton>
                    </S.AddButtonsWrap>
                  )}
                />
              </S.BlockContainer>
            )
          }}
        />
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Portfolio9
