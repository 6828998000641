/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Text14.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  background-attachment: fixed !important;
  @media only screen and (max-width: 768px) {
    padding-top: ${({ isEditing }) => (isEditing && '150px' )};
    background-attachment: initial !important;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: initial;
  padding: 0;
  margin: 0px;
`
const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin: 0 auto;
  &:hover {
    ${({ theme }) =>
      theme.colorMode === 'EXTRALIGHT'
        ? css`
            &.dark-content:not(.warning-max):not(.warning-near) {
              box-shadow: 0px 0px 0px 1px white;
            }
          `
        : css`
            &.dark-content:not(.warning-max):not(.warning-near),
            &.light-content:not(.warning-max):not(.warning-near) {
              box-shadow: 0px 0px 0px 1px #363f5a;
            }
          `}
  }
  &:focus {
    ${({ theme }) =>
      theme.colorMode === 'EXTRALIGHT'
        ? css`
            &.dark-content:not(.warning-max):not(.warning-near) {
              outline: 1px solid white;
            }
          `
        : css`
            &.dark-content:not(.warning-max):not(.warning-near),
            &.light-content:not(.warning-max):not(.warning-near) {
              outline: 1px solid #363f5a;
            }
          `}
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-top: 0;
  width: 100%;
  margin-bottom: 20px;
  line-height: 1.4;
  &:hover {
    ${({ theme }) =>
      theme.colorMode === 'EXTRALIGHT'
        ? css`
            &.dark-content:not(.warning-max):not(.warning-near) {
              box-shadow: 0px 0px 0px 1px white;
            }
          `
        : css`
            &.dark-content:not(.warning-max):not(.warning-near),
            &.light-content:not(.warning-max):not(.warning-near) {
              box-shadow: 0px 0px 0px 1px #363f5a;
            }
          `}
  }
  &:focus {
    ${({ theme }) =>
      theme.colorMode === 'EXTRALIGHT'
        ? css`
            &.dark-content:not(.warning-max):not(.warning-near) {
              outline: 1px solid white;
            }
          `
        : css`
            &.dark-content:not(.warning-max):not(.warning-near),
            &.light-content:not(.warning-max):not(.warning-near) {
              outline: 1px solid #363f5a;
            }
          `}
  }
`

const TextCompWrap = styled.div`
  mask: url(https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/text14-3.4.0.svg)
    repeat-x -2px top;
  mask-size: auto 100%;
  background-color: ${getStyleForProp('blockBackground')};
  width: 100%;
  max-width: 4000px;
  padding: 80px 0;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  @media only screen and (max-width: 767px) {
    padding: 60px 0;
  }
`

const ContentWrap = styled.div`
  max-width: 1360px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 128px;
  margin: 0 auto;
  box-sizing: border-box;
  @media only screen and (max-width: 1440px) {
    padding: 0 100px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0 40px;
  }
  @media only screen and (max-width: 457px) {
    padding: 0 20px;
  }
  .link-section {
    margin-top: 40px;
    margin-left: 15px;
    .control-container {
      &:hover {
        ${({ theme }) =>
          theme.colorMode === 'EXTRALIGHT'
            ? css`
                box-shadow: 0px 0px 0px 1px white;
              `
            : css`
                box-shadow: 0px 0px 0px 1px #363f5a;
              `}
      }
      &:focus {
        ${({ theme }) =>
          theme.colorMode === 'EXTRALIGHT'
            ? css`
                outline: 1px solid white;
              `
            : css`
                outline: 1px solid #363f5a;
              `}
      }
    }
  }
  .link {
    color: ${getStyleForProp('heading')};
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('heading')};
    }
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;

    h1,
    p {
      margin-bottom: 0;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  StyledContainer,
  Title,
  Description,
  ContentWrap,
  TextCompWrap
}
