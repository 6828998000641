/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './FAQ2.styled'
import { validation } from './FAQ2.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import { DispatchContext, EditingContext } from '@contexts'
import QuestionIcon from '@website/common/assets/question-icon.svg'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const FAQ2 = memo(props => {
  const {
    data: { title, paragraph, titleAlignment, paragraphAlignment, faqs }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const isEditing = useContext(EditingContext)
  const [activeQuestionIdx, setActiveQuestionIdx] = useState(0)
  const expandAnswer = idx => () => {
    const isTheSame = idx === activeQuestionIdx
    setActiveQuestionIdx({ activeQuestionIdx: isTheSame ? null : idx })
  }

  const renderFaqCards = (data, isOdd, Actions) => {
    return (
      <>
        {data.map((faq: string, idx: number) => {
          return idx % 2 === isOdd ? (
            <S.FaqItemWrap key={idx}>
              <ControlsWithModalOpener
                actions={Actions.getFaqActions(idx, faqs.length)}
              >
                <S.FaqItemHeader onClick={expandAnswer(idx)}>
                  <S.Circle>
                    <S.Icon src={QuestionIcon} alt="Question icon" />
                  </S.Circle>
                  <EditableContent
                    as={S.Question}
                    text={faq.question}
                    maxCount={validation.maxQuestionChar}
                    required
                    className="WM_GLOBAL_heading20"
                    onChange={(newQuestion: string) => {
                      Actions.changeQuestion(newQuestion, idx)
                    }}
                  />
                </S.FaqItemHeader>
                <S.FaqContentWrapper toExpand={idx === activeQuestionIdx}>
                  <EditableContent
                    text={faq.answer}
                    as={S.FaqContent}
                    maxCount={validation.maxAnswerChar}
                    required
                    className="WM_GLOBAL_paragraph18"
                    onChange={(newAnswer: string) => {
                      Actions.changeAnswer(newAnswer, idx)
                    }}
                  />
                </S.FaqContentWrapper>
              </ControlsWithModalOpener>
            </S.FaqItemWrap>
          ) : null
        })}
      </>
    )
  }

  return (
    <S.StyledContainer isEditing={isEditing}>
      <WMContainer>
        <S.FaqContainer>
          <Show when={[title, paragraph]}>
            <S.FaqHeader>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  as={S.Title}
                  maxCount={validation.maxTitleChar}
                  onChange={Actions.changeTitle}
                  className="WM_GLOBAL_heading"
                  changeAlignment={Actions.changeTitleAlignment}
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  alignment={paragraphAlignment}
                  as={S.Paragraph}
                  maxCount={validation.maxParagraphChar}
                  onChange={Actions.changeParagraph}
                  className="WM_GLOBAL_paragraph"
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
            </S.FaqHeader>
          </Show>
          <S.FaqsWrapper>
            <div>{renderFaqCards(faqs, 0, Actions)}</div>
            <div>{renderFaqCards(faqs, 1, Actions)}</div>
          </S.FaqsWrapper>
          <AddButton
            onAdd={Actions.addFaq}
            toShow={faqs.length < validation.faqs.max}
            style={{ marginTop: '30px' }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_faq_label')}
          </AddButton>
        </S.FaqContainer>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default FAQ2
