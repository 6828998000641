/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions
        draft.imgCropParams = {}
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
      }
    }
  })
}
