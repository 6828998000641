/* @flow */
// TODO: Find new solution to improve code structure
import { patchProjectDataAPI } from '@api'
import { revisionActions, visualParamsActions, commonActions } from './consts'
import {
  getSiteId,
  getProjectData,
  selectIsUserAuthorized,
  selectIsWebsiteMultilingual,
  selectWebsiteActiveLanguageCode
} from '@selectors'
import { getDebouncedRevisionAction } from './utils'

const debouncedSendRevision = getDebouncedRevisionAction()
const revisionAndCommonActions = [...revisionActions, ...commonActions]

export const sendRevisionMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const returnValue = next(action)
    const state = getState()
    const isUserAuthorized = selectIsUserAuthorized(state)
    const { type, undoable, needToSendRevision = true } = action

    if (!needToSendRevision || !isUserAuthorized) {
      return returnValue
    }

    const siteId = getSiteId(state)
    const projectData = getProjectData(state)
    const isWebsiteMultilingual = selectIsWebsiteMultilingual(state)
    const lang = isWebsiteMultilingual
      ? selectWebsiteActiveLanguageCode(state)
      : ''
    const { visualParams, ..._projectData } = projectData || {}

    const isActionCompatible =
      (revisionAndCommonActions.find(
        actionType => ~(type || '').indexOf(actionType)
      ) ||
        undoable) &&
      !visualParamsActions.includes(type)

    if (isActionCompatible) {
      const params = {
        siteId,
        data: { data: _projectData },
        dispatch,
        debouncedCb: patchProjectDataAPI,
        params: { lang }
      }
      debouncedSendRevision(params)
    }

    return returnValue
  }
