/* @flow */
import React, { PureComponent } from 'react'
import * as moment from 'moment'
import DayPicker from 'react-day-picker'
import Icon from '@website/common/components/Icon'
import { getFormatedDate } from '../utils'
import If from '@website/common/components/Conditional'
import { TDatePickerInputState, TDatePickerInputProps } from '../types'
import * as S from '../styled.js'
import { secondary } from '@website/common/styles/colors'
import { translate } from '@editor/common/utils/translations'

class DatePickerInput extends PureComponent<
  TDatePickerInputProps,
  TDatePickerInputState
> {
  state = {
    isDayPickerOpen: false
  }
  datePicker: null
  timePickerContainer: null
  componentDidMount() {
    window.addEventListener('click', this.onBackdropClick)
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.onBackdropClick)
  }

  onBackdropClick = (e: any) => {
    if (
      !this.datePickerContainer ||
      !this.datePicker ||
      this.datePickerContainer.contains(e.target) ||
      this.datePicker.contains(e.target)
    ) {
      return
    }
    this.setState({ isDayPickerOpen: false })
  }
  isPastDate = date => moment().diff(date.toISOString(), 'days') > 0

  render() {
    const { isDayPickerOpen } = this.state
    const { onDaySelect, day, waitingForLink } = this.props
    return (
      <div className="day-item-container">
        <p className="date-label">{translate('expiry_date_label')}</p>
        <S.DateInput
          disabled={waitingForLink}
          className="input-container"
          ref={ref => (this.datePickerContainer = ref)}
          onClick={() =>
            !waitingForLink && this.setState({ isDayPickerOpen: true })
          }
        >
          <S.InputText selected={!!day}>
            {day ? getFormatedDate(day) : translate('select_date_label')}
          </S.InputText>
          <Icon
            color={secondary.SemiLight}
            size="middle"
            name="icon-calendar"
            className="date-icon"
          />
        </S.DateInput>
        <If
          condition={isDayPickerOpen}
          then={() => (
            <div
              className="dayPicker"
              ref={ref => {
                this.datePicker = ref
              }}
            >
              <DayPicker
                selectedDays={day || null}
                onDayClick={day => {
                  if (this.isPastDate(day)) {
                    return
                  }
                  this.isPastDate(day)
                  this.setState({ isDayPickerOpen: false })
                  onDaySelect(day)
                }}
                disabledDays={{ before: new Date() }}
              />
            </div>
          )}
        />
      </div>
    )
  }
}

export default DatePickerInput
