/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Testimonials7.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  .quote_svg__from {
    stop-color: ${({ theme }) => theme.palette.primaryLight};
  }
  .quote_svg__to {
    stop-color: ${({ theme }) => theme.palette.primaryDark};
  }
  .testimonials7-slider {
    .slick-prev {
      left: -60px;
      @media only screen and (max-width: 1590px) {
        left: -25px;
      }
    }
    .slick-next {
      right: -30px;
      @media only screen and (max-width: 1590px) {
        right: -25px;
      }
    }
  }
`
export const WmCustomContainer = styled(WMContainer)`
  display: flex;
`

export const Card = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('cardRadius')};
  background: ${getStyleForProp('cardBackgroundGradient')};
  backdrop-filter: blur(15px);
  box-shadow: ${({ theme }) =>
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  padding: 30px 0 70px 80px;
  box-sizing: border-box;
  > :first-child {
    padding-top: 0;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-items: center;
    padding: 50px 0 70px 0;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    justify-items: center;
    padding-top: 30px;
    padding-bottom: 70px;
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-right: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    margin-bottom: 0;
    margin-right: 0;
    padding-right: 70px;
    padding-left: 70px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`
export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
  &:after {
    z-index: 9;
  }
  @media only screen and (max-width: 1024px) {
    margin-bottom: 0;
    margin-right: 0;
    padding-right: 70px;
    padding-left: 70px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 25px;
    padding-left: 25px;
  }
`

export const ClientWrap = styled.div`
  display: flex;
  align-items: center;
  transition: transform 0.5s linear;
  padding: 40px 30px 0 30px;
  position: relative;
  @media only screen and (max-width: 650px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const ImageFrame = styled.div`
  width: 80px;
  min-width: 80px;
  margin-right: 20px;
  .control-container {
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    background-color: ${getStyleForProp('cardBackground')};
    border-radius: ${getStyleForProp('iconRadius')};
    border: 1px solid ${getStyleForProp('border')};
    overflow: hidden;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

export const Name = styled.h2`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: ${({ isEditing }) => (isEditing ? '15px' : '10px')};
  width: 100%;
  ${getTextStyleByAlignment};
`

export const Position = styled.h3`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
`

export const BlockItem = styled.div`
  margin: 20px;
  flex-grow: 1;
  border-radius: ${getStyleForProp('cardRadius')};
  background-color: ${getStyleForProp('cardBackground')};
  border: 1px solid ${getStyleForProp('border')};
  @media only screen and (max-width: 1366px) {
    margin: 15px;
  }
  @media only screen and (max-width: 992px) {
    margin: 20px 15px;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
  }
  + .styled-controls {
    z-index: 5;
  }
`

export const SliderContainer = styled.div`
  ${({ isEditing }) =>
    isEditing
      ? css`
          .slick-initialized {
            .slick-slide {
              @media only screen and (min-width: 651px) {
                &:not(.slick-center) {
                  pointer-events: none;
                }
              }
            }
          }
        `
      : css``}
  position: relative;
  > div {
    width: 100% !important;
    margin-right: 0 !important;
    max-width: 850px;
    @media only screen and (max-width: 1440px) {
      max-width: 686px !important;
    }
    @media only screen and (max-width: 1240px) {
      max-width: 415px !important;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 900px !important;
    }
    @media only screen and (max-width: 490px) {
      max-width: 385px !important;
    }
  }
  .slick-track {
    display: flex !important;
  }
  .slick-initialized {
    .slick-slide {
      width: 415px !important;
      @media only screen and (max-width: 1366px) {
        width: 415px !important;
      }
      @media only screen and (max-width: 1024px) {
        width: 385px !important;
        max-width: 385px !important;
        padding-top: 30px;
      }
      @media only screen and (max-width: 650px) {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 40px;
      }
      @media only screen and (max-width: 490px) {
        max-width: 305px !important;
      }
      @media only screen and (min-width: 651px) {
        &:not(.slick-center) {
          margin-top: 15px;
          margin-bottom: 15px;
          opacity: 0.6;
          position: relative;
          &:before {
            content: '';
            position: absolute;
            display: inline-block;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 5;
            cursor: pointer;
          }
        }
        &.slick-center {
          ${BlockItem} {
            box-shadow: 0px 6px 12px rgba(13, 3, 28, 0.149);
          }
          .block-paragraph-wrap {
            padding-bottom: 50px;
          }
          .client-wrap {
            padding-top: 50px;
          }
          .block-item {
            box-shadow: 0px 6px 20px rgba(13, 3, 28, 0.149);
          }
        }
      }
      > div {
        margin: 0;
        z-index: 20;
      }
    }
  }
  .slick-dots {
    bottom: -30px;
    @media only screen and (max-width: 650px) {
      bottom: -40px;
    }
    button {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: ${({ theme }) =>
        `rgba(${hexToRgb(getStyleForProp('paragraph')({ theme }))}, 0.5)`};
      &:before {
        color: transparent;
      }
    }
    .slick-active {
      button {
        background: ${({ theme }) =>
          `linear-gradient(0, ${theme.palette.primaryDark}, ${theme.palette.primaryLight})`};
        &:before {
          color: transparent;
        }
      }
    }
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
  }
`

export const SlideCountContainer = styled.p`
  font-size: 16px;
  color: ${getStyleForProp('heading')};
  margin: 50px auto 20px auto;
  text-align: center;
`

export const BlockItemWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .rf-add-btn {
    min-width: 136px;
    box-sizing: border-box;
    margin-right: 20px;
  }
`
export const ItemParagraphWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  position: relative;
  padding: ${({ isEditing, isParagraphOnly }) =>
    !isEditing && isParagraphOnly ? '0 30px 30px 30px' : '30px'};
  @media only screen and (max-width: 650px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const ItemParagraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
`

export const AddBtnWrapper = styled.div``

export const TextContent = styled.div`
  width: 30%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media only screen and (min-width: 1025px) {
    margin-right: 80px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    min-width: initial;
    justify-items: center;
    align-items: center;
  }
  .control-container {
    margin-bottom: 20px;
    h1 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

export const SliderContent = styled.div`
  width: 70%;
  max-width: 850px;
  @media only screen and (max-width: 1440px) {
    max-width: 686px;
  }
  @media only screen and (max-width: 1300px) {
    max-width: 415px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 900px;
  }
  @media only screen and (max-width: 490px) {
    max-width: 385px;
  }
`

export const HeadingContentWrapper = styled.div`
  width: 100%;
  .control-container {
    width: 100%;
    .styled-controls {
      @media only screen and (max-width: 500px) {
        right: 0;
        left: initial;
      }
    }
  }
`

export const QuoteWrapper = styled.div`
  width: 80px;
  margin-bottom: 35px;
  @media only screen and (max-width: 650px) {
    width: 50px;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`
