import { useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { EDIT_BASE, PAGE_BASE } from '@editor/conf/routes'
import { useHistoryWithSearch } from '@hooks'
import { selectIsUserAuthorized } from '@selectors'
import { openLoginPopup } from '@actions/editor'

const useEventSettingsOpener = (tabName = 'details') => {
  const isUserAuthorized = useSelector(selectIsUserAuthorized)
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(EDIT_BASE) || {}
  const { params } = useRouteMatch(PAGE_BASE) || {}
  const dispatch = useDispatch()
  const page = params?.page || 'home'

  const openDashboard = useCallback(
    activeInputClassName => {
      if (!isUserAuthorized) {
        dispatch(openLoginPopup())
        return
      }

      history.push({
        pathname: `${url}/${page}/event-settings/${tabName}`,
        state:
          typeof activeInputClassName === 'string'
            ? { activeInputClassName }
            : {}
      })
    },
    [page, url]
  )

  const editSubtitle = useCallback(() => {
    openDashboard('subTitle')
  }, [openDashboard])

  const editTitle = useCallback(() => {
    openDashboard('title')
  }, [openDashboard])

  const editParagraph = useCallback(() => {
    openDashboard('paragraph')
  }, [openDashboard])

  const editLocation = useCallback(() => {
    openDashboard('location')
  }, [openDashboard])

  const editDate = useCallback(() => {
    openDashboard('search-dropdown-active-item')
  }, [openDashboard])

  const editButton = useCallback(() => {
    openDashboard('rsvp-button')
  }, [openDashboard])

  return {
    openDashboard,
    editSubtitle,
    editTitle,
    editParagraph,
    editLocation,
    editDate,
    editButton
  }
}

export default useEventSettingsOpener
