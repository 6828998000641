import styled from 'styled-components'
import { List as CommonList } from '../../components'

export const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #d2e0fc;
`

export const List = styled(CommonList)`
  width: 24px;
  height: 24px;
  background-color: transparent;
`

export const LanguageIcon = styled.img`
  width: 24;
  height: auto;
`
