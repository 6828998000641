import styled from 'styled-components'
import { primary } from '@website/common/styles/colors'

export const HelpButton = styled.a`
  font-size: 14px;
  border-radius: 5px;
  background: none;
  color: ${primary.Dark};
  text-decoration: none;
  user-select: none;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  font-weight: 500;
  &:hover {
    background-color: #eef5ff;
  }
  .question-icon {
    background-color: ${primary.Dark};
    border-radius: 50%;
    padding: 2px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 15px 20px;
    background: none;
  }
`

export const QuestionIconWrapper = styled.div`
  border-radius: 5px;
  margin-right: 5px;
  display: flex;
  @media only screen and (max-width: 1024px) {
    background-color: #eef5ff;
    margin-right: 10px;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
  }
`
