import React from 'react'
import { translate } from '@editor/common/utils/translations'
import { BoldComp } from '@editor/common/styled-components/simple-tags'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import {
  CARDS_STATIC_PROPS,
  TARIFF_PLAN_IDS,
  MONTHLY_RECURRENCE_VALUE,
  YEARLY_RECURRENCE_VALUE
} from './consts'

export const formatBytes = bytes => {
  if (!bytes) return { size: 0, type: 'BYTES' }

  const k = 1024
  const types = ['BYTES', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  const size = bytes / Math.pow(k, i)
  const needToFixDecimal = size % 1 !== 0

  return {
    size: needToFixDecimal ? size.toFixed(2) : size,
    type: types[i]
  }
}

export const getStorageSize = storageSizeMb => {
  const { size } = formatBytes(storageSizeMb * 1024 * 1024)
  return `${size}`
}

export const addSpacesToPrice = price =>
  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const getPremiumWebsiteOptions = tariffPlan => {
  const { storageSize, rsvpGuestLimit } = tariffPlan

  return [
    {
      str: transformTextVariables(translate('rsvp_submissions_count_label'), [
        {
          value: rsvpGuestLimit,
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('custom_domain_label'), [
        {
          value: translate('custom_domain_label_tr1'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('gb_storage_label'), [
        {
          value: `${getStorageSize(storageSize)} ${translate(
            'gb_storage_label_tr1'
          )}`,
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('no_branded_footer_label'), [
        {
          value: translate('no_branded_footer_label_tr1'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('advanced_marketing_tools'), [
        {
          value: translate('advanced_marketing_tools_tr1'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('free_ssl_cert_label_separated'), [
        {
          value: translate('free_ssl_cert_label_separated_tr1'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('unlimited_wizard_usages'), [
        {
          value: translate('unlimited_label'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('multilingual_website_label'), [
        {
          value: translate('multilingual_website_label_tr1'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('gdpr_compliance'), [
        {
          value: translate('gdpr_compliance_tr1'),
          Comp: BoldComp
        }
      ])
    }
  ]
}

export const getProOptions = (tariffPlan, showFullProOptions) => {
  const { storageSize, rsvpGuestLimit } = tariffPlan

  const RSVP = {
    str: transformTextVariables(translate('rsvp_submissions_count_label'), [
      {
        value: rsvpGuestLimit,
        Comp: BoldComp
      }
    ])
  }

  const DOMAIN = {
    str: transformTextVariables(translate('custom_domain_label'), [
      {
        value: translate('custom_domain_label_tr1'),
        Comp: BoldComp
      }
    ])
  }

  const GDPR = {
    str: transformTextVariables(translate('gdpr_compliance'), [
      {
        value: translate('gdpr_compliance_tr1'),
        Comp: BoldComp
      }
    ])
  }

  const HD_VIDEOS = {
    str: transformTextVariables(translate('unlimited_hd_videos'), [
      {
        value: translate('unlimited_hd_videos_tr1'),
        Comp: BoldComp
      }
    ])
  }

  const STORAGE = {
    str: transformTextVariables(translate('gb_storage_label'), [
      {
        value: `${getStorageSize(storageSize)} ${translate(
          'gb_storage_label_tr1'
        )}`,
        Comp: BoldComp
      }
    ])
  }

  const LOGOS = {
    str: transformTextVariables(translate('hq_logos_label'), [
      {
        value: translate('hq_logos_label_tr1'),
        Comp: BoldComp
      }
    ])
  }

  const MOCKUPS = {
    str: transformTextVariables(translate('hq_mockups_label'), [
      {
        value: translate('hq_logos_label_tr1'),
        Comp: BoldComp
      }
    ])
  }

  const GRAPHIC_DESIGN = {
    str: transformTextVariables(translate('premium_graphic_design_label'), [
      {
        value: translate('premium_graphic_design_label_tr1'),
        Comp: BoldComp
      }
    ])
  }

  const FONTS = {
    str: transformTextVariables(translate('unlimited_font_uploads'), [
      {
        value: translate('unlimited_font_uploads_tr1'),
        Comp: BoldComp
      }
    ])
  }

  return showFullProOptions
    ? [RSVP, STORAGE, DOMAIN, GDPR, HD_VIDEOS, LOGOS, MOCKUPS, GRAPHIC_DESIGN]
    : [HD_VIDEOS, STORAGE, LOGOS, MOCKUPS, GRAPHIC_DESIGN, FONTS]
}

export const getBusinessPlanOptions = tariffPlan => {
  const { storageSize } = tariffPlan

  return [
    {
      str: transformTextVariables(translate('rsvp_submissions_count_label'), [
        {
          value: translate('unlimited_label'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('gb_storage_label'), [
        {
          value: `${getStorageSize(storageSize)} ${translate(
            'gb_storage_label_tr1'
          )}`,
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('unlimited_uhd_video_label'), [
        {
          value: translate('unlimited_label'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('unlimited_graphics_label'), [
        {
          value: translate('unlimited_label'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('branding_guideline_label'), [
        {
          value: translate('guideline_label'),
          Comp: BoldComp
        }
      ])
    },
    {
      str: transformTextVariables(translate('reseller_license_label'), [
        {
          value: translate('license_label'),
          Comp: BoldComp
        }
      ])
    }
  ]
}

const getOptionsByTariffId = (id, tariffPlans, showFullProOptions) => {
  const tariffPlanData = tariffPlans[id]
  if (!tariffPlanData) {
    return []
  }

  switch (id) {
    case TARIFF_PLAN_IDS.premium:
      return getPremiumWebsiteOptions(tariffPlanData)
    case TARIFF_PLAN_IDS.pro:
      return getProOptions(tariffPlanData, showFullProOptions)
    case TARIFF_PLAN_IDS.business:
      return getBusinessPlanOptions(tariffPlanData)
    default:
      return []
  }
}

const getPriceDataByTariffId = (id, isYearly, tariffPlans) => {
  const priceData = tariffPlans[id]?.priceData
  if (!priceData) {
    return {}
  }
  const priceByRecurrence = isYearly ? priceData.yearly : priceData.monthly

  return {
    currencySymbol: priceData.currencySymbol || '$',
    price: priceByRecurrence.price || priceByRecurrence.priceUsd || 0,
    discountedPrice:
      priceByRecurrence.discountedPrice ||
      priceByRecurrence.discountedPriceUsd ||
      0
  }
}

const getUpgradePriceData = (id, isYearly, upgradingPrices) => {
  const recurrenceValue = isYearly
    ? YEARLY_RECURRENCE_VALUE
    : MONTHLY_RECURRENCE_VALUE
  const upgradingPrice = upgradingPrices.find(
    price => price.toPlanId === id && price.toRecurrence === recurrenceValue
  )

  if (!upgradingPrice) {
    return {}
  }

  const currencySymbol =
    window.CURRENCY_SYMBOLS[upgradingPrice.currency || 'USD']

  return {
    currencySymbol,
    price: upgradingPrice.amount,
    discountedPrice: 0
  }
}

export const getCardsData = ({
  tariffPlans,
  tariffPlanIds,
  upgradingPrices,
  isUpgrade,
  isYearly,
  isFullProOptionsVisible,
  selectedTariffId,
  setSelectedTariffId,
  onUpgradeBtnClick
}) => {
  return tariffPlanIds.map(id => {
    const staticProps = CARDS_STATIC_PROPS[id]
    const isSelected = selectedTariffId === staticProps.tariffId
    const isPremiumOptionsLabelVisible =
      id === TARIFF_PLAN_IDS.pro && !isFullProOptionsVisible

    const priceData = isUpgrade
      ? getUpgradePriceData(id, isYearly, upgradingPrices)
      : getPriceDataByTariffId(id, isYearly, tariffPlans)

    return {
      ...staticProps,
      isYearly,
      isSelected,
      isPremiumOptionsLabelVisible,
      options: getOptionsByTariffId(id, tariffPlans, isFullProOptionsVisible),
      priceData,
      setSelectedTariffId,
      onUpgradeBtnClick: () => onUpgradeBtnClick(id)
    }
  })
}
