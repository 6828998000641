/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Feature4_2.theme'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import {
  getBackground,
  getTextStyleByAlignment,
  styleProvider
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const stylesByColor = {
  default: '',
  dark: css`
    filter: brightness(0.22);
  `,
  light: css`
    filter: brightness(1);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};

  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }

  @media only screen and (max-width: 457px) {
    ${({ theme }) =>
      css`
        padding-bottom: ${theme.orientation === 'LEFT' ? '45px' : '60px'};
      `}
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 992px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }
`

const TextsContent = styled.div`
  width: 100%;
  > p:first-child {
    margin-top: 0;
  }
  & > .control-container {
    margin-top: 30px;
    width: 100%;
    h1,
    p {
      margin-top: 0;
      margin-left: 0;
    }
  }
`

const ListWrap = styled.ul`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 40px 0 0 15px;
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 30px;
  }
`
const ListItemWrap = styled.line`
  display: flex;
  align-items: center;
`

const ImageWrap = styled.div`
  width: 48%;
  @media only screen and (max-width: 992px) {
    width: 100%;
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT' ? '0 0 40px 0' : '40px 0 0 0'};
      `}
  }
  @media only screen and (max-width: 600px) {
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'LEFT'
          ? '0 auto'
          : ' 0 auto 30px auto'};
      `}
  }
`

const ImageFigure = styled.div`
  text-align: center;
  position: relative;
  .feature4_2_morph {
    & > g {
      g {
        fill: ${({ theme }) => theme.palette.primaryDark};
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .feature4_2_morph {
      width: 100%;
      @media only screen and (max-width: 650px) {
        transform: scale(1.08);
      }
    }
  }
`

const Content = styled.div`
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 1440px) {
    .buttons-block {
      ${({ theme }) =>
        css`
          ${theme.orientation === 'RIGHT'
            ? 'margin-left: -15px !important;'
            : 'margin-left: -15px !important;'};
        `}
    }
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    align-items: center;
    .buttons-block {
      justify-content: center;
      margin: 0 auto !important;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 600px) {
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT'
          ? '0 auto -10px auto'
          : '0 auto 30px auto'};
      `}
  }
  > div:first-child {
    padding-top: 0;
    ${({ theme }) =>
      css`
        ${theme.orientation === 'RIGHT'
          ? 'margin-left: -15px !important;'
          : ''};
      `}
    @media only screen and (max-width: 992px) {
      margin-left: 0 !important;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-left: 15px;
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-left: 15px;
  margin-top: 20px;
  width: 100%;
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
`

const ListItem = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding: 10px;
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('icon')
}))`
  padding-right: 10px;
  min-width: 34px;
`

const IconWrap = styled.div`
  width: 360px;
  height: 360px;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  background: ${({ theme }) => theme.palette.accentWhite};
  color: ${({ theme }) => theme.palette.primaryDark};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 500px) {
    width: 280px;
    height: 280px;
  }
`

const Phone = styled.div`
  width: 248px;
  min-width: 248px;
  position: relative;
  padding: 16px 24px 40px 24px;
  box-sizing: border-box;
  border-radius: 38px;
  font-size: 0;
  min-height: 470px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -48%);
  &:before {
    content: ' ';
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    border-radius: 24px;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header-8-mobile-mockup-1.png');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  &:after {
    content: '';
    width: 45%;
    height: 35px;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-54%);
    z-index: 1;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/phone-dinamic-min.png');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  .control-container {
    width: 100%;
  }
  .lazy-figure {
    width: 100%;
    position: relative;
    padding-top: 200%;
    border-radius: 24px;
  }
  .styled-controls {
    top: -55px;
    left: 50%;
    transform: translate(-50%);
  }
  .image-state-overlay,
  .failed-image-container {
    z-index: 1;
  }
`
const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextsContent,
  ImageWrap,
  ImageFigure,
  Content,
  ListWrap,
  Title,
  Description,
  ListItem,
  ListItemWrap,
  IconWrap,
  Icon,
  Phone,
  MobileImg
}
