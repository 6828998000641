// @flow

import React from 'react'

import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import * as Styled from './styled'
import { HELP_SUPPORT_LINK } from '@editor/conf/consts'
import { translate } from '@editor/common/utils/translations'

const HelpAndSupportButton = () => {
  return (
    <Styled.HelpButton role="button" href={HELP_SUPPORT_LINK} target="_blank">
      <Styled.QuestionIconWrapper>
        <Icon
          name="question"
          size="xxxSmall"
          color="white"
          className="question-icon"
        />
      </Styled.QuestionIconWrapper>
      <span>{translate('need_help_label')}</span>
    </Styled.HelpButton>
  )
}

export default HelpAndSupportButton
