import styled, { css } from 'styled-components'

const getDisabledButtonStyles = ({ disabled }) =>
  disabled
    ? css`
        &:before {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.4);
        }
      `
    : ''

export const MediaUploadButtonWrapper = styled.div`
  position: relative;
  ${getDisabledButtonStyles};
  .button-text {
    user-select: none;
  }
`
