/* @flow */
import React, { memo, useCallback, useContext } from 'react'
import { EditingContext } from '@contexts'

import S from './RSVPRadioButton.styled'
import type { TRSVPRadioButton } from './TRSVPRadioButton.types'

const RSVPRadioButton = memo(
  ({
    value,
    label,
    isChecked,
    labelClassName = '',
    onChange
  }: TRSVPRadioButton) => {
    const handleChange = useCallback(
      event => {
        const { checked } = event.target
        onChange(value, checked)
      },
      [onChange, value]
    )

    const { isEditing } = useContext(EditingContext)

    return (
      <S.RadioButtonWrap>
        <S.Input
          type="radio"
          id={value}
          value={value}
          name="options"
          checked={isChecked}
          onChange={handleChange}
        />
        <S.Label
          htmlFor={value}
          className={labelClassName}
          isEditing={isEditing}
        >
          {label}
        </S.Label>
      </S.RadioButtonWrap>
    )
  }
)

export default RSVPRadioButton
