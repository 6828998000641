/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header14.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  padding: 0 0 80px 0;
  margin-bottom: 0;
  @media only screen and (max-width: 457px) {
    padding: 0 0 60px 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const HeaderContent = styled.div`
  border: 6px solid ${getStyleForProp('contentBorder')};
  padding: 40px 40px 20px 40px;
  box-sizing: border-box;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: 550px) {
    border-width: 3px;
  }
  @media only screen and (max-width: 430px) {
    padding: 20px 20px 5px 20px;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
    }
  }

  & > .control-container:nth-child(1) {
    margin-bottom: 20px;
    & > h1 {
      margin: 0;
    }
  }

  & > .control-container:nth-child(2) {
    display: block;
    margin-bottom: 30px;
    & > h2 {
      max-width: unset;
      margin-bottom: 0;
    }
    @media only screen and (max-width: 430px) {
      max-width: initial;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 20px;
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  margin-bottom: 30px;
  @media only screen and (max-width: 430px) {
    max-width: initial;
  }
`

export default {
  WmCustomContainer,
  StyledContainer,
  HeaderContent,
  Title,
  Description
}
