/* @flow */
import React, { useContext, memo } from 'react'
import S from './Email3.styled'
import { validation } from './Email3.consts'
import THEME from './Email3.theme'
import ActionGetter from './Actions'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import If from '@website/common/components/Conditional'
import EditableContent from '@website/common/components/EditableContent'
import WMContainer from '@website/common/components/WMContainer'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { SubscribeForm } from '../SubscribeForm'
import { DispatchContext, EditingContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const Email3 = memo(props => {
  const {
    data: {
      imgUrl,
      imgDimensions,
      imgCropParams,
      title,
      paragraph,
      placeholder,
      titleAlignment,
      paragraphAlignment,
      buttonText,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isImageExists={imgUrl}
    >
      <WMContainer>
        <S.FormContainer isImageExists={imgUrl} isEditing={isEditing}>
          <S.EmailContent>
            <If
              condition={imgUrl}
              then={() => (
                <S.ImageFrame>
                  <S.ImageWrapper>
                    <ControlsWithImageCropper
                      actions={Actions.getImgActions(imgUrl)}
                      style={{
                        height: '100%'
                      }}
                    >
                      <Image
                        as={S.Img}
                        alt="Email form image"
                        sizes={imgDimensions}
                        defaultImgSrc={imgUrl}
                        asProps={{
                          imgCropParams
                        }}
                      />
                    </ControlsWithImageCropper>
                  </S.ImageWrapper>
                </S.ImageFrame>
              )}
            />
            <If
              condition={isEditing}
              then={() => (
                <S.AddBtnWrapper>
                  <AddButton onAdd={Actions.addImage} toShow={!imgUrl}>
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_image_label')}
                  </AddButton>
                </S.AddBtnWrapper>
              )}
            />
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleChar}
              onChange={Actions.changeTitle}
              required={!paragraph}
              className="WM_GLOBAL_heading32"
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.maxParagraphChar}
              onChange={Actions.changeParagraph}
              required={!title}
              className="WM_GLOBAL_paragraph18"
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.EmailContent>
          <S.FormWrap>
            <SubscribeForm
              actions={{
                buttonActions: Actions.getBtnActions(buttonText),
                placeHolderAction: Actions.changePlaceHolderText
              }}
              buttonText={buttonText}
              placeholder={placeholder}
              theme={THEME}
            />
          </S.FormWrap>
        </S.FormContainer>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Email3
