/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_NAME':
        draft.team[action.value.idx].name = action.value.newName
        break
      case 'CHANGE_POSITION':
        draft.team[action.value.idx].position = action.value.newPosition
        break
      case 'CHANGE_ITEM_PARAGRAPH':
        draft.team[action.value.idx].paragraph = action.value.paragraph
        break
      case 'CHANGE_IMAGE':
        {
          const { teamIdx, url, dimensions } = action.value
          draft.team[teamIdx].imgUrl = url
          draft.team[teamIdx].imgDimensions = dimensions
          draft.team[teamIdx].imgCropParams = {}
        }
        break
      case 'ADD_MEMBER':
        draft.team.push(initialState.team[0])
        break
      case 'ADD_SOCIAL':
        draft.team[action.value].socials.push(initialState.team[0].socials[0])
        break
      case 'REMOVE_SOCIAL':
        draft.team[action.value.teamIdx].socials.splice(action.value.idx, 1)
        break
      case 'REMOVE_MEMBER':
        draft.team.splice(action.value, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { link, idx, teamIdx } = action.value
          draft.team[teamIdx].socials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { teamIdx, idx, iconName } = action.value
          draft.team[teamIdx].socials[idx].icon = iconName
        }
        break
      case 'SET_IMG_CROP_PARAMS':
        const { imgCropParams, teamIdx } = action.value
        draft.team[teamIdx].imgCropParams = imgCropParams
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NAME_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.team[idx].nameAlignment = alignment
        break
      }
      case 'CHANGE_POSITION_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.team[idx].positionAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.team[idx].blockParagraphAlignment = alignment
        break
      }
    }
  })
}
