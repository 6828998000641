/* @flow */
import { validation } from '../Contact1.consts'
import { socialActionMaker } from '@website/components/ActionMakers'
import {
  addSocial,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeEmailTitle = (newEmailTitle: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_EMAIL_TITLE',
      value: { newEmailTitle, idx }
    })

  const changeEmail = (newEmail: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_EMAIL',
      value: { newEmail, idx }
    })

  const removeEmail = (idx: number) =>
    dispatcher({
      type: 'REMOVE_EMAIL',
      value: idx
    })

  const addEmail = () =>
    dispatcher({
      type: 'ADD_EMAIL'
    })

  const changeSocialTitle = (title: string) =>
    dispatcher({
      type: 'CHANGE_SOCIAL_TITLE',
      value: title
    })

  const _addSocial = () => dispatcher(addSocial())

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addEmail,
    changeEmail,
    changeEmailTitle,
    changeSocialTitle,
    addSocial: _addSocial,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    getSocialActions: socialActionMaker(dispatcher),
    contactActions: (idx: number, emailCount: number) => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => removeEmail(idx),
        toShow: emailCount > validation.emails.min
      }
    ]
  }
}
