/* @flow */
import styled from 'styled-components'
import THEME from './Soundcloud7.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
`

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '100px 0 80px 0' : '80px 0')};
  @media only screen and (max-width: 1300px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : '80px 0')};
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
`

const TextContent = styled.div`
  width: 100%;
  max-width: 830px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 40px;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 20px;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const MusicWrapper = styled.div`
  width: 700px;
  min-width: 700px;
  font-size: 0;
  @media only screen and (max-width: 800px) {
    width: 100%;
    min-width: initial;
    &:only-child {
      margin: 0;
    }
  }
`

const MusicContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: hidden;
  position: relative;
  width: 100%;
`

const IframeWrapper = styled.div`
  width: 100%;
  position: relative;
  iframe {
    width: 100%;
    height: 250px;
    @media only screen and (max-width: 767px) {
      height: 350px;
    }
  }
`

export default {
  WmCustomContainer,
  StyledContainer,
  Title,
  Paragraph,
  MusicContent,
  IframeWrapper,
  TextContent,
  MusicWrapper
}
