//@flow
import React, { useState, useCallback, useContext } from 'react'
import { connect } from 'react-redux'

import { openLoginPopup } from '@actions/editor'
import { BackgroundSidebarContext } from '@contexts'
import ReplaceIcon from '@editor/common/assets/svgr-icons/replace_icon.svg'
import TransparencyIcon from '@editor/common/assets/svgr-icons/Transparency_icon.svg'
import RangeSlider from '@editor/common/components/RangeSlider'
import { translate } from '@editor/common/utils/translations'
import { selectIsUserAuthorized } from '@selectors'
import { _debounce, getYoutubeThumbnail } from '@website/common/utils'

import { MobileControls } from '../components'
import { RemoveIcon } from '../styled'
import {
  MobileControlWrapper,
  MobileControl
} from '../components/MobileControls/styled'
import BackgroundImageControl from './BackgroundImageControl'
import BackgroundVideoControl from './BackgroundVideoControl'
import * as Styled from './styled'
import type { TBackgroundControlsChildProps } from './types'

const BackgroundControlsMobile = ({
  backgroundImage,
  backgroundVideo,
  bgOverlayTransparency,
  isBgImageAllowed,
  isBgVideoAllowed,
  isBgTransparencyAllowed,
  hasBgImageOrVideo,
  isUserAuthorized,
  hasLimitedBackgrounds,
  onMediaRemove,
  onRangeSliderChange,
  openImageFlyout,
  openVideoFlyout,
  openLoginPopup
}: TBackgroundControlsChildProps) => {
  const [isSlideUpOpen, setSlideUpOpenState] = useState(false)
  const [
    isTransparencyControlExpanded,
    setTransparencyControlExpandedState
  ] = useState(false)

  const { open: openBackgroundSidebar } = useContext(BackgroundSidebarContext)

  const _openImageFlyout = useCallback(() => {
    openImageFlyout({ isOpen: true })
  }, [openImageFlyout])

  const _openVideoFlyout = useCallback(
    () => openVideoFlyout({ isOpen: true }),
    [openVideoFlyout]
  )

  const openSlideUp = useCallback(() => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }

    setSlideUpOpenState(true)
  }, [isUserAuthorized, openLoginPopup])

  const closeSlideUp = useCallback(() => {
    setSlideUpOpenState(false)
    setTransparencyControlExpandedState(false)
  }, [])

  const onControlClick = useCallback(() => {
    if (hasLimitedBackgrounds) {
      openBackgroundSidebar()
      return
    }
    hasBgImageOrVideo && openSlideUp()
  }, [
    hasLimitedBackgrounds,
    openBackgroundSidebar,
    hasBgImageOrVideo,
    openSlideUp
  ])

  const onReplace = useCallback(() => {
    closeSlideUp()
    isBgVideoAllowed ? _openVideoFlyout() : _openImageFlyout()
  }, [isBgVideoAllowed, closeSlideUp, _openVideoFlyout, _openImageFlyout])

  const onTransparencyClick = useCallback(() => {
    setTransparencyControlExpandedState(true)
  }, [])

  const collapseTransparencyControl = useCallback(e => {
    e.stopPropagation()
    setTransparencyControlExpandedState(false)
  }, [])

  return (
    <>
      {isBgImageAllowed ? (
        <MobileControlWrapper onClick={onControlClick}>
          <BackgroundImageControl
            backgroundImage={backgroundImage}
            openImageFlyout={_openImageFlyout}
          />
        </MobileControlWrapper>
      ) : isBgVideoAllowed ? (
        <MobileControlWrapper onClick={onControlClick}>
          <BackgroundVideoControl
            backgroundVideo={backgroundVideo}
            openVideoFlyout={_openVideoFlyout}
          />
        </MobileControlWrapper>
      ) : null}

      {hasBgImageOrVideo ? (
        <MobileControls
          slideUpTitle={`${
            isBgVideoAllowed
              ? translate('background_video_label')
              : translate('background_image_label')
          }`}
          isSlideUpOpen={isSlideUpOpen}
          onControlClick={openSlideUp}
          closeSlideUp={closeSlideUp}
        >
          <Styled.Controls>
            <Styled.Image
              src={backgroundImage || getYoutubeThumbnail(backgroundVideo)}
              alt="bg-img"
            />
            <MobileControl
              onClick={onReplace}
              isDisabled={isTransparencyControlExpanded}
            >
              <p>
                {isBgVideoAllowed
                  ? translate('replace_video_label')
                  : translate('replace_image_label')}
              </p>
              <ReplaceIcon />
            </MobileControl>
            {hasBgImageOrVideo && isBgTransparencyAllowed ? (
              <MobileControl
                onClick={onTransparencyClick}
                isExpanded={isTransparencyControlExpanded}
              >
                {isTransparencyControlExpanded ? (
                  <Styled.MobileTransparencyContent>
                    <p>{translate('adjust_transparency_label')}</p>
                    <RangeSlider
                      value={1 - bgOverlayTransparency}
                      max={1}
                      step={0.01}
                      onChange={_debounce(onRangeSliderChange, 150)}
                    />
                    <button onClick={collapseTransparencyControl}>
                      {translate('close_label')}
                    </button>
                  </Styled.MobileTransparencyContent>
                ) : (
                  <>
                    <p>{translate('adjust_transparency_label')}</p>
                    <TransparencyIcon />
                  </>
                )}
              </MobileControl>
            ) : null}
            <MobileControl
              onClick={onMediaRemove}
              isDisabled={isTransparencyControlExpanded}
            >
              <p>{`${
                isBgVideoAllowed
                  ? translate('delete_video_label')
                  : translate('delete_image_label')
              }`}</p>
              <RemoveIcon />
            </MobileControl>
          </Styled.Controls>
        </MobileControls>
      ) : null}
    </>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

const mapDispatchToProps = { openLoginPopup }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundControlsMobile)
