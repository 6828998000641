/* @flow */
import styled from 'styled-components'
import THEME from './Header21.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  padding: 0 0 80px 0;
  position: relative;
  margin-bottom: 0%;
  @media only screen and (max-width: 1024px) {
    padding: 0 0 80px 0;
  }
  @media only screen and (max-width: 457px) {
    padding: 0 0 60px 0;
  }
`

const WMCustomContainer = styled(WMContainer)`
  ${({ isEditing }) => (isEditing ? 'margin-top: 10px;' : null)}
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 0;
`

const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  @media only screen and (min-width: 1025px) {
    max-width: 600px;
  }
  > *:first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  color: ${getStyleForProp('heading')};
  line-height: 1.4;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WMCustomContainer,
  HeaderContent,
  Title,
  Description
}
