export const validation = {
  headingMaxChar: 80,
  paragraphMaxChar: 300,
  blockTitleMaxChar: 20,
  blockParagraphMaxChar: 60,
  blocks: {
    min: 0,
    max: 3
  }
}

// export const ANIMATION_SPEED = 50
