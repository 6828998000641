import styled from 'styled-components'

const AVATAR_SIZE = 38

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  width: 68px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
`
export const Avatar = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  z-index: 1;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  border-radius: 50%;
  background-image: ${({ $data: { background, url } }) =>
    `url(${url}), ${background}`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 1px #d2e0fc;
`

export const UserNameWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${AVATAR_SIZE}px;
  height: 30px;
  box-sizing: border-box;
  border: 0.5px solid #d2e0fc;
  border-radius: 20px;
  background-color: #1b55d80d;
`

export const OpenIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  & > svg {
    fill: #545f7e;
    transition: transform 0.3s ease;
    transform: rotate(${({ isOpen }) => (isOpen ? 0 : 180)}deg);
  }
`
