/* @flow */
import produce from 'immer'
import { ADDITIONAL_LINK_INITIAL_STATE } from './Feature32.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_FEATURE_TITLE':
        draft.features[action.value.idx].title = action.value.newBlockTitle
        break
      case 'CHANGE_FEATURE_PARAGRAPH':
        draft.features[action.value.idx].paragraph =
          action.value.newBlockParagraph
        break
      case 'CHANGE_FEATURE_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.features[idx].featureTitleAlignment = alignment
        break
      }
      case 'CHANGE_FEATURE_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.features[idx].featureParagraphAlignment = alignment
        break
      }
      case 'ADD_ITEM':
        draft.features.push(initialState.features[0])
        break
      case 'REMOVE_ITEM':
        draft.features.splice(action.value, 1)
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'ADD_LINK':
        const { idx } = action.value
        draft.features[idx].additionalUrl =
          ADDITIONAL_LINK_INITIAL_STATE.additionalUrl
        draft.features[idx].additionalText =
          ADDITIONAL_LINK_INITIAL_STATE.additionalText
        break
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.features[idx].additionalUrl = ''
        draft.features[idx].additionalText = ''
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.features[linkIdx].additionalUrl = linkData.link
        draft.features[linkIdx].additionalText = linkData.text
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.features[idx].imgUrl = url
        draft.features[idx].imgDimensions = dimensions || {}
        break
      }
    }
  })
}
