/* @flow */
import {
  iconActionMaker,
  linkActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  addLink,
  addBlock,
  addIcon,
  changeTitle,
  changeParagraph,
  changeBlockTitle,
  changeBlockParagraph,
  removeBlock
} from '@website/components/CommonActions'
export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeBlockTitle = (newTitle, idx) => {
    dispatch(changeBlockTitle(newTitle, idx))
  }

  const _changeBlockParagraph = (newParagraph, idx) => {
    dispatch(changeBlockParagraph(newParagraph, idx))
  }
  const changeTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraphAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addLink: idx => dispatch(addLink(idx)),
    addBlock: () => dispatch(addBlock()),
    removeBlock,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle: _changeBlockTitle,
    changeBlockParagraph: _changeBlockParagraph,
    changeTitleAlignment,
    changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    iconActions: idx => [
      ...iconActionMaker(dispatch)({
        idx,
        showRemove: false
      })
    ],
    featureActions: removeBlockActionMaker(dispatch),
    linkActions: linkActionMaker(dispatch)
  }
}
