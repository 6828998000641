/* @flow */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { withTheme } from 'styled-components'

import * as Styled from './styled'
import BackgroundImageControl from '@editor/common/components/ComponentControls/BackgroundControls/BackgroundImageControl'
import Control from '@editor/common/components/ComponentControls/components/Control/Control'
import ReplaceIcon from '@editor/common/assets/svgr-icons/replace_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import TransparencyIcon from '@editor/common/assets/svgr-icons/Transparency_icon.svg'
import RangeSlider from '@editor/common/components/RangeSlider'
import { _debounce } from '@website/common/utils'
import {
  LINK_TYPES,
  MODAL_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import { ControlModalsContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import { backgroundImgSizes } from '@editor/conf/imageSizes'

export const BackgroundControlsDesktop = ({
  activeSlideIdx,
  backgroundImage,
  bgOverlayTransparency,
  componentId,
  changeSliderBackgroundImage,
  removeBgImage,
  setTransparency
}) => {
  const [isExpanded, setExpandedState] = useState(!!backgroundImage)
  const [isTransparencyFlyoutOpen, setTransparencyFlyoutState] = useState(false)

  const bgImageControlRef = useRef(null)
  const replaceControlRef = useRef(null)

  const { modalStates, setModalState } = useContext(ControlModalsContext)
  const isFlyoutOpen = modalStates[MODAL_TYPES.mediaModal].isOpen
  const bgChangeControlClassName = `${componentId}-bg-change-control`

  const commonState = useMemo(
    () => ({
      isOpen: !isFlyoutOpen,
      isFlyoutPositionFixed: false,
      alignment: 'right',
      renderingContainer: bgChangeControlClassName,
      type: LINK_TYPES.image,
      optimizationDimensions: backgroundImgSizes[componentId],
      handler: (url, dimensions) =>
        changeSliderBackgroundImage(activeSlideIdx, url, dimensions)
    }),
    [isFlyoutOpen, activeSlideIdx, changeSliderBackgroundImage]
  )

  useEffect(() => {
    setExpandedState(!!backgroundImage)
  }, [backgroundImage])

  const onTransparencyControlClick = useCallback(() => {
    setTransparencyFlyoutState(!isTransparencyFlyoutOpen)
  }, [isTransparencyFlyoutOpen])

  const onDelete = useCallback(() => {
    removeBgImage(activeSlideIdx)
  }, [activeSlideIdx, removeBgImage])

  const onReplace = useCallback(() => {
    setModalState(MODAL_TYPES.mediaModal, {
      targetRef: replaceControlRef,
      shift: { x: 75, y: 35 },
      ...commonState
    })
  }, [replaceControlRef, commonState])

  const onRangeSliderChange = useCallback(
    transparency => {
      setTransparency(activeSlideIdx, 1 - transparency)
    },
    [setTransparency, activeSlideIdx]
  )

  const openImageFlyout = useCallback(() => {
    setModalState(MODAL_TYPES.mediaModal, {
      targetRef: bgImageControlRef,
      shift: { x: 0, y: 35 },
      ...commonState
    })
  }, [bgImageControlRef, commonState])

  const tooltipContent = useMemo(() => {
    return (
      <Styled.TooltipContent>
        <p>{translate('transparency_label')}</p>
        <RangeSlider
          value={1 - bgOverlayTransparency}
          max={1}
          step={0.01}
          onChange={_debounce(onRangeSliderChange, 150)}
        />
      </Styled.TooltipContent>
    )
  }, [onRangeSliderChange, bgOverlayTransparency])

  return (
    <Styled.ControlsBar isExpanded={isExpanded}>
      <Styled.GlobalStyles />
      <BackgroundImageControl
        backgroundImage={backgroundImage}
        className={bgChangeControlClassName}
        bgImageControlRef={bgImageControlRef}
        openImageFlyout={openImageFlyout}
      />
      {backgroundImage ? (
        <Styled.Controls>
          <Control
            IconComp={ReplaceIcon}
            tooltipText={isFlyoutOpen ? '' : translate('replace_image_label')}
            onClick={onReplace}
            controlRef={replaceControlRef}
            className={bgChangeControlClassName}
          />
          <TooltipWrapper
            disableHover
            isOpen={isTransparencyFlyoutOpen && isExpanded}
            content={tooltipContent}
            offsets={{ y: 20 }}
            position="left"
            className="bg-transparency-control"
            color="#252E48"
            onClose={() => setTransparencyFlyoutState(false)}
            ignoreAutoPositioning
          >
            <Control
              IconComp={TransparencyIcon}
              tooltipText={
                !isTransparencyFlyoutOpen
                  ? translate('adjust_transparency_label')
                  : ''
              }
              isSelected={isTransparencyFlyoutOpen}
              onClick={onTransparencyControlClick}
            />
          </TooltipWrapper>
          <Control
            IconComp={Styled.RemoveIcon}
            tooltipText={translate('delete_image_label')}
            onClick={onDelete}
          />
        </Styled.Controls>
      ) : null}
    </Styled.ControlsBar>
  )
}

export default withTheme(BackgroundControlsDesktop)
