// @flow

import React, { memo, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import * as Styled from './styled'
import Slider from 'react-slick'
import ArrowLeft from '@editor/common/assets/svgr-icons/arrow_small_left.svg'
import ArrowRight from '@editor/common/assets/svgr-icons/arrow_small_right.svg'

import { selectIsUserAuthorized } from '@selectors'
import { translate } from '@editor/common/utils/translations'
import { SLIDER_DEFAULT_SETTINGS, STORAGE_KEY } from './consts'
import { getTranslatedNews } from './utils'

const NewFeaturesPopup = () => {
  const { localStorage } = window
  const isAlreadySeen = localStorage.getItem(STORAGE_KEY)
  const [isOpen, setOpenedState] = useState(!isAlreadySeen)
  const isUserAuthorized = useSelector(selectIsUserAuthorized)
  const isPopupOpen = isOpen && isUserAuthorized

  const handleClose = () => {
    localStorage.setItem(STORAGE_KEY, true)
    setOpenedState(false)
  }

  const news = useMemo(getTranslatedNews)

  return (
    <Styled.Popup isOpen={isPopupOpen} onClose={handleClose}>
      <Styled.Content>
        <Styled.Title>🤩 {translate('news_title')}👇</Styled.Title>
        <Styled.SliderContainer>
          <Slider
            {...SLIDER_DEFAULT_SETTINGS}
            className="new-features-slider"
            nextArrow={
              <div>
                <ArrowRight />
              </div>
            }
            prevArrow={
              <div>
                <ArrowLeft />
              </div>
            }
          >
            {news.map(
              ({
                mediaType,
                mediaUrl,
                secondaryTitle,
                description,
                mediaLink
              }) => (
                <Styled.SlideWrapper>
                  <a href={mediaLink} target="_blank" rel="noreferrer">
                    {mediaType === 'image' ? (
                      <Styled.Image src={mediaUrl} alt="banner_img" />
                    ) : (
                      <video
                        playsInline
                        autoPlay
                        muted
                        loop
                        src={mediaUrl}
                        className="video-banner"
                      />
                    )}
                  </a>
                  <Styled.SecondaryTitle>
                    {secondaryTitle}
                  </Styled.SecondaryTitle>
                  <Styled.Description>{description}</Styled.Description>
                </Styled.SlideWrapper>
              )
            )}
          </Slider>
        </Styled.SliderContainer>
      </Styled.Content>
    </Styled.Popup>
  )
}

export default memo(NewFeaturesPopup)
