/* @flow */

import React, { Component } from 'react'
import S from './Header17.styled'
import { validation } from './Header17.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import { getDate, getDateDistance } from '@website/common/utils'
import EditableContent from '@website/common/components/EditableContent'
import { MobileDetectContext, DispatchContext } from '@contexts'
import If from '@website/common/components/Conditional'

class CountDownDate extends Component<> {
  state = {
    formatedDates: getDate(this.props.date)
  }
  dateChanger = null

  componentDidMount() {
    this.countDownDate()
  }

  componentWillUnmount() {
    if (this.dateChanger) {
      clearInterval(this.dateChanger)
    }
  }
  componentDidUpdate(prevProps) {
    if (!this.dateChanger && prevProps.date !== this.props.date) {
      this.countDownDate()
    }
  }

  countDownDate = () => {
    this.dateChanger = setInterval(() => {
      const { date } = this.props
      const distance = getDateDistance(date)
      if (distance < 0) {
        clearInterval(this.dateChanger)
        this.dateChanger = null
      }
      this.setState({ formatedDates: getDate(date) })
    }, 1000)
  }

  render() {
    const { dateLabels, openDatePicker } = this.props
    const { formatedDates } = this.state
    const Actions = ActionGetter(this.context)

    return (
      <MobileDetectContext.Consumer>
        {isMobile => (
          <S.DateWrapper>
            <Mapper
              data={Object.keys(formatedDates)}
              render={(formatedDate, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <S.DateWrap className="formatedDate">
                      <div onClick={openDatePicker}>
                        <S.Time className="WM_GLOBAL_primary-font">
                          <span>{formatedDates[formatedDate]}</span>
                        </S.Time>
                      </div>
                      <EditableContent
                        text={isMobile ? dateLabels[idx][0] : dateLabels[idx]}
                        as={S.Date}
                        maxCount={validation.dateLabelsMaxChar}
                        className="WM_GLOBAL_secondary-font"
                        onChange={(newDateLabel: string) => {
                          Actions.changeDateLabel(newDateLabel, idx)
                        }}
                        areControlsHidden
                      />
                    </S.DateWrap>
                    <If
                      condition={idx < 3}
                      then={() => <S.TimeDots>:</S.TimeDots>}
                    />
                  </React.Fragment>
                )
              }}
            />
          </S.DateWrapper>
        )}
      </MobileDetectContext.Consumer>
    )
  }
}

CountDownDate.contextType = DispatchContext
export default CountDownDate
