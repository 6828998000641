/* @flow */
import styled, { css } from 'styled-components'
import THEME from './CTA13.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-top: ${({ isEditing }) => isEditing && '140px'};
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => isEditing && '120px'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  justify-content: center;
  ${({ theme }) =>
    css`
      max-width: ${theme.orientation === 'RIGHT' ? '1200px' : '1200px'};
    `}
  align-items: center;
  padding-top: 0;
`

const TextImageContent = styled.div`
  width: 50%;
  max-width: 725px;
  padding-top: ${({ isOneImage, isTwoImage }) =>
    isOneImage ? '32%' : isTwoImage ? '40%' : '56%'};
  position: relative;
  transform: translateX(-30px);
  .styled-controls {
    z-index: 5;
  }
  ${({ theme }) =>
    css`
      transform: ${theme.orientation === 'RIGHT'
        ? css`translateX(30px);
            @media only screen and (max-width: 1024px) {
              transform:translateX(0px);
            } 
          `
        : css`translateX(-30px);
    @media only screen and (max-width: 1024px) {
      transform:translateX(-14px);
    }`};
    `}
  .image-container {
    position: absolute;
    width: 53%;
    position: absolute;
    background-color: white;
  }
  @media only screen and (max-width: 992px) {
    width: 59.15%;
    padding-top: ${({ isOneImage }) => (isOneImage ? '37.55%' : '59.15%')};
    max-width: initial;
    margin: ${({ isOneImage, isTwoImage, isThreeImage, theme }) =>
      isOneImage && theme.orientation === 'LEFT'
        ? '0 0 30px 0'
        : isOneImage && theme.orientation === 'RIGHT'
        ? '30px 0 -10px 0'
        : isTwoImage && theme.orientation === 'LEFT'
        ? '0 0 -15px 0'
        : isTwoImage && theme.orientation === 'RIGHT'
        ? '30px 0 -50px 0'
        : isThreeImage && theme.orientation === 'RIGHT'
        ? '40px 0 0 0'
        : '0 0 40px 0'};
    transform: none;
  }
  @media only screen and (max-width: 450px) {
    width: 85%;
    padding-top: ${({ isOneImage }) => (isOneImage ? '54%' : '85%')};
    margin: ${({ isOneImage, isTwoImage, isThreeImage, theme }) =>
      isOneImage && theme.orientation === 'LEFT'
        ? '-10px 0 30px 0'
        : isOneImage && theme.orientation === 'RIGHT'
        ? '30px 0 -10px 0'
        : isTwoImage && theme.orientation === 'LEFT'
        ? '-10px 0 0 0'
        : isTwoImage && theme.orientation === 'RIGHT'
        ? '30px 0 -35px 0'
        : isThreeImage && theme.orientation === 'RIGHT'
        ? '40px 0 0 0'
        : '0 0 40px 0'};
  }
  ${({ isOneImage }) =>
    css`
      ${isOneImage
        ? css`
            .image-1 {
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          `
        : css`
            .image-1 {
              top: 0;
              left: 3%;
            }
          `}
    `}
  .image-1 {
    z-index: 2;
    .styled-controls {
      left: auto;
      right: 0;
    }
  }
  ${({ isTwoImage }) =>
    css`
      ${isTwoImage
        ? css`
            .image-2 {
              bottom: 0;
              right: -12%;
              z-index: 2;
              @media only screen and (max-width: 992px) {
                bottom: 12%;
                right: -18%;
              }
            }
            .image-1 {
              z-index: 1;
              top: 0;
              @media only screen and (max-width: 992px) {
                top: 3%;
                left: 6%;
              }
            }
          `
        : css`
            .image-2 {
              bottom: 0;
              left: 42%;
            }
          `}
    `}
  .image-2 {
    transform: translateX(-50%);
    z-index: 1;
    .styled-controls {
      top: auto;
      bottom: -45px;
      left: 0;
    }
  }
  .image-3 {
    top: 16%;
    right: 0;
    z-index: 3;
  }
  .add-button-wrap {
    transform: ${({ isOneImage }) =>
      isOneImage ? 'translateY(20px)' : 'translateY(55px)'};
  }
  @media only screen and (max-width: 992px) {
    .add-button-wrap {
      transform: ${({ isOneImage }) =>
        isOneImage ? 'translateY(28px)' : 'translateY(6px)'};
    }
  }
`

const ImageFrame = styled.div`
  box-shadow: 0px 3px 10px rgba(41, 55, 97, 0.101);
  background-color: white;
  position: absolute;
  width: 53%;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const ImageWrapper = styled.div`
  position: relative;
  width: calc(100% - 20px);
  margin: 10px 10px 36px 10px;
  @media only screen and (max-width: 480px) {
    width: calc(100% - 12px);
    margin: 6px 6px 20px 6px;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const ContentWrap = styled.div`
  width: 45%;
  max-width: 654px;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'RIGHT' ? '0 50px 0 0' : '0 0 0 50px'};
    `}
  .paragraph3,
  .paragraph4 {
    margin-top: 20px;
  }
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 992px) {
    margin: 0;
    width: 100%;
    text-align: center;
    .buttons-block {
      justify-content: center;
      margin-right: auto;
      margin-left: auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin-right: auto;
      margin-left: auto;
    }
    .paragraph2 {
      margin-top: 20px;
    }
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    max-width: initial;
  }
  @media only screen and (max-width: 450px) {
    .buttons-block {
      flex-direction: column;
    }
    .buttons-wrapper {
      flex-direction: column;
    }
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
  }
  > .control-container:first-child {
    @media only screen and (max-width: 992px) {
      margin-top: 40px;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Paragraph,
  TextImageContent,
  ImageFrame,
  ImageWrapper,
  Img,
  ControlContainer,
  ContentWrap
}
