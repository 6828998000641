/* @flow */
import React, { useContext, useState, memo, useCallback } from 'react'
import * as S from './Testimonials4.styled'
import { validation } from './Testimonials4.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Slider from './CenteredSlider'
import SlideCounts from './SlideCounts'
import Quote from '@website/common/assets/svgr-icons/quote.svg'
import { translate } from '@editor/common/utils/translations'

const Slider4 = memo(
  ({
    data: {
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      title,
      titleAlignment,
      testimonials
    }
  }) => {
    const [activeIdx, setActiveIdx] = useState(
      parseInt(testimonials.length / 2)
    )
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)

    const stopPropagation = useCallback(e => {
      e.stopPropagation()
    }, [])

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
        isEditing={isEditing}
      >
        <WMContainer>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading54"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <S.SliderContainer isEditing={isEditing}>
            <Slider
              itemProps={{
                width: 456
              }}
              onBeforeChange={setActiveIdx}
              swipeToSlide={!isEditing}
              swipe={!isEditing}
            >
              {testimonials.map((testimonial: string, idx: number) => (
                <ControlsWithModalOpener
                  actions={Actions.blockActions({
                    idx,
                    showRemove:
                      testimonials.length > validation.testimonials.min
                  })}
                  style={{ height: '100%', width: '100%' }}
                >
                  <Show
                    key={idx}
                    when={[testimonial.paragraph, testimonial.name]}
                  >
                    <S.BlockItem>
                      <S.BlockItemWrap
                        className="WM_GLOBAL_secondary-font"
                        onClick={isEditing && stopPropagation}
                      >
                        <S.QuoteWrapper>
                          <Quote />
                        </S.QuoteWrapper>
                        <S.ClientWrap>
                          <If
                            condition={testimonial.imgUrl}
                            then={() => (
                              <S.ClientImgWrap>
                                <ControlsWithImageCropper
                                  key={idx}
                                  hideControls={idx !== activeIdx}
                                  toPropagate={false}
                                  actions={Actions.mockupActions(
                                    idx,
                                    testimonial.imgUrl
                                  )}
                                  style={{
                                    height: '100%'
                                  }}
                                >
                                  <Image
                                    withLazy={false}
                                    as={S.Img}
                                    alt="Slider image"
                                    sizes={testimonial.imgDimensions}
                                    defaultImgSrc={testimonial.imgUrl}
                                    asProps={{
                                      imgCropParams: testimonial.imgCropParams
                                    }}
                                  />
                                </ControlsWithImageCropper>
                              </S.ClientImgWrap>
                            )}
                          />
                          <AddButton
                            onAdd={() => {
                              Actions.addClientImage(idx)
                            }}
                            toShow={!testimonial.imgUrl}
                            style={{
                              marginRight: '20px',
                              boxSizing: 'border-box'
                            }}
                          >
                            <Icon
                              name="glyph-add"
                              className="icon"
                              size="normal"
                            />
                            {translate('add_image_label')}
                          </AddButton>
                          <S.ClientInfo>
                            <EditableContent
                              as={S.Name}
                              text={testimonial.name}
                              alignment={testimonial.nameAlignment}
                              className="WM_GLOBAL_heading20"
                              maxCount={validation.maxCustomerNameChar}
                              onChange={(newName: string) => {
                                Actions.changeClientName(newName, idx)
                              }}
                              changeAlignment={(alignment: string) =>
                                Actions.changeNameAlignment(alignment, idx)
                              }
                            />
                            <EditableContent
                              as={S.Position}
                              text={testimonial.position}
                              alignment={testimonial.positionAlignment}
                              maxCount={validation.maxCustomerPositionChar}
                              className="WM_GLOBAL_paragraph14"
                              onChange={(newPosition: string) => {
                                Actions.changeClientPosition(newPosition, idx)
                              }}
                              changeAlignment={(alignment: string) =>
                                Actions.changePositionAlignment(alignment, idx)
                              }
                            />
                          </S.ClientInfo>
                        </S.ClientWrap>
                        <EditableContent
                          as={S.ItemParagraph}
                          text={testimonial.paragraph}
                          alignment={testimonial.blockParagraphAlignment}
                          required
                          className="WM_GLOBAL_paragraph18"
                          maxCount={validation.maxSlideContentChar}
                          onChange={(newParagraph: string) => {
                            Actions.changeItemParagraph(newParagraph, idx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                      </S.BlockItemWrap>
                    </S.BlockItem>
                  </Show>
                </ControlsWithModalOpener>
              ))}
            </Slider>
          </S.SliderContainer>
          <SlideCounts
            activeSlideIdx={activeIdx + 1}
            slidesCount={testimonials.length}
          />
          <AddButton
            onAdd={() => Actions.addSlide(activeIdx)}
            toShow={testimonials.length < validation.testimonials.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_slide_label')}
          </AddButton>
        </WMContainer>
      </S.StyledContainer>
    )
  }
)

export default Slider4
