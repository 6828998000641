/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_BLOCK_TITLE':
        draft.content[action.value.idx].title = action.value.newTitle
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        draft.content[action.value.idx].paragraph = action.value.newParagraph
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.content[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.content[idx].paragraphAlignment = alignment
        break
      }
      case 'ADD_BLOCK':
        draft.content.push(initialState.content[0])
        break
      case 'REMOVE_BLOCK':
        draft.content.splice(action.value.idx, 1)
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnData } = action.value
        draft.buttonUrl = btnData.link
        draft.buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BUTTON':
        draft.buttonText = initialState.buttonText
        draft.buttonUrl = initialState.buttonUrl
        break
    }
  })
}
