import produce from 'immer'

import { WEBSITE_LANGUAGES_DATA } from '../consts'

export default (state = WEBSITE_LANGUAGES_DATA, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_WEBSITE_LANGUAGES_DATA':
        draft.data = action.value
        break
      case 'SET_WEBSITE_LANGUAGES_DATA_LOADED':
        draft.isLoaded = true
        break
      case 'OPEN_SUCCESS_POPUP':
        draft.isSuccessPopupOpen = true
        break
      case 'CLOSE_SUCCESS_POPUP':
        draft.isSuccessPopupOpen = false
        break
      case 'SET_LANGUAGE_MENU_LAYOUT':
        draft.languageMenuLayout = action.value
        break
      case 'SET_LANGUAGE_TRANSLATION_STATE':
        const { language: languageCode, value } = action.value

        const language = draft.data.find(
          language => language.lang.code === languageCode
        )

        if (language) {
          language.state = value
        }
        break
      default:
        return state
    }
  })
