/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const imageActions = (blockIdx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature14_2',
      showRemove: false,
      additionalArgs: { blockIdx },
      imageUrl
    })

  const _changeParagraph = (newParagraph: string) => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitle = (newTitle: string) => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    imageActions
  }
}
