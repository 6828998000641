/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_BLOCK_TEXT': {
        const { blockIdx, text } = action.value
        draft.features[blockIdx].title = text
        break
      }
      case 'CHANGE_BLOCK_COUNT':
        const { blockIdx, count } = action.value
        draft.features[blockIdx].count = count
        break

      case 'CHANGE_BLOCK_COUNT_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.features[blockIdx].blockCountAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.features[blockIdx].blockTitleAlignment = alignment
        break
      }
      case 'ADD_BLOCK':
        draft.features.push(initialState.features[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.features.splice(idx, 1)
        break
      }
      case 'CHANGE_ICON':
        const { idx: idx_, iconName } = action.value
        draft.features[idx_].iconName = iconName
        break
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.features[idx].iconName = ''
        break
      }
      case 'ADD_ICON':
        draft.features[action.value].iconName = 'edit'
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
