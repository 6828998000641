import styled from 'styled-components'

export const InputContainer = styled.div`
  width: 100%;
  height: 40px;
  .input-container {
    width: 100%;

    input {
      border-radius: 5px;
    }

    .error-message {
      white-space: nowrap;
    }
  }
`
