/* @flow */

module.exports = {
  id: 'PayPal5',
  thumbnailRatio: 0.527,
  isUnique: false,
  label: 'PayPal5',
  category: 'paypal',
  colorMode: 'EXTRALIGHT',
  customPalette: {
    primaryDark: '#A08559',
    primaryLight: '#E3B873',
    secondaryDark: '#7D716B',
    secondaryLight: '#C6B193',
    tertiaryDark: '#393838',
    tertiaryNormal: '#575050',
    tertiarySemiLight: '#7D716B',
    tertiaryLight: '#F3F0F0',
    tertiaryExtraLight: '#F9F9F9',
    accentWhite: '#FFFFFF'
  },
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/paypal5-1.0.0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/paypal5m-1.0.0.jpg',
  metaData: {
    backgroundImgUrl: {
      _type: 'backgroundImage',
      _dimensionsKey: 'backgroundImgDimensions',
      _optimizationSizes: [
        { _width: 800 },
        { _width: 1200 },
        { _width: 1600 },
        { _width: 2200 }
      ],
      _min: 0,
      _max: 2048,
      _value: ''
    },
    backgroundImgDimensions: {},
    bgOverlayTransparency: 0.8,
    subtitle: {
      _type: 'subTitle',
      _min: 0,
      _max: 200,
      _value: 'BESTSELLERS'
    },
    subtitleAlignment: 'center',
    title: {
      _type: 'title',
      _min: 0,
      _max: 200,
      _value: 'Must-have books of the month'
    },
    titleAlignment: 'center',
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 400,
      _value: 'Find the bestsellers of the month with discounted prices.'
    },
    paragraphAlignment: 'center',
    blocks: {
      _type: 'list',
      _min: 1,
      _max: 8,
      _value: [
        {
          imgUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 600 },
              { _width: 600 },
              { _width: 600 },
              { _width: 600 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg'
          },
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 100,
            _value: 'Nathan Williams'
          },
          paragraphAlignment: 'center',
          titleAlignment: 'center',
          additionalUrl: {
            _type: 'link',
            _min: 0,
            _max: 2048,
            _value: ''
          },
          additionalText: {
            _type: 'linkLabel',
            _value: ''
          },
          paypalBtnProps: {
            email: '',
            type: 'selling',
            title: {
              _type: 'buttonLabel',
              _min: 1,
              _max: 19,
              _value: 'PAY NOW'
            },
            style: 'black',
            productName: {
              _type: 'title',
              _min: 1,
              _max: 150,
              _value: 'The Kinfolk Table'
            },
            productId: '',
            productPrice: '29.99',
            currency: 'USD',
            productDiscountedPrice: '39.99',
            shippingFee: ''
          }
        },
        {
          imgUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 600 },
              { _width: 600 },
              { _width: 600 },
              { _width: 600 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg'
          },
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 100,
            _value: 'Alabaste'
          },
          paragraphAlignment: 'center',
          titleAlignment: 'center',
          additionalUrl: {
            _type: 'link',
            _min: 0,
            _max: 2048,
            _value: ''
          },
          additionalText: {
            _type: 'linkLabel',
            _value: ''
          },
          paypalBtnProps: {
            email: '',
            type: 'selling',
            title: {
              _type: 'buttonLabel',
              _min: 1,
              _max: 19,
              _value: 'PAY NOW'
            },
            style: 'black',
            productName: {
              _type: 'title',
              _min: 1,
              _max: 150,
              _value: 'The Book of Proverbs'
            },
            productId: '',
            productPrice: '39.99',
            currency: 'USD',
            productDiscountedPrice: '49.99',
            shippingFee: ''
          }
        },
        {
          imgUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 600 },
              { _width: 600 },
              { _width: 600 },
              { _width: 600 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg'
          },
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 100,
            _value: 'Rupi Kaur'
          },
          paragraphAlignment: 'center',
          titleAlignment: 'center',
          additionalUrl: {
            _type: 'link',
            _min: 0,
            _max: 2048,
            _value: ''
          },
          additionalText: {
            _type: 'linkLabel',
            _value: ''
          },
          paypalBtnProps: {
            email: '',
            type: 'selling',
            title: {
              _type: 'buttonLabel',
              _min: 1,
              _max: 19,
              _value: 'PAY NOW'
            },
            style: 'black',
            productName: {
              _type: 'title',
              _min: 1,
              _max: 150,
              _value: 'Milk and Honey'
            },
            productId: '',
            productPrice: '29.99',
            currency: 'USD',
            productDiscountedPrice: '39.99',
            shippingFee: ''
          }
        },
        {
          imgUrl: {
            _type: 'image',
            _dimensionsKey: 'imgDimensions',
            _optimizationSizes: [
              { _width: 600 },
              { _width: 600 },
              { _width: 600 },
              { _width: 600 }
            ],
            _min: 0,
            _max: 2048,
            _value:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg'
          },
          imgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg'
          },
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 100,
            _value: 'Magazine'
          },
          paragraphAlignment: 'center',
          titleAlignment: 'center',
          additionalUrl: {
            _type: 'link',
            _min: 0,
            _max: 2048,
            _value: ''
          },
          additionalText: {
            _type: 'linkLabel',
            _value: ''
          },
          paypalBtnProps: {
            email: '',
            type: 'selling',
            title: {
              _type: 'buttonLabel',
              _min: 1,
              _max: 19,
              _value: 'PAY NOW'
            },
            style: 'black',
            productName: {
              _type: 'title',
              _min: 1,
              _max: 150,
              _value: 'Design Anthology'
            },
            productId: '',
            productPrice: '19.99',
            currency: 'USD',
            productDiscountedPrice: '29.99',
            shippingFee: ''
          }
        }
      ]
    }
  },
  defaultProps: {
    backgroundImgUrl: '',
    backgroundImgDimensions: {},
    bgOverlayTransparency: 0.8,
    subtitle: 'BESTSELLERS',
    subtitleAlignment: 'center',
    title: 'Must-have books of the month',
    titleAlignment: 'center',
    paragraph: 'Find the bestsellers of the month with discounted prices.',
    paragraphAlignment: 'center',
    blocks: [
      {
        imgUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-1.0.0.jpg'
        },
        paragraph: 'Nathan Williams',
        paragraphAlignment: 'center',
        titleAlignment: 'center',
        additionalUrl: '',
        additionalText: '',
        paypalBtnProps: {
          email: '',
          type: 'selling',
          title: 'PAY NOW',
          style: 'black',
          productName: 'The Kinfolk Table',
          productId: '',
          productPrice: '29.99',
          currency: 'USD',
          productDiscountedPrice: '39.99',
          shippingFee: ''
        }
      },
      {
        imgUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-2.0.0.jpg'
        },
        paragraph: 'Alabaste',
        paragraphAlignment: 'center',
        titleAlignment: 'center',
        additionalUrl: '',
        additionalText: '',
        paypalBtnProps: {
          email: '',
          type: 'selling',
          title: 'PAY NOW',
          style: 'black',
          productName: 'The Book of Proverbs',
          productId: '',
          productPrice: '39.99',
          currency: 'USD',
          productDiscountedPrice: '49.99',
          shippingFee: ''
        }
      },
      {
        imgUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-3.0.0.jpg'
        },
        paragraph: 'Rupi Kaur',
        paragraphAlignment: 'center',
        titleAlignment: 'center',
        additionalUrl: '',
        additionalText: '',
        paypalBtnProps: {
          email: '',
          type: 'selling',
          title: 'PAY NOW',
          style: 'black',
          productName: 'Milk and Honey',
          productId: '',
          productPrice: '29.99',
          currency: 'USD',
          productDiscountedPrice: '39.99',
          shippingFee: ''
        }
      },
      {
        imgUrl:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg',
        imgDimensions: {
          small:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg',
          medium:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg',
          large:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg',
          xlarge:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/paypal5-4.0.0.jpg'
        },
        paragraph: 'Magazine',
        paragraphAlignment: 'center',
        titleAlignment: 'center',
        additionalUrl: '',
        additionalText: '',
        paypalBtnProps: {
          email: '',
          type: 'selling',
          title: 'PAY NOW',
          style: 'black',
          productName: 'Design Anthology',
          productId: '',
          productPrice: '19.99',
          currency: 'USD',
          productDiscountedPrice: '29.99',
          shippingFee: ''
        }
      }
    ]
  }
}
