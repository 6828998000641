export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 100,
  emailMaxChar: 250,
  socials: {
    min: 3,
    max: 7
  },
  emails: {
    min: 1,
    max: 2
  }
}
