export default {
  DARK: {
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    arrows: p => p.accentWhite,
    backgroundColor: p => p.tertiaryDark,
    background: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    arrows: p => p.primaryDark,
    backgroundColor: p => p.tertiaryLight,
    background: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    arrows: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight
  }
}
