// @flow
import React, { useCallback, useRef, useState, useEffect } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import { ICONS } from './consts'
import type { TCategoryProps } from './types'
import { CURVED_DETAIL } from '../../../../consts'
import { scrollToTopOfContent } from '@containers/choose-template/utils'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'
import { useResponsive } from '@shared/hooks'
import { INITIAL_ACTIVE_SUBCATEGORY } from '@redux/consts'
import { selectTemplatesActiveCategoryInfo } from '@selectors'
import {
  fetchTemplates,
  setTemplatesActiveCategoryInfo,
  setTemplateSearchTerm
} from '@actions/editor'

const Category = ({
  categoryData,
  activeCategoryInfo,
  closeMobileCategories,
  setTemplateSearchTerm,
  setTemplatesActiveCategoryInfo,
  fetchTemplates
}: TCategoryProps) => {
  const [isSubcategoriesOpen, setSubcategoriesOpenState] = useState(false)
  const [height, setHeight] = useState(0)
  const subCategoryContentRef = useRef(null)

  const isMobileOrTablet = useResponsive(1024)

  const { id, name, subcategories } = categoryData
  const hasSubCategories = !!(subcategories && subcategories.length)

  const isCategoryActive = id === activeCategoryInfo.activeCategory.id
  const IconComp = ICONS[id]

  useEffect(() => {
    if (subCategoryContentRef.current) {
      const height = subCategoryContentRef.current.offsetHeight

      setHeight(height)
    }
  }, [subCategoryContentRef.current])

  useEffect(() => {
    setSubcategoriesOpenState(isCategoryActive)
  }, [isCategoryActive])

  const onCategoryClick = useCallback(() => {
    setTemplateSearchTerm('')
    setTemplatesActiveCategoryInfo({
      activeCategory: { id, name },
      activeSubcategory: INITIAL_ACTIVE_SUBCATEGORY
    })

    fetchTemplates('', 0, id !== 1 && id)
    isCategoryActive && setSubcategoriesOpenState(!isSubcategoriesOpen)
    isMobileOrTablet && id === 1 && closeMobileCategories()
    scrollToTopOfContent()
  }, [
    id,
    name,
    isCategoryActive,
    isSubcategoriesOpen,
    isMobileOrTablet,
    closeMobileCategories,
    setTemplateSearchTerm,
    setTemplatesActiveCategoryInfo,
    fetchTemplates
  ])

  const onSubCategoryClick = useCallback(
    (id, name) => {
      setTemplateSearchTerm('')
      setTemplatesActiveCategoryInfo({
        ...activeCategoryInfo,
        activeSubcategory: { id, name }
      })

      fetchTemplates('', 0, id)
      isMobileOrTablet && closeMobileCategories()
      scrollToTopOfContent()
    },
    [
      activeCategoryInfo,
      isMobileOrTablet,
      closeMobileCategories,
      setTemplateSearchTerm,
      setTemplatesActiveCategoryInfo,
      fetchTemplates
    ]
  )

  return (
    <Styled.CategoryWrapper onClick={onCategoryClick}>
      <Styled.Category
        isActive={isCategoryActive}
        isSubcategoriesOpen={isSubcategoriesOpen}
      >
        <div>
          {id !== 1 && IconComp ? <IconComp /> : null}
          <p>{name}</p>
        </div>
        {hasSubCategories ? <ArrowIcon className="arrow" /> : null}
      </Styled.Category>
      {hasSubCategories ? (
        <Styled.SubCategoriesWrapper
          height={height}
          isOpen={isSubcategoriesOpen}
          onClick={e => e.stopPropagation()}
        >
          <Styled.SubCategories ref={subCategoryContentRef}>
            {subcategories.map((subCategory, idx) => {
              const { id, name } = subCategory
              const isActive = id === activeCategoryInfo.activeSubcategory.id
              const onClick = () => !isActive && onSubCategoryClick(id, name)

              return (
                <Styled.SubCategory
                  key={idx}
                  isActive={isActive}
                  onClick={onClick}
                >
                  <p>{subCategory.name}</p>
                  <img
                    src={CURVED_DETAIL}
                    className="curved-detail"
                    alt="detail"
                  />
                </Styled.SubCategory>
              )
            })}
          </Styled.SubCategories>
        </Styled.SubCategoriesWrapper>
      ) : null}
    </Styled.CategoryWrapper>
  )
}

const mapStateToProps = state => ({
  activeCategoryInfo: selectTemplatesActiveCategoryInfo(state)
})

const mapDispatchToProps = {
  setTemplatesActiveCategoryInfo,
  setTemplateSearchTerm,
  fetchTemplates
}

export default connect(mapStateToProps, mapDispatchToProps)(Category)
