export const validation = {
  title: { required: true },
  titleMaxChar: 50,
  paragraphMaxChar: 100,
  labelTextMaxChar: 80,
  contactMaxChar: 100,
  socials: {
    min: 0,
    max: 7
  }
}