export default {
  DARK: {
    title: p => p.accentWhite,
    price: p => p.primaryLight,
    paragraph: p => p.accentWhite,
    link: p => p.primaryLight,
    blockTitle: p => p.tertiaryDark,
    blockText: p => p.tertiaryDark,
    iconText: p => p.primaryLight,
    background: p => `${p.tertiaryDark}`,
    icon: p => p.primaryLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    title: p => p.primaryDark,
    price: p => p.primaryDark,
    link: p => p.primaryDark,
    blockTitle: p => p.primaryDark,
    blockText: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    iconText: p => p.primaryDark,
    background: p => `${p.tertiaryLight}`,
    icon: p => p.primaryDark,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    title: p => p.tertiaryDark,
    price: p => p.primaryDark,
    link: p => p.primaryDark,
    blockTitle: p => p.tertiaryDark,
    blockText: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    iconText: p => p.primaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    icon: p => p.primaryLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
