/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BUTTON_DATA':
        {
          const { link, text } = action.value.btnData
          draft.btnUrl = link
          draft.btnText = text
        }
        break
      case 'REMOVE_BUTTON':
        draft.btnUrl = ''
        draft.btnText = ''
        break
      case 'ADD_BUTTON':
        draft.btnText = initialState.btnText
        draft.btnUrl = initialState.btnUrl
        break
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'REMOVE_SOCIAL':
        const { idx: socialIdx } = action.value
        draft.socials.splice(socialIdx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        const { idx, link } = action.value
        draft.socials[idx].socialUrl = link
        break
      case 'CHANGE_SOCIAL_ICON': {
        const { idx: idx, iconName } = action.value
        draft.socials[idx].icon = iconName
        break
      }
    }
  })
}
