/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
      case 'REMOVE_FORM':
        draft.buttonText = ''
        break
      case 'ADD_FORM':
        draft.buttonText = initialState.buttonText
        break
      case 'CHANGE_PLACEHOLDER_TEXT':
        draft.placeholderText = action.value
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'REMOVE_SOCIAL':
        draft.socials.splice(action.value.idx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.socials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.socials[idx].icon = iconName
        }
        break
      case 'CHANGE_MAP_DATA':
        draft.mapSrc = action.value.mapSrc
        draft.geoLocation = action.value.geoLocation
        break
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { newParagraph, key } = action.value
        draft[key] = newParagraph
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT':
        draft.titleAlignment = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT':
        draft.paragraphAlignment = action.value
        break
    }
  })
}
