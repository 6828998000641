// @flow

import React, { useState, useCallback, memo } from 'react'
import { translate } from '@editor/common/utils/translations'
import { getLocalizedURL } from '@editor/common/utils/translations'
import ArrowLeftIcon from '@editor/common/assets/svgr-icons/arrow_left_icon.svg'

import * as Styled from './styled'

import type { TSignOutButtonProps } from './types'

const SignOutButton = ({ closeUserDropdown }: TSignOutButtonProps) => {
  const [isWarningModalOpen, setWarningModalState] = useState(false)

  const handleSignOutClick = useCallback(() => {
    setWarningModalState(true)
  }, [])

  const onClose = useCallback(() => {
    setWarningModalState(false)
  }, [])

  const handleSignOut = useCallback(() => {
    onClose()
    closeUserDropdown()
  }, [onClose, closeUserDropdown])

  return (
    <>
      <Styled.SignOutButton role="button" onClick={handleSignOutClick}>
        <Styled.Icon>
          <ArrowLeftIcon />
        </Styled.Icon>
        <Styled.LangText>{translate('sign_out_label')}</Styled.LangText>
      </Styled.SignOutButton>
      <Styled.Popup isOpen={isWarningModalOpen} onClose={onClose}>
        <>
          <Styled.PopupHeader>
            <Styled.Title>
              {translate('your_website_will_be_saved')}
            </Styled.Title>
          </Styled.PopupHeader>
          <Styled.WarningButton
            onClick={handleSignOut}
            href={`${getLocalizedURL('/logout')}`}
          >
            {translate('ok_got_it_label', true)}
          </Styled.WarningButton>
        </>
      </Styled.Popup>
    </>
  )
}

export default memo(SignOutButton)
