/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header26.styled'
import THEME from './Header26.theme'
import { validation } from './Header26.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SocialLinks from '@website/common/components/SocialLinks'

const Header26 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      logoSrc,
      logoColor,
      headerSocials,
      logoWidth,
      logoDimensions,
      images
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.StyledContainer
      topOffset={navBarHeight}
      isEditing={isEditing}
      isSocials={headerSocials.length > 4}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        alwaysShowBackground
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />

      <S.WmCustomContainer isEditing={isEditing}>
        <S.ImagesContainer>
          <Mapper
            data={images}
            render={(image: string, imageIdx: number) => {
              return (
                <S.ImageWrapper key={imageIdx}>
                  <Show when={[title]}>
                    <S.Overlay isBottom={imageIdx === 1} />
                  </Show>
                  <S.ControlContainer>
                    <ControlsWithImageCropper
                      actions={Actions.imageActions(imageIdx, image.imgUrl)}
                    >
                      <Image
                        as={S.Img}
                        sizes={image.imgDimensions}
                        defaultImgSrc={image.imgUrl}
                        alt="Header image"
                        asProps={{
                          imgCropParams: image.imgCropParams
                        }}
                      />
                    </ControlsWithImageCropper>
                  </S.ControlContainer>
                </S.ImageWrapper>
              )
            }}
          />
        </S.ImagesContainer>
        <Show when={[title]}>
          <EditableContent
            text={title}
            isEditing={isEditing}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading54"
            as={S.Title}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
        </Show>
        <Show when={[paragraph]}>
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph14"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </Show>
        <SocialLinks
          className="header-socials"
          socials={headerSocials}
          socialsMaxCount={validation.headerSocials.max}
        />
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header26
