/* @flow */
import styled, { css } from 'styled-components'
import THEME from './CTA14.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const stylesByColor = {
  default: '',
  dark: css`
    filter: brightness(0.13);
  `,
  light: css`
    filter: brightness(0.97);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0;
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ isEditing }) => (isEditing ? '140px' : '80px')};
  padding-bottom: 80px;
  @media only screen and (max-width: 1300px) {
    text-align: center;
    align-items: center;
    flex-direction: ${({ theme }) =>
      theme.orientation === 'RIGHT' ? 'column-reverse' : 'column'};
  }
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => (isEditing ? '120px' : '60px')};
    padding-bottom: 60px;
  }
`

const LandingContent = styled.div`
  width: 100%;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  @media only screen and (min-width: 1301px) {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? css`
              padding-right: 50px;
            `
          : theme.orientation === 'RIGHT'
          ? css`
              padding-left: 50px;
            `
          : css`
              padding: 0;
            `}
      `}
  }
  @media only screen and (max-width: 1300px) {
    align-items: center;
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
  }
  > :first-child {
    margin-top: 0;
  }
  > .control-container {
    width: 100%;
    margin-top: 30px;
    h1,
    p {
      margin-top: 0;
      padding-top: 0;
    }
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 50%;
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 1300px) {
    width: 100%;
    box-sizing: border-box;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: -15px;
  > div {
    margin: 15px;
  }
  > :first-child {
    margin: 15px 15px 15px 0;
  }
  > :nth-child(2) {
    margin: 15px 0 15px 15px;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: initial;
    justify-content: center;
    flex-wrap: wrap;
    margin: 15px 0 -15px 0;
  }
  .add-button-wrap {
    > div {
      box-sizing: border-box;
      height: 50px;
      padding: 16px 30px;
    }
  }
`

const Phone = styled.div`
  width: 235px;
  margin: 0 auto;
  position: relative;
  padding: 10px 22px 22px 12px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 0;
  min-height: 400px;
  &:before {
    content: ' ';
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    border-radius: 24px;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-phone-wrap.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  &:after {
    content: '';
    width: 30%;
    height: 25px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-54%);
    z-index: 1;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-phone-detail.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  &.phone-1 {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? css`
              transform: translate(-8%, 10%);
              z-index: 3;
            `
          : css`
              transform: translate(-8%, -10%);
            `}
      `}
  }
  &.phone-2 {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? css`
              transform: translate(8%, -10%);
            `
          : css`
              transform: translate(8%, 10%);
            `}
      `}
  }
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 211.49%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 16px;
  }
`

const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const MorphWrapFirst = styled.div`
  width: 70%;
  position: absolute;
  top: -3.2%;
  left: -23%;
  svg {
    width: 100%;
    g {
      path {
        fill: ${getStyleForProp('dotColor')};
      }
    }
  }
`

const MorphWrapSecond = styled.div`
  width: 70%;
  position: absolute;
  bottom: -1.5%;
  left: -23%;
  svg {
    width: 100%;
    g {
      path {
        fill: ${getStyleForProp('dotColor')};
      }
    }
  }
`

const AddImageButtonWrap = styled.div`
  &.add-apple-image-button {
    transform: translate(-60px, -40px);
    @media only screen and (max-width: 650px) {
      transform: translate(-60px, -40px) scale(1.5);
    }
  }
  &.add-android-image-button {
    transform: translate(50px, -170px);
    @media only screen and (max-width: 650px) {
      width: 208px;
      transform: translate(50px, -170px) scale(1.5);
    }
  }
`

const MockupsWrap = styled.div`
  display: flex;
  align-items: flex-end;
  transform: translateX(-20%);
  .image-state-overlay,
  .failed-image-container {
    z-index: 2;
  }
  ${({ theme }) =>
    css`
      ${theme.orientation === 'LEFT'
        ? css`
            transform: translateX(-20%);
            @media only screen and (max-width: 1440px) {
              transform: translateX(-10%);
            }
          `
        : css`
            transform: translateX(20%);
            @media only screen and (max-width: 1440px) {
              transform: translateX(10%);
            }
          `}
    `}
  @media only screen and (max-width: 1300px) {
    transform: none;
    margin: ${({ theme }) =>
      theme.orientation === 'RIGHT'
        ? 'auto auto 40px auto'
        : '40px auto auto auto'};
  }
  ${({ isOneMockup }) =>
    css`
      ${isOneMockup
        ? css`
            margin: auto;
            ${Phone} {
              transform: translate(4%, 18px);
            }
            @media only screen and (max-width: 650px) {
              transform: none;
              margin: ${({ theme }) =>
                theme.orientation === 'RIGHT'
                  ? '0 auto 50px auto'
                  : '50px auto 0 auto'};
              ${Phone} {
                transform: none;
              }
            }
          `
        : css`
            padding: ${({ theme }) =>
              theme.orientation === 'RIGHT'
                ? '60px 0 55px 0;'
                : '50px 0 35px 0;'};
            @media only screen and (max-width: 650px) {
              padding: 50px 0 45px 0;
              transform: translateY(60px) scale(0.65);
              margin: ${({ theme }) =>
                theme.orientation === 'RIGHT'
                  ? '-110px 0 0 0'
                  : '-110px 0 -30px 0'};
            }
            @media only screen and (max-width: 370px) {
              transform: translateY(80px) scale(0.5);
              margin: ${({ theme }) =>
                theme.orientation === 'RIGHT'
                  ? '-130px 0 0 0'
                  : '-130px 0 -30px 0'};
            }
          `}
    `}
  &:only-child {
    margin-top: 0;
  }
`

const PhoneWrap = styled.div``

export default {
  StyledContainer,
  WmCustomContainer,
  LandingContent,
  Section,
  Title,
  Paragraph,
  BadgeContainer,
  PhoneWrap,
  Phone,
  MobileImg,
  MorphWrapFirst,
  MorphWrapSecond,
  MockupsWrap,
  AddImageButtonWrap
}
