import styled, { css, createGlobalStyle } from 'styled-components'
import { scrollBarStyles } from '@editor/common/styles'

import CommonSettingsIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'

const BLUE = '#387DFF'

const activeItemStyles = css`
  border: 1px solid ${BLUE};
  color: ${BLUE};
  & .home-icon {
    fill: ${BLUE};
  }
`

export const GlobalStyles = createGlobalStyle`
  .page-options-flyout_container {
    box-shadow: 0px 2px 10px #0052E01A;
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  @media only screen and (min-width: 769px) {
    border: 1px solid #edeff6;
    width: 280px;
  }
`

export const Header = styled.h3`
  padding: 15px 20px;
  color: #252e48;
  font-size: 14px;
  border-bottom: 1px solid #c1c9e0;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const Content = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid #c1c9e0;
  max-height: calc(100vh - 300px);
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    max-height: 320px;
  }
  @media only screen and (min-width: 1441px) {
    max-height: 500px;
  }
  ${scrollBarStyles}
`

export const Footer = styled.div`
  display: flex;
  padding: 10px 20px;
`

export const PageItem = styled.li`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  align-items: center;
  padding: 0 5px 0 15px;
  border: 1px solid #dee3f0;
  border-radius: 6px;
  cursor: pointer;
  flex-grow: 1;
  color: #363f5a;
  font-size: 14px;
  ${({ isActive }) => (isActive ? activeItemStyles : '')}
`

export const Pages = styled.ul`
  width: 100%;
  & ${PageItem}:not(:last-child) {
    margin-bottom: 10px;
  }
`

export const PageName = styled.p`
  width: calc(100% - 90px);
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  & > svg {
    margin-right: 10px;
  }
  & > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  @media only screen and (max-width: 768px) {
    width: calc(100% - 105px);
  }
`

// ::::: Page Options :::::

const PAGE_OPTION_BUTTON_STYLES = css`
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  &:hover {
    background: #eef5ff;
    & > svg {
      & g {
        stroke: #36405a;
      }
    }
  }
  & > svg {
    & g {
      stroke: #7684a8;
    }
    width: 20px;
  }
`

export const PageOptionsButton = styled.div`
  ${PAGE_OPTION_BUTTON_STYLES};
`

export const PageOptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 150px;
`

export const PageOption = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: white;
  width: 100%;
  height: 40px;
  &:hover {
    background-color: #eef5ff;
  }
  & > svg {
    margin-left: 15px;
    flex-shrink: 0;
    width: 18px;
    height: auto;
    fill: #36405a;
  }
  & > span {
    font-size: 12px;
    color: #363f5a;
    margin-left: 15px;
  }
`

export const MobilePageOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const MobileOptionItem = styled.div`
  ${PAGE_OPTION_BUTTON_STYLES};
  margin-left: 5px;
  &:hover {
    & > svg > g {
      stroke: transparent;
    }
  }
  & > svg {
    & > g {
      stroke: transparent;
    }
    fill: #9aa6c8;
    width: 25px;
  }
`

// ::::: End of Page Options :::::

export const AddButton = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #eef5ff;
  border-radius: 5px;
  height: 35px;
  flex-grow: 1;
  & > span {
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #3271e6;
    & > b {
      font-weight: 500;
      font-size: 25px;
      margin-right: 5px;
    }
  }
  &:hover {
    & > span {
      color: white;
    }
    background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%);
  }
`

export const OptionsContainer = styled.div`
  display: flex;
`
export const OptionItem = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  & > svg {
    fill: #9aa6c8;
  }

  &:hover {
    background: #eef5ff;
    & > svg {
      fill: #363f5a;
    }
  }
`

export const SettingsIcon = styled(CommonSettingsIcon)`
  width: 20px;
  height: 20px;
`
