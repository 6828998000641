import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    backgroundColor: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`,
    removeIcon: p => `rgba(${hexToRgb(p.accentWhite)}, 0.5)`,
    activeBorder: p => p.tertiaryLight,
    additionalButton: p => p.primaryLight,
    label: p => p.accentWhite
  },
  LIGHT: {
    backgroundColor: p => p.tertiaryLight,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`,
    removeIcon: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    activeBorder: p => p.tertiaryLight,
    additionalButton: p => p.primaryDark,
    label: p => p.tertiaryDark
  },
  EXTRALIGHT: {
    backgroundColor: p => p.tertiaryExtraLight,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`,
    removeIcon: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    activeBorder: p => p.tertiaryLight,
    additionalButton: p => p.primaryDark,
    label: p => p.tertiaryDark
  }
}
