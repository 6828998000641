/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Pricing6.theme'
import { styleProvider } from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  padding: ${props => (props.isEditing ? '135px 0 0 0' : '0')};
`

const Count = styled.p`
  font-size: 400px;
  font-weight: bold;
  color: #f0f2f7;
  position: absolute;
  right: 0;
  top: 50px;
  line-height: 0.5;
  color: ${getStyleForProp('countColor')};
  opacity: 0.2;
  @media only screen and (max-width: 992px) {
    font-size: 300px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 250px;
  }
`

const IconContainer = styled.button`
  padding: 0;
  width: 40px;
  min-width: 40px;
  height: 40px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #b3beeb;
  &:focus {
    outline: 1px solid #5690ff;
  }
  i {
    color: #b3beeb;
  }
`
const Icon = styled(LibIcon).attrs(() => ({
  color: '#b3beeb'
}))``

const SliderTitle = styled.h4`
  color: ${getStyleForProp('heading')};
  text-align: left;
  font-weight: 600;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`
const SliderParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  padding: 14px 0;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`

const TextContainer = styled.div`
  background: ${getStyleForProp('background')};
  position: relative;
  width: 50%;
  padding: 80px 8vw 80px 8vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-word;
  @media only screen and (max-width: 1440px) {
    padding: 80px 4vw 100px 4vw;
  }
  @media only screen and (max-width: 1020px) {
    width: 100%;
    padding: 80px 8vw 120px 8vw;
  }
`

const SlideContainer = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  display: flex;
  ${props =>
    props.animate &&
    css`
      animation: slidein 500ms linear;
      @keyframes slidein {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
    `}
  @media only screen and (max-width: 1020px) {
    flex-direction: column-reverse;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-shadow: ${getStyleForProp('raisedShadow')};
`

const DotsWrap = styled.div`
  svg {
    fill: ${getStyleForProp('background')};
  }
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primaryDark};
`

const MenuItemTop = styled.div`
  border-bottom: 1px dotted;
  border-color: ${({ theme }) => theme.palette.tertiaryNormal};
  padding: 0 0 6px 0;
  position: relative;
`

const ItemName = styled.p`
  color: ${getStyleForProp('paragraph')};
  font-weight: bold;
`

const ItemPrice = styled.p`
  min-width: 40px;
  text-align: right;
  margin-left: auto;
  font-size: 18px;
  font-weight: 500;
  color: ${getStyleForProp('price')};
  word-break: keep-all;
`

const ItemInfo = styled.p`
  font-size: 12px;
  text-align: right;
  color: ${getStyleForProp('info')};
  white-space: nowrap;
  margin: 0 0 0 4px;
`

const ItemParagraph = styled.p`
  max-width: 80%;
  font-size: 12px;
  color: ${getStyleForProp('price')};
`

const Badge = styled.div`
  word-break: keep-all;
  font-size: 10px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: ${getStyleForProp('badgeRadius')};
  font-weight: bold;
  background-color: ${({ color }) => color};
`
const Div = styled.div``

const SlideCounter = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 42px;
  color: ${getStyleForProp('slideCounter')};
  font-weight: bold;
  display: ${props => (props.isEditing ? 'block' : 'none')};
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export default {
  Img,
  Container,
  Count,
  Div,
  SliderTitle,
  TextContainer,
  IconContainer,
  ImageContainer,
  DotsWrap,
  SlideContainer,
  SliderParagraph,
  Anchor,
  MenuItemTop,
  ItemName,
  ItemPrice,
  ItemInfo,
  ItemParagraph,
  Badge,
  SlideCounter,
  Icon
}
