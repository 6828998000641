/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        draft.buttons.push(initialState.buttons[0])
        break
      case 'CHANGE_CODE':
        draft.customCode = action.value
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions: _dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = _dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
