// @flow

export const getRightOffsetFromActive = (
  idx: number,
  activeIdx: number,
  length: number
): number => (idx + length - activeIdx) % length

export const getLeftOffsetFromActive = (
  idx: number,
  activeIdx: number,
  length: number
): number => (activeIdx + length - idx) % length

const getCountToShow = (desiredCount: number, slidesLength: number) => {
  let count
  if (slidesLength > 5) {
    count = 5
  } else if (slidesLength > 3) {
    count = 3
  } else {
    count = 1
  }

  return Math.min(count, desiredCount)
}

export const getSliderProps = (
  slidesLength: number,
  breakPoints = [1024, 767]
) => ({
  focusOnSelect: true,
  infinite: true,
  speed: 500,
  lazyLoad: 'progressive',
  slidesToShow: getCountToShow(5, slidesLength),
  className: 'team6-slider',
  centerMode: true,
  centerPadding: 0,
  responsive: [
    {
      breakpoint: breakPoints[0],
      settings: {
        slidesToShow: getCountToShow(3, slidesLength)
      }
    },
    {
      breakpoint: breakPoints[1],
      settings: {
        slidesToShow: 1
      }
    }
  ]
})
