/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature21.styled'
import ActionGetter from './Actions'
import { validation } from './Feature21.consts'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import LinkWrapper from '@website/common/components/LinkWrapper'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Feature21 = memo(({ data: { blocks } }) => {
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.Container isEditing={isEditing}>
      <Mapper
        data={blocks}
        render={(block: string, idx: number) => (
          <S.BlockWrapper key={idx}>
            <S.Block isEven={idx % 2 === 0}>
              <S.Content
                onlyTitle={
                  !isEditing && !(block.paragraph || block.buttonText)
                    ? true
                    : false
                }
              >
                <EditableContent
                  text={block.title}
                  alignment={block.blockTitleAlignment}
                  as={S.Title}
                  maxCount={validation.titleMaxChar}
                  required={validation.title.required}
                  className="WM_GLOBAL_heading42"
                  onChange={(newTitle: string) =>
                    Actions.changeTitle(newTitle, idx)
                  }
                  changeAlignment={(alignment: string) =>
                    Actions.changeBlockTitleAlignment(alignment, idx)
                  }
                />
                <EditableContent
                  text={block.paragraph}
                  alignment={block.blockParagraphAlignment}
                  as={S.Paragraph}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={(newParagraph: string) =>
                    Actions.changeParagraph(newParagraph, idx)
                  }
                  changeAlignment={(alignment: string) =>
                    Actions.changeBlockParagraphAlignment(alignment, idx)
                  }
                />
                <If
                  condition={block.buttonText}
                  otherwise={() => (
                    <AddButton
                      onAdd={() => Actions.addLink(idx)}
                      toShow={block.buttonText === ''}
                      style={{ marginTop: '30px' }}
                      rounded
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      {translate('add_button_label')}
                    </AddButton>
                  )}
                  then={() => (
                    <S.ButtonWrap>
                      <ControlsWithModalOpener
                        actions={Actions.buttonActions(idx, {
                          text: block.buttonText,
                          link: block.buttonUrl
                        })}
                      >
                        <LinkWrapper
                          url={block.buttonUrl}
                          text={block.buttonText}
                          linkProps={{
                            className: 'WM_GLOBAL_secondary-font'
                          }}
                        />
                      </ControlsWithModalOpener>
                    </S.ButtonWrap>
                  )}
                />
              </S.Content>
              <S.ImageWrapper>
                <S.ControlContainer>
                  <ControlsWithImageCropper
                    actions={Actions.imageActions(idx, block.imgUrl)}
                    style={{ width: '100%' }}
                  >
                    <Image
                      as={S.Img}
                      sizes={block.imgDimensions}
                      defaultImgSrc={block.imgUrl}
                      asProps={{
                        imgCropParams: block.imgCropParams
                      }}
                      alt="Portfolio image"
                    />
                  </ControlsWithImageCropper>
                </S.ControlContainer>
              </S.ImageWrapper>
            </S.Block>
            <If
              condition={isEditing}
              then={() => (
                <S.AddButtonsWrap>
                  <AddButton
                    onAdd={() => {
                      dispatcher(
                        Actions.removeBlock(
                          idx,
                          blocks.length > validation.blocks.min
                        )
                      )
                    }}
                    toShow={blocks.length > validation.blocks.min}
                    style={{
                      display: 'inline-flex',
                      alignItems: 'center'
                    }}
                  >
                    <Icon name="glyph-remove" className="icon" size="normal" />
                    {translate('remove_block_label')}
                  </AddButton>
                  <AddButton
                    onAdd={() => Actions.addBlock(idx)}
                    toShow={blocks.length < validation.blocks.max}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_block_label')}
                  </AddButton>
                </S.AddButtonsWrap>
              )}
            />
          </S.BlockWrapper>
        )}
      />
    </S.Container>
  )
})

export default Feature21
