export default {
  DARK: {
    heading: p => p.primaryLight,
    paragraph: p => p.accentWhite,
    line: p => p.primaryLight,
    blockTitle: p => p.accentWhite,
    blockParagraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    line: p => p.primaryDark,
    blockTitle: p => p.primaryDark,
    blockParagraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    line: p => p.primaryDark,
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
