/* @flow */

export const validation = {
  titleMaxChar: 50,
  paragraphMaxChar: 1500,
  title: { required: true },
  images: {
    min: 1,
    max: 3
  },
  buttons: {
    min: 0,
    max: 2
  }
}
