//@flow
import React, { useContext, useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import { GlobalStyles } from './styled'
import ImageIcon from '@editor/common/assets/svgr-icons/Image_icon.svg'
import VideoIcon from '@editor/common/assets/svgr-icons/Video_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { StyledControlItem } from '@website/common/components/Controlled/Controls/styled'
import { FLYOUT_SHIFT } from '@editor/common/lib/FlyOutMenu/FlyOut/consts'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import { ControlModalsContext, MobileDetectContext } from '@contexts'
import { selectIsUserAuthorized } from '@selectors'
import { openLoginPopup } from '@actions/editor'
import { translate } from '@editor/common/utils/translations'
import * as imageSizes from '@editor/conf/imageSizes'

const MediaControl = props => {
  const {
    handler,
    type,
    keyForSizes,
    withImgSizes,
    link,
    isVideo,
    isUserAuthorized,
    mediaFlyoutShift,
    openLoginPopup
  } = props
  const isMobile = useContext(MobileDetectContext)
  const { modalStates, setModalState } = useContext(ControlModalsContext)
  const controlRef = useRef(null)

  const isFlyoutOpen = modalStates[MODAL_TYPES.mediaModal].isOpen
  const tooltipText =
    isMobile || (isFlyoutOpen && !!mediaFlyoutShift)
      ? ''
      : isVideo
      ? translate('choose_a_video_label')
      : translate('choose_an_image_label')

  useEffect(
    () => () => {
      !isMobile &&
        setModalState(MODAL_TYPES.mediaModal, {
          isOpen: false,
          isFlyoutPositionFixed: true,
          shift: FLYOUT_SHIFT
        })
    },
    []
  )

  const onControlClick = () => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }

    setModalState(MODAL_TYPES.mediaModal, {
      type,
      handler,
      link,
      withImgSizes,
      optimizationDimensions: imageSizes[keyForSizes],
      isOpen: !isFlyoutOpen,
      targetRef: controlRef,
      alignment: 'left',
      shift: mediaFlyoutShift || { x: 0, y: 42 },
      renderingContainer: 'secondary-media-control',
      isFlyoutPositionFixed: false,
    })
  }

  return (
    <>
      <GlobalStyles />
      <TooltipWrapper
        text={tooltipText}
        position="top"
        color="#252E48"
        className="secondary-media-control"
      >
        <StyledControlItem ref={controlRef} onClick={onControlClick}>
          {isVideo ? <VideoIcon /> : <ImageIcon />}
        </StyledControlItem>
      </TooltipWrapper>
    </>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

const mapDispatchToProps = { openLoginPopup }

export default connect(mapStateToProps, mapDispatchToProps)(MediaControl)
