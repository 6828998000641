/* @flow */
import styled, { css } from 'styled-components'
import LibIcon from '@website/common/components/Icon'
import THEME from './Feature19.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 130px;
        `
      : css``};
  @media only screen and (max-width: 700px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding-top: 110px;
          `
        : css``};
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
  justify-content: space-between;
  @media only screen and (max-width: 1023px) {
    & > div:first-child {
      ${({ theme }) =>
        css`
          margin: ${theme.orientation === 'RIGHT'
            ? '40px auto 0 auto'
            : '0 auto 40px auto'};
        `}
    }
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
    }
  }
`

const FeatureContent = styled.div`
  width: 45%;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: relative;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'LEFT' ? '0 20px 0 0 ' : '0 0 0 20px'};
    `}
  text-align: left;
  > *:first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: 1023px) {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  .buttons-block {
    margin-left: -15px;
    .control-container {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 1023px) {
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 20px;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 10px 0 0 0;
  > div,
  > li {
    &:not(:last-child) {
      margin: 0 12px 24px 0;
    }
  }

  @media only screen and (max-width: 1023px) {
    align-self: center;
    max-width: 400px;
  }
  .add-button-wrap {
    text-align: left !important;
    @media only screen and (max-width: 1023px) {
      text-align: center !important;
    }
  }
`

const ListItemWrap = styled.li`
  display: flex;
  align-items: center;
`

const ListItem = styled.p`
  color: ${getStyleForProp('paragraph')};
  text-align: left;
`

const IconWrap = styled.div`
  margin: 0 16px 0 0;
  user-select: none;
`

const Icon = styled(LibIcon).attrs(props => ({
  color: getStyleForProp('icon')(props)
}))``

const ImageWrap = styled.div`
  width: 45%;
  font-size: 0;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'LEFT' ? '0 0 0 20px' : '0 20px 0 0'};
    `}
  @media only screen and (min-width: 1023px) {
    &:only-child {
      transform: translateX(-50%);
      position: sticky;
      left: 50%;
    }
  }
  @media only screen and (max-width: 1023px) {
    &:only-child {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
`

const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: rgb(37 43 52 / 12%) 0px 9px 22px 0px;
  .LazyLoad {
    position: relative;
    padding-top: 75%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  .control-container {
    margin-bottom: 0;
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export default {
  WmCustomContainer,
  ImageWrap,
  ImageFigure,
  Img,
  StyledContainer,
  FeatureContent,
  Title,
  Paragraph,
  ListItemWrap,
  List,
  ListItem,
  Icon,
  IconWrap
}
