/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header54.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import LibIcon from '@website/common/components/Icon'

export const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  flex-direction: column;
  padding-top: 0;
  ${getBackgroundStyles(THEME)};
  position: relative;
`

export const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
`

export const HeaderContent = styled.div`
  padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  ${({ theme }) =>
    theme.orientation === 'RIGHT'
      ? css`
          flex-direction: row-reverse;
        `
      : css``};
  @media only screen and (max-width: 1024px) {
    align-items: center;
    margin-bottom: 60px;
    ${({ theme }) =>
      theme.orientation === 'RIGHT'
        ? css`
            flex-direction: column-reverse;
          `
        : css`
            flex-direction: column;
          `};
  }
`

export const AnimationContainer = styled.div`
  display: flex;
  background-color: ${getStyleForProp('elementColor')};
  position: absolute;
  z-index: 2;
  bottom: 30px;
  transform: rotate(6deg);
  width: calc(100vw + 40px);
  max-width: calc(100vw + 40px);
  left: calc(((100vw - 95%) / 2) * -1);
  ${({ isEditing }) =>
    isEditing
      ? css``
      : css`
          overflow: hidden;
        `}
  @media only screen and (max-width: 1024px) {
    bottom: 120px;
    padding-left: ${({ isEditing }) => !isEditing && '0px'};
    padding-right: ${({ isEditing }) => !isEditing && '0px'};
  }
  @media only screen and (max-width: 810px) {
    width: calc(127vw + 40px);
    max-width: calc(127vw + 40px);
    left: calc(((127vw - 95%) / 2) * -1);
  }
  @media only screen and (max-width: 768px) {
    left: calc(((144vw - 95%) / 2) * -1);
  }
  @media only screen and (max-width: 457px) {
    bottom: 90px;
    left: calc(((150vw - 95%) / 2) * -1);
  }
`

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${({ isEditing }) => (!isEditing ? '210px' : '240px')};
  width: 100%;
  max-width: 370px;
  margin-right: 30px;
  z-index: 2;
  position: relative;
  & > .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    margin-bottom: 40px;
    margin-right: 0px;
  }
`

export const ImageBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  & > .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 755px;
    flex-direction: column;
    align-items: flex-end;
  }
`

export const CenterContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 445px;
  position: relative;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 755px;
    flex-direction: column;
  }

  & > .control-container {
    position: absolute !important;
    z-index: 2;
    bottom: 30px;
    transition: transform 0.5s;
    transition-delay: 0.2s;
    transform: rotate(6deg);
    width: calc(100vw + 40px) !important;
    max-width: calc(100vw + 40px);
    left: calc(((100vw - 95%) / 2) * -1);
    max-height: 68px;

    &:focus-within {
      transform: rotate(0deg);
    }
    & > div {
      transform: rotate(0);
      bottom: 0;
    }

    .secondary-controls {
      left: 30px;
      transform: translateY(-50px);
    }
  }

  & .add-button-wrap {
    margin-top: 20px;
    margin-bottom: 30px;
    & > div {
      padding: 16px 30px;
    }
  }
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  max-width: 370px;
  position: relative;
  margin-left: 30px;
  margin-bottom: ${({ isEditing }) => (!isEditing ? '95px' : '125px')};
  box-sizing: border-box;
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    & > h2,
    p {
      max-width: 100%;
      margin-bottom: 0;
    }
  }
  .learn-more-link {
    color: ${getStyleForProp('link')};
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    align-self: flex-start;
    margin: 0;
    padding: 0;
    max-width: initial;
    margin-top: 40px;
    margin-bottom: 0;
    &:empty {
      margin-top: 0px;
    }
  }
  .add-button-wrap {
    > div {
      margin-top: 0 !important;
      height: 50px;
      box-sizing: border-box;
      padding: 16px 30px;
    }
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

export const SubTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 20px;
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
  & + div {
    margin-top: 30px;
  }
`

export const ImageWrapper = styled.div`
  max-width: 445px;
  width: 100%;
  position: relative;
  padding-top: 145%;
  @media only screen and (max-width: 1024px) {
    max-width: 755px;
    margin-left: auto;
    margin-right: auto;
  }
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    transform: none;
    padding-top: 145%;
    height: initial;
  }
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
`

export const ImageElement = styled.div`
  width: 54px;
  height: 54px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, 50%);
  @media only screen and (max-width: 1024px) {
    width: 120px;
    height: 120px;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
  .icon {
    margin-right: 0;
  }
  .add-button-wrap {
    width: max-content;
    bottom: 0;
    margin-top: 0;
    & > div {
      padding: 16px;
    }
  }
`

export const ElementWrapper = styled.div`
  svg:first-child {
    width: 100%;
    path {
      fill: ${getStyleForProp('elementColor')};
    }
  }
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

export const ScrollText = styled.p`
  min-width: 100px;
  text-align: center;
  padding: 0 15px;
  color: ${getStyleForProp('paragraph')};
`

export const BottomContent = styled.div`
  z-index: 1;
  width: 100%;
  max-width: 600px;
  margin: 20px auto -50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    margin-top: 0;
  }
`

export const DownButton = styled.div`
  padding: 0 20px 20px 20px;
  animation: nextComponentArrow 2s linear alternate-reverse infinite;
  @media only screen and (max-width: 1200px) {
    padding: 0 20px 10px 20px;
  }
  @keyframes nextComponentArrow {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: translateY(0);
    }
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.palette.accentWhite};
  line-height: 1.8;
  white-space: nowrap;
  margin-bottom: 0;
  ${({ isEditing }) =>
    isEditing
      ? css`
          overflow: hidden;
          width: 100%;
          padding-top: 15px;
          padding-bottom: 15px;
        `
      : css`
          width: max-content;
        `}
  &:after,
  &:before {
    top: 74% !important;
  }
`

export const IconWrap = styled.div`
  margin-right: 20px;
  width: 24px;
  height: 24px;
  padding: 10px;
  ${({ isEditing }) =>
    isEditing
      ? css`
          margin-left: 20px;
        `
      : css``}
  & > div > svg {
    height: 24px;
    fill: ${({ theme }) => theme.palette.accentWhite};
  }
  & > .control-container {
    width: max-content !important;
    min-width: auto !important;
    & > div > svg {
      fill: ${({ theme }) => theme.palette.accentWhite};
    }
  }
`

export const ItemTextOpacity = styled.div`
  opacity: 0;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: absolute;
`

export const ItemText = styled.div`
  width: max-content;
  ${({ isEditing }) =>
    !isEditing
      ? css`
          padding-right: 20px;
          box-sizing: border-box;
        `
      : css`
          max-width: 100%;
          margin-left: auto;
          margin-right: auto;
        `}
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ isEditing }) => (!isEditing ? 'animation-name: textRun;' : '')}
  animation-duration: ${({ animationSpeed }) => `${animationSpeed}s`};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  .control-container > div > svg {
    height: 24px;
    fill: ${getStyleForProp('iconColor')};
  }
  & > .control-container {
    width: 90%;
    min-width: 133px;
    @media only screen and (max-width: 1024px) {
      max-width: calc(100vw - 265px);
    }
    @media only screen and (max-width: 768px) {
      max-width: calc(100% - 300px);
    }
    @media only screen and (max-width: 457px) {
      width: calc(100% - 100px);
    }
    p {
      margin-top: 0;
      min-width: 133px;
    }
  }
  & > .add-button-wrap {
    position: static !important;
    margin-top: 0;
    margin-bottom: 0;
    & > div {
      padding: 4px 15px !important;
    }
  }
  p {
    margin-bottom: 0;
  }
  @keyframes textRun {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

export const DescriptionOpacity = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  white-space: nowrap;
  margin-bottom: 0;
  width: max-content;
`

export const ScrollIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('paragraph')
}))``
