export const validation = {
  headingMaxChar: 100,
  paragraphMaxChar: 300,
  blockTitleMaxChar: 100,
  blockParagraphMaxChar: 100,
  blockContentMaxChar: 500,
  steps: {
    min: 1,
    max: 15
  }
}
