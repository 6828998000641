import styled from 'styled-components'

export const TooltipContent = styled.div`
  max-width: 280px;
  background-color: #363f5a;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #252e484d;
  box-sizing: border-box;
  p {
    width: 100%;
    font-size: 14px;
    color: #ffffff;
    padding-top: 10px;
  }
  img {
    width: 100%;
  }
`
