export const validation = {
  titleMaxChar: 200,
  paragraphMaxChar: 1500,
  title: { required: true },
  paragraph: { required: true },
  buttons: {
    min: 0,
    max: 2
  }
}
