// @flow

import React, { memo, useRef } from 'react'

import {
  UserInfo,
  MyProjects,
  MoreLinks,
  Languages,
  SignOutButton,
  SignInButton
} from '../user-components'
import * as Styled from './styled'

const Content = ({ closeDropdown, isUserAuthorized }) => {
  const ref = useRef(null)

  return (
    <Styled.Content ref={ref}>
      {isUserAuthorized ? (
        <>
          <UserInfo />
          <Styled.ScrollableContent>
            <MyProjects closeDropdown={closeDropdown} />
            <MoreLinks closeDropdown={closeDropdown} />
            <Languages closeDropdown={closeDropdown} contentRef={ref} />
            <SignOutButton closeUserDropdown={closeDropdown} />
          </Styled.ScrollableContent>
        </>
      ) : (
        <>
          <Languages
            closeDropdown={closeDropdown}
            contentRef={ref}
            hasMaxHeightAnimation
          />
          <SignInButton />
        </>
      )}
    </Styled.Content>
  )
}

export default memo(Content)
