/* @flow */
import {
  addLink,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import { spotifyTrackEditActionMaker } from '@website/components/ActionMakers'

export default dispatcher => {
  const changeName = (newName: string) => ({
    type: 'CHANGE_NAME',
    value: newName
  })

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeName = newName => {
    dispatcher(changeName(newName))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeNameAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeName: _changeName,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNameAlignment,
    addLink,
    musicActions: spotifyTrackEditActionMaker(dispatcher)
  }
}
