/* @flow */
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TPayPalButton } from './types'

const PayPalButton = memo(({ buttonText, buttonStyle }: TPayPalButton) => (
  <Styled.Button buttonStyle={buttonStyle}>
    <Styled.IconWrapper />
    <Styled.ButtonText className="WM_GLOBAL_paragraph">
      {buttonText}
    </Styled.ButtonText>
  </Styled.Button>
))

export default PayPalButton
