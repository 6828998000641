/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer3.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '130px 0 50px 0' : '50px 0')};
  background-color: ${getStyleForProp('background')};
  .socials-block {
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    & > .control-container:nth-last-child(-n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
  }
  .socials-wrapper {
    flex-wrap: nowrap;
    & > .control-container:nth-last-child(-n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
    .link {
      fill: #ffffff;
      border-radius: ${getStyleForProp('iconRadius')};
      ${getStyleForProp('buttonBackground')};
    }
  }
`

const FooterContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  @media only screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
  > .add-button-wrap {
    min-width: 125px;
  }
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 992px) {
    margin: 60px 0 0 0;
    justify-content: center;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo-wrapper {
    display: block;
    margin-right: 20px;
    @media only screen and (max-width: 800px) {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  .add-button-wrap {
    margin-right: 20px;
    min-width: 125px;
  }
`

const CopyRightWrap = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
  }
`

const FooterLogo = styled.img.attrs(({ _width }) => {
  return _width
    ? {
        style: { width: `${_width}px` }
      }
    : {}
})`
  height: auto;
  object-fit: contain;
  height: auto;
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  color: ${getStyleForProp('paragraph')};
  @media only screen and (min-width: 801px) {
    margin-right: 20px;
    box-sizing: border-box;
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            max-width: calc(100% - 210px);
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 0;
            }
          `
        : css`
            max-width: 100%;
          `}
  }
  @media only screen and (max-width: 800px) {
    text-align: center;
  }
`

const LinkWrap = styled.div`
  position: relative;
  margin-right: 10px;
  max-width: 170px;
  min-width: 100px;
  &:hover {
    a {
      opacity: 0.6;
    }
  }
  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }
  @media only screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
    .control-container {
      margin-left: 0;
    }

    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
`

export default {
  Container,
  FooterContentWrap,
  FooterContent,
  FooterLogo,
  Copyright,
  LinkWrap,
  CopyRightWrap
}
