/* @flow */
/* eslint-disable max-statements */
import React, {
  useContext,
  useState,
  useCallback,
  useEffect,
  useRef,
  memo
} from 'react'
import Slider from 'react-slick'
import '../Swipe/swipe-slider-styles.css'

import Quote from '@website/common/assets/svgr-icons/quote.svg'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { validation, sliderSettings } from './Testimonials5.consts'
import ResizeObserver from '@website/common/utils/ResizeObserver'
import { getMaxHeight } from './Testimonials5.utils'
import * as S from './Testimonials5.styled'
import ActionGetter from './Actions'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import Arrow from '@editor/common/assets/svgr-icons/arrow_big_left.svg'
import { translate } from '@editor/common/utils/translations'

const SLIDE_WRAPPER_CALSSNAME = 'slide-wrapper'

const PrevArrow = memo(({ onClick }) => (
  <TooltipWrapper text="Previous" color="#252E48" position="top">
    <S.SliderArrow isPrev onClick={onClick}>
      <Arrow />
    </S.SliderArrow>
  </TooltipWrapper>
))

const NextArrow = memo(({ onClick }) => (
  <TooltipWrapper text="Next" color="#252E48" position="top">
    <S.SliderArrow onClick={onClick}>
      <Arrow />
    </S.SliderArrow>
  </TooltipWrapper>
))

const Testimonials5 = memo(
  ({
    data: {
      slides,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const [activeSlideIdx, setActiveSlideIdx] = useState(0)
    const [slidesMaxHeight, setSlidesMaxHeight] = useState(0)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const slickSliderRef = useRef(null)
    const sliderContainerRef = useRef(null)
    const resizeObserver = useRef([])

    const setSlideHeight = () => {
      if (!sliderContainerRef.current) {
        return 0
      }
      const elementsToObserve = sliderContainerRef.current.querySelectorAll(
        `.${SLIDE_WRAPPER_CALSSNAME}, .testimonials-dots-wrap`
      )
      const maxHeight = getMaxHeight(elementsToObserve)
      setSlidesMaxHeight(maxHeight)
    }

    useEffect(() => {
      const elementsToObserve = document.querySelectorAll(
        `.${SLIDE_WRAPPER_CALSSNAME}, .testimonials-dots-wrap`
      )
      resizeObserver.current = new ResizeObserver(setSlideHeight)
      for (const elem of elementsToObserve) {
        resizeObserver.current.observe(elem)
      }
      return () => {
        resizeObserver.current.disconnect()
      }
    }, [setSlideHeight])

    const goToSlide = useCallback(
      idx => {
        slickSliderRef.current.slickGoTo(idx)
      },
      [slickSliderRef.current]
    )

    const next = useCallback(() => {
      goToSlide(activeSlideIdx + 1)
    }, [activeSlideIdx, goToSlide])

    const prev = useCallback(() => {
      goToSlide(activeSlideIdx - 1)
    }, [activeSlideIdx, goToSlide])

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
        isEditing={isEditing}
      >
        <S.WmCustomContainer>
          <S.TestimonialsContainer height={slidesMaxHeight}>
            <S.SliderContainer ref={sliderContainerRef}>
              <If
                condition={isEditing && slides.length > 1}
                then={() => (
                  <S.SliderArrowWrapperMobile>
                    <PrevArrow onClick={prev} />
                    <NextArrow onClick={next} />
                  </S.SliderArrowWrapperMobile>
                )}
              />
              <Slider
                {...sliderSettings}
                swipeToSlide={!isEditing}
                swipe={!isEditing}
                accessibility={!isEditing}
                verticalSwiping={!isEditing}
                autoplay={!isEditing}
                className="slider"
                ref={slickSliderRef}
                beforeChange={(_, newIdx) => {
                  setActiveSlideIdx(newIdx)
                }}
              >
                {slides.map((slide, idx) => (
                  <S.SlideWrapper
                    className={SLIDE_WRAPPER_CALSSNAME}
                    isEditing={isEditing}
                    backgroundImgUrl={slide.backgroundImgUrl}
                    backgroundImgDimensions={slide.backgroundImgDimensions}
                  >
                    <S.Info
                      key={idx}
                      isEditing={isEditing}
                      isActive={idx === activeSlideIdx}
                    >
                      <S.QuoteWrapper>
                        <Quote />
                      </S.QuoteWrapper>
                      <EditableContent
                        text={slide.title}
                        as={S.Title}
                        alignment={slide.slideTitleAlignment}
                        className="WM_GLOBAL_heading20 slide-title"
                        required={!slide.paragraph}
                        maxCount={validation.titleMaxChar}
                        onChange={(newTitle: string) => {
                          Actions.changeSliderTitle(newTitle, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeSlideTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        text={slide.paragraph}
                        as={S.Paragraph}
                        required={!slide.title}
                        alignment={slide.slideParagraphAlignment}
                        className="WM_GLOBAL_paragraph18 slide-paragraph"
                        maxCount={validation.paragraphMaxChar}
                        onChange={(newParagraph: string) => {
                          Actions.changeSliderParagraph(newParagraph, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeSlideParagraphAlignment(alignment, idx)
                        }
                      />
                    </S.Info>
                  </S.SlideWrapper>
                ))}
              </Slider>
              <If
                condition={isEditing}
                then={() => (
                  <S.ActionsWrap>
                    <S.SlideActionButtons>
                      <AddButton
                        onAdd={() => {
                          Actions.removeSlide(activeSlideIdx)
                        }}
                        toShow={slides.length > validation.slides.min}
                      >
                        <Icon
                          name="glyph-remove"
                          className="icon"
                          size="normal"
                        />
                        {translate('remove_slide_label')}
                      </AddButton>
                      <AddButton
                        onAdd={() => Actions.addSlide(activeSlideIdx)}
                        toShow={slides.length < validation.slides.max}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_slide_label')}
                      </AddButton>
                    </S.SlideActionButtons>
                    <If
                      condition={slides.length > 1}
                      then={() => (
                        <S.SliderArrowWrapperDesktop className="slider-5-arrow-control">
                          <PrevArrow onClick={prev} />
                          <NextArrow onClick={next} />
                        </S.SliderArrowWrapperDesktop>
                      )}
                    />
                  </S.ActionsWrap>
                )}
              />
              <If
                condition={slides.length > 1}
                then={() => (
                  <S.DotsWrap className="testimonials-dots-wrap">
                    {slides.map((_, idx) => (
                      <S.DotWrap key={idx} onClick={() => goToSlide(idx)}>
                        <S.Dot
                          className={idx}
                          isEditing={isEditing}
                          isActive={idx === activeSlideIdx}
                        />
                      </S.DotWrap>
                    ))}
                  </S.DotsWrap>
                )}
              />
            </S.SliderContainer>
          </S.TestimonialsContainer>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Testimonials5
