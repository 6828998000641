/* @flow */
import {
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'
import {
  spotifyTrackEditActionMaker,
  musicItemRemoveActionMaker
} from '@website/components/ActionMakers'
import { validation } from '../Spotify2.consts'
export default dispatcher => {
  const addMusic = () => dispatcher({ type: 'ADD_MUSIC' })

  const deleteMusic = (idx: number) => ({
    type: 'DELETE_MUSIC',
    value: idx
  })

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    addMusic: () => dispatcher(addMusic()),
    deleteMusic,
    musicActions: ({ isSingleTrack, idx, musicCount, url }) => {
      return [
        ...spotifyTrackEditActionMaker(dispatcher)({ idx, isSingleTrack, url }),
        ...musicItemRemoveActionMaker(dispatcher)({
          idx,
          showRemove: musicCount > validation.musicUrls.min
        })
      ]
    }
  }
}
