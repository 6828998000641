export const validation = {
  titleMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 50,
  actions: {
    min: 1,
    max: 20
  },
  buttons: {
    min: 0,
    max: 2
  }
}