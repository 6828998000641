/* @flow */
import styled from 'styled-components'

import THEME from './Text11.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-top: ${({ isEditing }) => isEditing && '150px'};
  @media only screen and (max-width: 700px) {
    padding-top: ${({ isEditing }) => isEditing && '100px'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;

    h1,
    p {
      margin-top: 0;
    }
  }
`

const Content = styled.div`
  width: 60%;
  max-width: 650px;
  margin-right: 30px;
  @media only screen and (min-width: 1500px) {
    max-width: 850px;
    width: 60%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
    margin-right: 0;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
`

const ControlWrapper = styled.div`
  width: 48%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  @media only screen and (min-width: 1024px) {
    &:nth-child(odd) {
      margin-right: 15px;
    }
  }
  & > .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    width: 80%;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`

const InfoContainer = styled.div`
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`

const InfoWrapper = styled.div`
  min-width: 400px;
  max-width: 400px;
  display: flex;
  flex-wrap: wrap;
  margin: -20px auto;
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
    justify-content: center;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    min-width: 300px;
  }
`

const InfoItem = styled.div`
  width: 100%;
  text-align: center;
  padding: ${({ isEditing }) => (isEditing ? '20px' : '0 14px')};
  box-sizing: border-box;
  > p:first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: 800px) {
    padding: ${({ isEditing }) => (isEditing ? '20px' : '0')};
  }
`

const Count = styled.p`
  color: ${getStyleForProp('countColor')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const InfoTitle = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-top: 20px;
  margin-bottom: 0;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.6;
  margin-top: 20px;
`

export default {
  StyledContainer,
  WmCustomContainer,
  ControlWrapper,
  Content,
  InfoContainer,
  InfoWrapper,
  InfoItem,
  Count,
  InfoTitle,
  Title,
  Paragraph
}
