//@flow
import React, { memo, useCallback, useEffect, useState } from 'react'

import * as Styled from './styled'
import { TCookieBannerProps } from './types'
import PopupContent from './PopupContent'
import Popup from '@website/common/components/Popup'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { cookieManager } from '@website/common/utils'
import { COOKIE_ALL_ACCEPTED_STATE, COOKIE_ALL_DECLINED_STATE } from './consts'

const CookieBanner = ({
  siteId,
  acceptBtn,
  consentText,
  declineBtn,
  privacyPolicyBtn,
  settingsBtn,
  settings,
}: TCookieBannerProps) => {
  const [cookieAcceptedState, setCookieAcceptedState] = useState(
    COOKIE_ALL_ACCEPTED_STATE
  )
  const [toShow, setStatus] = useState(false)
  const [isPopupOpen, setPopupState] = useState(false)

  useEffect(() => {
    const hasPrevStored = !!cookieManager.getCookie(`${siteId}`)
    setStatus(!hasPrevStored)
  }, [])

  const setCookie = useCallback((): void => {
    setStatus(false)
    cookieManager.setCookie(siteId, `website-cookie-${siteId}`)
  }, [siteId])

  const handleStateChange = (category, value) => {
    setCookieAcceptedState({
      ...cookieAcceptedState,
      [category]: value
    })
  }

  const handleSettingsApply = useCallback(state => {
    window?.localStorage &&
      window.localStorage.setItem('cookieSettings', JSON.stringify(state))
    setCookie()
    setStatus(false)
    window.location.reload()
  }, [])

  const handleApplyAll = () => {
    handleSettingsApply(COOKIE_ALL_ACCEPTED_STATE)
  }

  const handleDeclineAll = () => {
    handleSettingsApply(COOKIE_ALL_DECLINED_STATE)
  }

  const openPopup = () => {
    setPopupState(true)
  }

  const closePopup = useCallback(() => {
    setPopupState(false)
    setCookieAcceptedState(COOKIE_ALL_ACCEPTED_STATE)
  }, [])

  if (!toShow) {
    return null
  }

  return (
    <>
      <Styled.CookieBannerWrapper
        className="WM_GLOBAL_secondary-font"
      >
        <Styled.CookieBanner>
          <Styled.ConsentText>
            {consentText}
            {privacyPolicyBtn?.isVisible ? (
              <span>
                <LinkWrapper
                  text={privacyPolicyBtn.name}
                  url={privacyPolicyBtn.link}
                  linkProps={{
                    className: 'privacy-policy-link'
                  }}
                />
              </span>
            ) : null}
          </Styled.ConsentText>
          <Styled.ButtonsWrapper>
            {settingsBtn?.isVisible ? (
              declineBtn?.isVisible ? (
                <Styled.LinkButton
                  role="button"
                  onClick={openPopup}
                  className="settings-btn-link"
                >
                  {settingsBtn.name}
                </Styled.LinkButton>
              ) : (
                <Styled.Button isTertiary role="button" onClick={openPopup}>
                  {settingsBtn.name}
                </Styled.Button>
              )
            ) : null}

            {declineBtn?.isVisible ? (
              <Styled.Button
                isTertiary
                role="button"
                onClick={handleDeclineAll}
              >
                {declineBtn.name}
              </Styled.Button>
            ) : null}
            <Styled.Button role="button" onClick={handleApplyAll}>
              {acceptBtn.name}
            </Styled.Button>
          </Styled.ButtonsWrapper>
        </Styled.CookieBanner>
      </Styled.CookieBannerWrapper>
      {settingsBtn?.isVisible ? (
        <Popup isOpen={isPopupOpen} onClose={closePopup}>
          {isPopupOpen && <Styled.GlobalStyles />}
          <PopupContent
            mainContent={{
              title: settings.title,
              description: settings.description,
              buttonText: settings.confirmBtnName
            }}
            categories={settings.categories}
            onConfirm={handleSettingsApply}
            state={cookieAcceptedState}
            handleStateChange={handleStateChange}
          />
        </Popup>
      ) : null}
    </>
  )
}

export default memo(CookieBanner)
