import { hexToRgb } from '@website/common/utils'

export default {
  DARK: {
    heading: p => p.accentWhite,
    lines: p => `rgba(${hexToRgb(p.accentWhite)}, 0.3)`,
    paragraph: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.primaryDark,
    lines: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryLight,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    lines: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
