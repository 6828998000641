export const validation = {
  headingMaxChar: 15,
  paragraphMaxChar: 70,
  labelMaxChar: 30,
  name: { required: true },
  email: { required: true },
  message: { required: true }
}

export const EMPTY_FIELD_MESSAGE = 'This field cannot be empty'
export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email'
export const SUCCESS_MESSAGE = 'Thank you for subscribing!'