/* @flow */
import produce from 'immer'

/* eslint-disable */
export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_CLIENT_DATA':
        const { data, idx, keyInStore } = action.value
        draft.blocks[idx][keyInStore] = data
        break
      case 'CHANGE_IMAGE': {
        const { idx, url, dimensions } = action.value
        draft.blocks[idx].imgUrl = url
        draft.blocks[idx].imgDimensions = dimensions || {}
        draft.blocks[idx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx } = action.value
          draft.blocks[idx].imgCropParams = imgCropParams
        }
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break

      case 'CHANGE_NAME_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.blocks[idx].nameAlignment = alignment
        break
      }
      case 'CHANGE_POSITION_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.blocks[idx].positionAlignment = alignment
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.blocks[idx].paragraphAlignment = alignment
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
    }
  })
}
