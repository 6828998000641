/* @flow */
import React, { useContext, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'

import * as Styled from './styled'
import {
  MobilePopupHeader,
  SideBlock,
  ArrowButton
} from '@editor/common/lib/Popup/styled'
import FlyOutMenu from '@editor/common/lib/FlyOutMenu/FlyOutMenu'
import MediaUploadButton from '@editor/common/components/MediaUploadButton'
import { logoEditorLink, videoEditorLink } from '@editor/conf/consts'
import { MODAL_TYPES, LINK_TYPES } from '../consts'
import {
  ControlModalsContext,
  MediaLibContext,
  MobileDetectContext
} from '@contexts'
import { optimizeImage } from '@actions/utility'
import { openUrl } from '@website/common/utils'
import { getMediaType, getOptions } from './utils'
import { ImageUploadInfo } from '@editor/common/utils/image'
import { generateUniqId, isBlobUrl } from '@editor/common/utils'
import { openLoginPopup, setImageUploadInfo } from '@actions/editor'
import { selectIsUserAuthorized } from '@selectors'
import { translate } from '@editor/common/utils/translations'

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

const mapDispatchToProps = {
  openLoginPopup,
  setImageUploadInfo
}

const MediaChangeModal = props => {
  const { isUserAuthorized, openLoginPopup, setImageUploadInfo } = props

  const isMobile = useContext(MobileDetectContext)
  const { setMediaLibProps } = useContext(MediaLibContext)
  const { modalStates, setModalState } = useContext(ControlModalsContext)
  const { siteId } = useParams()

  const {
    type,
    handler,
    withImgSizes,
    targetRef,
    alignment,
    renderingContainer,
    shift,
    optimizationDimensions,
    isFlyoutPositionFixed,
    className,
    isRCOutclickDisabled = true,
    backdropDisablingSelectors = []
  } = modalStates[MODAL_TYPES.mediaModal]

  const popupTitle =
    getMediaType(type) === 'image'
      ? translate('choose_an_image_label')
      : translate('choose_a_video_label')

  const closeModal = useCallback(
    () =>
      setModalState(MODAL_TYPES.mediaModal, {
        isOpen: false,
        link: '',
        className: ''
      }),
    [setModalState]
  )

  const handleError = useCallback(
    (key: string, url: ?string = '') => {
      setImageUploadInfo(
        key,
        new ImageUploadInfo({
          failed: true,
          isLoading: false,
          url: url
        })
      )
      handler(key, {})
    },
    [handler, setImageUploadInfo]
  )

  const mediaChooseHandler = useCallback(
    (url: string) => {
      if (getMediaType(type) !== 'image') {
        handler(url)
        return
      }

      const uniqId = generateUniqId()
      const key = `unoptimized_${uniqId}`

      handler(key)
      setImageUploadInfo(
        key,
        new ImageUploadInfo({ failed: false, isLoading: true, url: url })
      )

      if (isBlobUrl(url)) {
        closeModal()
        return
      }

      optimizeImage(url, optimizationDimensions, siteId, withImgSizes)
        .then(({ imgDimensions, imgSizes }) => {
          setImageUploadInfo(
            key,
            new ImageUploadInfo({
              failed: false,
              isLoading: false,
              url: url
            })
          )
          handler(url, imgDimensions, imgSizes)
        })
        .catch(() => {
          handleError(key, url)
        })
    },
    [
      type,
      siteId,
      optimizationDimensions,
      withImgSizes,
      handler,
      closeModal,
      handleError,
      setImageUploadInfo
    ]
  )

  const handleMediaUploadError = useCallback(() => {
    const uniqId = generateUniqId()
    const key = `unoptimized_${uniqId}`
    handleError(key)
    closeModal()
  }, [handleError, closeModal])

  const openMediaLib = (mediaLibMode = 'default') => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }

    setMediaLibProps({
      action: { type: getMediaType(type), handler: mediaChooseHandler },
      mode: mediaLibMode,
      isMediaLibVisible: true
    })
  }

  const actionGetter = {
    mediaLib: () => openMediaLib(),
    stockImage: () => openMediaLib('stock_images'),
    logoCreate: () => openUrl(logoEditorLink),
    videoCreate: () => openUrl(videoEditorLink),
    urlModal: notification => {
      setModalState(MODAL_TYPES.urlModal, {
        isOpen: true,
        link: '',
        ...modalStates[MODAL_TYPES.mediaModal],
        handler: mediaChooseHandler,
        notification
      })
    }
  }

  const options = useMemo(
    () => (
      <>
        {isMobile ? (
          <Styled.MobileContainer>
            <MobilePopupHeader>
              <SideBlock>
                <ArrowButton onClick={closeModal} />
              </SideBlock>
              <h3>{popupTitle}</h3>
              <SideBlock />
            </MobilePopupHeader>
          </Styled.MobileContainer>
        ) : null}

        <Styled.Options>
          {type !== LINK_TYPES.video ? (
            <MediaUploadButton
              uploadType={getMediaType(type)}
              className="upload-button"
              onCancel={closeModal}
              onError={handleMediaUploadError}
              insert={mediaChooseHandler}
            />
          ) : null}
          {getOptions(type, actionGetter).map((data, idx) => {
            const { text, IconComp } = data

            return (
              <Styled.Option key={idx} onClick={data.handler}>
                <IconComp />
                <p>{text}</p>
              </Styled.Option>
            )
          })}
        </Styled.Options>
      </>
    ),
    [type, closeModal, handleMediaUploadError, mediaChooseHandler, actionGetter]
  )

  return (
    <>
      <Styled.GlobalStyles />
      <FlyOutMenu
        isOpen
        onClose={closeModal}
        desktopProps={{
          shift,
          alignment,
          className: className || 'media-change-flyout',
          withAnimation: false,
          isPositionFixed: isFlyoutPositionFixed,
          anchorEl: targetRef && targetRef.current,
          backdropDisablingSelectors:
            renderingContainer && isRCOutclickDisabled
              ? [`.${renderingContainer}`, ...backdropDisablingSelectors]
              : [...backdropDisablingSelectors],
          renderingContainer: renderingContainer
            ? document.querySelector(`.${renderingContainer}`)
            : null
        }}
      >
        {options}
      </FlyOutMenu>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaChangeModal)
