/* @flow */
import {
  changeCopyright,
  changeParagraph,
  changePlaceHolderText
} from '@website/components/CommonActions'
import { simpleButtonActionMaker } from '../../ActionMakers'
import { privacyPolicyAction } from '@website/components/ActionMakers'

export default dispatcher => {
  const changeLabel = (newLabel: string) => {
    dispatcher({
      type: 'CHANGE_LABEL',
      value: newLabel
    })
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeCopyright = (newValue: string) => {
    dispatcher(changeCopyright(newValue))
  }

  const _changePlaceHolderText = (placeholder: string) =>
    dispatcher(changePlaceHolderText(placeholder))

  return {
    changeLabel,
    privacyPolicyAction,
    changeCopyright: _changeCopyright,
    changeParagraph: _changeParagraph,
    changePlaceHolderText: _changePlaceHolderText,
    buttonActions: simpleButtonActionMaker(dispatcher)
  }
}
