/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Header46.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import LibIcon from '@website/common/components/Icon'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 0;
  background: ${getStyleForProp('background')};
  min-height: 100vh;
  overflow: hidden;
  > div:first-child {
    position: absolute;
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      > div:first-child {
        padding-top: 180px;
      }
      .logoContainer {
        transform: translateY(-20px);
      }
    `}
  @media only screen and (max-width: 1024px) {
    padding: 0 !important;
  }
  .slick-slide > div {
    margin: 0;
  }
  .slick-slide {
    min-height: 100vh;
  }
  .team6-slick-slider {
    .slick-active {
      z-index: 1;
    }
  }
  .slick-track {
    > div {
      height: 100%;
    }
  }
  .slick-track {
    display: flex;
    align-items: stretch;
    justify-content: center;
    .slick-slide {
      height: auto;
    }
  }
  .slick-arrow {
    display: none !important;
  }
  .socialIcons {
    & > .control-container:nth-child(n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
  }
`

export const WmCustomContainer = styled(WMContainer)`
  width: 100% !important;
  max-width: initial;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    padding: 0;
  }
`

export const SliderWrapper = styled.div`
  ${getBackgroundStyles(THEME)};
  min-height: 100vh;
  height: 100%;
`

export const Title = styled.h2`
  max-width: 644px;
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  max-width: 644px;
  width: 100%;
  padding-top: 20px;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  bottom: 80px;
  box-sizing: border-box;
  position: absolute;
  padding-left: calc(50% - 700px);
  @media only screen and (max-width: 1600px) {
    bottom: 60px;
    padding-left: calc(50% - 600px);
  }
  @media only screen and (max-width: 1320px) {
    padding-left: 7.5%;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: initial;
    left: 50%;
    transform: translateX(-50%);
  }
  .add-button-wrap {
    margin-left: 30px;
  }
`

export const DotsWrap = styled.div`
  display: flex;
`

export const Dot = styled.div`
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border: 1px solid ${getStyleForProp('heading')};
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: ${({ isActive }) =>
    isActive ? getStyleForProp('heading') : 'transparent'};
`

export const SlidesCountWrap = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  z-index: 1;
  justify-content: flex-start;
  top: ${({ topOffset, isEditing }) =>
    isEditing ? '320px' : `${topOffset + 50}px`};
  box-sizing: border-box;
  position: absolute;
  left: calc(50% - 700px);
  @media only screen and (max-width: 1600px) {
    left: calc(50% - 600px);
  }
  @media only screen and (max-width: 1320px) {
    left: 7%;
  }
  @media only screen and (max-width: 1024px) {
    left: 35px;
  }
`

export const ActiveSlideNumber = styled.p`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0 !important;
  line-height: 1em;
  padding: 0 5px;
`

export const Divider = styled.p`
  color: ${getStyleForProp('heading')};
  padding: 0 5px 2px 5px;
`

export const SlidesNumber = styled.p`
  color: ${getStyleForProp('heading')};
  padding: 0 5px;
  line-height: 1em;
`
export const Info = styled.div`
  width: 95%;
  max-width: 1400px;
  min-height: 100vh;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ isEditing }) =>
    !isEditing &&
    css`
      h2,
      p,
      .buttons-block {
        opacity: 0;
        transform: translateX(70px);
      }
    `}
  ${({ isActive, isEditing }) =>
    isActive &&
    !isEditing &&
    css`
      h2,
      p,
      .buttons-block {
        opacity: 1;
        transform: translateX(0);
      }
      > :first-child {
        transition: opacity 0.7s 0.2s ease-out, transform 0.7s 0.2s ease-out;
        @media only screen and (max-width: 1024px) {
          transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        }
      }
      > :nth-child(2) {
        transition: opacity 0.7s 0.4s ease-out, transform 0.7s 0.4s ease-out;
        @media only screen and (max-width: 1024px) {
          transition: opacity 0.5s 0.2s ease-out, transform 0.5s 0.2s ease-out;
        }
      }
      > :nth-child(3) {
        transition: opacity 0.7s 0.4s ease-out, transform 0.7s 0.4s ease-out;
        @media only screen and (max-width: 1024px) {
          transition: opacity 0.5s 0.35s ease-out, transform 0.5s 0.35s ease-out;
        }
      }
    `}
  padding-top: ${({ topOffset, isEditing }) =>
    isEditing ? '420px' : `${topOffset + 135}px`};
  padding-bottom: 160px;
  @media only screen and (max-width: 1600px) {
    width: 90%;
    max-width: 1200px;
  }
  @media only screen and (max-width: 1320px) {
    width: 85%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    align-items: center;
    box-sizing: border-box;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: 110px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .buttons-block {
    align-items: flex-start;
    margin-left: -15px;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    p {
      padding-top: 0;
    }
  }
  .control-container {
    width: 100%;
    max-width: 644px;
    margin-bottom: 30px;
    @media only screen and (max-width: 1024px) {
      max-width: initial;
    }
  }
  @media only screen and (min-width: 1025px) {
    & > .control-container {
      max-width: 644px;
    }
    > .styled-controls {
      right: 0;
      left: unset;
    }
  }
`

export const ArrrowIconsWrap = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  max-width: 1612px;
  display: flex;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 0;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
`
export const IconContainer = styled.button`
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s linear;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: 50%;
  border: 1px solid ${getStyleForProp('heading')};
  border-radius: ${getStyleForProp('iconRadius')};
  &:focus {
    outline: none;
  }
  &.left {
    margin-left: 20px;
    &:hover {
      > div {
        transform: translateX(-2px);
      }
    }
  }
  &.right {
    margin-right: 20px;
    &:hover {
      > div {
        transform: translateX(2px);
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
  > div {
    transition: transform linear 0.15s;
  }
  &:hover {
    opacity: 0.7;
    > div {
      opacity: 0.7;
    }
  }
`

export const ArrowIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('heading')
}))`
  @media only screen and (max-width: 457px) {
    display: none;
  }
`

export const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('iconColor')
}))``
