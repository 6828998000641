export const Palette1 = {
  primaryDark: '#3DEDB8',
  primaryLight: '#145CFD',
  secondaryDark: '#5A8DFD',
  secondaryLight: '#72EBC1',
  tertiaryDark: '#1D1D1B',
  tertiaryNormal: '#A3A3A2',
  tertiarySemiLight: '#D0D0CF',
  tertiaryLight: '#F2F6FF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette2 = {
  primaryDark: '#EF1F44',
  primaryLight: '#0047FF',
  secondaryDark: '#042D96',
  secondaryLight: '#0047FF',
  tertiaryDark: '#1F242C',
  tertiaryNormal: '#A4A6A9',
  tertiarySemiLight: '#D1D2D4',
  tertiaryLight: '#F5F5F5',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette3 = {
  primaryDark: '#FF7127',
  primaryLight: '#FFA46E',
  secondaryDark: '#5E91F8',
  secondaryLight: '#81AAFC',
  tertiaryDark: '#0067FA',
  tertiaryNormal: '#97C1FC',
  tertiarySemiLight: '#CCE0FE',
  tertiaryLight: '#F1F6FE',
  tertiaryExtraLight: '#FBFCFE',
  accentWhite: '#FFFFFF'
}

export const Palette4 = {
  primaryDark: '#E5285C',
  primaryLight: '#FF3364',
  secondaryDark: '#FF97B0',
  secondaryLight: '#FFCBD7',
  tertiaryDark: '#1E1E1E',
  tertiaryNormal: '#8D8D8D',
  tertiarySemiLight: '#BABABA',
  tertiaryLight: '#F7EDEE',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette5 = {
  primaryDark: '#00DDCC',
  primaryLight: '#22F0E0',
  secondaryDark: '#22F0E0',
  secondaryLight: '#AFFFF9',
  tertiaryDark: '#243376',
  tertiaryNormal: '#A6ACC7',
  tertiarySemiLight: '#D2D5E3',
  tertiaryLight: '#FFEBEB',
  tertiaryExtraLight: '#FFFBFB',
  accentWhite: '#FFFFFF'
}

export const Palette6 = {
  primaryDark: '#2A932A',
  primaryLight: '#B1E200',
  secondaryDark: '#5BB25B',
  secondaryLight: '#D0ED65',
  tertiaryDark: '#161A1D',
  tertiaryNormal: '#8A8C8E',
  tertiarySemiLight: '#C4C5C6',
  tertiaryLight: '#B1E200',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette7 = {
  primaryDark: '#FF7E5F',
  primaryLight: '#FEB47B',
  secondaryDark: '#727577',
  secondaryLight: '#E5E5E6',
  tertiaryDark: '#464443',
  tertiaryNormal: '#A2A1A1',
  tertiarySemiLight: '#D0D0D0',
  tertiaryLight: '#E5E5E6',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette8 = {
  primaryDark: '#C71D12',
  primaryLight: '#FF564B',
  secondaryDark: '#263CE7',
  secondaryLight: '#81AAFC',
  tertiaryDark: '#0F28E5',
  tertiaryNormal: '#8692F1',
  tertiarySemiLight: '#C2C8F8',
  tertiaryLight: '#F1F3FD',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette9 = {
  primaryDark: '#663072',
  primaryLight: '#DD99EC',
  secondaryDark: '#FF8902',
  secondaryLight: '#FFB866',
  tertiaryDark: '#524BD4',
  tertiaryNormal: '#A8A5E9',
  tertiarySemiLight: '#DBDAF6',
  tertiaryLight: '#F6D0FF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette10 = {
  primaryDark: '#FF5756',
  primaryLight: '#F2E1A4',
  secondaryDark: '#F87877',
  secondaryLight: '#FCB0B0',
  tertiaryDark: '#04C5B9',
  tertiaryNormal: '#81E2DC',
  tertiarySemiLight: '#D8F6F4',
  tertiaryLight: '#E7F9FC',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette11 = {
  primaryDark: '#7B6161',
  primaryLight: '#D1B6B6',
  secondaryDark: '#CEB8B8',
  secondaryLight: '#EDE4E4',
  tertiaryDark: '#2F2E2E',
  tertiaryNormal: '#818181',
  tertiarySemiLight: '#ABAAAA',
  tertiaryLight: '#D1B6B6',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette12 = {
  primaryDark: '#DE5D68',
  primaryLight: '#EDD5DB',
  secondaryDark: '#E8989F',
  secondaryLight: '#E8989F',
  tertiaryDark: '#3E57B0',
  tertiaryNormal: '#94A2D3',
  tertiarySemiLight: '#B1BBDF',
  tertiaryLight: '#EDD5DB',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette13 = {
  primaryDark: '#AE8E07',
  primaryLight: '#FCEB93',
  secondaryDark: '#D9C055',
  secondaryLight: '#FCEB93',
  tertiaryDark: '#FF4B01',
  tertiaryNormal: '#FFA47F',
  tertiarySemiLight: '#FFB597',
  tertiaryLight: '#FCEB93',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette14 = {
  primaryDark: '#626E9A',
  primaryLight: '#B9C4E9',
  secondaryDark: '#8A98C9',
  secondaryLight: '#B9C4E9',
  tertiaryDark: '#383332',
  tertiaryNormal: '#9A9897',
  tertiarySemiLight: '#CDCCCB',
  tertiaryLight: '#F8F3F1',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette15 = {
  primaryDark: '#61F69F',
  primaryLight: '#F1A5FF',
  secondaryDark: '#61F69F',
  secondaryLight: '#AFF8CE',
  tertiaryDark: '#040815',
  tertiaryNormal: '#686A72',
  tertiarySemiLight: '#9A9CA1',
  tertiaryLight: '#D38DE0',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette16 = {
  primaryDark: '#F2744C',
  primaryLight: '#F09C97',
  secondaryDark: '#FF8DB3',
  secondaryLight: '#F09C97',
  tertiaryDark: '#023A15',
  tertiaryNormal: '#819D8A',
  tertiarySemiLight: '#B2C3B8',
  tertiaryLight: '#FFE6E2',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette17 = {
  primaryDark: '#FE93A0',
  primaryLight: '#FFBCBE',
  secondaryDark: '#FFC8CA',
  secondaryLight: '#FEA8B2',
  tertiaryDark: '#80295D',
  tertiaryNormal: '#BF93AD',
  tertiarySemiLight: '#D8BECE',
  tertiaryLight: '#FEEAE2',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FEEAE2'
}

export const Palette18 = {
  primaryDark: '#292E49',
  primaryLight: '#536976',
  secondaryDark: '#54586D',
  secondaryLight: '#758791',
  tertiaryDark: '#132A38',
  tertiaryNormal: '#89949B',
  tertiarySemiLight: '#C4C9CD',
  tertiaryLight: '#D2D4E2',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette19 = {
  primaryDark: '#773DC9',
  primaryLight: '#2270F7',
  secondaryDark: '#2270F7',
  secondaryLight: '#80AAF4',
  tertiaryDark: '#0D031C',
  tertiaryNormal: '#86818D',
  tertiarySemiLight: '#C2C0C6',
  tertiaryLight: '#F6F0FF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette20 = {
  primaryDark: '#7ACDFA',
  primaryLight: '#F2A89F',
  secondaryDark: '#F2A89F',
  secondaryLight: '#F0C4BF',
  tertiaryDark: '#39455A',
  tertiaryNormal: '#9BA1AB',
  tertiarySemiLight: '#C2C6CC',
  tertiaryLight: '#E0F5FF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette21 = {
  primaryDark: '#AE2107',
  primaryLight: '#FFD202',
  secondaryDark: '#FBA702',
  secondaryLight: '#FFD202',
  tertiaryDark: '#141414',
  tertiaryNormal: '#898989',
  tertiarySemiLight: '#B8B8B8',
  tertiaryLight: '#FFD202',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette22 = {
  primaryDark: '#17AA9C',
  primaryLight: '#03DAC5',
  secondaryDark: '#DABF2C',
  secondaryLight: '#F7D72A',
  tertiaryDark: '#6219EE',
  tertiaryNormal: '#B08CF6',
  tertiarySemiLight: '#CEB8F9',
  tertiaryLight: '#F5F7FF',
  tertiaryExtraLight: '#FCFCFF',
  accentWhite: '#FFFFFF'
}

export const Palette23 = {
  primaryDark: '#B78876',
  primaryLight: '#F2D1D8',
  secondaryDark: '#C2A792',
  secondaryLight: '#F3E7DD',
  tertiaryDark: '#413B89',
  tertiaryNormal: '#8B88B7',
  tertiarySemiLight: '#C5C3DB',
  tertiaryLight: '#F8F0E9',
  tertiaryExtraLight: '#FCFCFF',
  accentWhite: '#FFFFFF'
}

export const Palette24 = {
  primaryDark: '#D0B20F',
  primaryLight: '#F7D72A',
  secondaryDark: '#365669',
  secondaryLight: '#7993A3',
  tertiaryDark: '#093A56',
  tertiaryNormal: '#396077',
  tertiarySemiLight: '#9CAFBB',
  tertiaryLight: '#F2FBFF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette25 = {
  primaryDark: '#FC0E50',
  primaryLight: '#CA17CB',
  secondaryDark: '#365669',
  secondaryLight: '#7993A3',
  tertiaryDark: '#10030D',
  tertiaryNormal: '#574E55',
  tertiarySemiLight: '#9D989C',
  tertiaryLight: '#F4F4F4',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette26 = {
  primaryDark: '#355985',
  primaryLight: '#D5FFBF',
  secondaryDark: '#355985',
  secondaryLight: '#B1C1D5',
  tertiaryDark: '#1F2633',
  tertiaryNormal: '#61666F',
  tertiarySemiLight: '#8E9198',
  tertiaryLight: '#EFF3FC',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette27 = {
  primaryDark: '#B18930',
  primaryLight: '#E2C96E',
  secondaryDark: '#131B33',
  secondaryLight: '#6C707B',
  tertiaryDark: '#111625',
  tertiaryNormal: '#585C66',
  tertiarySemiLight: '#9EA0A6',
  tertiaryLight: '#FAFCFF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette28 = {
  primaryDark: '#000330',
  primaryLight: '#00077E',
  secondaryDark: '#181F8A',
  secondaryLight: '#464CA1',
  tertiaryDark: '#F22E55',
  tertiaryNormal: '#F56B86',
  tertiarySemiLight: '#F896AA',
  tertiaryLight: '#F7ECEC',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Palette29 = {
  primaryDark: '#F55B2E',
  primaryLight: '#E88C71',
  secondaryDark: '#72968B',
  secondaryLight: '#ACC1BB',
  tertiaryDark: '#356859',
  tertiaryNormal: '#72968B',
  tertiarySemiLight: '#ACC1BB',
  tertiaryLight: '#F2F5DF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Mercury = {
  primaryDark: '#FF58A0',
  primaryLight: '#FFCA68',
  secondaryDark: '#13BDE1',
  secondaryLight: '#97EF81',
  tertiaryDark: '#38255C',
  tertiaryNormal: '#675686',
  tertiarySemiLight: '#B2A6C8',
  tertiaryLight: '#F7F5FB',
  tertiaryExtraLight: '#FCFBFF',
  accentWhite: '#FFFFFF'
}

export const Neptune = {
  primaryDark: '#24A8F4',
  primaryLight: '#67EBB3',
  secondaryDark: '#6586FF',
  secondaryLight: '#4BD8FF',
  tertiaryDark: '#25265C',
  tertiaryNormal: '#525493',
  tertiarySemiLight: '#9596C6',
  tertiaryLight: '#F6F6FF',
  tertiaryExtraLight: '#FDFDFF',
  accentWhite: '#FFFFFF'
}

export const Saturn = {
  primaryDark: '#A08559',
  primaryLight: '#E3B873',
  secondaryDark: '#7D716B',
  secondaryLight: '#C6B193',
  tertiaryDark: '#393838',
  tertiaryNormal: '#575050',
  tertiarySemiLight: '#7D716B',
  tertiaryLight: '#F3F0F0',
  tertiaryExtraLight: '#F9F9F9',
  accentWhite: '#FFFFFF'
}

export const Uranus = {
  primaryDark: '#63A23A',
  primaryLight: '#AED978',
  secondaryDark: '#54902E',
  secondaryLight: '#8CC35F',
  tertiaryDark: '#363636',
  tertiaryNormal: '#5E5E5E',
  tertiarySemiLight: '#8C8C8C',
  tertiaryLight: '#F3F3F3',
  tertiaryExtraLight: '#F8F8F8',
  accentWhite: '#FFFFFF'
}

export const Elara = {
  primaryDark: '#FF77CC',
  primaryLight: '#FFCFBC',
  secondaryDark: '#FF77CC',
  secondaryLight: '#FFCFBC',
  tertiaryDark: '#2D54D6',
  tertiaryNormal: '#0028AF',
  tertiarySemiLight: '#7F99F1',
  tertiaryLight: '#FFF6F7',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Metis = {
  primaryDark: '#FF6177',
  primaryLight: '#FFC332',
  secondaryDark: '#5879E6',
  secondaryLight: '#9FB5FD',
  tertiaryDark: '#1C2F6D',
  tertiaryNormal: '#435DB3',
  tertiarySemiLight: '#8794C0',
  tertiaryLight: '#F2F5FC',
  tertiaryExtraLight: '#FAFBFF',
  accentWhite: '#FFFFFF'
}

export const Europa = {
  primaryDark: '#FF9C32',
  primaryLight: '#FFC444',
  secondaryDark: '#7B6FBB',
  secondaryLight: '#B3ABD9',
  tertiaryDark: '#4A3AA0',
  tertiaryNormal: '#6B5DBB',
  tertiarySemiLight: '#B3ABD9',
  tertiaryLight: '#F4F2FC',
  tertiaryExtraLight: '#FBFAFF',
  accentWhite: '#FFFFFF'
}

export const Earth = {
  primaryDark: '#3382D1',
  primaryLight: '#66BAF1',
  secondaryDark: '#3F5980',
  secondaryLight: '#5B87CB',
  tertiaryDark: '#252B34',
  tertiaryNormal: '#4C5A70',
  tertiarySemiLight: '#96A6BE',
  tertiaryLight: '#F5F9FF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Calisto = {
  primaryDark: '#49AF73',
  primaryLight: '#6DDB9A',
  secondaryDark: '#4A5975',
  secondaryLight: '#5F759C',
  tertiaryDark: '#21314D',
  tertiaryNormal: '#425475',
  tertiarySemiLight: '#7C8DAE',
  tertiaryLight: '#F3F8FF',
  tertiaryExtraLight: '#F8FBFF',
  accentWhite: '#FFFFFF'
}

export const Moon = {
  primaryDark: '#2A4696',
  primaryLight: '#516EBC',
  secondaryDark: '#000000',
  secondaryLight: '#474747',
  tertiaryDark: '#000000',
  tertiaryNormal: '#484848',
  tertiarySemiLight: '#A4A4A4',
  tertiaryLight: '#F2F2F2',
  tertiaryExtraLight: '#FAFAFA',
  accentWhite: '#FFFFFF'
}

export const Io = {
  primaryDark: '#27253C',
  primaryLight: '#51516D',
  secondaryDark: '#51516D',
  secondaryLight: '#8E8EAB',
  tertiaryDark: '#27253C',
  tertiaryNormal: '#51516D',
  tertiarySemiLight: '#B3B3C8',
  tertiaryLight: '#FFE02E',
  tertiaryExtraLight: '#FFF36D',
  accentWhite: '#FFFFFF'
}

export const Phobos = {
  primaryDark: '#17AF70',
  primaryLight: '#65E292',
  secondaryDark: '#39D49A',
  secondaryLight: '#8FF99F',
  tertiaryDark: '#2144B8',
  tertiaryNormal: '#001C7A',
  tertiarySemiLight: '#A6A6C2',
  tertiaryLight: '#DAFFE7',
  tertiaryExtraLight: '#E4FFD9',
  accentWhite: '#FFFFFF'
}

export const Mars = {
  primaryDark: '#27253C',
  primaryLight: '#51516D',
  secondaryDark: '#27253C',
  secondaryLight: '#51516D',
  tertiaryDark: '#FF5333',
  tertiaryNormal: '#51516D',
  tertiarySemiLight: '#A6A6C2',
  tertiaryLight: '#FFE3DE',
  tertiaryExtraLight: '#FFF8F6',
  accentWhite: '#FFFFFF'
}

export const Deimos = {
  primaryDark: '#334279',
  primaryLight: '#6F80BC',
  secondaryDark: '#2AA694',
  secondaryLight: '#80EBDA',
  tertiaryDark: '#121C3D',
  tertiaryNormal: '#3B4978',
  tertiarySemiLight: '#9DABDB',
  tertiaryLight: '#9EFEF0',
  tertiaryExtraLight: '#D7FFF9',
  accentWhite: '#FFFFFF'
}

export const Pasiphae = {
  primaryDark: '#484848',
  primaryLight: '#8E8E8E',
  secondaryDark: '#363636',
  secondaryLight: '#696969',
  tertiaryDark: '#363636',
  tertiaryNormal: '#484848',
  tertiarySemiLight: '#484848',
  tertiaryLight: '#F2F2F2',
  tertiaryExtraLight: '#FAFAFA',
  accentWhite: '#FFFFFF'
}

export const Venus = {
  primaryDark: '#BB2171',
  primaryLight: '#FB3880',
  secondaryDark: '#827394',
  secondaryLight: '#C0B1D2',
  tertiaryDark: '#362945',
  tertiaryNormal: '#362945',
  tertiarySemiLight: '#827394',
  tertiaryLight: '#F1EEF6',
  tertiaryExtraLight: '#FAF8FD',
  accentWhite: '#FFFFFF'
}

export const Jupiter = {
  primaryDark: '#F9561E',
  primaryLight: '#FF9820',
  secondaryDark: '#333333',
  secondaryLight: '#6B6B6B',
  tertiaryDark: '#252525',
  tertiaryNormal: '#515151',
  tertiarySemiLight: '#7D7D7D',
  tertiaryLight: '#F0F0F0',
  tertiaryExtraLight: '#FAFAFA',
  accentWhite: '#FFFFFF'
}

export const Taygete = {
  primaryDark: '#4BC4A1',
  primaryLight: '#ABF290',
  secondaryDark: '#6D6889',
  secondaryLight: '#B2AFC5',
  tertiaryDark: '#423E5A',
  tertiaryNormal: '#6D6889',
  tertiarySemiLight: '#B2AFC5',
  tertiaryLight: '#F4F2FC',
  tertiaryExtraLight: '#FBFAFF',
  accentWhite: '#FFFFFF'
}

export const Lysithea = {
  primaryDark: '#6E2687',
  primaryLight: '#C74CC1',
  secondaryDark: '#27253C',
  secondaryLight: '#51516D',
  tertiaryDark: '#151525',
  tertiaryNormal: '#51516D',
  tertiarySemiLight: '#A0A0BB',
  tertiaryLight: '#F4F4F8',
  tertiaryExtraLight: '#F8F8F9',
  accentWhite: '#FFFFFF'
}

export const Themisto = {
  primaryDark: '#E06665',
  primaryLight: '#EB9594',
  secondaryDark: '#4E8EE4',
  secondaryLight: '#84C5EA',
  tertiaryDark: '#3E4860',
  tertiaryNormal: '#56617C',
  tertiarySemiLight: '#8993A8',
  tertiaryLight: '#F3F5F8',
  tertiaryExtraLight: '#F7F9FD',
  accentWhite: '#FFFFFF'
}

export const Ananke = {
  primaryDark: '#EEA678',
  primaryLight: '#FFCAA3',
  secondaryDark: '#C7ADAB',
  secondaryLight: '#E8D2C3',
  tertiaryDark: '#443F3E',
  tertiaryNormal: '#564E4C',
  tertiarySemiLight: '#B19A99',
  tertiaryLight: '#F5F5F5',
  tertiaryExtraLight: '#FCF9F9',
  accentWhite: '#FFFFFF'
}

export const Amalthea = {
  primaryDark: '#D48EBE',
  primaryLight: '#FFC7D8',
  secondaryDark: '#977AB0',
  secondaryLight: '#D48EBE',
  tertiaryDark: '#7E6098',
  tertiaryNormal: '#73677C',
  tertiarySemiLight: '#B0A6B7',
  tertiaryLight: '#F8F6FA',
  tertiaryExtraLight: '#FDFCFF',
  accentWhite: '#FFFFFF'
}

export const Sinope = {
  primaryDark: '#D4A9AA',
  primaryLight: '#E5CED0',
  secondaryDark: '#3A6F7C',
  secondaryLight: '#769DA7',
  tertiaryDark: '#20313A',
  tertiaryNormal: '#235D6B',
  tertiarySemiLight: '#769DA7',
  tertiaryLight: '#F5F5F5',
  tertiaryExtraLight: '#FCF9F9',
  accentWhite: '#FFFFFF'
}

export const Thebe = {
  primaryDark: '#93966C',
  primaryLight: '#D5D186',
  secondaryDark: '#A39A86',
  secondaryLight: '#CFC6B2',
  tertiaryDark: '#40453F',
  tertiaryNormal: '#555E53',
  tertiarySemiLight: '#CFC6B2',
  tertiaryLight: '#F8F6FA',
  tertiaryExtraLight: '#FCFBFD',
  accentWhite: '#FFFFFF'
}

export const Ganymede = {
  primaryDark: '#695450',
  primaryLight: '#927B77',
  secondaryDark: '#413735',
  secondaryLight: '#695450',
  tertiaryDark: '#312C2B',
  tertiaryNormal: '#473B39',
  tertiarySemiLight: '#927B77',
  tertiaryLight: '#FAF5EA',
  tertiaryExtraLight: '#FFFCF4',
  accentWhite: '#EFE8D7'
}

export const Pluto = {
  primaryDark: '#7367F0',
  primaryLight: '#CE9FFC',
  secondaryDark: '#4975F3',
  secondaryLight: '#97ABFF',
  tertiaryDark: '#293761',
  tertiaryNormal: '#6375A9',
  tertiarySemiLight: '#B2C0E8',
  tertiaryLight: '#F5F7FF',
  tertiaryExtraLight: '#FBFCFF',
  accentWhite: '#FFFFFF'
}

export const Andromeda = {
  primaryDark: '#42B6FE',
  primaryLight: '#73E2FF',
  secondaryDark: '#42B6FE',
  secondaryLight: '#73E2FF',
  tertiaryDark: '#438AF2',
  tertiaryNormal: '#4B4B4B',
  tertiarySemiLight: '#5B5B5B',
  tertiaryLight: '#ECFBFF',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Corvus = {
  primaryDark: '#0B90A7',
  primaryLight: '#64E368',
  secondaryDark: '#21486C',
  secondaryLight: '#325E87',
  tertiaryDark: '#132F4B',
  tertiaryNormal: '#506085',
  tertiarySemiLight: '#A7B1C8',
  tertiaryLight: '#CEFFA8',
  tertiaryExtraLight: '#E9FFC8',
  accentWhite: '#FFFFFF'
}

export const Circinus = {
  primaryDark: '#26243C',
  primaryLight: '#51516D',
  secondaryDark: '#51516D',
  secondaryLight: '#8E8EAB',
  tertiaryDark: '#26243C',
  tertiaryNormal: '#51516D',
  tertiarySemiLight: '#B3B3C8',
  tertiaryLight: '#6CD7FF',
  tertiaryExtraLight: '#A3E2FF',
  accentWhite: '#FFFFFF'
}

export const Virgo = {
  primaryDark: '#FB0079',
  primaryLight: '#FF9C30',
  secondaryDark: '#FB0079',
  secondaryLight: '#FF9C30',
  tertiaryDark: '#28304B',
  tertiaryNormal: '#495476',
  tertiarySemiLight: '#8793C0',
  tertiaryLight: '#FCF7F2',
  tertiaryExtraLight: '#FFFEFA',
  accentWhite: '#FFFFFF'
}

export const Sculptor = {
  primaryDark: '#87A3FF',
  primaryLight: '#FFBBE6',
  secondaryDark: '#87A3FF',
  secondaryLight: '#FFBBE6',
  tertiaryDark: '#363552',
  tertiaryNormal: '#363552',
  tertiarySemiLight: '#363552',
  tertiaryLight: '#F7F7FB',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Tucana = {
  primaryDark: '#9A7AD2',
  primaryLight: '#FFD1ED',
  secondaryDark: '#9170CB',
  secondaryLight: '#FEB6E3',
  tertiaryDark: '#634084',
  tertiaryNormal: '#363552',
  tertiarySemiLight: '#363552',
  tertiaryLight: '#F7F7FB',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Draco = {
  primaryDark: '#54B370',
  primaryLight: '#68DE98',
  secondaryDark: '#54B370',
  secondaryLight: '#68DE98',
  tertiaryDark: '#18492C',
  tertiaryNormal: '#18492C',
  tertiarySemiLight: '#18492C',
  tertiaryLight: '#F3F3F3',
  tertiaryExtraLight: '#F8F8F8',
  accentWhite: '#FFFFFF'
}

export const Sagittarius = {
  primaryDark: '#000000',
  primaryLight: '#524C4B',
  secondaryDark: '#000000',
  secondaryLight: '#524C4B',
  tertiaryDark: '#F4712C',
  tertiaryNormal: '#000000',
  tertiarySemiLight: '#000000',
  tertiaryLight: '#FEF6F7',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Ursa = {
  primaryDark: '#24273B',
  primaryLight: '#3E4454',
  secondaryDark: '#24273B',
  secondaryLight: '#3E4454',
  tertiaryDark: '#23B2B7',
  tertiaryNormal: '#24273B',
  tertiarySemiLight: '#3E4454',
  tertiaryLight: '#EEF7F5',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}

export const Venatici = {
  primaryDark: '#412B1A',
  primaryLight: '#957050',
  secondaryDark: '#412B1A',
  secondaryLight: '#957050',
  tertiaryDark: '#9D5839',
  tertiaryNormal: '#473B39',
  tertiarySemiLight: '#4F3529',
  tertiaryLight: '#F9F3F0',
  tertiaryExtraLight: '#FFFFFF',
  accentWhite: '#FFFFFF'
}
