import { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const scrollBarStyles = css`
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-button {
    display: block;
    height: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${secondary.Light};
    border-radius: 4px;
  }
`

export const gradientTextStyles = css`
  font-weight: 700;
  background: linear-gradient(
    to right,
    #5a50de 0%,
    #be5fe2 20%,
    #4a83ed 40%,
    #be5fe2 60%,
    #4a83ed 80%,
    #5a50de 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: textGradient 5s linear infinite;

  @keyframes textGradient {
    0% {
      background-position: 0;
    }
    100% {
      background-position: 200%;
    }
  }
`
