/* @flow */
import { validation } from '../Header35.consts'
import {
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addBlock,
  addIcon
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockTitle = (idx: number, newTitle: string) => ({
    type: 'CHANGE_BLOCK_TITLE',
    value: { idx, newTitle }
  })

  const blockActions = (idx: number, blocksCount: number) => [
    ...removeBlockActionMaker(dispatcher)({
      idx,
      showRemove: blocksCount > validation.blocks.min
    })
  ]

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _changeBlockTitle = (newTitle, idx) => {
    dispatcher(changeBlockTitle(newTitle, idx))
  }

  const _addIcon = idx => {
    dispatcher(addIcon(idx))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle: _changeBlockTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    addIcon: _addIcon,
    blockActions,
    addBlock: () => dispatcher(addBlock()),
    iconActions: iconActionMaker(dispatcher)
  }
}
