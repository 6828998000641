// @flow

import React, { memo } from 'react'
import * as Styled from './styled'
import type { TTooltipContentProps } from './types'

const TooltipContent = ({ text, imgSrc }: TTooltipContentProps) => (
  <Styled.TooltipContent>
    <img src={imgSrc} alt="" />
    <p>{text}</p>
  </Styled.TooltipContent>
)

export default memo(TooltipContent)
