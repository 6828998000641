/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_SOCIAL_TITLE':
        draft.socialTitle = action.value
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'REMOVE_SOCIAL':
        const { idx } = action.value
        draft.socials.splice(idx, 1)
        break
      case 'CHANGE_SOCIAL_URL': {
        const { idx, link } = action.value
        draft.socials[idx].socialUrl = link
        break
      }
      case 'CHANGE_SOCIAL_ICON': {
        const { idx, iconName } = action.value
        draft.socials[idx].icon = iconName
        break
      }
      case 'ADD_EMAIL':
        draft.emails.push(
          initialState.emails.find(elem => elem.icon !== draft.emails[0].icon)
        )
        break
      case 'REMOVE_EMAIL':
        draft.emails.splice(action.value, 1)
        break
      case 'CHANGE_BUTTON_DATA':
        draft.socials[action.value.idx].socialUrl = action.value.btnData.link
        break
      case 'CHANGE_EMAIL_TITLE':
        draft.emails[action.value.idx].title = action.value.newEmailTitle
        break
      case 'CHANGE_EMAIL':
        draft.emails[action.value.idx].to = action.value.newEmail
        break
      case 'CHANGE_TITLE_ALIGNMENT':
        draft.titleAlignment = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT':
        draft.paragraphAlignment = action.value
        break
    }
  })
}
