// @flow

import React, { useEffect, useState, memo, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, useRouteMatch, useLocation } from 'react-router-dom'

import { fetchAISiteInitialData_API } from '@api/ai'
import { translate } from '@editor/common/utils/translations'
import { pushDataLayer } from '@editor/common/utils/analytics'
import { navigateToEdit } from '@editor/common/utils'
import { applyPreset, setComponentsData, setGlobalErrorMessage } from '@actions'
import { EDITOR_BASE } from '@editor/conf/routes'
import ErrorPopup from '@editor/common/components/ErrorPopup'

import Preview from '../../Preview'
import {
  AI_WEBSITE_DATA_FETCHING_STATES,
  FAILED,
  FINISHED,
  CHOOSING_STYLE
} from './consts'
import AIWebsiteLoader from './AIWebsiteLoader'

const AIPresetPreview = ({ history }) => {
  const { state: businessName } = useLocation()
  const { lang, tempId } = useParams()
  const dispatch = useDispatch()
  const { url } = useRouteMatch(EDITOR_BASE)

  const [siteData, setSiteData] = useState({})
  const [loadingState, setLoadingState] = useState(CHOOSING_STYLE)
  const [isAIWebsiteGenerating, setAIWebsiteGeneratingState] = useState(false)
  const [isInitialDataLoading, setInitialDataLoadingState] = useState(false)

  const goToStartCreation = useCallback(() => {
    history.push({ pathname: `${url}/start-creation` })
    window.localStorage.setItem('loadingPercentage', 0)
  }, [history, url])

  const setFail = () => {
    dispatch(setGlobalErrorMessage(translate('something_went_wrong')))
    window.setIsScroll && window.setIsScroll(false)
  }

  const cb = ({ siteData, state }) => {
    if (state === FAILED) {
      setFail()
      return
    }

    if (state === FINISHED) {
      window.setIsScroll && window.setIsScroll(false)
      window.isAIWebsiteGenerating = false

      setTimeout(() => {
        setAIWebsiteGeneratingState(false)
      }, 2000)
    }

    if (AI_WEBSITE_DATA_FETCHING_STATES.hasOwnProperty(state)) {
      setLoadingState(state)

      if (siteData?.data) {
        const { data, visualParams } = siteData

        const _siteData = {
          ...data,
          visualParams
        }

        setSiteData(_siteData)
        window.setProjectData &&
          window.setProjectData({
            data: _siteData
          })
      }
    }
  }

  useEffect(() => {
    window.subscribeToAiGenerateSockets &&
      window.subscribeToAiGenerateSockets(tempId, cb)

    fetchAISiteInitialData_API(tempId)
      .then(({ data: projectData }) => {
        setInitialDataLoadingState(true)

        const { state, siteData: dataFromReq, componentMeta } = projectData

        if (state === FAILED) {
          setFail()
          return
        }

        dispatch(setComponentsData(componentMeta))
        const { data, visualParams } = dataFromReq

        const _siteData = {
          ...data,
          visualParams
        }

        if (state === FINISHED) {
          setSiteData(_siteData)
          return
        }

        setSiteData(siteData => {
          if (!Object.keys(siteData).length) {
            return _siteData
          }

          return siteData
        })

        window.isAIWebsiteGenerating = true
        setAIWebsiteGeneratingState(true)
        setLoadingState(state)
      })
      .catch(setFail)
      .finally(() => {
        setInitialDataLoadingState(false)
      })
  }, [])

  const iframeSrc = `${window.location.origin}/website-maker/preview/${lang}/ai-preset`

  const createProject = useCallback(() => {
    pushDataLayer({ tempId })
    dispatch(applyPreset({ data: siteData, siteName: businessName }))
    navigateToEdit(history)(url)
  }, [siteData, businessName])

  return (
    <>
      <Preview
        iframeSrc={iframeSrc}
        projectData={{
          isLoading: isInitialDataLoading,
          payload: {
            data: siteData
          }
        }}
        goBack={goToStartCreation}
        siteHash={siteData?.hash}
        applyPreset={createProject}
        showOpenInNewTabBtn={false}
        btnLabel={translate('edit_label', true)}
      />
      {isAIWebsiteGenerating && <AIWebsiteLoader loadingState={loadingState} />}
      <ErrorPopup onClose={goToStartCreation} />
    </>
  )
}

export default memo(AIPresetPreview)
