/* @flow */
import styled from 'styled-components'
import THEME from './Email6.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import { Button } from '../SubscribeForm/styled'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  & ${Button} {
    min-width: 80px;
    flex: none;
  }
`

const WMCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  width: 100%;
  max-width: 100%;
  ${({ isEditing }) => (isEditing ? 'margin-top: 60px' : '')};
  @media only screen and (max-width: 1440px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0;
  }
  @media only screen and (max-width: 650px) {
    form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  > :first-child {
    margin-top: 0;
  }
`
const FormBlock = styled.div`
  background: ${getStyleForProp('backgroundOpacityColor')};
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  width: 100%;
  box-shadow: 0 0 7px 2px ${getStyleForProp('shadow')};
  border-top: 1px solid ${getStyleForProp('border')};
  border-bottom: 1px solid ${getStyleForProp('border')};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`
const LeftContainer = styled.div`
  width: calc(100% + 120px);
  padding: 50px 0 50px 40px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 1440px) {
    padding-left: 5%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding: 30px 20px;
  }
`
const RightContainer = styled.div`
  background: ${getStyleForProp('backgroundColor')};
  width: calc(100% - 120px);
  padding-top: 50px;
  padding-bottom: 50px;
  padding-right: 40px;
  box-sizing: border-box;
  display: flex;
  border-top-left-radius: 3px;
  @media only screen and (max-width: 1440px) {
    padding-right: 5%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-right: 0px;
    border-top-left-radius: 0;
  }
  @media only screen and (max-width: 457px) {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 20px;
  }
`

const FormWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: calc(100% - 605px);
  @media only screen and (max-width: 1440px) {
    margin-left: auto;
    margin-right: 0px;
  }
  form {
    display: flex;
    justify-content: center;
    @media (min-width: 650px) {
      & > .control-container {
        margin-left: -42px;
      }
      & > button {
        margin-left: -42px;
      }
    }
    input {
      min-width: 240px;
    }
  }

  .success-message {
    margin-bottom: 0;
    transform: translateX(0);
    left: 0;
    @media only screen and (max-width: 1024px) {
      left: 50%;
      transform: translateX(-50%);
    }
    @media only screen and (max-width: 650px) {
      left: 0%;
      transform: translateX(0%);
    }
  }

  & > p {
    margin-bottom: 20px;
  }

  & > .control-container {
    margin-bottom: 30px;
  }

  .wm-email-input-wrapper {
    transform: translateX(0);
  }

  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }

  @media only screen and (max-width: 457px) {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 0px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const EmailContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 120px);
  max-width: 600px;
  margin-left: auto;
  margin-right: 157px;
  @media only screen and (max-width: 1440px) {
    margin-right: 120px;
    max-width: 538px;
  }
  .btns-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
    &:nth-child(2) {
      margin-bottom: 0;
    }
  }
  & > h1 + p {
    margin-top: 20px;
  }

  @media only screen and (max-width: 1024px) {
    margin-right: auto;
    max-width: 100%;
    width: 100%;
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Triangle = styled.div`
  position: absolute;
  top: 0px;
  right: -1px;
  height: 100%;
  width: 120px;
  background-color: ${getStyleForProp('backgroundColor')};
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  FormBlock,
  EmailContent,
  Title,
  Description,
  FormWrap,
  LeftContainer,
  RightContainer,
  Triangle
}
