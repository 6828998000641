/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA13.styled'
import { validation } from './CTA13.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { DECORATIONS } from './consts'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const CTA13 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      images,
      buttons,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.TextImageContent
          isOneImage={images.length === 1}
          isTwoImage={images.length === 2}
          isThreeImage={images.length === 3}
        >
          <Mapper
            data={images}
            render={(image, idx) => {
              const Decoration = DECORATIONS[idx]
              return (
                <S.ImageFrame className={'image-' + (idx + 1)} key={idx}>
                  <S.ImageWrapper>
                    <Decoration />
                    <S.ControlContainer>
                      <ControlsWithImageCropper
                        actions={Actions.imageActions(
                          idx,
                          images.length,
                          image.imageUrl
                        )}
                        style={{ width: '100%' }}
                        key={idx}
                      >
                        <Image
                          as={S.Img}
                          sizes={image.imgDimensions}
                          defaultImgSrc={image.imageUrl}
                          asProps={{
                            imgCropParams: image.imgCropParams
                          }}
                          alt="CTA images"
                        />
                      </ControlsWithImageCropper>
                    </S.ControlContainer>
                  </S.ImageWrapper>
                </S.ImageFrame>
              )
            }}
          />
          <AddButton
            onAdd={Actions.addBlock}
            toShow={images.length < validation.images.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_image_label')}
          </AddButton>
        </S.TextImageContent>
        <S.ContentWrap>
          <EditableContent
            as={S.Title}
            text={title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            as={S.Paragraph}
            text={paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
        </S.ContentWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default CTA13
