/* @flow */
import styled from 'styled-components'

import THEME from './Feature29.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : ' 80px 0')};
  }
  ${getBackgroundStyles(THEME)};
  .webpage-wrap {
    box-shadow: rgb(37 43 52 / 12%) 0px 9px 22px 0px;
  }
`

const WMCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: 1025px) {
    max-width: 805px;
  }
  > *:first-child {
    margin-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const WebpageWrap = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 50px auto 0 auto;
  box-shadow: rgb(37 43 52 / 12%) 0px 9px 22px 0px;
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
  }
`

const BrowserBarWrapper = styled.div`
  display: block;
  max-width: initial;
  overflow: hidden;
  height: 30px;
  transform: translateY(1px);
`

const BrowserBarImage = styled.img`
  height: auto;
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
  margin-bottom: 0px;
  width: 100%;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  width: 100%;
  margin-top: 20px;
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  margin: 0 auto;
  box-shadow: ${getStyleForProp('raisedShadow')};
  .LazyLoad {
    position: relative;
    padding-top: 51.1%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
  }
`

export default {
  StyledContainer,
  Title,
  WMCustomContainer,
  HeaderContent,
  WebpageWrap,
  BrowserBarWrapper,
  BrowserBarImage,
  Description,
  ImageFigure,
  Img
}
