/* @flow */
import styled from 'styled-components'
import THEME from './Header36.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  padding: 0 0 80px 0;
  ${getBackgroundStyles(THEME)};
  position: relative;
  @media only screen and (max-width: 457px) {
    padding: 0 0 60px 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
`

const HeaderContent = styled.div`
  padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    max-width: 450px;
  }
`

const LeftContent = styled.div`
  display: flex;
  width: 66.6%;
  max-width: 820px;
  & > .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    position: relative;
  }
`

const LeftLine = styled.div`
  width: 2px;
  background-color: ${getStyleForProp('heading')};
  @media only screen and (max-width: 1024px) {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
  }
`

const RightContent = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 0 30px;
  width: 33.3%;
  max-width: 410px;
  position: relative;
  box-sizing: border-box;
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    & > h2,
    p {
      max-width: 100%;
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    align-self: flex-start;
    margin: 30px 0 0 0;
    padding: 0 30px 0 0;
    max-width: initial;
  }
  &:after {
    content: '';
    width: 2px;
    height: 100%;
    background-color: ${getStyleForProp('heading')};
    position: absolute;
    right: 0;
    top: 0;
  }
  .add-button-wrap {
    > div {
      height: 50px;
      box-sizing: border-box;
      padding: 16px 30px;
    }
  }
`

const TitleWrapper = styled.div`
  width: 50%;
  max-width: 410px;
  box-sizing: border-box;
  align-self: flex-start;
  padding: 0 30px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
    margin: 0 0 30px 0;
    padding-left: 11%;
    padding-right: 0;
  }
  .control-container {
    width: 100%;
  }
`


const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const SubTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 150%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 150%;
    height: initial;
  }
`

const ImageWrapper = styled.div`
  width: 50%;
  max-width: 400px;
  @media only screen and (max-width: 1024px) {
    width: 90%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  TitleWrapper,
  Title,
  SubTitle,
  Paragraph,
  ImageWrapper,
  Img,
  ControlContainer,
  LeftContent,
  RightContent,
  LeftLine
}
