/* @flow */
import produce from 'immer'
import { EDITOR_INITIAL_STATE } from '../consts'

// TODO: Refactor all reducers and implement immer
export default (
  mainState: TAppEditorState = EDITOR_INITIAL_STATE,
  action: TEditorActions
) =>
  produce(mainState, state => {
    switch (action.type) {
      case 'SET_SITE_ID':
        state.siteId = action.value
        break
      case 'SET_HAS_CHANGES_AFTER_PUBLISH':
        state.hasChangesAfterPublish = action.value
        break
      case 'SET_REPLACE_STATUS':
        const { addComponentIndex } = action.value
        return {
          ...state,
          addComponentIndex
        }
      case 'SET_PROJECT_LOADED':
        return {
          ...state,
          isProjectLoaded: true
        }
      case 'SET_USER_PAID_DATA':
        state.siteData.paid = action.value.paid
        break
      case 'SET_HAS_WEBSITE_PREMIUM_FEATURE':
        return {
          ...state,
          hasPremiumFeature: true
        }
      case 'SET_NO_LONGER_PREMIUM_POPUP_STATE':
        return {
          ...state,
          isNoLongerPremiumPopupOpen: action.value
        }
      case 'SET_ADDING_COMP_IDX':
        return {
          ...state,
          addComponentIndex: action.value,
          componentReplaceStatus: false
        }
      case 'SET_SELECTED_TEMPLATE_CATEGORY':
        state.templatesInfo.selectedCategoryId = action.value
        break
      case 'APPLY_TEMPLATE_PRESET':
        return {
          ...state,
          isProjectLoaded: true
        }
      case 'SET_PRESET_CATEGORIES':
        state.templatePresetCategories = action.value
      // template reducers
      case 'GET_TEMPLATE_CATEGORIES_STARTED':
        state.templatesInfo.categories.isLoading = true
        break
      case 'GET_TEMPLATE_CATEGORIES_SUCCESS':
        state.templatesInfo.categories = {
          isLoading: false,
          payload: action.value
        }
        break
      case 'GET_TEMPLATES_STARTED':
        state.templatesInfo.templates.isLoading = true
        break
      case 'GET_TEMPLATES_SUCCESS':
        const { templates, totalCount, shouldReset } = action.value
        state.templatesInfo.templates = {
          isLoading: false,
          payload: shouldReset
            ? templates
            : [...state.templatesInfo.templates.payload, ...templates]
        }
        state.templatesInfo.totalCount = totalCount
        break
      case 'RESET_TEMPLATES':
        state.templatesInfo.templates = {
          isLoading: false,
          payload: []
        }
        break
      case 'FETCH_NEWEST_TEMPLATES':
        state.templatesInfo.newestTemplates = action.value
        break
      case 'GET_TEMPLATE_BY_ID_STARTED': {
        const templateId = action.value
        state.templatesData[templateId] = { isLoading: true }
        break
      }
      case 'GET_TEMPLATE_BY_ID_SUCCESS': {
        const { templateId, data } = action.value
        state.templatesData[templateId] = { isLoading: false, data }
        break
      }
      case 'SET_TEMPLATES_ACTIVE_CATEGORY_INFO':
        state.templatesInfo.activeCategoryInfo = action.value
        break
      case 'SET_TEMPLATE_SEARCH_TERM':
        state.templatesInfo.searchTerm = action.value
        break
      case 'SET_APPLY_TEMPLATE_ID':
        state.applyTemplateId = action.value
        break
      // END OF template reducers
      case 'HIDE_ADD_COMPONENT':
        return {
          ...state,
          addComponentIndex: -1,
          componentReplaceStatus: false
        }
      case 'REVISION_SEND_FAILED':
      case 'REVISION_SEND_SUCCESS':
        state.pendingRevisionCount--
        break
      case 'REVISION_SEND_START':
        state.pendingRevisionCount++
        break
      case 'START_PROJECT_PUBLISH':
        state.publishingProject = true
        break
      case 'PROJECT_PUBLISH_FAILED':
        state.publishingProject = false
        break
      // USER REDUCERS
      case 'GET_USER_STARTED':
        state.user = {
          isLoading: true
        }
        break
      case 'GET_USER_SUCCESS':
        state.user = {
          isLoading: false,
          data: action.value
        }
        break
      case 'GET_USER_FAILURE':
      case 'REMOVE_USER_DATA':
        state.user = { isLoading: false }
        break
      // END USER REDUCERS

      case 'SET_TARIFF_PLANS_LOADING_STATE':
        state.tariffPlans.isLoading = action.value
        break
      case 'SET_TARIFF_PLANS':
        state.tariffPlans.payload = action.value
        break
      case 'SET_UPGRADE_PRICES':
        state.upgradePrices = action.value
        break
      case 'SET_SUBSCRIPTIONS_DATA':
        state.subscriptions = action.value
        break
      case 'SET_SITE_DATA':
        state.siteData = action.value
        break
      case 'SET_SUBDOMAIN':
        state.siteData.subdomain = action.value
        break
      case 'SET_MEDIA_LIMITS':
        state.mediaLimits = action.value
        break
      case 'SHOW_WARNING_MODAL':
        state.showWarningModal = action.value
        break
      case 'CLOSE_WARNING_MODAL':
        state.showWarningModal = null
        break
      case 'SHOW_MAINTENANCE_MODAL':
        state.showMaintenanceModal = true
        break
      case 'CLOSE_MAINTENANCE_MODAL':
        state.showMaintenanceModal = false
        break
      case 'SET_DOMAIN_CERTIFIED_STATUS':
        state.isDomainCertified = action.value
        break
      case 'SET_DOMAIN_CONFIGURED_STATUS':
        state.ownDomain.configured.isConfigured = action.value
        break
      case 'SET_DOMAIN_VALUE':
        state.siteData.domain = action.value
        break
      case 'OPEN_LOGIN_POPUP':
        state.loginPopupState = true
        break
      case 'OPEN_CONTRIBUTOR_SIGNOUT_POPUP':
        state.contributorSignoutPopupState = true
        state.currentUserName = action.value
        break
      case 'CLOSE_CONTRIBUTOR_SIGNOUT_POPUP':
        state.contributorSignoutPopupState = false
        break
      case 'OPEN_SHARE_LINK_POPUP':
        state.shareLinkPopupState = true
        break
      case 'CLOSE_SHARE_LINK_POPUP':
        state.shareLinkPopupState = false
        break
      case 'OPEN_GDPR_SETTINGS_CUSTOMIZATION_WINDOW':
        state.gdprSettingsCustomizationWindowState = true
        break
      case 'CLOSE_GDPR_SETTINGS_CUSTOMIZATION_WINDOW':
        state.gdprSettingsCustomizationWindowState = false
        break
      case 'OPEN_PUBLISH_POPUP':
        state.publishPopupState = true
        break
      case 'CLOSE_PUBLISH_POPUP':
        state.publishPopupState = false
        break
      case 'SET_SHARED_LINK_STATUS':
        state.sharedLinkData.isLoading = action.value
        break
      case 'SET_SHARED_LINK_DATA':
        state.sharedLinkData.isLoading = false
        state.sharedLinkData.payload = action.value
        break
      case 'SET_PROJECT_SYNCING_STATUS':
        state.isProjectSyncing = action.value
        break
      case 'SET_IMAGE_UPLOAD_INFO':
        {
          const { key, uploadInfo } = action.value
          state.imagesUploadInfo[key] = uploadInfo
        }
        break
      case 'OPEN_DOMAIN_PAGE':
        state.isDomainPageOpen = true
        break
      case 'CLOSE_DOMAIN_PAGE':
        state.isDomainPageOpen = false
        break
      case 'SET_COUNTRIES_LIST':
        state.countries = action.value
        break
      case 'FETCH_DOMAINS_STARTED':
        state.domains.isLoading = true
        break
      case 'FETCH_DOMAINS':
        if (action.value.resetDomains) {
          state.domains.rows = action.value.data
        } else {
          state.domains.rows.push(...action.value.data)
        }
        state.domains.count = action.value.totalCount
        state.domains.isLoading = false
        break
      case 'SET_SEARCH_QUERY':
        state.domains.searchQuery = action.value
        break
      case 'SET_SUBDOMAIN_SAVING_STATUS':
        state.subdomain.isSaving = action.value
        break
      case 'SET_SUBDOMAIN_ERROR_MESSAGE':
        state.subdomain.errorMessage = action.value
        break
      case 'SET_OWN_DOMAIN_LOADING_STATUS':
        state.ownDomain.isLoading = action.value
        break
      case 'SET_CERTIFIED_STATUS':
        state.siteData.hasCertificate = action.value
        break
      case 'SET_SSL_SUCCESS_MESSAGE':
        state.ownDomain.sslSuccessMessage = action.value
        break
      case 'SET_SSL_ERROR_MESSAGE':
        state.ownDomain.sslErrorMessage = action.value
        break
      case 'SET_OWN_DOMAIN_ERROR_MESSAGE':
        state.ownDomain.errorMessage = action.value
        break
      case 'OPEN_CONGRATULATION_POPUP':
        state.ownDomain.isCongratsPopupOpen = true
        break
      case 'CLOSE_CONGRATULATION_POPUP':
        state.ownDomain.isCongratsPopupOpen = false
        break
      case 'GET_DOMAIN_CONFIGURED_STATUS_STARTED':
        state.ownDomain.configured.isLoading = true
        break
      case 'GET_DOMAIN_CONFIGURED_STATUS_FINISHED':
        state.ownDomain.configured.isLoading = false
        break
      case 'SET_CONFIGURED_MESSAGE':
        state.ownDomain.configured.message = action.value
        break
      case 'CHANGE_ACTIVE_SIDEBAR_ITEM':
        state.activeSidebarItem = action.value
        break
      case 'CHANGE_PROJECT_PAGE': {
        state.activePagePath = action.value
        break
      }
      case 'SET_SITE_NAME':
        state.siteData.name = action.value
        break
      case 'SET_MAIN_SIDEBAR_EXPANDED_STATE':
        state.isMainSidebarExpanded = action.value
        break
      case 'SET_SETTINGS_LOADING_STATUS':
        state.settings.isLoading = action.value
        break
      case 'SET_SETTINGS_SUCCESS_MESSAGE':
        state.settings.successMessage = action.value
        break
      case 'SET_RESET_CHANGES_POPUP_STATE':
        state.resetChangesPopupState = {
          ...state.resetChangesPopupState,
          ...action.value
        }
        break
      case 'SET_SITE_ONLINE_STATUS_UI_STATE':
        state.siteOnlineStatus = {
          ...state.siteOnlineStatus,
          ...action.value
        }
        break
      case 'OPEN_STATUS_CHANGE_AND_PUBLISH_POPUP':
        state.statusChangeAndPublishPopupState = true
        break
      case 'CLOSE_STATUS_CHANGE_AND_PUBLISH_POPUP':
        state.statusChangeAndPublishPopupState = false
        break
      case 'SET_GLOBAL_ERROR_MESSAGE':
        state.globalErrorMessage = action.value
        break
      case 'SET_GOOGLE_ANALYTICS_SUCCESS_MESSAGE':
        state.settings.marketingSuccessMessages.googleAnalyticsSuccessMessage =
          action.value
        break
      case 'SET_YANDEX_METRICA_SUCCESS_MESSAGE':
        state.settings.marketingSuccessMessages.yandexMetricaSuccessMessage =
          action.value
        break
      case 'SET_GOOGLE_TAG_MANAGER_SUCCESS_MESSAGE':
        state.settings.marketingSuccessMessages.googleTagManagerSuccessMessage =
          action.value
        break
      case 'SET_GOOGLE_ADSENSE_SUCCESS_MESSAGE':
        state.settings.marketingSuccessMessages.googleAdSenseSuccessMessage =
          action.value
        break
      case 'SET_AUTH_DIGITAL_SELLERS_SUCCESS_MESSAGE':
        state.settings.marketingSuccessMessages.authDigitalSellersSuccessMessage =
          action.value
        break
      case 'SET_FACEBOOK_PIXEL_SUCCESS_MESSAGE':
        state.settings.marketingSuccessMessages.facebookPixelSuccessMessage =
          action.value
        break
      case 'SET_POSSIBLE_LANGUAGES_DATA':
        state.possibleLanguagesData.data = action.value
        break
      case 'SET_POSSIBLE_LANGUAGES_DATA_LOADING_STATUS':
        state.possibleLanguagesData.isLoading = action.value
        break
      case 'SET_WEBSITE_ACTIVE_LANGUAGE_CODE':
        state.websiteActiveLanguageCode = action.value
        break
      case 'SET_WEBSITE_LANGUAGE_CHANGING_STATUS':
        state.isWebsiteLanguageChanging = action.value
        break
      case 'SET_SEMRUSH_SIDEBAR_PINNED_STATE':
        state.semrushSidebar.isPinned = action.value
        break
      case 'SET_SEMRUSH_SIDEBAR_VISIBILITY_STATE':
        state.semrushSidebar.isOpen = action.value
        break
      case 'SET_EVENT_CREATION_POPUP_VISIBILITY_STATE':
        state.isEventCreationPopupOpen = action.value
        break
      case 'SET_SEMRUSH_KEYWORD_POPUP_STATE':
        state.isSemrushKeywordPopupOpen = action.value
        break
      case 'SET_SEMRUSH_LOGIN_WINDOW_STATE':
        state.isSemrushLoginWindowOpen = action.value
        break
      case 'SET_TIMEZONES_DATA':
        state.timeZonesData = action.value
        break
      case 'SET_LOGO_APPLYING_STATE':
        state.isLogoApplying = action.value
        break
      case 'SET_INITIAL_LOGO_APPLYING_STATE':
        state.isInitialLogoApplying = action.value
        break
      default:
        return state
    }
  })
