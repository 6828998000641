// @flow

import React, { useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TBackgroundImageControlProps } from './types'
import UploadStatusTooltip from './UploadStatusTooltip'
import ImageIcon from '@editor/common/assets/svgr-icons/Image_icon.svg'
import { IMAGE_UPLOAD_FAIL_ICON } from '@website/conf/asset-urls'
import { openLoginPopup } from '@actions/editor'
import { selectIsUserAuthorized } from '@selectors'

const BackgroundImageControl = ({
  className,
  backgroundImage,
  isUserAuthorized,
  openLoginPopup,
  bgImageControlRef,
  openImageFlyout
}: TBackgroundImageControlProps) => {
  const { isLoading, failed } = backgroundImage || {}
  const hasUploadingStatus = isLoading || failed

  const [isInvalidImage, setImageInvalidState] = useState(false)
  const [isUploadTooltipOpen, setUploadTooltipState] = useState(false)

  useEffect(() => {
    setUploadTooltipState(isLoading || failed)
  }, [isLoading, failed])

  useEffect(() => {
    setImageInvalidState(false)
  }, [`${backgroundImage}`])

  const onMouseOver = useCallback(() => {
    if (hasUploadingStatus) {
      setUploadTooltipState(true)
    }
  }, [hasUploadingStatus])

  const onMouseLeave = useCallback(() => {
    if (hasUploadingStatus) {
      setUploadTooltipState(false)
    }
  }, [hasUploadingStatus])

  const closeUploadTooltip = useCallback(() => setUploadTooltipState(false), [])

  const onImageLoadingError = useCallback(() => {
    setImageInvalidState(true)
  }, [])

  const onClick = useCallback(() => {
    if (backgroundImage) {
      return
    }

    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }

    openImageFlyout()
  }, [backgroundImage, isUserAuthorized, openLoginPopup, openImageFlyout])

  return (
    <Styled.BackgroundControl
      ref={bgImageControlRef}
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      className={backgroundImage ? '' : className}
    >
      <UploadStatusTooltip
        isOpen={isUploadTooltipOpen}
        closeTooltip={closeUploadTooltip}
        isLoading={isLoading}
        failed={failed}
      />
      {backgroundImage && isInvalidImage ? (
        <Styled.Image
          src={IMAGE_UPLOAD_FAIL_ICON}
          failed={failed}
          alt="failed"
        />
      ) : backgroundImage && !isInvalidImage ? (
        <Styled.Image
          src={backgroundImage}
          onError={onImageLoadingError}
          alt="bg-img"
        />
      ) : (
        <ImageIcon className="control-icon" />
      )}
    </Styled.BackgroundControl>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

const mapDispatchToProps = { openLoginPopup }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundImageControl)
