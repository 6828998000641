/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const MorphLeft = ({ theme }) => {
  const { look, palette } = theme
  const isSharp = look === 'SHARP'
  const from = palette.primaryLight
  const to = palette.primaryDark
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={569}
      height="602.205"
      viewBox="0 0 569 602.205"
    >
      <defs>
        <linearGradient
          id="a"
          x1="0.5"
          y1={1}
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop stopColor={from} offset="0%" />
          <stop stopColor={isSharp ? from : to} offset="100%" />
        </linearGradient>
      </defs>
      <path
        className="a"
        d="M-2560.074,12181.428v588.248s819.463,95.58,491.553-273.583S-2560.074,12181.428-2560.074,12181.428Z"
        transform="translate(2560.074 -12178.596)"
      />
    </svg>
  )
}

export default withTheme(MorphLeft)
