/* @flow */
import { changeCopyright } from '@website/components/CommonActions'
import { privacyPolicyAction } from '@website/components/ActionMakers'

export default dispatcher => {
  const _changeCopyright = (newValue: string) => {
    dispatcher(changeCopyright(newValue))
  }

  return {
    changeCopyright: _changeCopyright,
    privacyPolicyAction
  }
}
