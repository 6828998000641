import styled from 'styled-components'

import { primary, secondary, white } from '@website/common/styles/colors'

export const LanguagesListItem = styled.li`
  list-style-type: none;
  width: 100%;
  height: 50px;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? primary.Light : white)};
  order: ${({ order }) => order};
  flex-shrink: 0;

  &:hover {
    background-color: ${primary.Light};
  }
`

export const LeftBlock = styled.div`
  display: flex;
  align-items: center;
`

export const RightBlock = styled.div`
  & > div {
    display: flex;
    & > svg {
      fill: #8493bd;
    }
  }
`

export const LanguageInfoBlock = styled.div`
  margin-left: 15px;
  .name {
    font-size: 14px;
    font-weight: 500;
    color: ${secondary.ExtraDark};
  }

  .description {
    font-size: 12px;
    font-weight: 500;
    color: #8493bd;
    margin-top: 3px;
  }
`
