import React, { memo } from 'react'

import * as Styled from './styled'
import ArrowRight from '@editor/common/assets/svgr-icons/arrow_small_right.svg'

const TooltipContent = ({ title, description, link }) => (
  <Styled.TooltipContent>
    <p className="title">{title}</p>
    <p>{description}</p>
    {link ? (
      <a href={link} target="_blank" rel="noreferrer" className="link">
        Learn More
        <ArrowRight />
      </a>
    ) : null}
  </Styled.TooltipContent>
)

export default memo(TooltipContent)
