/* @flow */

module.exports = {
  id: 'NoHeader',
  isUnique: true,
  thumbnailRatio: 0.3147877,
  label: 'NoHeader',
  category: 'header',
  colorMode: 'LIGHT',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/Header/header_12_2.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header12m-1.0.0.jpg',
  metaData: {
    logoSrc: {
      _type: 'logo',
      _dimensionsKey: 'logoDimensions',
      _optimizationSizes: [
        { _width: 500 },
        { _width: 500 },
        { _width: 500 },
        { _width: 500 }
      ],
      _min: 0,
      _max: 2048,
      _value: ''
    },
    logoDimensions: {},
    logoColor: 'default'
  },
  defaultProps: {
    logoSrc: '',
    logoColor: 'default',
    logoDimensions: {}
  }
}
