// @flow
import React, { useCallback, useMemo, useState, useRef } from 'react'
import { connect } from 'react-redux'

import { translate } from '@editor/common/utils/translations'
import { CircleProgressLoader } from '@editor/common/components/Loaders'
import {
  openOrCloseSemrushKeywordsPopup,
  fetchSemrushTablePagination,
  setSemrushKeywordTableDatabase,
  setSemrushKeywordTableActiveTab,
  setSemrushKeywordTablePhrase,
  setSemrushKeywordTableSortingInfo
} from '@actions'
import { RippleLoading } from '@editor/common/components/Loaders'
import { useResponsive } from '@shared/hooks'

import {
  selectSemrushKeywordPopupState,
  selectSemrushKeywordTableDatabase,
  selectSemrushKeywordTableActiveTab,
  selectSemrushKeywordTableSortingInfo,
  selectSemrushKeywordsData,
  selectSemrushKeywordTableRowsOveredState,
  selectSemrushKeywordTablePhrase,
  selectSemrushKeywordsSearchLimitExceededState
} from '@selectors'
import useInfiniteScroll from '@hooks/useInfiniteScroll'

import SemrushKeyword from './SemrushKeyword'
import SemrushKeywordMobile from './SemrushKeywordMobile'

import { WarningContent } from '@editor/common/components/SemrushCommon'

import * as Styled from './styled'
import { TProps } from './types'

const POPUP_TOP_PADDING = 40
const SemrushKeywordPopup = ({
  keywordData,
  isOpen,
  hasResults,
  isSearching,
  sortingInfo,
  isKeywordDataOvered,
  isPaginationLoading,
  keywordTableDatabase,
  hasLimitExeeded,
  keywordTablePhrase,
  keywordTableActiveTab,
  fetchSemrushTablePagination,
  openOrCloseSemrushKeywordsPopup,
  setSemrushKeywordTableDatabase,
  setSemrushKeywordTableActiveTab,
  setSemrushKeywordTableSortingInfo,
  setSemrushKeywordTablePhrase
}: TProps) => {
  const isMobile = useResponsive(1024)

  const [searchValue, setSearchValue] = useState(keywordTablePhrase)
  const [isTableHeadSticky, setTableHeadStickyState] = useState(false)
  const [isFeaturesFlyoutOpened, setFeaturesFlyoutOpenedState] = useState(false)

  const wrapperRef = useRef(null)
  const tableHeadRef = useRef(null)

  const onClose = useCallback(() => {
    openOrCloseSemrushKeywordsPopup(false)
  }, [])

  const handleSearch = useCallback(() => {
    setSemrushKeywordTablePhrase(searchValue)
  }, [setSemrushKeywordTablePhrase, searchValue])

  const handleExamplesClick = useCallback(
    phrase => {
      setSearchValue(phrase)
      setSemrushKeywordTablePhrase(phrase)
    },
    [setSemrushKeywordTablePhrase]
  )

  const handleEnterPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleSearch()
      }
    },
    [handleSearch]
  )

  const onRemoveIconClick = useCallback(() => {
    setSearchValue('')
  }, [])

  const popupStatesCommonProps = useMemo(
    () => ({
      searchValue,
      database: keywordTableDatabase,
      onRemoveIconClick,
      handleEnterPress,
      isFeaturesFlyoutOpened,
      setFeaturesFlyoutOpenedState,
      onSearchChange: setSearchValue,
      onDatabaseChange: setSemrushKeywordTableDatabase
    }),
    [
      searchValue,
      keywordTableDatabase,
      setSearchValue,
      isFeaturesFlyoutOpened,
      setSemrushKeywordTableDatabase
    ]
  )

  const onScroll = useCallback(() => {
    const wrapperScrollTop = wrapperRef?.current.scrollTop + POPUP_TOP_PADDING
    const stickyOffsetTop = tableHeadRef?.current.offsetTop

    if (isFeaturesFlyoutOpened) {
      setFeaturesFlyoutOpenedState(false)
    }

    if (wrapperScrollTop > stickyOffsetTop) {
      setTableHeadStickyState(true)
      return
    }

    setTableHeadStickyState(false)
  }, [wrapperRef, tableHeadRef, isFeaturesFlyoutOpened])

  useInfiniteScroll(wrapperRef, () => {
    if (
      !isPaginationLoading &&
      !isKeywordDataOvered &&
      !hasLimitExeeded &&
      keywordData.length
    ) {
      fetchSemrushTablePagination()
    }
  })

  return (
    <Styled.SemrushKeywordPopup
      isOpen={isOpen}
      onClose={onClose}
      isMobileFullScreen
    >
      {isSearching ? (
        <CircleProgressLoader
          className="circle-loader"
          text={translate('searching_label')}
        />
      ) : null}
      {hasLimitExeeded ? <WarningContent /> : null}
      {isMobile ? (
        <SemrushKeywordMobile
          hasResults={hasResults}
          wrapperRef={wrapperRef}
          onScroll={onScroll}
          searchProps={{
            ...popupStatesCommonProps,
            onSearchClick: handleSearch
          }}
          sortingInfo={sortingInfo}
          tableTargetRef={tableHeadRef}
          isTableHeadSticky={isTableHeadSticky}
          activeTab={keywordTableActiveTab}
          onTabChange={setSemrushKeywordTableActiveTab}
          onSortingInfoChange={setSemrushKeywordTableSortingInfo}
        />
      ) : (
        <SemrushKeyword
          hasResults={hasResults}
          wrapperRef={wrapperRef}
          onScroll={onScroll}
          commonProps={popupStatesCommonProps}
          resultsPageProps={{
            searchedPhrase: keywordTablePhrase,
            activeTab: keywordTableActiveTab,
            sortingInfo: sortingInfo,
            onTabChange: setSemrushKeywordTableActiveTab,
            onSortingInfoChange: setSemrushKeywordTableSortingInfo,
            isTableHeadSticky: isTableHeadSticky,
            targetRef: tableHeadRef
          }}
          searchPageProps={{
            onSearchClick: handleSearch,
            onExamplesClick: handleExamplesClick
          }}
        />
      )}
      <Styled.LoaderWrapper isLoading={isPaginationLoading}>
        <RippleLoading color="#3270e6" />
      </Styled.LoaderWrapper>
    </Styled.SemrushKeywordPopup>
  )
}

const mapStateToProps = (state: TAppState) => ({
  isOpen: selectSemrushKeywordPopupState(state),
  keywordTableActiveTab: selectSemrushKeywordTableActiveTab(state),
  sortingInfo: selectSemrushKeywordTableSortingInfo(state),
  keywordTableDatabase: selectSemrushKeywordTableDatabase(state),
  keywordTablePhrase: selectSemrushKeywordTablePhrase(state),
  hasLimitExeeded: selectSemrushKeywordsSearchLimitExceededState(state),
  hasResults: !!selectSemrushKeywordsData(state).payload,
  keywordData: selectSemrushKeywordsData(state).payload?.rows,
  isSearching: selectSemrushKeywordsData(state).isLoading,
  isPaginationLoading: selectSemrushKeywordsData(state).isPaginationLoading,
  isKeywordDataOvered: selectSemrushKeywordTableRowsOveredState(state)
})

export default connect(mapStateToProps, {
  openOrCloseSemrushKeywordsPopup,
  setSemrushKeywordTableDatabase,
  setSemrushKeywordTableActiveTab,
  setSemrushKeywordTablePhrase,
  setSemrushKeywordTableSortingInfo,
  fetchSemrushTablePagination
})(SemrushKeywordPopup)
