// @flow
import {
  getComponentsListAPI,
  getComponentCategoriesAPI,
  getComponentsDataAPI
} from '@api'

import {
  selectComponentCategories,
  selectComponentsData,
  selectComponentsList,
  selectGeneratorVersion
} from '@selectors'
import { triggerApiError } from '../editor/editor'
import type { TComponentIDs, TComponentsData, TComponentsMeta } from './types'

// ::: Component categories :::
const getComponentCategoriesStarted = data => ({
  type: 'GET_COMPONENT_CATEGORIES_STARTED',
  value: data
})

const getComponentCategoriesSuccess = data => ({
  type: 'GET_COMPONENT_CATEGORIES_SUCCESS',
  value: data
})

export const fetchComponentCategories =
  () => (dispatch: Dispatch, getState) => {
    const componentCategories = selectComponentCategories(getState())
    if (!!componentCategories.length) {
      return Promise.resolve()
    }
    dispatch(getComponentCategoriesStarted())

    return getComponentCategoriesAPI().then(({ data }) => {
      dispatch(getComponentCategoriesSuccess(data))
    })
  }
// ::: End of Component categories :::

// ::: Components list :::
const setComponentsListLoading = (isLoading: boolean) => ({
  type: 'SET_COMPONENTS_LIST_LOADING',
  value: isLoading
})

const setComponentsList = (components: TComponentsMeta) => ({
  type: 'SET_COMPONENTS_LIST',
  value: components
})

export const fetchComponentsList = () => (dispatch, getState) => {
  const componentsList = selectComponentsList(getState())
  const generatorVersion = selectGeneratorVersion(getState())
  if (!!componentsList.length) {
    return Promise.resolve()
  }
  dispatch(setComponentsListLoading(true))
  return getComponentsListAPI(generatorVersion)
    .then(({ data }) => {
      dispatch(setComponentsList(data))
    })
    .catch(e => dispatch(triggerApiError(e)))
    .finally(() => {
      dispatch(setComponentsListLoading(false))
    })
}

const setComponentsDataLoading = (isLoading: boolean) => ({
  type: 'SET_COMPONENTS_DATA_LOADING',
  value: isLoading
})

export const setComponentsData = (componentsData: TComponentsData) => ({
  type: 'SET_COMPONENTS_DATA',
  value: componentsData
})

export const fetchComponentsData =
  (ids: TComponentIDs) => (dispatch, getState) => {
    const componentsData = selectComponentsData(getState())
    const nonPrefetchComponents = ids.filter(id => !componentsData[id])
    if (!nonPrefetchComponents.length) {
      return Promise.resolve()
    }
    dispatch(setComponentsDataLoading(true))
    return getComponentsDataAPI(nonPrefetchComponents)
      .then(({ data }) => {
        const _data = data.reduce((acc, current) => {
          acc[current.id] = current
          return acc
        }, {})
        dispatch(setComponentsData(_data))
      })
      .catch(e => dispatch(triggerApiError(e)))
      .finally(() => {
        dispatch(setComponentsDataLoading(false))
      })
  }
// ::: End of Components list :::
