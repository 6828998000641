/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Testimonials4.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : '80px 0')};

  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
  > div {
    width: 100%;
    max-width: none;
    padding: 0;
  }

  & > div > .control-container {
    display: block;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto 10px auto;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    & > h1 {
      width: 100%;
      max-width: none;
      margin: 0;
    }
  }

  .slick-arrow button {
    background: ${getStyleForProp('buttonBackground')};
    border-radius: ${getStyleForProp('iconRadius')};
  }
  .quote_svg__from {
    stop-color: ${({ theme }) => theme.palette.primaryLight};
  }
  .quote_svg__to {
    stop-color: ${({ theme }) => theme.palette.primaryDark};
  }
  .control-container {
    width: 100%;
  }
  > div {
    > .control-container {
      max-width: 1200px;
      margin-right: auto;
      margin-left: auto;
      @media only screen and (max-width: 1024px) {
        width: calc(100% - 80px);
        h1 {
          padding-left: 0;
          padding-right: 0;
        }
      }
      @media only screen and (max-width: 457px) {
        width: calc(100% - 40px);
      }
    }
  }
`

export const Title = styled.h1`
  width: 90%;
  max-width: 1200px;
  margin: 0 auto 10px auto;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    width: calc(100% - 80px);
  }
  @media only screen and (max-width: 457px) {
    width: calc(100% - 40px);
  }
`

export const ClientWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    .rf-add-btn {
      margin-bottom: 20px;
    }
  }
  .rf-add-btn {
    min-width: 136px;
  }
`

export const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .control-container:first-child {
    margin-bottom: 15px;
    h2 {
      margin-bottom: 0;
    }
  }
`

export const ClientImgWrap = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;
  margin-right: 20px;
  .lazy-figure {
    border-radius: ${getStyleForProp('iconRadius')};
    overflow: hidden;
  }
  @media only screen and (max-width: 767px) {
    width: 80px;
    height: 80px;
    margin: auto auto 20px auto;
  }
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
`
export const Name = styled.h2`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-bottom: 5px;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const Position = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  opacity: 0.5;
  font-weight: 500;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const SliderContainer = styled.div`
  position: relative;
  ${({ isEditing }) =>
    isEditing
      ? css`
          .slick-initialized {
            .slick-slide {
              &:not(.slick-center) {
                pointer-events: none;
              }
            }
          }
        `
      : css``}

  > div {
    width: 100% !important;
    max-width: 1378px !important;
    @media only screen and (max-width: 767px) {
      max-width: 312px !important;
    }
  }
  .slick-initialized {
    .slick-slide {
      width: 456px !important;
      @media only screen and (max-width: 767px) {
        width: 100%;
        max-width: 312px !important;
      }
    }
  }
  .slick-slider {
    .slick-track {
      display: flex !important;
    }
  }
`

export const SlideCountContainer = styled.p`
  font-size: 16px;
  color: ${getStyleForProp('heading')};
  margin: 50px auto 20px auto;
  text-align: center;
`

export const BlockItem = styled.div`
  flex-grow: 1;
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: 20px 0;
  }
  + .styled-controls {
    right: 0;
    left: auto;
  }
`

export const BlockItemWrap = styled.div`
  position: relative;
  box-shadow: 0px 6px 10px rgba(148, 148, 148, 0.2);
  border: 1px solid #f5f7ff;
  border-radius: ${getStyleForProp('borderRadius')};
  padding: 35px 30px 40px 30px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    padding: 30px 20px;
  }
`

export const ItemParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  line-height: 1.4;
  padding-bottom: 50px;
  border-bottom: 1px solid ${getStyleForProp('border')};
  width: 100%;
  ${getTextStyleByAlignment};
`

export const QuoteWrapper = styled.div`
  width: 46px;
  position: absolute;
  left: 30px;
  top: -20px;
  @media only screen and (max-width: 767px) {
    left: 20px;
  }
`
