/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_BLOCK_TITLE':
        draft.blocks[action.value.idx].title = action.value.newBlockTitle
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        draft.blocks[action.value.idx].paragraph =
          action.value.newBlockParagraph
        break
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.blocks[action.value.idx].titleAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.blocks[action.value.idx].paragraphAlignment = alignment
        break
      }
      case 'ADD_ITEM':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'CHANGE_ICON':
        const { idx, iconName } = action.value
        draft.blocks[idx].iconName = iconName
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break

      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }

      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.slides[idx].imgUrl = url
        draft.slides[idx].imgDimensions = dimensions || {}
        draft.slides[idx].imgCropParams = {}
        return
      }
      case 'REMOVE_SLIDER_IMAGE':
        draft.slides.splice(action.value, 1)
        break
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
      }
    }
  })
}
