/* @flow */
import React, { memo, useContext } from 'react'
import S from './Footer5.styled'
import ActionGetter from './Actions'
import Footer from '@website/common/components/Footer'
import WMContainer from '@website/common/components/WMContainer'
import {
  EditingContext,
  DispatchContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import { Controls } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'

const Footer5 = memo(props => {
  const { data } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}

  return (
    <S.Container isEditing={isEditing}>
      <WMContainer>
        <S.FooterContentWrap className="WM_GLOBAL_secondary-font">
          <Footer />
          <S.CopyRightWrapper>
            <EditableContent
              text={data?.copyrightText || ''}
              as={S.Copyright}
              className="WM_GLOBAL_secondary-font"
              onChange={Actions.changeCopyright}
              isPrivacyPolicyVisible={isPrivacyPolicyVisible}
            />
            {isPrivacyPolicyVisible ? (
              <S.LinkWrap>
                <Controls actions={Actions.privacyPolicyAction()}>
                  <LinkWrapper
                    text={privacyPolicyName}
                    url={privacyPolicyLink}
                    linkProps={{
                      className: 'privacy-policy-link WM_GLOBAL_secondary-font'
                    }}
                  />
                </Controls>
              </S.LinkWrap>
            ) : null}
          </S.CopyRightWrapper>
        </S.FooterContentWrap>
      </WMContainer>
    </S.Container>
  )
})

export default Footer5
