/* @flow */
import { hexToRgb } from '@website/common/utils'
import * as BUTTON_STATES from './buttons'

const buttonStates = (
  p: IPalette,
  _colorMode: string,
  lookMode: string,
  btnStage: string
) => {
  const colorMode =
    _colorMode === '' ? 'LIGHT' : _colorMode.replace('EXTRA', '')
  const { normal, hover, active } = BUTTON_STATES[btnStage][lookMode][colorMode]
  return `
    ${normal(p)};
    @media only screen and (min-width: 767px) {
      &:hover {
        ${hover(p)};
      }
    }
    &:active{
      ${active(p)};
    }
  `
}

export default {
  ROUNDED: {
    border: () => `none`,
    borderRadius: () => `10px`,
    imageRadius: () => `15px`,
    badgeRadius: () => `5px`,
    cardRadius: () => `10px`,
    buttonRadius: () => `50px`,
    inputRadius: () => `25px`,
    iconRadius: () => `50%`,
    buttonShadow: (p: Palette) =>
      `0 6px 24px 0 rgba(${hexToRgb(p.primaryDark)}, 0.5)`,

    shadow: (p: Palette) =>
      `0 3px 10px 0 rgba(${hexToRgb(p.tertiaryDark)},0.08)`,

    raisedShadow: (p: Palette) =>
      `0 9px 22px 0 rgba(${hexToRgb(p.tertiaryDark)},0.12)`,

    buttonBackground: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'ROUNDED', 'PRIMARY'),

    buttonBackgroundSecondary: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'ROUNDED', 'SECONDARY'),

    buttonShadowSecondary: (p: Palette) =>
      `0 3px 10px 0 rgba(${hexToRgb(p.secondaryDark)},0.08)`,

    buttonBackgroundTertiary: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'ROUNDED', 'TERTIARY')
  },
  CORNERED: {
    border: () => `none`,
    borderRadius: () => `10px`,
    imageRadius: () => `10px`,
    badgeRadius: () => `5px`,
    buttonRadius: () => `10px`,
    inputRadius: () => `10px`,
    cardRadius: () => `10px`,

    buttonBackground: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'CORNERED', 'PRIMARY'),

    buttonBackgroundSecondary: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'CORNERED', 'SECONDARY'),

    buttonBackgroundTertiary: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'CORNERED', 'TERTIARY'),

    buttonShadow: p => `0 3px 16px 0 rgba(${hexToRgb(p.primaryDark)}, 0.5)`,
    iconRadius: () => `10px`,
    buttonShadowSecondary: p =>
      `0 3px 16px 0 rgba(${hexToRgb(p.secondaryDark)}, 0.5)`,

    shadow: (p: Palette) =>
      `0 3px 10px 0 rgba(${hexToRgb(p.tertiaryDark)},0.08)`,

    raisedShadow: (p: Palette) =>
      `0 9px 22px 0 rgba(${hexToRgb(p.tertiaryDark)},0.12)`
  },
  SHARP: {
    buttonRadius: () => `0`,
    badgeRadius: () => `0`,
    imageRadius: () => `0`,
    buttonBackground: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'SHARP', 'PRIMARY'),

    buttonBackgroundSecondary: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'SHARP', 'SECONDARY'),

    buttonBackgroundTertiary: (p: Palette, colorMode: IColorMode) =>
      buttonStates(p, colorMode, 'SHARP', 'TERTIARY'),
    iconRadius: () => `0`,
    border: (p: Palette) =>
      `1px solid rgba(${hexToRgb(p.tertiarySemiLight)}, 0.3)`
  }
}
