/* @flow */
import React, { useContext, memo } from 'react'
import * as S from './Team8.styled'
import { validation } from './Team8.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { translate } from '@editor/common/utils/translations'

const Team8 = memo(
  ({
    data: {
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      title,
      titleAlignment,
      blocks
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
        isEditing={isEditing}
      >
        <S.WMCustomContainer>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
            required
          />
          <S.BlockContainer
            isFourBlock={blocks.length === 4}
            isFiveBlock={blocks.length === 5}
            isSixBlock={blocks.length === 6}
          >
            {blocks.map((block: string, idx: number) => {
              return (
                <S.BlockItem>
                  <S.BlockItemWrap>
                    <S.ImageWrapper>
                      <S.ControlContainer>
                        <ControlsWithImageCropper
                          actions={Actions.mockupActions(
                            idx,
                            blocks.length,
                            block.imgUrl
                          )}
                          style={{ width: '100%' }}
                        >
                          <Image
                            as={S.Img}
                            alt="Slider image"
                            sizes={block.imgDimensions}
                            defaultImgSrc={block.imgUrl}
                            asProps={{
                              imgCropParams: block.imgCropParams
                            }}
                          />
                        </ControlsWithImageCropper>
                      </S.ControlContainer>
                    </S.ImageWrapper>
                    <S.InfoWrap className="info-wrap">
                      <EditableContent
                        as={S.Name}
                        text={block.name}
                        className="WM_GLOBAL_heading20"
                        maxCount={validation.maxNameChar}
                        alignment={block.nameAlignment}
                        onChange={(newName: string) => {
                          Actions.changeClientName(newName, idx)
                        }}
                        required={!block.position}
                        changeAlignment={(alignment: string) =>
                          Actions.changeNameAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        as={S.Position}
                        text={block.position}
                        maxCount={validation.maxPositionChar}
                        className="WM_GLOBAL_paragraph14"
                        alignment={block.positionAlignment}
                        isEditing={isEditing}
                        onChange={(newPosition: string) => {
                          Actions.changeClientPosition(newPosition, idx)
                        }}
                        required={!block.name}
                        changeAlignment={(alignment: string) =>
                          Actions.changePositionAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        as={S.ItemParagraph}
                        text={block.paragraph}
                        isEditing={isEditing}
                        className="WM_GLOBAL_paragraph14"
                        maxCount={validation.maxDescriptionChar}
                        alignment={block.paragraphAlignment}
                        onChange={(newParagraph: string) => {
                          Actions.changeItemParagraph(newParagraph, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeParagraphAlignment(alignment, idx)
                        }
                      />
                    </S.InfoWrap>
                  </S.BlockItemWrap>
                </S.BlockItem>
              )
            })}
          </S.BlockContainer>
          <AddButton
            onAdd={Actions.addBlock}
            toShow={blocks.length < validation.blocks.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Team8
