// @flow

export const LINK_TYPES = {
  EXTERNAL: 'external',
  TO_PAGE: 'to_page',
  TO_COMPONENT: 'to_component',
  PHONE_CALL: 'phone_call'
}

export const getLinkType = (link: string = '') => {
  if (!link) {
    return LINK_TYPES.EXTERNAL
  }
  const isInternalLink = link.startsWith('/')
  const isPhoneCall = link.includes('tel:')
  if (!isInternalLink && !isPhoneCall) {
    return LINK_TYPES.EXTERNAL
  } else if (isPhoneCall) {
    return LINK_TYPES.PHONE_CALL
  } else if (link.includes('#')) {
    return LINK_TYPES.TO_COMPONENT
  } else {
    return LINK_TYPES.TO_PAGE
  }
}

export const getPageFromLink = (link: string): string => {
  const linkType = getLinkType(link)
  if (linkType === LINK_TYPES.EXTERNAL) {
    return ''
  } else if (linkType === LINK_TYPES.TO_PAGE) {
    return link
  } else {
    const hashSymbolIndex = link.indexOf('#')
    return link.slice(0, hashSymbolIndex)
  }
}

export const getComponentFromLink = (link: string): string => {
  const linkType = getLinkType(link)
  if (linkType !== LINK_TYPES.TO_COMPONENT) {
    return ''
  }
  const hashSymbolIndex = link.indexOf('#')
  return link.slice(hashSymbolIndex + 1)
}
