/* @flow */
import {
  removeBlockActionMaker,
  imageModalActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeImgUrl = (value: Object, undoable: boolean) => ({
    type: 'CHANGE_IMG_URL',
    value,
    undoable
  })
  const blockImageActions = (
    featureIdx: number,
    blockIdx: number,
    subIdx: number,
    imageUrl: string
  ) => [
    ...imageModalActionMaker(dispatcher)({
      showRemove: false,
      keyForSizes: 'feature16',
      actions: {
        change: ({ url, dimensions, undoable }) =>
          changeImgUrl(
            {
              featureIdx,
              blockIdx,
              subIdx,
              url,
              dimensions
            },
            undoable
          )
      },
      imageUrl
    })
  ]
  const changeBlockData = value => ({
    type: 'CHANGE_BLOCK_DATA',
    value
  })

  const changeBlockBadgetext = (featureIdx, blockIdx, subIdx, data) =>
    changeBlockData({ featureIdx, blockIdx, subIdx, data, key: 'badgeText' })

  const changeBlockTitle = (featureIdx, blockIdx, subIdx, data) =>
    changeBlockData({ featureIdx, blockIdx, subIdx, data, key: 'title' })

  const changeBlockParagraph = (featureIdx, blockIdx, subIdx, data) =>
    changeBlockData({ featureIdx, blockIdx, subIdx, data, key: 'paragraph' })

  return {
    addBlock,
    changeTitle,
    changeParagraph,
    changeBlockTitle,
    blockImageActions,
    changeBlockBadgetext,
    changeBlockParagraph,
    getFeatureAction: removeBlockActionMaker(dispatcher)
  }
}
