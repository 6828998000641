/* @flow */
import { buttonModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changePrice = (newPrice: string) => {
    dispatcher({
      type: 'CHANGE_PRICE',
      value: newPrice
    })
  }

  const changeOption = (newOption: string, optionIdx: number) => {
    dispatcher({
      type: 'CHANGE_OPTION',
      value: { newOption, optionIdx }
    })
  }

  const addOption = () => {
    dispatcher({ type: 'ADD_OPTION' })
  }

  const removeOption = optionIdx => ({
    type: 'REMOVE_OPTION',
    value: optionIdx
  })

  const optionAction = (optionIdx: number) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeOption(optionIdx))
    }
  ]

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changePriceAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PRICE_ALIGNMENT',
      value: alignment
    })
  }

  return {
    addOption,
    changePrice,
    changeOption,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changePriceAlignment,
    optionAction,
    btnActions: buttonModalActionMaker(dispatcher)
  }
}
