export const validation = {
  features: {
    min: 1,
    max: 2
  },
  headingMaxChar: 120,
  subHeadingMaxChar: 120,
  paragraphMaxChar: 1500,
  badgeMaxChar: 30,
  blockParagraphMaxChar: 500,
  ctaHeadingMaxChar: 120
}
