import styled from 'styled-components'
import { primary } from '@website/common/styles/colors'

export const SectionComp = styled.div``

export const BoldComp = styled.b`
  font-weight: 600;
`

export const LinkComp = styled.a`
  color: ${primary.Dark};
  text-decoration: none;
  cursor: pointer;
`

export const CustomLink = styled.span`
  margin-right: 5px;
  color: #387dff;
  -webkit-text-decoration: underline;
  text-decoration: underline;
  cursor: pointer;
`
