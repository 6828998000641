// @flow
import React, { useCallback, useContext, useState, memo, useMemo } from 'react'
import { withTheme } from 'styled-components'

import * as Styled from './styled'
import { MODES } from './consts'
import { TColorModeControlsProps, TColorModeControlsChildProps } from './types'
import ColorModeIcon from '@editor/common/assets/svgr-icons/Color_mode_icon.svg'
import { ControlsBar, MobileControls } from '../components'
import ProjectActions from '@actions/project/project'
import { DispatchContext, MobileDetectContext } from '@contexts'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { translate } from '@editor/common/utils/translations'

const ColorModeControlsDesktop = memo(
  ({ theme, selectedIdx, changeColorMode }: TColorModeControlsChildProps) => {
    const [isExpanded, setExpandedState] = useState(true)

    return (
      <ControlsBar
        className="color-mode-controls"
        IconComp={ColorModeIcon}
        tooltipText={!isExpanded ? translate('color_modes_label') : ''}
        isExpanded={isExpanded}
        setExpandedState={setExpandedState}
      >
        {isExpanded ? (
          <Styled.Controls>
            {MODES.map((mode, idx) => (
              <TooltipWrapper
                key={idx}
                text={MODES[idx].tooltipText}
                position="bottom"
                color="#252E48"
              >
                <Styled.Control
                  bgColor={theme.palette[mode.color]}
                  isSelected={selectedIdx === idx}
                  onClick={() => changeColorMode(idx)}
                />
              </TooltipWrapper>
            ))}
          </Styled.Controls>
        ) : null}
      </ControlsBar>
    )
  }
)

const ColorModeControlsMobile = memo(
  ({ theme, selectedIdx, changeColorMode }: TColorModeControlsChildProps) => {
    const [isSlideUpOpen, setSlideUpOpenState] = useState(false)

    const openSlideUp = useCallback(() => {
      setSlideUpOpenState(true)
    }, [])

    const closeSlideUp = useCallback(() => {
      setSlideUpOpenState(false)
    }, [])

    return (
      <MobileControls
        slideUpTitle={translate('color_modes_label')}
        IconComp={ColorModeIcon}
        isSlideUpOpen={isSlideUpOpen}
        onControlClick={openSlideUp}
        closeSlideUp={closeSlideUp}
      >
        <Styled.Controls>
          {MODES.map((mode, idx) => (
            <Styled.Control
              key={idx}
              bgColor={theme.palette[mode.color]}
              isSelected={selectedIdx === idx}
              onClick={() => changeColorMode(idx)}
            />
          ))}
        </Styled.Controls>
      </MobileControls>
    )
  }
)

const ColorModeControls = ({ theme, colorMode }: TColorModeControlsProps) => {
  const componentDispatcher = useContext(DispatchContext)
  const isMobile = useContext(MobileDetectContext)

  const selectedIdx = useMemo(
    () => MODES.findIndex(mode => mode.name === colorMode),
    [colorMode]
  )

  const changeColorMode = useCallback(
    idx => {
      const mode = MODES[idx].name
      componentDispatcher(ProjectActions.changeColorMode(mode))
    },
    [componentDispatcher]
  )

  const commonProps = {
    selectedIdx,
    changeColorMode
  }

  return isMobile ? (
    <ColorModeControlsMobile theme={theme} {...commonProps} />
  ) : (
    <ColorModeControlsDesktop theme={theme} {...commonProps} />
  )
}

export default memo(withTheme(ColorModeControls))
