/* @flow */
import React, { useContext, memo, useState, useCallback, useRef } from 'react'
import S from './Steps7.styled'
import { validation, sliderSettings } from './Steps7.consts'
import '../Swipe/swipe-slider-styles.css'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import Image from '@website/common/components/Image'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { useResponsive } from '@shared/hooks'

const NextArrow = memo(({ onClick, isClickable, isEditing }) => (
  <S.Arrow onClick={onClick} isClickable={isClickable} isEditing={isEditing}>
    <Icon color="white" size="large" name="keyboard_arrow_right" />
  </S.Arrow>
))

const PrevArrow = memo(({ onClick, isClickable, isEditing }) => (
  <S.Arrow onClick={onClick} isClickable={isClickable} isEditing={isEditing}>
    <Icon color="white" size="large" name="keyboard_arrow_left" />
  </S.Arrow>
))

const Steps7 = memo(props => {
  const {
    data: {
      slides,
      title,
      titleAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [activeSlideIdx, setActiveSlideIdx] = useState(0)
  const slickSliderRef = useRef(null)
  const isTabletOrMobile = useResponsive(1024)
  const isPrevButtonActive = isTabletOrMobile || activeSlideIdx !== 0
  const isNextButtonActive =
    isTabletOrMobile || activeSlideIdx !== slides.length - 1

  const next = useCallback(() => {
    if (!isNextButtonActive) {
      return
    }
    const nextIdx = (activeSlideIdx + 1) % slides.length
    setActiveSlideIdx(nextIdx)
  }, [activeSlideIdx, isNextButtonActive])

  const prev = useCallback(() => {
    if (!isPrevButtonActive) {
      return
    }
    const nextIdx = (activeSlideIdx - 1 + slides.length) % slides.length
    setActiveSlideIdx(nextIdx)
  }, [activeSlideIdx, isPrevButtonActive])

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer isEditing={isEditing}>
        <EditableContent
          text={title}
          as={S.Title}
          isEditing={isEditing}
          required={true}
          maxCount={validation.maxTitleChar}
          className="WM_GLOBAL_heading42"
          onChange={Actions.changeTitle}
          alignment={titleAlignment}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <S.ImageSlideContainer slidesLength={slides.length}>
          <S.SlideBoxContainer>
            <S.LineContainer slidesLength={slides.length}>
              <S.Line />
              <S.ProgressLine
                slidesLength={slides.length}
                dotActive={activeSlideIdx}
              />
            </S.LineContainer>
            <S.StepContainer>
              {slides.map((slide, idx) => (
                <S.SliderWrapper
                  slidesCount={slides.length}
                  isActive={idx <= activeSlideIdx}
                  onClick={() => setActiveSlideIdx(idx)}
                >
                  <S.Dot key={idx} slidesLength={slides.length}>
                    0{idx + 1}
                  </S.Dot>
                  <EditableContent
                    isLinkControlHidden
                    as={S.NavigationText}
                    isEditing={isEditing}
                    slidesCount={slides.length}
                    text={slide.slideTitle}
                    required={true}
                    className="WM_GLOBAL_paragraph18"
                    maxCount={validation.maxSliderTitle}
                    onChange={(newSlideTitle: string) => {
                      Actions.changeSlideTitle(newSlideTitle, idx)
                    }}
                  />
                </S.SliderWrapper>
              ))}
            </S.StepContainer>
            <S.MobileDotContainer activeSlideIdx={activeSlideIdx}>
              {slides.map((slide, idx) => (
                <S.SliderWrapper
                  isActive={idx === activeSlideIdx}
                  onClick={() => setActiveSlideIdx(idx)}
                >
                  <S.Dot
                    key={idx}
                    slidesLength={slides.length}
                    className={idx >= activeSlideIdx ? '' : 'inactiveStep'}
                  >
                    0{idx + 1}
                  </S.Dot>
                  <EditableContent
                    isLinkControlHidden
                    as={S.NavigationText}
                    isEditing={isEditing}
                    text={slide.slideTitle}
                    required={true}
                    className="WM_GLOBAL_paragraph18"
                    maxCount={validation.maxSliderTitle}
                    onChange={(newSlideTitle: string) => {
                      Actions.changeSlideTitle(newSlideTitle, idx)
                    }}
                  />
                </S.SliderWrapper>
              ))}
            </S.MobileDotContainer>
          </S.SlideBoxContainer>
          <S.SliderContent isEditing={isEditing}>
            <S.ArrowContainer>
              <PrevArrow
                onClick={prev}
                isClickable={isPrevButtonActive}
                isEditing={isEditing}
              />
              <NextArrow
                onClick={next}
                isClickable={isNextButtonActive}
                isEditing={isEditing}
              />
            </S.ArrowContainer>
            {slides.map((slide, idx) => (
              <S.SlideContainer
                idx={idx}
                className={idx === activeSlideIdx && 'activeStep'}
              >
                <If
                  condition={!!slide.imageUrl}
                  otherwise={() => (
                    <AddButton
                      onAdd={() => Actions.addImageBlock(idx)}
                      style={{
                        marginTop: '50px'
                      }}
                      medium
                      toShow
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      Add image
                    </AddButton>
                  )}
                  then={() => (
                    <S.ImageFigure isEditing={isEditing}>
                      <S.ControlContainer isEditing={isEditing}>
                        <ControlsWithImageCropper
                          toPropagate={false}
                          actions={Actions.getSliderActions(
                            activeSlideIdx,
                            slides[activeSlideIdx].imageUrl
                          )}
                          style={{ width: '100%', height: '100%' }}
                        >
                          <Image
                            withLazy={false}
                            as={S.Img}
                            alt="Slider image"
                            sizes={slide.imgDimensions}
                            defaultImgSrc={slide.imageUrl}
                            asProps={{
                              imgCropParams: slide.imgCropParams
                            }}
                          />
                        </ControlsWithImageCropper>
                        <EditableContent
                          as={S.Tooltip}
                          isEditing={isEditing}
                          text={slide.slideTooltipText}
                          maxCount={validation.maxTooltipChar}
                          className="WM_GLOBAL_paragraph18"
                          onChange={(newTooltipText: string) => {
                            Actions.changeSlideTooltipText(
                              newTooltipText,
                              activeSlideIdx
                            )
                          }}
                          alignment={slide.slideTooltipAlignment}
                          changeAlignment={Actions.changeTooltipAlignment(idx)}
                        />
                      </S.ControlContainer>
                    </S.ImageFigure>
                  )}
                />

                <S.TextContainer isEditing={isEditing}>
                  <EditableContent
                    as={S.SliderParagraph}
                    text={slide.slideContent}
                    isEditing={isEditing}
                    maxCount={validation.maxSlideContentChar}
                    className="WM_GLOBAL_paragraph18"
                    onChange={(newContent: string) => {
                      Actions.changeSlideContent(newContent, activeSlideIdx)
                    }}
                    alignment={slide.slideContentAlignment}
                    changeAlignment={Actions.changeContentAlignment(
                      activeSlideIdx
                    )}
                  />
                </S.TextContainer>
              </S.SlideContainer>
            ))}
          </S.SliderContent>
        </S.ImageSlideContainer>
        <S.StepNumber className="WM_GLOBAL_paragraph16" isEditing={isEditing}>
          Step {activeSlideIdx + 1} of {slides.length}
        </S.StepNumber>
        <S.ButtonsContainer>
          <AddButton
            style={{ marginTop: '2rem' }}
            onAdd={Actions.addSlide}
            toShow={slides.length < validation.slides.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            Add step
          </AddButton>
          <AddButton
            style={{ marginTop: '2rem' }}
            onAdd={() => {
              Actions.removeSlide(activeSlideIdx)
              activeSlideIdx > 1 && setActiveSlideIdx(activeSlideIdx - 1)
            }}
            toShow={slides.length > validation.slides.min}
          >
            <Icon name="glyph-remove" className="icon" size="normal" />
            Remove step
          </AddButton>
        </S.ButtonsContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Steps7
