/* @flow */
import React, { useContext, memo } from 'react'
import * as S from './Text22.styled'
import ActionGetter from './Actions'
import { validation } from './Text22.consts'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'
import Element from '@website/common/assets/svgr-icons/StarElement.svg'
import GridElement from '@website/common/assets/svgr-icons/net.svg'

const Text22 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      block,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      imageUrl,
      imgCropParams,
      imgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer>
        <S.Content>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.titleMaxChar}
            required={!paragraph}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Paragraph}
            maxCount={validation.paragraphMaxChar}
            required={!title}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Show when={[block.length !== 0]}>
            <S.BlockContainer>
              <S.BlockWrapper>
                <Mapper
                  data={block}
                  render={(item: string, idx: number) => {
                    return (
                      <Show when={[item.title, item.paragraph]}>
                        <S.ControlWrapper key={idx}>
                          <ControlsWithModalOpener
                            actions={Actions.removeBlock({
                              idx,
                              showRemove: block.length > validation.block.min
                            })}
                          >
                            <S.BlockItem isEditing={isEditing}>
                              <EditableContent
                                text={item.title}
                                alignment={item.titleAlignment}
                                as={S.BlockTitle}
                                required={block.length === 2}
                                maxCount={validation.blockTitleMaxChar}
                                className="WM_GLOBAL_heading32"
                                onChange={(newTitle: string) =>
                                  Actions.changeBlockTitle(newTitle, idx)
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockTitleAlignment(
                                    alignment,
                                    idx
                                  )
                                }
                              />
                              <EditableContent
                                text={item.paragraph}
                                alignment={item.paragraphAlignment}
                                as={S.BlockParagraph}
                                maxCount={validation.blockParagraphMaxChar}
                                required={block.length === 2}
                                className="WM_GLOBAL_paragraph18"
                                onChange={(newParagraph: string) =>
                                  Actions.changeBlockParagraph(
                                    newParagraph,
                                    idx
                                  )
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockParagraphAlignment(
                                    alignment,
                                    idx
                                  )
                                }
                              />
                            </S.BlockItem>
                          </ControlsWithModalOpener>
                        </S.ControlWrapper>
                      </Show>
                    )
                  }}
                />
                <AddButton
                  onAdd={Actions.addBlock}
                  toShow={block.length < validation.block.max}
                  style={{ marginTop: '20px' }}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_block_label')}
                </AddButton>
              </S.BlockWrapper>
            </S.BlockContainer>
          </Show>
        </S.Content>
        <S.ImageWrap>
          <S.NetContainer>
            <GridElement />
            <GridElement />
          </S.NetContainer>
          <Element />
          <S.ImageFigure>
            <ControlsWithImageCropper actions={Actions.imgActions(imageUrl)}>
              <Image
                as={S.Img}
                alt="Section img"
                defaultImgSrc={imageUrl}
                asProps={{ imgCropParams }}
                sizes={imgDimensions}
              />
            </ControlsWithImageCropper>
          </S.ImageFigure>
        </S.ImageWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text22
