export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    paragraph: p => p.accentWhite,
    backgroundColor: () => '#000000',
    nextComponentColor: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryDark,
    scrollIcon: p => p.accentWhite
  },
  LIGHT: {
    paragraph: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryLight,
    nextComponentColor: p => p.tertiaryLight,
    navbarBg: p => p.tertiaryLight,
    scrollIcon: p => p.tertiaryDark
  },
  EXTRALIGHT: {
    paragraph: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryExtraLight,
    nextComponentColor: p => p.tertiaryExtraLight,
    navbarBg: p => p.tertiaryExtraLight,
    scrollIcon: p => p.tertiaryDark
  }
}
