/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA11_2.styled'
import { validation } from './CTA11_2.consts'
import ActionGetter from './Actions'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import StoreBadge from '@website/common/components/StoreBadge'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { getCurrentScreenSize } from '@website/common/utils'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'

const CTA11_2 = memo(props => {
  const {
    data: {
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency,
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttons,
      appleStoreLink,
      googlePlayLink,
      appleBackground,
      androidBackground,
      androidBackgroundImgCropParams,
      appleBackgroundImgCropParams,
      androidBackgroundDimensions,
      appleBackgroundDimensions,
      appleBackgroundMockupColor = 'dark'
    }
  } = props

  const androidBackgroundUrl =
    (androidBackgroundDimensions &&
      androidBackgroundDimensions[getCurrentScreenSize()]) ||
    androidBackground
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.Content>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading32"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <S.BadgeContainer>
            <If
              condition={!!googlePlayLink}
              then={() => (
                <ControlsWithModalOpener
                  actions={Actions.storeIconActions(
                    'googlePlayLink',
                    googlePlayLink
                  )}
                >
                  <StoreBadge
                    className="badge-item-wrap"
                    badgeName="google"
                    url={googlePlayLink}
                  />
                </ControlsWithModalOpener>
              )}
              otherwise={() => (
                <AddButton
                  onAdd={Actions.addStoreLink('googlePlayLink')}
                  toShow={true}
                  rounded
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  Google play
                </AddButton>
              )}
            />
            <If
              condition={!!appleStoreLink}
              then={() => (
                <ControlsWithModalOpener
                  actions={Actions.storeIconActions(
                    'appleStoreLink',
                    appleStoreLink
                  )}
                >
                  <StoreBadge
                    badgeName="apple"
                    url={appleStoreLink}
                    className="badge-item-wrap"
                  />
                </ControlsWithModalOpener>
              )}
              otherwise={() => (
                <AddButton
                  onAdd={Actions.addStoreLink('appleStoreLink')}
                  toShow={true}
                  rounded
                  style={{ margin: '0 0 0 10px' }}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  App store
                </AddButton>
              )}
            />
          </S.BadgeContainer>
          <Buttons data={buttons} />
        </S.Content>
        <S.MockupsWrap>
          <S.Section className="frame-wrap apple-wrap">
            <S.PhoneWrap>
              <S.Phone color={appleBackgroundMockupColor}>
                <ControlsWithImageCropper
                  actions={Actions.mockupActions({
                    mockupType: 'appleBackground',
                    mockupColor: appleBackgroundMockupColor,
                    imageUrl: appleBackground
                  })}
                >
                  <Image
                    as={S.MobileImg}
                    asProps={{
                      imgCropParams: appleBackgroundImgCropParams
                    }}
                    defaultImgSrc={appleBackground}
                    sizes={appleBackgroundDimensions}
                    alt="Web page"
                  />
                </ControlsWithImageCropper>
              </S.Phone>
            </S.PhoneWrap>
          </S.Section>
          <S.Section className="frame-wrap android-wrap">
            <S.AndroidWrap>
              <ControlsWithImageCropper
                actions={Actions.mockupActions({
                  mockupType: 'androidBackground',
                  imageUrl: androidBackgroundUrl
                })}
                alignment="right"
                style={{ width: '100%' }}
              >
                <Image
                  as={S.MobileImg}
                  asProps={{
                    imgCropParams: androidBackgroundImgCropParams
                  }}
                  defaultImgSrc={androidBackgroundUrl}
                  sizes={androidBackgroundDimensions}
                  alt="Mobile mockup"
                />
              </ControlsWithImageCropper>
            </S.AndroidWrap>
          </S.Section>
        </S.MockupsWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default CTA11_2
