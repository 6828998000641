/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_BLOCK_TITLE': {
        const { title, idx } = action.value
        draft.posts[idx].title = title
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { paragraph, idx } = action.value
        draft.posts[idx].paragraph = paragraph
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.posts[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.posts[idx].paragraphAlignment = alignment
        break
      }
      case 'CHANGE_IMAGE':
        const { idx, url, dimensions } = action.value
        draft.posts[idx].imageUrl = url
        draft.posts[idx].imgDimensions = dimensions || {}
        draft.posts[idx].imgCropParams = {}
        break
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.posts[linkIdx].buttonUrl = linkData.link
        draft.posts[linkIdx].buttonText = linkData.text
        break
      case 'ADD_LINK':
        const { idx: blockIdx } = action.value
        const { buttonUrl, buttonText } = initialState.posts[0]
        draft.posts[blockIdx].buttonUrl = buttonUrl
        draft.posts[blockIdx].buttonText = buttonText
        break
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.posts[idx].buttonUrl = ''
        draft.posts[idx].buttonText = ''
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.posts[idx].imgCropParams = imgCropParams
      }
    }
  })
}
