/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'
import { BUTTON_INITIAL_STATE } from './Header46.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_SLIDER_BACKGROUND_IMAGE': {
        const { idx, url, dimensions } = action.value
        draft.slides[idx].backgroundImgUrl = url
        draft.slides[idx].backgroundImgDimensions = dimensions
        break
      }
      case 'REMOVE_SLIDER_BACKGROUND_IMAGE': {
        const { idx } = action.value
        draft.slides[idx].backgroundImgUrl = ''
        draft.slides[idx].backgroundImgDimensions = {}
        break
      }
      case 'CHANGE_SLIDER_BACKGROUND_TRANSPARENCY': {
        const { idx, bgOverlayTransparency } = action.value
        draft.slides[idx].bgOverlayTransparency = bgOverlayTransparency
        break
      }
      case 'ADD_SLIDE': {
        const activeIdx = action.value

        draft.slides = [
          ...draft.slides.slice(0, activeIdx + 1),
          initialState.slides[0],
          ...draft.slides.slice(activeIdx + 1)
        ]
        break
      }
      case 'REMOVE_SLIDE': {
        const { idx } = action.value
        draft.slides.splice(idx, 1)
        break
      }
      case 'CHANGE_SLIDER_TITLE': {
        const { idx, newTitle } = action.value
        draft.slides[idx].title = newTitle
        break
      }
      case 'CHANGE_SLIDER_PARAGRAPH': {
        const { idx, newParagraph } = action.value
        draft.slides[idx].paragraph = newParagraph
        break
      }
      case 'CHANGE_SLIDER_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].slideTitleAlignment = alignment
        break
      }
      case 'CHANGE_SLIDER_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].slideParagraphAlignment = alignment
        break
      }
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'REMOVE_SOCIAL':
        draft.socials.splice(action.value.idx, 1)
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.socials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.socials[idx].icon = iconName
        }
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        const sliderIdx = action.value.idx
        draft.slides[sliderIdx].buttons[btnIdx].buttonUrl = btnData.link
        draft.slides[sliderIdx].buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON': {
        const sliderIdx = action.additionalProps.idx
        draft.slides[sliderIdx].buttons.splice(action.value, 1)
        break
      }
      case 'ADD_BUTTON': {
        const sliderIdx = action.value.idx
        const btnType = !draft.slides[sliderIdx].buttons[0]
          ? 'primary'
          : draft.slides[sliderIdx].buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.slides[sliderIdx].buttons.unshift({
            ...BUTTON_INITIAL_STATE[0],
            type: btnType
          })
        } else {
          draft.slides[sliderIdx].buttons.push({
            ...BUTTON_INITIAL_STATE[1],
            type: btnType
          })
        }
        break
      }
    }
  })
}
