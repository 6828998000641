import React, { useCallback, useState, useMemo, useEffect } from 'react'

import { generateAIWebsiteAPI } from '@api'
import AIStarsIcon from '@editor/common/assets/svgr-icons/AI_stars.svg'
import { translate } from '@editor/common/utils/translations'
import { pushDataLayer } from '@editor/common/utils/analytics'
import { useHistoryWithSearch } from '@hooks'

import { DESCRIPTIONS, NAME_CATEGORY_CHAR_LIMIT, STEPS, TITLES } from './consts'
import * as Styled from './styled'
import CategoriesSearchInput from './CategoriesSearchInput'
import NameDescriptionInputs from './NameDescriptionInputs'
import StyleCards from './StyleCards'
import Stepper from './Stepper'
import { EDITOR_BASE } from '@editor/conf/routes'
import { useRouteMatch } from 'react-router-dom'

const isEmpty = value => !value.trim().length

const AiWebsiteBuilder = () => {
  const [activeStepIdx, setActiveStepIdx] = useState(0)
  const [style, setStyle] = useState('')
  const [category, setCategory] = useState('')
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const { url } = useRouteMatch(EDITOR_BASE)
  const history = useHistoryWithSearch()
  const searchParams = new URLSearchParams(history.location.search)
  const industry = searchParams
    .get('industry')
    ?.slice(0, NAME_CATEGORY_CHAR_LIMIT)

  const lastStepIdx = STEPS.length - 1

  const isNextBtnVisible = activeStepIdx !== lastStepIdx
  const isBackBtnHidden = activeStepIdx === 0
  const isGenerateWebsiteBtnVisible = activeStepIdx === lastStepIdx
  const isNextBtnDisabled =
    (activeStepIdx === 0 && isEmpty(category)) ||
    (activeStepIdx === 1 && isEmpty(name))

  const onNextBtnClick = useCallback(() => {
    if (isNextBtnDisabled || activeStepIdx === lastStepIdx) {
      return
    }

    setActiveStepIdx(activeStepIdx => activeStepIdx + 1)
  }, [activeStepIdx, isNextBtnDisabled])

  const onBackBtnClick = useCallback(() => {
    setActiveStepIdx(activeStepIdx => activeStepIdx - 1)
  }, [])

  const onGenerateWebsiteBtnClick = useCallback(() => {
    if (!style) {
      return
    }

    const body = {
      category,
      description,
      name,
      style
    }

    generateAIWebsiteAPI(body).then(({ data }) => {
      window.localStorage.setItem('loadingPercentage', 0)
      history.replace({
        pathname: `${url}/preview-project/ai-preset/${data.tempId}`,
        state: name
      })
      pushDataLayer({
        event: 'ai_website_generated',
        style,
        category,
        description,
        brand_name: name,
        temp_id: data.tempId
      })
    })
  }, [category, name, description, style])

  useEffect(() => {
    const onKeyDown = e => {
      if (e.keyCode === 13) {
        if (isNextBtnVisible && !isNextBtnDisabled) {
          onNextBtnClick()
        }

        if (isGenerateWebsiteBtnVisible && style) {
          onGenerateWebsiteBtnClick()
        }
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [isNextBtnVisible, isNextBtnDisabled, isGenerateWebsiteBtnVisible, style])

  useEffect(() => {
    if (!industry) return

    setCategory(industry)
    setActiveStepIdx(1)
  }, [industry])

  const contents = useMemo(
    () => [
      <CategoriesSearchInput
        inputValue={category}
        setInputValue={setCategory}
      />,
      <NameDescriptionInputs
        name={name}
        description={description}
        setName={setName}
        setDescription={setDescription}
      />,
      <StyleCards style={style} setStyle={setStyle} />
    ],
    [category, name, description, style]
  )

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Title>{TITLES[activeStepIdx]}</Styled.Title>
        <Styled.Description>{DESCRIPTIONS[activeStepIdx]}</Styled.Description>
        <Styled.MainContent>{contents[activeStepIdx]}</Styled.MainContent>
        <Styled.ButtonsWrapper>
          {isNextBtnVisible ? (
            <Styled.PrimaryButton
              onClick={onNextBtnClick}
              isDisabled={isNextBtnDisabled}
            >
              {translate('next_label')}
            </Styled.PrimaryButton>
          ) : null}
          {isGenerateWebsiteBtnVisible ? (
            <Styled.PrimaryButton
              onClick={onGenerateWebsiteBtnClick}
              isDisabled={!style}
            >
              <AIStarsIcon />
              {translate('generate_website_label')}
            </Styled.PrimaryButton>
          ) : null}
          <Styled.SecondaryButton
            onClick={onBackBtnClick}
            isHidden={isBackBtnHidden}
          >
            {translate('back_label')}
          </Styled.SecondaryButton>
        </Styled.ButtonsWrapper>
        <Styled.StepperWrapper>
          <Stepper steps={STEPS} activeStepIdx={activeStepIdx} color="white" />
        </Styled.StepperWrapper>
      </Styled.Content>
    </Styled.Container>
  )
}

export default AiWebsiteBuilder
