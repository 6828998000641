/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

export default dispatcher => {
  const addBlock = idx => ({
    type: 'ADD_BLOCK',
    value: idx
  })

  const changeBlockTitle = (newTitle: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx }
    })

  const changeBlockParagraph = (newParagraph: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newParagraph, idx }
    })
  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }
  const removeRow = (rowId: string) => ({
    type: 'REMOVE_ROW',
    value: rowId
  })

  const imageActions = (idx: number, imageIdx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature17',
      showRemove: false,
      additionalArgs: { idx, imageIdx },
      imageUrl
    })

  return {
    changeBlockTitle,
    changeBlockParagraph,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    imageActions,
    addBlock: idx => dispatcher(addBlock(idx)),
    removeRow: idx => dispatcher(removeRow(idx))
  }
}
