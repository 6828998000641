//@flow
import React, { memo } from 'react'

import * as Styled from './styled'

type TProps = {
  steps: Array<string>,
  activeStepIdx: number,
  color?: string
}

const Stepper = ({ steps, activeStepIdx, color = 'black' }: TProps) => (
  <Styled.Stepper>
    {steps.map((step, index) => (
      <Styled.Step key={index} color={color} stepsCount={steps.length}>
        <Styled.StepIndicator
          color={color}
          isActive={index === activeStepIdx}
        />
        {activeStepIdx > index ? <Styled.CheckIcon color={color} /> : null}
        <Styled.Label color={color}>{step}</Styled.Label>
      </Styled.Step>
    ))}
  </Styled.Stepper>
)

export default memo(Stepper)
