// TODO: Add all image urls, which are used for website components

export const BLANK_IMAGE_URL =
  'https://static.rfstat.com/renderforest/images/website_maker_images/white.png'

export const IMAGE_UPLOAD_FAIL_ICON =
  'https://static.rfstat.com/renderforest/images/website_maker_images/Image_Upload_fail_attention_icon_red.svg'

export const IMAGE_UPLOAD_FAIL_BACKGROUND =
  'https://static.rfstat.com/renderforest/images/website_maker_images/Image_Upload_fail_background.jpg'
