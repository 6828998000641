export default {
  DARK: {
    paragraph: p => p.accentWhite
  },
  LIGHT: {
    paragraph: p => p.tertiaryNormal
  },
  EXTRALIGHT: {
    paragraph: p => p.tertiaryNormal
  }
}
