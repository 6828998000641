import styled from 'styled-components'

export const Content = styled.div`
  width: 380px;
  padding: 30px 20px;
  box-sizing: border-box;
  .error-message {
    font-size: 12px;
    bottom: -20px;
    svg {
      width: 14px;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 20px;
    width: auto;
  }
`

export const Title = styled.p`
  height: 60px;
  font-size: 20px;
  font-weight: 600;
  color: #252e48;
  text-align: center;
  padding-top: 40px;
  box-sizing: border-box;
`

export const Label = styled.p`
  font-size: 14px;
  color: #545f7e;
  padding: 0 0 5px 5px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  & > button:first-child {
    margin-right: 30px;
  }
  /* & > button:last-child {
    max-width: 93px;
  } */
`
