/* @flow */
import React from 'react'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeParagraph,
  changeTitle,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

import MockupColorSwitcher from '@website/common/components/MockupColorSwitcher'

export default dispatcher => {
  const changeMockupColor = (mockupType: string) => ({
    type: 'CHANGE_MOCKUP_COLOR',
    value: mockupType
  })

  const makeMockupLight = (mockupType: string) => ({
    type: 'MAKE_MOCKUP_LIGHT',
    value: mockupType
  })

  const makeMockupDark = (mockupType: string) => ({
    type: 'MAKE_MOCKUP_DARK',
    value: mockupType
  })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const _mockupActions = ({
    mockupType,
    mockupColor,
    keyForSizes,
    imageUrl
  }) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes,
      showRemove: false,
      additionalArgs: { mockupType },
      imageUrl
    }),
    {
      renderer: () => (
        <MockupColorSwitcher
          onChoose={() => dispatcher(changeMockupColor(mockupType))}
          onFirstOptionSelect={() => dispatcher(makeMockupLight(mockupType))}
          onSecondOptionSelect={() => dispatcher(makeMockupDark(mockupType))}
          isSecondOptionSelected={mockupColor === 'dark'}
        />
      )
    }
  ]

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    mockupActions: _mockupActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment
  }
}
