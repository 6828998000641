import { hexToRgb } from '@website/common/utils'

export default {
  DARK: {
    shadow: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.15)`,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`,
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => `${p.tertiaryDark}`,
    backgroundColor: p => p.tertiaryDark,
    backgroundOpacityColor: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.4)`
  },
  LIGHT: {
    shadow: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.15)`,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`,
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => `${p.tertiaryLight}`,
    backgroundColor: p => p.tertiaryLight,
    backgroundOpacityColor: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.4)`
  },
  EXTRALIGHT: {
    shadow: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.15)`,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`,
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    backgroundColor: p => p.tertiaryExtraLight,
    backgroundOpacityColor: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.4)`
  }
}
