// @flow

import React from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import moment from 'moment'
import { MoreLinks, SignOutButton, Languages } from '../user-components'
import TopLinks from './TopLinks'
import UserProfile from './UserProfile'

import { openLoginPopup } from '@actions/editor'
import {
  isSubscriber,
  selectIsUserAuthorized,
  selectSitePublishedDate
} from '@selectors'
import { isOpenedWithHash } from '@editor/common/utils'
import { HelpAndSupportButton, UpgradeButton } from '../components'

import * as Styled from './styled'

const ConnectUnauthorized = ({ closeSlideLeft, openLoginPopup }) => (
  <Styled.Content>
    <TopLinks
      closeSlideLeft={closeSlideLeft}
      isUserAuthorized={false}
      openLoginPopup={openLoginPopup}
    />
    <Languages closeDropdown={closeSlideLeft} hasMaxHeightAnimation />
    <Styled.SignInButton
      role="button"
      onClick={() => {
        closeSlideLeft()
        openLoginPopup()
      }}
    >
      <span>{translate('sign_in_label')}</span>
    </Styled.SignInButton>
  </Styled.Content>
)

const ContentForContributor = ({ closeSlideLeft }) => (
  <Styled.Content>
    <TopLinks isContributor closeSlideLeft={closeSlideLeft} />
    <HelpAndSupportButton />
  </Styled.Content>
)

const ContentAuthorized = ({
  isUserSubscriber,
  sitePublishedDate,
  closeSlideLeft
}) => (
  <Styled.Content>
    {!isUserSubscriber ? <UpgradeButton /> : null}
    {sitePublishedDate ? (
      <Styled.PublishedDate>
        <span>
          {transformTextVariables(translate('last_published_on_label'), [
            {
              value: moment(sitePublishedDate).format('DD.MM.YYYY, HH:mm ')
            }
          ])}
        </span>
      </Styled.PublishedDate>
    ) : null}
    <TopLinks closeSlideLeft={closeSlideLeft} isUserAuthorized />
    <UserProfile closeSlideLeft={closeSlideLeft} />
    <MoreLinks closeDropdown={closeSlideLeft} />
    <Languages closeDropdown={closeSlideLeft} />
    <SignOutButton closeUserDropdown={closeSlideLeft} />
  </Styled.Content>
)

export const Content = ({
  isUserAuthorized,
  isUserSubscriber,
  sitePublishedDate,
  closeSlideLeft,
  openLoginPopup
}) =>
  isOpenedWithHash() ? (
    <ContentForContributor closeSlideLeft={closeSlideLeft} />
  ) : isUserAuthorized ? (
    <ContentAuthorized
      closeSlideLeft={closeSlideLeft}
      sitePublishedDate={sitePublishedDate}
      isUserSubscriber={isUserSubscriber}
    />
  ) : (
    <ConnectUnauthorized
      closeSlideLeft={closeSlideLeft}
      openLoginPopup={openLoginPopup}
    />
  )

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state),
  isUserSubscriber: isSubscriber(state),
  sitePublishedDate: selectSitePublishedDate(state)
})

export default connect(mapStateToProps, { openLoginPopup })(Content)
