import styled from 'styled-components'
import THEME from './Socials.theme'
import { styleProvider } from '../../utils'
const getStyleForProp = styleProvider(THEME)

const SocialWrapper = styled.div`
  display: flex;
  .rf-add-btn {
    padding: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: initial;
    }
  }
`

const SocialIcons = styled.div`
  display: inline-flex;
  flex-direction: ${({ layout }) => layout};
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    padding: 0 0 0 28px;
  }
  @media only screen and (max-width: 610px) {
    padding: 0 0 0 12px;
  }
  .link {
    width: 22px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
    fill: ${getStyleForProp('socialColor')};
    @media only screen and (max-width: 1024px) {
      fill: ${getStyleForProp('socialMobileColor')};
    }
    @media (orientation: landscape) and (max-width: 812px) {
      fill: ${getStyleForProp('socialMobileColor')};
    }
  }
`

const AddSocial = styled.div`
  @media only screen and (max-width: 1024px) {
    color: ${getStyleForProp('socialMobileColor')};
  }
  @media (orientation: landscape) and (max-width: 812px) {
    color: ${getStyleForProp('socialMobileColor')};
  }
`

export default {
  AddSocial,
  SocialWrapper,
  SocialIcons
}
