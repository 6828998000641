/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  removeBlock
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockParagraph = (idx, newBlockParagraph) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { idx, newBlockParagraph }
    })
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockTitleAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: () => dispatcher(addBlock()),
    removeBlock,
    changeBlockTitle,
    changeBlockParagraph,
    blockActions: removeBlockActionMaker(dispatcher),
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment
  }
}
