/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeModel = (newModel: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_MODEL',
      value: { newModel, idx }
    })
  }

  const removeSlide = slideIdx => {
    dispatcher({
      type: 'REMOVE_SLIDE',
      value: { slideIdx }
    })
  }

  const addSlide = () => {
    dispatcher({
      type: 'ADD_SLIDE'
    })
  }

  const sliderActions = (showRemove, slideIdx, imageUrl) =>
    cropableImageModalActionMaker(dispatcher)({
      showRemove,
      keyForSizes: 'header22',
      additionalArgs: {
        slideIdx
      },
      imageUrl
    })

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeModel,
    addSlide,
    removeSlide,
    sliderActions: sliderActions
  }
}
