/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Header48.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-top: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  .LazyLoad {
    width: 100%;
  }
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (min-width: 769px) {
    padding-top: ${({ isEditing }) => (isEditing ? '20px' : '0')};
  }
  @media only screen and (max-width: 1200px) {
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1400px;
  padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  position: relative;
  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
    align-items: center;
  }
  @media only screen and (max-width: 768px) {
    padding-top: ${({ topOffset }) => `${topOffset + 20}px`};
  }
`

const ContentWrapper = styled.div`
  position: relative;
  @media only screen and (min-width: 1201px) {
    min-width: 100%;
  }
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${getStyleForProp('cardBackground')};
  padding: 55px 0 55px 80px;
  box-sizing: border-box;
  position: relative;
  z-index: 3;
  @media only screen and (min-width: 1201px) {
    border-radius: ${getStyleForProp('cardRadius')};
    width: 100%;
    min-height: 450px;
  }
  @media only screen and (max-width: 1200px) {
    width: 450px;
    padding: ${({ isEditing }) => (isEditing ? '60px 20px' : '40px 20px')};
    text-align: center;
    border-bottom-left-radius: ${getStyleForProp('cardRadius')};
    border-bottom-right-radius: ${getStyleForProp('cardRadius')};
  }
  @media only screen and (max-width: 650px) {
    width: 334px;
  }
`

const TextsContainer = styled.div`
  width: 70%;
  max-width: 690px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1680px) {
    max-width: 645px;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 550px;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
    justify-content: center;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h4`
  &,
  a {
    ${getTextStyleByAlignment};
    color: ${getStyleForProp('title')};
    margin-bottom: 0;
  }
`

const Paragraph = styled.p`
  &,
  a {
    ${getTextStyleByAlignment};
    color: ${getStyleForProp('paragraph')};
    padding-top: 20px;
  }
`

const RatioWrapper = styled.div`
  position: relative;
  z-index: 4;
  &.ratio-wrapper-1 {
    background-color: ${getStyleForProp('firstImageFigure')};
    width: 225px;
    height: 225px;
    ${({ theme }) =>
      theme.look !== 'SHARP' &&
      css`
        border-radius: 5px;
      `}
    @media only screen and (max-width: 650px) {
      background-color: ${getStyleForProp('firstImageFigureMobile')};
      width: 167px;
      height: 167px;
    }
    .lazy-figure {
      padding-top: 100%;
      border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '50%' : '')};
    }
  }
  &.ratio-wrapper-2 {
    width: 225px;
    height: 450px;
    z-index: 4;
    background-image: linear-gradient(
      to bottom,
      ${getStyleForProp('secondImageFigure')} 0,
      ${getStyleForProp('secondImageFigure')} 100%
    );
    background-size: 225px 225px;
    background-repeat: no-repeat;
    background-position: left bottom;
    ${({ theme }) =>
      theme.look !== 'SHARP' &&
      css`
        border-radius: 5px;
      `}
    @media only screen and (max-width: 650px) {
      width: 167px;
      height: 334px;
      background-size: 167px 167px;
    }
    .lazy-figure {
      padding-top: 200%;
      border-radius: ${({ theme }) =>
        theme.look !== 'SHARP' ? '5px 450px 450px 5px' : '0 0 450px 0'};
      @media only screen and (max-width: 650px) {
        border-radius: ${({ theme }) =>
          theme.look !== 'SHARP' ? '5px 334px 334px 5px' : ' 0 0 334px 0'};
      }
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
`

const TriangleLeft = styled.div`
  width: 0;
  height: 0;
  border-top: 112.5px solid transparent;
  border-right: 112.5px solid ${getStyleForProp('leftTriangle')};
  @media only screen and (max-width: 650px) {
    border-top: 83.5px solid transparent;
    border-right: 83.5px solid ${getStyleForProp('leftTriangle')};
  }
`

const TriangleRight = styled.div`
  border-top: 112.5px solid transparent;
  border-left: 112.5px solid ${getStyleForProp('rightTriangle')};
  @media only screen and (max-width: 650px) {
    border-top: 83.5px solid transparent;
    border-left: 83.5px solid ${getStyleForProp('rightTriangle')};
  }
`

const ControlContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const ImagesWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 4;
  @media only screen and (min-width: 1201px) {
    transform: translateX(-100%);
  }
`

const CirclesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: -40px;
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const Circle = styled.div`
  width: 30px;
  height: 30px;
  margin-top: 25px;
  border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '15px' : '')};
  &.circle-1 {
    background-color: ${getStyleForProp('firstCircle')};
  }
  &.circle-2 {
    background-color: ${getStyleForProp('secondCircle')};
  }
  &.circle-3 {
    background-color: ${getStyleForProp('thirdCircle')};
  }
  &.circle-4 {
    background-color: ${getStyleForProp('fourthCircle')};
  }
`

const FigureWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FirstFigure = styled.div`
  width: 225px;
  height: 225px;
  background-color: ${getStyleForProp('firstFigure')};
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 3;
  ${({ theme }) =>
    theme.look !== 'SHARP' &&
    css`
      border-radius: 5px;
    `}
  overflow: hidden;
  @media only screen and (max-width: 650px) {
    width: 167px;
    height: 167px;
  }
`

const SecondFigure = styled.div`
  width: 225px;
  height: 225px;
  border-bottom-left-radius: 50%;
  background-color: ${getStyleForProp('secondFigure')};
  border-top-left-radius: 50%;
  ${({ theme }) =>
    theme.look !== 'SHARP' &&
    css`
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    `}
  @media only screen and (max-width: 650px) {
    width: 167px;
    height: 167px;
  }
`

const ThirdFigure = styled.div`
  width: 225px;
  height: 225px;
  position: relative;
  z-index: 4;
  border-top-left-radius: 100%;
  background-color: ${getStyleForProp('thirdFigure')};
  ${({ theme }) =>
    theme.look !== 'SHARP' &&
    css`
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
    `}

  @media only screen and (max-width: 650px) {
    display: none;
  }
`

const FourthFigure = styled.div`
  width: 225px;
  height: 225px;
  position: relative;
  z-index: 2;
  background-color: ${getStyleForProp('fourthFigure')};
  ${({ theme }) =>
    theme.look !== 'SHARP' &&
    css`
      border-radius: 5px;
    `}
  @media only screen and (max-width: 650px) {
    width: 167px;
    height: 167px;
  }
`

const FifthFigure = styled.div`
  width: 225px;
  height: 225px;
  background-color: ${getStyleForProp('fifthFigure')};
  ${({ theme }) =>
    theme.look !== 'SHARP' &&
    css`
      border-radius: 5px;
    `}
  @media only screen and (max-width: 650px) {
    display: none;
  }
`

const FifthNestedFigure = styled.div`
  width: 225px;
  height: 225px;
  background-color: ${getStyleForProp('fifthNestedFigure')};
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  ${({ theme }) =>
    theme.look !== 'SHARP' &&
    css`
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
    `}

  @media only screen and (max-width: 650px) {
    width: 167px;
    height: 167px;
  }
`
const DotsWrapper = styled.div`
  width: 308px;
  height: 122px;
  position: absolute;
  z-index: 2;
  svg {
    fill: ${getStyleForProp('title')};
  }
  &.card-dots-wrapper {
    top: -50px;
    left: 0;
    z-index: 2;
    @media only screen and (max-width: 1200px) {
      display: none;
    }
  }
  &.first-dots-wrapper {
    right: -88px;
    top: 96px;
    transform: rotate(90deg);
  }
  &.second-dots-wrapper {
    left: 0;
    top: 452px;
    @media only screen and (max-width: 1200px) {
      top: 458px;
    }
    @media only screen and (max-width: 650px) {
      display: none;
    }
  }
`

const InnerCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${getStyleForProp('border')};
  border-radius: 50%;
  box-sizing: border-box;
  &.first-circle {
    width: 225px;
    height: 225px;
    @media only screen and (max-width: 650px) {
      width: 167px;
      height: 167px;
    }
  }
  &.second-circle {
    width: 183px;
    height: 183px;
    @media only screen and (max-width: 650px) {
      width: 132px;
      height: 132px;
    }
  }
  &.third-circle {
    width: 143px;
    height: 143px;
    @media only screen and (max-width: 650px) {
      width: 104px;
      height: 104px;
    }
  }
  &.fourth-circle {
    width: 103px;
    height: 103px;
    @media only screen and (max-width: 650px) {
      width: 75px;
      height: 75px;
    }
  }
`

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  max-width: 700px;
  font-size: 0;
  margin-left: 1px;
  @media only screen and (max-width: 1200px) {
    justify-content: center;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  ContentWrapper,
  HeaderContent,
  Title,
  Paragraph,
  Img,
  GridWrapper,
  RatioWrapper,
  ImageWrapper,
  ControlContainer,
  FigureWrapper,
  FirstFigure,
  SecondFigure,
  ThirdFigure,
  FourthFigure,
  FifthFigure,
  FifthNestedFigure,
  ImagesWrapper,
  CirclesWrapper,
  Circle,
  DotsWrapper,
  TriangleLeft,
  TriangleRight,
  InnerCircle,
  TextsContainer
}
