import { translate } from '@editor/common/utils/translations'

export const POPUP_TITLE = translate('choose_the_website_section_label')
export const POPUP_INFO_TEXT = translate(
  'choose_the_website_section_info_label'
)
export const AGREEMENT_LABEL = translate('agreement_label_label')
export const EMPTY_STATE_TOOLTIP_TEXT = translate(
  'reset_changes_empty_tooltip_text'
)
export const AGREEMENT_REQUIRED_MESSAGE = translate('agreement_required_text')

export const RESET_OPTIONS = [
  {
    label: translate('content_label'),
    value: 'pages',
    description: translate('reset_changes_content_description')
  },
  {
    label: translate('settings_label'),
    value: 'settings',
    description: translate('reset_changes_settings_description')
  },
  {
    label: translate('widget_settings_label'),
    value: 'widgets',
    description: translate('reset_changes_widget_settings_description')
  }
]
