// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TButtonProps } from './types'

const Button = ({
  className = '',
  type = 'primary',
  onClick,
  children,
  disabled
}: TButtonProps) => (
  <Styled.Button
    onClick={onClick}
    className={className}
    disabled={disabled}
    type={type}
  >
    {children}
  </Styled.Button>
)

export default memo(Button)
