import React from 'react'

import * as Styled from './styled'
import ListNewLabel from '@editor/common/assets/component_list_new_label.svg'
import LittleStar from '@editor/common/assets/badge-star.svg'
import BigStar from '@editor/common/assets/big_sparkle_star.svg'

const NewBadge = () => (
  <Styled.BadgeWrapper>
    <Styled.NewBadge src={ListNewLabel} />
    <Styled.BadgeStar src={BigStar} className="star-1" />
    <Styled.BadgeStar src={LittleStar} className="star-2" />
    <Styled.BadgeStar src={LittleStar} className="star-3" />
    <Styled.BadgeStar src={BigStar} className="star-4" />
  </Styled.BadgeWrapper>
)

export default NewBadge
