/* @flow */
import {
  logoResizeAction,
  changeCopyright,
  changeParagraph,
  changePlaceHolderText,
  changeTitleAlignment
} from '@website/components/CommonActions'
import {
  simpleButtonActionMaker,
  navbarLogoActionMaker
} from '../../ActionMakers'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { privacyPolicyAction } from '@website/components/ActionMakers'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeLabel = (newLabel: string) => {
    dispatcher({
      type: 'CHANGE_LABEL',
      value: newLabel
    })
  }

  const removeForm = () => ({
    type: 'REMOVE_FORM'
  })

  const addForm = () => {
    dispatcher({
      type: 'ADD_FORM'
    })
  }

  const formActions = () => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeForm())
    }
  ]
  const _changeCopyright = (newValue: string) => {
    dispatcher(changeCopyright(newValue))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changePlaceHolderText = (placeholder: string) =>
    dispatcher(changePlaceHolderText(placeholder))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  return {
    changeLabel,
    logoResizeAction,
    privacyPolicyAction,
    changeCopyright: _changeCopyright,
    changeParagraph: _changeParagraph,
    changePlaceHolderText: _changePlaceHolderText,
    addForm,
    formActions,
    getLogoActions: navbarLogoActionMaker(dispatcher),
    buttonActions: simpleButtonActionMaker(dispatcher),
    changeTitleAlignment: _changeTitleAlignment
  }
}
