/* @flow */

type Palette = {
  [key: string]: string
}

export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    raisedShadow: () => 'none',
    heading: (p: Palette) => p.accentWhite,
    paragraph: (p: Palette) => p.accentWhite,
    anchor: p => p.primaryDark,
    background: (p: Palette) => p.tertiaryDark
  },
  LIGHT: {
    heading: (p: Palette) => p.tertiaryDark,
    paragraph: (p: Palette) => p.tertiaryNormal,
    anchor: p => p.primaryDark,
    background: (p: Palette) => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: (p: Palette) => p.tertiaryDark,
    anchor: p => p.primaryDark,
    paragraph: (p: Palette) => p.tertiaryNormal,
    background: (p: Palette) => p.tertiaryExtraLight
  }
}
