import styled from 'styled-components'

export const InputWrapper = styled.div`
  position: relative;

  .error-message {
    font-size: 12px;
    top: unset;
    bottom: -17px;
    & > div:nth-child(2) {
      margin-top: 0;
    }
  }
`

export const InputLabel = styled.p`
  font-size: 14px;
  color: #545f7e;
  margin-bottom: 5px;
  user-select: none;
`

export const CharLimit = styled.p`
  position: absolute;
  right: 0;
  bottom: 3px;
  color: ${({ hasError }) => (hasError ? '#ff4c4c' : '#8493BD')};
  font-size: 12px;
`
