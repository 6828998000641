import styled, { css } from 'styled-components'

export const ZoomOutContainer = styled.div`
  ${({ dimensions }) => {
    if (!dimensions.height) {
      return css``
    }

    return css`
      height: ${dimensions.height}px;
    `
  }}
`

const getWrapperStyles = ({ dimensions, zoomOutRatio }) => {
  if (!dimensions.width && !dimensions.height) {
    return css``
  }

  const { width, height } = dimensions

  const widthDiff = (width - width * zoomOutRatio) / zoomOutRatio
  const heightDiff = (height - height * zoomOutRatio) / zoomOutRatio
  const offsetLeft = widthDiff / 2
  const offsetTop = heightDiff / 2

  return css`
    width: calc(100% + ${widthDiff}px);
    transform: translate(-${offsetLeft}px, -${offsetTop}px)
      scale(${zoomOutRatio});
  `
}

export const ZoomOutWrapper = styled.div`
  @media only screen and (min-width: 769px) {
    ${({ zoomOutRatio }) => {
      const relationalOffset = (100 - zoomOutRatio * 100) / 2
      return zoomOutRatio
        ? css`
            ${getWrapperStyles};
            .slider-background-controls,
            .logo-change-flyout_container,
            .navbar-logo-change-flyout_container {
              transform: scale(${1 / zoomOutRatio})
                translate(${relationalOffset}%, ${relationalOffset}%);
            }
            .slider-arrow-control {
              transform: scale(${1 / zoomOutRatio}) translateY(-30px);
            }
            .slider-5-arrow- {
              transform: scale(${1 / zoomOutRatio});
            }
            .testimonials7-slider {
              .slick-prev,
              .slick-next {
                transform: scale(${1 / zoomOutRatio})
                  translate(0, calc(-50% + 25px));
              }
            }
          `
        : ''
    }}
  }
`
