import styled from 'styled-components'
import { CORN_ICON_URL } from './consts'

export const StyledSyncText = styled.p`
  display: inline-block;
  text-align: center;
  font-size: 18px;
  color: #7682a8;
  margin-top: 20px;
  font-weight: 500;
`
export const StyledLoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 50;
  background-color: ${({ background }) =>
    background || `rgba(255, 255, 255, 0.6)`};
`

export const StyledGearsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const LoadingCorn = styled.div`
  @keyframes cornLoading {
    0% {
      background-position-x: 0px;
    }
    100% {
      background-position-x: -16560px;
    }
  }
  width: 90px;
  height: 90px;
  background-image: url(${CORN_ICON_URL});
  background-repeat: no-repeat;
  animation: cornLoading 4s steps(184) infinite;
  background-color: transparent;
`
