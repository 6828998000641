/* @flow */
import React, { useContext, memo } from 'react'
import S from './Footer9.styled'
import { validation } from './Footer9.consts'
import ActionGetter from './Actions'
import Logo from '@website/common/components/Logo'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled/'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import {
  EditingContext,
  DispatchContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import SocialLinks from '@website/common/components/SocialLinks'
import { getMapSrcWithLatLng } from '@website/common/utils'
import { Controls } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'

const Footer9 = memo(props => {
  const {
    data: {
      copyrightText = '',
      socials,
      logoSrc,
      logoWidth,
      logoColor,
      logoDimensions,
      imgDimensions,
      mapSrc,
      geoLocation
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const _mapSrc = geoLocation ? getMapSrcWithLatLng(geoLocation) : mapSrc

  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}

  return (
    <S.Container isEditing={isEditing}>
      <S.MapWrap>
        <ControlsWithModalOpener
          actions={Actions.getMapActions(mapSrc, geoLocation)}
          style={{ width: '100%', height: '100%' }}
        >
          <Overlay />
          <S.Map title="Map" src={_mapSrc} frameBorder="0" allowFullScreen="" />
        </ControlsWithModalOpener>
      </S.MapWrap>
      <S.WmCustomContainer className="WM_GLOBAL_secondary-font">
        <S.FooterContentWrap>
          <Logo
            logoSrc={logoSrc}
            logoWidth={logoWidth}
            logoColor={logoColor}
            imgDimensions={logoDimensions || imgDimensions}
          />
          <S.CopyRightWrapper>
            <EditableContent
              text={copyrightText}
              as={S.Copyright}
              required={validation.copyrightText.required}
              maxCount={validation.copyrightTextMaxChar}
              className="WM_GLOBAL_paragraph12"
              onChange={Actions.changeCopyright}
              isPrivacyPolicyVisible={isPrivacyPolicyVisible}
            />
            {isPrivacyPolicyVisible ? (
              <S.LinkWrap isEditing={isEditing}>
                <Controls actions={Actions.privacyPolicyAction()}>
                  <LinkWrapper
                    text={privacyPolicyName}
                    url={privacyPolicyLink}
                    linkProps={{
                      className: 'privacy-policy-link WM_GLOBAL_secondary-font'
                    }}
                  />
                </Controls>
              </S.LinkWrap>
            ) : null}
          </S.CopyRightWrapper>

          <SocialLinks
            className="socials"
            socials={socials}
            socialsMaxCount={validation.socials.max}
          />
        </S.FooterContentWrap>
      </S.WmCustomContainer>
    </S.Container>
  )
})

export default Footer9
