//@flow
import React, { useCallback, memo, useEffect } from 'react'

import * as Styled from './styled'
import type { TDonatingProps } from './types'
import { AMOUNT_PLACEHOLDER, ID_PLACEHOLDER, CHECKBOX_DATA } from './consts'
import { Dropdown, Input } from '../components'
import { translate } from '@editor/common/utils/translations'

const DonatingForm = ({
  headline,
  organizationName,
  donationId,
  amount,
  errorMessages,
  activeCurrency,
  currencyDropdownData,
  isCustomerAmountSettingEnabled,
  setData,
  onCurrencyDropdownItemClick,
  setErrorMessages
}: TDonatingProps) => {
  useEffect(() => {
    if (isCustomerAmountSettingEnabled) {
      setData(data => ({
        ...data,
        amount: ''
      }))

      setErrorMessages(errorMessages => {
        const { amount, ...rest } = errorMessages
        return rest
      })

      return
    }

    setErrorMessages(errorMessages => ({
      ...errorMessages,
      amount: ''
    }))
  }, [isCustomerAmountSettingEnabled, setData])

  const onCheckboxChange = useCallback(() => {
    setData(data => ({
      ...data,
      isCustomerAmountSettingEnabled: !data.isCustomerAmountSettingEnabled
    }))
  }, [setData])

  return (
    <>
      <Input
        inputKey="headline"
        label={translate('headline_label')}
        value={headline}
        charLimit={200}
        className="headline-input"
        setData={setData}
      />
      <Styled.InputsWrapper>
        <Input
          inputKey="organizationName"
          label={translate('organization_name_label')}
          value={organizationName}
          charLimit={100}
          setData={setData}
        />
        <Input
          inputKey="donationId"
          label={translate('donation_id')}
          value={donationId}
          charLimit={100}
          placeholder={ID_PLACEHOLDER}
          setData={setData}
        />
      </Styled.InputsWrapper>
      <Styled.Checkbox
        isChecked={isCustomerAmountSettingEnabled}
        onChange={onCheckboxChange}
        item={CHECKBOX_DATA}
      />
      <Styled.InputsWrapper>
        <Input
          type="number"
          inputKey="amount"
          label={translate('amount_label')}
          value={amount}
          charLimit={19}
          placeholder={AMOUNT_PLACEHOLDER}
          isDisabled={isCustomerAmountSettingEnabled}
          setData={setData}
          errorMessage={
            isCustomerAmountSettingEnabled ? '' : errorMessages.amount
          }
          setErrorMessages={setErrorMessages}
        />
        <Dropdown
          isSearchDropdown
          label={translate('currency_label')}
          list={currencyDropdownData}
          activeItem={activeCurrency}
          isDisabled={isCustomerAmountSettingEnabled}
          onItemClick={onCurrencyDropdownItemClick}
        />
      </Styled.InputsWrapper>
    </>
  )
}

export default memo(DonatingForm)
