import http from './ApiFactory'

export const submitBuiltSiteRequest = (url, body) => {
  const _body = { ...body }
  const { cookieSettings } = window.localStorage
  if (cookieSettings) {
    const { marketing } = JSON.parse(cookieSettings)
    _body.isCookieAccepted = marketing
  }
  return http.POST(url, _body)
}
