/* @flow */

export const validation = {
  priceMaxChar: 19,
  blockNameMaxChar: 30,
  supTitleMaxChar: 100,
  titleMaxChar: 100,
  blockDescriptionMaxChar: 100,
  paragraphMaxChar: 300,
  checkmarkMaxChar: 100,
  blockList: {
    min: 1,
    max: 10
  },
  blocks: {
    min: 1,
    max: 6
  }
}

export const DEFAULT_LINK_DATA = {
  url: 'http://builder.renderforestsites.com',
  text: 'Learn more'
}
