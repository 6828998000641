// @flow

import React, { memo, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { GlobalStyles, Dropdown } from './styled'
import { selectAllPagesData } from '@selectors'
import { getPagesDropdownOptions } from './utils'
import type { TPageChooserDropdownProps } from './types'

const PageChooserDropdown = ({
  value,
  label = '',
  onChange,
  disabled = false
}: TPageChooserDropdownProps) => {
  const pages = useSelector(selectAllPagesData)

  const dropdownOptions = useMemo(() => getPagesDropdownOptions(pages), [pages])

  return (
    <>
      <GlobalStyles />
      <Dropdown
        disabled={disabled}
        label={label}
        value={value}
        placeholder="Select a page"
        onChange={onChange}
        options={dropdownOptions}
      />
    </>
  )
}

export default memo(PageChooserDropdown)
