/* @flow */
import React, { useContext, useMemo, memo } from 'react'
import S from './RSVP4.styled'
import { validation } from './RSVP4.consts'
import ActionGetter from './Actions'
import EditableContent from '@website/common/components/EditableContent'
import RSVPButton from '@website/common/components/RSVPButton'
import { DispatchContext, EditingContext } from '@contexts'
import { generateEventCardDate } from '@website/common/utils/event'
import useEventSettingsOpener from '@hooks/useEventSettingsOpener'

const RSVP4Card = memo(
  ({ detailsData = {}, metaData = {}, onRSVPBtnClick, isLoading }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const {
      title,
      subTitle,
      paragraph,
      startDateInfo,
      endDateInfo,
      location,
      button,
      timezone
    } = detailsData

    const {
      dateTitle,
      locationTitle,
      dateTitleAlignment,
      locationTitleAlignment,
      dateValueAlignment,
      locationValueAlignment,
      subtitleAlignment,
      titleAlignment,
      paragraphAlignment
    } = metaData

    const eventDate = useMemo(
      () => generateEventCardDate(startDateInfo, endDateInfo, timezone),
      [startDateInfo, endDateInfo, timezone]
    )

    const { editSubtitle, editTitle, editParagraph, editDate, editLocation } =
      useEventSettingsOpener()

    return (
      <S.DataContent>
        <S.DataTimeContainer>
          <S.DataTimeContainerItem>
            <EditableContent
              text={dateTitle}
              as={S.DateTitle}
              maxCount={validation.stepInfoMaxChar}
              isEditing={isEditing}
              className="WM_GLOBAL_heading20"
              onChange={Actions.changeDateTitle}
              alignment={dateTitleAlignment}
              changeAlignment={Actions.changeDateTitleAlignment}
            />
            <EditableContent
              text={eventDate}
              as={S.DateValue}
              isEditing={isEditing}
              isTypingDisabled
              isTextFormatingHidden
              className="WM_GLOBAL_paragraph18"
              alignment={dateValueAlignment}
              changeAlignment={Actions.changeDateValueAlignment}
              customActions={Actions.eventSettingsActionMaker({
                onControlClick: editDate
              })}
              onDoubleClick={editDate}
            />
          </S.DataTimeContainerItem>
          {location.isVisible ? (
            <S.DataTimeContainerItem>
              <EditableContent
                text={locationTitle}
                as={S.DateTitle}
                maxCount={validation.stepInfoMaxChar}
                isEditing={isEditing}
                className="WM_GLOBAL_heading20"
                onChange={Actions.changeLocationTitle}
                alignment={locationTitleAlignment}
                changeAlignment={Actions.changeLocationTitleAlignment}
              />
              <EditableContent
                text={location.name}
                as={S.LocationValue}
                isEditing={isEditing}
                isTypingDisabled
                isTextFormatingHidden
                target="_blank"
                href={location.url.replace('&output=embed', '')}
                className="WM_GLOBAL_paragraph18"
                alignment={locationValueAlignment}
                changeAlignment={Actions.changeLocationValueAlignment}
                customActions={Actions.eventSettingsActionMaker({
                  onControlClick: editLocation
                })}
                onDoubleClick={editLocation}
              />
            </S.DataTimeContainerItem>
          ) : null}
        </S.DataTimeContainer>
        <EditableContent
          text={subTitle.text}
          isEditing={isEditing}
          as={S.CardSubTitle}
          alignment={subtitleAlignment}
          isTypingDisabled
          isTextFormatingHidden
          className="WM_GLOBAL_paragraph14"
          changeAlignment={Actions.changeSubtitleAlignment}
          customActions={Actions.eventSettingsActionMaker({
            onControlClick: editSubtitle
          })}
          onDoubleClick={editSubtitle}
        />
        <EditableContent
          text={title.text}
          alignment={titleAlignment}
          as={S.Title}
          isEditing={isEditing}
          hasAlignment={true}
          isTypingDisabled
          isTextFormatingHidden
          className="WM_GLOBAL_heading42"
          changeAlignment={Actions.changeTitleAlignment}
          customActions={Actions.eventSettingsActionMaker({
            onControlClick: editTitle
          })}
          onDoubleClick={editTitle}
        />
        <EditableContent
          text={paragraph.text}
          isEditing={isEditing}
          as={S.CardParagraph}
          isTypingDisabled
          isTextFormatingHidden
          className="WM_GLOBAL_paragraph18"
          alignment={paragraphAlignment}
          changeAlignment={Actions.changeParagraphAlignment}
          customActions={Actions.eventSettingsActionMaker({
            onControlClick: editParagraph
          })}
          onDoubleClick={editParagraph}
        />
        <RSVPButton
          buttonText={button.text}
          onClick={onRSVPBtnClick}
          isLoading={isLoading}
        />
      </S.DataContent>
    )
  }
)

export default RSVP4Card
