/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text5_2.styled'
import { validation } from './Text5_2.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'

import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'

const Text5_2 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)

    return (
      <Show when={[title, paragraph]}>
        <S.StyledContainer
          isEditing={isEditing}
          backgroundImgUrl={backgroundImgUrl}
          backgroundImgDimensions={backgroundImgDimensions}
          bgOverlayTransparency={bgOverlayTransparency}
        >
          <S.WmCustomContainer>
            <Show when={[title]}>
              <S.TitleWrapper alignment={titleAlignment}>
                <EditableContent
                  text={title}
                  // alignment={titleAlignment}
                  as={S.Title}
                  required={!paragraph}
                  maxCount={validation.maxTitleChar}
                  className="WM_GLOBAL_heading42"
                  onChange={Actions.changeTitle}
                  // changeAlignment={Actions.changeTitleAlignment}
                />
              </S.TitleWrapper>
            </Show>
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              required={!title}
              maxCount={validation.maxParagraphChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.WmCustomContainer>
        </S.StyledContainer>
      </Show>
    )
  }
)

export default Text5_2
