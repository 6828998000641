import styled from 'styled-components'
import { UPGRADE_BANNER_BACKGROUND } from './consts'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const bannerCustomPadding = {
  padding: '15px 15px 15px 105px'
}

const stylesObj = {
  ru: bannerCustomPadding,
  es: bannerCustomPadding,
  fr: bannerCustomPadding,
  de: bannerCustomPadding,
  pt: bannerCustomPadding,
  tr: bannerCustomPadding,
  jp: bannerCustomPadding,
  default: {
    padding: '25px 25px 25px 120px;'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const UpgradeBannerImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  width: 128px;
  height: 121px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const UpgradeBannerHoverImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 128px;
  height: 121px;
  @media only screen and (max-width: 768px) {
    opacity: 1;
    top: auto;
    bottom: 0;
  }
`

export const TextContainer = styled.div`
  width: 100%;
  padding-right: 10px;
`

export const UpgradeBannerInfo = styled.div`
  user-select: none;
  width: 100%;
  padding: ${getStylesByLang('padding')};

  @media only screen and (max-width: 658px) {
    padding: 15px 15px 15px 120px;
  }

  @media only screen and (min-width: 658px) {
    padding-left: ${({ customTranslationAlignment }) =>
      customTranslationAlignment ? `${customTranslationAlignment}px` : '105px'};
  }
  display: flex;
  align-items: center;
  justify-content: space-around;
  .upgrade-title {
    color: #363f5a;
    font-size: 20px;
    font-weight: 500;
  }
  .upgrade-description {
    color: #363f5a;
    font-size: 14px;
    font-weight: 500;
    margin-top: 10px;
  }
  @media only screen and (max-width: 665px) {
    align-items: flex-start;
    flex-direction: column;
  }

  button {
    display: flex;
    white-space: nowrap;
    color: white;
    font-weight: 500;
    font-size: 14px;
    padding: 11px 20px;
    background: transparent linear-gradient(180deg, #ffa330 0%, #f07379 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #f37c6c80;
    border-radius: 20px;
    cursor: pointer;
    outline: none;
    border: none;
    & > div {
      margin-right: 10px;
    }

    &:hover {
      opacity: 0.9;
    }

    @media only screen and (max-width: 665px) {
      margin-top: 15px;
      padding: 11px 25px;
      width: fit-content;
    }
  }
`

export const UpgradeBanner = styled.div`
  position: relative;
  width: 640px;
  min-height: 121px;
  display: flex;
  margin-top: 20px;
  box-sizing: border-box;
  border: 1px solid #ccdeff;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #387dff33;
  background-color: #f5fafe;
  background-image: url(${UPGRADE_BANNER_BACKGROUND});
  background-repeat: no-repeat;

  &:hover ${UpgradeBannerImage} {
    opacity: 0;
    transition: opacity 0.4s ease-out;
  }

  &:hover ${UpgradeBannerHoverImage} {
    opacity: 1;
    transition: opacity 0.4s ease-out;
  }

  &:hover ${TextContainer} {
    @media only screen and (min-width: 768px) {
      padding-left: 5px;
      transition: padding 0.4s ease-out;
    }
  }

  @media only screen and (max-width: 665px) {
    width: 335px;
    min-height: 140px;
  }

  @media only screen and (max-width: 374px) {
    width: 315px;
  }
`

export const ListItem = styled.div`
  margin-top: 10px;
  display: flex;

  p {
    text-align: left;
    font-size: 16px;
  }

  img {
    height: max-content;
    margin-top: 5px;
    padding: 0 10px;
  }
`
