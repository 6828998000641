/* @flow */
import styled, { css } from 'styled-components'
import THEME from './RSVP5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getCardBGOverlayStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  width: 100%;
  z-index: 2;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  padding: 20px 0 0 0;
  margin-bottom: auto;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-left: 4px;
          margin-top: 50px;
          @media only screen and (max-width: 768px) {
            margin-top: 0;
            padding-top: 0px;
          }
        `
      : css`
          @media only screen and (min-width: 1025px) {
            ${({ theme }) =>
              theme.orientation === 'LEFT'
                ? css`
                    transform: translateX(-50%);
                  `
                : css`
                    transform: translateX(50%);
                  `}
          }
        `};
  ${({ formOpenStatus }) =>
    formOpenStatus === 'open'
      ? css`
          @media only screen and (min-width: 1025px) {
            animation-name: cardAnim;
          }
        `
      : formOpenStatus === 'closed'
      ? css`
          @media only screen and (min-width: 1025px) {
            animation-name: closeCardAnim;
            ${({ theme }) =>
              theme.orientation === 'LEFT'
                ? css`
                    transform: translateX(-50%) !important;
                  `
                : css`
                    transform: translateX(50%) !important;
                  `}
          }
        `
      : ''};
  flex-direction: column;
  position: relative;
  overflow: visible;
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
          @keyframes cardAnim {
            0% {
              transform: translateX(-50%);
            }
            100% {
              transform: translateX(0%);
            }
          }
        `
      : css`
          @keyframes cardAnim {
            0% {
              transform: translateX(50%);
            }
            100% {
              transform: translateX(0%);
            }
          }
        `}
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
          @keyframes closeCardAnim {
            0% {
              transform: translateX(0%);
            }
            100% {
              transform: translateX(50%);
            }
          }
        `
      : css`
          @keyframes closeCardAnim {
            0% {
              transform: translateX(-50%);
            }
            100% {
              transform: translateX(0%);
            }
          }
        `}
`

const BlocksWrapper = styled.div`
  max-width: 1430px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
          flex-direction: row-reverse;
        `
      : css``}
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

const MainCard = styled.div`
  width: 100%;
  max-width: 590px;
  position: relative;
  display: flex;
  flex-direction: column;
  & > .control-container {
    position: absolute;
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          margin-right: auto;
          @media only screen and (max-width: 1024px) {
            margin-right: initial;
          }
          & > .control-container {
            transform: translate(-20px, -20px);
          }
        `
      : ''};
  & > .control-container .secondary-transparency-control div:before {
    top: 48px;
  }

  .add-bg-img {
    position: absolute;
    top: -86px;
    left: 50%;
    min-width: max-content;
    transform: translateX(-50%);
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    padding-right: 0;
  }
`

const BackCardWrapper = styled.div`
  width: 100%;
  max-width: 590px;
  height: 100%;
  position: absolute;
  transform: translate(-20px, -20px);
  margin: 0 auto 20px auto;
  border-radius: ${getStyleForProp('cardRadius')};
  background-color: ${getStyleForProp('cardColor')};
  .control-container {
    height: 100%;
    width: 100%;
    & > div {
      margin-bottom: 0 !important;
    }
  }
  .secondary-transparency-control div::before {
    bottom: -9px;
    top: inherit;
  }
`

const CardWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: 100%;
  max-width: 590px;
  padding: 100px 40px;
  box-sizing: border-box;
  border-radius: ${getStyleForProp('cardRadius')};
  border: 1px solid ${getStyleForProp('border')};
  box-shadow: ${getStyleForProp('raisedShadow')};
  ${({ isCardImage }) =>
    !isCardImage
      ? css`
          background-color: ${getStyleForProp('backgroundColor')};
        `
      : css``};
  @media only screen and (max-width: 457px) {
    padding: 60px 10px;
  }
  .add-button-wrap {
    margin-bottom: 30px;
  }
`
const commonTextStyles = css`
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 20px;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 30px;
  word-break: break-word;
`

const Subtitle = styled.p`
  color: ${getStyleForProp('subTitle')};
  ${commonTextStyles};
  margin-bottom: 10px;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 40px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  .editable-control-container {
    width: 100%;
    margin-bottom: 20px;
    > * {
      margin-bottom: 0;
    }
  }

  > :last-child {
    margin-bottom: 0;
    margin-top: 50px;
  }

  .control-container > a {
    margin-top: 0;
  }
`

const ImageWrap = styled.div`
  font-size: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img {
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ImageFigureBG = styled.div`
  width: 100%;
  height: 100%;
  &::after {
    ${getCardBGOverlayStyles(THEME)};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ImageFigure = styled.div`
  width: 100%;
  max-width: 590px;
  height: 100%;
  .LazyLoad {
    position: relative;
  }
  .lazy-figure {
    top: 0;
    border-radius: ${getStyleForProp('cardRadius')};
    img {
      position: relative !important;
    }
  }
  .control-container {
    height: 100%;
  }
  &::after {
    ${getCardBGOverlayStyles(THEME)};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Date = styled.p`
  width: 100%;
  color: ${getStyleForProp('dateText')};
  ${getTextStyleByAlignment};
  font-weight: 700;
`

const Location = styled.a`
  display: block;
  width: 100%;
  ${getTextStyleByAlignment};
  margin-top: 30px;
  color: ${getStyleForProp('paragraph')};
`

const ComponentWrapper = styled.div`
  ${getBackgroundStyles(THEME)};
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  ${({ formOpenStatus }) =>
    formOpenStatus === 'open'
      ? css`
          .form-container {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-name: formAnimMobile;
            position: relative;
            transform: translateY(0%);
            opacity: 0;
            animation-delay: 0.5s;
            @keyframes formAnimMobile {
              0% {
                transform: translateY(0%);
              }
              100% {
                opacity: 1;
                transform: translateY(0%);
              }
            }
            @keyframes formAnim {
              0% {
                transform: translateY(50%);
              }
              100% {
                opacity: 1;
                transform: translateY(0%);
              }
            }
            @media only screen and (min-width: 1025px) {
              animation-delay: 1s;
              animation-name: formAnim;
              transform: translateY(50%);
            }
          }
        `
      : formOpenStatus === 'closed'
      ? css`
          .form-container {
            animation-duration: 1s;
            animation-fill-mode: forwards;
            animation-name: coloseFormAnim;
            position: relative;
            transform: translateY(50%);
            opacity: 0;
            @keyframes coloseFormAnim {
              0% {
                transform: translateY(0%);
              }
              100% {
                opacity: 1;
                transform: translateY(50%);
              }
            }
          }
        `
      : ''};
  @media only screen and (max-width: 457px) {
      padding-top: 60px;
      padding-bottom: 60px;
    }
  }
  .form-container {
    & > div {
      background-color: transparent !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
      @media only screen and (max-width: 1024px) {
        margin-top: 40px;
      }
    }
    .control-container {
      max-width: 590px;
      padding: 0px;
      margin: 50px auto 50px auto;
      & > div {
        background-color: transparent !important;
        padding-bottom: 0px !important;
      }

      @media only screen and (max-width: 1024px) {
        margin-right: 20px;
        margin-left: 20px;
        margin-top: 50px;
        > div {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
      @media only screen and (max-width: 457px) {
        margin-right: 10px;
        margin-left: 10px;
        > div {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
  }
`

export default {
  MainCard,
  StyledContainer,
  BlocksWrapper,
  CardWrapper,
  BackCardWrapper,
  WmCustomContainer,
  Title,
  Subtitle,
  Paragraph,
  Content,
  Img,
  ImageWrap,
  ImageFigure,
  ImageFigureBG,
  Date,
  Location,
  ComponentWrapper
}
