//@flow
import { useState, useEffect, useCallback } from 'react'

import { VALIDATION, WITH_VALIDATION_ERROR_CLASSNAME } from './consts'
import type { TRegister, TValue } from './types'

export const useFormValidation = onSubmitCb => {
  const [formData, setFormData] = useState({})
  const [formErrors, setFormErrors] = useState({})
  const [formValidationOptions, setFormValidationOptions] = useState({})

  useEffect(() => {
    const elem = document.getElementsByClassName(
      WITH_VALIDATION_ERROR_CLASSNAME
    )[0]
    if (elem) {
      elem.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [formErrors])

  const addFormField = (fieldName, value) => {
    setFormData(formData => ({
      ...formData,
      [fieldName]: value
    }))
  }

  const removeFormField = fieldName => {
    setFormData(formData => {
      // eslint-disable-next-line no-unused-vars
      const { [fieldName]: removedField, ...restFormData } = formData
      return restFormData
    })
    setFormErrors(formErrors => {
      // eslint-disable-next-line no-unused-vars
      const { [fieldName]: removedError, ...restFormErrors } = formErrors
      return restFormErrors
    })
  }

  const addValidationOptions = (fieldName, validationOptions) => {
    setFormValidationOptions(formValidationOptions => ({
      ...formValidationOptions,
      [fieldName]: validationOptions
    }))
  }

  const removeValidationOptions = fieldName => {
    setFormValidationOptions(formValidationOptions => {
      // eslint-disable-next-line no-unused-vars
      const {
        [fieldName]: removedOptions,
        ...restOptions
      } = formValidationOptions
      return restOptions
    })
  }

  const register = useCallback(
    ({ fieldName, validationOptions, onChange, initialValue }: TRegister) => {
      return {
        addFormField: () => addFormField(fieldName, initialValue),
        removeFormField: () => removeFormField(fieldName),
        addValidationOptions: () =>
          addValidationOptions(fieldName, validationOptions),
        removeValidationOptions: () => removeValidationOptions(fieldName),
        hasValidationError: !!formErrors[fieldName],
        onChange: (value: TValue) => {
          onChange && onChange(value)
          addFormField(fieldName, value)
          if ((Array.isArray(value) && !!value.length) || value !== '') {
            // eslint-disable-next-line no-unused-vars
            const { [fieldName]: removedField, ...restErrors } = formErrors
            setFormErrors(restErrors)
          }
        }
      }
    },
    [formErrors]
  )

  const handleSubmit = () => {
    const errors = {}
    for (const fieldName in formData) {
      const value = formData[fieldName]
      const validationOptions = formValidationOptions[fieldName]
      const validationTypes = Object.keys(validationOptions)

      for (const type of validationTypes) {
        if (!validationOptions[type]) {
          break
        }

        const validator =
          validationOptions[type].validator || VALIDATION[type].validator
        const errorMessage =
          validationOptions[type].errorMessage || VALIDATION[type].errorMessage

        if (!validator(value)) {
          errors[fieldName] = errorMessage
          break
        }
      }
    }

    setFormErrors(errors)

    if (Object.keys(errors).length === 0) {
      onSubmitCb()
    }
  }

  return {
    formErrors,
    register,
    handleSubmit
  }
}
