/* @flow */
import {
  buttonModalActionMaker,
  removeBlockActionMaker,
  imageModalActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'
export default dispatcher => {
  const changeSubTitle = (newSubTitle: string) => ({
    type: 'CHANGE_SUBTITLE',
    value: newSubTitle
  })
  const changeCtaText = (newCtaText: string) => ({
    type: 'CHANGE_CTA_TEXT',
    value: newCtaText
  })
  const changeItemParagraph = (
    newItemParagraph: string,
    idx: number,
    featureIdx: number
  ) => ({
    type: 'CHANGE_ITEM_PARAGRAPH',
    value: { newItemParagraph, idx, featureIdx }
  })
  const changeBadgeText = (
    newBadgeText: string,
    idx: number,
    featureIdx: number
  ) => ({
    type: 'CHANGE_BADGE_TEXT',
    value: { newBadgeText, idx, featureIdx }
  })

  const changeImgUrl = (value: Object, undoable: boolean) => ({
    type: 'CHANGE_IMG_URL',
    value,
    undoable
  })
  const imageActions = (
    itemIdx: number,
    blockIdx: number,
    imageUrl: string
  ) => [
    ...imageModalActionMaker(dispatcher)({
      showRemove: false,
      keyForSizes: 'feature15',
      actions: {
        change: ({ url, dimensions, undoable }) =>
          changeImgUrl({ url, dimensions, itemIdx, blockIdx }, undoable)
      },
      imageUrl
    })
  ]
  const addBottomBlock = () => ({
    type: 'ADD_BOTTOM_BLOCK'
  })

  const removeBottomBlock = () => ({
    type: 'REMOVE_BOTTOM_BLOCK'
  })

  return {
    addBlock,
    changeTitle,
    imageActions,
    changeCtaText,
    changeSubTitle,
    addBottomBlock,
    changeParagraph,
    changeBadgeText,
    changeItemParagraph,
    btnActions: buttonModalActionMaker(dispatcher),
    featureActions: removeBlockActionMaker(dispatcher),
    joinBlockAction: [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeBottomBlock())
      }
    ]
  }
}
