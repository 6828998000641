/* @flow */
import {
  videoModalActionMaker,
  removeBlockActionMaker,
  iconActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'
import {
  addLink,
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addLink,
    addBlock: _addBlock,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitle,
    changeBlockParagraph,
    changeBlockParagraphAlignment,
    linkActions: linkActionMaker(dispatcher),
    iconActions: iconActionMaker(dispatcher),
    videoActions: videoModalActionMaker(dispatcher),
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
