/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import { addBlock } from '@website/components/CommonActions'
export default dispatcher => {
  const changeSlideTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TITLE',
      value: { newTitle, idx }
    })
  }
  const changeSlideParagraph = (newParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_PARAGRAPH',
      value: { newParagraph, idx }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const changeSlideTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeSlideParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: _addBlock,
    changeSlideTitle,
    changeSlideParagraph,
    sliderActions: removeBlockActionMaker(dispatcher),
    changeSlideTitleAlignment,
    changeSlideParagraphAlignment
  }
}
