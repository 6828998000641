/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header56.theme'
import Icon from '@website/common/components/Icon'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'
import {
  MOBILE_SIZE,
  TABLET_SIZE,
  LARGE_SIZE
} from './../../../../src/shared/globals'

export const getStyleForProp = styleProvider(THEME)

export const WmCustomContainer = styled(WMContainer)`
  height: 70%;
  display: flex;
  justify-content: center;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  min-height: calc(100vh - 20px);
  .buttons-block {
    justify-content: center;
    margin: 0 auto 30px;
  }
  .buttons-wrapper {
    justify-content: center;
  }
  @media only screen and (max-width: 820px) {
    min-height: calc(100vh - 50px);
  }
`

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0 0 20px 0;
  flex-direction: column;
  position: relative;
  & ${S.Container} {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 820px) {
    padding-bottom: 50px;
  }
`

export const HeaderContent = styled.div`
  width: 100%;
  max-width: 1400px;
  ${({ isEditing }) => (isEditing ? 'padding-top: 60px' : '')};
  text-align: center;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1210px) and (min-width: 1025px) {
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }

  > :first-child {
    padding-top: 0;
    margin-top: auto;
  }
  > .control-container {
    margin-bottom: 30px;
    h1,
    h2,
    p {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

export const HeaderContentBlock = styled.div`
  margin-bottom: -50px;
`

export const BottomContainer = styled.div`
  margin-top: auto;
  padding-top: 100px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  @media only screen and (max-width: 820px) {
    flex-direction: column;
    row-gap: 20px;
  }

  .control-container:nth-child(1) {
    margin-right: auto;
    width: 45%;
    max-width: 487px;
    @media only screen and (max-width: 820px) {
      width: 100%;
      max-width: 100%;
    }
    & > p {
      width: 100%;
    }
  }
  .control-container:nth-child(2) {
    margin-left: auto;
    width: 45%;
    max-width: 487px;
    @media only screen and (max-width: 820px) {
      margin-right: auto;
      margin-left: 0;
      width: 100%;
      max-width: 100%;
    }
    & > p {
      width: 100%;
    }
  }
`

export const LinesContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  ${({ isEditing }) =>
    !isEditing
      ? css`
          span:nth-child(1) {
            animation: lineAnimation1 forwards 7s;
          }
          span:nth-child(2) {
            animation: lineAnimation2 forwards 7s;
          }
          span:nth-child(3) {
            animation: lineAnimation3 forwards 7s;
          }
          span:nth-child(4) {
            animation: lineAnimation4 forwards 7s;
          }
          span:nth-child(5) {
            animation: lineAnimation5 forwards 7s;
          }
        `
      : ''}
  & > span {
    height: 100%;
    border-left: 1px solid ${getStyleForProp('lines')};
    border-right: 1px solid ${getStyleForProp('lines')};
    position: absolute;
    transition-timing-function: ease-in-out;
    top: 0;
  }
  & > span:nth-child(1) {
    width: 30%;
    left: 5%;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  & > span:nth-child(2) {
    width: 35%;
    left: 23%;
    @media only screen and (max-width: ${TABLET_SIZE}px) {
      display: none;
    }
  }
  & > span:nth-child(3) {
    width: 40%;
    left: 30%;
  }
  & > span:nth-child(4) {
    width: 25%;
    left: 55%;
    @media only screen and (max-width: ${TABLET_SIZE}px) {
      display: none;
    }
  }
  & > span:nth-child(5) {
    width: 25%;
    left: 70%;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }
  @keyframes lineAnimation1 {
    0% {
    }
    50% {
      width: 20%;
      left: 45%;
    }
    100% {
      width: 15%;
      left: 7%;
    }
  }
  @keyframes lineAnimation2 {
    0%,
    100% {
      width: 35%;
      left: 23%;
    }
    50% {
      width: 50%;
      left: 32%;
    }
  }
  @keyframes lineAnimation3 {
    0%,
    100% {
      width: 33%;
      left: 32%;
    }
    50% {
      width: 20%;
      left: 20%;
    }
  }
  @keyframes lineAnimation4 {
    0%,
    100% {
      width: 23%;
      left: 45%;
    }
    50% {
      width: 34%;
      left: 44%;
    }
  }
  @keyframes lineAnimation5 {
    0%,
    100% {
      width: 25%;
      left: 70%;
    }
    50% {
      width: 15%;
      left: 80%;
    }
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-top: auto;
  margin-bottom: 0;
`

export const BottomLeft = styled.p`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  max-width: 487px;
  width: 45%;
  margin-right: auto;
  @media only screen and (max-width: 820px) {
    width: 100%;
    max-width: 100%;
  }
`
export const BottomRight = styled.p`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  max-width: 487px;
  width: 45%;
  margin-left: auto;
  @media only screen and (max-width: 820px) {
    margin-right: auto;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }
`

export const DownButton = styled.div`
  padding: 14px;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  transition: 500ms all cubic-bezier(0.84, 0.04, 1, 1);
  animation: arrowBounce 3s linear infinite;
  @keyframes arrowBounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0) translateX(-50%);
    }
    40% {
      transform: translateY(-10px) translateX(-50%);
    }
    60% {
      transform: translateY(-5px) translateX(-50%);
    }
  }
  @media only screen and (max-width: 820px) {
    bottom: -10px;
  }
`
export const ScrollIcon = styled(Icon)`
  fill: ${getStyleForProp('heading')};
`
