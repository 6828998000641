/* @flow */
import React, { useState } from 'react'
import { Input } from './RangeSlider.styled'

const RangeSlider = ({ value, onChange, min = 0, max = 100, step = 1 }) => {
  const [_value, setValue] = useState(value)

  const handleChange = (e: Event) => {
    const { value: _value } = e.target
    if (onChange) {
      onChange(_value)
    }
    setValue(_value)
  }

  return (
    <Input
      min={min}
      max={max}
      step={step}
      type="range"
      value={_value}
      onChange={handleChange}
    />
  )
}

export default RangeSlider
