/* @flow */
import styled from 'styled-components'
import THEME from './Feature1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '160px 0 80px 0' : ' 80px 0')};
  }
`

const Headings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > p:first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
  & + p {
    padding-top: 20px;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  width: 100%;
  ${getTextStyleByAlignment};
  padding-top: 20px;
`

const FeaturesContainer = styled.div`
  max-width: ${props => (props.isForBlock ? '900px' : '1200px')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: -50px auto 0 auto;
  padding-top: 50px;
  @media only screen and (max-width: 992px) {
    margin-top: -50px;
    padding-top: 40px;
  }
`

const FetaureItem = styled.div`
  padding: ${props => (props.isForBlock ? '0 50px' : '0 1rem')};
  margin-top: 50px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 320px;
  flex-grow: inherit;
  @media only screen and (max-width: 992px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 450px) {
    padding: 0;
  }
  .link-section {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
  .learn-more-link {
    color: ${getStyleForProp('anchor')};
    svg {
      fill: ${getStyleForProp('anchor')};
    }
  }
  > p:first-child,
  > div:first-child {
    margin-top: 0;
  }
`

const FeatureItemHeading = styled.div`
  display: flex;
  align-items: center;
`

const FeatureIconTextTitle = styled.p`
  color: ${getStyleForProp('heading')};
`

const Circle = styled.div`
  border-radius: ${getStyleForProp('iconRadius')};
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getStyleForProp('buttonBackground')};
  pointer-events: none;
`

const IconWrap = styled.div`
  margin-right: 1rem;
  user-select: none;
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: getStyleForProp('anchor')({ theme })
}))`
  margin-left: 0.5rem;
`

export default {
  StyledContainer,
  Headings,
  Title,
  Description,
  FeaturesContainer,
  FetaureItem,
  FeatureItemHeading,
  FeatureIconTextTitle,
  Circle,
  Paragraph,
  IconWrap,
  Icon
}
