/* @flow */
import React from 'react'
import * as Styled from './SuccessContent.styled'
import SuccessSvg from '@website/common/assets/saved-icon.svg'

const SuccessContent = () => {
  return (
    <Styled.SuccessContnetWrapper>
      <Styled.Image src={SuccessSvg} alt="Respon's image" />
      <Styled.Title>Information has been successfully sent</Styled.Title>
    </Styled.SuccessContnetWrapper>
  )
}

export default SuccessContent
