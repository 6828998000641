/* @flow */
import React, { useCallback, useContext } from 'react'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import { DispatchContext } from '@contexts'
import S from './AdditionalLink.styled'
import ActionGetter from './Actions'
import { translate } from '@editor/common/utils/translations'

const AdditionalLink = ({ additionalLinkText, additionalLinkUrl, idx }) => {
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  const _addLink = useCallback(() => {
    Actions.addLink(idx)
  }, [idx])

  return (
    <If
      condition={additionalLinkText && additionalLinkUrl}
      otherwise={() => (
        <AddButton
          onAdd={_addLink}
          toShow={additionalLinkText === ''}
          style={{ textAlign: 'start', marginTop: '30px' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_link_label')}
        </AddButton>
      )}
      then={() => (
        <S.LinkSection className="link-section">
          <ControlsWithModalOpener
            actions={Actions.linkActions({
              isAdditionalLink: true,
              linkIdx: idx,
              linkData: {
                text: additionalLinkText,
                url: additionalLinkUrl
              }
            })}
          >
            <LinkWrapper
              url={additionalLinkUrl}
              text={additionalLinkText}
              linkProps={{
                className: 'WM_GLOBAL_secondary-font learn-more-link'
              }}
            >
              <S.Icon
                size="large"
                name="keyboard_arrow_right"
                className="arrow-right"
              />
            </LinkWrapper>
          </ControlsWithModalOpener>
        </S.LinkSection>
      )}
    />
  )
}

export default AdditionalLink
