//@flow
import React, { memo, useMemo } from 'react'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { KEYWORD_TABLE_TABS } from './consts'
import * as Styled from './styled'

const KeywordTabs = ({
  className = '',
  isHorizontalView,
  activeTab,
  onTabChange
}) => {
  const TabStyledComponent = useMemo(() => {
    return isHorizontalView ? Styled.HorizontalTab : Styled.VerticalTab
  }, [isHorizontalView])

  return (
    <Styled.TabsWrapper
      isHorizontalView={isHorizontalView}
      className={className}
    >
      {KEYWORD_TABLE_TABS.map(tab => (
        <TooltipWrapper
          text={
            <Styled.TooltipContent>{tab.tooltipText}</Styled.TooltipContent>
          }
          mobileTimer={6000}
        >
          <TabStyledComponent
            key={tab.label}
            onClick={() => {
              onTabChange(tab.key)
            }}
            isActive={tab.key === activeTab}
          >
            {tab.label}
          </TabStyledComponent>
        </TooltipWrapper>
      ))}
    </Styled.TabsWrapper>
  )
}

export default memo(KeywordTabs)
