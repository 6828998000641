/* @flow */
/* eslint-disable max-statements */
import React, { useContext, useState, useEffect, memo } from 'react'
import * as S from './Contact14.styled'
import ActionGetter from './Actions'
import Element from '@website/common/assets/svgr-icons/contactStar.svg'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import SubmitButton from '@website/common/components/SubmitButton/SubmitButton'

import {
  validation,
  EMPTY_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE
} from './Contact14.consts'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { removeEmptyFields } from '@website/common/utils'
import {
  ControlsWithModalOpener,
  Controls
} from '@website/common/components/Controlled/'
import { defaultPlaceholders } from './consts'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import { isValidEmail } from '@website/common/utils'

import {
  DispatchContext,
  EditingContext,
  SiteHashContext,
  SiteIdContext
} from '@contexts'
import { contactApi } from '@website/common/api'
import { translate } from '@editor/common/utils/translations'

const initialState = {
  name: '',
  email: '',
  message: ''
}

const Contact14 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttonText,
      placeholders,
      phone,
      email,
      address,
      addressAlignment,
      addressTitle,
      addressTitleAlignment,
      emailTitle,
      emailTitleAlignment,
      phoneTitle,
      phoneTitleAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const siteId = useContext(SiteIdContext)
  const siteHash = useContext(SiteHashContext)
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)

  const [inputData, setInputData] = useState(
    isEditing ? { ...placeholders } : initialState
  )
  const [timer, setTimer] = useState(null)

  const withoutTexts =
    !isEditing && !(title || paragraph || phone || email) ? true : false

  useEffect(() => {
    if (timer) {
      setTimer(null)
    }
  })

  useEffect(() => {
    if (isEditing) {
      setInputData({ ...placeholders })
    }
  }, [placeholders])

  const addInfoButton = (key: 'phone' | 'email') => (
    <AddButton
      onAdd={() => Actions.addInfo(key)}
      isText
      toShow
      style={{
        marginTop: '10px'
      }}
    >
      <Icon name="glyph-add" className="icon" size="normal" />
      {`Add ${key}`}
    </AddButton>
  )

  const handleEmailError = () => {
    if (!inputData.email) {
      setEmailErrorMessage(EMPTY_FIELD_MESSAGE)
    }
    if (inputData.email && !isValidEmail(inputData.email)) {
      setEmailErrorMessage(INVALID_EMAIL_MESSAGE)
    }
  }

  const handleNameError = () => {
    if (!inputData.name) {
      setNameErrorMessage(EMPTY_FIELD_MESSAGE)
    }
  }

  const evtHandlerGetter = (type: string) => {
    const evtHandlers = {
      onChange: (e: Event) => {
        setInputData({ ...inputData, [type]: e.target.value })
        if (type === 'name') {
          setNameErrorMessage('')
        }
        if (type === 'email') {
          setEmailErrorMessage('')
        }
      }
    }
    if (isEditing) {
      evtHandlers.onBlur = (e: Event) => {
        const value = e.target.value || defaultPlaceholders[type]
        if (value === placeholders[type]) {
          return
        }
        Actions.changePlaceHolderText(type, value)
        setInputData({ ...inputData, [type]: value })
      }
    } else {
      evtHandlers.onBlur = () => {
        if (type === 'name') {
          handleNameError()
        }
        if (type === 'email') {
          handleEmailError()
        }
      }
    }
    return evtHandlers
  }

  const sendData = () => {
    if (isEditing) {
      return
    }
    handleEmailError()
    handleNameError()
    if (inputData.email && isValidEmail(inputData.email) && inputData.name) {
      contactApi(
        siteId,
        removeEmptyFields({
          ...inputData,
          siteHash
        })
      )
        .then(() => {
          setIsSuccess(true)
          setTimer(
            setTimeout(() => {
              setInputData(initialState)
              setIsSuccess(false)
            }, 4000)
          )
        })
        .catch(console.warn)
    }
  }

  const getPlaceHolderText = key => (placeholders && placeholders[key]) || ''

  const anchorInfo = (
    key: 'phone' | 'email',
    isEditing: boolean,
    alignmentKey
  ) => {
    const { [key]: text } = props.data
    const alignment = props.data[alignmentKey]
    const InfoWrapper = isEditing ? S.EmailWrap : S.EmailWrapA
    const hrefLinks = {
      phone: 'tel',
      email: 'mailto'
    }
    return (
      <InfoWrapper href={hrefLinks[key] + ':' + text} className="info">
        <EditableContent
          text={text}
          as={S.Phone}
          alignment={alignment}
          isEditing={isEditing}
          maxCount={validation.contactMaxChar}
          onChange={(info: string) => {
            Actions.infoChangeAction(key, info)
          }}
          className="WM_GLOBAL_paragraph18"
          changeAlignment={alignment => {
            Actions.changeContactAlignment(alignment, alignmentKey)
          }}
        />
      </InfoWrapper>
    )
  }

  return (
    <S.Container
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WMCustomContainer>
        <EditableContent
          text={title}
          as={S.Title}
          required={true}
          alignment={titleAlignment}
          maxCount={validation.headingMaxChar}
          onChange={Actions.changeTitle}
          className="WM_GLOBAL_heading42"
          changeAlignment={Actions.changeTitleAlignment}
        />
        <S.ContactMain emptyContent={[paragraph, phone, email, address]}>
          <Show
            when={[
              paragraph,
              phone,
              phoneTitle,
              email,
              emailTitle,
              address,
              addressTitle
            ]}
          >
            <S.Content isEditing={isEditing}>
              <Show when={[emailTitle]}>
                <EditableContent
                  text={emailTitle}
                  as={S.BlockTitle}
                  alignment={emailTitleAlignment}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeEmailTitle}
                  className="WM_GLOBAL_heading20"
                  changeAlignment={Actions.changeEmailTitleAlignment}
                />
              </Show>
              <If
                condition={email}
                otherwise={() => addInfoButton('email')}
                then={() => anchorInfo('email', isEditing, 'emailAlignment')}
              />
              <Show when={[phoneTitle]}>
                <EditableContent
                  text={phoneTitle}
                  as={S.BlockTitle}
                  alignment={phoneTitleAlignment}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changePhoneTitle}
                  className="WM_GLOBAL_heading20"
                  changeAlignment={Actions.changePhoneTitleAlignment}
                />
              </Show>
              <If
                condition={phone}
                otherwise={() => addInfoButton('phone')}
                then={() => anchorInfo('phone', isEditing, 'phoneAlignment')}
              />
              <Show when={[addressTitle]}>
                <EditableContent
                  text={addressTitle}
                  as={S.BlockTitle}
                  alignment={addressTitleAlignment}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeAddressTitle}
                  className="WM_GLOBAL_heading20"
                  changeAlignment={Actions.changeAddressTitleAlignment}
                />
              </Show>
              <Show when={[address]}>
                <EditableContent
                  text={address}
                  as={S.Address}
                  alignment={addressAlignment}
                  maxCount={validation.contactMaxChar}
                  onChange={Actions.changeAddress}
                  className="WM_GLOBAL_paragraph18"
                  changeAlignment={Actions.changeAddressAlignment}
                />
              </Show>
              <Show when={[paragraph]}>
                <S.Divider isEditing={isEditing}>
                  <Element />
                  <EditableContent
                    text={paragraph}
                    as={S.Paragraph}
                    alignment={paragraphAlignment}
                    maxCount={validation.paragraphMaxChar}
                    onChange={Actions.changeParagraph}
                    className="WM_GLOBAL_paragraph18"
                    changeAlignment={Actions.changeParagraphAlignment}
                  />
                </S.Divider>
              </Show>
            </S.Content>
          </Show>
          <If
            condition={buttonText}
            otherwise={() => (
              <If
                condition={isEditing}
                then={() => (
                  <S.AddFormContainer>
                    <AddButton onAdd={() => Actions.addForm()} toShow>
                      <Icon name="glyph-add" className="icon" size="normal" />
                      {translate('add_form_label')}
                    </AddButton>
                  </S.AddFormContainer>
                )}
              />
            )}
            then={() => (
              <S.FormContainer>
                <S.InputContainer className="name-input-container">
                  <S.RequiredIcon
                    name="icon-asterisk"
                    className="required-icon"
                  />
                  <S.Input
                    value={inputData.name}
                    placeholder={isEditing ? '' : getPlaceHolderText('name')}
                    isEditing={isEditing}
                    aria-label="Name input"
                    className="WM_GLOBAL_secondary-font name-input"
                    {...evtHandlerGetter('name')}
                    isError={nameErrorMessage}
                  />
                  {nameErrorMessage && (
                    <S.ErrorMessage>
                      <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                      {nameErrorMessage}
                    </S.ErrorMessage>
                  )}
                </S.InputContainer>
                <S.InputContainer>
                  <S.RequiredIcon
                    name="icon-asterisk"
                    className="required-icon"
                  />
                  <S.Input
                    value={inputData.email}
                    placeholder={isEditing ? '' : getPlaceHolderText('email')}
                    isEditing={isEditing}
                    aria-label="Email input"
                    className="WM_GLOBAL_secondary-font"
                    {...evtHandlerGetter('email')}
                    isError={emailErrorMessage}
                  />
                  {emailErrorMessage && (
                    <S.ErrorMessage>
                      <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                      {emailErrorMessage}
                    </S.ErrorMessage>
                  )}
                </S.InputContainer>
                <S.TextArea
                  rows={8}
                  value={inputData.message}
                  isEditing={isEditing}
                  placeholder={isEditing ? '' : getPlaceHolderText('message')}
                  data-gramm_editor="false" // for grammarly extension
                  aria-label="Message input"
                  className="WM_GLOBAL_secondary-font"
                  {...evtHandlerGetter('message')}
                />
                <SubmitButton
                  buttonText={buttonText}
                  submitFormData={sendData}
                  showSubmitNotification={isSuccess}
                />
              </S.FormContainer>
            )}
          />
        </S.ContactMain>
      </S.WMCustomContainer>
    </S.Container>
  )
})

export default Contact14
