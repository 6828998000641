// @flow

import { AVATAR_DEFAULT_SRC } from '../consts'

const MESSENGER_BASE_URLS = {
  facebook: 'https://',
  whatsapp: 'https://api.whatsapp.com/send?phone=',
  telegram: 'https://t.me/',
  viber: 'viber://chat?number='
}

const MESSENGER_FORMATTERS = {
  facebook: (value: string = '') => value.replace(/^(https?:\/\/)?/, ''),
  telegram: (value: string = '') => value.replace(/^@?/, ''),
  viber: (value: string = '') => value.replace('-', '').replace('+', '%2B'),
  whatsapp: (value: string = '') => value.replace('-', '').replace('+', '%2B')
}

export const getButtonUrl = (type: string, value: string = '') => {
  const formatter = MESSENGER_FORMATTERS[type]
  const formattedValue = formatter ? formatter(value) : value
  return `${MESSENGER_BASE_URLS[type]}${formattedValue}`
}

export const getAvatarProps = (url, optimizedUrl) => {
  if (!url) {
    return {
      url: AVATAR_DEFAULT_SRC,
      dimensions: {
        small: AVATAR_DEFAULT_SRC,
        medium: AVATAR_DEFAULT_SRC,
        large: AVATAR_DEFAULT_SRC,
        xlarge: AVATAR_DEFAULT_SRC
      }
    }
  }

  return optimizedUrl
    ? {
        url,
        dimensions: {
          small: optimizedUrl,
          medium: optimizedUrl,
          large: optimizedUrl,
          xlarge: optimizedUrl
        }
      }
    : {
        url
      }
}
