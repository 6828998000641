/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header40.styled'
import THEME from './Header40.theme'
import { validation } from './Header40.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import Socials from '@website/common/components/Socials'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import Buttons from '@website/common/components/Buttons'

const Header40 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      buttons,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency,
      socials
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      topOffset={navBarHeight}
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        isEditing={isEditing}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          alignment: 'right',
          additionalContent: () => (
            <Socials
              className="header-socials"
              data={socials}
              componentDispatcher={dispatcher}
            />
          )
        }}
      />
      <S.WmCustomContainer>
        <Show when={[title, ...buttons]}>
          <S.HeaderContentContainer>
            <S.HeaderContent>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                onlyTitle={!isEditing && buttons.length === 0}
                required={validation.title.required}
                maxCount={validation.titleMaxChar}
                isEditing={isEditing}
                className="WM_GLOBAL_heading72"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <Buttons data={buttons} />
            </S.HeaderContent>
          </S.HeaderContentContainer>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header40
