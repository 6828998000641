//@flow

import React, { createContext, useState } from 'react'
import { translate } from '@editor/common/utils/translations'

export const buttonTexts = {
  cancel: translate('cancel_label'),
  confirm: translate('discard_label', true)
}

export const title = translate('are_you_sure_discard_changes_label')
export const content = translate('unsaved_changes_will_be_lost_label')
export const confirmDialogInitialProps = {
  isOpen: false,
  isWarning: false,
  onConfirm: null,
  onCancel: null,
  title,
  content,
  buttonTexts,
  description: ''
}

export const ConfirmDialogContext = createContext()

export const ConfirmDialogProvider = ({ children }) => {
  const [props, _setProps] = useState(confirmDialogInitialProps)
  const [changedContentIds, setChangedContentIds] = useState([])

  const setProps = _props => {
    _setProps({
      ...props,
      ..._props
    })
  }

  return (
    <ConfirmDialogContext.Provider
      value={{
        props,
        setProps,
        changedContentIds,
        setChangedContentIds
      }}
    >
      {children}
    </ConfirmDialogContext.Provider>
  )
}
