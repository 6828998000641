/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockParagraphAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_ITEM_PARAGRAPH':
        const { newItemParagraph, idx } = action.value
        draft.blocks[idx].paragraph = newItemParagraph
        break
      case 'CHANGE_ITEM_TITLE':
        const { newBlockTitle, idx: idx_ } = action.value
        draft.blocks[idx_].blockTitle = newBlockTitle
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.blocks[idx].imgUrl = url
        draft.blocks[idx].imgDimensions = dimensions || {}
        draft.blocks[idx].imgCropParams = {}
        break
      }
      case 'ADD_BLOCK':
        const newblocksBlock = {
          ...initialState.blocks[0]
        }
        draft.blocks.push(newblocksBlock)
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'REMOVE_BLOCK':
        draft.blocks.splice(action.value.idx, 1)
        break
      case 'CHANGE_BUTTON_DATA':
        const { text, link } = action.value.btnData
        draft.buttonUrl = link
        draft.buttonText = text
        break
      case 'REMOVE_BOTTOM_BLOCK':
        draft.ctaText = ''
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BOTTOM_BLOCK':
        draft.ctaText = initialState.ctaText
        draft.buttonUrl = initialState.buttonUrl
        draft.buttonText = initialState.buttonText
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.blocks[idx].imgCropParams = imgCropParams
      }
    }
  })
}
