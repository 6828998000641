/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature10.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
        `
      : css``};
  @media only screen and (max-width: 700px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding-top: 70px;
          `
        : css``};
  }
`

const FeatureItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -30px 0;
`

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 30px;
  width: 220px;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;
  > p:first-child,
  > h5:first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h2,
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const IconWrap = styled.article``

const Count = styled.p`
  color: ${getStyleForProp('iconText')};
  ${getTextStyleByAlignment};
  font-weight: 800;
  width: 100%;
  margin-top: 20px;
`

const Title = styled.h2`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  font-size: 16px;
  width: 100%;
  margin-bottom: 0;
  margin-top: 20px;
`

const Circle = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getStyleForProp('iconText')};
`

const IconWrapper = styled.div`
  user-select: none;
`
const Icon = styled(LibIcon).attrs(props => ({
  color: getStyleForProp('icon')(props)
}))``

const Line = styled.div`
  width: 24px;
  height: 1px;
  background-color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
`

export default {
  StyledContainer,
  FeatureItemWrap,
  Title,
  Count,
  ItemWrap,
  IconWrapper,
  IconWrap,
  Circle,
  Line,
  Icon
}
