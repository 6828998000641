// @flow

import React, { useEffect, useState, memo } from 'react'
import { useParams } from 'react-router-dom'
import ProjectLoader from '@editor/common/components/ProjectLoader'
import Page from '../Page'

import { LinkWrapperContext } from '@contexts'
import { fetchTemplateById_API } from '@api'
import { PREVIEW_BASE } from '@editor/conf/routes'

const TemplatePreview = () => {
  const { templateId } = useParams()
  const initialData = window.parent.projectData || window.projectData
  const initialSiteHash = window.parent.siteHash || window.siteHash

  const [projectData, setProjectData] = useState(initialData)
  const [siteHash, setSiteHash] = useState(initialSiteHash)
  const [isLoading, setLoadingState] = useState(false)

  useEffect(() => {
    if (!isLoading && !projectData) {
      setLoadingState(true)

      fetchTemplateById_API(templateId)
        .then(projectResponse => {
          const {
            data: { data: projectData, hash: siteHash, visualParams, events }
          } = projectResponse

          setSiteHash(siteHash)
          setProjectData({ data: { ...projectData, visualParams }, events })
        })
        .catch(console.warn)
        .finally(() => {
          setLoadingState(false)
        })
    }
  }, [])

  if (isLoading || !projectData) {
    return <ProjectLoader />
  }

  return (
    <LinkWrapperContext.Provider
      value={{ routeBase: `${PREVIEW_BASE}/template/:templateId` }}
    >
      <Page projectData={projectData} siteHash={siteHash} />
    </LinkWrapperContext.Provider>
  )
}

export default memo(TemplatePreview)
