export default {
  DARK: {
    dotsBackground: p => p.accentWhite,
    time: p => p.accentWhite,
    date: p => p.accentWhite,
    background: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    line: p => p.tertiaryNormal,
    heading: p => p.accentWhite
  },
  LIGHT: {
    dotsBackground: p => p.accentWhite,
    time: p => p.primaryDark,
    date: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    backgroundColor: p => p.tertiaryLight,
    line: p => p.tertiarySemiLight,
    heading: p => p.primaryDark
  },
  EXTRALIGHT: {
    dotsBackground: p => p.accentWhite,
    time: p => p.tertiaryDark,
    date: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    line: p => p.tertiarySemiLight,
    heading: p => p.tertiaryDark
  }
}
