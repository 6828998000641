/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import { changeTitle, changeParagraph } from '@website/components/CommonActions'

export default dispatcher => {
  const changePlaceHolderText = (key: string, value: string) => {
    dispatcher({
      type: 'CHANGE_PLACEHOLDER_TEXT',
      value: { key, value }
    })
  }

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const changeTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraphAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }
  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changePlaceHolderText,
    changeTitleAlignment,
    changeParagraphAlignment,
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'email4',
        showRemove: false,
        imageUrl
      })
  }
}
