/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeSubTitle
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeBlockParagraph = (newParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newParagraph, idx }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeSubtitle = (subtitle: string) => {
    dispatcher(changeSubTitle(subtitle))
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const changeTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeSubtitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: _addBlock,
    changeTitle: _changeTitle,
    changeSubtitle: _changeSubtitle,
    changeBlockTitle,
    changeBlockParagraph,
    changeTitleAlignment,
    changeSubtitleAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
