/* @flow */
import React, { useContext, useState, useCallback, useRef, memo } from 'react'
import * as S from './Header46.styled'
import THEME from './Header46.theme'
import { validation } from './Header46.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext, MobileDetectContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { slickSliderSettings } from './Header46.consts'
import NavBar from '@website/common/components/NavBar'
import Socials from '@website/common/components/Socials'
import Slider from 'react-slick'
import Buttons from '@website/common/components/Buttons'
import SliderBackgroundControls from '@editor/common/components/SliderBackgroundControls'
import { translate } from '@editor/common/utils/translations'

const Header46 = memo(
  ({
    data: { slides, logoSrc, logoColor, logoWidth, logoDimensions, socials },
    componentId
  }) => {
    const [activeSlideIdx, setActiveSlideIdx] = useState(0)
    const [navBarHeight, setNavBarHeight] = useState(0)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const isMobile = useContext(MobileDetectContext)
    const slickSliderRef = useRef(null)

    const onPrevClick = useCallback(() => {
      slickSliderRef.current.slickPrev()
    }, [slickSliderRef.current])

    const onNextClick = useCallback(() => {
      slickSliderRef.current.slickNext()
    }, [slickSliderRef.current])

    const onSelectSlide = useCallback(
      idx => {
        slickSliderRef.current.slickGoTo(idx)
      },
      [slickSliderRef.current]
    )

    return (
      <S.Container isEditing={isEditing}>
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
          linksProps={{
            additionalContent:
              isEditing || socials.length !== 0
                ? () => (
                    <Socials
                      className="header-socials"
                      data={socials}
                      componentDispatcher={dispatcher}
                    />
                  )
                : null
          }}
        />
        <S.WmCustomContainer>
          <If
            condition={isEditing}
            then={() => (
              <SliderBackgroundControls
                slides={slides}
                activeSlideIdx={activeSlideIdx}
                slideCount={slides.length}
                componentId={componentId}
                onLeftChange={onPrevClick}
                onRightChange={onNextClick}
                onSlideChange={idx => onSelectSlide(idx)}
                onAddSlide={() => Actions.addSlide(activeSlideIdx)}
                minSlidesCount={validation.slides.min}
                defaultTransparencyValue={0.8}
                renderingContainer={document.querySelector('.Header46')}
              />
            )}
          />
          <S.SlidesCountWrap isEditing={isEditing} topOffset={navBarHeight}>
            <S.ActiveSlideNumber className="WM_GLOBAL_heading54">
              0{activeSlideIdx + 1}
            </S.ActiveSlideNumber>
            <S.Divider>/</S.Divider>
            <S.SlidesNumber className="WM_GLOBAL_paragraph18">
              0{slides.length}
            </S.SlidesNumber>
          </S.SlidesCountWrap>
          <Slider
            {...slickSliderSettings}
            swipeToSlide={!isEditing}
            swipe={!isEditing}
            accessibility={!isEditing}
            className="slider mySlider"
            ref={slickSliderRef}
            beforeChange={(_, newIdx) => {
              setActiveSlideIdx(newIdx)
            }}
          >
            {slides.map((slide, idx) => (
              <S.SliderWrapper
                isEditing={isEditing}
                backgroundImgUrl={slide.backgroundImgUrl}
                backgroundImgDimensions={slide.backgroundImgDimensions}
                bgOverlayTransparency={slide.bgOverlayTransparency}
              >
                <S.Info
                  key={idx}
                  topOffset={navBarHeight}
                  isEditing={isEditing}
                  isActive={idx === activeSlideIdx}
                >
                  <EditableContent
                    text={slide.title}
                    as={S.Title}
                    alignment={slide.slideTitleAlignment}
                    className="WM_GLOBAL_heading54"
                    required={!slide.paragraph}
                    maxCount={validation.titleMaxChar}
                    onChange={(newTitle: string) => {
                      Actions.changeSliderTitle(newTitle, idx)
                    }}
                    changeAlignment={(alignment: string) =>
                      Actions.changeSliderTitleAlignment(alignment, idx)
                    }
                  />
                  <EditableContent
                    text={slide.paragraph}
                    as={S.Paragraph}
                    alignment={slide.slideParagraphAlignment}
                    required={!slide.title}
                    className="WM_GLOBAL_paragraph18"
                    maxCount={validation.paragraphMaxChar}
                    onChange={(newParagraph: string) => {
                      Actions.changeSliderParagraph(newParagraph, idx)
                    }}
                    changeAlignment={(alignment: string) =>
                      Actions.changeSliderParagraphAlignment(alignment, idx)
                    }
                  />
                  <Buttons data={slide.buttons} additionalProps={{ idx }} />
                </S.Info>
              </S.SliderWrapper>
            ))}
          </Slider>
          <S.ArrrowIconsWrap>
            <S.IconContainer className="left" onClick={onPrevClick}>
              <S.ArrowIcon size="large" name="keyboard_arrow_left" isLeft />
            </S.IconContainer>
            <S.IconContainer className="right" onClick={onNextClick}>
              <S.ArrowIcon size="large" name="keyboard_arrow_right" />
            </S.IconContainer>
          </S.ArrrowIconsWrap>
          <S.ActionsWrapper>
            <S.DotsWrap>
              {slides.map((_, idx) => (
                <S.Dot
                  key={idx}
                  className={idx}
                  isActive={idx === activeSlideIdx}
                  onClick={() => onSelectSlide(idx)}
                />
              ))}
            </S.DotsWrap>
            <AddButton
              onAdd={() => Actions.addSlide(activeSlideIdx)}
              toShow={!isMobile && slides.length < validation.slides.max}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_slide_label')}
            </AddButton>
            <AddButton
              onAdd={() => {
                Actions.removeSlide(activeSlideIdx)
              }}
              toShow={!isMobile && slides.length > validation.slides.min}
            >
              <Icon name="glyph-remove" className="icon" size="normal" />
              {translate('remove_slide_label')}
            </AddButton>
          </S.ActionsWrapper>
        </S.WmCustomContainer>
      </S.Container>
    )
  }
)

export default Header46
