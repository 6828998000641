import styled, { css } from 'styled-components'

const getOffsetStyles = ({ offsets: { top, left } }) => css`
  top: ${top}px;
  left: ${left}px;
`

export const FlyOutWrapper = styled.div`
  position: absolute;
  height: auto;
  padding-top: 10px;
  box-sizing: border-box;
  z-index: 49;
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  ${getOffsetStyles};
`
