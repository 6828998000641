/* @flow */
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'

import { navLinkActionMaker } from '@website/components/ActionMakers'
import { undoable } from '@editor/common/utils'
import {
  removeNavigationLink,
  editNavigationLink
} from '@website/components/CommonActions'

export default (dispatcher, setModalState) => {
  return {
    addLink: () =>
      undoable({
        type: 'ADD_HEADER_LINK',
        value: { text: 'New link', link: '#' }
      }),
    linkActions: (...args) => navLinkActionMaker(dispatcher)(...args, 'header'),
    dropDownLinkActions: {
      edit: (idx, { link, text }) =>
        setModalState(MODAL_TYPES.extendedUrlModal, {
          link,
          text,
          limitText: true,
          isOpen: true,
          type: LINK_TYPES.link,
          handler: data => dispatcher(editNavigationLink(idx, data, 'header'))
        }),
      remove: idx => dispatcher(removeNavigationLink(idx, 'header'))
    },
    setLinksLabelValue: value => ({
      type: 'SET_EXTRA_LINKS_LABEL',
      value
    })
  }
}
