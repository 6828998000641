/* @flow */
import React, { useContext, memo } from 'react'
import * as S from './Portfolio14.styled'
import ActionGetter from './Actions'
import { validation } from './Portfolio14.consts'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'
import PaintLine from '@website/common/assets/svgr-icons/paintLine.svg'
import PaintLine2 from '@website/common/assets/svgr-icons/paintLine2.svg'

const Portfolio14 = memo(
  ({
    data: {
      title,
      titleAlignment,
      blocks,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const { isEditing } = useContext(EditingContext)
    const Actions = ActionGetter(dispatcher)
    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer>
          <S.HeadingWrap>
            <S.HeadingParagraph>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading42"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </S.HeadingParagraph>
          </S.HeadingWrap>
          <S.BlocksWrap isFourBlock={blocks.length === 4}>
            <Mapper
              data={blocks}
              render={(item: string, idx: number) => {
                return (
                  <S.BlockItemWrap
                    key={idx}
                    isEditing={isEditing}
                    isFourBlock={blocks.length === 4}
                  >
                    <PaintLine className="paintLine" />
                    <PaintLine2 className="paintLine2" />
                    <ControlsWithModalOpener
                      actions={Actions.featureActions({
                        idx,
                        showRemove: blocks.length !== validation.blocks.min
                      })}
                    >
                      <S.BlockImgWrap>
                        <S.ControlContainer>
                          <ControlsWithImageCropper
                            actions={Actions.imageActions(
                              idx,
                              blocks.length,
                              item.imgUrl
                            )}
                          >
                            <Image
                              as={S.Img}
                              alt="item img"
                              sizes={item.imgDimensions}
                              defaultImgSrc={item.imgUrl}
                              asProps={{ imgCropParams: item.imgCropParams }}
                            />
                          </ControlsWithImageCropper>
                        </S.ControlContainer>
                      </S.BlockImgWrap>
                      <S.BlockContentWrap>
                        <EditableContent
                          text={item.blockTitle}
                          as={S.BlockTitle}
                          required={!item.paragraph}
                          alignment={item.blockTitleAlignment}
                          maxCount={validation.blockTitleMaxChar}
                          className="WM_GLOBAL_heading20"
                          onChange={(newBlockTitle: string) =>
                            Actions.changeBlockTitle(newBlockTitle, idx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(alignment, idx)
                          }
                        />
                        <EditableContent
                          text={item.paragraph}
                          as={S.BlockParagraph}
                          required={!item.blockTitle}
                          alignment={item.blockParagraphAlignment}
                          maxCount={validation.blockParagraphMaxChar}
                          className="WM_GLOBAL_paragraph18"
                          onChange={(newItemParagraph: string) =>
                            Actions.changeItemParagraph(newItemParagraph, idx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                      </S.BlockContentWrap>
                    </ControlsWithModalOpener>
                  </S.BlockItemWrap>
                )
              }}
            />
          </S.BlocksWrap>
          <AddButton
            onAdd={Actions.addBlock}
            toShow={blocks.length < validation.blocks.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Portfolio14
