import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    blockTitle: p => p.accentWhite,
    blockParagraph: p => p.accentWhite,
    additionalBlockParagraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    cardBackground: p => p.accentWhite,
    border: p => p.accentWhite,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.8)`,
    firstColor: p => p.secondaryDark,
    secondColor: p => p.primaryLight,
    thirdColor: p => p.tertiarySemiLight,
    fourthColor: p => p.accentWhite,
    fifthColor: p => p.secondaryLight
  },
  LIGHT: {
    heading: p => p.primaryDark,
    blockTitle: p => p.primaryDark,
    blockParagraph: p => p.tertiaryDark,
    additionalBlockParagraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    cardBackground: p => p.accentWhite,
    border: p => p.tertiaryDark,
    firstColor: p => p.secondaryDark,
    secondColor: p => p.primaryLight,
    thirdColor: p => p.primaryDark,
    fourthColor: p => p.tertiaryDark,
    fifthColor: p => p.secondaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.8)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.tertiaryDark,
    additionalBlockParagraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    cardBackground: p => p.tertiaryLight,
    border: p => p.tertiaryDark,
    firstColor: p => p.secondaryDark,
    secondColor: p => p.primaryLight,
    thirdColor: p => p.primaryDark,
    fourthColor: p => p.tertiaryDark,
    fifthColor: p => p.secondaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.9)`
  }
}
