/* @flow */
import {
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

export default dispatcher => {
  const imageActions = (idx: number, imageUrl: string, mediaFlyoutShift) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'header29',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl,
      mediaFlyoutShift
    })
  ]

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  return {
    imageActions,
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment
  }
}
