import { createSelector } from 'reselect'
import { TRANSLATING } from '@editor/conf/consts'

export const selectWebsiteLanguages = state => state.websiteLanguages

export const selectWebsiteLanguagesData = createSelector(
  selectWebsiteLanguages,
  state => [...state?.data].sort((a, b) => a.order - b.order)
)

export const selectIsWebsiteLanguagesDataLoaded = createSelector(
  selectWebsiteLanguages,
  state => state?.isLoaded
)

export const selectIsWebsiteMultilingual = createSelector(
  selectWebsiteLanguagesData,
  data => data.length > 1
)

export const selectIsSuccessPopupOpen = createSelector(
  selectWebsiteLanguages,
  state => state?.isSuccessPopupOpen
)

export const selectLanguageMenuLayout = createSelector(
  selectWebsiteLanguages,
  state => state.languageMenuLayout
)

export const selectWasWebsiteMultilingual = createSelector(
  selectWebsiteLanguagesData,
  data => data.length > 0
)

export const selectLanguageTranslationState = createSelector(
  selectWebsiteLanguages,
  (_, languageCode) => languageCode,
  (websiteLanguages, languageCode) =>
    websiteLanguages.data.find(language => language.lang.code === languageCode)
      ?.state
)

export const selectIsAnyLanguageTranslating = createSelector(
  selectWebsiteLanguages,
  websiteLanguages =>
    websiteLanguages.data
      .filter(language => language?.state)
      .some(language => language.state === TRANSLATING)
)
