/* @flow */
/* eslint-disable max-statements */

import React, { useContext, useState, useEffect, memo } from 'react'
import S from './Email4.styled'
import {
  validation,
  EMPTY_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  SUCCESS_MESSAGE
} from './Email4.consts'
import ActionGetter from './Actions'
import EditableContent from '@website/common/components/EditableContent'
import Image from '@website/common/components/Image'
import { isValidEmail } from '@website/common/utils'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import SubmitButton from '@website/common/components/SubmitButton/SubmitButton'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SuccessIcon from '@website/common/assets/success_icon_green.svg'

import {
  DispatchContext,
  EditingContext,
  SiteHashContext,
  SiteIdContext
} from '@contexts'
import { subscribeAPI } from '@website/common/api'

const initialState = {
  name: '',
  email: ''
}

const Email4 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      placeholders,
      buttonText,
      imageUrl,
      imgDimensions,
      imgCropParams,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const { isEditing } = useContext(EditingContext)
    const siteId = useContext(SiteIdContext)
    const siteHash = useContext(SiteHashContext)
    const Actions = ActionGetter(dispatcher)
    const [timer, setTimer] = useState(null)
    const [nameErrorMessage, setNameErrorMessage] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const [isSuccess, setIsSuccess] = useState(false)

    const [inputData, setInputData] = useState(
      isEditing ? { ...placeholders } : initialState
    )

    useEffect(() => {
      if (timer) {
        setTimer(null)
      }
    })

    useEffect(() => {
      if (isEditing) {
        setInputData({ ...placeholders })
      }
    }, [placeholders])

    const handleEmailError = () => {
      if (!inputData.email) {
        setEmailErrorMessage(EMPTY_FIELD_MESSAGE)
      }
      if (inputData.email && !isValidEmail(inputData.email)) {
        setEmailErrorMessage(INVALID_EMAIL_MESSAGE)
      }
    }

    const handleNameError = () => {
      if (!inputData.name) {
        setNameErrorMessage(EMPTY_FIELD_MESSAGE)
      }
    }

    const evtHandlerGetter = (type: string) => {
      const evtHandlers = {
        onChange: (e: Event) => {
          setInputData({ ...inputData, [type]: e.target.value })
          if (type === 'name') {
            setNameErrorMessage('')
          }
          if (type === 'email') {
            setEmailErrorMessage('')
          }
        }
      }
      if (isEditing) {
        evtHandlers.onBlur = (e: Event) => {
          const value = e.target.value
          if (value === placeholders[type]) {
            return
          }
          Actions.changePlaceHolderText(type, value)
          setInputData({ ...inputData, [type]: value })
        }
      } else {
        evtHandlers.onBlur = () => {
          if (type === 'name') {
            handleNameError()
          }
          if (type === 'email') {
            handleEmailError()
          }
        }
      }
      return evtHandlers
    }

    const sendData = () => {
      if (isEditing) {
        return
      }
      handleNameError()
      handleEmailError()
      if (isValidEmail(inputData.email) && inputData.name) {
        subscribeAPI(siteId, {
          ...inputData,
          siteHash
        })
          .then(() => {
            setIsSuccess(true)
            setSuccessMessage(SUCCESS_MESSAGE)
            setTimer(
              setTimeout(() => {
                setInputData(initialState)
                setIsSuccess(false)
              }, 4000)
            )
          })
          .catch(console.warn)
      }
    }

    const getPlaceHolderText = key => (placeholders && placeholders[key]) || ''

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer isEditing={isEditing}>
          <S.FormImageWrapper>
            <S.RightContent>
              <S.FormContainer>
                <EditableContent
                  text={title}
                  as={S.Title}
                  required={!paragraph}
                  alignment={titleAlignment}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeTitle}
                  className="WM_GLOBAL_heading42"
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  required={!title}
                  maxCount={validation.paragraphMaxChar}
                  onChange={Actions.changeParagraph}
                  className="WM_GLOBAL_paragraph18"
                  alignment={paragraphAlignment}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
                <S.InputContainer>
                  <S.InputWrapper>
                    <S.RequiredIcon
                      name="icon-asterisk"
                      className="required-icon"
                    />
                    <S.Input
                      value={inputData.name}
                      aria-label="Name input"
                      className="WM_GLOBAL_secondary-font"
                      isEditing={isEditing}
                      isError={nameErrorMessage}
                      placeholder={isEditing ? '' : getPlaceHolderText('name')}
                      onBlur={!isEditing && handleNameError}
                      {...evtHandlerGetter('name')}
                    />
                    {nameErrorMessage && (
                      <S.ErrorMessage>
                        <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                        {nameErrorMessage}
                      </S.ErrorMessage>
                    )}
                  </S.InputWrapper>
                </S.InputContainer>
                <S.InputContainer>
                  <S.InputWrapper>
                    <S.RequiredIcon
                      name="icon-asterisk"
                      className="required-icon"
                    />
                    <S.Input
                      value={inputData.email}
                      aria-label="Email input"
                      placeholder={isEditing ? '' : getPlaceHolderText('email')}
                      isEditing={isEditing}
                      className="WM_GLOBAL_secondary-font"
                      isError={emailErrorMessage}
                      onBlur={!isEditing && handleEmailError}
                      {...evtHandlerGetter('email')}
                    />
                    {emailErrorMessage && (
                      <S.ErrorMessage>
                        <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                        {emailErrorMessage}
                      </S.ErrorMessage>
                    )}
                  </S.InputWrapper>
                </S.InputContainer>
                <SubmitButton
                  buttonText={buttonText}
                  submitFormData={sendData}
                  showSubmitNotification={false}
                />
                {isSuccess && (
                  <S.SuccessMessage className="success-message">
                    {' '}
                    <S.SuccessIcon src={SuccessIcon} alt="Success icon" />
                    {successMessage}
                  </S.SuccessMessage>
                )}
              </S.FormContainer>
            </S.RightContent>
            <S.ImageWrapper isEditing={isEditing}>
              <S.ControlContainer>
                <ControlsWithImageCropper
                  actions={Actions.getImgActions(imageUrl)}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    sizes={imgDimensions}
                    defaultImgSrc={imageUrl}
                    asProps={{
                      className: 'feature-image',
                      imgCropParams
                    }}
                    alt="Feature illustration"
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.ImageWrapper>
          </S.FormImageWrapper>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Email4
