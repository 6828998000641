/* @flow */

import {
  changeTitle,
  changeParagraph,
  // changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  // const _changeTitleAlignment = alignment => {
  //   dispatcher(changeTitleAlignment(alignment))
  // }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    // changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment
  }
}
