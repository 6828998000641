/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeBlockParagraph = (newParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newParagraph, idx }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  return {
    addBlock: _addBlock,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle,
    changeBlockParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
