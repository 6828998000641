/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Feature24.styled'
import { validation } from './Feature24.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled/'
import LinkWrapper from '@website/common/components/LinkWrapper'
import If from '@website/common/components/Conditional'
import { translate } from '@editor/common/utils/translations'

const Feature24 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      blocks,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const { isEditing } = useContext(EditingContext)
    const [hoveredBlockIdx, setHoveredBlockIdx] = useState(null)
    const Actions = ActionGetter(dispatcher)

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <WMContainer>
          <Show when={[title, paragraph]}>
            <S.Headings>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.headingMaxChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading42"
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                className="WM_GLOBAL_paragraph18"
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </S.Headings>
          </Show>
          <S.BlocksContainer
            isContainerWidthSmaller={
              blocks.length === 5 || blocks.length === 6 || blocks.length === 9
            }
          >
            <Mapper
              data={blocks}
              render={(block, idx) => (
                <Show
                  key={idx}
                  when={[
                    block.title,
                    block.paragraph,
                    block.additionalUrl,
                    block.additionalText
                  ]}
                >
                  <S.ControlContainer>
                    <ControlsWithModalOpener
                      actions={Actions.featureActions({
                        idx,
                        showRemove: blocks.length > validation.blocks.min
                      })}
                      alignment="right"
                    >
                      <S.Block
                        isEditing={isEditing}
                        isHover={hoveredBlockIdx === idx}
                        isLinkExists={
                          block.additionalText && block.additionalUrl
                        }
                      >
                        <S.IconWrap>
                          <ControlsWithModalOpener
                            actions={Actions.iconActions(idx)}
                          >
                            <S.Circle>
                              <Icon size="xlarge" name={block.icon} />
                            </S.Circle>
                          </ControlsWithModalOpener>
                        </S.IconWrap>
                        <EditableContent
                          text={block.title}
                          alignment={block.blockTitleAlignment}
                          as={S.BlockTitle}
                          className="WM_GLOBAL_heading20 blockText"
                          required={blocks.length === 3}
                          maxCount={validation.blockTitleMaxChar}
                          onChange={(newTitle: string) => {
                            Actions.changeBlockTitle(newTitle, idx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(alignment, idx)
                          }
                        />
                        <EditableContent
                          text={block.paragraph}
                          alignment={block.blockParagraphAlignment}
                          as={S.BlockParagraph}
                          maxCount={validation.blockParagraphMaxChar}
                          className="WM_GLOBAL_paragraph14 blockText"
                          onChange={(newParagraph: string) => {
                            Actions.changeBlockParagraph(newParagraph, idx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                        <If
                          condition={
                            block.additionalText && block.additionalUrl
                          }
                          otherwise={() => (
                            <AddButton
                              onAdd={() => Actions.addLink(idx)}
                              toShow={block.additionalText === ''}
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                              {translate('add_link_label')}
                            </AddButton>
                          )}
                          then={() => (
                            <Show
                              when={[
                                block.additionalText && block.additionalUrl
                              ]}
                            >
                              <S.AnchorContainer
                                isEditing={isEditing}
                                isHover={hoveredBlockIdx === idx}
                              >
                                <ControlsWithModalOpener
                                  actions={Actions.linkActions({
                                    isAdditionalLink: true,
                                    linkIdx: idx,
                                    linkData: {
                                      text: block.additionalText,
                                      url: block.additionalUrl
                                    }
                                  })}
                                >
                                  <LinkWrapper
                                    url={block.additionalUrl}
                                    text={block.additionalText}
                                    linkProps={{
                                      className:
                                        'WM_GLOBAL_secondary-font learn-more-link',
                                      onMouseOver: () => {
                                        setHoveredBlockIdx(idx)
                                      },
                                      onMouseLeave: () => {
                                        setHoveredBlockIdx(null)
                                      }
                                    }}
                                  >
                                    <Icon
                                      size="large"
                                      name="keyboard_arrow_right"
                                      className="arrow-right"
                                    />
                                  </LinkWrapper>
                                </ControlsWithModalOpener>
                              </S.AnchorContainer>
                            </Show>
                          )}
                        />
                      </S.Block>
                    </ControlsWithModalOpener>
                  </S.ControlContainer>
                </Show>
              )}
            />
          </S.BlocksContainer>
          <AddButton
            onAdd={Actions.addBlock}
            toShow={blocks.length !== validation.blocks.max}
            style={{ margin: '2rem 0' }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </WMContainer>
      </S.StyledContainer>
    )
  }
)

Feature24.contextType = DispatchContext

export default Feature24
