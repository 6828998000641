/* @flow */
import meta from '../Portfolio12.meta'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import { translate } from '@editor/common/utils/translations'
import LinkIcon from '@editor/common/assets/svgr-icons/Link_icon.svg'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'


export default dispatch => {
  const addRow = (idx: string) => ({
    type: 'ADD_ROW',
    value: idx
  })

  const removeRow = (idx: string) => ({
    type: 'REMOVE_ROW',
    value: idx
  })

  const changeBlockTitle = (
    newTitle: string,
    idx: number,
    itemIdx: number
  ) => ({
    type: 'CHANGE_BLOCK_TITLE',
    value: { newTitle, idx, itemIdx }
  })

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _addRow = idx => dispatch(addRow(idx))

  const _removeRow = idx => dispatch(removeRow(idx))

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const _changeBlockTitle = (newTitle, idx, itemIdx) => {
    dispatch(changeBlockTitle(newTitle, idx, itemIdx))
  }

  const changeBlockTitleAlignment = (alignment, idx, itemIdx) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx, itemIdx }
    })
  }

  const setImageLink = (imageLink, idx, itemIdx) => {
    dispatch({
      type: 'SET_IMAGE_LINK',
      value: {imageLink, idx, itemIdx}
    })
  }

  const imageActions = (idx: number, itemIdx: number, imageLink: string) => [
    {
      IconComp: LinkIcon,
      tooltipText: translate('add_link_label'),
      handler: link => {
        setImageLink(link, idx, itemIdx)
      },
      modal: {
        type: MODAL_TYPES.urlModal,
        props: {
          type: LINK_TYPES.link,
          link: imageLink
        }
      }
    },
    ...cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'portfolio12',
      showRemove: false,
      additionalArgs: { idx, itemIdx }
    })
  ]

  return {
    addRow: _addRow,
    removeRow: _removeRow,
    imageActions,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeBlockTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitle: _changeBlockTitle
  }
}
