// @flow
import React, { useCallback, useContext, memo } from 'react'

import type { TBackgroundControlsProps } from './types'
import BackgroundControlsDesktop from './BackgroundControlsDesktop'
import BackgroundControlsMobile from './BackgroundControlsMobile'
import {
  LINK_TYPES,
  MODAL_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import {
  MobileDetectContext,
  DispatchContext,
  SiteIdContext,
  ControlModalsContext
} from '@contexts'
import { changeBackgroundImage, changeBackgroundVideoAction } from './actions'
import ProjectActions from '@actions/project/project'
import { backgroundImgSizes } from '@editor/conf/imageSizes'

const BackgroundControls = ({
  componentId,
  componentUniqId,
  backgroundImage,
  backgroundVideo,
  bgOverlayTransparency,
  isBgImageAllowed,
  isBgVideoAllowed,
  isBgTransparencyAllowed,
  hasLimitedBackgrounds
}: TBackgroundControlsProps) => {
  const siteId = useContext(SiteIdContext)
  const isMobile = useContext(MobileDetectContext)
  const componentDispatcher = useContext(DispatchContext)
  const { setModalState } = useContext(ControlModalsContext)

  const hasBgImageOrVideo =
    !!backgroundImage || !!backgroundVideo || !!hasLimitedBackgrounds
  const optimizationDimensions =
    backgroundImgSizes[componentId] || backgroundImgSizes.default

  const onMediaRemove = useCallback(
    e => {
      e.stopPropagation()

      componentDispatcher({
        type: isBgVideoAllowed
          ? 'REMOVE_BACKGROUND_VIDEO'
          : 'REMOVE_BACKGROUND_IMAGE'
      })
    },
    [componentDispatcher]
  )

  const openImageFlyout = useCallback(
    additionalProps =>
      setModalState(MODAL_TYPES.mediaModal, {
        type: LINK_TYPES.image,
        link: backgroundImage,
        optimizationDimensions,
        handler: (url, dimensions) =>
          changeBackgroundImage(siteId, componentDispatcher)(url, dimensions),
        ...additionalProps
      }),
    [backgroundImage, siteId, setModalState, componentDispatcher]
  )

  const openVideoFlyout = useCallback(
    additionalProps =>
      setModalState(MODAL_TYPES.mediaModal, {
        type: LINK_TYPES.video,
        link: backgroundVideo,
        handler: url => componentDispatcher(changeBackgroundVideoAction(url)),
        ...additionalProps
      }),
    [backgroundVideo, setModalState, componentDispatcher]
  )

  const onRangeSliderChange = useCallback(
    transparency => {
      componentDispatcher(
        ProjectActions.changeBgOverlayTransparency(1 - transparency)
      )
    },
    [componentDispatcher]
  )

  const commonProps = {
    backgroundImage,
    backgroundVideo,
    bgOverlayTransparency,
    isBgImageAllowed,
    isBgVideoAllowed,
    isBgTransparencyAllowed,
    hasBgImageOrVideo,
    onMediaRemove,
    onRangeSliderChange,
    openImageFlyout,
    openVideoFlyout,
    hasLimitedBackgrounds
  }

  return isMobile ? (
    <BackgroundControlsMobile {...commonProps} />
  ) : (
    <BackgroundControlsDesktop
      componentUniqId={componentUniqId}
      {...commonProps}
    />
  )
}

export default memo(BackgroundControls)
