/* @flow */
import styled, { css } from 'styled-components'
import THEME from './CTA6.theme'
import {
  styleProvider,
  getBackground,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  padding: 80px 0 90px 0;
  flex-direction: ${getStyleForProp('orientation')};
  @media only screen and (max-width: 457px) {
    padding: 60px 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1280px;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      flex-direction: row-reverse;
    `}
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        flex-direction: column-reverse;
      `}
  }
  @media only screen and (min-width: 1025px) {
    transform: translateY(-80px);
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
  @media only screen and (max-width: 1024px) and (min-width: 768px) {
    margin-bottom: -100px;
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        margin-top: -100px;
        margin-bottom: 60px;
      `}
  }
  @media only screen and (max-width: 457px) {
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        margin-bottom: 60px;
      `}
  }
  @media only screen and (max-width: 500px) {
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        margin-bottom: 32px;
        margin-top: -10px;
      `}
  }
`

const ContentContainer = styled.div`
  width: 100%;
  max-width: 700px;
  @media only screen and (min-width: 1025px) {
    transform: translate(-35px, 140px);
    margin-left: 10px;
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        transform: translate(35px, 140px);
        margin-right: 10px;
        margin-left: initial;
      `}
  }
  z-index: 2;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
    transform: translate(0, -70px);
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        transform: translate(0, 70px);
        display: flex;
        flex-direction: column-reverse;
      `}
  }
  @media only screen and (max-width: 767px) {
    transform: initial;
  }
`

const CtaContent = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  background: ${({ theme }) => theme.palette.accentWhite};
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  padding: 40px 140px 40px 40px;
  @media only screen and (max-width: 767px) {
    padding: 50px 80px 40px 40px;
  }
  @media only screen and (max-width: 447px) {
    padding: 40px 80px 40px 20px;
  }
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      padding: 40px 40px 40px 140px;
      @media only screen and (max-width: 767px) {
        padding: 50px 50px 40px 80px;
      }
      @media only screen and (max-width: 400px) {
        padding: 40px 40px 40px 80px;
      }
    `}
  min-height: 340px;
  position: relative;
  @media only screen and (max-width: 1024px) {
    transform: translate(70px, 0);
    width: 90%;
    max-width: 502px;
    margin-right: auto;
    margin-left: auto;
  }
  @media only screen and (max-width: 767px) {
    transform: initial;
    width: 100%;
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        transform: translateY(30px);
      `}
  }
  @media only screen and (max-width: 500px) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: ${getStyleForProp('cardRadius')};
    border-bottom-left-radius: ${getStyleForProp('cardRadius')};
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        border-top-left-radius: ${getStyleForProp('cardRadius')};
        border-top-right-radius: ${getStyleForProp('cardRadius')};
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      `}
  }
  .buttons-block {
    margin-left: -15px;
  }
  > div:first-child {
    padding-top: 0;
  }

  & > .control-container:nth-child(1) {
    min-height: 80px;
    max-height: 350px;
    position: absolute;
    right: 30px;
    top: 50px;
    ${({ theme }) =>
      theme.orientation === 'RIGHT'
        ? css`
            left: 30px;
            right: initial;
          `
        : css`
            right: 30px;
            left: initial;
          `}
    & > h1 {
      position: static !important;
      margin-bottom: 0;
    }

    & > .styled-controls {
      right: 0;
      left: unset;
    }
  }
  & > .control-container:nth-child(2) {
    width: 100%;
  }
`

const VideoWrapper = styled.div`
  margin: 40px 0 40px auto;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      margin: 40px auto 40px 0;
    `};
  width: 100%;
  max-width: 550px;
  @media only screen and (max-width: 1600px) and (min-width: 1023px) {
    max-width: 560px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 648px;
    margin: 40px auto;
  }
  @media only screen and (max-width: 767px) {
    margin: 0;
    padding: 20px 40px 0 40px;
    box-sizing: border-box;
    max-width: initial;
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        padding: 0 40px 20px 40px;
      `}
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const VideoContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
`

const IframeWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
`

const ImageWrap = styled.div`
  width: 100%;
  @media only screen and (max-width: 1225px) and (min-width: 1025px) {
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        .styled-controls {
          left: initial;
          right: 0;
        }
      `}
  }
  @media only screen and (min-width: 1025px) {
    transform: translateX(45px);
    ${({ theme }) =>
      theme.orientation === 'RIGHT' &&
      css`
        transform: translateX(-45px);
      `}
  }
  @media only screen and (max-width: 1024px) {
    ${({ theme, isEditing }) =>
      theme.orientation === 'RIGHT' &&
      isEditing &&
      css`
        padding-top: 50px;
      `}
  }
  max-width: 600px;
  @media only screen and (max-width: 1024px) and (min-width: 601px) {
    transform: translateX(-3%);
  }
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    @media only screen and (max-width: 500px) {
      border-top-left-radius: ${getStyleForProp('cardRadius')};
      border-top-right-radius: ${getStyleForProp('cardRadius')};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      ${({ theme }) =>
        theme.orientation === 'RIGHT' &&
        css`
          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: ${getStyleForProp('cardRadius')};
          border-bottom-left-radius: ${getStyleForProp('cardRadius')};
        `}
    }
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 110%;
  ${({ theme, isEditing }) =>
    theme.orientation === 'RIGHT' &&
    isEditing &&
    css`
      margin-top: 90px;
    `}
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    height: initial;
    padding-top: 110%;
    border-radius: ${getStyleForProp('cardRadius')};
  }
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      .styled-controls {
        right: 0;
        left: initial;
      }
    `}
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  writing-mode: vertical-rl;
  min-height: 80px;
  position: absolute !important;
  right: 30px;
  ${({ theme }) =>
    theme.orientation === 'RIGHT'
      ? css`
          &:before {
            transform: translateX(30px);
          }
          left: 30px;
          right: initial;
        `
      : css`
          &:before {
            transform: translateX(30px);
          }
          right: 30px;
          left: initial;
        `}
  top: 50px;
`
const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  Title,
  Paragraph,
  CtaContent,
  ImageWrap,
  VideoContent,
  ContentContainer,
  VideoWrapper,
  WmCustomContainer,
  IframeWrap,
  ControlContainer
}
