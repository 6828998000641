/* @flow */
import styled from 'styled-components'
import LibIcon from '@website/common/components/Icon'
import THEME from './Feature11.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  background-color: ${getStyleForProp('background')};
  overflow: hidden;
  padding: ${props => (props.isEditing ? '140px 0 0 0' : '0')};
`

const Title = styled.h4`
  color: ${getStyleForProp('heading')};
  margin-left: 15px;
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  text-align: left;
  margin-left: 15px;
  @media only screen and (max-width: 992px) {
    text-align: center;
  }
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
`

const ListItem = styled.li`
  color: ${getStyleForProp('paragraph')};
  display: flex;
  align-items: center;
`

const IconWrap = styled.div`
  margin: 0 16px 0 0;
  user-select: none;
`

const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 30px;
  @media only screen and (max-width: 1440px) and (min-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    max-width: 380px;
    margin: 0 auto;
  }
`

const Icon = styled(LibIcon)`
  color: ${getStyleForProp('icon')};
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const LinkWrapper = styled.div`
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      getStyleForProp(props.isTertiary ? 'raisedShadow' : 'buttonShadow')};
    ${props =>
      getStyleForProp(
        props.isTertiary ? 'buttonBackgroundTertiary' : 'buttonBackground'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    max-width: 260px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
    margin: 20px 15px;
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
      margin: 12px 0;
    }
    @media only screen and (max-width: 700px) {
      margin: 12px 0;
      width: 100%;
      max-width: initial;
    }
    @media only screen and (max-width: 650px) {
      height: 40px;
      line-height: 40px;
    }
  }
`

export default {
  Img,
  Container,
  Title,
  Paragraph,
  ListItem,
  Icon,
  IconWrap,
  ButtonsWrap,
  LinkWrapper
}
