/* @flow */
import React from 'react'
import {
  navbarLogoActionMaker,
  navLinkActionMaker
} from '@website/components/ActionMakers'
import LogoColorChooser from '../LogoColorChooser'
import { BlackAcorn, BlueAcorn, WhiteAcorn } from '../consts'
import { translate } from '@editor/common/utils/translations'

const LOGO_COLORS = [
  {
    iconSrc: BlueAcorn,
    value: 'default',
    tooltipText: translate('default_label')
  },
  {
    iconSrc: BlackAcorn,
    value: 'black',
    tooltipText: translate('logo_filter_black_label')
  },
  {
    iconSrc: WhiteAcorn,
    value: 'white',
    tooltipText: translate('logo_filter_white_label')
  }
]

export default dispatcher => {
  const logoActions = (
    siteId,
    showRemove,
    selectedValue = 'default',
    imageUrl,
    mediaFlyoutShift
  ) => [
    {
      renderer: () => (
        <LogoColorChooser
          selected={selectedValue}
          data={LOGO_COLORS}
          onChoose={value =>
            dispatcher({
              type: 'CHANGE_LOGO_COLOR',
              value
            })
          }
        />
      ),
      toShow: showRemove
    },
    ...navbarLogoActionMaker(dispatcher)({
      showRemove,
      siteId,
      imageUrl,
      mediaFlyoutShift
    })
  ]

  const resizeAction = (size, undoable = true) => ({
    type: 'RESIZE_LOGO',
    value: size,
    undoable
  })

  const changeNavbarLogo = (url, dimensions, undoable) => {
    dispatcher({
      type: 'CHANGE_NAVBAR_LOGO',
      value: { url, dimensions },
      undoable
    })
  }

  return {
    logoActions,
    resizeAction,
    linkActions: navLinkActionMaker(dispatcher),
    changeNavbarLogo
  }
}
