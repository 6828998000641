import { useEffect, useCallback, useState } from 'react'

export const useResponsive = size => {
  const sizes = typeof size === 'object' ? size : { max: size }
  const { min = 0, max } = sizes
  const isClient = typeof window === 'object'

  const getWidth = useCallback(
    () => (isClient ? window.innerWidth : 0),
    [isClient]
  )

  const checkRangeMatch = useCallback(() => {
    const width = getWidth()
    return width >= min && width <= max
  }, [getWidth, min, max])

  const [isMatching, setMatchingState] = useState(checkRangeMatch())

  const handleResize = useCallback(() => {
    setMatchingState(checkRangeMatch())
  }, [checkRangeMatch, setMatchingState])

  useEffect(() => {
    setMatchingState(checkRangeMatch())
  }, [size])

  useEffect(() => {
    if (!isClient) {
      return true
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [isClient, handleResize])

  return isMatching
}
