export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    background: p => p.tertiaryDark,
    socialColor: p => p.accentWhite
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    socialColor: p => p.tertiaryDark
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    socialColor: p => p.accentWhite
  }
}
