// @flow
import React, { memo, useContext } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import {
  Divider,
  ThumbnailButtonsWrapper,
  ThumbnailButtons as Buttons,
  ThumbnailButtonWrapper
} from '@editor/common/styled-components/choose-component-template'
import type { TThumbnailButtonsProps } from './types'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import AddIcon from '@editor/common/assets/svgr-icons/Plus_icon.svg'
import ReplaceIcon from '@editor/common/assets/svgr-icons/replace_icon.svg'
import MultipleSelectionIcon from '@editor/common/assets/svgr-icons/multiple_selection_icon.svg'
import { MobileDetectContext } from '@contexts'

const WithTooltip = memo(({ children, isMobile, text }) =>
  !isMobile ? (
    <TooltipWrapper color="#363F5A" position="top" text={text} delay={500}>
      {children}
    </TooltipWrapper>
  ) : (
    children
  )
)

const ThumbnailButtons = ({
  isCompReplacing,
  onSelect,
  addOrReplace,
  isSmallerGrid
}: TThumbnailButtonsProps) => {
  const isMobile = useContext(MobileDetectContext)

  return (
    <ThumbnailButtonsWrapper fromBottom>
      {isCompReplacing ? (
        <Buttons fromBottom>
          <ThumbnailButtonWrapper>
            <Styled.ReplaceButton
              onClick={addOrReplace}
              isSmallerGrid={isSmallerGrid}
            >
              <ReplaceIcon />
              <p>{translate('replace_label')}</p>
            </Styled.ReplaceButton>
          </ThumbnailButtonWrapper>
        </Buttons>
      ) : (
        <Buttons fromBottom>
          <ThumbnailButtonWrapper>
            <WithTooltip
              text={translate('select_multiple_components_label')}
              isMobile={isMobile}
            >
              <Styled.SelectButton
                onClick={onSelect}
                isSmallerGrid={isSmallerGrid}
              >
                <p>{translate('select_label')}</p>
                <MultipleSelectionIcon />
              </Styled.SelectButton>
            </WithTooltip>
          </ThumbnailButtonWrapper>
          <Divider />
          <ThumbnailButtonWrapper>
            <WithTooltip
              text={translate('add_this_component_label')}
              isMobile={isMobile}
            >
              <Styled.AddButton
                onClick={addOrReplace}
                isSmallerGrid={isSmallerGrid}
              >
                <AddIcon />
                <p>{translate('add_label')}</p>
              </Styled.AddButton>
            </WithTooltip>
          </ThumbnailButtonWrapper>
        </Buttons>
      )}
    </ThumbnailButtonsWrapper>
  )
}

export default memo(ThumbnailButtons, () => true) // does not have any dependencies from outside
