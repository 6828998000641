import styled from 'styled-components'

export const Loader = styled.div`
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border: ${({ color }) => `4px solid ${color}`};
  border-radius: 50%;
  box-sizing: border-box;
  border-top-color: rgba(255, 255, 255, 0.9);
  animation: spin 0.8s ease-in-out infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`

export const StyledGearsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  min-width: 20px;
  max-width: 100px;
  flex-grow: 0;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & :before {
    display: block;
    content: '';
    padding-bottom: 100%;
  }
`

export const StyledLoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: ${({ background }) =>
    background || `rgba(255, 255, 255, 0.6)`};
`
