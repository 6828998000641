/* @flow */

export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  priceMaxChar: 20,
  optionMaxChar: 80,
  options: {
    min: 0,
    max: 15
  }
}
