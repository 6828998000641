/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_ROW': {
        const idx = action.value
        draft.gallery.splice(idx + 1, 0, initialState.gallery[0])
        break
      }
      case 'REMOVE_ROW': {
        const idx = action.value
        draft.gallery.splice(idx, 1)
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, itemIdx, dimensions } = action.value
        draft.gallery[idx][itemIdx].backgroundImg = url
        draft.gallery[idx][itemIdx].backgroundImgDimensions = dimensions || {}
        draft.gallery[idx][itemIdx].imgCropParams = {}
        break
      }
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, itemIdx, newTitle } = action.value
        draft.gallery[idx][itemIdx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, itemIdx, alignment } = action.value
        draft.gallery[idx][itemIdx].titleAlignment = alignment
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx, itemIdx } = action.value
        draft.gallery[idx][itemIdx].imgCropParams = imgCropParams
        break
      }
      case 'SET_IMAGE_LINK': {
        const { idx, itemIdx, imageLink } = action.value
        draft.gallery[idx][itemIdx].imageLink = imageLink
        break
      }
    }
  })
}
