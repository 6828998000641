/* @flow */
import styled from 'styled-components'
import LibIcon from '@website/common/components/Icon'

const Icon = styled(LibIcon)`
  margin-left: 0;
  padding-left: 5px;
  min-width: 24px;
  transition: transform linear 0.15s;
`

const LinkSection = styled.div`
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500 !important;
    font-size: 16px !important;
  }
  .learn-more-link {
    align-items: center;
    &:hover {
      opacity: 0.7;
      ${Icon} {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
`

export default {
  LinkSection,
  Icon
}
