/* @flow */
import { subscribtionCardActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeCardData = (
    planIdx: number,
    data: string,
    keyInStore: string
  ) => ({
    type: 'CHANGE_CARD_DATA',
    value: { keyInStore, planIdx, data }
  })
  const changePlanTitle = (title: string, planIndex: number) => {
    dispatcher(changeCardData(planIndex, title, 'title'))
  }

  const changePlanPrice = (price: string, planIdx: number) => {
    dispatcher(changeCardData(planIdx, price, 'price'))
  }

  const changePlanDescription = (date: string, planIdx: number) => {
    dispatcher(changeCardData(planIdx, date, 'description'))
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockDescriptionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_DESCRIPTION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changePriceAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PRICE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: () => dispatcher(addBlock()),
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changePlanTitle,
    changePlanPrice,
    changePlanDescription,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockDescriptionAlignment,
    changePriceAlignment,
    cardActions: subscribtionCardActionMaker(dispatcher)
  }
}
