// @flow
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import {
  StyledContainer,
  StyledHeader,
  StyledContent,
  StyledButton,
  GlobalStyles
} from './styled'
import { closeMaintenanceModal } from '@actions/editor'
import { selectMaintenanceModalStatus } from '@selectors'
import { openUrl } from '@website/common/utils'

const MaintenanceModal = ({ closeMaintenanceModal, isOpen }) => {
  const onClose = useCallback(() => {
    closeMaintenanceModal()
    openUrl('https://renderforest.com', false)
  }, [closeMaintenanceModal])

  return (
    <>
      <GlobalStyles />
      <Popup isOpen={isOpen} className="maintenance-popup" onClose={onClose}>
        <StyledContainer>
          <StyledHeader>Forest needs maintenance!</StyledHeader>
          <StyledContent>
            We apologize for the inconvenience. Our site is currently down for
            some maintenance. Please check back later, we expect to be back
            shortly.
          </StyledContent>
          <StyledButton type="secondary" size="small" onClick={onClose}>
            GOT IT
          </StyledButton>
        </StyledContainer>
      </Popup>
    </>
  )
}

const mapStateToProps = state => ({
  isOpen: selectMaintenanceModalStatus(state)
})

const mapDispatchToProps = {
  closeMaintenanceModal
}

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceModal)
