/* @flow */
import React, { memo, useCallback } from 'react'
import produce from 'immer'

import Validatable from '@website/common/lib/ValidatableHOC'
import { isValidName } from '@website/common/utils'

import _RSVPInput from '../RSVPInput'
import * as Styled from './styled'
import { TStaticFieldsProps } from './types'
import {
  FIRST_NAME_VALIDATION_MESSAGE,
  LAST_NAME_VALIDATION_MESSAGE
} from './consts'

const RSVPInput = Validatable(_RSVPInput)

const StaticFields = ({
  firstName,
  lastName,
  email,
  phone,
  formData = {},
  setFormData,
  register,
  formErrors
}: TStaticFieldsProps) => {
  const onInputChange = useCallback(
    key => value => {
      setFormData(
        produce(draft => {
          draft[key] = value
        })
      )
    },
    [setFormData]
  )

  return (
    <>
      <Styled.InputPair>
        <RSVPInput
          isRequired={firstName.isRequired}
          value={formData.firstName}
          label={firstName.text}
          errorMessage={formErrors.firstName}
          {...register({
            fieldName: 'firstName',
            initialValue: formData.firstName,
            onChange: onInputChange('firstName'),
            validationOptions: {
              isRequired: firstName.isRequired,
              firstName: {
                validator: isValidName,
                errorMessage: FIRST_NAME_VALIDATION_MESSAGE
              }
            }
          })}
        />
        <RSVPInput
          isRequired={lastName.isRequired}
          value={formData.lastName}
          label={lastName.text}
          errorMessage={formErrors.lastName}
          {...register({
            fieldName: 'lastName',
            initialValue: formData.lastName,
            onChange: onInputChange('lastName'),
            validationOptions: {
              isRequired: lastName.isRequired,
              lastName: {
                validator: isValidName,
                errorMessage: LAST_NAME_VALIDATION_MESSAGE
              }
            }
          })}
        />
      </Styled.InputPair>
      <Styled.InputPair hasNotFirstChildMargin={!phone.isVisible}>
        <RSVPInput
          isRequired={email.isRequired}
          value={formData.email}
          label={email.text}
          errorMessage={formErrors.email}
          {...register({
            fieldName: 'email',
            initialValue: formData.email,
            onChange: onInputChange('email'),
            validationOptions: {
              isRequired: email.isRequired,
              isEmail: true
            }
          })}
        />
        {phone.isVisible ? (
          <RSVPInput
            isRequired={phone.isRequired}
            value={formData.phone}
            label={phone.text}
            errorMessage={formErrors.phone}
            {...register({
              fieldName: 'phone',
              initialValue: formData.phone,
              onChange: onInputChange('phone'),
              validationOptions: {
                isRequired: phone.isRequired,
                isPhone: true
              }
            })}
          />
        ) : null}
      </Styled.InputPair>
    </>
  )
}

export default memo(StaticFields)
