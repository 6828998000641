/* @flow */
import React, { useContext } from 'react'

import S from './AddButton.styled'
import { EditingContext } from '@contexts'

const AddButton = props => {
  const {
    toShow,
    style,
    onAdd,
    children,
    buttonRef,
    className,
    wide = false,
    isBig = false,
    type = 'text',
    rounded = false,
    large = false,
    medium = false
  } = props
  const { isEditing } = useContext(EditingContext)

  if (!isEditing) {
    return null
  }

  if (toShow) {
    return (
      <S.AddButtonWrap className="add-button-wrap">
        <S.Div
          ref={buttonRef}
          wide={wide}
          style={style}
          role="button"
          onClick={onAdd}
          rounded={rounded}
          isText={type === 'text'}
          isIcon={type === 'icon'}
          isBig={isBig}
          large={large}
          medium={medium}
          className={`${className} rf-add-btn`}
        >
          {children || '+'}
        </S.Div>
      </S.AddButtonWrap>
    )
  }
  return null
}

export default AddButton
