export const validation = {
  headingMaxChar: 100,
  paragraphMaxChar: 300,
  blockTitleMaxChar: 5,
  blockParagraphMaxChar: 50,
  blocks: {
    min: 0,
    max: 3
  }
}

export const ANIMATION_SPEED = 50
