/* @flow */
import { validation } from '../Feature11_2.consts'

import {
  iconActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const removeListItem = (idx: number) => ({
    type: 'REMOVE_LIST_ITEM',
    value: idx
  })

  const changeListItem = (newItem: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_LIST_ITEM',
      value: { newItem, idx }
    })

  const featureActions = (idx, ftLenght) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeListItem(idx)),
      toShow: ftLenght > validation.features.min
    }
  ]

  const changeTitleGradientStatus = (newTitleTextGradient: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_TEXT_GRADIENT',
      value: newTitleTextGradient
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeListItem,
    featureActions,
    changeTitleGradientStatus,
    addNewItem: () => dispatcher({ type: 'ADD_NEW_ITEM' }),
    iconActions: iconActionMaker(dispatcher),
    imgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'feature11_2',
        showRemove: false,
        imageUrl
      })
  }
}
