import styled, { css } from 'styled-components'
import { globalColors } from '@renderforest/rf-ui-library/consts/colors'

export const UndoRedoContainer = styled.div`
  display: flex;
  margin-left: 20px;
  @media only screen and (max-width: 359px) {
    margin-left: 10px;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  &:first-child {
    margin-right: 15px;
    @media only screen and (max-width: 359px) {
      margin-right: 10px;
    }
  }
  ${({ hasCount }) =>
    hasCount
      ? css`
          fill: ${globalColors.secondarySemiDark};
          cursor: pointer;
          &:hover {
            svg {
              fill: ${globalColors.primaryDark};
            }
          }
        `
      : css`
          fill: ${globalColors.secondaryLight};
          cursor: default;
        `}
`
