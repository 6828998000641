/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature2.styled'
import { validation } from './Feature2.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { translate } from '@editor/common/utils/translations'

const Feature2 = memo(props => {
  const {
    data: {
      featureParts,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <WMContainer>
        <S.BlocksWrap blockCount={featureParts.length}>
          <Mapper
            data={featureParts}
            render={(featureBlock, idx) => (
              <S.BlockItemWrap key={idx}>
                <ControlsWithModalOpener
                  actions={Actions.featureActions({
                    idx,
                    showRemove:
                      featureParts.length !== validation.featureParts.min
                  })}
                  style={{ alignItems: 'center' }}
                >
                  <ControlsWithModalOpener
                    actions={Actions.getIconActions({
                      idx,
                      showRemove: false
                    })}
                  >
                    <S.Circle>
                      <S.Icon size="large" name={featureBlock.icon} />
                    </S.Circle>
                  </ControlsWithModalOpener>
                  <EditableContent
                    text={featureBlock.blockTitle}
                    alignment={featureBlock.blockTitleAlignment}
                    as={S.Title}
                    maxCount={validation.blockTitleMaxChar}
                    onChange={(newTitle: string) =>
                      Actions.changeBlockTitle(newTitle, idx)
                    }
                    className="WM_GLOBAL_primary-font"
                    changeAlignment={(alignment: string) =>
                      Actions.changeBlockTitleAlignment(alignment, idx)
                    }
                  />

                  <EditableContent
                    className="WM_GLOBAL_secondary-font"
                    text={featureBlock.blockDescription}
                    alignment={featureBlock.blockParagraphAlignment}
                    as={S.Description}
                    maxCount={validation.blockParagraphMaxChar}
                    onChange={(newDescription: string) =>
                      Actions.changeBlockContent(idx, newDescription)
                    }
                    changeAlignment={(alignment: string) =>
                      Actions.changeBlockParagraphAlignment(alignment, idx)
                    }
                  />
                  <AdditionalLink
                    idx={idx}
                    additionalLinkText={featureBlock.additionalText}
                    additionalLinkUrl={featureBlock.additionalUrl}
                  />
                </ControlsWithModalOpener>
              </S.BlockItemWrap>
            )}
          />
        </S.BlocksWrap>
        <AddButton
          style={{ marginTop: '2rem' }}
          onAdd={Actions.addBlock}
          toShow={featureParts.length < validation.featureParts.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_feature_label')}
        </AddButton>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Feature2
