// @flow
import { translate } from '@editor/common/utils/translations'

export const getResetChangesTooltipText = (
  isSitePublished,
  hasChangesAfterPublish
) => {
  if (isSitePublished && hasChangesAfterPublish) {
    return ''
  }
  if (!isSitePublished) {
    return translate('reset_changes_tooltip')
  }
  return translate('no_changes_tooltip_text')
}
