/* @flow */
import React from 'react'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'

import MockupColorSwitcher from '@website/common/components/MockupColorSwitcher'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const addStoreLink = (keyInStore: string) => () =>
    dispatch({ type: 'ADD_STORE_LINK', value: keyInStore })

  const changeStoreUrl = (storeType: string) => (url: string) => {
    dispatch({
      type: 'CHANGE_STORE_URL',
      value: { url, storeType }
    })
  }

  const storeIconActions = (storeType: string, link: string) => [
    {
      IconComp: EditIcon,
      tooltipText: translate('edit_label'),
      handler: changeStoreUrl(storeType),
      modal: {
        type: MODAL_TYPES.urlModal,
        props: { type: LINK_TYPES.link, link }
      }
    },
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        dispatch({ type: 'REMOVE_STORE_BUTTON', value: storeType })
      }
    }
  ]
  const changeMockupColor = (mockupType: string) => ({
    type: 'CHANGE_MOCKUP_COLOR',
    value: mockupType
  })

  const makeMockupLight = (mockupType: string) => ({
    type: 'MAKE_MOCKUP_LIGHT',
    value: mockupType
  })

  const makeMockupDark = (mockupType: string) => ({
    type: 'MAKE_MOCKUP_DARK',
    value: mockupType
  })

  const removeImage = (mockupType: string) => {
    dispatch({
      type: 'REMOVE_IMAGE',
      value: mockupType
    })
  }

  const addImage = (mockupType: string) => {
    dispatch({
      type: 'ADD_IMAGE',
      value: mockupType
    })
  }

  const _mockupActions = ({
    mockupType,
    mockupColor,
    areTwoMockups,
    imageUrl
  }) => [
    ...cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'CTA14',
      showRemove: false,
      additionalArgs: { mockupType },
      imageUrl
    }),
    {
      toShow: areTwoMockups,
      handler: () => removeImage(mockupType),
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label')
    },
    {
      renderer: () => (
        <MockupColorSwitcher
          onChoose={() => dispatch(changeMockupColor(mockupType))}
          onFirstOptionSelect={() => dispatch(makeMockupLight(mockupType))}
          onSecondOptionSelect={() => dispatch(makeMockupDark(mockupType))}
          isSecondOptionSelected={mockupColor === 'dark'}
        />
      )
    }
  ]

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraph: _changeParagraph,
    changeParagraphAlignment: _changeParagraphAlignment,
    addStoreLink,
    storeIconActions,
    addImage,
    mockupActions: _mockupActions
  }
}
