/* @flow */
import React, {
  useContext,
  useState,
  useCallback,
  useRef,
  useEffect,
  memo
} from 'react'
import S from './Header52.styled'
import THEME from './Header52.theme'
import { validation } from './Header52.consts'
import ActionGetter from './Actions'
import Arrow from './Arrow'
import NavBar from '@website/common/components/NavBar'
import { getOffsetXByMouseX, getOffsetYByMouseY } from '@website/common/utils'
import Show from '@website/common/components/Show/Show'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import useOnScreen from '@website/common/hooks/useOnScreen'
import Controls from '@website/common/components/Controlled/Controls'
import Socials from '@website/common/components/Socials'

const Header52 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      subTitle,
      subTitleAlignment,
      blocks,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      headerSocials,
      additionalText,
      additionalUrl,
      hasArrow,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      isAnimated
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const animatedRef = useRef(null)
  const isOnScreen = useOnScreen(animatedRef)

  const [navBarHeight, setNavBarHeight] = useState(0)

  const handleMouseMove = useCallback(
    event => {
      if ((!isEditing && !isAnimated) || isEditing || window.innerWidth <= 1024)
        return

      const targetElement = animatedRef.current
      const pointerClientX = event.clientX
      const pointerClientY = event.clientY

      const { width, height } = targetElement.getBoundingClientRect()

      const img1 = targetElement.getElementsByClassName('header52_1_image-2')[0]
      const img2 = targetElement.getElementsByClassName('header52_1_image-3')[0]
      const img3 = targetElement.getElementsByClassName('header52_2_image-2')[0]

      img1 &&
        (img1.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          7
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 4.8)}px)`)
      img2 &&
        (img2.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          2.5
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 3)}px)`)
      img3 &&
        (img3.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          1.5
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 6)}px)`)
    },
    [animatedRef, isAnimated]
  )

  useEffect(() => {
    if (animatedRef.current) {
      animatedRef.current.addEventListener('mousemove', handleMouseMove)
    }
    return () => {
      if (animatedRef.current) {
        animatedRef.current.removeEventListener('mousemove', handleMouseMove)
      }
    }
  }, [animatedRef.current, handleMouseMove])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      ref={animatedRef}
      isAnimated={isAnimated}
      isOnScreen={isOnScreen}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || headerSocials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={headerSocials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.WmCustomContainer
        isEditing={isEditing}
        topOffset={navBarHeight}
        isTwoBlock={!blocks['block1'] || !blocks['block2']}
      >
        <Show when={[blocks['block1']]}>
          <S.FirstCollage
            isEditing={isEditing}
            isBlockEmpty={!blocks['block1']}
            isSecondBlockEmpty={!blocks['block2']}
          >
            <If
              condition={blocks['block1']}
              then={() => (
                <Controls
                  actions={Actions.blockActions({
                    idx: 1,
                    showRemove: !!blocks['block2']
                  })}
                  style={{ height: '100%', width: '100%' }}
                >
                  <S.ImagesContainer
                    isEditing={isEditing}
                    isAnimated={isAnimated}
                    isOnScreen={isOnScreen}
                  >
                    <Mapper
                      data={blocks['block1'].images}
                      render={(image, idx) => {
                        return (
                          <S.FirstImageWrapper>
                            <S.ImageFrame
                              className={`header52_1_image-${idx + 1}`}
                            >
                              <ControlsWithImageCropper
                                actions={Actions.imageActions(
                                  idx,
                                  'block1',
                                  image.imageUrl
                                )}
                                style={{ width: '100%' }}
                                key={idx}
                              >
                                <Image
                                  as={S.Img}
                                  sizes={image.imgDimensions}
                                  defaultImgSrc={image.imageUrl}
                                  asProps={{
                                    imgCropParams: image.imgCropParams
                                  }}
                                  alt="header images"
                                />
                              </ControlsWithImageCropper>
                            </S.ImageFrame>
                          </S.FirstImageWrapper>
                        )
                      }}
                    />
                    <If
                      condition={hasArrow}
                      then={() => (
                        <S.ArrowWrapper isEditing={isEditing}>
                          <Controls
                            actions={Actions.removeArrow}
                            style={{ width: '100%', height: '100%' }}
                          >
                            <Arrow />
                          </Controls>
                        </S.ArrowWrapper>
                      )}
                      otherwise={() => (
                        <AddButton
                          onAdd={Actions.addArrow}
                          style={{
                            margin: '0px'
                          }}
                          type="icon"
                          medium
                          toShow
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                        </AddButton>
                      )}
                    />
                  </S.ImagesContainer>
                </Controls>
              )}
              otherwise={() => (
                <AddButton
                  onAdd={() => Actions.addBlock(1)}
                  toShow={blocks['block1'] === null}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_collage_label')}
                </AddButton>
              )}
            />
          </S.FirstCollage>
        </Show>
        <S.ContentWrap
          isEditing={isEditing}
          isLeftBlockMissing={!blocks['block1']}
          isRightBlockMissing={!blocks['block2']}
        >
          <EditableContent
            as={S.SubTitle}
            text={subTitle}
            required={!title}
            alignment={subTitleAlignment}
            maxCount={validation.subTitleMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeSubTitle}
            changeAlignment={Actions.changeSubtitleAlignment}
          />
          <EditableContent
            as={S.Title}
            text={title}
            required={!subTitle}
            alignment={titleAlignment}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <AdditionalLink
            idx={null}
            additionalLinkText={additionalText}
            additionalLinkUrl={additionalUrl}
          />
        </S.ContentWrap>
        <Show when={[blocks['block2']]}>
          <S.SecondCollage
            isEditing={isEditing}
            isBlockEmpty={!blocks['block2']}
            isFirstBlockEmpty={!blocks['block1']}
          >
            <If
              condition={blocks['block2']}
              then={() => (
                <Controls
                  actions={Actions.blockActions({
                    idx: 2,
                    showRemove: !!blocks['block1']
                  })}
                  style={{ height: '100%', width: '100%' }}
                  alignment="right"
                >
                  <S.ImagesContainer
                    isEditing={isEditing}
                    isAnimated={isAnimated}
                    isOnScreen={isOnScreen}
                  >
                    <Mapper
                      data={blocks['block2'].images}
                      render={(image, idx) => {
                        return (
                          <S.SecondImageWrapper>
                            <S.ImageFrame
                              className={`header52_2_image-${idx + 1}`}
                            >
                              <ControlsWithImageCropper
                                actions={Actions.imageActions(
                                  idx,
                                  'block2',
                                  image.imageUrl
                                )}
                                style={{ width: '100%' }}
                                key={idx}
                              >
                                <Image
                                  as={S.Img}
                                  sizes={image.imgDimensions}
                                  defaultImgSrc={image.imageUrl}
                                  asProps={{
                                    imgCropParams: image.imgCropParams
                                  }}
                                  alt="header images"
                                />
                              </ControlsWithImageCropper>
                            </S.ImageFrame>
                          </S.SecondImageWrapper>
                        )
                      }}
                    />
                  </S.ImagesContainer>
                  <Show when={[blocks['block2'].paragraph1]}>
                    <S.AdditionalBlockWrap isEditing={isEditing}>
                      <S.AdditionalBlock>
                        <EditableContent
                          as={S.AdditionalParagraph}
                          text={blocks['block2'].paragraph1}
                          alignment={blocks['block2'].paragraph1Alignment}
                          maxCount={validation.additionalParagraphMaxChar}
                          className="WM_GLOBAL_paragraph18"
                          onChange={Actions.changeParagraph1}
                          changeAlignment={Actions.changeParagraph1Alignment}
                        />
                      </S.AdditionalBlock>
                    </S.AdditionalBlockWrap>
                  </Show>
                </Controls>
              )}
              otherwise={() => (
                <AddButton onAdd={() => Actions.addBlock(2)} toShow>
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_collage_label')}
                </AddButton>
              )}
            />
          </S.SecondCollage>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header52
