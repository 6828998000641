/* @flow */
import {
  changeParagraph,
  changeCopyright
} from '@website/components/CommonActions'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import LinkIcon from '@editor/common/assets/svgr-icons/Link_icon.svg'
import { privacyPolicyAction } from '@website/components/ActionMakers'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeAppStoreUrl = (key: string, link: string) => {
    dispatcher({
      type: 'CHANGE_STORE_URL',
      value: { link, key }
    })
  }

  const removeButton = (key: string) => ({
    type: 'REMOVE_BUTTON',
    value: { key }
  })

  const addStoreLink = (key: string) => {
    dispatcher({
      type: 'ADD_STORE_LINK',
      value: { key }
    })
  }

  const _changeCopyright = (newValue: string) => {
    dispatcher(changeCopyright(newValue))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  return {
    addStoreLink,
    privacyPolicyAction,
    changeParagraph: _changeParagraph,
    changeCopyright: _changeCopyright,
    appStoreActions: (key: string, link: string) => [
      {
        IconComp: LinkIcon,
        tooltipText: translate('add_link_label'),
        handler: link => dispatcher(changeAppStoreUrl(key, link)),
        modal: {
          type: MODAL_TYPES.urlModal,
          props: {
            type: LINK_TYPES.link,
            link
          }
        }
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeButton(key))
      }
    ]
  }
}
