/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.images[idx].imgUrl = url
        draft.images[idx].imgDimensions = dimensions || {}
        draft.images[idx].imgCropParams = {}
        break
      }
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'ADD_SOCIAL':
        draft.headerSocials.push(initialState.headerSocials[0])
        break
      case 'REMOVE_SOCIAL':
        draft.headerSocials.splice(action.value.idx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.headerSocials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.headerSocials[idx].icon = iconName
        }
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.images[idx].imgCropParams = imgCropParams
      }
    }
  })
}
