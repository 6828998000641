/* @flow */
import React, { useContext, useState, memo, useCallback } from 'react'
import S from './FAQ1.styled'
import { validation } from './FAQ1.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import Arrow from '@website/common/assets/arrow-bottom.svg'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const WithWrapper = memo(({ children, isEditing }) => {
  const stopPropagation = useCallback(e => {
    e.stopPropagation()
  }, [])

  return isEditing ? <div onClick={stopPropagation}>{children}</div> : children
})

const FAQ1 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      faqs,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const [activeQuestionIdx, setActiveQuestionIdx] = useState(0)
  const expandAnswer = idx => () => {
    const isTheSame = idx === activeQuestionIdx
    setActiveQuestionIdx(isTheSame ? null : idx)
  }

  const renderFaqCards = (data, isOdd, Actions) => {
    return (
      <>
        {data.map((faq: string, idx: number) => {
          return idx % 2 === isOdd ? (
            <Show when={[faq.question && faq.answer]}>
              <S.FaqItemWrap key={idx} onClick={expandAnswer(idx)}>
                <ControlsWithModalOpener
                  actions={Actions.getFaqActions(idx, faqs.length)}
                  style={{ width: '100%' }}
                >
                  <S.FaqItemHeader toExpand={idx === activeQuestionIdx}>
                    <WithWrapper isEditing={isEditing}>
                      <EditableContent
                        isLinkControlHidden
                        text={faq.question}
                        alignment={faq.titleAlignment}
                        maxCount={validation.maxQuestionChar}
                        required
                        as={S.FaqItemHeaderText}
                        onChange={(newQuestion: string) => {
                          Actions.changeQuestion(newQuestion, idx)
                        }}
                        changeAlignment={(alignment: string) => {
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }}
                        className="WM_GLOBAL_paragraph18"
                      />
                    </WithWrapper>
                    <S.Arrow
                      src={Arrow}
                      alt="Arrow icon"
                      {...(isEditing && { onClick: expandAnswer(idx) })}
                    />
                  </S.FaqItemHeader>
                  <S.FaqContentWrapper
                    toExpand={idx === activeQuestionIdx}
                    isEditing={isEditing}
                  >
                    <WithWrapper isEditing={isEditing}>
                      <EditableContent
                        isLinkControlHidden
                        as={S.FaqContent}
                        alignment={faq.paragraphAlignment}
                        text={faq.answer}
                        maxCount={validation.maxAnswerChar}
                        required
                        onChange={(newAnswer: string) => {
                          Actions.changeAnswer(newAnswer, idx)
                        }}
                        changeAlignment={(alignment: string) => {
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }}
                        className="WM_GLOBAL_paragraph18"
                      />
                    </WithWrapper>
                  </S.FaqContentWrapper>
                </ControlsWithModalOpener>
              </S.FaqItemWrap>
            </Show>
          ) : null
        })}
      </>
    )
  }

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <WMContainer>
        <S.FaqContainer>
          <Show when={[title, paragraph]}>
            <S.FaqHeader>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  as={S.Title}
                  maxCount={validation.maxTitleChar}
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                  className="WM_GLOBAL_heading"
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  alignment={paragraphAlignment}
                  as={S.Paragraph}
                  maxCount={validation.maxParagraphChar}
                  onChange={Actions.changeParagraph}
                  className="WM_GLOBAL_paragraph"
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
            </S.FaqHeader>
          </Show>
          <S.FaqsWrapper>
            <S.Faqs>{renderFaqCards(faqs, 0, Actions)}</S.Faqs>
            <If
              condition={faqs.length > 1}
              then={() => <S.Faqs>{renderFaqCards(faqs, 1, Actions)}</S.Faqs>}
            />
          </S.FaqsWrapper>
          <AddButton
            onAdd={Actions.addFaq}
            toShow={faqs.length < validation.faqs.max}
            style={{ marginTop: '2rem' }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_faq_label')}
          </AddButton>
        </S.FaqContainer>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default FAQ1
