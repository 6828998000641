//@flow
import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { ControlModalsContext, UpgradePopupContext } from '@contexts'
import { getSiteData, selectUserFirstName } from '@selectors'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import { projectContainerClassName } from '@website/conf/consts'
import { AI_WIZARD_UPGRADE_REFERRER } from '@editor/conf/consts'
import { WizardWindow } from '@renderforest/text-wizard-ai'
import { useBackdropClick } from '@hooks'
import * as Styled from './styled'

const TextWizardAI = () => {
  const isPaidUser = useSelector(getSiteData).paid
  const userName = useSelector(selectUserFirstName)

  const { modalStates, setModalState } = useContext(ControlModalsContext)
  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const text = modalStates[MODAL_TYPES.textWizardAIWindow].text
  const maxCount = modalStates[MODAL_TYPES.textWizardAIWindow].maxCount
  const assistantCommand =
    modalStates[MODAL_TYPES.textWizardAIWindow].assistantCommand
  const handleUseTextChange =
    modalStates[MODAL_TYPES.textWizardAIWindow].handleUseTextChange

  const onClose = () => {
    document
      .querySelector('[data-hovered="true"]')
      ?.removeAttribute('data-hovered')
    setModalState(MODAL_TYPES.textWizardAIWindow, {
      isOpen: false,
      text: '',
      assistantCommand: ''
    })
  }

  const openUpgradePopup = useCallback(() => {
    setUpgradePopupState({ isOpen: true, referrer: AI_WIZARD_UPGRADE_REFERRER })
  }, [])

  const ref = useBackdropClick(() => {
    onClose()
  }, ['.more-flyout-content'])

  const editableArea = document.querySelector(`.${projectContainerClassName}`)

  const _handleUseTextChange = useCallback((...args) => {
    const textEl = document.querySelector('[data-hovered="true"]')
    const { top: elTop, bottom: elBottom } = textEl.getBoundingClientRect()

    const { top: editorTop, bottom: editorBottom } =
      editableArea.getBoundingClientRect()

    if (textEl && (elBottom > editorBottom || elTop < editorTop)) {
      textEl.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }

    handleUseTextChange(...args)
  }, [])

  return (
    <Styled.WizardWindowContainer ref={ref}>
      <WizardWindow
        className="wizard-window"
        isDraggable
        limit={maxCount}
        onClose={onClose}
        initialText={text}
        userName={userName}
        command={assistantCommand}
        applyText={_handleUseTextChange}
        flyoutOptions={{ isEngagingVisible: false }}
        dragBoundaryContainer={editableArea}
        {...(isPaidUser ? {} : { openUpgradePopup })}
      />
    </Styled.WizardWindowContainer>
  )
}

export default TextWizardAI
