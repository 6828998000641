/* @flow */

module.exports = {
  id: 'Header5',
  isUnique: true,
  thumbnailRatio: 0.49,
  label: 'Header5',
  category: 'header',
  colorMode: '',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header5new-1_0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header5m-1.0.0.jpg',
  metaData: {
    title: {
      _type: 'title',
      _min: 1,
      _max: 120,
      _value: 'Bringing a new and fresh interface'
    },
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 1500,
      _value:
        'Now you can enjoy our new interface with a more user-friendly design and new features. Supplemental addictions make it even more complete and pleasant for your use. Try it right now!'
    },
    appleBackground: {
      _type: 'image',
      _dimensionsKey: 'appleBackgroundDimensions',
      _optimizationSizes: [
        { _width: 600 },
        { _width: 600 },
        { _width: 600 },
        { _width: 600 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png'
    },
    appleBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png'
    },
    logoSrc: {
      _type: 'logo',
      _dimensionsKey: 'logoDimensions',
      _optimizationSizes: [
        { _width: 500 },
        { _width: 500 },
        { _width: 500 },
        { _width: 500 }
      ],
      _min: 0,
      _max: 2048,
      _value: ''
    },
    logoDimensions: {},
    logoColor: 'default',
    backgroundImgUrl: {
      _type: 'backgroundImage',
      _dimensionsKey: 'backgroundImgDimensions',
      _optimizationSizes: [
        { _width: 800 },
        { _width: 1200 },
        { _width: 1600 },
        { _width: 2200 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg'
    },
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg'
    },
    bgOverlayTransparency: 0.8,
    buttons: {
      _type: 'list',
      _min: 0,
      _max: 2,
      _value: [
        {
          buttonText: {
            _type: 'buttonLabel',
            _min: 1,
            _max: 19,
            _value: 'GET STARTED'
          },
          buttonUrl: {
            _type: 'buttonUrl',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          type: 'primary'
        },
        {
          buttonText: {
            _type: 'buttonLabel',
            _min: 1,
            _max: 19,
            _value: 'LEARN MORE'
          },
          buttonUrl: {
            _type: 'buttonUrl',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          type: 'tertiary'
        }
      ]
    }
  },
  defaultProps: {
    title: 'Bringing a new and fresh interface',
    paragraph:
      'Now you can enjoy our new interface with a more user-friendly design and new features. Supplemental addictions make it even more complete and pleasant for your use. Try it right now!',
    appleBackground:
      'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
    appleBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png'
    },
    logoSrc: '',
    logoDimensions: {},
    logoColor: 'default',
    backgroundImgUrl:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg',
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5-img1-1_0.jpg'
    },
    bgOverlayTransparency: 0.8,
    buttons: [
      {
        buttonText: 'GET STARTED',
        buttonUrl: 'http://builder.renderforestsites.com',
        type: 'primary'
      },
      {
        buttonText: 'LEARN MORE',
        buttonUrl: 'http://builder.renderforestsites.com',
        type: 'tertiary'
      }
    ]
  }
}
