/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE':
        {
          const { idx, blockIdx, newTitle } = action.value
          draft.blocks[idx][blockIdx].title = newTitle
        }
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        {
          const { idx, blockIdx, newParagraph } = action.value
          draft.blocks[idx][blockIdx].paragraph = newParagraph
        }
        break
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, blockIdx, alignment } = action.value
        draft.blocks[idx][blockIdx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, blockIdx, alignment } = action.value
        draft.blocks[idx][blockIdx].paragraphAlignment = alignment
        break
      }
      case 'CHANGE_IMAGE':
        {
          const { idx, blockIdx, url, dimensions } = action.value
          draft.blocks[idx][blockIdx].imgUrl = url
          draft.blocks[idx][blockIdx].imgDimensions = dimensions
          draft.blocks[idx][blockIdx].imgCropParams = {}
        }
        break
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'REMOVE_BLOCK':
        draft.blocks.splice(action.value.idx, 1)
        break
      case 'CHANGE_LINK_DATA':
        {
          const { idx, blockIdx, newLinkData } = action.value
          draft.blocks[idx][blockIdx].additionalUrl = newLinkData.link
          draft.blocks[idx][blockIdx].additionalText = newLinkData.text
        }
        break
      case 'REMOVE_LINK':
        {
          const { idx, blockIdx } = action.value
          draft.blocks[idx][blockIdx].additionalUrl = ''
          draft.blocks[idx][blockIdx].additionalText = ''
        }
        break
      case 'ADD_LINK':
        {
          const { idx, blockIdx } = action.value
          const { additionalUrl, additionalText } = initialState.blocks[0][0]
          draft.blocks[idx][blockIdx].additionalUrl = additionalUrl
          draft.blocks[idx][blockIdx].additionalText = additionalText
        }
        break
      case 'SET_IMG_CROP_PARAMS':
        const { imgCropParams, idx, blockIdx } = action.value
        draft.blocks[idx][blockIdx].imgCropParams = imgCropParams
    }
  })
}
