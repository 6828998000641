/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Pricing6_2.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import S from '@website/common/components/NavBar/NavBar.styled'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  padding: ${props => (props.isEditing ? '135px 0 0 0' : '0')};
`

const PricingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .lazy-figure {
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .control-container:nth-child(2) {
    margin: 0 0 0 4px;

    & > p {
      margin: 0;
    }
  }
`

const Count = styled.p`
  font-size: 400px;
  font-weight: bold;
  color: #f0f2f7;
  position: absolute;
  right: 0;
  top: 50px;
  line-height: 0.5;
  color: ${getStyleForProp('countColor')};
  opacity: 0.2;
  white-space: nowrap;
  @media only screen and (max-width: 992px) {
    font-size: 300px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 250px;
  }
`

const IconContainer = styled.button`
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #b3beeb;
  padding: 0;
  &:focus {
    outline: 1px solid #5690ff;
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: '#b3beeb'
}))``

const SliderTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const SliderParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-size: 16px;
  line-height: 1.4;
  text-align: left;
  padding: 14px 0;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`

const TextContainer = styled.div`
  background: ${getStyleForProp('background')};
  position: relative;
  width: 50%;
  ${({ isEditing }) =>
    isEditing ? 'padding: 20px 4vw 50px 4vw;' : 'padding: 60px 4vw 0 4vw;'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-word;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 60px 40px 90px 40px;
  }
  @media only screen and (max-width: 457px) {
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
  > .control-container:nth-child(2) {
    margin-top: 0px;
    margin-bottom: 10px;
    width: 100%;
    h2 {
      margin-bottom: 0;
    }
  }
`

const SlideContainer = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  display: flex;
  ${props =>
    props.animate &&
    css`
      animation: slidein 500ms linear;
      @keyframes slidein {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
    `};
  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-shadow: ${getStyleForProp('raisedShadow')};
`

const DotsWrap = styled.div`
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -6px;
  border-right: 12px dotted ${getStyleForProp('background')};
  @media only screen and (max-width: 1024px) {
    width: 100%;
    height: 2px;
    right: 0;
    border-right: none;
    border-top: 12px dotted ${getStyleForProp('background')};
    top: -6px;
  }
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.primaryDark};
`

const MenuItemTop = styled.div`
  display: flex;
  margin: 0 0 4px 0;
  border-bottom: 1px dotted;
  border-color: ${({ theme }) => theme.palette.tertiaryNormal};
  padding: 0 0 6px 0;
  position: relative;
  > .control-container:last-child {
    margin-left: auto;
  }
`

const ItemName = styled.p`
  color: ${getStyleForProp('paragraph')};
  font-weight: bold;
  font-size: 20px;
`

const ItemPrice = styled.p`
  min-width: 60px;
  text-align: right;
  margin-left: auto;
  font-size: 18px;
  font-weight: 500;
  color: ${getStyleForProp('price')};
`

const ItemInfo = styled.p`
  font-size: 12px;
  text-align: right;
  color: ${getStyleForProp('info')};
  white-space: nowrap;
  margin: 0 0 0 4px;
`

const ItemParagraph = styled.p`
  max-width: 80%;
  font-size: 12px;
  color: ${getStyleForProp('price')};
`

const Badge = styled.div`
  word-break: keep-all;
  font-size: 10px;
  color: #fff;
  height: 20px;
  line-height: 20px;
  padding: 0 10px;
  border-radius: ${getStyleForProp('badgeRadius')};
  background-color: ${({ color }) => color};
  div {
    white-space: nowrap;
    font-weight: bold;
  }
`
const Div = styled.div``

const SlideCounter = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  font-size: 16px;
  color: ${getStyleForProp('slideCounter')};
  font-weight: bold;
  display: ${props => (props.isEditing ? 'block' : 'none')};
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
    overflow: hidden;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const MenuContent = styled.div`
  width: 100%;
  ${({ setZindex }) =>
    !setZindex &&
    css`
      z-index: 1;
    `}
`

const BadgeItemWrapper = styled.div`
  margin: 0 8px 0 0;
`

const TitleBadgeWrap = styled.div`
  display: flex;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const SliderItem = styled.div`
  width: 100%;
`

const ImgFigure = styled.div`
  width: 50%;
  max-height: 1100px;
  position: relative;
  font-size: 0;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const ButtonsWrap = styled.div`
  display: flex;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  @media only screen and (max-width: 1024px) {
    bottom: 30px;
  }
`

const BadgeWrapper = styled.div`
  display: flex;
  margin: 0 0 0 10px;
  @media only screen and (max-width: 1400px) {
    margin: 0 0 0 10px;
  }
  @media only screen and (max-width: 992px) {
    margin: 10px 0 0 0;
  }
`

const MenuItemWrapper = styled.div`
  margin: 3vw 0 0 0;
  @media only screen and (max-width: 1400px) {
    margin: 2vw 0 0 0;
  }
  @media only screen and (max-width: 1180px) {
    margin: 1vw 0 0 0;
  }
  @media only screen and (max-width: 1020px) {
    &:first-child {
      margin: 20px 0 0 0;
    }
    & + ${S.MenuItemWrapper} {
      margin: 40px 0 0 0;
    }
  }

  & > .control-container {
    padding-top: 10px;
  }
`

const MenuItem = styled.div``

export default {
  Img,
  Container,
  Count,
  Div,
  MenuContent,
  SliderTitle,
  TextContainer,
  IconContainer,
  ImageContainer,
  DotsWrap,
  SlideContainer,
  SliderParagraph,
  Anchor,
  MenuItemTop,
  ItemName,
  ItemPrice,
  ItemInfo,
  ItemParagraph,
  Badge,
  SlideCounter,
  ControlContainer,
  Icon,
  Info,
  BadgeItemWrapper,
  BadgeWrapper,
  PricingContainer,
  TitleBadgeWrap,
  SliderItem,
  ButtonsWrap,
  ImgFigure,
  MenuItem,
  MenuItemWrapper
}
