/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer7.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '100px 0 50px 0' : '50px 0')};
  background: ${getStyleForProp('background')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .socials-wrapper {
    .control-container:nth-last-child(-n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
    .link {
      width: 26px;
      height: 26px;
      color: ${getStyleForProp('socialColor')};
      fill: ${getStyleForProp('socialColor')};
    }
  }
  .wm-email-input-wrapper {
    max-width: 560px;
    width: 100%;
    flex: 0 0 auto;
  }
  .wm-email-input {
    width: 100%;
    max-width: 560px;
    height: 50px;
  }
`

const Label = styled.label`
  color: ${getStyleForProp('socialColor')};
  ${getTextStyleByAlignment};
  width: 100%;
  display: block;
  margin-bottom: 30px;
`

const FooterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${({ isSocial, isEditing }) =>
    isEditing || isSocial ? 'margin: 48px 0 2px 0' : 'margin-bottom: 2px'};
`

const FormWrapper = styled.div`
  width: 100%;
`

const SubscribeFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 560px;
  margin: auto;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  > .control-container:first-child {
    width: 100%;
    margin-bottom: 20px;
    label {
      margin-bottom: 0;
      &:hover {
        cursor: text;
      }
    }
  }
`

const SubscribeFormWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 560px;
  &:only-child {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    label {
      width: 100%;
    }
  }
  form {
    display: flex;
    justify-content: center;
    transform: translateX(-21px);
    width: 100%;
    max-width: 400px;
    @media only screen and (max-width: 650px) {
      flex-direction: column;
      transform: initial;
      align-items: center;
    }
    input {
      flex: none;
      @media only screen and (max-width: 650px) {
        margin: 0;
      }
    }
    button {
      min-width: 144px;
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      margin-top: 0;
      flex: none;
    }
  }
  @media only screen and (max-width: 650px) {
    .wm-email-btn {
      margin-top: 30px;
    }
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('socialColor')
}))``

const LinkWrap = styled.div`
  position: relative;
  max-width: 170px;
  min-width: 100px;
  margin-left: 20px;
  &:hover {
    a {
      opacity: 0.6;
    }
  }
  @media only screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 30px;
    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  @media only screen and (min-width: 801px) {
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            max-width: calc(100% - 210px);
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 0;
            }
          `
        : ''}
  }
`

const CopyRightWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  &:empty {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`
export default {
  Container,
  SubscribeFormContainer,
  SubscribeFormWrap,
  FooterContent,
  Footer,
  Label,
  FormWrapper,
  Icon,
  LinkWrap,
  Copyright,
  CopyRightWrapper
}
