/* @flow */
import styled, { css } from 'styled-components'
import THEME from './RSVP1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  getCardBGOverlayStyles
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 150px;
          @media only screen and (max-width: 768px) {
            padding-top: 130px;
          }
        `
      : css``};

  flex-direction: column;
  position: relative;
  overflow: hidden;

  .add-button-wrap {
    margin: 10px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const CardWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  width: 100%;
  max-width: 790px;
  padding: 40px;
  box-sizing: border-box;
  border-radius: ${getStyleForProp('cardRadius')};
  ${({ isCardImage }) =>
    !isCardImage
      ? css`
          background-color: ${getStyleForProp('backgroundColor')};
          border: 1px solid ${getStyleForProp('border')};
        `
      : css``};

  @media only screen and (max-width: 457px) {
    padding: 20px;
  }
`
const commonTextStyles = css`
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 20px;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${commonTextStyles};
  word-break: break-word;
`

const Subtitle = styled.p`
  color: ${getStyleForProp('subTitle')};
  ${commonTextStyles};
  margin-bottom: 10px;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${commonTextStyles};
  margin-bottom: 30px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  position: relative;
  .editable-control-container {
    width: 100%;
    margin-bottom: 20px;
    > * {
      margin-bottom: 0;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const ImageWrap = styled.div`
  font-size: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img {
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ImageFigureBG = styled.div`
  width: 100%;
  height: 100%;

  &::after {
    ${getCardBGOverlayStyles(THEME)};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ImageFigure = styled.div`
  width: 100%;
  max-width: 150px;
  margin: 0 auto 20px auto;
  border-radius: ${getStyleForProp('iconRadius')};
  @media only screen and (max-width: 457px) {
    max-width: 100px;
  }
  .LazyLoad {
    position: relative;
    padding-top: 100%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('iconRadius')};
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Date = styled.p`
  width: 100%;
  color: ${getStyleForProp('dateText')};
  ${getTextStyleByAlignment};
`

const Location = styled.a`
  display: block;
  width: 100%;
  ${getTextStyleByAlignment};
  margin-top: 20px;
  color: ${getStyleForProp('paragraph')};
`

const ComponentWrapper = styled.div`
  .form-container {
    background-color: ${getStyleForProp('backgroundColor')};
    .control-container {
      max-width: 790px;
      padding: 40px 0 20px 0;
      margin: 40px auto 50px auto;
      @media only screen and (max-width: 1024px) {
        margin-right: 20px;
        margin-left: 20px;
        > div {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
      @media only screen and (max-width: 457px) {
        margin-right: 10px;
        margin-left: 10px;
        > div {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
  }
`

export default {
  StyledContainer,
  CardWrapper,
  WmCustomContainer,
  Title,
  Subtitle,
  Paragraph,
  Content,
  Img,
  ImageWrap,
  ImageFigure,
  ImageFigureBG,
  Date,
  Location,
  ComponentWrapper
}
