// @flow

import React, { createContext, useState } from 'react'

const HeaderNavBarContext = createContext({
  navBarHeight: 0
})
const { Provider, Consumer } = HeaderNavBarContext

export const provideHeaderNavBarContext = Comp => props => {
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <Provider
      value={{
        navBarHeight,
        setNavBarHeight
      }}
    >
      <Comp {...props} />
    </Provider>
  )
}

export const consumeHeaderNavbarContext = Comp => props => (
  <Consumer>
    {({ navBarHeight, setNavBarHeight }) => (
      <Comp
        {...props}
        navBarHeight={navBarHeight}
        setNavBarHeight={setNavBarHeight}
      />
    )}
  </Consumer>
)

const Wrapper = ({ children }) => children

export const HeaderNavbarContextWrapper = provideHeaderNavBarContext(Wrapper)

export { Consumer }
