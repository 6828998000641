// @flow
import React from 'react'

import ComponentsDropdownItem from './ComponentsDropdown/ComponentsDropdownItem'
import { LINK_TYPE_OPTIONS } from './consts'
export {
  getComponentFromLink,
  getLinkType,
  getPageFromLink
} from '@editor/common/utils/project-links'

export const getLinkTypesDropdownOptions = (hasManyPages: boolean) => [
  LINK_TYPE_OPTIONS.EXTERNAL,
  ...(hasManyPages ? [LINK_TYPE_OPTIONS.TO_PAGE] : []),
  LINK_TYPE_OPTIONS.TO_COMPONENT,
  LINK_TYPE_OPTIONS.PHONE_CALL
]

export const getComponentsDropdownOptions = components =>
  components.map((comp, idx) => {
    const category = comp.category
    const formattedCategory =
      category.charAt(0).toUpperCase() + category.slice(1)
    const title = comp.data?.title
      ? ` / ${comp.data.title.replace(/<[^>]+>/g, '')}`
      : ''
    const label = `${formattedCategory}${title}`
    const value = `comp-${comp.uniqId || idx}`

    return {
      label: <ComponentsDropdownItem label={label} component={comp} />,
      value
    }
  })

export const checkPageExisting = (pages, pagePath) => {
  return pages.findIndex(page => page.path === pagePath) !== -1
}
