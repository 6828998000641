import styled, { css } from 'styled-components'

export const PriceContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const PriceWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-left: 15px;
  margin-top: ${({ isSmallSize }) => (isSmallSize ? '10px' : '0')};
`

export const Currency = styled.p`
  position: absolute;
  top: 0;
  left: -15px;
  color: ${({ isDiscountedPrice }) =>
    isDiscountedPrice ? '#21A363' : '#252e48'};
  font-size: 16px;
  font-weight: 700;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`

export const Price = styled.p`
  font-size: 32px;
  font-weight: 700;

  ${({ isDiscountedPrice }) =>
    isDiscountedPrice
      ? css`
          margin-right: 5px;
          color: #21a363;
        `
      : css`
          color: #252e48;
        `};

  @media only screen and (max-width: 768px) {
    font-size: 24px;
  }
`

export const OldPriceContainer = styled.div`
  position: relative;

  ${({ isInCard }) =>
    isInCard
      ? css`
          display: flex;
          align-items: flex-end;
        `
      : ''};
`

export const OldPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0 5px 0;
  text-decoration: line-through;
  font-weight: 500;
`

export const OldPrice = styled.div`
  color: #252e48;
  font-size: 18px;
  text-decoration: line-through;
`

export const OldPriceCurrency = styled.div`
  color: #252e48;
  font-size: 12px;
`

export const Date = styled.p`
  color: #252e48;
  font-size: 14px;
  margin: 5px 0 5px 5px;
`

export const BilledInfo = styled.p`
  font-size: 14px;
  color: #545f7e;
  padding-top: 5px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`

export const DiscountedPriceBilledInfo = styled(BilledInfo)`
  & > span {
    font-size: 12px;
    text-decoration: line-through;
  }
`
