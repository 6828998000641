/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const changeParagraph1 = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH1',
      value: newParagraph
    })
  }

  const changeParagraph2 = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH2',
      value: newParagraph
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeParagraph1Alignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH1_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraph2Alignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH2_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph1,
    changeParagraph2,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraph1Alignment,
    changeParagraph2Alignment,
    imgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        showRemove: false,
        keyForSizes: 'text21',
        imageUrl
      })
  }
}
