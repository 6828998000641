/* @flow */
import React, { useContext, memo } from 'react'
import S from './Basic3.styled'
import PaperTop from './PaperTop'
import PaperBottom from './PaperBottom'
import { EditingContext } from '@contexts'

const Basic3 = memo(props => {
  const {
    data: { backgroundImgDimensions, backgroundImgUrl, bgOverlayTransparency }
  } = props
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer>
        <PaperTop class="paperTop" />
        <PaperBottom class="paperBottom" />
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Basic3
