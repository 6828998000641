import styled from 'styled-components'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const stylesObj = {
  fr: {
    whiteSpace: 'none'
  },
  jp: {
    whiteSpace: 'none'
  },
  tr: {
    whiteSpace: 'none'
  },
  default: {
    whiteSpace: 'nowrap'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const InputContainer = styled.div`
  width: 100%;
  position: relative;

  .left-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .remove-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      svg {
        fill: #545f7e;
      }
    }
  }
`

export const Input = styled.input`
  font-size: 14px;
  border-radius: 5px;
  height: 40px;
  width: 100%;
  padding: ${({ isActive, isLeftIconExists }) =>
    isLeftIconExists && isActive
      ? '10px 35px 10px 40px'
      : isLeftIconExists
      ? '10px 15px 10px 40px'
      : isActive
      ? '10px 35px 10px 15px'
      : '10px 15px'};
  outline: none;
  box-sizing: border-box;
  border: ${({ hasError, isActive, borderColor }) =>
    `1px solid ${
      hasError ? '#FF4C4C' : isActive ? '#387DFF' : borderColor || '#dde3f0'
    }`};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
    margin: 0;
  }
  &::placeholder {
    color: ${({ isActive, placeholderColor }) =>
      isActive ? 'transparent' : placeholderColor || '#7683a8'};
  }

  &:disabled {
    background-color: #edeff6;
    color: #8493bd;
  }

  &:hover {
    &:not(:disabled) {
      border: ${({ hasError, isActive, borderHoverColor }) =>
        `1px solid ${
          hasError
            ? '#FF4C4C'
            : isActive
            ? '#387DFF'
            : borderHoverColor || '#dde3f0'
        }`};
    }
  }
`

export const ErrorMessage = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  @media only screen and (min-width: 580px) {
    white-space: ${getStylesByLang('whiteSpace')};
  }
  top: 40px;
  bottom: -15px;
  font-size: 10px;
  font-weight: 500;
  color: #ff4c4c;
`

export const Error = styled.div`
  margin-top: 2px;
  @media only screen and (min-width: 580px) {
    white-space: nowrap;
  }
  @media only screen and (max-width: 579px) {
    white-space: ${getStylesByLang('whiteSpace')};
  }
`

export const ErrorIcon = styled(Icon)`
  margin-right: 5px;
`
