/* @flow */
import React, { useState } from 'react'
import { useBackdropClick } from '@hooks'
import If from '../Conditional'
import Mapper from '../Mapper'
import S from './SelectMenu.styled'
import AddButton from '../AddButton'
import { EditingContext } from '@contexts'
import { tertiary } from '@website/common/styles/colors'

const SelectMenu = ({
  options,
  onChange,
  fullWidth,
  rowRenderer,
  onOptionAdd,
  tabIndex = 0,
  showAddButton,
  renderSelected,
  defaultSelectedIdx
}) => {
  const [showMenu, setMenuStatus] = useState(false)
  const selecMenu = useBackdropClick(() => setMenuStatus(false))
  const [selectedIdx, setSelected] = useState(defaultSelectedIdx || 0)

  const _setSelected = (idx: number, option: any) => (
    onChange(idx, option), setSelected(idx)
  )

  const onKeyPress = (e: Event) =>
    e.key === 'Enter' || e.key === 'Space' ? setMenuStatus(!showMenu) : void 0

  const onSubmit = (idx: number, option: any) => (
    _setSelected(idx, option), setMenuStatus(false)
  )

  const _onKeyPress = (idx: number, option: any) => (e: Event) =>
    e.key === 'Enter' || e.key === 'Space' ? onSubmit(idx, option) : void 0

  return (
    <S.MenuWrap
      ref={selecMenu}
      tabIndex={tabIndex}
      fullWidth={fullWidth}
      onKeyPress={onKeyPress}
      className="rf-select-menu-wrap"
    >
      <S.SelectMenu
        show={showMenu}
        fullWidth={fullWidth}
        onClick={() => setMenuStatus(!showMenu)}
      >
        {renderSelected(selectedIdx)}
        <S.Icon
          color={tertiary.Dark}
          name="glyph-arrow_filled_bottom"
          size="large"
          show={showMenu}
          className="arrow-icon"
        />
      </S.SelectMenu>
      <S.SelectMenuWrap show={showMenu}>
        <Mapper
          data={options}
          render={(option, idx) => (
            <S.Menu
              key={idx}
              tabIndex={showMenu ? 0 : -1}
              onKeyPress={_onKeyPress(idx, option)}
              onClick={() => onSubmit(idx, option)}
            >
              {rowRenderer(option, idx)}
            </S.Menu>
          )}
        />
        <EditingContext.Consumer>
          {({ isEditing }) => (
            <If
              condition={isEditing && showAddButton}
              then={() => (
                <S.AddButtonContainer>
                  <AddButton toShow={true} onAdd={onOptionAdd}>
                    +
                  </AddButton>
                </S.AddButtonContainer>
              )}
            />
          )}
        </EditingContext.Consumer>
      </S.SelectMenuWrap>
    </S.MenuWrap>
  )
}

export default SelectMenu
