/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature15_2.theme'
import {
  styleProvider,
  getBackground,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : ' 80px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > div:first-child {
    padding-top: 0;
  }
`
const HeadingWrap = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  > p:first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h2 {
      padding-top: 0;
    }
  }
`

const HeadingParagraph = styled.div`
  > p:first-child,
  > h2:first-child {
    padding-top: 0;
  }
`

const BlockContentWrap = styled.div`
  width: 100%;
  padding: 34px 30px;
  box-sizing: border-box;
  @media only screen and (max-width: 380px) {
    padding: 34px 22px;
  }
  & > .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  width: 100%;
`

const SubTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  font-weight: 400;
  width: 100%;
  padding-top: 20px;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  font-weight: 400;
  width: 100%;
  padding-top: 20px;
`

const BlocksWrap = styled.div`
  width: 100%;
  max-width: ${({ isTwoBlock }) => (isTwoBlock ? '800px' : '1400px')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: -45px;
  padding-top: 45px;
  position: relative;
  @media only screen and (max-width: 1020px) {
    flex-direction: column;
  }

  .block-img-wrap {
    width: 100%;
    font-size: 0;
    position: relative;

    & > .control-container {
      position: absolute;
      bottom: -4px;
      left: 10px;
      width: calc(100% - 20px);
      .count {
        position: static;
      }
    }
  }
`

const BlockItemWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ withoutParagraph }) =>
    withoutParagraph ? 'transparent' : '#fff'};
  text-align: center;
  flex-grow: 1;
  max-width: 370px;
  width: 30%;
  box-sizing: border-box;
  position: relative;
  margin: 45px 1.5vw 0 1.5vw;
  & > .control-container {
    height: 100%;
  }

  .lazy-figure {
    height: initial;
    padding-top: 100%;
    ${props =>
    props.withoutParagraph
      ? css`
            border-radius: ${getStyleForProp('borderRadius')(props)};
          `
      : css`
            border-top-left-radius: ${getStyleForProp('borderRadius')};
            border-top-right-radius: ${getStyleForProp('borderRadius')};
          `};
  }
  @media only screen and (max-width: 1440px) {
    max-width: 340px;
  }
  @media only screen and (max-width: 1020px) {
    width: 100%;
    margin: 50px auto 0 auto;
  }
`

const Badge = styled.div`
  padding: 0 24px;
  height: 60px;
  box-sizing: border-box;
  border-radius: ${getStyleForProp('borderRadius')};
  background: ${getStyleForProp('badgeBg')};
  position: absolute;
  top: 14px;
  left: -14px;
  z-index: 1;
  display: flex;
  align-items: center;
`

const BadgeText = styled.p`
  color: #fff;
  font-weight: bold;
  font-size: 20px;
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const CtaWrapContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 60px 0 0 0;
`

const CtaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  background: ${props => {
    const bgLeft = getStyleForProp('bgLeft')(props)
    const bgRight = getStyleForProp('bgRight')(props)
    return `linear-gradient(to right, ${bgLeft},${bgRight})`
  }};
  border-radius: ${getStyleForProp('borderRadius')};
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
  .control-container {
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const CtaText = styled.p`
  color: ${({ theme }) => theme.palette.accentWhite};
  font-weight: 800;
  width: 100%;
  ${getTextStyleByAlignment};
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const Count = styled.p`
  font-size: 72px;
  font-weight: 700;
  color: #fff;
  width: 100%;
  ${getTextStyleByAlignment};
  position: absolute;
  bottom: -4px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  &::after {
    color: #6375a9 !important;
    font-weight: 300 !important;
  }
`
const BtnWrap = styled.div`
  margin: 0 0 0 20px;
  &:only-child {
    margin: 0 auto;
  }
  @media only screen and (max-width: 768px) {
    margin: 20px 0 0 0;
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    background: #fff;
    color: ${getStyleForProp('buttonColor')};
    text-decoration: none;
    max-width: 260px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`
export default {
  StyledContainer,
  WmCustomContainer,
  HeadingWrap,
  HeadingParagraph,
  BlockContentWrap,
  Title,
  SubTitle,
  Description,
  BlocksWrap,
  BlockItemWrap,
  Badge,
  Count,
  BadgeText,
  BlockParagraph,
  CtaWrapContainer,
  CtaWrap,
  CtaText,
  BtnWrap,
  Img,
  ControlContainer
}
