//@flow
import React, { memo, useMemo } from 'react'

import Popup from '@website/common/components/Popup'
import { generateGoogleCalendarEventUrl } from '@website/common/utils/event'

import PopupContent from './PopupContent'
import { TRSVPRegistrationPopupProps } from './types'

const RSVPRegistrationPopup = ({
  isPopupOpen,
  onClose,
  title,
  paragraph,
  buttonText,
  showButton,
  detailsData
}: TRSVPRegistrationPopupProps) => {
  const buttonUrl = useMemo(
    () =>
      detailsData
        ? generateGoogleCalendarEventUrl({
            eventTitle: detailsData.title.text,
            eventDescription: detailsData.paragraph.text,
            startDate: `${detailsData.startDateInfo.date} ${detailsData.startDateInfo.time}`,
            endDate: detailsData.endDateInfo.isVisible
              ? `${detailsData.endDateInfo.date} ${detailsData.endDateInfo.time}`
              : '',
            location: detailsData.location.isVisible ? detailsData.location.url : '',
            timezone: detailsData.timezone.value
          })
        : '',
    [detailsData]
  )

  return (
    <Popup isOpen={isPopupOpen} onClose={onClose} isMobileFullScreen={false}>
      <PopupContent
        title={title}
        paragraph={paragraph}
        buttonText={buttonText}
        showButton={showButton}
        buttonUrl={buttonUrl}
      />
    </Popup>
  )
}

export default memo(RSVPRegistrationPopup)
