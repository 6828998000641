// @flow

import React, { useState, useCallback, memo } from 'react'
import SingleMessenger from './SingleMessenger'
import * as Styled from './styled'
import { OPENER_BUTTON_ICONS } from './consts'
import { useBackdropClick } from '@hooks'

const Messenger = ({
  isOpen: propsIsOpen = false,
  isForPreview = false,
  alignment,
  type,
  data
}) => {
  const [isOpen, setOpenedState] = useState(propsIsOpen)

  const toggleMessenger = useCallback(() => {
    setOpenedState(!isOpen)
  }, [isOpen])

  const closeMessenger = useCallback(() => {
    setOpenedState(false)
  }, [])

  const messengerRef = !isForPreview ? useBackdropClick(closeMessenger) : null

  return (
    <Styled.Messenger
      isOpen={isOpen}
      type={type}
      alignment={alignment}
      isForPreview={isForPreview}
      ref={messengerRef}
    >
      <Styled.MessengerFlyout className="site-messenger-flyout">
        <SingleMessenger type={type} {...data} onClose={closeMessenger} />
      </Styled.MessengerFlyout>
      <Styled.OpenerButton onClick={toggleMessenger}>
        <Styled.OpenerIcon
          alt="messenger_opener"
          src={OPENER_BUTTON_ICONS[type]}
        />
      </Styled.OpenerButton>
    </Styled.Messenger>
  )
}

export default memo(Messenger)
