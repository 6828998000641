/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer4.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '140px 0 60px 0' : '60px 0')};
  background-color: ${getStyleForProp('background')};
  @media only screen and (max-width: 1024px) {
    padding: ${({ isEditing }) => (isEditing ? '90px 0 50px 0' : '50px 0')};
  }
  .socials-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    &:only-child {
      justify-content: center;
      width: 100%;
    }
    @media only screen and (max-width: 1024px) {
      margin: 30px 50px;
      .control-container:nth-last-child(-n + 3) {
        .styled-controls {
          right: 0;
          left: initial;
        }
      }
    }
  }
  .socials-wrapper {
    .link {
      fill: #ffffff;
      border-radius: ${getStyleForProp('iconRadius')};
      ${getStyleForProp('buttonBackground')};
    }
  }
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  .control-container {
    margin-top: 20px;
    & > p {
      margin-top: 0;
    }
  }
`

const Paragraph = styled.p`
  font-size: 12px;
  color: ${getStyleForProp('paragraph')};
  max-width: 350px;
  margin-top: 14px;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`

const FooterContentWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

const SocialBadgeWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 50px 0;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

const BadgesWrap = styled.div`
  display: flex;
  margin: 0 0 0 -10px;
  a {
    margin: 0 10px;
  }
  @media only screen and (max-width: 420px) {
    flex-direction: column;
    justify-content: center;
  }
`

const FooterContent = styled.div`
  margin-right: auto;
  min-width: 280px;
  margin-right: 30px;
  text-align: left;
  @media only screen and (max-width: 1024px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .logo-wrapper {
    display: block;
    position: relative;
    @media only screen and (min-width: 1025px) {
      margin-right: 20px;
    }
  }
  .add-button-wrap {
    display: flex;
    justify-content: flex-start;
    @media only screen and (max-width: 1024px) {
      justify-content: center;
    }
  }
`

const FooterLogo = styled.img.attrs(({ _width }) => {
  return _width
    ? {
        style: { width: `${_width}px` }
      }
    : {}
})`
  object-fit: contain;
  height: auto;
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  color: ${getStyleForProp('paragraph')};
  @media only screen and (min-width: 801px) {
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            max-width: calc(100% - 210px);
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 0;
            }
          `
        : ''}
  }
`

const LinksWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  &:only-child {
    width: 100%;
    justify-content: center;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 30px;
  }
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
`

const LinkWrap = styled.div`
  position: relative;
  margin-left: 10px;
  max-width: 170px;
  &:hover {
    a {
      opacity: 0.6;
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          .control-container {
            margin-left: 10px;
          }
        `
      : css`
          .link-wrapper {
            margin-left: 10px;
          }
        `}

  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 30px;

    .control-container {
      margin-left: 0;
    }

    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
`

const CopyRightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
`

export default {
  Container,
  FooterContentWrap,
  FooterContent,
  FooterLogo,
  Copyright,
  Paragraph,
  SocialBadgeWrap,
  BadgesWrap,
  FooterContainer,
  LinksWrap,
  CopyRightWrapper,
  LinkWrap
}
