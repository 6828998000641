import styled from 'styled-components'

const ICON_SIZE = 24

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  cursor: default;
  width: 100%;
`

export const Title = styled.h3`
  padding: 15px 15px 10px;
  font-size: 12px;
  font-weight: 700;
  color: #252e48;
`

export const Icon = styled.span`
  z-index: 1;
  width: ${ICON_SIZE}px;
  height: ${ICON_SIZE}px;
  background-color: #eef5ff;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  svg * {
    fill: #7683a8;
  }

  @media only screen and (max-width: 1024px) {
    & > svg {
      width: 20px;
      height: auto;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`

export const LanguageWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    & svg * {
      fill: #363f5a;
    }
    background: #eef5ff;
  }
`

export const Language = styled.span`
  font-size: 14px;
  color: #545f7e;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
  margin: 0 10px;
`

export const OpenIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 1px;
  & > svg {
    fill: #545f7e;
    transition: transform 0.3s ease;
    transform: rotate(${({ isOpen }) => (isOpen ? 0 : 180)}deg);
  }
`
