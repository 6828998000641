import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectFontPair } from '@actions/editor'
import bringIntoViewport from '@editor/common/utils/bringIntoViewport'
import { getSemrushDependedScreenBreakpoint } from '@editor/common/utils/styled'
import { selectSemrushSidebarVisibilityState } from '@selectors'
import { useResponsive } from '@shared/hooks'

// import NewBadge from '../NewBadge/NewBadge'
import * as Styled from './styled'

const FontPair = ({ fontPair, isSelected, scrollableContainerRef }) => {
  const dispatch = useDispatch()

  const isSemrushSidebarVisible = useSelector(
    selectSemrushSidebarVisibilityState
  )

  const semrushSidebarBreakpoint = getSemrushDependedScreenBreakpoint(
    800,
    isSemrushSidebarVisible
  )

  const isMobile = useResponsive(semrushSidebarBreakpoint)

  const onClick = useCallback(
    e => {
      dispatch(selectFontPair(fontPair))

      if (!scrollableContainerRef.current) return

      const options = {
        horizontalMargin: isMobile ? 0 : 100,
        extraScrollOffset: 40,
        isVerticalScroll: !isMobile
      }

      bringIntoViewport(
        e.currentTarget,
        scrollableContainerRef.current,
        options
      )
    },
    [fontPair, scrollableContainerRef, selectFontPair, isMobile]
  )

  return (
    <Styled.Font
      id={fontPair.primary}
      onClick={onClick}
      isSelected={isSelected}
    >
      <Styled.FontTextsWrapper>
        <Styled.FontText
          type="primary"
          font={fontPair.primary}
          letterCase={fontPair.letterCase}
        >
          {fontPair.name}
        </Styled.FontText>
        <Styled.FontText type="secondary" font={fontPair.secondary}>
          Paragraph text
        </Styled.FontText>
      </Styled.FontTextsWrapper>
      {/*<NewBadge />*/}
    </Styled.Font>
  )
}

export default memo(FontPair)
