/* @flow */
import styled, { css } from 'styled-components'
import Container from '@website/common/components/Container'
import THEME from './Testimonials3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  .slick-slider-container {
    max-width: 1000px;
  }
  .slick-slide > div {
    margin: 0 25px;
  }

  .dots-wrap {
    bottom: 0;
  }
  .control-container {
    width: 100%;
  }
`

const TextContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  @media only screen and (max-width: 650px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const SliderParagraph = styled.p`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const SliderTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  margin-bottom: ${({ isEditing }) => (isEditing ? '80px;' : '60px;')};
  width: 100%;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 650px) {
    margin-bottom: ${({ isEditing }) => (isEditing ? '100px;' : '60px;')};
  }
`

const IconContainer = styled.button`
  padding: 0;
  border: none;
  width: 50px;
  min-width: 50px;
  margin: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  ${getStyleForProp('buttonBackground')};
  @media only screen and (max-width: 760px) {
    display: none;
  }
`

const Dot = styled.button`
  padding: 0;
  border: none;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: 1px solid #5690ff;
  }
`

const SliderWrapper = styled.div`
  margin: 0;
  padding-top: ${({ isEditing }) => (isEditing ? '50px' : '0')};
  @media only screen and (max-width: 650px) {
    margin: ${({ isEditing }) => (isEditing ? '0 30px 14px 30px;' : '0')};
  }
`

const ImageSlideContainer = styled.div`
  min-height: 300px;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: ${({ isEditing }) => (isEditing ? 'center' : 'flex-start')};
  justify-content: center;
  ${({ isEditing }) =>
    isEditing
      ? css`
          width: 90%;
          max-width: 1000px;
        `
      : css`
          width: 1000%;
          max-width: 100%;
        `};

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  & > svg {
    opacity: 0.3;
    flex: none;
    position: absolute;
    top: ${({ isEditing }) => (isEditing ? '120px' : '90px')};
    left: 50%;
    transform: translateX(-50%);
    @media only screen and (max-width: 600px) {
      width: 160px;
      top: ${({ isEditing }) => (isEditing ? '100px' : '74px')};
    }
    .a {
      fill: ${({ theme }) => theme.palette.primaryDark};
    }
  }

  .slider-arrow-control {
    position: relative;
    z-index: 3;
  }
`

const SliderArrow = styled.button`
  display: ${({ isEditing }) => (isEditing ? 'flex' : 'none')};
  width: 42px;
  min-width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #c1c9e0;
  box-shadow: 0 6px 12px 0 #3271e626;

  & > svg {
    fill: #545f7e;
  }

  ${({ isRight }) =>
    isRight
      ? css`
          & > svg {
            transform: rotate(180deg);
          }
        `
      : ''}

  &:hover {
    & > svg {
      fill: #252e48;
    }
  }

  @media only screen and (max-width: 650px) {
    &.wm-arrow {
      position: absolute;
      top: 0px;
      z-index: 3;
      left: 52%;
    }
    &.left,
    &.right {
      transform: translate(-15px, 0);
    }
  }
  @media only screen and (max-width: 457px) {
    &.left {
      transform: translate(-10px, 0);
    }
    &.right {
      transform: translate(-30px, 0);
    }
  }
`

export default {
  StyledContainer,
  SliderParagraph,
  SliderTitle,
  TextContainer,
  Dot,
  IconContainer,
  SliderWrapper,
  ImageSlideContainer,
  SliderArrow
}
