/* @flow */
/* eslint-disable max-statements */
import {
  buttonModalActionMaker,
  cropableImageModalActionMaker,
  socialActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'
import {
  changeSubTitle,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addButton,
  addSocial,
  addLink,
  addIcon
} from '@website/components/CommonActions'

export default dispatch => {
  const changeNewsTitle = (newTitle: string) => ({
    type: 'CHANGE_NEWS_TITLE',
    value: newTitle
  })

  const changeNewsParagraph = (newParagraph: string) => ({
    type: 'CHANGE_NEWS_PARAGRAPH',
    value: newParagraph
  })

  const removeSlide = slideIdx => ({
    type: 'REMOVE_SLIDE',
    value: { slideIdx }
  })

  const addSlide = () => ({
    type: 'ADD_SLIDE'
  })

  const _addLink = () => dispatch(addLink())

  const _addSlide = () => dispatch(addSlide())

  const _addIcon = idx => {
    dispatch(addIcon(idx))
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeSubTitle = newSubTitle => {
    dispatch(changeSubTitle(newSubTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeNewsTitle = newTitle => {
    dispatch(changeNewsTitle(newTitle))
  }

  const _changeNewsParagraph = newParagraph => {
    dispatch(changeNewsParagraph(newParagraph))
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeNewsTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_NEWS_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeNewsParagraphAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_NEWS_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  const _addSocial = () => dispatch(addSocial())

  const sliderActions = ({ showRemove, idx, clickHandler, imageUrl }) =>
    cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'header37SliderImages',
      clickHandlers: {
        change: clickHandler,
        crop: clickHandler
      },
      showRemove,
      imageUrl,
      additionalArgs: {
        idx
      },
      actions: {
        change: ({ url, dimensions, undoable }) => ({
          type: 'CHANGE_SLIDER_IMAGE',
          value: { idx, url, dimensions },
          undoable
        }),
        remove: () => ({
          type: 'REMOVE_SLIDER_IMAGE',
          value: idx
        }),
        crop: ({ imgCropParams, undoable }) => ({
          type: 'SET_SLIDER_IMG_CROP_PARAMS',
          value: { imgCropParams, idx },
          undoable
        })
      }
    })

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSubTitle: _changeSubTitle,
    changeNewsTitle: _changeNewsTitle,
    changeSubTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNewsParagraph: _changeNewsParagraph,
    changeNewsParagraphAlignment,
    changeNewsTitleAlignment,
    addButton: () => dispatch(addButton()),
    addLink: _addLink,
    addIcon: _addIcon,
    addSlide: _addSlide,
    addSocial: _addSocial,
    removeSlide,
    sliderActions: sliderActions,
    linkActions: linkActionMaker(dispatch),
    socialActions: socialActionMaker(dispatch),
    btnActions: buttonModalActionMaker(dispatch),
    imgActions: imageUrl =>
      cropableImageModalActionMaker(dispatch)({
        showRemove: false,
        keyForSizes: 'header37newsImage',
        imageUrl
      })
  }
}
