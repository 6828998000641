/* @flow */
import React, { useContext, useState, useEffect, memo } from 'react'
import S from './Header50.styled'
import THEME from './Header50.theme'
import { validation, ANIMATION_SPEED } from './Header50.consts'
import { countUp } from './Header50.utils'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import NavBar from '@website/common/components/NavBar'
import Icon from '@website/common/components/Icon'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import Wave from './Header50_morph'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Socials from '@website/common/components/Socials'
import { translate } from '@editor/common/utils/translations'

const Header50 = memo(
  ({
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      additionalText,
      additionalUrl,
      blocks,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      headerSocials,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const [navBarHeight, setNavBarHeight] = useState(0)

    useEffect(() => {
      if (!isEditing) {
        countUp(ANIMATION_SPEED)
      }
    }, [])

    return (
      <S.StyledContainer isEditing={isEditing}>
        <S.Background
          backgroundImgUrl={backgroundImgUrl}
          backgroundImgDimensions={backgroundImgDimensions}
          bgOverlayTransparency={bgOverlayTransparency}
          isEditing={isEditing}
        />
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
          linksProps={{
            additionalContent:
              isEditing || headerSocials.length !== 0
                ? () => (
                    <Socials
                      className="header-socials"
                      data={headerSocials}
                      componentDispatcher={dispatcher}
                    />
                  )
                : null
          }}
        />
        <S.WMCustomContainer topOffset={navBarHeight} isEditing={isEditing}>
          <S.ContentWrapper bgOverlayTransparency={bgOverlayTransparency}>
            <S.WaveWrapper>
              <Wave />
            </S.WaveWrapper>
            <S.BottomContentWrapper>
              <S.ContentContainer>
                <S.LeftContent>
                  <EditableContent
                    text={title}
                    alignment={titleAlignment}
                    as={S.Title}
                    required={!paragraph}
                    maxCount={validation.headingMaxChar}
                    isEditing={isEditing}
                    className="WM_GLOBAL_heading42"
                    data-text={title}
                    onChange={Actions.changeTitle}
                    changeAlignment={Actions.changeTitleAlignment}
                  />
                  <EditableContent
                    text={paragraph}
                    alignment={paragraphAlignment}
                    as={S.Paragraph}
                    required={!title}
                    maxCount={validation.paragraphMaxChar}
                    className="WM_GLOBAL_paragraph18"
                    onChange={Actions.changeParagraph}
                    changeAlignment={Actions.changeParagraphAlignment}
                  />
                  <AdditionalLink
                    index={null}
                    additionalLinkText={additionalText}
                    additionalLinkUrl={additionalUrl}
                  />
                </S.LeftContent>
                <Show when={[blocks.length > 0]}>
                  <S.RightContent>
                    <S.BlocksWrap
                      isEditing={isEditing}
                      areTwoBlock={blocks.length === 2}
                    >
                      {blocks.map((block, idx) => {
                        const showRemove = blocks.length > validation.blocks.min
                        return (
                          <S.ControlContainer key={idx} isEditing={isEditing}>
                            <ControlsWithModalOpener
                              actions={Actions.blockActions({
                                idx,
                                showRemove
                              })}
                            >
                              <S.ItemWrapper isEditing={isEditing}>
                                <Show when={[block.blockTitle]}>
                                  <S.TitleWrapper>
                                    <EditableContent
                                      areControlsHidden
                                      text={block.blockTitle}
                                      data-target={block.blockTitle}
                                      as={S.BlockTitle}
                                      required={!block.blockParagraph}
                                      alignment={block.blockTitleAlignment}
                                      maxCount={validation.blockTitleMaxChar}
                                      className="WM_GLOBAL_heading42 counting-numbers"
                                      onChange={(newTitle: string) => {
                                        Actions.changeBlockTitle(newTitle, idx)
                                      }}
                                      isIcon={block.iconName}
                                      isEditing={isEditing}
                                      changeAlignment={(alignment: string) =>
                                        Actions.changeBlockTitleAlignment(
                                          alignment,
                                          idx
                                        )
                                      }
                                    />
                                    <S.HiddenTitle className="WM_GLOBAL_heading42">
                                      {block.blockTitle}
                                    </S.HiddenTitle>
                                  </S.TitleWrapper>
                                </Show>
                                <EditableContent
                                  isEditing={isEditing}
                                  text={block.blockParagraph}
                                  as={S.BlockParagraph}
                                  required={!block.blockTitle}
                                  alignment={block.blockParagraphAlignment}
                                  maxCount={validation.blockParagraphMaxChar}
                                  className="WM_GLOBAL_paragraph18"
                                  onChange={(newParagraph: string) => {
                                    Actions.changeBlockParagraph(
                                      newParagraph,
                                      idx
                                    )
                                  }}
                                  changeAlignment={(alignment: string) =>
                                    Actions.changeBlockParagraphAlignment(
                                      alignment,
                                      idx
                                    )
                                  }
                                />
                              </S.ItemWrapper>
                            </ControlsWithModalOpener>
                          </S.ControlContainer>
                        )
                      })}
                    </S.BlocksWrap>
                    <AddButton
                      onAdd={Actions.addBlock}
                      toShow={blocks.length < validation.blocks.max}
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      {translate('add_block_label')}
                    </AddButton>
                  </S.RightContent>
                </Show>
              </S.ContentContainer>
            </S.BottomContentWrapper>
          </S.ContentWrapper>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Header50
