// @flow

import React, { useContext } from 'react'

import If from '@website/common/components/Conditional'
import * as Styled from './styled'
import type { TControlItemProps } from './types'
import { MobileDetectContext } from '@contexts'
import TooltipWrapper from '@website/common/components/TooltipWrapper'

const ControlLabel = ({ label }) => (
  <Styled.ControlLabel>{label}</Styled.ControlLabel>
)

export default ({
  label,
  tooltipText,
  IconComp,
  renderer,
  toShow = true,
  handler,
  onClick
}: TControlItemProps): React$Node => {
  const isMobile = useContext(MobileDetectContext)

  if (!toShow) {
    return null
  }

  const clickHandler = e => {
    e.stopPropagation()
    if (typeof onClick === 'function') {
      onClick(e)
    }
    handler()
  }

  const renderControlItem = (): React$Node => (
    <TooltipWrapper
      text={isMobile ? '' : tooltipText}
      position="top"
      color="#252E48"
    >
      <Styled.ControlItem onClick={clickHandler}>
        {IconComp ? <IconComp /> : <ControlLabel label={label} />}
      </Styled.ControlItem>
    </TooltipWrapper>
  )

  const externalRenderer = () => renderer({ handler })

  return (
    <If
      condition={typeof renderer === 'function'}
      then={externalRenderer}
      otherwise={renderControlItem}
    />
  )
}
