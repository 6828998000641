import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    heading: p => p.accentWhite,
    blockText: p => p.accentWhite,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    cardBg: p => `rgba(${hexToRgb(p.primaryDark)}, 0.8)`,
    cardBgLight: p => `rgba(${hexToRgb(p.primaryLight)}, 0.8)`,
    navbarBg: p => p.tertiaryDark
  },
  LIGHT: {
    blockText: p => p.accentWhite,
    heading: p => p.accentWhite,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    cardBg: p => `rgba(${hexToRgb(p.primaryDark)}, 0.8)`,
    cardBgLight: p => `rgba(${hexToRgb(p.primaryLight)}, 0.8)`,
    navbarBg: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    blockText: p => p.accentWhite,
    heading: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    cardBg: p => `rgba(${hexToRgb(p.primaryDark)}, 0.8)`,
    cardBgLight: p => `rgba(${hexToRgb(p.primaryLight)}, 0.8)`,
    navbarBg: p => p.tertiaryExtraLight
  }
}
