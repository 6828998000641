//@flow
import React, { memo, useMemo } from 'react'

import * as Styled from './styled'
import type { TCountriesSearchDropdownProps } from './types'
import { COUNTRIES_DATA } from './consts'
import { getDropdownItems } from './utils'
import { translate } from '@editor/common/utils/translations'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { SearchDropdown } from '@renderforest/rf-ui-library/molecules/CustomizableDropdown/SearchDropdown'

const CountriesSearchDropdown = ({
  activeValue,
  setActiveValue
}: TCountriesSearchDropdownProps) => {
  const activeItem = useMemo(
    () => COUNTRIES_DATA.find(countryData => countryData.iso === activeValue),
    [activeValue]
  )

  return (
    <Styled.SearchDropdownWrapper>
      <TooltipWrapper text="Database">
        <>
          <SearchDropdown
            activeItem={{
              label: activeItem.iso,
              value: activeItem.name
            }}
            dropdownList={getDropdownItems(COUNTRIES_DATA)}
            noResultMessage={translate('dropdown_no_results_found_message')}
            onListItemClick={setActiveValue}
            className="countries-search-dropdown"
            dropdownTogglerIcon="arrow_filled_bottom"
            isActiveItemWithCheckmark={false}
            closeDropDownBySelecting
            listItemHeight={40}
            dropdownWidth="100%"
            listMaxHeight={345}
          />
          <Styled.ActiveCountryFlag
            src={`https://static.rfstat.com/${activeItem.flag}`}
            alt={activeItem.name}
          />
        </>
      </TooltipWrapper>
    </Styled.SearchDropdownWrapper>
  )
}

export default memo(CountriesSearchDropdown)
