/* @flow */
import {
  imageModalActionMaker,
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockTitle = (title: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { title, idx }
    })
  }
  const changeBlockParagraph = (paragraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { paragraph, idx }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeStepDescriptionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_STEP_DESCRIPTION_ALIGNMENT',
      value: { alignment, idx }
    })
  }


  return {
    addBlock: () => {
      dispatcher(addBlock())
    },
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitle,
    changeBlockParagraph,
    changeStepDescriptionAlignment,
    iconActions: iconActionMaker(dispatcher),
    imageActions: imageModalActionMaker(dispatcher),
    stepActions: removeBlockActionMaker(dispatcher)
  }
}
