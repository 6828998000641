import React from 'react'

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="108"
    height="102"
    viewBox="0 0 108 102"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M98.4416 0.158957C98.9062 -0.139707 99.5249 -0.00521988 99.8236 0.459342C105.662 9.54016 105.822 23.3772 97.4845 36.0033C94.6741 40.2594 90.9035 44.3743 86.0647 48.1321C91.3616 52.2973 95.586 56.9295 98.4679 62.4499C103.292 71.6906 104.27 83.2509 100.529 98.9349L106.242 95.5878C106.719 95.3086 107.331 95.4686 107.611 95.9452C107.89 96.4217 107.73 97.0343 107.253 97.3135L99.4878 101.863C99.0113 102.142 98.3987 101.982 98.1195 101.506L93.57 93.7402C93.2908 93.2636 93.4508 92.651 93.9273 92.3718C94.4039 92.0927 95.0165 92.2527 95.2957 92.7292L98.6057 98.379C102.252 83.0315 101.213 72.0304 96.6949 63.3755C93.8987 58.0189 89.7379 53.4968 84.4064 49.3753C74.9644 56.2091 61.7883 61.7255 44.1842 64.5374C26.5926 67.3474 14.4031 64.7896 7.32363 59.7724C0.18426 54.7127 -1.68855 47.1645 1.50058 40.5576C4.6548 34.023 12.6229 28.6786 24.6 27.4862C36.5952 26.2921 52.7035 29.2374 72.4268 39.3577L72.4389 39.364L72.4509 39.3705C76.8296 41.7706 80.8427 44.2341 84.4164 46.8759C89.3016 43.1523 93.0567 39.0792 95.8155 34.9013C103.785 22.8328 103.488 9.85716 98.1412 1.5409C97.8426 1.07634 97.9771 0.457621 98.4416 0.158957ZM82.7266 48.1175C79.3852 45.6917 75.6295 43.394 71.5016 41.1309C52.0199 31.1368 36.3042 28.331 24.7981 29.4764C13.2716 30.6239 6.06051 35.7117 3.30172 41.427C0.577845 47.07 2.05823 53.5895 8.48006 58.1406C14.9618 62.7341 26.5414 65.3302 43.8688 62.5624C60.9123 59.84 73.6171 54.5709 82.7266 48.1175Z"
      fill="none"
    />
  </svg>
)

export default Arrow
