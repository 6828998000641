import produce from 'immer'

import { COOKIE_SETTINGS_INITIAL_DATA } from '../consts'

export default (state = COOKIE_SETTINGS_INITIAL_DATA, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_COOKIE_SETTINGS':
        draft.isLoading = false
        draft.data = action.value
        break
      case 'SET_COOKIE_LOADING_STATUS':
        draft.isLoading = action.value
        break
      default:
        return state
    }
  })
