/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_ICON':
        {
          const { idx, iconName } = action.value
          draft.blocks[idx].iconName = iconName
        }
        return
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        return
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { idx, newParagraph, key } = action.value
        draft.blocks[idx][key] = newParagraph
        return
      }
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        return
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        return
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.blocks[idx].blockTitleALignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignmentKey, alignment } = action.value
        draft.blocks[idx][alignmentKey] = alignment
        break
      }
    }
  })
}
