/* @flow */
import React, { useContext, useCallback, memo, useMemo, useState } from 'react'
import S from './Text19.styled'
import { validation } from './Text19.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'

const DISTANCE_PER_SECOND = 80 // pixels
const getPossibleItemsCount = (containerWidth, itemWidth) =>
  containerWidth / itemWidth

const getAnimationSpeed = itemWidth => itemWidth / DISTANCE_PER_SECOND

const Text19 = memo(props => {
  const {
    data: {
      paragraph,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency,
      isLinkControlHidden,
      icon
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const [itemWidth, setItemWidth] = useState(0)

  const windowWidth = typeof window !== 'undefined' && window.innerWidth

  const handleItemRefChange = useCallback(el => {
    if (!el) return
    setItemWidth(el.offsetWidth)
  }, [])

  const renderedItemsCount = useMemo(() => {
    if (!itemWidth) {
      return 0
    }

    const possibleItemsCount = getPossibleItemsCount(windowWidth, itemWidth)

    if (possibleItemsCount <= 1) return 2
    return Math.ceil(possibleItemsCount) + 2
  }, [itemWidth])

  const animationSpeed = useMemo(
    () => getAnimationSpeed(itemWidth),
    [itemWidth]
  )

  const renderItems = useCallback(() => {
    const items = []
    items.length = renderedItemsCount
    return items.fill(null).map(() => (
      <S.ItemText
        isEditing={isEditing}
        animationSpeed={animationSpeed}
        className="item-box"
      >
        <If
          condition={icon}
          then={() => (
            <S.IconWrap>
              <ControlsWithModalOpener>
                <Icon size="large" color="white" name={icon} />
              </ControlsWithModalOpener>
            </S.IconWrap>
          )}
        />
        <S.Description
          className="WM_GLOBAL_heading20"
          isEditing={isEditing}
          dangerouslySetInnerHTML={{ __html: paragraph }}
        />
      </S.ItemText>
    ))
  }, [renderedItemsCount, paragraph, isEditing, animationSpeed])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WMCustomContainer isEditing={isEditing}>
        <S.AnimationContainer isEditing={isEditing}>
          {isEditing ? (
            <S.ItemText isEditing={isEditing}>
              <If
                condition={icon}
                otherwise={() => (
                  <AddButton
                    onAdd={Actions.addIcon}
                    style={{ marginRight: '1rem' }}
                    type="icon"
                    large
                    toShow
                  >
                    +
                  </AddButton>
                )}
                then={() => (
                  <S.IconWrap isEditing={isEditing}>
                    <ControlsWithModalOpener
                      actions={Actions.getIconActions(0)}
                    >
                      <Icon size="large" color="white" name={icon} />
                    </ControlsWithModalOpener>
                  </S.IconWrap>
                )}
              />

              <EditableContent
                isLinkControlHidden
                text={paragraph}
                required
                isEditing={isEditing}
                as={S.Description}
                maxCount={validation.maxParagraphChar}
                className="WM_GLOBAL_heading20"
                onChange={Actions.changeParagraph}
              />
            </S.ItemText>
          ) : (
            <>
              <S.ItemTextOpacity className="opacity" ref={handleItemRefChange}>
                <If
                  condition={icon}
                  then={() => (
                    <S.IconWrap>
                      <ControlsWithModalOpener>
                        <Icon size="large" color="white" name={icon} />
                      </ControlsWithModalOpener>
                    </S.IconWrap>
                  )}
                />
                <S.DescriptionOpacity
                  className="WM_GLOBAL_heading20"
                  dangerouslySetInnerHTML={{ __html: paragraph }}
                />
              </S.ItemTextOpacity>
              {renderItems()}
            </>
          )}
        </S.AnimationContainer>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Text19
