import React from 'react'

import * as Styled from './styled'
import { PublishButton, PreviewButton } from '../components'

const Buttons = ({ isPublishButtonVisible }) => {
  return (
    <Styled.ButtonsWrapper>
      <PreviewButton />
      {isPublishButtonVisible && <PublishButton />}
    </Styled.ButtonsWrapper>
  )
}

export default Buttons
