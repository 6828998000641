/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Team2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 782px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
  }
`

const MembersWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1050px;
  margin: -20px auto;
  padding-top: 50px;
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
`

const ImgWrap = styled.div`
  font-size: 0;
  height: 288px;
  width: 288px;
  margin: 0;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const MemberInfo = styled.div`
  opacity: ${props => (props.isEditing ? '1' : '0')};
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px 0 16px 0;
  text-align: center;
  transition: all 0.2s linear;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  @media only screen and (max-width: 1024px) {
    opacity: 1;
  }
  &::after {
    opacity: 1;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1) 0%, transparent 100%);
    border-bottom-right-radius: ${getStyleForProp('cardRadius')};
    border-bottom-left-radius: ${getStyleForProp('cardRadius')};
  }
  .control-container {
    width: calc(100% - 20px);
    margin-bottom: 10px;
    margin-right: auto;
    margin-left: auto;
    h2,
    p {
      margin-top: 0;
      margin-bottom: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }
`

const TeamImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  ${({ isUrl }) =>
    isUrl &&
    css`
      cursor: pointer;
    `}
`

const Name = styled.h2`
  color: ${getStyleForProp('name')};
  font-weight: 800;
  font-size: 20px;
  position: relative;
  z-index: 2;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  transform: ${props =>
    props.isEditing ? 'initial !important' : 'translateY(80px) !important'};
  opacity: ${props => (props.isEditing ? '1' : '0')};
  transform: translateY(80px);
  transition: all 0.3s ease-out;
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (max-width: 1200px) {
    transform: unset;
  }
`

const Position = styled.p`
  color: ${getStyleForProp('position')};
  font-weight: 300;
  font-size: 14px;
  position: relative;
  z-index: 2;
  transform: ${props =>
    props.isEditing ? 'initial !important' : 'translateY(80px) !important'};
  opacity: ${props => (props.isEditing ? '1' : '0')};
  transform: translateY(80px);
  transition: all 0.3s ease-out;
  ${getTextStyleByAlignment};
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  margin-bottom: 5px;
  @media only screen and (max-width: 1200px) {
    transform: unset;
  }
`

const ItemWrap = styled.div`
  position: relative;
  margin: 20px;
  box-sizing: border-box;
  position: relative;
  ${({ order, isEditing }) =>
    isEditing &&
    css`
      margin-top: 35px;
      z-index: ${order};
      ${MemberInfo} {
        z-index: ${order};
      }
    `}
  @media only screen and (max-width: 550px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            margin: 35px 0 20px 0;
          `
        : css`
            margin: 20px 0;
          `};
  }
  &:hover {
    ${MemberInfo} {
      opacity: 1;
      transform: translateY(0) !important;
      overflow: none;
    }
    ${Name}, ${Position} {
      transform: translateY(0) !important;
      opacity: 1;
    }
  }
  @media only screen and (max-width: 1024px) { 
    ${MemberInfo} {
      opacity: 1;
      transform: translateY(0) !important;
      overflow: none;
    }
    ${Name}, ${Position} {
      transform: translateY(0) !important;
      opacity: 1;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  MembersWrap,
  ImgWrap,
  Title,
  Description,
  ItemWrap,
  Name,
  TeamImg,
  Position,
  MemberInfo,
  ControlContainer
}
