/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA14.styled'
import { validation } from './CTA14.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import StoreBadge from '@website/common/components/StoreBadge'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { getCurrentScreenSize } from '@website/common/utils'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Morph from './CTA14_morph'
import Buttons from '@website/common/components/Buttons'

const CTA14 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      appleStoreLink,
      googlePlayLink,
      buttons,
      appleBackground,
      backgroundImgUrl,
      androidBackground,
      androidBackgroundImgCropParams,
      appleBackgroundImgCropParams,
      appleBackgroundMockupColor = 'light',
      androidBackgroundMockupColor = 'light',
      backgroundImgDimensions,
      bgOverlayTransparency,
      androidBackgroundDimensions,
      appleBackgroundDimensions
    }
  } = props
  const androidBackgroundUrl =
    (androidBackgroundDimensions &&
      androidBackgroundDimensions[getCurrentScreenSize()]) ||
    androidBackground

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const areTwoMockups = appleBackground !== '' && androidBackground !== ''

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer isEditing={isEditing}>
        <Show when={[title, paragraph, appleStoreLink, googlePlayLink]}>
          <S.Section>
            <S.LandingContent>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                required={validation.title.required}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading42"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <Show when={[appleStoreLink, googlePlayLink]}>
                <S.BadgeContainer isEditing={isEditing}>
                  <If
                    condition={!!googlePlayLink}
                    then={() => (
                      <ControlsWithModalOpener
                        actions={Actions.storeIconActions(
                          'googlePlayLink',
                          googlePlayLink
                        )}
                      >
                        <StoreBadge badgeName="google" url={googlePlayLink} />
                      </ControlsWithModalOpener>
                    )}
                    otherwise={() => (
                      <AddButton
                        onAdd={Actions.addStoreLink('googlePlayLink')}
                        toShow={true}
                        rounded
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        Google play
                      </AddButton>
                    )}
                  />
                  <If
                    condition={!!appleStoreLink}
                    then={() => (
                      <ControlsWithModalOpener
                        actions={Actions.storeIconActions(
                          'appleStoreLink',
                          appleStoreLink
                        )}
                      >
                        <StoreBadge badgeName="apple" url={appleStoreLink} />
                      </ControlsWithModalOpener>
                    )}
                    otherwise={() => (
                      <AddButton
                        onAdd={Actions.addStoreLink('appleStoreLink')}
                        toShow={true}
                        rounded
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        App store
                      </AddButton>
                    )}
                  />
                </S.BadgeContainer>
              </Show>
              <Buttons data={buttons} />
            </S.LandingContent>
          </S.Section>
        </Show>
        <Show when={[appleBackground, androidBackground]}>
          <S.MockupsWrap
            isOneMockup={
              !isEditing && (appleBackground === '' || androidBackground === '')
            }
          >
            <Show when={[appleBackground]}>
              <If
                condition={!!appleBackground}
                then={() => (
                  <S.PhoneWrap className="apple-wrap">
                    <S.Phone
                      className="phone-1"
                      color={appleBackgroundMockupColor}
                    >
                      <S.MorphWrapFirst>
                        <Morph />
                      </S.MorphWrapFirst>
                      <ControlsWithImageCropper
                        actions={Actions.mockupActions({
                          mockupType: 'appleBackground',
                          mockupColor: appleBackgroundMockupColor,
                          areTwoMockups: areTwoMockups,
                          imageUrl: appleBackground
                        })}
                      >
                        <Image
                          as={S.MobileImg}
                          asProps={{
                            imgCropParams: appleBackgroundImgCropParams
                          }}
                          defaultImgSrc={appleBackground}
                          sizes={appleBackgroundDimensions}
                          alt="Web page "
                        />
                      </ControlsWithImageCropper>
                    </S.Phone>
                  </S.PhoneWrap>
                )}
                otherwise={() => (
                  <S.AddImageButtonWrap className="add-apple-image-button">
                    <AddButton
                      onAdd={() => {
                        Actions.addImage({
                          mockupType: 'appleBackground'
                        })
                      }}
                      toShow={true}
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      Add image
                    </AddButton>
                  </S.AddImageButtonWrap>
                )}
              />
            </Show>
            <Show when={[androidBackground]}>
              <If
                condition={!!androidBackground}
                then={() => (
                  <S.PhoneWrap className="apple-wrap">
                    <S.Phone
                      className="phone-2"
                      color={androidBackgroundMockupColor}
                    >
                      <S.MorphWrapSecond>
                        <Morph />
                      </S.MorphWrapSecond>
                      <ControlsWithImageCropper
                        actions={Actions.mockupActions({
                          mockupType: 'androidBackground',
                          mockupColor: androidBackgroundMockupColor,
                          areTwoMockups: areTwoMockups,
                          imageUrl: androidBackgroundUrl
                        })}
                      >
                        <Image
                          as={S.MobileImg}
                          asProps={{
                            imgCropParams: androidBackgroundImgCropParams
                          }}
                          defaultImgSrc={androidBackgroundUrl}
                          sizes={androidBackgroundDimensions}
                          alt="Mobile mockup"
                        />
                      </ControlsWithImageCropper>
                    </S.Phone>
                  </S.PhoneWrap>
                )}
                otherwise={() =>
                  appleBackground !== '' && (
                    <S.AddImageButtonWrap className="add-android-image-button">
                      <AddButton
                        onAdd={() => {
                          Actions.addImage({
                            mockupType: 'androidBackground'
                          })
                        }}
                        toShow={true}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        Add image
                      </AddButton>
                    </S.AddImageButtonWrap>
                  )
                }
              />
            </Show>
          </S.MockupsWrap>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

CTA14.contextType = DispatchContext

export default CTA14
