/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header11.styled'
import THEME from './Header11.theme'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { validation } from './Header11.consts'
import Image from '@website/common/components/Image'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'

const Header11 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      mockupColor = 'light',
      buttons,
      logoWidth,
      logoDimensions,
      notebookBackground,
      imgCropParams,
      notebookbackgroundImgDimensions,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight}>
        <S.HeaderContent>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            className="WM_GLOBAL_paragraph"
            as={S.Description}
            maxCount={validation.paragraphMaxChar}
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
        </S.HeaderContent>
        <S.NoteBookHeader color={mockupColor}>
          <S.NotebookWrap>
            <ControlsWithImageCropper
              style={{ width: '100%', height: '100%' }}
              actions={Actions.mockupActions({
                mockupColor,
                imageUrl: notebookBackground
              })}
            >
              <Image
                as={S.Img}
                alt="Mockup"
                sizes={notebookbackgroundImgDimensions}
                asProps={{ imgCropParams }}
                defaultImgSrc={notebookBackground}
              />
            </ControlsWithImageCropper>
          </S.NotebookWrap>
        </S.NoteBookHeader>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})
Header11.contextType = DispatchContext

export default Header11
