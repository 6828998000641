/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header8.styled'
import THEME from './Header8.theme'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import { validation } from './Header8.consts'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'

const Header8 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      buttons,
      webImgUrl,
      logoDimensions,
      mobileImgUrl,
      logoWidth,
      mobileImgDimensions,
      mobileImgCropParams,
      webImgCropParams,
      webImgUrlDimensions,
      mobileImgMockupColor,
      webImgMockupColor,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
      isEditing={isEditing}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight}>
        <S.HeaderContent>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            className="WM_GLOBAL_heading"
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Description}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
        </S.HeaderContent>
        <S.WebpageWrap>
          <S.WebImg color={webImgMockupColor}>
            <ControlsWithImageCropper
              actions={Actions.mockupActions({
                mockupType: 'webImg',
                mockupColor: webImgMockupColor,
                keyForSizes: 'header8ImgSizes',
                imageUrl: webImgUrl
              })}
            >
              <Image
                as={S.Img}
                asProps={{
                  className: 'page-img',
                  imgCropParams: webImgCropParams
                }}
                defaultImgSrc={webImgUrl}
                sizes={webImgUrlDimensions}
                alt="Web page "
              />
            </ControlsWithImageCropper>
          </S.WebImg>
          <S.Phone color={mobileImgMockupColor}>
            <ControlsWithImageCropper
              actions={Actions.mockupActions({
                mockupType: 'mobileImg',
                mockupColor: mobileImgMockupColor,
                keyForSizes: 'header8MockupSizes',
                imageUrl: mobileImgUrl
              })}
            >
              <Image
                as={S.MobileImg}
                asProps={{ imgCropParams: mobileImgCropParams }}
                defaultImgSrc={mobileImgUrl}
                sizes={mobileImgDimensions}
                alt="Web page "
              />
            </ControlsWithImageCropper>
          </S.Phone>
        </S.WebpageWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

Header8.contextType = DispatchContext

export default Header8
