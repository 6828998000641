import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    bg: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.9)`
  },
  LIGHT: {
    bg: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.9)`
  },
  EXTRALIGHT: {
    bg: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.9)`
  }
}
