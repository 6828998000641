/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PayPal3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  padding-top: 0;
  padding-bottom: 0;
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding-right: 0;
  padding-left: 0;
  width: 100%;
  max-width: initial;
  ${getBackgroundStyles(THEME)};
  min-height: 690px;
  @media only screen and (max-width: 768px) {
    min-height: 490px;
  }
  @media only screen and (max-width: 457px) {
    min-height: 510px;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: ${getStyleForProp('orientation')};
  padding-top: 228px;
  @media only screen and (max-width: 457px) {
    padding-top: 150px;
  }
  > div {
    @media only screen and (max-width: 1024px) {
      padding-right: 40px;
      padding-left: 40px;
    }
    @media only screen and (max-width: 457px) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  margin-bottom: 0;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  max-width: 1200px;
`

const Paragraph = styled.p`
  width: 100%;
  padding-top: ${({ isSelling }) => (isSelling ? '0' : '20px')};
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  max-width: 1200px;
`


const BlockItemWrap = styled.div`
  width: 324px;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  word-break: break-word;
  @media only screen and (max-width: 1169px) {
    width: 285px;
  }
  @media only screen and (max-width: 1169px) {
    width: 100%;
  }
  > .control-container {
    width: 100%;
  }
`

const Block = styled.div`
  margin-bottom: 26px;
  @media only screen and (min-width: 711px) {
    max-width: 340px;
  }
  @media only screen and (max-width: 710px) {
    margin-right: 0;
    margin-left: 0;
  }
  .control-container {
    ${BlockItemWrap} {
      padding-top: 35px;
      padding-bottom: 35px;
    }
  }
`

const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 95%;
  max-width: 1400px;
  column-gap: 50px;
  @media only screen and (max-width: 1366px) {
    column-gap: 25px;
  }
  @media only screen and (max-width: 710px) {
    column-gap: 0;
    row-gap: 0;
  }
  @media only screen and (max-width: 1440px) {
    width: 90%;
    max-width: 1200px;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  @media only screen and (max-width: 710px) {
    flex-direction: column;
  }
`

const TextContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ areAllBlocksDeleted, isEditing }) =>
    areAllBlocksDeleted && !isEditing
      ? css`
          padding-bottom: 80px;
          padding: 40px 40px 80px;
          @media only screen and (max-width: 457px) {
            padding: 40px 20px 60px;
          }
        `
      : css`
        padding: 40px 40px 30px;
        @media only screen and (max-width: 457px) {
          padding: 40px 20px 30px;
        `}};
      
  background-color: ${getStyleForProp('backgroundGradient')};
  border-top: 1px solid ${getStyleForProp('border')};
  border-bottom: 1px solid ${getStyleForProp('border')};
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
  }
  > .control-container {
    max-width: 1200px;
    width: 100%;
    > p {
      padding-top: 0;
    }
  }
  > .control-container:nth-child(2) {
    margin-top: 20px;
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 34px;
  padding-bottom: 8px;
  background-color: ${getStyleForProp('background')};
  .add-button-wrap {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  text-align: left;
  margin-bottom: 0 !important;
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  > div {
    width: 24px;
    height: 24px;
    svg {
      width: 24px;
      height: 24px;
      max-width: 24px;
      max-height: 24px;
      fill: ${getStyleForProp('icon')};
    }
  }
  .control-container {
    margin-top: 30px;
    margin-bottom: 30px;
  }
`

const IconContainer = styled.div`
  box-sizing: border-box;
  margin-right: 10px;
`

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  > div:nth-child(2) {
    @media only screen and (min-width: 651px) {
      margin-left: 30px;
    }
    @media only screen and (max-width: 650px) {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }
  .rf-add-btn {
    margin-top: 0 !important;
    white-space: nowrap;
  }
  .link-section {
    width: 100%;
  }
  .link {
    color: ${getStyleForProp('link')};
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
  .rf-add-btn {
    margin-top: 0;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const PrevPriceCount = styled.p`
  color: ${getStyleForProp('price')};
  text-decoration: line-through;
  margin-bottom: 0 !important;
`

const PriceCount = styled.p`
  color: ${getStyleForProp('price')};
  margin-bottom: 0 !important;
  display: flex;
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
  column-gap: 15px;
  row-gap: 15px;
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextContentWrapper,
  ContentContainer,
  Paragraph,
  Title,
  BlocksWrapper,
  BlocksContainer,
  Block,
  BlockItemWrap,
  BlockParagraph,
  IconContainer,
  ButtonsWrap,
  IconWrap,
  PrevPriceCount,
  PriceCount,
  PriceContainer
}
