/* @flow */
import { validation } from '../Spotify1.consts'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import {
  spotifyTrackEditActionMaker,
  musicItemRemoveActionMaker
} from '@website/components/ActionMakers'
export default dispatcher => {
  const addMusic = () => dispatcher({ type: 'ADD_MUSIC' })

  const deleteMusic = (idx: number) => ({
    type: 'DELETE_MUSIC',
    value: idx
  })

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addMusic: () => dispatcher(addMusic()),
    deleteMusic,
    musicActions: ({ isSingleTrack, idx, musicCount, url }) => {
      return [
        ...spotifyTrackEditActionMaker(dispatcher)({ idx, isSingleTrack, url }),
        ...musicItemRemoveActionMaker(dispatcher)({
          idx,
          showRemove: musicCount > validation.musicUrls.min
        })
      ]
    }
  }
}
