// @flow

import React, { useState, useEffect, memo } from 'react'
import { useParams } from 'react-router-dom'

import ProjectLoader from '@editor/common/components/ProjectLoader'
import Page from '../Page'
import { LinkWrapperContext } from '@contexts'
import {
  siteDataAPI,
  getFullProjectDataAPI,
  getWebsiteLanguagesDataAPI,
  getProjectDataAPI
} from '@api'
import { PREVIEW_BASE } from '@editor/conf/routes'
import { useLangRedirection } from '@hooks'
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect'

const SitePreview = () => {
  const { siteId, lang } = useParams()
  const initialProjectData = window.parent.projectData || window.projectData
  const initialSiteHash = window.parent.siteHash || window.siteHash
  const initialWebsiteLanguagesData =
    window.parent.websiteLanguagesData || window.websiteLanguagesData

  const [projectData, setProjectData] = useState(initialProjectData)
  const [siteHash, setSiteHash] = useState(initialSiteHash)
  const [websiteLanguagesData, setWebsiteLanguagesData] = useState(
    initialWebsiteLanguagesData
  )
  const [isSiteLoading, setSiteLoadingState] = useState(false)
  const [isProjectLoading, setProjectLoadingState] = useState(false)
  const [isWebsiteLanguagesDataLoaded, setWebsiteLanguagesDataLoadedState] =
    useState(false)

  useLangRedirection(websiteLanguagesData, isWebsiteLanguagesDataLoaded, true)

  // fetch project data only when language changes
  useDidUpdateEffect(() => {
    setProjectLoadingState(true)

    getProjectDataAPI(siteId, { lang })
      .then(({ data }) => {
        setProjectData(projectData => ({
          ...projectData,
          data: {
            ...data.data,
            visualParams: data.visualParams
          },
          events: [...data.events]
        }))
        setProjectLoadingState(false)
      })
      .catch(console.warn)
  }, [lang])

  useEffect(() => {
    if (!isProjectLoading && !projectData) {
      setProjectLoadingState(true)

      const params = {
        lang: lang === 'lang' ? '' : lang
      }

      getFullProjectDataAPI(siteId, params)
        .then(projectData => {
          const { visualParams, ...data } = projectData
          setProjectData({
            ...data,
            data: { ...projectData.data, visualParams }
          })
          setProjectLoadingState(false)
        })
        .catch(console.warn)
    }
  }, [isProjectLoading, projectData, lang])

  useEffect(() => {
    if (!isSiteLoading && !siteHash) {
      setSiteLoadingState(true)

      siteDataAPI(siteId)
        .then(({ data: { siteHash } }) => {
          setSiteHash(siteHash)
          setSiteLoadingState(false)
        })
        .catch(console.warn)
    }
  }, [isSiteLoading, siteHash])

  useEffect(() => {
    getWebsiteLanguagesDataAPI(siteId)
      .then(({ data }) => {
        setWebsiteLanguagesData(data)
        setWebsiteLanguagesDataLoadedState(true)
      })
      .catch(console.warn)
  }, [])

  if (isProjectLoading || !projectData || isSiteLoading || !siteHash) {
    return <ProjectLoader isLoading={true} />
  }

  return (
    <LinkWrapperContext.Provider
      value={{ routeBase: `${PREVIEW_BASE}/site/:siteId` }}
    >
      <Page
        projectData={projectData}
        siteHash={siteHash}
        siteId={siteId}
        websiteLanguagesData={websiteLanguagesData}
      />
    </LinkWrapperContext.Provider>
  )
}

export default memo(SitePreview)
