/* @flow */
import styled from 'styled-components'

import THEME from './Header5.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  ${getBackgroundStyles(THEME)};
  word-break: break-word;
  padding: 0 0 100px 0;
  position: relative;
  @media only screen and (max-width: 650px) {
    padding: 0 0 60px 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
`

const Title = styled.h1`
  font-size: 32px;
  text-align: left;
  color: ${getStyleForProp('heading')};

  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`

const Description = styled.h2`
  color: ${getStyleForProp('paragraph')};

  text-align: left;
  margin-bottom: 30px;
  line-height: 1.4;
  @media only screen and (max-width: 1024px) {
    text-align: center;
  }
`

const FrameCircle = styled.div`
  width: 400px;
  min-width: 400px;
  height: 400px;
  border-radius: 50%;
  background: ${({ theme }) => theme.palette.tertiarySemiLight}70;
  position: relative;
`

const PhoneWrap = styled.div`
  position: relative;
  z-index: 1;
`

const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const LockScreen = styled.div`
  background-color: #f1f2f4;
  width: 2px;
  height: 60px;
  border-radius: 0 4px 4px 0;
  position: absolute;
  top: 72px;
  right: -12px;
`

const Phone = styled.div`
  width: 220px;
  margin: 0 auto;
  position: relative;
  border: 10px solid #f1f2f4;
  box-sizing: border-box;
  border-radius: 38px;
  font-size: 0;
  min-height: 400px;
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 200%;
  }
  .lazy-figure {
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 24px;
  }
  .image-state-overlay,
  .failed-image-container {
    z-index: 1;
  }
`

const SilentMode = styled.div`
  background-color: #f1f2f4;
  width: 2px;
  height: 14px;
  border-radius: 4px 0 0 4px;
  position: absolute;
  top: 8%;
  left: -12px;
`

const VoiceWrap = styled.div`
  position: absolute;
  top: 15%;
  left: -12px;
`

const VoiceBtn = styled.div`
  background-color: #f1f2f4;
  width: 2px;
  height: 41px;
  border-radius: 4px 0 0 4px;
  margin: 6px 0 0 0;
`

const Dinamic = styled.img`
  width: 60%;
  position: absolute;
  top: -1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
`
const BtnsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  .rf-comp-btn {
    margin: 20px 15px 20px 2px;
  }
`

const LinkWrapper = styled.div`
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      getStyleForProp(props.isTertiary ? 'raisedShadow' : 'buttonShadow')};
    ${props =>
      getStyleForProp(
        props.isTertiary ? 'buttonBackgroundTertiary' : 'buttonBackground'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    max-width: 260px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    @media only screen and (max-width: 650px) {
      height: 40px;
      line-height: 40px;
    }
  }
`

export default {
  Container,
  WmCustomContainer,
  Title,
  Description,
  FrameCircle,
  PhoneWrap,
  Phone,
  MobileImg,
  LockScreen,
  Dinamic,
  SilentMode,
  VoiceBtn,
  VoiceWrap,
  BtnsWrap,
  LinkWrapper
}
