import { translate } from '@editor/common/utils/translations'

export const BACKGROUND_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/upgrade-popup-background.png'
export const MOBILE_BACKGROUND_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/upgrade-popup-mobile-bg.png'
export const BOX_ILLUSTRATION =
  'https://static.rfstat.com/renderforest/images/website_maker_images/upgrade-popup-box-illustration.png'
export const DESIGN_ELEMENT =
  'https://static.rfstat.com/renderforest/images/website_maker_images/upgrade-popup-design-element.png'

export const TARIFF_PLAN_IDS = {
  free: 0,
  lite: 27,
  premium: 24,
  pro: 28,
  business: 29
}

const { free, lite, premium, pro, business } = TARIFF_PLAN_IDS

export const UPGRADE_MAPPING = {
  [free]: [premium, pro],
  [lite]: [pro, business],
  [pro]: [business],
  [premium]: [business],
  [business]: [business]
}

export const CARDS_STATIC_PROPS = {
  [pro]: {
    tariffId: pro,
    title: translate('pro_label')
  },
  [premium]: {
    tariffId: premium,
    title: translate('premium_website_label')
  },
  [business]: {
    tariffId: business,
    title: translate('business_plan_label')
  }
}

export const RECURRING_ACTIVE = 'active'
export const RECURRING_INACTIVE = 'inactive'

export const YEARLY_RECURRENCE_VALUE = '1 Year'
export const MONTHLY_RECURRENCE_VALUE = '1 Month'

export const UPGRADE_THANK_YOU_URL = '/payment/invoice-thank-you-upgrade'
