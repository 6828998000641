/* @flow */

module.exports = {
  id: 'Portfolio12',
  isUnique: false,
  thumbnailRatio: 0.375,
  label: 'Portfolio12',
  category: 'portfolio',
  colorMode: 'LIGHT',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/portfolio12-1.0.0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/portfolio12-1.0.0.jpg',
  metaData: {
    title: {
      _type: 'title',
      _min: 0,
      _max: 50,
      _value: 'NEW ECO BAGS'
    },
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 150,
      _value: 'Take your time to check our new arrivals with Aztec patterns.'
    },
    titleAlignment: 'center',
    paragraphAlignment: 'center',
    gallery: {
      _type: 'list',
      _min: 1,
      _max: 6,
      _value: [
        {
          _type: 'list',
          _min: 4,
          _max: 4,
          _value: [
            {
              title: {
                _type: 'title',
                _min: 0,
                _max: 50,
                _value: ''
              },
              titleAlignment: 'left',
              imageLink: {
                _type: "link",
                _min: 1,
                _max: 2048,
                _value: ""
              },
              backgroundImg: {
                _type: 'image',
                _dimensionsKey: 'backgroundImgDimensions',
                _optimizationSizes: [
                  { _width: 500 },
                  { _width: 700 },
                  { _width: 700 },
                  { _width: 700 }
                ],
                _min: 0,
                _max: 2048,
                _value:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg'
              },
              backgroundImgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg'
              }
            },
            {
              title: {
                _type: 'title',
                _min: 0,
                _max: 50,
                _value: ''
              },
              titleAlignment: 'left',
              imageLink: {
                _type: "link",
                _min: 1,
                _max: 2048,
                _value: ""
              },
              backgroundImg: {
                _type: 'image',
                _dimensionsKey: 'backgroundImgDimensions',
                _optimizationSizes: [
                  { _width: 500 },
                  { _width: 700 },
                  { _width: 700 },
                  { _width: 700 }
                ],
                _min: 0,
                _max: 2048,
                _value:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg'
              },
              backgroundImgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg'
              }
            },
            {
              title: {
                _type: 'title',
                _min: 0,
                _max: 50,
                _value: ''
              },
              titleAlignment: 'left',
              imageLink: {
                _type: "link",
                _min: 1,
                _max: 2048,
                _value: ""
              },
              backgroundImg: {
                _type: 'image',
                _dimensionsKey: 'backgroundImgDimensions',
                _optimizationSizes: [
                  { _width: 500 },
                  { _width: 700 },
                  { _width: 700 },
                  { _width: 700 }
                ],
                _min: 0,
                _max: 2048,
                _value:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg'
              },
              backgroundImgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg'
              }
            },
            {
              title: {
                _type: 'title',
                _min: 0,
                _max: 50,
                _value: ''
              },
              titleAlignment: 'left',
              imageLink: {
                _type: "link",
                _min: 1,
                _max: 2048,
                _value: ""
              },
              backgroundImg: {
                _type: 'image',
                _dimensionsKey: 'backgroundImgDimensions',
                _optimizationSizes: [
                  { _width: 500 },
                  { _width: 700 },
                  { _width: 700 },
                  { _width: 700 }
                ],
                _min: 0,
                _max: 2048,
                _value:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg'
              },
              backgroundImgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg'
              }
            }
          ]
        }
      ]
    }
  },
  defaultProps: {
    title: 'NEW ECO BAGS',
    paragraph: 'Take your time to check our new arrivals with Aztec patterns.',
    titleAlignment: 'center',
    paragraphAlignment: 'center',
    gallery: [
      [
        {
          title: '',
          titleAlignment: 'left',
          imageLink: '',
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-1.0.0.jpg'
          }
        },
        {
          title: '',
          titleAlignment: 'left',
          imageLink: '',
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-2.0.0.jpg'
          }
        },
        {
          title: '',
          titleAlignment: 'left',
          imageLink: '',
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-3.0.0.jpg'
          }
        },
        {
          title: '',
          titleAlignment: 'left',
          imageLink: '',
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio12-4.0.0.jpg'
          }
        }
      ]
    ]
  }
}
