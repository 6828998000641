/* @flow */
import styled from 'styled-components'
import THEME from './PayPal5.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding-top: ${({ isEditing }) => (isEditing ? '140px' : '80px')};
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => (isEditing ? '120px' : '60px')};
  }
`

const WMCustomContainer = styled(WMContainer)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > .control-container {
    width: 100%;
    max-width: 1130px;
    margin-top: 20px;
    h1,
    p {
      padding-top: 0;
      margin: 0;
    }
  }
  > .add-button-wrap {
    margin-top: 50px;
  }
  > :first-child {
    padding-top: 0;
  }
`

const Subtitle = styled.p`
  width: 100%;
  max-width: 1130px;
  color: ${getStyleForProp('subtitle')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  font-weight: 500;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`

const Title = styled.h1`
  width: 100%;
  max-width: 1130px;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  padding-top: 10px;
  margin-bottom: 0;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`

const Paragraph = styled.p`
  width: 100%;
  max-width: 1130px;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  padding-top: 20px;
  margin-bottom: 0;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`

const BlockParagraph = styled.h2`
  color: ${getStyleForProp('blockParagraph')};
  margin-bottom: 0;
  ${getTextStyleByAlignment}
  margin-top: 10px;
`

const BlockTitle = styled.p`
  font-weight: 700;
  color: ${getStyleForProp('blockTitle')};
  ${getTextStyleByAlignment};
  margin-bottom: 10px;
  margin-top: 10px;
`

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 138.5%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 138.5%;
    height: initial;
  }
`

const ImageWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 10px;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
  }
  .lazy-figure {
    padding-top: 138.5%;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const Table = styled.table`
  border-spacing: 0;
  .link-section {
    margin: 30px auto 0 auto;
    display: flex;
    justify-content: center;
  }
  .learn-more-link {
    width: 100%;
    color: ${getStyleForProp('link')};
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
  form {
    width: 100%;
  }
  button {
    width: 100%;
  }
  .paypal-button-wrapper {
    .control-container {
      width: 100%;
    }
    td {
      padding-top: 20px;
    }
  }
`

const TableRow = styled.tr`
  &.block-paragraph-row {
    td {
      @media only screen and (max-width: 1500px) and (min-width: 1364px) {
        &:nth-child(4n) {
          .editable-control-container {
            .styled-controls {
              right: 0;
              left: initial;
            }
          }
        }
      }
      @media only screen and (max-width: 1180px) and (min-width: 1025px) {
        &:nth-child(3n) {
          .editable-control-container {
            .styled-controls {
              right: 0;
              left: initial;
            }
          }
        }
      }
    }
  }
`

const TableCell = styled.td`
  width: 260px;
  max-width: 260px;
  vertical-align: baseline;
  padding: 0 20px;
  &.block-title {
    .control-container {
      margin-top: 15px;
      p {
        margin-top: 0;
      }
    }
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const PrevPriceCount = styled.p`
  color: ${getStyleForProp('price')};
  text-decoration: line-through;
  margin-bottom: 0;
`

const PriceCount = styled.p`
  color: ${getStyleForProp('price')};
  margin-bottom: 0;
  display: flex;
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  column-gap: 10px;
  row-gap: 15px;
`

export default {
  StyledContainer,
  WMCustomContainer,
  Subtitle,
  Title,
  Paragraph,
  BlockParagraph,
  BlockTitle,
  BlockContainer,
  ControlContainer,
  ImageWrapper,
  Table,
  TableRow,
  TableCell,
  Img,
  PrevPriceCount,
  PriceCount,
  PriceContainer
}
