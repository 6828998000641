//@flow
import React, { memo } from 'react'

import { translate } from '@editor/common/utils/translations'
import RangeSlider from '@editor/common/components/RangeSlider'

import * as Styled from './styled'
import type { TFlyoutContentProps } from './types'

const FlyoutContentDesktop = ({
  alignment,
  bgOverlayTransparency,
  onRangeSliderChange
}: TFlyoutContentProps) => (
  <Styled.FlyoutContentDesktop alignment={alignment}>
    <p>{translate('transparency_label')}</p>
    <RangeSlider
      value={1 - bgOverlayTransparency}
      max={1}
      step={0.01}
      onChange={onRangeSliderChange}
    />
  </Styled.FlyoutContentDesktop>
)

export default memo(FlyoutContentDesktop)
