import React, { memo, useState, useCallback } from 'react'

import * as Styled from './styled'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import CopyIcon from '@editor/common/assets/svgr-icons/Copy_icon.svg'
import { translate } from '@editor/common/utils/translations'
import { copyToClipboard } from '@website/common/utils'

const ListItem = ({ data }) => {
  const keyword = data[0]
  const [isCopied, setIsCopied] = useState(false)

  const copyKeyword = useCallback(
    data => {
      if (!isCopied) {
        setIsCopied(true)
        copyToClipboard(data)
        setTimeout(() => setIsCopied(false), 2000)
      }
    },
    [isCopied, setIsCopied]
  )

  return (
    <Styled.ListItem>
      <p>{keyword}</p>
      <TooltipWrapper
        text={
          <Styled.TooltipContent>
            {isCopied ? translate('copied_label') : translate('copy_label')}
          </Styled.TooltipContent>
        }
        className="copy-icon-tooltip"
      >
        <CopyIcon onClick={() => copyKeyword(keyword)} />
      </TooltipWrapper>
    </Styled.ListItem>
  )
}

export default memo(ListItem)
