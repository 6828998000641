// @flow

import React, { memo } from 'react'
import { LinkWrapperContext } from '@contexts'
import { PREVIEW_BASE } from '@editor/conf/routes'

import Page from '../Page'

const SitePresetPreview = () => {
  const projectData = window.parent.projectData || window.projectData
  const siteHash = window.parent.siteHash || window.siteHash

  return (
    <LinkWrapperContext.Provider
      value={{ routeBase: `${PREVIEW_BASE}/site-preset/` }}
    >
      <Page projectData={projectData} siteHash={siteHash} />
    </LinkWrapperContext.Provider>
  )
}

export default memo(SitePresetPreview)
