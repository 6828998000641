// @flow
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import * as Styled from './styled'
import type { TPhoneInputProps } from './types'
import { getDropDownOptions, splitPhoneNumber } from './helper'
import { selectCountriesList } from '@selectors'
import Input from '../Input'
import { extractNumberFromString } from './utils'

const PhoneInput = ({
  error,
  countriesList,
  value,
  onRemoveIconClick,
  onChange,
  onKeyPress,
  onBlur
}: TPhoneInputProps) => {
  const dropdownOptions = useMemo(
    () => getDropDownOptions(countriesList),
    [countriesList]
  )
  const [_phoneCode, setPhoneCode] = useState('+876')

  const phoneCode = useMemo(() => {
    if (!dropdownOptions.length) {
      return
    }

    return value ? splitPhoneNumber(value).code : _phoneCode
  }, [dropdownOptions, value, _phoneCode])

  useEffect(() => {
    if (!dropdownOptions.length) {
      return
    }

    setPhoneCode(phoneCode)
  }, [])

  const phoneNumber = useMemo(
    () => (value ? splitPhoneNumber(value).number : ''),
    [value]
  )

  const onInputChange = useCallback(
    e => {
      const { value } = e.target
      const inputValue = `${phoneCode}-${value}`

      if (!value) {
        onChange && onChange('')
        setPhoneCode(phoneCode)
        return
      }

      onChange && onChange(inputValue)
    },
    [onChange, phoneCode]
  )

  const onCodeSelect = useCallback(
    phoneCode => {
      const inputValue = `${phoneCode}-${phoneNumber}`

      if (!phoneNumber) {
        setPhoneCode(phoneCode)
        return
      }

      setPhoneCode(phoneCode)
      onChange && onChange(inputValue)
    },
    [onChange, phoneNumber]
  )

  const onKeyDown = e => {
    if (e.key === 'e') {
      e.preventDefault()
    }
  }

  const selectedCountryFlag = useMemo(() => {
    const _phoneCode = phoneCode && extractNumberFromString(phoneCode)
    const selectedCountry = countriesList.find(
      country => country.phonecode === _phoneCode
    )

    return `https://static.rfstat.com/${selectedCountry?.flag}`
  }, [countriesList, phoneCode])

  return (
    <Styled.PhoneInputWrapper hasError={!!error}>
      <Styled.GlobalStyles />
      <Styled.PhoneSearchDropdown
        value={phoneCode}
        listMaxHeight={'130px'}
        isSecondaryListVisibleByDefault
        onListItemClick={onCodeSelect}
        primaryList={dropdownOptions}
        secondaryList={[]}
      />
      <Input
        type="number"
        value={phoneNumber}
        className="phone-input"
        onRemoveIconClick={onRemoveIconClick}
        onChange={onInputChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyPress={onKeyPress}
      />
      <Styled.Flag src={selectedCountryFlag} />
      {error && (
        <Styled.ErrorMessage className="error-message">
          <Styled.ErrorIcon name="warning" size="xxSmall" color="#ff4c4c" />
          {error}
        </Styled.ErrorMessage>
      )}
    </Styled.PhoneInputWrapper>
  )
}

const mapStateToProps = state => ({
  countriesList: selectCountriesList(state)
})

export default connect(mapStateToProps)(PhoneInput)
