/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_PRIMARY_PLAN':
        draft.primaryPlanIdx = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'ADD_BLOCK':
        draft.plans.push(initialState.plans[0])
        break
      case 'REMOVE_SUBSCRIBTION_CARD':
        draft.plans.splice(action.value, 1)
        break
      case 'REMOVE_OPTION': {
        const { planIdx, optionIdx } = action.value
        draft.plans[planIdx].options.splice(optionIdx, 1)
        break
      }
      case 'ADD_OPTION':
        draft.plans[action.value].options.push(initialState.plans[0].options[0])
        break
      case 'CHANGE_CARD_OPTION': {
        const { newOption, planIdx, optionIdx } = action.value
        draft.plans[planIdx].options[optionIdx] = newOption
        break
      }
      case 'CHANGE_CARD_DATA':
        const { data, planIdx, keyInStore } = action.value
        draft.plans[planIdx][keyInStore] = data
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.plans[btnIdx].buttonText = btnData.text
        draft.plans[btnIdx].buttonUrl = btnData.link
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
