/* @flow */
import {
  changeTitle,
  changeTitleAlignment,
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeDate = value => {
    dispatcher({
      type: 'CHANGE_DATE',
      value
    })
  }

  const changeDateLabel = (dateLabelValue: string, dateLabelIdx: number) => {
    dispatcher({
      type: 'CHANGE_DATE_LABEL',
      value: { dateLabelValue, dateLabelIdx }
    })
  }

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  return {
    changeDate,
    changeDateLabel,
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment
  }
}
