/* @flow */
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const addClientImage = (idx: number) => {
    dispatch({
      type: 'ADD_CLIENT_IMAGE',
      value: idx
    })
  }
  const changeClientData = (data: string, idx: number, keyInStore: string) => {
    dispatch({
      type: 'CHANGE_CLIENT_DATA',
      value: { data, idx, keyInStore }
    })
  }
  const changeItemParagraph = (paragraph: string, idx: number) => {
    dispatch(changeClientData(paragraph, idx, 'paragraph'))
  }

  const changeClientName = (name: string, idx: number) => {
    dispatch(changeClientData(name, idx, 'name'))
  }

  const changeClientPosition = (position: string, idx: number) => {
    dispatch(changeClientData(position, idx, 'position'))
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const mockupActions = (idx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatch)({
      additionalArgs: { idx },
      keyForSizes: 'testimonial4ClientImgSizes',
      imageUrl
    })

  const addSlide = (activeIdx: number) => {
    dispatch({
      type: 'ADD_SLIDE',
      value: activeIdx
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeNameAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changePositionAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_POSITION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }
  

  return {
    changeTitle: _changeTitle,
    addItem: () => ({ type: 'ADD_ITEM' }),
    mockupActions,
    addSlide,
    addClientImage,
    changeItemParagraph,
    changeClientName,
    changeClientPosition,
    blockActions: removeBlockActionMaker(dispatch),
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNameAlignment,
    changePositionAlignment,
    changeBlockParagraphAlignment
  }
}
