import { useState, useEffect } from 'react'
import { projectContainerClassName } from '@website/conf/consts'

const useOnScreen = (ref, options) => {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        {
          root:
            options?.root ||
            document.querySelector(`.${projectContainerClassName}`),
          rootMargin: options?.rootMargin || '100px 0px',
          threshold: options?.threshold
        }
      )

      observer.observe(ref.current)

      if (isIntersecting && options?.triggerOnce) {
        observer.disconnect()
      }

      return () => {
        observer.disconnect()
      }
    }
  }, [ref.current])

  return isIntersecting
}

export default useOnScreen
