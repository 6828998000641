/* @flow */
import React, { useCallback } from 'react'
import BackgroundImageControl from './BackgroundImageControl'
import * as Styled from './styled'
import TransparencyIcon from '@editor/common/assets/svgr-icons/Transparency_icon.svg'

export const BackGroundControl = ({
  activeSlideIdx,
  componentId,
  backgroundImage,
  bgOverlayTransparency,
  defaultTransparencyValue,
  removeBgImage,
  setTransparency,
  changeSliderBackgroundImage
}) => {
  const isTransparent = bgOverlayTransparency === 0

  const _setTransparency = useCallback(() => {
    if (isTransparent) {
      setTransparency(activeSlideIdx, defaultTransparencyValue)
      return
    }

    setTransparency(activeSlideIdx, 0)
  }, [activeSlideIdx, isTransparent, setTransparency])

  return (
    <>
      <BackgroundImageControl
        componentId={componentId}
        activeSlideIdx={activeSlideIdx}
        backgroundImage={backgroundImage}
        removeBgImage={removeBgImage}
        changeSliderBackgroundImage={changeSliderBackgroundImage}
      />
      {backgroundImage ? (
        <Styled.TransparencyControl
          isTransparent={isTransparent}
          onClick={_setTransparency}
        >
          <TransparencyIcon />
        </Styled.TransparencyControl>
      ) : null}
    </>
  )
}

export default BackGroundControl
