import { hexToRgb } from '@website/common/utils'

export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    link: p => p.primaryLight,
    line: p => `rgba(${hexToRgb(p.accentWhite)}, 0.3)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryLight,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    link: p => p.primaryDark,
    line: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    link: p => p.primaryDark,
    line: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`
  }
}
