// @flow

import React, { useState, useEffect, useCallback, useContext } from 'react'
import { connect } from 'react-redux'
import Switcher from '@editor/common/lib/Switcher'
import { translate } from '@editor/common/utils/translations'

import ConnectDomainPopup from './ConnectDomainPopup'
import * as Styled from './styled'

import {
  isSubscriber,
  selectDomain,
  selectSiteOnlineStatus,
  selectSiteOnlineStatusUIState
} from '@selectors'
import { setSiteOnlineStatus } from '@actions/editor'
import { MobileDetectContext, UpgradePopupContext } from '@contexts'
import { addAnalyticsEvent } from '@editor/common/utils'

import type { TContentProps } from './types'

const _addAnalyticsEvent = (label: string): void => {
  addAnalyticsEvent('SiteMaker Website Status', 'Status change', label)
}

const Content = ({
  isOnline,
  isUserSubscribed,
  hasDomain,
  siteOnlineStatusUIState: { isLoading, isFailed },
  setSiteOnlineStatus
}: TContentProps) => {
  const initialCheckedUIState = isLoading ? !isOnline : isOnline
  const [isConnectDomainPopupOpen, setConnectDomainPopupState] = useState(false)
  const [isCheckedUIState, setIsCheckedUIState] = useState(
    initialCheckedUIState
  )
  const isMobile = useContext(MobileDetectContext)
  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const handleChange = useCallback(
    value => {
      if (isLoading || value === isCheckedUIState) {
        return
      }
      if (!isUserSubscribed) {
        _addAnalyticsEvent('Unsubscribed user')
        setUpgradePopupState({ isOpen: true })
        return
      }
      if (!hasDomain) {
        _addAnalyticsEvent('Domain is not connected')
        setConnectDomainPopupState(true)
        return
      }
      _addAnalyticsEvent(`Change status to ${value ? 'Online' : 'Offline'}`)
      setIsCheckedUIState(value)
      setSiteOnlineStatus(value)
    },
    [isOnline]
  )

  const closeConnectDomainPopup = useCallback(() => {
    setConnectDomainPopupState(false)
  }, [])

  useEffect(() => {
    if (isFailed) {
      setIsCheckedUIState(isOnline)
    }
  }, [isFailed])

  return (
    <Styled.Content>
      <p>
        <p>{translate('online_offline_status_description_1')}</p>
      </p>
      <p>
        <p>{translate('online_offline_status_description_2')}</p>
      </p>
      <Styled.SwitcherWrapper>
        <Styled.Label
          isSelected={!isCheckedUIState}
          onClick={() => handleChange(false)}
        >
          {translate('offline_label')}
        </Styled.Label>
        <Switcher
          className="website-status-switcher"
          size={isMobile ? 'large' : 'small'}
          isChecked={isCheckedUIState}
          isFailed={isFailed}
          isLoading={isLoading}
          onChange={handleChange}
        />
        <Styled.Label
          isSelected={isCheckedUIState}
          onClick={() => handleChange(true)}
        >
          {translate('online_label')}
        </Styled.Label>
      </Styled.SwitcherWrapper>
      <ConnectDomainPopup
        isOpen={isConnectDomainPopupOpen}
        onClose={closeConnectDomainPopup}
      />
    </Styled.Content>
  )
}

const mapStateToProps = state => ({
  isUserSubscribed: isSubscriber(state),
  hasDomain: !!selectDomain(state),
  isOnline: selectSiteOnlineStatus(state),
  siteOnlineStatusUIState: selectSiteOnlineStatusUIState(state)
})

const mapDispatchToProps = {
  setSiteOnlineStatus
}

export default connect(mapStateToProps, mapDispatchToProps)(Content)
