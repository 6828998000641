import styled, { css, keyframes } from 'styled-components'

import { secondary } from '@website/common/styles/colors'

//TODO: convert to link
import BackgroundImage from './Background_image.png'

const ANIMATION_DURATION = '1500ms'

const fromTopAnimation = keyframes`
  0% {
    transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
  }
`

const buttonStyles = css`
  text-align: center;
  font-size: 16px;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 12px;
  color: white;
`

export const InputBG = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border-radius: 13px;
  box-sizing: border-box;
  z-index: -1;
  background: ${secondary.Light};
`

export const inputStyles = css`
  height: 64px;
  width: 546px;
  border-radius: 12px;
  outline: none;
  box-sizing: border-box;
  font-size: 14px;
  padding: 20px;
  color: #1e2539;
  border: none;

  &::placeholder {
    color: ${secondary.Normal};
  }

  &:focus {
    & + ${InputBG} {
      background: linear-gradient(286.88deg, #b20eff -3.7%, #30baff 102.47%);
      box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.4);
    }
  }

  &:hover {
    & + ${InputBG} {
      box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.4);
    }
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const Container = styled.div`
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: #eff0f4;
  background-image: url(${BackgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  animation: animateBackground ${ANIMATION_DURATION};

  @keyframes animateBackground {
    0% {
      background-position: center 200px;
    }
    100% {
      background-position: center bottom;
    }
  }
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`

export const Title = styled.h1`
  font-size: 40px;
  color: #1e2539;
  margin-top: 125px;
  margin-bottom: 30px;
  text-align: center;
  padding: 0 20px;
  animation: ${fromTopAnimation} ${ANIMATION_DURATION};

  @media only screen and (max-width: 1100px) {
    margin-top: 40px;
    font-size: 32px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 20px;
  }
`

export const Description = styled.p`
  font-size: 18px;
  color: #1e2539;
  text-align: center;
  padding: 0 20px;
  animation: ${fromTopAnimation} ${ANIMATION_DURATION};

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
`

export const MainContent = styled.div`
  flex-grow: 1;
  margin-top: 50px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0;
`

export const StepperWrapper = styled.div`
  width: 600px;
  margin-top: auto;
  margin-bottom: 60px;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const PrimaryButton = styled.button`
  ${buttonStyles};
  font-weight: 500;
  padding: 12px 48px;
  display: flex;
  align-items: center;
  line-height: 32px;
  background: linear-gradient(270deg, #8a2be2 0%, #ff69b4 100%),
    linear-gradient(0deg, #ffffff, #ffffff);
  background-size: 150% 100%;
  transition: background 0.4s ease-in-out;

  & > svg {
    fill: white;
    margin-right: 10px;
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      color: ${secondary.SemiLight};
      background: ${secondary.ExtraLight};
      pointer-events: none;
      cursor: default;

      & > svg {
        fill: ${secondary.SemiLight};
      }
    `}

  &:hover {
    background-position: 98%;
  }
`

export const SecondaryButton = styled.button`
  ${buttonStyles};
  padding: 14px 18px;
  background: transparent;
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  margin-top: 15px;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
`
