//  @flow

import React, { useMemo, memo, useEffect } from 'react'
import { useParams, useRouteMatch, Redirect } from 'react-router-dom'

import If from '@website/common/components/Conditional'
import SiteMessengers from '@website/common/components/SiteMessengers'
import BackToTopButton from '@website/common/components/BackToTopButton'
import Providers from './Providers'
import PageComponents from '@shared/PageComponents'
import { addMetaToHtml, addLinkToHtml } from '@website/common/utils'
import {
  connectFbPixelScript,
  connectGtagScript,
  connectYMScript
} from '@website/common/utils/external-scripts'
import { connectChatScript } from '@website/common/utils/live-chats'
import { ThemeProvider } from 'styled-components'
import useScroll from '@hooks/scroll'
import { PREVIEW_BASE } from '@editor/conf/routes'

const findCurrentPageData = (path, pages) =>
  pages.find(({ path: _path }) => _path === `/${path}`)

const Page = ({ projectData, siteHash, siteId, websiteLanguagesData }) => {
  const { page = '' } = useParams()
  const { url: homePage } =
    useRouteMatch(`${PREVIEW_BASE}/site/:siteId/`) ||
    useRouteMatch(`${PREVIEW_BASE}/template/:templateId/`) ||
    useRouteMatch(`${PREVIEW_BASE}/site-preset/`) ||
    useRouteMatch(`${PREVIEW_BASE}/ai-preset/`)

  const {
    data,
    cookieSettings,
    widgets = {},
    meta = {},
    events = [],
    languageMenuLayout
  } = projectData

  const { pages, visualParams, headerLinks, footerLinks, extraLinksLabel } =
    data

  const currentPageData = useMemo(
    () => findCurrentPageData(page, pages),
    [page, pages]
  )

  const { facebookPixelCode, googleAnalyticsId, favicon, yandexMetricaId } =
    meta

  const { chats, messengers } = widgets
  const { components } = currentPageData || {}
  const projectLinks = { headerLinks, footerLinks }
  const { keywords, description, title = '' } = currentPageData?.meta || {}
  const hasAddedMessengers =
    messengers &&
    messengers.data?.findIndex(messenger => messenger.isActive) !== -1
  const _websiteLanguagesData = {
    data: websiteLanguagesData,
    languageMenuLayout
  }

  useScroll(page)
  useEffect(() => {
    if (googleAnalyticsId) {
      connectGtagScript(googleAnalyticsId)
    }

    if (facebookPixelCode) {
      connectFbPixelScript(facebookPixelCode)
    }

    if (yandexMetricaId) {
      connectYMScript(yandexMetricaId)
    }

    connectChatScript(chats)

    addMetaToHtml('keywords', keywords)
    addMetaToHtml('description', description)
    addLinkToHtml('icon', 'image/x-icon', favicon)
    document.title = title
  }, [])

  useEffect(() => {
    if (typeof gtag === 'function') {
      gtag('event', 'page_view', { page_path: `/${page}`, page_title: title })
    }
  }, [page])

  // As an enhancement can be redirected to 404 page
  if (!currentPageData) {
    return <Redirect to={homePage} />
  }

  return (
    <Providers
      projectLinks={projectLinks}
      extraLinksLabel={extraLinksLabel}
      siteId={siteId}
      siteHash={siteHash}
      gdprPrivacyPolicy={cookieSettings?.gdpr?.privacyPolicyBtn}
      websiteLanguagesData={_websiteLanguagesData}
    >
      <If
        condition={hasAddedMessengers}
        then={() => <SiteMessengers messengersData={messengers} />}
      />
      <ThemeProvider
        theme={{
          look: visualParams.look,
          palette: visualParams.palette,
          fontSize: visualParams.textSize,
          primaryFont: visualParams.primaryFont,
          secondaryFont: visualParams.secondaryFont
        }}
      >
        <>
          <BackToTopButton widgets={widgets} />
          <PageComponents
            events={events}
            components={components}
            cookieSettings={cookieSettings}
            siteId={siteId}
            isForPreview
          />
        </>
      </ThemeProvider>
    </Providers>
  )
}

export default memo(Page)
