/* @flow */
import React, { useContext } from 'react'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import Icon from '@website/common/components/Icon'
import ProportionalChildrenWrapper from '@website/common/components/ProportionalChildrenWrapper'
import { EditingContext, DispatchContext } from '@contexts'
import ActionGetter from './Actions'
import S from './Buttons.styled'
import { translate } from '@editor/common/utils/translations'

const Buttons = ({ data: buttons, additionalProps, showRemove = true }) => {
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <Show when={[buttons.length > 0]}>
      <S.ButtonsBlock className="buttons-block">
        <S.ButtonsContainer isEditing={isEditing}>
          <ProportionalChildrenWrapper className="WM_GLOBAL_secondary-font buttons-wrapper">
            <Mapper
              data={buttons}
              render={(btn: string, btnIdx: number) => {
                return (
                  <S.Button key={btnIdx} isTertiary={btn.type === 'tertiary'}>
                    <ControlsWithModalOpener
                      actions={Actions.getButtonActions(
                        btnIdx,
                        {
                          text: btn.buttonText || btn.btnText,
                          link: btn.buttonUrl || btn.btnUrl
                        },
                        showRemove,
                        additionalProps
                      )}
                    >
                      <LinkWrapper
                        text={btn.buttonText || btn.btnText}
                        url={btn.buttonUrl || btn.btnUrl}
                      />
                    </ControlsWithModalOpener>
                  </S.Button>
                )
              }}
            />
          </ProportionalChildrenWrapper>
        </S.ButtonsContainer>
        <AddButton
          onAdd={() => Actions.addButton(additionalProps)}
          toShow={buttons.length < 2}
          rounded
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_button_label')}
        </AddButton>
      </S.ButtonsBlock>
    </Show>
  )
}

export default Buttons
