/* @flow */
import styled from 'styled-components'
import { getTextStyleByAlignment, styleProvider } from '@website/common/utils'

import THEME from './Feature21.theme'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 80px 0;
  background: ${getStyleForProp('background')};
  padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '0')};
  @media only screen and (max-width: 678px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 60px 0' : '0')};
  }
`

const BlockWrapper = styled.div``

const Block = styled.div`
  display: flex;
  flex-direction: ${props => (props.isEven ? 'row' : 'row-reverse')};
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
`

const Content = styled.div`
  padding: 0 8vw;
  box-sizing: border-box;
  text-align: ${({ onlyTitle }) => (onlyTitle ? 'center' : 'left')};
  width: 45%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    padding: 20px 40px;
  }
  @media only screen and (max-width: 1023px) {
    width: 100%;
    padding: 60px 40px;
  }
  @media only screen and (max-width: 457px) {
    padding: 40px 20px;
  }
  .add-button-wrap {
    text-align: left !important;
    > div {
      height: 50px;
      box-sizing: border-box;
    }
  }
  > p:first-child,
  > div:first-child,
  > h2:first-child {
    margin-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const ImageWrapper = styled.div`
  width: 45%;
  flex-grow: 1;
  font-size: 0;
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 70%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 70%;
    height: initial;
  }
`

const Title = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.3;
  margin-top: 20px;
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const ButtonWrap = styled.div`
  margin-top: 30px;
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${getStyleForProp('buttonBackground')};
    background-repeat: no-repeat;
    text-decoration: none;
    max-width: 260px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`

const AddButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  .add-button-wrap {
    margin: 0 6px;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    .add-button-wrap {
      margin: 6px 0;
    }
  }
`

export default {
  Container,
  BlockWrapper,
  Block,
  Content,
  ImageWrapper,
  Title,
  Paragraph,
  Img,
  ButtonWrap,
  AddButtonsWrap,
  ControlContainer
}
