/* @flow */
import styled from 'styled-components'
import THEME from './Basic3.theme'
import {
  styleProvider,
  getBackgroundStyles
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  background-attachment: fixed !important;
  padding: 0;
  @media only screen and (max-width: 768px) {
    background-attachment: initial !important;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  height: 557px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: initial;
  padding: 0;
  margin: 0px;

  @media only screen and (max-width: 1440px) {
    height: 453px;
  }

  & svg:nth-child(1) {
    position: absolute;
    top: -45px;
    left: 0;
    & > g > path {
      fill: ${getStyleForProp('paperColor')};
    }
  }
  & svg:nth-child(2) {
    position: absolute;
    bottom: 0;
    left: 0;
    & > g > path {
      fill: ${getStyleForProp('paperColor')};
    }
    @media only screen and (max-width: 457px) {
      bottom: -15px;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer
}
