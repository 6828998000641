//@flow
import React, { useCallback, useContext, useEffect, useRef } from 'react'

import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { StyledControlItem } from '@website/common/components/Controlled/Controls/styled'
import SettingsIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'
import { translate } from '@editor/common/utils/translations'
import { ControlModalsContext } from '@contexts'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import { useResponsive } from '@shared/hooks'

const MenuSettingsControl = () => {
  const controlRef = useRef(null)
  const { modalStates, setModalState } = useContext(ControlModalsContext)
  const isSmallSizes = useResponsive(1024)

  const isFlyoutOpen = modalStates[MODAL_TYPES.languagesMenuFlyout].isOpen

  useEffect(
    () => () => {
      !isSmallSizes &&
        setModalState(MODAL_TYPES.languagesMenuFlyout, {
          isOpen: false
        })
    },
    []
  )

  const onControlClick = useCallback(
    () =>
      setModalState(MODAL_TYPES.languagesMenuFlyout, {
        isOpen: !isFlyoutOpen,
        controlRef
      }),
    [isFlyoutOpen]
  )

  return (
    <TooltipWrapper
      text={translate('languages_menu_settings_control_tooltip_text')}
      className="languages-menu-settings-control"
    >
      <StyledControlItem ref={controlRef} onClick={onControlClick}>
        <SettingsIcon />
      </StyledControlItem>
    </TooltipWrapper>
  )
}

export default MenuSettingsControl
