/* @flow */
import {
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeSlideTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeSlideContent = (newContent: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_CONTENT',
      value: { newContent, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockContentAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_CONTENT_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const sliderActions = ({ idx, onEditClick, imageUrl }) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'post1',
      showRemove: false,
      additionalArgs: { idx },
      clickHandlers: {
        change: onEditClick,
        crop: onEditClick
      },
      imageUrl
    })

  const addSlide = () => {
    dispatcher({ type: 'ADD_SLIDE' })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addSlide,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSlideTitle,
    changeSlideContent,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockContentAlignment,
    getSliderActions: sliderActions,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
