// @flow
import React, { useEffect } from 'react'
import { useRouteMatch, useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import If from '@website/common/components/Conditional'
import AddComponent from '@editor/common/components/AddComponent'
import ComponentIdWrapper from '@website/common/components/ComponentIdWrapper'
import ZoomOutWrapper from '@editor/common/components/ZoomOutWrapper'
import ComponentControls from '@editor/common/components/ComponentControls'
import * as Helper from './helper'
import Components from '@website/components'
import {
  getTagNameByCategory,
  getSecondComponentMode
} from '@website/common/utils'
import { RSVP_COMP_CATEGORY } from '@editor/conf/consts'
import {
  DispatchContext,
  ExtraLinksLabelContext,
  ProjectLinksContext,
  PageContext,
  GDPRPrivacyPolicyContext,
  BackgroundSidebarProvider
} from '@contexts'

import {
  selectCurrentPageComponentsForView,
  getProjectLinks,
  getExtraLinksLabel,
  getAllPages,
  selectEventsData,
  selectGDPRPrivacyPolicy,
  selectComponentsData
} from '@selectors'
import { ThemeProvider } from 'styled-components'

const PageComponents = ({
  componentsData,
  pageComponents,
  projectPages,
  dispatch,
  projectLinks,
  extraLinksLabel,
  gdprPrivacyPolicy,
  events
}) => {
  const {
    params: { page }
  } = useRouteMatch()
  const { state = {} } = useLocation()
  const history = useHistory()

  const { scrollIndex } = state
  const pagePath = page === 'home' ? '/' : `/${page}`

  useEffect(() => {
    if (!scrollIndex) {
      return
    }

    const comp = document.getElementById(`comp-${scrollIndex}`)

    if (comp) {
      setTimeout(() => {
        comp.scrollIntoView({ behavior: 'smooth' })
      }, 200)
    }
    history.replace({ ...history.location, state: {} })
  }, [scrollIndex])

  return (
    <>
      {pageComponents.map((component, i) => {
        // add component name into action
        // for devtool to display from which component comes actions
        const componentDataWithMeta = componentsData[component.id]
        const compName = component.id.toUpperCase()
        const { data = {} } = component
        const componentDispatcher = action => {
          if (!action) {
            return
          }
          const undoable = action.hasOwnProperty('undoable')
            ? action.undoable
            : true
          dispatch({
            ...action,
            type: `@${compName}/${action.type}`,
            __from_page: pagePath,
            __from_component: i,
            __initialData: componentDataWithMeta,
            undoable
          })
        }
        const Comp = Components[component.id]
        const HTMLTagName = getTagNameByCategory(component.category)

        const additionalProps =
          i === 0
            ? {
                hasNextComponent: !!pageComponents[i + 1]
              }
            : {}

        additionalProps.componentId = component.id

        if (component.category === RSVP_COMP_CATEGORY) {
          const { eventId } = component

          const eventData = eventId
            ? events.find(event => event.id === eventId)
            : events[0]

          if (!eventData) {
            return null
          }
          additionalProps.eventData = eventData
          additionalProps.uniqId = component.uniqId
          additionalProps.backgroundImages = component.backgroundImages
        }

        return (
          <React.Fragment key={component.uniqId || i}>
            <PageContext.Provider
              value={projectPages.map(page => ({
                path: page
              }))}
            >
              <DispatchContext.Provider value={componentDispatcher}>
                <ThemeProvider
                  theme={{
                    colorMode: component.colorMode,
                    orientation: component.orientation,
                    secondComponentMode: getSecondComponentMode(pageComponents)
                  }}
                >
                  <React.Fragment>
                    <HTMLTagName
                      className={`base-component-container ${component.id}`}
                    >
                      <ComponentIdWrapper
                        id={`comp-${i}`}
                        idWithUniqKey={`comp-${component.uniqId}`}
                      >
                        <ExtraLinksLabelContext.Provider
                          value={extraLinksLabel || translate('more_label')}
                        >
                          <ProjectLinksContext.Provider value={projectLinks}>
                            <GDPRPrivacyPolicyContext.Provider
                              value={gdprPrivacyPolicy}
                            >
                              <BackgroundSidebarProvider>
                                <ComponentControls
                                  idx={i}
                                  category={component.category}
                                  componentUniqId={component.uniqId}
                                  componentId={component.id}
                                  colorMode={component.colorMode}
                                  backgroundImage={
                                    data.backgroundImgUrl ||
                                    data.backgroundThumbnail
                                  }
                                  backgroundVideo={data.backgroundVideoUrl}
                                  bgOverlayTransparency={
                                    data.bgOverlayTransparency
                                  }
                                  isAnimationEnabled={data.isAnimated}
                                  hasLimitedBackgrounds={
                                    data.hasLimitedBackgrounds
                                  }
                                  isBgColorAllowed={Helper.isBgColorAllowed(
                                    componentDataWithMeta
                                  )}
                                  isBgImageAllowed={Helper.isBackgroundImageAllowed(
                                    componentDataWithMeta
                                  )}
                                  isBgVideoAllowed={Helper.isBackgroundVideoAllowed(
                                    componentDataWithMeta
                                  )}
                                  isBgTransparencyAllowed={Helper.isBgTransparencyAllowed(
                                    componentDataWithMeta
                                  )}
                                  isComponentAnimated={Helper.isComponentAnimated(
                                    componentDataWithMeta
                                  )}
                                  isUpMovementAllowed={Helper.isUpMovementAllowed(
                                    pageComponents,
                                    i
                                  )}
                                  isDownMovementAllowed={Helper.isDownMovementAllowed(
                                    pageComponents,
                                    i
                                  )}
                                  isOrientationAllowed={Helper.isOrientationAllowed(
                                    componentDataWithMeta
                                  )}
                                  isDuplicateAllowed={Helper.isDuplicateAllowed(
                                    pageComponents,
                                    i
                                  )}
                                />
                                <ZoomOutWrapper>
                                  <Comp data={data} {...additionalProps} />
                                </ZoomOutWrapper>
                              </BackgroundSidebarProvider>
                            </GDPRPrivacyPolicyContext.Provider>
                          </ProjectLinksContext.Provider>
                        </ExtraLinksLabelContext.Provider>
                      </ComponentIdWrapper>
                    </HTMLTagName>
                    <If
                      then={() => <AddComponent atIdx={1} />}
                      condition={
                        component.id === 'NoHeader' &&
                        pageComponents.length === 1
                      }
                      otherwise={() =>
                        !Helper.isComponentFooter(pageComponents, i) ? (
                          <AddComponent
                            atIdx={i + 1}
                            isLast={!pageComponents[i + 1]}
                          />
                        ) : null
                      }
                    />
                  </React.Fragment>
                </ThemeProvider>
              </DispatchContext.Provider>
            </PageContext.Provider>
          </React.Fragment>
        )
      })}
    </>
  )
}

const mapStateToProps = (state: TAppState) => ({
  componentsData: selectComponentsData(state),
  pageComponents: selectCurrentPageComponentsForView(state),
  projectPages: getAllPages(state),
  projectLinks: getProjectLinks(state),
  extraLinksLabel: getExtraLinksLabel(state),
  gdprPrivacyPolicy: selectGDPRPrivacyPolicy(state),
  events: selectEventsData(state)
})

export default connect(mapStateToProps)(PageComponents)
