import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const Content = styled.div`
  margin: 0 auto;
  width: 440px;
  margin: 30px auto;
  text-align: center;
  color: ${secondary.Dark};
  font-weight: 500;
  @media only screen and (max-width: 480px) {
    width: auto;
    margin: 30px 10px;
  }
`

export const Footer = styled.div`
  margin: 0 auto;
`
