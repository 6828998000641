/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions: _dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = _dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, itemIdx, newTitle } = action.value
        draft.blocks[idx][itemIdx].title = newTitle
        break
      }
      case 'CHANGE_PARAGRAPH': {
        const { idx, itemIdx, newParagraph } = action.value
        draft.blocks[idx][itemIdx].paragraph = newParagraph
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment, itemIdx } = action.value
        draft.blocks[idx][itemIdx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment, itemIdx } = action.value
        draft.blocks[idx][itemIdx].blockParagraphAlignment = alignment
        break
      }

      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx, itemIdx } = action.value
        draft.blocks[idx][itemIdx].imgCropParams = imgCropParams
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, itemIdx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.blocks[idx][itemIdx].imgUrl = url
        draft.blocks[idx][itemIdx].imgDimensions = dimensions || {}
        draft.blocks[idx][itemIdx].imgCropParams = {}
        break
      }
      case 'CHANGE_LINK_DATA':
        {
          const { idx, itemIdx, newLinkData } = action.value
          draft.blocks[idx][itemIdx].additionalUrl = newLinkData.link
          draft.blocks[idx][itemIdx].additionalText = newLinkData.text
        }
        break
      case 'REMOVE_LINK':
        {
          const { idx, itemIdx } = action.value
          draft.blocks[idx][itemIdx].additionalUrl = ''
          draft.blocks[idx][itemIdx].additionalText = ''
        }
        break
      case 'ADD_LINK':
        {
          const { idx, itemIdx } = action.value
          draft.blocks[idx][itemIdx].additionalUrl =
            'http://builder.renderforestsites.com'
          draft.blocks[idx][itemIdx].additionalText = 'Learn more'
        }
        break
    }
  })
}
