import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    iconBackground: p => `rgba(${hexToRgb(p.primaryLight)}, 0.9)`,
    blockBackground: p => `rgba(${hexToRgb(p.primaryLight)}, 1)`,
    raisedShadow: () => 'none',
    buttonShadow: () => 'none'
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => p.tertiaryLight,
    iconBackground: p => `rgba(${hexToRgb(p.primaryDark)}, 1)`,
    blockBackground: p => `rgba(${hexToRgb(p.primaryDark)}, 0.9)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => p.tertiaryExtraLight,
    iconBackground: p => `rgba(${hexToRgb(p.primaryDark)}, 1)`,
    blockBackground: p => `rgba(${hexToRgb(p.primaryDark)}, 0.9)`
  }
}
