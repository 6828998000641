/* @flow */
import { LINK_TYPES } from '../consts'
import { isOpenedWithHash } from '@editor/common/utils'
import { translate } from '@editor/common/utils/translations'

import LinkIcon from '@editor/common/assets/svgr-icons/Link_icon.svg'
import FilesIcon from '@editor/common/assets/svgr-icons/files_icon.svg'
import ImagesIcon from '@editor/common/assets/svgr-icons/images_icon.svg'
import CreateVideoIcon from '@editor/common/assets/svgr-icons/create_video_icon.svg'
import CreateLogoIcon from '@editor/common/assets/svgr-icons/Create_logo_icon.svg'

export const getMediaType = actionType => {
  if (!actionType) {
    return 'all'
  }
  const type = actionType === LINK_TYPES.logo ? LINK_TYPES.image : actionType
  return type.toLowerCase()
}
const VIDEO_EDIT_NOTIFICATION = translate('add_video_url_description')

const isImage = type => type === LINK_TYPES.image
const isLogo = type => type === LINK_TYPES.logo
const isVideo = type => type === LINK_TYPES.video

export const getOptions = (type, actionGetter) => {
  const actions = [
    {
      IconComp: LinkIcon,
      handler: () => {
        const notification =
          type === LINK_TYPES.video ? VIDEO_EDIT_NOTIFICATION : ''

        actionGetter.urlModal(notification)
      },
      text: `${
        type === LINK_TYPES.video
          ? translate('video_url_label')
          : translate('image_url_label')
      }`
    }
  ]

  if (isImage(type) && !isOpenedWithHash()) {
    return [
      ...[
        {
          text: translate('my_files_label'),
          IconComp: FilesIcon,
          handler: actionGetter.mediaLib
        },
        {
          text: translate('stock_images_label'),
          IconComp: ImagesIcon,
          handler: actionGetter.stockImage
        }
      ],
      ...actions
    ]
  }

  if (isLogo(type)) {
    return [
      ...[
        {
          text: translate('my_files_label'),
          IconComp: FilesIcon,
          handler: actionGetter.mediaLib
        },
        {
          text: translate('stock_images_label'),
          IconComp: ImagesIcon,
          handler: actionGetter.stockImage
        },
        {
          text: translate('create_logo_label'),
          IconComp: CreateLogoIcon,
          handler: actionGetter.logoCreate
        }
      ],
      ...actions
    ]
  }

  if (isVideo(type)) {
    return [
      ...actions,
      ...[
        {
          text: translate('create_video_label'),
          IconComp: CreateVideoIcon,
          handler: actionGetter.videoCreate
        }
      ]
    ]
  }

  return actions
}
