// @flow
import { RSVP_COMP_CATEGORY } from '@editor/conf/consts'

export const addEventIdToRsvpComponents = (
  pages: Array<Object>,
  eventId: number
) =>
  pages.map(page => ({
    ...page,
    components: page.components.map(component =>
      component.category === RSVP_COMP_CATEGORY
        ? {
            ...component,
            eventId
          }
        : component
    )
  }))
