import { createSelector } from 'reselect'

export const selectWidgets = state => state?.widgets

export const selectWidgetsLoadingState = createSelector(
  [selectWidgets],
  widgets => widgets.isLoading
)

export const selectWidgetsData = createSelector(
  [selectWidgets],
  widgets => widgets.data
)

export const selectMessengerSettings = createSelector(
  [selectWidgetsData],
  data => data?.messengers
)

export const selectChatSettings = createSelector(
  [selectWidgetsData],
  data => data?.chats
)
