import styled from 'styled-components'

export const TooltipContent = styled.div`
  .title {
    font-weight: 700;
    margin-bottom: 10px;
  }

  .link {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-weight: 600;

    svg {
      width: 12px;
      height: 12px;
      fill: #ffffff;
      transition: transform linear 0.15s;
    }

    &:hover {
      svg {
        transform: translateX(5px);
      }
    }
  }
`
