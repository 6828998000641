/* @flow */
import H from './Slider1.config'

/**
 * @description Change `slick-slider` settings for small devices
 * Shows  one slide.
 **/
export const getSliderSettings = (slideClicked: boolean) =>
  global.window && global.window.innerWidth < 1025
    ? { ...H.mobileSliderSettings, autoplay: !slideClicked }
    : { ...H.sliderSettings, autoplay: !slideClicked }
