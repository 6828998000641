/* @flow */
import React, { useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import type { TCompRemovePopupProps } from './types'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import * as Styled from '@editor/common/components/ConfirmDialog-old/styled'

import { setReplaceStatus } from '@actions/editor'
import { useHistoryWithSearch } from '@hooks'
import { EDITOR_BASE, PAGE_BASE } from '@editor/conf/routes'

const PopupHeader = ({ title }) => (
  <Styled.PopupHeader>{title}</Styled.PopupHeader>
)

const PopupFooter = ({ onReplace, onRemove }) => (
  <Styled.PopupFooter>
    <Styled.CancelButton onClick={onRemove}>
      {translate('remove_label', true)}
    </Styled.CancelButton>
    <Styled.ConfirmButton onClick={onReplace}>
      {translate('replace_label', true)}
    </Styled.ConfirmButton>
  </Styled.PopupFooter>
)

export const CompRemovePopup = ({
  idx,
  isOpen,
  removeComponent,
  setRemovePopupState,
  setReplaceStatus
}: TCompRemovePopupProps) => {
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(EDITOR_BASE)
  const {
    params: { page }
  } = useRouteMatch(PAGE_BASE)

  const onCancel = useCallback(() => {
    setRemovePopupState(false)
  }, [])

  const onRemove = useCallback(() => {
    removeComponent()
    setRemovePopupState(false)
  }, [removeComponent])

  const onReplace = useCallback(() => {
    setRemovePopupState(false)
    setReplaceStatus(true, 0)

    history.push(`${url}/edit/${page}/replace-component/${idx}`)
  }, [history, url, setReplaceStatus, setRemovePopupState, page])

  return (
    <Popup
      isOpen={isOpen}
      onClose={onCancel}
      header={<PopupHeader title={translate('delete_last_component_label')} />}
      footer={<PopupFooter onReplace={onReplace} onRemove={onRemove} />}
    >
      <Styled.Content>{translate('delete_last_component_text')}</Styled.Content>
    </Popup>
  )
}

export default connect(null, { setReplaceStatus })(CompRemovePopup)
