//@flow
import React, { useCallback, memo, useContext } from 'react'

import * as Styled from './styled'
import {
  DEFAULT_TEXT,
  BOLD_COMMAND,
  ITALIC_COMMAND,
  UNDERLINE_COMMAND,
  LINK_COMMAND,
  UNLINK_COMMAND,
  LINK_NODE_NAME,
  UNDERLINE_NODE_NAME,
  ITALIC_NODE_NAME,
  BOLD_NODE_NAME
} from './consts'
import type { TTextCustomizationToolbarProps } from './types'
import {
  getChildHyperLink,
  getParentHyperlink,
  _addAnalyticsEvent
} from './utils'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import BoldIcon from '@editor/common/assets/svgr-icons/Bold.svg'
import ItalicIcon from '@editor/common/assets/svgr-icons/Italic.svg'
import UnderlineIcon from '@editor/common/assets/svgr-icons/Underline.svg'
import LinkIcon from '@editor/common/assets/svgr-icons/Link_customize_icon.svg'
import UnlinkIcon from '@editor/common/assets/svgr-icons/Broken_link_icon.svg'
import TextAlignmentSwitcher from '@website/common/components/TextAlignmentSwitcher'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import { ControlModalsContext, MobileDetectContext } from '@contexts'
import { secondary } from '@website/common/styles/colors'
import { translate } from '@editor/common/utils/translations'

const TextCustomizationToolbar = ({
  editableEl,
  alignment,
  isTextGradient,
  changeAlignment,
  changeTextGradientStatus,
  isLinkControlHidden = false,
  activeNodesNames,
  isTextFormatingHidden = false,
  isGradientControlHidden = true,
  isUnderlineHidden = false,
  updateActiveNodeNames
}: TTextCustomizationToolbarProps) => {
  if (
    editableEl?.innerText?.length === 0 ||
    editableEl?.innerText === DEFAULT_TEXT
  ) {
    return null
  }

  const { setModalState } = useContext(ControlModalsContext)
  const isMobile = useContext(MobileDetectContext)

  const preventDefault = e => e.preventDefault()
  const isLinkActive = activeNodesNames.includes(LINK_NODE_NAME)
  const isBoldActive = activeNodesNames.includes(BOLD_NODE_NAME)
  const isItalicActive = activeNodesNames.includes(ITALIC_NODE_NAME)
  const isUnderlineActive = activeNodesNames.includes(UNDERLINE_NODE_NAME)

  const customizeText = useCallback(
    command => {
      const selection = document.getSelection()
      const selectedText = selection.toString()

      if (!selectedText || !editableEl.contains(selection.anchorNode)) {
        selection.selectAllChildren(editableEl)
      }

      document.execCommand(command, true, '')
      updateActiveNodeNames()
      _addAnalyticsEvent(command)
    },
    [editableEl, updateActiveNodeNames]
  )

  const createLink = useCallback(() => {
    const selection = document.getSelection()
    const selectedText = selection.toString()

    if (!selectedText || !editableEl.contains(selection.anchorNode)) {
      selection.selectAllChildren(editableEl)
    }

    const handler = ({ link }) => {
      document.execCommand(LINK_COMMAND, true, link)
      !isBoldActive && document.execCommand(BOLD_COMMAND, true)
      !isItalicActive && document.execCommand(ITALIC_COMMAND, true)
      !isUnderlineActive && document.execCommand(UNDERLINE_COMMAND, true)

      const parentHyperlink = getParentHyperlink(
        selection.anchorNode,
        editableEl
      )
      if (parentHyperlink) {
        parentHyperlink.setAttribute('class', 'text-customization-link')
      }
      _addAnalyticsEvent(LINK_COMMAND)
    }

    const linkElement = selectedText
      ? getParentHyperlink(selection.anchorNode, editableEl)
      : getChildHyperLink(selection.anchorNode)

    const link = linkElement?.getAttribute('href') || ''

    setModalState(MODAL_TYPES.extendedUrlModal, {
      link,
      text: selection.toString() || editableEl.innerText,
      isOpen: true,
      type: LINK_TYPES.link,
      isHypertextSelected: true,
      areSpacesAllowed: true,
      limitText: false,
      handler
    })

    // prevent user typing after opening link settings popup
    setTimeout(() => {
      editableEl.blur()
    }, 0)
  }, [editableEl, isBoldActive, isItalicActive, isUnderlineActive])

  const deleteLink = useCallback(() => {
    customizeText(UNLINK_COMMAND)
    isBoldActive && document.execCommand(BOLD_COMMAND, true)
    isItalicActive && document.execCommand(ITALIC_COMMAND, true)
    isUnderlineActive && document.execCommand(UNDERLINE_COMMAND, true)
    updateActiveNodeNames()
  }, [
    customizeText,
    isBoldActive,
    isItalicActive,
    isUnderlineActive,
    updateActiveNodeNames
  ])

  const toggleTextGradient = useCallback(() => {
    changeTextGradientStatus(!isTextGradient)
  }, [isTextGradient, changeTextGradientStatus])

  return (
    <Styled.TextCustomizationToolbar
      isTextFormatingHidden={isTextFormatingHidden}
      alignment={alignment}
    >
      {!isTextFormatingHidden ? (
        <>
          <TooltipWrapper
            text={isMobile ? '' : translate('bold_tooltip_text')}
            color={secondary.ExtraDark}
          >
            <Styled.Item
              onClick={() => customizeText(BOLD_COMMAND)}
              onMouseDown={preventDefault}
              isActive={activeNodesNames.includes(BOLD_NODE_NAME)}
            >
              <BoldIcon />
            </Styled.Item>
          </TooltipWrapper>
          <TooltipWrapper
            text={isMobile ? '' : translate('italic_tooltip_text')}
            color={secondary.ExtraDark}
          >
            <Styled.Item
              onClick={() => customizeText(ITALIC_COMMAND)}
              onMouseDown={preventDefault}
              isActive={activeNodesNames.includes(ITALIC_NODE_NAME)}
            >
              <ItalicIcon />
            </Styled.Item>
          </TooltipWrapper>
          {!isUnderlineHidden ? (
            <TooltipWrapper
              text={isMobile ? '' : translate('underline_tooltip_text')}
              color={secondary.ExtraDark}
            >
              <Styled.Item
                isDisabled={isTextGradient}
                onClick={() => customizeText(UNDERLINE_COMMAND)}
                onMouseDown={preventDefault}
                isActive={activeNodesNames.includes(UNDERLINE_NODE_NAME)}
              >
                <UnderlineIcon />
              </Styled.Item>
            </TooltipWrapper>
          ) : null}
        </>
      ) : null}
      {alignment ? (
        <TextAlignmentSwitcher
          alignment={alignment}
          changeAlignment={changeAlignment}
        />
      ) : null}
      {!isLinkControlHidden && !isTextFormatingHidden ? (
        <TooltipWrapper
          text={isMobile ? '' : translate('link_settings_tooltip_text')}
          color={secondary.ExtraDark}
        >
          <Styled.Item
            onClick={createLink}
            onMouseDown={preventDefault}
            isActive={isLinkActive}
          >
            <LinkIcon />
          </Styled.Item>
        </TooltipWrapper>
      ) : null}
      {isLinkActive ? (
        <TooltipWrapper
          text={isMobile ? '' : translate('delete_link_tooltip_text')}
          color={secondary.ExtraDark}
        >
          <Styled.Item onClick={deleteLink} onMouseDown={preventDefault} isActive={isTextGradient}>
            <UnlinkIcon />
          </Styled.Item>
        </TooltipWrapper>
      ) : null}
      {!isGradientControlHidden ? (
        <TooltipWrapper
          text={isMobile ? '' : translate('gradient_tooltip_text')}
          color={secondary.ExtraDark}
        >
          <Styled.Item
            onClick={toggleTextGradient}
            onMouseDown={preventDefault}
            isActive={isTextGradient}
          >
            <span className='Gradient_icon'>
            </span>
          </Styled.Item>
        </TooltipWrapper>
      ) : null}
    </Styled.TextCustomizationToolbar>
  )
}

export default memo(TextCustomizationToolbar)
