// @flow
import React, { memo, useCallback, useContext, useMemo, useState } from 'react'

import * as Styled from './styled'
import { Button } from '../styled'
import type { TCardProps } from './types'
import { Option, PriceContent } from '../components'
import { BOX_ILLUSTRATION, DESIGN_ELEMENT } from '../consts'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import { MobileDetectContext } from '@contexts'
import { splitArr } from './utils'
import { translate } from '@editor/common/utils/translations'

const Card = ({
  title,
  isYearly,
  isUpgrade,
  options,
  priceData,
  onUpgradeBtnClick
}: TCardProps) => {
  const isMobile = useContext(MobileDetectContext)
  const [isDetailsPopupOpen, setDetailsPopupOpenState] = useState(false)

  const {
    price = 0,
    currencySymbol: currency = '$',
    discountedPrice = 0
  } = priceData

  const optionsList = useMemo(
    () => (
      <Styled.OptionsWrapper>
        {splitArr(options).map(list => (
          <Styled.Options>
            {list.map((option, idx) => (
              <Option key={idx} option={option} />
            ))}
          </Styled.Options>
        ))}
      </Styled.OptionsWrapper>
    ),
    [options]
  )

  const openDetailsPopup = useCallback(() => {
    setDetailsPopupOpenState(true)
  }, [])

  const closeDetailsPopup = useCallback(() => {
    setDetailsPopupOpenState(false)
  }, [])

  return (
    <>
      <Styled.Card>
        <Styled.BoxIllustration src={BOX_ILLUSTRATION} alt="box-illustration" />
        <Styled.DesignElement src={DESIGN_ELEMENT} alt="design-element" />
        <Styled.CardHeader>
          {isMobile ? (
            <Styled.TitleWrapper>
              <Styled.Title>{title}</Styled.Title>
              <InfoIcon onClick={openDetailsPopup} />
            </Styled.TitleWrapper>
          ) : (
            <Styled.Title>{title}</Styled.Title>
          )}
          <PriceContent
            price={price}
            discountedPrice={discountedPrice}
            currency={currency}
            isYearly={isYearly}
            isUpgrade={isUpgrade}
            isSmallSize
          />
        </Styled.CardHeader>
        {!isMobile ? (
          <>
            <Styled.Line />
            {optionsList}
          </>
        ) : null}
      </Styled.Card>
      {isMobile ? (
        <Styled.MobileDetailsPopup
          isOpen={isDetailsPopupOpen}
          onClose={closeDetailsPopup}
        >
          <Styled.DetailsPopupHeader>
            <Styled.Title>{title}</Styled.Title>
            <PriceContent
              price={price}
              discountedPrice={discountedPrice}
              currency={currency}
              isYearly={isYearly}
              isUpgrade={isUpgrade}
              isSmallSize
            />
          </Styled.DetailsPopupHeader>
          <Styled.Line />
          {optionsList}
          <Button onClick={onUpgradeBtnClick}>
            {translate('upgrade_label')}
          </Button>
          <Styled.Info>
            * {translate('your_storage_will_be_added_to_active_subsb_label')}
          </Styled.Info>
        </Styled.MobileDetailsPopup>
      ) : null}
    </>
  )
}

export default memo(Card)
