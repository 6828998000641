export const validation = {
  headingMaxChar: 60,
  paragraphMaxChar: 200,
  blockTitleMaxChar: 50,
  blockParagraphMaxChar: 100,
  blocks: {
    min: 0,
    max: 4
  },
  buttons: {
    min: 0,
    max: 2
  }
}
