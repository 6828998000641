/* @flow */
import { buttonModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  addButton
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeCode = (newCode: string) => ({
    type: 'CHANGE_CODE',
    value: newCode
  })

  return {
    changeTitle,
    changeParagraph,
    addButton,
    changeCode,
    btnActions: buttonModalActionMaker(dispatcher)
  }
}
