import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import Root from '@editor/root'
import Preview from './preview'
import { EDITOR_BASE, PREVIEW_BASE } from '@editor/conf/routes'

const App = () => {
  const { CURRENT_LANGUAGE, location } = window
  const editorLang = location.pathname.split('/')[1]

  const basename =
    CURRENT_LANGUAGE === 'en' && editorLang !== 'en'
      ? '/website-maker'
      : `/${CURRENT_LANGUAGE}/website-maker`

  return (
    <Router basename={basename}>
      <Switch>
        <Route path={PREVIEW_BASE} component={Preview} />
        <Route path={EDITOR_BASE} component={Root} />
      </Switch>
    </Router>
  )
}

export default App
