// @flow

import React, { useEffect, useState } from 'react'
import SlickSlider from 'react-slick'
import ItemWrapper from './ItemWrapper'
import { PrevEditArrow, NextEditArrow } from './EditArrows'
import { PrevArrow, NextArrow } from './Arrows'
import { SLIDER_PARAMS, TABLET_SIZE, MOBILE_SIZE } from './consts'
import '@website/components/Swipe/swipe-slider-styles.css'

const Slider = ({
  swipeToSlide,
  swipe,
  autoplay,
  accessibility,
  slickProps,
  children,
  beforeChange,
  onMouseDown,
  dots,
  hasArrowsOnlyEditMode
}) => {
  const slidesLength = React.Children.count(children)
  const initialSlide = parseInt(slidesLength / 2 - 1)
  const [deviceType, setDeviceType] = useState('desktop')

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      const deviceType =
        windowWidth > TABLET_SIZE
          ? 'large'
          : windowWidth <= TABLET_SIZE && windowWidth > MOBILE_SIZE
          ? 'middle'
          : 'small'
      setDeviceType(deviceType)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const sliderProps = {
    ...SLIDER_PARAMS,
    prevArrow: hasArrowsOnlyEditMode ? <PrevEditArrow /> : <PrevArrow />,
    nextArrow: hasArrowsOnlyEditMode ? <NextEditArrow /> : <NextArrow />
  }

  return (
    <div
      key={deviceType}
      style={{
        margin: '0 auto'
      }}
    >
      <SlickSlider
        {...sliderProps}
        {...slickProps}
        initialSlide={initialSlide}
        beforeChange={beforeChange}
        swipeToSlide={swipeToSlide}
        autoplay={autoplay}
        accessibility={accessibility}
        swipe={swipe}
        dots={dots}
        hasArrowsOnlyEditMode={hasArrowsOnlyEditMode}
      >
        {React.Children.map(children, (child, idx) => {
          return (
            <ItemWrapper
              animationDuration={sliderProps.speed}
              key={idx}
              onMouseDown={onMouseDown}
            >
              {child}
            </ItemWrapper>
          )
        })}
      </SlickSlider>
    </div>
  )
}

export default Slider
