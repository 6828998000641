/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './CTA7.theme'
import {
  styleProvider,
  getBackground,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  flex-direction: ${getStyleForProp('orientation')};
  justify-content: space-between;
  @media only screen and (min-width: 769px) {
    padding-top: ${({ isEditing }) => isEditing && '150px'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  ${({ theme }) =>
    css`
      ${theme.orientation === 'LEFT'
        ? css``
        : css`
            flex-direction: row-reverse;
            @media only screen and (max-width: 992px) {
              flex-direction: column-reverse;
            }
          `}
    `}
`

const CtaContent = styled.div`
  width: 100%;
  margin: 0 0 0 30px;
  max-width: 600px;
  @media only screen and (max-width: 1024px) {
    margin: 0 auto;
    max-width: initial;
  }
  > :first-child {
    padding-top: 0;
  }
  > h4:first-child,
  > p:first-child {
    margin-top: 0;
  }
  .buttons-block {
    margin-left: -15px;
  }
`

const ImageContent = styled.div`
  width: 100%;
  max-width: 580px;
  max-height: 500px;
  height: 460px;
  position: relative;
  width: 540px;
  margin: -20px auto;
  @media only screen and (min-width: 1441px) {
    margin: -10px auto;
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? css`
              transform: scale(1.3) translateX(-70px);
              @media only screen and (min-width: 993px) {
                transform: scale(1.15) translateX(-50px);
              }
            `
          : css`
              transform: scale(1.3) translateX(70px);
              @media only screen and (min-width: 993px) {
                transform: scale(1.15) translateX(50px);
              }
            `}
      `}
  }
  @media only screen and (max-width: 992px) {
    transform: initial;
  }
  &:only-child {
    left: 50%;
    transform: translateX(-50%);
  }
  .styled-controls {
    @media only screen and (min-width: 1441px) {
      transform: scale(0.78);
    }
    @media only screen and (min-width: 993px) {
      transform: scale(0.87);
    }
    @media only screen and (max-width: 992px) {
      transform: initial;
    }
  }
  .morph {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    #whiteBg {
      fill: ${getStyleForProp('background')};
    }
  }
  @media only screen and (max-width: 1350px) and (min-width: 1024px) {
    height: 320px;
    max-width: 370px;
  }
  @media only screen and (max-width: 1024px) {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? css`
              margin: -30px auto 10px auto;
            `
          : css`
              margin: 10px auto -30px auto;
            `}
      `}
  }
  @media only screen and (max-width: 660px) {
    height: 320px;
    max-width: 370px;
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? css`
              margin: -20px auto 20px auto;
            `
          : css`
              margin: 30px auto -20px auto;
            `}
      `}
  }
  @media only screen and (max-width: 410px) {
    max-width: 280px;
    height: 236px;
  }
`

const Figure = styled.div`
  width: 540px;
  height: 424px;
  max-width: 90%;
  max-height: 90%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  @media only screen and (max-width: 1350px) and (min-width: 1024px) {
    width: 366px;
    height: 292px;
    max-height: 80%;
  }
  @media only screen and (max-width: 660px) {
    width: 366px;
    height: 292px;
  }
  @media only screen and (max-width: 410px) {
    width: 276px;
    height: 232px;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const TitleWrapper = styled.div`
  border-left: 4px solid ${getStyleForProp('titleBorder')};
  padding: 0 0 0 60px;
  @media only screen and (max-width: 660px) {
    padding: 0 0 0 4vw;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  margin-top: 20px;
`
const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  line-height: 1.4;
  margin-top: 20px;
`

const ButtonsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 25px 0 0 -15px;
  @media only screen and (max-width: 550px) {
    max-width: 320px;
    margin: 15px 0 0 -15px;
  }
  .add-button-wrap {
    > div {
      box-sizing: border-box;
      height: 50px;
      padding: 16px 30px;
      margin: 15px;
    }
  }
`

const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 550px) {
    flex-direction: column;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: -15px;
`

const BtnWrapper = styled.div`
  margin: 15px;
  @media only screen and (max-width: 550px) {
    max-width: 300px;
  }
  .control-container {
    width: 100%;
  }
  .link-wrapper {
    width: 100%;
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      getStyleForProp(props.isTertiary ? 'raisedShadow' : 'buttonShadow')};
    ${props =>
      getStyleForProp(
        props.isTertiary ? 'buttonBackgroundTertiary' : 'buttonBackground'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    max-width: 300px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`

const AddButtonsWrapper = styled.div`
  display: flex;
  margin: 30px 0 0 0;
  @media only screen and (max-width: 370px) {
    flex-direction: column;
    & > div {
      display: flex;
      & > div {
        margin: 6px 0;
      }
    }
  }
`

export default {
  ButtonsWrap,
  Img,
  WmCustomContainer,
  StyledContainer,
  TitleWrapper,
  Title,
  Paragraph,
  ButtonsContainer,
  ButtonsBlock,
  BtnWrapper,
  CtaContent,
  ImageContent,
  Figure,
  AddButtonsWrapper
}
