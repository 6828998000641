import { getQueryStringParam } from '@website/common/utils'

export const getHashFromParentWindow = (key: string) => {
  if (!global.window || !global.window.parent) {
    return ''
  }
  const p = global.window.parent.location.search
    .substr(1)
    .split('&')
    .map(pair => pair.split('='))
    .find(pair => pair[0] === key)

  if (p) {
    return p[1]
  }
}

export const getHash = () => {
  const hash = getQueryStringParam('authHash')
  if (hash) {
    return hash
  }
  //for peview
  return getHashFromParentWindow('authHash') || ''
}
