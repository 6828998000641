export default {
  DARK: {
    background: p => p.tertiaryDark
  },
  LIGHT: {
    background: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    background: p => p.tertiaryExtraLight
  }
}
