/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header6_2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const stylesByColor = {
  default: '',
  dark: css`
    filter: brightness(0.13);
  `,
  light: css`
    filter: brightness(0.97);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ topOffset }) => `${topOffset + 120}px`} 40px 120px 40px;
  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
    padding-bottom: 95px;
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
    padding-bottom: 85px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 48%;
  max-width: 620px;
  @media only screen and (max-width: 1300px) {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
`

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 310px;
  margin: ${({ isEditing }) => (isEditing ? '30px 0 0 -10px' : '30px 0 0 0')};
  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: initial;
    justify-content: center;
    margin: 0;
    flex-wrap: wrap;
    margin-top: 30px;
    a {
      margin: 10px;
    }
  }
  .control-container {
    margin: 10px;
    @media only screen and (max-width: 1200px) {
      margin: 0;
    }
  }
`

const ContentWrapper = styled.div`
  max-width: 620px;
  width: 100%;
  @media only screen and (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 45px;
    max-width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    margin-bottom: 35px;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const Phone = styled.div`
  width: 235px;
  margin: 0 auto;
  position: relative;
  padding: 10px 22px 22px 12px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 0;
  min-height: 400px;
  &:before {
    content: ' ';
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    border-radius: 24px;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-phone-wrap.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  &:after {
    content: '';
    width: 30%;
    height: 25px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-54%);
    z-index: 1;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-phone-detail.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  &.phone-1 {
    transform: translate(-8%, -10%);
  }
  &.phone-2 {
    transform: translate(8%, 10%);
  }
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 211.49%;
  }
  .lazy-figure {
    width: 100%;
    position: absolute;
    top: 0;
    border-radius: 16px;
  }
  .image-state-overlay,
  .failed-image-container {
    z-index: 1;
  }
`

const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const MorphWrapFirst = styled.div`
  width: 70%;
  position: absolute;
  top: -3.2%;
  left: -23%;
  svg {
    width: 100%;
    g {
      path {
        fill: ${getStyleForProp('dotColor')};
      }
    }
  }
`

const MorphWrapSecond = styled.div`
  width: 70%;
  position: absolute;
  bottom: -1.5%;
  left: -23%;
  svg {
    width: 100%;
    g {
      path {
        fill: ${getStyleForProp('dotColor')};
      }
    }
  }
`

const MockupsWrap = styled.div`
  display: flex;
  align-items: flex-end;
  transform: translateX(-20%);
  @media only screen and (max-width: 1440px) {
    transform: translate(-10%, -20px);
  }
  @media only screen and (max-width: 1300px) {
    transform: none;
  }
  @media only screen and (max-width: 1300px) {
    margin: 50px auto auto auto;
  }
  @media only screen and (max-width: 650px) {
    transform: translateY(60px) scale(0.65);
    margin: -110px 0 -30px 0;
  }
  @media only screen and (max-width: 370px) {
    transform: translateY(80px) scale(0.5);
    margin: -130px 0 -30px 0;
  }
  &:only-child {
    margin-top: 0;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  ContentWrapper,
  Section,
  Title,
  Description,
  BadgeContainer,
  Phone,
  MobileImg,
  MorphWrapFirst,
  MorphWrapSecond,
  MockupsWrap
}
