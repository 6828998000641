import styled from 'styled-components'

export const SearchContent = styled.div`
  padding: 50px 55px;
  .input-container {
    width: 686px;
    @media only screen and (max-width: 1100px) {
      width: 600px;
    }
  }
`

export const Suggestions = styled.div`
  display: flex;
  margin-top: ${({ hasErrorMessage }) => (hasErrorMessage ? '25px' : '15px')};
  font-size: 14px;

  & > span {
    color: #252e48;
    font-weight: 600;
  }

  & > p {
    color: #3271e6;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`
