/* @flow */
import React, { useContext, memo } from 'react'
import S from './Pricing2.styled'
import { validation } from './Pricing2.consts'
import ActionGetter from './Actions'
import Badge from '@website/common/components/Badge'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Pricing2 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      plans,
      primaryPlanIdx,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.HeaderContainer>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Heading}
                alignment={titleAlignment}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.HeaderContainer>
        </Show>
        <S.PricingCardsContainer>
          <Mapper
            data={plans}
            render={(plan, planIndex) => {
              const isPrimary = planIndex === primaryPlanIdx
              return (
                <S.PriceCardWrapper isPrimary={isPrimary} key={planIndex}>
                  <ControlsWithModalOpener
                    actions={Actions.cardAction({
                      idx: planIndex,
                      showStar: !isPrimary,
                      showRemove: plans.length > validation.plans.min
                    })}
                  >
                    <S.PriceCard isPrimary={isPrimary} isEditing={isEditing}>
                      <If condition={isPrimary} then={() => <Badge />} />
                      <S.ContentBody isPrimary={isPrimary}>
                        <S.PricingCardHeader>
                          <EditableContent
                            text={plan.title}
                            as={S.Title}
                            maxCount={validation.priceTitleMaxChar}
                            bold
                            color={'tertiaryNormal'}
                            uppercase
                            className="WM_GLOBAL_paragraph14"
                            onChange={newTitle =>
                              Actions.changePlanTitle(newTitle, planIndex)
                            }
                          />
                          <EditableContent
                            as={S.Price}
                            text={plan.price}
                            maxCount={validation.priceMaxChar}
                            onChange={newPrice =>
                              Actions.changePlanPrice(newPrice, planIndex)
                            }
                            className="WM_GLOBAL_heading54"
                          />
                          <EditableContent
                            text={plan.perDate}
                            as={S.PerDate}
                            maxCount={validation.perMonthMaxChar}
                            className="WM_GLOBAL_secondary-font"
                            onChange={(newDate: string) =>
                              Actions.changePerDate(newDate, planIndex)
                            }
                          />
                        </S.PricingCardHeader>
                        <S.PricingCardContent>
                          <Mapper
                            data={plan.options}
                            render={(option, optionIdx) => (
                              <ControlsWithModalOpener
                                style={{ width: '100%' }}
                                key={optionIdx}
                                actions={Actions.optionActions(
                                  planIndex,
                                  optionIdx
                                )}
                              >
                                <EditableContent
                                  text={option}
                                  as={S.Option}
                                  maxCount={validation.optionMaxChar}
                                  onChange={(newValue: string) =>
                                    Actions.changeOptionValue(
                                      newValue,
                                      planIndex,
                                      optionIdx
                                    )
                                  }
                                  className="WM_GLOBAL_paragraph14"
                                />
                              </ControlsWithModalOpener>
                            )}
                          />
                          <AddButton
                            style={{
                              margin: '10px 0'
                            }}
                            onAdd={() => Actions.addOption(planIndex)}
                            toShow={
                              plan.options.length < validation.plans.options.max
                            }
                          >
                            <Icon
                              name="glyph-add"
                              className="icon"
                              size="normal"
                            />
                            {translate('add_item_label')}
                          </AddButton>
                        </S.PricingCardContent>
                        <S.ButtonWrapper isPrimary={isPrimary}>
                          <S.PricingCardFooter>
                            <ControlsWithModalOpener
                              actions={Actions.btnActions(
                                planIndex,
                                {
                                  text: plan.buttonText,
                                  link: plan.buttonUrl
                                },
                                false
                              )}
                              style={{ width: '100%' }}
                            >
                              <LinkWrapper
                                text={plan.buttonText}
                                url={plan.buttonUrl}
                                linkProps={{
                                  className: 'WM_GLOBAL_secondary-font'
                                }}
                              />
                            </ControlsWithModalOpener>
                          </S.PricingCardFooter>
                        </S.ButtonWrapper>
                      </S.ContentBody>
                    </S.PriceCard>
                  </ControlsWithModalOpener>
                </S.PriceCardWrapper>
              )
            }}
          />
        </S.PricingCardsContainer>
        <AddButton
          style={{ marginTop: '1.5rem' }}
          onAdd={Actions.addBlock}
          toShow={plans.length < validation.plans.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Pricing2
