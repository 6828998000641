/* @flow */
import React, { useContext, memo } from 'react'
import { EditingContext } from '@contexts'
import S from './Container.styled'

const Container = React.forwardRef(({ children, ...rest }, ref) => {
  const { isEditing } = useContext(EditingContext)

  return (
    <S.Container isEditing={isEditing} {...rest} ref={ref}>
      {children}
    </S.Container>
  )
})

export default memo(Container)
