/* @flow */
import styled from 'styled-components'
import THEME from './Music5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: ${({ isEditing }) => (isEditing ? '100px 0 80px 0' : '80px 0')};
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1300px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : '80px 0')};
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
`

const TextContent = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('heading')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const MusicWrapper = styled.div`
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 0;
  margin: -10px auto;
  padding-top: 50px;
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
  > :first-child {
    padding-top: 0;
  }
`

const MusicContent = styled.div`
  width: 450px;
  min-width: 450px;
  border-radius: ${getStyleForProp('cardRadius')};
  position: relative;
  margin: 10px;
  @media only screen and (max-width: 550px) {
    width: 84vw;
    min-width: 84vw;
  }
`

const IframeWrapper = styled.div`
  width: 100%;
  position: relative;
  iframe {
    width: 100%;
    height: 450px;
    @media only screen and (max-width: 550px) {
      height: 84vw;
    }
  }
`

export default {
  WmCustomContainer,
  StyledContainer,
  Title,
  Paragraph,
  MusicContent,
  IframeWrapper,
  TextContent,
  MusicWrapper
}
