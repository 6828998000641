/* @flow */
import React, { useContext, memo } from 'react'
import S from './Footer1.styled'
import { validation } from './Footer1.consts'
import ActionGetter from './Actions'
import {
  DispatchContext,
  EditingContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import SocialLinks from '@website/common/components/SocialLinks'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { Controls } from '@website/common/components/Controlled'

const Footer1 = memo(props => {
  const {
    data: { copyrightText, socials }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}

  return (
    <S.StyledContainer isEditing={isEditing}>
      <WMContainer>
        <S.FooterContentWrap>
          <S.CopyRightWrapper isSocials={socials.length}>
            <EditableContent
              text={copyrightText}
              as={S.Copyright}
              onChange={Actions.changeCopyright}
              className="WM_GLOBAL_secondary-font"
              isPrivacyPolicyVisible={isPrivacyPolicyVisible}
            />
            {isPrivacyPolicyVisible ? (
              <S.LinkWrap isEditing={isEditing}>
                <Controls actions={Actions.privacyPolicyAction()}>
                  <LinkWrapper
                    text={privacyPolicyName}
                    url={privacyPolicyLink}
                    linkProps={{
                      className: 'privacy-policy-link WM_GLOBAL_secondary-font'
                    }}
                  />
                </Controls>
              </S.LinkWrap>
            ) : null}
          </S.CopyRightWrapper>
          <SocialLinks
            className="socials"
            socials={socials}
            socialsMaxCount={validation.socials.max}
          />
        </S.FooterContentWrap>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Footer1
