/* @flow */
import { simpleButtonActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changePlaceHolderText,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changePlaceHolderText = (placeholder: string) =>
    dispatcher(changePlaceHolderText(placeholder))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeFormParagraph = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_FORM_PARAGRAPH',
      value: newParagraph
    })
  }

  const changeFormParagraphAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_FORM_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeFormParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeFormParagraphAlignment,
    changePlaceHolderText: _changePlaceHolderText,
    getBtnActions: simpleButtonActionMaker(dispatcher)
  }
}
