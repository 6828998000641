/* @flow */
import React, { memo } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import PlusIcon from '@editor/common/assets/svgr-icons/Plus_icon.svg'
import { useHistoryWithSearch } from '@hooks'
import { EDITOR_BASE } from '@editor/conf/routes'

type TAddComponent = {
  atIdx: number,
  isLast: boolean
}

const AddComponent = ({ atIdx, isLast }: TAddComponent) => {
  const history = useHistoryWithSearch()
  const {
    params: { page = 'home' }
  } = useRouteMatch()
  const { url } = useRouteMatch(EDITOR_BASE)

  const handleClick = () => {
    history.push(`${url}/edit/${page}/add-component/${atIdx}`)
  }

  return (
    <Styled.AddComponent isLast={isLast}>
      <Styled.AddComponentButton onClick={handleClick}>
        <PlusIcon />
        <span>{translate('component_label')}</span>
      </Styled.AddComponentButton>
      <Styled.Line />
    </Styled.AddComponent>
  )
}

export default memo(AddComponent)
