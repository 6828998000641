import styled from 'styled-components'

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  max-width: 560px;
  & > button {
    margin-left: 10px;
  }
  & > div {
    margin-left: 10px;
    margin-right: 10px;
  }
  @media only screen and (max-width: 850px) {
    flex-grow: 1;
  }
`
