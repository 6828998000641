import styled, { css } from 'styled-components'

import { scrollBarStyles } from '@editor/common/styles'

export const ComponentThumbnailsWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
`

export const ComponentThumbnails = styled.div`
  ${scrollBarStyles};
  overflow-y: scroll;
  padding: 0 30px 30px;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    padding: 0 20px 30px;
  }
`

export const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  color: #252e48;
  margin-bottom: 30px;

  p {
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px;
    margin-right: 10px;
    border-right: 1px solid #c1c9e0;
    @media only screen and (min-width: 1270px) {
      white-space: nowrap;
    }
  }

  span {
    font-size: 14px;
    flex-grow: 1;
  }

  @media only screen and (max-width: 1270px) {
    margin-bottom: 20px;
    flex-direction: column;
    align-items: flex-start;
    p {
      border-right: none;
      padding: 0;
    }
  }
`

export const Container = styled.div`
  padding-top: 30px;
  &:not(:last-child) {
    padding-bottom: 30px;
    border-bottom: 1px solid #c1c9e0;
  }

  @media only screen and (max-width: 768px) {
    &:not(:last-child) {
      padding-bottom: 0;
    }
  }
`

export const LoaderWrapper = styled.div`
  & > div {
    position: absolute;
    left: 60%;
    margin-bottom: 5px;
    @media only screen and (max-width: 768px) {
      left: 50%;
    }

    ${({ isCentered }) =>
      isCentered
        ? css`
            bottom: 50%;
            transform: translate(-50%, -50%);
          `
        : css`
            bottom: 5px;
            transform: scale(0.8) translateX(-50%);
          `};
  }
`
