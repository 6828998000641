/* @flow */
import {
  changeTitle,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeTextLeft = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_TEXT_LEFT',
      value: newParagraph
    })
  }

  const changeTextRight = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_TEXT_RIGHT',
      value: newParagraph
    })
  }

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeTextLeftAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TEXT_LEFT_ALIGNMENT',
      value: alignment
    })
  }

  const changeTextRightAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TEXT_RIGHT_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeTextLeft,
    changeTextRight,
    changeTextLeftAlignment,
    changeTextRightAlignment
  }
}
