'' /* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_ROW':
        if (action.value % 2 === 0) {
          draft.images.splice(action.value - 1, 2)
        } else {
          draft.images.splice(action.value, 2)
        }
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_IMAGE':
        const { idx, url, dimensions } = action.value
        draft.images[idx].imgUrl = url
        draft.images[idx].imgDimensions = dimensions || {}
        draft.images[idx].imgCropParams = {}
        break
      case 'ADD_ROW':
        if (draft.images.length === 1) {
          draft.images.push(initialState.images[1], initialState.images[2])
          return
        }
        if (Math.floor(draft.images.length / 2) % 2 === 0) {
          draft.images.push(initialState.images[1], initialState.images[0])
        } else {
          draft.images.push(initialState.images[0], initialState.images[1])
        }
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.images[idx].imgCropParams = imgCropParams
      }
    }
  })
}
