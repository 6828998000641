/* @flow */
import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const SwitcherContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 6px;
  padding: 0 6px;
  &:before {
    content: '';
    width: 1px;
    height: 20px;
    position: absolute;
    top: 11px;
    left: -1px;
    background-color: ${secondary.Light};
    opacity: 0.5;
  }
  &:after {
    content: '';
    width: 1px;
    height: 20px;
    position: absolute;
    top: 11px;
    right: 0;
    background-color: ${secondary.Light};
    opacity: 0.5;
  }

  .first-line {
    position: absolute;
    width: 20px;
    top: 13px;
    transition: left 0.2s ease-out;
  }
  .second-line {
    position: absolute;
    width: 16px;
    top: 20px;
    transition: left 0.3s ease-out;
  }
  .third-line {
    position: absolute;
    width: 12px;
    top: 27px;
    transition: left 0.4s ease-out;
  }

  ${({ alignment }) =>
    alignment === 'left'
      ? css`
          .first-line,
          .second-line,
          .third-line {
            left: 16px;
          }
        `
      : alignment === 'center'
      ? css`
          .first-line {
            left: 56px;
          }
          .second-line {
            left: 58px;
          }
          .third-line {
            left: 60px;
          }
        `
      : css`
          .first-line {
            left: 96px;
          }
          .second-line {
            left: 100px;
          }
          .third-line {
            left: 104px;
          }
        `};
`

export const LineWrapper = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    fill: ${({ isActive }) =>
      isActive ? secondary.ExtraDark : secondary.Normal};

    @media only screen and (max-width: 768px) {
      fill: ${secondary.ExtraDark};
    }
  }

  @media only screen and (min-width: 769px) {
    &:hover {
      & > svg {
        fill: ${secondary.ExtraDark};
      }
    }
  }
`
