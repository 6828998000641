/* @flow */

/**
 *
 * @param {*} eventCategory
 * @param {*} eventAction
 * @param {*} eventLabel
 * @description Push events to analytics server (GA)
 */
export const analyticsEvent = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string | number
) => {
  const isProduction = process.env.NODE_ENV === 'production'
  const ga = window.ga
  if (isProduction && ga) {
    ga('send', 'event', {
      hitType: 'event',
      eventCategory,
      eventAction,
      eventLabel
    })
  }
}

export const pushDataLayer = (data: Object) => {
  window.setDataLayerData && window.setDataLayerData(data)
}
