import styled, { css } from 'styled-components'

export const Menu = styled.div`
  position: absolute;
  z-index: 30;
  ${({ _coords: { top, left }, isEditing }) => {
    return isEditing
      ? css`
          top: ${top}px;
          left: ${left}px;
        `
      : css`
          bottom: 0;
          right: 0;
          transform: translateY(100%);
        `
  }}
`

export const MenuItem = styled.div`
  display: flex;
`
