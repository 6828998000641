/* @flow */
import React, { useContext, memo } from 'react'
import { Controls } from '@website/common/components/Controlled'
import { EditingContext } from '@contexts'
import S from './RSVPButton.styled'
import ActionGetter from './Actions'
import type { TRSVPButton } from './RSVPButton.type'
import { Spinner } from '@website/common/styles/styles'
import useEventSettingsOpener from '@hooks/useEventSettingsOpener'

const RSVPButton = memo(({ buttonText, onClick, isLoading }: TRSVPButton) => {
  const Actions = ActionGetter()
  const { isEditing } = useContext(EditingContext)

  const { editButton } = useEventSettingsOpener()

  return (
    <S.ButtonWrap>
      <Controls
        actions={Actions.eventSettingsActionMaker({
          onControlClick: editButton
        })}
      >
        <S.Button
          isEditing={isEditing}
          onClick={onClick}
          className="WM_GLOBAL_paragraph"
        >
          {buttonText}
          {isLoading ? <Spinner className="spinner" /> : null}
        </S.Button>
      </Controls>
    </S.ButtonWrap>
  )
})

export default RSVPButton
