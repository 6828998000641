import styled, { css } from 'styled-components'

export const MobileControlWrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #c1c9e0;
  border-radius: 5px;
  background: transparent linear-gradient(180deg, #ffffff 0%, #edf5ff 100%) 0 0
    no-repeat padding-box;
  box-shadow: 0 3px 12px #4272de33;
  box-sizing: border-box;

  .control-icon {
    fill: #545f7e;
  }
`

export const SlideUpContent = styled.div`
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
`

export const SlideUpHeader = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #c1c9e0;
  box-sizing: border-box;
  padding: 0 25px;

  p {
    color: #363f5a;
    font-size: 14px;
    font-weight: 600;
  }

  .arrow-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%) rotate(-90deg);
    fill: #545f7e;
  }
`

export const MobileControl = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #c1c9e0;
  border-radius: 6px;
  padding: 0 15px;
  box-sizing: border-box;
  transition: all 300ms ease;
  overflow: hidden;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          pointer-events: none;
          border: 1px solid rgba(193, 201, 224, 0.5);
          &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '';
            width: 100%;
            height: 100%;
            background-color: #ffffff;
            opacity: 0.5;
          }
        `
      : ''}

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          height: 140px;
        `
      : css`
          height: 40px;
        `};

  p {
    color: #363f5a;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  svg {
    fill: #545f7e;
  }

  .info-text {
    font-size: 12px;
    color: #545f7e;
  }

  .divider {
    width: 1px;
    height: 20px;
    background-color: #c1c9e0;
    margin: 0 5px;
  }
`
