/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Text16.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
`

const WMCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  ${({ isEditing }) => (isEditing ? 'margin-top: 60px;' : null)}
  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
  ${({ theme }) =>
    css`
      flex-direction: ${theme.orientation === 'RIGHT' ? 'row-reverse' : 'row'};
    `}
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    ${({ theme }) =>
      css`
        align-items: ${theme.orientation === 'RIGHT'
          ? 'flex-end'
          : 'flex-start'};
      `}
  }
`

const BlocksContainer = styled.div`
  width: 100%;
  max-width: 800px;
  @media only screen and (max-width: 767px) {
    ${({ isExpandContent }) =>
      css`
        max-width: ${isExpandContent ? '600px' : '100%'};
      `}
  }
  @media only screen and (max-width: 700px) {
    max-width: 100%;
  }

  > div {
    padding-top: 30px;
  }
  > div:first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const AdditionalBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 150px;
  min-width: 150px;
  @media only screen and (max-width: 700px) {
    margin-bottom: 30px;
  }
  .control-container {
    margin-bottom: 0;
  }
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
          padding-right: 30px;
          margin-right: 40px;
          @media only screen and (min-width: 701px) {
            justify-content: flex-end;
          }
        `
      : css`
          padding-left: 30px;
          margin-left: 40px;
        `};
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    border-radius: ${({ theme }) => (theme.look === 'SHARP' ? '' : '2px')};

    right: -4px;
    @media only screen and (max-width: 700px) {
      right: initial;
      left: 0px;
    }

    @media only screen and (max-width: 700px) {
      height: 4px;
      width: 80px;
      top: 100%;
      background: transparent
        linear-gradient(
          270deg,
          ${({ theme }) => theme.palette.primaryLight} 0%,
          ${({ theme }) => theme.palette.primaryDark} 100%
        )
        0% 0% no-repeat padding-box;
    }
    background: transparent
      linear-gradient(
        180deg,
        ${({ theme }) => theme.palette.primaryLight} 0%,
        ${({ theme }) => theme.palette.primaryDark} 100%
      )
      0% 0% no-repeat padding-box;
  }
  @media only screen and (max-width: 700px) {
    margin: 0 0 20px 0;
    width: 100%;
  }
`

const AdditionalBlockTitle = styled.h2`
  color: ${getStyleForProp('additionalBlockTitle')};
  margin-bottom: ${({ isMarginBottom }) => (isMarginBottom ? '5px' : '0')};

  text-align: right;
  @media only screen and (max-width: 767px) {
    text-align: left;
  }
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
          padding-left: 30px @media only screen and (max-width: 767px) {
            padding-right: 10px;
          }
        `
      : css`
          padding-right: 30px;
          @media only screen and (max-width: 767px) {
            padding-right: 10px;
          }
        `};
`

const AdditionalBlockParagraph = styled.p`
  color: ${getStyleForProp('additionalBlockParagraph')};
  text-align: right;
  @media only screen and (max-width: 767px) {
    text-align: left;
    margin-bottom: 10px;
  }
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
          @media only screen and (max-width: 767px) {
            padding-right: 10px;
          }
        `
      : css`
          padding-right: 30px;
          @media only screen and (max-width: 767px) {
            padding-right: 10px;
          }
        `};
`

const Block = styled.div`
  width: 100%;
  position: relative;
  padding: ${({ isEditing }) => (isEditing ? '30px 0' : '0')};
`

const ControlContainer = styled.div`
  .control-container {
    width: 100%;
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`
const ImageWrapper = styled.div`
  position: relative;
  padding-top: 50%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 50%;
    position: relative;
    border-radius: ${getStyleForProp('cardRadius')};
    box-shadow: rgb(37 43 52 / 12%) 0px 9px 22px 0px;
  }
`

const AddButtonsWrapper = styled.div`
  display: flex;
  ${({ theme }) =>
    css`
      justify-content: ${theme.orientation === 'RIGHT'
        ? 'flex-end'
        : 'flex-start'};
    `}
`

export default {
  StyledContainer,
  WMCustomContainer,
  BlocksContainer,
  ControlContainer,
  Block,
  BlockTitle,
  BlockParagraph,
  ImageWrapper,
  Img,
  AdditionalBlockWrapper,
  AdditionalBlockTitle,
  AdditionalBlockParagraph,
  AddButtonsWrapper
}
