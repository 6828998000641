//@flow
import React, { memo, useCallback, useState } from 'react'

import * as Styled from './styled'
import type { TInputProps } from './types'
import InputComp from '@website/common/components/Input'
import { translate } from '@editor/common/utils/translations'
import { allowOnlyNumbersAndDot } from './utils'
import { transformTextVariables } from '@editor/common/utils/text-transformation'

export const Input = ({
  type = 'text',
  inputKey,
  value,
  label,
  charLimit,
  className,
  placeholder,
  isDisabled,
  errorMessage: errorMessageFromProps,
  setData,
  setErrorMessages
}: TInputProps) => {
  const [errorMessage, setErrorMessage] = useState('')

  const hasError = !!errorMessage || !!errorMessageFromProps

  const handleChange = useCallback(
    e => {
      const { value } = e.target

      if (charLimit && value.length > charLimit) {
        setErrorMessage(
          transformTextVariables(translate('max_char_message'), [
            { value: charLimit }
          ])
        )
        return
      }

      setData(data => ({
        ...data,
        [inputKey]: value
      }))

      errorMessage && setErrorMessage('')
      errorMessageFromProps &&
        setErrorMessages(errorMessages => ({
          ...errorMessages,
          [inputKey]: ''
        }))
    },
    [inputKey, charLimit, errorMessage, errorMessageFromProps, setData]
  )

  const handleBlur = useCallback(
    e => {
      if (type === 'number') {
        if (e.target.value === '.' || e.target.value === '') return

        const amount = +e.target.value

        setData(data => ({
          ...data,
          [inputKey]: amount.toFixed(2).replace(/\.00$/, '')
        }))
      }

      errorMessage && setErrorMessage('')
    },
    [inputKey, errorMessage, setData]
  )

  const handleKeyPress = useCallback(e => {
    if (type !== 'number') return

    allowOnlyNumbersAndDot(e)
  }, [])

  const onRemoveIconClick = useCallback(() => {
    setData(data => ({
      ...data,
      [inputKey]: ''
    }))

    errorMessage && setErrorMessage('')
  }, [errorMessage, setData])

  return (
    <Styled.InputWrapper className={className || 'paypal-input'}>
      <Styled.InputLabel>{label}</Styled.InputLabel>
      <InputComp
        disabled={isDisabled}
        value={value}
        error={errorMessage || errorMessageFromProps}
        placeholder={placeholder}
        placeholderColor="#8493BD"
        borderColor="#C1C9E0"
        borderHoverColor="#8493BD"
        onChange={handleChange}
        onBlur={handleBlur}
        onKeyPress={handleKeyPress}
        onRemoveIconClick={onRemoveIconClick}
      />
      {charLimit ? (
        <Styled.CharLimit hasError={hasError}>
          {value?.length}/{charLimit}
        </Styled.CharLimit>
      ) : null}
    </Styled.InputWrapper>
  )
}

export default memo(Input)
