/* @flow */
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

import Modal from '@website/common/components/Modal'
import If from '@website/common/components/Conditional'
import { isToday, copyToClipboard } from '@website/common/utils'
import { getSharedLinkData, getSiteId } from '@redux/selectors'
import { secondary } from '@website/common/styles/colors'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import Button from '@renderforest/rf-ui-library/atoms/Button'
import BusyWrapper from '@renderforest/rf-ui-library/atoms/BusyWrapper'
import {
  deleteShareLink,
  closeLinkSharePopup,
  createShareableLink,
  getShareLinkData
} from '@redux/actions/editor'

import image from './Images/illustration.svg'
import Loader from './Images/loader.svg'
import DeleteLoader from './Images/deleteLoader.svg'
import DateSelectForm from './DateSelectForm/DateSelectForm'
import { getShareableLink } from './utils'
import type { TPopupProps, TPopupState } from './types.js'
import * as S from './styled.js'
import './index.sass'

const mapDispatchToProps = {
  getShareLinkData,
  deleteShareLink,
  closeLinkSharePopup,
  createShareableLink
}

const mapStateToProps = (state: TAppState) => {
  return {
    shareLinkData: getSharedLinkData(state),
    siteId: getSiteId(state)
  }
}

class LinkSharePopup extends PureComponent<TPopupProps, TPopupState> {
  state = {
    day: '',
    time: new Date(),
    copied: false,
    waitingForLink: false,
    showDeleteLoading: false,
    showExpiredLinkMessage: false
  }

  removeExpiredLink = () => {
    this.deleteLink()
    this.setState({ showExpiredLinkMessage: true })
  }

  componentDidMount() {
    const { expireDate } = this.props.shareLinkData.payload
    if (!expireDate) {
      this.props.getShareLinkData()
    } else if (!this.isValidExpiredDate()) {
      this.removeExpiredLink()
    }
  }
  componentDidUpdate(prevProps) {
    const { payload } = this.props.shareLinkData
    const { expireDate } = payload
    const prevExpiredDate = prevProps.shareLinkData.payload.expireDate
    if (
      expireDate &&
      prevExpiredDate !== expireDate &&
      !this.isValidExpiredDate()
    ) {
      this.removeExpiredLink()
    }
  }

  isValidDateSelected = (day, time) => {
    if (!day || !time) {
      return false
    }
    if (!isToday(day)) {
      return true
    }
    return moment().diff(time.toISOString(), 'minutes') < 0
  }
  getPreparedDate = () => {
    const { day, time } = this.state
    return `${day.getFullYear()}-${
      day.getMonth() + 1
    }-${day.getDate()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}${moment().format(
      'Z'
    )}`
  }

  copyLink = () => {
    const { link } = this.props.shareLinkData.payload
    const { siteId } = this.props
    this.setState({ copied: true })
    copyToClipboard(getShareableLink(link, siteId))
    setTimeout(() => this.setState({ copied: false }), 4000)
  }

  createLink = () => {
    this.setState({ waitingForLink: true, showExpiredLinkMessage: false })
    const preparedDate = this.getPreparedDate()
    this.props.createShareableLink(preparedDate, () =>
      this.setState({ waitingForLink: false })
    )
  }

  isValidExpiredDate = () => {
    const { expireDate } = this.props.shareLinkData.payload
    if (!expireDate) {
      return false
    }
    const date = moment(expireDate).utcOffset(moment().format('Z')).toDate()

    return moment().diff(date.toISOString(), 'minutes') < 0
  }

  deleteLink = () => {
    const { deleteShareLink } = this.props
    this.setState({ showDeleteLoading: true })
    deleteShareLink().then(() => {
      this.setState({ showDeleteLoading: false })
    })
  }

  render() {
    const {
      day,
      time,
      copied,
      waitingForLink,
      showDeleteLoading,
      showExpiredLinkMessage
    } = this.state
    const { closeLinkSharePopup, shareLinkData, siteId } = this.props
    const { isLoading, payload } = shareLinkData
    const { expireDate, link } = payload

    return (
      <Modal
        wrapClassname="shareLink-popup"
        onModalClose={closeLinkSharePopup}
        renderContent={() => (
          <S.Container className="content-container">
            <BusyWrapper isBusy={isLoading} />
            <Icon
              size="large"
              color={secondary.SemiLight}
              name="close"
              className="close-icon"
              onClick={closeLinkSharePopup}
            />
            <img src={image} className="illustration-img" alt="illustration" />
            <div className="popup-text-container">
              <div className="text-title">
                {translate('get_link_to_invite_contributor_label')}
              </div>
              <div className="text-content">
                {translate('get_link_to_invite_contributor_text')}
              </div>
              <p className="text-content note">
                {transformTextVariables(
                  translate('get_link_to_invite_contributor_note'),
                  [
                    {
                      value: translate(
                        'get_link_to_invite_contributor_note_tr1'
                      ).toUpperCase(),
                      Comp: BoldComp
                    }
                  ]
                )}
              </p>
            </div>
            <If
              condition={link && this.isValidExpiredDate()}
              then={() => (
                <>
                  <div className="link-date-container">
                    <S.LinkExpirationMessgae>
                      <span className="link-info-text">
                        {transformTextVariables(
                          translate('link_has_expires_on_label'),
                          [
                            {
                              value: moment(expireDate)
                                .utcOffset(moment().format('Z'))
                                .format('MMM D, YYYY hh:mm A'),
                              Comp: BoldComp
                            }
                          ]
                        )}
                      </span>
                    </S.LinkExpirationMessgae>
                    <S.Link className="info-text">
                      {getShareableLink(link, siteId)}
                    </S.Link>
                  </div>
                  <S.LinkEditButtonsContainer>
                    <Button
                      size="small"
                      className="edit-link-button"
                      fit
                      type="default"
                      onClick={this.deleteLink}
                      disabled={showDeleteLoading || copied}
                    >
                      <span>{translate('delete_label', true)}</span>
                      <If
                        condition={showDeleteLoading}
                        then={() => (
                          <div className="loader-img">
                            <img src={DeleteLoader} alt="Loading animation" />
                          </div>
                        )}
                      />
                    </Button>
                    <Button
                      className="edit-link-button"
                      fit
                      size="small"
                      type="secondary"
                      disabled={copied || showDeleteLoading}
                      onClick={this.copyLink}
                    >
                      {copied
                        ? translate('copied_label', true)
                        : translate('copy_label', true)}
                    </Button>
                  </S.LinkEditButtonsContainer>
                </>
              )}
              otherwise={() => (
                <>
                  <If
                    condition={showExpiredLinkMessage}
                    then={() => (
                      <S.ExpiredLinkMessage>
                        <Icon
                          size="small"
                          color="#756CE9"
                          name="info_filled"
                          className="error-icon"
                        />
                        <p>{translate('prev_link_has_expired_label')}</p>
                      </S.ExpiredLinkMessage>
                    )}
                  />
                  <DateSelectForm
                    day={day}
                    time={time}
                    waitingForLink={waitingForLink}
                    onDaySelect={day => this.setState({ day })}
                    onTimeSelect={time => this.setState({ time })}
                  />
                  <Button
                    disabled={!this.isValidDateSelected(day, time)}
                    className="get-link-button"
                    fit
                    size="small"
                    type="secondary"
                    onClick={this.createLink}
                  >
                    <span>{translate('get_the_link_label', true)}</span>
                    <If
                      condition={waitingForLink}
                      then={() => (
                        <div className="loader-img">
                          <img src={Loader} alt="Loading animation" />
                        </div>
                      )}
                    />
                  </Button>
                </>
              )}
            />
          </S.Container>
        )}
      />
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkSharePopup)
