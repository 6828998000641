/* @flow */
import React from 'react'
import Slider from 'react-slick'
import S from './Feature22.styled'
import { validation } from './Feature22.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import '../Swipe/swipe-slider-styles.css'
import { sliderSettings } from './consts'
import Arrow from './Arrows'
import LeftArrowIcon from '@website/common/assets/svgr-icons/keyboard_arrow_left.svg'
import RightArrowIcon from '@website/common/assets/svgr-icons/keyboard_arrow_right.svg'
import { translate } from '@editor/common/utils/translations'

class Feature22 extends React.PureComponent {
  state = { activeTabIdx: 0 }

  setActiveTabIdx = idx => {
    this.setState({ activeTabIdx: idx })
  }

  beforeRemove = () => {
    const { activeTabIdx } = this.state
    if (activeTabIdx !== 0) {
      this.setState({ activeTabIdx: activeTabIdx - 1 })
    }
  }

  onPrevArrowClick = () => {
    const { activeTabIdx } = this.state

    this.setActiveTabIdx(activeTabIdx - 1)
  }

  onNextArrowClick = () => {
    const { activeTabIdx } = this.state

    this.setActiveTabIdx(activeTabIdx + 1)
  }

  onSwipe = direction => {
    const { activeTabIdx } = this.state
    const { slides } = this.props.data

    if (direction === 'left') {
      if (activeTabIdx === slides.length - 1) {
        return
      }

      this.onNextArrowClick()
      return
    }

    if (direction === 'right') {
      if (activeTabIdx === 0) {
        return
      }

      this.onPrevArrowClick()
    }
  }

  render() {
    const {
      data: { title, paragraph, titleAlignment, paragraphAlignment, slides }
    } = this.props
    const Actions = ActionGetter(this.context)
    const { activeTabIdx } = this.state

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.StyledContainer isEditing={isEditing}>
              <Show when={[title, paragraph]}>
                <S.Headings>
                  <EditableContent
                    text={title}
                    alignment={titleAlignment}
                    as={S.Title}
                    maxCount={validation.titleMaxChar}
                    className="WM_GLOBAL_heading42"
                    onChange={Actions.changeTitle}
                    changeAlignment={Actions.changeTitleAlignment}
                  />
                  <EditableContent
                    text={paragraph}
                    alignment={paragraphAlignment}
                    as={S.Paragraph}
                    maxCount={validation.paragraphMaxChar}
                    className="WM_GLOBAL_paragraph18"
                    onChange={Actions.changeParagraph}
                    changeAlignment={Actions.changeParagraphAlignment}
                  />
                </S.Headings>
              </Show>
              <Slider
                onSwipe={this.onSwipe}
                {...sliderSettings}
                prevArrow={
                  <Arrow
                    toShow={activeTabIdx !== 0}
                    SvgComp={LeftArrowIcon}
                    className="left"
                    callbackFunction={this.onPrevArrowClick}
                  />
                }
                nextArrow={
                  <Arrow
                    toShow={activeTabIdx !== slides.length - 1}
                    SvgComp={RightArrowIcon}
                    className="right"
                    callbackFunction={this.onNextArrowClick}
                  />
                }
              >
                {slides.map((slide, idx) => {
                  const showRemove = slides.length > validation.slides.min
                  return (
                    <S.ControlContainer key={idx} isEditing={isEditing}>
                      <ControlsWithModalOpener
                        actions={Actions.blockActions({
                          idx,
                          showRemove,
                          beforeRemove: this.beforeRemove
                        })}
                        style={{ width: '100%' }}
                      >
                        <S.ItemWrapper
                          onClick={() => this.setActiveTabIdx(idx)}
                          isActive={activeTabIdx === idx}
                        >
                          <S.IconWrapper>
                            <ControlsWithModalOpener
                              actions={Actions.iconActions(idx)}
                            >
                              <S.Circle>
                                <Icon
                                  color="white"
                                  size="large"
                                  name={slide.iconName}
                                />
                              </S.Circle>
                            </ControlsWithModalOpener>
                          </S.IconWrapper>
                          <EditableContent
                            isLinkControlHidden
                            text={slide.title}
                            as={S.BlockTitle}
                            required={validation.blockTitle.required}
                            maxCount={validation.blockTitleMaxChar}
                            className="WM_GLOBAL_paragraph14"
                            onChange={(newTitle: string) => {
                              Actions.changeBlockTitle(newTitle, idx)
                            }}
                          />
                        </S.ItemWrapper>
                      </ControlsWithModalOpener>
                    </S.ControlContainer>
                  )
                })}
              </Slider>
              <AddButton
                onAdd={Actions.addBlock}
                toShow={slides.length < validation.slides.max}
                style={{
                  margin: '30px 0'
                }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_tab_label')}
              </AddButton>
              <S.ImgControlContainer isEditing={isEditing}>
                <S.ImageWrapper>
                  <ControlsWithImageCropper
                    style={{ width: '100%' }}
                    actions={Actions.imageActions(
                      activeTabIdx,
                      slides[activeTabIdx].imgUrl
                    )}
                  >
                    <Image
                      as={S.Img}
                      alt="Feature image"
                      role="tabpanel"
                      asProps={{
                        imgCropParams: slides[activeTabIdx].imgCropParams
                      }}
                      defaultImgSrc={slides[activeTabIdx].imgUrl}
                      sizes={slides[activeTabIdx].imgDimensions}
                    />
                  </ControlsWithImageCropper>
                </S.ImageWrapper>
              </S.ImgControlContainer>
            </S.StyledContainer>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

Feature22.contextType = DispatchContext

export default Feature22
