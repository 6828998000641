/* @flow */
import React, { useContext, memo } from 'react'
import S from './Video2.styled'
import { validation } from './Video2.consts'
import ActionGetter from './Actions'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import LinkWrapper from '@website/common/components/LinkWrapper'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Video2 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      videoUrl,
      blocks,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const renderBlocks = (block: string, idx: number) => (
    <S.BlockWrap key={idx}>
      <ControlsWithModalOpener
        actions={Actions.blockActions({ idx })}
        style={{ width: '100%' }}
      >
        <S.Block>
          <S.IconTitleWrap>
            <S.IconWrap>
              <ControlsWithModalOpener
                actions={Actions.iconActions({ idx, showRemove: false })}
              >
                <S.Icon name={block.icon} size="xlarge" />
              </ControlsWithModalOpener>
            </S.IconWrap>
            <EditableContent
              as={S.BlockTitle}
              text={block.title}
              maxCount={validation.maxBlockTitle}
              className="WM_GLOBAL_heading20"
              onChange={(newBlockTitle: string) => {
                Actions.changeBlockTitle(newBlockTitle, idx)
              }}
            />
          </S.IconTitleWrap>
          <EditableContent
            as={S.BlockParagraph}
            text={block.paragraph}
            alignment={block.paragraphAlignment}
            maxCount={validation.maxBlockParagraph}
            className="WM_GLOBAL_paragraph18"
            onChange={(newBlockParagraph: string) => {
              Actions.changeBlockParagraph(newBlockParagraph, idx)
            }}
            changeAlignment={(alignment: string) =>
              Actions.changeBlockParagraphAlignment(alignment, idx)
            }
          />
          <S.LinkWrap className="WM_GLOBAL_secondary-font">
            <If
              condition={block.buttonText}
              then={() => (
                <ControlsWithModalOpener
                  actions={Actions.linkActions({
                    isAdditionalLink: true,
                    linkIdx: idx,
                    linkData: {
                      text: block.buttonText,
                      url: block.buttonUrl
                    }
                  })}
                >
                  <LinkWrapper
                    text={block.buttonText}
                    url={block.buttonUrl}
                    linkProps={{ className: 'learn-more-link' }}
                  >
                    <S.Icon
                      size="large"
                      name="keyboard_arrow_right"
                      className="arrow-right"
                    />
                  </LinkWrapper>
                </ControlsWithModalOpener>
              )}
            />
            <AddButton
              onAdd={() => dispatcher(Actions.addLink(idx))}
              toShow={block.buttonText === ''}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_link_label')}
            </AddButton>
          </S.LinkWrap>
        </S.Block>
      </ControlsWithModalOpener>
    </S.BlockWrap>
  )
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <Show when={[title, paragraph]}>
        <S.TextContent>
          <Show when={[title]}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleChar}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
          </Show>
          <Show when={[paragraph]}>
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.maxParagraphChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </Show>
        </S.TextContent>
      </Show>
      <S.WmCustomContainer>
        <Show when={[blocks.length > 0]}>
          <S.BlocksWrap>
            <Mapper data={blocks} render={renderBlocks} />
            <AddButton
              style={{ marginTop: '16px' }}
              onAdd={Actions.addBlock}
              toShow={blocks.length < validation.blocks.max}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_block_label')}
            </AddButton>
          </S.BlocksWrap>
        </Show>
        <S.VideoContainer>
          <ControlsWithModalOpener
            actions={Actions.videoActions(false, videoUrl)}
            style={{ width: '100%' }}
          >
            <S.VideoContent>
              <S.VideoWrap>
                <Overlay />
                <LazyVideoPlayer
                  key={videoUrl}
                  style={{
                    top: 0,
                    left: 0,
                    position: 'absolute'
                  }}
                  controls
                  width="100%"
                  height="100%"
                  url={videoUrl}
                />
              </S.VideoWrap>
            </S.VideoContent>
          </ControlsWithModalOpener>
        </S.VideoContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Video2
