/* @flow */
import {
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import { addBlock, addIcon } from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { idx, newTitle }
    })
  }
  const changeBlockParagraph = (paragraph, idx) =>
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { idx, paragraph }
    })

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const _addIcon = idx => {
    dispatcher(addIcon(idx))
  }

  const changeTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: _addBlock,
    changeBlockTitle,
    changeBlockParagraph,
    addIcon: _addIcon,
    changeTitleAlignment,
    changeParagraphAlignment,
    blockActions: removeBlockActionMaker(dispatcher),
    iconActions: iconActionMaker(dispatcher)
  }
}
