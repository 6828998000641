/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Clients3.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { secondary } from '@website/common/styles/colors'

import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  background-attachment: fixed !important;
  @media only screen and (max-width: 768px) {
    background-attachment: initial !important;
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
`

const CardWrapper = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 40px;
  box-sizing: border-box;
  border-radius: ${getStyleForProp('cardRadius')};
  background: ${getStyleForProp('cardBackgroundGradient')};
  backdrop-filter: blur(15px);
  box-shadow: ${({ theme }) =>
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  border: 1px solid ${getStyleForProp('border')};
  @media only screen and (max-width: 650px) {
    padding: 20px;
  }
`

const Card = styled.div`
  width: 100%;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.accentWhite};
  border-radius: ${getStyleForProp('cardRadius')};
  padding: 40px;
  > :first-child {
    padding-top: 0;
  }
  @media only screen and (max-width: 650px) {
    padding: 30px 15px;
  }
  .add-button-wrap {
    margin-top: 20px;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const LogosWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -20px auto 0 auto;
  padding-top: 40px;
  max-width: ${({ areFiveBlocks }) => (areFiveBlocks ? '700px' : '800px')};
  @media only screen and (max-width: 1100px) {
    padding-top: 40px;
  }
  .control-container {
    &:hover {
      box-shadow: 0px 0px 0px 1px ${secondary.Dark};
    }
  }
`

const LogoFigure = styled.div`
  width: 160px;
  min-width: 160px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 0 20px;
  .failed-image-container {
    width: 160px;
    height: 80px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  padding-top: 20px;
`

const Img = styled.img`
  width: 100%;
  height: auto;
  transition: all 0.1s linear;
  max-width: 160px;
  max-height: 80px;
  display: block;
  object-fit: contain;
  &:hover {
    opacity: 1;
  }
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
`

export default {
  Img,
  StyledContainer,
  CardWrapper,
  Card,
  WmCustomContainer,
  TextContainer,
  LogosWrap,
  LogoFigure,
  Title,
  Description
}
