/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const MorphRight = () => {
  return (
    <svg
      className="feature4_2_morph"
      width="601px"
      height="435px"
      viewBox="0 0 601 435"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        fillOpacity="0.2"
      >
        <g
          id="features-4-1366x768"
          transform="translate(-700.000000, -85.000000)"
        >
          <g id="Group" transform="translate(700.000000, 85.000000)">
            <path
              d="M394.970509,0.39108351 C361.995497,-2.44445123 281.320789,9.54901258 241.704953,55.8087413 C202.089116,102.06847 215.569116,159.714497 174.187374,204.308593 C132.805633,248.902689 80.1585099,239.993769 74.284218,309.87932 C68.4099261,379.764871 154.811976,414.379156 220.607702,428.55683 C286.403428,442.734504 393.640466,429.836475 459.348274,397.296887 C525.056081,364.757298 600.182985,281.178084 600.182985,208.802887 C600.182985,136.42769 573.502573,93.2754085 532.476137,55.8087413 C491.449702,18.3420741 427.945521,3.22661825 394.970509,0.39108351 Z"
              id="Path-2"
            />
            <path
              d="M76.6146831,99.2381959 C68.7696739,98.5619028 51.4621285,98.5619028 40.6381634,110.066106 C29.8141984,121.570308 29.7966996,134.703586 19.9516646,145.339561 C10.1066296,155.975536 1.46515694,158.980021 0.067617656,175.648168 C-1.32992163,192.316315 19.2257915,200.572042 34.8791015,203.953507 C50.5324115,207.334972 76.0449288,204.258711 91.6773222,196.497812 C107.309716,188.736914 125.182985,168.802741 125.182985,151.540797 C125.182985,134.278854 118.835509,123.986761 109.075004,115.050723 C99.3144993,106.114685 84.4596923,99.9144889 76.6146831,99.2381959 Z"
              id="Path-2-Copy"
              transform="translate(62.591492, 152.157579) scale(-1, -1) translate(-62.591492, -152.157579) "
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default withTheme(MorphRight)
