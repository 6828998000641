import styled, { css } from 'styled-components'
import { scrollBarStyles } from '@editor/common/styles'
import { mediaMaxWidth } from '@editor/common/utils/styled'
import { BREAKPOINT_800 } from '@containers/edit/consts'
import { globalColors } from '@renderforest/rf-ui-library/consts/colors'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  ${mediaMaxWidth(BREAKPOINT_800)`
    display: ${({ isContentVisible }) => (isContentVisible ? 'none' : 'flex')};
  `}
`

export const ContentHeader = styled.div`
  padding: 15px 15px 0;
  user-select: none;
  ${mediaMaxWidth(BREAKPOINT_800)`
    padding: 0 15px 0;
  `}
`

export const ContentMainSection = styled.div`
  flex-grow: 1;
  padding: 0 15px;
  user-select: none;
  overflow: scroll;
  position: relative;
  ${mediaMaxWidth(BREAKPOINT_800)`
    padding: 10px 15px 15px;
  `}
  ${({ showShadow }) =>
    showShadow
      ? css`
          box-shadow: inset 0 13px 8px -10px rgba(0, 82, 224, 0.2);
        `
      : ''}

  ${scrollBarStyles}
`

export const MainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMaxWidth(BREAKPOINT_800)`
    flex-direction: row;
    align-items: ${({ isAlignedCenter }) =>
      isAlignedCenter ? 'center' : 'stretch'};
  `}
`

export const ContentTitle = styled.p`
  ${mediaMaxWidth(BREAKPOINT_800)`
    display: none;
  `};
  font-size: 12px;
  color: ${globalColors.secondaryDark};
  font-weight: 500;
  padding-bottom: 10px;
  border-bottom: 1px solid ${globalColors.secondaryExtraLight};
`

export const ContentSubTitle = styled.p`
  font-size: 12px;
  color: ${globalColors.secondaryDark};
  padding: 15px 0 10px;
`
