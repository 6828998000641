import { createSelector } from 'reselect'

export const selectCookieSettings = state => state.cookieSettings

export const selectCookieSettingsData = createSelector(
  selectCookieSettings,
  state => state?.data
)

export const selectSimpleCookies = createSelector(
  selectCookieSettingsData,
  state => state?.simple
)

export const selectGDPRCookies = createSelector(
  selectCookieSettingsData,
  state => state?.gdpr
)

export const selectGDPRPrivacyPolicy = createSelector(
  selectGDPRCookies,
  state => state?.privacyPolicyBtn
)

export const selectIsCookiesLoading = createSelector(
  selectCookieSettings,
  state => state?.isLoading
)
