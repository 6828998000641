/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Testimonials1.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isTextsDeleted, isEditing }) =>
    isEditing && !isTextsDeleted
      ? '140px 0 80px 0'
      : isTextsDeleted
      ? '30px 0 60px 0'
      : '80px 0'};
  @media only screen and (max-width: 457px) {
    padding: ${({ isTextsDeleted }) =>
      isTextsDeleted ? '20px 0 30px 0' : '60px 0'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .slick-slide > div {
    margin: 25px;
    @media only screen and (max-width: 650px) {
      margin: 25px 0;
    }
  }
  .dots-wrap {
    bottom: 30px;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : '')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  padding-top: 20px;
  position: relative;
  z-index: 2;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Img = styled.img`
  max-width: 150px;
  max-height: 100px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
`

const IconContainer = styled.button`
  padding: 0;
  border: none;
  width: 50px;
  min-width: 50px;
  margin: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  ${getStyleForProp('buttonBackground')};
  &:focus {
    outline: 1px solid #5690ff;
  }
  @media only screen and (max-width: 760px) {
    display: none;
  }
`

const SliderParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-size: 16px;
  line-height: 1.4;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Dot = styled.button`
  padding: 0;
  border: none;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: 1px solid #5690ff;
  }
`

const TextContainer = styled.div`
  width: 100%;
  background: white;
  border-bottom-left-radius: ${getStyleForProp('cardRadius')};
  border-bottom-right-radius: ${getStyleForProp('cardRadius')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  .control-container {
    width: 100%;
  }
`

const SlideContainer = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  @media only screen and (max-width: 400px) {
    padding: 30px 12px;
  }
  .rf-add-btn {
    white-space: nowrap;
  }
`

const SliderWrapper = styled.div`
  margin: 30px;
  @media only screen and (max-width: 650px) {
    margin: 30px 14px;
  }
`

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
  box-shadow: ${getStyleForProp('raisedShadow')};
`

const ClientWrap = styled.div`
  display: flex;
  align-self: flex-start;
  margin-top: 30px;
  width: 100%;
`

const ClientImageWrapper = styled.div`
  width: 50px;
  height: 50px;
`

const ClientImgWrap = styled.div`
  width: 50px;
  height: 50px;
  .control-container {
    width: 100%;
    height: 100%;
  }
`

const ClientImg = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: ${getStyleForProp('iconRadius')};
`
const ClientInfo = styled.div`
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  .control-container {
    width: 100%;
  }
  .control-container:first-child {
    margin-bottom: 10px;
    h3 {
      margin-bottom: 0;
    }
  }
`

const ClientName = styled.h3`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.primaryDark};
  margin: ${({ isEditing }) => (isEditing ? '0 0 12px 0' : '0')};
  width: 100%;
  ${getTextStyleByAlignment};
`

const CLientPosition = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.primaryDark};
  width: 100%;
  ${getTextStyleByAlignment};
`

const ImageSlideContainer = styled.div`
  margin: -55px 0 -10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  position: relative;
  padding-top: ${({ isEditing }) => (isEditing ? '70px' : '50px')};
`

const ImageFigure = styled.div`
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SliderArrow = styled.button`
  display: ${({ isEditing }) => (isEditing ? 'flex' : 'none')};
  width: 42px;
  min-width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #c1c9e0;
  box-shadow: 0 6px 12px 0 #3271e626;

  &:focus {
    outline: 1px solid #5690ff;
  }

  & > svg {
    fill: #545f7e;
  }

  ${({ isRight }) =>
    isRight
      ? css`
          & > svg {
            transform: rotate(180deg);
          }
        `
      : ''}

  &:hover {
    & > svg {
      fill: #252e48;
    }
  }

  @media only screen and (max-width: 650px) {
    &.wm-arrow {
      position: absolute;
      z-index: 3;
      left: 52%;
      transform: translateY(-30px);
    }
    &.left {
      transform: translate(-45px, -30px);
    }
  }
`

export default {
  Img,
  Dot,
  Title,
  StyledContainer,
  WmCustomContainer,
  Paragraph,
  TextContainer,
  IconContainer,
  ImageContainer,
  SliderWrapper,
  SlideContainer,
  SliderParagraph,
  ClientWrap,
  ClientImgWrap,
  ClientImg,
  ClientInfo,
  ClientName,
  CLientPosition,
  ImageSlideContainer,
  SliderArrow,
  ImageFigure,
  ClientImageWrapper
}
