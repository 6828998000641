export const validation = {
  titleMaxChar: 100,
  paragraphMaxChar: 300,
  blockTitleMaxChar: 50,
  title: { required: true },
  blocks: {
    min: 1,
    max: 4,
    title: { required: true }
  },
  buttons: {
    min: 0,
    max: 2
  }
}
