/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_BLOCK_TITLE':
        const { newBlockTitle, idx } = action.value
        draft.blocks[idx].title = newBlockTitle
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        const { newBlockParagraph, idx: _idx } = action.value
        draft.blocks[_idx].paragraph = newBlockParagraph
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.blocks[idx].paragraphAlignment = alignment
        break
      }
      case 'CHANGE_VIDEO_URL':
        draft.videoUrl = action.value.url
        break
      case 'REMOVE_BLOCK':
        draft.blocks.splice(action.value.idx, 1)
        break
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.blocks[linkIdx].buttonUrl = linkData.link
        draft.blocks[linkIdx].buttonText = linkData.text
        break
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.blocks[idx].buttonUrl = ''
        draft.blocks[idx].buttonText = ''
        break
      }
      case 'ADD_LINK': {
        const { idx } = action.value
        const { buttonText, buttonUrl } = initialState.blocks[0]
        draft.blocks[idx].buttonUrl = buttonUrl
        draft.blocks[idx].buttonText = buttonText
        break
      }
      case 'CHANGE_ICON': {
        const { idx, iconName } = action.value
        draft.blocks[idx].icon = iconName
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
