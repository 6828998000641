/* @flow */
import React, { useContext, memo } from 'react'
import S from './Music2.styled'
import { validation } from './Music2.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { translate } from '@editor/common/utils/translations'

const isIframe = url => {
  return url.startsWith('<iframe')
}

const Music2 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      musicUrls,
      titleAlignment,
      paragraphAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.TextContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.maxTitleChar}
                className="WM_GLOBAL_heading54"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.maxParagraphChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.TextContent>
        </Show>
        <S.MusicWrapper>
          <Mapper
            data={musicUrls}
            render={(musicUrl, idx) => (
              <S.MusicContent>
                <ControlsWithModalOpener
                  actions={Actions.musicActions(idx, musicUrls)}
                  style={{ width: '100%' }}
                  key={idx}
                >
                  {isIframe(musicUrl) ? (
                    <S.IframeWrapper>
                      <Overlay />
                      <div dangerouslySetInnerHTML={{ __html: musicUrl }} />
                    </S.IframeWrapper>
                  ) : (
                    <S.IframeWrapper>
                      <iframe
                        scrolling="no"
                        frameBorder="no"
                        src={`https://w.soundcloud.com/player/?url=${musicUrl}`}
                        title="Embedded music"
                      />
                    </S.IframeWrapper>
                  )}
                </ControlsWithModalOpener>
              </S.MusicContent>
            )}
          />
          <AddButton
            onAdd={Actions.addMusic}
            medium
            toShow={musicUrls.length < validation.musicUrls.max}
            style={{ marginTop: '30px' }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_music_label')}
          </AddButton>
        </S.MusicWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Music2
