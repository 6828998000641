/* @flow */
import styled from 'styled-components'

import THEME from './Feature3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
`
const WMCustomContainer = styled(WMContainer)`
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  padding-top: 20px;
`

const BlockCount = styled.h2`
  color: ${getStyleForProp('blockCount')};
  font-size: 54px;
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const BlockCountTitle = styled.p`
  color: ${getStyleForProp('blockCountTitle')};
  font-size: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
  font-weight: 600;
  margin-top: 10px;
`

const BlockDescription = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const BlocksWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${props => (props.isForBlock ? '900px' : '1200px')};
  margin-top: -50px;
  padding-top: 50px;
  @media only screen and (max-width: 1200px) {
    align-items: center;
    text-align: center;
    flex-direction: ${props => {
      if (props.blockCount === 3) {
        return 'column'
      }
      return 'row'
    }};
  }
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    margin-top: -40px;
    padding-top: 40px;
  }
  .link-section {
    margin-top: 30px;
  }
  .learn-more-link {
    color: ${getStyleForProp('anchor')};
    svg {
      fill: ${getStyleForProp('anchor')};
    }
  }
`

const FeatureItem = styled.article`
  max-width: 320px;
  width: 100%;
  margin: 50px 40px 0 40px;
  margin: ${props =>
    props.isForBlock ? '50px 50px 0 50px' : '50px 40px 0 40px'};
  @media only screen and (max-width: 1400px) {
    margin: 50px 20px 0 20px;
  }
  @media only screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 40px 0 0 0;
  }
  > p:first-child,
  > div:first-child,
  > h5:first-child {
    margin-top: 0;
  }
  & > .control-container {
    width: 100%;
    & > .control-container {
      margin-bottom: 20px;
      width: 100%;
      p {
        margin-top: 0;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
  > .control-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  Title,
  Description,
  BlockCount,
  BlockCountTitle,
  BlockDescription,
  BlocksWrap,
  FeatureItem
}
