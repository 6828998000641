// @flow

import React, {
  useEffect,
  useState,
  useContext,
  useMemo,
  useRef,
  useCallback
} from 'react'
import { ZoomOutContext } from '@contexts'
import * as Styled from './styled'
import {
  CLOSED_SIDEBAR_WIDTH,
  MAIN_SIDEBAR_WIDTH,
  SUB_SIDEBAR_WIDTH
} from './const'
import ResizeObserver from '@website/common/utils/ResizeObserver'

const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowWidth
}

const ZoomOutWrapper = ({ children }) => {
  const wrapperRef = useRef(null)
  const observerRef = useRef(null)

  const [wrapperDimensions, setWrapperDimensions] = useState({
    width: 0,
    height: 0
  })

  const { isMainSidebarExpanded, isSubSidebarOpened } =
    useContext(ZoomOutContext)

  const windowWidth = useWindowWidth()

  const mainSidebarZoomOutRatio = useMemo(
    () => (isMainSidebarExpanded ? MAIN_SIDEBAR_WIDTH / windowWidth : 0),
    [windowWidth, isMainSidebarExpanded]
  )

  const subSidebarZoomOutRatio = useMemo(
    () => (isSubSidebarOpened ? SUB_SIDEBAR_WIDTH / windowWidth : 0),
    [windowWidth, isSubSidebarOpened]
  )

  const zoomOutRatio =
    1 -
    CLOSED_SIDEBAR_WIDTH / windowWidth -
    mainSidebarZoomOutRatio -
    subSidebarZoomOutRatio.toFixed(4)

  const setDimensions = useCallback(() => {
    if (!wrapperRef.current) {
      return
    }

    const { width, height } = wrapperRef.current.getBoundingClientRect()

    setWrapperDimensions({ width, height })
  }, [wrapperRef.current])

  useEffect(() => {
    observerRef.current = new ResizeObserver(setDimensions)
  }, [setDimensions])

  useEffect(() => {
    observerRef.current.observe(wrapperRef.current)
  }, [wrapperRef.current])

  return (
    <Styled.ZoomOutContainer
      wrapperEl={wrapperRef.current}
      zoomOutRatio={zoomOutRatio}
      dimensions={wrapperDimensions}
    >
      <Styled.ZoomOutWrapper
        ref={wrapperRef}
        zoomOutRatio={zoomOutRatio}
        wrapperEl={wrapperRef.current}
        dimensions={wrapperDimensions}
      >
        {children}
      </Styled.ZoomOutWrapper>
    </Styled.ZoomOutContainer>
  )
}

export default ZoomOutWrapper
