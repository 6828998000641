/* @flow */
import styled from 'styled-components'
import THEME from './Text13.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  background-attachment: fixed !important;
  padding: ${({ withoutTexts }) => (withoutTexts ? '300px 0' : '150px 0')};
  @media only screen and (max-width: 1024px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0' : '80px 0')};
  }
  @media (pointer: coarse) {
    background-attachment: initial !important;
  }
  @media only screen and (max-width: 767px) {
    padding-top: ${({ isEditing }) => isEditing && '150px'};
  }
  @media only screen and (max-width: 700px) {
    padding: ${({ withoutTexts }) => (withoutTexts ? '200px 0' : '80px 0')};
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ withoutTexts }) => (withoutTexts ? '200px 0' : '60px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  .link-section {
    margin-top: 20px;
  }
  .link {
    color: ${getStyleForProp('heading')};
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('heading')};
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-top: 30px;
  line-height: 1.4;
`

const TextCompWrap = styled.div`
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  max-width: 800px;
  padding: 50px 60px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
  border-radius: ${getStyleForProp('borderRadius')};
  @media only screen and (max-width: 1024px) {
    padding: 40px;
    max-width: 550px;
  }
  @media only screen and (max-width: 457px) {
    padding: 40px 20px;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Description,
  TextCompWrap
}
