import styled from 'styled-components'
import Icon from '@website/common/components/Icon'

export const BgControlsWrap = styled.div`
  width: calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  padding: 0 20px;
  margin: 8px;
  box-sizing: border-box;
  border-radius: 7px;
  border: ${({ isActive }) =>
    isActive ? '1px solid #3D81FB' : '1px solid #DEE3EF'};
  p {
    color: ${({ isActive }) => (isActive ? '#3D81FB' : '#363F5A')};
  }
`
export const RightContent = styled.p`
  display: flex;
  align-items: center;
`

export const SlidesNumber = styled.p`
  font-size: 14px;
  font-weight: 500;
`

export const BgDescriptionText = styled.p`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  color: #363f5a;
  border-bottom: 1px solid #dee3ef;
  margin-bottom: 10px;
  padding: 12px 20px;
  box-sizing: border-box;
`

export const CustomizeSlidesContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  padding: 12px 20px;
  box-shadow: 0px 3px 6px #387dff1a;
  z-index: 2;
  top: 60px;
`

export const CustomizedSlidesText = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #7683a8;
`

export const DeleteIconWrap = styled(Icon)`
  margin-left: 15px;
`

export const CustumizeSlidesIcon = styled(Icon)`
  transition: transform 0.3s ease;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : '')};
`

export const EditIcon = styled(Icon)`
  margin-right: 10px;
`

export const AddSlideBtnWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #dee3ef;
  padding-top: 10px;
  margin-top: 10px;
`

export const AddSlide = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eef5ff;
  font-size: 14px;
  font-weight: 500;
  color: #387dff;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #387dff;
    color: #ffffff;
  }
`

export const SliderBackgroundControls = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  position: absolute;
  right: 20px;
  top: 87px;
  z-index: 20;
  height: 40px;
  font-size: 14px;
  height: auto;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    position: initial;
    width: 100%;
    justify-content: initial;
    background: white;
    right: initial;
    top: 30px;
    box-shadow: 0px -3px 6px rgba(61, 129, 251, 0.101);
    border-radius: 7px 7px 0px 0px;
    padding-bottom: 30px;
  }
`

export const BaseComponentCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid #c1c9e0;
  z-index: 1;
  min-width: 40px;
  min-height: 40px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 2px 8px #3271e64d;
  @media only screen and (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    box-shadow: initial;
    margin: 0;
    padding: 0;
    flex-direction: column;
  }
  @media only screen and (max-width: 430px) {
    min-width: 30px;
    min-height: 30px;
  }
  @media only screen and (max-width: 330px) {
    i {
      font-size: 20px !important;
      padding: 6px !important;
    }
  }
`
