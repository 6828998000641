import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const SubscriptionLinkWrapper = styled.span`
  display: flex;
  color: ${secondary.ExtraDark};
  font-size: 14px;
  font-weight: 500;
  a {
    display: flex;
    color: #3271e6;
    font-weight: 500;
    text-decoration: none;
    margin-left: 5px;
    border-bottom: 1px solid transparent;
    box-sizing: border-box;
    & > div {
      margin: 0 5px;
    }
    &:hover {
      border-bottom: 1px solid #3271e6;
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    max-width: 230px;
    text-align: center;
  }
`
