import styled from 'styled-components'

export const Result = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    fill: #8493bd;
    margin-right: 10px;
  }
`
