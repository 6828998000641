import styled from 'styled-components'

import LibCheckbox from '@renderforest/rf-ui-library/molecules/Checkbox'

export const Form = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  height: 621px;

  .paypal-input {
    width: 330px;
  }

  .paypal-email-input,
  .headline-input,
  .paypal-input {
    padding-bottom: 20px;
  }

  @media only screen and (max-width: 768px) {
    overflow-y: auto;
    padding: 20px;
    height: unset;
    border-bottom: 1px solid #c1c9e0;

    .paypal-input {
      width: 100%;
    }
  }
`

export const InputsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 680px;

  & > div:nth-child(odd) {
    margin-right: 20px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;
  }
`

export const SubTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #252e48;

  @media only screen and (max-width: 768px) {
    margin-top: 10px;
  }
`

export const Checkbox = styled(LibCheckbox)`
  margin: 20px 0;
  width: max-content;

  div[role='checkbox'] {
    margin-inline-end: 10px;

    &:after {
      border: 1px solid
        ${({ isChecked }) => (isChecked ? '#387DFF' : '#8493BD')};
    }
  }

  & > div:nth-child(2) {
    font-size: 14px;
    font-weight: 500;
    color: #252e48;
  }

  @media only screen and (max-width: 768px) {
    margin: 10px 0 20px 0;
  }
`
