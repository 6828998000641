// @flow
import React, { useCallback, useContext, useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TPublishPopupProps } from './types'
import PopupsLoader from '@editor/common/components/Loaders/PopupsLoader'
import SubdomainInput from '@editor/common/components/SubdomainInput'
import { UpgradePopupContext } from '@contexts'
import { useHistoryWithSearch } from '@hooks'
import { PAGE_BASE } from '@editor/conf/routes'
import { addAnalyticsEvent } from '@editor/common/utils'

import { saveSubdomainAndPublish } from '@actions/project'
import { closePublishPopup } from '@actions/editor'
import {
  selectSubdomainValue,
  selectPublishingStatus,
  selectIsSubdomainSaving,
  selectPublishPopupState,
  isSubscriber
} from '@selectors'

const PublishPopup = ({
  isOpen,
  isSubscriber,
  isPublishing,
  subdomainValue,
  isSubdomainSaving,
  closePublishPopup,
  saveSubdomainAndPublish
}: TPublishPopupProps) => {
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(PAGE_BASE)
  const [value, setValue] = useState(subdomainValue || '')
  const isMobile = useContext(767)
  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const openUpgradePopup = useCallback(() => {
    setUpgradePopupState({ isOpen: true })
  }, [])

  const onConnectButtonClick = useCallback(() => {
    addAnalyticsEvent(
      'SiteMaker Domains',
      'connect-button-click',
      'location: Publishing Popup'
    )
    closePublishPopup()
    history.push(`${url}/settings/domain`)
  }, [history, url])

  return (
    <Styled.PublishPopup
      isOpen={isOpen}
      onClose={closePublishPopup}
      isMobileFullScreen
    >
      <PopupsLoader
        isLoading={isPublishing || isSubdomainSaving}
        loadingText={translate('publishing_label')}
      />
      <Styled.Title>
        {isSubscriber
          ? translate('how_you_want_to_publish_label')
          : translate(
              'your_website_will_be_published_with_free_subdomain_title'
            )}
      </Styled.Title>
      <Styled.PublishBlock>
        <Styled.PublishBlockTitle>
          {isSubscriber
            ? translate('use_a_free_subdomain_label')
            : translate('edit_your_free_subdomain_label')}
        </Styled.PublishBlockTitle>
        <Styled.Content>
          <SubdomainInput
            value={value}
            isLoading={isSubdomainSaving}
            setValue={setValue}
            saveAndPublish={saveSubdomainAndPublish}
            fromPublishPopup
          />
        </Styled.Content>
      </Styled.PublishBlock>
      {!isSubscriber ? (
        <Styled.UpgradeBanner>
          {!isMobile ? <Styled.Gradient /> : null}
          <Styled.UpgradeBannerTitle>
            {translate('take_your_website_to_next_level_label')}
          </Styled.UpgradeBannerTitle>
          <Styled.UpgradeBannerDescription>
            {translate('do_you_know_website_with_custom_domain_label')}
          </Styled.UpgradeBannerDescription>
          <Styled.UpgradeBtn onClick={openUpgradePopup}>
            {translate('upgrade_label', true)}
          </Styled.UpgradeBtn>
        </Styled.UpgradeBanner>
      ) : (
        <>
          <Styled.Divider>
            <div />
            <span>{translate('or_label')}</span>
            <div />
          </Styled.Divider>
          <Styled.ConnectDomainWrapper>
            <div>
              <h3>{translate('get_the_best_subscribtion')}</h3>
              <p>{translate('connect_your_branded_domain_label')}</p>
            </div>
            <Styled.ConnectButton onClick={onConnectButtonClick}>
              {translate('connect_domain_label')}
            </Styled.ConnectButton>
          </Styled.ConnectDomainWrapper>
        </>
      )}
    </Styled.PublishPopup>
  )
}

const mapStateToProps = state => ({
  isSubscriber: isSubscriber(state),
  subdomainValue: selectSubdomainValue(state),
  isOpen: selectPublishPopupState(state),
  isPublishing: selectPublishingStatus(state),
  isSubdomainSaving: selectIsSubdomainSaving(state)
})

const mapDispatchToProps = {
  saveSubdomainAndPublish,
  closePublishPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishPopup)
