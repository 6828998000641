export const validation = {
  titleMaxChar: 50,
  paragraphMaxChar: 1500,
  countMaxChar: 10,
  blockTitleMaxChar: 30,
  title: { required: true },
  paragraph: { required: true },
  info: {
    min: 1,
    max: 6
  }
}
