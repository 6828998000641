/* @flow */
import React, { useRef, useContext, useState, useEffect } from 'react'

import If from '../Conditional'
import { withTheme } from 'styled-components'
import { accentRed } from '@website/common/styles/colors'
import { KEY_CODES } from '../../../conf/consts'
import { EditingContext } from '@contexts'
import debounce from '@editor/common/utils/debounce'
import './index.sass'

import styled, { css } from 'styled-components'

const getInfoText = (text: string, maxCount: number, required: boolean) => {
  if (required) {
    return `This field is required`
  }
  const length = text.length
  if (isFinite(maxCount)) {
    return `${length}/${maxCount}`
  }
  return `${length}/∞`
}

const DEFAULT_TEXT = 'Your text'
const hoverClassName = ({ colorMode }) =>
  colorMode === 'DARK' ? 'light-content' : 'dark-content'

const textStateCommonStyles = css`
  font-size: 12px;
  text-shadow: initial;
  writing-mode: initial;
  letter-spacing: 0px;
  top: 100%;
  right: 0;
  display: inline-flex;
  position: absolute;
  width: 100%;
`

const getStyles = ({
  focused,
  text,
  hasMaxCount,
  requiredWarning,
  isTypingDisabled
}) => {
  if (isTypingDisabled) {
    return `
      pointer-events: none;
    `
  }
  if (requiredWarning) {
    return `
      &:after {
        color: ${accentRed};
        min-width: 130px;
        content: '${text}';
        ${textStateCommonStyles};
        font-family: initial;
        font-family: 'Montserrat', sans-serif;
      }
    `
  } else if (focused && hasMaxCount) {
    return `
      &:before {
        color: inherit;
        min-width: 80px;
        content: '${text}';
        ${textStateCommonStyles};
        font-family: 'Montserrat', sans-serif;
      }
    `
  }
  return ''
}

// eslint-disable-next-line max-statements
const EditableContent = ({
  text = '',
  theme,
  as: _As,
  required = false,
  maxCount = 320,
  onChange,
  className,
  toPropogate = true,
  interactive,
  setRef,
  isTypingDisabled = false,
  editableEl,
  ...rest
}) => {
  const { isEditing } = useContext(EditingContext)
  const [colorClass, setColorClass] = useState(!text || !text.length)
  const [warningClass, setWarningClass] = useState('')
  const [focused, setFocusStatus] = useState(false)
  const [_text, setText] = useState(text)
  const [staticText, setStaticText] = useState(text)
  const [requiredWarning, _setRequredWarning] = useState(false)

  useEffect(() => {
    setStaticText(text)
    setColorClass(!text || !text.length)
    setText(text)
  }, [text])

  const styledAsRef = useRef(styled(_As)`
    ${getStyles}
  `)
  const As = styledAsRef.current

  if (!isEditing) {
    return (
      <If
        condition={!!text}
        then={() => (
          <As
            {...rest}
            ref={setRef}
            className={`${className} sm-word-wrap`}
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      />
    )
  }

  const classNames = [
    className,
    hoverClassName(theme),
    'sm-word-wrap',
    colorClass ? 'hint-text' : '',
    warningClass,
    'selectable',
    'underline-inherit'
  ].join(' ')

  const setRequredWarning = (e: Event) => {
    e && e.preventDefault()
    setWarningClass('warning-max')
    _setRequredWarning(true)
  }

  const removeRequiredWarning = () => {
    setWarningClass('')
    _setRequredWarning(false)
  }

  const keyDown = (e: Event) => {
    if (e.keyCode === KEY_CODES.ENTER && !e.shiftKey) {
      e.preventDefault()
      const selection = window.getSelection()
      const range = selection.getRangeAt(0)
      const br = document.createElement('br')
      range.deleteContents()
      range.insertNode(br)
      range.setStartAfter(br)
      range.setEndAfter(br)
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    }
  }

  const onPaste = (e: Event) => {
    e.preventDefault()
    const text = event.clipboardData.getData('text/plain')

    document.execCommand('insertHTML', false, text)
  }

  const onBlur = (e: Event) => {
    e.target.style.removeProperty('min-width')

    const innerText = e.target.innerText.trim()
    const txt = e.target.innerHTML.trim()
    if (txt.length === 0 || innerText.length === 0) {
      if (required) {
        e.target.innerHTML = text
        setFocusStatus(false)
        setRequredWarning(e)
        setTimeout(() => {
          document.addEventListener('click', removeRequiredWarning, {
            once: true,
            capture: true
          })
        }, 300)
        return
      }
      e.target.innerText = DEFAULT_TEXT
      setColorClass(true)
      onChange('')
    } else {
      if (e.target.innerHTML !== DEFAULT_TEXT && e.target.innerHTML !== text) {
        onChange(e.target.innerHTML)
      }
    }
    setFocusStatus(false)
  }

  const checkWarning = (e: Event) => {
    const textLength = e.target.innerText.length
    const diff = maxCount - textLength
    let className = ''
    if (diff < 5) {
      className = 'warning-near'
    }
    if (diff < 0) {
      className = 'warning-max'
    }
    setWarningClass(className)
  }

  const stopPropagation = (e: Event) => toPropogate && e.stopPropagation()

  const debouncedUpdate = useRef(
    debounce((value: string) => {
      onChange(value)
    }, 1000)
  ).current

  const onInput = (e: Event) => {
    if (requiredWarning) {
      setWarningClass('')
      _setRequredWarning(false)
    }
    checkWarning(e)
    setText(e.target.innerText)
    if (interactive) {
      debouncedUpdate(e.target.innerText)
    }
  }

  const onFocus = (e: Event) => {
    const { target } = e
    if (!staticText) {
      const { width } = target.getBoundingClientRect()
      target.style.minWidth = `${width}px`
      target.innerText = ''
    }

    _setRequredWarning(false)
    setFocusStatus(true)
    checkWarning(e)
    setColorClass(false)
  }

  return (
    <As
      {...rest}
      ref={setRef}
      tabIndex="0"
      contentEditable
      onBlur={onBlur}
      onPaste={onPaste}
      onInput={onInput}
      onFocus={onFocus}
      onKeyDown={keyDown}
      focused={focused}
      className={classNames}
      data-gramm_editor="false"
      onClick={stopPropagation}
      suppressContentEditableWarning
      onDrop={e => e.preventDefault()}
      hasMaxCount={isFinite(maxCount)}
      requiredWarning={requiredWarning}
      isTypingDisabled={isTypingDisabled}
      text={getInfoText(_text, maxCount, requiredWarning)}
      dangerouslySetInnerHTML={{ __html: staticText || DEFAULT_TEXT }}
    />
  )
}

export default withTheme(EditableContent)
