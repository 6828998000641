import styled, { css } from 'styled-components'
import { styleProvider } from '@website/common/utils'
import THEME from './Buttons.theme'
const getStyleForProp = styleProvider(THEME)

const ButtonsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  z-index: 1;
  padding-top: 30px;
  .buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }
  .add-button-wrap {
    margin: 15px;
    > div {
      width: 100%;
      box-sizing: border-box;
      height: 50px;
      padding: 16px 30px;
    }
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -15px 0;
  margin: ${({ isEditing }) => (isEditing ? '0' : '-15px 0')};
`

const Button = styled.div`
  margin: 15px;
  @media only screen and (max-width: 550px) {
    max-width: 300px;
  }
  .control-container {
    width: 100%;
  }
  .link-wrapper {
    width: 100%;
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      props.isTertiary
        ? css`
            ${getStyleForProp('raisedShadow')};
            ${getStyleForProp('buttonBackgroundTertiary')(props)}
          `
        : css`
            ${getStyleForProp('buttonShadow')};
            ${getStyleForProp('buttonBackground')(props)}
          `};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    max-width: 300px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`

export default {
  ButtonsBlock,
  ButtonsContainer,
  Button
}
