/* @flow */
import React from 'react'

const IphoneDinamic = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="134"
      height={21}
      viewBox="0 0 147.677 21"
      className="rf-iphone-dimanic-svg"
    >
      <path
        id="Intersection_1"
        data-name="Intersection 1"
        d="M78.215,29.5a16.119,16.119,0,0,1-4.359-1.162,15.374,15.374,0,0,1-5.107-3.51,15.1,15.1,0,0,1-4.2-11.171V13a4,4,0,0,0-2.061-3.5h143.82a4,4,0,0,0-2.061,3.5v.661A15.541,15.541,0,0,1,190.722,29.5Z"
        transform="translate(-60.558 -9)"
        fill="#fff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit={10}
        strokeWidth={1}
      />
    </svg>
  )
}

export default IphoneDinamic
