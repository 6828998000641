/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA8.styled'
import { validation } from './CTA8.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'

const CTA8 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      images,
      buttons
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <Mapper
          data={images}
          render={(img: string, blockIdx: number) => {
            return (
              <S.GridWrapper key={blockIdx}>
                <Mapper
                  data={img}
                  render={(item: string, idx: number) => {
                    return (
                      <S.RatioWrapper
                        key={idx}
                        className={'ratio-wrapper-' + (idx + 1)}
                      >
                        <S.ImageWrapper className={'img-wrapper-' + (idx + 1)}>
                          <S.ControlContainer
                            className={'ControlContainer-' + (idx + 1)}
                          >
                            <ControlsWithImageCropper
                              style={{ width: '100%', height: '100%' }}
                              actions={Actions.imageActions(
                                blockIdx,
                                idx,
                                item.imgUrl
                              )}
                            >
                              <Image
                                as={S.Img}
                                alt="Gallery"
                                sizes={item.imgDimensions}
                                asProps={{
                                  imgCropParams: item.imgCropParams
                                }}
                                defaultImgSrc={item.imgUrl}
                              />
                            </ControlsWithImageCropper>
                          </S.ControlContainer>
                        </S.ImageWrapper>
                      </S.RatioWrapper>
                    )
                  }}
                />
              </S.GridWrapper>
            )
          }}
        />
        <S.HeaderContent>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            maxCount={validation.titleMaxChar}
            required={validation.title.required}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
        </S.HeaderContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default CTA8
