// @flow
import React, { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { ComponentCategories, ComponentThumbnails } from './containers'
import * as Styled from './styled'
import type { TMainContentProps } from './types'

const MainContent = ({
  baseUrl,
  filteredCats,
  selectedComponents,
  isLargerGrid,
  isCompReplacing,
  isMobileCategoriesOpen,
  setSelectedComponents,
  setMobileCategoriesState,
  hasSpecialCategory
}: TMainContentProps) => {
  const { activeCompCategory } = useLocation()
  const [activeCategory, setActiveCategory] = useState(activeCompCategory)

  useEffect(() => {
    if (!activeCategory && filteredCats.length) {
      setActiveCategory(filteredCats[0]?.key)
    }
  }, [filteredCats, activeCategory])

  return (
    <Styled.MainContent>
      <ComponentCategories
        activeCategory={activeCategory}
        setActiveCategory={setActiveCategory}
        hasSpecialCategory={hasSpecialCategory}
        filteredCats={filteredCats}
        isCompReplacing={isCompReplacing}
        selectedComponents={selectedComponents}
        isSlideUpOpen={isMobileCategoriesOpen}
        setSlideUpOpenState={setMobileCategoriesState}
      />
      <ComponentThumbnails
        baseUrl={baseUrl}
        activeCategory={activeCategory}
        filteredCats={filteredCats}
        isLargerGrid={isLargerGrid}
        isCompReplacing={isCompReplacing}
        selectedComponents={selectedComponents}
        setSelectedComponents={setSelectedComponents}
        setActiveCategory={setActiveCategory}
      />
    </Styled.MainContent>
  )
}

export default memo(MainContent)
