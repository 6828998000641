/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, title } = action.value
        draft.imageSteps[idx].stepHeading = title
        break
      }
      case 'CHANGE_BLOCK_CONTENT': {
        const { idx, content } = action.value
        draft.imageSteps[idx].stepDescription = content
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.imageSteps[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.imageSteps[idx].paragraphAlignment = alignment
        break
      }
      case 'CHANGE_IMAGE':
        const { idx, url, dimensions } = action.value
        draft.imageSteps[idx].stepImageUrl = url
        draft.imageSteps[idx].stepImgSizes = dimensions || {}
        draft.imageSteps[idx].imgCropParams = {}
        break
      case 'ADD_BLOCK':
        draft.imageSteps.push(initialState.imageSteps[0])
        break
      case 'REMOVE_BLOCK':
        draft.imageSteps.splice(action.value.idx, 1)
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.imageSteps[idx].imgCropParams = imgCropParams
      }
    }
  })
}
