export default {
  DARK: {
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    border: p => p.tertiaryDark
  },
  LIGHT: {
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    border: p => p.tertiarySemiLight
  },
  EXTRALIGHT: {
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    border: p => p.tertiarySemiLight
  }
}
