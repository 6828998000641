/* @flow */
import React, { useContext, memo, useRef } from 'react'
import S from './Feature34.styled'
import { validation } from './Feature34.consts'
import ActionGetter from './Actions'
import Buttons from '@website/common/components/Buttons'
import Image from '@website/common/components/Image'
import AnimationSvg from './AnimationSvg'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { DispatchContext, EditingContext } from '@contexts'
import useOnScreen from '@website/common/hooks/useOnScreen'

const Feature34 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      subTitle,
      subTitleAlignment,
      paragraph1,
      paragraph1Alignment,
      paragraph2,
      paragraph2Alignment,
      buttons,
      largeImgUrl,
      largeImgDimensions,
      largeImgCropParams,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      isAnimated
    }
  } = props

  const animatedRef = useRef(null)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const isOnScreen = useOnScreen(animatedRef)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.ParagraphsWrap>
          <EditableContent
            text={title}
            as={S.Title}
            required={!subTitle && !paragraph1 && !paragraph2}
            className="WM_GLOBAL_heading42"
            maxCount={validation.headingMaxChar}
            onChange={Actions.changeTitle}
            alignment={titleAlignment}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            as={S.Paragraph}
            text={paragraph1}
            required={!subTitle && !title && !paragraph2}
            maxCount={validation.contentParagraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph1}
            alignment={paragraph1Alignment}
            changeAlignment={Actions.changeParagraph1Alignment}
          />
          <EditableContent
            as={S.SubTitle}
            text={subTitle}
            required={!title && !paragraph1 && !paragraph2}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_heading32"
            onChange={Actions.changeSubTitle}
            alignment={subTitleAlignment}
            changeAlignment={Actions.changeSubtitleAlignment}
          />
          <EditableContent
            as={S.Paragraph}
            text={paragraph2}
            required={!subTitle && !title && !paragraph1}
            maxCount={validation.contentParagraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph2}
            alignment={paragraph2Alignment}
            changeAlignment={Actions.changeParagraph2Alignment}
          />
          <S.ButtonBlock>
            <Buttons data={buttons} />
          </S.ButtonBlock>
        </S.ParagraphsWrap>
        <S.TextImageContent ref={animatedRef}>
          <AnimationSvg
            key={
              isAnimated && isOnScreen && !isEditing
                ? 'animated'
                : 'not animated'
            }
            className="waveImg"
            isAnimated={isAnimated}
            isOnScreen={isOnScreen}
            isEditing={isEditing}
          />
          <S.ImageWrapper>
            <S.ControlContainer isEditing={isEditing}>
              <ControlsWithImageCropper
                actions={Actions.getLargeImgActions({
                  keyForSizes: 'feature34',
                  showRemove: false
                })}
                style={{ width: '100%' }}
              >
                <Image
                  as={S.Img}
                  sizes={largeImgDimensions}
                  defaultImgSrc={largeImgUrl}
                  asProps={{
                    imgCropParams: largeImgCropParams
                  }}
                  alt="avatar image"
                />
              </ControlsWithImageCropper>
            </S.ControlContainer>
          </S.ImageWrapper>
        </S.TextImageContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature34
