// @flow

import React, { useEffect, useCallback, useState, memo } from 'react'
import ReactDOM from 'react-dom'
import { useBackdropClick } from '@hooks'
import * as Styled from './styled'

import type { TSlideUpProps } from './types'

const SlideUp = ({
  isOpen: isOpenFromProps,
  className = '',
  children,
  onClose,
  backdropDisablingSelectors = []
}: TSlideUpProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isMounted, setMountedState] = useState(false)
  const ref = useBackdropClick(onClose, backdropDisablingSelectors)

  useEffect(() => {
    setIsOpen(isOpenFromProps)
    if (isOpenFromProps) {
      setMountedState(true)
    }
  }, [isOpenFromProps])

  const onAnimationEnd = useCallback(() => {
    if (!isOpenFromProps) {
      setMountedState(false)
    }
  }, [isOpenFromProps])

  return isMounted
    ? ReactDOM.createPortal(
        <>
          <Styled.GlobalStyles />
          <Styled.SlideUpContainer className={className}>
            <Styled.SlideUp
              isOpen={isOpen}
              className={`${className}_content`}
              onAnimationEnd={onAnimationEnd}
              ref={ref}
            >
              {children}
            </Styled.SlideUp>
          </Styled.SlideUpContainer>
        </>,
        document.body
      )
    : null
}
export default memo(SlideUp)
