import React from 'react'

import * as Styled from './styled'

const MainSidebar = ({ isExpanded, toggleMainSidebar, children }) => (
  <Styled.MainSidebar onClick={toggleMainSidebar} isExpanded={isExpanded}>
    {children}
  </Styled.MainSidebar>
)

export default MainSidebar
