/* @flow */
import React, { useContext, useState, memo, useCallback } from 'react'
import S from './Header16.styled'
import THEME from './Header16.theme'
import { validation } from './Header16.consts'
import CountDownDate from './CountDownDate'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import Socials from '@website/common/components/Socials'
import DatePicker from '@website/common/components/DatePicker'
import { scrollBottom } from '@website/common/utils'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'

const Header16 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      socials,
      logoWidth,
      logoDimensions,
      dateLabels,
      countDownDate,
      bgOverlayTransparency,
      backgroundImgDimensions
    },
    hasNextComponent
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false)
  const [ datePickerAnchorEl, setDatePickerAnchorEl ] = useState(null)

  const handleDatePickerOpen = useCallback((e) => {
    if(!isEditing) {
      return
    }
    setDatePickerVisibility(true)
    setDatePickerAnchorEl(e.currentTarget)
  }, [isEditing])

  const closeDatePicker = useCallback(() => {
    setDatePickerVisibility(false)
  }, [])

  const submitDate = useCallback(date => {
    setDatePickerVisibility(false)
    Actions.changeDate(date.toISOString())
  }, [])

  const _countDownDate = countDownDate || new Date().toISOString()

  return (
    <S.StyledContainer
      isEditing={isEditing}
      topOffset={navBarHeight}
      height={typeof window !== 'undefined' ? window.innerHeight : '100vh'}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <If
        condition={isDatePickerVisible}
        then={() => (
          <DatePicker
            anchorEl={datePickerAnchorEl}
            submitDate={submitDate}
            closeDatePicker={closeDatePicker}
            selectedDays={_countDownDate} //date format
          />
        )}
      />
      <NavBar
        handleHeightChange={setNavBarHeight}
        alwaysShowBackground
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || socials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={socials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.WmCustomContainer isEditing={isEditing} topOffset={navBarHeight}>
        <Show when={[title]}>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading20"
            as={S.Title}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
        </Show>
        <CountDownDate
          dateLabels={dateLabels}
          date={_countDownDate}
          openDatePicker={handleDatePickerOpen}
        />
      </S.WmCustomContainer>
      <Show when={[paragraph]}>
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          maxCount={validation.paragraphMaxChar}
          className="WM_GLOBAL_paragraph18"
          as={S.Paragraph}
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
      </Show>
      <If
        condition={hasNextComponent}
        then={() => (
          <S.DownButton role="button" onClick={() => scrollBottom(isEditing)}>
            <S.ScrollIcon size="xlarge" name="icon-arrow_small_bottom" />
          </S.DownButton>
        )}
      />
    </S.StyledContainer>
  )
})

Header16.contextType = DispatchContext

export default Header16
