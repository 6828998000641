const HEADINGS = ['H1', 'H2', 'H3', 'H4', 'H5', 'H6']
const NODE_NAMES = ['A', 'B', 'I', 'U', '#text', 'SPAN', ...HEADINGS]

const getHasParentHyperlink = element => {
  if (!NODE_NAMES.includes(element.nodeName)) {
    return false
  }

  const hasParentHyperlink = getHasParentHyperlink(element.parentNode)

  return (
    hasParentHyperlink ||
    (element.nodeName !== '#text' && element.nodeName === 'A')
  )
}

export const getActiveParentNodes = element => {
  if (!element) {
    return []
  }
  const nodesNames = []
  let notTextNode = element

  while (notTextNode.nodeName === '#text') {
    notTextNode = element.parentNode
  }

  const computedStyle = getComputedStyle(notTextNode)

  if (computedStyle.fontWeight >= 700) {
    nodesNames.push('B')
  }
  if (computedStyle.textDecorationLine === 'underline') {
    nodesNames.push('U')
  }
  if (computedStyle.fontStyle === 'italic') {
    nodesNames.push('I')
  }
  if (getHasParentHyperlink(element)) {
    nodesNames.push('A')
  }

  return nodesNames
}

export const getActiveChildNodes = element => {
  const nodesNames = []
  if (element.nodeName === 'A') {
    nodesNames.push('A')
  }

  const computedStyle = getComputedStyle(element)

  if (computedStyle.textDecorationLine === 'underline') {
    nodesNames.push('U')
  }
  if (computedStyle.fontStyle === 'italic') {
    nodesNames.push('I')
  }

  const firstChild = element.childNodes[0]
  if (!firstChild || firstChild.nodeName === '#text') {
    if (computedStyle.fontWeight >= 700) {
      nodesNames.push('B')
    }
    return nodesNames
  }

  return [...nodesNames, ...getActiveChildNodes(firstChild)]
}

export const getIsBackwardDirection = () => {
  const selection = document.getSelection()
  const position = selection?.anchorNode?.compareDocumentPosition(
    selection.focusNode
  )

  return (
    (!position && selection.anchorOffset > selection.focusOffset) ||
    position === Node.DOCUMENT_POSITION_PRECEDING
  )
}
