/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Header49.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0 0 310px 0;
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: 1024px) {
    padding: 0 0 188px 0;
  }
  @media only screen and (max-width: 457px) {
    padding: 0 0 188px 0;
  }
  .socials-block {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: 50%;
    transform: translateY(50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      align-items: center;
      right: unset;
      transform: unset;
      position: initial;
      box-sizing: border-box;
    }
  }
  .socials-wrapper {
    display: inline-flex;
    flex-direction: column;
    @media only screen and (max-width: 1024px) {
      flex-direction: row;
      flex-wrap: wrap;
      margin: ${({ isEditing }) => (isEditing ? '30px 0 0 0' : '25px 0 0 0')};
    }
    .link {
      fill: ${getStyleForProp('socialColor')};
    }
  }
`
const WaveWrapper = styled.div`
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media only screen and (max-width: 1024px) {
    height: 168px;
  }
  .waves {
    position: relative;
    height: 200px;
    margin-bottom: -5px;
    @media only screen and (min-width: 1025px) {
      width: 100%;
    }
  }
  svg {
    position: absolute;
    bottom: 0%;
    @media only screen and (min-width: 1025px) {
      width: 100%;
    }
  }
  ${({ isAnimated, isOnScreen, isEditing }) =>
    isAnimated &&
    isOnScreen &&
    !isEditing &&
    css`
      .parallax > use {
        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
      }
    `};
  .parallax > use:nth-child(1) {
    fill: ${({ theme }) => `rgba(${hexToRgb(theme.palette.primaryDark)}, 0.8)`};
    z-index: 2;
    animation-delay: -2s;
    animation-duration: 11s;
  }
  .parallax > use:nth-child(2) {
    fill: ${({ theme }) =>
      `rgba(${hexToRgb(theme.palette.primaryLight)}, 0.6)`};
    z-index: 3;
    animation-delay: -3s;
    animation-duration: 14s;
  }
  .parallax > use:nth-child(3) {
    fill: ${({ theme }) => `rgba(${hexToRgb(theme.palette.primaryDark)}, 0.3)`};
    z-index: 4;
    animation-delay: -4s;
    animation-duration: 17s;
  }
  .parallax > use:nth-child(4) {
    fill: ${({ theme }) =>
      `rgba(${hexToRgb(theme.palette.primaryLight)}, 0.9)`};
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: ${({ topOffset }) => `${topOffset + 230}px`};
  @media only screen and (max-width: 1024px) {
    padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  > :first-child {
    padding-top: 0;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  min-height: 150px;
`

const HeaderContent = styled.div`
  width: 85%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  > .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  width: 100%;
`

const Description = styled.h2`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
  width: 100%;
`

export default {
  StyledContainer,
  WmCustomContainer,
  ContentWrapper,
  HeaderContent,
  Title,
  Description,
  WaveWrapper
}
