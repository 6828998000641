import styled from 'styled-components'

export const UpgradeButton = styled.button`
  width: 40px;
  height: 40px;
  background: transparent linear-gradient(180deg, #ffd200 0%, #f7971e 100%);
  flex-shrink: 0;
  outline: none;
  margin-right: 10px;
  cursor: pointer;
  border: 2px solid #ffd200;
  border-radius: 5px;
  box-shadow: inset 0 1px 6px #f073794d;
  animation: upgrade-pulse 2s infinite;
  transition: background 300ms linear;
  transform: translate3d(0, 0, 0);
  svg {
    fill: #ffffff;
    position: relative;
    z-index: 1;
    animation: rotate-scale-up 4s infinite linear;
  }

  &:hover {
    background: transparent linear-gradient(180deg, #f7971e 0%, #ffd200 100%);
    animation: none;
    svg {
      animation: none;
    }
  }

  @keyframes rotate-scale-up {
    0% {
      transform: scale(1) rotateY(0);
    }

    20% {
      transform: scale(0.9) rotateY(0);
    }

    30% {
      transform: scale(1.2) rotateY(180deg);
    }

    40% {
      transform: scale(0.9) rotateY(0);
    }

    60%,
    100% {
      transform: scale(1);
    }
  }

  @keyframes upgrade-pulse {
    0% {
      box-shadow: inset 0 1px 6px #f073794d, 0 0 0 0 rgba(255, 210, 0);
    }
    100% {
      box-shadow: inset 0 1px 6px #f073794d, 0 0 0 5px rgba(255, 210, 0, 0);
    }
  }
`

export const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent
    linear-gradient(
      180deg,
      rgba(255, 163, 48, 0.5) 0%,
      rgba(235, 116, 122, 0.5) 100%
    );
  border-radius: 5px;
  box-shadow: 0px 3px 6px #f073794d;
  margin-right: 10px;
`

export const MobileUpgradeButton = styled.button`
  width: 100%;
  height: 60px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding: 15px 20px;
  box-sizing: border-box;
  background: transparent linear-gradient(170deg, #ffd200 0%, #f7971e 100%);
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  svg {
    fill: #ffffff;
  }
`
