/* @flow */
import React, { useState, useCallback } from 'react'
import ErrorSvg from '@website/common/assets/error-circle.svg'
import WarningSvg from '@website/common/assets/warning-circle.svg'

import * as S from './RenderforestFooter.styled'
import meta from './RenderforestFooter.meta'
import Popup from './Popup'
import FormContent from './FormContent'
import ErrorContent from './ErrorContent'
import SuccessContent from './SuccessContent'
import { useResponsive } from '@shared/hooks'

const RFLogo =
  'https://static.rfstat.com/renderforest/images/website_maker_images/render-logo.svg'

const RenderforestFooter = ({}) => {
  const [isComplaintPopupOpen, setComplaintPopupState] = useState(false)
  const [errorTitle, setErrorTitle] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorIcon, setErrorIcon] = useState('')
  const [isSuccessPopupOpen, setSuccessPopupState] = useState(false)
  const isMobile = useResponsive(1024)

  const openComplaintPopup = useCallback(() => {
    setComplaintPopupState(true)
  }, [])

  const closeComplaintPopup = useCallback(() => {
    setComplaintPopupState(false)
  }, [])

  const openSuccessPopup = useCallback(() => {
    setSuccessPopupState(true)
  }, [])

  const closeSuccessPopup = useCallback(() => {
    setSuccessPopupState(false)
  }, [])

  const onError = useCallback((message, status) => {
    setErrorMessage(message || 'Please refresh page to load data')
    setErrorTitle(
      status === 403 ? 'Thanks for the report!' : 'Something went wrong'
    )
    setErrorIcon(status === 403 ? WarningSvg : ErrorSvg)
  }, [])

  const closeErrorPopup = useCallback(() => {
    setErrorMessage('')
  }, [])

  return (
    <>
      <S.WMCustomContainer>
        <S.LeftContent>
          <S.LogoWrapper>
            <img width="255px" height="60px" alt="Logo" src={RFLogo} />
          </S.LogoWrapper>
          <S.Text>
            <S.Bold>Disclaimer:</S.Bold> This website was built with
            Renderforest, however it is <S.Bold>NOT</S.Bold> managed by
            Renderforest.  If you find the content on this website abusive or
            inappropriate please report right away.
          </S.Text>
        </S.LeftContent>
        <S.Button type="primary" onClick={openComplaintPopup}>
          Report
        </S.Button>
      </S.WMCustomContainer>
      {isComplaintPopupOpen && (
        <Popup onClose={closeComplaintPopup}>
          <FormContent
            onClose={closeComplaintPopup}
            onSuccess={openSuccessPopup}
            onError={onError}
          />
        </Popup>
      )}
      {isSuccessPopupOpen && (
        <Popup
          onClose={closeSuccessPopup}
          style={{ maxWidth: '400px', zIndex: 20 }}
        >
          <SuccessContent />
        </Popup>
      )}
      {errorMessage && (
        <Popup onClose={closeErrorPopup} style={{ maxWidth: '400px' }}>
          <ErrorContent
            message={errorMessage}
            icon={errorIcon}
            title={errorTitle}
          />
        </Popup>
      )}
    </>
  )
}

RenderforestFooter.meta = meta

export default RenderforestFooter
