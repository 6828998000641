/* @flow */
import {
  iconActionMaker,
  subscribtionCardActionMaker,
  paypalButtonModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeTitleAlignment,
  changeParagraph,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import { addIcon, addBlock } from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const removeBlockListItem = (blockIdx, listIdx) => ({
    type: 'REMOVE_BLOCK_LIST_ITEM',
    value: { blockIdx, listIdx }
  })

  const changeBlockListItem = (newBlockListTitle, blockIdx, listIdx) =>
    dispatcher({
      type: 'CHANGE_BLOCK_LIST_ITEM',
      value: { newBlockListTitle, blockIdx, listIdx }
    })

  const addBlockListItem = idx =>
    dispatcher({ type: 'ADD_BLOCK_LIST_ITEM', value: { idx } })

  const changeSupTitle = (newSupTitle: string) => {
    dispatcher({
      type: 'CHANGE_SUP_TITLE',
      value: newSupTitle
    })
  }

  const changeSupTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUP_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockName = (name: string, blockIdx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_NAME',
      value: { blockIdx, name }
    })

  const changeBlockTitleAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  const changeBlockText = (text: string, blockIdx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_TEXT',
      value: { blockIdx, text }
    })

  const changeBlockDescription = (description: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_DESCRIPTION',
      value: { blockIdx, description }
    })
  }

  const changeBlockNameAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_NAME_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  const changeDescriptionAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_DESCRIPTION_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  return {
    changeSupTitle,
    changeSupTitleAlignment,
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraph: _changeParagraph,
    changeParagraphAlignment: _changeParagraphAlignment,
    addIcon: idx => dispatcher(addIcon(idx)),
    addBlock: () => dispatcher(addBlock()),
    changeBlockText,
    changeBlockName,
    changeBlockTitleAlignment,
    changeBlockListItem,
    changeBlockDescription,
    changeBlockNameAlignment,
    changeDescriptionAlignment,
    iconActions: iconActionMaker(dispatcher),
    cardAction: subscribtionCardActionMaker(dispatcher),
    paypalButtonModalActions: paypalButtonModalActionMaker(dispatcher),
    addBlockListItem,
    listActions: (blockIdx, listIdx, showRemove) => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () => dispatcher(removeBlockListItem(blockIdx, listIdx))
      }
    ]
  }
}
