/* @flow */
import styled, { css } from 'styled-components'
import Icon from '@website/common/components/Icon'
import LibIcon from '@website/common/components/Icon'
import THEME from './Contact13.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : ' 80px 0')};
  }
`

const FormImageWrapper = styled.div`
  width: 100%;
  display: flex;
  max-width: 1200px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  display: ${getStyleForProp('orientation')};
  @media only screen and (max-width: 1200px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'LEFT'
          ? 'column'
          : 'column-reverse'};
      `}
    max-width: 500px;
  }
`

const RightContent = styled.div`
  width: 48%;
  max-width: 500px;
  flex-grow: 1;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (min-width: 1201px) {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? 'margin-left: 50px;'
          : 'margin-right: 50px;'};
      `}
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            margin-top: 40px;
          `
        : css`
            margin-bottom: 40px;
          `}
  }
  > .control-container {
    h1 {
      margin-bottom: 0;
    }
  }
  > .control-container:first-child {
    width: 100%;
  }
  > .control-container:nth-child(2) {
    width: 100%;
    margin-top: 20px;
    p {
      margin-top: 0;
    }
  }
  > &:first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  ${({ isTextGradient }) =>
    isTextGradient
      ? css`
          background: linear-gradient(
            90deg,
            ${getStyleForProp('gradientColorFrom')} 0,
            ${getStyleForProp('gradientColorTo')} 100%
          );
          background-clip: text;
          background-size: 100% auto;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:before,
          &:after {
            -webkit-background-clip: initial;
            -webkit-text-fill-color: initial;
            background: initial;
          }
          & .link-wrapper {
            position: initial;
          }
        `
      : ''}
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const InputWrapper = styled.div`
  position: relative;
  .required-icon {
    position: absolute;
    right: 10px;
    bottom: 15px;
    color: ${({ theme }) => theme.palette.tertiaryDark};
  }
`

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 45px 0 15px;
  height: 50px;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('buttonRadius')};
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 15px;
  margin: 0 0 30px 0;
  height: 150px;
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('inputRadius')};
  border: ${({ theme }) =>
    `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: ${({ isEditing }) => (isEditing ? '15px' : '4px')};
  color: ${getStyleForProp('paragraph')};
  font-weight: 400;
  align-self: flex-start;
  position: relative;
  z-index: 1;
  &:after {
    left: 0;
    right: initial;
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      &:hover {
        cursor: text;
      }
    `}
`

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: ${({ isEditing }) => (isEditing ? '10px' : '30px')};
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 113%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    border-radius: ${getStyleForProp('borderRadius')};
    padding-top: 113%;
    height: initial;
  }
`

const ImageWrapper = styled.div`
  width: 52%;
  max-width: 620px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  border-radius: ${getStyleForProp('borderRadius')};
`

const RequiredIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.palette.tertiaryNormal
}))`
  line-height: 0;
  svg {
    max-width: 12px;
    max-height: 12px;
    fill: ${({ theme }) => theme.palette.tertiaryDark};
  }
`

const ErrorMessage = styled.div`
  position: absolute;
  top: 52px;
  color: #eb0c00;
  font-size: 14px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 5px;
  }
`

export const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

const ContactItemWrap = styled.div`
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  .control-container {
    > .styled-controls {
      left: initial;
      right: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 30px;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
  > .add-button-wrap {
    margin-top: 20px;
  }
  @media only screen and (min-width: 651px) {
    > div:first-child {
      margin-right: 30px;
    }
  }
`

const ItemWrap = styled.div`
  display: flex;
  box-sizing: border-box;
  word-break: break-word;
  align-items: center;
  justify-content: center;
  ${({ isEditing }) =>
    isEditing &&
    css`
      margin-top: 20px;
      margin-bottom: 20px;
    `};
  > div {
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 650px) {
    margin-right: 0;
    &:first-child {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
  .contact-item-control {
    align-items: center;
  }
  > .add-button-wrap {
    margin-right: 15px;
  }
`

const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const A = styled.a`
  text-align: left;
  text-decoration: none;
  display: block;
  color: ${getStyleForProp('paragraph')};
  width: 178px;
  &:hover {
    opacity: 0.7;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 178px;
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
`

const ContactIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('icons')
}))`
  width: 26px;
  height: 26px;
  > svg {
    width: 26px;
    height: 26px;
    max-width: 26px;
    max-height: 26px;
  }
`

export default {
  RightContent,
  Title,
  Input,
  TextArea,
  StyledContainer,
  Paragraph,
  InputContainer,
  FormContainer,
  FormImageWrapper,
  ControlContainer,
  ImageWrapper,
  Img,
  Label,
  InputWrapper,
  RequiredIcon,
  ErrorMessage,
  ErrorIcon,
  ContactItemWrap,
  ItemWrap,
  IconWrapper,
  A,
  BlockParagraph,
  ContactIcon
}
