import styled, { css } from 'styled-components'
import THEME from './Portfolio13.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : '')};
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) =>
      isEditing ? '140px 0 60px 0' : '60px 0 60px 0'};
  }

  .image_1 {
    width: calc(100% - 110px);
    padding-top: 48.1%;
    margin-left: auto;
    transition: transform 0.3s ease-out;
    @media only screen and (max-width: 1440px) {
      padding-top: 44.4%;
    }
    @media only screen and (max-width: 768px) {
      padding-top: 58.5%;
    }
    @media only screen and (max-width: 457px) {
      width: 100%;
    }
    & > div {
      animation-duration: 4.2s;
      animation-delay: -1.2s;
    }
    & + p {
      @media only screen and (max-width: 768px) {
        margin-top: -10px;
        margin-bottom: 20px;
      }
    }
  }

  .image_2 {
    margin-bottom: 100px;
    padding-top: 143%;
    transition: transform 0.3s ease-out;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 0px;
    }
    & > div {
      animation-duration: 4.5s;
      animation-delay: -1.4s;
    }
  }
  .image_3 {
    margin-bottom: 60px;
    padding-top: 99%;
    transition: transform 0.3s ease-out;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 0px;
    }
    & > div {
      animation-duration: 3.4s;
      animation-delay: -1.6s;
    }
  }
  .image_4 {
    margin-bottom: 60px;
    padding-top: 100%;
    transition: transform 0.3s ease-out;
    @media only screen and (max-width: 1024px) {
      margin-bottom: 30px;
    }
    @media only screen and (max-width: 457px) {
      margin-bottom: 20px;
    }
    & > div {
      animation-duration: 3.7s;
      animation-delay: -3.4s;
    }
  }

  .image_5 {
    padding-top: 144%;
    transition: transform 0.3s ease-out;
    @media only screen and (max-width: 1024px) {
      padding-top: 144%;
    }
    & > div {
      animation-duration: 4.2s;
      animation-delay: -2.5s;
    }
  }

  .image_6 {
    margin-top: -100px;
    padding-top: 99%;
    transition: transform 0.3s ease-out;
    @media only screen and (max-width: 1024px) {
      margin-top: 0px;
    }
    & > div {
      animation-duration: 3.7s;
      animation-delay: -4.4s;
    }
  }

  .image_7 {
    margin-top: -60px;
    padding-top: 68%;
    margin-bottom: 40px;
    transition: transform 0.3s ease-out;
    @media only screen and (max-width: 1024px) {
      margin-top: -74px;
    }
    @media only screen and (max-width: 457px) {
      width: 100%;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    & > div {
      animation-duration: 4.4s;
      animation-delay: -1.4s;
    }
  }
`

const ImageContainer = styled.div``

const FirstRow = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  max-width: 1480px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  margin: 0 auto;
  @media only screen and (max-width: 1440px) {
    max-width: 1280px;
  }
  @media only screen and (max-width: 1024px) {
    align-items: flex-start;
    max-width: 728px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  ${ImageContainer} {
    &:nth-child(2) {
      margin-left: 30px;
      width: 21.429%;

      @media only screen and (max-width: 1024px) {
        margin-left: 0px;
        width: 50%;
      }
      @media only screen and (max-width: 457px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    &:nth-child(3) {
      margin-left: 30px;
      width: 21.429%;

      @media only screen and (max-width: 1024px) {
        width: calc(50% - 30px);
      }
      @media only screen and (max-width: 457px) {
        width: 100%;
        margin-left: 0;
        margin-bottom: 20px;
      }
    }
  }
`
const SecondRow = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1400px;
  width: 100%;
  margin: 30px auto 0px;
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 457px) {
    margin-top: 0px;
  }
  ${ImageContainer} {
    &:nth-child(1) {
      width: 20.929%;
      @media only screen and (max-width: 1024px) {
        margin-left: 0px;
        width: 50%;
      }
      @media only screen and (max-width: 457px) {
        width: 100%;
      }
    }
    &:nth-child(2) {
      margin-left: 30px;
      width: 21.429%;
      @media only screen and (max-width: 1440px) {
        width: 22.429%;
      }
      @media only screen and (max-width: 1024px) {
        width: calc(50% - 30px);
        margin-top: calc(-21% - 37px);
      }
      @media only screen and (max-width: 457px) {
        margin-left: 0px;
        width: 100%;
        margin-bottom: 20px;
        margin-top: 0px;
      }
    }
  }
`

const FirstItemBox = styled.div`
  width: 44.5%;
  position: relative;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
`

const SixthItemBox = styled.div`
  width: 21.429%;
  display: flex;
  margin-right: 30px;
  margin-left: 30px;
  flex-direction: column;
  ${ImageContainer} {
    width: 100% !important;
  }
  @media only screen and (max-width: 1440px) {
    width: 22.429%;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 0px;
    width: 50%;
  }
  @media only screen and (max-width: 457px) {
    width: 100%;
    margin-right: 0;
  }
  .add-button-wrap {
    margin-top: 30px;
    margin-bottom: 30px;
    @media only screen and (max-width: 768px) {
      display: none !important;
    }
  }
  & > .arrow-container {
    width: max-content !important;
    margin: 20px auto 20px auto;
    & > svg > path {
      fill: ${getStyleForProp('arrows')};
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  & > svg {
    width: max-content !important;
    margin: 20px auto 20px auto;
    & > path {
      fill: ${getStyleForProp('arrows')};
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  & > p {
    @media only screen and (max-width: 768px) {
      margin-top: 20px;
    }
  }
`

const SeventhItemBox = styled.div`
  width: 29.7145%;
  display: flex;
  flex-direction: column;
  ${ImageContainer} {
    width: 100% !important;
  }
  @media only screen and (max-width: 1024px) {
    width: calc(50% - 30px);
  }
  @media only screen and (max-width: 457px) {
    width: 100%;
  }
`

const ImageFrame = styled.div`
  position: relative;
  height: 0;
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 1024px) {
    transform: translate(0%, 0%) !important;
  }
`

const ControlContainer = styled.div`
  .control-container {
    height: 100%;
  }
  .LazyLoad {
    width: 100%;
    figure {
      border-radius: ${getStyleForProp('borderRadius')};
    }
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: translateY(0px);
  ${({ isEditing, isAnimated }) =>
    !isEditing && isAnimated ? 'animation-name: portfolioAnimation13;' : ''}
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
  &:hover {
    animation-play-state: paused;
  }
  ${ControlContainer} {
    height: 100%;
    padding-top: initial;
  }
  @keyframes portfolioAnimation13 {
    0% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(3px);
    }
  }
`

const ImageText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  @media only screen and (max-width: 1024px) {
    .image_1 {
      ${({ isEditing, isLeftArrow, isParagraph }) =>
        !isEditing && !isLeftArrow && !isParagraph
          ? css`
              width: 100%;
              padding-top: 58.5%;
            `
          : 'width: calc(100% - 136px);'}
    }
  }

  @media only screen and (max-width: 768px) {
    ${ImageFrame} {
      margin-bottom: 30px;
    }
    & > .control-container {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-width: 457px) {
    ${ImageFrame} {
      margin-bottom: 20px;
    }
  }
`

const TextSizeContainer = styled.div`
  margin-right: auto;
  display: flex;
  ${({ isEditing }) =>
    isEditing ? 'margin-bottom: -27%' : 'margin-bottom: -24%'};
  @media only screen and (max-width: 1440px) {
    ${({ isEditing }) =>
      isEditing ? 'margin-bottom: -27%;' : 'margin-bottom: -23%;'}
  }
  @media only screen and (max-width: 1024px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            margin-bottom: -30%;
            min-height: 195px;
          `
        : css`
            margin-bottom: -32%;
            min-height: 207px;
          `}
  }
  @media only screen and (max-width: 768px) {
    min-height: 0;
    margin-bottom: 0;
  }
  & > p {
    margin-bottom: 0;
    min-width: 100px;
  }
  & > .control-container {
    height: max-content;
    margin-top: auto;
    width: 100px;
    @media only screen and (max-width: 1024px) {
      margin-right: auto;
      margin-bottom: -22%;
      min-width: 100px;
      display: flex;
      justify-content: flex-end;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
    @media only screen and (max-width: 457px) {
      margin-bottom: 0;
      max-width: 100%;
      margin-right: 0px;
      order: 2;
    }
  }
`

const Headings = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    flex-direction: column-reverse;
  }
  @media only screen and (max-width: 457px) {
    flex-direction: column;
  }
  > :last-child {
    margin-bottom: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    h1 {
      margin-bottom: 0;
    }
    &:nth-child(3) {
      width: max-content !important;
      height: auto !important;
      position: absolute;
      bottom: 0;
      left: 0;
      margin-bottom: 0 !important;
      @media only screen and (max-width: 1440px) {
        margin-left: 30px;
      }
      & > svg {
        @media only screen and (max-width: 1440px) {
          width: 60px !important;
          height: 60px !important;
        }
        & > path {
          fill: ${getStyleForProp('arrows')};
        }
      }
    }
  }
  .add-arrow-btn {
    position: absolute;
    bottom: 0px;
    left: 30px;
  }
  .arrow-container {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .add-button-wrap {
    margin-top: 30px;
    margin-bottom: 30px;
    @media only screen and (max-width: 768px) {
      display: none !important;
    }
  }
  & > p {
    min-width: 100px;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      margin-right: auto;
      min-height: 30vw;
      ${({ isEditing }) =>
        isEditing
          ? css``
          : css`
              display: flex;
              align-items: flex-end;
            `}
    }
    @media only screen and (max-width: 457px) {
      max-width: 100%;
    }
  }
  & > .control-container:nth-child(2) {
    max-width: calc(100% - 110px);
    width: 100%;
    margin-bottom: 50px;
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }
  & > svg {
    position: absolute;
    bottom: 15px;
    left: 15px;
    @media only screen and (max-width: 1440px) {
      width: 60px !important;
      height: 60px !important;
      margin-left: 10px;
    }
    @media only screen and (max-width: 768px) {
      display: none;
    }
    & > path {
      fill: ${getStyleForProp('arrows')};
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${({ isEditing }) =>
    isEditing
      ? 'max-width: 100%;'
      : css`
          margin-bottom: 50px !important;
          max-width: calc(100% - 110px);
        `};
  width: 100%;
  margin-left: auto;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    ${({ isEditing }) =>
      isEditing
        ? css``
        : css`
            max-width: 100%;
            margin-bottom: 40px !important;
          `}
  }
  @media only screen and (max-width: 457px) {
    max-width: 100%;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  ${({ isEditing }) =>
    isEditing
      ? ''
      : css`
          max-width: 100px;
          margin-top: auto;
          margin-bottom: -24%;
        `}
  @media only screen and (max-width: 1024px) {
    ${({ isEditing }) => (isEditing ? '' : 'margin-bottom: calc(-30% - 25px);')}
  }
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
`

const MobileParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`

const ItemParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  ${({ isEditing }) => (isEditing ? '' : '  margin-top: 20px;')}
  @media only screen and (max-width: 768px) {
    margin-top: 0;
  }
`
const SecondaryHeading = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  font-weight: 700;
  margin-bottom: 0;
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1480px;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  margin: 0 auto;
  @media only screen and (max-width: 1440px) {
    max-width: 1280px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 728px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`
const AddButtonWrapper = styled.div`
  display: flex;
  margin: 20px auto;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: ${getStyleForProp('cardRadius')};
`

export default {
  StyledContainer,
  FirstRow,
  SecondRow,
  FirstItemBox,
  SixthItemBox,
  SeventhItemBox,
  AddButtonWrapper,
  RowWrapper,
  Headings,
  Title,
  Paragraph,
  TextSizeContainer,
  MobileParagraph,
  SecondaryHeading,
  ItemParagraph,
  Img,
  ImageContainer,
  ImageFrame,
  ImageWrapper,
  ImageText,
  ControlContainer
}
