/* @flow */
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  addBlock,
  addIcon
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeNameLabel = (newLabel: string) =>
    dispatcher({
      type: 'CHANGE_NAME_LABEL',
      value: newLabel
    })

  const changeEmailLabel = (newLabel: string) =>
    dispatcher({
      type: 'CHANGE_EMAIL_LABEL',
      value: newLabel
    })

  const changeMessageLabel = (newLabel: string) =>
    dispatcher({
      type: 'CHANGE_MESSAGE_LABEL',
      value: newLabel
    })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const changeTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraphAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  const changePlaceHolderText = (key: string, value: string) => {
    dispatcher({
      type: 'CHANGE_PLACEHOLDER_TEXT',
      value: { key, value }
    })
  }

  const _addIcon = idx => {
    dispatcher(addIcon(idx))
  }

  const changeBlockParagraph = (paragraph, idx) =>
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { idx, paragraph }
    })

  const changeTitleGradientStatus = (newTitleTextGradient: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_TEXT_GRADIENT',
      value: newTitleTextGradient
    })
  }

  return {
    changeTitleGradientStatus,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeNameLabel,
    changeEmailLabel,
    changeMessageLabel,
    changePlaceHolderText,
    addBlock: _addBlock,
    changeTitleAlignment,
    changeParagraphAlignment,
    addIcon: _addIcon,
    changeBlockParagraph,
    iconActions: idx => [
      ...iconActionMaker(dispatcher)({
        idx
      })
    ],
    blockActions: removeBlockActionMaker(dispatcher),
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'contact13',
        showRemove: false,
        imageUrl
      })
  }
}
