/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        break
      }
      case 'CHANGE_PARAGRAPH': {
        const { idx, newParagraph } = action.value
        draft.blocks[idx].paragraph = newParagraph
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.blocks[idx].titleAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.blocks[idx].paragraphAlignment = alignment
        break
      }

      case 'CHANGE_LINK_DATA':
        {
          const { linkIdx, linkData } = action.value
          draft.blocks[linkIdx].additionalUrl = linkData.link
          draft.blocks[linkIdx].additionalText = linkData.text
        }
        break
      case 'REMOVE_LINK':
        {
          const { idx } = action.value
          draft.blocks[idx].additionalUrl = ''
          draft.blocks[idx].additionalText = ''
        }
        break
      case 'ADD_LINK':
        {
          const { idx } = action.value
          const { additionalUrl, additionalText } = initialState.blocks[0]
          draft.blocks[idx].additionalUrl = additionalUrl
          draft.blocks[idx].additionalText = additionalText
        }
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx, imgIdx } = action.value
        draft.blocks[idx].images[imgIdx].imgCropParams = imgCropParams
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, imgIdx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.blocks[idx].images[imgIdx].imgUrl = url
        draft.blocks[idx].images[imgIdx].ImgDimensions = dimensions || {}
        draft.blocks[idx].images[imgIdx].imgCropParams = {}
        break
      }
      case 'ADD_BLOCK':
        {
          const { idx } = action.value
          draft.blocks.splice(idx + 1, 0, initialState.blocks[0])
        }
        break
      case 'REMOVE_BLOCK':
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
    }
  })
}
