/* @flow */
import styled, { css } from 'styled-components'
import THEME from './FAQ2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
`

const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 0;
  line-height: 1.4;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const FaqHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  width: 100%;
  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
`

const FaqsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin: -40px auto -21px auto;
  & > div {
    width: 45%;
    @media only screen and (max-width: 992px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 450px) {
    margin-bottom: 0;
  }
`

const FaqItemWrap = styled.div`
  max-width: 600px;
  box-sizing: border-box;
  word-break: break-word;
  margin: 20px auto;
  &:last-child {
    margin: 20px auto 0 auto;
  }
  @media only screen and (max-width: 667px) {
    margin: 10px 0;
  }
  @media only screen and (max-width: 450px) {
    margin: 30px 0 0 0;
  }
  > .control-container {
    width: 100%;
  }
`

const FaqItemHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  color: ${getStyleForProp('heading')};
  font-weight: 600;
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    padding: 20px 0;
  }
  > .control-container {
    width: 100%;
  }
`

const Icon = styled.img`
  width: 10px;
`

const FaqContentWrapper = styled.div`
  & > .control-container {
    margin: 0 20px 20px 70px;
    @media only screen and (max-width: 450px) {
      margin: 0;
    }
    p {
      margin: 0px;
      padding: 0px;
    }
  }
`

const Question = styled.h2`
  width: 100%;
`

const FaqContent = styled.p`
  padding: 0 20px 20px 70px;
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  box-sizing: border-box;
  @media only screen and (max-width: 450px) {
    margin: 0;
    padding: 0;
  }
`

const Circle = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getStyleForProp('buttonBackground')};
  border-radius: ${getStyleForProp('iconRadius')};
  flex: none;
  @media only screen and (max-width: 450px) {
    margin: 0 0 20px 0;
  }
`

export default {
  StyledContainer,
  FaqContainer,
  Title,
  Paragraph,
  FaqContentWrapper,
  FaqHeader,
  FaqsWrapper,
  FaqItemHeader,
  Icon,
  FaqItemWrap,
  FaqContent,
  Question,
  Circle
}
