/* @flow */

import {
  CURRENCIES,
  FONT_INFO,
  ORIENTATION,
  projectContainerClassName
} from '@website/conf/consts'
import LOOK_DATA from '@website/conf/look'
import { css } from 'styled-components'

export const getQueryStringParam = (key: string, search?: string) => {
  if (typeof window === 'undefined') {
    return ''
  }
  const _search = search || window.location.search
  const p = _search
    .substr(1)
    .split('&')
    .map(pair => pair.split('='))
    .find(pair => pair[0] === key)

  if (p) {
    return p[1]
  }
}

export const removeQueryStringParam = (query: string, paramName: string) => {
  if (typeof window === 'undefined' || !query) {
    return ''
  }

  const updatedQuery = query
    .substr(1)
    .split('&')
    .filter(param => !param.includes(paramName))
    .join('&')

  return updatedQuery ? `?${updatedQuery}` : ''
}

export const getFontData = fontInfo => {
  const font = fontInfo.reduce(
    (acc, variant) => {
      const _variant = FONT_INFO[variant.toLowerCase()]
      if (_variant) {
        return {
          ...acc,
          variants: [...acc.variants, _variant]
        }
      }
      return {
        ...acc,
        name: `${acc.name} ${variant}`
      }
    },
    { variants: [], name: '' }
  )
  const _variants = font.variants.length === 0 ? ['400'] : font.variants
  const isItalic = _variants[0] === 'i'
  const bla = isItalic ? ['400i'] : _variants
  return {
    name: font.name.trim(),
    variants: [bla.join('')]
  }
}

export const getFont = (prop: 'primaryFont' | 'secondaryFont', theme) => () => {
  const fontData = getFontData(theme[prop].split(' '))
  return `${fontData.name}; font-weight: ${fontData.variants[0]};`
}

const getOrientation = (compOrientation, __ORIENTATION__) => () => {
  const isLeft = compOrientation === __ORIENTATION__.LEFT
  // not all components have orientation
  if (!!compOrientation) {
    return ` 
        flex; flex-direction: ${isLeft ? 'row' : 'row-reverse'};
        @media only screen and (max-width: 992px) {
          flex-direction: ${isLeft ? 'column' : 'column-reverse'}
        }
        `
  }
  return ''
}

export const styleProvider =
  theme =>
  prop =>
  ({ theme: componentTheme }) => {
    const { colorMode, look, orientation: _o } = componentTheme
    const themeData = theme[colorMode]
    const lookData = LOOK_DATA[look]
    // order is important
    // there are style properties from look
    // which theme overrides
    // (ex. there are no shadows in dark mode of any component
    // but there are shadow in different looks for overall website)
    const styles = {
      ...lookData,
      ...themeData,
      orientation: getOrientation(_o, ORIENTATION),
      headingSize: () => `${componentTheme.fontSize * 2}px`,
      paragraphSize: () => `${componentTheme.fontSize}px`,
      primaryFont: getFont('primaryFont', componentTheme),
      secondaryFont: getFont('secondaryFont', componentTheme)
    }
    const styleGetter = styles[prop]
    return styleGetter ? styleGetter(componentTheme.palette, colorMode) : ''
  }

const hexTable = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  a: 10,
  b: 11,
  c: 12,
  d: 13,
  e: 14,
  f: 15
}

export const hexToRgb = hex => {
  const _hexArr = hex.replace('#', '').toLowerCase().split('')
  const pairs = _hexArr.reduce((acc, _, idx, arr) => {
    if (idx % 2 === 0) {
      return [...acc, arr.slice(idx, idx + 2)]
    }
    return acc
  }, [])

  return pairs
    .reduce((rgb, pair) => {
      const key = pair[0]
      const color = hexTable[key] * 16 + hexTable[pair[1]]
      return `${rgb},${color}`
    }, '')
    .replace(',', '')
}

export const hexToRgbA = (colorHex: string, alpha: number): ?string => {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(colorHex)) {
    let hex = colorHex.substring(1).split('')
    if (hex.length === 3) {
      hex = [hex[0], hex[0], hex[1], hex[1], hex[2], hex[2]]
    }

    hex = Number(`0x${hex.join('')}`)

    return `rgba(${[(hex >> 16) & 255, (hex >> 8) & 255, hex & 255].join(
      ','
    )},${alpha})`
  }
  throw new Error('Bad Hex')
}

export const isMobileDevice = () =>
  global.window && global.window.innerWidth <= 768

export const getTagNameByCategory = (category: string) => {
  if (category === 'header' || category === 'footer') {
    return category
  }
  return 'section'
}

/**
 * Copies given string to clipboard
 * @param {string} str Stirng to copy
 */
export const copyToClipboard = str => {
  const el = document.createElement('textarea') // Create a <textarea> element
  el.value = str // Set its value to the string that you want copied
  el.setAttribute('readonly', '') // Make it readonly to be tamper-proof
  el.style.position = 'absolute'
  el.style.left = '-9999px' // Move outside the screen to make it invisible
  document.body.appendChild(el) // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false // Mark as false to know no selection existed before
  el.select() // Select the <textarea> content
  document.execCommand('copy') // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el) // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges() // Unselect everything on the HTML document
    document.getSelection().addRange(selected) // Restore the original selection
  }
}

export const _debounce = (func, delay) => {
  let inDebounce = null
  return (...args) => {
    if (inDebounce) {
      clearTimeout(inDebounce)
    }
    inDebounce = setTimeout(() => func(...args), delay)
  }
}

export const generateRandomNumber = maxNumber =>
  Math.floor(Math.random() * maxNumber)

export const isLandscapeMobile = () =>
  global.window &&
  window.innerHeight < window.innerWidth &&
  window.innerWidth <= 812

const setCookie = (
  key: string,
  value: string,
  expirationInDays: number = 100
) => {
  const date = new Date()

  date.setDate(date.getDate() + expirationInDays)
  if (typeof document !== 'undefined') {
    document.cookie = `${key}=${value}; path=/; expires=${date.toUTCString()}`
  }
}

const getCookie = (key: string) => {
  const matches =
    typeof document !== 'undefined' &&
    document.cookie.match(
      new RegExp(
        '(?:^|; )' +
          key.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') +
          '=([^;]*)'
      )
    )
  return matches ? decodeURIComponent(matches[1]) : undefined
}

export const cookieManager = {
  setCookie,
  getCookie
}

export const sentry = () =>
  `Sentry.init({ dsn: 'https://1d21c4c4f0ba46ebb2a4bd26f59df35f@sentry.io/1545556' })`

export const removeEmptyFields = (obj: Object) =>
  Object.keys(obj).reduce((acc, item) => {
    const objValue = obj[item]
    if (typeof objValue === 'boolean' || objValue) {
      return {
        ...acc,
        [item]: objValue
      }
    }
    return acc
  }, {})

const screenSizes = {
  480: 'small',
  768: 'medium',
  1440: 'large'
}

export const getCurrentScreenSize = () => {
  if (typeof window !== 'undefined') {
    const size = Object.keys(screenSizes).find(
      size => Math.max(window.innerWidth, size) === parseInt(size)
    )
    return screenSizes[size] || 'xlarge'
  }
  return 'xlarge'
}

export const getBackgroundImage = (imgDimensions, initialImg) =>
  (imgDimensions && imgDimensions[getCurrentScreenSize()]) || initialImg

/**
 * Function for `Header` components
 * Scroll window with 500px.
 **/
export const scrollBottom = (isEditing: boolean) => {
  if (isEditing) {
    return
  }
  if (typeof window !== 'undefined') {
    const scrollTop = window.scrollY + 500
    window.scroll({
      top: scrollTop,
      behavior: 'smooth'
    })
  }
}

export const getFormatedNumber = number => {
  const numberString = number.toString()
  return numberString.length === 1 ? `0${numberString}` : numberString
}

//youtube links api

export const isYoutubeUrl = url =>
  url.match(
    /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=/
  )

const regExps = [/(&)?list=([a-zA-Z0-9-_]+)&?/, /(&)?index=([0-9]+)&?/]

const formatUrl = (url, regexps) =>
  regexps.reduce((formattedUrl, regex) => {
    return formattedUrl.replace(regex, '')
  }, url)

const _getYoutubeFormatedUrl = (url: string) => formatUrl(url, regExps)

const _grabYoutubeVideoId = url => {
  const youtubeIdMatch = url.match(
    /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})|youtube\.com\/playlist\?list=/
  )[1]

  return youtubeIdMatch || null
}

const _youtubeVideoThumbById = videoId =>
  `//img.youtube.com/vi/${videoId}/hqdefault.jpg`

export const getYoutubeThumbnail = url => {
  if (!isYoutubeUrl(url)) {
    return null
  }

  const videoId = _grabYoutubeVideoId(url)
  if (videoId) {
    return _youtubeVideoThumbById(videoId)
  }
  return null
}

export const getBackgroundVideoUrl = (url: string) =>
  isYoutubeUrl(url) ? _getYoutubeFormatedUrl(url) : url

//CountDown util functions
//TODO separate CountDown component from `Header17` and `Header18`

// Find the distance between now and the count down date
export const getDateDistance = dateWithMiliSeconds => {
  const now = new Date().getTime()
  return dateWithMiliSeconds - now
}

export const isToday = someDate => {
  const today = new Date()
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  )
}

export const isPastDate = date => {
  if (getDateDistance(date.getTime()) < 0 && !isToday(date)) {
    return true
  }
  return false
}

const parseDate = input => {
  let parts = input.match(/(\d+)/g)
  // new Date(year, month [, date [, hours[, minutes[, seconds[, ms]]]]])
  return new Date(parts[0], parts[1] - 1, parts[2]) // months are 0-based
}
// Time calculations for days, hours, minutes and seconds
export const getDate = date => {
  let milliseconds = date
  if (typeof date !== 'number') {
    milliseconds = parseDate(date).getTime()
  }
  const distance = getDateDistance(milliseconds)
  if (distance < 0) {
    return { days: '00', hours: '00', minutes: '00', seconds: '00' }
  }
  const days = getFormatedNumber(Math.floor(distance / (1000 * 60 * 60 * 24)))
  const hours = getFormatedNumber(
    Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  )

  const minutes = getFormatedNumber(
    Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
  )
  const seconds = getFormatedNumber(Math.floor((distance % (1000 * 60)) / 1000))

  return {
    days,
    hours,
    minutes,
    seconds
  }
}

// This function will create proxy object to access undefined properties as functions
const _Proxy = typeof Proxy !== 'undefined' ? Proxy : function () {}
export const createDummyProxy = () => {
  const proxyHandler = {
    get: function () {
      return () => {}
    }
  }

  return new _Proxy({}, proxyHandler)
}

export const isValidUrl = (url: string): boolean => {
  const regExp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u005f-\uffff0-9]-*)*[a-z\u005f-\uffff0-9]+)(?:\.(?:[a-z\u005f-\uffff0-9]-*)*[a-z\u005f-\uffff0-9]+)*(?:\.(?:[a-z\u005f-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/
  return regExp.test(url)
}

export const isValidEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const isValidPhoneNumber = number => {
  const regExp1 = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
  if (regExp1.test(number)) {
    return true
  }
  const regexp4 = /1?\s?((\(\d{3}\))|(\d{3}))(-|\s)?\d{3}(-|\s)?\d{4}/
  if (regexp4.test(number)) {
    return true
  }
  return false
}

export const isValidName = name => {
  const regExp = new RegExp(
    /^((?!http|www|[\?\#\&\<\>\"\'\*\(\)\\\/\~\;\:\$\^\%\@\{\}\[\]]).){1,100}$/g
  )

  return regExp.test(name)
}

export const onEnterPress = onSave => e => {
  if (e.key === 'Enter') {
    onSave()
  }
}

export const getExternalUrl = url => {
  if (!isValidUrl(url)) {
    return url
  }
  const regExp = new RegExp('^(?:http|ftp)s?://|[A-Za-z]:\\|//.*')
  const isAbsolutePath = regExp.test(url)
  return isAbsolutePath ? url : `http://${url}`
}

export const openUrl = (url: string, isBlank: boolean = true): void => {
  //cross browser mouseEvents support
  let evt = document.createEvent('MouseEvents')
  evt.initMouseEvent(
    'click',
    true,
    true,
    window,
    0,
    0,
    0,
    0,
    0,
    false,
    false,
    false,
    false,
    0,
    null
  )
  const link = document.createElement('a')
  isBlank && link.setAttribute('target', '_blank')
  link.href = url
  link.dispatchEvent(evt)
}

export const getSecondComponentMode = currentPageComponents => {
  const componentAfterHeader = currentPageComponents[1]
  if (!componentAfterHeader) {
    return null
  }
  const mode = componentAfterHeader.colorMode
  return mode ? mode : 'LIGHT'
}

export const addScriptToHtml = innerHTML => {
  const script = document.createElement('script')
  script.innerHTML = innerHTML
  script.type = 'text/javascript'

  document.head.appendChild(script)
}

export const addMetaToHtml = (name, content) => {
  const meta = document.createElement('meta')
  meta.name = name
  meta.content = content

  document.head.appendChild(meta)
}

export const addLinkToHtml = (rel, type, href) => {
  const link = document.createElement('link')
  link.rel = rel
  link.type = type
  link.href = href

  document.head.appendChild(link)
}

export const compose =
  (...fns) =>
  arg =>
    fns.reduceRight((acc, fn) => fn(acc), arg)

export const scrollToTop = scrollableContainer => {
  const htmlNode = document.querySelector('html')
  htmlNode.setAttribute('style', 'scroll-behavior: unset;')
  setTimeout(() => {
    const _scrollableContainer = scrollableContainer || window
    _scrollableContainer.scrollTo(0, 0)
    htmlNode.removeAttribute('style')
  })
}

const FAILED_BG_URL =
  'https://static.rfstat.com/renderforest/images/website_maker_images/Background_Fail_repeat_element.jpg'

export const getBackgroundStyles = theme => props => {
  const getStyleForProp = styleProvider(theme)
  const _bgColor = getStyleForProp('backgroundColor')(props)

  const {
    backgroundImgUrl,
    backgroundImgDimensions,
    isEditing,
    bgOverlayTransparency
  } = props

  const bgGradient = _bgColor
    ? hexToRgbA(_bgColor, bgOverlayTransparency)
    : `rgba(0,0,0,0)`

  const { failed, isLoading } = backgroundImgUrl || {}
  const hasDimensions =
    backgroundImgDimensions && !!Object.keys(backgroundImgDimensions).length

  const transparentBgOverlay = `linear-gradient(${bgGradient}, ${bgGradient}),`

  if (isEditing) {
    const showInvalidBg = failed || (backgroundImgUrl && !hasDimensions)
    if (isLoading) {
      return css`
        background: ${transparentBgOverlay} url(${backgroundImgUrl}) no-repeat
          center/cover;
      `
    } else if (showInvalidBg) {
      return css`
        background: ${transparentBgOverlay} url(${FAILED_BG_URL}) center/340px
          330px;
      `
    }
  }

  if (!hasDimensions) {
    return css`
      background: ${getStyleForProp('background')(props)};
    `
  }

  const getBgBySize = size => {
    const bgImageUrl = backgroundImgDimensions && backgroundImgDimensions[size]
    return css`
      background: ${transparentBgOverlay} url(${bgImageUrl}) no-repeat
        center/cover;
    `
  }

  return css`
    ${getBgBySize('xlarge')};
    @media only screen and (max-width: 1440px) {
      ${getBgBySize('large')}
    }
    @media only screen and (max-width: 768px) {
      ${getBgBySize('medium')}
    }
    @media only screen and (max-width: 480px) {
      ${getBgBySize('small')}
    }
  `
}

export const getBackground = theme => props => {
  const getStyleForProp = styleProvider(theme)
  return `${getStyleForProp('background')(props)}`
}

export const getMapSrcWithLatLng = geoLocation => {
  return `https://maps.google.com/maps?q=${Object.values(geoLocation).join(
    ','
  )}&output=embed`
}

export const isNoneNegativeInteger = value => {
  const num = Number(value)
  return Number.isInteger(num) && num >= 0
}
export const getTextStyleByAlignment = ({ alignment }) => {
  const stylesByAlignment = {
    default: '',
    left: css`
      text-align: left;
    `,
    center: css`
      text-align: center;
    `,
    right: css`
      text-align: right;
    `
  }

  return stylesByAlignment[alignment]
}

export const getOffsetXByMouseX = (mouseX, compWidth, movementPercent) => {
  const _compWidth = Math.min(compWidth, 1400)
  const maxOffset = (_compWidth * movementPercent) / 100
  return (mouseX * maxOffset) / compWidth
}

export const getOffsetYByMouseY = (mouseY, compHeight, movementPercent) => {
  const _compHeight = Math.min(compHeight, 1200)
  const maxOffset = (_compHeight * movementPercent) / 100
  return (mouseY * maxOffset) / compHeight
}

export const getCurrencySign = currency => CURRENCIES[currency]

export const getAddressFromQuery = (addressQuery: string) => {
  const rawAddress = addressQuery.split('q=')[1]
  return rawAddress ? rawAddress.split('&')[0].split('+').join(' ') : ''
}

export const getCardBGOverlayStyles = theme => props => {
  const getStyleForProp = styleProvider(theme)
  const _bgColor = getStyleForProp('cardBackgroundColor')(props)

  const bgGradient = _bgColor
    ? hexToRgbA(_bgColor, props.bgOverlayTransparency)
    : `rgba(0,0,0,0)`

  return css`
    background: linear-gradient(${bgGradient}, ${bgGradient});
  `
}

export const convertSpotifyUrlToEmbedUrl = (url: string) =>
  url.replace('spotify.com', 'spotify.com/embed')
