export const validation = {
  title: { required: true },
  subTitle: { required: true },
  titleMaxChar: 30,
  subTitleMaxChar: 60,
  paragraphMaxChar: 300,
  images: {
    min: 4,
    max: 12
  }
}