/* @flow */
import styled from 'styled-components'
import THEME from './lightBox.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const PreviewModeContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: ${({ isEditing }) => (isEditing ? 50 : 2147483649)};
  width: 100%;
  height: 100%;
  .rf-close {
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
    padding: 20px;
  }
  .slick-list {
    height: 100%;
    & > * {
      &:focus {
        outline: none !important;
      }
    }
  }
  .slick-slide > div {
    margin: 0;
    height: 100%;
  }
  .slick-arrow.slick-prev {
    display: none !important;
  }
  .slick-arrow.slick-next {
    display: none !important ;
  }
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }

  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }

  .slick-slide.dragging img {
    pointer-events: none;
  }

  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-arrow.slick-prev {
    display: none !important;
  }
  .slick-arrow.slick-next {
    display: none !important;
  }
`

const CarouselSlider = styled.div`
  margin-bottom: 10px;
  position: absolute;
  bottom: 0;
  left: 10px;
  width: 100%;
  .slick-slide img {
    object-fit: cover;
  }
  .control-container {
    height: ${props => `${props.lightBoxImageWidth}px`};
    width: ${props => `${props.lightBoxImageWidth}px`} !important;
  }
`

const PortImgWrap = styled.div`
  padding: ${({ isEditing }) => (isEditing ? '270px 0 0 0' : '50px 0 0 0')};
  .LazyLoad {
    height: 100%;
  }
  &:focus {
    outline: 0;
  }
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    box-sizing: border-box;
    width: ${props => `${props.lightBoxImageWidth}px`};
    height: ${props => `${props.lightBoxImageWidth}px`};
    border: ${props =>
      props.selected ? '2px solid white' : '2px solid transparent'};
  }
`

const MainSliderContainer = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .left {
    position: absolute;
    top: 48%;
    left: -50px;
    @media only screen and (max-width: 600px) {
      left: -44px;
    }
  }
  .right {
    position: absolute;
    right: -50px;
    top: 48%;
    @media only screen and (max-width: 600px) {
      right: -44px;
    }
  }
`

const MainSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-bottom: 130px;
  box-sizing: border-box;
`

const LightBoxSelectedImage = styled.img`
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
`

const LightBoxImage = styled.img`
  box-sizing: border-box;
  height: ${props => `${props.lightBoxImageWidth}px`};
  width: ${props => `${props.lightBoxImageWidth}px`};
  cursor: pointer;
`

const IconContainer = styled.div`
  width: 36px;
  min-width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
`

const SlideIndexIndicator = styled.div`
  position: absolute;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  padding: 10px;
  font-size: 14px;
`

const TopSliderContainer = styled.div`
  display: flex;
  position: relative;
  flex: none;
  width: 80%;
  height: 100%;
  max-width: 600px;
  max-height: 600px;
  margin: 0 auto;
  flex: none;
  @media only screen and (max-height: 800px) {
    max-width: 400px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 260px;
  }
  & > .slick-slider {
    width: 100%;
  }
  .slick-slide > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    height: 100%;
  }
  .slick-track {
    height: 100%;
  }
`

const CustomSSContainer = styled.div`
  background: ${getStyleForProp('bg')};
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`

const ImageWrap = styled.div`
  max-width: 500px;
  .LazyLoad {
    height: initial;
  }
  .lazy-figure {
    height: initial;
    position: relative;
    padding-top: 100%;
    .lightbox-selected-image {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      width: 100%;
      height: 100%;
    }
  }
`

export default {
  PreviewModeContainer,
  MainSliderContainer,
  MainSliderWrapper,
  CustomSSContainer,
  LightBoxSelectedImage,
  LightBoxImage,
  IconContainer,
  SlideIndexIndicator,
  TopSliderContainer,
  ImageWrap,
  CarouselSlider,
  PortImgWrap
}
