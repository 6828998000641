import styled, { css } from 'styled-components'
import { styleProvider } from '@website/common/utils'
import { PAYPAL_BUTTONS_SETTINGS } from './consts'
import THEME from './theme'
const getStyleForProp = styleProvider(THEME)

const getPrimaryButtonStyles = () => {
  return css`
    ${getStyleForProp('buttonShadow')};
    ${getStyleForProp('buttonBackground')}
    >div:first-child {
      background-image: url(https://static.rfstat.com/renderforest/images/website_maker_images/paypal-button-icon-light.svg);
    }
  `
}

const getSecondaryButtonStyles = () => {
  return css`
    ${getStyleForProp('raisedShadow')};
    ${getStyleForProp('buttonBackgroundTertiary')}
    >div:first-child {
      background-image: url(https://static.rfstat.com/renderforest/images/website_maker_images/paypal-button-icon-dark.svg);
    }
  `
}

const getCustomButtonStyles = buttonStyle => {
  const {
    background,
    color,
    hoveredBackground,
    shadow,
    icon
  } = PAYPAL_BUTTONS_SETTINGS[buttonStyle]
  return css`
    background-color: ${background};
    color: ${color};
    > div:first-child {
      background-image: url(https://static.rfstat.com/renderforest/images/website_maker_images/paypal-button-icon-${icon}.svg);
    }
    &:not(:active) {
      box-shadow: 0 4px 10px 0 ${shadow};
    }
    &:hover {
      background-color: ${hoveredBackground};
    }
  `
}

export const IconWrapper = styled.div`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('buttonRadius')};
  border: none;
  background-repeat: no-repeat;
  max-width: 300px;
  padding-right: 40px;
  padding-left: 40px;
  box-sizing: border-box;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  ${({ buttonStyle }) =>
    buttonStyle === 'primary'
      ? getPrimaryButtonStyles()
      : buttonStyle === 'secondary'
      ? getSecondaryButtonStyles()
      : getCustomButtonStyles(buttonStyle)}
`

export const ButtonText = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px !important;
`
