/* @flow */
import React, { useContext } from 'react'
import S from './Feature15.styled'
import { validation } from './Feature15.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import LinkWrapper from '@website/common/components/LinkWrapper'
import If from '@website/common/components/Conditional'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, SiteIdContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import './Feature15.styles.sass'
import { translate } from '@editor/common/utils/translations'

const Feature15 = props => {
  const {
    data: {
      title,
      subTitle,
      paragraph,
      features,
      ctaText,
      buttonUrl,
      buttonText
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const siteId = useContext(SiteIdContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <S.Container>
      <WMContainer className="feature-15-container">
        <Show when={[title, paragraph, subTitle]}>
          <div className="wm-heading">
            <Show when={[title, subTitle]}>
              <div className="wm-heading-paragraph">
                <EditableContent
                  text={title}
                  as={S.Title}
                  maxCount={validation.headingMaxChar}
                  className="WM_GLOBAL_heading72"
                  onChange={(newTitle: string) =>
                    dispatcher(Actions.changeTitle(newTitle))
                  }
                />
                <EditableContent
                  text={subTitle}
                  as={S.SubTitle}
                  maxCount={validation.subHeadingMaxChar}
                  className="WM_GLOBAL_heading42"
                  onChange={(newSubTitle: string) =>
                    dispatcher(Actions.changeSubTitle(newSubTitle))
                  }
                />
              </div>
            </Show>
            <EditableContent
              text={paragraph}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_heading20"
              onChange={(newParagraph: string) =>
                dispatcher(Actions.changeParagraph(newParagraph))
              }
            />
          </div>
        </Show>

        <Mapper
          data={features}
          render={(item: string, idx: number) => {
            return (
              <ControlsWithModalOpener
                actions={Actions.featureActions({
                  idx,
                  showRemove: features.length > validation.features.min
                })}
                key={idx}
                style={{ width: '100%' }}
              >
                <div className="blocks-wrap">
                  <Mapper
                    data={item}
                    render={(feature: string, featureIdx: number) => {
                      return (
                        <S.BlockItemWrap key={featureIdx}>
                          <Show when={[feature.badgeText]}>
                            <S.Badge>
                              <EditableContent
                                text={feature.badgeText}
                                as={S.BadgeText}
                                maxCount={validation.badgeMaxChar}
                                className="WM_GLOBAL_secondary-font"
                                onChange={(newBadgeText: string) =>
                                  dispatcher(
                                    Actions.changeBadgeText(
                                      newBadgeText,
                                      featureIdx,
                                      idx
                                    )
                                  )
                                }
                              />
                            </S.Badge>
                          </Show>
                          <ControlsWithModalOpener
                            actions={Actions.imageActions(
                              idx,
                              featureIdx,
                              feature.imgUrl
                            )}
                            style={{ width: '100%' }}
                          >
                            <div className="block-img-wrap">
                              <Image
                                as={S.Img}
                                alt="item img"
                                sizes={feature.imgDimensions}
                                defaultImgSrc={feature.imgUrl}
                              />

                              <S.Count>
                                0{(idx === 0 ? 0 : 3) + featureIdx + 1}
                              </S.Count>
                            </div>
                          </ControlsWithModalOpener>
                          <Show when={[feature.paragraph]}>
                            <div className="block-content-wrap">
                              <EditableContent
                                text={feature.paragraph}
                                as={S.BlockParagraph}
                                maxCount={validation.blockParagraphMaxChar}
                                className="WM_GLOBAL_secondary-font"
                                onChange={(newItemParagraph: string) =>
                                  dispatcher(
                                    Actions.changeItemParagraph(
                                      newItemParagraph,
                                      featureIdx,
                                      idx
                                    )
                                  )
                                }
                              />
                            </div>
                          </Show>
                        </S.BlockItemWrap>
                      )
                    }}
                  />
                </div>
              </ControlsWithModalOpener>
            )
          }}
        />
        <AddButton
          onAdd={() => dispatcher(Actions.addBlock())}
          toShow={features.length < validation.features.max}
          style={{ margin: '20px 0' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_blocks_label')}
        </AddButton>
        <If
          condition={ctaText || buttonText || buttonUrl}
          otherwise={() => (
            <AddButton
              onAdd={() => dispatcher(Actions.addBottomBlock())}
              toShow
              style={{ margin: '20px 0' }}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_text_block_label')}
            </AddButton>
          )}
          then={() => (
            <ControlsWithModalOpener
              actions={Actions.joinBlockAction}
              style={{ width: '100%' }}
            >
              <S.CtaWrap>
                <EditableContent
                  text={ctaText}
                  as={S.CtaText}
                  maxCount={validation.ctaHeadingMaxChar}
                  className="WM_GLOBAL_secondary-font"
                  onChange={(newCtaText: string) =>
                    dispatcher(Actions.changeCtaText(newCtaText))
                  }
                />
                <S.BtnWrap>
                  <ControlsWithModalOpener
                    actions={Actions.btnActions(
                      0,
                      {
                        text: buttonText,
                        link: buttonUrl
                      },
                      false
                    )}
                  >
                    <LinkWrapper
                      url={buttonUrl}
                      text={buttonText}
                      linkProps={{ className: 'WM_GLOBAL_secondary-font' }}
                    />
                  </ControlsWithModalOpener>
                </S.BtnWrap>
              </S.CtaWrap>
            </ControlsWithModalOpener>
          )}
        />
      </WMContainer>
    </S.Container>
  )
}

export default Feature15
