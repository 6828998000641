import { useLayoutEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { scrollToTop } from '@website/common/utils'

export default (page, scrollableContainer) => {
  const { hash } = useHistory().location

  const scrollToEl = () => {
    if (typeof history !== 'undefined') {
      history.scrollRestoration = 'manual'
    }
    const id = hash.substr(1)
    setTimeout(() => {
      const el = document.getElementById(id)
      el && el.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  // Scroll to component
  useLayoutEffect(() => {
    if (hash) {
      scrollToEl()
    }
  }, [])

  // Scroll to top
  useLayoutEffect(() => {
    if (!hash) {
      scrollToTop(scrollableContainer)
    }
  }, [page, hash])
}
