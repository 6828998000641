/* @flow */
import styled, { css } from 'styled-components'
import Icon from '@website/common/components/Icon'
import THEME from './Email4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgbA,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
`

const WmCustomContainer = styled(WMContainer)`
  ${({ isEditing }) => (isEditing ? 'margin-top: 50px;' : null)}
`

const FormImageWrapper = styled.div`
  display: flex;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  box-shadow: ${getStyleForProp('raisedShadow')};
  background-color: #ffffff;
  border-radius: ${({ theme }) =>
    theme.look === 'ROUNDED' || theme.look === 'CORNERED' ? '10px' : '0'};
  ${({ theme }) =>
    css`
      flex-direction: ${theme.orientation === 'LEFT' ? 'row' : 'row-reverse'};
    `}

  @media only screen and (max-width: 1200px) {
    ${({ theme }) =>
    css`
        flex-direction: ${theme.orientation === 'LEFT'
        ? 'column'
        : 'column-reverse'};
      `}
    max-width: 600px;
  }
  @media only screen and (max-width: 767px) {
    box-shadow: initial;
  }
`

const RightContent = styled.div`
  width: 100%;
  flex-grow: 1;
  max-width: 600px;
  background-color: #ffffff
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    align-items: center;
    border-radius: ${({ theme }) => {
    const radius = getStyleForProp('buttonRadius')({ theme })
    return theme.orientation === 'LEFT'
      ? ` ${radius} ${radius} 0 0`
      : ` 0 0 ${radius} ${radius}`
  }};
  }
  @media only screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 20px;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 30px;
  ${getTextStyleByAlignment};
`

const InputWrapper = styled.div`
  position: relative;
  .required-icon {
    position: absolute;
    right: 10px;
    bottom: 5px;
    padding: 10px;
    color: ${({ theme }) => theme.palette.tertiaryDark};
    @media only screen and (max-width: 767px) {
      right: 4px;
    }
  }

  img {
    object-fit: cover;
  }
`

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 40px 0 30px;
  height: 50px;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('buttonRadius')};
  border: ${({ isError, theme }) =>
    `1px solid ${
    isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 12px;
  margin: 0 0 20px 0;
  height: 100px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('inputRadius')};
  border: ${({ theme }) =>
    `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${getStyleForProp('placeholder')};
  }
`

const FormContainer = styled.div`
  width: 100%;
  padding: 80px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  @media only screen and (max-width: 1200px) {
    padding: ${({ theme }) => {
    return theme.orientation === 'LEFT' ? `40px 40px 20px` : `40px`
  }};
  }
  @media only screen and (max-width: 767px) {
    padding: 0px 20px;
  }

  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
    }
  }
  .button-container {
    margin-top: 10px;
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  > div {
    svg {
      max-width: 12px;
      max-height: 12px;
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding: ${({ theme }) => {
    return theme.orientation === 'LEFT'
      ? '40px 40px 40px 0'
      : '40px 0 40px 40px'
  }};
  box-sizing: border-box;
  max-height: 630px;
  top: 50%;
  transform: translateY(-50%);
  @media only screen and (max-width: 1200px) {
    transform: translateY(0%);
    top: 0;
    padding: ${({ theme }) => {
    return theme.orientation === 'LEFT' ? '20px' : '20px 20px 0'
  }};
  }
  .LazyLoad {
    width: 100%;
    background: ${({ theme }) => theme.palette.accentWhite};
    border-radius: ${({ theme }) =>
    theme.look === 'ROUNDED' || theme.look === 'CORNERED' ? '10px' : '0'};
    overflow: hidden;
    padding-top: 100%;
    height: 0;
    position: relative;
    .lazy-figure {
      position: absolute;
      top: 0;
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  @media only screen and (max-width: 1200px) {
    ${({ isEditing }) => (isEditing ? 'padding-top: 20px;' : null)}
  }
  .control-container.control-container {
    display: block;
    width: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  @media only screen and (max-width: 1200px) {
    position: relative !important;
  }
`

const RequiredIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.palette.tertiaryNormal
}))`
  line-height: 0;
`

const ErrorMessage = styled.div`
  position: absolute;
  top: 52px;
  color: #eb0c00;
  font-size: 14px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 5px;
  }
`

const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

const SuccessMessage = styled.p`
  color: #048900;
  font-size: '18px';
  position: absolute;
  bottom: ${({ theme }) => {
    return theme.orientation === 'LEFT' ? '50px' : '50px'
  }};
  display: flex;
  align-items: center;
  text-align: start;
  white-space: nowrap;
  @media only screen and (max-width: 1200px) {
    bottom: ${({ theme }) => {
    return theme.orientation === 'LEFT' ? '-10px' : '10px'
  }};
  }
  @media only screen and (max-width: 650px) {
    bottom: -29px;
  }
`

const SuccessIcon = styled.img`
  width: 19px;
  margin-right: 10px;
`

export default {
  WmCustomContainer,
  RightContent,
  Title,
  Input,
  TextArea,
  StyledContainer,
  Paragraph,
  InputContainer,
  FormContainer,
  FormImageWrapper,
  ControlContainer,
  ImageWrapper,
  Img,
  InputWrapper,
  RequiredIcon,
  ErrorMessage,
  ErrorIcon,
  SuccessMessage,
  SuccessIcon
}
