/* @flow */
import React, { useCallback, memo, useContext } from 'react'

import * as Styled from './styled'
import { ALIGNMENTS_DATA, LEFT, CENTER, RIGHT } from './consts'
import { TTextAlignmentCompProps, TTextAlignmentSwitcherProps } from './types'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { secondary } from '@website/common/styles/colors'
// import FirstLine from './first_line.png'
// import SecondLine from './second_line.png'
// import ThirdLine from './third_line.png'
import { MobileDetectContext } from '@contexts'
import { addAnalyticsEvent } from '@editor/common/utils'

const TextAlignmentSwitcherMobile = ({
  alignment,
  changeAlignment,
  stopPropagation
}: TTextAlignmentCompProps) => {
  const alignmentData = ALIGNMENTS_DATA[alignment]
  const { IconComp } = alignmentData

  const onClick = useCallback(
    () =>
      alignment === LEFT
        ? changeAlignment(CENTER)
        : alignment === CENTER
        ? changeAlignment(RIGHT)
        : changeAlignment(LEFT),
    [alignment, changeAlignment]
  )

  return (
    <Styled.SwitcherContainer onClick={stopPropagation}>
      <Styled.LineWrapper onClick={onClick}>
        <IconComp />
      </Styled.LineWrapper>
    </Styled.SwitcherContainer>
  )
}

const TextAlignmentSwitcherDesktop = ({
  alignment,
  changeAlignment,
  stopPropagation
}: TTextAlignmentCompProps) => (
  <Styled.SwitcherContainer onClick={stopPropagation}>
    {Object.values(ALIGNMENTS_DATA).map((alignmentData, idx) => {
      const { value, tooltipText, IconComp } = alignmentData
      return (
        <TooltipWrapper
          key={idx}
          text={tooltipText}
          position="top"
          color={secondary.ExtraDark}
        >
          <Styled.LineWrapper
            isActive={alignment === value}
            onClick={() => {
              changeAlignment(value)
            }}
          >
            <IconComp />
          </Styled.LineWrapper>
        </TooltipWrapper>
      )
    })}
    {/*<img src={FirstLine} alt="first-line" className="first-line" />*/}
    {/*<img src={SecondLine} alt="second-line" className="second-line" />*/}
    {/*<img src={ThirdLine} alt="third-line" className="third-line" />*/}
  </Styled.SwitcherContainer>
)

const TextAlignmentSwitcher = ({
  alignment,
  changeAlignment
}: TTextAlignmentSwitcherProps) => {
  const isMobile = useContext(MobileDetectContext)

  const stopPropagation = useCallback(e => {
    e.stopPropagation()
  }, [])

  const _changeAlignment = useCallback(
    value => {
      changeAlignment(value)
      addAnalyticsEvent('SiteMaker Text Alignment', 'Align text', value)
    },
    [changeAlignment]
  )

  return isMobile ? (
    <TextAlignmentSwitcherMobile
      alignment={alignment}
      changeAlignment={_changeAlignment}
      stopPropagation={stopPropagation}
    />
  ) : (
    <TextAlignmentSwitcherDesktop
      alignment={alignment}
      changeAlignment={_changeAlignment}
      stopPropagation={stopPropagation}
    />
  )
}

export default memo(TextAlignmentSwitcher)
