/* @flow */
import React, { useContext, useState, memo } from 'react'
import * as S from './Header56.styled'
import THEME from './Header56.theme'
import { validation } from './Header56.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import If from '@website/common/components/Conditional'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import { scrollBottom } from '@website/common/utils'
import Buttons from '@website/common/components/Buttons'
import SocialLinks from '@website/common/components/SocialLinks'

const Header56 = memo(props => {
  const {
    data: {
      title,
      textLeft,
      textRight,
      textLeftAlignment,
      titleAlignment,
      textRightAlignment,
      logoSrc,
      logoColor,
      buttons,
      headerSocials,
      logoWidth,
      logoDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    },
    hasNextComponent
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      topOffset={navBarHeight}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        isEditing={isEditing}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight}>
        <S.LinesContainer isEditing={isEditing}>
          <span isEditing={isEditing}></span>
          <span isEditing={isEditing}></span>
          <span isEditing={isEditing}></span>
          <span isEditing={isEditing}></span>
          <span isEditing={isEditing}></span>
        </S.LinesContainer>
        <S.HeaderContent isEditing={isEditing}>
          <S.HeaderContentBlock isEditing={isEditing}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              required={!textLeft && !textRight}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading120"
              as={S.Title}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <Buttons data={buttons} />
          </S.HeaderContentBlock>
          <S.BottomContainer>
            <EditableContent
              text={textLeft}
              as={S.BottomLeft}
              required={!title && !textRight}
              alignment={textLeftAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeTextLeft}
              changeAlignment={Actions.changeTextLeftAlignment}
            />
            <EditableContent
              text={textRight}
              as={S.BottomRight}
              required={!title && !textLeft}
              alignment={textRightAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeTextRight}
              changeAlignment={Actions.changeTextRightAlignment}
            />
          </S.BottomContainer>
        </S.HeaderContent>
      </S.WmCustomContainer>
      <If
        condition={hasNextComponent}
        then={() => (
          <S.DownButton role="button" onClick={() => scrollBottom(isEditing)}>
            <S.ScrollIcon size="xlarge" name="icon-arrow_small_bottom" />
          </S.DownButton>
        )}
      />
    </S.StyledContainer>
  )
})

export default Header56
