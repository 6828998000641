/* @flow */
import React, { useContext, memo } from 'react'
import S from './PayPal1.styled'
import ActionGetter from './Actions'
import { validation } from './PayPal1.consts'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { EditingContext, DispatchContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'
import { PayPalButtonForm } from '@website/common/components/PayPalButton'
import { getCurrencySign } from '@website/common/utils'
import { openUrl, getExternalUrl } from '@website/common/utils'

const PayPal1 = memo(props => {
  const {
    data: {
      paragraph,
      paragraphAlignment,
      titleAlignment,
      blocks,
      additionalText,
      paypalBtnDefaultProps,
      additionalUrl,
      logoImgUrl,
      logoImgDimensions,
      largeImgUrl,
      clientUrl,
      largeImgDimensions,
      largeImgCropParams,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    },
    componentId
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const hasBlocksIcon = blocks.some(elem => elem.iconName.length > 0)
  const isSelling = paypalBtnDefaultProps.type === 'selling'

  const {
    currency,
    productPrice,
    productDiscountedPrice,
    productName,
    headline
  } = paypalBtnDefaultProps

  const editableContentCustomActions = Actions.paypalButtonModalActions(
    paypalBtnDefaultProps,
    componentId
  )

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WMCustomContainer>
        <S.ContentConainer>
          <S.LeftContainer>
            <If
              condition={logoImgUrl}
              then={() => (
                <ControlsWithModalOpener
                  actions={Actions.logoActions(logoImgUrl, clientUrl)}
                >
                  <Image
                    as={S.Logo}
                    alt="Logo"
                    sizes={logoImgDimensions}
                    defaultImgSrc={logoImgUrl}
                    isEditing={isEditing}
                    asProps={{
                      onClick: () =>
                        !!clientUrl && openUrl(getExternalUrl(clientUrl))
                    }}
                    hasUrl={!!clientUrl}
                  />
                </ControlsWithModalOpener>
              )}
            />
            <AddButton onAdd={Actions.addLogo} toShow={!logoImgUrl}>
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_logo_label')}
            </AddButton>
            <EditableContent
              as={S.Title}
              text={isSelling ? productName : headline}
              required
              className="WM_GLOBAL_heading42"
              alignment={titleAlignment}
              changeAlignment={Actions.changeTitleAlignment}
              isTypingDisabled
              isTextFormatingHidden
              customActions={editableContentCustomActions}
            />
            {isSelling ? (
              <S.PriceContainer>
                <EditableContent
                  as={S.PriceCount}
                  text={`${getCurrencySign(currency)} ${productPrice}`}
                  className="WM_GLOBAL_heading42"
                  isTypingDisabled
                  isTextFormatingHidden
                  customActions={editableContentCustomActions}
                />
                {productDiscountedPrice ? (
                  <EditableContent
                    as={S.PrevPriceCount}
                    text={`${getCurrencySign(
                      currency
                    )} ${productDiscountedPrice}`}
                    className="WM_GLOBAL_heading32"
                    isTypingDisabled
                    isTextFormatingHidden
                    customActions={editableContentCustomActions}
                  />
                ) : null}
              </S.PriceContainer>
            ) : null}
            <EditableContent
              isEditing={isEditing}
              text={paragraph}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              as={S.LeftContainerParagraph}
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <S.PayButtonsContainer>
              <PayPalButtonForm
                data={paypalBtnDefaultProps}
                compId={componentId}
              />
              <AdditionalLink
                additionalLinkText={additionalText}
                additionalLinkUrl={additionalUrl}
              />
            </S.PayButtonsContainer>
          </S.LeftContainer>
          <S.RightContainer>
            <S.ImageWrapper>
              <S.ControlContainer isEditing={isEditing}>
                <ControlsWithImageCropper
                  actions={Actions.getLargeImgActions(largeImgUrl)}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    sizes={largeImgDimensions}
                    defaultImgSrc={largeImgUrl}
                    asProps={{
                      imgCropParams: largeImgCropParams
                    }}
                    alt="avatar image"
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.ImageWrapper>
          </S.RightContainer>
        </S.ContentConainer>
        <S.PayPalItemWrap>
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => {
              return (
                <Show
                  when={[
                    block.iconName,
                    block.name,
                    block.title,
                    block.description
                  ]}
                  key={idx}
                >
                  <ControlsWithModalOpener
                    actions={Actions.blockActions({
                      idx,
                      showRemove: blocks.length > validation.blocks.min
                    })}
                  >
                    <S.ItemWrap>
                      <S.IconContainer hasBlocksIcon={hasBlocksIcon}>
                        <If
                          condition={!!block.iconName}
                          otherwise={() => (
                            <AddButton
                              onAdd={() => Actions.addIcon(idx)}
                              type="icon"
                              large
                              toShow
                            >
                              +
                            </AddButton>
                          )}
                          then={() => (
                            <article>
                              <ControlsWithModalOpener
                                actions={Actions.iconActions({ idx })}
                              >
                                <S.Circle>
                                  <S.Icon
                                    size="xxlarge"
                                    name={block.iconName}
                                  />
                                </S.Circle>
                              </ControlsWithModalOpener>
                            </article>
                          )}
                        />
                      </S.IconContainer>
                      <EditableContent
                        as={S.ItemName}
                        text={block.name}
                        required={!block.title && !block.description}
                        alignment={block.blockNameAlignment}
                        maxCount={validation.blockName}
                        onChange={(newName: string) =>
                          Actions.changeBlockName(newName, idx)
                        }
                        className="WM_GLOBAL_paragraph14"
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockNameAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        as={S.ItemTitle}
                        text={block.title}
                        required={!block.name && !block.description}
                        alignment={block.blockTitleAlignment}
                        maxCount={validation.blockName}
                        className="WM_GLOBAL_heading20"
                        onChange={(text: string) =>
                          Actions.changeBlockText(text, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        as={S.ItemDescription}
                        text={block.description}
                        required={!block.name && !block.title}
                        alignment={block.blockDescriptionAlignment}
                        maxCount={validation.titleMaxChar}
                        className="WM_GLOBAL_paragraph14"
                        onChange={(newDescription: string) =>
                          Actions.changeBlockDescription(newDescription, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockDescriptionAlignment(
                            alignment,
                            idx
                          )
                        }
                      />
                    </S.ItemWrap>
                  </ControlsWithModalOpener>
                </Show>
              )
            }}
          />
          <AddButton
            onAdd={Actions.addBlock}
            toShow={blocks.length < validation.blocks.max}
            style={{
              marginTop: '40px'
            }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.PayPalItemWrap>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default PayPal1
