//@flow
import React, { memo, useCallback } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'

import * as Styled from './styled'
import { Flag } from '../../styled'
import { scrollToTop } from './utils'
import type { TLanguagesListItemProps } from '../../types'
import HiddenIcon from '@editor/common/assets/svgr-icons/Hidden_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { translate } from '@editor/common/utils/translations'
import { getFlagUrl } from '@website/common/utils/website-languages'
import { useHistoryWithSearch } from '@hooks'
import { dropHistory } from '@actions'

const LanguagesListItem = ({
  language,
  closeLanguagesDropdown,
  dropHistory
}: TLanguagesListItemProps) => {
  const { lang: websiteActiveLanguageCode, siteId } = useParams()
  const history = useHistoryWithSearch()

  const {
    order,
    isPrimary,
    status,
    lang: { flagCode, name, code }
  } = language
  const isActive = websiteActiveLanguageCode === code

  const onListItemClick = useCallback(() => {
    if (isActive) return

    history.push(`/${siteId}/${code}/edit/home`)
    closeLanguagesDropdown()
    dropHistory()
    scrollToTop()
  }, [siteId, code, isActive])

  return (
    <Styled.LanguagesListItem
      onClick={onListItemClick}
      isActive={isActive}
      order={order}
    >
      <Styled.LeftBlock>
        <Flag src={getFlagUrl(flagCode)} alt={name} />
        <Styled.LanguageInfoBlock>
          <p className="name">{name}</p>
          {isPrimary ? (
            <p className="description">{translate('primary_language_label')}</p>
          ) : null}
        </Styled.LanguageInfoBlock>
      </Styled.LeftBlock>
      {status === 'hidden' ? (
        <Styled.RightBlock>
          <TooltipWrapper text={translate('hidden_language_tooltip_text')}>
            <HiddenIcon />
          </TooltipWrapper>
        </Styled.RightBlock>
      ) : null}
    </Styled.LanguagesListItem>
  )
}

const mapDispatchToProps = {
  dropHistory
}

export default connect(null, mapDispatchToProps)(memo(LanguagesListItem))
