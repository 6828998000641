import React from 'react'
import { withTheme } from 'styled-components'

const RightTriangle = ({ theme }) => {
  const { palette } = theme
  const color = palette.primaryDark
  return (
    <svg
      className="right-triangle"
      width="725px"
      height="238px"
      viewBox="0 0 725 238"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        opacity="0.103171"
      >
        <polygon
          id="Rectangle-Copy-76"
          fill={color}
          transform="translate(362.500000, 119.000000) scale(-1, 1) translate(-362.500000, -119.000000) "
          points="0 0 725 238 0 238"
        />
      </g>
    </svg>
  )
}

export default withTheme(RightTriangle)
