import styled, { css } from 'styled-components'

export const DatePickerContainer = styled.div`
  background-color: white;
  position: absolute;
  z-index: 50;
  box-shadow: 0px 9px 22px 0px rgba(0, 0, 0, 0.12);
  ${({ $coords: { top = 0, left = 0 } = {} }) =>
    css`
      top: ${top}px;
      left: ${left}px;
    `}
`
