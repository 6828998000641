import {
  getSiteId,
  selectSemrushKeywordTableFilterQueries,
  selectSemrushKeywordTableRowsLength
} from '@selectors'
import {
  generateSemrushTokensAPI,
  getSemrushLoggedInStatusAPI,
  fetchKeywordsAPI,
  logOutSemrushUserAPI
} from '@api'

import { pinOrUnpinSemrushSidebar, setSemrushLoginWindowState } from '../editor'

import { COLUMNS_BY_TAB } from './consts'

const setSemrusLoggedIn = value => ({
  type: 'SET_SEMRUSH_LOGGED_IN_STATUS',
  value
})

const setSemrushLoggedInLoading = value => ({
  type: 'SET_SEMRUSH_LOGGED_IN_LOADING',
  value
})

const SEMRUSH_API_NO_RESULTS_RESP = 'ERROR 50 :: NOTHING FOUND'
const SEMRUSH_KEYWORDS_SEARCH_LIMIT_EXCEEDED =
  'ERROR 134 :: TOTAL LIMIT EXCEEDED'

export const fetchSemrushLoggedInStatus = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())

  dispatch(setSemrushLoggedInLoading(true))
  getSemrushLoggedInStatusAPI(siteId)
    .then(() => {
      dispatch(setSemrushLoggedInLoading(false))
      dispatch(setSemrusLoggedIn(true))
    })
    .catch(() => {
      dispatch(setSemrushLoggedInLoading(false))
      dispatch(setSemrusLoggedIn(false))
    })
}

export const logOutSemrushUser = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())

  dispatch(setSemrushLoggedInLoading(true))
  logOutSemrushUserAPI(siteId)
    .then(() => {
      dispatch(pinOrUnpinSemrushSidebar(false))
      dispatch({ type: 'RESET_SEMRUSH_STORE' })
    })
    .catch(() => {
      dispatch(setSemrushLoggedInLoading(false))
    })
}

export const generateSemrushTokens = code => (dispatch, getState) => {
  const siteId = getSiteId(getState())

  dispatch(setSemrushLoggedInLoading(true))

  generateSemrushTokensAPI(code, siteId)
    .then(() => {
      dispatch(setSemrushLoggedInLoading(false))
      dispatch(setSemrusLoggedIn(true))
    })
    .catch(() => {
      dispatch(setSemrushLoggedInLoading(false))
    })
}

const handleFetchingError = (error, siteId, dispatch) => {
  if (
    error.status === 401 ||
    (error.status === 400 && error.message?.includes('token is invalid'))
  ) {
    logOutSemrushUserAPI(siteId).then(() => {
      dispatch(setSemrushLoginWindowState(true))
    })
  }
}

export const fetchSemrushTablePagination = () => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const filters = selectSemrushKeywordTableFilterQueries(state)
  const dataLength = selectSemrushKeywordTableRowsLength(state)

  dispatch({
    type: 'SET_KEYWORDS_DATA_PAGINATION_LOADING_STATE',
    value: true
  })

  fetchKeywordsAPI(siteId, { ...filters, dataLength })
    .then(({ data }) => {
      const { rows, error } = data

      if (error && error === SEMRUSH_KEYWORDS_SEARCH_LIMIT_EXCEEDED) {
        dispatch({
          type: 'SET_SEMRUSH_KEYWORDS_SEARCH_LIMIT_EXCEEDED_STATE',
          value: true
        })
        return
      }

      if (!rows.length) {
        dispatch({
          type: 'SET_PAGINATED_KEYWORDS_OVERED_DATA_STATE',
          value: true
        })
      } else {
        dispatch({
          type: 'SET_PAGINATED_KEYWORDS_DATA',
          value: data
        })
      }
    })
    .catch(err => {
      handleFetchingError(err, siteId, dispatch)
    })
    .finally(() => {
      dispatch({
        type: 'SET_KEYWORDS_DATA_PAGINATION_LOADING_STATE',
        value: false
      })
    })
}

export const fetchSemrushKeywords = () => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const filters = selectSemrushKeywordTableFilterQueries(state)

  if (!filters.phrase) {
    return
  }

  dispatch({
    type: 'SET_KEYWORDS_DATA_LOADING_STATE',
    value: true
  })

  fetchKeywordsAPI(siteId, { ...filters })
    .then(({ data }) => {
      const { rows, columnNames, error } = data

      if (error && error === SEMRUSH_KEYWORDS_SEARCH_LIMIT_EXCEEDED) {
        dispatch({
          type: 'SET_SEMRUSH_KEYWORDS_SEARCH_LIMIT_EXCEEDED_STATE',
          value: true
        })
        return
      }
      const _columnNames =
        columnNames[0] === SEMRUSH_API_NO_RESULTS_RESP
          ? COLUMNS_BY_TAB[filters.activeTab]
          : columnNames

      dispatch({
        type: 'SET_KEYWORDS_DATA',
        value: { rows, columnNames: _columnNames }
      })

      dispatch({
        type: 'SET_PAGINATED_KEYWORDS_OVERED_DATA_STATE',
        value: false
      })
    })
    .catch(err => {
      handleFetchingError(err, siteId, dispatch)
    })
    .finally(() => {
      dispatch({
        type: 'SET_KEYWORDS_DATA_LOADING_STATE',
        value: false
      })
    })
}

export const setSemrushKeywordTableDatabase = database => dispatch => {
  dispatch({
    type: 'SET_SEMRUSH_KEYWORD_TABLE_FILTER_QUERIES',
    value: { database }
  })

  dispatch(fetchSemrushKeywords())
}

export const setSemrushKeywordTablePhrase = phrase => dispatch => {
  dispatch({
    type: 'SET_SEMRUSH_KEYWORD_TABLE_FILTER_QUERIES',
    value: { phrase }
  })

  dispatch(fetchSemrushKeywords())
}

export const setSemrushKeywordTableSortingInfo = sortingInfo => dispatch => {
  dispatch({
    type: 'SET_SEMRUSH_KEYWORD_TABLE_FILTER_QUERIES',
    value: { sortingInfo }
  })

  dispatch(fetchSemrushKeywords())
}

export const setSemrushKeywordTableActiveTab = activeTab => dispatch => {
  dispatch({
    type: 'SET_SEMRUSH_KEYWORD_TABLE_FILTER_QUERIES',
    value: { activeTab }
  })

  dispatch(fetchSemrushKeywords())
}
