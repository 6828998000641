import styled from 'styled-components'

import { secondary } from '@website/common/styles/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const cardCustomStyle = {
  titleFontSize: '16px',
  descriptionFontSize: '14px'
}

const stylesObj = {
  en: cardCustomStyle,
  tr: cardCustomStyle,
  ru: {
    titleFontSize: '14px',
    descriptionFontSize: '12px',
    blockTopMargin: '7px',
    width: '232px'
  },
  default: {
    titleFontSize: '14px',
    descriptionFontSize: '12px',
    width: '220px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const TabContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;

  .gdpr-tab-img {
    @media only screen and (min-width: 768px) {
      width: 100px;
    }
  }
`

export const TextBlock = styled.div`
  padding: 15px 10px 0 5px;
  padding-top: ${getStylesByLang('blockTopMargin')};
  width: ${getStylesByLang('width')};
  box-sizing: border-box;
`

export const Title = styled.p`
  font-size: ${getStylesByLang('titleFontSize')};
  font-weight: 600;
  color: ${secondary.Dark};
`

export const Description = styled.p`
  font-size: ${getStylesByLang('descriptionFontSize')};
  color: ${secondary.SemiDark};
  padding-top: 5px;
`

export const UpgradeLabel = styled.img`
  position: absolute;
  z-index: 1;
  top: -7px;
  right: 15px;
`
