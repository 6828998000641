import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    teamName: p => p.tertiaryDark,
    teamPosition: p => p.tertiaryNormal,
    teamParagraph: p => p.tertiaryNormal,
    raisedShadow: () => 'none'
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    teamName: p => p.tertiaryDark,
    teamPosition: p => p.tertiaryNormal,
    teamParagraph: p => p.tertiaryNormal
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    teamName: p => p.tertiaryDark,
    teamPosition: p => p.tertiaryNormal,
    teamParagraph: p => p.tertiaryNormal
  }
}
