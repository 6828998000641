/* @flow */
import React, { useContext, memo } from 'react'
import S from './Post2.styled'
import { validation } from './Post2.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'

const Post2 = memo(props => {
  const {
    data: { title, paragraph, posts, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.maxTitleChar}
          className="WM_GLOBAL_heading"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Paragraph}
          maxCount={validation.maxParagraphChar}
          className="WM_GLOBAL_paragraph"
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.PostsWrap className="posts-wrap">
          <Mapper
            data={posts}
            render={(post: string, idx: number) => {
              const {
                title,
                imageUrl,
                imgDimensions,
                buttonUrl,
                paragraph,
                titleAlignment,
                paragraphAlignment,
                buttonText,
                imgCropParams
              } = post
              return (
                <React.Fragment key={idx}>
                  <S.PostItemWrap className="post-item-wrap">
                    <S.FigureContainer className="figure-container">
                      <S.PostItemImgWrap className="post-item-img-wrap">
                        <ControlsWithImageCropper
                          style={{ height: '100%', width: '100%' }}
                          actions={Actions.imageActions(idx, imageUrl)}
                        >
                          <Image
                            as={S.Img}
                            alt="Post image"
                            sizes={imgDimensions}
                            defaultImgSrc={imageUrl}
                            asProps={{ imgCropParams }}
                          />
                        </ControlsWithImageCropper>
                      </S.PostItemImgWrap>
                    </S.FigureContainer>
                    <S.PostItemContent className="post-item-content">
                      <EditableContent
                        text={title}
                        as={S.PostTitle}
                        alignment={titleAlignment}
                        maxCount={validation.maxPostTitleChar}
                        onChange={(title: string) => {
                          Actions.changeBlockTitle(title, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        text={paragraph}
                        as={S.PostParagraph}
                        alignment={paragraphAlignment}
                        maxCount={validation.maxPostParagraphChar}
                        className="WM_GLOBAL_secondary-font"
                        onChange={(paragraph: string) => {
                          Actions.changeBlockParagraph(paragraph, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }
                      />
                      <AdditionalLink
                        idx={idx}
                        additionalLinkText={buttonText}
                        additionalLinkUrl={buttonUrl}
                      />
                    </S.PostItemContent>
                  </S.PostItemWrap>
                </React.Fragment>
              )
            }}
          />
        </S.PostsWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Post2
