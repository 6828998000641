export const validation = {
  maxTitleChar: 100,
  maxBlockTitleChar: 100,
  maxSubBlockTitleChar: 100,
  maxSubBlockParagraphChar: 2000,
  maxListItemParagraphChar: 500,
  blocks: {
    min: 2,
    max: 12
  },
  textItem: {
    min: 1,
    max: 10
  },
  listItem: {
    min: 0,
    max: 10
  },
  subBlock: {
    min: 1,
    max: 10
  }
}

export const TEXT = 'text'

export const LIST = 'list'

export const TEXT_BLOCK_INITIAL_STATE = {
  title: '',
  paragraph:
    'Thank you for choosing Company LLC (“Company,” “we,” “us,” or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice with regard to your personal information, please contact us at privacy@company.com.',
  subBlockTitleAlignment: 'left',
  subBlockParagraphAlignment: 'left',
  list: []
}

export const LIST_ITEM_INITIAL_STATE =
  'Visit our website at http://www.company.com'
