/* @flow */
import produce from 'immer'
import { generateRandomNumber } from '@website/common/utils'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        return
      case 'CHANGE_IMAGE':
        const { idx, url, dimensions } = action.value
        draft.slides[idx].imgUrl = url
        draft.slides[idx].imgDimensions = dimensions
        draft.slides[idx].imgCropParams = {}
        return
      case 'REMOVE_IMAGE':
        draft.slides.splice(action.value.idx, 1)
        return
      case 'ADD_SLIDE':
        const randomIdx = generateRandomNumber(initialState.slides.length - 1)
        draft.slides.push(initialState.slides[randomIdx])
        return
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        return
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        return
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
        return
      }
      case 'ADD_BUTTON':
        draft.buttons.unshift({ ...initialState.buttons[0], type: 'primary' })
        return
    }
  })
}
