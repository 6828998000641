/* @flow */

import {
  cropableImageModalActionMaker,
  removeBlockActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'

export default dispatcher => {
  const addBlock = () =>
    dispatcher({
      type: 'ADD_BLOCK'
    })

  const removeBlock = (idx: string) =>
    dispatcher({
      type: 'REMOVE_BLOCK',
      value: idx
    })

  const changeTitle = (newTitle: string, idx: number, itemIdx: number) =>
    dispatcher({
      type: 'CHANGE_TITLE',
      value: { newTitle, idx, itemIdx }
    })

  const changeParagraph = (
    newParagraph: string,
    idx: number,
    itemIdx: number
  ) =>
    dispatcher({
      type: 'CHANGE_PARAGRAPH',
      value: { newParagraph, idx, itemIdx }
    })

  const changeLinkData = value => ({
    type: 'CHANGE_LINK_DATA',
    value
  })

  const removeLink = value => () => ({
    type: 'REMOVE_LINK',
    value
  })

  const changeBlockTitleAlignment = (
    alignment: string,
    idx: numberitem,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx, itemIdx }
    })
  }

  const changeBlockParagraphAlignment = (
    alignment: string,
    idx: number,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx, itemIdx }
    })
  }

  const linkActions = (idx: number, itemIdx: number, linkData) => [
    ...linkActionMaker(dispatcher)({
      isAdditionalLink: true,
      idx,
      itemIdx,
      linkData,
      actions: {
        change: (_, newLinkData) =>
          changeLinkData({ idx, itemIdx, newLinkData }),
        remove: removeLink({ idx, itemIdx })
      }
    })
  ]

  const addLink = (idx: number, itemIdx: number) =>
    dispatcher({
      type: 'ADD_LINK',
      value: { idx, itemIdx }
    })

  const imageActions = (idx: number, itemIdx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      showRemove: false,
      additionalArgs: { idx, itemIdx },
      keyForSizes: 'feature20',
      imageUrl
    })

  return {
    changeLinkData,
    linkActions,
    addLink,
    addBlock,
    imageActions,
    changeTitle,
    changeParagraph,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    removeBlock,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
