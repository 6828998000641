export const validation = {
  titleMaxChar: 100,
  paragraphMaxChar: 300,
}

export const BUTTON_INITIAL_STATE = [
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]
