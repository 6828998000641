/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE': {
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      }
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY': {
        draft.bgOverlayTransparency = action.value
        break
      }

      case 'CHANGE_ICON': {
        const { iconName } = action.value
        draft.icon = iconName
        break
      }
      case 'REMOVE_ICON': {
        draft.icon = ''
        break
      }
      case 'ADD_ICON': {
        draft.icon = 'glyph-stars-complexity-3'
        break
      }
    }
  })
}
