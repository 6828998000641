/* @flow */
import {
  imageModalActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'

export default dispatcher => {
  const changeItemText = (text: string, idx: number, itemIdx: number) => ({
    type: 'CHANGE_ITEM_TEXT',
    value: { text, idx, itemIdx }
  })
  const changeItemCount = (newCount: string, idx: number, itemIdx: number) => ({
    type: 'CHANGE_ITEM_COUNT',
    value: { newCount, idx, itemIdx }
  })
  const changeImgUrl = (value: Object, undoable: boolean) => ({
    type: 'CHANGE_IMG',
    value,
    undoable
  })
  const imageActions = (idx: number, itemIdx: number, imageUrl: string) => [
    ...imageModalActionMaker(dispatcher)({
      showRemove: false,
      keyForSizes: 'feature14',
      actions: {
        change: ({ url, dimenions, undoable }) =>
          changeImgUrl({ url, dimenions, idx, itemIdx }, undoable)
      },
      imageUrl
    })
  ]
  const removeLink = value => () => ({
    type: 'REMOVE_LINK',
    value
  })
  const changeLinkData = value => ({
    type: 'CHANGE_LINK_DATA',
    value
  })
  const linkActions = (idx: number, linkData, itemIdx: number) => [
    ...linkActionMaker(dispatcher)({
      idx,
      linkData,
      actions: {
        change: (_, linkData) => changeLinkData({ idx, itemIdx, linkData }),
        remove: removeLink({ idx, itemIdx })
      }
    })
  ]
  const addLink = (idx: number, itemIdx: number) => ({
    type: 'ADD_LINK',
    value: { idx, itemIdx }
  })
  return {
    addLink,
    imageActions,
    linkActions,
    changeItemText,
    changeItemCount
  }
}
