/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature29.styled'
import { validation } from './Feature29.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import IosBrowserBar from '@website/common/assets/iosBrowser.svg'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import Buttons from '@website/common/components/Buttons'

const Feature29 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      buttons,
      imageUrl,
      imgCropParams,
      imgDimensions,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WMCustomContainer>
        <Show when={[title, paragraph, buttons.length]}>
          <S.HeaderContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading42"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Description}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <Buttons data={buttons} />
          </S.HeaderContent>
        </Show>
        <S.WebpageWrap>
          <S.BrowserBarWrapper>
            <S.BrowserBarImage src={IosBrowserBar} alt="browser bar" />
          </S.BrowserBarWrapper>
          <S.ImageFigure>
            <ControlsWithImageCropper
              actions={Actions.imgActions(imageUrl)}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                alt="Section img"
                defaultImgSrc={imageUrl}
                asProps={{ imgCropParams }}
                sizes={imgDimensions}
              />
            </ControlsWithImageCropper>
          </S.ImageFigure>
        </S.WebpageWrap>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature29
