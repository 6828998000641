/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header17.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import { Input, Button } from '../SubscribeForm/styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles};
  padding: 0;
  min-height: ${props => (props.isEditing ? 'calc(100vh - 90px)' : '100vh')};
  & ${S.NavbarWrapper} {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: ${props => (props.isEditing ? '2' : '9')};
  }
  & ${S.Container} {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1024px) {
    height: auto;
  }
  @media only screen and (max-width: 650px) {
    padding: 0;
  }
  & ${Button} {
    width: 100%;
    margin: 0;
    @media only screen and (max-width: 650px) {
      width: auto;
      max-width: initial;
    }
  }
  .datePicker {
    width: 290px;
    @media only screen and (max-width: 768px) {
      top: 30%;
      ${({ theme }) =>
        theme.orientation === 'LEFT' &&
        css`
          top: initial;
          bottom: -10%;
        `}
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  background-color: ${getStyleForProp('background')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: initial;
  padding: 0;
  @media only screen and (max-width: 1024px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }

  .socials-block {
    position: absolute;
    bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: calc(100% - 80px);
    max-width: 500px;
    @media only screen and (max-width: 1024px) {
      position: static;
      bottom: initial;
      margin: 50px 0 0 0;
      max-width: 100%;
      width: 100%;
      @media only screen and (max-width: 768px) {
        margin-top: 50px;
      }
    }
  }
  .socials-wrapper {
    display: inline-flex;
    .link {
      width: 30px;
      height: 30px;
      fill: ${getStyleForProp('social')};
    }
  }
  .add-button-wrap {
    margin-left: 10px;
  }
  .DayPicker-Day {
    &:focus {
      outline: none !important;
    }
  }
  .DayPicker-Day--selected {
    color: white !important;
    background-color: rgb(56, 125, 255) !important;
    font-weight: 700;
  }
  .DayPicker-Day--today {
    background-color: rgba(56, 125, 255, 0.15) !important;
    font-weight: 500 !important;
    color: initial !important;
  }
  .clockerContainer {
    display: flex;
    justify-content: space-around;
  }
  .DayPicker {
    width: 100%;
  }
  .DayPicker-Day {
    min-width: 36px;
    box-sizing: border-box;
  }
`

const Content = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  ${({ isEditing }) =>
    isEditing ? 'padding: 250px 40px 180px' : 'padding: 180px 40px'};
  @media only screen and (max-width: 1024px) {
    width: 100%;
    ${({ theme, isEditing }) =>
      theme.orientation === 'LEFT' &&
      isEditing &&
      css`
        @media only screen and (max-width: 1024px) {
          padding-top: 260px;
          padding-bottom: 80px;
        }
      `}
    ${({ theme, topOffset, isEditing }) =>
      theme.orientation === 'LEFT' &&
      !isEditing &&
      css`
        @media only screen and (max-width: 1024px) {
          padding-top: ${topOffset + 80 + 'px'};
          padding-bottom: 80px;
        }
        @media only screen and (max-width: 650px) {
          padding-top: ${topOffset + 60 + 'px'};
        }
        @media only screen and (max-width: 457px) {
          padding-bottom: 60px;
          padding-right: 20px;
          padding-left: 20px;
        }
      `}
  ${({ theme, isEditing }) =>
      theme.orientation === 'RIGHT' &&
      !isEditing &&
      css`
        @media only screen and (max-width: 1024px) {
          padding: 80px 40px;
        }
        @media only screen and (max-width: 457px) {
          padding: 60px 20px;
        }
      `}
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  & > .control-container {
    margin-bottom: 30px;
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
    }

    & .wm-email-btn {
      width: 50%;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    margin-top: ${props => (props.isEditing ? '12px' : '0')};
  }
`

const SocialWrap = styled.div`
  position: absolute;
  bottom: 30px;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  @media only screen and (max-width: 1024px) {
    position: static;
    bottom: initial;
    margin: 50px 0 0 0;
  }
  @media only screen and (max-width: 768px) {
    margin-top: 50px;
  }
`

const SocialIcons = styled.div`
  display: inline-flex;
  .link {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
    color: ${getStyleForProp('social')};
  }
`

const DateContainer = styled.div`
  ${getBackgroundStyles(THEME)};
  width: 60%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  min-height: 100vh;

  ${({ isEditing }) =>
    isEditing ? 'padding: 250px 30px 180px' : 'padding: 180px 30px'};
  @media only screen and (max-width: 1024px) {
    width: 100%;
    min-height: initial;
    padding-right: 40px;
    padding-left: 40px;
    ${({ theme, isEditing }) =>
      theme.orientation === 'LEFT' &&
      isEditing &&
      css`
        padding-top: 16vw;
        padding-bottom: 16vw;
      `}
    ${({ theme, isEditing }) =>
      theme.orientation === 'RIGHT' &&
      isEditing &&
      css`
        padding-top: 260px;
        padding-bottom: 16vw;
      `}
    ${({ theme, topOffset, isEditing }) =>
      theme.orientation === 'RIGHT' &&
      !isEditing &&
      css`
        padding-top: ${topOffset + 80 + 'px'};
        padding-bottom: 80px;
      `}
    ${({ theme, isEditing }) =>
      theme.orientation === 'LEFT' &&
      !isEditing &&
      css`
        padding-top: 80px;
        padding-bottom: 80px;
      `}
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
    ${({ theme, topOffset, isEditing }) =>
      theme.orientation === 'RIGHT' &&
      !isEditing &&
      css`
        padding-top: ${topOffset + 60 + 'px'};
        padding-bottom: 60px;
      `}
    ${({ theme, isEditing }) =>
      theme.orientation === 'LEFT' &&
      !isEditing &&
      css`
        padding: 60px;
      `}
  }
  & > .control-container {
    max-width: 600px;
    width: 100%;
    h2 {
      margin-bottom: 0;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 460px;
    }
  }
`

const DateTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    max-width: 460px;
    padding-left: 0px;
    padding-right: 0px;
  }
`

const DateWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  max-width: 110px;

  & > .control-container {
    margin-top: 20px;
    & > p {
      margin: 0;
    }
  }
`

const Time = styled.p`
  position: relative;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  border-radius: 10px;
  background: #fff;
  min-width: 110px;
  height: 90px;
  line-height: 90px;
  text-align: center;
  margin-bottom: 0 !important;
  font-size: 54px;
  font-weight: bold;
  span {
    position: relative;
    z-index: 1;
  }
  @media only screen and (max-width: 1280px) {
    font-size: 45px;
    min-width: 80px;
    height: 70px;
    line-height: 70px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 32px;
    min-width: 65px;
    height: 60px;
    line-height: 60px;
  }
`

const Date = styled.p`
  color: ${getStyleForProp('heading')};
  font-weight: 400 !important;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  margin: 20px 0 0 0;
  @media only screen and (max-width: 1280px) {
    margin: 10px 0 0 0;
  }
`

const SubscribeFormWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  margin-right: -42px;
  @media only screen and (max-width: 1024px) {
    margin-right: 0;
  }
  @media only screen and (max-width: 650px) {
    transform: unset;
  }
  form {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 650px) {
      flex-direction: column;
      align-items: center;
    }
    .control-container {
      width: 50%;
      @media only screen and (max-width: 650px) {
        width: auto;
      }
    }
    & > button {
      width: 50%;
    }
  }
  .wm-email-input-wrapper {
    min-width: 340px;
    max-width: initial;
    transform: translateX(0);
    margin-right: -42px;
    @media only screen and (max-width: 1200px) and (min-width: 1024px) {
      min-width: 300px;
    }
    @media only screen and (max-width: 650px) {
      margin: 0 0 30px 0;
      min-width: 100%;
    }
  }

  .wm-email-input {
    padding-right: 50px;
  }

  .success-message {
    transform: translateX(-45%);
    @media only screen and (max-width: 650px) {
      transform: none;
    }
  }
`

const FormWrapper = styled.div`
  margin-top: 30px;
  .control-container {
    width: 100%;
    @media only screen and (max-width: 1024px) {
      width: max-content;
    }
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 1024px) {
    display: flex;
    justify-content: center;
  }
`

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  max-width: 600px;
  margin-top: 30px;
  @media only screen and (max-width: 1024px) {
    max-width: 480px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 320px;
  }
`

const TimeDots = styled.span`
  color: ${getStyleForProp('heading')};
  font-size: 40px;
  font-weight: bold;
  height: 90px;
  line-height: 84px;
  @media only screen and (max-width: 1280px) {
    line-height: 68px;
    font-size: 30px;
  }
  @media only screen and (max-width: 650px) {
    line-height: 58px;
    font-size: 20px;
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('social')
}))``

export default {
  WmCustomContainer,
  DateContainer,
  DateWrapper,
  SubscribeFormWrap,
  Content,
  ContentContainer,
  StyledContainer,
  DateWrap,
  Time,
  Date,
  Paragraph,
  DateTitle,
  Title,
  FormWrapper,
  SocialWrap,
  SocialIcons,
  TimeDots,
  Icon
}
