/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature8.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  @media only screen and (min-width: 769px) {
    padding-top: ${({ isEditing }) => isEditing && '140px'};
  }
  .tab-layer-0 {
    transform: translateX(0);
  }
  .tab-layer-1 {
    transform: translateX(200px);
  }
  .tab-layer-2 {
    transform: translateX(400px);
  }
  .tab-layer {
    position: absolute;
    bottom: 4px;
    left: 4px;
    top: 4px;
    width: 200px;
    transform-origin: 0 0;
    transition: transform 0.25s;
    z-index: 0;
    box-shadow: ${getStyleForProp('raisedShadow')};
    border: ${getStyleForProp('border')};
    border-radius: ${({ theme }) =>
      theme.look === 'ROUNDED'
        ? '300px'
        : theme.look === 'CORNERED'
        ? '10px'
        : '0'};
    ${({ theme }) =>
      theme.look !== 'SHARP'
        ? `
          background-image: linear-gradient(
            -180deg
            , ${theme.palette.primaryLight} 0%, ${theme.palette.primaryDark} 96%);
        `
        : theme.look === 'SHARP' && theme.colorMode === 'DARK'
        ? `background-color: ${theme.palette.primaryLight};`
        : `background-color: ${theme.palette.primaryDark};`};
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 0 50px 0;
  @media only screen and (max-width: 1024px) {
    margin: 0 0 40px 0;
  }
  > :first-child {
    margin-top: 0;
  }
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`
const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`
const Title = styled.h1`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
`

const ItemTitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.palette.tertiaryDark};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-top: 20px;
`

const FeturesContent = styled.div``

const BlocksWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: ${({ isEditing }) => (isEditing ? 'stretch' : 'center')};
  flex-wrap: wrap;
  margin: 0 auto 30px auto;
  padding: 4px;
  width: fit-content;
  background-color: ${getStyleForProp('tab')};
  border-radius: ${getStyleForProp('borderRadius')};
  border-radius: ${({ theme }) =>
    theme.look === 'ROUNDED'
      ? '300px'
      : theme.look === 'CORNERED'
      ? '10px'
      : '0'};
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    border-radius: ${({ theme }) =>
      theme.look === 'ROUNDED'
        ? '30px'
        : theme.look === 'CORNERED'
        ? '10px'
        : '0'};
  }
  .control-container {
    .styled-controls {
      z-index: 3;
    }
  }
`

const Block = styled.div`
  z-index: 3;
  padding: 13px 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 0.25s linear;
  font-size: 14px;
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  @media only screen and (max-width: 767px) {
    border-radius: ${({ theme }) =>
      theme.look === 'ROUNDED'
        ? '300px'
        : theme.look === 'CORNERED'
        ? '10px'
        : '0'};
    ${({ isActiveFeatureIdx, ...rest }) =>
      isActiveFeatureIdx
        ? `
      box-shadow: ${getStyleForProp('raisedShadow')(rest)};
      border: ${getStyleForProp('border')(rest)};
    `
        : ``};
    ${({ isActiveFeatureIdx, theme }) =>
      isActiveFeatureIdx && theme.look !== 'SHARP'
        ? `
      background-image: linear-gradient(
        -180deg
        , ${theme.palette.primaryLight} 0%, ${theme.palette.primaryDark} 96%);
    `
        : isActiveFeatureIdx && theme.look === 'SHARP'
        ? `
        background-color: ${theme.palette.primaryDark};
        `
        : isActiveFeatureIdx &&
          theme.look === 'SHARP' &&
          theme.colorMode === 'DARK'
        ? `background-color: ${theme.palette.primaryLight};`
        : ``};

    &:focus {
      ${({ isActiveFeatureIdx, ...rest }) =>
        isActiveFeatureIdx
          ? `
      box-shadow: ${getStyleForProp('raisedShadow')(rest)};
      border: ${getStyleForProp('border')(rest)};
    `
          : ``};
      ${({ isActiveFeatureIdx, theme }) =>
        isActiveFeatureIdx && theme.look !== 'SHARP'
          ? `
          background-image: linear-gradient(
            -180deg
            , ${theme.palette.primaryLight} 0%, ${theme.palette.primaryDark} 96%);
        `
          : isActiveFeatureIdx && theme.look === 'SHARP'
          ? `
            background-color: ${theme.palette.primaryDark};
            `
          : isActiveFeatureIdx &&
            theme.look === 'SHARP' &&
            theme.colorMode === 'DARK'
          ? `background-color: ${theme.palette.primaryLight};`
          : ``};
    }
  }
  > div {
    text-align: center;
  }
`

const BlocksContainer = styled.div`
  display: flex;
  margin: auto;
  width: fit-content;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const BlockTitle = styled.p`
  color: ${({ isActiveFeatureIdx, ...rest }) =>
    isActiveFeatureIdx
      ? rest.theme.palette.accentWhite
      : getStyleForProp('paragraph')(rest)};
  ${({ isActiveFeatureIdx, ...rest }) =>
    isActiveFeatureIdx
      ? css`
          color: ${rest.theme.palette.accentWhite};
          &:before {
            color: ${rest.theme.colorMode !== 'DARK'
              ? '#363f5a !important'
              : 'inherit'};
          }
        `
      : css`
          color: ${getStyleForProp('paragraph')(rest)};
        `};

  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &:after,
  &:before {
    top: initial !important;
    bottom: -30px;
  }
`

const ImagesWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
`

const Figure = styled.div`
  box-sizing: border-box;
  font-size: 0;
  display: flex;
  width: 100%;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  .control-container {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 56.25%;
    height: initial;
    border-radius: ${getStyleForProp('borderRadius')};
  }
`

const FeatureImage = styled.img`
  border: ${getStyleForProp('border')};
  object-fit: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
`

const Dot = styled.button`
  border: none;
  padding: 0;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
`

export default {
  Dot,
  StyledContainer,
  DotsContainer,
  HeaderContent,
  Title,
  ItemTitle,
  Paragraph,
  FeturesContent,
  BlocksWrap,
  Block,
  BlockTitle,
  ImagesWrap,
  FeatureImage,
  Figure,
  ControlContainer,
  BlocksContainer
}
