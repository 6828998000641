// @flow
import React, { memo } from 'react'
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'

import Edit from './edit'
import PreviewProject from './preview-project'
import StartCreation from './start-creation'
import MediaLib from '@editor/common/components/Media-lib/MediaLib'
import RFLoader from '@editor/common/components/Loaders/RF-loader'
import { selectIsLogoApplying } from '@selectors'
import '@src/shared/index.sass'
import { white } from '@website/common/styles/colors'

const Containers = () => {
  const { path } = useRouteMatch()
  const isLogoApplying = useSelector(selectIsLogoApplying)

  return (
    <div className="rf-website-maker">
      <RFLoader isVisible={isLogoApplying} background={white} />
      <Switch>
        <Route path={`${path}/edit`} component={Edit} />
        <Route path={`${path}/start-creation`} component={StartCreation} />
        <Route path={`${path}/preview-project`} component={PreviewProject} />
        <Redirect to={`${path}/edit`} />
      </Switch>
      <MediaLib />
    </div>
  )
}

export default memo(Containers)
