import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    arrow: p => p.primaryLight,
    postTitle: p => p.tertiaryDark,
    stepDescription: p => p.tertiaryNormal
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    arrow: p => p.primaryDark,
    postTitle: p => p.tertiaryDark,
    postParagraph: p => p.tertiaryNormal
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    arrow: p => p.primaryDark,
    postTitle: p => p.tertiaryDark,
    postParagraph: p => p.tertiaryNormal
  }
}
