//@flow

import React, { memo } from 'react'

import * as Styled from './styled'
import type { TProps } from './types'
import { FlyOut } from '@editor/common/lib/FlyOutMenu'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import TooltipContent from '../../../../TooltipContent'

const SERPFeaturesFlyout = ({
  options,
  targetRef,
  isOpen,
  onClose
}: TProps) => (
  <>
    <Styled.GlobalStyles />
    <FlyOut
      className="serp-features-flyout"
      animationPrefix="serp-features-flyout"
      alignment="right"
      isOpen={isOpen}
      anchorEl={targetRef}
      shift={{ x: 0, y: 20 }}
      onClose={onClose}
      isPositionFixed={false}
      withAnimation={false}
      backdropDisablingSelectors={['.hidden-serp-features']}
      renderingContainer={targetRef}
    >
      {options.map((serpFeature, idx) => {
        const { IconComp, tooltip, name } = serpFeature

        return (
          <TooltipWrapper
            key={idx}
            text={<TooltipContent title={name} description={tooltip} />}
            className="serp-option-tooltip"
          >
            <Styled.FlyOutOption>
              <IconComp key={idx} />
              {name}
            </Styled.FlyOutOption>
          </TooltipWrapper>
        )
      })}
    </FlyOut>
  </>
)

export default memo(SERPFeaturesFlyout)
