/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature26.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { secondary } from '@website/common/styles/colors'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  position: relative;
  padding-top: ${({ isEditing }) => (isEditing ? '140px' : null)};
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${({ isEditing }) => (isEditing ? 'margin-top: 60px;' : null)}
  > :first-child {
    padding-top: 0;
    margin-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
`
const ControlContainer = styled.div`
  display: flex;
  min-height: 155px;
  margin: ${({ isEditing }) => (isEditing ? '30px 20px' : '20px')};
  z-index: ${({ order }) => order};
  @media only screen and (max-width: 430px) {
    width: 100%;
    max-width: 350px;
    margin: ${({ isEditing }) => (isEditing ? '30px 0' : '20px 0')};
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('mainColor')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  padding-top: 20px;
`

const Block = styled.div`
  background-color: #fff;
  height: 100%;
  position: relative;
  transform: perspective(1px) translateZ(0);
  transition-property: color;
  transition-duration: 0.3s;
  color: ${getStyleForProp('mainColor')};
  box-shadow: 0px 3px 6px rgba(41, 55, 97, 0.1);
  border-radius: ${getStyleForProp('borderRadius')};
  a {
    color: ${getStyleForProp('mainColor')};
    svg {
      fill: ${getStyleForProp('mainColor')};
    }
  }
  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 8px;
    right: 0;
    transform: scaleX(0);
    transform-origin: 0 50%;
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    background-color: ${getStyleForProp('mainColor')};
    border-top-right-radius: ${getStyleForProp('borderRadius')};
    border-bottom-right-radius: ${getStyleForProp('borderRadius')};
    ${({ theme }) =>
      theme.look === 'SHARP'
        ? css`
            top: 1px;
            bottom: 1px;
          `
        : css`
            top: 0px;
            bottom: 0px;
          `}
  }
  ${({ isHover }) =>
    css`
      color: ${isHover ? 'white' : getStyleForProp('mainColor')};
      .icon_wrap {
        svg {
          fill: ${isHover ? 'white !important' : getStyleForProp('mainColor')};
        }
      }
      ${isHover
        ? css`
            &::before {
              transform: scaleX(1);
            }
          `
        : ''}
    `}
  @media only screen and (max-width: 430px) {
    width: 100%;
  }
`

const BlockItemWrap = styled.article`
  width: 350px;
  height: 100%;
  position: relative;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  display: flex;
  margin: auto;
  align-items: center;
  box-sizing: border-box;
  word-break: break-word;
  -webkit-will-change: transform;
  @media only screen and (max-width: 430px) {
    width: 100%;
    max-width: 350px;
  }
  &::before {
    content: '';
    position: absolute;
    width: 10px;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: ${getStyleForProp('mainColor')};
    border-top-left-radius: ${getStyleForProp('borderRadius')};
    border-bottom-left-radius: ${getStyleForProp('borderRadius')};
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  z-index: 1;
  max-width: ${({ isFourBlock }) => (isFourBlock ? '800px' : '1250px')};
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto -20px auto;
  box-sizing: border-box;
  padding-top: 30px;
  @media only screen and (max-width: 992px) {
    padding-top: 20px;
  }
  .disappearing {
    @media only screen and (max-width: 430px) {
      width: 100%;
      margin: 20px auto;
    }
    ${ControlContainer} {
      margin: 0;
      height: 100%;
    }
  }
`

const BlockTitle = styled.h2`
  font-weight: 700;
  ${getTextStyleByAlignment};
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${({ isEditing }) => (isEditing ? '0' : '22px')};
  > div {
    width: 40px;
    height: 40px;
    svg {
      max-width: 40px;
      max-height: 40px;
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  width: 82px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .icon_wrap {
    svg {
      fill: ${getStyleForProp('mainColor')};
    }
  }
  .control-container {
    margin-left: 22px;
  }
`

const BlockWrap = styled.div`
  display: flex;
  width: calc(100% - 82px);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 20px 24px 20px 20px;
  box-sizing: border-box;
  transform: translateX(-1px);
  .add-button-wrap {
    text-align: initial !important;
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
  & > .control-container h2 {
    margin-bottom: 0px;
  }
  .add-button-wrap {
    margin-top: 20px;
  }
`

const AnchorWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  .link {
    display: flex;
    text-decoration: none;
    font-size: 16px !important;
    color: ${({ isHover }) =>
      isHover ? 'white' : getStyleForProp('mainColor')};
    svg {
      fill: ${({ isHover }) =>
        isHover ? 'white' : getStyleForProp('mainColor')};
    }
  }
  .learn-more-link {
    font-weight: 500;
    align-items: center;
    .arrow-right {
      margin-left: 0;
      padding-left: 5px;
      min-width: 24px;
      transition: transform linear 0.15s;
    }
    &:hover {
      opacity: 0.7;
      .arrow-right {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
  svg {
    transform: translate3d(0, 0, 0);
  }
`

const MorphLeftWrapper = styled.div`
  width: 290px;
  position: absolute;
  top: 0;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
  @media only screen and (max-width: 1366px) {
    width: 275px;
    max-width: 100%;
  }
  .a,
  .c,
  .f {
    fill: ${getStyleForProp('backgroundImageColor')};
  }
  .a,
  .b,
  .c {
    opacity: 0.05;
  }
  .b,
  .e {
    fill: none;
  }
  .b {
    stroke: ${getStyleForProp('backgroundImageColor')};
    stroke-width: 10px;
  }
  .d,
  .f {
    stroke: none;
  }
`

const MorphRightWrapper = styled.div`
  width: 662px;
  position: absolute;
  right: 0;
  bottom: -4px;
  svg {
    width: 100% !important;
    height: 100% !important;
  }
  @media only screen and (max-width: 1366px) {
    width: 662px;
    max-width: 95%;
  }
  .a {
    fill: ${getStyleForProp('backgroundImageColor')};
  }
  .a,
  .b {
    opacity: 0.05;
  }
  .b,
  .d {
    fill: none;
  }
  .b {
    stroke: ${getStyleForProp('backgroundImageColor')};
    stroke-width: 10px;
  }
  .c {
    stroke: none;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  ControlContainer,
  BlocksWrapper,
  Title,
  Description,
  Block,
  BlockItemWrap,
  BlockTitle,
  IconContainer,
  IconWrap,
  BlockWrap,
  AnchorWrapper,
  MorphRightWrapper,
  MorphLeftWrapper
}
