/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header5_2.styled'
import THEME from './Header5_2.theme'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import { validation } from './Header5_2.consts'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'

const Header5_2 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      buttons,
      logoSrc,
      logoWidth,
      logoColor,
      logoDimensions,
      desktopBackgroundMockupColor = 'dark',
      appleBackgroundMockupColor = 'dark',
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      appleBackground,
      appleBackgroundDimensions,
      appleBackgroundImgCropParams,
      desktopBackground,
      desktopBackgroundImgCropParams,
      desktopBackgroundDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
      isEditing={isEditing}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight + 40}>
        <Show when={[title, paragraph, buttons.length > 0]}>
          <S.HeaderContent>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <Buttons data={buttons} />
          </S.HeaderContent>
        </Show>
        <S.MockupsWrap>
          <S.Section>
            <S.Notebook color={desktopBackgroundMockupColor}>
              <ControlsWithImageCropper
                actions={Actions.mockupActions({
                  mockupType: 'desktopBackground',
                  mockupColor: desktopBackgroundMockupColor,
                  keyForSizes: 'header5_2MockupDesktop',
                  imageUrl: desktopBackground
                })}
              >
                <Image
                  alt="Web page "
                  as={S.DesktopImg}
                  asProps={{ imgCropParams: desktopBackgroundImgCropParams }}
                  defaultImgSrc={desktopBackground}
                  sizes={desktopBackgroundDimensions}
                />
              </ControlsWithImageCropper>
            </S.Notebook>
          </S.Section>
          <S.Section>
            <S.Phone color={appleBackgroundMockupColor}>
              <ControlsWithImageCropper
                actions={Actions.mockupActions({
                  mockupType: 'appleBackground',
                  mockupColor: appleBackgroundMockupColor,
                  keyForSizes: 'header5_2Mockup',
                  imageUrl: appleBackground
                })}
              >
                <Image
                  alt="Web page "
                  as={S.MobileImg}
                  asProps={{ imgCropParams: appleBackgroundImgCropParams }}
                  defaultImgSrc={appleBackground}
                  sizes={appleBackgroundDimensions}
                />
              </ControlsWithImageCropper>
            </S.Phone>
          </S.Section>
        </S.MockupsWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header5_2
