/* @flow */
import styled from 'styled-components'

import _Icon from '@website/common/components/Icon'
import { primary, secondary, white } from '@website/common/styles/colors'
import { hexToRgb } from '../../utils'

const MenuWrap = styled.div`
  position: relative;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  border: 1px solid ${secondary.ExtraLight};
  &:focus {
    border-color: ${primary.Dark};
    outline: none;
  }
`

const SelectMenu = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  height: 40px;
  display: flex;
  transition: all 0.1s linear;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  background-color: ${white};
  padding: 10.25px 12px;
  color: ${secondary.Dark};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const Icon = styled(_Icon)`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: rotate(${props => (props.show ? '180' : '0')}deg)
    translateY(${props => (props.show ? '50' : '-50')}%);
`

const SelectMenuWrap = styled.div`
  position: absolute;
  transition: all 0.1s linear;
  z-index: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
  left: 0;
  top: 105%;
  box-shadow: 0 6px 12px 0 rgba(${hexToRgb(primary.ExtraDark)}, 0.1);
  width: 100%;
  height: ${props => (props.show ? 'auto' : 0)};
  opacity: ${props => (props.show ? 1 : 0)};
`

const Menu = styled.div`
  display: flex;
  background-color: white;
  color: ${secondary.Normal};
  padding: 13px 12px;
  flex: none;
  &:hover {
    background-color: ${primary.Light};
    color: ${primary.Dark};
  }
  &:focus {
    background-color: ${primary.Light};
    color: ${primary.Dark};
  }
`

const AddButtonContainer = styled.div`
  flex: none;
  display: flex;
  padding: 13px 12px;
  background-color: white;
  .rf-add-btn {
    padding: 5px 15px;
    font-size: 32px;
  }
`

export default {
  SelectMenu,
  Icon,
  Menu,
  MenuWrap,
  SelectMenuWrap,
  AddButtonContainer
}
