import { useCallback, useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { openLoginPopup } from '@actions/editor'
import { selectIsUserAuthorized } from '@selectors'

const useAuthCallback = (cb, dependencies = []) => {
  const cbRef = useRef(null)
  const dispatch = useDispatch()
  // memoizedCb is the cb function, that will be called after authorization
  const { current: memoizedCb } = cbRef
  const isUserAuthorized = useSelector(selectIsUserAuthorized)

  useEffect(() => {
    if (isUserAuthorized && memoizedCb) {
      memoizedCb()
      cbRef.current = null
    }
  }, [isUserAuthorized, memoizedCb])

  const callback = useCallback(
    (...args) => {
      if (isUserAuthorized) {
        cb(...args)
      } else {
        cbRef.current = () => {
          cb(...args)
        }
        dispatch(openLoginPopup())
      }
    },
    [isUserAuthorized, cb, [...dependencies]]
  )

  return callback
}

export default useAuthCallback
