/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addButton
} from '@website/components/CommonActions'
import {
  cropableImageModalActionMaker,
  buttonModalActionMaker
} from '@website/components/ActionMakers'

export default dispatch => {
  const changeSubTitle = (newSubTitle: string) => ({
    type: 'CHANGE_SUBTITLE',
    value: newSubTitle
  })

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeSubTitle = newTitle => {
    dispatch(changeSubTitle(newTitle))
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSubTitle: _changeSubTitle,
    changeSubTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatch)({
        showRemove: false,
        keyForSizes: 'header36',
        imageUrl
      }),
    btnActions: buttonModalActionMaker(dispatch),
    addButton: () => dispatch(addButton())
  }
}
