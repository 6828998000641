/* @flow */
import React, { useContext, memo } from 'react'
import S from './Portfolio2.styled'
import { validation } from './Portfolio2.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import DotsSVG from '@website/common/components/Dots'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const Portfolio2 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      imageUrl,
      imgDimensions,
      imgCropParams
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WMCustomContainer isEditing={isEditing}>
        <Show when={[title, paragraph]}>
          <S.PortfolioContent>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              className="WM_GLOBAL_heading"
              maxCount={validation.titleMaxChar}
              render={_t => (
                <S.Title className="WM_GLOBAL_heading">{_t}</S.Title>
              )}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Description}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.PortfolioContent>
        </Show>
        <S.PortfolioImageWrap>
          <S.Dots className="right">
            <DotsSVG />
          </S.Dots>
          <S.Dots className="left">
            <DotsSVG />
          </S.Dots>
          <S.SquareBox className="right" />
          <S.SquareBox className="left" />
          <S.ImageFigure>
            <S.ControlContainer>
              <ControlsWithImageCropper
                actions={Actions.imageActions(imageUrl)}
                style={{ width: '100%' }}
              >
                <Image
                  as={S.PortfolioImg}
                  defaultImgSrc={imageUrl}
                  sizes={imgDimensions}
                  asProps={{ imgCropParams }}
                  alt="Section image"
                />
              </ControlsWithImageCropper>
            </S.ControlContainer>
          </S.ImageFigure>
        </S.PortfolioImageWrap>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Portfolio2
