/* @flow */

module.exports = {
  id: 'Feature11',
  isUnique: false,
  thumbnailRatio: 0.48,
  label: 'Feature11',
  category: 'feature',
  orientation: 'LEFT',
  colorMode: '',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/features11new-1_0.png',
  metaData: {
    title: {
      _type: 'title',
      _min: 0,
      _max: 100,
      _value: 'Poster design platform'
    },
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 200,
      _value:
        'This platform brings you the opportunity to create modern, original and exclusive designs with:'
    },
    features: {
      _type: 'list',
      _min: 0,
      _max: 7,
      _value: [
        {
          icon: 'icon-brush_2',
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 60,
            _value: 'A great collection of brushes for an in-detail image'
          }
        },
        {
          icon: 'icon-color',
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 60,
            _value: 'A color palette with blurry colors for smooth lines'
          }
        },
        {
          icon: 'icon-gallery',
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 60,
            _value: 'New set of thematic backgrounds and patterns'
          }
        },
        {
          icon: 'icon-statue',
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 60,
            _value: 'Sculpture effect tool for statue lovers'
          }
        },
        {
          icon: 'icon-3D',
          paragraph: {
            _type: 'paragraph',
            _min: 0,
            _max: 60,
            _value: 'Innovative 3D tool to give your posters a vivid effect'
          }
        }
      ]
    },
    imageUrl: {
      _type: 'image',
      _dimensionsKey: 'imgDimensions',
      _optimizationSizes: [
        { _width: 700 },
        { _width: 1000 },
        { _width: 1000 },
        { _width: 1500 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg'
    },
    imgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg'
    },
    buttons: {
      _type: 'list',
      _min: 0,
      _max: 2,
      _value: [
        {
          buttonText: {
            _type: 'buttonLabel',
            _min: 1,
            _max: 19,
            _value: 'GET STARTED'
          },
          buttonUrl: {
            _type: 'buttonUrl',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          type: 'primary'
        },
        {
          buttonText: {
            _type: 'buttonLabel',
            _min: 1,
            _max: 19,
            _value: 'LEARN MORE'
          },
          buttonUrl: {
            _type: 'buttonUrl',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          type: 'tertiary'
        }
      ]
    }
  },
  defaultProps: {
    title: 'Poster design platform',
    paragraph:
      'This platform brings you the opportunity to create modern, original and exclusive designs with:',
    features: [
      {
        icon: 'icon-brush_2',
        paragraph: 'A great collection of brushes for an in-detail image'
      },
      {
        icon: 'icon-color',
        paragraph: 'A color palette with blurry colors for smooth lines'
      },
      {
        icon: 'icon-gallery',
        paragraph: 'New set of thematic backgrounds and patterns'
      },
      {
        icon: 'icon-statue',
        paragraph: 'Sculpture effect tool for statue lovers'
      },
      {
        icon: 'icon-3D',
        paragraph: 'Innovative 3D tool to give your posters a vivid effect'
      }
    ],
    imageUrl:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg',
    imgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature11-img1-1_0.jpeg'
    },
    buttons: [
      {
        buttonText: 'GET STARTED',
        buttonUrl: 'http://builder.renderforestsites.com',
        type: 'primary'
      },
      {
        buttonText: 'LEARN MORE',
        buttonUrl: 'http://builder.renderforestsites.com',
        type: 'tertiary'
      }
    ]
  }
}
