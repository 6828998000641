/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature23.styled'
import { validation } from './Feature23.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Feature23 = memo(props => {
  const {
    data: {
      blocks,
      title,
      titleAlignment,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <WMContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          isEditing={isEditing}
          as={S.Title}
          maxCount={validation.titleMaxChar}
          className="WM_GLOBAL_heading32"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <S.BlocksWrap isForBlock={blocks.length === 4 ? true : false}>
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => (
              <S.ControlContainer key={idx}>
                <ControlsWithModalOpener
                  actions={Actions.blockActions({
                    idx,
                    showRemove: blocks.length > validation.blocks.min
                  })}
                >
                  <S.ItemWrap>
                    <If
                      condition={!!block.iconName}
                      otherwise={() => (
                        <AddButton
                          onAdd={() => Actions.addIcon(idx)}
                          type="icon"
                          large
                          toShow
                        >
                          +
                        </AddButton>
                      )}
                      then={() => (
                        <S.IconWrapper>
                          <ControlsWithModalOpener
                            actions={Actions.iconActions(idx)}
                          >
                            <S.Circle>
                              <Icon
                                color="white"
                                size="xlarge"
                                name={block.iconName}
                              />
                            </S.Circle>
                          </ControlsWithModalOpener>
                        </S.IconWrapper>
                      )}
                    />
                    <S.Line />
                    <S.TextsContainer>
                      <EditableContent
                        as={S.BlockTitle}
                        text={block.title}
                        alignment={block.blockTitleAlignment}
                        className="WM_GLOBAL_heading20"
                        required={validation.blockTitle.required}
                        maxCount={validation.blockTitleMaxChar}
                        onChange={(newBlockTitle: string) =>
                          Actions.changeBlockTitle(newBlockTitle, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        as={S.BlockParagraph}
                        text={block.paragraph}
                        alignment={block.blockParagraphAlignment}
                        className="WM_GLOBAL_paragraph18"
                        maxCount={validation.blockParagraphMaxChar}
                        onChange={(newBlockParagraph: string) =>
                          Actions.changeBlockParagraph(newBlockParagraph, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }
                      />
                    </S.TextsContainer>
                  </S.ItemWrap>
                </ControlsWithModalOpener>
              </S.ControlContainer>
            )}
          />
        </S.BlocksWrap>
      </WMContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Feature23
