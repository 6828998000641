import styled from 'styled-components'
import LibPopup from '@renderforest/rf-ui-library/organism/Popup'
import LibSlideUp from '@editor/common/lib/SlideUp'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_left.svg'
import { secondary } from '@website/common/styles/colors'

export const Popup = styled(LibPopup)`
  & > div {
    padding: 0;
    & > div {
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const SlideUp = styled(LibSlideUp)`
  & > div {
    background: white;
    border-radius: 10px 10px 0 0;
  }
`

export const PopupHeader = styled.div`
  padding: 40px 20px 0;
  display: flex;
  justify-content: center;
  & > h3 {
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    color: ${secondary.ExtraDark};
  }
`

export const SideBlock = styled.div`
  display: flex;
  justify-content: center;
  width: 40px;
  height: 100%;
`

export const ArrowButton = styled(ArrowIcon)`
  padding: 12px;
  transform: rotate(-90deg);
`

export const MobilePopupHeader = styled.div`
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid ${secondary.Light};
  & > h3 {
    text-align: center;
    margin: 15px 0;
    font-size: 14px;
    font-weight: 600;
    color: ${secondary.ExtraDark};
  }
`
