/* @flow */
import http from '@shared/ApiFactory'
import { SM_API_PATH } from '@shared/ApiConfig'
import objToQs from '@editor/common/utils/obj-to-qs'

type TTemplatesAPIParams = {
  order?: string,
  search?: string | undefined,
  offset?: number | undefined,
  categoryId?: number | undefined
}

export const fetchTemplates_API = (params: TTemplatesAPIParams) => {
  const queryString = objToQs(params)
  return http.GET(`${SM_API_PATH}/presets${queryString}`)
}

export const fetchTemplateById_API = (id: number) =>
  http.GET(`${SM_API_PATH}/presets/${id}`)

export const getPresetCategories = () => http.GET(`${SM_API_PATH}/categories`)

export const getPresetByCategory = (id: number) =>
  http.GET(`${SM_API_PATH}/presets/category/${id}`)
