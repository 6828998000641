/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Text19.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)}
  padding: ${({ isEditing }) => (isEditing ? '170px  0 120px' : '40px 0px')};
  .opacity {
    opacity: 0;
    position: absolute;
    user-select: none;
  }
`

const WMCustomContainer = styled(WMContainer)`
  ${({ isEditing }) =>
    isEditing
      ? 'max-width: 90%;'
      : css`
          width: 100%;
          max-width: 100%;
        `}
  margin: 0 auto;
  color: ${getStyleForProp('heading')};
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: ${({ isEditing }) => !isEditing && '0px'};
    padding-right: ${({ isEditing }) => !isEditing && '0px'};
  }
`

const AnimationContainer = styled.div`
  width: 100%;
  display: flex;
  ${({ isEditing }) =>
    isEditing
      ? ''
      : css`
          max-width: 100%;
          position: relative;
          overflow: hidden;
        `}
  @media only screen and (max-width: 1024px) {
    padding-left: ${({ isEditing }) => !isEditing && '0px'};
    padding-right: ${({ isEditing }) => !isEditing && '0px'};
  }
  .control-container {
    width: 95%;
    @media only screen and (max-width: 457px) {
      width: calc(100% - 65px);
    }
    p {
      margin-top: 0;
      min-width: 133px;
    }
  }
`

const ItemTextOpacity = styled.div`
  opacity: 0.5;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
`

const ItemText = styled.div`
  width: max-content;
  ${({ isEditing }) =>
    !isEditing
      ? css`
          padding-right: 20px;
          box-sizing: border-box;
        `
      : css`
          max-width: 100%;
          min-width: 150px;
          margin-left: auto;
          margin-right: auto;
        `}
  display: flex;
  align-items: center;
  position: relative;
  ${({ isEditing }) => (!isEditing ? 'animation-name: textRun;' : '')}
  animation-duration: ${({ animationSpeed }) => `${animationSpeed}s`};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  .control-container > div > svg {
    height: 24px;
    fill: ${getStyleForProp('iconColor')};
  }
  p {
    margin-bottom: 0;
  }
  @keyframes textRun {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
`

const IconWrap = styled.div`
  margin-right: 20px;
  width: 24px;
  height: 24px;
  & > div > svg {
    height: 24px;
    fill: ${getStyleForProp('iconColor')};
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.8;
  white-space: nowrap;
  margin-bottom: 0;
  ${({ isEditing }) =>
    isEditing
      ? css`
          overflow: hidden;
          width: 100%;
          padding-top: 15px;
          padding-bottom: 15px;
        `
      : css`
          width: max-content;
        `}
  &:after,
  &:before {
    top: 74% !important;
  }
`

const DescriptionOpacity = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  white-space: nowrap;
  margin-bottom: 0;
  width: max-content;
`

export default {
  StyledContainer,
  WMCustomContainer,
  AnimationContainer,
  IconWrap,
  ItemText,
  ItemTextOpacity,
  Description,
  DescriptionOpacity
}
