import styled, { css } from 'styled-components'
import { primary, white } from '@website/common/styles/colors'
import { RippleLoading } from '@editor/common/components/Loaders'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const stylesObj = {
  de: {
    btnPadding: '0 15px'
  },
  default: {
    btnPadding: '0 20px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

const BUTTON_STYLES = css`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 100%;
  background: transparent;
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%) 0%
      0% no-repeat padding-box;
  }
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          pointer-events: none;
          opacity: 0.7;
        `
      : ''};
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background: linear-gradient(180deg, ${primary.SemiDark}, ${primary.Dark});
  & .publish-settings-tooltip {
    height: 100%;
  }
`

export const Loader = styled(RippleLoading)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const PublishButton = styled.button`
  ${BUTTON_STYLES};
  ${({ isLoading }) =>
    isLoading
      ? css`
          & > span {
            visibility: hidden;
          }
        `
      : css`
          & ${Loader} {
            visibility: hidden;
          }
        `};
  flex-grow: 1;
  position: relative;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 600;
  color: ${white};
  padding: ${getStylesByLang('btnPadding')};
`

export const Divider = styled.div`
  height: 70%;
  width: 1px;
  background: ${white};
`

export const OptionsButton = styled.div`
  ${BUTTON_STYLES};
  width: 40px;
  & > svg {
    transition: transform 0.3s ease-out;
    transform: rotate(
      ${({ isOptionsOpened }) => (isOptionsOpened ? '0' : '180deg')}
    );
    fill: white;
  }
`
