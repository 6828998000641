import { getCurrentScreenSize } from '@website/common/utils'

const defaultProps = {
  className: '',
  loadingClassName: 'img-loading',
  loadedClassName: 'img-loaded'
}

/**
 * @exports
 * @description Returns image elem classname depends on
 *  loaded status and on `additionalProps` className prop.
 * @param {bboolean} isLoaded
 * @param {Object} additionalProps
 **/
export const getClassName = (isLoaded, additionalProps) => {
  const customClassName =
    additionalProps && additionalProps.className
      ? additionalProps.className
      : ''
  const { className, loadedClassName, loadingClassName } = defaultProps

  return `${className} ${customClassName} ${
    isLoaded ? loadedClassName : loadingClassName
  }`
}
/**
 * @exports
 * @description Returns image src depends on screen size if sizes is not null,
 * Returns defaultImgSrc otherwise
 * @param {Object} sizes
 * @param {string} defaultImgSrc
 **/
export const getImgSrc = sizes => {
  const size = getCurrentScreenSize()
  if (sizes && sizes.hasOwnProperty(size)) {
    return sizes[size]
  }
  return ''
}
