import styled from 'styled-components'
import THEME from './RSVPButton.theme'
import { styleProvider } from '../../utils'
const getStyleForProp = styleProvider(THEME)

const ButtonWrap = styled.div`
  margin-top: 30px;
`

const Button = styled.button`
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('buttonRadius')};
  ${getStyleForProp('buttonShadow')};
  ${getStyleForProp('buttonBackground')};
  max-width: 300px;
  padding: 0 40px;
  box-sizing: border-box;
  text-align: center;
  height: 50px;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 400 !important;
  background-repeat: no-repeat !important;
  font-size: 14px !important;
  line-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: none;

  .spinner {
    position: absolute;
    right: 15px;
    top: 14px;
  }
`

export default {
  Button,
  ButtonWrap
}
