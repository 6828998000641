// @flow

import React, { memo } from 'react'

import * as Styled from './styled'
import type { TSwitcherProps } from './types'

const Switcher = ({ isChecked, onClick }: TSwitcherProps) => {
  const handleSwitcherClick = () => {
    onClick(!isChecked)
  }

  return (
    <Styled.Switcher isChecked={isChecked} onClick={handleSwitcherClick}>
      <div />
    </Styled.Switcher>
  )
}

export default memo(Switcher)
