/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_CARD_IMAGE':
        draft.cardImage = initialState.cardImage
        break
      case 'REMOVE_CARD_IMAGE': {
        draft.cardImage.imageUrl = ''
        draft.cardImage.imgDimensions = {}
        draft.cardImage.imgCropParams = ''
        break
      }
      case 'CHANGE_CARD_IMAGE': {
        const { url, dimensions } = action.value
        if (!url) {
          return
        }
        draft.cardImage.imageUrl = url
        draft.cardImage.imgDimensions = dimensions || {}
        draft.cardImage.imgCropParams = {}
        break
      }
      case 'CHANGE_CARD_BG_OVERLAY_TRANSPARENCY': {
        draft.cardImage.bgOverlayTransparency = action.value
        break
      }
      case 'CHANGE_SUBTITLE_ALIGNMENT': {
        draft.subtitleAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_LOCATION_ALIGNMENT': {
        draft.locationAlignment = action.value
        break
      }
      case 'CHANGE_DATE_ALIGNMENT': {
        draft.dateAlignment = action.value
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
