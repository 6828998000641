import styled, { css } from 'styled-components'
import { mediaMaxWidth } from '@editor/common/utils/styled'
import { SEMRUSH_SIDEBAR_WIDTH } from '@editor/common/components/SemrushSidebar/consts'
import { BREAKPOINT_800 } from '@containers/edit/consts'

const getSemrushDependedWidth = ({ theme: { isSemrushSidebarVisible } }) =>
  isSemrushSidebarVisible ? `calc(100% - ${SEMRUSH_SIDEBAR_WIDTH}px)` : '100%'

const ButtonStyles = css`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 3px 6px #0052e033;
`

export const PickerPopover = styled.div`
  position: absolute;
  z-index: 4;
  width: 260px;
  top: -27px;
  left: 42px;
  .color-picker {
    border-radius: 10px;
    box-shadow: 0 4px 16px 0 rgba(0, 82, 224, 0.2);
  }
  ${mediaMaxWidth(BREAKPOINT_800)`
    position: fixed;
    bottom: 0;
    width: ${getSemrushDependedWidth};
    left: 0;
    top: initial;
    transform: initial;
    z-index: 11;
    .color-picker {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      & > div > div:nth-child(3) {
        padding-bottom: 15px;
        box-sizing: border-box;
      }
    }
  `}
`

export const ActionButtons = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: -60px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
`

export const CloseButton = styled.div`
  ${ButtonStyles};
  background-color: #9aa6c8;
  left: 10px;
`

export const SaveButton = styled.div`
  ${ButtonStyles};
  background-color: #387dff;
  right: 10px;
`

export const Triangle = styled.div`
  position: absolute;
  top: 32px;
  left: -8px;
  width: 8px;
  height: 14px;
  clip-path: polygon(100% 0, 0 50%, 100% 100%);
  background-color: #fff;
  ${mediaMaxWidth(BREAKPOINT_800)`
     display: none;
  `}
`

export const ColorPickerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
`
