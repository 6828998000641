// @flow
import React, { memo } from 'react'

import { TitleWrapper } from './styled'
import { Title } from '../styled'
import type { TTitleContainerProps } from './types'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import TooltipContent from './../TooltipContent'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import { useResponsive } from '@shared/hooks'

const TitleContainer = ({
  title,
  tooltipText,
  imgSrc,
  className,
  hasTooltipContent,
  isTooltipFromRight
}: TTitleContainerProps) => {
  const isMobile = useResponsive(768)

  return (
    <TitleWrapper className={className}>
      <Title>{title}</Title>
      <TooltipWrapper
        className="tooltip-wrapper"
        position={isTooltipFromRight || !isMobile ? 'right' : 'bottom'}
        content={
          hasTooltipContent ? (
            <TooltipContent text={tooltipText} imgSrc={imgSrc} />
          ) : null
        }
        text={tooltipText}
        color="#363F5A"
        withClick={isMobile}
      >
        <InfoIcon className="info-icon" />
      </TooltipWrapper>
    </TitleWrapper>
  )
}

export default memo(TitleContainer)
