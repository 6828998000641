/* @flow */
import React, { useContext, memo } from 'react'
import S from './Spotify2.styled'
import { validation } from './Spotify2.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { translate } from '@editor/common/utils/translations'
import { convertSpotifyUrlToEmbedUrl } from '@website/common/utils'

const Spotify2 = memo(props => {
  const dispatcher = useContext(DispatchContext)
  const {
    data: {
      title,
      titleAlignment,
      musicUrls,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.maxTitleChar}
          className="WM_GLOBAL_heading42"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <S.MusicWrapper isForBlock={musicUrls.length === 4}>
          <Mapper
            data={musicUrls}
            render={(musicUrl, idx) => (
              <S.MusicContent>
                <ControlsWithModalOpener
                  actions={Actions.musicActions({
                    isSingleTrack: true,
                    idx,
                    musicCount: musicUrls.length,
                    url: musicUrl
                  })}
                  style={{ width: '100%' }}
                >
                  <Overlay />
                  <S.IframeWrapper>
                    <iframe
                      scrolling="no"
                      frameBorder="no"
                      src={convertSpotifyUrlToEmbedUrl(musicUrl)}
                      title="Embedded music"
                    />
                  </S.IframeWrapper>
                </ControlsWithModalOpener>
              </S.MusicContent>
            )}
          />
        </S.MusicWrapper>
        <AddButton
          onAdd={Actions.addMusic}
          toShow={musicUrls.length < validation.musicUrls.max}
          style={{ marginTop: '30px' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_music_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Spotify2
