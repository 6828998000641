/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Slider6.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { secondary } from '@website/common/styles/colors'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding-bottom: ${({ isOneSlide }) => (isOneSlide ? '80px' : '115px')};
  @media only screen and (max-width: 1340px) {
    padding-bottom: ${({ isOneSlide, theme }) =>
      !isOneSlide && theme.orientation === 'RIGHT' ? '115px' : '80px'};
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: ${({ isOneSlide, theme }) =>
      !isOneSlide && theme.orientation === 'RIGHT' ? '95px' : '60px'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isEditing }) => (isEditing ? 'margin-top: 60px;' : null)}
  @media only screen and (max-width: 1340px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'LEFT'
          ? 'column'
          : 'column-reverse'};
      `}

    justify-content: center;
  }
  .slick-slider-container {
    margin: 0;
  }
  .slick-slide > div {
    margin: 0 25px;
    @media only screen and (max-width: 650px) {
      margin: 0 10px;
    }
    font-size: 0;
    .slick-list {
      height: 100%;
    }
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
  .slick-dots {
    bottom: -35px;
    li {
      width: 13px;
      height: 13px;
    }
    button {
      width: 13px;
      height: 13px;
      clip-path: circle(40%);
      background: ${({ theme }) =>
        `rgba(${hexToRgb(getStyleForProp('paragraph')({ theme }))}, 0.5)`};
    }
    .slick-active button {
      background: ${({ theme }) =>
        `linear-gradient(0, ${theme.palette.primaryDark}, ${theme.palette.primaryLight})`};
    }
  }
`

const ImageContentWrapper = styled.div`
  width: 750px;
  min-width: 570px;
  display: flex;
  flex-direction: column;
  position: relative;

  .left,
  .right {
    position: absolute;
    top: 40%;

    @media only screen and (max-width: 450px) {
      top: 36%;
    }
    @media only screen and (max-width: 400px) {
      top: 200px;
    }
  }


  .left {
    left: -30px;
    @media only screen and (max-width: 768px) {
      left: 10px;
    }
    @media only screen and (max-width: 650px) {
      left: -10px;
    }
  }
  .right {
    right: -30px;
    @media only screen and (max-width: 768px) {
      right: 10px;
    }
    @media only screen and (max-width: 650px) {
      right: -10px;
    }
  }
}

  @media only screen and (min-width: 1341px) {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'LEFT'
          ? css`
              margin-right: 50px;
            `
          : css`
              margin-left: 50px;
            `}
      `}
  }
  @media only screen and (max-width: 1440px) {
    width: 600px;
  }
  @media only screen and (max-width: 1340px) {
    width: 100%;
    max-width: 700px;
    min-width: initial;
    margin-right: auto;
    margin-left: auto;
  }
  .rf-add-btn {
    margin: 20px;
  }
`

const TextContentWrapper = styled.div`
  width: 45%;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
  @media only screen and (max-width: 1340px) {
    width: 100%;
    max-width: 700px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
  ${({ theme, isOneSlide }) =>
    css`
      ${theme.orientation === 'LEFT' && isOneSlide
        ? css`
            @media only screen and (max-width: 1340px) {
              margin-top: 50px;
            }
            @media only screen and (max-width: 992px) {
              margin-top: 40px;
            }
          `
        : theme.orientation === 'LEFT' && !isOneSlide
        ? css`
            @media only screen and (max-width: 1340px) {
              margin-top: 85px;
            }
            @media only screen and (max-width: 992px) {
              margin-top: 75px;
            }
          `
        : css`
            @media only screen and (max-width: 1340px) {
              margin-bottom: 50px;
              ${({ isEditing }) => (isEditing ? 'margin-top: 60px;' : null)}
            }
            @media only screen and (max-width: 992px) {
              margin-bottom: 40px;
            }
          `}
    `}
`

const Title = styled.h1`
  margin-bottom: 0;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  padding-top: 20px;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

const Block = styled.article`
  width: calc(50% - 25px);
  margin: 25px 0 50px 0;
  box-sizing: border-box;
  box-shadow: ${({ theme }) =>
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.palette.accentWhite}, ${theme.palette.tertiaryLight})`};
  border-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 1440px) and (min-width: 1341px) {
    max-width: 240px;
    min-width: 240px;
  }
  @media only screen and (min-width: 651px) {
    ${({ areThreeBlock }) =>
      areThreeBlock &&
      css`
        &:nth-child(3) {
          margin-right: auto;
          margin-left: auto;
        }
      `}
    ${({ areFiveBlock }) =>
      areFiveBlock &&
      css`
        &:nth-child(5) {
          margin-right: auto;
          margin-left: auto;
        }
      `}
    &:nth-child(even) {
      margin-left: 20px;
    }
  }
  @media only screen and (max-width: 1340px) {
    width: 100%;
    max-width: 255px;
  }
  @media only screen and (max-width: 650px) {
    margin: 24px 0 40px 0;
  }
  .control-container {
    display: flex;
    justify-content: flex-start;
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 50px;
  margin-bottom: -50px;
  @media only screen and (max-width: 1340px) {
    margin-right: auto;
    margin-left: auto;
    max-width: 580px;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 30px;
    margin-bottom: -40px;
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTextColor')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockTextColor')};
  padding-top: ${({ isBlockTitle, isEditing }) =>
    isBlockTitle || isEditing ? '10px' : '0'};
  width: 100%;
  ${getTextStyleByAlignment};
`

const IconWrap = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${getStyleForProp('buttonBackground')};
  border-radius: ${({ theme }) => (theme.look === 'SHARP' ? '0' : '5px')};
  pointer-events: none;
  > div {
    width: 32px;
    height: 32px;
    svg {
      max-width: 32px;
      max-height: 32px;
      fill: ${({ theme }) => theme.palette.accentWhite};
    }
  }
  .control-container {
    margin: 0 30px;
  }
`

const IconContainer = styled.div`
  position: absolute;
  top: -24px;
  width: 48px;
  height: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .control-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const BlockWrap = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  padding: 15px 0;
  box-sizing: border-box;
  padding: 50px 20px 30px 20px;
  @media only screen and (max-width: 450px) {
    width: auto;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
  }
`

const SliderItemWrapper = styled.div`
  padding: ${({ isEditing }) => (isEditing ? '50px 0 2px 0' : '0')};
  border-radius: ${getStyleForProp('cardRadius')};
  height: 100%;
  position: relative;
  box-sizing: border-box;
  .control-container {
    height: 100%;
    width: 100%;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: auto;
    border-radius: ${getStyleForProp('cardRadius')};
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
    box-shadow: ${({ theme }) =>
      `0px 0px 24px rgba(${hexToRgb(theme.palette.primaryDark)}, 0.15)`};
  }
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ActionButtonsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto 0 auto;
`

const SlideCountContainer = styled.p`
  font-size: 16px;
  text-align: center;
  color: ${getStyleForProp('heading')};
  margin: 50px auto 20px auto;
`

const ArrowIconContainer = styled.button`
  width: 42px;
  min-width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #c1c9e0;
  box-shadow: 0 6px 12px 0 #3271e626;

  & > svg {
    fill: #545f7e;
  }

  ${({ isRight }) =>
    isRight
      ? css`
          & > svg {
            transform: rotate(180deg);
          }
        `
      : ''}

  &:hover {
    & > svg {
      fill: #252e48;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextContentWrapper,
  ImageContentWrapper,
  Paragraph,
  Title,
  BlocksWrapper,
  Block,
  BlockTitle,
  BlockParagraph,
  IconContainer,
  IconWrap,
  BlockWrap,
  SliderItemWrapper,
  ControlContainer,
  Img,
  ActionButtonsWrapper,
  SlideCountContainer,
  ArrowIconContainer
}
