import React, { useState, useCallback } from 'react'
import ImgStateOverlay from './ImgStateOverlay'
import ImageElem from './ImageElem'

const ImageWithUploadState = props => {
  const [loaded, setLoaded] = useState(false)

  const { hideLoader, defaultImgSrc, sizes, onLoad, ...restProps } = props
  const src = `${defaultImgSrc}`
  const isLoading = !hideLoader && defaultImgSrc.isLoading
  const failed = defaultImgSrc.failed
  const showTemporaryImage = isLoading || failed
  const hasSizes = sizes && !!Object.keys(sizes).length

  const _sizes = showTemporaryImage
    ? { small: src, medium: src, large: src, xlarge: src }
    : hasSizes
    ? sizes
    : {}

  const handleImageLoad = useCallback(
    e => {
      onLoad && onLoad(e)
      setLoaded(true)
    },
    [onLoad]
  )

  return (
    <>
      <ImageElem {...restProps} sizes={_sizes} onLoad={handleImageLoad} />
      <ImgStateOverlay loaded={loaded} isLoading={isLoading} failed={failed} />
    </>
  )
}

export default ImageWithUploadState
