// @flow
import React, { memo, useState, useCallback, useMemo } from 'react'

import * as Styled from './styled'
import Header from './Header'
import Content from './Content'
import Footer from './Footer'
import { ICON_FILTERS } from './consts'
import { MODAL_TYPES } from '../consts'
import { shuffle, addIconPrefixes } from './utils'

const IconModal = ({
  handler,
  setModalState,
  initialFilter = ICON_FILTERS.ALL
}) => {
  const [searchTerm, setSearchTerm] = useState('')
  const [filterValue, setFilterValue] = useState(initialFilter)
  const [selectedIcon, setSelectedIcon] = useState('')

  const ICON_NAMES =
    typeof window !== 'undefined' && window.ICON_NAMES ? window.ICON_NAMES : []

  const iconsByFilter = useMemo(() => {
    const glyphIconsPrefixed = addIconPrefixes(ICON_NAMES.glyphIcons, 'glyph-')
    const outlineIconsPrefixed = addIconPrefixes(
      ICON_NAMES.outlineIcons,
      'outline-'
    )
    const socialIconsPrefixed = addIconPrefixes(
      ICON_NAMES.socialIcons,
      'social-'
    )

    const allIconsSorted = shuffle([
      ...glyphIconsPrefixed,
      ...socialIconsPrefixed,
      ...outlineIconsPrefixed
    ])

    return {
      [ICON_FILTERS.ALL]: allIconsSorted,
      [ICON_FILTERS.GLYPH]: glyphIconsPrefixed,
      [ICON_FILTERS.OUTLINE]: outlineIconsPrefixed,
      [ICON_FILTERS.SOCIAL]: socialIconsPrefixed
    }
  }, [])

  const iconsToShow = useMemo(() => {
    const icons = iconsByFilter[filterValue]
    return searchTerm
      ? icons.filter(
          icon => icon.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
        )
      : icons
  }, [searchTerm, filterValue])

  const closeModal = useCallback(() => {
    setModalState(MODAL_TYPES.iconModal, { isOpen: false })
  }, [])

  const handleSave = useCallback(() => {
    handler(selectedIcon)
    closeModal()
  }, [selectedIcon, closeModal])

  return (
    <Styled.Popup
      isOpen
      isMobileFullScreen
      onClose={closeModal}
      className="icon_popup"
    >
      <Header
        searchTerm={searchTerm}
        filterValue={filterValue}
        setSearchTerm={setSearchTerm}
        setFilterValue={setFilterValue}
      />
      <Content
        icons={iconsToShow}
        selected={selectedIcon}
        onSelect={setSelectedIcon}
      />
      <Footer
        onCancel={closeModal}
        onSave={handleSave}
        isSaveDisabled={!selectedIcon}
      />
    </Styled.Popup>
  )
}

export default memo(IconModal)
