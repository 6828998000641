export const validation = {
  titleMaxChar: 50,
  paragraphMaxChar: 150,
  blockTitleMaxChar: 40,
  blockTitle: { required: true },
  slides: {
    min: 1,
    max: 12
  }
}
