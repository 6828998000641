/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature1.styled'
import { validation } from './Feature1.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled/'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { translate } from '@editor/common/utils/translations'

const Feature1 = memo(props => {
  const {
    data: {
      title,
      description,
      titleAlignment,
      paragraphAlignment,
      featureIconText,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <WMContainer>
        <Show when={[title, description]}>
          <S.Headings>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.headingMaxChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading"
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              as={S.Paragraph}
              text={description}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              onChange={Actions.changeDescription}
              className="WM_GLOBAL_paragraph"
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.Headings>
        </Show>
        <S.FeaturesContainer isForBlock={featureIconText.length === 4}>
          <Mapper
            data={featureIconText}
            render={(featureItem, idx) => (
              <Show
                key={idx}
                when={[
                  featureItem.icon,
                  featureItem.title,
                  featureItem.content,
                  featureItem.additionalUrl,
                  featureItem.additionalText
                ]}
              >
                <S.FetaureItem isForBlock={featureIconText.length === 4}>
                  <ControlsWithModalOpener
                    actions={Actions.featureActions({
                      idx,
                      showRemove:
                        featureIconText.length > validation.featureIconText.min
                    })}
                    alignment="right"
                  >
                    <S.FeatureItemHeading>
                      <If
                        condition={featureItem.icon !== ''}
                        otherwise={() => (
                          <AddButton
                            onAdd={() => Actions.addIcon(idx)}
                            style={{ marginRight: '1rem' }}
                            type="icon"
                            large
                            toShow
                          >
                            +
                          </AddButton>
                        )}
                        then={() => (
                          <S.IconWrap>
                            <ControlsWithModalOpener
                              actions={Actions.getIconActions({ idx })}
                            >
                              <S.Circle>
                                <Icon
                                  size="large"
                                  color="white"
                                  name={featureItem.icon}
                                />
                              </S.Circle>
                            </ControlsWithModalOpener>
                          </S.IconWrap>
                        )}
                      />
                      <EditableContent
                        text={featureItem.title}
                        as={S.FeatureIconTextTitle}
                        className="WM_GLOBAL_primary-font"
                        maxCount={validation.blockTitleMaxChar}
                        onChange={(newTitle: string) =>
                          Actions.changeBlockTitle(newTitle, idx)
                        }
                      />
                    </S.FeatureItemHeading>
                    <EditableContent
                      text={featureItem.content}
                      alignment={featureItem.blockContentAlignment}
                      as={S.Description}
                      maxCount={validation.blockParagraphMaxChar}
                      className="WM_GLOBAL_secondary-font"
                      onChange={(newData: string) =>
                        Actions.changeBlockContent(newData, idx)
                      }
                      changeAlignment={(alignment: string) =>
                        Actions.changeContentAlignment(alignment, idx)
                      }
                    />
                    <AdditionalLink
                      idx={idx}
                      additionalLinkText={featureItem.additionalText}
                      additionalLinkUrl={featureItem.additionalUrl}
                    />
                  </ControlsWithModalOpener>
                </S.FetaureItem>
              </Show>
            )}
          />
        </S.FeaturesContainer>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={featureIconText.length !== validation.featureIconText.max}
          style={{ margin: '0 0 2rem 0' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Feature1
