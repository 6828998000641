//@flow
import React, { memo } from 'react'

import * as Styled from './styled'

const CircleProgressLoader = ({ className = '', text }) => (
  <Styled.CircleProgressLoader className={className}>
    <div className="circle" />
    {text ? <p className="loading-text">{text}</p> : null}
  </Styled.CircleProgressLoader>
)

export default memo(CircleProgressLoader)
