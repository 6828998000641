/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const MorphRight = ({ theme }) => {
  const { look, palette } = theme
  const isSharp = look === 'SHARP'
  const from = palette.primaryLight
  const to = palette.primaryDark
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="698"
      height="610"
      viewBox="0 0 698 610"
    >
      <defs></defs>
      <g transform="translate(-742 -34)">
        <path
          className="a"
          d="M776.811,644A373.517,373.517,0,0,1,742,486c0-207.107,167.893-375,375-375,137.507,0,257.727,74.009,323,184.368V644Z"
        />
        <g className="b" transform="translate(1059 34)">
          <circle className="c" cx="23" cy="23" r="23" />
          <circle className="d" cx="23" cy="23" r="18" />
        </g>
      </g>
    </svg>
  )
}

export default withTheme(MorphRight)
