export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryLight,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
