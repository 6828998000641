import React, { memo, useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'
import ReactPlayer from 'react-player'

import { EditingContext } from '@contexts'

const LazyVideoPlayer = props => {
  const { isEditing } = useContext(EditingContext)
  const location = useLocation()
  const isVBrowserMode = location.search.includes('vbrowser=true')

  if (isEditing || isVBrowserMode) {
    return <ReactPlayer {...props} light={isVBrowserMode}/>
  }

  const [isPlayerLoaded, setPlayerLoadedState] = useState(false)

  const handleMouseMove = () => {
    setPlayerLoadedState(true)
  }

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove, { once: true })
    window.addEventListener('touchmove', handleMouseMove, { once: true })
  }, [])

  return isPlayerLoaded ? <ReactPlayer {...props} /> : null
}

export default memo(LazyVideoPlayer)
