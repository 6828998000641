// @flow
import React from 'react'
import * as CommonActions from './CommonActions'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import { ICON_FILTERS } from '@website/common/components/Controlled/ControlModals/IconModal/consts'
import MediaControl from '@website/common/components/MediaControl'
import EventControlItem from '@website/common/components/EventControlItem'
import TransparencyControl from '@website/common/components/TransparencyControl'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import LinkIcon from '@editor/common/assets/svgr-icons/Link_icon.svg'
import StarIcon from '@editor/common/assets/svgr-icons/star_icon.svg'
import SettingsIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'
import LocationIcon from '@editor/common/assets/svgr-icons/Location_icon.svg'
import SpotifyIcon from '@editor/common/assets/svgr-icons/spotify.svg'
import SoundcloudIcon from '@editor/common/assets/svgr-icons/soundcloud.svg'

import { useHistoryWithSearch } from '@hooks'
import { useRouteMatch } from 'react-router-dom'
import { PAGE_BASE, EDIT_BASE } from '@editor/conf/routes'
import { translate } from '@editor/common/utils/translations'
import { isOpenedWithHash } from '@editor/common/utils'

// Make actions for editing company logos
export const clientLogoActionMaker =
  dispatch =>
  ({
    showRemove,
    logoIdx,
    keyForSizes,
    imageUrl,
    link,
    withImgSizes = false,
    mediaFlyoutShift
  }) =>
    [
      {
        modalType: 'image',
        renderer: ({ handler }) => (
          <MediaControl
            handler={handler}
            type={LINK_TYPES.image}
            keyForSizes={keyForSizes}
            withImgSizes={withImgSizes}
            link={imageUrl}
            mediaFlyoutShift={mediaFlyoutShift}
          />
        ),
        handler: (imgUrl, dimensions, sizes) => {
          const undoable = !!dimensions
          dispatch(
            CommonActions.changeClientLogo({
              url: imgUrl,
              idx: logoIdx,
              imgDimensions: dimensions,
              sizes,
              undoable
            })
          )
        }
      },
      {
        IconComp: LinkIcon,
        tooltipText: translate('add_link_label'),
        handler: link => {
          dispatch(CommonActions.changeClientUrl(logoIdx, link))
        },
        modal: {
          type: MODAL_TYPES.urlModal,
          props: {
            type: LINK_TYPES.link,
            link: link
          }
        }
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () => {
          dispatch(CommonActions.removeClientLogo(logoIdx))
        }
      }
    ]

// Make actions for editing standard buttons
export const buttonModalActionMaker =
  dispatch =>
  (btnIdx, { text, link }, showRemove = true, additionalProps = {}) =>
    [
      {
        IconComp: SettingsIcon,
        tooltipText: translate('settings_label'),
        handler: data => {
          dispatch(
            CommonActions.changeButtonData(btnIdx, data, additionalProps)
          )
        },
        modal: {
          type: MODAL_TYPES.extendedUrlModal,
          props: { text, link, limitText: true }
        }
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () =>
          dispatch(CommonActions.removeButton(btnIdx, additionalProps))
      }
    ]

// Make action for editing any image

const defaultImageActions = {
  change: CommonActions.changeImage,
  remove: CommonActions.removeImage
}
export const imageModalActionMaker =
  dispatch =>
  ({
    showRemove = true,
    keyForSizes,
    actions = defaultImageActions,
    clickHandlers = {},
    withImgSizes = false,
    imageUrl,
    type = LINK_TYPES.image,
    ...rest
  }) => {
    const additionalArgs = Object.values(rest || {})
    return [
      {
        modalType: 'image',
        renderer: ({ handler }) => (
          <MediaControl
            handler={handler}
            type={type}
            keyForSizes={keyForSizes}
            withImgSizes={withImgSizes}
            link={imageUrl}
            {...rest}
          />
        ),
        handler: (url, dimensions, sizes) => {
          const undoable = !!dimensions
          dispatch(
            actions.change({
              url,
              dimensions,
              sizes,
              ...rest,
              undoable
            })
          )
        },
        onClick: clickHandlers.change
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () => {
          dispatch(actions.remove(...additionalArgs))
        },
        onClick: clickHandlers.remove
      }
    ]
  }

// Make actions for editing mockups
export const mockupModalActionMaker = dispatch => args =>
  imageModalActionMaker(dispatch)({
    ...args,
    actions: {
      change: CommonActions.changeMockupBackground,
      remove: CommonActions.removeMockupBackground
    }
  })

// Actions for editing header and footer navigation logos
export const navbarLogoActionMaker = dispatch => args =>
  imageModalActionMaker(dispatch)({
    ...args,
    keyForSizes: 'navbarLogoSizes',
    type: LINK_TYPES.logo,
    actions: {
      change: CommonActions.changeNavbarLogo,
      remove: CommonActions.removeNavbarLogo
    }
  })

//Actions to edit icons

const defaultIconActions = {
  change: CommonActions.changeIcon,
  remove: CommonActions.removeIcon
}
export const iconActionMaker =
  dispatcher =>
  ({ idx, filter, actions = defaultIconActions, showRemove = true }) =>
    [
      {
        IconComp: EditIcon,
        tooltipText: translate('edit_label'),
        handler: (iconName: TLinkData) =>
          dispatcher(actions.change(idx, iconName)),
        modal: {
          type: MODAL_TYPES.iconModal,
          props: {
            initialFilter: filter
          }
        }
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(actions.remove(idx)),
        toShow: showRemove
      }
    ]

// Make actions from Social Icons
export const socialActionMaker =
  dispatcher =>
  (socialIdx: number, socialUrl: string, additionalArgs: Object) => {
    return [
      {
        IconComp: LinkIcon,
        tooltipText: translate('add_social_link_label'),
        handler: link =>
          dispatcher(
            CommonActions.changeSocialUrl(socialIdx, link, additionalArgs)
          ),
        modal: {
          type: MODAL_TYPES.urlModal,
          props: {
            type: LINK_TYPES.social,
            link: socialUrl
          }
        }
      },
      ...iconActionMaker(dispatcher)({
        idx: socialIdx,
        actions: {
          change: (...actionArgs) =>
            CommonActions.changeSocialIcon(...actionArgs, additionalArgs),
          remove: (...actionArgs) =>
            CommonActions.removeSocial(...actionArgs, additionalArgs)
        },
        filter: ICON_FILTERS.SOCIAL
      })
    ]
  }

export const simpleButtonActionMaker = dispatcher => text =>
  [
    {
      IconComp: EditIcon,
      tooltipText: translate('edit_label'),
      handler: (changedText: string) =>
        dispatcher(CommonActions.changeButtonText(changedText)),
      modal: {
        type: MODAL_TYPES.textModal,
        props: { text }
      }
    }
  ]

export const navLinkActionMaker =
  dispatcher =>
  (idx, { text, link }, fromComp: 'header' | 'footer') => {
    return [
      {
        IconComp: SettingsIcon,
        tooltipText: translate('settings_label'),
        handler: data =>
          dispatcher(CommonActions.editNavigationLink(idx, data, fromComp)),
        modal: {
          type: MODAL_TYPES.extendedUrlModal,
          props: {
            link,
            text,
            limitText: true
          }
        }
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () =>
          dispatcher(CommonActions.removeNavigationLink(idx, fromComp))
      }
    ]
  }

export const mapModalActionMaker =
  dispatcher => (initialMapSrc, initialGeoLocation) =>
    [
      {
        IconComp: LocationIcon,
        tooltipText: translate('choose_location_label'),
        handler: mapData => dispatcher(CommonActions.changeMapData(mapData)),
        modal: {
          type: MODAL_TYPES.mapModal,
          props: {
            initialMapSrc,
            initialGeoLocation
          }
        }
      }
    ]

// Make action for changing video Urls with modal
export const videoModalActionMaker =
  dispatcher => (showRemove: boolean, videoUrl: string, idx: number) =>
    [
      {
        handler: url => {
          dispatcher(CommonActions.changeVideoUrl(url, idx))
        },
        renderer: ({ handler }) => (
          <MediaControl
            isVideo
            handler={handler}
            link={videoUrl}
            type={LINK_TYPES.video}
          />
        )
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () => {
          dispatcher(CommonActions.removeVideoUrl(idx))
        }
      }
    ]

const defaultLinkActions = {
  change: CommonActions.changeLinkData,
  remove: CommonActions.removeLink
}

export const linkActionMaker =
  dispatcher =>
  ({
    isAdditionalLink,
    linkIdx,
    linkData,
    showRemove = true,
    actions = defaultLinkActions
  }) => {
    const { text, url } = linkData
    return [
      {
        IconComp: isAdditionalLink ? SettingsIcon : EditIcon,
        tooltipText: isAdditionalLink
          ? translate('settings_label')
          : translate('edit_label'),
        handler: data => dispatcher(actions.change(linkIdx, data)),
        modal: {
          type: MODAL_TYPES.extendedUrlModal,
          props: { text, link: url, limitText: false }
        }
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(actions.remove(linkIdx)),
        toShow: showRemove
      }
    ]
  }

export const removeBlockActionMaker =
  dispatcher =>
  ({ idx, showRemove, beforeRemove }) =>
    [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => {
          beforeRemove && beforeRemove()
          dispatcher(CommonActions.removeBlock(idx))
        },
        toShow: showRemove
      }
    ]

export const subscribtionCardActionMaker =
  dispatcher =>
  ({ idx, showStar, showRemove }) =>
    [
      {
        IconComp: StarIcon,
        tooltipText: translate('highlight_label'),
        handler: () => dispatcher(CommonActions.changePrimaryPlan(idx)),
        toShow: showStar
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () => dispatcher(CommonActions.removeSubscriptionCard(idx))
      }
    ]

export const soundCloudUrlEditActionMaker =
  dispatcher =>
  ({ fullHeight = false, idx, isSingleTrack = true, url }) =>
    [
      {
        IconComp: SoundcloudIcon,
        tooltipText: translate('add_soundcloud_label'),
        handler: url => dispatcher(CommonActions.changeMusicUrl(url, idx)),
        modal: {
          type: MODAL_TYPES.soundcloudModal,
          props: {
            fullHeight,
            type: LINK_TYPES.soundCloudTrack,
            isSingleTrack,
            url: url.match(/src="https[^"]+/)?.[0].substring(5)
          }
        }
      }
    ]

export const spotifyTrackEditActionMaker =
  dispatcher =>
  ({ idx, isSingleTrack, url }) =>
    [
      {
        IconComp: SpotifyIcon,
        tooltipText: translate('add_spotify_label'),
        handler: url => dispatcher(CommonActions.changeMusicUrl(url, idx)),
        modal: {
          type: MODAL_TYPES.spotifyModal,
          props: {
            type: LINK_TYPES.spotifyTrack,
            isSingleTrack,
            url
          }
        }
      }
    ]

export const musicItemRemoveActionMaker =
  dispatcher =>
  ({ idx, showRemove }) =>
    [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () => dispatcher(CommonActions.removeMusicUrl(idx))
      }
    ]

const defaultCropableImageActions = {
  ...defaultImageActions,
  crop: CommonActions.setImgCropParams
}

export const cropableImageModalActionMaker =
  dispatch =>
  ({
    showRemove = true,
    showCrop = true,
    actions = defaultCropableImageActions,
    clickHandlers = {},
    additionalArgs = {},
    keyForSizes,
    withImgSizes = false,
    imageUrl,
    mediaFlyoutShift
  }) =>
    [
      {
        modalType: 'image',
        renderer: ({ handler }) => (
          <MediaControl
            handler={handler}
            type={LINK_TYPES.image}
            keyForSizes={keyForSizes}
            withImgSizes={withImgSizes}
            link={imageUrl}
            mediaFlyoutShift={mediaFlyoutShift}
          />
        ),
        handler: (url, dimensions, sizes) => {
          const undoable = !!dimensions
          dispatch(
            actions.change({
              url,
              dimensions,
              sizes,
              ...additionalArgs,
              undoable
            })
          )
        },
        onClick: clickHandlers.change
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () => dispatch(actions.remove(additionalArgs)),
        onClick: clickHandlers.remove
      },
      {
        modalType: 'crop',
        IconComp: EditIcon,
        tooltipText: translate('edit_label'),
        toShow: showCrop,
        onClick: clickHandlers.crop,
        handler: imgCropParams =>
          dispatch(
            actions.crop({ imgCropParams, undoable: true, ...additionalArgs })
          ),
        modal: {
          type: MODAL_TYPES.imageCrop
        }
      }
    ]

export const privacyPolicyAction = () => {
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(EDIT_BASE) || {}
  const { params } = useRouteMatch(PAGE_BASE) || {}
  const page = params?.page || 'home'

  return [
    {
      IconComp: SettingsIcon,
      tooltipText: translate('settings_label'),
      handler: () =>
        history.push({
          pathname: `${url}/${page}/settings/cookies`,
          state: { activeSetting: 'privacyPolicyBtn' }
        })
    }
  ]
}

export const paypalButtonModalActionMaker =
  dispatch => (data, compId, reducerProps) =>
    [
      {
        IconComp: SettingsIcon,
        tooltipText: translate('settings_label'),
        handler: data => {
          dispatch(CommonActions.changePayPalButtonData(data, reducerProps))
        },
        modal: {
          type: MODAL_TYPES.paypalSettingsPopup,
          props: { popupFormData: data, compId }
        }
      }
    ]

export const eventSettingsActionMaker = ({ tabName, onControlClick }) => [
  {
    renderer: () =>
      isOpenedWithHash() ? (
        []
      ) : (
        <EventControlItem tabName={tabName} onClick={onControlClick} />
      )
  }
]

export const cardBgImageActionMaker =
  dispatch =>
  ({
    showRemove = true,
    actions,
    additionalArgs = {},
    keyForSizes,
    imageUrl,
    bgOverlayTransparency = 0,
    transparencyFlyoutAlignment,
    transparencyFlyoutShift,
    mediaFlyoutShift
  }) =>
    [
      {
        modalType: 'image',
        renderer: ({ handler }) => (
          <MediaControl
            handler={handler}
            type={LINK_TYPES.image}
            keyForSizes={keyForSizes}
            link={imageUrl}
            mediaFlyoutShift={mediaFlyoutShift}
          />
        ),
        handler: (url, dimensions, sizes) => {
          const undoable = !!dimensions
          dispatch(
            actions.changeImage({
              url,
              dimensions,
              sizes,
              undoable
            })
          )
        }
      },
      {
        renderer: ({ handler }) => (
          <TransparencyControl
            bgOverlayTransparency={bgOverlayTransparency}
            transparencyFlyoutAlignment={transparencyFlyoutAlignment}
            transparencyFlyoutShift={transparencyFlyoutShift}
            handler={handler}
          />
        ),
        handler: actions.changeTransparency
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        toShow: showRemove,
        handler: () => dispatch(actions.removeImage(additionalArgs))
      }
    ]
