import React, { useContext, useState, memo } from 'react'
import { EditingContext } from '@contexts'

import * as Styled from './styled'

const ItemWrapper = memo(({ animationDuration, children, width }) => {
  const [mouseDownTarget, setMouseDownTarget] = useState(null)

  const { isEditing } = useContext(EditingContext)

  const onClick = e => {
    if (e.target !== mouseDownTarget) {
      e.stopPropagation()
    }
    setMouseDownTarget(null)
  }

  const handleMouseDown = e => {
    setMouseDownTarget(e.target)
  }

  const listeners = isEditing ? { onClick, onMouseDown: handleMouseDown } : {}

  return (
    <Styled.ItemWrapper {...listeners}>
      <Styled.Item width={width} animationDuration={animationDuration}>
        {children}
      </Styled.Item>
    </Styled.ItemWrapper>
  )
})

export default ItemWrapper
