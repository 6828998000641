/* @flow */
import styled from 'styled-components'
import THEME from './Feature16.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 80px 0;
  background-color: ${getStyleForProp('background')};
`
const Title = styled.h4`
  color: ${getStyleForProp('heading')};
  text-align: center;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  font-weight: bold;
  text-align: center;
  margin-bottom: 30px;
`

const Img = styled.img`
  border-radius: ${getStyleForProp('borderRadius')};
  height: ${props => (props.isEditing ? '100% !important' : 'auto')};
`

const BlockTitle = styled.p`
  color: ${getStyleForProp('blockTitle')};
  font-size: 20px;
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockTitle')};
  font-size: 12px;
  font-weight: bold;
  font-weight: 400;
  margin: 8px 0 0 0;
`

const Badge = styled.div`
  background: ${getStyleForProp('badgeBg')};
  font-size: 10px;
  padding: 4px 10px;
  margin: 8px 0 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getStyleForProp('borderRadius')};
`

const BadgeText = styled.p`
  color: ${({ theme }) => theme.palette.accentWhite};
  font-weight: bold;
`

const SubItem = styled.div`
  flex-direction: ${props => (props.isEven ? 'row' : 'row-reverse')};
  @media only screen and (max-width: 1400px) {
    flex-direction: ${props => (props.isEven ? 'column' : 'column-reverse')};
  }

  .withSubItems_0 {
    div:nth-child(2) .figure {
      @media only screen and (max-width: 800px) {
        .LazyLoad {
          width: 100%;
        }
      }
      figure img {
        height: 100%;
      }
    }
  }
`

export default {
  Container,
  Title,
  Description,
  BlockTitle,
  BlockParagraph,
  Badge,
  BadgeText,
  Img,
  SubItem
}
