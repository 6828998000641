/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  addButton,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeSubTitle = (newSubTitle: string) => {
    dispatcher({
      type: 'CHANGE_SUB_TITLE',
      value: newSubTitle
    })
  }

  const removeSlide = slideIdx => {
    dispatcher({
      type: 'REMOVE_SLIDE',
      value: { slideIdx }
    })
  }

  const addSlide = () => {
    dispatcher({
      type: 'ADD_SLIDE'
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const imgActions = (slideIdx, imgIdx, imageUrl) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'header18',
      showRemove: false,
      additionalArgs: {
        slideIdx,
        imgIdx
      },
      imageUrl
    })

  return {
    changeTitle: _changeTitle,
    changeSubTitle,
    changeParagraph: _changeParagraph,
    changeSubTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addButton,
    addSlide,
    removeSlide,
    imgActions
  }
}
