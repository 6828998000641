//@flow
import React, { memo } from 'react'

import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { secondary } from '@website/common/styles/colors'
import * as Styled from './styled'

type TProps = {
  suggestion: string,
  inputValue: string,
  setInputValue: (value: string) => void,
  closeSuggestionsList: () => void
}

const NonMatchingSubString = ({ substring }) => (
  <span style={{ color: secondary.Light }}>{substring}</span>
)

const SuggestionItem = ({
  suggestion,
  inputValue: inputValueFromProps,
  setInputValue,
  closeSuggestionsList
}: TProps) => {
  const inputValue = inputValueFromProps.trim()

  const indexOfMatch = suggestion
    .toLowerCase()
    .indexOf(inputValue.toLowerCase())

  const highlightedSuggestion =
    !inputValue.length || indexOfMatch === -1 ? (
      suggestion
    ) : (
      <>
        <NonMatchingSubString
          substring={suggestion.substring(0, indexOfMatch)}
        />
        {suggestion.substring(indexOfMatch, indexOfMatch + inputValue.length)}
        <NonMatchingSubString
          substring={suggestion.substring(indexOfMatch + inputValue.length)}
        />
      </>
    )

  const onClick = () => {
    setInputValue(suggestion)
    closeSuggestionsList()
  }

  return (
    <Styled.SuggestionItem onClick={onClick}>
      <Icon name="search_tiny" size="medium" color={secondary.Light} />
      <span>{highlightedSuggestion}</span>
    </Styled.SuggestionItem>
  )
}

export default memo(SuggestionItem)
