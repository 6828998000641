/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps

  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, dimensions } = action.value
        draft.mainImage.url = url
        draft.mainImage.imgDimensions = dimensions || {}
        draft.mainImage.imgCropParams = {}
        break
      }
      case 'CHANGE_SLIDER_IMAGE':
        {
          const { slideIdx, url, dimensions } = action.value
          draft.slides[slideIdx].url = url
          draft.slides[slideIdx].imgDimensions = dimensions
          draft.slides[slideIdx].imgCropParams = {}
        }
        break
      case 'REMOVE_SLIDER_IMAGE':
        draft.slides.splice(action.value, 1)
        break
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        break
      case 'REMOVE_SOCIAL':
        {
          const { idx } = action.value
          draft.socials.splice(idx, 1)
        }
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.socials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.socials[idx].icon = iconName
        }
        break
      case 'SET_SLIDER_IMG_CROP_PARAMS': {
        const { imgCropParams, slideIdx } = action.value
        draft.slides[slideIdx].imgCropParams = imgCropParams
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams } = action.value
        draft.mainImage.imgCropParams = imgCropParams
      }
    }
  })
}
