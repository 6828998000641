/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  return produce(state, draft => {
    const initialState = action.__initialData.defaultProps
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_PRICE':
        draft.planPrice = action.value
        break
      case 'CHANGE_PRICE_ALIGNMENT': {
        draft.priceAlignment = action.value
        break
      }
      case 'CHANGE_OPTION':
        draft.options[action.value.optionIdx] = action.value.newOption
        break
      case 'ADD_OPTION':
        draft.options.push(initialState.options[0])
        break
      case 'REMOVE_OPTION':
        draft.options.splice(action.value, 1)
        break
      case 'CHANGE_BUTTON_DATA':
        const { link, text } = action.value.btnData
        draft.buttonUrl = link
        draft.buttonText = text
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
