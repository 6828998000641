import styled from 'styled-components'

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff0ec;
  padding: 22px 15px;
  white-space: nowrap;

  svg {
    fill: #545f7e;
  }
`

export const SidebarTitleWrapper = styled.span`
  display: flex;
  align-items: center;

  & > svg {
    fill: #ff642d;
  }
`

export const SidebarTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #252e48;
  margin-left: 10px;
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;

  .close_icon {
    fill: #8493bd;
    cursor: pointer;

    &:hover {
      fill: #545f7e;
    }
  }
`

export const PinIconWrapper = styled.div`
  display: flex;

  svg * {
    fill: #8493bd;
  }

  :hover {
    cursor: pointer;
    color: #252e48;

    svg * {
      fill: #545f7e;
    }
  }

  &:after {
    content: '';
    background-color: #8493bd;
    width: 1px;
    height: 25px;
    margin: 0 15px 0 10px;
  }
`
