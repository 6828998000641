import React, { memo } from 'react'

import * as Styled from './styled'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'

const Option = ({ option, className = '' }) => (
  <Styled.Option className={className}>
    <Icon name="check" size="xxSmall" color="white" />
    <span>{option.str}</span>
  </Styled.Option>
)

export default memo(Option)
