/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text7.styled'
import { validation } from './Text7.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import LeftTriangle from '@website/common/components/LeftTriangle'
import RightTriangle from '@website/common/components/RightTriangle'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import SingleButton from '@website/common/components/SingleButton'
import { translate } from '@editor/common/utils/translations'

const Text7 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      content,
      buttonText,
      buttonUrl
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const isAnyItemFilled = () => {
    return (
      content.findIndex(
        contentItem => contentItem.title !== '' || contentItem.paragraph !== ''
      ) !== -1
    )
  }
  const areAllItemsEmpty = !isAnyItemFilled()

  return (
    <S.StyledContainer>
      <LeftTriangle />
      <RightTriangle />
      <S.WmCustomContainer isEditing={isEditing}>
        <Show when={[title, paragraph]}>
          <S.Content
            isEditing={isEditing}
            areAllItemsEmpty={areAllItemsEmpty}
            isParagraphExists={paragraph}
          >
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              areAllItemsEmpty={areAllItemsEmpty}
              maxCount={validation.maxTitleChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading"
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.maxParagraphChar}
              onChange={Actions.changeParagraph}
              className="WM_GLOBAL_paragraph"
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.Content>
        </Show>
        <Show when={[!areAllItemsEmpty]}>
          <S.ContentWrapper>
            {content.map((item: string, idx: number) => {
              return idx % 2 === 0 ? (
                <Show when={[item.title, item.paragraph]}>
                  <S.TextItemWrap key={idx}>
                    <S.TextItem>
                      <ControlsWithModalOpener
                        actions={Actions.blockActions({
                          idx,
                          showRemove: content.length > validation.content.min
                        })}
                      >
                        <EditableContent
                          as={S.BlockTitle}
                          alignment={item.titleAlignment}
                          text={item.title}
                          maxCount={validation.maxQuestionChar}
                          className="WM_GLOBAL_primary-font WM_GLOBAL_heading20"
                          onChange={(newTitle: string) => {
                            Actions.changeBlockTitle(newTitle, idx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(alignment, idx)
                          }
                        />
                        <EditableContent
                          text={item.paragraph}
                          alignment={item.paragraphAlignment}
                          as={S.BlockParagraph}
                          maxCount={validation.maxAnswerChar}
                          className="WM_GLOBAL_secondary-font WM_GLOBAL_paragraph18"
                          onChange={(newParagraph: string) => {
                            Actions.changeBlockParagraph(newParagraph, idx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                      </ControlsWithModalOpener>
                    </S.TextItem>
                  </S.TextItemWrap>
                </Show>
              ) : null
            })}
            {content.map((item: string, idx: number) => {
              return idx % 2 === 1 ? (
                <S.TextItemWrap key={idx}>
                  <S.TextItem>
                    <ControlsWithModalOpener
                      actions={Actions.blockActions({
                        idx,
                        showRemove: content.length > validation.content.min
                      })}
                    >
                      <EditableContent
                        as={S.BlockTitle}
                        alignment={item.titleAlignment}
                        text={item.title}
                        maxCount={validation.maxQuestionChar}
                        className="WM_GLOBAL_primary-font WM_GLOBAL_heading20"
                        onChange={(newTitle: string) => {
                          Actions.changeBlockTitle(newTitle, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        text={item.paragraph}
                        as={S.BlockParagraph}
                        alignment={item.paragraphAlignment}
                        maxCount={validation.maxAnswerChar}
                        className="WM_GLOBAL_secondary-font WM_GLOBAL_paragraph18"
                        onChange={(newParagraph: string) => {
                          Actions.changeBlockParagraph(newParagraph, idx)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }
                      />
                    </ControlsWithModalOpener>
                  </S.TextItem>
                </S.TextItemWrap>
              ) : null
            })}
          </S.ContentWrapper>
        </Show>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={content.length < validation.content.max}
          style={{ marginTop: '50px' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
        <SingleButton btnUrl={buttonUrl} btnText={buttonText} />
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text7
