/* @flow */
import React, { forwardRef, memo, useCallback } from 'react'
import S from './RSVPCheckboxList.styled'
import RSVPCheckbox from '@website/common/components/RSVPCheckbox'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import type { TRSVPCheckboxList } from './RSVPCheckboxList.type'

const RSVPCheckboxList = forwardRef(
  (
    {
      title,
      items,
      checkedItems,
      onChange,
      errorMessage,
      isRequired,
      className
    }: TRSVPCheckboxList,
    ref
  ) => {
    const handleChange = useCallback(
      (value, isChecked) => {
        const _checkedItems = isChecked
          ? [...checkedItems, value]
          : checkedItems.filter(checked => checked !== value)

        onChange && onChange(_checkedItems)
      },
      [checkedItems, onChange]
    )

    return (
      <S.CheckboxListWrap isError={!!errorMessage} className={className || ''}>
        <S.CheckboxTitleWrap className="checkbox-list-title">
          <S.CheckboxTitle className="WM_GLOBAL_paragraph14">
            {title}{' '}
            {isRequired ? (
              <S.Asterisk className="asterisk">*</S.Asterisk>
            ) : null}
          </S.CheckboxTitle>
        </S.CheckboxTitleWrap>
        <S.CheckboxItemWrap hasOnlyFourItems={items.length === 4}>
          {items.map(item => {
            const isChecked =
              checkedItems.findIndex(checked => checked === item.value) !== -1
            return (
              <RSVPCheckbox
                ref={ref}
                key={item.value}
                value={item.value}
                label={item.label}
                isChecked={isChecked}
                onChange={handleChange}
              />
            )
          })}
        </S.CheckboxItemWrap>
        {!!errorMessage ? (
          <S.ErrorMessage className="WM_GLOBAL_secondary-font">
            <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
            {errorMessage}
          </S.ErrorMessage>
        ) : null}
      </S.CheckboxListWrap>
    )
  }
)

export default memo(RSVPCheckboxList)
