import styled, { css } from 'styled-components'

import Popup from '@renderforest/rf-ui-library/organism/Popup'
import { primary, secondary, white } from '@website/common/styles/colors'
import { BACKGROUND_IMAGE, MOBILE_BACKGROUND_IMAGE } from './consts'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const stylesObj = {
  ru: {
    defaultPopupHeight: '855px'
  },
  es: {
    defaultPopupHeight: '855px'
  },
  jp: {
    defaultPopupHeight: '840px',
    defaultTitleFontSize: '20px',
    singleOptionTitleFontSize: '20px'
  },
  default: {
    defaultPopupHeight: '800px',
    singleOptionPopupHeight: 'auto',
    defaultTitleFontSize: '32px',
    singleOptionTitleFontSize: '26px'
  }
}

const getStylesByLang = styleByLang(stylesObj)

export const UpgradePopup = styled(Popup)`
  height: 100%;
  & > div {
    padding: 0;
    max-height: 90vh;
    max-width: 800px;
    height: ${({ isSingleOption }) =>
      isSingleOption
        ? getStylesByLang('singleOptionPopupHeight')
        : getStylesByLang('defaultPopupHeight')};
    & > div {
      position: relative;
      justify-content: space-between;
      padding: 0;
      z-index: 0;
      ${({ showBgImage }) =>
        showBgImage
          ? css`
              background-image: url(${BACKGROUND_IMAGE});
              background-size: 100% 100%;
              background-repeat: no-repeat;

              @media only screen and (max-width: 768px) {
                background-image: url(${MOBILE_BACKGROUND_IMAGE});
              }
            `
          : ''};
      @media only screen and (max-width: 768px) {
        justify-content: flex-start;
      }
    }
  }

  .upgrade-popup-loader {
    backdrop-filter: blur(8px);
  }

  @media only screen and (max-width: 768px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 850px) {
    & > div {
      max-width: 90%;
      width: 90%;
    }
  }

  @media only screen and (min-width: 769px) and (max-height: 800px) {
    & > div {
      max-height: 90%;
      height: 100%;
    }
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SwitcherWrapper = styled.div`
  position: relative;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const Switcher = styled.div`
  position: relative;
  width: 184px;
  height: 40px;
  display: flex;
  border: 1px solid #c1c9e0;
  border-radius: 20px;
  box-sizing: border-box;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  &:after {
    content: '';
    width: 90px;
    height: 36px;
    position: absolute;
    top: 1px;
    right: ${({ isYearly }) => (isYearly ? '1px' : '91px')};
    background-color: #387dff;
    border-radius: 20px;
    transition: right 200ms ease;
  }
`

export const SwitcherButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#FFFFFF' : '#363f5a')};
  font-weight: 500;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  z-index: 1;
  transition: color 200ms ease;
  user-select: none;
`

export const SaveLabel = styled.img`
  position: absolute;
  bottom: 5px;
  left: calc(100% + 5px);
  @media only screen and (max-width: 768px) {
    position: static;
    margin-bottom: 10px;
  }
`

export const Title = styled.h1`
  color: #252e48;
  font-weight: 700;
  text-align: center;
  font-size: ${({ isSingleOption }) =>
    isSingleOption
      ? getStylesByLang('singleOptionTitleFontSize')
      : getStylesByLang('defaultTitleFontSize')};
  ${({ isSingleOption }) =>
    isSingleOption
      ? css`
          max-width: 537px;
          padding: 50px 40px 20px;
        `
      : css`
          padding: 40px 20px 20px;
        `}

  @media only screen and (max-width: 768px) {
    padding: 50px 10px 10px;
    font-size: 24px;
  }
`

export const MainContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-grow: 0;
  }
`

export const CardsWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-end;
  padding: 40px 80px 30px;
  & > div:first-child {
    margin-right: 20px;
  }
  @media only screen and (max-width: 768px) {
    height: auto;
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    padding: 30px 20px;
    box-sizing: border-box;
    & > div:first-child {
      margin: 0;
    }
    & > div:last-child {
      margin: 0 0 20px 0;
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 850px) {
    padding: 40px 25px 30px;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0 20px;
`

export const SingleCardWrapper = styled.div`
  padding: 40px 40px 30px;

  @media only screen and (max-width: 630px) {
    width: 100%;
    box-sizing: border-box;
    padding: 60px 35px 30px;
  }
`

export const Gradient = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background: linear-gradient(
    315deg,
    #db9af9,
    #ffffff,
    #ffe0d2,
    #ffffff,
    #9eb3f6
  );
  background-size: 600% 100%;
  animation: gradient 7s linear infinite;
  animation-direction: alternate;
  border-radius: 10px;

  @keyframes gradient {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }
`

export const Button = styled.button`
  color: ${white};
  font-size: 18px;
  font-weight: 500;
  padding: 14px 30px;
  border-radius: 5px;
  box-shadow: 0 6px 12px #766de880;
  background: transparent linear-gradient(180deg, #8c83f7 0%, #4136c1 100%) 0%
    0% no-repeat padding-box;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: none;

  &:hover {
    background: transparent linear-gradient(180deg, #b5afff 0%, #9b94fc 100%) 0%
      0% no-repeat padding-box;
  }
`
export const Info = styled.p`
  color: ${secondary.ExtraDark};
  font-size: 12px;
  font-weight: 500;
  padding-top: 3px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const DetailsPopup = styled(Popup)`
  height: 100%;

  & > div {
    padding: 0;
    max-height: 90%;
    max-width: 90%;
    border: 2px solid ${primary.Dark};
    border-radius: 20px;
    & > div {
      background-color: #f6fafe;
      padding: 30px 30px 20px;
      box-sizing: border-box;
      width: 310px;
      & > button {
        margin: 30px auto 0;
      }
    }
  }
`
