/* @flow */
import { validation } from '../Text9.consts'

import {
  iconActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import { addBlock } from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockTitle = (newTitle: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, blockIdx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  return {
    addBlock: _addBlock,
    changeBlockTitle,
    changeBlockTitleAlignment,
    iconActions: blockIdx => [
      ...iconActionMaker(dispatcher)({
        idx: blockIdx,
        showRemove: false
      })
    ],
    blockActions: (idx: number, rowCount: number) =>
      removeBlockActionMaker(dispatcher)({
        idx,
        showRemove: rowCount > validation.blocks.min
      })
  }
}
