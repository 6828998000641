//@flow
import React, { memo } from 'react'
import * as Styled from './styled'
import type { TPopupContentData } from './types'
import LinkWrapper from '@website/common/components/LinkWrapper'
import RightArrow from '@editor/common/assets/svgr-icons/arrow_small_right.svg'

const PopupContent = ({
  title,
  paragraph,
  buttonText,
  showButton,
  buttonUrl
}: TPopupContentData) => (
  <Styled.PopupContent>
    <Styled.Title className="WM_GLOBAL_heading">{title}</Styled.Title>
    {paragraph ? (
      <Styled.Description className="WM_GLOBAL_paragraph">
        {paragraph}
      </Styled.Description>
    ) : null}
    {showButton ? (
      <Styled.AdditionalLinkWrapper>
        <LinkWrapper
          url={buttonUrl}
          text={buttonText}
          linkProps={{
            className: 'WM_GLOBAL_secondary-font additional-link'
          }}
        />
        <RightArrow />
      </Styled.AdditionalLinkWrapper>
    ) : null}
  </Styled.PopupContent>
)

export default memo(PopupContent)
