import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.primaryLight,
    paragraph: p => p.accentWhite,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    teamName: p => p.tertiaryDark,
    teamPosition: p => p.tertiaryNormal,
    blockParagraph: p => p.tertiaryNormal,
    badgeBg: p => p.primaryLight,
    buttonColor: p => p.tertiaryNormal,
    bgLeft: p => p.primaryLight,
    bgRight: p => p.primaryDark,
    raisedShadow: () => 'none'
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    teamName: p => p.tertiaryDark,
    teamPosition: p => p.tertiaryNormal,
    blockParagraph: p => p.tertiaryNormal,
    badgeBg: p => p.primaryDark,
    buttonColor: p => p.tertiaryNormal,
    bgLeft: p => p.primaryLight,
    bgRight: p => p.primaryDark
  },
  EXTRALIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    teamName: p => p.tertiaryDark,
    teamPosition: p => p.tertiaryNormal,
    blockParagraph: p => p.tertiaryNormal,
    badgeBg: p => p.primaryDark,
    buttonColor: p => p.tertiaryNormal,
    bgLeft: p => p.primaryLight,
    bgRight: p => p.primaryDark
  }
}
