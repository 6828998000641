/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import { accentRed } from '@website/common/styles/colors'
import THEME from './Contact12.theme'
import {
  styleProvider,
  hexToRgb,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${getStyleForProp('cardBackgroundGradient')};
  backdrop-filter: blur(15px);
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: ${({ theme }) =>
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  border: 1px solid ${getStyleForProp('border')};
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media only screen and (max-width: 650px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  > :first-child {
    padding-top: 0;
  }
  .socials-block {
    flex-direction: column;
    align-items: center;
    position: relative;
    @media only screen and (min-width: 1025px) {
      transform: translateY(60px);
    }
    @media only screen and (max-width: 1024px) {
      flex-direction: row;
      bottom: -50px;
      right: 0;
      position: absolute;
    }
    @media only screen and (max-width: 650px) {
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      box-sizing: border-box;
      bottom: -130px;
      left: 50%;
      transform: translateX(-50%);
    }
    .add-button-wrap {
      margin-top: 20px;
      .rf-add-btn {
        margin-left: 0;
      }
    }
    .link-wrapper {
      svg {
        fill: ${getStyleForProp('socialIcons')};
      }
    }
    ${({ isSocialBlock, isEditing }) =>
      (isSocialBlock || isEditing) &&
      css`
        &:before {
          content: '';
          position: absolute;
          top: -110px;
          left: 50%;
          transform: translateX(-50%);
          width: 1px;
          height: 110px;
          background-color: ${getStyleForProp('socialIcons')};
          @media only screen and (max-width: 1024px) {
            width: 70px;
            height: 1px;
            left: -80px;
            top: 50%;
            transform: translateY(-50%);
          }
          @media only screen and (max-width: 768px) {
            display: none;
          }
        }
      `}
  }

  .socials-wrapper {
    @media only screen and (min-width: 1025px) {
      flex-direction: column;
    }
  }
`

const ContactTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    padding-top: 0;
    margin-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  margin-bottom: 30px;
  margin-right: auto;
  margin-left: auto;
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 45px 0 25px;
  height: 50px;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('inputRadius')};
  border: ${getStyleForProp('border')};
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 25px;
  margin-bottom: 30px;
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('inputRadius')};
  border-color: ${({ theme }) =>
    `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  &:focus {
    border-color: ${({ theme }) =>
      `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
`

const FormContainer = styled.div`
  width: ${({ isSocialBlock, isEditing }) =>
    !isSocialBlock && !isEditing ? '100%' : '85%'};
  max-width: 740px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
`

const InputContainer = styled.div`
  position: relative;
  width: calc(50% - 15px);
  margin: 20px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
  .required-icon {
    position: absolute;
    right: 10px;
    bottom: 5px;
    padding: 10px;
  }
  > div {
    > svg {
      max-width: 12px;
      max-height: 12px;
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
`

const InputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }
`

const Label = styled.label`
  display: block;
  margin-bottom: ${({ isEditing }) => (isEditing ? '15px' : '5px')};
  color: ${getStyleForProp('label')};
  font-weight: 400;
  align-self: flex-start;
  position: relative;
  z-index: 1;
  &:after,
  &:before {
    left: 0;
    right: initial;
  }
`

const TextAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 866px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
    flex-direction: column;
  }
`

const ErrorMessage = styled.div`
  position: absolute;
  bottom: -20px;
  color: ${accentRed};
  font-size: 14px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 5px;
  }
`

export const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
  max-width: ${({ isSocialBlock, isEditing }) =>
    !isSocialBlock && !isEditing ? '740px' : '866px'};
  @media only screen and (max-width: 1024px) {
    max-width: 744px;
  }
  @media only screen and (max-width: 650px) {
    align-items: center;
    margin-bottom: ${({ isSocialBlock, isEditing }) =>
      !isSocialBlock && !isEditing ? '0' : '80px'};
  }
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin: 0;
    }
  }
`

export default {
  Title,
  Input,
  TextArea,
  StyledContainer,
  WmCustomContainer,
  InputContainer,
  FormContainer,
  ContactTextContainer,
  InputsWrapper,
  Label,
  TextAreaContainer,
  ContentWrapper,
  ErrorMessage,
  ErrorIcon,
  CardContainer
}
