/* @flow */
import styled from 'styled-components'

import THEME from './Header10.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  ${getBackgroundStyles(THEME)};
  word-break: break-word;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  text-align: center;
`

const Description = styled.h2`
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.4;
`
const ButtonsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
  .add-button-wrap {
    margin: 15px;
    > div {
      width: 100%;
      box-sizing: border-box;
      height: 50px;
      padding: 16px 30px;
    }
  }
`
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
`

const BtnWrapper = styled.div`
  margin: 20px 15px;
  @media only screen and (max-width: 700px) {
    max-width: 300px;
    margin: 15px 0;
  }
  .control-container {
    width: 100%;
  }
  .link-wrapper {
    width: 100%;
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      getStyleForProp(props.isTertiary ? 'raisedShadow' : 'buttonShadow')};
    ${props =>
      getStyleForProp(
        props.isTertiary ? 'buttonBackgroundTertiary' : 'buttonBackground'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    max-width: 300px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`

const Code = styled.pre`
  margin: 0;
  &:focus {
    outline: none;
  }
  &:after {
    top: 0 !important;
  }
`

export default {
  Code,
  Container,
  WmCustomContainer,
  Title,
  Description,
  ButtonsContainer,
  ButtonsBlock,
  ButtonsWrap,
  BtnWrapper
}
