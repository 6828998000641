//@flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TResetOptionsProps } from './types'

const ResetOption = ({ isChecked, option, onChange }: TResetOptionsProps) => (
  <Styled.OptionBlock>
    <div>
      <Styled.OptionLabel>{option.label}</Styled.OptionLabel>
      <Styled.OptionDescription>{option.description}</Styled.OptionDescription>
    </div>
    <div>
      <Styled.Checkbox
        isChecked={isChecked}
        onChange={onChange}
        item={{
          label: '',
          value: option.value
        }}
      />
    </div>
  </Styled.OptionBlock>
)

export default memo(ResetOption)
