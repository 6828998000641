export const allowOnlyNumbersAndDot = e => {
  const charCode = e.which || e.keyCode

  if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
    e.preventDefault()

  // Check if the dot has already been entered
  const dotIdx = e.target.value.indexOf('.')

  if (charCode === 46 && dotIdx >= 0) e.preventDefault()
}
