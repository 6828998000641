/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer1.theme'
import { styleProvider } from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  padding: ${({ isEditing }) => (isEditing ? '130px 0 50px 0' : '50px 0')};
  background-color: ${getStyleForProp('background')};
  .socials-block {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
  .socials-wrapper {
    flex-wrap: nowrap;
    & > .control-container:nth-last-child(-n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
    .link {
      fill: #ffffff;
      ${getStyleForProp('buttonBackground')};
      border-radius: ${getStyleForProp('iconRadius')};
    }
  }
`

const FooterContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 800px) {
    flex-direction: column-reverse;
  }
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  color: ${getStyleForProp('paragraph')};
  @media only screen and (min-width: 801px) {
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            max-width: calc(100% - 210px);
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 0;
            }
          `
        : css`
            max-width: 100%;
          `}
  }
  @media only screen and (max-width: 800px) {
    margin-top: 40px;
    text-align: center;
  }
`

const LinkWrap = styled.div`
  position: relative;
  max-width: 170px;
  &:hover {
    a {
      opacity: 0.6;
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          .control-container {
            margin-left: 10px;
          }
        `
      : css`
          .link-wrapper {
            margin-left: 10px;
          }
        `}
  @media only screen and (min-width: 801px) {
    margin-left: 10px;
  }
  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
    .control-container {
      margin-left: 0;
    }

    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
`

const CopyRightWrapper = styled.div`
  display: flex;
  @media only screen and (min-width: 801px) {
    ${({ isEditing, isSocials }) =>
      !isEditing && !isSocials
        ? css`
            width: 100%;
          `
        : css`
            width: 72%;
          `}
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    .control-container {
      margin-top: 60px;
      & > p {
        margin-top: 0;
      }
    }
  }
`

export default {
  StyledContainer,
  FooterContentWrap,
  Copyright,
  LinkWrap,
  CopyRightWrapper
}
