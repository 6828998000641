/* @flow */
import styled from 'styled-components'

import THEME from './Header39.theme'
import { styleProvider, getBackgroundStyles ,  getTextStyleByAlignment} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0 0 80px 0;
  @media only screen and (max-width: 457px) {
    padding: 0 0 60px 0;
  }
`

const WMCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 0;
`

const HeaderContent = styled.div`
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  margin-bottom: 20px;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  letter-spacing: 5px;
  text-shadow: 10px 10px ${getStyleForProp('shadowColor')},
    20px 20px ${getStyleForProp('shadowColor2')},
    30px 30px ${getStyleForProp('shadowColor3')};
  @media only screen and (max-width: 1200px) {
    text-shadow: 3px 3px ${getStyleForProp('shadowColor')},
      6px 6px ${getStyleForProp('shadowColor2')},
      9px 9px ${getStyleForProp('shadowColor3')};
  }
  @media only screen and (max-width: 767px) {
    text-shadow: 2px 2px ${getStyleForProp('shadowColor')},
      4px 4px ${getStyleForProp('shadowColor2')},
      6px 6px ${getStyleForProp('shadowColor3')};
  }
`

const Description = styled.p`
  color: ${getStyleForProp('heading')};
  margin-bottom: 30px;
  line-height: 1.4;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WMCustomContainer,
  HeaderContent,
  Title,
  Description
}
