/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header42.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const stylesLineAlignment = {
  default: '',
  left: css`
    margin-right: auto;
  `,
  center: css`
    margin-right: auto;
    margin-left: auto;
  `,
  right: css`
    margin-left: auto;
  `
}

const getLineStyleByAlignment = ({ alignment }) =>
  stylesLineAlignment[alignment]

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1240px;
  margin: auto;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    padding-top: ${({ topOffset }) => `${topOffset + 110}px`};
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  }
`

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0 0 100px 0;
  min-height: ${({ isSocials }) => (isSocials ? '330px' : 'auto')};
  flex-direction: column;
  position: relative;
  @media only screen and (max-width: 650px) {
    padding: ${({ isEditing }) => (isEditing ? '0 0 80px 0' : '0 0 84px 0')};
  }
  @media only screen and (max-width: 450px) {
    padding: ${({ isEditing }) => (isEditing ? '0 0 80px 0' : '0 0 64px 0')};
  }
  .socials-block {
    display: inline-block;
    position: absolute;
    right: 30px;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media only screen and (max-width: 992px) {
      width: 90%;
      right: unset;
      transform: unset;
      position: initial;
      padding: 0 20px;
      box-sizing: border-box;
    }
    .rf-add-btn {
      margin-left: initial;
    }
  }
  .socials-wrapper {
    flex-direction: column;
    @media only screen and (max-width: 992px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin: 50px 0 0 0;
    }
    @media only screen and (max-width: 610px) {
      width: 100%;
    }
    .link {
      fill: ${getStyleForProp('socialColor')};
      border-radius: ${getStyleForProp('iconRadius')};
      border: 1px solid ${getStyleForProp('socialBorder')};
    }
  }
`

const HeaderContent = styled.div`
  position: relative;
  width: 50%;
  max-width: 620px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    margin-top: ${({ isSecondImage }) => (isSecondImage ? '100px' : '60px')};
    margin-bottom: ${({ isThirdImage, isEditing }) =>
      !isThirdImage && !isEditing ? '50px' : 'auto'};
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: 1201px) {
    transform: ${({ isThirdImage, isEditing }) =>
      !isThirdImage && !isEditing ? 'translateY(55px)' : 'none'};
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`
const HeaderContentContainer = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 85px;
  @media only screen and (max-width: 1440px) and (min-width: 1201px) {
    width: 85%;
    transform: translateX(-15%);
  }
  &::before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: ${getStyleForProp('iconRadius')};
    background-color: transparent;
    border: 2px solid ${getStyleForProp('icons')};
    bottom: 0;
    right: 70px;
    @media only screen and (max-width: 1200px) {
      right: auto;
      left: ${({ isThirdImage, isEditing }) =>
        !isThirdImage && !isEditing ? 'calc(50% - 65px)' : 'calc(50% - 150px)'};
      bottom: -60px;
    }
  }
  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: ${getStyleForProp('iconRadius')};
    background-color: ${getStyleForProp('icons')};
    bottom: 70px;
    right: 30px;
    @media only screen and (max-width: 1200px) {
      right: auto;
      left: ${({ isThirdImage, isEditing }) =>
        !isThirdImage && !isEditing ? '50%' : 'calc(50% - 90px)'};
      bottom: 15px;
    }
  }
  .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 1200px) {
    padding-bottom: 50px;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  > :first-child {
    padding-top: 0;
    margin-top: 0;
  }
`

const ImageContent = styled.div`
  position: relative;
  width: 45%;
  max-width: 560px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media only screen and (min-width: 1201px) {
    margin-right: 60px;
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  @media only screen and (max-width: 900px) {
    max-width: 470px;
  }
  .add-button-wrap {
    margin: 20px auto auto auto;
  }
`
const ImageFrame = styled.div`
  box-shadow: 0px 3px 10px rgba(41, 55, 97, 0.101);
  background-color: inherit;
  border-radius: ${getStyleForProp('iconRadius')};
  .image-state-overlay,
  .failed-image-container {
    z-index: 3;
  }
  &.image-1 {
    width: 96%;
    z-index: 1;
    ${({ theme }) =>
      theme.look === 'ROUNDED'
        ? css`
            &::before {
              content: '';
              position: absolute;
              width: 50px;
              height: 50px;
              border-radius: ${getStyleForProp('iconRadius')};
              background-color: transparent;
              border: 2px solid ${getStyleForProp('icons')};
              top: 0;
              right: 2px;
              @media only screen and (max-width: 650px) {
                width: 27px;
                height: 27px;
              }
            }
            &::after {
              content: '';
              position: absolute;
              width: 25px;
              height: 25px;
              border-radius: ${getStyleForProp('iconRadius')};
              background-color: ${getStyleForProp('icons')};
              top: 75px;
              right: 0;
              @media only screen and (max-width: 650px) {
                width: 13px;
                height: 13px;
                top: 50px;
              }
            }
          `
        : css``};
  }
  &.image-2 {
    width: 41%;
    position: absolute;
    max-width: 230px;
    bottom: -7%;
    left: 0;
    z-index: 3;
    ${({ theme }) =>
      theme.look === 'ROUNDED'
        ? css`
            &::after {
              content: '';
              position: absolute;
              width: 10px;
              height: 10px;
              border-radius: ${getStyleForProp('iconRadius')};
              background-color: ${getStyleForProp('icons')};
              right: 10px;
              bottom: 2px;
            }
          `
        : css``};
  }
  &.image-3 {
    width: 150px;
    margin-right: 0;
    position: relative;
    z-index: 1;
    @media only screen and (min-width: 1201px) {
      margin-bottom: 20px;
    }
    ${({ theme }) =>
      theme.look === 'ROUNDED'
        ? css`
            &::after {
              content: '';
              position: absolute;
              width: 10px;
              height: 10px;
              border-radius: ${getStyleForProp('iconRadius')};
              background-color: ${getStyleForProp('icons')};
              right: -20px;
              bottom: 20px;
            }
          `
        : css``};
  }
`
const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('iconRadius')};
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  &::before {
    content: '';
    position: absolute;
    border-radius: ${getStyleForProp('iconRadius')};
    background-color: transparent;
    border: 2px solid ${getStyleForProp('icons')};
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    transform: translate(-6px, 6px);
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  padding-top: 10px;
`

const SubTitle = styled.h1`
  width: 100%;
  color: ${getStyleForProp('subTitle')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.h2`
  position: relative;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  max-width: 100%;
  margin-top: 20px;
`
const Line = styled.div`
  @media only screen and (min-width: 1201px) {
    width: 100%;
  }
  width: 210px;
  height: 2px;
  background-color: ${getStyleForProp('subTitle')};
  ${getLineStyleByAlignment};
  margin-top: 20px;
`

export default {
  WmCustomContainer,
  StyledContainer,
  ImageContent,
  ImageFrame,
  ControlContainer,
  ImageWrapper,
  Img,
  HeaderContent,
  HeaderContentContainer,
  Title,
  SubTitle,
  Description,
  Line
}
