/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header34.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0 0 80px 0;
  min-height: ${({ isSocials }) => (isSocials ? '330px' : 'auto')};
  position: relative;
  @media only screen and (max-width: 457px) {
    padding: 0 0 60px 0;
  }
  & ${S.Container} {
    margin-bottom: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 450px;
  margin: 0 20px 0 50px;
  @media only screen and (max-width: 1023px) {
    max-width: 100%;
    margin: 0;
  }
  .socials-block {
    display: inline-block;
    position: absolute;
    left: 30px;
    bottom: 78px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media only screen and (max-width: 1023px) {
      padding: 35px 0 0 0;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 90%;
      right: unset;
      transform: unset;
      position: initial;
    }
    @media only screen and (min-width: 1024px) {
      .rf-add-btn {
        margin-left: initial;
      }
    }
  }
  .socials-wrapper {
    flex-direction: column;
    @media only screen and (max-width: 1023px) {
      flex-direction: row;
    }
    .link {
      fill: ${getStyleForProp('socialColor')};
      border-radius: ${getStyleForProp('iconRadius')};
      border: 1px solid ${getStyleForProp('socialBorder')};
    }
  }
  .buttons-block {
    @media only screen and (max-width: 1023px) {
      justify-content: center;
    }
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h2 {
      padding-top: 0;
    }
  }
  & > .control-container + .buttons-block {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const BlockTitle = styled.p`
  color: ${getStyleForProp('blockTitle')};
  margin: 30px 0 0 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const BlocksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 600px;
  min-width: 600px;
  @media only screen and (max-width: 1023px) {
    margin: 30px 0 0 0;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
    min-width: initial;
  }
`

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  position: relative;
  padding: 5px;
  box-sizing: border-box;
  ${({ isEditing }) =>
    isEditing
      ? css`
          &.block-1 {
            z-index: 2;
          }
          &.block-2 {
            z-index: 1;
          }
          &.block-3 {
            z-index: 3;
          }
          &.block-4 {
            z-index: 4;
          }
          @media only screen and (max-width: 650px) {
            &.block-1 {
              z-index: 1;
            }
            &.block-2 {
              z-index: 2;
            }
            &.block-3 {
              z-index: 4;
            }
            &.block-4 {
              z-index: 3;
            }
          }
        `
      : css``}

  @media only screen and (max-width: 650px) {
    &.block-3 {
      order: 1;
    }
  }
  @media only screen and (max-width: 420px) {
    width: 280px;
  }
  .link-section {
    margin: 40px 0 0 0;
    .link {
      color: ${({ theme }) => theme.palette.accentWhite};
    }
    a svg {
      fill: ${({ theme }) => theme.palette.accentWhite};
    }
  }
  &.block-4 {
    & > div > .editable-control-container {
      .styled-controls {
        right: 0;
        left: unset;
      }
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 300px;
  font-size: 0;
  border-radius: ${getStyleForProp('imageRadius')};
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
  .lazy-figure {
    position: relative;
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('imageRadius')};
  }
`

const ControlContainer = styled.div`
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    border-radius: ${getStyleForProp('imageRadius')};
    position: absolute;
    top: 0;
    left: 0;
    ${({ isOverlay, ...rest }) =>
      isOverlay
        ? `
      background: ${getStyleForProp('backgroundDark')(rest)};
    `
        : ``};
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const BlockContent = styled.div`
  width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  & > .control-container {
    width: 100%;
    margin-top: 30px;
    & > p {
      margin-top: 0;
    }
  }
`

const IconWrapper = styled.div`
  user-select: none;
  text-align: center;
`

const Circle = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Icon = styled(LibIcon)``

export default {
  WmCustomContainer,
  StyledContainer,
  HeaderContent,
  Title,
  Paragraph,
  BlocksContainer,
  Block,
  ImageWrapper,
  ControlContainer,
  BlockContent,
  Img,
  IconWrapper,
  Circle,
  BlockTitle,
  Icon
}
