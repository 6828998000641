import styled from 'styled-components'

import { globalColors } from '@renderforest/rf-ui-library/consts/colors'
import { getFontData } from '@website/common/utils'
import { mediaMaxWidth, mediaMinWidth } from '@editor/common/utils/styled'
import { BREAKPOINT_800 } from '@containers/edit/consts'

export const Font = styled.div`
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  margin: ${({ isSelected }) => (isSelected ? '4px -1px' : '5px 0')};
  color: ${({ isSelected }) =>
    isSelected ? globalColors.secondaryDark : globalColors.secondary};
  border: ${({ isSelected }) =>
    isSelected
      ? `2px solid ${globalColors.primarySemiDark}`
      : `1px solid ${globalColors.secondaryExtraLight}`};
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0 3px 6px #387dff33' : 'none'};
  border-radius: 5px;
  cursor: pointer;
  ${mediaMinWidth(BREAKPOINT_800 + 1)`
    &:hover {
      box-shadow: 0 3px 6px #387dff33;
      color: ${globalColors.secondaryDark};
      margin: ${({ isSelected }) => (isSelected ? '4px -1px' : '5px -1px')};
      transform: scale(1.05);
      transition: transform 0.4s ease;
    }
  `};
  ${mediaMaxWidth(BREAKPOINT_800)`
    width: 140px;
    flex-shrink: 0;
    margin: 0 5px;
  `};
`

export const FontTextsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const FontText = styled.p`
  ${({ font }) => {
    const fontData = getFontData(font.split(' '))
    return `
      font-family: ${fontData.name};
      font-weight: ${fontData.variants[0]}
    `
  }};
  text-transform: ${({ letterCase }) =>
    letterCase === 'lowercase' ? 'capitalize' : letterCase};
  ${({ type }) =>
    type === 'primary'
      ? 'font-size: 16px; padding-bottom: 5px'
      : 'font-size: 12px'};
`

export const RangSliderWrapper = styled.div`
  padding-bottom: 5px;
`
