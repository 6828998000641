// @flow
import React, { memo, useCallback, useState } from 'react'
import LazyLoad from 'react-lazy-load'

import * as Styled from './styled'
import type { TThumbnailImageProps } from './types'

const ThumbnailImage = ({ src, imageRatio, onLoad }: TThumbnailImageProps) => {
  const [loaded, setLoadedStatus] = useState(false)

  const handleOnLoad = useCallback(
    e => {
      onLoad && onLoad(e)
      setLoadedStatus(true)
    },
    [onLoad]
  )

  return (
    <Styled.ImageWrapper imageRatio={imageRatio}>
      <LazyLoad offset={500}>
        <Styled.Image
          src={src}
          alt="image"
          className={loaded ? 'img-loaded' : 'img-loading'}
          onLoad={handleOnLoad}
        />
      </LazyLoad>
    </Styled.ImageWrapper>
  )
}

export default memo(ThumbnailImage)
