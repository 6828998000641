import React from 'react'

const Arrow = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="88"
    height="85"
    viewBox="0 0 88 85"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M63.9314 83.3074C72.2107 85.8547 80.7377 83.8245 87.1343 78.9728L85.9256 77.3794C79.9866 81.884 72.1201 83.7343 64.5195 81.3958C60.9859 80.3086 57.4732 78.304 54.2176 75.1938C55.0002 74.2747 55.7517 73.2751 56.4682 72.1928C62.1497 63.6098 63.498 56.397 62.3273 50.8934C61.1562 45.3873 57.4855 41.7272 53.4414 40.286C49.4203 38.853 44.8927 39.597 42.2808 43.1111C39.6911 46.5953 39.2676 52.4329 42.5332 60.599C44.997 66.7601 48.0512 71.593 51.4611 75.2478C46.5585 80.2391 40.6101 82.0515 34.5964 81.4156C27.0759 80.6205 19.3254 75.977 13.3447 68.6014C1.72666 54.2736 -3.05119 29.8032 13.7959 4.42819L14.8953 10.8688C14.9882 11.4132 15.5049 11.7792 16.0493 11.6863C16.5937 11.5933 16.9597 11.0767 16.8668 10.5323L15.3525 1.66058C15.2595 1.11616 14.7429 0.750166 14.1985 0.843087L5.32678 2.35738C4.78237 2.4503 4.41636 2.96696 4.50929 3.51138C4.60221 4.05579 5.11888 4.42179 5.66329 4.32887L12.2024 3.21272C-5.21204 29.3512 -0.397314 54.8297 11.7912 69.861C18.0202 77.5429 26.2182 82.5409 34.3861 83.4045C41.0222 84.1062 47.5543 82.0662 52.867 76.6699C56.3158 79.9573 60.0786 82.122 63.9314 83.3074ZM52.8099 73.7615C49.6328 70.3232 46.7475 65.751 44.3903 59.8564C41.2301 51.9541 41.9077 46.9657 43.886 44.3041C45.842 41.6724 49.3492 40.9508 52.77 42.1699C56.1678 43.3808 59.3461 46.4905 60.3711 51.3095C61.3966 56.131 60.2882 62.7986 54.8004 71.0889C54.1635 72.0511 53.4989 72.9413 52.8099 73.7615Z"
      fill="none"
    />
  </svg>
)

export default Arrow
