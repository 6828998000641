import styled from 'styled-components'

import _RemoveIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'

export const ComponentControls = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  top: 25px;
  z-index: 20;

  @media only screen and (max-width: 768px) {
    justify-content: space-between;
    top: 15px;
    right: 0;
    left: 0;
    padding: 0 20px;
  }
`

export const ControlsMobileWrapper = styled.div`
  display: flex;

  & > div:not(:last-child) {
    margin-right: 10px;
  }
`

export const RemoveIcon = styled(_RemoveIcon)`
  width: 18px;
  height: auto;
  padding: 0 3px;
`
