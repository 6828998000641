/* @flow */
import React, { useContext, useMemo, memo, useRef, useCallback } from 'react'
import S from './RSVP4.styled'
import RSVP4Card from './RSVP4.card'
import { validation } from './RSVP4.consts'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import useEventRegistration from '@website/common/hooks/useEventRegistration'
import RSVPForm from '@website/common/components/RSVPForm'
import RSVPRegistrationPopup from '@website/common/components/RSVPRegistrationPopup'
import SingleButton from '@website/common/components/SingleButton'

const RSVP4 = memo(props => {
  const rightContainerRef = useRef(null)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const {
    data: {
      additionalBlockTitle,
      additionalBlockTitleAlignment,
      additionalBlockParagraph,
      additionalBlockParagraphAlignment,
      cardImage,
      additionalBlockImage,
      dateTitle,
      timeTitle,
      locationTitle,
      titleAlignment,
      dateTitleAlignment,
      timeTitleAlignment,
      locationTitleAlignment,
      dateValueAlignment,
      timeValueAlignment,
      locationValueAlignment,
      paragraphAlignment,
      steps,
      subtitleAlignment,
      backgroundImgUrl,
      backgroundImgDimensions,
      buttonText,
      buttonUrl
    },
    eventData,
    uniqId
  } = props

  const eventCardMetaData = {
    dateTitle,
    timeTitle,
    locationTitle,
    dateTitleAlignment,
    timeTitleAlignment,
    locationTitleAlignment,
    dateValueAlignment,
    timeValueAlignment,
    locationValueAlignment,
    subtitleAlignment,
    titleAlignment,
    paragraphAlignment
  }

  const {
    onClick: onRSVPBtnClick,
    isLoading,
    additionalGuestCount,
    isRegistrationClosedPopupOpen,
    closeRegistrationClosedPopup,
    setGuestLimit
  } = useEventRegistration(eventData.id, eventData.form.additionalGuestNumber)

  const registrationClosedPopupData = eventData.popups.closed

  const isAllStepInfoTitlesEmpty = useMemo(
    () => !steps.some(step => step.stepInfo),
    [steps]
  )

  const scrollToNextBlock = useCallback(() => {
    const element = rightContainerRef.current
    element &&
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      })
  }, [rightContainerRef])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <S.WmCustomContainer>
        <S.Postcard>
          <S.LeftContainer isEditing={isEditing}>
            <S.SectionImageFigure>
              <ControlsWithModalOpener
                actions={Actions.cardImgActions(
                  cardImage.imageUrl,
                  cardImage.bgOverlayTransparency
                )}
                style={{ width: '100%', height: '100%' }}
              >
                <S.ImageFigureBG
                  bgOverlayTransparency={cardImage.bgOverlayTransparency || 0}
                >
                  <Image
                    as={S.Img}
                    alt="Section img"
                    defaultImgSrc={cardImage.imageUrl}
                    sizes={cardImage.imgDimensions}
                  />
                </S.ImageFigureBG>
              </ControlsWithModalOpener>
            </S.SectionImageFigure>
          </S.LeftContainer>
          <S.RightContainer isEditing={isEditing} ref={rightContainerRef}>
            <S.DownButton
              role="button"
              onClick={scrollToNextBlock}
              aria-label="Scroll down button"
            >
              <S.ScrollIcon size="xlarge" name="icon-arrow_small_bottom" />
            </S.DownButton>
            <S.WmCustomContainer>
              <S.Content isEditing={isEditing}>
                <S.AdditionalContent>
                  {additionalBlockImage.imageUrl ? (
                    <S.ImageFigure>
                      <ControlsWithImageCropper
                        actions={Actions.additionalBlockImgActions(
                          additionalBlockImage.imageUrl
                        )}
                        style={{ width: '100%' }}
                      >
                        <Image
                          as={S.Img}
                          alt="Additional img"
                          defaultImgSrc={additionalBlockImage.imageUrl}
                          asProps={{
                            imgCropParams: additionalBlockImage.imgCropParams
                          }}
                          sizes={additionalBlockImage.imgDimensions}
                        />
                      </ControlsWithImageCropper>
                    </S.ImageFigure>
                  ) : (
                    <AddButton toShow onAdd={Actions.addAdditionalBlockImage}>
                      <Icon name="glyph-add" className="icon" size="normal" />
                      {translate('add_image_label')}
                    </AddButton>
                  )}
                  <EditableContent
                    as={S.Title}
                    text={additionalBlockTitle}
                    maxCount={validation.headingMaxChar}
                    className="WM_GLOBAL_heading32"
                    onChange={Actions.changeAdditionalBlockTitle}
                    alignment={additionalBlockTitleAlignment}
                    changeAlignment={
                      Actions.changeAdditionalBlockTitleAlignment
                    }
                  />
                  <EditableContent
                    as={S.Paragraph}
                    text={additionalBlockParagraph}
                    maxCount={validation.paragraphMaxChar}
                    className="WM_GLOBAL_paragraph18"
                    onChange={Actions.changeAdditionalBlockParagraph}
                    alignment={additionalBlockParagraphAlignment}
                    changeAlignment={
                      Actions.changeAdditionalBlockParagraphAlignment
                    }
                  />
                  <SingleButton btnUrl={buttonUrl} btnText={buttonText} />
                </S.AdditionalContent>
                <S.StepsContent isStepsEmpty={steps >= 0} isEditing={isEditing}>
                  <Mapper
                    data={steps}
                    isStepsEmpty={steps >= 0}
                    render={(step: string, idx: number) => (
                      <S.StepsWrap key={idx}>
                        <Show
                          when={[
                            step.stepInfo,
                            step.stepTitle,
                            step.stepParapgraph
                          ]}
                        >
                          <ControlsWithModalOpener
                            alignment="right"
                            style={{ width: '100%' }}
                            actions={Actions.stepActions({
                              idx,
                              showRemove: steps.length > validation.steps.min
                            })}
                          >
                            <S.StepItemWrap>
                              <S.StepInfoWrapper
                                isEditing={isEditing}
                                isAllStepInfoTitlesEmpty={
                                  isAllStepInfoTitlesEmpty
                                }
                              >
                                <EditableContent
                                  text={step.stepInfo}
                                  as={S.StepInfo}
                                  required={
                                    !step.stepTitle && !step.stepParapgraph
                                  }
                                  alignment={step.stepInfoAlignment}
                                  maxCount={validation.stepInfoMaxChar}
                                  className="WM_GLOBAL_paragraph18"
                                  onChange={(newStepInfo: string) => {
                                    Actions.changeStepInfo(newStepInfo, idx)
                                  }}
                                  changeAlignment={(alignment: string) =>
                                    Actions.changeStepInfoAlignment(
                                      alignment,
                                      idx
                                    )
                                  }
                                />
                              </S.StepInfoWrapper>
                              <S.StepContent
                                isEditing={isEditing}
                                isAllStepInfoTitlesEmpty={
                                  isAllStepInfoTitlesEmpty
                                }
                                className="step-right-content"
                              >
                                <S.Circle isEditing={isEditing} />
                                <EditableContent
                                  as={S.StepTitle}
                                  text={step.stepTitle}
                                  required={
                                    !step.stepInfo && !step.stepParapgraph
                                  }
                                  alignment={step.stepTitleAlignment}
                                  maxCount={validation.headingMaxChar}
                                  className="WM_GLOBAL_paragraph18"
                                  onChange={(title: string) =>
                                    Actions.changeStepTitle(title, idx)
                                  }
                                  changeAlignment={(alignment: string) =>
                                    Actions.changeStepTitleAlignment(
                                      alignment,
                                      idx
                                    )
                                  }
                                />
                                <EditableContent
                                  as={S.StepsParagraph}
                                  text={step.stepParapgraph}
                                  required={!step.stepInfo && !step.stepTitle}
                                  alignment={step.stepParagraphAlignment}
                                  maxCount={validation.stepParagraphMaxChar}
                                  className="WM_GLOBAL_paragraph18"
                                  onChange={(paragraph: string) => {
                                    Actions.changeStepParagraph(paragraph, idx)
                                  }}
                                  changeAlignment={(alignment: string) =>
                                    Actions.changeStepParagraphAlignment(
                                      alignment,
                                      idx
                                    )
                                  }
                                />
                              </S.StepContent>
                            </S.StepItemWrap>
                          </ControlsWithModalOpener>
                        </Show>
                      </S.StepsWrap>
                    )}
                  />
                  <AddButton
                    onAdd={Actions.addBlock}
                    toShow={steps.length < validation.steps.max}
                    style={{
                      marginTop: '2rem'
                    }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_step_label')}
                  </AddButton>
                </S.StepsContent>
                <RSVP4Card
                  detailsData={eventData.details}
                  metaData={eventCardMetaData}
                  onRSVPBtnClick={onRSVPBtnClick}
                  isLoading={isLoading}
                />
              </S.Content>
            </S.WmCustomContainer>
            <RSVPForm
              compId={uniqId}
              eventId={eventData.id}
              formData={eventData.form}
              popupsData={eventData.popups}
              detailsData={eventData.details}
              additionalGuestCount={additionalGuestCount}
              setGuestLimit={setGuestLimit}
            />
            {!isEditing ? (
              <RSVPRegistrationPopup
                isPopupOpen={isRegistrationClosedPopupOpen}
                title={registrationClosedPopupData.title.text}
                paragraph={registrationClosedPopupData.paragraph.text}
                onClose={closeRegistrationClosedPopup}
              />
            ) : null}
          </S.RightContainer>
        </S.Postcard>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default RSVP4
