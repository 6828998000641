/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        return
      case 'ADD_SLIDE': {
        const activeIdx = action.value

        draft.blocks = [
          ...draft.blocks.slice(0, activeIdx + 1),
          initialState.blocks[0],
          ...draft.blocks.slice(activeIdx + 1)
        ]
        //draft.blocks.push(initialState.blocks[0])
        break
      }
      case 'REMOVE_IMAGE':
        draft.blocks.splice(action.value.idx, 1)
        break
      case 'CHANGE_NAME':
        {
          const { idx, newName } = action.value
          draft.blocks[idx].name = newName
        }
        break
      case 'CHANGE_POSITION':
        {
          const { idx, newPosition } = action.value
          draft.blocks[idx].position = newPosition
        }
        break
      case 'CHANGE_SLOGAN':
        {
          const { idx, newSlogan } = action.value
          draft.blocks[idx].slogan = newSlogan
        }
        break
      case 'CHANGE_IMAGE':
        {
          const { idx, url, dimensions } = action.value
          draft.blocks[idx].imgUrl = url
          draft.blocks[idx].imgDimensions = dimensions
          draft.blocks[idx].imgCropParams = {}
        }
        break
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx } = action.value
          draft.blocks[idx].imgCropParams = imgCropParams
        }
        break
      case 'ADD_SOCIAL':
        draft.blocks[action.value].socials.push(
          initialState.blocks[0].socials[0]
        )
        break
      case 'REMOVE_SOCIAL': {
        const { idx, blockIdx } = action.value
        draft.blocks[blockIdx].socials.splice(idx, 1)
        break
      }
      case 'CHANGE_SOCIAL_URL':
        {
          const { link, idx, blockIdx } = action.value
          draft.blocks[blockIdx].socials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, blockIdx, iconName } = action.value
          draft.blocks[blockIdx].socials[idx].icon = iconName
        }
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NAME_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].nameAlignment = alignment
        break
      }
      case 'CHANGE_POSITION_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].positionAlignment = alignment
        break
      }
      case 'CHANGE_SLOGAN_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].sloganAlignment = alignment
        break
      }
    }
  })
}
