import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    border: p => p.primaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    cardBackground: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    border: p => p.primaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.4)`,
    cardBackground: p => p.accentWhite,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    border: p => p.primaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.4)`,
    cardBackground: p => p.accentWhite,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  }
}
