import { storeInHistory, freezeProjectData } from '@actions/project'

export default ({ dispatch }) =>
  next =>
  action => {
    action.undoable && dispatch(storeInHistory())
    const result = next(action)
    action.undoable && dispatch(freezeProjectData())
    return result
  }
