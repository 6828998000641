/* @flow */
import React, { useContext, useCallback, useRef, useEffect, memo } from 'react'
import S from './Feature36.styled'
import { validation } from './Feature36.consts'
import ActionGetter from './Actions'
import Arrow from './Arrow'
import { getOffsetXByMouseX, getOffsetYByMouseY } from '@website/common/utils'
import Show from '@website/common/components/Show/Show'
import Controls from '@website/common/components/Controlled/Controls'
import If from '@website/common/components/Conditional'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import useOnScreen from '@website/common/hooks/useOnScreen'

const Feature36 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      images,
      imageUrl,
      imgDimensions,
      imgCropParams,
      additionalText1,
      additionalUrl1,
      additionalText2,
      additionalUrl2,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      hasArrow,
      paragraph1,
      paragraph1Alignment,
      isAnimated
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const animatedRef = useRef(null)
  const isOnScreen = useOnScreen(animatedRef)

  const imagesCount = images.length
  const isOnlyLeft = !title && !paragraph && !additionalText2 && !additionalUrl2

  const handleMouseMove = useCallback(
    event => {
      if ((!isEditing && !isAnimated) || isEditing || window.innerWidth <= 1024)
        return

      const targetElement = animatedRef.current
      const pointerClientX = event.clientX
      const pointerClientY = event.clientY

      const { width, height } = targetElement.getBoundingClientRect()

      const img1 = targetElement.getElementsByClassName('feature36image-1')[0]
      const img2 = targetElement.getElementsByClassName('feature36image-2')[0]
      const img3 = targetElement.getElementsByClassName('feature36image-3')[0]

      img1 &&
        (img1.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          7
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 4.8)}px)`)
      img2 &&
        (img2.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          2.5
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 3)}px)`)
      img3 &&
        (img3.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          1.5
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 6)}px)`)
    },
    [animatedRef, isAnimated]
  )

  useEffect(() => {
    if (animatedRef.current) {
      animatedRef.current.addEventListener('mousemove', handleMouseMove)
    }
    return () => {
      animatedRef.current?.removeEventListener('mousemove', handleMouseMove)
    }
  }, [animatedRef.current, handleMouseMove])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      ref={animatedRef}
      isAnimated={isAnimated}
      isOnScreen={isOnScreen}
    >
      <S.WmCustomContainer
        isOnlyLeft={isOnlyLeft}
        isEditing={isEditing}
        imagesCount={imagesCount}
      >
        <S.TextImageContent isEditing={isEditing}>
          <S.ImagesWrap>
            <S.MainImageFigure isEditing={isEditing}>
              <If
                condition={hasArrow}
                then={() => (
                  <S.ArrowWrapper isEditing={isEditing}>
                    <Controls
                      actions={Actions.removeArrow}
                      style={{ width: '100%', height: '100%' }}
                    >
                      <Arrow />
                    </Controls>
                  </S.ArrowWrapper>
                )}
                otherwise={() => (
                  <AddButton
                    onAdd={Actions.addArrow}
                    style={{
                      margin: '0px'
                    }}
                    type="icon"
                    large
                    toShow
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                  </AddButton>
                )}
              />
              <ControlsWithImageCropper
                actions={Actions.imageActions(null, imageUrl)}
                style={{ width: '100%' }}
              >
                <Image
                  as={S.MainImg}
                  defaultImgSrc={imageUrl}
                  sizes={imgDimensions}
                  alt="Section image"
                  asProps={{ imgCropParams }}
                />
              </ControlsWithImageCropper>
            </S.MainImageFigure>
            <S.ImagesContainer
              isEditing={isEditing}
              imagesCount={imagesCount}
              isAnimated={isAnimated}
              isOnScreen={isOnScreen}
            >
              <Mapper
                data={images}
                render={(image, idx) => {
                  return (
                    <S.ImageWrapper imagesCount={imagesCount}>
                      <S.ControlContainer
                        className={`feature36image-${idx + 1}`}
                      >
                        <ControlsWithImageCropper
                          actions={Actions.imageActions(idx, image.imageUrl)}
                          style={{ width: '100%' }}
                          key={idx}
                        >
                          <Image
                            as={S.Img}
                            sizes={image.imgDimensions}
                            defaultImgSrc={image.imageUrl}
                            asProps={{
                              imgCropParams: image.imgCropParams
                            }}
                            alt="CTA images"
                          />
                        </ControlsWithImageCropper>
                      </S.ControlContainer>
                    </S.ImageWrapper>
                  )
                }}
              />
            </S.ImagesContainer>
            <AddButton
              onAdd={() => Actions.addBlock(images.length)}
              toShow={imagesCount < validation.images.max}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_image_label')}
            </AddButton>
          </S.ImagesWrap>
          <Show when={[paragraph1, additionalText1, additionalUrl1]}>
            <S.AdditionalBlockWrap
              imagesCount={imagesCount}
              isEditing={isEditing}
            >
              <S.AdditionalBlock imagesCount={imagesCount}>
                <EditableContent
                  as={S.AdditionalParagraph}
                  text={paragraph1}
                  required={!paragraph && !title}
                  alignment={paragraph1Alignment}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={Actions.changeParagraph1}
                  changeAlignment={Actions.changeParagraph1Alignment}
                />
                <AdditionalLink
                  idx={0}
                  additionalLinkText={additionalText1}
                  additionalLinkUrl={additionalUrl1}
                />
              </S.AdditionalBlock>
            </S.AdditionalBlockWrap>
          </Show>
        </S.TextImageContent>
        <Show when={[!isOnlyLeft]}>
          <S.ContentWrap isEditing={isEditing}>
            <EditableContent
              as={S.Title}
              text={title}
              alignment={titleAlignment}
              required={!paragraph && !paragraph1}
              maxCount={validation.titleMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              as={S.Paragraph}
              text={paragraph}
              required={!title && !paragraph1}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <AdditionalLink
              idx={1}
              additionalLinkText={additionalText2}
              additionalLinkUrl={additionalUrl2}
            />
          </S.ContentWrap>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature36
