/* @flow */
import {
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import { addIcon, addBlock } from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockCount = (count: string, blockIdx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_COUNT',
      value: { blockIdx, count }
    })

  const changeBlockText = (text: string, blockIdx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_TEXT',
      value: { blockIdx, text }
    })

  const changeBlockCountAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_COUNT_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, blockIdx }
    })
  }

  return {
    addIcon: idx => dispatcher(addIcon(idx)),
    addBlock: () => dispatcher(addBlock()),
    changeBlockText,
    changeBlockCount,
    changeBlockCountAlignment,
    changeBlockTitleAlignment,
    iconActions: iconActionMaker(dispatcher),
    featureActions: removeBlockActionMaker(dispatcher)
  }
}
