//@flow
import React, { memo, useMemo } from 'react'
import { ResponsiveContainer, AreaChart, Area } from 'recharts'

import type { TProps } from '../types'
import * as Styled from './styled'

const fillData = data => {
  const filledData = Array(12 - data.length).fill({ value: '0.00' })
  return [...filledData, ...data]
}

export const TrendChart = ({ data }: TProps) => {
  const isZero = data.split(',').every(value => value === '0.00')

  const chartData = useMemo(() => {
    const splittedData = data.split(',').map(value => ({ value }))
    return splittedData.length < 12 ? fillData(splittedData) : splittedData
  }, [data])

  return isZero ? (
    <Styled.ZeroChart />
  ) : (
    <Styled.ChartWrapper>
      <ResponsiveContainer>
        <AreaChart data={chartData}>
          <Area
            type="linear"
            dataKey="value"
            stroke="rgb(80, 174, 244)"
            fill="rgba(80, 174, 244, 0.2)"
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </Styled.ChartWrapper>
  )
}

export default memo(TrendChart)
