/* @flow */
import {
  iconActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  addIcon,
  changeTitle,
  removeBlock
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockContent = (idx, newContent) => {
    dispatcher({
      type: 'CHANGE_BLOCK_CONTENT',
      value: { idx, newContent }
    })
  }

  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addIcon,
    addBlock: () => dispatcher(addBlock()),
    removeBlock,
    changeTitle,
    changeBlockTitle,
    changeBlockContent,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    getIconActions: iconActionMaker(dispatcher),
    featureActions: removeBlockActionMaker(dispatcher)
  }
}
