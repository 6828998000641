import styled, { css } from 'styled-components'

import { scrollBarStyles } from '@editor/common/styles'
import { white } from '@website/common/styles/colors'

export const ComponentCategories = styled.div`
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: #ffffff;
  box-sizing: border-box;
  border-right: 1px solid #8493bd;
  box-shadow: 0 6px 10px #387dff33;
`

export const Categories = styled.ul`
  ${scrollBarStyles};
  position: relative;
  overflow-y: scroll;
  flex-grow: 1;

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          overflow-y: hidden;
          pointer-events: none;
          &::after {
            content: '';
            background: #ffffff;
            opacity: 0.5;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;
          }
        `
      : ''}
`

export const Category = styled.li`
  position: relative;
  font-size: 14px;
  color: ${({ isActive }) => (isActive ? '#252E48' : '#545f7e')};
  font-weight: ${({ isActive }) => (isActive ? 500 : 400)};
  padding: 16px 25px;
  background-color: ${({ isActive }) => (isActive ? '#eef5ff' : '#ffffff')};
  box-sizing: border-box;
  list-style-type: none;
  cursor: pointer;
  user-select: none;
  p {
    transition: transform 0.2s ease-out;
  }

  &:not(:last-child) {
    border-bottom: 1px solid rgba(132, 147, 189, 0.3);
  }

  &:hover {
    color: #252e48;
    background-color: #eef5ff;
    p {
      transform: translateX(2px);
    }
  }
`
export const SpecialCategory = styled.div`
  width: calc(100% + 1px);
  height: 53px;
  margin-left: 1px;
  background-image: linear-gradient(
    90deg,
    #d774da 25%,
    #5b5fe3 50%,
    #db30b5 75%,
    #ff7373 100%
  );
  background-size: 200% auto;
  box-sizing: border-box;
  cursor: pointer;
  user-select: none;
  padding: 1px;
  animation: shine 4s linear infinite;
  animation-play-state: paused;

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${white};
    font-weight: 600;
    font-size: 14px;

    > img {
      margin-right: 10px;
    }

    > span {
      background: linear-gradient(90deg, #da75da 0%, #405be5 106.25%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }

  ${({ isActive }) =>
    isActive
      ? css`
          padding: 2px;
        `
      : ''}

  &:hover {
    padding: 2px;
    animation-play-state: running;
  }

  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
`
