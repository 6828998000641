//@flow
import React, { useRef, memo, useContext } from 'react'

import { TPayPalFormProps } from './types'
import PayPalButton from './PayPalButton'
import { getOriginalValues } from './utils'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext } from '@contexts'
import ActionGetter from './Actions'

const PayPalForm = ({ data, compId, reducerProps }: TPayPalFormProps) => {
  const {
    email,
    type,
    title,
    style,
    productName,
    productId,
    productPrice,
    currency,
    shippingFee,
    organizationName,
    donationId,
    amount
  } = data

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  const formRef = useRef()
  const isSelling = type === 'selling'

  const formType = isSelling ? '_xclick' : '_donations'
  const _amount = productPrice || amount || ''
  const itemNumber = productId || donationId
  const itemName = productName || organizationName

  const onSubmit = e => {
    e.preventDefault()

    const form = formRef.current
    const data = new FormData(form)
    const originalValues = getOriginalValues(data)

    Object.entries(originalValues).forEach(([name, value]) => {
      if (data.get(name) !== value) {
        form.elements.namedItem(name).value = value
      }
    })

    form.submit()
  }

  return (
    <ControlsWithModalOpener
      actions={Actions.getPayPalButtonActions(data, compId, reducerProps)}
    >
      <form
        ref={formRef}
        action="https://www.paypal.com/cgi-bin/webscr"
        method="add"
        target="_blank"
        onSubmit={onSubmit}
      >
        <input type="hidden" name="cmd" value={formType} />
        <input type="hidden" name="business" value={email} />
        {itemName ? (
          <input type="hidden" name="item_name" value={itemName} />
        ) : null}
        {itemNumber ? (
          <input type="hidden" name="item_number" value={itemNumber} />
        ) : null}
        <input type="hidden" name="amount" value={_amount} />
        {currency ? (
          <input type="hidden" name="currency_code" value={currency} />
        ) : null}
        {shippingFee ? (
          <input type="hidden" name="shipping" value={shippingFee} />
        ) : null}
        <PayPalButton buttonStyle={style} buttonText={title} />
      </form>
    </ControlsWithModalOpener>
  )
}

export default memo(PayPalForm)
