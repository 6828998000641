import styled from 'styled-components'
import { Button } from '../../../styled'

export const Footer = styled.div`
  position: relative;
  width: 100%;
  height: 65px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  border-top: ${({ hasBorder }) => (hasBorder ? '1px solid #edeff6' : 'none')};
  background: #ffffff;
  border-radius: 0 0 10px 10px;
  @media only screen and (max-width: 830px) {
    justify-content: center;
  }
`

export const SavingInfo = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  color: #545f7e;
  margin-left: 8px;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  max-width: 430px;
  svg {
    margin-right: 10px;
  }
  @media only screen and (max-width: 830px) {
    display: none;
  }
`

export const DiscardButton = styled(Button)`
  color: #387dff;
  background: #ffffff;
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
`
export const SaveButton = styled(Button)`
  color: #ffffff;
  text-transform: uppercase;
  background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
    0% no-repeat padding-box;
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #0052e080;
    transition: all 0.2s ease-out;
  }
`
