//@flow
import React, { useCallback, useContext } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TMovementControlProps } from './types'
import { Control } from '@editor/common/components/ComponentControls/components'
import MoveUpIconDesktop from '@editor/common/assets/svgr-icons/move-up-desktop.svg'
import MoveDownIconDesktop from '@editor/common/assets/svgr-icons/move-down-desktop.svg'
import MoveUpIconMobile from '@editor/common/assets/svgr-icons/move-up-mobile.svg'
import MoveDownIconMobile from '@editor/common/assets/svgr-icons/move-down-mobile.svg'
import { toBottom, toTop } from '@actions/project'
import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const MovementControl = ({
  idx,
  isUpMovementAllowed,
  isDownMovementAllowed,
  toBottom,
  toTop
}: TMovementControlProps) => {
  const isMobile = useContext(MobileDetectContext)
  const onMoveUp = useCallback(() => toTop(idx), [idx])
  const onMoveDown = useCallback(() => toBottom(idx), [idx])

  return (
    <Styled.MovementControl
      isDouble={isUpMovementAllowed && isDownMovementAllowed}
    >
      {isUpMovementAllowed ? (
        <Control
          IconComp={isMobile ? MoveUpIconMobile : MoveUpIconDesktop}
          tooltipText={isMobile ? '' : translate('move_up_label')}
          onClick={onMoveUp}
        />
      ) : null}
      {isDownMovementAllowed ? (
        <Control
          IconComp={isMobile ? MoveDownIconMobile : MoveDownIconDesktop}
          tooltipText={isMobile ? '' : translate('move_down_label')}
          onClick={onMoveDown}
        />
      ) : null}
    </Styled.MovementControl>
  )
}

const mapDispatchToProps = {
  toTop,
  toBottom
}

export default connect(null, mapDispatchToProps)(MovementControl)
