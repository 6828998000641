import styled from 'styled-components'

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 10px 0;
  justify-content: center;
  box-shadow: 0px -4px 8px #3271e61a;
  z-index: 1;
  border-top: #f5fafe;

  & > button:first-child {
    margin-right: 30px;
  }
`
