/* @flow */
import styled, { css } from 'styled-components'
import { hexToRgbA } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Video5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  padding-top: ${({ isEditing }) => (isEditing ? '150px' : '80px')};
  padding-bottom: ${({ isButton, isEditing }) =>
    !isEditing && !isButton ? '130px' : '80px'};
  ${getBackgroundStyles(THEME)};
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    padding-top: 80px;
  }
  @media only screen and (max-width: 992px) {
    padding-bottom: ${({ isButton, isEditing }) =>
      !isEditing && !isButton ? '110px' : '80px'};
  }
  @media only screen and (max-width: 650px) {
    padding-top: 60px;
    padding-bottom: ${({ isButton, isEditing }) =>
      !isEditing && !isButton ? '70px' : '60px'};
  }
`
const WmCustomContainer = styled(WMContainer)`
  width: 100%;
  .buttons-block {
    justify-content: center;
    margin: 75px auto 0 auto;
    @media only screen and (max-width: 992px) {
      margin-top: 40px;
    }
    @media only screen and (max-width: 650px) {
      margin-top: 25px;
    }
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 100px auto;
  width: 100%;
  max-width: 854px;
  position: relative;
  z-index: 4;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 70px;
  }
  @media only screen and (max-width: 768px) {
    max-width: initial;
  }
  @media only screen and (max-width: 650px) {
    margin-bottom: 50px;
  }
  .control-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      padding-top: 0;
    }
  }
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const PortfolioImg = styled.img`
  border-radius: ${getStyleForProp('cardRadius')};
`

const VideoControlWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 854px;
  @media only screen and (max-width: 1024px) {
    max-width: 640px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 600px) {
    max-width: 300px;
  }
  > .control-container {
    z-index: 2;
  }
`

const VideoContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: hidden;
  position: relative;
  z-index: 1;
`

const VideoContainer = styled.div`
  width: 100%;
  max-width: 854px;
  margin: 0 auto;
  position: relative;
  > svg {
    margin: auto;
    background: none;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 650px) {
      width: 95%;
      height: 95%;
    }
  }
  > svg.animated-2 {
    path {
      fill: ${props => hexToRgbA(getStyleForProp('blob')(props), 0.5)};
    }
  }
  > svg:nth-child(1) {
    transform: scale(2.45, 2.3) rotate(-84deg) translateY(-4%);
    z-index: 1;
    @media only screen and (max-width: 650px) {
      transform: scale(2.45, 2.3) rotate(-84deg) translateY(-2%);
    }
    defs linearGradient stop:nth-child(1) {
      stop-color: ${getStyleForProp('blobGradientEnd')};
    }
    defs linearGradient stop:nth-child(2) {
      stop-color: ${getStyleForProp('blobGradientStart')};
    }
  }
  > svg:nth-child(2) {
    transform: scale(2.5, 2) rotate(131deg) translateY(-3%);
    @media only screen and (max-width: 650px) {
      transform: scale(2.5, 2) rotate(131deg) translateY(-6%);
    }
  }
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    padding-top: 56.25%;
  }
  .iframe-video {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  VideoContainer,
  Title,
  Description,
  PortfolioImg,
  VideoContent,
  VideoControlWrapper,
  VideoWrap,
  TextContent
}
