/* @flow */

import React, { useContext, memo } from 'react'
import S from './Post3.styled'
import { validation } from './Post3.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import If from '@website/common/components/Conditional'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Post3 = memo(props => {
  const {
    data: {
      blocks,
      title = '',
      paragraph = '',
      titleAlignment,
      paragraphAlignment
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <Show when={[title, paragraph]}>
        <S.TextContent>
          <Show when={[title]}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.titleMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
          </Show>
          <Show when={[paragraph]}>
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </Show>
        </S.TextContent>
      </Show>
      <S.WmCustomContainer>
        <S.Row>
          <Mapper
            data={blocks}
            render={(item: string, idx: number) => {
              return (
                <React.Fragment key={idx}>
                  <S.Col>
                    <Mapper
                      data={item}
                      render={(block: string, blockIdx: number) => {
                        return (
                          <S.ItemWrap
                            isEven={blockIdx % 2 === 1}
                            isEditing={isEditing}
                            key={blockIdx}
                          >
                            <S.ItemImageWrapper>
                              <S.ControlContainer>
                                <ControlsWithImageCropper
                                  actions={Actions.imageActions(
                                    idx,
                                    blockIdx,
                                    block.imgUrl
                                  )}
                                >
                                  <Image
                                    as={S.Img}
                                    defaultImgSrc={block.imgUrl}
                                    sizes={block.imgDimensions}
                                    alt="Post image"
                                    asProps={{
                                      imgCropParams: block.imgCropParams
                                    }}
                                  />
                                </ControlsWithImageCropper>
                              </S.ControlContainer>
                            </S.ItemImageWrapper>
                            <Show
                              when={[
                                block.title,
                                block.paragraph,
                                block.additionalText && block.additionalUrl
                              ]}
                            >
                              <S.ItemTextWrapper isEven={blockIdx % 2 === 1}>
                                <Show when={[block.title]}>
                                  <EditableContent
                                    text={block.title}
                                    alignment={block.titleAlignment}
                                    as={S.BlockTitle}
                                    maxCount={validation.blockTitleMaxChar}
                                    className="WM_GLOBAL_heading32"
                                    onChange={(newTitle: string) =>
                                      Actions.changeBlockTitle(
                                        idx,
                                        blockIdx,
                                        newTitle
                                      )
                                    }
                                    changeAlignment={(alignment: string) =>
                                      Actions.changeBlockTitleAlignment(
                                        idx,
                                        blockIdx,
                                        alignment
                                      )
                                    }
                                  />
                                </Show>
                                <Show when={[block.paragraph]}>
                                  <EditableContent
                                    text={block.paragraph}
                                    alignment={block.paragraphAlignment}
                                    as={S.BlockParagraph}
                                    maxCount={validation.blockParagraphMaxChar}
                                    className="WM_GLOBAL_paragraph16"
                                    onChange={(newParagraph: string) =>
                                      Actions.changeItemParagraph(
                                        idx,
                                        blockIdx,
                                        newParagraph
                                      )
                                    }
                                    changeAlignment={(alignment: string) =>
                                      Actions.changeBlockParagraphAlignment(
                                        idx,
                                        blockIdx,
                                        alignment
                                      )
                                    }
                                  />
                                </Show>
                                <If
                                  condition={
                                    block.additionalText && block.additionalUrl
                                  }
                                  otherwise={() => (
                                    <AddButton
                                      onAdd={() =>
                                        dispatcher(
                                          Actions.addLink(idx, blockIdx)
                                        )
                                      }
                                      toShow={block.additionalText === ''}
                                    >
                                      <Icon
                                        name="glyph-add"
                                        className="icon"
                                        size="normal"
                                      />
                                      {translate('add_link_label')}
                                    </AddButton>
                                  )}
                                  then={() => (
                                    <S.LinkWrap>
                                      <ControlsWithModalOpener
                                        actions={Actions.linkActions(
                                          idx,
                                          blockIdx,
                                          {
                                            text: block.additionalText,
                                            url: block.additionalUrl
                                          }
                                        )}
                                      >
                                        <LinkWrapper
                                          url={block.additionalUrl}
                                          text={block.additionalText}
                                          linkProps={{
                                            className:
                                              'WM_GLOBAL_paragraph14 learn-more-link'
                                          }}
                                        >
                                          <S.Icon
                                            size="large"
                                            name="keyboard_arrow_right"
                                            className="arrow-right"
                                          />
                                        </LinkWrapper>
                                      </ControlsWithModalOpener>
                                    </S.LinkWrap>
                                  )}
                                />
                              </S.ItemTextWrapper>
                            </Show>
                          </S.ItemWrap>
                        )
                      }}
                    />
                  </S.Col>
                  <AddButton
                    onAdd={() => {
                      Actions.removeBlock(
                        idx,
                        blocks.length > validation.blocks.min
                      )
                    }}
                    toShow={blocks.length > validation.blocks.min}
                    style={{
                      marginTop: '40px',
                      display: 'inline-flex',
                      alignItems: 'center'
                    }}
                  >
                    <Icon name="glyph-remove" className="icon" size="normal" />
                    {translate('remove_block_label')}
                  </AddButton>
                </React.Fragment>
              )
            }}
          />
          <AddButton
            onAdd={Actions.addBlock}
            toShow={blocks.length < validation.blocks.max}
            style={{ marginTop: '20px' }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.Row>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Post3
