/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PayPal1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const WMCustomContainer = styled(WMContainer)`
  max-width: 1200px;
  flex-direction: column;
`

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
        `
      : css``};
  @media only screen and (max-width: 700px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding-top: 70px;
          `
        : css``};
  }
`

const ContentConainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${({ theme }) =>
    theme.orientation === 'LEFT' ? 'row' : 'row-reverse'};
  @media (max-width: 1024px) {
    flex-direction: ${({ theme }) =>
      theme.orientation === 'LEFT' ? 'column-reverse' : 'column'};
  }
`

const LeftContainer = styled.div`
  width: 100%;
  max-width: 600px;
  box-sizing: border-box;
  ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? 'padding-right: 50px;'
      : 'padding-left: 50px;'};

  .add-button-wrap {
    text-align: left;
    margin-bottom: 0px;
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            padding-right: 0px;
            margin-top: 40px;
          `
        : css`
            padding-left: 0px;
            margin-bottom: 40px;
          `};
  }
  .control-container + h1 {
    margin-top: 20px;
  }

  & > .control-container + .control-container {
    margin-top: 20px;
  }

  img {
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const RightContainer = styled.div`
  width: 100%;
`

const ImageWrapper = styled.div`
  width: 100%;
`

const Logo = styled.img`
  width: 150px;
  height: 80px;
  object-fit: contain;
  display: block;
  border-radius: 0;
  ${({ isEditing }) => (!isEditing ? 'margin-bottom: 20px;' : '')}
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  ${({ isEditing }) => (isEditing ? 'padding-top: 66.667%;' : '')}
  border-radius: 50%;
  .control-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100% !important;
    height: 100%;
  }
  .LazyLoad {
    overflow: hidden;
    border-radius: ${getStyleForProp('cardRadius')};
  }
  .lazy-figure {
    padding-top: 66.667%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('title')};
  margin-bottom: 30px !important;
  ${getTextStyleByAlignment};
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 15px;
  row-gap: 15px;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    column-gap: 7px;
  }
`
const PriceCount = styled.p`
  color: ${getStyleForProp('price')};
  margin-bottom: 0 !important;
  opacity: 1;
  display: flex;
`

const PrevPriceCount = styled.p`
  color: ${getStyleForProp('price')};
  text-decoration: line-through;
  margin-bottom: 0 !important;
  opacity: 1;
`

const PayButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;

  & form > button {
    margin: 0 !important;
  }

  & .add-button-wrap > div {
    margin-top: 0 !important;
  }

  .link-wrapper {
    color: ${getStyleForProp('link')};
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
`

const LeftContainerParagraph = styled.p`
  margin-bottom: 30px;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`
const IconContainer = styled.div`
  min-height: ${({ hasBlocksIcon }) => (hasBlocksIcon ? '44px' : '0')};
  margin-bottom: ${({ hasBlocksIcon }) => (hasBlocksIcon ? '10px' : '0')};
`

const PayPalItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: white;
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: 0 9px 32px 0 ${({ theme }) => theme.palette.tertiaryDark + '25'};
  padding: 5px 15px 15px;
  column-gap: 30px;
  margin-top: 40px;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
  &:empty {
    display: none;
  }
  & > .control-container {
    flex: 1 1 calc(25% - 30px);
    @media only screen and (max-width: 1024px) {
      flex: 1 1 calc(50% - 30px);
    }
    @media only screen and (max-width: 457px) {
      flex: 1 1 100%;
    }
    &:focus,
    &:hover {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.75) !important;
    }
    & p,
    h2 {
      outline-color: #000;
    }
    & p:focus,
    & h2:focus,
    & > div:focus,
    & > div:focus,
    & p:hover,
    & h2:hover {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 1) !important;
    }
  }
  .add-button-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 calc(25% - 30px);
    @media only screen and (max-width: 1024px) {
      flex: 1 1 calc(50% - 30px);
    }
    @media only screen and (max-width: 457px) {
      flex: 1 1 100%;
    }
    & > div {
      margin-top: 0 !important;
    }
  }
  & > .add-button-wrap {
    min-height: 200px;
  }
`

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: center;
  padding: 15px;
  margin-bottom: 0px;
  flex: 1 1 calc(25% - 60px);
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h2,
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  .control-container:nth-last-child(1) {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1024px) {
    flex: 1 1 calc(50% - 60px);
    &:nth-child(3)&:nth-last-child(1) {
      max-width: 50%;
    }
  }
  @media only screen and (max-width: 457px) {
    flex: 1 1 100%;
    &:nth-child(3)&:nth-last-child(1) {
      max-width: 100%;
    }
  }
  .add-button-wrap {
    margin-bottom: 20px;
    max-height: 48px;
  }
`

const Count = styled.p`
  color: ${getStyleForProp('iconText')};
  ${getTextStyleByAlignment};
  font-weight: 800;
  width: 100%;
  margin-top: 20px;
`
const ItemDescription = styled.p`
  width: 100%;
  margin-top: 10px;
  color: ${getStyleForProp('blockText')};
  ${getTextStyleByAlignment};
`
const ItemName = styled.p`
  width: 100%;
  margin-top: auto;
  color: ${getStyleForProp('blockText')};
  ${getTextStyleByAlignment};
`

const ItemTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
  margin-top: 5px;
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getStyleForProp('iconText')};

  & > div {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      width: 28px;
      height: 28px;
      fill: ${getStyleForProp('link')};
    }
  }
`

const IconWrapper = styled.div`
  user-select: none;
`

const Icon = styled(LibIcon).attrs(props => ({
  color: getStyleForProp('icon')(props)
}))``

export default {
  StyledContainer,
  WMCustomContainer,
  ContentConainer,
  LeftContainer,
  LeftContainerParagraph,
  PayButtonsContainer,
  RightContainer,
  ImageWrapper,
  ControlContainer,
  PayPalItemWrap,
  PriceContainer,
  PriceCount,
  Img,
  Logo,
  PrevPriceCount,
  ItemTitle,
  Title,
  Count,
  ItemDescription,
  ItemName,
  IconContainer,
  ItemWrap,
  IconWrapper,
  Circle,
  Icon
}
