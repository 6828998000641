export const validation = {
  headingMaxChar: 150,
  paragraphMaxChar: 200,
  blockTitleMaxChar: 20,
  blockSubTitleMaxChar: 70,
  badgeMaxChar: 40,
  features: {
    min: 1,
    max: 3
  }
}
