/* @flow */
import {
  clientLogoActionMaker,
  imageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const addSliderLogo = (idx: number) => {
    dispatcher({
      type: 'ADD_CLIENT_LOGO',
      value: idx
    })
  }
  const changeSlideContent = (newContent: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_CONTENT',
      value: { newContent, idx }
    })
  }

  const addClientImage = (idx: number) => {
    dispatcher({
      type: 'ADD_CLIENT_AVATAR',
      value: idx
    })
  }
  
  const changeClientName = (newClientName: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_CLIENT_NAME',
      value: { newClientName, idx }
    })
  }

  const changeClientPosition = (newClientPosition: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_CLIENT_POSITION',
      value: { newClientPosition, idx }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeClientNameAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_CLIENT_NAME_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeClientPositionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_CLIENT_POSITION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeSlideContentAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_CONTENT_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: _addBlock,
    changeTitle: _changeTitle,
    addSliderLogo,
    addClientImage,
    changeParagraph: _changeParagraph,
    changeClientName,
    changeSlideContent,
    changeClientPosition,
    getClientsActions: clientLogoActionMaker(dispatcher),
    sliderActions: removeBlockActionMaker(dispatcher),
    clientImageActions: imageModalActionMaker(dispatcher),
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeClientNameAlignment,
    changeClientPositionAlignment,
    changeSlideContentAlignment
  }
}
