/* @flow */
import { mapModalActionMaker } from '@website/components/ActionMakers'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import {
  addLink,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const removeLink = (idx: number) =>
    dispatcher({
      type: 'REMOVE_LINK',
      value: idx
    })

  const changeLinkUrl = (idx: number, link: string) =>
    dispatcher({
      type: 'CHANGE_LINK_DATA',
      value: { idx, link }
    })

  const _addLink = () => dispatcher(addLink())

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addLink: _addLink,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    getMapActions: mapModalActionMaker(dispatcher),
    linkActions: (idx: number, link: string) => [
      {
        IconComp: EditIcon,
        tooltipText: translate('edit_label'),
        handler: link => changeLinkUrl(idx, link),
        modal: {
          type: MODAL_TYPES.urlModal,
          props: { type: LINK_TYPES.link, link }
        }
      },
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => removeLink(idx)
      }
    ]
  }
}
