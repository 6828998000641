/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Frame = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 545 414"
      xmlSpace="preserve"
    >
      <g>
        <path
          className="st0"
          d="M129.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C130.9,402.2,130.3,402.2,129.9,402.6z"
        />
        <path
          className="st0"
          d="M118.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C119.9,402.2,119.3,402.2,118.9,402.6z"
        />
        <path
          className="st0"
          d="M107.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C108.9,402.2,108.3,402.2,107.9,402.6z"
        />
        <path
          className="st0"
          d="M96.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C97.9,402.2,97.3,402.2,96.9,402.6z"
        />
        <path
          className="st0"
          d="M85.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C86.9,402.2,86.3,402.2,85.9,402.6z"
        />
        <path
          className="st0"
          d="M74.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C75.9,402.2,75.3,402.2,74.9,402.6z"
        />
        <path
          className="st0"
          d="M63.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C64.9,402.2,64.3,402.2,63.9,402.6z"
        />
        <path
          className="st0"
          d="M52.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C53.9,402.2,53.3,402.2,52.9,402.6z"
        />
        <path
          className="st0"
          d="M41.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C42.9,402.2,42.3,402.2,41.9,402.6z"
        />
        <path
          className="st0"
          d="M30.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C31.9,402.2,31.3,402.2,30.9,402.6z"
        />
        <path
          className="st0"
          d="M19.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C20.9,402.2,20.3,402.2,19.9,402.6z"
        />
        <path
          className="st0"
          d="M8.9,402.6l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C9.9,402.2,9.3,402.2,8.9,402.6z"
        />
        <path
          className="st0"
          d="M12.3,271.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,272.7,12.7,272.1,12.3,271.7z"
        />
        <path
          className="st0"
          d="M12.3,282.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,283.7,12.7,283.1,12.3,282.7z"
        />
        <path
          className="st0"
          d="M12.3,293.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,294.7,12.7,294.1,12.3,293.7z"
        />
        <path
          className="st0"
          d="M12.3,304.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,305.7,12.7,305.1,12.3,304.7z"
        />
        <path
          className="st0"
          d="M12.3,315.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,316.7,12.7,316.1,12.3,315.7z"
        />
        <path
          className="st0"
          d="M12.3,326.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,327.7,12.7,327.1,12.3,326.7z"
        />
        <path
          className="st0"
          d="M12.3,337.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,338.7,12.7,338.1,12.3,337.7z"
        />
        <path
          className="st0"
          d="M12.3,348.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,349.7,12.7,349.1,12.3,348.7z"
        />
        <path
          className="st0"
          d="M12.3,359.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,360.7,12.7,360.1,12.3,359.7z"
        />
        <path
          className="st0"
          d="M12.3,370.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,371.7,12.7,371.1,12.3,370.7z"
        />
        <path
          className="st0"
          d="M12.3,381.7c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C12.6,382.7,12.7,382.1,12.3,381.7z"
        />
        <path
          className="st0"
          d="M10.9,392.6l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7
		c0.4-0.4,0.5-1,0.1-1.4C11.9,392.3,11.3,392.2,10.9,392.6z"
        />
        <path
          className="st0"
          d="M422.3,0.3l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C423.3-0.1,422.7-0.1,422.3,0.3z"
        />
        <path
          className="st0"
          d="M433.3,0.3l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C434.3-0.1,433.7-0.1,433.3,0.3z"
        />
        <path
          className="st0"
          d="M444.3,0.3l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C445.3-0.1,444.7-0.1,444.3,0.3z"
        />
        <path
          className="st0"
          d="M455.3,0.3l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C456.3-0.1,455.7-0.1,455.3,0.3z"
        />
        <path
          className="st0"
          d="M466.3,0.3l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C467.3-0.1,466.7-0.1,466.3,0.3z"
        />
        <path
          className="st0"
          d="M477.3,0.3l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C478.3-0.1,477.7-0.1,477.3,0.3z"
        />
        <path
          className="st0"
          d="M488.3,0.3l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4l8.7-9.7
		c0.3-0.4,0.3-1-0.1-1.4C489.3-0.1,488.7-0.1,488.3,0.3z"
        />
        <path
          className="st0"
          d="M500.7,0.2c-0.4-0.3-1-0.3-1.4,0.1l-8.7,9.7c-0.3,0.4-0.3,1,0.1,1.4c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.4
		l8.7-9.7C501.1,1.2,501.1,0.6,500.7,0.2z"
        />
        <path
          className="st0"
          d="M502.4,11.7c0.2,0,0.5-0.1,0.7-0.4l8.7-9.7c0.3-0.4,0.3-1-0.1-1.4c-0.4-0.3-1-0.3-1.4,0.1l-8.7,9.7
		c-0.3,0.4-0.3,1,0.1,1.4C501.9,11.6,502.2,11.7,502.4,11.7z"
        />
        <path
          className="st0"
          d="M513.4,11.7c0.2,0,0.5-0.1,0.7-0.4l8.7-9.7c0.3-0.4,0.3-1-0.1-1.4c-0.4-0.3-1-0.3-1.4,0.1l-8.7,9.7
		c-0.3,0.4-0.3,1,0.1,1.4C512.9,11.6,513.2,11.7,513.4,11.7z"
        />
        <path
          className="st0"
          d="M524.4,11.7c0.2,0,0.5-0.1,0.7-0.4l8.7-9.7c0.3-0.4,0.3-1-0.1-1.4c-0.4-0.3-1-0.3-1.4,0.1l-8.7,9.7
		c-0.3,0.4-0.3,1,0.1,1.4C523.9,11.6,524.2,11.7,524.4,11.7z"
        />
        <path
          className="st0"
          d="M535.4,11.7c0.2,0,0.5-0.1,0.7-0.4l8.7-9.7c0.3-0.4,0.3-1-0.1-1.4c-0.4-0.3-1-0.3-1.4,0.1l-8.7,9.7
		c-0.3,0.4-0.3,1,0.1,1.4C534.9,11.6,535.2,11.7,535.4,11.7z"
        />
        <path
          className="st0"
          d="M542.6,132.3l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7
		c0.4-0.4,0.5-1,0.1-1.4C543.6,132,543,131.9,542.6,132.3z"
        />
        <path
          className="st0"
          d="M544,121.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,122.4,544.4,121.8,544,121.4z"
        />
        <path
          className="st0"
          d="M544,110.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,111.4,544.4,110.8,544,110.4z"
        />
        <path
          className="st0"
          d="M544,99.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,100.4,544.4,99.8,544,99.4z"
        />
        <path
          className="st0"
          d="M544,88.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,89.4,544.4,88.8,544,88.4z"
        />
        <path
          className="st0"
          d="M544,77.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,78.4,544.4,77.8,544,77.4z"
        />
        <path
          className="st0"
          d="M544,66.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,67.4,544.4,66.8,544,66.4z"
        />
        <path
          className="st0"
          d="M544,55.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,56.4,544.4,55.8,544,55.4z"
        />
        <path
          className="st0"
          d="M544,44.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,45.4,544.4,44.8,544,44.4z"
        />
        <path
          className="st0"
          d="M544,33.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,34.4,544.4,33.8,544,33.4z"
        />
        <path
          className="st0"
          d="M544,22.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,23.4,544.4,22.8,544,22.4z"
        />
        <path
          className="st0"
          d="M544,11.4c-0.4-0.4-1-0.5-1.4-0.1l-9.7,8.7c-0.4,0.4-0.5,1-0.1,1.4c0.2,0.2,0.4,0.3,0.7,0.3
		c0.2,0,0.5-0.1,0.7-0.2l9.7-8.7C544.3,12.4,544.4,11.8,544,11.4z"
        />
        <path
          className="st0"
          d="M544,362c-0.6,0-1,0.4-1,1v49h-49c-0.6,0-1,0.4-1,1s0.4,1,1,1h50c0.6,0,1-0.4,1-1v-50
		C545,362.4,544.6,362,544,362z"
        />
        <path
          className="st0"
          d="M1,52c0.6,0,1-0.4,1-1V2h49c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H1C0.4,0,0,0.4,0,1v50C0,51.6,0.4,52,1,52z"
        />
      </g>
    </svg>
  )
}

export default withTheme(Frame)
