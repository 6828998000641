/* @flow */

export const validation = {
  team: {
    min: 1,
    max: 15
  },
  socials: {
    min: 0,
    max: 12
  },
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 50,
  blockSubTitleMaxChar: 50,
  blockParagraphMaxChar: 1000
}
