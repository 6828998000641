/* @flow */

export const validation = {
  titleMaxChar: 50,
  maxCustomerNameChar: 30,
  maxCustomerPositionChar: 30,
  maxSlideContentChar: 200,
  testimonials: {
    min: 3,
    max: 12
  }
}
