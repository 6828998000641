import styled, { css } from 'styled-components'

import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const stylesObj = {
  tr: {
    margin: '40px auto 0px'
  },
  default: {
    margin: '30px auto 0px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

const getDisabledBtnStyles = ({ disabled }) =>
  disabled
    ? css`
        opacity: 0.7;
        box-shadow: none;
        cursor: default;
        border-color: gray;
        pointer-events: none;
      `
    : ''

export const SubdomainInputContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  .subdomain-input-wrapper {
    @media only screen and (max-width: 769px) {
      .input-container {
        position: inherit;
      }
    }
  }
`

export const InputBlock = styled.div`
  flex-grow: 1;
  position: relative;
  order: -1;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

export const InputForm = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  background-color: #edf5ff;
  padding: 5px 10px;
  border-radius: 5px;
  box-sizing: border-box;
  flex-shrink: 0;
  @media only screen and (max-width: 767px) {
    padding: 5px 10px 5px 5px;
    position: relative;
  }
`

export const Dots = styled.div`
  display: flex;
  align-items: center;
  padding-right: 10px;
  & > div {
    background-color: #c1c9e0;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    &:not(:last-child) {
      margin-right: 7px;
    }
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }
`

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  input {
    height: 30px;
    color: #252e48;
    &::placeholder {
      color: #9aa6c8;
      font-size: 12px;
    }
  }

  .remove-icon {
    position: absolute;
    z-index: 2;
    right: 10px;
    cursor: pointer;
    &:hover {
      svg {
        fill: #545f7e;
      }
    }
  }

  .error-message {
    bottom: -22px;
    font-size: 12px;
    @media only screen and (max-width: 568px) {
      width: 315px;
    }
    svg {
      width: 14px;
    }
  }

  @media only screen and (max-width: 767px) {
    flex-grow: 1;
    input {
      width: 100%;
    }
  }
`

export const RenerforestSites = styled.p`
  min-width: 134px;
  font-size: 12px;
  font-weight: 500;
  color: #8493bd;
  padding-left: 10px;
  flex-shrink: 0;
  user-select: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const SuccessIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 7px;
`

export const Button = styled.button`
  order: -1;
  ${getDisabledBtnStyles};
  height: 40px;
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: #eef5ff;
  color: #3271e6;
  font-size: 14px;
  font-weight: 500;
  padding: 11px 30px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
  box-sizing: border-box;
  &:hover {
    color: #ffffff;
    background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%);
  }

  @media only screen and (max-width: 767px) {
    order: 0;
    margin: ${getStylesByLang('margin')};
  }
`
