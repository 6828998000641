import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    title: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`,
    background: p => `${p.tertiaryDark}`,
    lightBackground: p => p.tertiaryExtraLight,
    activeTabBg: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.5)`,
    actieMobileTabBg: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.3)`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.8)`,
    raisedShadow: () => 'none'
  },
  LIGHT: {
    title: p => p.primaryDark,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`,
    background: p => `${p.tertiaryLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    activeTabBg: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.5)`,
    actieMobileTabBg: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.3)`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.8)`
  },
  EXTRALIGHT: {
    title: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`,
    background: p => `${p.tertiaryExtraLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    activeTabBg: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.5)`,
    actieMobileTabBg: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.3)`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.9)`
  }
}
