//@flow
import React, { memo, useState, useEffect, useCallback, useRef } from 'react'
import { createPortal } from 'react-dom'

import * as Styled from './styled'
import type { TPopupProps } from './types'

const Popup = ({
  isOpen: isOpenFromProps,
  isMobileFullScreen = true,
  children,
  onClose
}: TPopupProps) => {
  const [isOpen, setPopupOpenState] = useState(false)
  const popupRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = e => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [popupRef, onClose])

  useEffect(() => {
    if (isOpenFromProps) {
      setPopupOpenState(true)
    }
  }, [isOpenFromProps])

  const onAnimationEnd = useCallback(() => {
    if (!isOpenFromProps) {
      setPopupOpenState(false)
    }
  }, [isOpenFromProps])

  return isOpen
    ? createPortal(
        <Styled.PopupContainer>
          <Styled.Popup
            ref={popupRef}
            isOpen={isOpenFromProps}
            isMobileFullScreen={isMobileFullScreen}
            onAnimationEnd={onAnimationEnd}
          >
            <Styled.CloseIcon onClick={onClose} />
            {children}
          </Styled.Popup>
        </Styled.PopupContainer>,
        document.body
      )
    : null
}

export default memo(Popup)
