/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_COPYRIGHT':
        draft.copyrightText = action.value
        return
    }
  })
}
