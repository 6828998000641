/* @flow */
import { imageModalActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const addImage = () => {
    dispatcher({
      type: 'ADD_IMAGE'
    })
  }

  const imageActions = (idx, showRemove, imageUrl, mediaFlyoutShift) => {
    const actions = {
      change: ({ url, dimensions, undoable, sizes }) => ({
        type: 'CHANGE_PORTFOLIO_IMG',
        value: { url, dimensions, idx, sizes },
        undoable
      }),
      remove: () => ({
        type: 'REMOVE_PORTFOLIO_IMG',
        value: idx
      })
    }
    return imageModalActionMaker(dispatcher)({
      showRemove,
      withImgSizes: true,
      keyForSizes: 'portfolio1',
      actions,
      imageUrl,
      mediaFlyoutShift
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addImage,
    imageActions
  }
}
