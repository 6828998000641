/* @flow */
import React, { useContext } from 'react'
import { withTheme } from 'styled-components'
import * as Styled from './MockupColorSwitcher.styled'
import Switcher from '@renderforest/rf-ui-library/atoms/Switcher'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const MockupColorSwitcher = ({
  onChoose,
  firstOption = 'Light',
  secondOption = 'Dark',
  tooltipText = translate('mockup_color_label'),
  onFirstOptionSelect,
  onSecondOptionSelect,
  isSecondOptionSelected
}) => {
  const isMobile = useContext(MobileDetectContext)

  return (
    <TooltipWrapper text={isMobile ? '' : tooltipText}>
      <Styled.SwitcherContainer checked={isSecondOptionSelected}>
        <Styled.ColorModeText
          onClick={onFirstOptionSelect}
          isChecked={!isSecondOptionSelected}
        >
          {firstOption}
        </Styled.ColorModeText>
        <Switcher
          size="medium"
          checked={isSecondOptionSelected}
          onClick={onChoose}
        />
        <Styled.ColorModeText
          onClick={onSecondOptionSelect}
          isChecked={isSecondOptionSelected}
        >
          {secondOption}
        </Styled.ColorModeText>
      </Styled.SwitcherContainer>
    </TooltipWrapper>
  )
}

export default withTheme(MockupColorSwitcher)
