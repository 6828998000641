// import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    background: p => `${p.tertiaryDark}`,
    backgroundColor: p => p.tertiaryDark,
    blockParagraph: p => p.tertiaryDark,
    blockBG: p => p.primaryLight,
    oddBlockBG: p => p.secondaryLight,
    raisedShadow: () => 'none',
    paintLine: p => p.primaryDark,
    paintLine2: p => p.accentWhite
  },
  LIGHT: {
    heading: p => p.primaryDark,
    background: p => `${p.tertiaryLight}`,
    backgroundColor: p => p.tertiaryLight,
    blockParagraph: p => p.accentWhite,
    blockBG: p => p.primaryDark,
    oddBlockBG: p => p.secondaryDark,
    paintLine: p => p.primaryDark,
    paintLine2: p => p.tertiaryDark
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    backgroundColor: p => p.tertiaryExtraLight,
    blockParagraph: p => p.accentWhite,
    blockBG: p => p.primaryDark,
    oddBlockBG: p => p.secondaryDark,
    paintLine: p => p.primaryDark,
    paintLine2: p => p.tertiaryDark
  }
}
