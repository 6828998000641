/* @flow */
import React, { useCallback, useContext, memo, useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { createPortal } from 'react-dom'

import * as Styled from './styled'
import {
  CategoriesFooter,
  MobileCategoriesWrapper,
  CategoriesHeader,
  CloseButton,
  SelectedSign,
  SlideUpWrapper,
  SlideUp,
  Button,
  ButtonSection
} from '@editor/common/styled-components/choose-component-template'
import type { TComponentCategoriesProps } from './types'
import { SCROLLABLE_CONTAINER_ID, ADDITIONAL_OFFSET } from '../../consts'
import NewBadge from './NewBadge/NewBadge'
import EventGradientIcon from '@editor/common/assets/rsvp_event_icon_gradient.svg'
import { useBackdropClick, useHistoryWithSearch } from '@hooks'
import { MobileDetectContext } from '@contexts'
import { EDITOR_BASE } from '@editor/conf/routes'
import { NEW_COMPONENTS, RSVP_COMP_CATEGORY } from '@editor/conf/consts'
import { translate } from '@editor/common/utils/translations'
import { isOpenedWithHash } from '@editor/common/utils'
import {
  getCurrentPageComponents,
  selectRsvpCompCategory,
  selectComponentsList
} from '@selectors'

const hasNewComponent = (currentCat, componentsList) =>
  NEW_COMPONENTS.some(
    compId =>
      componentsList.find(comp => comp.id === compId)?.category === currentCat
  )

const ComponentCategories = ({
  activeCategory,
  filteredCats,
  isCompReplacing,
  selectedComponents,
  isSlideUpOpen,
  setSlideUpOpenState,
  setActiveCategory,
  hasSpecialCategory
}: TComponentCategoriesProps) => {
  const {
    params: { page, idx: _idx }
  } = useRouteMatch()
  const { url } = useRouteMatch(EDITOR_BASE)
  const history = useHistoryWithSearch()
  const { pathname, hasOpenedFromSidebar } = useLocation()

  const idx = parseInt(_idx)
  const isMobile = useContext(MobileDetectContext)
  const backToAction = isCompReplacing ? 'replace-component' : 'add-component'

  const pageComponents = useSelector(getCurrentPageComponents)
  const componentsList = useSelector(selectComponentsList)
  const rsvpCategory = useSelector(selectRsvpCompCategory)
  const selectedCompCategories = new Set(
    selectedComponents.map(component => component.category)
  )

  const slideUpRef = useBackdropClick(() => {
    setSlideUpOpenState(false)
  })

  const replacingCompCategory = useMemo(
    () => pageComponents[idx]?.category,
    [pageComponents, idx]
  )

  useEffect(() => {
    if (isCompReplacing && replacingCompCategory === 'rsvp') {
      setActiveCategory('rsvp')
    }
  }, [])

  useEffect(() => {
    const category = document.getElementById(`${activeCategory}_category`)

    category && category.scrollIntoView({ block: 'center' })
  }, [activeCategory, isSlideUpOpen])

  const onTemplatesBtnClick = useCallback(() => {
    const isStartCreation = pathname.includes('start-creation')

    const backTo = isStartCreation
      ? `start-creation/${backToAction}/${page}/${idx}`
      : `edit/${page}/${backToAction}/${idx}`

    const _pathname = isStartCreation
      ? `${url}/start-creation/choose-template`
      : `${url}/edit/${page}/choose-template`

    history.push({
      pathname: _pathname,
      state: {
        backTo
      }
    })
  }, [url, page, idx, pathname, backToAction])

  const closeSlideUp = useCallback(() => {
    setSlideUpOpenState(false)
  }, [])

  const isFilteredCatsDisabled =
    activeCategory === RSVP_COMP_CATEGORY &&
    (hasOpenedFromSidebar || isCompReplacing)

  const onCategoryClick = useCallback(
    category => {
      if (!isFilteredCatsDisabled) {
        setActiveCategory(category)
      }

      setTimeout(() => {
        const container = document.getElementById(`${category}`)
        if (container) {
          const scrollableContainer = document.getElementById(
            SCROLLABLE_CONTAINER_ID
          )
          scrollableContainer.scrollTop =
            container.offsetTop - ADDITIONAL_OFFSET
        }
      }, 0)

      isMobile && closeSlideUp()
    },
    [isMobile, isFilteredCatsDisabled]
  )

  const content = useMemo(() => {
    return (
      <>
        <Styled.Categories isDisabled={isFilteredCatsDisabled}>
          {filteredCats.map((cat, idx) => {
            const { name, key } = cat

            return (
              <Styled.Category
                key={idx}
                id={`${key}_category`}
                isActive={key === activeCategory}
                onClick={() => onCategoryClick(key)}
              >
                {selectedCompCategories.has(key) ? <SelectedSign /> : null}
                <p>{name}</p>
                {hasNewComponent(key, componentsList) && <NewBadge />}
              </Styled.Category>
            )
          })}
        </Styled.Categories>
        <CategoriesFooter>
          {hasSpecialCategory && !isOpenedWithHash() && !!rsvpCategory.key ? (
            <Styled.SpecialCategory
              id={`${rsvpCategory.key}_category`}
              isActive={rsvpCategory.key === activeCategory}
              onClick={() => onCategoryClick(rsvpCategory.key)}
            >
              <div>
                <img src={EventGradientIcon} alt="event-icon" />
                <span>{rsvpCategory.name}</span>
              </div>
            </Styled.SpecialCategory>
          ) : null}
          <ButtonSection>
            <Button onClick={onTemplatesBtnClick}>
              {translate('explore_templates_label')}
            </Button>
          </ButtonSection>
        </CategoriesFooter>
      </>
    )
  }, [
    filteredCats,
    activeCategory,
    selectedCompCategories,
    onTemplatesBtnClick
  ])

  return isMobile ? (
    createPortal(
      <SlideUpWrapper isSlideUpOpen={isSlideUpOpen}>
        <SlideUp ref={slideUpRef} isSlideUpOpen={isSlideUpOpen}>
          <MobileCategoriesWrapper>
            <CategoriesHeader>
              <CloseButton
                name="close"
                color="secondarySemiLight"
                size="medium"
                onClick={closeSlideUp}
              />
            </CategoriesHeader>
            {content}
          </MobileCategoriesWrapper>
        </SlideUp>
      </SlideUpWrapper>,
      document.body
    )
  ) : (
    <Styled.ComponentCategories>{content}</Styled.ComponentCategories>
  )
}

export default memo(ComponentCategories)
