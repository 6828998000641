/* @flow */
import {
  simpleButtonActionMaker,
  socialActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  addSocial,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changePlaceHolderText = (key: string, value: string) => {
    dispatcher({
      type: 'CHANGE_PLACEHOLDER_TEXT',
      value: { key, value }
    })
  }

  const infoChangeAction = (key: string, value: string) => {
    dispatcher({
      type: `CHANGE_${key.toUpperCase()}`,
      value
    })
  }

  const removeInfo = (key: stinrg) => {
    dispatcher({
      type: `REMOVE_${key.toUpperCase()}`
    })
  }

  const addInfo = key => {
    dispatcher({
      type: `ADD_${key.toUpperCase()}`
    })
  }

  const removeForm = () => {
    dispatcher({
      type: 'REMOVE_FORM'
    })
  }

  const addForm = () => {
    dispatcher({
      type: 'ADD_FORM'
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeContactAlignment = (alignment, alignmentKey) => {
    dispatcher({
      type: 'CHANGE_CONTACT_ALIGNMENT',
      value: { alignment, alignmentKey }
    })
  }

  const changeAddress = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_ADDRESS',
      value: newParagraph
    })
  }
  const changeAddressAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_ADDRESS_ALIGNMENT',
      value: alignment
    })
  }

  const changeAddressTitle = (newTitle: string) => {
    dispatcher({
      type: 'CHANGE_ADDRESS_TITLE',
      value: newTitle
    })
  }
  const changeAddressTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_ADDRESS_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeEmailTitle = (newTitle: string) => {
    dispatcher({
      type: 'CHANGE_EMAIL_TITLE',
      value: newTitle
    })
  }
  const changeEmailTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_EMAIL_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changePhoneTitle = (newTitle: string) => {
    dispatcher({
      type: 'CHANGE_PHONE_TITLE',
      value: newTitle
    })
  }
  const changePhoneTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PHONE_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    infoChangeAction,
    changePlaceHolderText,
    addSocial: () => dispatcher(addSocial()),
    addForm,
    addInfo,
    changeAddress,
    changeAddressAlignment,
    changeAddressTitle,
    changeAddressTitleAlignment,
    changeEmailTitle,
    changeEmailTitleAlignment,
    changePhoneTitle,
    changePhoneTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeContactAlignment,
    getSocialActions: socialActionMaker(dispatcher),
    getButtonActions: simpleButtonActionMaker(dispatcher),
    infoActions: key => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeInfo(key))
      }
    ]
  }
}
