import React from 'react'
import { withTheme } from 'styled-components'

const QuoteSVG = ({ theme, style }) => {
  const { palette } = theme
  const color = palette.tertiarySemiLight
  return (
    <svg
      width="40"
      height="32"
      style={style}
      viewBox="0 0 40 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd">
        <g
          id="testimonials-2-1366x768"
          transform="translate(-123 -323)"
          fill={color}
        >
          <path
            d="M163,355 L145.695035,355 L145.695035,341.862745 C145.695035,337.02612 146.716084,333.209164 148.758213,330.411765 C150.800342,327.614365 154.287821,325.143802 159.220755,323 L163,329.980392 C159.923546,331.392164 157.808516,332.797379 156.654846,334.196078 C155.501176,335.594778 154.858048,337.248356 154.725442,339.156863 L163,339.156863 L163,355 Z M140.304965,355 L123,355 L123,341.862745 C123,337.02612 124.021049,333.209164 126.063178,330.411765 C128.105306,327.614365 131.592785,325.143802 136.525719,323 L140.304965,329.980392 C137.228511,331.392164 135.113481,332.797379 133.959811,334.196078 C132.806141,335.594778 132.163013,337.248356 132.030407,339.156863 L140.304965,339.156863 L140.304965,355 Z"
            id="Shape-Copy"
          />
        </g>
      </g>
    </svg>
  )
}

export default withTheme(QuoteSVG)
