// @flow
import React, { useMemo, memo } from 'react'

import * as Styled from './styled'
import type { TGridLayoutProps } from './types'
import { createGridData } from './utils'

const GridLayout = ({ columnsCount, gap, children }: TGridLayoutProps) => {
  const gridData = useMemo(
    () => createGridData(columnsCount, children),
    [columnsCount, children, children.length]
  )

  return (
    <Styled.GridLayout>
      {gridData.map((column, idx) => (
        <Styled.Column key={idx} gap={gap}>
          {column.map((columnItem, idx) => (
            <Styled.ColumnItem key={idx} gap={gap}>
              {columnItem}
            </Styled.ColumnItem>
          ))}
        </Styled.Column>
      ))}
    </Styled.GridLayout>
  )
}

export default memo(GridLayout)
