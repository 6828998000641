/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Header1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0;
  position: relative;
  flex-direction: column;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  padding-bottom: ${({ isLogoExist, isEditing }) =>
    !isLogoExist && !isEditing ? '80px' : '0px'};
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
    padding-bottom: ${({ isLogoExist, isEditing }) =>
      !isLogoExist && !isEditing ? '60px' : '0px'};
  }
  > :first-child {
    padding-top: 0;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
  }
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  padding-top: 20px;
`

const CompanyLogosWrap = styled.div`
  justify-content: ${({ isStart }) => (isStart ? 'flex-start' : 'center')};
  display: flex;
  margin: 0 auto;
  align-items: center;
  overflow-x: auto;
  max-width: 100%;
  width: 80%;
  box-sizing: border-box;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding: 300px 0 40px 0;
          margin-top: -220px;
          @media only screen and (max-width: 650px) {
            padding-top: 300px;
          }
        `
      : css`
          padding: 80px 0 40px 0;
          @media only screen and (max-width: 650px) {
            padding-top: 60px;
          }
        `}

  @media only screen and (max-width: 650px) {
    width: 100%;
    padding-bottom: 30px;
  }
  .failed-image-container {
    width: 160px;
    height: 100px;
  }
`
const CompanyLogosFigure = styled.div`
  margin: 0 25px;
  flex-shrink: 0;
  @media only screen and (max-width: 1024px) {
    margin: 0 12px;
  }
`

const Img = styled.img`
  width: 100%;
  min-width: 100px;
  height: auto;
  object-fit: contain;
  transition: all 0.1s linear;
  max-width: 160px;
  max-height: 100px;
  display: block;
  &:hover {
    opacity: 1;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 128px;
    max-height: 80px;
  }
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
`

export default {
  CompanyLogosWrap,
  CompanyLogosFigure,
  Img,
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  Description
}
