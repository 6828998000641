/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BLOCK':
        draft.featureBlocks.splice(action.value.idx, 1)
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_PRIMARY_TITLE': {
        const { idx, title } = action.value
        draft.featureBlocks[idx].primaryTitle = title
        break
      }
      case 'CHANGE_BLOCK_SECONDARY_TITLE': {
        const { idx, title } = action.value
        draft.featureBlocks[idx].secondaryTitle = title
        break
      }
      case 'CHANGE_BLOCK_CONTENT': {
        const { idx, newContent } = action.value
        draft.featureBlocks[idx].description = newContent
        break
      }
      case 'CHANGE_PRIMARY_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.featureBlocks[idx].primaryTitleAlignment = alignment
        break
      }
      case 'CHANGE_SECONDARY_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.featureBlocks[idx].secondaryTitleAlignment = alignment
        break
      }
      case 'CHANGE_DESCRIPTION_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.featureBlocks[idx].descriptionAlignment = alignment
        break
      }
      case 'ADD_BLOCK':
        draft.featureBlocks.push(initialState.featureBlocks[0])
        break
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.featureBlocks[linkIdx].additionalUrl = linkData.link
        draft.featureBlocks[linkIdx].additionalText = linkData.text
        break
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.featureBlocks[idx].additionalUrl = ''
        draft.featureBlocks[idx].additionalText = ''
        break
      }
      case 'ADD_LINK':
        const { idx: blockIdx } = action.value
        const { additionalUrl, additionalText } = initialState.featureBlocks[0]
        draft.featureBlocks[blockIdx].additionalUrl = additionalUrl
        draft.featureBlocks[blockIdx].additionalText = additionalText
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
