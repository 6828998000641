/* @flow */
import produce from 'immer'

const setFeatureImgUrl = (feature, action, level = 0) => {
  const { blockIdx, subIdx, data, category } = action
  if (Array.isArray(feature)) {
    return feature.map((subItem, subItemIdx) => {
      if (subItemIdx === subIdx) {
        setFeatureImgUrl(subItem, action, level + 1, subItemIdx)
      }
    })
  } else {
    if (level === blockIdx) {
      feature[category] = data
    }
  }
}

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'ADD_BLOCK': {
        const idx = action.value
        draft.features.splice(idx + 1, 0, initialState.features[0])
        break
      }
      case 'REMOVE_BLOCK':
        const idx = action.value
        draft.features.splice(idx, 1)
        break
      case 'CHANGE_IMAGE': {
        const { blockIdx, featureIdx, url, subIdx, dimensions } = action.value
        setFeatureImgUrl(draft.features[featureIdx][blockIdx], {
          subIdx,
          blockIdx,
          category: 'imageUrl',
          data: url
        })
        setFeatureImgUrl(draft.features[featureIdx][blockIdx], {
          subIdx,
          blockIdx,
          data: dimensions,
          category: 'imgDimensions'
        })
        setFeatureImgUrl(draft.features[featureIdx][blockIdx], {
          subIdx,
          blockIdx,
          data: {},
          category: 'imgCropParams'
        })
        break
      }
      case 'CHANGE_BLOCK_DATA': {
        const { blockIdx, featureIdx, subIdx, data, key } = action.value
        setFeatureImgUrl(draft.features[featureIdx][blockIdx], {
          subIdx,
          blockIdx,
          data,
          category: key
        })
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, featureIdx, blockIdx, subIdx } = action.value
        setFeatureImgUrl(draft.features[featureIdx][blockIdx], {
          subIdx,
          blockIdx,
          data: imgCropParams,
          category: 'imgCropParams'
        })
        break
      }
    }
  })
}
