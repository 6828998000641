import React from 'react'

import * as Styled from './styled'
import MainSidebar from './components/MainSidebar'
import SubSidebar from './components/SubSidebar'
import { useBackdropClick } from '@hooks'

const Sidebar = ({
  exceptedBackdropSelectors = [],
  isMainSidebarExpanded = false,
  isSubSidebarOpened = false,
  toggleMainSidebar,
  setSubSidebarOpenedState,
  mainSidebarContent,
  subSidebarContent,
  onSubSidebarCloseEnd
}) => {
  const sidebarRef = useBackdropClick(() => {
    if (isSubSidebarOpened) {
      setSubSidebarOpenedState(false)
    }
  }, exceptedBackdropSelectors)

  return (
    <Styled.SidebarWrapper ref={sidebarRef}>
      <Styled.Sidebar>
        <MainSidebar
          isExpanded={isMainSidebarExpanded}
          toggleMainSidebar={toggleMainSidebar}
        >
          {mainSidebarContent}
        </MainSidebar>
        <SubSidebar
          isOpened={isSubSidebarOpened}
          onCloseEnd={onSubSidebarCloseEnd}
        >
          {subSidebarContent}
        </SubSidebar>
      </Styled.Sidebar>
    </Styled.SidebarWrapper>
  )
}

export default Sidebar
