import React from 'react'

export default ({ isAnimationEnabled }) => {
  const duration = isAnimationEnabled ? '9.5s' : '0s'
  return (
    <svg
      viewBox="0 0 485 485"
      xmlns="http://www.w3.org/2000/svg"
      className="animated-2"
    >
      <defs>
        <linearGradient
          id="video5-gradient-1"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%"></stop>
          <stop offset="100%"></stop>
        </linearGradient>
      </defs>
      <path id="blob-2">
        <animate
          dur={duration}
          repeatCount="indefinite"
          attributeName="d"
          values="M369.5,317.5Q411,385,350.5,431Q290,477,223,444.5Q156,412,155.5,348.5Q155,285,129.5,241Q104,197,122.5,129Q141,61,211,67Q281,73,312,122.5Q343,172,335.5,211Q328,250,369.5,317.5Z;
          M358.5,303.5Q378,357,329.5,390.5Q281,424,236,388Q191,352,178.5,316Q166,280,133.5,238Q101,196,117,122Q133,48,210,43.5Q287,39,312,108Q337,177,338,213.5Q339,250,358.5,303.5Z;
          M369.5,317.5Q411,385,350.5,431Q290,477,223,444.5Q156,412,155.5,348.5Q155,285,129.5,241Q104,197,122.5,129Q141,61,211,67Q281,73,312,122.5Q343,172,335.5,211Q328,250,369.5,317.5Z"
        ></animate>
      </path>
    </svg>
  )
}
