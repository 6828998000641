/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA3.styled'
import { validation } from './CTA3.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const CTA3 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttons,
      actions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <Show when={[title, paragraph, buttons.length > 0, actions.length > 0]}>
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer>
          <Show when={[title, paragraph, buttons.length > 0]}>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                maxCount={validation.titleMaxChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading42"
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Description}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                className="WM_GLOBAL_paragraph"
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <Buttons data={buttons} componentDispatcher={dispatcher} />
          </Show>
          <S.ItemsWrap>
            <Mapper
              data={actions}
              render={(cta: string, ctaIdx: number) => (
                <Show when={[cta.title, cta.paragraph]} key={ctaIdx}>
                  <S.Cta>
                    <ControlsWithModalOpener
                      key={ctaIdx}
                      actions={Actions.ctaActions(ctaIdx, actions.length)}
                    >
                      <S.CtaItemWrap>
                        <EditableContent
                          as={S.CtaTitle}
                          text={cta.title}
                          alignment={cta.blockTitleAlignment}
                          maxCount={validation.blockTitleMaxChar}
                          className="WM_GLOBAL_primary-font"
                          onChange={(newCtaTitle: string) =>
                            Actions.changeCtaTitle(newCtaTitle, ctaIdx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(alignment, ctaIdx)
                          }
                        />
                        <EditableContent
                          as={S.CtaParagraph}
                          text={cta.paragraph}
                          alignment={cta.blockParagraphAlignment}
                          maxCount={validation.blockParagraphMaxChar}
                          className="WM_GLOBAL_secondary-font"
                          onChange={(newCtaParagraph: string) =>
                            Actions.changeCtaParapgraph(newCtaParagraph, ctaIdx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              ctaIdx
                            )
                          }
                        />
                      </S.CtaItemWrap>
                    </ControlsWithModalOpener>
                  </S.Cta>
                </Show>
              )}
            />
          </S.ItemsWrap>
        </S.WmCustomContainer>
        <AddButton
          onAdd={Actions.addItem}
          toShow={actions.length < validation.actions.max}
          style={{
            marginTop: '2rem'
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.StyledContainer>
    </Show>
  )
})

export default CTA3
