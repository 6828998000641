/* @flow */
import _componentReducers from '@website/components/reducers'
import { ORIENTATION } from '@website/conf/consts'
const colorModeReducer = (colorMode: string, action: TProjectActions) => {
  switch (action.type) {
    case 'CHANGE_COLOR_MODE':
      return action.value
    default:
      return colorMode
  }
}

const orientationReducer = (
  orientation: string | null,
  action: TProjectActions
) => {
  switch (action.type) {
    case 'CHANGE_ORIENTATION':
      return orientation === ORIENTATION.LEFT
        ? ORIENTATION.RIGHT
        : ORIENTATION.LEFT
    default:
      return orientation
  }
}

const pagesReducer = (page: IPage, action: TProjectActions): IPage => {
  // find action's component index (if any)!
  return {
    ...page,
    components: page.components.map((component, i) => {
      const componentReducer = _componentReducers[component.id]
      const isReduceableComponent = typeof componentReducer === 'function'
      const isAction4ThisComponent = action.__from_component === i

      if (isAction4ThisComponent && isReduceableComponent) {
        // remove component name from actual action type
        const _actionType = action.type.replace(
          `@${component.id.toUpperCase()}/`,
          ''
        )

        const _action = {
          ...action,
          type: _actionType
        }
        return {
          ...component,
          colorMode: colorModeReducer(component.colorMode, _action),
          orientation: orientationReducer(component.orientation, _action),
          data: componentReducer(component.data, _action)
        }
      } else {
        return component
      }
    })
  }
}

export default pagesReducer
