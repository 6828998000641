/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA10.styled'
import { validation } from './CTA10.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SingleButton from '@website/common/components/SingleButton'
import { translate } from '@editor/common/utils/translations'

const CTA10 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      blocks,
      images,
      buttonText,
      buttonUrl
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <S.GridWrapper>
          <Mapper
            data={images}
            render={(item: string, idx: number) => {
              return (
                <S.RatioWrapper key={idx}>
                  <S.ImageWrapper>
                    <S.ImageControlContainer>
                      <ControlsWithImageCropper
                        style={{ width: '100%', height: '100%' }}
                        actions={Actions.imageActions(idx, item.imgUrl)}
                      >
                        <Image
                          as={S.Img}
                          alt="Gallery"
                          sizes={item.imgDimensions}
                          asProps={{
                            imgCropParams: item.imgCropParams
                          }}
                          defaultImgSrc={item.imgUrl}
                        />
                      </ControlsWithImageCropper>
                    </S.ImageControlContainer>
                  </S.ImageWrapper>
                  <S.Info
                    className="item-info"
                    isEditing={isEditing}
                    withoutTexts={!isEditing && !(item.title || item.price)}
                  >
                    <EditableContent
                      text={item.price}
                      as={S.InfoP}
                      alignment={item.priceAlignment}
                      maxCount={validation.priceMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(newPrice: string) => {
                        Actions.changePrice(newPrice, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changePriceAlignment(alignment, idx)
                      }
                    />
                    <EditableContent
                      text={item.title}
                      as={S.InfoP}
                      alignment={item.blockTitleAlignment}
                      isEditing={isEditing}
                      maxCount={validation.priceMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(newTitle: string) => {
                        Actions.changeText(newTitle, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx)
                      }
                    />
                  </S.Info>
                </S.RatioWrapper>
              )
            }}
          />
        </S.GridWrapper>
        <S.HeaderContent>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            maxCount={validation.titleMaxChar}
            required={validation.title.required}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <S.BlocksWrapper>
            <Mapper
              data={blocks}
              render={(block: string, blockIdx: number) => {
                return (
                  <S.ControlContainer key={blockIdx}>
                    <ControlsWithModalOpener
                      actions={Actions.blockActions(blockIdx, blocks.length)}
                      key={blockIdx}
                      alignment="right"
                    >
                      <S.BlockItem>
                        <S.IconWrap>
                          <ControlsWithModalOpener
                            actions={Actions.iconActions({
                              idx: blockIdx,
                              showRemove: false
                            })}
                          >
                            <S.Icon name={block.iconName} size="large" />
                          </ControlsWithModalOpener>
                        </S.IconWrap>
                        <EditableContent
                          text={block.title}
                          as={S.BlockTitle}
                          maxCount={validation.blockMaxChar}
                          className="WM_GLOBAL_paragraph18"
                          onChange={(newTitle: string) =>
                            Actions.changeBlockTitle(newTitle, blockIdx)
                          }
                        />
                      </S.BlockItem>
                    </ControlsWithModalOpener>
                  </S.ControlContainer>
                )
              }}
            />
            <AddButton
              onAdd={Actions.addNewItem}
              toShow={blocks.length < validation.blocks.max}
              style={{ margin: '20px 0' }}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_item_label')}
            </AddButton>
          </S.BlocksWrapper>
          <SingleButton btnUrl={buttonUrl} btnText={buttonText} />
        </S.HeaderContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default CTA10
