/* @flow */
import styled from 'styled-components'
import THEME from './Header29.theme'
import Container from '@website/common/components/Container'
import { styleProvider, getBackgroundStyles, getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  position: relative;
  flex-direction: column;
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0' : `${topOffset}px 0 0 0`};
  margin-bottom: 0;
  .socialIcons {
    .styled-controls {
      left: initial;
      right: 0;
    }
  }
  nav {
    margin-right: 30px;
  }
`

const TextContent = styled.div`
  width: 90%;
  max-width: 560px;
  padding: 60px 90px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.5);
  @media only screen and (max-width: 768px) {
    max-width: 430px;
  }
  @media only screen and (max-width: 550px) {
    padding: 40px 13vw;
  }
  & > .control-container {
    & > h1 {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const Row = styled.div`
  position: relative;
`

const ImagesWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-top: ${({ isNavbarMissing }) =>
    isNavbarMissing ? '4px solid transparent' : ''};
  padding-top: ${({ isEditing }) => (isEditing ? '50px' : 0)};
  .item {
    border-right: 4px solid transparent;
    border-bottom: 4px solid transparent;
    &:nth-child(4),
    &:nth-child(8) {
      border-right: none;
    }
    .styled-controls {
      z-index: 3;
    }
    &:nth-child(n + 5) {
      .styled-controls {
        top: initial;
        bottom: 0;
      }
    }
    @media only screen and (max-width: 767px) {
      &.item {
        border-right: none;
      }
      &:nth-child(odd) {
        border-right: 2px solid transparent;
      }
      &:nth-child(even) {
        border-left: 2px solid transparent;
      }
    }
  }
`

const Item = styled.div`
  width: 24%;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 767px) {
    width: 45%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    height: initial;
    padding-top: 100%;
  }
`

export default {
  StyledContainer,
  Row,
  ImagesWrap,
  Item,
  TextContent,
  Title,
  Img,
  ControlContainer
}
