/* @flow */
import React, { useContext, memo } from 'react'
import S from './Music1.styled'
import { validation } from './Music1.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'

const isIframe = url => {
  return url.startsWith('<iframe')
}

const Music1 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      name,
      musicUrl,
      titleAlignment,
      paragraphAlignment,
      nameAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[name, title, paragraph]}>
          <S.TextContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.maxTitleChar}
                className="WM_GLOBAL_heading20"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.maxParagraphChar}
                className="WM_GLOBAL_heading54"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <Show when={[name]}>
              <EditableContent
                text={name}
                alignment={nameAlignment}
                as={S.Name}
                maxCount={validation.maxNameChar}
                className="WM_GLOBAL_heading32"
                onChange={Actions.changeName}
                changeAlignment={Actions.changeNameAlignment}
              />
            </Show>
          </S.TextContent>
        </Show>
        <S.MusicWrapper>
          <ControlsWithModalOpener
            actions={Actions.musicActions(musicUrl)}
            style={{ width: '100%' }}
          >
            <S.MusicContent>
              {isIframe(musicUrl) ? (
                <S.IframeWrapper>
                  <Overlay />
                  <div dangerouslySetInnerHTML={{ __html: musicUrl }} />
                </S.IframeWrapper>
              ) : (
                <S.IframeWrapper>
                  <iframe
                    scrolling="no"
                    frameBorder="no"
                    src={`https://w.soundcloud.com/player/?url=${musicUrl}`}
                    title="Embedded music"
                  />
                </S.IframeWrapper>
              )}
            </S.MusicContent>
          </ControlsWithModalOpener>
        </S.MusicWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Music1
