/* @flow */
import { combineReducers } from 'redux'
import produce from 'immer'
import editor from './editor'
import semrush from './semrush'
import project from './project'
import cookieSettings from './cookie-settings'
import websiteLanguages from './website-languages'
import widgets from './widgets'
import meta from './meta'
import events from  './events'
import components from './components'
import { leftJoin } from '@editor/common/utils'
import { MAX_HISTORY_COUNT } from '../../conf/consts'

const storeInHistory = (state: TAppStateProject) => {
  const { past, frozen } = state
  const reachedLimit = past.length === MAX_HISTORY_COUNT
  state.past = [...(reachedLimit ? past.slice(1) : past), frozen]
}

function trackedProject(reducer) {
  const initalState = {
    past: [],
    present: reducer(null, {}), // empty action
    frozen: reducer(null, {}), // always valid state for undo/redo
    future: []
  }

  return (s: TAppStateProject = initalState, action: TProjectAction) =>
    produce(s, state => {
      switch (action.type) {
        case 'UNDO':
          if (state.past.length === 0) {
            return
          }
          {
            const { projectFileUrls } = action.value
            state.future = [state.present, ...state.future]
            const newPresent = state.past.pop()
            // replace all url-ids with actual url from projectFileUrls
            state.frozen = state.present = leftJoin(newPresent, projectFileUrls)
          }
          break
        case 'REDO':
          if (state.future.length === 0) {
            return
          }
          {
            const { projectFileUrls } = action.value
            state.past = [...state.past, state.present]
            const _newPresent = state.future.shift()
            state.frozen = state.present = leftJoin(
              _newPresent,
              projectFileUrls
            )
          }
          break
        case 'DROP_HISTORY':
          state.past = []
          state.future = []
          state.frozen = state.present
          break
        case 'STORE_IN_HISTORY':
          storeInHistory(state)
          break
        case 'FREEZE_PROJECT_DATA':
          state.frozen = state.present
          break
        default:
          state.present = reducer(state.present, action)
          break
      }
    })
}

const reducers = combineReducers({
  editor,
  project: trackedProject(project),
  components,
  cookieSettings,
  widgets,
  meta,
  websiteLanguages,
  semrush,
  events
})

export default reducers
