export const COOKIE_ALL_ACCEPTED_STATE = {
  analytics: true,
  marketing: true,
  functional: true
}

export const COOKIE_ALL_DECLINED_STATE = {
  analytics: false,
  marketing: false,
  functional: false
}
