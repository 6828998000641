/* @flow */
import styled from 'styled-components'
import Icon from '@website/common/components/Icon'
import THEME from './Header13.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  position: relative;
  min-height: ${({ isSocials }) => (isSocials ? '530px' : 'auto')};
  padding: 0 0 160px 0;
  background-position: right bottom;
  @media only screen and (max-width: 768px) {
    background-position: center center;
    padding: ${({ isEditing }) => (isEditing ? '80px 0' : '20px 0 80px 0')};
    height: auto;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 650px) {
    padding: 0 0 80px 0;
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: 60px;
  }
  .socials-block {
    position: absolute;
    right: 30px;
    bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    @media only screen and (max-width: 768px) {
      width: 90%;
      right: unset;
      transform: unset;
      position: initial;
      padding: 0 20px;
      margin-top: 50px;
    }
    .rf-add-btn {
      margin-left: initial;
    }
    .link {
      width: 36px;
      height: 36px;
      text-decoration: none;
      margin: 5px;
      background: ${getStyleForProp('socialBackground')};
      fill: ${getStyleForProp('socialColor')};
      border-radius: ${getStyleForProp('iconRadius')};
    }
    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 100px;
      background: ${getStyleForProp('socialBackground')};
      top: -120px;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
  .socials-wrapper {
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 768px) {
      flex-direction: row;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  height: 70%;
  display: flex;
  align-items: center;
  padding-top: ${({ topOffset }) => `${topOffset + 100}px`};
  @media only screen and (max-width: 1024px) {
    padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  }
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    justify-content: center;
    align-items: center;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    h1 {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
`

const Description = styled.h2`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${getStyleForProp('socialBackground')};
  margin: 0 0 20px 0;
`

const Line = styled.div`
  width: 2px;
  height: 100px;
  background: ${getStyleForProp('socialBackground')};
  margin: 0 0 20px 0;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const DownButton = styled.div`
  position: absolute;
  padding: 20px;
  bottom: 0;
  left: 50%;
  cursor: pointer;
  transform: translateY(-50%);
  transition: 500ms all cubic-bezier(0.84, 0.04, 1, 1);
  animation: arrowBounce 3s linear infinite;
  @keyframes arrowBounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0) translateX(-50%);
    }
    40% {
      transform: translateY(-20px) translateX(-50%);
    }
    60% {
      transform: translateY(-10px) translateX(-50%);
    }
  }
`

const ScrollIcon = styled(Icon)`
  fill: ${getStyleForProp('arrow')};
`

export default {
  StyledContainer,
  WmCustomContainer,
  ScrollIcon,
  HeaderContent,
  Title,
  Description,
  Line,
  Divider,
  DownButton
}
