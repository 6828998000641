/* @flow */

export default dispatcher => {
  const changeQuote = (newQuote: string) =>
    dispatcher({
      type: 'CHANGE_QUOTE',
      value: newQuote
    })

  const changeQuoteAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_QUOTE_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeQuote,
    changeQuoteAlignment
  }
}
