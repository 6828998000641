import { translate } from '@editor/common/utils/translations'

export const FULL = 'full'
export const TEXT = 'text'
export const FLAG = 'flag'

export const OPTIONS = [
  {
    tooltipText: translate('full_name_label'),
    layout: FULL
  },
  {
    tooltipText: translate('texts_only_label'),
    layout: TEXT
  },
  {
    tooltipText: translate('flags_only_label'),
    layout: FLAG
  }
]
