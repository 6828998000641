// @flow

import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

import { removeProjectPremiumData } from '@actions/project'
import { setNoLongerPremiumPopupState, getSiteData } from '@actions/editor'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { openUrl } from '@website/common/utils'
import { addAnalyticsEvent } from '@editor/common/utils'
import { getLocalizedURL } from '@editor/common/utils/translations'
import { getNoLongerPremiumPopupState, getSiteId } from '@selectors/editor'

import { TProps } from './types'
import * as Styled from './styled'

export const ATTENTION_ICON =
  'https://static.rfstat.com/renderforest/images/website_maker_images/Attention.svg'

const NoLongerPremiumPopup = ({
  isOpen,
  siteId,
  setNoLongerPremiumPopupState,
  removeProjectPremiumData,
  getSiteData
}: TProps) => {
  const onClose = useCallback(() => {
    setNoLongerPremiumPopupState(false)
  }, [])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        getSiteData()
      }
    }

    if (isOpen) {
      window.addEventListener('visibilitychange', handleVisibilityChange)
    }
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [isOpen])

  const upgradeWebsite = useCallback(() => {
    addAnalyticsEvent(
      'SiteMaker No Longer Premium',
      'Navigate to subscriptions'
    )
    openUrl(getLocalizedURL(`/subscription/24?site_id=${siteId}`))
  }, [])

  const makeUserFree = useCallback(() => {
    addAnalyticsEvent('SiteMaker No Longer Premium', 'Continue for free')
    removeProjectPremiumData()
  }, [])

  return (
    <Styled.NoLongerPremiumPopup isOpen={isOpen} onClose={onClose} disableClose>
      <Styled.PopupContent>
        <Styled.AttentionIcon src={ATTENTION_ICON} />
        <Styled.Heading>
          {translate('is_no_longer_premium_title')}
        </Styled.Heading>
        <Styled.SecondaryInfoText>
          {transformTextVariables(translate('is_no_longer_premium_text'), [
            {
              value: translate('premium_website'),
              Comp: BoldComp
            }
          ])}
        </Styled.SecondaryInfoText>
        <Styled.Note>
          <Styled.WarningIcon />
          <Styled.NoteText>
            {transformTextVariables(translate('is_no_longer_popup_note'), [
              {
                value: translate('get_link_to_invite_contributor_note_tr1'),
                Comp: BoldComp
              }
            ])}
          </Styled.NoteText>
        </Styled.Note>
        <Styled.ButtonsWrapper>
          <Styled.LinkButton size="small" type="info" onClick={makeUserFree}>
            {translate('continue_for_free')}
          </Styled.LinkButton>
          <Styled.Button size="small" type="info" onClick={upgradeWebsite}>
            {translate('upgrade_label', true)}
          </Styled.Button>
        </Styled.ButtonsWrapper>
      </Styled.PopupContent>
    </Styled.NoLongerPremiumPopup>
  )
}

const mapStateToProps = (state: TAppState) => ({
  isOpen: getNoLongerPremiumPopupState(state),
  siteId: getSiteId(state)
})

export default connect(mapStateToProps, {
  setNoLongerPremiumPopupState,
  removeProjectPremiumData,
  getSiteData
})(NoLongerPremiumPopup)
