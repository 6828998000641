export const validation = {
  headingMaxChar: 100,
  paragraphMaxChar: 300,
  name: { required: true },
  email: { required: true }
}

export const EMPTY_FIELD_MESSAGE = 'This field cannot be empty'
export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email'
export const SUCCESS_MESSAGE = 'Thank you for subscribing!'
