/* @flow */
import React, { useContext, useState, memo, useCallback, useMemo } from 'react'
import * as S from './Header54.styled'
import THEME from './Header54.theme'
import { validation } from './Header54.consts'
import ActionGetter from './Actions'
import Element from '@website/common/assets/svgr-icons/StarElement.svg'
import NavBar from '@website/common/components/NavBar'
import Socials from '@website/common/components/Socials'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import Image from '@website/common/components/Image'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SingleButton from '@website/common/components/SingleButton'
import If from '@website/common/components/Conditional'
import { scrollBottom } from '@website/common/utils'
import Controls from '@website/common/components/Controlled/Controls'
import { translate } from '@editor/common/utils/translations'

const DISTANCE_PER_SECOND = 80 // pixels

const Header54 = memo(props => {
  const {
    data: {
      title,
      subTitle,
      paragraph,
      subTitleAlignment,
      titleAlignment,
      paragraphAlignment,
      imageUrl,
      imgDimensions,
      imgCropParams,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      btnText,
      btnUrl,
      additionalText,
      additionalUrl,
      titleBtnUrl,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      socials,
      scrollText,
      hasElement,
      hasRuningText,
      runingText,
      icon
    },
    hasNextComponent
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const [itemWidth, setItemWidth] = useState(0)

  const windowWidth = typeof window !== 'undefined' && window.innerWidth

  const handleItemRefChange = useCallback(el => {
    if (!el) return
    setItemWidth(el.offsetWidth)
  }, [])

  const renderedItemsCount = useMemo(() => {
    if (!itemWidth) {
      return 0
    }

    const possibleItemsCount = windowWidth / itemWidth

    if (possibleItemsCount <= 1) return 4
    return Math.ceil(possibleItemsCount) + 2 * 2
  }, [itemWidth])

  const animationSpeed = itemWidth / DISTANCE_PER_SECOND

  const renderItems = useCallback(() => {
    const items = []
    items.length = renderedItemsCount
    return items.fill(null).map(() => (
      <S.ItemText
        isEditing={isEditing}
        animationSpeed={animationSpeed}
        className="item-box"
      >
        <If
          condition={icon}
          then={() => (
            <S.IconWrap isEditing={isEditing}>
              <ControlsWithModalOpener>
                <Icon size="large" color="white" name={icon} />
              </ControlsWithModalOpener>
            </S.IconWrap>
          )}
        />
        <S.Description
          className="WM_GLOBAL_heading20"
          isEditing={isEditing}
          dangerouslySetInnerHTML={{ __html: runingText }}
        />
      </S.ItemText>
    ))
  }, [renderedItemsCount, runingText, isEditing, animationSpeed])

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || socials.length
              ? () => (
                  <Socials
                    className="header-socials"
                    data={socials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.WmCustomContainer>
        <S.HeaderContent topOffset={navBarHeight}>
          <S.LeftContent isEditing={isEditing}>
            <EditableContent
              text={title}
              isEditing={isEditing}
              alignment={titleAlignment}
              required={!subTitle && !paragraph}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading42"
              as={S.Title}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <SingleButton btnUrl={btnUrl} btnText={btnText} />
          </S.LeftContent>
          <S.CenterContent>
            <S.ImageBox>
              <S.ImageWrapper>
                <ControlsWithImageCropper
                  actions={Actions.getImgActions(imageUrl)}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    sizes={imgDimensions}
                    defaultImgSrc={imageUrl}
                    asProps={{ imgCropParams }}
                    alt="Hero illustration"
                  />
                </ControlsWithImageCropper>
              </S.ImageWrapper>
              <S.ImageElement>
                <If
                  condition={hasElement}
                  then={() => (
                    <S.ElementWrapper isEditing={isEditing}>
                      <Controls
                        actions={Actions.removeElement}
                        style={{ width: '100%', height: '100%' }}
                      >
                        <Element />
                      </Controls>
                    </S.ElementWrapper>
                  )}
                  otherwise={() => (
                    <AddButton
                      onAdd={Actions.addElement}
                      style={{
                        margin: '0px'
                      }}
                      type="icon"
                      medium
                      toShow
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                    </AddButton>
                  )}
                />
              </S.ImageElement>
            </S.ImageBox>
            <If
              condition={hasRuningText}
              then={() => (
                <Controls
                  isRelativeToWrapper
                  tabIndex={1}
                  actions={Actions.removeRuningText}
                  style={{ width: '100%', height: '100%' }}
                >
                  <S.AnimationContainer isEditing={isEditing} tabIndex={1}>
                    {isEditing ? (
                      <S.ItemText isEditing={isEditing}>
                        <If
                          condition={icon}
                          otherwise={() => (
                            <AddButton
                              onAdd={Actions.addIcon}
                              style={{ marginRight: '1rem' }}
                              type="icon"
                              large
                              toShow
                            >
                              +
                            </AddButton>
                          )}
                          then={() => (
                            <S.IconWrap isEditing={isEditing}>
                              <ControlsWithModalOpener
                                actions={Actions.getIconActions(0)}
                              >
                                <Icon size="large" color="white" name={icon} />
                              </ControlsWithModalOpener>
                            </S.IconWrap>
                          )}
                        />
                        <EditableContent
                          isLinkControlHidden
                          text={runingText}
                          required
                          isEditing={isEditing}
                          as={S.Description}
                          maxCount={validation.subTitleMaxChar}
                          className="WM_GLOBAL_heading20"
                          onChange={Actions.runingText}
                        />
                      </S.ItemText>
                    ) : (
                      <>
                        <S.ItemTextOpacity
                          className="opacity"
                          ref={handleItemRefChange}
                        >
                          <If
                            condition={icon}
                            then={() => (
                              <S.IconWrap>
                                <ControlsWithModalOpener>
                                  <Icon
                                    size="large"
                                    color="white"
                                    name={icon}
                                  />
                                </ControlsWithModalOpener>
                              </S.IconWrap>
                            )}
                          />
                          <S.DescriptionOpacity
                            className="WM_GLOBAL_heading20"
                            dangerouslySetInnerHTML={{ __html: runingText }}
                          />
                        </S.ItemTextOpacity>
                        {renderItems()}
                      </>
                    )}
                  </S.AnimationContainer>
                </Controls>
              )}
              otherwise={() => (
                <AddButton
                  onAdd={Actions.addRuningText}
                  style={{
                    margin: '0px'
                  }}
                  type="icon"
                  medium
                  toShow
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_scrolling_text_label')}
                </AddButton>
              )}
            />
          </S.CenterContent>
          <S.RightContent isEditing={isEditing}>
            <EditableContent
              text={subTitle}
              as={S.SubTitle}
              required={!title && !paragraph}
              alignment={subTitleAlignment}
              maxCount={validation.subTitleMaxChar}
              className="WM_GLOBAL_heading32"
              onChange={Actions.changeSubTitle}
              changeAlignment={Actions.changeSubTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Paragraph}
              required={!subTitle && !title}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <Show when={[additionalUrl]}>
              <AdditionalLink
                idx={0}
                additionalLinkText={additionalText}
                additionalLinkUrl={additionalUrl}
              />
            </Show>
          </S.RightContent>
        </S.HeaderContent>
      </S.WmCustomContainer>
      <If
        condition={hasNextComponent}
        then={() => (
          <S.BottomContent
            isEditing={isEditing}
            onClick={() => scrollBottom(isEditing)}
          >
            <EditableContent
              text={scrollText}
              as={S.ScrollText}
              maxCount={validation.scrollMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={(newScrollText: string) => {
                Actions.changeScrollText(newScrollText)
              }}
            />
            <S.DownButton role="button">
              <S.ScrollIcon size="xlarge" name="icon-arrow_small_bottom" />
            </S.DownButton>
          </S.BottomContent>
        )}
      />
    </S.StyledContainer>
  )
})

export default Header54
