//@flow
import React, { useCallback, useState, memo } from 'react'
import { ThemeProvider } from 'styled-components'
import { useSelector } from 'react-redux'

import * as Styled from './styled'
import type { TPayPalButtonsProps } from './types'
import {
  PAYPAL_COLORS_RADIO_DATA,
  YOUR_COLORS_RADIO_DATA,
  CUSTOM_BUTTONS_STYLES,
  BUTTONS_STYLES,
  PAYPAL_COLORS,
  YOUR_COLORS
} from './consts'
import { PayPalButton } from '@website/common/components/PayPalButton'
import { getCurrentPageComponents, getVisualParams } from '@selectors'

const PayPalButtons = ({
  compId,
  activeStyle,
  title,
  setData
}: TPayPalButtonsProps) => {
  const initialColorsType = BUTTONS_STYLES.includes(activeStyle)
    ? YOUR_COLORS
    : PAYPAL_COLORS

  const [colorsType, setColorsType] = useState(initialColorsType)

  const components = useSelector(getCurrentPageComponents)
  const visualParams = useSelector(getVisualParams)

  const colorMode = components.find(
    component => component.id === compId
  ).colorMode

  const onPaypalColorsChange = useCallback(() => {
    setColorsType(PAYPAL_COLORS)
  }, [colorsType])

  const onYourColorsChange = useCallback(() => {
    setColorsType(YOUR_COLORS)
  }, [colorsType])

  const onButtonClick = style => {
    if (style === activeStyle) return

    setData(data => ({
      ...data,
      style
    }))
  }

  return (
    <ThemeProvider
      theme={{
        look: visualParams.look,
        colorMode,
        palette: visualParams.palette
      }}
    >
      <Styled.PayPalButtons>
        <Styled.RadioButton
          isActive={colorsType === PAYPAL_COLORS}
          onChange={onPaypalColorsChange}
          checked={colorsType}
          list={PAYPAL_COLORS_RADIO_DATA}
        />
        <Styled.RadioButton
          isActive={colorsType === YOUR_COLORS}
          onChange={onYourColorsChange}
          checked={colorsType}
          list={YOUR_COLORS_RADIO_DATA}
        />
        <Styled.ButtonsWrapper>
          {colorsType === PAYPAL_COLORS
            ? CUSTOM_BUTTONS_STYLES.map(style => (
                <Styled.PayPalButtonWrapper
                  onClick={() => onButtonClick(style)}
                  isActive={style === activeStyle}
                >
                  <PayPalButton
                    key={style}
                    buttonText={title}
                    buttonStyle={style}
                  />
                </Styled.PayPalButtonWrapper>
              ))
            : BUTTONS_STYLES.map(style => (
                <Styled.PayPalButtonWrapper
                  onClick={() => onButtonClick(style)}
                  isActive={style === activeStyle}
                >
                  <PayPalButton
                    key={style}
                    buttonText={title}
                    buttonStyle={style}
                  />
                </Styled.PayPalButtonWrapper>
              ))}
        </Styled.ButtonsWrapper>
      </Styled.PayPalButtons>
    </ThemeProvider>
  )
}

export default memo(PayPalButtons)
