/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Countdown2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  .datePicker {
    width: 290px;
    @media only screen and (max-width: 768px) {
      top: initial;
      bottom: -10%;
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
          @media only screen and (max-width: 768px) {
            padding-top: 40px;
          }
        `
      : css``}
  .circle {
    transform-origin: 50% 50%;
    transition: stroke-dashoffset 0.4s cubic-bezier(0.4, 0, 0, 1.7);
  }
  .line {
    transform: rotate(-90deg);
    fill: transparent;
    stroke: ${getStyleForProp('line')};
    position: relative;
    @media only screen and (max-width: 768px) {
      transform: scale(0.78) translateY(-23px) rotate(-90deg) ;
    }
    @media only screen and (max-width: 600px) {
      transform: scale(0.445) translateY(-96px) rotate(-90deg) ;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .DayPicker-Day {
    &:focus {
      outline: none !important;
    }
  }
  .DayPicker-Day--selected {
    color: white !important;
    background-color: rgb(56, 125, 255) !important;
    font-weight: 700;
  }
  .DayPicker-Day--today {
    background-color: rgba(56, 125, 255, 0.15) !important;
    font-weight: 500 !important;
    color: initial !important;
  }
  .clockerContainer {
    display: flex;
    justify-content: space-around;
  }
  .DayPicker {
    width: 100%;
  }
  .DayPicker-Day {
    min-width: 36px;
    box-sizing: border-box;
  }
  > .control-container {
    width: 100%;
    margin-top: 50px;
    h1 {
      margin-top: 0;
    }
  }
`

const DateContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  max-width: 840px;
  @media only screen and (max-width: 768px) {
    max-width: 660px;
  }
`

const DateTitle = styled.h4`
  color: ${getStyleForProp('heading')};
  text-align: center;
`

const Time = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: ${getStyleForProp('time')};
  text-align: center;
  font-size: 42px;
  font-weight: 600;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  @media only screen and (max-width: 768px) {
    font-size: 26px;
    width: 120px;
    height: 120px;
  }
  @media only screen and (max-width: 600px) {
    width: 68px;
    height: 68px;
  }
  p {
    position: relative;
    z-index: 1;
  }
`

const Date = styled.p`
  width: 100%;
  max-width: 150px;
  color: ${getStyleForProp('date')};
  font-weight: 400 !important;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 0;
  @media only screen and (max-width: 768px) {
    margin-top: -5px;
  }
  @media only screen and (max-width: 600px) {
    margin-top: -70px;
  }
`

const DateWrapper = styled.div`
  position: relative;
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-top: 50px;
  ${getTextStyleByAlignment};
  margin-bottom: 0 !important;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
`

export default {
  WmCustomContainer,
  DateContainer,
  StyledContainer,
  DateWrapper,
  Time,
  Date,
  DateTitle,
  Title
}
