// @flow

import React, { memo, useMemo } from 'react'
import { useHistoryWithSearch } from '@hooks'
import { CURRENT_LANGUAGE } from '@editor/conf/consts'

import { openUrl } from '@website/common/utils'
import { LANGUAGES } from './consts'

import * as Styled from './styled'

const origin = window.location.origin
const currentLanguageCode = CURRENT_LANGUAGE

const LanguagesDropdown = ({
  closeDropdown,
  contentRef,
  changeCurrentLang
}) => {
  const history = useHistoryWithSearch()

  const {
    location: { pathname }
  } = history

  const items = useMemo(
    () =>
      LANGUAGES.reduce((acc, { langCode, iconUrl, text }) => {
        const languageCode = langCode === 'en' ? '' : `/${langCode}`

        if (langCode !== currentLanguageCode) {
          acc.push({
            text,
            IconComp: () => <Styled.LanguageIcon src={iconUrl} />,
            onClick: () => {
              changeCurrentLang(text)
              openUrl(
                `${origin}${languageCode}/website-maker${pathname}`,
                false
              )
            }
          })
        }
        return acc
      }, []),
    [closeDropdown]
  )

  return (
    <Styled.Container>
      <Styled.List items={items}></Styled.List>
    </Styled.Container>
  )
}

export default memo(LanguagesDropdown)
