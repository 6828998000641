// @flow

import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import * as Styled from './styled'
import Button from '@editor/common/lib/Button'
import StatusAnimationWrapper from './StautsAnimationWrapper'
import { translate } from '@editor/common/utils/translations'

import type { TConnectDomainPopupProps } from './types'
import { useHistoryWithSearch } from '@hooks'
import { EDITOR_BASE, PAGE_BASE } from '@editor/conf/routes'

const ConnectDomainPopup = ({ isOpen, onClose }: TConnectDomainPopupProps) => {
  const { url } = useRouteMatch(EDITOR_BASE)
  const { url: urlForSettings } = useRouteMatch(PAGE_BASE) || {}
  const history = useHistoryWithSearch()

  const goToSettings = () => {
    const _url = urlForSettings || `${url}/edit/home`
    history.push(`${_url}/settings/domain`)
  }

  const handleConnectButtonClick = () => {
    onClose()
    goToSettings()
  }

  return (
    <Styled.ConnectDomainPopup
      className="connect-domain-popup"
      isOpen={isOpen}
      onClose={onClose}
      isMobileFullScreen
    >
      <Styled.ConnectDomainContainer>
        <StatusAnimationWrapper />
        <Styled.ConnectDomainPopupTitle>
          {translate('want_to_go_offline_label')}
        </Styled.ConnectDomainPopupTitle>
        <Styled.ConnectDomainPopupDescription>
          {translate('want_to_go_offline_description')}
        </Styled.ConnectDomainPopupDescription>
        <Button type="primary" onClick={handleConnectButtonClick}>
          {translate('connect_a_domain')}
        </Button>
      </Styled.ConnectDomainContainer>
    </Styled.ConnectDomainPopup>
  )
}

export default ConnectDomainPopup
