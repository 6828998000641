/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature5.styled'
import { validation } from './Feature5.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const Feature5 = memo(props => {
  const {
    data: {
      title,
      description,
      titleAlignment,
      paragraphAlignment,
      featureBlocks,
      buttons,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <EditableContent
          as={S.Title}
          alignment={titleAlignment}
          text={title}
          maxCount={validation.headingMaxChar}
          className="WM_GLOBAL_heading"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          as={S.Description}
          text={description}
          alignment={paragraphAlignment}
          maxCount={validation.paragraphMaxChar}
          className="WM_GLOBAL_paragraph"
          onChange={Actions.changeDescription}
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <Buttons data={buttons} />
        <S.BlocksWrap blockCount={featureBlocks.length}>
          <Mapper
            data={featureBlocks}
            render={(featureBlock, blockIdx) => (
              <Show
                when={[featureBlock.title, ...featureBlock.featureList]}
                key={blockIdx}
              >
                <S.BlockItemWrap>
                  <ControlsWithModalOpener
                    actions={Actions.blockActions({
                      idx: blockIdx,
                      showRemove:
                        featureBlocks.length > validation.featureBlocks.min
                    })}
                  >
                    <EditableContent
                      as={S.ListTitle}
                      text={featureBlock.title}
                      alignment={featureBlock.titleAlignment}
                      maxCount={validation.blockTitleMaxChar}
                      className="WM_GLOBAL_heading20"
                      onChange={Actions.changeBlockTitle(blockIdx)}
                      changeAlignment={Actions.changeBlockTitleAlignment(
                        blockIdx
                      )}
                    />
                    <S.BlockList>
                      <Mapper
                        data={featureBlock.featureList}
                        render={(feature, listIdx) => (
                          <ControlsWithModalOpener
                            key={listIdx}
                            style={{ display: 'block' }}
                            actions={Actions.listActions(blockIdx, listIdx)}
                          >
                            <S.ListItem>
                              <S.Icon size="large" name="check" />
                              <EditableContent
                                text={feature}
                                maxCount={validation.checkmarkMaxChar}
                                as={S.BlockListItem}
                                className="WM_GLOBAL_paragraph18"
                                onChange={(newBlockListTitle: string) =>
                                  Actions.changeBlockListItem(
                                    newBlockListTitle,
                                    blockIdx,
                                    listIdx
                                  )
                                }
                              />
                            </S.ListItem>
                          </ControlsWithModalOpener>
                        )}
                      />
                      <AddButton
                        style={{
                          marginTop: '1rem'
                        }}
                        toShow={
                          featureBlock.featureList.length <
                          validation.featureList.max
                        }
                        onAdd={() => Actions.addBlockListItem(blockIdx)}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_item_label')}
                      </AddButton>
                    </S.BlockList>
                  </ControlsWithModalOpener>
                </S.BlockItemWrap>
              </Show>
            )}
          />
        </S.BlocksWrap>
        <AddButton
          style={{ marginTop: '2rem' }}
          toShow={featureBlocks.length < validation.featureBlocks.max}
          onAdd={Actions.addBlock}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_feature_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature5
