/* @flow */
import {
  cropableImageModalActionMaker,
  cardBgImageActionMaker,
  eventSettingsActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitleAlignment,
  changeParagraphAlignment,
  changeCardBgOverlayTransparency
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeSubtitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeDateAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_DATE_ALIGNMENT',
      value: alignment
    })
  }

  const changeLocationAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_LOCATION_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const addImage = (imgName: string) => {
    dispatcher({
      type: 'ADD_IMAGE',
      value: { imgName }
    })
  }

  const centerImgActions = (imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'RSVP3Image',
      showRemove: true,
      imageUrl
    })
  ]

  const addCardImage = () => {
    dispatcher({
      type: 'ADD_CARD_IMAGE'
    })
  }

  const changeCardImgAction = (value: Object, undoable: boolean) => ({
    type: 'CHANGE_CARD_IMAGE',
    value,
    undoable
  })

  const removeCardImgUrl = () => ({
    type: 'REMOVE_CARD_IMAGE'
  })

  const cardImageActions = (
    imageUrl: string,
    bgOverlayTransparency: number
  ) => [
    ...cardBgImageActionMaker(dispatcher)({
      keyForSizes: 'RSVP3CardImage',
      actions: {
        changeImage: ({ url, dimensions, undoable }) =>
          dispatcher(changeCardImgAction({ url, dimensions }, undoable)),
        removeImage: removeCardImgUrl,
        changeTransparency: value => {
          dispatcher(changeCardBgOverlayTransparency(value))
        }
      },
      imageUrl,
      bgOverlayTransparency
    })
  ]

  return {
    addImage,
    addCardImage,
    changeSubtitleAlignment,
    changeDateAlignment,
    changeLocationAlignment,
    cardImageActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    centerImgActions,
    eventSettingsActionMaker
  }
}
