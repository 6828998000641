/* @flow */

export const validation = {
  slogan: { required: true },
  name: { required: true },
  headingMaxChar: 50,
  paragraphMaxChar: 100,
  contentParagraphMaxChar: 380,
  sloganMaxChar: 100,
  nameMaxChar: 20,
  paragraph2: { required: true }
}
