import styled, { css, createGlobalStyle } from 'styled-components'

const mobileItemStyles = () => css`
  height: 40px;
  border: 1px solid #c1c9e0;
  border-radius: 6px;
  padding: 0 15px;
  color: #545f7e;
  flex-direction: row-reverse;
  justify-content: space-between;
`

export const GlobalStyles = createGlobalStyle`
  .media-change-flyout, .logo-change-flyout, .navbar-logo-change-flyout {
    font-family: 'Montserrat', sans-serif;
    padding: 10px 0;
  }
`

export const Options = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #c1c9e0;
  border-radius: 10px;
  box-shadow: 0 6px 12px #3271e61a;
  overflow: hidden;

  @media only screen and (min-width: 769px) {
    .upload-button {
      height: 50px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      cursor: pointer;
      transition: all 300ms ease;

      & > div {
        width: 24px;
        height: 24px;
        margin-right: 15px;
        transition: all 300ms ease;

        svg {
          fill: #545f7e;
        }
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: #545f7e;
        margin-left: 0;
        transition: all 300ms ease;
      }

      &:hover {
        background-color: #eef5ff;

        & > div {
          svg {
            fill: #252e48;
          }
        }

        span {
          color: #252e48;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    border: none;
    border-radius: 0;
    padding: 20px;
    box-shadow: none;
    box-sizing: border-box;
    background-color: #ffffff;

    .upload-button {
      display: flex;
      align-items: center;
      ${mobileItemStyles};

      & > div {
        width: 24px;
        height: 24px;
        svg {
          fill: #545f7e;
        }
      }

      span {
        font-size: 14px;
        font-weight: 500;
      }
      margin-bottom: 10px;
    }
  }
`

export const Option = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  cursor: pointer;
  user-select: none;
  transition: all 300ms ease;
  white-space: nowrap;

  p {
    font-size: 14px;
    font-weight: 500;
    transition: all 300ms ease;
  }

  @media only screen and (min-width: 769px) {
    svg {
      margin-right: 15px;
      transition: all 300ms ease;
    }

    svg {
      fill: #545f7e;
    }

    p {
      color: #545f7e;
    }

    &:hover {
      background-color: #eef5ff;
      svg {
        fill: #252e48;
      }
      p {
        color: #252e48;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    ${mobileItemStyles};
    svg {
      fill: #545f7e;
    }

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const MobileContainer = styled.div`
  background-color: #ffffff;
  border-radius: 10px 10px 0 0;
`
