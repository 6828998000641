/* @flow */
import styled from 'styled-components'

import THEME from './Portfolio9.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)}
  padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : '0')};
  @media only screen and (max-width: 768px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 40px 0' : '0')};
  }
`

const WmCustomContainer = styled.div``

const BlockContainer = styled.div`
  .link-section {
    margin: 20px auto 0 auto;
  }
  .link-wrapper {
    justify-content: center;
  }
  .learn-more-link {
    margin-left: 5px;
    color: ${getStyleForProp('link')};
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
`

const Content = styled.div`
  width: 30%;
  flex-grow: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 44px;
  box-sizing: border-box;
  @media only screen and (max-width: 1280px) and (min-width: 1024px) {
    padding: 0 6px;
  }
  @media only screen and (max-width: 1024px) {
    width: 45%;
    padding: 10px 40px;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding: 30px 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  & > .control-container {
    margin-bottom: 30px;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const BlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 74%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
    overflow: hidden;
  }
  .lazy-figure {
    padding-top: 74%;
    height: initial;
    position: relative;
  }
`

const ImageWrapper = styled.div`
  width: 30%;
  flex-grow: 1;
  font-size: 0;
  &.image-0 {
    order: -1;
  }
  @media only screen and (max-width: 1024px) {
    width: 45%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const AddButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
  .add-button-wrap {
    margin: 0 6px;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    .add-button-wrap {
      margin: 6px 0;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Paragraph,
  BlocksWrapper,
  ImageWrapper,
  Img,
  Content,
  AddButtonsWrap,
  BlockContainer,
  ControlContainer
}
