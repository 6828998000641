/* @flow */
import React, { useContext } from 'react'
import S from './Footer.styled'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import { ProjectLinksContext } from '@contexts'
import { LinksList } from '@website/common/components/ProjectLinks/ProjectLinks.Helper'
import ActionGetter from './Actions'
import { translate } from '@editor/common/utils/translations'

const Footer = ({ dispatch }) => {
  const { footerLinks } = useContext(ProjectLinksContext)
  const Actions = ActionGetter(dispatch)
  return (
    <S.LinkContainer>
      <LinksList list={footerLinks} actions={Actions} />
      <AddButton toShow={true} onAdd={Actions.addLink}>
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_link_label')}
      </AddButton>
    </S.LinkContainer>
  )
}

export default Footer
