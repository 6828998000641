/* @flow */
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

import {
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeFormData = (value, key) => {
    dispatcher({
      type: 'CHANGE_FORM_DATA',
      value: { value, key }
    })
  }

  const changeWorkingHoursValue = (idx, value) => {
    dispatcher({
      type: 'CHANGE_HOURS_VALUE',
      value: { idx, value }
    })
  }

  const changeWorkingHoursLabel = (idx, label) => {
    dispatcher({
      type: 'CHANGE_HOURS_LABEL',
      value: { idx, label }
    })
  }
  const changeWorkingHoursButtonText = (buttonText: string) => {
    dispatcher({
      type: 'CHANGE_HOURS_BUTTON_TEXT',
      value: { buttonText }
    })
  }

  const changeWorkingHoursTitle = (title: string) => {
    dispatcher({
      type: 'CHANGE_HOURS_TITLE',
      value: { title }
    })
  }

  const removeServiceOption = serviceIdx => ({
    type: 'REMOVE_SERVICE_OPTION',
    value: { serviceIdx }
  })

  const addServiceOption = () => {
    dispatcher({
      type: 'ADD_SRVICE_OPTION'
    })
  }

  const changeService = (service, idx) => {
    dispatcher({
      type: 'CHANGE_SERVICE',
      value: { service, idx }
    })
  }

  const toggleRequiredField = inputLabel => {
    dispatcher({
      type: 'TOGGLE_REQUIRED_FIELDS',
      value: { inputLabel }
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  return {
    changeFormData,
    changeService,
    addServiceOption,
    toggleRequiredField,
    changeWorkingHoursLabel,
    changeWorkingHoursValue,
    changeWorkingHoursTitle,
    changeWorkingHoursButtonText,
    changeTitleAlignment: _changeTitleAlignment,
    serviceOptionActions: idx => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeServiceOption(idx))
      }
    ]
  }
}
