/* @flow */
import {
  videoModalActionMaker,
  imageModalActionMaker
} from '@website/components/ActionMakers'
import { addButton } from '@website/components/CommonActions'

export default dispatcher => {
  const changeText = (newText: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_TEXT',
      value: { newText, idx }
    })

  const changeTextAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_TEXT_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addButton,
    changeText,
    changeTextAlignment,
    addTitle: () => dispatcher({ type: 'ADD_TITLE' }),
    addParagraph: () => dispatcher({ type: 'ADD_PARAGRAPH' }),
    getVideoActions: videoModalActionMaker(dispatcher),
    getImageActions: imageModalActionMaker(dispatcher)
  }
}
