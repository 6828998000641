export const validation = {
  titleMaxChar: 100,
  paragraphMaxChar: 300,
  blockTitleMaxChar: 100,
  blockParagraphMaxChar: 600,
  blocks: {
    min: 1,
    max: 12
  }
}

