/* @flow */
import styled from 'styled-components'

import THEME from './Pricing1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Content = styled.div`
  width: 70%;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0 0 40px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  > :first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const PricingCardWrap = styled.div`
  &:only-child {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
`

const CardBody = styled.div`
  padding: 16px 40px;
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  margin-top: 20px;
  ${getTextStyleByAlignment};
`

const PriceCard = styled.div`
  background-color: white;
  box-sizing: border-box;
  width: 320px;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('borderRadius')};
  border: ${getStyleForProp('border')};
  display: flex;
  flex-direction: column;
  > .control-container:first-child {
    margin-top: 40px;
    width: calc(100% - 80px);
    box-sizing: border-box;
    div {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
  & > .control-container {
    margin: 40px 40px 0 40px;
    & > div {
      margin: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }
`

const Price = styled.div`
  font-weight: 800;
  margin-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  ${getTextStyleByAlignment};
`

const OptionItem = styled.article`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  padding: 10px 0;
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.secondaryDark
}))`
  padding-right: 16px;
  flex-shrink: 0;
`

const Option = styled.p`
  word-break: break-word;
`
const CardFooter = styled.div`
  padding: 0 40px 40px 40px;
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    ${getStyleForProp('buttonBackground')};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    max-width: 260px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`
export default {
  StyledContainer,
  WmCustomContainer,
  Content,
  PricingCardWrap,
  CardBody,
  Title,
  Description,
  PriceCard,
  Price,
  OptionItem,
  CardFooter,
  Option,
  Icon
}
