/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addIcon,
  addButton
} from '@website/components/CommonActions'
import {
  cropableImageModalActionMaker,
  iconActionMaker,
  buttonModalActionMaker
} from '@website/components/ActionMakers'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatch => {
  const changeSubTitle = (newSubTitle: string) => {
    dispatch({
      type: 'CHANGE_SUBTITLE',
      value: newSubTitle
    })
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeScrollText = (newScrollText: string) => {
    dispatch({
      type: 'CHANGE_SCROLL_TEXT',
      value: newScrollText
    })
  }

  const addElement = () => {
    dispatch({
      type: 'ADD_ELEMENT'
    })
  }

  const removeElement = [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        dispatch({ type: 'REMOVE_ELEMENT' })
      },
      toShow: true
    }
  ]

  const addRuningText = () => {
    dispatch({
      type: 'ADD_RUNING_TEXT'
    })
  }

  const removeRuningText = [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        dispatch({ type: 'REMOVE_RUNING_TEXT' })
      },
      toShow: true
    }
  ]

  const runingText = (newRuningText: string) => {
    dispatch({
      type: 'CHANGE_RUNING_TEXT',
      value: newRuningText
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSubTitle,
    changeSubTitleAlignment,
    changeScrollText,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatch)({
        showRemove: false,
        keyForSizes: 'header54',
        imageUrl
      }),
    btnActions: buttonModalActionMaker(dispatch),
    addButton: () => dispatch(addButton()),
    addElement,
    removeElement,
    addRuningText,
    removeRuningText,
    runingText,
    getIconActions: iconActionMaker(dispatch),
    addIcon: () => dispatch(addIcon(0))
  }
}
