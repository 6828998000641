export const INTENT_ELEMENTS_DATA = [
  {
    key: 'Commercial',
    value: '0',
    tooltipText: 'The user wants to investigate brands or services.',
    color: '#A75800',
    bgColor: '#FCE081',
    bgHoverColor: '#EF9800'
  },
  {
    key: 'Informational',
    value: '1',
    tooltipText: 'The user wants to find an answer to a specific question.',
    color: '#006DCA',
    bgColor: '#C4E5FE',
    bgHoverColor: '#2BB3FF'
  },
  {
    key: 'Navigational',
    value: '2',
    tooltipText: 'The user wants to find a specific page or site.',
    color: '#8649E1',
    bgColor: '#EDD9FF',
    bgHoverColor: '#AB6CFE'
  },
  {
    key: 'Transactional',
    value: '3',
    tooltipText: 'The user wants to complete an action (conversion).',
    color: '#007C65',
    bgColor: '#9EF2C9',
    bgHoverColor: '#00C192'
  }
]
