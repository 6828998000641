export const KEYWORD_TABLE_TABS = [
  {
    key: 'phrase_questions',
    label: 'Questions',
    tooltipText:
      'A list of question-based keywords that contain your seed keyword.'
  },
  {
    key: 'phrase_fullsearch',
    label: 'Broad Match',
    tooltipText:
      'Any variation of your seed keyword or keyword phrase in any order.'
  },
  {
    key: 'phrase_related',
    label: 'Related',
    tooltipText: 'A list of keywords that are similar to your seed keyword.'
  }
]
