/* @flow */
import React, { createContext, useCallback, useState } from 'react'

export const EditingContext = createContext()
export const DispatchContext = createContext()
export const MobileDetectContext = createContext()
export const ProjectLinksContext = createContext()
export const ZoomOutContext = createContext()
export const MediaLibContext = createContext()
export const SiteIdContext = createContext()
export const SiteHashContext = createContext()
export const PageContext = createContext()
export const UpgradePopupContext = createContext()
export const ExtraLinksLabelContext = createContext()
export const LinkWrapperContext = createContext({ routeBase: '/' })
export const ControlModalsContext = createContext()
export const GDPRPrivacyPolicyContext = createContext()
export const WebsiteLanguagesContext = createContext()
export const BackgroundSidebarContext = createContext()

export const withEditingContext = Comp => props =>
  (
    <EditingContext.Consumer>
      {({ isEditing }) => <Comp {...props} isEditing={isEditing} />}
    </EditingContext.Consumer>
  )

export const withDispatchContext = Comp => props =>
  (
    <DispatchContext.Consumer>
      {componentDispatcher => (
        <Comp {...props} componentDispatcher={componentDispatcher} />
      )}
    </DispatchContext.Consumer>
  )

export const withMobileDetectContext = Comp => props =>
  (
    <MobileDetectContext.Consumer>
      {isMobile => <Comp {...props} isMobile={isMobile} />}
    </MobileDetectContext.Consumer>
  )

export const withSiteId = Comp => props =>
  (
    <SiteIdContext.Consumer>
      {siteId => <Comp {...props} siteId={siteId} />}
    </SiteIdContext.Consumer>
  )

export const withSiteHash = Comp => props =>
  (
    <SiteHashContext.Consumer>
      {siteHash => <Comp {...props} siteHash={siteHash} />}
    </SiteHashContext.Consumer>
  )

export const BackgroundSidebarProvider = ({ children }) => {
  const [isOpened, setOpenedState] = useState(false)

  const open = useCallback(() => {
    setOpenedState(true)
  }, [])

  const close = useCallback(() => {
    setOpenedState(false)
  }, [])

  return (
    <BackgroundSidebarContext.Provider value={{ isOpened, open, close }}>
      {children}
    </BackgroundSidebarContext.Provider>
  )
}
