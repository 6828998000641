//@flow
import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import { translate } from '@editor/common/utils/translations'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import SemrushLogo from '@editor/common/assets/svgr-icons/semrush_logo.svg'
import CloseIcon from '@editor/common/assets/svgr-icons/cancel.svg'
import UnpinIcon from '@editor/common/assets/svgr-icons/semrush-unpin_icon.svg'
import { pinOrUnpinSemrushSidebar } from '@actions'
import * as Styled from './styled'

const SidebarHeader = ({ onClose, pinOrUnpinSemrushSidebar }) => {
  const unpinSidebarToEditor = useCallback(() => {
    pinOrUnpinSemrushSidebar(false)
  }, [])

  return (
    <Styled.SidebarHeader>
      <Styled.SidebarTitleWrapper>
        <SemrushLogo />
        <Styled.SidebarTitle>
          {translate('keyword_magic_tool_label')}
        </Styled.SidebarTitle>
      </Styled.SidebarTitleWrapper>
      <Styled.IconsWrapper>
        <TooltipWrapper text={translate('unpin_from_editor')}>
          <Styled.PinIconWrapper onClick={unpinSidebarToEditor}>
            <UnpinIcon />
          </Styled.PinIconWrapper>
        </TooltipWrapper>
        <CloseIcon onClick={onClose} className="close_icon" />
      </Styled.IconsWrapper>
    </Styled.SidebarHeader>
  )
}

export default connect(null, {
  pinOrUnpinSemrushSidebar
})(SidebarHeader)
