// @flow
import { selectHeader15logoUrl } from '@selectors'
import { copyLogoAPI } from '@api'
import { optimizeImage } from '@actions/utility'
import {
  patchProjectData,
  setLogoApplyingState,
  triggerApiError,
  createNewProject,
  setInitialLogoApplyingState
} from '@actions'
import Header15Meta from '@website/components/Header15/Header15.meta'
import Footer7Meta from '@website/components/Footer7/Footer7.meta'

const optimizeLogoAndApply = () => async (dispatch, getState) => {
  const state = getState()
  const logoUrl = selectHeader15logoUrl(state)

  try {
    const { imgDimensions } = await optimizeImage(
      logoUrl,
      'header15ImgSizes',
      siteId
    )
    dispatch({
      type: 'APPLY_LOGO_TO_HEADER15',
      value: { logoUrl, logoDimensions: imgDimensions }
    })
  } catch (e) {
    dispatch(triggerApiError(e))
  }
}

export const applySitePreset = (cb: void) => async (dispatch) => {
  try {
    dispatch(setLogoApplyingState(true))

    await dispatch(createNewProject())

    dispatch(optimizeLogoAndApply())

    dispatch(
      patchProjectData({
        onSuccess: () => {
          cb()
        }
      })
    )
  } catch (e) {
    dispatch(triggerApiError(e))
  } finally {
    dispatch(setLogoApplyingState(false))
  }
}

const getLogoUrlAndApply = (logoId: string) => async dispatch => {
  try {
    dispatch(setInitialLogoApplyingState(true))

    const {
      data: [logoData]
    } = await copyLogoAPI({ logoProjectIds: [logoId] })

    const { filePath } = logoData
    const logoDimensions = {
      large: filePath,
      medium: filePath,
      small: filePath,
      xlarge: filePath
    }

    dispatch({
      type: 'APPLY_LOGO_TO_HEADER15',
      value: { logoUrl: filePath, logoDimensions }
    })
  } catch (e) {
    dispatch(triggerApiError(e))
  } finally {
    dispatch(setInitialLogoApplyingState(false))
  }
}

export const initializeSitePreset = (logoId: string) => dispatch => {
  dispatch({
    type: 'ADD_MULTIPLE_COMPONENTS',
    value: {
      activePagePath: '/',
      atIndex: 0,
      componentsMeta: [Header15Meta, Footer7Meta]
    },
    undoable: false,
    needToSendRevision: false
  })

  dispatch(getLogoUrlAndApply(logoId))
}
