import styled, { css } from 'styled-components'

const stylesByColor = {
  default: '',
  black: css`
    filter: brightness(0);
  `,
  white: css`
    filter: brightness(0) invert(1);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

export const StyledResizerHolder = styled.div`
  cursor: ${({ isCatched }) => (isCatched ? 'grabbing' : 'grab')};
  display: ${({ isCatched }) => (isCatched ? 'flex' : 'none')};
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  transform: translate(50%, 50%);
  border-radius: 100%;
  background-color: #363f5a;
  height: 24px;
  width: 24px;
  align-items: center;
  justify-content: center;
`

export const StyledResizerContainer = styled.div`
  position: relative;
  &:hover > ${StyledResizerHolder} {
    display: flex;
  }
`

export const StyledImg = styled.img`
  height: auto;
  object-fit: contain;
  ${getStyleByColor};
`
