// @flow
import React, { useCallback } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import UndoIcon from '@editor/common/assets/svgr-icons/Undo_icon.svg'
import RedoIcon from '@editor/common/assets/svgr-icons/Redo_icon.svg'
import type { TUndoRedoProps } from './types'
import { getRedoCount, getUndoCount } from '@selectors'
import { redo, undo } from '@actions/project'

const UndoRedo = ({ undoCount, redoCount, undo, redo }: TUndoRedoProps) => {
  const onUndoClick = useCallback(() => {
    if (undoCount > 0) {
      undo()
    }
  }, [undoCount])

  const onRedoClick = useCallback(() => {
    if (redoCount > 0) {
      redo()
    }
  }, [redoCount])

  return (
    <Styled.UndoRedoContainer>
      <Styled.IconWrapper
        role="button"
        onClick={onUndoClick}
        hasCount={undoCount !== 0}
      >
        <UndoIcon />
      </Styled.IconWrapper>
      <Styled.IconWrapper
        role="button"
        onClick={onRedoClick}
        hasCount={redoCount !== 0}
      >
        <RedoIcon />
      </Styled.IconWrapper>
    </Styled.UndoRedoContainer>
  )
}

const mapStateToProps = state => ({
  undoCount: getUndoCount(state),
  redoCount: getRedoCount(state)
})

export default connect(mapStateToProps, { undo, redo })(UndoRedo)
