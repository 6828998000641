/* @flow */
import meta from '../Portfolio11.meta'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'

export default dispatcher => {
  const changeBlockTitle = (newTitle: string, tabIdx) => ({
    type: 'CHANGE_BLOCK_TITLE',
    value: { newTitle, tabIdx }
  })
  const addRow = (galleryTabIdx: string, galleryRowIdx) => () =>
    dispatcher({
      type: 'ADD_ROW',
      value: { galleryTabIdx, galleryRowIdx }
    })

  const addTab = () =>
    dispatcher({
      type: 'ADD_TAB'
    })

  const removeRow = (galleryTabIdx: string, galleryRowIdx) => () =>
    dispatcher({
      type: 'REMOVE_ROW',
      value: { galleryTabIdx, galleryRowIdx }
    })

  const imageActions = (
    galleryTabIdx: number,
    galleryRowIdx: number,
    rowCount: number,
    itemIdx: number,
    imageUrl: string,
    mediaFlyoutShift
  ) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'Portfolio11',
      showRemove: false,
      additionalArgs: { galleryTabIdx, galleryRowIdx, itemIdx },
      imageUrl,
      mediaFlyoutShift
    })
  ]

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const _changeBlockTitle = (newTitle, tabIdx) => {
    dispatcher(changeBlockTitle(newTitle, tabIdx))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle: _changeBlockTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addTab,
    addRow,
    removeRow,
    imageActions,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
