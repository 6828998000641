/* @flow */
import React, { useContext, useState, useRef, useEffect, memo } from 'react'
import SlickSlider from 'react-slick'
import * as S from './Team6.styled'
import { validation } from './Team6.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import Slider from '../CenteredSlider'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Team6 = memo(
  ({
    data: { blocks, title, titleAlignment, paragraph, paragraphAlignment }
  }) => {
    const [activeIdx, setActiveIdx] = useState(parseInt(blocks.length / 2))
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const slickSliderRef = useRef(null)

    useEffect(() => {
      slickSliderRef.current && slickSliderRef.current.slickGoTo(activeIdx)
    }, [slickSliderRef.current, activeIdx])

    const showRemove = blocks.length > validation.blocks.min

    return (
      <S.Container isEditing={isEditing}>
        <S.HeaderTexts>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            className="WM_GLOBAL_heading42"
            required={validation.title.required}
            maxCount={validation.titleMaxChar}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            className="WM_GLOBAL_paragraph18"
            maxCount={validation.paragraphMaxChar}
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.HeaderTexts>
        <S.WmCustomContainer>
          <S.DotS className="left light" isHidden={blocks.length < 4} />
          <S.DotS className="right light" isHidden={blocks.length < 4} />
          <S.DotS className="right dark" isHidden={blocks.length < 4} />
          <S.DotL className="dotL left" isHidden={blocks.length < 4} />
          <S.DotL className="dotL middle" isHidden={blocks.length < 4} />
          <div className="SliderContainer">
            <Slider
              swipeToSlide={!isEditing}
              swipe={!isEditing}
              accessibility={!isEditing}
              onBeforeChange={setActiveIdx}
            >
              {blocks.map((block: string, idx: number) => (
                <ControlsWithImageCropper
                  key={idx}
                  hideControls={idx !== activeIdx}
                  toPropagate={false}
                  actions={Actions.imageActions(idx, showRemove, block.imgUrl)}
                  isRelativeToWrapper
                >
                  <Image
                    withLazy={false}
                    as={S.Img}
                    alt="Slider image"
                    sizes={block.imgDimensions}
                    defaultImgSrc={block.imgUrl}
                    asProps={{
                      imgCropParams: block.imgCropParams
                    }}
                  />
                </ControlsWithImageCropper>
              ))}
            </Slider>
          </div>
          <SlickSlider
            className="team6-slick-slider"
            infinite
            speed={500}
            fade
            slidesToShow={1}
            touchMove={false}
            ref={slickSliderRef}
            swipeToSlide={!isEditing}
            swipe={!isEditing}
            accessibility={!isEditing}
          >
            {blocks.map((block, idx) => (
              <S.Info key={idx}>
                <EditableContent
                  text={block.name}
                  as={S.Name}
                  isEditing={isEditing}
                  alignment={block.nameAlignment}
                  className="WM_GLOBAL_heading20"
                  required={validation.blocks.name.required}
                  maxCount={validation.nameMaxChar}
                  onChange={(newName: string) => {
                    Actions.changeName(newName, idx)
                  }}
                  changeAlignment={(alignment: string) =>
                    Actions.changeNameAlignment(alignment, idx)
                  }
                />
                <EditableContent
                  text={block.position}
                  as={S.Position}
                  alignment={block.positionAlignment}
                  className="WM_GLOBAL_paragraph14"
                  maxCount={validation.positionMaxChar}
                  onChange={(newPosition: string) => {
                    Actions.changePosition(newPosition, idx)
                  }}
                  changeAlignment={(alignment: string) =>
                    Actions.changePositionAlignment(alignment, idx)
                  }
                />
                <EditableContent
                  text={block.slogan}
                  as={S.Slogan}
                  alignment={block.sloganAlignment}
                  className="WM_GLOBAL_paragraph18"
                  maxCount={validation.sloganMaxChar}
                  onChange={(newSlogan: string) => {
                    Actions.changeSlogan(newSlogan, idx)
                  }}
                  changeAlignment={(alignment: string) =>
                    Actions.changeSloganAlignment(alignment, idx)
                  }
                />
                <S.SocialsWrapper>
                  <Mapper
                    data={block.socials}
                    render={(social, socialIdx) => {
                      const lastIndex = block.socials.length - 1

                      return (
                        <ControlsWithModalOpener
                          key={socialIdx}
                          actions={Actions.socialActions(
                            socialIdx,
                            social.socialUrl,
                            idx
                          )}
                          alignment={
                            socialIdx >= lastIndex - 1 ? 'right' : 'left'
                          }
                        >
                          <LinkWrapper
                            url={social.socialUrl}
                            linkProps={{
                              'aria-label': 'Social link'
                            }}
                          >
                            <S.Icon size="normal" name={social.icon} />
                          </LinkWrapper>
                        </ControlsWithModalOpener>
                      )
                    }}
                  />
                  <AddButton
                    onAdd={() => dispatcher(Actions.addSocial(idx))}
                    toShow={
                      block.socials.length < validation.blocks.socials.max
                    }
                    style={{ marginLeft: '10px' }}
                    type="icon"
                    large
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                  </AddButton>
                </S.SocialsWrapper>
              </S.Info>
            ))}
          </SlickSlider>
        </S.WmCustomContainer>
        <AddButton
          onAdd={() => Actions.addSlide(activeIdx)}
          toShow={blocks.length < validation.blocks.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </S.Container>
    )
  }
)

export default Team6
