/* @flow */

import React, { useContext, memo } from 'react'
import S from './Text10.styled'
import { validation } from './Text10.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { EditingContext } from '@contexts'
import { DispatchContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Buttons from '@website/common/components/Buttons'

const Text10 = memo(props => {
  const {
    data: {
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      buttons
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph, ...buttons]}>
          <S.LeftBlock>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              required={validation.title.required}
              maxCount={validation.titleMaxChar}
              className="WM_GLOBAL_heading32"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
          </S.LeftBlock>
          <S.TextButtons>
            <S.ParagraphWrap>
              <S.Line alignment={title ? titleAlignment : null} />
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                required={validation.paragraph.required}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </S.ParagraphWrap>
            <Buttons data={buttons} />
          </S.TextButtons>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text10
