/* @flow */

module.exports = {
  id: 'CTA11',
  thumbnailRatio: 0.49,
  isUnique: false,
  label: 'CTA11',
  category: 'cta',
  colorMode: 'LIGHT',
  orientation: 'LEFT',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/CTA11-1.0.0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/CTA11m-1.0.0.jpg',
  metaData: {
    buttons: {
      _type: 'list',
      _min: 0,
      _max: 2,
      _value: []
    },
    backgroundImgUrl: {
      _type: 'backgroundImage',
      _dimensionsKey: 'backgroundImgDimensions',
      _optimizationSizes: [
        { _width: 800 },
        { _width: 1200 },
        { _width: 1600 },
        { _width: 2200 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg'
    },
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg'
    },
    bgOverlayTransparency: 0,
    title: {
      _type: 'title',
      _min: 1,
      _max: 60,
      _value: 'Mobile management application'
    },
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 200,
      _value:
        'Introducing one of our new products - Mobile Business Management. Get it for free on the App Store or Google Play, and easily manage your information from all edges of the world.'
    },
    appleBackground: {
      _type: 'image',
      _dimensionsKey: 'appleBackgroundDimensions',
      _optimizationSizes: [
        { _width: 400 },
        { _width: 600 },
        { _width: 600 },
        { _width: 600 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg'
    },
    appleBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg'
    },
    androidBackground: {
      _type: 'image',
      _dimensionsKey: 'appleBackgroundDimensions',
      _optimizationSizes: [
        { _width: 400 },
        { _width: 600 },
        { _width: 600 },
        { _width: 600 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg'
    },
    androidBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg'
    },
    appleStoreLink: {
      _type: 'link',
      _min: 1,
      _max: 2048,
      _value: 'https://www.apple.com/ios/app-store/'
    },
    googlePlayLink: {
      _type: 'link',
      _min: 1,
      _max: 2048,
      _value: 'https://play.google.com/store'
    }
  },
  defaultProps: {
    buttons: [],
    backgroundImgUrl:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg',
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11bg-1.0.0.jpg'
    },
    bgOverlayTransparency: 0,
    title: 'Mobile management application',
    paragraph:
      'Introducing one of our new products - Mobile Business Management. Get it for free on the App Store or Google Play, and easily manage your information from all edges of the world.',
    appleBackground:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg',
    appleBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-1.1.0.jpg'
    },
    androidBackground:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg',
    androidBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/CTA11-2.1.0.jpg'
    },
    appleStoreLink: 'https://www.apple.com/ios/app-store/',
    googlePlayLink: 'https://play.google.com/store'
  }
}
