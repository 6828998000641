export default {
  //`rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => `${p.tertiaryDark}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    anchor: p => p.primaryLight,
    blob: p => p.secondaryLight,
    blobGradientStart: p => p.primaryLight,
    blobGradientEnd: p => p.primaryDark,
    icon: p => p.accentWhite
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => `${p.tertiaryLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    anchor: p => p.primaryDark,
    blob: p => p.tertiaryDark,
    blobGradientStart: p => p.primaryLight,
    blobGradientEnd: p => p.primaryDark,
    icon: p => p.tertiaryDark
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    anchor: p => p.primaryDark,
    blob: p => p.secondaryDark,
    blobGradientStart: p => p.primaryLight,
    blobGradientEnd: p => p.primaryDark,
    icon: p => p.tertiaryDark
  }
}
