// @flow

import React, { useState, useRef, useContext } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import PublishOptions from '../PublishOptions'

import { MobileDetectContext } from '@contexts'
import { publishProject } from '@actions/project'
import {
  openPublishPopup,
  openStatusChangeAndPublishPopup
} from '@actions/editor'
import {
  selectDomain,
  selectIsCustomDomainSaving,
  selectPublishingStatus,
  selectPublishPopupState,
  selectSiteOnlineStatus,
  selectSiteOnlineStatusUIState
} from '@selectors'
import type { TPublishButtonProps } from './types'

const PublishButton = ({
  hasDomain,
  isOnline,
  isPublishing,
  isDomainSaving,
  isPublishPopupOpen,
  isSiteStateChanging,
  publishProject,
  openPublishPopup,
  openStatusChangeAndPublishPopup
}: TPublishButtonProps) => {
  const [isOptionsOpened, setOptionsOpenedState] = useState(false)
  const optionsButtonRef = useRef(null)
  const isMobile = useContext(MobileDetectContext)
  const isOptionsButtonDisabled = isPublishing
  const isPublishButtonDisabled = isDomainSaving || isSiteStateChanging
  const optionsButtonTooltipText =
    isOptionsOpened || isOptionsButtonDisabled
      ? ''
      : translate('publish_settings_label')

  const onPublish = () => {
    if (isPublishing || isPublishButtonDisabled) {
      return
    }
    if (hasDomain && !isOnline) {
      openStatusChangeAndPublishPopup()
    } else if (!hasDomain) {
      openPublishPopup()
    } else {
      publishProject()
    }
  }

  const closeOptions = () => setOptionsOpenedState(false)

  const toggleOptions = () =>
    setOptionsOpenedState(currentState => !currentState)

  return (
    <>
      <Styled.ButtonWrapper>
        <Styled.PublishButton
          onClick={onPublish}
          disabled={isPublishButtonDisabled}
          isLoading={isPublishing && !isPublishPopupOpen}
        >
          <Styled.Loader color="white" isSmall />
          <span>{translate('publish_label')}</span>
        </Styled.PublishButton>
        {isMobile ? null : (
          <>
            <Styled.Divider />
            <TooltipWrapper
              className="publish-settings-tooltip"
              position="bottom"
              text={optionsButtonTooltipText}
            >
              <Styled.OptionsButton
                className="publish-options-button"
                disabled={isOptionsButtonDisabled}
                ref={optionsButtonRef}
                isOptionsOpened={isOptionsOpened}
                onClick={toggleOptions}
              >
                <ArrowIcon />
              </Styled.OptionsButton>
            </TooltipWrapper>
          </>
        )}
      </Styled.ButtonWrapper>
      {isMobile ? null : (
        <PublishOptions
          isOpen={isOptionsOpened}
          anchorRef={optionsButtonRef.current}
          onClose={closeOptions}
        />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  isOnline: selectSiteOnlineStatus(state),
  hasDomain: !!selectDomain(state),
  isDomainSaving: selectIsCustomDomainSaving(state),
  isPublishing: selectPublishingStatus(state),
  isPublishPopupOpen: selectPublishPopupState(state),
  isSiteStateChanging: selectSiteOnlineStatusUIState(state).isLoading
})

const mapDispatchToProps = {
  publishProject,
  openPublishPopup,
  openStatusChangeAndPublishPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(PublishButton)
