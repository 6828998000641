//@flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TSellingProps } from './types'
import { AMOUNT_PLACEHOLDER, ID_PLACEHOLDER } from './consts'
import { Dropdown, Input } from '../components'
import { translate } from '@editor/common/utils/translations'

const SellingForm = ({
  productName,
  productId,
  productPrice,
  productDiscountedPrice,
  shippingFee,
  activeCurrency,
  currencyDropdownData,
  errorMessages,
  setErrorMessages,
  setData,
  onCurrencyDropdownItemClick
}: TSellingProps) => (
  <Styled.InputsWrapper>
    <Input
      inputKey="productName"
      label={translate('product_name_label')}
      value={productName}
      charLimit={150}
      errorMessage={errorMessages.productName}
      setErrorMessages={setErrorMessages}
      setData={setData}
    />
    <Input
      inputKey="productId"
      label={translate('product_id_label')}
      value={productId}
      charLimit={100}
      placeholder={ID_PLACEHOLDER}
      setData={setData}
    />
    <Input
      type="number"
      inputKey="productPrice"
      label={translate('product_price_label')}
      value={productPrice}
      charLimit={19}
      errorMessage={errorMessages.productPrice}
      setErrorMessages={setErrorMessages}
      setData={setData}
    />
    <Dropdown
      isSearchDropdown
      label={translate('currency_label')}
      list={currencyDropdownData}
      activeItem={activeCurrency}
      onItemClick={onCurrencyDropdownItemClick}
    />
    <Input
      type="number"
      inputKey="productDiscountedPrice"
      label={translate('product_old_price_label')}
      value={productDiscountedPrice}
      charLimit={19}
      placeholder={AMOUNT_PLACEHOLDER}
      setData={setData}
    />
    <Input
      type="number"
      inputKey="shippingFee"
      label={translate('shipping_fee_label')}
      value={shippingFee}
      charLimit={19}
      placeholder={AMOUNT_PLACEHOLDER}
      setData={setData}
    />
  </Styled.InputsWrapper>
)

export default memo(SellingForm)
