// @flow

import React, { memo } from 'react'
import SemrushLogo from '@editor/common/assets/svgr-icons/semrush_logo.svg'
import * as Styled from './styled'
import type { TSemrushSidebarButtonProps } from './types'

const SemrushSidebarButton = ({ onClick }: TSemrushSidebarButtonProps) => (
  <Styled.ButtonContainer onClick={onClick}>
    <Styled.TooltipContainer>
      <span>Get high performing keywords!</span>
    </Styled.TooltipContainer>
    <Styled.Button>
      <SemrushLogo />
    </Styled.Button>
  </Styled.ButtonContainer>
)

export default memo(SemrushSidebarButton)
