/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header24.styled'
import THEME from './Header24.theme'
import ActionGetter from './Actions'
import MorphLg from './morphs/Header24_morph_lg'
import MorphSm from './morphs/Header24_morph_sm'
import MorphMobile from './morphs/Header24_morph_mobile'
import NavBar from '@website/common/components/NavBar'
import { validation } from './Header24.consts'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import { EditingContext, DispatchContext } from '@contexts'
import Buttons from '@website/common/components/Buttons'

const Header24 = memo(props => {
  const {
    data: {
      title,
      subTitle,
      paragraph,
      subTitleAlignment,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      mockupColor = 'light',
      buttons,
      logoWidth,
      logoDimensions,
      imgCropParams,
      notebookBackground,
      notebookbackgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.MorphLgWrapper>
        <MorphLg />
      </S.MorphLgWrapper>
      <S.MorphSmWrapper>
        <MorphSm />
      </S.MorphSmWrapper>
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight}>
        <S.HeaderContent>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            required={validation.title.required}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading54"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={subTitle}
            as={S.SubTitle}
            alignment={subTitleAlignment}
            maxCount={validation.subTitleMaxChar}
            className="WM_GLOBAL_heading32"
            onChange={Actions.changeSubTitle}
            changeAlignment={Actions.changeSubTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            className="WM_GLOBAL_paragraph"
            as={S.Paragraph}
            maxCount={validation.paragraphMaxChar}
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
        </S.HeaderContent>
        <S.MorphPlaceholderWrap>
          <S.MorphMobileWrapper>
            <MorphMobile />
          </S.MorphMobileWrapper>
          <S.NoteBookHeader color={mockupColor} className="notebook-header">
            <S.NotebookWrap>
              <ControlsWithImageCropper
                style={{ width: '100%', height: '100%' }}
                actions={Actions.mockupActions({
                  mockupColor,
                  imageUrl: notebookBackground
                })}
              >
                <Image
                  as={S.NotebookImg}
                  alt="Mockup"
                  sizes={notebookbackgroundImgDimensions}
                  asProps={{ imgCropParams }}
                  defaultImgSrc={notebookBackground}
                />
              </ControlsWithImageCropper>
            </S.NotebookWrap>
          </S.NoteBookHeader>
        </S.MorphPlaceholderWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

Header24.contextType = DispatchContext

export default Header24
