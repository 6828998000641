import styled from 'styled-components'
import AttentionIcon from '@editor/common/assets/svgr-icons/Attention_icon_red.svg'
import InfoSvgIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'

export const NotificationWrapper = styled.p`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  padding: 10px 15px;
  border-radius: 5px;
  width: 100%;
  background-color: ${({ isWarning }) => (isWarning ? '#fef8e6' : '#ffffff')};
  align-items: ${({ isWarning }) => (isWarning ? 'flex-start' : 'center')};
  border: 1px solid #dee3f0;
`

export const WarningIcon = styled(AttentionIcon)`
  & * {
    fill: #e79e00;
  }
  flex-shrink: 0;
  width: 18px;
  margin-top: 3px;
  margin-right: 10px;
`

export const InfoIcon = styled(InfoSvgIcon)`
  fill: #9aa6c8;
  flex-shrink: 0;
  margin-right: 10px;
`

export const Notification = styled.p`
  color: #545f7e;
  line-height: 18px;
  font-size: 14px;
  & > span {
    font-weight: 600;
  }
`
