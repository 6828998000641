/* @flow */
import React, { useContext } from 'react'
import S from './Feature16.styled'
import { validation } from './Feature16.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext, EditingContext, SiteIdContext } from '@contexts'
import './Feature16.styles.sass'
import { translate } from '@editor/common/utils/translations'

const Feature16 = props => {
  const {
    data: { title, paragraph, features }
  } = props

  const dispatcher = useContext(DispatchContext)
  const siteId = useContext(SiteIdContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const changeFeaturTitle = (featureIdx, level, subIdx) => newTitle =>
    dispatcher(Actions.changeBlockTitle(featureIdx, level, subIdx, newTitle))

  const renderFeatureItems = (features, featureIdx) => {
    const renderItems = (item, subIdx = 0, level = 0) => (
      <If
        key={subIdx}
        condition={Array.isArray(item)}
        then={() => (
          <div className={`withSubItems_${level}`}>
            <Mapper
              data={item}
              render={(subItem, subIdx) =>
                renderItems(subItem, subIdx, level + 1)
              }
            />
          </div>
        )}
        otherwise={() => {
          return (
            <div className="item" key={subIdx}>
              <div className="figure">
                <ControlsWithModalOpener
                  className="component-controls"
                  style={{ width: '100%' }}
                  actions={Actions.blockImageActions(
                    featureIdx,
                    level,
                    subIdx,
                    item.imageUrl
                  )}
                >
                  <Image
                    as={S.Img}
                    alt="feature"
                    asProps={{ isEditing: isEditing }}
                    defaultImgSrc={item.imageUrl}
                    sizes={item.imgDimensions}
                  />
                </ControlsWithModalOpener>
                <div className="item-info">
                  <EditableContent
                    text={item.title}
                    as={S.BlockTitle}
                    maxCount={validation.blockTitleMaxChar}
                    onChange={changeFeaturTitle(featureIdx, level, subIdx)}
                  />
                  <EditableContent
                    text={item.paragraph}
                    as={S.BlockParagraph}
                    maxCount={validation.blockSubTitleMaxChar}
                    onChange={(newParagraph: string) =>
                      dispatcher(
                        Actions.changeBlockParagraph(
                          featureIdx,
                          level,
                          subIdx,
                          newParagraph
                        )
                      )
                    }
                  />
                  <Show when={[item.badgeText]}>
                    <S.Badge>
                      <EditableContent
                        text={item.badgeText}
                        as={S.BadgeText}
                        maxCount={validation.badgeMaxChar}
                        onChange={(badgeText: string) =>
                          dispatcher(
                            Actions.changeBlockBadgetext(
                              featureIdx,
                              level,
                              subIdx,
                              badgeText
                            )
                          )
                        }
                      />
                    </S.Badge>
                  </Show>
                </div>
              </div>
            </div>
          )
        }}
      />
    )
    return features.map((feature, i) => renderItems(feature, i))
  }
  return (
    <EditingContext.Consumer>
      {({ isEditing }) => {
        return (
          <S.Container>
            <WMContainer className="feature-16-container">
              <EditableContent
                text={title}
                as={S.Title}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading72"
                onChange={(newTitle: string) =>
                  dispatcher(Actions.changeTitle(newTitle))
                }
              />
              <EditableContent
                text={paragraph}
                as={S.Description}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={(newParagraph: string) =>
                  dispatcher(Actions.changeParagraph(newParagraph))
                }
              />
              <div className="features-wrap">
                {
                  <Mapper
                    data={features}
                    render={(feature, idx) => {
                      return (
                        <ControlsWithModalOpener
                          actions={Actions.getFeatureAction({
                            idx,
                            showRemove:
                              features.length > validation.features.min
                          })}
                          key={idx}
                        >
                          <S.SubItem
                            className="withSubItems"
                            isEven={idx % 2 === 0}
                            isEditing={isEditing}
                          >
                            {renderFeatureItems(feature, idx)}
                          </S.SubItem>
                        </ControlsWithModalOpener>
                      )
                    }}
                  />
                }
              </div>
            </WMContainer>
            <AddButton
              onAdd={() => dispatcher(Actions.addBlock())}
              toShow={features.length < validation.features.max}
              style={{
                marginTop: '2rem'
              }}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_block_label')}
            </AddButton>
          </S.Container>
        )
      }}
    </EditingContext.Consumer>
  )
}

export default Feature16
