// @flow
import React, { useEffect, useState, memo } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TSuccessPopupProps } from './types'
import SemrushDependedPopup from '@editor/common/components/SemrushDependedPopup'
import SuccessSvg from '@website/common/assets/saved-icon.svg'

//TODO: Get rid of this component and use SuccessPopup from common components
const SuccessPopup = ({
  primaryMessage,
  successMessage,
  setSuccessMessage
}: TSuccessPopupProps) => {
  const [isOpen, setOpenedState] = useState(false)

  useEffect(() => {
    if (!!successMessage) {
      setOpenedState(true)
    }
  }, [successMessage])

  const handleClose = () => {
    setOpenedState(false)
    setSuccessMessage('')
  }

  return (
    <SemrushDependedPopup isOpen={isOpen} onClose={handleClose}>
      <Styled.SuccessPopupContainer>
        <Styled.Image src={SuccessSvg} alt="success" />
        <p className="title">{primaryMessage || translate('saved_label')}</p>
        <p className="info">{translate('publish_to_see_all_changes_label')}</p>
        <Styled.OkGotItBtn onClick={handleClose}>
          {translate('ok_got_it_label')}
        </Styled.OkGotItBtn>
      </Styled.SuccessPopupContainer>
    </SemrushDependedPopup>
  )
}

export default memo(SuccessPopup)
