/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Feature7.styled'
import { validation } from './Feature7.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import { KEY_CODES } from '@website/conf/consts'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Feature7 = memo(props => {
  const {
    data: { features, title, paragraph, titleAlignment, paragraphAlignment }
  } = props
  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const [activeFeatureIdx, setActiveFeatureIdx] = useState(0)

  const _features = isEditing
    ? features
    : features.filter(feature => feature.title || feature.paragraph)

  const Actions = ActionGetter(dispatcher)

  const beforeRemove = () => {
    if (activeFeatureIdx !== 0) {
      setActiveFeatureIdx(activeFeatureIdx - 1)
    }
  }
  const dots = () => {
    if (_features.length === 1) {
      return null
    }
    return (
      <S.DotsContainer>
        {_features.map((_, idx) => (
          <S.Dot
            isActive={activeFeatureIdx === idx}
            key={idx}
            onClick={() => setActiveFeatureIdx(idx)}
          />
        ))}
      </S.DotsContainer>
    )
  }
  return (
    <S.StyledContainer>
      <S.WMCustomContainer isEditing={isEditing}>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          className="WM_GLOBAL_heading"
          maxCount={validation.headingMaxChar}
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Paragraph}
          maxCount={validation.paragraphMaxChar}
          className="WM_GLOBAL_paragraph"
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />

        <S.FeturesContent isEditing={isEditing}>
          <If
            condition={_features.length > 0}
            then={() => (
              <>
                <S.BlocksWrap role="tablist">
                  <Mapper
                    data={_features}
                    render={(item, idx) => (
                      <S.BlockItemWrap key={idx}>
                        <ControlsWithModalOpener
                          style={{ width: '100%' }}
                          actions={Actions.blockActions({
                            idx,
                            beforeRemove
                          })}
                        >
                          <S.Block
                            isActiveFeatureIdx={activeFeatureIdx === idx}
                            onClick={() => setActiveFeatureIdx(idx)}
                            onKeyDown={e => {
                              if (e.keyCode === KEY_CODES.ENTER) {
                                setActiveFeatureIdx(idx)
                              }
                            }}
                            role="tab"
                            tabIndex="0"
                          >
                            <EditableContent
                              isLinkControlHidden
                              text={item.title}
                              as={S.BlockTitle}
                              alignment={item.blockTitleAlignment}
                              toPropogate={false}
                              maxCount={validation.blockHeadingMaxChar}
                              className="WM_GLOBAL_heading20"
                              isActiveFeatureIdx={activeFeatureIdx === idx}
                              onChange={(newTitle: string) => {
                                Actions.changeBlockTitle(newTitle, idx)
                              }}
                              changeAlignment={(alignment: string) =>
                                Actions.changeBlockTitleAlignment(
                                  alignment,
                                  idx
                                )
                              }
                            />
                            <EditableContent
                              isLinkControlHidden
                              text={item.paragraph}
                              as={S.BlockParagraph}
                              alignment={item.blockParagraphAlignment}
                              toPropogate={false}
                              maxCount={validation.blockParagraphMaxChar}
                              className="WM_GLOBAL_paragraph18"
                              isActiveFeatureIdx={activeFeatureIdx === idx}
                              onChange={(newParagraph: string) => {
                                Actions.changeBlockParagraph(newParagraph, idx)
                              }}
                              changeAlignment={(alignment: string) =>
                                Actions.changeBlockParagraphAlignment(
                                  alignment,
                                  idx
                                )
                              }
                            />
                          </S.Block>
                        </ControlsWithModalOpener>
                      </S.BlockItemWrap>
                    )}
                  />
                </S.BlocksWrap>
                <S.ImagesWrap>
                  <S.ControlContainer>
                    <ControlsWithImageCropper
                      actions={Actions.blockImgActions(
                        activeFeatureIdx,
                        _features[activeFeatureIdx].imgUrl
                      )}
                      style={{ width: '100%', height: '100%' }}
                    >
                      <Image
                        as={S.FeatureImage}
                        alt="Feature image"
                        role="tabpanel"
                        asProps={{
                          imgCropParams:
                            _features[activeFeatureIdx].imgCropParams
                        }}
                        defaultImgSrc={_features[activeFeatureIdx].imgUrl}
                        sizes={_features[activeFeatureIdx].imgDimensions}
                      />
                    </ControlsWithImageCropper>
                  </S.ControlContainer>
                  {dots()}
                </S.ImagesWrap>
              </>
            )}
          />
        </S.FeturesContent>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={_features.length < validation.features.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature7
