/* @flow */
import React, { useContext, useState, memo, useCallback } from 'react'
import S from './Header17.styled'
import THEME from './Header17.theme'
import { validation } from './Header17.consts'
import ActionGetter from './Actions'
import CountDownDate from './CountDownDate'
import { SubscribeForm } from '../SubscribeForm'
import Icon from '@website/common/components/Icon'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import DatePicker from '@website/common/components/DatePicker'
import { Controls } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import SocialLinks from '@website/common/components/SocialLinks'
import { translate } from '@editor/common/utils/translations'

const Header17 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      dataTitleAlignment,
      titleAlignment,
      paragraphAlignment,
      buttonText,
      placeholderText,
      dateTitle,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      headerSocials,
      logoWidth,
      logoDimensions,
      dateLabels,
      countDownDate,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false)
  const [datePickerAnchorEl, setDatePickerAnchorEl] = useState(null)

  const handleDatePickerOpen = useCallback(
    e => {
      if (!isEditing) {
        return
      }
      setDatePickerVisibility(true)
      setDatePickerAnchorEl(e.currentTarget)
    },
    [isEditing]
  )

  const closeDatePicker = useCallback(() => {
    setDatePickerVisibility(false)
  }, [])

  const submitDate = useCallback(date => {
    setDatePickerVisibility(false)
    Actions.changeDate(date.toISOString())
  }, [])

  const _countDownDate = countDownDate || new Date().toISOString()

  return (
    <S.StyledContainer isEditing={isEditing}>
      <If
        condition={isDatePickerVisible}
        then={() => (
          <DatePicker
            anchorEl={datePickerAnchorEl}
            submitDate={submitDate}
            closeDatePicker={closeDatePicker}
            selectedDays={_countDownDate} //date format
          />
        )}
      />
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer>
        <S.Content topOffset={navBarHeight} isEditing={isEditing}>
          <S.ContentContainer>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                required={validation.title.required}
                maxCount={validation.titleMaxChar}
                className="WM_GLOBAL_heading54"
                as={S.Title}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                isEditing={isEditing}
                text={paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                as={S.Paragraph}
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <S.FormWrapper>
              <If
                condition={buttonText}
                otherwise={() => (
                  <AddButton
                    onAdd={Actions.addForm}
                    toShow={true}
                    style={{
                      marginRight: '10px'
                    }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_input_box_label')}
                  </AddButton>
                )}
                then={() => (
                  <Controls actions={Actions.formActions()}>
                    <S.SubscribeFormWrap>
                      <SubscribeForm
                        actions={{
                          buttonActions: Actions.btnActions(buttonText),
                          placeHolderAction: Actions.changePlaceHolderText
                        }}
                        buttonText={buttonText}
                        placeholder={placeholderText}
                        theme={THEME}
                      />
                    </S.SubscribeFormWrap>
                  </Controls>
                )}
              />
            </S.FormWrapper>
            <SocialLinks
              className="header-socials"
              socials={headerSocials}
              componentDispatcher={dispatcher}
              socialsMaxCount={validation.headerSocials.max}
            />
          </S.ContentContainer>
        </S.Content>
        <S.DateContainer
          backgroundImgUrl={backgroundImgUrl}
          backgroundImgDimensions={backgroundImgDimensions}
          topOffset={navBarHeight}
          isEditing={isEditing}
        >
          <Show when={[dateTitle]}>
            <EditableContent
              text={dateTitle}
              alignment={dataTitleAlignment}
              maxCount={validation.dateTitleMaxChar}
              className="WM_GLOBAL_heading20"
              as={S.DateTitle}
              onChange={Actions.changeDateTitle}
              changeAlignment={Actions.changeDataTitleAlignment}
            />
          </Show>
          <CountDownDate
            dateLabels={dateLabels}
            date={_countDownDate}
            openDatePicker={handleDatePickerOpen}
          />
        </S.DateContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})
Header17.contextType = DispatchContext

export default Header17
