export const FREE_SITE_META = {
  email: '',
  googleAnalyticsId: '',
  googleAdSenseId: '',
  googleTagManagerId: '',
  authDigitalSellersContent: ''
}

export const FREE_COOKIE_DATA = {
  isVisible: false,
  consentText:
    'This website uses cookies to improve your experience. By using our website you agree to the use of cookies.',
  acceptBtn: {
    name: 'Accept'
  }
}

export const EMPTY_PAGE_META = {
  title: '',
  keywords: '',
  description: '',
  useForAll: false
}
