/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text12.styled'
import ActionGetter from './Actions'
import { validation } from './Text12.consts'
import Mapper from '@website/common/components/Mapper'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { translate } from '@editor/common/utils/translations'

const Text12 = memo(
  ({
    data: {
      title,
      titleAlignment,
      blocks,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)

    const areFourBlock = blocks.length === 4

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer isEditing={isEditing}>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading32"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <S.BlocksContainer areFourBlock={areFourBlock}>
            <Mapper
              data={blocks}
              render={(block: string, idx: number) => {
                return (
                  <S.ControlContainer
                    isBorder={blocks.length}
                    className="ControlContainer"
                  >
                    <ControlsWithModalOpener
                      actions={Actions.blockActions({
                        idx,
                        showRemove: blocks.length > validation.blocks.min
                      })}
                    >
                      <S.Block className="block">
                        <EditableContent
                          text={block.title}
                          alignment={block.titleAlignment}
                          as={S.BlockTitle}
                          required={validation.blockTitle.required}
                          maxCount={validation.blockTitleMaxChar}
                          className="WM_GLOBAL_heading20"
                          onChange={(newTitle: string) =>
                            Actions.changeBlockTitle(newTitle, idx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(alignment, idx)
                          }
                        />
                        <EditableContent
                          text={block.paragraph}
                          alignment={block.paragraphAlignment}
                          as={S.BlockParagraph}
                          maxCount={validation.blockParagraphMaxChar}
                          className="WM_GLOBAL_paragraph18"
                          onChange={(newParagraph: string) =>
                            Actions.changeBlockParagraph(newParagraph, idx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                      </S.Block>
                    </ControlsWithModalOpener>
                  </S.ControlContainer>
                )
              }}
            />
          </S.BlocksContainer>
        </S.WmCustomContainer>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={blocks.length < validation.blocks.max}
          style={{
            marginTop: '2rem'
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.StyledContainer>
    )
  }
)

export default Text12
