/* @flow */
import styled from 'styled-components'
import THEME from './Music3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  align-items: center;
  padding: 0;
  @media only screen and (max-width: 650px) {
    padding: 0;
  }
`

const TextContent = styled.div`
  width: 95%;
  max-width: 800px;
  margin: 0 auto;
  padding: ${({ isEditing }) => (isEditing ? '135px 0 50px 0' : '80px 0 50px')};
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: ${({ isEditing }) =>
      isEditing ? '140px 40px 50px 40px' : '80px 40px 40px 40px'};
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) =>
      isEditing ? '120px 20px 40px 20px' : '60px 20px 40px'};
  }
  > :last-child {
    margin-bottom: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    h1 {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const MusicWrapper = styled.div`
  width: 100%;
  font-size: 0;
  @media only screen and (max-width: 650px) {
    min-width: initial;
    width: 100%;
    max-width: 600px;
  }
`

const MusicContent = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
`

const IframeWrapper = styled.div`
  width: 100%;
  position: relative;
  iframe {
    width: 100%;
    height: 44vw;
    max-height: 700px;
  }
`

export default {
  StyledContainer,
  Title,
  Paragraph,
  MusicContent,
  IframeWrapper,
  TextContent,
  MusicWrapper
}
