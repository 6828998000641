/* @flow */
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker,
  paypalButtonModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  addBlock,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import { validation } from '../PayPal5.consts'

export default dispatch => {
  const _changeTitle = (title: string) => {
    dispatch(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatch(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeSubtitle = subtitle => {
    dispatch({
      type: 'CHANGE_SUBTITLE',
      value: subtitle
    })
  }

  const changeSubtitleAlignment = alignment => {
    dispatch({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const imageActions = (idx: number, blocksCount: number, imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'paypal5',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    }),
    ...removeBlockActionMaker(dispatch)({
      idx,
      showRemove: blocksCount > validation.blocks.min
    })
  ]

  const _addBlock = () => {
    dispatch(addBlock())
  }

  const changeBlockParagraph = (paragraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { paragraph, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSubtitle,
    changeBlockParagraph,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    addBlock: _addBlock,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeSubtitleAlignment,
    imageActions,
    paypalButtonModalActions: paypalButtonModalActionMaker(dispatch)
  }
}
