/* @flow */
import React, { useContext, memo } from 'react'
import S from './Spotify6.styled'
import { validation } from './Spotify6.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { convertSpotifyUrlToEmbedUrl } from '@website/common/utils'

const Spotify6 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      musicUrl,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.TextContent>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.maxParagraphChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.TextContent>
        </Show>
        <S.MusicWrapper>
          <ControlsWithModalOpener
            actions={Actions.musicActions(musicUrl)}
            style={{ width: '100%' }}
          >
            <S.MusicContent>
              <Overlay />
              <S.IframeWrapper>
                <iframe
                  scrolling="no"
                  frameBorder="no"
                  src={convertSpotifyUrlToEmbedUrl(musicUrl)}
                  title="Embedded music"
                />
              </S.IframeWrapper>
            </S.MusicContent>
          </ControlsWithModalOpener>
        </S.MusicWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Spotify6
