import styled from 'styled-components'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import CommonButton from '@editor/common/lib/Button'
import { primary, secondary } from '@website/common/styles/colors'

export const ErrorPopup = styled(Popup)`
  & > div {
    padding: 0;
    & > div {
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const ErrorPopupContent = styled.div`
  display: flex;
  min-width: 360px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 40px;
`

export const ErrorImage = styled.img`
  width: 90px;
  height: auto;
  animation: scale-image 700ms ease-out;
  margin-bottom: 30px;
  @keyframes scale-image {
    0% {
      transform: scale(0.5);
    }
    90% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`

export const ErrorMessage = styled.p`
  color: ${secondary.Dark};
  font-size: 14px;
  line-hright: 18px;
  text-align: center;
  margin-bottom: 30px;
`

export const Button = styled(CommonButton)`
  flex-grow: 0;
  background: ${primary.Light};
  color: ${primary.Dark};
  &:hover {
    background: ${primary.SemiLight};
    transition: all 0.2s ease-out;
  }
`
