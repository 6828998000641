/* @flow */
import produce from 'immer'
import { EMPTY_PROJECT_DATA } from '@redux/consts'
import {
  toTop,
  toBottom,
  addNewPage,
  addComponent,
  addMultipleComponents,
  removeComponent,
  replaceComponent,
  duplicateComponent,
  updateTemplateDataAfterReplacing
} from './project.reducers'
import pageReducer from './page.reducers'
import { addEventIdToRsvpComponents } from '@editor/common/utils/project-data'
import { generateUniqId } from '@editor/common/utils'
const initialState = { ...EMPTY_PROJECT_DATA }

// TODO: make state updates as mutable
/* eslint-disable max-statements */
export default (s: TAppProjectState = initialState, action: TProjectActions) =>
  produce(s, state => {
    if (action.type === 'SET_PROJECT_DATA') {
      return {
        ...action.value,
        generator: window.GENERATOR || action.value.generator
      }
    }
    if (!state) {
      return null
    }
    switch (action.type) {
      case 'CREATE_NEW_PAGE':
        return addNewPage(action.value)(state)
      case 'APPLY_TEMPLATE_PRESET':
        return updateTemplateDataAfterReplacing(action.value)(state)
      case 'CHANGE_PROJECT_PAGE_NAMES':
        const pagePaths = action.value
        state.pages = state.pages.map((page, idx) => {
          if (idx === 0 || page.path === pagePaths[idx]) {
            return page
          }
          return {
            ...page,
            path: `/${pagePaths[idx]}`
          }
        })
        break
      case 'REMOVE_PROJECT_PAGE':
        const removingPagePath = action.value
        const { pages } = state
        const removingPageIdx = pages.findIndex(
          page => page.path === removingPagePath
        )

        const newPages = [
          ...state.pages.slice(0, removingPageIdx),
          ...state.pages.slice(removingPageIdx + 1)
        ]

        return {
          ...state,
          pages: newPages
        }
      case 'DUPLICATE_PAGE': {
        const { duplicatingPagePath, newPagePath } = action.value
        const { pages } = state
        const duplicatingPageIdx = pages.findIndex(
          page => page.path === duplicatingPagePath
        )

        const duplicatingPage = pages[duplicatingPageIdx]

        const filteredDuplicatingPage = {
          ...duplicatingPage,
          components: duplicatingPage.components
            .filter(component => component.category !== 'rsvp')
            .map(component => ({
              ...component,
              uniqId: generateUniqId()
            }))
        }

        return {
          ...state,
          pages: [
            ...pages.slice(0, duplicatingPageIdx + 1),
            {
              ...filteredDuplicatingPage,
              meta: { title: '', keywords: '', description: '' },
              path: newPagePath
            },
            ...pages.slice(duplicatingPageIdx + 1)
          ]
        }
      }
      case 'CHANGE_PAGE_META':
        const {
          newPageSettings,
          newPageSettings: { useForAll }
        } = action.value
        return {
          ...state,
          pages: state.pages.map((page, idx) => {
            if (useForAll || idx === action.value.pageIdx) {
              return {
                ...page,
                meta: {
                  ...page.meta,
                  ...newPageSettings
                }
              }
            }
            return page
          })
        }
      case 'CHANGE_PAGE_SETTINGS': {
        const pageSettings = action.value
        state.pages.forEach((page, idx) => {
          page.meta = pageSettings[idx]
        })
        break
      }
      case 'DROP':
        return {
          ...state,
          pages: state.pages.map(page => {
            return {
              ...page,
              components: []
            }
          })
        }
      case 'ADD_COMPONENT':
        return addComponent(action.value)(state)
      case 'ADD_MULTIPLE_COMPONENTS':
        return addMultipleComponents(action.value)(state)
      case 'REPLACE_COMPONENT':
        return replaceComponent(action.value)(state)
      case 'DUPLICATE_COMPONENT':
        return duplicateComponent(action.value)(state)
      case 'REMOVE_COMPONENT':
        return removeComponent(action.value)(state)
      case 'COMPONENT_TO_TOP':
        toTop(action.value)(state)
        break
      case 'COMPONENT_TO_BOTTOM':
        toBottom(action.value)(state)
        break
      case 'SET_VISUAL_PARAMS':
        state.visualParams = action.value
        break
      case 'CHANGE_PALETTE':
        const { colorKey, newColor } = action.value
        return {
          ...state,
          visualParams: {
            ...state.visualParams,
            palette: {
              ...state.visualParams.palette,
              [colorKey]: newColor
            }
          }
        }
      case 'CHANGE_FONT_SIZE':
        return {
          ...state,
          visualParams: {
            ...state.visualParams,
            textSize: action.value
          }
        }
      case 'CHANGE_FONT_PAIR':
        const { primary, secondary } = action.value
        return {
          ...state,
          visualParams: {
            ...state.visualParams,
            primaryFont: primary,
            secondaryFont: secondary
          }
        }
      case 'CHANGE_ENTIRE_PALETTE':
        return {
          ...state,
          visualParams: {
            ...state.visualParams,
            palette: action.value
          }
        }
      case 'CHANGE_LOOK':
        return {
          ...state,
          visualParams: {
            ...state.visualParams,
            look: action.value
          }
        }
      case 'ADD_HEADER_LINK':
        return {
          ...state,
          headerLinks: [...state.headerLinks, action.value]
        }
      case 'ADD_PROJECT_LINK':
        return {
          ...state,
          headerLinks: [...state.headerLinks, action.value],
          footerLinks: [...state.footerLinks, action.value]
        }
      case 'ADD_FOOTER_LINK':
        return {
          ...state,
          footerLinks: [...state.footerLinks, action.value]
        }
      case 'EDIT_NAVIGATION_LINK':
        const {
          linkData: { link: footerLink, text },
          fromComp
        } = action.value
        const linkKey = `${fromComp}Links`
        return {
          ...state,
          [linkKey]: state[linkKey].map((link, idx) => {
            if (idx === action.value.idx) {
              return {
                link: footerLink,
                text
              }
            }
            return link
          })
        }
      case 'REMOVE_NAVIGATION_LINK': {
        const { fromComp, idx } = action.value
        const linkKey = `${fromComp}Links`
        return {
          ...state,
          [linkKey]: [
            ...state[linkKey].slice(0, idx),
            ...state[linkKey].slice(idx + 1)
          ]
        }
      }
      case 'SET_EXTRA_LINKS_LABEL':
        state.extraLinksLabel = action.value
        break
      case 'SET_EVENT_ID_TO_RSVP_COMPONENTS':
        {
          const eventId = action.value
          state.pages = addEventIdToRsvpComponents(state.pages, eventId)
        }
        break
      case 'APPLY_LOGO_TO_HEADER15':
        const { logoUrl, logoDimensions } = action.value
        const header15Comp = state.pages[0].components.find(
          component => component.id === 'Header15'
        )
        if (header15Comp) {
          header15Comp.data.contentImage.imgUrl = logoUrl
          header15Comp.data.contentImage.imgDimensions = logoDimensions
        }
        break
      default:
        const { __from_page } = action
        return {
          ...state,
          pages: state.pages.map(page => {
            // get current active page and apply component reducers only on it
            if (page.path === __from_page) {
              return pageReducer(page, action)
            }
            return page
          })
        }
    }
  })
