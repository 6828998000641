// @flow
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState
} from 'react'
import { connect } from 'react-redux'

import { RfLoader } from '@editor/common/components/Loaders'
import { translate } from '@editor/common/utils/translations'
import { MobileDetectContext, UpgradePopupContext } from '@contexts'
import { getTariffPlans, fetchDataAfterSubscribe } from '@actions'
import {
  selectSubscriptions,
  selectTariffPlans,
  selectWebsiteSubscriptionPlan,
  selectUpgradingPrices
} from '@selectors'

import * as Styled from './styled'
import type { TUpgradePopupProps } from './types'
import Card from './DefaultPopupCard'
import SingleOptionCard from './SingleOptionPopupCard'
import { SubscriptionLink } from './components'
import { TARIFF_PLAN_IDS, UPGRADE_MAPPING } from './consts'
import { getCardsData } from './utils'

const language = window.CURRENT_LANGUAGE
const SAVE_LABEL = `https://static.rfstat.com/renderforest/images/website_maker_images/${language}_sale.svg`
const MOBILE_SAVE_LABEL = `https://static.rfstat.com/renderforest/images/website_maker_images/${language}_mobile_sale.svg`

const PopupCore = ({
  isUpgrade,
  tariffPlans,
  subscriptionsCount,
  websiteSubscriptionPlan,
  upgradingPrices,
  getTariffPlans,
  onUpgradeClick
}: TUpgradePopupProps) => {
  const { tariffId: currentTariffId = TARIFF_PLAN_IDS.free, recurrencePeriod } =
    websiteSubscriptionPlan

  const showRecurringSwitcher = !isUpgrade || recurrencePeriod === 'monthly'
  const isSingleOption = subscriptionsCount >= 1
  const isFreeUser = currentTariffId === TARIFF_PLAN_IDS.free

  const availableTariffIds = useMemo(
    () =>
      isFreeUser && isSingleOption
        ? [UPGRADE_MAPPING[currentTariffId][0]]
        : UPGRADE_MAPPING[currentTariffId],
    [isFreeUser, isSingleOption, currentTariffId]
  )

  const [selectedTariffId, setSelectedTariffId] = useState(
    availableTariffIds[0]
  )
  const [isYearly, setYearlyState] = useState(true)
  const [showBgImage, setBgImageState] = useState(false)

  const isMobile = useContext(MobileDetectContext)
  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const {
    payload: { website = {}, pro = {}, business = {} },
    isLoading
  } = tariffPlans

  useEffect(() => {
    getTariffPlans(availableTariffIds, isUpgrade)
  }, [availableTariffIds, isUpgrade])

  useLayoutEffect(() => {
    setBgImageState(true)
  }, [])

  const onClose = useCallback(() => {
    setUpgradePopupState({ isOpen: false })
  }, [])

  const toggleSwitcher = useCallback(() => {
    setYearlyState(!isYearly)
  }, [isYearly])

  const onUpgradeBtnClick = useCallback(
    tariffId => {
      onUpgradeClick(tariffId, isYearly)
    },
    [isYearly, onUpgradeClick]
  )

  const _tariffPlans = useMemo(
    () => ({
      [TARIFF_PLAN_IDS.pro]: pro,
      [TARIFF_PLAN_IDS.premium]: website,
      [TARIFF_PLAN_IDS.business]: business
    }),
    [pro, website, business]
  )

  const cardsData = useMemo(
    () =>
      getCardsData({
        tariffPlans: _tariffPlans,
        tariffPlanIds: availableTariffIds,
        upgradingPrices,
        isUpgrade,
        isYearly,
        isFullProOptionsVisible: !isFreeUser,
        selectedTariffId,
        setSelectedTariffId,
        onUpgradeBtnClick
      }),
    [
      tariffPlans,
      availableTariffIds,
      upgradingPrices,
      isUpgrade,
      isYearly,
      isFreeUser,
      selectedTariffId,
      onUpgradeBtnClick,
      _tariffPlans
    ]
  )

  return (
    <Styled.UpgradePopup
      isOpen
      onClose={onClose}
      showBgImage={showBgImage}
      isSingleOption={isSingleOption}
      isMobileFullScreen
      className="upgrade-popup"
    >
      {!isMobile ? <Styled.Gradient /> : null}
      <RfLoader isVisible={isLoading} className="upgrade-popup-loader" />
      <Styled.Header>
        <Styled.Title isSingleOption={isSingleOption}>
          {translate('upgrade_to_power_text')}
        </Styled.Title>
        {showRecurringSwitcher ? (
          <Styled.SwitcherWrapper>
            <Styled.Switcher onClick={toggleSwitcher} isYearly={isYearly}>
              <Styled.SwitcherButton isActive={!isYearly}>
                {translate('monthly_label')}
              </Styled.SwitcherButton>
              <Styled.SwitcherButton isActive={isYearly}>
                {translate('yearly_label')}
              </Styled.SwitcherButton>
            </Styled.Switcher>
            <Styled.SaveLabel
              src={isMobile ? MOBILE_SAVE_LABEL : SAVE_LABEL}
              alt="save-label"
            />
          </Styled.SwitcherWrapper>
        ) : null}
      </Styled.Header>
      {cardsData.length < 2 ? (
        <Styled.MainContent>
          <Styled.SingleCardWrapper>
            <SingleOptionCard {...cardsData[0]} isUpgrade={isUpgrade} />
          </Styled.SingleCardWrapper>
          <Styled.Button onClick={cardsData[0].onUpgradeBtnClick}>
            {translate('upgrade_label')}
          </Styled.Button>
        </Styled.MainContent>
      ) : (
        <Styled.MainContent>
          <Styled.CardsWrapper>
            {cardsData.map(cardData => (
              <Card {...cardData} isUpgrade={isUpgrade} />
            ))}
          </Styled.CardsWrapper>
          <Styled.Button
            onClick={() => {
              onUpgradeBtnClick(selectedTariffId)
            }}
          >
            {translate('upgrade_label')}
          </Styled.Button>
        </Styled.MainContent>
      )}
      <Styled.Footer>
        <SubscriptionLink />
        <Styled.Info>
          * {translate('your_storage_will_be_added_to_active_subsb_label')}
        </Styled.Info>
      </Styled.Footer>
    </Styled.UpgradePopup>
  )
}

const mapStateToProps = state => ({
  tariffPlans: selectTariffPlans(state),
  subscriptionsCount: selectSubscriptions(state).length,
  websiteSubscriptionPlan: selectWebsiteSubscriptionPlan(state),
  upgradingPrices: selectUpgradingPrices(state)
})

const mapDispatchToProps = {
  getTariffPlans,
  fetchDataAfterSubscribe
}

export default connect(mapStateToProps, mapDispatchToProps)(PopupCore)
