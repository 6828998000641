import React from 'react'
import IconButton from '@website/common/components/IconButton'

export const PrevArrow = ({ onClick, style, className }) => (
  <div style={style} className={className}>
    <IconButton
      onClick={onClick}
      iconProps={{
        color: '#FFFFFF',
        name: 'arrow_small_left',
        size: 'middle'
      }}
    />
  </div>
)

export const NextArrow = ({ onClick, style, className }) => (
  <div style={style} className={className}>
    <IconButton
      onClick={onClick}
      iconProps={{
        color: '#FFFFFF',
        name: 'arrow_small_right',
        size: 'middle'
      }}
    />
  </div>
)
