/* @flow */
import {
  cropableImageModalActionMaker,
  buttonModalActionMaker
} from '@website/components/ActionMakers'
import {
  addButton,
  changeTitle,
  changeTitleAlignment,
  changeParagraph
} from '@website/components/CommonActions'
export default dispatcher => {
  const _changeTitle = (newTitle: string) => dispatcher(changeTitle(newTitle))

  const _changeParagraph = (newParagraph: string) =>
    dispatcher(changeParagraph(newParagraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeFeatureContentAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_FEATURE_CONTENT_ALIGNMENT',
      value: alignment
    })
  }
  return {
    addButton,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeFeatureContentAlignment,
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'feature6',
        showRemove: false,
        imageUrl
      }),
    getBtnActions: buttonModalActionMaker(dispatcher)
  }
}
