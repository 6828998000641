/* @flow */
import React, { useContext } from 'react'
import S from './Footer2.styled'
import ActionGetter from './Actions'
import Footer from '@website/common/components/Footer'
import Logo from '@website/common/components/Logo'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import {
  EditingContext,
  DispatchContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import { Controls } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'

const Footer2 = props => {
  const {
    data: {
      paragraph,
      logoSrc,
      copyrightText,
      logoWidth,
      logoDimensions,
      logoColor
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}

  return (
    <S.Container isEditing={isEditing}>
      <WMContainer className="WM_GLOBAL_secondary-font" isEditing={isEditing}>
        <Footer />
        <S.FooterContentWrap>
          <S.FooterContent>
            <Logo
              logoSrc={logoSrc}
              logoWidth={logoWidth}
              logoColor={logoColor}
              imgDimensions={logoDimensions}
            />
            <EditableContent
              text={paragraph}
              as={S.Paragraph}
              onChange={Actions.changeParagraph}
            />
          </S.FooterContent>
          <S.CopyRightWrapper>
            <EditableContent
              text={copyrightText}
              as={S.Copyright}
              onChange={Actions.changeCopyright}
              isPrivacyPolicyVisible={isPrivacyPolicyVisible}
            />
            {isPrivacyPolicyVisible ? (
              <S.LinkWrap isEditing={isEditing}>
                <Controls actions={Actions.privacyPolicyAction()}>
                  <LinkWrapper
                    text={privacyPolicyName}
                    url={privacyPolicyLink}
                    linkProps={{
                      className: 'privacy-policy-link WM_GLOBAL_secondary-font'
                    }}
                  />
                </Controls>
              </S.LinkWrap>
            ) : null}
          </S.CopyRightWrapper>
        </S.FooterContentWrap>
      </WMContainer>
    </S.Container>
  )
}

export default Footer2
