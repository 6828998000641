// @flow

import React, { memo } from 'react'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import * as Styled from './styled'
import type { TListProps } from './types'

const List = ({ title, items }: TListProps) => (
  <Styled.Container>
    {title ? <Styled.Title>{title}</Styled.Title> : null}
    <ul>
      {items.map(
        ({ IconComp, text, tooltipText, isDisabled, onClick }, idx) => (
          <TooltipWrapper
            text={tooltipText}
            position="bottom"
            mobileTimer={5000}
          >
            <Styled.ListItem
              key={idx}
              onClick={onClick}
              isDisabled={isDisabled}
            >
              <Styled.IconWrapper>
                <IconComp />
              </Styled.IconWrapper>
              <Styled.ItemText>{text}</Styled.ItemText>
            </Styled.ListItem>
          </TooltipWrapper>
        )
      )}
    </ul>
  </Styled.Container>
)

export default memo(List)
