import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    paperColor: p => p.tertiaryDark,
    background: p => p.accentWhite,
    blockBackground: p => p.accentWhite,
    lightBackground: p => p.accentWhite,
    backgroundColor: p => p.accentWhite
  },
  LIGHT: {
    paperColor: p => p.tertiaryLight,
    background: p => p.primaryDark,
    blockBackground: p => p.primaryDark,
    lightBackground: p => p.primaryDark,
    backgroundColor: p => p.primaryDark
  },
  EXTRALIGHT: {
    paperColor: p => p.tertiaryExtraLight,
    background: p => p.tertiaryDark,
    blockBackground: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark
  }
}
