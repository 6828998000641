import styled, { css } from 'styled-components'
import { white, secondary } from '@website/common/styles/colors'

export const FlyoutContentDesktop = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  background-color: ${secondary.ExtraDark};
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;

  & > p {
    color: ${white};
    font-size: 12px;
    font-weight: 500;
    flex-shrink: 0;
    margin-right: 10px;
    word-break: normal;
  }

  & > input {
    width: auto;
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border: 6px solid #252e48;
    position: absolute;
    top: -1px;

    ${({ alignment }) =>
      alignment === 'left'
        ? css`
            left: 14px;
            transform: rotate(45deg);
          `
        : alignment === 'right'
        ? css`
            right: 14px;
            transform: rotate(45deg);
          `
        : css`
            left: 50%;
            transform: rotate(45deg) translateX(-50%);
          `}
  }
`

export const FlyoutContentMobile = styled.div`
  width: 100%;
  background-color: ${white};
  border-radius: 5px 5px 0 0;
`

export const Header = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: ${secondary.ExtraDark};
  padding: 15px;
  border-bottom: 1px solid ${secondary.Light};
  box-sizing: border-box;

  .arrow-icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%) rotate(-90deg);
    fill: ${secondary.SemiDark};
  }
`

export const Main = styled.div`
  padding: 20px 20px 40px;
`

export const RangeSliderWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${secondary.Light};
  padding: 20px;
  box-sizing: border-box;

  & > input {
    &::-webkit-slider-thumb {
      transform: scale(1.5);
      box-shadow: 0 0 0 3px #387dff4d;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      transform: scale(1.5);
      box-shadow: 0 0 0 3px #387dff4d;
      border-radius: 50%;
    }
  }
`
