/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_SUBTITLE':
        draft.subTitle = action.value
        break
      case 'CHANGE_CTA_TEXT':
        draft.ctaText = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_SUB_TITLE_ALIGNMENT': {
        draft.subTitleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_CTA_TEXT_ALIGNMENT': {
        draft.ctaTextAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.features[idx].blockParagraphAlignment = alignment
        break
      }
      case 'CHANGE_COUNT_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.features[idx].countAlignment = alignment
        break
      }
      case 'CHANGE_ITEM_PARAGRAPH':
        const { newItemParagraph, idx } = action.value
        draft.features[idx].paragraph = newItemParagraph
        break
      case 'CHANGE_BADGE_TEXT':
        const { newBadgeText, idx: idx_ } = action.value
        draft.features[idx_].badgeText = newBadgeText
        break
      case 'CHANGE_COUNT_TEXT':
        const { newCountText, idx: idx__ } = action.value
        draft.features[idx__].count = newCountText
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.features[idx].imgUrl = url
        draft.features[idx].imgDimensions = dimensions || {}
        draft.features[idx].imgCropParams = {}
        break
      }
      case 'ADD_BLOCK':
        const newFeaturesBlock = {
          ...initialState.features[0],
          count: '0' + (draft.features.length + 1)
        }
        draft.features.push(newFeaturesBlock)
        break
      case 'REMOVE_BLOCK':
        draft.features.splice(action.value.idx, 1)
        break
      case 'CHANGE_BUTTON_DATA':
        const { text, link } = action.value.btnData
        draft.buttonUrl = link
        draft.buttonText = text
        break
      case 'REMOVE_BOTTOM_BLOCK':
        draft.ctaText = ''
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BOTTOM_BLOCK':
        draft.ctaText = initialState.ctaText
        draft.buttonUrl = initialState.buttonUrl
        draft.buttonText = initialState.buttonText
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.features[idx].imgCropParams = imgCropParams
      }
    }
  })
}
