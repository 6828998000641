/* @flow */
import React, { useContext, memo } from 'react'
import S from './Contact5.styled'
import Form from './Form'
import WorkingHours from './WorkingHours'
import { EditingContext } from '@contexts'
import 'react-datepicker/dist/react-datepicker.css'

const Contact5 = memo(
  ({
    data: {
      form,
      workingHours,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const { isEditing } = useContext(EditingContext)

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer>
          <Form data={form} />
          <WorkingHours workingHours={workingHours} />
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Contact5
