/* @flow */
import {
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockTitle = (title: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { title, idx }
    })
  }

  const changeBlockParagraph = (paragraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { paragraph, idx }
    })
  }

  const blockImgActions = (blockIdx, imageUrl) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature7',
      showRemove: false,
      additionalArgs: { blockIdx },
      imageUrl
    })

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  return {
    addBlock: _addBlock,
    changeTitle: _changeTitle,
    blockImgActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitle,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    changeParagraph: _changeParagraph,
    changeBlockParagraph,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
