// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TMobileControlClick } from './types'
import SlideUp from '@editor/common/lib/SlideUp'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_left.svg'

const MobileControls = ({
  children,
  slideUpTitle,
  IconComp,
  isSlideUpOpen,
  closeSlideUp,
  onControlClick
}: TMobileControlClick) => {
  return (
    <>
      {IconComp ? (
        <Styled.MobileControlWrapper onClick={onControlClick}>
          <IconComp className="control-icon" />
        </Styled.MobileControlWrapper>
      ) : null}

      <SlideUp
        isOpen={isSlideUpOpen}
        onClose={closeSlideUp}
        className="mobile-controls-slide-up"
      >
        <Styled.SlideUpContent>
          <Styled.SlideUpHeader>
            <ArrowIcon className="arrow-icon" onClick={closeSlideUp} />
            <p>{slideUpTitle}</p>
          </Styled.SlideUpHeader>
          {children}
        </Styled.SlideUpContent>
      </SlideUp>
    </>
  )
}

export default memo(MobileControls)
