/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        return
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        return
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        return
      case 'CHANGE_STORE_URL':
        {
          const { url, storeType } = action.value
          draft[storeType] = url
        }
        return
      case 'ADD_STORE_LINK':
        {
          const storeType = action.value
          draft[storeType] = initialState[storeType]
        }
        return
      case 'REMOVE_STORE_BUTTON':
        {
          const storeType = action.value
          draft[storeType] = ''
        }
        return
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx } = action.value
          draft.slides[idx].imgCropParams = imgCropParams
        }
        return
      case 'CHANGE_IMAGE':
        {
          const { idx, url, dimensions } = action.value
          draft.slides[idx].imageUrl = url
          draft.slides[idx].imgDimensions = dimensions
          draft.slides[idx].imgCropParams = {}
        }
        return
      case 'REMOVE_IMAGE':
        draft.slides.splice(action.value.idx, 1)
        return
      case 'ADD_SLIDE': {
        const activeIdx = action.value

        draft.slides = [
          ...draft.slides.slice(0, activeIdx + 1),
          initialState.slides[0],
          ...draft.slides.slice(activeIdx + 1)
        ]
        //draft.blocks.push(initialState.blocks[0])
        return
      }
    }
  })
}
