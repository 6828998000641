/* @flow */
import styled from 'styled-components'

import THEME from './Text3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: ${getStyleForProp('orientation')};
  padding-top: ${({ isEditing }) => isEditing && '90px'};
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ isEditing }) => (isEditing ? 'padding-top: 60px;' : null)}
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
  }
`

const TextWrap = styled.div`
  width: 48%;
  max-width: 600px;
  .buttons-block {
    margin-left: -15px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  > p:first-child,
  > div:first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;

    h1,
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`
const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  padding-top: 20px;
`

const TextImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const Figure = styled.div`
  margin: ${({ isEditing }) => (isEditing ? '40px 0 15px 0' : '0')};
  width: 48%;
  max-width: 580px;
  &:only-child {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 40px;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 56.25%;
    height: auto;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('cardRadius')};
    box-shadow: rgb(37 43 52 / 12%) 0px 9px 22px 0px;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextWrap,
  Title,
  Description,
  TextImg,
  Figure
}
