// @flow

import React, { memo, useContext, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'

import * as Styled from './styled.js'
import HomeIcon from '@editor/common/assets/svgr-icons/Home_icon.svg'
import OpenIcon from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'
import FlyOut from '@editor/common/lib/FlyOutMenu/FlyOut'
import MobilePopup from '@editor/common/lib/Popup/MobilePopup'
import Content from './Content'

import type { TValueContainerProps } from './types'
import { PAGE_BASE } from '@editor/conf/routes'
import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const ValueContainer = ({ isOpen, onClick, close }: TValueContainerProps) => {
  const routeMatch = useRouteMatch(PAGE_BASE)
  const pageName = routeMatch ? routeMatch.params.page : ''
  const isMobile = useContext(MobileDetectContext)

  const showHomeIcon = pageName === 'home'
  const [containerEl, setContainerEl] = useState(null)

  return (
    <>
      <Styled.GlobalStyles />
      <Styled.ValueContainer
        isOpen={isOpen}
        onClick={onClick}
        ref={setContainerEl}
        className="page-value-container"
      >
        <Styled.Value>
          {showHomeIcon ? <HomeIcon /> : null}
          {pageName ? (
            <span>{pageName}</span>
          ) : (
            <span className="placeholder">Go to page</span>
          )}
        </Styled.Value>
        <Styled.OpenIcon isOpen={isOpen}>
          <OpenIcon />
        </Styled.OpenIcon>
      </Styled.ValueContainer>
      {isMobile ? (
        <MobilePopup
          isOpen={isOpen}
          onClose={close}
          title={translate('pages_label')}
          backdropDisablingSelectors={[
            '.page-name-editing-popup',
            '.page-deleting-popup'
          ]}
        >
          <Content closePagesFlyOut={close} />
        </MobilePopup>
      ) : (
        <FlyOut
          isOpen={isOpen}
          onClose={close}
          anchorEl={containerEl}
          className="pages-flyout"
          animationPrefix="pages-flyout"
          backdropDisablingSelectors={[
            '.page-value-container',
            '.page-name-editing-popup',
            '.page-deleting-popup'
          ]}
        >
          <Content closePagesFlyOut={close} />
        </FlyOut>
      )}
    </>
  )
}

export default memo(ValueContainer)
