import styled, { css } from 'styled-components'

import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { DetailsPopup, Info as _Info } from '../styled'

export const Card = styled.div`
  width: 310px;
  padding: 30px;
  margin-top: 20px;
  box-sizing: border-box;
  border-radius: 20px;
  transition: all 300ms ease;
  cursor: pointer;
  height: 100%;
  ${({ isSelected }) =>
    isSelected
      ? css`
          background-color: #ffffff;
          border: 2px solid #387dff;
        `
      : css`
          height: calc(100% - 20px);
          background-color: #f6fafe;
          border: 2px solid #dee3f0;
          &:hover {
            border: 2px solid #cfe0ff;
            box-shadow: 0px 6px 24px #387dff33;
          }
        `}
`

export const MobileCard = styled.div`
  position: relative;
  height: 122px;
  box-sizing: border-box;
  border-radius: 20px;
  box-shadow: 0px 6px 12px #387dff33;
  transition: all 300ms ease;
  padding: 30px;
  -webkit-tap-highlight-color: transparent;
  ${({ isSelected }) =>
    isSelected
      ? css`
          width: 100%;
          background-color: #ffffff;
          border: 2px solid #387dff;
        `
      : css`
          width: calc(100% - 20px);
          background-color: #f6fafe;
          border: 2px solid #dee3f0;
        `}
`

export const SelectedIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  background-color: #387dff;
  border-radius: 10px;
  border: 1px solid #387dff80;
  background-clip: padding-box;
  -webkit-background-clip: padding-box;
`

export const TitleWrapper = styled.div`
  position: relative;
  width: max-content;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  .info-icon {
    position: absolute;
    right: -30px;
    bottom: 6px;
    fill: #7683a8;
    width: 28px;
    height: 28px;
  }
`

export const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #252e48;
  padding-bottom: 15px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
  }
`

export const GradientText = styled.p`
  padding-top: 17px;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  background: transparent
    linear-gradient(263deg, #40ea95 0%, #17d677 52%, #12be99 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const Options = styled.ul`
  padding-top: 17px;
`

export const MobileDetailsPopup = styled(DetailsPopup)`
  .popup-title {
    padding-bottom: 15px;
  }

  .price {
    font-size: 32px;
  }

  .currency {
    font-size: 16px;
  }
`

export const Info = styled(_Info)`
  @media only screen and (max-width: 768px) {
    display: block;
    text-align: center;
    padding-top: 20px;
  }
`
