// @flow
import React, { memo, useContext } from 'react'

import * as Styled from './styled'
import type { TComponentsControlsProps } from './types'
import ColorModeControls from './ColorModeControls'
import BackgroundControls from './BackgroundControls'
import MainControls from './MainControls'
import { MovementControl } from './components'
import { MobileDetectContext } from '@contexts'
import { isOpenedWithHash } from '@editor/common/utils'

const WithControlsMobileWrapper = memo(({ children, isMobile }) =>
  isMobile ? (
    <Styled.ControlsMobileWrapper>{children}</Styled.ControlsMobileWrapper>
  ) : (
    children
  )
)

const ComponentControls = ({
  idx,
  category,
  componentId,
  componentUniqId,
  colorMode,
  backgroundImage,
  backgroundVideo,
  bgOverlayTransparency,
  isBgColorAllowed,
  isBgImageAllowed,
  isBgVideoAllowed,
  isBgTransparencyAllowed,
  isComponentAnimated,
  isAnimationEnabled,
  isUpMovementAllowed,
  isDownMovementAllowed,
  isOrientationAllowed,
  isDuplicateAllowed,
  hasLimitedBackgrounds
}: TComponentsControlsProps) => {
  const isMobile = useContext(MobileDetectContext)

  return (
    <Styled.ComponentControls>
      {isMobile ? (
        <MovementControl
          idx={idx}
          isUpMovementAllowed={isUpMovementAllowed}
          isDownMovementAllowed={isDownMovementAllowed}
        />
      ) : null}
      <WithControlsMobileWrapper isMobile={isMobile}>
        {isBgColorAllowed ? <ColorModeControls colorMode={colorMode} /> : null}
        {isBgImageAllowed || isBgVideoAllowed ? (
          <BackgroundControls
            componentId={componentId}
            componentUniqId={componentUniqId}
            backgroundImage={backgroundImage}
            backgroundVideo={backgroundVideo}
            bgOverlayTransparency={bgOverlayTransparency}
            isBgImageAllowed={isBgImageAllowed}
            isBgVideoAllowed={isBgVideoAllowed}
            isBgTransparencyAllowed={isBgTransparencyAllowed}
            hasLimitedBackgrounds={hasLimitedBackgrounds}
          />
        ) : null}{' '}
        {isOpenedWithHash() ? null : (
          <MainControls
            idx={idx}
            category={category}
            isUpMovementAllowed={isUpMovementAllowed}
            isDownMovementAllowed={isDownMovementAllowed}
            isOrientationAllowed={isOrientationAllowed}
            isDuplicateAllowed={isDuplicateAllowed}
            isDuplicateDisabled={backgroundImage?.isLoading}
            isComponentAnimated={isComponentAnimated}
            isAnimationEnabled={isAnimationEnabled}
          />
        )}
      </WithControlsMobileWrapper>
    </Styled.ComponentControls>
  )
}

export default memo(ComponentControls)
