/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_BLOCK_TITLE':
        const { idx, title } = action.value
        draft.features[idx].title = title
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        const { idx: blockIdx, paragraph } = action.value
        draft.features[blockIdx].paragraph = paragraph
        break

      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx: blockIdx } = action.value
        draft.features[blockIdx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: blockIdx } = action.value
        draft.features[blockIdx].blockParagraphAlignment = alignment
        break
      }
      case 'CHANGE_IMAGE': {
        const { blockIdx, url, dimensions } = action.value
        draft.features[blockIdx].imgUrl = url
        draft.features[blockIdx].imgDimensions = dimensions || {}
        draft.features[blockIdx].imgCropParams = {}
        break
      }
      case 'ADD_BLOCK':
        draft.features.push(initialState.features[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.features.splice(idx, 1)
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, blockIdx } = action.value
        draft.features[blockIdx].imgCropParams = imgCropParams
      }
    }
  })
}
