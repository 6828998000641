// @flow

import React, { useState, useCallback, useEffect, useContext } from 'react'
import { connect } from 'react-redux'

import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import UploadStatusTooltip from '@editor/common/components/ComponentControls/BackgroundControls/UploadStatusTooltip'
import AttentionIcon from '@editor/common/assets/Image_Upload_fail_attention_icon_red.svg'
import ImageIcon from '@editor/common/assets/svgr-icons/Image_icon.svg'

import * as Styled from './styled'
import { secondary } from '@website/common/styles/colors'

import { openLoginPopup } from '@actions/editor'
import { selectIsUserAuthorized } from '@selectors'
import {
  LINK_TYPES,
  MODAL_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import { ControlModalsContext } from '@contexts'
import { backgroundImgSizes } from '@editor/conf/imageSizes'

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})
const mapDispatchToProps = { openLoginPopup }

const BackgroundImageControl = ({
  activeSlideIdx,
  componentId,
  backgroundImage,
  isUserAuthorized,
  removeBgImage,
  openLoginPopup,
  changeSliderBackgroundImage
}) => {
  const { isLoading, failed } = backgroundImage || {}
  const hasUploadingStatus = isLoading || failed

  const [isInvalidImage, setImageInvalidState] = useState(false)
  const [isUploadTooltipOpen, setUploadTooltipState] = useState(false)
  const { setModalState } = useContext(ControlModalsContext)

  useEffect(() => {
    setUploadTooltipState(isLoading || failed)
  }, [isLoading, failed])

  useEffect(() => {
    setImageInvalidState(false)
  }, [`${backgroundImage}`])

  const handleMouseOver = useCallback(() => {
    if (hasUploadingStatus) {
      setUploadTooltipState(true)
    }
  }, [hasUploadingStatus])

  const handleMouseLeave = useCallback(() => {
    if (hasUploadingStatus) {
      setUploadTooltipState(false)
    }
  }, [hasUploadingStatus])

  const closeUploadTooltip = useCallback(() => setUploadTooltipState(false), [])

  const handleImageLoadingError = useCallback(() => {
    setImageInvalidState(true)
  }, [])

  const openImageSlideUp = useCallback(
    () =>
      setModalState(MODAL_TYPES.mediaModal, {
        isOpen: true,
        type: LINK_TYPES.image,
        optimizationDimensions: backgroundImgSizes[componentId],
        handler: (url, dimensions) =>
          changeSliderBackgroundImage(activeSlideIdx, url, dimensions)
      }),
    [activeSlideIdx, changeSliderBackgroundImage]
  )
  const handleImageChangeClick = useCallback(() => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }

    openImageSlideUp()
  }, [isUserAuthorized, openLoginPopup, openImageSlideUp])

  const onDelete = useCallback(() => {
    removeBgImage(activeSlideIdx)
  }, [activeSlideIdx, removeBgImage])

  return (
    <>
      <Styled.BgChangeButton>
        <ImageIcon onClick={handleImageChangeClick} />
      </Styled.BgChangeButton>
      <If
        condition={!!backgroundImage}
        then={() => (
          <Styled.SmallBgImageWrapper
            onMouseOver={handleMouseOver}
            onMouseLeave={handleMouseLeave}
            failed={failed}
          >
            <UploadStatusTooltip
              isOpen={isUploadTooltipOpen}
              closeTooltip={closeUploadTooltip}
              isLoading={isLoading}
              failed={failed}
            />
            <Styled.BgRemoveIconWrapper role="button" onClick={onDelete}>
              <Icon name="close" color={secondary.SemiDark} />
            </Styled.BgRemoveIconWrapper>
            {isInvalidImage ? (
              <img src={AttentionIcon} alt="failed" className="failed-image" />
            ) : (
              <img
                onError={handleImageLoadingError}
                src={backgroundImage}
                alt="Error img"
              />
            )}
          </Styled.SmallBgImageWrapper>
        )}
      />
    </>
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackgroundImageControl)
