// @flow
const protocol =
  typeof window !== 'undefined' ? window.location.protocol : 'https:'
const MAP_SDK_PATH = `${protocol}//maps.googleapis.com/maps/api/js?key=${MAP_API_KEY}&libraries=places`

export const injectMapScript = (onLoadCb: (e: Event) => void) => {
  if (typeof window === undefined) {
    return
  }
  const mapScript = document.querySelector('#rf-wm-map-sdk')
  if (!mapScript) {
    const scriptTag = document.createElement('script')
    scriptTag.setAttribute('id', 'rf-wm-map-sdk')
    scriptTag.setAttribute('src', MAP_SDK_PATH)
    scriptTag.setAttribute('defer', 'defer')
    scriptTag.setAttribute('async', 'async')
    scriptTag.onload = onLoadCb
    document.head.appendChild(scriptTag)
  } else {
    return onLoadCb()
  }
}

export const addressSplitter = (address: string) =>
  address.split(', ').join(',').split(' ').join('+')
