/* @flow */
import {
  buttonModalActionMaker,
  cropableImageModalActionMaker,
  socialActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  addSocial,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const addSlide = () =>
    dispatcher({
      type: 'ADD_SLIDE'
    })

  const sliderActions = ({ showRemove, slideIdx, clickHandler, imageUrl }) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature18',
      clickHandlers: {
        change: clickHandler,
        crop: clickHandler
      },
      showRemove,
      imageUrl,
      additionalArgs: {
        slideIdx
      },
      actions: {
        change: ({ url, dimensions, undoable }) => ({
          type: 'CHANGE_SLIDER_IMAGE',
          value: { slideIdx, url, dimensions },
          undoable
        }),
        remove: () => ({
          type: 'REMOVE_SLIDER_IMAGE',
          value: slideIdx
        }),
        crop: ({ imgCropParams, undoable }) => ({
          type: 'SET_SLIDER_IMG_CROP_PARAMS',
          value: { imgCropParams, slideIdx },
          undoable
        })
      }
    })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const _addSocial = () => dispatcher(addSocial())

  return {
    addSlide,
    addSocial: _addSocial,
    changeTitle: _changeTitle,
    sliderActions: sliderActions,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    imgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        showRemove: false,
        keyForSizes: 'feature18LeftImage',
        imageUrl
      }),
    btnActions: buttonModalActionMaker(dispatcher),
    socialActions: socialActionMaker(dispatcher)
  }
}
