export const MODAL_TYPES = {
  extendedUrlModal: 'EXTENDED_URL_MODAL',
  urlModal: 'URL_MODAL',
  textModal: 'TEXT_MODAL',
  iconModal: 'ICON_MODAL',
  mediaModal: 'MEDIA_MODAL',
  mapModal: 'MAP_MODAL',
  soundcloudModal: 'SOUNDCLOUD_MODAL',
  spotifyModal: 'SPOTIFY_MODAL',
  imageCrop: 'IMAGE_CROP',
  languagesMenuFlyout: 'LANGUAGES_MENU_FLYOUT',
  paypalSettingsPopup: 'PAYPAL_SETTINGS_POPUP',
  transparencyFlyout: 'TRANSPARENCY_FLYOUT',
  textWizardAIWindow: 'TEXT_WIZARD_AI_WINDOW'
}

export const LINK_TYPES = {
  image: 'Image',
  video: 'Video',
  logo: 'Logo',
  social: 'Social',
  link: 'Link',
  soundCloudTrack: 'Soundcloud track',
  spotifyTrack: 'Spotify track',
  spotifyPlaylist: 'Spotify playlist'
}

export const INVALID_IMAGE_URL_MESSAGE = 'Please enter a valid image URL'
export const INVALID_VIDEO_URL_MESSAGE = 'Please enter a valid video URL'
export const EMPTY_INPUT_MESSAGE = 'This field cannot be empty'

export const INTERNAL_IMAGE_URL_REGEXP =
  NODE_ENV === 'development'
    ? /^https:\/\/uploads-test-bucket.s3(.*)/
    : /^https:\/\/(.*).renderforest.com(.*)/
export const RF_STAT_IMAGE_URL_SUBSTR = 'static.rfstat.com/renderforest'
