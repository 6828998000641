import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    border: p => p.accentWhite,
    smallRhombus: p => p.primaryDark,
    bigRhombus: p => p.primaryLight,
    socialIcons: p => p.accentWhite,
    icons: p => p.primaryLight,
    navbarBg: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    border: p => p.tertiaryDark,
    smallRhombus: p => p.tertiaryDark,
    bigRhombus: p => p.tertiaryDark,
    socialIcons: p => p.primaryDark,
    icons: p => p.primaryDark,
    navbarBg: p => p.tertiaryLight,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    border: p => p.tertiaryDark,
    smallRhombus: p => p.primaryLight,
    bigRhombus: p => p.primaryDark,
    socialIcons: p => p.tertiaryDark,
    icons: p => p.primaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
