/* @flow */
import styled from 'styled-components'

import THEME from './Feature32.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding-top: ${({ isEditing }) => (isEditing ? '140px' : null)};
`
const WmCustomContainer = styled(WMContainer)`
  width: 100%;
  max-width: 1480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    max-width: 1230px;
  }
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    @media only screen and (min-width: 1025px) {
      max-width: 800px;
    }
    p {
      padding-top: 0;
    }
  }
`

const ItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: -25px;
  margin-bottom: -25px;
  padding-top: 50px;
  max-width: ${({ areFourBlock }) => (areFourBlock ? '1000px' : '')};
  @media only screen and (max-width: 1024px) {
    padding-top: 40px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin: 0 auto;
  width: 100%;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  font-weight: 400;
  margin: 0 auto;
  padding-top: 20px;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
`

const Feature = styled.div`
  margin: 25px 40px;
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: stretch;
  & > .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 1440px) {
    margin: 25px;
  }
  @media only screen and (max-width: 650px) {
    margin: 25px 0;
  }
  .link-section {
    position: relative;
    z-index: 2;
    margin: 30px auto 0 auto;
  }
  .link-wrapper {
    justify-content: center;
    transform: translateX(7px);
  }
  .add-button-wrap {
    position: relative;
    z-index: 1;
  }
  .learn-more-link {
    color: ${getStyleForProp('anchor')};
    svg {
      fill: ${getStyleForProp('anchor')};
    }
  }
  .styled-controls {
    z-index: 3;
  }
`

const FeatureItemWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  padding: 44px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  text-align: center;
  flex-grow: 1;
  box-sizing: border-box;
  word-break: break-word;
  height: 100%;
  position: relative;
  min-height: 274px;
  @media only screen and (max-width: 768px) {
    padding: 44px 40px;
    min-height: 260px;
  }
  .LazyLoad {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    overflow: hidden;
    border-radius: ${getStyleForProp('cardRadius')};
  }
  .lazy-figure {
    transition: transform 0.2s ease-out;
  }
  p {
    position: relative;
    z-index: 2;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${getStyleForProp('cardLayerBackground')};
    border-radius: ${getStyleForProp('cardRadius')};
    z-index: 1;
  }
  &:hover {
    .lazy-figure {
      transform: scale(1.3);
    }
  }
  @media only screen and (max-width: 1100px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 450px) {
    min-width: 300px;
  }
  .control-container {
    width: 100%;
  }
  > &:nth-child(3) {
    padding-top: 0;
  }
`

const FeatureTitle = styled.h2`
  width: 100%;
  color: ${getStyleForProp('featureTitle')};
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  ${getTextStyleByAlignment};
`

const FeatureParagraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('featureParagraph')};
  padding-top: 20px;
  line-height: 1.4;
  ${getTextStyleByAlignment};
  z-index: 2;
  transition: text-align 0.5s ease-in;
`

const ControlContainer = styled.div`
  position: relative;
  .control-container {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 5;
  }
  .LazyLoad {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
  }
  .lazy-figure::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: ${getStyleForProp('cardLayerBackground')};
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
`

export default {
  StyledContainer,
  WmCustomContainer,
  ItemsWrap,
  Title,
  Description,
  Feature,
  FeatureItemWrap,
  FeatureTitle,
  FeatureParagraph,
  ControlContainer,
  Img
}
