import styled from 'styled-components'

import { DetailsPopup, Info as _Info } from '../styled'
import { secondary, white } from '@website/common/styles/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const MOBILE_BREAK_POINT = '630px'

const stylesObj = {
  fr: {
    width: '50%'
  },
  de: {
    width: '50%'
  },
  pt: {
    width: '50%'
  },
  es: {
    width: '50%'
  },
  default: {
    width: 'auto'
  }
}

const getStylesByLang = styleByLang(stylesObj)

export const Card = styled.div`
  position: relative;
  width: 537px;
  background-color: ${white};
  box-shadow: 0 0 20px #387dff33;
  border: 1px solid ${secondary.Light};
  border-radius: 20px;
  box-sizing: border-box;

  @media only screen and (max-width: ${MOBILE_BREAK_POINT}) {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
  }
`

export const BoxIllustration = styled.img`
  position: absolute;
  right: 35px;
  top: -35px;
  width: 140px;
  height: 160px;

  @media only screen and (max-width: 768px) {
    width: 112px;
    height: 120px;
  }

  @media only screen and (max-width: ${MOBILE_BREAK_POINT}) {
    left: 50%;
    right: initial;
    transform: translateX(-50%);
    top: -42px;
  }
`

export const DesignElement = styled.img`
  position: absolute;
  left: -10px;
  bottom: -20px;
  width: 45px;
  height: 50px;
`

export const CardHeader = styled.div`
  padding: 20px 25px 10px;

  @media only screen and (min-width: 631px) and (max-width: 768px) {
    padding: 20px 25px;
  }

  @media only screen and (max-width: ${MOBILE_BREAK_POINT}) {
    padding: 85px 25px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  svg {
    fill: ${secondary.Normal};
    width: 28px;
    height: 28px;
  }
`

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  color: ${secondary.ExtraDark};
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: transparent
    linear-gradient(90deg, #ffffff 0%, #c1b3ef 48%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
`

export const OptionsWrapper = styled.div`
  padding: 20px 25px;
  display: flex;

  & > ul {
    width: ${getStylesByLang('width')};
  }

  @media only screen and (max-width: 768px) {
    & > ul {
      width: auto;
    }
    padding: 5px 25px 20px;
    display: unset;
  }
`

export const Options = styled.ul`
  display: flex;
  flex-direction: column;
  &:first-child {
    margin-right: 15px;
  }

  @media only screen and (max-width: 768px) {
    & > li {
      padding-top: 15px;
    }
  }
`

export const MobileDetailsPopup = styled(DetailsPopup)`
  & > div {
    border: 2px solid ${secondary.Light};
    & > div {
      padding: 0;

      & > button {
        margin: 0 auto 20px;
      }
    }
  }
`

export const DetailsPopupHeader = styled.div`
  padding: 30px 30px 20px;
`

export const Info = styled(_Info)`
  @media only screen and (max-width: 768px) {
    display: block;
    text-align: center;
    margin-bottom: 15px;
    padding: 0 20px;
  }
`
