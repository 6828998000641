/* @flow */
import styled from 'styled-components'
import { white, primary, secondary } from '@website/common/styles/colors'
import LibPopup from '@renderforest/rf-ui-library/organism/Popup'

export const SignInButton = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  &:hover {
    background-color: #eef5ff;
    svg {
      fill: #363f5a;
    }
  }
  & > span {
    font-size: 14px;
    color: #545f7e;
    font-weight: 500;
  }
`

export const LangText = styled.span`
  margin: 0 10px;
`

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #eef5ff;
  border-radius: 5px;

  & > svg {
    fill: #7683a8;
    width: 16px;
    height: 14px;
  }
`
