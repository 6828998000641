/* @flow */
import React from 'react'
import S from './Video4.styled'
import { validation } from './Video4.consts'
import ActionGetter from './Actions'
import VideoPopup from '@website/common/components/VideoPopup/VideoPopup'
import If from '@website/common/components/Conditional'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { withDispatchContext } from '@contexts'
import VideoItem from './VideoItem'
import { translate } from '@editor/common/utils/translations'

class Video4 extends React.PureComponent<void, void> {
  state = {
    showVideoPopup: false,
    currentVideoUrl: ''
  }

  render() {
    const { showVideoPopup } = this.state
    const {
      data: { title, paragraph, videos, titleAlignment, paragraphAlignment },
      componentDispatcher
    } = this.props
    const Actions = ActionGetter(componentDispatcher)
    const isForBlock = videos.length === 2 || videos.length === 4
    return (
      <S.StyledContainer>
        <If
          condition={showVideoPopup}
          then={() => (
            <VideoPopup
              url={this.state.currentVideoUrl}
              closeVideoPopup={() => this.setState({ showVideoPopup: false })}
            />
          )}
        />
        <S.WmCustomContainer>
          <S.HeaderContent>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.titleMaxChar}
              required={validation.title.required}
              className="WM_GLOBAL_heading32"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.HeaderContent>
          <S.VideosContainer isForBlock={isForBlock}>
            <Mapper
              data={videos}
              render={(video: string, idx: number) => (
                <VideoItem
                  key={idx}
                  video={video}
                  idx={idx}
                  alignment={titleAlignment}
                  videosLength={videos.length}
                  isForBlock={isForBlock}
                  setCurrentVideoUrl={url =>
                    this.setState({
                      currentVideoUrl: url,
                      showVideoPopup: url !== null
                    })
                  }
                />
              )}
            />
          </S.VideosContainer>
          <AddButton
            onAdd={Actions.addBlock}
            toShow={videos.length < validation.videos.max}
            style={{
              marginTop: '40px'
            }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_video_label')}
          </AddButton>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
}

const Video4WithContexts = withDispatchContext(Video4)

export default Video4WithContexts
