/* @flow */
import { buttonModalActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  addButton,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const _changeTitle = (title: string) => dispatch(changeTitle(title))

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _addButton = () => {
    dispatch(addButton())
  }
  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    addButton: _addButton,
    btnActions: buttonModalActionMaker(dispatch)
  }
}
