/* @flow */
import {
  iconActionMaker,
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const addItem = () => {
    dispatch({ type: 'ADD_ITEM' })
  }

  const imageActions = (idx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'slider6',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    })

  const sliderActions = ({ idx, onEditClick, imageUrl }) =>
    cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'post1',
      showRemove: false,
      additionalArgs: { idx },
      clickHandlers: {
        change: onEditClick,
        crop: onEditClick
      },
      imageUrl
    })

  const addSlide = () => {
    dispatch({ type: 'ADD_SLIDE' })
  }
  const _removeSlide = idx => {
    dispatch({
      type: 'REMOVE_SLIDER_IMAGE',
      value: idx
    })
  }

  return {
    changeBlockTitle,
    changeBlockParagraph,
    addItem,
    blockActions: removeBlockActionMaker(dispatch),
    getIconActions: iconActionMaker(dispatch),
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    imageActions,
    getSliderActions: sliderActions,
    addSlide,
    removeSlide: _removeSlide
  }
}
