import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'

import {
  Content,
  ContentHeader,
  ContentTitle,
  ContentSubTitle,
  ContentMainSection,
  MainContentContainer
} from '@containers/edit/components/SubSidebarContent/styled'
import RangeSlider from '@editor/common/components/RangeSlider'
import Scrollable from '@editor/common/components/Scrollable'
import { getProjectFontPair, getTextSize } from '@selectors'
import { changeFontSize } from '@actions/editor'
import { _debounce } from '@website/common/utils'
import { fontPairs } from '@editor/conf/consts'
import { translate } from '@editor/common/utils/translations'

import FontPair from './FontPair'
import * as Styled from './styled'

const getIsSelected = (selectedPair, iteratedPair) =>
  selectedPair.primary === iteratedPair.primary &&
  selectedPair.secondary === iteratedPair.secondary

const TextContent = ({ textSize, selectedFontPair, changeFontSize }) => {
  const mainSectionRef = useRef(null)

  useEffect(() => {
    const elem = document.getElementById(selectedFontPair.primary)

    elem &&
      setTimeout(() => {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }, 300)
  }, [])

  return (
    <Content>
      <ContentHeader>
        <ContentTitle>{translate('text_label')}</ContentTitle>
        <ContentSubTitle>{translate('text_size_label')}</ContentSubTitle>
        <Styled.RangSliderWrapper>
          <RangeSlider
            value={textSize}
            onChange={_debounce(newVal => changeFontSize(newVal), 150)}
          />
        </Styled.RangSliderWrapper>
        <ContentSubTitle>{translate('font_pairings_label')}</ContentSubTitle>
      </ContentHeader>
      <Scrollable childrenRef={mainSectionRef}>
        <ContentMainSection>
          <MainContentContainer>
            {fontPairs.map((fontPair, idx) => (
              <FontPair
                key={idx}
                fontPair={fontPair}
                isSelected={getIsSelected(selectedFontPair, fontPair)}
                scrollableContainerRef={mainSectionRef}
              />
            ))}
          </MainContentContainer>
        </ContentMainSection>
      </Scrollable>
    </Content>
  )
}

const mapStateToProps = state => ({
  textSize: getTextSize(state),
  selectedFontPair: getProjectFontPair(state)
})

export default connect(mapStateToProps, {
  changeFontSize
})(TextContent)
