import { getHash } from './utils'

//export const SM_API_PATH = 'https://sm.renderforest.com/api/v1'
// export const SM_API_PATH =
//   'https://site-maker-api-4.local.renderforest.com/api/v1'
// export const SM_API_PATH =
//   'https://site-maker-api-stage.renderforest.com/api/v1'
export const SM_API_PATH =
  (typeof window !== 'undefined' && window.SITE_MAKER_API_PATH) || ''

export const FILE_UPLOAD_PATH =
  (typeof window !== 'undefined' && window.FILE_UPLOAD_PATH) || ''

const extraHeader = getHash()
  ? {
      Authorization: `Bearer ${getHash()}`,
      'X-SharedEditing': true
    }
  : {}

export const COMMON_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  ...extraHeader
}

export const HEADERS = {
  GET: COMMON_HEADERS,
  PATCH: COMMON_HEADERS,
  PUT: COMMON_HEADERS,
  POST: {
    'Content-Type': 'application/json',
    ...extraHeader
  },
  DELETE: {}
}

// export const ApiPrefix = '//api-4.local.renderforest.com/api/v1'
export const ApiPrefix =
  (typeof window !== 'undefined' && window.API_RENDER_HOST) ||
  '//api.local.renderforest.com/api/v1'

export const METHODS = ['GET', 'PUT', 'POST', 'DELETE', 'PATCH']

export const uploadApiPath = '/api/v3'

export const subscribtionPath = 'https://www.renderforest.com/subscription'

export const CURRENT_HOST =
  (typeof window !== 'undefined' && window.CURRENT_HOST) ||
  'https://www.local.renderforest.com'
