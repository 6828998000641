// @flow
import styled from 'styled-components'

import { MESSENGER_STYLES } from '../consts'
import { getTextEllipsisStyles } from '../styled'

const getStyleForProp =
  (propName: string) =>
  ({ type }) =>
    (MESSENGER_STYLES[type] || {})[propName]

export const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  position: relative;
  padding: 24px 20px;
  box-sizing: border-box;
`

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
`

export const AvatarWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 52px;
  height: 52px;
  border-radius: 50% 50% 50% 50%;
  overflow: hidden;
  .image-state-overlay {
    border-radius: 50%;
  }
`

export const Avatar = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
`

export const HeaderTexts = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  margin-left: 16px;
  & > h5 {
    ${getTextEllipsisStyles};
    font-size: 16px;
    font-weight: 500;
  }
  & > p {
    ${getTextEllipsisStyles};
    font-size: 13px;
    margin-top: 5px;
  }
`

export const Content = styled.div`
  display: flex;
  max-height: 200px;
  overflow: auto;
  width: 100%;
  flex-direction: column;
  padding: 10px 0;
  box-sizing: border-box;
  background-size: contain;
  background-position: center;
`

export const Footer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
`

export const Button = styled.a`
  padding: 8px 0;
  box-sizing: border-box;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  & > img {
    fill: white;
    width: 20px;
    height: auto;
  }
  & > span {
    font-size: 14px;
    font-weight: 500;
    margin-left: 10px;
    max-width: 230px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const SingleMessenger = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 15px 0px;
  background: #eeeeee;
  @media only screen and (max-width: 768px) {
    width: 94vw;
    max-width: 360px;
  }
  ${Header} {
    background: ${getStyleForProp('headerBackground')};
    & * {
      color: ${getStyleForProp('headerTextColor')};
    }
  }
  ${Content} {
    background: ${getStyleForProp('contentBackground')} no-repeat;
    background-size: cover;
  }
  ${Footer} {
    background: ${getStyleForProp('footerBackground')};
  }
  ${Button} {
    background: ${getStyleForProp('buttonBackground')};
    border-radius: ${getStyleForProp('buttonRadius')};
    & span {
      color: ${getStyleForProp('buttonTextColor')};
    }
  }
`
