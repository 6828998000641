import styled, { css } from 'styled-components'
import { primary, secondary, white } from '@website/common/styles/colors'

export const LanguagesBlock = styled.div`
  margin-top: 20px;
  padding: 15px 0 5px;
  border-top: 1px solid #c2c9de;
  border-bottom: 1px solid #c2c9de;
  box-sizing: border-box;

  & > p {
    font-size: 12px;
    font-weight: 600;
    color: ${secondary.SemiDark};
    margin-bottom: 10px;
  }
`

export const Languages = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > div:first-child {
    margin-right: 0;
  }

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          opacity: 0.5;
          cursor: default;
          pointer-events: none;
        `
      : ''}
`

export const Language = styled.div`
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px 5px 5px;
  box-sizing: border-box;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  margin: 0 5px 10px 0;
  cursor: pointer;

  & > img {
    width: 20px;
    height: 20px;
    border-radius: 50px;
    margin-right: 5px;
    border: 1px solid #dee3f0;
  }

  ${({ isActive }) =>
    isActive
      ? css`
          color: ${white};
          background-color: ${primary.Dark};
        `
      : css`
          color: ${secondary.SemiDark};
          background-color: ${primary.Light};
        `}
`

export const AllButton = styled(Language)`
  padding: 0 15px;
`
export const Divider = styled.div`
  width: 1px;
  height: 20px;
  background-color: #c2c9de;
  margin: 0 10px 10px 10px;
`
