/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Cta7Morph = ({ theme, className = '' }) => {
  const { look, palette } = theme
  const isSharp = look === 'SHARP'
  const from = palette.primaryLight
  const to = palette.primaryDark
  const primaryColor = palette.primaryDark
  return (
    <svg
      className={className}
      width="754px"
      height="653px"
      viewBox="0 0 754 653"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="96.0553649%"
          id="linearGradient-1"
        >
          <stop offset="0%" stopColor={from} />
          <stop offset="100%" stopColor={isSharp ? from : to} />
        </linearGradient>
      </defs>
      <g
        id="frame"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M-2.33058017e-12,-1.42108547e-12 L754,-1.42108547e-12 L754,653 L-2.33058017e-12,653 L-2.33058017e-12,-1.42108547e-12 Z M228.005283,94.9698608 C207.006341,109.319175 189.564933,129.158852 176.930333,150.339398 C159.047066,180.430559 148.308013,214.187229 139.574225,247.892994 C111.200879,357.355166 127.79536,490.436639 242.50434,541.489212 C319.630129,575.616043 409.798955,564.332914 488.045905,540.72819 C602.829499,505.955678 776.883985,371.718707 666.082294,238.373899 C606.54235,166.7948 492.906473,174.447523 416.967392,129.508486 C381.33247,108.19878 349.8178,74.1602329 306.206377,71.691389 C277.519805,69.6563814 250.539782,79.1728511 228.005283,94.9698608 Z"
          fill="#F5F7FF"
          id="whiteBg"
        />
        <path
          d="M345.273985,82.4950812 C365.39792,72.7527151 386.391044,64.8406904 407.966655,58.9874154 C444.082792,49.1529522 488.798009,45.2191669 514.349561,78.1646187 C526.388273,93.8997599 533.267537,113.076963 542.603682,130.533136 C556.36221,156.594463 576.262938,164.216172 597.637795,181.672344 C605.352521,187.929751 612.906197,194.455763 620.103638,201.339165 C558.540774,167.622979 476.262469,164.597976 416.967392,129.508486 C392.857687,115.090867 370.634045,94.8465703 345.273975,82.4950767 L345.273985,82.4950812 Z"
          id="Path"
          fill={primaryColor}
          fillRule="nonzero"
          opacity={isSharp ? '1' : '0.2'}
        />
        <path
          d="M170.05318,162.741545 C156.178677,189.561314 147.126702,218.746187 139.574225,247.892994 C111.200879,357.355166 127.79536,490.436639 242.50434,541.489212 C307.55599,570.273445 381.886532,566.752769 450.516162,550.78134 C450.195206,551.053141 449.873273,551.323758 449.55037,551.59318 C407.955341,586.558283 352.028452,602.341497 297.637598,600.418618 C236.911726,598.436294 175.157031,573.801446 136.989529,526.359829 C115.914469,500.437374 103.466122,467.57615 101.425215,434.170536 C100.003699,407.747649 103.360027,380.96121 100.053119,354.734647 C96.7610736,326.924507 79.7033815,312.962183 66.2157305,290.492374 C48.6847566,260.964907 46.5224441,222.250562 64.4825605,192.371274 C80.5727735,165.59907 113.135411,148.868964 143.996644,153.781291 C153.268793,155.265913 161.928318,158.411469 170.05318,162.741545 Z M136.702513,115.127817 C119.368549,102.427311 127.57241,68.4270003 150.99311,72.2636112 C171.502762,75.5710345 183.808553,103.221093 163.960503,115.921598 C155.888962,121.213475 144.509413,120.816585 136.702513,115.127817 Z M193.505632,78.7795988 C189.479137,78.7795988 186.215017,75.5158444 186.215017,71.4897994 C186.215017,67.4637544 189.479137,64.2 193.505632,64.2 C197.532128,64.2 200.796247,67.4637544 200.796247,71.4897994 C200.796247,75.5158444 197.532128,78.7795988 193.505632,78.7795988 Z M515.641417,557.529363 C530.165385,551.721604 617.529958,504.439181 616.320403,544.951441 C615.411657,578.749738 551.316693,599.222903 525.588279,599.81957 C518.248185,599.961702 510.541305,599.280112 503.976997,595.622356 C497.412688,591.964599 492.49407,584.613195 493.496431,577.257915 C494.771459,566.820227 505.984162,561.335065 515.641417,557.529363 Z"
          fill="url(#linearGradient-1)"
        />
      </g>
    </svg>
  )
}

export default withTheme(Cta7Morph)
