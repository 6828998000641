import styled, { css } from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'

import { CONTROLS_HEIGHT } from './consts'

const getPositionStyles = ({ coords, alignment, isRelativeToWrapper }) => {
  if (isRelativeToWrapper) {
    return alignment === 'left'
      ? css`
          top: 0;
          left: 0;
          transform: translateY(-100%);
        `
      : css`
          top: 0;
          right: 0;
          transform: translateY(-100%);
        `
  }

  const { top, left } = coords

  return css`
    top: ${top}px;
    left: ${left}px;
  `
}

export const Controls = styled.div`
  ${getPositionStyles};
  height: ${CONTROLS_HEIGHT}px;
  display: flex;
  position: absolute;
  z-index: 25;
  padding-bottom: 5px;

  & > div:not(:first-child) {
    margin-left: 2.5px;
  }

  & > div:not(:last-child) {
    margin-right: 2.5px;
  }
`

export const ControlItem = styled.div`
  position: relative;
  min-width: 42px;
  height: 42px;
  cursor: pointer;
  display: inline-flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  border: 1px solid ${secondary.Light};
  box-sizing: border-box;
  flex-shrink: 0;
  box-shadow: 0 6px 12px 0 #3271e626;

  & > svg {
    fill: ${secondary.SemiDark};
    flex-grow: 1;
  }

  &:hover {
    & > svg {
      fill: ${secondary.ExtraDark};
    }
  }
`

export const ControlLabel = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 3px;
  white-space: nowrap;
  font-size: 14px;
  color: ${secondary.SemiDark};
  height: 100%;
  &:hover {
    color: ${secondary.ExtraDark};
  }
`
