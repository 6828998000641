/* @flow */
import React, { PureComponent } from 'react'
import Icon from '@website/common/components/Icon'
import { onEnterPress } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'

import Timepicker from 'react-datepicker'
import { TTimePickerInputState, TTimePickerInputProps } from '../types'
import * as S from '../styled.js'
import { secondary } from '@website/common/styles/colors'

class TimePickerInput extends PureComponent<
  TTimePickerInputProps,
  TTimePickerInputState
> {
  state = {
    isTimePickerOpen: false
  }
  timePickerContainer: null

  componentDidMount() {
    window.addEventListener('click', this.onBackdropClick)
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.onBackdropClick)
  }

  onBackdropClick = (e: any) => {
    const { isTimePickerOpen } = this.state
    if (
      isTimePickerOpen &&
      this.timePickerContainer &&
      !this.timePickerContainer.contains(e.target)
    ) {
      this.setState({ isTimePickerOpen: false })
    }
  }

  openTimePicker = e => {
    const { className } = e.target
    if (
      className !== 'react-datepicker__time-list-item' &&
      className.indexOf('input-container') === -1 &&
      className.indexOf('date-icon') === -1
    ) {
      this.setState({ isTimePickerOpen: true })
    }
  }

  render() {
    const { isTimePickerOpen } = this.state
    const { onTimeSelect, time, waitingForLink } = this.props
    return (
      <div className="time-item-container" onClick={this.openTimePicker}>
        <p className="date-label">{translate('expiry_time_label')}</p>
        <S.TimeInput
          disabled={waitingForLink}
          ref={ref => (this.timePickerContainer = ref)}
          isOpen={!!isTimePickerOpen}
          selected={!!time}
          className="input-container"
        >
          {!time && !isTimePickerOpen && (
            <S.InputText selected={!!time}>
              {translate('select_time_label')}
            </S.InputText>
          )}
          <Icon
            color={secondary.SemiLight}
            size="middle"
            name="icon-time_outlined"
            className="date-icon"
          />
          <Timepicker
            selected={time}
            onChange={onTimeSelect}
            showTimeSelect
            onKeyDown={onEnterPress(() =>
              this.setState({ isTimePickerOpen: false })
            )}
            showTimeSelectOnly
            timeIntervals={15}
            dateFormat="h:mmaa"
          />
        </S.TimeInput>
      </div>
    )
  }
}

export default TimePickerInput
