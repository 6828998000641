export const validation = {
  titleMaxChar: 100,
  paragraphMaxChar: 600,
  blockTitleMaxChar: 100,
  blockParagraphMaxChar: 100,
  title: { required: true },
  paragraph: { required: true },
  block: {
    min: 0,
    max: 3
  }
}
