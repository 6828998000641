/* @flow */
import styled from 'styled-components'
import { styleProvider } from '@website/common/utils'
import THEME from '../CTA13.theme'

const getStyleForProp = styleProvider(THEME)

export const MorphLeftWrapper = styled.div`
  width: 57%;
  position: absolute;
  z-index: 4;
  top: -20%;
  left: -25%;
  @media only screen and (max-width: 992px) {
    top: -28%;
  }
  @media only screen and (max-width: 650px) {
    top: -54%;
  }
  svg {
    width: 100%;
  }
  .a {
    fill: ${getStyleForProp('imageSecondaryColor')};
  }
  .b {
    fill: ${getStyleForProp('imagePrimaryColor')};
  }
  .c {
    fill: ${getStyleForProp('imageTertiaryColor')};
  }
`
