export const SLIDER_PARAMS = {
  focusOnSelect: true,
  infinite: true,
  autoplaySpeed: 5000,
  lazyLoad: 'progressive',
  slidesToShow: 1,
  centerMode: true,
  variableWidth: true,
  centerPadding: 0
}

export const ARROW_COLOR = '#FFFFFF'
export const ARROW_SIZE = 'middle'
export const TABLET_SIZE = 1024
export const MOBILE_SIZE = 450
