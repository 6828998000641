/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA12.styled'
import { validation } from './CTA12.consts'
import ActionGetter from './Actions'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'

const CTA12 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      blocks,
      additionalText,
      additionalUrl,
      infoTitle,
      infoTitleAlignment
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <S.Content>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.Content>
        <S.BlocksContainer className="first-block">
          <S.BlocksWrapper>
            {blocks.slice(0, 4).map((block, idx) => {
              return (
                <S.BlockItem isEditing={isEditing} order={blocks.length - idx}>
                  <S.ImageWrapper>
                    <S.ControlContainer>
                      <ControlsWithImageCropper
                        actions={Actions.imageActions(idx, block.imgUrl)}
                        style={{ width: '100%' }}
                      >
                        <Image
                          as={S.Img}
                          sizes={block.imgDimensions}
                          defaultImgSrc={block.imgUrl}
                          asProps={{
                            imgCropParams: block.imgCropParams
                          }}
                          alt="Cta image"
                        />
                      </ControlsWithImageCropper>
                    </S.ControlContainer>
                  </S.ImageWrapper>
                  <S.BlockContent>
                    <If
                      condition={!!block.iconName}
                      otherwise={() => (
                        <AddButton
                          onAdd={() => Actions.addIcon(idx)}
                          type="icon"
                          large
                          toShow
                        >
                          +
                        </AddButton>
                      )}
                      then={() => (
                        <S.IconWrapper>
                          <ControlsWithModalOpener
                            actions={Actions.iconActions(idx)}
                          >
                            <S.Circle>
                              <Icon
                                color="white"
                                size="xxlarge"
                                name={block.iconName}
                              />
                            </S.Circle>
                          </ControlsWithModalOpener>
                        </S.IconWrapper>
                      )}
                    />
                    <EditableContent
                      text={block.title}
                      as={S.BlockTitle}
                      alignment={block.blockTitleAlignment}
                      maxCount={validation.blockTitleMaxChar}
                      className="WM_GLOBAL_paragraph14"
                      onChange={(newTitle: string) =>
                        Actions.changeBlockTitle(newTitle, idx)
                      }
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx)
                      }
                    />
                  </S.BlockContent>
                </S.BlockItem>
              )
            })}
          </S.BlocksWrapper>
        </S.BlocksContainer>
        <S.BlocksContainer className="rf-reverse second-block">
          <S.BlocksWrapper>
            {blocks.slice(4).map((block, idx) => {
              return (
                <S.BlockItem isEditing={isEditing} order={blocks.length - idx}>
                  <S.ImageWrapper>
                    <S.ControlContainer>
                      <ControlsWithImageCropper
                        actions={Actions.imageActions(idx + 4, block.imgUrl)}
                        style={{ width: '100%' }}
                      >
                        <Image
                          as={S.Img}
                          sizes={block.imgDimensions}
                          defaultImgSrc={block.imgUrl}
                          asProps={{
                            imgCropParams: block.imgCropParams
                          }}
                          alt="Cta image"
                        />
                      </ControlsWithImageCropper>
                    </S.ControlContainer>
                  </S.ImageWrapper>
                  <S.BlockContent>
                    <If
                      condition={!!block.iconName}
                      otherwise={() => (
                        <AddButton
                          onAdd={() => Actions.addIcon(idx + 4)}
                          type="icon"
                          large
                          toShow
                        >
                          +
                        </AddButton>
                      )}
                      then={() => (
                        <S.IconWrapper>
                          <ControlsWithModalOpener
                            actions={Actions.iconActions(idx + 4)}
                          >
                            <S.Circle>
                              <Icon
                                color="white"
                                size="xxlarge"
                                name={block.iconName}
                              />
                            </S.Circle>
                          </ControlsWithModalOpener>
                        </S.IconWrapper>
                      )}
                    />
                    <EditableContent
                      text={block.title}
                      as={S.BlockTitle}
                      alignment={block.blockTitleAlignment}
                      maxCount={validation.blockTitleMaxChar}
                      className="WM_GLOBAL_paragraph14"
                      onChange={(newTitle: string) =>
                        Actions.changeBlockTitle(newTitle, idx + 4)
                      }
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx + 4)
                      }
                    />
                  </S.BlockContent>
                </S.BlockItem>
              )
            })}
          </S.BlocksWrapper>
          <S.InfoWrapper>
            <S.InfoContent>
              <EditableContent
                text={infoTitle}
                as={S.InfoTitle}
                alignment={infoTitleAlignment}
                required={validation.infoTitle.required}
                maxCount={validation.infoTitleMaxChar}
                className="WM_GLOBAL_paragraph14"
                onChange={(newTitle: string) =>
                  Actions.changeInfoTitle(newTitle)
                }
                changeAlignment={Actions.changeInfoTitleAlignment}
              />
              <AdditionalLink
                index={null}
                additionalLinkText={additionalText}
                additionalLinkUrl={additionalUrl}
              />
            </S.InfoContent>
          </S.InfoWrapper>
        </S.BlocksContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default CTA12
