/* @flow */
export const validation = {
  headingMaxChar: 100,
  blockHeadingMaxChar: 200,
  blocks: {
    min: 2,
    max: 12,
    title: { required: true }
  }
}
