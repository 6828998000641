export const validation =  {
  headingMaxChar: 20,
  paragraphMaxChar: 60,
  socials: {
    min: 1,
    max: 6
  },
  slides: {
    min: 1,
    max: 5
  }
}