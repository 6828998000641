import { translate } from '@editor/common/utils/translations'
import { isValidEmail, isValidPhoneNumber } from '@website/common/utils'

export const VALIDATION = {
  isRequired: {
    validator: value => value !== '',
    errorMessage:
      translate('this_field_cannot_be_empty_label') ||
      'This field cannot be empty'
  },
  isEmail: {
    validator: value => isValidEmail(value),
    errorMessage:
      translate('please_enter_a_valid_email') || 'Please enter a valid email'
  },
  isPhone: {
    validator: value => isValidPhoneNumber(value),
    errorMessage:
      translate('please_enter_a_valid_phone_number') ||
      'Please enter a valid phone number'
  }
}

export const WITH_VALIDATION_ERROR_CLASSNAME = 'with_validation_error'
