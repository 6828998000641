/* @flow */
import React, { memo, useRef } from 'react'
import S from './../Header51.styled'
import THEME from './../Header51.theme'
import Icon from '@website/common/components/Icon'
import { translate } from '@editor/common/utils/translations'
import { DispatchContext } from '@contexts'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Image from '@website/common/components/Image'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const ImageItem = memo(
  ({
    isEditing,
    alt,
    className,
    imgDimensions,
    imageUrl,
    imgCropParams,
    id,
    addImage,
    imageActions,
    isAnimated
  }) => {
    return (
      <If
        condition={!!imageUrl}
        otherwise={() => (
          <If
            condition={!isEditing}
            otherwise={() => (
              <AddButton
                onAdd={addImage}
                id={id}
                className={className}
                toShow={true}
                style={{ margin: '50px auto' }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_image_label')}
              </AddButton>
            )}
            then={() => null}
          />
        )}
        then={() => (
          <S.ImageFrame className={className} id={id} isEditing={isEditing}>
            <S.ImageWrapper isEditing={isEditing} isAnimated={isAnimated}>
              <S.ControlContainer>
                <ControlsWithImageCropper
                  actions={imageActions}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    isEditing={isEditing}
                    sizes={imgDimensions}
                    defaultImgSrc={imageUrl}
                    asProps={{
                      imgCropParams
                    }}
                    alt={alt}
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.ImageWrapper>
          </S.ImageFrame>
        )}
      />
    )
  }
)

export default ImageItem
