export const validation = {
  titleMaxChar: 50,
  blockTitleMaxChar: 100,
  blockParagraphMaxChar: 150,
  blockTitle: { required: true },
  blocks: {
    min: 1,
    max: 6
  }
}
