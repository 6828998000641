/* @flow */
import React, { useContext, memo } from 'react'
import S from './Contact1.styled'
import { validation } from './Contact1.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Contact1 = memo(
  ({
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      socials,
      socialTitle,
      emails
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    return (
      <S.StyledContainer isEditing={isEditing}>
        <S.WmCustomContainer>
          <Show when={[title, paragraph]}>
            <S.TextWrap>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  as={S.Title}
                  alignment={titleAlignment}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeTitle}
                  className="WM_GLOBAL_heading"
                  changeAlignment={Actions.changeTitleAlignment}
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  alignment={paragraphAlignment}
                  maxCount={validation.paragraphMaxChar}
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
            </S.TextWrap>
          </Show>
          <S.ContactItemsWrap className="WM_GLOBAL_secondary-font">
            <Show when={[socialTitle, ...socials]}>
              <S.SocialsWrap className="social-wrap">
                <EditableContent
                  text={socialTitle}
                  as={S.SocialTitle}
                  maxCount={validation.blockTitleMaxChar}
                  onChange={Actions.changeSocialTitle}
                />
                <S.SocialItems>
                  <Mapper
                    data={socials}
                    render={(social, idx) => (
                      <ControlsWithModalOpener
                        key={idx}
                        actions={Actions.getSocialActions(
                          idx,
                          social.socialUrl
                        )}
                        alignment={idx >= socials.length - 3 ? 'right' : 'left'}
                      >
                        <LinkWrapper
                          url={social.socialUrl}
                          linkProps={{ 'aria-label': 'Social link' }}
                        >
                          <Icon
                            size="middle"
                            color="white"
                            name={social.icon}
                          />
                        </LinkWrapper>
                      </ControlsWithModalOpener>
                    )}
                  />
                  <AddButton
                    onAdd={Actions.addSocial}
                    toShow={socials.length < validation.socials.max}
                    large
                    type="icon"
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                  </AddButton>
                </S.SocialItems>
              </S.SocialsWrap>
            </Show>
            <Mapper
              data={emails}
              render={(email: string, idx: number) => (
                <ControlsWithModalOpener
                  key={idx}
                  actions={Actions.contactActions(idx, emails.length)}
                >
                  {!isEditing && !email.title && !email.to ? null : (
                    <S.EmailWrap>
                      {!isEditing && !email.title ? null : (
                        <EditableContent
                          text={email.title}
                          as={S.EmailTitle}
                          maxCount={validation.blockTitleMaxChar}
                          onChange={value =>
                            Actions.changeEmailTitle(value, idx)
                          }
                        />
                      )}
                      {!isEditing && !email.to ? null : (
                        <S.EmailTextWrap>
                          <EditableContent
                            areControlsHidden
                            text={email.to}
                            as={S.Email}
                            maxCount={validation.emailMaxChar}
                            href={
                              idx ? 'tel:' + email.to : 'mailto:' + email.to
                            }
                            onChange={(newEmail: string) => {
                              Actions.changeEmail(newEmail, idx)
                            }}
                          />
                        </S.EmailTextWrap>
                      )}
                    </S.EmailWrap>
                  )}
                </ControlsWithModalOpener>
              )}
            />
          </S.ContactItemsWrap>
          <AddButton
            onAdd={Actions.addEmail}
            toShow={emails.length < validation.emails.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_contact_item_label')}
          </AddButton>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Contact1
