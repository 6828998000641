/* @flow */
import {
  cropableImageModalActionMaker,
  buttonModalActionMaker
} from '@website/components/ActionMakers'

import { removeBlock } from '@website/components/CommonActions'

export default dispatcher => {
  const imageActions = (idx: number, imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature21',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    })
  ]

  const changeTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeParagraph = (newParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH',
      value: { newParagraph, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }
  const buttonActions = buttonModalActionMaker(dispatcher)

  const addLink = (idx: number) =>
    dispatcher({
      type: 'ADD_LINK',
      value: { idx }
    })

  const addBlock = (idx: number) =>
    dispatcher({
      type: 'ADD_BLOCK',
      value: { idx }
    })

  return {
    addBlock,
    removeBlock,
    imageActions,
    changeTitle,
    changeParagraph,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    addLink,
    buttonActions
  }
}
