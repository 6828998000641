import { translate } from '@editor/common/utils/translations'

export const DIALOG_BUTTONS = {
  cancel: translate('cancel_label'),
  confirm: translate('apply_label', true)
}

export const BACKGROUND_IMG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/choose-template-background.png'
export const MOBILE_BACKGROUND_IMG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/choose-template-mobile-bg.png'
export const CURVED_DETAIL =
  'https://static.rfstat.com/renderforest/images/website_maker_images/subcategory-curved-detail.svg'
