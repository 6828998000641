import styled, { css } from 'styled-components'
import { accentGreen, secondary } from '@website/common/styles/colors'

const SPINNER_BORDER_STYLE = '1px solid white'
const THUMB_PADDING = 3
const ASPECT_RATIO = 9 / 5
const sizes = {
  small: { width: 36, height: 20 },
  medium: { width: 48, height: 28 },
  large: { width: 65, height: 36 }
}

const getStylesBySize = ({ size }) => {
  const { width, height } = sizes[size] || sizes.medium
  return css`
    width: ${width}px;
    height: ${height}px;
  `
}

const getStylesByCheckedState = ({ isChecked, isLoading, checkedBgColor }) => {
  const backgroundColor =
    (isChecked && isLoading) || (!isChecked && !isLoading)
      ? secondary.Normal
      : checkedBgColor || accentGreen
  return css`
    background-color: ${backgroundColor};
  `
}

const getTbumbStylesByCheckedState = ({ isChecked, size }) => {
  const width = sizes[size]?.width || sizes.medium.width
  return isChecked
    ? css`
        transform: translateX(calc(${width}px - ${2 * THUMB_PADDING}px - 100%));
      `
    : css`
        transform: translateX(0);
      `
}

export const Switcher = styled.div`
  ${getStylesBySize};
  ${getStylesByCheckedState};
  cursor: pointer;
  border-radius: 20px;
  aspect-ratio: ${ASPECT_RATIO};
  align-items: center;
  justify-content: space-between;
  padding: ${THUMB_PADDING}px;
  box-sizing: border-box;
  & > div {
    width: 100%;
    height: 100%;
    & > div {
      ${getTbumbStylesByCheckedState};
      transition: transform
        ${({ isFailed }) =>
          isFailed ? '100ms cubic-bezier(.72,.53,.71,1.69)' : '300ms ease-out'};
      border-radius: 50%;
      height: 100%;
      aspect-ratio: 1;
    }
  }
`

export const LoadingThumb = styled.div`
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  width: 100%;
  height: 100%;
  border-radius: 50%;
  box-sizing: border-box;
  border: ${SPINNER_BORDER_STYLE};
  border-right: 1px solid transparent;
  animation: spin 0.8s linear infinite;
`

export const StaticThumb = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: white;
`
