/* @flow */
import React, { useContext, memo } from 'react'
import S from './Steps10.styled'
import { validation } from './Steps10.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Steps10 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      blocks,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.titleMaxChar}
          required={!paragraph}
          onChange={Actions.changeTitle}
          className="WM_GLOBAL_heading42"
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Paragraph}
          maxCount={validation.paragraphMaxChar}
          required={!title}
          onChange={Actions.changeParagraph}
          className="WM_GLOBAL_paragraph18"
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.ItemsWrap areFourBlock={blocks.length === 4}>
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => {
              return (
                <S.Block>
                  <ControlsWithModalOpener
                    actions={Actions.blockActions({
                      idx,
                      showRemove: blocks.length > validation.blocks.min
                    })}
                    key={idx}
                  >
                    <S.BlockItemWrap>
                      <S.BlockHeadingWrap
                        withParagraph={block.blockTitle}
                        isEditing={isEditing}
                      >
                        <S.StepsItem>{idx + 1}</S.StepsItem>
                        <EditableContent
                          as={S.BlockTitle}
                          text={block.blockTitle}
                          maxCount={validation.blockTitleMaxChar}
                          required={!block.blockParagraph}
                          className="WM_GLOBAL_heading20"
                          onChange={(newBlockTitle: string) =>
                            Actions.changeBlockTitle(newBlockTitle, idx)
                          }
                        />
                      </S.BlockHeadingWrap>
                      <EditableContent
                        as={S.BlockParagraph}
                        text={block.blockParagraph}
                        alignment={block.blockParagraphAlignment}
                        maxCount={validation.blockParagraphMaxChar}
                        required={!block.blockTitle}
                        className="WM_GLOBAL_paragraph18"
                        onChange={(newBlockParagraph: string) =>
                          Actions.changeBlockParagraph(newBlockParagraph, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }
                      />
                    </S.BlockItemWrap>
                  </ControlsWithModalOpener>
                </S.Block>
              )
            }}
          />
        </S.ItemsWrap>
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addItem}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Steps10
