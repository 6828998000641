/* @flow */

export const validation = {
  headingMaxChar: 30,
  paragraphMaxChar: 150,
  subTitleMaxChar: 20,
  socialTextMaxChar: 20,
  title: { required: true },
  socials: {
    min: 3,
    max: 8
  },
  slides: {
    min: 1,
    max: 10
  },
  buttons: {
    min: 0,
    max: 2
  }
}

export const slickSliderSettings = {
  dots: false,
  autoplay: false,
  autoplaySpeed: 1000,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1
}
