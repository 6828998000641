export const validation = {
  headingMaxChar: 100,
  labelMaxChar: 30,
  name: { required: true },
  email: { required: true },
  subject: { required: true },
  phone: { required: true },
  message: { required: true },
  socials: {
    min: 0,
    max: 6
  }
}

export const EMPTY_FIELD_MESSAGE = 'This field cannot be empty'
export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email'

export const defaultPlaceholders = {
  name: 'What’s your name?',
  email: 'What’s your email address?',
  phone: 'What’s your phone number?',
  subject: 'What’s your phone number?',
  message: 'Type your message…'
}

export const NAME = 'name'
export const EMAIL = 'email'
export const PHONE = 'phone'
export const SUBJECT = 'subject'
export const MESSAGE = 'message'
