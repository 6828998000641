//@flow
import React, { memo, useMemo } from 'react'

import * as Styled from './styled'
import type { TProps } from '../types'
import { INTENT_ELEMENTS_DATA } from './consts'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import TooltipContent from '@editor/common/components/SemrushKeywordPopup/TooltipContent'
import { useResponsive } from '@shared/hooks'

const Intent = ({ data }: TProps) => {
  const intentElements = useMemo(() => {
    return INTENT_ELEMENTS_DATA.filter(intentEl =>
      data.split(',').includes(intentEl.value)
    )
  }, [data])

  const isSmallSize = useResponsive(1024)

  return intentElements.length ? (
    <Styled.IntentElements>
      {intentElements.map((intentElement, idx) => (
        <TooltipWrapper
          key={idx}
          text={
            isSmallSize ? (
              ''
            ) : (
              <TooltipContent
                title={intentElement.key}
                description={intentElement.tooltipText}
              />
            )
          }
        >
          <Styled.IntentElement data={intentElement}>
            {intentElement.key.charAt(0)}
          </Styled.IntentElement>
        </TooltipWrapper>
      ))}
    </Styled.IntentElements>
  ) : (
    <Styled.NA>n/a</Styled.NA>
  )
}

export default memo(Intent)
