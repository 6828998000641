/* @flow */
import React, { useContext, memo } from 'react'
import S from './Contact2.styled'
import { validation } from './Contact2.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { getMapSrcWithLatLng } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'

const Contact2 = memo(
  ({
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      links,
      mapSrc,
      geoLocation
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const _mapSrc = geoLocation ? getMapSrcWithLatLng(geoLocation) : mapSrc

    return (
      <S.StyledContainer isEditing={isEditing}>
        <S.WmCustomContainer>
          <Show when={[title, paragraph, links.length > 0]}>
            <S.TextItem>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  as={S.Title}
                  alignment={titleAlignment}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeTitle}
                  className="WM_GLOBAL_heading"
                  changeAlignment={Actions.changeTitleAlignment}
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  as={S.Description}
                  alignment={paragraphAlignment}
                  maxCount={validation.paragraphMaxChar}
                  onChange={Actions.changeParagraph}
                  className="WM_GLOBAL_paragraph"
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
              <S.LinksWrap className="WM_GLOBAL_secondary-font">
                <Mapper
                  data={links}
                  render={(link: string, idx: number) => (
                    <ControlsWithModalOpener
                      key={idx}
                      actions={Actions.linkActions(idx, link)}
                    >
                      <LinkWrapper url={link} text={link} />
                    </ControlsWithModalOpener>
                  )}
                />
                <AddButton
                  style={{ marginTop: '1rem' }}
                  withPadding={false}
                  onAdd={Actions.addLink}
                  toShow={links.length < validation.links.max}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_link_label')}
                </AddButton>
              </S.LinksWrap>
            </S.TextItem>
          </Show>
          <S.MapWrap>
            <ControlsWithModalOpener
              actions={Actions.getMapActions(mapSrc, geoLocation)}
              style={{ width: '100%', height: '100%' }}
            >
              <Overlay />
              <S.Map
                title="Map"
                src={_mapSrc}
                frameBorder="0"
                allowFullScreen=""
              />
            </ControlsWithModalOpener>
          </S.MapWrap>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Contact2
