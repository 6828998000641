import { useCallback, useEffect } from 'react'

const offset = 5
const useInfiniteScroll = (ref, cb) => {
  const handleScroll = useCallback(() => {
    const elem = ref.current

    if (
      elem.scrollHeight - Math.round(elem.scrollTop) <=
      elem.clientHeight + offset
    ) {
      cb()
    }
  }, [ref.current, cb])

  useEffect(() => {
    const elem = ref.current

    if (elem) {
      elem.addEventListener('scroll', handleScroll)
      return () => elem.removeEventListener('scroll', handleScroll)
    }
  }, [ref.current, handleScroll])
}

export default useInfiniteScroll
