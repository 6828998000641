/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeSubTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const changeParagraph1 = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH1',
      value: newParagraph
    })
  }

  const changeParagraph2 = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH2',
      value: newParagraph
    })
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeSubTitle = newSubTitle => {
    dispatch(changeSubTitle(newSubTitle))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const changeSubtitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraph1Alignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH1_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraph2Alignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH2_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeSubTitle: _changeSubTitle,
    changeParagraph1,
    changeParagraph2,
    getLargeImgActions: cropableImageModalActionMaker(dispatch),
    changeTitleAlignment: _changeTitleAlignment,
    changeSubtitleAlignment,
    changeParagraph1Alignment,
    changeParagraph2Alignment
  }
}
