// @flow

import React from 'react'
import { consumeHeaderNavbarContext } from '@contexts/HeaderNavBar.context'
import * as Styled from './styled'

import { ComponentIdWrapperProps } from './types'

const ComponentIdWrapper = ({
  id,
  idWithUniqKey,
  navBarHeight,
  children
}: ComponentIdWrapperProps) => (
  <Styled.Wrapper>
    <Styled.IdComp id={idWithUniqKey} top={navBarHeight} />
    <Styled.IdComp id={id} top={navBarHeight} />
    {children}
  </Styled.Wrapper>
)

export default consumeHeaderNavbarContext(ComponentIdWrapper)
