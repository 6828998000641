/* @flow */
/* eslint-disable max-statements */

import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_ADDITIONAL_BLOCK_TITLE':
        draft.additionalBlockTitle = action.value
        break
      case 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH':
        draft.additionalBlockParagraph = action.value
        break
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { idx, newParagraph } = action.value
        draft.blocks[idx].paragraph = newParagraph
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].paragraphAlignment = alignment
        break
      }
      case 'ADD_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx + 1, 0, initialState.blocks[0])
        break
      }
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'ADD_IMAGE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx + 1, 0, initialState.blocks[1])
        break
      }
      case 'REMOVE_IMAGE_BLOCK':
        const { idx: idx__ } = action.value
        draft.blocks.splice(idx__, 1)
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.blocks[idx].imageUrl = url
        draft.blocks[idx].imgDimensions = dimensions || {}
        draft.blocks[idx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.blocks[idx].imgCropParams = imgCropParams
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
