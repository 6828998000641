/* @flow */
import styled from 'styled-components'
import THEME from './Steps3.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .control-container {
    width: 100%;
    margin-bottom: 30px;
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
`

const StepCounter = styled.div`
  color: ${getStyleForProp('counter')};
  margin: 0;
  position: absolute;
  top: -50px;
  @media only screen and (max-width: 600px) {
    top: -40px;
  }
`

const StepItemHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;
`

const StepTitle = styled.h2`
  color: ${getStyleForProp('stepTitle')};
  font-weight: 800;
`

const ControlWrapper = styled.article`
  margin: 80px 0 0 0;
  width: 100%;
  &:nth-child(odd) {
    display: flex;
    justify-content: flex-end;
    ${StepCounter} {
      left: 66px;
      @media only screen and (max-width: 900px) {
        right: initial;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &:nth-child(even) {
    display: flex;
    justify-content: flex-start;
    ${StepCounter} {
      right: 66px;
      @media only screen and (max-width: 900px) {
        right: initial;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  @media only screen and (max-width: 600px) {
    margin-top: 60px;
  }
`

const StepWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('borderRadius')};
  border: ${getStyleForProp('border')};
  width: 100%;
  max-width: 770px;
  background-color: #fff;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
  &.left {
    ${StepTitle} {
      margin-left: 1rem;
    }
  }
  &.right {
    text-align: right;
    ${StepItemHeading} {
      justify-content: flex-start;
      flex-direction: row-reverse;
    }
    ${StepTitle} {
      margin-right: 1rem;
    }
  }
  @media only screen and (max-width: 992px) {
    &.left,
    &.right {
      text-align: left;
      ${StepItemHeading} {
        flex-direction: row;
      }
      ${StepTitle} {
        margin: 0 0 0 1rem;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    &.left,
    &.right {
      padding: 40px 16px;
      text-align: center;
      ${StepItemHeading} {
        flex-direction: column;
        margin-bottom: 1rem;
      }
      ${StepTitle} {
        margin: 1rem 0 0 0;
      }
    }
  }
`

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  @media only screen and (max-width: 992px) {
    margin-top: 10px;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 15px;
  }
`

const StepDescription = styled.p`
  color: ${getStyleForProp('stepDescription')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
`

const Circle = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('iconRadius')};
  ${getStyleForProp('buttonBackground')};
  pointer-events: none;
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextContainer,
  Title,
  Description,
  ControlWrapper,
  StepsContainer,
  StepTitle,
  StepDescription,
  StepCounter,
  Circle,
  StepWrap,
  StepItemHeading
}
