/* @flow */
import React, { useContext } from 'react'
import S from './Text5.styled'
import { validation } from './Text5.consts'
import Actions from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import './Text5.styles.sass'

const Text5 = ({
  data: {
    title,
    paragraph,
    backgroundImgDimensions,
    backgroundImgUrl,
    bgOverlayTransparency
  }
}) => {
  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  return (
    <Show when={[title, paragraph]}>
      <S.Container
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <WMContainer className="text-comp-container">
          <EditableContent
            text={title}
            as={S.Title}
            maxCount={validation.maxTitleChar}
            className="WM_GLOBAL_heading"
            onChange={(newTitle: string) =>
              dispatcher(Actions.changeTitle(newTitle))
            }
          />
          <S.Line />
          <EditableContent
            text={paragraph}
            as={S.Description}
            maxCount={validation.maxParagraphChar}
            className="WM_GLOBAL_paragraph"
            onChange={(newParagraph: string) =>
              dispatcher(Actions.changeParagraph(newParagraph))
            }
          />
        </WMContainer>
      </S.Container>
    </Show>
  )
}

export default Text5
