import styled, { css } from 'styled-components'

import { primary, secondary, white } from '@website/common/styles/colors'

export const FlyoutContent = styled.div`
  width: 280px;
  background-color: ${white};
  box-shadow: 0 6px 12px #3271e61a;
  border: 1px solid ${secondary.Light};
  border-radius: 5px;

  @media only screen and (max-width: 1024px) {
    width: 100%;
    border-radius: 0;
    border: none;
    box-shadow: unset;
  }

  & > * {
    font-family: 'Montserrat', sans-serif;
  }
`

export const FlyoutTitle = styled.h5`
  font-size: 12px;
  color: ${secondary.ExtraDark};
  padding: 15px;
  border-bottom: 1px solid ${secondary.Light};
  box-sizing: border-box;

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const Options = styled.div`
  display: flex;
  padding: 15px;
  box-sizing: border-box;

  & > div:not(:first-child) {
    margin-left: 5px;
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 20px;

    & > div:not(:first-child) {
      margin-left: 10px;
    }

    & > div {
      width: calc(100% / 3);
    }
  }
`

export const Option = styled.div`
  width: 80px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${secondary.SemiDark};
  font-size: 10px;
  font-weight: 500;
  box-sizing: border-box;
  cursor: pointer;

  ${({ isActive }) =>
    isActive
      ? css`
          background-color: ${white};
          border: 1px solid #3271e6;
          box-shadow: 0 4px 6px #3271e633;
        `
      : css`
          background-color: ${primary.Light};
          border: 1px solid transparent;
        `};

  & > svg {
    fill: ${secondary.SemiDark};
    margin-right: ${({ hasText }) => (hasText ? '5px' : '')};
  }

  &:hover {
    color: ${secondary.ExtraDark};
    & > svg {
      fill: ${secondary.ExtraDark};
    }
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`
