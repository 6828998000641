import { SIZE_LIMITS } from './consts'

export const getDeviceKey = () => {
  if (typeof window === undefined) {
    return
  }
  const { innerWidth } = window

  if (innerWidth > 1024) {
    return 'desktop'
  } else if (innerWidth > 768) {
    return 'tablet'
  }
  return 'mobile'
}

export const getSizeLimitsByScreenSize = () => {
  const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 1025
  if (innerWidth <= 768) {
    return SIZE_LIMITS.mobile
  } else if (innerWidth <= 1024) {
    return SIZE_LIMITS.tablet
  }
  return SIZE_LIMITS.desktop
}
