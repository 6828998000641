/* @flow */
import styled from 'styled-components'
import THEME from './Pricing8.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
  .buttons-block {
    max-width: 836px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    .buttons-wrapper {
      justify-content: center;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  > :first-child {
    padding-top: 0;
  }
  .add-button-wrap {
    margin-top: 20px;
  }
`
const HeaderContainer = styled.div`
  max-width: 836px;
  width: 100%;
  margin: 0 auto;
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const CardsContainer = styled.section`
  width: 100%;
  max-width: 836px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 34px;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    padding-top: 24px;
  }
`

const CardControlsWrapper = styled.article`
  width: 100%;
`

const PriceWrapper = styled.div`
  width: 100%;
  max-width: 121px;
  margin-left: 20px;
  padding: 16px 5px 16px 0;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;

  & .control-container {
    p {
      margin-bottom: 0;
    }
  }

  &:empty {
    width: 0;
    margin-left: 0px !important;
    padding: 0;
  }

  @media only screen and (max-width: 457px) {
    max-width: 80px;
    margin-left: 5px;
    padding-right: 0;
  }
`

const Heading = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
  & + p {
    margin-top: 20px;
  }
`

const Paragraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  margin-top: 0px;
  ${getTextStyleByAlignment};
`

const Title = styled.h2`
  width: 100%;
  margin-bottom: 10px;
  color: ${getStyleForProp('heading')};
  font-weight: 700;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

const CardContent = styled.div`
  padding: 16px 0 16px 5px;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  @media only screen and (max-width: 457px) {
    padding-left: 0;
  }
`

const Price = styled.p`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const PriceCard = styled.div`
  align-items: center;
  display: flex;
  align-items: stretch;
  position: relative;

  .control-container {
    width: 100%;
  }
`

const Line = styled.div`
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: -1px;
  width: 100%;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    height: 1px;
    width: 100%;
    border-bottom: 6px dashed ${getStyleForProp('line')};
  }
`

export default {
  HeaderContainer,
  CardsContainer,
  CardControlsWrapper,
  Line,
  PriceWrapper,
  Title,
  Heading,
  Description,
  StyledContainer,
  WmCustomContainer,
  Paragraph,
  PriceCard,
  Price,
  CardContent
}
