/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header34.styled'
import THEME from './Header34.theme'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { validation } from './Header34.consts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled/'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import If from '@website/common/components/Conditional'
import Image from '@website/common/components/Image'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SingleButton from '@website/common/components/SingleButton'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import SocialLinks from '@website/common/components/SocialLinks'

const Header34 = memo(props => {
  const {
    data: {
      blocks,
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      btnText,
      btnUrl,
      logoSrc,
      logoColor,
      headerSocials,
      logoWidth,
      logoDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isSocials={headerSocials.length > 4}
      topOffset={navBarHeight}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        isEditing={isEditing}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight}>
        <S.HeaderContent>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading42"
            as={S.Title}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <SingleButton
            btnUrl={btnUrl}
            btnText={btnText}
            isEditing={isEditing}
          />
          <SocialLinks
            className="header-socials"
            socials={headerSocials}
            socialsMaxCount={validation.headerSocials.max}
          />
        </S.HeaderContent>
        <S.BlocksContainer>
          {blocks.map((block, idx) => {
            return (
              <S.Block
                key={idx}
                className={'block-' + (idx + 1)}
                isEditing={isEditing}
              >
                <S.ImageWrapper>
                  <S.ControlContainer isOverlay={idx === 0 || idx === 3}>
                    <ControlsWithImageCropper
                      actions={Actions.imageActions(idx, block.imgUrl)}
                      style={{ width: '100%' }}
                    >
                      <Image
                        as={S.Img}
                        sizes={block.imgDimensions}
                        defaultImgSrc={block.imgUrl}
                        asProps={{
                          imgCropParams: block.imgCropParams
                        }}
                        alt="Cta image"
                      />
                    </ControlsWithImageCropper>
                  </S.ControlContainer>
                </S.ImageWrapper>
                <S.BlockContent>
                  <If
                    condition={!!block.iconName}
                    otherwise={() => (
                      <If
                        condition={idx !== 1 && idx !== 2}
                        then={() => (
                          <AddButton
                            onAdd={() => Actions.addIcon(idx)}
                            type="icon"
                            large
                            toShow
                          >
                            +
                          </AddButton>
                        )}
                      />
                    )}
                    then={() => (
                      <S.IconWrapper>
                        <ControlsWithModalOpener
                          actions={Actions.iconActions(idx)}
                        >
                          <S.Circle>
                            <Icon
                              color="white"
                              size="xxmlarge"
                              name={block.iconName}
                            />
                          </S.Circle>
                        </ControlsWithModalOpener>
                      </S.IconWrapper>
                    )}
                  />
                  <If
                    condition={block.title}
                    then={() => (
                      <EditableContent
                        text={block.title}
                        as={S.BlockTitle}
                        alignment={block.blockTitleAlignment}
                        required={validation.blockTitle.required}
                        maxCount={validation.blockTitleMaxChar}
                        className="WM_GLOBAL_heading20"
                        onChange={(newTitle: string) =>
                          Actions.changeBlockTitle(newTitle, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                    )}
                  />
                  <AdditionalLink
                    idx={idx}
                    additionalLinkText={block.additionalText}
                    additionalLinkUrl={block.additionalUrl}
                  />
                </S.BlockContent>
              </S.Block>
            )
          })}
        </S.BlocksContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header34
