// @flow

import {
  createEventAPI,
  updateEventDataAPI,
  getEventTranslationAPI,
  updateEventTranslationAPI,
  fetchGuestsListAPI,
  removeGuestAPI,
  fetchFormDataFromRevisionAPI,
  addGuestApi,
  editGuestApi,
  deleteEventAPI,
  getFullProjectDataAPI,
  updateEventEmailSubmissionStatusAPI,
  updateEventEmailContentAPI,
  updateEventEmailSettingsAPI
} from '@api'
import {
  getSiteId,
  selectEventId,
  selectGuestsListFilters,
  selectGuestsListLength,
  selectTotalResultsCount,
  selectIsGuestListPaginationLoading,
  selectIsGuestsListLoading,
  selectIsWebsiteMultilingual,
  selectWebsiteActiveLanguageCode,
  selectEvent,
  selectWebsiteEmail,
  selectUserEmail
} from '@selectors'
import { undoable } from '@editor/common/utils'
import { setGlobalErrorMessage, triggerApiError } from '@actions/editor'
import { setEventIdToRsvpComponents } from '@actions/project'
import type { TEventEndpointType, IEventFormFieldCommonData } from '@types'
import {
  SAVED_MESSAGE,
  SUCCESS_SAVED_MESSAGE,
  RSVP_EMAIL_DEFAULT_SENDER_NAME
} from '@editor/conf/consts'
import { EVENTS_INITIAL_DATA } from '@redux/consts'
import { getEmailDefaultContent } from '@redux/utils'

const GUESTS_LIST_LIMIT = 20

export const setEventsData = events => ({
  type: 'SET_EVENTS_DATA',
  value: events
})

export const setEventLoadingState = (isEventsLoading: boolean) => ({
  type: 'SET_EVENTS_LOADING_STATE',
  value: isEventsLoading
})

export const setEventCreatingState = (isEventCreating: boolean) => ({
  type: 'SET_EVENT_CREATING_STATE',
  value: isEventCreating
})

export const setEventSuccessMessage = (message: string) => ({
  type: 'SET_EVENT_SUCCESS_MESSAGE',
  value: message
})

const setEventForm = (value: IEventFormFieldCommonData) => ({
  type: 'SET_EVENT_FORM',
  value
})

const setEventSettings = (value: IEventFormFieldCommonData) => ({
  type: 'SET_EVENT_SETTINGS',
  value
})

const setDetailsDataAction = data => ({
  type: 'SET_DETAILS_DATA',
  value: data
})

const setPopupsDataAction = data => ({
  type: 'SET_POPUPS_DATA',
  value: data
})

const setEventTranslationData = (
  data: IEventFormFieldCommonData,
  eventId: string,
  lang: string
) => ({
  type: 'SET_EVENT_TRANSLATION_DATA',
  value: { eventId, data, lang }
})

export const setTranslationLoadingState = (isTranslationLoading: boolean) => ({
  type: 'SET_TRANSLATION_LOADING_STATE',
  value: isTranslationLoading
})

export const createEvent = initialData => (dispatch, getState) => {
  const siteId = getSiteId(getState())
  dispatch(setEventCreatingState(true))

  createEventAPI(initialData, siteId)
    .then(({ data }) => {
      dispatch(setEventsData([data]))
      dispatch(undoable(setEventIdToRsvpComponents(data.id)))
    })
    .catch(err => {
      dispatch(triggerApiError(err.message))
    })
    .finally(() => {
      dispatch(setEventCreatingState(false))
    })
}

const setterActions = {
  details: setDetailsDataAction,
  form: setEventForm,
  popups: setPopupsDataAction,
  settings: setEventSettings
}

export const updateEventData =
  (data: Object, type: TEventEndpointType) => (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const eventId = selectEventId(state)
    const setterAction = setterActions[type]
    const isWebsiteMultilingual = selectIsWebsiteMultilingual(state)
    const lang = isWebsiteMultilingual
      ? selectWebsiteActiveLanguageCode(state)
      : ''

    dispatch(setEventLoadingState(true))

    updateEventDataAPI(type, siteId, eventId, data, { lang })
      .then(({ data }) => {
        dispatch(setterAction(data))
        dispatch(setEventSuccessMessage(SUCCESS_SAVED_MESSAGE))
        setTimeout(() => dispatch(setEventSuccessMessage('')), 5000)
      })
      .catch(err => {
        dispatch(triggerApiError(err.message))
      })
      .finally(() => {
        dispatch(setEventLoadingState(false))
      })
  }

export const getEventTranslationData =
  (lang: string) => (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const eventId = selectEventId(state)

    dispatch(setTranslationLoadingState(true))

    getEventTranslationAPI(siteId, eventId, lang)
      .then(({ data }) => {
        dispatch(setEventTranslationData(data, eventId, lang))
      })
      .catch(err => {
        dispatch(triggerApiError(err.message))
      })
      .finally(() => {
        dispatch(setTranslationLoadingState(false))
      })
  }

export const updateEventTranslationData =
  (lang: string, data) => (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const eventId = selectEventId(state)

    dispatch(setEventLoadingState(true))

    updateEventTranslationAPI(lang, siteId, eventId, data)
      .then(({ data }) => {
        dispatch(setEventSuccessMessage(SUCCESS_SAVED_MESSAGE))
        dispatch(setEventTranslationData(data, eventId, lang))
        setTimeout(() => dispatch(setEventSuccessMessage('')), 5000)
      })
      .catch(err => {
        dispatch(triggerApiError(err.message))
      })
      .finally(() => {
        dispatch(setEventLoadingState(false))
      })
  }

export const setGuestsListLoadingState = (isLoading: boolean) => ({
  type: 'SET_GUESTS_LIST_LOADING_STATE',
  value: isLoading
})

const setGusetListPaginationLoadingState = (isLoading: boolean) => ({
  type: 'SET_GUESTS_LIST_PAGINATION_LOADING_STATE',
  value: isLoading
})

const setGuestsResultCounts = (answers, languages) => ({
  type: 'SET_GUESTS_RESULT_COUNTS',
  value: { answers, languages }
})

const guestListSetterAction =
  (setterActionType, offset = 0) =>
  (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const eventId = selectEventId(state)
    const filters = selectGuestsListFilters(state)

    dispatch(setGuestsListLoadingState(true))

    fetchGuestsListAPI(siteId, eventId, {
      ...filters,
      offset,
      limit: GUESTS_LIST_LIMIT
    })
      .then(({ data, totalCount: totalResultsCount }) => {
        const { answers, languages, results, totalCount = 0 } = data
        dispatch(setGuestsResultCounts(answers, languages))
        dispatch({
          type: 'SET_TOTAL_GUESTS_COUNT',
          value: totalCount
        })
        dispatch({
          type: 'SET_TOTAL_RESULTS_COUNT',
          value: totalResultsCount
        })
        dispatch({
          type: setterActionType,
          value: {
            results,
            offset,
            limit: GUESTS_LIST_LIMIT
          }
        })
      })
      .catch(err => {
        dispatch(triggerApiError(err.message))
      })
      .finally(() => {
        dispatch(setGuestsListLoadingState(false))
      })
  }

export const getGuestsList = () => dispatch => {
  dispatch(guestListSetterAction('SET_GUESTS_LIST_DATA'))
}

const replaceGuestListPageData = (pageNumber: number) => dispatch => {
  const offset = (pageNumber - 1) * GUESTS_LIST_LIMIT
  dispatch(guestListSetterAction('REPLACE_GUEST_LIST_PAGE_DATA', offset))
}

export const getGuestListNextPage = () => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const eventId = selectEventId(state)
  const filters = selectGuestsListFilters(state)
  const length = selectGuestsListLength(state)
  const totalResultsCount = selectTotalResultsCount(state)
  const isGuestListPaginationLoading = selectIsGuestListPaginationLoading(state)
  const isGuestListLoading = selectIsGuestsListLoading(state)
  const isOver = length === totalResultsCount

  if (isGuestListPaginationLoading || isGuestListLoading || isOver) {
    return
  }

  dispatch(setGusetListPaginationLoadingState(true))

  fetchGuestsListAPI(siteId, eventId, {
    ...filters,
    offset: length,
    limit: GUESTS_LIST_LIMIT
  })
    .then(({ data }) => {
      const { answers, languages, results } = data
      dispatch(setGuestsResultCounts(answers, languages))
      dispatch({
        type: 'PUSH_GUEST_LIST_DATA',
        value: results
      })
    })
    .catch(err => {
      dispatch(triggerApiError(err.message))
    })
    .finally(() => {
      dispatch(setGusetListPaginationLoadingState(false))
    })
}

const setGuestsListFilters = value => dispatch => {
  dispatch({
    type: 'SET_GUESTS_LIST_FILTERS',
    value
  })
  dispatch(getGuestsList())
}

export const setGuestsListSearchStr = (searchStr: string) => dispatch =>
  dispatch(setGuestsListFilters({ searchStr }))

export const setGuestsListLanguage = (language: string) => dispatch =>
  dispatch(setGuestsListFilters({ language }))

export const setGuestsListAnswer = (answer: string) => dispatch =>
  dispatch(setGuestsListFilters({ answer }))

export const setGuestsListSortingInfo =
  (columnName: string, isAscending: boolean) => dispatch =>
    dispatch(
      setGuestsListFilters({
        sortingInfo: {
          columnName,
          isAscending
        }
      })
    )

export const resetGuestsListFilters = () => dispatch =>
  dispatch(setGuestsListFilters(EVENTS_INITIAL_DATA.guestsList.filters))

export const removeGuest =
  (id: number, idx: number) => (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const eventId = selectEventId(state)
    const removedGuestPageNumber = Math.ceil(idx + 1 / GUESTS_LIST_LIMIT)

    dispatch(setGuestsListLoadingState(true))

    removeGuestAPI(siteId, eventId, id)
      .then(() => {
        dispatch({
          type: 'REMOVE_GUEST',
          value: id
        })
        dispatch(replaceGuestListPageData(removedGuestPageNumber))
      })
      .catch(err => {
        dispatch(triggerApiError(err.message))
        dispatch(setGuestsListLoadingState(false))
      })
  }

const setFormDataFromRevision = value => ({
  type: 'SET_FORM_DATA_FROM_REVISION',
  value
})

export const getFormDataFromRevision = () => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const eventId = selectEventId(state)

  dispatch(setGuestsListLoadingState(true))

  fetchFormDataFromRevisionAPI(siteId, eventId)
    .then(({ data: { formData } }) => {
      dispatch(setFormDataFromRevision(formData))
    })
    .catch(err => {
      dispatch(triggerApiError(err.message))
      dispatch(setFormDataFromRevision({}))
    })
    .finally(() => {
      dispatch(setGuestsListLoadingState(false))
    })
}

export const addGuest = (formData, resetData) => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const eventId = selectEventId(state)

  dispatch(setEventLoadingState(true))

  addGuestApi(siteId, eventId, formData)
    .then(() => {
      resetData()
      dispatch(resetGuestsListFilters())
      dispatch(setEventSuccessMessage(SAVED_MESSAGE))
      setTimeout(() => dispatch(setEventSuccessMessage('')), 5000)
    })
    .catch(e => {
      if (e.status === 412 && e.code === 'OutOfLimit') {
        const limit = parseInt(e.message)

        const errorMessage =
          limit === 0
            ? 'You have reached the limit'
            : limit === 1
            ? 'Only 1 place left available'
            : `Only ${limit} places left available`

        dispatch(setGlobalErrorMessage(errorMessage))
        return
      }

      dispatch(setGlobalErrorMessage(e.message))
    })
    .finally(() => {
      dispatch(setEventLoadingState(false))
    })
}

export const editGuest =
  (formData, guestId, updateData) => (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const eventId = selectEventId(state)

    dispatch(setEventLoadingState(true))

    editGuestApi(siteId, eventId, guestId, formData)
      .then(({ data }) => {
        updateData(data)
        dispatch(resetGuestsListFilters())
        dispatch(setEventSuccessMessage(SAVED_MESSAGE))
        setTimeout(() => dispatch(setEventSuccessMessage('')), 5000)
      })
      .catch(e => {
        dispatch(setGlobalErrorMessage(e.message))
      })
      .finally(() => {
        dispatch(setEventLoadingState(false))
      })
  }

export const deleteEvent = cb => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const eventId = selectEventId(state)
  const lang = selectWebsiteActiveLanguageCode(state)
  const isLangExists = lang !== 'lang'
  const params = {
    lang: isLangExists ? lang : ''
  }

  dispatch(setEventLoadingState(true))

  deleteEventAPI(siteId, eventId)
    .then(() => {
      return getFullProjectDataAPI(siteId, params)
    })
    .then(projectData => {
      const { data, visualParams, events } = projectData
      dispatch({
        type: 'SET_PROJECT_DATA',
        value: { ...data, visualParams }
      })
      dispatch(setEventsData(events))
      cb()
    })
    .catch(err => {
      triggerApiError(err)
    })
    .finally(() => {
      dispatch(setEventLoadingState(false))
    })
}

export const setConfirmationEmailContent = data => ({
  type: 'SET_CONFIRMATION_EMAIL_CONTENT',
  value: data
})

export const setRegretEmailContent = data => ({
  type: 'SET_REGRET_EMAIL_CONTENT',
  value: data
})

export const setEmailSettings = emailSettings => ({
  type: 'SET_EMAIL_SETTINGS',
  value: emailSettings
})

const emailContentSetterActions = {
  confirm: setConfirmationEmailContent,
  regret: setRegretEmailContent
}

const updateEventEmailContent = (type, data) => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const eventId = selectEventId(state)

  const setterAction = emailContentSetterActions[type]

  dispatch(setEventLoadingState(true))

  updateEventEmailContentAPI(siteId, eventId, type, data)
    .then(({ data }) => {
      dispatch(setterAction(data))
      dispatch(setEventSuccessMessage(SAVED_MESSAGE))
      setTimeout(() => dispatch(setEventSuccessMessage('')), 5000)
    })
    .catch(err => {
      dispatch(setGlobalErrorMessage(err.message))
      dispatch(triggerApiError(err.message))
    })
    .finally(() => {
      dispatch(setEventLoadingState(false))
    })
}

export const updateEventConfirmationEmailContent = data => dispatch => {
  dispatch(updateEventEmailContent('confirm', data))
}

export const updateEventRegretEmailContent = data => dispatch => {
  dispatch(updateEventEmailContent('regret', data))
}

export const updateEventEmailSettings = data => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const eventId = selectEventId(state)

  dispatch(setEventLoadingState(true))

  updateEventEmailSettingsAPI(siteId, eventId, data)
    .then(({ data }) => {
      dispatch(setEmailSettings(data))
      dispatch(setEventSuccessMessage(SAVED_MESSAGE))
      setTimeout(() => dispatch(setEventSuccessMessage('')), 5000)
    })
    .catch(err => {
      dispatch(setGlobalErrorMessage(err.message))
      dispatch(triggerApiError(err.message))
    })
    .finally(() => {
      dispatch(setEventLoadingState(false))
    })
}

const updateConfirmationEmailContentIfNeeded = (dispatch, eventData) => {
  if (!eventData?.emailData?.confirm) {
    const emailContent = getEmailDefaultContent(eventData.details.title.text)
    dispatch(updateEventConfirmationEmailContent(emailContent))
  }
}

const updateRegretEmailContentIfNeeded = (dispatch, eventData) => {
  if (!eventData?.emailData?.regret) {
    const emailContent = getEmailDefaultContent(
      eventData.details.title.text,
      true
    )
    dispatch(updateEventRegretEmailContent(emailContent))
  }
}

const emailStatusUpdateActions = {
  confirm: updateConfirmationEmailContentIfNeeded,
  regret: updateRegretEmailContentIfNeeded
}

const updateEventEmailStatus = (type, data) => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const eventId = selectEventId(state)
  const eventData = selectEvent(state)

  dispatch(setEventLoadingState(true))

  updateEventEmailSubmissionStatusAPI(siteId, eventId, type, data)
    .then(({ data }) => {
      dispatch(setEmailSettings(data))

      const emailStatusUpdateAction = emailStatusUpdateActions[type]

      emailStatusUpdateAction(dispatch, eventData)

      if (
        !eventData?.emailSettings?.senderName ||
        !eventData?.emailSettings?.replyTo
      ) {
        const websiteEmail = selectWebsiteEmail(state)
        const userEmail = selectUserEmail(state)

        const data = {
          senderName: RSVP_EMAIL_DEFAULT_SENDER_NAME,
          replyTo: websiteEmail || userEmail
        }

        dispatch(updateEventEmailSettings(data))
      }
    })
    .catch(err => {
      dispatch(setGlobalErrorMessage(err.message))
      dispatch(triggerApiError(err.message))
    })
    .finally(() => {
      dispatch(setEventLoadingState(false))
    })
}

export const updateEventConfirmationEmailStatus =
  isConfirmationEnabled => dispatch => {
    dispatch(updateEventEmailStatus('confirm', { isConfirmationEnabled }))
  }

export const updateEventRegretEmailStatus = isRegretEnabled => dispatch => {
  dispatch(updateEventEmailStatus('regret', { isRegretEnabled }))
}
