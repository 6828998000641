/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer10.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb,
  hexToRgbA
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: '0 0 20px 0';
  background-color: ${getStyleForProp('background')};
  overflow: hidden;
  .socials-block {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: nowrap;
    min-width: 250px;

    @media only screen and (max-width: 992px) {
      justify-content: center;
    }

    .socials-wrapper {
      display: flex;
      justify-content: flex-end;
      &:empty {
        display: none;
      }
    }

    & > .control-container:nth-last-child(-n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
  }
  .socials-wrapper {
    flex-wrap: nowrap;
    & > .control-container:nth-last-child(-n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
    .link {
      fill: ${getStyleForProp('paragraph')};
    }
  }
`

const SocialLinksstyled = styled.div`
  background: red;
`

const Line = styled.span`
  width: 100%;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  z-index: 10;
  background-color: ${props =>
    hexToRgbA(getStyleForProp('paragraph')(props), 0.3)};
`

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    max-width: 1600px;
    padding-left: 100px;
    padding-right: 100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;

    @media only screen and (max-width: 1440px) {
      max-width: 1320px;
      padding-left: 60px;
      padding-right: 60px;
    }
    @media only screen and (max-width: 992px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media only screen and (max-width: 600px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    & > div:nth-last-child(1):nth-child(1) {
      margin-left: 0px;
    }
  }

  .add-button-wrap.add-button-wrap {
    @media only screen and (max-width: 992px) {
      margin-right: 0;
      margin-top: 20px;
    }
  }
`

const CopyRightWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  ${({ isEditing }) =>
    isEditing &&
    css`
      margin-right: 30px;
    `}
  @media only screen and (max-width: 992px) {
    margin-top: 15px;
    order: 3;
  }
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
  .control-container p {
    margin-left: 0;
    margin-right: 0;
  }
`

const FooterContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1600px !important;
  padding-left: 100px;
  padding-right: 100px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  @media only screen and (max-width: 1440px) {
    max-width: 1320px !important;
    padding-left: 60px;
    padding-right: 60px;
  }
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 600px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  > .add-button-wrap {
    min-width: 125px;
  }
`

const BackgroundImageBlock = styled.div`
  position: relative;

  & > svg {
    width: 100%;
    height: auto !important;
    transform: translateY(-30%);
    @media only screen and (max-width: 992px) {
      transform: translateY(-40%);
    }
  }
`

const Background = styled.div`
  width: 100%;
  height: 280px;
  ${getBackgroundStyles(THEME)};
  @media only screen and (max-width: 600px) {
    height: 200px;
  }
`

const WaveWrapper = styled.div`
  position: absolute;
  bottom: -20px;
  left: -2%;
  width: 104%;
  @media only screen and (max-width: 992px) {
    bottom: -70px;
  }
  @media only screen and (max-width: 800px) {
    bottom: -70px;
  }
  @media only screen and (max-width: 600px) {
    height: 80px;
    bottom: -50px;
  }
  svg {
    display: block;
    > path {
      fill: ${getStyleForProp('background')};
    }
  }
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 250px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 15px;
    justify-content: center;
    &:empty {
      display: none;
    }
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .logo-wrapper {
    display: block;
    margin-right: 20px;
    @media only screen and (max-width: 992px) {
      margin-right: 0;
      margin-top: 30px;
    }
  }
  .add-button-wrap {
    margin-right: 20px;
    min-width: 125px;
  }
`

const FooterLogo = styled.img.attrs(({ _width }) => {
  return _width
    ? {
        style: { width: `${_width}px` }
      }
    : {}
})`
  height: auto;
  object-fit: contain;
  height: auto;
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  @media only screen and (min-width: 601px) {
    box-sizing: border-box;
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            max-width: calc(100% - 210px);
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 50%;
              transform: translateY(-50%);
            }
          `
        : css`
            max-width: 100%;
          `}
  }
  @media only screen and (max-width: 800px) {
    text-align: center;
  }
`

const LinkWrap = styled.div`
  position: relative;
  margin-right: 10px;
  max-width: 170px;
  min-width: 100px;
  & a {
    padding: 0px 8px !important;
  }
  &:hover {
    a {
      opacity: 0.6;
    }
  }

  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }

  @media only screen and (max-width: 600px) {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
    text-align: center;
    .control-container {
      margin-left: 0;
    }

    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
`

const WaveWrapperAnimation = styled.div`
  height: 200px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media only screen and (max-width: 992px) {
    height: 168px;
  }
  @media only screen and (max-width: 600px) {
    height: 120px;
  }

  .waves {
    position: relative;
    height: 200px;
    margin-bottom: -5px;
    @media only screen and (min-width: 993px) {
      width: 100%;
    }
    @media only screen and (max-width: 600px) {
      height: 150px;
    }
  }
  svg {
    position: absolute;
    bottom: 0%;
    @media only screen and (min-width: 992px) {
      width: 100%;
    }
  }
  ${({ isAnimated, isOnScreen, isEditing }) =>
    isAnimated &&
    isOnScreen &&
    !isEditing &&
    css`
      .parallax > use {
        animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
      }
    `};
  .parallax > use:nth-child(1) {
    fill: ${({ theme }) => `rgba(${hexToRgb(theme.palette.primaryDark)}, 0.8)`};
    z-index: 2;
    animation-delay: -2s;
    animation-duration: 11s;
  }
  .parallax > use:nth-child(2) {
    fill: ${({ theme }) =>
      `rgba(${hexToRgb(theme.palette.primaryLight)}, 0.6)`};
    z-index: 3;
    animation-delay: -3s;
    animation-duration: 14s;
  }
  .parallax > use:nth-child(3) {
    fill: ${({ theme }) => `rgba(${hexToRgb(theme.palette.primaryDark)}, 0.3)`};
    z-index: 4;
    animation-delay: -4s;
    animation-duration: 17s;
  }
  .parallax > use:nth-child(4) {
    fill: ${({ theme }) =>
      `rgba(${hexToRgb(theme.palette.primaryLight)}, 0.9)`};
    animation-delay: -5s;
    animation-duration: 20s;
  }
  @keyframes move-forever {
    0% {
      transform: translate3d(-90px, 0, 0);
    }
    100% {
      transform: translate3d(85px, 0, 0);
    }
  }
`

export default {
  Container,
  Line,
  SocialLinksstyled,
  FooterContainer,
  FooterContentWrap,
  FooterContent,
  FooterLogo,
  WaveWrapper,
  WaveWrapperAnimation,
  BackgroundImageBlock,
  Background,
  Copyright,
  LinkWrap,
  CopyRightWrap
}
