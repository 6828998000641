/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeCardData = (
    blockIdx: number,
    data: string,
    keyInStore: string
  ) => ({
    type: 'CHANGE_CARD_DATA',
    value: { keyInStore, blockIdx, data }
  })
  const changeBlockTitle = (title: string, blockIndex: number) => {
    dispatcher(changeCardData(blockIndex, title, 'blockTitle'))
  }

  const changeBlockPrice = (price: string, blockIdx: number) => {
    dispatcher(changeCardData(blockIdx, price, 'blockPrice'))
  }

  const changeBlockDescription = (date: string, BlockIdx: number) => {
    dispatcher(changeCardData(BlockIdx, date, 'blockDescription'))
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockDescriptionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_DESCRIPTION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changePriceAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PRICE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addBlock: () => dispatcher(addBlock()),
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle,
    changeBlockPrice,
    changeBlockDescription,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockDescriptionAlignment,
    changePriceAlignment,
    cardActions: removeBlockActionMaker(dispatcher)
  }
}
