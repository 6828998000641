// @flow

import React, { useCallback, useState } from 'react'
import Input from '@renderforest/rf-ui-library/molecules/Input'
import * as Styled from './styled'
import { MAX_TEXT_LIMIT, TEXT_LIMIT_ERROR } from './consts'
import { translate } from '@editor/common/utils/translations'

const LinkTextInput = ({
  value,
  error: errorFromProps,
  hasLimit,
  isDisabled,
  onChange
}) => {
  const [errorMessage, setErrorMessage] = useState('')

  const handleChange = useCallback(
    e => {
      const { value } = e.target
      if (hasLimit && value.length > MAX_TEXT_LIMIT) {
        setErrorMessage(TEXT_LIMIT_ERROR)
        return
      }
      onChange(value)
      setErrorMessage('')
    },
    [onChange]
  )

  return (
    <Styled.InputContainer withError>
      <Input
        disabled={isDisabled}
        error={errorFromProps || errorMessage}
        value={value}
        onChange={handleChange}
        placeholder={translate('enter_link_text')}
        label={translate('link_popup_for_action_label')}
      />
      {hasLimit && (
        <Styled.CharacterCount>
          {value.length}/{MAX_TEXT_LIMIT}
        </Styled.CharacterCount>
      )}
    </Styled.InputContainer>
  )
}

export default LinkTextInput
