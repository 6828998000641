/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_DATE_TITLE':
        draft.dateTitle = action.value
        break
      case 'CHANGE_DATE':
        draft.countdownDate = action.value
        break
      case 'CHANGE_DATE_LABEL':
        const { dateLabelValue, dateLabelIdx } = action.value
        draft.dateLabels[dateLabelIdx] = dateLabelValue
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
    }
  })
}
