/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Feature8.styled'
import { validation } from './Feature8.consts'
import ActionGetter from './Actions'
import { KEY_CODES } from '@website/conf/consts'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Feature8 = memo(props => {
  const [activeFeatureIdx, setActiveFeatureIdx] = useState(0)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const beforeRemove = () => {
    if (activeFeatureIdx !== 0) {
      setActiveFeatureIdx(activeFeatureIdx - 1)
    }
  }
  const dots = () => {
    const {
      data: { features }
    } = props
    if (features.length === 1) {
      return null
    }
    return (
      <S.DotsContainer>
        {features.map((_, idx) => (
          <S.Dot
            isActive={activeFeatureIdx === idx}
            key={idx}
            onClick={() => setActiveFeatureIdx(idx)}
          />
        ))}
      </S.DotsContainer>
    )
  }
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      features,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <WMContainer>
        <Show when={[title, paragraph]}>
          <S.HeaderContent>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.HeaderContent>
        </Show>
        <S.FeturesContent>
          <S.BlocksContainer>
            <S.BlocksWrap role="tablist" isEditing={isEditing}>
              <Mapper
                data={features}
                render={(item, idx) => (
                  <ControlsWithModalOpener
                    key={idx}
                    actions={Actions.blockActions({
                      idx,
                      beforeRemove,
                      showRemove: features.length > validation.features.min
                    })}
                  >
                    <S.Block
                      isActiveFeatureIdx={activeFeatureIdx === idx}
                      onClick={() => setActiveFeatureIdx(idx)}
                      onKeyDown={e => {
                        if (e.keyCode === KEY_CODES.ENTER) {
                          setActiveFeatureIdx(idx)
                        }
                      }}
                      role="tab"
                      tabIndex="0"
                    >
                      <EditableContent
                        isLinkControlHidden
                        text={item.title}
                        as={S.BlockTitle}
                        toPropogate={false}
                        required={validation.features.title.required}
                        maxCount={validation.tabTextMaxChar}
                        isActiveFeatureIdx={activeFeatureIdx === idx}
                        className="WM_GLOBAL_secondary-font"
                        onChange={(newBlockTitle: string) => {
                          Actions.changeBlockTitle(newBlockTitle, idx)
                        }}
                      />
                    </S.Block>
                  </ControlsWithModalOpener>
                )}
              />
              <div
                className={`tab-layer 
               tab-layer-${activeFeatureIdx}`}
              />
            </S.BlocksWrap>
            <AddButton
              onAdd={Actions.addBlock}
              toShow={features.length < validation.features.max}
              style={{ marginLeft: '1rem', marginBottom: '30px' }}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_tab_label')}
            </AddButton>
          </S.BlocksContainer>
          <If
            condition={features.length > 0}
            then={() => (
              <React.Fragment>
                <S.ImagesWrap>
                  <S.Figure>
                    <S.ControlContainer>
                      <ControlsWithImageCropper
                        style={{ width: '100%' }}
                        actions={Actions.blockImgActions({
                          blockIdx: activeFeatureIdx,
                          imageUrl: features[activeFeatureIdx].imgUrl
                        })}
                      >
                        <Image
                          as={S.FeatureImage}
                          alt="Feature image"
                          role="tabpanel"
                          defaultImgSrc={features[activeFeatureIdx].imgUrl}
                          sizes={features[activeFeatureIdx].imgDimensions}
                          asProps={{
                            imgCropParams:
                              features[activeFeatureIdx].imgCropParams
                          }}
                        />
                      </ControlsWithImageCropper>
                    </S.ControlContainer>
                  </S.Figure>
                </S.ImagesWrap>
                {dots()}
              </React.Fragment>
            )}
          />
        </S.FeturesContent>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Feature8
