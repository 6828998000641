/* @flow */
import styled from 'styled-components'
import THEME from './Text9.theme'
import LibIcon from '@website/common/components/Icon'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding-top: ${({ isEditing }) => isEditing && '140px'};
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const Row = styled.div`
  display: flex;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  & > .control-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: 768px) {
      flex-direction: row;
      &:nth-last-child(1).control-container .styled-controls {
        right: 0;
        left: unset;
      }
    }
  }
`

const BlockWrap = styled.div`
  width: 19%;
  flex-grow: 1;
  box-sizing: border-box;
  border-right: 1px solid ${getStyleForProp('borderColor')};
  border-top: 1px solid ${getStyleForProp('borderColor')};
  border-bottom: 1px solid ${getStyleForProp('borderColor')};
  &:first-child {
    border-left: 1px solid ${getStyleForProp('borderColor')};
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    border-left: 1px solid ${getStyleForProp('borderColor')};
    border-top: none;
    &:first-child {
      border-top: 1px solid ${getStyleForProp('borderColor')};
    }
  }
`

const Block = styled.div`
  min-height: 18vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  @media only screen and (max-width: 767px) {
    min-height: 160px;
    max-width: 500px;
    margin: 0 auto;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h2 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

const IconWrap = styled.div``

const Title = styled.h2`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  font-size: 16px;
`

const Circle = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getStyleForProp('iconText')};
`

const IconWrapper = styled.div`
  user-select: none;
  margin: 10px;
`
const Icon = styled(LibIcon).attrs(props => ({
  color: getStyleForProp('iconText')(props)
}))``

export default {
  StyledContainer,
  Row,
  BlockWrap,
  Title,
  Block,
  IconWrapper,
  IconWrap,
  Circle,
  Icon
}
