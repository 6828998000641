import React from 'react'

export default ({ isAnimated, isOnScreen, isEditing }) => {
    const duration = isAnimated && isOnScreen && !isEditing ? "5000ms" : '0ms'
    
    return(
    <svg viewBox="0 0 485 485" xmlns="http://www.w3.org/2000/svg" style={{ margin: 'auto', background: 'none', display: 'block', position: 'absolute', width: '100%', height: '100%'}}>
        <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%"></stop>
                <stop offset="100%"></stop>
            </linearGradient>
        </defs>
        <path id="blob" fill="#24A8F4">
            <animate dur={duration} repeatCount="indefinite" attributeName="d" values="M446,307Q427,364,388,415Q349,466,284.5,462.5Q220,459,166,434Q112,409,67,363Q22,317,20.5,249.5Q19,182,68,139.5Q117,97,166,51Q215,5,276,32.5Q337,60,379.5,99.5Q422,139,443.5,194.5Q465,250,446,307Z;
                M466,309Q433,368,386,406Q339,444,279.5,452Q220,460,160.5,441Q101,422,65.5,368.5Q30,315,40.5,253.5Q51,192,80,139.5Q109,87,162.5,51Q216,15,280.5,29Q345,43,385,90Q425,137,462,193.5Q499,250,466,309Z;
                M440,306Q425,362,387.5,415.5Q350,469,284,470.5Q218,472,165,440.5Q112,409,82.5,358.5Q53,308,42,247Q31,186,67.5,134Q104,82,161.5,57Q219,32,279,43Q339,54,390.5,90.5Q442,127,448.5,188.5Q455,250,440,306Z;
                M446,307Q427,364,388,415Q349,466,284.5,462.5Q220,459,166,434Q112,409,67,363Q22,317,20.5,249.5Q19,182,68,139.5Q117,97,166,51Q215,5,276,32.5Q337,60,379.5,99.5Q422,139,443.5,194.5Q465,250,446,307Z">
            </animate>
        </path>
        <path id="blob1" fill="url(#gradient)">
            <animate dur={duration} repeatCount="indefinite" attributeName="d" values="M442,321Q392,392,321,428Q250,464,166,441Q82,418,42.5,334Q3,250,47,170.5Q91,91,170.5,58Q250,25,321,66.5Q392,108,442,179Q492,250,442,321Z;
            M446,332Q414,414,332,455.5Q250,497,176.5,447Q103,397,59,323.5Q15,250,45,162.5Q75,75,162.5,48Q250,21,331.5,54Q413,87,445.5,168.5Q478,250,446,332Z;
            M432,324.5Q399,399,324.5,443.5Q250,488,175.5,443.5Q101,399,70,324.5Q39,250,60.5,166Q82,82,166,45.5Q250,9,321.5,58Q393,107,429,178.5Q465,250,432,324.5Z;
            M442,321Q392,392,321,428Q250,464,166,441Q82,418,42.5,334Q3,250,47,170.5Q91,91,170.5,58Q250,25,321,66.5Q392,108,442,179Q492,250,442,321Z"></animate>
        </path>
    </svg>
)}