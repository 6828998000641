//@flow
import React from 'react'
import { connect } from 'react-redux'
import { openLoginPopup } from '@actions/editor'
import { translate } from '@editor/common/utils/translations'
import { selectIsUserAuthorized } from '@selectors'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import { StyledControlItem } from '@website/common/components/Controlled/Controls/styled'
import TooltipWrapper from '@website/common/components/TooltipWrapper'

import { TEventControlItemProps } from './types'

const TOOLTIP_TEXTS = {
  details: translate('edit_details_label'),
  forms: translate('edit_forms_label')
}

const EventControlItem = ({
  tabName = 'details',
  onClick
}: TEventControlItemProps) => {
  const tooltipText = TOOLTIP_TEXTS[tabName]

  return (
    <TooltipWrapper text={tooltipText} className="event-control">
      <StyledControlItem onClick={onClick}>
        <EditIcon />
      </StyledControlItem>
    </TooltipWrapper>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

const mapDispatchToProps = { openLoginPopup }

export default connect(mapStateToProps, mapDispatchToProps)(EventControlItem)
