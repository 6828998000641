import styled, { css } from 'styled-components'

import { secondary } from '@website/common/styles/colors'
import _CloseIcon from '@website/common/assets/svgr-icons/close.svg'

export const PopupContainer = styled.div`
  position: fixed;
  height: 100%;
  width: 100vw;
  z-index: 50;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(54, 63, 90, 0.5);
`

export const Popup = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  max-height: 90%;
  max-width: 90%;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  animation-name: ${({ isOpen }) => (isOpen ? 'openPopup' : 'closePopup')};
  animation-duration: 300ms;

  ${({ isMobileFullScreen }) =>
    isMobileFullScreen
      ? css`
          @media only screen and (max-width: 630px) {
            width: 100%;
            height: 100%;
            max-height: 100%;
            max-width: 100%;
            border-radius: 0;
          }
        `
      : ''};

  @keyframes openPopup {
    from {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  @keyframes closePopup {
    from {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
    }
  }
`

export const CloseIcon = styled(_CloseIcon)`
  cursor: pointer;
  position: absolute;
  width: 26px;
  height: 26px;
  fill: ${secondary.SemiLight};
  top: 10px;
  right: 10px;
  z-index: 1;
`
