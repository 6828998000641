export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    link: p => p.primaryLight
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    backgroundColor: p => p.tertiaryLight,
    link: p => p.primaryDark
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    link: p => p.primaryDark
  }
}
