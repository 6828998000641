// @flow

import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import {
  EditingContext,
  MobileDetectContext,
  ControlModalsContext,
  MediaLibContext,
  UpgradePopupContext,
  SiteIdContext,
  SiteHashContext,
  WebsiteLanguagesContext
} from '@contexts'

import { ConfirmDialogProvider } from '@contexts/ConfirmDialog.context'

import { useControlModals } from '@hooks'
import { useResponsive } from '@shared/hooks'
import { ControlModals } from '@website/common/components/Controlled'
import {
  selectSiteHash,
  getSiteId,
  selectWebsiteLanguagesData,
  selectLanguageMenuLayout
} from '@selectors'

const Providers = ({ children }) => {
  const [modalStates, setModalState] = useControlModals()
  const isMobile = useResponsive(768)

  const [upgradePopupState, setUpgradePopupState] = useState({
    isOpen: false
  })

  const [medialibProps, setMediaLibProps] = useState(false)
  const siteId = useSelector(getSiteId)
  const siteHash = useSelector(selectSiteHash)
  const websiteLanguagesData = useSelector(selectWebsiteLanguagesData)
  const languageMenuLayout = useSelector(selectLanguageMenuLayout)

  return (
    <MediaLibContext.Provider value={{ medialibProps, setMediaLibProps }}>
      <MobileDetectContext.Provider value={isMobile}>
        <ControlModalsContext.Provider value={{ modalStates, setModalState }}>
          <EditingContext.Provider value={{ isEditing: true }}>
            <SiteIdContext.Provider value={siteId}>
              <SiteHashContext.Provider value={siteHash}>
                <UpgradePopupContext.Provider
                  value={{ upgradePopupState, setUpgradePopupState }}
                >
                  <WebsiteLanguagesContext.Provider
                    value={{
                      data: websiteLanguagesData,
                      languageMenuLayout
                    }}
                  >
                    <ControlModals />
                    <ConfirmDialogProvider>{children}</ConfirmDialogProvider>
                  </WebsiteLanguagesContext.Provider>
                </UpgradePopupContext.Provider>
              </SiteHashContext.Provider>
            </SiteIdContext.Provider>
          </EditingContext.Provider>
        </ControlModalsContext.Provider>
      </MobileDetectContext.Provider>
    </MediaLibContext.Provider>
  )
}

export default Providers
