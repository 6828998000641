// @flow

import React, { memo } from 'react'
import { TWebsiteStatusCommonProps } from './types'
import * as Styled from './styled'
import Content from './Content'

const WebsiteStatusDesktop = ({ isOpen }: TWebsiteStatusCommonProps) => (
  <Styled.DesktopContentWrapper isOpen={isOpen}>
    <Content />
  </Styled.DesktopContentWrapper>
)

export default memo(WebsiteStatusDesktop)
