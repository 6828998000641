/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA2.styled'
import { validation } from './CTA2.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import SingleButton from '@website/common/components/SingleButton'

const CTA2 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttonUrl,
      buttonText,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <Show when={[title, paragraph, buttonText, buttonUrl]}>
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer className="simple-cta-container">
          <Show when={[title, paragraph]}>
            <S.HeaderContent>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                maxCount={validation.titleMaxChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading"
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                as={S.Description}
                alignment={paragraphAlignment}
                className="WM_GLOBAL_paragraph"
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </S.HeaderContent>
          </Show>
          <SingleButton
            btnUrl={buttonUrl}
            btnText={buttonText}
            componentDispatcher={dispatcher}
          />
        </S.WmCustomContainer>
      </S.StyledContainer>
    </Show>
  )
})

export default CTA2
