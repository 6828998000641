/* @flow */
import {
  clientLogoActionMaker,
  imageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const addSliderLogo = (idx: number) => {
    dispatcher({
      type: 'ADD_CLIENT_LOGO',
      value: idx
    })
  }
  const addClientImage = (idx: number) => {
    dispatcher({
      type: 'ADD_CLIENT_IMAGE',
      value: idx
    })
  }
  const changeClientData = (data: string, idx: number, keyInStore: string) => {
    dispatcher({
      type: 'CHANGE_CLIENT_DATA',
      value: { data, idx, keyInStore }
    })
  }
  const changeItemParagraph = (paragraph: string, idx: number) => {
    dispatcher
    changeClientData(paragraph, idx, 'paragraph')
  }

  const changeClientName = (name: string, idx: number) => {
    dispatcher
    changeClientData(name, idx, 'name')
  }

  const changeClientPosition = (position: string, idx: number) => {
    dispatcher
    changeClientData(position, idx, 'position')
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }


  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    addSliderLogo,
    addClientImage,
    changeParagraph: _changeParagraph,
    changeClientName,
    changeItemParagraph,
    changeClientPosition,
    getClientsActions: clientLogoActionMaker(dispatcher),
    imageActions: imageModalActionMaker(dispatcher),
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockParagraphAlignment
  }
}
