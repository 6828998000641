/* @flow */
import React, { useContext, useState, useRef, memo } from 'react'
import S from './Header49.styled'
import THEME from './Header49.theme'
import { validation } from './Header49.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Buttons from '@website/common/components/Buttons'
import Wave from './Header49_wave'
import AnimatedWave from './Header49_wave_animated'
import SocialLinks from '@website/common/components/SocialLinks'
import useOnScreen from '@website/common/hooks/useOnScreen'

const Header49 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      logoWidth,
      backgroundImgUrl,
      buttons,
      logoDimensions,
      isAnimated,
      headerSocials,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  } = props

  const animatedRef = useRef(null)
  const isOnScreen = useOnScreen(animatedRef)
  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <S.WaveWrapper
        isAnimated={isAnimated}
        ref={animatedRef}
        isOnScreen={isOnScreen}
        isEditing={isEditing}
      >
        {isAnimated && !isEditing ? <AnimatedWave /> : <Wave />}
      </S.WaveWrapper>
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight}>
        <S.ContentWrapper>
          <S.HeaderContent>
            <EditableContent
              text={title}
              required={!paragraph}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading42"
              as={S.Title}
              onChange={Actions.changeTitle}
              alignment={titleAlignment}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Description}
              required={!title}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              alignment={paragraphAlignment}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <SocialLinks
              className="header-socials"
              socials={headerSocials}
              socialsMaxCount={validation.headerSocials.max}
            />
            <Buttons data={buttons} />
          </S.HeaderContent>
        </S.ContentWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header49
