/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions: _dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = _dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({ ...initialState.buttons[0], type: btnType })
        } else {
          draft.buttons.push({ ...initialState.buttons[0], type: btnType })
        }
        break
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions || {}
        draft.imgCropParams = {}
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
      }
    }
  })
}
