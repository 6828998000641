export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 50,
  blockParagraphMaxChar: 100,
  features: {
    min: 1,
    max: 2
  }
}
