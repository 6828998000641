/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Steps10.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { secondary } from '@website/common/styles/colors'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
        `
      : ''};
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
  > *:first-child {
    padding-top: 0;
  }
`

const ItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  padding-top: 50px;
  ${({ areFourBlock }) =>
    areFourBlock
      ? css`
          max-width: 1000px;
        `
      : ''};
  column-gap: 75px;
  row-gap: 60px;
  @media only screen and (max-width: 1024px) {
    padding-top: 40px;
  }
  @media only screen and (max-width: 768px) {
    row-gap: 40px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin: 0 auto;
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin: 0 auto;
  padding-top: 20px;
`

const Block = styled.div`
  width: 100%;
  min-height: 100%;
  max-width: 400px;
  display: flex;
  align-items: stretch;
  width: 100%;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 1440px) {
    max-width: 350px;
  }
  background-color: #ffffff;
  > .control-container {
    width: 100%;
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:hover {
      box-shadow: 0px 0px 0px 1px ${secondary.Dark};
    }
  }
`

const BlockItemWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
  > .control-container {
    width: 100%;
  }
`

const BlockTitle = styled.h2`
  width: 100%;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-bottom: 0;
  position: relative;
  z-index: 1;
  margin-top: 10px;
`

const BlockParagraph = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-top: 20px;
  ${getTextStyleByAlignment};
  z-index: 2;
`

const ControlContainer = styled.div`
  position: relative;
`

const BlockHeadingWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ withParagraph, isEditing }) =>
    !withParagraph && !isEditing ? 'center' : 'flex-start'};
  align-items: flex-start;
  padding-bottom: 10px;
  border-bottom: ${({ theme }) =>
    `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  > :nth-child(2) {
    width: 100%;
    margin-left: 10px;
  }
`

const StepsItem = styled.div`
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: ${({ theme }) => theme.palette.accentWhite};
  font-weight: 700;
  border-radius: ${getStyleForProp('iconRadius')};
  ${getStyleForProp('buttonBackground')};
  pointer-events: none;
  box-shadow: none;
`

export default {
  StyledContainer,
  WmCustomContainer,
  ItemsWrap,
  Title,
  Paragraph,
  Block,
  BlockItemWrap,
  BlockTitle,
  BlockParagraph,
  ControlContainer,
  BlockHeadingWrap,
  StepsItem
}
