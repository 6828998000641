/* @flow */
import {
  getFullProjectDataAPI,
  getWebsiteLanguagesDataAPI,
  siteDataAPI
} from '@api'
import { getSiteId, selectWebsiteActiveLanguageCode } from '@selectors'
import {
  setHasChangesAfterPublish,
  setSiteData,
  setNoLongerPremiumPopupState,
  getSiteData
} from '@actions/editor'
import { assignPremiumPlanToWebsite } from '@actions/project'
import { setLanguageMenuLayout } from '@actions/website-languages'
import { setMetaSettings } from '@actions/meta'
import { setEventsData } from '@actions/events'
import { setComponentsData } from '@actions/components'

import { hasWebsitePremiumFeatures } from '@editor/common/utils/premium-features-checks'

export const initialDataLoad =
  (currentUser = {}) =>
  (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const lang = selectWebsiteActiveLanguageCode(state)
    const isLangExists = lang !== 'lang'
    const params = {
      lang: isLangExists ? lang : ''
    }
    const fullProjectDataPromise = getFullProjectDataAPI(siteId, params)
    const siteDataPromise = siteDataAPI(siteId)
    const websiteLanguagesDataPromise = getWebsiteLanguagesDataAPI(siteId)

    return Promise.all([
      fullProjectDataPromise,
      siteDataPromise,
      websiteLanguagesDataPromise
    ])
      .then(
        ([projectData, { data: siteData }, { data: websiteLanguageData }]) => {
          if (!siteData.paid) {
            if (currentUser.siteLimit) {
              dispatch(assignPremiumPlanToWebsite())
              dispatch(getSiteData())
            } else {
              if (
                hasWebsitePremiumFeatures(
                  projectData,
                  siteData,
                  websiteLanguageData
                )
              ) {
                dispatch(setNoLongerPremiumPopupState(true))
              }
            }
          }
          const {
            componentMeta,
            hasChangesAfterPublish,
            widgets,
            visualParams,
            cookieSettings,
            data,
            meta,
            events,
            languageMenuLayout
          } = projectData

          dispatch(setComponentsData(componentMeta))

          dispatch({
            type: 'SET_PROJECT_DATA',
            value: { ...data, visualParams }
          })
          events.length && dispatch(setEventsData(events))
          dispatch({ type: 'FREEZE_PROJECT_DATA' })

          cookieSettings &&
            dispatch({
              type: 'SET_COOKIE_SETTINGS',
              value: cookieSettings
            })

          widgets &&
            dispatch({
              type: 'SET_WIDGETS_DATA',
              value: widgets
            })
          dispatch({ type: 'SET_PROJECT_LOADED' })
          dispatch(setHasChangesAfterPublish(hasChangesAfterPublish))
          meta && dispatch(setMetaSettings(meta))
          dispatch(setSiteData(siteData))
          dispatch({
            type: 'SET_WEBSITE_LANGUAGES_DATA',
            value: websiteLanguageData
          })
          dispatch({ type: 'SET_WEBSITE_LANGUAGES_DATA_LOADED' })
          dispatch(setLanguageMenuLayout(languageMenuLayout))
        }
      )
      .catch(err => {
        if (err.status === 404 && isLangExists) {
          window.location.href = `/website-maker/${siteId}/lang/edit`
        }

        console.error(err)
      })
  }
