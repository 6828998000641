import styled from 'styled-components'

import { secondary, white } from '@website/common/styles/colors'
import Button from '@editor/common/lib/Button'

export const Content = styled.div`
  width: 280px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background-color: ${white};
  border: 1px solid ${secondary.UltraLight};

  @media only screen and (max-width: 768px) {
    width: 100%;
    border-radius: 0;
    border: none;
  }
`

export const Header = styled.div`
  padding: 15px 20px;
  border-bottom: 1px solid ${secondary.Light};
  box-sizing: border-box;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const LanguagesList = styled.ul`
  display: flex;
  flex-direction: column;
  overflow-y:auto
`

export const Title = styled.h4`
  color: ${secondary.ExtraDark};
  font-size: 14px;
`

export const Footer = styled.div`
  padding: 10px 20px;
  border-top: 1px solid ${secondary.Light};
  box-sizing: border-box;
`

export const SettingsButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    width: 18px;
    height: 18px;
    fill: #3271e6;
    margin-right: 7px;
  }

  &:hover {
    & > svg {
      fill: ${white};
    }
  }
`
