import styled from 'styled-components'

import THEME from './theme'
import Menu from '@website/common/components/Menu'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const LanguagesMenuContainer = styled.div`
  flex-shrink: 0;
  padding: 10px 0;

  & > .control-container {
    & > .styled-controls {
      right: 0;
      left: unset;
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 0;
    border-top: 1px solid ${getStyleForProp('borderColor')};
    border-bottom: 1px solid ${getStyleForProp('borderColor')};
    & > .control-container {
      width: 100%;
      & > .styled-controls {
        left: 30px;
      }
    }
  }
`

export const Flag = styled.img`
  width: 24px;
  height: 24px;
  border-radius: ${({ theme }) =>
    theme.look === 'ROUNDED'
      ? '15px'
      : theme.look === 'CORNERED'
      ? '5px'
      : '0'};
  border: 1px solid #dee3f0;
  opacity: ${({ isActive }) => (isActive ? 0.5 : 1)};
`

export const SelectedLanguageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px 0;
  margin: ${({ isEditing }) => (isEditing ? '0 20px' : '0 20px 0 0')};
  user-select: none;

  & > p {
    font-size: 16px;
    color: ${getStyleForProp('text')};
    margin-left: ${({ isFull }) => (isFull ? '15px' : '')};
  }

  & > svg {
    width: 24px;
    height: 24px;
    fill: ${getStyleForProp('text')};
    margin-left: 5px;
    flex-shrink: 0;
    transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(180deg)' : '')};
  }

  @media only screen and (min-width: 1025px) {
    &:hover {
      & > p,
      svg {
        opacity: 0.6;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    background-color: ${({ isMenuOpen }) =>
      isMenuOpen ? getStyleForProp('mobileSelectedContainerBgColor') : ''};
    padding: 20px 30px;
    margin: 0;
  }
`

export const LanguagesMenu = styled(Menu)`
  display: flex;
  flex-direction: column;
  background-color: ${getStyleForProp('backgroundColor')};
  border: 1px solid ${getStyleForProp('borderColor')};
  border-radius: ${({ theme }) =>
    theme.look === 'ROUNDED'
      ? '10px'
      : theme.look === 'CORNERED'
      ? '5px'
      : '0'};
  box-shadow: 0 3px 12px #0000001a;
`

export const MobileLanguagesMenu = styled.div`
  overflow-y: hidden;
  transition: all 0.2s ease-out;
  max-height: ${({ isOpen }) => (isOpen ? '580px' : '0px')};
  display: flex;
  flex-direction: column;
`

export const MenuItem = styled.div`
  position: relative;
  padding: 13px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  order: ${({ order }) => order};
  opacity: ${({ isActive }) => (isActive ? 0.5 : 1)};

  & > p {
    font-size: 14px;
    color: ${getStyleForProp('text')};
    min-width: 100px;
    margin-left: ${({ isFull }) => (isFull ? '15px' : '')};
  }

  @media only screen and (min-width: 1025px) {
    &:hover {
      & > p,
      img {
        opacity: 0.6;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 13px 30px;

    & > p {
      font-size: 16px;
      min-width: unset;
    }
  }
`
