import styled from 'styled-components'

export const Keyword = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 15px;

  .copy-icon-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -5px;
    cursor: pointer;
    svg {
      display: none;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    .copy-icon-wrapper {
      svg {
        fill: #8493bd;
        display: block;

        &:hover {
          fill: #545f7e;
        }
      }
    }
  }
`

export const TooltipContent = styled.p`
  width: 50px;
  text-align: center;
`
