/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Steps6.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  .control-container {
    width: 100%;
    max-width: 900px;
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
    }
    h2 {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${props => (props.isFourBlock ? '1100px' : '1200px')};
  margin: -25px auto;
  padding-top: ${({ hasTitle, isEditing }) =>
    !hasTitle && !isEditing ? '24px' : '50px'};
  @media only screen and (max-width: 992px) {
    padding-top: ${({ hasTitle, isEditing }) =>
      !hasTitle && !isEditing ? '24px' : '40px'};
  }
`

const BlockWrap = styled.article`
  width: 30%;
  max-width: 310px;
  min-width: 280px;
  display: flex;
  margin: 25px;
  @media only screen and (max-width: 380px) {
    max-width: 260px;
  }
`

const Block = styled.div`
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 48px 30px;
  border-radius: ${getStyleForProp('cardRadius')};
  background-color: #fff;
  box-sizing: border-box;
  word-break: break-word;
  position: relative;
  box-shadow: ${getStyleForProp('raisedShadow')};
`

const Count = styled.div`
  width: 48px;
  height: 48px;
  border-radius: ${getStyleForProp('iconRadius')};
  position: absolute;
  top: -24px;
  left: -24px;
  background: ${({ isEven, theme }) =>
    isEven ? theme.palette.primaryDark : theme.palette.primaryLight};
  color: ${({ theme }) => theme.palette.accentWhite};
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  line-height: 48px;
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
`

export default {
  StyledContainer,
  WmCustomContainer,
  BlocksWrapper,
  BlockWrap,
  Title,
  BlockTitle,
  Block,
  Count
}
