export const validation = {
  titlehMaxChar: 20,
  subTitleMaxChar: 120,
  paragraphMaxChar: 250,
  contentImage: 1,
  title: { required: true },
  slides: {
    min: 1,
    max: 15
  }
}