export const validation = {
  headingMaxChar: 30,
  paragraphMaxChar: 300,
  title: { required: true },
  blockTitle: { required: true },
  blockTitleMaxChar: 12,
  blockParagraphMaxChar: 80,
  blocks: {
    min: 2,
    max: 4
  },
  buttons: {
    min: 0,
    max: 2
  }
}

export const buttonsInitialState = [
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]
