import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .editable-control-container {
    display: block;
  }
  
  .text-customization-link-wrapper {
    display: inline;
  }
`

export const DoubleClickLayer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: transparent;
`
