/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_BLOCK_TITLE': {
        const { newBlockTitle, idx } = action.value
        draft.blocks[idx].blockTitle = newBlockTitle
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { newBlockParagraph, idx } = action.value
        draft.blocks[idx].blockParagraph = newBlockParagraph
        break
      }

      case 'CHOOSE_PARAGRAPH_ALIGNMENT': {
        const { alignment } = action.value
        draft.paragraphAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockParagraphAlignment = alignment
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break
      case 'REMOVE_LINK':
        draft.additionalText = ''
        draft.additionalUrl = ''
        break
      case 'ADD_LINK':
        const { additionalText, additionalUrl } = initialState
        draft.additionalText = additionalText
        draft.additionalUrl = additionalUrl
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'ADD_SOCIAL':
        draft.headerSocials.push(initialState.headerSocials[0])
        break
      case 'REMOVE_SOCIAL':
        draft.headerSocials.splice(action.value.idx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.headerSocials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.headerSocials[idx].icon = iconName
        }
        break
    }
  })
}
