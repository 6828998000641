/* @flow */
import React from 'react'
import ReactDOM from 'react-dom'
import ReactPlayer from 'react-player'
import {
  LINK_TYPES,
  INVALID_IMAGE_URL_MESSAGE,
  INVALID_VIDEO_URL_MESSAGE,
  INTERNAL_IMAGE_URL_REGEXP,
  RF_STAT_IMAGE_URL_SUBSTR
} from '../consts'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

export const validateImageUrl = src => {
  const image = new Image()
  image.src = src
  return new Promise((res, rej) => {
    image.addEventListener('load', () => res(true))
    image.addEventListener('error', () => rej(INVALID_IMAGE_URL_MESSAGE))
  })
}

export const validateVideoUrl = url => {
  const div = document.createElement('div')
  div.style.display = 'none'
  document.body.appendChild(div)
  return new Promise((res, rej) => {
    ReactDOM.render(
      <ReactPlayer
        style={{
          display: 'none'
        }}
        url={url}
        onReady={() => {
          res(true)
          document.body.removeChild(div)
        }}
        onError={() => {
          rej(INVALID_VIDEO_URL_MESSAGE)
          document.body.removeChild(div)
        }}
      />,
      div
    )
  })
}

export const validateUrl = (url, type) => {
  if (LINK_TYPES.image === type || LINK_TYPES.logo === type) {
    return validateImageUrl(url)
  }
  if (LINK_TYPES.video === type) {
    return validateVideoUrl(url)
  }
}

export const isInternalImage = url => {
  const _url = url.toString()

  return (
    new RegExp(INTERNAL_IMAGE_URL_REGEXP).test(_url) ||
    _url.includes(RF_STAT_IMAGE_URL_SUBSTR)
  )
}

export const getTitle = type => {
  if (type === LINK_TYPES.social) {
    return translate('add_social_media_url_label')
  }

  return transformTextVariables(translate('add_logo_url'), [
    {
      value: type.toLowerCase()
    }
  ])
}
