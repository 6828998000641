/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_BUTTON_DATA':
        {
          const { link, text } = action.value.btnData
          draft.btnUrl = link
          draft.btnText = text
        }
        break
      case 'REMOVE_BUTTON':
        draft.btnUrl = ''
        draft.btnText = ''
        break
      case 'ADD_BUTTON':
        draft.btnText = initialState.btnText
        draft.btnUrl = initialState.btnUrl
        break
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions
        draft.imgCropParams = {}
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_SUBTITLE':
        draft.subTitle = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_SUB_TITLE_ALIGNMENT': {
        draft.subTitleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'SET_IMG_CROP_PARAMS':
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE': {
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      }
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'REMOVE_SOCIAL':
        draft.socials.splice(action.value.idx, 1)
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.socials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.socials[idx].icon = iconName
        }
        break
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break
      case 'ADD_LINK': {
        const { idx } = action.value
        draft.additionalUrl = initialState.additionalUrl
        draft.additionalText = initialState.additionalText
        break
      }
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.additionalUrl = ''
        draft.additionalText = ''
        break
      }
      case 'CHANGE_SCROLL_TEXT':
        draft.scrollText = action.value
        break
      case 'REMOVE_ELEMENT':
        draft.hasElement = false
        break
      case 'ADD_ELEMENT':
        draft.hasElement = true
        break
      case 'REMOVE_RUNING_TEXT':
        draft.hasRuningText = false
        break
      case 'ADD_RUNING_TEXT':
        draft.hasRuningText = true
        draft.runingText = initialState.runingText
        break
      case 'CHANGE_RUNING_TEXT':
        draft.runingText = action.value
        break
      case 'CHANGE_ICON': {
        const { iconName } = action.value
        draft.icon = iconName
        break
      }
      case 'REMOVE_ICON': {
        draft.icon = ''
        break
      }
      case 'ADD_ICON': {
        draft.icon = 'glyph-stars-complexity-3'
        break
      }
    }
  })
}
