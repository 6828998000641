/* @flow */
import React, { useContext } from 'react'
import TextCustomizationToolbar from '@website/common/components/TextCustomizationToolbar'
import TextWizardAiControl from '@website/common/components/TextWizardAiControl'
import { MobileDetectContext } from '@contexts'

export default ({
  editableEl,
  alignment,
  isTextGradient,
  changeAlignment,
  changeTextGradientStatus,
  isLinkControlHidden,
  isUnderlineHidden,
  isTextFormatingHidden,
  isGradientControlHidden,
  activeNodesNames,
  updateActiveNodeNames,
  customActions = [],
  onChange,
  maxCount
}) => {
  const isMobileOrTablet = useContext(MobileDetectContext)

  return [
    ...customActions,
    {
      renderer: props => (
        <>
          <TextCustomizationToolbar
            {...props}
            editableEl={editableEl}
            alignment={alignment}
            isTextGradient={isTextGradient}
            changeAlignment={changeAlignment}
            changeTextGradientStatus={changeTextGradientStatus}
            isLinkControlHidden={isLinkControlHidden}
            isUnderlineHidden={isUnderlineHidden}
            isTextFormatingHidden={isTextFormatingHidden}
            isGradientControlHidden={isGradientControlHidden}
            activeNodesNames={activeNodesNames}
            updateActiveNodeNames={updateActiveNodeNames}
          />
          {isTextFormatingHidden || isMobileOrTablet ? null : (
            <TextWizardAiControl
              editableEl={editableEl}
              text={editableEl?.innerText}
              onChange={onChange}
              maxCount={maxCount}
            />
          )}
        </>
      )
    }
  ]
}
