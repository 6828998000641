import React, { memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { changeEntirePalette } from '@actions/editor'
import { alternatePalettes } from '@editor/conf/consts'
import bringIntoViewport from '@editor/common/utils/bringIntoViewport'
import { getSemrushDependedScreenBreakpoint } from '@editor/common/utils/styled'
import { selectSemrushSidebarVisibilityState } from '@selectors'
import { useResponsive } from '@shared/hooks'

// import NewBadge from '../NewBadge/NewBadge'
import * as Styled from './styled'

const ColorPalette = ({
  id,
  isActive,
  paletteName,
  scrollableContainerRef
}) => {
  const dispatch = useDispatch()
  const isSemrushSidebarVisible = useSelector(
    selectSemrushSidebarVisibilityState
  )

  const semrushSidebarBreakpoint = getSemrushDependedScreenBreakpoint(
    800,
    isSemrushSidebarVisible
  )

  const isMobile = useResponsive(semrushSidebarBreakpoint)

  const palette = alternatePalettes[paletteName]

  const onClick = useCallback(
    e => {
      dispatch(changeEntirePalette(paletteName))

      if (!scrollableContainerRef.current) return

      const options = {
        horizontalMargin: isMobile ? 0 : 100,
        extraScrollOffset: 40,
        isVerticalScroll: !isMobile
      }

      bringIntoViewport(
        e.currentTarget,
        scrollableContainerRef.current,
        options
      )
    },
    [paletteName, scrollableContainerRef, changeEntirePalette, isMobile]
  )

  return (
    <Styled.PaletteWrapper id={id} isActive={isActive} onClick={onClick}>
      <Styled.Palette isActive={isActive} data-name={paletteName}>
        {Object.keys(palette).map(paletteKey => (
          <Styled.PaletteColor
            key={paletteKey}
            style={{ backgroundColor: palette[paletteKey] }}
          />
        ))}
      </Styled.Palette>
      {/*<NewBadge />*/}
    </Styled.PaletteWrapper>
  )
}

export default memo(ColorPalette)
