/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Pricing7.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (max-width: 1400px) and (min-width: 769px) {
    ${({ isEditing }) =>
      isEditing &&
      css`
        padding-top: 140px;
      `};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  .rf-badge-svg {
    position: absolute;
    top: -3px;
    right: -3px;
  }
  @media only screen and (max-width: 768px) {
    > .add-button-wrap {
      margin-top: 30px;
    }
  }
  .styled-controls {
    z-index: 8;
  }
`

const Heading = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  font-weight: 400;
  ${getTextStyleByAlignment};
  width: 100%;
`

const LabelContainer = styled.div`
  width: 194px;
  margin: 0 auto;
  position: relative;
  transform: translateY(-14px);
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 13px;
    top: 0;
    background-color: ${({ theme }) => theme.palette.tertiarySemiLight};
    z-index: 2;
  }
  &:before {
    clip-path: polygon(7px 0, 7px 14px, 0 14px);
    left: 0;
  }
  &:after {
    clip-path: polygon(0 0, 7px 14px, 0 14px);
    right: 0;
  }
`

const LabelBorder = styled.div`
  width: 180px;
  margin: 0 auto;
  box-sizing: border-box;
  border: ${getStyleForProp('border')};
  clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - 50px),
    90px 100%,
    0 calc(100% - 50px)
  );
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background: ${getStyleForProp('border')};
`

const Label = styled.div`
  width: 178px;
  min-height: 150px;
  margin: 0 auto;
  padding: 20px 20px 70px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  ${({ theme, isPrimary }) =>
    theme.colorMode !== 'DARK' && isPrimary
      ? css`
          color: ${theme.palette.accentWhite};
        `
      : css``}
  ${({ isPrimary }) =>
    isPrimary
      ? css`
          padding: 40px 20px 70px 20px;
        `
      : css`
          padding: 20px 20px 70px 20px;
        `}
  clip-path: polygon(
    0 1px,
    100% 1px,
    100% calc(100% - 51px),
    89px calc(100% - 1px),
    0 calc(100% - 51px)
  );
  background: ${({ isPrimary, theme }) =>
    isPrimary
      ? `linear-gradient(180deg, ${theme.palette.primaryLight}, ${theme.palette.primaryDark})`
      : getStyleForProp('labelBackground')};
`

const CardTitle = styled.p`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  font-weight: bold;
  ${getTextStyleByAlignment};
  ${({ isEditing }) =>
    !isEditing &&
    css`
      padding: 30px 20px;
    `};
`

const PricingCardsContainer = styled.div`
  margin: 0px auto -10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  max-width: ${props => (props.isFourBlock ? '800px' : 'initial')};
  @media only screen and (max-width: 1302px) {
    margin-bottom: -36px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: -20px;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 0px;
  }
`

const PriceCardWrapper = styled.article`
  display: flex;
  color: ${getStyleForProp('cardTextColor')};
  transform: ${props => (props.isPrimary ? 'translateY(-20px)' : 'initial')};
  padding-top: ${props => (props.isEditing ? '30px' : '')};
  @media only screen and (max-width: 768px) {
    transform: initial;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
    .control-container {
      width: 100%;
    }
  }
`

const PricingHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 50px auto;
  max-width: 700px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 40px;
    max-width: 100%;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .control-container {
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
  .control-container:first-child {
    margin-bottom: 20px;
  }
`

const PriceCard = styled.div`
  height: ${props => (props.isEditing ? 'initial' : 'auto')};
  flex: 1;
  box-sizing: border-box;
  box-shadow: ${props =>
    getStyleForProp(props.isPrimary ? 'raisedShadow' : 'shadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 350px;
  margin: 36px 20px;
  box-sizing: border-box;
  border: 1px solid ${getStyleForProp('border')};
  position: relative;
  @media only screen and (max-width: 768px) {
    margin: 20px;
  }
  @media only screen and (max-width: 600px) {
    margin: 2rem 0 0 0;
  }
  @media only screen and (max-width: 400px) {
    width: 100%;
  }
  @media only screen and (min-width: 769px) {
    &:before {
      content: '';
      position: absolute;
      left: 0px;
      right: 0px;
      background: ${getStyleForProp('cardBackground')};
      border-bottom-left-radius: ${getStyleForProp('borderRadius')};
      border-bottom-right-radius: ${getStyleForProp('borderRadius')};
      box-shadow: ${props =>
        getStyleForProp(props.isPrimary ? 'raisedShadow' : 'shadow')};
      z-index: 1;
    }
    &:after {
      content: '';
      position: absolute;
      height: 18px;
      box-sizing: border-box;
      background: ${getStyleForProp('cardBackground')};
      z-index: 2;
    }
    ${({ isPrimary }) =>
      isPrimary
        ? css`
            &:before {
              outline: 1px solid ${getStyleForProp('border')};
              height: 50px;
              bottom: -46px;
              left: 0px;
              right: 0px;
            }
            &:after {
              height: 18px;
              bottom: 0;
              left: 0px;
              right: 0px;
            }
          `
        : css`
            &:before {
              height: 30px;
              bottom: 0;
              left: 0px;
              right: 0px;
            }
            &:after {
              bottom: 15px;
              left: 0px;
              right: 0px;
            }
          `}
  }
`

const PricingCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  > * {
    &:not(:first-child) {
      border-top: 1px solid ${getStyleForProp('border')};
    }
  }
  .add-button-wrap {
    border-top: none;
  }
`

const PricingCardHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background: ${getStyleForProp('cardBackgroundGradient')};
  backdrop-filter: blur(15px);
  border-top-left-radius: ${getStyleForProp('borderRadius')};
  border-top-right-radius: ${getStyleForProp('borderRadius')};
  border-bottom: 1px solid ${getStyleForProp('border')};
  > .control-container {
    margin: 20px 30px;
    width: calc(100% - 60px);
    p {
      margin-bottom: 0;
    }
  }
`

const PricingCardButtomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${getStyleForProp('cardBackground')};
  border-bottom-left-radius: ${getStyleForProp('borderRadius')};
  border-bottom-right-radius: ${getStyleForProp('borderRadius')};
  flex-grow: 1;
`

const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: auto;
  padding-bottom: 50px;
  box-sizing: border-box;
  padding-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
  transform: ${props => (props.isPrimary ? 'translateY(-2px)' : 'initial')};
  @media only screen and (max-width: 768px) {
    transform: initial;
  }
  @media only screen and (min-width: 769px) {
    padding-bottom: ${props => (props.isPrimary ? '30px' : '50px')};
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    ${getStyleForProp('buttonBackground')};
    ${props =>
      getStyleForProp(
        props.isPrimary ? 'buttonBackground' : 'buttonBackgroundSecondary'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
    margin: 0 auto;
  }
`
const LabelTitle = styled.p`
  font-weight: bold;
  text-align: center;
  margin-bottom: 0;
`
const LabelParagraph = styled.p`
  margin: 5px 0 0 0;
  text-align: center;
  font-weight: 400;
`

const OptionWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 20px 16px 48px;
  margin-top: 0;
  box-sizing: border-box;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    background: ${getStyleForProp('markIconColor')};
    border-radius: 4px;
  }
`

const Option = styled.p`
  font-weight: 400;
`

const PricingCardButtomContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export default {
  CardTitle,
  Heading,
  StyledContainer,
  WmCustomContainer,
  Label,
  Paragraph,
  LabelParagraph,
  PricingCardsContainer,
  PricingHeaderContainer,
  PriceCardWrapper,
  PricingCardContent,
  PricingCardButtomContainer,
  PriceCard,
  PricingCardHeader,
  ButtonWrapper,
  LabelTitle,
  Option,
  OptionWrapper,
  LabelContainer,
  PricingCardButtomContent,
  LabelBorder
}
