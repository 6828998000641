import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export const Loader = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  overflow: unset;
  z-index: 10000;
  top: 0;
  left: 0;
  background-color: rgba(37, 46, 72, 0.5);
  backdrop-filter: blur(11px);

  display: flex;
  align-items: center;
  justify-content: center;
`

export const Percentage = styled.span`
  font-size: 20px;
  color: #f3f3f3;
  z-index: 3;
  text-align: center;
  font-weight: 500;
`

export const LoaderSpinner = styled.div`
  transform: translate(0, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;

  width: 100%;
  height: 100%;

  .first-circle {
    position: absolute;
    z-index: 2;
    animation: spin 16s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const LoaderText = styled.div`
  font-size: 16px;
  color: #f3f3f3;
  text-align: center;
  margin-top: 40px;
  font-weight: 500;
  white-space: nowrap;
`

export const TextImageContent = styled.div`
  width: 250px;
  height: 250px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
  }
  .waveImg {
    width: 100%;
    height: 100%;
  }
`

export const ImageWrapper = styled.div`
  position: absolute;
  top: 50%;
`

export const AnimationContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
