/* @flow */
import React, { useContext, memo } from 'react'
import S from './Portfolio8.styled'
import { validation } from './Portfolio8.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import Image from '@website/common/components/Image'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Portfolio8 = memo(props => {
  const {
    data: { gallery }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      {gallery.map((galleryRow, idx) => {
        return (
          <S.RowWrapper>
            <S.Row key={idx}>
              {galleryRow.map((galleryItem: string, itemIdx: number) => {
                return (
                  <S.Item isEditing={isEditing} key={itemIdx}>
                    <S.ControlContainer>
                      <ControlsWithImageCropper
                        actions={Actions.imageActions(
                          idx,
                          gallery.length,
                          itemIdx,
                          galleryItem.backgroundImg
                        )}
                        key={itemIdx}
                        style={{ width: '100%' }}
                      >
                        <Image
                          as={S.Img}
                          sizes={galleryItem.backgroundImgDimensions}
                          defaultImgSrc={galleryItem.backgroundImg}
                          asProps={{
                            imgCropParams: galleryItem.imgCropParams
                          }}
                          alt="Portfolio image"
                        />
                      </ControlsWithImageCropper>
                    </S.ControlContainer>
                  </S.Item>
                )
              })}
            </S.Row>
            <If
              condition={isEditing}
              then={() => (
                <S.AddButtonWrapper>
                  <AddButton
                    onAdd={() => {
                      Actions.addRow(idx)
                    }}
                    toShow={
                      Object.keys(gallery).length < validation.gallery.max
                    }
                    style={{
                      margin: '0 0.5rem'
                    }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_row_label')}
                  </AddButton>
                  <AddButton
                    onAdd={() => Actions.removeRow(idx)}
                    toShow={
                      Object.keys(gallery).length > validation.gallery.min
                    }
                    style={{
                      margin: '0 0.5rem'
                    }}
                  >
                    <Icon name="glyph-remove" className="icon" size="normal" />
                    {translate('remove_row_label')}
                  </AddButton>
                </S.AddButtonWrapper>
              )}
            />
          </S.RowWrapper>
        )
      })}
    </S.StyledContainer>
  )
})

export default Portfolio8
