/* @flow */
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeSubTitle,
  changeTitleAlignment,
  changeParagraphAlignment,
  removeBlock
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

const keysForSizes = ['feature38Image1', 'feature38Image2']

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const changeParagraph1 = (newParagraph1: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH1',
      value: newParagraph1
    })
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeParagraph1Alignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH1_ALIGNMENT',
      value: alignment
    })
  }

  const _changeSubTitle = newSubTitle => {
    dispatcher(changeSubTitle(newSubTitle))
  }

  const changeSubtitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const addBlock = imagesCount => {
    dispatcher({
      type: 'ADD_BLOCK',
      value: imagesCount
    })
  }

  const imageActions = (idx: number, imageUrl: string, imageCount: number) => {
    const keyForSizes = idx === null ? keysForSizes[0] : keysForSizes[1]
    return [
      ...cropableImageModalActionMaker(dispatcher)({
        keyForSizes,
        showRemove: false,
        additionalArgs: { idx },
        imageUrl
      }),
      {
        toShow: imageCount === 2,
        handler: () => dispatcher(removeBlock(idx)),
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label')
      }
    ]
  }

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeSubTitle: _changeSubTitle,
    changeParagraph: _changeParagraph,
    changeSubtitleAlignment,
    changeParagraph1,
    changeParagraph1Alignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addBlock,
    removeBlock,
    imageActions,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
