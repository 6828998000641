import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.primaryLight,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.primaryLight,
    badgeBg: p => p.primaryLight
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.primaryDark,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.primaryDark,
    badgeBg: p => p.primaryDark
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.primaryDark,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.primaryDark,
    badgeBg: p => p.primaryDark
  }
}
