/* @flow */
import styled from 'styled-components'
import THEME from './Header4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0 0 160px 0;
  position: relative;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: 60px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: ${({ topOffset }) => `${topOffset + 160}px`};
  @media only screen and (min-width: 1025px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 1024px) {
    padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      padding-top: 0;
    }
  }
`

const SubscribtionFormWrap = styled.div`
  width: 100%;
  max-width: 700px;
  position: relative;
  transform: translateX(-21px);
  padding-top: 30px;
  form {
    display: flex;
    justify-content: center;
    input {
      flex: none;
    }
    & > .control-container {
      @media only screen and (max-width: 650px) {
        width: auto;
        margin-top: 30px;
      }
      & > button {
        margin-top: 0;
      }
    }
  }
  .wm-email-input-wrapper {
    max-width: 340px;
  }
  .wm-email-input {
    width: 100%;
    max-width: 340px;
    height: 50px;
  }
  @media only screen and (max-width: 650px) {
    transform: unset;
    form {
      flex-direction: column;
      align-items: center;
      max-width: 400px;
      margin: 0 auto;
    }
    .wm-email-input {
      max-width: unset;
    }
  }
  .success-message {
    @media only screen and (max-width: 650px) {
      bottom: 56px;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
`

const Anchor = styled.a`
  border-radius: ${getStyleForProp('buttonRadius')};
  ${props =>
    getStyleForProp(
      props.isOdd ? 'buttonBackgroundTertiary' : 'buttonBackground'
    )(props)};
  background-repeat: no-repeat;
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  SubscribtionFormWrap,
  Title,
  Description,
  Anchor
}
