/* @flow */
/* eslint-disable max-statements */

import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        break
      case 'REMOVE_SLIDE': {
        draft.slides.splice(action.value, 1)
        break
      }
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_CONTENT_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.slides[idx].slideContentAlignment = alignment
        break
      }
      case 'CHANGE_SLIDE_CONTENT':
        const { newContent, idx: _idx } = action.value
        draft.slides[_idx].slideContent = newContent
        break
      case 'CHANGE_SLIDE_TOOLTIP_TEXT': {
        const { newTooltipText, idx } = action.value
        draft.slides[idx].slideTooltipText = newTooltipText
        break
      }
      case 'CHANGE_SLIDE_TITLE': {
        const { newSlideTitle, idx } = action.value
        draft.slides[idx].slideTitle = newSlideTitle
        break
      }
      case 'CHANGE_TOOLTIP_ALIGNMENT':
        const { alignment, idx } = action.value
        draft.slides[idx].slideTooltipAlignment = alignment
        break

      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.slides[idx].imageUrl = url
        draft.slides[idx].imgDimensions = dimensions || {}
        draft.slides[idx].imgCropParams = {}
        break
      }
      case 'ADD_IMAGE_BLOCK': {
        const { idx } = action.value
        draft.slides[idx].imageUrl = initialState.slides[0].imageUrl
        draft.slides[idx].imgDimensions = initialState.slides[0].imgDimensions
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE': {
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      }
      case 'CHANGE_BACKGROUND_IMAGE': {
        const { url: _url, dimensions: _dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = _dimensions
        break
      }
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY': {
        draft.bgOverlayTransparency = action.value
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
      }
    }
  })
}
