/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Steps5.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-bottom: 70px;

  @media only screen and (max-width: 457px) {
    padding-bottom: 50px;
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1100px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`
const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    padding-top: 0;
    margin-bottom: 0;
  }
  .control-container {
    width: 100%;
    margin-bottom: 30px;
    h2 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`

const StepsWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: -14px auto;
  padding-top: 50px;
  @media only screen and (max-width: 1100px) {
    padding-top: 40px;
    flex-direction: column;
    align-items: center;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  padding-top: 30px;
`

const StepItemWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('borderRadius')};
  border: ${getStyleForProp('border')};
  width: 30%;
  max-width: 410px;
  margin: 25px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media only screen and (max-width: 1440px) {
    margin: 14px;
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    max-width: 500px;
    margin: 22px 0;
  }

  .lazy-figure {
    border-top-left-radius: ${getStyleForProp('borderRadius')};
    border-top-right-radius: ${getStyleForProp('borderRadius')};
  }
`

const FigureContainer = styled.div`
  position: relative;
  padding-top: 56.25%; // 16:9 aspect ratio
  width: 100%;
  margin: 0 auto;
`

const ItemImgWrap = styled.div`
  font-size: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
`

const StepImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`

const StepItemContent = styled.article`
  border-bottom-left-radius: ${getStyleForProp('borderRadius')};
  border-bottom-right-radius: ${getStyleForProp('borderRadius')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  word-break: break-word;
  padding: 30px;
  background-color: #fff;
  flex-grow: 1;
  @media only screen and (max-width: 1440px) and (min-width: 1100px) {
    padding: 30px 14px;
  }
  @media only screen and (max-width: 400px) {
    padding: 30px 14px;
  }
`

const StepTitle = styled.h2`
  color: ${getStyleForProp('stepTitle')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 22px;
`

const StepDescription = styled.p`
  color: ${getStyleForProp('stepDescription')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const NextIcon = styled.div`
  align-self: center;
  i {
    font-size: 34px;
    font-weight: bold;
  }
  &.hide {
    display: none;
  }
  @media only screen and (max-width: 1100px) {
    transform: rotate(90deg);
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('arrow')
}))``

export default {
  StyledContainer,
  WmCustomContainer,
  StepsWrap,
  ItemImgWrap,
  FigureContainer,
  Title,
  Description,
  StepItemWrap,
  StepTitle,
  StepImg,
  StepItemContent,
  StepDescription,
  NextIcon,
  Icon
}
