import React from 'react'

import * as Styled from './styled'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { LinkComp } from '@editor/common/styled-components/simple-tags'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import { getLocalizedURL } from '@editor/common/utils/translations'

const SubscriptionLink = () => (
  <Styled.SubscriptionLinkWrapper>
    {transformTextVariables(translate('want_more_check_other_label'), [
      {
        value: (
          <>
            {translate('sub_plans_label')}
            <Icon name="new_tab" size="small" color="primaryDark" />
          </>
        ),
        Comp: LinkComp,
        props: {
          href: `${getLocalizedURL('/subscription')}`,
          target: '_blank'
        }
      }
    ])}
  </Styled.SubscriptionLinkWrapper>
)

export default SubscriptionLink
