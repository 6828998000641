import Iphone from '@editor/common/assets/iphoneXframe.png'
import Tablet from '@editor/common/assets/ipadFrame.png'

export const TABLET_DEVICE_SIZE = 768

export const DEVICES = {
  desktop: {
    iconName: 'desktop',
    mockupSrc: ''
  },
  tablet: {
    iconName: 'tablet',
    mockupSrc: Tablet
  },
  mobile: {
    iconName: 'mobile',
    mockupSrc: Iphone
  }
}
