/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature31.styled'
import { validation } from './Feature31.consts'
import ActionMaker from './Actions'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Buttons from '@website/common/components/Buttons'
import Image from '@website/common/components/Image'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Feature31 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      titleTextGradient,
      paragraphAlignment,
      buttons,
      blocks,
      images
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionMaker(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <S.ImageContentWrapper>
          <Mapper
            data={images}
            render={(image: string, idx: number) => (
              <S.ImageWrapper
                className={'image-wrapper-' + (idx + 1)}
                key={idx}
              >
                <S.ControlContainer className={'image-' + (idx + 1)}>
                  <ControlsWithImageCropper
                    actions={Actions.imageActions(idx, image.imgUrl)}
                    style={{ width: '100%' }}
                    key={idx}
                  >
                    <Image
                      as={S.Img}
                      alt="Feature image"
                      sizes={image.imgDimensions}
                      defaultImgSrc={image.imgUrl}
                      asProps={{
                        imgCropParams: image.imgCropParams
                      }}
                    />
                  </ControlsWithImageCropper>
                </S.ControlContainer>
              </S.ImageWrapper>
            )}
          />
        </S.ImageContentWrapper>
        <S.TextContentWrapper>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            required={!paragraph}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={(newTitle: string) => Actions.changeTitle(newTitle)}
            changeAlignment={Actions.changeTitleAlignment}
            changeTextGradientStatus={Actions.changeTitleGradientStatus}
            isTextGradient={titleTextGradient}
            isGradientControlHidden={false}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Paragraph}
            required={!title}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={(newParagraph: string) =>
              Actions.changeParagraph(newParagraph)
            }
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
          <S.BlocksWrapper>
            <Mapper
              data={blocks}
              render={(block: string, idx: number) => (
                <Show when={[block.title, block.paragraph]}>
                  <S.Block key={idx} areThreeBlock={blocks.length === 3}>
                    <ControlsWithModalOpener
                      actions={Actions.blockActions({
                        idx,
                        showRemove: blocks.length > validation.blocks.min
                      })}
                      style={{ height: '100%', width: '100%' }}
                    >
                      <S.BlockItemWrap>
                        <S.IconContainer isEditing={isEditing}>
                          <ControlsWithModalOpener
                            actions={Actions.getIconActions({
                              idx,
                              showRemove: false
                            })}
                            style={{ height: 'auto' }}
                          >
                            <S.IconWrap isEditing={isEditing}>
                              <Icon name={block.iconName} />
                            </S.IconWrap>
                          </ControlsWithModalOpener>
                        </S.IconContainer>
                        <S.BlockWrap>
                          <EditableContent
                            as={S.BlockTitle}
                            text={block.title}
                            isEditing={isEditing}
                            required={!block.paragraph}
                            maxCount={validation.blockTitleMaxChar}
                            className="WM_GLOBAL_heading20"
                            onChange={(newBlockTitle: string) =>
                              Actions.changeBlockTitle(newBlockTitle, idx)
                            }
                          />
                          <EditableContent
                            as={S.BlockParagraph}
                            text={block.paragraph}
                            isEditing={isEditing}
                            required={!block.title}
                            maxCount={validation.blockParagraphMaxChar}
                            className="WM_GLOBAL_paragraph18"
                            onChange={(newBlockParagraph: string) =>
                              Actions.changeBlockParagraph(
                                newBlockParagraph,
                                idx
                              )
                            }
                          />
                        </S.BlockWrap>
                      </S.BlockItemWrap>
                    </ControlsWithModalOpener>
                  </S.Block>
                </Show>
              )}
            />
          </S.BlocksWrapper>
          <AddButton
            onAdd={Actions.addItem}
            toShow={blocks.length < validation.blocks.max}
            style={{
              marginTop: '2rem'
            }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.TextContentWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature31
