/* @flow */
import styled from 'styled-components'
import THEME from './theme'
import { styleProvider } from '@website/common/utils'
import Icon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

export const VideoPopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2147483649;
  background: ${getStyleForProp('bg')};
`

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 20px;
  z-index: 1;
  cursor: pointer;
`

export const VideoElem = styled.div`
  width: 95%;
  & > div {
    height: auto !important;
    width: 80% !important;
    max-width: 966px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    @media only screen and (max-width: 500px) {
      width: 95% !important;
    }
  }
  & > div > div {
    width: 100% !important;
    position: relative;
    height: 0 !important;
    padding-top: 56.25%;
  }
  iframe {
    border-radius: ${getStyleForProp('cardRadius')};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`
