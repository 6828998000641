/* @flow */

import produce from 'immer'
import { validation, DEFAULT_LINK_DATA } from './PayPal4.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_SUP_TITLE':
        draft.supTitle = action.value
        break
      case 'CHANGE_SUP_TITLE_ALIGNMENT': {
        draft.supTitleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TEXT': {
        const { blockIdx, text } = action.value
        draft.blocks[blockIdx].title = text
        break
      }
      case 'CHANGE_BLOCK_NAME':
        const { blockIdx, name } = action.value
        draft.blocks[blockIdx].name = name
        break

      case 'CHANGE_BLOCK_DESCRIPTION': {
        const { blockIdx, description } = action.value
        draft.blocks[blockIdx].description = description
        break
      }

      case 'CHANGE_BLOCK_NAME_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.blocks[blockIdx].nameAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_DESCRIPTION_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.blocks[blockIdx].descriptionAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { blockIdx, alignment } = action.value
        draft.blocks[blockIdx].titleAlignment = alignment
        break
      }

      case 'ADD_LINK': {
        const { idx } = action.value
        draft.blocks[idx].additionalUrl = DEFAULT_LINK_DATA.url
        draft.blocks[idx].additionalText = DEFAULT_LINK_DATA.text
        break
      }
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.blocks[idx].additionalUrl = ''
        draft.blocks[idx].additionalText = ''
        break
      }
      case 'CHANGE_LINK_DATA': {
        const { linkIdx, linkData } = action.value
        console.log('linkData', linkData)
        draft.blocks[linkIdx].additionalUrl = linkData.link
        draft.blocks[linkIdx].additionalText = linkData.text
        break
      }
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'REMOVE_SUBSCRIBTION_CARD': {
        draft.blocks.splice(action.value, 1)
        break
      }
      case 'CHANGE_ICON':
        const { idx: idx_, iconName } = action.value
        draft.blocks[idx_].iconName = iconName
        break
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.blocks[idx].iconName = ''
        break
      }
      case 'ADD_BLOCK_LIST_ITEM':
        const { idx } = action.value
        draft.blocks[idx].list.push(initialState.blocks[0].list[0])
        break
      case 'REMOVE_BLOCK': {
        if (state.blocks.length === validation.blocks.min) {
          return
        }
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'REMOVE_BLOCK_LIST_ITEM': {
        const { blockIdx, listIdx } = action.value
        draft.blocks[blockIdx].list.splice(listIdx, 1)
        break
      }

      case 'CHANGE_BLOCK_LIST_ITEM': {
        const { newBlockListTitle, blockIdx, listIdx } = action.value
        if (!newBlockListTitle) {
          draft.blocks[blockIdx].list.splice(listIdx, 1)
        } else {
          draft.blocks[blockIdx].list[listIdx] = newBlockListTitle
        }
        break
      }

      case 'ADD_ICON':
        draft.blocks[action.value].iconName = 'edit'
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_PRIMARY_PLAN':
        draft.primaryPlanIdx = action.value
        break
      case 'CHANGE_PAYPAL_BUTTON_DATA': {
        const { btnData, idx } = action.value
        draft.blocks[idx].paypalBtnProps = btnData
        break
      }
    }
  })
}
