export default {
  DARK: {
    shadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    placeholder: p => p.tertiaryNormal,
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    backgroundColor: p => p.tertiaryLight,
    placeholder: p => p.tertiaryNormal,
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    placeholder: p => p.tertiaryNormal,
  }
}
