/* @flow */
import styled from 'styled-components'

const getPadding = props => {
  const { wide, isBig, isText, isIcon } = props
  if (wide) {
    return '0px 40px'
  }
  if (isBig) {
    return '24px 86px'
  }
  if (isText) {
    return '15px'
  }
  if (isIcon) {
    return '4px 15px'
  }
  return '8px'
}

const getRadius = ({ rounded }) => (rounded ? '50px' : '5px')

const fontSize = ({ large, medium }) =>
  large ? '32px' : medium ? '16px' : '14px'

const AddButtonWrap = styled.div`
  text-align: center;
  font-family: 'Montserrat', sans-serif;
`

const Div = styled.div`
  position: relative;
  padding: ${getPadding};
  color: #545f7e;
  font-weight: 400;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 0%, #f5fafe 100%) 0%
    0% no-repeat border-box;
  border: 1px dashed #7683a8;
  box-shadow: 0px 3px 9px rgba(49, 119, 248, 0.101);
  border-radius: ${getRadius};
  cursor: pointer;
  display: inline-flex;
  font-size: ${fontSize};
  user-select: none;
  .icon {
    margin-right: 10px;
    transform: translateY(1px);
    svg {
      fill: #545f7e;
    }
  }
  &:hover {
    background: #ffffff;
  }
`

export default { Div, AddButtonWrap }
