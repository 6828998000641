/* @flow  */

const sliderSettings = {
  autoplay: true,
  autoplaySpeed: 2000,
  infinite: true,
  slidesToScroll: 1,
  touchMove: false,
  className: 'slider variable-width',
  variableWidth: true
}

const mobileSliderSettings = {
  dots: false,
  autoplay: true,
  autoplaySpeed: 2000,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1
}

export default {
  mobileSliderSettings,
  sliderSettings
}
