// @flow
import React, { memo, useCallback } from 'react'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import * as Styled from './styled'
import type { TDeletePopupProps } from './types'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

const DeletePopup = ({
  isOpen,
  pagePath,
  handleDelete,
  onClose
}: TDeletePopupProps) => {
  const isHome = pagePath === '/home'
  const originalPath = isHome ? '/' : pagePath
  const pageName = pagePath.substr(1)

  const onDeleteClick = useCallback(() => {
    handleDelete(originalPath)
    onClose()
  }, [originalPath, handleDelete, onClose])

  return (
    <Popup isOpen={isOpen} onClose={onClose} className="page-deleting-popup">
      <Styled.Content>
        <Styled.ConfirmQuestion>
          {transformTextVariables(translate('delete_page_from_pages_label'), [
            { value: pageName, Comp: BoldComp }
          ])}
        </Styled.ConfirmQuestion>
        <Styled.ButtonsWrapper>
          <Styled.CancelBtn onClick={onClose}>
            {translate('cancel_label')}
          </Styled.CancelBtn>
          <Styled.DeleteButton onClick={onDeleteClick}>
            {translate('delete_label', true)}
          </Styled.DeleteButton>
        </Styled.ButtonsWrapper>
      </Styled.Content>
    </Popup>
  )
}

export default memo(DeletePopup)
