/* @flow */
export const validation = {
  maxHeadingChar: 30,
  maxSubtitleChar: 60,
  maxParagraphChar: 100,
  maxInfoChar: 16,
  maxPriceChar: 10,
  maxBagdeChar: 10,
  slides: {
    slideTitle: { required: true },
    min: 1,
    max: 10,
    menu: {
      price: { required: true },
      min: 1,
      max: 6,
      bagde: {
        min: 0,
        max: 3
      }
    }
  }
}
