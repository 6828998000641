export const BUTTON_INITIAL_STATE = [
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]

export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 800,
  title: { required: true },
  buttons: {
    min: 0,
    max: 2
  }
}
