import styled, { css } from 'styled-components'

export const SiteMessengersWrapper = styled.div`
  position: fixed;
  z-index: 20;
  overflow: visible;
  ${({ alignment }) =>
    alignment === 'left'
      ? css`
          left: 10px;
        `
      : css`
          right: 10px;
        `};
  bottom: 10px;
  display: flex;
  flex-direction: column;
`

export const SiteMessengerItem = styled.div`
  margin: 10px;
`
