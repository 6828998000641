import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

const BORDER_STYLES = `1px solid ${secondary.ExtraLight}`

const getActiveStyles = ({ isOpen }) =>
  isOpen
    ? css`
        & > i:nth-child(1) {
          top: 21px;
          transform: rotateZ(45deg);
        }
        & > i:nth-child(2) {
          top: 21px;
          transform: rotateZ(-45deg);
        }
      `
    : ''

export const BurgerContainer = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  & > i {
    position: absolute;
    display: block;
    height: 2px;
    background: #363f5a;
    width: 24px;
    left: 8px;
    transition: all 0.2s;
  }
  & > i:nth-child(1) {
    top: 15px;
  }
  & > i:nth-child(2) {
    top: 25px;
  }
  ${getActiveStyles};
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  overflow-y: auto;
  padding-bottom: 55px;
  box-sizing: border-box;
`

export const TopLinksContent = styled.div`
  display: flex;
  border-bottom: ${BORDER_STYLES};
`

export const SignInButton = styled.div`
  padding: 20px 15px;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  &:hover {
    background-color: #eef5ff;
  }
  & > span {
    font-size: 14px;
    color: #387dff;
  }
`

export const PublishedDate = styled.div`
  display: flex;
  padding: 15px;
  justify-content: flex-start;
  border-bottom: ${BORDER_STYLES};
  & > span {
    font-size: 12px;
    font-weight: 500;
    color: ${secondary.SemiDark};
  }
`
