/* @flow */
import React, { useContext, memo } from 'react'
import { validation } from './Contact7.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { isValidEmail, isValidPhoneNumber } from '@website/common/utils'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import S from './Contact7.styled'
import { translate } from '@editor/common/utils/translations'

const Contact7 = memo(props => {
  const {
    data: { title, titleAlignment, blocks }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const renderContact = (
    text,
    idx,
    isEditing,
    key,
    alignmentKey,
    alignment
  ) => {
    if (isEditing || (!isValidEmail(text) && !isValidPhoneNumber(text))) {
      return (
        <EditableContent
          areControlsHidden
          as={S.BlockParagraph}
          text={text}
          required={blocks.length === 1}
          alignment={alignment}
          maxCount={validation.blockParagraphMaxChar}
          isEditing={isEditing}
          className="WM_GLOBAL_paragraph14"
          onChange={(newParagraph: string) => {
            Actions.changeBlockParagraph(newParagraph, idx, key)
          }}
          changeAlignment={alignment => {
            Actions.changeBlockParagraphAlignment(alignment, alignmentKey, idx)
          }}
        />
      )
    }
    const hrefPrefix = isValidEmail(text) ? 'mailto:' : 'tel:'
    return (
      <S.A
        alignment={alignment}
        href={`${hrefPrefix}${text}`}
        className="WM_GLOBAL_paragraph14"
      >
        {text}
      </S.A>
    )
  }

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WMCustomContainer>
        <EditableContent
          as={S.Title}
          text={title}
          alignment={titleAlignment}
          maxCount={validation.titleMaxChar}
          className="WM_GLOBAL_heading42"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <S.FeatureItemWrap areFourBlock={blocks.length === 4}>
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => (
              <Show when={[block.title, block.paragraph1, block.paragraph2]}>
                <S.ItemWrap key={idx} isMoreThanFour={blocks.length > 4}>
                  <ControlsWithModalOpener
                    actions={Actions.blockActions({
                      idx,
                      showRemove: blocks.length > validation.blocks.min
                    })}
                    style={{ width: '100%' }}
                  >
                    <S.IconWrapper>
                      <ControlsWithModalOpener
                        actions={Actions.iconActions(idx)}
                      >
                        <S.Circle>
                          <S.Icon size="xxlLarge" name={block.iconName} />
                        </S.Circle>
                      </ControlsWithModalOpener>
                    </S.IconWrapper>
                    <EditableContent
                      as={S.BlockTitle}
                      text={block.title}
                      required={blocks.length === 1}
                      alignment={block.blockTitleALignment}
                      maxCount={validation.blockTitleMaxChar}
                      className="WM_GLOBAL_heading20"
                      onChange={(newTitle: string) => {
                        Actions.changeBlockTitle(newTitle, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx)
                      }
                    />
                    {renderContact(
                      block.paragraph1,
                      idx,
                      isEditing,
                      'paragraph1',
                      'paragraph1Alignment',
                      block.paragraph1Alignment
                    )}
                    {renderContact(
                      block.paragraph2,
                      idx,
                      isEditing,
                      'paragraph2',
                      'paragraph2Alignment',
                      block.paragraph2Alignment
                    )}
                  </ControlsWithModalOpener>
                </S.ItemWrap>
              </Show>
            )}
          />
        </S.FeatureItemWrap>
      </S.WMCustomContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '20px'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Contact7
