import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    message: p => p.accentWhite
  },
  LIGHT: {
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    message: p => p.tertiaryNormal
  },
  EXTRALIGHT: {
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    message: p => p.tertiaryNormal
  }
}
