import styled from 'styled-components'

import { inputStyles } from '../styled'

export const NameInputWrapper = styled.div`
  position: relative;
`

export const NameInput = styled.input`
  ${inputStyles};
`

export const DescriptionInputWrapper = styled.div`
  position: relative;
  margin-top: 20px;
`

export const DescriptionInput = styled.textarea`
  ${inputStyles};
  vertical-align: top;
  resize: none;
  height: 120px;
`
