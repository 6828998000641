/* @flow */
import {
  linkActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addLink
} from '@website/components/CommonActions'

export default dispatch => {
  const _changeTitle = (title: string) => dispatch(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatch(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const _addLink = () => dispatch(addLink())

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addLink: _addLink,
    iconActions: iconActionMaker(dispatch),
    linkActions: linkActionMaker(dispatch)
  }
}
