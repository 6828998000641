/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Text12.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  ${({ isEditing }) => (isEditing ? 'margin-top: 50px' : null)};
  > div:first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (min-width: 1025px) {
      max-width: 900px;
    }
    h2 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1025px) {
    max-width: 900px;
  }
`

const BlocksContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 40px;
  max-width: ${props => (props.areFourBlock ? '1100px' : '1200px')};
  margin: 0px auto -30px auto;
  @media only screen and (max-width: 1350px) and (min-width: 1024px) {
    max-width: ${props => (props.areFourBlock ? '800px' : '1000px')};
  }
  @media only screen and (max-width: 890px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0;
    article:nth-child(1) {
      h2 {
        margin-top: 0;
      }
    }
  }
`

const Block = styled.div`
  width: 300px;
  position: relative;
  padding: 2px 40px;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: ${getStyleForProp('blockParagraph')};
    @media only screen and (max-width: 890px) {
      display: block !important;
      width: 100%;
      height: 1px;
      top: initial;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media only screen and (max-width: 1350px) and (min-width: 1024px) {
    padding: 2px 26px;
    width: 240px;
  }
  @media only screen and (max-width: 890px) {
    width: 100%;
    max-width: 420px;
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
    text-align: center;
  }
  @media only screen and (max-width: 600px) {
    max-width: 100%;
  }
`

const ControlContainer = styled.article`
  padding: 30px 0;
  @media only screen and (max-width: 890px) {
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    max-width: 420px;
  }
  &:only-child {
    .block {
      text-align: center;
      &::after {
        display: none;
      }
    }
  }
  @media only screen and (min-width: 1024px) {
    ${({ isBorder }) => {
      if (isBorder > 4) {
        return css`
          &:nth-child(1),
          &:nth-child(4) {
            .block::after {
              display: none;
            }
          }
        `
      }
      if (isBorder === 4) {
        return css`
          &:nth-child(1),
          &:nth-child(3) {
            .block::after {
              display: none;
            }
          }
        `
      }
      if (isBorder <= 3 && isBorder > 1) {
        return css`
          &:nth-child(1) {
            .block::after {
              display: none;
            }
          }
        `
      } else {
        return css`
          display: block;
        `
      }
    }}
  }
  @media only screen and (max-width: 1023px) {
    &:nth-child(odd) {
      .block::after {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 890px) {
    &:last-child {
      .block::after {
        display: none !important;
      }
      p {
        margin-bottom: 0px;
      }
    }
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
  @media only screen and (max-width: 890px) {
    margin-top: 40px;
  }
`

const BlockParagraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('blockParagraph')};
  ${getTextStyleByAlignment};
  margin-top: 15px;
  @media only screen and (max-width: 890px) {
    margin-bottom: 40px;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  BlocksContainer,
  ControlContainer,
  Block,
  BlockTitle,
  BlockParagraph
}
