/* eslint-disable max-statements */
/* @flow */
import {
  getSiteId,
  selectSubdomainValue,
  getUser,
  getMediaLibLimits,
  selectTemplatesData,
  selectTemplates,
  selectTemplateSearchTerm,
  selectTemplatesTotalCount,
  selectIsUserAuthorized,
  selectDomain,
  getShareLinkId,
  selectDomainsTotalCount,
  selectDomains,
  selectDomainsSearchQuery,
  getProjectFontPair,
  getLook,
  getPalette,
  hasComponents,
  selectWebsiteSubscriptionPlan
} from '@selectors'
import {
  getTariffPlanAPI,
  uploadFilesAPI,
  siteDataAPI,
  getMediaLimitsAPI,
  saveDomainAPI,
  saveSubdomainAPI,
  domainRemoveAPI,
  getDomainConfiguredStatusAPI,
  configureHttpsAPI,
  removeHttpsConfigsAPI,
  uploadFilesAsyncAPI,
  configureDomainAPI,
  getShareLinkAPI,
  createShareableLinkAPI,
  deleteShareLinkAPI,
  getCountriesAPI,
  getDomainsAPI,
  getUserAPI,
  setSiteOnlineStatusAPI,
  getSubscriptionsAPI,
  getPossibleLanguagesDataAPI,
  getTimeZonesDataAPI,
  getCSRFTokenAPI,
  upgradeTariffPlanAPI,
  getUpgradePreview
} from '@api'

import {
  undoable,
  getFileType,
  isOpenedWithHash,
  postMaxSizeMessage,
  mediaLibLimitMessage
} from '@editor/common/utils'
import {
  fetchTemplateById_API,
  getPresetCategories,
  fetchTemplates_API
} from '@api/template'
import { translate } from '@editor/common/utils/translations'

import { EMPTY_SITE_DATA, EMPTY_PROJECT_DATA, UPLOAD_PATH } from '../../consts'
import { alternatePalettes } from '@editor/conf/consts'
import { fetchUserData } from '@actions/editor/user'
import { syncProject } from '@actions/project/project-sync'
import { ApiError } from '@shared/ApiFactory'
import { setComponentsData } from '@actions'

const DOMAINS_LIMIT_PER_PAGE = 10

export const setSiteData = siteData => dispatch => {
  dispatch({ type: 'SET_SITE_DATA', value: siteData })
}

export const setSiteId = (id: string) => dispatch => {
  dispatch({ type: 'SET_SITE_ID', value: id })
}

export const triggerApiError = err => dispatch => {
  dispatch({
    type: 'API_ERROR',
    value: err
  })
}

export const setGlobalErrorMessage = message => ({
  type: 'SET_GLOBAL_ERROR_MESSAGE',
  value: message
})

export const setUserPaidData = value => ({
  type: 'SET_USER_PAID_DATA',
  value
})

export const setSubdomain = (subdomain: string) => ({
  type: 'SET_SUBDOMAIN',
  value: subdomain
})

export const checkLoginStatus = () => (dispatch, getState) => {
  const { closeRFAuthModal } = window
  const siteId = getSiteId(getState())

  getUserAPI().then(({ data }) => {
    closeRFAuthModal && closeRFAuthModal()
    if (siteId === 'new') {
      dispatch(syncProject())
    } else {
      dispatch({ type: 'GET_USER_SUCCESS', value: data })
    }
  })
}

export const googleOneTapLoginCb = () => (dispatch, getState) => {
  const _hasComponents = hasComponents(getState())
  if (!_hasComponents) {
    dispatch(fetchUserData())
    return
  }
  dispatch(syncProject())
}

export const openLoginPopup = () => (dispatch, getState) => {
  const { openRFAuthModal, closeRFAuthModal, removeGoogleOneTapLogin } = window
  if (
    isOpenedWithHash() ||
    typeof openRFAuthModal === 'undefined' ||
    typeof closeRFAuthModal === 'undefined'
  ) {
    return
  }

  const siteId = getSiteId(getState())
  const preventCloseOutsideClick = siteId !== 'new'

  const loginCallback = () => {
    if (!!siteId && siteId !== 'new') {
      dispatch(fetchUserData())
      return
    }
    closeRFAuthModal()
    dispatch(syncProject())
  }
  openRFAuthModal({ preventCloseOutsideClick }, loginCallback)
  removeGoogleOneTapLogin && removeGoogleOneTapLogin()
}
export const openDomainPage = () => ({ type: 'OPEN_DOMAIN_PAGE' })
export const closeDomainPage = () => ({ type: 'CLOSE_DOMAIN_PAGE' })

export const openLinkSharePopup = () => ({
  type: 'OPEN_SHARE_LINK_POPUP'
})

export const closeLinkSharePopup = () => ({ type: 'CLOSE_SHARE_LINK_POPUP' })

export const openGdprSettingsCustomizationWindow = () => ({
  type: 'OPEN_GDPR_SETTINGS_CUSTOMIZATION_WINDOW'
})

export const closeGdprSettingsCustomizationWindow = () => ({
  type: 'CLOSE_GDPR_SETTINGS_CUSTOMIZATION_WINDOW'
})

export const showMaintenanceModal = () => dispatch => {
  dispatch({ type: 'SHOW_MAINTENANCE_MODAL' })
}

export const closeMaintenanceModal = () => dispatch => {
  dispatch({ type: 'CLOSE_MAINTENANCE_MODAL' })
}

export const showWarningAction = (message: string) => ({
  type: 'SHOW_WARNING_MODAL',
  value: message
})

export const closeWarningModal = () => ({
  type: 'CLOSE_WARNING_MODAL'
})

export const setAddingCompIdx = (i: number) => (dispatch: Dispatch) => {
  dispatch({
    type: 'SET_ADDING_COMP_IDX',
    value: i
  })
}

const getTemplateCategoriesStarted = () => ({
  type: 'GET_TEMPLATE_CATEGORIES_STARTED'
})

const getTemplateCategoriesSuccess = data => ({
  type: 'GET_TEMPLATE_CATEGORIES_SUCCESS',
  value: data
})

export const setTemplatesActiveCategoryInfo = activeCategoryInfo => ({
  type: 'SET_TEMPLATES_ACTIVE_CATEGORY_INFO',
  value: activeCategoryInfo
})

export const setApplyTemplateId = (applyTemplateId: number) => ({
  type: 'SET_APPLY_TEMPLATE_ID',
  value: applyTemplateId
})

export const setTemplateSearchTerm = searchTerm => (dispatch: Dispatch) => {
  dispatch({
    type: 'SET_TEMPLATE_SEARCH_TERM',
    value: searchTerm
  })
}

// :::: Get template by Id actions ::::
const getTemplateByIdStarted = (templateId: number) => ({
  type: 'GET_TEMPLATE_BY_ID_STARTED',
  value: templateId
})

const getTemplateByIdSuccess = (templateId: number, data) => ({
  type: 'GET_TEMPLATE_BY_ID_SUCCESS',
  value: { templateId, data }
})

export const setTemplatesPresetCategories =
  (categories: Array<number>) => (dispatch: Dispatch) => {
    dispatch({
      type: 'SET_PRESET_CATEGORIES',
      value: categories
    })
  }

export const getTemplateById =
  (templateId: number) => (dispatch: Dispatch, getState: Function) => {
    const templateData = selectTemplatesData(getState())[templateId]
    if (templateData) {
      return
    }

    dispatch(getTemplateByIdStarted(templateId))

    fetchTemplateById_API(templateId)
      .then(({ data }) => {
        const {
          visualParams,
          componentMeta,
          categories = [1, 2],
          ...templateData
        } = data
        dispatch(setComponentsData(componentMeta))
        dispatch(setTemplatesPresetCategories(categories))
        dispatch(
          getTemplateByIdSuccess(templateId, {
            ...templateData,
            data: { ...templateData.data, visualParams }
          })
        )
      })
      .catch(err => {
        dispatch(triggerApiError(err))
      })
  }

export const getTemplatesSuccess =
  (templates: TTemplatePreset[], totalCount: number, shouldReset: boolean) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: 'GET_TEMPLATES_SUCCESS',
      value: { templates, totalCount, shouldReset }
    })
  }

export const getTemplatesStarted = () => (dispatch: Dispatch) => {
  dispatch({ type: 'GET_TEMPLATES_STARTED' })
}

export const resetTemplates = () => ({
  type: 'RESET_TEMPLATES'
})

export const fetchTemplates =
  (searchTerm?: string, offset?: number, categoryId?: number) =>
  (dispatch: Dispatch, getState: () => IEditorState) => {
    const state = getState()

    const shouldReset = offset === 0
    const { payload: templates, isLoading } = selectTemplates(state)
    const totalCount = shouldReset ? 0 : selectTemplatesTotalCount(state)
    const _searchTerm = searchTerm || selectTemplateSearchTerm(state)
    const _offset = shouldReset ? 0 : templates.length

    if (
      isLoading ||
      (selectTemplateSearchTerm(state) && !searchTerm && !templates.length) ||
      (totalCount !== 0 && templates.length === totalCount)
    )
      return

    const params = {
      order: 'trending',
      search: _searchTerm,
      offset: _offset,
      categoryId: categoryId
    }

    shouldReset && dispatch(resetTemplates())
    dispatch(getTemplatesStarted())

    fetchTemplates_API(params).then(res => {
      const { data, totalCount } = res

      dispatch(getTemplatesSuccess(data, totalCount, shouldReset))
    })
  }

export const fetchNewestTemplates = () => (dispatch: Dispatch) => {
  const params = {
    order: 'newest',
    limit: 9
  }

  fetchTemplates_API(params).then(res => {
    dispatch({
      type: 'FETCH_NEWEST_TEMPLATES',
      value: res.data
    })
  })
}

export const fetchTemplateCategories = () => (dispatch: Dispatch) => {
  dispatch(getTemplateCategoriesStarted())

  getPresetCategories()
    .then(({ data }) => {
      dispatch(getTemplateCategoriesSuccess(data))
    })
    .catch(err => console.log('ERRRR', err))
}

export const changePaletteColor = (colorKey: string, newColor: string) =>
  undoable({
    type: 'CHANGE_PALETTE',
    value: { colorKey, newColor }
  })

export const changeFontSize = (newSize: number) => ({
  type: 'CHANGE_FONT_SIZE',
  value: newSize
})

export const selectFontPair = (fontPair: TFontPair) => (dispatch, getState) => {
  const projectFontPair = getProjectFontPair(getState())

  if (fontPair.primary !== projectFontPair.primary) {
    dispatch(
      undoable({
        type: 'CHANGE_FONT_PAIR',
        value: fontPair
      })
    )
  }
}

export const changeLook = (newLook: string) => (dispatch, getState) => {
  const look = getLook(getState())

  if (look !== newLook) {
    dispatch(
      undoable({
        type: 'CHANGE_LOOK',
        value: newLook
      })
    )
  }
}

export const changeEntirePalette =
  (newPalette: string, undoable: boolean = true) =>
  (dispatch, getState) => {
    const palette = getPalette(getState())
    const _newPalette =
      typeof newPalette === 'string'
        ? alternatePalettes[newPalette]
        : newPalette

    if (JSON.stringify(palette) !== JSON.stringify(_newPalette)) {
      dispatch({
        type: 'CHANGE_ENTIRE_PALETTE',
        value: _newPalette,
        undoable
      })
    }
  }

export const setReplaceStatus = (
  status: boolean,
  addComponentIndex: number
) => ({
  type: 'SET_REPLACE_STATUS',
  value: { status, addComponentIndex }
})

export const startProjectPublish = () => ({ type: 'START_PROJECT_PUBLISH' })
export const projectPublishFailed = () => ({ type: 'PROJECT_PUBLISH_FAILED' })

// END OF Publishing actions

export const fetchMediaLibLimitsAction = () => {
  return (dispatch: Dispatch) =>
    getMediaLimitsAPI()
      .then(({ data }) => dispatch({ type: 'SET_MEDIA_LIMITS', value: data }))
      .catch(e => {
        console.log('err', e)
      })
}

// If user media lib limit has reached OR file size if bigger than allowed
export const checkUserLimitAndShowWarning =
  (size: number) => (dispatch: Dispatch, getState: () => IEditorState) => {
    const state = getState()
    const limits = getMediaLibLimits(state)
    if (!limits) {
      return true
    }
    const user = getUser(state)
    if (size > user.postMaxSize * 1024 * 1024) {
      dispatch(showWarningAction(postMaxSizeMessage(user)))
      return false
    }
    if (size > user.uploadMaxFileSize * 1024 * 1024) {
      dispatch(showWarningAction(uploadMaxSizeMessae(user)))
      return false
    }

    if (size > limits.limit.size - limits.usage.size) {
      dispatch(showWarningAction(mediaLibLimitMessage(user)))
      return false
    }
    return true
  }

export const uploadFiles =
  (file, onFileUploadEnd, onStart, onError) => (dispatch, getState) => {
    const state = getState()
    const isUserAuthorized = selectIsUserAuthorized(state)

    if (!isUserAuthorized) {
      onStart()
      return
    }

    const checkForUserLimits = checkUserLimitAndShowWarning(file.size)(
      dispatch,
      getState
    )
    const fileType = getFileType(file.type)
    if (!fileType) {
      onError && onError()
      return
    }
    if (!checkForUserLimits) {
      onError && onError()
      return
    }
    const uploadPath = `${UPLOAD_PATH}/user-media/`
    const fd = new FormData()
    fd.append('file', file, file.name)
    fd.append('folderId', '0')
    uploadFilesAPI(
      uploadPath,
      fd,
      (err?: TMessage, res) => {
        if (err) {
          onError && onError()
          return
        }
        if (res.data && res.data[fileType]) {
          onFileUploadEnd(res.data[fileType][0])
        }
      },
      onStart
    )
  }

export const setNoLongerPremiumPopupState = (value: boolean) => dispatch => {
  dispatch({ type: 'SET_NO_LONGER_PREMIUM_POPUP_STATE', value })
}

export const uploadFilesAsync = file => {
  const uploadPath = `${UPLOAD_PATH}/user-media/`
  const fd = new FormData()
  fd.append('file', file, file.name)
  fd.append('folderId', '0')
  return uploadFilesAsyncAPI(fd, uploadPath)
}

export const getSiteData = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())
  siteDataAPI(siteId)
    .then(({ data }) => {
      dispatch(setSiteData(data))
      if (data.paid) {
        dispatch(setNoLongerPremiumPopupState(false))
      }
    })
    .catch(err => {
      dispatch(triggerApiError(err))
    })
}

const setShareLinkData = data => ({
  type: 'SET_SHARED_LINK_DATA',
  value: {
    projectId: data.id,
    expireDate: data.expireDate,
    link: data.hash
  }
})

export const deleteShareLink = () => (dispatch, getState) => {
  const projectId = getShareLinkId(getState())
  if (projectId) {
    return deleteShareLinkAPI(projectId).then(() => {
      dispatch(setShareLinkData({ id: null, expireDate: '', link: '' }))
    })
  }
  return Promise.resolve(true)
}

export const createShareableLink =
  (date: string, cb: () => void) => (dispatch, getState) => {
    const siteId = getSiteId(getState())
    const body = {
      itemId: siteId,
      itemType: 'SITE',
      expireDate: date
    }
    createShareableLinkAPI(body)
      .then(({ data }) => {
        dispatch(setShareLinkData(data))
        cb()
      })
      .catch(() => cb())
  }

export const getShareLinkData = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())

  if (isOpenedWithHash()) {
    return
  }
  dispatch({
    type: 'SET_SHARED_LINK_STATUS',
    value: true
  })

  getShareLinkAPI(siteId)
    .then(({ data }) => dispatch(setShareLinkData(data)))
    .catch(e => {
      dispatch({
        type: 'SET_SHARED_LINK_STATUS',
        value: false
      })
      dispatch(triggerApiError(e))
    })
}

export const setEmptyProject = () => dispatch => {
  dispatch(setSiteData(EMPTY_SITE_DATA))
  dispatch({ type: 'SET_PROJECT_DATA', value: EMPTY_PROJECT_DATA })
  dispatch({ type: 'SET_PROJECT_LOADED' })
  dispatch({ type: 'FREEZE_PROJECT_DATA' })
}

export const setTariffPlansLoadingState = (value: boolean) => ({
  type: 'SET_TARIFF_PLANS_LOADING_STATE',
  value
})

const fetchUpgradePrices = (
  toPlanIds: number[],
  currentSubscriptionPlan: Object
) => {
  const { upgradeOptions, id: currentSubscriptionId } = currentSubscriptionPlan
  const suitableUpgradeOptions = upgradeOptions.filter(
    option => toPlanIds.indexOf(option.toPlanId) !== -1
  )

  const pricePromises = suitableUpgradeOptions.map(upgradeOption =>
    getUpgradePreview(currentSubscriptionId, upgradeOption.upgradeId)
  )

  return Promise.all(pricePromises).then(results =>
    results.map(({ data }) => ({
      ...data.immediatePayment,
      toPlanId: data.toPlan.id,
      toRecurrence: data.toRecurrence
    }))
  )
}

export const getTariffPlans =
  (toPlanIds: number[], isUpgrade: boolean) => async (dispatch, getState) => {
    const currentSubscriptionPlan = selectWebsiteSubscriptionPlan(getState())

    const websitePlanPromise = getTariffPlanAPI(24)
    const proPlanPromise = getTariffPlanAPI(28)
    const businessPlanPromise = getTariffPlanAPI(29)

    dispatch(setTariffPlansLoadingState(true))

    if (isUpgrade) {
      const prices = await fetchUpgradePrices(
        toPlanIds,
        currentSubscriptionPlan
      )
      dispatch({
        type: 'SET_UPGRADE_PRICES',
        value: prices
      })
    }

    return Promise.all([
      websitePlanPromise,
      proPlanPromise,
      businessPlanPromise
    ])
      .then(
        ([
          { data: websitePlanData },
          { data: proPlanData },
          { data: businessPlanData }
        ]) => {
          dispatch({
            type: 'SET_TARIFF_PLANS',
            value: {
              website: websitePlanData,
              pro: proPlanData,
              business: businessPlanData
            }
          })
        }
      )
      .catch(e => dispatch(triggerApiError(e)))
      .finally(() => {
        dispatch(setTariffPlansLoadingState(false))
      })
  }

const setSubscriptions = data => ({
  type: 'SET_SUBSCRIPTIONS_DATA',
  value: data
})

export const fetchSubscriptions = () => dispatch => {
  getSubscriptionsAPI()
    .then(({ data }) => dispatch(setSubscriptions(data)))
    .catch(e => dispatch(triggerApiError(e)))
}

const setDomainConfiguredStatus = (status: boolean) => dispatch => {
  dispatch({
    type: 'SET_DOMAIN_CONFIGURED_STATUS',
    value: status,
    undoable: false
  })
}

export const setOwnDomainErrorMessage = (errorMessage: string) => dispatch => {
  dispatch({
    type: 'SET_OWN_DOMAIN_ERROR_MESSAGE',
    value: errorMessage
  })
}

export const setSemrushLoginWindowState = (value: boolean) => ({
  type: 'SET_SEMRUSH_LOGIN_WINDOW_STATE',
  value
})

export const setSemrushSidebarPinnedState =
  (value: boolean) => (dispatch, getState) => {
    const siteId = getSiteId(getState())
    const key = `isSemrushSidebarPinned_${siteId}`

    window.localStorage.setItem(key, value)
    dispatch({
      type: 'SET_SEMRUSH_SIDEBAR_PINNED_STATE',
      value
    })
  }

export const setSemrushSidebarVisibilityState = (value: boolean) => ({
  type: 'SET_SEMRUSH_SIDEBAR_VISIBILITY_STATE',
  value
})

export const setEventCreationPopupVisibilityState = (value: boolean) => ({
  type: 'SET_EVENT_CREATION_POPUP_VISIBILITY_STATE',
  value
})

export const pinOrUnpinSemrushSidebar = (value: boolean) => dispatch => {
  dispatch(setSemrushSidebarPinnedState(value))
  !value && dispatch(setSemrushSidebarVisibilityState(false))
}

export const setSemrushKeywordPopupState = (value: boolean) => ({
  type: 'SET_SEMRUSH_KEYWORD_POPUP_STATE',
  value
})

export const openOrCloseSemrushKeywordsPopup = (value: boolean) => dispatch => {
  dispatch(setSemrushKeywordPopupState(value))
  value && dispatch(setSemrushSidebarVisibilityState(false))
}

export const setConfiguredMessage = (message: string) => dispatch => {
  dispatch({
    type: 'SET_CONFIGURED_MESSAGE',
    value: message
  })
}

export const setOwnDomainLoadingStatus = (status: boolean) => dispatch => {
  dispatch({
    type: 'SET_OWN_DOMAIN_LOADING_STATUS',
    value: status
  })
}

export const openCongratulationPopup = () => (dispatch: Function) => {
  dispatch({
    type: 'OPEN_CONGRATULATION_POPUP'
  })
}

export const closeCongratulationPopup = () => (dispatch: Function) =>
  dispatch({
    type: 'CLOSE_CONGRATULATION_POPUP'
  })

export const configureDomain = oldDomain => (dispatch, getState) => {
  const siteId = getSiteId(getState())
  return configureDomainAPI(oldDomain || null, siteId)
    .then(() => {
      dispatch(setDomainConfiguredStatus(true))
      dispatch(setOwnDomainLoadingStatus(false))
      dispatch(openCongratulationPopup())
      dispatch(setConfiguredMessage(''))
    })
    .catch(e => {
      dispatch(setDomainConfiguredStatus(false))
      dispatch(setOwnDomainLoadingStatus(false))
      dispatch(openCongratulationPopup())
      dispatch(setConfiguredMessage(e.message))
    })
}

const getDomainConfiguredStatusFinished = () => (dispatch: Function) => {
  dispatch({
    type: 'GET_DOMAIN_CONFIGURED_STATUS_FINISHED'
  })
}

export const getDomainConfiguredStatus = () => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const domain = selectDomain(state)

  if (domain && siteId) {
    dispatch({
      type: 'GET_DOMAIN_CONFIGURED_STATUS_STARTED'
    })

    return getDomainConfiguredStatusAPI(siteId, domain)
      .then(() => {
        dispatch(setDomainConfiguredStatus(true))
        dispatch(setConfiguredMessage(''))
      })
      .catch(e => {
        dispatch(setDomainConfiguredStatus(false))
        dispatch(setConfiguredMessage(e.message))
      })
      .finally(() => {
        dispatch(getDomainConfiguredStatusFinished())
      })
  }
}

const setDomainValue = (value: string) => ({
  type: 'SET_DOMAIN_VALUE',
  value
})

export const setWebsitePremiumFeatureState = (value: boolean) => dispatch => {
  dispatch({
    type: 'SET_HAS_WEBSITE_PREMIUM_FEATURE',
    value
  })
}

export const setCertifiedStatus = (status: boolean) => dispatch => {
  dispatch({
    type: 'SET_CERTIFIED_STATUS',
    value: status
  })
}

const fetchDomainsAction = (
  data: TDomains,
  totalCount: number,
  resetDomains: boolean
) => {
  return {
    type: 'FETCH_DOMAINS',
    value: {
      data,
      totalCount,
      resetDomains
    }
  }
}

export const fetchDomains =
  (searchText?: string) => (dispatch: Function, getState: () => void) => {
    const state = getState()
    const isSearching = searchText !== undefined
    const totalCount = isSearching ? null : selectDomainsTotalCount(getState())
    const offset = isSearching ? 0 : selectDomains(state).length
    const searchQuery = isSearching
      ? searchText
      : selectDomainsSearchQuery(state)

    if (totalCount !== 0 && offset === totalCount) return

    const params = {
      search: searchQuery,
      offset: !!offset && offset,
      limit: DOMAINS_LIMIT_PER_PAGE
    }

    dispatch({
      type: 'FETCH_DOMAINS_STARTED'
    })

    getDomainsAPI(params)
      .then(res => {
        dispatch(
          fetchDomainsAction(res.data.domains, res.data.count, isSearching)
        )
      })
      .catch(err => dispatch(triggerApiError(err)))
  }

export const connectDomain = (domain: string) => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const oldDomain = selectDomain(state)

  dispatch(setOwnDomainLoadingStatus(true))

  saveDomainAPI(domain, siteId)
    .then(({ data }) => {
      dispatch(setSiteData(data))
      dispatch(configureDomain(oldDomain))
      dispatch(setOwnDomainErrorMessage(''))
    })
    .catch(e => {
      dispatch(setOwnDomainErrorMessage(e.message))
      dispatch(setOwnDomainLoadingStatus(false))
    })
}

export const disconnectDomain = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())

  dispatch(setOwnDomainLoadingStatus(true))

  return domainRemoveAPI(siteId)
    .then(() => {
      dispatch(setDomainValue(''))
      dispatch(setDomainConfiguredStatus(false))
      dispatch(setCertifiedStatus(false))
      dispatch(setOwnDomainErrorMessage(''))
      dispatch(setConfiguredMessage(''))
      dispatch(fetchDomains(''))
      dispatch(fetchUserData())
      dispatch(setOwnDomainLoadingStatus(false))
    })
    .catch(e => {
      dispatch(setOwnDomainErrorMessage(e.message))
      dispatch(setOwnDomainLoadingStatus(false))
    })
}

export const setProjectSyncingStatus = status => dispatch => {
  dispatch({
    type: 'SET_PROJECT_SYNCING_STATUS',
    value: status
  })
}

export const setImageUploadInfo = (key: string, uploadInfo: Object) => ({
  type: 'SET_IMAGE_UPLOAD_INFO',
  value: { key, uploadInfo }
})

export const closeContributerSignoutPopup = () => ({
  type: 'CLOSE_CONTRIBUTOR_SIGNOUT_POPUP'
})

export const getCountriesList = () => dispatch =>
  getCountriesAPI()
    .then(res =>
      dispatch({
        type: 'SET_COUNTRIES_LIST',
        value: res.data
      })
    )
    .catch(err => dispatch(triggerApiError(err)))

export default {
  changePaletteColor,
  setAddingCompIdx
}

export const setSearchQuery = (searchQuery: string) => (dispatch: Function) => {
  dispatch({
    type: 'SET_SEARCH_QUERY',
    value: searchQuery
  })
}

export const setSubdomainSavingStatus = (status: boolean) => dispatch => {
  dispatch({
    type: 'SET_SUBDOMAIN_SAVING_STATUS',
    value: status
  })
}

export const setSubdomainErrorMessage = (errorMessage: string) => dispatch => {
  dispatch({
    type: 'SET_SUBDOMAIN_ERROR_MESSAGE',
    value: errorMessage
  })
}

export const saveSubdomain = subdomain => (dispatch, getState) => {
  const subdomainValue = selectSubdomainValue(getState())
  if (subdomainValue === subdomain) {
    return Promise.resolve(subdomain)
  }
  const siteId = getSiteId(getState())
  dispatch(setSubdomainSavingStatus(true))

  return saveSubdomainAPI(subdomain, siteId)
    .then(({ data }) => {
      dispatch(setSiteData(data))
      dispatch(setSubdomainSavingStatus(false))
      return Promise.resolve(subdomain)
    })
    .catch(e => {
      dispatch(setSubdomainErrorMessage(e.message))
      dispatch(setSubdomainSavingStatus(false))
      return Promise.reject(e)
    })
}

export const setSSLSuccessMessage = (successMessage: string) => dispatch => {
  dispatch({
    type: 'SET_SSL_SUCCESS_MESSAGE',
    value: successMessage
  })
}

export const setSSLErrorMessage = (errorMessage: string) => dispatch => {
  dispatch({
    type: 'SET_SSL_ERROR_MESSAGE',
    value: errorMessage
  })
}

export const configureHttps = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())
  dispatch(setOwnDomainLoadingStatus(true))

  return configureHttpsAPI(siteId)
    .then(() => {
      dispatch(setCertifiedStatus(true))
      dispatch(setSSLSuccessMessage(translate('successfully_enabled')))
      dispatch(setOwnDomainLoadingStatus(false))
      setTimeout(() => dispatch(setSSLSuccessMessage(null)), 5000)
    })
    .catch(e => {
      dispatch(setOwnDomainLoadingStatus(false))
      dispatch(setSSLErrorMessage(e.message))
      setTimeout(() => dispatch(setSSLErrorMessage(null)), 5000)
    })
}

export const removeHttpsConfigs = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())
  dispatch(setOwnDomainLoadingStatus(true))

  return removeHttpsConfigsAPI(siteId)
    .then(() => {
      dispatch(setOwnDomainLoadingStatus(false))
      dispatch(setCertifiedStatus(false))
      dispatch(getSiteData())
    })
    .catch(e => {
      dispatch(setOwnDomainLoadingStatus(false))
      dispatch(setSSLErrorMessage(e.message))
      setTimeout(() => dispatch(setSSLErrorMessage(null)), 5000)
    })
}

export const setSiteOnlineStatusUIState = state => ({
  type: 'SET_SITE_ONLINE_STATUS_UI_STATE',
  value: state
})

export const setSiteOnlineStatus = value => (dispatch, getState) => {
  const siteId = getSiteId(getState())
  dispatch(
    setSiteOnlineStatusUIState({
      isLoading: true
    })
  )
  setSiteOnlineStatusAPI(siteId, value)
    .then(({ data }) => {
      dispatch(
        setSiteOnlineStatusUIState({
          isLoading: false
        })
      )
      dispatch(setSiteData(data))
    })
    .catch(e => {
      dispatch(
        setSiteOnlineStatusUIState({
          isLoading: false,
          isFailed: true
        })
      )
      dispatch(setGlobalErrorMessage(e.message))
      setTimeout(() => {
        dispatch(
          setSiteOnlineStatusUIState({
            isFailed: false
          })
        )
      }, 1000)
    })
}

export const changeActiveSidebarItem = (value: string) => ({
  type: 'CHANGE_ACTIVE_SIDEBAR_ITEM',
  value
})

export const setMainSidebarExpandedState = (value: boolean) => ({
  type: 'SET_MAIN_SIDEBAR_EXPANDED_STATE',
  value
})

export const setSiteName = (value: string) => ({
  type: 'SET_SITE_NAME',
  value
})

export const setSettingsLoadingStatus = status => ({
  type: 'SET_SETTINGS_LOADING_STATUS',
  value: status
})

export const setSettingsSuccessMessage = message => ({
  type: 'SET_SETTINGS_SUCCESS_MESSAGE',
  value: message
})

export const setHasChangesAfterPublish = (hasChanges: boolean) => ({
  type: 'SET_HAS_CHANGES_AFTER_PUBLISH',
  value: hasChanges
})

export const setResetChangesPopupState = state => ({
  type: 'SET_RESET_CHANGES_POPUP_STATE',
  value: state
})

export const openPublishPopup = () => ({
  type: 'OPEN_PUBLISH_POPUP'
})

export const closePublishPopup = () => ({ type: 'CLOSE_PUBLISH_POPUP' })

export const openStatusChangeAndPublishPopup = () => ({
  type: 'OPEN_STATUS_CHANGE_AND_PUBLISH_POPUP'
})

export const closeStatusChangeAndPublishPopup = () => ({
  type: 'CLOSE_STATUS_CHANGE_AND_PUBLISH_POPUP'
})

const setPossibleLanguagesDataLoadingStatus = status => ({
  type: 'SET_POSSIBLE_LANGUAGES_DATA_LOADING_STATUS',
  value: status
})

export const fetchPossibleLanguagesData = () => dispatch => {
  dispatch(setPossibleLanguagesDataLoadingStatus(true))

  getPossibleLanguagesDataAPI()
    .then(({ data }) => {
      dispatch(setPossibleLanguagesDataLoadingStatus(false))
      dispatch({
        type: 'SET_POSSIBLE_LANGUAGES_DATA',
        value: data
      })
    })
    .catch(err => dispatch(triggerApiError(err)))
}

export const setWebsiteActiveLanguageCode = languageCode => ({
  type: 'SET_WEBSITE_ACTIVE_LANGUAGE_CODE',
  value: languageCode
})

export const setWebsiteLanguageChangingStatus = status => ({
  type: 'SET_WEBSITE_LANGUAGE_CHANGING_STATUS',
  value: status
})

export const fetchTimeZonesData = () => dispatch =>
  getTimeZonesDataAPI()
    .then(({ data }) => {
      dispatch({
        type: 'SET_TIMEZONES_DATA',
        value: data
      })
    })
    .catch(err => {
      dispatch(triggerApiError(err))
    })

export const fetchDataAfterSubscribe = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())
  dispatch(setTariffPlansLoadingState(true))

  const siteDataPromise = siteDataAPI(siteId)
  const subscriptionsPromise = getSubscriptionsAPI()

  Promise.all([siteDataPromise, subscriptionsPromise])
    .then(([{ data: siteData }, { data: subscriptionsData }]) => {
      dispatch(setSiteData(siteData))
      dispatch(setSubscriptions(subscriptionsData))
    })
    .catch(err => {
      dispatch(triggerApiError(err))
    })
    .finally(() => {
      dispatch(setTariffPlansLoadingState(false))
    })
}

export const upgradeSubscriptionPlan =
  (
    newTariffPlanId: number,
    isYearly: boolean,
    referrer: string,
    cb: Function
  ) =>
  async (dispatch, getState) => {
    const state = getState()
    const currentSubscriptionPlan = selectWebsiteSubscriptionPlan(state)
    if (!currentSubscriptionPlan) {
      return
    }
    const { upgradeOptions, id } = currentSubscriptionPlan
    const { upgradeId } = upgradeOptions.find(
      option =>
        option.toPlanId === newTariffPlanId &&
        (isYearly ? option.toRecurrence === '1 Year' : true)
    )

    try {
      dispatch(setTariffPlansLoadingState(true))
      const { token } = await getCSRFTokenAPI()
      await upgradeTariffPlanAPI(id, upgradeId, token, referrer)
      cb && cb()
    } catch (e) {
      dispatch(setTariffPlansLoadingState(false))
      if (e instanceof ApiError) {
        dispatch(triggerApiError(e))
      }
    }
  }

export const setLogoApplyingState = state => ({
  type: 'SET_LOGO_APPLYING_STATE',
  value: state
})

export const setInitialLogoApplyingState = state => ({
  type: 'SET_INITIAL_LOGO_APPLYING_STATE',
  value: state
})
