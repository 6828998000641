/* @flow */
import React, { useCallback, useEffect, useState } from 'react'
import './Cookie.styles.sass'
import S from './Cookie.styled'
import meta from './Cookie.meta'
import Text from '@website/common/components/Text'
import { cookieManager } from '@website/common/utils'
import If from '@website/common/components/Conditional'

const DEFAULT_MESSAGE =
  'This website uses cookies to give you the best experience.'
const DEFAULT_BUTTON_TEXT = 'OKAY'

const Cookie = ({ message, siteId, buttonText }) => {
  const [toShow, setStatus] = useState(false)

  useEffect(() => {
    const hasPrevStored = !!cookieManager.getCookie(`${siteId}`)
    setStatus(!hasPrevStored)
  }, [])

  const setCookie = useCallback((): void => {
    setStatus(false)
    cookieManager.setCookie(siteId, `website-cookie-${siteId}`)
  }, [siteId])

  return (
    <If
      condition={toShow}
      then={() => (
        <S.Container>
          <S.CookieWrap className="msg-btn-wrap">
            <S.Msg>{message || DEFAULT_MESSAGE}</S.Msg>
            <S.Button onClick={setCookie}>
              <Text>{buttonText || DEFAULT_BUTTON_TEXT}</Text>
            </S.Button>
          </S.CookieWrap>
        </S.Container>
      )}
    />
  )
}

Cookie.meta = meta

export default Cookie
