//@flow
import React, { useCallback } from 'react'

import * as Styled from './styled'
import WarningSvg from './info-warning.svg'
import LibButton from '@editor/common/lib/Button'
import { openUrl } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'

const WarningContent = () => {
  const handleLearnMoreBtnClick = useCallback(() => {
    openUrl('https://www.semrush.com/analytics/keywordmagic/start')
  }, [])

  return (
    <Styled.WarningContent className="warning-content">
      <img src={WarningSvg} alt="warning" />
      <h3>{translate('you_have_reached_your_daily_limit_label')}</h3>
      <LibButton type="tertiary" onClick={handleLearnMoreBtnClick}>
        {translate('learn_more_label')}
      </LibButton>
    </Styled.WarningContent>
  )
}

export default WarningContent
