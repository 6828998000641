import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    main: p => p.primaryLight,
    block: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    arrow: p => p.accentWhite,
    icon: p => p.primaryLight,
    tabItem: p => `rgba(${hexToRgb(p.accentWhite)}, 0.25)`,
    backgroundGradientHeader: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.8)`,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.primaryDark,
    main: p => p.primaryDark,
    block: p => p.accentWhite,
    background: p => p.tertiaryLight,
    arrow: p => p.tertiaryDark,
    icon: p => p.primaryDark,
    tabItem: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    backgroundGradientHeader: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.7)`,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    main: p => p.primaryDark,
    block: p => p.accentWhite,
    background: p => p.tertiaryExtraLight,
    arrow: p => p.tertiaryDark,
    icon: p => p.primaryDark,
    tabItem: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    backgroundGradientHeader: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.6)`,
    backgroundColor: p => p.tertiaryLight
  }
}
