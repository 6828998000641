/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Team1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }

  & > div:first-child {
    & > .control-container {
      display: block;
    }
  }
`

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .control-container {
    width: 100%;
  }
`

const MembersWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -30px auto;
  padding-top: 50px;
  & > .control-container {
    @media only screen and (max-width: 1100px) {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  @media only screen and (max-width: 1100px) {
    padding-top: 40px;
  }
`

const ImageWrap = styled.div`
  font-size: 0;
  width: 130px;
  height: 130px;
  & > .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const TeamItemWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  text-align: center;
  margin: 30px;
  flex-grow: 1;
  width: 100%;
  max-width: 400px;
  min-width: 400px;
  box-sizing: border-box;
  & .lazy-figure {
    border-radius: ${getStyleForProp('iconRadius')};
  }
  @media only screen and (max-width: 992px) {
    margin: 30px 0;
  }
  @media only screen and (max-width: 1100px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 700px) {
    margin: 30px 0;
    min-width: initial;
  }

  > .control-container {
    width: 100%;
    margin-top: 20px;
    h2,
    h3,
    p {
      margin: 0;
      padding: 0;
    }
  }
`

const TeamName = styled.h2`
  color: ${getStyleForProp('teamName')};
  margin-top: 30px;
  font-weight: 800;
  font-size: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const TeamPosition = styled.h3`
  color: ${getStyleForProp('teamPosition')};
  margin-top: 10px;
  font-weight: 300;
  font-size: 14px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const TeamParagraph = styled.p`
  color: ${getStyleForProp('teamParagraph')};
  margin-top: 20px;
  margin-bottom: 30px;
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  display: block;
  object-fit: cover;
  ${({ isUrl }) =>
    isUrl &&
    css`
      cursor: pointer;
    `}
`

const SocialWrap = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding: 16px 0 0 0;
  .link {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
    ${getStyleForProp('buttonBackground')};
    border-radius: ${getStyleForProp('iconRadius')};
  }
  @media only screen and (max-width: 650px) {
    .control-container:nth-child(5n),
    .control-container:nth-child(4),
    .control-container:nth-child(9) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
  }
  .rf-add-btn {
    padding: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: initial;
    }
  }
`
export default {
  StyledContainer,
  WmCustomContainer,
  MembersWrap,
  Title,
  Description,
  TeamItemWrap,
  TeamName,
  TeamPosition,
  TeamParagraph,
  SocialWrap,
  ImageWrap,
  Img,
  HeadingWrapper
}
