import styled, { css } from 'styled-components'

import { Button } from '../styled'
import { secondary } from '@website/common/styles/colors'

export const MultilingualBtn = styled(Button)`
  & > svg {
    fill: ${secondary.Dark};
  }

  ${({ isBgYellowOnHover }) =>
    isBgYellowOnHover
      ? css`
          &:hover {
            background: transparent
              linear-gradient(180deg, #ffd200 0%, #f7971e 100%);
            border: 2px solid #ffd200;

            & > svg {
              fill: white;
            }
          }
        `
      : ''}
`
