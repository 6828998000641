import styled from 'styled-components'
import { secondary, primary, accentRed } from '@website/common/styles/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const stylesObj = {
  jp: {
    textMaxWidth: '400px',
    margin: '0 auto 15px'
  },
  default: {
    textMaxWidth: 'auto'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const InfoPopupHeader = styled.div`
  margin: 0 auto;
  img {
    width: 160px;
    height: 130px;
  }
`
export const InfoPopupContent = styled.div`
  text-align: center;
  min-width: 460px;
  @media only screen and (max-width: 580px) {
    min-width: 270px;
  }
`
export const ContentHeader = styled.h2`
  margin-bottom: 15px;
  color: #353e5a;
  max-width: ${getStylesByLang('textMaxWidth')};
  margin: ${getStylesByLang('margin')};
`
export const ContentText = styled.p`
  font-size: 16px;
  width: 350px;
  display: inline-block;
  color: ${secondary.Dark};
  @media only screen and (max-width: 580px) {
    width: 270px;
  }
`

export const WishText = styled.p`
  margin: 20px;
  font-weight: 500;
  color: ${secondary.Dark};
`

export const Footer = styled.div`
  margin: 0 auto;
`
