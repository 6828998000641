/* @flow */
import React, { useState, memo } from 'react'
import ReactDom from 'react-dom'
import DayPicker from 'react-day-picker'
import { useBackdropClick } from '@hooks'
import useRelativeCoordinates from '@website/common/hooks/useRelativeCoordinates'
import { projectContainerClassName } from '@website/conf/consts'
import 'react-day-picker/lib/style.css'
import './index.sass'
import * as Styled from './styled'

const DatePicker = ({
  anchorEl,
  selectedDays,
  endDate,
  submitDate,
  closeDatePicker,
  backdropDisablingSelectors = []
}) => {
  const pickerRef = useBackdropClick(closeDatePicker, [
    '.datePicker',
    ...backdropDisablingSelectors
  ])
  const [date, setDate] = useState(null)
  const renderingContainer = document.querySelector(
    `.${projectContainerClassName}`
  )

  const coords = useRelativeCoordinates(
    renderingContainer,
    anchorEl,
    pickerRef,
    { alignmentY: 'bottom', alignmentX: 'left' }
  )

  const submit = () => {
    if (date) {
      submitDate(date)
      return
    }
    closeDatePicker()
  }

  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return
    }
    if (!modifiers.selected) {
      setDate(day)
    }
  }

  return ReactDom.createPortal(
    <Styled.DatePickerContainer
      className="datePicker"
      ref={pickerRef}
      $coords={coords}
    >
      <div className="helper-text">Pick the date of your upcoming event</div>
      <DayPicker
        disabledDays={{
          before: new Date(),
          after: endDate
        }}
        onDayClick={handleDayClick}
        selectedDays={date || selectedDays}
      />
      <div
        role="button"
        className={`submit-button ${date ? 'selected' : ''}`}
        onClick={submit}
      >
        Save
      </div>
    </Styled.DatePickerContainer>,
    renderingContainer
  )
}

export default memo(DatePicker)
