/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addButton,
  addIcon,
  addBlock
} from '@website/components/CommonActions'

import {
  removeBlockActionMaker,
  buttonModalActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _addIcon = idx => {
    dispatch(addIcon(idx))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    getButtonActions: buttonModalActionMaker(dispatch),
    addButton: () => dispatch(addButton()),
    addIcon: _addIcon,
    changeBlockTitle,
    changeBlockParagraph,
    addBlock: () => dispatch(addBlock()),
    blockActions: removeBlockActionMaker(dispatch),
    iconActions: idx => [
      ...iconActionMaker(dispatch)({
        idx,
        showRemove: true
      })
    ]
  }
}
