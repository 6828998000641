export const validation = {
  titleMaxChar: 70,
  subTitleMaxChar: 100,
  paragraphMaxChar: 500,
  paragraph1MaxChar: 300,
  images: {
    max: 2, 
    min: 1
  },
  buttons: {
    min: 0,
    max: 2
  }
}
