/* @flow */

import { createSelector } from 'reselect'
import { RSVP_COMP_CATEGORY, RF_SUBSCIRBER_LIMIT } from '@editor/conf/consts'
import { leftJoin } from '@editor/common/utils'
import { translate } from '@editor/common/utils/translations'
import { AVATAR_BG_OPTIONS } from './consts'

export const getProjectData = createSelector(
  state => state.project,
  project => project?.present
)

export const selectGeneratorVersion = state => getProjectData(state).generator

export const getEditor = (state: TAppState) => state.editor

export const getTemplatesInfo = (state: TAppState) =>
  getEditor(state).templatesInfo

export const getTemplatePresetCategories = (state: TAppState) => {
  const { templatePresetCategories } = getEditor(state)
  return templatePresetCategories?.map(category => category.id)
}

export const getUndoCount = (state: TAppProjectState) =>
  state.project.past.length
export const getRedoCount = (state: TAppProjectState) =>
  state.project.future.length

export const getPagesMeta = state => {
  const projectData = getProjectData(state)
  if (!projectData) {
    return null
  }
  const { pages } = projectData
  return pages.map(page => page.meta)
}

export const selectActivePagePath = (state: TAppProjectState) =>
  getEditor(state).activePagePath

export const selectSemrushLoginWindowState = (state: TAppProjectState) =>
  getEditor(state).isSemrushLoginWindowOpen

export const selectSemrushSidebarVisibilityState = (state: TAppProjectState) =>
  getEditor(state).semrushSidebar.isOpen

export const selectSemrushSidebarPinnedState = (state: TAppProjectState) =>
  getEditor(state).semrushSidebar.isPinned

export const selectSemrushKeywordPopupState = (state: TAppProjectState) =>
  getEditor(state).isSemrushKeywordPopupOpen

export const hasChangesAfterPublish = state =>
  getEditor(state).hasChangesAfterPublish

export const selectResetChangesPopupState = state =>
  getEditor(state).resetChangesPopupState

export const getCurrentPage = createSelector(
  selectActivePagePath,
  getProjectData,
  (activePagePath, projectData) => {
    if (!projectData) {
      return null
    }
    const { pages } = projectData
    return (
      pages && (pages.find(page => page.path === activePagePath) || pages[0])
    )
  }
)

export const hasComponents = (state: TAppState) => {
  const projectData = getProjectData(state)
  if (!projectData || !projectData.pages) {
    return false
  }
  return !!projectData.pages.find(
    page => page.components && page.components.length
  )
}

export const selectAllPagesData = createSelector(
  getProjectData,
  projectData => {
    if (!projectData) {
      return []
    }
    return projectData.pages
  }
)

export const selectHasFooterInProject = (state: TAppState) => {
  const projectData = getProjectData(state)
  if (!projectData || !projectData.pages) {
    return false
  }
  const doesPageHaveFooter = page =>
    page.components.find(comp => comp.category === 'footer')
  return projectData.pages.some(doesPageHaveFooter)
}

/**
 * Get all currently avaiable pages for the project
 * @param {TAppProjectState} state
 * @returns {Array<string>} Retruns string of page paths
 */
export const getAllPages = createSelector([selectAllPagesData], pages =>
  pages.map(page => page.path.substr(1)).map(path => path || 'home')
)

export const selectAllPagePaths = createSelector([selectAllPagesData], pages =>
  pages.map(page => {
    return page.path === '/' ? '/home' : page.path
  })
)

/**
 * Get all project links
 */
export const getProjectLinks = (
  state: TAppProjectState
): Array<{ text: string, link: string }> => {
  const { footerLinks, headerLinks } = getProjectData(state)
  return { footerLinks, headerLinks }
}

export const getCurrentPageComponents = (state: TAppState) => {
  const currentPage = getCurrentPage(state)
  if (!currentPage) {
    return []
  }
  return currentPage.components
}

export const getVisualParams = (state: TAppState) =>
  getProjectData(state).visualParams

export const getProjectFontPair = (state: TAppState) => {
  const visualParams = getVisualParams(state)
  return {
    primary: visualParams.primaryFont,
    secondary: visualParams.secondaryFont
  }
}

export const getLook = (state: TAppState) => getVisualParams(state).look

export const getPalette = (state: TAppState) => getVisualParams(state).palette

export const getTextSize = (state: TAppState) => getVisualParams(state).textSize

export const getSiteId = (state: TAppState) => {
  return getEditor(state).siteId
}

export const isProjectLoaded = (state: TAppState) =>
  getEditor(state).isProjectLoaded

export const hasPremiumFeature = (state: TAppState) =>
  getEditor(state).hasPremiumFeature

export const getNoLongerPremiumPopupState = (state: TAppState) =>
  getEditor(state).isNoLongerPremiumPopupOpen

export const selectPublishingStatus = (state: TAppState) =>
  getEditor(state).publishingProject

export const selectUserWithLoadingStatus = (state: TAppState) =>
  getEditor(state).user

export const getIsUserLoading = (state: TAppState) =>
  selectUserWithLoadingStatus(state).isLoading

export const selectIsUserAuthorized = (state: TAppState) => {
  if (!Object.keys(state).length) {
    return false
  }
  const user = selectUserWithLoadingStatus(state)
  return !user.isLoading && !!user.data
}

export const selectIsProjectSyncing = (state: TAppState) =>
  getEditor(state).isProjectSyncing

export const getUser = (state: TAppState) =>
  selectUserWithLoadingStatus(state).data

export const selectUserAvatar = createSelector(getUser, user => {
  if (!user || !user.avatar) {
    return {
      background: 'linear-gradient(#fff, #fff)',
      url: `https://cdn.renderforest.com/website/media/avatars/avater_0_108x108.svg`
    }
  }
  const { filePath2x, iconIndex, colorIndex } = user.avatar

  if (filePath2x) {
    return {
      background: 'linear-gradient(#fff, #fff)',
      url: filePath2x
    }
  }
  return {
    background: AVATAR_BG_OPTIONS[colorIndex || 0],
    url: `https://cdn.renderforest.com/website/media/avatars/avater_${
      iconIndex || 0
    }_108x108.svg`
  }
})

export const selectUserEmail = createSelector(getUser, user => user.email)

export const selectUserFirstName = createSelector(getUser, user => {
  if (!user) return ''

  return user.firstName
})

export const getMediaLibLimits = (state: IEditorState) =>
  getEditor(state).mediaLimits

export const isWarningModalOpened = (state: IEditorState) =>
  getEditor(state).showWarningModal

export const getSiteData = (state: IEditorState) => getEditor(state).siteData

export const isSubscriber = (state: IEditorState): boolean => {
  const user = getUser(state)
  const isPaid = getSiteData(state).paid
  if (!user) {
    return false
  }
  return isPaid || user.siteLimit > RF_SUBSCIRBER_LIMIT
}

export const getPageIdx = (state: IEditorState, url: string) => {
  const pageIdx = getAllPages(state).indexOf(url)
  return pageIdx !== -1 ? pageIdx : 0
}

export const selectTemplatesData = (state: IEditorState) =>
  getEditor(state).templatesData

export const selectTariffPlans = (state: IEditorState) =>
  getEditor(state).tariffPlans

export const selectSubscriptions = (state: IEditorState) =>
  getEditor(state).subscriptions

export const selectUpgradingPrices = (state: IEditorState) =>
  getEditor(state).upgradePrices

export const selectWebsiteSubscriptionPlan = createSelector(
  [selectSubscriptions, getSiteData],
  (subscriptions, siteData) => {
    const { tariffPlanId } = siteData
    const websiteSubscription = subscriptions.find(
      subscription => subscription.id === tariffPlanId
    )
    return websiteSubscription || {}
  }
)

export const getExtraLinksLabel = (state: IEditorState) =>
  getProjectData(state).extraLinksLabel

export const selectDomain = (state: IEditorState) => {
  const siteData = getSiteData(state)
  return siteData ? siteData.domain : ''
}

export const selectSiteHash = (state: IEditorState) => {
  const siteData = getSiteData(state)
  return siteData ? siteData.siteHash : ''
}

export const selectDomainCertifiedStatus = (state: IEditorState) => {
  const siteData = getSiteData(state)
  return siteData && siteData.hasCertificate
}

export const selectNewSubdomain = state => {
  const siteData = getSiteData(state)
  return siteData ? siteData.newSubdomain : ''
}

export const selectSubdomainValue = (state: IEditorState) => {
  const siteData = getSiteData(state)
  return siteData ? siteData.newSubdomain || siteData.subdomain : ''
}

export const getSubDomain = (state: IEditorState) => {
  const siteData = getSiteData(state)
  return siteData ? siteData.subdomain : ''
}

export const selectSitePublishedState = state => {
  const siteData = getSiteData(state)
  return siteData ? siteData.state === 'exported' : false
}

export const selectSitePublishedDate = createSelector(
  [selectSitePublishedState, getSiteData],
  (isSitePublished, siteData) => {
    if (!isSitePublished || !siteData || !siteData.lastPublishDate) {
      return ''
    }
    return siteData.lastPublishDate
  }
)

export const selectSiteOnlineStatus = state => {
  const siteData = getSiteData(state)
  return siteData ? siteData.isOnline : false
}

export const selectSiteOnlineStatusUIState = state =>
  getEditor(state).siteOnlineStatus

export const getUserId = (state: IEditorState) => {
  const siteData = getSiteData(state)
  return siteData ? siteData.userId : null
}
//return  userId+siteId as default subdomain
export const getDefaultSubdomain = createSelector(
  [getSiteId, getUserId],
  (siteId, userId) => {
    return `${userId}.${siteId}`
  }
)

//return default subdomain if subdomain is null
//otherwise return subdomain
export const getInitialSubdomain = createSelector(
  [getSubDomain, getDefaultSubdomain],
  (subdomain, initialSubdomain) => {
    if (!!subdomain) {
      return subdomain
    }
    return initialSubdomain
  }
)

export const selectSitePublishUrl = createSelector(
  [selectDomain, getSubDomain, getDefaultSubdomain],
  (domain, subdomain, defaultSubdomain) => {
    if (domain) {
      return `http://${domain}`
    } else {
      return `http://${subdomain || defaultSubdomain}.renderforestsites.com`
    }
  }
)

export const selectApplyTemplateId = (state: IEditorState) =>
  getEditor(state).applyTemplateId

export const selectTemplateCategories = (state: IEditorState) =>
  getTemplatesInfo(state).categories

export const selectTemplates = (state: IEditorState) =>
  getTemplatesInfo(state).templates

export const selectNewestTemplates = (state: IEditorState) =>
  getTemplatesInfo(state).newestTemplates

export const selectTemplateSearchTerm = (state: IEditorState) =>
  getTemplatesInfo(state).searchTerm

export const selectTemplatesTotalCount = (state: IEditorState) =>
  getTemplatesInfo(state).totalCount

export const selectTemplatesActiveCategoryInfo = (state: IEditorState) =>
  getTemplatesInfo(state).activeCategoryInfo

export const selectTemplatesCategories = createSelector(
  selectTemplateCategories,
  categories => {
    const defaultCategory = {
      id: 1,
      name: translate('all_label')
    }

    return {
      isLoading: categories.isLoading,
      payload: [defaultCategory, ...categories.payload]
    }
  }
)

const selectAppliedTemplateCategory = (state: IEditorState) =>
  selectTemplatesActiveCategoryInfo(state).activeCategory?.name

export const selectTemplateInfo = createSelector(
  [
    selectApplyTemplateId,
    selectTemplates,
    selectAppliedTemplateCategory,
    getTemplatePresetCategories
  ],
  (tempId, templates, templateCategoryName, templateCategories) => {
    const appliedTemplate = templates.payload?.find(
      template => template.id === tempId
    )

    return {
      ...appliedTemplate,
      templateCategories,
      templateCategoryName
    }
  }
)

// END new template selectors

export const getLinkSharePopupState = (state: TAppState) =>
  getEditor(state).shareLinkPopupState

export const selectGdprSettingsCustomizationWindowState = (state: TAppState) =>
  getEditor(state).gdprSettingsCustomizationWindowState

export const selectPublishPopupState = (state: TAppState) =>
  getEditor(state).publishPopupState

export const selectStatusChangeAndPublishPopupState = (state: TAppState) =>
  getEditor(state).statusChangeAndPublishPopupState

export const getSharedLinkData = (state: TAppState) =>
  getEditor(state).sharedLinkData

export const getShareLinkId = (state: TAppState) => {
  const sharedLinkData = getEditor(state).sharedLinkData.payload
  return sharedLinkData ? sharedLinkData.projectId : null
}

export const selectProjectFileUrls = (state: TAppState) =>
  getEditor(state).projectFileUrls

export const selectImagesUploadInfo = (state: TAppState) =>
  getEditor(state)?.imagesUploadInfo

export const selectCurrentPageComponentsForView = createSelector(
  [getCurrentPageComponents, selectImagesUploadInfo],
  leftJoin
)

export const getContributerSignOutPopupState = (state: TAppState) =>
  getEditor(state).contributorSignoutPopupState
export const getCurrentUserName = (state: TAppState) =>
  getEditor(state).currentUserName

export const isDomainPageOpen = (state: TAppState) =>
  getEditor(state).isDomainPageOpen

export const selectMaintenanceModalStatus = (state: TAppState) =>
  getEditor(state).showMaintenanceModal

export const selectCountriesList = (state: TAppState) =>
  getEditor(state).countries || []

const selectDomainsData = (state: TAppState) => getEditor(state).domains

export const selectDomains = (state: TAppState) => selectDomainsData(state).rows

export const selectDomainsForView = createSelector([selectDomains], domains => {
  return domains.filter(domain => domain.siteId === null)
})
export const selectDomainsTotalCount = (state: TAppState) =>
  parseInt(selectDomainsData(state).count)
export const selectIsDomainsLoading = (state: TAppState) =>
  selectDomainsData(state).isLoading
export const selectDomainsSearchQuery = (state: TAppState) =>
  selectDomainsData(state).searchQuery

export const selectSubdomain = (state: TAppState) => getEditor(state).subdomain
export const selectIsSubdomainSaving = (state: TAppState) =>
  selectSubdomain(state).isSaving
export const selectSubdomainErrorMessage = (state: TAppState) =>
  selectSubdomain(state).errorMessage
export const selectOwnDomain = (state: TAppState) => getEditor(state).ownDomain
export const selectIsCustomDomainSaving = (state: TAppState) =>
  selectOwnDomain(state).isLoading
export const selectSSLSuccessMessage = (state: TAppState) =>
  selectOwnDomain(state).sslSuccessMessage
export const selectSSLErrorMessage = (state: TAppState) =>
  selectOwnDomain(state).sslErrorMessage
export const selectDomainConfiguredStatus = (state: TAppState) =>
  selectOwnDomain(state).configured.isConfigured
export const selectConfiguredMessage = (state: TAppState) =>
  selectOwnDomain(state).configured.message
export const selectIsLoadingConfigured = (state: TAppState) =>
  selectOwnDomain(state).configured.isLoading
export const selectOwnDomainErrorMessage = (state: TAppState) =>
  selectOwnDomain(state).errorMessage
export const selectIsLastPublishFree = state => {
  const siteData = getSiteData(state)
  return siteData && siteData.lastPublishIsFree
}
export const selectIsCongratsPopupOpen = (state: TAppState) =>
  selectOwnDomain(state).isCongratsPopupOpen
export const selectLastTimeSslOnDate = (state: IEditorState) => {
  const siteData = getSiteData(state)
  return siteData && siteData.lastTimeSslOnDate
}

export const selectSidebarActiveItem = (state: TAppState) =>
  getEditor(state).activeSidebarItem

export const selectSiteName = (state: IEditorState) => {
  const siteData = getSiteData(state)
  return siteData ? siteData.name : ''
}

export const selectIsMainSidebarExpanded = (state: TAppState) =>
  getEditor(state).isMainSidebarExpanded

export const selectIsRevisionPending = (state: TAppState) =>
  getEditor(state).pendingRevisionCount > 0

// settings
export const selectIsSettingsLoading = (state: TAppState) =>
  getEditor(state).settings.isLoading

export const selectSettingsSuccessMessage = (state: TAppState) =>
  getEditor(state).settings.successMessage

export const selectGlobalErrorMessage = (state: TAppState) =>
  getEditor(state).globalErrorMessage

export const selectGoogleAnalyticsSuccessMessage = (state: IEditorState) =>
  getEditor(state).settings.marketingSuccessMessages
    .googleAnalyticsSuccessMessage

export const selectYandexMetricaSuccessMessage = (state: IEditorState) =>
  getEditor(state).settings.marketingSuccessMessages.yandexMetricaSuccessMessage

export const selectGoogleTagManagerSuccessMessage = (state: IEditorState) =>
  getEditor(state).settings.marketingSuccessMessages
    .googleTagManagerSuccessMessage

export const selectGoogleAdSenseSuccessMessage = (state: IEditorState) =>
  getEditor(state).settings.marketingSuccessMessages.googleAdSenseSuccessMessage

export const selectAuthDigitalSellersSuccessMessage = (state: IEditorState) =>
  getEditor(state).settings.marketingSuccessMessages
    .authDigitalSellersSuccessMessage

export const selectFacebookPixelSuccessMessage = (state: IEditorState) =>
  getEditor(state).settings.marketingSuccessMessages.facebookPixelSuccessMessage

export const selectWebsiteActiveLanguageCode = createSelector(
  getEditor,
  state => state.websiteActiveLanguageCode
)

export const selectPossibleLanguagesData = createSelector(
  getEditor,
  state => state.possibleLanguagesData.data
)

export const selectPossibleLanguagesDataLoadingStatus = createSelector(
  getEditor,
  state => state.possibleLanguagesData.isLoading
)

export const selectIsWebsiteLanguageChanging = createSelector(
  getEditor,
  state => state.isWebsiteLanguageChanging
)

export const selectEventCreationPopupVisibilityState = createSelector(
  getEditor,
  state => state.isEventCreationPopupOpen
)

export const selectPagesSpecialComponent = createSelector(
  [selectAllPagesData],
  pages =>
    pages.find(page =>
      page.components.find(item => item.category === RSVP_COMP_CATEGORY)
    )
)

export const selectAllRsvpComponents = createSelector(
  [selectAllPagesData],
  pages =>
    pages.reduce((obj, { components }) => {
      components.forEach(component => {
        if (component.category === RSVP_COMP_CATEGORY) {
          obj[component.eventId] = component
        }
      })

      return obj
    }, {})
)

export const selectTimeZonesData = createSelector(
  getEditor,
  state => state.timeZonesData
)

export const selectIsLogoApplying = createSelector(
  getEditor,
  state => state.isLogoApplying
)

export const selectIsInitialLogoApplying = createSelector(
  getEditor,
  state => state.isInitialLogoApplying
)

export const selectHeader15logoUrl = createSelector(
  getProjectData,
  state => state.pages[0].components[0]?.data?.contentImage?.imgUrl
)
