/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_INFO_TITLE':
        draft.infoTitle = action.value
        break
      case 'CHANGE_INFO_TITLE_ALIGNMENT': {
        draft.infoTitleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_ICON':
        {
          const { idx, iconName } = action.value
          draft.blocks[idx].iconName = iconName
        }
        break
      case 'ADD_ICON':
        draft.blocks[action.value].iconName = 'edit'
        break
      case 'CHANGE_LINK_DATA':
        const { linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break
      case 'REMOVE_LINK':
        draft.additionalText = ''
        draft.additionalUrl = ''
        break
      case 'ADD_LINK':
        const { additionalText, additionalUrl } = initialState
        draft.additionalText = additionalText
        draft.additionalUrl = additionalUrl
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.blocks[idx].imgCropParams = imgCropParams
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.blocks[idx].imgUrl = url
        draft.blocks[idx].imgDimensions = dimensions || {}
        draft.blocks[idx].imgCropParams = {}
        break
      }
    }
  })
}
