/* @flow */
import produce from 'immer'
import type { TPricing4Actions } from './types.flow.js'

export default function reducer(state, action: TPricing4Actions) {
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_PRIMARY_PLAN':
        draft.primaryPlanIdx = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_CARD_DATA':
        const { keyInStore, planIdx, data } = action.value
        draft.plans[planIdx][keyInStore] = data
        break
      case 'CHANGE_OPTION':
        const { newOption, planIndex: _idx } = action.value
        draft.plans[_idx].option = newOption
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.plans[btnIdx].buttonText = btnData.text
        draft.plans[btnIdx].buttonUrl = btnData.link
        break
      case 'CHANGE_ICON':
        const { idx, iconName } = action.value
        draft.plans[idx].icon = iconName
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.plans[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_OPTION_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.plans[idx].optionAlignment = alignment
        break
      }
      case 'CHANGE_PRICE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.plans[idx].priceAlignment = alignment
        break
      }
    }
  })
}
