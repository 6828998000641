// @flow

import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import {
  setSemrushSidebarVisibilityState,
  fetchSemrushTablePagination,
  setSemrushKeywordTableDatabase,
  setSemrushKeywordTableActiveTab,
  setSemrushKeywordTablePhrase
} from '@actions'

import {
  selectSemrushSidebarVisibilityState,
  selectSemrushKeywordTableDatabase,
  selectSemrushKeywordTableActiveTab,
  selectSemrushKeywordsData,
  selectSemrushKeywordTablePhrase,
  selectSemrushKeywordsSearchLimitExceededState
} from '@selectors'
import {
  SearchWithDatabases,
  KeywordTabs,
  WarningContent
} from '@editor/common/components/SemrushCommon'
import { SidebarHeader, TopRelatedKeywords, InitialContent } from './components'
import { KEYWORD_CHAR_LIMIT } from '../SemrushKeywordPopup/consts'

import * as Styled from './styled'
import type { TSemrushSidebarProps } from './types'
import { CircleProgressLoader } from '@editor/common/components/Loaders'
import { translate } from '@editor/common/utils/translations'

const SemrushSidebar = ({
  isOpen,
  hasLimitExceeded,
  keywordTablePhrase,
  keywordTableDatabase,
  keywordTableActiveTab,
  setSemrushKeywordTablePhrase,
  setSemrushKeywordTableDatabase,
  setSemrushKeywordTableActiveTab,
  keywordsData,
  setSemrushSidebarVisibilityState
}: TSemrushSidebarProps) => {
  const [isMounted, setMountedState] = useState(isOpen)
  const [searchValue, setSearchValue] = useState(keywordTablePhrase)

  const { payload, isLoading } = keywordsData
  const hasResults = !!payload

  useEffect(
    () => () => {
      setSemrushSidebarVisibilityState(false)
    },
    []
  )

  useEffect(() => {
    if (isOpen) {
      setMountedState(true)
    }
  }, [isOpen])

  useEffect(() => {
    setSearchValue(keywordTablePhrase)
  }, [keywordTablePhrase])

  const handleAnimationEnd = useCallback(e => {
    if (e.animationName === 'close-semrush-sidebar') {
      setMountedState(false)
    }
  }, [])

  const closeSidebar = useCallback(() => {
    setSemrushSidebarVisibilityState(false)
  }, [setSemrushSidebarVisibilityState])

  const handleSearch = useCallback(() => {
    setSemrushKeywordTablePhrase(searchValue)
  }, [setSemrushKeywordTablePhrase, searchValue])

  const handleEnterPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        handleSearch()
      }
    },
    [handleSearch]
  )

  const onRemoveIconClick = useCallback(() => {
    setSearchValue('')
  }, [])

  return isMounted ? (
    <Styled.SidebarContainer
      isOpen={isOpen}
      onAnimationEnd={handleAnimationEnd}
    >
      <SidebarHeader onClose={closeSidebar} />
      <Styled.SidebarContent>
        {isLoading ? (
          <CircleProgressLoader
            className="circle-loader"
            text={translate('searching_label')}
          />
        ) : null}
        {hasLimitExceeded ? <WarningContent /> : null}
        <Styled.SearchContainer>
          <SearchWithDatabases
            isVerticalView
            count={KEYWORD_CHAR_LIMIT}
            database={keywordTableDatabase}
            searchValue={searchValue}
            onSearchClick={handleSearch}
            onSearchChange={setSearchValue}
            handleEnterPress={handleEnterPress}
            onDatabaseChange={setSemrushKeywordTableDatabase}
            onRemoveIconClick={onRemoveIconClick}
          />
        </Styled.SearchContainer>
        {hasResults ? (
          <>
            <KeywordTabs
              className="sidebar-tabs"
              isHorizontalView
              activeTab={keywordTableActiveTab}
              onTabChange={setSemrushKeywordTableActiveTab}
            />
            <TopRelatedKeywords />
          </>
        ) : (
          <InitialContent />
        )}
      </Styled.SidebarContent>
    </Styled.SidebarContainer>
  ) : null
}

const mapStateToProps = state => ({
  isOpen: selectSemrushSidebarVisibilityState(state),
  keywordTableActiveTab: selectSemrushKeywordTableActiveTab(state),
  keywordTableDatabase: selectSemrushKeywordTableDatabase(state),
  keywordTablePhrase: selectSemrushKeywordTablePhrase(state),
  hasLimitExceeded: selectSemrushKeywordsSearchLimitExceededState(state),
  isPaginationLoading: selectSemrushKeywordsData(state).isPaginationLoading,
  keywordsData: selectSemrushKeywordsData(state)
})

export default connect(mapStateToProps, {
  setSemrushSidebarVisibilityState,
  setSemrushKeywordTableDatabase,
  setSemrushKeywordTableActiveTab,
  setSemrushKeywordTablePhrase,
  fetchSemrushTablePagination
})(SemrushSidebar)
