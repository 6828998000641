/* @flow */
import React, { useContext, memo } from 'react'
import S from './Footer4.styled'
import { validation } from './Footer4.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Footer from '@website/common/components/Footer'
import Logo from '@website/common/components/Logo'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import StoreBadge from '@website/common/components/StoreBadge'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import {
  Controls,
  ControlsWithModalOpener
} from '@website/common/components/Controlled'
import {
  EditingContext,
  DispatchContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import SocialLinks from '@website/common/components/SocialLinks'
import LinkWrapper from '@website/common/components/LinkWrapper'

const Footer4 = memo(props => {
  const {
    data: {
      socials,
      logoSrc,
      paragraph,
      logoWidth,
      logoColor,
      logoDimensions,
      copyrightText,
      appleStoreLink,
      googlePlayLink
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}

  return (
    <S.Container isEditing={isEditing}>
      <WMContainer>
        <S.FooterContainer>
          <S.FooterContentWrap>
            <S.FooterContent>
              <Logo
                logoSrc={logoSrc}
                logoWidth={logoWidth}
                logoColor={logoColor}
                imgDimensions={logoDimensions}
              />

              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
              />
            </S.FooterContent>
            <S.LinksWrap className="WM_GLOBAL_secondary-font">
              <Footer />
            </S.LinksWrap>
          </S.FooterContentWrap>

          <S.SocialBadgeWrap>
            <Show when={[googlePlayLink, appleStoreLink]}>
              <S.BadgesWrap>
                <If
                  condition={!!googlePlayLink}
                  then={() => (
                    <ControlsWithModalOpener
                      actions={Actions.appStoreActions(
                        'googlePlayLink',
                        googlePlayLink
                      )}
                    >
                      <StoreBadge badgeName="google" url={googlePlayLink} />
                    </ControlsWithModalOpener>
                  )}
                  otherwise={() => (
                    <AddButton
                      onAdd={() => Actions.addStoreLink('googlePlayLink')}
                      toShow={true}
                      rounded
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      Play market
                    </AddButton>
                  )}
                />
                <If
                  condition={!!appleStoreLink}
                  then={() => (
                    <ControlsWithModalOpener
                      actions={Actions.appStoreActions(
                        'appleStoreLink',
                        appleStoreLink
                      )}
                    >
                      <StoreBadge badgeName="apple" url={appleStoreLink} />
                    </ControlsWithModalOpener>
                  )}
                  otherwise={() => (
                    <AddButton
                      onAdd={() => Actions.addStoreLink('appleStoreLink')}
                      toShow={true}
                      rounded
                      style={{ marginLeft: '10px' }}
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      App store
                    </AddButton>
                  )}
                />
              </S.BadgesWrap>
            </Show>
            <SocialLinks
              className="socials"
              socials={socials}
              socialsMaxCount={validation.socials.max}
            />
          </S.SocialBadgeWrap>
          <S.CopyRightWrapper>
            <EditableContent
              text={copyrightText}
              as={S.Copyright}
              className="WM_GLOBAL_secondary-font"
              onChange={Actions.changeCopyright}
              isPrivacyPolicyVisible={isPrivacyPolicyVisible}
            />
            {isPrivacyPolicyVisible ? (
              <S.LinkWrap isEditing={isEditing}>
                <Controls actions={Actions.privacyPolicyAction()}>
                  <LinkWrapper
                    text={privacyPolicyName}
                    url={privacyPolicyLink}
                    linkProps={{
                      className: 'privacy-policy-link WM_GLOBAL_secondary-font'
                    }}
                  />
                </Controls>
              </S.LinkWrap>
            ) : null}
          </S.CopyRightWrapper>
        </S.FooterContainer>
      </WMContainer>
    </S.Container>
  )
})

export default Footer4
