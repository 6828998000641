/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature10.styled'
import ActionGetter from './Actions'
import { validation } from './Feature10.consts'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import { EditingContext, DispatchContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Feature10 = memo(props => {
  const {
    data: {
      features,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <WMContainer>
        <S.FeatureItemWrap>
          <Mapper
            data={features}
            render={(feature: string, idx: number) => (
              <Show
                when={[feature.iconName, feature.count, feature.title]}
                key={idx}
              >
                <ControlsWithModalOpener
                  actions={Actions.featureActions({
                    idx,
                    showRemove: features.length > validation.features.min
                  })}
                >
                  <S.ItemWrap>
                    <S.IconWrapper>
                      <If
                        condition={!!feature.iconName}
                        otherwise={() => (
                          <AddButton
                            onAdd={() => Actions.addIcon(idx)}
                            type="icon"
                            large
                            toShow
                          >
                            +
                          </AddButton>
                        )}
                        then={() => (
                          <ControlsWithModalOpener
                            actions={Actions.iconActions({ idx })}
                          >
                            <S.Circle>
                              <S.Icon size="xxlarge" name={feature.iconName} />
                            </S.Circle>
                          </ControlsWithModalOpener>
                        )}
                      />
                    </S.IconWrapper>
                    <EditableContent
                      as={S.Count}
                      text={feature.count}
                      alignment={feature.blockCountAlignment}
                      maxCount={validation.countMaxChar}
                      onChange={(newCount: string) =>
                        Actions.changeBlockCount(newCount, idx)
                      }
                      className="WM_GLOBAL_heading54"
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockCountAlignment(alignment, idx)
                      }
                    />
                    <EditableContent
                      as={S.Title}
                      text={feature.title}
                      alignment={feature.blockTitleAlignment}
                      maxCount={validation.titleMaxChar}
                      className="WM_GLOBAL_secondary-font"
                      onChange={(text: string) =>
                        Actions.changeBlockText(text, idx)
                      }
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(alignment, idx)
                      }
                    />
                    <S.Line />
                  </S.ItemWrap>
                </ControlsWithModalOpener>
              </Show>
            )}
          />
        </S.FeatureItemWrap>
      </WMContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={features.length < validation.features.max}
        style={{
          marginTop: '40px'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_feature_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Feature10
