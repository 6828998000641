//@flow
import React, { memo } from 'react'

import TopContent from './TopContent'
import TabsSection from './TabsSection'
import KeywordTable from './KeywordTable'
import type { TProps } from './types'

const ResultContent = ({
  searchValue,
  searchedPhrase,
  sortingInfo,
  database,
  activeTab,
  onSearchChange,
  onDatabaseChange,
  onTabChange,
  onSortingInfoChange,
  handleEnterPress,
  onRemoveIconClick,
  isTableHeadSticky,
  targetRef,
  isFeaturesFlyoutOpened,
  setFeaturesFlyoutOpenedState
}: TProps) => (
  <>
    <TopContent
      onSearchChange={onSearchChange}
      searchValue={searchValue}
      searchedPhrase={searchedPhrase}
      handleEnterPress={handleEnterPress}
      onRemoveIconClick={onRemoveIconClick}
    />
    <TabsSection
      activeTab={activeTab}
      database={database}
      onTabChange={onTabChange}
      onDatabaseChange={onDatabaseChange}
    />
    <KeywordTable
      sortingInfo={sortingInfo}
      isTableHeadSticky={isTableHeadSticky}
      targetRef={targetRef}
      onSortingInfoChange={onSortingInfoChange}
      isFeaturesFlyoutOpened={isFeaturesFlyoutOpened}
      setFeaturesFlyoutOpenedState={setFeaturesFlyoutOpenedState}
    />
  </>
)

export default memo(ResultContent)
