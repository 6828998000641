/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header6_2.styled'
import THEME from './Header6_2.theme'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import StoreBadge from '@website/common/components/StoreBadge'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { validation } from './Header6_2.consts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Morph from './Header6_2_morph'

const Header6_2 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      logoWidth,
      description,
      appleStoreLink,
      logoDimensions,
      googlePlayLink,
      appleBackground,
      backgroundImgUrl,
      androidBackground,
      androidBackgroundImgCropParams,
      appleBackgroundImgCropParams,
      appleBackgroundMockupColor = 'light',
      androidBackgroundMockupColor = 'light',
      backgroundImgDimensions,
      bgOverlayTransparency,
      androidBackgroundDimensions,
      appleBackgroundDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const isEditing = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
      isEditing={isEditing}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer
        className="header-6_2-container"
        topOffset={navBarHeight}
      >
        <Show when={[title, description, appleStoreLink, googlePlayLink]}>
          <S.Section>
            <S.ContentWrapper>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                required={validation.title.required}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={description}
                alignment={paragraphAlignment}
                as={S.Description}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeDescription}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <S.BadgeContainer isEditing={isEditing}>
                <If
                  condition={!!googlePlayLink}
                  then={() => (
                    <ControlsWithModalOpener
                      actions={Actions.storeIconActions(
                        'googlePlayLink',
                        googlePlayLink
                      )}
                    >
                      <StoreBadge
                        className="badge-item-wrap"
                        badgeName="google"
                        url={googlePlayLink}
                      />
                    </ControlsWithModalOpener>
                  )}
                  otherwise={() => (
                    <AddButton
                      onAdd={() => Actions.addStoreLink('googlePlayLink')}
                      toShow={true}
                      rounded
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      Google play
                    </AddButton>
                  )}
                />
                <If
                  condition={!!appleStoreLink}
                  then={() => (
                    <ControlsWithModalOpener
                      actions={Actions.storeIconActions(
                        'appleStoreLink',
                        appleStoreLink
                      )}
                    >
                      <StoreBadge
                        badgeName="apple"
                        url={appleStoreLink}
                        className="badge-item-wrap"
                      />
                    </ControlsWithModalOpener>
                  )}
                  otherwise={() => (
                    <AddButton
                      onAdd={() => Actions.addStoreLink('appleStoreLink')}
                      toShow={true}
                      rounded
                      style={{ margin: '0 0 0 10px' }}
                    >
                      <Icon name="glyph-add" className="icon" size="normal" />
                      App store
                    </AddButton>
                  )}
                />
              </S.BadgeContainer>
            </S.ContentWrapper>
          </S.Section>
        </Show>
        <S.MockupsWrap>
          <S.Section className="frame-wrap apple-wrap phone-1">
            <S.Phone className="phone-1" color={appleBackgroundMockupColor}>
              <S.MorphWrapFirst>
                <Morph />
              </S.MorphWrapFirst>
              <ControlsWithImageCropper
                actions={Actions.mockupActions({
                  mockupType: 'appleBackground',
                  mockupColor: appleBackgroundMockupColor,
                  imageUrl: appleBackground
                })}
              >
                <Image
                  as={S.MobileImg}
                  asProps={{
                    imgCropParams: appleBackgroundImgCropParams
                  }}
                  defaultImgSrc={appleBackground}
                  sizes={appleBackgroundDimensions}
                  alt="Web page "
                />
              </ControlsWithImageCropper>
            </S.Phone>
          </S.Section>
          <S.Section className="frame-wrap apple-wrap phone-2">
            <S.Phone className="phone-2" color={androidBackgroundMockupColor}>
              <S.MorphWrapSecond>
                <Morph />
              </S.MorphWrapSecond>
              <ControlsWithImageCropper
                actions={Actions.mockupActions({
                  mockupType: 'androidBackground',
                  mockupColor: androidBackgroundMockupColor,
                  imageUrl: androidBackground
                })}
              >
                <Image
                  as={S.MobileImg}
                  asProps={{
                    imgCropParams: androidBackgroundImgCropParams
                  }}
                  defaultImgSrc={androidBackground}
                  sizes={androidBackgroundDimensions}
                  alt="Mobile mockup"
                />
              </ControlsWithImageCropper>
            </S.Phone>
          </S.Section>
        </S.MockupsWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

Header6_2.contextType = DispatchContext

export default Header6_2
