// @flow

import React, { useMemo, useCallback, memo } from 'react'
import { useSelector } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import Dropdown from '@renderforest/rf-ui-library/molecules/Dropdown'
import { InputContainer as StyledInputContainer } from './styled'

import { getLinkTypesDropdownOptions } from './utils'
import { selectAllPagesData } from '@selectors'

const LinkTypeDropdown = ({ value, onChange }) => {
  const hasManyPages = useSelector(selectAllPagesData).length > 1

  const dropdownOptions = useMemo(
    () => getLinkTypesDropdownOptions(hasManyPages),
    [hasManyPages]
  )

  const handleChange = useCallback(
    newValue => {
      if (newValue === value) {
        return
      }
      onChange(newValue)
    },
    [value]
  )

  return (
    <StyledInputContainer className="select-action-wrapper">
      <Dropdown
        label={translate('link_popup_select_click_action_label')}
        value={value}
        placeholder={translate('select_action')}
        onChange={handleChange}
        options={dropdownOptions}
      />
    </StyledInputContainer>
  )
}

export default memo(LinkTypeDropdown)
