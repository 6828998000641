import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import {
  fetchWebsiteDataByLang,
  setGlobalErrorMessage,
  setLanguageTranslationState
} from '@actions'
import { TRANSLATED } from '@editor/conf/consts'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'

const useSubscribeAutoTranslationSocket = () => {
  const dispatch = useDispatch()
  const { lang } = useParams()
  const activeLanguageRef = useRef(lang)

  useEffect(() => {
    activeLanguageRef.current = lang
  }, [lang])

  const cb = ({ language, state, type }) => {
    if (type === 'site-translation') {
      if (state === TRANSLATED) {
        dispatch(setLanguageTranslationState(language, TRANSLATED))

        if (activeLanguageRef.current === language) {
          dispatch(fetchWebsiteDataByLang(language))
        }
      }

      if (state === 'ERROR') {
        dispatch(
          setGlobalErrorMessage(
            transformTextVariables(
              translate('language_translation_error_message'),
              [{ value: language.toUpperCase(), Comp: BoldComp }]
            )
          )
        )
        dispatch(setLanguageTranslationState(language, 'ERROR'))
      }
    }
  }

  useEffect(() => {
    window.subscribeToSockets && window.subscribeToSockets(cb)
  }, [])
}

export default useSubscribeAutoTranslationSocket
