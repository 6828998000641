import React from 'react'

import * as Styled from './styled'

const ItemWrapper = ({
  offsetFromCenter,
  animationDuration,
  onClick,
  children,
  width,
  height,
  sizes,
  additionalOffset
}) => {
  return (
    <Styled.ItemWrapper onClick={onClick} height={height}>
      <Styled.Item
        height={height}
        width={width}
        sizes={sizes}
        additionalOffset={additionalOffset}
        offsetFromCenter={offsetFromCenter}
        animationDuration={animationDuration}
      >
        {children}
      </Styled.Item>
    </Styled.ItemWrapper>
  )
}

export default ItemWrapper
