/* @flow */

module.exports = {
  id: 'RenderforestFooter',
  isUnique: true,
  label: 'Renderforest Footer',
  // category: 'cookie',
  thumbnail: '',
  colorMode: ''
}
