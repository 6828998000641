import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    firstIcon: p => p.primaryLight,
    secondIcon: p => p.tertiaryLight,
    thirdIcon: p => p.tertiarySemiLight,
    fourthIcon: p => p.secondaryLight,
    background: p => `${p.tertiaryDark}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    anchor: p => p.primaryLight
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    firstIcon: p => p.primaryDark,
    secondIcon: p => p.tertiaryDark,
    thirdIcon: p => p.tertiaryNormal,
    fourthIcon: p => p.secondaryDark,
    background: p => `${p.tertiaryLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    anchor: p => p.primaryDark
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    firstIcon: p => p.primaryDark,
    secondIcon: p => p.tertiaryDark,
    thirdIcon: p => p.tertiaryNormal,
    fourthIcon: p => p.secondaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    anchor: p => p.primaryDark
  }
}
