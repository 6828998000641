/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Header5_2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const stylesByColor = {
  default: '',
  dark: css`
    filter: brightness(0.13);
  `,
  light: css`
    filter: brightness(0.97);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0;
  flex-direction: column;
  @media only screen and (max-width: 457px) {
    padding-bottom: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset + 140}px`};
  padding-bottom: 125px;
  align-items: center;
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 1300px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
    padding-bottom: 20px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
    padding-bottom: 0;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 560px;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (max-width: 1300px) {
    max-width: unset;
    align-items: center;
    margin-right: initial;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
`

const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const DesktopImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

const Phone = styled.div`
  width: 116px;
  margin: 0 auto;
  position: relative;
  padding: 20px 8px 25px 8px;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 0;
  margin-left: 5px;
  margin-bottom: 5px;
  &:before {
    content: ' ';
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5_2-mobile-with-shadow_1.png');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 211.49%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 5px;
  }
`

const Notebook = styled.div`
  width: 520px;
  margin: 0 auto;
  position: relative;
  padding: 24px 30px 100px 30px;
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 0;
  &:before {
    content: ' ';
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header5_2-macbook-with-shadow_1.png');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 56.25%;
  }
  .lazy-figure {
    width: 100%;
    position: absolute;
    top: 0;
  }
`
const MockupsWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 40px;
  @media only screen and (max-width: 1400px) {
    margin-right: 15px;
  }
  @media only screen and (max-width: 1300px) {
    margin-top: 40px;
    margin-right: initial;
  }
  @media only screen and (max-width: 700px) {
    transform: scale(0.55);
    margin-top: -30px;
    margin-bottom: -50px;
  }
  @media only screen and (max-width: 370px) {
    transform: scale(0.4);
    margin-top: -40px;
    margin-bottom: -60px;
  }
  .image-state-overlay,
  .failed-image-container {
    z-index: 1;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  Description,
  Phone,
  MobileImg,
  Section,
  Notebook,
  MockupsWrap,
  DesktopImg
}
