/* @flow */
import styled, {css} from 'styled-components'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Testimonials2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  .control-container {
    width: 100%;
  }
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const TestimonialsItemsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -20px 0;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
  }
`

const ClientImgWrap = styled.div`
  min-width: 40px;
  font-size: 0;
  .control-container {
    width: 100%;
    height: 100%;
  }
`

const CompanyLogoWrap = styled.div`
  display: block;
  height: 80px;
  max-width: 200px;
  margin: 0 0 20px 0;
`

const TextContet = styled.div`
  width: 100%;
  margin-bottom: 50px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
  }
  > :last-child {
    padding-top: 0;
  }
  .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
`

const ClientWrap = styled.div`
  display: flex;
  align-self: flex-end;
  margin-top: 40px;
`

const ClientInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  margin-right: 20px;
  width: 100%;
  .control-container {
    width: 100%;
    h2,
    p {
      margin-bottom: 0;
    }
  }
  .control-container:first-child {
    margin-bottom: 10px;
  }
`

const Item = styled.div`
  margin: 20px;
  flex-grow: 1;
  width: 45%;
  max-width: 600px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: 20px 0;
  }
`

const ItemWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  box-sizing: border-box;
  @media only screen and (max-width: 1200px) {
    padding: 40px 20px;
  }
  .control-container {
    width: 100%;
    height: 100%;
  }
`

const Name = styled.h2`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-weight: 800;
  font-size: 16px;
  width: 100%;
  /* ${getTextStyleByAlignment}; */
`

const Position = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  font-weight: 300;
  font-size: 12px;
  width: 100%;
  /* ${getTextStyleByAlignment}; */
`

const ItemParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  line-height: 1.4;
  width: 100%;
`

const Img = styled.img`
  border-radius: ${getStyleForProp('iconRadius')};
  width: 100%;
  height: auto;
  display: block;
  width: 40px;
  height: 40px;
  object-fit: cover;
`

const Logo = styled.img`
  width: 100%;
  height: auto;
  max-height: 80px;
  object-fit: contain;
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
`

const ItemParagraphContent = styled.p`
  width: 100%;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WmCustomContainer,
  TestimonialsItemsWrap,
  TextContet,
  CompanyLogoWrap,
  ClientWrap,
  ClientImgWrap,
  ClientInfo,
  Title,
  Logo,
  Paragraph,
  Item,
  ItemWrap,
  Name,
  Position,
  ItemParagraph,
  Img,
  ItemParagraphContent
}
