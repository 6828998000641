export const validation = {
  headingMaxChar: 30,
  paragraphMaxChar: 300,
  contactMaxChar: 80,
  socials: {
    min: 0,
    max: 7
  }
}

export const EMPTY_FIELD_MESSAGE = 'This field cannot be empty'
export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email'
