import styled from 'styled-components'

export const Button = styled.div`
  position: relative;
  z-index: 3;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #ff642d;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 0 3px rgba(255, 100, 45, 0.4),
    0 0 0 6px rgba(255, 100, 45, 0.2);
  & > img {
    transform: translateX(-2px);
  }

  & > svg {
    fill: #ffffff;
  }
`

export const TooltipContainer = styled.div`
  position: absolute;
  right: 2px;
  width: 50px;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0px 0px 12px 2px rgba(253, 101, 56, 0.08);
  border-radius: 30px;
  transition: width 0.3s ease-out;
  display: flex;
  align-items: center;
  & > span {
    min-width: 130px;
    max-width: 130px;
    margin-left: 30px;
    font-size: 12px;
    color: #252e48;
    user-select: none;
  }
`

export const ButtonContainer = styled.div`
  position: absolute;
  z-index: 21;
  bottom: 25px;
  right: 45px;
  cursor: pointer;
  &:hover ${TooltipContainer} {
    width: 235px;
  }
`
