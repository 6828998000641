/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Morph = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="306"
      height="115"
      viewBox="0 0 306 115"
    >
      <g
        id="Group_76675"
        data-name="Group 76675"
        transform="translate(-1828.438 -8227.361)"
        opacity="0.2"
      >
        <circle
          id="Ellipse_50"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1828.438 8227.361)"
        />
        <circle
          id="Ellipse_50-2"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1851.438 8227.361)"
        />
        <circle
          id="Ellipse_50-3"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1874.438 8227.361)"
        />
        <circle
          id="Ellipse_50-4"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1897.438 8227.361)"
        />
        <circle
          id="Ellipse_50-5"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1920.438 8227.361)"
        />
        <circle
          id="Ellipse_50-6"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1943.438 8227.361)"
        />
        <circle
          id="Ellipse_50-7"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1966.438 8227.361)"
        />
        <circle
          id="Ellipse_50-8"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1989.438 8227.361)"
        />
        <circle
          id="Ellipse_50-9"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2012.438 8227.361)"
        />
        <circle
          id="Ellipse_50-10"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2035.438 8227.361)"
        />
        <circle
          id="Ellipse_50-11"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2058.438 8227.361)"
        />
        <circle
          id="Ellipse_50-12"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2081.438 8227.361)"
        />
        <circle
          id="Ellipse_50-13"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2104.438 8227.361)"
        />
        <circle
          id="Ellipse_50-14"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2127.438 8227.361)"
        />
        <circle
          id="Ellipse_50-15"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1828.438 8254.361)"
        />
        <circle
          id="Ellipse_50-16"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1851.438 8254.361)"
        />
        <circle
          id="Ellipse_50-17"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1874.438 8254.361)"
        />
        <circle
          id="Ellipse_50-18"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1897.438 8254.361)"
        />
        <circle
          id="Ellipse_50-19"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1920.438 8254.361)"
        />
        <circle
          id="Ellipse_50-20"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1943.438 8254.361)"
        />
        <circle
          id="Ellipse_50-21"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1966.438 8254.361)"
        />
        <circle
          id="Ellipse_50-22"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1989.438 8254.361)"
        />
        <circle
          id="Ellipse_50-23"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2012.438 8254.361)"
        />
        <circle
          id="Ellipse_50-24"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2035.438 8254.361)"
        />
        <circle
          id="Ellipse_50-25"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2058.438 8254.361)"
        />
        <circle
          id="Ellipse_50-26"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2081.438 8254.361)"
        />
        <circle
          id="Ellipse_50-27"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2104.438 8254.361)"
        />
        <circle
          id="Ellipse_50-28"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2127.438 8254.361)"
        />
        <circle
          id="Ellipse_50-29"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1828.438 8281.361)"
        />
        <circle
          id="Ellipse_50-30"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1851.438 8281.361)"
        />
        <circle
          id="Ellipse_50-31"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1874.438 8281.361)"
        />
        <circle
          id="Ellipse_50-32"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1897.438 8281.361)"
        />
        <circle
          id="Ellipse_50-33"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1920.438 8281.361)"
        />
        <circle
          id="Ellipse_50-34"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1943.438 8281.361)"
        />
        <circle
          id="Ellipse_50-35"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1966.438 8281.361)"
        />
        <circle
          id="Ellipse_50-36"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1989.438 8281.361)"
        />
        <circle
          id="Ellipse_50-37"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2012.438 8281.361)"
        />
        <circle
          id="Ellipse_50-38"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2035.438 8281.361)"
        />
        <circle
          id="Ellipse_50-39"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2058.438 8281.361)"
        />
        <circle
          id="Ellipse_50-40"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2081.438 8281.361)"
        />
        <circle
          id="Ellipse_50-41"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2104.438 8281.361)"
        />
        <circle
          id="Ellipse_50-42"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2127.438 8281.361)"
        />
        <circle
          id="Ellipse_50-43"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1828.438 8308.361)"
        />
        <circle
          id="Ellipse_50-44"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1851.438 8308.361)"
        />
        <circle
          id="Ellipse_50-45"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1874.438 8308.361)"
        />
        <circle
          id="Ellipse_50-46"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1897.438 8308.361)"
        />
        <circle
          id="Ellipse_50-47"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1920.438 8308.361)"
        />
        <circle
          id="Ellipse_50-48"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1943.438 8308.361)"
        />
        <circle
          id="Ellipse_50-49"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1966.438 8308.361)"
        />
        <circle
          id="Ellipse_50-50"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1989.438 8308.361)"
        />
        <circle
          id="Ellipse_50-51"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2012.438 8308.361)"
        />
        <circle
          id="Ellipse_50-52"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2035.438 8308.361)"
        />
        <circle
          id="Ellipse_50-53"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2058.438 8308.361)"
        />
        <circle
          id="Ellipse_50-54"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2081.438 8308.361)"
        />
        <circle
          id="Ellipse_50-55"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2104.438 8308.361)"
        />
        <circle
          id="Ellipse_50-56"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2127.438 8308.361)"
        />
        <circle
          id="Ellipse_50-57"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1828.438 8335.361)"
        />
        <circle
          id="Ellipse_50-58"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1851.438 8335.361)"
        />
        <circle
          id="Ellipse_50-59"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1874.438 8335.361)"
        />
        <circle
          id="Ellipse_50-60"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1897.438 8335.361)"
        />
        <circle
          id="Ellipse_50-61"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1920.438 8335.361)"
        />
        <circle
          id="Ellipse_50-62"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1943.438 8335.361)"
        />
        <circle
          id="Ellipse_50-63"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1966.438 8335.361)"
        />
        <circle
          id="Ellipse_50-64"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(1989.438 8335.361)"
        />
        <circle
          id="Ellipse_50-65"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2012.438 8335.361)"
        />
        <circle
          id="Ellipse_50-66"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2035.438 8335.361)"
        />
        <circle
          id="Ellipse_50-67"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2058.438 8335.361)"
        />
        <circle
          id="Ellipse_50-68"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2081.438 8335.361)"
        />
        <circle
          id="Ellipse_50-69"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2104.438 8335.361)"
        />
        <circle
          id="Ellipse_50-70"
          data-name="Ellipse 50"
          cx="3.5"
          cy="3.5"
          r="3.5"
          transform="translate(2127.438 8335.361)"
        />
      </g>
    </svg>
  )
}

export default withTheme(Morph)
