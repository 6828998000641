// @flow
import React, { useEffect, Suspense } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import PageComponents from './PageComponents'
import { HeaderNavbarContextWrapper } from '@contexts/HeaderNavBar.context'
import { LinkWrapperContext } from '@contexts'
import { getVisualParams, selectAllPagePaths } from '@selectors'
import { changeProjectPage } from '@actions/project'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from '@src/shared/globals'
import { useHistoryWithSearch } from '@hooks'
import useScroll from '@hooks/scroll'
import { projectContainerClassName } from '@website/conf/consts'
import ChooseComponent from '@containers/choose-component'
import ChooseTemplate from '@containers/choose-template'
import { EDIT_BASE } from '@editor/conf/routes'
import useSubscribeAutoTranslationSocket from '@hooks/useSubscribeAutoTranslationSocket'

const Settings = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "settings"*/ '../settings'
  )
)

const PageSettings = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "page-settings"*/ '../settings/contents/PageSettings'
  )
)

const Widgets = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "widgets"*/ '../widgets'
  )
)

const EventSettings = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ /* webpackChunkName: "event-settings"*/ '../event'
  )
)

const Pages = ({ pagePaths, changeProjectPage, visualParams }) => {
  const {
    params: { page },
    path
  } = useRouteMatch()
  const { url } = useRouteMatch(EDIT_BASE)
  const history = useHistoryWithSearch()

  const scrollableContainer = document.getElementsByClassName(
    projectContainerClassName
  )[0]

  useScroll(page, scrollableContainer)
  useSubscribeAutoTranslationSocket()

  // when page in route changed
  useEffect(() => {
    const pagePath = `/${page}`
    const activePageIdxByPath = pagePaths.indexOf(pagePath)

    if (activePageIdxByPath === -1) {
      history.replace(`${url}`)
      return
    }

    changeProjectPage(pagePath)
  }, [page, pagePaths.join('')])

  return (
    <HeaderNavbarContextWrapper>
      <LinkWrapperContext.Provider value={{ routeBase: EDIT_BASE }}>
        <ThemeProvider
          theme={{
            look: visualParams.look,
            palette: visualParams.palette,
            fontSize: visualParams.textSize,
            primaryFont: visualParams.primaryFont,
            secondaryFont: visualParams.secondaryFont
          }}
        >
          <>
            <GlobalStyle />
            <PageComponents />
            <>
              <Switch>
                <Route path={`${path}/settings`}>
                  <Suspense fallback={null}>
                    <Settings />
                  </Suspense>
                </Route>
                <Route path={`${path}/page-settings`}>
                  <Suspense fallback={null}>
                    <PageSettings />
                  </Suspense>
                </Route>
                <Route path={`${path}/widgets`}>
                  <Suspense fallback={null}>
                    <Widgets />
                  </Suspense>
                </Route>
                <Route path={`${path}/event-settings`}>
                  <Suspense fallback={null}>
                    <EventSettings />
                  </Suspense>
                </Route>
                <Route
                  path={`${path}/add-component/:idx`}
                  render={props => (
                    <ChooseComponent {...props} actionType="add" />
                  )}
                />
                <Route
                  path={`${path}/replace-component/:idx`}
                  render={props => (
                    <ChooseComponent {...props} actionType="replace" />
                  )}
                />
                <Route path={`${path}/choose-template`}>
                  <ChooseTemplate />
                </Route>
              </Switch>
            </>
          </>
        </ThemeProvider>
      </LinkWrapperContext.Provider>
    </HeaderNavbarContextWrapper>
  )
}

const mapStateToProps = (state: TAppState) => ({
  pagePaths: selectAllPagePaths(state),
  visualParams: getVisualParams(state)
})

export default connect(mapStateToProps, { changeProjectPage })(Pages)
