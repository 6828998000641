/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature30.theme'
import { styleProvider, getBackgroundStyles, getTextStyleByAlignment } from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 120px;
        `
      : css``};
  @media only screen and (max-width: 700px) {
    ${({ isEditing }) =>
    isEditing
      ? css`
            padding-top: 70px;
          `
      : css``};
  }
`

const WMCustomContainer = styled(WMContainer)`
  @media only screen and (max-width: 1440px) and (min-width: 1025px) {
    width: 95%;
    max-width: 1280px;
  }
`

const BlockItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 334px;
  margin: 80px 50px 0 50px;
  @media only screen and (max-width: 1440px) {
    margin: 80px 40px 0 40px;
  }
  @media only screen and (max-width: 1260px) {
    margin: 80px 20px 0 20px;
  }
  @media only screen and (max-width: 960px) {
    margin: 60px 0 0 0;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
  .control-container {
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
  > p:first-child,
  > div:first-child,
  > h6:first-child {
    margin-top: 0;
  }
  &:nth-child(4n + 1) {
    .figures-wrapper {
      div:first-child {
        border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
        background-color: ${getStyleForProp('firstIcon')};
      }
      div:nth-child(2) {
        border-radius: ${({ theme }) =>
    theme.look !== 'SHARP' ? '4px 4px 4px 100%' : '0 0 0 100%'};
        background-color: ${getStyleForProp('secondIcon')};
      }
      div:nth-child(3) {
        border-radius: 20px;
        background-color: ${getStyleForProp('thirdIcon')};
      }
      div:nth-child(4) {
        border-radius: ${({ theme }) =>
    theme.look !== 'SHARP' ? '4px 4px 20px 20px' : '0 0 20px 20px'};
        background-color: ${getStyleForProp('fourthIcon')};
      }
    }
  }
  &:nth-child(4n + 2) {
    .figures-wrapper {
      div:first-child {
        border-radius: ${({ theme }) =>
    theme.look !== 'SHARP' ? '20px 20px 4px 4px ' : '20px 20px 0 0 '};
        background-color: ${getStyleForProp('fourthIcon')};
      }
      div:nth-child(2) {
        border-radius: 20px;
        background-color: ${getStyleForProp('firstIcon')};
      }
      div:nth-child(3) {
        border-radius: ${({ theme }) =>
    theme.look !== 'SHARP' ? '4px 100% 4px 4px' : '0 100% 0 0'};
        background-color: ${getStyleForProp('thirdIcon')};
      }
      div:nth-child(4) {
        border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
        background-color: ${getStyleForProp('secondIcon')};
      }
    }
  }
  &:nth-child(4n + 3) {
    .figures-wrapper {
      div:first-child {
        border-radius: 20px;
        background-color: ${getStyleForProp('firstIcon')};
      }
      div:nth-child(2) {
        border-radius: ${({ theme }) =>
    theme.look !== 'SHARP' ? '4px 100% 4px 4px' : '0 100% 0 0'};
        background-color: ${getStyleForProp('thirdIcon')};
      }
      div:nth-child(3) {
        border-radius: ${({ theme }) =>
    theme.look !== 'SHARP' ? '20px 4px 4px 20px' : '20px 0 0 20px'};
        background-color: ${getStyleForProp('secondIcon')};
      }
      div:nth-child(4) {
        border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
        background-color: ${getStyleForProp('fourthIcon')};
      }
    }
  }
  &:nth-child(4n + 4) {
    .figures-wrapper {
      div:first-child {
        border-radius: ${({ theme }) =>
    theme.look !== 'SHARP' ? '20px 20px 4px 4px ' : '20px 20px 0 0 '};
        background-color: ${getStyleForProp('thirdIcon')};
      }
      div:nth-child(2) {
        border-radius: 20px;
        background-color: ${getStyleForProp('secondIcon')};
      }
      div:nth-child(3) {
        border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
        background-color: ${getStyleForProp('firstIcon')};
      }
      div:nth-child(4) {
        border-radius: ${({ theme }) =>
    theme.look !== 'SHARP' ? '4px 4px 100% 4px' : '0 0 100% 0'};
        background-color: ${getStyleForProp('fourthIcon')};
      }
    }
  }
  .control-container {
    align-items: initial !important;
  }
`
const FiguresWrapper = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 30px;
  grid-gap: ${({ isEditing }) => (isEditing ? '2px' : '1px')};
`

const Figure = styled.div`
  width: 40px;
  height: 40px;
`

const Title = styled.h2`
  color: ${getStyleForProp('heading')};
  margin: 0;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const BlocksWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin: -80px auto auto auto;
  @media only screen and (min-width: 1400px) {
    max-width: ${({ isFourBlock }) => (isFourBlock ? '1000px' : '')};
  }
  @media only screen and (max-width: 1100px) {
    align-items: center;
    flex-direction: ${props => {
    if (props.blockCount === 3) {
      return 'column'
    }
    return 'row'
  }};
  }
  @media only screen and (max-width: 960px) {
    margin-top: -60px;
    flex-direction: column;
    align-items: center;
  }
  .link-section {
    margin: 30px auto 0 auto;
  }
  .learn-more-link {
    color: ${getStyleForProp('anchor')};
    svg {
      fill: ${getStyleForProp('anchor')};
    }
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  BlockItemWrap,
  Title,
  Description,
  BlocksWrap,
  FiguresWrapper,
  Figure
}
