// @flow

import React, { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import moment from 'moment'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { FlyOut } from '@editor/common/lib/FlyOutMenu'
import ResetChanges from './ResetChanges'
import WebsiteStatus from './WebsiteStatus'
import ConnectDomainIcon from '@editor/common/assets/svgr-icons/Link_icon.svg'
import CrownIcon from '@editor/common/assets/crown_gear.svg'
import * as Styled from './styled'
import { UpgradePopupContext } from '@contexts'
import {
  isSubscriber,
  selectWasWebsiteMultilingual,
  selectSitePublishedDate,
  selectHasWebsiteEvent
} from '@selectors'
import type { TPublishOptionsProps } from './types'
import { useHistoryWithSearch } from '@hooks'
import { EDITOR_BASE, PAGE_BASE } from '@editor/conf/routes'

const PublishOptions = ({
  isOpen,
  anchorRef,
  isUserSubscribed,
  wasWebsiteMultilingual,
  hasWebsiteEvent,
  sitePublishedDate,
  onClose
}: TPublishOptionsProps) => {
  const { url } = useRouteMatch(EDITOR_BASE)
  const { url: urlForSettings } = useRouteMatch(PAGE_BASE) || {}
  const history = useHistoryWithSearch()
  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const goToSettings = () => {
    const _url = urlForSettings || `${url}/edit/home`
    history.push(`${_url}/settings/domain`)
  }

  const handleConnectDomainClick = () => {
    if (isUserSubscribed) {
      onClose()
      goToSettings()
    } else {
      setUpgradePopupState({ isOpen: true })
    }
  }

  return (
    <>
      <Styled.GlobalStyles />
      <FlyOut
        className="publish-options-flyout"
        animationPrefix="publish-options-flyout"
        alignment="right"
        isOpen={isOpen}
        anchorEl={anchorRef}
        onClose={onClose}
        backdropDisablingSelectors={[
          '.connect-domain-popup',
          '.publish-options-button',
          '.reset-changes-popup',
          '.global-error-popup'
        ]}
      >
        <WebsiteStatus />
        {!wasWebsiteMultilingual && !hasWebsiteEvent ? <ResetChanges /> : null}
        <Styled.PublishOptionItem
          withTopBorder
          onClick={handleConnectDomainClick}
        >
          <ConnectDomainIcon />
          <span>{translate('connect_a_domain')}</span>
          {!isUserSubscribed ? <Styled.CrownGear src={CrownIcon} /> : null}
        </Styled.PublishOptionItem>
        {sitePublishedDate ? (
          <Styled.PublishedDate withTopBorder>
            <span>
              {transformTextVariables(translate('last_published_on_label'), [
                {
                  value: moment(sitePublishedDate).format('DD.MM.YYYY, HH:mm ')
                }
              ])}
            </span>
          </Styled.PublishedDate>
        ) : null}
      </FlyOut>
    </>
  )
}

const mapStateToProps = state => ({
  isUserSubscribed: isSubscriber(state),
  sitePublishedDate: selectSitePublishedDate(state),
  wasWebsiteMultilingual: selectWasWebsiteMultilingual(state),
  hasWebsiteEvent: selectHasWebsiteEvent(state)
})

export default connect(mapStateToProps)(PublishOptions)
