/* @flow */

export const validation = {
  slogan: { required: true },
  name: { required: true },
  headingMaxChar: 30,
  paragraphMaxChar: 500,
  sloganMaxChar: 100,
  nameMaxChar: 50
}
