import styled, { css, keyframes } from 'styled-components'
import { primary } from '@website/common/styles/colors'

const BACKGROUND_COLOR = primary.Light

const contentLoaderKeyframes = keyframes`
  0% {
    background-color: rgba(238, 245, 255, 0.7);
  }
  50% {
    background-color: rgba(207, 224, 255);
  }
  100% {
    background-color: rgba(238, 245, 255, 0.7);
  }
`

const contentLoaderAnimation = css`
  animation: ${contentLoaderKeyframes} 3s infinite ease-in-out;
`

const mobileHeight = css`
  @media only screen and (max-width: 768px) {
    height: auto;
  }
`

export const ProjectLoader = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    & > div {
      flex-grow: 1;
    }
  }
`

export const FirstSection = styled.div`
  position: relative;
  width: 100%;
  height: 20%;
  display: flex;
`

export const TitleSkeleton = styled.div`
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  width: 35%;
  height: 25%;
  background: ${BACKGROUND_COLOR};
  ${contentLoaderAnimation};
`

export const DescriptionSkeleton = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 65%;
  height: 25%;
  background: ${BACKGROUND_COLOR};
  ${contentLoaderAnimation};
`

export const SecondSection = styled.div`
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: space-between;
`

export const LeftBlock = styled.div`
  width: 50%;
  height: 100%;
  position: relative;
  ${mobileHeight};
`

export const RightBlock = styled.div`
  width: 45%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .first-text-skeleton {
    top: 20%;
    width: 92%;
  }
  .second-text-skeleton {
    top: 26%;
    width: 85%;
  }
  .third-text-skeleton {
    top: 32%;
    width: 80%;
  }
  .fourth-text-skeleton {
    top: 38%;
    width: 94%;
  }
  ${mobileHeight};
`

export const ImageSkeleton = styled.div`
  position: absolute;
  top: 13%;
  left: 8%;
  width: 90%;
  height: 48%;
  background: ${BACKGROUND_COLOR};
  ${contentLoaderAnimation};
`

export const TextSkeleton = styled.div`
  position: absolute;
  height: 3%;
  background: ${BACKGROUND_COLOR};
  ${contentLoaderAnimation};
`

export const ButtonSkeleton = styled.div`
  position: absolute;
  top: 48%;
  height: 8%;
  width: 30%;
  background: ${BACKGROUND_COLOR};
  border-radius: 20px;
  left: 46%;
  transform: translateX(-46%);
  ${contentLoaderAnimation};
`
