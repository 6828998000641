/* @flow */
import meta from '../Header9.meta'
import {
  videoModalActionMaker,
  cropableImageModalActionMaker,
  imageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    //when src is video url
    mediaActions: (url, siteId) => {
      const showRemove = url !== meta.defaultProps.iframeVideoUrl
      return [
        ...imageModalActionMaker(dispatcher)({
          showRemove: false,
          siteId,
          keyForSizes: 'header9ImgSizes'
        }),
        ...videoModalActionMaker(dispatcher)(showRemove, url)
      ]
    },
    //when src is image url
    mediaActionsWithCrop: imageUrl => [
      ...cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'header9ImgSizes',
        showRemove: false,
        imageUrl
      }),
      ...videoModalActionMaker(dispatcher)(true)
    ]
  }
}
