/* @flow */
/* eslint-disable max-statements */

import {
  removeBlockActionMaker,
  iconActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addBlock,
  addIcon
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const removeImageBlock = idx => ({
    type: 'REMOVE_IMAGE_BLOCK',
    value: { idx }
  })

  const imageActions = (idx: number, sliderContent, imageUrl) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature28',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    }),
    {
      toShow:
        sliderContent.sliderTitle ||
        sliderContent.sliderParagraph ||
        sliderContent.blockTitle ||
        sliderContent.blockValue ||
        sliderContent.subBlocks.length !== 0,
      handler: () => dispatcher(removeImageBlock(idx)),
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label')
    }
  ]

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeMenuTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_MENU_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeSliderTitle = (newSliderTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDER_TITLE',
      value: { newSliderTitle, idx }
    })
  }

  const changeSliderParagraph = (newSliderParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDER_PARAGRAPH',
      value: { newSliderParagraph, idx }
    })
  }

  const changeSliderTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDER_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeSliderParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDER_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockValue = (newBlockValue: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_VALUE',
      value: { newBlockValue, idx }
    })
  }

  const addSubBlockItem = (activeIdx: number) => {
    dispatcher({
      type: 'ADD_SUBBLOCK_ITEM',
      value: activeIdx
    })
  }

  const removeSubBlockItem = (idx: number, activeIdx: number) => {
    dispatcher({
      type: 'REMOVE_SUBBLOCK_ITEM',
      value: { idx, activeIdx }
    })
  }

  const subBlockItemActions = (idx: number, activeIdx: number) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeSubBlockItem(activeIdx, idx)),
      toShow: true
    }
  ]

  const changeSubBlockTitle = (
    newSubBlockTitle: string,
    activeIdx: number,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_SUBBLOCK_TITLE',
      value: { newSubBlockTitle, activeIdx, itemIdx }
    })
  }

  const changeSubBlockValue = (
    newSubBlockValue: string,
    activeIdx: number,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_SUBBLOCK_VALUE',
      value: { newSubBlockValue, activeIdx, itemIdx }
    })
  }

  const addImageBlock = idx => {
    dispatcher({
      type: 'ADD_IMAGE_BLOCK',
      value: { idx }
    })
  }

  const _addIcon = idx => {
    dispatcher(addIcon(idx))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeSliderTitle,
    changeSliderParagraph,
    changeSliderTitleAlignment,
    changeSliderParagraphAlignment,
    changeBlockTitle,
    changeBlockValue,
    changeSubBlockTitle,
    changeSubBlockValue,
    subBlockItemActions,
    addSubBlockItem,
    changeMenuTitle,
    addImageBlock,
    addIcon: _addIcon,
    addBlock: () => dispatcher(addBlock()),
    imageActions,
    iconActions: idx => [
      ...iconActionMaker(dispatcher)({
        idx,
        showRemove: true
      })
    ],
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
