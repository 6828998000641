/* @flow */
import React from 'react'
import './Feature9.styles.sass'
import S from './Feature9.styled'
import { validation } from './Feature9.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { copyToClipboard, compose } from '@website/common/utils'
import BrowserBar from '@website/common/assets/Browser.svg'
import WMContainer from '@website/common/components/WMContainer'
import { withDispatchContext, withEditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
class Feature9 extends React.PureComponent<void, void> {
  onButtonClick = () => {
    const {
      data: { customCode }
    } = this.props
    copyToClipboard(customCode)
  }

  render() {
    const {
      data: {
        title,
        paragraph,
        customCode,
        buttonText,
        backgroundImgDimensions,
        backgroundImgUrl,
        bgOverlayTransparency
      },
      componentDispatcher,
      isEditing
    } = this.props

    const Actions = ActionGetter(componentDispatcher)
    return (
      <S.Container
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <WMContainer className="feature-with-code-container">
          <Show when={[title, paragraph]}>
            <div className="header-content">
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  as={S.Title}
                  maxCount={validation.headingMaxChar}
                  className="WM_GLOBAL_heading"
                  onChange={(newTitle: string) =>
                    componentDispatcher(Actions.changeTitle(newTitle))
                  }
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  as={S.Description}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph"
                  onChange={(newParagraph: string) =>
                    componentDispatcher(Actions.changeParagraph(newParagraph))
                  }
                />
              </Show>
            </div>
          </Show>
          <div className="webpage-wrap">
            <img src={BrowserBar} className="browser-bar" alt="browser bar " />
            <EditableContent
              as={S.Code}
              text={customCode}
              className="code-area"
              required={validation.customCode.required}
              onChange={(newCode: string) =>
                componentDispatcher(Actions.changeCode(newCode))
              }
            />
          </div>
          <S.BtnWrap>
            <ControlsWithModalOpener actions={Actions.btnActions(buttonText)}>
              <S.Button
                onClick={this.onButtonClick}
                role="button"
                className="WM_GLOBAL_secondary-font"
              >
                {buttonText}
              </S.Button>
            </ControlsWithModalOpener>
          </S.BtnWrap>
        </WMContainer>
      </S.Container>
    )
  }
}

const Feature9WithContexts = compose(
  withDispatchContext,
  withEditingContext
)(Feature9)

export default Feature9WithContexts
