/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Feature18.theme'
import {
  styleProvider,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  padding: ${({ isEditing }) => (isEditing ? '80px 0 0 0' : '0')};
  background: ${getStyleForProp('background')};
  position: relative;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    height: auto;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const WmCustomContainer = styled(WMContainer)`
  width: 100%;
  max-width: initial;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 !important;
  position: relative;
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
  .socials-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    z-index: 2;
    bottom: 20px;
    right: 20px;
    box-sizing: border-box;
    .styled-controls {
      right: 0;
      left: initial;
    }
  }
  .socials-wrapper {
    display: flex;
    flex-direction: column;
    .link {
      border: 1px solid ${getStyleForProp('socialColor')};
      fill: ${getStyleForProp('socialColor')};
      border-radius: ${getStyleForProp('iconRadius')};
    }
  }
  .slick-slider-container {
    margin: 0;
  }
  .slick-slide > div {
    margin: 0;
    height: 100%;
    font-size: 0;
    .slick-list {
      height: 100%;
    }
  }
  .mySlider {
    overflow: hidden;
    width: 100vw;
  }
  .dots-wrap {
    left: 50%;
    bottom: 30px;
    transform: translateX(-50%);
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    & + ${ControlContainer} {
      margin-bottom: 30px;
    }
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
    }
  }
`

const HeaderContentWrapper = styled.div`
  width: 48%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`
const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin: ${({ isEditing }) => (isEditing ? '0 0 16px 0' : '')};
`

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1023px) {
    padding: 80px 0;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 0;
  }
  ${({ isEditing }) =>
    css`
      ${isEditing
        ? css`
            ${Title} {
              margin: 0 0 16px 0;
            }
          `
        : css`
            ${Title} {
              margin: 0;
            }
          `}
    `}
`

const ImageWrapper = styled.div`
  width: 80%;
  max-width: 700px;
  @media only screen and (max-width: 1600px) {
    width: 70%;
  }
  @media only screen and (max-width: 1366px) {
    width: 60%;
  }
  @media only screen and (max-width: 1023px) {
    width: 80%;
    max-width: 600px;
  }
  @media only screen and (max-width: 900px) {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const TextContent = styled.div`
  position: absolute;
  left: 0;
`

const Paragraph = styled.p`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
  margin-top: 10px;
`

const SliderWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;

  .slick-list {
    padding-top: ${({ isEditing }) => (isEditing ? '20px' : '0')};
  }

  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`

const SliderItemWrapper = styled.div`
  padding: ${({ isEditing }) => (isEditing ? '50px 0 0 0' : '0')};
  height: 100%;
  position: relative;
  box-sizing: border-box;
  .control-container {
    height: 100%;
    width: 100%;
  }
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Content = styled.div`
  display: flex;
`

const AddButtonWrap = styled.div`
  position: absolute;
  left: 30px;
  bottom: 20px;
  z-index: 2;
  transition: all 0.1s linear;
  @media only screen and (max-width: 457px) {
    left: 20px;
  }
`

const Dot = styled.button`
  border: none;
  padding: 0;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: 1px solid #5690ff;
  }
`

export default {
  WmCustomContainer,
  SliderWrapper,
  SliderItemWrapper,
  HeaderContentWrapper,
  Content,
  ImageContainer,
  ImageWrapper,
  Img,
  Container,
  TextContent,
  Title,
  Paragraph,
  AddButtonWrap,
  Dot,
  ControlContainer
}
