/* @flow */
import styled, { css } from 'styled-components'
import THEME from './RSVP7.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getCardBGOverlayStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 80px 0;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 150px;
          @media only screen and (max-width: 768px) {
            padding-top: 130px;
          }
        `
      : ''};

  flex-direction: column;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 457px) {
    padding-bottom: 60px;
    padding-top: 60px;
  }
  .add-button-wrap {
    margin-bottom: 30px;
  }
`

export const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  @media only screen and (max-width: 457px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  &:nth-child(1) {
    margin-top: 0px;
  }
  .envelop-open-mode {
    margin-bottom: 0 !important;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
  & > .control-container {
    width: 100%;
  }
`

export const MainTitle = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0px;
  ${({ isEditing }) =>
    isEditing
      ? ''
      : css`
          & + div {
            margin-top: 232px !important;
            @media only screen and (max-width: 768px) {
              margin-top: 190px !important;
            }
            @media only screen and (max-width: 467px) {
              margin-top: 130px !important;
            }
          }
        `};
`

export const Postcard = styled.div`
  padding: 30px 0;
  box-sizing: border-box;
  border-top-left-radius: ${getStyleForProp('cardRadius')};
  border-top-right-radius: ${getStyleForProp('cardRadius')};
  border-bottom: none;
  ${({ isCardImage }) =>
    !isCardImage
      ? css`
          background-color: ${getStyleForProp('backgroundColor')};
        `
      : css``};
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 600px;
  }
  & > div {
    padding-top: 0;
  }
`

export const LetterWrapper = styled.div`
  width: 100%;
  max-width: 530px;
  min-height: 300px;
  position: relative;
  margin: 50px auto 38px;
  .envelope-main {
    border-radius: 0 0 12px 12px;
  }
  @media only screen and (min-width: 769px) {
    min-height: 500px;
    max-width: 716px;
    margin-bottom: 0px;
    .envelope-main {
      border-radius: 12px;
    }
    ${({ isEditing, isLetterOpened, isOpen, shouldShowCloseAnimation }) =>
      isEditing
        ? ''
        : (isLetterOpened && !shouldShowCloseAnimation) ||
          shouldShowCloseAnimation
        ? css`
            .envelope-top {
              animation-name: openLetter;
            }
            .envelope-main {
              border-radius: 0 0 12px 12px;
            }
          `
        : css``}
    ${({ isEditing, isLetterOpened, isOpen, shouldShowCloseAnimation }) =>
      isEditing
        ? ''
        : (isLetterOpened && !shouldShowCloseAnimation) ||
          (isOpen && shouldShowCloseAnimation)
        ? css`
            @media only screen and (min-width: 769px) {
              .letter-container {
                animation-name: viewLetter;
              }
            }
            .envelope-main {
              border-radius: 0 0 12px 12px;
            }
            @keyframes openLetter {
              0% {
                transform: rotateX(0deg);
                border-radius: 0;
              }
              25% {
                z-index: 12;
              }
              48% {
                z-index: 12;
                transform: rotateX(-140deg);
                border-radius: 0;
              }
              50% {
                z-index: 2;
              }
              100% {
                z-index: 2;
                transform: rotateX(-140deg);
                border-radius: 0;
              }
            }
            @keyframes viewLetter {
              0% {
                z-index: 4;
                transform: translateY(0);
                max-height: 400px;
              }
              10% {
                transform: translateY(0);
                z-index: 8;
                max-height: 400px;
              }
              60% {
                transform: translateY(calc(-100% + 190px));
              }
              100% {
                transform: translateY(calc(-100% + 200px));
                z-index: 8;
                max-height: 800px;
              }
            }
          `
        : ''}
    ${({ isOpen, shouldShowCloseAnimation }) =>
      isOpen && shouldShowCloseAnimation
        ? css`
            .letter-container {
              animation-name: closeViewLetter;
              animation-delay: 0.75s;
              animation-duration: 1.5s;
            }
            @keyframes closeLetter {
              0% {
                z-index: 2;
                transform: rotateX(-140deg);
                border-radius: 0px;
              }
              30% {
                z-index: 2;
              }
              31% {
                z-index: 12;
                transform: rotateX(-140deg);
                border-radius: 0px;
              }
              100% {
                transform: rotateX(0deg);
                z-index: 12;
                border-radius: 12px;
              }
            }
            @keyframes closeViewLetter {
              0% {
                z-index: 13;
                transform: translateY(-200px);
              }
              50% {
                transform: translateY(-300px);
                z-index: 10;
              }
              100% {
                z-index: 5;
                transform: translateY(calc(-100% + 825px));
                margin-bottom: -561px;
              }
            }
          `
        : ''}

    &.envelop-open-mode {
      @media only screen and (min-width: 769px) {
        animation-name: opened-envelop !important;
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
      }
      @keyframes opened-envelop {
        0% {
          transform: translateY(0px);
        }
        50% {
          transform: translateY(200px);
        }
        100% {
          transform: translateY(0px);
        }
      }
      ${({ isOpen, shouldShowCloseAnimation }) =>
        isOpen && !shouldShowCloseAnimation
          ? css`
              & .envelope-top {
                transform: rotateX(-140deg);
              }
            `
          : css``}
    }
  }
  @media only screen and (max-width: 457px) {
    margin-bottom: 0;
  }
  ${({ isEditing }) =>
    isEditing
      ? ''
      : css`
          margin-top: 272px !important;
          @media only screen and (max-width: 768px) {
            margin-top: 150px !important;
          }
          @media only screen and (max-width: 457px) {
            margin-top: 102px !important;
          }
        `};
  @media only screen and (max-width: 457px) {
    min-height: 250px;
  }
`
export const Envelope = styled.div`
  width: 100%;
  height: 367px;
  max-width: 716px;
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
  ${({ envelopeStyle }) =>
    envelopeStyle === 'kraft'
      ? css`
          background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp7-1.0.0.png');
        `
      : css`
          background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp7-4.0.0.png');
        `}

  margin: 0 auto;
  border-radius: 12px;
  overflow: hidden;

  ${({ isEditing }) => (isEditing ? '' : 'margin-bottom: -600px;')};
  @media only screen and (min-width: 769px) {
    min-height: 500px;
  }
  @media only screen and (max-width: 450px) {
    height: 250px;
  }
`

export const EnvelopeTop = styled.div`
  width: 100%;
  min-height: 300px;
  clip-path: polygon(51% 87%, 100% 51%, 100% 0, 0 0, 0 62%);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 12;
  ${({ isEditing }) =>
    isEditing
      ? css``
      : css`
          margin-bottom: -561px;
        `};
  transform-origin: top;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
  transition-timing-function: ease-in;
  ${({ envelopeStyle }) =>
    envelopeStyle === 'kraft'
      ? css`
          background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp7-3.1.0.png');
        `
      : css`
          background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp7-6.0.0.png');
        `}
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top;
  @media only screen and (min-width: 769px) {
    min-height: 500px;
    border-radius: 12px 12px 0 0;
    transform: rotateX(0deg);
    clip-path: polygon(51% 62%, 100% 51%, 100% 0, 0 0, 0 62%);
  }
  @media only screen and (max-width: 768px) {
    ${({ isEditing }) =>
      isEditing
        ? css``
        : css`
            transform: rotateX(140deg) !important;
          `};
  }
  @media only screen and (max-width: 457px) {
    top: 1px;
  }
`

export const EnvelopeFront = styled.div`
  width: 100%;
  height: 367px;
  ${({ envelopeStyle }) =>
    envelopeStyle === 'kraft'
      ? css`
          background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp7-2.0.0.png');
        `
      : css`
          background-image: url('https://i.ibb.co/NNg8PBJ/white-whole.png');
        `}
  clip-path: polygon(50% 40%, 50% 40%, 89% 0, 100% 0, 100% 100%, 0 100%, 0 0, 11% 0);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  ${({ isEditing }) => (isEditing ? '' : 'margin-bottom: -600px;')};
  @media only screen and (min-width: 769px) {
    height: 500px;
    border-radius: 0 0 12px 12px;
  }
  @media only screen and (max-width: 450px) {
    height: 250px;
  }
`

export const LetterContainer = styled.div`
  width: calc(100% - 60px);
  max-width: 600px;
  border-radius: ${getStyleForProp('cardRadius')};
  filter: drop-shadow(0 0 3px ${getStyleForProp('border')});
  margin: 0px auto 0;
  z-index: 1;
  position: relative;
  animation-duration: 1.5s;
  animation-delay: 0.42s;
  animation-fill-mode: forwards;
  ${({ isEditing }) =>
    isEditing
      ? css`
          margin-top: 30px;
          transform: translateY(0px) !important;
          margin-bottom: 0 !important;
        `
      : css`
          margin-top: 172px;
          overflow: hidden;
          @media only screen and (min-width: 769px) {
            max-height: 400px;
            margin-top: 130px;
          }
        `};
  ${({ isOpen, shouldShowCloseAnimation }) =>
    isOpen && !shouldShowCloseAnimation
      ? css`
          margin-bottom: -200px;
          @media only screen and (min-width: 769px) {
            animation-name: opened-letter !important;
            animation-delay: 0s !important;
            & .block-form-1 {
              animation-duration: 0.75s;
              animation-delay: 1.5s;
              animation-fill-mode: forwards;
              transition-timing-function: ease-in;
              animation-name: letter-folding-animation;
            }
            & .block-form-2 {
              animation-duration: 0.75s;
              animation-delay: 2.45s;
              animation-fill-mode: forwards;
              transition-timing-function: ease-in;
              animation-name: letter-folding-animation;
            }
          }
          @keyframes opened-letter {
            0% {
              transform: translateY(-200px);
              z-index: 5;
              max-height: 100%;
            }
            50% {
              transform: translateY(-400px);
              z-index: 5;
              overflow: initial;
            }
            100% {
              z-index: 30;
              transform: translateY(-200px);
              max-height: 100%;
              overflow: initial;
            }
          }
          @keyframes letter-folding-animation {
            0% {
              transform: perspective(208px) rotateX(-90deg);
              transform-origin: top;
            }
            10% {
              transform: perspective(208px) rotateX(-90deg);
            }
            70%,
            78% {
              transform: perspective(208px) rotateX(2deg);
              transform-origin: top;
            }
            100% {
              transform: perspective(208px) rotateX(0deg);
              transform-origin: top;
            }
          }
        `
      : ''}

  ${({ shouldShowCloseAnimation, areDynamicFildsHidden }) =>
    shouldShowCloseAnimation
      ? css`
          @media only screen and (min-width: 769px) {
            animation-name: close-opened-letter;
            animation-fill-mode: forwards;
            animation-delay: 2.25s;
            transform: translateY(-200px);
            z-index: 30;
            max-height: 100%;
            & .block-form-1 {
              animation-duration: 0.75s;
              animation-delay: 1.5s;
              animation-fill-mode: forwards;
              transition-timing-function: ease-in;
              animation-name: close-letter-folding-animation;
            }
            & .block-form-2 {
              animation-duration: 0.75s;
              animation-delay: 0.75s;
              animation-fill-mode: forwards;
              transition-timing-function: ease-in;
              animation-name: close-letter-folding-animation;
            }

            @keyframes close-opened-letter {
              0% {
                transform: translateY(-200px);
                z-index: 30;
                max-height: 100%;
                overflow: initial;
              }
              50% {
                transform: translateY(-400px);
                z-index: 30;
                overflow: initial;
              }
              70% {
                z-index: 5;
              }
              100% {
                z-index: 5;
                transform: translateY(0px);
                max-height: 100%;
              }
            }
            @keyframes close-letter-folding-animation {
              0% {
                transform: perspective(208px) rotateX(0deg);
                transform-origin: top;
              }
              100% {
                transform: perspective(208px) rotateX(-90deg);
                transform-origin: top;
              }
            }
          }
        `
      : ''}

  @media only screen and (max-width: 768px) {
    width: calc(100% - 40px);
    margin-bottom: 200px;
    transform: translateY(200px);
    z-index: 30;

    ${({ isEditing }) =>
      isEditing
        ? css`
            margin-top: 30px;
          `
        : css`
            transform: translateY(95px);
            margin-bottom: 95px !important;
          `};
  }
  @media only screen and (max-width: 457px) {
    ${({ isEditing }) =>
      isEditing
        ? css``
        : css`
            transform: translateY(110px);
            margin-bottom: 110px !important;
          `};
  }
`

export const SubTitle = styled.p`
  display: block;
  width: 100%;
  color: ${getStyleForProp('subTitle')};
  ${getTextStyleByAlignment};
  & + h1 {
    margin-top: 10px;
  }
  & + p {
    margin-top: ${({ isEditing }) => (isEditing ? '0' : '10px')};
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-top: ${({ isEditing }) => (isEditing ? '0' : '20px')};
  margin-bottom: 0px;
  word-break: break-word;
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

export const Date = styled.p`
  color: ${getStyleForProp('dateText')};
  display: block;
  width: 100%;
  ${({ isEditing }) => (isEditing ? '' : 'margin-top: 20px;')};
  ${getTextStyleByAlignment};
`

export const Location = styled.a`
  display: block;
  width: 100%;
  ${({ isEditing }) => (isEditing ? '' : 'margin-top: 15px;')};
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  a {
    word-break: break-all;
  }
`

export const ImageWrap = styled.div`
  font-size: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

export const ImageFigureBG = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  height: 100%;

  &::after {
    ${getCardBGOverlayStyles(THEME)};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-top-left-radius: ${getStyleForProp('cardRadius')};
    border-top-right-radius: ${getStyleForProp('cardRadius')};
  }
`

export const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  border-radius: ${getStyleForProp('iconRadius')};

  @media only screen and (max-width: 768px) {
    max-width: 100px;
  }
  @media only screen and (max-width: 375px) {
    max-width: 70px;
  }
  .LazyLoad {
    position: relative;
    padding-top: 100%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('iconRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

export const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-left-radius: ${getStyleForProp('cardRadius')};
  border-top-right-radius: ${getStyleForProp('cardRadius')};
`

export const ComponentWrapper = styled.div`
  .form-container {
    max-width: 600px;
    width: 100%; 
    box-sizing: border-box;
    border-top: none;
    margin: 0 auto;
    padding: 0;
    transform-origin: top;

    & .form-wrapper {
      padding: 0 !important;
          max-width: 600px;
          background-color: transparent;
    }

    .block-form-1 {
      margin-top: -1px;
      background-color: ${getStyleForProp('backgroundColor')};
      padding: 30px 30px 15px 30px !important;
      @media only screen and (max-width:457px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    }
    .block-form-2 {
      padding: 1px 30px 40px 30px;
      margin-top: -1px;
      border-bottom-left-radius: ${getStyleForProp('cardRadius')};
      background-color: ${getStyleForProp('backgroundColor')};
      clip-path: polygon(
                100% 0,
                100% calc(100% - 30px),
                calc(100% - 30px) 100%,
                0 100%,
                0 0
              );
              &:after {
                content: '';
                position: absolute;
                bottom: 0;
                right: 0;
                width: 30px;
                height: 30px;
                background-image: linear-gradient(
                  135deg,
                  ${getStyleForProp('gradientBackgroundColorTop')} 5%,
                  ${getStyleForProp('gradientBackgroundColorBottom')} 57%
                );
                border-radius: 6px 0 0 0;
                box-shadow: 0px 4px 6px ${getStyleForProp('border')};
              }
      @media only screen and (max-width:457px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
        button {
          margin-top: 15px;
        }
    }

    .control-container {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      border-bottom-left-radius: ${getStyleForProp('cardRadius')};
      border-bottom-right-radius: ${getStyleForProp('cardRadius')};
      & > div {
        border-bottom-left-radius: ${getStyleForProp('cardRadius')};
        border-bottom-right-radius: ${getStyleForProp('cardRadius')};
      }
    }
  }

    ${({ isEditing }) =>
      isEditing
        ? css`
            .form-container {
              margin-top: -6px;

              & > div > div {
                background-color: transparent !important;
                padding: 0 !important;
              }
            }
          `
        : css`
            .form-container {
              & > div {
                background-color: transparent !important;
                padding: 0 !important;
              }
            }
            .block-form-1 {
              @media only screen and (min-width: 769px) {
                transform: perspective(208px) rotateX(90deg);
              }
            }
            .block-form-2 {
              @media only screen and (min-width: 769px) {
                transform: perspective(208px) rotateX(90deg);
              }
            }
          `};

    ${({ shouldShowCloseAnimation }) =>
      shouldShowCloseAnimation
        ? css`
            .letter-container {
              margin-bottom: -200px;
            }
            .block-form-1 {
              @media only screen and (min-width: 769px) {
                transform: perspective(208px) rotateX(0deg);
              }
            }
            .block-form-2 {
              @media only screen and (min-width: 769px) {
                transform: perspective(208px) rotateX(0deg);
              }
            }
          `
        : css``}
  }
`
