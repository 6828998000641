import React from 'react'
import ReactDOM from 'react-dom'
import App from './src/App'
// import * as Sentry from '@sentry/react'
// import { Integrations } from '@sentry/tracing'
import 'core-js/stable'
import 'regenerator-runtime/runtime'

// if (NODE_ENV === 'production') {
//   Sentry.init({
//     dsn:
//       'https://20762ad903974e3384ab0219b5b696d9@o128789.ingest.sentry.io/5842850',
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1.0,
//     ignoreErrors: [
//       'ResizeObserver loop limit exceeded',
//       'Authorization required.'
//     ]
//   })
// }

const element = document.getElementById('root')
ReactDOM.render(<App />, element)
