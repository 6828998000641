/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer2.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '130px 0 50px 0' : '50px 0')};
  background-color: ${getStyleForProp('background')};
`

const FooterContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
  .add-button-wrap {
    min-width: 125px;
  }
`

const FooterContent = styled.div`
  min-width: 40%;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 800px) {
    justify-content: center;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo-wrapper {
    display: block;
    position: relative;
    @media only screen and (min-width: 500px) {
      margin-right: 20px;
    }
  }
  .add-button-wrap {
    margin-right: 20px;
  }
  .control-container {
    & > p {
      margin-right: 0 !important;
    }
  }
`

const FooterLogo = styled.img.attrs(({ _width }) => {
  return _width
    ? {
        style: { width: `${_width}px` }
      }
    : {}
})`
  height: auto;
  object-fit: contain;
  height: auto;
`

const Paragraph = styled.p`
  max-width: 236px;
  font-size: 12px;
  color: ${getStyleForProp('paragraph')};
  @media only screen and (min-width: 801px) {
    margin-right: 20px;
  }
  @media only screen and (max-width: 500px) {
    text-align: center;
    margin-top: 12px;
  }
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  color: ${getStyleForProp('paragraph')};
  text-align: right;
  &:only-child {
    width: 100%;
    text-align: right;
    @media only screen and (max-width: 800px) {
      text-align: center;
    }
  }
  @media only screen and (min-width: 801px) {
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            max-width: calc(100% - 210px);
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 0;
            }
          `
        : css`
            max-width: 100%;
          `}
  }
  @media only screen and (max-width: 800px) {
    margin-top: 40px;
    text-align: center;
  }
`

const LinkWrap = styled.div`
  position: relative;
  margin-left: 10px;
  max-width: 170px;
  &:hover {
    a {
      opacity: 0.6;
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          .control-container {
            margin-left: 10px;
          }
        `
      : css`
          .link-wrapper {
            margin-left: 10px;
          }
        `}

  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
    .control-container {
      margin-left: 0;
    }

    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
`

const CopyRightWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (min-width: 801px) {
    justify-content: flex-end;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
  }
  .control-container {
    margin-top: 60px;
    & > p {
      margin-top: 0;
    }
  }
`

export default {
  Container,
  FooterContentWrap,
  FooterContent,
  FooterLogo,
  Paragraph,
  Copyright,
  LinkWrap,
  CopyRightWrapper
}
