import styled from 'styled-components'

import { mediaMaxWidth } from '@editor/common/utils/styled'
import { secondary, white, primary } from '@website/common/styles/colors'
import { BREAKPOINT_800 } from '@containers/edit/consts'

export const Wrapper = styled.div`
  margin-left: 15px;
  display: flex;
  align-items: center;

  ${mediaMaxWidth(BREAKPOINT_800)`
    margin-left: 10px;
    flex-grow: 1;
  `}
`

export const Button = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${secondary.ExtraLight};
  background: ${({ isActive }) =>
    isActive
      ? white
      : `transparent
    linear-gradient(180deg, ${white} 0%, ${primary.ExtraLight} 100%) 0 0
    no-repeat padding-box`};
  box-sizing: border-box;
  margin: 0 15px;
  cursor: pointer;

  &:hover {
    background: ${white};
  }

  ${mediaMaxWidth(BREAKPOINT_800)`
    margin: 0;
  `}
`

export const Divider = styled.div`
  width: 1px;
  height: 30px;
  background-color: ${secondary.ExtraLight};

  ${mediaMaxWidth(BREAKPOINT_800)`
    display: none;
  `}
`
