// @flow

export const generateDuplicatedPagePath = (pagePaths, duplicatingPagePath) => {
  const isHomePage = duplicatingPagePath === '/'
  const _duplicatingPagePath = isHomePage ? '/home' : duplicatingPagePath

  const isPageExists = path => pagePaths.indexOf(path) !== -1

  let num = 0
  let predictedPagePath = isHomePage
    ? `${_duplicatingPagePath}_1`
    : _duplicatingPagePath

  while (isPageExists(predictedPagePath)) {
    num++
    predictedPagePath = `${_duplicatingPagePath}_${num}`
  }

  return predictedPagePath
}

export const validatePageName = (name: string): boolean => {
  const regExp = new RegExp(/[!@#$%'^&*/\\(),.?":;{}|\s<>`]/)
  return !regExp.test(name)
}
