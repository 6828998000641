/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Feature16_2.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 80px 0;
  background-color: ${getStyleForProp('background')};
  @media only screen and (max-width: 457px) {
    padding: 60px 0;
  }
`

const WMCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 20px;
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Figure = styled.div`
  font-size: 0;
  margin: 20px;
  box-sizing: border-box;
  position: relative;
  min-height: 260px;
  @media only screen and (max-width: 1270px) {
    margin: 10px;
  }
  &.big-image {
    min-height: 560px;
    @media only screen and (max-width: 660px) {
      min-height: 90vw;
    }
    @media only screen and (max-width: 457px) {
      min-height: 275px;
    }
  }
`

const AddButtonWrapper = styled.div`
  display: flex;
  margin: 20px auto;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  font-weight: 700;
  width: 100%;
  ${getTextStyleByAlignment};
  padding-top: 20px;
`
const FeaturesWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: -20px auto;
  padding-top: 50px;
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
  .withSubItems_0 {
    display: flex;
    align-item: flex-start;
    flex-wrap: wrap;
    width: 630px;
    @media only screen and (max-width: 1300px) {
      margin-bottom: 10px;
    }
    @media only screen and (max-width: 1300px) and (min-width: 1270px) {
      width: 580px;
    }
    @media only screen and (max-width: 660px) {
      width: 90vw;
    }
    @media only screen and (max-width: 457px) {
      flex-direction: column;
    }
    & > .item {
      width: 50%;
      @media only screen and (max-width: 457px) {
        width: 100%;
      }
      @media only screen and (max-width: 800px) {
        .figure {
          width: calc(100% - 20px);
          max-width: 500px;
          display: flex;
          align-items: stretch;
        }
      }
    }
  }
  .withSubItems_1 {
    display: flex;
    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }
    .item {
      width: 50%;
      @media only screen and (max-width: 800px) {
        width: 100%;
      }
    }
  }
`

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .LazyLoad {
    width: 100%;
    position: relative;
    padding-top: 100%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('borderRadius')};
  }
`

const Item = styled.div`
  position: relative;
  &:hover {
    .item-info {
      opacity: 1;
    }
    .item-title,
    .item-paragraph,
    .item-badge {
      transform: initial;
      opacity: 1;
    }
  }
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const BlockTitle = styled.p`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('blockTitle')};
  font-size: 20px;
  transform: ${props => (props.isEditing ? 'initial' : 'translateY(40px)')};
  opacity: ${props => (props.isEditing ? '1' : '0')};
  transition: all 0.2s ease-in;
  @media only screen and (max-width: 1024px) {
    opacity: 1;
    transform: initial;
  }
`

const BlockParagraph = styled.p`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('blockTitle')};
  width: 100%;
  font-size: 12px;
  font-weight: bold;
  font-weight: 400;
  margin: 8px 0 0 0;
  transform: ${props => (props.isEditing ? 'initial' : 'translateY(40px)')};
  opacity: ${props => (props.isEditing ? '1' : '0')};
  transition: all 0.25s ease-in;
  @media only screen and (max-width: 1024px) {
    opacity: 1;
    transform: initial;
  }
`

const Badge = styled.div`
  background: ${getStyleForProp('badgeBg')};
  font-size: 10px;
  padding: 4px 10px;
  margin: 8px 0 0 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${getStyleForProp('borderRadius')};
  transform: ${props => (props.isEditing ? 'initial' : 'translateY(20px)')};
  opacity: ${props => (props.isEditing ? '1' : '0')};
  @media only screen and (max-width: 1024px) {
    opacity: 1;
    transform: initial;
  }
  transition: all 0.25s ease-in;
`

const BadgeText = styled.p`
  color: ${({ theme }) => theme.palette.accentWhite};
  font-weight: bold;
`

const SubItem = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100% - 60px);
  flex-direction: ${props => (props.isEven ? 'row' : 'row-reverse')};
  @media only screen and (max-width: 1270px) {
    flex-direction: ${props => (props.isEven ? 'column' : 'column-reverse')};
    width: 100%;
  }
  & > .item {
    width: 630px;
    @media only screen and (max-width: 1300px) and (min-width: 1270px) {
      width: 580px;
    }
    @media only screen and (max-width: 660px) {
      width: 90vw;
    }
  }
`

const ItemInfo = styled.div`
  opacity: ${props => (props.isEditing ? '1' : '0')};
  display: ${({ withoutTexts }) => (withoutTexts ? 'none' : 'block')};
  @media only screen and (max-width: 1024px) {
    opacity: 1;
  }
  transition: all 0.1s ease-in;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px 20px 20px 20px;
  width: 100%;
  box-sizing: border-box;
  background: linear-gradient(to top, black, transparent);
  border-bottom-left-radius: ${getStyleForProp('borderRadius')};
  border-bottom-right-radius: ${getStyleForProp('borderRadius')};
  & > .control-container {
    width: 100%;
    margin-bottom: 10px;
    p {
      margin-top: 0;
    }
  }
`

export default {
  Container,
  WMCustomContainer,
  Figure,
  Title,
  Description,
  FeaturesWrap,
  RowWrapper,
  BlockTitle,
  BlockParagraph,
  AddButtonWrapper,
  Badge,
  BadgeText,
  Img,
  SubItem,
  ItemInfo,
  Item
}
