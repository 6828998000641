/* @flow */
import meta from '../Feature11.meta'
import { validation } from '../Feature11.consts'
import {
  iconActionMaker,
  imageModalActionMaker,
  buttonModalActionMaker
} from '@website/components/ActionMakers'
import {
  addButton,
  changeTitle,
  changeParagraph
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'
export default dispatcher => {
  const removeListItem = (idx: number) => ({
    type: 'REMOVE_LIST_ITEM',
    value: idx
  })

  const changeListItem = (newItem: string, idx: number) => ({
    type: 'CHANGE_LIST_ITEM',
    value: { newItem, idx }
  })

  const featureActions = (idx, ftLenght) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeListItem(idx)),
      toShow: ftLenght > validation.features.min
    }
  ]
  return {
    addButton,
    changeTitle,
    changeListItem,
    changeParagraph,
    featureActions,
    addNewItem: () => ({ type: 'ADD_NEW_ITEM' }),
    iconActions: iconActionMaker(dispatcher),
    btnActions: buttonModalActionMaker(dispatcher),
    imgActions: imageModalActionMaker(dispatcher)
  }
}
