/* @flow */
import {
  socialActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import { changeTitle, changeParagraph, changeTitleAlignment, changeParagraphAlignment } from '@website/components/CommonActions'

export default dispatcher => {
  const changeName = (newName: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_NAME',
      value: { newName, idx }
    })
  }

  const changePosition = (newPosition: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_POSITION',
      value: { newPosition, idx }
    })
  }

  const changeItemParagraph = (paragraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_ITEM_PARAGRAPH',
      value: { paragraph, idx }
    })
  }

  const addMember = () => {
    dispatcher({ type: 'ADD_MEMBER' })
  }

  const addSocial = (teamIdx: number) => {
    dispatcher({
      type: 'ADD_SOCIAL',
      value: teamIdx
    })
  }

  const imageActions = (teamIdx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'team4',
      showRemove: false,
      additionalArgs: { teamIdx },
      imageUrl
    })

  const removeBlock = (teamIdx: number) =>
    dispatcher({
      type: 'REMOVE_MEMBER',
      value: teamIdx
    })

  const socialActions = (
    socialIdx: number,
    socialUrl: string,
    teamIdx: number
  ) => socialActionMaker(dispatcher)(socialIdx, socialUrl, { teamIdx })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

    const _changeTitleAlignment = alignment => {
      dispatcher(changeTitleAlignment(alignment))
    }
  
    const _changeParagraphAlignment = alignment => {
      dispatcher(changeParagraphAlignment(alignment))
    }
  
    const changeNameAlignment = (alignment: string, idx: number) => {
      dispatcher({
        type: 'CHANGE_NAME_ALIGNMENT',
        value: { alignment, idx }
      })
    }
  
    const changePositionAlignment = (alignment: string, idx: number) => {
      dispatcher({
        type: 'CHANGE_POSITION_ALIGNMENT',
        value: { alignment, idx }
      })
    }
  
    const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
      dispatcher({
        type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
        value: { alignment, idx }
      })
    }  

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeName,
    addMember: () => dispatcher(addMember()),
    addSocial,
    changePosition,
    changeItemParagraph,
    removeBlock,
    imageActions,
    socialActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNameAlignment,
    changePositionAlignment,
    changeBlockParagraphAlignment
  }
}
