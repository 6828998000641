/* flow */

import React from 'react'
import Image from '@website/common/components/Image'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import S from './Slider1.styled'

const MainImage = ({ activeIndex, Actions, slides, stopAutoPlay }) => {
  const mainImg = slides[activeIndex] ? slides[activeIndex] : slides[0]
  return (
    <div className="slider-main-img-figure">
      <ControlsWithImageCropper
        style={{ height: '100%', width: '100%' }}
        keyForSizes="slider1"
        actions={Actions.getSliderActions({
          showRemove: false,
          additionalArgs: { idx: activeIndex },
          clickHandlers: {
            change: stopAutoPlay,
            crop: stopAutoPlay
          }
        })}
      >
        <Image
          as={S.MainSliderImg}
          asProps={{
            imgCropParams: mainImg.imgCropParams,
            className: 'slider-main-img'
          }}
          alt="Slider main image"
          defaultImgSrc={mainImg.imgUrl}
          sizes={mainImg.imgDimensions}
        />
      </ControlsWithImageCropper>
    </div>
  )
}
export default MainImage
