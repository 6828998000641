import React, { useMemo } from 'react'

const backFrames = [
  'M455,336Q422,422,336,411.5Q250,401,168.5,407Q87,413,63.5,331.5Q40,250,80,185Q120,120,185,65.5Q250,11,323.5,57Q397,103,442.5,176.5Q488,250,455,336Z',
  'M413.5,330Q410,410,330,412.5Q250,415,189,393.5Q128,372,92,311Q56,250,69.5,166.5Q83,83,166.5,50.5Q250,18,326.5,57.5Q403,97,410,173.5Q417,250,413.5,330Z',
  'M436.5,324Q398,398,324,442.5Q250,487,190.5,428Q131,369,112,309.5Q93,250,84,162.5Q75,75,162.5,47Q250,19,315.5,69Q381,119,428,184.5Q475,250,436.5,324Z',
  'M417.5,303Q356,356,303,421Q250,486,165,453Q80,420,77.5,335Q75,250,86.5,174Q98,98,174,94Q250,90,313.5,106.5Q377,123,428,186.5Q479,250,417.5,303Z',
  'M399.5,323.5Q397,397,323.5,435.5Q250,474,188,424Q126,374,99,312Q72,250,76,165Q80,80,165,74Q250,68,314.5,94.5Q379,121,390.5,185.5Q402,250,399.5,323.5Z',
  'M411.5,312.5Q375,375,312.5,409.5Q250,444,169.5,427.5Q89,411,89.5,330.5Q90,250,97,177Q104,104,177,52.5Q250,1,331.5,44Q413,87,430.5,168.5Q448,250,411.5,312.5Z',
  'M435.5,316Q382,382,316,419.5Q250,457,191,412.5Q132,368,96.5,309Q61,250,96,190.5Q131,131,190.5,80.5Q250,30,318,72Q386,114,437.5,182Q489,250,435.5,316Z'
]

const frontFrames = [
  'M427,334.5Q419,419,334.5,428.5Q250,438,184,410Q118,382,59,316Q0,250,54,179Q108,108,179,85.5Q250,63,312,94.5Q374,126,404.5,188Q435,250,427,334.5Z',
  'M437.5,337.5Q425,425,337.5,426.5Q250,428,179.5,409.5Q109,391,64,320.5Q19,250,66,181.5Q113,113,181.5,72.5Q250,32,329,62Q408,92,429,171Q450,250,437.5,337.5Z',
  'M412,314.5Q379,379,314.5,399Q250,419,189,395.5Q128,372,65,311Q2,250,39.5,163.5Q77,77,163.5,52Q250,27,325,63.5Q400,100,422.5,175Q445,250,412,314.5Z',
  'M446,326.5Q403,403,326.5,419Q250,435,179.5,413Q109,391,71,320.5Q33,250,80,188.5Q127,127,188.5,109Q250,91,319.5,101Q389,111,439,180.5Q489,250,446,326.5Z',
  'M415.5,324Q398,398,324,416.5Q250,435,189,403.5Q128,372,104.5,311Q81,250,92,176.5Q103,103,176.5,99.5Q250,96,333.5,89.5Q417,83,425,166.5Q433,250,415.5,324Z',
  'M419,337Q424,424,337,452Q250,480,175.5,439.5Q101,399,68.5,324.5Q36,250,68.5,175.5Q101,101,175.5,51Q250,1,330.5,45Q411,89,412.5,169.5Q414,250,419,337Z',
  'M396,310Q370,370,310,405Q250,440,192.5,402.5Q135,365,84.5,307.5Q34,250,54.5,162.5Q75,75,162.5,83Q250,91,317,103.5Q384,116,403,183Q422,250,396,310Z',
  'M427.5,322Q394,394,322,447Q250,500,165.5,459.5Q81,419,79,334.5Q77,250,101,187.5Q125,125,187.5,68Q250,11,305,75.5Q360,140,410.5,195Q461,250,427.5,322Z'
]

const shuffle = arr => {
  const shuffledArr = []
  arr.forEach(item => {
    let randomIdx = Math.floor(Math.random() * arr.length)
    while (shuffledArr[randomIdx]) {
      randomIdx = Math.floor(Math.random() * arr.length)
    }
    shuffledArr[randomIdx] = item
  })
  return shuffledArr
}

const getRandomAnimation = arr => {
  const randomFrames = shuffle(arr)
  randomFrames.push(randomFrames[0])
  return randomFrames.join(';')
}

export default ({ isAnimationEnabled, idx = 0 }) => {
  const nextBackFrameIdx = idx % backFrames.length
  const nextFrontFrameIdx = idx % frontFrames.length
  const backAnimation = isAnimationEnabled
    ? useMemo(() => getRandomAnimation(backFrames), [])
    : backFrames[nextBackFrameIdx]
  const frontAnimation = isAnimationEnabled
    ? useMemo(() => getRandomAnimation(frontFrames), [])
    : frontFrames[nextFrontFrameIdx]

  const duration = isAnimationEnabled ? '18s' : '0s'
  const key = isAnimationEnabled ? 'enabled' : 'disabled'

  return (
    <svg
      viewBox="0 0 485 485"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        margin: 'auto',
        background: 'none',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%'
      }}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%"></stop>
          <stop offset="100%"></stop>
        </linearGradient>
      </defs>
      <path id="blob" fill="#24AF4">
        <animate
          key={key}
          dur={duration}
          repeatCount="indefinite"
          attributeName="d"
          values={backAnimation}
        ></animate>
      </path>
      <path id="blob1" fill="url(#gradient)">
        <animate
          key={key}
          dur={duration}
          repeatCount="indefinite"
          attributeName="d"
          values={frontAnimation}
        ></animate>
      </path>
    </svg>
  )
}
