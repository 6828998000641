/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const imageActions = (idx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'header26',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    imageActions,
    changeTitleAlignment: _changeTitleAlignment
  }
}
