//@flow

import { addScriptToHtml } from '@website/common/utils/index'
import { connectChatScript } from './live-chats'

// FB Pixel
const getFbPixelScriptHtml = (fbId: number) => `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window,document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${fbId}');
  fbq('track', 'PageView');
`

const getFbPixelNoScriptHtml = (fbId: number) =>
  `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${fbId}&ev=PageView&noscript=1"/>`

export const connectFbPixelScript = (facebookPixelCode: string) => {
  const scriptHtml = getFbPixelScriptHtml(facebookPixelCode)
  const noScriptHtml = getFbPixelNoScriptHtml()
  addScriptToHtml(scriptHtml)
  const noScript = document.createElement('noscript')
  noScript.innerHTML = noScriptHtml
  document.body.appendChild(noScript)
}

export const getFbPixelScript = (facebookPixelCode: string) => {
  const scriptHtml = getFbPixelScriptHtml(facebookPixelCode)
  const noScriptHtml = getFbPixelNoScriptHtml()
  return `
    <script type="text/javascript">${scriptHtml}</script>
    <noscript>${noScriptHtml}</noscript>
  `
}
// end of FB Pixel

// Google Analytics
const getGaScriptHtml = (gaId: string) => `
(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', '${gaId}', 'auto');
  ga('send', 'pageview');
`

export const connectGaScript = (gaId: string) => {
  const scriptHtml = getGaScriptHtml(gaId)
  addScriptToHtml(scriptHtml)
}

export const getGaScript = (gaId: string) => {
  const scriptHtml = getGaScriptHtml(gaId)
  return `<script type="text/javascript">${scriptHtml}</script>`
}
// end of Google Analytics

// Google Tags
const getGtagScriptHtml = (gtagId: string) => `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  
  gtag('config', '${gtagId}', { 'send_page_view': false });
`

export const connectGtagScript = (gtagId: string) => {
  const scriptHtml = getGtagScriptHtml(gtagId)
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`
  document.head.appendChild(script)
  addScriptToHtml(scriptHtml)
}

export const getGtagScript = (gtagId: string) => {
  const scriptHtml = getGtagScriptHtml(gtagId)
  return `
  <script async src="https://www.googletagmanager.com/gtag/js?id=${gtagId}"></script>
  <script type="text/javascript">${scriptHtml}</script>
`
}

// end of Google Tags

// Google Tag Manager

const getGTMScriptHtml = (containerId: string) => `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${containerId}');
`

export const getGTMScriptByCookies = (containerId: string) => `
<script>
  const { cookieSettings } = window.localStorage
  if (cookieSettings) {
    const { marketing } = JSON.parse(cookieSettings)
    if(marketing) {
      ${getGTMScriptHtml(containerId)}
    }
  }
</script>
`

export const getGTMScript = (containerId: string) => `
<script>
${getGTMScriptHtml(containerId)}
</script>
`

export const getGTMNoScript = containerId => `
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
`

// end of google tag manager

export const connectAdSenseScript = (publisherId: string) => {
  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('crossorigin', 'anonymous')
  script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-${publisherId}`
  document.head.appendChild(script)
}

export const getAdSenseScript = (publisherId: string) => `
     <script data-ad-client="ca-${publisherId}" async src="https://pagead2.googlesyndication.com/
pagead/js/adsbygoogle.js"></script>
`

// Yandex Metrica
export const getYMScript = (ymId: string) => `
   (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(${ymId}, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true
   });
`

const getYMNoScriptHtml = (ymId: string) =>
  `<div><img src="https://mc.yandex.ru/watch/${ymId}" style="position:absolute; left:-9999px;" alt="" /></div>`

export const connectYMScript = (ymId: string) => {
  const scriptHtml = getYMScript(ymId)
  const noScriptHtml = getYMNoScriptHtml(ymId)

  addScriptToHtml(scriptHtml)
  const noScript = document.createElement('noscript')
  noScript.innerHTML = noScriptHtml
  document.body.appendChild(noScript)
}

export const connectExternalScriptsByCookies = ({
  chatsData,
  facebookPixelCode,
  gtagId,
  adSensePublisherId,
  ymId
}) => {
  const { cookieSettings } = window.localStorage
  if (!cookieSettings) {
    return
  }
  const { analytics, marketing, functional } = JSON.parse(cookieSettings) // functional cookies are not used yet

  const listener = () => {
    if (analytics) {
      facebookPixelCode && connectFbPixelScript(facebookPixelCode)
      gtagId && connectGtagScript(gtagId)
      ymId && connectYMScript(ymId)
    }
    if (marketing) {
      connectChatScript(chatsData)
      adSensePublisherId && connectAdSenseScript(adSensePublisherId)
    }
  }

  // Add event listener for mouse/touch events to load external scripts only on user interaction, improving website performance.
  document.addEventListener('mousemove', listener, { once: true })
  document.addEventListener('touchmove', listener, { once: true })
}

export const connectExternalScripts = ({
  chatsData,
  facebookPixelCode,
  gtagId,
  adSensePublisherId,
  ymId
}) => {
  const listener = () => {
    facebookPixelCode && connectFbPixelScript(facebookPixelCode)
    gtagId && connectGtagScript(gtagId)
    ymId && connectYMScript(ymId)
    connectChatScript(chatsData)
    adSensePublisherId && connectAdSenseScript(adSensePublisherId)
  }

  // Add event listener for mouse/touch events to load external scripts only on user interaction, improving website performance.
  document.addEventListener('mousemove', listener, { once: true })
  document.addEventListener('touchmove', listener, { once: true })
}
