/* @flow */
import React, { PureComponent } from 'react'
import { Figure, StyledImg } from './styled'
import { getClassName } from './utils'
import { _debounce } from '@website/common/utils'

import type { TImgWithCropParams } from './type.js'
class ImgWithCropParams extends PureComponent<TImgWithCropParams, *> {
  state = {
    style: {}
  }
  _setStyles = () => this.setState({ style: this.getImgStyles() })

  componentDidMount() {
    const debouncedImgSourceSetter = _debounce(this._setStyles, 400)
    window.addEventListener('resize', debouncedImgSourceSetter)
    this._setStyles()
  }

  componentDidUpdate(prevProps) {
    const { asProps: additionalProps } = this.props
    if (!additionalProps) {
      return
    }
    const { asProps: prevAdditionalProps } = prevProps
    const { imgCropParams } = additionalProps
    //apply new img crop params
    if (prevAdditionalProps.imgCropParams !== imgCropParams) {
      this._setStyles()
    }
  }

  getImgStyles = () => {
    const { asProps: additionalProps } = this.props
    if (!additionalProps) {
      return {}
    }
    const { imgCropParams } = additionalProps
    if (
      this.imgContainerRef &&
      imgCropParams &&
      Object.keys(imgCropParams).length
    ) {
      const imgWidthCoef =
        this.imgContainerRef.offsetWidth / imgCropParams.containerWidth
      const { width, left, top, transform } = imgCropParams
      const style = {
        width: width * imgWidthCoef,
        left: left * imgWidthCoef,
        top: top * imgWidthCoef,
        transform: `rotate(${transform}deg)`,
        height: 'auto',
        position: 'absolute'
      }
      return style
    }
    return {}
  }

  handleContainerRef = ref => {
    this.imgContainerRef = ref
  }

  sendImgContainerSizes = e => {
    const { asProps: additionalProps } = this.props
    this._setStyles()
    if (additionalProps && additionalProps.onAnimationEnd) {
      return additionalProps.onAnimationEnd(
        {
          width: this.imgContainerRef.offsetWidth,
          height: this.imgContainerRef.offsetHeight
        },
        e
      )
    }
  }

  render() {
    const {
      src,
      loaded,
      alt = 'default src',
      as: Img = StyledImg,
      asProps: additionalProps
    } = this.props
    const { style } = this.state

    return (
      <Figure className="lazy-figure" ref={this.handleContainerRef}>
        <Img
          {...additionalProps}
          {...this.props}
          style={{
            ...style
          }}
          src={src}
          alt={alt}
          onAnimationEnd={this.sendImgContainerSizes}
          className={getClassName(loaded, additionalProps)}
        />
      </Figure>
    )
  }
}

export default ImgWithCropParams
