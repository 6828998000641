//@flow

const hasWebsiteGDPR = data => !!data.cookieSettings?.gdpr?.isVisible

const hasWebsiteEmail = data => !!data.meta?.email

const hasWebsiteAnalyticsIds = data => {
  const {
    googleAdSenseId,
    googleAnalyticsId,
    googleTagManagerId,
    authDigitalSellersContent
  } = data?.meta || {}

  return !!(
    googleAdSenseId ||
    googleAnalyticsId ||
    googleTagManagerId ||
    authDigitalSellersContent
  )
}

const hasWebsiteCustomDomain = data => !!data.domain

const hasWebsitePagesMetas = ({ data }) => {
  return !!data?.pages.find(
    ({ meta }) => meta.title || meta.keywords || meta.description
  )
}

const hasWebsiteLanguages = data => data.length > 1

export const hasWebsitePremiumFeatures = (
  projectData,
  siteData,
  websiteLanguagesData
) => {
  return (
    hasWebsiteGDPR(projectData) ||
    hasWebsiteCustomDomain(siteData) ||
    hasWebsiteEmail(projectData) ||
    hasWebsiteAnalyticsIds(projectData) ||
    hasWebsitePagesMetas(projectData) ||
    hasWebsiteLanguages(websiteLanguagesData)
  )
}
