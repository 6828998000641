import { hexToRgb } from '@website/common/utils'

export const PRIMARY = {
  ROUNDED: {
    LIGHT: {
      normal: p =>
        `color: ${p.accentWhite}; background: linear-gradient(-180deg, ${
          p.primaryLight
        } 0%, ${p.primaryDark} 96%); box-shadow: 0 4px 10px 0 rgba(${hexToRgb(
          p.primaryDark
        )}, 0.5)`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${
          p.primaryLight
        }; box-shadow: 0 4px 10px 0 rgba(${hexToRgb(p.primaryDark)},0.5)`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.primaryDark}; box-shadow: none;`
    },
    DARK: {
      normal: p =>
        `color: ${p.accentWhite}; background: linear-gradient(-180deg, ${p.primaryLight} 0%, ${p.primaryDark} 96%); box-shadow: none;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.primaryDark}; box-shadow: none;`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.primaryLight}; box-shadow: none;`
    }
  },
  CORNERED: {
    LIGHT: {
      normal: p =>
        `color: ${p.accentWhite}; background: linear-gradient(-180deg, ${
          p.primaryLight
        } 0%, ${p.primaryDark} 96%); box-shadow: 0 2px 6px 0 rgba(${hexToRgb(
          p.primaryDark
        )},0.5)`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${
          p.primaryLight
        }; box-shadow: 0 2px 6px 0 rgba(${hexToRgb(p.primaryDark)},0.5)`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.primaryDark}; box-shadow: none;`
    },
    DARK: {
      normal: p =>
        `color: ${p.accentWhite}; background: linear-gradient(-180deg, ${p.primaryLight} 0%, ${p.primaryDark} 96%); box-shadow: none;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.primaryDark}; box-shadow: none;`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.primaryLight}; box-shadow: none;`
    }
  },
  SHARP: {
    LIGHT: {
      normal: p =>
        `color: ${p.accentWhite}; background: ${p.primaryDark}; border: 1px solid transparent;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.primaryLight}; border: 1px solid transparent;`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.primaryDark}; border: 1px solid transparent;`
    },
    DARK: {
      normal: p =>
        `color: ${p.accentWhite}; background: ${p.primaryLight}; border: 1px solid transparent;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.primaryDark}; border: 1px solid transparent;`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.primaryLight}; border: 1px solid transparent;`
    }
  }
}

export const SECONDARY = {
  ROUNDED: {
    LIGHT: {
      normal: p =>
        `color: ${p.accentWhite}; background: linear-gradient(-180deg, ${
          p.secondaryLight
        } 0%, ${p.secondaryDark} 96%); box-shadow: 0 4px 10px 0 rgba(${hexToRgb(
          p.secondaryDark
        )},0.5)`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${
          p.secondaryLight
        }; box-shadow: 0 4px 10px 0 rgba(${hexToRgb(p.secondaryDark)},0.5)`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryDark}; box-shadow: none;`
    },
    DARK: {
      normal: p =>
        `color: ${p.accentWhite}; background: linear-gradient(-180deg, ${p.secondaryLight} 0%, ${p.secondaryDark} 96%); box-shadow: none;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryDark}; box-shadow: none;`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryLight}; box-shadow: none;`
    }
  },
  CORNERED: {
    LIGHT: {
      normal: p =>
        `color: ${p.accentWhite}; background: linear-gradient(-180deg, ${
          p.secondaryLight
        } 0%, ${p.secondaryDark} 96%); box-shadow: 0 2px 6px 0 rgba(${hexToRgb(
          p.secondaryDark
        )},0.5)`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${
          p.secondaryLight
        }; box-shadow: 0 2px 6px 0 rgba(${hexToRgb(p.secondaryDark)},0.5)`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryDark}; box-shadow: none;`
    },
    DARK: {
      normal: p =>
        `color: ${p.accentWhite}; background: linear-gradient(-180deg, ${p.secondaryLight} 0%, ${p.secondaryDark} 96%); box-shadow: none;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryDark}; box-shadow: none;`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryLight}; box-shadow: none;`
    }
  },
  SHARP: {
    LIGHT: {
      normal: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryDark}; border: 1px solid transparent;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryLight}; border: 1px solid transparent;`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryDark}; border: 1px solid transparent;`
    },
    DARK: {
      normal: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryLight}; border: 1px solid transparent;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryDark}; border: 1px solid transparent;`,
      active: p =>
        `color: ${p.accentWhite}; background: ${p.secondaryLight}; border: 1px solid transparent;`
    }
  }
}

export const TERTIARY = {
  ROUNDED: {
    LIGHT: {
      normal: p =>
        `color: ${p.tertiaryNormal}; background: linear-gradient(-180deg, ${
          p.tertiaryExtraLight
        } 0%, ${p.tertiaryLight} 96%); box-shadow: 0 4px 10px 0 rgba(${hexToRgb(
          p.tertiarySemiLight
        )},0.3)`,
      hover: p =>
        `color: ${p.tertiaryNormal}; background: ${
          p.tertiaryExtraLight
        }; box-shadow: 0 4px 10px 0 rgba(${hexToRgb(p.tertiarySemiLight)},0.3)`,
      active: p =>
        `color: ${p.tertiaryNormal}; background: ${p.tertiaryLight}; box-shadow: none;`
    },
    DARK: {
      normal: p =>
        `color: ${p.tertiaryNormal}; background: linear-gradient(-180deg, ${p.tertiaryExtraLight} 0%, ${p.tertiaryLight} 96%); box-shadow: none;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.tertiarySemiLight}; box-shadow: none;`,
      active: p =>
        `color: ${p.tertiaryNormal}; background: ${p.tertiaryLight}; box-shadow: none;`
    }
  },
  CORNERED: {
    LIGHT: {
      normal: p =>
        `color: ${p.tertiaryNormal}; background: linear-gradient(-180deg, ${
          p.tertiaryExtraLight
        } 0%, ${p.tertiaryLight} 96%); box-shadow: 0 2px 6px 0 rgba(${hexToRgb(
          p.tertiarySemiLight
        )},0.3)`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${
          p.tertiarySemiLight
        }; box-shadow: 0 2px 6px 0 rgba(${hexToRgb(p.tertiarySemiLight)},0.3)`,
      active: p =>
        `color: ${p.tertiaryNormal}; background: ${p.tertiaryLight}; box-shadow: none;`
    },
    DARK: {
      normal: p =>
        `color: ${p.tertiaryNormal}; background: linear-gradient(-180deg, ${p.tertiaryExtraLight} 0%, ${p.tertiaryLight} 96%); box-shadow: none;`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.tertiarySemiLight}; box-shadow: none;`,
      active: p =>
        `color: ${p.tertiaryNormal}; background: ${p.tertiaryLight}; box-shadow: none;`
    }
  },
  SHARP: {
    LIGHT: {
      normal: p =>
        `color: ${p.tertiaryNormal}; background: transparent; border: 1px solid ${p.tertiarySemiLight};`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.tertiarySemiLight}; border: 1px solid transparent;`,
      active: p =>
        `color: ${p.tertiaryNormal}; background: transparent; border: 1px solid ${p.tertiarySemiLight};`
    },
    DARK: {
      normal: p =>
        `color: ${p.tertiaryLight}; background: transparent; border: 1px solid ${p.tertiarySemiLight};`,
      hover: p =>
        `color: ${p.accentWhite}; background: ${p.tertiarySemiLight}; border: 1px solid transparent;`,
      active: p =>
        `color: ${p.tertiaryLight}; background: transparent; border: 1px solid ${p.tertiarySemiLight};`
    }
  }
}
