/* @flow */
import styled from 'styled-components'
import THEME from './SubmitButton.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
`

const Button = styled.button`
  max-width: 300px;
  border: none;
  border-radius: ${getStyleForProp('buttonRadius')};
  white-space: nowrap;
  ${getStyleForProp('buttonShadow')};
  ${getStyleForProp('buttonBackground')};
  overflow: hidden;
  text-overflow: ellipsis;
  background-repeat: no-repeat;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0 40px;
  font-size: 14px;
  letter-spacing: 0.4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  word-break: initial;
  outline: none;
  &:focus {
    outline: none;
  }
`

const WhiteCheckIcon = styled.img`
  position: absolute;
  width: 8px;
  right: 16px;
  top: 18px;
  animation: scale 0.3s linear forwards;
  animation-delay: 1s;
  opacity: 0;
  @keyframes scale {
    90% {
      opacity: 1;
      transform: scale(1.1);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`

const ErrorSignWrapper = styled.div`
  position: absolute;
  z-index: 4;
  right: 5px;
  top: 5px;
  animation: disappear 1s linear forwards;
  animation-delay: 8s;
  opacity: 1;
  @keyframes disappear {
    to {
      opacity: 0;
    }
  }
  svg {
    transform: rotate(-90deg);
  }
  circle {
    fill: none;
    stroke: white;
    stroke-width: 1px;
    stroke-dasharray: 314.16px;
    stroke-dashoffset: 314.16px;
    animation: draw 4s linear forwards;
  }

  @keyframes draw {
    to {
      stroke-dashoffset: 0px;
    }
  }
`

export default {
  ButtonContainer,
  Button,
  WhiteCheckIcon,
  ErrorSignWrapper
}
