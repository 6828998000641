/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'REMOVE_PORTFOLIO_IMG':
        draft.portfolioImages.splice(action.value, 1)
        break
      case 'ADD_IMAGE':
        draft.portfolioImages.push(initialState.portfolioImages[0])
        break
      case 'CHANGE_PORTFOLIO_IMG':
        {
          const { idx, url, dimensions, sizes } = action.value
          draft.portfolioImages[idx].imgUrl = url
          draft.portfolioImages[idx].imgDimensions = dimensions
          draft.portfolioImages[idx].imgSizes = sizes
        }
        break
    }
  })
}
