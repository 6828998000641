import styled, { createGlobalStyle, css } from 'styled-components'

const SLIDE_SIZES = {
  large: 170,
  medium: 120,
  small: 60
}

const getItemSizes = ({
  offsetFromCenter,
  sizes = SLIDE_SIZES,
  additionalOffset = 30
}) => {
  const { large, medium, small } = sizes
  switch (offsetFromCenter) {
    case 0:
      return css`
        transform: scale(1);
      `
    case NaN:
      return css`
        transform: scale(${medium / large});
      `
    case 1:
    case -1:
      const xCord = offsetFromCenter * additionalOffset
      return css`
        transform: scale(${medium / large}) translateX(${xCord}px);
      `
    default:
      return css`
        transform: scale(${small / large});
      `
  }
}

export const ItemWrapper = styled.div`
  height: ${({ height }) => `${height}px`};
  display: flex !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export const Item = styled.div`
  ${({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
    min-width: ${width}px;
  `};
  display: flex;
  .control-container {
    width: 100%;
  }
  .lazy-figure {
    height: 100%;
    width: 100%;
  }
  ${getItemSizes};
  transition: ${({ animationDuration }) => {
    const duration = animationDuration / 1000
    return css`
      transform ${duration}s
    `
  }};
`

export const GlobalStyles = createGlobalStyle`
  .team6-slider {
    .slick-arrow {
      display: block !important;
      font-size: inherit;
      width: 36px;
      height: 36px;
      line-height: 1;
      z-index: 2;
      &:before {
        display: none;
      }
    }
    .slick-prev {
      & > button > div {
        transform: translateX(-1px);
      }
    }
    .slick-next {
      & > button > div {
        transform: translateX(1px);
      }
    }
    @media only screen and (min-width: 1280px) {
      .slick-prev {
        left: -36px;
      }
      .slick-next {
        right: -36px;
      }
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
    }
    .slick-slide > div {
      margin: 50px 0;
    }
  }
`
