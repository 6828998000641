/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import { changeTitle } from '@website/components/CommonActions'

export default dispatcher => {
  const changeSlideContent = (newContent: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_CONTENT',
      value: { newContent, idx }
    })
  }

  const changeSlideTooltipText = (newTooltipText: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TOOLTIP_TEXT',
      value: { newTooltipText, idx }
    })
  }

  const changeSlideTitle = (newSlideTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TITLE',
      value: { newSlideTitle, idx }
    })
  }

  const changeSlideImage = (url: string, idx: number, dimensions: string) => {
    dispatcher({
      type: 'CHANGE_IMAGE',
      value: { url, idx, dimensions }
    })
  }

  const sliderActions = (idx, imageUrl) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'steps7',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    })

  const addImageBlock = idx => {
    dispatcher({
      type: 'ADD_IMAGE_BLOCK',
      value: { idx }
    })
  }

  const addSlide = () => {
    dispatcher({ type: 'ADD_SLIDE' })
  }

  const removeSlide = idx => {
    dispatcher({ type: 'REMOVE_SLIDE', value: idx })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const changeTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeTooltipAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TOOLTIP_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeContentAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_CONTENT_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addSlide,
    changeTitle: _changeTitle,
    removeSlide,
    changeSlideTitle,
    changeSlideContent,
    changeSlideTooltipText,
    getSliderActions: sliderActions,
    addImageBlock,
    changeTitleAlignment,
    changeTooltipAlignment,
    changeContentAlignment
  }
}
