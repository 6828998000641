/* @flow */
import styled from 'styled-components'

export const SwitcherContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 160px;
  padding: 9px 10px;
  height: 42px;
  background-color: #ffffff;
  border: 1px solid #c1c9e0;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 6px 12px 0 #3271e626;

  > div {
    background-color: ${({ checked }) => (checked ? '#545F7E' : '#3271E6')};
    margin: 0 10px;
    flex-grow: 1;
  }
`

export const ColorModeText = styled.span`
  font-size: 14px;
  line-height: 40px;
  color: ${({ isChecked }) => (isChecked ? '#252e48' : '#545f7e')};
  font-weight: 600;
  cursor: pointer;
`
