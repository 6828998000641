import styled, { css } from 'styled-components'
import RoundedCheckIcon from '@editor/common/assets/svgr-icons/Rounded_check.svg'

const commonPseudoElementStyles = css`
  content: '';
  position: absolute;
  height: 1px;
  background-color: ${({ color }) => color};
`

const commonPseudoElementWidth = css`
  width: calc(50% - 16px);
`

export const Stepper = styled.div`
  display: flex;
  width: 100%;
`

export const Step = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ stepsCount }) => `calc(100% / ${stepsCount})`};

  &:before {
    ${commonPseudoElementStyles};
  }

  &:not(:first-child):not(:last-child) {
    &:before {
      ${commonPseudoElementWidth};
      left: 0;
    }
    &:after {
      ${commonPseudoElementStyles};
      ${commonPseudoElementWidth};
      right: 0;
    }
  }

  &:first-child {
    &:before {
      ${commonPseudoElementWidth};
      right: 0;
    }
  }

  &:last-child {
    &:before {
      ${commonPseudoElementWidth};
      left: 0;
    }
  }
`

export const StepIndicator = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ isActive }) =>
    isActive
      ? css`
          &:after {
            content: '';
            width: 15px;
            height: 15px;
            background-color: ${({ color }) => color};
            border-radius: 50%;
          }
        `
      : ''}
`

export const Label = styled.span`
  position: absolute;
  bottom: -30px;
  font-size: 12px;
  font-weight: 500;
  color: ${({ color }) => color};
  user-select: none;
`

export const CheckIcon = styled(RoundedCheckIcon)`
  position: absolute;
  fill: ${({ color }) => color};
  width: 20px;
  height: 20px;
`
