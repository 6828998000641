import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    backgroundColor: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.4)`,
    backgroundColor: p => p.tertiaryLight,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.4)`,
    backgroundColor: p => p.tertiaryExtraLight,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  }
}
