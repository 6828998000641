export const validation = {
  maxTitleChar: 120,
  maxParagraphChar: 1500,
  maxSlideTitleChar: 80,
  maxSlideParagraphChar: 210,
  textItems: {
    min: 1,
    max: 3
  },
  slides: {
    min: 1,
    max: 20
  },
  buttons: {
    min: 0,
    max: 2
  }
}
