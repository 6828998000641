// @flow

import React, { memo, useCallback, useContext, useRef, useState } from 'react'
import ReactPlayer from 'react-player'

import * as Styled from './styled'
import { TBackgroundVideoControlProps } from './types'
import { getYoutubeThumbnail, isYoutubeUrl } from '@website/common/utils'
import { accentRed } from '@website/common/styles/colors'
import VideoIcon from '@editor/common/assets/svgr-icons/Video_icon.svg'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import { ControlModalsContext } from '@contexts'

const BackgroundVideoControl = ({
  className,
  backgroundVideo,
  openVideoFlyout
}: TBackgroundVideoControlProps) => {
  const [videoErrorNotification, setVideoError] = useState(false)
  const bgControlRef = useRef(null)
  const { modalStates } = useContext(ControlModalsContext)
  const isFlyoutOpen = modalStates[MODAL_TYPES.mediaModal].isOpen

  const _openVideoFlyout = useCallback(() => {
    openVideoFlyout({
      isOpen: !isFlyoutOpen,
      targetRef: bgControlRef,
      isFlyoutPositionFixed: false,
      shift: { x: 0, y: 40 },
      alignment: 'right',
      renderingContainer: className
    })
  }, [bgControlRef, isFlyoutOpen, className, openVideoFlyout])

  const onClick = useCallback(() => {
    if (backgroundVideo) {
      return
    }

    _openVideoFlyout()
  }, [backgroundVideo, _openVideoFlyout])

  return (
    <Styled.BackgroundControl
      onClick={onClick}
      ref={bgControlRef}
      className={backgroundVideo ? '' : className}
    >
      {backgroundVideo && videoErrorNotification ? (
        <Styled.BgVideoWarningIcon
          name="warning"
          size="medium"
          color={accentRed}
        />
      ) : backgroundVideo && isYoutubeUrl(backgroundVideo) ? (
        <Styled.Image alt="thumb" src={getYoutubeThumbnail(backgroundVideo)} />
      ) : backgroundVideo ? (
        <Styled.VideoContainer>
          <ReactPlayer
            muted
            config={{
              vimeo: {
                playerOptions: {
                  background: false,
                  controls: false,
                  showinfo: 1
                }
              }
            }}
            playing={false}
            controls={false}
            style={{
              right: '0',
              bottom: '0',
              position: 'absolute',
              width: 20,
              height: 20
            }}
            url={backgroundVideo}
            onError={() => {
              setVideoError(true)
            }}
          />
        </Styled.VideoContainer>
      ) : (
        <VideoIcon className="control-icon" />
      )}
    </Styled.BackgroundControl>
  )
}

export default memo(BackgroundVideoControl)
