export const TEXT = 'Text'
export const COLORS = 'Colors'
export const STYLES = 'Styles'
export const WIDGETS = 'Widgets'
export const INVITE = 'Invite'
export const SETTINGS = 'Settings'
export const EVENT = 'Event'

export const DEFAULT_EXCEPTED_BACKDROP_SELECTORS = [
  '.color-picker',
  '.color-picker-container'
]

export const COLOR_MODE_CONTROL_SELECTOR = '.color-mode-controls'
export const BREAKPOINT_800 = 800
