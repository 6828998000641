// @flow

import React, { memo } from 'react'
import Input from '@renderforest/rf-ui-library/molecules/Input'

import { InputContainer as StyledInputContainer } from './styled'
import { translate } from '@editor/common/utils/translations'

const ExternalUrlInput = ({ value, error, onChange }) => (
  <StyledInputContainer withError>
    <Input
      className="extended-url"
      value={value}
      error={error}
      onChange={onChange}
      placeholder="https://example.com"
      label={translate('whats_the_web_address_label')}
    />
  </StyledInputContainer>
)

export default memo(ExternalUrlInput)
