import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as Styled from './styled'
import { changeActiveSidebarItem } from '@actions/editor'

const ToolbarItemContent = ({
  isOpened,
  children,
  changeActiveSidebarItem
}) => {
  const [isMounted, setMounted] = useState(isOpened)

  useEffect(() => {
    if (isOpened) {
      setMounted(true)
    }
  }, [isOpened])

  const onAnimationEnd = () => {
    if (!isOpened) {
      setMounted(false)
      changeActiveSidebarItem('')
    }
  }

  return isMounted ? (
    <Styled.ToolbarItemContentWrapper
      onAnimationEnd={onAnimationEnd}
      isOpened={isOpened}
    >
      {children}
    </Styled.ToolbarItemContentWrapper>
  ) : null
}

export default connect(null, { changeActiveSidebarItem })(ToolbarItemContent)
