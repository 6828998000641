/* @flow */
import {
  addButton,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

import { imageModalActionMaker } from '@website/components/ActionMakers'

import { validation } from '../Feature32.consts'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeFeatureTitle = (newBlockTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_FEATURE_TITLE',
      value: { newBlockTitle, idx }
    })
  }
  const changeFeatureParagraph = (newBlockParagraph: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_FEATURE_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }
  const removeItem = (idx: number) => {
    dispatcher({
      type: 'REMOVE_ITEM',
      value: idx
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeImage = (value: Object) => ({
    type: 'CHANGE_IMAGE',
    value
  })

  const featureActions = (
    idx: number,
    featureCount: number,
    imageUrl: string
  ) => [
    ...imageModalActionMaker(dispatcher)({
      keyForSizes: 'feature32',
      showRemove: false,
      actions: {
        change: ({ url, dimensions }) =>
          dispatcher(changeImage({ url, dimensions, idx }))
      },
      imageUrl
    }),
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeItem(idx)),
      toShow: featureCount > validation.features.min
    }
  ]

  const changeFeatureTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_FEATURE_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeFeatureParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_FEATURE_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addButton,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeFeatureTitle,
    changeFeatureParagraph,
    addItem: () => {
      dispatcher({ type: 'ADD_ITEM' })
    },
    featureActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeFeatureTitleAlignment,
    changeFeatureParagraphAlignment
  }
}
