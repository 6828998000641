/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

const getDimensionKey = (mockupType: string) =>
  mockupType === 'mobileImg' ? 'mobileImgDimensions' : 'webImgUrlDimensions'

const getCropKey = mockupType =>
  mockupType === 'mobileImg' ? 'mobileImgCropParams' : 'webImgCropParams'
export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({ ...initialState.buttons[0], type: btnType })
        } else {
          draft.buttons.push({ ...initialState.buttons[0], type: btnType })
        }
        break
      case 'CHANGE_IMAGE':
        {
          const { url, dimensions, mockupType } = action.value
          const mockupKey = `${mockupType}Url`
          const dimensionKey = `${getDimensionKey(mockupType)}`
          const cropParamsKey = getCropKey(mockupType)
          draft[mockupKey] = url
          draft[dimensionKey] = dimensions
          draft[cropParamsKey] = {}
        }
        break
      case 'REMOVE_IMAGE':
        {
          const { mockupType } = action.value // mobileImg | webImg
          const mockupKey = `${mockupType}Url`
          const dimensionKey = `${getDimensionKey(mockupType)}`
          const cropParamsKey = getCropKey(mockupType)
          draft[mockupKey] = initialState[mockupKey]
          draft[dimensionKey] = {}
          draft[cropParamsKey].imgCropParams = {}
        }
        break
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, mockupType } = action.value
        const key = getCropKey(mockupType)
        draft[key] = imgCropParams
        break
      }
      case 'CHANGE_MOCKUP_COLOR': {
        const mockupType = action.value
        draft[`${mockupType}MockupColor`] === 'light'
          ? (draft[`${mockupType}MockupColor`] = 'dark')
          : (draft[`${mockupType}MockupColor`] = 'light')
        break
      }
      case 'MAKE_MOCKUP_LIGHT': {
        const mockupType = action.value
        draft[`${mockupType}MockupColor`] = 'light'
        break
      }
      case 'MAKE_MOCKUP_DARK': {
        const mockupType = action.value
        draft[`${mockupType}MockupColor`] = 'dark'
        break
      }
    }
  })
}
