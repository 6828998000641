/* @flow */
import styled, { css } from 'styled-components'
import Icon from '@website/common/components/Icon'
import THEME from './RSVP4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getCardBGOverlayStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  position: relative;
  padding-top: 130px;
  ${({ isEditing }) =>
    isEditing
      ? css`
          overflow: hidden;
        `
      : css`
          overflow: unset;
          padding: 0 !important;
        `}

  .add-button-wrap {
    margin-bottom: 30px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 20px;
  width: 100% !important;
  max-width: 100% !important;
  @media only screen and (max-width: 1024px) {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0px;
  }
  &:nth-child(1) {
    margin-top: 0px;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

const LeftContainer = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  ${({ isEditing }) => (isEditing ? null : 'z-index: -1')};
`
const RightContainer = styled.div`
  ${({ isEditing }) => (isEditing ? 'margin-left: 1px' : 'z-index: 1')};
  width: 100%;
  z-index: 1;
  position: relative;
  ${getBackgroundStyles(THEME)};
  .form-container {
    position: relative;
    ${getBackgroundStyles(THEME)};
    .control-container {
      margin-top: 2px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-left: 0px;
  }
`

const SubTitle = styled.p`
  color: ${getStyleForProp('subTitle')};
  display: block;
  width: 100%;
  ${getTextStyleByAlignment};

  & + h1 {
    margin-top: 10px;
  }
`

const CardSubTitle = styled.p`
  color: ${getStyleForProp('cardSubTitle')};
  display: block;
  width: 100%;
  ${getTextStyleByAlignment};

  & + h1 {
    margin-top: 10px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0px;

  & + p {
    margin-top: 20px;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const CardParagraph = styled.p`
  color: ${getStyleForProp('text')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${getBackgroundStyles(THEME)};
  width: 100%;
`

const AdditionalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 80px 40px;
  box-sizing: border-box;
  &:empty {
    display: none;
  }
  @media only screen and (max-width: 1024px) {
    min-height: auto;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 20px;
  }
`

const StepsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${({ isEditing, isStepsEmpty }) =>
    isStepsEmpty && !isEditing
      ? css`
          display: none;
        `
      : css``};

  padding: 80px 40px;
  box-sizing: border-box;

  @media only screen and (max-width: 1024px) {
    min-height: auto;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 20px;
  }
`
const DataContent = styled.div`
  ${getBackgroundStyles(THEME)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 80px 40px;
  box-sizing: border-box;
  position: relative;
  @media only screen and (max-width: 1024px) {
    min-height: auto;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 20px;
  }
`

const DataTimeContainer = styled.div`
  top: 22px;
  width: 100%;
  max-width: 630px;
  padding-left: 35px;
  padding-right: 35px;
  margin-bottom: 160px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
    top: 0;
    margin-bottom: 120px;
    row-gap: 20px;
  }

  & .control-container > p {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1024px) {
    position: relative;
    padding-left: 0;
    padding-right: 0;
  }
`

const DataTimeContainerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-right: 1px solid ${getStyleForProp('border')};
  padding: 5px 20px;
  box-sizing: border-box;
  &:nth-last-child(1) {
    border-right: none;
  }
  @media only screen and (max-width: 600px) {
    border-right: none;
    padding: 0;
  }
`

const DateTitle = styled.p`
  margin-bottom: 10px;
  width: 100%;
  font-weight: 600;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('stepsTitle')};
`

const DateValue = styled.p`
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
`

const LocationValue = styled.a`
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  display: block;
`

const StepsParagraph = styled.p`
  color: ${getStyleForProp('stepsParagraph')};
  line-height: 1.4;
  max-width: 680px;
  ${getTextStyleByAlignment}
  margin-bottom: 30px;
  @media only screen and (max-width: 768px) {
    margin: 0px auto 20px auto;
    max-width: 100%;
  }
`

const StepsWrap = styled.div`
  width: 100%;
  max-width: 880px;
  margin: 0 auto;
  & > .control-container {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    margin-bottom: 0;
  }
  &:last-child {
    ${StepsParagraph} {
      margin-bottom: 0;
    }
  }
`

const StepItemWrap = styled.div`
  display: flex;
  .step-right-content  .styled-controls {
      @media only screen and (max-width: 600px) {
        right: 0;
        left: inherit;
      }
    }
  }
`

const StepContent = styled.div`
  border-left: 1px solid ${getStyleForProp('paragraph')};
  width: 80%;
  padding: 0 0 0 50px;
  margin-left: 19px;
  position: relative;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 20px;
        `
      : css``};

  ${({ isAllStepInfoTitlesEmpty, isEditing }) =>
    isAllStepInfoTitlesEmpty && !isEditing
      ? css`
          width: 100%;
          margin-left: 0px !important;
        `
      : css``};

  @media only screen and (max-width: 768px) {
    margin-left: 14px;
    padding-left: 14px;
    padding-right: 0px;
  }
`

const StepsContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
`

const StepInfoWrapper = styled.div`
  width: 172px;
  min-width: 172px;
  ${({ isAllStepInfoTitlesEmpty, isEditing }) =>
    isAllStepInfoTitlesEmpty && !isEditing
      ? css`
          width: 0;
          min-width: 0;
        `
      : css``};

  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 20px;
        `
      : css`
          @media only screen and (max-width: 768px) {
            margin-bottom: 10px;
            &:empty {
              margin-bottom: 0;
            }
          }
        `};
  & > .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    width: 98px;
    min-width: 98px;
  }
`

const StepInfo = styled.p`
  color: ${getStyleForProp('stepsInfo')};
  font-weight: 700;
  display: block;
  justify-content: flex-end;
  width: 100%;
  max-width: 172px;
  margin-right: 10px;
  ${getTextStyleByAlignment};
  word-break: break-word;
`

const StepTitle = styled.p`
  color: ${getStyleForProp('stepsTitle')};
  ${getTextStyleByAlignment}
  font-weight: 600;
  margin-bottom: 15px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 10px;
    &:after {
      left: 0;
    }
  }
`

const Circle = styled.div`
  background-color: ${getStyleForProp('paragraph')};
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  ${({ isEditing }) =>
    isEditing
      ? css`
          top: 25px;
          @media only screen and (max-width: 768px) {
            top: 25px;
          }
        `
      : css`
          top: 0.6rem;
        `};
  left: -4px;
`

const ImageWrap = styled.div`
  font-size: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const SectionImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  height: 100vh;
  margin: 0 auto;

  .LazyLoad {
    position: relative;
    height: 100vh;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
  }
`
const ImageFigureBG = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  height: 100%;

  &::after {
    ${getCardBGOverlayStyles(THEME)};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  max-width: 150px;
  margin: 0 auto 10px auto;
  border-radius: ${getStyleForProp('iconRadius')};

  @media only screen and (max-width: 768px) {
    max-width: 100px;
  }
  @media only screen and (max-width: 375px) {
    max-width: 70px;
  }
  .LazyLoad {
    position: relative;
    padding-top: 100%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('iconRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const Postcard = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  width: 100%;
  ${({ theme }) =>
    theme.orientation === 'RIGHT'
      ? css`
          flex-direction: row-reverse;
        `
      : css``}

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }

  /* to do */

  .form-container {
    .control-container {
      max-width: 790px;
      padding: 40px 0 20px 0;
      margin: 40px auto 50px auto;
      @media only screen and (max-width: 1024px) {
        margin-right: 20px;
        margin-left: 20px;
        > div {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
      @media only screen and (max-width: 457px) {
        margin-right: 10px;
        margin-left: 10px;
        > div {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
    & > div {
      padding: 80px 40px;
      @media only screen and (max-width: 457px) {
        padding: 60px 20px;
      }
    }
  }
`

const DownButton = styled.div`
  display: none;
  position: absolute;
  padding: 20px;
  top: -64px;
  left: 50%;
  z-index: 20;
  cursor: pointer;
  transform: translateY(-50%);
  outline: none;
  transition: 500ms all cubic-bezier(0.84, 0.04, 1, 1);
  animation: arrowBounce 3s linear infinite;
  @keyframes arrowBounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0) translateX(-50%);
    }
    40% {
      transform: translateY(-20px) translateX(-50%);
    }
    60% {
      transform: translateY(-10px) translateX(-50%);
    }
  }
  @media only screen and (max-width: 1024px) {
    display: block;
  }
`

const ScrollIcon = styled(Icon)`
  fill: ${getStyleForProp('arrow')};
`

export default {
  StyledContainer,
  WmCustomContainer,
  LeftContainer,
  RightContainer,
  ScrollIcon,
  SubTitle,
  CardSubTitle,
  Title,
  Paragraph,
  CardParagraph,
  Content,
  AdditionalContent,
  StepsContent,
  DataContent,
  DataTimeContainer,
  DataTimeContainerItem,
  DateTitle,
  DateValue,
  LocationValue,
  StepsContainer,
  StepsWrap,
  StepItemWrap,
  StepContent,
  StepInfo,
  StepInfoWrapper,
  StepTitle,
  StepsParagraph,
  Circle,
  Img,
  ImageWrap,
  ImageFigureBG,
  SectionImageFigure,
  ImageFigure,
  Postcard,
  DownButton
}
