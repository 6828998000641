// @flow

// TODO: Rewrite this component

import React, { PureComponent } from 'react'
import YourLogo from '@website/common/assets/YourLogo.svg'
import Img from '../Image'
import Icon from '@website/common/components/Icon'
import {
  DispatchContext,
  withEditingContext,
  withMobileDetectContext
} from '@contexts'
import {
  StyledResizerContainer,
  StyledResizerHolder,
  StyledImg
} from './styled'

import { getImageValidWidth } from '@editor/common/utils/image'
import { getSizeLimitsByScreenSize } from './utils'
import { SIZE_LIMITS, MIN_STICKY_HEIGHT } from './consts'

const isTouchableDevice = () =>
  typeof window !== 'undefined' && typeof window.ontouchstart !== 'undefined'

class Resizer extends PureComponent {
  constructor(props) {
    super(props)
    this.isTouchableDevice = isTouchableDevice()
    this.optionalEventListeners = this.isTouchableDevice
      ? {
          onTouchStart: this.handleDragStart
        }
      : {
          onMouseDown: this.handleDragStart
        }

    this.state = {
      isValidImage: true,
      isHolderActive: false,
      startPosition: 0,
      imgRatio: 0,
      width: props.initialWidth || null
    }
  }

  componentDidMount() {
    const { initialWidth, imageSrc, imageDimensions } = this.props
    const imgSrc =
      (imageDimensions && Object.values(imageDimensions)[0]) || imageSrc
    if (initialWidth) {
      this.loadImage(imgSrc)
        .then(ratio => {
          this.setState({ imgRatio: ratio, width: initialWidth })
        })
        .catch(() => console.error('Failed to fetch'))
      return
    } else if (imageSrc) {
      this.handleImageChange()
    }
  }

  componentDidUpdate({
    initialWidth: prevInitialWidth,
    imageSrc: prevImageSrc
  }) {
    const {
      initialWidth: nextInitialWidth,
      imageSrc: nextImageSrc
    } = this.props
    const isSizeUpdated =
      nextInitialWidth && prevInitialWidth !== nextInitialWidth
    const isImageChanged = nextImageSrc && prevImageSrc !== nextImageSrc

    if (isImageChanged) {
      this.handleImageChange()
      return
    }
    if (isSizeUpdated) {
      this.setState({
        width: nextInitialWidth
      })
    }
  }

  resetState = () => {
    this.ratio = null
    this.setState({
      startPosition: 0,
      isHolderActive: false,
      width: 0
    })
  }

  loadImage = (src): Promise<number> =>
    new Promise((res, rej) => {
      const img = new Image()
      img.addEventListener('load', () => {
        res(img.naturalWidth / img.naturalHeight)
      })
      img.addEventListener('error', () => rej(null))
      img.src = src
    })

  handleImageChange = (): void => {
    const { imageSrc, imageDimensions } = this.props
    const imgSrc =
      (imageDimensions && Object.values(imageDimensions)[0]) || imageSrc

    this.resetState()
    this.loadImage(imgSrc)
      .then(ratio => {
        this.setState({ isValidImage: true, imgRatio: ratio }, () => {
          this.setImageDefaultWidth()
        })
      })
      .catch(() => {
        this.setState({
          width: 50,
          isValidImage: false
        })
      })
  }

  setImageDefaultWidth = (): void => {
    const { action, isEditing } = this.props
    const { imgRatio } = this.state
    const componentDispatcher = this.context
    const limits = SIZE_LIMITS.desktop

    const defaultWidth = getImageValidWidth(
      limits.maxWidth / 2,
      imgRatio,
      limits
    )

    this.setState({
      width: defaultWidth
    })
    isEditing && componentDispatcher(action(defaultWidth, false))
  }

  handleDragStart = e => {
    e.preventDefault()
    this.setState({
      startPosition: e.clientX,
      isHolderActive: true
    })
    if (this.isTouchableDevice) {
      document.addEventListener('touchmove', this.handleTouchMove)
      document.addEventListener('touchend', this.handleDragEnd)
    } else {
      document.addEventListener('mousemove', this.handleDrag, false)
      document.addEventListener('mouseup', this.handleDragEnd)
    }
  }

  handleTouchMove = e => {
    const { touches } = e
    const { clientX } = touches[0]
    this.calculateWidth(clientX)
  }

  handleDrag = e => {
    const { clientX } = e
    this.calculateWidth(clientX)
  }

  handleDragEnd = () => {
    this.setState({ isHolderActive: false })
    const componentDispatcher = this.context
    if (this.isTouchableDevice) {
      document.removeEventListener('touchmove', this.handleTouchMove, false)
      document.removeEventListener('touchend', this.handleDragEnd)
    } else {
      document.removeEventListener('mousemove', this.handleDrag, false)
      document.removeEventListener('mouseup', this.handleDragEnd)
    }
    componentDispatcher(this.props.action(this.state.width))
  }

  calculateWidth = offsetX => {
    const { initialWidth } = this.props
    const { imgRatio } = this.state

    const { startPosition } = this.state
    const widthDiff = offsetX - startPosition
    const proposedWidth = initialWidth + widthDiff

    const calculatedWidth = getImageValidWidth(
      proposedWidth,
      imgRatio,
      SIZE_LIMITS.desktop
    )
    this.setState({ width: calculatedWidth })
  }

  getImageWidth = () => {
    const { width, imgRatio } = this.state

    if (!width || !imgRatio) {
      return null
    }

    const limits = getSizeLimitsByScreenSize()
    return getImageValidWidth(width, imgRatio, limits)
  }

  getRelativeWidth = (scrollTop, actualWidth) => {
    const { imgRatio } = this.state
    if (!actualWidth || !imgRatio || typeof scrollTop === 'undefined') {
      return actualWidth
    }

    const actualHeight = actualWidth / imgRatio
    if (actualHeight < MIN_STICKY_HEIGHT) {
      return actualWidth
    }

    const relativeHeight = actualHeight - scrollTop / 3

    const expectedHeight = Math.max(relativeHeight, MIN_STICKY_HEIGHT)

    return expectedHeight * imgRatio
  }

  renderImage = hasDimensions => {
    const isDesktopDevice =
      typeof window !== 'undefined' ? window.innerWidth > 1024 : true
    // TODO: Refactor this part for invalid image
    const width = isDesktopDevice
      ? this.state.width
      : hasDimensions
      ? this.getImageWidth()
      : 120
    const relativeWidth = this.getRelativeWidth(this.props.scrollTop, width)
    const { color, imageSrc, imageDimensions, alt } = this.props

    return relativeWidth ? (
      <Img
        onLoad={this.props.onLoad}
        asProps={{
          color,
          width: relativeWidth,
          className: 'logo-image'
        }}
        withLazy={false}
        defaultImgSrc={imageSrc}
        as={StyledImg}
        alt={alt}
        sizes={imageDimensions}
      />
    ) : null
  }

  render() {
    const { isEditing, isMobile, imageSrc, imageDimensions } = this.props
    const { isValidImage } = this.state
    const hasDimensions = imageDimensions && Object.keys(imageDimensions).length

    if (!imageSrc) {
      return <StyledImg src={YourLogo} />
    }
    if (!isEditing || isMobile || !isValidImage || !hasDimensions) {
      return this.renderImage(hasDimensions)
    }

    return (
      <StyledResizerContainer>
        <StyledResizerHolder
          isCatched={this.state.isHolderActive}
          {...this.optionalEventListeners}
        >
          <Icon
            className="resize-icon"
            size="medium"
            color="white"
            name="icon-resize"
            from="rf"
          />
        </StyledResizerHolder>
        {this.renderImage()}
      </StyledResizerContainer>
    )
  }
}

Resizer.contextType = DispatchContext

export default withMobileDetectContext(withEditingContext(Resizer))
