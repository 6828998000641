/* @flow */
import {
  iconActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _addItem = () => {
    dispatch({ type: 'ADD_ITEM' })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    addItem: _addItem,
    changeBlockTitle,
    changeBlockParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    blockActions: removeBlockActionMaker(dispatch),
    getIconActions: iconActionMaker(dispatch)
  }
}
