import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const LanguageWrapper = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  border-bottom: ${`1px solid ${secondary.ExtraLight}`};
`

export const LanguagesDropdownWrapper = styled.div`
  ${({ hasMaxHeightAnimation, isOpen }) => {
    const animatedPropName = hasMaxHeightAnimation ? 'max-height' : 'height'
    const animatedPropValue = hasMaxHeightAnimation ? '310px' : 'auto'

    return isOpen
      ? css`
          transition: ${animatedPropName} 0.3s ease-out;
          ${animatedPropName}: ${animatedPropValue}
        `
      : css`
          transition: ${animatedPropName} 0.3s ease-out;
          ${animatedPropName}: 0
        `
  }};

  width: 100%;
  overflow-y: hidden;

  & > div {
    background-color: #f8fbff;
  }
`
