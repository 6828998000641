/* @flow */
import React from 'react'
import './Header6.styles.sass'
import S from './Header6.styled'
import meta from './Header6.meta'
import { validation } from './Header6.consts'
import THEME from './Header6.theme'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import StoreBadge from '@website/common/components/StoreBadge'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { getCurrentScreenSize } from '@website/common/utils'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

class Header6 extends React.Component<void, void> {
  dispatcher = this.context

  state = { navbarHeight: 0 }

  handleHeightChange = height => {
    this.setState({ navBarHeight: height })
  }

  render() {
    const {
      data: {
        title,
        logoSrc,
        logoColor,
        logoWidth,
        description,
        appleStoreLink,
        logoDimensions,
        googlePlayLink,
        appleBackground,
        backgroundImgUrl,
        androidBackground,
        androidBackgroundImgCropParams,
        appleBackgroundImgCropParams,
        backgroundImgDimensions,
        bgOverlayTransparency,
        androidBackgroundDimensions,
        appleBackgroundDimensions
      }
    } = this.props

    const androidBackgroundUrl =
      (androidBackgroundDimensions &&
        androidBackgroundDimensions[getCurrentScreenSize()]) ||
      androidBackground
    const Actions = ActionGetter(this.dispatcher)

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.Container
              backgroundImgUrl={backgroundImgUrl}
              backgroundImgDimensions={backgroundImgDimensions}
              bgOverlayTransparency={bgOverlayTransparency}
              isEditing={isEditing}
            >
              <NavBar
                handleHeightChange={this.handleHeightChange}
                theme={THEME}
                logoProps={{
                  logoSrc: logoSrc,
                  logoColor: logoColor,
                  logoWidth: logoWidth,
                  logoSizes: logoDimensions
                }}
              />
              <S.WmCustomContainer
                className="header-6-container"
                topOffset={this.state.navBarHeight}
              >
                <Show
                  when={[title, description, appleStoreLink, googlePlayLink]}
                >
                  <S.Section>
                    <div
                      style={{ maxWidth: '450px' }}
                      className="landing-content"
                    >
                      <EditableContent
                        text={title}
                        as={S.Title}
                        required={validation.title.required}
                        maxCount={validation.headingMaxChar}
                        className="WM_GLOBAL_heading"
                        onChange={(newTitle: string) =>
                          this.dispatcher(Actions.changeTitle(newTitle))
                        }
                      />
                      <EditableContent
                        text={description}
                        as={S.Description}
                        maxCount={validation.paragraphMaxChar}
                        className="WM_GLOBAL_paragraph"
                        onChange={(newDescription: string) =>
                          this.dispatcher(
                            Actions.changeDescription(newDescription)
                          )
                        }
                      />
                      <S.BadgeContainer
                        className="badge-container"
                        isEditing={isEditing}
                      >
                        <If
                          condition={!!googlePlayLink}
                          then={() => (
                            <ControlsWithModalOpener
                              actions={Actions.storeIconActions(
                                'googlePlayLink',
                                googlePlayLink
                              )}
                            >
                              <StoreBadge
                                className="badge-item-wrap"
                                badgeName="google"
                                url={googlePlayLink}
                              />
                            </ControlsWithModalOpener>
                          )}
                          otherwise={() => (
                            <AddButton
                              onAdd={() =>
                                this.dispatcher(
                                  Actions.addStoreLink('googlePlayLink')
                                )
                              }
                              toShow={true}
                              rounded
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                              Google play
                            </AddButton>
                          )}
                        />
                        <If
                          condition={!!appleStoreLink}
                          then={() => (
                            <ControlsWithModalOpener
                              actions={Actions.storeIconActions(
                                'appleStoreLink',
                                appleStoreLink
                              )}
                            >
                              <StoreBadge
                                badgeName="apple"
                                url={appleStoreLink}
                                className="badge-item-wrap"
                              />
                            </ControlsWithModalOpener>
                          )}
                          otherwise={() => (
                            <AddButton
                              onAdd={() =>
                                this.dispatcher(
                                  Actions.addStoreLink('appleStoreLink')
                                )
                              }
                              toShow={true}
                              rounded
                              style={{ margin: '0 0 0 10px' }}
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                              App store
                            </AddButton>
                          )}
                        />
                      </S.BadgeContainer>
                    </div>
                  </S.Section>
                </Show>
                <div className="mockups-wrap">
                  <S.Section className="frame-wrap apple-wrap">
                    <S.PhoneWrap>
                      <S.Phone>
                        <ControlsWithImageCropper
                          alignment="right"
                          actions={Actions.mockupActions({
                            mockupType: 'appleBackground',
                            showRemove:
                              appleBackground !==
                              meta.defaultProps.appleBackground,
                            imageUrl: appleBackground
                          })}
                        >
                          <Image
                            as={S.MobileImg}
                            asProps={{
                              imgCropParams: appleBackgroundImgCropParams
                            }}
                            defaultImgSrc={appleBackground}
                            sizes={appleBackgroundDimensions}
                            alt="Web page "
                          />
                        </ControlsWithImageCropper>
                        <S.Dinamic
                          src="https://static.rfstat.com/renderforest/images/website_maker_images/phone-dinamic-min.png"
                          alt="mobile dynamic"
                        />
                        <S.SilentMode />
                        <S.LockScreen />
                        <S.VoiceWrap>
                          <S.VoiceBtn />
                          <S.VoiceBtn />
                        </S.VoiceWrap>
                      </S.Phone>
                    </S.PhoneWrap>
                  </S.Section>
                  <S.Section className="frame-wrap android-wrap">
                    <S.AndroidPhoneWrap>
                      <S.AndroidPhone>
                        <ControlsWithImageCropper
                          actions={Actions.mockupActions({
                            showRemove:
                              androidBackground !==
                              meta.defaultProps.androidBackground,
                            mockupType: 'androidBackground',
                            imageUrl: androidBackgroundUrl
                          })}
                          alignment="right"
                        >
                          <Image
                            as={S.AndroidMobileImg}
                            asProps={{
                              imgCropParams: androidBackgroundImgCropParams
                            }}
                            defaultImgSrc={androidBackgroundUrl}
                            sizes={androidBackgroundDimensions}
                            alt="Mobile mockup"
                          />
                        </ControlsWithImageCropper>
                        <S.AndroidDinamic
                          src="https://static.rfstat.com/renderforest/images/website_maker_images/mobile_cameras.jpg"
                          alt="mobile dynamic"
                        />
                        <S.AndroidSilentMode />
                        <S.AndroidLockScreen />
                        <S.AndroidVoiceBtn />
                      </S.AndroidPhone>
                    </S.AndroidPhoneWrap>
                  </S.Section>
                </div>
              </S.WmCustomContainer>
            </S.Container>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

Header6.contextType = DispatchContext

export default Header6
