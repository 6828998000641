/* @flow */
import React, { memo, useCallback, useContext, useState } from 'react'
import produce from 'immer'

import { addGuestFromVisitorApi } from '@website/common/api'
import { SiteIdContext, EditingContext, SiteHashContext } from '@contexts'
import { useFormValidation } from '@website/common/lib/ValidatableHOC'
import { getFormIdByEventId } from '@website/common/utils/event'
import ConditionalWrapper from '@website/common/components/ConditionalWrapper'
import { Spinner } from '@website/common/styles/styles'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'

import RSVPRadioButtonList from '../RSVPRadioButtonList'
import StaticFields from './StaticFields'
import AdditionalGuests from './AdditionalGuests'
import DynamicField from './DynamicField'
import { YES, NO, CLOSED } from './consts'
import * as Styled from './styled'
import type { TRSVPFormContentProps } from './types'
import { getRsvpRadioButtonsListItems, preparePayloadData } from './utils'

const RSVPFormContent = ({
  formKey,
  isInFormPreview = false,
  eventId,
  formData = {},
  className = '',
  setFormData: setFormDataFromProps,
  eventFormData,
  isSubmitting,
  isFormDivided,
  setSubmittingState,
  additionalGuestCount,
  setRegistrationPopupType
}: TRSVPFormContentProps) => {
  const {
    firstName,
    lastName,
    email,
    phone,
    rsvpOption = {},
    guestBtn = {},
    submitBtn = {},
    dynamicFields = []
  } = eventFormData || {}

  const [errorMessage, setErrorMessage] = useState('')
  const isInEditor = typeof window !== 'undefined' && !!window.CURRENT_HOST
  const { isEditing } = useContext(EditingContext)
  const siteId = useContext(SiteIdContext)
  const siteHash = useContext(SiteHashContext)
  const rsvpOptionAnswer = formData.rsvpOption?.answer

  const onSubmitInEditor = useCallback(() => {
    setRegistrationPopupType(rsvpOptionAnswer)
  }, [rsvpOptionAnswer])

  const setFormData = useCallback(
    args => {
      setFormDataFromProps(args)
      errorMessage && setErrorMessage('')
    },
    [setFormDataFromProps, errorMessage]
  )

  const onSubmit = () => {
    const _formData = preparePayloadData({ ...formData, siteHash })
    setSubmittingState(true)

    const lang = typeof window !== 'undefined' ? window.CURRENT_LANGUAGE : ''

    addGuestFromVisitorApi(siteId, eventId, lang, _formData)
      .then(({ data }) => {
        data.rsvpOption.answer === YES
          ? setRegistrationPopupType(YES)
          : setRegistrationPopupType(NO)
      })
      .catch(e => {
        if (e.status === 412 && e.code === 'OutOfLimit') {
          const limit = parseInt(e.message)

          if (limit === 0) {
            setRegistrationPopupType(CLOSED)
          } else {
            const errorMessage =
              limit === 1
                ? 'Only 1 place left available'
                : `Only ${limit} places left available`

            setErrorMessage(errorMessage)
          }
          return
        }

        if (e.status === 412) {
          setRegistrationPopupType(CLOSED)
          return
        }

        setErrorMessage(e.message)
      })
      .finally(() => {
        setSubmittingState(false)
      })
  }

  const onRsvpOptionChange = useCallback(
    value => {
      const activeOption = rsvpOption.options?.find(
        option => option.name === value
      )

      setFormData(
        produce(draft => {
          draft.rsvpOption.id = activeOption.id
          draft.rsvpOption.text = activeOption.text
          draft.rsvpOption.answer = activeOption.name
        })
      )
    },
    [rsvpOption, setFormData]
  )

  const _onSubmit = isInEditor ? onSubmitInEditor : onSubmit

  const { register, formErrors, handleSubmit } = useFormValidation(_onSubmit)
  return (
    <Styled.FormContainer
      key={formKey}
      className={className}
      id={getFormIdByEventId(eventId)}
      isEditing={isEditing || isInFormPreview}
    >
      <Styled.FormWrapper className="form-wrapper">
        <ConditionalWrapper shouldWrap={isFormDivided} className="block-form-1">
          <>
            {rsvpOption.isVisible ? (
              <Styled.RSVPOptionWrapper>
                <RSVPRadioButtonList
                  title={rsvpOption.text}
                  items={getRsvpRadioButtonsListItems(rsvpOption.options)}
                  checkedItem={rsvpOptionAnswer}
                  onChange={onRsvpOptionChange}
                  isRequired={false}
                  className="WM_GLOBAL_heading20"
                  labelClassName="WM_GLOBAL_paragraph18"
                />
              </Styled.RSVPOptionWrapper>
            ) : null}
            <StaticFields
              key={rsvpOptionAnswer}
              firstName={firstName}
              lastName={lastName}
              email={email}
              phone={phone}
              formData={formData}
              setFormData={setFormData}
              formErrors={formErrors}
              register={register}
            />
          </>
        </ConditionalWrapper>
        <ConditionalWrapper shouldWrap={isFormDivided} className="block-form-2">
          {isInFormPreview || rsvpOptionAnswer === YES ? (
            <>
              {guestBtn.isVisible ? (
                <AdditionalGuests
                  guestBtn={guestBtn}
                  additionalGuestCount={additionalGuestCount}
                  firstName={firstName}
                  lastName={lastName}
                  additionalGuests={formData.additionalGuests}
                  setFormData={setFormData}
                  formErrors={formErrors}
                  register={register}
                />
              ) : null}
              <Styled.DynamicFields className="dynamic-fields">
                {dynamicFields.map((dynamicField, idx) => {
                  const answer = formData?.dynamicFields?.[idx]?.answer

                  return (
                    <DynamicField
                      key={idx}
                      idx={idx}
                      dynamicField={dynamicField}
                      answer={answer}
                      setFormData={setFormData}
                      register={register}
                      formErrors={formErrors}
                    />
                  )
                })}
              </Styled.DynamicFields>
            </>
          ) : null}
          <Styled.SubmitButton
            onClick={handleSubmit}
            className="WM_GLOBAL_paragraph14"
          >
            {submitBtn.text}
            {isSubmitting ? <Spinner className="spinner" /> : null}
          </Styled.SubmitButton>
          {errorMessage ? (
            <Styled.ErrorMessage>
              <img src={ErrorIcon} alt="error-icon" />
              {errorMessage}
            </Styled.ErrorMessage>
          ) : null}
        </ConditionalWrapper>
      </Styled.FormWrapper>
    </Styled.FormContainer>
  )
}

export default memo(RSVPFormContent)
