/* @flow */
export default dispatcher => {
  const changeDate = value => {
    dispatcher({
      type: 'CHANGE_DATE',
      value
    })
  }

  const changeDateLabel = (dateLabelValue: string, dateLabelIdx: number) => {
    dispatcher({
      type: 'CHANGE_DATE_LABEL',
      value: { dateLabelValue, dateLabelIdx }
    })
  }

  return {
    changeDate,
    changeDateLabel
  }
}
