/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeSecondTitle = (newTitle: string) =>
    dispatcher({
      type: 'CHANGE_SECOND_TITLE',
      value: newTitle
    })

  const changeSecondParagraph = (newParagraph: string) =>
    dispatcher({
      type: 'CHANGE_SECOND_PARAGRAPH',
      value: newParagraph
    })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeSecondTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SECOND_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeSecondParagraphAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SECOND_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSecondTitle,
    changeSecondParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeSecondTitleAlignment,
    changeSecondParagraphAlignment
  }
}
