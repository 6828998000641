// @flow
import React, { memo, useCallback, useContext } from 'react'

import * as Styled from './styled'
import type { TUpgradeBannerProps } from './types'
import { GRADIENT_CHECK } from './consts'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { UpgradePopupContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const UpgradeBanner = ({
  img,
  hoverImg,
  info,
  onClick,
  btnText,
  infoList,
  className,
  isBtnIconHidden,
  isDescriptionHidden,
  customTranslationAlignment
}: TUpgradeBannerProps) => {
  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const _onClick = useCallback(() => {
    onClick
      ? onClick()
      : setUpgradePopupState({ isOpen: true, isOpenFromPopup: true })
  }, [onClick])

  return (
    <Styled.UpgradeBanner hoverImg={hoverImg} className={className}>
      <Styled.UpgradeBannerImage src={img} alt="upgrade" />
      <Styled.UpgradeBannerHoverImage src={hoverImg} alt="upgrade" />
      <Styled.UpgradeBannerInfo
        customTranslationAlignment={customTranslationAlignment}
      >
        <Styled.TextContainer>
          <p className="upgrade-title">{info}</p>
          {infoList
            ? infoList.map((listItem, idx) => (
                <Styled.ListItem key={idx}>
                  <img src={GRADIENT_CHECK} alt="check" />
                  {listItem}
                </Styled.ListItem>
              ))
            : null}
          {!isDescriptionHidden && (
            <p className="upgrade-description">
              {translate('upgrade_website_email_text')}
            </p>
          )}
        </Styled.TextContainer>
        <button onClick={_onClick}>
          {!isBtnIconHidden && (
            <Icon color="white" name="crown" size="xSmall" />
          )}
          {btnText || translate('upgrade_label')}
        </button>
      </Styled.UpgradeBannerInfo>
    </Styled.UpgradeBanner>
  )
}

export default memo(UpgradeBanner)
