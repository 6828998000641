// @flow

import { PositionType, CoordsType } from './types'
import { TOOLTIP_OFFSET_Y, TOOLTIP_OFFSET_X, POSITIONS } from './consts'

const getOffset = el => {
  if (!el) {
    return { top: 0, left: 0 }
  }
  const rect = el.getBoundingClientRect()
  return { top: rect.top, left: rect.left }
}

// this is an huge function, it is very risky to change any line, sorry
/* eslint-disable max-statements */
export const getAvailablePositionInfo = (
  targetEl: HTMLDivElement | null,
  tooltipEl: HTMLDivElement | null,
  preferredPosition: PositionType,
  { x: offsetX = TOOLTIP_OFFSET_X, y: offsetY = TOOLTIP_OFFSET_Y } = {}
): {
  position: PositionType,
  coords: CoordsType
} => {
  const positionInfo = {
    position: POSITIONS.TOP,
    coords: {
      x: 0,
      y: 0
    }
  }

  if (!targetEl || !tooltipEl) {
    return positionInfo
  }

  const { top: targetTop, left: targetLeft } = getOffset(targetEl)
  const {
    height: targetHeight,
    width: targetWidth
  } = targetEl.getBoundingClientRect()

  const {
    width: tooltipWidth,
    height: tooltipHeight
  } = tooltipEl.getBoundingClientRect()

  const spaceCheckers = {
    top: () => {
      const hasSpaceFromTop = tooltipHeight < targetTop - offsetY
      const isNotOutsideFromLeft =
        tooltipWidth / 2 < targetLeft + targetWidth / 2
      const isNotOutsideFromRight =
        tooltipWidth / 2 < window.innerWidth - (targetLeft + targetWidth / 2)
      return hasSpaceFromTop && isNotOutsideFromLeft && isNotOutsideFromRight
    },
    bottom: () => {
      const hasSpaceFromBottom =
        tooltipHeight <
        window.innerHeight - (targetTop + targetHeight + offsetY)
      const isNotOutsideFromLeft = tooltipWidth / 2 < targetLeft
      const isNotOutsideFromRight =
        tooltipWidth / 2 < window.innerWidth - (targetLeft + targetWidth / 2)
      return hasSpaceFromBottom && isNotOutsideFromLeft && isNotOutsideFromRight
    },
    left: () => tooltipWidth < targetLeft - offsetX,
    right: () =>
      tooltipWidth <
      window.innerWidth - (targetLeft + targetWidth / 2 + offsetX)
  }

  const coordGetters = {
    top: () => ({
      x: targetLeft - tooltipWidth / 2 + targetWidth / 2,
      y: targetTop - (offsetY + tooltipHeight)
    }),
    left: () => ({
      x: targetLeft - (tooltipWidth + offsetX),
      y: targetTop + targetHeight / 2 - tooltipHeight / 2
    }),
    right: () => ({
      x: targetLeft + targetWidth + offsetX,
      y: targetTop + targetHeight / 2 - tooltipHeight / 2
    }),
    bottom: () => ({
      x: targetLeft - tooltipWidth / 2 + targetWidth / 2,
      y: targetTop + targetHeight + offsetY
    })
  }

  const getCoords = (fromPosition: PositionType): CoordsType => {
    const coordGetter = coordGetters[fromPosition]
    return coordGetter()
  }

  const checkAvailableSpace = (fromPosition: PositionType) => {
    const checkSpace = spaceCheckers[fromPosition]
    return checkSpace ? checkSpace() : false
  }

  const hasSpaceForPreferredPosition = checkAvailableSpace(preferredPosition)
  if (hasSpaceForPreferredPosition) {
    return {
      position: preferredPosition,
      coords: getCoords(preferredPosition)
    }
  }

  // exclude already checked position
  const restAvailablePositions = Object.keys(spaceCheckers).filter(
    (position: string) => position !== preferredPosition
  )

  // find first suitable available position
  const availablePosition = restAvailablePositions.find(
    (position: PositionType) => checkAvailableSpace(position)
  )

  return availablePosition
    ? {
        position: availablePosition,
        coords: getCoords(availablePosition)
      }
    : {
        position: preferredPosition,
        coords: getCoords(preferredPosition)
      }
}
