export const filterLanguagesDataBeforePatch = array =>
  array
    .filter(language => !language.removed)
    .map(
      ({
        lang: { code: lang, id: smLangId },
        isPrimary,
        order,
        status,
        autoTranslate
      }) => ({
        lang,
        smLangId,
        isPrimary,
        order,
        status,
        ...(!isPrimary && autoTranslate !== undefined && { autoTranslate })
      })
    )
