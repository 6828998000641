/* @flow */
import React, { useContext, useMemo, memo } from 'react'
import S from './RSVP6.styled'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import RSVPButton from '@website/common/components/RSVPButton'
import { DispatchContext, EditingContext } from '@contexts'
import { generateEventCardDate } from '@website/common/utils/event'
import useEventSettingsOpener from '@hooks/useEventSettingsOpener'

const RSVP6Card = memo(
  ({ detailsData = {}, metaData = {}, onRSVPBtnClick, isLoading }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const {
      title,
      subTitle,
      paragraph,
      startDateInfo,
      endDateInfo,
      location,
      timezone,
      button
    } = detailsData

    const {
      backgroundTopImage,
      backgroundBottomImage,
      dateAlignment,
      locationAlignment,
      subtitleAlignment,
      titleAlignment,
      paragraphAlignment
    } = metaData

    const eventDate = useMemo(
      () => generateEventCardDate(startDateInfo, endDateInfo, timezone),
      [startDateInfo, endDateInfo, timezone]
    )

    const { editSubtitle, editTitle, editParagraph, editDate, editLocation } =
      useEventSettingsOpener()

    return (
      <div className="rsvp-6-container">
        <S.ImageFigure>
          <Image
            as={S.ImgTop}
            alt="Section img"
            defaultImgSrc={backgroundTopImage.imageUrl}
            sizes={backgroundTopImage.imgDimensions}
          />
        </S.ImageFigure>

        <S.Postcard>
          <S.WmCustomContainer>
            <S.Content isEditing={isEditing} isParagraphExists={paragraph}>
              <EditableContent
                text={subTitle.text}
                isEditing={isEditing}
                as={S.SubTitle}
                alignment={subtitleAlignment}
                isTypingDisabled
                isTextFormatingHidden
                className="WM_GLOBAL_paragraph14"
                changeAlignment={Actions.changeSubtitleAlignment}
                customActions={Actions.eventSettingsActionMaker({
                  onControlClick: editSubtitle
                })}
                onDoubleClick={editSubtitle}
              />
              <EditableContent
                text={eventDate}
                as={S.Date}
                isEditing={isEditing}
                isTypingDisabled
                isTextFormatingHidden
                className="WM_GLOBAL_paragraph18"
                alignment={dateAlignment}
                changeAlignment={Actions.changeDateAlignment}
                customActions={Actions.eventSettingsActionMaker({
                  onControlClick: editDate
                })}
                onDoubleClick={editDate}
              />
              {location.isVisible ? (
                <EditableContent
                  text={location.name}
                  as={S.Location}
                  isEditing={isEditing}
                  isTypingDisabled
                  isTextFormatingHidden
                  className="WM_GLOBAL_paragraph18"
                  target="_blank"
                  href={location.url.replace('&output=embed', '')}
                  alignment={locationAlignment}
                  changeAlignment={Actions.changeLocationAlignment}
                  customActions={Actions.eventSettingsActionMaker({
                    onControlClick: editLocation
                  })}
                  onDoubleClick={editLocation}
                />
              ) : null}
              <EditableContent
                text={title.text}
                alignment={titleAlignment}
                as={S.Title}
                isEditing={isEditing}
                hasAlignment={true}
                isTypingDisabled
                isTextFormatingHidden
                className="WM_GLOBAL_heading42"
                changeAlignment={Actions.changeTitleAlignment}
                customActions={Actions.eventSettingsActionMaker({
                  onControlClick: editTitle
                })}
                onDoubleClick={editTitle}
              />
              <EditableContent
                text={paragraph.text}
                isEditing={isEditing}
                as={S.Paragraph}
                isTypingDisabled
                isTextFormatingHidden
                className="WM_GLOBAL_paragraph18"
                alignment={paragraphAlignment}
                changeAlignment={Actions.changeParagraphAlignment}
                customActions={Actions.eventSettingsActionMaker({
                  onControlClick: editParagraph
                })}
                onDoubleClick={editParagraph}
              />

              <RSVPButton
                buttonText={button.text}
                onClick={onRSVPBtnClick}
                isLoading={isLoading}
              />
            </S.Content>
          </S.WmCustomContainer>
        </S.Postcard>
        <S.ImageFigure>
          <Image
            as={S.ImgBottom}
            alt="Section img"
            defaultImgSrc={backgroundBottomImage.imageUrl}
            sizes={backgroundBottomImage.imgDimensions}
          />
        </S.ImageFigure>
      </div>
    )
  }
)

export default RSVP6Card
