/* @flow */
import React, { useContext, memo } from 'react'
import S from './Pricing5.styled'
import { validation } from './Pricing5.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import SingleButton from '@website/common/components/SingleButton'
import { translate } from '@editor/common/utils/translations'

const Pricing5 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      plans,
      buttonUrl,
      paragraph,
      paragraphAlignment,
      buttonText,
      primaryPlanIdx,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.HeaderContainer>
            <EditableContent
              text={title}
              as={S.Heading}
              alignment={titleAlignment}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Paragraph}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.HeaderContainer>
        </Show>
        <S.CardsContainer>
          <Mapper
            data={plans}
            render={(plan, planIndex) => {
              const isPrimary = planIndex === primaryPlanIdx
              return (
                <S.CardControlsWrapper key={planIndex}>
                  <ControlsWithModalOpener
                    key={planIndex}
                    actions={Actions.cardActions({
                      idx: planIndex,
                      showStar: !isPrimary,
                      showRemove: plans.length > validation.plans.min
                    })}
                    style={{ width: '100%' }}
                  >
                    <S.PriceCard isPrimary={isPrimary} className="pricing-card">
                      <S.CardContent
                        className="card-content"
                        isPrimary={isPrimary}
                      >
                        <EditableContent
                          isPrimary={isPrimary}
                          text={plan.title}
                          as={S.Title}
                          alignment={plan.blockTitleAlignment}
                          required={validation.plans.title.required}
                          maxCount={validation.blockTitleMaxChar}
                          onChange={newTitle =>
                            Actions.changePlanTitle(newTitle, planIndex)
                          }
                          className="WM_GLOBAL_heading32"
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(
                              alignment,
                              planIndex
                            )
                          }
                        />
                        <Show when={[plan.description]}>
                          <EditableContent
                            isPrimary={isPrimary}
                            text={plan.description}
                            as={S.Description}
                            alignment={plan.blockDescriptionAlignment}
                            maxCount={validation.blockParagraphMaxChar}
                            onChange={newDescription =>
                              Actions.changePlanDescription(
                                newDescription,
                                planIndex
                              )
                            }
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockDescriptionAlignment(
                                alignment,
                                planIndex
                              )
                            }
                            className="WM_GLOBAL_paragraph18"
                          />
                        </Show>
                      </S.CardContent>
                      <S.PriceWrapper>
                        <EditableContent
                          isPrimary={isPrimary}
                          as={S.Price}
                          alignment={plan.priceAlignment}
                          text={plan.price}
                          required={validation.plans.price.required}
                          maxCount={validation.priceMaxChar}
                          onChange={newPrice =>
                            Actions.changePlanPrice(newPrice, planIndex)
                          }
                          className="WM_GLOBAL_heading32"
                          changeAlignment={(alignment: string) =>
                            Actions.changePriceAlignment(alignment, planIndex)
                          }
                        />
                      </S.PriceWrapper>
                    </S.PriceCard>
                    <S.Scale isPrimary={isPrimary} />
                  </ControlsWithModalOpener>
                </S.CardControlsWrapper>
              )
            }}
          />
          <SingleButton
            btnUrl={buttonUrl}
            btnText={buttonText}
            componentDispatcher={dispatcher}
          />
        </S.CardsContainer>
        <AddButton
          style={{ marginTop: '1rem' }}
          onAdd={Actions.addBlock}
          toShow={plans.length < validation.plans.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_item_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Pricing5
