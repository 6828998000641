export const validation = {
  title: 70,
  additionalBlockParagraphMaxChar: 50,
  blockTitleMaxChar: 200,
  blockParagraphMaxChar: 2000,
  listItemParagraphMaxChar: 1000,
  block: {
    min: 1,
    max: 15
  },
  listItem: {
    min: 0,
    max: 10
  }
}

export const LIST_ITEM_INITIAL_STATE = 'To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time'