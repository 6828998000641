/* @flow */
import styled, { css } from 'styled-components'
import LibIcon from '@website/common/components/Icon'
import THEME from './Feature11_2.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

import { getBackground } from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 120px;
        `
      : css`
          padding-top: 0;
          padding-bottom: 0;
        `};
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  max-width: unset;
  width: 100%;
  padding: 0;
  @media only screen and (max-width: 1100px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }
`

const FeatureContent = styled.div`
  width: 50%;
  margin: 80px 2vw;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    max-width: 600px;
    margin: 40px auto 0 auto;
    text-align: left;
    align-items: center;
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT'
          ? '40px auto 50px auto'
          : '80px auto 40px auto'};
      `}

    .buttons-block {
      justify-content: center;
      ${({ theme }) =>
        css`
          margin-bottom: ${theme.orientation === 'RIGHT' && '30px'};
        `}
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT'
          ? '40px auto 60px auto'
          : '60px auto 40px auto'};
      `}
  }
`

const ContentWrapper = styled.div`
  max-width: 800px;
  width: 90%;
  @media only screen and (min-width: 1024px) {
    max-width: initial;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 40px 0 0 0;
  @media only screen and (max-width: 1440px) {
    margin-left: 0;
  }
  @media only screen and (max-width: 1100px) {
    max-width: 460px;
    margin: 20px auto;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 0;
  }
`

const ListItemWrap = styled.li`
  display: flex;
  align-items: center;
  margin: 10px 0;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  ${({ isTextGradient }) =>
    isTextGradient
      ? css`
          background: linear-gradient(
            90deg,
            ${getStyleForProp('gradientColorFrom')} 0,
            ${getStyleForProp('gradientColorTo')} 100%
          );
          background-clip: text;
          background-size: 100% auto;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:before,
          &:after {
            -webkit-background-clip: initial;
            -webkit-text-fill-color: initial;
            background: initial;
          }
          & .link-wrapper {
            position: initial;
          }
        `
      : ''}
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const ListItem = styled.p`
  color: ${getStyleForProp('paragraph')};
  @media only screen and (min-width: 1025px) {
    padding-right: 12px;
  }
`

const IconWrap = styled.div`
  margin: 0 16px 0 0;
  user-select: none;
`

const Icon = styled(LibIcon).attrs(props => ({
  color: getStyleForProp('icon')(props)
}))``

const ImageWrap = styled.div`
  width: 60%;
  font-size: 0;
  &:only-child {
    margin: 0 auto;
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    max-width: 560px;
    margin: 0 auto;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  .lazy-figure {
    height: initial;
    padding-top: 100%;
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export default {
  WmCustomContainer,
  Img,
  StyledContainer,
  FeatureContent,
  ContentWrapper,
  List,
  ListItemWrap,
  Title,
  Paragraph,
  ListItem,
  Icon,
  IconWrap,
  ImageWrap,
  ImageFigure
}
