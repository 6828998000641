//@flow
import React, { useContext, memo } from 'react'

import { BackgroundSidebarContext, MobileDetectContext } from '@contexts'
import { MobilePopup } from '@editor/common/lib/Popup'
import CloseIcon from '@editor/common/assets/svgr-icons/cancel.svg'
import { translate } from '@editor/common/utils/translations'

import List from './List'
import * as Styled from './styled'
import type { TProps } from './types'

const BackgroundSidebar = ({ activeBgThumbnail, backgroundImages }: TProps) => {
  const isMobile = useContext(MobileDetectContext)
  const { isOpened, close } = useContext(BackgroundSidebarContext)

  return isMobile ? (
    <MobilePopup
      isOpen={isOpened}
      onClose={close}
      title={translate('background_style_label')}
    >
      <List
        activeBgThumbnail={activeBgThumbnail}
        backgroundImages={backgroundImages}
      />
    </MobilePopup>
  ) : (
    <Styled.SlideLeft isOpen={isOpened}>
      <Styled.Header>
        <div>
          <span> {translate('background_style_label')}</span>
          <CloseIcon onClick={close} />
        </div>
      </Styled.Header>
      <List
        activeBgThumbnail={activeBgThumbnail}
        backgroundImages={backgroundImages}
      />
    </Styled.SlideLeft>
  )
}

export default memo(BackgroundSidebar)
