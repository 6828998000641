export const validation = {
  titleMaxChar: 40,
  blocks: {
    min: 3,
    max: 5
  },
  buttons: {
    min: 0,
    max: 2
  }
}