import styled from 'styled-components'

import LibPopup from '@renderforest/rf-ui-library/organism/Popup'

export const Popup = styled(LibPopup)`
  z-index: 50;
  & > div {
    padding: 0;
    width: 400px;
    & > div {
      padding: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    height: 100%;
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`
