/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Header38.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0' : `${topOffset}px 0 0 0`};
  background: ${getStyleForProp('background')};
  position: relative;
  & ${S.Container} {
    margin-bottom: 0;
  }
  .slick-slide > div {
    margin: 0 !important;
    height: 100% !important;
    .slick-list {
      height: 100% !important;
    }
  }
  .mySlider {
    .slick-list {
      overflow: visible;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  ${({ isEditing }) => (isEditing ? 'margin-top: 10px;' : null)}
  width: 100%;
  max-width: initial;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 !important;
  @media only screen and (max-width: 1360px) {
    flex-direction: column;
  }
`

const LeftContent = styled.div`
  width: 48%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  justify-content: flex-end;
  padding: 40px 0 0 0;
  @media only screen and (max-width: 1360px) {
    width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 0 0 0;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 660px;
  padding: 0 2vw 0 7vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (min-width: 1600px) {
    max-width: 700px;
    padding: 0;
    margin: 2vw 2vw 0 8vw;
  }
  @media only screen and (max-width: 1360px) {
    max-width: 800px;
    margin: 9vw auto 0 auto;
    padding: 0;
    text-align: center;
    align-items: center;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 880px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media only screen and (max-width: 1360px) {
    margin: 0 auto;
  }
`

const HGroup = styled.div`
  width: 100%;
  max-width: 630px;
  .control-container {
    width: 100%;
    margin-bottom: 30px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1360px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: ${({ isEditing }) => (isEditing ? '0' : '20px')};
  ${getTextStyleByAlignment};
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  margin-top: 16px;
  margin-top: ${({ isEditing }) => (isEditing ? '0' : '16px')};
  ${getTextStyleByAlignment};
`

const SocialText = styled.p`
  font-size: 16px;
  font-weight: 600;
  min-height: 74px;
  margin: 0 0 12px 0;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  display: flex;
  align-items: center;
  &:after {
    left: 0 !important;
  }
  @media only screen and (max-width: 1360px) {
    writing-mode: initial;
    text-orientation: initial;
  }
`

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  z-index: 2;
  @media only screen and (min-width: 1600px) {
    width: 90%;
  }
  @media only screen and (min-width: 1800px) {
    width: 98%;
  }
  @media only screen and (min-width: 1440px) {
    max-width: initial;
  }
  @media only screen and (max-width: 1440px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 1360px) {
    max-width: initial;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 110%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 110%;
    height: initial;
  }
`

const RightContent = styled.div`
  width: 48%;
  flex-grow: 1;
  @media only screen and (max-width: 1360px) {
    width: 100%;
  }
`

const RightImageContainer = styled.div`
  display: flex;
  padding-top: ${({ isEditing }) => (isEditing ? '45px' : '0')};
  @media only screen and (max-width: 1360px) {
    flex-direction: column;
  }
`

const SliderItemWrapper = styled.div`
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '55px 0 0 0' : '30px 0 0 0')};
  position: relative;
  box-sizing: border-box;
  .control-container {
    width: 100%;
  }
  .lazy-figure {
    padding-top: 110%;
    height: initial;
  }
`

const SliderIdx = styled.div`
  color: white;
  font-size: 18px;
  color: ${getStyleForProp('slideCounter')};
  display: flex;
  align-items: center;
  .slide-count {
    opacity: 0.6;
  }
  @media only screen and (max-width: 767px) {
    .slide-count {
    }
  }
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Content = styled.div`
  display: flex;
`

const SocialWrap = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 30px;
  bottom: 30px;
  box-sizing: border-box;
  color: ${getStyleForProp('heading')};
  word-breack: breack-word;
  @media only screen and (max-width: 1360px) {
    width: 100%;
    position: initial;
    bottom: initial;
    right: initial;
    margin: 40px 0 0 0;
  }
  .rf-add-btn {
    padding: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: initial;
    }
  }
`

const SocialIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 1360px) {
    flex-direction: row;
    width: 100%;
  }
  .link {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
`

const SliderActionButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  &#slider-1 {
    visibility: hidden;
  }
`

const IconContainer = styled.button`
  min-width: 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s linear;
  padding: 0;
  border: none;
  background: transparent;
  opacity: 0.6;
  transition: all 0.1s linear;
  &:hover {
    opacity: 1;
  }
  &:focus {
    outline: none !important;
  }
  @media only screen and (max-width: 767px) {
    min-width: 35px;
    width: 35px;
  }
`

const AddButtonWrap = styled.div`
  opacity: 0.6;
  transition: all 0.1s linear;
  &:hover {
    opacity: 1;
  }
`

const SliderArrowsInfo = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
`

const ImageWrap = styled.div`
  width: 48%;
  flex-grow: 1;
  font-size: 0;
  @media only screen and (max-width: 1360px) {
    width: 100%;
  }
`

const ImageFigure = styled.div`
  width: 100%;
  position: relative;
  padding-top: 90%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 90%;
    height: initial;
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const SliderItemsContainer = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: ${({ isOneSlide, isEditing }) =>
    isOneSlide && !isEditing ? '70px' : '0'};
  @media only screen and (max-width: 1360px) {
    padding-bottom: ${({ isOneSlide, isEditing }) =>
      isOneSlide && !isEditing ? '50px' : '0'};
  }
`

const SliderAddRemoveButtons = styled.div`
  width: 100%;
  position: absolute;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  .add-button-wrap {
    margin: 0 4px;
  }
`

const SocialIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('heading')
}))``

const SliderArrowIcons = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('slideCounter')
}))``

export default {
  WmCustomContainer,
  SliderWrapper,
  SliderItemWrapper,
  IconContainer,
  LeftContent,
  HeaderContent,
  Content,
  SocialWrap,
  HGroup,
  Img,
  SocialIcons,
  SocialIcon,
  Container,
  Title,
  Paragraph,
  SliderIdx,
  SliderActionButtons,
  AddButtonWrap,
  SliderArrowsInfo,
  RightContent,
  RightImageContainer,
  ImageWrap,
  ImageFigure,
  Image,
  ControlContainer,
  SocialText,
  SliderItemsContainer,
  SliderAddRemoveButtons,
  SliderArrowIcons
}
