// @flow

import React, { memo, useCallback } from 'react'

import * as Styled from './styled'
import { TPageItemProps } from './types'
import Options from './Options'
import HomeIcon from '@editor/common/assets/svgr-icons/Home_icon.svg'

const PageItem = ({
  path,
  isActive,
  removePage,
  duplicatePage,
  onClick,
  navigateToSettings
}: TPageItemProps) => {
  const isHome = path === '/home'
  const name = path.substr(1)

  const handleClick = useCallback((): void => {
    onClick(path)
  }, [path, onClick])

  return (
    <Styled.PageItem isActive={isActive} onClick={handleClick}>
      <Styled.PageName>
        {isHome ? <HomeIcon className="home-icon" /> : null}
        <span>{name}</span>
      </Styled.PageName>
      <Options
        pagePath={path}
        removePage={removePage}
        duplicatePage={duplicatePage}
        onClick={onClick}
        navigateToSettings={navigateToSettings}
      />
    </Styled.PageItem>
  )
}

export default memo(PageItem)
