/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header51.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  justify-content: center;
  padding: 0;
  min-height: 100vh;
  height: 100%;
  ${S.NavbarWrapper} {
    ${({ isEditing }) => (isEditing ? 'padding-top: 115px;' : '')}
  }

  & ${S.Container} {
    margin-bottom: 0;
    nav {
      margin-right: 30px;
    }
  }
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding: 485px 0 406px;
  ${({ isEditing }) => (isEditing ? 'position: relative;' : '')}
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  @media only screen and (max-width: 1440px) {
    padding: 385px 0 406px;
  }
  @media only screen and (max-width: 600px) {
    padding: 295px 0 326px;
  }
  @media only screen and (max-width: 457px) {
    padding: 295px 20px 300px;
  }
`

const HeaderContentContainer = styled.div`
  width: 90%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  & > .control-container {
    width: 100%;
    margin-bottom: 20px;
    > h2 {
      margin-bottom: 0;
      padding-top: 0;
    }
  }
  @media only screen and (max-width: 1440px) {
    max-width: 720px;
  }
  @media only screen and (max-width: 1150px) {
    width: 100%;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 0px;
    padding-right: 0px;
    box-sizing: border-box;
  }
  .buttons-block {
    position: absolute;
    left: 50%;
  }
  ${props =>
    props.onlyButton
      ? css`
          .buttons-block {
            top: 50%;
            transform: translate(-50%, -50%);
          }
        `
      : css`
          .buttons-block {
            bottom: -22px;
            transform: translateX(-50%);
          }
        `}
  > *:first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.h2`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  font-weight: 500;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`
const IconsWrapperInner = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media only screen and (max-width: 1440px) {
    max-width: 1200px;
  }
`

const IconsWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  @media only screen and (max-width: 457px) {
    margin-left: -20px;
  }

  .add-button-wrap {
    width: 100%;
    .image_1 {
      top: 100px;
      left: 27%;
      position: absolute;
      @media only screen and (max-width: 700px) {
        left: 12%;
      }
    }
    .image_2 {
      top: 90px;
      right: 30%;
      position: absolute;
      @media only screen and (max-width: 1150px) {
        right: 22%;
      }
      @media only screen and (max-width: 700px) {
        right: 5%;
      }
    }
    .image_3 {
      top: 35%;
      left: 7%;
      position: absolute;
      @media only screen and (min-width: 1439px) {
        left: 1%;
      }
      @media only screen and (max-width: 1300px) {
        bottom: 180px;
        top: auto;
        left: 4%;
      }
    }
    .image_4 {
      top: 30%;
      right: 10%;
      position: absolute;
      @media only screen and (min-width: 1439px) {
        right: 3%;
      }
      @media only screen and (max-width: 1300px) {
        bottom: 140px;
        top: auto;
        right: 10%;
      }
      @media only screen and (max-width: 850px) {
        right: 15%;
      }
      @media only screen and (max-width: 450px) {
        right: 5%;
      }
    }
    .image_5 {
      ${({ isEditing }) => (isEditing ? 'bottom: 200px;' : 'bottom: 135px;')}
      left: 43%;
      position: absolute;
      margin: 0 !important;
      @media only screen and (max-width: 1300px) {
        bottom: 115px;
        left: 35%;
      }
    }
  }
`

const ImageFrame = styled.div`
  background-color: inherit;
  height: max-content;
  position: absolute;
  border-radius: ${getStyleForProp('iconRadius')};
  transition: transform 0.3s ease-out;
  @media only screen and (max-width: 1024px) {
    transform: translate(0%, 0%) !important;
  }
  .image-state-overlay,
  .failed-image-container {
    z-index: 3;
  }
  &.image_1 {
    top: 160px;
    left: 18%;

    & > div {
      animation-duration: 6.4s;
      animation-delay: -4s;
    }
    @media only screen and (max-width: 1150px) {
      top: 125px;
    }
    @media only screen and (max-width: 1150px) {
      left: 13%;
    }
  }
  &.image_2 {
    top: 155px;
    right: 28%;
    & > div {
      animation-duration: 5.6s;
      animation-delay: -1.4s;
    }
    @media only screen and (max-width: 1150px) {
      right: 22%;
      top: 110px;
    }
    @media only screen and (max-width: 850px) {
      right: 14.5%;
    }
  }
  &.image_3 {
    top: 35%;
    ${({ isEditing }) => (isEditing ? 'left: 0%;' : 'left: -6%;')}

    & > div {
      animation-duration: 6.2s;
      animation-delay: -5s;
    }
    @media only screen and (max-width: 1300px) {
      bottom: 120px;
      top: auto;
      left: 4%;
    }
    @media only screen and (max-width: 850px) {
      bottom: 170px;
      left: 9%;
    }
  }
  &.image_4 {
    top: 30%;
    right: 0%;
    & > div {
      animation-duration: 5.8s;
      animation-delay: -1.2s;
    }

    @media only screen and (max-width: 1300px) {
      bottom: 120px;
      top: auto;
      right: 10%;
    }
    @media only screen and (max-width: 850px) {
      right: ${({ isEditing }) => (isEditing ? '17%' : '40px')};
      bottom: 170px;
    }
  }
  &.image_5 {
    bottom: 155px;
    left: 42%;
    & > div {
      animation-duration: 5s;
      animation-delay: -3s;
    }
    @media only screen and (max-width: 1300px) {
      bottom: 115px;
    }
    @media only screen and (max-width: 1150px) {
      left: 40%;
      bottom: 100px;
    }
  }
`
const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .lazy-figure {
    border-radius: ${getStyleForProp('iconRadius')};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const WindowMoveContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .buttons-block {
    justify-content: center;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    @media only screen and (max-width: 1440px) {
      max-width: 720px;
    }
    .buttons-wrapper {
      justify-content: center;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  transform: translateY(0px);
  ${({ isEditing, isAnimated }) =>
    !isEditing && isAnimated ? 'animation-name: downUp;' : ''}
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;

  @media only screen and (max-width: 1440px) {
    width: 150px;
    height: 150px;
  }
  @media only screen and (max-width: 600px) {
    width: 80px;
    height: 80px;
  }

  &:hover {
    animation-play-state: paused;
  }
  @keyframes downUp {
    0% {
      transform: translateY(0px);
    }

    100% {
      transform: translateY(50px);
      @media only screen and (max-width: 1150px) {
        transform: translateY(30px);
      }
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContentContainer,
  Title,
  Paragraph,
  IconsWrapper,
  WindowMoveContainer,
  ImageFrame,
  ControlContainer,
  ImageWrapper,
  IconsWrapperInner
}
