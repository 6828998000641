/* @flow */
import React from 'react'
import S from './Pricing6_2.styled'
import { validation } from './Pricing6_2.consts'
import ActionGetter from './Actions'
import { KEY_CODES } from '@website/conf/consts'
import Image from '@website/common/components/Image'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import ColorPicker from '@website/common/components/ColorPicker'
import EditableContent from '@website/common/components/EditableContent'
import { EditingContext, withDispatchContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

class Pricing6_2 extends React.PureComponent<void, void> {
  state = {
    animate: 0,
    activeIdx: 0,
    selectedMenuIdx: null,
    selectedBadgeIdx: null
  }
  removeAnimation = () =>
    setTimeout(() => {
      this.setState({
        animate: false
      })
    }, 300)

  setSelectedBadgeIdx = (selectedMenuIdx, selectedBadgeIdx) => {
    this.setState({ selectedMenuIdx, selectedBadgeIdx })
  }
  slidePrevious = () => {
    const { activeIdx } = this.state
    const {
      data: { slides }
    } = this.props
    const prevIdx = (activeIdx + slides.length - 1) % slides.length
    this.setState({ activeIdx: prevIdx, animate: true })
  }
  slideNext = () => {
    const { activeIdx } = this.state
    const {
      data: { slides }
    } = this.props
    const nextIdx = (activeIdx + 1) % slides.length
    this.setState({ activeIdx: nextIdx, animate: true })
  }
  renderLeftIcon = () => (
    <S.IconContainer
      className="wm-slider-btn"
      onClick={this.slidePrevious}
      tabIndex="0"
      onKeyDown={e => {
        if (e.keyCode === KEY_CODES.ENTER) {
          this.slidePrevious()
        }
      }}
    >
      <S.Icon size="large" name="keyboard_arrow_left" />
    </S.IconContainer>
  )
  renderRightIcon = () => (
    <S.IconContainer
      className="wm-slider-btn"
      onClick={this.slideNext}
      tabIndex="0"
      onKeyDown={e => {
        if (e.keyCode === KEY_CODES.ENTER) {
          this.slideNext()
        }
      }}
    >
      <S.Icon size="large" name="keyboard_arrow_right" />
    </S.IconContainer>
  )
  beforeRemove = () => {
    const { activeIdx } = this.state
    if (activeIdx !== 0) {
      this.setState({ activeIdx: activeIdx - 1 })
    }
  }
  closeColorPicker = () =>
    this.setState({ menuIdx: null, selectedBadgeIdx: null })
  renderContent = () => {
    const {
      data: { slides },
      componentDispatcher
    } = this.props

    const { animate, activeIdx, selectedMenuIdx, selectedBadgeIdx } = this.state
    const Actions = ActionGetter(componentDispatcher)
    return (
      <S.SliderItem className="slider-item">
        <ControlsWithModalOpener
          actions={Actions.blockActions({
            idx: activeIdx,
            showRemove: slides.length > validation.slides.min,
            beforeRemove: this.beforeRemove
          })}
          style={{ width: '100%' }}
          alignment="right"
        >
          <S.SlideContainer animate={animate}>
            <S.ImgFigure className="img-figure">
              <S.ControlContainer>
                <ControlsWithImageCropper
                  actions={Actions.sliderActions(
                    activeIdx,
                    slides[activeIdx].imageUrl
                  )}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    alt="Slider item"
                    onLoad={this.removeAnimation}
                    sizes={slides[activeIdx].imgDimensions}
                    defaultImgSrc={slides[activeIdx].imageUrl}
                    asProps={{
                      imgCropParams: slides[activeIdx].imgCropParams
                    }}
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
              <S.DotsWrap />
            </S.ImgFigure>
            <EditingContext.Consumer>
              {({ isEditing }) => {
                return (
                  <S.TextContainer isEditing={isEditing}>
                    <If
                      condition={activeIdx > 8}
                      then={() => <S.Count>{activeIdx + 1}</S.Count>}
                      otherwise={() => <S.Count>0{activeIdx + 1}</S.Count>}
                    />
                    <EditableContent
                      as={S.SliderTitle}
                      text={slides[activeIdx].slideTitle}
                      alignment={slides[activeIdx].slideTitleAlignment}
                      required={validation.slides.slideTitle.required}
                      maxCount={validation.maxHeadingChar}
                      className="WM_GLOBAL_heading42"
                      onChange={(newTitle: string) => {
                        Actions.changeSlideTitle(newTitle, activeIdx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeSlideTitleAlignment(alignment, activeIdx)
                      }
                    />
                    <S.MenuContent
                      className="menu-content"
                      setZindex={selectedBadgeIdx !== null}
                    >
                      {slides[activeIdx].menu.map((item, idx) => {
                        return (
                          <S.MenuItemWrapper key={idx}>
                            <ControlsWithModalOpener
                              style={{ width: '100%' }}
                              actions={Actions.menuItemActions(
                                idx,
                                activeIdx,
                                slides[activeIdx].menu.length
                              )}
                            >
                              <S.MenuItem>
                                <S.MenuItemTop>
                                  <S.TitleBadgeWrap>
                                    <EditableContent
                                      as={S.ItemName}
                                      text={item.title}
                                      maxCount={validation.maxSubtitleChar}
                                      onChange={(newItemName: string) => {
                                        Actions.changeItemTitle(
                                          newItemName,
                                          activeIdx,
                                          idx
                                        )
                                      }}
                                      className="WM_GLOBAL_primary-font"
                                    />
                                    <Show when={[item.badge.length > 0]}>
                                      <S.BadgeWrapper className="badge-wrapper">
                                        {item.badge.map((item, badgeIdx) => {
                                          return (
                                            <div key={badgeIdx}>
                                              <S.BadgeItemWrapper className="badge-item-wrapper">
                                                <ControlsWithModalOpener
                                                  actions={Actions.getBadgeActions(
                                                    activeIdx,
                                                    idx,
                                                    badgeIdx,
                                                    this.setSelectedBadgeIdx
                                                  )}
                                                >
                                                  <S.Badge color={item.color}>
                                                    <EditableContent
                                                      as={S.Div}
                                                      text={item.name}
                                                      maxCount={
                                                        validation.maxBagdeChar
                                                      }
                                                      className="WM_GLOBAL_secondary-font"
                                                      onChange={(
                                                        name: string
                                                      ) => {
                                                        Actions.changeBadgeName(
                                                          name,
                                                          activeIdx,
                                                          idx,
                                                          badgeIdx
                                                        )
                                                      }}
                                                    />
                                                    <If
                                                      condition={
                                                        selectedMenuIdx ===
                                                          idx &&
                                                        selectedBadgeIdx ===
                                                          badgeIdx
                                                      }
                                                      then={() => (
                                                        <ColorPicker
                                                          color={item.color}
                                                          onColorChoose={color => {
                                                            Actions.changeBadgeColor(
                                                              activeIdx,
                                                              idx,
                                                              badgeIdx,
                                                              color
                                                            )
                                                          }}
                                                          closeColorPicker={
                                                            this
                                                              .closeColorPicker
                                                          }
                                                        />
                                                      )}
                                                    />
                                                  </S.Badge>
                                                </ControlsWithModalOpener>
                                              </S.BadgeItemWrapper>
                                            </div>
                                          )
                                        })}
                                        <AddButton
                                          onAdd={() => {
                                            Actions.addBadgeItem(activeIdx, idx)
                                          }}
                                          toShow={
                                            slides[activeIdx].menu[idx].badge
                                              .length <
                                            validation.slides.menu.bagde.max
                                          }
                                          style={{ padding: '3px 8px' }}
                                        >
                                          +
                                        </AddButton>
                                      </S.BadgeWrapper>
                                    </Show>
                                  </S.TitleBadgeWrap>
                                  <EditableContent
                                    as={S.ItemPrice}
                                    text={item.price}
                                    required={
                                      validation.slides.menu.price.required
                                    }
                                    maxCount={validation.maxPriceChar}
                                    onChange={(newPrice: string) => {
                                      Actions.changeItemPrice(
                                        newPrice,
                                        activeIdx,
                                        idx
                                      )
                                    }}
                                  />
                                </S.MenuItemTop>
                                <S.Info className="info">
                                  <EditableContent
                                    as={S.ItemParagraph}
                                    text={item.paragraph}
                                    maxCount={validation.maxParagraphChar}
                                    className="WM_GLOBAL_secondary-font"
                                    onChange={(newParagraph: string) => {
                                      Actions.changeItemParagraph(
                                        newParagraph,
                                        activeIdx,
                                        idx
                                      )
                                    }}
                                  />
                                  <EditableContent
                                    as={S.ItemInfo}
                                    text={item.info}
                                    maxCount={validation.maxInfoChar}
                                    className="WM_GLOBAL_secondary-font"
                                    onChange={(newItemInfo: string) => {
                                      Actions.changeItemInfo(
                                        newItemInfo,
                                        activeIdx,
                                        idx
                                      )
                                    }}
                                    controlsAlignment="right"
                                  />
                                </S.Info>
                              </S.MenuItem>
                            </ControlsWithModalOpener>
                          </S.MenuItemWrapper>
                        )
                      })}
                      <AddButton
                        onAdd={() => {
                          Actions.addMenuItem(activeIdx)
                        }}
                        toShow={
                          slides[activeIdx].menu.length <
                          validation.slides.menu.max
                        }
                        style={{ marginTop: '2rem' }}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_item_label')}
                      </AddButton>
                    </S.MenuContent>
                    <If
                      condition={slides.length > 1}
                      then={() => (
                        <S.ButtonsWrap>
                          {this.renderLeftIcon()}
                          {this.renderRightIcon()}
                        </S.ButtonsWrap>
                      )}
                    />
                    <EditingContext.Consumer>
                      {({ isEditing }) => {
                        return (
                          <S.SlideCounter isEditing={isEditing}>
                            0{activeIdx + 1}
                          </S.SlideCounter>
                        )
                      }}
                    </EditingContext.Consumer>
                  </S.TextContainer>
                )
              }}
            </EditingContext.Consumer>
          </S.SlideContainer>
        </ControlsWithModalOpener>

        <AddButton
          onAdd={Actions.addSlide}
          toShow={slides.length < validation.slides.max}
          style={{
            margin: '2rem'
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </S.SliderItem>
    )
  }
  render() {
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.Container isEditing={isEditing}>
              <S.PricingContainer>{this.renderContent()}</S.PricingContainer>
            </S.Container>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

const Pricing6_2WithDispatchContext = withDispatchContext(Pricing6_2)

export default Pricing6_2WithDispatchContext
