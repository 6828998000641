import styled, { css } from 'styled-components'

import { BACKGROUND_IMG, MOBILE_BACKGROUND_IMG } from '../../consts'
import { SelectedSign as _SelectedSign } from '@editor/common/styled-components/choose-component-template'
import { gradientTextStyles, scrollBarStyles } from '@editor/common/styles'

export const TemplatesWrapper = styled.div`
  ${scrollBarStyles};
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow-y: auto;
  background-image: url(${BACKGROUND_IMG});
  background-repeat: no-repeat;
  background-size: 100% 620px;
  background-position-y: 150px;
  border-top: 1px solid #ffffff;
  @media only screen and (max-width: 1024px) {
    border-top: none;
  }
`

export const Title = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 26px;
  font-weight: 500;
  color: #252e48;
  padding: 50px 0 10px;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const GradientText = styled.p`
  ${gradientTextStyles};
  font-size: 26px;
  font-weight: 700;
  margin: 0 7px;
`

export const StickyElementWrapper = styled.div`
  width: calc(100% - 245px);
  height: 60px;
  position: fixed;
  top: 0;
  display: ${({ isSticky }) => (isSticky ? 'block' : 'none')};
  box-shadow: 0 6px 20px #387dff4d;
  border-bottom: 1px solid #8493bd;
  z-index: 2;
  background-color: #ffffff;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  @media only screen and (max-width: 1024px) {
    width: 100%;
    display: block;
    height: 120px;
  }
`

export const StickyElement = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  .icon-button {
    margin-left: 10px;
  }

  @media only screen and (min-width: 1025px) {
    &:before {
      content: '';
      position: absolute;
      top: -1px;
      left: -21px;
      background-color: #ffffff;
      height: 60px;
      width: 20px;
      box-sizing: border-box;
    }
  }

  @media only screen and (max-width: 1024px) {
    border-top: 1px solid #c1c9e0;
  }
`

export const SearchBarWrapper = styled.div`
  height: 60px;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

export const SearchBar = styled.div`
  height: 60px;
  width: 100%;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
`

export const MobileHeader = styled.div`
  width: 100%;
  height: 60px;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 700;
  color: #252e48;
  padding: 20px;
  box-sizing: border-box;
  background-image: url(${MOBILE_BACKGROUND_IMG});
  background-repeat: no-repeat;
  background-size: cover;
`

export const TemplatesThumbnails = styled.div`
  position: relative;
  flex-grow: 1;
  padding: 20px 40px 0;
  @media only screen and (max-width: 1024px) {
    padding: ${({ hasLargePaddingFromTop }) =>
      hasLargePaddingFromTop ? '140px 40px 0' : '20px 40px 0'};
  }
  @media only screen and (max-width: 767px) {
    padding: ${({ hasLargePaddingFromTop }) =>
      hasLargePaddingFromTop ? '140px 20px 0' : '20px 20px 0'};
  }
`

export const Suggestions = styled.div`
  padding: 0 40px;
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }
`

export const SuggestionsTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: #252e48;
  text-align: center;
  padding-bottom: 30px;
`

export const NotFoundMessage = styled.div`
  font-size: 14px;
  color: #545f7e;
  text-align: center;
  padding-bottom: 30px;

  @media only screen and (max-width: 1024px) {
    padding: 140px 10px 30px;
  }
`

export const ThumbnailsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ThumbnailsListItem = styled.div`
  width: calc(100% / 3 - 20px);
  margin: 0 30px 30px 0;

  @media only screen and (min-width: 1271px) {
    &:nth-child(3n) {
      margin: 0 0 30px 0;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1270px) {
    width: calc(100% / 2 - 10px);
    margin: 0 20px 20px 0;

    &:nth-child(2n) {
      margin: 0 0 20px 0;
    }
  }

  @media only screen and (max-width: 767px) {
    width: 100%;
    margin: 0 0 20px 0;
  }
`

export const SignLayer = styled.div`
  position: absolute;
  background-color: #ffffff;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  right: -4px;
  top: -3px;
`

export const SelectedSign = styled(_SelectedSign)`
  width: 10px;
  height: 10px;
  top: -1px;
  right: -2px;
  left: auto;
  z-index: 1;

  &:after {
    width: 8px;
    height: 8px;
  }
`

export const LoaderWrapper = styled.div`
  & > div {
    position: absolute;
    left: 50%;
    margin-bottom: 5px;

    ${({ isCentered }) =>
      isCentered
        ? css`
            bottom: 50%;
            transform: translate(-50%, -50%);
          `
        : css`
            bottom: 5px;
            transform: scale(0.8) translateX(-50%);
          `};
  }
`
