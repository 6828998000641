/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Contact2.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: ${getStyleForProp('orientation')};
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1050px) and (min-width: 779px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const TextItem = styled.div`
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    width: 100%;
    p {
      padding-top: 0;
      margin-bottom: 0;
    }
  }
  .control-container:nth-child(2) {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  padding-top: 20px;
  margin-bottom: 30px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const MapWrap = styled.div`
  width: 52%;
  height: 400px;
  &:only-child {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-top: 40px;
  }
  @media only screen and (max-width: 600px) {
    height: 250px;
  }
  @media only screen and (max-width: 400px) {
    height: 200px;
  }
`

const Map = styled.iframe`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 100%;
  height: 100%;
`

const LinksWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
  @media only screen and (max-width: 500px) {
    align-self: flex-start;
  }
  .link {
    color: ${getStyleForProp('anchor')};
    padding: 5px 0;
    transition: color 0.1s linear;
    max-width: 350px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
      color: ${({ theme }) => theme.palette.primaryLight};
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextItem,
  Title,
  Description,
  Map,
  LinksWrap,
  MapWrap
}
