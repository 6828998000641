/* @flow */
import React, { useContext } from 'react'
import S from './Steps3.styled'
import ActionGetter from './Actions'
import { validation } from './Steps3.consts'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Steps4 = props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      steps,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <WMContainer>
        <S.TextContainer>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Description}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.TextContainer>
        <S.StepsContainer>
          <Mapper
            data={steps}
            render={(step: string, idx: number) => (
              <S.ControlWrapper>
                <ControlsWithModalOpener
                  key={idx}
                  actions={Actions.stepActions({
                    idx,
                    showRemove: steps.length > validation.steps.min
                  })}
                >
                  <S.StepWrap className={idx % 2 === 1 ? 'left' : 'right'}>
                    <S.StepCounter>{idx + 1}</S.StepCounter>
                    <S.StepItemHeading>
                      <ControlsWithModalOpener
                        actions={Actions.iconActions({
                          idx,
                          showRemove: false
                        })}
                      >
                        <S.Circle>
                          <Icon name={step.icon} color="white" size="large" />
                        </S.Circle>
                      </ControlsWithModalOpener>
                      <EditableContent
                        text={step.stepHeading}
                        as={S.StepTitle}
                        maxCount={validation.blockTitleMaxChar}
                        className="WM_GLOBAL_heading20"
                        onChange={(title: string) => {
                          Actions.changeBlockTitle(title, idx)
                        }}
                      />
                    </S.StepItemHeading>
                    <EditableContent
                      text={step.stepDescription}
                      as={S.StepDescription}
                      alignment={step.stepDescriptionAlignment}
                      maxCount={validation.blockParagraphMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(paragraph: string) => {
                        Actions.changeBlockParagraph(paragraph, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeStepDescriptionAlignment(alignment, idx)
                      }
                    />
                  </S.StepWrap>
                </ControlsWithModalOpener>
              </S.ControlWrapper>
            )}
          />
        </S.StepsContainer>
      </WMContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={steps.length < validation.steps.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_step_label')}
      </AddButton>
    </S.StyledContainer>
  )
}

export default Steps4
