/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header11.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const stylesByColor = {
  default: '',
  dark: css`
    filter: brightness(0.14);
  `,
  light: css`
    filter: brightness(0.97);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0;
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${({ topOffset }) => `${topOffset + 110}px`};
  padding-bottom: 110px;
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 1024px) {
    padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
    padding-bottom: 60px;
  }
  @media only screen and (max-width: 457px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
    padding-bottom: 50px;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 60px;
  @media only screen and (max-width: 1200px) {
    margin: 0;
    max-width: 700px;
    align-items: center;
  }
  @media only screen and (max-width: 1500px) {
    .buttons-block {
      margin-left: -15px;
    }
  }
  @media only screen and (max-width: 1200px) {
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
      margin-left: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-left: 15px;
  width: 100%;
  margin-bottom: 0;
  & + p {
    margin-top: 20px;
  }
  @media only screen and (max-width: 1500px) {
    margin-left: 0;
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-left: 15px;
  width: 100%;
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  line-height: 1.4;
  @media only screen and (max-width: 1500px) {
    margin-left: 0;
  }
`

const NotebookWrap = styled.div`
  width: 100%;
  position: relative;
  padding-top: 62.25%;
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .styled-controls {
    top: -75px;
    @media only screen and (max-width: 650px) {
      top: -65px;
    }
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const NoteBookHeader = styled.div`
  width: 100%;
  max-width: 585px;
  padding: 18px 45px 60px 45px;
  box-sizing: border-box;
  border-radius: 18px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 40px;
  }
  @media only screen and (max-width: 650px) {
    padding: 14px 28px 45px 28px;
  }
  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/notebook-background-light-1.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    ${getStyleByColor};
  }
`

export default {
  HeaderContent,
  StyledContainer,
  Title,
  Description,
  NotebookWrap,
  Img,
  WmCustomContainer,
  NoteBookHeader
}
