export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    imagePrimaryColor: p => p.primaryLight,
    imageSecondaryColor: p => p.secondaryDark,
    imageTertiaryColor: p => p.secondaryLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => p.tertiaryLight,
    imagePrimaryColor: p => p.primaryDark,
    imageSecondaryColor: p => p.primaryLight,
    imageTertiaryColor: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => p.tertiaryExtraLight,
    imagePrimaryColor: p => p.primaryDark,
    imageSecondaryColor: p => p.primaryLight,
    imageTertiaryColor: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryLight
  }
}
