/* @flow */
import {
  iconActionMaker,
} from '@website/components/ActionMakers'
import { addIcon, changeParagraph } from '@website/components/CommonActions'

export default dispatcher => {
  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  return {
    changeParagraph: _changeParagraph,
    getIconActions: iconActionMaker(dispatcher),
    addIcon: () => dispatcher(addIcon(0))
  }
}
