export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  checkmarkMaxChar: 60,
  features: {
    min: 1,
    max: 10
  },
  buttons: {
    min: 0,
    max: 2
  }
}
