// @flow

import React, { useContext } from 'react'
import { connect } from 'react-redux'

import {
  getLinkSharePopupState,
  selectPublishPopupState,
  isWarningModalOpened,
  selectResetChangesPopupState,
  selectSemrushKeywordPopupState
} from '@selectors'
import ErrorPopup from '@editor/common/components/ErrorPopup'
import LinkSharePopup from '@editor/common/components/LinkSharePopup'
import PublishPopup from '@editor/common/components/PublishPopup'
import LinkShareInfoPopup from '@editor/common/components/LinkShareInfoPopup'
import WarningModal from '@editor/common/components/WarningModal'
import MaintenanceModal from '@editor/common/components/MaintenanceModal'
import UpgradePopup from '@editor/common/components/UpgradePopup'
import ResetChangesPopup from '@editor/common/components/ResetChangesPopup'
import StatusChangeAndPublishPopup from '@editor/common/components/StatusChangeAndPublishPopup'
import NewFeaturesPopup from '@editor/common/components/NewFeaturesPopup'
import SemrushKeywordPopup from '@editor/common/components/SemrushKeywordPopup'
import EventCreationPopup from '@editor/common/components/EventCreationPopup'
import { UpgradePopupContext } from '@contexts'
import ConfirmDialog from '@editor/common/components/ConfirmDialog'
import {
  getNoLongerPremiumPopupState,
  selectEventCreationPopupVisibilityState
} from '@selectors/editor'
import NoLongerPremiumPopup from '@editor/common/components/NoLongerPremiumPopup'

const Popups = ({
  isLinkSharePopupOpen,
  isPublishPopupOpen,
  warningModalText,
  isResetChangesPopupOpen,
  isNoLongerPremiumPopupOpen,
  isSemrushKeywordPopupOpen,
  isEventCreationPopupOpen
}) => {
  const { upgradePopupState } = useContext(UpgradePopupContext)

  return (
    <>
      <ErrorPopup />
      <NewFeaturesPopup />
      <MaintenanceModal />
      <ConfirmDialog />
      <LinkShareInfoPopup />
      <StatusChangeAndPublishPopup />
      {isNoLongerPremiumPopupOpen && <NoLongerPremiumPopup />}
      {isSemrushKeywordPopupOpen && <SemrushKeywordPopup />}
      {isLinkSharePopupOpen && <LinkSharePopup />}
      {isPublishPopupOpen && <PublishPopup />}
      {warningModalText && <WarningModal />}
      {isEventCreationPopupOpen && <EventCreationPopup />}
      {upgradePopupState.isOpen && <UpgradePopup />}
      {isResetChangesPopupOpen && <ResetChangesPopup />}
    </>
  )
}

const mapStateToProps = (state: TAppState) => ({
  isLinkSharePopupOpen: getLinkSharePopupState(state),
  isPublishPopupOpen: selectPublishPopupState(state),
  warningModalText: isWarningModalOpened(state),
  isResetChangesPopupOpen: selectResetChangesPopupState(state).isOpen,
  isNoLongerPremiumPopupOpen: getNoLongerPremiumPopupState(state),
  isSemrushKeywordPopupOpen: selectSemrushKeywordPopupState(state),
  isEventCreationPopupOpen: selectEventCreationPopupVisibilityState(state)
})

export default connect(mapStateToProps)(Popups)
