/* @flow */
export const getShareableLink = (authHash, siteId) => {
  return `https://www.renderforest.com/website-maker/${siteId}/lang/edit?authHash=${authHash}`
  // return `http://t.renderforest.com:3000/?siteId=${getSiteId()}&authHash=${link}`
  // return `https://test1.renderforest.com/website-maker/${getSiteId()}?authHash=${link}`
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]
export const getFormatedDate = date => {
  return `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`
}
