//@flow

import React, { memo, useContext, useCallback } from 'react'
import * as S from './styled'
import {
  ConfirmDialogContext,
  confirmDialogInitialProps
} from '@contexts/ConfirmDialog.context'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'

const ConfirmDialog = () => {
  const {
    props: {
      isOpen,
      onConfirm,
      onCancel,
      title,
      content,
      description,
      buttonTexts = {},
      isWarning
    },
    setProps
  } = useContext(ConfirmDialogContext)

  const closeDialog = useCallback(() => {
    setProps(confirmDialogInitialProps)
    onCancel && onCancel()
  }, [onCancel, setProps])

  return (
    <S.ConfirmPopup
      isOpen={isOpen}
      isWarningPopup={isWarning}
      onClose={closeDialog}
      header={<S.PopupHeader>{title}</S.PopupHeader>}
      footer={
        <S.PopupFooter>
          <S.CancelButton onClick={closeDialog} isWarningPopup={isWarning}>
            {buttonTexts.cancel}
          </S.CancelButton>
          <S.ConfirmButton onClick={onConfirm} isWarningPopup={isWarning}>
            {buttonTexts.confirm}
          </S.ConfirmButton>
        </S.PopupFooter>
      }
    >
      {content ? (
        isWarning ? (
          <S.WarningContent>
            <InfoIcon />
            <span>{content}</span>
          </S.WarningContent>
        ) : (
          <S.Content>{content}</S.Content>
        )
      ) : null}
      {description ? <S.Description>{description}</S.Description> : null}
    </S.ConfirmPopup>
  )
}

export default memo(ConfirmDialog)
