/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_ROW': {
        const idx = action.value
        draft.gallery.splice(idx + 1, 0, initialState.gallery[0])
        break
      }
      case 'REMOVE_ROW':
        const idx = action.value
        draft.gallery.splice(idx, 1)
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, itemIdx, dimensions } = action.value
        draft.gallery[idx][itemIdx].backgroundImg = url
        draft.gallery[idx][itemIdx].backgroundImgDimensions = dimensions || {}
        draft.gallery[idx][itemIdx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx, itemIdx } = action.value
        draft.gallery[idx][itemIdx].imgCropParams = imgCropParams
      }
    }
  })
}
