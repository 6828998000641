import styled from 'styled-components'
import { styleProvider } from '../../utils'
const getStyleForProp = styleProvider({})

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
  height: 50px;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('inputRadius')};
  &:focus {
    outline: none;
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

const Asterisk = styled.span`
  font-size: 16px;
  padding-left: 5px;
`

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 400 !important;
  position: relative;
  word-break: break-word;
`

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: -15px;
  color: #eb0c00;
  font-size: 12px;
  font-weight: 500 !important;
  > svg {
    margin-right: 5px;
    width: 12px;
    height: 12px;
  }
`

const LabelWrapper = styled.div`
  display: flex;
  > div {
    > svg {
      max-width: 12px;
      max-height: 12px;
    }
  }
`

const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

export default {
  InputContainer,
  Asterisk,
  Input,
  Label,
  ErrorMessage,
  LabelWrapper,
  ErrorIcon
}
