import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const Container = styled.div`
  width: 100%;
`

export const Title = styled.h3`
  padding: 15px 15px 10px;
  font-size: 12px;
  font-weight: 700;
  color: #252e48;
`

export const ListItem = styled.li`
  padding: 10px 15px;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${secondary.SemiDark};
  font-weight: 500;
  & svg {
    fill: ${secondary.Normal};
  }
  @media only screen and (max-width: 1024px) {
    & svg {
      width: 20px;
      height: auto;
    }
  }
  ${({ isDisabled }) =>
    isDisabled
      ? css`
          cursor: default;
          opacity: 0.5;
        `
      : css`
          &:hover {
            background-color: #eef5ff;
            & svg {
              fill: #363f5a;
            }
          }
        `}
`

export const IconWrapper = styled.span`
  min-width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #eef5ff;
  border-radius: 5px;
  @media only screen and (max-width: 767px) {
    min-width: 30px;
    height: 30px;
  }
`

export const ItemText = styled.span`
  font-size: 14px;
  margin: 0 10px;
`
