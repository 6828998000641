/* @flow */
import styled from 'styled-components'

import THEME from './Feature20.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${props => (props.isEditing ? '130px 0 80px 0' : '0')};
  background: ${getStyleForProp('background')};
  @media only screen and (max-width: 768px) {
    padding: ${props => (props.isEditing ? '140px 0 80px 0' : '0')};
  }
`

const WmCustomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const BlockWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1100px) {
    &.block-wrapper1 {
      .map-wrapper .col:nth-child(odd) {
        flex-direction: row-reverse;
      }
      .map-wrapper .col:nth-child(even) {
        flex-direction: row;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    &.block-wrapper1 {
      .map-wrapper .col {
        flex-direction: column !important;
      }
    }
  }
`

const MapWrapper = styled.div`
  display: flex;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`

const Col = styled.div`
  width: 30%;
  flex-grow: 1;
  display: flex;
  flex-direction: ${props => (props.isEven ? 'column-reverse' : 'column')};
  @media only screen and (max-width: 1100px) {
    width: 100%;
    flex-direction: ${props => (props.isEven ? 'row-reverse' : 'row')};
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const BlockItem = styled.div``

const BlockContent = styled.article`
  position: relative;
  padding-top: 74%;
  @media only screen and (max-width: 1100px) {
    width: 45%;
    flex-grow: 1;
    padding: 0;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  @media only screen and (max-width: 457px) {
    ${({ isEditing }) => (isEditing ? 'margin-top: 40px;' : '')};
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  @media only screen and (max-width: 767px) {
    min-height: 220px;
    position: initial;
    transform: initial;
    width: 100%;
    ${({ isEditing }) => (isEditing ? 'margin-top: 30px;' : '')}
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h2`
  margin: 0;
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
`

const Paragraph = styled.p`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
`

const ImageWrapper = styled.div`
  font-size: 0;
  @media only screen and (max-width: 1100px) {
    width: 45%;
    flex-grow: 1;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 74%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 74%;
    height: initial;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const AddButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`

const AnchorWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 16px !important;
    color: ${getStyleForProp('link')};
  }
  .learn-more-link {
    font-weight: 500;
    align-items: center;
    .arrow-right {
      margin-left: 0;
      padding-left: 5px;
      min-width: 24px;
      transition: transform linear 0.15s;
    }
    &:hover {
      opacity: 0.7;
      .arrow-right {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
  .styled-controls {
    transform: translate3d(0, 0, 0);
  }
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: getStyleForProp('link')({ theme })
}))``

export default {
  Container,
  WmCustomContainer,
  Col,
  BlockItem,
  BlockContent,
  Content,
  Title,
  Paragraph,
  ImageWrapper,
  Img,
  AddButtonsWrap,
  BlockWrapper,
  MapWrapper,
  AnchorWrapper,
  ControlContainer,
  Icon
}
