/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature20.styled'
import { validation } from './Feature20.consts'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext } from '@contexts'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import If from '@website/common/components/Conditional'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Feature20 = memo(({ data: { blocks } }) => {
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.Container isEditing={isEditing}>
      <S.WmCustomContainer>
        <Mapper
          data={blocks}
          render={(block: string, idx: number) => {
            return (
              <S.BlockWrapper key={idx} className={'block-wrapper' + idx}>
                <S.MapWrapper className="map-wrapper">
                  <Mapper
                    data={block}
                    render={(item: string, itemIdx: number) => {
                      return (
                        <S.Col
                          key={itemIdx}
                          isEven={itemIdx === 1 || itemIdx === 4}
                          className={'col col-' + itemIdx}
                        >
                          <S.ImageWrapper>
                            <S.ControlContainer>
                              <ControlsWithImageCropper
                                actions={Actions.imageActions(
                                  idx,
                                  itemIdx,
                                  item.imgUrl
                                )}
                                style={{ width: '100%' }}
                              >
                                <Image
                                  as={S.Img}
                                  sizes={item.ImgDimensions}
                                  defaultImgSrc={item.imgUrl}
                                  asProps={{
                                    imgCropParams: item.imgCropParams
                                  }}
                                  alt="Portfolio image"
                                />
                              </ControlsWithImageCropper>
                            </S.ControlContainer>
                          </S.ImageWrapper>
                          <S.BlockContent isEditing={isEditing}>
                            <S.Content isEditing={isEditing}>
                              <EditableContent
                                text={item.title}
                                as={S.Title}
                                alignment={item.blockTitleAlignment}
                                required={validation.title.required}
                                maxCount={validation.titleMaxChar}
                                className="WM_GLOBAL_heading32"
                                onChange={(newTitle: string) =>
                                  Actions.changeTitle(newTitle, idx, itemIdx)
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockTitleAlignment(
                                    alignment,
                                    idx,
                                    itemIdx
                                  )
                                }
                              />
                              <EditableContent
                                text={item.paragraph}
                                alignment={item.blockParagraphAlignment}
                                as={S.Paragraph}
                                maxCount={validation.paragraphMaxChar}
                                className="WM_GLOBAL_paragraph18"
                                onChange={(newTitle: string) =>
                                  Actions.changeParagraph(
                                    newTitle,
                                    idx,
                                    itemIdx
                                  )
                                }
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockParagraphAlignment(
                                    alignment,
                                    idx,
                                    itemIdx
                                  )
                                }
                              />
                              <If
                                condition={
                                  item.additionalText && item.additionalUrl
                                }
                                otherwise={() => (
                                  <AddButton
                                    onAdd={() => Actions.addLink(idx, itemIdx)}
                                    toShow={item.additionalText === ''}
                                    style={{ marginTop: '20px' }}
                                  >
                                    <Icon
                                      name="glyph-add"
                                      className="icon"
                                      size="normal"
                                    />
                                    {translate('add_link_label')}
                                  </AddButton>
                                )}
                                then={() => (
                                  <S.AnchorWrapper>
                                    <ControlsWithModalOpener
                                      actions={Actions.linkActions(
                                        idx,
                                        itemIdx,
                                        {
                                          text: item.additionalText,
                                          url: item.additionalUrl
                                        }
                                      )}
                                    >
                                      <LinkWrapper
                                        url={item.additionalUrl}
                                        text={item.additionalText}
                                        linkProps={{
                                          className:
                                            'WM_GLOBAL_paragraph14 learn-more-link'
                                        }}
                                      >
                                        <S.Icon
                                          size="large"
                                          name="keyboard_arrow_right"
                                          className="arrow-right"
                                        />
                                      </LinkWrapper>
                                    </ControlsWithModalOpener>
                                  </S.AnchorWrapper>
                                )}
                              />
                            </S.Content>
                          </S.BlockContent>
                        </S.Col>
                      )
                    }}
                  />
                </S.MapWrapper>
                <If
                  condition={isEditing}
                  then={() => (
                    <S.AddButtonsWrap>
                      <AddButton
                        onAdd={Actions.addBlock}
                        toShow={blocks.length < validation.blocks.max}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_block_label')}
                      </AddButton>
                      <AddButton
                        onAdd={() => {
                          Actions.removeBlock(idx)
                        }}
                        toShow={blocks.length > validation.blocks.min}
                        style={{
                          marginTop: '40px',
                          display: 'inline-flex',
                          alignItems: 'center'
                        }}
                      >
                        <Icon
                          name="glyph-remove"
                          className="icon"
                          size="normal"
                        />
                        {translate('remove_block_label')}
                      </AddButton>
                    </S.AddButtonsWrap>
                  )}
                />
              </S.BlockWrapper>
            )
          }}
        />
      </S.WmCustomContainer>
    </S.Container>
  )
})

export default Feature20
