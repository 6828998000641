/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Team5.theme'
import {
  styleProvider,
  getBackground,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  padding: ${({ isEditing }) => (isEditing ? '120px 0 80px 0' : '80px 0')};
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  padding-top: 0;

  @media only screen and (min-width: 768px) {
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'row-reverse'
          : 'row'};
      `}
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 767px) {
    align-items: center;
    & > div:first-child {
      ${({ theme }) =>
        css`
          margin: ${theme.orientation === 'RIGHT'
            ? '40px auto 0 auto'
            : '0 auto 40px auto'};
        `}
    }
  }
`

const TextContent = styled.div`
  width: 50%;
  max-width: 396px;
  text-align: left;
  > :first-child {
    margin-top: 0;
  }
  .paragraph2 {
    margin: 24px 0 0 0;
    ${getTextStyleByAlignment};
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
`

const TextImageContent = styled.div`
  width: 44%;
  max-width: 400px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    margin-top: 0;
  }
  &:only-child {
    margin: 0 auto;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
  > .control-container:first-child,
  > .control-container:nth-child(2) {
    width: 100%;
    @media only screen and (max-width: 768px) {
      .styled-controls {
        left: unset;
        right: 0;
      }
    }
  }
  > .control-container:nth-child(2) {
    margin-top: 20px;
    p {
      margin-top: 0;
    }
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Slogan = styled.p`
  color: ${getStyleForProp('slogan')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Name = styled.p`
  color: ${getStyleForProp('heading')};
  margin: 20px 0 0 0;
  font-weight: 500;
  ${getTextStyleByAlignment};
  width: 100%;
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 274px;
  margin: 50px 0 0 0;
  .lazy-figure {
    padding-top: 100%;
    border-radius: ${getStyleForProp('iconRadius')};
  }
  @media only screen and (max-width: 992px) {
    margin: 40px 0 0 0;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Paragraph,
  Slogan,
  Name,
  TextImageContent,
  TextContent,
  ImageWrapper,
  Img,
  ControlContainer
}
