export const validation = {
  blockTitle: { required: true },
  titleMaxChar: 50,
  blockTitleMaxChar: 150,
  blockParagraphMaxChar: 200,
  blocks: {
    min: 1,
    max: 6
  }
}
