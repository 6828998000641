import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    ListItem: p => p.accentWhite,
    icon: p => p.primaryLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    ListItem: p => p.tertiaryDark,
    icon: p => p.primaryDark,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    ListItem: p => p.tertiaryDark,
    icon: p => p.primaryDark,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
