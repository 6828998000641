/* @flow */

import React, {
  useContext,
  useCallback,
  useState,
  useRef,
  useEffect,
  useMemo
} from 'react'
import { withTheme } from 'styled-components'
import S from './NavBar.styled'
import If from '../Conditional'
import ActionGetter from './Actions'
import AddLogoButton from './AddLogoButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ImageResizer from '../ImageResizer'
import ProjectLinks from '@website/common/components/ProjectLinks'
import LanguagesMenu from '@website/common/components/LanguagesMenu'
import LinkWrapper from '../LinkWrapper'
import type { TNavBar } from './Type.flow.js'

import {
  DispatchContext,
  EditingContext,
  SiteIdContext,
  WebsiteLanguagesContext
} from '@contexts'
import { consumeHeaderNavbarContext } from '@contexts/HeaderNavBar.context'
import { useEventListener } from '@hooks'
import { useResponsive } from '@shared/hooks'

const Link = p => <a {...p}>{p.children}</a>

const NavBar = ({
  logoProps,
  type = 'header',
  linksProps = {},
  theme,
  alwaysShowBackground,
  handleHeightChange,
  setNavBarHeight
}: TNavBar) => {
  const [scrollTop, setScrollTop] = useState(0)
  const navBarWrapperRef = useRef(null)

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const siteId = useContext(SiteIdContext)
  const websiteLanguages = useContext(WebsiteLanguagesContext)
  const isSmallSizes = useResponsive(1024)

  const Actions = ActionGetter(dispatcher)
  const isNavBarFixed = scrollTop > navBarWrapperRef.current?.offsetHeight / 2
  const filteredWebsiteLanguagesData = useMemo(
    () =>
      websiteLanguages?.data?.filter(language => language.status === 'active'),
    [websiteLanguages]
  )

  const isWebsiteMultilingual =
    filteredWebsiteLanguagesData && filteredWebsiteLanguagesData.length > 1

  useEffect(() => {
    if (navBarWrapperRef.current) {
      setTimeout(() => {
        const height = isEditing ? 0 : navBarWrapperRef.current.offsetHeight
        setNavBarHeight(height)
        handleHeightChange && handleHeightChange(height)
      })
    }
  }, [navBarWrapperRef.current, isEditing])

  const handleScroll = useCallback(e => {
    const { scrollingElement } = e.target
    if (!scrollingElement) {
      return
    }
    const scrollTop = Math.max(scrollingElement.scrollTop, 0)
    setScrollTop(scrollTop)
  }, [])

  const handleLogoLoad = useCallback(() => {
    if (navBarWrapperRef.current) {
      setTimeout(() => {
        const height = isEditing ? 0 : navBarWrapperRef.current.offsetHeight
        setNavBarHeight(height)
        handleHeightChange && handleHeightChange(height)
      })
    }
  }, [isEditing])

  useEventListener('scroll', handleScroll, true)

  return (
    <S.NavbarWrapper
      isEditing={isEditing}
      isFixed={!isEditing}
      showBackground={alwaysShowBackground || isNavBarFixed}
      showShadow={isNavBarFixed}
      Theme={theme}
      ref={navBarWrapperRef}
    >
      <S.Container
        type={type}
        isEditing={isEditing}
        className="WM_GLOBAL_secondary-font header-nav-bar"
        isLogoRemoved={!isEditing && logoProps && !logoProps.logoSrc}
      >
        <If
          condition={!isEditing && !logoProps.logoSrc}
          then={() => null}
          otherwise={() => (
            <If
              condition={!logoProps.logoSrc}
              then={() => <AddLogoButton action={Actions.changeNavbarLogo} />}
              otherwise={() => (
                <S.LogoContainer
                  className="logoContainer"
                  isEditing={isEditing}
                  decreasePadding={isNavBarFixed}
                >
                  <ControlsWithModalOpener
                    actions={Actions.logoActions(
                      siteId,
                      !!logoProps.logoSrc,
                      logoProps.logoColor,
                      logoProps.logoSrc
                    )}
                  >
                    <figure>
                      <LinkWrapper url="/" as={Link}>
                        <ImageResizer
                          imageSrc={logoProps.logoSrc}
                          scrollTop={scrollTop}
                          color={logoProps.logoColor}
                          imageDimensions={logoProps.logoSizes}
                          alt="Navbar logo"
                          initialWidth={logoProps.logoWidth}
                          action={Actions.resizeAction}
                          onLoad={handleLogoLoad}
                        />
                      </LinkWrapper>
                    </figure>
                  </ControlsWithModalOpener>
                </S.LogoContainer>
              )}
            />
          )}
        />
        <ProjectLinks
          {...linksProps}
          isNavBarFixed={isNavBarFixed}
          isWebsiteMultilingual={isWebsiteMultilingual}
          websiteLanguages={websiteLanguages}
        />
        {isWebsiteMultilingual && !isSmallSizes ? (
          <LanguagesMenu websiteLanguages={websiteLanguages} />
        ) : null}
      </S.Container>
    </S.NavbarWrapper>
  )
}

export default withTheme(consumeHeaderNavbarContext(NavBar))
