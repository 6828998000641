/* @flow */
import {
  removeBlockActionMaker,
  iconActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeBlockTitle,
  changeTitleAlignment,
  changeParagraphAlignment,
  addBlock
} from '@website/components/CommonActions'

export default dispatcher => {
  const imageActions = (idx: number, imageUrl: string) => {
    return cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature22',
      additionalArgs: { idx },
      showRemove: false,
      imageUrl
    })
  }

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeBlockTitle = (newTitle, idx) => {
    dispatcher(changeBlockTitle(newTitle, idx))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle: _changeBlockTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addBlock: () => dispatcher(addBlock()),
    imageActions,
    iconActions: idx => [
      ...iconActionMaker(dispatcher)({
        idx,
        showRemove: false
      })
    ],
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
