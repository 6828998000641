/* @flow */
import React, { useContext, memo } from 'react'
import S from './Pricing4.styled'
import { validation } from './Pricing4.consts'
import ActionGetter from './Actions'
import Badge from '@website/common/components/Badge'
import If from '@website/common/components/Conditional'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'

const Pricing4 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      plans,
      primaryPlanIdx,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.PricingHeaderContainer>
          <EditableContent
            text={title}
            as={S.Heading}
            alignment={titleAlignment}
            className="WM_GLOBAL_heading"
            maxCount={validation.headingMaxChar}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.PricingHeaderContainer>
        <S.PricingCardsContainer>
          {plans.map((plan, planIndex) => {
            const isPrimary = planIndex === primaryPlanIdx

            return (
              <S.PriceCardWrapper isPrimary={isPrimary} key={planIndex}>
                <ControlsWithModalOpener
                  key={planIndex}
                  actions={Actions.cardActions({
                    idx: planIndex,
                    showStar: !isPrimary,
                    showRemove: false
                  })}
                >
                  <S.PriceCard
                    className="WM_GLOBAL_secondary-font"
                    isPrimary={isPrimary}
                    isEditing={isEditing}
                  >
                    <If condition={isPrimary} then={() => <Badge />} />
                    <S.ContentBody isPrimary={isPrimary}>
                      <S.PricingCardHeader>
                        <ControlsWithModalOpener
                          actions={Actions.iconActions({
                            idx: planIndex,
                            showRemove: false
                          })}
                        >
                          <S.Icon
                            name={plan.icon}
                            size="xxlarge"
                            isPrimary={isPrimary}
                          />
                        </ControlsWithModalOpener>
                        <EditableContent
                          text={plan.title}
                          as={S.Title}
                          alignment={plan.blockTitleAlignment}
                          maxCount={validation.priceTitleMaxChar}
                          onChange={value => {
                            Actions.changePlanTitle(value, planIndex)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockTitleAlignment(
                              alignment,
                              planIndex
                            )
                          }
                        />
                        <EditableContent
                          text={plan.price}
                          as={S.Price}
                          alignment={plan.priceAlignment}
                          maxCount={validation.priceMaxChar}
                          onChange={newPrice =>
                            Actions.changePlanPrice(newPrice, planIndex)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changePriceAlignment(alignment, planIndex)
                          }
                        />
                      </S.PricingCardHeader>
                      <S.PricingCardcontent>
                        <EditableContent
                          text={plan.option}
                          as={S.Option}
                          alignment={plan.optionAlignment}
                          maxCount={validation.optionMaxChar}
                          onChange={
                            (planIndex,
                            paragraph =>
                              Actions.changePlanParagraph(paragraph, planIndex))
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeOptionAlignment(alignment, planIndex)
                          }
                        />
                      </S.PricingCardcontent>
                      <S.ButtonWrapper isPrimary={isPrimary}>
                        <S.PricingCardFooter>
                          <ControlsWithModalOpener
                            actions={Actions.btnActions(
                              planIndex,
                              {
                                text: plan.buttonText,
                                link: plan.buttonUrl
                              },
                              false
                            )}
                            style={{ width: '100%' }}
                          >
                            <LinkWrapper
                              linkProps={{
                                className: 'rf-comp-btn'
                              }}
                              text={plan.buttonText}
                              url={plan.buttonUrl}
                            />
                          </ControlsWithModalOpener>
                        </S.PricingCardFooter>
                      </S.ButtonWrapper>
                    </S.ContentBody>
                  </S.PriceCard>
                </ControlsWithModalOpener>
              </S.PriceCardWrapper>
            )
          })}
        </S.PricingCardsContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Pricing4
