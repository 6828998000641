import styled from 'styled-components'

export const SavingChanges = styled.div`
  height: 40px;
  box-sizing: border-box;
  padding: 9px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  box-shadow: 2px 2px 8px #387dff4d;
  border-radius: 5px;
  margin: ${({ isExpanded }) => (isExpanded ? '15px' : '15px 10px')};
  cursor: default;
  font-size: 12px;
  color: #545f7e;
  transition: all 0.3s ease-out;
`

export const LoadingText = styled.p`
  min-width: 107px;
  font-size: 12px;
  color: #545f7e;
  padding-left: 10px;
  box-sizing: border-box;
`

export const LoadingNut = styled.div`
  position: relative;
  height: 22px;
  width: 18px;
  display: inline-flex;
  justify-content: center;
  img {
    height: 100%;
    width: 14px;
    animation: animateLoadingNut 1.2s infinite;
    animation-timing-function: ease-in-out;
    @keyframes animateLoadingNut {
      0% {
        transform: translateY(0);
      }
      50% {
        transform: translateY(-1px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  &::after {
    content: '';
    position: absolute;
    height: 2px;
    width: 14px;
    background-color: #ededed;
    top: 22px;
    border-radius: 50%;
    animation: animateShadow 1.2s infinite;
    animation-timing-function: ease-in-out;
    @keyframes animateShadow {
      0% {
        transform: scale(0.8);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0.8);
      }
    }
  }
`
