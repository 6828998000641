// @flow

import React, { useContext } from 'react'
import { StyledControlItem } from '@website/common/components/Controlled/Controls/styled'
import Mapper from '@website/common/components/Mapper'
import Styled from './NavBar.styled.js'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

type TColorData = {
  label: string,
  value: string
}

type TColorChooserProps = {
  onChoose: TColorData => void,
  data: Array<TColorData>,
  selected: typeof TColorData.value
}

export default ({ data, onChoose, selected }: TColorChooserProps) => {
  const isMobile = useContext(MobileDetectContext)

  return (
    <StyledControlItem>
      <Styled.LogoColorChooser>
        <p>{translate('logo_style_label')}</p>
        {
          <Mapper
            data={data}
            render={({ iconSrc, value, tooltipText }) => (
              <TooltipWrapper
                text={isMobile ? '' : tooltipText}
                position="top"
                color="#252E48"
              >
                <Styled.LogoColorItem
                  key={value}
                  onClick={() => onChoose(value)}
                  isSelected={value === selected}
                >
                  <img src={iconSrc} alt="logo-color" />
                </Styled.LogoColorItem>
              </TooltipWrapper>
            )}
          />
        }
      </Styled.LogoColorChooser>
    </StyledControlItem>
  )
}
