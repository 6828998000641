/* @flow */
import React, { useCallback, useState, useContext } from 'react'
import S from './NoHeader.styled'
import meta from './NoHeader.meta'
import THEME from './NoHeader.theme'
import NavBar from '@website/common/components/NavBar'
import { EditingContext } from '@contexts'

const NoHeader = props => {
  const { data } = props
  const [navBarHeight, setNavBarHeight] = useState(0)
  const handleHeightChange = useCallback(height => {
    setNavBarHeight(height)
  }, [])

  const { isEditing } = useContext(EditingContext)

  return (
    <S.Container isEditing={isEditing}>
      <S.WmCustomContainer topOffset={navBarHeight} isEditing={isEditing}>
        <NavBar
          handleHeightChange={handleHeightChange}
          alwaysShowBackground
          theme={THEME}
          logoProps={{
            logoSrc: data.logoSrc,
            logoColor: data.logoColor,
            logoWidth: data.logoWidth,
            logoSizes: data.logoDimensions
          }}
          type="noheader"
        />
      </S.WmCustomContainer>
    </S.Container>
  )
}
NoHeader.meta = meta
export default NoHeader
