/* @flow */
import produce from 'immer'

/* eslint-disable */
export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_CLIENT_DATA':
        const { data, idx, keyInStore } = action.value
        draft.testimonials[idx][keyInStore] = data
        break
      case 'CHANGE_IMAGE': {
        const { idx, url, dimensions } = action.value
        draft.testimonials[idx].imgUrl = url
        draft.testimonials[idx].imgDimensions = dimensions || {}
        draft.testimonials[idx].imgCropParams = {}
        break
      }
      case 'REMOVE_IMAGE': {
        const { idx } = action.value
        draft.testimonials[idx].imgUrl = ''
        draft.testimonials[idx].imgDimensions = {}
        draft.testimonials[idx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx } = action.value
          draft.testimonials[idx].imgCropParams = imgCropParams
        }
        break
      case 'ADD_CLIENT_IMAGE': {
        draft.testimonials[action.value].imgUrl =
          initialState.testimonials[0].imgUrl
        draft.testimonials[action.value].imgDimensions =
          initialState.testimonials[0].imgDimensions
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'ADD_ITEM':
        draft.testimonials.push(initialState.testimonials[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.testimonials.splice(idx, 1)
        break
      }
      case 'ADD_SLIDE': {
        const activeIdx = action.value

        draft.testimonials = [
          ...draft.testimonials.slice(0, activeIdx + 1),
          initialState.testimonials[0],
          ...draft.testimonials.slice(activeIdx + 1)
        ]
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NAME_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.testimonials[idx].nameAlignment = alignment
        break
      }
      case 'CHANGE_POSITION_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.testimonials[idx].positionAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.testimonials[idx].paragraphAlignment = alignment
        break
      }
    }
  })
}
