/* @flow */
import React, { useContext, useState, memo } from 'react'
import './Header41.styles.sass'
import S from './Header41.styled'
import THEME from './Header41.theme'
import { validation } from './Header41.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import SingleButton from '@website/common/components/SingleButton'

const Header41 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      paragraphAlignment,
      btnText,
      btnUrl,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <Show when={[title, paragraph]}>
        <WMContainer className="header-41-container">
          <S.HeaderContent className="header-content" topOffset={navBarHeight}>
            <EditableContent
              text={title}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              isEditing={isEditing}
              className={
                isEditing ? 'WM_GLOBAL_heading42' : 'WM_GLOBAL_heading42 glitch'
              }
              data-text={title}
              as={S.Title}
              onChange={Actions.changeTitle}
              areControlsHidden
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <SingleButton btnUrl={btnUrl} btnText={btnText} />
          </S.HeaderContent>
        </WMContainer>
      </Show>
    </S.StyledContainer>
  )
})

export default Header41
