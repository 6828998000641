export default {
  DARK: {
    heading: p => p.accentWhite,
    background: p => `${p.tertiaryDark}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    headingEnd: p => p.primaryDark,
    headingStart: p => p.primaryLight,
  },
  LIGHT: {
    heading: p => p.primaryDark,
    background: p => `${p.tertiaryLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    headingEnd: p => p.primaryDark,
    headingStart: p => p.primaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    headingEnd: p => p.primaryDark,
    headingStart: p => p.primaryLight
  }
}
