/* @flow */

import React, { useState, useCallback, memo } from 'react'
import { translate } from '@editor/common/utils/translations'
import { ButtonsWrapper, Content, Label } from '../styled'
import { EMPTY_INPUT_MESSAGE, MODAL_TYPES } from '../consts'
import {
  PrimaryButton,
  SecondaryButton
} from '@editor/common/styled-components/buttons'
import Popup from '@editor/common/lib/Popup'
import Input from '@website/common/components/Input/Input'
import { secondary } from '@website/common/styles/colors'
import { onEnterPress } from '@website/common/utils'

const TextModal = ({ handler, text, setModalState }) => {
  const [inputValue, setInputValue] = useState(text)
  const [validationMessage, setValidationMessage] = useState(false)

  const onFocus = useCallback(() => {
    if (validationMessage) setValidationMessage('')
  }, [validationMessage])

  const onChange = useCallback(e => {
    setInputValue(e.target.value)
  }, [])

  const onRemoveIconClick = useCallback(() => {
    setInputValue('')
  }, [])

  const closePopup = useCallback(
    () => setModalState(MODAL_TYPES.textModal, { isOpen: false }),
    [setModalState]
  )

  const onAdd = useCallback(() => {
    if (text === inputValue) {
      closePopup()
      return
    }

    if (inputValue === '') {
      setValidationMessage(EMPTY_INPUT_MESSAGE)
      return
    }

    if (inputValue) {
      handler(inputValue)
      closePopup()
    }
  }, [inputValue, closePopup])

  return (
    <Popup
      isOpen
      onClose={closePopup}
      title={translate('edit_button_title_label')}
    >
      <Content>
        <Label>{translate('type_a_title_here_label')}</Label>
        <Input
          value={inputValue}
          placeholder="Title"
          placeholderColor={secondary.SemiLight}
          borderColor={secondary.Light}
          borderHoverColor={secondary.SemiLight}
          error={validationMessage}
          onChange={onChange}
          onFocus={onFocus}
          onKeyPress={onEnterPress(onAdd)}
          onRemoveIconClick={onRemoveIconClick}
        />
        <ButtonsWrapper>
          <SecondaryButton onClick={closePopup}>
            {translate('cancel_label')}
          </SecondaryButton>
          <PrimaryButton onClick={onAdd}>
            {translate('save_label', true)}
          </PrimaryButton>
        </ButtonsWrapper>
      </Content>
    </Popup>
  )
}

export default memo(TextModal)
