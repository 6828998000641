/* @flow */
import styled from 'styled-components'

import THEME from './CTA4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  @media only screen and (min-width: 769px) {
    padding-top: ${({ isEditing }) => (isEditing && '150px')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  > .control-container {
    width: 100%;
  }
`
const ActionItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: -40px;
  padding-top: 40px;
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const Cta = styled.div`
  margin: 0 20px 40px 20px;
`

const CtaItemWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  text-align: center;
  width: 270px;
  box-sizing: border-box;
  height: 100%;
  word-break: break-word;
  .control-container:nth-child(2) {
    width: 100%;
  }
`

const CtaTitle = styled.h2`
  width: 100%;
  color: ${getStyleForProp('ctaTitle')};
  font-weight: 800;
  font-size: 20px;
  padding-top: 12px;
  ${getTextStyleByAlignment};
`

const IconWrap = styled.p`
  color: ${getStyleForProp('ctaParagraph')};
  font-size: 50px;
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.primaryDark
}))``

export default {
  StyledContainer,
  WmCustomContainer,
  ActionItemsWrap,
  Title,
  Description,
  Cta,
  CtaItemWrap,
  CtaTitle,
  IconWrap,
  Icon
}
