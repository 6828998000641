export default {
  DARK: {
    socialColor: p => p.accentWhite,
    socialMobileColor: p => p.tertiaryDark
  },
  LIGHT: {
    socialColor: p => p.tertiaryDark,
    socialMobileColor: p => p.tertiaryDark
  },
  EXTRALIGHT: {
    socialColor: p => p.tertiaryDark,
    socialMobileColor: p => p.tertiaryDark
  }
}
