/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Team7.theme'
import {
  styleProvider,
  getBackground,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  width: 100%;
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '120px 80px 80px 80px' : '80px')};
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    padding: ${({ isEditing }) =>
      isEditing ? '140px 40px 80px 40px' : '80px 40px'};
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 20px;
  }
  > :first-child {
    margin-top: 0;
  }
  .control-container {
    width: 100%;
  }
`

const WmCustomContainer = styled(WMContainer)`
  justify-content: space-between;
  width: 100%;
  ${({ theme }) =>
    css`
      max-width: ${theme.orientation === 'RIGHT' ? '1200px' : '1150px'};
    `}
  align-items: center;
  padding-top: 0;
  margin-top: 50px;
  padding: 0;
  @media only screen and (max-width: 1024px) {
    max-width: 600px;

    ${({ theme }) =>
      css`
        margin-top: ${theme.orientation === 'RIGHT' ? '20px' : '40px'};
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }
  @media only screen and (max-width: 768px) {
    max-width: 600px;
  }
`

const Hgroup = styled.div`
  text-align: center;
  width: 90%;
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  .main-paragraph {
    color: ${getStyleForProp('heading')};
    margin-top: 20px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
  > :first-child {
    margin-top: 0;
  }
`

const TextImageContent = styled.div`
  width: 45%;
  max-width: 450px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 120%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 120%;
    height: initial;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const SloganWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  position: absolute;
  bottom: 70px;
  border-radius: ${getStyleForProp('cardRadius')};
  ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
      left: -70px;
    `}
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      right: -70px;
    `}
  z-index: 1;
  background-color: ${getStyleForProp('heading')};
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  &.hidden-desktop {
    margin-right: auto;
    margin-left: auto;
    display: none;
    @media only screen and (max-width: 1024px) {
      ${({ theme }) =>
        theme.orientation === 'RIGHT' &&
        css`
          display: none;
        `}
      ${({ theme }) =>
        theme.orientation === 'LEFT' &&
        css`
          display: block;
        `}
    }
  }
  &.hidden-mobile {
    @media only screen and (max-width: 1024px) {
      ${({ theme }) =>
        theme.orientation === 'LEFT' &&
        css`
          display: none;
        `}
      ${({ theme }) =>
        theme.orientation === 'RIGHT' &&
        css`
          display: block;
          transform: translateY(-90px);
          margin-bottom: -90px;
        `}
    }
    @media only screnn and (max-width: 450px) {
      ${({ theme }) =>
        theme.orientation === 'RIGHT' &&
        css`
          display: block;
          transform: translateY(-100px);
          margin-bottom: -100px;
        `}
    }
  }
  @media only screen and (max-width: 1024px) {
    position: initial;
    left: initial;
    bottom: initial;
    max-width: 350px;
  }
  @media only screen and (max-width: 450px) {
    width: 90%;
  }
`

const Slogan = styled.p`
  color: ${getStyleForProp('slogan')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const Name = styled.p`
  color: ${getStyleForProp('slogan')};
  margin: 10px 0 0 0;
  font-weight: 500;
  ${getTextStyleByAlignment};
  width: 100%;
`

const ImageWrapper = styled.div`
  width: 100%;
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const ParagraphsWrap = styled.div`
  width: 55%;
  max-width: 550px;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'RIGHT' ? '0 50px 0 0' : '0 0 0 50px'};
    `}
  .paragraph2,
  .paragraph3,
  .paragraph4 {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'RIGHT'
          ? 'margin-top: 20px;'
          : 'margin-bottom: 20px; margin-top: 20px;'}
      `}
  }

  & > .control-container:nth-last-child(1) > p,
  & > p:nth-last-child(1) {
    @media only screen and (max-width: 1024px) {
      ${({ theme }) =>
        css`
          ${theme.orientation === 'RIGHT'
            ? ''
            : 'margin-bottom: 0px;margin-top: 0px;'}
        `}
    }
  }


  @media only screen and (max-width: 1024px) {
    margin: 0;
    width: 100%;
    max-width: 100%;
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            transform: translateY(-90px);
            margin-bottom: -90px;
          `
        : css`
            margin: 0 0 50px 0;
          `}
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Hgroup,
  Title,
  Paragraph,
  Slogan,
  Name,
  TextImageContent,
  SloganWrapper,
  ImageWrapper,
  Img,
  ControlContainer,
  ParagraphsWrap
}
