/* @flow */
import styled from 'styled-components'
import Container from '@website/common/components/Container'
import THEME from './Slider4.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) => (isEditing ? 'padding-top: 140px;' : null)}
  padding-bottom: 36px;
  @media only screen and (max-width: 1024px) {
    padding-top: ${({ isEditing }) => (isEditing ? '140px' : '80px')};
  }
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => (isEditing ? '140px' : '60px')};
    padding-bottom: 16px;
  }
  .slick-arrow button {
    background: ${getStyleForProp('buttonBackground')};
    border-radius: ${getStyleForProp('iconRadius')};
  }

  .slick-next {
    @media only screen and (max-width: 1370px) {
      right: 0;
    }
    @media only screen and (max-width: 1024px) {
      right: -36px;
    }
    @media only screen and (max-width: 380px) {
      right: -21px;
    }
  }

  .slick-prev {
    @media only screen and (max-width: 1370px) {
      left: 0;
    }
    @media only screen and (max-width: 1024px) {
      left: -36px;
    }
    @media only screen and (max-width: 380px) {
      left: -21px;
    }
  }

  > :first-child {
    padding-top: 0;
    & > .control-container {
      width: 100%;
    }
    @media only screen and (max-width: 457px) {
      padding-left: 20px;
      padding-right: 20px;
    }
    & > div > .control-container {
      display: block;
    }
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

export const BadgeContainer = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 330px;
  padding-top: ${({ isTitle }) => (isTitle ? '40px' : '0px')};
  padding-bottom: ${({ isEditing }) => (isEditing ? '10px' : '0')};
  position: relative;
  z-index: 2;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
    justify-content: center;
    flex-wrap: wrap;
    a {
      margin: 0 10px 10px;
    }
  }
`

export const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 26px;
  object-fit: cover;
`

export const SliderContainer = styled.div`
  position: relative;
  margin-top: -50px;
  padding-top: 50px;
  .lazy-figure {
    border-radius: 24px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 400px;
    margin: -50px auto 0 auto;
    padding-top: 40px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 300px;
  }
`

export const PhoneWrap = styled.div`
  width: 260px;
  position: absolute;
  top: 53.42%;
  left: 50%;
  transform: translate(-50%, -49.5%);
  @media only screen and (max-width: 1024px) {
    top: 52.7%;
  }
`

export const Phone = styled.div`
  width: 258px;
  margin: 0 auto;
  position: relative;
  border: 10px solid ${getStyleForProp('phone')};
  box-sizing: border-box;
  border-radius: 36px;
  font-size: 0;
  height: 534px;
  .control-container {
    width: 100%;
  }
  .lazy-figure {
    width: 100%;
    height: initial;
    position: relative;
    padding-top: 216.1%;
    border-radius: 24px;
  }
`

export const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 24px;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

export const DinamicWrap = styled.div`
  width: 134px;
  position: absolute;
  top: 98px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  .rf-iphone-dimanic-svg path {
    fill: ${getStyleForProp('phone')};
  }
  @media only screen and (max-width: 1024px) {
    top: 88px;
  }
`

export const SlideCountContainer = styled.p`
  font-size: 16px;
  text-align: center;
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
`

export const BadgeControlContainer = styled.div`
  margin: 0 20px;
`
