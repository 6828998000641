/* @flow */

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

import { clientLogoActionMaker } from '@website/components/ActionMakers'

export default dispatcher => {
  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const addSlide = (activeIdx: number) => {
    dispatcher({
      type: 'ADD_SLIDE',
      value: activeIdx
    })
  }

  const removeSlide = idx => {
    dispatcher({
      type: 'REMOVE_SLIDE',
      value: { idx }
    })
  }


  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addSlide,
    removeSlide,
    getClientsActions: clientLogoActionMaker(dispatcher)
  }
}
