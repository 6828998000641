import styled from 'styled-components'

export const MainContent = styled.div`
  width: 100%;
  display: flex;
  overflow: auto;
  flex-grow: 1;
  position: relative;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`
