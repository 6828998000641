/* @flow */
import React, { useContext } from 'react'

import { SlideCountContainer } from './Testimonials4.styled'
import { EditingContext } from '@contexts'

const SlideCounts = ({ activeSlideIdx, slidesCount }) => {
  const { isEditing } = useContext(EditingContext)

  if (!isEditing || !activeSlideIdx || !slidesCount) {
    return null
  }

  return (
    <SlideCountContainer>
      Slide {activeSlideIdx} of {slidesCount}
    </SlideCountContainer>
  )
}

export default SlideCounts
