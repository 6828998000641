/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import { changeTitle } from '@website/components/CommonActions'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatch => {
  const changeDescription = (newDescription: string) => ({
    type: 'CHANGE_DESCRIPTION',
    value: newDescription
  })

  const addStoreLink = (keyInStore: string) => ({
    type: 'ADD_STORE_LINK',
    value: keyInStore
  })

  const changeStoreUrl = (storeType: string) => (url: string) => {
    dispatch({
      type: 'CHANGE_STORE_URL',
      value: { url, storeType }
    })
  }

  const storeIconActions = (storeType: string, link: string) => [
    {
      IconComp: EditIcon,
      tooltipText: translate('edit_label'),
      handler: changeStoreUrl(storeType),
      modal: {
        type: MODAL_TYPES.urlModal,
        props: { type: LINK_TYPES.link, link }
      }
    },
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        dispatch({ type: 'REMOVE_BUTTON', value: storeType })
      }
    }
  ]

  return {
    changeTitle,
    changeDescription,
    addStoreLink,
    storeIconActions,
    mockupActions: ({ showRemove, mockupType, imageUrl }) =>
      cropableImageModalActionMaker(dispatch)({
        keyForSizes: 'header6Mockup',
        showRemove,
        additionalArgs: { mockupType },
        imageUrl
      })
  }
}
