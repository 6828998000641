/* @flow */
import http from '@shared/ApiFactory'
import {
  SM_API_PATH,
  ApiPrefix,
  CURRENT_HOST,
  FILE_UPLOAD_PATH
} from '@shared/ApiConfig'
import { getHash } from '@shared/utils'
import objToQs from '@editor/common/utils/obj-to-qs'

type TDomainsAPIParams = {
  search: string | undefined,
  offset: number | undefined,
  limit: number
}

export const getProjectDataAPI = (siteId: number, params) => {
  const queryString = objToQs(params)
  return http.GET(`${SM_API_PATH}/sites/data/${siteId}${queryString}`)
}

export const createNewProjectAPI = data =>
  http.POST(`${SM_API_PATH}/sites`, data)

export const patchProjectDataAPI = (
  siteId: number,
  data: TProjectDataApi,
  params
) => {
  const queryString = objToQs(params)
  return http.PATCH(`${SM_API_PATH}/sites/data/${siteId}${queryString}`, data)
}

export const resetProjectChangesAPI = (siteId: number, sections: [string]) =>
  http.PATCH(`${SM_API_PATH}/sites/data/${siteId}/reset`, {
    types: sections
  })

export const publishProjectAPI = (siteId: number, needToGoOnline?: boolean) => {
  const body =
    typeof needToGoOnline === 'boolean' ? { isOnline: needToGoOnline } : {}
  return http.POST(`${SM_API_PATH}/sites/${siteId}/publish`, body)
}

export const signoutUserAPI = () =>
  http.POST(`${ApiPrefix}/users/current/logout`, null, {
    Authorization: '',
    'X-SharedEditing': false
  })

export const uploadFilesAPI = (
  userUploadHost: string,
  file: FormData,
  callback,
  onStart
) => {
  const xhr = new XMLHttpRequest()
  xhr.withCredentials = true
  xhr.addEventListener('error', (error: TMessage) => {
    callback(error, null)
  })
  xhr.addEventListener('load', () => {
    callback(null, JSON.parse(xhr.response))
  })
  xhr.addEventListener('loadstart', onStart)
  xhr.open('POST', userUploadHost)
  if (!!getHash()) {
    xhr.setRequestHeader('Authorization', 'Bearer ' + getHash())
    xhr.setRequestHeader('X-SharedEditing', true)
  }
  xhr.send(file)
}

// this function will return Promise
export const uploadFilesAsyncAPI = (file: FormData, userUploadHost) => {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    xhr.addEventListener('error', error => {
      reject(error)
    })
    xhr.addEventListener('load', () => {
      resolve(JSON.parse(xhr.response))
    })
    xhr.open('POST', userUploadHost)
    xhr.send(file)
  })
}

export const getUserAPI = () => http.GET(`${ApiPrefix}/users/current`)

export const getMediaLimitsAPI = () => http.GET(`${ApiPrefix}/user-media/stats`)

export const getDomainConfiguredStatusAPI = (siteId, domain) =>
  http.GET(`${SM_API_PATH}/sites/domain/${siteId}/cname/${domain}`)

export const configureDomainAPI = (oldDomain: string, siteId: number) =>
  http.POST(`${SM_API_PATH}/sites/domain/configure/${siteId}`, {
    domain: oldDomain
  })

export const configureHttpsAPI = siteId =>
  http.POST(`${SM_API_PATH}/sites/domain/configure/https/${siteId}`)

export const removeHttpsConfigsAPI = siteId =>
  http.DELETE(`${SM_API_PATH}/sites/domain/configure/https/${siteId}`)

export const saveDomainAPI = (domain, siteId) =>
  http.PATCH(`${SM_API_PATH}/sites/domain/${siteId}`, { domain })

export const saveSubdomainAPI = (subdomain, siteId) =>
  http.PATCH(`${SM_API_PATH}/sites/subdomain/${siteId}`, { subdomain })

export const getTariffPlanAPI = id =>
  http.GET(`${ApiPrefix}/tariff-plans/${id}`)

export const getSubscriptionsAPI = () => http.GET(`${ApiPrefix}/subscriptions`)

export const getUpgradePreview = (subscriptionId: number, upgradeId: number) =>
  http.GET(
    `${CURRENT_HOST}/profile/subscription/upgrade-preview/${subscriptionId}?upgradeTariffPlanId=${upgradeId}`
  )

export const upgradeTariffPlanAPI = (
  userTariffPlanId: string,
  upgradeId: number,
  csrfToken: string,
  referrer: string
) =>
  http.POST(`${CURRENT_HOST}/profile/subscription/upgrade/${upgradeId}`, {
    userTariffPlanId,
    _csrf: csrfToken,
    ref: referrer
  })

export const getProductPriceDataAPI = id =>
  http.GET(`${ApiPrefix}/products/${id}/price`)

export const siteDataAPI = siteId => http.GET(`${SM_API_PATH}/sites/${siteId}`)

export const domainRemoveAPI = siteId =>
  http.DELETE(`${SM_API_PATH}/sites/domain/${siteId}`)

export const subdomainRemoveAPI = siteId =>
  http.DELETE(`${SM_API_PATH}/sites/subdomain/${siteId}`)

export const imageOptimizationAPI = (siteId, body) =>
  http.POST(`${SM_API_PATH}/sites/${siteId}/image`, body)

//Share link APIs
export const createShareableLinkAPI = body =>
  http.POST(`${ApiPrefix}/shared-editing`, body)

export const deleteShareLinkAPI = projectId =>
  http.DELETE(`${ApiPrefix}/shared-editing/${projectId}`)

export const getShareLinkAPI = siteId =>
  http.GET(`${ApiPrefix}/shared-editing/SITE/${siteId}`)

export const getCountriesAPI = () => http.GET(`${ApiPrefix}/countries`)

export const getDomainsAPI = (params: TDomainsAPIParams) => {
  const queryString = objToQs(params)
  return http.GET(`${SM_API_PATH}/domains${queryString}`)
}

export const renameSiteAPI = (siteId, name) =>
  http.PATCH(`${SM_API_PATH}/sites/${siteId}`, { name })

export const setSiteOnlineStatusAPI = (siteId, value) =>
  http.PATCH(`${SM_API_PATH}/sites/${siteId}/online`, { isOnline: value })

export const getCookieSettingsAPI = siteId =>
  http.GET(`${SM_API_PATH}/sites/${siteId}/cookies`)

export const patchVisualParamsAPI = (siteId, data, params) => {
  const queryString = objToQs(params)
  return http.PATCH(
    `${SM_API_PATH}/sites/${siteId}/visualParams${queryString}`,
    data
  )
}

export const getFullProjectDataAPI = (siteId, params) =>
  getProjectDataAPI(siteId, params).then(({ data }) => data)

export const patchCookieSettingsAPI = (siteId, data) =>
  http.PATCH(`${SM_API_PATH}/sites/${siteId}/cookies`, data)

export const getCSRFTokenAPI = () => http.GET(`${CURRENT_HOST}/csrf`)

export const patchWidgetsDataAPI = (siteId, data) =>
  http.PATCH(`${SM_API_PATH}/sites/${siteId}/widgets`, { widgets: data })

export const deleteWidgetsDataAPI = siteId =>
  http.DELETE(`${SM_API_PATH}/sites/${siteId}/widgets`)

export const assignPremiumPlanAPI = siteId =>
  http.PATCH(`${SM_API_PATH}/sites/${siteId}/assign-plan`)

export const patchMetaSettingsAPI = (siteId, meta) =>
  http.PATCH(`${SM_API_PATH}/sites/${siteId}/meta`, { meta })

export const getPossibleLanguagesDataAPI = () =>
  http.GET(`${SM_API_PATH}/sites/langsList`)

export const getWebsiteLanguagesDataAPI = siteId =>
  http.GET(`${SM_API_PATH}/sites/${siteId}/translations`)

export const patchWebsiteLanguagesDataAPI = (siteId, translations) =>
  http.PATCH(`${SM_API_PATH}/sites/${siteId}/translations`, { translations })

export const patchLanguageMenuLayoutAPI = (siteId, languageMenuLayout) =>
  http.PATCH(`${SM_API_PATH}/sites/${siteId}/languageMenuLayout`, {
    languageMenuLayout
  })

export const getTimeZonesDataAPI = () =>
  http.GET(`${SM_API_PATH}/sites/timeZone`)

export const copyLogoAPI = body =>
  http.POST(`${FILE_UPLOAD_PATH}/user-media/copy-logo`, body)

export const getAIWebsitePopularCategoriesAPI = (searchValue = '') => {
  const search = searchValue ? `search=${encodeURIComponent(searchValue)}&` : ''
  return http.GET(`${SM_API_PATH}/popular-categories?${search}limit=5`)
}

export const generateAIWebsiteAPI = body =>
  http.POST(`${SM_API_PATH}/sites/ai/generate`, body)
