/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Slider3.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import { getTextStyleByAlignment } from '@website/common/utils'

import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing ? 'padding-top: 140px;' : 'padding-top: 80px;'}
  @media only screen and (max-width: 1100px) and (max-width: 1024px) {
    ${({ isSingleSlide }) =>
      isSingleSlide ? 'padding-bottom: 10px !important;' : ''}
  }
  @media only screen and (max-width: 1100px) and (min-width: 769px) {
    ${({ isEditing }) =>
      isEditing ? 'padding: 140px 0 40px 0;' : 'padding: 80px 0 40px 0;'}
  }
  @media only screen and (max-width: 768px) {
    ${({ isEditing }) => (isEditing ? 'padding-top: 0px;' : null)}
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 0 20px 0;
    ${({ isSingleSlide }) =>
      isSingleSlide
        ? css`
            padding-bottom: 5px !important;
            .slick-slider-container {
              margin-bottom: 0;
            }
          `
        : ''}
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  .slick-slider-container {
    position: relative;
    z-index: 2;
  }
  .rf-image-slider {
    padding: 30px 0 0 0;
    @media only screen and (max-width: 1100px) {
      padding: 30px 0;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${({ isEditing }) => (isEditing ? 'margin-top: 60px;' : null)}

  .slick-slide > div {
    margin: 25px;
    @media only screen and (max-width: 1100px) {
      margin: 25px 20px;
    }
    @media only screen and (max-width: 767px) {
      margin: 25px 0px;
    }
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      padding-top: 0;
    }
  }
`

const FigureContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin: 0 auto;
`

const ImageSlideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  position: relative;
  margin-top: -50px;
  padding-top: 50px;
  .dots-wrap {
    bottom: 0;
    @media only screen and (max-width: 1100px) {
      bottom: 40px;
      padding-top: 50px;
    }
  }
`

const Dots = styled.div`
  position: absolute;
  z-index: 1;
  &.left {
    top: 70px;
    left: -76px;
  }
  &.right {
    bottom: 30px;
    right: -60px;
    transform: rotate(90deg);
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  width: 100%;
  padding-top: 20px;
`

const Img = styled.img`
  box-shadow: ${getStyleForProp('raisedShadow')};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const IconContainer = styled.button`
  padding: 0;
  border: none;
  width: 36px;
  min-width: 36px;
  margin: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${getStyleForProp('iconRadius')};
  ${getStyleForProp('buttonBackground')};
  z-index: 3;
  position: relative;
  @media only screen and (max-width: 1100px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: none;
    width: 100%;
    margin: 2rem 0;
  }
  .left {
    transform: translateX(-1px);
  }
  .right {
    transform: translateX(1px);
  }
  &:focus {
    outline: none;
  }
`

const Dot = styled.button`
  padding: 0;
  border: none;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: none;
  }
`

const SquareBox = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 200px;
  height: 200px;
  background: ${({ theme }) => theme.palette.tertiarySemiLighter};
  opacity: 0.2;
  position: absolute;
  &.left {
    top: 114px;
    left: -25px;
  }
  &.right {
    bottom: 102px;
    right: -66px;
  }
  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

const SlideImgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  .figure-img {
    object-fit: cover;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 56.25%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 56.25%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

export default {
  Img,
  Dot,
  Title,
  StyledContainer,
  WmCustomContainer,
  FigureContainer,
  ImageSlideContainer,
  Dots,
  Paragraph,
  SquareBox,
  IconContainer,
  SlideImgContainer,
  ControlContainer
}
