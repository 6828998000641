import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    raisedShadow: () => 'none',
    navbarBg: p => p.tertiaryDark,
    title: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    firstCircle: p => p.primaryLight,
    secondCircle: p => p.secondaryLight,
    thirdCircle: p => p.tertiarySemiLight,
    fourthCircle: p => p.accentWhite,
    firstFigure: p => p.primaryLight,
    secondFigure: p => p.tertiarySemiLight,
    thirdFigure: p => p.secondaryLight,
    firstImageFigure: p => p.tertiarySemiLight,
    secondImageFigure: p => p.secondaryLight,
    firstImageFigureMobile: p => p.secondaryLight,
    fourthFigure: p => p.accentWhite,
    fifthFigure: p => p.secondaryLight,
    fifthNestedFigure: p => p.primaryLight,
    border: p => p.tertiaryDark,
    cardBackground: p => `rgba(${hexToRgb(p.accentWhite)}, 0.1)`,
    leftTriangle: p => p.secondaryLight,
    rightTriangle: p => p.accentWhite,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark
  },

  LIGHT: {
    navbarBg: p => p.tertiaryLight,
    title: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    firstCircle: p => p.primaryDark,
    secondCircle: p => p.primaryLight,
    thirdCircle: p => p.tertiarySemiLight,
    fourthCircle: p => p.tertiaryDark,
    firstFigure: p => p.primaryDark,
    secondFigure: p => p.tertiaryDark,
    thirdFigure: p => p.primaryLight,
    firstImageFigure: p => p.tertiaryDark,
    secondImageFigure: p => p.primaryLight,
    firstImageFigureMobile: p => p.primaryLight,
    fourthFigure: p => p.tertiaryDark,
    fifthFigure: p => p.primaryLight,
    fifthNestedFigure: p => p.primaryDark,
    border: p => p.accentWhite,
    cardBackground: p => `rgba(${hexToRgb(p.accentWhite)}, 0.7)`,
    leftTriangle: p => p.primaryLight,
    rightTriangle: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight
  },

  EXTRALIGHT: {
    navbarBg: p => p.tertiaryExtraLight,
    title: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    firstCircle: p => p.primaryDark,
    secondCircle: p => p.primaryLight,
    thirdCircle: p => p.secondaryDark,
    fourthCircle: p => p.secondaryLight,
    firstFigure: p => p.primaryDark,
    secondFigure: p => p.primaryLight,
    thirdFigure: p => p.secondaryDark,
    firstImageFigure: p => p.primaryLight,
    secondImageFigure: p => p.secondaryLight,
    firstImageFigureMobile: p => p.secondaryLight,
    fourthFigure: p => p.tertiaryDark,
    fifthFigure: p => p.secondaryLight,
    fifthNestedFigure: p => p.primaryDark,
    border: p => p.accentWhite,
    cardBackground: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.7)`,
    leftTriangle: p => p.secondaryLight,
    rightTriangle: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
