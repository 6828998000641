import { useEffect, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'

import { selectSemrushLoginWindowState } from '@selectors'
import { generateSemrushTokens } from '@actions/semrush'
import {
  setSemrushLoginWindowState,
  setSemrushSidebarPinnedState
} from '@actions/editor'

const popupWidth = 650
const popupHeight = 700

export const openSemrushLoginWindow = () => {
  const innerWidth = window.innerWidth
  const innerHeight = window.innerHeight
  const outerHeight = window.outerHeight
  const toolbarHeight = outerHeight - innerHeight
  const leftPos = (innerWidth - popupWidth) / 2
  const topPos = (innerHeight - popupHeight) / 2 + toolbarHeight
  const windowFeatures = `modal,left=${leftPos},top=${topPos},width=${popupWidth},height=${popupHeight}`

  return window.open(
    'https://oauth.semrush.com/oauth2/authorize?ref=9044540196&client_id=renderforest&redirect_uri=/oauth2/renderforest/success&response_type=code&scope=user.id%20domains.info',
    '_blank',
    windowFeatures
  )
}

export const useSemrushLoginWindow = () => {
  const dispatch = useDispatch()
  const isWindowOpen = useSelector(selectSemrushLoginWindowState)
  const windowRef = useRef(null)

  const handleSigninPostMessage = useCallback(
    event => {
      const url = event.data.url
      if (url) {
        const code = url.split('=')[1]
        dispatch(generateSemrushTokens(code))
        windowRef.current && windowRef.current.close()
      }
    },
    [windowRef.current]
  )

  useEffect(() => {
    if (isWindowOpen) {
      windowRef.current = openSemrushLoginWindow()
      dispatch(setSemrushLoginWindowState(false))
    }
  }, [isWindowOpen])

  useEffect(() => {
    window.addEventListener('message', handleSigninPostMessage)
    return () => window.removeEventListener('message', handleSigninPostMessage)
  }, [handleSigninPostMessage])
}

export const useSemrushInitialPinned = () => {
  const { siteId } = useParams()
  const dispatch = useDispatch()
  const key = `isSemrushSidebarPinned_${siteId}`

  const isPinned = JSON.parse(window.localStorage.getItem(key))

  useEffect(() => {
    isPinned && dispatch(setSemrushSidebarPinnedState(true))
  }, [])
}
