export const SLIDER_PARAMS = {
  focusOnSelect: true,
  infinite: true,
  speed: 400,
  lazyLoad: 'progressive',
  slidesToShow: 1,
  className: 'testimonials6-slider',
  centerMode: true,
  variableWidth: true,
  centerPadding: 0
}
