/* @flow */
import {
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeTitleAlignment,
  addIcon,
  addBlock
} from '@website/components/CommonActions'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeBlockTitle,
    changeBlockParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    addBlock: () => dispatch(addBlock()),
    addIcon: () => dispatch(addIcon()),
    iconActions: idx => [
      ...iconActionMaker(dispatch)({
        idx,
        showRemove: false
      })
    ],
    blockActions: removeBlockActionMaker(dispatch)
  }
}
