/* @flow */
import styled from 'styled-components'

import THEME from './Video2.theme'
import Container from '@website/common/components/Container'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '120px 0 80px 0' : '80px 0')};
  @media only screen and (max-width: 1270px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    padding: ${({ isEditing }) => (isEditing ? '100px 0 60px 0' : '60px 0')};
  }
  > :first-child {
    padding-top: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding-top: 0;
  margin-top: -50px;
  padding-top: 50px;
  @media only screen and (max-width: 1100px) {
    margin-top: -40px;
    padding-top: 40px;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    max-width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const BlocksWrap = styled.div`
  width: 38%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 20px;
  padding: 40px 0 0 0;
  @media only screen and (max-width: 1100px) {
    width: 100%;
    margin: 0;
    padding-top: 30px;
  }
`

const BlockWrap = styled.div`
  margin: 10px 0;
  width: 100%;
  &:last-child {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1200px) {
    max-width: 360px;
  }
  @media only screen and (max-width: 992px) {
    max-width: unset;
  }
`

const VideoContainer = styled.div`
  width: 56%;
  margin-top: 10px;
  padding: 40px 0 0 0;
  &:only-child {
    margin: auto;
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    padding: 30px 0 0 0;
  }
`

const TextContent = styled.div`
  width: 90%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
    width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    p {
      margin-top: 0;
    }
  }
`

const IconTitleWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    padding-top: 56.25%;
  }
  .iframe-video {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const Block = styled.div`
  border: ${getStyleForProp('border')};
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  background: ${({ theme }) => theme.palette.accentWhite};
  padding: 30px;
  box-sizing: border-box;
  width: 100%;
  word-break: break-word;
  @media only screen and (max-width: 1200px) {
    max-width: 360px;
  }
  @media only screen and (max-width: 992px) {
    max-width: unset;
  }
  & > .control-container {
    width: 100%;
  }
`

const BlockTitle = styled.h2`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-weight: 700;
`

const BlockParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  width: 100%;
  ${getTextStyleByAlignment};
`

const SquareBox = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 200px;
  height: 200px;
  background: ${getStyleForProp('boxBackground')};
  opacity: 0.2;
`

const VideoContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: hidden;
`

const IconWrap = styled.div`
  color: ${({ theme }) => theme.palette.primaryDark};
  padding-right: 25px;
  @media only screen and (max-width: 992px) {
    padding-right: 0;
    padding-bottom: 20px;
  }
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.primaryDark
}))``

const LinkWrap = styled.div`
  display: inline-block;
  margin-top: 22px;
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.primaryDark};
  }
  .learn-more-link {
    font-weight: 500;
    align-items: center;
    .arrow-right {
      margin-left: 0;
      padding-left: 5px;
      min-width: 24px;
      transition: transform linear 0.15s;
    }
    &:hover {
      opacity: 0.7;
      .arrow-right {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
  svg {
    transform: translate3d(0, 0, 0);
  }
  @media only screen and (max-width: 1100px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`
export default {
  StyledContainer,
  WmCustomContainer,
  VideoContainer,
  VideoWrap,
  BlocksWrap,
  BlockWrap,
  TextContent,
  IconTitleWrap,
  Title,
  Paragraph,
  SquareBox,
  VideoContent,
  IconWrap,
  LinkWrap,
  Block,
  BlockTitle,
  BlockParagraph,
  Icon
}
