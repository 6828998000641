/* @flow */
import styled from 'styled-components'

import THEME from './Text5.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  ${getBackgroundStyles(THEME)};
  word-break: break-word;
  width: 100%;
  padding: 80px 0;
  @media only screen and (max-width: 600px) {
    padding: 60px 0;
  }
`
const Title = styled.h4`
  color: ${getStyleForProp('heading')};
  text-align: center;
`
const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  text-align: center;
`

const Line = styled.div`
  width: 80px;
  height: 4px;
  margin: 10px 0 26px 0;
  background-color: ${getStyleForProp('line')};
`

export default { Container, Title, Description, Line }
