/* @flow */
import { hexToRgb } from '@website/common/utils'

type Palette = {
  [key: string]: string
}

export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    raisedShadow: () => 'none',
    heading: (p: Palette) => p.accentWhite,
    paragraph: (p: Palette) => p.accentWhite,
    cardTextColor: (p: Palette) => p.accentWhite,
    markIconColor: (p: Palette) => p.primaryLight,
    labelBackground: (p: Palette) => p.tertiaryDark,
    anchor: p => p.primaryDark,
    background: (p: Palette) => p.tertiaryDark,
    cardBackground: (p: Palette) => p.tertiaryDark,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    backgroundColor: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`
  },
  LIGHT: {
    heading: (p: Palette) => p.primaryDark,
    paragraph: (p: Palette) => p.tertiaryDark,
    cardTextColor: (p: Palette) => p.tertiaryDark,
    markIconColor: (p: Palette) => p.primaryDark,
    labelBackground: (p: Palette) => p.accentWhite,
    anchor: p => p.primaryDark,
    background: (p: Palette) => p.tertiaryLight,
    cardBackground: (p: Palette) => p.accentWhite,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.4)`,
    backgroundColor: p => p.tertiaryLight,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  },
  EXTRALIGHT: {
    heading: (p: Palette) => p.tertiaryDark,
    anchor: p => p.primaryDark,
    paragraph: (p: Palette) => p.tertiaryDark,
    cardTextColor: (p: Palette) => p.tertiaryDark,
    markIconColor: (p: Palette) => p.primaryDark,
    labelBackground: (p: Palette) => p.accentWhite,
    background: (p: Palette) => p.tertiaryExtraLight,
    cardBackground: (p: Palette) => p.accentWhite,
    cardBackgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.4)`,
    backgroundColor: p => p.tertiaryExtraLight,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  }
}
