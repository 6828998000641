/* @flow */
import styled from 'styled-components'
import THEME from './Feature7.theme'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
`

const WMCustomContainer = styled(WMContainer)`
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ isEditing }) => (isEditing ? '50px' : null)};
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    width: 100%;
    margin-top: 30px;
    p {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
`

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 10px;
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-top: 20px;
`

const FeturesContent = styled.div`
  display: ${getStyleForProp('orientation')};
  justify-content: space-between;
  margin: ${({ isEditing }) => (isEditing ? '50px 0' : '50px 0 0 0')};
  @media only screen and (max-width: 992px) {
    margin: ${({ isEditing }) => (isEditing ? '40px 0 60px 0' : '40px 0 0 0')};
    align-items: center;
  }
`

const BlocksWrap = styled.div`
  width: 45%;
  @media only screen and (max-width: 992px) {
    width: 100%;
    max-width: 700px;
  }
`

const Block = styled.div`
  border: 1px solid transparent;
  ${({ isActiveFeatureIdx, ...rest }) =>
    isActiveFeatureIdx
      ? `
      box-shadow: ${getStyleForProp('raisedShadow')(rest)};
      border-radius: ${getStyleForProp('borderRadius')(rest)};
      background-color: ${rest.theme.palette.accentWhite};
      border: ${getStyleForProp('border')(rest)};
    `
      : ``};
  padding: 30px;
  cursor: pointer;
  transition: all 0.25s linear;
  &:focus {
    ${({ isActiveFeatureIdx, ...rest }) =>
      isActiveFeatureIdx
        ? `
      box-shadow: ${getStyleForProp('raisedShadow')(rest)};
      border-radius: ${getStyleForProp('borderRadius')(rest)};
      background-color: ${rest.theme.palette.accentWhite};
      border: ${getStyleForProp('border')(rest)};
      outline: none !important;
    `
        : ``};
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  & > .control-container {
    width: 100%;
    margin-top: 30px;
    h2 {
      margin-bottom: 0;
    }
  }
  .styled-controls {
    > div {
      > div:nth-child(4) {
        &:after {
          display: none;
        }
      }
    }
  }
`

const BlockTitle = styled.h2`
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
  color: ${({ isActiveFeatureIdx, ...rest }) =>
    isActiveFeatureIdx
      ? rest.theme.palette.tertiaryDark
      : getStyleForProp('paragraph')(rest)};
`

const BlockParagraph = styled.p`
  color: ${({ isActiveFeatureIdx, ...rest }) =>
    isActiveFeatureIdx
      ? rest.theme.palette.tertiaryDark
      : getStyleForProp('paragraph')(rest)};
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const ImagesWrap = styled.div`
  width: 50%;
  &:only-child {
    margin: auto;
  }
  .control-container {
    height: initial !important;
  }
  @media only screen and (max-width: 992px) {
    margin-top: 40px;
    width: 100%;
    max-width: 700px;
  }
`

const FeatureImage = styled.img`
  border: ${getStyleForProp('border')};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const BlockItemWrap = styled.article`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`

const Dot = styled.button`
  border: none;
  padding: 0;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
`

const ControlContainer = styled.div`
  width: 100%;
  .LazyLoad {
    padding-top: 100%;
    position: relative;
    height: initial;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('borderRadius')};
  }
`

export default {
  Dot,
  StyledContainer,
  WMCustomContainer,
  DotsContainer,
  Title,
  Paragraph,
  FeturesContent,
  BlocksWrap,
  Block,
  BlockTitle,
  BlockParagraph,
  ImagesWrap,
  FeatureImage,
  BlockItemWrap,
  ControlContainer
}
