/* @flow */
/* eslint-disable max-statements */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addRow
} from '@website/components/CommonActions'
import {
  iconActionMaker,
  imageModalActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'

export default dispatcher => {
  const changeBlockTitle = (
    newTitle: string,
    rowId: string,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, rowId, itemIdx }
    })
  }

  const changeLinkData = value => ({
    type: 'CHANGE_LINK_DATA',
    value
  })

  const removeLink = value => () => {
    dispatcher({
      type: 'REMOVE_LINK',
      value
    })
  }

  const addLink = (rowId: string, blockIdx: number) => {
    dispatcher({
      type: 'ADD_LINK',
      value: { rowId, blockIdx }
    })
  }

  const changeIconData = (value: Object) => ({
    type: 'CHANGE_ICON',
    value
  })
  const removeIcon = (value: Object) => () => ({
    type: 'REMOVE_ICON',
    value
  })

  const addIcon = (rowId: string, blockIdx: number) =>
    dispatcher({
      type: 'ADD_ICON',
      value: { rowId, blockIdx }
    })

  const removeRow = (rowId: string) => {
    dispatcher({
      type: 'REMOVE_ROW',
      value: rowId
    })
  }

  const changeBgUrlAction = (value: Object, undoable: boolean) => ({
    type: 'CHANGE_BG_IMG',
    value,
    undoable
  })

  const removeItemImgUrl = (rowId: string, itemIdx: number) => () => ({
    type: 'REMOVE_BG_IMG',
    value: { rowId, itemIdx }
  })

  const bgActions = (newUrl: string, rowId: number, itemIdx: number) => [
    ...imageModalActionMaker(dispatcher)({
      keyForSizes: 'feature12_2',
      showRemove: newUrl,
      actions: {
        change: ({ url, dimensions, undoable }) =>
          dispatcher(
            changeBgUrlAction({ url, dimensions, rowId, itemIdx }, undoable)
          ),
        remove: removeItemImgUrl(rowId, itemIdx)
      },
      imageUrl: newUrl
    })
  ]

  const linkActions = (rowId: number, blockIdx: number, linkData) => [
    ...linkActionMaker(dispatcher)({
      isAdditionalLink: true,
      idx: blockIdx,
      linkData,
      actions: {
        change: (_, linkData) => changeLinkData({ rowId, blockIdx, linkData }),
        remove: removeLink({ rowId, blockIdx })
      }
    })
  ]
  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (
    alignment: string,
    rowId: number,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, rowId, itemIdx }
    })
  }

  const _addRow = idx => dispatcher(addRow(idx))

  return {
    addRow: _addRow,
    removeRow,
    addIcon,
    bgActions,
    addLink,
    linkActions,
    removeLink,
    changeTitle: _changeTitle,
    changeBlockTitle,
    changeBlockTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeParagraph: _changeParagraph,
    iconActions: (rowId: string, itemIdx: number) =>
      iconActionMaker(dispatcher)({
        actions: {
          change: (idx, iconName) =>
            changeIconData({ rowId, itemIdx, iconName }),
          remove: removeIcon({ rowId, itemIdx })
        }
      })
  }
}
