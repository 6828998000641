import React from 'react'
import { withTheme } from 'styled-components'

const ScrollBottomBg = ({ theme }) => {
  const { palette } = theme
  const color = palette.tertiarySemiLight
  return (
    <svg
      width="106px"
      height="26px"
      viewBox="0 0 106 26"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g
          id="header-19-1366x768"
          transform="translate(-630.000000, -654.000000)"
          fill={color}
        >
          <path
            d="M736,680 L630,680 C639.395755,680 648.262172,676.55102 655.143571,670.183673 L665.200999,660.765306 C669.965044,656.387755 675.9201,654.265306 681.875156,654 L683.992509,654 C690.0799,654.132653 695.902622,656.387755 700.666667,660.765306 L710.856429,670.183673 C717.737828,676.55102 726.736579,680 736,680 Z"
            id="Path"
          />
        </g>
      </g>
    </svg>
  )
}

export default withTheme(ScrollBottomBg)
