export default {
  DARK: {
    background: p => `${p.tertiaryDark}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    quote: p => p.tertiaryDark,
    raisedShadow: () => 'none'
  },
  LIGHT: {
    background: p => `${p.tertiaryLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    quote: p => p.tertiaryDark
  },
  EXTRALIGHT: {
    background: p => `${p.tertiaryExtraLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    quote: p => p.tertiaryDark
  }
}
