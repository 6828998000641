export const validation = {
  headingMaxChar: 100,
  paragraphMaxChar: 200,
  checkmarkMaxChar: 60,
  features: {
    min: 0,
    max: 7
  },
  buttons: {
    min: 0,
    max: 2
  }
}
