/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 120px;
        `
      : css``};
  @media only screen and (max-width: 700px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding-top: 70px;
          `
        : css``};
  }
`

const Circle = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${getStyleForProp('buttonBackground')};
  border-radius: ${getStyleForProp('iconRadius')};
  pointer-events: none;
`
const Icon = styled(LibIcon).attrs(({ theme, isArrow }) => ({
  color: isArrow
    ? getStyleForProp('anchor')({ theme })
    : theme.palette.accentWhite
}))`
  margin-left: ${({ isArrow }) => (isArrow ? '5px' : 0)};
`

const BlockItemWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-width: 360px;
  margin: 60px 40px 0 40px;
  text-align: center;
  @media only screen and (max-width: 1260px) {
    margin: 60px 20px 0 20px;
  }
  @media only screen and (max-width: 1100px) {
    margin: 60px 0 0 0;
  }
  > p:first-child,
  > div:first-child,
  > h6:first-child {
    margin-top: 0;
  }
  & > .control-container {
    width: 100%;
    & > .control-container:not(:first-child) {
      margin-top: 30px;
      width: 100%;
      h1,
      p {
        margin-top: 0;
      }
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  font-weight: 600;
  width: 100%;
  ${getTextStyleByAlignment};
  margin: 20px 0 0 0;
  font-size: 20px;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  margin-top: 20px;
`

const BlocksWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: -60px;
  @media only screen and (max-width: 1100px) {
    align-items: center;
    text-align: center;
    flex-direction: ${props => {
      if (props.blockCount === 3) {
        return 'column'
      }
      return 'row'
    }};
  }
  @media only screen and (max-width: 960px) {
    flex-direction: column;
    align-items: center;
  }
  .link-section {
    margin-top: 30px;
  }
  .learn-more-link {
    color: ${getStyleForProp('anchor')};
    svg {
      fill: ${getStyleForProp('anchor')};
    }
  }
`

export default {
  StyledContainer,
  BlockItemWrap,
  Circle,
  Title,
  Description,
  BlocksWrap,
  Icon
}
