/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header42.styled'
import THEME from './Header42.theme'
import { validation } from './Header42.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Icon from '@website/common/components/Icon'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import NavBar from '@website/common/components/NavBar'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import Buttons from '@website/common/components/Buttons'
import SocialLinks from '@website/common/components/SocialLinks'
import { translate } from '@editor/common/utils/translations'

const Header42 = memo(
  ({
    data: {
      title,
      subTitle,
      paragraph,
      subTitleAlignment,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      images,
      logoColor,
      buttons,
      headerSocials,
      logoWidth,
      logoDimensions
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const [navBarHeight, setNavBarHeight] = useState(0)

    return (
      <S.StyledContainer
        isEditing={isEditing}
        isSocials={headerSocials.length > 4}
        topOffset={navBarHeight}
      >
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          isEditing={isEditing}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <Show when={[title, paragraph, buttons.length > 0]}>
          <S.WmCustomContainer topOffset={navBarHeight}>
            <S.ImageContent>
              <S.ImageFrame className={'image-1 test'}>
                <S.ImageWrapper>
                  <S.ControlContainer>
                    <ControlsWithImageCropper
                      actions={Actions.imageActions(0, images[0].imageUrl)}
                      style={{ width: '100%' }}
                    >
                      <Image
                        as={S.Img}
                        sizes={images[0].imgDimensions}
                        defaultImgSrc={images[0].imageUrl}
                        asProps={{
                          imgCropParams: images[0].imgCropParams
                        }}
                        alt="header image"
                      />
                    </ControlsWithImageCropper>
                  </S.ControlContainer>
                </S.ImageWrapper>
              </S.ImageFrame>
              <If
                condition={images[1].imageUrl}
                otherwise={() => (
                  <AddButton
                    onAdd={() => Actions.addImage(1)}
                    toShow={!images[1].imageUrl}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_image_label')}
                  </AddButton>
                )}
                then={() => (
                  <S.ImageFrame className={'image-2 test'}>
                    <S.ImageWrapper>
                      <S.ControlContainer>
                        <ControlsWithImageCropper
                          actions={Actions.imageActions(1, images[1].imageUrl)}
                          style={{ width: '100%' }}
                        >
                          <Image
                            as={S.Img}
                            sizes={images[1].imgDimensions}
                            defaultImgSrc={images[1].imageUrl}
                            asProps={{
                              imgCropParams: images[1].imgCropParams
                            }}
                            alt="header image"
                          />
                        </ControlsWithImageCropper>
                      </S.ControlContainer>
                    </S.ImageWrapper>
                  </S.ImageFrame>
                )}
              />
            </S.ImageContent>
            <S.HeaderContent
              isThirdImage={images[2].imageUrl}
              isSecondImage={images[1].imageUrl}
              isEditing={isEditing}
            >
              <If
                condition={images[2].imageUrl}
                otherwise={() => (
                  <AddButton
                    onAdd={() => Actions.addImage(2)}
                    toShow={!images[2].imageUrl}
                    style={{ margin: '50px auto' }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_image_label')}
                  </AddButton>
                )}
                then={() => (
                  <S.ImageFrame className={'image-3 test'}>
                    <S.ImageWrapper>
                      <S.ControlContainer>
                        <ControlsWithImageCropper
                          actions={Actions.imageActions(2, images[2].imageUrl)}
                          style={{ width: '100%' }}
                        >
                          <Image
                            as={S.Img}
                            sizes={images[2].imgDimensions}
                            defaultImgSrc={images[2].imageUrl}
                            asProps={{
                              imgCropParams: images[2].imgCropParams
                            }}
                            alt="header image"
                          />
                        </ControlsWithImageCropper>
                      </S.ControlContainer>
                    </S.ImageWrapper>
                  </S.ImageFrame>
                )}
              />
              <S.HeaderContentContainer
                isThirdImage={images[2].imageUrl}
                isEditing={isEditing}
              >
                <EditableContent
                  text={subTitle}
                  alignment={subTitleAlignment}
                  maxCount={validation.subTitleMaxChar}
                  className="WM_GLOBAL_heading20"
                  as={S.SubTitle}
                  onChange={Actions.changeSubTitle}
                  changeAlignment={Actions.changeSubTitleAlignment}
                />
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  required={validation.title.required}
                  maxCount={validation.headingMaxChar}
                  className="WM_GLOBAL_heading42"
                  as={S.Title}
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <S.Line
                  alignment={title ? titleAlignment : paragraphAlignment}
                />
                <EditableContent
                  text={paragraph}
                  alignment={paragraphAlignment}
                  as={S.Description}
                  maxCount={validation.paragraphMaxChar}
                  className="WM_GLOBAL_paragraph14"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
                <Buttons data={buttons} />
              </S.HeaderContentContainer>
            </S.HeaderContent>
          </S.WmCustomContainer>
        </Show>
        <SocialLinks
          className="header-socials"
          socials={headerSocials}
          socialsMaxCount={validation.headerSocials.max}
        />
      </S.StyledContainer>
    )
  }
)

export default Header42
