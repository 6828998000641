/* @flow */
import React, { forwardRef, memo, useCallback } from 'react'
import S from './RSVPCheckbox.styled'
import type { TRSVPCheckbox } from './RSVPCheckbox.type'

const RSVPCheckbox = forwardRef(
  ({ value, label, isChecked, onChange }: TRSVPCheckbox, ref) => {
    const handleChange = useCallback(
      event => {
        const { checked } = event.target
        onChange(value, checked)
      },
      [onChange, value]
    )
    return (
      <S.CheckboxWrap>
        <S.Input
          ref={ref}
          onChange={handleChange}
          type="checkbox"
          id={value}
          name={value}
          value={value}
          checked={isChecked}
        />
        <S.Label
          htmlFor={value}
          className="WM_GLOBAL_secondary-font WM_GLOBAL_paragraph14"
        >
          {label}
        </S.Label>
      </S.CheckboxWrap>
    )
  }
)

export default memo(RSVPCheckbox)
