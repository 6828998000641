/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Contact7.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`
const WMCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > .control-container {
    width: 100%;
    margin-bottom: 50px;
    max-width: 700px;
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
    }
    h1 {
      margin-bottom: 0;
      @media only screen and (max-width: 992px) {
        margin-bottom: 40px;
      }
    }
  }
`

const FeatureItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -20px auto;
  .control-container {
    margin-bottom: 15px;
    h5,
    p,
    a {
      margin-bottom: 0;
    }
  }
  ${({ areFourBlock }) =>
    areFourBlock &&
    css`
      @media only screen and (min-width: 1100px) {
        > div:nth-child(4) {
          .styled-controls {
            right: 0;
            left: initial;
          }
        }
      }
    `}
  @media only screen and (max-width: 1100px) and (min-width: 769px) {
    > div:nth-child(3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
  }
`

const ItemWrap = styled.div`
  padding: 20px;
  box-sizing: border-box;
  word-break: break-word;
  width: ${({ isMoreThanFour }) => (isMoreThanFour ? '30%' : '23%')};
  min-width: 250px;
  text-align: center;
  & > p:nth-last-child(1) {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 768px) {
    width: 49%;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  max-width: 700px;
  margin: 0 auto 50px auto;
  text-align: center;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  & + p,
  & + a {
    margin-top: 20px;
  }
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  font-weight: 500;
  ${({ isEditing }) =>
    isEditing
      ? css`
          margin-bottom: 16px;
          margin-top: 20px;
        `
      : css`
          margin-bottom: 6px;
        `};
`

const A = styled.a`
  text-decoration: none;
  display: block;
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 6px;
  font-weight: 500 !important;
  &:hover {
    opacity: 0.7;
  }
`

const IconWrapper = styled.div`
  user-select: none;
  margin: 0 0 30px 0;
  display: flex;
  justify-content: center;
`

const Circle = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getStyleForProp('blockTitle')};
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('blockTitle')
}))``

export default {
  StyledContainer,
  FeatureItemWrap,
  WMCustomContainer,
  Title,
  BlockTitle,
  BlockParagraph,
  ItemWrap,
  A,
  IconWrapper,
  Circle,
  Icon
}
