/* @flow */
import styled from 'styled-components'

import THEME from './Feature9.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  ${getBackgroundStyles(THEME)};
  word-break: break-word;
  padding: 80px 0 80px 0;
  background-color: ${getStyleForProp('background')};
  display: flex;
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    padding: 60px 0;
  }
`

const Title = styled.h4`
  color: ${getStyleForProp('heading')};
  text-align: center;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};

  text-align: center;
  line-height: 1.4;
`

const BtnWrap = styled.div`
  margin-top: 30px;
  @media only screen and (max-width: 650px) {
    max-width: 260px;
  }
`

const Button = styled.div`
  border-radius: ${getStyleForProp('buttonRadius')};
  white-space: nowrap;
  ${getStyleForProp('buttonBackground')};
  font-size: 12px;
  background-repeat: no-repeat;
  text-decoration: none;
  max-width: 260px;
  padding: 0 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: center;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  display: block;
`

const Code = styled.pre`
  margin: 0;
  &:focus {
    outline: none;
  }
`

export default {
  Code,
  Container,
  Title,
  Description,
  Button,
  BtnWrap
}
