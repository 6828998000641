/* @flow */
/* eslint-disable max-statements */
import React, { useContext, useState, useEffect, memo } from 'react'
import S from './Contact6.styled'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import SubmitButton from '@website/common/components/SubmitButton/SubmitButton'

import {
  validation,
  EMPTY_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE
} from './Contact6.consts'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { removeEmptyFields } from '@website/common/utils'
import {
  ControlsWithModalOpener,
  Controls
} from '@website/common/components/Controlled/'
import { defaultPlaceholders } from './consts'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import { isValidEmail } from '@website/common/utils'

import {
  DispatchContext,
  EditingContext,
  SiteHashContext,
  SiteIdContext
} from '@contexts'
import { contactApi } from '@website/common/api'
import { translate } from '@editor/common/utils/translations'

const initialState = {
  name: '',
  email: '',
  message: ''
}

const Contact6 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttonText,
      placeholders,
      phone,
      email,
      socialTitle,
      socials
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const siteId = useContext(SiteIdContext)
  const siteHash = useContext(SiteHashContext)
  const [nameErrorMessage, setNameErrorMessage] = useState('')
  const [emailErrorMessage, setEmailErrorMessage] = useState('')
  const [isSending, setIsSending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const [inputData, setInputData] = useState(
    isEditing ? { ...placeholders } : initialState
  )
  const [timer, setTimer] = useState(null)

  const withoutTexts =
    !isEditing && !(title || paragraph || phone || email) ? true : false

  useEffect(() => {
    if (timer) {
      setTimer(null)
    }
  })

  useEffect(() => {
    if (isEditing) {
      setInputData({ ...placeholders })
    }
  }, [placeholders])

  const addInfoButton = (key: 'phone' | 'email') => (
    <AddButton
      onAdd={() => Actions.addInfo(key)}
      isText
      toShow
      style={{
        marginTop: '10px'
      }}
    >
      <Icon name="glyph-add" className="icon" size="normal" />
      {`Add ${key}`}
    </AddButton>
  )

  const handleEmailError = () => {
    if (!inputData.email) {
      setEmailErrorMessage(EMPTY_FIELD_MESSAGE)
    }
    if (inputData.email && !isValidEmail(inputData.email)) {
      setEmailErrorMessage(INVALID_EMAIL_MESSAGE)
    }
  }

  const handleNameError = () => {
    if (!inputData.name) {
      setNameErrorMessage(EMPTY_FIELD_MESSAGE)
    }
  }

  const evtHandlerGetter = (type: string) => {
    const evtHandlers = {
      onChange: (e: Event) => {
        setInputData({ ...inputData, [type]: e.target.value })
        if (type === 'name') {
          setNameErrorMessage('')
        }
        if (type === 'email') {
          setEmailErrorMessage('')
        }
      }
    }
    if (isEditing) {
      evtHandlers.onBlur = (e: Event) => {
        const value = e.target.value || defaultPlaceholders[type]
        if (value === placeholders[type]) {
          return
        }
        Actions.changePlaceHolderText(type, value)
        setInputData({ ...inputData, [type]: value })
      }
    } else {
      evtHandlers.onBlur = () => {
        if (type === 'name') {
          handleNameError()
        }
        if (type === 'email') {
          handleEmailError()
        }
      }
    }
    return evtHandlers
  }

  const sendData = () => {
    if (isEditing || isSending || isSuccess) {
      return
    }
    handleEmailError()
    handleNameError()
    if (inputData.email && isValidEmail(inputData.email) && inputData.name) {
      setIsSending(true)
      contactApi(
        siteId,
        removeEmptyFields({
          ...inputData,
          siteHash
        })
      )
        .then(() => {
          setIsSuccess(true)
          setTimer(
            setTimeout(() => {
              setInputData(initialState)
              setIsSuccess(false)
            }, 4000)
          )
        })
        .catch(console.warn)
        .finally(() => {
          setIsSending(false)
        })
    }
  }

  const getPlaceHolderText = key => (placeholders && placeholders[key]) || ''

  const anchorInfo = (
    key: 'phone' | 'email',
    isEditing: boolean,
    alignmentKey
  ) => {
    const { [key]: text } = props.data
    const alignment = props.data[alignmentKey]
    const InfoWrapper = isEditing ? S.EmailWrap : S.EmailWrapA
    const hrefLinks = {
      phone: 'tel',
      email: 'mailto'
    }
    return (
      <ControlsWithModalOpener
        actions={Actions.infoActions(key)}
        style={{ width: '100%' }}
      >
        <InfoWrapper href={hrefLinks[key] + ':' + text} className="info">
          <EditableContent
            text={text}
            as={S.Phone}
            alignment={alignment}
            isEditing={isEditing}
            maxCount={validation.contactMaxChar}
            onChange={(info: string) => {
              Actions.infoChangeAction(key, info)
            }}
            className="WM_GLOBAL_paragraph14"
            changeAlignment={alignment => {
              Actions.changeContactAlignment(alignment, alignmentKey)
            }}
          />
        </InfoWrapper>
      </ControlsWithModalOpener>
    )
  }

  return (
    <S.Container isEditing={isEditing}>
      <S.WMCustomContainer>
        <Show when={[title, paragraph, phone, email, socials.length > 0]}>
          <S.Content>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                maxCount={validation.headingMaxChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading32"
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                className="WM_GLOBAL_paragraph14"
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <If
              condition={phone}
              otherwise={() => addInfoButton('phone')}
              then={() => anchorInfo('phone', isEditing, 'phoneAlignment')}
            />
            <If
              condition={email}
              otherwise={() => addInfoButton('email')}
              then={() => anchorInfo('email', isEditing, 'emailAlignment')}
            />
            <S.SocialWrap withoutTexts={withoutTexts}>
              <Show when={[socialTitle]}>
                <EditableContent
                  text={socialTitle}
                  as={S.SocialTitle}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeSocialTitle}
                  className="WM_GLOBAL_paragraph18"
                />
              </Show>
              <S.SocialIconsWrap isForm={!buttonText && !isEditing}>
                <Mapper
                  data={socials}
                  render={(social, idx) => (
                    <ControlsWithModalOpener
                      key={idx}
                      actions={Actions.getSocialActions(idx, social.socialUrl)}
                      alignment={idx >= socials.length - 3 ? 'right' : 'left'}
                    >
                      <LinkWrapper
                        url={social.socialUrl}
                        linkProps={{ 'aria-label': 'Social link' }}
                      >
                        <S.SocialIcon size="middle" name={social.icon} />
                      </LinkWrapper>
                    </ControlsWithModalOpener>
                  )}
                />
                <AddButton
                  onAdd={Actions.addSocial}
                  toShow={socials.length < validation.socials.max}
                  style={{ marginLeft: '10px' }}
                  type="icon"
                  large
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                </AddButton>
              </S.SocialIconsWrap>
            </S.SocialWrap>
          </S.Content>
        </Show>
        <If
          condition={buttonText}
          otherwise={() => (
            <If
              condition={isEditing}
              then={() => (
                <S.AddFormContainer>
                  <AddButton onAdd={() => Actions.addForm()} toShow>
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_form_label')}
                  </AddButton>
                </S.AddFormContainer>
              )}
            />
          )}
          then={() => (
            <S.FormContainer>
              <Controls
                actions={Actions.formActions()}
                style={{ width: '100%' }}
              >
                <S.InputContainer className="name-input-container">
                  <S.RequiredIcon
                    name="icon-asterisk"
                    className="required-icon"
                  />
                  <S.Input
                    value={inputData.name}
                    placeholder={isEditing ? '' : getPlaceHolderText('name')}
                    isEditing={isEditing}
                    aria-label="Name input"
                    className="WM_GLOBAL_secondary-font name-input"
                    {...evtHandlerGetter('name')}
                    isError={nameErrorMessage}
                  />
                  {nameErrorMessage && (
                    <S.ErrorMessage>
                      <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                      {nameErrorMessage}
                    </S.ErrorMessage>
                  )}
                </S.InputContainer>
                <S.InputContainer>
                  <S.RequiredIcon
                    name="icon-asterisk"
                    className="required-icon"
                  />
                  <S.Input
                    value={inputData.email}
                    placeholder={isEditing ? '' : getPlaceHolderText('email')}
                    isEditing={isEditing}
                    aria-label="Email input"
                    className="WM_GLOBAL_secondary-font"
                    {...evtHandlerGetter('email')}
                    isError={emailErrorMessage}
                  />
                  {emailErrorMessage && (
                    <S.ErrorMessage>
                      <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                      {emailErrorMessage}
                    </S.ErrorMessage>
                  )}
                </S.InputContainer>
                <S.TextArea
                  rows={8}
                  value={inputData.message}
                  isEditing={isEditing}
                  placeholder={isEditing ? '' : getPlaceHolderText('message')}
                  data-gramm_editor="false" // for grammarly extension
                  aria-label="Message input"
                  className="WM_GLOBAL_secondary-font"
                  {...evtHandlerGetter('message')}
                />
                <SubmitButton
                  buttonText={buttonText}
                  submitFormData={sendData}
                  showSubmitNotification={isSuccess}
                />
              </Controls>
            </S.FormContainer>
          )}
        />
      </S.WMCustomContainer>
    </S.Container>
  )
})

export default Contact6
