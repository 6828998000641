/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_SUBTITLE':
        draft.subTitle = action.value
        break
      case 'CHANGE_CTA_TEXT':
        draft.ctaText = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_ITEM_PARAGRAPH':
        const { newItemParagraph, idx, featureIdx } = action.value
        draft.features[featureIdx][idx].paragraph = newItemParagraph
        break
      case 'CHANGE_BADGE_TEXT':
        const {
          newBadgeText,
          idx: idx_,
          featureIdx: featureIdx_
        } = action.value
        draft.features[featureIdx_][idx_].badgeText = newBadgeText
        break
      case 'CHANGE_IMG_URL':
        const { url, itemIdx, blockIdx, dimensions } = action.value
        draft.features[itemIdx][blockIdx].imgUrl = url
        draft.features[itemIdx][blockIdx].imgDimensions = dimensions || {}
        break
      case 'ADD_BLOCK':
        draft.features.push(initialState.features[0])
        break
      case 'REMOVE_BLOCK':
        draft.features.splice(action.value.idx, 1)
        break
      case 'CHANGE_BUTTON_DATA':
        const { text, link } = action.value.btnData
        draft.buttonUrl = link
        draft.buttonText = text
        break
      case 'REMOVE_BOTTOM_BLOCK':
        draft.ctaText = ''
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BOTTOM_BLOCK':
        draft.ctaText = initialState.ctaText
        draft.buttonUrl = initialState.buttonUrl
        draft.buttonText = initialState.buttonText
        break
    }
  })
}
