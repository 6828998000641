/* @flow */

export default dispatch => {
  const removeSliderBackground = idx => {
    dispatch({
      type: 'REMOVE_SLIDER_BACKGROUND_IMAGE',
      value: { idx }
    })
  }

  const changeSliderBackgroundImage = (idx, url, dimensions) => {
    dispatch({
      type: 'CHANGE_SLIDER_BACKGROUND_IMAGE',
      value: { idx, url, dimensions }
    })
  }

  const changeSliderTransparency = (idx, bgOverlayTransparency) => {
    dispatch({
      type: 'CHANGE_SLIDER_BACKGROUND_TRANSPARENCY',
      value: { idx, bgOverlayTransparency }
    })
  }

  const removeSlide = idx => {
    dispatch({
      type: 'REMOVE_SLIDE',
      value: { idx }
    })
  }

  return {
    changeSliderBackgroundImage,
    removeSliderBackground,
    changeSliderTransparency,
    removeSlide
  }
}
