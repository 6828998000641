/* @flow */

import React, { useContext, memo } from 'react'
import S from './Team4.styled'
import ActionGetter from './Actions'
import { validation } from './Team4.consts'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Team4 = memo(props => {
  const {
    data: { title, titleAlignment, paragraph, paragraphAlignment, team }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WMCustomContainer>
        <Show when={[title, paragraph]}>
          <S.HeadingsContainer isEditing={isEditing}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Paragraph}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph16"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.HeadingsContainer>
        </Show>
        <S.Row>
          <Mapper
            data={team}
            render={(member: string, teamIdx: number) => {
              return (
                <>
                  <S.ControlWrapper>
                    <S.TeamItemWrap
                      isEven={teamIdx % 2 === 1}
                      isFirst={teamIdx === 0}
                      isEditing={isEditing}
                    >
                      <S.ItemImageWrapper>
                        <S.ImgBgBorder isEven={teamIdx % 2 === 1} />
                        <S.ControlContainer>
                          <ControlsWithImageCropper
                            actions={Actions.imageActions(
                              teamIdx,
                              member.imgUrl
                            )}
                          >
                            <Image
                              as={S.Img}
                              asProps={{
                                imgCropParams: member.imgCropParams
                              }}
                              defaultImgSrc={member.imgUrl}
                              sizes={member.imgDimensions}
                              alt="Team member"
                            />
                          </ControlsWithImageCropper>
                        </S.ControlContainer>
                      </S.ItemImageWrapper>
                      <S.ItemTextWrapper isEven={teamIdx % 2 === 1}>
                        <EditableContent
                          text={member.name}
                          as={S.TeamName}
                          alignment={member.nameAlignment}
                          maxCount={validation.blockTitleMaxChar}
                          className="WM_GLOBAL_heading32"
                          onChange={(newName: string) =>
                            Actions.changeName(newName, teamIdx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeNameAlignment(alignment, teamIdx)
                          }
                        />
                        <EditableContent
                          text={member.position}
                          as={S.TeamPosition}
                          alignment={member.positionAlignment}
                          maxCount={validation.blockSubTitleMaxChar}
                          className="WM_GLOBAL_paragraph14"
                          onChange={(newPosition: string) =>
                            Actions.changePosition(newPosition, teamIdx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changePositionAlignment(alignment, teamIdx)
                          }
                        />
                        <EditableContent
                          text={member.paragraph}
                          as={S.TeamParagraph}
                          alignment={member.blockParagraphAlignment}
                          maxCount={validation.blockParagraphMaxChar}
                          className="WM_GLOBAL_paragraph14"
                          onChange={(newParagraph: string) =>
                            Actions.changeItemParagraph(newParagraph, teamIdx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              teamIdx
                            )
                          }
                        />
                        <Show when={[...member.socials]}>
                          <S.SocialsWrapper>
                            <Mapper
                              data={member.socials}
                              render={(social, socialIdx) => {
                                const lastIndex = member.socials.length - 1

                                return (
                                  <ControlsWithModalOpener
                                    key={socialIdx}
                                    actions={Actions.socialActions(
                                      socialIdx,
                                      social.socialUrl,
                                      teamIdx
                                    )}
                                    alignment={
                                      socialIdx >= lastIndex - 1
                                        ? 'right'
                                        : 'left'
                                    }
                                  >
                                    <LinkWrapper
                                      url={social.socialUrl}
                                      linkProps={{
                                        'aria-label': 'Social link'
                                      }}
                                    >
                                      <S.Icon
                                        size="middle"
                                        name={social.icon}
                                      />
                                    </LinkWrapper>
                                  </ControlsWithModalOpener>
                                )
                              }}
                            />
                            <AddButton
                              onAdd={() => Actions.addSocial(teamIdx)}
                              toShow={
                                member.socials.length < validation.socials.max
                              }
                              style={{
                                marginLeft: '10px'
                              }}
                              type="icon"
                              large
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                            </AddButton>
                          </S.SocialsWrapper>
                        </Show>
                      </S.ItemTextWrapper>
                    </S.TeamItemWrap>
                  </S.ControlWrapper>
                  <AddButton
                    onAdd={() => {
                      Actions.removeBlock(
                        teamIdx,
                        team.length > validation.team.min
                      )
                    }}
                    toShow={team.length > validation.team.min}
                    style={{
                      marginTop: '40px',
                      display: 'inline-flex',
                      alignItems: 'center'
                    }}
                  >
                    <Icon name="glyph-remove" className="icon" size="normal" />
                    {translate('remove_block_label')}
                  </AddButton>
                </>
              )
            }}
          />
          <AddButton
            onAdd={Actions.addMember}
            toShow={team.length < validation.team.max}
            style={{ marginTop: '1rem', width: '116px' }}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.Row>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Team4
