/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'
import * as Styled from './CTA13_morph_bottom.styled'

const MorphBottom = () => {
  return (
    <Styled.MorphBottomWrapper>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="158.247"
        height="163.606"
        viewBox="0 0 158.247 163.606"
      >
        <g transform="translate(-419.417 -499.693)">
          <path
            className="a"
            d="M.371,2.9a4.057,4.057,0,0,0-.272.67,2.47,2.47,0,0,0,2.842,3.11c.064-.007.129-.017.194-.03a2.361,2.361,0,0,0,1.149-.61,4.942,4.942,0,0,0,.751-.662A3.552,3.552,0,0,0,6.333,3.4a2.341,2.341,0,0,0,.03-.6,2.354,2.354,0,0,0-.39-1.751,2.5,2.5,0,0,0-2.8-.927A4.96,4.96,0,0,0,.371,2.9Z"
            transform="translate(561.897 544.409) rotate(52)"
          />
          <path
            className="b"
            d="M4.958,55.522q.344-.546.68-1.1a1.071,1.071,0,0,0,.239-.279,109.847,109.847,0,0,0,5.706-10.4A94.753,94.753,0,0,0,15.57,32.578c2.15-6.843,5.311-13.3,7.493-20.122A37.759,37.759,0,0,0,25.023.973a1,1,0,0,0-1.3-.916C20.38,1.386,18.522,4.49,16.98,7.57c-1.809,3.613-3.29,7.386-4.749,11.15Q7.842,30.048,4.342,41.7c-.869,2.869-1.681,5.751-2.457,8.643-1,2.075-2.2,4.451-1.809,6.745a1.38,1.38,0,0,0,.349.718,1.134,1.134,0,0,0,1.582.535c1.5-.09,2.184-1.6,2.95-2.816Z"
            transform="matrix(0.996, -0.087, 0.087, 0.996, 533.131, 501.874)"
          />
          <path
            className="b"
            d="M10.609,10.522q-.126.077-.257.149a5.591,5.591,0,0,1-3.845,2.335A5.987,5.987,0,0,1,.244,8.759C-.5,6.088.454,1.986,2.987.579c5.294-2.941,13.879,6.1,7.621,9.943Z"
            transform="translate(559.783 608.15) rotate(-121)"
          />
          <path
            className="b"
            d="M5.389,12.225A6.086,6.086,0,0,1,1.431,9.135,4.421,4.421,0,0,1,.1,6.783,5.817,5.817,0,0,1,2.684,1.051C6.273-1.408,11.67.664,12.438,5.02c.717,4.071-2.813,8.178-7.049,7.2Z"
            transform="translate(531.859 606.255) rotate(-121)"
          />
          <path
            className="b"
            d="M11.375,8.689a6.246,6.246,0,0,1-5.3,4.379c-2.988.266-4.74-2.107-5.014-4.855a6.817,6.817,0,0,1-.028-.771A3.559,3.559,0,0,1,.122,5.855C-.539,3.339,1.6.995,3.838.294,8.952-1.3,13,3.917,11.375,8.689Z"
            transform="translate(541.658 627.412) rotate(-121)"
          />
          <path
            className="b"
            d="M.073,1.7C-.4,3.225,1.52,4.09,2.557,4.565a4.491,4.491,0,0,0,1.5.424A2.945,2.945,0,0,0,6.077,4.8a2.341,2.341,0,0,0,.717-3.458A4.113,4.113,0,0,0,3.509,0C2.456.015.463.433.073,1.7Z"
            transform="translate(566.346 570.098) rotate(143)"
          />
          <path
            className="b"
            d="M0,2.051A3.683,3.683,0,0,0,1.515,4.666c.758.712,1.663,1.42,2.826.947A2.568,2.568,0,0,0,5.815,2.994a2.548,2.548,0,0,0-.681-1.369,2.423,2.423,0,0,0-.1-.236A2.626,2.626,0,0,0,2.536,0,2.482,2.482,0,0,0,0,2.051Z"
            transform="translate(577.664 555.061) rotate(143)"
          />
          <path
            className="c"
            d="M.619,90.665C1,90.3,1.354,89.917,1.7,89.519a.341.341,0,0,0,.194-.1,49.077,49.077,0,0,0,5.087-6.744q.327-.47.646-.945a.263.263,0,0,0,.215-.1,69.919,69.919,0,0,0,8.84-13.553c2.386-4.926,3.5-10.278,4.4-15.641a108.358,108.358,0,0,0,1.919-16.9c.025-2.992-.232-5.979-.2-8.971a72.576,72.576,0,0,1,.885-8.99c.406-2.959.813-5.926,1.43-8.851.375-1.777.869-3.5,1.409-5.215q.279-.789.562-1.576a.175.175,0,0,0-.009-.139q.283-.861.57-1.724c.024-.071-.077-.094-.112-.042a27.35,27.35,0,0,0-3.521,8.69,129.985,129.985,0,0,0-5.549,17.628c-1.956,8.824-1.615,17.816-2.754,26.735-.3,2.382-.614,4.766-1.017,7.134-.256,1.508-.492,3.044-.762,4.567a57.459,57.459,0,0,1-2.226,6.325,57.055,57.055,0,0,1-2.749,5.524c-.274.41-.546.821-.822,1.231-1.343,2-2.708,3.978-4.068,5.964A47.711,47.711,0,0,0,.038,90.261a.358.358,0,0,0,.581.4Z"
            transform="matrix(0.996, -0.087, 0.087, 0.996, 503.344, 517.477)"
          />
          <path
            className="c"
            d="M5.586,97.323c.014-.016.023-.034.036-.051,1.442-.292,2.874-.638,4.293-1.027,2.885-.792,5.719-1.762,8.521-2.807,5.8-2.165,11.546-4.535,17.253-6.943A170.744,170.744,0,0,0,64.2,71.488q.753-.5,1.5-1.013c2.048-.8,4.214-1.527,6.167-2.529a2.992,2.992,0,0,0,1.384-1.572,12.034,12.034,0,0,1,1.7-2.595.752.752,0,0,0,.157-.288,161.1,161.1,0,0,0,31.4-33.785,156.058,156.058,0,0,0,8.862-14.388A106.784,106.784,0,0,0,122.383.261c.083-.234-.3-.37-.406-.149-2.114,4.458-4.1,8.96-6.428,13.316s-4.855,8.59-7.581,12.7A155.872,155.872,0,0,1,89.187,49.265a154.082,154.082,0,0,1-12.649,11.5,28.685,28.685,0,0,1-6.1,2.713A5.248,5.248,0,0,0,67.5,65.7c-.941,1.2-1.864,2.406-2.79,3.615a.764.764,0,0,0-.129.27,166.423,166.423,0,0,1-25.3,13.872C33.063,86.2,26.754,88.75,20.433,91.236c-3.052,1.2-6.132,2.34-9.271,3.293-1.63.494-3.276.939-4.939,1.312l-.776.173a.869.869,0,0,0-1.213.083,2.534,2.534,0,0,1-.223.2c-.1.016-.193.034-.29.049-.933.141-1.859-.038-2.8.065A.983.983,0,0,0,.51,98.248a4.048,4.048,0,0,0,5.076-.925Z"
            transform="matrix(0.996, -0.087, 0.087, 0.996, 419.417, 564.91)"
          />
          <path
            className="c"
            d="M21.5,57.815a63.086,63.086,0,0,1-3.845-8.731c-.289-.961-.584-1.994-.828-3.055-.4,4.232-1.02,8.455-1.694,12.642-.56,3.474-1.2,6.934-1.864,10.389a65.023,65.023,0,0,1-2.511,10.556c-.09.239-.486.232-.476-.061.188-5.664,1.817-11.386,2.767-16.962,1.006-5.9,1.922-11.827,2.447-17.793a78.38,78.38,0,0,0,.233-10.577c-1.527,8.189-5.1,16.333-10.5,22.374-.2.227-.579-.07-.42-.327a82.868,82.868,0,0,0,7.585-14.329,69.164,69.164,0,0,0,3.1-11.016A58.771,58.771,0,0,0,11,13.038C8.778,7.92,5.492,3.027.2.77-.218.592.086-.15.51.027,9.8,3.9,13.971,14.959,15.955,24.085c.177.813.331,1.626.469,2.441a.154.154,0,0,1,.126.152c0,.164-.013.329-.02.493A70.528,70.528,0,0,1,17.1,42.478a49.821,49.821,0,0,0,1.387,6.106,25.549,25.549,0,0,0,4.223,8.3c.758.892-.758,1.891-1.215.927Z"
            transform="translate(494.826 650.615) rotate(-121)"
          />
          <path
            className="c"
            d="M3.871,2.344c-.5,1.013-1.6.848-2.517.8A1.338,1.338,0,0,1,.181,2.476,1.434,1.434,0,0,1,0,1.851c0-.013,0-.025,0-.037A1.33,1.33,0,0,1,.673.641C.925.527,1.181.42,1.429.3a2.6,2.6,0,0,1,.81-.282A1.689,1.689,0,0,1,3.64.5a1.556,1.556,0,0,1,.231,1.842Z"
            transform="translate(548.359 625.043) rotate(-121)"
          />
          <path
            className="c"
            d="M3.42,2.1A7.413,7.413,0,0,1,2.3,4.329a1.354,1.354,0,0,1-2.247-.6A7.411,7.411,0,0,1,.205,1.237,1.67,1.67,0,0,1,2.244.061,1.682,1.682,0,0,1,3.42,2.1Z"
            transform="translate(567.272 598.589) rotate(-121)"
          />
          <path
            className="c"
            d="M3.883,3.394a3.542,3.542,0,0,1-2.7-.243A1.6,1.6,0,1,1,2.4.225c.7.443,1.807,1.089,1.98,1.952a1.1,1.1,0,0,1-.5,1.217Z"
            transform="translate(535.605 594.619) rotate(-121)"
          />
        </g>
      </svg>
    </Styled.MorphBottomWrapper>
  )
}

export default withTheme(MorphBottom)
