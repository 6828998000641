// @flow

import React from 'react'
import * as Styled from './styled'
import { secondary } from '@website/common/styles/colors'
import { translate } from '@editor/common/utils/translations'

const SliderControls = ({
  activeSlideIdx,
  slideCount,
  onLeftChange,
  onRightChange
}) => {
  return (
    <Styled.SliderControlsContainer>
      <Styled.InfoWrapper>
        <Styled.InfoText>{translate('slides_label')}</Styled.InfoText>
        <Styled.LeftArrow
          name="glyph-arrow_small_left"
          size="medium"
          color={secondary.SemiDark}
          onClick={onLeftChange}
        />
        <Styled.ActiveSlideIdx>{activeSlideIdx + 1}</Styled.ActiveSlideIdx>
        <Styled.Divider>/</Styled.Divider>
        <Styled.SlideCount>{slideCount}</Styled.SlideCount>
        <Styled.RightArrow
          name="glyph-arrow_small_right"
          size="medium"
          color={secondary.SemiDark}
          onClick={onRightChange}
        />
      </Styled.InfoWrapper>
    </Styled.SliderControlsContainer>
  )
}

export default SliderControls
