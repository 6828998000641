/* @flow */
import { validation } from '../Pricing6_2.consts'
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import ColorsIcon from '@editor/common/assets/svgr-icons/color_palette_icon.svg'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeSlideTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeItemData = (
    data: string,
    activeSlideIdx: number,
    itemIdx: number,
    keyInStore: string
  ) => ({
    type: 'CHANGE_ITEM_DATA',
    value: { data, activeSlideIdx, itemIdx, keyInStore }
  })
  const changeItemTitle = (
    title: string,
    activeSlideIdx: number,
    itemIdx: number
  ) => {
    dispatcher(changeItemData(title, activeSlideIdx, itemIdx, 'title'))
  }

  const changeItemParagraph = (
    paragraph: string,
    activeSlideIdx: number,
    itemIdx: number
  ) => {
    dispatcher(changeItemData(paragraph, activeSlideIdx, itemIdx, 'paragraph'))
  }

  const changeItemInfo = (
    info: string,
    activeSlideIdx: number,
    itemIdx: number
  ) => {
    dispatcher(changeItemData(info, activeSlideIdx, itemIdx, 'info'))
  }

  const changeItemPrice = (
    price: string,
    activeSlideIdx: number,
    itemIdx: number
  ) => {
    dispatcher(changeItemData(price, activeSlideIdx, itemIdx, 'price'))
  }

  const removeMenuItem = (idx: number, activeIdx: number) => ({
    type: 'REMOVE_MENU_ITEM',
    value: { idx, activeIdx }
  })
  const menuItemActions = (
    idx: number,
    activeIdx: number,
    menuItemsCount: number
  ) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeMenuItem(activeIdx, idx)),
      toShow: menuItemsCount > validation.slides.menu.min
    }
  ]
  const addMenuItem = (activeIdx: number) => {
    dispatcher({
      type: 'ADD_MENU_ITEM',
      value: activeIdx
    })
  }

  const changeBadgeName = (
    newName: stinrg,
    slideIdx: number,
    menuIdx: number,
    badgeIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BADGE_NAME',
      value: { newName, slideIdx, menuIdx, badgeIdx }
    })
  }

  const addBadgeItem = (slideIdx: number, menuIdx: number) => {
    dispatcher({
      type: 'ADD_BADGE_ITEM',
      value: { slideIdx, menuIdx }
    })
  }

  const addSlide = () => {
    dispatcher({ type: 'ADD_SLIDE' })
  }

  const changeBadgeColor = (
    slideIdx: number,
    menuIdx: number,
    badgeIdx: number,
    color: string
  ) => {
    dispatcher({
      type: 'CHANGE_BADGE_COLOR',
      value: { slideIdx, menuIdx, badgeIdx, color }
    })
  }

  const changeSlideTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLIDE_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const getBadgeActions = (
    slideIdx: number,
    menuIdx: number,
    badgeIdx: number,
    setSelectedBadgeIdx: (menuIdx: number, badgeIdx: number) => void
  ) => [
    {
      IconComp: ColorsIcon,
      tooltipText: translate('change_color_label'),
      handler: () => setSelectedBadgeIdx(menuIdx, badgeIdx)
    },
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () =>
        dispatcher({
          type: 'REMOVE_BADGE',
          value: { slideIdx, menuIdx, badgeIdx }
        })
    }
  ]
  return {
    addSlide,
    getBadgeActions,
    addBadgeItem,
    changeBadgeColor,
    changeBadgeName,
    addMenuItem,
    changeItemPrice,
    changeItemInfo,
    changeItemTitle,
    changeSlideTitle,
    menuItemActions,
    changeItemParagraph,
    changeSlideTitleAlignment,
    blockActions: removeBlockActionMaker(dispatcher),
    sliderActions: (idx, imageUrl) =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'Pricing6_2',
        showRemove: false,
        additionalArgs: { idx },
        imageUrl
      })
  }
}
