/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockData = value => {
    dispatcher({
      type: 'CHANGE_BLOCK_DATA',
      value
    })
  }

  const addBlock = (idx: string) => {
    dispatcher({
      type: 'ADD_BLOCK',
      value: idx
    })
  }

  const removeBlock = (idx: string) => {
    dispatcher({
      type: 'REMOVE_BLOCK',
      value: idx
    })
  }

  const blockImageActions = (
    featureIdx: number,
    blockIdx: number,
    subIdx: number,
    imageUrl: string
  ) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature16',
      showRemove: false,
      additionalArgs: { featureIdx, blockIdx, subIdx },
      imageUrl
    })

  const changeBlockBadgetext = (featureIdx, blockIdx, subIdx, data) => {
    changeBlockData({ featureIdx, blockIdx, subIdx, data, key: 'badgeText' })
  }

  const changeBlockTitle = (featureIdx, blockIdx, subIdx, data) => {
    changeBlockData({ featureIdx, blockIdx, subIdx, data, key: 'title' })
  }

  const changeBlockParagraph = (featureIdx, blockIdx, subIdx, data) => {
    changeBlockData({ featureIdx, blockIdx, subIdx, data, key: 'paragraph' })
  }

  const changeBlockTitleAlignment = (
    featureIdx,
    blockIdx,
    subIdx
  ) => alignment => {
    changeBlockData({
      featureIdx,
      blockIdx,
      subIdx,
      data: alignment,
      key: 'blockTitleAlignment'
    })
  }

  const changeBlockParagraphAlignment = (
    featureIdx,
    blockIdx,
    subIdx
  ) => alignment => {
    changeBlockData({
      featureIdx,
      blockIdx,
      subIdx,
      data: alignment,
      key: 'blockParagraphAlignment'
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addBlock,
    removeBlock,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle,
    blockImageActions,
    changeBlockBadgetext,
    changeBlockParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment
  }
}
