/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Morph = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="105" height="98" viewBox="0 0 105 98">
  <g id="Group_17738" data-name="Group 17738" transform="translate(-3072.562 509)" opacity="0.6">
    <circle id="Ellipse_94" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3072.562 -509)"/>
    <circle id="Ellipse_94-2" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3089.562 -509)"/>
    <circle id="Ellipse_94-3" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3106.562 -509)"/>
    <circle id="Ellipse_94-4" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3123.562 -509)"/>
    <circle id="Ellipse_94-5" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3140.562 -509)"/>
    <circle id="Ellipse_94-6" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3157.562 -509)"/>
    <circle id="Ellipse_94-7" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3174.562 -509)"/>
    <circle id="Ellipse_94-8" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3072.562 -490)"/>
    <circle id="Ellipse_94-9" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3089.562 -490)"/>
    <circle id="Ellipse_94-10" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3106.562 -490)"/>
    <circle id="Ellipse_94-11" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3123.562 -490)"/>
    <circle id="Ellipse_94-12" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3140.562 -490)"/>
    <circle id="Ellipse_94-13" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3157.562 -490)"/>
    <circle id="Ellipse_94-14" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3174.562 -490)"/>
    <circle id="Ellipse_94-15" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3072.562 -471)"/>
    <circle id="Ellipse_94-16" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3089.562 -471)"/>
    <circle id="Ellipse_94-17" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3106.562 -471)"/>
    <circle id="Ellipse_94-18" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3123.562 -471)"/>
    <circle id="Ellipse_94-19" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3140.562 -471)"/>
    <circle id="Ellipse_94-20" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3157.562 -471)"/>
    <circle id="Ellipse_94-21" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3174.562 -471)"/>
    <circle id="Ellipse_94-22" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3072.562 -452)"/>
    <circle id="Ellipse_94-23" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3089.562 -452)"/>
    <circle id="Ellipse_94-24" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3106.562 -452)"/>
    <circle id="Ellipse_94-25" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3123.562 -452)"/>
    <circle id="Ellipse_94-26" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3140.562 -452)"/>
    <circle id="Ellipse_94-27" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3157.562 -452)"/>
    <circle id="Ellipse_94-28" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3174.562 -452)"/>
    <circle id="Ellipse_94-29" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3072.562 -433)"/>
    <circle id="Ellipse_94-30" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3089.562 -433)"/>
    <circle id="Ellipse_94-31" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3106.562 -433)"/>
    <circle id="Ellipse_94-32" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3123.562 -433)"/>
    <circle id="Ellipse_94-33" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3140.562 -433)"/>
    <circle id="Ellipse_94-34" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3157.562 -433)"/>
    <circle id="Ellipse_94-35" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3174.562 -433)"/>
    <circle id="Ellipse_94-36" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3072.562 -414)"/>
    <circle id="Ellipse_94-37" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3089.562 -414)"/>
    <circle id="Ellipse_94-38" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3106.562 -414)"/>
    <circle id="Ellipse_94-39" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3123.562 -414)"/>
    <circle id="Ellipse_94-40" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3140.562 -414)"/>
    <circle id="Ellipse_94-41" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3157.562 -414)"/>
    <circle id="Ellipse_94-42" data-name="Ellipse 94" cx="1.5" cy="1.5" r="1.5" transform="translate(3174.562 -414)"/>
  </g>
</svg>  )
}

export default withTheme(Morph)
