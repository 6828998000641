import styled from 'styled-components'

import THEME from './theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const AddComponent = styled.div`
  position: relative;
  width: 100%;
  z-index: 2;
  height: 4px;
  padding-bottom: ${({ isLast }) => (isLast ? '25px' : '0')};

  @media only screen and (max-width: 768px) {
    padding: 25px 0;
    background-color: ${getStyleForProp('background')};
    height: auto;
  }
`

export const Line = styled.div`
  width: 100%;
  height: 4px;
  position: absolute;
  top: 0;
  background-color: #3271e6;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  box-sizing: border-box;
  transition: transform 0.3s;

  @media only screen and (max-width: 768px) {
    top: 23px;
    transform: scaleY(1.5);
  }
`

export const AddComponentButton = styled.button`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  margin: 0 auto;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: #3271e6;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;

  svg {
    fill: #ffffff;
    flex-shrink: 0;
    transition: all 0.3s;
  }

  span {
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
    margin-bottom: 2px;
  }

  &:before {
    content: '';
    position: absolute;
    left: -4px;
    width: 5px;
    height: 2px;
    background-color: #3271e6;
    transition: all 0.3s;
  }

  &:after {
    content: '';
    position: absolute;
    right: -4px;
    width: 5px;
    height: 2px;
    background-color: #3271e6;
    transition: all 0.3s;
  }

  @media only screen and (min-width: 769px) {
    top: -16px;
    height: 36px;
    width: 36px;

    span {
      max-width: 0;
      overflow: hidden;
      transition: max-width 0.3s;
    }

    &:hover {
      width: 155px;
      height: 48px;
      top: -22px;

      & > span {
        max-width: 155px;
      }

      & > svg {
        padding-right: 5px;
      }

      &:before,
      :after {
        transform: scale(1.4);
      }

      &:hover ~ ${Line} {
        transform: scaleY(1.4);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    width: 155px;
    height: 48px;
    top: 1px;

    & > svg {
      padding-right: 5px;
    }

    &:before,
    :after {
      transform: scale(1.4);
    }
  }
`
