//@flow
import React, { memo } from 'react'

import { translate } from '@editor/common/utils/translations'
import { SearchWithDatabases } from '@editor/common/components/SemrushCommon'
import { SUGGESTIONS } from './consts'
import { KEYWORD_CHAR_LIMIT } from '../consts'
import * as Styled from './styled'

const SearchContent = ({
  onSearchChange,
  onSearchClick,
  handleEnterPress,
  searchValue,
  database,
  onDatabaseChange,
  onRemoveIconClick,
  onExamplesClick
}) => {
  return (
    <Styled.SearchContent>
      <SearchWithDatabases
        database={database}
        count={KEYWORD_CHAR_LIMIT}
        searchValue={searchValue}
        onSearchClick={onSearchClick}
        onSearchChange={onSearchChange}
        handleEnterPress={handleEnterPress}
        onDatabaseChange={onDatabaseChange}
        onRemoveIconClick={onRemoveIconClick}
      />
      <Styled.Suggestions
        hasErrorMessage={searchValue.length >= KEYWORD_CHAR_LIMIT}
      >
        <span>{translate('examples_label')}:</span>
        {SUGGESTIONS.map((suggestion, idx) => (
          <p
            key={idx}
            onClick={() => {
              onExamplesClick(suggestion)
            }}
          >
            {suggestion}
          </p>
        ))}
      </Styled.Suggestions>
    </Styled.SearchContent>
  )
}

export default memo(SearchContent)
