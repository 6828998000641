/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature19.styled'
import ActionGetter from './Actions'
import { validation } from './Feature19.consts'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const Feature19 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      features,
      imageUrl,
      buttons,
      imgCropParams,
      imgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph, features.length > 0]}>
          <S.FeatureContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.headingMaxChar}
                onChange={(newTitle: string) => Actions.changeTitle(newTitle)}
                className="WM_GLOBAL_heading42"
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.paragraphMaxChar}
                onChange={(newParagraph: string) =>
                  Actions.changeParagraph(newParagraph)
                }
                className="WM_GLOBAL_paragraph18"
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <Show when={[features.length > 0]}>
              <S.List>
                <Mapper
                  data={features}
                  render={(feature: string, idx: number) => (
                    <Show when={[feature.paragraph]} key={idx}>
                      <ControlsWithModalOpener
                        actions={Actions.featureActions(idx, features.length)}
                      >
                        <S.ListItemWrap>
                          <S.IconWrap>
                            <ControlsWithModalOpener
                              actions={Actions.iconActions({
                                idx,
                                showRemove: false
                              })}
                            >
                              <S.Icon name={feature.icon} size="large" />
                            </ControlsWithModalOpener>
                          </S.IconWrap>

                          <EditableContent
                            toPropogate={false}
                            text={feature.paragraph}
                            as={S.ListItem}
                            maxCount={validation.checkmarkMaxChar}
                            onChange={(newContent: string) =>
                              Actions.changeListItem(newContent, idx)
                            }
                            className="WM_GLOBAL_paragraph18"
                          />
                        </S.ListItemWrap>
                      </ControlsWithModalOpener>
                    </Show>
                  )}
                />
                <AddButton
                  onAdd={Actions.addNewItem}
                  toShow={features.length < validation.features.max}
                  style={{
                    marginTop: '1rem'
                  }}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_item_label')}
                </AddButton>
              </S.List>
            </Show>
            <Buttons data={buttons} />
          </S.FeatureContent>
        </Show>
        <S.ImageWrap>
          <S.ImageFigure>
            <ControlsWithImageCropper
              actions={Actions.imgActions(imageUrl)}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                alt="Section img"
                defaultImgSrc={imageUrl}
                asProps={{ imgCropParams }}
                sizes={imgDimensions}
              />
            </ControlsWithImageCropper>
          </S.ImageFigure>
        </S.ImageWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature19
