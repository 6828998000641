/* @flow */
import styled, { css } from 'styled-components'
import THEME from './FAQ1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  background-position: right bottom;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 679px) {
        padding-top: 150px;
      }
    `}
`

const FaqContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('heading')};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const ButtonWrap = styled.div`
  margin-top: 50px;
`

const Anchor = styled.a`
  border-radius: ${getStyleForProp('buttonRadius')};
  white-space: nowrap;
  ${getStyleForProp('buttonBackground')};
  background-repeat: no-repeat;
  text-decoration: none;
  max-width: 260px;
  padding: 0 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: center;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 400;
  background-repeat: no-repeat !important;
  font-size: 14px;
  display: block;
  @media only screen and (max-width: 650px) {
    height: 40px;
    line-height: 40px;
  }
`

const FaqHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 30px auto;
  width: 100%;
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    h1 {
      margin-bottom: 0;
    }
  }
`

const FaqsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 1100px;
  margin-bottom: -20px;
  @media only screen and (max-width: 920px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }
`

const Faqs = styled.div`
  width: 45%;
  flex-grow: 1;
  margin: 0 20px;
  @media only screen and (max-width: 920px) {
    width: 100%;
    max-width: 500px;
  }
`

const FaqItemWrap = styled.article`
  width: 100%;
  max-width: 510px;
  background: ${({ theme }) => theme.palette.accentWhite};
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  box-sizing: border-box;
  margin: 20px auto;
  cursor: pointer;
  @media only screen and (max-width: 920px) {
    margin: 10px 0;
    &:last-child {
      margin: 10px 0 0 0;
    }
  }
  .styled-controls {
    > div {
      > div:nth-child(4) {
        &:after {
          display: none;
        }
      }
    }
  }
`

const FaqItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-height: 60px;
  box-sizing: border-box;
  img {
    transition: all 0.2s ease;
    transform: ${({ toExpand }) => (toExpand ? 'rotate(180deg)' : '0')};
  }
  > div {
    width: 100%;
    .control-container {
      width: calc(100% - 10px);
      margin-right: 10px;
      h2 {
        padding-right: 0;
      }
    }
  }
`

const FaqItemHeaderText = styled.h2`
  color: ${getStyleForProp('faqContent')};
  font-weight: 400;
  ${getTextStyleByAlignment};
  width: 100%;
  padding-right: 10px;
  box-sizing: border-box;
`

const Arrow = styled.img``

const FaqContentWrapper = styled.div`
  max-height: ${({ toExpand }) => (toExpand ? 'auto' : 0)};
  overflow: ${({ toExpand, isEditing }) =>
    toExpand && isEditing ? 'visible' : 'hidden'};
  transition: max-height 0.15s ease-out;
  & > div > .control-container {
    margin: 2px 20px 20px 20px;
    & > p {
      margin: 0;
    }
  }
`

const FaqContent = styled.p`
  margin: 2px 20px 20px 20px;
  padding-top: 20px;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('faqContent')};
  line-height: 1.4;
  border-top: 1px solid ${({ theme }) => theme.palette.tertiaryLight};
  word-break: break-word;
`

export default {
  StyledContainer,
  FaqContainer,
  Title,
  Paragraph,
  FaqContentWrapper,
  FaqHeader,
  FaqsWrapper,
  Faqs,
  FaqItemHeader,
  FaqItemHeaderText,
  Arrow,
  FaqItemWrap,
  FaqContent,
  ButtonWrap,
  Anchor
}
