/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Contact4.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 80px 0 0 0;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 779px) {
        padding-top: 150px;
      }
    `}

  @media only screen and (max-width: 768px) {
    padding: ${({ isEditing }) => (isEditing ? '100px 0 0 0' : '80px 0 0 0')};
  }
  @media only screen and (max-width: 650px) {
    padding: ${({ isEditing }) => (isEditing ? '100px 0 0 0' : '60px 0 0 0')};
  }
  .info {
    display: flex;
    padding: 12px 24px;
    width: 100%;
    box-sizing: border-box;
    text-decoration: none;
    @media only screen and (max-width: 457px) {
      padding-right: 0px;
      padding-left: 0;
    }
  }
`

const Content = styled.div`
  width: 90%;
  max-width: 600px;
  margin: 0 auto 50px auto;
  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
    width: 100%;
    max-width: initial;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    width: 100%;
  }
  .control-container:nth-child(2) {
    margin-top: 20px;
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const MapWrap = styled.div`
  overflow: hidden;
  position: relative;
  font-size: 0;
`

const Map = styled.iframe`
  width: 100%;
  height: 70vh;
  margin: -140px 0 0 0;
  @media only screen and (max-width: 1200px) {
    height: 90vh;
  }
`

const ContactInfo = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  position: absolute;
  z-index: 2;
  top: ${({ isEditing }) => (isEditing ? '50px' : '30px')};
  left: 30px;
  background: #fff;
  @media only screen and (max-width: 767px) {
    position: relative;
    top: initial;
    left: initial;
    border-radius: 0;
    max-width: initial;
    display: flex;
    flex-direction: column;
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      margin-top: 50px;
    `};
  > .control-container:first-child {
    width: 100%;
  }
`

const InfoContainerTitle = styled.p`
  padding: 16px 30px 14px 30px;
  min-height: 25px;
  font-size: 18px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  width: 100%;
  box-sizing: border-box;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.palette.primaryDark};
`

const InfoWrap = styled.div`
  padding: 6px;
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const LocationWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding: 12px 24px;
  @media only screen and (max-width: 457px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.primaryDark
}))`
  min-width: 24px;
`

const Location = styled.address`
  font-style: initial;
  margin: 0 0 0 20px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
`

const PhoneWrap = styled.div``

const PhoneWrapA = styled.a``

const Phone = styled.p`
  margin: 0 0 0 18px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
`

const EmailWrapA = styled.a`
  @media only screen and (max-width: 457px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const EmailWrap = styled.div`
  @media only screen and (max-width: 457px) {
    padding-right: 0;
    padding-left: 0;
  }
`

const Email = styled.p`
  margin: 0 0 0 18px;
  font-size: 14px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
`

export default {
  StyledContainer,
  Title,
  Paragraph,
  Map,
  Content,
  MapWrap,
  ContactInfo,
  LocationWrap,
  Location,
  InfoContainerTitle,
  PhoneWrap,
  Phone,
  EmailWrap,
  Email,
  Divider,
  InfoWrap,
  PhoneWrapA,
  EmailWrapA,
  Icon
}
