/* @flow */
import styled, { createGlobalStyle } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import { secondary, white } from '@website/common/styles/colors'

export const WMCustomContainer = styled(WMContainer)`
  padding: 90px 80px;
  word-break: break-word;
  background: linear-gradient(180deg, #8980f7 0%, #4136c1 100%);
  width: 100%;
  z-index: 10;
  max-width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 769px) {
    flex-direction: column;
    padding: 70px 20px;
  }
`

export const SubmitText = styled.p`
  cursor: pointer;
  position: relative;
  @media only screen and (max-width: 768px) {
    margin-top: 45px;
  }
  &:before {
    content: '';
    position: absolute;
    left: -22px;
    background-color: #6291f7;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    @media only screen and (max-width: 768px) {
      left: 50%;
      transform: translateX(-50%);
      top: -20px;
    }
  }
`

export const Text = styled.p`
  position: relative;
  color: ${white};
  font-size: 18px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
  line-height: 22px;
  margin: 0 40px 0 15px;
  padding-left: 15px;

  @media only screen and (max-width: 768px) {
    text-align: center;
    padding: 0;
    margin: 0 auto 15px 15px;
  }
  @media only screen and (max-width: 980px) {
    margin: 30px 15px;
  }
  @media only screen and (min-width: 769px) {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 2px;
      top: 0;
      bottom: 0;
      background-color: #c1c9e0;
    }
  }
  @media only screen and (max-width: 768px) {
    &:before {
      content: '';
      position: absolute;
      top: -30px;
      left: 46%;
      width: 60px;
      height: 1px;
      background-color: #c1c9e0;
    }
  }
  @media only screen and (max-width: 600px) {
    &:before {
      left: 44%;
    }
  }
  @media only screen and (max-width: 458px) {
    &:before {
      left: 42%;
    }
  }
`

export const Bold = styled.span`
  font-weight: 500;
  color: ${white};
`

export const LeftContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const LogoWrapper = styled.div`
  max-width: 255px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`

export const Button = styled.button`
  background: linear-gradient(180deg, ${white} 0%, #f5fafe 100%);
  &:hover {
    background: ${white};
    color: ${secondary.Dark};
  }
  padding: 11px 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  line-height: 17px;
  color: #545f7e;

  color: ${secondary.semiDark};
  white-space: nowrap;
`
