/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text4.styled'
import { validation } from './Text4.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'

const Text4 = memo(
  ({
    data: {
      title,
      paragraph,
      secondTitle,
      secondParagraph,
      titleAlignment,
      paragraphAlignment,
      secondTitleAlignment,
      secondParagraphAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    return (
      <Show when={[title, paragraph, secondTitle, secondParagraph]}>
        <S.StyledContainer
          isEditing={isEditing}
          backgroundImgUrl={backgroundImgUrl}
          backgroundImgDimensions={backgroundImgDimensions}
          bgOverlayTransparency={bgOverlayTransparency}
        >
          <S.WmCustomContainer>
            <Show when={[title, paragraph]}>
              <S.ItemWrap>
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  as={S.Title}
                  maxCount={validation.maxTitleChar}
                  className="WM_GLOBAL_heading"
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
                <EditableContent
                  text={paragraph}
                  alignment={paragraphAlignment}
                  as={S.Description}
                  maxCount={validation.maxParagraphChar}
                  className="WM_GLOBAL_paragraph"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </S.ItemWrap>
            </Show>
            <Show when={[secondTitle, secondParagraph]}>
              <S.ItemWrap>
                <EditableContent
                  as={S.Title}
                  text={secondTitle}
                  alignment={secondTitleAlignment}
                  maxCount={validation.maxTitleChar}
                  className="WM_GLOBAL_heading"
                  onChange={Actions.changeSecondTitle}
                  changeAlignment={Actions.changeSecondTitleAlignment}
                />
                <EditableContent
                  as={S.Description}
                  alignment={secondParagraphAlignment}
                  text={secondParagraph}
                  maxCount={validation.maxParagraphChar}
                  className="WM_GLOBAL_paragraph"
                  onChange={Actions.changeSecondParagraph}
                  changeAlignment={Actions.changeSecondParagraphAlignment}
                />
              </S.ItemWrap>
            </Show>
          </S.WmCustomContainer>
        </S.StyledContainer>
      </Show>
    )
  }
)

export default Text4
