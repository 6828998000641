/* @flow */
import styled from 'styled-components'

import THEME from './Text4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding-top: ${({ isEditing }) => isEditing && '150px'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 45%;
  word-break: break-word;
  text-align: left;
  &:only-child {
    text-align: center;
  }
  @media only screen and (min-width: 1025px) {
    margin: 0 20px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    text-align: center;
    &:nth-child(2) {
      margin-top: 30px;
    }
  }
  > p:first-child,
  > div:first-child,
  > h6:first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  line-height: 1.4;
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const TextImg = styled.img`
  border-radius: ${getStyleForProp('cardRadius')};
`

export default {
  StyledContainer,
  WmCustomContainer,
  ItemWrap,
  Title,
  Description,
  TextImg
}
