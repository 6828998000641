/* @flow */
import React, { useEffect } from 'react'
import * as S from './ProjectLinks.styled'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Mapper from '../Mapper'
import ReactDOM from 'react-dom'
import LinkWrapper from '../LinkWrapper'
import { ControlsWithModalOpener } from '../Controlled'
import CloseIcon from '@website/common/assets/svgr-icons/close.svg'
import { translate } from '@editor/common/utils/translations'
import LanguagesMenu from '@website/common/components/LanguagesMenu'

/**
 * @param {Number} link index
 * @returns {Number} returns width of specified link (with margin)
 **/
const getLinkWidth = (idx: number) => {
  const elem = document.getElementById(`header_link_${idx}`)
  const elemWidth = elem ? elem.offsetWidth : 125 // elem width  125px, if elem `id` is not set yet
  return elemWidth + 30 // margin left of 30 px
}

/**
 *
 * @param {Array} links navbar links array
 * @param {Number} maxWidth max width that navbar can occupy
 * @param {Number} sum of link widths.
 * @param {Number} index calculated link's index.
 * @returns {Number} returns last link's index that can be visible outside of `more` dropdown.
 */
export const getLastVisibleLinkIndex = (links, maxWidth, sum, index) => {
  if (sum > maxWidth || links.length === 0) {
    return index
  }
  sum += getLinkWidth(index)
  index = index + 1
  return getLastVisibleLinkIndex(links.slice(1), maxWidth, sum, index)
}

export const MobileNavBar = ({
  onAdd,
  Actions,
  links,
  closeNavBar,
  isWebsiteMultilingual,
  websiteLanguages,
  additionalContent: AdditionalContent
}) => {
  const websiteEditor = document.getElementById('root')

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return ReactDOM.createPortal(
    <S.MobileLinkContainer
      onClick={e => e.stopPropagation()}
      className="mobile-link-container"
    >
      <S.IconContainer onClick={closeNavBar}>
        <Icon SvgComp={CloseIcon} size="large" />
      </S.IconContainer>
      <S.MobileNavBarContent>
        <S.MobileLinks>
          <S.MobileLink className="WM_GLOBAL_secondary-font">
            <AddButton onAdd={onAdd} toShow={true}>
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_link_label')}
            </AddButton>
          </S.MobileLink>

          <Mapper
            data={links}
            render={(urlData, idx) => {
              return (
                <S.MobileLink
                  key={idx}
                  onClick={closeNavBar}
                  className="WM_GLOBAL_secondary-font"
                >
                  <ControlsWithModalOpener
                    actions={Actions.linkActions(idx, urlData)}
                    isRelativeToWrapper
                  >
                    <LinkWrapper text={urlData.text} url={urlData.link} />
                  </ControlsWithModalOpener>
                </S.MobileLink>
              )
            }}
          />
          {AdditionalContent && <AdditionalContent />}
        </S.MobileLinks>
        {isWebsiteMultilingual ? (
          <LanguagesMenu websiteLanguages={websiteLanguages} />
        ) : null}
      </S.MobileNavBarContent>
    </S.MobileLinkContainer>,
    websiteEditor
  )
}

export const LinksList = ({ list, actions, isNavBarFixed }) => (
  <Mapper
    data={list}
    render={(link, idx) => (
      <S.Wrap key={idx} isNavBarFixed={isNavBarFixed}>
        <ControlsWithModalOpener actions={actions.linkActions(idx, link)}>
          <LinkWrapper
            text={link.text}
            url={link.link}
            linkProps={{ id: `header_link_${idx}` }}
          />
        </ControlsWithModalOpener>
      </S.Wrap>
    )}
  />
)
