/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Team8.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding-top: ${({ isEditing }) => (isEditing ? '140px' : '80px')};

  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => (isEditing ? '120px' : '60px')};
  }
  > :first-child {
    padding-top: 0;
  }
  .add-button-wrap {
    margin-top: 20px;
  }
`

export const WMCustomContainer = styled(WMContainer)`
  width: 100%;
  max-width: 1480px;
  @media only screen and (max-width: 768px) {
    max-width: 1250px;
    width: 100%;
  }
  > .control-container:first-child {
    width: 100%;
    max-width: 800px;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    h1 {
      margin-top: 0;
    }
  }
  & > .control-container {
    & > h1 {
      width: 100%;
      max-width: unset;
      margin: 0;
      @media only screen and (max-width: 768px) {
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
`

export const Title = styled.h1`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`

export const Name = styled.h2`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-weight: 600;
  margin-bottom: 0;
  ${getTextStyleByAlignment}
`

export const Position = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-weight: 500;
  padding-top: ${({ isEditing }) => (isEditing ? '15px' : '10px')};
  ${getTextStyleByAlignment}
`

export const ItemParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  padding-top: ${({ isEditing }) => (isEditing ? '15px' : '10px')};
  ${getTextStyleByAlignment}
`

export const BlockContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 50px;
  margin: -25px auto;
  max-width: ${({ isFiveBlock, isSixBlock }) =>
    isFiveBlock || isSixBlock ? '1200px' : ''};
  @media only screen and (max-width: 768px) {
    padding-top: 40px;
  }
  @media only screen and (max-width: 650px) {
    margin-bottom: -20px;
  }
  @media only screen and (max-width: 1300px) {
    max-width: ${({ isFourBlock }) => (isFourBlock ? '800px' : '')};
  }
`

export const BlockItem = styled.div`
  width: 260px;
  background-color: #fff;
  border-radius: ${getStyleForProp('borderRadius')};
  box-shadow: ${({ theme }) =>
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  margin: 25px 40px;
  @media only screen and (max-width: 1440px) {
    margin: 25px;
  }
  @media only screen and (max-width: 650px) {
    margin: 20px;
  }
`

export const BlockItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

export const InfoWrap = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  > :first-child {
    padding-top: 0;
  }
`

export const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 115%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 115%;
    height: initial;
  }
`

export const ImageWrapper = styled.div`
  width: 100%;
  .lazy-figure {
    padding-top: 115%;
    border-top-left-radius: ${getStyleForProp('cardRadius')};
    border-top-right-radius: ${getStyleForProp('cardRadius')};
  }
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`
