/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_QUESTION':
        draft.faqs[action.value.idx].question = action.value.newQuestion
        break
      case 'CHANGE_ANSWER':
        draft.faqs[action.value.idx].answer = action.value.newAnswer
        break
      case 'ADD_FAQ':
        draft.faqs.push(initialState.faqs[0])
        break
      case 'REMOVE_FAQ':
        draft.faqs.splice(action.value, 1)
        break
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
    }
  })
}
