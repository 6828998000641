// @flow

const PATHS_NOT_TO_REDIRECT = [
  '/new/lang/start-creation/add-component',
  '/new/lang/start-creation/choose-template',
  '/new/lang/start-creation/ai',
  'new/lang/preview-project/template',
  'new/lang/preview-project/site-preset',
  'new/lang/preview-project/ai-preset'
]

export const shouldRedirectToStart = (pathName: string): boolean =>
  !PATHS_NOT_TO_REDIRECT.some(path => pathName.includes(path))
