/* @flow */
import styled from 'styled-components'
import THEME from './Basic1.theme'
import {
  getBackgroundStyles
} from '@website/common/utils'
import Container from '@website/common/components/Container'


const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-top: 150px;
  padding-bottom: 150px;
  @media only screen and (max-width: 768px) {
    padding-top: 125px;
    padding-bottom: 125px;
  }
  @media only screen and (min-width: 769px) {
    padding-top: ${({isEditing}) => isEditing ? '210px' : '150px'};
  }
`
export default {
  StyledContainer
}
