/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header27.styled'
import THEME from './Header27.theme'
import { validation } from './Header27.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Mapper from '@website/common/components/Mapper'
import Socials from '@website/common/components/Socials'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { translate } from '@editor/common/utils/translations'

const Header27 = memo(props => {
  const {
    data: {
      paragraph,
      paragraphAlignment,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      bgOverlayTransparency,
      logoWidth,
      logoDimensions,
      backgroundImgDimensions,
      socials,
      blocks
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.StyledContainer
      topOffset={navBarHeight}
      isEditing={isEditing}
      isOnlyBlocks={!paragraph}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        alwaysShowBackground
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || socials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={socials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.HeaderContent
        isEditing={isEditing}
        isOnlyBlocks={!paragraph}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <EditableContent
          text={paragraph}
          as={S.Description}
          alignment={paragraphAlignment}
          required={validation.paragraph.required}
          maxCount={validation.paragraphMaxChar}
          className="WM_GLOBAL_heading32"
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
      </S.HeaderContent>
      <S.WmCustomContainer isOnlyBlocks={!paragraph}>
        <S.BlocksContainer>
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => {
              return (
                <S.ControlWrapper
                  key={idx}
                  isFourOrTwoBlock={blocks.length === 2 || blocks.length === 4}
                >
                  <ControlsWithModalOpener
                    actions={Actions.blockActions(idx, blocks.length)}
                    style={{ width: '100%' }}
                  >
                    <S.BlockItem key={idx} isEven={idx % 2 === 1}>
                      <EditableContent
                        text={block.title}
                        as={S.BlockTitle}
                        isEditing={isEditing}
                        alignment={block.blockTitleAlignment}
                        required={validation.blocks.title.required}
                        maxCount={validation.blockTitleMaxChar}
                        className="WM_GLOBAL_heading20"
                        onChange={(newTitle: string) => {
                          Actions.changeBlockTitle(idx, newTitle)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        text={block.paragraph}
                        as={S.BlockParagraph}
                        alignment={block.blockParagraphAlignment}
                        maxCount={validation.blockParagraphMaxChar}
                        className="WM_GLOBAL_paragraph14"
                        onChange={(newParagraph: string) => {
                          Actions.changeBlockParagraph(idx, newParagraph)
                        }}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }
                      />
                      <AdditionalLink
                        idx={idx}
                        additionalLinkText={block.additionalText}
                        additionalLinkUrl={block.additionalUrl}
                      />
                    </S.BlockItem>
                  </ControlsWithModalOpener>
                </S.ControlWrapper>
              )
            }}
          />
        </S.BlocksContainer>
        <AddButton
          onAdd={() => dispatcher(Actions.addBlock())}
          toShow={blocks.length < validation.blocks.max}
          style={{ margin: '40px 0' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header27
