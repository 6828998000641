/* @flow */

const isIframe = (url: string) => {
  return url.startsWith('<iframe')
}

export const isPlaylist = (url: string) => {
  if (url.includes('?in=')) {
    return false
  }
  //soundcloud single regexp
  const playlistRegExp = new RegExp(/^https?:\/\/soundcloud\.com\/\S+\/\S+$/i)
  if (!isIframe(url) && !playlistRegExp.test(url)) {
    return true
  }
  return (url && url.includes('/playlist')) || url.includes('/sets')
}

const validateSoundCloudUrl = (url: string) => {
  const exp = new RegExp(/(snd\.sc|soundcloud\.com)/)
  return exp.test(url)
}

const getUrlFromIframe = (iframe: string) => {
  const elem = document.createElement('div')
  elem.innerHTML = iframe
  return elem.childNodes[0].src
}

export const checkUrlLinkValidation = (url: string) => {
  if (isIframe(url)) {
    const musicUrl = getUrlFromIframe(url)
    return validateSoundCloudUrl(musicUrl)
  } else {
    return validateSoundCloudUrl(url)
  }
}

const isFullHeightUrl = url => url.includes('&visual=true')

export const getFullHeightUrl = (url: string) => {
  let musicUrl = url
  if (isIframe(url)) {
    musicUrl = getUrlFromIframe(url)
  }
  if (isFullHeightUrl(musicUrl)) {
    return url
  }
  const urlWithVisualProp = `${musicUrl}&visual=true`
  return isIframe(url)
    ? url.replace('amp;', '').replace(musicUrl, urlWithVisualProp)
    : urlWithVisualProp
}
