/* @flow */
import React, { useContext, memo } from 'react'
import S from './Post1.styled'
import { validation } from './Post1.consts'
import Swipe from '../Swipe'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { translate } from '@editor/common/utils/translations'

const Post1 = memo(props => {
  const {
    data: { slides, title, paragraph, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const renderDots = (isActive: boolean) => <S.Dot isActive={isActive} />
  const renderLeftIcon = () => (
    <S.IconContainer className="wm-slider-btn">
      <Icon
        className="left"
        color="white"
        size="middle"
        name="arrow_small_left"
      />
    </S.IconContainer>
  )
  const renderRightIcon = () => (
    <S.IconContainer className="wm-slider-btn right">
      <Icon
        className="right"
        color="white"
        size="middle"
        name="arrow_small_right"
      />
    </S.IconContainer>
  )
  const renderContent = (idx: number, stopAutoPlay: Function) => {
    return (
      <ControlsWithModalOpener
        toPropagate={false}
        actions={Actions.blockActions({
          idx,
          showRemove: slides.length > validation.slides.min
        })}
        style={{ width: '100%' }}
        alignment="right"
      >
        <S.SlideContainer>
          <S.ImageFigure>
            <S.ControlContainer>
              <ControlsWithImageCropper
                toPropagate={false}
                actions={Actions.getSliderActions({
                  idx,
                  onEditClick: stopAutoPlay,
                  imageUrl: slides[idx].imageUrl
                })}
                style={{ width: '100%', height: '100%' }}
              >
                <Image
                  as={S.Img}
                  alt="Slider image"
                  sizes={slides[idx].imgDimensions}
                  defaultImgSrc={slides[idx].imageUrl}
                  asProps={{
                    imgCropParams: slides[idx].imgCropParams
                  }}
                />
              </ControlsWithImageCropper>
            </S.ControlContainer>
          </S.ImageFigure>

          <S.TextContainer>
            <EditableContent
              as={S.SliderTitle}
              text={slides[idx].slideTitle}
              alignment={slides[idx].titleAlignment}
              maxCount={validation.maxSlideTitleChar}
              className="WM_GLOBAL_primary-font"
              onChange={(newTitle: string) => {
                Actions.changeSlideTitle(newTitle, idx)
              }}
              changeAlignment={(alignment: string) =>
                Actions.changeBlockTitleAlignment(alignment, idx)
              }
            />
            <EditableContent
              as={S.SliderParagraph}
              text={slides[idx].slideContent}
              alignment={slides[idx].contentAlignment}
              maxCount={validation.maxSlideContentChar}
              className="WM_GLOBAL_secondary-font"
              onChange={(newContent: string) => {
                Actions.changeSlideContent(newContent, idx)
              }}
              changeAlignment={(alignment: string) =>
                Actions.changeBlockContentAlignment(alignment, idx)
              }
            />
            <AdditionalLink
              idx={idx}
              additionalLinkText={slides[idx].buttonText}
              additionalLinkUrl={slides[idx].buttonUrl}
            />
          </S.TextContainer>
        </S.SlideContainer>
      </ControlsWithModalOpener>
    )
  }
  return (
    <S.StyledContainer isEditing={isEditing} length={slides.length}>
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.maxTitleChar}
          className="WM_GLOBAL_heading"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Paragraph}
          maxCount={validation.maxParagraphChar}
          className="WM_GLOBAL_paragraph"
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.ImageSlideContainer>
          <Swipe
            dataArray={slides}
            renderDots={renderDots}
            renderContent={renderContent}
            renderLeftIcon={renderLeftIcon}
            renderRightIcon={renderRightIcon}
          />
        </S.ImageSlideContainer>
        <AddButton
          style={{ marginTop: '2rem' }}
          onAdd={Actions.addSlide}
          toShow={slides.length < validation.slides.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Post1
