import styled, { css } from 'styled-components'

export const noSelect = css`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
`

export const Spinner = styled.div`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  box-sizing: border-box;
  border-top-color: rgba(255, 255, 255, 0.4);
  animation: spin 0.8s ease-in-out infinite;
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`
