import styled, { css } from 'styled-components'
import { scrollBarStyles } from '@editor/common/styles'

export const ContentWrapper = styled.div`
  overflow-y: scroll;
  padding: 10px 25px 10px 30px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 305px;
  box-sizing: border-box;

  ${({ showShadow }) =>
    showShadow
      ? css`
          box-shadow: inset 0 4px 8px #0052e01a;
        `
      : ''}

  @media only screen and (max-width: 768px) {
    padding: 10px 15px 10px 20px;
  }

  ${scrollBarStyles}
`
export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const IconItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  background-color: white;
  margin-bottom: 10px;

  & > div {
    width: 30px;
    height: 30px;
  }

  svg {
    fill: #545f7e;
    max-width: 30px;
    max-height: 30px;
  }

  &:not(:nth-child(5n)) {
    margin-right: 10px;
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
          border: 2px solid #3271e6;
          fill: #252e48;
        `
      : css`
          border: 1px solid #c1c9e0;
          @media only screen and (min-width: 769px) {
            &:hover {
              svg {
                fill: #252e48;
              }
              box-shadow: 0 4px 8px #3271e633;
              border: 1px solid #545f7e;
            }
          }
        `};

  @media only screen and (max-width: 768px) {
    width: calc((100vw - 80px) / 5);
    height: calc((100vw - 80px) / 5);
  }
`

export const NoIconContainer = styled.div`
  height: 305px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding-top: 30px;
  box-sizing: border-box;

  & > img {
    width: 117px;
    height: 127px;
  }

  & > p {
    text-align: center;
    color: #252e48;
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
  }

  @media only screen and (max-width: 768px) {
    padding-top: 0;
    justify-content: center;
  }
`
