import React from 'react'
import { withTheme } from 'styled-components'

const MorphSm = ({ theme }) => {
  const { palette } = theme
  const color = palette.tertiarySemiLight
  return (
    <svg
      width="495px"
      height="422px"
      viewBox="0 0 495 422"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
        opacity="0.1"
      >
        <path
          d="M480.278538,174.958299 C465.95929,151.511322 445.067271,132.446958 422.766801,115.5739 C402.344266,100.015625 383.330182,93.222576 370.184642,69.9947299 C361.264454,54.4364556 354.691684,37.344267 343.189336,23.3199071 C318.776191,-6.04359651 276.053186,-2.53750653 241.546144,6.22771841 C149.762106,29.4555645 69.0109327,89.0590942 23.4710266,167.069596 C11.7339374,187.229614 2.10952429,208.923545 0.231590018,231.932261 C-1.41160247,254.940976 5.63065105,279.045345 23.2362849,295.04188 C45.3020126,314.982767 78.4006041,318.707988 104.926426,333.170609 C131.686989,347.852361 150.23159,372.394991 173.001543,392.335877 C195.771496,412.057633 227.696379,427.835038 257.039102,418.193291 C281.686989,410.085458 296.006238,386.857612 314.550839,369.765423 C343.893562,342.593226 384.738632,329.88365 421.123609,311.695808 C457.508585,293.288835 492.954595,263.706201 494.832529,225.139211 C495.771496,207.389631 489.433468,190.078312 480.278538,174.958299 Z"
          id="Path-Copy-2"
          fill={color}
          fillRule="nonzero"
        />
      </g>
    </svg>
  )
}

export default withTheme(MorphSm)
