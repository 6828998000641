/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_SUBTITLE':
        draft.subTitle = action.value
        break
      case 'CHANGE_PARAGRAPH1':
        draft.paragraph1 = action.value
        break
      case 'CHANGE_PARAGRAPH2':
        draft.paragraph2 = action.value
        break
      case 'CHANGE_BOTTOM_BLOCK_TITLE':
        draft.bottomBlockTitle = action.value
        break
      case 'CHANGE_BOTTOM_BLOCK_PARAGRAPH':
        draft.bottomBlockParagraph = action.value
        break
      case 'CHANGE_IMAGE':
        {
          const { url, dimensions } = action.value
          draft.largeImgUrl = url
          draft.largeImgDimensions = dimensions
          draft.largeImgCropParams = {}
        }
        break
      case 'CHANGE_SMALL_IMAGE':
        {
          const { url, dimensions, sizes } = action.value
          draft.smallImg.imgUrl = url
          draft.smallImg.imgDimensions = dimensions
          draft.smallImg.imgSizes = sizes
        }
        break
      case 'REMOVE_SMALL_IMAGE':
        draft.smallImg = {}
        break
      case 'ADD_SMALL_IMAGE':
        draft.smallImg.imgUrl = initialState.smallImg.imgUrl
        draft.smallImg.imgDimensions = initialState.smallImg.imgDimensions
        draft.smallImg.imgSizes = initialState.smallImg.imgSizes
        break
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams } = action.value
          draft.largeImgCropParams = imgCropParams
        }
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions: _dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = _dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_SUB_TITLE_ALIGNMENT': {
        draft.subTitleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH1_ALIGNMENT': {
        draft.paragraph1Alignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH2_ALIGNMENT': {
        draft.paragraph2Alignment = action.value
        break
      }
      case 'CHANGE_BOTTOM_BLOCK_TITLE_ALIGNMENT': {
        draft.bottomBlockTitleAlignment = action.value
        break
      }
      case 'CHANGE_BOTTOM_BLOCK_PARAGRAPH_ALIGNMENT': {
        draft.bottomBlockParagraphAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_SLOGAN_ALIGNMENT': {
        draft.sloganAlignment = action.value
        break
      }
      case 'CHANGE_NAME_ALIGNMENT': {
        draft.nameAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH2_ALIGNMENT': {
        draft.paragraph2Alignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH3_ALIGNMENT': {
        draft.paragraph3Alignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH4_ALIGNMENT': {
        draft.paragraph4Alignment = action.value
        break
      }
    }
  })
}
