/* @flow */
import styled, { css } from 'styled-components'
import THEME from './FAQ3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 679px) {
        padding-top: 150px;
      }
    `}
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  padding-top: 20px;
  width: 100%;
`

const Hgroup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px auto;
  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
`

const FaqsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  margin: -20px auto;
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: -10px;
  }
  @media only screen and (max-width: 667px) {
    margin-bottom: -20px;
  }
  & > div {
    width: 43%;
    max-width: 500px;
    @media only screen and (max-width: 1023px) {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const FaqItemHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: ${getStyleForProp('heading')};
  font-weight: 600;
  @media only screen and (max-width: 450px) {
    flex-direction: column;
    padding: 20px 0;
  }
`

const Question = styled.p`
  width: 100%;
  color: ${getStyleForProp('blockText')};
  opacity: ${({ showAnswer }) => (showAnswer ? '0' : '1')};
  visibility: ${({ showAnswer }) => (showAnswer ? 'hidden' : 'visible')};
  transition: all 0.1s linear;
`

const FaqContent = styled.p`
  display: ${({ isCardTextOpen }) =>
    isCardTextOpen ? 'block' : '-webkit-box'};
  overflow: ${({ isCardTextOpen }) => (isCardTextOpen ? 'initial' : 'hidden')};
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: ${getStyleForProp('blockText')};
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (max-width: 450px) {
    padding: 0;
  }
`

const Button = styled.button`
  width: 150px;
  height: 15px;
  display: ${({ isThreeLine, isEditing }) =>
    isThreeLine && !isEditing ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin: 20px 0 0 0;
  color: ${({ theme }) => theme.palette.primaryDark};
  position: relative;
  &:hover i {
    opacity: 0.6;
  }
  i {
    transition: all 0.3s linear;
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0) ')};
  }
`

const Circle = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getStyleForProp('buttonBackground')};
  border-radius: ${getStyleForProp('iconRadius')};
  flex: none;
  @media only screen and (max-width: 450px) {
    margin: 0 0 20px 0;
  }
`
const Icon = styled(LibIcon).attrs(() => ({
  color: '#387dff'
}))``

const CardWrapper = styled.div`
  width: 96%;
  max-width: 500px;
  margin: 20px 10px;
  @media only screen and (max-width: 1023px) {
    margin: 10px;
  }
  @media only screen and (max-width: 650px) {
    margin: 10px 0;
    width: 100%;
  }
`

const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  background: white;
  margin: 0 auto 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('cardRadius')};
  cursor: pointer;
`

const FaqCard = styled.div`
  cursor: ${({ isEditing }) => (isEditing ? 'initial' : 'pointer')};
  perspective: 800px;
  @media only screen and (max-width: 768px) {
    perspective: 400px;
  }
`

const Card = styled.div`
  width: 100%;
  min-height: 185px;
  text-align: center;
  box-sizing: border-box;
  word-break: break-word;
  transform: ${({ showAnswer }) =>
    showAnswer ? 'rotateY(180deg)' : 'rotateY(0deg)'};
  transition: transform 1s;
  position: relative;
  @media only screen and (max-width: 667px) {
    margin: 10px 0;
  }
  .card-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 14px 25px;
    box-sizing: border-box;
    background: white;
    box-shadow: ${getStyleForProp('raisedShadow')};
    border-radius: ${getStyleForProp('cardRadius')};
    min-height: 185px;
  }
`

const CardFront = styled.div`
  position: absolute;
  height: 100%;
  z-index: ${({ showAnswer }) => (showAnswer ? '0' : '1')};
  transition: all 0.6s linear;
`

const CardBack = styled.div`
  transform: rotateY(180deg);
`

export default {
  StyledContainer,
  Title,
  Paragraph,
  Hgroup,
  FaqsWrapper,
  FaqItemHeader,
  Card,
  FaqContent,
  Question,
  Circle,
  FaqCard,
  CardFront,
  CardBack,
  IconWrapper,
  CardWrapper,
  Button,
  Icon
}
