import styled from 'styled-components'
import { primary } from '@website/common/styles/colors'
import { inputStyles } from '@containers/ai-website-builder/styled'

export const Container = styled.div`
  position: relative;
`

export const SearchInputWrapper = styled.div`
  position: relative;

  .search-icon {
    position: absolute;
    left: 20px;
    top: 23px;
  }
`

export const SearchInput = styled.input`
  ${inputStyles};
  padding: 10px 15px 10px 50px;
`

export const SuggestionsList = styled.div`
  position: absolute;
  left: 0;
  top: 64px;
  width: 546px;
  max-height: 256px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px 0 #2b003f12;
  margin-top: 10px;
  padding: 8px;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

export const SuggestionItem = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;

  & > span {
    color: #1e2539;
    font-size: 14px;
    margin-left: 10px;
  }

  &:hover {
    background-color: ${primary.Light};
  }
`
