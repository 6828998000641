import React from 'react'
import { withTheme } from 'styled-components'

const RhombusSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="126"
      height="121"
      viewBox="0 0 126 121"
    >
      <path
        id="Polygon_8"
        data-name="Polygon 8"
        d="M82.468,0,80.961,35.538l33.007-12.429L92.062,50.965,126,60.5,92.062,70.035l21.906,27.856L80.961,85.462,82.468,121,63,91.355,43.532,121l1.507-35.538L12.032,97.891,33.938,70.035,0,60.5l33.938-9.535L12.032,23.109,45.039,35.538,43.532,0,63,29.645Z"
      />
    </svg>
  )
}

export default withTheme(RhombusSVG)
