import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    subTitle: p => p.primaryLight,
    paragraph: p => p.accentWhite,
    icons: p => p.accentWhite,
    socialColor: p => p.accentWhite,
    socialBorder: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.8)`
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    subTitle: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    icons: p => p.tertiaryDark,
    socialColor: p => p.tertiaryDark,
    socialBorder: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryLight,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.8)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    subTitle: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    icons: p => p.tertiaryDark,
    socialColor: p => p.tertiaryDark,
    socialBorder: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.9)`
  }
}
