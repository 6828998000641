/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Pricing3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  .rf-badge-svg {
    position: absolute;
    top: -3px;
    right: -3px;
  }
`

const Heading = styled.h1`
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  text-align: center;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const Title = styled.h2`
  text-align: center;
  margin: 20px 0 12px 0;
  font-weight: bold;
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  text-align: center;
  width: 100%;
`

const PricingCardsContainer = styled.div`
  margin: 0 0 -20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  @media only screen and (max-width: 1068px) {
    margin-bottom: -40px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: -20px;
  }
  @media only screen and (max-width: 600px) {
    margin-bottom: 0px;
  }
`

const PriceCardWrapper = styled.article`
  display: flex;
  @media only screen and (min-width: 769px) {
    padding: ${props => (!props.isPrimary ? '20px 0 20px 0' : 'initial')};
  }
  .control-container:not(:first-child) {
    width: 100%;
  }
  .control-container:nth-child(2) {
    margin-top: 20px;
    margin-bottom: 12px;
    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const PricingHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 50px auto;
  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .control-container {
    width: 100%;
  }
  > .control-container:first-child {
    h1 {
      margin-bottom: 0;
    }
  }
  > .control-container:nth-child(2) {
    margin-top: 20px;
    p {
      padding-top: 0;
    }
  }
`

const PriceCard = styled.div`
  height: ${props => (props.isEditing ? 'initial' : 'auto')};
  flex: 1;
  background-color: white;
  box-sizing: border-box;
  min-width: 280px;
  box-shadow: ${props =>
    getStyleForProp(props.isPrimary ? 'raisedShadow' : 'shadow')};
  padding: 40px 20px;
  border-radius: ${getStyleForProp('borderRadius')};
  border: ${getStyleForProp('border')};
  display: flex;
  flex-direction: column;
  position: relative;
  width: 280px;
  max-width: 280px;
  margin: 20px;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    margin: 2rem 0 0 0;
  }
`

const PricingCardcontent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0 4px;

  & > .control-container {
    padding: 16px 0;

    & > .control-container {
      p {
        padding: 0;
      }
    }
  }
`

const PricingCardHeader = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & > .control-container:nth-child(2) {
    margin: 20px 0 12px 0;

    & > p {
      margin: 0;
    }
  }

  & > .control-container:nth-child(4) {
    margin: 16px 0 0 0;

    & > p {
      margin: 0;
    }
  }
`

const ContentBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media only screen and (min-width: 769px) {
    padding: ${props => (props.isPrimary ? '20px 0 20px 0' : 'initial')};
  }
`

const ButtonWrapper = styled.div`
  margin-top: auto;
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    ${props =>
      getStyleForProp(
        props.isPrimary ? 'buttonBackground' : 'buttonBackgroundSecondary'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    max-width: 260px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`

const Icon = styled(LibIcon).attrs(({ isPrimary, theme }) => ({
  color: isPrimary ? theme.palette.primaryDark : theme.palette.secondaryDark
}))``

const PerDate = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin: 16px 0 0 0;
  text-align: center;
  width: 100%;
`

const Price = styled.p`
  font-size: 54px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-weight: bold;
  text-align: center;
  width: 100%;
`

const Option = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  text-align: center;
  padding: 16px 0;
  margin-top: 0;
`

export default {
  Title,
  Heading,
  StyledContainer,
  WmCustomContainer,
  Paragraph,
  PerDate,
  PricingCardsContainer,
  PricingHeaderContainer,
  PriceCardWrapper,
  PricingCardcontent,
  PriceCard,
  PricingCardHeader,
  ContentBody,
  ButtonWrapper,
  Price,
  Option,
  Icon
}
