import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    anchor: p => p.primaryLight,
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    blockParagraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`
  },
  LIGHT: {
    anchor: p => p.primaryDark,
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    blockParagraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`
  },
  EXTRALIGHT: {
    anchor: p => p.primaryDark,
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    blockParagraph: p => p.tertiaryNormal,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`
  }
}
