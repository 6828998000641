import styled, { createGlobalStyle } from 'styled-components'
import { mediaMaxWidth } from '@editor/common/utils/styled'
import { SEMRUSH_SIDEBAR_WIDTH } from '@editor/common/components/SemrushSidebar'

export const GlobalStyles = createGlobalStyle`
    @keyframes slide-left {
        from {transform: translateX(100%) }
        to {transform: translateX(0)}
    }
    
    @keyframes slide-right {
        from {transform: translateX(0)}
        to {transform: translateX(100%)}
    }
`

export const SlideLeftContainer = styled.div`
  position: fixed;
  z-index: 49;
  bottom: 0;
  left: 0;
  right: ${({ theme }) =>
    theme.isSemrushSidebarVisible ? `${SEMRUSH_SIDEBAR_WIDTH}px` : '0'};
  background-color: rgba(37, 46, 72, 0.2);
  ${mediaMaxWidth(1024)`
    top: 69px;
  `}
  ${mediaMaxWidth(800)`
    top: 60px;
  `}
`

export const SlideLeft = styled.div`
  box-shadow: 0px -6px 12px #387dff1a;
  position: absolute;
  right: 0;
  bottom: 0;
  width: 250px;
  height: 100%;
  animation-name: ${({ isOpen }) => (isOpen ? 'slide-left' : 'slide-right')};
  animation-duration: 0.3s;
  transform: translateX(0);
`
