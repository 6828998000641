/* @flow */
import React, { useContext, memo } from 'react'
import S from './Portfolio4.styled'
import { validation } from './Portfolio4.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const Portfolio4 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      imageUrl,
      imgCropParams,
      imgDimensions,
      titleAlignment,
      paragraphAlignment
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <WMContainer>
        <Show when={[title, paragraph]}>
          <S.TextContent>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.titleMaxChar}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.TextContent>
        </Show>
      </WMContainer>
      <S.PortfolioImageFigure>
        <S.ControlContainer>
          <ControlsWithImageCropper
            actions={Actions.imageActions(imageUrl)}
            style={{ width: '100%' }}
          >
            <Image
              as={S.Img}
              sizes={imgDimensions}
              alt="Section image"
              asProps={{ imgCropParams }}
              defaultImgSrc={imageUrl}
            />
          </ControlsWithImageCropper>
        </S.ControlContainer>
      </S.PortfolioImageFigure>
    </S.StyledContainer>
  )
})

export default Portfolio4
