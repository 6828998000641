export const validation = {
  headingMaxChar: 50,
  paragraphMaxChar: 500,
  blockTitleMaxChar: 30,
  title: { required: true },
  blockTitle: { required: true },
  headerSocials: {
    min: 0,
    max: 7
  }
}
