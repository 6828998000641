/* @flow */
import styled, { css } from 'styled-components'
import Icon from '@website/common/components/Icon'
import THEME from './Contact10.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      padding-top: 150px;
    `}
`

const WmCustomContainer = styled(WMContainer)`
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    padding-right: 0;
  }
`

const FormImageWrapper = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: ${getStyleForProp('raisedShadow')};
  background: ${({ theme }) => theme.palette.accentWhite};
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    max-width: 800px;
  }
  @media only screen and (max-width: 767px) {
    box-shadow: initial;
  }
`

const RightContent = styled.div`
  width: 35%;
  flex-grow: 1;
  max-width: 800px;
  padding: 25px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    align-items: center;
  }
  @media only screen and (max-width: 767px) {
    padding: 25px 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  > .control-container {
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
  }
  > .control-container:first-child {
    margin-bottom: 10px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const InputWrapper = styled.div`
  position: relative;
  .required-icon {
    position: absolute;
    right: 15px;
    bottom: 18px;
    color: ${({ theme }) => theme.palette.tertiaryDark};
  }
`

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 30px 0 12px;
  height: 50px;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('buttonRadius')};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  color: ${({ theme }) => theme.palette.tertiaryDark};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 12px;
  margin: 0 0 20px 0;
  height: 100px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('inputRadius')};
  border: ${({ theme }) =>
    `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
  }
`

const Label = styled.label`
  display: block;
  margin: 0 0 4px 0;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  font-weight: 400;
  align-self: flex-start;
  position: relative;
  z-index: 1;
  &:after {
    left: 0;
    right: initial;
  }
`

const FormContainer = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  @media only screen and (max-width: 1200px) {
    align-items: center;
    margin: 20px 0 0 0;
  }
`

const Button = styled.div`
  border-radius: ${getStyleForProp('buttonRadius')};
  white-space: nowrap;
  ${getStyleForProp('buttonBackground')};
  background-repeat: no-repeat;
  text-decoration: none;
  max-width: 300px;
  padding: 0 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-align: center;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 400;
  background-repeat: no-repeat !important;
  font-size: 14px;
  display: block;
  &:focus {
    outline: 1px solid ${({ theme }) => theme.palette.secondaryDark};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1200px) {
    transform: translateX(24px);
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 20px 0 0 0;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 98%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 98%;
    height: initial;
  }
`

const ImageWrapper = styled.div`
  width: 50%;
  flex-grow: 1;
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const RequiredIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.palette.tertiaryDark
}))`
  line-height: 0;
  max-width: 12px;
  max-height: 12px;
`

const ErrorMessage = styled.div`
  position: absolute;
  top: 52px;
  color: #eb0c00;
  font-size: 14px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 5px;
  }
`

export const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

export default {
  WmCustomContainer,
  RightContent,
  Title,
  Input,
  Button,
  TextArea,
  StyledContainer,
  Paragraph,
  InputContainer,
  FormContainer,
  ButtonContainer,
  FormImageWrapper,
  ControlContainer,
  ImageWrapper,
  Img,
  Label,
  InputWrapper,
  RequiredIcon,
  ErrorMessage,
  ErrorIcon
}
