import styled, { css } from 'styled-components'

const TEXT_COLOR = '#252E48'

const getLoadingMessageStyles = ({ minOfSizes }) =>
  minOfSizes < 110
    ? css`
        display: none;
      `
    : minOfSizes < 400
    ? css`
        font-size: 14px;
        margin-top: 10px;
      `
    : css`
        font-size: 16px;
        margin-top: 20px;
      `

const getErrorMainMessageStyles = ({ minOfSizes }) =>
  minOfSizes < 100
    ? css`
        display: none;
      `
    : minOfSizes < 400
    ? css`
        font-size: 14px;
        margin-top: 10px;
      `
    : css`
        font-size: 20px;
        font-weight: 500;
        margin-top: 20px;
      `

const getErrorSecondaryMessageStyles = ({ minOfSizes }) =>
  minOfSizes < 400
    ? css`
        display: none;
      `
    : ''

const getStatusIconWidth = ({ minOfSizes }) =>
  minOfSizes < 50
    ? 20
    : minOfSizes < 100
    ? 30
    : minOfSizes < 400
    ? 40
    : minOfSizes < 600
    ? 60
    : 80

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const LoadingMessage = styled.p`
  color: ${TEXT_COLOR};
  font-size: 15px;
`

export const ErrorMainMessage = styled.p`
  color: ${TEXT_COLOR};
`

export const ErrorSecondaryMessage = styled.p`
  color: ${TEXT_COLOR};
  font-size: 16px;
  margin-top: 10px;
`

export const StateOverlay = styled.div`
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  display: flex;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  opacity: ${({ minOfSizes }) => (!minOfSizes || minOfSizes < 10 ? 0 : 1)};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.6);
  box-sizing: border-box;

  ${({ failed }) =>
    failed
      ? css`
          border: 2px solid red;
        `
      : ''};
  .circle-loader {
    animation: spinner 850ms linear infinite;
    width: ${getStatusIconWidth}px;
    height: auto;
  }
  .attention-icon {
    width: ${getStatusIconWidth}px;
    height: auto;
  }
  ${LoadingMessage} {
    ${getLoadingMessageStyles};
  }
  ${ErrorMainMessage} {
    ${getErrorMainMessageStyles};
  }
  ${ErrorSecondaryMessage} {
    ${getErrorSecondaryMessageStyles};
  }
`
