/* @flow */
import React from 'react'
import Swipe from '../Swipe'
import S from './Slider2.styled'
import { validation } from './Slider2.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import AddButton from '@website/common/components/AddButton'
import { withDispatchContext, withEditingContext, withSiteId } from '@contexts'
import {
  ControlsWithModalOpener,
  Controls
} from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const TextItemWrapWithEditingContext = withEditingContext(S.TextItemWrap)
const ContainerWithEditingContext = withEditingContext(S.StyledContainer)

const isAllTextsEmpty = textItems => {
  if (textItems.length === 0) {
    return true
  }
  return !textItems.find(item => item.title || item.paragraph)
}
class Slider2 extends React.PureComponent<void, void> {
  renderDots = (isActive: boolean) => <S.Dot isActive={isActive} />
  renderContent = Actions => (idx: number, stopAutoPlay: Function) => {
    const {
      data: { slides }
    } = this.props
    return (
      <S.SliderContainer>
        <S.FigureContainer className="figure-container">
          <S.ControlContainer>
            <ControlsWithImageCropper
              toPropagate={false}
              style={{ width: '100%' }}
              actions={Actions.getSliderActions({
                additionalArgs: { idx },
                keyForSizes: 'slider2',
                showRemove: slides.length > validation.slides.min,
                clickHandlers: {
                  change: stopAutoPlay,
                  crop: stopAutoPlay
                },
                imageUrl: slides[idx].imgUrl
              })}
            >
              <Image
                as={S.Img}
                alt="Slider image"
                asProps={{ imgCropParams: slides[idx].imgCropParams }}
                defaultImgSrc={slides[idx].imgUrl}
                sizes={slides[idx].imgDimensions}
              />
            </ControlsWithImageCropper>
          </S.ControlContainer>
        </S.FigureContainer>
      </S.SliderContainer>
    )
  }
  renderLeftIcon = () => (
    <S.IconContainer className="left">
      <Icon color="white" size="large" name="keyboard_arrow_left" />
    </S.IconContainer>
  )
  renderRightIcon = () => (
    <S.IconContainer className="right">
      <Icon color="white" size="large" name="keyboard_arrow_right" />
    </S.IconContainer>
  )
  render() {
    const {
      data: {
        slides,
        textItems,
        title,
        paragraph,
        buttons,
        titleAlignment,
        paragraphAlignment
      },
      componentDispatcher
    } = this.props
    const Actions = ActionGetter(componentDispatcher)
    return (
      <ContainerWithEditingContext isSingleSlide={slides.length === 1}>
        <S.WmCustomContainer>
          <Show when={[title, paragraph, ...buttons]}>
            <S.HeadingsWrap>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.maxTitleChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.maxParagraphChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <Buttons data={buttons} />
            </S.HeadingsWrap>
          </Show>
          <S.TextSliderContainer className="text-slider-container">
            <Show when={[!isAllTextsEmpty(textItems)]}>
              <S.TextItemsWrapper isBlocksExist={textItems.length !== 0}>
                {textItems.map((item: string, idx: number) => (
                  <Show when={[item.title, item.paragraph]} key={idx}>
                    <TextItemWrapWithEditingContext>
                      <Controls
                        actions={Actions.getBlockActions(idx)}
                        alignment="right"
                      >
                        <S.TextItem>
                          <S.CircleWrap>
                            <ControlsWithModalOpener
                              actions={Actions.getIconActions({
                                idx,
                                showRemove: false
                              })}
                            >
                              <S.Circle>
                                <Icon
                                  color="white"
                                  size="large"
                                  name={item.icon}
                                />
                              </S.Circle>
                            </ControlsWithModalOpener>
                          </S.CircleWrap>
                          <S.TextContent>
                            <S.TextTitleWrap>
                              <EditableContent
                                text={item.title}
                                as={S.TextTitle}
                                maxCount={validation.maxSlideTitleChar}
                                onChange={(newItemTitle: string) =>
                                  Actions.changeItemTitle(newItemTitle, idx)
                                }
                              />
                            </S.TextTitleWrap>
                            <EditableContent
                              as={S.TextParagraph}
                              text={item.paragraph}
                              // alignment={item.paragraphAlignment}
                              maxCount={validation.maxSlideParagraphChar}
                              className="WM_GLOBAL_secondary-font"
                              onChange={(newItemParagraph: string) =>
                                Actions.changeItemParagraph(
                                  newItemParagraph,
                                  idx
                                )
                              }
                              // changeAlignment={(alignment: string) =>
                              //   Actions.changeBlockParagraphAlignment(
                              //     alignment,
                              //     idx
                              //   )
                              // }
                            />
                          </S.TextContent>
                        </S.TextItem>
                      </Controls>
                    </TextItemWrapWithEditingContext>
                  </Show>
                ))}
                <AddButton
                  onAdd={Actions.addBlock}
                  toShow={textItems.length < validation.textItems.max}
                  style={{ margin: '20px auto auto auto' }}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_block_label')}
                </AddButton>
              </S.TextItemsWrapper>
            </Show>
            <S.SliderMainContainer className="slider-main-container">
              <div className="image-slide-container">
                <Swipe
                  dataArray={slides}
                  renderDots={this.renderDots}
                  renderContent={this.renderContent(Actions)}
                  renderLeftIcon={this.renderLeftIcon}
                  renderRightIcon={this.renderRightIcon}
                />
              </div>
            </S.SliderMainContainer>
          </S.TextSliderContainer>
        </S.WmCustomContainer>
        <AddButton
          onAdd={Actions.addSlide}
          toShow={slides.length < validation.slides.max}
          style={{ marginTop: '20px' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </ContainerWithEditingContext>
      // </S.StyledContainer>
    )
  }
}
const Slider2WithContexts = withDispatchContext(withSiteId(Slider2))

export default Slider2WithContexts
