import styled, { css } from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Footer = styled.div`
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 20;
`
