/* @flow */

import React, { useState, useCallback, useMemo, memo } from 'react'

import * as Styled from './styled'
import { Content, Label, ButtonsWrapper } from '../styled'
import {
  PrimaryButton,
  SecondaryButton
} from '@editor/common/styled-components/buttons'
import {
  MODAL_TYPES,
  LINK_TYPES,
  EMPTY_INPUT_MESSAGE,
  INVALID_VIDEO_URL_MESSAGE,
  INVALID_IMAGE_URL_MESSAGE
} from '../consts'
import Popup from '@editor/common/lib/Popup'
import Input from '@website/common/components/Input'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import { secondary } from '@website/common/styles/colors'
import { RippleLoading } from '@editor/common/components/Loaders'
import { isInternalImage, validateUrl, getTitle } from './utils'
import { isValidUrl, onEnterPress } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'

const UrlModal = ({ type, link, handler, setModalState, notification }) => {
  const [url, setUrl] = useState(link)
  const [validationMessage, setValidationMessage] = useState('')
  const [isLoading, setLoadingState] = useState(false)

  const onChange = useCallback(e => {
    setUrl(e.target.value)
  }, [])

  const onFocus = useCallback(() => {
    if (validationMessage) setValidationMessage('')
  }, [validationMessage])

  const onRemoveIconClick = useCallback(() => {
    setUrl('')
  }, [])

  const closePopup = useCallback(() => {
    setModalState(MODAL_TYPES.urlModal, {
      isOpen: false,
      link: '',
      notification: ''
    })
  }, [setModalState])

  const onAdd = useCallback(() => {
    if (link === url) {
      closePopup()
      return
    }

    if (url === '') {
      setValidationMessage(EMPTY_INPUT_MESSAGE)
      return
    }

    if (!isValidUrl(url)) {
      type === LINK_TYPES.video
        ? setValidationMessage(INVALID_VIDEO_URL_MESSAGE)
        : setValidationMessage(INVALID_IMAGE_URL_MESSAGE)
      return
    }

    if (
      type === LINK_TYPES.video ||
      type === LINK_TYPES.image ||
      type === LINK_TYPES.logo
    ) {
      setLoadingState(true)
      validateUrl(url, type)
        .then(() => {
          setLoadingState(false)
          handler(url)
          closePopup()
        })
        .catch(message => {
          setLoadingState(false)
          setValidationMessage(message)
        })
      return
    }

    if (!validationMessage) {
      handler(url)
      closePopup()
    }
  }, [url, handler, closePopup, validationMessage])

  const inputValue = useMemo(
    () =>
      url && (type === LINK_TYPES.video || !isInternalImage(url)) ? url : '',
    [url, type]
  )

  return (
    <Popup isOpen onClose={closePopup} title={getTitle(type)}>
      <Content>
        <Label>{translate('paste_url_here_label')}</Label>
        <Input
          value={inputValue}
          placeholder="http(s)://example.com"
          placeholderColor={secondary.SemiLight}
          borderColor={secondary.Light}
          borderHoverColor={secondary.SemiLight}
          error={validationMessage}
          disabled={isLoading}
          onChange={onChange}
          onFocus={onFocus}
          onKeyPress={onEnterPress(onAdd)}
          onRemoveIconClick={onRemoveIconClick}
        />
        {notification ? (
          <Styled.Notification>
            <InfoIcon />
            <span>
              <span>{translate('note_label')},</span>
              {notification}
            </span>
          </Styled.Notification>
        ) : null}
        <ButtonsWrapper>
          <SecondaryButton onClick={closePopup}>
            {translate('cancel_label')}
          </SecondaryButton>
          <PrimaryButton onClick={onAdd}>
            {isLoading && <RippleLoading color="#ffffff" isSmall />}
            <Styled.PrimaryBtnText isShown={!isLoading}>
              {translate('add_label', true)}
            </Styled.PrimaryBtnText>
          </PrimaryButton>
        </ButtonsWrapper>
      </Content>
    </Popup>
  )
}

export default memo(UrlModal)
