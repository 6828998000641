export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    arrow: p => p.accentWhite,
    activeBlockTitle: p => p.tertiaryNormal,
    blockTitle: p => p.accentWhite,
    background: p => p.tertiaryDark,
    icon: p => p.primaryLight
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    arrow: p => p.tertiaryDark,
    activeBlockTitle: p => p.tertiaryNormal,
    blockTitle: p => p.tertiaryNormal,
    background: p => p.tertiaryLight,
    icon: p => p.primaryDark
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    arrow: p => p.tertiaryDark,
    activeBlockTitle: p => p.tertiaryNormal,
    blockTitle: p => p.tertiaryNormal,
    background: p => p.tertiaryExtraLight,
    icon: p => p.primaryDark
  }
}
