export const validation = {
  socials: {
    min: 0,
    max: 6
  },
  copyrightMaxChar: 100,
  footerLogo: 1
}

export const ANIMATION_SPEED = 50
