/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Feature33.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import { getFontSize } from '@shared/globals'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  .datePicker {
    width: 290px;
    @media only screen and (max-width: 768px) {
      top: 30%;
      ${({ theme }) =>
        theme.orientation === 'LEFT' &&
        css`
          top: initial;
          bottom: -10%;
        `}
    }
  }
  padding-top: ${props => (props.isEditing ? '140px' : null)};
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    justify-content: space-between;
    align-items: center;
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'RIGHT'
          ? 'column-reverse'
          : 'column'};
      `}
  }
  .DayPicker-Day {
    &:focus {
      outline: none !important;
    }
  }
  .DayPicker-Day--selected {
    color: white !important;
    background-color: rgb(56, 125, 255) !important;
    font-weight: 700;
  }
  .DayPicker-Day--today {
    background-color: rgba(56, 125, 255, 0.15) !important;
    font-weight: 500 !important;
    color: initial !important;
  }
  .clockerContainer {
    display: flex;
    justify-content: space-around;
  }
  .DayPicker {
    width: 100%;
  }
  .DayPicker-Day {
    min-width: 36px;
    box-sizing: border-box;
  }
`

const Content = styled.div`
  position: relative;
  max-width: 680px;
  width: 48.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  ${({ theme }) =>
    theme.orientation === 'RIGHT'
      ? css`
          @media only screen and (max-width: 1024px) {
            padding-top: 45px;
          }
          @media only screen and (max-width: 650px) {
            padding-top: 35px;
          }
        `
      : css`
          @media only screen and (max-width: 1024px) {
            padding-bottom: 45px;
          }
          @media only screen and (max-width: 650px) {
            padding-bottom: 35px;
          }
        `}

  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  > :last-child {
    margin-bottom: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
    }
  }
  & > .control-container:first-child {
    width: 100%;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
  }
`

const Paragraph = styled.p`
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  @media only screen and (max-width: 1024px) {
    margin-top: ${props => (props.isEditing ? '12px' : '0')};
  }
`

const DateContainer = styled.div`
  width: 48%;
  min-width: 650px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ isAnyDateLabelFilled, isEditing }) =>
    css`
      ${(isAnyDateLabelFilled || isEditing) &&
      css`
        .time-dots {
          transform: translateY(-15%);
        }
      `}
    `}
`

const DateTitle = styled.h4`
  color: ${getStyleForProp('heading')};
  text-align: center;
`

const DateWrap = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding: 0 6px;
  box-sizing: border-box;
  max-width: 130px;
  @media only screen and (max-width: 650px) {
    padding: 0 4px;
  }
  &:first-child {
    max-width: 210px;
  }

  & > .control-container {
    margin-top: 10px;

    & > p {
      margin-top: 0;
    }
  }

  &:last-child {
    .styled-controls {
      right: 0;
      left: auto;
    }
  }
`

const Time = styled.p`
  position: relative;
  color: ${getStyleForProp('time')};
  border-radius: 10px;
  min-width: 125px;
  text-align: center;
  margin-bottom: 0 !important;
  font-size: 72px;
  font-weight: bold;
  @media only screen and (max-width: 1024px) {
    font-size: 42px;
    min-width: 60px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 26px;
    min-width: 50px;
  }
  span {
    position: relative;
    z-index: 1;
  }
`

const Date = styled.p`
  width: 100%;
  color: ${getStyleForProp('date')};
  font-weight: 400 !important;
  text-transform: uppercase;
  text-align: center;
  margin: 10px 0 0 0;
  @media only screen and (max-width: 768px) {
    font-size: 0;
    &::first-letter {
      font-size: ${({ theme }) => getFontSize('paragraph18', theme.fontSize)}px;
    }
  }
`

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 650px;
  @media only screen and (max-width: 1024px) {
    max-width: 460px;
  }
  @media only screen and (max-width: 650px) {
    max-width: 320px;
  }
`

const TimeDotsWrap = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`

const TimeDots = styled.span`
  color: ${getStyleForProp('dots')};
  font-size: 40px;
  font-weight: bold;
  @media only screen and (max-width: 1280px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 650px) {
    font-size: 20px;
  }
`

export default {
  WmCustomContainer,
  DateContainer,
  DateWrapper,
  Content,
  StyledContainer,
  DateWrap,
  Time,
  Date,
  Paragraph,
  DateTitle,
  Title,
  TimeDotsWrap,
  TimeDots
}
