/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_ITEM_TEXT':
        const { text, idx, itemIdx } = action.value
        draft.features[idx][itemIdx].paragraph = text
        break
      case 'CHANGE_ITEM_COUNT':
        const { newCount, idx: _idx_, itemIdx: _itemIdx_ } = action.value
        draft.features[_idx_][_itemIdx_].count = newCount
        break
      case 'CHANGE_IMG': {
        const { url, idx, itemIdx, dimensions } = action.value
        draft.features[idx][itemIdx].img = url
        draft.features[idx][itemIdx].imgDimensions = dimensions || {}
        break
      }
      case 'CHANGE_LINK_DATA': {
        const { idx, linkData, itemIdx } = action.value
        draft.features[idx][itemIdx].additionalUrl = linkData.link
        draft.features[idx][itemIdx].additionalText = linkData.text
        break
      }
      case 'REMOVE_LINK': {
        const { idx, itemIdx } = action.value
        draft.features[idx][itemIdx].additionalUrl = ''
        draft.features[idx][itemIdx].additionalText = ''
        break
      }
      case 'ADD_LINK':
        const { idx: idx_, itemIdx: itemIdx_ } = action.value
        const { additionalUrl, additionalText } = initialState.features[0][1]
        draft.features[idx_][itemIdx_].additionalUrl = additionalUrl
        draft.features[idx_][itemIdx_].additionalText = additionalText
        break
    }
  })
}
