// @flow

import type { TShift } from './types'

export const FLYOUT_SHIFT: TShift = {
  x: 0,
  y: 10
}

export const ALIGNMENT_TYPES = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center'
}
