import { createEventAPI, createNewProjectAPI, patchProjectDataAPI } from '@api'
import { getLocalizedURL } from '@editor/common/utils/translations'
import { getProjectData, selectEvent, selectHasWebsiteEvent } from '@selectors'

import {
  // uploadFilesAsync,
  fetchMediaLibLimitsAction,
  // checkUserLimitAndShowWarning,
  setProjectSyncingStatus
} from '../editor/editor'
import { fetchUserData } from '../editor/user'
import { setEventsData } from '../events'
import { setEventIdToRsvpComponents } from '../project'
import { pushDataLayer } from '@editor/common/utils/analytics'

// import { isPlainObject, isBlobUrl } from '@editor/common/utils'
// import { IMAGE_MIME_TO_EXT } from '@editor/conf/consts'

// const _flatten2DimArray = arr =>
//   arr.reduce((acc, item) => {
//     acc.push(...item)
//     return acc
//   }, [])
//
// // Utils
// const _getFileFromDataUrl = blobUrl =>
//   fetch(blobUrl)
//     .then(resp => resp.blob())
//     .then(file => {
//       const ext = IMAGE_MIME_TO_EXT[file.type] || '.jpg'
//       return new File([file], `file${ext}`, { type: file.type })
//     })
//
// const _processUploadResponse = res => {
//   const { images } = res.data
//   if (images) {
//     const { filePath } = images[0]
//     return filePath
//   }
//   return ''
// }

// const syncProjectImages = (dispatch, getState) => projectData => {
//   const _checkFileSize = file => {
//     const checkForUserLimits = checkUserLimitAndShowWarning(file.size)(
//       dispatch,
//       getState
//     )
//     // TODO: return message for showing notifications
//     if (!checkForUserLimits) {
//       return Promise.reject()
//     }
//     return Promise.resolve(file)
//   }
//
//   /**
//    * Grab image via URL and upload to server.
//    */
//   const _uploadBlob = dataUrl =>
//     _getFileFromDataUrl(dataUrl)
//       .then(_checkFileSize)
//       .then(uploadFilesAsync)
//       .then(_processUploadResponse)
//
//   /**
//    * Update images recoursivelly in component data
//    * Returns list of promises, where each one of them uploads blob and updates components image path.
//    */
//   function componentImagesUpdaters(data) {
//     if (!data) {
//       return []
//     }
//     const dataKeys = Object.keys(data)
//     const _uploadAndUpdate = key => {
//       const value = data[key]
//       return _uploadBlob(value)
//         .then(filePath => {
//           data[key] = filePath
//         })
//         .then(() => true)
//     }
//     const updaters = []
//
//     dataKeys.forEach(key => {
//       const value = data[key]
//       if (isPlainObject(value)) {
//         updaters.push(...componentImagesUpdaters(value))
//       } else if (Array.isArray(value)) {
//         value.forEach(item => {
//           updaters.push(...componentImagesUpdaters(item))
//         })
//       } else if (typeof value === 'string' && isBlobUrl(value)) {
//         updaters.push(_uploadAndUpdate(key))
//       }
//     })
//
//     return updaters
//   }
//
//   /**
//    * Upload project BLOB images, and update project data paths ON ProjectData Reference object.
//    */
//   const pageUpdaters = projectData.pages.map(page => {
//     const { components } = page
//     const updaters = components.map(({ data }) => componentImagesUpdaters(data))
//     return _flatten2DimArray(updaters)
//   })
//   return Promise.all(_flatten2DimArray(pageUpdaters)).then(() => projectData)
// }

const _redirectToProject = projectId => {
  // change redirect url for prod
  window.location.href = getLocalizedURL(
    `/website-maker/${projectId}/lang/edit`
  )
}

// This function fill sync local saved project after user signin
export const syncProject = () => (dispatch, getState) => {
  const state = getState()
  const eventData = selectEvent(state)
  const hasWebsiteEvent = selectHasWebsiteEvent(state)
  const _projectData = getProjectData(state)
  // clone project data for mutations
  const projectData = JSON.parse(JSON.stringify(_projectData))

  if (!projectData) {
    return Promise.reject()
  }
  dispatch(setProjectSyncingStatus(true))

  const _fetchUserData = fetchUserData()(dispatch)
  const _fetchMediaLimits = fetchMediaLibLimitsAction()(dispatch)

  Promise.all([_fetchUserData, _fetchMediaLimits])
    .then(() => {
      const { visualParams, ...data } = projectData
      return createNewProjectAPI({
        visualParams,
        data
      })
    })
    .then(res => {
      const {
        data: { id }
      } = res

      pushDataLayer({
        event: 'website_project_created',
        item_id: id
      })

      if (hasWebsiteEvent) {
        // eslint-disable-next-line no-unused-vars
        const { id: eventId, settings, ...initialEventData } = eventData
        return createEventAPI({ event: initialEventData }, id).then(({ data }) => {
          dispatch(setEventsData([data]))
          dispatch(setEventIdToRsvpComponents(data.id, false))
          return res
        })
      }

      return res
    })
    .then(projectData => {
      const {
        data: { id: projectId }
      } = projectData
      const _projectData = getProjectData(getState())

      return patchProjectDataAPI(projectId, { data: _projectData }).then(
        () => projectId
      )
    })
    .then(_redirectToProject)
    .catch(e => console.log(e))
}
