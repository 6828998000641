import styled from 'styled-components'

const AVATAR_SIZE = 38

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: 80px;
  border-bottom: 1px solid #d2e0fc;
  box-sizing: border-box;
`
export const Avatar = styled.div`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  border-radius: 50%;
  flex-shrink: 0;
  background-image: ${({ $data: { background, url } }) =>
  `url(${url}), ${background}`};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border: 1px solid #ffffff;
  box-shadow: 0 0 0 1px #d2e0fc;
`

export const NameAndEmail = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  max-width: calc(100% - (${AVATAR_SIZE}px) - 15px);
  flex: 1;
  & > h5 {
    font-size: 14px;
    color: #252e48;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  & > p {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #545f7e;
    font-weight: 500;
    margin-top: 3px;

    .beforeAt {
      white-space: nowrap;
      overflow: hidden;
    }

    .afterAt {
      white-space: nowrap;
    }
  }
`
