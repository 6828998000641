// @flow

import React, { PureComponent } from 'react'
import SlickSlider from 'react-slick'
import ItemWrapper from './ItemWrapper'
import { PrevArrow, NextArrow } from './Arrows'
import { SLIDER_PARAMS } from './consts'
import * as Styled from './styled'
import '../../Swipe/swipe-slider-styles.css'
import { getLeftOffsetFromActive, getRightOffsetFromActive } from './utils'

const ADDITIONAL_PADDING = 150

class Slider extends PureComponent<*, *> {
  constructor(props) {
    super(props)

    const slidesLength = React.Children.count(props.children)
    this.initialSlide = parseInt(slidesLength / 2)

    this.state = {
      activeIndex: this.initialSlide
    }
  }

  getOffsetFromCenter = (idx: number) => {
    const slidesLength = React.Children.count(this.props.children)
    const { activeIndex } = this.state

    const rightOffset = getRightOffsetFromActive(idx, activeIndex, slidesLength)
    const leftOffset = getLeftOffsetFromActive(idx, activeIndex, slidesLength)

    if (rightOffset > leftOffset) {
      return -leftOffset
    } else if (rightOffset === leftOffset && rightOffset === 1) {
      return NaN
    }
    return rightOffset
  }

  onBeforeChange = (_, newIdx: number) => {
    this.setState({
      activeIndex: newIdx
    })
    this.props.onBeforeChange && this.props.onBeforeChange(newIdx)
  }

  render() {
    const { slickProps = {}, itemProps } = this.props
    const sliderProps = {
      ...SLIDER_PARAMS,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />
    }

    return (
      <div
        style={{
          maxWidth:
            sliderProps.slidesToShow * itemProps.width + ADDITIONAL_PADDING,
          margin: '0 auto'
        }}
      >
        <Styled.GlobalStyles />
        <SlickSlider
          {...sliderProps}
          {...slickProps}
          initialSlide={this.initialSlide}
          beforeChange={this.onBeforeChange}
          swipeToSlide={this.props.swipeToSlide}
          swipe={this.props.swipe}
        >
          {React.Children.map(this.props.children, (child, idx) => {
            const offsetFromCenter = this.getOffsetFromCenter(idx)
            return (
              <ItemWrapper
                {...itemProps}
                animationDuration={sliderProps.speed}
                offsetFromCenter={offsetFromCenter}
                key={idx}
              >
                {child}
              </ItemWrapper>
            )
          })}
        </SlickSlider>
      </div>
    )
  }
}

export default Slider
