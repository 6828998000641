import styled, { css } from 'styled-components'

const getOffsetStyles = ({ offsets }) => css`
  top: ${offsets.top}px;
  ${offsets.left || offsets.left === 0
    ? `left: ${offsets.left}px;`
    : `right: ${offsets.right}px;`}
`

const containerStyles = css`
  position: ${({ isPositionFixed }) =>
    isPositionFixed ? 'fixed' : 'absolute'};
  box-sizing: border-box;
  z-index: 50;
  ${getOffsetStyles}
`

export const FlyoutContainer = styled.div`
  height: auto;
  ${containerStyles};
`

export const FlyOutContainerWithAnimation = styled.div`
  @keyframes open-flyout-${({ animationPrefix }) => animationPrefix} {
    0% {
      height: 0;
    }
    99% {
      height: ${({ height }) => `${height}px`};
    }
    100% {
      height: auto;
    }
  }
  @keyframes close-flyout-${({ animationPrefix }) => animationPrefix} {
    0% {
      height: auto;
    }
    1% {
      height: ${({ height }) => `${height}px`};
    }
    100% {
      height: 0;
    }
  }
  overflow: hidden;
  animation: ${({ isOpen, animationPrefix }) =>
      isOpen
        ? `open-flyout-${animationPrefix}`
        : `close-flyout-${animationPrefix}`}
    0.2s ease-out;
  height: ${({ isOpen }) => (isOpen ? 'auto' : 0)};
  ${containerStyles}
`
