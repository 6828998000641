/* @flow */
/* eslint-disable */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_BLOCK':
        draft.slides.push(initialState.slides[0])
        break
      case 'REMOVE_BLOCK':
        draft.slides.splice(action.value.idx, 1)
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_SLIDE_CONTENT':
        const { newContent, idx: _idx } = action.value
        draft.slides[_idx].slideContent = newContent
        break
      case 'CHANGE_CLIENT_NAME':
        const { newClientName, idx } = action.value
        draft.slides[idx].customerName = newClientName
        break
      case 'CHANGE_CLIENT_POSITION':
        const { newClientPosition, idx: __idx } = action.value
        draft.slides[__idx].customerPosition = newClientPosition
        break
      case 'CHANGE_CLIENT_LOGO': {
        const { url, idx, imgDimensions } = action.value
        draft.slides[idx].imageUrl = url
        draft.slides[idx].imgDimensions = imgDimensions || {}
        break
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.slides[idx].customerImgUrl = url
        draft.slides[idx].customarImgDimensions = dimensions || {}
        break
      }
      case 'REMOVE_CLIENT_LOGO': {
        draft.slides[action.value].imageUrl = ''
        draft.slides[action.value].imgDimensions = {}
        break
      }
      case 'REMOVE_IMAGE':
        draft.slides[action.value].customerImgUrl = ''
        draft.slides[action.value].customarImgDimensions = {}
        break
      case 'CHANGE_CLIENT_URL':
        {
          const { idx, link } = action.value
          draft.slides[idx].clientUrl = link
        }
        break
      case 'ADD_CLIENT_AVATAR':
        const __slideIdx = action.value
        draft.slides[__slideIdx].customerImgUrl =
          initialState.slides[0].customerImgUrl
        draft.slides[__slideIdx].customarImgDimensions =
          initialState.slides[0].customarImgDimensions
        break
      case 'ADD_CLIENT_LOGO':
        const slideIdx = action.value
        draft.slides[slideIdx].imageUrl = initialState.slides[0].imageUrl
        draft.slides[slideIdx].imgDimensions =
          initialState.slides[0].imgDimensions
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_CLIENT_NAME_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].nameAlignment = alignment
        break
      }
      case 'CHANGE_CLIENT_POSITION_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].positionAlignment = alignment
        break
      }
      case 'CHANGE_SLIDE_CONTENT_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].slideContentAlignment = alignment
        break
      }
    }
  })
}
