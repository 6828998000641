export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    background: p => p.tertiaryExtraLight
  }
}
