import React from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import { selectSidebarActiveItem } from '@selectors'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import NewLabel from '@editor/common/assets/new-label-violet.svg'

const MainSidebarItem = ({
  label,
  icon,
  hasNew,
  isActive,
  onItemClick,
  sidebarActiveItem,
  isMainSidebarExpanded,
  isLastItem
}) => {
  return isMainSidebarExpanded ? (
    <Styled.MainSidebarItem
      isLastItem={isLastItem}
      isActive={isActive}
      onClick={onItemClick}
      sidebarActiveItem={sidebarActiveItem}
      isMainSidebarExpanded={isMainSidebarExpanded}
    >
      <Styled.IconWrapper
        isActive={isActive}
        isLastItem={isLastItem}
        isMainSidebarExpanded={isMainSidebarExpanded}
      >
        {icon}
      </Styled.IconWrapper>
      <Styled.Label
        isActive={isActive}
        isLastItem={isLastItem}
        isMainSidebarExpanded={isMainSidebarExpanded}
      >
        {label}
      </Styled.Label>
      {hasNew && <Styled.NewLabel src={NewLabel} alt="new-label" />}
    </Styled.MainSidebarItem>
  ) : (
    <TooltipWrapper text={label} position="right">
      <Styled.MainSidebarItem
        isLastItem={isLastItem}
        isActive={isActive}
        onClick={onItemClick}
        sidebarActiveItem={sidebarActiveItem}
        isMainSidebarExpanded={isMainSidebarExpanded}
      >
        <Styled.IconWrapper
          hasNew={hasNew}
          isActive={isActive}
          isLastItem={isLastItem}
          isMainSidebarExpanded={isMainSidebarExpanded}
        >
          {icon}
        </Styled.IconWrapper>
      </Styled.MainSidebarItem>
    </TooltipWrapper>
  )
}

const mapStateToProps = state => ({
  sidebarActiveItem: selectSidebarActiveItem(state)
})

export default connect(mapStateToProps)(MainSidebarItem)
