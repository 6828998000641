/* @flow */
import React, { useContext, memo } from 'react'
import S from './Portfolio5.styled'
import { validation } from './Portfolio5.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import { EditingContext, DispatchContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Portfolio5 = memo(props => {
  const {
    data: { title, paragraph, images, titleAlignment, paragraphAlignment }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <>
      <S.StyledContainer isEditing={isEditing}>
        <S.PortItem className="item1">
          <S.ContentWrapper>
            <S.BackgroundTitle className="bg-title-1">
              {title}
            </S.BackgroundTitle>
            <S.BackgroundTitle className="bg-title-2">
              {title}
            </S.BackgroundTitle>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.headingMaxChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading54"
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                className="WM_GLOBAL_heading20"
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.ContentWrapper>
        </S.PortItem>
        <Mapper
          data={images}
          render={(item: string, idx: number) => {
            return (
              <S.PortItem key={idx}>
                <ControlsWithImageCropper
                  style={{ width: '100%', height: '100%' }}
                  actions={Actions.imageActions(
                    idx,
                    images.length,
                    item.imgUrl
                  )}
                >
                  <Image
                    as={S.Img}
                    alt="Portfolio item"
                    asProps={{
                      imgCropParams: item.imgCropParams
                    }}
                    sizes={item.imgDimensions}
                    defaultImgSrc={item.imgUrl}
                  />
                </ControlsWithImageCropper>
              </S.PortItem>
            )
          }}
        />
      </S.StyledContainer>
      <AddButton
        onAdd={Actions.addRow}
        toShow={images.length < validation.images.max}
        style={{
          margin: '60px 0'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_row_label')}
      </AddButton>
    </>
  )
})

export default Portfolio5
