import BusinessIcon from '@editor/common/assets/svgr-icons/Business_icon.svg'
import LifestyleIcon from '@editor/common/assets/svgr-icons/Lifestyle_icon.svg'
import PersonalIcon from '@editor/common/assets/svgr-icons/Personal_icon.svg'
import InvitationIcon from '@editor/common/assets/svgr-icons/invitation_category_icon.svg'
import ArtIcon from '@editor/common/assets/svgr-icons/Art_icon.svg'
import ComingSoonIcon from '@editor/common/assets/svgr-icons/Coming_soon_icon.svg'

import { NonProfitIcon, EventIcon } from './styled'

export const ICONS = {
  2: BusinessIcon,
  3: LifestyleIcon,
  4: PersonalIcon,
  5: EventIcon,
  6: NonProfitIcon,
  7: ArtIcon,
  32: ComingSoonIcon,
  33: InvitationIcon
}
