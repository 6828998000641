/* @flow */

import styled, { css } from 'styled-components'
import THEME from '../Contact5.theme'
import {
  styleProvider,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'
const getStyleForProp = styleProvider(THEME)
import { secondary, primary, white } from '@website/common/styles/colors'
import Icon from '@website/common/components/Icon'
import S from '@website/common/components/SelectMenu/SelectMenu.styled'

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  @media only screen and (max-width: 600px) {
    flex-direction: column;
  }
`

const DateInputContainer = styled.div`
  width: 48.5%;
  > div:first-child {
    display: flex;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
  .react-datepicker-wrapper {
    width: 80%;
    @media only screen and (max-width: 768px) {
      width: 93%;
    }
  }
  .react-datepicker__time-list {
    .react-datepicker__time-list-item {
      display: flex;
      align-items: center;
      font-weight: 400;
      color: ${({ theme }) => `${theme.palette.secondaryExtraDark}`};
    }
    .react-datepicker__time-list-item--selected {
      background-color: ${({ theme }) =>
        `${theme.palette.primaryDark}`} !important;
    }
  }
  .react-datepicker__header {
    display: none;
  }
  .react-datepicker__triangle {
    display: none;
  }
  .react-datepicker-popper {
    z-index: 4 !important;
    top: -10px !important;
  }
  input {
    &:focus {
      outline: none;
    }
  }
`

const ContactDatePicker = styled.div`
  position: absolute;
  top: 71px;
  left: 15px;
  z-index: 5;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 9px 22px 0px rgba(0, 0, 0, 0.12);
`

const TimeInputContainer = styled.div`
  width: 48.5%;
  @media only screen and (max-width: 600px) {
    width: 100%;
    margin-top: 20px;
  }
  > div {
    display: flex;
  }
`

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  font-size: 14px;
  color: #eb0c00;
  > div {
    margin-right: 5px;
  }
`

const FormContainer = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  padding: 30px;
  background-color: ${white};
  position: relative;
  border-top: ${({ theme }) => `5px solid ${theme.palette.primaryDark}`};
  ${({ isEditing }) =>
    isEditing &&
    css`
      & ${S.SelectMenuWrap} {
        & > div:first-child {
          margin: 42px 0 0 0;
        }
      }
    `};
  & ${S.SelectMenuWrap} {
    background-color: white;
    border: ${({ theme }) =>
      `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  }
  & ${S.MenuWrap} {
    ${({ showSelectInputError, theme }) =>
      showSelectInputError
        ? css`
            border: 1px solid #eb0c00;
            + ${ErrorMessage} {
              display: block;
            }
          `
        : css`
            border: 1px solid
              ${`rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
            + ${ErrorMessage} {
              display: none;
            }
          `};
  }
  @media only screen and (max-width: 370px) {
    padding: 20px;
  }
  .input-container {
    position: relative;
    margin-top: 10px;
    ${ErrorMessage} {
      top: 52px;
    }
  }
  .details-input-container {
    ${ErrorMessage} {
      top: 102px;
    }
  }
  > .control-container:first-child {
    width: 100%;
    margin: 30px 10px;
    h1 {
      margin-bottom: 0;
      padding: 0;
    }
  }
`

const DateInput = styled.div`
  z-index: 1;
  padding-left: 15px;
  height: 50px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  margin-right: 10px;
  border-radius: ${getStyleForProp('buttonRadius')};
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  @media only screen and (max-width: 600px) {
    margin-right: 0;
  }
  .date-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    svg {
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
`

const TimeInput = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  box-sizing: border-box;
  border: ${({ error, theme }) =>
    `1px solid ${
      error ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  border-radius: ${getStyleForProp('buttonRadius')};
  position: relative;
  margin-top: 10px;
  input {
    border: none;
    font-size: 16px;
    width: 100%;
    padding: 0;
    height: 32px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    z-index: 1;
    background: transparent;
    &:focus {
      border: none;
      outline: none;
    }
  }
  .date-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
  }
  .date-icon {
    svg {
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
  .react-datepicker-wrapper {
    width: 80%;
  }
  .react-datepicker__time-list-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const ServiceInput = styled.input`
  width: 100%;
  height: 42px;
  box-sizing: border-box;
  border-color: ${({ theme }) => theme.palette.tertiaryDark}; ;
`

const Row = styled.span`
  width: 100%;
`
const Button = styled.button`
  max-width: 300px;
  border: none;
  border-radius: ${getStyleForProp('buttonRadius')};
  white-space: nowrap;
  ${getStyleForProp('buttonBackground')};
  overflow: hidden;
  text-overflow: ellipsis;
  background-repeat: no-repeat;
  height: 50px;
  line-height: 50px;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0 40px;
  font-size: 14px;
  letter-spacing: 0.4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  word-break: initial;
  outline: none;
  &:focus {
    outline: none;
  }
`

const InputField = styled.input`
  border-radius: ${getStyleForProp('inputRadius')};
  -webkit-appearance: none;
  height: 50px;
  min-width: 260px;
  border: none;
  padding: 4px 16px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
  }
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
  }
  caret-color: ${({ theme }) => theme.palette.tertiaryDark};
  outline: none;
  padding: 11.25px 12px;
`

const Textarea = styled.textarea`
  border-radius: ${getStyleForProp('inputRadius')};
  -webkit-appearance: none;
  resize: none;
  height: 42px;
  min-width: 260px;
  border: none;
  padding: 4px 16px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
  }
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
  }
  caret-color: ${({ theme }) => theme.palette.tertiaryDark};
  outline: none;
  padding: 11.25px 12px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0px;
  position: relative;
  align-items: center;
`

const FormTitle = styled.h1`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  padding: 30px 10px 30px 10px;
  font-size: 32px;
  width: 100%;
  ${getTextStyleByAlignment};
  box-sizing: border-box;
`

const NotificationIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  right: 0;
  margin: 0 10px;
  visibility: ${({ toShow }) => (toShow ? 'visible' : 'hidden')};
`

const Label = styled.label`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin: ${({ isEditing }) => (isEditing ? '0 0 6px 0' : '0')};
  display: inline-block;
  &::after,
  &::before {
    right: initial;
    left: 0;
  }
`

const ReqPointer = styled.span`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  margin-left: 3px;
`

const ServiceOptionContainer = styled.div`
  display: ${({ isEditing }) => (isEditing ? 'flex' : 'block')};
  flex-direction: column;
`

const ReqIconWrap = styled.div`
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-image: ${({ isRequired }) =>
    isRequired
      ? `linear-gradient(${primary.Dark}, ${primary.Dark})`
      : `linear-gradient(180deg, #ffffff 0%, #ffffff 0%, #f5fafe 100%) 0%
    0% no-repeat border-box`};
  border: 1px solid;
  border-color: ${({ isRequired }) => (isRequired ? 'transparent' : '#7683a8')};
  border-style: ${({ isRequired }) => (isRequired ? 'solid' : 'dashed')};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 3px 9px rgba(49, 119, 248, 0.101);
  cursor: pointer;
  user-select: none;
  .icon {
    svg {
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
`
const RequiredIcon = styled(Icon).attrs(({ isRequired }) => ({
  color: isRequired ? 'white' : secondary.Dark
}))`
  position: relative;
  svg {
    position: absolute;
  }
`

const Hint = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryNormal};
  font-size: 14px;
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  @media only screen and (max-width: 410px) {
    bottom: 6px;
    font-size: 12px;
  }
`

const ServiceInputContainer = styled.div`
  margin-top: 20px;
  .arrow-icon > svg {
    fill: ${({ theme }) => theme.palette.tertiaryDark};
  }
`

const PersonalInfoInputContainer = styled.div`
  margin-top: 20px;
`

const LabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    display: flex;
  }
`

const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

const SelectMenuWrapper = styled.div`
  position: relative;
`

const FormButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  > div {
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
  }
  .button-container {
    margin: 20px 0;
  }
`

export default {
  DateContainer,
  DateInputContainer,
  ContactDatePicker,
  TimeInputContainer,
  ServiceInputContainer,
  PersonalInfoInputContainer,
  LabelContainer,
  Row,
  Label,
  FormTitle,
  RequiredIcon,
  ButtonContainer,
  InputField,
  Textarea,
  Button,
  DateInput,
  TimeInput,
  ServiceInput,
  FormContainer,
  NotificationIcon,
  ServiceOptionContainer,
  ReqIconWrap,
  ReqPointer,
  Hint,
  ErrorMessage,
  ErrorIcon,
  SelectMenuWrapper,
  FormButtonWrapper
}
