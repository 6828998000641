import React, { useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'

import * as Styled from './styled'
import {
  TEXT,
  COLORS,
  STYLES,
  WIDGETS,
  SETTINGS,
  EVENT,
  INVITE
} from '@containers/edit/consts'
import MainSidebarItem from './MainSidebarItem'
import SavingChanges from '@editor/common/components/SavingChanges'
import TextIcon from '@editor/common/assets/svgr-icons/text_icon.svg'
import ColorIcon from '@editor/common/assets/svgr-icons/color_palette_icon.svg'
import StyleIcon from '@editor/common/assets/svgr-icons/star_icon.svg'
import WidgetsIcon from '@editor/common/assets/svgr-icons/widget_icon.svg'
import SettingIcon from '@editor/common/assets/svgr-icons/settings_icon.svg'
import ShareLinkIcon from '@editor/common/assets/svgr-icons/share_link_icon.svg'
import CollapseIcon from '@editor/common/assets/svgr-icons/arrow_left_icon.svg'
import EventIcon from '@editor/common/assets/svgr-icons/rsvp_event_icon.svg'
import {
  changeActiveSidebarItem,
  openLinkSharePopup,
  openLoginPopup,
  setEventCreationPopupVisibilityState
} from '@actions/editor'
import {
  selectIsUserAuthorized,
  selectSidebarActiveItem,
  selectHasWebsiteEvent
} from '@selectors'
import { useHistoryWithSearch } from '@hooks'
import { isOpenedWithHash } from '@editor/common/utils'
import { translate } from '@editor/common/utils/translations'
import { PAGE_BASE } from '@editor/conf/routes'

const MainSidebarContent = ({
  isMainSidebarExpanded,
  toggleMainSidebar,
  setSubSidebarOpenedState,
  sidebarActiveItem,
  isUserAuthorized,
  changeActiveSidebarItem,
  openLinkSharePopup,
  openLoginPopup,
  setEventCreationPopupVisibilityState,
  hasWebsiteEvent
}) => {
  const history = useHistoryWithSearch()
  const { url } = useRouteMatch()
  const { params } = useRouteMatch(PAGE_BASE) || {}
  const page = params?.page

  const onItemClick = useCallback(
    (e, value) => {
      e.stopPropagation()
      if (value === sidebarActiveItem) {
        setSubSidebarOpenedState(false)
        return
      }

      changeActiveSidebarItem(value)
      setSubSidebarOpenedState(true)
      history.push(`${url}/${page}`)
    },
    [sidebarActiveItem, page, url]
  )

  const onWidgetsClick = useCallback(
    e => {
      if (!isUserAuthorized) {
        openLoginPopup()
        return
      }

      e.stopPropagation()
      setSubSidebarOpenedState(false)
      history.push(`${url}/${page}/widgets`)
    },
    [isUserAuthorized, page, url]
  )

  const onEventClick = useCallback(
    e => {
      e.stopPropagation()
      setSubSidebarOpenedState(false)

      if (hasWebsiteEvent) {
        if (!isUserAuthorized) {
          openLoginPopup()
          return
        }
        history.push(`${url}/${page}/event-settings/details`)
      } else {
        setEventCreationPopupVisibilityState(true)
      }
    },
    [hasWebsiteEvent, url, page]
  )

  const onSettingsClick = useCallback(
    e => {
      if (!isUserAuthorized) {
        openLoginPopup()
        return
      }

      e.stopPropagation()
      setSubSidebarOpenedState(false)
      history.push(`${url}/${page}/settings`)
    },
    [isUserAuthorized, page, url]
  )

  const onLinkShareItemClick = e => {
    e.stopPropagation()
    setSubSidebarOpenedState(false)

    openLinkSharePopup()
  }

  return (
    <Styled.MainSidebarContainer sidebarActiveItem={sidebarActiveItem}>
      <div>
        <MainSidebarItem
          label={translate('text_label')}
          icon={<TextIcon />}
          onItemClick={e => onItemClick(e, TEXT)}
          isActive={sidebarActiveItem === TEXT}
          isMainSidebarExpanded={isMainSidebarExpanded}
        />
        <MainSidebarItem
          label={translate('colors_label')}
          icon={<ColorIcon />}
          onItemClick={e => onItemClick(e, COLORS)}
          isActive={sidebarActiveItem === COLORS}
          isMainSidebarExpanded={isMainSidebarExpanded}
        />
        <MainSidebarItem
          label={translate('styles_label')}
          icon={<StyleIcon />}
          onItemClick={e => onItemClick(e, STYLES)}
          isActive={sidebarActiveItem === STYLES}
          isMainSidebarExpanded={isMainSidebarExpanded}
        />
      </div>
      <Styled.MainSidebarMidPart>
        <SavingChanges isExpanded={isMainSidebarExpanded} />
      </Styled.MainSidebarMidPart>
      <div>
        {isOpenedWithHash() ? null : (
          <MainSidebarItem
            label={translate('event_label')}
            icon={<EventIcon />}
            onItemClick={onEventClick}
            isActive={sidebarActiveItem === EVENT}
            isMainSidebarExpanded={isMainSidebarExpanded}
          />
        )}
        {isUserAuthorized && !isOpenedWithHash() && (
          <MainSidebarItem
            label={translate('share_label')}
            icon={<ShareLinkIcon />}
            onItemClick={onLinkShareItemClick}
            isActive={sidebarActiveItem === INVITE}
            isMainSidebarExpanded={isMainSidebarExpanded}
          />
        )}
        <MainSidebarItem
          label={translate('widgets_label')}
          icon={<WidgetsIcon />}
          onItemClick={onWidgetsClick}
          isActive={sidebarActiveItem === WIDGETS}
          isMainSidebarExpanded={isMainSidebarExpanded}
        />
        <MainSidebarItem
          label={translate('settings_label')}
          icon={<SettingIcon />}
          onItemClick={onSettingsClick}
          isActive={sidebarActiveItem === SETTINGS}
          isMainSidebarExpanded={isMainSidebarExpanded}
        />
        <MainSidebarItem
          label={
            isMainSidebarExpanded
              ? translate('collapse_label')
              : translate('expand_label')
          }
          icon={<CollapseIcon />}
          onItemClick={toggleMainSidebar}
          isMainSidebarExpanded={isMainSidebarExpanded}
          isLastItem
        />
      </div>
    </Styled.MainSidebarContainer>
  )
}

const mapStateToProps = state => ({
  sidebarActiveItem: selectSidebarActiveItem(state),
  isUserAuthorized: selectIsUserAuthorized(state),
  hasWebsiteEvent: selectHasWebsiteEvent(state)
})

export default connect(mapStateToProps, {
  changeActiveSidebarItem,
  openLinkSharePopup,
  openLoginPopup,
  setEventCreationPopupVisibilityState
})(MainSidebarContent)
