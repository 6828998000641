/* @flow */

export const validation = {
  headingMaxChar: 200,
  paragraphMaxChar: 200,
  contentParagraphMaxChar: 1000,
  buttons: {
    min: 0,
    max: 2
  }
}
