/* @flow */
import styled from 'styled-components'
import { white, primary, secondary } from '@website/common/styles/colors'
import LibPopup from '@renderforest/rf-ui-library/organism/Popup'

export const SignOutButton = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  box-sizing: border-box;
  user-select: none;
  &:hover {
    background-color: #eef5ff;
    svg {
      fill: #363f5a;
    }
  }
  & > span {
    font-size: 14px;
    color: #545f7e;
    font-weight: 500;
  }
`

export const LangText = styled.span`
  margin: 0 10px;
`

export const Popup = styled(LibPopup)`
  & > div {
    padding: 0;
    & > div {
      padding: 0;
    }
  }

  @media only screen and (max-width: 568px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      width: 95%;
    }
  }
`

export const PopupHeader = styled.div`
  padding: 40px 20px 0;
  display: flex;
  justify-content: center;
  & > h3 {
    font-weight: 600;
    text-align: center;
    font-size: 20px;
    color: ${secondary.ExtraDark};
  }
`

export const WarningButton = styled.a`
  color: ${white};
  text-decoration: none;
  max-width: 260px;
  height: 20px;
  padding: 10px 30px;
  margin: 0 auto 40px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
  background: transparent
    linear-gradient(180deg, ${primary.SemiDark} 0%, ${primary.Dark} 100%) 0% 0%
    no-repeat padding-box;
  &:hover {
    background: transparent
      linear-gradient(180deg, #6ea0ff 0%, ${primary.SemiDark} 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 3px 9px #0052e080;
    transition: all 0.2s ease-out;
  }
`

export const Title = styled.h3`
  max-width: 310px;
  margin: 30px;
  @media only screen and (max-width: 400px) {
    margin: 0 20px 30px;
  }
  margin-top: 0;
`

export const Icon = styled.div`
  width: 24px;
  height: 24px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: #eef5ff;
  border-radius: 5px;

  & > svg {
    fill: #7683a8;
    width: 16px;
    height: 16px;
  }

  @media only screen and (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
`
