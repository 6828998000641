/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        break
      case 'REMOVE_IMAGE':
        draft.slides.splice(action.value.idx, 1)
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_SLIDE_TITLE':
        const { newTitle, idx } = action.value
        draft.slides[idx].slideTitle = newTitle
        break
      case 'CHANGE_SLIDE_CONTENT':
        const { newContent, idx: _idx } = action.value
        draft.slides[_idx].slideContent = newContent
        break

      case 'CHANGE_SLIDE_TITLE_ALIGNMENT': {
        const { alignment, idx: _idx } = action.value
        draft.slides[_idx].titleAlignment = alignment
        break
      }

      case 'CHANGE_SLIDE_CONTENT_ALIGNMENT': {
        const { alignment, idx: _idx } = action.value
        draft.slides[_idx].contentAlignment = alignment
        break
      }

      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.slides[idx].imageUrl = url
        draft.slides[idx].imgDimensions = dimensions || {}
        draft.slides[idx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
      }
    }
  })
}
