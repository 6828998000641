import styled from 'styled-components'

import {
  UPGRADE_BANNER_BG_IMAGE,
  UPGRADE_BANNER_MOBILE_BG_IMAGE
} from './consts'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import { PrimaryButton } from '@editor/common/styled-components/buttons-new'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const stylesObj = {
  de: {
    width: '730px'
  },
  ru: {
    width: '730px'
  },
  default: {
    width: '680px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const PublishPopup = styled(Popup)`
  & > div {
    padding: 30px;
    width: ${getStylesByLang('width')};
    & > div {
      padding: 0;
      align-items: center;
    }
  }

  .publish-loader {
    p {
      color: #252e48;
    }
  }

  @media only screen and (max-width: 768px) {
    & > div {
      padding: 30px 20px;
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const Title = styled.p`
  color: #252e48;
  font-size: 20px;
  font-weight: 600;
  padding: 20px 0;
  text-align: center;
`

export const PublishBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  margin-bottom: 20px;
  border: 1px solid #dee3f0;
  border-radius: 10px;
  box-sizing: border-box;
  width: 100%;
  @media only screen and (max-width: 767px) {
    border: none;
    margin-top: 20px;
    padding: 0;
  }
`

export const PublishBlockTitle = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #252e48;
  padding-bottom: 10px;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & .subdomain-input-wrapper {
    @media only screen and (min-width: 769px) {
      max-width: 235px;
    }
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    flex-direction: column;
  }
`

export const UpgradeBanner = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 617px;
  padding: 30px;
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  background-image: url(${UPGRADE_BANNER_BG_IMAGE});
  background-repeat: no-repeat;
  background-size: cover;
  @media only screen and (max-width: 767px) {
    background-image: url(${UPGRADE_BANNER_MOBILE_BG_IMAGE});
    background-size: 100% 100%;
  }
`

export const UpgradeBannerTitle = styled.p`
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  text-align: center;
`

export const UpgradeBannerDescription = styled.p`
  max-width: 470px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 30px;
  text-align: center;
`

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: linear-gradient(315deg, #ea9f6d, #9467fc, #689af5, #cc4bd6);
  background-size: 600% 100%;
  animation: gradient 7s linear infinite;
  animation-direction: alternate;

  @keyframes gradient {
    0% {
      background-position: 0%;
    }
    100% {
      background-position: 100%;
    }
  }
`

export const Divider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  & > div {
    flex-grow: 1;
    height: 1px;
    &:first-child {
      background: linear-gradient(to right, #ffffff, #d0d6e780, #d0d6e7);
    }
    &:last-child {
      background: linear-gradient(to left, #ffffff, #d0d6e780, #d0d6e7);
    }
  }
  & > span {
    font-size: 16px;
    color: #545f7e;
    margin: 0 10px;
  }
`

export const ConnectDomainWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 30px;
  box-sizing: border-box;
  background: url('https://static.rfstat.com/renderforest/images/website_maker_images/connect-domain-banner-bg.png ')
    no-repeat center/cover;
  border-radius: 10px;
  border: 1px solid #dbe8ff;
  margin-top: 15px;
  width: 100%;
  & > div {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-width: 350px;
    & > h3 {
      color: #252e48;
      font-size: 18px;
      font-weight: 600;
    }
    & > p {
      font-size: 14px;
      margin-top: 10px;
      color: #363f5a;
      line-height: 20px;
    }
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    background: url('https://static.rfstat.com/renderforest/images/website_maker_images/subdomain_mobile_background.png ')
      no-repeat center/cover;
    & > div {
      & > h3 {
        text-align: center;
      }
      & > p {
        text-align: center;
      }
    }
  }
`

export const ConnectButton = styled(PrimaryButton)`
  margin-left: 30px;
  white-space: nowrap;
  width: 180px;
  min-width: 180px;
  @media only screen and (max-width: 768px) {
    margin-left: 0;
    margin-top: 20px;
  }
`

export const UpgradeBtn = styled.button`
  width: fit-content;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  padding: 16px 30px;
  box-shadow: 0px 3px 9px #f37c6c80;
  border-radius: 25px;
  background: transparent linear-gradient(180deg, #ffa330 0%, #f07379 100%) 0%
    0% no-repeat;
  box-sizing: border-box;
  cursor: pointer;
  outline: none;
  border: none;
  white-space: nowrap;
  &:hover {
    background: transparent linear-gradient(180deg, #ffb04e 0%, #ff7e84 100%) 0%
      0% no-repeat;
  }
`
