import { translate } from '@editor/common/utils/translations'

export const ID_PLACEHOLDER = 'XYXYXYXY'
export const AMOUNT_PLACEHOLDER = '0'

export const SELLING_ITEM_DATA = [
  {
    component: translate('selling_label'),
    label: translate('selling_label'),
    value: 'selling'
  }
]

export const DONATING_ITEM_DATA = [
  {
    component: translate('donating_label'),
    label: translate('donating_label'),
    value: 'donating'
  }
]

export const CURRENCY_DROPDOWN_DATA = [
  { component: 'A$ AUD', label: 'A$ AUD', value: 'AUD' },
  { component: 'R$ BRL', label: 'R$ BRL', value: 'BRL' },
  { component: 'C$ CAD', label: 'C$ CAD', value: 'CAD' },
  { component: '¥ CNY', label: '¥ CNY', value: 'CNY' },
  { component: 'Kč CZK', label: 'Kč CZK', value: 'CZK' },
  { component: 'kr DKK', label: 'kr DKK', value: 'DKK' },
  { component: '€ EUR', label: '€ EUR', value: 'EUR' },
  { component: 'HK$ HKD', label: 'HK$ HKD', value: 'HKD' },
  { component: 'Ft HUF', label: 'Ft HUF', value: 'HUF' },
  { component: '₪ ILS', label: '₪ ILS', value: 'ILS' },
  { component: '¥ JPY', label: '¥ JPY', value: 'JPY' },
  { component: 'RM MYR', label: 'RM MYR', value: 'MYR' },
  { component: 'MXN$ MXN', label: 'MXN$ MXN', value: 'MXN' },
  { component: 'NT$ TWD', label: 'NT$ TWD', value: 'TWD' },
  { component: 'NZ$ NZD', label: 'NZ$ NZD', value: 'NZD' },
  { component: 'kr NOK', label: 'kr NOK', value: 'NOK' },
  { component: '₱ PHP', label: '₱ PHP', value: 'PHP' },
  { component: 'Zł PLN', label: 'Zł PLN', value: 'PLN' },
  { component: '£ GBP', label: '£ GBP', value: 'GBP' },
  { component: '₽ RUB', label: '₽ RUB', value: 'RUB' },
  { component: 'SG$ SGD', label: 'SG$ SGD', value: 'SGD' },
  { component: 'kr SEK', label: 'kr SEK', value: 'SEK' },
  { component: '₣ CHF', label: '₣ CHF', value: 'CHF' },
  { component: '฿ THB', label: '฿ THB', value: 'THB' },
  { component: '$ USD', label: '$ USD', value: 'USD' }
]

export const CHECKBOX_DATA = {
  label: translate('let_customers_set_their_own_amount_label')
}
