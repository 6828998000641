/* @flow */
import { validation } from './../CTA10.consts'
import {
  cropableImageModalActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  changeBlockTitle
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const imageActions = (idx: number, imageUrl: string) => {
    return cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'CTA10',
      additionalArgs: { idx },
      showRemove: false,
      imageUrl
    })
  }

  const removeListItem = (blockIdx: number) =>
    dispatcher({
      type: 'REMOVE_LIST_ITEM',
      value: blockIdx
    })

  const blockActions = (blockIdx, ftLenght) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeListItem(blockIdx)),
      toShow: ftLenght > validation.blocks.min
    }
  ]

  const changePrice = (newPrice: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_PRICE',
      value: { idx, newPrice }
    })

  const changeText = (newTitle: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_TEXT',
      value: { idx, newTitle }
    })

  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const _changeBlockTitle = (newTitle: string, blockIdx: number) => {
    dispatcher(changeBlockTitle(newTitle, blockIdx))
  }

  const changePriceAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PRICE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeText,
    changePrice,
    imageActions,
    blockActions,
    iconActions: iconActionMaker(dispatcher),
    addNewItem: () => dispatcher({ type: 'ADD_NEW_ITEM' }),
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraph: _changeParagraph,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitle: _changeBlockTitle,
    changeBlockTitleAlignment,
    changePriceAlignment
  }
}
