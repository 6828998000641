import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import Icon from '@website/common/components/Icon'
import { ColorPicker as Picker } from '@renderforest/rf-ui-library/organism/ColorPicker'
import * as Styled from './ColorPicker.styled'
import { useBackdropClick } from '@hooks'
import { useResponsive } from '@shared/hooks'
import {
  selectIsUserAuthorized,
  selectSemrushSidebarVisibilityState
} from '@selectors'
import { getSemrushDependedScreenBreakpoint } from '@editor/common/utils/styled'
import { translate } from '@editor/common/utils/translations'
import { BREAKPOINT_800 } from '@containers/edit/consts'

const ColorPicker = ({
  color: _color,
  containerRef,
  isUserAuthorized,
  isSemrushSidebarVisible,
  onColorChoose,
  closeColorPicker,
  hasExtraBackground
}) => {
  const [color, setColor] = useState(_color)
  const picker = useBackdropClick(closeColorPicker, ['.color-picker'])
  const breakpoint = getSemrushDependedScreenBreakpoint(
    BREAKPOINT_800,
    isSemrushSidebarVisible
  )

  const isSmallSizes = useResponsive(breakpoint)

  const onColorChange = useCallback(
    ({ color }) => {
      setColor(color)
      if (!isSmallSizes) {
        onColorChoose(color)
      }
    },
    [isSmallSizes]
  )

  const onCancel = useCallback(
    e => {
      e.stopPropagation()
      closeColorPicker()
    },
    [closeColorPicker]
  )

  const onSave = useCallback(
    e => {
      e.stopPropagation()
      onColorChoose(color)
      closeColorPicker()
    },
    [color, onColorChoose, closeColorPicker]
  )

  return (
    <>
      {hasExtraBackground
        ? ReactDOM.createPortal(
            <Styled.ColorPickerContainer className="color-picker-container" />,
            document.body
          )
        : null}
      <Styled.PickerPopover ref={picker}>
        {isSmallSizes ? (
          <Styled.ActionButtons className="action-buttons">
            <Styled.CloseButton onClick={onCancel} role="button">
              <Icon name="glyph-close" color="white" size="middle" />
            </Styled.CloseButton>
            <Styled.SaveButton role="button" onClick={onSave}>
              <Icon name="glyph-check" color="white" size="middle" />
            </Styled.SaveButton>
          </Styled.ActionButtons>
        ) : null}
        <Styled.Triangle />
        <Picker
          translations={{
            myColorsTitle: translate('my_colors_label'),
            addPaletteCtaText: translate('add_palette_label'),
            addPaletteTooltipMessage: translate('add_new_palette_label')
          }}
          isLoggedIn={isUserAuthorized}
          isMobile={isSmallSizes}
          refHandler={containerRef}
          onColorChange={onColorChange}
          color={color}
          className="color-picker"
        />
      </Styled.PickerPopover>
    </>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state),
  isSemrushSidebarVisible: selectSemrushSidebarVisibilityState(state)
})

export default connect(mapStateToProps)(ColorPicker)
