import { translate } from '@editor/common/utils/translations'

export const EVENT_CREATION_STEPS = [
  translate('event_creation_step1'),
  translate('event_creation_step2'),
  translate('event_creation_step3')
]

export const EVENT_ILLUSTRATION =
  'https://static.rfstat.com/renderforest/images/website_maker_images/RSVP_illustration.png'
