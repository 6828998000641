/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header16.theme'
import Icon from '@website/common/components/Icon'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import { hexToRgb } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0 0 0 0' : `${topOffset}px 0 0 0`};
  height: ${props =>
    props.isEditing ? 'calc(100vh - 158px)' : `${props.height}px`};
  flex-direction: column;
  flex-shrink: 0;
  & ${S.NavbarWrapper} {
    border-bottom: ${({ theme }) =>
      `1px solid rgba(${hexToRgb(
        theme.palette.tertiaryDark
      )}, 0.7) !important`};
  }
  @media (max-width: 768px) {
    min-height: ${({ isEditing }) => (isEditing ? '804px' : '604px')};
  }
  @media (min-width: 650px) {
    padding: 0;
    min-height: 700px;
  }
  & ${S.Container} {
    margin-bottom: 0;
  }
  & > .control-container {
    margin: 0 auto 90px auto;
    width: 100%;
    max-width: 1200px;

    & > h2 {
      margin: 0;
      max-width: unset;
    }
    p {
      margin-bottom: 0;
    }
  }
  .date-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    max-width: 700px;
    margin: 30px auto 0 auto;
    box-sizing: border-box;
    @media only screen and (max-width: 1024px) {
      padding-left: 40px;
      padding-right: 40px;
    }
    @media only screen and (max-width: 767px) {
      max-width: 440px;
    }
    @media only screen and (max-width: 457px) {
      padding-right: 20px;
      padding-left: 20px;
    }
  }
  .DayPicker-Day {
    &:focus {
      outline: none !important;
    }
  }
  .DayPicker-Day--selected {
    color: white !important;
    background-color: rgb(56, 125, 255) !important;
    font-weight: 700;
    outline: none;
  }
  .DayPicker-Day--today {
    background-color: rgba(56, 125, 255, 0.15) !important;
    font-weight: 500 !important;
    color: initial !important;
  }
  .clockerContainer {
    display: flex;
    justify-content: space-around;
  }
  .DayPicker {
    width: 100%;
  }
  .datePicker {
    width: 290px;
  }
  .DayPicker-Day {
    min-width: 36px;
    box-sizing: border-box;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  height: 100%;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  & > .control-container {
    margin-bottom: 30px;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    h1 {
      margin: 0;
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    padding-top: 0;
    padding-bottom: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  letter-spacing: 12px;
  box-sizing: border-box;
`

const Paragraph = styled.p`
  ${getTextStyleByAlignment};
  margin: 0 auto 90px auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 1400px;
  color: ${getStyleForProp('heading')};
  @media only screen and (max-width: 1280px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const DateWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 6px;
  box-sizing: border-box;
`

const Time = styled.p`
  color: ${getStyleForProp('heading')};
  min-width: 100px;
  text-align: center;
  margin-bottom: 0 !important;
  font-size: 72px;
  font-weight: 700;
  @media only screen and (max-width: 768px) {
    font-size: 35px;
    min-width: 50px;
  }
`

const Date = styled.p`
  color: ${getStyleForProp('heading')};
  font-weight: 700 !important;
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`

const DownButton = styled.div`
  padding: 14px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  transition: 500ms all cubic-bezier(0.84, 0.04, 1, 1);
  animation: arrowBounce 3s linear infinite;
  @keyframes arrowBounce {
    0%,
    20%,
    50%,
    80%,
    100% {
      transform: translateY(0) translateX(-50%);
    }
    40% {
      transform: translateY(-20px) translateX(-50%);
    }
    60% {
      transform: translateY(-10px) translateX(-50%);
    }
  }
`
const ScrollIcon = styled(Icon)`
  fill: ${getStyleForProp('heading')};
`

const TimeDots = styled.span`
  color: ${getStyleForProp('heading')};
  font-size: 40px;
  font-weight: bold;
  height: 90px;
  line-height: 84px;
  @media only screen and (max-width: 768px) {
    line-height: 40px;
    font-size: 20px;
  }
`

export default {
  WmCustomContainer,
  StyledContainer,
  DateWrap,
  Time,
  Date,
  Title,
  Paragraph,
  DownButton,
  ScrollIcon,
  TimeDots
}
