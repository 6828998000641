/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header20.theme'
import {
  styleProvider,
  hexToRgbA,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 0;
  ${({ backgroundImgUrl }) =>
    backgroundImgUrl &&
    css`
      @media only screen and (max-width: 769px) {
        background: url(${backgroundImgUrl});
        height: auto;
        min-height: 400px;
        background-repeat: no-repeat;
        background-size: auto 180vh;
        background-position: center;
        padding: ${({ isEditing }) => (isEditing ? '90px 0 130px 0' : '')};
      }
    `}
  @media only screen and (max-width: 769px) {
    height: auto;
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({
    bgOverlayTransparency,
    isBgImgOrVideoExists,
    ...rest
  }) => {
    const transparency = isBgImgOrVideoExists ? bgOverlayTransparency : 1
    return hexToRgbA(getStyleForProp('backgroundColor')(rest), transparency)
  }};
`

const WmCustomContainer = styled(WMContainer)`
  ${({ isEditing }) => (isEditing ? 'margin-top: 10px;' : null)}
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  min-height: 100vh;
`

const HeaderContent = styled.div`
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
  & > .control-container:nth-child(1) {
    margin-bottom: 20px;
    width: 100%;

    & > h1 {
      margin-bottom: 0;
    }
  }

  & > .control-container:nth-child(2) {
    margin-bottom: 30px;
    width: 100%;

    & > p {
      margin-bottom: 0;
      max-width: unset;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 30px;
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
  @media only screen and (max-width: 430px) {
    max-width: initial;
  }
`

const PlayButton = styled.div`
  border-radius: ${getStyleForProp('iconRadius')};
  border: 2px solid ${getStyleForProp('heading')};
  color: ${getStyleForProp('heading')};
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s linear;
  margin: 0 auto 20px auto;
  &:hover {
    opacity: 0.5;
  }
`

const ScrollText = styled.p`
  color: ${getStyleForProp('heading')};
`

const BottomContent = styled.div`
  z-index: 1;
  margin: auto auto 0 auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const DownButton = styled.div`
  padding: 0 20px 20px 20px;
  @media only screen and (max-width: 1200px) {
    padding: 0 20px 10px 20px;
  }
`

const ScrollIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('heading')
}))``

const VideoContainer = styled.div`
  pointer-events: none;
  .react-player {
    height: 100% !important;
    overflow: hidden;
  }
  iframe {
    transform: scale(1.33);
    @media only screen and (min-width: 1800px) {
      transform: scale(2);
    }
    @media only screen and (max-width: 1200px) {
      transform: scale(2);
    }
    @media only screen and (max-width: 992px) {
      transform: scale(3);
    }
  }
`

const PlayIcon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('heading')
}))`
  transform: translateX(2px);
`

export default {
  Overlay,
  WmCustomContainer,
  StyledContainer,
  VideoContainer,
  HeaderContent,
  DownButton,
  BottomContent,
  Title,
  Paragraph,
  ScrollText,
  ScrollIcon,
  PlayButton,
  PlayIcon
}
