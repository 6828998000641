/* @flow */
import styled from 'styled-components'

import THEME from './Feature5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};

  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  > div:first-child {
    padding-top: 0;
  }
  > p:first-child {
    margin-top: 0;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  & > .control-container {
    width: 100%;
    margin-top: 30px;
    p {
      margin-top: 0;
    }
  }
`

const BlockItemWrap = styled.div`
  width: 320px;
  box-sizing: border-box;
  min-width: 260px;
  margin: 50px 20px 0 20px;
  @media only screen and (max-width: 850px) {
    width: 280px;
    margin: 40px 20px 0 20px;
  }
  @media only screen and (max-width: 650px) {
    margin: 40px 0 0 0;
  }

  & > .control-container {
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    @media only screen and (max-width: 850px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    & > .styled-controls {
      right: 0;
      left: auto;
    }
  }
`

const BlockListItem = styled.span``

const BlockList = styled.ul`
  list-style: none;
  padding-top: 26px;
  & + ${BlockListItem} {
    margin-top: 18px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  width: 100%;
`

const ListTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const ListItem = styled.li`
  color: ${getStyleForProp('ListItem')};
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 14px;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-top: 20px;
`

const BlocksWrap = styled.div`
  flex-wrap: wrap;
  max-width: ${({ blockCount }) =>
    blockCount === 2 || blockCount === 4 ? '720px' : '1080px'};
  display: flex;
  width: 100%;
  margin-top: -50px;
  padding-top: 50px;
  justify-content: center;
  @media only screen and (max-width: 1250px) {
    max-width: 720px;
  }
  @media only screen and (max-width: 850px) {
    margin-top: -40px;
    padding-top: 40px;
  }
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
  .add-button-wrap {
    text-align: start;
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('icon')
}))`
  padding-right: 12px;
  min-width: 1.5rem;
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  BlockItemWrap,
  Description,
  ListTitle,
  ListItem,
  BlocksWrap,
  BlockList,
  BlockListItem,
  Icon
}
