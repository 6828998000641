/* @flow */
import React, { PureComponent } from 'react'
import ActionGetter from '../Actions'
import { LabelText } from './Form.util'
import DayPicker from 'react-day-picker'
import Timepicker from 'react-datepicker'
import { withDispatchContext, withEditingContext } from '@contexts'
//lib components
import Icon from '@website/common/components/Icon'
import { isPastDate } from '@website/common/utils'
import If from '@website/common/components/Conditional'
//style
import 'react-datepicker/dist/react-datepicker.css'
import 'react-day-picker/lib/style.css'
import S from './Form.styled'
import { EMPTY_FIELD_MESSAGE } from '../Contact5.consts'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'

class DateInputs extends PureComponent<void, void> {
  datePickerContainer: null
  datePicker: null
  state = {
    showDatePicker: false,
    showTimePicker: false,
    selectedDate: '',
    selectedTime: '',
    isDateFieldClicked: false,
    isTimeFieldClicked: false
  }

  componentDidMount() {
    window.addEventListener('click', this.onBackdropClick)
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.onBackdropClick)
  }

  onBackdropClick = (e: any) => {
    if (
      !this.datePickerContainer ||
      !this.datePicker ||
      this.datePickerContainer.contains(e.target) ||
      this.datePicker.contains(e.target)
    ) {
      return
    }
    this.setState({ showDatePicker: false })
  }

  toggleDatePicker = (): void => {
    this.setState(({ showDatePicker }) => ({
      showDatePicker: !showDatePicker
    }))
    this.setState(() => ({
      isDateFieldClicked: true
    }))
  }

  openTimePicker = (): void => {
    this.setState(() => ({
      showTimePicker: true,
      isTimeFieldClicked: true
    }))
  }

  closeTimePicker = (): void => {
    this.setState(() => ({
      showTimePicker: false
    }))
  }

  setSelectedDate = value => {
    const { setFormValue } = this.props
    if (isPastDate(value)) {
      return
    }
    this.setState({ selectedDate: value })
    setFormValue(value.toLocaleDateString(), 'date')
  }

  setTimeValue = time => {
    const { setFormValue } = this.props
    this.setState({ selectedTime: time })
    setFormValue(time.toLocaleTimeString(), 'time')
    this.setState(() => ({
      isTimeFieldClicked: true
    }))
  }

  render() {
    const { formData, data, isMessageVisible, componentDispatcher, isEditing } =
      this.props
    const { showDatePicker, selectedTime } = this.state
    const { dateLabel, timeLabel } = data
    const Actions = ActionGetter(componentDispatcher)
    const dateError =
      (!isEditing &&
        this.state.isDateFieldClicked &&
        this.state.selectedDate === '' &&
        !this.state.showDatePicker) ||
      (!isEditing && !formData.date && isMessageVisible)

    const timeError =
      (!isEditing &&
        this.state.isTimeFieldClicked &&
        this.state.selectedTime === '' &&
        !this.state.showTimePicker) ||
      (!isEditing && !formData.time && isMessageVisible)
    return (
      <S.DateContainer className="date-container">
        <S.DateInputContainer className="date-input-container">
          <LabelText
            text={dateLabel}
            label={'date'}
            isRequired={true}
            formAction={(...args) =>
              componentDispatcher(Actions.changeFormData(...args))
            }
            className="WM_GLOBAL_secondary-font"
          />
          <S.DateInput
            isError={dateError}
            ref={ref => {
              this.datePickerContainer = ref
            }}
            onClick={this.toggleDatePicker}
          >
            <span> {formData.date}</span>
            <Icon
              size="middle"
              name="icon-calendar"
              className="date-icon"
              from="rf"
            />
          </S.DateInput>
          <If
            condition={showDatePicker}
            then={() => (
              <S.ContactDatePicker
                className="contact5-datePicker"
                ref={ref => {
                  this.datePicker = ref
                }}
              >
                <DayPicker
                  onDayClick={this.setSelectedDate}
                  selectedDays={this.state.selectedDate}
                  disabledDays={{ before: new Date() }}
                />
              </S.ContactDatePicker>
            )}
          />
          {dateError && (
            <S.ErrorMessage>
              {' '}
              <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
              {EMPTY_FIELD_MESSAGE}
            </S.ErrorMessage>
          )}
        </S.DateInputContainer>
        <S.TimeInputContainer className="time-input-container">
          <LabelText
            text={timeLabel}
            label={'time'}
            isRequired={true}
            formAction={(...args) =>
              componentDispatcher(Actions.changeFormData(...args))
            }
          />
          <S.TimeInput className="input-container" error={timeError}>
            <Timepicker
              selected={selectedTime}
              onChange={this.setTimeValue}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={15}
              onCalendarOpen={this.openTimePicker}
              onCalendarClose={this.closeTimePicker}
              dateFormat="h:mm aa"
            />
            <Icon
              size="middle"
              name="icon-time_outlined"
              className="date-icon"
            />
          </S.TimeInput>
          {timeError && (
            <S.ErrorMessage>
              {' '}
              <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
              {EMPTY_FIELD_MESSAGE}
            </S.ErrorMessage>
          )}
        </S.TimeInputContainer>
      </S.DateContainer>
    )
  }
}

export default withEditingContext(withDispatchContext(DateInputs))
