/* @flow */
export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 500,
  cardTitleMaxChar: 50,
  labelTitleMaxChar: 15,
  labelParagraphMaxChar: 30,
  optionMaxChar: 80,
  plans: {
    min: 1,
    max: 6,
    options: {
      min: 0,
      max: 10
    }
  }
}
