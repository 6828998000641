import { patchCookieSettingsAPI, assignPremiumPlanAPI } from '@editor/api'
import { getSiteId, getSiteData as selectSiteData } from '@selectors'
import {
  setHasChangesAfterPublish,
  triggerApiError,
  setSettingsSuccessMessage,
  getSiteData
} from '@actions/editor'
import { SUCCESS_SAVED_MESSAGE } from '@editor/conf/consts'

export const patchCookieSettings = data => async (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const siteData = selectSiteData(state)

  dispatch({
    type: 'SET_COOKIE_LOADING_STATUS',
    value: true
  })

  try {
    if (!siteData.paid && data.cookieSettings?.gdpr) {
      await assignPremiumPlanAPI(siteId)
      dispatch(getSiteData())
    }

    const cookiesData = await patchCookieSettingsAPI(siteId, data)

    dispatch({
      type: 'SET_COOKIE_SETTINGS',
      value: data.cookieSettings
    })
    dispatch(setHasChangesAfterPublish(data.hasChangesAfterPublish))
    dispatch({
      type: 'SET_COOKIE_LOADING_STATUS',
      value: false
    })
    dispatch(setSettingsSuccessMessage(SUCCESS_SAVED_MESSAGE))
    setTimeout(() => dispatch(setSettingsSuccessMessage('')), 5000)
  } catch (e) {
    dispatch(triggerApiError(e))
  }
}
