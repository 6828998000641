import produce from 'immer'

import { SEMRUSH_KEYWORD_SEARCH_INITIAL_DATA } from '../consts'

export default (state = SEMRUSH_KEYWORD_SEARCH_INITIAL_DATA, action) =>
  produce(state, draft => {
    switch (action.type) {
      case 'SET_SEMRUSH_KEYWORD_TABLE_FILTER_QUERIES':
        draft.queries = { ...state.queries, ...action.value }
        break
      case 'SET_SEMRUSH_LOGGED_IN_LOADING':
        draft.loginInfo.isLoading = action.value
        break
      case 'SET_SEMRUSH_LOGGED_IN_STATUS':
        draft.loginInfo.isLoggedIn = action.value
        break
      case 'SET_KEYWORDS_DATA_LOADING_STATE':
        draft.data.isLoading = action.value
        break
      case 'SET_KEYWORDS_DATA_PAGINATION_LOADING_STATE':
        draft.data.isPaginationLoading = action.value
        break
      case 'SET_KEYWORDS_DATA':
        draft.data.payload = action.value
        break
      case 'SET_PAGINATED_KEYWORDS_DATA':
        draft.data.payload.rows.push(...action.value.rows)
        break
      case 'SET_PAGINATED_KEYWORDS_OVERED_DATA_STATE':
        draft.data.isOvered = action.value
        break
      case 'SET_SEMRUSH_KEYWORDS_SEARCH_LIMIT_EXCEEDED_STATE':
        draft.data.hasLimitExceeded = action.value
        break
      case 'RESET_SEMRUSH_STORE':
        return SEMRUSH_KEYWORD_SEARCH_INITIAL_DATA
        break
      default:
        return draft
    }
  })
