/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addButton
} from '@website/components/CommonActions'
import {
  imageModalActionMaker,
  buttonModalActionMaker
} from '@website/components/ActionMakers'

export default dispatch => {

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeFooterTitle = (newFooterTitle: string) => {
    dispatch({
      type: 'CHANGE_FOOTER_TITLE',
      value: newFooterTitle
    })
  }

  const changeFooterTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_FOOTER_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeFooterParagraph = (newFooterParagraph: string) => {
    dispatch({
      type: 'CHANGE_FOOTER_PARAGRAPH',
      value: newFooterParagraph
    })
  }

  const changeFooterParagraphAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_FOOTER_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeFooterParagraph,
    changeFooterParagraphAlignment,
    changeFooterTitle,
    changeFooterTitleAlignment,
    getImageActions: imageModalActionMaker(dispatch),
    btnActions: buttonModalActionMaker(dispatch),
    addButton: () => dispatch(addButton())
  }
}
