/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const changeParagraph2 = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH2',
      value: newParagraph
    })
  }

  const changeParagraph3 = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH3',
      value: newParagraph
    })
  }

  const changeParagraph4 = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH4',
      value: newParagraph
    })
  }

  const changeSlogan = (newSlogan: string) => {
    dispatch({
      type: 'CHANGE_SLOGAN',
      value: newSlogan
    })
  }

  const changeName = (newName: string) => {
    dispatch({
      type: 'CHANGE_NAME',
      value: newName
    })
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeNameAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: alignment
    })
  }

  const changeSloganAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SLOGAN_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraph2Alignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH2_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraph3Alignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH3_ALIGNMENT',
      value: alignment
    })
  }
  const changeParagraph4Alignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH4_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeParagraph2,
    changeParagraph3,
    changeParagraph4,
    changeSlogan,
    changeName,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNameAlignment,
    changeSloganAlignment,
    changeParagraph2Alignment,
    changeParagraph3Alignment,
    changeParagraph4Alignment,
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatch)({
        keyForSizes: 'team7',
        showRemove: false,
        imageUrl
      })
  }
}
