/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PayPal4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'
import { secondary } from '@website/common/styles/colors'

const getStyleForProp = styleProvider(THEME)

const WMCustomContainer = styled(WMContainer)`
  max-width: 1200px;
  flex-direction: column;
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    & > p,
    & > h1 {
      margin-bottom: 0;
    }
  }
`

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
          @media only screen and (max-width: 700px) {
            padding-top: 70px;
          }
        `
      : css``};
`

const ContentConainer = styled.div`
  display: flex;
  align-items: center;
`

const Title = styled.h1`
  color: ${getStyleForProp('title')};
  ${getTextStyleByAlignment};
  margin-bottom: 0px;
  & + p {
    margin-top: 20px;
  }
`

const PriceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
  @media only screen and (max-width: 768px) {
    column-gap: 7px;
    min-height: auto;
  }
  @media only screen and (max-width: 457px) {
    padding-top: 0;
  }
`
const PriceCount = styled.p`
  margin-bottom: 0px;
  opacity: 1;
  display: flex;
  width: 100%;
`

const IconTitileContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  column-gap: 15px;
  margin-bottom: ${({ isEditing }) => (isEditing ? '25px' : '10px')};
  min-height: 26px;
  .control-container {
    margin-bottom: 0;
  }
  & + div {
    margin-top: 20px;
  }
`

const PrevPriceCount = styled.p`
  text-decoration: line-through;
  margin-bottom: 0 !important;
  margin-top: 5px;
  opacity: 1;
  width: 100%;
`

const PayButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  column-gap: 30px;
  row-gap: 30px;
  width: 100%;
  margin-top: auto;
  & form {
    width: 100%;
    & > button {
      margin: 0 !important;
      width: 100%;
    }
  }
  & .add-button-wrap > div {
    margin-top: 0 !important;
  }
  .link-section > .control-container {
    margin-bottom: 0;
  }
  ${({ isPrimary }) =>
    isPrimary
      ? css`
          .link-wrapper {
            color: ${getStyleForProp('primaryText')};
            svg {
              fill: ${getStyleForProp('primaryText')};
            }
          }
        `
      : css`
          .link-wrapper {
            color: ${getStyleForProp('link')};
            svg {
              fill: ${getStyleForProp('link')};
            }
          }
        `}
`
const SupParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-bottom: 10px;
`

const SubParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`
const IconContainer = styled.div`
  margin-bottom: ${({ hasBlocksIcon }) => (hasBlocksIcon ? '0px' : '0')};
  &:empty {
    display: none;
  }
`

const PayPalItemCardWrapp = styled.article`
  width: 100%;
  max-width: 300px;
  ${({ isEditing }) => (isEditing ? 'height: 100%;' : '')}
  ${({ isPrimary }) =>
    !isPrimary
      ? css`
          padding-top: 20px;
          @media only screen and (max-width: 457px) {
            padding-top: 0px;
          }
        `
      : 'padding-top: 0px; '}
`

const PayPalItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: ${props => (props.isFourBlock ? '700px' : 'initial')};
  column-gap: 30px;
  row-gap: 30px;
  margin-top: 50px;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    justify-content: center;
    row-gap: 20px;
    margin-top: 40px;
  }
  & > .control-container {
    max-width: 300px;
    width: 100%;
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          .add-button-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -13px;
            margin-top: 0px;
            margin-bottom: 0px;
            & > div {
              margin-top: 0 !important;
            }
          }
        `
      : css`
          .add-button-wrap {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -13px;
            margin-top: -13px;
            margin-bottom: -13px;
            & > div {
              margin-top: 0 !important;
            }
          }
        `};
  & > .add-button-wrap {
    min-height: 200px;
  }
`

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getStyleForProp('iconText')};
  & > div {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      width: 28px;
      height: 28px;
    }
  }
`

const PayPalItemCard = styled.div`
  height: 100%;
  box-sizing: border-box;
  border: 1px solid
    ${({ theme }) =>
      `rgba(${hexToRgb(getStyleForProp('border')({ theme }))}, 0.15)`};
  box-shadow: 0 6px 12px
    ${({ theme }) =>
      `rgba(${hexToRgb(getStyleForProp('border')({ theme }))}, 0.15)`};
  border-radius: ${getStyleForProp('cardRadius')};
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
  ${({ isEditing }) => (isEditing ? 'height: 100%' : '')};
  ${({ isPrimary }) =>
    isPrimary
      ? css`
          background-color: ${getStyleForProp('primaryCard')};
          padding: 32px 30px 50px 30px;
          color: ${getStyleForProp('primaryText')};

          .control-container:not(.warning-near):not(.warning-max) {
            &:focus {
              outline-color: ${secondary.Dark};
            }
          }
          .control-container:hover {
            box-shadow: 0px 0px 0px 1px ${secondary.Dark};
          }
          .light-content:not(.warning-near):not(.warning-max) {
            &:focus {
              outline-color: ${secondary.Dark};
            }
          }
          .light-content:hover {
            box-shadow: 0px 0px 0px 1px ${secondary.Dark};
          }

          ${Circle} svg {
            fill: ${getStyleForProp('iconPrimary')};
          }
        `
      : css`
          background-color: ${getStyleForProp('cardColor')};
          padding: 32px 30px 50px 30px;
          color: ${getStyleForProp('paragraph')};
        `};
  @media only screen and (max-width: 457px) {
    padding-bottom: 40px;
  }
`

const ItemDescription = styled.p`
  width: 100%;
  margin-bottom: 10px;
  ${getTextStyleByAlignment};
  & + ul {
    margin-top: 20px;
  }
`
const ItemName = styled.p`
  width: 100%;
  ${getTextStyleByAlignment};
  ${({ isEditing }) =>
    !isEditing &&
    css`
      & + div {
        margin-top: 30px;
      }
    `}

  & + ul {
    margin-top: 30px;
  }
`

const ItemTitle = styled.h2`
  margin-bottom: 0px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const BlockListItem = styled.span`
  display: block;
`

const BlockList = styled.ul`
  list-style: none;
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;
  @media only screen and (max-width: 457px) {
    padding-top: 0;
  }
  & + ${BlockListItem} {
    margin-top: 18px;
  }
  & > .control-container {
    padding: 20px 40px;
    margin-bottom: 0;
    width: calc(100% + 60px);
    margin-left: -30px;
    box-sizing: border-box;
  }
`

const ListItem = styled.li`
  color: ${getStyleForProp('ListItem')};
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 25px;
  & > .control-container {
    margin-bottom: 0;
  }
  & > div:nth-child(1) {

    ${({ theme: { look } }) =>
      look === 'CORNERED' || look === 'ROUNDED'
        ? css`
            background-image: linear-gradient(
              ${({ theme }) => theme.palette.primaryLight},
              ${({ theme }) => theme.palette.primaryDark}
            );
          `
        : css`
            background-color: ${getStyleForProp('icon')};
          `};
    border-radius: ${({ theme: { look } }) =>
      look === 'CORNERED' ? '5px' : look === 'ROUNDED' ? '50%' : '0'};
    padding: 1px 2px 1px 2px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 15px;
    box-sizing: border-box;
    & > svg {
      fill: #ffffff;
    }
  }
 
  & ${({ isPrimary }) =>
    isPrimary
      ? css`
          span {
            color: ${getStyleForProp('primaryText')};
          }
          & > div:nth-child(1) {
            ${({ theme: { look } }) =>
              look === 'CORNERED'
                ? ''
                : look === 'ROUNDED'
                ? ''
                : css`
                    background-color: ${getStyleForProp('iconPrimary')};
                  `}
        `
      : css`
          span {
            color: ${getStyleForProp('paragraph')};
          }
        `}
  }

  &:nth-child(1) {
    margin-top: 0;
  }
`

const IconWrapper = styled.div`
  user-select: none;
`

const Icon = styled(LibIcon).attrs(props => ({
  color: getStyleForProp('icon')(props)
}))``

export default {
  StyledContainer,
  WMCustomContainer,
  ContentConainer,
  SupParagraph,
  SubParagraph,
  PayButtonsContainer,
  PayPalItemWrap,
  PayPalItemCard,
  PayPalItemCardWrapp,
  IconTitileContainer,
  PriceContainer,
  PriceCount,
  PrevPriceCount,
  ItemTitle,
  Title,
  BlockListItem,
  BlockList,
  ListItem,
  ItemDescription,
  ItemName,
  IconContainer,
  IconWrapper,
  Circle,
  Icon
}
