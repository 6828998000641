/* @flow */
import {
  changeTitle,
  changeBlockTitle,
  addBlock,
  changeTitleAlignment
} from '@website/components/CommonActions'
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeBlockTitle = (newTitle, idx) => {
    dispatcher(changeBlockTitle(newTitle, idx))
  }

  const changeSubBlockTitle = (idx, subBlockIdx, newSubBlockTitle) => {
    dispatcher({
      type: 'CHANGE_SUB_BLOCK_TITLE',
      value: { idx, subBlockIdx, newSubBlockTitle }
    })
  }

  const changeSubBlockParagraph = (idx, subBlockIdx, newSubBlockParagraph) => {
    dispatcher({
      type: 'CHANGE_SUB_BLOCK_PARAGRAPH',
      value: { idx, subBlockIdx, newSubBlockParagraph }
    })
  }

  const addTextBlock = (idx, subBlockIdx) => {
    dispatcher({
      type: 'ADD_TEXT_BLOCK',
      value: { idx, subBlockIdx }
    })
  }

  const addListItem = (idx, subBlockIdx) => {
    dispatcher({
      type: 'ADD_LIST_ITEM',
      value: { idx, subBlockIdx }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const removeSubBlockItem = (activeIdx: number, subBlockIdx: number) => {
    dispatcher({
      type: 'REMOVE_SUB_BLOCK_ITEM',
      value: { activeIdx, subBlockIdx }
    })
  }

  const subBlockItemActions = (activeIdx, subBlockIdx, showRemove) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeSubBlockItem(activeIdx, subBlockIdx)),
      toShow: showRemove
    }
  ]

  const changeListItem = (idx, subBlockIdx, listItemIdx, newListItem) => {
    dispatcher({
      type: 'CHANGE_LIST_ITEM',
      value: { idx, subBlockIdx, listItemIdx, newListItem }
    })
  }

  const removeListItem = (idx, subBlockIdx, listItemIdx) => {
    dispatcher({
      type: 'REMOVE_LIST_ITEM',
      value: { idx, subBlockIdx, listItemIdx }
    })
  }

  const listItemActions = (idx, subBlockIdx, listItemIdx) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeListItem(idx, subBlockIdx, listItemIdx)),
      toShow: true
    }
  ]

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeSubBlockTitleAlignment = (
    idx: number,
    subBlockIdx: number,
    alignment: string
  ) => {
    dispatcher({
      type: 'CHANGE_SUB_BLOCK_TITLE_ALIGNMENT',
      value: { idx, subBlockIdx, alignment }
    })
  }

  const changeSubBlockParagraphAlignment = (
    idx: number,
    subBlockIdx: number,
    alignment: string
  ) => {
    dispatcher({
      type: 'CHANGE_SUB_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { idx, subBlockIdx, alignment }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeBlockTitle: _changeBlockTitle,
    changeSubBlockTitle,
    changeSubBlockParagraph,
    addTextBlock,
    addListItem,
    changeListItem,
    listItemActions,
    blockActions: removeBlockActionMaker(dispatcher),
    addBlock: _addBlock,
    subBlockItemActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeSubBlockTitleAlignment,
    changeSubBlockParagraphAlignment
  }
}
