import styled from 'styled-components'
import WarnIcon from '@editor/common/assets/svgr-icons/Attention_icon_red.svg'

import LibPopup from '@renderforest/rf-ui-library/organism/Popup'
import LibButton from '@renderforest/rf-ui-library/atoms/Button'
import { globalColors } from '@renderforest/rf-ui-library/consts/colors'
const TEXT_COLOR = globalColors.secondaryDark

export const NoLongerPremiumPopup = styled(LibPopup)`
  z-index: 1000;
  & > div {
    padding: 0;
    max-height: 96%;
    max-width: 578px;
    @media only screen and (max-width: 767px) {
      width: 90vw;
    }
    & > div {
      padding: 0;
    }
  }
`

export const PopupContent = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  @media only screen and (max-width: 767px) {
    padding: 30px 15px;
  }
`

export const Heading = styled.h1`
  color: ${TEXT_COLOR};
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin: 30px auto 20px;
  text-align: center;
  max-width: 460px;
`

export const SecondaryInfoText = styled.p`
  color: ${TEXT_COLOR};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  max-width: 460px;
  font-weight: 400;
`

export const Button = styled(LibButton)`
  padding: 15px 40px;
  margin: 0 15px;
  border-radius: 5px;
  background: linear-gradient(180deg, #8c83f7 0%, #4136c1 100%);
  box-shadow: 0px 6px 12px rgb(118 109 232 / 50%);
  font-weight: 600;

  &:hover {
    background: linear-gradient(180deg, #b5afff 0%, #9b94fc 100%);
    box-shadow: 0px 6px 24px rgba(118, 109, 232, 0.5);
  }
`

export const LinkButton = styled.button`
  padding: 15px;
  background: transparent;
  border: 0;
  display: flex;
  color: #4136c1;
  font-size: 14px;
  align-items: center;
  font-weight: 600;
  position: relative;
  margin: 0 15px;

  &:hover {
    cursor: pointer;
    background: #e2e0fd;
    border-radius: 5px;
  }
`

export const TextBold = styled.span`
  font-weight: 600;
`

export const AttentionIcon = styled.img``

export const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  @media only screen and (max-width: 480px) {
    flex-direction: column-reverse;
  }
  @media only screen and (min-width: 767px) and (max-width: 900px) {
    flex-direction: column-reverse;
  }
`

export const WarningIcon = styled(WarnIcon)`
  & * {
    fill: #e79e00;
  }
  flex-shrink: 0;
  width: 18px;
  margin-top: 3px;
  margin-right: 10px;
`

export const Note = styled.div`
  display: flex;
  margin-top: 10px;
  background: #f8bc001a;
  border: 1px solid #252e481a;
  padding: 10px 15px;
  border-radius: 5px;
`
export const NoteText = styled.div`
  font-size: 14px;
  line-height: 17px;
  max-width: 400px;
  color: #545f7e;
`
