export const validation = {
  headingMaxChar: 100,
  paragraphMaxChar: 200,
  labelMaxChar: 65,
  blockParagraphMaxChar: 50,
  name: { required: true },
  email: { required: true },
  message: { required: true },
  blocks: {
    min: 0,
    max: 2
  }
}

export const EMPTY_FIELD_MESSAGE = 'This field cannot be empty'
export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email'
export const SUCCESS_MESSAGE = 'Thank you for subscribing!'

export const NAME = 'name'
export const EMAIL = 'email'
export const MESSAGE = 'message'

export const defaultPlaceholders = {
  name: 'Your name name',
  email: 'Your email here',
  message: 'Your message here'
}