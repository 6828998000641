/* @flow */
import meta from '../Feature12.meta'
import {
  changeTitle,
  changeParagraph,
  addRow
} from '@website/components/CommonActions'
import {
  iconActionMaker,
  imageModalActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'

export default dispatcher => {
  const changeBlockParagraph = (
    paragraph: string,
    rowId: string,
    blockIdx
  ) => ({
    type: 'CHANGE_BLOCK_PARAGRAPH',
    value: { paragraph, rowId, blockIdx }
  })

  const changeBlockTitle = (
    newTitle: string,
    rowId: string,
    itemIdx: number
  ) => ({
    type: 'CHANGE_BLOCK_TITLE',
    value: { newTitle, rowId, itemIdx }
  })

  const changeIconData = (value: Object) => ({
    type: 'CHANGE_ICON',
    value
  })
  const removeIcon = (value: Object) => () => ({
    type: 'REMOVE_ICON',
    value
  })
  const changeLinkData = value => ({
    type: 'CHANGE_LINK_DATA',
    value
  })
  const removeLink = value => () => ({
    type: 'REMOVE_LINK',
    value
  })

  const addLink = (rowId: string, blockIdx: number) => ({
    type: 'ADD_LINK',
    value: { rowId, blockIdx }
  })
  const addIcon = (rowId: string, blockIdx: number) => ({
    type: 'ADD_ICON',
    value: { rowId, blockIdx }
  })

  const changeBgUrlAction = (value: Object, undoable: boolean) => ({
    type: 'CHANGE_BG_IMG',
    value,
    undoable
  })

  const removeItemImgUrl = (rowId: string, itemIdx: number) => () => ({
    type: 'REMOVE_BG_IMG',
    value: { rowId, itemIdx }
  })

  const bgActions = (newUrl: string, rowId: number, itemIdx: number) => [
    ...imageModalActionMaker(dispatcher)({
      keyForSizes: 'feature12',
      showRemove: newUrl,
      actions: {
        change: ({ url, dimensions, undoable }) =>
          dispatcher(
            changeBgUrlAction({ url, dimensions, rowId, itemIdx }, undoable)
          ),
        remove: removeItemImgUrl(rowId, itemIdx)
      },
      imageUrl: newUrl
    })
  ]

  const linkActions = (rowId: number, blockIdx: number, linkData) => [
    ...linkActionMaker(dispatcher)({
      idx: blockIdx,
      linkData,
      actions: {
        change: (_, linkData) => changeLinkData({ rowId, blockIdx, linkData }),
        remove: removeLink({ rowId, blockIdx })
      }
    })
  ]

  return {
    addRow,
    addLink,
    addIcon,
    bgActions,
    changeTitle,
    linkActions,
    changeBlockTitle,
    changeParagraph,
    changeBlockParagraph,
    iconActions: (rowId: string, itemIdx: number) =>
      iconActionMaker(dispatcher)({
        actions: {
          change: (idx, iconName) =>
            changeIconData({ rowId, itemIdx, iconName }),
          remove: removeIcon({ rowId, itemIdx })
        }
      })
  }
}
