// @flow

import React from 'react'
import { translate } from '@editor/common/utils/translations'

import { ActionButtonsContainer as StyledActionButtonsContainer } from './styled'
import * as Styled from './styled'

const FormActionButtons = ({ onCancel, onSave }) => (
  <StyledActionButtonsContainer>
    <Styled.CancelButton type="transparent" onClick={onCancel}>
      {translate('cancel_label')}
    </Styled.CancelButton>
    <Styled.SaveButton type="secondary" onClick={onSave}>
      {translate('save_label', true)}
    </Styled.SaveButton>
  </StyledActionButtonsContainer>
)

export default FormActionButtons
