/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps

  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'ADD_BLOCK':
        draft.slides.push(initialState.slides[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.slides.splice(idx, 1)
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.slides[idx].title = newTitle
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_ICON':
        {
          const { idx, iconName } = action.value
          draft.slides[idx].iconName = iconName
        }
        break
      case 'CHANGE_IMAGE':
        {
          const { idx, url, dimensions } = action.value
          draft.slides[idx].imgUrl = url
          draft.slides[idx].imgDimensions = dimensions
          draft.slides[idx].imgCropParams = {}
        }
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
      }
    }
  })
}
