import React from 'react'
import { withTheme } from 'styled-components'

const LargeQuote = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="230"
      height="180"
      viewBox="0 0 279.654 227.566"
    >
      <defs />
      <g className="a">
        <g transform="translate(0)">
          <path
            className="b"
            d="M178.869,145.091c-14.853,53.119-51.495,91.679-87.112,91.679a48.941,48.941,0,0,1-8.5-.741,5.647,5.647,0,0,1-4.474-7.094l7.332-26.226A5.718,5.718,0,0,1,91.8,198.58l.583.011c13.209,0,37.349-19.8,49.655-63.8.487-1.746.948-3.531,1.392-5.393a64.306,64.306,0,0,1-45.218,4.262,63.393,63.393,0,1,1,34.146-122.1c22.2,6.205,38.894,24.638,47,51.891C186.682,88.042,186.5,117.8,178.869,145.091Z"
            transform="translate(94.93 -9.204)"
          />
          <path
            className="b"
            d="M39.864,236.766a48.9,48.9,0,0,1-8.5-.736,5.652,5.652,0,0,1-4.474-7.094l7.335-26.225a5.682,5.682,0,0,1,5.688-4.129l.577.011c13.209,0,37.352-19.8,49.658-63.8.49-1.757.951-3.543,1.392-5.393a64.309,64.309,0,0,1-45.218,4.262,63.394,63.394,0,1,1,34.154-122.1c22.2,6.205,38.891,24.638,47,51.891,7.318,24.587,7.136,54.344-.5,81.642C112.126,198.212,75.49,236.766,39.864,236.766Z"
            transform="translate(0 -9.206)"
          />
        </g>
      </g>
    </svg>
  )
}

export default withTheme(LargeQuote)
