import styled, { css } from 'styled-components'

import { secondary, white } from '@website/common/styles/colors'

export const TextCustomizationToolbar = styled.div`
  height: 42px;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid ${secondary.Light};
  background-color: ${white};
  box-shadow: 0 6px 12px 0 #3271e626;
  box-sizing: border-box;
  overflow: hidden;
  ${({ isTextFormatingHidden, alignment }) =>
    isTextFormatingHidden && !alignment
      ? css`
          display: none;
        `
      : css``}
  & > div {
    ${({ isTextFormatingHidden }) =>
      isTextFormatingHidden
        ? css`
            padding: 0;
            &:before,
            &:after {
              display: none;
            }
          `
        : css``}
    &:first-child {
      border-radius: 5px 0 0 5px;
    }

    &:last-child {
      border-radius: 0 5px 5px 0;
    }
  }
`

export const Item = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  & > svg {
    fill: ${({ isActive }) =>
      isActive ? secondary.ExtraDark : secondary.Normal};
  }

  .Gradient_icon {
    background-image: ${({ theme }) =>
      `linear-gradient(90deg, ${theme.palette.primaryDark}, ${theme.palette.primaryLight})`};
    width: 20px;
    height: 20px;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #ffffff;
  }

  ${({ isDisabled }) =>
    isDisabled
      ? css`
          pointer-events: none;
          cursor: default;
          & > svg {
            fill: ${secondary.Normal};
          }
        `
      : ''}

  ${({ isActive }) =>
    !isActive
      ? css`
          & > svg {
            fill: ${secondary.Normal};
          }
        `
      : css`
          .Gradient_icon {
            box-shadow: 0px 0px 0px 1px #2a314b;
          }
        `}


  @media only screen and (min-width: 769px) {
    &:hover {
      & > svg {
        fill: ${secondary.ExtraDark};
      }
    }
  }
`
