/* @flow */
import styled from 'styled-components'

import THEME from './Header44.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  padding: 0;
  overflow: hidden;
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`
const WMCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  @media only screen and (max-width: 768px) {
    padding-top: ${({ topOffset }) => `${topOffset}px`};
  }
  @media only screen and (max-width: 457px) {
    padding-top: ${({ topOffset }) => `${topOffset}px`};
    padding-bottom: 60px;
  }
`

const HeaderContent = styled.div`
  position: relative;
  margin: 30px auto auto auto;
  width: 100%;
  max-width: 1160px;
  box-sizing: border-box;
  padding-bottom: 80px;
  @media only screen and (max-width: 1439px) and (min-width: 1360px) {
    transform: translateX(20px);
  }
  @media only screen and (max-width: 768px) {
    margin-top: 50px;
  }
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  font-weight: 700;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  ${({ alignment }) =>
    alignment === 'left'
      ? 'margin-right: auto;'
      : alignment === 'right'
      ? 'margin-left: auto;'
      : 'margin-left: auto; margin-right: auto;'};
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.5em;
  padding-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const ControlContainer = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${({ isThreeBlock }) => (isThreeBlock ? '32%' : '49.25%')};
  min-width: 376px;
  outline: none;
  margin-top: 15px;
  @media only screen and (min-width: 1361px) {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
  @media only screen and (max-width: 1360px) {
    width: 100%;
    max-width: 538px;
    min-width: 0;
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }
`
const ItemWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: white;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.101);
  border-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 1360px) {
    align-items: center;
  }
  .link-section {
    margin-top: 10px;
  }
  .learn-more-link {
    color: ${({ theme }) => theme.palette.primaryDark};
    svg {
      fill: ${({ theme }) => theme.palette.primaryDark};
    }
  }
`
const ItemImageWrapper = styled.div`
  width: 145px;
  min-width: 145px;
  border-radius: ${getStyleForProp('cardRadius')};
  padding: 10px 0 10px 10px;
  margin-bottom: auto;
`
const ItemContentWrapper = styled.div`
  display: flex;
  width: calc(100% - 145px);
  flex-direction: column;
  padding: 15px 20px 15px 20px;
  background: white;
  box-sizing: border-box;
  border-top-right-radius: ${getStyleForProp('cardRadius')};
  border-bottom-right-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 650px) {
    padding: 15px;
  }
  .add-button-wrap {
    text-align: start;
  }

  .control-container {
    margin-bottom: 10px;
    p {
      margin-top: 0;
    }
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  line-height: 1.5em;
  margin-top: 10px;
  ${getTextStyleByAlignment};
`

const BlocksWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: auto;
  padding: ${({ isEditing }) => (isEditing ? '35px 0 0 0' : '15px 0 0 0')};
  @media only screen and (max-width: 1360px) {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: ${({ isEditing }) => (isEditing ? '35px 0 0 0' : '25px 0 0 0')};
  }
`

const TopContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1360px) {
    flex-direction: column;
    align-items: center;
  }
`

const LeftContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46%;
  max-width: 538px;
  @media only screen and (max-width: 1360px) {
    width: 100%;
  }
`
const RightContent = styled.div`
  width: calc(54% - 50px);
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  .buttons-block {
    margin-left: -15px;
    align-items: flex-start;
  }
  .control-container {
    margin-bottom: 20px;
    width: 100%;

    h2 {
      padding-top: 0;
    }
  }
  @media only screen and (max-width: 1360px) {
    width: 100%;
    max-width: 618px;
    align-items: center;
    text-align: center;
    margin-left: initial;
    margin-top: 50px;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
    max-width: initial;
  }
  @media only screen and (max-width: 800px) {
    max-width: 100%;
  }
  .socials-block {
    display: inline-block;
    position: absolute;
    left: -90px;
    bottom: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media only screen and (max-width: 1439px) and (min-width: 1360px) {
      left: -70px;
    }
    @media only screen and (max-width: 1360px) {
      width: 90%;
      justify-content: center;
      right: unset;
      transform: unset;
      position: initial;
      box-sizing: border-box;
      flex-direction: row;
    }
    @media only screen and (min-width: 1361px) {
      .rf-add-btn {
        margin-left: initial;
      }
    }
    @media only screen and (max-width: 1360px) {
      .rf-add-btn {
        margin-top: 10px;
      }
    }
    .link {
      width: 36px;
      height: 36px;
      border-radius: ${getStyleForProp('iconRadius')};
      border: 1px solid ${getStyleForProp('socialBorder')};
      svg {
        fill: ${getStyleForProp('socialColor')};
      }
    }
    .socials-wrapper {
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      @media only screen and (max-width: 1360px) {
        flex-direction: row;
        justify-content: center;
        margin: ${({ isEditing }) => (isEditing ? '30px 0 0 0' : '40px 0 0 0')};
      }
    }
    .add-button-wrap {
      @media only screen and (max-width: 1360px) {
        margin-top: 15px;
      }
    }
  }
  > :first-child {
    padding-top: 0;
  }
`

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ImageControlContainer = styled.div`
  width: 100%;
  position: relative;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    height: initial;
  }
  &.main-image {
    padding-top: 75.8%;
    transform: translate(0.01%, -0.1%);
    @media only screen and (max-width: 1360px) {
      transform: translate(0.04%, -0.1%);
    }
    .lazy-figure {
      padding-top: 75.8%;
    }
  }
  &.block-image {
    padding-top: 143%;
    .lazy-figure {
      padding-top: 143%;
      overflow: hidden;
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  position: relative;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const FrameWrapper = styled.div`
  position: absolute;
  top: -4px;
  right: -4px;
  bottom: -4px;
  left: -4px;
  .st0 {
    fill: ${getStyleForProp('frame')};
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  HeaderContent,
  Title,
  Paragraph,
  ControlContainer,
  ItemWrapper,
  ItemContentWrapper,
  ItemImageWrapper,
  BlockTitle,
  BlockParagraph,
  BlocksWrap,
  TopContent,
  LeftContent,
  RightContent,
  BottomContent,
  ImageControlContainer,
  ImageWrapper,
  Img,
  FrameWrapper
}
