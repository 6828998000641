/* @flow */
import { videoModalActionMaker } from '@website/components/ActionMakers'
import { changeTitle, changeParagraph } from '@website/components/CommonActions'
export default dispatcher => {
  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const changeTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraphAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    videoActions: videoModalActionMaker(dispatcher),
    changeTitleAlignment,
    changeParagraphAlignment
  }
}
