/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text13.styled'
import { validation } from './Text13.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'

const Text13 = memo(props => {
  const dispatcher = useContext(DispatchContext)
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      additionalText,
      additionalUrl,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      withoutTexts={!isEditing && !(title || paragraph || additionalText)}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph, additionalText]}>
          <S.TextCompWrap>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleCount}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.maxParagraph}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <AdditionalLink
              index={null}
              additionalLinkText={additionalText}
              additionalLinkUrl={additionalUrl}
            />
          </S.TextCompWrap>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text13
