import styled from 'styled-components'
import LibButton from '@renderforest/rf-ui-library/atoms/Button'

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.9);
`

export const Text = styled.p`
  display: inline-block;
  text-align: center;
  font-size: 16px;
  color: #545f7e;
  margin-top: 20px;
  font-weight: 600;
  width: 280px;
  line-height: 20px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const LoadingCorn = styled.div`
  @keyframes cornLoading {
    0% {
      background-position-x: 0px;
    }
    100% {
      background-position-x: -16560px;
    }
  }
  width: 90px;
  height: 90px;
  background-image: url('https://static.rfstat.com/renderforest/images/v2/mockup-images/corn2.png');
  background-repeat: no-repeat;
  animation: cornLoading 4s steps(184) infinite;
  background-color: transparent;
`

export const StateImage = styled.img`
  animation: scale-image 700ms ease-out;
  @keyframes scale-image {
    0% {
      transform: scale(0.5);
    }
    90% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`

export const Button = styled.button`
  margin-top: 30px;
  display: flex;
  align-items: center;
  background: #eef5ff;
  border-radius: 5px;
  padding: 0 30px;
  height: 40px;
  color: #387dff;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
  &:hover {
    color: #ffffff;
    background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
      0% no-repeat padding-box;
    svg {
      fill: #ffffff;
    }
  }
`
