/* @flow */

export const validation = {
  headingMaxChar: 100,
  paragraphMaxChar: 300,
  blockTitleMaxChar: 100,
  blockParagraphMaxChar: 200,
  priceMaxChar: 7,
  blocks: {
    min: 1,
    max: 15,
    title: { required: true },
    price: { required: true }
  },
  buttons: {
    min: 0,
    max: 2
  }
}
