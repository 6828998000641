/* @flow */
/* eslint-disable max-statements */

import React, { useContext, useState, useEffect, memo } from 'react'
import {
  validation,
  EMPTY_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE
} from './Contact3.consts'
import S from './Contact3.styled'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { removeEmptyFields } from '@website/common/utils'
import SubmitButton from '@website/common/components/SubmitButton/SubmitButton'
import { isValidEmail } from '@website/common/utils'
import { defaultPlaceholders } from './consts'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'

import {
  DispatchContext,
  EditingContext,
  SiteHashContext,
  SiteIdContext
} from '@contexts'
import { contactApi } from '@website/common/api'

const initialState = {
  name: '',
  email: '',
  message: ''
}

const Contact3 = memo(
  ({
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttonText,
      placeholders,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const { isEditing } = useContext(EditingContext)
    const siteId = useContext(SiteIdContext)
    const siteHash = useContext(SiteHashContext)
    const Actions = ActionGetter(dispatcher)
    const [nameErrorMessage, setNameErrorMessage] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [isSending, setIsSending] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    const [inputData, setInputData] = useState(
      isEditing ? { ...placeholders } : initialState
    )
    const [timer, setTimer] = useState(null)

    useEffect(() => {
      if (timer) {
        setTimer(null)
      }
    })

    useEffect(() => {
      if (isEditing) {
        setInputData({ ...placeholders })
      }
    }, [placeholders])

    const handleEmailError = () => {
      if (!inputData.email) {
        setEmailErrorMessage(EMPTY_FIELD_MESSAGE)
      }
      if (inputData.email && !isValidEmail(inputData.email)) {
        setEmailErrorMessage(INVALID_EMAIL_MESSAGE)
      }
    }

    const handleNameError = () => {
      if (!inputData.name) {
        setNameErrorMessage(EMPTY_FIELD_MESSAGE)
      }
    }

    const evtHandlerGetter = (type: string) => {
      const evtHandlers = {
        onChange: (e: Event) => {
          setInputData({ ...inputData, [type]: e.target.value })
          if (type === 'name') {
            setNameErrorMessage('')
          }
          if (type === 'email') {
            setEmailErrorMessage('')
          }
        }
      }
      if (isEditing) {
        evtHandlers.onBlur = (e: Event) => {
          const value = e.target.value || defaultPlaceholders[type]
          if (value === placeholders[type]) {
            return
          }
          Actions.changePlaceHolderText(type, value)
          setInputData({ ...inputData, [type]: value })
        }
      } else {
        evtHandlers.onBlur = () => {
          if (type === 'name') {
            handleNameError()
          }
          if (type === 'email') {
            handleEmailError()
          }
        }
      }
      return evtHandlers
    }

    const sendData = () => {
      if (isEditing || isSending || isSuccess) {
        return
      }
      handleEmailError()
      handleNameError()
      if (inputData.email && isValidEmail(inputData.email) && inputData.name) {
        setIsSending(true)
        contactApi(
          siteId,
          removeEmptyFields({
            ...inputData,
            siteHash
          })
        )
          .then(() => {
            setIsSuccess(true)
            setTimer(
              setTimeout(() => {
                setInputData(initialState)
                setIsSuccess(false)
              }, 4000)
            )
          })
          .catch(console.warn)
          .finally(() => {
            setIsSending(false)
          })
      }
    }

    const getPlaceHolderText = key => (placeholders && placeholders[key]) || ''
    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
        isEditing={isEditing}
      >
        <S.WmCustomContainer>
          <Show when={[title, paragraph]}>
            <S.ContactTextContainer className="contact-text-container">
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  as={S.Title}
                  alignment={titleAlignment}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeTitle}
                  className="WM_GLOBAL_heading"
                  changeAlignment={Actions.changeTitleAlignment}
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  alignment={paragraphAlignment}
                  maxCount={validation.paragraphMaxChar}
                  onChange={Actions.changeParagraph}
                  className="WM_GLOBAL_paragraph"
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
            </S.ContactTextContainer>
          </Show>
          <S.FormContainer>
            <S.InputContainer>
              <Icon name="icon-asterisk" className="required-icon" />
              <S.Input
                value={inputData.name}
                placeholder={isEditing ? '' : getPlaceHolderText('name')}
                aria-label="Name input"
                className="WM_GLOBAL_secondary-font"
                {...evtHandlerGetter('name')}
                isError={nameErrorMessage}
                isEditing={isEditing}
              />
              {nameErrorMessage && (
                <S.ErrorMessage>
                  <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                  {nameErrorMessage}
                </S.ErrorMessage>
              )}
            </S.InputContainer>
            <S.InputContainer>
              <Icon name="icon-asterisk" className="required-icon" />
              <S.Input
                value={inputData.email}
                placeholder={isEditing ? '' : getPlaceHolderText('email')}
                aria-label="Email input"
                className="WM_GLOBAL_secondary-font"
                {...evtHandlerGetter('email')}
                isError={emailErrorMessage}
                isEditing={isEditing}
              />
              {emailErrorMessage && (
                <S.ErrorMessage>
                  <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                  {emailErrorMessage}
                </S.ErrorMessage>
              )}
            </S.InputContainer>
            <S.TextArea
              rows={8}
              value={inputData.message}
              placeholder={isEditing ? '' : getPlaceHolderText('message')}
              data-gramm_editor="false" // for grammarly extension
              aria-label="Message input"
              className="WM_GLOBAL_secondary-font"
              {...evtHandlerGetter('message')}
              isEditing={isEditing}
            />
            <SubmitButton
              buttonText={buttonText}
              submitFormData={sendData}
              showSubmitNotification={isSuccess}
            />
          </S.FormContainer>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Contact3
