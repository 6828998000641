/* @flow */

import { imageOptimizationAPI } from '@api/site'
import { isValidUrl } from '@website/common/utils'

function responseHandler({ data: { imageDimensions, publicUrls } }) {
  const imgDimensions = {
    small: publicUrls[0],
    medium: publicUrls[1],
    large: publicUrls[2],
    xlarge: publicUrls[3]
  }
  return {
    imgDimensions,
    imgSizes: imageDimensions
  }
}

const formatOptimizationDimensions = optimizationDimensions => {
  const needToFormat =
    optimizationDimensions[0] && typeof optimizationDimensions[0] === 'number'

  return needToFormat
    ? optimizationDimensions.map(dimension => ({
        width: dimension
      }))
    : optimizationDimensions
}

export const optimizeImage = (
  url,
  optimizationDimensions,
  siteId,
  withImgSizes
) => {
  if (!isValidUrl(url)) {
    return Promise.reject('Invalid image URL')
  }

  const imgSizes = formatOptimizationDimensions(optimizationDimensions)

  const body = {
    imgUrl: url,
    imgSizes,
    isDimensionsRequired: withImgSizes
  }

  return imageOptimizationAPI(siteId, body)
    .then(responseHandler)
    .catch(() => Promise.reject("Can't optimize image"))
}

export const sendBrazeProjectCreationEvent = (eventName, data, options) => {
  const { siteId, createdAt } = options
  const { templateCategories, name: templateName, id: templateId } = data

  const eventData = {
    Site_type: templateCategories ? 'Template' : 'Scratch',
    Project_id: siteId,
    createdAt
  }

  if (templateCategories) {
    eventData.Template_category = templateCategories
    eventData.Template_name = templateName
    eventData.Template_id = templateId
  }

  window.sendBrazeCustomEvent &&
    window.sendBrazeCustomEvent({
      eventName,
      eventData
    })
}
