/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Steps8.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import Container from '@website/common/components/Container'
import { hexToRgb } from '@website/common/utils'
import { secondary } from '@website/common/styles/colors'

const stylesByAlignment = {
  default: '',
  left: css`
    text-align: start;
  `,
  center: css`
    text-align: center;
  `,
  right: css`
    text-align: end;
  `
}

const getStyleByAlignment = ({ alignment }) => stylesByAlignment[alignment]

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding-bottom: 110px;
  @media only screen and (max-width: 768px) {
    padding-bottom: 110px;
  }
  @media only screen and (max-width: 650px) {
    padding-bottom: 90px;
  }
  .add-button-wrap {
    margin-top: 20px;
  }
  @media only screen and (min-width: 769px) {
    ${({ isEditing }) =>
      isEditing &&
      css`
        padding-top: 150px;
      `};
  }
`

const Headings = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 90%;
  max-width: 830px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
  > p:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getStyleByAlignment};
`

const Paragraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  padding-top: 20px;
  ${getStyleByAlignment};
`

const StepsContainer = styled.div`
  max-width: 830px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  margin: 50px auto 0 auto;
  position: relative;
  box-sizing: border-box;
  @media only screen and (max-width: 992px) {
    margin-top: 40px;
    padding-left: 95px;
  }
  @media only screen and (max-width: 650px) {
    padding-left: 75px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 60px;
  }

  &:after {
    content: '';
    position: absolute;
    left: 5px;
    width: 10px;
    border-radius: ${getStyleForProp('borderRadius')};
    background-color: ${getStyleForProp('line')};
    top: 0;
    bottom: -30px;
    @media only screen and (max-width: 992px) {
      left: 20px;
    }
    @media only screen and (max-width: 457px) {
      left: 15px;
    }
  }
`

const StepItemContainer = styled.div`
  width: 100%;
  max-width: 750px;
  position: relative;
  margin-top: 30px;
  &:after {
    content: '';
    width: 25px;
    height: 25px;
    background-color: #fff;
    position: absolute;
    transform: rotate(45deg);
    left: -12.5px;
    border-bottom: 1px solid ${getStyleForProp('border')};
    border-left: 1px solid ${getStyleForProp('border')};
    top: 45px;
    @media only screen and (max-width: 450px) {
      width: 20px;
      height: 20px;
      left: -10.5px;
    }
  }
  &:before {
    content: '';
    background-color: #fff;
    position: absolute;
    z-index: 1;
    width: 30px;
    height: 38px;
    top: 40px;
    left: 1px;
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
  .control-container {
    width: 100%;
  }
`

const StepItem = styled.div`
  min-height: 115px;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: ${getStyleForProp('borderRadius')};
  box-shadow: ${({ theme }) =>
    theme.look !== 'SHARP' &&
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  ${({ theme }) => (theme.look !== 'SHARP' ? '4px' : '')};
  border: 1px solid ${getStyleForProp('border')};
  position: relative;
  @media only screen and (max-width: 450px) {
    padding: 15px;
  }
  > p:first-child,
  > div:first-child {
    margin-top: 0;
  }
  > .control-container {
    margin-top: 20px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const StepItemHeading = styled.div`
  display: flex;
  align-items: center;
  .add-button-wrap {
    margin-top: 0;
    margin-right: 20px;
    position: relative;
    z-index: 3;
  }
`

const StepTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 0;
  ${getStyleByAlignment};
  position: relative;
  z-index: 1;
`

const StepSubTitle = styled.p`
  color: ${getStyleForProp('blockSubTitle')};
  padding-top: 5px;
  ${getStyleByAlignment};
  position: relative;
  z-index: 1;
`

const StepParagraph = styled.p`
  color: ${getStyleForProp('blockContent')};
  position: relative;
  z-index: 3;
  margin-top: 10px;
  ${getStyleByAlignment};
`

const IconContainer = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  position: relative;
  z-index: 1;
  > div {
    width: 36px;
    height: 36px;
    svg {
      max-width: 36px;
      max-height: 36px;
      fill: ${getStyleForProp('icon')};
    }
  }
`

const IconWrap = styled.div`
  margin-right: 1rem;
  user-select: none;
  .control-container {
    position: relative;
    z-index: 3;
  }
`

const TitleWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  > p:first-child,
  > div:first-child {
    margin-top: 0;
  }

  @media only screen and (max-width: 450px) {
    & > .control-container {
      & > .styled-controls {
        right: 0;
        left: unset;
      }
    }
  }
`

const Circle = styled.div`
  position: absolute;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('iconRadius')};
  ${getStyleForProp('buttonBackground')};
  pointer-events: none;
  left: -95px;
  z-index: 3;
  top: 35px;
  font-weight: 800;
  font-size: 20px;
  @media only screen and (max-width: 650px) {
    left: -75px;
  }
  @media only screen and (max-width: 450px) {
    width: 40px;
    height: 40px;
    left: -60px;
  }
  ${({ isEditing, isOnScreen }) =>
    !isEditing &&
    isOnScreen &&
    css`
      animation: scale 700ms ease-out;
    `}
  @keyframes scale {
    0% {
      transform: scale(0.5);
    }
    90% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: getStyleForProp('icon')({ theme })
}))``

export default {
  StyledContainer,
  Headings,
  Title,
  Paragraph,
  StepsContainer,
  StepItemContainer,
  StepItem,
  StepItemHeading,
  StepTitle,
  StepSubTitle,
  StepParagraph,
  IconContainer,
  Paragraph,
  IconWrap,
  Icon,
  TitleWrap,
  Circle
}
