/* @flow */

import { removeBlockActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  changeBlockTitle,
  changeBlockParagraph
} from '@website/components/CommonActions'

import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const addBlock = (idx: number) => {
    dispatcher({
      type: 'ADD_BLOCK',
      value: { idx }
    })
  }

  const addListItem = (idx: number) => {
    dispatcher({
      type: 'ADD_LIST_ITEM',
      value: { idx }
    })
  }

  const removeListItem = (idx: number, listItemIdx: number) => {
    dispatcher({
      type: 'REMOVE_LIST_ITEM',
      value: { idx, listItemIdx }
    })
  }

  const listItemActions = (idx: number, listItemIdx: number) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeListItem(idx, listItemIdx)),
      toShow: true
    }
  ]

  const changeListItem = (
    newListItem: string,
    idx: number,
    listItemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_LIST_ITEM',
      value: { newListItem, idx, listItemIdx }
    })
  }

  const changeAdditionalBlockParagraph = (newParagraph: string) =>
    dispatcher({
      type: 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH',
      value: newParagraph
    })

  const _changeBlockTitle = (newBlockTitle, idx) => {
    dispatcher(changeBlockTitle(newBlockTitle, idx))
  }

  const _changeBlockParagraph = (newBlockParagraph, idx) => {
    dispatcher(changeBlockParagraph(newBlockParagraph, idx))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeBlockTitle: _changeBlockTitle,
    changeBlockParagraph: _changeBlockParagraph,
    changeAdditionalBlockParagraph,
    addBlock,
    addListItem,
    changeListItem,
    blockActions: removeBlockActionMaker(dispatcher),
    listItemActions,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment
  }
}
