// @flow
import React, { memo, useEffect } from 'react'

import * as Styled from './styled'
import AttentionIcon from '@editor/common/assets/attention-icon.svg'
import ProgressLoading from '@renderforest/rf-ui-library/molecules/ProgressLoading'
import { translate } from '@editor/common/utils/translations'

const UploadingContent = () => (
  <Styled.UploadingContent>
    <ProgressLoading
      progressLabel={translate('uploading_label')}
      withImg={false}
      color="white"
      percent={null}
      infiniteMode
    />
  </Styled.UploadingContent>
)

const FailedContent = () => (
  <Styled.FailedContent>
    <img src={AttentionIcon} alt="upload failed" />
    <span>{translate('upload_failed_label')}</span>
  </Styled.FailedContent>
)

const UploadStatusTooltip = ({ isOpen, closeTooltip, isLoading, failed }) => {
  useEffect(() => {
    if (failed && isOpen) {
      setTimeout(closeTooltip, 5000)
    }
  }, [failed, closeTooltip, isOpen])

  return isOpen ? (
    <Styled.UploadStatusTooltip>
      <Styled.Triangle />
      {isLoading ? <UploadingContent /> : null}
      {failed ? <FailedContent /> : null}
    </Styled.UploadStatusTooltip>
  ) : null
}

export default memo(UploadStatusTooltip)
