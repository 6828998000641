/* @flow */
import React, { useState, useContext, memo } from 'react'
import S from './Header31.styled'
import THEME from './Header31.theme'
import { validation } from './Header31.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import Socials from '@website/common/components/Socials'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'

const Header31 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      videoUrl,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      socials,
      logoWidth,
      logoDimensions,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const withoutTitle = !(title || logoSrc)

  return (
    <>
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
        isEditing={isEditing}
      >
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
          linksProps={{
            additionalContent:
              isEditing || socials.length !== 0
                ? () => (
                    <Socials
                      className="header-socials"
                      data={socials}
                      componentDispatcher={dispatcher}
                    />
                  )
                : null
          }}
        />
        <S.WmCustomContainer topOffset={navBarHeight}>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading42"
            as={S.Title}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <S.VideoControlWrapper withoutTitle={withoutTitle}>
            <ControlsWithModalOpener
              actions={Actions.videoActions(false, videoUrl)}
              style={{ width: '100%' }}
            >
              <S.VideoContent>
                <S.VideoWrap>
                  <Overlay />
                  <LazyVideoPlayer
                    style={{
                      top: 0,
                      left: 0,
                      position: 'absolute'
                    }}
                    controls
                    width="100%"
                    height="100%"
                    url={videoUrl}
                  />
                </S.VideoWrap>
              </S.VideoContent>
            </ControlsWithModalOpener>
          </S.VideoControlWrapper>
        </S.WmCustomContainer>
      </S.StyledContainer>
      <S.BottomBlock />
    </>
  )
})

Header31.contextType = DispatchContext

export default Header31
