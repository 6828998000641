/* @flow */
import React, { useEffect, useContext, useMemo, memo, useRef } from 'react'
import S from './Header51.styled'
import THEME from './Header51.theme'
import ActionGetter from './Actions'
import { validation } from './Header51.consts'
import NavBar from '@website/common/components/NavBar'
import Socials from '@website/common/components/Socials'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import Buttons from '@website/common/components/Buttons'
import ImageItem from './ImageItem'

const Header51 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      images,
      buttons,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency,
      socials,
      isAnimated
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const containerRef = useRef(null)

  const handleMouseMove = event => {
    if (
      (!isEditing && !isAnimated) ||
      isEditing ||
      window.innerWidth <= 1024 ||
      window.scrollY > containerRef.current.offsetHeight
    )
      return

    const targetElement = containerRef.current
    const pointerClientX = event.clientX
    const pointerClientY = event.clientY
    const { left, top } = targetElement.getBoundingClientRect()

    const offsetX = pointerClientX - left
    const offsetY = pointerClientY - top

    const img1 = document.getElementById('header51_img_0')
    const img2 = document.getElementById('header51_img_1')
    const img3 = document.getElementById('header51_img_2')
    const img4 = document.getElementById('header51_img_3')
    const img5 = document.getElementById('header51_img_4')

    img1 &&
      (img1.style.transform = `translate(${offsetX / 64}%, ${
        offsetY / 96
      }%) translateZ(0)`)
    img2 &&
      (img2.style.transform = `translate(${offsetX / 88}%, ${
        offsetY / 84
      }%) translateZ(0)`)
    img3 &&
      (img3.style.transform = `translate(${offsetX / 180}%, ${
        offsetY / 118
      }%) translateZ(0)`)
    img4 &&
      (img4.style.transform = `translate(${offsetX / 270}%, ${
        offsetY / 120
      }%) translateZ(0)`)
    img5 &&
      (img5.style.transform = `translate(${offsetX / 200}%, ${
        offsetY / 92
      }%) translateZ(0)`)
  }

  useEffect(() => {
    containerRef.current &&
      containerRef.current.addEventListener('mousemove', handleMouseMove)
  }, [containerRef.current])

  const _imagesCount = useMemo(
    () => Object.values(images).filter(img => !!img.imageUrl).length,
    [images]
  )

  return (
    <S.StyledContainer
      isEditing={isEditing}
      ref={containerRef}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <NavBar
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || socials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={socials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.WmCustomContainer isEditing={isEditing} isLogo={logoSrc !== ''}>
        <S.WindowMoveContainer>
          <S.IconsWrapper>
            <S.IconsWrapperInner>
              {Object.keys(images).map((key, idx) => {
                const image = images[key]
                return (
                  <ImageItem
                    isEditing={isEditing}
                    isAnimated={isAnimated}
                    id={`header51_img_${idx}`}
                    className={`image_${idx + 1}`}
                    alt={`image_${idx + 1}`}
                    {...image}
                    addImage={() => Actions.addImage(key)}
                    imageActions={Actions.imageActions(
                      key,
                      images[key].imageUrl,
                      _imagesCount
                    )}
                  />
                )
              })}
            </S.IconsWrapperInner>
          </S.IconsWrapper>
          <S.HeaderContentContainer>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              required={!paragraph}
              maxCount={validation.titleMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Paragraph}
              alignment={paragraphAlignment}
              required={!title}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.HeaderContentContainer>
          <Buttons data={buttons} />
        </S.WindowMoveContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header51
