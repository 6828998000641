import styled, { css } from 'styled-components'

import Radio from '@renderforest/rf-ui-library/molecules/Radio'

export const PayPalButtons = styled.div`
  margin-bottom: 30px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`

export const RadioButton = styled(Radio)`
  flex-direction: unset;
  margin: 10px 0;

  & > label {
    margin-right: 70px;
  }

  .radio-button {
    margin: 0 10px 0 0;
    &:after {
      border: 1px solid ${({ isActive }) => (isActive ? '#387DFF' : '#8493BD')};
    }
  }

  .label-container {
    font-size: 14px;
    color: #252e48;
    font-weight: 500;
  }

  @media only screen and (max-width: 768px) {
    & > label {
      margin-right: 50px;
    }
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
    margin-top: 10px;
  }
`

export const PayPalButtonWrapper = styled.div`
  ${({ isActive }) =>
    isActive
      ? css`
          box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #3271e6;
        `
      : ''};
  border-radius: ${({ theme: { look } }) =>
    look === 'CORNERED' ? '10px' : look === 'ROUNDED' ? '22px' : '0'};

  &:not(:last-child) {
    margin-right: 20px;
  }

  & > button {
    margin: 0;
    height: 40px;
    line-height: 40px;
    padding-left: 25px;
    padding-right: 25px;

    &:not(:active) {
      box-shadow: none;
    }

    & > p {
      font-size: 12px;
      max-width: 75px;
      min-width: 63px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 400px) {
    &:not(:last-child) {
      margin-right: 0;
    }

    &:nth-child(odd) {
      margin-right: 20px;
    }
  }
`
