/* @flow */
/* eslint-disable max-statements */

import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_SUBTITLE':
        draft.subtitle = action.value
        break
      case 'CHANGE_BLOCK_TITLE':
        draft.content[action.value.idx].title = action.value.newTitle
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        draft.content[action.value.idx].paragraph = action.value.newParagraph
        break
      case 'ADD_BLOCK':
        draft.content.push(initialState.content[0])
        break
      case 'REMOVE_BLOCK':
        draft.content.splice(action.value.idx, 1)
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_SUBTITLE_ALIGNMENT': {
        draft.subtitleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.content[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.content[idx].paragraphAlignment = alignment
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
