/* @flow */
import { simpleButtonActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changePlaceHolderText = (key: string, value: string) => {
    dispatcher({
      type: 'CHANGE_PLACEHOLDER_TEXT',
      value: { key, value }
    })
  }

  const changeLabel = (key: string, newLabel: string) => {
    dispatcher({
      type: 'CHANGE_LABEL',
      value: { key, newLabel }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeLabel,
    changePlaceHolderText,
    getButtonActions: simpleButtonActionMaker(dispatcher)
  }
}
