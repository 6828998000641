/* @flow */
/* eslint-disable max-statements */
import {
  cropableImageModalActionMaker,
  socialActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addSocial,
  addIcon
} from '@website/components/CommonActions'

export default dispatch => {
  const changeSocialText = (newText: string) => ({
    type: 'CHANGE_SOCIAL_TEXT',
    value: newText
  })

  const _addSlide = () =>
    dispatch({
      type: 'ADD_SLIDE'
    })

  const _addIcon = idx => {
    dispatch(addIcon(idx))
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeSocialText = newText => {
    dispatch(changeSocialText(newText))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const _addSocial = () => dispatch(addSocial())

  const _removeSlide = idx => {
    dispatch({
      type: 'REMOVE_SLIDER_IMAGE',
      value: idx
    })
  }

  const sliderActions = ({
    idx,
    clickHandler,
    key,
    imageUrl,
    mediaFlyoutShift
  }) =>
    cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'header38SliderImages',
      clickHandlers: {
        change: clickHandler,
        crop: clickHandler
      },
      showRemove: false,
      imageUrl,
      mediaFlyoutShift,
      additionalArgs: {
        idx,
        key
      },
      actions: {
        change: ({ url, dimensions, undoable }) => ({
          type: 'CHANGE_SLIDER_IMAGE',
          value: { idx, url, dimensions, key },
          undoable
        }),
        crop: ({ imgCropParams, undoable }) => ({
          type: 'SET_SLIDER_IMG_CROP_PARAMS',
          value: { imgCropParams, idx, key },
          undoable
        })
      }
    })

  return {
    removeSlide: _removeSlide,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSocialText: _changeSocialText,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addIcon: _addIcon,
    addSlide: _addSlide,
    addSocial: _addSocial,
    sliderActions: sliderActions,
    linkActions: linkActionMaker(dispatch),
    socialActions: socialActionMaker(dispatch),
    imgActions: imageUrl =>
      cropableImageModalActionMaker(dispatch)({
        keyForSizes: 'Header38rightImage',
        showRemove: false,
        imageUrl
      })
  }
}
