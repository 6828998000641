/* @flow */
import styled from 'styled-components'

const Container = styled.div`
  word-break: break-word;
  padding: 80px 0;
  display: flex;
  overflow: hidden;
  @media only screen and (max-width: 457px) {
    padding: 60px 0;
  }
`

export default {
  Container
}

