/* @flow */
import React from 'react'
import { translate } from '@editor/common/utils/translations'

import { RfLoader } from '@editor/common/components/Loaders'

const SyncingLoader = () => {
  return (
    <RfLoader
      isVisible
      text={translate('saving_your_changes_label')}
      background="white"
    />
  )
}

export default SyncingLoader
