/* @flow */
import React, { useContext, useState, memo } from 'react'
import ReactPlayer from 'react-player'

import S from './Header20.styled'
import THEME from './Header20.theme'
import { validation } from './Header20.consts'
import VideoPopup from '@website/common/components/VideoPopup/VideoPopup'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import Socials from '@website/common/components/Socials'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext, MobileDetectContext } from '@contexts'
import {
  scrollBottom,
  isYoutubeUrl,
  getYoutubeThumbnail
} from '@website/common/utils'

const Header20 = memo(props => {
  const {
    data: {
      title,
      logoSrc,
      socials,
      logoColor,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoWidth,
      scrollText,
      logoDimensions,
      backgroundVideoUrl,
      bgOverlayTransparency
    },
    hasNextComponent
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const [showVideoPopup, setVideoPopup] = useState(false)
  const isMobile = useContext(MobileDetectContext)
  return (
    <S.StyledContainer
      backgroundImgUrl={getYoutubeThumbnail(backgroundVideoUrl)}
      windowHeight={typeof window !== 'undefined' ? window.innerHeight : null}
    >
      <>
        <S.Overlay
          bgOverlayTransparency={bgOverlayTransparency}
          isBgImgOrVideoExists={!!backgroundVideoUrl}
        />
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
          linksProps={{
            additionalContent:
              isEditing || socials.length !== 0
                ? () => (
                    <Socials
                      className="header-socials"
                      data={socials}
                      componentDispatcher={dispatcher}
                    />
                  )
                : null
          }}
        />
        <If
          condition={showVideoPopup}
          then={() => (
            <VideoPopup
              url={backgroundVideoUrl}
              closeVideoPopup={() => setVideoPopup(false)}
            />
          )}
        />
        <S.VideoContainer>
          <If
            condition={isMobile && isYoutubeUrl(backgroundVideoUrl)}
            otherwise={() => (
              <ReactPlayer
                muted
                loop
                playing={isMobile ? false : true}
                controls={false}
                style={{
                  right: '0',
                  bottom: '0',
                  minWidth: '100%',
                  minHeight: '100%',
                  height: '100%',
                  overflow: 'hidden',
                  position: 'absolute'
                }}
                url={backgroundVideoUrl}
                className="react-player"
              />
            )}
          />
        </S.VideoContainer>
        <S.WmCustomContainer topOffset={navBarHeight} isEditing={isEditing}>
          <S.HeaderContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                required={validation.title.required}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading54"
                as={S.Title}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <If
              condition={backgroundVideoUrl}
              then={() => (
                <S.PlayButton
                  role="button"
                  onClick={() => backgroundVideoUrl && setVideoPopup(true)}
                >
                  <S.PlayIcon name="icon-play-complexity-3" size="large" />
                </S.PlayButton>
              )}
            />
          </S.HeaderContent>
          <If
            condition={hasNextComponent}
            then={() => (
              <S.BottomContent
                isEditing={isEditing}
                onClick={() => scrollBottom(isEditing)}
              >
                <EditableContent
                  text={scrollText}
                  as={S.ScrollText}
                  maxCount={validation.scrollMaxChar}
                  className="WM_GLOBAL_paragraph18"
                  onChange={(newScrollText: string) => {
                    Actions.changeScrollText(newScrollText)
                  }}
                />

                <S.DownButton role="button">
                  <S.ScrollIcon size="xlarge" name="icon-arrow_small_bottom" />
                </S.DownButton>
              </S.BottomContent>
            )}
          />
        </S.WmCustomContainer>
      </>
    </S.StyledContainer>
  )
})

Header20.contextType = DispatchContext

export default Header20
