/* @flow */
import React, { useContext, memo } from 'react'
import S from './Video3.styled'
import { validation } from './Video3.consts'
import ActionGetter from './Actions'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import DotsSVG from '@website/common/components/Dots'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'

const Video3 = memo(
  ({
    data: { title, paragraph, videoUrl, titleAlignment, paragraphAlignment }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)

    return (
      <S.StyledContainer isEditing={isEditing}>
        <WMContainer>
          <Show when={[title, paragraph]}>
            <S.VideoContainer>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  as={S.Title}
                  maxCount={validation.maxTitleChar}
                  className="WM_GLOBAL_heading"
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  alignment={paragraphAlignment}
                  as={S.Description}
                  maxCount={validation.maxParagraphChar}
                  className="WM_GLOBAL_paragraph"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
            </S.VideoContainer>
          </Show>
          <S.VideosWrapper>
            <Mapper
              data={videoUrl}
              render={(video: string, idx: number) => (
                <S.VideoBlock key={idx}>
                  <S.Dots>
                    <DotsSVG />
                  </S.Dots>
                  <S.SquareBox />
                  <ControlsWithModalOpener
                    actions={Actions.videoActions(false, videoUrl[idx], idx)}
                    style={{ width: '100%' }}
                  >
                    <S.VideoContent>
                      <S.VideoWrap>
                        <Overlay />
                        <LazyVideoPlayer
                          key={video}
                          style={{
                            top: 0,
                            left: 0,
                            position: 'absolute'
                          }}
                          controls
                          url={video}
                          width="100%"
                          height="100%"
                        />
                      </S.VideoWrap>
                    </S.VideoContent>
                  </ControlsWithModalOpener>
                </S.VideoBlock>
              )}
            />
          </S.VideosWrapper>
        </WMContainer>
      </S.StyledContainer>
    )
  }
)

export default Video3
