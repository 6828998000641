import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const SearchDropdownWrapper = styled.div`
  position: relative;

  .countries-search-dropdown {
    width: 115px;
    border-radius: 5px;
    box-shadow: none;

    .dropdown-wrapper {
      border: 1px solid #c1c9e0;
      &:hover {
        border: 1px solid #8493bd;
      }
    }

    .dropdown-wrapper,
    .dropdown-wrapper-active {
      height: 40px;
      position: relative;
      left: -1px;
    }

    .search-dropdown-active-item {
      margin: 0;
      width: 67px;
      padding-left: 30px;
    }

    .arrow_filled_bottom {
      margin-top: 0;
      position: absolute;
      right: 10px;
      top: 7px;
      svg {
        width: 24px;
        height: 24px;
      }
    }

    .search-dropdown-options-wrapper {
      width: 200px;
      right: 0;
      left: unset;
      border: 1px solid #c1c9e0;
      border-radius: 5px;
      box-shadow: 0 2px 10px 0 #3271e626;
      padding: 0;

      .react-window-wrapper {
        padding: 0;

        .search-dropdown-list-item {
          padding: 0;
          border-radius: 0;
        }
      }
    }

    &:hover {
      box-shadow: none;
    }
  }
`

export const DropdownItem = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: ${secondary.SemiDark};

  & > p {
    max-width: 130px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & > img {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    border: 1px solid #dee3f0;
    box-sizing: unset;
  }

  &:hover {
    color: ${secondary.ExtraDark};
  }
`

export const ActiveCountryFlag = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 9px;
  left: 12px;
  z-index: 1;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #dee3f0;
`
