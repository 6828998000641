export const validation = {
  maxTitleChar: 100,
  maxSlideContentChar: 600,
  maxTooltipChar: 100,
  maxSliderTitle: 50,
  slides: {
    min: 2,
    max: 7
  }
}

export const sliderSettings = {
  dots: false,
  loop: false,
  arrows: false,
  autoplaySpeed: 5000,
  infinite: false,
  slidesToShow: 7,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        autoplaySpeed: 0,
        speed: 0
      }
    }
  ]
}
