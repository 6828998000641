/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature34.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgbA,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const getStyleByAlignment = ({ alignment }) => stylesByAlignment[alignment]

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  width: 100%;
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '140px 0 180px 0' : '80px 0')};
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
  > :first-child {
    margin-top: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  justify-content: space-between;
  width: 100%;
  max-width: 1560px;
  align-items: center;
  padding-top: 0;
  margin-top: 50px;
  @media only screen and (min-width: 1025px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
  }
`

const TextImageContent = styled.div`
  width: 100%;
  max-width: 570px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 1024px) {
    ${({ theme }) =>
    theme.orientation === 'LEFT'
      ? css`
            margin-top: 40px;
          `
      : css`
            margin-bottom: 40px;
          `}
  }
  svg {
    path#blob {
      fill: ${props => hexToRgbA(getStyleForProp('blob')(props), 0.5)};
    }
    defs linearGradient stop:nth-child(1) {
      stop-color: ${getStyleForProp('blobGradientStart')};
    }
    defs linearGradient stop:nth-child(2) {
      stop-color: ${getStyleForProp('blobGradientEnd')};
    }
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  border-radius: 50%;
  .control-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 73.685% !important;
    height: 73.685%;
  }
  .LazyLoad {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    overflow: hidden;
    width: ${({ isEditing }) => (isEditing ? '100%' : ' 73.685%')};
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0px;
  ${getTextStyleByAlignment}
`

const SubTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  margin: 0;
  margin-top: 20px;
  ${getTextStyleByAlignment}
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment}
`

const ImageWrapper = styled.div`
  width: 100%;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const ParagraphsWrap = styled.div`
  width: 100%;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'RIGHT' ? '0 0 0 100px' : '0 100px 0 0'};
      @media only screen and (max-width: 1440px) {
        margin: ${theme.orientation === 'RIGHT' ? '0 0 0 92px' : '0 92px 0 0'};
      }
    `}
  & > *:nth-child(1) {
    margin-top: 0;
  }
  @media only screen and (max-width: 992px) {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .waveImg {
    height: 500px;
    padding-bottom: 100%;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    h2,
    p {
      margin-top: 0;
    }
  }
`

const ButtonBlock = styled.div`
  display: flex;
  align-items: center;
  .buttons-block {
    justify-content: center;
    flex-wrap: nowrap;
    @media only screen and (max-width: 650px) {
      flex-wrap: wrap;
      align-items: center;
    }
  }

  @media only screen and (max-width: 992px) {
    margin-left: auto;
    margin-right: auto;
  }

  & > div {
    padding-top: 0px;
    margin-left: -20px;
    white-space: nowrap;
    @media only screen and (max-width: 992px) {
      margin-left: 0px;
    }

    & > div {
      margin: 0;
    }
  }

  .buttons-wrapper {
    flex-wrap: nowrap;
    @media only screen and (max-width: 650px) {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }
    @media only screen and (max-width: 500px) {
      flex-direction: column;
    }
    & > div {
      margin: 32px 20px 0px 20px;

      @media only screen and (max-width: 500px) {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .add-button-wrap {
    margin-top: 35px;
    margin-bottom: 0px;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Paragraph,
  TextImageContent,
  ImageWrapper,
  Img,
  ControlContainer,
  ParagraphsWrap,
  ButtonBlock,
  SubTitle
}
