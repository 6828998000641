import { hexToRgb } from '@website/common/utils'

export default {
  DARK: {
    paragraph: p => p.accentWhite,
    iconText: p => p.primaryLight,
    background: p => p.tertiaryDark,
    borderColor: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    paragraph: p => p.tertiaryDark,
    iconText: p => p.primaryDark,
    background: p => p.tertiaryLight,
    borderColor: p => `rgba(${hexToRgb(p.tertiarySemiLight)}, 0.2)`,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    paragraph: p => p.tertiaryDark,
    iconText: p => p.primaryDark,
    background: p => p.tertiaryExtraLight,
    borderColor: p => `rgba(${hexToRgb(p.tertiarySemiLight)}, 0.2)`,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
