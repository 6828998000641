/* @flow */
import React, { useContext, useMemo, memo } from 'react'
import S from './CTA7.styled'
import { validation } from './CTA7.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext, EditingContext } from '@contexts'
import Morph from './Cta7Morph'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const CTA7 = memo(props => {
  const {
    data: { ctaContent, buttons, imageUrl, imgDimensions }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const titleCount = useMemo(
    () => ctaContent.filter(cta => cta.type === 'title').length,
    [ctaContent]
  )

  const paragraphCount = useMemo(
    () => ctaContent.filter(cta => cta.type === 'paragraph').length,
    [ctaContent]
  )

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <S.ImageContent>
          <ControlsWithModalOpener
            actions={Actions.getImageActions({
              keyForSizes: 'header57',
              showRemove: false,
              imageUrl
            })}
            style={{ width: '100%', height: '100%' }}
          >
            <Morph className="morph" />
            <S.Figure>
              <Image
                as={S.Img}
                sizes={imgDimensions}
                defaultImgSrc={imageUrl}
                alt="Feature illustration"
              />
            </S.Figure>
          </ControlsWithModalOpener>
        </S.ImageContent>
        <Show when={[...ctaContent, ...buttons]}>
          <S.CtaContent>
            <Mapper
              data={ctaContent}
              render={(item: string, idx: number) => {
                return (
                  <React.Fragment key={idx}>
                    <If
                      condition={item.type === 'paragraph'}
                      otherwise={() => (
                        <S.TitleWrapper>
                          <EditableContent
                            text={item.text}
                            as={S.Title}
                            alignment={item.textAlignment}
                            className="WM_GLOBAL_heading"
                            maxCount={validation.headingMaxChar}
                            onChange={(newText: string) =>
                              Actions.changeText(newText, idx)
                            }
                            changeAlignment={(alignment: string) =>
                              Actions.changeTextAlignment(alignment, idx)
                            }
                          />
                        </S.TitleWrapper>
                      )}
                      then={() => (
                        <EditableContent
                          as={S.Paragraph}
                          text={item.text}
                          alignment={item.textAlignment}
                          maxCount={validation.paragraphMaxChar}
                          className="WM_GLOBAL_paragraph"
                          onChange={(newText: string) =>
                            Actions.changeText(newText, idx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeTextAlignment(alignment, idx)
                          }
                        />
                      )}
                    />
                  </React.Fragment>
                )
              }}
            />
            <If
              condition={isEditing}
              then={() => (
                <S.AddButtonsWrapper>
                  <AddButton
                    onAdd={Actions.addTitle}
                    toShow={titleCount < validation.ctaContent.title.max}
                    style={{ marginRight: '20px' }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_quote_label')}
                  </AddButton>
                  <AddButton
                    onAdd={Actions.addParagraph}
                    toShow={
                      paragraphCount < validation.ctaContent.paragraph.max
                    }
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_paragraph_label')}
                  </AddButton>
                </S.AddButtonsWrapper>
              )}
            />
            <Buttons data={buttons} />
          </S.CtaContent>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default CTA7
