import styled, { css } from 'styled-components'

export const ControlsBarWrapper = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-right: 10px;
  }
`

export const ControlsBar = styled.div`
  display: flex;
  box-shadow: 0 2px 8px #3271e64d;
  border: 1px solid #c1c9e0;
  border-radius: 5px;
  height: 40px;
  transition: all 300ms ease;
  overflow: ${({ isTransitionEnded }) =>
    isTransitionEnded ? 'visible' : 'hidden'};
  box-sizing: border-box;
  & {
    pointer-events: ${({ isTransitionEnded }) =>
      isTransitionEnded ? 'unset' : 'none'};
  }

  &::before {
    content: '';
    display: none;
    width: 44px;
    height: 44px;
    position: absolute;
    top: -2px;
    left: -2px;
    background-color: transparent;
    z-index: -1;
    border: 2px dashed #3271e6;
    border-radius: 5px;
  }

  ${({ isExpanded, width, expandedStaticWidth }) =>
    isExpanded
      ? css`
          background: #ffffff;
          width: ${`calc(${width}px + ${expandedStaticWidth}px)`};
          box-shadow: 0 2px 8px #3271e64d;
          cursor: default;

          .control-icon {
            fill: #252e48;
          }
        `
      : css`
          background: transparent
            linear-gradient(180deg, #ffffff 0%, #edf5ff 100%) 0 0 no-repeat
            padding-box;
          width: 40px;
          box-shadow: 0 3px 12px #4272de33;
          cursor: pointer;

          .control-icon {
            fill: #545f7e;
          }
        `};

  &:hover {
    ${({ isExpanded }) =>
      isExpanded
        ? css`
            border: 1px solid transparent;
          `
        : css`
            border: 1px solid #3271e6;
            .control-icon {
              fill: #3271e6;
            }
          `}
  }
`

export const ControlsContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
  }

  .control-icon {
    padding: 7px;
  }

  .arrow-icon {
    padding: 12px;
    fill: #8493bd;
    cursor: pointer;

    &:hover {
      fill: #252e48;
    }
  }
`
