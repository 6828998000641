/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Spotify5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
`
const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '100px 0 80px 0' : '80px 0')};
  @media only screen and (max-width: 1300px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : '80px 0')};
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
`

const TextContent = styled.div`
  width: 100%;
  max-width: 630px;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  & > .control-container {
    margin-bottom: 30px;
    p,
    h1 {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const Name = styled.p`
  color: ${getStyleForProp('name')};
  ${getTextStyleByAlignment};
`

const MusicWrapper = styled.div`
  width: 600px;
  min-width: 600px;
  font-size: 0;
  margin-top: 35px;
  margin-left: 0px;
  @media only screen and (min-width: 1023px) {
    margin-left: 20px;
    &:only-child {
      margin: auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 30px;
    &:only-child {
      margin: 0;
    }
  }
  @media only screen and (max-width: 650px) {
    min-width: initial;
    width: 95%;
    max-width: 600px;
    &:only-child {
      margin: 0;
    }
  }
`

const MusicContent = styled.div`
  border-radius: ${getStyleForProp('badgeRadius')};
  overflow: hidden;
  position: relative;
  width: 100%;
`

const IframeWrapper = styled.div`
  width: 100%;
  position: relative;
  iframe {
    width: 100%;
    height: 80px;
    border-radius: ${getStyleForProp('badgeRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      iframe {
        height: 100px;
      }
    `}
`

export default {
  WmCustomContainer,
  StyledContainer,
  Title,
  Paragraph,
  Name,
  MusicContent,
  IframeWrapper,
  TextContent,
  MusicWrapper
}
