/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer9.theme'
import { styleProvider } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '120px 0 0 0' : '0')};
  background-color: ${getStyleForProp('background')};
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
`

const MapWrap = styled.div`
  font-size: 0;
  border-bottom: 1px solid ${getStyleForProp('border')};
`

const Map = styled.iframe`
  width: 100%;
  height: 360px;
`

const FooterContentWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  > .add-button-wrap {
    min-width: 125px;
    margin-right: 25px;
  }
  @media only screen and (max-width: 1023px) {
    flex-direction: column-reverse;
    align-items: center;
    > .add-button-wrap {
      margin-top: 30px;
    }
  }
  .socials-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    & > .control-container:nth-last-child(-n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
    @media only screen and (max-width: 1023px) {
      margin-top: 20px;
      .control-container:nth-last-child(-n + 3) {
        .styled-controls {
          right: 0;
          left: initial;
        }
      }
    }
    .link {
      width: 28px;
      height: 28px;
      border-radius: ${getStyleForProp('iconRadius')};
      fill: #ffffff;
      ${getStyleForProp('buttonBackground')};
      > div {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }
  .socials-block {
    @media only screen and (max-width: 1023px) {
      .rf-add-btn {
        margin-top: 10px;
      }
    }
  }
  .logo-wrapper {
    display: block;
    margin-right: 20px;
    @media only screen and (max-width: 1023px) {
      margin-top: 20px;
      margin-right: 0;
    }
  }
`

const FooterContent = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1023px) {
    margin: 60px 0 0 0;
  }
  @media only screen and (max-width: 500px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const FooterLogo = styled.img.attrs(({ _width }) => {
  return _width
    ? {
        style: { width: `${_width}px` }
      }
    : {}
})`
  height: auto;
  object-fit: contain;
  height: auto;
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  color: ${getStyleForProp('paragraph')};

  @media only screen and (min-width: 801px) {
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            @media only screen and (min-width: 801px) {
              max-width: calc(100% - 210px);
            }
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 0;
            }
          `
        : css`
            max-width: 100%;
          `}
  }
`

const CopyRightWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  @media only screen and (max-width: 1023px) {
    justify-content: center;
    margin-top: 30px;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin-top: 30px;
  }
`

const LinkWrap = styled.div`
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 170px;
  &:hover {
    a {
      opacity: 0.6;
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          .control-container {
            margin-left: 10px;
            @media only screen and (max-width: 800px) {
              margin-left: 0;
            }
          }
        `
      : css`
          .link-wrapper {
            margin-left: 10px;
            @media only screen and (max-width: 800px) {
              margin-left: 0;
            }
          }
        `}

  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 30px;

    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
`

export default {
  Container,
  WmCustomContainer,
  FooterContentWrap,
  FooterContent,
  FooterLogo,
  Copyright,
  MapWrap,
  Map,
  CopyRightWrapper,
  LinkWrap
}
