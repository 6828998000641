/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PrivacyPolicy5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import { secondary } from '@website/common/styles/colors'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  background-color: ${getStyleForProp('backgroundColor')};
  ${getBackgroundStyles(THEME)};
  padding-bottom: 130px;
  ${({ isEditing }) =>
    !isEditing &&
    css`
      padding-top: 0;
    `}
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
  .control-container:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
  .image-state-overlay {
    z-index: 9;
  }
  @media (max-width: 900px) {
    padding-bottom: 80px;
  }
  @media (max-width: 457px) {
    padding-bottom: 60px;
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`

const WMCustomContainer = styled(WMContainer)`
  width: 90%;
  display: flex;
  justify-content: center;
  max-width: 960px;
  box-sizing: border-box;
  position: relative;
  margin-top: 50px;
  @media only screen and (max-width: 1380px) {
    max-width: 740px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 900px) {
    max-width: 100%;
    width: 100%;
    margin-top: 25px;
    padding-bottom: 0;
    flex-direction: column;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 20px;
  }
`

const BlocksContainer = styled.div`
  width: 100%;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1380px) {
    max-width: 740px;
  }

  @media only screen and (max-width: 900px) {
    max-width: 100%;
    min-height: auto;
  }
`
const AdditionalBlockContainer = styled.div`
  margin-left: -200px;
  margin-right: auto;
  @media only screen and (max-width: 1440px) {
    margin-left: -120px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: -80px;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    position: static;
    margin-left: 0px;
    margin-bottom: 40px;
  }
`
const AdditionalBlockWrapper = styled.div`
  ${({ hasAdditionalParagraph }) =>
    hasAdditionalParagraph
      ? css`
          min-height: 400px;
        `
      : css`
          height: 100%;
        `};
  min-width: 26px;
  padding-right: 10px;
  position: relative;
  border-right: 1px solid ${getStyleForProp('line')};
  > div {
    width: 100%;
  }
  p {
    min-height: 1.5em;
  }

  @media only screen and (max-width: 900px) {
    border-right: none;
    padding-right: 0;
    min-height: inherit;
  }
`

const RightLine = styled.span`
  position: absolute;
  top: 80px;
  right: -188px;
  height: 82%;
  max-height: 600px;
  width: 1px;
  overflow: hidden;
  @media only screen and (max-width: 1440px) {
    right: -88px;
  }
  @media only screen and (max-width: 1024px) {
    right: -48px;
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
  &:after {
    content: '';
    height: 100%;
    border: 8px dashed ${getStyleForProp('line')};
    display: block;
  }
`

const RhombusWrapper = styled.div`
  position: absolute;
  right: -227px;
  top: -91px;
  width: 80px;
  height: 80px;
  @media only screen and (max-width: 1440px) {
    width: 60px;
    height: 60px;
    right: -118px;
    top: -81px;
  }
  @media only screen and (max-width: 1024px) {
    right: -77px;
    top: -81px;
  }
  @media only screen and (max-width: 900px) {
    width: 40px;
    height: 40px;
    right: 0px;
    transform: translateX(-50%);
    top: -46px;
  }
  svg {
    height: 100%;
    width: 100%;
    path {
      fill: ${getStyleForProp('rhombus')};
    }
  }
`

const SmallRhombusWrapper = styled.div`
  width: 40px !important;
  height: 40px;
  position: absolute;
  left: 50%;
  bottom: -50px;
  svg {
    height: 100%;
    width: 100%;
    path {
      fill: ${getStyleForProp('smallRhombus')};
    }
  }
  @media only screen and (max-width: 900px) {
    display: none;
  }
`

const AdditionalBlockParagraph = styled.p`
  width: 100%;
  transform: rotate(180deg);
  color: ${getStyleForProp('additionalBlockParagraph')};
  text-align: right;
  writing-mode: vertical-lr;
  white-space: nowrap;
  ${({ isEditing }) => (!isEditing ? css`` : '')}
  @media only screen and (max-width: 900px) {
    text-align: left;
    writing-mode: horizontal-tb;
    transform: translateY(0%);
    white-space: normal;
  }
  &:before {
    bottom: 50px;
    top: 100px;
    transform: rotate(90deg);
    position: absolute;
  }
`

const ListItemWrap = styled.div`
  width: 100%;
  position: relative;
  margin-top: 20px !important;

  & > .control-container {
    padding-top: 0px;
    margin-bottom: 0;
    .control-container {
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
`

const Block = styled.div`
  width: 100%;
  position: relative;

  > div {
    margin-top: 0;
  }
  > .control-container {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    p,
    h2 {
      margin-top: 0;
    }

    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
`

const ControlContainer = styled.div`
  .control-container {
    width: 100%;
  }
`

const BlockWrap = styled.article`
  margin-bottom: 30px;

  .control-container {
    margin-bottom: 30px;
  }
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  margin-top: 0;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`
const ListItemParagraph = styled.p`
  position: relative;
  box-sizing: border-box;
  color: ${getStyleForProp('blockParagraph')};
  &::first-letter {
    padding-left: 20px;
  }
  &:after {
    content: '';
    position: absolute;
    margin-right: 5px;
    max-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: 6px;
    left: 0;
    background: ${({ theme }) =>
      `linear-gradient(0, ${theme.palette.primaryDark}, ${theme.palette.primaryLight})`};
  }
`

const AddButtonsWrapper = styled.div`
  display: flex;
`

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${getStyleForProp('line')};
  padding: 80px 40px 40px 40px;
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  @media only screen and (max-width: 900px) {
    padding-bottom: 20px;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 20px 20px 20px;
  }

  & > .control-container {
    margin: 0 auto;
    max-width: 960px;
    width: 100%;
    box-sizing: border-box;
    & > h1 {
      margin: 0;
      max-width: unset;
    }
    @media only screen and (max-width: 1380px) {
      max-width: 740px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 960px;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1380px) {
    max-width: 740px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  BlocksContainer,
  ControlContainer,
  BlockWrap,
  Block,
  BlockTitle,
  BlockParagraph,
  ListItemWrap,
  ListItemParagraph,
  AdditionalBlockContainer,
  AdditionalBlockWrapper,
  AdditionalBlockParagraph,
  RightLine,
  RhombusWrapper,
  SmallRhombusWrapper,
  AddButtonsWrapper,
  TitleContainer,
  Title
}
