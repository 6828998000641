import styled from 'styled-components'

export const MobileActiveCategory = styled.div`
  @media only screen and (max-width: 1024px) {
    padding: 150px 40px 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 150px 20px 0;
  }
  @media only screen and (min-width: 1025px) {
    display: none;
  }
`

export const ActiveCategoryWrapper = styled.div`
  display: flex;
  align-items: center;

  svg {
    fill: #252e48;
  }
`

export const ActiveCategory = styled.p`
  color: #252e48;
  font-weight: 600;
  font-size: 16px;
  margin-left: 10px;
`

export const ActiveSubcategory = styled.p`
  color: #252e48;
  font-weight: 500;
  font-size: 14px;
  margin-top: 7px;
`
