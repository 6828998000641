// @flow

type WidthLimitsType = {
  minWidth: number,
  maxWidth: number,
  minHeight: number,
  maxHeight: number
}

const getWidthLimitsByRatio = (
  limits: WidthLimitsType,
  ratio: number
): number[] => {
  const { minWidth, maxWidth, minHeight, maxHeight } = limits
  const widthForMinPossibleHeight = ratio * minHeight
  const widthForMaxPossibleHeight = ratio * maxHeight

  const min = Math.max(widthForMinPossibleHeight, minWidth)
  const max = Math.min(widthForMaxPossibleHeight, maxWidth)

  return [min, max]
}

const ensureBoundaries = (width: number) => (
  min: number,
  max: number
): number => Math.min(Math.max(min, width), max)

export const getImageValidWidth = (
  expectedWidth: number,
  ratio: number,
  limits: WidthLimitsType
): number => {
  const [minWidthByRatio, maxWidthByRatio] = getWidthLimitsByRatio(
    limits,
    ratio
  )

  if (minWidthByRatio < maxWidthByRatio) {
    return ensureBoundaries(expectedWidth)(minWidthByRatio, maxWidthByRatio)
  } else {
    // in case we've got some wired image that cannot be fit within our boundaries
    return maxWidthByRatio
  }
}

export const ImageUploadInfo = function ({ failed, isLoading, url }) {
  this.failed = failed
  this.isLoading = isLoading
  this.url = url
}

ImageUploadInfo.prototype.toString = function () {
  return this.url
}
