import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    social: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    social: p => p.tertiaryDark,
    navbarBg: p => p.accentWhite,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    social: p => p.tertiaryDark,
    navbarBg: p => p.accentWhite,
    lightBackground: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0)`
  }
}
