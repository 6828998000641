export const validation = {
  title: { required: true },
  menuTitle: { required: true },
  titleMaxChar: 50,
  paragraphMaxChar: 300,
  menuTitleMaxChar: 15,
  sliderTitleMaxChar: 50,
  sliderParagraphMaxChar: 500,
  blockTitleMaxChar: 20,
  blockValueMaxChar: 10,
  subBlockTitleMaxChar: 50,
  subBlockValueMaxChar: 10,
  slides: {
    min: 2,
    max: 5,
    subBlocks: {
      min: 0,
      max: 8
    }
  }
}
