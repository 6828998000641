/* @flow */
/* eslint-disable max-statements */

import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_TEXT_GRADIENT': {
        draft.titleTextGradient = action.value
        break
      }
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NAME_LABEL':
        draft.name = action.value
        break
      case 'CHANGE_EMAIL_LABEL':
        draft.email = action.value
        break
      case 'CHANGE_MESSAGE_LABEL':
        draft.message = action.value
        break
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
      case 'CHANGE_PLACEHOLDER_TEXT':
        const { key, value } = action.value
        if (draft.placeholders) {
          draft.placeholders[key] = value
        } else {
          draft.placeholders = { [key]: value }
        }
        break
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions
        draft.imgCropParams = {}
        break
      case 'SET_IMG_CROP_PARAMS':
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'CHANGE_ICON':
        {
          const { idx, iconName } = action.value
          draft.blocks[idx].iconName = iconName
        }
        break
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.blocks[idx].iconName = ''
        break
      }
      case 'ADD_ICON':
        draft.blocks[action.value].iconName = initialState.blocks[0].iconName
        break
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { paragraph, idx } = action.value
        draft.blocks[idx].paragraph = paragraph
        return
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE': {
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      }
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
