import styled, { css } from 'styled-components'

export const MovementControl = styled.div`
  ${({ isDouble }) =>
    isDouble
      ? css`
          display: flex;
          & > div {
            & > div {
              width: 35px;
            }
            &:first-child {
              > div {
                border-radius: 5px 0 0 5px;
                border-right: 0;
                @media only screen and (max-width: 768px) {
                  &:hover {
                    border-radius: 5px 0 0 5px;
                  }
                }
              }
              &:hover {
                & + div > div {
                  &:before {
                    left: -1px;
                    background-color: #3271e6;
                  }
                }
              }
            }

            &:last-child > div {
              position: relative;
              border-radius: 0 5px 5px 0;
              border-left: 0;
              @media only screen and (max-width: 768px) {
                &:hover {
                  border-radius: 0 5px 5px 0;
                }
              }

              &:before {
                content: '';
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: #c1c9e0;
                left: 0;
                top: 0;
              }

              &:hover {
                &:before {
                  background-color: #3271e6;
                }
              }
            }
          }
        `
      : ''}

  @media only screen and (max-width: 768px) {
    & > div {
      & > div {
        width: 40px;
        height: 40px;
        box-sizing: border-box;
        background: transparent
          linear-gradient(180deg, #ffffff 0%, #eef5ff 100%) 0 0 no-repeat
          padding-box;

        &:hover {
          border-radius: 5px;
          svg {
            fill: #545f7e;
          }
        }
      }
    }
  }
`
