/* @flow */
import { validation } from '../Text15.consts'
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'

import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  removeBlock,
  addIcon
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const addImageBlock = () =>
    dispatch({
      type: 'ADD_IMAGE_BLOCK'
    })

  const removeImageBlock = idx => ({
    type: 'REMOVE_IMAGE_BLOCK',
    value: { idx }
  })

  const _addIcon = idx => {
    dispatch(addIcon(idx))
  }

  const imageActions = (
    idx: number,
    blocksCount: number,
    imageUrl: string,
    mediaFlyoutShift
  ) => [
    ...cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'text15',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl,
      mediaFlyoutShift
    }),
    {
      toShow: blocksCount > validation.images.min,
      handler: () => dispatch(removeImageBlock(idx)),
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label')
    }
  ]

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addIcon: _addIcon,
    changeBlockTitle,
    changeBlockParagraph,
    addImageBlock,
    addBlock: () => dispatch(addBlock()),
    removeBlock,
    removeImageBlock,
    imageActions,
    blockActions: removeBlockActionMaker(dispatch),
    iconActions: iconActionMaker(dispatch),
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatch)({
        showRemove: false,
        imageUrl
      })
  }
}
