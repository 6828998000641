/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature17.styled'
import ActionGetter from './Actions'
import { validation } from './Feature17.consts'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Feature17 = memo(props => {
  const {
    data: { features }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.Container isEditing={isEditing}>
      <S.TopBlockWrapper>
        <Mapper
          data={features}
          render={(feature: string, idx: number) => {
            const {
              images,
              title,
              paragraph,
              blockTitleAlignment,
              blockParagraphAlignment
            } = feature
            return (
              <React.Fragment key={idx}>
                <S.ImagesContainer>
                  <Mapper
                    data={images}
                    render={(image: string, imageIdx: number) => {
                      return (
                        <S.BlockItemWrap key={imageIdx}>
                          <div className="block-img-wrap">
                            <S.ControlContainer>
                              <ControlsWithImageCropper
                                actions={Actions.imageActions(
                                  idx,
                                  imageIdx,
                                  image.imgUrl
                                )}
                                style={{ width: '100%' }}
                              >
                                <Image
                                  as={S.Img}
                                  alt="item img"
                                  sizes={image.imgDimensions}
                                  defaultImgSrc={image.imgUrl}
                                  asProps={{
                                    imgCropParams: image.imgCropParams
                                  }}
                                />
                              </ControlsWithImageCropper>
                            </S.ControlContainer>
                          </div>
                        </S.BlockItemWrap>
                      )
                    }}
                  />
                </S.ImagesContainer>
                <Show when={[title, paragraph]}>
                  <S.TextContent>
                    <Show when={[title]}>
                      <EditableContent
                        text={title}
                        alignment={blockTitleAlignment}
                        as={S.Title}
                        maxCount={validation.headingMaxChar}
                        className="WM_GLOBAL_heading42"
                        onChange={(newTitle: string) =>
                          Actions.changeBlockTitle(newTitle, idx)
                        }
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                    </Show>
                    <EditableContent
                      text={paragraph}
                      alignment={blockParagraphAlignment}
                      as={S.Description}
                      maxCount={validation.paragraphMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(newParagraph: string) =>
                        Actions.changeBlockParagraph(newParagraph, idx)
                      }
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockParagraphAlignment(alignment, idx)
                      }
                    />
                  </S.TextContent>
                </Show>
                <If
                  condition={isEditing}
                  then={() => (
                    <S.AddButtonsWrap>
                      <AddButton
                        onAdd={() => Actions.addBlock(idx)}
                        toShow={features.length < validation.blocks.max}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_block_label')}
                      </AddButton>
                      <AddButton
                        onAdd={() => {
                          Actions.removeRow(idx)
                        }}
                        toShow={features.length > validation.blocks.min}
                        style={{ margin: '10px 5px' }}
                      >
                        <Icon
                          name="glyph-remove"
                          className="icon"
                          size="normal"
                        />
                        {translate('remove_block_label')}
                      </AddButton>
                    </S.AddButtonsWrap>
                  )}
                />
              </React.Fragment>
            )
          }}
        />
      </S.TopBlockWrapper>
    </S.Container>
  )
})

export default Feature17
