//@flow
import React, { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'

import { projectContainerClassName } from '@website/conf/consts'
import useRelativeCoordinates from '@website/common/hooks/useRelativeCoordinates'

import ControlItem from './ControlItem'
import * as Styled from './styled'

const SecondaryControls = ({
  targetEl,
  actions,
  hideControls,
  alignment = 'left',
  isRelativeToWrapper,
  onMouseOver,
  onMouseLeave
}) => {
  const controlsRef = useRef(null)

  const renderingContainer = isRelativeToWrapper
    ? targetEl
    : document.querySelector(`.${projectContainerClassName}`)

  const coords = useRelativeCoordinates(
    renderingContainer,
    targetEl,
    controlsRef,
    { alignmentY: 'top', alignmentX: alignment }
  )

  useEffect(() => {
    const controlsEl = controlsRef.current
    if (controlsEl) {
      controlsEl.addEventListener('mouseover', onMouseOver, false)
      controlsEl.addEventListener('mouseleave', onMouseLeave, false)
      controlsEl.addEventListener('touchstart', onMouseOver, false)
    }
    return () => {
      if (controlsEl) {
        controlsEl.removeEventListener('mouseover', onMouseOver, false)
        controlsEl.removeEventListener('mouseleave', onMouseLeave, false)
        controlsEl.removeEventListener('touchstart', onMouseOver, false)
      }
    }
  }, [onMouseOver, onMouseLeave, controlsRef])

  if (!targetEl || hideControls) {
    return null
  }

  return createPortal(
    <Styled.Controls
      ref={controlsRef}
      coords={coords}
      activeEl={targetEl}
      alignment={alignment}
      className="secondary-controls"
      isRelativeToWrapper={isRelativeToWrapper}
    >
      {actions.map((action, index) => (
        <ControlItem key={index} {...action} />
      ))}
    </Styled.Controls>,
    renderingContainer
  )
}

export default SecondaryControls
