/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header23.styled'
import THEME from './Header23.theme'
import { validation } from './Header23.consts'
import { withTheme } from 'styled-components'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import HeaderRect from '@website/common/components/HeaderRect'
import SingleButton from '@website/common/components/SingleButton'
import SocialLinks from '@website/common/components/SocialLinks'

const Header23 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      headerSocials,
      logoWidth,
      logoDimensions,
      bgOverlayTransparency,
      backgroundImgDimensions,
      buttonText,
      buttonUrl
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isSocials={headerSocials.length > 4}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.HeaderRectWrap>
        <HeaderRect />
      </S.HeaderRectWrap>
      <Show when={[title, paragraph, headerSocials.length > 0]}>
        <S.WmCustomContainer isEditing={isEditing}>
          <S.HeaderContent topOffset={navBarHeight}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              maxCount={validation.headingMaxChar}
              required={validation.title.required}
              className="WM_GLOBAL_heading96"
              as={S.Title}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Description}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <SingleButton btnUrl={buttonUrl} btnText={buttonText} />
          </S.HeaderContent>
          <SocialLinks
            className="header-socials"
            socials={headerSocials}
            socialsMaxCount={validation.headerSocials.max}
          />
        </S.WmCustomContainer>
      </Show>
    </S.StyledContainer>
  )
})

Header23.contextType = DispatchContext

export default withTheme(Header23)
