/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA5.styled'
import { validation } from './CTA5.consts'
import ActionGetter from './Actions'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import Buttons from '@website/common/components/Buttons'

const CTA5 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttons,
      videoUrl,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.Border isBackground={backgroundImgUrl} />
      <S.WmCustomContainer isEditing={isEditing}>
        <S.VideoWrapper>
          <ControlsWithModalOpener
            actions={Actions.getVideoActions(false, videoUrl)}
            style={{ width: '100%' }}
          >
            <S.VideoContent>
              <Overlay />
              <S.VideoWrap>
                <LazyVideoPlayer
                  style={{
                    top: 0,
                    left: 0,
                    position: 'absolute'
                  }}
                  controls
                  width="100%"
                  height="100%"
                  url={videoUrl}
                />
              </S.VideoWrap>
            </S.VideoContent>
          </ControlsWithModalOpener>
        </S.VideoWrapper>
        <S.CtaContent>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            className="WM_GLOBAL_heading32"
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            as={S.Paragraph}
            text={paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <Buttons data={buttons} />
        </S.CtaContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default CTA5
