/* @flow */
import produce from 'immer'

const setFeatureImgUrl = (feature, action, level = -1, subItemIdx = 0) => {
  const { blockIdx, subIdx, data, category } = action
  if (Array.isArray(feature)) {
    return feature.map((subItem, subItemIdx) =>
      setFeatureImgUrl(subItem, action, level + 1, subItemIdx)
    )
  } else {
    if (level === 2 && subItemIdx !== subIdx) {
      return
    }
    if (level === blockIdx) {
      feature[category] = data
    }
  }
}

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'ADD_BLOCK':
        draft.features.push(initialState.features[0])
        break
      case 'REMOVE_BLOCK':
        draft.features.splice(action.value.idx, 1)
        break
      case 'CHANGE_IMG_URL':
        const { blockIdx, featureIdx, url, subIdx, dimensions } = action.value
        setFeatureImgUrl(draft.features[featureIdx], {
          subIdx,
          blockIdx,
          category: 'imageUrl',
          data: url
        })
        setFeatureImgUrl(draft.features[featureIdx], {
          subIdx,
          blockIdx,
          data: dimensions,
          category: 'imgDimensions'
        })
        break
      case 'CHANGE_BLOCK_DATA': {
        const { blockIdx, featureIdx, subIdx, data, key } = action.value
        setFeatureImgUrl(draft.features[featureIdx], {
          subIdx,
          blockIdx,
          data,
          category: key
        })
        break
      }
    }
  })
}
