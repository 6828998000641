import styled from 'styled-components'

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  .info-icon {
    fill: #9aa6c8;
    display: flex;
    cursor: pointer;
  }

  .tooltip-wrapper {
    display: flex;
  }
`
