/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Text10.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const stylesLineAlignment = {
  default: '',
  left: css`
    margin-right: auto;
  `,
  center: css`
    margin-right: auto;
    margin-left: auto;
  `,
  right: css`
    margin-left: auto;
  `
}

const getLineStyleByAlignment = ({ alignment }) =>
  stylesLineAlignment[alignment]

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: ${getStyleForProp('orientation')};
  padding: ${({ isEditing }) => (isEditing && '140px 0 100px 0')};
  @media only screen and (max-width: 600px) {
    padding: ${({ isEditing }) => isEditing && '120px 0 100px 0'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100% !important;
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-bottom: 0;
    }
  }
  & > .control-container {
    width: 40%;
    max-width: 414px;

    @media only screen and (min-width: 1024px) {
      margin: 0 30px 0 0;
    }

    @media only screen and (max-width: 1024px) {
      max-width: initial;
      width: 100%;
    }
    & > h1 {
      width: 100%;
      max-width: unset;
      @media only screen and (min-width: 1024px) {
        margin: 0;
      }

      @media only screen and (max-width: 1024px) {
        max-width: unset;
        width: 100%;
      }
    }
  }
`

const LeftBlock = styled.div`
  width: 40%;
  max-width: 414px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
  width: 100%;
  &::after {
    left: 0;
  }
  @media only screen and (min-width: 1024px) {
    margin: 0 30px 0 0;
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    width: 100%;
  }
`

const TextButtons = styled.div`
  width: 60%;
  flex-grow: 1;
  max-width: 900px;
  padding: 0 0 0 60px;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    padding: 0;
    width: 100%;
    max-width: 100%;
  }
`

const Line = styled.div`
  height: 100%;
  width: 3px;
  background: ${getStyleForProp('line')};
  position: absolute;
  left: -40px;
  top: 0;
  @media only screen and (max-width: 1024px) {
    width: 90%;
    height: 3px;
    position: initial;
    left: initial;
    top: initial;
    margin: 20px 0;
    ${getLineStyleByAlignment}
  }
`

const ParagraphWrap = styled.div`
  position: relative;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WmCustomContainer,
  LeftBlock,
  TextButtons,
  Title,
  Paragraph,
  Line,
  ParagraphWrap
}
