/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'
import { addBlock, changeTitle, changeTitleAlignment } from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockTitle = (newTitle: string, blockIdx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { blockIdx, newTitle }
    })
  }

  const changeBlockParagraph = (
    newParagraph: string,
    blockIdx: number,
    key: string
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { blockIdx, newParagraph, key }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }
  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, alignmentKey: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, alignmentKey, idx }
    })
  }


  return {
    addBlock: _addBlock,
    changeBlockTitle,
    changeBlockParagraph,
    changeTitle: _changeTitle,
    blockActions: removeBlockActionMaker(dispatcher),
    changeTitleAlignment: _changeTitleAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment
  }
}
