/* @flow */
import produce from 'immer'
import meta from './PayPal5.meta'
const initialState = meta.defaultProps
import { ADDITIONAL_URL_DEFAULT_DATA } from './PayPal5.consts'

/* eslint-disable */
export default function reducer(
  state: typeof meta.defaultProps = initialState,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_SUBTITLE':
        draft.subtitle = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_IMAGE': {
        const { idx, url, dimensions } = action.value
        draft.blocks[idx].imgUrl = url
        draft.blocks[idx].imgDimensions = dimensions || {}
        draft.blocks[idx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx } = action.value
          draft.blocks[idx].imgCropParams = imgCropParams
        }
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'ADD_BLOCK': {
        draft.blocks.push(initialState.blocks[0])
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { paragraph, idx } = action.value
        draft.blocks[idx].paragraph = paragraph
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.blocks[idx].paragraphAlignment = alignment
        break
      }
      case 'CHANGE_SUBTITLE_ALIGNMENT': {
        draft.subtitleAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'ADD_LINK': {
        const { idx } = action.value
        draft.blocks[idx].additionalUrl =
          ADDITIONAL_URL_DEFAULT_DATA.additionalUrl
        draft.blocks[idx].additionalText =
          ADDITIONAL_URL_DEFAULT_DATA.additionalText
        break
      }
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.blocks[idx].additionalUrl = ''
        draft.blocks[idx].additionalText = ''
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.blocks[linkIdx].additionalUrl = linkData.link
        draft.blocks[linkIdx].additionalText = linkData.text
        break
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.blocks[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_PAYPAL_BUTTON_DATA': {
        const { btnData, idx } = action.value
        console.log('btnData', btnData)
        console.log('idx', idx)
        draft.blocks[idx].paypalBtnProps = btnData
        break
      }
    }
  })
}
