import styled from 'styled-components'

import { hexToRgb } from '@website/common/utils'

export const ButtonWrapper = styled.div`
  position: fixed;
  z-index: 19;
  right: 20px;
  top: ${({ isCentered }) => (isCentered ? '50%' : 'auto')};
  bottom: ${({ isCentered }) => (isCentered ? 'auto' : '0')};
  transform: ${({ isCentered }) => (isCentered ? 'translateY(-50%)' : 'none')};
  margin-bottom: 20px;
`

export const Button = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity .2s, visibility .2s;
  background: ${({ theme }) =>
    theme.look === 'SHARP'
      ? theme.palette.accentWhite
      : `transparent linear-gradient(180deg, 
      ${theme.palette.accentWhite} 0%, 
      ${theme.palette.tertiaryLight} 100%) 
      0% 0% no-repeat`};
  border: ${({ theme }) =>
    `1px solid ${
      theme.look === 'SHARP'
        ? theme.palette.primaryDark
        : theme.palette.tertiaryLight
    }`};
  border-radius: ${({ theme }) =>
    theme.look === 'ROUNDED'
      ? '50%'
      : theme.look === 'CORNERED'
      ? '10px'
      : '0'};
  box-shadow: ${({ theme }) =>
    `0px 4px 8px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  & > div {
    svg {
      flex-shrink: 0;
      fill: ${({ theme }) => theme.palette.primaryDark};
    }
  }
  &:hover {
    background: ${({ theme }) =>
      theme.look === 'SHARP'
        ? theme.palette.tertiaryLight
        : theme.palette.accentWhite}
`
