/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Post3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 458px) {
        padding-top: 150px;
      }
    `}
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px' : '60px 0')};
  }
`
const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
`

const TextContent = styled.div`
  text-align: center;
  width: 90%;
  max-width: 600px;
  margin: 0 auto 50px auto;
  @media only screen and (max-width: 992px) {
    margin: 0 auto 40px auto;
    width: 100%;
    max-width: initial;
    box-sizing: border-box;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h1,
    h2 {
      padding: 0;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 20px;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
`

const Row = styled.div`
  width: 100%;
  max-width: 850px;
  margin: 0px auto;
  & > div:first-child {
    margin-top: 0;
  }
`

const Col = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 60px 0 0 0;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: center;
    margin: 0;
  }
`

const ItemWrap = styled.div`
  width: 100%;
  max-width: 362px;
  margin: 0 20px;
  display: flex;
  flex-direction: ${({ isEven }) => (isEven ? 'column-reverse' : 'column')};
  justify-content: center;
  align-items: flex-start;
  @media only screen and (max-width: 992px) {
    margin: 0;
    flex-direction: column;
    align-items: center;
  }
  @media only screen and (max-width: 457px) {
    max-width: 100%;
  }
  > :first-child {
    margin-top: 0;
  }
`

const ItemImageWrapper = styled.div`
  width: 100%;
  flex-grow: 1;
  max-width: 362px;
  position: relative;
  @media only screen and (max-width: 992px) {
    margin: 20px 0 0 0;
  }
  @media only screen and (max-width: 457px) {
    max-width: 100%;
  }
`

const ControlContainer = styled.div`
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ItemTextWrapper = styled.div`
  width: 100%;
  max-width: 362px;
  flex-grow: 1;
  margin: ${({ isEven }) => (isEven ? '0 0 28px 0' : '0')};
  @media only screen and (max-width: 992px) {
    margin: 20px 0 0 0;
  }
  @media only screen and (max-width: 457px) {
    max-width: 100%;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h2 {
      padding: 0;
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  margin: 20px 0;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.primaryDark
}))``

const LinkWrap = styled.div`
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500 !important;
    font-size: 16px !important;
    color: ${({ theme }) => theme.palette.primaryDark};
  }
  .learn-more-link {
    font-weight: 500;
    align-items: center;
    .arrow-right {
      margin-left: 0;
      padding-left: 5px;
      min-width: 24px;
      transition: transform linear 0.15s;
    }
    &:hover {
      opacity: 0.7;
      .arrow-right {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
  svg {
    transform: translate3d(0, 0, 0);
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextContent,
  Title,
  Paragraph,
  ItemWrap,
  ItemImageWrapper,
  ItemTextWrapper,
  BlockTitle,
  BlockParagraph,
  Img,
  Row,
  Col,
  ControlContainer,
  Icon,
  LinkWrap
}
