import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  input{
      border-radius: 5px 0 0 5px;
  }
  .input-container {
    width: 400px;
  }

  @media only screen and (max-width: 1024px) {
    flex-grow: 1;
    .input-container {
      width: 100%;
    }
  }
`

export const SearchButton = styled.button`
  height: 100%;
  border: 1px solid #C1C9E0;
  border-left: none;
  border-radius: 0 5px 5px 0;
  background: none;
  height: 40px;
  padding: 0px 10px;
  cursor: pointer;
`
