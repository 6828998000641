import styled, { createGlobalStyle, css } from 'styled-components'

export const ItemWrapper = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
`

export const Item = styled.div`
  width: 100%;
  margin: 2px 20px;
  @media only screen and (max-width: 1366px) {
    margin: 15px;
  }
  @media only screen and (max-width: 490px) {
    margin: 15px 37px;
  }
  display: flex;
  .control-container {
    width: 100%;
  }
`

export const GlobalStyles = createGlobalStyle`
  .testimonials6-slider {
    .slick-arrow {
      display: block !important;
      font-size: inherit;
      width: 36px;
      height: 36px;
      line-height: 1;
      z-index: 2;
      &:before {
        display: none;
      }
      button {
        width: 36px;
        height: 36px;
      }
    }
    .slick-prev {
      left: -30px;
      transform: translate(0, calc(-50% + 25px));
      div {
        transform: translateX(-2px);
      }
      @media only screen and (max-width: 1340px) {
        left: 40px;
      }
      @media only screen and (max-width: 1024px) {
        left: -40px;
      }
      @media only screen and (max-width: 767px) {
        left: -19px;
      }
      @media only screen and (max-width: 490px) {
        left: -10px;
      }
    }
    .slick-next {
      transform: translate(0, calc(-50% + 25px));
      right: -30px;
      div {
        transform: translateX(2px);
      }
      @media only screen and (max-width: 1340px) {
        right: 40px;
      }
      @media only screen and (max-width: 1024px) {
        right: -40px;
      }
      @media only screen and (max-width: 767px) {
        right: -19px;
      }
      @media only screen and (max-width: 490px) {
        right: -10px;
      }
    }
    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
    }
  }
`
