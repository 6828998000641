import styled, { css } from 'styled-components'

import { secondary, white } from '@website/common/styles/colors'
import { ThumbnailButtonsWrapper } from '@editor/common/styled-components/choose-component-template'

export const Overlay = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(54, 63, 90, 0.3);
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 8px;
  visibility: ${({ isSelected }) => (isSelected ? 'visible' : 'hidden')};
`

export const ComponentThumbnailWrapper = styled.div`
  position: relative;
  overflow: hidden;
  box-shadow: ${({ isSelected }) =>
    isSelected
      ? `0px 6px 12px #0052e01a, 0 0 0 1px #e9eaee, 0 0 0 3px #387dff`
      : '0px 6px 12px #0052e01a'};
  border-radius: 12px;
  box-sizing: border-box;
  border: ${({ isSelected }) =>
    isSelected ? '1px solid #e9eaee' : '1px solid #c1c9e0'};
  cursor: ${({ isMultiSelectionActive }) =>
    isMultiSelectionActive ? 'pointer' : 'default'};
  transition: all 200ms ease-out;

  .video-thumb {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    object-fit: cover;
    border-radius: 10px;
  }

  ${({ isMultiSelectionActive, isSelected }) =>
    isMultiSelectionActive && !isSelected
      ? css`
          &:hover {
            box-shadow: 0px 6px 12px #0052e01a, 0 0 0 1px #e9eaee,
              0 0 0 3px #387dff;
            border: 1px solid #e9eaee;
          }
          &:hover ${Overlay} {
            visibility: visible;
          }
        `
      : !isSelected
      ? css`
          &:hover ${ThumbnailButtonsWrapper} {
            visibility: visible;
            opacity: 1;
            @media only screen and (max-width: 768px) {
              transition-delay: 100ms;
            }
          }
          &:hover {
            @media only screen and (max-width: 768px) {
              box-shadow: 0px 6px 12px #0052e01a, 0 0 0 1px #e9eaee,
                0 0 0 3px #387dff;
              border: 1px solid #e9eaee;
            }
          }
        `
      : ''}
`

export const ComponentThumbnail = styled.div`
  position: relative;
  flex-grow: 1;
  & > p {
    position: absolute;
    z-index: 1;
    color: #252e48;
    font-size: 20px;
    background-color: #eef5ff;
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 10px 0 10px 0;
    box-shadow: 6px 6px 12px #0052e01a;
  }
`

export const Index = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  width: 20px;
  height: 20px;
  font-size: 11px;
  color: #ffffff;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #387dff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 50%;
`

export const NewLabel = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`

export const AnimationSign = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  left: 5px;
  top: 5px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: ${white};
  border: 1px solid ${secondary.Light};
  box-shadow: 0 1px 4px 0 #3271e64d;
  box-sizing: border-box;

  & > div {
    display: flex;
  }

  svg {
    padding: 5px;
    width: 18px;
    height: 18px;
    fill: ${secondary.ExtraDark};
  }
`
