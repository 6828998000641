/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_ROW':
        draft.features = {
          ...initialState.features,
          ...draft.features
        }
        break
      case 'REMOVE_ROW': {
        delete draft.features[action.value]
        break
      }
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, rowId, itemIdx } = action.value
        draft.features[rowId][itemIdx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_TITLE':
        const { newTitle, rowId, itemIdx } = action.value
        draft.features[rowId][itemIdx].title = newTitle
        break
      case 'CHANGE_ICON':
        const { rowId: rowId_, itemIdx: itemIdx_, iconName } = action.value
        draft.features[rowId_][itemIdx_].icon = iconName
        break
      case 'REMOVE_ICON': {
        const { rowId, itemIdx } = action.value
        draft.features[rowId][itemIdx].icon = ''
        break
      }
      case 'ADD_ICON': {
        const { rowId: rowId__, blockIdx } = action.value
        draft.features[rowId__][blockIdx].icon = 'edit'
        break
      }
      case 'CHANGE_LINK_DATA':
        const {
          rowId: linkRowId,
          blockIdx: linkBlockIdx,
          linkData
        } = action.value
        draft.features[linkRowId][linkBlockIdx].additionalUrl = linkData.link
        draft.features[linkRowId][linkBlockIdx].additionalText = linkData.text
        break
      case 'REMOVE_LINK':
        const { rowId: linkRowId_, blockIdx: linkBlockIdx_ } = action.value
        draft.features[linkRowId_][linkBlockIdx_].additionalUrl = ''
        draft.features[linkRowId_][linkBlockIdx_].additionalText = ''
        break
      case 'ADD_LINK':
        const { rowId: id_, blockIdx: blockItemIdx } = action.value
        const { additionalUrl, additionalText } =
          initialState.features[id_][blockItemIdx]
        draft.features[id_][blockItemIdx].additionalUrl = additionalUrl
        draft.features[id_][blockItemIdx].additionalText = additionalText
        break
      case 'CHANGE_BG_IMG': {
        const { url, rowId, itemIdx, dimensions } = action.value
        draft.features[rowId][itemIdx].backgroundImg = url
        draft.features[rowId][itemIdx].backgroundImgDimensions =
          dimensions || {}
        break
      }
      case 'REMOVE_BG_IMG': {
        const { rowId, itemIdx } = action.value
        draft.features[rowId][itemIdx].backgroundImg = ''
        draft.features[rowId][itemIdx].backgroundImgDimensions = {}
        break
      }
    }
  })
}
