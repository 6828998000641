// @flow

import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import Containers from '@containers'
import ProjectLoader from '@editor/common/components/ProjectLoader'
import Providers from './Providers'
import Popups from './Popups'

import {
  isProjectLoaded,
  hasComponents,
  getSiteId,
  getUser,
  getIsUserLoading,
  selectIsProjectSyncing,
  selectIsUserAuthorized,
  selectWebsiteLanguagesData,
  selectIsWebsiteLanguagesDataLoaded
} from '@selectors'
import {
  setSiteId,
  fetchUserData,
  setEmptyProject,
  fetchMediaLibLimitsAction,
  openLoginPopup,
  checkLoginStatus,
  googleOneTapLoginCb,
  fetchSubscriptions,
  setWebsiteActiveLanguageCode
} from '@actions/editor'

import { initialDataLoad } from '@actions/common'
import { isOpenedWithHash } from '@editor/common/utils'
import { fetchWebsiteDataByLang } from '@actions/project'
import { shouldRedirectToStart } from './utils'
import { useHistoryWithSearch, useLangRedirection } from '@hooks'
import { useDidUpdateEffect } from '@hooks/useDidUpdateEffect'

const mapStateToProps = state => ({
  isProjectLoaded: isProjectLoaded(state),
  isUserAuthorized: selectIsUserAuthorized(state),
  hasComponents: hasComponents(state),
  siteIdFromStore: getSiteId(state),
  currentUser: getUser(state),
  isUserLoading: getIsUserLoading(state),
  isProjectSyncing: selectIsProjectSyncing(state),
  websiteLanguagesData: selectWebsiteLanguagesData(state),
  isWebsiteLanguagesDataLoaded: selectIsWebsiteLanguagesDataLoaded(state)
})

const mapDispatchToProps = {
  setSiteId,
  fetchUserData,
  setEmptyProject,
  openLoginPopup,
  checkLoginStatus,
  fetchMediaLibLimitsAction,
  googleOneTapLoginCb,
  fetchSubscriptions,
  initialDataLoad,
  setWebsiteActiveLanguageCode,
  fetchWebsiteDataByLang
}

const Root = ({
  siteIdFromStore,
  setSiteId,
  fetchUserData,
  checkLoginStatus,
  isProjectLoaded,
  hasComponents,
  currentUser,
  isUserLoading,
  isUserAuthorized,
  isProjectSyncing,
  setEmptyProject,
  openLoginPopup,
  fetchMediaLibLimitsAction,
  googleOneTapLoginCb,
  fetchSubscriptions,
  initialDataLoad,
  websiteLanguagesData,
  isWebsiteLanguagesDataLoaded,
  setWebsiteActiveLanguageCode,
  fetchWebsiteDataByLang
}) => {
  const {
    url,
    params: {
      siteId: siteIdFromRoute,
      lang: websiteActiveLanguageCodeFromRoute
    }
  } = useRouteMatch()

  const history = useHistoryWithSearch()

  const beforeUnloadHandler = useCallback(e => {
    e.preventDefault()
    e.returnValue = ''
  }, [])

  useEffect(() => {
    const removeListener = () =>
      window.removeEventListener('beforeunload', beforeUnloadHandler)

    if (isUserAuthorized) {
      removeListener()
      return
    }

    if (hasComponents) {
      window.addEventListener('beforeunload', beforeUnloadHandler)
      return
    }

    removeListener()
    return () => {
      removeListener()
    }
  }, [hasComponents, isUserAuthorized])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (isUserAuthorized || document.visibilityState !== 'visible') {
        return
      }
      checkLoginStatus()
    }
    window.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [isUserAuthorized])

  // for google one tap login
  useEffect(() => {
    if (isUserAuthorized || window.ajaxLogin) {
      return
    }
    window.ajaxLogin = googleOneTapLoginCb
  }, [isUserAuthorized])

  useEffect(() => {
    setSiteId(siteIdFromRoute)
    fetchUserData()
  }, [])

  useEffect(() => {
    setWebsiteActiveLanguageCode(websiteActiveLanguageCodeFromRoute)
  }, [websiteActiveLanguageCodeFromRoute])

  useEffect(() => {
    if (isUserLoading || isProjectSyncing) {
      return
    }

    if (currentUser) {
      fetchMediaLibLimitsAction()
      !isOpenedWithHash() && fetchSubscriptions()
      if (siteIdFromRoute !== 'new') {
        initialDataLoad(currentUser)
      } else {
        setEmptyProject()
      }
    } else {
      if (siteIdFromRoute !== 'new') {
        openLoginPopup()
      } else {
        setEmptyProject()
      }
    }
  }, [isUserLoading, currentUser, isProjectSyncing])

  useDidUpdateEffect(() => {
    fetchWebsiteDataByLang(websiteActiveLanguageCodeFromRoute)
  }, [websiteActiveLanguageCodeFromRoute])

  // in case when site id changed in store first
  useEffect(() => {
    if (
      siteIdFromStore &&
      !history.location.pathname.includes(siteIdFromStore)
    ) {
      history.replace(
        `/${siteIdFromStore}/${websiteActiveLanguageCodeFromRoute}/edit`
      )
    }
  }, [siteIdFromStore])

  useEffect(() => {
    const shouldRedirect = shouldRedirectToStart(history.location.pathname)

    if (
      shouldRedirect &&
      isProjectLoaded &&
      !hasComponents &&
      !isProjectSyncing
    ) {
      history.replace(`${url}/start-creation`)
    }
  }, [isProjectSyncing, hasComponents, isProjectLoaded, history, url])

  useLangRedirection(websiteLanguagesData, isWebsiteLanguagesDataLoaded)

  return (
    <Providers>
      {isProjectLoaded ? <Containers /> : <ProjectLoader />}
      <Popups />
    </Providers>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Root)
