/* @flow */
import styled, { css } from 'styled-components'

import THEME from './CTA2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)}
  @media only screen and (max-width: 1200px) and (min-width: 769px) {
    padding-top: ${({ isEditing }) => (isEditing && '150px')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const HeaderContent = styled.div`
  width: 80%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media only screen and (max-width: 1260px) {
    max-width: 550px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    max-width: initial;
    align-items: center;
    margin-right: 0;
  }
  > :first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 20px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  Description
}
