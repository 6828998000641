/* @flow */
/* eslint-disable max-statements */

import React, { useContext, useState, useEffect, memo } from 'react'
import S from './Contact10.styled'
import {
  validation,
  EMPTY_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE
} from './Contact10.consts'
import ActionGetter from './Actions'
import EditableContent from '@website/common/components/EditableContent'
import { removeEmptyFields } from '@website/common/utils'
import Image from '@website/common/components/Image'
import { isValidEmail } from '@website/common/utils'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import SubmitButton from '@website/common/components/SubmitButton/SubmitButton'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { defaultPlaceholders } from './consts'

import {
  DispatchContext,
  EditingContext,
  SiteHashContext,
  SiteIdContext
} from '@contexts'
import { contactApi } from '@website/common/api'

const initialState = {
  name: '',
  email: '',
  message: ''
}

const Contact10 = memo(
  ({
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      placeholders,
      name,
      email,
      message,
      buttonText,
      imageUrl,
      imgDimensions,
      imgCropParams
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const { isEditing } = useContext(EditingContext)
    const siteId = useContext(SiteIdContext)
    const siteHash = useContext(SiteHashContext)
    const Actions = ActionGetter(dispatcher)
    const [inputData, setInputData] = useState(initialState)
    const [timer, setTimer] = useState(null)
    const [nameErrorMessage, setNameErrorMessage] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {
      if (timer) {
        setTimer(null)
      }
    })

    useEffect(() => {
      if (isEditing) {
        setInputData({ ...placeholders })
      }
    }, [placeholders])

    const handleChange = (type: string) => (e: Event) => {
      setInputData({ ...inputData, [type]: e.target.value })
      if (type === 'name') {
        setNameErrorMessage('')
      }
      if (type === 'email') {
        setEmailErrorMessage('')
      }
    }

    const handleEmailError = () => {
      if (!inputData.email) {
        setEmailErrorMessage(EMPTY_FIELD_MESSAGE)
      }
      if (inputData.email && !isValidEmail(inputData.email)) {
        setEmailErrorMessage(INVALID_EMAIL_MESSAGE)
      }
    }

    const handleNameError = () => {
      if (!inputData.name) {
        setNameErrorMessage(EMPTY_FIELD_MESSAGE)
      }
    }

    const evtHandlerGetter = (type: string) => {
      const evtHandlers = {
        onChange: (e: Event) => {
          setInputData({ ...inputData, [type]: e.target.value })
          if (type === 'name') {
            setNameErrorMessage('')
          }
          if (type === 'email') {
            setEmailErrorMessage('')
          }
        }
      }
      if (isEditing) {
        evtHandlers.onBlur = (e: Event) => {
          const value = e.target.value || defaultPlaceholders[type]
          if (value === placeholders[type]) {
            return
          }
          Actions.changePlaceHolderText(type, value)
          setInputData({ ...inputData, [type]: value })
        }
      } else {
        evtHandlers.onBlur = () => {
          if (type === 'name') {
            handleNameError()
          }
          if (type === 'email') {
            handleEmailError()
          }
        }
      }
      return evtHandlers
    }

    const sendData = () => {
      if (isEditing) {
        return
      }
      handleNameError()
      handleEmailError()
      if (isValidEmail(inputData.email) && inputData.name) {
        contactApi(
          siteId,
          removeEmptyFields({
            ...inputData,
            siteHash
          })
        )
          .then(() => {
            setIsSuccess(true)
            setTimer(
              setTimeout(() => {
                setInputData(initialState)
                setIsSuccess(false)
              }, 4000)
            )
          })
          .catch(console.warn)
      }
    }

    const getPlaceHolderText = key => (placeholders && placeholders[key]) || ''

    return (
      <S.StyledContainer isEditing={isEditing}>
        <S.WmCustomContainer>
          <S.FormImageWrapper>
            <S.ImageWrapper>
              <S.ControlContainer>
                <ControlsWithImageCropper
                  actions={Actions.getImgActions(imageUrl)}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    sizes={imgDimensions}
                    defaultImgSrc={imageUrl}
                    asProps={{
                      className: 'feature-image',
                      imgCropParams
                    }}
                    alt="Feature illustration"
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.ImageWrapper>
            <S.RightContent>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                maxCount={validation.headingMaxChar}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
                className="WM_GLOBAL_heading32"
              />
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
                className="WM_GLOBAL_paragraph18"
              />
              <S.FormContainer>
                <S.InputContainer>
                  <EditableContent
                    text={name}
                    as={S.Label}
                    maxCount={validation.labelMaxChar}
                    required={validation.name.required}
                    onChange={Actions.changeNameLabel}
                    className="WM_GLOBAL_paragraph14"
                    for="name"
                  />
                  <S.InputWrapper>
                    <S.RequiredIcon
                      name="glyph-asterisk"
                      className="required-icon"
                    />
                    <S.Input
                      id={!isEditing ? 'name' : ''}
                      value={inputData.name}
                      aria-label="Name input"
                      className="WM_GLOBAL_secondary-font"
                      isError={nameErrorMessage}
                      placeholder={isEditing ? '' : getPlaceHolderText('name')}
                      {...evtHandlerGetter('name')}
                      isError={nameErrorMessage}
                      isEditing={isEditing}
                    />
                    {nameErrorMessage && (
                      <S.ErrorMessage>
                        <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                        {nameErrorMessage}
                      </S.ErrorMessage>
                    )}
                  </S.InputWrapper>
                </S.InputContainer>
                <S.InputContainer>
                  <EditableContent
                    text={email}
                    as={S.Label}
                    required={validation.email.required}
                    maxCount={validation.labelMaxChar}
                    onChange={Actions.changeEmailLabel}
                    className="WM_GLOBAL_paragraph14"
                    for="email"
                  />
                  <S.InputWrapper>
                    <S.RequiredIcon
                      name="icon-asterisk"
                      className="required-icon"
                    />
                    <S.Input
                      id={!isEditing ? 'email' : ''}
                      value={inputData.email}
                      placeholder={isEditing ? '' : getPlaceHolderText('email')}
                      aria-label="Email input"
                      className="WM_GLOBAL_secondary-font"
                      isError={emailErrorMessage}
                      data-gramm_editor="false" // for grammarly extension
                      {...evtHandlerGetter('email')}
                    />
                    {emailErrorMessage && (
                      <S.ErrorMessage>
                        <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                        {emailErrorMessage}
                      </S.ErrorMessage>
                    )}
                  </S.InputWrapper>
                </S.InputContainer>
                <S.InputContainer>
                  <EditableContent
                    text={message}
                    as={S.Label}
                    required={validation.message.required}
                    maxCount={validation.labelMaxChar}
                    onChange={Actions.changeMessageLabel}
                    className="WM_GLOBAL_paragraph14"
                    for="message"
                  />
                  <S.TextArea
                    id={!isEditing ? 'message' : ''}
                    value={inputData.message}
                    placeholder={isEditing ? '' : getPlaceHolderText('message')}
                    data-gramm_editor="false" // for grammarly extension
                    aria-label="Message input"
                    className="WM_GLOBAL_secondary-font"
                    {...evtHandlerGetter('message')}
                  />
                </S.InputContainer>
                <SubmitButton
                  buttonText={buttonText}
                  submitFormData={sendData}
                  showSubmitNotification={isSuccess}
                />
              </S.FormContainer>
            </S.RightContent>
          </S.FormImageWrapper>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Contact10
