import { hexToRgb } from '@website/common/utils'

export default {
  DARK: {
    supHeader: p => p.accentWhite,
    heading: p => `rgba(${hexToRgb(p.accentWhite)}, 0.7)`,
    paragraph: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    socialBlock: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    socialBorder: p => `rgba(${hexToRgb(p.accentWhite)}, 0.3)`
  },
  LIGHT: {
    supHeader: p => p.primaryDark,
    heading: p => `rgba(${hexToRgb(p.primaryDark)}, 0.7)`,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryLight,
    backgroundColor: p => p.tertiaryLight,
    socialBlock: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.5)`,
    socialBorder: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`
  },
  EXTRALIGHT: {
    supHeader: p => p.tertiaryDark,
    heading: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.7)`,
    paragraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    socialBlock: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.5)`,
    socialBorder: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.3)`
  }
}
