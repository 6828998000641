//@flow
import React, { useCallback, useContext } from 'react'

import Content from './Content'
import FlyOut from '@editor/common/lib/FlyOutMenu/FlyOut'
import MobilePopup from '@editor/common/lib/Popup/MobilePopup'
import { ControlModalsContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'
import { useResponsive } from '@shared/hooks'

const MenuSettingsFlyout = () => {
  const { modalStates, setModalState } = useContext(ControlModalsContext)
  const isSmallSizes = useResponsive(1024)

  const { controlRef } = modalStates[MODAL_TYPES.languagesMenuFlyout]

  const onClose = useCallback(
    () => setModalState(MODAL_TYPES.languagesMenuFlyout, { isOpen: false }),
    []
  )

  return isSmallSizes ? (
    <MobilePopup
      isOpen
      onClose={onClose}
      title={translate('languages_menu_settings_control_tooltip_text')}
    >
      <Content />
    </MobilePopup>
  ) : (
    <FlyOut
      isOpen
      onClose={onClose}
      alignment="right"
      shift={{ x: 0, y: 50 }}
      withAnimation={false}
      isPositionFixed={false}
      anchorEl={controlRef && controlRef.current}
      className="languages-menu-settings-flyout"
      backdropDisablingSelectors={['.languages-menu-settings-control']}
      renderingContainer={document.querySelector(
        '.languages-menu-settings-control'
      )}
    >
      <Content />
    </FlyOut>
  )
}

export default MenuSettingsFlyout
