import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

import LibPopup from '@renderforest/rf-ui-library/organism/Popup'
import LibCheckbox from '@renderforest/rf-ui-library/molecules/Checkbox'
import LibButton from '@renderforest/rf-ui-library/atoms/Button'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const stylesObj = {
  de: {
    width: '90vw'
  },
  default: {
    width: 'auto'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const Popup = styled(LibPopup)`
  & > div {
    & > button {
      z-index: ${({ showCloseButton }) => (showCloseButton ? 11 : 1)};
    }
    padding: 0;
    align-items: center;
    max-height: 90vh;
    @media screen and (max-width: 550px) {
      max-width: 100%;
    }
    & > div {
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 480px;
  max-width: 500px;
  color: ${secondary.ExtraDark};
  @media screen and (max-width: 768px) {
    width: auto;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 50px 20px 50px;
  border-bottom: 1px solid #c2c9de;

  @media screen and (max-width: 768px) {
    padding: 40px 20px 20px;
  }
`

export const OptionBlock = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  & > div:first-child {
    flex-grow: 1;
    max-width: 300px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  border-bottom: 1px solid #c2c9de;
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
  & ${OptionBlock}:first-child {
    margin-top: 0;
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 50px 30px 50px;
  @media screen and (max-width: 580px) {
    width: ${getStylesByLang('width')};
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`

export const Title = styled.h1`
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
`

export const InfoText = styled.p`
  font-size: 14px;
  margin-top: 10px;
  line-height: 18px;
  & > a {
    color: #3271e6;
    font-weight: 500;
    text-decoration: none;
  }
`

export const OptionLabel = styled.h3`
  font-size: 16px;
  font-weight: 600;
  user-select: none;
`

export const OptionDescription = styled.p`
  font-size: 14px;
  color: ${secondary.SemiDark};
  margin-top: 10px;
  line-height: 18px;
`

const CHECKBOX_AFTER_STYLES = css`
  position: absolute;
  display: block;
  content: '';
  width: 50px;
  height: 50px;
  cursor: pointer;
  left: -16px;
  top: -16px;
`

export const Checkbox = styled(LibCheckbox)`
  margin: 0;
  position: relative;
  & div {
    margin-inline-end: 0;
  }
  &:after {
    ${CHECKBOX_AFTER_STYLES};
  }
`

export const AgreementCheckbox = styled(LibCheckbox)`
  margin: 0;
  position: relative;
  &:after {
    ${CHECKBOX_AFTER_STYLES};
  }
  & div {
    margin-inline-end: 0;
  }
  @keyframes pulse-checkbox {
    0% {
      box-shadow: 0 0 0 0 rgba(21, 190, 153, 0.8);
    }
    100% {
      box-shadow: 0 0 0 5px rgba(21, 190, 153, 0);
    }
  }
  & > div::after {
    ${({ isChecked }) =>
      isChecked
        ? css`
            border: none;
            background: linear-gradient(180deg, #40ea95, #12be99);
          `
        : css`
            border: 2px solid rgba(21, 190, 153, 0.8);
            animation: pulse-checkbox 2s infinite;
          `}
  }
`

export const AgreementBlock = styled.div`
  display: flex;
  align-items: center;
  min-height: 20px;
  & > div {
    margin-right: 10px;
  }
  & > p {
    user-select: none;
    font-size: 14px;
    font-weight: 600;
  }
`

export const ActionButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

const BUTTON_STYLES = css`
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  margin: 0 10px;
`

export const CancelButton = styled(LibButton)`
  ${BUTTON_STYLES};
  color: #3271e6;
  background: white;
  min-width: 108px;
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
`

export const SaveButton = styled(LibButton)`
  ${BUTTON_STYLES};
  color: white;
  box-shadow: none;
  background: linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0% 0% no-repeat;
  min-width: 98px;
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%) 0%
      0% no-repeat;
    box-shadow: 0px 3px 6px rgba(56, 125, 255, 0.501);
    transition: all 0.2s ease-out;
  }
`
