/* @flow */
import styled from 'styled-components'
import THEME from './Portfolio7.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0;
  .row-remove {
    color: white;
    padding: 10px;
  }
`

const TextContent = styled.div`
  width: 90%;
  margin: ${({ isEditing }) =>
    isEditing ? '100px auto 50px auto' : '80px auto 40px auto'};
  @media only screen and (max-width: 1024px) {
    margin-top: ${({ isEditing }) => (isEditing ? '150px' : '80px')};
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 768px) {
    margin: ${({ isEditing }) =>
      isEditing ? '120px auto 40px auto' : '40px auto'};
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  & > .control-container {
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
  }
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('heading')};
`
const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
`
const AddButtonWrapper = styled.div`
  display: flex;
  margin: 20px auto;
`

const Item = styled.div`
  width: 24%;
  display: flex;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    width: 45%;
  }
  .LazyLoad {
    width: 100%;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export default {
  StyledContainer,
  Row,
  RowWrapper,
  AddButtonWrapper,
  Item,
  TextContent,
  Title,
  Img,
  ControlContainer
}
