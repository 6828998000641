export const validation = {
  blockTitleMaxChar: 200,
  blockParagraphMaxChar: 500,
  featureParts: {
    min: 1,
    max: 12
  }
}

export const DEFAULT_LINK_DATA = {
  url: 'http://builder.renderforestsites.com',
  text: 'Learn more'
}
