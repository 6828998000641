/* @flow */
import styled from 'styled-components'
import Container from '@website/common/components/Container'
import THEME from './Feature22.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  .slick-slider {
    width: 95%;
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
    margin-top: -50px;
    padding-top: 50px;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      padding-left: 40px;
      padding-right: 40px;
    }
    @media only screen and (max-width: 992px) {
      margin-top: -40px;
      padding-top: 40px;
    }
  }
  .slick-slide > div {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .slick-list {
    width: 96%;
    margin: 0 auto;
  }
  .slick-slider {
    media only screen and (max-width: 1024px) {
      width: 100% !important;
      padding-left: 40px;
      padding-right: 40px;
    }
  }

  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 768px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 80px 0' : '80px 0')};
    .slick-slider {
      max-width: 320px;
    }
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
  > :first-child {
    padding-top: 0;
  }
`

const Headings = styled.div`
  text-align: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
  position: relative;
  z-index: 2;
`

const BlockTitle = styled.h2`
  text-align: center;
  width: 100%;
  color: ${getStyleForProp('blockTitle')};
`

const Circle = styled.div`
  width: 54px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

const IconWrap = styled.div``

const IconWrapper = styled.div`
  width: 54px;
  height: 54px;
  margin: 0 0 15px 0;
  user-select: none;
  border-radius: ${getStyleForProp('iconRadius')};
  background: ${getStyleForProp('buttonBackground')};
`

const IconContainer = styled.button`
  position: absolute;
  top: 53%;
  transform: translateY(calc(-50% + 20px));
  z-index: 1;
  padding: 0;
  border: none;
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: all 0.1s linear;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  > div {
    width: 30px;
    height: 30px;
  }
  &.slick-next {
    right: 0;
    @media only screen and (max-width: 1024px) {
      right: 31px;
    }
    @media only screen and (max-width: 768px) {
      right: 0px;
    }
  }
  &.slick-prev {
    left: 0;
    @media only screen and (max-width: 1024px) {
      left: 31px;
    }
    @media only screen and (max-width: 768px) {
      left: 0px;
    }
  }
  svg {
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
    max-width: unset;
    max-height: unset;
  }
`

const ControlContainer = styled.div`
  padding: 50px 4px 24px 4px;
  max-width: 190px;
  min-width: 190px;
  outline: none;
  margin: 0 18px;
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
`

const ItemWrapper = styled.div`
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.1s linear;
  background: ${({ isActive }) => (isActive ? 'white' : 'transparent')};
  ${({ isActive, ...rest }) =>
    isActive
      ? `
      box-shadow: ${getStyleForProp('raisedShadow')(rest)};
      ${BlockTitle} {
        color: ${getStyleForProp('activeBlockTitle')(rest)};
      }
    `
      : `none`};
  padding: 20px 10px;
  cursor: pointer;
  border-radius: ${getStyleForProp('cardRadius')};
  &:hover {
    box-shadow: 0 3px 11px 0 rgba(41, 55, 97, 0.08);
  }
  & > .control-container {
    width: 100%;
  }
`

const ImgControlContainer = styled.div`
  width: 100%;
  max-width: 914px;
  margin: 0 auto;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 56.25%;
    position: relative;
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

export default {
  StyledContainer,
  Title,
  Paragraph,
  BlockTitle,
  Circle,
  IconWrap,
  IconWrapper,
  IconContainer,
  ItemWrapper,
  Headings,
  ControlContainer,
  Img,
  ImageWrapper,
  ImgControlContainer
}
