import styled from 'styled-components'

export const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: ${({ imageRatio }) =>
    imageRatio ? `${Math.floor(imageRatio * 100)}%` : '100%'};
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: block;

  .img-loading {
    opacity: 0;
    width: 100%;
    height: auto;
  }
  .img-loaded {
    animation: fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1;
    position: relative;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-duration: 1.5s;
    animation-delay: 0.1s;
  }
  @keyframes fadeInImg {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`
