/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Morph2 = ({ theme }) => {
  const { look, palette } = theme
  const isSharp = look === 'SHARP'
  const from = palette.primaryLight
  const to = palette.primaryDark
  return (
    <svg
      width="786px"
      height="648px"
      viewBox="0 0 786 648"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="96.0553649%"
          id="linearGradient-1"
        >
          <stop stopColor={from} offset="0%" />
          <stop stopColor={isSharp ? from : to} offset="100%" />
        </linearGradient>
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <path
          d="M785.237155,482.407894 C764.964306,504.836666 741.977695,524.72992 716.35936,541.257831 C662.494165,576.402238 597.084584,593.633047 532.72927,590.82585 C481.864884,588.23629 432.60752,563.681039 381.739167,564.741167 C327.842562,565.454504 296.339819,608.054334 250.611893,629.364127 C190.559939,656.997499 116.018325,653.355614 62.4417325,612.958545 C14.4360005,576.766626 -11.0294229,511.051333 4.58987277,452.944522 C21.1764897,391.464261 75.6694843,348.676676 128.914813,313.876071 C182.160143,279.075466 239.878755,245.036008 270.501122,189.145072 C301.91704,132.112534 298.248879,63.5131205 311.991425,0 L785.237155,0 L785.237155,482.407894 Z M231.428667,145.5886 C217.578902,155.682497 197.391108,156.386722 183.071859,146.997051 C147.860592,124.46184 169.691578,75.4008067 206.076554,69.5322621 C247.62585,62.7247503 262.179841,123.053389 231.428667,145.5886 Z"
          fill="url(#linearGradient-1)"
        />
      </g>
    </svg>
  )
}

export default withTheme(Morph2)
