/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header22.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  padding: 0;
  padding-top: ${({ topOffset, isEditing }) =>
    isEditing ? '0' : `${topOffset}px`};
  ${getBackgroundStyles(THEME)};
  position: relative;
  @media only screen and (max-width: 650px) {
    padding: ${({ topOffset, isEditing }) =>
      isEditing ? '0 0 80px 0' : `${topOffset}px` + '0 0 0'};
  }
`

const SliderWrapper = styled.div`
  width: 48%;
  display: flex;
  flex-grow: 1;
  position: relative;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-bottom: 90px;
    overflow: hidden;
  }
  @media only screen and (max-width: 766px) {
    padding-bottom: 50px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  width: 100%;
  max-width: initial;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 !important;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    position: relative;
  }

  .socials-block {
    width: 50%;
    height: 90px;
    background: ${getStyleForProp('navbarBg')};
    align-items: center;
    position: absolute;
    bottom: 0;
    padding: 0 0 0 2vw;
    box-sizing: border-box;
    @media only screen and (max-width: 1300px) {
      height: 90px;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
    }
    @media only screen and (max-width: 766px) {
      height: 50px;
      padding: 0 100px 0 0;
    }
  }
  ${({ isSocialsBlockHidden, isEditing }) =>
    isSocialsBlockHidden && !isEditing
      ? css`
          .socials-block {
            @media only screen and (max-width: 1024px) {
              display: none;
            }
          }
          ${SliderWrapper} {
            padding-bottom: 0;
          }
        `
      : ''}
  .socials-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @media only screen and (max-width: 500px) {
      max-width: 300px;
      flex-wrap: nowrap;
    }
    .link {
      width: 36px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      fill: ${getStyleForProp('socialColor')};
    }
  }
  .slick-slide > div {
    margin: 0 !important;
    height: 100% !important;
    .slick-list {
      height: 100% !important;
    }
  }
  .mySlider {
    overflow: hidden;
    width: 100vw;
  }
`

const FigureContainer = styled.div`
  height: 100%;
  transform: translateY(0px) !important;
  position: relative;
  + .control-container {
    display: flex;
    justify-content: flex-end;
    max-width: 40%;
    min-width: 200px;
    position: absolute;
    right: 40px;
    bottom: 30px;
    p {
      position: static;
      max-width: unset;
    }
  }
  .lazy-figure {
    height: 101% !important;
  }
`

const ImageFigure = styled.div`
  height: 100%;
  .control-container {
    height: 100%;
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 100%;
    height: initial;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
  }
`

const HeaderContentWrapper = styled.div`
  width: 48%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 0 100px 0;
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding: 60px 0 0 0;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 650px;
  padding: 2vw 2vw 35px 2vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .buttons-block {
    margin-left: -5px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    padding-right: 40px;
    padding-left: 40px;
    .buttons-block {
      margin-left: -15px;
    }
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  > :first-child {
    padding-top: 0;
  }

  .control-container {
    margin-bottom: 30px;
    h1,
    p {
      margin-left: 0;
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-left: 10px;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  line-height: 1.4;
  margin-left: 10px;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 768px) {
    margin-left: 0;
  }
`

const SliderItemWrapper = styled.div`
  padding: ${({ isEditing }) => (isEditing ? '50px 0 0 0' : '0')};
  height: 100%;
  position: relative;
  box-sizing: border-box;
`

const SliderIdx = styled.div`
  position: absolute;
  top: ${({ isEditing }) => (isEditing ? '90px' : '40px')};
  left: 30px;
  z-index: 1;
  color: ${getStyleForProp('heading')};
  font-size: 20px;
  .active-slide-idx {
    font-weight: 600;
    width: 28px;
    display: inline-block;
  }
`

const ModelName = styled.p`
  max-width: 40%;
  position: absolute;
  right: 40px;
  bottom: 30px;
  font-size: 18px;
  z-index: 4;
`

const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Content = styled.div`
  display: flex;
`

const SliderActionButtons = styled.div`
  width: 180px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  &#slider-1 {
    visibility: hidden;
  }
  & > button:first-child {
    background: transparent;
  }
  & > button:nth-child(2) {
    background: ${getStyleForProp('leftArrowBackground')};
  }
  @media only screen and (max-width: 1024px) {
    left: initial;
    right: 0;
    transform: initial;
  }
  @media only screen and (max-width: 766px) {
    height: 50px;
    width: 100px;
  }
`

const IconContainer = styled.button`
  width: 90px;
  min-width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s linear;
  padding: 0;
  border: none;
  &:focus {
    outline: 1px solid #5690ff;
  }
  @media only screen and (max-width: 766px) {
    height: 50px;
    width: 50px;
    min-width: 50px;
  }
`

const AddButtonWrap = styled.div`
  position: absolute;
  left: 100px;
  bottom: 20px;
  @media only screen and (max-width: 1024px) {
    bottom: 120px;
    left: 20px;
    transform: initial;
  }
  @media only screen and (max-width: 766px) {
    bottom: 80px;
  }
`

const ArrowIcon = styled(LibIcon).attrs(({ isLeft }) => ({
  color: isLeft
    ? getStyleForProp('leftArrowBackground')
    : getStyleForProp('rightArrowBackground')
}))`
  padding: 20px;
  @media only screen and (max-width: 766px) {
    padding: 10px;
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('socialColor')
}))``

export default {
  WmCustomContainer,
  FigureContainer,
  ImageFigure,
  SliderWrapper,
  SliderItemWrapper,
  IconContainer,
  HeaderContentWrapper,
  HeaderContent,
  Content,
  Img,
  StyledContainer,
  Title,
  Paragraph,
  SliderIdx,
  SliderActionButtons,
  AddButtonWrap,
  ModelName,
  Icon,
  ArrowIcon
}
