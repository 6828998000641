/* @flow */
/* eslint-disable max-statements */

import React, { useState, useEffect, useContext, memo } from 'react'
import S from './PrivacyPolicy3.styled'
import { validation } from './PrivacyPolicy3.consts'
import Show from '@website/common/components/Show/Show'
import ActionGetter from './Actions'
import { DispatchContext, EditingContext, MobileDetectContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { Controls } from '@website/common/components/Controlled'
import Mapper from '@website/common/components/Mapper'
import SingleButton from '@website/common/components/SingleButton'
import AddButton from '@website/common/components/AddButton'
import Arrow from './Arrow'
import Icon from '@website/common/components/Icon'
import { translate } from '@editor/common/utils/translations'

const ActionButtons = memo(
  ({ addTextBlock, addListItem, subBlockLength, listItemLength }) => {
    return (
      <S.AddButtonsWrapper>
        <AddButton
          onAdd={addTextBlock}
          toShow={subBlockLength < validation.subBlock.max}
          style={{
            margin: '0 30px 15px 0'
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
        <AddButton
          onAdd={addListItem}
          toShow={listItemLength < validation.listItem.max}
          style={{
            marginBottom: '15px'
          }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_list_label')}
        </AddButton>
      </S.AddButtonsWrapper>
    )
  }
)

const PrivacyPolicy3 = memo(
  ({
    data: {
      title,
      titleAlignment,
      backgroundImgDimensions,
      backgroundImgUrl,
      backgroundTransparency,
      blocks,
      buttonText,
      buttonUrl
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const isMobile = useContext(MobileDetectContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const [activeTabIdx, setActiveTabIdx] = useState(0)

    const expandTab = idx => e => {
      const isTheSame = idx === activeTabIdx
      const needToScroll = !isTheSame && isMobile && !isEditing
      if (needToScroll) {
        setTimeout(() => {
          const elementPosition = e.target.getBoundingClientRect().top
          const headerOffset = 100
          const offsetPosition =
            elementPosition + window.pageYOffset - headerOffset
          window.scrollTo({ top: offsetPosition })
        })
      }
      setActiveTabIdx(isTheSame && isMobile ? null : idx)
    }
    useEffect(() => {
      if (activeTabIdx > blocks.length - 1) {
        setActiveTabIdx(blocks.length - 1)
      }
    }, [blocks.length])

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        transparent={backgroundTransparency}
      >
        <S.WMCustomContainer>
          <S.CardWrap>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              required={true}
              maxCount={validation.maxTitleChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <S.BlocksContainer isEditing={isEditing}>
              <S.BlockTitlesWrapper isEditing={isEditing}>
                <Mapper
                  data={blocks}
                  render={(block, idx) => (
                    <Controls
                      actions={Actions.blockActions({
                        idx,
                        showRemove: blocks.length > validation.blocks.min
                      })}
                    >
                      <S.BlockTitleContainer isActiveTab={idx === activeTabIdx}>
                        <S.BlockTitleWrapper onClick={expandTab(idx)}>
                          <EditableContent
                            isLinkControlHidden
                            toPropogate={false}
                            key={idx}
                            text={block.blockTitle}
                            as={S.BlockTitle}
                            maxCount={validation.maxBlockTitleChar}
                            className="WM_GLOBAL_paragraph18"
                            onChange={newBlockTitle => {
                              Actions.changeBlockTitle(newBlockTitle, idx)
                            }}
                            required={true}
                          />
                          {isMobile && (
                            <S.ArrowWrapper>
                              <Arrow />
                            </S.ArrowWrapper>
                          )}
                        </S.BlockTitleWrapper>
                        {isMobile && idx === activeTabIdx && (
                          <S.MobileContentWrapper>
                            <S.SubBlockWrapper>
                              <Mapper
                                data={blocks[activeTabIdx]?.subBlocks}
                                render={(subBlock: string, idx: number) => {
                                  return (
                                    <div>
                                      <S.ControlContainer isEditing={isEditing}>
                                        <Controls
                                          actions={Actions.subBlockItemActions(
                                            activeTabIdx,
                                            idx,
                                            block.subBlocks.length >
                                              validation.subBlock.min
                                          )}
                                        >
                                          <S.Block isEditing={isEditing}>
                                            <EditableContent
                                              text={subBlock.title}
                                              as={S.SubBlockTitle}
                                              alignment={
                                                subBlock.subBlockTitleAlignment
                                              }
                                              required={!subBlock.paragraph}
                                              maxCount={
                                                validation.maxSubBlockTitleChar
                                              }
                                              className="WM_GLOBAL_heading20"
                                              onChange={newSubBlockTitle => {
                                                Actions.changeSubBlockTitle(
                                                  activeTabIdx,
                                                  idx,
                                                  newSubBlockTitle
                                                )
                                              }}
                                              changeAlignment={(
                                                alignment: string
                                              ) =>
                                                Actions.changeSubBlockTitleAlignment(
                                                  activeTabIdx,
                                                  idx,
                                                  alignment
                                                )
                                              }
                                            />
                                            <EditableContent
                                              text={subBlock.paragraph}
                                              as={S.SubBlockParagraph}
                                              alignment={
                                                subBlock.subBlockParagraphAlignment
                                              }
                                              maxCount={
                                                validation.maxSubBlockParagraphChar
                                              }
                                              required={!subBlock.title}
                                              className="WM_GLOBAL_paragraph18"
                                              onChange={newSubBlockParagraph => {
                                                Actions.changeSubBlockParagraph(
                                                  activeTabIdx,
                                                  idx,
                                                  newSubBlockParagraph
                                                )
                                              }}
                                              changeAlignment={(
                                                alignment: string
                                              ) =>
                                                Actions.changeSubBlockParagraphAlignment(
                                                  activeTabIdx,
                                                  idx,
                                                  alignment
                                                )
                                              }
                                            />
                                            {subBlock.list.length ? (
                                              <Mapper
                                                data={subBlock.list}
                                                render={(
                                                  listItem,
                                                  listItemIdx
                                                ) => {
                                                  return (
                                                    <Show when={[listItem]}>
                                                      <S.ListItemWrap
                                                        isEditing={isEditing}
                                                      >
                                                        <Controls
                                                          actions={Actions.listItemActions(
                                                            activeTabIdx,
                                                            idx,
                                                            listItemIdx
                                                          )}
                                                          alignment="right"
                                                        >
                                                          <EditableContent
                                                            text={listItem}
                                                            as={
                                                              S.ListItemParagraph
                                                            }
                                                            maxCount={
                                                              validation.maxListItemParagraphChar
                                                            }
                                                            className="WM_GLOBAL_paragraph18"
                                                            onChange={(
                                                              newListItem: string
                                                            ) =>
                                                              Actions.changeListItem(
                                                                activeTabIdx,
                                                                idx,
                                                                listItemIdx,
                                                                newListItem
                                                              )
                                                            }
                                                          />
                                                        </Controls>
                                                      </S.ListItemWrap>
                                                    </Show>
                                                  )
                                                }}
                                              />
                                            ) : null}
                                          </S.Block>
                                        </Controls>
                                      </S.ControlContainer>
                                      {isEditing && (
                                        <ActionButtons
                                          addTextBlock={() =>
                                            Actions.addTextBlock(
                                              activeTabIdx,
                                              idx
                                            )
                                          }
                                          addListItem={() =>
                                            Actions.addListItem(
                                              activeTabIdx,
                                              idx
                                            )
                                          }
                                          subBlockLength={
                                            blocks[activeTabIdx]?.subBlocks
                                              .length
                                          }
                                          listItemLength={subBlock.list.length}
                                        />
                                      )}
                                    </div>
                                  )
                                }}
                              />
                            </S.SubBlockWrapper>
                          </S.MobileContentWrapper>
                        )}
                      </S.BlockTitleContainer>
                    </Controls>
                  )}
                />
                <AddButton
                  onAdd={Actions.addBlock}
                  toShow={blocks.length < validation.blocks.max}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_tab_label')}
                </AddButton>
                <SingleButton btnUrl={buttonUrl} btnText={buttonText} />
              </S.BlockTitlesWrapper>
              {!isMobile && (
                <S.ContentWrapper>
                  <Mapper
                    data={blocks}
                    render={(block: string, blockIdx: number) => {
                      return (
                        <S.SubBlockWrapper
                          className={
                            blockIdx === activeTabIdx
                              ? 'active-block'
                              : 'hidden-block'
                          }
                        >
                          <Mapper
                            data={block.subBlocks}
                            render={(subBlock: string, idx: number) => {
                              return (
                                <div>
                                  <S.ControlContainer isEditing={isEditing}>
                                    <Controls
                                      actions={Actions.subBlockItemActions(
                                        activeTabIdx,
                                        idx,
                                        block.subBlocks.length >
                                          validation.subBlock.min
                                      )}
                                    >
                                      <S.Block isEditing={isEditing}>
                                        <EditableContent
                                          text={subBlock.title}
                                          as={S.SubBlockTitle}
                                          required={!subBlock.paragraph}
                                          alignment={
                                            subBlock.subBlockTitleAlignment
                                          }
                                          maxCount={
                                            validation.maxSubBlockTitleChar
                                          }
                                          className="WM_GLOBAL_heading20"
                                          onChange={newSubBlockTitle => {
                                            Actions.changeSubBlockTitle(
                                              activeTabIdx,
                                              idx,
                                              newSubBlockTitle
                                            )
                                          }}
                                          changeAlignment={(
                                            alignment: string
                                          ) =>
                                            Actions.changeSubBlockTitleAlignment(
                                              activeTabIdx,
                                              idx,
                                              alignment
                                            )
                                          }
                                        />
                                        <EditableContent
                                          text={subBlock.paragraph}
                                          as={S.SubBlockParagraph}
                                          maxCount={
                                            validation.maxSubBlockParagraphChar
                                          }
                                          alignment={
                                            subBlock.subBlockParagraphAlignment
                                          }
                                          required={!subBlock.title}
                                          className="WM_GLOBAL_paragraph18"
                                          onChange={newSubBlockParagraph => {
                                            Actions.changeSubBlockParagraph(
                                              activeTabIdx,
                                              idx,
                                              newSubBlockParagraph
                                            )
                                          }}
                                          changeAlignment={(
                                            alignment: string
                                          ) =>
                                            Actions.changeSubBlockParagraphAlignment(
                                              activeTabIdx,
                                              idx,
                                              alignment
                                            )
                                          }
                                        />
                                        {subBlock.list.length ? (
                                          <Mapper
                                            data={subBlock.list}
                                            render={(listItem, listItemIdx) => {
                                              return (
                                                <Show when={[listItem]}>
                                                  <S.ListItemWrap
                                                    isEditing={isEditing}
                                                  >
                                                    <Controls
                                                      actions={Actions.listItemActions(
                                                        activeTabIdx,
                                                        idx,
                                                        listItemIdx
                                                      )}
                                                      alignment="right"
                                                    >
                                                      <EditableContent
                                                        text={listItem}
                                                        as={S.ListItemParagraph}
                                                        maxCount={
                                                          validation.maxListItemParagraphChar
                                                        }
                                                        className="WM_GLOBAL_paragraph18"
                                                        onChange={(
                                                          newListItem: string
                                                        ) =>
                                                          Actions.changeListItem(
                                                            activeTabIdx,
                                                            idx,
                                                            listItemIdx,
                                                            newListItem
                                                          )
                                                        }
                                                      />
                                                    </Controls>
                                                  </S.ListItemWrap>
                                                </Show>
                                              )
                                            }}
                                          />
                                        ) : null}
                                      </S.Block>
                                    </Controls>
                                  </S.ControlContainer>
                                  {isEditing && (
                                    <ActionButtons
                                      addTextBlock={() =>
                                        Actions.addTextBlock(activeTabIdx, idx)
                                      }
                                      addListItem={() =>
                                        Actions.addListItem(activeTabIdx, idx)
                                      }
                                      subBlockLength={
                                        blocks[activeTabIdx]?.subBlocks.length
                                      }
                                      listItemLength={subBlock.list.length}
                                    />
                                  )}
                                </div>
                              )
                            }}
                          />
                        </S.SubBlockWrapper>
                      )
                    }}
                  />
                </S.ContentWrapper>
              )}
            </S.BlocksContainer>
          </S.CardWrap>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default PrivacyPolicy3
