/* @flow */
import styled from 'styled-components'
import THEME from './Testimonials6.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isEditing }) =>
    isEditing ? '150px 0 40px 0' : '80px 0 40px 0'};

  @media only screen and (max-width: 1366px) {
    padding: ${({ isEditing }) =>
      isEditing ? '150px 0 50px 0' : '80px 0 50px 0'};
  }

  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) =>
      isEditing ? '120px 0 40px 0' : '60px 0 20px 0'};
  }
  > div {
    width: 100%;
    max-width: none;
  }

  & > div > .control-container {
    display: block;
  }

  & > div > .control-container:nth-child(1) {
    width: 90%;
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    & > h1 {
      width: 100%;
      box-sizing: border-box;
      max-width: none;
      margin: 0;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  & > div > .control-container:nth-child(2) {
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    & > p {
      max-width: none;
      margin: 0;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
  }

  .slick-arrow button {
    background: ${getStyleForProp('buttonBackground')};
    border-radius: ${getStyleForProp('iconRadius')}
  }
  .quote_svg__from {
    stop-color: ${({ theme }) => theme.palette.primaryLight};
  }
  .quote_svg__to {
    stop-color: ${({ theme }) => theme.palette.primaryDark};
  }
`
export const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    width: 100%;
  }
  > .control-container {
    max-width: 900px;
    h1,
    p {
      padding: 0;
    }
  }
  > .control-container:first-child {
    margin-bottom: 20px;
  }
`

export const Title = styled.h1`
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  box-sizing: border-box;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin-bottom: 0;
  }
`

export const Paragraph = styled.p`
  max-width: 900px;
  color: ${getStyleForProp('paragraph')};
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  box-sizing: border-box;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    padding-top: 20px;
  }
`

export const ClientWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s linear;
  padding: ${({ areAllImagesDeleted, isEditing }) =>
    isEditing
      ? '68px 30px 35px 30px'
      : areAllImagesDeleted && !isEditing
      ? '40px 30px 20px 30px'
      : '68px 30px 20px 30px'};
  border-radius: ${getStyleForProp('cardRadius')}
    ${getStyleForProp('cardRadius')} 0 0;
  border: 1px solid ${getStyleForProp('border')};
  background: ${getStyleForProp('cardBackgroundGradient')};
  position: relative;
  z-index: 1;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  > .control-container:not(:last-child) {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 0;
    }
  }
`

export const ImageFrame = styled.div`
  width: 95px;
  position: absolute;
  top: -47px;
  @media only screen and (max-width: 490px) {
    width: 85px;
    top: -42px;
  }
  .control-container {
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    background-color: ${getStyleForProp('cardBackground')};
    border-radius: ${getStyleForProp('iconRadius')};
    border: 1px solid ${getStyleForProp('border')};
    overflow: hidden;
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

export const Name = styled.h2`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: ${({ isEditing }) => (isEditing ? '15px' : '10px')};
  width: 100%;
  ${getTextStyleByAlignment};
`

export const Position = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
`

export const SliderContainer = styled.div`
  position: relative;
  > div {
    width: 100% !important;
    max-width: 1300px !important;
    @media only screen and (max-width: 1366px) {
      max-width: 100vw !important;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 400px !important;
    }
    @media only screen and (max-width: 490px) {
      max-width: 318px !important;
    }
  }
  .slick-initialized {
    .slick-slide {
      width: 435px !important;
      padding-top: ${({ isEditing }) => (isEditing ? '90px' : '57px')};
      transition: all 0.4s ease-in;
      @media only screen and (max-width: 1366px) {
        width: 415px !important;
      }
      @media only screen and (max-width: 1024px) {
        width: 385px !important;
        max-width: 385px !important;
      }
      @media only screen and (max-width: 992px) {
        padding-top: 70px;
      }
      @media only screen and (max-width: 490px) {
        max-width: 318px !important;
      }
      @media only screen and (min-width: 1025px) {
        &:not(.slick-center) {
          margin-top: 10px;
          margin-bottom: 10px;
        }
        &.slick-center {
          .block-paragraph-wrap {
            padding-top: 40px;
            padding-bottom: 40px;
          }
          .block-item {
            box-shadow: 0px 6px 20px rgba(13, 3, 28, 0.149);
          }
        }
      }
      > div {
        margin: 0;
        z-index: 20;
      }
    }
  }
  .slick-slider {
    .slick-track {
      display: flex !important;
    }
    .rf-add-btn {
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`

export const SlideCountContainer = styled.p`
  font-size: 16px;
  text-align: center;
  color: ${getStyleForProp('heading')};
  margin: 50px auto 20px auto;
`

export const BlockItem = styled.div`
  margin: 20px;
  flex-grow: 1;
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: 0px 6px 12px rgba(13, 3, 28, 0.149);
  @media only screen and (max-width: 1366px) {
    margin: 15px;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin: 20px 0;
  }
  + .styled-controls {
    right: 0;
    left: auto;
  }
`

export const BlockItemWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  .rf-add-btn {
    min-width: 136px;
    box-sizing: border-box;
  }
`
export const ItemParagraphWrap = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  border-radius: 0 0 ${getStyleForProp('cardRadius')}
    ${getStyleForProp('cardRadius')};
  border-left: 1px solid ${getStyleForProp('border')};
  border-right: 1px solid ${getStyleForProp('border')};
  border-bottom: 1px solid ${getStyleForProp('border')};
  background-color: ${getStyleForProp('cardBackground')};
  position: relative;
  transition: padding 0.4s ease-in;
  @media only screen and (max-width: 490px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

export const ItemParagraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
`

export const AddBtnWrapper = styled.div``

export const QuoteWrapper = styled.div`
  width: 35px;
  position: absolute;
  left: 30px;
  bottom: -20px;
  transition: all 0.4s linear;
  svg {
    width: 100%;
  }
  @media only screen and (max-width: 767px) {
    left: 20px;
  }
`
