/* @flow */
import styled, { css } from 'styled-components'

import THEME from './CTA8.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import { getBackground } from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  position: relative;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
  .LazyLoad {
    width: 100%;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  & > div:first-child {
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT' ? '0 0 0 2vw' : '0 2vw 0 0'};
      `}
  }
  @media only screen and (max-width: 992px) {
    align-items: center;
    & > div:first-child {
      ${({ theme }) =>
        css`
          margin: ${theme.orientation === 'RIGHT'
            ? '40px auto 0 auto'
            : '0 auto 40px auto'};
        `}
    }
  }
`

const HeaderContent = styled.div`
  text-align: left;
  width: 100%;
  max-width: 440px;
  > :first-child {
    padding-top: 0;
  }
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 992px) {
    text-align: center;
    max-width: initial;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  > .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('title')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const RatioWrapper = styled.div`
  &.ratio-wrapper-1,
  &.ratio-wrapper-2 {
    margin: 0 10px 10px 10px;
    @media only screen and (max-width: 375px) {
      margin: 0 5px 5px 5px;
    }
  }
  &.ratio-wrapper-3,
  &.ratio-wrapper-4 {
    margin: 10px 10px 0 10px;
    @media only screen and (max-width: 375px) {
      margin: 5px 5px 0 5px;
    }
  }
  &.ratio-wrapper-1,
  &.ratio-wrapper-4 {
    width: 34%;
    @media only screen and (max-width: 375px) {
      width: 35%;
    }
  }
  &.ratio-wrapper-2,
  &.ratio-wrapper-3 {
    width: 54%;
    max-width: 384px;
    @media only screen and (max-width: 375px) {
      width: 56%;
    }
  }
`

const ImageWrapper = styled.div`
  position: relative;
  &.img-wrapper-1,
  &.img-wrapper-4 {
    .lazy-figure,
    .ControlContainer-1,
    .ControlContainer-4 {
      padding-top: 100%;
    }
  }
  &.img-wrapper-2,
  &.img-wrapper-3 {
    .lazy-figure,
    .ControlContainer-2,
    .ControlContainer-3 {
      padding-top: 62.5%;
    }
  }
  .lazy-figure {
    border-radius: ${getStyleForProp('borderRadius')};
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: ${getStyleForProp('borderRadius')};
`

const GridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${({ theme }) =>
    css`
      justify-content: ${theme.orientation === 'RIGHT'
        ? 'flex-end'
        : 'flex-start'};
    `};
  width: 100%;
  max-width: 700px;
  font-size: 0;
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
`

const ButtonsBlock = styled.div`
  margin: 15px 0 -15px -15px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 992px) {
    justify-content: center;
    margin: 25px 0 0 0;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 30px auto 0 auto;
  }
  .add-button-wrap {
    > div {
      box-sizing: border-box;
      height: 50px;
      padding: 16px 30px;
      margin: 15px;
    }
  }
`

const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
  @media only screen and (max-width: 550px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BtnWrapper = styled.div`
  margin: 15px;
  @media only screen and (max-width: 550px) {
    max-width: 300px;
    margin: 15px 0;
  }
  .control-container {
    width: 100%;
  }
  .link-wrapper {
    width: 100%;
  }
  .link {
    border-radius: ${getStyleForProp('buttonRadius')};
    white-space: nowrap;
    ${props =>
      getStyleForProp(props.isTertiary ? 'raisedShadow' : 'buttonShadow')};
    ${props =>
      getStyleForProp(
        props.isTertiary ? 'buttonBackgroundTertiary' : 'buttonBackground'
      )(props)};
    background-repeat: no-repeat;
    text-decoration: none;
    width: 100%;
    max-width: 300px;
    padding: 0 40px;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    text-align: center;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    text-decoration: none !important;
    font-weight: 400;
    background-repeat: no-repeat !important;
    font-size: 14px;
    display: block;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  Paragraph,
  Img,
  GridWrapper,
  RatioWrapper,
  ImageWrapper,
  ButtonsContainer,
  ButtonsBlock,
  ButtonsWrap,
  BtnWrapper,
  ControlContainer
}
