/* @flow */
import styled from 'styled-components'
import THEME from './Slider2.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb
} from '@website/common/utils'

import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing ? 'padding-top: 130px;' : 'padding-top: 80px;'}
  @media only screen and (max-width: 1270px) {
    ${({ isSingleSlide }) =>
      isSingleSlide ? 'padding-bottom: 55px !important;' : ''}
  }
  @media only screen and (max-width: 457px) {
    ${({ isEditing }) =>
      isEditing ? 'padding-top: 130px;' : 'padding-top: 60px;'}
    ${({ isSingleSlide }) =>
      isSingleSlide ? 'padding-bottom: 35px !important;' : ''}
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  .dots-wrap {
    width: 100%;
  }
  .rf-image-slider-dots-container {
    width: 100%;
    flex-wrap: wrap;
  }
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  @media only screen and (max-width: 1440px) {
    width: 100%;
  }
  @media only screen and (max-width: 1270px) {
    flex-direction: column;
    align-items: center;
  }
  .slick-slide > div {
    margin: 0 25px 25px 25px;
  }
  .dots-wrap {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .rf-image-slider {
    @media only screen and (max-width: 550px) {
      width: 100%;
    }
  }
  .slick-list {
    padding-top: 50px;
  }
`

const TextSliderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  @media only screen and (max-width: 1270px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const HeadingsWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h1 {
      padding: 0;
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const FigureContainer = styled.div`
  position: relative;
  width: 100%;
`

const IconContainer = styled.button`
  padding: 0;
  border: none;
  width: 50px;
  min-width: 50px;
  margin: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  background: transparent;
  opacity: 0;
  transition: all 0.1s linear;
  position: absolute;
  z-index: 5;
  top: 50%;
  transform: translateY(-50%);
  user-select: none;
  &.left {
    left: 40px;
    z-index: 7;
    @media only screen and (max-width: 480px) {
      left: 20px;
    }
  }
  &.right {
    right: 40px;
    @media only screen and (max-width: 480px) {
      right: 20px;
    }
  }
`

const SliderMainContainer = styled.div`
  position: relative;
  width: 50%;
  max-width: 1200px;
  @media only screen and (max-width: 1270px) {
    width: 80%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  &:only-child {
    margin: auto;
  }
  &:hover {
    ${IconContainer} {
      opacity: 1;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : '')};
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
`

const SliderContainer = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  border: ${getStyleForProp('border')};
  font-size: 0;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
    overflow: hidden;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`
const SliderTitle = styled.h2`
  color: ${({ theme }) => theme.palette.tertiaryDark};
`
const SliderParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  line-height: 1.4;
`

const Dot = styled.button`
  padding: 0;
  border: none;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: none;
  }
`

const Circle = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getStyleForProp('buttonBackground')};
  border-radius: ${getStyleForProp('iconRadius')};
  pointer-events: none;
`

const TextItemsWrapper = styled.div`
  max-width: 520px;
  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
`

const TextItemWrap = styled.article`
  margin-top: 50px;
  @media only screen and (max-width: 1270px) {
    margin-top: 40px;
    margin-bottom: ${({ isEditing }) => (isEditing ? '0' : '-10px')};
  }
  .control-container {
    width: 100%;
  }
`

const TextItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  word-break: break-word;
  @media only screen and (max-width: 1270px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`
const CircleWrap = styled.div``

const TextContent = styled.div`
  margin-left: 30px;
  width: 100%;
  @media only screen and (max-width: 1270px) {
    text-align: center;
    margin-left: 0;
  }
`

const TextTitleWrap = styled.div`
  min-height: 60px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1270px) {
    justify-content: center;
  }
`

const TextTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  width: 100%;
  font-size: 20px;
`

const TextParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
`

export default {
  Img,
  Dot,
  Title,
  StyledContainer,
  WmCustomContainer,
  TextSliderContainer,
  HeadingsWrap,
  FigureContainer,
  SliderMainContainer,
  Paragraph,
  SliderTitle,
  IconContainer,
  SliderParagraph,
  SliderContainer,
  TextItemsWrapper,
  TextItemWrap,
  TextItem,
  CircleWrap,
  Circle,
  TextContent,
  TextTitle,
  TextParagraph,
  TextTitleWrap,
  ControlContainer
}
