/* @flow */
import React, { useContext, memo, useCallback } from 'react'
import Swipe from '../Swipe'
import S from './Steps1.styled'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import { validation } from './Steps1.consts'
import { DispatchContext, EditingContext } from '@contexts'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Steps1 = memo(props => {
  const {
    data: { slides, title, paragraph, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const showRemove = slides.length > validation.slides.min
  const { isEditing } = useContext(EditingContext)
  const renderDots = useCallback(
    (isActive: boolean) => <S.Dot isActive={isActive} />,
    []
  )
  const renderLeftIcon = useCallback(
    () => (
      <S.IconContainer>
        <Icon
          className="left"
          color="white"
          size="middle"
          name="arrow_small_left"
        />
      </S.IconContainer>
    ),
    []
  )
  const renderRightIcon = useCallback(
    () => (
      <S.IconContainer>
        <Icon
          className="right"
          color="white"
          size="middle"
          name="arrow_small_right"
        />
      </S.IconContainer>
    ),
    []
  )
  const renderContent = (idx: number, stopAutoPlay: Function) => (
    <S.SlideContainer length={slides.length}>
      <S.FigureContainer>
        <S.FigureImg>
          <ControlsWithImageCropper
            toPropagate={false}
            style={{ width: '100%', height: '100%' }}
            actions={Actions.slideActions({
              additionalArgs: { idx },
              keyForSizes: 'steps1',
              showRemove,
              clickHandlers: { change: stopAutoPlay, crop: stopAutoPlay },
              imageUrl: slides[idx].imageUrl
            })}
          >
            <Image
              as={S.Img}
              alt="Slider image"
              sizes={slides[idx].imgDimensions}
              defaultImgSrc={slides[idx].imageUrl}
              asProps={{
                imgCropParams: slides[idx].imgCropParams
              }}
            />
          </ControlsWithImageCropper>
        </S.FigureImg>
      </S.FigureContainer>
      <S.TextContainer>
        <Show when={[slides[idx].slideTitle, slides[idx].slideContent]}>
          <Show when={[slides[idx].slideTitle]}>
            <EditableContent
              as={S.SliderTitle}
              maxCount={validation.blockTitleMaxChar}
              text={slides[idx].slideTitle}
              alignment={slides[idx].titleAlignment}
              className="WM_GLOBAL_heading20"
              onChange={(newTitle: string) => {
                Actions.changeSlideTitle(newTitle, idx)
              }}
              changeAlignment={(alignment: string) =>
                Actions.changeBlockTitleAlignment(alignment, idx)
              }
            />
          </Show>
          <Show when={[slides[idx].slideContent]}>
            <EditableContent
              className="WM_GLOBAL_paragraph"
              as={S.SliderParagraph}
              alignment={slides[idx].contentAlignment}
              maxCount={validation.blockParagraphMaxChar}
              onChange={(newContent: string) => {
                Actions.changeSlideContent(newContent, idx)
              }}
              text={slides[idx].slideContent}
              changeAlignment={(alignment: string) =>
                Actions.changeBlockContentAlignment(alignment, idx)
              }
            />
          </Show>
        </Show>
      </S.TextContainer>
    </S.SlideContainer>
  )

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.headingMaxChar}
          className="WM_GLOBAL_heading"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          as={S.Paragraph}
          alignment={paragraphAlignment}
          maxCount={validation.paragraphMaxChar}
          className="WM_GLOBAL_paragraph"
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.ImageSlideContainer>
          <Swipe
            dataArray={slides}
            renderDots={renderDots}
            renderContent={renderContent}
            renderLeftIcon={renderLeftIcon}
            renderRightIcon={renderRightIcon}
          />
        </S.ImageSlideContainer>
        <AddButton
          onAdd={Actions.addSlide}
          toShow={slides.length < validation.slides.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Steps1
