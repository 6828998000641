// @flow

import React, { useCallback, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import Preview from '../../Preview'

import {
  getProjectData,
  selectSiteHash,
  selectWidgetsData,
  selectMetaSettings,
  selectLanguageMenuLayout,
  selectEventsData
} from '@selectors'
import { selectCookieSettingsData } from '@selectors/cookie-settings'
import { selectWebsiteLanguagesData } from '@selectors/website-languages'
import { EDITOR_BASE } from '@editor/conf/routes'

const mapStateToProps = state => ({
  projectData: getProjectData(state),
  cookieSettings: selectCookieSettingsData(state),
  widgets: selectWidgetsData(state),
  meta: selectMetaSettings(state),
  siteHash: selectSiteHash(state),
  websiteLanguagesData: selectWebsiteLanguagesData(state),
  languageMenuLayout: selectLanguageMenuLayout(state),
  events: selectEventsData(state)
})

const SitePreview = ({
  history,
  match,
  projectData,
  widgets,
  meta,
  cookieSettings,
  websiteLanguagesData,
  siteHash,
  languageMenuLayout,
  events
}) => {
  const { siteId, lang } = match.params
  const { url } = useRouteMatch(EDITOR_BASE)
  const { search } = history.location

  useEffect(() => {
    window.setLangInUrl = lang =>
      history.push({ pathname: `/${siteId}/${lang}/preview-project` })
  }, [siteId, history])

  const goBack = useCallback(() => {
    history.push({ pathname: `${url}/edit/home`, search })
  }, [history, url])

  const iframeSrc = useMemo(
    () =>
      `${window.location.origin}/website-maker/preview/${lang}/site/${siteId}${search}`,
    [siteId, search]
  )

  return (
    <Preview
      projectId={siteId}
      iframeSrc={iframeSrc}
      projectData={{
        isLoading: false,
        payload: {
          data: projectData,
          cookieSettings,
          widgets,
          meta,
          languageMenuLayout,
          events
        }
      }}
      siteHash={siteHash}
      websiteLanguagesData={websiteLanguagesData}
      goBack={goBack}
    />
  )
}

export default connect(mapStateToProps)(SitePreview)
