import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    raisedShadow: () => 'none',
    title: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    firstSquare: p => p.tertiarySemiLight,
    secondSquare: p => p.primaryLight,
    thirdSquare: p => p.secondaryLight,
    firstFigure: p => p.primaryLight,
    thirdFigure: p => p.tertiarySemiLight,
    firstCircle: p => p.tertiarySemiLight,
    secondCircle: p => p.secondaryLight,
    fourthCircle: p => p.primaryLight,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    title: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    firstSquare: p => p.primaryDark,
    secondSquare: p => p.tertiaryDark,
    thirdSquare: p => p.primaryDark,
    firstFigure: p => p.tertiaryDark,
    thirdFigure: p => p.tertiaryDark,
    firstCircle: p => p.primaryDark,
    secondCircle: p => p.tertiarySemiLight,
    fourthCircle: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`
  },
  EXTRALIGHT: {
    title: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    firstSquare: p => p.primaryDark,
    secondSquare: p => p.tertiaryDark,
    thirdSquare: p => p.secondaryLight,
    firstFigure: p => p.primaryLight,
    thirdFigure: p => p.tertiaryDark,
    firstCircle: p => p.primaryDark,
    secondCircle: p => p.secondaryDark,
    fourthCircle: p => p.primaryLight,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.2)`
  }
}
