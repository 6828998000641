/* @flow */
import styled from 'styled-components'

import THEME from './Text5_2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
`

const WmCustomContainer = styled(WMContainer)`
  word-break: break-word;
  display: flex;
  flex-direction: column;
  max-width: 960px;
  margin: auto;
  @media only screen and (max-width: 1120px) {
    max-width: unset;
    width: calc(100% - 160px);
    margin-right: 80px;
    margin-left: 80px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }
  .control-container {
    width: auto;
    margin-top: 60px;
    h1 {
      margin-bottom: 0;
    }
  }

  & > .control-container {
    width: 100%;
    margin-top: 30px;
    p {
      margin-top: 0;
    }
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 0;
  & > .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  display: inline;
  &:focus {
    display: block;
    background-image: none;
    overflow: initial;
    margin-bottom: 0;
  }
  transition: background-size 0.15s linear 0.1s, box-shadow 0.15s linear 0s;
  background-image: linear-gradient(
    to bottom,
    ${getStyleForProp('headingLine')} 0%,
    ${getStyleForProp('headingLine')} 100%
  );
  background-size: 100% 12px;
  background-repeat: no-repeat;
  background-position: 0 bottom;
  overflow: hidden;
  &:hover {
    cursor: pointer;
    background-size: 100% 100%;
    background-image: linear-gradient(
      to bottom,
      ${getStyleForProp('headingLine')} 0%,
      ${getStyleForProp('headingLine')} 100%
    );
    transition: background-size 0.1s linear 0s, box-shadow 0.1s linear 0.1s;
  }
`
const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
  max-width: 960px;
  margin-top: 30px;
`
export default {
  StyledContainer,
  WmCustomContainer,
  TitleWrapper,
  Title,
  Description
}
