/* @flow */
import React from 'react'
import Slider from 'react-slick'
import S from './Header22.styled'
import THEME from './Header22.theme'
import ActionGetter from './Actions'
import { validation, slickSliderSettings } from './Header22.consts'
import Image from '@website/common/components/Image'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { getFormatedNumber } from '@website/common/utils'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { DispatchContext, withEditingContext } from '@contexts'
import Buttons from '@website/common/components/Buttons'
import '../Swipe/swipe-slider-styles.css'
import SocialLinks from '@website/common/components/SocialLinks'
import { translate } from '@editor/common/utils/translations'

// const { isEditing } = useContext(EditingContext)
const ContainerWithEditingContext = withEditingContext(S.StyledContainer)
const SliderWrapperWithEditingContext = withEditingContext(S.SliderItemWrapper)
const SliderIdxWithEditingContext = withEditingContext(S.SliderIdx)
const WmCustomContainerEditingContext = withEditingContext(S.WmCustomContainer)

class Header22 extends React.PureComponent<void, void> {
  dispatcher = this.context
  sliderRef: null
  state = { activeSlideIdx: 0, navBarHeight: 0 }

  handleHeightChange = height => {
    this.setState({ navBarHeight: height })
  }

  getPrevIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + slides.length - 1) % slides.length
  }
  getNextIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + 1) % slides.length
  }
  onPrevClick = () => {
    const { activeSlideIdx } = this.state
    this.changeActiveSlideIndex(this.getPrevIdx(activeSlideIdx))
    this.sliderRef.slickPrev()
  }

  onNextClick = () => {
    const { activeSlideIdx } = this.state
    this.changeActiveSlideIndex(this.getNextIdx(activeSlideIdx))
    this.sliderRef.slickNext()
  }

  changeActiveSlideIndex = (activeSlideIdx: number) =>
    this.setState({
      activeSlideIdx
    })

  getSliderCounter = () => {
    const { activeSlideIdx } = this.state
    const { slides } = this.props.data
    //start counter from 1
    const activeIdx = activeSlideIdx + 1
    return (
      <SliderIdxWithEditingContext>
        <span className="active-slide-idx">
          {`${getFormatedNumber(activeIdx)}`}
        </span>
        {`/${getFormatedNumber(slides.length)}`}
      </SliderIdxWithEditingContext>
    )
  }
  renderSlideImg = Actions => (slide, idx) => {
    const { slides } = this.props.data
    return (
      <SliderWrapperWithEditingContext>
        <S.FigureContainer>
          <S.ImageFigure>
            <ControlsWithImageCropper
              key={idx}
              toPropagate={false}
              actions={Actions.sliderActions(slides.length > 1, idx, slide.url)}
            >
              <Image
                as={S.Img}
                defaultImgSrc={slide.url}
                sizes={slide.imgDimensions}
                alt="Section image"
                asProps={{ imgCropParams: slide.imgCropParams }}
              />
            </ControlsWithImageCropper>
          </S.ImageFigure>
        </S.FigureContainer>
        <EditableContent
          text={slide.model}
          as={S.ModelName}
          maxCount={validation.modelMaxChar}
          className="WM_GLOBAL_paragraph"
          onChange={(newModel: string) => Actions.changeModel(newModel, idx)}
        />
      </SliderWrapperWithEditingContext>
    )
  }

  renderSlider = Actions => {
    const { slides } = this.props.data
    return (
      <S.SliderWrapper>
        <>
          <Show when={[slides.length > 1]}>{this.getSliderCounter()}</Show>
          <Slider
            {...slickSliderSettings}
            className="slider mySlider"
            ref={ref => (this.sliderRef = ref)}
            afterChange={activeSlideIdx => this.setState({ activeSlideIdx })}
          >
            {slides.map(this.renderSlideImg(Actions))}
          </Slider>
        </>
        <S.AddButtonWrap>
          <AddButton
            onAdd={Actions.addSlide}
            toShow={slides.length < validation.slides.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_slide_label')}
          </AddButton>
        </S.AddButtonWrap>
      </S.SliderWrapper>
    )
  }

  renderTextContent = Actions => {
    const {
      data: { title, paragraph, buttons, titleAlignment, paragraphAlignment }
    } = this.props
    return (
      <S.HeaderContentWrapper>
        <S.HeaderContent>
          <Show when={[title]}>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading54"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
          </Show>
          <Show when={[paragraph]}>
            <EditableContent
              text={paragraph}
              as={S.Paragraph}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </Show>
          <Buttons data={buttons} />
        </S.HeaderContent>
      </S.HeaderContentWrapper>
    )
  }

  renderActionsBar = slides => {
    return (
      <S.SliderActionButtons className="actions-bar" id={'slider-' + slides}>
        <S.IconContainer
          className="wm-slider-btn left"
          onClick={this.onPrevClick}
        >
          <S.ArrowIcon
            size="xlarge"
            mobileSize="small"
            name="keyboard_arrow_left"
            isLeft
          />
        </S.IconContainer>
        <S.IconContainer
          className="wm-slider-btn right"
          onClick={this.onNextClick}
        >
          <S.ArrowIcon
            size="xlarge"
            mobileSize="small"
            name="keyboard_arrow_right"
          />
        </S.IconContainer>
      </S.SliderActionButtons>
    )
  }

  render() {
    const {
      data: { logoSrc, logoColor, logoWidth, logoDimensions, socials, slides }
    } = this.props

    const Actions = ActionGetter(this.dispatcher)

    return (
      <ContainerWithEditingContext topOffset={this.state.navBarHeight}>
        <NavBar
          handleHeightChange={this.handleHeightChange}
          alwaysShowBackground
          theme={THEME}
          logoProps={{
            logoSrc: logoSrc,
            logoColor: logoColor,
            logoWidth: logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <WmCustomContainerEditingContext
          className="header-22-container"
          isSocialsBlockHidden={socials.length === 0 && slides.length === 1}
        >
          {this.renderTextContent(Actions)}
          {this.renderSlider(Actions)}
          {this.renderActionsBar(slides.length)}
          <SocialLinks
            className="header-socials"
            socials={socials}
            componentDispatcher={this.dispatcher}
            socialsMaxCount={validation.socials.max}
          />
        </WmCustomContainerEditingContext>
      </ContainerWithEditingContext>
    )
  }
}

Header22.contextType = DispatchContext

export default Header22
