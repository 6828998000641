import { useEffect, useRef } from 'react'
import { getDate, getDateDistance } from '@website/common/utils'

// TODO: Take a look this logic later

const useCountdownDatePicker = (date, setDate) => {
  const dateChanger = useRef(null)

  const countdownDatePicker = () => {
    dateChanger.current = setInterval(() => {
      const distance = getDateDistance(date)
      if (distance < 0) {
        clearInterval(dateChanger.current)
        dateChanger.current = null
      }
      setDate(getDate(date))
    }, 1000)
  }

  useEffect(() => {
    countdownDatePicker()
    return () => {
      if (dateChanger.current !== null) {
        clearInterval(dateChanger.current)
      }
    }
  }, [date])
}

export default useCountdownDatePicker
