/* @flow */

export const validation = {
  titleMaxChar: 150,
  paragraphMaxChar: 600,
  slides: {
    min: 1,
    max: 10
  }
}

export const sliderSettings = {
  dots: false,
  arrows: false,
  autoplaySpeed: 5000,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  vertical: true,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        vertical: false,
        verticalSwiping: false
      }
    }
  ]
}
