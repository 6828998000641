/* @flow */
import React, { useContext, memo } from 'react'
import S from './Email2.styled'
import { validation } from './Email2.consts'
import THEME from './Email2.theme'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { SubscribeForm } from '../SubscribeForm'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'

const Email2 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      placeholder,
      buttonText,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WMCustomContainer isEditing={isEditing}>
        <Show when={[title, paragraph]}>
          <S.EmailContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.maxTitleChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading"
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Description}
                maxCount={validation.maxParagraphChar}
                onChange={Actions.changeParagraph}
                className="WM_GLOBAL_paragraph"
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.EmailContent>
        </Show>
        <S.FormWrap>
          <SubscribeForm
            actions={{
              buttonActions: Actions.getBtnActions(buttonText),
              placeHolderAction: Actions.changePlaceHolderText
            }}
            buttonText={buttonText}
            placeholder={placeholder}
            theme={THEME}
          />
        </S.FormWrap>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Email2
