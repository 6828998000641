// @flow

import React, { memo, useEffect, useRef, useState } from 'react'

import { LinkWrapperContext } from '@contexts'
import { PREVIEW_BASE } from '@editor/conf/routes'

import Page from '../Page'

const SITE_GENERATING_DURATION = 20000
const AIPresetPreview = () => {
  const timerRef = useRef(null)
  const [isScrolling, setIsScrolling] = useState(
    window.parent?.isAIWebsiteGenerating
  )
  const [projectData, setProjectData] = useState(window.parent.projectData)

  const scrollToComp = id => {
    const comp = document.getElementById(`comp-${id}`)
    if (comp) {
      comp.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(() => {
    window.parent.setIsScroll = state => {
      setIsScrolling(state)

      if (!state) {
        scrollToComp(0)
        clearTimeout(timerRef.current)
      }
    }

    window.parent.setProjectData = data => {
      setProjectData(data)
    }
  }, [])

  useEffect(() => {
    const components = projectData.data?.pages[0]?.components || []
    const compCount = components.length
    const interval = Math.floor(SITE_GENERATING_DURATION / compCount)

    if (!isScrolling) {
      clearTimeout(timerRef.current)
      return
    }

    function handleScroll(components, idx) {
      if (idx < compCount) {
        const uniqId = components[idx].uniqId

        timerRef.current = setTimeout(function () {
          handleScroll(components, idx + 1)
          scrollToComp(uniqId)
        }, interval)
      }
    }

    handleScroll(components, 1)

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [isScrolling])

  return (
    <LinkWrapperContext.Provider
      value={{ routeBase: `${PREVIEW_BASE}/ai-preset/` }}
    >
      <Page projectData={projectData} />
    </LinkWrapperContext.Provider>
  )
}

export default memo(AIPresetPreview)
