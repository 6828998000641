export default {
  DARK: {
    background: p => p.tertiaryDark,
    raisedShadow: () => 'none',
    title: p => p.accentWhite,
    subTitle: p => p.primaryLight,
    paragraph: p => p.accentWhite
  },
  LIGHT: {
    background: p => p.tertiaryLight,
    title: p => p.tertiaryDark,
    subTitle: p => p.primaryDark,
    paragraph: p => p.tertiaryNormal
  },
  EXTRALIGHT: {
    background: p => p.tertiaryExtraLight,
    title: p => p.tertiaryDark,
    subTitle: p => p.primaryDark,
    paragraph: p => p.tertiaryNormal
  }
}
