/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const MorphRight = ({ theme }) => {
  const { look, palette } = theme
  const isSharp = look === 'SHARP'
  const from = palette.primaryLight
  const to = palette.primaryDark
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="534.35"
      height="578.895"
      viewBox="0 0 534.35 578.895"
    >
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html: '.a{opacity:0.097;fill:url(#a);}'
          }}
        />
        <linearGradient
          id="a"
          x1="0.5"
          y1={1}
          x2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop stopColor={from} offset="0%" />
          <stop stopColor={isSharp ? from : to} offset="100%" />
        </linearGradient>
      </defs>
      <g className="a" transform="translate(-831.65 -71.018)">
        <path
          className="b"
          d="M-2072.073,12692.256v-504.125s-702.809-81.912-421.579,234.457S-2072.073,12692.256-2072.073,12692.256Z"
          transform="translate(3438.073 -12044.77)"
        />
        <path
          className="b"
          d="M63.547,68.265c13.5-7.361,13.5-54.84,0-63.306S-26.471-5.327,9.55,34.4,50.047,75.625,63.547,68.265Z"
          transform="translate(831.65 143.973) rotate(-82)"
        />
      </g>
    </svg>
  )
}

export default withTheme(MorphRight)
