/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_COPYRIGHT':
        draft.copyrightText = action.value
        return
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        return
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        return
      case 'REMOVE_SOCIAL':
        const { idx: socialIdx } = action.value
        draft.socials.splice(socialIdx, 1)
        return
      case 'CHANGE_SOCIAL_URL':
        const { idx, link } = action.value
        draft.socials[idx].socialUrl = link
        return
      case 'CHANGE_SOCIAL_ICON': {
        const { idx: idx, iconName } = action.value
        draft.socials[idx].icon = iconName
        return
      }
      case 'CHANGE_NAVBAR_LOGO':
        const { url, dimensions } = action.value
        draft.logoSrc = url
        draft.logoDimensions = dimensions || {}
        draft.logoWidth = null
        return
      case 'CHANGE_STORE_URL': {
        const { key, link } = action.value
        draft[key] = link
        return
      }
      case 'ADD_STORE_LINK': {
        const { key } = action.value
        draft[key] = initialState[key]
        return
      }
      case 'REMOVE_BUTTON':
        const { key } = action.value
        draft[key] = ''
        return
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoWidth = null
        draft.logoDimensions = {}
        return
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        return
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        return
    }
  })
}
