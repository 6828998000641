/* @flow */
import { mapModalActionMaker, navbarLogoActionMaker } from '../../ActionMakers'
import {
  logoResizeAction,
  changeCopyright
} from '@website/components/CommonActions'
import { privacyPolicyAction } from '@website/components/ActionMakers'

export default dispatcher => {
  const _changeCopyright = (newValue: string) => {
    dispatcher(changeCopyright(newValue))
  }

  return {
    logoResizeAction,
    privacyPolicyAction,
    changeCopyright: _changeCopyright,
    getLogoActions: navbarLogoActionMaker(dispatcher),
    getMapActions: mapModalActionMaker(dispatcher)
  }
}
