//@flow
import { patchLanguageMenuLayoutAPI, patchWebsiteLanguagesDataAPI } from '@api'
import { getSiteId, selectIsWebsiteMultilingual } from '@selectors'
import {
  setSettingsLoadingStatus,
  setSettingsSuccessMessage,
  triggerApiError,
  setWebsiteActiveLanguageCode,
  setHasChangesAfterPublish,
  setGlobalErrorMessage
} from '@actions'
import debounce from '@editor/common/utils/debounce'
import { SUCCESS_SAVED_MESSAGE } from '@editor/conf/consts'

const debouncedPatchLanguageMenuLayoutAPI = debounce(
  patchLanguageMenuLayoutAPI,
  1000
)

export const setLanguageTranslationState = (language, value) => ({
  type: 'SET_LANGUAGE_TRANSLATION_STATE',
  value: { language, value }
})

export const patchWebsiteLanguagesData =
  languagesData => (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const isWebsiteMultilingual = selectIsWebsiteMultilingual(state)

    dispatch(setSettingsLoadingStatus(true))

    patchWebsiteLanguagesDataAPI(siteId, languagesData)
      .then(({ data }) => {
        dispatch(setSettingsLoadingStatus(false))
        dispatch({
          type: 'SET_WEBSITE_LANGUAGES_DATA',
          value: data
        })
        if (!isWebsiteMultilingual) {
          const {
            lang: { code: primaryLangCode }
          } = data.find(lang => lang.isPrimary)

          dispatch(setWebsiteActiveLanguageCode(primaryLangCode))
          dispatch({ type: 'OPEN_SUCCESS_POPUP' })
        } else {
          dispatch(setSettingsSuccessMessage(SUCCESS_SAVED_MESSAGE))
          setTimeout(() => dispatch(setSettingsSuccessMessage('')), 5000)
        }
      })
      .catch(e => {
        dispatch(setSettingsLoadingStatus(false))
        dispatch(setGlobalErrorMessage(e.message))
        dispatch(triggerApiError(e))
      })
  }

export const closeSuccessPopup = () => ({
  type: 'CLOSE_SUCCESS_POPUP'
})

export const setLanguageMenuLayout = languageMenuLayout => ({
  type: 'SET_LANGUAGE_MENU_LAYOUT',
  value: languageMenuLayout
})

export const changeLanguageMenuLayout =
  (newLanguageMenuLayout: string) => (dispatch, getState) => {
    const siteId = getSiteId(getState())
    dispatch(setLanguageMenuLayout(newLanguageMenuLayout))

    debouncedPatchLanguageMenuLayoutAPI(siteId, newLanguageMenuLayout)
      .then(({ data: { languageMenuLayout, hasChangesAfterPublish } }) => {
        dispatch(setLanguageMenuLayout(languageMenuLayout))
        dispatch(setHasChangesAfterPublish(hasChangesAfterPublish))
      })
      .catch(err => dispatch(triggerApiError(err)))
  }
