/* @flow */
import { removeBlockActionMaker } from '@website/components/ActionMakers'

import {
  addBlock,
  changeTitle,
  removeBlock,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockContent = (idx, newContent) =>
    dispatcher({
      type: 'CHANGE_BLOCK_CONTENT',
      value: { idx, newContent }
    })

  const changeBlockPrimaryTitle = (title: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_PRIMARY_TITLE',
      value: { title, idx }
    })
  const changeBlockSecondaryTitle = (title: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_BLOCK_SECONDARY_TITLE',
      value: { title, idx }
    })

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changePrimaryTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PRIMARY_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }
  const changeSecondaryTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SECONDARY_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }
  const changeDescriptionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_DESCRIPTION_ALIGNMENT',
      value: { alignment, idx }
    })
  }
  return {
    addBlock: () => dispatcher(addBlock()),
    removeBlock,
    changeTitle: newTitle => dispatcher(changeTitle(newTitle)),
    changeParagraph: newParagraph => dispatcher(changeParagraph(newParagraph)),
    changeBlockContent,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockPrimaryTitle,
    changeBlockSecondaryTitle,
    changePrimaryTitleAlignment,
    changeSecondaryTitleAlignment,
    changeDescriptionAlignment,
    featureActions: removeBlockActionMaker(dispatcher)
  }
}
