/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header36.styled'
import THEME from './Header36.theme'
import { validation } from './Header36.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import Image from '@website/common/components/Image'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SingleButton from '@website/common/components/SingleButton'

const Header36 = memo(props => {
  const {
    data: {
      title,
      subTitle,
      paragraph,
      subTitleAlignment,
      titleAlignment,
      paragraphAlignment,
      imageUrl,
      imgDimensions,
      imgCropParams,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      btnText,
      btnUrl,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer>
        <S.HeaderContent topOffset={navBarHeight}>
          <S.LeftContent>
            <S.LeftLine />
            <S.TitleWrapper>
              <EditableContent
                text={title}
                isEditing={isEditing}
                alignment={titleAlignment}
                required={validation.title.required}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading42"
                as={S.Title}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </S.TitleWrapper>
            <S.ImageWrapper>
              <S.ControlContainer>
                <ControlsWithImageCropper
                  actions={Actions.getImgActions(imageUrl)}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    sizes={imgDimensions}
                    defaultImgSrc={imageUrl}
                    asProps={{ imgCropParams }}
                    alt="Hero illustration"
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.ImageWrapper>
          </S.LeftContent>
          <Show when={[subTitle, paragraph, btnText]}>
            <S.RightContent>
              <EditableContent
                text={subTitle}
                as={S.SubTitle}
                alignment={subTitleAlignment}
                maxCount={validation.subTitleMaxChar}
                className="WM_GLOBAL_heading42"
                onChange={Actions.changeSubTitle}
                changeAlignment={Actions.changeSubTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <SingleButton btnUrl={btnUrl} btnText={btnText} />
            </S.RightContent>
          </Show>
        </S.HeaderContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

Header36.contextType = DispatchContext

export default Header36
