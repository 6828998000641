// @flow

import { getUserAPI } from '@api'
import { triggerApiError } from '../editor'
export const fetchUserData = () => dispatch => {
  dispatch({ type: 'GET_USER_STARTED' })
  return getUserAPI()
    .then(({ data }) => {
      dispatch({ type: 'GET_USER_SUCCESS', value: data })
      return data
    })
    .catch(err => {
      dispatch({ type: 'GET_USER_FAILURE' })
      dispatch(triggerApiError(err))
      return Promise.reject(err)
    })
}

export const removeUserData = () => dispatch => {
  dispatch({
    type: 'REMOVE_USER_DATA'
  })
}
