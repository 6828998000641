//@flow
import React, { memo, useCallback, useState } from 'react'
import { translate } from '@editor/common/utils/translations'
import CopyIcon from '@editor/common/assets/svgr-icons/Copy_icon.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { copyToClipboard } from '@website/common/utils'

import * as Styled from './styled.js'
import type { TProps } from '../types'

const Keyword = ({ data }: TProps) => {
  const [isCopied, setIsCopied] = useState(false)

  const copyKeyword = useCallback(() => {
    if (!isCopied) {
      setIsCopied(true)
      copyToClipboard(data)
      setTimeout(() => setIsCopied(false), 2000)
    }
  }, [data, isCopied, setIsCopied])

  return (
    <Styled.Keyword>
      {data}
      <TooltipWrapper
        text={
          <Styled.TooltipContent>
            {isCopied ? translate('copied_label') : translate('copy_label')}
          </Styled.TooltipContent>
        }
        className="copy-icon-wrapper"
      >
        <CopyIcon onClick={copyKeyword} />
      </TooltipWrapper>
    </Styled.Keyword>
  )
}

export default memo(Keyword)
