import styled from 'styled-components'

export const SERPFeatures = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 5px;
    svg {
      fill: #8493bd;
    }

    &:not(.hidden-serp-features):hover {
      svg {
        fill: #545f7e;
      }
    }
  }
`

export const HiddenSERPFeaturesCount = styled.div`
  color: #252e48;
  border-bottom: 1px dashed #252e48;
  cursor: pointer;
  position: relative;

  &:hover {
    span {
      opacity: 0.75;
    }
  }
`

export const No = styled.p`
  color: #545f7e;
  font-size: 14px;
`
