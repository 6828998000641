/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer6.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  overflow: visible;
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '140px 0 0 0' : '50px 0 0 0')};
  @media only screen and (max-width: 1024px) {
    padding-top: 50px;
  }
  .socials-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
    @media only screen and (max-width: 767px) {
      margin-bottom: 40px;
    }
    .link {
      width: 26px;
      height: 26px;
      fill: ${getStyleForProp('socialColor')};
    }
    .control-container:nth-last-child(-n + 3) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
  }
`

const FooterContentWrap = styled.div`
  margin: 40px 0;
`

const SubscribeFormWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% - 150px);

  &:only-child {
    position: relative;
    width: 100%;
    label {
      text-align: right;
    }
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 40px 0 0 0;
    width: 100%;
  }
  .control-container {
    margin-bottom: 30px;
  }

  & > p {
    width: 100%;
    text-align: right;
    right: 0;
    left: initial;
    transform: initial;
    @media only screen and (max-width: 767px) {
      width: 100%;
      right: initial;
      left: 50%;
      transform: translateX(-50%);
      bottom: 55px;
      text-align: center;
    }
  }
  form {
    display: flex;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
    }

    button {
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      margin-top: 0;
      flex: none;
      @media only screen and (max-width: 650px) {
        margin: 10px 0 0 0;
      }
    }
  }
  .wm-email-input-wrapper {
    max-width: 300px;
    flex: none;
    @media only screen and (max-width: 767px) {
      transform: translateX(0);
      margin: 0 0 20px 0;
    }
  }
  .wm-email-input {
    width: 100%;
    max-width: 300px;
    height: 50px;
    @media only screen and (max-width: 767px) {
      transform: initial;
      width: 100%;
      max-width: initial;
    }
  }
  .success-message {
    @media only screen and (min-width: 651px) {
      width: auto;
    }
    @media only screen and (max-width: 1023px) {
      left: 50%;
      transform: translateX(-56%);
    }
    @media only screen and (max-width: 650px) {
      transform: translateX(-50%);
    }
  }

  & > .control-container {
    margin-right: 20px;
    transform: translateX(8px);

    @media only screen and (min-width: 1024px) {
      margin-bottom: 14px;
      max-width: 50%;
      transform: initial;
    }

    @media only screen and (max-width: 767px) {
      display: inline-block;
    }

    & > label {
      margin-right: 0;
      transform: initial;

      @media only screen and (min-width: 1024px) {
        max-width: unset;
      }

      @media only screen and (max-width: 1023px) {
        margin: 0;
      }
    }
  }
`

const Label = styled.label`
  font-weight: 600;
  color: ${getStyleForProp('socialColor')};
  max-width: calc(100% - 450px);
  margin-bottom: 0;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 14px;
    transform: initial;
    max-width: 100%;
  }
  @media only screen and (max-width: 767px) {
    transform: initial;
    text-align: center;
    display: inline-block;
  }
`

const FooterContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .logo-wrapper {
    display: block;
    position: relative;
    @media only screen and (max-width: 767px) {
      margin-right: 0px;
    }
  }
`

const FooterLogo = styled.img.attrs(({ _width }) => {
  return _width
    ? {
        style: { width: `${_width}px` }
      }
    : {}
})`
  height: auto;
  object-fit: contain;
  height: auto;
`

const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0;
  position: relative;
  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
`

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background: ${({ theme }) => theme.palette.tertiaryDark};
  opacity: 0.2;
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  color: ${getStyleForProp('paragraph')};
  @media only screen and (min-width: 768px) {
    box-sizing: border-box;
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            max-width: calc(100% - 210px);
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 0;
            }
          `
        : ''}
  }
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`

const LinkWrap = styled.div`
  position: relative;
  margin-left: 10px;
  max-width: 170px;
  &:hover {
    a {
      opacity: 0.6;
    }
  }
  ${({ isEditing }) =>
    isEditing
      ? css`
          .control-container {
            margin-left: 10px;
            @media only screen and (max-width: 767px) {
              margin-left: 0;
            }
          }
        `
      : css`
          .link-wrapper {
            margin-left: 10px;
            @media only screen and (max-width: 767px) {
              margin-left: 0;
            }
          }
        `}

  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 30px;
    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
`

const CopyRightWrapper = styled.div`
  width: 100%;
  display: flex;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

export default {
  StyledContainer,
  SubscribeFormWrap,
  FooterContentWrap,
  FooterContent,
  FooterLogo,
  Copyright,
  Footer,
  Divider,
  Label,
  CopyRightWrapper,
  LinkWrap
}
