/* @flow */

import React, { useContext } from 'react'
import S from './Text8.styled'
import { validation } from './Text8.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { EditingContext } from '@contexts'
import { DispatchContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import './Text8.styles.sass'
import Buttons from '@website/common/components/Buttons'

const Text8 = props => {
  const {
    data: {
      mainTitle,
      backgroundImgUrl,
      backgroundImgDimensions,
      title,
      paragraph,
      mainTitleAlignment,
      titleAlignment,
      paragraphAlignment,
      buttons
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <EditingContext.Consumer>
      {({ isEditing }) => {
        return (
          <S.Container isEditing={isEditing}>
            <WMContainer>
              <EditableContent
                areControlsHidden
                text={mainTitle}
                alignment={mainTitleAlignment}
                as={S.MainTitle}
                maxCount={validation.mainTitleMaxChar}
                className="WM_GLOBAL_headingExtraLarge"
                onChange={(newTitle: string) =>
                  dispatcher(Actions.changeMainTitle(newTitle))
                }
                isEditing={isEditing}
                backgroundImgUrl={backgroundImgUrl}
                backgroundImgDimensions={backgroundImgDimensions}
                changeAlignment={Actions.changeMainTitleAlignment}
              />
            </WMContainer>
            <S.WmCustomContainer>
              <Show when={[title, paragraph, ...buttons]}>
                <S.LeftBlock>
                  <EditableContent
                    text={title}
                    alignment={titleAlignment}
                    as={S.Title}
                    maxCount={validation.titleMaxChar}
                    className="WM_GLOBAL_heading42"
                    onChange={(newTitle: string) =>
                      dispatcher(Actions.changeTitle(newTitle))
                    }
                    changeAlignment={Actions.changeTitleAlignment}
                  />
                </S.LeftBlock>
                <Show when={[title, paragraph]}>
                  <S.Line
                    alignment={title ? titleAlignment : mainTitleAlignment}
                  />
                </Show>
                <S.TextButtons>
                  <EditableContent
                    text={paragraph}
                    alignment={paragraphAlignment}
                    as={S.Paragraph}
                    isEditing={isEditing}
                    maxCount={validation.paragraphMaxChar}
                    className="WM_GLOBAL_paragraph"
                    onChange={(newParagraph: string) =>
                      dispatcher(Actions.changeParagraph(newParagraph))
                    }
                    changeAlignment={Actions.changeParagraphAlignment}
                  />
                  <Buttons data={buttons} />
                </S.TextButtons>
              </Show>
            </S.WmCustomContainer>
          </S.Container>
        )
      }}
    </EditingContext.Consumer>
  )
}

export default Text8
