/* @flow */
import React, { useContext, useMemo, memo } from 'react'
import S from './PayPal5.styled'
import { validation } from './PayPal5.consts'
import { splitArrayIntoNParts } from './PayPal5.utils'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { translate } from '@editor/common/utils/translations'
import { useResponsive } from '@shared/hooks'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { PayPalButtonForm } from '@website/common/components/PayPalButton'
import { getCurrencySign } from '@website/common/utils'

const PayPal5 = memo(
  ({
    data: {
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      subtitle,
      subtitleAlignment,
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      blocks
    },
    componentId
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const isLargeScreen = useResponsive(1363)
    const isTablet = useResponsive(1024)
    const isMobile = useResponsive(650)
    const blocksCount = blocks.length

    const columnsLength = useMemo(() => {
      if (isMobile) return 1

      if (isTablet) return 2

      if (blocksCount === 5 || blocksCount === 6) {
        return 3
      }

      if (isLargeScreen) {
        return blocksCount === 4 ? 2 : 3
      }

      return 4
    }, [blocksCount, isMobile, isTablet, isLargeScreen])

    const groupedBlocks = splitArrayIntoNParts(blocks, columnsLength)

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
        isEditing={isEditing}
      >
        <S.WMCustomContainer>
          <EditableContent
            text={subtitle}
            as={S.Subtitle}
            alignment={subtitleAlignment}
            maxCount={validation.subtitleMaxChar}
            className="WM_GLOBAL_heading20"
            onChange={Actions.changeSubtitle}
            changeAlignment={Actions.changeSubtitleAlignment}
            required={!paragraph && !title}
          />
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
            required={!paragraph && !subtitle}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
            required={!title && !subtitle}
          />
          <S.BlockContainer>
            {groupedBlocks.map((block, idx) => {
              return (
                <S.Table key={idx}>
                  <S.TableRow>
                    {block.map((item, itemIdx) => {
                      const newIdx = idx * columnsLength + itemIdx

                      return (
                        <S.TableCell key={newIdx}>
                          <S.ImageWrapper>
                            <S.ControlContainer>
                              <ControlsWithImageCropper
                                actions={Actions.imageActions(
                                  newIdx,
                                  blocksCount,
                                  item.imgUrl
                                )}
                                style={{ width: '100%' }}
                              >
                                <Image
                                  as={S.Img}
                                  alt="Slider image"
                                  sizes={item.imgDimensions}
                                  defaultImgSrc={item.imgUrl}
                                  asProps={{
                                    imgCropParams: item.imgCropParams
                                  }}
                                />
                              </ControlsWithImageCropper>
                            </S.ControlContainer>
                          </S.ImageWrapper>
                        </S.TableCell>
                      )
                    })}
                  </S.TableRow>
                  <Show when={[block.some(blockItem => blockItem.paragraph)]}>
                    <S.TableRow className="block-paragraph-row">
                      {block.map((item, itemIdx) => {
                        const newIdx = idx * columnsLength + itemIdx
                        return (
                          <S.TableCell key={newIdx}>
                            <EditableContent
                              as={S.BlockParagraph}
                              text={item.paragraph}
                              className="WM_GLOBAL_paragraph14"
                              maxCount={validation.blockParagraphMaxChar}
                              alignment={item.paragraphAlignment}
                              onChange={(newBlockParagraph: string) => {
                                Actions.changeBlockParagraph(
                                  newBlockParagraph,
                                  newIdx
                                )
                              }}
                              changeAlignment={(alignment: string) =>
                                Actions.changeBlockParagraphAlignment(
                                  alignment,
                                  newIdx
                                )
                              }
                            />
                          </S.TableCell>
                        )
                      })}
                    </S.TableRow>
                  </Show>
                  <S.TableRow>
                    {block.map((item, itemIdx) => {
                      const { type, productName, headline } =
                        item.paypalBtnProps
                      const newIdx = idx * columnsLength + itemIdx
                      const paypalBtnDefaultProps = item.paypalBtnProps
                      return (
                        <S.TableCell key={newIdx} className="block-title">
                          <EditableContent
                            as={S.BlockTitle}
                            alignment={item.titleAlignment}
                            text={type === 'selling' ? productName : headline}
                            required
                            maxCount={validation.blockTitleMaxChar}
                            className="WM_GLOBAL_heading20"
                            isEditing={isEditing}
                            isTypingDisabled
                            isTextFormatingHidden
                            customActions={Actions.paypalButtonModalActions(
                              paypalBtnDefaultProps,
                              componentId,
                              { idx: newIdx }
                            )}
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockTitleAlignment(
                                alignment,
                                newIdx
                              )
                            }
                          />
                        </S.TableCell>
                      )
                    })}
                  </S.TableRow>
                  <S.TableRow>
                    {block.map((item, itemIdx) => {
                      const {
                        type,
                        currency,
                        productPrice,
                        productDiscountedPrice
                      } = item.paypalBtnProps
                      const paypalBtnDefaultProps = item.paypalBtnProps
                      const newIdx = idx * columnsLength + itemIdx
                      const isSelling = type === 'selling'
                      return (
                        <S.TableCell key={newIdx}>
                          {isSelling ? (
                            <S.PriceContainer>
                              <EditableContent
                                as={S.PriceCount}
                                text={`${getCurrencySign(
                                  currency
                                )} ${productPrice}`}
                                className="WM_GLOBAL_heading20"
                                isTypingDisabled
                                isTextFormatingHidden
                                customActions={Actions.paypalButtonModalActions(
                                  paypalBtnDefaultProps,
                                  componentId,
                                  { idx: newIdx }
                                )}
                              />
                              {productDiscountedPrice ? (
                                <EditableContent
                                  as={S.PrevPriceCount}
                                  text={`${getCurrencySign(
                                    currency
                                  )} ${productDiscountedPrice}`}
                                  className="WM_GLOBAL_heading20"
                                  isTypingDisabled
                                  isTextFormatingHidden
                                  customActions={Actions.paypalButtonModalActions(
                                    paypalBtnDefaultProps,
                                    componentId,
                                    { idx: newIdx }
                                  )}
                                />
                              ) : null}
                            </S.PriceContainer>
                          ) : null}
                        </S.TableCell>
                      )
                    })}
                  </S.TableRow>
                  <S.TableRow className="paypal-button-wrapper">
                    {block.map((item, itemIdx) => {
                      const newIdx = idx * columnsLength + itemIdx
                      return (
                        <S.TableCell key={itemIdx}>
                          <PayPalButtonForm
                            data={item.paypalBtnProps}
                            compId={componentId}
                            reducerProps={{ idx: newIdx }}
                          />
                        </S.TableCell>
                      )
                    })}
                  </S.TableRow>
                  <S.TableRow>
                    {block.map((item, itemIdx) => {
                      const newIdx = idx * columnsLength + itemIdx
                      return (
                        <S.TableCell>
                          <AdditionalLink
                            idx={newIdx}
                            additionalLinkText={item.additionalText}
                            additionalLinkUrl={item.additionalUrl}
                          />
                        </S.TableCell>
                      )
                    })}
                  </S.TableRow>
                </S.Table>
              )
            })}
          </S.BlockContainer>
          <AddButton
            onAdd={Actions.addBlock}
            toShow={blocksCount < validation.blocks.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default PayPal5
