/* @flow */
import {
  simpleButtonActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changePlaceHolderText,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const _changePlaceHolderText = (placeholder: string) =>
    dispatcher(changePlaceHolderText(placeholder))

  const addImage = () => {
    dispatcher({
      type: 'ADD_IMAGE'
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changePlaceHolderText: _changePlaceHolderText,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    getBtnActions: simpleButtonActionMaker(dispatcher),
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'team5',
        imageUrl
      }),
    addImage
  }
}
