/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_FORM_DATA':
        const { key, value } = action.value
        draft.form[key] = value
        break
      case 'CHANGE_BUTTON_TEXT':
        draft.form.buttonText = action.value
        break
      case 'CHANGE_HOURS_BUTTON_TEXT':
        const { buttonText } = action.value
        draft.workingHours.buttonText = buttonText
        break
      case 'CHANGE_HOURS_TITLE':
        const { title } = action.value
        draft.workingHours.title = title
        break
      case 'CHANGE_HOURS_LABEL':
        const { idx, label } = action.value
        draft.workingHours.days[idx].label = label
        break
      case 'CHANGE_HOURS_VALUE':
        const { idx: idx_, value: value_ } = action.value
        draft.workingHours.days[idx_].value = value_
        break
      case 'REMOVE_SERVICE_OPTION':
        const { serviceIdx } = action.value
        draft.form.services.splice(serviceIdx, 1)
        break
      case 'ADD_SRVICE_OPTION':
        draft.form.services.push(initialState.form.services[0])
        break
      case 'CHANGE_SERVICE':
        const { service, idx: serviceIdx_ } = action.value
        draft.form.services[serviceIdx_] = service
        break
      case 'CHANGE_TITLE_ALIGNMENT':
        draft.form.titleAlignment = action.value
        break
      case 'TOGGLE_REQUIRED_FIELDS':
        const { inputLabel } = action.value
        const { requiredFields } = draft.form

        const requiredFieldIdx = requiredFields.findIndex(
          field => field === inputLabel
        )
        if (requiredFieldIdx !== -1) {
          requiredFields.splice(requiredFieldIdx, 1)
        } else {
          requiredFields.push(inputLabel)
        }

        break
    }
  })
}
