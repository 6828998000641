import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    backgroundColor: p => `${p.tertiaryDark}`,
    background: p => `${p.tertiaryDark}`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.8)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    backgroundColor: p => `${p.tertiaryLight}`,
    background: p => `${p.tertiaryLight}`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.8)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    backgroundColor: p => `${p.tertiaryExtraLight}`,
    background: p => `${p.tertiaryExtraLight}`,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.9)`
  }
}