/* @flow */
import styled from 'styled-components'
import Icon from '@website/common/components/Icon'

export const PopupOverlay = styled.div`
  background-color: rgba(37, 46, 72, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
`

export const PopupContainer = styled.div`
  width: 90%;
  max-width: 480px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 3px 10px rgba(0, 82, 224, 0.149);
  border-radius: 10px;
  padding: 50px 0 30px 0;
  box-sizing: border-box;
  @media only screen and (max-width: 650px) {
    padding: 40px 20px 30px 20px;
    max-height: 98vh;
  }
  animation: appear 300ms ease-out;
  @keyframes appear {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`

export const CloseIcon = styled(Icon)`
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
`
