// @flow

import React, { memo } from 'react'
import * as Messages from './Messages'

const MESSAGES_MAP = {
  facebook: Messages.Facebook,
  whatsapp: Messages.Whatsapp,
  telegram: Messages.Telegram,
  viber: Messages.Viber
}

const Message = ({ type, ...props }) => {
  const Comp = MESSAGES_MAP[type]
  return <Comp {...props} />
}

export default memo(Message)
