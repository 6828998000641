/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Header19.theme'
import {
  styleProvider,
  hexToRgbA,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const NavBarWrapper = styled.div`
  background: ${getStyleForProp('navbarBg')};
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  word-break: break-word;
  position: relative;
  padding: 0 0 60px 0;
  ${({ backgroundImgUrl }) =>
    backgroundImgUrl &&
    css`
      @media only screen and (max-width: 769px) {
        background: url(${backgroundImgUrl});
        height: auto;
        min-height: 400px;
        background-repeat: no-repeat;
        /* background-size: cover; */
        background-size: 170%;
        background-position: center;
      }
      @media only screen and (max-width: 457px) {
        background-size: 295%;
      }
    `}
  @media only screen and (max-width: 769px) {
    height: auto;
  }
  & ${S.Container} {
    margin-bottom: 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  max-height: 75%;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
`

const VideoContainer = styled.div`
  & > div {
    overflow: hidden;
  }
  iframe {
    transform: scale(1.33);
    @media only screen and (min-width: 1800px) {
      transform: scale(2);
    }
    @media only screen and (max-width: 1200px) {
      transform: scale(2);
    }
    @media only screen and (max-width: 992px) {
      transform: scale(3);
    }
  }
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: ${({
    bgOverlayTransparency,
    isBgImgOrVideoExists,
    ...rest
  }) => {
    const transparency = isBgImgOrVideoExists ? bgOverlayTransparency : 0.6
    return hexToRgbA(getStyleForProp('backgroundColor')(rest), transparency)
  }};
`

const HeaderContent = styled.div`
  position: relative;
  z-index: 2;
  width: 100%;
  margin: 80px auto 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 550px) {
    margin: 30px auto 0 auto;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      padding-top: 0;
    }
  }
`

const ContentImage = styled.div`
  margin: 0 0 20px 0;
`

const Description = styled.h1`
  color: ${getStyleForProp('paragraph')};
  font-weight: 700;
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
`

const Img = styled.img`
  max-width: 270px;
  max-height: 150px;
  object-fit: contain;
  @media only screen and (max-width: 500px) {
    max-width: 170px;
  }
`

const ScrollToNext = styled.div`
  position: absolute;
  z-index: 1;
  bottom: -1px;
  left: 50%;
  transform: translateX(-50%);
  width: 106px;
  height: 26px;
  svg g {
    fill: ${({ theme }) =>
      THEME[theme.secondComponentMode].nextComponentColor(theme.palette)};
  }
`
const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: THEME[theme.secondComponentMode].scrollIcon(theme.palette)
}))`
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export default {
  Container,
  WmCustomContainer,
  VideoContainer,
  NavBarWrapper,
  ContentImage,
  HeaderContent,
  Description,
  Img,
  Overlay,
  ScrollToNext,
  Icon
}
