/* @flow */
import styled, { css } from 'styled-components'
import Icon from '@website/common/components/Icon'
import THEME from './Contact14.theme'
import {
  styleProvider,
  hexToRgb,
  getTextStyleByAlignment,
  getBackgroundStyles
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import {
  MOBILE_SIZE,
  TABLET_SIZE,
  LARGE_SIZE
} from './../../../../src/shared/globals'

export const getStyleForProp = styleProvider(THEME)

export const Container = styled.div`
  padding: ${({ isEditing }) => (isEditing ? '120px 0 80px 0' : '80px 0')};
  ${getBackgroundStyles(THEME)};
  word-break: break-word;
  width: 100%;

  @media only screen and (max-width: 1550px) and (min-width: 1441px) {
    box-sizing: border-box;
    padding: ${({ isEditing }) => (isEditing ? '120px 80px 80px' : '80px')};
  }
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    padding: ${({ isEditing }) => (isEditing ? '100px 0 60px 0' : '60px 0')};
  }
`

export const WMCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0;
  @media only screen and (max-width: ${TABLET_SIZE}px) {
    align-items: center;
    flex-direction: column;
  }
  .required-icon {
    bottom: 5px;
    color: ${({ theme }) => theme.palette.tertiarySemiLight};
    position: absolute;
    padding: 10px;
    right: 10px;
    @media only screen and (max-width: ${TABLET_SIZE}px) {
      right: 4px;
    }
  }
  .add-button-wrap {
    text-align: start;
  }
  & > .control-container {
    margin-bottom: 50px;
    h1 {
      margin-bottom: 0;
    }
  }
`

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: ${({ isEditing }) => (isEditing ? '40px;' : '10px')};
  width: 100%;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    max-width: 488px;
    margin-left: auto;
    margin-right: auto;
  }
  .control-container {
    width: 100%;
    h2 {
      margin-bottom: 0;
    }
  }

  ${({ isEditing }) =>
    isEditing
      ? ''
      : css`
          & > p + div,
          & > a + div,
          & > h2 + div {
            margin-top: 46px;
            @media only screen and (max-width: ${TABLET_SIZE}px) {
              margin-top: 24px;
            }
          }
        `};

  & > h2 {
    margin-bottom: 0;
    & + a {
      margin-bottom: 10px;
    }
  }
`

export const Divider = styled.div`
  border-top: 1px solid ${getStyleForProp('paragraph')};
  display: flex;
  padding-top: 46px;
  position: relative;
  width: 100%;
  ${({ isEditing }) =>
    isEditing
      ? css`
          margin-top: 46px;
          @media only screen and (max-width: ${TABLET_SIZE}px) {
            margin-top: 24px;
          }
        `
      : ''};
  @media only screen and (max-width: ${TABLET_SIZE}px) {
    padding-top: 24px;
  }

  & > svg {
    height: 40px;
    position: absolute;
    right: 28%;
    top: -20px;
    path {
      fill: ${getStyleForProp('paragraph')};
    }
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 50px;
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    margin-bottom: 40px;
  }
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
`

export const Address = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
`

export const BlockTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  font-weight: 700;
  ${getTextStyleByAlignment};
  width: 100%;
`

export const ContactMain = styled.div`
  align-items: flex-start;
  display: flex;
  width: 100%;
  ${({ emptyContent }) => (emptyContent ? 'justify-content: center' : '')};
  ${({ theme }) =>
    css`
      flex-direction: ${theme.orientation === 'LEFT' ? 'row' : 'row-reverse'};
    `}
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    align-items: center;
    max-width: 488;
    ${({ theme }) =>
      css`
        flex-direction: ${theme.orientation === 'LEFT'
          ? 'column'
          : 'column-reverse'};
      `}
  }
  .add-button-wrap {
    margin-right: auto;
  }
`

export const Input = styled.input`
  border-radius: ${getStyleForProp('buttonRadius')};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  box-sizing: border-box;
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  padding: 0 45px 0 25px;
  height: 50px;
  margin-top: 20px;
  font-size: 16px;
  -webkit-appearance: none;
  width: 100%;
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
`

export const TextArea = styled.textarea`
  border: ${({ theme }) =>
    `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  box-sizing: border-box;
  border-radius: ${getStyleForProp('inputRadius')};
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  resize: none;
  padding: 25px;
  margin: 20px 0 30px 0;
  max-height: 200px;
  font-size: 16px;
  width: 100%;
  -webkit-appearance: none;
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
  @media only screen and (max-width: ${TABLET_SIZE}px) {
    padding: 20px;
  }
`

export const FormContainer = styled.div`
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 488px;
  position: relative;
  width: 90%;
  ${({ theme }) =>
    css`
      ${theme.orientation === 'RIGHT'
        ? 'margin-right: 224px;'
        : 'margin-left: 224px;'};
    `}
  @media only screen and (max-width: 1200px) {
    ${({ theme }) =>
      css`
        ${theme.orientation === 'RIGHT'
          ? 'margin-right: 100px'
          : 'margin-left: 100px'};
      `}
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    max-width: 488px;
    align-items: center;
    ${({ theme }) =>
      css`
        ${theme.orientation === 'RIGHT'
          ? 'margin: 0 auto 40px auto;'
          : 'margin: 40px auto 0 auto'};
      `}
  }
  @media only screen and (max-width: ${TABLET_SIZE}px) {
    width: 100%;
    min-width: initial;
  }
  &:only-child {
    align-items: center;
    margin: 0;

    .button-container {
      justify-content: center;
    }
  }
  & > .control-container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
  .name-input {
    margin: 0;
  }
  .button-container {
    justify-content: flex-start;
    @media only screen and (max-width: ${LARGE_SIZE}px) {
      justify-content: center;
    }
  }
`

export const AddFormContainer = styled.div`
  align-items: center;
  max-width: 488px;
  margin-left: 224px;
  display: flex;
  justify-content: center;
  width: 90%;
  @media only screen and (max-width: 1200px) {
    margin-left: 100px;
  }
  @media only screen and (max-width: ${TABLET_SIZE}px) {
    margin-top: 40px;
    margin-left: 0px;
  }
`

export const ErrorMessage = styled.div`
  align-items: center;
  display: flex;
  color: #eb0c00;
  position: absolute;
  top: 70px;
  font-size: 14px;
  > div {
    margin-right: 5px;
  }
`

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    flex-direction: column;
  }
  &.name-input-container {
    ${ErrorMessage} {
      top: 50px;
    }
  }
  div {
    svg {
      max-width: 12px;
      max-height: 12px;
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
`

export const PhoneWrapA = styled.a`
  display: inline-block;
  text-decoration: none;
`

export const Phone = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
`

export const EmailWrapA = styled.a`
  display: block;
  text-decoration: none;
  ${getTextStyleByAlignment};
  width: 100%;
`

export const EmailWrap = styled.div`
  ${getTextStyleByAlignment};
  width: 100%;
`

export const Email = styled.p`
  color: ${getStyleForProp('heading')};
  margin-top: ${({ isEditing }) => (isEditing ? '10px' : '0')};
`

export const RequiredIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.palette.tertiarySemiLight
}))``

export const ErrorIcon = styled.img`
  margin-right: 5px;
  width: 12px;
`
