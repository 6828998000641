export const TEXT = 'text'
export const IMAGE = 'image'
export const validation = {
  additionalBlockTitleMaxChar: 5,
  additionalBlockParagraphMaxChar: 20,
  blockTitleMaxChar: 100,
  blockParagraphMaxChar: 2000,
  blockText: {
    min: 1,
    max: 5
  },
  blockImage: {
    min: 0,
    max: 10
  }
}
