/* @flow */
import {
  cropableImageModalActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import { getTextStyleByAlignment } from '@website/common/utils'

export default dispatcher => {
  const changeBlockTitle = (title: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { title, idx }
    })
  }

  const blockImgActions = ({ blockIdx, imageUrl }) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature8',
      showRemove: false,
      additionalArgs: { blockIdx },
      imageUrl
    })

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  return {
    addBlock: _addBlock,
    changeTitle: _changeTitle,
    blockImgActions,
    changeParagraph: _changeParagraph,
    changeBlockTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    blockActions: removeBlockActionMaker(dispatcher)
  }
}
