/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_BLOCK_TITLE':
        const { idx, newTitle } = action.value
        draft.features[idx].title = newTitle
        break
      case 'CHANGE_BLOCK_PARAGRAPH':
        const { idx: _idx, newParagraph } = action.value
        draft.features[_idx].paragraph = newParagraph
        break
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.features[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.features[idx].blockParagraphAlignment = alignment
        break
      }
      case 'ADD_BLOCK': {
        const idx = action.value
        draft.features.splice(idx + 1, 0, initialState.features[0])
        break
      }
      case 'REMOVE_ROW':
        draft.features.splice(action.value, 1)
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, imageIdx, dimensions } = action.value
        draft.features[idx].images[imageIdx].imgUrl = url
        draft.features[idx].images[imageIdx].imgDimensions = dimensions || {}
        draft.features[idx].images[imageIdx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx, imageIdx } = action.value
        draft.features[idx].images[imageIdx].imgCropParams = imgCropParams
      }
    }
  })
}
