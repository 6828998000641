/* @flow */
import React, { useContext } from 'react'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import Icon from '@website/common/components/Icon'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext } from '@contexts'
import S from './SingleButton.styled'
import ActionGetter from './Actions'
import { translate } from '@editor/common/utils/translations'

const SingleButton = ({ btnText, btnUrl }) => {
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <Show when={[btnText !== '']}>
      <S.ButtonsBlock className="buttons-block">
        <If
          condition={btnText}
          then={() => (
            <S.Button>
              <ControlsWithModalOpener
                actions={Actions.getButtonActions(null, {
                  text: btnText,
                  link: btnUrl
                })}
              >
                <LinkWrapper
                  text={btnText}
                  url={btnUrl}
                  linkProps={{
                    className: 'WM_GLOBAL_secondary-font'
                  }}
                />
              </ControlsWithModalOpener>
            </S.Button>
          )}
        />
        <AddButton onAdd={Actions.addButton} toShow={btnText === ''} rounded>
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_button_label')}
        </AddButton>
      </S.ButtonsBlock>
    </Show>
  )
}

export default SingleButton
