//@flow
import React, { memo } from 'react'
import { translate } from '@editor/common/utils/translations'
import {
  SearchWithDatabases,
  KeywordTabs
} from '@editor/common/components/SemrushCommon'
import { useResponsive } from '@shared/hooks'

import KeywordTable from '../ResultContent/KeywordTable'

import { KEYWORD_CHAR_LIMIT } from '../consts'

import type { TProps } from './types'
import * as Styled from './styled'

const SemrushKeywordMobile = ({
  hasResults,
  wrapperRef,
  onScroll,
  activeTab,
  searchProps,
  sortingInfo,
  tableTargetRef,
  isTableHeadSticky,
  onSortingInfoChange,
  onTabChange
}: TProps) => {
  const isVerticalView = useResponsive(500)

  return (
    <Styled.SemrushKeywordMobile ref={wrapperRef} onScroll={onScroll}>
      <Styled.Heading>
        {translate('semrush_keyword_magic_tool_title')}
      </Styled.Heading>
      <Styled.SearchContainer hasResults={hasResults}>
        <SearchWithDatabases
          isVerticalView={isVerticalView}
          count={KEYWORD_CHAR_LIMIT}
          {...searchProps}
        />
      </Styled.SearchContainer>
      {hasResults ? (
        <>
          <KeywordTabs
            isHorizontalView
            activeTab={activeTab}
            onTabChange={onTabChange}
          />
          <KeywordTable
            className="semrush-keyword_mobile-view"
            sortingInfo={sortingInfo}
            isTableHeadSticky={isTableHeadSticky}
            targetRef={tableTargetRef}
            onSortingInfoChange={onSortingInfoChange}
          />
        </>
      ) : null}
    </Styled.SemrushKeywordMobile>
  )
}

export default memo(SemrushKeywordMobile)
