/* @flow */

import React, { useEffect } from 'react'
import { ImageCropper } from '@renderforest/rf-ui-library/organism/ImageCropper'
import { translate } from '@editor/common/utils/translations'

import { MODAL_TYPES, LINK_TYPES } from '../consts'
import './index.sass'
import type { TImageCropModal } from './type'
import { useBackdropClick } from '@hooks'

const ImageCropModal = (props: TImageCropModal) => {
  const {
    height,
    imgSrc,
    handler,
    showReplace = true,
    setModalState,
    containerWidth,
    imgCropParams = {},
    cropperType,
    isFromCropper
  } = props

  const closeCropperModal = () =>
    setModalState(MODAL_TYPES.imageCrop, {
      isOpen: false
    })

  const onCropCancel = () => {
    closeCropperModal()
    window.optimizedImages = {}
    window.isApplied = false
  }

  const wrapperRef = useBackdropClick(onCropCancel)
  useEffect(() => {
    window.optimizedImages = {}
    window.isApplied = false
  }, [])

  const onCropParamsConfirm = cropParams => {
    closeCropperModal()
    handler(
      {
        ...cropParams,
        containerWidth
      },
      isFromCropper,
      imgSrc
    )
    if (!isFromCropper) {
      window.isApplied = true
    }
  }
  const onReplaceClick = () => {
    closeCropperModal()
    // open media choose modal
    setModalState(MODAL_TYPES.mediaModal, {
      isOpen: true,
      type: LINK_TYPES.image
    })
  }

  const {
    left = 0,
    top = 0,
    transform = 0,
    width = containerWidth
  } = imgCropParams

  return (
    <div className="sm-cropper-container">
      <div className="sm-cropper-wrapper" ref={wrapperRef}>
        <ImageCropper
          params={{
            left,
            top,
            transform,
            width,
            height
          }}
          src={imgSrc}
          type={cropperType}
          containerHeight={height}
          onCancel={onCropCancel}
          onConfirm={onCropParamsConfirm}
          containerWidth={containerWidth}
          recSizeText={translate('recommended_size_label')}
          onReplaceClick={showReplace ? onReplaceClick : undefined}
          warningAction={warningMessage => console.log(warningMessage)}
          confirmingBtnLabel={translate('apply_label')}
          cancelingBtnLabel={translate('cancel_label')}
          rotateBtnLabel={translate('rotate_label')}
          fillBtnLabel={translate('fill_label')}
          fitBtnLabel={translate('fit_label')}
        />
      </div>
    </div>
  )
}

export default ImageCropModal
