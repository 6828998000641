/* @flow */
import React, { useContext, memo } from 'react'
import S from './Footer7.styled'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import { validation } from './Footer7.consts'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import { SubscribeForm } from '../SubscribeForm'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import {
  EditingContext,
  DispatchContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import THEME from './Footer7.theme'
import SocialLinks from '@website/common/components/SocialLinks'
import { Controls } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { translate } from '@editor/common/utils/translations'

const Footer7 = memo(props => {
  const {
    data: {
      socials,
      formText,
      formTextAlignment,
      buttonText,
      placeholder,
      copyrightText
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}

  return (
    <S.Container isEditing={isEditing}>
      <WMContainer style={{ paddingTop: '0' }}>
        <S.FooterContent>
          <S.SubscribeFormContainer>
            <Show when={[formText]}>
              <EditableContent
                text={formText}
                as={S.Label}
                alignment={formTextAlignment}
                className="WM_GLOBAL_heading32"
                maxCount={validation.labelMaxChar}
                onChange={Actions.changeLabel}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <If
              condition={buttonText}
              otherwise={() => (
                <AddButton
                  onAdd={Actions.addForm}
                  toShow
                  style={{ marginTop: '20px' }}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_form_label')}
                </AddButton>
              )}
              then={() => (
                <S.FormWrapper>
                  <Controls
                    actions={Actions.formActions()}
                    style={{ width: '100%' }}
                  >
                    <S.SubscribeFormWrap>
                      <SubscribeForm
                        actions={{
                          buttonActions: Actions.buttonActions(buttonText),
                          placeHolderAction: Actions.changePlaceHolderText
                        }}
                        buttonText={buttonText}
                        placeholder={placeholder}
                        theme={THEME}
                      />
                    </S.SubscribeFormWrap>
                  </Controls>
                </S.FormWrapper>
              )}
            />
          </S.SubscribeFormContainer>
        </S.FooterContent>
      </WMContainer>
      <WMContainer style={{ paddingTop: '0' }}>
        <S.Footer isSocial={socials.length > 0} isEditing={isEditing}>
          <SocialLinks
            className="socials"
            socials={socials}
            socialsMaxCount={validation.socials.max}
          />
          <S.CopyRightWrapper>
            <EditableContent
              text={copyrightText}
              as={S.Copyright}
              maxCount={validation.copyrightMaxChar}
              onChange={Actions.changeCopyright}
              isPrivacyPolicyVisible={isPrivacyPolicyVisible}
              className="WM_GLOBAL_secondary-font"
            />
            {isPrivacyPolicyVisible ? (
              <S.LinkWrap isEditing={isEditing}>
                <Controls actions={Actions.privacyPolicyAction()}>
                  <LinkWrapper
                    text={privacyPolicyName}
                    url={privacyPolicyLink}
                    linkProps={{
                      className: 'privacy-policy-link WM_GLOBAL_secondary-font'
                    }}
                  />
                </Controls>
              </S.LinkWrap>
            ) : null}
          </S.CopyRightWrapper>
        </S.Footer>
      </WMContainer>
    </S.Container>
  )
})

export default Footer7
