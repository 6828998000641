/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'REMOVE_CLIENT_LOGO':
        draft.clientsLogos.splice(action.value, 1)
        break
      case 'ADD_LOGO':
        draft.clientsLogos.push(initialState.clientsLogos[0])
        break
      case 'CHANGE_CLIENT_LOGO':
        const { idx, url, imgDimensions } = action.value
        draft.clientsLogos[idx].logoUrl = url
        draft.clientsLogos[idx].logoDimensions = imgDimensions || {}
        break
      case 'CHANGE_CLIENT_URL':
        {
          const { idx, link } = action.value
          draft.clientsLogos[idx].clientUrl = link
        }
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
