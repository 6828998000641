import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const SemrushKeywordMobile = styled.div`
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  overflow-y: auto;

  @media only screen and (max-width: 768px) {
    padding: 0 20px;
  }

  .semrush-keyword_mobile-view {
    margin-top: 30px;
    @media only screen and (max-width: 1024px) {
      width: fit-content;
    }
  }
`

export const Heading = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;

  color: #252e48;
`

export const SearchContainer = styled.div`
  padding: 30px 0;
  ${({ hasResults }) =>
    hasResults
      ? css`
          border-bottom: 1px solid #dee3f0;
        `
      : ''}
`
