/* @flow */
import React from 'react'
import S from './Portfolio6.styled'
import { validation } from './Portfolio6.consts'
import ActionGetter from './Actions'
import { withDispatchContext, withSiteId, EditingContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import { translate } from '@editor/common/utils/translations'

class Portfolio6 extends React.PureComponent<void, void> {
  render() {
    const {
      data: {
        title,
        subTitle,
        paragraph,
        mainImage,
        images,
        titleAlignment,
        paragraphAlignment,
        subTitleAlignment
      },
      componentDispatcher,
      siteId
    } = this.props
    const Actions = ActionGetter(componentDispatcher)
    return (
      <S.Container>
        <S.LeftLine />
        <WMContainer>
          <S.ComponentHeader>
            <S.HeaderContent>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.titleMaxChar}
                required={validation.title.required}
                className="WM_GLOBAL_heading72"
                onChange={(newTitle: string) =>
                  componentDispatcher(Actions.changeTitle(newTitle))
                }
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={subTitle}
                alignment={subTitleAlignment}
                as={S.SubTitle}
                required={validation.subTitle.required}
                maxCount={validation.subTitleMaxChar}
                className="WM_GLOBAL_heading20"
                onChange={(newSubTitle: string) =>
                  componentDispatcher(Actions.changeSubTitle(newSubTitle))
                }
                changeAlignment={Actions.changeSubtitleAlignment}
              />
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={(newParagraph: string) =>
                  componentDispatcher(Actions.changeParagraph(newParagraph))
                }
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <S.Line />
            </S.HeaderContent>
            <S.Div>
              <ControlsWithModalOpener
                style={{ width: '100%' }}
                actions={Actions.mainImageActions(siteId, mainImage.url)}
              >
                <Image
                  as={S.Img}
                  alt="Gallery"
                  sizes={mainImage.imgDimensions}
                  defaultImgSrc={mainImage.url}
                />
              </ControlsWithModalOpener>
            </S.Div>
          </S.ComponentHeader>

          <Mapper
            data={images}
            render={(img: string, blockIdx: number) => {
              return (
                <React.Fragment key={blockIdx}>
                  <S.GridWrapper
                    key={blockIdx}
                    className={blockIdx % 2 === 1 ? 'grid-reverse' : ''}
                  >
                    <Mapper
                      data={img}
                      render={(item: string, idx: number) => {
                        return (
                          <div
                            className={'content content-' + (idx + 1)}
                            key={idx}
                          >
                            <ControlsWithModalOpener
                              style={{ width: '100%', height: '100%' }}
                              actions={Actions.imageActions(
                                blockIdx,
                                idx,
                                siteId,
                                item.imgUrl
                              )}
                            >
                              <Image
                                as={S.Img}
                                alt="Gallery"
                                sizes={item.imgDimensions}
                                defaultImgSrc={item.imgUrl}
                              />
                            </ControlsWithModalOpener>
                          </div>
                        )
                      }}
                    />
                  </S.GridWrapper>
                  <EditingContext.Consumer>
                    {({ isEditing }) => {
                      return (
                        <If
                          condition={isEditing}
                          then={() => (
                            <S.AddButtonsWrap>
                              <AddButton
                                onAdd={() =>
                                  componentDispatcher(
                                    Actions.addBlock(blockIdx)
                                  )
                                }
                                toShow={images.length < validation.images.max}
                                style={{ margin: '0 6px 16px 6px' }}
                              >
                                <Icon
                                  name="glyph-add"
                                  className="icon"
                                  size="normal"
                                />
                                {translate('add_block_label')}
                              </AddButton>
                              <AddButton
                                onAdd={() =>
                                  componentDispatcher(
                                    Actions.removeBlock(blockIdx)
                                  )
                                }
                                toShow={images.length !== 1}
                                style={{
                                  margin: '0 6px 16px 6px',
                                  padding: '9px 10px',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                <Icon
                                  name="glyph-remove"
                                  className="icon"
                                  size="normal"
                                />
                                {translate('remove_block_label')}
                              </AddButton>
                            </S.AddButtonsWrap>
                          )}
                        />
                      )
                    }}
                  </EditingContext.Consumer>
                </React.Fragment>
              )
            }}
          />
        </WMContainer>
      </S.Container>
    )
  }
}

const Portfolio6WithContexts = withDispatchContext(withSiteId(Portfolio6))

export default Portfolio6WithContexts
