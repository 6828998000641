/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        break
      }
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'ADD_SOCIAL':
        draft.headerSocials.push(initialState.headerSocials[0])
        break
      case 'REMOVE_SOCIAL':
        draft.headerSocials.splice(action.value.idx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.headerSocials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.headerSocials[idx].icon = iconName
        }
        break
      case 'CHANGE_BUTTON_DATA':
        {
          const { link, text } = action.value.btnData
          draft.btnUrl = link
          draft.btnText = text
        }
        break
      case 'REMOVE_BUTTON':
        draft.btnUrl = ''
        draft.btnText = ''
        break
      case 'ADD_BUTTON':
        draft.btnText = initialState.btnText
        draft.btnUrl = initialState.btnUrl
        break
      case 'CHANGE_ICON':
        {
          const { idx, iconName } = action.value
          draft.blocks[idx].iconName = iconName
        }
        break
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.blocks[idx].iconName = ''
        break
      }
      case 'ADD_ICON':
        draft.blocks[action.value].iconName = 'edit'
        break
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.blocks[linkIdx].additionalUrl = linkData.link
        draft.blocks[linkIdx].additionalText = linkData.text
        break

      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.blocks[idx].additionalUrl = ''
        draft.blocks[idx].additionalText = ''
        break
      }
      case 'ADD_LINK': {
        const { additionalUrl, additionalText } = initialState.blocks[0]
        const { idx } = action.value
        draft.blocks[idx].additionalUrl = additionalUrl
        draft.blocks[idx].additionalText = additionalText
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.blocks[idx].imgCropParams = imgCropParams
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.blocks[idx].imgUrl = url
        draft.blocks[idx].imgDimensions = dimensions || {}
        draft.blocks[idx].imgCropParams = {}
        break
      }

      case 'CHANGE_URL_DATA': {
        const { idx, newLinkData } = action.value
        draft.blocks[idx].additionalUrl = newLinkData.link
        draft.blocks[idx].additionalText = newLinkData.text
        break
      }
      case 'REMOVE_URL':
        const { idx } = action.value
        draft.blocks[idx].additionalUrl = ''
        draft.blocks[idx].additionalText = ''
        break
      case 'ADD_URL':
        {
          const { idx } = action.value
          const { additionalUrl, additionalText } = initialState.blocks[idx]
          draft.blocks[idx].additionalUrl = additionalUrl
          draft.blocks[idx].additionalText = additionalText
        }
        break
    }
  })
}
