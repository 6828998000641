import React from 'react'

import * as Styled from './styled'
import {
  AIIcon,
  AICircle,
  AIVisualBG,
  AITemplate
} from '@editor/common/assets/start-creation'

const AICard = () => (
  <Styled.AICard>
    <img src={AIVisualBG} alt="visual-bg" />
    <img src={AIIcon} className="ai-circle" alt="ai-icon" />
    <img src={AICircle} className="first-circle" alt="circle" />
    <img src={AICircle} className="second-circle" alt="circle" />
    <img src={AICircle} className="third-circle" alt="circle" />
    <img src={AICircle} className="fourth-circle" alt="circle" />
    <img src={AICircle} className="fifth-circle" alt="circle" />
    <img src={AITemplate} className="first-template" alt="ai-template" />
    <img src={AITemplate} className="second-template" alt="ai-template" />
  </Styled.AICard>
)

export default AICard
