// @flow
import React, { memo, useCallback, useContext, useMemo, useState } from 'react'

import * as Styled from './styled'
import { Button } from '../styled'
import type { TCardProps } from './types'
import { Option, PriceContent } from '../components'
import InfoIcon from '@editor/common/assets/svgr-icons/Info_icon.svg'
import { MobileDetectContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const Card = ({
  tariffId,
  title,
  isYearly,
  isUpgrade,
  isSelected,
  options,
  priceData,
  isPremiumOptionsLabelVisible,
  setSelectedTariffId,
  onUpgradeBtnClick
}: TCardProps) => {
  const [isDetailsPopupOpen, setDetailsPopupOpenState] = useState(false)
  const isMobile = useContext(MobileDetectContext)

  const {
    price = 0,
    currencySymbol: currency = '$',
    discountedPrice = 0
  } = priceData

  const onClick = useCallback(() => {
    setSelectedTariffId(tariffId)
  }, [tariffId])

  const openDetailsPopup = useCallback(() => {
    setDetailsPopupOpenState(true)
  }, [])

  const closeDetailsPopup = useCallback(() => {
    setDetailsPopupOpenState(false)
  }, [])

  const _options = useMemo(
    () => (
      <Styled.Options>
        {options.map((option, idx) => (
          <Option key={idx} option={option} />
        ))}
      </Styled.Options>
    ),
    [options]
  )

  return isMobile ? (
    <>
      <Styled.MobileCard isSelected={isSelected} onClick={onClick}>
        <Styled.TitleWrapper>
          <Styled.Title>{title}</Styled.Title>
          <InfoIcon className="info-icon" onClick={openDetailsPopup} />
        </Styled.TitleWrapper>
        <PriceContent
          price={price}
          discountedPrice={discountedPrice}
          currency={currency}
          isYearly={isYearly}
          isUpgrade={isUpgrade}
          isInMobileCard
        />
        {isSelected ? (
          <Styled.SelectedIcon name="check" size="xxSmall" color="white" />
        ) : null}
      </Styled.MobileCard>
      <Styled.MobileDetailsPopup
        isOpen={isDetailsPopupOpen}
        onClose={closeDetailsPopup}
      >
        <Styled.Title className="popup-title">{title}</Styled.Title>
        <PriceContent
          price={price}
          discountedPrice={discountedPrice}
          currency={currency}
          isYearly={isYearly}
          isUpgrade={isUpgrade}
        />
        {isPremiumOptionsLabelVisible ? (
          <Styled.GradientText>
            {translate('everything_in_premium_plus_label')}
          </Styled.GradientText>
        ) : null}
        {_options}
        <Button onClick={onUpgradeBtnClick}>
          {translate('upgrade_label')}
        </Button>
        <Styled.Info>
          * {translate('your_storage_will_be_added_to_active_subsb_label')}
        </Styled.Info>
      </Styled.MobileDetailsPopup>
    </>
  ) : (
    <Styled.Card isSelected={isSelected} onClick={onClick}>
      <Styled.Title>{title}</Styled.Title>
      <PriceContent
        price={price}
        discountedPrice={discountedPrice}
        currency={currency}
        isYearly={isYearly}
        isUpgrade={isUpgrade}
      />
      {isPremiumOptionsLabelVisible ? (
        <Styled.GradientText>
          {translate('everything_in_premium_plus_label')}:
        </Styled.GradientText>
      ) : null}
      {_options}
    </Styled.Card>
  )
}

export default memo(Card)
