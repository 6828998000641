export const validation = {
  title: 70,
  additionalBlockParagraphMaxChar: 100,
  blockTitleMaxChar: 200,
  blockParagraphMaxChar: 2000,
  listItemParagraphMaxChar: 1000,
  block: {
    min: 1,
    max: 15
  },
  listItem: {
    min: 0,
    max: 10
  }
}

export const LIST_ITEM_INITIAL_STATE =
  'To send you marketing and promotional communications. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes if this is in accordance with your marketing preferences. You can opt out of our marketing emails at any time'

export const ICON_CLASSNAMES = [
  'circle first-color',
  'square second-color',
  'first-quorter third-color',
  'fourth-quorter fourth-color',
  'circle first-color',
  'fourth-quorter fourth-color hidden-mobile',
  'second-quorter third-color hidden-tablet hidden-mobile',
  'square second-color hidden-tablet hidden-mobile',
  'second-quorter fourth-color hidden-tablet hidden-mobile',
  'fourth-quorter third-color hidden-tablet hidden-mobile',
  'circle fifth-color hidden-tablet hidden-mobile',
  'first-quorter fourth-color hidden-tablet hidden-mobile',
  'third-quorter third-color hidden-mobile',
  'square second-color hidden-mobile',
  'first-quorter fourth-color hidden-mobile',
  'extra-large right third-color',
  'extra-large left third-color',
  'circle fifth-color',
  'square second-color',
  'first-quorter fourth-color',
  'square second-color hidden-mobile',
  'circle fifth-color hidden-mobile',
  'third-quorter fourth-color hidden-tablet hidden-mobile',
  'circle first-color hidden-tablet hidden-mobile',
  'square second-color hidden-tablet hidden-mobile',
  'first-quorter fourth-color hidden-tablet hidden-mobile',
  'square second-color hidden-tablet hidden-mobile',
  'circle first-color hidden-tablet hidden-mobile',
  'fourth-quorter fourth-color hidden-mobile',
  'first-quorter third-color hidden-mobile',
  'square second-color',
  'circle fifth-color'
]
