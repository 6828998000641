/* @flow */
import styled from 'styled-components'

import THEME from './Portfolio6.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 120px 0;
  background-color: ${getStyleForProp('background')};
  position: relative;
  @media only screen and (max-width: 600px) {
    padding: 60px 0;
  }
  .LazyLoad {
    width: 100%;
  }
`

const LeftLine = styled.div`
  width: 10px;
  height: 420px;
  background-color: ${getStyleForProp('subTitle')};
  position: absolute;
  left: 0;
  top: 0;
  @media only screen and (max-width: 1023px) {
    width: 100%;
    height: 6px;
  }
`

const ComponentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px 0;
  .content {
    width: 40vw;
    max-width: 700px;
    height: 33vw;
    max-height: 560px;
    align-self: flex-end;
    display: flex;
    position: relative;
    &::after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    @media only screen and (max-width: 1200px) {
      width: 36vw;
      height: 36vw;
    }
    @media only screen and (max-width: 1023px) {
      width: 45%;
      flex-grow: 1;
      margin: 8px;
      height: initial;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin: 0;
      box-sizing: border-box;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  @media only screen and (max-width: 1023px) {
    margin: 10px 10px 10px 0;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 8px;
    margin: 0;
  }
`

const HeaderContent = styled.div`
  width: 45%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  @media only screen and (max-width: 1023px) {
    width: 45%;
    flex-grow: 1;
    margin: 8px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0;
    width: 100%;
    text-align: center;
  }
}
`

const Title = styled.h4`
  color: ${getStyleForProp('title')};
  @media only screen and (min-width: 769px) {
    position: relative;
    width: 200%;
    z-index: 1;
  }
`

const SubTitle = styled.h6`
  color: ${getStyleForProp('subTitle')};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin: 20px 0 40px 0;
  max-width: 520px;
  @media only screen and (max-width: 768px) {
    max-width: initial;
  }
`

const Line = styled.div`
  width: 100px;
  height: 8px;
  background-color: ${getStyleForProp('subTitle')};
  position: absolute;
  bottom: 0;
  right: 16px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`

const Img = styled.img`
  height: 100%;
  width: 100%;
`

const GridWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-gap: 16px;
  margin: 0 0 16px 0;
  font-size: 0;
  grid-template-areas:
    'content-3 content-4 content-1'
    'content-2 content-2 content-1';
  &.grid-reverse {
    grid-template-areas:
      'content-1 content-3 content-4'
      'content-1 content-2 content-2';
  }
  @media only screen and (max-width: 1023px) {
    display: flex;
    flex-wrap: wrap;
  }
  .content-1 {
    grid-area: content-1;
  }
  .content-2 {
    grid-area: content-2;
  }
  .content-3 {
    grid-area: content-3;
  }
  .content-4 {
    grid-area: content-4;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media only screen and (min-width: 1023px) {
    .content-2,
    .content-3,
    .content-4 {
      height: 26vw;
    }
  }
  @media only screen and (max-width: 1023px) {
    .content {
      position: relative;
      width: 45%;
      flex-grow: 1;
      margin: 8px;
      &::after {
        content: '';
        display: block;
        padding-bottom: 100%;
      }
      img {
        position: absolute;
      }
    }
  }
`

const AddButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 374px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Div = styled.div`
  width: 40vw;
  height: 33vw;
  max-width: 700px;
  max-height: 560px;
  align-self: flex-end;
  display: flex;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 60vw;
  }
  @media only screen and (max-width: 360px) {
    height: auto;
    .LazyLoad {
      position: relative;
      padding-top: 100%;
    }
  }
  img {
    object-fit: cover;
    @media only screen and (max-width: 360px) {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

export default {
  Container,
  ComponentHeader,
  HeaderContent,
  Title,
  SubTitle,
  Paragraph,
  Line,
  Img,
  Div,
  GridWrapper,
  LeftLine,
  AddButtonsWrap
}
