/* @flow */
import React from 'react'
import S from './Feature28.styled'
import Icon from '@website/common/components/Icon'
import { withTheme } from 'styled-components'
import THEME from './Feature28.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Arrow = ({ toShow, SvgComp, className, callbackFunction, ...rest }) => {
  const color = getStyleForProp('arrow')

  return (
    toShow && (
      <S.IconContainer className={className}>
        <Icon
          color={color}
          size="large"
          SvgComp={SvgComp}
          onClick={() => {
            callbackFunction()
            rest.onClick && rest.onClick()
          }}
        />
      </S.IconContainer>
    )
  )
}
export default withTheme(Arrow)
