import styled from 'styled-components'
import { SEMRUSH_SIDEBAR_WIDTH, SEMRUSH_SIDEBAR_ANIMATION_TIME } from './consts'

export const SidebarContainer = styled.div`
  @keyframes open-semrush-sidebar {
    from {
      width: 0;
    }
    to {
      width: ${SEMRUSH_SIDEBAR_WIDTH}px;
    }
  }
  @keyframes close-semrush-sidebar {
    from {
      width: ${SEMRUSH_SIDEBAR_WIDTH}px;
    }
    to {
      width: 0;
    }
  }
  position: relative;
  z-index: 50;
  background: white;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  animation: ${({ isOpen }) =>
      isOpen ? 'open-semrush-sidebar' : 'close-semrush-sidebar'}
    ${SEMRUSH_SIDEBAR_ANIMATION_TIME}ms ease-out;
  animation-fill-mode: forwards;
  overflow: hidden;
  border-left: 1px solid #c1cadf;
  box-sizing: border-box;

  .sidebar-tabs {
    margin: 0 auto 14px;
  }
`

export const SidebarContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  .circle-loader {
    background-color: rgba(255, 255, 255, 0.9);
    .circle {
      zoom: 0.7;
    }
  }

  .warning-content {
    h3 {
      max-width: 255px;
      text-align: center;
    }
  }
`

export const SearchContainer = styled.div`
  padding: 30px 15px;
`
