/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header25.styled'
import THEME from './Header25.theme'
import { validation } from './Header25.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import Buttons from '@website/common/components/Buttons'
import SocialLinks from '@website/common/components/SocialLinks'

const Header25 = memo(props => {
  const {
    data: {
      title,
      subTitle,
      paragraph,
      subTitleAlignment,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      buttons,
      headerSocials,
      logoWidth,
      logoDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      bgOverlayTransparency={bgOverlayTransparency}
      isSocials={headerSocials.length > 4}
      topOffset={navBarHeight}
      backgroundImgDimensions={backgroundImgDimensions}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        isEditing={isEditing}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <Show when={[title, paragraph, buttons.length > 0]}>
        <S.WmCustomContainer topOffset={navBarHeight}>
          <S.HeaderContent>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading96"
              as={S.Title}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={subTitle}
              alignment={subTitleAlignment}
              maxCount={validation.subTitleMaxChar}
              className="WM_GLOBAL_heading42"
              as={S.SubTitle}
              onChange={Actions.changeSubTitle}
              changeAlignment={Actions.changeSubTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Description}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <Buttons data={buttons} />
          </S.HeaderContent>
        </S.WmCustomContainer>
      </Show>
      <SocialLinks
        className="header-socials"
        socials={headerSocials}
        socialsMaxCount={validation.headerSocials.max}
      />
    </S.StyledContainer>
  )
})

export default Header25
