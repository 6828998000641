/* @flow */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useContext,
  memo
} from 'react'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'
import { useRouteMatch, Redirect } from 'react-router-dom'

import { translate } from '@editor/common/utils/translations'
import {
  getCurrentPageComponents,
  selectActivePagePath,
  hasComponents,
  selectAllPagePaths,
  selectComponentCategoriesForView,
  selectPagesSpecialComponent,
  selectIsComponentsDataLoading,
  selectIsCompCategoriesLoading,
  selectIsComponentsListLoading
} from '@selectors'
import {
  fetchComponentCategories,
  changeProjectPage,
  fetchComponentsList
} from '@actions'
import {
  Popup,
  IconButton
} from '@editor/common/styled-components/choose-component-template'
import { PopupsLoader } from '@editor/common/components/Loaders'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import LargerGrid from '@editor/common/assets/svgr-icons/larger_grid.svg'
import FilterIcon from '@editor/common/assets/svgr-icons/filter_icon.svg'
import SmallerGrid from '@editor/common/assets/svgr-icons/smaller_grid.svg'
import { EDITOR_BASE } from '@editor/conf/routes'
import { MobileDetectContext } from '@contexts'
import { useHistoryWithSearch } from '@hooks'
import { useResponsive } from '@shared/hooks'

import MainContent from './MainContent'
import * as Styled from './styled'
import type { TChooseComponentProps } from './types'

const PopupAdditionalOverlay = memo(() => {
  const popupEl = document.getElementsByClassName('components-popup')[0]
  if (!popupEl) {
    return null
  }
  return createPortal(<Styled.Overlay />, popupEl)
})

const ChooseComponent = ({
  pageComponents,
  actionType = 'add',
  changeProjectPage,
  hasComponents,
  allPagePaths,
  activePagePath: pagePathFromStore,
  componentCategories,
  fetchComponentCategories,
  fetchComponentsList,
  hasNotPageSpecialComp,
  isCategoriesLoading,
  isComponentsListLoading,
  isComponentsDataLoading
}: TChooseComponentProps) => {
  const [isLargerGrid, setGridState] = useState(true)
  const [selectedComponents, setSelectedComponents] = useState([])
  const [isMobileCategoriesOpen, setMobileCategoriesState] = useState(false)

  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(EDITOR_BASE)
  const {
    params: { page, idx: _idx }
  } = useRouteMatch()
  const isMobile = useContext(MobileDetectContext)
  const isGridButtonHidden = useResponsive(1200)

  const idx = parseInt(_idx)
  const isCompReplacing = actionType === 'replace'
  const pagePath = `/${page}`
  const hasPagePathMismatches = pagePath !== pagePathFromStore
  const isLoaderVisible =
    isCategoriesLoading || isComponentsListLoading || isComponentsDataLoading

  const _activeCompCategory = useMemo(
    () => pageComponents[idx]?.category,
    [idx]
  )

  useEffect(() => {
    fetchComponentCategories()
    fetchComponentsList()
  }, [])

  useEffect(() => {
    if (hasPagePathMismatches) {
      changeProjectPage(pagePath)
    }
  }, [hasPagePathMismatches, pagePath])

  const isUrlInvalid = useMemo(() => {
    // eslint-disable-next-line
    const isInvalidIdx = idx != _idx
    const isInvalidPagePath =
      allPagePaths.findIndex(path => path === pagePath) === -1

    if (isInvalidIdx || isInvalidPagePath) {
      return true
    }

    const isInValidReplacingIndex =
      isCompReplacing && (idx < 0 || idx >= pageComponents.length)

    const isFirstComponentDisallowed = pageComponents.length && idx === 0
    const isLastComponentDisallowed =
      pageComponents[idx - 1]?.category === 'footer'
    const isIncorrectAddingIdx = idx < 0 || idx > pageComponents.length

    const isInvalidAddingIndex =
      !isCompReplacing &&
      (isFirstComponentDisallowed ||
        isLastComponentDisallowed ||
        isIncorrectAddingIdx)

    return isInValidReplacingIndex || isInvalidAddingIndex
  }, [
    pageComponents,
    pagePathFromStore,
    pagePath,
    isCompReplacing,
    allPagePaths
  ])

  const onClose = useCallback(() => {
    const _url = hasComponents
      ? `${url}/edit${pagePath}`
      : `${url}/start-creation`

    history.push(_url)
  }, [hasComponents, page, url])

  const exceptUniqueCats = categories => {
    const uniqCats = pageComponents
      .filter(Comp => Comp.isUnique)
      .map(Comp => Comp.category)

    //if the adding component is not the last one
    if (idx !== pageComponents.length && pageComponents.length !== 0) {
      uniqCats.push('footer')
    }
    return categories.filter(item => uniqCats.indexOf(item.key) === -1)
  }

  const onlyCurrentCompCat = categories =>
    categories.filter(item => item.key === _activeCompCategory)

  const isReplacingUniqComponent =
    isCompReplacing && pageComponents[idx]?.isUnique

  const filterCategories =
    isReplacingUniqComponent && _activeCompCategory !== 'rsvp'
      ? onlyCurrentCompCat
      : exceptUniqueCats

  const hasSpecialCategory =
    (!isCompReplacing && hasNotPageSpecialComp) ||
    (isCompReplacing && _activeCompCategory === 'rsvp')

  const filteredCats = filterCategories(componentCategories)

  const toggleGrid = useCallback(() => {
    setGridState(!isLargerGrid)
  }, [isLargerGrid])

  const openMobileCategories = useCallback(() => {
    setMobileCategoriesState(true)
  }, [])

  if (hasPagePathMismatches) {
    return null
  }

  if (isUrlInvalid) {
    return <Redirect to={`${url}/edit`} />
  }

  return (
    <>
      {selectedComponents.length ? <PopupAdditionalOverlay /> : null}
      <Popup
        isOpen={true}
        onClose={onClose}
        isMobileFullScreen
        className="components-popup"
      >
        <PopupsLoader isLoading={isLoaderVisible} loadingText="" />
        <Styled.Header>
          <Styled.Title>{translate('components_label')}</Styled.Title>
          <Styled.HeaderButtons>
            {!isGridButtonHidden ? (
              <TooltipWrapper
                color="#363F5A"
                position="bottom"
                text={
                  isLargerGrid
                    ? translate('smaller_grid_label')
                    : translate('larger_grid')
                }
              >
                <IconButton onClick={toggleGrid}>
                  {isLargerGrid ? <SmallerGrid /> : <LargerGrid />}
                </IconButton>
              </TooltipWrapper>
            ) : null}
            {isMobile ? (
              <IconButton onClick={openMobileCategories}>
                <FilterIcon />
              </IconButton>
            ) : null}
          </Styled.HeaderButtons>
        </Styled.Header>
        <MainContent
          baseUrl={url}
          filteredCats={filteredCats}
          selectedComponents={selectedComponents}
          isLargerGrid={isLargerGrid}
          isCompReplacing={isCompReplacing}
          isMobileCategoriesOpen={isMobileCategoriesOpen}
          setSelectedComponents={setSelectedComponents}
          setMobileCategoriesState={setMobileCategoriesState}
          hasSpecialCategory={hasSpecialCategory}
        />
      </Popup>
    </>
  )
}

const mapStateToProps = state => ({
  pageComponents: getCurrentPageComponents(state),
  activePagePath: selectActivePagePath(state),
  hasComponents: hasComponents(state),
  allPagePaths: selectAllPagePaths(state),
  componentCategories: selectComponentCategoriesForView(state),
  hasNotPageSpecialComp: !selectPagesSpecialComponent(state),
  isCategoriesLoading: selectIsCompCategoriesLoading(state),
  isComponentsListLoading: selectIsComponentsListLoading(state),
  isComponentsDataLoading: selectIsComponentsDataLoading(state)
})

export default connect(mapStateToProps, {
  changeProjectPage,
  fetchComponentCategories,
  fetchComponentsList
})(ChooseComponent)
