// @flow
import React, { useCallback, useState, memo } from 'react'

import * as Styled from './styled'
import type { TInputProps } from './types'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'

const Input = ({
  type,
  value,
  error,
  className,
  placeholder,
  placeholderColor,
  borderColor,
  borderHoverColor,
  iconName,
  disabled,
  onRemoveIconClick,
  onChange,
  onKeyPress,
  onKeyDown,
  onBlur,
  onFocus,
  onPaste
}: TInputProps) => {
  const [isInputActive, setInputActiveState] = useState(false)

  const isRemoveIconVisible = isInputActive && value
  const _onChange = useCallback(
    e => {
      onChange(e)
      setInputActiveState(true)
    },
    [value, onChange]
  )

  const _onFocus = useCallback(() => {
    typeof onFocus === 'function' && onFocus()
    setInputActiveState(true)
  }, [value, onFocus])

  const _onBlur = useCallback(
    e => {
      typeof onBlur === 'function' && onBlur(e)

      setInputActiveState(false)
    },
    [onBlur]
  )

  const _onKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.target.blur()
      }

      typeof onKeyPress === 'function' && onKeyPress(e)
    },
    [onKeyPress]
  )

  return (
    <Styled.InputContainer className="input-container">
      {iconName ? (
        <Icon
          className="left-icon"
          name={iconName}
          size="medium"
          color="#545f7e"
        />
      ) : null}
      <Styled.Input
        type={type}
        value={value}
        className={className}
        placeholder={placeholder}
        placeholderColor={placeholderColor}
        borderColor={borderColor}
        borderHoverColor={borderHoverColor}
        isActive={isInputActive}
        disabled={disabled}
        isLeftIconExists={iconName}
        hasError={error}
        onChange={_onChange}
        onKeyPress={_onKeyPress}
        onKeyDown={onKeyDown}
        onFocus={_onFocus}
        onBlur={_onBlur}
        onPaste={onPaste}
      />
      {isRemoveIconVisible ? (
        <div onMouseDown={onRemoveIconClick} onTouchEnd={onRemoveIconClick}>
          <Icon
            className="remove-icon"
            name="cancel"
            size="medium"
            color="#9AA6C8"
          />
        </div>
      ) : null}
      {error && (
        <Styled.ErrorMessage className="error-message 1">
          <Styled.ErrorIcon name="warning" size="xxSmall" color="#ff4c4c" />
          <Styled.Error>{error}</Styled.Error>
        </Styled.ErrorMessage>
      )}
    </Styled.InputContainer>
  )
}

export default memo(Input)
