//@flow

import { useContext, useEffect } from 'react'
import { ConfirmDialogContext } from '@contexts/ConfirmDialog.context'

//TODO: watchedContentId need to be removed and refactor the hook!
export const useDiscardDialog = (
  cb,
  changeableContent = {},
  additionalProps = {}
) => {
  const { id, hasChanges, watchedContentId = '' } = changeableContent
  const { setProps, changedContentIds, setChangedContentIds } =
    useContext(ConfirmDialogContext)

  useEffect(() => {
    setChangedContentIds(ids =>
      hasChanges ? [...ids, id] : ids.filter(_id => _id !== id)
    )
  }, [hasChanges])

  const handleConfirm =
    (...args) =>
    () => {
      setProps({ isOpen: false })
      setChangedContentIds(
        changedContentIds.filter(_id => _id !== id && _id !== watchedContentId)
      )
      cb(...args)
    }

  const shouldOpen =
    changedContentIds.includes(id) ||
    changedContentIds.includes(watchedContentId)

  return shouldOpen
    ? (...args) => {
        setProps({
          isOpen: true,
          onConfirm: handleConfirm(...args),
          ...additionalProps
        })
      }
    : cb
}
