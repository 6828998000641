// @flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TPriceContentProps } from './types'
import { addSpacesToPrice } from '../../utils'
import { translate } from '@editor/common/utils/translations'

const PriceContent = ({
  price,
  discountedPrice,
  currency,
  isYearly,
  isUpgrade,
  isSmallSize,
  isInMobileCard
}: TPriceContentProps) =>
  isInMobileCard && discountedPrice ? (
    <>
      <Styled.PriceWrapper isSmallSize={isSmallSize}>
        <Styled.Currency
          className="currency"
          isDiscountedPrice={discountedPrice}
        >
          {currency}
        </Styled.Currency>
        <Styled.Price className="price" isDiscountedPrice={discountedPrice}>
          {addSpacesToPrice(discountedPrice)}
        </Styled.Price>
        <Styled.OldPriceContainer>
          <Styled.OldPriceWrapper>
            <Styled.OldPriceCurrency>{currency}</Styled.OldPriceCurrency>
            <Styled.OldPrice>{price}</Styled.OldPrice>
          </Styled.OldPriceWrapper>
        </Styled.OldPriceContainer>
        <Styled.Date>
          / {translate('per_month_label').toLowerCase()}
        </Styled.Date>
      </Styled.PriceWrapper>
      <Styled.DiscountedPriceBilledInfo isVisible={isYearly}>
        ({currency}
        {addSpacesToPrice(+(discountedPrice * 12).toFixed(2))}{' '}
        <span>
          {currency}
          {addSpacesToPrice(price * 12)}
        </span>{' '}
        / {translate('billed_annually_label')})
      </Styled.DiscountedPriceBilledInfo>
    </>
  ) : discountedPrice ? (
    <>
      <Styled.PriceContent>
        <Styled.PriceWrapper
          isDiscountedPrice={discountedPrice}
          isSmallSize={isSmallSize}
        >
          <Styled.Currency
            className="currency"
            isDiscountedPrice={discountedPrice}
          >
            {currency}
          </Styled.Currency>
          <Styled.Price className="price" isDiscountedPrice={discountedPrice}>
            {addSpacesToPrice(discountedPrice)}
          </Styled.Price>
        </Styled.PriceWrapper>
        <Styled.OldPriceContainer isInCard={!isInMobileCard}>
          <Styled.OldPriceWrapper>
            <Styled.OldPriceCurrency>{currency}</Styled.OldPriceCurrency>
            <Styled.OldPrice>{price}</Styled.OldPrice>
          </Styled.OldPriceWrapper>
          <Styled.Date>
            / {translate('per_month_label').toLowerCase()}
          </Styled.Date>
        </Styled.OldPriceContainer>
      </Styled.PriceContent>

      <Styled.DiscountedPriceBilledInfo isVisible={isYearly}>
        ({currency}
        {addSpacesToPrice(+(discountedPrice * 12).toFixed(2))}{' '}
        <span>
          {currency}
          {addSpacesToPrice(price * 12)}
        </span>{' '}
        / {translate('billed_annually_label')})
      </Styled.DiscountedPriceBilledInfo>
    </>
  ) : (
    <>
      <Styled.PriceWrapper isSmallSize={isSmallSize}>
        <Styled.Currency className="currency">{currency}</Styled.Currency>
        <Styled.Price className="price">{addSpacesToPrice(price)}</Styled.Price>
        {!isUpgrade ? (
          <Styled.Date>
            / {translate('per_month_label').toLowerCase()}
          </Styled.Date>
        ) : null}
      </Styled.PriceWrapper>

      <Styled.BilledInfo isVisible={!isUpgrade && isYearly}>
        {`(${currency}${addSpacesToPrice(price * 12)} / ${translate(
          'billed_annually_label'
        )})`}
      </Styled.BilledInfo>
    </>
  )

export default memo(PriceContent)
