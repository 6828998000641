//@flow
import React, { memo, useEffect, useRef, useState } from 'react'

import { getAIWebsitePopularCategoriesAPI } from '@api'
import debounce from '@editor/common/utils/debounce'
import { translate } from '@editor/common/utils/translations'
import { useBackdropClick } from '@hooks'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { secondary } from '@website/common/styles/colors'

import { NAME_CATEGORY_CHAR_LIMIT } from '../consts'
import { InputBG } from '../styled'
import * as Styled from './styled'
import SuggestionItem from './SuggestionItem'

type TProps = {
  inputValue: string,
  setInputValue: (value: string) => void
}

const CategoriesSearchInput = ({ inputValue, setInputValue }: TProps) => {
  const [isInputActive, setInputActiveState] = useState(false)
  const [popularCategories, setPopularCategories] = useState([])

  const { current: onDebounceSearch } = useRef(
    debounce(searchValue => {
      getAIWebsitePopularCategoriesAPI(searchValue).then(({ data }) => {
        setPopularCategories(data)
      })
    }, 500)
  )

  useEffect(() => {
    onDebounceSearch(inputValue.trim())
  }, [inputValue])

  const onChange = e => {
    const { value } = e.target

    if (value.length >= NAME_CATEGORY_CHAR_LIMIT) {
      return
    }

    setInputValue(e.target.value)
  }

  const onFocus = () => {
    setInputActiveState(true)
  }

  const deactivateInput = () => {
    setInputActiveState(false)
  }

  const inputRef = useBackdropClick(() => {
    deactivateInput()
  }, ['.suggestions-list'])

  return (
    <Styled.Container>
      <Styled.SearchInputWrapper>
        <Styled.SearchInput
          ref={inputRef}
          value={inputValue}
          placeholder={translate('marketing_agency_restaurant_placeholder')}
          onChange={onChange}
          onFocus={onFocus}
        />
        <InputBG />
        <Icon
          className="search-icon"
          name="search_tiny"
          size="medium"
          color={secondary.Normal}
        />
      </Styled.SearchInputWrapper>
      {isInputActive && popularCategories.length ? (
        <Styled.SuggestionsList className="suggestions-list">
          {popularCategories.map((suggestion, index) => (
            <SuggestionItem
              index={index}
              suggestion={suggestion.name}
              inputValue={inputValue}
              setInputValue={setInputValue}
              closeSuggestionsList={deactivateInput}
            />
          ))}
        </Styled.SuggestionsList>
      ) : null}
    </Styled.Container>
  )
}

export default memo(CategoriesSearchInput)
