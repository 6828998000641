export const validation = {
  title: { required: true },
  slideTitle: { required: true },
  titleMaxChar: 50,
  paragraphMaxChar: 150,
  blockTitleMaxChar: 200,
  slides: {
    min: 1,
    max: 10
  }
}
