/* @flow */
import {
  iconActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  addIcon,
  changeTitle,
  changeParagraph,
  removeBlock
} from '@website/components/CommonActions'
export default dispatcher => {
  const changeBlockContent = (newContent: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_CONTENT',
      value: { newContent, idx }
    })
  }

  const changeBlockTitle = (newTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newTitle, idx }
    })
  }

  const changeBlockSubTitle = (newSubTitle: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_SUB_TITLE',
      value: { newSubTitle, idx }
    })
  }

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const changeTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraphAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  const changeStepTitleAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_STEP_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeSubTitleAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_STEP_SUBTITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeStepContentAlignment = (idx: number) => (alignment: string) => {
    dispatcher({
      type: 'CHANGE_STEP_CONTENT_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    addIcon: idx => dispatcher(addIcon(idx)),
    addBlock: () => dispatcher(addBlock()),
    removeBlock,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle,
    changeBlockSubTitle,
    changeBlockContent,
    getIconActions: iconActionMaker(dispatcher),
    blockActions: removeBlockActionMaker(dispatcher),
    changeTitleAlignment,
    changeParagraphAlignment,
    changeStepTitleAlignment,
    changeSubTitleAlignment,
    changeStepContentAlignment
  }
}
