/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Steps4.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
const getStyleForProp = styleProvider(THEME)
import { getTextStyleByAlignment } from '@website/common/utils'

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '150px 0 80px 0' : ' 80px 0')};
  }
`
const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  > :first-child {
    padding-top: 0;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const StepsContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  @media only screen and (max-width: 992px) {
    margin-top: 40px;
  }
`

const StepsParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  max-width: 680px;
  ${getTextStyleByAlignment}
  margin: 0px 0 40px 0;
  @media only screen and (max-width: 992px) {
    margin: 0px auto 40px auto;
  }
`

const StepsWrap = styled.div`
  width: 100%;
  max-width: 880px;
  margin: 0 auto;

  & > .control-container {
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
  }

  &:last-child {
    ${StepsParagraph} {
      margin-bottom: 0;
    }
  }
`

const StepItemWrap = styled.div`
  display: flex;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  margin-top: 20px;
`

const StepContent = styled.div`
  border-left: 1px solid ${getStyleForProp('paragraph')};
  width: 80%;
  padding: 0 0 0 50px;
  position: relative;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 20px;
        `
      : css``};

  .control-container {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 992px) {
    padding: 0;
    border: none;
    text-align: center;
    width: 100%;
  }
`

const StepInfoWrapper = styled.div`
  width: 20%;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 20px;
          @media only screen and (max-width: 992px) {
            margin-bottom: 20px;
          }
        `
      : css``};
  & > .control-container {
    width: 100%;
  }
  @media only screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

const StepInfo = styled.p`
  color: ${getStyleForProp('stepsInfo')};
  font-weight: 800;
  display: flex;
  display: block;
  justify-content: flex-end;
  width: 100%;
  max-width: 150px;
  ${getTextStyleByAlignment};
  word-break: break-word;
  @media only screen and (max-width: 992px) {
    padding: 0;
    max-width: unset;
    justify-content: center;
  }
`

const StepTitle = styled.p`
  color: ${getStyleForProp('stepsTitle')};
  ${getTextStyleByAlignment}
  & + p {
    margin-top: 20px;
  }
`

const Circle = styled.div`
  background-color: ${getStyleForProp('paragraph')};
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: -4px;
  @media only screen and (max-width: 992px) {
    display: none;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  TextContainer,
  StepsContainer,
  StepsWrap,
  StepItemWrap,
  Title,
  Description,
  StepContent,
  StepInfo,
  StepInfoWrapper,
  StepTitle,
  StepsParagraph,
  Circle
}
