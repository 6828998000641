/* @flow */
import React, { useContext, memo } from 'react'
import S from './PayPal3.styled'
import { validation } from './PayPal3.consts'
import ActionMaker from './Actions'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import { PayPalButtonForm } from '@website/common/components/PayPalButton'
import { getCurrencySign } from '@website/common/utils'

const PayPal3 = memo(props => {
  const {
    data: {
      titleAlignment,
      paypalBtnDefaultProps,
      paragraph,
      paragraphAlignment,
      blocks,
      additionalText,
      additionalUrl,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency
    },
    componentId
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionMaker(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const {
    headline,
    currency,
    productName,
    productPrice,
    productDiscountedPrice
  } = paypalBtnDefaultProps

  const isSelling = paypalBtnDefaultProps.type === 'selling'

  const editableContentCustomActions = Actions.paypalButtonModalActions(
    paypalBtnDefaultProps,
    componentId
  )

  return (
    <S.StyledContainer>
      <S.WmCustomContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.ContentContainer>
          <S.TextContentWrapper
            areAllBlocksDeleted={blocks.length === 0}
            isEditing={isEditing}
          >
            <EditableContent
              text={isSelling ? productName : headline}
              as={S.Title}
              required={true}
              alignment={titleAlignment}
              className="WM_GLOBAL_heading42"
              changeAlignment={Actions.changeTitleAlignment}
              isTypingDisabled
              isTextFormatingHidden
              customActions={editableContentCustomActions}
            />
            {isSelling ? (
              <S.PriceContainer>
                <EditableContent
                  as={S.PriceCount}
                  text={`${getCurrencySign(currency)}&nbsp${productPrice}`}
                  className="WM_GLOBAL_heading42"
                  isTypingDisabled
                  isTextFormatingHidden
                  customActions={editableContentCustomActions}
                />
                {productDiscountedPrice ? (
                  <EditableContent
                    as={S.PrevPriceCount}
                    text={`${getCurrencySign(
                      currency
                    )}&nbsp${productDiscountedPrice}`}
                    className="WM_GLOBAL_heading32"
                    isTypingDisabled
                    isTextFormatingHidden
                    customActions={editableContentCustomActions}
                  />
                ) : null}
              </S.PriceContainer>
            ) : null}
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Paragraph}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
              isSelling={isSelling}
            />
            <S.ButtonsWrap>
              <PayPalButtonForm
                data={paypalBtnDefaultProps}
                compId={componentId}
              />
              <AdditionalLink
                additionalLinkText={additionalText}
                additionalLinkUrl={additionalUrl}
              />
            </S.ButtonsWrap>
          </S.TextContentWrapper>
        </S.ContentContainer>
      </S.WmCustomContainer>
      <Show when={[blocks.length !== 0]}>
        <S.BlocksWrapper>
          <S.BlocksContainer>
            <Mapper
              data={blocks}
              render={(block: string, idx: number) => (
                <Show when={[block.paragraph]}>
                  <S.Block key={idx} areThreeBlock={blocks.length === 3}>
                    <ControlsWithModalOpener
                      actions={Actions.blockActions({
                        idx,
                        showRemove: blocks.length > validation.blocks.min
                      })}
                      style={{ height: '100%', width: '100%' }}
                    >
                      <S.BlockItemWrap>
                        <S.IconContainer isEditing={isEditing}>
                          <ControlsWithModalOpener
                            actions={Actions.getIconActions({
                              idx,
                              showRemove: false
                            })}
                            style={{ height: 'auto' }}
                          >
                            <S.IconWrap isEditing={isEditing}>
                              <Icon name={block.iconName} />
                            </S.IconWrap>
                          </ControlsWithModalOpener>
                        </S.IconContainer>
                        <EditableContent
                          as={S.BlockParagraph}
                          text={block.paragraph}
                          isEditing={isEditing}
                          required={!block.title}
                          maxCount={validation.blockParagraphMaxChar}
                          className="WM_GLOBAL_paragraph18"
                          onChange={(newBlockParagraph: string) =>
                            Actions.changeBlockParagraph(newBlockParagraph, idx)
                          }
                        />
                      </S.BlockItemWrap>
                    </ControlsWithModalOpener>
                  </S.Block>
                </Show>
              )}
            />
          </S.BlocksContainer>
          <AddButton
            onAdd={Actions.addItem}
            toShow={blocks.length < validation.blocks.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_block_label')}
          </AddButton>
        </S.BlocksWrapper>
      </Show>
    </S.StyledContainer>
  )
})

export default PayPal3
