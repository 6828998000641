import { createSelector } from 'reselect'

export const selectSemrush = state => state.semrush

export const selectSemrushKeywordTableFilterQueries = createSelector(
  selectSemrush,
  state => state.queries
)

export const selectSemrushKeywordTableActiveTab = createSelector(
  selectSemrushKeywordTableFilterQueries,
  state => state.activeTab
)

export const selectSemrushKeywordTableDatabase = createSelector(
  selectSemrushKeywordTableFilterQueries,
  state => state.database
)

export const selectSemrushKeywordTablePhrase = createSelector(
  selectSemrushKeywordTableFilterQueries,
  state => state.phrase
)

export const selectSemrushKeywordTableSortingInfo = createSelector(
  selectSemrushKeywordTableFilterQueries,
  state => state.sortingInfo
)

export const selectSemrushLoginInfo = createSelector(
  selectSemrush,
  state => state.loginInfo
)

export const selectSemrushKeywordsData = createSelector(
  selectSemrush,
  state => state.data
)


export const selectSemrushKeywordsSearchLimitExceededState = createSelector(
  selectSemrushKeywordsData,
  state => state.hasLimitExceeded
)

export const selectSemrushKeywordTableRowsOveredState = createSelector(
  selectSemrushKeywordsData,
  state => state.isOvered
)

export const selectSemrushKeywordTableRowsLength = createSelector(
  selectSemrushKeywordsData,
  state => state.payload?.rows?.length || 0
)
