/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header18.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding-top: ${({ topOffset, isEditing }) =>
    isEditing ? '0' : `${topOffset}px`};
  padding-bottom: ${({ isSingleSlide }) => (isSingleSlide ? '160px' : '80px')};
  position: relative;
  & ${S.Container} {
    margin-bottom: 0;
  }
  @media only screen and (max-width: 1440px) {
    padding-bottom: ${({ isSingleSlide }) =>
      isSingleSlide ? '130px' : '80px'};
  }
  @media only screen and (max-width: 1024px) and (min-width: 955px) {
    ${({ isSingleSlide }) =>
      isSingleSlide
        ? css`
            padding-bottom: 160px;
          `
        : css``}
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: ${({ isSingleSlide }) => (isSingleSlide ? '80px' : '55px')};
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 80px 0 0 0;
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 457px) {
    padding-top: 60px;
  }
`

const NavBarWrapper = styled.div`
  background: ${getStyleForProp('navbarBg')};
`

const HeaderContent = styled.div`
  width: 100%;
  width: 520px;
  min-width: 520px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: -80px;
  @media only screen and (max-width: 1365px) {
    min-width: 500px;
  }
  @media only screen and (max-width: 1200px) {
    align-items: center;
    right: initial;
    margin: 0 0 30px 0;
    min-width: initial;
    width: 100%;
    max-width: 600px;
    .link-wrapper {
      justify-content: center;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  @media only screen and (max-width: 375px) {
    align-items: stretch;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    width: 100%;
    margin-bottom: 30px;
    h2,
    p {
      padding-top: 0;
      margin-bottom: 0;
    }

    & + .buttons-block {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  width: 100%;
  box-sizing: border-box;
`

const SubTitle = styled.h2`
  color: ${getStyleForProp('subTitle')};
  width: 100%;
  font-weight: bold !important;
  ${getTextStyleByAlignment};
  padding-top: 20px;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  padding-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 1200px) {
    max-width: initial;
  }
`

const MainImgContainer = styled.div`
  width: 600px;
  height: 600px;
  img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
  @media only screen and (max-width: 1440px) and (min-width: 1200px),
    screen and (max-width: 950px) {
    width: 450px;
    height: 450px;
  }
  @media only screen and (max-width: 550px) {
    width: 80vw;
    height: 80vw;
  }
`

const SecondaryImgContainer = styled.div`
  width: 330px;
  position: absolute;
  bottom: -80px;
  right: -180px;
  img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    border-radius: ${getStyleForProp('cardRadius')};
  }
  @media only screen and (max-width: 1440px) and (min-width: 1200px),
    screen and (max-width: 950px) {
    width: 200px;
    bottom: -50px;
    right: -50px;
  }
  @media only screen and (max-width: 550px) {
    width: 40vw;
    bottom: -20px;
    right: -20px;
  }
`

const SlideImagesContainer = styled.div`
  position: relative;
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const SlidesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 180px 0 0;
  @media only screen and (max-width: 1600px) {
    padding: 0 150px 0 0;
  }
  @media only screen and (max-width: 1365px) {
    padding: 0 84px 0 0;
  }
  @media only screen and (max-width: 1200px) {
    margin: 30px 0;
    padding: 0;
  }
  @media only screen and (max-width: 1024px) {
    margin: 10px 0 0 0;
  }
`

const SliderContainer = styled.div`
  display: flex;
  ${props =>
    props.animate &&
    css`
      animation: slidein 500ms linear;
      @keyframes slidein {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    `}
  @media (max-width: 1020px) {
    flex-direction: column-reverse;
  }
`
const IconContainer = styled.button`
  position: relative;
  width: 30px;
  min-width: 30px;
  height: 30px;
  border: 1px solid ${getStyleForProp('iconColor')};
  border-radius: ${getStyleForProp('iconRadius')};
  color: ${getStyleForProp('iconColor')};
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: transparent;
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('iconColor')
}))`
  position: absolute;
`

const Img = styled.img``

const AddButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const SliderCounter = styled.div`
  color: ${getStyleForProp('heading')};
  display: ${({ isEditing }) => (isEditing ? 'block' : 'none')};
  padding-top: 30px;
`

const ButtonsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 36px;
  @media only screen and (max-width: 1200px) {
    margin-top: 0px;
  }
  @media only screen and (max-width: 550px) {
    margin-top: ${({ isEditing }) => (isEditing ? '0' : '40px')};
  }
`

export default {
  SlideImagesContainer,
  MainImgContainer,
  SecondaryImgContainer,
  SlidesContainer,
  Img,
  WmCustomContainer,
  StyledContainer,
  IconContainer,
  SliderContainer,
  HeaderContent,
  Title,
  SubTitle,
  Paragraph,
  ButtonsWrap,
  AddButtonsWrapper,
  SliderCounter,
  NavBarWrapper,
  Icon
}
