import React, { useMemo } from 'react'
import { Provider } from 'react-redux'

import { initializeStore } from '@redux/store/configureStore'
import Root from './Root'

const RootWithStore = () => {
  const store = useMemo(initializeStore, [])

  return (
    <Provider store={store}>
      <Root />
    </Provider>
  )
}

export default RootWithStore
