import { INITIAL_GUEST_DATA, INITIAL_STATE, NO } from './consts'

export const getInitialState = formData => {
  const rsvpOption = formData.rsvpOption

  const dynamicFields = formData.dynamicFields.map(dynamicField => {
    const { id, text } = dynamicField

    const item = { id, text }

    if (dynamicField.type === 'checkbox') {
      item.answer = dynamicField.isMultiple ? [] : [dynamicField.options[0]]
    } else {
      item.answer = ''
    }

    return item
  })

  const rsvpSelectedOption = rsvpOption.options[0]

  return {
    ...INITIAL_STATE,
    rsvpOption: {
      id: rsvpSelectedOption.id,
      text: rsvpSelectedOption.text,
      answer: rsvpSelectedOption.name
    },
    dynamicFields
  }
}

export const getCheckboxComponentItems = data =>
  data.map(dataItem => ({
    label: dataItem.text,
    value: dataItem.id
  }))

export const getRsvpRadioButtonsListItems = data =>
  data.map(dataItem => ({
    label: dataItem.text,
    value: dataItem.name
  }))

export const getRegistrationPopupContentProps = (data, activePopupType) => {
  const activePopupData = data[activePopupType]

  if (!activePopupData) {
    return {}
  }

  return {
    title: activePopupData.title.text,
    paragraph: activePopupData.paragraph.text,
    buttonText: activePopupData.buttonText?.text,
    showButton: activePopupData.showButton
  }
}

export const preparePayloadData = data => {
  const {
    /* eslint-disable no-unused-vars */
    dynamicFields,
    additionalGuests,
    additionalGuestNumber,
    siteHash,
    /* eslint-enable no-unused-vars */
    ...staticFields
  } = data

  const _additionalGuests = additionalGuests.map(guest => {
    // eslint-disable-next-line no-unused-vars
    const { id, ...restGuestData } = guest
    return restGuestData
  })

  const filteredDynamicFields = data.dynamicFields.filter(
    dynamicField => dynamicField.answer.length > 0
  )

  return data.rsvpOption.answer === NO
    ? { ...staticFields, siteHash }
    : {
        ...data,
        dynamicFields: filteredDynamicFields,
        additionalGuests: _additionalGuests
      }
}

export const generateUniqId = () => {
  const randomNumber = Math.floor(Math.random() * 1000)
  return `${(+new Date() + randomNumber).toString(16)}`
}

export const getInitialGuestData = () => ({
  ...INITIAL_GUEST_DATA,
  id: generateUniqId()
})