/* @flow */
import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'

import GridLayout from '@editor/common/components/GridLayout'
import { ComponentThumbnail, Footer } from './components'
import {
  selectNoHeaderCompMeta,
  getCurrentPageComponents,
  selectRsvpCompCategory,
  selectComponentsGroupedByCat
} from '@selectors'
import { addComponent, replaceComponent, addMultipleComponents } from '@actions'
import { useResponsive } from '@shared/hooks'
import { COMPONENT_CATEGORIES } from '@editor/conf/consts'

import * as Styled from './styled'
import type { TComponentThumbnailsProps } from './types'
import { AREA, ADDITIONAL_OFFSET, SCROLLABLE_CONTAINER_ID } from '../../consts'

const ComponentThumbnails = ({
  baseUrl,
  noHeaderCompMeta,
  activeCategory,
  rsvpCategory,
  filteredCats,
  pageComponents,
  selectedComponents,
  componentsByCat,
  isLargerGrid,
  isCompReplacing,
  setActiveCategory,
  setSelectedComponents,
  addComponent,
  replaceComponent,
  addMultipleComponents
}: TComponentThumbnailsProps) => {
  const [selectedHeader, setSelectedHeader] = useState(null)
  const [selectedFooter, setSelectedFooter] = useState(null)
  const [selectedRsvp, setSelectedRsvp] = useState(null)
  const [selectedOtherComponents, setSelectedOtherComponents] = useState([])

  const scrollableContainerRef = useRef(null)
  const containerRef = useRef(null)
  const isMediumSize = useResponsive(1400)
  const columnsCount = isLargerGrid
    ? isMediumSize
      ? 2
      : 3
    : isMediumSize
    ? 3
    : 4
  const hasPageComponents = !!pageComponents.length

  const {
    params: { idx, page, siteId }
  } = useRouteMatch()
  const history = useHistory()
  const isFromScratch =
    history.location.pathname.indexOf('start-creation') !== -1

  useEffect(() => {
    if (isCompReplacing && containerRef.current) {
      const _idx = parseInt(idx)
      const category = pageComponents[_idx]?.category
      const container = document.getElementById(`${category}`)
      const scrollableContainer = document.getElementById(
        SCROLLABLE_CONTAINER_ID
      )
      scrollableContainer.scrollTop = container?.offsetTop - ADDITIONAL_OFFSET
    }
  }, [containerRef.current])

  useEffect(() => {
    const _selectedComponents = [
      ...(selectedHeader ? [selectedHeader] : []),
      ...selectedOtherComponents,
      ...(selectedRsvp ? [selectedRsvp] : []),
      ...(selectedFooter ? [selectedFooter] : [])
    ]

    setSelectedComponents(_selectedComponents)
  }, [selectedHeader, selectedFooter, selectedRsvp, selectedOtherComponents])

  const compCategories = useMemo(
    () => (activeCategory === rsvpCategory.key ? [rsvpCategory] : filteredCats),
    [filteredCats, activeCategory]
  )

  const onScroll = useCallback(() => {
    const scrollableContainer = scrollableContainerRef.current
    const containers = scrollableContainerRef.current.childNodes
    const currentTop = scrollableContainer.scrollTop

    containers.forEach(container => {
      const containerTop = container.offsetTop
      const containerBottom = containerTop + container.offsetHeight

      if (currentTop >= containerTop - AREA && currentTop <= containerBottom) {
        const category = container.getAttribute('id')

        setActiveCategory(category)
      }
    })
  }, [activeCategory, compCategories, scrollableContainerRef.current])

  const onSelect = useCallback(
    (componentMeta, isSelected) => {
      const isHeader = componentMeta.category === COMPONENT_CATEGORIES.HEADER
      const isFooter = componentMeta.category === COMPONENT_CATEGORIES.FOOTER
      const isRSVP = componentMeta.category === COMPONENT_CATEGORIES.RSVP

      if (isHeader && componentMeta.id !== selectedHeader?.id) {
        setSelectedHeader(componentMeta)
        return
      }

      if (isFromScratch && !selectedHeader) {
        setSelectedHeader(noHeaderCompMeta)
      }

      if (isFooter) {
        const meta =
          componentMeta.id !== selectedFooter?.id ? componentMeta : null
        setSelectedFooter(meta)
        return
      }

      if (isRSVP) {
        const meta =
          componentMeta.id !== selectedRsvp?.id ? componentMeta : null
        setSelectedRsvp(meta)
        return
      }

      if (isSelected) {
        setSelectedOtherComponents(
          selectedOtherComponents.filter(({ id }) => id !== componentMeta.id)
        )
        return
      }

      setSelectedOtherComponents([...selectedOtherComponents, componentMeta])
    },
    [
      noHeaderCompMeta,
      componentsByCat,
      selectedOtherComponents,
      selectedHeader,
      selectedFooter,
      selectedRsvp,
      selectedComponents,
      isFromScratch
    ]
  )

  const onDeselect = useCallback(() => {
    setSelectedHeader(null)
    setSelectedFooter(null)
    setSelectedRsvp(null)
    setSelectedOtherComponents([])
    setSelectedComponents([])
  }, [])

  const navigateToEditor = useCallback(
    (url: string, compIdx: number) => {
      if (siteId === 'new') {
        history.replace(url, {
          scrollIndex: compIdx
        })
      } else {
        history.push(url, {
          scrollIndex: compIdx
        })
      }
    },
    [siteId, history]
  )

  const addOrReplaceComponent = useCallback(
    componentMeta => {
      const { search } = history.location
      const pagePath = page === 'home' ? '/' : `/${page}`
      const url = `${baseUrl}/edit/${page}${search}`
      const cb = () => navigateToEditor(url, idx)
      if (
        !hasPageComponents &&
        componentMeta.category !== COMPONENT_CATEGORIES.HEADER
      ) {
        const compWithNoHeader = [noHeaderCompMeta, componentMeta]
        addMultipleComponents(pagePath, idx, compWithNoHeader, cb)
        return
      }
      const action = isCompReplacing ? replaceComponent : addComponent

      action(pagePath, idx, componentMeta, cb)
    },
    [
      idx,
      baseUrl,
      page,
      isCompReplacing,
      componentsByCat,
      noHeaderCompMeta,
      hasPageComponents,
      addComponent,
      replaceComponent,
      addMultipleComponents
    ]
  )

  return (
    <Styled.ComponentThumbnailsWrapper>
      <Styled.ComponentThumbnails
        ref={scrollableContainerRef}
        id={SCROLLABLE_CONTAINER_ID}
        onScroll={onScroll}
      >
        {compCategories.map((cat, idx) => {
          const { name, key, description } = cat
          if (!componentsByCat[key]) {
            return null
          }

          return (
            <Styled.Container key={idx} id={key} ref={containerRef}>
              <Styled.CategoryTitle>
                <p>{name}</p>
                <span>{description}</span>
              </Styled.CategoryTitle>
              <GridLayout columnsCount={columnsCount} gap={20}>
                {Object.keys(componentsByCat[key]).map(componentId => {
                  const componentMeta = componentsByCat[key][componentId]
                  const selectedIndex = selectedComponents.findIndex(
                    comp => comp.id === componentMeta.id
                  )
                  const isSelected = selectedIndex !== -1
                  return (
                    <ComponentThumbnail
                      key={componentMeta.id}
                      isLargerGrid={isLargerGrid}
                      componentMeta={componentMeta}
                      selectedIndex={selectedIndex}
                      isCompReplacing={isCompReplacing}
                      isMultiSelectionActive={selectedComponents.length}
                      onClick={() => {
                        selectedComponents.length &&
                          onSelect(componentMeta, isSelected)
                      }}
                      onSelect={() => {
                        onSelect(componentMeta)
                      }}
                      addOrReplace={() => {
                        addOrReplaceComponent(componentMeta)
                      }}
                    />
                  )
                })}
              </GridLayout>
            </Styled.Container>
          )
        })}
      </Styled.ComponentThumbnails>
      <Footer
        baseUrl={baseUrl}
        onDeselect={onDeselect}
        selectedComponents={selectedComponents}
        navigateToEditor={navigateToEditor}
      />
    </Styled.ComponentThumbnailsWrapper>
  )
}

const mapStateToProps = state => ({
  noHeaderCompMeta: selectNoHeaderCompMeta(state),
  componentsByCat: selectComponentsGroupedByCat(state),
  pageComponents: getCurrentPageComponents(state),
  rsvpCategory: selectRsvpCompCategory(state)
})

const mapDispatchToProps = {
  addComponent,
  replaceComponent,
  addMultipleComponents
}

export default connect(mapStateToProps, mapDispatchToProps)(ComponentThumbnails)
