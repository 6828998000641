/* @flow */
import React from 'react'
import './Feature4.styles.sass'
import S from './Feature4.styled'
import { validation } from './Feature4.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'

import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import LinkWrapper from '@website/common/components/LinkWrapper'
import WMContainer from '@website/common/components/WMContainer'
import CircleIllustration from '@editor/common/assets/circle-ill.svg'
import { withDispatchContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ProportionalChildrenWrapper from '@website/common/components/ProportionalChildrenWrapper'
import { translate } from '@editor/common/utils/translations'

class Feature4 extends React.PureComponent<void, void> {
  render() {
    const {
      data: { title, description, features, icon, buttons },
      componentDispatcher
    } = this.props
    const Actions = ActionGetter(componentDispatcher)
    return (
      <S.Container>
        <WMContainer className="feature-with-list-container">
          <div className="feature-content feature-item">
            <Show when={[title, description]}>
              <div className="texts-content">
                <Show when={[title]}>
                  <EditableContent
                    text={title}
                    as={S.Title}
                    maxCount={validation.headingMaxChar}
                    onChange={(newTitle: string) =>
                      componentDispatcher(Actions.changeTitle(newTitle))
                    }
                    className="WM_GLOBAL_heading"
                  />
                </Show>
                <Show when={[description]}>
                  <EditableContent
                    text={description}
                    as={S.Description}
                    maxCount={validation.paragraphMaxChar}
                    onChange={(newContent: string) =>
                      componentDispatcher(Actions.changeParagraph(newContent))
                    }
                    className="WM_GLOBAL_paragraph"
                  />
                </Show>
              </div>
            </Show>
            <Show when={[...buttons]}>
              <S.ButtonsBlock>
                <S.ButtonsContainer>
                  <ProportionalChildrenWrapper
                    WrapperComp={S.ButtonsWrap}
                    className="WM_GLOBAL_secondary-font"
                  >
                    <Mapper
                      data={buttons}
                      render={(btn: string, btnIdx: number) => {
                        return (
                          <S.BtnWrapper
                            key={btnIdx}
                            isTertiary={btn.type === 'tertiary'}
                          >
                            <ControlsWithModalOpener
                              actions={Actions.getBtnActions(btnIdx, {
                                text: btn.buttonText,
                                link: btn.buttonUrl
                              })}
                            >
                              <LinkWrapper
                                linkProps={{
                                  className: 'rf-comp-btn'
                                }}
                                text={btn.buttonText}
                                url={btn.buttonUrl}
                              />
                            </ControlsWithModalOpener>
                          </S.BtnWrapper>
                        )
                      }}
                    />
                  </ProportionalChildrenWrapper>
                </S.ButtonsContainer>
                <AddButton
                  onAdd={() => componentDispatcher(Actions.addButton())}
                  toShow={buttons.length < validation.buttons.max}
                  rounded
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_button_label')}
                </AddButton>
              </S.ButtonsBlock>
            </Show>
            <ul className="feature-list-wrap">
              <Mapper
                data={features}
                render={(feature: string, idx: number) => (
                  <Show when={[feature]}>
                    <li className="feature-list-item-wrap" key={idx}>
                      <S.Icon
                        size="large"
                        name="check"
                        className="check-icon"
                      />
                      <ControlsWithModalOpener
                        actions={Actions.listActions(
                          idx,
                          features.length > validation.features.min
                        )}
                        key={idx}
                      >
                        <EditableContent
                          text={feature}
                          as={S.ListItem}
                          maxCount={validation.checkmarkMaxChar}
                          onChange={(newContent: string) =>
                            componentDispatcher(
                              Actions.changeListItem(newContent, idx)
                            )
                          }
                          className="feature-list-item WM_GLOBAL_secondary-font"
                        />
                      </ControlsWithModalOpener>
                    </li>
                  </Show>
                )}
              />
              <AddButton
                onAdd={() => componentDispatcher(Actions.addNewListItem())}
                toShow={features.length < validation.features.max}
                style={{
                  marginTop: '2rem'
                }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_item_label')}
              </AddButton>
            </ul>
          </div>
          <div className="feature-image-wrap feature-item">
            <figure className="feature-image-figure">
              <img
                src={CircleIllustration}
                alt="Section img"
                className="feature-image"
              />
              <S.IconWrap>
                <ControlsWithModalOpener
                  actions={Actions.iconActions({ idx: 0, showRemove: false })}
                >
                  <S.MainIcon name={icon} size={180} />
                </ControlsWithModalOpener>
              </S.IconWrap>
            </figure>
          </div>
        </WMContainer>
      </S.Container>
    )
  }
}

const Feature4WithDispatchContext = withDispatchContext(Feature4)
export default Feature4WithDispatchContext
