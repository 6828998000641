/* @flow */
import React, { useContext, memo } from 'react'
import S from './Email6.styled'
import { validation } from './Email6.consts'
import THEME from './Email6.theme'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { SubscribeForm } from '../SubscribeForm'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'

const Email6 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      formParagraph,
      titleAlignment,
      paragraphAlignment,
      formParagraphAlignment,
      placeholder,
      buttonText,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WMCustomContainer isEditing={isEditing}>
        <S.FormBlock>
          <S.LeftContainer>
            <S.EmailContent>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                required={!paragraph}
                maxCount={validation.maxTitleChar}
                onChange={Actions.changeTitle}
                className="WM_GLOBAL_heading32"
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Description}
                required={!title}
                maxCount={validation.maxParagraphChar}
                onChange={Actions.changeParagraph}
                className="WM_GLOBAL_paragraph"
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </S.EmailContent>
            <S.Triangle />
          </S.LeftContainer>
          <S.RightContainer>
            <S.FormWrap>
              <EditableContent
                text={formParagraph}
                alignment={formParagraphAlignment}
                as={S.Description}
                maxCount={validation.maxFormParagraphChar}
                onChange={Actions.changeFormParagraph}
                className="WM_GLOBAL_paragraph"
                changeAlignment={Actions.changeFormParagraphAlignment}
              />
              <SubscribeForm
                actions={{
                  buttonActions: Actions.getBtnActions(buttonText),
                  placeHolderAction: Actions.changePlaceHolderText
                }}
                buttonText={buttonText}
                placeholder={placeholder}
                theme={THEME}
              />
            </S.FormWrap>
          </S.RightContainer>
        </S.FormBlock>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})

export default Email6
