/* @flow */
import React from 'react'

import * as S from './styled'
import Icon from '@website/common/components/Icon'

const IconButton = ({ className = '', onClick, iconProps }) => {
  const { name = 'add', color = 'white', size = 'middle' } = iconProps

  return (
    <S.IconButton onClick={onClick} className={className}>
      <Icon color={color} size={size} name={name} />
    </S.IconButton>
  )
}

export default IconButton
