import { addAnalyticsEvent } from '@editor/common/utils'

export const getParentHyperlink = (element, boundaryEl) => {
  if (!element || !boundaryEl) {
    return null
  }
  let el = element

  while (el.nodeName !== 'A' && el.parentNode) {
    if (el === boundaryEl) {
      return null
    }
    el = el.parentNode
  }
  return el
}

export const getChildHyperLink = element =>
  element && element.getElementsByTagName('a')[0]

export const _addAnalyticsEvent = command =>
  addAnalyticsEvent('SiteMaker Text Customization', 'Customize text', command)
