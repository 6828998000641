// @flow

import http from '@shared/ApiFactory'
import { SM_API_PATH } from '@shared/ApiConfig'
import type { TEventEndpointType, TGuestListFilters } from '@types'
import { buildGuestListQuery } from './utils'
import objToQs from '@editor/common/utils/obj-to-qs'

// TODO: set all flow types

export const createEventAPI = (initialData: Object = {}, siteId: number) =>
  http.POST(`${SM_API_PATH}/sites/${siteId}/events`, initialData)

export const updateEventDataAPI = (
  type: TEventEndpointType,
  siteId: string,
  eventId: number,
  data: Object,
  params
) => {
  const queryString = objToQs(params)
  return http.PATCH(
    `${SM_API_PATH}/sites/${siteId}/events/${eventId}/type/${type}${queryString}`,
    data
  )
}

export const getEventTranslationAPI = (
  siteId: string,
  eventId: number,
  lang: string
) =>
  http.GET(
    `${SM_API_PATH}/sites/${siteId}/events/${eventId}/translation/${lang}`
  )

export const updateEventTranslationAPI = (
  lang: string,
  siteId: string,
  eventId: number,
  data: Object
) =>
  http.PATCH(
    `${SM_API_PATH}/sites/${siteId}/events/${eventId}/translation/${lang}`,
    data
  )

export const addGuestApi = (siteId: string, eventId: number, data: Object) =>
  http.POST(`${SM_API_PATH}/sites/${siteId}/events/${eventId}/guest`, data)

export const editGuestApi = (
  siteId: string,
  eventId: number,
  guestId: number,
  data: Object
) =>
  http.PATCH(
    `${SM_API_PATH}/sites/${siteId}/events/${eventId}/guest/${guestId}`,
    data
  )

export const fetchGuestsListAPI = (
  siteId: number,
  eventId: number,
  options: TGuestListFilters & { offset: number, limit: number }
) => {
  const query = buildGuestListQuery(options)
  const url = `${SM_API_PATH}/sites/${siteId}/events/${eventId}/guest${query}`
  return http.GET(url)
}

export const removeGuestAPI = (
  siteId: number,
  eventId: number,
  guestId: number
) =>
  http.DELETE(
    `${SM_API_PATH}/sites/${siteId}/events/${eventId}/guest/${guestId}`
  )

export const fetchFormDataFromRevisionAPI = (siteId: string, eventId: number) =>
  http.GET(`${SM_API_PATH}/sites/${siteId}/revisionEvent/${eventId}`)

export const deleteEventAPI = (siteId: number, eventId: number) =>
  http.DELETE(`${SM_API_PATH}/sites/${siteId}/events/${eventId}`)

export const updateEventEmailContentAPI = (
  siteId: string,
  eventId: number,
  type: string,
  data: Object
) =>
  http.PATCH(
    `${SM_API_PATH}/sites/${siteId}/events/emailContent/${eventId}/type/${type}`,
    data
  )

export const updateEventEmailSettingsAPI = (
  siteId: string,
  eventId: number,
  data: Object
) =>
  http.PATCH(
    `${SM_API_PATH}/sites/${siteId}/events/emailSettings/${eventId}/sender`,
    data
  )

export const updateEventEmailSubmissionStatusAPI = (
  siteId: string,
  eventId: number,
  type: string,
  data: Object
) =>
  http.PATCH(
    `${SM_API_PATH}/sites/${siteId}/events/emailSettings/${eventId}/type/${type}`,
    data
  )
