// TODO: Add all types of buttons here
// TODO: File name changed to buttons-new to avoid conflicts with button.js . Need to be merged these two files in the future

import styled, { css } from 'styled-components'

const getDisabledProps = ({ disabled }) =>
  disabled
    ? css`
        opacity: 0.5;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
      `
    : ``

export const BUTTON_STYLES = css`
  ${getDisabledProps};
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  border: none;
  outline: none;
  cursor: pointer;
`

export const PrimaryButton = styled.button`
  ${BUTTON_STYLES};
  background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
    0% no-repeat padding-box;
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #0052e080;
    transition: all 0.2s ease-out;
  }
  color: #ffffff;
`
