import styled, { css } from 'styled-components'
import { Popup as LibPopup } from '@renderforest/rf-ui-library/organism/Popup'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'

export const Popup = styled(LibPopup)`
  height: 100%;
  z-index: 50;
  & > div {
    padding: 0;
    width: 90%;
    height: 90%;
    max-height: 90%;
    max-width: 90%;
    & > button {
      top: 20px;
      right: 15px;
      z-index: 10;
    }
    & > div {
      padding: 0;
      position: relative;
    }
  }

  @media only screen and (max-width: 768px) {
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }

  .loader {
    z-index: 20;
  }
`

export const ThumbnailButtonWrapper = styled.div`
  width: 50%;
  display: flex;

  &:nth-child(1) {
    justify-content: flex-end;
  }
  &:nth-child(3) {
    justify-content: flex-start;
  }
  &:only-child {
    width: 100%;
    justify-content: center;
  }
`

export const ThumbnailButtonsWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
  ${({ fromBottom }) =>
    fromBottom
      ? css`
          height: 70%;
          border-radius: 0 0 10px 10px;
          background: transparent
            linear-gradient(180deg, #ffffff00 0%, #363f5a65 53%, #363f5a 100%);
        `
      : css`
          height: 100%;
          border-radius: 10px 10px 0 0;
          background: rgba(37, 46, 72, 0.7);
        `};

  @media only screen and (max-width: 768px) {
    height: 100%;
    background: ${({ fromBottom }) =>
      fromBottom ? 'rgba(54, 63, 90, 0.5)' : 'rgba(37, 46, 72, 0.7)'};
  }
`

export const ThumbnailButtons = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ fromBottom }) =>
    fromBottom
      ? css`
          bottom: 15px;
        `
      : css`
          top: 50%;
          transform: translateY(-50%);
        `};

  @media only screen and (max-width: 768px) {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
  }
`

const ThumbnailButton = styled.button`
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 300ms;
  overflow: hidden;
  background-clip: padding-box !important;
  -webkit-background-clip: padding-box !important;
  svg {
    position: absolute;
    flex-shrink: 0;
  }
  p {
    position: absolute;
    white-space: nowrap;
    font-size: 14px;
  }
  &:hover {
    transition: all 200ms cubic-bezier(0.62, 0.1, 0.5, 1);
  }
  @media only screen and (max-width: 768px) {
    &:hover {
      transition: all 0s;
    }
  }
`

export const LeftButton = styled(ThumbnailButton)`
  color: #252e48;
  border: 1px solid #dee3f080;
  box-shadow: 0 0 12px #363f5a80;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f5fafe 100%);
  svg {
    fill: #252e48;
    right: 5px;
  }
  p {
    right: 35px;
    color: #252e48;
  }
`

export const RightButton = styled(ThumbnailButton)`
  color: #ffffff;
  border: 1px solid #387dff80;
  box-shadow: 0 0 12px #387dff80;
  background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%);
  svg {
    fill: #ffffff;
    left: 5px;
  }
  p {
    left: 35px;
    color: #ffffff;
  }
`

export const Divider = styled.span`
  width: 1px;
  height: ${({ isLong }) => (isLong ? '50px' : '20px')};
  background-color: #f5fafe;
  opacity: 0.5;
  margin: 0 10px;
  flex-shrink: 0;
`

export const CategoriesFooter = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  z-index: 3;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px -3px 12px #5690ff1a;
`

export const Button = styled.button`
  background: transparent;
  color: #3271e6;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;

  &::after {
    content: '';
    display: block;
    width: 0;
    height: 1px;
    margin: 0 auto;
    background-color: #3271e6;
    transition: width 0.4s;
  }

  &:hover::after {
    width: 100%;
  }
`

export const IconButton = styled.div`
  position: relative;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent linear-gradient(180deg, #ffffff 0%, #f5fafe 100%);
  padding: 7px;
  border: 1px solid #c1c9e0;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  svg {
    fill: #252e48;
  }
  &:hover {
    background: #ffffff;
  }
`

export const SlideUpWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 60;
  background-color: rgba(37, 46, 72, 0.3);
  visibility: ${({ isSlideUpOpen }) => (isSlideUpOpen ? 'visible' : 'hidden')};
  transition: visibility 500ms ease-out;
`

export const SlideUp = styled.div`
  width: 100%;
  height: calc(100% - 20px);
  position: absolute;
  bottom: 0;
  background-color: white;
  border-radius: 10px 10px 0 0;
  transform: ${({ isSlideUpOpen }) =>
    isSlideUpOpen ? 'translateY(0)' : 'translateY(100%)'};
  transition: transform 200ms ease-out;
`

export const MobileCategoriesWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0 0;
`

export const CategoriesHeader = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-bottom: 1px solid #dde3f0;
  box-sizing: border-box;
`

export const CloseButton = styled(Icon)`
  margin-right: 15px;
`

export const SelectedSign = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 50%;
  position: absolute;
  top: 23px;
  left: 10px;
  background-color: #387dff80;

  &:after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #387dff;
  }
`

export const ButtonSection = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #8493bd;
  box-sizing: border-box;
`
