/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { idx, newParagraph } = action.value
        draft.block[idx].paragraph = newParagraph
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.block[idx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.block[idx].titleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.block[idx].paragraphAlignment = alignment
        break
      }
      case 'ADD_BLOCK':
        draft.block.push(initialState.block[0])
        break
      case 'REMOVE_BLOCK':
        const { idx } = action.value
        draft.block.splice(idx, 1)
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_IMAGE': {
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions || {}
        draft.imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
        break
      }
    }
  })
}
