/* @flow */
import styled from 'styled-components'
import THEME from './Header33.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  position: relative;
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0' : `${topOffset}px 0 0 0`};
  & ${S.Container} {
    margin-bottom: 0;
    nav {
      margin-right: 30px;
    }
  }
`

const WmCustomContainer = styled.div`
  display: flex;
  justify-content: center;
  min-height: 600px;
  position: relative;
  box-sizing: border-box;
  padding: 40px;
  @media only screen and (max-width: 1024px) {
    min-height: 62vw;
  }
  @media only screen and (max-width: 457px) {
    padding: 16px;
  }
`

const HeaderContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid ${getStyleForProp('borderColor')};
  box-sizing: border-box;
  text-align: center;
  padding: 40px;
  @media only screen and (max-width: 457px) {
    padding: 40px 20px;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 790px;
  & > .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContentContainer,
  HeaderContent,
  Title
}
