import React from 'react'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { LINK_TYPES } from '@editor/common/utils/project-links'
export { LINK_TYPES }

export const LINK_TYPE_OPTIONS = {
  EXTERNAL: {
    label: translate('open_web_address_label'),
    value: LINK_TYPES.EXTERNAL
  },
  TO_PAGE: {
    label: translate('open_page_label'),
    value: LINK_TYPES.TO_PAGE
  },
  TO_COMPONENT: {
    label: translate('scroll_to_component_label'),
    value: LINK_TYPES.TO_COMPONENT
  },
  PHONE_CALL: {
    label: (
      <div className="phone-call-label">
        {translate('make_phone_call_label')}
      </div>
    ),
    value: LINK_TYPES.PHONE_CALL
  }
}

export const MAX_TEXT_LIMIT = 19
export const TEXT_LIMIT_ERROR = transformTextVariables(
  translate('max_char_message'),
  [{ value: MAX_TEXT_LIMIT }]
)
export const REQUIRED_ERROR = translate('required_field_label')
export const PHONE_NUMBER_ERROR = translate('please_enter_phone_label')
export const SPACE_REGEXP = /^\s+$/
export const PHONE_INPUT_LABEL = translate('phone_input_label')
export const PAGE_CHOOSER_DROPDOWN_LABEL = translate(
  'page_chooser_dropdown_label'
)
