/* @flow */
import { compose, createStore, applyMiddleware } from 'redux'
import rootReducer from '../reducers'
import middleWares from '../middlewares'

const __REDUX_DEVTOOLS_EXTENSION__ = global.window
  ? global.window.__REDUX_DEVTOOLS_EXTENSION__
  : null

function initializeStore(initialState) {
  const store = createStore(
    rootReducer,
    initialState,
    __REDUX_DEVTOOLS_EXTENSION__
      ? compose(applyMiddleware(...middleWares), __REDUX_DEVTOOLS_EXTENSION__())
      : applyMiddleware(...middleWares)
  )

  return store
}

export { initializeStore }
