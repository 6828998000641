// @flow
import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import * as Styled from './styled'
import { Popup } from '@editor/common/styled-components/choose-component-template'
import type { TChooseTemplateProps } from './types'
import { TemplatesCategories, TemplatesThumbnails } from './containers'
import { PopupsLoader } from '@editor/common/components/Loaders'
import { EDITOR_BASE } from '@editor/conf/routes'
import { useHistoryWithSearch } from '@hooks'
import { INITIAL_ACTIVE_CATEGORY_INFO } from '@redux/consts'
import {
  hasComponents,
  selectTemplatesData,
  selectApplyTemplateId
} from '@selectors'
import {
  setApplyTemplateId,
  setTemplatesActiveCategoryInfo,
  setTemplateSearchTerm,
  fetchTemplates,
  fetchTemplateCategories,
  resetTemplates
} from '@actions/editor'
import { translate } from '@editor/common/utils/translations'

const ChooseTemplate = ({
  hasComponents,
  templatesData,
  applyTemplateId,
  setApplyTemplateId,
  setTemplateSearchTerm,
  setTemplatesActiveCategoryInfo,
  resetTemplates
}: TChooseTemplateProps) => {
  const [isMobileCategoriesOpen, setMobileCategoriesState] = useState(false)
  const isLoading = templatesData[applyTemplateId]?.isLoading

  const history = useHistoryWithSearch()
  const { url } = useRouteMatch(EDITOR_BASE)
  const {
    params: { page }
  } = useRouteMatch()

  const resetTemplatesFilters = useCallback(() => {
    setTemplatesActiveCategoryInfo(INITIAL_ACTIVE_CATEGORY_INFO)
    setTemplateSearchTerm('')
  }, [setTemplatesActiveCategoryInfo, setTemplateSearchTerm])

  useEffect(
    () => () => {
      setApplyTemplateId(null)

      if (!window.location.pathname.includes('preview-project')) {
        resetTemplatesFilters()
        resetTemplates()
      }
    },
    []
  )

  const onClose = useCallback(() => {
    hasComponents
      ? history.push(`${url}/edit/${page}`)
      : history.push(`${url}/start-creation`)
  }, [url, page, hasComponents])

  return (
    <Popup isOpen={true} onClose={onClose} isMobileFullScreen>
      <PopupsLoader
        isLoading={isLoading}
        loadingText={translate('applying_the_template_label')}
        className="loader"
      />
      <Styled.ChooseTemplateContainer>
        <TemplatesCategories
          isMobileCategoriesOpen={isMobileCategoriesOpen}
          setMobileCategoriesState={setMobileCategoriesState}
        />
        <TemplatesThumbnails
          setMobileCategoriesState={setMobileCategoriesState}
        />
      </Styled.ChooseTemplateContainer>
    </Popup>
  )
}

const mapStateToProps = state => ({
  hasComponents: hasComponents(state),
  templatesData: selectTemplatesData(state),
  applyTemplateId: selectApplyTemplateId(state)
})

const mapDispatchToProps = {
  setTemplatesActiveCategoryInfo,
  setTemplateSearchTerm,
  setApplyTemplateId,
  fetchTemplates,
  fetchTemplateCategories,
  resetTemplates
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseTemplate)
