/* @flow */

import React from 'react'
import './Portfolio1.styles.sass'
import S from './Portfolio1.styled'
import { validation } from './Portfolio1.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import PortfolioLightBox from './Portfolio1.lightbox'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import { withDispatchContext, withEditingContext } from '@contexts'
import './index.css'
import { translate } from '@editor/common/utils/translations'

const COLUMNS_COUNT = 3

const ContainerWithEditingContext = withEditingContext(S.Container)


class Portfolio1 extends React.PureComponent<void, void> {
  state = {
    lightBoxMode: false,
    lightBoxActiveImgIndex: 0
  }

  closePreviewMode = () => this.setState({ lightBoxMode: false })

  openPorfolioPreviewMode = (index: number) =>
    this.setState({ lightBoxMode: true, lightBoxActiveImgIndex: index })

  chunkImagesByColumn = (imageData, colCount) => {
    const chunked = []
    imageData.forEach((img, i) => {
      const colIndex = i % colCount
      chunked[colIndex] = [...(chunked[colIndex] || []), img]
    })
    return chunked
  }

  renderImages = Actions => {
    const {
      data: { portfolioImages }
    } = this.props
    const chunkedImages = this.chunkImagesByColumn(
      portfolioImages,
      COLUMNS_COUNT
    )
    const showRemoveAction =
      portfolioImages.length > validation.portfolioImages.min

    return (
      <div className="portfolio-wrap">
        {chunkedImages.map((columnImages, colIdx) => (
          <div className="portfolio-col" key={colIdx}>
            <Mapper
              data={columnImages}
              render={(pic: string, picIdx: number) => {
                const imgRatio = pic.imgSizes
                  ? (pic.imgSizes.height / pic.imgSizes.width).toFixed(2)
                  : null
                const imageIndex = colIdx + picIdx * COLUMNS_COUNT

                return (
                  <div className="port-img-wrap" key={picIdx}>
                    <ControlsWithModalOpener
                      style={{ width: '100%' }}
                      actions={Actions.imageActions(
                        imageIndex,
                        showRemoveAction,
                        pic.imgUrl
                      )}
                    >
                      <S.PortfolioImgWrapper imgRatio={imgRatio}>
                        <Image
                          as={S.PortfolioImg}
                          defaultImgSrc={pic.imgUrl}
                          alt="Portfolio image"
                          sizes={pic.imgDimensions}
                          asProps={{
                            onClick: () =>
                              this.openPorfolioPreviewMode(imageIndex)
                          }}
                        />
                      </S.PortfolioImgWrapper>
                    </ControlsWithModalOpener>
                  </div>
                )
              }}
            />
          </div>
        ))}
      </div>
    )
  }

  render() {
    const {
      data: {
        title,
        paragraph,
        portfolioImages,
        titleAlignment,
        paragraphAlignment
      },
      componentDispatcher
    } = this.props
    const Actions = ActionGetter(componentDispatcher)
    const { lightBoxMode, lightBoxActiveImgIndex } = this.state
    return (
      <ContainerWithEditingContext>
        <WMContainer className="portfolio-container">
          <Show when={[title]}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              className="WM_GLOBAL_heading"
              maxCount={validation.titleMaxChar}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
          </Show>
          <Show when={[paragraph]}>
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </Show>
          <If
            condition={!lightBoxMode}
            then={() => this.renderImages(Actions)}
            otherwise={() => {
              return (
                <PortfolioLightBox
                  dispatcher={componentDispatcher}
                  portfolioImages={portfolioImages}
                  closePreviewMode={this.closePreviewMode}
                  lightBoxActiveImgIndex={lightBoxActiveImgIndex}
                />
              )
            }}
          />

          <AddButton
            onAdd={Actions.addImage}
            toShow={portfolioImages.length < validation.portfolioImages.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_image_label')}
          </AddButton>
        </WMContainer>
      </ContainerWithEditingContext>
    )
  }
}

const Portfolio1WithContexts = withDispatchContext(Portfolio1)

export default Portfolio1WithContexts
