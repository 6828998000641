import { createSelector } from 'reselect'

export const selectMeta = state => state.meta

export const selectMetaSettings = createSelector(selectMeta, meta => meta.data)

export const selectIsMetaLoading = createSelector(
  selectMeta,
  meta => meta.isLoading
)

export const selectGeneralSettings = createSelector(
  selectMetaSettings,
  meta => {
    const { favicon, socialImage, email, socialImageWidth, socialImageHeight } =
      meta
    return { favicon, socialImage, email, socialImageWidth, socialImageHeight }
  }
)

export const selectWebsiteEmail = createSelector(
  selectGeneralSettings,
  generalSettings => generalSettings.email
)

export const selectMarketingSettings = createSelector(
  selectMetaSettings,
  meta => {
    const {
      facebookPixelCode,
      googleAnalyticsId,
      googleAdSenseId,
      googleTagManagerId,
      yandexMetricaId,
      authDigitalSellersContent
    } = meta
    return {
      facebookPixelCode,
      googleAnalyticsId,
      googleAdSenseId,
      googleTagManagerId,
      yandexMetricaId,
      authDigitalSellersContent
    }
  }
)

export const selectGoogleAnalyticsId = state =>
  selectMarketingSettings(state).googleAnalyticsId

export const selectGoogleTagManagerId = state =>
  selectMarketingSettings(state).googleTagManagerId

export const selectGoogleAdSenseId = state =>
  selectMarketingSettings(state).googleAdSenseId

export const selectAuthDigitalSellersContent = state =>
  selectMarketingSettings(state).authDigitalSellersContent

export const selectFacebookPixelCode = state =>
  selectMarketingSettings(state).facebookPixelCode

export const selectYandexMetricaId = state =>
  selectMarketingSettings(state).yandexMetricaId
