// @flow

import React, { useState, memo, useCallback, useRef } from 'react'
import { FlyOut } from '@editor/common/lib/FlyOutMenu'
import { UserProfile } from '../user-components'
import Content from './Content'
import { GlobalStyles } from './styled'
import { TUserDropdownProps } from './types'

const UserDropdown = ({ isUserAuthorized }: TUserDropdownProps) => {
  const [isOpen, setOpenedState] = useState(false)
  const anchorRef = useRef(null)

  const toggle = useCallback((): void => {
    setOpenedState(!isOpen)
  }, [isOpen])

  const closeDropdown = useCallback((): void => {
    setOpenedState(false)
  }, [])

  const handleRef = useCallback((el: HTMLElement): void => {
    anchorRef.current = el
  }, [])

  return (
    <>
      <GlobalStyles />
      <UserProfile isOpen={isOpen} onClick={toggle} handleRef={handleRef} />
      <FlyOut
        className="user-flyout"
        animationPrefix="user-flyout"
        alignment="right"
        isOpen={isOpen}
        anchorEl={anchorRef.current}
        onClose={closeDropdown}
        backdropDisablingSelectors={[
          '.user-profile-container',
          '.sign-out-warning-modal'
        ]}
      >
        <Content
          closeDropdown={closeDropdown}
          isUserAuthorized={isUserAuthorized}
        />
      </FlyOut>
    </>
  )
}

export default memo(UserDropdown)
