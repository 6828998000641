import styled from 'styled-components'

import Popup from '@renderforest/rf-ui-library/organism/Popup'

export const ConfirmPopup = styled(Popup)`
  & > div {
    padding: 50px 30px;
    max-width: 400px;
    & > div {
      padding: 0;
    }
  }
`

export const PopupHeader = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #252e48;
  text-align: center;
  line-height: 26px;
  margin-bottom: 10px;
  width: 100%;
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 20px;
  }
`

export const PopupFooter = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

export const Content = styled.div`
  padding: 10px 0;
  font-size: 14px;
  color: #252e48;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
`

const Button = styled.button`
  padding: 11px 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  white-space: nowrap;
`

export const CancelButton = styled(Button)`
  color: #3271e6;
  background: #ffffff;
  margin-right: 10px;
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
`

export const ConfirmButton = styled(Button)`
  color: #ffffff;
  background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
    0% no-repeat padding-box;
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #0052e080;
    transition: all 0.2s ease-out;
  }
`
