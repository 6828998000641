/* @flow */

export const validation = {
  slideTitleMaxChar: 50,
  slideParagraphMaxChar: 500,
  slides: {
    min: 1,
    max: 10,
    slideParagraph: { required: true }
  }
}