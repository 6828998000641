/* @flow */

import React, { useState, useCallback, memo } from 'react'

import { ButtonsWrapper, Content, Label } from '../styled'
import {
  PrimaryButton,
  SecondaryButton
} from '@editor/common/styled-components/buttons'
import { MODAL_TYPES } from '../consts'
import type { TSoundcloudModalProps } from './types'
import Popup from '@editor/common/lib/Popup'
import Input from '@website/common/components/Input/Input'
import { secondary } from '@website/common/styles/colors'
import { onEnterPress } from '@website/common/utils'
import { checkUrlLinkValidation, isPlaylist, getFullHeightUrl } from './helper'
import { translate } from '@editor/common/utils/translations'

const SoundcloudModal = ({
  url: urlFromProps,
  handler,
  setModalState,
  fullHeight,
  isSingleTrack
}: TSoundcloudModalProps) => {
  const [url, setUrl] = useState(urlFromProps)
  const [validationMessage, setValidationMessage] = useState(false)

  const onFocus = useCallback(() => {
    if (validationMessage) setValidationMessage('')
  }, [validationMessage])

  const onChange = useCallback(e => {
    setUrl(e.target.value)
  }, [])

  const onRemoveIconClick = useCallback(() => {
    setUrl('')
  }, [])

  const closePopup = useCallback(() => {
    setModalState(MODAL_TYPES.soundcloudModal, { isOpen: false })
  }, [setModalState])

  const onAdd = useCallback(() => {
    if (urlFromProps === url) {
      closePopup()
      return
    }

    const isValid = !!url && checkUrlLinkValidation(url)

    if (!isValid) {
      setValidationMessage('Invalid soundcloud url')
      return
    }

    if (isSingleTrack && isPlaylist(url)) {
      setValidationMessage(`You can't use playlist link for this component`)
      return
    }

    if (!isSingleTrack && !isPlaylist(url)) {
      setValidationMessage(`You can't use track link for this component`)
      return
    }

    if (!validationMessage) {
      const musicUrl = fullHeight ? getFullHeightUrl(url) : url
      handler(musicUrl)
      closePopup()
    }
  }, [url, validationMessage, fullHeight, isSingleTrack, handler, closePopup])

  return (
    <Popup
      isOpen
      onClose={closePopup}
      title={translate('add_soundcloud_label')}
    >
      <Content>
        <Label>{translate('paste_url_here_label')}</Label>
        <Input
          value={url || ''}
          placeholder="http(s)://example.com"
          placeholderColor={secondary.SemiLight}
          borderColor={secondary.Light}
          borderHoverColor={secondary.SemiLight}
          error={validationMessage}
          onChange={onChange}
          onFocus={onFocus}
          onKeyPress={onEnterPress(onAdd)}
          onRemoveIconClick={onRemoveIconClick}
        />
        <ButtonsWrapper>
          <SecondaryButton onClick={closePopup}>
            {translate('cancel_label')}
          </SecondaryButton>
          <PrimaryButton onClick={onAdd}>
            {translate('add_label', true)}
          </PrimaryButton>
        </ButtonsWrapper>
      </Content>
    </Popup>
  )
}

export default memo(SoundcloudModal)
