/* @flow */
import styled from 'styled-components'
import THEME from './Header23.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  ${getBackgroundStyles(THEME)};
  min-height: 768px;
  padding: ${({ isEditing }) => (isEditing ? '0 0 30px 0' : '0 0 80px 0')};
  @media only screen and (max-width: 650px) {
    min-height: 650px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  ${({ isEditing }) => (isEditing ? 'margin-top: 10px;' : null)}
  position: relative;
  padding-top: 0;
  z-index: 2;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
  .socials-block {
    padding: 8vw 0 0 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    .link {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      margin: 5px;
      background: ${({ theme }) => theme.palette.accentWhite};
      color: ${({ theme }) => theme.palette.primaryDark};
      border-radius: ${getStyleForProp('iconRadius')};
    }
    svg {
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
  @media only screen and (max-width: 768px) {
    ${({ isEditing }) =>
      isEditing ? `padding: 40px 40px 0;` : `padding: 0 40px;`}
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const HeaderRectWrap = styled.div`
  width: 100%;
  position: absolute;
  top: 44%;
  left: -55%;
  transform: translateY(-50%);
  z-index: 1;
  .rect {
    fill: ${getStyleForProp('layerBackground')};
  }
  svg {
    width: 120%;
    @media only screen and (max-width: 1280px) and (min-width: 900px) {
      width: 130%;
    }
    @media only screen and (max-width: 950px) {
      width: 160%;
    }
    @media only screen and (max-width: 540px) {
      width: 190%;
    }
    @media only screen and (max-width: 420px) {
      width: 290%;
    }
  }
  @media only screen and (max-width: 1280px) and (min-width: 950px) {
    left: -50%;
  }
  @media only screen and (max-width: 950px) {
    top: 12%;
    left: -55%;
  }
  @media only screen and (max-width: 540px) {
    left: -80%;
  }
  @media only screen and (max-width: 420px) {
    left: -100%;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 450px;
  margin-top: ${({ topOffset }) => `${topOffset + 40}px`};
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 650px) {
    margin-top: ${({ topOffset }) => `${topOffset + 20}px`};
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    h1,
    p {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  text-align: inherit;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  text-align: inherit;
  line-height: 1.4;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderRectWrap,
  HeaderContent,
  Title,
  Description
}
