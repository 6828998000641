/* @flow */
import styled from 'styled-components'
import THEME from './CTA9.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  font-size: 0;
  padding: ${({ isEditing }) => (isEditing ? '150px 0 0 0' : '0')};
  @media only screen and (max-width: 1100px) and (min-width: 992px) {
    padding: ${({ isEditing }) => (isEditing ? '100px 0 50px 0' : '0')};
  }
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 768px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 0 0' : '0')};
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const ImagesContainer = styled.div`
  width: 30%;
  flex-grow: 1;
  @media only screen and (max-width: 1023px) {
    width: 100%;
  }
`

const TextContent = styled.div`
  width: 95%;
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 1023px) {
    width: 100%;
    max-width: initial;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
  }
`

const TextContainer = styled.div`
  text-align: center;
  width: 30%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px;
  @media only screen and (max-width: 1100px) {
    padding: 10px;
  }
  @media only screen and (max-width: 1023px) {
    width: 100%;
    padding: 60px 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

export default {
  StyledContainer,
  TextContainer,
  TextContent,
  Title,
  Paragraph,
  Img,
  ImagesContainer,
  ControlContainer
}
