// @flow
import React, {
  cloneElement,
  useCallback,
  useEffect,
  useState,
  useRef,
  memo
} from 'react'

import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_right.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'

import * as Styled from './styled'
import type { TControlsBarProps } from './types'

const ControlsBar = ({
  children,
  IconComp,
  className,
  tooltipText: tooltipTextFromProp,
  isExpanded,
  showControlIcon = true,
  isDefaultPrevented,
  isTooltipHidden,
  setExpandedState
}: TControlsBarProps) => {
  const [tooltipText, setTooltipText] = useState(tooltipTextFromProp)
  const [isTransitionEnded, setTransitionEnded] = useState(true)
  const [width, setWidth] = useState(0)
  const childrenRef = useRef(null)
  const firstRenderRef = useRef(true)

  useEffect(() => {
    const { current: el } = childrenRef

    if (!el || el.offsetWidth === width) return

    setWidth(el.offsetWidth)
  })

  useEffect(() => {
    isTooltipHidden ? setTooltipText('') : setTooltipText(tooltipTextFromProp)
  }, [isTooltipHidden])

  useEffect(() => {
    if (isExpanded) setTooltipText('')
    if (firstRenderRef.current) {
      firstRenderRef.current = false
    } else {
      setTransitionEnded(false)
    }
  }, [isExpanded, firstRenderRef])

  const expandControlsBar = useCallback(() => {
    setExpandedState(true)
  }, [])

  const collapseControlsBar = useCallback(e => {
    e.stopPropagation()
    setExpandedState(false)
  }, [])

  const onTransitionEnd = useCallback(
    e => {
      if (e.propertyName === 'width') {
        setTransitionEnded(true)
        !isExpanded && setTooltipText(tooltipTextFromProp)
      }
    },
    [isExpanded]
  )

  const onControlsBarClick = useCallback(() => {
    if (isDefaultPrevented) {
      return
    }

    expandControlsBar()
  }, [isDefaultPrevented, expandControlsBar])

  return (
    <Styled.ControlsBarWrapper className={className}>
      <TooltipWrapper text={tooltipText} position="bottom" color="#252E48">
        <Styled.ControlsBar
          width={width}
          expandedStaticWidth={showControlIcon ? 80 : 40}
          isExpanded={isExpanded}
          isTransitionEnded={isTransitionEnded}
          onClick={onControlsBarClick}
          onTransitionEnd={onTransitionEnd}
        >
          <Styled.ControlsContent>
            {showControlIcon ? <IconComp className="control-icon" /> : null}
            {children &&
              cloneElement(children, {
                ref: childrenRef
              })}
            {isExpanded ? (
              <ArrowIcon className="arrow-icon" onClick={collapseControlsBar} />
            ) : null}
          </Styled.ControlsContent>
        </Styled.ControlsBar>
      </TooltipWrapper>
    </Styled.ControlsBarWrapper>
  )
}

export default memo(ControlsBar)
