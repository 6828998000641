export const BUTTON_INITIAL_STATE = [
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]

export const validation = {
  customCode: { required: true },
  headingMaxChar: 120,
  paragraphMaxChar: 300,
  buttons: {
    min: 0,
    max: 2
  }
}

