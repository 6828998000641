// @flow
/* eslint-disable max-statements */

import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'

import LinkTextInput from './LinkTextInput'
import LinkTypeDropdown from './LinkTypeDropdown'
import PageChooserDropdown from '@editor/common/components/PageChooserDropdown'
import ComponentsDropdown from './ComponentsDropdown/ComponentsDropdown'
import ExternalUrlInput from './ExternalUrlInput'
import PhoneNumberInput from './PhoneNumberInput'
import FormActionButtons from './FormActionButtons'
import { getCountriesList } from '@actions/editor'
import { translate } from '@editor/common/utils/translations'

import { isValidUrl } from '@website/common/utils'
import { addAnalyticsEvent } from '@editor/common/utils'
import {
  getLinkType,
  getPageFromLink,
  getComponentFromLink,
  checkPageExisting
} from './utils'
import {
  LINK_TYPES,
  PHONE_NUMBER_ERROR,
  SPACE_REGEXP,
  PHONE_INPUT_LABEL,
  PAGE_CHOOSER_DROPDOWN_LABEL
} from './consts'
import { useSelector } from 'react-redux'
import { selectAllPagesData } from '@selectors'
import * as Styled from './styled'
import { PAGE_BASE } from '@editor/conf/routes'

const EMPTY_MESSAGE_ERROR = translate('this_field_cannot_be_empty_label')
const EXTERNAL_LINK_ERROR = translate('enter_valid_url_label')

const mapDispatchToProps = {
  getCountriesList
}

const Form = ({
  text,
  link: linkFromProps,
  limitText,
  isLinkTextInputDisabled,
  areSpacesAllowed = false,
  handler,
  onCancel,
  getCountriesList
}) => {
  const {
    params: { page: pageFromRoute = '' }
  } = useRouteMatch(PAGE_BASE)

  const pages = useSelector(selectAllPagesData)
  const pageFromLink = getPageFromLink(linkFromProps)
  const isPageExist = checkPageExisting(pages, pageFromLink)
  const componentFromLink = isPageExist
    ? getComponentFromLink(linkFromProps)
    : ''

  const defaultPage = pageFromRoute === 'home' ? '' : pageFromRoute
  const initialPage =
    isPageExist && pageFromLink ? pageFromLink : `/${defaultPage}`
  const initialLinkType = getLinkType(linkFromProps)
  const initialPhoneNumber =
    initialLinkType === LINK_TYPES.PHONE_CALL
      ? linkFromProps.replace('tel:', '')
      : ''

  const [linkText, setLinkText] = useState(text)
  const [externalLink, setExternalLink] = useState(linkFromProps)
  const [phoneNumber, setPhoneNumber] = useState(initialPhoneNumber)
  const [linkType, setLinkType] = useState(initialLinkType)
  const [page, setPage] = useState(initialPage)
  const [component, setComponent] = useState(componentFromLink)

  const [linkTextError, setLinkTextError] = useState('')
  const [externalLinkError, setExternalLinkError] = useState('')
  const [phoneNumberError, setPhoneNumberError] = useState('')

  const isGoToPage = linkType === LINK_TYPES.TO_PAGE
  const isExternal = linkType === LINK_TYPES.EXTERNAL
  const isPhoneCall = linkType === LINK_TYPES.PHONE_CALL
  const isScrollToComponent = linkType === LINK_TYPES.TO_COMPONENT

  useEffect(() => {
    getCountriesList()
  }, [])

  const onRemoveIconClick = useCallback(() => {
    setPhoneNumber('')
  }, [])

  const handleLinkTextChange = useCallback(value => {
    setLinkTextError('')
    setLinkText(value)
  }, [])

  const handleLinkTypeChange = useCallback(
    linkType => {
      setLinkType(linkType)
      setPage(initialPage)
      setComponent('')
      setExternalLink('')
    },
    [initialPage]
  )

  const handlePageChange = useCallback(page => {
    setPage(page)
    setComponent('')
  }, [])

  const handleExternalLinkChange = useCallback(e => {
    setExternalLinkError('')
    setExternalLink(e.target.value)
  }, [])

  const handlePhoneNumberChange = useCallback(number => {
    setPhoneNumberError('')
    setPhoneNumber(number)
  }, [])

  const handleSave = useCallback(() => {
    let isFormValid = true
    let link = ''

    if (!linkText) {
      setLinkTextError(EMPTY_MESSAGE_ERROR)
      isFormValid = false
    }

    if (!areSpacesAllowed && SPACE_REGEXP.test(linkText)) {
      setLinkTextError(EMPTY_MESSAGE_ERROR)
      isFormValid = false
    }

    if (isExternal) {
      link = externalLink
      const isValidExternalLink = isValidUrl(externalLink)

      if (!isValidExternalLink) {
        isFormValid = false
        setExternalLinkError(EXTERNAL_LINK_ERROR)
      }
    } else {
      const toComp = component ? `#${component}` : ''
      link = `${page}${toComp}`
    }

    if (isPhoneCall) {
      if (phoneNumber) {
        link = `tel:${phoneNumber}`
      } else {
        isFormValid = false
        setPhoneNumberError(PHONE_NUMBER_ERROR)
      }
    }

    if (isFormValid) {
      isPhoneCall && addAnalyticsEvent('SiteMaker Links', 'Phone Call Link')
      handler({ text: linkText.trim(), link })
      onCancel()
    }
  }, [
    linkText,
    isGoToPage,
    isExternal,
    isPhoneCall,
    isScrollToComponent,
    page,
    component,
    externalLink,
    phoneNumber
  ])

  return (
    <>
      <Styled.GlobalStyles />
      <LinkTextInput
        value={linkText}
        error={linkTextError}
        isDisabled={isLinkTextInputDisabled}
        onChange={handleLinkTextChange}
        hasLimit={limitText}
      />
      <LinkTypeDropdown value={linkType} onChange={handleLinkTypeChange} />
      {(isGoToPage || isScrollToComponent) && (
        <Styled.InputContainer>
          <PageChooserDropdown
            value={page}
            label={PAGE_CHOOSER_DROPDOWN_LABEL}
            onChange={handlePageChange}
          />
        </Styled.InputContainer>
      )}
      {isScrollToComponent && (
        <ComponentsDropdown
          selectedPage={page}
          value={component}
          onChange={setComponent}
        />
      )}
      {isPhoneCall && (
        <PhoneNumberInput
          value={phoneNumber}
          onRemoveIconClick={onRemoveIconClick}
          onChange={handlePhoneNumberChange}
          inputLabel={PHONE_INPUT_LABEL}
          error={phoneNumberError}
        />
      )}
      {isExternal && (
        <ExternalUrlInput
          value={externalLink}
          error={externalLinkError}
          onChange={handleExternalLinkChange}
        />
      )}
      <FormActionButtons onCancel={onCancel} onSave={handleSave} />
    </>
  )
}

export default connect(null, mapDispatchToProps)(Form)
