/* @flow */
import React, { memo } from 'react'

const AnimatedWave = () => (
  <svg
    className="waves"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28"
    preserveAspectRatio="none"
    shapeRendering="auto"
  >
    <defs>
      <path
        id="gentle-wave"
        d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
      />
    </defs>
    <g className="parallax">
      <use href="#gentle-wave" x="20" y="0" />
      <use href="#gentle-wave" x="75" y="1" />
      <use href="#gentle-wave" x="60" y="4" />
      <use href="#gentle-wave" x="95" y="3" />
    </g>
  </svg>
)

export default memo(AnimatedWave)
