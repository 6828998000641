import styled, { css } from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'
import { hexToRgbA } from '@website/common/utils'
import LOOK_DATA from '@website/conf/look'

const MOBILE__MEDIA_MAX_SIZE = '630px'
const FOOTER_HEIGHT = 70

const getStyleForProp = propName => ({ theme }) => {
  const { look, palette } = theme
  const lookData = LOOK_DATA[look]

  const styleGetter = lookData[propName]
  return styleGetter ? styleGetter(palette, 'LIGHT') : ''
}

export const PopupContent = styled.div`
  width: 550px;
  box-sizing: border-box;
  background-color: #ffffff;
  height: calc(100% - ${FOOTER_HEIGHT}px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  ${({ disabled }) =>
    disabled
      ? css`
          user-select: none;
          pointer-events: none;
        `
      : ''}
  @media only screen and (max-width: ${MOBILE__MEDIA_MAX_SIZE}) {
    border-radius: 0;
    width: 100%;
  }
`

export const Title = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  padding-bottom: 20px;
  text-align: center;

  @media only screen and (max-width: ${MOBILE__MEDIA_MAX_SIZE}) {
    text-align: left;
  }
`

export const Description = styled.p`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  line-height: 18px;
  padding-bottom: 13px;
`

export const Block = styled.div`
  &:not(:last-child) {
    border-bottom: 1px solid ${secondary.ExtraLight};
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 0 10px;
`

export const BlockTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.palette.tertiaryDark};
`

export const PopupFooter = styled.div`
  width: 100%;
  height: 90px;
  padding: 20px 45px;
  box-sizing: border-box;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-shrink: 0;
  z-index: 1;
  flex-wrap: wrap;
  align-content: space-around;

  @media only screen and (max-width: ${MOBILE__MEDIA_MAX_SIZE}) {
    justify-content: center;
    align-items: center;
    border-radius: 0;
  }
`

export const Button = styled.div`
  height: 50px;
  cursor: pointer;
  padding: 0 40px;
  display: flex;
  align-items: center;
  border-radius: ${getStyleForProp('buttonRadius')};
  ${getStyleForProp('buttonBackground')}
  box-sizing: border-box;
  text-align: center;
  font-size: 14px;
`

export const Switcher = styled.div`
  height: 24px;
  width: 42px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  box-sizing: border-box;
  border-radius: 12px;

  & > div {
    border-radius: 10px;
    width: 16px;
    height: 16px;
    background-color: white;
    transition: transform 300ms ease-out;

    ${({ isChecked }) =>
      isChecked
        ? css`
            transform: translateX(18px);
          `
        : css`
            transform: translateX(0);
          `}
  }

  background-color: ${({ isChecked, theme }) =>
    isChecked
      ? theme.palette.tertiaryDark
      : hexToRgbA(theme.palette.tertiaryDark, 0.6)};
`

export const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  height: calc(100% - 110px);
  padding: 0 40px 30px;
  @media only screen and (max-width: ${MOBILE__MEDIA_MAX_SIZE}) {
    padding: 0 20px;
  }
`

export const TitleContent = styled.div`
  padding: 30px 40px 0;
  @media only screen and (max-width: ${MOBILE__MEDIA_MAX_SIZE}) {
    padding: 30px 20px 0;
  }
`

export const PopupContainer = styled.div`
  background: ${white};
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @media only screen and (max-width: ${MOBILE__MEDIA_MAX_SIZE}) {
    border-radius: 0;
  }
}
`
