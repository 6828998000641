/* @flow */

export const validation = {
  titleMaxChar: 60,
  paragraphMaxChar: 200,
  blockTitleMaxChar: 30,
  infoTitleMaxChar: 150,
  title: { required: true },
  infoTitle: { required: true }
}
