//@flow
import React, { useCallback, useRef } from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import { TProps } from './types'
import ListItem from './ListItem'
import Scrollable from '@editor/common/components/Scrollable'
import { translate } from '@editor/common/utils/translations'
import useInfiniteScroll from '@hooks/useInfiniteScroll'
import {
  fetchSemrushTablePagination,
  openOrCloseSemrushKeywordsPopup
} from '@actions'
import { selectSemrushKeywordsData } from '@selectors'
import { RippleLoading } from '@editor/common/components/Loaders'

const TopRelatedKeywords = ({
  keywordsData,
  fetchSemrushTablePagination,
  openOrCloseSemrushKeywordsPopup
}: TProps) => {
  const listRef = useRef(null)

  const {
    payload: { rows = [] } = {},
    isPaginationLoading,
    isOvered
  } = keywordsData

  useInfiniteScroll(listRef, () => {
    if (!isPaginationLoading && !isOvered) {
      fetchSemrushTablePagination()
    }
  })

  const openSemrushKeywordsPopup = useCallback(() => {
    openOrCloseSemrushKeywordsPopup(true)
  }, [])

  return rows.length ? (
    <Styled.Container>
      <Styled.Title>{translate('top_related_keywords_label')}</Styled.Title>
      <Scrollable childrenRef={listRef}>
        <Styled.List ref={listRef}>
          {rows.map((row, i) => (
            <ListItem key={i} data={row} />
          ))}
        </Styled.List>
      </Scrollable>
      <Styled.MoreDetails>
        <Styled.MoreDetailsButton onClick={openSemrushKeywordsPopup}>
          {translate('more_details_label')}
        </Styled.MoreDetailsButton>
      </Styled.MoreDetails>
      <Styled.LoaderWrapper isLoading={isPaginationLoading}>
        <RippleLoading color="#3270e6" />
      </Styled.LoaderWrapper>
    </Styled.Container>
  ) : (
    <Styled.NoResults>
      {translate('semrush_keywords_noresults_label')}
    </Styled.NoResults>
  )
}

const mapStateToProps = state => ({
  keywordsData: selectSemrushKeywordsData(state)
})

const mapDispatchToProps = {
  fetchSemrushTablePagination,
  openOrCloseSemrushKeywordsPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(TopRelatedKeywords)
