// @flow

import React, { useState, useCallback, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import Header from '@editor/common/components/Header'
import SemrushSidebarButton from '@editor/common/components/SemrushSidebarButton'
import SemrushSidebar from '@editor/common/components/SemrushSidebar'
import Popups from './Popups'
import Containers from './containers'
import { projectContainerClassName } from '@website/conf/consts'
import {
  openLoginPopup,
  changeActiveSidebarItem,
  setMainSidebarExpandedState,
  setSemrushSidebarVisibilityState
} from '@actions/editor'
import {
  selectIsUserAuthorized,
  selectSidebarActiveItem,
  selectIsMainSidebarExpanded,
  selectIsProjectSyncing,
  selectIsWebsiteLanguageChanging,
  selectWebsiteActiveLanguageCode,
  selectSemrushSidebarPinnedState,
  selectSemrushSidebarVisibilityState,
  selectEventCreatingState,
  selectLanguageTranslationState
} from '@selectors'
import { ZoomOutContext } from '@contexts'
import Sidebar from '@editor/common/components/Sidebar'
import {
  CircleProgressLoader,
  SyncingLoader
} from '@editor/common/components/Loaders'
import { useHistoryWithSearch } from '@hooks'
import { useResponsive } from '@shared/hooks'
import { useSemrushLoginWindow, useSemrushInitialPinned } from '@hooks/semrush'

import * as Styled from './styled'
import MainSidebarContent from './components/MainSidebarContent'
import SubSidebarContent from '@containers/edit/components/SubSidebarContent'
import {
  WIDGETS,
  SETTINGS,
  INVITE,
  COLORS,
  DEFAULT_EXCEPTED_BACKDROP_SELECTORS,
  COLOR_MODE_CONTROL_SELECTOR
} from './consts'
import MobileToolbar from '@editor/common/components/MobileToolbar'
import { replaceItem } from '@editor/common/utils'
import { getSemrushDependedScreenBreakpoint } from '@editor/common/utils/styled'
import { TRANSLATING } from '@editor/conf/consts'
import { translate } from '@editor/common/utils/translations'

const Editor = ({
  isMainSidebarExpanded,
  isProjectSyncing,
  isWebsiteLanguageChanging,
  websiteActiveLanguageCodeFromStore,
  sidebarActiveItem,
  isSemrushSidebarPinned,
  isSemrushSidebarVisible,
  changeActiveSidebarItem,
  setMainSidebarExpandedState,
  setSemrushSidebarVisibilityState,
  isEventCreating
}) => {
  useSemrushLoginWindow()
  useSemrushInitialPinned()
  const { pathname } = useLocation()
  const history = useHistoryWithSearch()
  const { siteId, lang } = useParams()
  const semrushSidebarBreakpoint = getSemrushDependedScreenBreakpoint(
    800,
    isSemrushSidebarVisible
  )

  const languageTranslationState = useSelector(state =>
    selectLanguageTranslationState(state, lang)
  )

  const isMobile = useResponsive(semrushSidebarBreakpoint)
  const isSemrushSidebarHidden = useResponsive(1024)

  const [isSubSidebarOpened, setSubSidebarOpenedState] = useState(false)

  const isSemrushSidebarOpenerButtonVisible =
    isSemrushSidebarPinned && !isSemrushSidebarVisible
  const isLinkOrShareItem = [WIDGETS, SETTINGS, INVITE].some(
    item => item === sidebarActiveItem
  )

  const onSubSidebarCloseEnd = useCallback(() => {
    !isLinkOrShareItem && changeActiveSidebarItem('')
  }, [isLinkOrShareItem])

  const toggleMainSidebar = useCallback(() => {
    setMainSidebarExpandedState(!isMainSidebarExpanded)
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 300)
  }, [isMainSidebarExpanded, setMainSidebarExpandedState])

  const openSemrushSidebar = useCallback(() => {
    setSemrushSidebarVisibilityState(true)
  }, [])

  const exceptedBackdropSelectors =
    sidebarActiveItem === COLORS
      ? [...DEFAULT_EXCEPTED_BACKDROP_SELECTORS, COLOR_MODE_CONTROL_SELECTOR]
      : DEFAULT_EXCEPTED_BACKDROP_SELECTORS

  const isLanguageTranslating = languageTranslationState === TRANSLATING

  // in case when lang changed in store first
  useEffect(() => {
    if (!siteId || !websiteActiveLanguageCodeFromStore) {
      return
    }

    const url = replaceItem(
      pathname.split('/'),
      2,
      websiteActiveLanguageCodeFromStore
    ).join('/')

    history.replace(url)
  }, [websiteActiveLanguageCodeFromStore])

  return (
    <ThemeProvider theme={{ isSemrushSidebarVisible }}>
      <Styled.MainContainer>
        <Styled.WMContainer>
          <Header isLogoExpanded={isMainSidebarExpanded} />
          <Styled.WMMainSection>
            {isMobile ? (
              <MobileToolbar toolbarContent={<SubSidebarContent />} />
            ) : null}
            {!isMobile && (
              <Sidebar
                exceptedBackdropSelectors={exceptedBackdropSelectors}
                isMainSidebarExpanded={isMainSidebarExpanded}
                isSubSidebarOpened={isSubSidebarOpened}
                setSubSidebarOpenedState={setSubSidebarOpenedState}
                toggleMainSidebar={toggleMainSidebar}
                onSubSidebarCloseEnd={onSubSidebarCloseEnd}
                mainSidebarContent={
                  <MainSidebarContent
                    isMainSidebarExpanded={isMainSidebarExpanded}
                    isSubSidebarOpened={isSubSidebarOpened}
                    toggleMainSidebar={toggleMainSidebar}
                    setSubSidebarOpenedState={setSubSidebarOpenedState}
                  />
                }
                subSidebarContent={<SubSidebarContent />}
              />
            )}
            <Styled.EditorContainer className={projectContainerClassName}>
              <ZoomOutContext.Provider
                value={{
                  isMainSidebarExpanded,
                  isSubSidebarOpened,
                  isSemrushSidebarVisible
                }}
              >
                <Containers />
              </ZoomOutContext.Provider>
              <Popups />
            </Styled.EditorContainer>
            {!isSemrushSidebarHidden && isSemrushSidebarOpenerButtonVisible ? (
              <SemrushSidebarButton onClick={openSemrushSidebar} />
            ) : null}
          </Styled.WMMainSection>
          {isProjectSyncing ? <SyncingLoader /> : null}
          {isWebsiteLanguageChanging ||
          isEventCreating ||
          isLanguageTranslating ? (
            <CircleProgressLoader
              text={
                isLanguageTranslating
                  ? translate('we_are_translating_your_content_message')
                  : isWebsiteLanguageChanging
                  ? translate('fetching_content_message')
                  : ''
              }
            />
          ) : null}
        </Styled.WMContainer>
        {!isSemrushSidebarHidden ? <SemrushSidebar /> : null}
      </Styled.MainContainer>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: TAppState) => ({
  isUserAuthorized: selectIsUserAuthorized(state),
  sidebarActiveItem: selectSidebarActiveItem(state),
  isMainSidebarExpanded: selectIsMainSidebarExpanded(state),
  isProjectSyncing: selectIsProjectSyncing(state),
  isWebsiteLanguageChanging: selectIsWebsiteLanguageChanging(state),
  websiteActiveLanguageCodeFromStore: selectWebsiteActiveLanguageCode(state),
  isSemrushSidebarPinned: selectSemrushSidebarPinnedState(state),
  isSemrushSidebarVisible: selectSemrushSidebarVisibilityState(state),
  isEventCreating: selectEventCreatingState(state)
})

export default connect(mapStateToProps, {
  openLoginPopup,
  changeActiveSidebarItem,
  setMainSidebarExpandedState,
  setSemrushSidebarVisibilityState
})(Editor)
