/* @flow */
import produce from 'immer'
import { validation } from './Feature2.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        if (state.featureParts.length === validation.featureParts.min) {
          return
        }
        draft.featureParts.splice(idx, 1)
        break
      }
      case 'ADD_BLOCK':
        if (state.featureParts.length === validation.featureParts.max) {
          return
        }
        draft.featureParts.push(initialState.featureParts[0])
        break
      case 'CHANGE_BLOCK_TITLE':
        const { newTitle, idx: titleIdx } = action.value
        draft.featureParts[titleIdx].blockTitle = newTitle
        break
      case 'CHANGE_BLOCK_CONTENT': {
        const { newContent, idx } = action.value
        draft.featureParts[idx].blockDescription = newContent
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.featureParts[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.featureParts[idx].blockParagraphAlignment = alignment
        break
      }
      case 'ADD_LINK':
        const { idx } = action.value
        draft.featureParts[idx].additionalUrl =
          initialState.featureParts[0].additionalUrl
        draft.featureParts[idx].additionalText =
          initialState.featureParts[0].additionalText
        break
      case 'REMOVE_LINK': {
        const { idx } = action.value
        draft.featureParts[idx].additionalUrl = ''
        draft.featureParts[idx].additionalText = ''
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkIdx, linkData } = action.value
        draft.featureParts[linkIdx].additionalUrl = linkData.link
        draft.featureParts[linkIdx].additionalText = linkData.text
        break
      case 'CHANGE_ICON':
        const { idx: idx_, iconName } = action.value
        draft.featureParts[idx_].icon = iconName
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
