import React, { memo } from 'react'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'

// Depracated

export const WithTooltip = memo(({ children, isDisabled, text }) =>
  !isDisabled ? (
    <TooltipWrapper color="#363F5A" position="top" text={text} delay={500}>
      {children}
    </TooltipWrapper>
  ) : (
    children
  )
)
