import { selectUserWithLoadingStatus } from '@selectors'
import { showMaintenanceModal } from '@actions/editor'
import { API_STATUS_CODES } from '@editor/conf/consts'
import { isOpenedWithHash } from '@editor/common/utils'

export default ({ dispatch, getState }) => next => action => {
  const result = next(action)
  if (action.type === 'API_ERROR') {
    const { status, message } = action.value
    switch (status) {
      case API_STATUS_CODES.WRONG_AUTHORIZATION:
        if (isOpenedWithHash()) {
          dispatch({
            type: 'OPEN_CONTRIBUTOR_SIGNOUT_POPUP',
            value: message
          })
        }
        break
      case API_STATUS_CODES.AUTHORIZATION:
        const { isLoading, data } = selectUserWithLoadingStatus(getState())
        if (isOpenedWithHash()) {
          window.location.reload()
        } else {
          if (!isLoading && data) {
            dispatch({ type: 'REMOVE_USER_DATA' })
            dispatch({ type: 'OPEN_LOGIN_POPUP' })
          }
        }
        break
      case API_STATUS_CODES.MAINTENANCE:
        dispatch(showMaintenanceModal())
        break
    }
  }
  return result
}
