/* @flow */
import React, { memo, forwardRef } from 'react'
import S from './RSVPInput.styled'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import type { TRSVPInput } from './RSVPInput.type'

const RSVPInput = forwardRef(
  (
    {
      type = 'text',
      value,
      label,
      className,
      errorMessage,
      isRequired,
      onChange,
      onBlur
    }: TRSVPInput,
    ref
  ) => {
    const handleChange = e => {
      const { value } = e.target

      onChange && onChange(value)
    }

    return (
      <S.InputContainer
        className={`${className || ''} ${!!errorMessage ? 'error-state' : ''}`}
      >
        <S.LabelWrapper>
          <S.Label className="WM_GLOBAL_paragraph14" htmlFor="name">
            {label}{' '}
            {isRequired && label ? (
              <S.Asterisk className="asterisk">*</S.Asterisk>
            ) : null}
          </S.Label>
        </S.LabelWrapper>
        <S.Input
          ref={ref}
          type={type}
          value={value}
          className="WM_GLOBAL_secondary-font"
          onBlur={onBlur}
          onChange={handleChange}
        />
        {!!errorMessage ? (
          <S.ErrorMessage className="WM_GLOBAL_secondary-font">
            <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
            {errorMessage}
          </S.ErrorMessage>
        ) : null}
      </S.InputContainer>
    )
  }
)

export default memo(RSVPInput)
