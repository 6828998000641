import styled from 'styled-components'
import {
  LeftButton,
  RightButton,
  ThumbnailButtonsWrapper
} from '@editor/common/styled-components/choose-component-template'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const buttonCustomWidth1 = {
  previewBtnWidth: '160px',
  applyBtnWidth: '140px'
}

const buttonCustomWidth2 = {
  previewBtnWidth: '140px',
  applyBtnWidth: '110px'
}

const prevBtnCustomStyle = {
  prevBtnLeft: '38px',
  prevSvgLeft: '7px',
  prevBtnRight: '',
  prevSvgRight: ''
}

const stylesObj = {
  ru: {
    ...buttonCustomWidth1,
    ...prevBtnCustomStyle
  },
  de: {
    previewBtnWidth: '120px',
    applyBtnWidth: '130px',
    ...prevBtnCustomStyle
  },
  es: {
    ...buttonCustomWidth2,
    ...prevBtnCustomStyle
  },
  fr: {
    previewBtnWidth: '110px',
    applyBtnWidth: '130px',
    ...prevBtnCustomStyle
  },
  tr: {
    previewBtnWidth: '100px',
    applyBtnWidth: '110px',
    ...prevBtnCustomStyle
  },
  jp: {
    ...buttonCustomWidth2,
    ...prevBtnCustomStyle
  },
  pt: {
    previewBtnWidth: '150px',
    applyBtnWidth: '100px',
    ...prevBtnCustomStyle
  },
  default: {
    previewBtnWidth: '110px',
    applyBtnWidth: '94px',
    prevBtnRight: '38px',
    prevSvgRight: '7px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const TemplateThumbnail = styled.div`
  position: relative;
  background-color: #ffffff;
  border: 1px solid #c1c9e0;
  box-shadow: 0 3px 12px #387dff33;
  border-radius: 10px;
  &:hover ${ThumbnailButtonsWrapper} {
    visibility: visible;
    opacity: 1;
    @media only screen and (max-width: 768px) {
      transition-delay: 100ms;
    }
  }
`

export const ThumbnailImageWrapper = styled.div`
  position: relative;
  img {
    border-radius: 9px 9px 0 0;
  }
`

export const Name = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #363f5a;
  background-color: #ffffff;
  padding: 12px 20px;
  border-radius: 0 0 10px 10px;
`

export const PreviewButton = styled(LeftButton)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  svg {
    right: ${getStylesByLang('prevSvgRight')};
    left: ${getStylesByLang('prevSvgLeft')};
  }
  p {
    right: ${getStylesByLang('prevBtnRight')};
    left: ${getStylesByLang('prevBtnLeft')};
  }
  &:hover {
    width: ${({ isBtnShorten }) =>
      isBtnShorten ? '40px' : getStylesByLang('previewBtnWidth')};
  }
  @media only screen and (max-width: 768px) {
    width: ${getStylesByLang('previewBtnWidth')};
  }
`

export const ApplyButton = styled(RightButton)`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  svg {
    left: 7px;
  }
  p {
    left: 38px;
  }

  &:hover {
    width: ${({ isBtnShorten }) =>
      isBtnShorten ? '40px' : getStylesByLang('applyBtnWidth')};
  }
  @media only screen and (max-width: 768px) {
    width: ${getStylesByLang('applyBtnWidth')};
  }
`
