/* @flow */
import React from 'react'
import {
  iconActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeMockupColor,
  changeTitleAlignment,
  changeParagraphAlignment,
  makeMockupLight,
  makeMockupDark
} from '@website/components/CommonActions'

import MockupColorSwitcher from '@website/common/components/MockupColorSwitcher'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatch => {
  const changeListItem = (newItem: string, idx: number) =>
    dispatch({
      type: 'CHANGE_LIST_ITEM',
      value: { newItem, idx }
    })

  const removeListItem = (idx: number) => ({
    type: 'REMOVE_LIST_ITEM',
    value: idx
  })

  const _changeTitle = (title: string) => dispatch(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatch(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const _mockupActions = ({ mockupColor, imageUrl }) => [
    ...cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'header8MockupSizes',
      showRemove: false,
      imageUrl
    }),
    {
      renderer: () => (
        <MockupColorSwitcher
          onChoose={() => dispatch(changeMockupColor())}
          onFirstOptionSelect={() => dispatch(makeMockupLight())}
          onSecondOptionSelect={() => dispatch(makeMockupDark())}
          isSecondOptionSelected={mockupColor === 'dark'}
        />
      )
    }
  ]

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeListItem,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    iconActions: iconActionMaker(dispatch),
    addNewListItem: () => dispatch({ type: 'ADD_NEW_LIST_ITEM' }),
    mockupActions: _mockupActions,
    listActions: (idx, toShow) => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatch(removeListItem(idx)),
        toShow
      }
    ]
  }
}
