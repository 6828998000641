// @flow

import React, { memo } from 'react'
import * as Styled from './styled'
import type { TMobilePopupProps } from './types'

const MobilePopup = ({
  title,
  isOpen,
  onClose,
  children,
  ...props
}: TMobilePopupProps) => (
  <Styled.SlideUp isOpen={isOpen} onClose={onClose} {...props}>
    <Styled.MobilePopupHeader>
      <Styled.SideBlock>
        <Styled.ArrowButton onClick={onClose} />
      </Styled.SideBlock>
      {title ? <h3>{title}</h3> : null}
      <Styled.SideBlock />
    </Styled.MobilePopupHeader>
    {children}
  </Styled.SlideUp>
)

export default memo(MobilePopup)
