/* @flow */

export const validation = {
  titleMaxChar: 50,
  paragraphMaxChar: 300,
  nameMaxChar: 30,
  positionMaxChar: 30,
  sloganMaxChar: 150,
  title: { required: true },
  blocks: {
    name: { required: true },
    min: 1,
    max: 20,
    socials: {
      min: 1,
      max: 7
    }
  }
}
