/* @flow */
import React, { useContext, memo } from 'react'
import ActionGetter from './Actions'
import S from './Testimonials2.styled'
import { validation } from './Testimonials2.consts'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import QuoteSVG from '@website/common/components/Quote'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'
import { openUrl, getExternalUrl } from '@website/common/utils'

const Testimonials2 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      testimonials,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  return (
    <Show when={[title, paragraph, testimonials.length > 0]}>
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WmCustomContainer>
          <Show when={[title, paragraph]}>
            <S.TextContet>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  as={S.Title}
                  alignment={titleAlignment}
                  maxCount={validation.maxTitleChar}
                  className="WM_GLOBAL_heading"
                  onChange={Actions.changeTitle}
                  changeAlignment={Actions.changeTitleAlignment}
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  as={S.Paragraph}
                  alignment={paragraphAlignment}
                  maxCount={validation.maxParagraphChar}
                  className="WM_GLOBAL_paragraph"
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
            </S.TextContet>
          </Show>
          <S.TestimonialsItemsWrap>
            <Mapper
              data={testimonials}
              render={(item: string, idx: number) => (
                <Show
                  key={idx}
                  when={[
                    item.logoUrl,
                    item.paragraph,
                    item.name,
                    item.position,
                    item.imgUrl
                  ]}
                >
                  <S.Item>
                    <S.ItemWrap>
                      <If
                        condition={item.logoUrl}
                        then={() => (
                          <S.CompanyLogoWrap>
                            <ControlsWithModalOpener
                              actions={Actions.getClientsActions({
                                showRemove: true,
                                logoIdx: idx,
                                keyForSizes: 'testimonial1LogoSizes',
                                imageUrl: item.logoUrl,
                                link: item.clientUrl
                              })}
                            >
                              <Image
                                as={S.Logo}
                                alt="Logo"
                                sizes={item.logoDimensions}
                                defaultImgSrc={item.logoUrl}
                                asProps={{
                                  onClick: () =>
                                    !!item.clientUrl &&
                                    openUrl(getExternalUrl(item.clientUrl))
                                }}
                                hasUrl={!!item.clientUrl}
                              />
                            </ControlsWithModalOpener>
                          </S.CompanyLogoWrap>
                        )}
                      />
                      <AddButton
                        onAdd={() => Actions.addSliderLogo(idx)}
                        toShow={!item.logoUrl}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_image_label')}
                      </AddButton>
                      <S.ItemParagraph>
                        <QuoteSVG style={{ paddingRight: '12px' }} />
                        <EditableContent
                          as={S.ItemParagraphContent}
                          text={item.paragraph}
                          alignment={item.blockParagraphAlignment}
                          maxCount={validation.maxSlideContentChar}
                          onChange={(newParagraph: string) => {
                            Actions.changeItemParagraph(newParagraph, idx)
                          }}
                          changeAlignment={(alignment: string) =>
                            Actions.changeBlockParagraphAlignment(
                              alignment,
                              idx
                            )
                          }
                        />
                      </S.ItemParagraph>
                      <S.ClientWrap>
                        <S.ClientInfo>
                          <EditableContent
                            as={S.Name}
                            text={item.name}
                            maxCount={validation.maxCustomerNameChar}
                            onChange={(newName: string) => {
                              Actions.changeClientName(newName, idx)
                            }}
                          />
                          <EditableContent
                            as={S.Position}
                            text={item.position}
                            maxCount={validation.maxCustomerPositionChar}
                            onChange={(newPosition: string) => {
                              Actions.changeClientPosition(newPosition, idx)
                            }}
                          />
                        </S.ClientInfo>
                        <If
                          condition={item.imgUrl}
                          then={() => (
                            <S.ClientImgWrap>
                              <ControlsWithModalOpener
                                actions={Actions.imageActions({
                                  idx,
                                  keyForSizes: 'testimonial2ClientImgSizes',
                                  imageUrl: item.imgUrl,
                                  mediaFlyoutShift: { x: -90, y: -225 }
                                })}
                                alignment="right"
                              >
                                <Image
                                  as={S.Img}
                                  alt="Team member"
                                  sizes={item.imgDimensions}
                                  defaultImgSrc={item.imgUrl}
                                />
                              </ControlsWithModalOpener>
                            </S.ClientImgWrap>
                          )}
                        />
                        <AddButton
                          onAdd={() => Actions.addClientImage(idx)}
                          toShow={!item.imgUrl}
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_image_label')}
                        </AddButton>
                      </S.ClientWrap>
                    </S.ItemWrap>
                  </S.Item>
                </Show>
              )}
            />
          </S.TestimonialsItemsWrap>
        </S.WmCustomContainer>
      </S.StyledContainer>
    </Show>
  )
})

export default Testimonials2
