import styled from 'styled-components'

export const SidebarWrapper = styled.div`
  position: relative;
  z-index: 40;
  background-color: white;
`

export const Sidebar = styled.div`
  display: flex;
  height: 100%;
  box-shadow: 0 6px 12px #387dff33;
`
