export const validation = {
  headingMaxChar: 40,
  paragraphMaxChar: 80,
  title: { required: true },
  socials: {
    min: 1,
    max: 6
  },
  slides: {
    min: 1,
    max: 10
  }
}
