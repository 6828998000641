/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE': {
        const { idx, itemIdx, newTitle } = action.value
        draft.gallery[idx][itemIdx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, itemIdx, alignment } = action.value
        draft.gallery[idx][itemIdx].titleAlignment = alignment
        break
      }
      case 'ADD_ROW': {
        const idx = action.value
        draft.gallery.splice(idx + 1, 0, initialState.gallery[0])
        break
      }
      case 'REMOVE_ROW':
        const idx = action.value
        draft.gallery.splice(idx, 1)
        break
      case 'CHANGE_IMAGE': {
        const { url, idx, itemIdx, dimensions } = action.value
        draft.gallery[idx][itemIdx].backgroundImg = url
        draft.gallery[idx][itemIdx].backgroundImgDimensions = dimensions || {}
        draft.gallery[idx][itemIdx].imgCropParams = {}
        break
      }
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx, itemIdx } = action.value
          draft.gallery[idx][itemIdx].imgCropParams = imgCropParams
        }
        break
      case 'CHANGE_LINK_DATA':
        {
          const { idx, itemIdx, newLinkData } = action.value
          draft.gallery[idx][itemIdx].additionalUrl = newLinkData.link
          draft.gallery[idx][itemIdx].additionalText = newLinkData.text
        }
        break
      case 'REMOVE_LINK':
        {
          const { idx, itemIdx } = action.value
          draft.gallery[idx][itemIdx].additionalUrl = ''
          draft.gallery[idx][itemIdx].additionalText = ''
        }
        break
      case 'ADD_LINK':
        {
          const { idx, itemIdx } = action.value
          const { additionalUrl, additionalText } = initialState.gallery[0][0]
          draft.gallery[idx][itemIdx].additionalUrl = additionalUrl
          draft.gallery[idx][itemIdx].additionalText = additionalText
        }
        break
    }
  })
}
