// @flow
import React, { memo, useState } from 'react'

import MoreIcon from '@editor/common/assets/svgr-icons/More_icon.svg'
import FlipIcon from '@editor/common/assets/svgr-icons/Flip_icon.svg'
import ReplaceIcon from '@editor/common/assets/svgr-icons/replace_icon.svg'
import DuplicateIcon from '@editor/common/assets/svgr-icons/Duplicate_icon.svg'
import AnimationIcon from '@editor/common/assets/svgr-icons/Animation_icon.svg'
import { translate } from '@editor/common/utils/translations'
import { secondary } from '@website/common/styles/colors'

import { Control, ControlsBar, MovementControl } from '../components'
import { RemoveIcon } from '../styled'
import * as Styled from './styled'
import type { TMainControlsDesktopProps } from './types'

const MainControlsDesktop = ({
  idx,
  isUpMovementAllowed,
  isDownMovementAllowed,
  isOrientationAllowed,
  isDuplicateAllowed,
  isDuplicateDisabled,
  isRemoveBtnVisible,
  isComponentAnimated,
  isAnimationEnabled,
  onAnimationChange,
  onOrientationChange,
  onRemove,
  onReplace,
  onDuplicate
}: TMainControlsDesktopProps) => {
  const [isExpanded, setExpandedState] = useState(true)

  return (
    <ControlsBar
      IconComp={MoreIcon}
      tooltipText={!isExpanded ? translate('component_actions_label') : ''}
      isExpanded={isExpanded}
      setExpandedState={setExpandedState}
    >
      {isExpanded ? (
        <Styled.Controls>
          <MovementControl
            idx={idx}
            isUpMovementAllowed={isUpMovementAllowed}
            isDownMovementAllowed={isDownMovementAllowed}
          />
          {isOrientationAllowed ? (
            <Control
              IconComp={FlipIcon}
              tooltipText={translate('flip_label')}
              onClick={onOrientationChange}
            />
          ) : null}
          {isComponentAnimated ? (
            <Control
              isSelected={isAnimationEnabled}
              IconComp={AnimationIcon}
              tooltipText={
                <p>
                  {isAnimationEnabled
                    ? translate('disable_animation_label')
                    : translate('enable_animation_label')}
                  <p style={{ color: secondary.Light }}>
                    {translate('preview_to_see_changes_label')}
                  </p>
                </p>
              }
              onClick={onAnimationChange}
            />
          ) : null}
          <Control
            IconComp={ReplaceIcon}
            tooltipText={translate('replace_label')}
            onClick={onReplace}
          />
          {isDuplicateAllowed ? (
            <Control
              IconComp={DuplicateIcon}
              tooltipText={translate('duplicate_label')}
              onClick={onDuplicate}
              isDisabled={isDuplicateDisabled}
            />
          ) : null}
          {isRemoveBtnVisible ? (
            <Control
              IconComp={RemoveIcon}
              tooltipText={translate('delete_label')}
              onClick={onRemove}
            />
          ) : null}
        </Styled.Controls>
      ) : null}
    </ControlsBar>
  )
}

export default memo(MainControlsDesktop)
