/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header2.styled'
import THEME from './Header2.theme'
import { validation } from './Header2.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import Buttons from '@website/common/components/Buttons'

const Header2 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      buttons,
      logoWidth,
      logoDimensions,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)

    const [navBarHeight, setNavBarHeight] = useState(0)

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
      >
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <Show when={[title, paragraph, buttons.length > 0]}>
          <S.WmCustomContainer topOffset={navBarHeight}>
            <S.HeaderContent>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                required={validation.title.required}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading"
                as={S.Title}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Description}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <Buttons data={buttons} />
            </S.HeaderContent>
          </S.WmCustomContainer>
        </Show>
      </S.StyledContainer>
    )
  }
)

Header2.contextType = DispatchContext

export default Header2
