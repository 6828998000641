/* @flow */
import React, { useContext, useCallback, useRef } from 'react'
import { connect } from 'react-redux'
import { selectIsUserAuthorized } from '@selectors'
import { openLoginPopup } from '@actions/editor'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ImageResizer from '@website/common/components/ImageResizer'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import { translate } from '@editor/common/utils/translations'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import { projectContainerClassName } from '@website/conf/consts'

import {
  DispatchContext,
  EditingContext,
  SiteIdContext,
  ControlModalsContext
} from '@contexts'
import * as S from './Logo.styled'
import { navbarLogoSizes } from '@editor/conf/imageSizes'
import ActionGetter from './Actions'
import { FLYOUT_HEIGHT, BUTTON_CLASSNAME } from './consts'

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

const Logo = ({
  logoSrc,
  logoWidth,
  logoColor,
  imgDimensions,
  isUserAuthorized,
  openLoginPopup
}) => {
  const buttonRef = useRef(null)
  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const siteId = useContext(SiteIdContext)
  const Actions = ActionGetter(dispatcher)
  const { modalStates, setModalState } = useContext(ControlModalsContext)

  const isFlyoutOpen = modalStates && modalStates[MODAL_TYPES.mediaModal].isOpen

  const openPopup = () =>
    setModalState(MODAL_TYPES.mediaModal, {
      isOpen: !isFlyoutOpen,
      targetRef: buttonRef,
      renderingContainer: projectContainerClassName,
      isFlyoutPositionFixed: false,
      alignment: 'left',
      type: LINK_TYPES.logo,
      optimizationDimensions: navbarLogoSizes,
      handler: Actions.changeNavbarLogo,
      className: 'logo-change-flyout',
      isRCOutclickDisabled: false,
      backdropDisablingSelectors: [`.${BUTTON_CLASSNAME}`]
    })

  const handleImageChangeClick = useCallback(() => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }
    openPopup()
  }, [isUserAuthorized, openLoginPopup, openPopup])

  return (
    <>
      <S.GlobalStyles buttonEl={buttonRef.current} />
      <If
        condition={!isEditing && !logoSrc}
        then={() => null}
        otherwise={() => (
          <If
            condition={!logoSrc}
            then={() => (
              <AddButton
                toShow={true}
                onAdd={handleImageChangeClick}
                buttonRef={buttonRef}
                className={BUTTON_CLASSNAME}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_logo_label')}
              </AddButton>
            )}
            otherwise={() => (
              <S.Figure className="logo-wrapper">
                <ControlsWithModalOpener
                  actions={Actions.logoActions(
                    siteId,
                    !!logoSrc,
                    logoColor,
                    logoSrc,
                    { x: 0, y: -FLYOUT_HEIGHT }
                  )}
                >
                  <ImageResizer
                    imageSrc={logoSrc}
                    imageDimensions={imgDimensions}
                    color={logoColor}
                    alt="Footer logo"
                    initialWidth={logoWidth}
                    action={Actions.resizeAction}
                  />
                </ControlsWithModalOpener>
              </S.Figure>
            )}
          />
        )}
      />
    </>
  )
}

export default connect(mapStateToProps, { openLoginPopup })(Logo)
