/* @flow */
import {
  cropableImageModalActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'

export default dispatcher => {
  const changeTitle = (newTitle: string, idx: number, itemIdx: number) => {
    dispatcher({
      type: 'CHANGE_TITLE',
      value: { newTitle, idx, itemIdx }
    })
  }

  const changeBlockTitleAlignment = (
    alignment: string,
    idx: number,
    itemIdx: number
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx, itemIdx }
    })
  }

  const addRow = (idx: string) => {
    dispatcher({
      type: 'ADD_ROW',
      value: idx
    })
  }

  const removeRow = (idx: string) => {
    dispatcher({
      type: 'REMOVE_ROW',
      value: idx
    })
  }

  const imageActions = (idx: number, itemIdx: number, imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'Portfolio10',
      showRemove: false,
      additionalArgs: { idx, itemIdx },
      imageUrl
    })
  ]

  const changeLinkData = value => {
    dispatcher({
      type: 'CHANGE_LINK_DATA',
      value
    })
  }

  const removeLink = value => () => {
    dispatcher({
      type: 'REMOVE_LINK',
      value
    })
  }

  const linkActions = (idx: number, itemIdx: number, linkData) => [
    ...linkActionMaker(dispatcher)({
      isAdditionalLink: true,
      idx,
      itemIdx,
      linkData,
      actions: {
        change: (_, newLinkData) =>
          changeLinkData({ idx, itemIdx, newLinkData }),
        remove: removeLink({ idx, itemIdx })
      }
    })
  ]

  const addLink = (idx: number, itemIdx: number) => {
    dispatcher({
      type: 'ADD_LINK',
      value: { idx, itemIdx }
    })
  }

  return {
    addRow,
    removeRow,
    imageActions,
    changeTitle,
    changeBlockTitleAlignment,
    linkActions,
    addLink
  }
}
