import React, { useCallback, useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { openLoginPopup } from '@actions'
import { UpgradePopupContext } from '@contexts'
import { PAGE_BASE } from '@editor/conf/routes'
import { MAKE_MULTILINGUAL_REFERRER } from '@editor/conf/consts'
import { translate } from '@editor/common/utils/translations'
import MultilingualIcon from '@editor/common/assets/svgr-icons/Multilingual_icon.svg'
import { useHistoryWithSearch } from '@hooks'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { isSubscriber, selectIsUserAuthorized } from '@selectors'

import { Divider, Wrapper } from '../styled'
import * as Styled from './styled'

const MakeMultilingualBtn = () => {
  const isPaidUser = useSelector(isSubscriber)
  const isUserAuthorized = useSelector(selectIsUserAuthorized)
  const dispatch = useDispatch()

  const history = useHistoryWithSearch()

  const { url } = useRouteMatch(PAGE_BASE) || {}

  const { setUpgradePopupState } = useContext(UpgradePopupContext)

  const onClick = useCallback(() => {
    if (!isUserAuthorized) {
      dispatch(openLoginPopup())
      return
    }

    isPaidUser
      ? history.push(`${url}/settings/languages`)
      : setUpgradePopupState({
          isOpen: true,
          referrer: MAKE_MULTILINGUAL_REFERRER
        })
  }, [url, isPaidUser, isUserAuthorized])

  return (
    <Wrapper>
      <Divider />
      <TooltipWrapper
        text={translate('make_multilingual_label')}
        position="bottom"
      >
        <Styled.MultilingualBtn
          onClick={onClick}
          isBgYellowOnHover={!isPaidUser}
        >
          <MultilingualIcon />
        </Styled.MultilingualBtn>
      </TooltipWrapper>
      <Divider />
    </Wrapper>
  )
}

export default MakeMultilingualBtn
