import styled, { createGlobalStyle, css } from 'styled-components'

import { secondary } from '@website/common/styles/colors'

export const GlobalStyles = createGlobalStyle`
  @keyframes slide-left {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slide-right {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }
`

export const SlideLeftContainer = styled.div`
  position: fixed;
  height: 100vh;
  z-index: 20;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(37, 46, 72, 0.2);
`

export const SlideLeft = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
  max-width: 95%;
  height: 100%;
  transform: translateX(0);
  animation-name: ${({ isOpen }) => (isOpen ? 'slide-left' : 'slide-right')};
  animation-duration: 0.3s;

  ${({ isPositionFixed }) =>
    !isPositionFixed
      ? css`
          box-shadow: -3px 0 12px #387dff33;
          border-left: 1px solid ${secondary.ExtraLight};
        `
      : ''}
`
