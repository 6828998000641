// @flow

// TODO: get rid of unauthorized user logic in this component

import React, { useContext } from 'react'
import { connect } from 'react-redux'
import { ControlModalsContext, EditingContext } from '@contexts'
import Controls from '../Controls'

import type { TControlsProps } from '../Controls/types'
import { selectIsUserAuthorized } from '@selectors'
import { openLoginPopup } from '@actions/editor'

type TProps = {
  isUserAuthorized: boolean,
  actionGetter: Function,
  openLoginPopup: Function
} & TControlsProps

const prepareActions4Controls = (
  actions,
  setModalState,
  isUserAuthorized,
  openLoginPopup
) =>
  actions.map(({ modalType, modal, handler, ...rest }) => {
    const isImageAction = modalType === 'image'
    const _handler =
      isImageAction && !isUserAuthorized
        ? openLoginPopup
        : modal
        ? () =>
            setModalState(modal.type, {
              isOpen: true,
              handler,
              ...(modal.props || {})
            })
        : handler

    return {
      modalType,
      handler: _handler,
      ...rest
    }
  })

const ControlsWithModalOpener = ({
  actions,
  children,
  isUserAuthorized,
  openLoginPopup,
  ...controlProps
}: TProps) => {
  const { isEditing } = useContext(EditingContext)

  if (!actions || !actions.length || !isEditing) {
    return children
  }

  const { setModalState } = useContext(ControlModalsContext)
  return (
    <Controls
      {...controlProps}
      actions={prepareActions4Controls(
        actions,
        setModalState,
        isUserAuthorized,
        openLoginPopup
      )}
    >
      {children}
    </Controls>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})
const mapDispatchToProps = { openLoginPopup }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ControlsWithModalOpener)
