/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature24.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  padding-top: ${({ isEditing }) => (isEditing ? '140px' : '80px')};
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => (isEditing ? '140px' : '60px')};
  }
  ${getBackgroundStyles(THEME)};
  > :first-child {
    padding-top: 0;
  }
`

const Headings = styled.div`
  text-align: center;
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-top: 20px;
`

const BlocksContainer = styled.div`
  max-width: ${props => (props.isContainerWidthSmaller ? '900px' : '1200px')};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 50px;
  margin: -34px auto 0 auto;
  @media only screen and (max-width: 1025px) {
    max-width: 700px;
    padding-top: 40px;
  }
`

const ControlContainer = styled.div`
  margin: 58px 15px 0 15px;
  display: flex;
`

const Block = styled.div`
  position: relative;
  height: 100%;
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  ${({ isHover }) =>
    css`
      background-color: ${isHover ? getStyleForProp('heading') : 'white'};
      ${isHover
        ? css`
            & .blockText {
              color: white;
            }
          `
        : ''}
    `}

  transition: 0.1s linear;
  padding: ${({ isLinkExists, isEditing }) =>
    isLinkExists || isEditing ? '50px 20px 30px 20px' : '50px 20px 10px 20px'};
  box-sizing: border-box;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  .add-button-wrap {
    text-align: left !important;
    margin-top: auto;
  }
  & > .control-container {
    margin-bottom: 10px;
    width: 100%;
    h2 {
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const BlockTitle = styled.h2`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
`

const BlockParagraph = styled.p`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('blockParagraph')};
  margin: 10px 0 40px 0;
  line-height: 1.4;
`

const IconWrap = styled.div`
  user-select: none;
  position: absolute;
  top: -23px;
  left: 20px;
`

const Circle = styled.div`
  width: 47px;
  height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('cardRadius')};
  box-shadow: 0px 3px 6px rgba(41, 55, 97, 0.101);
  border: ${getStyleForProp('border')};
  color: ${getStyleForProp('heading')};
  background: white;
  svg {
    fill: ${getStyleForProp('blockIcon')};
  }
`
const AnchorContainer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  ${({ isEditing }) =>
    isEditing &&
    css`
      padding-top: 30px;
    `};
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ isHover }) => (isHover ? 'white' : getStyleForProp('heading'))};
    font-weight: 500 !important;
    svg {
      fill: ${({ isHover }) =>
        isHover ? 'white' : getStyleForProp('heading')};
    }
  }
  .learn-more-link {
    align-items: center;
    .arrow-right {
      margin-left: 0;
      padding-left: 5px;
      min-width: 24px;
      transition: transform linear 0.15s;
    }
    &:hover {
      opacity: 0.7;
      .arrow-right {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
  svg {
    transform: translate3d(0, 0, 0);
  }
`

export default {
  StyledContainer,
  Headings,
  Title,
  Paragraph,
  BlocksContainer,
  ControlContainer,
  Block,
  IconWrap,
  Circle,
  BlockTitle,
  BlockParagraph,
  AnchorContainer
}
