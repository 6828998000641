/* @flow */
import produce from 'immer'
import { validation } from './Pricing6.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        return
      case 'REMOVE_IMAGE':
        draft.slides.splice(action.value.idx, 1)
        return
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        draft.slides[idx].imageUrl = url
        draft.slides[idx].imgDimensions = dimensions || {}
        return
      }
      case 'REMOVE_MENU_ITEM':
        const { idx: idx__, activeIdx: activeIdx__ } = action.value
        if (state.slides[idx__].menu.length === validation.slides.menu.min) {
          return
        }
        draft.slides[idx__].menu.splice(activeIdx__, 1)
        return
      case 'ADD_MENU_ITEM':
        draft.slides[action.value].menu.push(
          initialState.slides[action.value].menu[0]
        )
        return
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        return
      case 'CHANGE_SLIDE_TITLE':
        const { newTitle, idx } = action.value
        draft.slides[idx].slideTitle = newTitle
        return
      case 'CHANGE_ITEM_DATA':
        const { data, itemIdx, activeSlideIdx, keyInStore } = action.value
        draft.slides[activeSlideIdx].menu[itemIdx][keyInStore] = data
        return
      case 'CHANGE_BADGE_COLOR':
        const { slideIdx, menuIdx, color, badgeIdx } = action.value
        draft.slides[slideIdx].menu[menuIdx].badge[badgeIdx].color = color
        return
      case 'REMOVE_BADGE':
        const {
          slideIdx: slideIdx_,
          menuIdx: menuIdx_,
          badgeIdx: badgeIdx_
        } = action.value
        draft.slides[slideIdx_].menu[menuIdx_].badge.splice(badgeIdx_, 1)
        return
      case 'CHANGE_BADGE_NAME':
        const {
          slideIdx: slideIdx__,
          menuIdx: menuIdx__,
          badgeIdx: badgeIdx__,
          newName
        } = action.value
        draft.slides[slideIdx__].menu[menuIdx__].badge[badgeIdx__].name =
          newName
        return
      case 'ADD_BADGE_ITEM':
        const { slideIdx: slideIdx___, menuIdx: menuIdx___ } = action.value
        draft.slides[slideIdx___].menu[menuIdx___].badge.push(
          initialState.slides[0].menu[2].badge[0]
        )
    }
  })
}
