import React from 'react'
import { connect } from 'react-redux'

import { selectSidebarActiveItem } from '@selectors'
import TextContent from './components/TextContent'
import ColorsContent from './components/ColorsContent'
import StylesContent from './components/StylesContent'

const subSidebarContents = {
  Text: <TextContent />,
  Colors: <ColorsContent />,
  Styles: <StylesContent />
}

const SubSidebarContent = ({ sidebarActiveItem }) => {
  return <>{subSidebarContents[sidebarActiveItem]}</>
}

const mapStateToProps = state => ({
  sidebarActiveItem: selectSidebarActiveItem(state)
})

export default connect(mapStateToProps)(SubSidebarContent)
