/* @flow */
import { validation } from '../FAQ1.consts'
import { simpleButtonActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'
export default dispatcher => {
  const changeQuestion = (newQuestion: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_QUESTION',
      value: { newQuestion, idx }
    })
  }
  const changeAnswer = (newAnswer: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_ANSWER',
      value: { newAnswer, idx }
    })
  }

  const removeFaq = (idx: number) => {
    dispatcher({
      type: 'REMOVE_FAQ',
      value: idx
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeAnswer,
    changeParagraph: _changeParagraph,
    changeQuestion,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    addFaq: () => {
      dispatcher({ type: 'ADD_FAQ' })
    },
    getBtnActions: simpleButtonActionMaker(dispatcher),
    getFaqActions: (idx: number, faqCount: number) => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeFaq(idx)),
        toShow: faqCount > validation.faqs.min
      }
    ]
  }
}
