// @flow
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo
} from 'react'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { translate } from '@editor/common/utils/translations'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'

import {
  InputWrapper,
  Input as StyledInput,
  TextArea,
  Count
} from '../../shared-components/styled'
import * as Styled from './styled'
import type { TInputProps } from './types'
import AttentionIcon from '@editor/common/assets/svgr-icons/Attention_icon_red.svg'

const EMPTY_FIELD_MESSAGE = translate('this_field_cannot_be_empty_label')

const getCharMaxLimitMessage = count =>
  transformTextVariables(translate('max_char_message'), [{ value: count }])

const Input = ({
  value,
  count,
  hideCount,
  placeholder,
  defaultValue = '',
  errorMessage: errorMessageFromProps,
  className,
  hasError,
  isRequired,
  disabled,
  isTextArea,
  borderColor,
  borderHoverColor,
  onChange,
  onPaste,
  onBlur,
  onKeyPress,
  iconName,
  onRemoveIconClick
}: TInputProps) => {
  const [errorMessage, setErrorMessage] = useState(errorMessageFromProps)
  const [isInputActive, setInputActiveState] = useState(false)
  const timerRef = useRef()

  const isRemoveIconVisible = useMemo(
    () => isInputActive && value,
    [isInputActive, value]
  )

  const _setErrorMessage = useCallback(
    value => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      setErrorMessage(value)
    },
    [timerRef.current]
  )

  useEffect(() => {
    _setErrorMessage(errorMessageFromProps)
  }, [errorMessageFromProps])

  const handleFocus = useCallback(() => {
    setInputActiveState(true)
  }, [])

  const handleKeyPress = useCallback(
    e => {
      if (e.key === 'Enter') {
        e.target.blur()
      }

      typeof onKeyPress === 'function' && onKeyPress(e)
    },
    [onKeyPress]
  )

  const handleBlur = useCallback(
    e => {
      const { value } = e.target
      if (isRequired && value.length === 0) {
        _setErrorMessage(EMPTY_FIELD_MESSAGE)
        onChange(defaultValue)
        // timerRef.current = setTimeout(() => _setErrorMessage(''), 3000)
        return
      }

      onBlur && onBlur(e)
      setInputActiveState(false)
      _setErrorMessage('')
    },
    [onBlur, onChange, isRequired, defaultValue, timerRef]
  )

  const handleChange = useCallback(
    e => {
      const { value } = e.target
      if (value.length > count) {
        const charLimitMessage = getCharMaxLimitMessage(count)
        _setErrorMessage(charLimitMessage)
        return
      }

      onChange && onChange(value)
      _setErrorMessage('')
    },
    [count, _setErrorMessage, onChange]
  )

  const handlePaste = useCallback(
    e => {
      e.preventDefault()
      const copiedText = e.clipboardData.getData('text')
      const selected = document.getSelection().toString()
      const replacedText = selected
        ? value.replace(selected, copiedText)
        : `${value || ''}${copiedText}`

      const currentText = `${replacedText}`.slice(0, count)

      if (currentText.length >= count) {
        const charLimitMessage = getCharMaxLimitMessage(count)

        _setErrorMessage(charLimitMessage)
      }

      onChange && onChange(currentText)
    },
    [value, _setErrorMessage, onChange]
  )

  return (
    <InputWrapper className={className}>
      {iconName ? (
        <Icon
          className="left-icon"
          name={iconName}
          size="medium"
          color="#545f7e"
        />
      ) : null}
      {isTextArea ? (
        <TextArea
          value={value}
          disabled={disabled}
          placeholder={placeholder}
          isInputActive={isInputActive}
          hasError={errorMessage || hasError}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onPaste={handlePaste}
        />
      ) : (
        <StyledInput
          type="text"
          isLeftIconExists={iconName}
          value={value}
          disabled={disabled}
          borderColor={borderColor}
          borderHoverColor={borderHoverColor}
          placeholder={placeholder}
          isInputActive={isInputActive}
          hasError={errorMessage || hasError}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onPaste={handlePaste}
          onKeyPress={handleKeyPress}
        />
      )}
      {isRemoveIconVisible ? (
        <div onMouseDown={onRemoveIconClick} onTouchEnd={onRemoveIconClick}>
          <Icon
            className="remove-icon"
            name="cancel"
            size="medium"
            color="#9AA6C8"
          />
        </div>
      ) : null}
      {errorMessage ? (
        <Styled.ErrorMessage className="error-message">
          <AttentionIcon />
          {errorMessage}
        </Styled.ErrorMessage>
      ) : null}
      {count && !hideCount ? (
        <Count hasExceedLimit={errorMessage || hasError}>
          {value.length}/{count}
        </Count>
      ) : null}
    </InputWrapper>
  )
}

export default memo(Input)
