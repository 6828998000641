import React, { useEffect } from 'react'
import { WITH_VALIDATION_ERROR_CLASSNAME } from './consts'

const Validatable =
  Comp =>
  ({
    addFormField,
    addValidationOptions,
    removeFormField,
    removeValidationOptions,
    hasValidationError,
    className,
    ...props
  }) => {
    useEffect(() => {
      addFormField && addFormField()
      addValidationOptions && addValidationOptions()
      return () => {
        removeFormField && removeFormField()
        removeValidationOptions && removeValidationOptions()
      }
    }, [])

    return (
      <Comp
        {...props}
        className={`${className || ''} ${
          hasValidationError ? WITH_VALIDATION_ERROR_CLASSNAME : ''
        }`}
      />
    )
  }

export default Validatable

export { useFormValidation } from './useFormValidation'
