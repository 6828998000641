// @flow

import React, { memo, useMemo } from 'react'
import { translate, getLocalizedURL } from '@editor/common/utils/translations'

import { List } from '../../components'
import WebsiteIcon from '@editor/common/assets/svgr-icons/Website_icon.svg'
import DomainIcon from '@editor/common/assets/svgr-icons/Domain_icon.svg'
import * as Styled from './styled'

import { openUrl } from '@website/common/utils'

const path = window.CURRENT_HOST || window.location.origin

const MyProjects = ({ closeDropdown }) => {
  const items = useMemo(
    () => [
      {
        IconComp: WebsiteIcon,
        text: translate('websites_label'),
        onClick: () => {
          closeDropdown()
          openUrl(`${path}${getLocalizedURL('/profile/my-websites')}`)
        }
      },
      {
        IconComp: DomainIcon,
        text: translate('domains_label'),
        onClick: () => {
          closeDropdown()
          openUrl(`${path}${getLocalizedURL('/profile/my-domains')}`)
        }
      }
    ],
    [closeDropdown]
  )
  return (
    <Styled.Container>
      <List title={translate('my_projects_label')} items={items} />
    </Styled.Container>
  )
}

export default memo(MyProjects)
