import styled, { createGlobalStyle, css } from 'styled-components'
import LibPopup from '@renderforest/rf-ui-library/organism/Popup'
import Button from '@renderforest/rf-ui-library/atoms/Button'
import { globalColors } from '@renderforest/rf-ui-library/consts/colors'

export const Popup = styled(LibPopup)`
  z-index: 10001;
  height: 100%;
  width: 100%;
  > div {
    padding: 30px 20px 20px;
    @media only screen and (max-width: 767px) {
      padding: 30px 10px 20px 10px;
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
      border-radius: 0;
    }
  }
`

export const PopupHeader = styled.h3`
  font-size: 20px;
  text-align: center;
  width: 100%;
  color: ${globalColors.secondaryDark};
`

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 350px;
  min-height: 450px;
  @media only screen and (max-width: 767px) {
    width: 100%;
    margin-top: 15px;
  }
  & label {
    margin-bottom: 0;
  }
  input {
    border-radius: 5px;
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  input {
    ::placeholder {
      color: ${globalColors.secondarySemiLight};
    }
  }
  ${({ withError }) =>
    withError
      ? css`
          min-height: 101px;
          margin-bottom: 0;
        `
      : css`
          margin-bottom: 21px;
        `}
  .message-text {
    padding-left: 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      height: 16px;
      width: 16px;
      background: url('https://static.rfstat.com/renderforest/images/website_maker_images/warning_icon_red.svg')
        no-repeat 100% 100%/16px 16px;
    }
  }
  > div {
    > div {
      border-radius: 5px;
    }
  }
`

export const CharacterCount = styled.span`
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
  color: ${globalColors.secondary};
`

export const ActionButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 10px;
  padding-bottom: 10px;
  > button {
    margin: 0 10px;
  }
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 101px;
  ${({ isValid }) =>
    !isValid
      ? css`
          input {
            border: 1px solid red;
          }
          > div {
            > div {
              > div {
                border: 1px solid red;
              }
            }
          }
        `
      : css``}
  ul {
    max-height: 170px;
  }
`

export const PhoneInputLabel = styled.p`
  display: flex;
  color: #7683a7;
  padding: 7px;
  font-size: 14px;
`

export const ErrorMessageWrapper = styled.p`
  color: #ff4c4c;
  padding: 7px 7px 7px 20px;
  font-size: 12px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    height: 16px;
    width: 16px;
    background: url('https://static.rfstat.com/renderforest/images/website_maker_images/warning_icon_red.svg')
      no-repeat 100% 100%/16px 16px;
  }
`

export const GlobalStyles = createGlobalStyle`
  .dropdown-items-container {
     & > div > div {
       @media only screen and (max-width: 768px) {
         &:hover {
           background-color: ${globalColors.primaryLight};
         }
       }
     }
     border-radius: 0 0 5px 5px;
     transform: translateY(-3px);
  }
`

export const CancelButton = styled(Button)`
  color: #3271e6;
  background: white;
  min-width: 108px;
  padding: 10px 30px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
`

export const SaveButton = styled(Button)`
  color: white;
  background: linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0% 0% no-repeat;
  min-width: 98px;
  padding: 10px 30px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: 500;
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%) 0%
      0% no-repeat;
    box-shadow: 0px 3px 6px rgba(56, 125, 255, 0.501);
    transition: all 0.2s ease-out;
  }
`
