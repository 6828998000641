/* @flow */
import {
  buttonModalActionMaker,
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeTitleAlignment,
  changeParagraph,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'
import { validation } from '../Feature15_2.consts'

export default dispatcher => {
  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const changeSubTitle = (newSubTitle: string) =>
    dispatcher({
      type: 'CHANGE_SUBTITLE',
      value: newSubTitle
    })

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeCtaTextAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_CTA_TEXT_ALIGNMENT',
      value: alignment
    })
  }

  const changeCtaText = (newCtaText: string) =>
    dispatcher({
      type: 'CHANGE_CTA_TEXT',
      value: newCtaText
    })

  const changeItemParagraph = (newItemParagraph: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_ITEM_PARAGRAPH',
      value: { newItemParagraph, idx }
    })

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeCountAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_COUNT_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBadgeText = (newBadgeText: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_BADGE_TEXT',
      value: { newBadgeText, idx }
    })

  const changeCountText = (newCountText: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_COUNT_TEXT',
      value: { newCountText, idx }
    })

  const featureActions = (
    idx: number,
    featuresCount: number,
    imageUrl: string
  ) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature15',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    }),
    ...removeBlockActionMaker(dispatcher)({
      idx,
      showRemove: featuresCount > validation.features.min
    })
  ]

  const addBottomBlock = () =>
    dispatcher({
      type: 'ADD_BOTTOM_BLOCK'
    })

  const removeBottomBlock = () => ({
    type: 'REMOVE_BOTTOM_BLOCK'
  })

  return {
    addBlock: () => dispatcher(addBlock()),
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    featureActions,
    changeCtaText,
    changeSubTitle,
    changeSubTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeCountAlignment,
    changeCtaTextAlignment,
    changeBlockParagraphAlignment,
    addBottomBlock,
    changeBadgeText,
    changeCountText,
    changeItemParagraph,
    btnActions: buttonModalActionMaker(dispatcher),
    joinBlockAction: [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeBottomBlock())
      }
    ]
  }
}
