export const validation = {
  paragraphMaxChar: 100,
  blockTitleMaxChar: 50,
  blockParagraphMaxChar: 60,
  paragraph: { required: true },
  blocks: {
    min: 1,
    max: 4,
    title: { required: true }
  }
}
