//@flow
import React, { memo } from 'react'

import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_left.svg'
import { translate } from '@editor/common/utils/translations'
import RangeSlider from '@editor/common/components/RangeSlider'

import * as Styled from './styled'
import type { TFlyoutContentProps } from './types'

const FlyoutContentMobile = ({
  bgOverlayTransparency,
  onRangeSliderChange,
  onClose
}: TFlyoutContentProps) => (
  <Styled.FlyoutContentMobile>
    <Styled.Header>
      <ArrowIcon className="arrow-icon" onClick={onClose} />
      {translate('adjust_transparency_label')}
    </Styled.Header>
    <Styled.Main>
      <Styled.RangeSliderWrapper>
        <RangeSlider
          value={1 - bgOverlayTransparency}
          max={1}
          step={0.01}
          onChange={onRangeSliderChange}
        />
      </Styled.RangeSliderWrapper>
    </Styled.Main>
  </Styled.FlyoutContentMobile>
)

export default memo(FlyoutContentMobile)
