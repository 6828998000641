/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'


const AnimatedWave = () =>
  (
    < svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto" >
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g className="parallax">
        <use href="#gentle-wave" x="50" y="0" />
        <use href="#gentle-wave" x="50" y="1" />
        <use href="#gentle-wave" x="50" y="4" />
        <use href="#gentle-wave" x="50" y="3" />
      </g>
    </svg >
  )


export default withTheme(AnimatedWave)
