import React from 'react'

import * as S from './styled'

const voidFunction = () => void 0

const PopupHeader = ({ title }) => <S.PopupHeader>{title}</S.PopupHeader>
const PopupFooter = ({ buttonTexts, onConfirm, onCancel }) => (
  <S.PopupFooter>
    <S.CancelButton onClick={onCancel}>{buttonTexts.cancel}</S.CancelButton>
    <S.ConfirmButton onClick={onConfirm}>{buttonTexts.confirm}</S.ConfirmButton>
  </S.PopupFooter>
)

export default ({
  isOpen,
  onConfirm = voidFunction,
  onCancel = voidFunction,
  title,
  content,
  buttons
}) => (
  <S.ConfirmPopup
    isOpen={isOpen}
    onClose={onCancel}
    header={<PopupHeader title={title} />}
    footer={
      <PopupFooter
        buttonTexts={buttons}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    }
  >
    {content && <S.Content>{content}</S.Content>}
  </S.ConfirmPopup>
)
