/* @flow */
import React, { useContext, memo } from 'react'
import S from './Team2.styled'
import { validation } from './Team2.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { openUrl, getExternalUrl } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'

const Team2 = memo(props => {
  const {
    data: { title, titleAlignment, paragraph, paragraphAlignment, team }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <Show when={[title]}>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
        </Show>
        <Show when={[paragraph]}>
          <EditableContent
            text={paragraph}
            as={S.Description}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </Show>
        <S.MembersWrap>
          <Mapper
            data={team}
            render={(member, idx: number) => (
              <S.ItemWrap
                key={idx}
                order={team.length - idx}
                isEditing={isEditing}
              >
                <S.ImgWrap>
                  <S.ControlContainer>
                    <ControlsWithImageCropper
                      actions={Actions.memberActions(
                        idx,
                        team.length > validation.team.min,
                        member.teamRef,
                        member.imageUrl
                      )}
                      style={{ height: '100%', width: '100%' }}
                    >
                      <Image
                        as={S.TeamImg}
                        sizes={member.imgDimensions}
                        defaultImgSrc={member.imageUrl}
                        alt="Team member image"
                        asProps={{
                          imgCropParams: member.imgCropParams,
                          onClick: () =>
                            member.teamRef &&
                            openUrl(getExternalUrl(member.teamRef))
                        }}
                        isUrl={member.teamRef}
                      />
                    </ControlsWithImageCropper>
                  </S.ControlContainer>
                </S.ImgWrap>
                <Show when={[member.name, member.position]}>
                  <S.MemberInfo
                    className="WM_GLOBAL_secondary-font"
                    isEditing={isEditing}
                  >
                    <EditableContent
                      text={member.name}
                      as={S.Name}
                      alignment={member.nameAlignment}
                      maxCount={validation.blockTitleMaxChar}
                      onChange={(newName: string) => {
                        Actions.changeName(newName, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeNameAlignment(alignment, idx)
                      }
                      isEditing={isEditing}
                    />
                    <EditableContent
                      text={member.position}
                      as={S.Position}
                      alignment={member.positionAlignment}
                      maxCount={validation.blockSubTitleMaxChar}
                      onChange={(newPosition: string) => {
                        Actions.changePosition(newPosition, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changePositionAlignment(alignment, idx)
                      }
                      isEditing={isEditing}
                    />
                  </S.MemberInfo>
                </Show>
              </S.ItemWrap>
            )}
          />
        </S.MembersWrap>
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addMember}
        toShow={team.length < validation.team.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Team2
