/* @flow */
import React, { useContext, useMemo, memo } from 'react'
import { validation } from './Contact11.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { isValidEmail, isValidPhoneNumber } from '@website/common/utils'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import S from './Contact11.styled'
import { translate } from '@editor/common/utils/translations'

const Contact11 = memo(props => {
  const {
    data: {
      blocks,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)

  const areAllIconsDeleted = useMemo(() => {
    return !blocks.find(block => block.iconName !== '')
  }, [blocks])

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WMCustomContainer isFourBlock={blocks.length === 4}>
        <S.FeatureItemWrap isEditing={isEditing}>
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => (
              <S.ItemWrap
                key={idx}
                isMoreThanFour={blocks.length > 4}
                isEditing={isEditing}
              >
                <ControlsWithModalOpener
                  actions={Actions.blockActions({
                    idx,
                    showRemove: blocks.length > validation.blocks.min
                  })}
                  style={{ width: '100%' }}
                >
                  <S.ContentWrap
                    areAllIconsDeleted={areAllIconsDeleted}
                    isEditing={isEditing}
                  >
                    <If
                      condition={!!block.iconName}
                      otherwise={() => (
                        <AddButton
                          onAdd={() => Actions.addIcon(idx)}
                          style={{
                            margin: '0px'
                          }}
                          type="icon"
                          large
                          toShow
                        >
                          +
                        </AddButton>
                      )}
                      then={() => (
                        <S.IconWrapper>
                          <ControlsWithModalOpener
                            actions={Actions.iconActions({ idx })}
                          >
                            <S.Circle>
                              <S.Icon size="large" name={block.iconName} />
                            </S.Circle>
                          </ControlsWithModalOpener>
                        </S.IconWrapper>
                      )}
                    />
                    <EditableContent
                      as={S.BlockTitle}
                      text={block.title}
                      required={!block.paragraph}
                      maxCount={validation.blockTitleMaxChar}
                      alignment={block.titleAlignment}
                      className="WM_GLOBAL_heading20"
                      onChange={(newTitle: string) => {
                        Actions.changeBlockTitle(newTitle, idx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeTitleAlignment(alignment, idx)
                      }
                    />
                    <If
                      condition={
                        isEditing ||
                        (!isValidEmail(block.paragraph) &&
                          !isValidPhoneNumber(block.paragraph))
                      }
                      otherwise={() => (
                        <S.A
                          href={`${
                            isValidEmail(block.paragraph) ? 'mailto:' : 'tel:'
                          }${block.paragraph}`}
                          className="WM_GLOBAL_paragraph18"
                          alignment={block.paragraphAlignment}
                        >
                          {block.paragraph}
                        </S.A>
                      )}
                      then={() => (
                        <EditableContent
                          areControlsHidden
                          as={S.BlockParagraph}
                          text={block.paragraph}
                          required={!block.title}
                          maxCount={validation.blockParagraphMaxChar}
                          isEditing={isEditing}
                          alignment={blocks[idx].paragraphAlignment}
                          className="WM_GLOBAL_paragraph18"
                          onChange={(newParagraph: string) =>
                            Actions.changeBlockParagraph(newParagraph, idx)
                          }
                          changeAlignment={(alignment: string) =>
                            Actions.changeParagraphAlignment(alignment, idx)
                          }
                        />
                      )}
                    />
                  </S.ContentWrap>
                </ControlsWithModalOpener>
              </S.ItemWrap>
            )}
          />
        </S.FeatureItemWrap>
      </S.WMCustomContainer>
      <AddButton
        onAdd={Actions.addBlock}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '20px'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Contact11
