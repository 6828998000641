/* @flow */
import React, { useContext, memo } from 'react'
import Swipe from '../Swipe'
import ActionGetter from './Actions'
import S from './Testimonials3.styled'
import { validation } from './Testimonials3.consts'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import LargeQuote from '@website/common/components/LargeQuote'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import Arrow from '@editor/common/assets/svgr-icons/arrow_big_left.svg'
import { translate } from '@editor/common/utils/translations'

const Testimonials3 = memo(props => {
  const {
    data: { slides }
  } = props
  const { isEditing } = useContext(EditingContext)
  const renderLeftIcon = () => (
    <TooltipWrapper text="Previous" color="#252E48" position="top">
      <S.SliderArrow isEditing={isEditing} className="wm-arrow left">
        <Arrow />
      </S.SliderArrow>
    </TooltipWrapper>
  )
  const renderRightIcon = () => (
    <TooltipWrapper text="Next" color="#252E48" position="top">
      <S.SliderArrow isRight isEditing={isEditing} className="wm-arrow right">
        <Arrow />
      </S.SliderArrow>
    </TooltipWrapper>
  )
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const renderDots = (isActive: boolean) => <S.Dot isActive={isActive} />
  const renderContent = (idx: number) => {
    const {
      data: { slides }
    } = props
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.SliderWrapper isEditing={isEditing}>
              <ControlsWithModalOpener
                actions={Actions.sliderActions({
                  idx,
                  showRemove: slides.length > validation.slides.min
                })}
                style={{ width: '100%' }}
              >
                <S.TextContainer>
                  <EditableContent
                    as={S.SliderTitle}
                    maxCount={validation.slideTitleMaxChar}
                    text={slides[idx].slideTitle}
                    alignment={slides[idx].slideTitleAlignment}
                    className="WM_GLOBAL_paragraph18"
                    onChange={(newTitle: string) => {
                      Actions.changeSlideTitle(newTitle, idx)
                    }}
                    changeAlignment={(alignment: string) =>
                      Actions.changeSlideTitleAlignment(alignment, idx)
                    }
                    isEditing={isEditing}
                  />
                  <EditableContent
                    as={S.SliderParagraph}
                    required={validation.slides.slideParagraph.required}
                    alignment={slides[idx].slideParagraphAlignment}
                    maxCount={validation.slideParagraphMaxChar}
                    text={slides[idx].slideParagraph}
                    className="WM_GLOBAL_heading32"
                    onChange={(newParagraph: string) => {
                      Actions.changeSlideParagraph(newParagraph, idx)
                    }}
                    changeAlignment={(alignment: string) =>
                      Actions.changeSlideParagraphAlignment(alignment, idx)
                    }
                  />
                </S.TextContainer>
              </ControlsWithModalOpener>
            </S.SliderWrapper>
          )
        }}
      </EditingContext.Consumer>
    )
  }

  return (
    <S.StyledContainer isEditing={isEditing}>
      <WMContainer>
        <S.ImageSlideContainer isEditing={isEditing}>
          <LargeQuote />
          <Swipe
            autoplaySpeed={5000}
            dataArray={slides}
            renderDots={renderDots}
            renderContent={renderContent}
            renderLeftIcon={renderLeftIcon}
            renderRightIcon={renderRightIcon}
            arrowControlClassName="slider-arrow-control"
          />
        </S.ImageSlideContainer>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={slides.length < validation.slides.max}
          style={{ marginTop: '2rem' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Testimonials3
