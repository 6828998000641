import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    sliderParagraph: p => p.accentWhite,
    sliderLine: p => p.tertiaryLight,
    sliderLineActive: p => p.primaryLight,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    tooltipText: p => p.accentWhite,
    buttonText: p => p.tertiaryDark,
    buttonTextActive: p => p.accentWhite,
    buttonColorActive: p => p.primaryLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.primaryDark,
    sliderParagraph: p => p.tertiaryDark,
    sliderLine: p => p.tertiarySemiLight,
    sliderLineActive: p => p.primaryDark,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    tooltipText: p => p.accentWhite,
    buttonText: p => p.accentWhite,
    buttonTextActive: p => p.accentWhite,
    buttonColorActive: p => p.primaryLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    sliderParagraph: p => p.tertiaryDark,
    sliderLine: p => p.tertiarySemiLight,
    sliderLineActive: p => p.primaryDark,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    tooltipText: p => p.accentWhite,
    buttonText: p => p.accentWhite,
    buttonTextActive: p => p.accentWhite,
    buttonColorActive: p => p.primaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
