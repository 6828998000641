/* @flow */
/* eslint-disable max-statements */
import {
  cropableImageModalActionMaker,
  socialActionMaker
} from '@website/components/ActionMakers'

import { changeTitle, changeParagraph, changeTitleAlignment, changeParagraphAlignment } from '@website/components/CommonActions'

export default dispatcher => {
  const changeName = (newName: string, idx) => {
    dispatcher({
      type: 'CHANGE_NAME',
      value: { newName, idx }
    })
  }
  const changePosition = (newPosition: string, idx) => {
    dispatcher({
      type: 'CHANGE_POSITION',
      value: { newPosition, idx }
    })
  }
  const changeSlogan = (newSlogan: string, idx) => {
    dispatcher({
      type: 'CHANGE_SLOGAN',
      value: { newSlogan, idx }
    })
  }

  const sliderActions = ({ idx, onEditClick, imageUrl }) =>
    cropableImageModalActionMaker(dispatcher)({
      showRemove: false,
      additionalArgs: { idx },
      clickHandlers: {
        change: onEditClick,
        crop: onEditClick
      },
      imageUrl
    })

  const imageActions = (idx: number, showRemove, imageUrl) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'team6',
      showRemove,
      additionalArgs: { idx },
      imageUrl
    })

  const addSlide = (activeIdx: number) => {
    dispatcher({
      type: 'ADD_SLIDE',
      value: activeIdx
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const socialActions = (
    socialIdx: number,
    socialUrl: string,
    blockIdx: number
  ) => socialActionMaker(dispatcher)(socialIdx, socialUrl, { blockIdx })

  const addSocial = (idx: number) => {
    dispatcher({
      type: 'ADD_SOCIAL',
      value: idx
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeNameAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changePositionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_POSITION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeSloganAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_SLOGAN_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeName,
    changePosition,
    changeSlogan,
    imageActions,
    addSlide,
    getSliderActions: sliderActions,
    addSocial,
    socialActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNameAlignment,
    changePositionAlignment,
    changeSloganAlignment
  }
}
