import styled from 'styled-components'

export const ChartWrapper = styled.div`
  width: 50px;
  height: 30px;
`

export const ZeroChart = styled.div`
  height: 1px;
  width: 40px;
  background-color: rgb(80, 174, 244);
  margin: 0 auto;
`
