import styled, { css } from 'styled-components'
import { primary, accentRed } from '@website/common/styles/colors'

export const SmallBgImageWrapper = styled.figure`
  width: 30px;
  height: 30px;
  margin: 0 0 0 4px;
  position: relative;
  border-radius: 6px;
  box-sizing: border-box;
  border: ${({ failed }) => `1px solid ${failed ? accentRed : '#c1c9e0'}`};
  flex: none;
  cursor: pointer;

  img {
    border-radius: 5px;
    width: 100%;
    height: 100%;
  }
  .failed-image {
    background: #f5f7ff;
    border: 1px solid #cfe0ff;
    box-sizing: border-box;
  }
`

export const BgRemoveIconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border: 1px solid ${primary.Light};
  border-radius: 50%;
  top: -50%;
  right: -50%;
  background-color: #ffffff;
  > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    width: 14px;
    height: 14px;
  }
  @media only screen and (max-width: 768px) {
    svg {
      width: 18px;
      height: 18px;
    }
  }
`

export const BgChangeButton = styled.div`
  position: relative;
  display: flex;
  margin-left: 8px;
  cursor: pointer;
  svg {
    fill: #545f7e;
    margin-right: 15px;
  }
`

export const TransparencyControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  padding: 2px;

  svg {
    fill: #545f7e;
  }

  ${({ isTransparent }) =>
    isTransparent
      ? css`
          border-radius: 50%;
          border: 1px solid #3271e6;
        `
      : ''}
`
