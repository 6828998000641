/* @flow */
import React, { useContext, memo } from 'react'
import S from './Email1.styled'
import THEME from './Email1.theme'
import { validation } from './Email1.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { SubscribeForm } from '../SubscribeForm'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'

const Email1 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      placeholder,
      titleAlignment,
      paragraphAlignment,
      buttonText,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer isEditing={isEditing}>
        <Show when={[title, paragraph]}>
          <S.EmailContent className="email-content">
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              maxCount={validation.maxTitleChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading"
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.maxParagraph}
              onChange={Actions.changeParagraph}
              className="WM_GLOBAL_paragraph"
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.EmailContent>
        </Show>
        <S.SubscribeFormWrap className="subscribe-form-wrap">
          <SubscribeForm
            actions={{
              buttonActions: Actions.getBtnActions(buttonText),
              placeHolderAction: text => Actions.changePlaceHolderText(text)
            }}
            buttonText={buttonText}
            placeholder={placeholder}
            theme={THEME}
          />
        </S.SubscribeFormWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Email1
