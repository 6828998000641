// @flow
import React, { useCallback, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { createPortal } from 'react-dom'
import { translate } from '@editor/common/utils/translations'
import { capitalizeFirstLetter } from '@src/editor/common/utils/text-transformation'

import * as Styled from './styled'
import {
  Button,
  ButtonSection,
  CategoriesFooter,
  CategoriesHeader,
  CloseButton,
  MobileCategoriesWrapper,
  SlideUp,
  SlideUpWrapper
} from '@editor/common/styled-components/choose-component-template'
import type { TTemplatesCategoriesProps } from './types'
import { Category } from './components'
import { EDITOR_BASE } from '@editor/conf/routes'
import { useBackdropClick, useHistoryWithSearch } from '@hooks'
import { useResponsive } from '@shared/hooks'
import { selectTemplatesCategories } from '@selectors'
import { fetchTemplateCategories } from '@actions/editor'

const TemplatesCategories = ({
  categories,
  isMobileCategoriesOpen,
  setMobileCategoriesState,
  fetchTemplateCategories
}: TTemplatesCategoriesProps) => {
  const isMobileAndTablet = useResponsive(1024)

  const slideUpRef = useBackdropClick(() => {
    setMobileCategoriesState(false)
  })

  useEffect(() => {
    fetchTemplateCategories()
  }, [])

  const { url } = useRouteMatch(EDITOR_BASE)
  const history = useHistoryWithSearch()
  const { state = {}, pathname } = useLocation()
  const {
    backTo = pathname.includes('start-creation')
      ? 'start-creation/add-component/home/0'
      : 'edit/home/add-component/0'
  } = state

  const onComponentsBtnClick = useCallback(() => {
    history.push(`${url}/${backTo}`)
  }, [url, backTo])

  const closeSlideUp = useCallback(() => {
    setMobileCategoriesState(false)
  }, [])

  const content = useMemo(
    () => (
      <>
        <Styled.Categories>
          {categories.payload.map(categoryData => {
            return (
              <Category
                key={categoryData.id}
                categoryData={categoryData}
                closeMobileCategories={closeSlideUp}
              />
            )
          })}
        </Styled.Categories>
        <CategoriesFooter>
          <ButtonSection>
            <Button onClick={onComponentsBtnClick}>
              {translate('explore_components_label')}
            </Button>
          </ButtonSection>
        </CategoriesFooter>
      </>
    ),
    [categories, onComponentsBtnClick]
  )

  return isMobileAndTablet ? (
    createPortal(
      <SlideUpWrapper isSlideUpOpen={isMobileCategoriesOpen}>
        <SlideUp ref={slideUpRef} isSlideUpOpen={isMobileCategoriesOpen}>
          <MobileCategoriesWrapper>
            <CategoriesHeader>
              <CloseButton
                name="close"
                color="secondarySemiLight"
                size="medium"
                onClick={closeSlideUp}
              />
            </CategoriesHeader>
            {content}
          </MobileCategoriesWrapper>
        </SlideUp>
      </SlideUpWrapper>,
      document.body
    )
  ) : (
    <Styled.TemplatesCategoriesWrapper>
      <Styled.Header>
        <h2>{capitalizeFirstLetter(translate('templates_label'))}</h2>
      </Styled.Header>
      {content}
    </Styled.TemplatesCategoriesWrapper>
  )
}

const mapStateToProps = state => ({
  categories: selectTemplatesCategories(state)
})

const mapDispatchToProps = {
  fetchTemplateCategories
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesCategories)
