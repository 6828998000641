import styled from 'styled-components'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider({})

export const Title = styled.h2`
  font-size: 20px !important;
  margin-bottom: 10px !important;
  color: ${({ theme }) => theme.palette.primaryDark};
`

export const Description = styled.p`
  font-size: 18px !important;
  font-weight: 500 !important;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.tertiaryDark};
`

export const PopupContent = styled.div`
  width: 420px;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('cardRadius')};
  background: #ffffff;
  padding: 50px 30px;
  text-align: center;
  @media only screen and (max-width: 457px) {
    padding: 40px 20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

export const AdditionalLinkWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primaryDark};
  svg {
    margin-left: 0;
    padding-left: 5px;
    min-width: 24px;
    transition: transform linear 0.15s;
    fill: ${({ theme }) => theme.palette.primaryDark};
  }
  .additional-link {
    align-items: center;
    font-weight: 500;
    font-size: 16px;
  }
  &:hover {
    opacity: 0.7;
    svg {
      opacity: 0.7;
      transform: translateX(5px);
    }
  }
`
