// @flow
import React, { useState, useEffect } from 'react'
import { SvgIcon } from './styled.js'

import WarningIcon from '@website/common/assets/svgr-icons/warning.svg'
import { SOCIAL_ICONS } from './consts'

const getIconTypeAndName = name => {
  if (name.startsWith('social-') || SOCIAL_ICONS.has(name)) {
    return {
      type: 'socialIcons',
      name: name.replace(/^social-/, '').replace(/^icon-/, '')
    }
  } else if (name.startsWith('outline-')) {
    return {
      type: 'outlineIcons',
      name: name.replace(/^outline-/, '')
    }
  } else {
    return {
      type: 'glyphIcons',
      name: name.replace(/^glyph-/, '').replace(/^icon-/, '')
    }
  }
}

const Icon = ({ name = '', SvgComp, ...restProps }) => {
  const dateObj = new Date()
  const [time, setTime] = useState(dateObj.getTime())
  const { type, name: _name } = getIconTypeAndName(name)

  if (typeof window === 'undefined') {
    // Store icon name for exporter
    if (typeof global !== 'undefined') {
      if (!global.icons) {
        global.icons = {
          socialIcons: [],
          outlineIcons: [],
          glyphIcons: []
        }
      }
      if (_name && global.icons[type].indexOf(_name) === -1) {
        global.icons[type].push(_name)
      }
    }

    return null
  }
  const { ICONS } = window

  useEffect(() => {
    if (!ICONS || !Object.keys(ICONS).length) {
      setTimeout(() => {
        const dateObj = new Date()
        const newTime = dateObj.getTime()
        setTime(newTime)
      }, 1000)
    }
  }, [time, ICONS, setTime])

  if (!ICONS || !Object.keys(ICONS).length) {
    return null
  }

  let color = restProps.color
  let IconModule = ICONS[type][_name]
  let Comp = null

  if (SvgComp) {
    Comp = SvgComp
  } else if (IconModule) {
    Comp = IconModule.default
  } else {
    color = 'red'
    Comp = WarningIcon
  }

  return (
    <SvgIcon {...restProps} color={color} data-name={name}>
      <Comp />
    </SvgIcon>
  )
}

export default Icon
