import styled, { createGlobalStyle } from 'styled-components'
import { mediaMaxWidth } from '@editor/common/utils/styled'
const VALUE_COLOR = '#363f5a'

export const GlobalStyles = createGlobalStyle`
  .pages-flyout_container {
    border-radius: 6px;
    box-shadow: 0px 3px 12px #387dff33;
  }
`

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  max-width: 250px;
  ${mediaMaxWidth(800)`
     flex-grow: 0;
  `}
`

export const Label = styled.span`
  font-size: 14px;
  margin-right: 10px;
  color: #545f7e;
  ${mediaMaxWidth(960)`
    display: none;
  `}
`

export const ValueContainer = styled.div`
  display: flex;
  height: 40px;
  width: 160px;
  box-sizing: border-box;
  background-color: ${({ isOpen }) => (isOpen ? 'white' : '#f8fbff')};
  border: 1px solid #dee3f0;
  border-radius: 6px;
  align-items: center;
  padding: 0 15px;
  & svg {
    fill: ${VALUE_COLOR};
  }
`

export const Value = styled.p`
  display: inline-flex;
  flex-grow: 1;
  align-items: center;
  width: calc(100% - 20px);
  & > span {
    font-size: 14px;
    color: ${VALUE_COLOR};
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.placeholder {
      color: #9aa6c8;
    }
  }

  & > svg {
    margin-right: 5px;
  }
`

export const OpenIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  & > svg {
    fill: #363f5a;
    transition: transform 0.3s ease;
    transform: rotate(${({ isOpen }) => (isOpen ? 0 : 180)}deg);
  }
`
