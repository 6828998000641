export const SIZE_LIMITS = {
  mobile: {
    minWidth: 10,
    maxWidth: 200,
    minHeight: 20,
    maxHeight: 70
  },
  tablet: {
    minWidth: 10,
    maxWidth: 300,
    minHeight: 20,
    maxHeight: 90
  },
  desktop: {
    minWidth: 10,
    maxWidth: 400,
    minHeight: 20,
    maxHeight: 115
  }
}

export const MIN_STICKY_HEIGHT = 50
