/* @flow */
import authMiddleware from './authentication'
import thunkMiddleware from './thunk'
import historyMiddleware from './history'
import { visualParamsMiddleware, sendRevisionMiddleware } from './revision'
export default [
  sendRevisionMiddleware,
  visualParamsMiddleware,
  authMiddleware,
  historyMiddleware,
  thunkMiddleware
]
