/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header24.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  overflow: hidden;
  position: relative;
  padding: 0 0 40px 0;
  ${({ theme }) =>
    theme.orientation === 'RIGHT'
    &&
    css`
      @media only screen and (max-width: 1200px) {
        padding-bottom: 80px;
      }
    `
  }
  @media only screen and (max-width: 457px) {
    padding: 0 0 60px 0;
  }
`

const stylesByColor = {
  default: '',
  dark: css`
    filter: brightness(0.17);
  `,
  light: css`
    filter: brightness(0.97);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

const WmCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset + 120}px`};
  position: relative;
  min-height: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 1200px) {
    ${({ theme, topOffset }) =>
    css`
        ${theme.orientation === 'LEFT'
        ? css`
              padding-top: ${topOffset + 80}px;
              flex-direction: column;
            `
        : css`
              paddiing-top: ${topOffset + 70}px;
              flex-direction: column-reverse;
            `}
      `}
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 650px) {
    align-items: center;
    ${({ theme, topOffset }) =>
    css`
        padding-top: ${theme.orientation === 'LEFT'
        ? `${topOffset + 60}px`
        : `${topOffset + 60}px`};
      `}
  }
`

const HeaderContent = styled.div`
  width: 50%;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 60px 0 0;
  text-align: left;
  position: relative;
  z-index: 2;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      text-align: right;
      align-items: flex-end;
      margin: 0 0 0 60px;
    `}
  @media only screen and (max-width: 1200px) {
    width: 100%;
    margin: 0;
    ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
        margin: 10vw 0 0 0;
      `}
    max-width: 700px;
    align-items: center;
    text-align: center;
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    h2,
    p {
      margin-top: 0;
      padding-top: 0;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const SubTitle = styled.h2`
  width: 100%;
  color: ${getStyleForProp('subTitle')};
  font-weight: 700;
  padding-top: 10px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  padding-top: 20px;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const MorphSmWrapper = styled.div`
  position: absolute;
  left: -12%;
  top: -20%;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      left: unset;
      right: -12%;
    `}
  @media only screen and (max-width: 768px) {
    right: unset;
    left: -30%;
  }
  @media only screen and (max-width: 500px) {
    left: -80%;
  }
`

const MorphLgWrapper = styled.div`
  position: absolute;
  right: 0;
  top: -40px;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      right: unset;
      left: 0;
      transform: scaleX(-1);
    `}
  @media only screen and (max-width: 1440px) {
    width: 50%;
  }
  @media only screen and (max-width: 1200px) {
    width: 1px;
    height: 1px;
    margin: -1px;
    overflow: hidden;
  }
`

const MorphPlaceholderWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 640px;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

const MorphMobileWrapper = styled.div`
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  @media only screen and (max-width: 1200px) {
    position: absolute;
    width: 100%;
    height: auto;
    margin: auto;
    overflow: visible;
    svg {
      width: 100%;
      transform: translateY(-15%);
      @media only screen and (max-width: 667px) {
        ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
            transform: translateY(-8%);
          `}
      }
    }
  }
  @media only screen and (max-width: 667px) {
    top: -20px;
  }
  @media only screen and (max-width: 600px) {
    top: -50px;
  }
  @media only screen and (max-width: 500px) {
    top: -80px;
  }
`

const FrameWrap = styled.div`
  width: 100%;
  max-width: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    max-width: 450px;
  }
  @media only screen and (max-width: 1200px) {
    margin-top: 80px;
  }
  @media only screen and (max-width: 550px) {
    max-width: 250px;
  }
`

const NotebookImg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const NoteBookHeader = styled.div`
  width: 50%;
  max-width: 670px;
  padding: 20px 75px 160px 75px;
  box-sizing: border-box;
  border-radius: 18px;
  width: 100%;
  position: relative;
  z-index: 2;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
        margin-top: 40px;
      `}
  }
  @media only screen and (max-width: 667px) {
    padding: 15px 55px 100px 55px;
    ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
        margin-top: 20px;
      `}
  }
  @media only screen and (max-width: 450px) {
    padding: 12px 45px 80px 45px;
  }
  &:before {
    content: ' ';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/notebook-light-2.png');
    background-repeat: no-repeat;
    background-size: 100% auto;
    ${getStyleByColor};
  }
`
const NotebookWrap = styled.div`
  width: 100%;
  position: relative;
  padding-top: 62.25%;
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .styled-controls {
    top: -68px;
  }
  .image-state-overlay {
    z-index: 1;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  SubTitle,
  Paragraph,
  MorphSmWrapper,
  MorphLgWrapper,
  MorphPlaceholderWrap,
  MorphMobileWrapper,
  NotebookWrap,
  NoteBookHeader,
  FrameWrap,
  NotebookImg
}
