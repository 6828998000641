//@flow
import React, { memo, useCallback, useContext } from 'react'

import { ControlModalsContext, MobileDetectContext } from '@contexts'
import FlyOutMenu from '@editor/common/lib/FlyOutMenu/FlyOutMenu'
import { _debounce } from '@website/common/utils'
import { MODAL_TYPES } from '@website/common/components/Controlled/ControlModals/consts'

import FlyoutContentMobile from './FlyoutContentMobile'
import FlyoutContentDesktop from './FlyoutContentDesktop'

const TransparencyFlyout = () => {
  const isMobile = useContext(MobileDetectContext)
  const { modalStates, setModalState } = useContext(ControlModalsContext)

  const {
    targetRef,
    alignment,
    shift,
    isFlyoutPositionFixed,
    bgOverlayTransparency,
    controlClassName,
    handler
  } = modalStates[MODAL_TYPES.transparencyFlyout]

  const onClose = useCallback(() => {
    setModalState(MODAL_TYPES.transparencyFlyout, {
      isOpen: false
    })
  }, [])

  const debouncedHandler = _debounce(transparency => {
    handler(1 - transparency)
  }, 150)

  const onRangeSliderChange = useCallback(
    transparency => {
      debouncedHandler(transparency)
    },
    [debouncedHandler]
  )

  const stopPropagation = useCallback((e) => {
    e.stopPropagation()
  }, [])

  return (
    <FlyOutMenu
      isOpen
      onClose={onClose}
      desktopProps={{
        alignment,
        shift,
        className: 'transparency-flyout',
        withAnimation: false,
        isPositionFixed: isFlyoutPositionFixed,
        anchorEl: targetRef && targetRef.current,
        renderingContainer: targetRef && targetRef.current,
        backdropDisablingSelectors: [`.${controlClassName}`],
        onClick: stopPropagation
      }}
    >
      {isMobile ? (
        <FlyoutContentMobile
          bgOverlayTransparency={bgOverlayTransparency}
          onRangeSliderChange={onRangeSliderChange}
          onClose={onClose}
        />
      ) : (
        <FlyoutContentDesktop
          alignment={alignment}
          bgOverlayTransparency={bgOverlayTransparency}
          onRangeSliderChange={onRangeSliderChange}
        />
      )}
    </FlyOutMenu>
  )
}

export default memo(TransparencyFlyout)
