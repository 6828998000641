import styled, { createGlobalStyle } from 'styled-components'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { secondary, accentRed } from '@website/common/styles/colors'

export const GlobalStyles = createGlobalStyle`
  .transparency-control_content {
     & > div {
       &:before {
         content: '';
         width: 0;
         height: 0;
         border-left: 7px solid transparent;
         border-right: 7px solid transparent;
         border-bottom: 8px solid #252E48;
         position: absolute;
         top: -8px;
         left: 50%;
         transform: translateX(-50%);
       };
       
       background-color: #252E48;
       max-width: none;
       padding: 18px 10px;
       border-radius: 5px;
       p {
         color: #FFFFFF;
         font-size: 12px;
         font-weight: 500;
         flex-shrink: 0;
         margin-right: 10px;
       }
     }
  }
  
  .bg-change-control {
    position: relative;
    margin-left: 2px;
    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }
`

export const Controls = styled.div`
  display: flex;
  align-items: center;

  & > div:not(:first-child, :last-child) {
    margin-right: 6px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    padding: 20px;

    & > img {
      width: 50px;
      height: 50px;
      margin: 0 auto 20px;
    }

    & > div:not(:first-child, :last-child) {
      margin-right: 0;
    }

    & > div:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const BackgroundControl = styled.div`
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Image = styled.img`
  width: 30px;
  height: 30px;
  background-color: #f5f7ff;
  border-radius: 5px;
  border: ${({ failed }) => `1px solid ${failed ? accentRed : '#c1c9e0'}`};
  box-sizing: border-box;
  object-fit: cover;
`

export const VideoContainer = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  pointer-events: none;
  border-radius: 5px;
  border: ${({ hasError }) => `1px solid ${hasError ? accentRed : '#c1c9e0'}`};
  border: 1px solid #c1c9e0;
  box-sizing: border-box;
  & > div {
    width: 100% !important;
    height: 100% !important;
  }
`

export const BgVideoWarningIcon = styled(Icon)`
  padding: 5px;
  border: 1px solid ${accentRed};
  border-radius: 5px;
  box-sizing: border-box;
`

export const TransparencyFlyoutContent = styled.div`
  display: flex;
  align-items: center;
`

export const UploadingContent = styled.div`
  transform: scale(0.8);
  width: 100%;
`

export const FailedContent = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 15px 0;
  & > img {
    width: 25px;
    height: auto;
  }
  & > span {
    font-size: 14px;
    margin-left: 5px;
  }
`

export const UploadStatusTooltip = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translate(calc(-50% + 20px), calc(100% + 18px));
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${secondary.ExtraDark};
  color: white;
  border-radius: 5px;
  box-shadow: 0 0 9px #497ef733;

  @media only screen and (max-width: 768px) {
    width: 170px;
  }
`

export const Triangle = styled.span`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 6px 0 6px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%) rotate(180deg);
  border-color: ${secondary.ExtraDark} transparent transparent transparent;
`

export const MobileTransparencyContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
  box-sizing: border-box;

  & > p {
    padding-bottom: 30px;
  }

  & > button {
    position: absolute;
    bottom: 15px;
    right: 0;
    font-size: 14px;
    font-weight: 600;
    color: #3271e6;
    background-color: #ffffff;
    border: none;
    outline: none;
  }

  & > input {
    &::-webkit-slider-thumb {
      transform: scale(1.5);
      box-shadow: 0 0 0 3px #387dff4d;
      border-radius: 50%;
    }

    &::-moz-range-thumb {
      transform: scale(1.5);
      box-shadow: 0 0 0 3px #387dff4d;
      border-radius: 50%;
    }
  }
`
