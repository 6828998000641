/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Clients4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import LibIcon from '@website/common/components/Icon'
import PrevIcon from '@website/common/assets/svgr-icons/keyboard_arrow_left.svg'
import NextIcon from '@website/common/assets/svgr-icons/keyboard_arrow_right.svg'
import { getSlidesToShow } from './utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)}
  flex-direction: column;
  ${({ isEditing }) => (isEditing ? 'padding-top: 130px' : null)};
`

const WMCustomContainer = styled(WMContainer)`
  width: 90% !important;
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1024px) {
    width: 100% !important;
  }
  @media only screen and (max-width: 800px) {
    padding-top: ${({ isEditing }) => (isEditing ? '0' : null)};
  }
  @media only screen and (max-width: 767px) {
    padding-top: ${({ isEditing }) => (isEditing ? '0' : null)};
  }

  .slick-list {
    margin-left: auto;
    margin-right: auto;
  }

  .slick-slide {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    width: 100%;
    > div {
      margin: 0 !important;
    }
  }

  .add-button-wrap {
    margin-top: 30px;
  }

  & .arrowButton {
    background: ${getStyleForProp('buttonBackground')};
    width: 36px;
    height: 36px;
    margin: 10px;
    border-radius: 50%;
  }

  & .slick-arrow.slick-arrow {
    display: block !important;
    background: ${getStyleForProp('buttonBackground')};
    width: 36px;
    height: 36px;
    top: calc(${({ isEditing }) => (!isEditing ? '50% + 15px' : '50% + 13px')});
    border-radius: ${({ theme }) =>
      theme.look === 'ROUNDED'
        ? '50%'
        : theme.look === 'CORNERED'
        ? '10px'
        : '0'};
    border: none !important;
    z-index: 2;
  }

  & > .control-container {
    max-width: 900px;
    margin: 0 auto 20px auto;
    @media only screen and (max-width: 1024px) {
      max-width: 100%;
    }
    & > h1,
    p {
      max-width: unset;
      margin: 0;
    }
    &:nth-last-child(1) {
      margin-bottom: 0px;
    }
  }
`

const PrevIcons = styled(PrevIcon)`
  fill: #ffffff;
  width: 24px;
  height: 36px;
`

const NextIcons = styled(NextIcon)`
  fill: #ffffff;
  width: 24px;
  height: 36px;
`

const WMSliderWrapper = styled(WMCustomContainer)`
  min-width: 220px;
  position: relative;
  max-width: ${({ slidesCount }) => `
    ${getSlidesToShow(slidesCount) * 235}px
  `};

  ${({ isEditing }) =>
    isEditing
      ? css`
          .slick-slide {
            padding: 120px 30px 90px 30px;
            @media only screen and (max-width: 768px) {
              padding: 120px 20px 90px 20px;
            }
          }
        `
      : css`
          .slick-slide {
            padding: 50px 30px 0px 30px;
            @media only screen and (max-width: 768px) {
              padding: 40px 20px 0px 20px;
            }
          }
        `};
  @media only screen and (max-width: 1250px) {
    max-width: ${({ slidesCount }) =>
      `${getSlidesToShow(slidesCount, 1250) * 235}px`};
  }
  @media only screen and (max-width: 768px) {
    max-width: ${({ slidesCount }) =>
      `${getSlidesToShow(slidesCount, 768) * 235}px`};
  }

  @media only screen and (max-width: 550px) {
    max-width: 300px !important;
    min-width: auto;
  }

  .slick-prev {
    left: -45px !important;
    @media only screen and (max-width: 768px) {
      left: -55px !important;
    }
    @media only screen and (max-width: 550px) {
      left: -35px !important;
    }
  }

  .slick-next {
    right: -45px !important;
    @media only screen and (max-width: 768px) {
      right: -55px !important;
    }
    @media only screen and (max-width: 550px) {
      right: -35px !important;
    }
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  max-width: 900px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  & + ${Description} {
    margin-top: 20px;
  }
`

const InnerSliderItem = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  padding: 20px;
  background: #ffffff;
  width: 180px !important;
  height: 160px;
  box-sizing: border-box;
  box-shadow: ${({ theme }) =>
    `0 9px 22px 0 ${`rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`}`};
  &:hover {
    outline: none;
  }
`

const Img = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  ${({ hasUrl }) =>
    hasUrl &&
    css`
      cursor: pointer;
    `}
`

const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  margin: 0 auto;
  position: relative;
`

export const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('iconColor')
}))``

export default {
  StyledContainer,
  WMCustomContainer,
  Title,
  Description,
  InnerSliderItem,
  Img,
  ImageFigure,
  WMSliderWrapper,
  PrevIcons,
  NextIcons
}
