/* @flow */
import React, { useContext, memo } from 'react'

import S from './Team7.styled'
import { validation } from './Team7.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const Team7 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      paragraph2,
      paragraph2Alignment,
      paragraph3,
      paragraph3Alignment,
      paragraph4,
      paragraph4Alignment,
      slogan,
      sloganAlignment,
      name,
      nameAlignment,
      imageUrl,
      imgDimensions,
      imgCropParams
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <Show when={[title, paragraph]}>
        <S.Hgroup>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            className="WM_GLOBAL_heading42"
            maxCount={validation.headingMaxChar}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            as={S.Paragraph}
            text={paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph14 main-paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.Hgroup>
      </Show>
      <S.WmCustomContainer>
        <S.TextImageContent>
          <S.ImageWrapper>
            <S.ControlContainer>
              <ControlsWithImageCropper
                actions={Actions.getImgActions(imageUrl)}
                style={{ width: '100%' }}
              >
                <Image
                  as={S.Img}
                  sizes={imgDimensions}
                  defaultImgSrc={imageUrl}
                  asProps={{
                    imgCropParams
                  }}
                  alt="Feature illustration"
                />
              </ControlsWithImageCropper>
            </S.ControlContainer>
          </S.ImageWrapper>
          <Show when={[slogan, name]}>
            <S.SloganWrapper className="hidden-mobile">
              <EditableContent
                as={S.Slogan}
                text={slogan}
                alignment={sloganAlignment}
                required={validation.slogan.required}
                maxCount={validation.sloganMaxChar}
                className="WM_GLOBAL_heading20"
                onChange={Actions.changeSlogan}
                changeAlignment={Actions.changeSloganAlignment}
              />
              <EditableContent
                as={S.Name}
                text={name}
                alignment={nameAlignment}
                required={validation.name.required}
                maxCount={validation.nameMaxChar}
                className="WM_GLOBAL_paragraph14"
                onChange={Actions.changeName}
                changeAlignment={Actions.changeNameAlignment}
              />
            </S.SloganWrapper>
          </Show>
        </S.TextImageContent>
        <S.ParagraphsWrap>
          <Show when={[slogan, name]}>
            <S.SloganWrapper className="hidden-desktop">
              <EditableContent
                as={S.Slogan}
                text={slogan}
                alignment={sloganAlignment}
                required={validation.slogan.required}
                maxCount={validation.sloganMaxChar}
                className="WM_GLOBAL_heading20"
                onChange={Actions.changeSlogan}
                changeAlignment={Actions.changeSloganAlignment}
              />
              <EditableContent
                as={S.Name}
                text={name}
                alignment={nameAlignment}
                required={validation.name.required}
                maxCount={validation.nameMaxChar}
                className="WM_GLOBAL_paragraph14"
                onChange={Actions.changeName}
                changeAlignment={Actions.changeNameAlignment}
              />
            </S.SloganWrapper>
          </Show>
          <EditableContent
            as={S.Paragraph}
            text={paragraph2}
            alignment={paragraph2Alignment}
            required={validation.paragraph2.required}
            maxCount={validation.contentParagraphMaxChar}
            className="WM_GLOBAL_paragraph18 paragraph2"
            onChange={Actions.changeParagraph2}
            changeAlignment={Actions.changeParagraph2Alignment}
          />
          <EditableContent
            as={S.Paragraph}
            text={paragraph3}
            alignment={paragraph3Alignment}
            maxCount={validation.contentParagraphMaxChar}
            className="WM_GLOBAL_paragraph18 paragraph3"
            onChange={Actions.changeParagraph3}
            changeAlignment={Actions.changeParagraph3Alignment}
          />
          <EditableContent
            as={S.Paragraph}
            text={paragraph4}
            alignment={paragraph4Alignment}
            maxCount={validation.contentParagraphMaxChar}
            className="WM_GLOBAL_paragraph18 paragraph4"
            onChange={Actions.changeParagraph4}
            changeAlignment={Actions.changeParagraph4Alignment}
          />
        </S.ParagraphsWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Team7
