import styled from 'styled-components'
import { styleProvider, hexToRgb } from '@website/common/utils'

const getStyleForProp = styleName => props =>
  styleProvider(props.compTheme)(styleName)(props)

export const Button = styled.button`
  border-radius: ${getStyleForProp('buttonRadius')};
  white-space: nowrap;
  ${getStyleForProp('buttonBackground')};
  background-repeat: no-repeat;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  text-decoration: none;
  padding: 0 40px;
  font-size: 14px;
  letter-spacing: 0.4px;
  cursor: pointer;
  position: relative;
  z-index: 1;
  word-break: initial;
  border: none;
  @media only screen and (min-width: 767px) {
    border: none;
    &:hover {
      border: none;
    }
  }
  @media only screen and (max-width: 650px) {
    margin-top: 30px;
  }
`

export const Input = styled.input`
  border-radius: ${getStyleForProp('buttonRadius')};
  background: ${({ theme }) => theme.palette.accentWhite};
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  background-repeat: no-repeat;
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  font-size: 16px;
  padding: 0 45px 0 30px;
  -webkit-appearance: none;
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
  @media only screen and (max-width: 650px) {
    padding: 0 30px;
    transform: unset;
  }
`

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: #eb0c00;
  font-size: 14px;
  position: absolute;
  bottom: -20px;
  text-align: start;
  @media only screen and (max-width: 650px) {
    left: 0;
  }
`
export const SuccessMessage = styled.p`
  color: #048900;
  font-size: '18px';
  position: absolute;
  display: flex;
  align-items: center;
  bottom: -27px;
  left: 50%;
  transform: translateX(-56%);
  text-align: start;
  white-space: nowrap;
  @media only screen and (max-width: 650px) {
    transform: translateX(-50%);
    bottom: 56px;
    width: 100%;
    text-align: center;
    left: initial;
    transform: none;
    display: flex;
    justify-content: center;
  }
`

export const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

export const SuccessIcon = styled.img`
  width: 19px;
  margin-right: 10px;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 340px;
  transform: translateX(42px);
  @media only screen and (max-width: 650px) {
    transform: unset;
  }
`
