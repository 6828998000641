import React from 'react'
import styled from 'styled-components'
import { withEditingContext } from '@contexts'

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  z-index: 1;
  @media only screen and (max-width: 1024px) {
    display: ${props => (props.isEditing ? 'block' : 'none')};
  }
`

const ControlsMobileOverlay = ({ isEditing }) => {
  return <Overlay isEditing={isEditing} />
}

export default withEditingContext(ControlsMobileOverlay)
