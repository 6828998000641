/* @flow */
import {
  cropableImageModalActionMaker,
  linkActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addBlock,
  removeBlock
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeBlockTitle = (
    idx: number,
    blockIdx: number,
    newTitle: string
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE',
      value: { idx, blockIdx, newTitle }
    })
  }

  const changeItemParagraph = (
    idx: number,
    blockIdx: number,
    newParagraph: string
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { idx, blockIdx, newParagraph }
    })
  }

  const changeBlockTitleAlignment = (
    idx: number,
    blockIdx: number,
    alignment: string
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { idx, blockIdx, alignment }
    })
  }

  const changeBlockParagraphAlignment = (
    idx: number,
    blockIdx: number,
    alignment: string
  ) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { idx, blockIdx, alignment }
    })
  }

  const imageActions = (idx: number, blockIdx: number, imageUrl: string) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'post3',
      additionalArgs: { idx, blockIdx },
      showRemove: false,
      imageUrl
    })

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  const _removeBlock = () => {
    dispatcher(removeBlock())
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeLinkData = value => ({
    type: 'CHANGE_LINK_DATA',
    value
  })

  const removeLink = value => () => ({
    type: 'REMOVE_LINK',
    value
  })

  const linkActions = (idx: number, blockIdx: number, linkData) => [
    ...linkActionMaker(dispatcher)({
      isAdditionalLink: true,
      idx,
      blockIdx,
      linkData,
      actions: {
        change: (_, newLinkData) =>
          changeLinkData({ idx, blockIdx, newLinkData }),
        remove: removeLink({ idx, blockIdx })
      }
    })
  ]

  const addLink = (idx: number, blockIdx: number) => ({
    type: 'ADD_LINK',
    value: { idx, blockIdx }
  })

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addBlock: _addBlock,
    removeBlock: _removeBlock,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    imageActions,
    changeBlockTitle,
    changeItemParagraph,
    linkActions,
    addLink
  }
}
