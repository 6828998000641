/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Text18.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isBgImage }) =>
    !isBgImage &&
    css`
      padding-top: 155px;
      @media only screen and (max-width: 768px) {
        padding-top: 130px;
      }
      @media only screen and (max-width: 650px) {
        padding-top: 95px;
      }
    `}

  ${({ isCenterImage, isBgImage }) =>
    !isCenterImage &&
    !isBgImage &&
    css`
      padding-top: 80px;
      @media only screen and (max-width: 768px) {
        padding-top: 80px;
      }
      @media only screen and (max-width: 650px) {
        padding-top: 60px;
      }
    `}    
      
  
  
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 150px;
          @media only screen and (max-width: 768px) {
            padding-top: 130px;
          }
        `
      : css``};

  flex-direction: column;
  position: relative;
  overflow: hidden;
  .left-triangle {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    @media only screen and (max-width: 600px) {
      bottom: -250px;
    }
  }
  .right-triangle {
    position: absolute;
    right: 0;
    bottom: 0;
    @media only screen and (max-width: 600px) {
      bottom: -100px;
    }
  }

  .add-button-wrap {
    margin: 10px;
  }

  .add-button-wrap + .avatarImage {
    margin-top: 0;
  }

  .avatarImage {
    color: red;
    margin-top: -75px;

    @media only screen and (max-width: 768px) {
      margin-top: -50px;
    }
    @media only screen and (max-width: 650px) {
      margin-top: -35px;
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 40px;
  @media only screen and (max-width: 768px) {
    padding-top: 0px;
  }
  &:nth-child(1) {
    margin-top: 0px;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 20px;
  ${({ isTextGradient }) =>
    isTextGradient
      ? css`
          background: linear-gradient(
            90deg,
            ${getStyleForProp('gradientColorFrom')} 0,
            ${getStyleForProp('gradientColorTo')} 100%
          );
          background-clip: text;
          background-size: 100% auto;
          text-fill-color: transparent;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          &:before,
          &:after {
            -webkit-background-clip: initial;
            -webkit-text-fill-color: initial;
            background: initial;
          }
          & .link-wrapper {
            position: initial;
          }
        `
      : ''}
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  > :last-child {
    margin-bottom: 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;
  width: 100%;
  & > div {
    width: 50%;
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }
`

const TextItemWrap = styled.div`
  box-sizing: border-box;
  padding-bottom: 30px;
  word-break: break-word;
  margin: 0 auto;
  &:nth-last-child(1) {
    padding-bottom: 0px;
  }

  @media only screen and (min-width: 769px) {
    &:nth-child(2n-1)&:last-child {
      padding-bottom: 0;
      width: auto;
      max-width: 50%;
      @media only screen and (max-width: 769px) {
        max-width: 100%;
      }
    }
    &:nth-child(2n) {
      padding-left: 32px;
    }
    &:nth-child(2n-1) {
      padding-right: 32px;
    }
    &:nth-child(2n-1)&:nth-last-child(1) {
      padding-bottom: 0;
    }
    &:nth-child(2n)&:nth-last-child(1) {
      padding-bottom: 0px;
    }
    &:nth-child(2n-1)&:nth-last-child(2) {
      padding-bottom: 0px;
    }
    &:nth-child(2n-1)&:last-child {
      padding-bottom: 0;
      padding-right: 0;
    }
  }
`

const BlockParagraph = styled.p`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 20px;
        `
      : css``};
`

const BlockTitle = styled.h2`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('blockHeading')};
  margin-bottom: 0px;
  & + ${BlockParagraph} {
    margin-top: 20px;
  }
`

const TextItem = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageWrap = styled.div`
  font-size: 0;
  @media only screen and (min-width: 1023px) {
    &:only-child {
      transform: translateX(-50%);
      position: sticky;
      left: 50%;
    }
  }
`
const ImageFigureBG = styled.div`
  text-align: center;
  font-size: 0;
  width: 95%;
  max-width: 1400px;
  margin: 0 auto;
  @media only screen and (max-width: 1440px) {
    width: 90%;
    max-width: 1200px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  .LazyLoad {
    position: relative;
    padding-top: 22%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  border-radius: ${getStyleForProp('iconRadius')};

  @media only screen and (max-width: 768px) {
    max-width: 100px;
  }
  @media only screen and (max-width: 375px) {
    max-width: 70px;
  }
  .LazyLoad {
    position: relative;
    padding-top: 100%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('iconRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Content,
  ContentWrapper,
  TextItemWrap,
  BlockParagraph,
  BlockTitle,
  TextItem,
  Img,
  ImageWrap,
  ImageFigure,
  ImageFigureBG
}
