/* @flow */
import React, {
  useContext,
  memo,
  useRef,
  useState,
  useEffect,
  useCallback
} from 'react'
import { MobileDetectContext } from '@contexts'
import * as Styled from './RSVP7.styled'
import RSVP7Card from './RSVP7.card'
import ActionGetter from './Actions'
import { validation } from './RSVP7.consts'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import useEventRegistration from '@website/common/hooks/useEventRegistration'
import RSVPForm from '@website/common/components/RSVPForm'
import RSVPRegistrationPopup from '@website/common/components/RSVPRegistrationPopup'
import { Controls } from '@website/common/components/Controlled'
import useOnScreen from '@website/common/hooks/useOnScreen'
import { id } from '../Feature1/Feature1.meta'
import { ANIMATION_DURATION } from './RSVP7.consts'

const RSVP7 = memo(props => {
  const {
    data: {
      mainTitle,
      mainTitleAlignment,
      subtitleAlignment,
      dateAlignment,
      locationAlignment,
      paragraphAlignment,
      cardImage,
      titleAlignment,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      envelopeStyle
    },
    eventData,
    uniqId
  } = props

  const eventCardMetaData = {
    cardImage,
    dateAlignment,
    locationAlignment,
    subtitleAlignment,
    titleAlignment,
    paragraphAlignment
  }

  const letterRef = useRef(null)
  const [isLetterOpened, setLetterOpenedState] = useState(false)
  const [shouldShowCloseAnimation, setShouldShowCloseAnimation] =
    useState(false)
  const isMobile = useContext(MobileDetectContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const {
    onClick: onRSVPBtnClick,
    isLoading,
    additionalGuestCount,
    isRegistrationClosedPopupOpen,
    closeRegistrationClosedPopup,
    setGuestLimit,
    formOpenStatus,
    setFormOpenStatus
  } = useEventRegistration(
    eventData.id,
    eventData.form.additionalGuestNumber,
    false
  )

  const isFormOpened = formOpenStatus === 'open'
  const registrationClosedPopupData = eventData.popups.closed
  const isOnScreen = useOnScreen(letterRef, {
    rootMargin: '-55% 0px -45% 0px'
  })

  useEffect(() => {
    if (!isLetterOpened) {
      setLetterOpenedState(isOnScreen)
    }
  }, [isOnScreen, isLetterOpened])

  const onRegistrationPopupClose = useCallback(isOpen => {
    setShouldShowCloseAnimation(isOpen)
    setFormOpenStatus('')
  }, [])

  const formCloseDelay = isMobile ? 0 : ANIMATION_DURATION

  return (
    <Styled.ComponentWrapper
      isEditing={isEditing}
      shouldShowCloseAnimation={shouldShowCloseAnimation}
    >
      <Styled.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <Styled.WmCustomContainer>
          <EditableContent
            text={mainTitle}
            alignment={mainTitleAlignment}
            as={Styled.MainTitle}
            isEditing={isEditing}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeMainTitle}
            changeAlignment={Actions.changeMainTitleAlignment}
          />
          <Styled.LetterWrapper
            isEditing={isEditing}
            isLetterOpened={isLetterOpened}
            isOpen={isFormOpened}
            className={isFormOpened ? 'envelop-open-mode' : ''}
            shouldShowCloseAnimation={shouldShowCloseAnimation}
          >
            <Controls actions={Actions.envelopeActions({ envelopeStyle })}>
              <>
                <Styled.Envelope
                  ref={letterRef}
                  isEditing={isEditing}
                  className="envelope-main"
                  envelopeStyle={envelopeStyle}
                />
                <Styled.EnvelopeTop
                  isEditing={isEditing}
                  className="envelope-top"
                  envelopeStyle={envelopeStyle}
                  shouldShowCloseAnimation={shouldShowCloseAnimation}
                />
                <Styled.EnvelopeFront
                  isEditing={isEditing}
                  envelopeStyle={envelopeStyle}
                />
              </>
            </Controls>
            <Styled.LetterContainer
              isOpen={isFormOpened}
              shouldShowCloseAnimation={shouldShowCloseAnimation}
              className="letter-container"
              isEditing={isEditing}
            >
              <RSVP7Card
                detailsData={eventData.details}
                metaData={eventCardMetaData}
                onRSVPBtnClick={onRSVPBtnClick}
                isLoading={isLoading}
              />
              <RSVPForm
                isFormDivided
                compId={uniqId}
                eventId={eventData.id}
                formData={eventData.form}
                popupsData={eventData.popups}
                detailsData={eventData.details}
                additionalGuestCount={additionalGuestCount}
                setGuestLimit={setGuestLimit}
                needToScrollAfterClosing
                formCloseDelay={formCloseDelay}
                onRegistrationPopupClose={onRegistrationPopupClose}
              />
              {!isEditing ? (
                <RSVPRegistrationPopup
                  isPopupOpen={isRegistrationClosedPopupOpen}
                  title={registrationClosedPopupData.title.text}
                  paragraph={registrationClosedPopupData.paragraph.text}
                  onClose={closeRegistrationClosedPopup}
                />
              ) : null}
            </Styled.LetterContainer>
          </Styled.LetterWrapper>
        </Styled.WmCustomContainer>
      </Styled.StyledContainer>
    </Styled.ComponentWrapper>
  )
})

export default RSVP7
