export default {
  DARK: {
    background: p => p.tertiaryDark,
    raisedShadow: () => 'none',
    title: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    icon: p => p.primaryLight,
    info: p => p.accentWhite
  },
  LIGHT: {
    background: p => p.tertiaryLight,
    title: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    icon: p => p.primaryDark,
    info: p => p.accentWhite
  },
  EXTRALIGHT: {
    background: p => p.tertiaryExtraLight,
    title: p => p.tertiaryDark,
    paragraph: p => p.tertiaryNormal,
    icon: p => p.primaryDark,
    info: p => p.accentWhite
  }
}
