export const validation = {
  headingMaxChar: 60,
  labelMaxChar: 60,
  inputLabelMaxChar: 60,
  workingHoursTitleMaxChar: 30,
  daysMaxChar: 20,
  serviceMaxChar: 30
}

export const EMPTY_FIELD_MESSAGE = 'This field cannot be empty'
export const INVALID_EMAIL_MESSAGE = 'Please enter a valid email'
export const SUCCESS_MESSAGE = 'Thank you for subscribing!'
