/* @flow */
import styled from 'styled-components'
import THEME from './Spotify3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0;
  @media only screen and (max-width: 1024px) {
    flex-direction: column-reverse;
  }
`

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '135px 0 80px 0' : '80px 0')};
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
`

const TextContent = styled.div`
  width: 100%;
  max-width: 530px;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    text-align: center;
    margin-bottom: 40px;
  }
`

const Line = styled.div`
  width: 100%;
  height: 2px;
  margin: 20px 0;
  background-color: ${getStyleForProp('paragraph')};
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.5;
  ${getTextStyleByAlignment};
`

const MusicWrapper = styled.div`
  width: 500px;
  min-width: 500px;
  font-size: 0;
  margin: 0 35px 0 0;
  @media only screen and (min-width: 1023px) {
    &:only-child {
      margin: auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    margin: 0;
    &:only-child {
      margin: 0;
    }
  }
  @media only screen and (max-width: 650px) {
    min-width: initial;
    width: 100%;
  }
`

const MusicContent = styled.div`
  border-radius: ${getStyleForProp('badgeRadius')};
  overflow: hidden;
  position: relative;
  width: 100%;
`

const IframeWrapper = styled.div`
  width: 100%;
  position: relative;
  iframe {
    width: 100%;
    height: 300px;
    border-radius: ${getStyleForProp('badgeRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

export default {
  WmCustomContainer,
  StyledContainer,
  Title,
  Paragraph,
  Line,
  MusicContent,
  IframeWrapper,
  TextContent,
  MusicWrapper
}
