import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    card: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.3)`,
    firstElement: p => p.primaryLight,
    secondElement: p => p.tertiaryNormal,
    thirdElement: p => p.accentWhite,
    fourthElement: p => p.primaryLight
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    backgroundColor: p => p.tertiaryLight,
    firstElement: p => p.primaryDark,
    secondElement: p => p.secondaryDark,
    thirdElement: p => p.tertiaryDark,
    fourthElement: p => p.primaryLight,
    card: p => p.tertiaryLight,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    firstElement: p => p.primaryDark,
    secondElement: p => p.secondaryDark,
    thirdElement: p => p.tertiaryDark,
    fourthElement: p => p.primaryLight,
    card: p => p.accentWhite,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.2)`
  }
}
