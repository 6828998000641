import produce from 'immer'

import { WIDGETS_INITIAL_DATA } from '../consts'

export default (state = WIDGETS_INITIAL_DATA, action) =>
  produce(state, draft => {
    const { messengers, chats } = draft.data
    switch (action.type) {
      case 'SET_WIDGETS_DATA':
        draft.isLoading = false
        draft.data.messengers =
          action.value.messengers || WIDGETS_INITIAL_DATA.data.messengers
        draft.data.chats = action.value.chats || WIDGETS_INITIAL_DATA.data.chats
        break
      case 'SET_WIDGETS_LOADING_STATUS':
        draft.isLoading = action.value
        break
      case 'CHANGE_MESSENGERS_ALIGNMENT':
        messengers.settings.alignment = action.value
        break
      case 'REMOVE_MESSENGER': {
        const messengerName = action.value
        messengers.data = messengers.data.filter(
          messenger => messenger.name !== messengerName
        )
        break
      }
      case 'SET_MESSENGER_VALUE': {
        const { messengerName, value } = action.value
        const targetMessengerIdx = messengers.data.findIndex(
          messenger => messenger.name === messengerName
        )
        if (targetMessengerIdx === -1) {
          messengers.data.push({
            name: messengerName,
            value: value,
            isActive: false
          })
        } else {
          messengers.data[targetMessengerIdx].value = value
        }
        break
      }
      case 'SET_MESSENGER_ACTIVE_STATE': {
        const { messengerName, isActive } = action.value
        const targetMessengerIdx = messengers.data.findIndex(
          messenger => messenger.name === messengerName
        )
        if (targetMessengerIdx !== -1) {
          messengers.data[targetMessengerIdx].isActive = isActive
        }
        break
      }
      case 'SET_MESSENGER_DATA': {
        const { messengerName, data } = action.value
        const targetMessengerIdx = messengers.data.findIndex(
          messenger => messenger.name === messengerName
        )
        if (targetMessengerIdx !== -1) {
          messengers.data[targetMessengerIdx].data = data
        }
        break
      }
      case 'REMOVE_CHAT': {
        const chatName = action.value
        chats.data = chats.data.filter(chat => chat.name !== chatName)
        break
      }
      case 'SET_CHAT_KEY': {
        const { chatName, key } = action.value
        const targetChatIdx = chats.data.findIndex(
          chat => chat.name === chatName
        )
        if (targetChatIdx === -1) {
          chats.data.push({
            key,
            name: chatName,
            isPrimary: false
          })
        } else {
          chats.data[targetChatIdx].key = key
        }
        break
      }
      case 'SET_CHAT_PRIMARY_STATE': {
        const { chatName, isPrimary } = action.value
        chats.data = chats.data.map(chat => {
          const _isPrimary = chat.name === chatName ? isPrimary : false
          return {
            ...chat,
            isPrimary: _isPrimary
          }
        })
        break
      }
      default:
        return state
    }
  })
