/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PrivacyPolicy3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import {
  secondary,
  accentRed,
  accentYellow
} from '@website/common/styles/colors'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
`

const WMCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
`

const CardWrap = styled.div`
  width: 100%;
  max-width: 1400px;
  padding: 50px 100px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  border: ${getStyleForProp('border')};
  @media only screen and (max-width: 1440px) {
    padding-right: 60px;
    padding-left: 60px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 50px 40px;
  }
  @media only screen and (max-width: 767px) {
    padding: 40px 20px;
  }
  @media only screen and (max-width: 450px) {
    padding: 40px 10px;
  }
  @media only screen and (max-width: 768px) {
    .buttons-block {
      margin-right: auto;
      margin-left: auto;
    }
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:hover {
      box-shadow: 0 0 0 1px ${secondary.Dark};
    }
  }
  .warning-max {
    &:focus {
      outline: ${accentRed} solid 2px;
    }
  }
  .warning-near {
    &:focus {
      outline: ${accentYellow} solid 2px;
    }
  }

  .control-container.hovered {
    box-shadow: 0 0 0 1px ${secondary.Dark};
  }

  .control-container {
    width: 100%;
  }
`

const Title = styled.h4`
  width: 100%;
  color: ${getStyleForProp('title')};
  margin-bottom: 0;
  text-align: center;
  ${getTextStyleByAlignment};
`

const BlocksContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  @media only screen and (max-width: 1024px) {
    margin-top: 40px;
  }
  @media only screen and (min-width: 769px) {
    ${({ isEditing }) =>
      isEditing &&
      css`
        transform: translateY(-50px);
      `}
  }
`

const BlockTitlesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: flex-start;
  width: 35%;
  & > .control-container {
    width: 100%;
    padding: 1px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  @media only screen and (min-width: 769px) {
    ${({ isEditing }) =>
      isEditing &&
      css`
        margin-top: 50px;
      `}
  }
  > .add-button-wrap {
    margin: 15px 20px 15px 0;
    @media only screen and (max-width: 768px) {
      margin-left: 20px;
    }
    @media only screen and (max-width: 450px) {
      margin-left: 10px;
    }
  }
`

const BlockTitle = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  ${getTextStyleByAlignment};
  @media only screen and (max-width: 768px) {
    width: 90%;
  }
`
const ArrowWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 20px;
  right: 10px;
  svg {
    width: 100%;
    fill: ${({ theme }) => theme.palette.tertiaryDark};
  }
`

const BlockTitleWrapper = styled.div`
  position: relative;
  padding: 20px;
  @media only screen and (max-width: 450px) {
    padding: 20px 10px;
  }

  & > .control-container {
    @media only screen and (max-width: 768px) {
      width: 90%;

      & > p {
        width: 100%;
      }
    }
  }
`

const BlockTitleContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  ${({ isActiveTab }) =>
    isActiveTab &&
    css`
      background-color: ${getStyleForProp('activeTabBg')};
      @media only screen and (max-width: 768px) {
        background-color: ${getStyleForProp('actieMobileTabBg')};
        ${BlockTitleWrapper} {
          border-bottom: none;
        }
      }
    `}
  ${ArrowWrapper} {
    svg {
      transition: all 0.2s ease;
      transform: ${({ isActiveTab }) => (isActiveTab ? 'rotate(180deg)' : '')};
    }
  }
  > div {
    border-bottom: 1px solid ${getStyleForProp('border')};
  }
`

const ContentWrapper = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

const AddButtonsWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  @media only screen and (max-width: 768px) {
    padding-left: 20px;
  }
  @media only screen and (max-width: 450px) {
    padding-left: 10px;
  }
`

const MobileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const ControlContainer = styled.article`
  @media only screen and (min-width: 769px) {
    ${({ isEditing }) =>
      isEditing &&
      css`
        margin-top: 50px;
      `}
  }
  .control-container {
    width: 100%;
  }
  > .control-container {
    padding: 2px;
    box-sizing: border-box;
  }
`

const ListItemWrap = styled.div`
  width: 100%;
  position: relative;
  &:not(:last-child) {
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 768px) {
    &:last-child {
      margin-bottom: 20px;
    }
  }

  & > .control-container {
    padding-top: 20px;
  }
`

const Block = styled.div`
  width: 100%;
  position: relative;
  ${({ isEditing }) =>
    isEditing
      ? css`
          margin-top: 30px;
          margin-bottom: 30px;
        `
      : css`
          margin-bottom: 20px;
          margin-top: 10px;
        `};
  > div:nth-child(3) {
    margin-top: 30px;
  }
  > div {
    margin-top: 0;
  }
  > :first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 450px) {
    padding-right: 10px;
    padding-left: 10px;
  }

  & > .control-container:nth-child(2) {
    margin-top: 20px;

    & > p {
      margin-top: 0;
    }
  }
`

const SubBlockWrapper = styled.div`
  min-width: 100%;
  width: 100%;
  padding-right: 1px;
  padding-left: 1px;
  box-sizing: border-box;
  &.hidden-block {
    visibility: hidden;
    order: 2;
  }
  &.active-block {
    order: 1;
  }
  > div {
    @media only screen and (min-width: 769px) {
      &:last-child {
        ${Block} {
          margin-bottom: 0;
        }
      }
    }

    @media only screen and (max-width: 650px) {
      &:first-child {
        ${Block} {
          margin-top: 0;
        }
      }
    }
  }
`

const SubBlockTitle = styled.h1`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const SubBlockParagraph = styled.p`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  margin-top: 20px;
  ${getTextStyleByAlignment};
`

const ListItemParagraph = styled.p`
  position: relative;
  box-sizing: border-box;
  padding-left: 20px;
  color: ${({ theme }) => theme.palette.tertiaryDark};

  &:after {
    content: '';
    position: absolute;
    max-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: 5px;
    left: 0;
    background: ${({ theme }) =>
      `linear-gradient(0, ${theme.palette.primaryDark}, ${theme.palette.primaryLight})`};
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  Title,
  CardWrap,
  BlockTitleContainer,
  BlocksContainer,
  BlockTitlesWrapper,
  BlockTitle,
  ContentWrapper,
  SubBlockWrapper,
  AddButtonsWrapper,
  ArrowWrapper,
  MobileContentWrapper,
  BlockTitleWrapper,
  ControlContainer,
  Block,
  SubBlockTitle,
  SubBlockParagraph,
  ListItemWrap,
  ListItemParagraph
}
