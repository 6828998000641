/* @flow */
import React, { useContext, useState, memo } from 'react'

import S from './Header53.styled'
import THEME from './Header53.theme'
import { validation } from './Header53.consts'
import VideoPopup from '@website/common/components/VideoPopup/VideoPopup'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import YourLogo from '@website/common/assets/YourLogo.svg'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import SingleButton from '@website/common/components/SingleButton'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import SocialLinks from '@website/common/components/SocialLinks'
import { DispatchContext, EditingContext, MobileDetectContext } from '@contexts'
import ProportionalChildrenWrapper from '@website/common/components/ProportionalChildrenWrapper'
import { isYoutubeUrl, getYoutubeThumbnail } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'

const Header53 = memo(props => {
  const {
    data: {
      supTitle,
      supTitleAlignment,
      title,
      logoSrc,
      headerSocials,
      logoColor,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoWidth,
      btnText,
      btnUrl,
      playBtnText,
      contentImage,
      logoDimensions,
      backgroundVideoUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const [showVideoPopup, setVideoPopup] = useState(false)
  const isMobile = useContext(MobileDetectContext)
  return (
    <S.StyledContainer
      backgroundImgUrl={getYoutubeThumbnail(backgroundVideoUrl)}
      windowHeight={typeof window !== 'undefined' ? window.innerHeight : null}
    >
      <>
        <S.Overlay
          bgOverlayTransparency={bgOverlayTransparency}
          isBgImgOrVideoExists={!!backgroundVideoUrl}
        />
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <If
          condition={showVideoPopup}
          then={() => (
            <VideoPopup
              url={backgroundVideoUrl}
              closeVideoPopup={() => setVideoPopup(false)}
            />
          )}
        />
        <S.VideoContainer>
          <If
            condition={isMobile && isYoutubeUrl(backgroundVideoUrl)}
            otherwise={() => (
              <LazyVideoPlayer
                muted
                loop
                playing={!isMobile}
                controls={false}
                style={{
                  right: '0',
                  bottom: '0',
                  minWidth: '100%',
                  minHeight: '100%',
                  overflow: 'hidden',
                  position: 'absolute'
                }}
                url={backgroundVideoUrl}
                className="react-player"
              />
            )}
          />
        </S.VideoContainer>
        <S.WmCustomContainer topOffset={navBarHeight} isEditing={isEditing}>
          <S.HeaderContent>
            <Show when={[contentImage.imgUrl]}>
              <S.ContentImage>
                <ControlsWithModalOpener
                  actions={Actions.imageActions({
                    showRemove: !!contentImage.imgUrl,
                    keyForSizes: 'header53',
                    imageUrl: contentImage.imgUrl
                  })}
                  style={{ width: '100%' }}
                >
                  <If
                    condition={contentImage.imgUrl}
                    then={() => (
                      <Image
                        as={S.Img}
                        alt="Logo"
                        sizes={contentImage.imgDimensions}
                        defaultImgSrc={contentImage.imgUrl}
                      />
                    )}
                    otherwise={() => <S.Img src={YourLogo} alt="Your Logo" />}
                  />
                </ControlsWithModalOpener>
              </S.ContentImage>
            </Show>
            <EditableContent
              text={supTitle}
              isEditing={isEditing}
              alignment={supTitleAlignment}
              required={!title && !paragraph}
              maxCount={validation.supTitleMaxChar}
              className="WM_GLOBAL_heading42"
              as={S.supTitle}
              onChange={Actions.changeSupTitle}
              changeAlignment={Actions.changeSupTitleAlignment}
            />
            <Show when={[title]}>
              <EditableContent
                text={title}
                isEditing={isEditing}
                alignment={titleAlignment}
                required={!supTitle && !paragraph}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading72"
                as={S.Title}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                isEditing={isEditing}
                as={S.Paragraph}
                required={!supTitle && !title}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <S.ButtonsContainer>
              <ProportionalChildrenWrapper className="WM_GLOBAL_secondary-font buttons-wrapper">
                <SingleButton btnUrl={btnUrl} btnText={btnText} />
                <If
                  condition={playBtnText && backgroundVideoUrl !== ''}
                  then={() => (
                    <ControlsWithModalOpener
                      actions={Actions.playBtnActions(playBtnText)}
                    >
                      <S.Button
                        isEditing={isEditing}
                        onClick={() =>
                          backgroundVideoUrl && setVideoPopup(true)
                        }
                      >
                        <Icon name="icon-play-complexity-3" size="large" />
                        <LinkWrapper
                          text={playBtnText}
                          linkProps={{
                            className: 'WM_GLOBAL_secondary-font'
                          }}
                        />
                      </S.Button>
                    </ControlsWithModalOpener>
                  )}
                />
                <AddButton
                  onAdd={Actions.addPlayButton}
                  toShow={!playBtnText && !!backgroundVideoUrl}
                  rounded
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_play_button_label')}
                </AddButton>
              </ProportionalChildrenWrapper>
            </S.ButtonsContainer>
          </S.HeaderContent>
        </S.WmCustomContainer>
      </>
      <Show when={[headerSocials.length > 0]}>
        <SocialLinks
          className="header-socials"
          socials={headerSocials}
          socialsMaxCount={validation.headerSocials.max}
        />
      </Show>
    </S.StyledContainer>
  )
})

export default Header53
