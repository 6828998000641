// @flow

import React, { memo, useState, useCallback } from 'react'
import { useSelector } from 'react-redux'

import {
  selectIsComponentsListLoading,
  selectIsComponentsDataLoading
} from '@selectors'
import { translate } from '@editor/common/utils/translations'
import Popup from '@editor/common/lib/Popup'
import { PopupsLoader } from '@editor/common/components/Loaders'
import Input from '@website/common/components/Input/Input'
import {
  PrimaryButton,
  SecondaryButton
} from '@editor/common/styled-components/buttons'
import { onEnterPress } from '@website/common/utils'
import { secondary } from '@website/common/styles/colors'

import { PAGE_NAME_VALIDATION_MESSAGE } from '../consts'
import { validatePageName } from '../utils'
import * as Styled from './styled'
import type { TPageAddingPopupProps } from './types'

const PAGE_NAME_EXISTENCE_MESSAGE = translate('this_page_name_already_exists')
const EMPTY_INPUT_MESSAGE = translate('this_field_cannot_be_empty_label')

const PageAddingPopup = ({
  pagePaths,
  onSave,
  onClose
}: TPageAddingPopupProps) => {
  const [pageName, setPageName] = useState('')
  const [validationMessage, setValidationMessage] = useState('')
  const isCompListLoading = useSelector(selectIsComponentsListLoading)
  const isCompDataLoading = useSelector(selectIsComponentsDataLoading)
  const isLoaderVisible = isCompListLoading || isCompDataLoading

  const onFocus = useCallback(() => {
    if (validationMessage) setValidationMessage('')
  }, [validationMessage])

  const onRemoveIconClick = useCallback(() => {
    setPageName('')
  }, [])

  const onChange = useCallback(e => {
    const pageName = e.target.value.replace(' ', '_')

    setPageName(pageName)
  }, [])

  const onAdd = useCallback(() => {
    if (!pageName) {
      setValidationMessage(EMPTY_INPUT_MESSAGE)
      return
    }

    if (!validatePageName(pageName)) {
      setValidationMessage(PAGE_NAME_VALIDATION_MESSAGE)
      return
    }

    if (pageName === 'home' || pagePaths.indexOf(`/${pageName}`) !== -1) {
      setValidationMessage(PAGE_NAME_EXISTENCE_MESSAGE)
      return
    }

    onSave(pageName)
  }, [pageName, pagePaths, onSave])

  return (
    <Popup
      isOpen
      onClose={onClose}
      title={translate('add_page_label')}
      mobileProps={{
        className: 'page-name-editing-popup'
      }}
      className="page-name-editing-popup"
    >
      <PopupsLoader isLoading={isLoaderVisible} />
      <Styled.Content>
        <Styled.Label>{translate('type_a_page_name')}</Styled.Label>
        <Styled.InputWrapper>
          <Input
            value={pageName}
            className="page-name-input"
            placeholder="page_name"
            placeholderColor="#8493BD"
            borderColor={secondary.Light}
            borderHoverColor={secondary.SemiLight}
            error={validationMessage}
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onEnterPress(onAdd)}
            onRemoveIconClick={onRemoveIconClick}
          />
        </Styled.InputWrapper>
        <Styled.ButtonsWrapper>
          <SecondaryButton onClick={onClose}>
            {translate('cancel_label')}
          </SecondaryButton>
          <PrimaryButton onClick={onAdd}>
            {translate('add_label', true)}
          </PrimaryButton>
        </Styled.ButtonsWrapper>
      </Styled.Content>
    </Popup>
  )
}

export default memo(PageAddingPopup)
