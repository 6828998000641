/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PrivacyPolicy4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  .image-state-overlay {
    z-index: 9;
  }
`
const WMCustomContainer = styled(WMContainer)`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 960px;
  box-sizing: border-box;
  @media only screen and (max-width: 1040px) {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    max-width: initial;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const BlocksContainer = styled.div`
  width: 100%;
  padding-top: 20px;
  @media only screen and (min-width: 651px) {
    border-top: 1px solid ${getStyleForProp('border')};
  }
`

const AdditionalBlockParagraph = styled.p`
  color: ${getStyleForProp('additionalBlockParagraph')};
  @media only screen and (min-width: 769px) {
    margin-left: 30px;
    max-width: 270px;
  }
  @media only screen and (max-width: 768px) and (min-width: 651px) {
    margin-left: 20px;
    max-width: 260px;
  }
  @media only screen and (max-width: 650px) {
    margin-top: 20px;
    width: 100%;
  }
`

const ListItemWrap = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;

  & > .control-container {
    padding-top: 20px;
  }
`

const Block = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  margin-top: 10px;
  > div:nth-child(3) {
    margin-top: 30px;
  }
  > div {
    margin-top: 0;
  }
  > .control-container:first-child,
  > .control-container:nth-child(2) {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const ControlContainer = styled.div`
  .control-container {
    width: 100%;
  }
`

const BlockWrap = styled.article`
  &:last-child {
    ${Block} {
      margin-bottom: 0;
    }
  }
`

const BlockTitle = styled.h1`
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
`

const ListItemParagraph = styled.p`
  position: relative;
  box-sizing: border-box;
  padding-left: 20px;
  color: ${getStyleForProp('blockParagraph')};

  &:after {
    content: '';
    position: absolute;
    max-width: 10px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    top: 5px;
    left: 0;
    background: ${({ theme }) =>
      `linear-gradient(0, ${theme.palette.primaryDark}, ${theme.palette.primaryLight})`};
  }
`

const AddButtonsWrapper = styled.div`
  display: flex;
`

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  @media only screen and (min-width: 651px) {
    padding-bottom: 20px;
    align-items: flex-end;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
  }

  & > .control-container:first-child {
    @media only screen and (min-width: 769px) {
      width: ${({ isAdditionalBlockParagraph }) =>
        isAdditionalBlockParagraph ? '68%' : '100%'};
    }

    & > h4 {
      @media only screen and (min-width: 769px) {
        width: 100%;
      }
    }
  }
  .control-container {
    @media only screen and (min-width: 650px) {
      width: 100%;
    }
  }
  .control-container:nth-child(2) {
    @media only screen and (min-width: 769px) {
      max-width: 270px;
    }
    @media only screen and (max-width: 768px) and (min-width: 651px) {
      max-width: 260px;
    }
    @media only screen and (max-width: 650px) {
      width: 100%;
    }
  }
`

const Title = styled.h4`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  @media only screen and (min-width: 769px) {
    width: ${({ isAdditionalBlockParagraph, isEditing }) =>
      !isEditing && isAdditionalBlockParagraph ? '68%' : '100%'};
  }
  @media only screen and (max-width: 768px) and (min-width: 651px) {
    width: ${({ isAdditionalBlockParagraph, isEditing }) =>
      !isEditing && isAdditionalBlockParagraph ? '57%' : '100%'};
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
    border-bottom: 1px solid ${getStyleForProp('border')};
    padding-bottom: 20px;
  }
`
const IconsContainer = styled.div`
  width: 100%;
  height: 112px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 50px;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (max-width: 1024px) {
    max-width: 618px;
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media only screen and (max-width: 710px) {
    max-width: 314px;
    height: 90px;
  }
`

const Icon = styled.div`
  height: 55px;
  ${({ theme }) =>
    theme.look !== 'SHARP' &&
    css`
      border-radius: 10px;
    `}
  @media only screen and (max-width: 710px) {
    height: 44px;
  }
  &:not(.extra-large) {
    width: 55px;
    @media only screen and (max-width: 710px) {
      width: 44px;
    }
  }
  &.extra-large {
    width: 111.5px;
    @media only screen and (max-width: 710px) {
      width: 89px;
    }
    &.right {
      border-top-right-radius: 26px;
      border-bottom-right-radius: 26px;
    }
    &.left {
      border-top-left-radius: 26px;
      border-bottom-left-radius: 26px;
    }
  }
  &.first-color {
    background-color: ${getStyleForProp('firstColor')};
  }
  &.second-color {
    background-color: ${getStyleForProp('secondColor')};
  }
  &.third-color {
    background-color: ${getStyleForProp('thirdColor')};
  }
  &.fourth-color {
    background-color: ${getStyleForProp('fourthColor')};
  }
  &.fifth-color {
    background-color: ${getStyleForProp('fifthColor')};
  }
  &.circle {
    border-radius: 50%;
  }
  &.first-quorter {
    border-top-right-radius: 100%;
  }
  &.second-quorter {
    border-bottom-right-radius: 100%;
  }
  &.third-quorter {
    border-bottom-left-radius: 100%;
  }
  &.fourth-quorter {
    border-top-left-radius: 100%;
  }
  @media only screen and (max-width: 1024px) and (min-width: 711px) {
    &.hidden-tablet {
      display: none;
    }
  }
  @media only screen and (max-width: 710px) {
    &.hidden-mobile {
      display: none;
    }
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  BlocksContainer,
  ControlContainer,
  BlockWrap,
  Block,
  BlockTitle,
  BlockParagraph,
  ListItemWrap,
  ListItemParagraph,
  AdditionalBlockParagraph,
  AddButtonsWrapper,
  TitleContainer,
  Title,
  IconsContainer,
  Icon
}
