import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
`

export const IdComp = styled.div`
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  top: ${({ top }) => `-${top}px`};
  left: 50%;
`
