import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryLight)}, 0.8)`,
    backgroundColor: p => p.tertiaryLight,
    navbarBg: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    backgroundGradient: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 0.9)`,
    backgroundColor: p => p.tertiaryExtraLight,
    navbarBg: p => p.tertiaryExtraLight
  }
}
