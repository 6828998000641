export const getMaxWidth = elements => {
  let maxWidth = 0
  for (let i = 0; i < elements.length; i++) {
    const elWidth = elements[i].offsetWidth
    if (maxWidth < elWidth) {
      maxWidth = elWidth
    }
  }
  return maxWidth + 1
}

export const setWidth = (elements, width) => {
  for (let i = 0; i < elements.length; i++) {
    if (width) {
      elements[i].style.width = `${width + 1}px` // +1 is for floating point numbers
    }
  }
}
