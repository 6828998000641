/* @flow */
import produce from 'immer'
import meta from './NoHeader.meta'
const initialState = meta.defaultProps

export default function reducer(
  state: typeof meta.defaultProps = initialState,
  action: any
) {
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        return
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        return
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        return
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        return
    }
  })
}
