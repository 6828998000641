/* @flow */
import styled, { css } from 'styled-components'
import THEME from './CTA11_2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const stylesByColor = {
  default: '',
  dark: css`
    filter: brightness(0.13);
  `,
  light: css`
    filter: brightness(0.97);
  `
}

const getStyleByColor = ({ color }) => stylesByColor[color]

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (max-width: 760px) {
    overflow: hidden;
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`

const Content = styled.div`
  width: 100%;
  max-width: 550px;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'LEFT' ? '0 60px 0 0' : '0 0 0 60px'};
    `}
  .buttons-block {
    margin-left: -15px;
  }
  > :first-child {
    padding-top: 0;
  }
  @media only screen and (max-width: 992px) {
    max-width: initial;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'LEFT' ? '0 0 40px 0' : '40px 0 0 0'};
      `}
  }
  @media only screen and (max-width: 768px) {
    max-width: initial;
  }
  @media only screen and (max-width: 550px) {
    ${({ theme }) =>
      css`
        transform-origin: ${theme.orientation === 'LEFT' ? 'bottom' : 'top'};
        margin: ${theme.orientation === 'RIGHT' ? '-30px 0 0 0' : '0'};
      `}
  }
  > :first-child {
    margin-top: 0 !important;
  }
  > .control-container {
    width: 100%;
    p,
    h2 {
      margin-top: 0;
      padding-top: 0;
    }
  }
  > .control-container:first-child {
    margin-bottom: 30px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  font-weight: 500;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const BadgeContainer = styled.div`
  margin: -15px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 310px;
  padding-top: 30px;
  > div {
    margin: 15px;
  }
  > :first-child {
    margin: 15px 15px 15px 0;
  }
  > :nth-child(2) {
    margin: 15px 0 15px 15px;
  }
  @media only screen and (max-width: 1023px) {
    width: 100%;
    max-width: initial;
    justify-content: center;
    flex-wrap: wrap;
  }
`

/*-------------*/
const PhoneWrap = styled.div`
  position: relative;
  z-index: 1;
`

const Phone = styled.div`
  width: 280px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  border-radius: 38px;
  font-size: 0;
  height: 530px;
  padding: 12px 30px 22px 18px;
  &:before {
    content: ' ';
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    border-radius: 24px;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-phone-wrap.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }
  &:after {
    content: '';
    width: 27%;
    height: 28px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-54%);
    z-index: 1;
    background-image: url('https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-phone-detail.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    ${getStyleByColor};
  }

  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: relative;
    padding-top: 216.1%;
    height: initial;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    width: 100%;
    border-radius: 24px;
  }
`

const MobileImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 24px;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  z-index: 1;
`

const DinamicWrap = styled.div`
  width: 134px;
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  .rf-iphone-dimanic-svg path {
    fill: ${getStyleForProp('phone')};
  }
`

const AndroidWrap = styled.div`
  width: 210px;
  transform: translateX(-40px);
  .LazyLoad {
    padding-top: 213%;
    position: relative;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: 24px;
  }
`

const MockupsWrap = styled.div`
  display: flex;
  align-items: center;
  transform: translateX(20px);
  @media only screen and (max-width: 550px) {
    transform: translateX(15px) scale(0.75);
    ${({ theme }) =>
      css`
        transform-origin: ${theme.orientation === 'LEFT' ? 'bottom' : 'top'};
        margin: ${theme.orientation === 'LEFT' ? '-90px 0 0 0' : '0 0 -60px 0'};
      `}
  }
  .image-state-overlay,
  .failed-image-container {
    z-index: 1;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Section,
  Title,
  Paragraph,
  BadgeContainer,
  PhoneWrap,
  Phone,
  MobileImg,
  DinamicWrap,
  AndroidWrap,
  MockupsWrap,
  Content
}
