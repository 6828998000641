/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header50.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  padding: 0;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 650px) {
    padding: 0;
  }
`

const Background = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  ${getBackgroundStyles(THEME)};
  background-attachment: fixed !important;
  background-position: center;
  @media only screen and (max-width: 768px) {
    background-attachment: initial !important;
  }
  @media only screen and (max-width: 650px) {
    height: 0;
    padding-top: 50%;
  }
`

const WaveWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: -185px;
  left: 0;
  right: 0;
  @media only screen and (max-width: 1024px) {
    top: -205px;
  }
  @media only screen and (max-width: 767px) {
    top: -195px;
  }
  @media only screen and (max-width: 660px) {
    top: -175px;
  }
  svg {
    width: 100%;
    filter: drop-shadow(1px 1px 2px ${getStyleForProp('border')});
    path {
      fill: ${getStyleForProp('wave')};
    }
  }
`

const WMCustomContainer = styled(WMContainer)`
  min-width: 100%;
  position: relative;
  padding-left: 0;
  padding-right: 0;

  padding-top: ${({ topOffset }) => `${topOffset + 500}px`};
  @media only screen and (max-width: 1024px) {
    padding-top: ${({ topOffset }) => `${topOffset + 470}px`};
  }

  ${({ isEditing }) =>
    !isEditing
      ? css`
          @media only screen and (max-width: 650px) {
            padding-top: 50%;
          }
        `
      : css`
          @media only screen and (max-width: 650px) {
            padding-top: 16%;
          }
          @media only screen and (max-width: 500px) {
            padding-top: 10%;
          }
        `}
`

const ContentWrapper = styled.div`
  position: relative;
  margin-right: auto;
  margin-left: auto;
`

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  max-width: 1400px;
  position: relative;
  margin: 0 auto;
  box-sizing: border-box;
  @media only screen and (max-width: 1440px) {
    width: 90%;
    max-width: 1200px;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 40px;
  }
  @media only screen and (max-width: 457px) {
    padding: 0 20px;
  }
  .add-button-wrap {
    display: flex;
  }
  .link-section {
    margin-top: 20px;

    .link {
      color: ${getStyleForProp('link')};
    }
  }
  .arrow-right {
    svg {
      fill: ${getStyleForProp('link')};
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  max-width: 1300px;
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.5em;
  padding-top: 20px;
  width: 100%;
  max-width: 1300px;
  ${getTextStyleByAlignment};
`

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: none;
  box-sizing: border-box;
  ${({ isEditing }) =>
    !isEditing
      ? css`
          @media only screen and (min-width: 651px) {
            margin-right: 20px;
            margin-left: 20px;
            width: 180px;
            min-width: 180px;
          }
        `
      : css`
          width: 220px;
          min-width: 220px;
          @media only screen and (max-width: 1024px) and (min-width: 651px) {
            margin-right: 20px;
            margin-left: 20px;
            width: 180px;
            min-width: 180px;
          }
        `};

  > .control-container {
    width: 100%;
  }
  @media only screen and (min-width: 651px) {
    margin-top: 20px;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
`
const ItemWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  ${({ isEditing }) =>
    isEditing &&
    css`
      padding: 20px;
      @media only screen and (max-width: 1024px) {
        padding: 25px 0;
      }
    `};

  > .control-container {
    width: 100%;
    margin-top: 20px;
    & > p {
      margin-top: ${({ isEditing }) => (isEditing ? '0' : '10px')};
    }
  }

  > :first-child {
    margin-top: 0;
  }
`

const BlockTitle = styled.h5`
  min-width: 100%;
  width: 100%;
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('blockParagraph')};
  ${getTextStyleByAlignment};
  margin-top: ${({ isEditing }) => (isEditing ? '20px' : '10px')};
`

const BlocksWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 810px;
  justify-content: center;

  @media only screen and (max-width: 1045px) {
    max-width: 800px;
  }
  @media only screen and (min-width: 1025px) {
    margin-right: -20px;
  }
  @media only screen and (max-width: 1024px) {
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
  }
  @media only screen and (max-width: 650px) {
    flex-direction: column;
    align-items: center;
  }
`

const LeftContent = styled.div`
  width: 50%;
  max-width: 600px;
  @media only screen and (min-width: 1025px) {
    margin-right: 40px;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    min-width: 100%;
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }
  @media only screen and (max-width: 650px) {
    padding-top: 20px;
  }
`

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 660px;
  @media only screen and (max-width: 1444px) {
    width: 66%;
  }
  @media only screen and (max-width: 1366px) {
    width: 68%;
  }
  @media only screen and (max-width: 1024px) {
    padding-top: 40px;
    width: 100%;
    max-width: 100%;
  }
  .add-button-wrap {
    margin-top: 20px;
  }
`

const TitleWrapper = styled.div`
  min-width: 100%;
  display: flex;
  > .control-container {
    min-width: 100%;
  }
`

const HiddenTitle = styled.div`
  min-width: 100%;
  visibility: hidden;
  margin-bottom: 0 !important;
`

const BottomContentWrapper = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  padding-bottom: 80px;
  background-color: ${getStyleForProp('wave')};
  @media only screen and (max-width: 650px) {
    padding-bottom: 60px;
  }
`

export default {
  StyledContainer,
  Background,
  WMCustomContainer,
  ContentContainer,
  ContentWrapper,
  Title,
  Paragraph,
  ControlContainer,
  ItemWrapper,
  BlockTitle,
  BlockParagraph,
  BlocksWrap,
  LeftContent,
  RightContent,
  WaveWrapper,
  TitleWrapper,
  HiddenTitle,
  BottomContentWrapper
}
