/* @flow */
import styled from 'styled-components'

import THEME from './Text1.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
`

const WMCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
`

const QuoteWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  border: ${getStyleForProp('border')};
  width: 100%;
  max-width: 1200px;
  padding: ${({ isEditing }) => (isEditing ? '60px 36px' : '36px')};
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
`

const Quote = styled.h1`
  color: ${getStyleForProp('quote')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

export default {
  StyledContainer,
  WMCustomContainer,
  Quote,
  QuoteWrap
}
