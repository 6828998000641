/* @flow */
import React from 'react'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'

import MockupColorSwitcher from '@website/common/components/MockupColorSwitcher'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeDescription = (newDescription: string) => {
    dispatcher({
      type: 'CHANGE_DESCRIPTION',
      value: newDescription
    })
  }

  const addStoreLink = (keyInStore: string) => {
    dispatcher({
      type: 'ADD_STORE_LINK',
      value: keyInStore
    })
  }

  const changeStoreUrl = (storeType: string) => (url: string) => {
    dispatcher({
      type: 'CHANGE_STORE_URL',
      value: { url, storeType }
    })
  }

  const storeIconActions = (storeType: string, link: string) => [
    {
      IconComp: EditIcon,
      tooltipText: translate('edit_label'),
      handler: changeStoreUrl(storeType),
      modal: {
        type: MODAL_TYPES.urlModal,
        props: { type: LINK_TYPES.link, link }
      }
    },
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        dispatcher({ type: 'REMOVE_BUTTON', value: storeType })
      }
    }
  ]
  const changeMockupColor = (mockupType: string) => ({
    type: 'CHANGE_MOCKUP_COLOR',
    value: mockupType
  })

  const makeMockupLight = (mockupType: string) => ({
    type: 'MAKE_MOCKUP_LIGHT',
    value: mockupType
  })

  const makeMockupDark = (mockupType: string) => ({
    type: 'MAKE_MOCKUP_DARK',
    value: mockupType
  })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const _mockupActions = ({ mockupType, mockupColor, imageUrl }) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'header6_2Mockup',
      showRemove: false,
      additionalArgs: { mockupType },
      imageUrl
    }),
    {
      renderer: () => (
        <MockupColorSwitcher
          onChoose={() => dispatcher(changeMockupColor(mockupType))}
          onFirstOptionSelect={() => dispatcher(makeMockupLight(mockupType))}
          onSecondOptionSelect={() => dispatcher(makeMockupDark(mockupType))}
          isSecondOptionSelected={mockupColor === 'dark'}
        />
      )
    }
  ]

  return {
    changeTitle: _changeTitle,
    changeDescription,
    addStoreLink,
    storeIconActions,
    mockupActions: _mockupActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment
  }
}
