/* @flow */

export const validation = {
  maxTitleChar: 120,
  maxParagraphChar: 1500,
  maxQuestionChar: 90,
  maxAnswerChar: 800,
  faqs: {
    min: 1,
    max: 20
  }
}