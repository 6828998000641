import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const Option = styled.li`
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: ${secondary.Dark};
  list-style-type: none;
  & > div {
    width: 16px;
    height: 16px;
    border-radius: 10px;
    margin-right: 15px;
    background: transparent
      linear-gradient(133deg, #40ea95 0%, #17d677 52%, #12be99 100%) 0% 0%
      no-repeat;
    justify-content: center;
    flex-shrink: 0;
  }

  &:not(:first-child) {
    padding-top: 15px;
  }

  span,
  b:not(:last-child) {
    margin-right: 4px;
  }
`
