/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addBlock
} from '@website/components/CommonActions'
import {
  removeBlockActionMaker,
  iconActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const changeBlockParagraph = (paragraph, idx) =>
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { idx, paragraph }
    })

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _addBlock = () => {
    dispatcher(addBlock())
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    addBlock: _addBlock,
    changeBlockParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockParagraphAlignment,
    blockActions: removeBlockActionMaker(dispatcher),
    iconActions: idx => [
      ...iconActionMaker(dispatcher)({
        idx,
        showRemove: false
      })
    ],
    getImgActions: cropableImageModalActionMaker(dispatcher)({
      showRemove: false,
      keyForSizes: 'header47'
    })
  }
}
