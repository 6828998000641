const bringIntoViewport = (
  element,
  scrollableContainer,
  {
    verticalMargin = 0,
    horizontalMargin = 0,
    isVerticalScroll = true,
    extraScrollOffset = 20
  } = {}
) => {
  const getScrollOffsets = intersectionRatio => {
    const {
      top: elementTop,
      left: elementLeft,
      height: elementHeight,
      width: elementWidth
    } = element.getBoundingClientRect()
    const {
      top: scrollableContainerTop,
      left: scrollableContainerLeft,
      right: scrollableContainerRight,
      bottom: scrollableContainerBottom
    } = scrollableContainer.getBoundingClientRect()

    const isFullyInvisible = intersectionRatio === 0

    const offsetTop = isFullyInvisible
      ? elementTop -
        scrollableContainerBottom +
        elementHeight +
        extraScrollOffset
      : elementHeight * (1 - intersectionRatio) + extraScrollOffset

    const offsetLeft = isFullyInvisible
      ? elementLeft -
        scrollableContainerRight +
        elementWidth +
        extraScrollOffset
      : elementWidth * (1 - intersectionRatio) + extraScrollOffset

    const top = elementTop < scrollableContainerTop ? -offsetTop : offsetTop
    const left =
      elementLeft < scrollableContainerLeft ? -offsetLeft : offsetLeft

    return {
      top: isVerticalScroll ? top : 0,
      left: isVerticalScroll ? 0 : left
    }
  }

  const observer = new IntersectionObserver(
    entries => {
      entries.forEach(entry => {
        if (entry.target === element) {
          if (entry.intersectionRatio < 1) {
            scrollableContainer.scrollBy({
              ...getScrollOffsets(entry.intersectionRatio),
              behavior: 'smooth'
            })
          }
        }
      })

      observer.unobserve(element)
    },
    {
      root: scrollableContainer,
      threshold: 1.0,
      rootMargin: `${verticalMargin}px ${horizontalMargin}px`
    }
  )

  observer.observe(element)
}

export default bringIntoViewport
