import React, { useState, useEffect, useRef } from 'react'
import S from './FAQ3.styled'
import { validation } from './FAQ3.consts'
import EditableContent from '@website/common/components/EditableContent'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'

const isMoreThanThreeLine = textElement => {
  if (textElement) {
    const element = textElement.cloneNode()
    element.style.display = 'block'
    element.style.width = textElement.getBoundingClientRect().width + 'px'
    element.style.position = 'absolute'
    element.innerHTML = textElement.innerHTML
    document.body.appendChild(element)

    const isThreeLine = element.getBoundingClientRect().height > 75
    document.body.removeChild(element)

    return isThreeLine
  }
  return false
}

const Card = ({
  isEditing,
  data,
  rotateCard,
  isActive,
  faqActions,
  changeQuestion,
  changeAnswer
}) => {
  const [isOpen, setOpenState] = useState(isEditing)

  const textRef = useRef(null)

  const wrapTextRef = ({ ...props }) => {
    return <S.FaqContent {...props} ref={textRef} />
  }

  const handleTextOpening = e => {
    if (isEditing) {
      return
    }
    e.stopPropagation()
    setOpenState(!isOpen)
  }

  useEffect(() => {
    if (isEditing) {
      return
    }
    setOpenState(false)
  }, [isActive])

  return (
    <S.CardWrapper>
      <If
        condition={isEditing}
        then={() => (
          <S.IconWrapper onClick={rotateCard()}>
            <S.Icon size="middle" name="icon-refresh-complexity-3" />
          </S.IconWrapper>
        )}
      />
      <ControlsWithModalOpener
        actions={faqActions}
        style={{ width: '100%', height: '100%' }}
      >
        <S.FaqCard
          onClick={!isEditing ? rotateCard() : ''}
          isEditing={isEditing}
        >
          <S.Card showAnswer={isActive}>
            <S.CardFront className="card-inner" showAnswer={isActive}>
              <S.Circle>
                <Icon color="white" size="large" name="icon-question" />
              </S.Circle>
              <EditableContent
                isLinkControlHidden
                as={S.Question}
                text={data.question}
                showAnswer={isActive}
                required={validation.faqs.question.required}
                maxCount={validation.maxQuestionChar}
                className="WM_GLOBAL_paragraph18"
                onChange={changeQuestion}
              />
            </S.CardFront>
            <S.CardBack className="card-inner">
              <EditableContent
                areControlsHidden
                text={data.answer}
                as={wrapTextRef}
                showAnswer={isActive}
                isCardTextOpen={isOpen}
                required={validation.faqs.answer.required}
                maxCount={validation.maxAnswerChar}
                className="WM_GLOBAL_paragraph18"
                onChange={changeAnswer}
              />
              <S.Button
                onClick={handleTextOpening}
                isOpen={!isEditing && isOpen}
                isEditing={isEditing}
                isThreeLine={isMoreThanThreeLine(textRef.current)}
              >
                <Icon from="rf" size="middle" name="icon-arrow_small_bottom" />
              </S.Button>
            </S.CardBack>
          </S.Card>
        </S.FaqCard>
      </ControlsWithModalOpener>
    </S.CardWrapper>
  )
}
export default Card
