/* @flow */
import {
  iconActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'

export default dispatch => {
  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const addItem = () => {
    dispatch({ type: 'ADD_ITEM' })
  }

  return {
    changeBlockTitle,
    changeBlockParagraph,
    addItem,
    blockActions: removeBlockActionMaker(dispatch),
    getIconActions: iconActionMaker(dispatch)
  }
}
