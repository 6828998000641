/* @flow */

export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 50,
  blockSubTitleMaxChar: 50,
  team: {
    min: 1,
    max: 30
  }
}
