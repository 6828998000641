//@flow
import React, { memo, useMemo } from 'react'

import * as Styled from './styled'
import type { TProps } from '../types'
import { KD_DATA } from './consts'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import TooltipContent from '@editor/common/components/SemrushKeywordPopup/TooltipContent'
import { useResponsive } from '@shared/hooks'

const KD = ({ data }: TProps) => {
  const number = parseInt(data)
  const isZero = number === 0

  const isSmallSize = useResponsive(1024)

  const kd = useMemo(
    () => KD_DATA.find(d => number >= d.min && number <= d.max),
    [number]
  )

  return (
    <Styled.KD>
      {isZero ? <p className="na">n/a</p> : number}
      <TooltipWrapper
        text={
          isZero || isSmallSize ? (
            ''
          ) : (
            <TooltipContent title={kd.label} description={kd.tooltipText} />
          )
        }
      >
        <Styled.Circle color={isZero ? '#e0e1e9' : kd.color} />
      </TooltipWrapper>
    </Styled.KD>
  )
}

export default memo(KD)
