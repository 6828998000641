export const AVATAR_INITIAL_STATE = {
  imageUrl:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp3-2.0.0.png',
  imgDimensions: {
    small:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp3-2.0.0.png',
    medium:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp3-2.0.0.png',
    large:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp3-2.0.0.png',
    xlarge:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp3-2.0.0.png'
  }
}
