import {
  EMAIL_DEFAULT_GREETING,
  CONFIRMATION_EMAIL_DEFAULT_MESSAGE,
  REGRET_EMAIL_DEFAULT_MESSAGE
} from '@containers/edit/containers/event/content/Email/consts'

export const getEmailDefaultContent = (title, isRegret) => ({
  subject: {
    text: title
  },
  title: {
    text: title
  },
  greeting: {
    text: EMAIL_DEFAULT_GREETING
  },
  message: {
    text: isRegret
      ? REGRET_EMAIL_DEFAULT_MESSAGE
      : CONFIRMATION_EMAIL_DEFAULT_MESSAGE
  }
})
