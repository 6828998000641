/* @flow */
import React, { useContext, memo } from 'react'
import S from './Footer3.styled'
import { validation } from './Footer3.consts'
import ActionGetter from './Actions'
import Footer from '@website/common/components/Footer'
import Logo from '@website/common/components/Logo'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import {
  EditingContext,
  DispatchContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import SocialLinks from '@website/common/components/SocialLinks'
import { Controls } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'

const Footer3 = memo(props => {
  const {
    data: {
      copyrightText,
      socials,
      logoSrc,
      logoWidth,
      logoColor,
      logoDimensions,
      imgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}

  return (
    <S.Container isEditing={isEditing}>
      <WMContainer className="WM_GLOBAL_secondary-font">
        <Footer />
        <S.FooterContentWrap>
          <S.FooterContent>
            <Logo
              logoSrc={logoSrc}
              logoWidth={logoWidth}
              logoColor={logoColor}
              imgDimensions={logoDimensions || imgDimensions}
            />
            <S.CopyRightWrap>
              <EditableContent
                text={copyrightText}
                as={S.Copyright}
                onChange={Actions.changeCopyright}
                isPrivacyPolicyVisible={isPrivacyPolicyVisible}
              />
              {isPrivacyPolicyVisible ? (
                <S.LinkWrap isEditing={isEditing}>
                  <Controls actions={Actions.privacyPolicyAction()}>
                    <LinkWrapper
                      text={privacyPolicyName}
                      url={privacyPolicyLink}
                      linkProps={{
                        className:
                          'privacy-policy-link WM_GLOBAL_secondary-font'
                      }}
                    />
                  </Controls>
                </S.LinkWrap>
              ) : null}
            </S.CopyRightWrap>
          </S.FooterContent>
          <SocialLinks
            className="socials"
            socials={socials}
            socialsMaxCount={validation.socials.max}
          />
        </S.FooterContentWrap>
      </WMContainer>
    </S.Container>
  )
})

export default Footer3
