/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Basic4.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    ${({ isEditing }) =>
      isEditing &&
      css`
        padding-top: 140px;
      `}
  }
`

const WMCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 10px;
    padding-left: 10px;
  }
  .control-container {
    width: 100%;
    h1 {
      padding-right: 0;
      padding-left: 0;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  margin-bottom: 0;
  padding-right: 10px;
  padding-left: 10px;
  box-sizing: border-box;
  text-align: center;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
  background: linear-gradient(
    to right,
    ${getStyleForProp('headingStart')} 0%,
    ${getStyleForProp('headingEnd')} 100%
  );
  ${({ theme }) =>
    theme.colorMode === 'DARK' &&
    css`
      &:not(.warning-max):not(.warning-near) {
        &:before {
          color: white;
        }
      }
    `}
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  &:before,
  &:after {
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    background: initial;
  }
  > span {
    display: block;
    box-sizing: border-box;
    padding-right: 5px;
    padding-left: 5px;
  }
  .link-wrapper {
    position: initial;
  }
  u {
    background: transparent;
    text-decoration: none !important;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  Title
}
