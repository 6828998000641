// @flow

import React from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { getLocalizedURL } from '@editor/common/utils/translations'
import { openLoginPopup } from '@actions/editor'
import ArrowRightIcon from '@editor/common/assets/svgr-icons/Arrow_right.svg'

import * as Styled from './styled'

import type { TSignInButtonProps } from './types'

const SignInButton = ({ openLoginPopup }: TSignInButtonProps) => (
  <Styled.SignInButton onClick={openLoginPopup}>
    <Styled.Icon>
      <ArrowRightIcon />
    </Styled.Icon>
    <Styled.LangText>{translate('sign_in_label')}</Styled.LangText>
  </Styled.SignInButton>
)

export default connect(null, { openLoginPopup })(SignInButton)
