/* @flow */
import produce from 'immer'
import type { TPricing3Actions } from './types.flow.js'

export default function reducer(state, action: TPricing3Actions) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    const currentPlan =
      action.value && action.value.planIndex !== 'undefined'
        ? draft.plans[action.value.planIndex]
        : null

    switch (action.type) {
      case 'CHANGE_PRIMARY_PLAN':
        draft.primaryPlanIdx = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'ADD_BLOCK':
        draft.plans.push(initialState.plans[0])
        break
      case 'REMOVE_SUBSCRIBTION_CARD':
        draft.plans.splice(action.value, 1)
        break
      case 'ADD_OPTION':
        draft.plans[action.value].options.push(initialState.plans[0].options[0])
        break
      case 'CHANGE_CARD_DATA':
        const { keyInStore, planIdx, data } = action.value
        draft.plans[planIdx][keyInStore] = data
        break
      case 'CHANGE_OPTION_VALUE': {
        const { idx, text } = action.value
        if (text === '') {
          currentPlan.options.splice(idx, 1)
        } else {
          currentPlan.options[idx] = text
        }
        break
      }
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.plans[btnIdx].buttonText = btnData.text
        draft.plans[btnIdx].buttonUrl = btnData.link
        break
      case 'CHANGE_ICON':
        const { idx, iconName } = action.value
        draft.plans[idx].icon = iconName
        break
      case 'REMOVE_OPTION': {
        const { planIdx, optionIdx } = action.value
        draft.plans[planIdx].options.splice(optionIdx, 1)
        break
      }
    }
  })
}
