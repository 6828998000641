import styled, { css } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > div:not(:last-child) {
    margin-right: 32px;
  }

  @media only screen and (max-width: 1100px) {
    max-width: 600px;
    padding: 0 20px;

    & > div:not(:last-child) {
      margin-right: 0;
    }

    & > div:nth-child(odd) {
      margin-right: 32px;
    }

    & > div:nth-last-child(-n + 2) {
      margin-top: 32px;
    }
  }

  @media only screen and (max-width: 600px) {
    padding: 0;

    & > div:not(:last-child) {
      margin-right: 0;
    }

    & > div:not(:first-child) {
      margin-top: 20px;
    }
  }
`

export const Title = styled.p`
  padding: 22px 16px 0;
  color: #1e2539;
  font: ${({ fontStyle }) => fontStyle};
  line-height: 24px;

  @media only screen and (max-width: 600px) {
    padding: 38px 10px;
    line-height: normal;
  }
`

export const Image = styled.img`
  width: 100%;
  height: auto;
  padding-top: 5px;
  z-index: 1;
  transition: transform 0.3s ease;

  @media only screen and (max-width: 600px) {
    width: 80px;
    height: 83px;
    padding: 10px 16px;
  }
`

export const Card = styled.div`
  position: relative;
  width: 240px;
  height: 320px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;

    ${({ isActive }) =>
      isActive
        ? css`
            border: 1px solid white;
            &:after {
              content: '';
              position: absolute;
              top: -3px;
              left: -3px;
              width: calc(100% + 6px);
              height: calc(100% + 6px);
              z-index: -1;
              background: linear-gradient(
                270.32deg,
                #6860df -25.52%,
                #f648a8 83.54%
              );
              border-radius: 21px;
            }
          `
        : css`
            border: 1px solid ${secondary.Light};
          `}}

  &:hover {
    ${Image} {
      transform: translateY(-5px);
    }
  }

    @media only screen and (max-width: 600px) {
        width: 100%;
        height: 105px;
        flex-direction: row-reverse;
        justify-content: flex-end;
    }
`
