/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA6.styled'
import { validation } from './CTA6.consts'
import ActionGetter from './Actions'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import Buttons from '@website/common/components/Buttons'

const CTA6 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      paragraphAlignment,
      imageUrl,
      buttons,
      imgDimensions,
      videoUrl,
      imgCropParams
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer>
      <S.WmCustomContainer>
        <S.ImageWrap isEditing={isEditing}>
          <S.ControlContainer isEditing={isEditing}>
            <ControlsWithImageCropper
              actions={Actions.imageActions(imageUrl)}
              style={{ width: '100%', height: '100%' }}
            >
              <Image
                defaultImgSrc={imageUrl}
                sizes={imgDimensions}
                alt="Section image"
                asProps={{
                  imgCropParams: imgCropParams
                }}
              />
            </ControlsWithImageCropper>
          </S.ControlContainer>
        </S.ImageWrap>
        <S.ContentContainer>
          <S.CtaContent>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Title}
                maxCount={validation.maxTitleChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.maxParagraphChar}
                required={validation.paragraph.required}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <Buttons data={buttons} />
          </S.CtaContent>
          <S.VideoWrapper>
            <ControlsWithModalOpener
              actions={Actions.getVideoActions(false, videoUrl)}
              style={{ width: '100%' }}
            >
              <S.VideoContent>
                <Overlay />
                <S.IframeWrap>
                  <LazyVideoPlayer
                    style={{
                      top: 0,
                      left: 0,
                      position: 'absolute'
                    }}
                    controls
                    width="100%"
                    height="100%"
                    url={videoUrl}
                  />
                </S.IframeWrap>
              </S.VideoContent>
            </ControlsWithModalOpener>
          </S.VideoWrapper>
        </S.ContentContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default CTA6
