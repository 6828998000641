/* @flow */
import React, { useContext, memo } from 'react'
import S from './CTA9.styled'
import { validation } from './CTA9.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SingleButton from '@website/common/components/SingleButton'

const CTA9 = memo(props => {
  const {
    data: {
      images,
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      btnText,
      btnUrl
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const renderImage = (imgData, idxCount) => {
    return (
      <Mapper
        data={imgData}
        render={(image: string, idx: number) => {
          return (
            <S.ImagesContainer key={idx}>
              <S.ControlContainer>
                <ControlsWithImageCropper
                  actions={Actions.imageActions(idx + idxCount, image.imgUrl)}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    alt="item img"
                    sizes={image.imgDimensions}
                    defaultImgSrc={image.imgUrl}
                    asProps={{
                      imgCropParams: image.imgCropParams
                    }}
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.ImagesContainer>
          )
        }}
      />
    )
  }
  return (
    <S.StyledContainer isEditing={isEditing}>
      {renderImage(images.slice(0, 1), 0)}
      <Show when={[title, paragraph, btnText]}>
        <S.TextContainer>
          <S.TextContent>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              required={validation.title.required}
              maxCount={validation.titleMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              as={S.Paragraph}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <SingleButton btnUrl={btnUrl} btnText={btnText} />
          </S.TextContent>
        </S.TextContainer>
      </Show>
      {renderImage(images.slice(1, 2), 1)}
    </S.StyledContainer>
  )
})

export default CTA9
