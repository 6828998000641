import styled, { css } from 'styled-components'

import { globalColors } from '@renderforest/rf-ui-library/consts/colors'
import { WIDGETS, SETTINGS } from '@containers/edit/consts'

export const MainSidebarContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  overflow-x: hidden;
  background-color: ${({ sidebarActiveItem }) =>
    sidebarActiveItem === '' ? 'white' : '#f8fbff'};
`

export const MainSidebarMidPart = styled.div`
  display: flex;
  align-items: flex-end;
  flex-grow: 1;
  border-right: 1px solid ${globalColors.secondaryExtraLight};
  border-bottom: 1px solid ${globalColors.secondaryExtraLight};
`

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  svg {
    fill: ${({ isActive }) =>
      isActive ? globalColors.secondaryDark : globalColors.secondarySemiDark};
    width: 22px;
    height: 22px;
    ${({ isLastItem, isMainSidebarExpanded }) =>
      isLastItem &&
      !isMainSidebarExpanded &&
      css`
        transform: rotate(180deg);
      `}
  }
  ${({ hasNew, isMainSidebarExpanded }) =>
    hasNew && !isMainSidebarExpanded
      ? css`
          @keyframes pulse-dot {
            0% {
              box-shadow: 0 0 0 0 rgba(93, 83, 214, 0.5);
            }
            100% {
              box-shadow: 0 0 0 5px rgba(93, 83, 214, 0.5);
            }
          }
          &::after {
            content: '';
            position: absolute;
            right: -5px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #5d53d6;
            animation: pulse-dot 2s infinite;
          }
        `
      : ''};
`

export const Label = styled.span`
  font-size: 14px;
  margin-left: 13px;
  color: ${({ isActive }) =>
    isActive ? globalColors.secondaryDark : globalColors.secondarySemiDark};
  width: ${({ isMainSidebarExpanded }) => (isMainSidebarExpanded ? 'auto' : 0)};
  opacity: ${({ isMainSidebarExpanded }) => (isMainSidebarExpanded ? 1 : 0)};
`

export const MainSidebarItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 21px;
  white-space: nowrap;
  border-bottom: 1px solid ${globalColors.secondaryExtraLight};
  border-right: ${({ isActive, sidebarActiveItem }) =>
    isActive &&
    (sidebarActiveItem === WIDGETS || sidebarActiveItem === SETTINGS)
      ? `1px solid ${globalColors.secondaryExtraLight}`
      : isActive
      ? '1px solid transparent'
      : `1px solid ${globalColors.secondaryExtraLight}`};
  background: ${({ isActive, sidebarActiveItem, isLastItem }) =>
    isLastItem && sidebarActiveItem !== ''
      ? 'linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 52%, #EEF5FF 100%)'
      : isLastItem
      ? 'linear-gradient(180deg, #FFFFFF 0%, #EEF5FF 52%, #EEF5FF 100%)'
      : sidebarActiveItem === '' || isActive
      ? 'white'
      : '#f8fbff'};
  &:hover {
    background: ${globalColors.primaryLight};
    &:hover ${IconWrapper} {
      ${({ isMainSidebarExpanded, isLastItem }) =>
        (isMainSidebarExpanded && !isLastItem) ||
        (isLastItem && !isMainSidebarExpanded)
          ? css`
              transform: translateX(3px);
              transition: transform 0.3s;
            `
          : isLastItem && isMainSidebarExpanded
          ? css`
              transform: translateX(-3px);
              transition: transform 0.3s;
            `
          : !isMainSidebarExpanded && !isLastItem
          ? css`
              transform: scale(1.1);
              transition: transform 0.3s;
            `
          : ''}
    }

    svg {
      fill: ${globalColors.secondaryDark};
    }

    &:hover ${Label} {
      color: ${globalColors.secondaryDark};
      ${({ isMainSidebarExpanded, isLastItem }) =>
        (isMainSidebarExpanded && !isLastItem) ||
        (isLastItem && !isMainSidebarExpanded)
          ? css`
              transform: translateX(3px);
              transition: transform 0.3s;
            `
          : isLastItem && isMainSidebarExpanded
          ? css`
              transform: translateX(-3px);
              transition: transform 0.3s;
            `
          : ''}
    }
  }
`

export const NewLabel = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  top: -1px;
  right: -1px;
`
