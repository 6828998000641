// @flow

import React, { memo } from 'react'
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory
} from 'react-router-dom'

import Pages from './pages'

const Container = () => {
  const history = useHistory()
  const { path } = useRouteMatch()
  const { search = '' } = history.location

  return (
    <Switch>
      <Route path={`${path}/:page`}>
        <Pages />
      </Route>
      <Redirect to={`${path}/home${search}`} />
    </Switch>
  )
}

export default memo(Container)
