import styled, { createGlobalStyle } from 'styled-components'

import THEME from './theme'
import { secondary } from '@website/common/styles/colors'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

export const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
  }
`

export const CookieBannerWrapper = styled.div`
  width: 100%;
  padding: 20px 80px;
  max-width: 100%;
  box-sizing: border-box;
  background-color: ${getStyleForProp('background')};
  box-shadow: 0px -1px 54px rgba(0, 0, 0, 0.3);
  position: fixed;
  bottom: 0;
  z-index: 21;

  @media only screen and (max-width: 992px) {
    padding: 20px 20px 0;
  }
`

export const CookieBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 1366px) {
    flex-direction: column;
  }
`

export const ConsentText = styled.p`
  display: inline;
  font-size: 14px;
  color: ${getStyleForProp('text')};
  max-height: 100px;
  padding-right: 10px;
  overflow-y: auto;

  & > span {
    & > div {
      display: inline;
    }
  }

  .privacy-policy-link {
    color: ${getStyleForProp('text')};
    font-size: 14px;
    margin-left: 5px;
    &:hover {
      opacity: 0.6;
    }
  }

  &::-webkit-scrollbar {
    background-color: ${secondary.ExtraLight};
    width: 5px;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background: ${secondary.Dark};
    border-radius: 5px;
  }

  @media only screen and (max-width: 992px) {
    max-height: 70px;
  }
`

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  @media only screen and (max-width: 1366px) {
    margin-top: 30px;
  }

  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }

  .settings-btn-link {
    &:hover {
      opacity: 0.6;
    }
  }
`

export const Button = styled.div`
  border-radius: ${getStyleForProp('buttonRadius')};
  ${({ isTertiary }) =>
    isTertiary
      ? getStyleForProp('buttonBackgroundTertiary')
      : getStyleForProp('buttonBackground')};
  background-repeat: no-repeat;
  max-width: 245px;
  min-width: 100px;
  height: 50px;
  padding: 0 40px;
  font-size: 14px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  margin-left: 30px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0;
    width: 100%;
  }
`

export const LinkButton = styled.div`
  color: ${getStyleForProp('text')};
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 30px;

  @media only screen and (max-width: 992px) {
    margin-bottom: 30px;
    margin-left: 0;
  }
`
