/* @flow */
import styled from 'styled-components'

import THEME from './Feature25.theme'
import WMContainer from '@website/common/components/WMContainer'
import {
  getBackground,
  getTextStyleByAlignment,
  styleProvider
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  background: ${getBackground(THEME)};
  flex-direction: column;
  position: relative;
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 2;
  > *:first-child {
    margin-top: 0;
    padding-top: 0;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-top: 20px;
`

const Block = styled.div`
  position: relative;
  z-index: ${({ idx }) => 20 - idx};
  margin: ${({ isEditing }) => (isEditing ? '30px 20px' : '20px')};
  @media only screen and (max-width: 430px) {
    width: 100%;
    margin: ${({ isEditing }) => (isEditing ? '30px auto' : '20px auto')};
  }
`

const BlockItemWrap = styled.article`
  width: 350px;
  height: 100%;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  display: flex;
  margin: auto;
  align-items: center;
  box-sizing: border-box;
  word-break: break-word;
  @media only screen and (max-width: 430px) {
    width: 100%;
    max-width: 350px;
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  max-width: ${({ isFourBlock }) => (isFourBlock ? '800px' : '1250px')};
  flex-wrap: wrap;
  justify-content: center;
  margin: -20px auto;
  padding-top: 50px;
  @media only screen and (max-width: 1024px) {
    padding-top: 40px;
  }
  .disappearing {
    ${({ isEditing }) =>
      isEditing ? 'margin: 20px' : 'margin: 30px 20px !important'};
    @media only screen and (max-width: 430px) {
      width: 100%;
      margin: 20px auto;
    }
    ${Block} {
      margin: 0;
      height: 100%;
    }
  }
`

const BlockTitle = styled.h2`
  color: #fff;
  font-weight: 700;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: #fff;
  font-weight: 400;
  ${getTextStyleByAlignment};
  margin: 4px 0 0 0;
`

const IconContainer = styled.div`
  display: flex;
  width: 82px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  background-color: ${getStyleForProp('iconBackground')};
  border-top-left-radius: ${getStyleForProp('borderRadius')};
  border-bottom-left-radius: ${getStyleForProp('borderRadius')};
  .block-icon {
    svg {
      fill: #fff;
    }
  }
`

const BlockWrap = styled.div`
  display: flex;
  width: calc(100% - 82px);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 30px 24px 30px 20px;
  box-sizing: border-box;
  background-color: ${getStyleForProp('blockBackground')};
  border-top-right-radius: ${getStyleForProp('borderRadius')};
  border-bottom-right-radius: ${getStyleForProp('borderRadius')};
  transform: translateX(-1px);
  & > .control-container {
    margin-bottom: 10px;
    width: 100%;
    h2 {
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
    }
  }
`

const MorphLeftWrapper = styled.div`
  width: 569px;
  position: absolute;
  left: 0;
  bottom: 0;
  svg {
    width: 100%;
  }
  @media only screen and (max-width: 1024px) {
    width: 55%;
  }
`

const MorphRightWrapper = styled.div`
  width: 534px;
  position: absolute;
  right: 0;
  top: 9%;
  svg {
    width: 100%;
  }
  @media only screen and (max-width: 1200px) {
    top: 2%;
  }
  @media only screen and (max-width: 1024px) {
    width: 55%;
    top: 0;
  }
  @media only screen and (max-width: 650px) {
    top: -20px;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  BlocksWrapper,
  Title,
  Description,
  Block,
  BlockItemWrap,
  BlockTitle,
  BlockParagraph,
  IconContainer,
  BlockWrap,
  MorphRightWrapper,
  MorphLeftWrapper
}
