/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const addImage = () => {
    dispatcher({
      type: 'ADD_IMAGE'
    })
  }

  const addBlock = (afterIdx: number) => {
    dispatcher({
      type: 'ADD_BLOCK',
      value: afterIdx
    })
  }

  const removeBlock = (idx: number) => {
    dispatcher({
      type: 'REMOVE_BLOCK',
      value: idx
    })
  }

  const changeSubTitle = (newSubTitle: string) => {
    dispatcher({
      type: 'CHANGE_SUB_TITLE',
      value: newSubTitle
    })
  }

  const imageActions = (blockIdx: number, idx: number, imageUrl: string) => {
    return cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'portfolio6_2',
      additionalArgs: { idx, blockIdx },
      showRemove: false,
      imageUrl
    })
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const changeSubtitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeSubTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeSubtitleAlignment,
    addBlock,
    removeBlock,
    addImage,
    imageActions
  }
}
