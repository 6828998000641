/* @flow */
import React from 'react'
import { createPortal } from 'react-dom'

import Icon from '@website/common/components/Icon'
import Mapper from '../Mapper'
import If from '../Conditional'
import { KEY_CODES } from '../../../conf/consts'
import { white } from '@website/common/styles/colors'
import './index.sass'

const CONTENT = ['Header', 'Content', 'Footer']

class Modal extends React.PureComponent<any> {
  isMouseDownOnModal: boolean = false
  actualModal: HTMLElement | null = null

  checkIsOnOutside = (e): boolean =>
    this.actualModal && !this.actualModal.contains(e.target)

  onMouseDown = (e): void => {
    this.isMouseDownOnModal = !this.checkIsOnOutside(e)
  }

  onMouseUp = (e): void => {
    const onMouseUpOutside = this.checkIsOnOutside(e)
    if (!this.isMouseDownOnModal && onMouseUpOutside) {
      this.props.onModalClose()
    }
  }

  onKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === KEY_CODES.ESC) {
      this.props.onModalClose()
    }
  }

  renderModal = () => (
    <div
      role="button"
      aria-hidden="true"
      className={`rf-modal ${this.props.wrapClassname}`}
      onMouseDown={this.onMouseDown}
      onTouchStart={this.onMouseDown}
      onMouseUp={this.onMouseUp}
      onTouchEnd={this.onMouseUp}
    >
      <div
        role="button"
        aria-hidden="true"
        className="rf-modal-close-button"
        onClick={() => this.props.onModalClose()}
      >
        <Icon name="close" color={white} size="xlarge" />
      </div>
      <div
        className="rf-modal-wrap"
        ref={(r: HTMLDivElement) => (this.actualModal = r)}
      >
        <Mapper
          data={CONTENT}
          render={(content, idx) => (
            <If
              key={idx}
              condition={!!this.props[`render${content}`]}
              then={() => (
                <div className={`rf-modal-${content}`}>
                  {this.props[`render${content}`]()}
                </div>
              )}
            />
          )}
        />
      </div>
    </div>
  )

  render() {
    return typeof document !== 'undefined'
      ? createPortal(this.renderModal(), document.body)
      : null
  }
}

export default Modal
