//@flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TPopupContentType } from './types'
import Switcher from './Switcher'
import { COOKIE_ALL_ACCEPTED_STATE } from '../consts'

const PopupContent = ({
  state = COOKIE_ALL_ACCEPTED_STATE,
  disabled = false,
  mainContent: { title, description, buttonText },
  categories,
  onConfirm,
  handleStateChange
}: TPopupContentType) => {
  const _handleStateChange = category => value => {
    handleStateChange(category, value)
  }

  const _onConfirm = () => {
    onConfirm(state)
  }

  return (
    <Styled.PopupContainer>
      <Styled.PopupContent disabled={disabled}>
        <Styled.TitleContent>
          <Styled.Title className="WM_GLOBAL_primary-font">
            {title}
          </Styled.Title>
          <Styled.Description className="WM_GLOBAL_secondary-font">
            {description}
          </Styled.Description>
        </Styled.TitleContent>
        <Styled.Content className="WM_GLOBAL_secondary-font">
          {categories.map(item => {
            const onSwitcherChange = _handleStateChange(item.type)
            const isSwitcherChecked = state[item.type]
            return (
              <>
                <Styled.Block>
                  <Styled.TitleContainer>
                    <Styled.BlockTitle>{item.title}</Styled.BlockTitle>
                    {item.type !== 'essential' ? (
                      <Switcher
                        isChecked={isSwitcherChecked}
                        onClick={!disabled ? onSwitcherChange : null}
                      />
                    ) : null}
                  </Styled.TitleContainer>
                  <Styled.Description>{item.description}</Styled.Description>
                </Styled.Block>
              </>
            )
          })}
        </Styled.Content>
      </Styled.PopupContent>
      <Styled.PopupFooter
        disabled={disabled}
        className="WM_GLOBAL_secondary-font"
      >
        <Styled.Button role="button" onClick={!disabled ? _onConfirm : null}>
          {buttonText}
        </Styled.Button>
      </Styled.PopupFooter>
    </Styled.PopupContainer>
  )
}

export default memo(PopupContent)
