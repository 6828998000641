/* @flow */
import React, { PureComponent } from 'react'
import TimePickerInput from './TimePickerInput'
import DatePickerInput from './DatePickerInput'
import { TLinkSharePopupProps } from '../types'
import * as S from '../styled.js'
import '../index.sass'
class LinkSharePopup extends PureComponent<TLinkSharePopupProps, *> {
  render() {
    const { onDaySelect, day, time, onTimeSelect, waitingForLink } = this.props
    return (
      <S.FormContainer>
        <DatePickerInput
          day={day}
          onDaySelect={onDaySelect}
          waitingForLink={waitingForLink}
        />
        <TimePickerInput
          onTimeSelect={onTimeSelect}
          time={time}
          waitingForLink={waitingForLink}
        />
      </S.FormContainer>
    )
  }
}

export default LinkSharePopup
