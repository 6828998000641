// @flow

import React, { memo, useState, useCallback, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useBackdropClick } from '@hooks'
import * as Styled from './styled'
import { FLYOUT_SHIFT, ALIGNMENT_TYPES } from './consts'
import type { TFlyOutProps, TFlyOutOffsets } from './types'

const FlyOut = ({
  anchorEl,
  isOpen = false,
  withAnimation = true,
  isPositionFixed = true,
  alignment = ALIGNMENT_TYPES.LEFT,
  shift = FLYOUT_SHIFT,
  children,
  className = '',
  animationPrefix,
  renderingContainer,
  backdropDisablingSelectors = [],
  onClose,
  onClick
}: TFlyOutProps) => {
  const [isMounted, setMountedState] = useState(isOpen)
  const [flyoutHeight, setFlyoutHeight] = useState(0)
  const [flyoutWidth, setFlyoutWidth] = useState(0)

  const containerRef = useBackdropClick(() => {
    onClose()
  }, backdropDisablingSelectors)

  useEffect(() => {
    if (isOpen) {
      setMountedState(true)
    }
  }, [isOpen])

  const handleAnimationEnd = useCallback(() => {
    if (!isOpen) {
      setMountedState(false)
    }
  }, [isOpen])

  const setDimensions = useCallback(el => {
    if (!el) {
      return
    }
    const { height, width } = el.getBoundingClientRect()

    setFlyoutHeight(height)
    setFlyoutWidth(width)
  }, [])

  const getOffsets = useCallback((): TFlyOutOffsets => {
    if (!anchorEl) {
      return { top: 0, left: 0 }
    }

    const { x, y } = shift
    const { bottom, left, width } = anchorEl.getBoundingClientRect()

    if (alignment === ALIGNMENT_TYPES.LEFT) {
      return isPositionFixed
        ? { top: bottom + y, left: left + x }
        : { top: y, left: x }
    }

    if (alignment === ALIGNMENT_TYPES.CENTER) {
      return isPositionFixed
        ? { top: bottom + y, left: left + width / 2 - flyoutWidth / 2 }
        : { top: y, left: -(flyoutWidth / 2 - width / 2) }
    }

    const right = window.innerWidth - (left + width)

    return isPositionFixed
      ? { top: bottom + y, right: right - x }
      : { top: y, right: x }
  }, [anchorEl, alignment, isPositionFixed, flyoutWidth])

  const containerClassName = className ? `${className}_container` : ''

  if (!withAnimation) {
    return isOpen
      ? ReactDOM.createPortal(
          <Styled.FlyoutContainer
            offsets={getOffsets()}
            ref={containerRef}
            className={containerClassName}
            isPositionFixed={isPositionFixed}
            onClick={onClick}
          >
            <div className={className} ref={setDimensions}>
              {children}
            </div>
          </Styled.FlyoutContainer>,
          renderingContainer || document.body
        )
      : null
  }

  return isMounted
    ? ReactDOM.createPortal(
        <Styled.FlyOutContainerWithAnimation
          offsets={getOffsets()}
          isOpen={isOpen}
          isPositionFixed={isPositionFixed}
          height={flyoutHeight}
          ref={containerRef}
          className={containerClassName}
          animationPrefix={animationPrefix}
          onAnimationEnd={handleAnimationEnd}
          onClick={onClick}
        >
          <div className={className} ref={setDimensions}>
            {children}
          </div>
        </Styled.FlyOutContainerWithAnimation>,
        renderingContainer || document.body
      )
    : null
}

export default memo(FlyOut)
