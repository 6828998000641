/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_INFO_CONTAINER_TITLE':
        draft.name = action.value
        break
      case 'CHANGE_MAP_DATA':
        draft.mapSrc = action.value.mapSrc
        draft.geoLocation = action.value.geoLocation
        break
      case 'CHANGE_LOCATION':
        draft.location = action.value
        break
      case 'CHANGE_PHONE':
        draft.phone = action.value
        break
      case 'CHANGE_EMAIL':
        draft.email = action.value
        break
      case 'REMOVE_LOCATION':
        draft.location = ''
        break
      case 'ADD_LOCATION':
        draft.location = initialState.location
        break
      case 'REMOVE_PHONE':
        draft.phone = ''
        break
      case 'ADD_PHONE':
        draft.phone = initialState.phone
        break
      case 'REMOVE_EMAIL':
        draft.email = ''
        break
      case 'ADD_EMAIL':
        draft.email = initialState.email
        break
      case 'CHANGE_TITLE_ALIGNMENT':
        draft.titleAlignment = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT':
        draft.paragraphAlignment = action.value
        break
      case 'CHANGE_NAME_ALIGNMENT':
        draft.nameAlignment = action.value
        break
    }
  })
}
