/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Team9.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  width: 100%;
  flex-direction: column;
  padding: ${({ isEditing }) => (isEditing ? '140px 0 180px 0' : '80px 0')};
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
  > :first-child {
    margin-top: 0;
  }
  .control-container {
    width: 100%;
  }
`

const WmCustomContainer = styled(WMContainer)`
  justify-content: space-between;
  max-width: 1370px;
  align-items: center;
  padding-top: 0;
  margin-top: 50px;
  @media only screen and (max-width: 992px) {
    max-width: 640px;
    margin-top: 40px;
  }
`

const TextImageContent = styled.div`
  width: 100%;
  max-width: 670px;
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 112%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 112%;
    height: initial;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const SubTitle = styled.h2`
  color: ${getStyleForProp('subheading')};
  margin: 0;
  margin-bottom: 30px;
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (min-width: 992px) {
    margin-bottom: 40px;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const BottomBlockWrapper = styled.div`
  width: ${({ isImage, isEditing }) =>
    !isEditing && !isImage ? '100%' : 'calc(100% - 215px)'};
  max-width: 315px;
  ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
      left: -70px;
      bottom: 70px;
    `}
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      right: -70px;
    `}
  @media only screen and (max-width: 992px) {
    position: initial;
    left: initial;
    bottom: initial;
    max-width: 300px;
  }
  @media only screen and (max-width: 650px) {
    width: ${({ isImage, isEditing }) =>
      !isEditing && !isImage ? '100%' : 'calc(100% - 165px)'};
  }
`

const BottomBlockTitle = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 10px;
  font-weight: 600;
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (max-width: 450px) {
    font-size: 14px;
  }
`

const BottomBlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  font-weight: 500;
  font-size: 14px;
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (max-width: 450px) {
    font-size: 14px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
  @media only screen and (max-width: 992px) {
    ${({ theme }) =>
      theme.orientation === 'LEFT'
        ? css`
            margin-bottom: 40px;
          `
        : css`
            margin-top: 40px;
          `}
  }
`

const SmallImageContainer = styled.div`
  width: 30%;
  max-width: 200px;
  max-height: 160px;
`

const SmallImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  ${({ imgRatio }) =>
    imgRatio < 0.8
      ? css`
          position: relative;
          padding-top: ${imgRatio * 100}%;
          & > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        `
      : css`
          min-height: 160px;
          @media only screen and (max-width: 650px) {
            min-height: 120px;
          }
        `};
  @media only screen and (max-width: 450px) {
    max-width: 150px;
  }

  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const ImgSmall = styled.img`
  width: 100%;
  height: auto;
  max-width: 200px;
  max-height: 160px;
  display: block;
  object-fit: contain;
`

const ParagraphsWrap = styled.div`
  width: 100%;
  max-width: 630px;
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'RIGHT' ? '0 50px 0 0' : '0 0 0 50px'};
    `}
  @media only screen and (max-width: 992px) {
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .control-container {
    margin-bottom: 20px;
    width: 100%;
    p,
    h2,
    h1 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }
`

const BottomBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media only screen and (min-width: 991px) {
    margin-bottom: ${({ isEditing }) => (isEditing ? '75px' : '0')};
  }

  & ${ImageWrapper} {
    max-width: 200px;
  }
  .TextImageContent {
    max-width: 670px;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Paragraph,
  BottomBlockParagraph,
  BottomBlockTitle,
  TextImageContent,
  BottomBlockWrapper,
  SmallImageContainer,
  SmallImageWrapper,
  ImageWrapper,
  Img,
  ImgSmall,
  ControlContainer,
  ParagraphsWrap,
  BottomBlock,
  SubTitle
}
