/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Feature27.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
        `
      : css``};
  @media only screen and (max-width: 781px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding-top: 50px;
          `
        : css``};
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1470px;
  margin: -20px auto;
`

const BlockItemWrap = styled.div`
  width: 240px;
  height: 100%;
  display: flex;
  margin: auto;
  align-items: center;
  box-sizing: border-box;
  word-break: break-word;
  @media only screen and (min-width: 491px) {
    border-right: 1px solid ${getStyleForProp('blockParagraph')};
  }
  @media only screen and (max-width: 490px) {
    position: relative;
  }
`

const Block = styled.div`
  margin: 20px 0;
  @media only screen and (max-width: 490px) {
    position: relative;
    &:not(:last-child) {
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        height: 1px;
        width: 84px;
        background-color: ${getStyleForProp('blockParagraph')};
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  &:nth-child(5n) {
    .block-wrap > .control-container {
      .styled-controls {
        right: 0;
        left: unset;
      }
    }
  }
  @media only screen and (max-width: 1400px) {
    &:nth-child(4n) {
      .block-wrap > .control-container {
        .styled-controls {
          right: 0;
          left: unset;
        }
      }
    }
  }
  @media only screen and (max-width: 1130px) {
    &:nth-child(3n) {
      .block-wrap > .control-container {
        .styled-controls {
          right: 0;
          left: unset;
        }
      }
    }
  }
`

const BlocksWrapper = styled.div`
  display: flex;
  max-width: 1470px;
  flex-wrap: wrap;
  justify-content: center;
  @media only screen and (max-width: 1400px) {
    max-width: 1000px;
  }
  @media only screen and (max-width: 1130px) {
    max-width: 800px;
  }
  @media only screen and (max-width: 880px) and (min-width: 491px) {
    width: 484px;
  }
  @media only screen and (max-width: 490px) {
    max-width: 300px;
    flex-direction: column;
  }

  ${Block} {
    @media only screen and (min-width: 1621px) {
      &:nth-child(6n) {
        ${BlockItemWrap} {
          border-right: none;
        }
      }
      ${({ isSevenBlock }) =>
        css`
          ${isSevenBlock
            ? css`
                &:nth-last-child(3) {
                  margin-right: 100px;
                  ${BlockItemWrap} {
                    border-right: none;
                  }
                }
                &:nth-last-child(7) {
                  margin-left: 100px;
                }
                &:nth-last-child(2) {
                  ${BlockItemWrap} {
                    border-right: 1px solid ${getStyleForProp('blockParagraph')};
                  }
                }
              `
            : ''}
        `}
    }
    @media only screen and (max-width: 1620px) and (min-width: 1401px) {
      &:nth-child(5n) {
        ${BlockItemWrap} {
          border-right: none;
        }
      }
      ${({ isSixBlock }) =>
        css`
          ${isSixBlock
            ? css`
                &:nth-last-child(3) {
                  margin-right: 100px;
                  ${BlockItemWrap} {
                    border-right: none;
                  }
                }
                &:nth-last-child(6) {
                  margin-left: 100px;
                }
                &:nth-last-child(2) {
                  ${BlockItemWrap} {
                    border-right: 1px solid ${getStyleForProp('blockParagraph')};
                  }
                }
              `
            : ''}
        `}
    }
    @media only screen and (max-width: 1400px) and (min-width: 1131px) {
      &:nth-child(4n) {
        ${BlockItemWrap} {
          border-right: none;
        }
      }
      ${({ isFiveBlock }) =>
        css`
          ${isFiveBlock
            ? css`
                &:nth-last-child(3) {
                  margin-right: 90px;
                  ${BlockItemWrap} {
                    border-right: none;
                  }
                }
                &:nth-last-child(5) {
                  margin-left: 90px;
                }
                &:nth-last-child(2) {
                  ${BlockItemWrap} {
                    border-right: 1px solid ${getStyleForProp('blockParagraph')};
                  }
                }
              `
            : ''}
        `}
    }
    @media only screen and (max-width: 1130px) and (min-width: 881px) {
      &:nth-child(3n) {
        ${BlockItemWrap} {
          border-right: none;
        }
      }
      ${({ isFourBlock }) =>
        css`
          ${isFourBlock
            ? css`
                &:nth-last-child(3) {
                  margin-right: 110px;
                  ${BlockItemWrap} {
                    border-right: none;
                  }
                }
                s &:nth-last-child(4) {
                  margin-left: 110px;
                }
                &:nth-last-child(2) {
                  ${BlockItemWrap} {
                    border-right: 1px solid ${getStyleForProp('blockParagraph')};
                  }
                }
              `
            : ''}
        `}
    }
    @media only screen and (max-width: 880px) and (min-width: 491px) {
      &:nth-child(even) {
        ${BlockItemWrap} {
          border-right: none;
        }
      }
    }
    &:last-child {
      ${BlockItemWrap} {
        border-right: none;
      }
    }
    @media only screen and (max-width: 490px) {
      width: auto;
      max-width: 100%;
      justify-content: center;
      margin: 20px auto;
    }
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  font-weight: 700;
  width: 100%;
  margin-bottom: ${({ isEditing }) =>
    isEditing ? 'calc(0.3rem + 6px)' : '0.3rem'};
  &:after {
    left: 0;
  }
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  width: 100%;
  font-weight: 400;
`

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  margin: ${({ isEditing }) => (isEditing ? 'initial' : '0 30px')};
  > div {
    width: 45px;
    height: 45px;
    svg {
      max-width: 45px;
      max-height: 45px;
      fill: ${getStyleForProp('icon')};
    }
  }
`

const IconContainer = styled.div`
  display: flex;
  width: 105px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  .control-container {
    margin: 0 30px;
  }
`

const BlockWrap = styled.div`
  display: flex;
  width: calc(100% - 105px);
  max-width: calc(100% - 105px);
  height: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 5px 20px 5px 0;
  box-sizing: border-box;
  transform: translateX(-1px);
  z-index: 1;
  @media only screen and (max-width: 450px) {
    width: auto;
  }
  & > .control-container {
    width: 100%;
    margin-top: 30px;
    h2 {
      margin-bottom: 0;
    }
    p {
      margin-top: 0;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  BlocksWrapper,
  Block,
  BlockItemWrap,
  BlockTitle,
  BlockParagraph,
  IconContainer,
  IconWrap,
  BlockWrap
}
