/* @flow */
import React from 'react'
import S from './Contact4.styled'
import ActionGetter from './Actions'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import { validation } from './Contact4.consts'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Overlay from '@website/common/components/ControlsMobileOverlay/ControlsMobileOverlay'
import { getMapSrcWithLatLng } from '@website/common/utils'
import { translate } from '@editor/common/utils/translations'

class Contact4 extends React.PureComponent<void, void> {
  addInfoButton = (key: 'location' | 'phone' | 'email', label) => Actions =>
    (
      <AddButton
        onAdd={() => Actions.addInfo(key)}
        isText
        toShow
        style={{
          marginTop: '10px'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {label}
      </AddButton>
    )

  locationInfo = () => Actions => {
    const { location } = this.props.data
    return (
      <ControlsWithModalOpener
        actions={Actions.infoActions('location')}
        style={{ width: '100%' }}
      >
        <S.LocationWrap>
          <S.Icon size="large" name="icon-pin" />
          <EditableContent
            text={location}
            as={S.Location}
            maxCount={validation.contactMaxChar}
            className="WM_GLOBAL_paragraph14"
            onChange={(newLocation: string) => {
              Actions.infoChangeAction('location', newLocation)
            }}
          />
        </S.LocationWrap>
      </ControlsWithModalOpener>
    )
  }
  anchorInfo = (key: 'phone' | 'email', isEditing: boolean) => Actions => {
    const { [key]: text } = this.props.data
    const InfoWrapper = isEditing ? S.EmailWrap : S.EmailWrapA
    const hrefLinks = {
      phone: 'tel',
      email: 'mailto'
    }
    return (
      <ControlsWithModalOpener
        actions={Actions.infoActions(key)}
        style={{ width: '100%' }}
      >
        <InfoWrapper href={hrefLinks[key] + ':' + text} className="info">
          <S.Icon size="large" name={`icon-${key}`} />
          <EditableContent
            text={text}
            as={S.Phone}
            className="WM_GLOBAL_paragraph14"
            maxCount={validation.contactMaxChar}
            onChange={(info: string) => Actions.infoChangeAction(key, info)}
          />
        </InfoWrapper>
      </ControlsWithModalOpener>
    )
  }

  render() {
    const {
      data: {
        title,
        titleAlignment,
        paragraph,
        paragraphAlignment,
        mapSrc,
        geoLocation,
        location,
        phone,
        email,
        name: infoContainerTitle,
        nameAlignment
      }
    } = this.props

    const _mapSrc = geoLocation ? getMapSrcWithLatLng(geoLocation) : mapSrc
    const Actions = ActionGetter(this.context)

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.StyledContainer isEditing={isEditing}>
              <S.Content>
                <Show when={[title]}>
                  <EditableContent
                    text={title}
                    as={S.Title}
                    alignment={titleAlignment}
                    maxCount={validation.headingMaxChar}
                    onChange={Actions.changeTitle}
                    className="WM_GLOBAL_heading"
                    changeAlignment={Actions.changeTitleAlignment}
                  />
                </Show>
                <Show when={[paragraph]}>
                  <EditableContent
                    text={paragraph}
                    as={S.Paragraph}
                    alignment={paragraphAlignment}
                    maxCount={validation.paragraphMaxChar}
                    onChange={Actions.changeParagraph}
                    className="WM_GLOBAL_paragraph"
                    changeAlignment={Actions.changeParagraphAlignment}
                  />
                </Show>
              </S.Content>
              <ControlsWithModalOpener
                actions={Actions.getMapActions(mapSrc, geoLocation)}
                style={{ width: '100%', height: '100%' }}
              >
                <S.MapWrap>
                  <Overlay />
                  <S.Map
                    title="Map"
                    src={_mapSrc}
                    frameBorder="0"
                    allowFullScreen=""
                  />
                  <Show when={[location, phone, email]}>
                    <S.ContactInfo isEditing={isEditing}>
                      <Show when={[infoContainerTitle]}>
                        <EditableContent
                          text={infoContainerTitle}
                          as={S.InfoContainerTitle}
                          alignment={nameAlignment}
                          maxCount={validation.contactMaxChar}
                          onChange={Actions.changeInfoContainerTitle}
                          className="WM_GLOBAL_secondary-font"
                          changeAlignment={Actions.changeNameAlignment}
                        />
                        <S.Divider />
                      </Show>
                      <S.InfoWrap>
                        <If
                          condition={location}
                          otherwise={() =>
                            this.addInfoButton(
                              'location',
                              translate('add_location_label')
                            )(Actions)
                          }
                          then={() => this.locationInfo()(Actions)}
                        />
                        <If
                          condition={phone}
                          otherwise={() =>
                            this.addInfoButton(
                              'phone',
                              translate('add_phone_label')
                            )(Actions)
                          }
                          then={() =>
                            this.anchorInfo('phone', isEditing)(Actions)
                          }
                        />
                        <If
                          condition={email}
                          otherwise={() =>
                            this.addInfoButton(
                              'email',
                              translate('add_email_label')
                            )(Actions)
                          }
                          then={() =>
                            this.anchorInfo('email', isEditing)(Actions)
                          }
                        />
                      </S.InfoWrap>
                    </S.ContactInfo>
                  </Show>
                </S.MapWrap>
              </ControlsWithModalOpener>
            </S.StyledContainer>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

Contact4.contextType = DispatchContext

export default Contact4
