/* @flow */
import { videoModalActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  changeBlockTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addBlock
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockTitle: (newTitle: string, idx: number) =>
      dispatcher(changeBlockTitle(newTitle, idx)),
    addBlock: () => dispatcher(addBlock()),
    videoActions: videoModalActionMaker(dispatcher)
  }
}
