/* @flow */
import {
  addButton,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeMainTitle = (newTitle: string) => ({
    type: 'CHANGE_MAIN_TITLE',
    value: newTitle
  })

  const changeMainTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_MAIN_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addButton,
    changeTitle,
    changeMainTitle,
    changeParagraph,
    changeMainTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment
  }
}
