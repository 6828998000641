// @flow
import React, { memo, useEffect, useRef } from 'react'
import OpenIcon from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'
import LanguageIcon from '@editor/common/assets/svgr-icons/translate_icon.svg'
import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'

const ChosenLanguage = ({ isOpen, onClick, currentLanguage }) => {
  const ref = useRef(null)

  useEffect(() => {
    isOpen && ref.current && ref.current.scrollIntoView({ behavior: 'smooth' })
  }, [isOpen])
  return (
    <Styled.Container
      ref={ref}
      onClick={onClick}
      className="chosen-languages-container"
    >
      <Styled.Title>{translate('language_label')}</Styled.Title>
      <Styled.LanguageWrapper>
        <Styled.Icon>
          <LanguageIcon />
        </Styled.Icon>
        <Styled.Language>{currentLanguage}</Styled.Language>
        <Styled.OpenIcon isOpen={isOpen}>
          <OpenIcon />
        </Styled.OpenIcon>
      </Styled.LanguageWrapper>
    </Styled.Container>
  )
}

export default memo(ChosenLanguage)
