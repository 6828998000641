/* @flow */
import styled from 'styled-components'

import THEME from './CTA3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  @media only screen and (min-width: 769px) {
    padding-top: ${({ isEditing }) => isEditing && '150px'};
  }
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1440px) {
    width: 94%;
    max-width: 1230px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
    @media only screen and (min-width: 1025px) {
      max-width: 970px;
    }
  }
`

const ItemsWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: -20px;
  margin-bottom: -20px;
  padding-top: 40px;
  @media only screen and (max-width: 1024px) {
    padding-top: 30px;
  }
  @media only screen and (max-width: 700px) {
    margin-bottom: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  @media only screen and (min-width: 1025px) {
    max-width: 970px;
  }
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  ${getTextStyleByAlignment};
  margin-bottom: 0;
  @media only screen and (min-width: 1025px) {
    max-width: 970px;
  }
`

const Cta = styled.div`
  margin: 20px;
  display: flex;
  align-items: stretch;
  width: 100%;
  max-width: 368px;
  & > .control-container {
    @media only screen and (max-width: 450px) {
      width: 100%;
    }
  }
  @media only screen and (max-width: 700px) {
    margin: 30px 0 0 0;
  }
`

const CtaItemWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border: ${getStyleForProp('border')};
  border-radius: ${getStyleForProp('borderRadius')};
  padding: 40px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  flex-grow: 1;
  min-width: 368px;
  max-width: 368px;
  box-sizing: border-box;
  word-break: break-word;
  @media only screen and (max-width: 1100px) {
    max-width: 700px;
  }
  @media only screen and (max-width: 450px) {
    min-width: 300px;
  }
  .control-container {
    margin-bottom: 20px;
    width: 100%;
    p,
    h2 {
      margin-top: 0;
    }
  }
`

const CtaTitle = styled.h2`
  width: 100%;
  color: ${getStyleForProp('ctaTitle')};
  font-weight: 800;
  font-size: 20px;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const CtaParagraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('ctaParagraph')};
  margin-top: 20px;
  line-height: 1.4;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WmCustomContainer,
  ItemsWrap,
  Title,
  Description,
  Cta,
  CtaItemWrap,
  CtaTitle,
  CtaParagraph
}
