/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeParagraphAlignment: _changeParagraphAlignment
  }
}
