/* @flow */
import React, { useContext, memo } from 'react'
import { EditingContext } from '@contexts'
import useEventRegistration from '@website/common/hooks/useEventRegistration'
import RSVPForm from '@website/common/components/RSVPForm'
import RSVPRegistrationPopup from '@website/common/components/RSVPRegistrationPopup'
import S from './RSVP6.styled'
import RSVP6Card from './RSVP6.card'
import { BACKGROUND_IMAGES } from './RSVP6.consts'
import BackgroundSidebar from './BackgroundSidebar'

const RSVP6 = memo(props => {
  const {
    data: {
      subtitleAlignment,
      dateAlignment,
      locationAlignment,
      paragraphAlignment,
      cardImage,
      backgroundTopImage,
      backgroundBottomImage,
      titleAlignment,
      backgroundThumbnail
    },
    eventData,
    uniqId
  } = props

  const eventCardMetaData = {
    cardImage,
    backgroundTopImage,
    backgroundBottomImage,
    dateAlignment,
    locationAlignment,
    subtitleAlignment,
    titleAlignment,
    paragraphAlignment
  }

  const { isEditing } = useContext(EditingContext)
  const {
    onClick: onRSVPBtnClick,
    isLoading,
    additionalGuestCount,
    isRegistrationClosedPopupOpen,
    closeRegistrationClosedPopup,
    setGuestLimit
  } = useEventRegistration(eventData.id, eventData.form.additionalGuestNumber)

  const registrationClosedPopupData = eventData.popups.closed

  return (
    <S.ComponentWrapper>
      <S.StyledContainer isEditing={isEditing}>
        <S.WmCustomContainer>
          <RSVP6Card
            detailsData={eventData.details}
            metaData={eventCardMetaData}
            onRSVPBtnClick={onRSVPBtnClick}
            isLoading={isLoading}
          />
        </S.WmCustomContainer>
      </S.StyledContainer>
      <RSVPForm
        compId={uniqId}
        eventId={eventData.id}
        formData={eventData.form}
        popupsData={eventData.popups}
        detailsData={eventData.details}
        additionalGuestCount={additionalGuestCount}
        setGuestLimit={setGuestLimit}
      />
      {!isEditing ? (
        <RSVPRegistrationPopup
          isPopupOpen={isRegistrationClosedPopupOpen}
          title={registrationClosedPopupData.title.text}
          paragraph={registrationClosedPopupData.paragraph.text}
          onClose={closeRegistrationClosedPopup}
        />
      ) : null}
      {isEditing ? (
        <BackgroundSidebar
          activeBgThumbnail={backgroundThumbnail}
          backgroundImages={BACKGROUND_IMAGES}
        />
      ) : null}
    </S.ComponentWrapper>
  )
})

export default RSVP6
