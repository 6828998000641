// @flow

import React, { useEffect, useCallback, memo } from 'react'
import { translate } from '@editor/common/utils/translations'

import SuccessSvg from '@website/common/assets/saved-icon.svg'
import ErrorSvg from '@website/common/assets/error-circle.svg'
import * as Styled from './styled'
import type { TPopupsLoaderProps } from './types'

const PopupsLoader = ({
  className = '',
  isLoading = false,
  isSuccess = false,
  isFailed = false,
  loadingText = translate('saving_your_changes_label'),
  successText = translate('saved_label'),
  errorText = 'Something went wrong, please try again',
  onClick
}: TPopupsLoaderProps) => {
  const toShow = isLoading || isSuccess || isFailed
  const infoText = isLoading
    ? loadingText
    : isSuccess
    ? successText
    : isFailed
    ? errorText
    : ''

  const propagate = useCallback(e => {
    e.stopPropagation()
  }, [])

  useEffect(() => {
    if (toShow && isLoading) {
      document.addEventListener('mousedown', propagate, true)
      document.addEventListener('click', propagate, true)
    } else {
      document.removeEventListener('mousedown', propagate, true)
      document.removeEventListener('click', propagate, true)
    }
  }, [toShow, isLoading])

  useEffect(
    () => () => {
      document.removeEventListener('mousedown', propagate, true)
      document.removeEventListener('click', propagate, true)
    },
    []
  )

  if (!toShow) {
    return null
  }

  return (
    <Styled.Container className={className}>
      <Styled.Content>
        {isLoading ? <Styled.LoadingCorn /> : null}
        {isSuccess ? (
          <Styled.StateImage src={SuccessSvg} alt="Success" />
        ) : null}
        {isFailed ? <Styled.StateImage src={ErrorSvg} alt="Error" /> : null}
        <Styled.Text>{infoText}</Styled.Text>
        {isFailed && onClick ? (
          <Styled.Button type="transparent" onClick={onClick}>
            Got it
          </Styled.Button>
        ) : null}
      </Styled.Content>
    </Styled.Container>
  )
}

export default memo(PopupsLoader)
