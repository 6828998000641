/* @flow */
import React, { useContext, memo } from 'react'
import S from './Team9.styled'
import { validation } from './Team9.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithModalOpener from '@website/common/components/Controlled/ControlsWithModalOpener'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { DispatchContext, EditingContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const Team9 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      subTitle,
      subTitleAlignment,
      paragraph1,
      paragraph1Alignment,
      paragraph2,
      paragraph2Alignment,
      bottomBlockTitle,
      bottomBlockTitleAlignment,
      bottomBlockParagraph,
      bottomBlockParagraphAlignment,
      largeImgUrl,
      largeImgDimensions,
      largeImgCropParams,
      smallImg,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const imgRatio = smallImg.imgSizes
    ? (smallImg.imgSizes.height / smallImg.imgSizes.width).toFixed(2)
    : null

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.TextImageContent>
          <S.ImageWrapper>
            <S.ControlContainer>
              <ControlsWithImageCropper
                actions={Actions.getLargeImgActions({
                  keyForSizes: 'team9LargeImg',
                  showRemove: false
                })}
                style={{ width: '100%' }}
              >
                <Image
                  as={S.Img}
                  sizes={largeImgDimensions}
                  defaultImgSrc={largeImgUrl}
                  asProps={{
                    imgCropParams: largeImgCropParams
                  }}
                  alt="team image"
                />
              </ControlsWithImageCropper>
            </S.ControlContainer>
          </S.ImageWrapper>
        </S.TextImageContent>
        <S.ParagraphsWrap>
          <Show when={[title, subTitle]}>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              required={!subTitle && !paragraph1 && !paragraph2}
              className="WM_GLOBAL_heading42"
              maxCount={validation.headingMaxChar}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              as={S.SubTitle}
              text={subTitle}
              alignment={subTitleAlignment}
              required={!title && !paragraph1 && !paragraph2}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_heading20"
              onChange={Actions.changeSubTitle}
              changeAlignment={Actions.changeSubTitleAlignment}
            />
          </Show>
          <EditableContent
            as={S.Paragraph}
            text={paragraph1}
            alignment={paragraph1Alignment}
            required={!subTitle && !title && !paragraph2}
            maxCount={validation.contentParagraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph1}
            changeAlignment={Actions.changeParagraph1Alignment}
          />
          <EditableContent
            as={S.Paragraph}
            text={paragraph2}
            alignment={paragraph2Alignment}
            required={!subTitle && !title && !paragraph1}
            maxCount={validation.contentParagraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph2}
            changeAlignment={Actions.changeParagraph2Alignment}
          />
          <Show
            when={[bottomBlockTitle, bottomBlockParagraph, smallImg.imgUrl]}
          >
            <S.BottomBlock isEditing={isEditing}>
              <Show when={[bottomBlockTitle, bottomBlockParagraph]}>
                <S.BottomBlockWrapper isImage={smallImg} isEditing={isEditing}>
                  <EditableContent
                    as={S.BottomBlockTitle}
                    text={bottomBlockTitle}
                    alignment={bottomBlockTitleAlignment}
                    maxCount={validation.bottomBlockTitleMaxChar}
                    className="WM_GLOBAL_paragraph18"
                    onChange={Actions.changeBottomBlockTitle}
                    changeAlignment={Actions.changeBottomBlockTitleAlignment}
                  />
                  <EditableContent
                    as={S.BottomBlockParagraph}
                    text={bottomBlockParagraph}
                    alignment={bottomBlockParagraphAlignment}
                    maxCount={validation.bottomBlockParagraphMaxChar}
                    classbottomBlockParagraph="WM_GLOBAL_paragraph14"
                    onChange={Actions.changeBottomBlockParagraph}
                    changeAlignment={
                      Actions.changeBottomBlockParagraphAlignment
                    }
                  />
                </S.BottomBlockWrapper>
              </Show>
              <S.SmallImageContainer>
                <If
                  condition={smallImg.imgUrl}
                  then={() => (
                    <ControlsWithModalOpener
                      actions={Actions.smallImageActions(smallImg)}
                      style={{ width: '100%', height: '100%' }}
                    >
                      <S.SmallImageWrapper imgRatio={imgRatio}>
                        <Image
                          as={S.ImgSmall}
                          sizes={smallImg.imgDimensions}
                          defaultImgSrc={smallImg.imgUrl}
                          asProps={{
                            imgCropParams: smallImg.imgCropParams
                          }}
                          alt="logo image"
                        />
                      </S.SmallImageWrapper>
                    </ControlsWithModalOpener>
                  )}
                  otherwise={() => (
                    <AddButton toShow onAdd={Actions.addSmallImage}>
                      <Icon name="glyph-add" className="icon" size="normal" />
                      {translate('add_image_label')}
                    </AddButton>
                  )}
                />
              </S.SmallImageContainer>
            </S.BottomBlock>
          </Show>
        </S.ParagraphsWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Team9
