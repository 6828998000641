// TODO: move content from "./style-by-lang.js" to this file
import { css } from 'styled-components'
import { SEMRUSH_SIDEBAR_WIDTH } from '@editor/common/components/SemrushSidebar'

export const getSemrushDependedScreenBreakpoint = (
  expectedWidth,
  isSemrushSidebarVisible
) =>
  isSemrushSidebarVisible
    ? expectedWidth + SEMRUSH_SIDEBAR_WIDTH
    : expectedWidth

const getMediaScreenWidth =
  expectedWidth =>
  ({ theme: { isSemrushSidebarVisible } }) =>
    getSemrushDependedScreenBreakpoint(expectedWidth, isSemrushSidebarVisible)

export const getMediaQuery =
  queryType =>
  screenSize =>
  (...args) =>
    css`
      @media only screen and (${queryType}: ${getMediaScreenWidth(
          screenSize
        )}px) {
        ${css(...args)}
      }
    `

export const mediaMinWidth = getMediaQuery('min-width')

export const mediaMaxWidth = getMediaQuery('max-width')
