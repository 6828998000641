/* @flow */

export const validation = {
  titleMaxChar: 70,
  paragraphMaxChar: 200,
  maxCustomerNameChar: 30,
  maxCustomerPositionChar: 50,
  maxSlideContentChar: 500,
  testimonials: {
    min: 3,
    max: 10
  }
}
