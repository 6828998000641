/* @flow */

import { getBackgroundVideoUrl } from '@website/common/utils'

const changeBackgroundImageAction = (url, dimensions) => ({
  type: 'CHANGE_BACKGROUND_IMAGE',
  value: { url, dimensions },
  undoable: !!dimensions
})

export const changeBackgroundImage = (siteId, dispatcher) => (
  url,
  dimensions
) => {
  dispatcher(changeBackgroundImageAction(url, dimensions))
}

export const changeBackgroundVideoAction = videoUrl => ({
  type: 'CHANGE_BACKGROUND_VIDEO',
  value: getBackgroundVideoUrl(videoUrl)
})
