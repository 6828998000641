import styled, { createGlobalStyle } from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const GlobalStyles = createGlobalStyle`
  .text-wizard-ai-control {
    position: relative;
  }
  .text-wizard-ai-control-flyout_container {
    border-radius: 8px;
    box-shadow: 0 0 4px 0 rgba(56, 102, 255, 0.4);
  }
`

export const Control = styled.div`
  width: 53px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  box-shadow: 0 0 4px 0 rgba(137, 7, 168, 0.4);
  box-sizing: border-box;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(78deg, #8a2be2 10%, #ff69b4 40%, #ecbff3 60%, #8a2be2 80%)
      border-box;
  border: 1px solid transparent;

  cursor: pointer;
  background-size: 200% auto;
  animation: shine 3s linear infinite;
  animation-play-state: paused;
  background-position: 30% center;

  &:hover {
    border: 1.5px solid transparent;
    animation-play-state: running;
  }

  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
`

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #dde3f0;
  margin-top: 4px;
`

export const Subtitle = styled.p`
  color: ${secondary.SemiDark};
  font-size: 10px;
  font-weight: 500;
  margin: 8px 12px 4px;
`
