/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text17.styled'
import { validation } from './Text17.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Morph from './Text17_morph'

const Text17 = memo(props => {
  const {
    data: {
      title,
      paragraph1,
      paragraph2,
      titleAlignment,
      paragraph1Alignment,
      paragraph2Alignment,
      images,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.ImagesWrapper>
          <S.CirclesWrapper>
            <S.Circle className="circle-1" />
            <S.Circle className="circle-2" />
            <S.Circle className="circle-3" />
            <S.Circle className="circle-4" />
          </S.CirclesWrapper>
          <S.FigureWrapper>
            <S.FirstFigure />
            <S.SecondFigure />
            <S.ThirdFigure />
            <S.DotsWrapper>
              <Morph />
            </S.DotsWrapper>
          </S.FigureWrapper>
          <Mapper
            data={images}
            render={(img: string, blockIdx: number) => {
              return (
                <S.GridWrapper key={blockIdx}>
                  <Mapper
                    data={img}
                    render={(item: string, idx: number) => {
                      return (
                        <S.RatioWrapper
                          key={idx}
                          className={'ratio-wrapper-' + (idx + 1)}
                        >
                          <S.ImageWrapper>
                            <S.ControlContainer>
                              <ControlsWithImageCropper
                                style={{ width: '100%', height: '100%' }}
                                actions={Actions.imageActions(
                                  blockIdx,
                                  idx,
                                  item.imgUrl
                                )}
                              >
                                <Image
                                  as={S.Img}
                                  alt="Gallery"
                                  sizes={item.imgDimensions}
                                  asProps={{
                                    imgCropParams: item.imgCropParams
                                  }}
                                  defaultImgSrc={item.imgUrl}
                                />
                              </ControlsWithImageCropper>
                            </S.ControlContainer>
                          </S.ImageWrapper>
                        </S.RatioWrapper>
                      )
                    }}
                  />
                </S.GridWrapper>
              )
            }}
          />
        </S.ImagesWrapper>
        <S.HeaderContent>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.titleMaxChar}
            required={!paragraph1 && !paragraph2}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph1}
            alignment={paragraph1Alignment}
            as={S.Paragraph}
            required={!title && !paragraph2}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph1}
            changeAlignment={Actions.changeParagraph1Alignment}
          />
          <EditableContent
            text={paragraph2}
            alignment={paragraph2Alignment}
            as={S.Paragraph}
            required={!title && !paragraph1}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph2}
            changeAlignment={Actions.changeParagraph2Alignment}
          />
        </S.HeaderContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text17
