/* @flow */
import styled from 'styled-components'

import THEME from './Feature23.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0;' : '80px 0')};
  }

  .control-container {
    max-width: 900px;
    width: 100%;
    display: block;
    margin: ${({ isEditing }) =>
      isEditing ? '60px auto 0px auto' : '0 auto 0px auto'};

    & > h1 {
      max-width: none;
      margin: 0;

      @media only screen and (max-width: 768px) {
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: 1024px) {
      margin-bottom: 40px;
      max-width: 100%;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
  ${({ isEditing }) => (!isEditing ? 'margin-bottom: 50px;' : '')}
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`

const BlocksWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: ${props => (props.isForBlock ? '900px' : '1200px')};
  margin: -50px auto 0 auto;
  @media only screen and (max-width: 768px) {
    margin: -40px auto 0 auto;
  }
`

const ControlContainer = styled.div`
  margin: 50px 0 0 0;
  @media only screen and (max-width: 768px) {
    margin: 40px 0 0 0;
  }
  > p:first-child {
    margin-top: 0;
  }
`

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 360px;
  min-width: 280px;
  box-sizing: border-box;
  word-break: break-word;
`

const TextsContainer = styled.div`
  margin: 40px 20px 0 20px;
  width: 100%;
  max-width: 320px;
  @media only screen and (max-width: 550px) {
    margin: 40px 0 0 0;
  }
  & > .control-container p {
    padding-top: 0;
  }
  & > .control-container {
    width: 100%;
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin: 0;
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  padding-top: 15px;
`

const Circle = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('iconRadius')};
  background: ${getStyleForProp('buttonBackground')};
  pointer-events: none;
`

const IconWrap = styled.div``

const IconWrapper = styled.div`
  user-select: none;
`

const Line = styled.div`
  width: 100%;
  height: 2px;
  background: ${getStyleForProp('line')};
  margin-top: 20px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: ${getStyleForProp('line')};
  }
`

export default {
  StyledContainer,
  BlocksWrap,
  Title,
  BlockTitle,
  BlockParagraph,
  ItemWrap,
  IconWrapper,
  IconWrap,
  Circle,
  Line,
  TextsContainer,
  ControlContainer
}
