/* @flow */
import styled from 'styled-components'

import THEME from './Header2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 0 0 160px 0;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    padding: 0 0 80px 0;
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: 60px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  padding-top: ${({ topOffset }) => `${topOffset + 160}px`};
  @media only screen and (max-width: 1024px) {
    padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
  }
  .buttons-block {
    justify-content: center;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  > :first-child {
    padding-top: 0;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (min-width: 1025px) {
    max-width: 600px;
  }
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  width: 100%;
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  Description
}
