/* @flow */
import React, { useContext, memo } from 'react'
import S from './Basic2.styled'
import { validation } from './Basic2.consts'
import { DispatchContext, EditingContext } from '@contexts'
import Buttons from '@website/common/components/Buttons'

const Basic2 = memo(props => {
  const {
    data: {
      buttons,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <Buttons
          data={buttons}
          componentDispatcher={dispatcher}
          showRemove={buttons.length > validation.buttons.min}
        />
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Basic2
