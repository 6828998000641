import styled, { css } from 'styled-components'

import { globalColors } from '@renderforest/rf-ui-library/consts/colors'
import { SEMRUSH_SIDEBAR_WIDTH } from '@editor/common/components/SemrushSidebar/consts'

const getSemrushDependedWidth = ({ theme: { isSemrushSidebarVisible } }) =>
  isSemrushSidebarVisible ? `calc(100% - ${SEMRUSH_SIDEBAR_WIDTH}px)` : '100%'

export const Toolbar = styled.div``

export const ToolbarItemsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 50;
  height: 55px;
  width: ${getSemrushDependedWidth};
  display: flex;
`

export const ToolbarItem = styled.div`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive, sidebarActiveItem }) =>
    sidebarActiveItem === '' || isActive ? 'white' : '#fcfdff'};
  border-top: ${({ isActive }) =>
    isActive
      ? '1px solid transparent'
      : `1px solid ${globalColors.secondaryUltraLight}`};
  border-left: 1px solid ${globalColors.secondaryUltraLight};
  box-sizing: border-box;
  cursor: pointer;
`

export const ToolbarItemContentWrapper = styled.div`
  @keyframes openContent {
    0% {
      transform: translateY(100%);
    }
    100% {
      transform: translateY(0%);
    }
  }

  @keyframes closeContent {
    0% {
      transform: translateY(0%);
    }
    100% {
      transform: translateY(100%);
    }
  }

  position: fixed;
  width: ${getSemrushDependedWidth};
  height: auto;
  bottom: 54px;
  z-index: 49;
  background-color: white;
  box-shadow: 0px -6px 12px #0052e01a;
  animation: ${({ isOpened }) =>
    isOpened ? 'openContent 0.3s' : 'closeContent 0.3s'};
`

export const IconWrapper = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 5px;
  svg {
    width: 18px;
    height: 18px;
    fill: ${({ isActive }) =>
      isActive ? globalColors.secondaryDark : globalColors.secondarySemiDark};
  }
  ${({ hasNew }) =>
    hasNew
      ? css`
          @keyframes pulse-dot {
            0% {
              box-shadow: 0 0 0 0 rgba(59, 251, 101, 0.5);
            }
            100% {
              box-shadow: 0 0 0 5px rgba(59, 251, 101, 0.5);
            }
          }
          &::after {
            content: '';
            position: absolute;
            right: -5px;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #3bfb65;
            animation: pulse-dot 2s infinite;
          }
        `
      : ''};
`

export const Label = styled.span`
  font-size: 14px;
  color: ${({ isActive }) =>
    isActive ? globalColors.secondaryDark : globalColors.secondarySemiDark};
`
