/* @flow */
import React, { useContext } from 'react'
import './Feature14.styles.sass'
import S from './Feature14.styled'
import { validation } from './Feature14.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Feature14 = props => {
  const {
    data: { features }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.Container className="feature-14-container" isEditing={isEditing}>
      <Mapper
        data={features}
        render={(feature: string, idx: number) => (
          <div className={'row' + ' row-' + idx} key={idx}>
            <Mapper
              data={feature}
              render={(item: string, itemIdx: number) => {
                return (
                  <React.Fragment key={itemIdx}>
                    <If
                      condition={item.img}
                      then={() => (
                        <ControlsWithModalOpener
                          actions={Actions.imageActions(idx, itemIdx, item.img)}
                        >
                          <Image
                            as={S.Img}
                            alt="feature"
                            defaultImgSrc={item.img}
                            sizes={item.imgDimensions}
                            asProps={{ className: 'feature-img' }}
                          />
                        </ControlsWithModalOpener>
                      )}
                    />
                    <If
                      condition={item.paragraph !== undefined}
                      then={() => (
                        <S.TitleBlock
                          className="feature-title-block"
                          isEven={idx % 2 === 0}
                        >
                          <Show
                            when={[
                              item.paragraph,
                              item.count,
                              item.additionalText,
                              item.additionalUrl
                            ]}
                          >
                            <EditableContent
                              isEven={idx % 2 === 0}
                              text={item.count}
                              as={S.Counter}
                              className="WM_GLOBAL_heading96"
                              maxCount={validation.countMaxChar}
                              onChange={(newCount: string) =>
                                dispatcher(
                                  Actions.changeItemCount(
                                    newCount,
                                    idx,
                                    itemIdx
                                  )
                                )
                              }
                            />
                            <EditableContent
                              isEven={idx % 2 === 0}
                              text={item.paragraph}
                              as={S.Title}
                              className="WM_GLOBAL_heading42"
                              maxCount={validation.contentMaxChar}
                              onChange={(text: string) =>
                                dispatcher(
                                  Actions.changeItemText(text, idx, itemIdx)
                                )
                              }
                            />
                            <If
                              condition={
                                item.additionalText && item.additionalUrl
                              }
                              otherwise={() => (
                                <AddButton
                                  onAdd={() =>
                                    dispatcher(Actions.addLink(idx, itemIdx))
                                  }
                                  toShow={item.additionalText === ''}
                                >
                                  <Icon
                                    name="glyph-add"
                                    className="icon"
                                    size="normal"
                                  />
                                  {translate('add_link_label')}
                                </AddButton>
                              )}
                              then={() => (
                                <S.LinkWrap isEven={idx % 2 === 0}>
                                  <ControlsWithModalOpener
                                    actions={Actions.linkActions(
                                      idx,
                                      {
                                        text: item.additionalText,
                                        url: item.additionalUrl
                                      },
                                      itemIdx
                                    )}
                                  >
                                    <LinkWrapper
                                      url={item.additionalUrl}
                                      text={item.additionalText}
                                      linkProps={{
                                        className: 'WM_GLOBAL_secondary-font'
                                      }}
                                    />
                                  </ControlsWithModalOpener>
                                </S.LinkWrap>
                              )}
                            />
                            <S.Line isEven={idx % 2 === 0} />
                          </Show>
                        </S.TitleBlock>
                      )}
                    />
                  </React.Fragment>
                )
              }}
            />
          </div>
        )}
      />
    </S.Container>
  )
}

export default Feature14
