/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_PRIMARY_PLAN':
        draft.primaryPlanIdx = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'ADD_BLOCK':
        draft.plans.push(initialState.plans[0])
        break
      case 'REMOVE_SUBSCRIBTION_CARD':
        draft.plans.splice(action.value, 1)
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnData } = action.value
        draft.buttonUrl = btnData.link
        draft.buttonText = btnData.text
        break
      case 'CHANGE_CARD_DATA':
        const { keyInStore, planIdx, data } = action.value
        draft.plans[planIdx][keyInStore] = data
        break
      case 'REMOVE_BUTTON':
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BUTTON':
        draft.buttonUrl = initialState.buttonUrl
        draft.buttonText = initialState.buttonText
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.plans[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_DESCRIPTION_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.plans[idx].blockDescriptionAlignment = alignment
        break
      }
      case 'CHANGE_PRICE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.plans[idx].priceAlignment = alignment
        break
      }
    }
  })
}
