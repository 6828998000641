import { createSelector } from 'reselect'
import {
  RSVP_COMP_CATEGORY,
  COMPONENT_CATEGORIES,
  NO_HEADER_COMPONENT_ID
} from '@editor/conf/consts'

export const selectComponents = state => state.components

export const selectComponentCategoriesInfo = state =>
  selectComponents(state).componentCategoriesInfo

export const selectComponentCategories = state =>
  selectComponentCategoriesInfo(state).data

export const selectIsCompCategoriesLoading = state =>
  selectComponentCategoriesInfo(state).isLoading

export const selectComponentCategoriesForView = createSelector(
  selectComponentCategories,
  categories => categories.filter(cat => cat.key !== RSVP_COMP_CATEGORY)
)

export const selectRsvpCompCategory = createSelector(
  selectComponentCategories,
  categories => categories.find(cat => cat.key === RSVP_COMP_CATEGORY) || {}
)

export const selectIsComponentsListLoading = state =>
  selectComponents(state).componentsList.isLoading

export const selectComponentsList = state =>
  selectComponents(state).componentsList.payload

export const selectComponentsGroupedByCat = createSelector(
  selectComponentsList,
  components =>
    components.reduce((acc, current) => {
      const { id, category } = current

      if (!acc[category]) {
        acc[category] = { [id]: current }
      } else {
        acc[category][id] = current
      }
      return acc
    }, {})
)

export const selectNoHeaderCompMeta = createSelector(
  selectComponentsGroupedByCat,
  componentsByCat =>
    componentsByCat[COMPONENT_CATEGORIES.HEADER] &&
    componentsByCat[COMPONENT_CATEGORIES.HEADER][NO_HEADER_COMPONENT_ID]
)

export const selectIsComponentsDataLoading = state =>
  selectComponents(state).componentsData.isLoading

export const selectComponentsData = state =>
  selectComponents(state).componentsData.payload
