import styled, { createGlobalStyle, css } from 'styled-components'

import SearchDropdown from '@renderforest/rf-ui-library/molecules/SearchDropdown'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
const errorBorderStyles = '1px solid #ff4c4c !important'

export const GlobalStyles = createGlobalStyle`
  .phone-number-flag {
    width: 20px;
    height: 10px;
    margin-right: 5px;
  }
`

export const PhoneSearchDropdown = styled(SearchDropdown)`
  min-width: 110px;
  max-width: 110px;

  & > div {
    border-radius: 5px 0 0 5px;
    & > div > div:nth-child(1) {
      font-size: 14px;
      color: #363f5a;
      margin-left: 28px;
    }

    & > div > div:nth-child(2) {
      svg {
        fill: #545f7e;
      }
    }
  }

  & label {
    display: none;
  }

  .search-dropdown-options-wrapper {
    width: calc(100% - 1px);
    overflow: hidden;
    ul {
      &::-webkit-scrollbar {
        background-color: transparent;
        width: 5px;
      }
      &::-webkit-scrollbar-button {
        display: none;
      }
      &::-webkit-scrollbar-thumb {
        background: #c2c9e0;
        border-radius: 10px;
      }
      & > li > span {
        font-size: 14px;
      }
    }
  }
`

export const PhoneInputWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  .remove-icon {
    z-index: 2;
    &:hover {
      svg {
        fill: #545f7e;
      }
    }
  }
  .phone-input {
    border-radius: 0 5px 5px 0;
    z-index: 1;
    ${({ hasError }) =>
      hasError
        ? css`
            &,
            &:hover {
              border: ${errorBorderStyles};
            }
          `
        : ''}
  }
  & ${PhoneSearchDropdown} {
    & > div {
      ${({ hasError }) =>
        hasError
          ? css`
              border-top: ${errorBorderStyles};
              border-left: ${errorBorderStyles};
              border-bottom: ${errorBorderStyles};
            `
          : ''}
    }
  }
`

export const Flag = styled.img`
  position: absolute;
  width: 20px;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
`

export const ErrorMessage = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: -15px;
  font-size: 10px;
  font-weight: 500;
  color: #ff4c4c;
  white-space: nowrap;
`

export const ErrorIcon = styled(Icon)`
  margin-right: 5px;
`
