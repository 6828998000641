import FAQ1 from './FAQ1/FAQ1.reducer'
import FAQ2 from './FAQ2/FAQ2.reducer'
import FAQ3 from './FAQ3/FAQ3.reducer'
import Pricing1 from './Pricing1/Pricing1.reducer'
import Pricing2 from './Pricing2/Pricing2.reducer'
import Pricing3 from './Pricing3/Pricing3.reducer'
import Pricing4 from './Pricing4/Pricing4.reducer'
import Pricing5 from './Pricing5/Pricing5.reducer'
import Pricing6 from './Pricing6/Pricing6.reducer'
import Pricing6_2 from './Pricing6_2/Pricing6_2.reducer'
import Pricing7 from './Pricing7/Pricing7.reducer'
import Pricing8 from './Pricing8/Pricing8.reducer'
import PayPal2 from './PayPal2/PayPal2.reducer'
import PayPal1 from './PayPal1/PayPal1.reducer'
import PayPal3 from './PayPal3/PayPal3.reducer'
import PayPal4 from './PayPal4/PayPal4.reducer'
import PayPal5 from './PayPal5/PayPal5.reducer'
import Feature1 from './Feature1/Feature1.reducer'
import Feature2 from './Feature2/Feature2.reducer'
import Feature3 from './Feature3/Feature3.reducer'
import Feature4 from './Feature4/Feature4.reducer'
import Feature4_2 from './Feature4_2/Feature4_2.reducer'
import Feature5 from './Feature5/Feature5.reducer'
import Feature6 from './Feature6/Feature6.reducer'
import Feature7 from './Feature7/Feature7.reducer'
import Feature8 from './Feature8/Feature8.reducer'
import Feature9 from './Feature9/Feature9.reducer'
import Feature9_2 from './Feature9_2/Feature9_2.reducer'
import Feature10 from './Feature10/Feature10.reducer'
import Feature11 from './Feature11/Feature11.reducer'
import Feature11_2 from './Feature11_2/Feature11_2.reducer'
import Feature12 from './Feature12/Feature12.reducer'
import Feature12_2 from './Feature12_2/Feature12_2.reducer'
import Feature13 from './Feature13/Feature13.reducer'
import Feature14 from './Feature14/Feature14.reducer'
import Feature14_2 from './Feature14_2/Feature14_2.reducer'
import Feature15 from './Feature15/Feature15.reducer'
import Feature15_2 from './Feature15_2/Feature15_2.reducer'
import Feature16 from './Feature16/Feature16.reducer'
import Feature16_2 from './Feature16_2/Feature16_2.reducer'
import Feature17 from './Feature17/Feature17.reducer'
import Feature18 from './Feature18/Feature18.reducer'
import Feature19 from './Feature19/Feature19.reducer'
import Feature20 from './Feature20/Feature20.reducer'
import Feature21 from './Feature21/Feature21.reducer'
import Feature22 from './Feature22/Feature22.reducer'
import Feature23 from './Feature23/Feature23.reducer'
import Feature24 from './Feature24/Feature24.reducer'
import Feature25 from './Feature25/Feature25.reducer'
import Feature26 from './Feature26/Feature26.reducer'
import Feature27 from './Feature27/Feature27.reducer'
import Feature28 from './Feature28/Feature28.reducer'
import Feature29 from './Feature29/Feature29.reducer'
import Feature30 from './Feature30/Feature30.reducer'
import Feature31 from './Feature31/Feature31.reducer'
import Feature32 from './Feature32/Feature32.reducer'
import Feature33 from './Feature33/Feature33.reducer'
import Feature34 from './Feature34/Feature34.reducer'
import Feature35 from './Feature35/Feature35.reducer'
import Feature36 from './Feature36/Feature36.reducer'
import Feature37 from './Feature37/Feature37.reducer'
import Feature38 from './Feature38/Feature38.reducer'
import Contact1 from './Contact1/Contact1.reducer'
import Contact2 from './Contact2/Contact2.reducer'
import Contact3 from './Contact3/Contact3.reducer'
import Contact4 from './Contact4/Contact4.reducer'
import Contact5 from './Contact5/Contact5.reducer'
import Contact6 from './Contact6/Contact6.reducer'
import Contact7 from './Contact7/Contact7.reducer'
import Contact8 from './Contact8/Contact8.reducer'
import Contact9 from './Contact9/Contact9.reducer'
import Contact10 from './Contact10/Contact10.reducer'
import Contact11 from './Contact11/Contact11.reducer'
import Contact12 from './Contact12/Contact12.reducer'
import Contact13 from './Contact13/Contact13.reducer'
import Contact14 from './Contact14/Contact14.reducer'
import Steps1 from './Steps1/Steps1.reducer'
import Steps2 from './Steps2/Steps2.reducer'
import Steps4 from './Steps4/Steps4.reducer'
import Steps3 from './Steps3/Steps3.reducer'
import Steps5 from './Steps5/Steps5.reducer'
import Steps6 from './Steps6/Steps6.reducer'
import Steps7 from './Steps7/Steps7.reducer'
import Steps8 from './Steps8/Steps8.reducer'
import Steps9 from './Steps9/Steps9.reducer'
import Steps10 from './Steps10/Steps10.reducer'
import Team1 from './Team1/Team1.reducer'
import Team2 from './Team2/Team2.reducer'
import Team3 from './Team3/Team3.reducer'
import Team4 from './Team4/Team4.reducer'
import Team5 from './Team5/Team5.reducer'
import Team6 from './Team6/Team6.reducer'
import Team7 from './Team7/Team7.reducer'
import Team8 from './Team8/Team8.reducer'
import Team9 from './Team9/Team9.reducer'
import Header1 from './Header1/Header1.reducer'
import Header2 from './Header2/Header2.reducer'
import Header4 from './Header4/Header4.reducer'
import Header5 from './Header5/Header5.reducer'
import Header5_2 from './Header5_2/Header5_2.reducer'
import Header6 from './Header6/Header6.reducer'
import Header6_2 from './Header6_2/Header6_2.reducer'
import Header7 from './Header7/Header7.reducer'
import Header8 from './Header8/Header8.reducer'
import Header9 from './Header9/Header9.reducer'
import Header10 from './Header10/Header10.reducer'
import Header11 from './Header11/Header11.reducer'
import Header13 from './Header13/Header13.reducer'
import Header14 from './Header14/Header14.reducer'
import Header15 from './Header15/Header15.reducer'
import Header16 from './Header16/Header16.reducer'
import Header17 from './Header17/Header17.reducer'
import Header18 from './Header18/Header18.reducer'
import Header19 from './Header19/Header19.reducer'
import Header20 from './Header20/Header20.reducer'
import Header21 from './Header21/Header21.reducer'
import Header22 from './Header22/Header22.reducer'
import Header23 from './Header23/Header23.reducer'
import Header24 from './Header24/Header24.reducer'
import Header25 from './Header25/Header25.reducer'
import Header26 from './Header26/Header26.reducer'
import Header27 from './Header27/Header27.reducer'
import Header28 from './Header28/Header28.reducer'
import Header29 from './Header29/Header29.reducer'
import Header30 from './Header30/Header30.reducer'
import Header31 from './Header31/Header31.reducer'
import Header32 from './Header32/Header32.reducer'
import Header33 from './Header33/Header33.reducer'
import Header34 from './Header34/Header34.reducer'
import Header35 from './Header35/Header35.reducer'
import Header36 from './Header36/Header36.reducer'
import Header37 from './Header37/Header37.reducer'
import Header38 from './Header38/Header38.reducer'
import Header39 from './Header39/Header39.reducer'
import Header40 from './Header40/Header40.reducer'
import Header41 from './Header41/Header41.reducer'
import Header42 from './Header42/Header42.reducer'
import Header43 from './Header43/Header43.reducer'
import Header44 from './Header44/Header44.reducer'
import Header45 from './Header45/Header45.reducer'
import Header46 from './Header46/Header46.reducer'
import Header47 from './Header47/Header47.reducer'
import Header48 from './Header48/Header48.reducer'
import Header49 from './Header49/Header49.reducer'
import Header50 from './Header50/Header50.reducer'
import Header51 from './Header51/Header51.reducer'
import Header52 from './Header52/Header52.reducer'
import Header53 from './Header53/Header53.reducer'
import Header54 from './Header54/Header54.reducer'
import Header55 from './Header55/Header55.reducer'
import Header56 from './Header56/Header56.reducer'
import Header57 from './Header57/Header57.reducer'
import Clients1 from './Clients1/Clients1.reducer'
import Clients2 from './Clients2/Clients2.reducer'
import Clients3 from './Clients3/Clients3.reducer'
import Clients4 from './Clients4/Clients4.reducer'
import Portfolio1 from './Portfolio1/Portfolio1.reducer'
import Portfolio2 from './Portfolio2/Portfolio2.reducer'
import Portfolio3 from './Portfolio3/Portfolio3.reducer'
import Portfolio4 from './Portfolio4/Portfolio4.reducer'
import Portfolio5 from './Portfolio5/Portfolio5.reducer'
import Portfolio6 from './Portfolio6/Portfolio6.reducer'
import Portfolio6_2 from './Portfolio6_2/Portfolio6_2.reducer'
import Portfolio7 from './Portfolio7/Portfolio7.reducer'
import Portfolio8 from './Portfolio8/Portfolio8.reducer'
import Portfolio9 from './Portfolio9/Portfolio9.reducer'
import Portfolio10 from './Portfolio10/Portfolio10.reducer'
import Portfolio11 from './Portfolio11/Portfolio11.reducer'
import Portfolio12 from './Portfolio12/Portfolio12.reducer'
import Portfolio13 from './Portfolio13/Portfolio13.reducer'
import Portfolio14 from './Portfolio14/Portfolio14.reducer'
import CTA1 from './CTA1/CTA1.reducer'
import CTA2 from './CTA2/CTA2.reducer'
import CTA3 from './CTA3/CTA3.reducer'
import CTA4 from './CTA4/CTA4.reducer'
import CTA5 from './CTA5/CTA5.reducer'
import CTA6 from './CTA6/CTA6.reducer'
import CTA7 from './CTA7/CTA7.reducer'
import CTA8 from './CTA8/CTA8.reducer'
import CTA9 from './CTA9/CTA9.reducer'
import CTA10 from './CTA10/CTA10.reducer'
import CTA11 from './CTA11/CTA11.reducer'
import CTA11_2 from './CTA11_2/CTA11_2.reducer'
import CTA12 from './CTA12/CTA12.reducer'
import CTA13 from './CTA13/CTA13.reducer'
import CTA14 from './CTA14/CTA14.reducer'
import Text1 from './Text1/Text1.reducer'
import Text2 from './Text2/Text2.reducer'
import Text3 from './Text3/Text3.reducer'
import Text4 from './Text4/Text4.reducer'
import Text5 from './Text5/Text5.reducer'
import Text5_2 from './Text5_2/Text5_2.reducer'
import Text6 from './Text6/Text6.reducer'
import Text7 from './Text7/Text7.reducer'
import Text8 from './Text8/Text8.reducer'
import Text9 from './Text9/Text9.reducer'
import Text10 from './Text10/Text10.reducer'
import Text11 from './Text11/Text11.reducer'
import Text12 from './Text12/Text12.reducer'
import Text13 from './Text13/Text13.reducer'
import Text14 from './Text14/Text14.reducer'
import Text15 from './Text15/Text15.reducer'
import Text16 from './Text16/Text16.reducer'
import Text17 from './Text17/Text17.reducer'
import Text18 from './Text18/Text18.reducer'
import Text19 from './Text19/Text19.reducer'
import Text20 from './Text20/Text20.reducer'
import Text21 from './Text21/Text21.reducer'
import Text22 from './Text22/Text22.reducer'
import Email1 from './Email1/Email1.reducer'
import Email2 from './Email2/Email2.reducer'
import Email3 from './Email3/Email3.reducer'
import Email4 from './Email4/Email4.reducer'
import Email5 from './Email5/Email5.reducer'
import Email6 from './Email6/Email6.reducer'
import NoHeader from './NoHeader/NoHeader.reducer'
import Footer1 from './Footer1/Footer1.reducer'
import Footer2 from './Footer2/Footer2.reducer'
import Footer3 from './Footer3/Footer3.reducer'
import Footer4 from './Footer4/Footer4.reducer'
import Footer5 from './Footer5/Footer5.reducer'
import Footer6 from './Footer6/Footer6.reducer'
import Footer7 from './Footer7/Footer7.reducer'
import Footer9 from './Footer9/Footer9.reducer'
import Footer10 from './Footer10/Footer10.reducer'
import Slider1 from './Slider1/Slider1.reducer'
import Slider2 from './Slider2/Slider2.reducer'
import Slider3 from './Slider3/Slider3.reducer'
import Slider4 from './Slider4/Slider4.reducer'
import Slider5 from './Slider5/Slider5.reducer'
import Slider6 from './Slider6/Slider6.reducer'
import Testimonials1 from './Testimonials1/Testimonials1.reducer'
import Testimonials2 from './Testimonials2/Testimonials2.reducer'
import Testimonials3 from './Testimonials3/Testimonials3.reducer'
import Testimonials4 from './Testimonials4/Testimonials4.reducer'
import Testimonials5 from './Testimonials5/Testimonials5.reducer'
import Testimonials6 from './Testimonials6/Testimonials6.reducer'
import Testimonials7 from './Testimonials7/Testimonials7.reducer'
import Post1 from './Post1/Post1.reducer'
import Post2 from './Post2/Post2.reducer'
import Post3 from './Post3/Post3.reducer'
import Video1 from './Video1/Video1.reducer'
import Video2 from './Video2/Video2.reducer'
import Video3 from './Video3/Video3.reducer'
import Video4 from './Video4/Video4.reducer'
import Video5 from './Video5/Video5.reducer'
import Music1 from './Music1/Music1.reducer'
import Music2 from './Music2/Music2.reducer'
import Music3 from './Music3/Music3.reducer'
import Music4 from './Music4/Music4.reducer'
import Music5 from './Music5/Music5.reducer'
import Spotify1 from './Spotify1/Spotify1.reducer'
import Spotify2 from './Spotify2/Spotify2.reducer'
import Spotify3 from './Spotify3/Spotify3.reducer'
import Spotify4 from './Spotify4/Spotify4.reducer'
import Spotify5 from './Spotify5/Spotify5.reducer'
import Spotify6 from './Spotify6/Spotify6.reducer'
import Soundcloud6 from './Soundcloud6/Soundcloud6.reducer'
import Soundcloud7 from './Soundcloud7/Soundcloud7.reducer'
import PrivacyPolicy1 from './PrivacyPolicy1/PrivacyPolicy1.reducer'
import PrivacyPolicy2 from './PrivacyPolicy2/PrivacyPolicy2.reducer'
import PrivacyPolicy4 from './PrivacyPolicy4/PrivacyPolicy4.reducer'
import PrivacyPolicy3 from './PrivacyPolicy3/PrivacyPolicy3.reducer'
import PrivacyPolicy5 from './PrivacyPolicy5/PrivacyPolicy5.reducer'
import Basic1 from './Basic1/Basic1.reducer'
import Basic2 from './Basic2/Basic2.reducer'
import Basic3 from './Basic3/Basic3.reducer'
import Basic4 from './Basic4/Basic4.reducer'
import Countdown1 from './Countdown1/Countdown1.reducer'
import Countdown2 from './Countdown2/Countdown2.reducer'
import RSVP1 from './RSVP1/RSVP1.reducer'
import RSVP2 from './RSVP2/RSVP2.reducer'
import RSVP3 from './RSVP3/RSVP3.reducer'
import RSVP4 from './RSVP4/RSVP4.reducer'
import RSVP6 from './RSVP6/RSVP6.reducer'
import RSVP5 from './RSVP5/RSVP5.reducer'
import RSVP7 from './RSVP7/RSVP7.reducer'
import Cookie from './Cookie/Cookie.reducer'

export default {
  FAQ1,
  FAQ2,
  FAQ3,
  Pricing1,
  Pricing2,
  Pricing3,
  Pricing4,
  Pricing5,
  Pricing6,
  Pricing6_2,
  Pricing7,
  Pricing8,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature4_2,
  Feature5,
  Feature6,
  Feature7,
  Feature8,
  Feature9,
  Feature9_2,
  Feature10,
  Feature11,
  Feature11_2,
  Feature12,
  Feature12_2,
  Feature13,
  Feature14,
  Feature14_2,
  Feature15,
  Feature15_2,
  Feature16,
  Feature16_2,
  Feature17,
  Feature18,
  Feature19,
  Feature20,
  Feature21,
  Feature22,
  Feature23,
  Feature24,
  Feature25,
  Feature26,
  Feature27,
  Feature28,
  Feature29,
  Feature30,
  Feature31,
  Feature32,
  Feature33,
  Feature34,
  Feature35,
  Feature36,
  Feature37,
  Feature38,
  Contact1,
  Contact2,
  Contact3,
  Contact4,
  Contact5,
  Contact6,
  Contact7,
  Contact8,
  Contact9,
  Contact10,
  Contact11,
  Contact12,
  Contact13,
  Contact14,
  Steps1,
  Steps2,
  Steps4,
  Steps3,
  Steps5,
  Steps6,
  Steps7,
  Steps8,
  Steps9,
  Steps10,
  Team1,
  Team2,
  Team3,
  Team4,
  Team5,
  Team6,
  Team7,
  Team8,
  Team9,
  Header1,
  Header2,
  Header4,
  Header5,
  Header5_2,
  Header6,
  Header6_2,
  Header7,
  Header8,
  Header9,
  Header10,
  Header11,
  Header13,
  Header14,
  Header15,
  Header16,
  Header17,
  Header18,
  Header19,
  Header20,
  Header21,
  Header22,
  Header23,
  Header24,
  Header25,
  Header26,
  Header27,
  Header28,
  Header29,
  Header30,
  Header31,
  Header32,
  Header33,
  Header34,
  Header35,
  Header36,
  Header37,
  Header38,
  Header39,
  Header40,
  Header41,
  Header42,
  Header43,
  Header44,
  Header45,
  Header46,
  Header47,
  Header48,
  Header49,
  Header50,
  Header51,
  Header52,
  Header53,
  Header54,
  Header55,
  Header56,
  Header57,
  Clients1,
  Clients2,
  Clients3,
  Clients4,
  Portfolio1,
  Portfolio2,
  Portfolio3,
  Portfolio4,
  Portfolio5,
  Portfolio6,
  Portfolio6_2,
  Portfolio7,
  Portfolio8,
  Portfolio9,
  Portfolio10,
  Portfolio11,
  Portfolio12,
  Portfolio13,
  Portfolio14,
  PayPal1,
  PayPal2,
  PayPal3,
  PayPal4,
  PayPal5,
  CTA1,
  CTA2,
  CTA3,
  CTA4,
  CTA5,
  CTA6,
  CTA7,
  CTA8,
  CTA9,
  CTA10,
  CTA11,
  CTA11_2,
  CTA12,
  CTA13,
  CTA14,
  Text1,
  Text2,
  Text3,
  Text4,
  Text5,
  Text5_2,
  Text6,
  Text7,
  Text8,
  Text9,
  Text10,
  Text11,
  Text12,
  Text13,
  Text14,
  Text15,
  Text16,
  Text17,
  Text18,
  Text19,
  Text20,
  Text21,
  Text22,
  Email1,
  Email2,
  Email3,
  Email4,
  Email5,
  Email6,
  NoHeader,
  Footer1,
  Footer2,
  Footer3,
  Footer4,
  Footer5,
  Footer6,
  Footer7,
  Footer9,
  Footer10,
  Slider1,
  Slider2,
  Slider3,
  Slider4,
  Slider5,
  Slider6,
  Testimonials1,
  Testimonials2,
  Testimonials3,
  Testimonials4,
  Testimonials5,
  Testimonials6,
  Testimonials7,
  Video1,
  Video2,
  Video3,
  Video4,
  Video5,
  Music1,
  Music2,
  Music3,
  Music4,
  Music5,
  Spotify1,
  Spotify2,
  Spotify3,
  Spotify4,
  Spotify5,
  Spotify6,
  Soundcloud6,
  Soundcloud7,
  Post1,
  Post2,
  Post3,
  PrivacyPolicy1,
  PrivacyPolicy2,
  PrivacyPolicy4,
  PrivacyPolicy3,
  PrivacyPolicy5,
  Basic1,
  Basic2,
  Basic3,
  Basic4,
  Countdown1,
  Countdown2,
  RSVP1,
  RSVP2,
  RSVP3,
  RSVP4,
  RSVP6,
  RSVP5,
  RSVP7,
  Cookie
}
