export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    background: p => `${p.tertiaryDark}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => `${p.tertiaryLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
