// @flow

import React, { memo, useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'

import {
  TemplatePreview,
  SitePreview,
  SitePresetPreview,
  AIPresetPreview
} from './containers'

const PreviewProject = () => {
  const { path } = useRouteMatch()

  useEffect(() => {
    window.removeGoogleOneTapLogin && window.removeGoogleOneTapLogin()
  }, [])

  return (
    <Switch>
      <Route exact path={`${path}/`} component={SitePreview} />
      <Route
        path={`${path}/template/:templateId`}
        component={TemplatePreview}
      />
      <Route path={`${path}/site-preset/`} component={SitePresetPreview} />
      <Route path={`${path}/ai-preset/:tempId`} component={AIPresetPreview} />
    </Switch>
  )
}

export default memo(PreviewProject)
