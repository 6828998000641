/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].title = newTitle
        break
      }
      case 'CHANGE_PARAGRAPH': {
        const { idx, newParagraph } = action.value
        draft.blocks[idx].paragraph = newParagraph
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.blocks[idx].imgCropParams = imgCropParams
      }
      case 'CHANGE_IMAGE': {
        const { url, idx, dimensions } = action.value
        if (!url) {
          return
        }
        draft.blocks[idx].imgUrl = url
        draft.blocks[idx].imgDimensions = dimensions || {}
        draft.blocks[idx].imgCropParams = {}
        break
      }
      case 'CHANGE_BUTTON_DATA': {
        const { btnIdx, btnData } = action.value
        draft.blocks[btnIdx].buttonUrl = btnData.link
        draft.blocks[btnIdx].buttonText = btnData.text
        break
      }
      case 'REMOVE_BUTTON': {
        const btnIdx = action.value
        draft.blocks[btnIdx].buttonUrl = ''
        draft.blocks[btnIdx].buttonText = ''
        break
      }
      case 'ADD_LINK':
        {
          const { idx } = action.value
          draft.blocks[idx].buttonUrl = initialState.blocks[0].buttonUrl
          draft.blocks[idx].buttonText = initialState.blocks[0].buttonText
        }
        break
      case 'ADD_BLOCK':
        {
          const { idx } = action.value
          draft.blocks.splice(idx + 1, 0, initialState.blocks[0])
        }
        break
      case 'REMOVE_BLOCK':
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockTitleAlignment = alignment
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockParagraphAlignment = alignment
        break
      }
    }
  })
}
