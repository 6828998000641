/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature4_2.styled'
import { validation } from './Feature4_2.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Image from '@website/common/components/Image'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import Feature4_2_morph from './Feature4_2_morph'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const Feature4_2 = memo(props => {
  const {
    data: {
      title,
      description,
      titleAlignment,
      paragraphAlignment,
      features,
      buttons,
      mobileImgUrl,
      mockupColor,
      mobileImgDimensions,
      imgCropParams: mobileImgCropParams
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <S.Content>
          <Show when={[title, description]}>
            <S.TextsContent>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  as={S.Title}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeTitle}
                  className="WM_GLOBAL_heading"
                  changeAlignment={Actions.changeTitleAlignment}
                />
              </Show>
              <Show when={[description]}>
                <EditableContent
                  text={description}
                  alignment={paragraphAlignment}
                  as={S.Description}
                  maxCount={validation.paragraphMaxChar}
                  onChange={Actions.changeParagraph}
                  changeAlignment={Actions.changeParagraphAlignment}
                  className="WM_GLOBAL_paragraph"
                />
              </Show>
            </S.TextsContent>
          </Show>
          <Buttons data={buttons} />
          <S.ListWrap>
            <Mapper
              data={features}
              render={(feature: string, idx: number) => (
                <Show when={[feature]}>
                  <ControlsWithModalOpener
                    actions={Actions.listActions(
                      idx,
                      features.length > validation.features.min
                    )}
                    key={idx}
                  >
                    <S.ListItemWrap key={idx}>
                      <S.Icon
                        size="large"
                        name="check"
                        className="check-icon"
                      />
                      <EditableContent
                        text={feature}
                        as={S.ListItem}
                        maxCount={validation.checkmarkMaxChar}
                        onChange={(newContent: string) =>
                          Actions.changeListItem(newContent, idx)
                        }
                        className="WM_GLOBAL_paragraph18"
                      />
                    </S.ListItemWrap>
                  </ControlsWithModalOpener>
                </Show>
              )}
            />
            <AddButton
              onAdd={() => Actions.addNewListItem()}
              toShow={features.length < validation.features.max}
              style={{
                marginTop: '2rem'
              }}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_item_label')}
            </AddButton>
          </S.ListWrap>
        </S.Content>
        <S.ImageWrap>
          <S.ImageFigure>
            <Feature4_2_morph />
            <S.Phone color={mockupColor}>
              <ControlsWithImageCropper
                actions={Actions.mockupActions({
                  mockupColor: mockupColor,
                  imageUrl: mobileImgUrl
                })}
              >
                <Image
                  as={S.MobileImg}
                  asProps={{ imgCropParams: mobileImgCropParams }}
                  defaultImgSrc={mobileImgUrl}
                  sizes={mobileImgDimensions}
                  alt="Web page "
                />
              </ControlsWithImageCropper>
            </S.Phone>
          </S.ImageFigure>
        </S.ImageWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature4_2
