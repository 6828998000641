import styled, { css } from 'styled-components'
import { styleProvider, hexToRgb } from '../../utils'
const getStyleForProp = styleProvider({})

const Label = styled.label`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  position: relative;

  padding-left: 30px;
  display: flex;
  align-items: center;
  width: 120px;
  cursor: pointer;
  word-break: break-word;
  @media only screen and (max-width: 457px) {
    width: calc(100% - 30px);
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 22px;
    height: 22px;
    min-width: 22px;
    min-height: 22px;
    box-sizing: border-box;
    border: 1px solid
      ${({ theme }) => `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
    border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '100%' : '0')};
    background: #fff;
  }
  &:after {
    content: '';
    width: 12px;
    height: 12px;
    ${getStyleForProp('buttonBackground')};
    position: absolute;
    border: none;
    left: 5px;
    border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '100%' : '0')};
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
`

const RadioButtonWrap = styled.div`
  display: block;
  ${Label} {
    font-weight: 400;
  }
`

const Input = styled.input`
  display: none;
  &:not(:checked) {
    + ${Label} {
      &:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
      }
    }
  }
  &:checked {
    + ${Label} {
      &:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
`

export default {
  RadioButtonWrap,
  Input,
  Label
}
