/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_DESCRIPTION':
        draft.description = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_IMAGE':
        {
          const { mockupType, dimensions, url } = action.value
          draft[mockupType] = url
          draft[`${mockupType}Dimensions`] = dimensions
          draft[`${mockupType}ImgCropParams`] = {}
        }
        break
      case 'CHANGE_STORE_URL':
        {
          const { url, storeType } = action.value
          draft[storeType] = url
        }
        break
      case 'ADD_STORE_LINK':
        {
          const storeType = action.value
          draft[storeType] = initialState[storeType]
        }
        break
      case 'REMOVE_BUTTON':
        {
          const storeType = action.value
          draft[storeType] = ''
        }
        break
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, mockupType } = action.value
        draft[`${mockupType}ImgCropParams`] = imgCropParams
        break
      }
      case 'CHANGE_MOCKUP_COLOR': {
        const mockupType = action.value
        draft[`${mockupType}MockupColor`] === 'light'
          ? (draft[`${mockupType}MockupColor`] = 'dark')
          : (draft[`${mockupType}MockupColor`] = 'light')
        break
      }
      case 'MAKE_MOCKUP_LIGHT': {
        const mockupType = action.value
        draft[`${mockupType}MockupColor`] = 'light'
        break
      }
      case 'MAKE_MOCKUP_DARK': {
        const mockupType = action.value
        draft[`${mockupType}MockupColor`] = 'dark'
        break
      }
    }
  })
}
