import styled, { createGlobalStyle, css } from 'styled-components'
import { InputContainer as StyledInputContainer } from '../styled'

export const InputContainer = styled(StyledInputContainer)`
  .components-dropdown-tooltip {
    pointer-events: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const GlobalStyles = createGlobalStyle`
  .components-dropdown-tooltip_content {
    animation: slide-bottom 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    
    @keyframes slide-bottom {
      0% {
        transform: translateY(-10px);
      }
      100% {
        transform: translateY(0);
      }
    }
  }

  .components-dropdown_list {
    & > div {
      & > div {
        padding: 0;
        &:first-child {
          border-radius: 0;
        }
        
        & > div {
          width: 100%;
          height: 100%;
          
          & > div {
            padding: 11px 20px;
            box-sizing: border-box;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
`

const getScaledStyles = () => {
  const { innerWidth } = window
  const scale = 240 / innerWidth

  return css`
    transform: scale(${scale});
  `
}

export const ComponentPreview = styled.div`
  ${getScaledStyles};
  width: 100vw;
  min-height: 100px;
  max-height: 100vh;
  overflow: hidden;
  border-radius: 50px;
  flex-shrink: 0;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
  max-height: 250px;
  overflow: hidden;
`
