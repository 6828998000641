/* @flow */
import { validation } from './../Pricing6.consts'
import { imageModalActionMaker } from '@website/components/ActionMakers'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import ColorsIcon from '@editor/common/assets/svgr-icons/color_palette_icon.svg'
import { translate } from '@editor/common/utils/translations'
export default dispatcher => {
  const changeSlideTitle = (newTitle: string, idx: number) => ({
    type: 'CHANGE_SLIDE_TITLE',
    value: { newTitle, idx }
  })
  const changeItemData = (
    data: string,
    activeSlideIdx: number,
    itemIdx: number,
    keyInStore: string
  ) => ({
    type: 'CHANGE_ITEM_DATA',
    value: { data, activeSlideIdx, itemIdx, keyInStore }
  })
  const changeItemTitle = (
    title: string,
    activeSlideIdx: number,
    itemIdx: number
  ) => changeItemData(title, activeSlideIdx, itemIdx, 'title')

  const changeItemParagraph = (
    paragraph: string,
    activeSlideIdx: number,
    itemIdx: number
  ) => changeItemData(paragraph, activeSlideIdx, itemIdx, 'paragraph')

  const changeItemInfo = (
    info: string,
    activeSlideIdx: number,
    itemIdx: number
  ) => changeItemData(info, activeSlideIdx, itemIdx, 'info')

  const changeItemPrice = (
    price: string,
    activeSlideIdx: number,
    itemIdx: number
  ) => changeItemData(price, activeSlideIdx, itemIdx, 'price')

  const removeMenuItem = (idx: number, activeIdx: number) => ({
    type: 'REMOVE_MENU_ITEM',
    value: { idx, activeIdx }
  })
  const menuItemActions = (
    idx: number,
    activeIdx: number,
    menuItemsCount: number
  ) => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => dispatcher(removeMenuItem(activeIdx, idx)),
      toShow: menuItemsCount > validation.slides.menu.min
    }
  ]
  const addMenuItem = (activeIdx: number) => ({
    type: 'ADD_MENU_ITEM',
    value: activeIdx
  })
  const changeBadgeName = (
    newName: stinrg,
    slideIdx: number,
    menuIdx: number,
    badgeIdx: number
  ) => ({
    type: 'CHANGE_BADGE_NAME',
    value: { newName, slideIdx, menuIdx, badgeIdx }
  })
  const addBadgeItem = (slideIdx: number, menuIdx: number) => ({
    type: 'ADD_BADGE_ITEM',
    value: { slideIdx, menuIdx }
  })
  const addSlide = () => ({ type: 'ADD_SLIDE' })

  const changeBadgeColor = (
    slideIdx: number,
    menuIdx: number,
    badgeIdx: number,
    color: string
  ) => ({
    type: 'CHANGE_BADGE_COLOR',
    value: { slideIdx, menuIdx, badgeIdx, color }
  })

  const getBadgeActions = (
    slideIdx: number,
    menuIdx: number,
    badgeIdx: number,
    setSelectedBadgeIdx: (menuIdx: number, badgeIdx: number) => void
  ) => [
    {
      IconComp: ColorsIcon,
      tooltipText: translate('change_color_label'),
      handler: () => setSelectedBadgeIdx(menuIdx, badgeIdx)
    },
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () =>
        dispatcher({
          type: 'REMOVE_BADGE',
          value: { slideIdx, menuIdx, badgeIdx }
        })
    }
  ]
  return {
    addSlide,
    getBadgeActions,
    addBadgeItem,
    changeBadgeColor,
    changeBadgeName,
    addMenuItem,
    changeItemPrice,
    changeItemInfo,
    changeItemTitle,
    changeSlideTitle,
    menuItemActions,
    changeItemParagraph,
    sliderActions: imageModalActionMaker(dispatcher)
  }
}
