/* @flow */
import React from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'

import { BoldComp } from '@editor/common/styled-components/simple-tags'
import Modal from '@website/common/components/Modal/Modal.js'
import Button from '@renderforest/rf-ui-library/atoms/Button'
import { getCurrentUserName } from '@redux/selectors/index.js'
import {
  closeContributerSignoutPopup,
  removeUserData,
  fetchUserData
} from '@actions/editor'
import * as S from './styled.js'
import { signoutUserAPI } from '@api/site'
import './index.sass'

const mapStateToProps = (state: TAppState) => ({
  currentUserName: getCurrentUserName(state)
})

const mapDispatchToProps = {
  fetchUserData,
  removeUserData,
  closeContributerSignoutPopup
}

const ContributerSignOutPopup = ({
  currentUserName,
  removeUserData,
  closeContributerSignoutPopup
}) => {
  const signOut = () => {
    removeUserData()
    closeContributerSignoutPopup()
    signoutUserAPI().then(() => {
      window.location.reload()
      fetchUserData()
    })
  }
  const closePopup = () => {
    return
  }
  return (
    <Modal
      wrapClassname="signOutPopup"
      renderContent={() => (
        <S.Content>
          {transformTextVariables(translate('you_are_signed_in_with'), [
            {
              value: currentUserName || 'another',
              Comp: BoldComp
            }
          ])}
        </S.Content>
      )}
      onModalClose={closePopup}
      renderFooter={() => (
        <S.Footer>
          <Button
            fit
            className="confirm-button"
            size="small"
            type="secondary"
            onClick={signOut}
          >
            {translate('sign_out_label', true)}
          </Button>
        </S.Footer>
      )}
    />
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContributerSignOutPopup)
