/* @flow */
import styled from 'styled-components'
import THEME from './Contact5.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
const getStyleForProp = styleProvider(THEME)
import S from '@website/common/components/SelectMenu/SelectMenu.styled'
import Icon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const StyledContainer = styled(Container)`
  position: relative;
  ${getBackgroundStyles(THEME)};
  & ${S.SelectMenuWrap} {
    border-radius: ${getStyleForProp('inputRadius')};
  }
  & ${S.MenuWrap} {
    border-radius: ${getStyleForProp('inputRadius')};
  }
  & ${S.SelectMenu} {
    padding: 0 15px;
    border-radius: 25px;
    box-sizing: border-box;
    height: 50px;
    white-space: nowrap;
    overflow: hidden;
    font-family: inherit;
    &:after {
      content: '';
      position: absolute;
      border-top-right-radius: 23px;
      border-bottom-right-radius: 23px;
      width: 18px;
      height: 40px;
      transform: inherit;
      right: 0;
      z-index: 1;
      background-color: inherit;
    }
    .arrow_drop_down {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    .arrow-icon {
      background-color: inherit;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0' : '80px 0 160px 0')};
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0' : '60px 0 140px 0')};
  }
  .DayPicker-Day {
    min-width: 36px;
    box-sizing: border-box;
    padding: 8px 2px;
  }

  .DayPicker-Day--selected {
    outline: none;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 0;
  }
`

export const WorkingHoursContainer = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  width: 100%;
  max-width: 300px;
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  position: relative;
  .close-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  @media only screen and (max-width: 1024px) {
    position: absolute;
    bottom: -80px;
    z-index: 1;
  }
`

const WorkingHoursTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  margin: 30px 10px;
  color: ${getStyleForProp('workingHours')};
`

const Day = styled.p`
  font-size: 14px;
  font-weight: 700;
`

const Hour = styled.p`
  font-size: 14px;
`

export const WorkingHoursButtonContainer = styled.div`
  align-items: center;
  display: flex;
  cursor: pointer;
`

export const WorkingHoursButtonText = styled.p`
  margin-left: 20px;
  color: ${getStyleForProp('workingHours')};
`

const WorkingHoursIcon = styled(Icon).attrs(() => ({
  color: getStyleForProp('workingHours')
}))``

const CloseIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.palette.tertiarySemiLight
}))``

const WorkingDayContainer = styled.div`
  display: flex;
  padding: 10px 20px;
  justify-content: space-between;
  &:last-child {
    padding: 10px 20px 30px 20px;
  }
`

export default {
  StyledContainer,
  WorkingHoursIcon,
  WorkingHoursButtonText,
  WorkingHoursContainer,
  WorkingHoursButtonContainer,
  WmCustomContainer,
  WorkingHoursTitle,
  WorkingDayContainer,
  Day,
  Hour,
  CloseIcon
}
