/* @flow */
import React, { useContext, memo } from 'react'
import S from './Steps8.styled'
import { validation } from './Steps8.consts'
import Circle from './Circle'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled/'
import { translate } from '@editor/common/utils/translations'

const Steps8 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      steps,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <WMContainer>
        <Show when={[title, paragraph]}>
          <S.Headings>
            <EditableContent
              text={title}
              as={S.Title}
              required={!paragraph}
              maxCount={validation.headingMaxChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading42"
              alignment={titleAlignment}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              as={S.Paragraph}
              text={paragraph}
              required={!title}
              maxCount={validation.paragraphMaxChar}
              onChange={Actions.changeParagraph}
              className="WM_GLOBAL_paragraph18"
              alignment={paragraphAlignment}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.Headings>
        </Show>
        <S.StepsContainer>
          <Mapper
            data={steps}
            render={(step, idx) => (
              <S.StepItemContainer>
                <ControlsWithModalOpener
                  actions={Actions.blockActions({
                    idx,
                    showRemove: steps.length > validation.steps.min
                  })}
                >
                  <Circle isEditing={isEditing} idx={idx} isZero={idx < 9} />
                  <S.StepItem>
                    <S.StepItemHeading>
                      <If
                        condition={step.icon !== ''}
                        otherwise={() => (
                          <AddButton
                            onAdd={() => Actions.addIcon(idx)}
                            type="icon"
                            toShow
                            large
                          >
                            +
                          </AddButton>
                        )}
                        then={() => (
                          <S.IconWrap>
                            <ControlsWithModalOpener
                              actions={Actions.getIconActions({ idx })}
                            >
                              <S.IconContainer>
                                <Icon
                                  size="large"
                                  color="white"
                                  name={step.icon}
                                />
                              </S.IconContainer>
                            </ControlsWithModalOpener>
                          </S.IconWrap>
                        )}
                      />
                      <S.TitleWrap>
                        <EditableContent
                          text={step.title}
                          as={S.StepTitle}
                          className="WM_GLOBAL_heading20"
                          required={!step.subTitle}
                          maxCount={validation.blockTitleMaxChar}
                          onChange={(newTitle: string) =>
                            Actions.changeBlockTitle(newTitle, idx)
                          }
                          alignment={step.stepTitleAlignment}
                          changeAlignment={Actions.changeStepTitleAlignment(
                            idx
                          )}
                        />
                        <EditableContent
                          text={step.subTitle}
                          as={S.StepSubTitle}
                          required={!step.title}
                          className="WM_GLOBAL_paragraph18"
                          maxCount={validation.blockParagraphMaxChar}
                          onChange={(newSubTitle: string) =>
                            Actions.changeBlockSubTitle(newSubTitle, idx)
                          }
                          alignment={step.stepSubTitleAlignment}
                          changeAlignment={Actions.changeSubTitleAlignment(idx)}
                        />
                      </S.TitleWrap>
                    </S.StepItemHeading>
                    <EditableContent
                      text={step.content}
                      as={S.StepParagraph}
                      maxCount={validation.blockContentMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(newData: string) =>
                        Actions.changeBlockContent(newData, idx)
                      }
                      alignment={step.stepContentAlignment}
                      changeAlignment={Actions.changeStepContentAlignment(idx)}
                    />
                  </S.StepItem>
                </ControlsWithModalOpener>
              </S.StepItemContainer>
            )}
          />
        </S.StepsContainer>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={steps.length !== validation.steps.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Steps8
