// @flow

import React from 'react'
import PhoneInput from '../../../PhoneInput'
import * as Styled from './styled'

const PhoneNumberInput = ({ value, onChange, onRemoveIconClick, error, inputLabel }) => (
  <Styled.PhoneInputWrapper isValid={!error}>
    <Styled.PhoneInputLabel>{inputLabel}</Styled.PhoneInputLabel>
    <PhoneInput
      value={value}
      onRemoveIconClick={onRemoveIconClick}
      onChange={onChange}
    />
    {error && (
      <Styled.ErrorMessageWrapper>
        {error}
      </Styled.ErrorMessageWrapper>
    )}
  </Styled.PhoneInputWrapper>
)

export default PhoneNumberInput
