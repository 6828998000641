/* @flow */
/* eslint-disable max-statements */
import React, { useContext, useState, useEffect, useRef, memo } from 'react'
import If from '../Conditional'
import { _debounce } from '@website/common/utils'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import type Props from './Types.flow.js'
import * as S from './ProjectLinks.styled'
import EditableContent from '../EditableContent'
import LinksDropDown from './LinksDropDown'
import ArrowDownIcon from '@website/common/assets/svgr-icons/keyboard_arrow_down.svg'
import MenuIcon from '@website/common/assets/svgr-icons/menu.svg'
import { translate } from '@editor/common/utils/translations'

import ActionGetter from './Actions'
import {
  ProjectLinksContext,
  ExtraLinksLabelContext,
  EditingContext,
  ControlModalsContext
} from '@contexts'
import {
  MobileNavBar,
  getLastVisibleLinkIndex,
  LinksList
} from './ProjectLinks.Helper'
import { useResponsive } from '@shared/hooks'

const getWindowSizeWithPercent = widthPercent => {
  const windowSize = global.window ? global.window.innerWidth : 0
  return windowSize * widthPercent
}

const ProjectLinks = ({
  dispatch,
  isNavBarFixed,
  isWebsiteMultilingual,
  alignment = 'right',
  websiteLanguages,
  additionalContent: AdditionalContent
}: Props) => {
  const [showDropdown, setDropdownStatus] = useState(false)
  const [lasVisibleIdx, setLastIdx] = useState(0)
  const moreDropdownContainerRef = useRef(null)

  const isSmallSizes = useResponsive(1024)
  const { isEditing } = useContext(EditingContext)
  const { headerLinks } = useContext(ProjectLinksContext)
  const extraLinksLabel = useContext(ExtraLinksLabelContext)
  const { setModalState } = useContext(ControlModalsContext)

  const Actions = ActionGetter(dispatch, setModalState)
  const widthPercent = AdditionalContent && isEditing ? 0.3 : 0.4

  const onResize = () => {
    setLastIdx(
      getLastVisibleLinkIndex(
        headerLinks,
        getWindowSizeWithPercent(widthPercent),
        0,
        0
      )
    )
  }

  const closeDropDown = () => setDropdownStatus(false)

  useEffect(() => {
    setLastIdx(
      getLastVisibleLinkIndex(
        headerLinks,
        getWindowSizeWithPercent(widthPercent),
        0,
        0
      )
    )
  }, [lasVisibleIdx, headerLinks])

  useEffect(() => {
    const debouncedImgSourceSetter = _debounce(onResize, 400)
    window.addEventListener('resize', debouncedImgSourceSetter)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  const addLinkAction = () => dispatch(Actions.addLink())

  const addLink = () => {
    return (
      <AddButton onAdd={addLinkAction} toShow={true}>
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_link_label')}
      </AddButton>
    )
  }

  return (
    <React.Fragment>
      <S.Desktop
        alignment={alignment}
        className="porjectLinksDesktop"
        isNavBarFixed={isNavBarFixed}
      >
        {!isEditing && !(headerLinks && headerLinks.length) ? null : (
          <S.LinkContainer>
            <LinksList
              actions={Actions}
              list={headerLinks.slice(0, lasVisibleIdx)}
            />
            <If
              condition={lasVisibleIdx < headerLinks.length}
              then={() => (
                <S.DropDownContainer ref={moreDropdownContainerRef}>
                  <S.MoreLinks onClick={() => setDropdownStatus(!showDropdown)}>
                    <EditableContent
                      text={extraLinksLabel}
                      as={S.Div}
                      onChange={(value: string) =>
                        dispatch(Actions.setLinksLabelValue(value))
                      }
                      areControlsHidden
                    />

                    <S.MoreArrowIcon SvgComp={ArrowDownIcon} size="large" />
                  </S.MoreLinks>
                  {moreDropdownContainerRef.current &&
                    showDropdown &&
                    !isSmallSizes && (
                      <LinksDropDown
                        containerRef={moreDropdownContainerRef.current}
                        Actions={Actions}
                        closeDropDownCb={closeDropDown}
                        lastVisibleLinkIndex={lasVisibleIdx}
                      />
                    )}
                </S.DropDownContainer>
              )}
            />
            {addLink()}
          </S.LinkContainer>
        )}
        {AdditionalContent && <AdditionalContent />}
      </S.Desktop>
      <S.Mobile>
        <If
          condition={
            headerLinks.length > 0 || AdditionalContent || isWebsiteMultilingual
          }
          then={() => (
            <S.MobileNavbarContainer
              key="MobileNavbarContainer"
              className="mobile-navbar-container"
              onClick={() => setDropdownStatus(!showDropdown)}
            >
              <S.HamburgerIcon SvgComp={MenuIcon} size="large" />
              <If
                condition={showDropdown && isSmallSizes}
                then={() => (
                  <MobileNavBar
                    onAdd={addLinkAction}
                    Actions={Actions}
                    links={headerLinks}
                    additionalContent={AdditionalContent}
                    isWebsiteMultilingual={isWebsiteMultilingual}
                    websiteLanguages={websiteLanguages}
                    closeNavBar={closeDropDown}
                  />
                )}
              />
            </S.MobileNavbarContainer>
          )}
          otherwise={() => addLink()}
        />
      </S.Mobile>
    </React.Fragment>
  )
}

export default memo(ProjectLinks)
