/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature16_2.styled'
import { validation } from './Feature16_2.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { DispatchContext, EditingContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const Feature16_2 = memo(props => {
  const {
    data: { title, paragraph, features, titleAlignment, paragraphAlignment }
  } = props

  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const changeFeaturTitle = (featureIdx, level, subIdx) => newTitle =>
    dispatcher(Actions.changeBlockTitle(featureIdx, level, subIdx, newTitle))

  const renderFeatureItems = (features, featureIdx) => {
    const renderItems = (item, subIdx = 0, level = 0) => (
      <If
        key={subIdx}
        condition={Array.isArray(item)}
        then={() => (
          <div className={`withSubItems_${level}`}>
            <Mapper
              data={item}
              render={(subItem, subIdx) =>
                renderItems(subItem, subIdx, level + 1)
              }
            />
          </div>
        )}
        otherwise={() => {
          return (
            <S.Item className="item" key={subIdx} isFirstImage={subIdx === 0}>
              <S.Figure className={`${level === 0 ? 'big-image' : ''}`}>
                <ControlsWithImageCropper
                  className="component-controls"
                  style={{ width: '100%' }}
                  actions={Actions.blockImageActions(
                    featureIdx,
                    level,
                    subIdx,
                    item.imageUrl
                  )}
                >
                  <Image
                    as={S.Img}
                    alt="feature"
                    asProps={{
                      isEditing,
                      imgCropParams: item.imgCropParams
                    }}
                    defaultImgSrc={item.imageUrl}
                    sizes={item.imgDimensions}
                  />
                </ControlsWithImageCropper>
                <EditingContext.Consumer>
                  {({ isEditing }) => {
                    return (
                      <S.ItemInfo
                        className="item-info"
                        isEditing={isEditing}
                        withoutTexts={
                          !isEditing &&
                          !(item.title || item.paragraph || item.badgeText)
                        }
                      >
                        <EditableContent
                          text={item.title}
                          alignment={item.blockTitleAlignment}
                          as={S.BlockTitle}
                          isEditing={isEditing}
                          className="item-title"
                          maxCount={validation.blockTitleMaxChar}
                          onChange={changeFeaturTitle(
                            featureIdx,
                            level,
                            subIdx
                          )}
                          changeAlignment={Actions.changeBlockTitleAlignment(
                            featureIdx,
                            level,
                            subIdx
                          )}
                        />
                        <EditableContent
                          text={item.paragraph}
                          alignment={item.blockParagraphAlignment}
                          as={S.BlockParagraph}
                          isEditing={isEditing}
                          className="item-paragraph"
                          maxCount={validation.blockSubTitleMaxChar}
                          onChange={(newParagraph: string) => {
                            Actions.changeBlockParagraph(
                              featureIdx,
                              level,
                              subIdx,
                              newParagraph
                            )
                          }}
                          changeAlignment={Actions.changeBlockParagraphAlignment(
                            featureIdx,
                            level,
                            subIdx
                          )}
                        />
                        <Show when={[item.badgeText]}>
                          <S.Badge className="item-badge" isEditing={isEditing}>
                            <EditableContent
                              text={item.badgeText}
                              as={S.BadgeText}
                              maxCount={validation.badgeMaxChar}
                              onChange={(badgeText: string) => {
                                Actions.changeBlockBadgetext(
                                  featureIdx,
                                  level,
                                  subIdx,
                                  badgeText
                                )
                              }}
                            />
                          </S.Badge>
                        </Show>
                      </S.ItemInfo>
                    )
                  }}
                </EditingContext.Consumer>
              </S.Figure>
            </S.Item>
          )
        }}
      />
    )
    return features.map((feature, i) => renderItems(feature, i))
  }
  return (
    <S.Container>
      <S.WMCustomContainer>
        <EditableContent
          text={title}
          alignment={titleAlignment}
          as={S.Title}
          maxCount={validation.headingMaxChar}
          className="WM_GLOBAL_heading72"
          onChange={Actions.changeTitle}
          changeAlignment={Actions.changeTitleAlignment}
        />
        <EditableContent
          text={paragraph}
          alignment={paragraphAlignment}
          as={S.Description}
          maxCount={validation.paragraphMaxChar}
          className="WM_GLOBAL_paragraph18"
          onChange={Actions.changeParagraph}
          changeAlignment={Actions.changeParagraphAlignment}
        />
        <S.FeaturesWrap className="features-wrap">
          <Mapper
            data={features}
            render={(feature, idx) => {
              return (
                <S.RowWrapper>
                  <S.SubItem className="withSubItems" isEven={idx % 2 === 0}>
                    {renderFeatureItems(feature, idx)}
                  </S.SubItem>
                  <If
                    condition={isEditing}
                    then={() => (
                      <S.AddButtonWrapper>
                        <AddButton
                          onAdd={() => Actions.addBlock(idx)}
                          toShow={features.length < validation.features.max}
                          style={{
                            marginTop: '0 0.5rem'
                          }}
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          {translate('add_block_label')}
                        </AddButton>
                        <AddButton
                          onAdd={() => Actions.removeBlock(idx)}
                          toShow={features.length > validation.features.min}
                          style={{
                            margin: '0 0.5rem'
                          }}
                        >
                          <Icon
                            name="glyph-remove"
                            className="icon"
                            size="normal"
                          />
                          {translate('remove_block_label')}
                        </AddButton>
                      </S.AddButtonWrapper>
                    )}
                  />
                </S.RowWrapper>
              )
            }}
          />
        </S.FeaturesWrap>
      </S.WMCustomContainer>
    </S.Container>
  )
})

export default Feature16_2
