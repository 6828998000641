/* @flow */
/**
 * @description Finds image change action index from controlsActions array.
 * @param {Array} actions
 * @returns {number} Returns image change action index.
 **/
const findCropModalActionIndex = (actions: Array<Object>) => {
  return actions.findIndex(action => action.modalType === 'crop')
}

/**
 * @description Finds image crop action index from controlsActions array.
 * @param {Array} actions
 * @returns {number} Returns image crop action index.
 **/
const findImageModalActionIndex = (actions: Array<Object>) => {
  return actions.findIndex(action => action.modalType === 'image')
}

const addCropperModalProps = actions => (modalProps, showCropAction) => {
  const cropActionIndex = findCropModalActionIndex(actions)
  const cropAction = actions[cropActionIndex]
  const cropModalProps = {
    ...cropAction.modal,
    props: modalProps
  }

  return [
    ...actions.slice(0, cropActionIndex),
    {
      ...cropAction,
      toShow:
        !!modalProps.containerWidth && !!modalProps.height && showCropAction,
      modal: cropModalProps
    },
    ...actions.slice(cropActionIndex + 1)
  ]
}

const getAuthorizedImageAction = actions => ({
  isUserAuthorized,
  openLoginPopup
}) => {
  if (isUserAuthorized) {
    return actions
  }
  const imageActionIndex = findImageModalActionIndex(actions)
  const imageAction = actions[imageActionIndex]

  return [
    ...actions.slice(0, imageActionIndex),
    {
      ...imageAction,
      modal: undefined,
      handler: openLoginPopup
    },
    ...actions.slice(imageActionIndex + 1)
  ]
}

export const prepareActions = ({
  sizes,
  cropperType,
  controlActions,
  imgCropParams,
  isUserAuthorized,
  openLoginPopup,
  imgDimensions
}) => {
  const { width, height } = sizes
  const hasImgDimensions = !!(
    imgDimensions && Object.keys(imgDimensions).length !== 0
  )
  const imgSrc = hasImgDimensions && imgDimensions['medium']

  const actionsWithCropperProps = addCropperModalProps(controlActions)(
    {
      containerWidth: width,
      height,
      imgSrc,
      imgCropParams,
      cropperType,
      isFromCropper: true,
      showReplace: false
    },
    hasImgDimensions
  )

  const authorizedImageAction = getAuthorizedImageAction(
    actionsWithCropperProps
  )({
    isUserAuthorized,
    openLoginPopup
  })

  return authorizedImageAction
}
