// @flow

import React, { useEffect, useState } from 'react'
import SlickSlider from 'react-slick'
import ItemWrapper from './ItemWrapper'
import { PrevArrow, NextArrow } from './Arrows'
import { SLIDER_PARAMS } from './consts'
import * as Styled from './styled'
import '../../Swipe/swipe-slider-styles.css'

const ADDITIONAL_PADDING = 150

const Slider = (
  {
    swipeToSlide,
    swipe,
    accessibility,
    slickProps,
    children,
    beforeChange,
    onMouseDown
  }
) => {
  const slidesLength = React.Children.count(children)
  const initialSlide = parseInt(slidesLength / 2 - 1)
  const [deviceType, setDeviceType] = useState('desktop')

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth
      const deviceType =
        windowWidth > 768
          ? 'desktop'
          : windowWidth <= 768 && windowWidth > 374
          ? 'tablet'
          : 'mobile'
      setDeviceType(deviceType)
    }
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const sliderProps = {
    ...SLIDER_PARAMS,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />
  }

  return (
    <div
      key={deviceType}
      style={{
        maxWidth: sliderProps.slidesToShow + ADDITIONAL_PADDING,
        margin: '0 auto'
      }}
    >
      <Styled.GlobalStyles />
      <SlickSlider
        {...sliderProps}
        {...slickProps}
        initialSlide={initialSlide}
        beforeChange={beforeChange}
        swipeToSlide={swipeToSlide}
        accessibility={accessibility}
        swipe={swipe}
      >
        {React.Children.map(children, (child, idx) => {
          return (
            <ItemWrapper
              animationDuration={sliderProps.speed}
              key={idx}
              onMouseDown={onMouseDown}
            >
              {child}
            </ItemWrapper>
          )
        })}
      </SlickSlider>
    </div>
  )
}

export default Slider
