/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Feature9_2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  .webpage-wrap {
    box-shadow: rgb(37 43 52 / 12%) 0px 9px 22px 0px;
  }
  .buttons-block {
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '130px 0 80px 0' : ' 80px 0')};
  }
`
const WMCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  margin-top: 20px;
`

const Code = styled.p`
  margin: 0;
  background-color: #fff;
  width: 100%;
  height: 368px;
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  display: block;
  &:focus {
    outline: none;
  }
`
const HeaderContent = styled.div`
  width: 100%;
  max-width: 650px;
  margin: 0 auto 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    box-sizing: border-box;
    margin-bottom: 40px;
  }
  > :first-child {
    margin-top: 0;
  }
`

const WebpageWrap = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`

const BrowserBar = styled.img`
  width: 100%;
  display: block;
  max-width: initial;
`

export default {
  Code,
  StyledContainer,
  WMCustomContainer,
  HeaderContent,
  WebpageWrap,
  BrowserBar,
  Title,
  Description
}
