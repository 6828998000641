/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_DATE_TITLE':
        draft.dateTitle = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_DATA_TITLE_ALIGNMENT': {
        draft.dataTitleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
      case 'REMOVE_FORM':
        draft.buttonText = ''
        break
      case 'ADD_FORM':
        draft.buttonText = initialState.buttonText
        break
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'ADD_SOCIAL':
        draft.headerSocials.push(initialState.headerSocials[0])
        break
      case 'REMOVE_SOCIAL':
        draft.headerSocials.splice(action.value.idx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.headerSocials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.headerSocials[idx].icon = iconName
        }
        break
      case 'CHANGE_DATE':
        draft.countDownDate = action.value
        break
      case 'CHANGE_DATE_LABEL':
        const { dateLabelValue, dateLabelIdx } = action.value
        draft.dateLabels[dateLabelIdx] = dateLabelValue
        break
      case 'CHANGE_PLACEHOLDER_TEXT':
        draft.placeholderText = action.value
        break
    }
  })
}
