/* @flow */
import styled, { css } from 'styled-components'
import THEME from './RSVP3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getCardBGOverlayStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};

  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 150px;
          @media only screen and (max-width: 768px) {
            padding-top: 130px;
          }
        `
      : css``};

  flex-direction: column;
  position: relative;
  overflow: hidden;

  .add-button-wrap {
    margin-bottom: 30px;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
  margin-top: 20px;
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;

  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &:nth-child(1) {
    margin-top: 0px;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

const Postcard = styled.div`
  padding: 40px 0;
  box-sizing: border-box;
  border-radius: ${getStyleForProp('cardRadius')};
  ${({ isCardImage }) =>
    !isCardImage
      ? css`
          background-color: ${getStyleForProp('blockHeading')};
          border: 1px solid ${getStyleForProp('border')};
        `
      : css``};
  width: 95%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  @media only screen and (max-width: 768px) {
    width: 100%;
    max-width: 600px;
  }
`

const SubTitle = styled.p`
  display: block;
  width: 100%;
  color: ${getStyleForProp('subTitle')};
  ${getTextStyleByAlignment};
  & + h1 {
    margin-top: 10px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0px;
  word-break: break-word;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: ${({ isEditing }) => (isEditing ? '0' : '20px')};
  width: 100%;
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const Date = styled.p`
  color: ${getStyleForProp('dateText')};
  display: block;
  width: 100%;
  ${({ isEditing }) => (isEditing ? '' : 'margin-top: 30px;')};
  ${getTextStyleByAlignment};
`

const Location = styled.a`
  display: block;
  width: 100%;
  ${({ isEditing }) => (isEditing ? '' : 'margin-top: 20px;')};
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('paragraph')};
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const ImageWrap = styled.div`
  font-size: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
`

const ImageFigureBG = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  height: 100%;

  &::after {
    ${getCardBGOverlayStyles(THEME)};
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

const ImageFigure = styled.div`
  text-align: center;
  font-size: 0;
  width: 100%;
  max-width: 150px;
  margin: 0 auto;
  border-radius: ${getStyleForProp('iconRadius')};

  @media only screen and (max-width: 768px) {
    max-width: 100px;
  }
  @media only screen and (max-width: 375px) {
    max-width: 70px;
  }
  .LazyLoad {
    position: relative;
    padding-top: 100%;
  }
  .lazy-figure {
    position: absolute;
    top: 0;
    border-radius: ${getStyleForProp('iconRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: ${getStyleForProp('cardRadius')};
`

const ComponentWrapper = styled.div`
  .form-container {
    background-color: ${getStyleForProp('backgroundColor')};
    .control-container {
      width: 95%;
      max-width: 600px;
      padding: 40px 0 20px 0;
      margin: 40px auto 50px auto;
      @media only screen and (max-width: 768px) {
        max-width: 600px;
      }
      @media only screen and (max-width: 1024px) {
        margin-right: 20px;
        margin-left: 20px;
        > div {
          padding-right: 20px;
          padding-left: 20px;
        }
      }
      @media only screen and (max-width: 457px) {
        margin-right: 10px;
        margin-left: 10px;
        > div {
          padding-right: 10px;
          padding-left: 10px;
        }
      }
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Postcard,
  Date,
  Location,
  SubTitle,
  Title,
  Paragraph,
  Content,
  Img,
  ImageWrap,
  ImageFigure,
  ImageFigureBG,
  ComponentWrapper
}
