/* @flow */
import React from 'react'
import S from './Slider1.styled'
import Slider from 'react-slick'
import { validation } from './Slider1.consts'
import ActionGetter from './Actions'
import { isMobileDevice } from '@website/common/utils'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import If from '@website/common/components/Conditional'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import { getSliderSettings } from './Slider1.helper.js'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import LinkWrapper from '@website/common/components/LinkWrapper'
import MainImage from './MainImage'
import { withDispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import '../Swipe/swipe-slider-styles.css'
import './Slider1.styles.sass'
import { translate } from '@editor/common/utils/translations'

class Slider1 extends React.PureComponent<void, void> {
  state = {
    activeIndex: 0,
    slideClicked: false
  }
  sliderRef: null

  componentDidMount() {
    const {
      data: { slides }
    } = this.props
    if (slides.length > 0) {
      this.setState({
        activeIndex: slides.length - 1
      })
    }
  }
  getPrevIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + slides.length - 1) % slides.length
  }
  getNextIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + 1) % slides.length
  }

  onPrevClick = () => {
    const { activeIndex } = this.state
    this.changeActiveSlideIndex(this.getPrevIdx(activeIndex))
    this.sliderRef.slickPrev()
  }

  onNextClick = () => {
    const { activeIndex } = this.state
    this.changeActiveSlideIndex(this.getNextIdx(activeIndex))
    this.sliderRef.slickNext()
  }

  stopAutoPlay = () => {
    const { slideClicked } = this.state
    if (!slideClicked) {
      this.setState({ slideClicked: true })
    }
  }

  changeActiveSlideIndex = (activeIndex: number) => {
    this.stopAutoPlay()
    this.setState({
      activeIndex
    })
  }

  selectImg = (index: number) => {
    if (isMobileDevice()) {
      return
    }
    this.changeActiveSlideIndex(index - 1)
    this.sliderRef.slickGoTo(index)
  }

  render() {
    const {
      data: { title, paragraph, slides, buttons },
      componentDispatcher
    } = this.props
    const Actions = ActionGetter(componentDispatcher)
    const { activeIndex, slideClicked } = this.state
    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.Container isEditing={isEditing}>
              <S.SliderContainer
                className="slider-container"
                isEditing={isEditing}
              >
                <MainImage
                  Actions={Actions}
                  slides={slides}
                  stopAutoPlay={this.stopAutoPlay}
                  activeIndex={activeIndex}
                />
                <div className="slider-content">
                  <div className="slider-text-wrap">
                    <Show when={[title, paragraph]}>
                      <div className="content-text">
                        <Show when={[title]}>
                          <EditableContent
                            text={title}
                            as={S.Title}
                            maxCount={validation.maxTitleChar}
                            className="WM_GLOBAL_heading"
                            onChange={(newTitle: string) =>
                              componentDispatcher(Actions.changeTitle(newTitle))
                            }
                          />
                        </Show>
                        <Show when={[paragraph]}>
                          <EditableContent
                            text={paragraph}
                            as={S.Paragraph}
                            maxCount={validation.maxParagraphChar}
                            className="WM_GLOBAL_paragraph"
                            onChange={(newParagraph: string) =>
                              componentDispatcher(
                                Actions.changeParagraph(newParagraph)
                              )
                            }
                          />
                        </Show>
                      </div>
                    </Show>
                    <S.ButtonsWrap className="WM_GLOBAL_secondary-font">
                      <Mapper
                        data={buttons}
                        render={(btn: string, btnIdx: number) => {
                          return (
                            <ControlsWithModalOpener
                              key={btnIdx}
                              actions={Actions.getBtnActions(btnIdx, {
                                text: btn.buttonText,
                                link: btn.buttonUrl
                              })}
                            >
                              <S.LinkWrapper
                                isTertiary={btn.type === 'tertiary'}
                              >
                                <LinkWrapper
                                  text={btn.buttonText}
                                  url={btn.buttonUrl}
                                  linkProps={{
                                    className: 'rf-comp-btn'
                                  }}
                                />
                              </S.LinkWrapper>
                            </ControlsWithModalOpener>
                          )
                        }}
                      />
                      <AddButton
                        onAdd={() => componentDispatcher(Actions.addButton())}
                        toShow={buttons.length === 0}
                        rounded
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_button_label')}
                      </AddButton>
                    </S.ButtonsWrap>
                  </div>
                  <If
                    condition={slides.length > 1}
                    then={() => (
                      <div className="slider-controls-wrap">
                        <div className="slider-images-wrap">
                          <Slider
                            {...getSliderSettings(slideClicked)}
                            className="slider"
                            ref={ref => (this.sliderRef = ref)}
                            afterChange={value =>
                              this.setState({
                                activeIndex: this.getPrevIdx(value)
                              })
                            }
                          >
                            {slides.map((slide, idx) => {
                              return (
                                <div
                                  key={idx}
                                  className="slider-images-figure"
                                  onClick={() => this.selectImg(idx + 1)}
                                >
                                  <ControlsWithImageCropper
                                    toPropagate={false}
                                    actions={Actions.getSliderActions({
                                      keyForSizes: 'slider1',
                                      additionalArgs: { idx },
                                      showRemove:
                                        slides.length > validation.slides.min,
                                      clickHandlers: {
                                        change: this.stopAutoPlay,
                                        crop: this.stopAutoPlay
                                      },
                                      imageUrl: slide.imgUrl
                                    })}
                                    style={{
                                      width: '100%',
                                      height: '100%'
                                    }}
                                  >
                                    <Image
                                      as={S.SliderImg}
                                      alt="Slider image"
                                      sizes={slide.imgDimensions}
                                      defaultImgSrc={slide.imgUrl}
                                      asProps={{
                                        imgCropParams: slide.imgCropParams,
                                        className: 'slider-main-img'
                                      }}
                                    />
                                  </ControlsWithImageCropper>
                                </div>
                              )
                            })}
                          </Slider>
                        </div>
                        <div className="slider-controls">
                          <S.IconContainer className="wm-slider-btn left">
                            <Icon
                              color="white"
                              size="large"
                              name="keyboard_arrow_left"
                              onClick={this.onPrevClick}
                            />
                          </S.IconContainer>
                          <S.IconContainer className="wm-slider-btn right">
                            <Icon
                              color="white"
                              size="large"
                              name="keyboard_arrow_right"
                              onClick={this.onNextClick}
                            />
                          </S.IconContainer>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </S.SliderContainer>
              <AddButton
                style={{ marginTop: '1.5rem' }}
                onAdd={() => componentDispatcher(Actions.addSlide())}
                toShow={slides.length < validation.slides.max}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_photo_label')}
              </AddButton>
            </S.Container>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

const Slider1WithContexts = withDispatchContext(Slider1)

export default Slider1WithContexts
