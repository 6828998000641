/* @flow */
import styled from 'styled-components'
import THEME from './Email2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import { Button } from '../SubscribeForm/styled'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  & ${Button} {
    min-width: 80px;
    flex: none;
  }
`

const WMCustomContainer = styled(WMContainer)`
  max-width: 700px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${({ isEditing }) => (isEditing ? 'margin-top: 60px' : null)};
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 650px) {
    form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  > :first-child {
    margin-top: 0;
  }
`

const FormWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  transform: translateX(-24px);
  margin: 30px auto 0 auto;
  @media only screen and (max-width: 650px) {
    transform: unset;
  }
  form {
    display: flex;
    justify-content: center;
    input {
      min-width: 240px;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const EmailContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .btns-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
  padding-top: 20px;
`

export default {
  StyledContainer,
  WMCustomContainer,
  EmailContent,
  Title,
  Description,
  FormWrap
}
