import styled from 'styled-components'

const SocialsBlock = styled.div`
  display: flex;
  .rf-add-btn {
    padding: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: initial;
    }
  }
`

const SocialsWrapper = styled.div`
  display: flex;
  .link {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
  }
`

export default {
  SocialsBlock,
  SocialsWrapper
}
