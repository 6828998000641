// @flow

import React, { memo, useCallback, useState } from 'react'
import * as Styled from './styled'
import SlideLeft from './SlideLeft'
import Content from './Content'

const MobileMenu = () => {
  const [isOpen, setOpenedState] = useState(false)

  const toggle = useCallback(() => {
    if (isOpen) {
      return
    }
    setOpenedState(!isOpen)
  }, [isOpen])

  const close = useCallback(() => {
    setOpenedState(false)
  }, [])

  return (
    <>
      <Styled.BurgerContainer onClick={toggle} isOpen={isOpen}>
        <i />
        <i />
      </Styled.BurgerContainer>
      <SlideLeft
        isOpen={isOpen}
        onClose={close}
        backdropDisablingSelectors={['.website-status-mobile-popup']}
      >
        <Content closeSlideLeft={close} />
      </SlideLeft>
    </>
  )
}

export default memo(MobileMenu)
