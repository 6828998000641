/* @flow */
export const revisionActions = [
  'CREATE_NEW_PAGE',
  'ADD_COMPONENT',
  'ADD_MULTIPLE_COMPONENTS',
  'REMOVE_PROJECT_PAGE',
  'REPLACE_COMPONENT',
  'DUPLICATE_COMPONENT',
  'REMOVE_COMPONENT',
  'COMPONENT_TO_TOP',
  'COMPONENT_TO_BOTTOM',
  'REVISION_FOR_PREVIEW',
  'SET_DOMAIN_VALUE',
  'CHANGE_PROJECT_PAGE_NAMES',
  'CHANGE_PAGE_META',
  'SET_EXTRA_LINKS_LABEL',
  'APPLY_TEMPLATE_PRESET',
  'RESIZE_LOGO',
  'EDIT_NAVIGATION_LINK',
  'EDIT_NAVIGATION_LINK',
  'REMOVE_NAVIGATION_LINK',
  'ADD_HEADER_LINK',
  'ADD_FOOTER_LINK',
  'DUPLICATE_PAGE',
  'SET_EVENT_ID_TO_RSVP_COMPONENTS'
]

export const visualParamsActions = [
  'CHANGE_PALETTE',
  'CHANGE_FONT_SIZE',
  'CHANGE_FONT_PAIR',
  'CHANGE_ENTIRE_PALETTE',
  'CHANGE_LOOK',
  'SET_VISUAL_PARAMS'
]

export const commonActions = ['UNDO', 'REDO']
