/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatch => {
  const addRow = (idx: string) =>
    dispatch({
      type: 'ADD_ROW',
      value: idx
    })

  const removeRow = (idx: string) =>
    dispatch({
      type: 'REMOVE_ROW',
      value: idx
    })

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _addRow = idx => dispatch(addRow(idx))

  const _removeRow = idx => dispatch(removeRow(idx))

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeSixthParagraph = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_SIXTH_PARAGRAPH',
      value: newParagraph
    })
  }

  const changeSixthParagraphAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SIXTH_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  const changeSeventhParagraph = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_SEVENTH_PARAGRAPH',
      value: newParagraph
    })
  }

  const changeSeventhParagraphAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SEVENTH_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  const addLeftArrow = () => {
    dispatch({
      type: 'ADD_LEFT_ARROW'
    })
  }

  const addRightArrow = () => {
    dispatch({
      type: 'ADD_RIGHT_ARROW'
    })
  }

  const imageActions = (
    idx: number,
    galleryRowIdx: number,
    imgUrl: string,
    mediaFlyoutShift
  ) => {
    const keyForSizes =
      idx === 0 || idx === 6 ? 'portfolio13Large' : 'portfolio13Small'
    return cropableImageModalActionMaker(dispatch)({
      keyForSizes,
      showRemove: false,
      additionalArgs: { idx, galleryRowIdx },
      imgUrl,
      mediaFlyoutShift
    })
  }

  const removeLeftArrowActions = () => [
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => removeLeftArrow(),
      toShow: true
    }
  ]
  const removeArrowAction = arrowType => {
    const actionType =
      arrowType === 'left' ? 'REMOVE_LEFT_ARROW' : 'REMOVE_RIGHT_ARROW'
    return [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => {
          dispatch({ type: actionType })
        },
        toShow: true
      }
    ]
  }

  return {
    addRow: _addRow,
    removeRow: _removeRow,
    imageActions,
    addLeftArrow,
    removeLeftArrowActions,
    addRightArrow,
    removeArrowAction,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeSixthParagraph,
    changeSeventhParagraph,
    changeSeventhParagraphAlignment,
    changeSixthParagraphAlignment
  }
}
