/* @flow */
import styled from 'styled-components'
import { hexToRgb } from '@website/common/utils'
import THEME from './Email3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import { Button } from '../SubscribeForm/styled'
import { secondary } from '@website/common/styles/colors'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding-top: ${({ isEditing, isImageExists }) =>
    isEditing ? '220px' : isImageExists ? '155px' : '80px'};
  @media only screen and (max-width: 650px) {
    padding-top: ${({ isEditing, isImageExists }) =>
      isEditing ? '185px' : isImageExists ? '135px' : '60px'};
  }
  & ${Button} {
    min-width: 80px;
    flex: none;
  }
  .light-content:not(.warning-near):not(.warning-max) {
    &:focus {
      outline-color: ${secondary.Dark};
    }
  }
  .light-content:hover {
    box-shadow: 0px 0px 0px 1px ${secondary.Dark};
  }
`

const FormContainer = styled.div`
  max-width: 800px;
  margin-right: auto;
  margin-left: auto;
  padding: ${({ isImageExists, isEditing }) =>
    !isImageExists && !isEditing ? '40px 53px' : '105px 53px 40px 53px'};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: ${({ theme }) =>
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  border: ${({ theme }) =>
    `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.2)`};
  background: ${({ theme }) => theme.palette.accentWhite};
  border-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 650px) {
    padding: ${({ isImageExists, isEditing }) =>
      !isImageExists && !isEditing ? '40px 20px' : '105px 20px 40px 20px'};

    form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  > :first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: 650px) {
    .FormMessage {
      width: 100%;
      bottom: 58px;
    }
  }
`

const FormWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  transform: translateX(-24px);
  margin: 30px auto 0 auto;
  @media only screen and (max-width: 650px) {
    transform: unset;
  }
  form {
    display: flex;
    justify-content: center;
    input {
      min-width: 240px;
      &:focus {
        border-color: ${({ theme }) =>
          `1px solid rgba(${hexToRgb(
            theme.palette.tertiaryDark
          )}, 1) !important`};
      }
    }

    .wm-email-input {
      &::placeholder {
        text-align: left;
      }
    }
    @media only screen and (max-width: 650px) {
      button {
        margin-top: 30px;
      }
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const EmailContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btns-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
  padding-top: 20px;
`

export const ImageFrame = styled.div`
  width: 150px;
  position: absolute;
  top: -75px;
  .control-container {
    width: 100%;
    height: 100%;
    padding-top: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
    border-radius: ${getStyleForProp('iconRadius')};
  }
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

export const AddBtnWrapper = styled.div`
  position: absolute;
  top: -25px;
`

export default {
  StyledContainer,
  FormContainer,
  EmailContent,
  Title,
  Description,
  FormWrap,
  ImageFrame,
  ImageWrapper,
  Img,
  AddBtnWrapper
}
