/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Badge = ({ theme }) => {
  const { look, palette } = theme
  const isSharp = look === 'SHARP'
  const from = palette.primaryLight
  const to = palette.primaryDark
  return (
    <svg
      width="69"
      height="69"
      className="rf-badge-svg"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="gradient" x1="50%" x2="50%" y1="0%" y2="96.055%">
          <stop offset="0%" stopColor={from} />
          <stop offset="100%" stopColor={isSharp ? from : to} />
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="url(#gradient)"
          d="M66 66L3 3H0V0h47.366L69 21.634V69h-3v-3z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M47.483 15.068l1.48 3.626 3.908.282a1.58 1.58 0 0 1 .898 2.786l-2.994 2.495.94 3.808a1.58 1.58 0 0 1-2.37 1.747l-3.326-2.063-3.326 2.063a1.58 1.58 0 0 1-2.37-1.713l.94-3.809-2.994-2.495a1.58 1.58 0 0 1 .898-2.819l3.908-.282 1.48-3.626a1.58 1.58 0 0 1 2.928 0z"
        />
      </g>
    </svg>
  )
}

export default withTheme(Badge)
