export default {
  DARK: {
    background: p => p.tertiaryDark,
    text: p => p.accentWhite
  },
  LIGHT: {
    background: p => p.tertiaryLight,
    text: p => p.tertiaryNormal
  },
  EXTRALIGHT: {
    background: p => p.tertiaryExtraLight,
    text: p => p.tertiaryNormal
  }
}
