/* @flow */
import {
  buttonModalActionMaker,
  subscribtionCardActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changeCardData = (
    planIdx: number,
    data: string,
    keyInStore: string
  ) => {
    dispatcher({
      type: 'CHANGE_CARD_DATA',
      value: { keyInStore, planIdx, data }
    })
  }
  const changePlanTitle = (title: string, planIndex: number) => {
    dispatcher(changeCardData(planIndex, title, 'title'))
  }

  const changePlanPrice = (price: string, planIdx: number) => {
    dispatcher(changeCardData(planIdx, price, 'price'))
  }

  const changePlanParagraph = (date: string, planIdx: number) => {
    dispatcher(changeCardData(planIdx, date, 'option'))
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changePriceAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_PRICE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeOptionAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_OPTION_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changePlanTitle,
    changePlanPrice,
    changeParagraph: _changeParagraph,
    changePlanParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    iconActions: iconActionMaker(dispatcher),
    btnActions: buttonModalActionMaker(dispatcher),
    cardActions: subscribtionCardActionMaker(dispatcher),
    changeBlockTitleAlignment,
    changePriceAlignment,
    changeOptionAlignment
  }
}
