/* @flow */
import styled from 'styled-components'
import THEME from './Header43.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgbA
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)
const CONTENT_OPACITY = 0.6

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: 0;
  position: relative;
  overflow: hidden;
  @media only screen and (max-width: 457px) {
    padding: 0;
  }
`
const WMCustomContainer = styled(WMContainer)`
  padding-bottom: 80px;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  @media only screen and (max-width: 768px) {
    padding-top: ${({ topOffset }) => `${topOffset}px`};
  }
  @media only screen and (max-width: 457px) {
    padding-top: ${({ topOffset }) => `${topOffset}px`};
    padding-bottom: 60px;
  }
`

const HeaderContent = styled.div`
  margin: 50px auto auto auto;
  text-align: center;
  width: 100%;
  max-width: 1300px;
  background: ${props =>
    hexToRgbA(getStyleForProp('backgroundColor')(props), CONTENT_OPACITY)};
  backdrop-filter: blur(15px);
  border-radius: ${getStyleForProp('cardRadius')};
  border: 1px solid ${getStyleForProp('border')};
  box-sizing: border-box;
  padding: 60px 45px 0 45px;
  @media only screen and (max-width: 650px) {
    padding: 40px 20px 0 20px;
  }
  .buttons-block {
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
  }
  .buttons-wrapper {
    justify-content: center;
    margin: 0 auto;
  }
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  ${({ isEditing }) =>
    !isEditing
      ? 'width: 100%; max-width: 900px; margin: auto auto 0 auto;'
      : 'margin-bottom: 0;'}

  color: ${getStyleForProp('heading')};
  font-weight: bold;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  max-width: 900px;
  margin: auto;
  line-height: 1.5em;
  padding-top: 20px;
  ${getTextStyleByAlignment};
`

const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 160px;
  min-width: 160px;
  outline: none;
  margin: 0 20px 80px 20px;
  @media only screen and (max-width: 457px) {
    margin-bottom: 60px;
  }
`
const ItemWrapper = styled.article`
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  .control-container {
    width: 100%;
    margin-bottom: 10px;
  }
`
const IconWrapper = styled.div`
  width: 45px;
  height: 45px;
  margin: 0 0 20px 0;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  .blockIcon {
    svg {
      fill: ${getStyleForProp('icon')};
    }
  }
`
const BlockTitle = styled.h5`
  width: 100%;
  color: ${getStyleForProp('blockTitle')};
  ${({ isEditing }) =>
    !isEditing ? 'margin-bottom: 10px;' : 'margin-bottom: 0;'}
  ${getTextStyleByAlignment};
  @media only screen and (min-width: 451px) {
    margin-top: ${({ isEditing, isIcon, isAllIconsMissing }) =>
      !isEditing && !isIcon && !isAllIconsMissing ? '65px' : '0'};
  }
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('blockParagraph')};
  line-height: 1.5em;
  ${getTextStyleByAlignment};
`
const BlocksWrap = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 810px;
  margin: auto;
  @media only screen and (max-width: 1045px) {
    max-width: ${({ isFourBlock }) => (isFourBlock ? '500px' : '810')};
  }
  @media only screen and (max-width: 450px) {
    flex-direction: column;
  }
`

const TopContent = styled.div`
  .control-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto 30px;

    h2 {
      padding-top: 0;
    }
  }
  > :first-child {
    padding-top: 0;
  }
`

const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  HeaderContent,
  Title,
  Paragraph,
  ControlContainer,
  ItemWrapper,
  IconWrapper,
  BlockTitle,
  BlockParagraph,
  BlocksWrap,
  TopContent,
  BottomContent
}
