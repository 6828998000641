// @flow
import React, { memo, useCallback } from 'react'

import MoreIcon from '@editor/common/assets/svgr-icons/More_icon.svg'
import FlipIcon from '@editor/common/assets/svgr-icons/Flip_icon.svg'
import ReplaceIcon from '@editor/common/assets/svgr-icons/replace_icon.svg'
import DuplicateIcon from '@editor/common/assets/svgr-icons/Duplicate_icon.svg'
import { translate } from '@editor/common/utils/translations'

import { MobileControl } from '../components/MobileControls/styled'
import { MobileControls } from '../components'
import { RemoveIcon } from '../styled'
import * as Styled from './styled'
import type { TMainControlsMobileProps } from './types'

const MainControlsMobile = ({
  isSlideUpOpen,
  isOrientationAllowed,
  isDuplicateAllowed,
  isRemoveBtnVisible,
  isComponentAnimated,
  isAnimationEnabled,
  onAnimationChange,
  onOrientationChange,
  onRemove,
  onReplace,
  onDuplicate,
  setSlideUpOpenState
}: TMainControlsMobileProps) => {
  const openSlideUp = useCallback(() => {
    setSlideUpOpenState(true)
  }, [])

  const closeSlideUp = useCallback(() => {
    setSlideUpOpenState(false)
  }, [])

  return (
    <MobileControls
      slideUpTitle={translate('component_actions_label')}
      IconComp={MoreIcon}
      isSlideUpOpen={isSlideUpOpen}
      onControlClick={openSlideUp}
      closeSlideUp={closeSlideUp}
    >
      <Styled.Controls>
        {isOrientationAllowed ? (
          <MobileControl onClick={onOrientationChange}>
            <p>{translate('flip_label')}</p>
            <FlipIcon />
          </MobileControl>
        ) : null}
        {isComponentAnimated ? (
          <MobileControl onClick={onAnimationChange}>
            <p>
              Animation
              <div className="divider" />
              <p className="info-text">Preview to see changes</p>
            </p>
            <p>{isAnimationEnabled ? 'Off' : 'On'}</p>
          </MobileControl>
        ) : null}
        <MobileControl onClick={onReplace}>
          <p>{translate('replace_label')}</p>
          <ReplaceIcon />
        </MobileControl>
        {isDuplicateAllowed ? (
          <MobileControl onClick={onDuplicate}>
            <p>{translate('duplicate_label')}</p>
            <DuplicateIcon />
          </MobileControl>
        ) : null}
        {isRemoveBtnVisible ? (
          <MobileControl onClick={onRemove}>
            <p>{translate('delete_label')}</p>
            <RemoveIcon />
          </MobileControl>
        ) : null}
      </Styled.Controls>
    </MobileControls>
  )
}

export default memo(MainControlsMobile)
