import styled from 'styled-components'

export const InitialContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`

export const Text = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  width: 216px;
  color: #252e48;
`

export const Image = styled.img`
  margin: 10px auto 15px;
`
