// @flow
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TPreviewButtonProps } from './types'
import { RippleLoading } from '@editor/common/components/Loaders'
import { primary } from '@website/common/styles/colors'
import { useHistoryWithSearch } from '@hooks'
import { useResponsive } from '@shared/hooks'
import { patchProjectData } from '@actions'
import { selectIsUserAuthorized } from '@selectors'
import { openLoginPopup } from '@actions/editor'
import PreviewIcon from '@editor/common/assets/svgr-icons/Preview_icon.svg'
import { EDITOR_BASE } from '@editor/conf/routes'

const PreviewButton = ({
  isUserAuthorized,
  openLoginPopup,
  patchProjectData
}: TPreviewButtonProps) => {
  const isMobile = useResponsive(600)
  const [isPendingPreview, setPendingStatus] = useState(false)
  const { url } = useRouteMatch(EDITOR_BASE)
  const history = useHistoryWithSearch()

  const onPreview = () => {
    if (!isUserAuthorized) {
      openLoginPopup()
      return
    }

    setPendingStatus(true)
    patchProjectData({
      onSuccess: () => {
        history.push(`${url}/preview-project`)
        setPendingStatus(false)
      },
      onError: () => {
        setPendingStatus(false)
      }
    })
  }

  const Comp = isMobile ? Styled.SmallPreviewButton : Styled.PreviewButton
  const componentContent =
    isMobile && isUserAuthorized ? <PreviewIcon /> : translate('preview_label')

  return (
    <Comp
      type="secondary"
      onClick={onPreview}
      isUserAuthorized={isUserAuthorized}
    >
      {isPendingPreview && <RippleLoading color={primary.Dark} isSmall />}
      <Styled.CompContent isShown={!isPendingPreview}>
        {componentContent}
      </Styled.CompContent>
    </Comp>
  )
}

const mapStateToProps = state => ({
  isUserAuthorized: selectIsUserAuthorized(state)
})

export default connect(mapStateToProps, {
  openLoginPopup,
  patchProjectData
})(PreviewButton)
