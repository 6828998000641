/* @flow */
import React, { useContext, useCallback, useState, memo } from 'react'
import S from './PayPal2.styled'
import ActionGetter from './Actions'
import { validation, sliderSettings } from './PayPal2.consts'
import AddButton from '@website/common/components/AddButton'
import { PayPalButtonForm } from '@website/common/components/PayPalButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { EditingContext, DispatchContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { translate } from '@editor/common/utils/translations'
import Slider from 'react-slick'
import '../Swipe/swipe-slider-styles.css'
import { getCurrencySign } from '@website/common/utils'
const PayPal2 = memo(props => {
  const {
    data: {
      name,
      nameAlignment,
      titleAlignment,
      paragraph,
      paypalBtnDefaultProps,
      paragraphAlignment,
      slides,
      additionalText,
      additionalUrl,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    },
    componentId
  } = props
  const { isEditing } = useContext(EditingContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const [activeSlideIdx, setActiveSlideIdx] = useState(0)
  const isSelling = paypalBtnDefaultProps.type === 'selling'
  const {
    currency,
    productPrice,
    productDiscountedPrice,
    productName,
    headline
  } = paypalBtnDefaultProps
  const editableContentCustomActions = Actions.paypalButtonModalActions(
    paypalBtnDefaultProps,
    componentId
  )
  const onPrevClick = useCallback(() => {
    const nextIdx =
      activeSlideIdx === 0 ? slides.length - 1 : activeSlideIdx - 1
    setActiveSlideIdx(nextIdx)
  }, [activeSlideIdx])
  const onNextClick = useCallback(() => {
    const nextIdx =
      activeSlideIdx === slides.length - 1 ? 0 : activeSlideIdx + 1
    setActiveSlideIdx(nextIdx)
  }, [activeSlideIdx])
  const beforeRemove = () => {
    if (activeSlideIdx !== 0) {
      setActiveSlideIdx(activeSlideIdx - 1)
    }
  }

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WMCustomContainer>
        <S.ContentConainer>
          <S.LeftContainer
            isEditing={isEditing}
            hasMargin={slides.length === validation.slides.max}
          >
            <S.LargeImageWrapper>
              <ControlsWithImageCropper
                actions={Actions.imageActions(
                  slides[activeSlideIdx].imageUrl,
                  activeSlideIdx,
                  slides.length,
                  beforeRemove
                )}
                style={{ width: '100%' }}
              >
                <Image
                  as={S.Img}
                  sizes={slides[activeSlideIdx].imgDimensions}
                  defaultImgSrc={slides[activeSlideIdx].imageUrl}
                  asProps={{
                    imgCropParams: slides[activeSlideIdx].imgCropParams
                  }}
                  alt="team image"
                />
              </ControlsWithImageCropper>
            </S.LargeImageWrapper>
            <S.WMSliderWrapper
              slidesCount={slides.length}
              isEditing={isEditing}
            >
              {slides.length > 0 && (
                <Slider
                  {...sliderSettings}
                  prevArrow={
                    slides.length > 2 ? (
                      <S.ArrowButton>
                        <S.PrevIcon onClick={onPrevClick} />
                      </S.ArrowButton>
                    ) : null
                  }
                  nextArrow={
                    slides.length > 2 ? (
                      <S.ArrowButton>
                        <S.NextIcon onClick={onNextClick} />
                      </S.ArrowButton>
                    ) : null
                  }
                  swipeToSlide={!isEditing}
                  swipe={!isEditing}
                >
                  {slides.map((slide, idx) => (
                    <S.ImageFigure
                      kay={idx}
                      isEditing={isEditing}
                      onClick={() => setActiveSlideIdx(idx)}
                    >
                      <S.InnerSliderItem
                        tabindex="2"
                        activeSlideIdx={idx === activeSlideIdx}
                        isEditing={isEditing}
                      >
                        <Image
                          withLazy={false}
                          as={S.Img}
                          alt="company img"
                          defaultImgSrc={slide.imageUrl}
                          sizes={slide.imgDimensions}
                        />
                      </S.InnerSliderItem>
                    </S.ImageFigure>
                  ))}
                </Slider>
              )}
              <S.SliderCount isEditing={isEditing}>
                Slide {activeSlideIdx + 1} of {slides.length}
              </S.SliderCount>
              <AddButton
                onAdd={Actions.addSlide}
                toShow={slides.length < validation.slides.max}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_image_label')}
              </AddButton>
            </S.WMSliderWrapper>
          </S.LeftContainer>
          <S.RightContainer>
            <S.RightContent>
              <EditableContent
                as={S.Name}
                text={name}
                isEditing={isEditing}
                maxCount={validation.nameMaxChar}
                className="WM_GLOBAL_paragraph14"
                onChange={Actions.changeName}
                alignment={nameAlignment}
                changeAlignment={Actions.changeNameAlignment}
              />
              <EditableContent
                as={S.Title}
                text={isSelling ? productName : headline}
                required
                isEditing={isEditing}
                className="WM_GLOBAL_heading20"
                alignment={titleAlignment}
                changeAlignment={Actions.changeTitleAlignment}
                isTypingDisabled
                isTextFormatingHidden
                customActions={editableContentCustomActions}
              />
              {isSelling ? (
                <S.PriceContainer>
                  <EditableContent
                    as={S.PriceCount}
                    text={`${getCurrencySign(currency)} ${productPrice}`}
                    className="WM_GLOBAL_heading42"
                    isTypingDisabled
                    isTextFormatingHidden
                    customActions={editableContentCustomActions}
                  />
                  {productDiscountedPrice ? (
                    <EditableContent
                      as={S.PrevPriceCount}
                      text={`${getCurrencySign(
                        currency
                      )} ${productDiscountedPrice}`}
                      className="WM_GLOBAL_heading32"
                      isTypingDisabled
                      isTextFormatingHidden
                      customActions={editableContentCustomActions}
                    />
                  ) : null}
                </S.PriceContainer>
              ) : null}
              <EditableContent
                isEditing={isEditing}
                text={paragraph}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                as={S.RightContainerParagraph}
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <S.PayButtonsContainer>
                <PayPalButtonForm
                  data={paypalBtnDefaultProps}
                  compId={componentId}
                />
                <AdditionalLink
                  additionalLinkText={additionalText}
                  additionalLinkUrl={additionalUrl}
                />
              </S.PayButtonsContainer>
            </S.RightContent>
          </S.RightContainer>
        </S.ContentConainer>
      </S.WMCustomContainer>
    </S.StyledContainer>
  )
})
export default PayPal2