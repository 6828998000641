/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_STEP_DATA':
        const { idx, data, keyInStore } = action.value
        draft.steps[idx][keyInStore] = data
        break

      case 'CHANGE_STEP_INFO_ALIGNMENT': {
        const { alignment, idx: _idx } = action.value
        draft.steps[_idx].stepInfoAlignment = alignment
        break
      }

      case 'CHANGE_STEP_TITLE_ALIGNMENT': {
        const { alignment, idx: _idx } = action.value
        draft.steps[_idx].stepTitleAlignment = alignment
        break
      }

      case 'CHANGE_STEP_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: _idx } = action.value
        draft.steps[_idx].stepParagraphAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_TITLE_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.posts[idx].titleAlignment = alignment
        break
      }

      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: idx } = action.value
        draft.posts[idx].paragraphAlignment = alignment
        break
      }

      case 'ADD_BLOCK':
        draft.steps.push(initialState.steps[0])
        break
      case 'REMOVE_BLOCK':
        draft.steps.splice(action.value.idx, 1)
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
