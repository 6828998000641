// @flow

import React, { memo, useContext } from 'react'
import FlyOut from './FlyOut'
import SlideUp from '@editor/common/lib/SlideUp'
import { MobileDetectContext } from '@contexts'
import type { TFlyOutMenuProps } from './types'

const FlyOutMenu = ({
  isOpen,
  onClose,
  children,
  mobileProps,
  desktopProps
}: TFlyOutMenuProps) => {
  const isMobile = useContext(MobileDetectContext)
  const commonProps = { isOpen, onClose }
  return isMobile ? (
    <SlideUp {...commonProps} {...mobileProps}>
      {children}
    </SlideUp>
  ) : (
    <FlyOut {...commonProps} {...desktopProps}>
      {children}
    </FlyOut>
  )
}

export default memo(FlyOutMenu)
