/* @flow */
import React, { useContext, memo } from 'react'
import S from './Team5.styled'
import { validation } from './Team5.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const Team5 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      paragraph2,
      paragraph2Alignment,
      slogan,
      sloganAlignment,
      name,
      nameAlignment,
      imageUrl,
      imgDimensions,
      imgCropParams
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <Show when={[title, paragraph, paragraph]}>
          <S.TextContent>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              className="WM_GLOBAL_heading54"
              maxCount={validation.headingMaxChar}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              as={S.Paragraph}
              text={paragraph}
              alignment={paragraphAlignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <EditableContent
              as={S.Paragraph}
              text={paragraph2}
              alignment={paragraph2Alignment}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18 paragraph2"
              onChange={Actions.changeParagraph2}
              changeAlignment={Actions.changeParagraph2Alignment}
            />
          </S.TextContent>
        </Show>
        <S.TextImageContent>
          <EditableContent
            as={S.Slogan}
            text={slogan}
            alignment={sloganAlignment}
            required={validation.slogan.required}
            maxCount={validation.sloganMaxChar}
            className="WM_GLOBAL_heading32"
            onChange={Actions.changeSlogan}
            changeAlignment={Actions.changeSloganAlignment}
          />
          <EditableContent
            as={S.Name}
            text={name}
            alignment={nameAlignment}
            required={validation.name.required}
            maxCount={validation.nameMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeName}
            changeAlignment={Actions.changeNameAlignment}
          />
          <S.ImageWrapper>
            <S.ControlContainer>
              <ControlsWithImageCropper
                actions={Actions.getImgActions(imageUrl)}
                style={{ width: '100%' }}
              >
                <Image
                  as={S.Img}
                  sizes={imgDimensions}
                  defaultImgSrc={imageUrl}
                  asProps={{ className: 'feature-image', imgCropParams }}
                  alt="Feature illustration"
                />
              </ControlsWithImageCropper>
            </S.ControlContainer>
          </S.ImageWrapper>
        </S.TextImageContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Team5
