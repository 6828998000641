export default {
  DARK: {
    title: p => p.accentWhite,
    price: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    border: p => p.accentWhite,
    link: p => p.accentWhite,
    blockTitle: p => p.tertiaryDark,
    blockText: p => p.tertiaryDark,
    iconText: p => p.primaryLight,
    background: p => `${p.tertiaryDark}`,
    icon: p => p.primaryLight,
    iconPrimary: p => p.primaryDark,
    backgroundColor: p => p.tertiaryDark,
    cardColor: p => p.tertiaryDark,
    primaryCard: p => p.accentWhite,
    primaryText: p => p.tertiaryDark
  },
  LIGHT: {
    title: p => p.primaryDark,
    price: p => p.tertiaryDark,
    link: p => p.tertiaryDark,
    border: p => p.tertiaryDark,
    blockTitle: p => p.tertiaryDark,
    blockText: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    iconText: p => p.tertiaryDark,
    background: p => `${p.tertiaryLight}`,
    icon: p => p.primaryDark,
    iconPrimary: p => p.primaryLight,
    backgroundColor: p => p.tertiaryLight,
    cardColor: p => '#FFFFFF',
    primaryCard: p => p.tertiaryDark,
    primaryText: p => p.accentWhite
  },
  EXTRALIGHT: {
    title: p => p.tertiaryDark,
    price: p => p.tertiaryDark,
    link: p => p.tertiaryDark,
    border: p => p.tertiaryDark,
    blockTitle: p => p.tertiaryDark,
    blockText: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    iconText: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    icon: p => p.primaryDark,
    iconPrimary: p => p.primaryLight,
    backgroundColor: p => p.tertiaryExtraLight,
    cardColor: p => '#FFFFFF',
    primaryCard: p => p.tertiaryDark,
    primaryText: p => p.accentWhite
  }
}
