/* @flow */
import styled from 'styled-components'

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${props => (props.isEditing ? '140px 0 0 0' : '0')};
  background-color: ${({ theme }) => theme.palette.tertiaryDark};
`

const Title = styled.h5`
  text-align: center;
  color: ${({ isEven, theme }) =>
    isEven ? theme.palette.accentWhite : theme.palette.tertiaryDark};
`

const Counter = styled.p`
  font-weight: bold;
  color: ${({ isEven, theme }) =>
    isEven ? theme.palette.primaryLight : theme.palette.primaryDark};
`

const TitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4vw;
  overflow: hidden;
  box-sizing: border-box;
  background: ${({ isEven, theme }) =>
    isEven ? theme.palette.tertiaryDark : theme.palette.accentWhite};
  color: ${({ isEven, theme }) =>
    isEven ? theme.palette.accentWhite : theme.palette.primaryDark};
  @media only screen and (max-width: 650px) {
    padding: 12vw;
  }
`

const Line = styled.div`
  width: 60px;
  height: 6px;
  min-height: 6px;
  margin: 20px 0 0 0;
  background: ${({ isEven, theme }) =>
    isEven ? theme.palette.primaryLight : theme.palette.primaryDark};
`

const Img = styled.img`
  height: 100%;
`
const LinkWrap = styled.div`
  margin-top: 4px;
  justify-content: center;
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: ${({ isEven, theme }) =>
      isEven ? theme.palette.accentWhite : theme.palette.primaryDark};
  }
`
export default {
  Img,
  Container,
  Title,
  TitleBlock,
  Counter,
  LinkWrap,
  Line
}
