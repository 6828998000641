/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Team3.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  display: ${getStyleForProp('orientation')};
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (min-width: 768px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  text-align: left;
  justify-content: center;
  &:only-child {
    text-align: center;
  }
  div:first-child {
    margin-top: 0;
  }
  .control-container {
    width: 100%;
  }
`

const AuthorInfo = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  > :first-child {
    margin-top: 0;
  }
`

const TeamDescription = styled.div`
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'RIGHT' ? '0 0 0 20px' : '0 20px 0 0'};
    `}
  width: 45%;
  text-align: left;
  &:only-child {
    margin-top: 0;
    margin-top: 0;
    text-align: center;
  }
  @media only screen and (max-width: 992px) {
    margin: 40px 0 0 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: inherit;
  }
`

const QuoteWrap = styled.div`
  ${({ theme }) =>
    css`
      margin: ${theme.orientation === 'RIGHT' ? '0 20px 0 0' : '0 0 0 20px'};
    `}
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 48%;
  padding: 60px 60px 80px 60px;
  box-sizing: border-box;
  text-align: inherit;
  background-color: #fff;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  border: ${getStyleForProp('border')};
  &:only-child {
    margin-top: 0;
  }
  > :first-child {
    margin-top: 0;
  }
  @media only screen and (max-width: 992px) {
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT' ? '0 0 40px 0' : '40px 0 0 0'};
      `}
    width: 100%;
    padding: 30px 30px 50px 30px;
  }
  i {
    font-size: 90px;
  }
  @media only screen and (max-width: 650px) {
    padding: 20px 14px 40px 14px;
    i {
      font-size: 70px;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Quote = styled.p`
  font-size: 32px;
  color: ${getStyleForProp('quote')};
  margin: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Name = styled.p`
  color: ${getStyleForProp('quoteIcon')};
  font-weight: 800;
  font-size: 16px;
  margin: 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Position = styled.p`
  color: ${getStyleForProp('quoteIcon')};
  font-weight: 300;
  font-size: 14px;
  margin: 12px 0 0 0;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('quoteIcon')
}))`
  margin-right: auto;
  margin-left: auto;
`

export default {
  StyledContainer,
  WmCustomContainer,
  AuthorInfo,
  TeamDescription,
  QuoteWrap,
  Title,
  Description,
  Quote,
  Name,
  Position,
  Icon
}
