// @flow

import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useLocation, useParams, useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import ConfirmDialog from '@editor/common/components/ConfirmDialog-old'
import { useTemplateApply } from '@hooks/templates'
import { selectTemplatesData, hasComponents } from '@selectors'
import { getTemplateById } from '@actions/editor'
import Preview from '../../Preview'

import { DIALOG_BUTTONS } from '@containers/choose-template/consts'
import { useHistoryWithSearch } from '@hooks'
import { EDITOR_BASE } from '@editor/conf/routes'

const TEMPLATE_APPLY_CONFIRM_QUESTION = translate(
  'new_template_on_created_site_label'
)
const TEMPLATE_APPLY_CONFIRM_MESSAGE = translate(
  'prev_changes_will_be_lost_label'
)

const mapStateToProps = (state, { match }) => {
  const { templateId } = match.params
  return {
    templateData: selectTemplatesData(state)[templateId],
    hasComponents: hasComponents(state)
  }
}

const mapDispatchToProps = {
  getTemplateById
}

const TemplatePreview = ({
  templateData = {},
  hasComponents,
  getTemplateById
}) => {
  const history = useHistoryWithSearch()
  const { search, state = {} } = useLocation()
  const { templateId, lang } = useParams()
  const { url } = useRouteMatch(EDITOR_BASE)
  const [isApplyClicked, setApplyClickedStatus] = useState(false)
  const [showConfirmDialog, setConfirmDialogStatus] = useState(false)

  useTemplateApply(templateId, isApplyClicked)

  useEffect(() => {
    getTemplateById(templateId)
  }, [])

  const onApplyClick = useCallback(() => {
    if (hasComponents) {
      setConfirmDialogStatus(true)
    } else {
      setApplyClickedStatus(true)
    }
  }, [hasComponents])

  const onConfirm = useCallback(() => {
    setApplyClickedStatus(true)
    setConfirmDialogStatus(false)
  }, [])

  const closeConfirmDialog = useCallback(() => {
    setConfirmDialogStatus(false)
  }, [])

  const goBack = useCallback(() => {
    state.prevPath && state.prevPath.includes('start-creation')
      ? history.push({
          pathname: `${url}/start-creation/choose-template`,
          state: { backTo: state.backTo }
        })
      : history.push({
          pathname: `${url}/edit/home/choose-template`,
          state: { backTo: state.backTo }
        })
  }, [history, url, state])

  const iframeSrc = `${window.location.origin}/website-maker/preview/${lang}/template/${templateId}${search}`
  const { isLoading, data } = templateData

  const projectData = data ? { isLoading, payload: data } : { isLoading: true }

  return (
    <>
      <ConfirmDialog
        isOpen={showConfirmDialog}
        title={TEMPLATE_APPLY_CONFIRM_QUESTION}
        content={TEMPLATE_APPLY_CONFIRM_MESSAGE}
        buttons={DIALOG_BUTTONS}
        onConfirm={onConfirm}
        onCancel={closeConfirmDialog}
      />
      <Preview
        projectId={templateId}
        iframeSrc={iframeSrc}
        projectData={projectData}
        siteHash={templateData?.hash}
        goBack={goBack}
        applyPreset={onApplyClick}
      />
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreview)
