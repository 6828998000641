/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Text17.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  position: relative;
  padding-top: ${({ isEditing }) => (isEditing && '120px')};
  @media only screen and (max-width: 992px) {
    ${({ isEditing, theme }) =>
      isEditing && theme.orientation === 'LEFT'
        ? css`
            padding-top: 120px;
          `
        : css``};
  }
  @media only screen and (max-width: 650px) {
    padding-top: ${({ isEditing }) => (isEditing && '100px')};
  }
  .LazyLoad {
    width: 100%;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  & > div:first-child {
    ${({ theme }) =>
      css`
        margin: ${theme.orientation === 'RIGHT' ? '0 0 0 2vw' : '0 2vw 0 0'};
      `}
  }
  @media only screen and (max-width: 992px) {
    align-items: center;
    & > div:first-child {
      ${({ theme }) =>
        css`
          margin: ${theme.orientation === 'RIGHT'
            ? '40px auto 0 auto'
            : '0 auto 40px auto'};
        `}
    }
  }
`

const HeaderContent = styled.div`
  text-align: left;
  width: calc(100% - 360px);
  max-width: 760px;
  @media only screen and (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
    p {
      padding-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('title')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  padding-top: 20px;
`

const RatioWrapper = styled.div`
  width: 135px;
  height: 135px;
  &.ratio-wrapper-1 {
    background-color: ${getStyleForProp('firstSquare')};
  }
  &.ratio-wrapper-2 {
    background-color: ${getStyleForProp('secondSquare')};
  }
  &.ratio-wrapper-3 {
    background-color: ${getStyleForProp('thirdSquare')};
  }
`

const ImageWrapper = styled.div`
  position: relative;
  height: 100%;
  .lazy-figure {
    padding-top: 100%;
  }
  .lazy-figure {
    border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '50%' : '')};
  }
`

const ControlContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
`

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const ImagesWrapper = styled.div`
  display: flex;
  position: relative;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      transform: translateX(-55px);
    `}
  @media only screen and (max-width: 992px) {
    transform: translateX(-27px);
  }
`

const CirclesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: -55px;
`

const Circle = styled.div`
  width: 35px;
  height: 35px;
  margin-top: 30px;
  border-radius: ${({ theme }) => (theme.look !== 'SHARP' ? '17.5px' : '')};
  &.circle-1 {
    background-color: ${getStyleForProp('firstCircle')};
  }
  &.circle-2 {
    background-color: ${getStyleForProp('secondCircle')};
  }
  &.circle-3 {
    background-color: ${({ theme }) => theme.palette.tertiaryNormal};
  }
  &.circle-4 {
    background-color: ${getStyleForProp('fourthCircle')};
  }
`

const FigureWrapper = styled.div`
  display: flex;
  flex-direction: column;
  > div {
    margin-bottom: 1px;
  }
`

const FirstFigure = styled.div`
  width: 135px;
  height: 135px;
  border-top-right-radius: 100%;
  background-color: ${getStyleForProp('firstFigure')};
`

const SecondFigure = styled.div`
  width: 135px;
  height: 135px;
  border-bottom-left-radius: 100%;
  background-color: ${({ theme }) => theme.palette.tertiaryNormal};
`

const ThirdFigure = styled.div`
  width: 135px;
  height: 135px;
  position: relative;
  z-index: 4;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  background-color: ${getStyleForProp('thirdFigure')};
`

const DotsWrapper = styled.div`
  width: 118px;
  height: 107px;
  position: absolute;
  z-index: 2;
  top: 200px;
  svg {
    fill: ${getStyleForProp('title')};
  }
`

const GridWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${({ theme }) =>
    css`
      justify-content: ${theme.orientation === 'RIGHT'
        ? 'flex-end'
        : 'flex-start'};
    `};
  width: 100%;
  max-width: 700px;
  font-size: 0;
  margin-left: 1px;
  @media only screen and (max-width: 992px) {
    justify-content: center;
  }
  > div {
    margin-bottom: 1px;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  Title,
  Paragraph,
  Img,
  GridWrapper,
  RatioWrapper,
  ImageWrapper,
  ControlContainer,
  FigureWrapper,
  FirstFigure,
  SecondFigure,
  ThirdFigure,
  ImagesWrapper,
  CirclesWrapper,
  Circle,
  DotsWrapper
}
