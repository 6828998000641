/* @flow */
export const validation = {
  title: { required: true },
  titleMaxChar: 60,
  paragraphMaxChar: 100,
  priceMaxChar: 30,
  blockMaxChar: 60,
  blocks: {
    min: 0,
    max: 5
  }
}