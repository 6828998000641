/* @flow */
import React, { useContext, useMemo, memo } from 'react'
import S from './RSVP3.styled'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import RSVPButton from '@website/common/components/RSVPButton'
import { DispatchContext, EditingContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'
import { generateEventCardDate } from '@website/common/utils/event'
import useEventSettingsOpener from '@hooks/useEventSettingsOpener'

const RSVP3Card = memo(
  ({ detailsData = {}, metaData = {}, onRSVPBtnClick, isLoading }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const {
      title,
      subTitle,
      paragraph,
      startDateInfo,
      endDateInfo,
      location,
      timezone,
      button
    } = detailsData

    const {
      cardImage,
      centerImage,
      dateAlignment,
      locationAlignment,
      subtitleAlignment,
      titleAlignment,
      paragraphAlignment
    } = metaData

    const eventDate = useMemo(
      () => generateEventCardDate(startDateInfo, endDateInfo, timezone),
      [startDateInfo, endDateInfo, timezone]
    )

    const { editSubtitle, editTitle, editParagraph, editDate, editLocation } =
      useEventSettingsOpener()

    return (
      <S.Postcard isCardImage={cardImage.imageUrl}>
        {cardImage.imageUrl ? (
          <S.ImageWrap className="card-img-wrapper">
            <ControlsWithModalOpener
              actions={Actions.cardImageActions(
                cardImage.imageUrl,
                cardImage.bgOverlayTransparency
              )}
              style={{ width: '100%', height: '100%' }}
            >
              <S.ImageFigureBG
                bgOverlayTransparency={cardImage.bgOverlayTransparency || 0}
              >
                <Image
                  as={S.Img}
                  alt="Card img"
                  defaultImgSrc={cardImage.imageUrl}
                  asProps={{ imgCropParams: cardImage.imgCropParams }}
                  sizes={cardImage.imgDimensions}
                />
              </S.ImageFigureBG>
            </ControlsWithModalOpener>
          </S.ImageWrap>
        ) : (
          <AddButton toShow onAdd={Actions.addCardImage}>
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_card_cover_image_label')}
          </AddButton>
        )}
        {centerImage.imageUrl ? (
          <S.ImageFigure>
            <ControlsWithImageCropper
              actions={Actions.centerImgActions(centerImage.imageUrl)}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                alt="Section img"
                defaultImgSrc={centerImage.imageUrl}
                asProps={{ imgCropParams: centerImage.imgCropParams }}
                sizes={centerImage.imgDimensions}
              />
            </ControlsWithImageCropper>
          </S.ImageFigure>
        ) : (
          <AddButton toShow onAdd={Actions.addImage}>
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_image_label')}
          </AddButton>
        )}
        <S.WmCustomContainer>
          <S.Content isEditing={isEditing} isParagraphExists={paragraph}>
            <EditableContent
              text={subTitle.text}
              isEditing={isEditing}
              as={S.SubTitle}
              alignment={subtitleAlignment}
              isTypingDisabled
              isTextFormatingHidden
              className="WM_GLOBAL_paragraph18"
              changeAlignment={Actions.changeSubtitleAlignment}
              customActions={Actions.eventSettingsActionMaker({
                onControlClick: editSubtitle
              })}
              onDoubleClick={editSubtitle}
            />
            <EditableContent
              text={title.text}
              alignment={titleAlignment}
              as={S.Title}
              isEditing={isEditing}
              hasAlignment={true}
              isTypingDisabled
              isTextFormatingHidden
              className="WM_GLOBAL_heading32"
              changeAlignment={Actions.changeTitleAlignment}
              customActions={Actions.eventSettingsActionMaker({
                onControlClick: editTitle
              })}
              onDoubleClick={editTitle}
            />
            <EditableContent
              text={paragraph.text}
              isEditing={isEditing}
              as={S.Paragraph}
              isTypingDisabled
              isTextFormatingHidden
              className="WM_GLOBAL_paragraph18"
              alignment={paragraphAlignment}
              changeAlignment={Actions.changeParagraphAlignment}
              customActions={Actions.eventSettingsActionMaker({
                onControlClick: editParagraph
              })}
              onDoubleClick={editParagraph}
            />
            <EditableContent
              text={eventDate}
              as={S.Date}
              isEditing={isEditing}
              isTypingDisabled
              isTextFormatingHidden
              className="WM_GLOBAL_paragraph18"
              alignment={dateAlignment}
              changeAlignment={Actions.changeDateAlignment}
              customActions={Actions.eventSettingsActionMaker({
                onControlClick: editDate
              })}
              onDoubleClick={editDate}
            />
            {location.isVisible ? (
              <EditableContent
                text={location.name}
                as={S.Location}
                isEditing={isEditing}
                isTypingDisabled
                isTextFormatingHidden
                className="WM_GLOBAL_paragraph18"
                target="_blank"
                href={location.url.replace('&output=embed', '')}
                alignment={locationAlignment}
                changeAlignment={Actions.changeLocationAlignment}
                customActions={Actions.eventSettingsActionMaker({
                  onControlClick: editLocation
                })}
                onDoubleClick={editLocation}
              />
            ) : null}
            <RSVPButton
              buttonText={button.text}
              onClick={onRSVPBtnClick}
              isLoading={isLoading}
            />
          </S.Content>
        </S.WmCustomContainer>
      </S.Postcard>
    )
  }
)

export default RSVP3Card
