import styled from 'styled-components'

import { accentRed } from '@website/common/styles/colors'

export const ErrorMessage = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  font-size: 12px;
  font-weight: 500;
  color: ${accentRed};
  padding-top: 5px;
  max-width: 400px;

  @media only screen and (max-width: 568px) {
    max-width: 200px;
  }

  svg {
    width: 14px;
    height: 14px;
    padding-right: 5px;

    & * {
      fill: ${accentRed};
    }
  }
`
