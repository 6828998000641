/* @flow */
import React, { useContext } from 'react'
import { DispatchContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ActionGetter from './Actions'
import S from './SubmitButton.styled'
import WhiteCheckIcon from '@website/common/assets/check_white.svg'

const SubmitButton = ({
  buttonText,
  submitFormData,
  showSubmitNotification
}) => {
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.ButtonContainer className="button-container">
      <ControlsWithModalOpener actions={Actions.getButtonActions(buttonText)}>
        <S.Button onClick={submitFormData} className="WM_GLOBAL_secondary-font">
          {buttonText}
        </S.Button>
      </ControlsWithModalOpener>
      {showSubmitNotification && (
        <S.ErrorSignWrapper>
          <svg height="40" width="40">
            <circle
              cx="20"
              cy="20"
              r="10"
              stroke="black"
              strokeWidth="3"
              fill="red"
            />
          </svg>
          <S.WhiteCheckIcon src={WhiteCheckIcon} alt="white check icon" />
        </S.ErrorSignWrapper>
      )}
    </S.ButtonContainer>
  )
}

export default SubmitButton
