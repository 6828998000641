/* @flow */
import http from '@shared/ApiFactory'
import { submitBuiltSiteRequest } from '@shared/ApiUtils'
import { EMAIL_API_PATH, EVENT_API_PATH } from './consts'

export const contactApi = (siteId, data) =>
  submitBuiltSiteRequest(
    `${EMAIL_API_PATH}/sites/form/contact/${siteId}/`,
    data
  )

export const subscribeAPI = (siteId, body) =>
  submitBuiltSiteRequest(`${EMAIL_API_PATH}/sites/form/email/${siteId}/`, body)

export const addGuestFromVisitorApi = (
  siteId: string,
  eventId: number,
  lang: string,
  data: Object
) => {
  const body = { ...data }
  if (lang) {
    body.lang = lang
  }
  return submitBuiltSiteRequest(
    `${EVENT_API_PATH}/sites/events/${siteId}/event/${eventId}/guest/`,
    body
  )
}

export const getEventStatusApi = (
  siteId: string,
  eventId: number,
  siteHash: string
) =>
  http.GET(
    `${EVENT_API_PATH}/sites/events/${siteId}/status/${eventId}/?siteHash=${siteHash}`
  )

export const submitContactFormAPI = (siteId, body) =>
  submitBuiltSiteRequest(
    `${EMAIL_API_PATH}/sites/form/appointment/${siteId}/`,
    body
  )
