/* @flow */
import {
  cropableImageModalActionMaker,
  buttonModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  addButton
} from '@website/components/CommonActions'
import {
  MODAL_TYPES,
  LINK_TYPES
} from '@website/common/components/Controlled/ControlModals/consts'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import EditIcon from '@editor/common/assets/svgr-icons/Edit_icon.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatch => {
  const changeStoreUrl = (storeType: string) => (url: string) => {
    dispatch({
      type: 'CHANGE_STORE_URL',
      value: { url, storeType }
    })
  }

  const storeIconActions = (storeType: string, link: string) => [
    {
      IconComp: EditIcon,
      tooltipText: translate('edit_label'),
      handler: changeStoreUrl(storeType),
      modal: {
        type: MODAL_TYPES.urlModal,
        props: { type: LINK_TYPES.link, link }
      }
    },
    {
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label'),
      handler: () => {
        dispatch({ type: 'REMOVE_STORE_BUTTON', value: storeType })
      }
    }
  ]

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    addStoreLink: (keyInStore: string) => () =>
      dispatch({ type: 'ADD_STORE_LINK', value: keyInStore }),
    addButton: () => dispatch(addButton()),
    storeIconActions,
    btnActions: buttonModalActionMaker(dispatch),
    mockupActions: ({ showRemove, mockupType, imageUrl }) =>
      cropableImageModalActionMaker(dispatch)({
        keyForSizes: 'CTA11',
        showRemove,
        additionalArgs: { mockupType },
        imageUrl
      })
  }
}
