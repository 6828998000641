/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Basic2.theme'
import WMContainer from '@website/common/components/WMContainer'
import {
  getBackgroundStyles
} from '@website/common/utils'
import Container from '@website/common/components/Container'


const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  @media only screen and (min-width: 769px) {
    ${({isEditing}) => isEditing && css`
      padding-top: 140px;
    `}
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  .buttons-block {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
    padding-top: 0;
  }
  .buttons-wrapper {
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }
`


export default {
  StyledContainer,
  WmCustomContainer
}
