export default {
  DARK: {
    paragraph: p => p.accentWhite,
    iconText: p => p.primaryLight,
    background: p => `${p.tertiaryDark}`,
    lightBackground: p => p.tertiaryExtraLight,
    icon: p => p.primaryLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    paragraph: p => p.tertiaryNormal,
    iconText: p => p.primaryDark,
    background: p => `${p.tertiaryLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    icon: p => p.primaryLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    paragraph: p => p.tertiaryNormal,
    iconText: p => p.primaryDark,
    background: p => `${p.tertiaryExtraLight}`,
    lightBackground: p => p.tertiaryExtraLight,
    icon: p => p.primaryLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
