//@flow
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { TooltipWrapper } from '@website/common/components/TooltipWrapper'
import { SearchInput } from '@editor/common/components/SemrushCommon'

import PinIcon from '@editor/common/assets/svgr-icons/semrush-pin_icon.svg'
import UnpinIcon from '@editor/common/assets/svgr-icons/semrush-unpin_icon.svg'
import { setSemrushSidebarPinnedState } from '@actions/editor'
import { selectSemrushSidebarPinnedState } from '@selectors'
import { KEYWORD_CHAR_LIMIT } from '../../consts'

import type { TProps } from './types'
import * as Styled from './styled.js'
import { useResponsive } from '@shared/hooks'

const TopContent = ({
  isSemrushSidebarPined,
  searchValue,
  searchedPhrase,
  onSearchChange,
  handleEnterPress,
  onRemoveIconClick,
  setSemrushSidebarPinnedState
}: TProps) => {
  const isSmallSize = useResponsive(1024)
  const pinUnpinSidebarToEditor = useCallback(() => {
    setSemrushSidebarPinnedState(!isSemrushSidebarPined)
  }, [isSemrushSidebarPined, setSemrushSidebarPinnedState])

  return (
    <Styled.TopContentContainer>
      <Styled.KeywordWrapper>
        <Styled.KeywordLabel>{translate('keyword_label')}:</Styled.KeywordLabel>
        <Styled.Keyword>{searchedPhrase}</Styled.Keyword>
      </Styled.KeywordWrapper>
      <Styled.SearchWrapper>
        <TooltipWrapper
          className="tooltip-wrapper"
          position="top"
          text={
            isSmallSize ? (
              ''
            ) : (
              <Styled.TooltipContent>
                {isSemrushSidebarPined
                  ? translate('unpin_from_editor')
                  : translate('pin_to_editor')}
              </Styled.TooltipContent>
            )
          }
        >
          <Styled.PinIconWrapper onClick={pinUnpinSidebarToEditor}>
            {isSemrushSidebarPined ? <UnpinIcon /> : <PinIcon />}
          </Styled.PinIconWrapper>
        </TooltipWrapper>
        <SearchInput
          hideCount
          count={KEYWORD_CHAR_LIMIT}
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          handleEnterPress={handleEnterPress}
          onRemoveIconClick={onRemoveIconClick}
        />
      </Styled.SearchWrapper>
    </Styled.TopContentContainer>
  )
}

const mapStateToProps = state => ({
  isSemrushSidebarPined: selectSemrushSidebarPinnedState(state)
})

export default connect(mapStateToProps, {
  setSemrushSidebarPinnedState
})(TopContent)
