import styled, { css } from 'styled-components'

import { globalColors } from '@renderforest/rf-ui-library/consts/colors'
import { styleByLang } from '@editor/common/utils/style-by-lang'
import { mediaMaxWidth } from '@editor/common/utils/styled'

const stylesObj = {
  de: {
    logoMarginLeft: '10px',
    logoWidth: '40px'
  },
  ru: {
    logoMarginLeft: '10px',
    logoWidth: '45px'
  },
  default: {
    logoMarginLeft: '20px',
    logoWidth: '66px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

const sideBlockStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Divider = styled.div`
  width: 0;
  height: 30px;
  border-left: 1px solid ${globalColors.secondaryExtraLight};
`

export const HeaderWithLogo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  position: relative;
  z-index: 30;
  box-shadow: 0px 6px 10px #387dff33;
  border-bottom: 1px solid #dee3f0;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  flex-direction: row;
  height: 100%;
  padding: 0 30px 0 20px;
  box-sizing: border-box;
`

export const LeftSection = styled.div`
  ${sideBlockStyles};
  justify-content: flex-start;
  & ${Divider} {
    margin: 0 20px;
  }
`

export const RightSection = styled.div`
  ${sideBlockStyles};
  justify-content: flex-end;
  & ${Divider} {
    margin: 0 15px 0 5px;
  }
`

export const LogoContainer = styled.a`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ isExpanded }) => (isExpanded ? '180px' : '66px')};
  ${mediaMaxWidth(580)`
    width: ${getStylesByLang('logoWidth')};
  `};
  height: 100%;
  border-right: 1px solid #dee3f0;
  transition: width 0.2s ease-out;
  overflow: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  cursor: pointer;
  & > img {
    margin-left: 20px;
    ${mediaMaxWidth(580)`
      margin-left: ${getStylesByLang('logoMarginLeft')};
    `};
    height: 32px;
    width: auto;
  }
`

export const MobileHeader = styled.div`
  width: 100%;
  height: 120px;
  border-bottom: 1px solid ${globalColors.secondaryExtraLight};
`

export const MobileTopSection = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 0;
  display: flex;
  box-sizing: border-box;
  border-bottom: 1px solid ${globalColors.secondaryExtraLight};
  justify-content: space-between;
`

export const Menu = styled.div`
  width: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border-left: 1px solid ${globalColors.secondaryExtraLight};
`

export const MobileBottomSection = styled.div`
  width: 100%;
  height: 60px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  & ${Divider} {
    margin: 0 15px;
    @media only screen and (max-width: 359px) {
      margin: 0 10px;
    }
  }
`
