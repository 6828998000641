/* @flow */
import {
  buttonModalActionMaker,
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'
import { validation } from '../Portfolio14.consts'

export default dispatcher => {
  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const changeItemParagraph = (newItemParagraph: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_ITEM_PARAGRAPH',
      value: { newItemParagraph, idx }
    })

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) =>
    dispatcher({
      type: 'CHANGE_ITEM_TITLE',
      value: { newBlockTitle, idx }
    })

  const imageActions = (idx: number, imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'feature15',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    })
  ]

  const addBottomBlock = () =>
    dispatcher({
      type: 'ADD_BOTTOM_BLOCK'
    })

  const removeBottomBlock = () => ({
    type: 'REMOVE_BOTTOM_BLOCK'
  })

  return {
    addBlock: () => dispatcher(addBlock()),
    changeTitle: _changeTitle,
    imageActions,
    featureActions: removeBlockActionMaker(dispatcher),
    changeTitleAlignment: _changeTitleAlignment,
    changeBlockParagraphAlignment,
    changeBlockTitleAlignment,
    addBottomBlock,
    changeBlockTitle,
    changeItemParagraph,
    btnActions: buttonModalActionMaker(dispatcher),
    joinBlockAction: [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeBottomBlock())
      }
    ]
  }
}
