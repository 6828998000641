/* @flow */
import {
  getSiteId,
  getProjectData,
  selectProjectFileUrls,
  selectActivePagePath,
  getSiteData as selectSiteData,
  selectMetaSettings,
  selectSimpleCookies,
  selectWebsiteActiveLanguageCode,
  selectWebsiteLanguagesData,
  selectGDPRCookies,
  selectHasWebsiteEvent,
  selectIsUserAuthorized,
  getUser,
  selectEvent,
  selectComponentsData,
  getCurrentPageComponents,
  getPalette,
  selectTemplateInfo
} from '@selectors'
import {
  publishProjectAPI,
  patchProjectDataAPI,
  resetProjectChangesAPI,
  assignPremiumPlanAPI,
  patchMetaSettingsAPI,
  patchCookieSettingsAPI,
  getProjectDataAPI,
  patchWebsiteLanguagesDataAPI,
  createNewProjectAPI
} from '@api'

import {
  setSiteId,
  getSiteData,
  setSubdomain,
  saveSubdomain,
  setUserPaidData,
  triggerApiError,
  changeEntirePalette,
  startProjectPublish,
  projectPublishFailed,
  setSettingsLoadingStatus,
  setSettingsSuccessMessage,
  setHasChangesAfterPublish,
  setResetChangesPopupState,
  setNoLongerPremiumPopupState,
  setWebsiteLanguageChangingStatus
} from '../editor'
import { fetchComponentsData, setComponentsData } from '../components'
import { createEvent, setEventsData } from '../events'
import { setLanguageMenuLayout } from '../website-languages'
import { setMetaSettings } from '../meta'
import {
  NO_HEADER_COMPONENT_ID,
  RSVP_COMP_CATEGORY,
  SUCCESS_SAVED_MESSAGE
} from '@editor/conf/consts'
import { RESET_CHANGES_POPUP_INITIAL_STATE } from '../../consts'
import { getLocalizedURL } from '@editor/common/utils/translations'
import { hasWebsitePremiumFeatures } from '@editor/common/utils/premium-features-checks'
import { filterLanguagesDataBeforePatch } from '@editor/common/utils/multilingual'
import { FREE_SITE_META, EMPTY_PAGE_META, FREE_COOKIE_DATA } from './const'
import { sendBrazeProjectCreationEvent } from '../utility'
import { pushDataLayer } from '@editor/common/utils/analytics'

const setFreePages = pages => {
  return pages.reduce((acc, page) => {
    const newPage = { ...page, meta: EMPTY_PAGE_META }
    acc.push(newPage)

    return acc
  }, [])
}

const PROMISE_RESOLVE = Promise.resolve({})

export const fetchWebsiteDataByLang = languageCode => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const params = {
    lang: languageCode === 'lang' ? '' : languageCode
  }

  dispatch(setWebsiteLanguageChangingStatus(true))

  getProjectDataAPI(siteId, params)
    .then(({ data: { data, visualParams, events, languageMenuLayout, componentMeta } }) => {
      dispatch(setComponentsData(componentMeta))
      dispatch({ type: 'SET_PROJECT_DATA', value: { ...data, visualParams } })
      dispatch({ type: 'FREEZE_PROJECT_DATA' })
      dispatch(setEventsData(events))
      dispatch(setLanguageMenuLayout(languageMenuLayout))
      dispatch(setWebsiteLanguageChangingStatus(false))
    })
    .catch(e => {
      dispatch(triggerApiError(e))
    })
}

export const removeProjectPremiumData = () => (dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)
  const siteMeta = selectMetaSettings(state)
  const cookieSettings = selectSimpleCookies(state) || FREE_COOKIE_DATA
  const gdprCookies = selectGDPRCookies(state)
  const { pages, visualParams, ...projectData } = getProjectData(state)
  const websiteLanguagesData = selectWebsiteLanguagesData(state)
  const isWebsiteMultilingual = websiteLanguagesData.length > 1
  const lang = isWebsiteMultilingual
    ? selectWebsiteActiveLanguageCode(state)
    : ''

  const _patchProjectData = patchProjectDataAPI(
    siteId,
    {
      data: { ...projectData, pages: setFreePages(pages) }
    },
    { lang }
  )

  const promises = [_patchProjectData]

  const hasMetaSettings = Object.values(siteMeta).some(value => value)

  if (hasMetaSettings) {
    const _patchMetaSettings = patchMetaSettingsAPI(siteId, {
      ...siteMeta,
      ...FREE_SITE_META
    })
    promises.push(_patchMetaSettings)
  } else {
    promises.push(PROMISE_RESOLVE)
  }

  if (gdprCookies) {
    const _cookieSettings = patchCookieSettingsAPI(siteId, {
      cookieSettings: { simple: cookieSettings }
    })
    promises.push(_cookieSettings)
  } else {
    promises.push(PROMISE_RESOLVE)
  }

  if (isWebsiteMultilingual) {
    const primaryLanguage = websiteLanguagesData.find(lang => lang.isPrimary)
    const secondaryLanguages = websiteLanguagesData
      .filter(lang => !lang.isPrimary)
      .map(lang => ({
        ...lang,
        removed: true
      }))

    const _patchWebsiteLanguagesData = patchWebsiteLanguagesDataAPI(
      siteId,
      filterLanguagesDataBeforePatch([primaryLanguage, ...secondaryLanguages])
    )

    promises.push(_patchWebsiteLanguagesData)
  } else {
    promises.push(PROMISE_RESOLVE)
  }

  return Promise.all(promises)
    .then(([projectResp, metaResp, cookieResp, websiteLanguagesDataResp]) => {
      const { data } = projectResp?.data
      dispatch({
        type: 'SET_PROJECT_DATA',
        value: { ...data, visualParams }
      })
      dispatch({ type: 'FREEZE_PROJECT_DATA' })

      if (cookieResp?.data) {
        dispatch({
          type: 'SET_COOKIE_SETTINGS',
          value: cookieResp?.data
        })
      }
      if (metaResp?.data) {
        dispatch(setMetaSettings(metaResp?.data))
      }
      if (websiteLanguagesDataResp?.data) {
        dispatch({
          type: 'SET_WEBSITE_LANGUAGES_DATA',
          value: websiteLanguagesDataResp.data
        })
      }
    })
    .catch(e => {
      dispatch(triggerApiError(e))
      return Promise.reject(e)
    })
    .finally(() => {
      dispatch(setNoLongerPremiumPopupState(false))
    })
}

export const assignPremiumPlanToWebsite = () => (dispatch, getState) => {
  const siteId = getSiteId(getState())

  return assignPremiumPlanAPI(siteId)
    .then(res => {
      dispatch(getSiteData())
    })
    .catch(e => {
      console.error(e)
    })
}

export const createNewProject = (siteName?: string) => (dispatch, getState) => {
  const state = getState()
  const projectData = getProjectData(state)

  const currentUser = getUser(state)
  const { visualParams, ...data } = projectData

  return createNewProjectAPI({
    siteName,
    data,
    visualParams
  })
    .then(
      ({
         data: {
           id: newSiteId,
           subdomain,
           data: projectData,
           visualParams,
           createdAt
         }
       }) => {
        // TODO: Ask backend to send all necessary info about site, like isPaid, tariffPlan e.t.c
        if (currentUser.siteLimit) {
          dispatch(setUserPaidData({ paid: true }))
        }

        dispatch(setSiteId(newSiteId))
        dispatch(getSiteData())
        dispatch(setSubdomain(subdomain))
        dispatch({
          type: 'SET_PROJECT_DATA',
          value: { ...projectData, visualParams },
          needToSendRevision: false
        })

        return { siteId: newSiteId, createdAt }
      }
    )
    .then(({ siteId, createdAt }) => {
      const templateInfo = selectTemplateInfo(state)

      sendBrazeProjectCreationEvent('Website_project_create', templateInfo, {
        siteId,
        createdAt
      })

      pushDataLayer({
        event: 'website_project_created',
        item_id: siteId
      })

      return { siteId }
    })
    .catch(err => dispatch(triggerApiError(err)))
}

export const addComponent =
  (pagePath, idx, meta, cb) => async (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const isUserAuthorized = selectIsUserAuthorized(state)
    const defaultPalette = getPalette(state)
    const needToCreateProject = isUserAuthorized && siteId === 'new'
    const hasWebsiteEvent = selectHasWebsiteEvent(state)

    await dispatch(fetchComponentsData([meta.id]))

    const componentsData = selectComponentsData(getState())
    const componentData = componentsData[meta.id]

    const compFullData = {
      ...meta,
      ...componentData
    }
    const isRsvpComponent = compFullData.category === RSVP_COMP_CATEGORY
    const initialEventData = compFullData.eventData

    if (!hasWebsiteEvent && isRsvpComponent) {
      dispatch(setEventsData([initialEventData]))
    }

    if (hasWebsiteEvent && isRsvpComponent) {
      const { id } = selectEvent(state)
      compFullData.eventId = id
    }

    const { customPalette, ...compDataWithoutPalette } = compFullData

    const palette = customPalette || defaultPalette

    const needToCreateEvent =
      isUserAuthorized && !hasWebsiteEvent && isRsvpComponent

    dispatch({
      type: 'ADD_COMPONENT',
      value: {
        activePagePath: pagePath,
        atIndex: idx,
        componentMeta: compDataWithoutPalette
      },
      undoable: !needToCreateEvent,
      needToSendRevision: !needToCreateProject
    })
    cb && cb()

    siteId === 'new' && dispatch(changeEntirePalette(palette, false))
    needToCreateProject && (await dispatch(createNewProject()))

    needToCreateEvent && dispatch(createEvent({ event: initialEventData }))
  }

export const addMultipleComponents =
  (pagePath, idx, componentsMeta, cb) => async (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const defaultPalette = getPalette(state)
    const isUserAuthorized = selectIsUserAuthorized(state)
    const needToCreateProject = isUserAuthorized && siteId === 'new'
    const hasWebsiteEvent = selectHasWebsiteEvent(state)

    const compIDs = componentsMeta.map(comp => comp.id)
    await dispatch(fetchComponentsData(compIDs))

    const componentsData = selectComponentsData(getState())
    const componentsFullData = componentsMeta.map(compMeta => ({
      ...compMeta,
      ...componentsData[compMeta.id]
    }))

    const rsvpComponentMeta = componentsFullData.find(
      comp => comp.category === RSVP_COMP_CATEGORY
    )
    const initialEventData = rsvpComponentMeta?.eventData
    const isRsvpComponentSelected = !!rsvpComponentMeta

    if (!hasWebsiteEvent && isRsvpComponentSelected) {
      dispatch(setEventsData([initialEventData]))
    }

    let _componentsFullData = componentsFullData
    if (hasWebsiteEvent && isRsvpComponentSelected) {
      const { id } = selectEvent(state)
      _componentsFullData = componentsFullData.map(comp =>
        comp.category === RSVP_COMP_CATEGORY ? { ...comp, eventId: id } : comp
      )
    }

    const { customPalette } = _componentsFullData.filter(
      comp => comp.id !== NO_HEADER_COMPONENT_ID
    )[0]

    const compsDataWithoutCustomPalette = _componentsFullData.map(
      ({ customPalette, ...rest }) => rest
    )

    const palette = customPalette || defaultPalette

    const needToCreateEvent =
      isUserAuthorized && !hasWebsiteEvent && isRsvpComponentSelected

    dispatch({
      type: 'ADD_MULTIPLE_COMPONENTS',
      value: {
        activePagePath: pagePath,
        atIndex: idx,
        componentsMeta: compsDataWithoutCustomPalette
      },
      undoable: !needToCreateEvent,
      needToSendRevision: !needToCreateProject
    })
    cb && cb()

    siteId === 'new' && dispatch(changeEntirePalette(palette, false))
    needToCreateProject && (await dispatch(createNewProject()))

    needToCreateEvent && dispatch(createEvent({ event: initialEventData }))
  }

export const replaceComponent =
  (pagePath, idx, meta, cb) => async (dispatch, getState) => {
    const hasWebsiteEvent = selectHasWebsiteEvent(getState())
    const isUserAuthorized = selectIsUserAuthorized(getState())

    await dispatch(fetchComponentsData([meta.id]))

    const componentsData = selectComponentsData(getState())
    const pageComponents = getCurrentPageComponents(getState())
    const componentData = componentsData[meta.id]
    const { id: oldCompId } = pageComponents[idx]
    const { defaultProps: oldCompDefaultProps } = componentsData[oldCompId]

    const compFullData = {
      ...meta,
      ...componentData
    }

    const isRsvpComponent = compFullData.category === RSVP_COMP_CATEGORY
    const initialEventData = compFullData.eventData

    if (!hasWebsiteEvent && isRsvpComponent) {
      dispatch(setEventsData([initialEventData]))
    }

    if (hasWebsiteEvent && isRsvpComponent) {
      const { id } = selectEvent(getState())
      compFullData.eventId = id
    }

    const needToCreateEvent =
      isUserAuthorized && !hasWebsiteEvent && isRsvpComponent
    dispatch({
      type: 'REPLACE_COMPONENT',
      value: {
        activePagePath: pagePath,
        atIndex: idx,
        componentMeta: compFullData,
        oldCompDefaultProps
      },
      undoable: !needToCreateEvent
    })
    cb && cb()

    needToCreateEvent && dispatch(createEvent({ event: initialEventData }))
  }

export const duplicateComponent =
  (activePagePath, duplicatingCompIdx) => dispatch => {
    dispatch({
      type: 'DUPLICATE_COMPONENT',
      value: { activePagePath, duplicatingCompIdx },
      undoable: true
    })
  }

export const patchProjectData =
  ({ onSuccess, onError } = {}) =>
  async (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)
    const siteData = selectSiteData(state)
    const { visualParams, ...projectData } = getProjectData(state)
    const websiteLanguagesData = selectWebsiteLanguagesData(state)
    const isWebsiteMultilingual = websiteLanguagesData.length > 1
    const lang = isWebsiteMultilingual
      ? selectWebsiteActiveLanguageCode(state)
      : ''

    try {
      if (
        !siteData.paid &&
        hasWebsitePremiumFeatures(
          { data: projectData },
          siteData,
          websiteLanguagesData
        )
      ) {
        await assignPremiumPlanAPI(siteId)
        dispatch(getSiteData())
      }

      const data = await patchProjectDataAPI(
        siteId,
        { data: projectData },
        { lang }
      )

      onSuccess && onSuccess(data)
      dispatch(setHasChangesAfterPublish(data.hasChangesAfterPublish))
    } catch (e) {
      onError && onError(e)
      dispatch(triggerApiError(e))
    }
  }

export const storeInHistory = () => dispatch => {
  dispatch({
    type: 'STORE_IN_HISTORY'
  })
}

export const freezeProjectData = () => dispatch => {
  dispatch({
    type: 'FREEZE_PROJECT_DATA'
  })
}

export const removeComponent = (idx: number, pagePath: string) => dispatch => {
  dispatch({
    type: 'REMOVE_COMPONENT',
    value: {
      activePagePath: pagePath,
      componentIdx: idx
    },
    undoable: true
  })
}

const setAddingCompIdx = (i: number) => {
  return {
    type: 'SET_ADDING_COMP_IDX',
    value: i
  }
}

const hideComponentPopup = () => ({
  type: 'HIDE_ADD_COMPONENT'
})

export const toTop = (index: number) => (dispatch, getState) => {
  const activePagePath = selectActivePagePath(getState())
  const domElem = document.getElementById(`comp-${index - 1}`)
  if (domElem) {
    domElem.scrollIntoView({ behavior: 'smooth' })
  }
  dispatch({
    type: 'COMPONENT_TO_TOP',
    value: {
      index,
      activePagePath
    },
    undoable: true
  })
}

export const toBottom = (index: number) => (dispatch, getState) => {
  const activePagePath = selectActivePagePath(getState())
  const domElem = document.getElementById(`comp-${index + 1}`)
  if (domElem) {
    domElem.scrollIntoView({ behavior: 'smooth' })
  }
  dispatch({
    type: 'COMPONENT_TO_BOTTOM',
    value: { index, activePagePath },
    undoable: true
  })
}

const changeColorMode = (colorMode: string) => ({
  type: 'CHANGE_COLOR_MODE',
  value: colorMode
})

export const createNewPage =
  (path: string, cb: Function) => async (dispatch, getState) => {
    await dispatch(fetchComponentsData(['NoHeader']))

    const { NoHeader: noHeaderData } = selectComponentsData(getState())

    dispatch({
      type: 'CREATE_NEW_PAGE',
      value: { path, components: [noHeaderData] },
      undoable: true
    })
    cb && cb()
  }

export const changeProjectPage = (pagePath: string) => ({
  type: 'CHANGE_PROJECT_PAGE',
  value: pagePath
})

export const changePageNames = (pagePaths: [string]) => ({
  type: 'CHANGE_PROJECT_PAGE_NAMES',
  value: pagePaths
})

export const removePage = (pagePath: string) => ({
  type: 'REMOVE_PROJECT_PAGE',
  value: pagePath,
  undoable: true
})

export const duplicatePage = (
  duplicatingPagePath: string,
  newPagePath: string
) => ({
  type: 'DUPLICATE_PAGE',
  value: { duplicatingPagePath, newPagePath },
  undoable: true
})

type TPageSettingsObj = {
  title: string,
  keywords: string,
  useForAll: boolean,
  description: string
}

export const changePageMeta = (
  newPageSettings: TPageSettingsObj,
  pageIdx: number
) => ({
  type: 'CHANGE_PAGE_META',
  value: { newPageSettings, pageIdx }
})

export const changePageSettings = value => dispatch => {
  dispatch({
    type: 'CHANGE_PAGE_SETTINGS',
    value
  })

  dispatch(setSettingsLoadingStatus(true))
  dispatch(
    patchProjectData({
      onSuccess: () => {
        dispatch(setSettingsLoadingStatus(false))
        dispatch(setSettingsSuccessMessage(SUCCESS_SAVED_MESSAGE))
        setTimeout(() => dispatch(setSettingsSuccessMessage('')), 5000)
      }
    })
  )
}

export const undo = () => (dispatch, getState) => {
  const projectFileUrls = selectProjectFileUrls(getState())
  dispatch({ type: 'UNDO', value: { projectFileUrls } })
}
export const redo = () => (dispatch, getState) => {
  const projectFileUrls = selectProjectFileUrls(getState())
  dispatch({ type: 'REDO', value: { projectFileUrls } })
}

const changeOrientation = () => ({ type: 'CHANGE_ORIENTATION' })

export const publishProject =
  (needToGoOnline?: boolean) => (dispatch, getState) => {
    dispatch(startProjectPublish())
    const state = getState()
    const siteId = getSiteId(state)

    dispatch(
      patchProjectData({
        onSuccess: () => {
          publishProjectAPI(siteId, needToGoOnline)
            .then(() => {
              window.location.href = `${getLocalizedURL(
                '/profile/my-websites'
              )}`
            })
            .catch(e => {
              dispatch(projectPublishFailed())
              dispatch(triggerApiError(e))
            })
        }
      })
    )
  }

export const saveSubdomainAndPublish = (subdomain: string) => dispatch => {
  dispatch(saveSubdomain(subdomain)).then(() => {
    dispatch(publishProject())
  })
}

const changeBgOverlayTransparency = (value: boolean) => ({
  type: 'CHANGE_BG_OVERLAY_TRANSPARENCY',
  value
})

const setComponentAnimatedState = () => ({
  type: 'SET_COMPONENT_ANIMATED_STATE'
})

export const dropHistory = () => ({
  type: 'DROP_HISTORY'
})

export const resetProjectChanges =
  (sections: [string]) => (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)

    dispatch(setResetChangesPopupState({ isLoading: true }))

    resetProjectChangesAPI(siteId, sections)
      .then(({ data }) => {
        const {
          data: projectData,
          visualParams,
          widgets,
          meta = {},
          cookieSettings
        } = data
        dispatch(
          setResetChangesPopupState({
            isLoading: false,
            isSuccess: true,
            isFailed: false,
            errorMessage: ''
          })
        )
        dispatch(dropHistory())
        dispatch({
          type: 'SET_PROJECT_DATA',
          value: { ...projectData, visualParams }
        })
        dispatch({
          type: 'SET_COOKIE_SETTINGS',
          value: cookieSettings
        })
        widgets &&
          dispatch({
            type: 'SET_WIDGETS_DATA',
            value: widgets
          })
        dispatch(setMetaSettings(meta))
        dispatch(setHasChangesAfterPublish(data.hasChangesAfterPublish))
        setTimeout(() => {
          dispatch(setResetChangesPopupState(RESET_CHANGES_POPUP_INITIAL_STATE))
        }, 3000)
      })
      .catch(e => {
        dispatch(
          setResetChangesPopupState({
            isLoading: false,
            isSuccess: false,
            isFailed: true,
            errorMessage: e.message
          })
        )
        dispatch(triggerApiError(e))
      })
  }

export const setEventIdToRsvpComponents = (
  eventId: number,
  needToSendRevision: boolean = true
) => ({
  type: 'SET_EVENT_ID_TO_RSVP_COMPONENTS',
  value: eventId,
  needToSendRevision
})

export default {
  changeColorMode,
  changeOrientation,
  hideComponentPopup,
  changeBgOverlayTransparency,
  setAddingCompIdx,
  setComponentAnimatedState
}
