/* @flow */

import React, { useContext, memo } from 'react'
import S from './Text3.styled'
import { validation } from './Text3.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'

const Text3 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      imageUrl,
      buttons,
      imgDimensions,
      imgCropParams,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer
        className="text-image-container"
        isEditing={isEditing}
      >
        <Show when={[title, paragraph, ...buttons]}>
          <S.TextWrap>
            <Show when={[title]}>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.maxTitleChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Description}
                maxCount={validation.maxParagraphChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
            <Buttons data={buttons} />
          </S.TextWrap>
        </Show>
        <S.Figure isEditing={isEditing}>
          <ControlsWithImageCropper
            actions={Actions.imageActions(imageUrl)}
            style={{ width: '100%' }}
          >
            <Image
              as={S.TextImg}
              defaultImgSrc={imageUrl}
              sizes={imgDimensions}
              alt="Section image"
              asProps={{ imgCropParams }}
            />
          </ControlsWithImageCropper>
        </S.Figure>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text3
