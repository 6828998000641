/* @flow */

export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 80,
  blockParagraphMaxChar: 1000,
  steps: {
    min: 1,
    max: 10
  }
}
