import { useState, useContext } from 'react'

import { getEventStatusApi } from '@website/common/api'
import { SiteIdContext, SiteHashContext } from '@contexts'
import { getFormIdByEventId } from '@website/common/utils/event'

const useEventRegistration = (
  eventId,
  additionalGuestCount,
  needToScroll = true
) => {
  const isInEditor = typeof window !== 'undefined' && !!window.CURRENT_HOST
  const siteId = useContext(SiteIdContext)
  const siteHash = useContext(SiteHashContext)

  const [guestLimit, setGuestLimit] = useState(null)
  const [isRegistrationClosedPopupOpen, setRegistrationClosedPopupState] =
    useState(false)
  const [isLoading, setLoadingState] = useState(false)
  const [formOpenStatus, setFormOpenStatus] = useState('')

  const openOrScrollToForm = () => {
    const formId = getFormIdByEventId(eventId)
    const targetElement = document.getElementById(formId)

    targetElement.style.display = 'flex'
    setFormOpenStatus('open')

    needToScroll &&
      targetElement.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
  }

  const fetchData = () => {
    setLoadingState(true)
    getEventStatusApi(siteId, eventId, siteHash)
      .then(({ data: guestLimit }) => {
        setLoadingState(false)

        if (guestLimit === 0) {
          setRegistrationClosedPopupState(true)
          return
        }

        setGuestLimit(guestLimit)
        openOrScrollToForm()
      })
      .catch(() => {
        setLoadingState(false)
      })
  }

  const onClick = () => {
    if (guestLimit !== null || isInEditor) {
      openOrScrollToForm()
      return
    }

    fetchData()
  }

  const closeRegistrationClosedPopup = () => {
    setRegistrationClosedPopupState(false)
  }

  const _guestLimit = guestLimit > 0 ? guestLimit - 1 : 0
  const _additionalGuestCount = isInEditor
    ? additionalGuestCount
    : Math.min(_guestLimit, additionalGuestCount)

  return {
    onClick,
    isLoading,
    formOpenStatus,
    additionalGuestCount: _additionalGuestCount,
    isRegistrationClosedPopupOpen,
    closeRegistrationClosedPopup,
    setGuestLimit,
    setFormOpenStatus
  }
}

export default useEventRegistration
