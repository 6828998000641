/* @flow */

import React, { useState } from 'react'
import { translate } from '@editor/common/utils/translations'

import headerImg from './headerImg.svg'
import { isOpenedWithHash } from '@editor/common/utils'
import Popup from '@renderforest/rf-ui-library/organism/Popup'
import Button from '@renderforest/rf-ui-library/atoms/Button'
import * as S from './styled.js'

const BUTTON_TEXT = translate('got_it_label')
const HEADER_TEXT = translate('good_to_see_you_label')

const CONTENT_TEXT = translate('before_you_starting_editing')
const CONTENT_WISH_TEXT = translate('happy_sitebuilding')

const LinkShareInfoPopup = () => {
  const [isOpen, setPopupStatus] = useState(
    isOpenedWithHash() && !localStorage.getItem('isShareLinkPopupShowed')
  )
  const closeInforPopup = () => {
    setPopupStatus(false)
    localStorage.setItem('isShareLinkPopupShowed', true)
  }
  return (
    <Popup
      isOpen={isOpen}
      onClose={closeInforPopup}
      header={
        <S.InfoPopupHeader>
          <img src={headerImg} alt="header-img" />
        </S.InfoPopupHeader>
      }
      footer={
        <S.Footer>
          <Button
            fit
            className="confirm-button"
            size="small"
            type="secondary"
            onClick={closeInforPopup}
          >
            {BUTTON_TEXT}
          </Button>
        </S.Footer>
      }
    >
      <S.InfoPopupContent>
        <S.ContentHeader>{HEADER_TEXT}</S.ContentHeader>
        <S.ContentText>{CONTENT_TEXT}</S.ContentText>
        <S.WishText>{CONTENT_WISH_TEXT}</S.WishText>
      </S.InfoPopupContent>
    </Popup>
  )
}

export default LinkShareInfoPopup
