/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  addButton,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const removeSlide = slideIdx => ({
    type: 'REMOVE_SLIDE',
    value: { slideIdx }
  })

  const addSlide = () => {
    dispatcher({
      type: 'ADD_SLIDE'
    })
  }
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const sliderActions = ({ showRemove, slideIdx, clickHandlers, imageUrl }) =>
    cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'header30SliderImage',
      clickHandlers,
      showRemove,
      imageUrl,
      additionalArgs: { slideIdx },
      actions: {
        change: ({ url, dimensions, undoable }) => ({
          type: 'CHANGE_SLIDER_IMAGE',
          value: { slideIdx, url, dimensions },
          undoable
        }),
        remove: () => ({
          type: 'REMOVE_SLIDER_IMAGE',
          value: slideIdx
        }),
        crop: ({ imgCropParams }) => ({
          type: 'CHANGE_SLIDER_IMAGE_CROP_PARAMS',
          value: { slideIdx, imgCropParams }
        })
      }
    })

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addButton,
    addSlide,
    removeSlide,
    sliderActions: sliderActions
  }
}
