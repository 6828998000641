/* @flow */
import styled from 'styled-components'

import THEME from './Portfolio2.theme'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
`

const WMCustomContainer = styled(WMContainer)``

const PortfolioImageWrap = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
`

const PortfolioContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
  @media only screen and (max-width: 1200px) {
    padding-bottom: 40px;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h1 {
      padding: 0;
      margin-bottom: 0;
    }
  }
`

const Dots = styled.div`
  position: absolute;
  z-index: 1;
  &.right {
    right: -200px;
    top: -50px;
  }
  &.left {
    left: -200px;
    bottom: -50px;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  box-sizing: border-box;
  margin-bottom: 0;
  width: 100%;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  padding-top: 20px;
  width: 100%;
`

const ImageFigure = styled.div`
  position: relative;
  z-index: 2;
`

const PortfolioImg = styled.img`
  width: 100%;
  height: auto;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const SquareBox = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 200px;
  height: 200px;
  background: ${({ theme }) => theme.palette.tertiarySemiLight};
  opacity: 0.2;
  position: absolute;
  &.right {
    right: -144px;
    top: 6px;
  }
  &.left {
    left: -140px;
    bottom: 12px;
  }
  @media only screen and (max-width: 1200px) {
    display: none;
  }
`

const ControlContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 56.25%;
    height: initial;
    border-radius: ${getStyleForProp('cardRadius')};
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  PortfolioContent,
  PortfolioImageWrap,
  Title,
  Description,
  PortfolioImg,
  SquareBox,
  ControlContainer,
  Dots,
  ImageFigure
}
