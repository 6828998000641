/* @flow */
import styled from 'styled-components'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Video1.theme'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import { getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  align-items: center;
  flex-direction: column;
`
const WmCustomContainer = styled(WMContainer)`
  @media only screen and (min-width: 769px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : ' 80px 0')};
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  @media only screen and (max-width: 992px) {
    margin-bottom: 40px;
  }
  .control-container {
    margin-bottom: 30px;
    p {
      padding-top: 0;
    }
  }
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  padding-top: 20px;
`

const PortfolioImg = styled.img`
  border-radius: ${getStyleForProp('cardRadius')};
`

const SquareBox = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  width: 200px;
  height: 200px;
  background: ${({ theme }) => theme.palette.tertiarySemiLight};
  opacity: 0.2;
  position: absolute;
  &.right {
    right: -144px;
    top: 6px;
  }
  &.left {
    left: -140px;
    bottom: 12px;
  }
`

const VideoControlWrapper = styled.div`
  margin: 0;
`

const VideoContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: hidden;
  position: relative;
  z-index: 1;
`

const VideoContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
`

const Dots = styled.div`
  position: absolute;
  z-index: 1;
  &.right {
    right: -200px;
    top: -50px;
  }
  &.left {
    left: -200px;
    bottom: -50px;
  }
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    padding-top: 56.25%;
  }
  .iframe-video {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  VideoContainer,
  Title,
  Description,
  PortfolioImg,
  SquareBox,
  VideoContent,
  VideoControlWrapper,
  VideoWrap,
  TextContent,
  Dots
}
