/* @flow */
import React, { PureComponent } from 'react'
import S from './Form.styled'
import { EMPTY_FIELD_MESSAGE } from '../Contact5.consts'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import { withDispatchContext, withEditingContext } from '@contexts'
import { LabelRenderer, isRequiredField } from './Form.util'

class DetailsInput extends PureComponent<void, void> {
  render() {
    const {
      data: form,
      formData,
      isEditing,
      isMessageVisible,
      requiredFields,
      setFormValue,
      componentDispatcher,
      visibilityMessages,
      setVisibilityMessages
    } = this.props
    const { detailsLabel } = form

    const isRequired = isRequiredField('details', requiredFields)
    const isError =
      (!isEditing && isMessageVisible && isRequired && !formData.details) ||
      (!isEditing && isRequired && visibilityMessages.details)
    return (
      <S.PersonalInfoInputContainer>
        <LabelRenderer
          text={detailsLabel}
          label="details"
          isEditing={isEditing}
          dispatcher={componentDispatcher}
          isRequired={isRequired}
          className="WM_GLOBAL_secondary-font"
        />
        <div className="input-container details-input-container">
          <S.Textarea
            isError={isError}
            value={formData.details || ' '}
            onChange={e => setFormValue(e.target.value, 'details')}
            onBlur={() => setVisibilityMessages('details')}
            style={{ minHeight: 100 }}
            maxLength="500"
            className="WM_GLOBAL_secondary-font"
          />
          {isError && (
            <S.ErrorMessage>
              {' '}
              <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
              {EMPTY_FIELD_MESSAGE}
            </S.ErrorMessage>
          )}
        </div>
      </S.PersonalInfoInputContainer>
    )
  }
}

export default withEditingContext(withDispatchContext(DetailsInput))
