/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_LINK_DATA':
        const { linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break
      case 'CHANGE_ICON':
        draft.icon = action.value.iconName
        break
      case 'REMOVE_ICON':
        draft.icon = ''
        break
      case 'ADD_ICON':
        draft.icon = 'edit'
      case 'REMOVE_LINK':
        draft.additionalText = ''
        draft.additionalUrl = ''
        break
      case 'ADD_LINK':
        const { additionalText, additionalUrl } = initialState
        draft.additionalText = additionalText
        draft.additionalUrl = additionalUrl
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
    }
  })
}
