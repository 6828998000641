/* @flow */
import { changeTitle } from '@website/components/CommonActions'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

export default dispatcher => {
  const addRow = (idx: string) => {
    dispatcher({
      type: 'ADD_ROW',
      value: idx
    })
  }

  const removeRow = (idx: string) => {
    dispatcher({
      type: 'REMOVE_ROW',
      value: idx
    })
  }

  const imageActions = (
    idx: number,
    rowCount: number,
    itemIdx: number,
    imageUrl: string
  ) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'portfolio8',
      showRemove: false,
      additionalArgs: { idx, itemIdx },
      imageUrl
    })
  ]

  return {
    addRow,
    removeRow,
    imageActions,
    changeTitle
  }
}
