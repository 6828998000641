/* @flow */
/* eslint-disable max-statements */
import React, { useContext, useCallback, useState, memo, useMemo } from 'react'
import ReactDOM from 'react-dom'

import { secondary } from '@website/common/styles/colors'
import If from '@website/common/components/Conditional'
import BackgroundControlsDesktop from './BackgroundControlsDesktop'
import BackgroundControlsMobile from './BackgroundControlsMobile'
import SliderControls from './SliderControls'
import ActionGetter from './Actions'
import SlideUp from '@editor/common/lib/SlideUp'
import { DispatchContext, MobileDetectContext } from '@contexts'

import * as Styled from './styled'
import { translate } from '@editor/common/utils/translations'

const SliderBackgroundControlsMobile = ({
  slides,
  activeSlideIdx,
  componentId,
  onSlideChange,
  onAddSlide,
  minSlidesCount,
  Actions,
  defaultTransparencyValue
}) => {
  const [bgModalState, setBgModalState] = useState(false)

  const handleSliderControls = useCallback(() => {
    setBgModalState(true)
  }, [])

  const closeDropDown = useCallback(() => {
    setBgModalState(false)
  }, [])

  return (
    <>
      <Styled.CustomizeSlidesContainer onClick={handleSliderControls}>
        <Styled.CustomizedSlidesText>
          <Styled.EditIcon
            name="glyph-edit"
            size="large"
            color={secondary.Normal}
          />
          <p>{translate('customize_the_slides_label')}</p>
        </Styled.CustomizedSlidesText>
        <Styled.CustumizeSlidesIcon
          name="glyph-arrow_filled_bottom"
          size="large"
          color={secondary.Normal}
          isOpen={bgModalState}
        />
      </Styled.CustomizeSlidesContainer>
      <SlideUp isOpen={bgModalState} onClose={closeDropDown}>
        <Styled.SliderBackgroundControls>
          <Styled.BaseComponentCard>
            <Styled.BgDescriptionText>
              {translate('background_image_label')}
            </Styled.BgDescriptionText>
            {slides.map((slide, idx) => (
              <>
                <Styled.BgControlsWrap
                  onClick={() => onSlideChange(idx)}
                  isActive={idx === activeSlideIdx}
                >
                  <Styled.SlidesNumber>Slide {idx + 1}</Styled.SlidesNumber>
                  <Styled.RightContent>
                    <BackgroundControlsMobile
                      activeSlideIdx={idx}
                      componentId={componentId}
                      backgroundImage={slide.backgroundImgUrl}
                      bgOverlayTransparency={slide.bgOverlayTransparency}
                      defaultTransparencyValue={defaultTransparencyValue}
                      removeBgImage={Actions.removeSliderBackground}
                      setTransparency={Actions.changeSliderTransparency}
                      changeSliderBackgroundImage={
                        Actions.changeSliderBackgroundImage
                      }
                    />
                    <If
                      condition={slides.length > minSlidesCount}
                      then={() => (
                        <Styled.DeleteIconWrap
                          name="icon-delete"
                          size="large"
                          color={secondary.SemiDark}
                          onClick={() => Actions.removeSlide(idx)}
                        />
                      )}
                    />
                  </Styled.RightContent>
                </Styled.BgControlsWrap>
              </>
            ))}
            <If
              condition={slides.length <= minSlidesCount}
              then={() => (
                <Styled.AddSlideBtnWrap>
                  <Styled.AddSlide onClick={onAddSlide}>
                    + {translate('add_slide_label')}
                  </Styled.AddSlide>
                </Styled.AddSlideBtnWrap>
              )}
            />
          </Styled.BaseComponentCard>
        </Styled.SliderBackgroundControls>
      </SlideUp>
    </>
  )
}

const SliderBackgroundControlsDesktop = ({
  slides,
  Actions,
  activeSlideIdx,
  slideCount,
  componentId,
  onLeftChange,
  onRightChange,
  openPopup,
  renderingContainer
}) => {
  const activeSlide = slides[activeSlideIdx] || {}
  const { backgroundImgUrl, bgOverlayTransparency } = activeSlide

  return ReactDOM.createPortal(
    <Styled.SliderBackgroundControls className="slider-background-controls">
      <Styled.BaseComponentCard>
        <SliderControls
          activeSlideIdx={activeSlideIdx}
          slideCount={slideCount}
          onLeftChange={onLeftChange}
          onRightChange={onRightChange}
        />
        <BackgroundControlsDesktop
          activeSlideIdx={activeSlideIdx}
          backgroundImage={backgroundImgUrl}
          bgOverlayTransparency={bgOverlayTransparency}
          componentId={componentId}
          openChangePopup={openPopup}
          removeBgImage={Actions.removeSliderBackground}
          setTransparency={Actions.changeSliderTransparency}
          changeSliderBackgroundImage={Actions.changeSliderBackgroundImage}
        />
      </Styled.BaseComponentCard>
    </Styled.SliderBackgroundControls>,
    renderingContainer || document.body
  )
}

const SliderBackgroundControls = memo(props => {
  const isMobile = useContext(MobileDetectContext)
  const dispatcher = useContext(DispatchContext)
  const Actions = useMemo(() => ActionGetter(dispatcher), [])

  const commonProps = {
    Actions
  }

  return (
    <If
      condition={isMobile}
      then={() => (
        <SliderBackgroundControlsMobile {...props} {...commonProps} />
      )}
      otherwise={() => (
        <SliderBackgroundControlsDesktop {...props} {...commonProps} />
      )}
    />
  )
})

export default SliderBackgroundControls
