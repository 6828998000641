/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Contact11.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'
import { hexToRgb } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding-top: ${({ isEditing }) => (isEditing ? '170px' : '110px')};
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => (isEditing ? '130px' : '90px')};
  }
`

const WMCustomContainer = styled(WMContainer)`
  max-width: ${({ isFourBlock }) => (isFourBlock ? '900px' : '1480px')};
  @media only screen and (max-width: 1440px) {
    max-width: ${({ isFourBlock }) => (isFourBlock ? '900px' : '1280px')};
  }
`

const FeatureItemWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -30px auto -50px auto;
  .control-container {
    width: 100%;
    height: 100%;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    margin: -30px auto;
  }
`

const ItemWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  word-break: break-word;
  width: 320px;
  max-width: 320px;
  text-align: center;
  border-radius: ${getStyleForProp('cardRadius')};
  background: ${getStyleForProp('cardBackgroundGradient')};
  backdrop-filter: blur(15px);
  box-shadow: ${({ theme }) =>
    `0px 0px 24px rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.15)`};
  border: 1px solid ${getStyleForProp('border')};
  margin: 30px 40px 50px 40px;
  @media only screen and (max-width: 1024px) {
    margin: 30px 40px 30px 40px;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
    max-width: 335px;
    margin: 30px auto 30px auto;
  }
`
const ContentWrap = styled.div`
  padding: ${({ areAllIconsDeleted, isEditing }) =>
    areAllIconsDeleted && !isEditing
      ? '40px 20px 40px 20px'
      : '50px 20px 40px 20px'};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .add-button-wrap {
    position: absolute;
    top: -20px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  > .control-container:nth-child(2) {
    margin-bottom: 20px;
    h2 {
      margin-bottom: 0;
    }
  }
`

const BlockTitle = styled.h2`
  width: 100%;
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment}
`

const BlockParagraph = styled.p`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment}
`

const A = styled.a`
  text-decoration: none;
  display: block;
  color: ${getStyleForProp('paragraph')};
  margin-bottom: 6px;
  width: 100%;
  ${getTextStyleByAlignment};
  &:hover {
    opacity: 0.7;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  margin: 0 0 30px 0;
  display: flex;
  justify-content: center;
  top: -30px;
`

const Circle = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${getStyleForProp('iconRadius')};
  background: ${getStyleForProp('buttonBackground')};
  pointer-events: none;
  > div {
    width: 38px;
    height: 38px;
    svg {
      max-width: 38px;
      max-height: 38px;
    }
  }
`

const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: theme.palette.accentWhite
}))``

export default {
  StyledContainer,
  WMCustomContainer,
  FeatureItemWrap,
  BlockTitle,
  BlockParagraph,
  ItemWrap,
  ContentWrap,
  A,
  IconWrapper,
  Circle,
  Icon
}
