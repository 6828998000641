import styled from 'styled-components'
import { secondary, white } from '@website/common/styles/colors'

const getContainerBoxShadow = ({ theme, hovered, hideControls }) => {
  const color = theme.colorMode === 'DARK' ? white : secondary.Dark
  return hovered && !hideControls ? `0px 0px 0px 1px ${color}` : ''
}

export const StyledControlContainer = styled.div`
  box-shadow: ${getContainerBoxShadow};
  position: relative;
  display: inline-flex;
  flex-direction: column;
  user-select: none;
  &[data-hovered="true"] {
    box-shadow: ${({ theme }) => {
      const color = theme.colorMode === 'DARK' ? white : secondary.Dark
      return `0px 0px 0px 1px ${color}`
    }}
  }
`

export const StyledControlItem = styled.div`
  position: relative;
  min-width: 42px;
  height: 42px;
  cursor: pointer;
  display: inline-flex;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  border: 1px solid ${secondary.Light};
  box-sizing: border-box;
  flex-shrink: 0;
  box-shadow: 0 6px 12px 0 #3271e626;

  & > svg {
    fill: ${secondary.SemiDark};
    flex-grow: 1;
  }

  &:hover {
    & > svg {
      fill: ${secondary.ExtraDark};
    }
  }
`
