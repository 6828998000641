import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    heading: p => p.accentWhite,
    iconColor: p => p.accentWhite,
    subTitle: p => p.primaryLight,
    paragraph: p => p.accentWhite,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    lightBackground: p => p.tertiaryExtraLight,
    navbarBg: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    subTitle: p => p.primaryDark,
    paragraph: p => p.tertiaryNormal,
    iconColor: p => p.tertiaryDark,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    lightBackground: p => p.tertiaryExtraLight,
    navbarBg: p => p.accentWhite
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    subTitle: p => p.primaryDark,
    paragraph: p => p.tertiaryNormal,
    iconColor: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    navbarBg: p => p.accentWhite,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    lightBackground: p => p.tertiaryExtraLight
  }
}
