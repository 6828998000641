import moment from 'moment'
import { getAddressFromQuery } from '@website/common/utils/index'

const DATE_TIME_FORMAT = 'DD.MM.YYYY HH:mm Z'
const DATE_TIME_FORMAT_WITHOUT_TIMEZONE = 'DD.MM.YYYY HH:mm'
const TIME_STAMP_FORMAT = 'YYYYMMDDTHHmmss'

const replaceSpaces = (text = '') => text.replace(/ /g, '+')
const formatDate = (date, timezone) => {
  const regex = /\(GMT([+-]\d{2}:\d{2})\)/
  const match = timezone.match(regex)
  const timezoneOffset = match && match.length > 1 ? match[1] : '+00:00'

  return moment(`${date} ${timezoneOffset}`, DATE_TIME_FORMAT).format(
    TIME_STAMP_FORMAT
  )
}

const addAnHour = date =>
  moment(date, DATE_TIME_FORMAT_WITHOUT_TIMEZONE)
    .add(1, 'hour')
    .format(DATE_TIME_FORMAT_WITHOUT_TIMEZONE)

export const generateGoogleCalendarEventUrl = eventData => {
  const {
    eventTitle,
    eventDescription,
    startDate,
    endDate,
    location,
    timezone
  } = eventData

  const _eventTitle = replaceSpaces(eventTitle)
  const _eventDescription = replaceSpaces(eventDescription)
  const _startDate = formatDate(startDate, timezone)
  const _endDate = endDate
    ? formatDate(endDate, timezone)
    : formatDate(addAnHour(startDate), timezone)
  const _location = location
    ? `&location=${replaceSpaces(getAddressFromQuery(location))}`
    : ''

  return `https://calendar.google.com/calendar/r/eventedit?text=${_eventTitle}&details=${_eventDescription}&dates=${_startDate}/${_endDate}${_location}`
}

export const formatTime = time => moment(time, 'HH:mm').format('h:mmA')

export const getFormIdByEventId = eventId => `${eventId}_formId`

export const generateEventCardDate = (startDateInfo, endDateInfo, timezone) => {
  const startDateTime = `${startDateInfo.date} | ${formatTime(
    startDateInfo.time
  )}`

  const endDateTime = endDateInfo.isVisible
    ? ` - ${
        endDateInfo.date !== startDateInfo.date ? endDateInfo.date + ' | ' : ''
      }${formatTime(endDateInfo.time)}`
    : ''

  const { value, isVisible } = timezone
  const timezoneValue = isVisible ? `${value.split(')')[0]})` : ''

  return `${startDateTime} ${endDateTime} ${timezoneValue}`
}
