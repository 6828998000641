//@flow
import React, { memo, useCallback, useMemo } from 'react'

import * as Styled from './styled'
import type { TFormProps } from './types'
import {
  CURRENCY_DROPDOWN_DATA,
  DONATING_ITEM_DATA,
  SELLING_ITEM_DATA
} from './consts'
import {
  INITIAL_DONATING_ERROR_MESSAGES,
  INITIAL_SELLING_ERROR_MESSAGES
} from '../consts'
import { Input, PayPalButtons, Dropdown } from '../components'
import SellingForm from './SellingForm'
import DonatingForm from './DonatingForm'
import { translate } from '@editor/common/utils/translations'

const Form = ({
  compId,
  activeType,
  sellingData,
  donatingData,
  setSellingData,
  setDonatingData,
  setActiveType,
  errorMessages,
  setErrorMessages
}: TFormProps) => {
  const isSelling = activeType === 'selling'

  const data = isSelling ? sellingData : donatingData
  const setData = isSelling ? setSellingData : setDonatingData

  const { email, title, style, ...rest } = data

  const onButtonTypeDropdownItemClick = useCallback(
    buttonType => {
      setActiveType(buttonType)

      isSelling
        ? setDonatingData({
            ...donatingData,
            title: donatingData.title || 'DONATE',
            type: 'donating',
            email: donatingData.email || sellingData.email,
            style: donatingData.style || sellingData.style
          })
        : setSellingData({
            ...sellingData,
            title: sellingData.title || 'PAY NOW',
            type: 'selling',
            email: sellingData.email || donatingData.email,
            style: sellingData.style || donatingData.style
          })

      setErrorMessages(
        isSelling
          ? INITIAL_DONATING_ERROR_MESSAGES
          : INITIAL_SELLING_ERROR_MESSAGES
      )
    },
    [isSelling, activeType, sellingData, donatingData]
  )

  const onCurrencyDropdownItemClick = useCallback(
    currency => {
      setData(data => ({
        ...data,
        currency
      }))
    },
    [setData]
  )

  const activeCurrency = useMemo(
    () =>
      CURRENCY_DROPDOWN_DATA.find(
        currencyDataItem => currencyDataItem.value === data.currency
      ),
    [data]
  )

  const currencyDropdownData = CURRENCY_DROPDOWN_DATA.filter(
    currencyItem => currencyItem.value !== activeCurrency.value
  )

  const commonProps = {
    setData,
    activeCurrency,
    currencyDropdownData,
    errorMessages,
    setErrorMessages,
    onCurrencyDropdownItemClick,
    ...rest
  }

  return (
    <Styled.Form>
      <Input
        inputKey="email"
        value={email}
        label={translate('paypal_email_address_label')}
        placeholder={translate('enter_your_email_label')}
        className="paypal-email-input"
        errorMessage={errorMessages.email}
        setErrorMessages={setErrorMessages}
        setData={setData}
      />
      <Styled.InputsWrapper>
        <Dropdown
          dropdownKey="type"
          label={translate('button_type_label')}
          list={isSelling ? DONATING_ITEM_DATA : SELLING_ITEM_DATA}
          activeItem={isSelling ? SELLING_ITEM_DATA[0] : DONATING_ITEM_DATA[0]}
          onItemClick={onButtonTypeDropdownItemClick}
          setData={setData}
        />
        <Input
          inputKey="title"
          label={translate('button_title_label')}
          value={title}
          charLimit={19}
          setData={setData}
          errorMessage={errorMessages.title}
          setErrorMessages={setErrorMessages}
        />
      </Styled.InputsWrapper>
      <Styled.SubTitle>
        {translate('select_the_button_color_label')}
      </Styled.SubTitle>
      <PayPalButtons
        activeStyle={style}
        title={title}
        compId={compId}
        setData={setData}
      />
      {isSelling ? (
        <SellingForm {...commonProps} />
      ) : (
        <DonatingForm {...commonProps} />
      )}
    </Styled.Form>
  )
}

export default memo(Form)
