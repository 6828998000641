import React from 'react'
import { withTheme } from 'styled-components'

const RhombusSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="122" height="122" viewBox="0 0 122 122">
      <defs>
        <style>
          </style>
      </defs>
      <path className="a" d="M228.333,295h-.2a68.3,68.3,0,0,0-1.742-12.121,67.128,67.128,0,0,0-3.764-11.288,66.4,66.4,0,0,0-5.583-10.242,65.432,65.432,0,0,0-7.2-8.983,63.97,63.97,0,0,0-8.608-7.511,62.2,62.2,0,0,0-9.814-5.826A60.312,60.312,0,0,0,169,233.283v-.2a60.308,60.308,0,0,0,22.089-5.585,62.136,62.136,0,0,0,9.7-5.658,63.871,63.871,0,0,0,8.54-7.3,65.338,65.338,0,0,0,7.189-8.735,66.328,66.328,0,0,0,5.643-9.97,67.051,67.051,0,0,0,3.9-11A68.107,68.107,0,0,0,228.031,173h.408a68.067,68.067,0,0,0,2.082,12.258,66.994,66.994,0,0,0,4.155,11.358,66.242,66.242,0,0,0,6.011,10.231,65.17,65.17,0,0,0,7.65,8.878,63.575,63.575,0,0,0,9.073,7.3,61.787,61.787,0,0,0,10.279,5.493A60.314,60.314,0,0,0,291,233.181h-.012a60.293,60.293,0,0,0-23.646,4.812,61.844,61.844,0,0,0-10.4,5.66,63.676,63.676,0,0,0-9.144,7.513,65.267,65.267,0,0,0-7.66,9.128,66.315,66.315,0,0,0-5.949,10.506,67.081,67.081,0,0,0-4.011,11.647A68.268,68.268,0,0,0,228.333,295Z" transform="translate(-169 -173)"/>
    </svg>
  )
}

export default withTheme(RhombusSVG)
