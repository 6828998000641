/* @flow */
import { validation } from '../Portfolio5.consts'
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addRow
} from '@website/components/CommonActions'

export default dispatcher => {
  const removeRow = (idx: string) => {
    dispatcher({
      type: 'REMOVE_ROW',
      value: idx
    })
  }

  const _addRow = idx => dispatcher(addRow(idx))

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addRow: _addRow,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    imageActions: (idx, rowCount, imageUrl) => [
      ...cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'portfolio5',
        additionalArgs: { idx },
        showRemove: false,
        imageUrl
      }),
      {
        toShow: rowCount > validation.images.min && idx !== 0,
        label: 'Delete row',
        handler: () => dispatcher(removeRow(idx))
      }
    ]
  }
}
