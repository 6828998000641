/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Footer5.theme'
import { styleProvider } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) =>
    isEditing ? '130px 0 50px 0' : '50px 0 50px 0'};
  background-color: ${getStyleForProp('background')};
  @media only screen and (max-width: 1024px) {
    padding: ${({ isEditing }) =>
      isEditing ? '50px 0 50px 0' : '50px 0 50px 0'};
  }
  @media only screen and (max-width: 457px) {
    padding-top: 50px;
    padding-bottom: 20px;
  }
`

const CopyRightWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  &:empty {
    display: none;
  }
  @media only screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`

const Copyright = styled.p`
  position: relative;
  font-size: 14px;
  color: ${getStyleForProp('paragraph')};
  text-align: center;
  @media only screen and (min-width: 801px) {
    ${({ isPrivacyPolicyVisible }) =>
      isPrivacyPolicyVisible
        ? css`
            max-width: calc(100% - 210px);
            &:after {
              content: '';
              background-color: ${getStyleForProp('paragraph')};
              position: absolute;
              height: 100%;
              width: 1px;
              right: -10px;
              top: 0;
            }
          `
        : ''}
  }
`

const FooterContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LinkWrap = styled.div`
  position: relative;
  max-width: 170px;
  margin-left: 30px;
  &:hover {
    a {
      opacity: 0.6;
    }
  }
  .privacy-policy-link {
    font-size: 14px;
    color: ${getStyleForProp('paragraph')};
    text-decoration: none;
  }
  @media only screen and (max-width: 800px) {
    margin-left: 0;
    margin-top: 30px;
    text-align: center;
    .control-container {
      margin-left: 0;
    }

    &:after {
      content: '';
      height: 1px;
      width: 15px;
      background-color: ${getStyleForProp('paragraph')};
      position: absolute;
      left: 50%;
      top: -13px;
      transform: translateX(-50%);
    }
  }
`

export default {
  Container,
  FooterContentWrap,
  LinkWrap,
  CopyRightWrapper,
  Copyright
}
