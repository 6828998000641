/* @flow */
import produce from 'immer'
import meta from './Cookie.meta'
const initialState = meta.defaultProps

export default function reducer(
  state: typeof meta.defaultProps = initialState,
  action: any
) {
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_QUOTE':
        draft.quote = action.value
        return
    }
  })
}
