/* @flow */
import {
  buttonModalActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeParagraph,
  changeTitle,
  addButton
} from '@website/components/CommonActions'

export default dispatch => ({
  changeTitle,
  changeParagraph,
  addButton,
  mockupActions: (showRemove, imageUrl) =>
    cropableImageModalActionMaker(dispatch)({
      showRemove,
      keyForSizes: 'header5Mockup',
      imageUrl
    }),
  btnActions: buttonModalActionMaker(dispatch)
})
