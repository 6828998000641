// @flow

import React from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import TooltipWrapper from '@website/common/components/TooltipWrapper'
import ResetChangesIcon from '@editor/common/assets/svgr-icons/Reset_changes_icon.svg'

import { setResetChangesPopupState } from '@actions/editor'
import { hasChangesAfterPublish, selectSitePublishedState } from '@selectors'

import { getResetChangesTooltipText } from './utils'
import type { TResetChangesPopupProps } from './types'
import * as Styled from '../styled'

const ResetChanges = ({
  isSitePublished,
  hasChangesAfterPublish,
  setResetChangesPopupState
}: TResetChangesPopupProps) => {
  const isOptionDisabled = !isSitePublished || !hasChangesAfterPublish

  const tooltipText = getResetChangesTooltipText(
    isSitePublished,
    hasChangesAfterPublish
  )

  const openPopup = () => {
    !isOptionDisabled && setResetChangesPopupState({ isOpen: true })
  }

  return (
    <TooltipWrapper
      offsets={{ x: 0, y: 8 }}
      position="bottom"
      text={tooltipText}
      mobileTimer={7000}
    >
      <Styled.PublishOptionItem
        withTopBorder
        disabled={isOptionDisabled}
        onClick={openPopup}
      >
        <ResetChangesIcon />
        <span>{translate('reset_changes_label')}</span>
      </Styled.PublishOptionItem>
    </TooltipWrapper>
  )
}

const mapStateToProps = state => ({
  isSitePublished: selectSitePublishedState(state),
  hasChangesAfterPublish: hasChangesAfterPublish(state)
})
const mapDispatchToProps = { setResetChangesPopupState }

export default connect(mapStateToProps, mapDispatchToProps)(ResetChanges)
