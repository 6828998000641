/* @flow */
import React from 'react'
import './Header10.styles.sass'
import S from './Header10.styled'
import THEME from './Header10.theme'
import { validation } from './Header10.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import NavBar from '@website/common/components/NavBar'
import BrowserBar from '@website/common/assets/Browser.svg'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import { DispatchContext, withEditingContext } from '@contexts'
import LinkWrapper from '@website/common/components/LinkWrapper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import ProportionalChildrenWrapper from '@website/common/components/ProportionalChildrenWrapper'
import { translate } from '@editor/common/utils/translations'

const ContainerWithEditingContext = withEditingContext(S.Container)

class Header10 extends React.PureComponent<void, void> {
  dispatcher = this.context
  state = { navbarHeight: 0 }

  handleHeightChange = height => {
    this.setState({ navBarHeight: height })
  }

  render() {
    const {
      data: {
        title,
        paragraph,
        buttons,
        logoSrc,
        logoColor,
        logoWidth,
        customCode,
        logoDimensions,
        backgroundImgUrl,
        backgroundImgDimensions,
        bgOverlayTransparency
      }
    } = this.props
    const Actions = ActionGetter(this.dispatcher)
    return (
      <ContainerWithEditingContext
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <NavBar
          handleHeightChange={this.handleHeightChange}
          theme={THEME}
          logoProps={{
            logoSrc: logoSrc,
            logoColor: logoColor,
            logoWidth: logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <S.WmCustomContainer
          className="header-10-container"
          topOffset={this.state.navBarHeight}
        >
          <div className="header-content">
            <EditableContent
              text={title}
              as={S.Title}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading"
              onChange={(newTitle: string) =>
                this.dispatcher(Actions.changeTitle(newTitle))
              }
            />
            <EditableContent
              text={paragraph}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={(newParagraph: string) =>
                this.dispatcher(Actions.changeParagraph(newParagraph))
              }
            />
            <S.ButtonsBlock>
              <S.ButtonsContainer>
                <ProportionalChildrenWrapper
                  WrapperComp={S.ButtonsWrap}
                  className="WM_GLOBAL_secondary-font"
                >
                  <Mapper
                    data={buttons}
                    render={(btn: string, btnIdx: number) => {
                      return (
                        <S.BtnWrapper
                          key={btnIdx}
                          isTertiary={btn.type === 'tertiary'}
                        >
                          <ControlsWithModalOpener
                            actions={Actions.btnActions(btnIdx, {
                              text: btn.buttonText,
                              link: btn.buttonUrl
                            })}
                          >
                            <LinkWrapper
                              text={btn.buttonText}
                              url={btn.buttonUrl}
                            />
                          </ControlsWithModalOpener>
                        </S.BtnWrapper>
                      )
                    }}
                  />
                </ProportionalChildrenWrapper>
              </S.ButtonsContainer>
              <AddButton
                onAdd={() => this.dispatcher(Actions.addButton())}
                toShow={buttons.length < validation.buttons.max}
                rounded
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_button_label')}
              </AddButton>
            </S.ButtonsBlock>
          </div>
          <div className="webpage-wrap">
            <img src={BrowserBar} className="browser-bar" alt="browser bar " />
            <EditableContent
              as={S.Code}
              text={customCode}
              required={validation.customCode.required}
              className="code-area"
              onChange={(newCode: string) =>
                this.dispatcher(Actions.changeCode(newCode))
              }
            />
          </div>
        </S.WmCustomContainer>
      </ContainerWithEditingContext>
    )
  }
}

Header10.contextType = DispatchContext

export default Header10
