/* @flow  */

/**
 Checks if Features is empty
 In first iteration fn returns array with boolean values,
 where every element indicates wheter feature with specified index is empty or not
 Function returns true if arr contains only `falsy` values.
 @returns boolean
 **/
export const isFeaturesEmpty = features =>
  !features.find(
    element => Object.values(element).filter(item => item).length !== 0
  )
