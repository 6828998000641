/* @flow */
import Pricing1 from './Pricing1'
import Pricing2 from './Pricing2'
import Pricing3 from './Pricing3'
import Pricing4 from './Pricing4'
import Pricing5 from './Pricing5'
import Pricing6 from './Pricing6'
import Pricing6_2 from './Pricing6_2'
import Pricing7 from './Pricing7'
import Pricing8 from './Pricing8'
import Feature1 from './Feature1'
import Feature2 from './Feature2'
import Feature3 from './Feature3'
import Feature4 from './Feature4'
import Feature4_2 from './Feature4_2'
import Feature5 from './Feature5'
import Feature6 from './Feature6'
import Feature7 from './Feature7'
import Feature8 from './Feature8'
import Feature9 from './Feature9'
import Feature9_2 from './Feature9_2'
import Feature10 from './Feature10'
import Feature11 from './Feature11'
import Feature11_2 from './Feature11_2'
import Feature12 from './Feature12'
import Feature12_2 from './Feature12_2'
import Feature13 from './Feature13'
import Feature14 from './Feature14'
import Feature14_2 from './Feature14_2'
import Feature15 from './Feature15'
import Feature15_2 from './Feature15_2'
import Feature16 from './Feature16'
import Feature16_2 from './Feature16_2'
import Feature17 from './Feature17'
import Feature18 from './Feature18'
import Feature19 from './Feature19'
import Feature20 from './Feature20'
import Feature21 from './Feature21'
import Feature23 from './Feature23'
import Feature22 from './Feature22'
import Feature24 from './Feature24'
import Feature25 from './Feature25'
import Feature26 from './Feature26'
import Feature27 from './Feature27'
import Feature28 from './Feature28'
import Feature29 from './Feature29'
import Feature30 from './Feature30'
import Feature31 from './Feature31'
import Feature32 from './Feature32'
import Feature33 from './Feature33'
import Feature34 from './Feature34'
import Feature35 from './Feature35'
import Feature36 from './Feature36'
import Feature37 from './Feature37'
import Feature38 from './Feature38'
import Contact1 from './Contact1'
import Contact2 from './Contact2'
import Contact3 from './Contact3'
import Contact4 from './Contact4'
import Contact5 from './Contact5'
import Contact6 from './Contact6'
import Contact7 from './Contact7'
import Contact8 from './Contact8'
import Contact9 from './Contact9'
import Contact10 from './Contact10'
import Contact11 from './Contact11'
import Contact12 from './Contact12'
import Contact13 from './Contact13'
import Contact14 from './Contact14'
import Steps1 from './Steps1'
import Steps2 from './Steps2'
import Steps4 from './Steps4'
import Steps3 from './Steps3'
import Steps5 from './Steps5'
import Steps6 from './Steps6'
import Steps7 from './Steps7'
import Steps8 from './Steps8'
import Steps9 from './Steps9'
import Steps10 from './Steps10'
import Team1 from './Team1'
import Team2 from './Team2'
import Team3 from './Team3'
import Team4 from './Team4'
import Team5 from './Team5'
import Team6 from './Team6'
import Team7 from './Team7'
import Team8 from './Team8'
import Team9 from './Team9'
import Header1 from './Header1'
import Header2 from './Header2'
import Header4 from './Header4'
import Header5 from './Header5'
import Header5_2 from './Header5_2'
import Header6 from './Header6'
import Header6_2 from './Header6_2'
import Header7 from './Header7'
import Header8 from './Header8'
import Header9 from './Header9'
import Header10 from './Header10'
import Header11 from './Header11'
import Header13 from './Header13'
import Header14 from './Header14'
import Header15 from './Header15'
import Header16 from './Header16'
import Header17 from './Header17'
import Header18 from './Header18'
import Header19 from './Header19'
import Header20 from './Header20'
import Header21 from './Header21'
import Header22 from './Header22'
import Header23 from './Header23'
import Header24 from './Header24'
import Header25 from './Header25'
import Header26 from './Header26'
import Header27 from './Header27'
import Header28 from './Header28'
import Header29 from './Header29'
import Header30 from './Header30'
import Header31 from './Header31'
import Header32 from './Header32'
import Header33 from './Header33'
import Header34 from './Header34'
import Header35 from './Header35'
import Header36 from './Header36'
import Header37 from './Header37'
import Header38 from './Header38'
import Header39 from './Header39'
import Header40 from './Header40'
import Header41 from './Header41'
import Header42 from './Header42'
import Header43 from './Header43'
import Header44 from './Header44'
import Header45 from './Header45'
import Header46 from './Header46'
import Header47 from './Header47'
import Header48 from './Header48'
import Header49 from './Header49'
import Header50 from './Header50'
import Header51 from './Header51'
import Header52 from './Header52'
import Header53 from './Header53'
import Header54 from './Header54'
import Header55 from './Header55'
import Header56 from './Header56'
import Header57 from './Header57'
import Clients1 from './Clients1'
import Clients2 from './Clients2'
import Clients3 from './Clients3'
import Clients4 from './Clients4'
import Portfolio1 from './Portfolio1'
import Portfolio2 from './Portfolio2'
import Portfolio3 from './Portfolio3'
import Portfolio4 from './Portfolio4'
import Portfolio5 from './Portfolio5'
import Portfolio6 from './Portfolio6'
import Portfolio6_2 from './Portfolio6_2'
import Portfolio7 from './Portfolio7'
import Portfolio8 from './Portfolio8'
import Portfolio9 from './Portfolio9'
import Portfolio10 from './Portfolio10'
import Portfolio11 from './Portfolio11'
import Portfolio12 from './Portfolio12'
import Portfolio13 from './Portfolio13'
import Portfolio14 from './Portfolio14'
import PayPal2 from './PayPal2'
import PayPal1 from './PayPal1'
import PayPal3 from './PayPal3'
import PayPal5 from './PayPal5'
import PayPal4 from './PayPal4'
import CTA1 from './CTA1'
import CTA2 from './CTA2'
import CTA3 from './CTA3'
import CTA4 from './CTA4'
import CTA5 from './CTA5'
import CTA6 from './CTA6'
import CTA7 from './CTA7'
import CTA8 from './CTA8'
import CTA9 from './CTA9'
import CTA10 from './CTA10'
import CTA11 from './CTA11'
import CTA11_2 from './CTA11_2'
import CTA12 from './CTA12'
import CTA13 from './CTA13'
import CTA14 from './CTA14'
import Text1 from './Text1'
import Text2 from './Text2'
import Text3 from './Text3'
import Text4 from './Text4'
import Text5 from './Text5'
import Text5_2 from './Text5_2'
import Text6 from './Text6'
import Text7 from './Text7'
import Text8 from './Text8'
import Text9 from './Text9'
import Text10 from './Text10'
import Text11 from './Text11'
import Text12 from './Text12'
import Text13 from './Text13'
import Text14 from './Text14'
import Text15 from './Text15'
import Text16 from './Text16'
import Text17 from './Text17'
import Text18 from './Text18'
import Text19 from './Text19'
import Text20 from './Text20'
import Text21 from './Text21'
import Text22 from './Text22'
import Email1 from './Email1'
import Email2 from './Email2'
import Email3 from './Email3'
import Email4 from './Email4'
import Email5 from './Email5'
import Email6 from './Email6'
import NoHeader from './NoHeader'
import Footer1 from './Footer1'
import Footer2 from './Footer2'
import Footer3 from './Footer3'
import Footer4 from './Footer4'
import Footer5 from './Footer5'
import Footer6 from './Footer6'
import Footer7 from './Footer7'
import Footer9 from './Footer9'
import Footer10 from './Footer10'
import FAQ1 from './FAQ1'
import FAQ2 from './FAQ2'
import FAQ3 from './FAQ3'
import Slider1 from './Slider1'
import Slider2 from './Slider2'
import Slider3 from './Slider3'
import Slider4 from './Slider4'
import Slider5 from './Slider5'
import Slider6 from './Slider6'
import Testimonials1 from './Testimonials1'
import Testimonials2 from './Testimonials2'
import Testimonials3 from './Testimonials3'
import Testimonials4 from './Testimonials4'
import Testimonials5 from './Testimonials5'
import Testimonials6 from './Testimonials6'
import Testimonials7 from './Testimonials7'
import Video1 from './Video1'
import Video2 from './Video2'
import Video3 from './Video3'
import Video4 from './Video4'
import Video5 from './Video5'
import Post1 from './Post1'
import Post2 from './Post2'
import Post3 from './Post3'
import Music1 from './Music1'
import Music2 from './Music2'
import Music3 from './Music3'
import Music4 from './Music4'
import Music5 from './Music5'
import Spotify1 from './Spotify1'
import Spotify2 from './Spotify2'
import Spotify3 from './Spotify3'
import Spotify4 from './Spotify4'
import Spotify5 from './Spotify5'
import Spotify6 from './Spotify6'
import Soundcloud6 from './Soundcloud6'
import Soundcloud7 from './Soundcloud7'
import PrivacyPolicy1 from './PrivacyPolicy1'
import PrivacyPolicy2 from './PrivacyPolicy2'
import PrivacyPolicy4 from './PrivacyPolicy4'
import PrivacyPolicy3 from './PrivacyPolicy3'
import PrivacyPolicy5 from './PrivacyPolicy5'
import Basic1 from './Basic1'
import Basic2 from './Basic2'
import Basic3 from './Basic3'
import Basic4 from './Basic4'
import Countdown1 from './Countdown1'
import Countdown2 from './Countdown2'
import Cookie from './Cookie'
import RenderforestFooter from './RenderforestFooter'
import RSVP1 from './RSVP1'
import RSVP2 from './RSVP2'
import RSVP3 from './RSVP3'
import RSVP4 from './RSVP4'
import RSVP5 from './RSVP5'
import RSVP6 from './RSVP6'
import RSVP7 from './RSVP7'

export default {
  Pricing1,
  Pricing2,
  Pricing3,
  Pricing4,
  Pricing5,
  Pricing6,
  Pricing6_2,
  Pricing7,
  Pricing8,
  Feature1,
  Feature2,
  Feature3,
  Feature4,
  Feature4_2,
  Feature5,
  Feature6,
  Feature7,
  Feature8,
  Feature9,
  Feature9_2,
  Feature10,
  Feature11,
  Feature11_2,
  Feature12,
  Feature12_2,
  Feature13,
  Feature14,
  Feature14_2,
  Feature15,
  Feature15_2,
  Feature16,
  Feature16_2,
  Feature17,
  Feature18,
  Feature19,
  Feature20,
  Feature21,
  Feature22,
  Feature23,
  Feature24,
  Feature25,
  Feature26,
  Feature27,
  Feature28,
  Feature29,
  Feature30,
  Feature31,
  Feature32,
  Feature33,
  Feature34,
  Feature35,
  Feature36,
  Feature37,
  Feature38,
  Contact1,
  Contact2,
  Contact3,
  Contact4,
  Contact5,
  Contact6,
  Contact7,
  Contact8,
  Contact9,
  Contact10,
  Contact11,
  Contact12,
  Contact13,
  Contact14,
  Steps1,
  Steps2,
  Steps4,
  Steps3,
  Steps5,
  Steps6,
  Steps7,
  Steps8,
  Steps9,
  Steps10,
  Team1,
  Team2,
  Team3,
  Team4,
  Team5,
  Team6,
  Team7,
  Team8,
  Team9,
  NoHeader,
  Header1,
  Header2,
  Header4,
  Header5,
  Header5_2,
  Header6,
  Header6_2,
  Header7,
  Header8,
  Header9,
  Header10,
  Header11,
  Header13,
  Header24,
  Header14,
  Header15,
  Header16,
  Header17,
  Header18,
  Header20,
  Header21,
  Header22,
  Header23,
  Header19,
  Header25,
  Header26,
  Header27,
  Header28,
  Header29,
  Header30,
  Header31,
  Header32,
  Header33,
  Header34,
  Header35,
  Header36,
  Header37,
  Header38,
  Header39,
  Header40,
  Header41,
  Header42,
  Header43,
  Header44,
  Header45,
  Header46,
  Header47,
  Header48,
  Header49,
  Header50,
  Header51,
  Header52,
  Header53,
  Header54,
  Header55,
  Header56,
  Header57,
  Clients1,
  Clients2,
  Clients3,
  Clients4,
  Portfolio1,
  Portfolio2,
  Portfolio3,
  Portfolio4,
  Portfolio5,
  Portfolio6,
  Portfolio6_2,
  Portfolio7,
  Portfolio8,
  Portfolio9,
  Portfolio10,
  Portfolio11,
  Portfolio12,
  Portfolio13,
  Portfolio14,
  CTA1,
  CTA2,
  CTA3,
  CTA4,
  CTA5,
  CTA6,
  CTA7,
  CTA8,
  CTA9,
  CTA10,
  CTA11,
  CTA11_2,
  CTA12,
  CTA13,
  CTA14,
  Text1,
  Text2,
  Text3,
  Text4,
  Text5,
  Text5_2,
  Text6,
  Text7,
  Text8,
  Text9,
  Text10,
  Text11,
  Text12,
  Text13,
  Text14,
  Text15,
  Text16,
  Text17,
  Text18,
  Text19,
  Text20,
  Text21,
  Text22,
  Email1,
  Email2,
  Email3,
  Email4,
  Email5,
  Email6,
  Footer1,
  Footer2,
  Footer3,
  Footer4,
  Footer5,
  Footer6,
  Footer7,
  Footer9,
  Footer10,
  FAQ1,
  FAQ2,
  FAQ3,
  Slider1,
  Slider2,
  Slider3,
  Slider4,
  Slider5,
  Slider6,
  Testimonials1,
  Testimonials2,
  Testimonials3,
  Testimonials4,
  Testimonials5,
  Testimonials6,
  Testimonials7,
  Video1,
  Video2,
  Video3,
  Video4,
  Video5,
  Post1,
  Post2,
  Post3,
  Music4,
  Music2,
  Music1,
  Music3,
  Music5,
  Spotify1,
  Spotify2,
  Spotify3,
  Spotify4,
  Spotify5,
  Spotify6,
  Soundcloud6,
  Soundcloud7,
  PrivacyPolicy1,
  PrivacyPolicy2,
  PrivacyPolicy4,
  PrivacyPolicy3,
  PrivacyPolicy5,
  Basic1,
  Basic2,
  Basic3,
  Basic4,
  Countdown1,
  Countdown2,
  PayPal1,
  PayPal2,
  PayPal3,
  PayPal4,
  PayPal5,
  Cookie,
  RenderforestFooter,
  RSVP1,
  RSVP2,
  RSVP3,
  RSVP4,
  RSVP5,
  RSVP6,
  RSVP7
}
