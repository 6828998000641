import React, { memo } from 'react'

import Icon from '@website/common/components/Icon'
import NoIcons from './NoIcons'
import * as Styled from './styled'
import Scrollable from '@editor/common/components/Scrollable'

const Content = ({ icons, selected, onSelect }) =>
  icons.length ? (
    <Scrollable>
      <Styled.ContentWrapper>
        <Styled.Content>
          {icons.map(icon => (
            <Styled.IconItem
              key={icon}
              data-name={icon}
              isSelected={selected === icon}
              onClick={() => onSelect(icon)}
            >
              <Icon name={icon} />
            </Styled.IconItem>
          ))}
        </Styled.Content>
      </Styled.ContentWrapper>
    </Scrollable>
  ) : (
    <NoIcons />
  )

export default memo(Content)
