/* @flow */

module.exports = {
  id: 'Header6',
  thumbnailRatio: 0.49,
  isUnique: true,
  label: 'Header6',
  category: 'header',
  colorMode: 'LIGHT',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header6new-1_0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header6m-1.0.0.jpg',
  metaData: {
    title: {
      _type: 'title',
      _min: 1,
      _max: 120,
      _value: 'Get our app and navigate our website with your smart devices'
    },
    description: {
      _type: 'paragraph',
      _min: 0,
      _max: 800,
      _value:
        'We make it easier for you! Now you can download our application and enjoy our services on your smartphone. Acquire it ether on App Store or Google Play. '
    },
    logoSrc: {
      _type: 'logo',
      _dimensionsKey: 'logoDimensions',
      _optimizationSizes: [
        { _width: 500 },
        { _width: 500 },
        { _width: 500 },
        { _width: 500 }
      ],
      _min: 0,
      _max: 2048,
      _value: ''
    },
    logoDimensions: {},
    logoColor: 'default',
    appleBackground: {
      _type: 'image',
      _dimensionsKey: 'appleBackgroundDimensions',
      _optimizationSizes: [
        { _width: 600 },
        { _width: 600 },
        { _width: 600 },
        { _width: 600 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png'
    },
    appleBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png'
    },
    androidBackground: {
      _type: 'image',
      _dimensionsKey: 'androidBackgroundDimensions',
      _optimizationSizes: [
        { _width: 600 },
        { _width: 600 },
        { _width: 600 },
        { _width: 600 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png'
    },
    androidBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png'
    },
    backgroundImgUrl: {
      _type: 'backgroundImage',
      _dimensionsKey: 'backgroundImgDimensions',
      _optimizationSizes: [
        { _width: 800 },
        { _width: 1200 },
        { _width: 1600 },
        { _width: 2200 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg'
    },
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg'
    },
    bgOverlayTransparency: 0,
    appleStoreLink: {
      _type: 'link',
      _min: 0,
      _max: 2048,
      _value: 'https://www.apple.com/ios/app-store/'
    },
    googlePlayLink: {
      _type: 'link',
      _min: 0,
      _max: 2048,
      _value: 'https://play.google.com/store'
    }
  },
  defaultProps: {
    title: 'Get our app and navigate our website with your smart devices',
    description:
      'We make it easier for you! Now you can download our application and enjoy our services on your smartphone. Acquire it ether on App Store or Google Play. ',
    logoSrc: '',
    logoDimensions: {},
    logoColor: 'default',
    appleBackground:
      'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
    appleBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/ios-placeholder.png'
    },
    androidBackground:
      'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png',
    androidBackgroundDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/android-placeholder.png'
    },
    backgroundImgUrl:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg',
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header6-img1-1_0.jpg'
    },
    bgOverlayTransparency: 0,
    appleStoreLink: 'https://www.apple.com/ios/app-store/',
    googlePlayLink: 'https://play.google.com/store'
  }
}
