/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Contact3.theme'
import {
  styleProvider,
  hexToRgb,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 779px) {
        padding-top: 150px;
      }
    `}
`

const WmCustomContainer = styled(WMContainer)`
  > :first-child {
    padding-top: 0;
  }
`

const ContactTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  .control-container {
    width: 100%;
  }
  .control-container:nth-child(2) {
    margin-top: 20px;
    p {
      padding-top: 0;
    }
  }
  > :first-child {
    padding-top: 0;
    margin-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 45px 0 25px;
  height: 50px;
  margin: 20px 0 0 0;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('buttonRadius')};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 25px;
  margin: 20px 0 30px 0;
  max-height: 200px;
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('inputRadius')};
  border: ${({ theme }) =>
    `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
`

const FormContainer = styled.div`
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 30px;
  @media only screen and (max-width: 992px) {
    padding-top: 20px;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
  .button-container {
    margin-top: 10px;
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  .required-icon {
    position: absolute;
    right: 10px;
    bottom: 5px;
    padding: 10px;
    color: $secondary;
  }
  > div {
    svg {
      max-width: 12px;
      max-height: 12px;
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
`
const ErrorMessage = styled.div`
  position: absolute;
  top: 70px;
  color: #eb0c00;
  font-size: 14px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 5px;
  }
`

const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

export default {
  Title,
  Input,
  TextArea,
  StyledContainer,
  WmCustomContainer,
  Paragraph,
  InputContainer,
  FormContainer,
  ContactTextContainer,
  ErrorMessage,
  ErrorIcon
}
