/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addButton,
  addBlock,
  addSocial
} from '@website/components/CommonActions'

import {
  removeBlockActionMaker,
  buttonModalActionMaker,
  socialActionMaker,
  videoModalActionMaker
} from '@website/components/ActionMakers'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeSubTitle = (newSubTitle: string) => {
    dispatch({
      type: 'CHANGE_SUB_TITLE',
      value: newSubTitle
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const changeBlockSubTitle = (newSubTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_SUBTITLE',
      value: { newSubTitle, idx }
    })
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const _addSocial = () => dispatch(addSocial())

  const addVideo = () => {
    dispatch({
      type: 'ADD_VIDEO'
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeSubTitle,
    changeSubTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    getButtonActions: buttonModalActionMaker(dispatch),
    addButton: () => dispatch(addButton()),
    changeBlockTitle,
    changeBlockParagraph,
    changeBlockSubTitle,
    addBlock: () => dispatch(addBlock()),
    blockActions: removeBlockActionMaker(dispatch),
    socialActions: socialActionMaker(dispatch),
    addSocial: _addSocial,
    videoActions: videoModalActionMaker(dispatch),
    addVideo
  }
}
