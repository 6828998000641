/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  addButton,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
export default dispatcher => {
  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const changeParagraph2 = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH2',
      value: newParagraph
    })
  }

  const changeSlogan = (newSlogan: string) => {
    dispatcher({
      type: 'CHANGE_SLOGAN',
      value: newSlogan
    })
  }

  const changeName = (newName: string) => {
    dispatcher({
      type: 'CHANGE_NAME',
      value: newName
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeParagraph2Alignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_PARAGRAPH2_ALIGNMENT',
      value: alignment
    })
  }

  const changeNameAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: alignment
    })
  }

  const changeSloganAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SLOGAN_ALIGNMENT',
      value: alignment
    })
  }

  return {
    addButton,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeParagraph2,
    changeSlogan,
    changeName,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeParagraph2Alignment,
    changeSloganAlignment,
    changeNameAlignment,
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'team5',
        showRemove: false,
        imageUrl
      })
  }
}
