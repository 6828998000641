export default {
  DARK: {
    blockTitle: p => p.accentWhite,
    blockParagraph: p => p.accentWhite,
    additionalBlockTitle: p => p.accentWhite,
    additionalBlockParagraph: p => p.accentWhite,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    blockTitle: p => p.primaryDark,
    blockParagraph: p => p.tertiaryDark,
    additionalBlockTitle: p => p.primaryDark,
    additionalBlockParagraph: p => p.tertiaryDark,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.tertiaryDark,
    additionalBlockTitle: p => p.tertiaryDark,
    additionalBlockParagraph: p => p.tertiaryDark,
    background: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight
  }
}
