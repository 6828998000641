/* @flow */
import React, { useContext, useState, useCallback, memo } from 'react'
import * as S from './Testimonials7.styled'
import { validation } from './Testimonials7.consts'
import ActionGetter from './Actions'
import SlideCounts from './SlideCounts'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Slider from '@website/common/components/StagePaddingSlider'
import Quote from '@website/common/assets/svgr-icons/quote.svg'
import { translate } from '@editor/common/utils/translations'

const Testimonials7 = memo(
  ({
    data: {
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      testimonials
    }
  }) => {
    const [activeIdx, setActiveIdx] = useState(0)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)

    const stopPropagation = useCallback(e => {
      e.stopPropagation()
    }, [])

    const _addSlide = useCallback(() => Actions.addSlide(activeIdx), [])

    const _beforeChange = (_, newIdx: number) => {
      setActiveIdx(newIdx)
    }

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
        isEditing={isEditing}
      >
        <S.WmCustomContainer>
          <S.Card>
            <S.TextContent>
              <S.QuoteWrapper>
                <Quote />
              </S.QuoteWrapper>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                required={!paragraph}
                maxCount={validation.titleMaxChar}
                className="WM_GLOBAL_heading42"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                alignment={paragraphAlignment}
                required={!title}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </S.TextContent>
            <S.SliderContent>
              <S.SliderContainer
                isEditing={isEditing}
                className="testimonials7-slider"
              >
                <Slider
                  swipeToSlide={!isEditing}
                  swipe={!isEditing}
                  accessibility={!isEditing}
                  autoplay={!isEditing}
                  beforeChange={_beforeChange}
                  dots={true}
                  hasArrowsOnlyEditMode={isEditing}
                >
                  {testimonials.map((testimonial: string, idx: number) => (
                    <ControlsWithModalOpener
                      actions={Actions.blockActions({
                        idx,
                        showRemove:
                          testimonials.length > validation.testimonials.min
                      })}
                      style={{
                        height: '100%',
                        width: '100%',
                        marginTop: '50px',
                        marginBottom: '10px'
                      }}
                    >
                      <Show
                        key={idx}
                        when={[testimonial.paragraph, testimonial.name]}
                      >
                        <S.BlockItem
                          isEditing={isEditing}
                          className="block-item"
                        >
                          <S.BlockItemWrap className="WM_GLOBAL_secondary-font">
                            <S.ClientWrap
                              isEditing={isEditing}
                              className="client-wrap"
                              onClick={isEditing && stopPropagation}
                            >
                              <If
                                condition={testimonial.imgUrl}
                                then={() => (
                                  <S.ImageFrame>
                                    <S.ImageWrapper
                                      onClick={isEditing && stopPropagation}
                                    >
                                      <ControlsWithImageCropper
                                        key={idx}
                                        toPropagate={false}
                                        actions={Actions.mockupActions(idx)}
                                        style={{
                                          height: '100%'
                                        }}
                                      >
                                        <Image
                                          withLazy={false}
                                          as={S.Img}
                                          alt="Slider image"
                                          sizes={testimonial.imgDimensions}
                                          defaultImgSrc={testimonial.imgUrl}
                                          asProps={{
                                            imgCropParams:
                                              testimonial.imgCropParams
                                          }}
                                        />
                                      </ControlsWithImageCropper>
                                    </S.ImageWrapper>
                                  </S.ImageFrame>
                                )}
                              />
                              <S.AddBtnWrapper
                                onClick={stopPropagation}
                                onMouseDown={isEditing && stopPropagation}
                              >
                                <AddButton
                                  onAdd={() => {
                                    Actions.addClientImage(idx)
                                  }}
                                  toShow={!testimonial.imgUrl}
                                >
                                  <Icon
                                    name="glyph-add"
                                    className="icon"
                                    size="normal"
                                  />
                                  {translate('add_image_label')}
                                </AddButton>
                              </S.AddBtnWrapper>
                              <S.HeadingContentWrapper>
                                <EditableContent
                                  as={S.Name}
                                  text={testimonial.name}
                                  alignment={testimonial.nameAlignment}
                                  className="WM_GLOBAL_heading20"
                                  maxCount={validation.maxCustomerNameChar}
                                  isEditing={isEditing}
                                  onChange={(newName: string) => {
                                    Actions.changeClientName(newName, idx)
                                  }}
                                  changeAlignment={(alignment: string) =>
                                    Actions.changeNameAlignment(alignment, idx)
                                  }
                                />
                                <EditableContent
                                  as={S.Position}
                                  text={testimonial.position}
                                  alignment={testimonial.positionAlignment}
                                  maxCount={validation.maxCustomerPositionChar}
                                  className="WM_GLOBAL_paragraph14"
                                  onChange={(newPosition: string) => {
                                    Actions.changeClientPosition(
                                      newPosition,
                                      idx
                                    )
                                  }}
                                  changeAlignment={(alignment: string) =>
                                    Actions.changePositionAlignment(
                                      alignment,
                                      idx
                                    )
                                  }
                                />
                              </S.HeadingContentWrapper>
                            </S.ClientWrap>
                            <S.ItemParagraphWrap
                              className="block-paragraph-wrap"
                              onClick={isEditing && stopPropagation}
                              isEditing={isEditing}
                              isParagraphOnly={
                                !testimonial.imgUrl &&
                                !testimonial.name &&
                                !testimonial.position
                              }
                            >
                              <EditableContent
                                as={S.ItemParagraph}
                                text={testimonial.paragraph}
                                alignment={testimonial.paragraphAlignment}
                                required
                                className="WM_GLOBAL_paragraph18"
                                maxCount={validation.maxSlideContentChar}
                                onChange={(newParagraph: string) => {
                                  Actions.changeItemParagraph(newParagraph, idx)
                                }}
                                changeAlignment={(alignment: string) =>
                                  Actions.changeBlockParagraphAlignment(
                                    alignment,
                                    idx
                                  )
                                }
                              />
                            </S.ItemParagraphWrap>
                          </S.BlockItemWrap>
                        </S.BlockItem>
                      </Show>
                    </ControlsWithModalOpener>
                  ))}
                </Slider>
              </S.SliderContainer>
              <SlideCounts
                activeSlideIdx={activeIdx + 1}
                slidesCount={testimonials.length}
              />
              <AddButton
                onAdd={_addSlide}
                toShow={testimonials.length < validation.testimonials.max}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_slide_label')}
              </AddButton>
            </S.SliderContent>
          </S.Card>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Testimonials7
