/* @flow */
import styled from 'styled-components'

import THEME from './Header15.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0 0 0 0' : `${topOffset}px 0 0 0`};
  border-left: 10px solid ${getStyleForProp('navbarBg')};
  border-right: 10px solid ${getStyleForProp('navbarBg')};
  border-bottom: 10px solid ${getStyleForProp('navbarBg')};
  border-top: ${({ isNavbarMissing, ...rest }) =>
    isNavbarMissing ? `10px solid ${getStyleForProp('navbarBg')(rest)}` : '0'};
  margin-bottom: 0;
  @media only screen and (max-width: 768px) {
    border-width: 6px;
  }
  @media only screen and (max-width: 600px) {
    padding: ${({ topOffset, isEditing }) =>
      isEditing ? '0 0 0 0' : `${topOffset}px 0 0 0`};
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 74%;
  padding: 160px 0;
  @media only screen and (max-width: 768px) {
    padding: 80px 0;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      width: 100%;
      padding-top: 0;
    }
  }
`

const ContentImage = styled.div`
  margin: 0 0 40px 0;
`

const Description = styled.h1`
  width: 100%;
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
  padding-top: 20px;
`

const Img = styled.img`
  max-height: 200px;
  object-fit: contain;
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 538px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  > :first-child {
    padding-top: 0;
  }
  @media only screen and (max-width: 1024px) {
    padding-left: 40px;
    padding-right: 40px;
    max-width: 100%;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  HeaderContent,
  ContentImage,
  Description,
  Img
}
