import styled, { css } from 'styled-components'

const getDisabledProps = ({ disabled }) =>
  disabled
    ? css`
        opacity: 0.7;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
      `
    : ``

const Button = styled.button`
  ${getDisabledProps};
  padding: 11px 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: all 0.2s ease-out;
`

export const SecondaryButton = styled(Button)`
  color: #3271e6;
  background: #ffffff;
  &:hover {
    background: #eef5ff;
  }
`

export const PrimaryButton = styled(Button)`
  color: #ffffff;
  background: transparent linear-gradient(180deg, #5690ff 0%, #387dff 100%) 0%
    0% no-repeat padding-box;
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #5690ff 100%) 0%
      0% no-repeat padding-box;
    box-shadow: 0px 3px 9px #0052e080;
  }
`
