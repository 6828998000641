/* @flow */
import { imageModalActionMaker } from '@website/components/ActionMakers'

import { changeTitle, changeParagraph } from '@website/components/CommonActions'

export default dispatcher => {
  const addImage = () => ({
    type: 'ADD_IMAGE'
  })

  const addBlock = (afterIdx: number) => ({
    type: 'ADD_BLOCK',
    value: afterIdx
  })

  const removeBlock = (idx: number) => ({
    type: 'REMOVE_BLOCK',
    value: idx
  })

  const changeSubTitle = (newSubTitle: string) => ({
    type: 'CHANGE_SUB_TITLE',
    value: newSubTitle
  })

  const changeSubtitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const imageActions = (
    blockIdx: number,
    idx: number,
    siteId: number,
    imageUrl: string
  ) => {
    const actions = {
      change: ({ url, dimensions, undoable }) => ({
        type: 'CHANGE_PORTFOLIO_IMG',
        value: { url, dimensions, blockIdx, idx },
        undoable
      })
    }
    return imageModalActionMaker(dispatcher)({
      siteId,
      actions,
      showRemove: false,
      keyForSizes: 'portfolio6',
      imageUrl
    })
  }

  const mainImageActions = (siteId: number, imageUrl: string) =>
    imageModalActionMaker(dispatcher)({
      siteId,
      keyForSizes: 'portfolio6MainImage',
      showRemove: false,
      imageUrl
    })

  return {
    changeTitle,
    changeSubTitle,
    changeSubtitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeParagraph,
    addBlock,
    removeBlock,
    addImage,
    imageActions,
    mainImageActions
  }
}
