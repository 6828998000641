/* @flow */
import { useState, useEffect, useRef, useContext, useCallback } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { KEY_CODES } from '@website/conf/consts'
import { replaceItem } from '@editor/common/utils'
export { useControlModals } from './ControlModalsHook'

/**
 * Checks if given url is valid image or not
 * @param {string} url Image url
 * @returns {boolean} is image valid or not
 */
export const useImageFromUrl = (url: string): boolean => {
  const [isValid, setStatus] = useState(null)
  const image = new Image()
  const _setStatus = (st: boolean) => () => setStatus(st)

  useEffect(() => {
    image.src = url
    image.addEventListener('load', _setStatus(true))
    image.addEventListener('error', _setStatus(false))
    return () => {
      image.removeEventListener('load', _setStatus(true))
      image.removeEventListener('error', _setStatus(false))
    }
  }, [url])
  return isValid
}

/**
 * Calls given callback when click target is not a descendant of used ref.
 * @param {function} cb Which is called when we detect outside click from given refed element.
 * @returns create ref
 */
export const useBackdropClick = (
  cb: () => void,
  selectorArr?: Array<string>
): any => {
  const ref: HTMLElement | null = useRef(null)
  let isMouseDownOnModal: boolean = false

  const checkIsOnOutside = (e: Event): boolean => {
    const selectors = selectorArr
      ? selectorArr.reduce((nodes, selector) => {
          const nodesList = document.querySelectorAll(selector)
          for (let i = 0; i < nodesList.length; i++) {
            nodes.push(nodesList[i])
          }
          return nodes
        }, [])
      : []

    const hasPreventedSelector = selectors.some(selector =>
      selector ? selector.contains(e.target) : false
    )

    return (
      ref &&
      ref.current &&
      !ref.current.contains(e.target) &&
      !hasPreventedSelector
    )
  }

  const onMouseUp = useCallback(
    (e: Event) => {
      const isMouseUpOutside = checkIsOnOutside(e)
      if (!isMouseDownOnModal && isMouseUpOutside) {
        return cb()
      }
    },
    [isMouseDownOnModal, cb]
  )

  const onMouseDown = useCallback((e: Event) => {
    isMouseDownOnModal = !checkIsOnOutside(e)
  }, [])

  useEffect(() => {
    if (document.body) {
      document.body.addEventListener('mousedown', onMouseDown)
      document.body.addEventListener('mouseup', onMouseUp)
      document.body.addEventListener('touchstart', onMouseDown)
      document.body.addEventListener('touchend', onMouseUp)
    }
    return () => {
      if (document.body) {
        document.body.removeEventListener('mousedown', onMouseDown)
        document.body.removeEventListener('mouseup', onMouseUp)
        document.body.removeEventListener('touchstart', onMouseDown)
        document.body.removeEventListener('touchend', onMouseUp)
      }
    }
  }, [onMouseDown, onMouseUp])

  return ref
}

/**
 * Calls given callback when click target is not a descendant of used ref.
 * @param {function} cb Which is called when we detect outside click from given refed element.
 * @returns create ref
 */
export const useKeyPress = ({
  buffer,
  keyCode,
  onPress,
  withBuffer = false
}): boolean => {
  const ref: HTMLElement | null = useRef(null)

  let _buffer = []
  let clearId = null

  if (withBuffer) {
    clearId = setInterval(() => {
      _buffer = []
    }, 1000)
  }

  const onKeyDown = (e: Event) => {
    if (withBuffer) {
      const key = Object.keys(KEY_CODES).find(
        key => KEY_CODES[key] === e.keyCode
      )
      if (key) {
        _buffer.push(key.toLocaleLowerCase())
        const pattern = _buffer.join('+')
        const matchedBuffer = buffer.find(buf => buf.code === pattern)

        if (matchedBuffer) {
          return matchedBuffer.action()
        }
      }
    }

    if (Array.isArray(keyCode)) {
      const haveSuppliedCode = keyCode.find(c => c === e.keyCode)
      return haveSuppliedCode ? onPress(e) : void 0
    }

    if (e.keyCode === keyCode) {
      return onPress ? onPress(e) : void 0
    }
  }

  useEffect(() => {
    if (document.body) {
      document.body.addEventListener('keydown', onKeyDown)
    }
    return () => {
      if (document.body) {
        document.body.removeEventListener('keydown', onKeyDown)
      }
      if (clearId) {
        clearInterval(clearId)
      }
    }
  })

  return ref
}

/**
 * Caches value between rerenders
 * @param {*} value Any value that needs to be cached in different renders
 */
export const usePrevious = (value: T): T => {
  const ref = useRef(value)
  useEffect(() => {
    ref.current = value
  })

  return ref.current
}

export const useContextArr = (contexts: Array<any>) =>
  contexts.map(context => useContext(context))

export function useEventListener(
  eventName: string,
  handler: Function,
  useCapture = false
) {
  if (typeof window === 'undefined') return
  // Create a ref that stores handler
  const savedHandler = useRef(handler)

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = window.addEventListener
      if (!isSupported) return

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: any) => savedHandler.current(event)

      // Add event listener
      window.addEventListener(eventName, eventListener, useCapture)

      // Remove event listener on cleanup
      return () => {
        window.removeEventListener(eventName, eventListener)
      }
    },
    [eventName] // Re-run if eventName or element changes
  )
}

export const useHistoryWithSearch = () => {
  const history = useHistory()
  const ref = useRef({ ...history })
  const { search } = history.location

  const getParams = param => {
    return typeof param === 'object'
      ? { ...param, search }
      : { pathname: param, search }
  }

  ref.current.push = param => {
    history.push(getParams(param))
  }

  ref.current.replace = param => {
    history.replace(getParams(param))
  }

  return ref.current
}

export const useLangRedirection = (
  websiteLanguagesData,
  isLoaded,
  isPreview
) => {
  const { pathname } = useLocation()
  const { siteId: siteIdFromRoute, lang: langFromRoute } = useParams()
  const history = useHistoryWithSearch()

  const getEditUrl = (pathname, code) =>
    replaceItem(pathname.split('/'), 2, code).join('/')

  useEffect(() => {
    if (!isLoaded) return

    if (websiteLanguagesData && websiteLanguagesData.length > 1) {
      const isLanguageExists = !!websiteLanguagesData.find(
        ({ lang }) => lang.code === langFromRoute
      )

      if (isLanguageExists) return

      const primaryLanguage = websiteLanguagesData.find(
        language => language.isPrimary
      )

      const {
        lang: { code }
      } = primaryLanguage

      const _url = isPreview
        ? `/preview/${code}/site/${siteIdFromRoute}`
        : getEditUrl(pathname, code)

      history.replace(`${_url}`)

      return
    }

    const _url = isPreview
      ? `/preview/lang/site/${siteIdFromRoute}`
      : getEditUrl(pathname, 'lang')

    if (langFromRoute !== 'lang') history.replace(`${_url}`)
  }, [
    websiteLanguagesData,
    isLoaded,
    isPreview,
    langFromRoute,
    siteIdFromRoute
  ])
}

// TODO: get rid of this hook
export const usePopupCustomMouseDown = (ref, className, onClose) => {
  useEffect(() => {
    const popupEl = document.querySelector(className)

    const onPopupClose = e => {
      if (ref.current && !ref.current.contains(e.target)) {
        e.stopPropagation()
        onClose()
      }
    }

    popupEl.addEventListener('mousedown', onPopupClose, true)

    return () => {
      popupEl.removeEventListener('mousedown', onPopupClose, true)
    }
  }, [ref.current, onClose])
}
