export const INITIAL_SELLING_ERROR_MESSAGES = {
  email: '',
  title: '',
  productName: '',
  productPrice: ''
}

export const INITIAL_DONATING_ERROR_MESSAGES = {
  email: '',
  title: ''
}

export const SELLING_EMPTY_DATA = {
  productName: '',
  productId: '',
  productPrice: '',
  currency: 'USD',
  productDiscountedPrice: '',
  shippingFee: ''
}

export const DONATING_EMPTY_DATA = {
  headline: '',
  organizationName: '',
  donationId: '',
  amount: '',
  currency: 'USD',
  isCustomerAmountSettingEnabled: true
}
