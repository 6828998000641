/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Morph = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75.212"
      height="122.876"
      viewBox="0 0 75.212 122.876"
    >
      <g
        id="Group_3658"
        data-name="Group 3658"
        transform="translate(-532.105 -272.01)"
        // style="isolation: isolate"
      >
        <path
          id="Path_3225"
          data-name="Path 3225"
          d="M572.784,278.394a3.192,3.192,0,1,1,3.193-3.191A3.192,3.192,0,0,1,572.784,278.394Z"
          transform="translate(31.34)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3226"
          data-name="Path 3226"
          d="M572.784,294.256a3.192,3.192,0,1,1,3.193-3.193A3.193,3.193,0,0,1,572.784,294.256Z"
          transform="translate(31.34 13.261)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3227"
          data-name="Path 3227"
          d="M572.784,310.119a3.193,3.193,0,1,1,3.193-3.193A3.194,3.194,0,0,1,572.784,310.119Z"
          transform="translate(31.34 26.522)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3228"
          data-name="Path 3228"
          d="M572.784,325.979a3.192,3.192,0,1,1,3.193-3.193A3.193,3.193,0,0,1,572.784,325.979Z"
          transform="translate(31.34 39.783)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3229"
          data-name="Path 3229"
          d="M572.784,341.842a3.193,3.193,0,1,1,3.193-3.193A3.194,3.194,0,0,1,572.784,341.842Z"
          transform="translate(31.34 53.044)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3230"
          data-name="Path 3230"
          d="M560.289,278.394a3.192,3.192,0,1,1,3.191-3.191A3.191,3.191,0,0,1,560.289,278.394Z"
          transform="translate(20.894)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3231"
          data-name="Path 3231"
          d="M560.289,294.256a3.192,3.192,0,1,1,3.191-3.193A3.192,3.192,0,0,1,560.289,294.256Z"
          transform="translate(20.894 13.261)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3232"
          data-name="Path 3232"
          d="M560.289,310.119a3.193,3.193,0,1,1,3.191-3.193A3.193,3.193,0,0,1,560.289,310.119Z"
          transform="translate(20.894 26.522)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3233"
          data-name="Path 3233"
          d="M560.289,325.979a3.192,3.192,0,1,1,3.191-3.193A3.191,3.191,0,0,1,560.289,325.979Z"
          transform="translate(20.894 39.783)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3234"
          data-name="Path 3234"
          d="M560.289,341.842a3.193,3.193,0,1,1,3.191-3.193A3.193,3.193,0,0,1,560.289,341.842Z"
          transform="translate(20.894 53.044)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3235"
          data-name="Path 3235"
          d="M547.792,278.394a3.192,3.192,0,1,1,3.193-3.191A3.191,3.191,0,0,1,547.792,278.394Z"
          transform="translate(10.447)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3236"
          data-name="Path 3236"
          d="M547.792,294.256a3.192,3.192,0,1,1,3.193-3.193A3.191,3.191,0,0,1,547.792,294.256Z"
          transform="translate(10.447 13.261)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3237"
          data-name="Path 3237"
          d="M547.792,310.119a3.193,3.193,0,1,1,3.193-3.193A3.192,3.192,0,0,1,547.792,310.119Z"
          transform="translate(10.447 26.522)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3238"
          data-name="Path 3238"
          d="M547.792,325.979a3.192,3.192,0,1,1,3.193-3.193A3.191,3.191,0,0,1,547.792,325.979Z"
          transform="translate(10.447 39.783)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3239"
          data-name="Path 3239"
          d="M547.792,341.842a3.193,3.193,0,1,1,3.193-3.193A3.192,3.192,0,0,1,547.792,341.842Z"
          transform="translate(10.447 53.044)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3240"
          data-name="Path 3240"
          d="M535.3,278.394a3.192,3.192,0,1,1,3.193-3.191A3.192,3.192,0,0,1,535.3,278.394Z"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3241"
          data-name="Path 3241"
          d="M535.3,294.256a3.192,3.192,0,1,1,3.193-3.193A3.193,3.193,0,0,1,535.3,294.256Z"
          transform="translate(0 13.261)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3242"
          data-name="Path 3242"
          d="M535.3,310.119a3.193,3.193,0,1,1,3.193-3.193A3.194,3.194,0,0,1,535.3,310.119Z"
          transform="translate(0 26.522)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3243"
          data-name="Path 3243"
          d="M535.3,325.979a3.192,3.192,0,1,1,3.193-3.193A3.193,3.193,0,0,1,535.3,325.979Z"
          transform="translate(0 39.783)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
        <path
          id="Path_3244"
          data-name="Path 3244"
          d="M535.3,341.842a3.193,3.193,0,1,1,3.193-3.193A3.194,3.194,0,0,1,535.3,341.842Z"
          transform="translate(0 53.044)"
          // style="mix-blend-mode: lighten;isolation: isolate"
        />
      </g>
    </svg>
  )
}

export default withTheme(Morph)
