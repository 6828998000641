import React, { useContext } from 'react'
import { MobileDetectContext } from '@contexts'

import * as Styled from './styled'
import SkeletonSecondSection from './SkeletonSecondSection'

const ProjectLoader = () => {
  const isMobile = useContext(MobileDetectContext)

  return (
    <Styled.ProjectLoader>
      <Styled.FirstSection>
        <Styled.TitleSkeleton />
        <Styled.DescriptionSkeleton />
      </Styled.FirstSection>
      <SkeletonSecondSection />
      {isMobile ? (
        <>
          <SkeletonSecondSection />
          <SkeletonSecondSection />
        </>
      ) : null}
    </Styled.ProjectLoader>
  )
}

export default ProjectLoader
