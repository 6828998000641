/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions: _dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = _dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
      case 'CHANGE_PHONE':
        draft.phone = action.value
        break
      case 'CHANGE_EMAIL':
        draft.email = action.value
        break
      case 'CHANGE_SOCIAL_TITLE':
        draft.socialTitle = action.value
        break
      case 'CHANGE_PLACEHOLDER_TEXT':
        const { key, value } = action.value
        if (draft.placeholders) {
          draft.placeholders[key] = value
        } else {
          draft.placeholders = { [key]: value }
        }
        break
      case 'REMOVE_PHONE':
        draft.phone = ''
        break
      case 'ADD_PHONE':
        draft.phone = initialState.phone
        break
      case 'REMOVE_EMAIL':
        draft.email = ''
        break
      case 'ADD_EMAIL':
        draft.email = initialState.email
        break
      case 'CHANGE_TITLE_ALIGNMENT':
        draft.titleAlignment = action.value
        break
      case 'CHANGE_PARAGRAPH_ALIGNMENT':
        draft.paragraphAlignment = action.value
        break
      case 'CHANGE_CONTACT_ALIGNMENT': {
        const { alignment, alignmentKey } = action.value
        draft[alignmentKey] = alignment
        break
      }
      case 'CHANGE_ADDRESS': {
        draft.address = action.value
        break
      }
      case 'CHANGE_ADDRESS_ALIGNMENT': {
        draft.addressAlignment = action.value
        break
      }
      case 'CHANGE_ADDRESS_TITLE': {
        draft.addressTitle = action.value
        break
      }
      case 'CHANGE_ADDRESS_TITLE_ALIGNMENT': {
        draft.addressTitleAlignment = action.value
        break
      }
      case 'CHANGE_EMAIL_TITLE': {
        draft.emailTitle = action.value
        break
      }
      case 'CHANGE_EMAIL_TITLE_ALIGNMENT': {
        draft.emailTitleAlignment = action.value
        break
      }
      case 'CHANGE_PHONE_TITLE': {
        draft.phoneTitle = action.value
        break
      }
      case 'CHANGE_PHONE_TITLE_ALIGNMENT': {
        draft.phoneTitleAlignment = action.value
        break
      }
    }
  })
}
