import styled, { createGlobalStyle } from 'styled-components'
import { secondary, primary } from '@website/common/styles/colors'
import LibDropdown from '@renderforest/rf-ui-library/molecules/Dropdown'

export const GlobalStyles = createGlobalStyle`
  .dropdown-items-container {
    & svg {
      fill: ${secondary.SemiDark};
    }
    & [color='primaryDark'] svg {
      fill: ${primary.Dark};
    }
  }
`

export const Dropdown = styled(LibDropdown)`
  & svg {
    fill: ${secondary.SemiDark};
  }
  span {
    max-width: 160px;
  }
`

export const HomeOptionLabel = styled.div`
  display: flex;
  align-teims: center;
  & > svg {
    width: 13px;
    height: auto;
    margin-right: 5px;
  }
`
