/* @flow */

import React, { useContext } from 'react'
import S from './Team1.styled'
import { validation } from './Team1.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show'
import Image from '@website/common/components/Image'
import { getExternalUrl } from '@website/common/utils'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import LinkWrapper from '@website/common/components/LinkWrapper'
import {
  ControlsWithModalOpener,
  Controls
} from '@website/common/components/Controlled'
import EditableContent from '@website/common/components/EditableContent'
import { openUrl } from '@website/common/utils'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SingleButton from '@website/common/components/SingleButton'
import { translate } from '@editor/common/utils/translations'

const Team1 = props => {
  const {
    data: {
      title,
      titleAlignment,
      paragraph,
      paragraphAlignment,
      buttonUrl,
      buttonText,
      team
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <Show when={[title, paragraph]}>
          <S.HeadingWrapper>
            <Show when={[title]}>
              <EditableContent
                text={title}
                as={S.Title}
                alignment={titleAlignment}
                maxCount={validation.headingMaxChar}
                className="WM_GLOBAL_heading"
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
            </Show>
            <Show when={[paragraph]}>
              <EditableContent
                text={paragraph}
                as={S.Description}
                alignment={paragraphAlignment}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </Show>
          </S.HeadingWrapper>
        </Show>
        <S.MembersWrap>
          <Mapper
            data={team}
            render={(member: string, teamIdx: number) => {
              return (
                <Controls
                  key={teamIdx}
                  actions={Actions.teamActions(
                    teamIdx,
                    team.length > validation.team.min
                  )}
                >
                  <S.TeamItemWrap>
                    <S.ImageWrap>
                      <ControlsWithImageCropper
                        style={{ height: '100%' }}
                        actions={Actions.imageActions(
                          teamIdx,
                          member.imgLink,
                          member.imgUrl
                        )}
                      >
                        <Image
                          as={S.Img}
                          alt="Team member"
                          defaultImgSrc={member.imgUrl}
                          sizes={member.imgDimensions}
                          asProps={{
                            imgCropParams: member.imgCropParams,
                            onClick: () =>
                              member.imgLink &&
                              openUrl(getExternalUrl(member.imgLink))
                          }}
                          isUrl={member.imgLink}
                        />
                      </ControlsWithImageCropper>
                    </S.ImageWrap>
                    <EditableContent
                      text={member.name}
                      as={S.TeamName}
                      alignment={member.nameAlignment}
                      maxCount={validation.blockTitleMaxChar}
                      className="WM_GLOBAL_primary-font"
                      onChange={(newName: string) => {
                        Actions.changeName(newName, teamIdx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeNameAlignment(alignment, teamIdx)
                      }
                    />
                    <EditableContent
                      text={member.position}
                      as={S.TeamPosition}
                      alignment={member.positionAlignment}
                      maxCount={validation.blockSubTitleMaxChar}
                      className="WM_GLOBAL_secondary-font"
                      onChange={(newPosition: string) => {
                        Actions.changePosition(newPosition, teamIdx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changePositionAlignment(alignment, teamIdx)
                      }
                    />
                    <EditableContent
                      text={member.paragraph}
                      as={S.TeamParagraph}
                      alignment={member.blockParagraphAlignment}
                      maxCount={validation.blockParagraphMaxChar}
                      className="WM_GLOBAL_secondary-font"
                      onChange={(newParagraph: string) =>
                        Actions.changeItemParagraph(newParagraph, teamIdx)
                      }
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockParagraphAlignment(
                          alignment,
                          teamIdx
                        )
                      }
                    />
                    <Show when={[...member.socials]}>
                      <S.SocialWrap>
                        <Mapper
                          data={member.socials}
                          render={(social, socialIdx) => {
                            return (
                              <ControlsWithModalOpener
                                key={socialIdx}
                                actions={Actions.socialActions(
                                  socialIdx,
                                  social.socialUrl,
                                  teamIdx
                                )}
                              >
                                <LinkWrapper
                                  url={social.socialUrl}
                                  linkProps={{ 'aria-label': 'Social link' }}
                                >
                                  <Icon
                                    size="middle"
                                    color="white"
                                    name={social.icon}
                                  />
                                </LinkWrapper>
                              </ControlsWithModalOpener>
                            )
                          }}
                        />
                        <AddButton
                          onAdd={() => Actions.addSocial(teamIdx)}
                          toShow={
                            member.socials.length < validation.socials.max
                          }
                          type="icon"
                          large
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                        </AddButton>
                      </S.SocialWrap>
                    </Show>
                  </S.TeamItemWrap>
                </Controls>
              )
            }}
          />
        </S.MembersWrap>
        <SingleButton
          btnUrl={buttonUrl}
          btnText={buttonText}
          componentDispatcher={dispatcher}
        />
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addMember}
        toShow={team.length < validation.team.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_block_label')}
      </AddButton>
    </S.StyledContainer>
  )
}

export default Team1
