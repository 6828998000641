/* @flow */
import { navLinkActionMaker } from '@website/components/ActionMakers'
import { undoable } from '@editor/common/utils'

export default dispatcher => ({
  linkActions: (...args) => navLinkActionMaker(dispatcher)(...args, 'footer'),
  addLink: () =>
    undoable(
      dispatcher({
        type: 'ADD_FOOTER_LINK',
        value: { text: 'New link', link: '#' }
      })
    )
})
