/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'
import { SINGLE_BUTTON } from './RSVP4.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      // additional_block
      case 'CHANGE_ADDITIONAL_BLOCK_TITLE':
        draft.additionalBlockTitle = action.value
        break
      case 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH':
        draft.additionalBlockParagraph = action.value
        break
      case 'CHANGE_ADDITIONAL_BLOCK_TITLE_ALIGNMENT': {
        draft.additionalBlockTitleAlignment = action.value
        break
      }
      case 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH_ALIGNMENT': {
        draft.additionalBlockParagraphAlignment = action.value
        break
      }
      case 'ADD_ADDITIONAL_BLOCK_IMAGE': {
        draft.additionalBlockImage = initialState.additionalBlockImage
        break
      }

      case 'REMOVE_ADDITIONAL_BLOCK_IMAGE': {
        draft.additionalBlockImage.imageUrl = ''
        draft.additionalBlockImage.imgDimensions = {}
        draft.additionalBlockImage.imgCropParams = ''
        break
      }
      case 'CHANGE_ADDITIONAL_BLOCK_IMAGE': {
        const { url, dimensions } = action.value
        if (!url) {
          return
        }
        draft.additionalBlockImage.imageUrl = url
        draft.additionalBlockImage.imgDimensions = dimensions || {}
        draft.additionalBlockImage.imgCropParams = {}
        break
      }
      case 'SET_ADDITIONAL_BLOCK_IMG_CROP_PARAMS': {
        const { imgCropParams } = action.value
        draft.additionalBlockImage.imgCropParams = imgCropParams
        break
      }
      // section_image_actions
      case 'CHANGE_CARD_IMAGE': {
        const { url, dimensions } = action.value
        if (!url) {
          return
        }
        draft.cardImage.imageUrl = url
        draft.cardImage.imgDimensions = dimensions || {}
        draft.cardImage.imgCropParams = {}
        break
      }

      case 'CHANGE_CARD_BG_OVERLAY_TRANSPARENCY': {
        draft.cardImage.bgOverlayTransparency = action.value
        break
      }

      case 'CHANGE_SUBTITLE_ALIGNMENT': {
        draft.subtitleAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BUTTON_DATA':
        const { btnData } = action.value
        draft.buttonUrl = btnData.link
        draft.buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BUTTON':
        draft.buttonText = SINGLE_BUTTON.buttonText
        draft.buttonUrl = SINGLE_BUTTON.buttonUrl
        break
      // step_actions
      case 'CHANGE_STEP_DATA':
        const { idx, data, keyInStore } = action.value
        draft.steps[idx][keyInStore] = data
        break
      case 'CHANGE_STEP_INFO_ALIGNMENT': {
        const { alignment, idx: _idx } = action.value
        draft.steps[_idx].stepInfoAlignment = alignment
        break
      }
      case 'CHANGE_STEP_TITLE_ALIGNMENT': {
        const { alignment, idx: _idx } = action.value
        draft.steps[_idx].stepTitleAlignment = alignment
        break
      }
      case 'CHANGE_STEP_PARAGRAPH_ALIGNMENT': {
        const { alignment, idx: _idx } = action.value
        draft.steps[_idx].stepParagraphAlignment = alignment
        break
      }
      case 'ADD_BLOCK':
        draft.steps.push(initialState.steps[0])
        break
      case 'REMOVE_BLOCK':
        draft.steps.splice(action.value.idx, 1)
        break
      // dateAndLocationActions
      case 'CHANGE_DATE_TITLE':
        draft.dateTitle = action.value
        break
      case 'CHANGE_TIME_TITLE':
        draft.timeTitle = action.value
        break
      case 'CHANGE_LOCATION_TITLE':
        draft.locationTitle = action.value
        break
      case 'CHANGE_DATA_TITLE_ALIGNMENT': {
        draft.dateTitleAlignment = action.value
        break
      }
      case 'CHANGE_TIME_TITLE_ALIGNMENT': {
        draft.timeTitleAlignment = action.value
        break
      }
      case 'CHANGE_LOCATION_TITLE_ALIGNMENT': {
        draft.locationTitleAlignment = action.value
        break
      }
      case 'CHANGE_DATE_VALUE_ALIGNMENT': {
        draft.dateValueAlignment = action.value
        break
      }
      case 'CHANGE_TIME_VALUE_ALIGNMENT': {
        draft.timeValueAlignment = action.value
        break
      }
      case 'CHANGE_LOCATION_VALUE_ALIGNMENT': {
        draft.locationValueAlignment = action.value
        break
      }
    }
  })
}
