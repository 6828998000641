/* @flow */
import { validation } from '../Music5.consts'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import {
  soundCloudUrlEditActionMaker,
  musicItemRemoveActionMaker
} from '@website/components/ActionMakers'
export default dispatcher => {
  const addMusic = () => {
    dispatcher({
      type: 'ADD_MUSIC'
    })
  }
  const deleteMusic = (idx: number) => ({
    type: 'DELETE_MUSIC',
    value: idx
  })
  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    addMusic,
    deleteMusic,
    musicActions: (idx, musicUrls) => {
      const musicCount = musicUrls.length
      const musicUrl = musicUrls[idx]

      return [
        ...soundCloudUrlEditActionMaker(dispatcher)({
          idx,
          fullHeight: true,
          url: musicUrl
        }),
        ...musicItemRemoveActionMaker(dispatcher)({
          idx,
          showRemove: musicCount > validation.musicUrls.min
        })
      ]
    }
  }
}
