//@flow
import React, { memo } from 'react'
import SearchInput from '../SearchInput'
import CountriesSearchDropdown from '../CountriesSearchDropdown'
import * as Styled from './styled'

const SearchWithDatabases = ({
  count,
  database,
  searchValue,
  isVerticalView,
  onSearchClick,
  onSearchChange,
  handleEnterPress,
  onDatabaseChange,
  onRemoveIconClick
}) => {
  return (
    <Styled.Container isVerticalView={isVerticalView}>
      <Styled.SearchWrapper>
        <SearchInput
          count={count}
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          handleEnterPress={handleEnterPress}
          onRemoveIconClick={onRemoveIconClick}
        />
        <CountriesSearchDropdown
          activeValue={database}
          setActiveValue={onDatabaseChange}
        />
      </Styled.SearchWrapper>
      <Styled.SearchButton
        onClick={onSearchClick}
        isVerticalView={isVerticalView}
      >
        Search
      </Styled.SearchButton>
    </Styled.Container>
  )
}

export default memo(SearchWithDatabases)
