/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_CLIENT_DATA': {
        const { data, idx, keyInStore } = action.value
        draft.testimonials[idx][keyInStore] = data
        break
      }
      case 'CHANGE_IMAGE': {
        const { idx, url, dimensions } = action.value
        draft.testimonials[idx].imgUrl = url
        draft.testimonials[idx].imgDimensions = dimensions || {}
        break
      }
      case 'CHANGE_CLIENT_LOGO': {
        const { url, idx, imgDimensions } = action.value
        draft.testimonials[idx].logoUrl = url
        draft.testimonials[idx].logoDimensions = imgDimensions || {}
        break
      }
      case 'ADD_CLIENT_LOGO':
        const slideIdx = action.value
        draft.testimonials[slideIdx].logoUrl =
          initialState.testimonials[slideIdx].logoUrl
        draft.testimonials[slideIdx].logoDimensions =
          initialState.testimonials[slideIdx].logoDimensions
        break
      case 'REMOVE_CLIENT_LOGO':
        draft.testimonials[action.value].logoUrl = ''
        draft.testimonials[action.value].logoDimensions = {}
        break
      case 'REMOVE_IMAGE':
        draft.testimonials[action.value].imgUrl = ''
        draft.testimonials[action.value].imgDimensions = {}
        break
      case 'ADD_CLIENT_IMAGE': {
        draft.testimonials[action.value].imgUrl =
          initialState.testimonials[0].imgUrl
        draft.testimonials[action.value].imgDimensions =
          initialState.testimonials[0].imgDimensions
        break
      }
      case 'CHANGE_CLIENT_URL':
        {
          const { idx, link } = action.value
          draft.testimonials[idx].clientUrl = link
        }
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      // case 'CHANGE_NAME_ALIGNMENT': {
      //   const { idx, alignment } = action.value
      //   draft.testimonials[idx].nameAlignment = alignment
      //   break
      // }
      // case 'CHANGE_POSITION_ALIGNMENT': {
      //   const { idx, alignment } = action.value
      //   draft.testimonials[idx].positionAlignment = alignment
      //   break
      // }
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.testimonials[idx].blockParagraphAlignment = alignment
        break
      }
    }
  })
}
