/* @flow */
import styled from 'styled-components'
import THEME from './Feature17.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '150px 0 0 0' : '0')};
  background-color: ${getStyleForProp('background')};
  font-size: 0;
  @media only screen and (max-width: 992px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 0 0' : '0')};
  }
`

const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const TextContent = styled.section`
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
  padding: 80px 0;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: initial;
    box-sizing: border-box;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding: 60px 0;
    padding-right: 20px;
    padding-left: 20px;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  font-weight: 400;
  margin-top: 20px;
`

const TopBlockWrapper = styled.div``

const BlockItemWrap = styled.div`
  width: 24%;
  flex-grow: 1;
  @media only screen and (max-width: 768px) {
    width: 45%;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

const AddButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 30px 0;
`

export default {
  Container,
  TextContent,
  Title,
  Description,
  BlockItemWrap,
  Img,
  TopBlockWrapper,
  ImagesContainer,
  AddButtonsWrap,
  ControlContainer
}
