import styled, { createGlobalStyle, css } from 'styled-components'
import _RemoveIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'

export const GlobalStyles = createGlobalStyle`
  .bg-transparency-control_content {
     & > div {
       &:before {
         content: '';
         width: 0;
         height: 0;
         border-top: 7px solid transparent;
         border-left: 8px solid #252E48;
         border-bottom: 7px solid transparent;
         position: absolute;
         right: -8px;
         top: 50%;
         transform: translateY(-50%);
       };
       
       background-color: #252E48;
       max-width: none;
       padding: 18px 10px;
       border-radius: 5px;
       p {
         color: #FFFFFF;
         font-size: 12px;
         font-weight: 500;
         flex-shrink: 0;
         margin-right: 10px;
       }
     }
  }
`

export const TooltipContent = styled.div`
  display: flex;
  align-items: center;
`

export const ControlsBar = styled.div`
  display: flex;
  align-items: center;
  transition: all 300ms ease;
  position: relative;

  & > div:first-child {
    flex-shrink: 0;
  }

  ${({ isExpanded }) =>
    isExpanded
      ? css`
          width: 155px;
          & > div:first-child {
            margin: 0 2px;
          }
        `
      : css`
          cursor: pointer;
          width: 45px;
          & > div:first-child {
            width: 45px;
            & > svg {
              fill: #545f7e;
            }
          }

          &:hover {
            & > div:first-child {
              & > svg {
                fill: #3271e6;
              }
            }
          }
        `}
`

export const Controls = styled.div`
  display: flex;
  height: 38px;
  align-items: center;
  overflow: hidden;

  & > div:not(:last-child) {
    margin-right: 7px;
  }
`

export const RemoveIcon = styled(_RemoveIcon)`
  width: 18px;
  height: auto;
  padding: 0 3px;
`
