/* @flow */
import React, { useContext, memo, useRef } from 'react'
import S from './Footer10.styled'
import { validation } from './Footer10.consts'
import ActionGetter from './Actions'
import Wave from './Footer_morph/Footer_morph'
import AnimatedWave from './Footer10_wave_animated/Footer10_wave_animated'
import Footer from '@website/common/components/Footer'
import Logo from '@website/common/components/Logo'
import EditableContent from '@website/common/components/EditableContent'
import {
  EditingContext,
  DispatchContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import SocialLinks from '@website/common/components/SocialLinks'
import { Controls } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'
import useOnScreen from '@website/common/hooks/useOnScreen'

const Footer10 = memo(props => {
  const {
    data: {
      copyrightText,
      socials,
      logoSrc,
      logoWidth,
      logoColor,
      logoDimensions,
      imgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      isAnimated
    }
  } = props
  const animatedRef = useRef(null)
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const isOnScreen = useOnScreen(animatedRef)
  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}
  const _privacyPolicyAction = Actions.privacyPolicyAction()

  return (
    <S.Container isEditing={isEditing}>
      <S.BackgroundImageBlock>
        <S.Background
          backgroundImgUrl={backgroundImgUrl}
          backgroundImgDimensions={backgroundImgDimensions}
          bgOverlayTransparency={bgOverlayTransparency}
        ></S.Background>
        <S.WaveWrapperAnimation
          isAnimated={isAnimated}
          ref={animatedRef}
          isOnScreen={isOnScreen}
          isEditing={isEditing}
        >
          <AnimatedWave />
        </S.WaveWrapperAnimation>
        <S.WaveWrapper>
          <Wave />
        </S.WaveWrapper>
      </S.BackgroundImageBlock>
      <S.FooterContainer className="WM_GLOBAL_secondary-font">
        <Footer />
        {isPrivacyPolicyVisible ||
        logoSrc ||
        copyrightText ||
        socials.length ? (
          <S.Line />
        ) : null}
        <S.FooterContentWrap>
          <S.FooterContent>
            <Logo
              logoSrc={logoSrc}
              logoWidth={logoWidth}
              logoColor={logoColor}
              imgDimensions={logoDimensions || imgDimensions}
            />
          </S.FooterContent>
          <S.CopyRightWrap isEditing={isEditing}>
            <EditableContent
              text={copyrightText}
              maxCount={validation.copyrightMaxChar}
              as={S.Copyright}
              onChange={Actions.changeCopyright}
              isPrivacyPolicyVisible={isPrivacyPolicyVisible}
            />
            {isPrivacyPolicyVisible ? (
              <S.LinkWrap isEditing={isEditing}>
                <Controls actions={_privacyPolicyAction}>
                  <LinkWrapper
                    text={privacyPolicyName}
                    url={privacyPolicyLink}
                    linkProps={{
                      className: 'privacy-policy-link WM_GLOBAL_secondary-font'
                    }}
                  />
                </Controls>
              </S.LinkWrap>
            ) : null}
          </S.CopyRightWrap>

          <SocialLinks
            className="socials.length"
            socials={socials}
            socialsMaxCount={validation.socials.max}
          />
        </S.FooterContentWrap>
      </S.FooterContainer>
    </S.Container>
  )
})

export default Footer10
