/* @flow */

module.exports = {
  id: 'Header9',
  isUnique: true,
  thumbnailRatio: 0.50366032,
  label: 'Header9',
  category: 'header',
  colorMode: 'DARK',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header9new-1_0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header9m-1.0.0.jpg',
  metaData: {
    backgroundImgUrl: {
      _type: 'backgroundImage',
      _dimensionsKey: 'backgroundImgDimensions',
      _optimizationSizes: [
        {
          _width: 400,
          _height: 800,
          _fit: 'cover'
        },
        {
          _width: 600,
          _height: 760,
          _fit: 'cover'
        },
        { _width: 1600 },
        { _width: 2200 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg'
    },
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg'
    },
    bgOverlayTransparency: 0,
    title: {
      _type: 'title',
      _min: 1,
      _max: 120,
      _value:
        'Want to see how would your website look like in different devices?'
    },
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 1500,
      _value:
        'With our platform it is pretty easy. Select the device, add your texts, images, all your website materials and preview it. You can make changes as much as you want. Give it a try!'
    },
    titleAlignment: 'left',
    paragraphAlignment: 'left',
    iframeVideoUrl: {
      _type: 'video',
      _min: 0,
      _max: 2048,
      _value: 'https://www.youtube.com/embed/8vD9Mciquhs?rel=0'
    },
    imgUrl: {
      _type: 'image',
      _dimensionsKey: 'imgUrlDimensions',
      _optimizationSizes: [
        { _width: 600 },
        { _width: 800 },
        { _width: 900 },
        { _width: 1000 }
      ],
      _min: 0,
      _max: 2048,
      _value: ''
    },
    imgUrlDimensions: {},
    logoDimensions: {},
    logoSrc: {
      _type: 'logo',
      _dimensionsKey: 'logoDimensions',
      _optimizationSizes: [
        { _width: 500 },
        { _width: 500 },
        { _width: 500 },
        { _width: 500 }
      ],
      _min: 0,
      _max: 2048,
      _value: ''
    },
    logoColor: 'default',
    buttons: {
      _type: 'list',
      _min: 0,
      _max: 2,
      _value: [
        {
          buttonText: {
            _type: 'buttonLabel',
            _min: 1,
            _max: 19,
            _value: 'GET STARTED'
          },
          buttonUrl: {
            _type: 'buttonUrl',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          type: 'primary'
        },
        {
          buttonText: {
            _type: 'buttonLabel',
            _min: 1,
            _max: 19,
            _value: 'LEARN MORE'
          },
          buttonUrl: {
            _type: 'buttonUrl',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          type: 'tertiary'
        }
      ]
    }
  },
  defaultProps: {
    backgroundImgUrl:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg',
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header9-img1-1_0.jpg'
    },
    bgOverlayTransparency: 0,
    title: 'Want to see how would your website look like in different devices?',
    paragraph:
      'With our platform it is pretty easy. Select the device, add your texts, images, all your website materials and preview it. You can make changes as much as you want. Give it a try!',
    titleAlignment: 'left',
    paragraphAlignment: 'left',
    iframeVideoUrl: 'https://www.youtube.com/embed/8vD9Mciquhs?rel=0',
    imgUrl: '',
    imgUrlDimensions: {},
    logoDimensions: {},
    logoSrc: '',
    logoColor: 'default',
    buttons: [
      {
        buttonText: 'GET STARTED',
        buttonUrl: 'http://builder.renderforestsites.com',
        type: 'primary'
      },
      {
        buttonText: 'LEARN MORE',
        buttonUrl: 'http://builder.renderforestsites.com',
        type: 'tertiary'
      }
    ]
  }
}
