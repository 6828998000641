// @flow
import React, { useRef, useState, useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { SectionComp } from '@editor/common/styled-components/simple-tags'
import { capitalizeFirstLetter } from '@src/editor/common/utils/text-transformation'

import * as Styled from './styled'
import { IconButton } from '@editor/common/styled-components/choose-component-template'
import type { TTemplatesThumbnailsProps } from './types'
import {
  SearchInput,
  TemplateThumbnail,
  MobileActiveCategory
} from './components'
import { RippleLoading } from '@editor/common/components/Loaders'
import FilterIcon from '@editor/common/assets/svgr-icons/filter_icon.svg'
import { useResponsive } from '@shared/hooks'
import useInfiniteScroll from '@hooks/useInfiniteScroll'
import {
  selectTemplates,
  selectNewestTemplates,
  selectTemplateSearchTerm,
  selectTemplatesActiveCategoryInfo
} from '@selectors'
import { fetchTemplates, fetchNewestTemplates } from '@actions/editor'

const TemplatesThumbnails = ({
  templates,
  newestTemplates,
  searchTerm,
  activeCategoryInfo,
  setMobileCategoriesState,
  fetchTemplates,
  fetchNewestTemplates
}: TTemplatesThumbnailsProps) => {
  const [isSearchSticky, setSearchStickyState] = useState(false)

  const topContentRef = useRef(null)
  const wrapperRef = useRef(null)
  const searchBarRef = useRef(null)
  const isMobileOrTablet = useResponsive(1024)

  const { payload: _templates, isLoading } = templates

  const _fetchTemplates = useCallback(() => {
    const { activeCategory, activeSubcategory } = activeCategoryInfo
    const categoryId = activeSubcategory.id || activeCategory.id

    activeCategory.id === 1
      ? fetchTemplates()
      : fetchTemplates('', null, categoryId)
  }, [fetchTemplates, activeCategoryInfo])

  useEffect(() => {
    _fetchTemplates()
    fetchNewestTemplates()
  }, [])

  useInfiniteScroll(wrapperRef, _fetchTemplates)

  const onScroll = useCallback(() => {
    const wrapperScrollTop = wrapperRef?.current.scrollTop
    const stickyOffsetTop = searchBarRef?.current.offsetTop
    if (wrapperScrollTop > stickyOffsetTop) {
      setSearchStickyState(true)
      return
    }

    setSearchStickyState(false)
  }, [wrapperRef, searchBarRef])

  const openMobileCategories = useCallback(() => {
    setMobileCategoriesState(true)
  }, [])

  const showSelectedSign =
    activeCategoryInfo.activeCategory.id !== 1 &&
    activeCategoryInfo.activeSubcategory

  const showNewestTemplates =
    searchTerm && newestTemplates && !isLoading && _templates.length <= 15

  return (
    <Styled.TemplatesWrapper
      id="templates-wrapper"
      ref={wrapperRef}
      onScroll={onScroll}
    >
      <Styled.Title ref={topContentRef}>
        {transformTextVariables(translate('select_any_of_templates_label'), [
          {
            value: (
              <Styled.GradientText>
                {translate('select_any_of_templates_label_tr1')}
              </Styled.GradientText>
            ),
            Comp: SectionComp
          }
        ])}
      </Styled.Title>
      <Styled.StickyElementWrapper isSticky={isSearchSticky}>
        {isMobileOrTablet ? (
          <Styled.MobileHeader>
            {capitalizeFirstLetter(translate('templates_label'))}
          </Styled.MobileHeader>
        ) : null}
        <Styled.StickyElement>
          <SearchInput />
          {isMobileOrTablet ? (
            <IconButton onClick={openMobileCategories} className="icon-button">
              <FilterIcon />
              {showSelectedSign ? (
                <>
                  <Styled.SelectedSign />
                  <Styled.SignLayer />
                </>
              ) : null}
            </IconButton>
          ) : null}
        </Styled.StickyElement>
      </Styled.StickyElementWrapper>
      <Styled.SearchBarWrapper ref={searchBarRef}>
        <Styled.SearchBar isHidden={isSearchSticky}>
          <SearchInput />
        </Styled.SearchBar>
      </Styled.SearchBarWrapper>
      <MobileActiveCategory />
      {!_templates.length && !isLoading ? (
        <Styled.NotFoundMessage>
          {translate('couldnt_match_search_label')}
        </Styled.NotFoundMessage>
      ) : (
        <Styled.TemplatesThumbnails hasLargePaddingFromTop={searchTerm}>
          <Styled.ThumbnailsList>
            {_templates.map((template, idx) => (
              <Styled.ThumbnailsListItem key={idx}>
                <TemplateThumbnail template={template} />
              </Styled.ThumbnailsListItem>
            ))}
          </Styled.ThumbnailsList>
          {isLoading ? (
            <Styled.LoaderWrapper isCentered={!_templates.length}>
              <RippleLoading color="#3270e6" />
            </Styled.LoaderWrapper>
          ) : null}
        </Styled.TemplatesThumbnails>
      )}
      {showNewestTemplates ? (
        <Styled.Suggestions>
          <Styled.SuggestionsTitle>
            {translate('discover_new_templates_label')}
          </Styled.SuggestionsTitle>
          <Styled.ThumbnailsList>
            {newestTemplates.map((template, idx) => (
              <Styled.ThumbnailsListItem key={idx}>
                <TemplateThumbnail template={template} />
              </Styled.ThumbnailsListItem>
            ))}
          </Styled.ThumbnailsList>
        </Styled.Suggestions>
      ) : null}
    </Styled.TemplatesWrapper>
  )
}

const mapStateToProps = state => ({
  templates: selectTemplates(state),
  newestTemplates: selectNewestTemplates(state),
  searchTerm: selectTemplateSearchTerm(state),
  activeCategoryInfo: selectTemplatesActiveCategoryInfo(state)
})

const mapDispatchToProps = {
  fetchTemplates,
  fetchNewestTemplates
}

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesThumbnails)
