//@flow
import React, { memo } from 'react'
import { translate } from '@editor/common/utils/translations'

import { Input } from '@editor/containers/edit/containers/settings/contents/shared-components'
import * as Styled from './styled'

const SearchInput = ({
  count,
  searchValue,
  onSearchChange,
  handleEnterPress,
  onRemoveIconClick
}) => {
  return (
    <Styled.InputContainer>
      <Input
        hideCount
        count={count}
        className="input-container"
        value={searchValue}
        placeholder={translate('enter_a_keyword_label')}
        iconName="search_tiny"
        borderColor="#C1C9E0"
        borderHoverColor="#8493BD"
        onChange={onSearchChange}
        onKeyPress={handleEnterPress}
        onRemoveIconClick={onRemoveIconClick}
      />
    </Styled.InputContainer>
  )
}

export default memo(SearchInput)
