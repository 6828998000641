/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text16.styled'
import ActionGetter from './Actions'
import { TEXT, IMAGE, validation } from './Text16.consts'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import If from '@website/common/components/Conditional'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Image from '@website/common/components/Image'
import { translate } from '@editor/common/utils/translations'

const Text16 = memo(
  ({
    data: {
      additionalBlockTitle,
      additionalBlockParagraph,
      blocks,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)

    const getBlocksNumberByType = type => {
      return blocks.filter(block => block.type === type).length
    }

    const textBlocksNumber = getBlocksNumberByType(TEXT)
    const imageBlocksNumber = getBlocksNumberByType(IMAGE)
    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
      >
        <S.WMCustomContainer isEditing={isEditing}>
          <Show when={[additionalBlockTitle, additionalBlockParagraph]}>
            <S.AdditionalBlockWrapper>
              <EditableContent
                text={additionalBlockTitle}
                as={S.AdditionalBlockTitle}
                isMarginBottom={additionalBlockParagraph !== '' || isEditing}
                maxCount={validation.additionalBlockTitleMaxChar}
                className="WM_GLOBAL_heading54"
                onChange={Actions.changeAdditionalBlockTitle}
              />
              <EditableContent
                text={additionalBlockParagraph}
                as={S.AdditionalBlockParagraph}
                maxCount={validation.additionalBlockParagraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeAdditionalBlockParagraph}
              />
            </S.AdditionalBlockWrapper>
          </Show>
          <S.BlocksContainer
            isExpandContent={
              !isEditing && !additionalBlockTitle && !additionalBlockParagraph
            }
          >
            <Mapper
              data={blocks}
              render={(block: string, idx: number) => {
                return (
                  <If
                    condition={block.type === TEXT}
                    otherwise={() => (
                      <Show when={[block.imageUrl]}>
                        <div>
                          <S.ImageWrapper>
                            <ControlsWithImageCropper
                              actions={Actions.imageActions(
                                idx,
                                block.imageUrl
                              )}
                              style={{ width: '100%' }}
                              key={idx}
                            >
                              <Image
                                as={S.Img}
                                sizes={block.imgDimensions}
                                defaultImgSrc={block.imageUrl}
                                asProps={{
                                  imgCropParams: block.imgCropParams
                                }}
                                alt="Block images"
                              />
                            </ControlsWithImageCropper>
                          </S.ImageWrapper>
                          <S.AddButtonsWrapper>
                            <AddButton
                              onAdd={() => Actions.addBlock(idx)}
                              toShow={
                                textBlocksNumber < validation.blockText.max
                              }
                              style={{
                                margin: '15px 30px 15px 0'
                              }}
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                              {translate('add_block_label')}
                            </AddButton>
                            <AddButton
                              onAdd={() => Actions.addImageBlock(idx)}
                              toShow={
                                imageBlocksNumber < validation.blockImage.max
                              }
                              style={{
                                margin: '15px 0'
                              }}
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                              {translate('add_image_label')}
                            </AddButton>
                          </S.AddButtonsWrapper>
                        </div>
                      </Show>
                    )}
                    then={() => (
                      <Show when={[block.title, block.paragraph]}>
                        <div>
                          <S.ControlContainer>
                            <ControlsWithModalOpener
                              actions={Actions.blockActions({
                                idx,
                                showRemove:
                                  textBlocksNumber > validation.blockText.min
                              })}
                            >
                              <S.Block isEditing={isEditing}>
                                <EditableContent
                                  text={block.title}
                                  alignment={block.titleAlignment}
                                  as={S.BlockTitle}
                                  required={!block.paragraph}
                                  maxCount={validation.blockTitleMaxChar}
                                  className="WM_GLOBAL_heading42"
                                  onChange={(newTitle: string) =>
                                    Actions.changeBlockTitle(newTitle, idx)
                                  }
                                  changeAlignment={(alignment: string) =>
                                    Actions.changeBlockTitleAlignment(
                                      alignment,
                                      idx
                                    )
                                  }
                                />
                                <EditableContent
                                  text={block.paragraph}
                                  alignment={block.paragraphAlignment}
                                  as={S.BlockParagraph}
                                  maxCount={validation.blockParagraphMaxChar}
                                  required={!block.title}
                                  className="WM_GLOBAL_paragraph18"
                                  onChange={(newParagraph: string) =>
                                    Actions.changeBlockParagraph(
                                      newParagraph,
                                      idx
                                    )
                                  }
                                  changeAlignment={(alignment: string) =>
                                    Actions.changeBlockParagraphAlignment(
                                      alignment,
                                      idx
                                    )
                                  }
                                />
                              </S.Block>
                            </ControlsWithModalOpener>
                          </S.ControlContainer>
                          <S.AddButtonsWrapper>
                            <AddButton
                              onAdd={() => Actions.addBlock(idx)}
                              toShow={
                                textBlocksNumber < validation.blockText.max
                              }
                              style={{
                                margin: '0 30px 15px 0'
                              }}
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                              {translate('add_block_label')}
                            </AddButton>
                            <AddButton
                              onAdd={() => Actions.addImageBlock(idx)}
                              toShow={
                                imageBlocksNumber < validation.blockImage.max
                              }
                              style={{
                                marginBottom: '15px'
                              }}
                            >
                              <Icon
                                name="glyph-add"
                                className="icon"
                                size="normal"
                              />
                              {translate('add_image_label')}
                            </AddButton>
                          </S.AddButtonsWrapper>
                        </div>
                      </Show>
                    )}
                  />
                )
              }}
            />
          </S.BlocksContainer>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

export default Text16
