import styled, { css } from 'styled-components'

import Button from '@editor/common/lib/Button'

export const PreviewButton = styled(Button)`
  flex-grow: 1;
  color: #387dff;
  background: white;
  min-width: 60px;
  min-height: 40px;
  font-weight: 600;
  padding: 11px;

  svg {
    fill: #545f7e;
    margin-top: -3px;
  }
  &:hover {
    background: #eef5ff;
    transition: all 0.2s ease-out;
  }
  @media only screen and (max-width: 850px) {
    background: #eef5ff;
    margin: 0 10px;
  }
`

export const SmallPreviewButton = styled(PreviewButton)`
  ${({ isUserAuthorized }) =>
    isUserAuthorized
      ? css`
          width: 75px;
          flex-grow: 0;
        `
      : ''}
`

export const CompContent = styled.div`
  line-height: 0;
  visibility: ${({ isShown }) => (isShown ? 'visible' : 'hidden')};
`
