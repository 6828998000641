/* @flow */

export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  clientsLogos: {
    min: 1,
    max: 20
  }
}
