import {
  MessengerIcon,
  WhatsAppIcon,
  ViberIcon,
  MessengerLogo,
  WhatsappLogo,
  TelegramLogo,
  ViberLogo
} from './Icons'

export const AVATAR_DEFAULT_SRC =
  'https://static.rfstat.com/renderforest/images/website_maker_images/avatar_holder.png'

export const MESSENGER_DEFAULT_PROPS = {
  type: 'facebook',
  avatar: '',
  name: 'David',
  subtitle: '',
  message: 'Hi there 👋\n How can I help you?',
  buttonText: 'Start Chat',
  buttonUrl: 'https://www.messenger.com/t/testpagesapp'
}

export const BUTTON_ICONS = {
  facebook: MessengerIcon,
  whatsapp: WhatsAppIcon,
  telegram: TelegramLogo,
  viber: ViberIcon
}

export const OPENER_BUTTON_ICONS = {
  facebook: MessengerLogo,
  whatsapp: WhatsappLogo,
  telegram: TelegramLogo,
  viber: ViberLogo
}

export const OPENER_BUTTON_STYLES = {
  facebook: {
    background: '#ffffff'
  },
  whatsapp: {
    background: '#25D366'
  },
  telegram: {
    background: '#0088cc'
  },
  viber: {
    background: '#7360f2'
  }
}

export const MESSENGER_STYLES = {
  facebook: {
    headerBackground: '#fafafa',
    headerTextColor: '#1d2129',
    contentBackground: '#ffffff',
    footerBackground: '#ffffff',
    buttonRadius: '8px',
    buttonTextColor: '#ffffff',
    buttonBackground: '#0284fe',
    closeIconColor: 'secondarySemiDark'
  },
  whatsapp: {
    headerBackground: '#0a5f54',
    headerTextColor: '#c9dbd8',
    contentBackground:
      'url(https://static.rfstat.com/renderforest/images/website_maker_images/whatsapp-background.jpg)',
    footerBackground: '#ffffff',
    buttonRadius: '20px',
    buttonTextColor: '#ffffff',
    buttonBackground: '#19c656',
    closeIconColor: '#C1C9E0'
  },
  telegram: {
    headerBackground: '#0388ce',
    headerTextColor: '#c9dbd8',
    contentBackground:
      'url(https://static.rfstat.com/renderforest/images/website_maker_images/telegram-background.jpg)',
    footerBackground: '#ffffff',
    buttonRadius: '20px',
    buttonTextColor: '#ffffff',
    buttonBackground: '#0388ce',
    closeIconColor: '#C1C9E0'
  },
  viber: {
    headerBackground: '#fafafa',
    headerTextColor: '#393939',
    contentBackground: '#eeeeee',
    footerBackground: '#eeeeee',
    buttonRadius: '8px',
    buttonTextColor: '#ffffff',
    buttonBackground: '#675ab0',
    closeIconColor: '#545F7E'
  }
}
