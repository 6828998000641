//@flow
import React, { memo } from 'react'

import * as Styled from './styled'
import type { TDropdownProps } from './types'

const Dropdown = ({
  label,
  list,
  activeItem,
  isDisabled,
  isSearchDropdown,
  onItemClick
}: TDropdownProps) => {
  const commonProps = {
    activeItem,
    dropdownList: list,
    onListItemClick: onItemClick,
    dropdownTogglerIcon: 'arrow_filled_bottom',
    isActiveItemWithCheckmark: false,
    closeDropDownBySelecting: true,
    listItemHeight: 40,
    dropdownWidth: '100%',
    isDisabled,
    listMaxHeight: 120
  }

  return (
    <Styled.DropdownWrapper>
      <Styled.DropdownLabel>{label}</Styled.DropdownLabel>
      {isSearchDropdown ? (
        <Styled.SearchDropdown {...commonProps} />
      ) : (
        <Styled.Dropdown {...commonProps} />
      )}
    </Styled.DropdownWrapper>
  )
}

export default memo(Dropdown)
