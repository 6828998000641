import styled from 'styled-components'

export const ItemWrapper = styled.div`
  display: flex !important;
  align-items: center;
  justify-content: center;
`

export const Item = styled.div`
  width: 100%;
  display: flex;
  .control-container {
    width: 100%;
  }
`

export const ArrowWrapper = styled.div`
  display: flex !important;
  width: 42px;
  min-width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  background-color: #ffffff;
  border: 1px solid #c1c9e0;
  box-shadow: 0 6px 12px 0 #3271e626;
  z-index: 5;

  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: #545f7e;
  }

  &:hover {
    background-color: #ffffff;
    svg {
      fill: #252e48;
    }
  }
`
