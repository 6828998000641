import styled from 'styled-components'
import { styleProvider, hexToRgb } from '../../utils'
const getStyleForProp = styleProvider({})

const Label = styled.label`
  display: flex;
  position: relative;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.tertiaryDark};
  width: 150px;
  word-break: break-word;
  padding-left: 30px;
  &:before {
    content: '';
    -webkit-appearance: none;
    background-color: #fff;
    width: 20px;
    min-width: 20px;
    height: 20px;
    box-sizing: border-box;
    border: 1px solid
      ${({ theme }) => `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    vertical-align: middle;
    cursor: pointer;
    margin-right: 10px;
    border-radius: ${({ theme }) => (theme.look === 'SHARP' ? '0' : '5px')};
  }
  @media only screen and (max-width: 457px) {
    width: 100%;
  }
`

const CheckboxWrap = styled.div`
  display: block;
  margin-bottom: 15px;
  ${Label} {
    font-weight: 400;
  }
`

const Input = styled.input`
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
  &:checked {
    + ${Label} {
      &:before {
        ${getStyleForProp('buttonBackground')};
        border: none;
      }
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 6px);
        left: 7.5px;
        width: 4px;
        height: 8px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
`

export default {
  CheckboxWrap,
  Input,
  Label
}
