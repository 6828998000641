export default {
  DARK: {
    heading: p => p.accentWhite,
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.tertiaryDark,
    additionalBlockParagraph: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryDark,
    cardBackground: p => p.accentWhite,
    border: p => p.primaryDark,
    lightBackground: p => p.tertiaryExtraLight
  },
  LIGHT: {
    heading: p => p.primaryDark,
    blockTitle: p => p.primaryDark,
    blockParagraph: p => p.tertiaryDark,
    additionalBlockParagraph: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryLight,
    cardBackground: p => p.accentWhite,
    border: p => p.primaryDark,
    lightBackground: p => p.tertiaryExtraLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.tertiaryDark,
    additionalBlockParagraph: p => p.tertiaryDark,
    backgroundColor: p => p.tertiaryExtraLight,
    cardBackground: p => p.tertiaryLight,
    border: p => p.primaryDark,
    lightBackground: p => p.tertiaryExtraLight
  }
}
