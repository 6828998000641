//@flow
import React, { memo } from 'react'

import * as Styled from './styled'

type TProps = {
  cardData: {
    title: string,
    imageSrc: string,
    value: string,
    fontStyle: string
  },
  isActive: boolean,
  setStyle: () => void
}

const Card = ({ cardData, isActive, setStyle }: TProps) => {
  const { title, imageSrc, value, fontStyle } = cardData

  return (
    <Styled.Card isActive={isActive} onClick={() => setStyle(value)}>
      <Styled.Title fontStyle={fontStyle}>{title}</Styled.Title>
      <Styled.Image src={imageSrc} alt={title} />
    </Styled.Card>
  )
}

export default memo(Card)
