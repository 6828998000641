/* @flow */
import { imageModalActionMaker } from '@website/components/ActionMakers'

import {
  changeParagraph,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const addImage = () => {
    dispatcher({
      type: 'ADD_IMAGE'
    })
  }


  return {
    changeParagraph: _changeParagraph,
    imageActions: imageModalActionMaker(dispatcher),
    changeParagraphAlignment: _changeParagraphAlignment,
    addImage
  }
}
