/* @flow */
import {
  cropableImageModalActionMaker,
  imageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeSubTitle,
  changeTitleAlignment
} from '@website/components/CommonActions'

export default dispatch => {
  const changeParagraph1 = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH1',
      value: newParagraph
    })
  }

  const changeParagraph2 = (newParagraph: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH2',
      value: newParagraph
    })
  }

  const changeBottomBlockTitle = (newBottomBlockTitle: string) => {
    dispatch({
      type: 'CHANGE_BOTTOM_BLOCK_TITLE',
      value: newBottomBlockTitle
    })
  }

  const changeBottomBlockParagraph = (newBottomBlockParagraph: string) => {
    dispatch({
      type: 'CHANGE_BOTTOM_BLOCK_PARAGRAPH',
      value: newBottomBlockParagraph
    })
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeSubTitle = newSubTitle => {
    dispatch(changeSubTitle(newSubTitle))
  }

  const addSmallImage = () => {
    dispatch({
      type: 'ADD_SMALL_IMAGE'
    })
  }

  const smallImageActions = image => {
    const actions = {
      change: ({ url, dimensions, undoable, sizes }) => ({
        type: 'CHANGE_SMALL_IMAGE',
        value: { url, dimensions, sizes },
        undoable
      }),
      remove: () => ({
        type: 'REMOVE_SMALL_IMAGE'
      })
    }
    return imageModalActionMaker(dispatch)({
      showRemove: true,
      withImgSizes: true,
      keyForSizes: 'team9SmallImg',
      actions,
      image
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraph1Alignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH1_ALIGNMENT',
      value: alignment
    })
  }

  const changeParagraph2Alignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_PARAGRAPH2_ALIGNMENT',
      value: alignment
    })
  }

  const changeBottomBlockTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_BOTTOM_BLOCK_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeBottomBlockParagraphAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_BOTTOM_BLOCK_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  return {
    changeTitle: _changeTitle,
    changeSubTitle: _changeSubTitle,
    changeParagraph1,
    changeParagraph2,
    changeBottomBlockTitle,
    changeBottomBlockParagraph,
    addSmallImage,
    getLargeImgActions: cropableImageModalActionMaker(dispatch),
    smallImageActions,
    changeTitleAlignment: _changeTitleAlignment,
    changeSubTitleAlignment,
    changeParagraph1Alignment,
    changeParagraph2Alignment,
    changeBottomBlockTitleAlignment,
    changeBottomBlockParagraphAlignment
  }
}
