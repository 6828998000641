//@flow

import React from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import { HelpAndSupportButton, UpgradeButton } from './components'
import UndoRedo from './UndoRedo'
import UserDropdown from './UserDropdown'
import PagesDropdown from './PagesDropdown'
import Buttons from './Buttons'
import MobileMenu from './MobileMenu'
import { LanguagesDropdown, MakeMultilingualBtn } from './MultilingualSection'

import LogoExpanded from '@editor/common/assets/RF_color_logo.svg'
import LogoCollapsed from '@editor/common/assets/RF_color_logo_collapse.svg'

import {
  isSubscriber,
  selectIsUserAuthorized,
  selectSidebarActiveItem,
  selectIsWebsiteMultilingual,
  selectSemrushSidebarVisibilityState
} from '@selectors'
import { openLoginPopup } from '@actions/editor'

import { useResponsive } from '@shared/hooks'
import { isOpenedWithHash } from '@editor/common/utils'
import { getSemrushDependedScreenBreakpoint } from '@editor/common/utils/styled'
import { getLocalizedURL } from '@editor/common/utils/translations'

import type {
  THeaderProps,
  TDesktopHeaderProps,
  TMobileHeaderProps
} from './types'

const PATH = window.CURRENT_HOST || window.location.origin
const LANGUAGE = window.CURRENT_LANGUAGE || 'en'
const HOMEPAGE_TEMPLATES_PATH = getLocalizedURL('/home/templates/websites')

const HeaderDesktop = ({
  isLogoExpanded,
  isUserAuthorized,
  isUserSubscriber,
  isWebsiteMultilingual,
  isPublishButtonVisible,
  isSemrushSidebarVisible
}: TDesktopHeaderProps) => {
  const screenBreakpoint = getSemrushDependedScreenBreakpoint(
    1024,
    isSemrushSidebarVisible
  )
  const isTablet = useResponsive(screenBreakpoint)
  const isLogoCollapsed = isTablet || !isLogoExpanded

  return (
    <Styled.HeaderWithLogo>
      <Styled.LogoContainer
        isExpanded={!isLogoCollapsed}
        href={`${PATH}/${LANGUAGE}/website-maker`}
        data-auth-href={HOMEPAGE_TEMPLATES_PATH}
      >
        <img src={isLogoCollapsed ? LogoCollapsed : LogoExpanded} alt="Logo" />
      </Styled.LogoContainer>
      <Styled.HeaderContainer>
        <Styled.LeftSection>
          <PagesDropdown />
          {isWebsiteMultilingual ? (
            <LanguagesDropdown />
          ) : (
            <MakeMultilingualBtn />
          )}
          <UndoRedo />
        </Styled.LeftSection>
        {/*<Styled.CenterSection>*/}
        {/*  <RenameInput />*/}
        {/*</Styled.CenterSection>*/}
        <Styled.RightSection>
          <Buttons isPublishButtonVisible={isPublishButtonVisible} />
          <Styled.Divider />
          {!isUserSubscriber ? (
            <>
              <UpgradeButton />
              <Styled.Divider />
            </>
          ) : null}
          {isOpenedWithHash() ? (
            <HelpAndSupportButton />
          ) : (
            <UserDropdown isUserAuthorized={isUserAuthorized} />
          )}
        </Styled.RightSection>
      </Styled.HeaderContainer>
    </Styled.HeaderWithLogo>
  )
}

const HeaderMobile = ({
  isPublishButtonVisible,
  isWebsiteMultilingual
}: TMobileHeaderProps) => {
  return (
    <Styled.MobileHeader>
      <Styled.MobileTopSection>
        <Styled.LogoContainer
          href={`${PATH}/${LANGUAGE}/website-maker`}
          data-auth-href={HOMEPAGE_TEMPLATES_PATH}
        >
          <img src={LogoCollapsed} alt="Logo" />
        </Styled.LogoContainer>
        <Buttons isPublishButtonVisible={isPublishButtonVisible} />
        <Styled.Menu>
          <MobileMenu />
        </Styled.Menu>
      </Styled.MobileTopSection>
      <Styled.MobileBottomSection>
        <PagesDropdown />
        {isWebsiteMultilingual ? (
          <LanguagesDropdown />
        ) : (
          <MakeMultilingualBtn />
        )}
        <UndoRedo />
      </Styled.MobileBottomSection>
    </Styled.MobileHeader>
  )
}

const Header = ({
  isLogoExpanded,
  isUserAuthorized,
  isUserSubscriber,
  isWebsiteMultilingual,
  hasChangesAfterPublish,
  isSemrushSidebarVisible,
  openLoginPopup
}: THeaderProps) => {
  const screenBreakpoint = getSemrushDependedScreenBreakpoint(
    800,
    isSemrushSidebarVisible
  )

  const isMobile = useResponsive(screenBreakpoint)
  const isPublishButtonVisible = isUserAuthorized && !isOpenedWithHash()

  return isMobile ? (
    <HeaderMobile
      isPublishButtonVisible={isPublishButtonVisible}
      isWebsiteMultilingual={isWebsiteMultilingual}
      hasChangesAfterPublish={hasChangesAfterPublish}
    />
  ) : (
    <HeaderDesktop
      isLogoExpanded={isLogoExpanded}
      isUserAuthorized={isUserAuthorized}
      isUserSubscriber={isUserSubscriber}
      isWebsiteMultilingual={isWebsiteMultilingual}
      isPublishButtonVisible={isPublishButtonVisible}
      hasChangesAfterPublish={hasChangesAfterPublish}
      isSemrushSidebarVisible={isSemrushSidebarVisible}
      openLoginPopup={openLoginPopup}
    />
  )
}

const mapStateToProps = state => ({
  sidebarActiveItem: selectSidebarActiveItem(state),
  isUserAuthorized: selectIsUserAuthorized(state),
  isUserSubscriber: isSubscriber(state),
  isWebsiteMultilingual: selectIsWebsiteMultilingual(state),
  isSemrushSidebarVisible: selectSemrushSidebarVisibilityState(state)
})

export default connect(mapStateToProps, { openLoginPopup })(Header)
