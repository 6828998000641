// @flow

// Button actions
export const changeButtonData = (
  btnIdx: number,
  btnData,
  additionalProps = {}
) => ({
  type: 'CHANGE_BUTTON_DATA',
  value: { btnIdx, btnData, ...additionalProps }
})

export const removeButton = (btnIdx: string, additionalProps = {}) => ({
  type: 'REMOVE_BUTTON',
  value: btnIdx,
  additionalProps
})

export const addButton = idx => ({ type: 'ADD_BUTTON', value: idx })
// END OF Button actions

// Client logo actions
export const changeClientLogo = ({
  url,
  idx,
  imgDimensions,
  sizes,
  undoable
}) => ({
  type: 'CHANGE_CLIENT_LOGO',
  value: { url, imgDimensions, idx, sizes },
  undoable
})

export const removeClientLogo = (logoIdx: number) => ({
  type: 'REMOVE_CLIENT_LOGO',
  value: logoIdx
})
// END OF Client logo actions

export const changeSocialUrl = (
  idx: number,
  link: string,
  additionalArgs: Object = {}
) => ({
  type: 'CHANGE_SOCIAL_URL',
  value: { idx, link, ...additionalArgs }
})

export const removeSocial = (idx: number, additionalArgs: Object = {}) => ({
  type: 'REMOVE_SOCIAL',
  value: { idx, ...additionalArgs }
})

export const changeSocialIcon = (
  idx: number,
  iconName: string,
  additionalArgs: Object = {}
) => ({
  type: 'CHANGE_SOCIAL_ICON',
  value: { iconName, idx, ...additionalArgs }
})

export const changeButtonText = value => ({
  type: 'CHANGE_BUTTON_TEXT',
  value
})

export const removeNavigationLink = (
  idx: number,
  fromComp: 'header' | 'footer'
) => ({
  type: `REMOVE_NAVIGATION_LINK`,
  value: { idx, fromComp },
  undoable: true
})

export const editNavigationLink = (
  idx: number,
  linkData: Object,
  fromComp: 'header' | 'footer'
) => ({
  type: 'EDIT_NAVIGATION_LINK',
  value: { idx, linkData, fromComp }
})

//Subscribtion plan  actions

export const changePrimaryPlan = (idx: number) => ({
  type: 'CHANGE_PRIMARY_PLAN',
  value: idx
})

export const removeSubscriptionCard = (idx: number) => ({
  type: 'REMOVE_SUBSCRIBTION_CARD',
  value: idx
})

//End of Subscribtion plan

// Mockup actions
export const changeMockupBackground = ({ undoable, ...rest }) => {
  return {
    type: 'CHANGE_MOCKUP_BACKGROUND',
    value: rest,
    undoable
  }
}

export const removeMockupBackground = (keyInStore?: string) => ({
  type: 'REMOVE_MOCKUP_BACKGROUND',
  value: keyInStore
})
// END OF Mockup actions

export const changeMapData = (value: Object) => ({
  type: 'CHANGE_MAP_DATA',
  value
})

// Video actions
export const changeVideoUrl = (url: string, idx?: number) => ({
  type: 'CHANGE_VIDEO_URL',
  value: { url, idx }
})

export const removeVideoUrl = (idx: number) => ({
  type: 'REMOVE_VIDEO',
  value: { idx }
})
// END OF Video actions

// Image actions

export const changeImage = ({ undoable, ...rest }) => ({
  type: 'CHANGE_IMAGE',
  value: rest,
  undoable
})

export const removeImage = (value: Object) => ({
  type: 'REMOVE_IMAGE',
  value
})

// END OF Image actions

//Link actions

export const changeLinkData = (linkIdx, linkData) => ({
  type: 'CHANGE_LINK_DATA',
  value: { linkIdx, linkData }
})

export const removeLink = (idx: number) => ({
  type: 'REMOVE_LINK',
  value: { idx }
})

//End of link actions
export const changeIcon = (idx, iconName) => ({
  type: 'CHANGE_ICON',
  value: { idx, iconName }
})

export const removeIcon = idx => ({
  type: 'REMOVE_ICON',
  value: { idx }
})

//Navbar logo actions
export const removeNavbarLogo = () => ({
  type: 'REMOVE_NAVBAR_LOGO'
})

export const changeNavbarLogo = ({ undoable, ...rest }) => ({
  type: 'CHANGE_NAVBAR_LOGO',
  value: rest,
  undoable
})

export const logoResizeAction = value => ({
  type: 'RESIZE_LOGO',
  value
})

//End of navbar logo actions

// Other Simple actions
export const changeTitle = (newTitle: string) => ({
  type: 'CHANGE_TITLE',
  value: newTitle
})

export const changeSubTitle = (newSubTitle: string) => ({
  type: 'CHANGE_SUBTITLE',
  value: newSubTitle
})

export const changeParagraph = (newParagraph: string) => ({
  type: 'CHANGE_PARAGRAPH',
  value: newParagraph
})

export const changeMockupColor = () => ({
  type: 'CHANGE_MOCKUP_COLOR'
})

export const makeMockupLight = () => ({
  type: 'MAKE_MOCKUP_LIGHT'
})

export const makeMockupDark = () => ({
  type: 'MAKE_MOCKUP_DARK'
})

export const changeBlockTitle = (newTitle: string, idx) => ({
  type: 'CHANGE_BLOCK_TITLE',
  value: { newTitle, idx }
})

export const changeBlockParagraph = (newParagraph: string, idx) => ({
  type: 'CHANGE_BLOCK_PARAGRAPH',
  value: { newParagraph, idx }
})

export const changeDescription = (newDescription: string) => ({
  type: 'CHANGE_DESCRIPTION',
  value: newDescription
})

export const addLogo = () => ({ type: 'ADD_LOGO' })

export const addSocial = () => ({ type: 'ADD_SOCIAL' })

export const addLink = idx => ({ type: 'ADD_LINK', value: { idx } })

export const addRow = () => ({ type: 'ADD_ROW' })

export const changeCopyright = (newValue: string) => ({
  type: 'CHANGE_COPYRIGHT',
  value: newValue
})

export const changePlaceHolderText = value => ({
  type: 'CHANGE_PLACEHOLDER_TEXT',
  value
})

export const addIcon = (idx: number) => ({
  type: 'ADD_ICON',
  value: idx
})

export const addBlock = () => ({
  type: 'ADD_BLOCK'
})

export const removeBlock = (idx: number) => ({
  type: 'REMOVE_BLOCK',
  value: { idx }
})

export const changeMusicUrl = (url, idx) => ({
  type: 'CHANGE_MUSIC_URL',
  value: { url, idx }
})

export const removeMusicUrl = idx => ({
  type: 'REMOVE_MUSIC_URL',
  value: idx
})

export const setImgCropParams = ({
  imgCropParams,
  undoable = false,
  ...rest
}) => ({
  type: 'SET_IMG_CROP_PARAMS',
  value: { imgCropParams, ...rest },
  undoable: undoable
})

export const changeTitleAlignment = (alignment: string) => ({
  type: 'CHANGE_TITLE_ALIGNMENT',
  value: alignment
})

export const changeParagraphAlignment = (alignment: string) => ({
  type: 'CHANGE_PARAGRAPH_ALIGNMENT',
  value: alignment
})

export const changeClientUrl = (idx, link) => ({
  type: 'CHANGE_CLIENT_URL',
  value: { idx, link }
})

export const changePayPalButtonData = (btnData, reducerProps) => ({
  type: 'CHANGE_PAYPAL_BUTTON_DATA',
  value: { btnData, ...reducerProps }
})

export const changeCardBgOverlayTransparency = (value: number) => ({
  type: 'CHANGE_CARD_BG_OVERLAY_TRANSPARENCY',
  value,
  undoable: true
})
