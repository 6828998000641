import React, { useContext, memo, useMemo } from 'react'
import S from './Clients4.styled'
import meta from './Clients4.meta'
import { validation } from './Clients4.consts'
import ActionGetter from './Actions'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Image from '@website/common/components/Image'
import Icon from '@website/common/components/Icon'
import AddButton from '@website/common/components/AddButton'
import Slider from 'react-slick'
import '../Swipe/swipe-slider-styles.css'
import { DispatchContext, EditingContext } from '@contexts'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { getSliderSettings } from './utils'
import { translate } from '@editor/common/utils/translations'
import { openUrl, getExternalUrl } from '@website/common/utils'

const Clients4 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      slides,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const showRemove = slides.length > validation.slides.min

    const slickSliderSettings = useMemo(
      () => getSliderSettings(isEditing, slides.length),
      [isEditing, slides.length]
    )

    return (
      <Show when={[title, paragraph]}>
        <S.StyledContainer
          isEditing={isEditing}
          backgroundImgUrl={backgroundImgUrl}
          backgroundImgDimensions={backgroundImgDimensions}
          bgOverlayTransparency={bgOverlayTransparency}
        >
          <S.WMCustomContainer isEditing={isEditing}>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              required={!paragraph}
              maxCount={validation.maxTitleChar}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              required={!title}
              maxCount={validation.maxParagraphChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.WMCustomContainer>
          <S.WMSliderWrapper slidesCount={slides.length} isEditing={isEditing}>
            {slides.length > 0 && (
              <Slider {...slickSliderSettings}>
                {slides.map((slide, idx) => (
                  <S.ImageFigure kay={idx}>
                    <ControlsWithModalOpener
                      actions={Actions.getClientsActions({
                        showRemove,
                        logoIdx: idx,
                        keyForSizes: 'clientsLogos',
                        imageUrl: slide.imageUrl,
                        link: slide.clientUrl,
                        withImgSizes: false
                      })}
                      key={idx}
                    >
                      <S.InnerSliderItem>
                        <Image
                          withLazy={false}
                          as={S.Img}
                          alt="company img"
                          defaultImgSrc={slide.imageUrl}
                          sizes={slide.imgDimensions}
                          asProps={{
                            onClick: () =>
                              !!slide.clientUrl &&
                              openUrl(getExternalUrl(slide.clientUrl))
                          }}
                          hasUrl={!!slide.clientUrl}
                        />
                      </S.InnerSliderItem>
                    </ControlsWithModalOpener>
                  </S.ImageFigure>
                ))}
              </Slider>
            )}
            <AddButton
              onAdd={Actions.addSlide}
              toShow={slides.length < validation.slides.max}
            >
              <Icon name="glyph-add" className="icon" size="normal" />
              {translate('add_logo_label')}
            </AddButton>
          </S.WMSliderWrapper>
        </S.StyledContainer>
      </Show>
    )
  }
)

export default Clients4
