/* @flow */
import styled from 'styled-components'
import THEME from './Text6.theme'
import LibIcon from '@website/common/components/Icon'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
`

const WMCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  .link-section {
    margin-top: 20px;

    .link {
      color: ${getStyleForProp('anchor')};
    }
  }

  .arrow-right {
    svg {
      fill: ${getStyleForProp('anchor')};
    }
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
  margin: 30px 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin-top: 0;
  width: 100%;
  line-height: 1.4;
`

const TextCompWrap = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  border: ${getStyleForProp('border')};
  width: 100%;
  max-width: 1200px;
  padding: 36px;
  box-sizing: border-box;
  text-align: center;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  word-break: break-word;
`

const Circle = styled.div`
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${getStyleForProp('buttonBackground')};
  border-radius: ${getStyleForProp('iconRadius')};
  pointer-events: none;
`

const IconWrap = styled.div`
  user-select: none;
`
const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: getStyleForProp('anchor')({ theme })
}))`
  margin-left: 0.5rem;
`
export default {
  StyledContainer,
  WMCustomContainer,
  Title,
  Description,
  TextCompWrap,
  Circle,
  IconWrap,
  Icon
}
