export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockTitleMaxChar: 80,
  checkmarkMaxChar: 60,
  featureBlocks: {
    min: 1,
    max: 10
  },
  featureList: {
    min: 0,
    max: 20
  },
  buttons: {
    min: 0,
    max: 2
  }
}
