/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Steps9.theme'
import { styleProvider, getBackgroundStyles, getTextStyleByAlignment } from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          padding-top: 140px;
        `
      : css``};
  @media only screen and (max-width: 700px) {
    ${({ isEditing }) =>
    isEditing
      ? css`
            padding-top: 80px;
          `
      : css``};
  }
`

const WMCustomContainer = styled(WMContainer)`
  @media only screen and (min-width: 1025px) {
    width: 95%;
    max-width: 1250px;
  }
  .add-button-wrap {
    margin-top: 10px;
  }
`

const BlockItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 350px;
  margin: 0 25px 50px 25px;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
    &:not(:last-child) {
      border-bottom: 1px solid ${getStyleForProp('line')};
      padding-bottom: 20px;
    }
    &:not(:first-child) {
      padding-top: 20px;
    }
  }
  .control-container {
    margin-bottom: 20px;
    p, h3  {
      margin-bottom: 0;
    }
  }
  > h3:first-child,
  > p:first-child,
  > div:first-child
   {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .control-container {
    align-items: initial !important;
  }
  h2 {
    margin-bottom: 30px;
    @media only screen and (max-width: 457px) {
      margin-bottom: 20px;
    }
  }
`

const BlockTitle = styled.h3`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
`

const Order = styled.h2`
  color: ${getStyleForProp('order')};
  position: relative;
  width: 100%;
  @media only screen and (min-width: 1025px) {
    &:after {
      content: '';
      position: absolute;
      width: 225px;
      height: 2px;
      background-color: ${getStyleForProp('line')};
      right: 0;
      top: 50%;
    }
  }
  @media only screen and (max-width: 1024px) {
    font-size: 42px;
  }
`

const BlocksWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  margin-right: auto;
  margin-left: auto;
  @media only screen and (min-width: 1025px) {
    max-width: ${({ areFourBlock }) => (areFourBlock ? '1000px' : '')};
    margin-bottom: -50px;
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
  }
`

export default {
  StyledContainer,
  WMCustomContainer,
  BlockItemWrap,
  BlockTitle,
  BlockParagraph,
  BlocksWrap,
  Order
}
