/* @flow */
import {
  buttonModalActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addButton
} from '@website/components/CommonActions'

export default dispatcher => {
  const removeSlide = slideIdx =>
    dispatcher({
      type: 'REMOVE_SLIDE',
      value: { slideIdx }
    })

  const addSlide = () =>
    dispatcher({
      type: 'ADD_SLIDE'
    })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const _addButton = () => dispatcher(addButton())

  return {
    addSlide,
    removeSlide,
    addButton: _addButton,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    getSliderActions: cropableImageModalActionMaker(dispatcher),
    getBtnActions: buttonModalActionMaker(dispatcher)
  }
}
