/* @flow */
import React, { useContext, memo } from 'react'
import S from './PrivacyPolicy1.styled'
import { validation } from './PrivacyPolicy1.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext, EditingContext } from '@contexts'
import { translate } from '@editor/common/utils/translations'

const PrivacyPolicy1 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      subtitle,
      subtitleAlignment,
      content,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer>
        <Show when={[title, subtitle]}>
          <S.Content
            isEditing={isEditing}
            isParagraphExists={subtitle}
            style={{ width: '100%' }}
          >
            <EditableContent
              text={title}
              as={S.Title}
              required={!subtitle}
              maxCount={validation.maxTitleChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading42"
              alignment={titleAlignment}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={subtitle}
              as={S.Paragraph}
              required={!title}
              maxCount={validation.maxSubtitleChar}
              onChange={Actions.changeSubtitle}
              className="WM_GLOBAL_paragraph18"
              alignment={subtitleAlignment}
              changeAlignment={Actions.changeSubtitleAlignment}
            />
            <S.TitleLine
              alignment={subtitle ? subtitleAlignment : titleAlignment}
            />
          </S.Content>
        </Show>
        <S.ContentWrapper>
          {content.map((item: string, idx: number) => {
            return (
              <Show when={[item.title, item.paragraph]}>
                <S.TextItemWrap key={idx}>
                  <S.TextItem>
                    <ControlsWithModalOpener
                      style={{
                        width: '100%',
                        paddingTop: '20px',
                        paddingBottom: '20px'
                      }}
                      actions={Actions.blockActions({
                        idx,
                        showRemove: content.length > validation.content.min
                      })}
                    >
                      <EditableContent
                        as={S.BlockTitle}
                        text={item.title}
                        required={!item.paragraph}
                        maxCount={validation.maxBlockTitleChar}
                        className="WM_GLOBAL_heading20"
                        onChange={(newTitle: string) => {
                          Actions.changeBlockTitle(newTitle, idx)
                        }}
                        alignment={item.titleAlignment}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockTitleAlignment(alignment, idx)
                        }
                      />
                      <EditableContent
                        text={item.paragraph}
                        as={S.BlockParagraph}
                        required={!item.title}
                        maxCount={validation.maxBlockParagraphChar}
                        className="WM_GLOBAL_paragraph18"
                        onChange={(newParagraph: string) => {
                          Actions.changeBlockParagraph(newParagraph, idx)
                        }}
                        alignment={item.paragraphAlignment}
                        changeAlignment={(alignment: string) =>
                          Actions.changeBlockParagraphAlignment(alignment, idx)
                        }
                      />
                      <S.TextItemLine />
                    </ControlsWithModalOpener>
                  </S.TextItem>
                </S.TextItemWrap>
              </Show>
            )
          })}
        </S.ContentWrapper>
        <AddButton
          onAdd={Actions.addBlock}
          toShow={content.length < validation.content.max}
          style={{ marginTop: '50px' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default PrivacyPolicy1
