/* @flow */
/* eslint-disable max-statements */
import {
  cropableImageModalActionMaker,
  eventSettingsActionMaker,
  cardBgImageActionMaker,
  removeBlockActionMaker
} from '@website/components/ActionMakers'
import {
  addBlock,
  changeTitleAlignment,
  changeParagraphAlignment,
  changeCardBgOverlayTransparency
} from '@website/components/CommonActions'

export default dispatcher => {
  // additionalBlockActions

  const changeAdditionalBlockTitle = (newTitle: string) => {
    dispatcher({
      type: 'CHANGE_ADDITIONAL_BLOCK_TITLE',
      value: newTitle
    })
  }

  const changeAdditionalBlockParagraph = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH',
      value: newParagraph
    })
  }

  const changeAdditionalBlockTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_ADDITIONAL_BLOCK_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeAdditionalBlockParagraphAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_ADDITIONAL_BLOCK_PARAGRAPH_ALIGNMENT',
      value: alignment
    })
  }

  const addAdditionalBlockImage = () => {
    dispatcher({
      type: 'ADD_ADDITIONAL_BLOCK_IMAGE'
    })
  }

  const removeAdditionalBlockImage = () => {
    dispatcher({
      type: 'REMOVE_ADDITIONAL_BLOCK_IMAGE'
    })
  }

  const changeAdditionalBlockImgAction = (
    value: Object,
    undoable: boolean
  ) => ({
    type: 'CHANGE_ADDITIONAL_BLOCK_IMAGE',
    value,
    undoable
  })

  const setAdditionalBlockImgCropParams = ({
    imgCropParams,
    undoable,
    ...rest
  }) => ({
    type: 'SET_ADDITIONAL_BLOCK_IMG_CROP_PARAMS',
    value: { imgCropParams, ...rest },
    undoable: undoable
  })

  const additionalBlockImgActions = (imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: 'RSVP4SmallImage',
      showRemove: true,
      actions: {
        change: ({ url, dimensions, undoable }) =>
          dispatcher(
            changeAdditionalBlockImgAction({ url, dimensions }, undoable)
          ),
        crop: ({ imgCropParams, undoable }) =>
          dispatcher(
            setAdditionalBlockImgCropParams({ imgCropParams, undoable })
          ),
        remove: () => removeAdditionalBlockImage()
      },
      imageUrl
    })
  ]

  // eventCardActions

  const changeSubtitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  // largeImageActions

  const changeCardImgAction = (value: Object, undoable: boolean) => ({
    type: 'CHANGE_CARD_IMAGE',
    value,
    undoable
  })

  const cardImgActions = (imageUrl: string, bgOverlayTransparency: number) => [
    ...cardBgImageActionMaker(dispatcher)({
      keyForSizes: 'RSVP4LargeImage',
      showRemove: false,
      actions: {
        changeImage: ({ url, dimensions, undoable }) =>
          dispatcher(changeCardImgAction({ url, dimensions }, undoable)),
        changeTransparency: value => {
          dispatcher(changeCardBgOverlayTransparency(value))
        }
      },
      imageUrl,
      bgOverlayTransparency,
      transparencyFlyoutAlignment: 'left'
    })
  ]

  // stepsActions

  const changeStepInfoAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_STEP_INFO_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeStepTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_STEP_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeStepParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_STEP_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }
  const changeStepData = (data: string, idx: number, keyInStore: string) => ({
    type: 'CHANGE_STEP_DATA',
    value: { data, idx, keyInStore }
  })

  const changeStepTitle = (title: string, idx: number) => {
    dispatcher(changeStepData(title, idx, 'stepTitle'))
  }

  const changeStepParagraph = (paragraph: string, idx: number) => {
    dispatcher(changeStepData(paragraph, idx, 'stepParapgraph'))
  }

  const changeStepInfo = (year: string, idx: number) => {
    dispatcher(changeStepData(year, idx, 'stepInfo'))
  }

  // date and location actions

  const changeDateTitle = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_DATE_TITLE',
      value: newParagraph
    })
  }

  const changeTimeTitle = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_TIME_TITLE',
      value: newParagraph
    })
  }
  const changeLocationTitle = (newParagraph: string) => {
    dispatcher({
      type: 'CHANGE_LOCATION_TITLE',
      value: newParagraph
    })
  }

  const changeDateTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_DATA_TITLE_ALIGNMENT',
      value: alignment
    })
  }
  const changeTimeTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TIME_TITLE_ALIGNMENT',
      value: alignment
    })
  }
  const changeLocationTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_LOCATION_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeDateValueAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_DATE_VALUE_ALIGNMENT',
      value: alignment
    })
  }
  const changeTimeValueAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_TIME_VALUE_ALIGNMENT',
      value: alignment
    })
  }
  const changeLocationValueAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_LOCATION_VALUE_ALIGNMENT',
      value: alignment
    })
  }

  return {
    addBlock: () => dispatcher(addBlock()),
    addAdditionalBlockImage,
    additionalBlockImgActions,
    changeAdditionalBlockTitle,
    changeAdditionalBlockTitleAlignment,
    changeAdditionalBlockParagraph,
    changeAdditionalBlockParagraphAlignment,
    changeStepInfo,
    changeStepTitle,
    changeStepParagraph,
    changeStepInfoAlignment,
    changeStepTitleAlignment,
    changeStepParagraphAlignment,
    changeSubtitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    cardImgActions,
    changeDateTitle,
    changeTimeTitle,
    changeLocationTitle,
    changeDateTitleAlignment,
    changeTimeTitleAlignment,
    changeLocationTitleAlignment,
    changeDateValueAlignment,
    changeTimeValueAlignment,
    changeLocationValueAlignment,
    stepActions: removeBlockActionMaker(dispatcher),
    eventSettingsActionMaker
  }
}
