/* @flow */

export const validation = {
  countMaxChar: 8,
  priceMaxChar: 19,
  blockName: 30,
  titleMaxChar: 100,
  paragraphMaxChar: 300,
  blocks: {
    min: 0,
    max: 4
  }
}
