/* @flow */
import React, { PureComponent } from 'react'
import S from './Portfolio11.styled'
import { validation } from './Portfolio11.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { EditingContext, DispatchContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import If from '@website/common/components/Conditional'
import PortfolioLightBox from '@website/common/components/LightBox/Lightbox'
import Show from '@website/common/components/Show/Show'
import { translate } from '@editor/common/utils/translations'

const isMobileDevice = () => global.window && global.window.innerWidth < 1025
class Portfolio11 extends PureComponent<void, void> {
  state = {
    activeTabIdx: 0,
    lightBoxMode: false,
    lightBoxActiveImgIndex: 0
  }

  isMobile = isMobileDevice()

  closePreviewMode = () => this.setState({ lightBoxMode: false })

  getActiveImgIdx = (rowIdx, imgIdx) => {
    if (rowIdx === 0) {
      return imgIdx
    }
    return rowIdx * 4 + imgIdx
  }

  openPorfolioPreviewMode = (
    rowIdx: number,
    imgIndex: number,
    isEditing: boolean
  ) => {
    if (!(this.isMobile && isEditing)) {
      this.setState({
        lightBoxMode: true,
        lightBoxActiveImgIndex: this.getActiveImgIdx(rowIdx, imgIndex)
      })
    }
  }

  setActiveTabIdx = idx => {
    this.setState({ activeTabIdx: idx })
  }

  beforeRemove = () => {
    const { activeTabIdx } = this.state
    if (activeTabIdx !== 0) {
      this.setState({ activeTabIdx: activeTabIdx - 1 })
    }
  }

  concatArrays = galleyArray => {
    const result = galleyArray.reduce((acc, galleryItem) => {
      return acc.concat(galleryItem)
    }, [])
    return result
  }

  render() {
    const {
      data: { galleryTab, title, paragraph, titleAlignment, paragraphAlignment }
    } = this.props
    const Actions = ActionGetter(this.context)
    const { activeTabIdx, lightBoxMode, lightBoxActiveImgIndex } = this.state

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.StyledContainer isEditing={isEditing}>
              <WMContainer>
                <Show when={[title, paragraph]}>
                  <S.Headings>
                    <EditableContent
                      text={title}
                      alignment={titleAlignment}
                      as={S.Title}
                      maxCount={validation.titleMaxChar}
                      className="WM_GLOBAL_heading32"
                      onChange={Actions.changeTitle}
                      changeAlignment={Actions.changeTitleAlignment}
                    />
                    <EditableContent
                      text={paragraph}
                      alignment={paragraphAlignment}
                      as={S.Paragraph}
                      maxCount={validation.paragraphMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={Actions.changeParagraph}
                      changeAlignment={Actions.changeParagraphAlignment}
                    />
                  </S.Headings>
                </Show>
                <S.TabsContainer role="tablist">
                  {galleryTab.map((tab, tabIdx) => {
                    return (
                      <S.TabControlContainer key={tabIdx}>
                        <ControlsWithModalOpener
                          actions={Actions.blockActions({
                            idx: tabIdx,
                            showRemove:
                              galleryTab.length > validation.galleryTab.min,
                            beforeRemove: this.beforeRemove
                          })}
                        >
                          <S.Tab
                            role="tab"
                            tabIndex="0"
                            isActive={activeTabIdx === tabIdx}
                            onClick={() => {
                              this.setActiveTabIdx(tabIdx)
                            }}
                          >
                            <EditableContent
                              isLinkControlHidden
                              toPropogate={false}
                              text={tab.title}
                              as={S.BlockTitle}
                              isActive={activeTabIdx === tabIdx}
                              required={validation.tabTitle.required}
                              maxCount={validation.tabTitleMaxChar}
                              className="WM_GLOBAL_paragraph14"
                              onChange={(newTitle: string) => {
                                Actions.changeBlockTitle(newTitle, tabIdx)
                              }}
                            />
                          </S.Tab>
                        </ControlsWithModalOpener>
                      </S.TabControlContainer>
                    )
                  })}
                </S.TabsContainer>
                <AddButton
                  onAdd={Actions.addTab}
                  toShow={galleryTab.length < validation.galleryTab.max}
                  style={{
                    margin: '14px 0'
                  }}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_tab_label')}
                </AddButton>
                {galleryTab.map((gallery, galleryTabIdx) => {
                  return (
                    <React.Fragment key={galleryTabIdx}>
                      <S.GalleryTab isActive={activeTabIdx === galleryTabIdx}>
                        <>
                          {gallery.gallery.map((galleryRow, galleryRowIdx) => {
                            return (
                              <S.RowWrapper>
                                <S.Row key={galleryRowIdx}>
                                  {galleryRow.map(
                                    (galleryItem: string, itemIdx: number) => {
                                      return (
                                        <S.Item
                                          isEditing={isEditing}
                                          key={itemIdx}
                                        >
                                          <S.ControlContainer>
                                            <ControlsWithImageCropper
                                              actions={Actions.imageActions(
                                                galleryTabIdx,
                                                galleryRowIdx,
                                                gallery.gallery.length,
                                                itemIdx,
                                                galleryItem.imgUrl
                                              )}
                                              key={itemIdx}
                                              style={{ width: '100%' }}
                                            >
                                              <Image
                                                as={S.Img}
                                                sizes={
                                                  galleryItem.imgDimensions
                                                }
                                                defaultImgSrc={
                                                  galleryItem.imgUrl
                                                }
                                                asProps={{
                                                  imgCropParams:
                                                    galleryItem.imgCropParams,
                                                  onClick: () =>
                                                    this.openPorfolioPreviewMode(
                                                      galleryRowIdx,
                                                      itemIdx,
                                                      isEditing
                                                    )
                                                }}
                                                alt="Portfolio image"
                                              />
                                            </ControlsWithImageCropper>
                                          </S.ControlContainer>
                                        </S.Item>
                                      )
                                    }
                                  )}
                                </S.Row>
                                <If
                                  condition={isEditing}
                                  then={() => (
                                    <S.AddButtonWrapper>
                                      <AddButton
                                        onAdd={Actions.addRow(
                                          galleryTabIdx,
                                          galleryRowIdx
                                        )}
                                        toShow={
                                          gallery.gallery.length <
                                          validation.galleryRows.max
                                        }
                                        style={{
                                          margin: '0 0.5rem'
                                        }}
                                      >
                                        <Icon
                                          name="glyph-add"
                                          className="icon"
                                          size="normal"
                                        />
                                        {translate('add_row_label')}
                                      </AddButton>
                                      <AddButton
                                        onAdd={Actions.removeRow(
                                          galleryTabIdx,
                                          galleryRowIdx
                                        )}
                                        toShow={
                                          gallery.gallery.length >
                                          validation.galleryRows.min
                                        }
                                        style={{
                                          margin: '0 0.5rem'
                                        }}
                                      >
                                        <Icon
                                          name="glyph-remove"
                                          className="icon"
                                          size="normal"
                                        />
                                        {translate('remove_row_label')}
                                      </AddButton>
                                    </S.AddButtonWrapper>
                                  )}
                                />
                              </S.RowWrapper>
                            )
                          })}
                        </>
                      </S.GalleryTab>
                    </React.Fragment>
                  )
                })}
                <If
                  condition={!lightBoxMode}
                  otherwise={() => {
                    return (
                      <PortfolioLightBox
                        dispatcher={this.context}
                        portfolioImages={this.concatArrays(
                          galleryTab[activeTabIdx].gallery
                        )}
                        closePreviewMode={this.closePreviewMode}
                        lightBoxActiveImgIndex={lightBoxActiveImgIndex}
                      />
                    )
                  }}
                />
              </WMContainer>
            </S.StyledContainer>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

Portfolio11.contextType = DispatchContext

export default Portfolio11
