/* @flow */

import { getColorMode, transferHeaderProps } from './project.reducers.utils'
import { omit, addAnalyticsEvent } from '@editor/common/utils'
import { generateUniqId } from '@editor/common/utils'

import { COMPONENT_CATEGORIES, EMPTY_PAGE_META } from '@editor/conf/consts'
import { INITIAL_NAVBAR_LINKS } from '@redux/consts'

const COMP_EXTRACTED_PROPS = [
  'defaultProps',
  'eventData',
  'metaData',
  'customPalette'
]

export const replaceComponent =
  ({ activePagePath, atIndex, componentMeta, oldCompDefaultProps }) =>
  (state: TAppProjectState) => {
    const { pages } = state
    const { components } = pages.find(page => page.path === activePagePath)
    const oldComp = components[atIndex]
    const isHeader = oldComp.category === COMPONENT_CATEGORIES.HEADER
    const isNoHeader = oldComp.id === 'NoHeader'

    if (isHeader) {
      addAnalyticsEvent(
        'SiteMaker components',
        `${oldComp.id} to ${componentMeta.id}`,
        'Header replacement'
      )
      if (oldComp.id === componentMeta.id) {
        return
      }
    }

    const newCompData = isHeader
      ? transferHeaderProps(
          oldComp.data,
          componentMeta.defaultProps,
          oldCompDefaultProps
        )
      : componentMeta.defaultProps
    const colorMode = getColorMode(
      pages,
      atIndex,
      componentMeta.colorMode,
      activePagePath
    )
    const newComp: IComponent = {
      ...omit(componentMeta, COMP_EXTRACTED_PROPS),
      uniqId: generateUniqId(),
      colorMode:
        isNoHeader && componentMeta.colorMode
          ? componentMeta.colorMode
          : colorMode,
      data: {
        ...newCompData,
        bgOverlayTransparency: componentMeta.defaultProps.bgOverlayTransparency,
        titleAlignment: componentMeta.defaultProps.titleAlignment,
        ...(componentMeta.defaultProps.paragraphAlignment && {
          paragraphAlignment: componentMeta.defaultProps.paragraphAlignment
        })
      }
    }
    components[atIndex] = newComp
  }

export const addComponent =
  ({ activePagePath, atIndex, componentMeta }) =>
  (state: TAppProjectState) => {
    const { pages } = state
    const newComp = {
      ...omit(componentMeta, COMP_EXTRACTED_PROPS),
      uniqId: generateUniqId(),
      id: componentMeta.id,
      colorMode: getColorMode(
        pages,
        atIndex - 1,
        componentMeta.colorMode,
        activePagePath
      ),
      data: componentMeta.defaultProps
    }

    return {
      ...state,
      pages: pages.map(page => {
        const hasFooter =
          page.components.length &&
          page.components[page.components.length - 1].category === 'footer'
        const isFooter = newComp.category === 'footer'

        if (page.path === activePagePath || (isFooter && !hasFooter)) {
          const _newComp = isFooter
            ? { ...newComp, uniqId: generateUniqId() }
            : newComp

          const newPageComponents = isFooter
            ? [...page.components, _newComp]
            : [
                ...page.components.slice(0, atIndex),
                _newComp,
                ...page.components.slice(atIndex)
              ]

          return {
            ...page,
            components: newPageComponents
          }
        }
        return page
      })
    }
  }

export const addMultipleComponents =
  ({ activePagePath, atIndex, componentsMeta }) =>
  (state: TAppProjectState) => {
    const { pages } = state

    const newComponents = componentsMeta.map(componentMeta => {
      return {
        ...omit(componentMeta, COMP_EXTRACTED_PROPS),
        uniqId: generateUniqId(),
        data: componentMeta.defaultProps,
        colorMode: getColorMode(
          pages,
          atIndex - 1,
          componentMeta.colorMode,
          activePagePath
        )
      }
    })

    return {
      ...state,
      pages: pages.map(page => {
        if (page.path === activePagePath) {
          const newPageComponents = [
            ...page.components.slice(0, atIndex),
            ...newComponents,
            ...page.components.slice(atIndex)
          ]

          return {
            ...page,
            components: newPageComponents
          }
        }
        return page
      })
    }
  }

export const duplicateComponent =
  ({ activePagePath, duplicatingCompIdx }) =>
  (state: TAppProjectState) => {
    const { pages } = state
    const activePage = pages.find(page => page.path === activePagePath)
    const duplicatingComponent = {
      ...activePage.components[duplicatingCompIdx],
      uniqId: generateUniqId()
    }

    activePage.components = [
      ...activePage.components.slice(0, duplicatingCompIdx + 1),
      duplicatingComponent,
      ...activePage.components.slice(duplicatingCompIdx + 1)
    ]
  }

export const removeComponent =
  ({ componentIdx, activePagePath }) =>
  (state: TAppProjectState) => {
    const { pages } = state
    const activePage = pages.find(page => page.path === activePagePath)

    const isHomePage = activePagePath === '/'
    const isLastComp = componentIdx === 0

    if (isHomePage && isLastComp) {
      state.headerLinks = INITIAL_NAVBAR_LINKS
      state.footerLinks = INITIAL_NAVBAR_LINKS
    }

    activePage.components = activePage.components.filter(
      (_, idx) => idx !== componentIdx
    )
  }

export const toTop =
  ({ index, activePagePath }) =>
  (state: TAppProjectState) => {
    const { components } = state.pages.find(page => {
      const _activePagePath = activePagePath === '/home' ? '/' : activePagePath

      return page.path === _activePagePath
    })

    if (index !== 0) {
      const swap = components[index]
      components[index] = components[index - 1]
      components[index - 1] = swap
    }
  }

export const toBottom =
  ({ index, activePagePath }) =>
  (state: TAppProjectState) => {
    const { components } = state.pages.find(page => {
      const _activePagePath = activePagePath === '/home' ? '/' : activePagePath

      return page.path === _activePagePath
    })

    if (index !== components.length - 1) {
      const swap = components[index]
      components[index] = components[index + 1]
      components[index + 1] = swap
    }
  }

export const addNewPage =
  ({ path, components }) =>
  (state: TAppProjectState) => {
    const firstPageComponents = state.pages[0].components
    const lastComponent = firstPageComponents[firstPageComponents.length - 1]

    const _components = components.map(comp => ({
      ...omit(comp, COMP_EXTRACTED_PROPS),
      data: comp.defaultProps
    }))

    lastComponent.category === COMPONENT_CATEGORIES.FOOTER &&
      _components.push({
        ...lastComponent,
        uniqId: generateUniqId()
      })

    state.pages.push({
      path: `/${path}`,
      meta: { ...EMPTY_PAGE_META },
      components: _components
    })
  }

export const updateTemplateDataAfterReplacing = template => state => {
  return {
    ...template,
    visualParams: state.visualParams,
    generator: state.generator,
    widgets: state.widgets,
    meta: state.meta
  }
}
