import { translate } from '@editor/common/utils/translations'

export const CHOOSING_STYLE = 'CHOOSING_STYLE'
export const GENERATING_CONTENT = 'GENERATING_CONTENT'
export const PICKING_IMAGES = 'PICKING_IMAGES'
export const FINALIZING = 'FINALIZING'
export const FINISHED = 'FINISHED'
export const FAILED = 'FAILED'

export const AI_WEBSITE_DATA_FETCHING_STATES = {
  [CHOOSING_STYLE]: translate('choosing_style'),
  [GENERATING_CONTENT]: translate('generating_content'),
  [PICKING_IMAGES]: translate('picking_images'),
  [FINALIZING]: translate('finalizing'),
  [FINISHED]: translate('finished')
}

export const SITE_GENERATING_DURATION_SEC = 20000
