//@flow
import React from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import { TContentProps } from './types'
import { OPTIONS, FULL, TEXT, FLAG } from './consts'
import Flag from '@editor/common/assets/svgr-icons/gb-flag.svg'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { translate } from '@editor/common/utils/translations'
import { selectLanguageMenuLayout } from '@selectors'
import { changeLanguageMenuLayout } from '@actions'

const Content = ({
  languageMenuLayout = 'full',
  changeLanguageMenuLayout
}: TContentProps) => (
  <Styled.FlyoutContent>
    <Styled.FlyoutTitle>
      {translate('languages_menu_settings_flyout_title')}
    </Styled.FlyoutTitle>

    <Styled.Options>
      {OPTIONS.map((option, idx) => {
        const { layout, tooltipText } = option
        const hasText = layout === FULL || layout === TEXT

        return (
          <TooltipWrapper key={idx} text={tooltipText}>
            <Styled.Option
              hasText={hasText}
              isActive={languageMenuLayout === layout}
              onClick={() => {
                languageMenuLayout !== layout &&
                  changeLanguageMenuLayout(layout)
              }}
            >
              {layout === FULL || layout === FLAG ? <Flag /> : null}
              {hasText ? 'English' : ''}
            </Styled.Option>
          </TooltipWrapper>
        )
      })}
    </Styled.Options>
  </Styled.FlyoutContent>
)

const mapStateToProps = state => ({
  languageMenuLayout: selectLanguageMenuLayout(state)
})

const mapDispatchToProps = { changeLanguageMenuLayout }

export default connect(mapStateToProps, mapDispatchToProps)(Content)
