/* @flow */
import { visualParamsActions, commonActions } from './consts'
import {
  getSiteId,
  selectIsUserAuthorized,
  selectIsWebsiteMultilingual,
  selectVisualParamsData,
  selectWebsiteActiveLanguageCode
} from '@selectors'
import { patchVisualParamsAPI } from '@api'
import { getDebouncedRevisionAction } from './utils'

export const debouncedSendRevision = getDebouncedRevisionAction()

const visualParamsAndCommonActions = [...visualParamsActions, ...commonActions]

export const visualParamsMiddleware =
  ({ dispatch, getState }) =>
  next =>
  action => {
    const returnValue = next(action)
    const state = getState()
    const isUserAuthorized = selectIsUserAuthorized(state)
    const { type, needToSendRevision = true } = action

    if (!needToSendRevision || !isUserAuthorized) {
      return returnValue
    }

    const siteId = getSiteId(state)
    const visualParamsData = selectVisualParamsData(state)
    const isWebsiteMultilingual = selectIsWebsiteMultilingual(state)
    const lang = isWebsiteMultilingual
      ? selectWebsiteActiveLanguageCode(state)
      : ''

    const isActionCompatible = visualParamsAndCommonActions.find(
      actionType => ~(type || '').indexOf(actionType)
    )

    if (isActionCompatible) {
      const params = {
        siteId,
        data: { visualParams: visualParamsData },
        dispatch,
        debouncedCb: patchVisualParamsAPI,
        params: { lang }
      }
      debouncedSendRevision(params)
    }

    return returnValue
  }
