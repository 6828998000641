/* @flow */
import React, { useContext, useState, useCallback, useMemo, memo } from 'react'
import S from './Feature33.styled'
import { validation } from './Feature33.consts'
import ActionGetter from './Actions'
import CountDownDate from './CountDownDate'
import If from '@website/common/components/Conditional'
import DatePicker from '@website/common/components/DatePicker'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'

const Feature33 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      dateLabels,
      countDownDate,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [showDatePicker, setDatePickerState] = useState(false)
  const [datePickerAnchorEl, setDatePickerAnchorEl] = useState(null)

  const isAnyDateLabelFilled = useMemo(() => {
    return dateLabels.findIndex(dateLabel => dateLabel !== '') !== -1
  }, [dateLabels])

  const closeDatePicker = useCallback(() => {
    setDatePickerState(false)
  }, [])

  const submitDate = useCallback(date => {
    closeDatePicker(false)
    Actions.changeDate(date.toISOString())
  }, [])

  const handleDatePicker = useCallback(e => {
    if (!isEditing) {
      return
    }
    setDatePickerAnchorEl(e.currentTarget)
    setDatePickerState(true)
  }, [])

  const _countDownDate = countDownDate || new Date().toISOString()

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <If
        condition={showDatePicker}
        then={() => (
          <DatePicker
            anchorEl={datePickerAnchorEl}
            submitDate={submitDate}
            closeDatePicker={closeDatePicker}
            selectedDays={_countDownDate}
            backdropDisablingSelectors={['.countdown-time-value']}
          />
        )}
      />
      <S.WmCustomContainer>
        <S.Content isEditing={isEditing}>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            required={!paragraph}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading42"
            as={S.Title}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            isEditing={isEditing}
            text={paragraph}
            alignment={paragraphAlignment}
            required={!title}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            as={S.Paragraph}
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.Content>
        <S.DateContainer
          isEditing={isEditing}
          isAnyDateLabelFilled={isAnyDateLabelFilled}
        >
          <CountDownDate
            dateLabels={dateLabels}
            date={_countDownDate}
            openDatePicker={handleDatePicker}
          />
        </S.DateContainer>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature33
