//  @flow

import React, { memo } from 'react'

import Messenger from '../Messenger'
import * as Styled from './styled'

const SiteMessengers = ({ messengersData: { settings, data } }) => {
  const addedMessengers = data.filter(messenger => messenger.isActive)
  const { alignment } = settings
  return (
    <Styled.SiteMessengersWrapper
      alignment={alignment}
      className="site-messengers"
    >
      {addedMessengers.map(messenger => {
        return (
          <Styled.SiteMessengerItem key={messenger.name}>
            <Messenger
              type={messenger.name}
              alignment={alignment}
              data={{
                ...(messenger.data || {}),
                value: messenger.value
              }}
            />
          </Styled.SiteMessengerItem>
        )
      })}
    </Styled.SiteMessengersWrapper>
  )
}

export default memo(SiteMessengers)
