/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_SUB_TITLE':
        draft.subTitle = action.value
        return
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        return
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_SUBTITLE_ALIGNMENT': {
        draft.subTitleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_PORTFOLIO_IMG':
        {
          const { blockIdx, idx, url, dimensions } = action.value
          draft.images[blockIdx][idx].imgUrl = url
          draft.images[blockIdx][idx].imgDimensions = dimensions
        }
        return
      case 'CHANGE_IMAGE':
        {
          const { url, dimensions } = action.value
          draft.mainImage.url = url
          draft.mainImage.imgDimensions = dimensions
        }
        return
      case 'ADD_BLOCK':
        const afterIdx = action.value
        draft.images.splice(afterIdx + 1, 0, initialState.images[0])
        return
      case 'REMOVE_BLOCK':
        const imgIdx_ = action.value
        draft.images.splice(imgIdx_, 1)
        return
    }
  })
}
