import styled, { css } from 'styled-components'

import { SimpleDropdown } from '@renderforest/rf-ui-library/molecules/CustomizableDropdown/SimpleDropdown'
import { SearchDropdown as SearchDropdownLib } from '@renderforest/rf-ui-library/molecules/CustomizableDropdown/SearchDropdown'

const getDropdownStyles = () => css`
  width: 100%;
  border-radius: 5px;
  box-shadow: none;

  .dropdown-wrapper {
    border: 1px solid #c1c9e0;
    &:hover {
      border: 1px solid #8493bd;
    }
  }

  .dropdown-wrapper-active {
    border: 1px solid #3271e6;
  }

  .dropdown-wrapper,
  .dropdown-wrapper-active {
    height: 40px;
    max-height: unset;
    border-radius: 5px;
    padding: 0 15px;
    justify-content: center;
    box-sizing: border-box;

    .search-dropdown-active-item {
      color: ${({ isDisabled }) => (isDisabled ? '#8493bd' : '#252e48')};
    }

    .search-dropdown-options-wrapper {
      padding: 0;
      top: 44px;
      box-sizing: unset;
      border: 1px solid #c1c9e0;
      border-radius: 5px;
      box-shadow: 0 3px 6px 0 rgba(50, 113, 230, 0.2);
      .react-window-wrapper {
        padding: 0;

        .search-dropdown-list-item {
          border: none;
          border-radius: 0;
          color: #545f7e;
          padding: 0 15px;
          font-size: 14px;
        }
      }
    }

    ${({ isDisabled }) =>
      isDisabled
        ? css`
            background-color: #edeff6;
            cursor: default;
            pointer-events: none;
          `
        : ''}
  }

  .arrow_filled_bottom {
    margin-top: 0;
    position: absolute;
    right: 10px;
    top: 7px;
    svg {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    box-shadow: none;
  }
`

export const DropdownWrapper = styled.div`
  width: 330px;

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const DropdownLabel = styled.p`
  font-size: 14px;
  color: #545f7e;
  font-weight: 500;
  margin-bottom: 5px;
`

export const Dropdown = styled(SimpleDropdown)`
  ${getDropdownStyles};
`

export const SearchDropdown = styled(SearchDropdownLib)`
  ${getDropdownStyles};
`
