/* @flow */
import { mapModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

export default dispatcher => {
  const changeInfoContainerTitle = (newName: string) =>
    dispatcher({
      type: 'CHANGE_INFO_CONTAINER_TITLE',
      value: newName
    })

  const removeInfo = (key: stinrg) =>
    dispatcher({
      type: `REMOVE_${key.toUpperCase()}`
    })

  const addInfo = key =>
    dispatcher({
      type: `ADD_${key.toUpperCase()}`
    })

  const infoChangeAction = (key: string, value: string) =>
    dispatcher({
      type: `CHANGE_${key.toUpperCase()}`,
      value
    })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeNameAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_NAME_ALIGNMENT',
      value: alignment
    })
  }

  return {
    addInfo,
    infoChangeAction,
    changeInfoContainerTitle,
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeNameAlignment,
    getMapActions: mapModalActionMaker(dispatcher),
    infoActions: key => [
      {
        IconComp: DeleteIcon,
        tooltipText: translate('delete_label'),
        handler: () => dispatcher(removeInfo(key))
      }
    ]
  }
}
