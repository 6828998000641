/* @flow */
import {
  changeTitle,
  changeSubTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addBlock,
  addButton
} from '@website/components/CommonActions'

import {
  buttonModalActionMaker,
  removeBlockActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeSubTitle = newSubTitle => {
    dispatch(changeSubTitle(newSubTitle))
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_SUBTITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeTitleGradientStatus = (newTitleTextGradient: string) => {
    dispatch({
      type: 'CHANGE_TITLE_TEXT_GRADIENT',
      value: newTitleTextGradient
    })
  }

  const changeSubTitleGradientStatus = (newTitleTextGradient: string) => {
    dispatch({
      type: 'CHANGE_SUBTITLE_TEXT_GRADIENT',
      value: newTitleTextGradient
    })
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeSubTitle: _changeSubTitle,
    changeSubTitleAlignment,
    changeTitleGradientStatus,
    changeSubTitleGradientStatus,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    changeBlockParagraph,
    changeBlockTitle,
    addButton,
    btnActions: buttonModalActionMaker(dispatch),
    addBlock: () => dispatch(addBlock()),
    blockActions: removeBlockActionMaker(dispatch),
    imgActions: imageUrl =>
      cropableImageModalActionMaker(dispatch)({
        showRemove: false,
        keyForSizes: 'text21',
        imageUrl
      })
  }
}
