/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'
import { buttonInitialState } from './CTA11.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        return
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url: _url, dimensions } = action.value
        draft.backgroundImgUrl = _url
        draft.backgroundImgDimensions = dimensions
        return
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        return
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        return
      case 'CHANGE_IMAGE':
        {
          const { mockupType, dimensions, url } = action.value
          draft[mockupType] = url
          draft[`${mockupType}Dimensions`] = dimensions
          draft[`${mockupType}ImgCropParams`] = {}
        }
        return
      case 'CHANGE_STORE_URL':
        {
          const { url, storeType } = action.value
          draft[storeType] = url
        }
        return
      case 'ADD_STORE_LINK':
        {
          const storeType = action.value
          draft[storeType] = initialState[storeType]
        }
        return
      case 'REMOVE_STORE_BUTTON':
        {
          const storeType = action.value
          draft[storeType] = ''
        }
        return
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        return
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        return
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({
            ...buttonInitialState[0],
            type: btnType
          })
        } else {
          draft.buttons.push({
            ...buttonInitialState[1],
            type: btnType
          })
        }
        return
      case 'REMOVE_IMAGE':
        {
          const { mockupType } = action.value
          draft[`${mockupType}Dimensions`] = {}
          draft[mockupType] = initialState[mockupType]
          draft[`${mockupType}ImgCropParams`] = {}
        }
        return
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        return
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams, mockupType } = action.value
        draft[`${mockupType}ImgCropParams`] = imgCropParams
      }
    }
  })
}
