import styled, { css } from 'styled-components'
import LibButton from '@editor/common/lib/Button'

export const Container = styled.div`
  display: flex;
  ${({ isVerticalView }) =>
    isVerticalView
      ? css`
          flex-direction: column;
        `
      : ''}

  .input-container {
    width: auto;
    flex-shrink: 0;
    box-sizing: border-box;
    height: 100%;
    z-index: 1;

    & > input {
      height: 100%;
      border-radius: 5px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .countries-search-dropdown {
    .dropdown-wrapper {
      &:hover {
        z-index: 1;
      }
    }

    .dropdown-wrapper,
    .dropdown-wrapper-active {
      border-radius: 0 5px 5px 0;
    }

    .dropdown-wrapper-active {
      z-index: 1;
    }
  }
`

export const SearchButton = styled(LibButton)`
  padding: 11px 30px;
  ${({ isVerticalView }) =>
    isVerticalView
      ? css`
          margin-top: 25px;
        `
      : css`
          margin-left: 20px;
        `}
`

export const SearchWrapper = styled.div`
  display: flex;
  width: 100%;
`
