/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature6.styled'
import { validation } from './Feature6.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Buttons from '@website/common/components/Buttons'

const Feature6 = memo(props => {
  const {
    data: {
      title,
      featureContent,
      titleAlignment,
      featureContentAlignment,
      buttons,
      imageUrl,
      imgDimensions,
      imgCropParams,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer>
        <S.ContentWrap>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            className="WM_GLOBAL_heading"
            maxCount={validation.headingMaxChar}
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            as={S.Description}
            text={featureContent}
            alignment={featureContentAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeFeatureContentAlignment}
          />
          <Buttons data={buttons} />
        </S.ContentWrap>
        <S.ImageWrap>
          <S.ImageFigure>
            <ControlsWithImageCropper
              actions={Actions.getImgActions(imageUrl)}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                sizes={imgDimensions}
                defaultImgSrc={imageUrl}
                asProps={{ imgCropParams }}
                alt="Feature illustration"
              />
            </ControlsWithImageCropper>
          </S.ImageFigure>
        </S.ImageWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature6
