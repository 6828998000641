/* @flow */
import styled from 'styled-components'
import Icon from '@website/common/components/Icon'
import THEME from './Contact6.theme'
import {
  styleProvider,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: ${({ isEditing }) => (isEditing ? '120px 0 80px 0' : '80px 0')};
  background: ${getStyleForProp('background')};

  @media only screen and (max-width: 1550px) and (min-width: 1441px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 80px 80px' : '80px')};
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '100px 0 60px 0' : '60px 0')};
  }
`

const WMCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  padding-top: 0;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
  .required-icon {
    position: absolute;
    right: 10px;
    bottom: 5px;
    padding: 10px;
    color: ${({ theme }) => theme.palette.tertiarySemiLight};
    @media only screen and (max-width: 767px) {
      right: 4px;
    }
  }
  .add-button-wrap {
    text-align: start;
  }
`

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .control-container {
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
  }
  .control-container:nth-child(3),
  .control-container:nth-child(4) {
    width: 100%;
    > div:first-child {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  > .control-container:first-child {
    margin-bottom: 30px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 30px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 0 45px 0 25px;
  height: 50px;
  margin: 20px 0 0 0;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('buttonRadius')};
  border: ${({ isError, theme }) =>
    `1px solid ${
      isError ? '#EB0C00' : `rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`
    }`};
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
`

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  padding: 25px;
  margin: 20px 0 30px 0;
  color: ${({ theme, isEditing }) =>
    isEditing ? theme.palette.tertiaryNormal : theme.palette.tertiaryDark};
  font-size: 16px;
  box-sizing: border-box;
  -webkit-appearance: none;
  border-radius: ${getStyleForProp('inputRadius')};
  max-height: 200px;
  border: ${({ theme }) =>
    `1px solid rgba(${hexToRgb(theme.palette.tertiaryDark)}, 0.3)`};
  &:focus {
    border-color: ${({ theme }) => theme.palette.tertiaryDark};
    outline: none;
    &::placeholder {
      color: transparent;
    }
  }
  &::placeholder {
    color: ${({ theme }) => theme.palette.tertiaryNormal};
    font-family: inherit;
  }
  @media only screen and (max-width: 767px) {
    padding: 20px;
  }
`

const FormContainer = styled.div`
  width: 90%;
  max-width: 400px;
  min-width: 400px;
  margin: 0 0 0 16vw;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  position: relative;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    max-width: 400px;
  }
  &:only-child {
    margin: 0;
    align-items: center;
    .button-container {
      transform: translateX(-24px);
    }
  }
  & > .control-container {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: center;
    @media only screen and (max-width: 767px) {
      align-items: center;
    }
  }
  @media only screen and (max-width: 992px) {
    margin: 0 0 0 10vw;
  }
  @media only screen and (max-width: 767px) {
    min-width: initial;
    margin: 30px 0 0 0;
    align-items: center;
  }
  @media only screen and (max-width: 500px) {
    width: 100%;
  }
  .name-input {
    margin: 0;
  }
`

const AddFormContainer = styled.div`
  width: 90%;
  max-width: 400px;
  margin: 0 0 0 16vw;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 767px) {
    margin: 40px 0 0 0;
  }
`

const ErrorMessage = styled.div`
  position: absolute;
  top: 70px;
  color: #eb0c00;
  font-size: 14px;
  display: flex;
  align-items: center;
  > div {
    margin-right: 5px;
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  &.name-input-container {
    ${ErrorMessage} {
      top: 50px;
    }
  }
  div {
    svg {
      max-width: 12px;
      max-height: 12px;
      fill: ${({ theme }) => theme.palette.tertiaryDark};
    }
  }
`

const PhoneWrap = styled.div`
  margin-top: 10px;
`

const PhoneWrapA = styled.a`
  display: inline-block;
  text-decoration: none;
`

const Phone = styled.p`
  color: ${getStyleForProp('heading')};
  margin-top: ${({ isEditing }) => (isEditing ? '10px' : '0')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const EmailWrapA = styled.a`
  display: block;
  text-decoration: none;
  margin-top: ${({ isEditing }) => (isEditing ? '0' : '10px')};
  ${getTextStyleByAlignment};
  width: 100%;
`

const EmailWrap = styled.div`
  ${getTextStyleByAlignment};
  width: 100%;
`

const Email = styled.p`
  color: ${getStyleForProp('heading')};
  margin-top: ${({ isEditing }) => (isEditing ? '10px' : '0')};
`

const SocialWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ withoutTexts }) => (withoutTexts ? '0' : '50px 0 0 0')};
  max-width: 250px;
  margin-right: auto;
  @media only screen and (max-width: 767px) {
    margin: 30px 0 0 0;
  }
`

const SocialIconsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ isForm }) => (isForm ? 'center' : 'flex-start')};
  transform: ${({ isForm }) => (isForm ? 'initial' : 'translateX(-12px)')};
  margin: 10px 0 0 0;
  & > .control-container:nth-child(n + 4) {
    .styled-controls {
      right: 0;
      left: initial;
    }
  }
  .link {
    width: 26px;
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
    color: ${getStyleForProp('socialColor')};
  }
  .rf-add-btn {
    padding: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: initial;
    }
  }
`

const SocialTitle = styled.p`
  color: ${getStyleForProp('heading')};
  font-weight: 300;
  ${getTextStyleByAlignment};
  width: 100%;
`

const SocialIcon = styled(Icon).attrs(() => ({
  color: getStyleForProp('socialColor')
}))``

const RequiredIcon = styled(Icon).attrs(({ theme }) => ({
  color: theme.palette.tertiarySemiLight
}))``

const ErrorIcon = styled.img`
  width: 12px;
  margin-right: 5px;
`

export default {
  WMCustomContainer,
  Content,
  Title,
  Input,
  TextArea,
  Container,
  Paragraph,
  InputContainer,
  FormContainer,
  PhoneWrap,
  PhoneWrapA,
  Phone,
  EmailWrapA,
  EmailWrap,
  Email,
  SocialWrap,
  SocialTitle,
  SocialIconsWrap,
  AddFormContainer,
  SocialIcon,
  RequiredIcon,
  ErrorMessage,
  ErrorIcon
}
