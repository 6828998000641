/* @flow */
import styled from 'styled-components'
import THEME from './Music2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  align-items: center;
  padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : '80px 0')};
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 60px 0' : '60px 0')};
  }
`
const WmCustomContainer = styled(WMContainer)`
  padding-top: 0;
`

const TextContent = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  > :last-child {
    margin-bottom: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    p,
    h1 {
      padding: 0;
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
`

const MusicWrapper = styled.div`
  width: 100%;
  max-width: 900px;
  font-size: 0;
  margin: 50px auto 0 auto;
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
    margin-top: 40px;
  }
`

const MusicContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  position: relative;
  width: 100%;
  margin: 10px 0 0 0;
`

const IframeWrapper = styled.div`
  width: 100%;
  position: relative;
  iframe {
    width: 100%;
    height: 160px;
    @media only screen and (max-width: 450px) {
      height: 100px;
    }
  }
`

export default {
  WmCustomContainer,
  StyledContainer,
  Title,
  Paragraph,
  MusicContent,
  IframeWrapper,
  TextContent,
  MusicWrapper
}
