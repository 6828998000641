import React, { useState, useEffect } from 'react'

import * as Styled from './styled'

const SubSidebar = ({ children, isOpened, onCloseEnd }) => {
  const [isMounted, setMounted] = useState(isOpened)

  // Did unmount
  useEffect(
    () => () => {
      onCloseEnd()
    },
    []
  )

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 300)

    if (isOpened) {
      setMounted(true)
    }
  }, [isOpened])

  const onAnimationEnd = () => {
    if (!isOpened) {
      setMounted(false)
      onCloseEnd()
    }
  }

  return isMounted ? (
    <Styled.SubSidebarWrapper
      isOpened={isOpened}
      onAnimationEnd={onAnimationEnd}
    >
      <Styled.SubSidebar>{children}</Styled.SubSidebar>
    </Styled.SubSidebarWrapper>
  ) : null
}

export default SubSidebar
