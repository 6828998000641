import AUTO_TRANSLATION_VIDEO from './auto_translation_video.mp4'
export const STORAGE_KEY = 'isAutoTranslationSeen' //Change this value if you want the <News> popup to be displayed again

export const NEWS_MEDIA = [
  {
    mediaType: 'video',
    mediaUrl: AUTO_TRANSLATION_VIDEO
  }
]

export const NEWS = {
  en: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 Auto-translate your websites 🔥',
      description:
        'Great news, everyone! 🔔 You can now automatically translate your website in seconds. Just count ‘one, two, three’ and voilà — your website speaks multiple languages! 🌍'
    }
  ],
  ru: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 Автоматически переводите ваши сайты 🔥',
      description:
        'Отличные новости! 🔔 Теперь вы можете автоматически перевести ваш сайт за считанные секунды. Просто считайте «раз, два, три» — и ваш сайт заговорит на нескольких языках! 🌍'
    }
  ],
  pt: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 Traduza automaticamente seus sites 🔥',
      description:
        'Ótimas notícias, pessoal! 🔔 Agora você pode traduzir automaticamente seu site em segundos. Basta contar "um, dois, três" e voilà — seu site fala vários idiomas! 🌍'
    }
  ],
  tr: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 Web sitelerinizi otomatik olarak çevirin 🔥',
      description:
        'Harika haberler! 🔔 Artık web sitenizi saniyeler içinde otomatik olarak çevirebilirsiniz. Sadece "bir, iki, üç" sayın ve voilà — web siteniz birden fazla dilde konuşuyor! 🌍'
    }
  ],
  es: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 Traduce automáticamente tus sitios web 🔥',
      description:
        '¡Buenas noticias, todos! 🔔 Ahora puedes traducir automáticamente tu sitio web en segundos. Solo cuenta "uno, dos, tres" y voilà — ¡tu sitio web habla varios idiomas! 🌍'
    }
  ],
  de: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 Übersetzen Sie Ihre Websites automatisch 🔥',
      description:
        'Tolle Neuigkeiten! 🔔 Sie können jetzt Ihre Website in Sekunden automatisch übersetzen. Zählen Sie einfach "eins, zwei, drei" und voilà — Ihre Website spricht mehrere Sprachen! 🌍'
    }
  ],
  fr: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 Traduisez automatiquement vos sites Web 🔥',
      description:
        'Bonne nouvelle à tous ! 🔔 Vous pouvez désormais traduire automatiquement votre site en quelques secondes. Comptez simplement "un, deux, trois" et voilà — votre site parle plusieurs langues ! 🌍'
    }
  ],
  jp: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 サイトを自動翻訳します 🔥',
      description:
        '素晴らしいニュースです！ 🔔 あなたのウェブサイトを数秒で自動的に翻訳できます。「いち、に、さん」と数えるだけで、ウェブサイトが複数の言語を話します！ 🌍'
    }
  ],
  ar: [
    {
      ...NEWS_MEDIA[0],
      secondaryTitle: '🔥 ترجم مواقع الويب الخاصة بك تلقائيًا 🔥',
      description:
        'أخبار رائعة للجميع! 🔔 يمكنك الآن ترجمة موقع الويب الخاص بك تلقائيًا في ثوانٍ. فقط عد "واحد، اثنان، ثلاثة" وستجد موقعك يتحدث عدة لغات! 🌍'
    }
  ]
}

export const SLIDER_DEFAULT_SETTINGS = {
  infinite: true,
  dots: true,
  fade: true,
  swipeToScroll: true,
  // autoplay: true, this prop can be reverted after RSVP-NewFeaturesPopup
  responsive: [
    {
      breakpoint: 767,
      settings: {
        fade: false,
        nextArrow: null,
        prevArrow: null
      }
    }
  ]
}
