/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header4.styled'
import THEME from './Header4.theme'
import ActionGetter from './Actions'
import { validation } from './Header4.consts'
import { SubscribeForm } from '../SubscribeForm'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'

const Header4 = memo(
  ({
    data: {
      title,
      logoSrc,
      logoColor,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      buttonText,
      placeholder,
      logoWidth,
      logoDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const { isEditing } = useContext(EditingContext)
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)

    const [navBarHeight, setNavBarHeight] = useState(0)

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
      >
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <S.WmCustomContainer>
          <S.HeaderContent topOffset={navBarHeight}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <S.SubscribtionFormWrap>
              <SubscribeForm
                actions={{
                  buttonActions: Actions.buttonActions(buttonText),
                  placeHolderAction: Actions.changePlaceHolderText
                }}
                buttonText={buttonText}
                placeholder={placeholder}
                theme={THEME}
              />
            </S.SubscribtionFormWrap>
          </S.HeaderContent>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
)

Header4.contextType = DispatchContext

export default Header4
