//@flow
import React, { memo, useContext, useMemo, useRef, useEffect } from 'react'

import { DispatchContext, MobileDetectContext } from '@contexts'

import * as Styled from './styled'
import type { TProps } from './types'
import ActionGetter from '@website/components/RSVP6/Actions/Actions'
import bringIntoViewport from '@editor/common/utils/bringIntoViewport'

const List = ({ activeBgThumbnail, backgroundImages }: TProps) => {
  const scrollableAreaRef = useRef(null)

  const isMobile = useContext(MobileDetectContext)
  const dispatcher = useContext(DispatchContext)

  const Actions = useMemo(() => ActionGetter(dispatcher), [dispatcher])

  const activeIdx = backgroundImages.findIndex(
    backgroundImage => backgroundImage.backgroundThumbnail === activeBgThumbnail
  )

  useEffect(() => {
    const scrollableArea = scrollableAreaRef.current

    if (!scrollableArea || isMobile) return

    const elem = scrollableArea.querySelector(`#background-${activeIdx}`)

    setTimeout(() => {
      bringIntoViewport(elem, scrollableArea, {
        extraScrollOffset: 50
      })
    }, 300)
  }, [scrollableAreaRef])

  const handleElementClick = (e, img) => {
    if (e.target.src !== activeBgThumbnail) {
      Actions.changeBackgroundImage(img)
    }

    if (!isMobile) {
      const scrollableArea = scrollableAreaRef.current

      if (!scrollableArea) return

      bringIntoViewport(e.currentTarget, scrollableArea, {
        verticalMargin: 20,
        extraScrollOffset: 50
      })
    }
  }

  return (
    <Styled.List ref={scrollableAreaRef}>
      {backgroundImages.map((backgroundImage, idx) => (
        <Styled.ListItem
          key={idx}
          id={`background-${idx}`}
          isActive={backgroundImage.backgroundThumbnail === activeBgThumbnail}
          onClick={event => handleElementClick(event, backgroundImage)}
        >
          <img src={backgroundImage.backgroundThumbnail} alt="topImage" />
        </Styled.ListItem>
      ))}
    </Styled.List>
  )
}

export default memo(List)
