/* @flow */
import {
  cropableImageModalActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  changeBlockTitle
} from '@website/components/CommonActions'

export default dispatch => {
  const changeUrl = value => ({
    type: 'CHANGE_URL_DATA',
    value
  })

  const removeUrl = value => () => {
    dispatch({
      type: 'REMOVE_URL',
      value
    })
  }

  const urlActions = (idx: number, linkData) => [
    ...linkActionMaker(dispatch)({
      idx,
      linkData,
      actions: {
        change: (_, newLinkData) => changeLinkData({ idx, newLinkData }),
        remove: removeUrl({ idx })
      }
    })
  ]

  const imageActions = (idx: number, imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'CTA12',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    })
  ]

  const _addUrl = idx => {
    dispatch(addUrl(idx))
  }

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeBlockTitle = (newTitle, idx) => {
    dispatch(changeBlockTitle(newTitle, idx))
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeParagraph: _changeParagraph,
    changeBlockTitle: _changeBlockTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    addUrl: _addUrl,
    changeUrl,
    removeUrl,
    urlActions,
    changeTitle: _changeTitle,
    imageActions,
    iconActions: idx => [
      ...iconActionMaker(dispatch)({
        idx,
        showRemove: false
      })
    ]
  }
}
