/* @flow */
import { createGlobalStyle, css } from 'styled-components'
import { getFont } from '@website/common/utils'

const FONT_RANGES = {
  headingExtraLarge: {
    mobile: {
      min: 100,
      default: 200,
      max: 300
    },
    desktop: {
      min: 250,
      default: 325,
      max: 400
    }
  },
  headingLarge: {
    mobile: {
      min: 50,
      default: 75,
      max: 100
    },
    desktop: {
      min: 100,
      default: 200,
      max: 300
    }
  },
  heading120: {
    mobile: {
      min: 40,
      default: 54,
      max: 72
    },
    desktop: {
      min: 92,
      default: 120,
      max: 150
    }
  },
  heading96: {
    mobile: {
      min: 44,
      default: 55,
      max: 66
    },
    desktop: {
      min: 72,
      default: 96,
      max: 120
    }
  },
  heading72: {
    mobile: {
      min: 30,
      default: 42,
      max: 54
    },
    desktop: {
      min: 54,
      default: 72,
      max: 90
    }
  },
  heading54: {
    mobile: {
      min: 26,
      default: 34,
      max: 42
    },
    desktop: {
      min: 42,
      default: 54,
      max: 66
    }
  },
  heading42: {
    mobile: {
      min: 22,
      default: 26,
      max: 30
    },
    desktop: {
      min: 32,
      default: 42,
      max: 52
    }
  },
  heading32: {
    mobile: {
      min: 20,
      default: 24,
      max: 28
    },
    desktop: {
      min: 24,
      default: 32,
      max: 40
    }
  },
  heading24: {
    desktop: {
      min: 20,
      default: 24,
      max: 28
    }
  },
  heading20: {
    mobile: {
      min: 16,
      default: 20,
      max: 24
    },
    desktop: {
      min: 16,
      default: 20,
      max: 24
    }
  },
  paragraph18: {
    desktop: {
      min: 14,
      default: 18,
      max: 22
    }
  },
  paragraph16: {
    desktop: {
      min: 14,
      default: 16,
      max: 18
    }
  },
  paragraph14: {
    desktop: {
      min: 12,
      default: 14,
      max: 16
    }
  },
  paragraph12: {
    desktop: {
      min: 10,
      default: 12,
      max: 14
    }
  }
}

export const LARGE_SIZE = 1024
export const TABLET_SIZE = 768
export const MOBILE_SIZE = 457

export const getFontSize = (fontType, fontSize, device = 'desktop') => {
  const fontRange = FONT_RANGES[fontType]
  const fontRangeByDevice = fontRange[device]
  if (!fontRangeByDevice) {
    return 'inherit'
  }
  const { min, max } = fontRangeByDevice
  const step = (max - min) / 100
  return fontSize * step + min
}

const getHeadingStylesForMobile = fontType => css`
  font-size: ${({ theme }) =>
    getFontSize(fontType, theme.fontSize, 'mobile')}px;
`

const getHeadingStyles = fontType => css`
  font-family: ${({ theme }) => getFont('primaryFont', theme)};
  font-size: ${({ theme }) => getFontSize(fontType, theme.fontSize)}px;
  margin-bottom: 0.3em;
`

const getParagraphStyles = fontType => css`
  font-family: ${({ theme }) => getFont('secondaryFont', theme)};
  font-size: ${({ theme }) => getFontSize(fontType, theme.fontSize)}px;
  line-height: 1.4;
`

// @media only screen and

export const GlobalStyle = createGlobalStyle`
.WM_GLOBAL_heading {
  ${getHeadingStyles('heading42')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading42')}
  }
};
.WM_GLOBAL_headingExtraLarge {
  ${getHeadingStyles('headingExtraLarge')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('headingExtraLarge')}
  }
}
.WM_GLOBAL_headingLarge {
  ${getHeadingStyles('headingLarge')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('headingLarge')}
  }
}
.WM_GLOBAL_heading120 {
  ${getHeadingStyles('heading120')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading120')}
  }
}
.WM_GLOBAL_heading96 {
  ${getHeadingStyles('heading96')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading96')}
  }
}
.WM_GLOBAL_heading72 {
  ${getHeadingStyles('heading72')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading72')}
  }
}
.WM_GLOBAL_heading54 {
  ${getHeadingStyles('heading54')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading54')}
  }
}
.WM_GLOBAL_heading42 {
  ${getHeadingStyles('heading42')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading42')}
  }
}
.WM_GLOBAL_heading32 {
  ${getHeadingStyles('heading32')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading32')}
  }
}
.WM_GLOBAL_heading24 {
  ${getHeadingStyles('heading24')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading24')}
  }
}
.WM_GLOBAL_heading20 {
  ${getHeadingStyles('heading20')}
  @media only screen and (max-width: 768px) {
    ${getHeadingStylesForMobile('heading20')}
  }
}

.WM_GLOBAL_paragraph {
  ${getParagraphStyles('paragraph18')}
};

.WM_GLOBAL_paragraph18 {
  ${getParagraphStyles('paragraph18')}
}

.WM_GLOBAL_paragraph16 {
  ${getParagraphStyles('paragraph16')}
}

.WM_GLOBAL_paragraph14 {
  ${getParagraphStyles('paragraph14')}
}

.WM_GLOBAL_paragraph12 {
  ${getParagraphStyles('paragraph12')}
}

.WM_GLOBAL_primary-font {
  font-family: ${({ theme }) => getFont('primaryFont', theme)};
};
.WM_GLOBAL_secondary-font {
  font-family: ${({ theme }) => getFont('secondaryFont', theme)};
};
`
