/* @flow */
import styled, { css } from 'styled-components'
import Container from '@website/common/components/Container'
import THEME from './Feature28.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgbA,
  getTextStyleByAlignment
} from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  width: 100%;
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  padding-top: 0;
  .slick-slider {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 40px;
    box-sizing: border-box;
    @media only screen and (max-width: 457px) {
      padding-right: 20px;
      padding-left: 20px;
    }
    .slick-track {
      display: flex;
      align-items: flex-end;
    }
  }
  .slick-slide > div {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }
  .slick-list {
    width: 96%;
    margin: 0 auto;
  }
  .slick-initialized .slick-slide {
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 767px) {
    padding-bottom: 60px;
    .slick-slider {
      max-width: 360px;
    }
  }
`

const getRelativeTransparency = bgOverlayTransparency => {
  const MAX_TRANSPARENCY = 0.8
  const MIN_TRANSPARENCY = 0.2
  const range = MAX_TRANSPARENCY - MIN_TRANSPARENCY
  return (MAX_TRANSPARENCY - bgOverlayTransparency * range).toFixed(2)
}

const HeaderWrap = styled.div`
  width: 100%;
  overflow: hidden;
  padding-top: ${({ isEditing }) => (isEditing ? '140px' : '80px')};
  backdrop-filter: blur(15px);
  @media only screen and (max-width: 457px) {
    padding-top: ${({ isEditing }) => (isEditing ? '120px' : '60px')};
  }
  ${({ bgOverlayTransparency, ...rest }) => {
    const relativeTransparency = getRelativeTransparency(bgOverlayTransparency)
    const bgGradient = hexToRgbA(
      getStyleForProp('backgroundColor')(rest),
      relativeTransparency
    )
    return `background: linear-gradient(${bgGradient}, ${bgGradient})`
  }}
`

const Headings = styled.div`
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    width: 100%;
    margin-top: 30px;
    p {
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const SliderTitle = styled.h2`
  color: ${getStyleForProp('block')};
  ${getTextStyleByAlignment};
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid ${getStyleForProp('block')};
`

const SliderParagraph = styled.p`
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('block')};
`

const SecondBlockTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;

  .control-container:nth-child(1) {
    width: 60%;
    margin-bottom: 0.3em;
    & > p {
      width: auto;
      margin-bottom: 0;
    }
  }

  .control-container:nth-child(2) {
    width: 30%;
    margin-bottom: 0.3em;
    & > p {
      width: auto;
      margin-bottom: 0;
    }
  }
`

const SubBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageWrapper = styled.div`
  position: relative;
  padding-top: 117.41%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 117.41%;
    position: relative;
    border-radius: ${getStyleForProp('cardRadius')};
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  }
`

const BottomContentWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 90%;
  margin: 30px auto auto auto;
  max-width: 1100px;
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    box-sizing: border-box;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  ${props =>
    props.animate &&
    css`
      ${SliderTitle}, ${SliderParagraph}, ${SecondBlockTitleWrap}, ${SubBlockContainer}, ${ImageWrapper} {
        animation: section-up 700ms ease-out;
      }
      @keyframes section-up {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0.5;
        }
        100% {
          opacity: 1;
        }
      }
    `}
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  ${getTextStyleByAlignment};
`

const MenuItemWrap = styled.div`
  display: flex;
  width: 100%;
  max-width: 1414px;
  min-width: 600px;
  overflow-x: scroll;
  justify-content: center;
  margin: auto;
`

const MenuTitle = styled.p`
  color: ${getStyleForProp('tabItem')};
  font-weight: 700;
`

const BlockTitle = styled.p`
  color: ${getStyleForProp('block')};
  width: 100%;
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('block')};
  width: 100%;
  text-align: right;
`

const SubBlockWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  color: ${getStyleForProp('block')};

  .control-container:nth-child(1) {
    width: 60%;
    & > p {
      width: auto;
    }
  }

  .control-container:nth-child(2) {
    width: 30%;
    & > p {
      width: auto;
    }
  }
`

const Circle = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: ${getStyleForProp('tabItem')};
  }
`

const IconWrap = styled.div``

const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  margin: 0 0 15px 0;
  user-select: none;
`

const IconContainer = styled.button`
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  z-index: 1;
  padding: 0;
  border: none;
  width: 30px;
  height: 30px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: transparent;
  transition: all 0.1s linear;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  > div {
    width: 30px;
    height: 30px;
  }
  &.slick-next {
    right: 40px;
    @media only screen and (max-width: 457px) {
      right: 20px;
    }
  }
  &.slick-prev {
    left: 40px;
    @media only screen and (max-width: 457px) {
      left: 20px;
    }
  }
  svg {
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
    max-width: unset;
    max-height: unset;
  }
`

const ControlContainer = styled.div`
  display: flex;
  padding: ${({ isEditing }) => (isEditing ? '50px 0 0 0' : '20px 0 0 0')};
  max-width: 180px;
  min-width: 180px;
  outline: none;
  margin: 0 18px;
`

const ItemWrapper = styled.div`
  position: relative;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: all 0.1s linear;
  &:before {
    content: '';
    position: absolute;
    height: 8px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0px;
    left: 50%;
    transition: all ease-in-out 0.2s;
    background-color: ${getStyleForProp('main')};
    border-top-left-radius: ${getStyleForProp('borderRadius')};
    border-top-right-radius: ${getStyleForProp('borderRadius')};
  }
  &:hover {
    &::before {
      width: 100%;
      left: 0;
    }
    ${MenuTitle} {
      color: ${getStyleForProp('main')} !important;
      opacity: 1 !important;
    }
    ${Circle} {
      svg {
        fill: ${getStyleForProp('main')};
        opacity: 1;
      }
    }
  }
  ${({ isActive, ...rest }) =>
    isActive
      ? `
      ${Circle} {
        svg {
          fill: ${getStyleForProp('main')(rest)};
          opacity: 1;
        }
      }
      ${MenuTitle} {
        color: ${getStyleForProp('main')(rest)} !important;
        opacity: 1 !important;
      }
      &:before {
        content: '';
        position: absolute;
        height: 8px;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        left: 0;
        transition: all ease-in-out .2s;
        background-color: ${getStyleForProp('main')(rest)};
        border-top-left-radius: ${getStyleForProp('borderRadius')(rest)};
        border-top-right-radius: ${getStyleForProp('borderRadius')(rest)};
      }
    `
      : `none`};
  padding: 20px 0;
  cursor: pointer;
  border-radius: ${getStyleForProp('cardRadius')};
`

const ImgControlContainer = styled.div`
  width: 35%;
  max-width: 385px;
  margin: 0 auto;
  box-sizing: border-box;
  @media only screen and (max-width: 1023px) {
    width: 100%;
    max-width: 510px;
  }
`

const DescriptionContainer = styled.div`
  width: 63.25%;
  max-width: 695px;
  box-sizing: border-box;
  @media only screen and (max-width: 1023px) {
    width: 100%;
    max-width: 510px;
    margin: 36px auto 0 auto;
  }
`

const DescriptionWrapper = styled.div`
  position: relative;
  height: calc(100% - 30px);
  margin: 15px auto;
  padding: 30px 24px 30px 30px;
  box-sizing: border-box;
  background-color: ${getStyleForProp('main')};
`

const LineTop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -15px;
  height: 16px;
  overflow: hidden;
  .cls-1 {
    fill: ${getStyleForProp('main')};
  }
`

const LineBottom = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -15px;
  height: 16px;
  transform: rotate(180deg);
  overflow: hidden;
  .cls-1 {
    fill: ${getStyleForProp('main')};
  }
`
const SecondBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

export default {
  StyledContainer,
  HeaderWrap,
  Title,
  Paragraph,
  MenuItemWrap,
  MenuTitle,
  SubBlockContainer,
  SubBlockWrap,
  SecondBlockContainer,
  SecondBlockTitleWrap,
  SliderTitle,
  SliderParagraph,
  BlockTitle,
  BlockParagraph,
  Circle,
  IconWrap,
  IconWrapper,
  IconContainer,
  ItemWrapper,
  Headings,
  BottomContentWrap,
  DescriptionContainer,
  DescriptionWrapper,
  LineBottom,
  LineTop,
  ControlContainer,
  Img,
  ImageWrapper,
  ImgControlContainer
}
