//@flow
import React, {
  useCallback,
  useState,
  useRef,
  useContext,
  useMemo
} from 'react'
import { connect } from 'react-redux'

import { Divider, Button, Wrapper } from '../styled'
import * as Styled from './styled'
import { TLanguagesDropdownProps } from './types'
import Content from './Content'
import FlyOut from '@editor/common/lib/FlyOutMenu/FlyOut'
import MobilePopup from '@editor/common/lib/Popup/MobilePopup'
import TooltipWrapper from '@website/common/components/TooltipWrapper'
import { translate } from '@editor/common/utils/translations'
import { getFlagUrl } from '@website/common/utils/website-languages'
import { MobileDetectContext } from '@contexts'
import { selectWebsiteLanguagesData } from '@selectors/website-languages'
import { selectWebsiteActiveLanguageCode } from '@selectors'

const LanguagesDropdown = ({
  websiteActiveLanguageCode,
  websiteLanguagesData
}: TLanguagesDropdownProps) => {
  const [isOpen, setOpenedState] = useState(false)
  const dropdownRef = useRef(null)
  const isMobile = useContext(MobileDetectContext)

  const toggle = useCallback(() => {
    setOpenedState(!isOpen)
  }, [isOpen])

  const closeDropdown = useCallback(() => {
    setOpenedState(false)
  }, [])

  const dropdownActiveLanguage = useMemo(
    () =>
      websiteLanguagesData.find(
        ({ lang }) => lang.code === websiteActiveLanguageCode
      ),
    [websiteActiveLanguageCode, websiteLanguagesData]
  )

  return (
    <>
      <Styled.GlobalStyles />
      <Wrapper>
        <Divider />
        <TooltipWrapper
          text={isOpen ? '' : translate('website_language_label')}
          position="bottom"
        >
          <Button
            ref={dropdownRef}
            onClick={toggle}
            isActive={isOpen}
            className="languages-dropdown"
          >
            <Styled.Flag
              src={getFlagUrl(dropdownActiveLanguage?.lang?.flagCode)}
              alt={dropdownActiveLanguage?.lang?.name}
            />
          </Button>
        </TooltipWrapper>
        <Divider />
      </Wrapper>

      {isMobile ? (
        <MobilePopup
          isOpen={isOpen}
          onClose={closeDropdown}
          title={translate('your_website_languages')}
        >
          <Content
            languagesData={websiteLanguagesData}
            closeLanguagesDropdown={closeDropdown}
          />
        </MobilePopup>
      ) : (
        <FlyOut
          isOpen={isOpen}
          onClose={closeDropdown}
          anchorEl={dropdownRef.current}
          className={'languages-flyout'}
          animationPrefix={'languages-flyout'}
          backdropDisablingSelectors={['.languages-dropdown']}
        >
          <Content
            languagesData={websiteLanguagesData}
            closeLanguagesDropdown={closeDropdown}
          />
        </FlyOut>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  websiteActiveLanguageCode: selectWebsiteActiveLanguageCode(state),
  websiteLanguagesData: selectWebsiteLanguagesData(state)
})

export default connect(mapStateToProps)(LanguagesDropdown)
