/* @flow */
import React, { useContext, memo, useRef } from 'react'
import S from './Feature35.styled'
import { validation } from './Feature35.consts'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import AnimationSvg from './AnimationSvg'
import { DispatchContext, EditingContext } from '@contexts'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'
import useOnScreen from '@website/common/hooks/useOnScreen'
import { translate } from '@editor/common/utils/translations'

const Feature35 = memo(props => {
  const {
    data: {
      featureParts,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      isAnimated
    }
  } = props

  const animatedRef = useRef(null)
  const dispatcher = useContext(DispatchContext)
  const { isEditing } = useContext(EditingContext)
  const Actions = ActionGetter(dispatcher)
  const isOnScreen = useOnScreen(animatedRef)
  const isAnimationEnabled = isAnimated && isOnScreen && !isEditing

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <WMContainer>
        <S.BlocksWrap
          blockCount={featureParts.length}
          ref={animatedRef}
          isFourBlock={featureParts.length === 4}
        >
          <Mapper
            data={featureParts}
            render={(featureBlock, idx) => (
              <S.BlockItemWrap key={idx}>
                <ControlsWithModalOpener
                  actions={Actions.featureActions({
                    idx,
                    showRemove:
                      featureParts.length !== validation.featureParts.min
                  })}
                  style={{ alignItems: 'center' }}
                >
                  <S.AnimationContainer>
                    <AnimationSvg
                      idx={idx}
                      className="waveImg"
                      isAnimationEnabled={isAnimationEnabled}
                    />
                    <ControlsWithModalOpener
                      actions={Actions.getIconActions({
                        idx,
                        showRemove: false
                      })}
                    >
                      <S.Circle>
                        <S.Icon size="large" name={featureBlock.icon} />
                      </S.Circle>
                    </ControlsWithModalOpener>
                  </S.AnimationContainer>
                  <EditableContent
                    text={featureBlock.blockTitle}
                    as={S.Title}
                    required={!featureBlock.blockDescription}
                    isEditing={isEditing}
                    maxCount={validation.blockTitleMaxChar}
                    onChange={(newTitle: string) =>
                      Actions.changeBlockTitle(newTitle, idx)
                    }
                    className="WM_GLOBAL_heading32"
                    alignment={featureBlock.titleAlignment}
                    changeAlignment={Actions.changeTitleAlignment(idx)}
                  />

                  <EditableContent
                    className="WM_GLOBAL_paragraph18"
                    text={featureBlock.blockDescription}
                    required={!featureBlock.blockTitle}
                    as={S.Description}
                    maxCount={validation.blockParagraphMaxChar}
                    onChange={(newDescription: string) =>
                      Actions.changeBlockContent(idx, newDescription)
                    }
                    alignment={featureBlock.paragraphAlignment}
                    changeAlignment={Actions.changeParagraphAlignment(idx)}
                  />
                  <AdditionalLink
                    idx={idx}
                    additionalLinkText={featureBlock.additionalText}
                    additionalLinkUrl={featureBlock.additionalUrl}
                  />
                </ControlsWithModalOpener>
              </S.BlockItemWrap>
            )}
          />
        </S.BlocksWrap>
        <AddButton
          style={{ marginTop: '2rem' }}
          onAdd={Actions.addBlock}
          toShow={featureParts.length < validation.featureParts.max}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_block_label')}
        </AddButton>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Feature35
