/* @flow */
import React from 'react'
import styled from 'styled-components'

const fromProps = prop => props => props[prop]

const Text = styled.span`
  color: ${fromProps('color')};
  font-size: ${fromProps('size')};
  text-transform: ${fromProps('transform')};
`

const _Text = props => {
  return <Text {...props}>{props.children}</Text>
}

export default _Text
