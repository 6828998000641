/* @flow */
import React from 'react'
import Swipe from '../Swipe'
import S from './Header30.styled'
import THEME from './Header30.theme'
import { validation } from './Header30.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import NavBar from '@website/common/components/NavBar'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { EditingContext, DispatchContext } from '@contexts'
import SingleButton from '@website/common/components/SingleButton'
import SocialLinks from '@website/common/components/SocialLinks'
import { translate } from '@editor/common/utils/translations'
import { withTheme } from 'styled-components'

class Header30 extends React.PureComponent<void, void> {
  dispatcher = this.context

  state = { navBarHeight: 0 }

  handleHeightChange = height => {
    this.setState({ navBarHeight: height })
  }

  renderDots = (isActive: boolean) => <S.Dot isActive={isActive} />
  renderContent =
    (isEditing: boolean) => (slideIdx: number, stopAutoPlay: Function) => {
      const Actions = ActionGetter(this.dispatcher)
      const { slides } = this.props.data
      return (
        <S.SliderItemWrapper isEditing={isEditing}>
          <ControlsWithImageCropper
            key={slideIdx}
            toPropagate={false}
            actions={Actions.sliderActions({
              showRemove: slides.length > 1,
              slideIdx,
              clickHandlers: {
                change: stopAutoPlay,
                crop: stopAutoPlay
              },
              imageUrl: slides[slideIdx].url
            })}
          >
            <Image
              isEditing={isEditing}
              as={S.Img}
              defaultImgSrc={slides[slideIdx].url}
              sizes={slides[slideIdx].imgDimensions}
              alt="Section image"
              asProps={{
                imgCropParams: slides[slideIdx].imgCropParams
              }}
            />
          </ControlsWithImageCropper>
        </S.SliderItemWrapper>
      )
    }

  renderSlider = (Actions, isEditing) => {
    const { slides, socials } = this.props.data

    return (
      <S.SliderWrapper className="image-wrap">
        <Swipe
          dataArray={slides}
          renderDots={this.renderDots}
          renderContent={this.renderContent(isEditing)}
        />
        <S.AddButtonWrap>
          <AddButton
            onAdd={Actions.addSlide}
            toShow={slides.length < validation.slides.max}
          >
            <Icon name="glyph-add" className="icon" size="normal" />
            {translate('add_slide_label')}
          </AddButton>
        </S.AddButtonWrap>
        <SocialLinks
          className="header-socials"
          socials={socials}
          componentDispatcher={this.dispatcher}
          socialsMaxCount={validation.socials.max}
        />
      </S.SliderWrapper>
    )
  }

  renderTextContent = Actions => {
    const {
      data: {
        title,
        paragraph,
        btnText,
        btnUrl,
        titleAlignment,
        paragraphAlignment
      }
    } = this.props
    return (
      <S.HeaderContentWrapper>
        <S.ContentContainer>
          <EditableContent
            text={title}
            as={S.Title}
            alignment={titleAlignment}
            required={validation.title.required}
            maxCount={validation.headingMaxChar}
            className="WM_GLOBAL_heading54"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            as={S.Paragraph}
            alignment={paragraphAlignment}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
          <SingleButton btnUrl={btnUrl} btnText={btnText} />
        </S.ContentContainer>
      </S.HeaderContentWrapper>
    )
  }

  render() {
    const Actions = ActionGetter(this.dispatcher)
    const {
      data: {
        logoSrc,
        logoColor,
        logoWidth,
        logoDimensions,
        backgroundImgUrl,
        bgOverlayTransparency,
        backgroundImgDimensions
      }
    } = this.props

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => (
          <S.StyledContainer
            isEditing={isEditing}
            backgroundImgUrl={backgroundImgUrl}
            backgroundImgDimensions={backgroundImgDimensions}
            bgOverlayTransparency={bgOverlayTransparency}
          >
            <NavBar
              handleHeightChange={this.handleHeightChange}
              theme={THEME}
              logoProps={{
                logoSrc,
                logoColor,
                logoWidth,
                logoSizes: logoDimensions
              }}
            />
            <S.WmCustomContainer topOffset={this.state.navBarHeight}>
              {this.renderTextContent(Actions)}
              {this.renderSlider(Actions, isEditing)}
            </S.WmCustomContainer>
          </S.StyledContainer>
        )}
      </EditingContext.Consumer>
    )
  }
}

Header30.contextType = DispatchContext

export default withTheme(Header30)
