/* @flow */
import React, { useContext, useCallback, useState } from 'react'
import './Header5.styles.sass'
import S from './Header5.styled'
import meta from './Header5.meta'
import { validation } from './Header5.conts'
import THEME from './Header5.theme'
import ActionGetter from './Actions'
import Mapper from '@website/common/components/Mapper'
import NavBar from '@website/common/components/NavBar'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import Image from '@website/common/components/Image'
import LinkWrapper from '@website/common/components/LinkWrapper'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, withEditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const ContainerWithEditingContext = withEditingContext(S.Container)
// TODO: meta usage should be handled

const Header5 = props => {
  const {
    data: {
      title,
      paragraph,
      buttons,
      logoSrc,
      logoWidth,
      logoColor,
      logoDimensions,
      backgroundImgDimensions,
      appleBackground,
      backgroundImgUrl,
      bgOverlayTransparency,
      imgCropParams: mobileImgCropParams,
      appleBackgroundDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const handleHeightChange = useCallback(height => {
    setNavBarHeight(height)
  }, [])

  return (
    <ContainerWithEditingContext
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <NavBar
        handleHeightChange={handleHeightChange}
        theme={THEME}
        logoProps={{
          logoSrc: logoSrc,
          logoColor: logoColor,
          logoWidth: logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <S.WmCustomContainer
        className="header-5-container"
        topOffset={navBarHeight + 40}
      >
        <Show when={[title, paragraph, buttons.length > 0]}>
          <div className="header-content">
            <EditableContent
              text={title}
              as={S.Title}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_heading"
              onChange={(newTitle: string) =>
                dispatcher(Actions.changeTitle(newTitle))
              }
            />
            <EditableContent
              text={paragraph}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={(newParagraph: string) =>
                dispatcher(Actions.changeParagraph(newParagraph))
              }
            />
            <S.BtnsWrap className="WM_GLOBAL_secondary-font">
              <Mapper
                data={buttons}
                render={(btn: string, btnIdx: number) => {
                  return (
                    <ControlsWithModalOpener
                      key={btnIdx}
                      actions={Actions.btnActions(btnIdx, {
                        text: btn.buttonText,
                        link: btn.buttonUrl
                      })}
                    >
                      <S.LinkWrapper isTertiary={btn.type === 'tertiary'}>
                        <LinkWrapper
                          text={btn.buttonText}
                          url={btn.buttonUrl}
                          linkProps={{
                            className: 'rf-comp-btn'
                          }}
                        />
                      </S.LinkWrapper>
                    </ControlsWithModalOpener>
                  )
                }}
              />
              <AddButton
                onAdd={() => dispatcher(Actions.addButton())}
                toShow={buttons.length < validation.buttons.max}
                rounded
              >
                {translate('add_button_label')}
              </AddButton>
            </S.BtnsWrap>
          </div>
        </Show>
        <div className="frame-wrap">
          <S.FrameCircle>
            <S.PhoneWrap>
              <S.Phone>
                <ControlsWithImageCropper
                  actions={Actions.mockupActions(
                    appleBackground !== meta.defaultProps.appleBackground,
                    appleBackground
                  )}
                >
                  <Image
                    alt="Web page "
                    as={S.MobileImg}
                    asProps={{ imgCropParams: mobileImgCropParams }}
                    defaultImgSrc={appleBackground}
                    sizes={appleBackgroundDimensions}
                  />
                </ControlsWithImageCropper>
                <S.Dinamic
                  src="https://static.rfstat.com/renderforest/images/website_maker_images/phone-dinamic-min.png"
                  alt="mobile dynamic"
                />
                <S.SilentMode />
                <S.LockScreen />
                <S.VoiceWrap>
                  <S.VoiceBtn />
                  <S.VoiceBtn />
                </S.VoiceWrap>
              </S.Phone>
            </S.PhoneWrap>
          </S.FrameCircle>
        </div>
      </S.WmCustomContainer>
    </ContainerWithEditingContext>
  )
}

export default Header5
