/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Text8.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'

const getStyleForProp = styleProvider(THEME)

const FAILED_BG_URL =
  'https://static.rfstat.com/renderforest/images/website_maker_images/Background_Fail_repeat_element.jpg'

export const getBackgroundStyles = theme => props => {
  const getStyleForProp = styleProvider(theme)
  const { backgroundImgUrl, backgroundImgDimensions, isEditing } = props
  const { failed, isLoading } = backgroundImgUrl || {}
  const hasDimensions =
    backgroundImgDimensions && !!Object.keys(backgroundImgDimensions).length

  if (isEditing) {
    const showInvalidBg = failed || (backgroundImgUrl && !hasDimensions)
    if (isLoading) {
      return css`
        background: url(${backgroundImgUrl});
      `
    } else if (showInvalidBg) {
      return css`
        background: url(${FAILED_BG_URL}) center/340px 330px;
      `
    }
  }

  if (!hasDimensions) {
    return css`
      background: ${getStyleForProp('titleBackground')(props)};
    `
  }

  const getBgBySize = size => {
    const bgImageUrl = backgroundImgDimensions && backgroundImgDimensions[size]
    return css`
      background: url(${bgImageUrl});
    `
  }

  const bgStyles = css`
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `

  return css`
    ${getBgBySize('xlarge')};
    ${bgStyles};
    @media only screen and (max-width: 1440px) {
      ${getBgBySize('large')};
      ${bgStyles};
    }
    @media only screen and (max-width: 768px) {
      ${getBgBySize('medium')};
      ${bgStyles};
    }
    @media only screen and (max-width: 480px) {
      ${getBgBySize('small')};
      ${bgStyles};
    }
  `
}

const stylesLineAlignment = {
  default: '',
  left: css`
    margin-right: auto;
  `,
  center: css`
    margin-right: auto;
    margin-left: auto;
  `,
  right: css`
    margin-left: auto;
  `
}

const getLineStyleByAlignment = ({ alignment }) =>
  stylesLineAlignment[alignment]

const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 80px 0;
  background-color: ${getStyleForProp('background')};
  flex-direction: ${getStyleForProp('orientation')};
  padding: ${({ isEditing }) => (isEditing ? '140px 0 100px 0' : '80px 0')};
  @media only screen and (max-width: 600px) {
    padding: 60px 0;
  }
`

const WmCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: space-between;
  /* align-items: flex-start; */
  @media only screen and (max-width: 1023px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .buttons-block {
    margin-left: -15px;
  }
`

const LeftBlock = styled.div`
  max-width: 100%;
  width: 100%;
  position: relative;
  @media only screen and (min-width: 1023px) {
    margin-right: 30px;
    max-width: 400px;
  }
  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1 {
      margin-bottom: 0;
    }
  }
`

const MainTitle = styled.h1`
  ${getBackgroundStyles(THEME)};
  ${getTextStyleByAlignment};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1.15;
  font-weight: 800;
  margin-bottom: 20px;
  overflow: hidden;
`

const Title = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  max-width: 400px;
  width: 100%;
  @media only screen and (min-width: 1023px) {
    margin: 0 30px 0 0;
  }
  @media only screen and (max-width: 1023px) {
    max-width: initial;
  }
`

const TextButtons = styled.div`
  width: 100%;
  max-width: 900px;
  position: relative;
  padding: 0 0 0 60px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  @media only screen and (max-width: 1023px) {
    padding: 0;
  }

  > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-bottom: 0;
    }
  }
  .buttons-block {
    padding-top: 0;
  }
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};

  ${({ isEditing }) =>
    isEditing
      ? css``
      : css`
          padding-top: 0.8rem;
          padding-bottom: 0.8rem;
        `};

  @media only screen and (max-width: 1023px) {
    padding-top: 0;
    padding-bottom: 0;
  }
  & > div {
    margin-top: -0.8rem;
  }

  & > .buttons-block {
    padding-top: 30px;
  }
`

const Line = styled.div`
  width: 2px;
  background: ${getStyleForProp('heading')};
  position: relative;
  @media only screen and (max-width: 1023px) {
    width: 80%;
    height: 2px;
    position: initial;
    left: initial;
    top: initial;
    margin: 30px 0 40px 0;
    ${getLineStyleByAlignment}
  }
`

export default {
  Container,
  MainTitle,
  LeftBlock,
  WmCustomContainer,
  TextButtons,
  Title,
  Paragraph,
  Line
}
