/* @flow */
import React, { useContext, memo } from 'react'
import * as S from './Text21.styled'
import { validation } from './Text21.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const Text21 = memo(props => {
  const {
    data: {
      title,
      paragraph1,
      paragraph2,
      titleAlignment,
      paragraph1Alignment,
      paragraph2Alignment,
      imageUrl,
      imgCropParams,
      imgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.WmCustomContainer>
        <S.HeaderContent>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.titleMaxChar}
            required={!paragraph1 && !paragraph2}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <S.ImageWrapper isEditing={isEditing}>
            <ControlsWithImageCropper
              actions={Actions.imgActions(imageUrl)}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                alt="Section img"
                defaultImgSrc={imageUrl}
                asProps={{ imgCropParams }}
                sizes={imgDimensions}
              />
            </ControlsWithImageCropper>
          </S.ImageWrapper>
          <S.TextContainer>
            <EditableContent
              text={paragraph1}
              alignment={paragraph1Alignment}
              as={S.Paragraph}
              required={!title && !paragraph2}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph1}
              changeAlignment={Actions.changeParagraph1Alignment}
            />
            <EditableContent
              text={paragraph2}
              alignment={paragraph2Alignment}
              as={S.Paragraph}
              required={!title && !paragraph1}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph2}
              changeAlignment={Actions.changeParagraph2Alignment}
            />
          </S.TextContainer>
        </S.HeaderContent>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text21
