/* @flow */
import React, { useState, useContext, memo } from 'react'
import S from './Header29.styled'
import THEME from './Header29.theme'
import { validation } from './Header29.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import Mapper from '@website/common/components/Mapper'
import Image from '@website/common/components/Image'
import NavBar from '@website/common/components/NavBar'
import Socials from '@website/common/components/Socials'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'

const Header29 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      gallery,
      logoSrc,
      logoColor,
      logoWidth,
      logoDimensions,
      socials
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  const isNavbarMissing = !isEditing && socials.length === 0 && !logoSrc

  return (
    <S.StyledContainer topOffset={navBarHeight} isEditing={isEditing}>
      <NavBar
        alwaysShowBackground
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || socials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={socials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.Row>
        <Show when={[title]}>
          <S.TextContent isEditing={isEditing}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              onChange={Actions.changeTitle}
              className="WM_GLOBAL_heading42"
              changeAlignment={Actions.changeTitleAlignment}
            />
          </S.TextContent>
        </Show>
        <S.ImagesWrap isNavbarMissing={isNavbarMissing} isEditing={isEditing}>
          <Mapper
            data={gallery}
            render={(item: string, idx: number) => {
              return (
                <S.Item isEditing={isEditing} key={idx} className="item">
                  <S.ControlContainer>
                    <ControlsWithImageCropper
                      actions={Actions.imageActions(idx, item.imageUrl)}
                      key={idx}
                      style={{ width: '100%' }}
                      alignment={idx === 6 ? 'right' : 'left'}
                    >
                      <Image
                        as={S.Img}
                        alt="Portfolio image"
                        defaultImgSrc={item.imageUrl}
                        sizes={item.imageUrlDimensions}
                        asProps={{
                          imgCropParams: item.imgCropParams
                        }}
                      />
                    </ControlsWithImageCropper>
                  </S.ControlContainer>
                </S.Item>
              )
            }}
          />
        </S.ImagesWrap>
      </S.Row>
    </S.StyledContainer>
  )
})

export default Header29
