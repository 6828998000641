/* @flow */

module.exports = {
  id: 'Feature12',
  isUnique: false,
  thumbnailRatio: 0.58,
  label: 'Feature12',
  category: 'feature',
  colorMode: '',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/features12new-1_0.png',
  metaData: {
    title: {
      _type: 'title',
      _min: 0,
      _max: 120,
      _value: 'Wallpapers'
    },
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 1500,
      _value: 'Cheer up your day with beautiful and vivid wallpapers!'
    },
    features: {
      feat1: {
        _type: 'list',
        _value: [
          {
            backgroundImg: {
              _type: 'image',
              _dimensionsKey: 'backgroundImgDimensions',
              _optimizationSizes: [
                { _width: 800 },
                { _width: 1200 },
                { _width: 1600 },
                { _width: 2200 }
              ],
              _min: 0,
              _max: 2048,
              _value:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg'
            },
            backgroundImgDimensions: {
              small:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg',
              medium:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg',
              large:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg',
              xlarge:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg'
            },
            icon: 'icon-water',
            title: {
              _type: 'title',
              _min: 0,
              _max: 50,
              _value: 'Liquid fusion'
            },
            content: {
              _type: 'paragraph',
              _min: 0,
              _max: 100,
              _value:
                'Check our perfect combination of colors and thick liquid wallpapers.'
            },
            additionalUrl: {
              _type: 'link',
              _min: 1,
              _max: 2048,
              _value: 'http://builder.renderforestsites.com'
            },
            additionalText: {
              _type: 'linkLabel',
              _value: 'Learn more',
              _min: 1
            }
          },
          {
            backgroundImg: {
              _type: 'image',
              _dimensionsKey: 'backgroundImgDimensions',
              _optimizationSizes: [
                { _width: 800 },
                { _width: 1200 },
                { _width: 1600 },
                { _width: 2200 }
              ],
              _min: 0,
              _max: 2048,
              _value:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg'
            },
            backgroundImgDimensions: {
              small:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg',
              medium:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg',
              large:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg',
              xlarge:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg'
            },
            icon: 'icon-leaves',
            title: {
              _type: 'title',
              _min: 0,
              _max: 50,
              _value: 'Healer nature '
            },
            content: {
              _type: 'paragraph',
              _min: 0,
              _max: 100,
              _value:
                'Get a relaxing and a peaceful atmosphere with beautiful nature, trees, plants and more.'
            },
            additionalUrl: {
              _type: 'link',
              _min: 1,
              _max: 2048,
              _value: 'http://builder.renderforestsites.com'
            },
            additionalText: {
              _type: 'linkLabel',
              _value: 'Learn more',
              _min: 1
            }
          },
          {
            backgroundImg: {
              _type: 'image',
              _dimensionsKey: 'backgroundImgDimensions',
              _optimizationSizes: [
                { _width: 800 },
                { _width: 1200 },
                { _width: 1600 },
                { _width: 2200 }
              ],
              _min: 0,
              _max: 2048,
              _value:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg'
            },
            backgroundImgDimensions: {
              small:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg',
              medium:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg',
              large:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg',
              xlarge:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg'
            },
            icon: 'icon-cloud',
            title: {
              _type: 'title',
              _min: 0,
              _max: 50,
              _value: 'Moving clouds'
            },
            content: {
              _type: 'paragraph',
              _min: 0,
              _max: 100,
              _value:
                'A collection of sweetie clouds in movement for those who like to dream high.'
            },
            additionalUrl: {
              _type: 'link',
              _min: 1,
              _max: 2048,
              _value: 'http://builder.renderforestsites.com'
            },
            additionalText: {
              _type: 'linkLabel',
              _value: 'Learn more',
              _min: 1
            }
          }
        ]
      },
      feat2: {
        _type: 'list',
        _value: [
          {
            backgroundImg: {
              _type: 'image',
              _dimensionsKey: 'backgroundImgDimensions',
              _optimizationSizes: [
                { _width: 800 },
                { _width: 1200 },
                { _width: 1600 },
                { _width: 2200 }
              ],
              _min: 0,
              _max: 2048,
              _value:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg'
            },
            backgroundImgDimensions: {
              small:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg',
              medium:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg',
              large:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg',
              xlarge:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg'
            },
            icon: 'icon-umbrella',
            title: {
              _type: 'title',
              _min: 0,
              _max: 50,
              _value: 'Weather'
            },
            content: {
              _type: 'paragraph',
              _min: 0,
              _max: 100,
              _value:
                'Find incredible weather photos of all 4 seasons.  Sunny days, blowing wind, night silence, etc. '
            },
            additionalUrl: {
              _type: 'link',
              _min: 1,
              _max: 2048,
              _value: 'http://builder.renderforestsites.com'
            },
            additionalText: {
              _type: 'linkLabel',
              _value: 'Learn more',
              _min: 1
            }
          },
          {
            backgroundImg: {
              _type: 'image',
              _dimensionsKey: 'backgroundImgDimensions',
              _optimizationSizes: [
                { _width: 800 },
                { _width: 1200 },
                { _width: 1600 },
                { _width: 2200 }
              ],
              _min: 0,
              _max: 2048,
              _value:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg'
            },
            backgroundImgDimensions: {
              small:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg',
              medium:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg',
              large:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg',
              xlarge:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg'
            },
            icon: 'icon-mountain',
            title: {
              _type: 'title',
              _min: 0,
              _max: 50,
              _value: 'High mountains'
            },
            content: {
              _type: 'paragraph',
              _min: 0,
              _max: 100,
              _value:
                'Breathe the fresh air of snowy mountains and fill your soul with freedom.'
            },
            additionalUrl: {
              _type: 'link',
              _min: 1,
              _max: 2048,
              _value: 'http://builder.renderforestsites.com'
            },
            additionalText: {
              _type: 'linkLabel',
              _value: 'Learn more',
              _min: 1
            }
          },
          {
            backgroundImg: {
              _type: 'image',
              _dimensionsKey: 'backgroundImgDimensions',
              _optimizationSizes: [
                { _width: 800 },
                { _width: 1200 },
                { _width: 1600 },
                { _width: 2200 }
              ],
              _min: 0,
              _max: 2048,
              _value:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg'
            },
            backgroundImgDimensions: {
              small:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg',
              medium:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg',
              large:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg',
              xlarge:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg'
            },
            icon: 'icon-chemical',
            title: {
              _type: 'title',
              _min: 0,
              _max: 50,
              _value: 'Chemical seas '
            },
            content: {
              _type: 'paragraph',
              _min: 0,
              _max: 100,
              _value:
                'Use our eye-catching chemical images to stimulate your innovative mind.'
            },
            additionalUrl: {
              _type: 'link',
              _min: 1,
              _max: 2048,
              _value: 'http://builder.renderforestsites.com'
            },
            additionalText: {
              _type: 'linkLabel',
              _value: 'Learn more',
              _min: 1
            }
          }
        ]
      }
    }
  },
  defaultProps: {
    title: 'Wallpapers',
    paragraph: 'Cheer up your day with beautiful and vivid wallpapers!',
    features: {
      feat1: [
        {
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img1-1_0.jpg'
          },
          icon: 'icon-water',
          title: 'Liquid fusion',
          content:
            'Check our perfect combination of colors and thick liquid wallpapers.',
          additionalUrl: 'http://builder.renderforestsites.com',
          additionalText: 'Learn more'
        },
        {
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img2-1_0.jpg'
          },
          icon: 'icon-leaves',
          title: 'Healer nature ',
          content:
            'Get a relaxing and a peaceful atmosphere with beautiful nature, trees, plants and more.',
          additionalUrl: 'http://builder.renderforestsites.com',
          additionalText: 'Learn more'
        },
        {
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img3-1_0.jpg'
          },
          icon: 'icon-cloud',
          title: 'Moving clouds',
          content:
            'A collection of sweetie clouds in movement for those who like to dream high.',
          additionalUrl: 'http://builder.renderforestsites.com',
          additionalText: 'Learn more'
        }
      ],
      feat2: [
        {
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img4-1_0.jpg'
          },
          icon: 'icon-umbrella',
          title: 'Weather',
          content:
            'Find incredible weather photos of all 4 seasons.  Sunny days, blowing wind, night silence, etc. ',
          additionalUrl: 'http://builder.renderforestsites.com',
          additionalText: 'Learn more'
        },
        {
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features12-4.0.0.jpg'
          },
          icon: 'icon-mountain',
          title: 'High mountains',
          content:
            'Breathe the fresh air of snowy mountains and fill your soul with freedom.',
          additionalUrl: 'http://builder.renderforestsites.com',
          additionalText: 'Learn more'
        },
        {
          backgroundImg:
            'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg',
          backgroundImgDimensions: {
            small:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg',
            medium:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg',
            large:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg',
            xlarge:
              'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/feature12-img6-1_0.jpg'
          },
          icon: 'icon-chemical',
          title: 'Chemical seas ',
          content:
            'Use our eye-catching chemical images to stimulate your innovative mind.',
          additionalUrl: 'http://builder.renderforestsites.com',
          additionalText: 'Learn more'
        }
      ]
    }
  }
}
