/* @flow */
/* eslint-disable max-statements */

import produce from 'immer'
import {
  TEXT_BLOCK_INITIAL_STATE,
  LIST_ITEM_INITIAL_STATE
} from './PrivacyPolicy3.consts'

export default function reducer(state, action: any) {
  return produce(state, draft => {
    const initialState = action.__initialData.defaultProps
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BACKGROUND_TRANSPARENCY':
        draft.backgroundTransparency = action.value
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnData } = action.value
        draft.buttonUrl = btnData.link
        draft.buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttonUrl = ''
        draft.buttonText = ''
        break
      case 'ADD_BUTTON':
        draft.buttonText = initialState.buttonText
        draft.buttonUrl = initialState.buttonUrl
        break
      case 'CHANGE_BLOCK_TITLE': {
        const { idx, newTitle } = action.value
        draft.blocks[idx].blockTitle = newTitle
        break
      }
      case 'CHANGE_SUB_BLOCK_TITLE_ALIGNMENT': {
        const { idx, subBlockIdx, alignment } = action.value
        draft.blocks[idx].subBlocks[subBlockIdx].subBlockTitleAlignment =
          alignment
        break
      }
      case 'CHANGE_SUB_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, subBlockIdx, alignment } = action.value
        draft.blocks[idx].subBlocks[subBlockIdx].subBlockParagraphAlignment =
          alignment
        break
      }
      case 'CHANGE_SUB_BLOCK_TITLE': {
        const { idx, subBlockIdx, newSubBlockTitle } = action.value
        draft.blocks[idx].subBlocks[subBlockIdx].title = newSubBlockTitle
        break
      }
      case 'CHANGE_SUB_BLOCK_PARAGRAPH': {
        const { idx, subBlockIdx, newSubBlockParagraph } = action.value
        draft.blocks[idx].subBlocks[subBlockIdx].paragraph =
          newSubBlockParagraph
        break
      }
      case 'CHANGE_LIST_ITEM': {
        const { idx, subBlockIdx, listItemIdx, newListItem } = action.value
        draft.blocks[idx].subBlocks[subBlockIdx].list[listItemIdx] = newListItem
        break
      }

      case 'REMOVE_LIST_ITEM': {
        const { idx, subBlockIdx, listItemIdx } = action.value
        draft.blocks[idx].subBlocks[subBlockIdx].list.splice(listItemIdx, 1)
        return
      }
      case 'ADD_TEXT_BLOCK': {
        const { idx, subBlockIdx } = action.value
        draft.blocks[idx].subBlocks.splice(
          subBlockIdx + 1,
          0,
          TEXT_BLOCK_INITIAL_STATE
        )
        break
      }
      case 'ADD_LIST_ITEM': {
        const { idx, subBlockIdx } = action.value
        draft.blocks[idx].subBlocks[subBlockIdx].list.push(
          LIST_ITEM_INITIAL_STATE
        )
        break
      }
      case 'ADD_BLOCK': {
        draft.blocks.push(initialState.blocks[0])
        break
      }
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'REMOVE_SUB_BLOCK_ITEM': {
        const { activeIdx, subBlockIdx } = action.value
        draft.blocks[activeIdx].subBlocks.splice(subBlockIdx, 1)
        break
      }
    }
  })
}
