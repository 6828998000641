/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Steps2.theme'
import WMContainer from '@website/common/components/WMContainer'
import { styleProvider, getBackgroundStyles } from '@website/common/utils'
import Container from '@website/common/components/Container'
import { getTextStyleByAlignment } from '@website/common/utils'
const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1100px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > :first-child {
    margin-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.4;
  margin-top: 20px;
`

const StepItemFigureWrap = styled.div`
  border-left: ${props => (props.isEven ? '1px solid #D7DEF4' : 'none')};
  border-right: ${props => (props.isEven ? 'none' : '1px solid #D7DEF4')};
  position: relative;
  width: 50%;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  padding-bottom: 50px;
  .lazy-figure {
    border-radius: ${getStyleForProp('cardRadius')};
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: unset;
    border: none;
    padding-bottom: 0;
    margin-top: 80px;
    padding-bottom: 40px;
  }
  @media only screen and (max-width: 1024px) {
    margin-top: 0;
  }
  ${({ isEven }) =>
    css`
      transform: ${isEven ? 'translateX(-1px)' : 'none'};
    `}
`

const StepCounter = styled.div`
  width: 34px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  background-color: ${getStyleForProp('background')};
  color: ${getStyleForProp('counter')};
  position: absolute;
  left: ${props => (props.position ? '-17px' : 'unset')};
  right: ${props => (props.position ? 'unset' : '-16px')};
  top: 0;
  @media only screen and (max-width: 1024px) {
    right: unset;
    left: -17px;
    bottom: -70px;
    top: initial;
  }
  @media only screen and (max-width: 600px) {
    left: -22px;
  }
`

const StepTitle = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 22px;
  font-weight: 800;
`

const StepItemContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 650px;
  padding: 0 45px;
  width: 50%;
  max-width: 50%;
  box-sizing: border-box;
  ${props =>
    props.isEven
      ? 'align-items: flex-end; text-align: right;'
      : 'align-items: flex-start;'}
  @media only screen and (max-width: 1024px) {
    align-items: flex-start;
    max-width: unset;
    width: 100%;
    margin-top: 40px;
    padding: 0 45px;
  }
  @media only screen and (max-width: 600px) {
    align-items: center;
    padding: 0 0 0 5px;
    text-align: center;
  }
  > :first-child {
    padding-top: 0;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h2 {
      padding: 0;
      margin-bottom: 0;
    }
  }
`

const StepItemFigure = styled.div`
  max-width: 700px;
  width: 100%;
  font-size: 0;
  padding-top: 0;
  padding-right: 45px;
  padding-left: 45px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    max-width: unset;
  }
  @media only screen and (max-width: 600px) {
    padding: 0;
  }
`

const FigureContainer = styled.div`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  margin: 0 auto;
  @media only screen and (max-width: 600px) {
    margin-top: 40px;
  }
`

const StepImg = styled.img`
  width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
`

const StepDescription = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  line-height: 1.4;
`

const StepsWrap = styled.article`
  padding-top: 50px;
  width: 100%;
  @media only screen and (max-width: 600px) {
    margin-top: -40px;
  }
`

const StepItemRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    border-left: 1px solid #d7def4;
    padding-bottom: 50px;
  }
  flex-direction: ${props => (props.toReverse ? 'row-reverse' : 'row')};
  @media only screen and (max-width: 600px) {
    border: none;
    padding-bottom: 0;
  }
  ${({ isEditing }) =>
    !isEditing &&
    css`
      &:last-child {
        @media only screen and (min-width: 601px) {
          ${StepItemFigureWrap} {
            padding-bottom: 0;
          }
        }
        @media only screen and (max-width: 600px) {
          &:last-child {
            padding-bottom: 0;
          }
        }
        ${StepItemContent} {
          padding-bottom: 0;
        }
      }
    `}
  &:last-child {
    padding-bottom: 0;
    ${StepCounter} {
      bottom: -70px;
    }
  }
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  Description,
  StepsWrap,
  StepTitle,
  StepItemRow,
  StepCounter,
  StepItemFigureWrap,
  StepItemContent,
  FigureContainer,
  StepItemFigure,
  StepImg,
  StepDescription
}
