/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: newTitle => dispatcher(changeTitle(newTitle)),
    changeParagraph: newParagraph => dispatcher(changeParagraph(newParagraph)),
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    imageActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'text3',
        showRemove: false,
        imageUrl
      })
  }
}
