/* @flow */
/* eslint-disable max-statements */

import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_BLOCK':
        draft.steps.push(initialState.steps[0])
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_BLOCK_SUB_TITLE': {
        const { newSubTitle, idx } = action.value
        draft.steps[idx].subTitle = newSubTitle
        break
      }
      case 'CHANGE_BLOCK_TITLE': {
        const { newTitle, idx } = action.value
        draft.steps[idx].title = newTitle
        break
      }
      case 'CHANGE_BLOCK_CONTENT':
        const { newContent, idx: _idx } = action.value
        draft.steps[_idx].content = newContent
        break
      case 'REMOVE_BLOCK':
        const { idx: idx__ } = action.value
        draft.steps.splice(idx__, 1)
        break
      case 'REMOVE_ICON': {
        const { idx } = action.value
        draft.steps[idx].icon = ''
        break
      }
      case 'ADD_ICON':
        draft.steps[action.value].icon = 'edit'
        break
      case 'CHANGE_ICON':
        const { idx: idx_, iconName } = action.value
        draft.steps[idx_].icon = iconName
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_STEP_TITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.steps[idx].stepTitleAlignment = alignment
        break
      }
      case 'CHANGE_STEP_SUBTITLE_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.steps[idx].stepSubTitleAlignment = alignment
        break
      }
      case 'CHANGE_STEP_CONTENT_ALIGNMENT': {
        const { alignment, idx } = action.value
        draft.steps[idx].stepContentAlignment = alignment
        break
      }
    }
  })
}
