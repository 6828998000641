/* @flow */
import React from 'react'
import EditableContent from '@website/common/components/EditableContent'
import { validation } from '../Contact5.consts'
import S from './Form.styled'
import ActionGetter from '../Actions'
export const isRequiredField = (key, requiredFields) =>
  requiredFields.includes(key)

export const LabelText = ({ formAction, text, label, isRequired }) => {
  return (
    <div>
      <EditableContent
        text={text}
        as={S.Label}
        required={true}
        maxCount={validation.labelMaxChar}
        onChange={newLabel => formAction(newLabel, `${label}Label`)}
        className="WM_GLOBAL_secondary-font"
      />
      {isRequired && (
        <S.ReqPointer className="required-pointer">*</S.ReqPointer>
      )}
    </div>
  )
}

export const LabelRenderer = props => {
  const { text, label, dispatcher, isEditing, isRequired } = props
  const Actions = ActionGetter(dispatcher)
  return (
    <S.LabelContainer className="label-container">
      <LabelText
        text={text}
        label={label}
        formAction={(...args) => Actions.changeFormData(...args)}
        isRequired={isRequired}
      />
      {isEditing ? (
        <S.ReqIconWrap
          onClick={() => {
            Actions.toggleRequiredField(label)
          }}
          isRequired={isRequired}
          toShow={isEditing}
        >
          <S.RequiredIcon
            name="icon-asterisk"
            size="small"
            isRequired={isRequired}
            isEditing={isEditing}
          />
        </S.ReqIconWrap>
      ) : null}
    </S.LabelContainer>
  )
}
