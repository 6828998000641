import styled from 'styled-components'

export const RippleLoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Ellipsis = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ isSmall }) => (isSmall ? '56px' : '64px')};
  height: 11px;

  div {
    position: absolute;
    width: ${({ isSmall }) => (isSmall ? '6px' : '11px')};
    height: ${({ isSmall }) => (isSmall ? '6px' : '11px')};
    border-radius: 50%;
    background: ${({ color }) => color};
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 6px;
      animation: loader-ellipsis1 0.6s infinite;
    }

    &:nth-child(2) {
      left: 6px;
      animation: loader-ellipsis2 0.6s infinite;
    }

    &:nth-child(3) {
      left: 26px;
      animation: loader-ellipsis2 0.6s infinite;
    }

    &:nth-child(4) {
      left: 45px;
      animation: loader-ellipsis3 0.6s infinite;
    }
  }

  @keyframes loader-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes loader-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes loader-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(19px, 0);
    }
  }
`
