/* @flow */
import React, { useContext, memo } from 'react'
import S from './Portfolio6_2.styled'
import { validation } from './Portfolio6_2.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Portfolio6_2 = memo(props => {
  const {
    data: {
      title,
      subTitle,
      paragraph,
      images,
      titleAlignment,
      paragraphAlignment,
      subTitleAlignment
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.LeftLine />
      <WMContainer>
        <S.HeaderContent>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.titleMaxChar}
            required={validation.title.required}
            className="WM_GLOBAL_heading72"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={subTitle}
            alignment={subTitleAlignment}
            as={S.SubTitle}
            required={validation.subTitle.required}
            maxCount={validation.subTitleMaxChar}
            className="WM_GLOBAL_heading20"
            onChange={Actions.changeSubTitle}
            changeAlignment={Actions.changeSubtitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Paragraph}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.HeaderContent>

        <Mapper
          data={images}
          render={(img: string, blockIdx: number) => {
            return (
              <React.Fragment key={blockIdx}>
                <S.GridWrapper key={blockIdx}>
                  <Mapper
                    data={img}
                    render={(item: string, idx: number) => {
                      return (
                        <S.RatioWrapper
                          className={'ratio-wrapper-' + (idx + 1)}
                        >
                          <S.ImageWrapper
                            className={'img-wrapper-' + (idx + 1)}
                          >
                            <S.ControlContainer
                              className={'ControlContainer-' + (idx + 1)}
                            >
                              <ControlsWithImageCropper
                                style={{ width: '100%', height: '100%' }}
                                actions={Actions.imageActions(
                                  blockIdx,
                                  idx,
                                  item.imgUrl
                                )}
                              >
                                <Image
                                  as={S.Img}
                                  alt="Gallery"
                                  sizes={item.imgDimensions}
                                  asProps={{
                                    imgCropParams: item.imgCropParams
                                  }}
                                  defaultImgSrc={item.imgUrl}
                                />
                              </ControlsWithImageCropper>
                            </S.ControlContainer>
                          </S.ImageWrapper>
                        </S.RatioWrapper>
                      )
                    }}
                  />
                </S.GridWrapper>
                <If
                  condition={isEditing}
                  then={() => (
                    <S.AddButtonsWrap>
                      <AddButton
                        onAdd={() => Actions.addBlock(blockIdx)}
                        toShow={images.length < validation.images.max}
                        style={{ margin: '10px 6px 16px 6px' }}
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_block_label')}
                      </AddButton>
                      <AddButton
                        onAdd={() => Actions.removeBlock(blockIdx)}
                        toShow={images.length !== 1}
                        style={{
                          margin: '10px 6px 16px 6px',
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        <Icon
                          name="glyph-remove"
                          className="icon"
                          size="normal"
                        />
                        {translate('remove_block_label')}
                      </AddButton>
                    </S.AddButtonsWrap>
                  )}
                />
              </React.Fragment>
            )
          }}
        />
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Portfolio6_2
