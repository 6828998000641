/* @flow */
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addButton,
  addBlock,
  addLink,
  addSocial
} from '@website/components/CommonActions'

import {
  removeBlockActionMaker,
  buttonModalActionMaker,
  cropableImageModalActionMaker,
  linkActionMaker,
  socialActionMaker
} from '@website/components/ActionMakers'

export default dispatch => {
  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const changeBlockTitle = (newBlockTitle: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE',
      value: { newBlockTitle, idx }
    })
  }

  const changeBlockParagraph = (newBlockParagraph: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH',
      value: { newBlockParagraph, idx }
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const blockImageActions = ({ idx, clickHandler, imageUrl }) =>
    cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'header44BlockImages',
      clickHandlers: {
        change: clickHandler,
        crop: clickHandler
      },
      showRemove: false,
      imageUrl,
      additionalArgs: {
        idx
      },
      actions: {
        change: ({ url, dimensions, undoable }) => ({
          type: 'CHANGE_BLOCK_IMAGE',
          value: { idx, url, dimensions },
          undoable
        }),
        crop: ({ imgCropParams, undoable }) => ({
          type: 'SET_BLOCK_IMG_CROP_PARAMS',
          value: { imgCropParams, idx },
          undoable
        })
      }
    })

  const _addSocial = () => dispatch(addSocial())

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    getButtonActions: buttonModalActionMaker(dispatch),
    addButton: () => dispatch(addButton()),
    changeBlockTitle,
    changeBlockParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    addBlock: () => dispatch(addBlock()),
    blockActions: removeBlockActionMaker(dispatch),
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatch)({
        showRemove: false,
        keyForSizes: 'header44MainImage',
        imageUrl
      }),
    blockImageActions: blockImageActions,
    addLink: idx => dispatch(addLink(idx)),
    linkActions: linkActionMaker(dispatch),
    socialActions: socialActionMaker(dispatch),
    addSocial: _addSocial
  }
}
