import styled, { css } from 'styled-components'

import {
  LeftButton,
  RightButton
} from '@editor/common/styled-components/choose-component-template'
import { styleByLang } from '@editor/common/utils/style-by-lang'

const buttonCustomWidth110 = {
  selectBtnWidth: '110px',
  replaceBtnWidth: '120px',
  addBtnWidth: '120px'
}

const stylesObj = {
  ru: {
    selectBtnWidth: '110px',
    replaceBtnWidth: '125px',
    addBtnWidth: '125px'
  },
  tr: {
    selectBtnWidth: '90px',
    replaceBtnWidth: '95px',
    addBtnWidth: '95px'
  },
  de: {
    ...buttonCustomWidth110,
    replaceBtnWidth: '115px',
    addBtnWidth: '130px'
  },
  es: {
    selectBtnWidth: '140px',
    replaceBtnWidth: '135px',
    addBtnWidth: '110px'
  },
  fr: {
    selectBtnWidth: '140px',
    replaceBtnWidth: '125px',
    addBtnWidth: '110px'
  },
  pt: {
    selectBtnWidth: '120px',
    replaceBtnWidth: '115px',
    addBtnWidth: '115px'
  },
  jp: {
    selectBtnWidth: '80px',
    replaceBtnWidth: '105px',
    addBtnWidth: '80px'
  },
  default: {
    selectBtnWidth: '94px',
    replaceBtnWidth: '108px',
    addBtnWidth: '80px'
  }
}
const getStylesByLang = styleByLang(stylesObj)

export const SelectButton = styled(LeftButton)`
  &:hover {
    width: ${({ isSmallerGrid }) =>
      isSmallerGrid ? '36px' : getStylesByLang('selectBtnWidth')};
  }
  @media only screen and (max-width: 768px) {
    width: ${getStylesByLang('selectBtnWidth')};
  }
`

const addBtnWidth = getStylesByLang('addBtnWidth')
export const AddButton = styled(RightButton)`
  width: 36px;
  &:hover {
    width: ${({ isSmallerGrid }) =>
      isSmallerGrid ? '36px' : getStylesByLang('addBtnWidth')};
  }
  @media only screen and (max-width: 768px) {
    width: ${addBtnWidth};
  }
`

const replaceBtnWidth = getStylesByLang('replaceBtnWidth')
export const ReplaceButton = styled(RightButton)`
  width: ${replaceBtnWidth};
  &:hover {
    background: transparent linear-gradient(180deg, #6ea0ff 0%, #518dff 100%);
  }
  @media only screen and (max-width: 768px) {
    width: ${replaceBtnWidth};
  }
`
