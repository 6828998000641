export const KD_DATA = [
  {
    label: 'Very easy',
    color: '#009f81',
    min: 0,
    max: 14,
    tooltipText: 'Your chance to start ranking new pages.'
  },
  {
    label: 'Easy',
    color: '#59ddaa',
    min: 15,
    max: 29,
    tooltipText:
      'You will need quality content focused on the keyword’s intent.'
  },
  {
    label: 'Possible',
    color: '#fdc23c',
    min: 30,
    max: 49,
    tooltipText: 'You will need well-structured and unique content.'
  },
  {
    label: 'Difficult',
    color: '#ff8c43',
    min: 50,
    max: 69,
    tooltipText: 'You will need lots of ref. domains and optimized content.'
  },
  {
    label: 'Hard',
    color: '#ff4953',
    min: 70,
    max: 84,
    tooltipText:
      'You will need lots of high-quality ref. domains and optimized content.'
  },
  {
    label: 'Very hard',
    color: '#d1002f',
    min: 85,
    max: 100,
    tooltipText:
      'It will take a lot of on-page SEO, link building, and content promotion efforts.'
  }
]
