/* @flow */
import {
  videoModalActionMaker,
  cropableImageModalActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatcher(changeParagraphAlignment(alignment))
  }


  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeParagraphAlignment: _changeParagraphAlignment,
    imageActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'CTA6',
        showRemove: false,
        imageUrl
      }),
    getVideoActions: videoModalActionMaker(dispatcher)
  }
}
