import React, { memo } from 'react'

import { NoIconContainer } from './styled'
import { translate } from '@editor/common/utils/translations'

const svgPath =
  'https://static.rfstat.com/renderforest/images/v2/blog-img/no-result-squirrel.svg'

const NoIcons = () => (
  <NoIconContainer>
    <img src={svgPath} alt="no-content" />
    <p>{translate('couldnt_find_any_matches_label')}</p>
  </NoIconContainer>
)

export default memo(NoIcons)
