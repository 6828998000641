/* @flow */
import React, { PureComponent } from 'react'
import S from './Header18.styled'
import THEME from './Header18.theme'
import { validation } from './Header18.consts'
import ActionGetter from './Actions'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import If from '@website/common/components/Conditional'
import NavBar from '@website/common/components/NavBar'
import Socials from '@website/common/components/Socials'
import { getFormatedNumber, compose } from '@website/common/utils'
import AddButton from '@website/common/components/AddButton'
import EditableContent from '@website/common/components/EditableContent'
import { withEditingContext, withDispatchContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SingleButton from '@website/common/components/SingleButton'
import { translate } from '@editor/common/utils/translations'

class Header18 extends PureComponent<> {
  sliderRef: null
  dispatcher = this.context
  state = {
    animate: 0,
    activeIdx: 0,
    slideClicked: false,
    navBarHeight: 0
  }

  handleHeightChange = height => {
    this.setState({ navBarHeight: height })
  }

  removeAnimation = () =>
    setTimeout(() => {
      this.setState({
        animate: false
      })
    }, 500)
  getNextIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + 1) % slides.length
  }

  beforeRemove = () => {
    const {
      data: { slides }
    } = this.props
    const { activeIndex } = this.state
    if (activeIndex !== 0) {
      const nextActiveIdx =
        activeIndex < slides.length - 1 ? activeIndex + 1 : slides.length - 2
      this.setState({ activeIdx: nextActiveIdx })
    }
  }
  sliderContent = Actions => {
    const { activeIdx } = this.state
    const { slides } = this.props.data
    return (
      <S.SliderContainer animate={this.state.animate}>
        <S.SlideImagesContainer className="slide-images-container">
          <S.MainImgContainer>
            <ControlsWithImageCropper
              actions={Actions.imgActions(
                activeIdx,
                0,
                slides[activeIdx].img1.url
              )}
              style={{ width: '100%', height: '100%' }}
            >
              <Image
                as={S.Img}
                alt="slide img1"
                sizes={slides[activeIdx].img1.dimensions}
                defaultImgSrc={slides[activeIdx].img1.url}
                asProps={{
                  imgCropParams: slides[activeIdx].img1.imgCropParams
                }}
              />
            </ControlsWithImageCropper>
          </S.MainImgContainer>

          <S.SecondaryImgContainer>
            <ControlsWithImageCropper
              actions={Actions.imgActions(
                activeIdx,
                1,
                slides[activeIdx].img2.url
              )}
              style={{ width: '100%', height: '100%' }}
            >
              <Image
                as={S.Img}
                alt="slide img1"
                sizes={slides[activeIdx].img2.dimensions}
                defaultImgSrc={slides[activeIdx].img2.url}
                asProps={{
                  imgCropParams: slides[activeIdx].img2.imgCropParams
                }}
              />
            </ControlsWithImageCropper>
          </S.SecondaryImgContainer>
        </S.SlideImagesContainer>
      </S.SliderContainer>
    )
  }

  slidePrevious = () => {
    const { activeIdx } = this.state
    this.removeAnimation()
    this.setState({ activeIdx: this.getPrevIdx(activeIdx), animate: true })
  }
  slideNext = () => {
    const { activeIdx } = this.state
    this.removeAnimation()
    this.setState({ activeIdx: this.getNextIdx(activeIdx), animate: true })
  }

  sliderControlIcons = () => {
    const { slides } = this.props.data
    return (
      <If
        condition={slides.length > 1}
        then={() => (
          <S.ButtonsWrap>
            <S.IconContainer
              className="wm-slider-btn"
              onClick={this.slidePrevious}
            >
              <S.Icon size="large" name="keyboard_arrow_left" />
            </S.IconContainer>

            <S.IconContainer className="wm-slider-btn" onClick={this.slideNext}>
              <S.Icon size="large" name="keyboard_arrow_right" />
            </S.IconContainer>
          </S.ButtonsWrap>
        )}
      />
    )
  }

  getPrevIdx = (index: number) => {
    const {
      data: { slides }
    } = this.props
    return (index + slides.length - 1) % slides.length
  }

  onSlideChange = (e: Event) => {
    const { activeIdx } = this.state
    const newImgIndex =
      e === 'left' ? this.getNextIdx(activeIdx) : this.getPrevIdx(activeIdx)
    this.setState({ activeIdx: newImgIndex })
  }

  getSliderCounter = () => {
    const { activeIdx } = this.state
    const {
      isEditing,
      data: { slides }
    } = this.props
    //start counter from 1
    const idx = activeIdx + 1
    return (
      <S.SliderCounter isEditing={isEditing}>
        <span className="active-slide-idx">{`${getFormatedNumber(idx)}`}</span>
        {`/${getFormatedNumber(slides.length)}`}
      </S.SliderCounter>
    )
  }

  render() {
    const {
      data: {
        title,
        subTitle,
        paragraph,
        subTitleAlignment,
        titleAlignment,
        paragraphAlignment,
        logoSrc,
        logoColor,
        logoWidth,
        logoDimensions,
        btnText,
        socials,
        btnUrl,
        slides
      },
      componentDispatcher,
      isEditing
    } = this.props

    const { activeIdx } = this.state
    const Actions = ActionGetter(componentDispatcher)
    return (
      <S.StyledContainer
        topOffset={this.state.navBarHeight}
        isEditing={isEditing}
        isSingleSlide={slides.length === 1}
      >
        <NavBar
          handleHeightChange={this.handleHeightChange}
          alwaysShowBackground
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
          linksProps={{
            additionalContent:
              isEditing || socials.length !== 0
                ? () => (
                    <Socials
                      data={socials}
                      componentDispatcher={componentDispatcher}
                    />
                  )
                : null
          }}
        />
        <S.WmCustomContainer
          isEditing={isEditing}
          className="header-18-container"
        >
          <S.HeaderContent>
            <EditableContent
              text={title}
              as={S.Title}
              alignment={titleAlignment}
              required={validation.title.required}
              maxCount={validation.titlehMaxChar}
              className="WM_GLOBAL_heading96"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={subTitle}
              as={S.SubTitle}
              alignment={subTitleAlignment}
              maxCount={validation.subTitleMaxChar}
              className="WM_GLOBAL_heading32"
              onChange={Actions.changeSubTitle}
              changeAlignment={Actions.changeSubTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              className="WM_GLOBAL_paragraph"
              as={S.Paragraph}
              maxCount={validation.paragraphMaxChar}
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
            <SingleButton btnUrl={btnUrl} btnText={btnText} />
          </S.HeaderContent>
          <S.SlidesContainer className="slides-container">
            {this.sliderContent(Actions)}
            {this.getSliderCounter()}
            {this.sliderControlIcons()}
            <S.AddButtonsWrapper>
              <AddButton
                onAdd={Actions.addSlide}
                toShow={slides.length < validation.slides.max}
                style={{ margin: '10px 5px' }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_slide_label')}
              </AddButton>
              <AddButton
                onAdd={() => {
                  this.beforeRemove()
                  Actions.removeSlide(activeIdx)
                }}
                toShow={slides.length > validation.slides.min}
                style={{ margin: '10px 5px' }}
              >
                <Icon name="glyph-remove" className="icon" size="normal" />
                {translate('remove_slide_label')}
              </AddButton>
            </S.AddButtonsWrapper>
          </S.SlidesContainer>
        </S.WmCustomContainer>
      </S.StyledContainer>
    )
  }
}

const Header18WithContexts = compose(
  withEditingContext,
  withDispatchContext
)(Header18)

export default Header18WithContexts
