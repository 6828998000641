/* @flow */
import styled, { css } from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './CTA5.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  padding: 80px 0 150px 0;
  flex-direction: ${getStyleForProp('orientation')};
  justify-content: space-between;
  @media only screen and (max-width: 1360px) {
    padding: 80px 0 0 0;
  }
  @media only screen and (max-width: 760px) {
    padding: ${({ isEditing }) =>
    isEditing ? '120px 0 80px 0' : '80px 0'};
  }
  @media only screen and (max-width: 550px) {
    padding: ${({ isEditing }) => (isEditing ? '120px 0 0 0' : '0')};
  }
`

const Border = styled.div`
  width: 22%;
  background: ${getStyleForProp('lineBg')};
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  display: ${({ isBackground }) => (isBackground ? 'none' : 'initial')};
  @media only screen and (max-width: 1360px) {
    width: 90px;
  }
  @media only screen and (max-width: 760px) {
    display: none;
  }
`

const WmCustomContainer = styled(WMContainer)`
  @media only screen and (min-width: 1361px) {
    ${({ theme }) =>
    css`
        ${theme.orientation === 'LEFT'
        ? css`
              transform: translateX(40px);
            `
        : css`
              transform: translateX(40px);
            `}
      `}
  }
  @media only screen and (max-width: 1360px) {
    ${({ theme }) =>
    css`
        flex-direction: ${theme.orientation === 'RIGHT'
        ? 'column-reverse'
        : 'column'};
      `}
  }
  @media only screen and (max-width: 760px) {
    padding: ${({ isEditing }) => (isEditing ? '30px 0 0 0' : '0')};
  }
`

const CtaContent = styled.div`
  box-shadow: ${getStyleForProp('raisedShadow')};
  background: ${({ theme }) => theme.palette.accentWhite};
  box-sizing: border-box;
  width: 55%;
  max-width: 720px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  border-radius: ${getStyleForProp('cardRadius')};
  ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
      transform: translate(-80px, 50px);
      padding: 40px 3vw 40px 120px;
    `}
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      padding: 40px 100px 40px 3vw;
    `}
  @media only screen and (max-width: 1360px) {
    width: 65%;
    ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
        align-self: flex-end;
        transform: translate(0, -80px);
        padding: 140px 40px 40px 40px;
      `}
    ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
        align-self: flex-start;
        transform: initial;
        padding: 40px 40px 100px 40px;
      `}
  }
  @media only screen and (max-width: 1100px) {
    width: 80%;
  }
  @media only screen and (max-width: 760px) {
    padding: ${({ theme }) => (theme.orientation === 'LEFT' ? '80px 40px 80px 40px' : '80px 40px 40px 40px')};
    align-self: center;
    width: 90%;
    ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
        transform: initial;
        margin-top: -4px;
        border-bottom-left-radius: ${getStyleForProp('cardRadius')};
        border-bottom-right-radius: ${getStyleForProp('cardRadius')};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      `}
    ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
        border-top-left-radius: ${getStyleForProp('cardRadius')};
        border-top-right-radius: ${getStyleForProp('cardRadius')};
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      `}
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ theme }) => (theme.orientation === 'LEFT' ? '50px 20px 60px 20px' : '60px 20px 40px 20px')};
  }
  @media only screen and (max-width: 1440px) {
    .buttons-block {
      margin-left: -15px;
    }
  }
  @media only screen and (max-width: 550px) {
    border-radius: 0;
    width: 100%;
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
    p,
    h1 {
      padding-top: 0;
      padding-left: 0;
    }
    @media only screen and (min-width: 1441px) {
      margin-left: 15px;
    }
  }
  > .control-container:nth-child(2) {
    margin-top: 20px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (min-width: 1441px) {
    padding-left: 15px;
  }
`
const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  padding-top: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
  @media only screen and (min-width: 1441px) {
    padding-left: 15px;
  }
`

const VideoWrapper = styled.div`
  width: 60%;
  position: relative;
  z-index: 1;
  ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
      align-self: flex-end;
      transform: translate(-70px, 80px);
    `}
  @media only screen and (max-width: 1360px) {
    width: 70%;
    ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
        transform: translate(-50px, -66px);
      `}
  }
  @media only screen and (max-width: 1100px) {
    width: 80%;
  }
  @media only screen and (max-width: 760px) {
    transform: initial;
    align-self: center;
    width: 90%;
  }
  @media only screen and (max-width: 550px) {
    width: 100%;
  }
`

const VideoContent = styled.div`
  border-radius: ${getStyleForProp('cardRadius')};
  overflow: hidden;
  @media only screen and (max-width: 760px) {
    ${({ theme }) =>
    theme.orientation === 'LEFT' &&
    css`
        border-top-left-radius: ${getStyleForProp('cardRadius')};
        border-top-right-radius: ${getStyleForProp('cardRadius')};
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      `}
    ${({ theme }) =>
    theme.orientation === 'RIGHT' &&
    css`
        border-bottom-left-radius: ${getStyleForProp('cardRadius')};
        border-bottom-right-radius: ${getStyleForProp('cardRadius')};
        border-top-right-radius: 0;
        border-top-left-radius: 0;
      `}
  }
  @media only screen and (max-width: 550px) {
    border-radius: 0;
  }
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    padding-top: 56.25%;
  }
  .iframe-video {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export default {
  WmCustomContainer,
  VideoContent,
  CtaContent,
  StyledContainer,
  Title,
  Paragraph,
  VideoWrapper,
  VideoWrap,
  Border
}
