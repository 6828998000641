import styled, { css } from 'styled-components'

const OptionsWrap = styled.form`
  display: flex;
  column-gap: 30px;
  row-gap: 15px;
  flex-wrap: wrap;

  @media only screen and (min-width: 590px) {
    ${({ hasOnlyFourItems }) =>
      hasOnlyFourItems
        ? css`
            max-width: 350px;
          `
        : ''}
  }

  @media only screen and (max-width: 457px) {
    flex-direction: column;
  }
`

const RadioButtonsTitle = styled.h4`
  word-break: break-word;
`

const SectionWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  ${RadioButtonsTitle} {
    font-weight: 400;
  }
`

const Asterisk = styled.span`
  font-size: 14px;
  padding-left: 5px;
`

const RadioButtonTitleWrap = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 20px;
`

export default {
  OptionsWrap,
  RadioButtonsTitle,
  SectionWrap,
  Asterisk,
  RadioButtonTitleWrap
}
