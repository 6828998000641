/* @flow */
import produce from 'immer'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'ADD_BLOCK':
        draft.slides.push(initialState.slides[0])
        break
      case 'REMOVE_BLOCK':
        draft.slides.splice(action.value.idx, 1)
        break
      case 'CHANGE_SLIDE_TITLE':
        const { newTitle, idx } = action.value
        draft.slides[idx].slideTitle = newTitle
        break
      case 'CHANGE_SLIDE_PARAGRAPH':
        const { newParagraph, idx: _idx } = action.value
        draft.slides[_idx].slideParagraph = newParagraph
        break
      case 'CHANGE_SLIDE_TITLE_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].slideTitleAlignment = alignment
        break
      }
      case 'CHANGE_SLIDE_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.slides[idx].slideParagraphAlignment = alignment
        break
      }
    }
  })
}
