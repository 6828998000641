import { isNoneNegativeInteger } from '@website/common/utils'

export const countUp = animationSpeed => {
  const elements = document.querySelectorAll('.counting-numbers')

  elements.forEach(elem => {
    const elementValue = elem.getAttribute('data-target')
    if (!isNoneNegativeInteger(elementValue)) {
      elem.innerText = elementValue
    } else {
      let start = 0
      const end = parseInt(elementValue)
      const increment = end / animationSpeed
      const counter = setInterval(() => {
        start += increment
        elem.innerText = Math.floor(start)

        if (start + increment >= end) {
          elem.innerText = end
          clearInterval(counter)
        }
      }, animationSpeed)
    }
  })
}
