/* @flow */
/* eslint-disable max-statements */
import React, { useRef } from 'react'
import * as Styled from './Popup.styled'
import CloseIcon from '@website/common/assets/svgr-icons/close.svg'

const Popup = ({ onClose, children }) => {
  const popupRef = useRef(null)

  const onContainerClick = (e): void => {
    const { target } = e
    if (popupRef && target && !popupRef.current.contains(target)) {
      onClose()
    }
  }

  return (
    <Styled.PopupOverlay onMouseDown={onContainerClick}>
      <Styled.PopupContainer ref={popupRef}>
        {children}
        <Styled.CloseIcon
          SvgComp={CloseIcon}
          size="large"
          color="#9AA6C8"
          onClick={onClose}
        />
      </Styled.PopupContainer>
    </Styled.PopupOverlay>
  )
}

export default Popup
