import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    paragraph: p => p.accentWhite,
    icon: p => p.accentWhite,
    blockTitle: p => p.accentWhite,
    blockParagraph: p => p.accentWhite,
    navbarBg: p => p.tertiaryDark,
    background: p => p.tertiaryDark,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.25)`
  },
  LIGHT: {
    heading: p => p.primaryDark,
    paragraph: p => p.tertiaryDark,
    icon: p => p.primaryDark,
    blockTitle: p => p.primaryDark,
    blockParagraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryLight,
    background: p => p.tertiaryLight,
    lightBackground: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryLight,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.25)`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    icon: p => p.tertiaryDark,
    blockTitle: p => p.tertiaryDark,
    blockParagraph: p => p.tertiaryDark,
    navbarBg: p => p.tertiaryExtraLight,
    lightBackground: p => p.tertiaryExtraLight,
    background: p => p.tertiaryExtraLight,
    backgroundColor: p => p.tertiaryExtraLight,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.25)`
  }
}
