/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header48.styled'
import { validation } from './Header48.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import EditableContent from '@website/common/components/EditableContent'
import THEME from './Header48.theme'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import MorphSmall from './Header48_morph_small'
import MorphBig from './Header48_morph_big'
import Buttons from '@website/common/components/Buttons'
import NavBar from '@website/common/components/NavBar'
import Socials from '@website/common/components/Socials'

const Header48 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      buttons,
      images,
      titleAlignment,
      paragraphAlignment,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions,
      logoSrc,
      logoColor,
      headerSocials,
      logoWidth,
      logoDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)

  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
        linksProps={{
          additionalContent:
            isEditing || headerSocials.length !== 0
              ? () => (
                  <Socials
                    className="header-socials"
                    data={headerSocials}
                    componentDispatcher={dispatcher}
                  />
                )
              : null
        }}
      />
      <S.WmCustomContainer topOffset={navBarHeight}>
        <S.ContentWrapper>
          <S.DotsWrapper className="card-dots-wrapper">
            <MorphSmall />
          </S.DotsWrapper>
          <S.HeaderContent isEditing={isEditing}>
            <S.TextsContainer>
              <EditableContent
                text={title}
                as={S.Title}
                maxCount={validation.titleMaxChar}
                required={!paragraph}
                className="WM_GLOBAL_heading42"
                alignment={titleAlignment}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                required={!title}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                alignment={paragraphAlignment}
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <Buttons data={buttons} />
            </S.TextsContainer>
          </S.HeaderContent>
        </S.ContentWrapper>
        <S.ImagesWrapper>
          <S.CirclesWrapper>
            <S.Circle className="circle-1" />
            <S.Circle className="circle-2" />
            <S.Circle className="circle-3" />
            <S.Circle className="circle-4" />
          </S.CirclesWrapper>
          <S.FigureWrapper>
            <S.FirstFigure>
              <S.TriangleLeft />
              <S.TriangleRight />
            </S.FirstFigure>
            <S.SecondFigure />
            <S.ThirdFigure />
            <S.RatioWrapper className={'ratio-wrapper-1'}>
              <S.ImageWrapper>
                <S.ControlContainer>
                  <ControlsWithImageCropper
                    style={{ width: '100%', height: '100%' }}
                    actions={Actions.imageActions(0, images[0].imgUrl)}
                  >
                    <Image
                      as={S.Img}
                      alt="Gallery"
                      sizes={images[0].imgDimensions}
                      asProps={{
                        imgCropParams: images[0].imgCropParams
                      }}
                      defaultImgSrc={images[0].imgUrl}
                    />
                  </ControlsWithImageCropper>
                </S.ControlContainer>
              </S.ImageWrapper>
            </S.RatioWrapper>
            <S.DotsWrapper className="first-dots-wrapper">
              <MorphBig />
            </S.DotsWrapper>
            <S.DotsWrapper className="second-dots-wrapper">
              <MorphBig />
            </S.DotsWrapper>
          </S.FigureWrapper>
          <S.FigureWrapper>
            <S.RatioWrapper className={'ratio-wrapper-2'}>
              <S.ImageWrapper>
                <S.ControlContainer>
                  <ControlsWithImageCropper
                    style={{ width: '100%', height: '100%' }}
                    actions={Actions.imageActions(1, images[1].imgUrl)}
                  >
                    <Image
                      as={S.Img}
                      alt="Gallery"
                      sizes={images[1].imgDimensions}
                      asProps={{
                        imgCropParams: images[1].imgCropParams
                      }}
                      defaultImgSrc={images[1].imgUrl}
                    />
                  </ControlsWithImageCropper>
                </S.ControlContainer>
              </S.ImageWrapper>
            </S.RatioWrapper>
            <S.FourthFigure>
              <S.InnerCircle className="first-circle">
                <S.InnerCircle className="second-circle">
                  <S.InnerCircle className="third-circle">
                    <S.InnerCircle className="fourth-circle"></S.InnerCircle>
                  </S.InnerCircle>
                </S.InnerCircle>
              </S.InnerCircle>
            </S.FourthFigure>
            <S.FifthFigure>
              <S.FifthNestedFigure />
            </S.FifthFigure>
          </S.FigureWrapper>
        </S.ImagesWrapper>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Header48
