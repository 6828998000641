/* @flow */

/**
 * @exports
 *  @param {number} i specified component index
 *  @param {Array} pageComponents
 * @description Checks if  specified component's category is `footer`.
 * @returns {boolean}
 **/
export const isComponentFooter = (pageComponents: Array<any>, index: number) =>
  pageComponents[index].category === 'footer'

/**
 *  @param {number} i specified component index
 *  @param {Array} pageComponents
 * @description Checks if  specified component's category is `header`.
 * @returns {boolean}
 **/
export const isComponentHeader = (pageComponents: Array<any>, index: number) =>
  pageComponents[index].category === 'header'

/**
 *  @param {number} i specified component index
 *  @param {Array} pageComponents
 * @description Checks if  specified component's category is `rsvp`.
 * @returns {boolean}
 **/
export const isComponentRSVP = (pageComponents: Array<any>, index: number) =>
  pageComponents[index].category === 'rsvp'

/**
 *  @param {number} i specified component index
 *  @param {Array} pageComponents
 * @description Checks if  specified component have previous component and it is not `header`.
 * @returns {boolean}
 **/
export const isPrevComponentReplacable = (
  pageComponents: Array<any>,
  index: number
) => {
  return (
    pageComponents[(pageComponents, index - 1)] &&
    !isComponentHeader(pageComponents, index - 1)
  )
}

/**
 *  @param {number} i specified component index
 *  @param {Array} pageComponents
 * @description Checks if  specified component have next component and it is not `footer`.
 * @returns {boolean}
 **/
export const isNextComponentReplacable = (
  pageComponents: Array<any>,
  index: number
) => {
  return (
    pageComponents[index + 1] && !isComponentFooter(pageComponents, index + 1)
  )
}

/**
 *  @export
 *  @param {number} i specified component index
 *  @param {Array} pageComponents
 * @description Checks if component with specified `index` should have icon
 * `to replace` with its upper positioned component.
 * @returns {boolean}
 **/
export const isUpMovementAllowed = (pageComponents: Array<any>, i: number) => {
  return (
    !isComponentFooter(pageComponents, i) &&
    isPrevComponentReplacable(pageComponents, i)
  )
}

/**
 *  @export
 *  @param {number} i specified component index
 *  @param {Array} pageComponents
 * @description Checks if component with specified `index` should have icon
 * `to replace` with its lower positioned component.
 * @returns {boolean}
 **/
export const isDownMovementAllowed = (pageComponents: Array<any>, i: number) => {
  return (
    !isComponentHeader(pageComponents, i) &&
    isNextComponentReplacable(pageComponents, i)
  )
}

export const isBackgroundImageAllowed = component =>
  component.defaultProps.hasOwnProperty('backgroundImgUrl') ||
  component.defaultProps.hasOwnProperty('backgroundTopImage')

export const isBackgroundVideoAllowed = component =>
  component.defaultProps.hasOwnProperty('backgroundVideoUrl')

export const isBgTransparencyAllowed = component =>
  component.defaultProps.hasOwnProperty('bgOverlayTransparency')

export const isOrientationAllowed = component => component.hasOwnProperty('orientation')

export const isBgColorAllowed = component => component.hasOwnProperty('colorMode')

export const isComponentAnimated = component =>
  component.defaultProps.hasOwnProperty('isAnimated')

export const isDuplicateAllowed = (pageComponents: Array<any>, i: number) =>
  !isComponentHeader(pageComponents, i) &&
  !isComponentFooter(pageComponents, i) &&
  !isComponentRSVP(pageComponents, i)
