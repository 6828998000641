/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'

import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'
import DeleteIcon from '@editor/common/assets/svgr-icons/Delete_icon_2.svg'
import { translate } from '@editor/common/utils/translations'

const keysForSizes = [
  'header42CenterImage',
  'header42LeftImage',
  'header42RightImage'
]

export default dispatcher => {
  const changeSubTitle = (newSubTitle: string) => {
    dispatcher({
      type: 'CHANGE_SUB_TITLE',
      value: newSubTitle
    })
  }

  const addImage = (idx: number) => {
    dispatcher({
      type: 'ADD_IMAGE',
      value: { idx }
    })
  }

  const removeImage = (idx: number) => {
    dispatcher({
      type: 'REMOVE_IMAGE',
      value: { idx }
    })
  }

  const changeSubTitleAlignment = (alignment: string) => {
    dispatcher({
      type: 'CHANGE_SUB_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const imageActions = (idx: number, imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatcher)({
      keyForSizes: keysForSizes[idx],
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    }),
    {
      toShow: idx !== 0,
      handler: () => {
        removeImage(idx)
      },
      IconComp: DeleteIcon,
      tooltipText: translate('delete_label')
    }
  ]
  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  return {
    changeTitle: _changeTitle,
    changeSubTitle,
    changeSubTitleAlignment,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeParagraph: _changeParagraph,
    imageActions,
    addImage
  }
}
