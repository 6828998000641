/* @flow */

export const validation = {
  headingMaxChar: 180,
  paragraphMaxChar: 400,
  buttons: {
    min: 0,
    max: 2
  },
  ctaContent: {
    title: {
      min: 0,
      max: 2
    },
    paragraph: {
      min: 0,
      max: 2
    }
  }
}
