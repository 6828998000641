import styled from 'styled-components'

export const Input = styled.input`
  position: relative;
  width: 100%;
  height: 5px;
  margin: 0;
  border: none;
  border-radius: 10px;
  background-color: #c2c9de;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;

  &:before {
    content: '';
    height: 100%;
    width: ${({ max, min, value }) =>
      100 - ((max - value) * 100) / (max - min) + '%'};
    position: absolute;
    z-index: 2;
    border-radius: 10px;
    background-color: #3271e6;
  }

  &::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    background-color: #3271e6;
    box-shadow: 0 3px 3px #387dff4d;
    width: 12px;
    height: 12px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 3;
    transition: transform 300ms ease;
    border: none !important;

    &:hover {
      transform: scale(1.5);
      box-shadow: 0 0 0 3px #387dff4d;
      border-radius: 50%;
    }

    @media only screen and (max-width: 768px) {
      padding: 10px;
    }
  }

  &::-moz-range-thumb {
    position: relative;
    -webkit-appearance: none;
    background-color: #3271e6;
    box-shadow: 0 3px 3px #387dff4d;
    width: 12px;
    height: 12px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 3;
    transition: transform 300ms ease;
    border: none !important;

    &:hover {
      transform: scale(1.5);
      box-shadow: 0 0 0 3px #387dff4d;
      border-radius: 50%;
    }

    @media only screen and (max-width: 768px) {
      padding: 10px;
    }
  }
`
