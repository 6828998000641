/* @flow */
import styled from 'styled-components'
import THEME from './Header47.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  position: relative;
  padding-top: 0;
  padding-bottom: ${({ isEditing, isBottomContnetExist }) =>
    !isEditing && !isBottomContnetExist ? '160px !important' : '0'};
  @media only screen and (min-width: 1241px) {
    padding-bottom: 102px;
    background-color: ${getStyleForProp('background')};
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 80px;
      right: 0;
      ${getBackgroundStyles(THEME)};
    }
  }
  @media only screen and (max-width: 1240px) {
    ${getBackgroundStyles(THEME)};
    padding-bottom: ${({ isEditing, isBottomContnetExist }) =>
      !isEditing && !isBottomContnetExist ? '80px !important' : '0'};
  }
  @media only screen and (max-width: 457px) {
    padding-bottom: ${({ isEditing, isBottomContnetExist }) =>
      !isEditing && !isBottomContnetExist ? '50px !important' : '0'};
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  padding-top: 0 !important;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`

const WmBottomCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  padding-top: 0;
  @media only screen and (min-width: 1241px) {
    transform: translateY(-80px);
  }
  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    align-items: center;
  }
`

const HeaderContent = styled.div`
  position: relative;
  padding-top: ${({ topOffset }) => `${topOffset + 80}px`};
  text-align: center;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  @media only screen and (max-width: 1240px) {
    flex-direction: column;
    align-items: center;
  }
`

const LeftContent = styled.div`
  position: relative;
  display: flex;
  width: 50%;
  max-width: 450px;
  z-index: 3;
  @media only screen and (min-width: 1241px) {
    transform: translateY(100px);
  }
  @media only screen and (max-width: 1240px) {
    width: 100%;
    flex-direction: column;
    position: relative;
  }
  @media only screen and (max-width: 1240px) and (min-width: 701px) {
    transform: translateX(8%);
  }
  @media only screen and (max-width: 700px) {
    width: 80%;
    flex-direction: column;
    position: relative;
  }
`

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 0 0 0 30px;
  width: 50%;
  max-width: 600px;
  position: relative;
  box-sizing: border-box;
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    width: 100%;
    margin-bottom: 30px;

    p {
      padding-top: 0;
    }
  }
  .socials-block {
    padding-top: 20px;
    @media only screen and (max-width: 1240px) {
      margin-bottom: 30px;
    }
  }

  .link-wrapper {
    svg {
      fill: ${getStyleForProp('socialIcons')};
    }
  }
  @media only screen and (max-width: 1240px) {
    width: 100%;
    margin: 30px auto 0 auto;
    max-width: 100%;
    align-items: center;
    > :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  width: 100%;
  box-sizing: border-box;
  align-self: flex-start;
  margin-bottom: 0;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 124.73%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 124.73%;
    height: initial;
    border-radius: ${({ theme }) =>
      theme.look !== 'SHARP' ? '190px 190px 0 0' : ''};
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 380px;
  @media only screen and (max-width: 1440px) {
    max-width: 300px;
  }
  @media only screen and (max-width: 1024px) {
    width: 90%;
    & > .control-container {
      padding: 5px;
    }
  }
  @media only screen and (max-width: 457px) {
    width: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
`

const ItemWrap = styled.div`
  display: flex;
  box-sizing: border-box;
  word-break: break-word;
  margin-right: 30px;
  align-items: center;
  > div {
    display: flex;
    flex-direction: row;
  }
  @media only screen and (max-width: 1240px) {
    margin-right: 0;
  }
  .contact-item-control {
    align-items: center;
  }
  & > a,
  p {
    ${getTextStyleByAlignment};
  }
`

const ContactItemWrap = styled.div`
  align-items: flex-start;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
  @media only screen and (min-width: 1241px) {
    ${ItemWrap}:nth-child(2) {
      .control-container {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 1240px) {
    width: auto;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
  }
`

const IconWrapper = styled.div`
  width: 36px;
  height: 36px;
  margin-bottom: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`

const A = styled.a`
  text-decoration: none;
  ${getTextStyleByAlignment};
  display: block;
  color: ${getStyleForProp('paragraph')};
  max-width: 220px;
  &:hover {
    opacity: 0.7;
  }
`

const BlockParagraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  max-width: 220px;
`

const RhombusWrapper = styled.div`
  position: absolute;
  left: -110px;
  top: -110px;
  width: 150px;
  height: 150px;
  @media only screen and (max-width: 1440px) {
    width: 110px;
    height: 110px;
    left: -90px;
    top: -90px;
  }
  @media only screen and (max-width: 700px) {
    width: 70px;
    height: 70px;
    left: -30px;
    top: -50px;
  }
  @media only screen and (max-width: 500px) {
    width: 60px;
    height: 60px;
    left: -45px;
    top: -50px;
  }
  svg {
    height: 100%;
    width: 100%;
  }
  .a {
    fill: ${getStyleForProp('bigRhombus')};
  }
`

const SmallRhombusWrapper = styled.div`
  width: 50px;
  height: 50px;
  svg {
    height: 100%;
    width: 100%;
  }
  .a {
    fill: ${getStyleForProp('smallRhombus')};
  }
`

const RightElements = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 80px;
  right: 0;
  @media only screen and (max-width: 1240px) {
    bottom: initial;
    top: 160px;
    right: calc(50% - 250px);
  }
  @media only screen and (max-width: 700px) {
    top: 160px;
  }
  @media only screen and (max-width: 500px) {
    right: -5px;
  }
  @media only screen and (max-width: 480px) {
    display: none;
  }
`

const Lines = styled.div`
  height: 240px;
  width: 2px;
  overflow: hidden;
  margin-bottom: 20px;
  transform: translateX(-1px);
  @media only screen and (max-width: 700px) {
    height: 220px;
  }
  &:after {
    display: block;
    content: '';
    height: 100%;
    border-right: 7px dashed ${getStyleForProp('border')};
  }
`

const ButtomContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin: 0 0 0 30px;
  width: 50%;
  max-width: 600px;
  position: relative;
  box-sizing: border-box;
  .buttons-block {
    margin-left: -15px;
  }
  @media only screen and (max-width: 1240px) {
    width: 100%;
    margin: 0 auto;
    max-width: 550px;
    align-items: center;
    padding-bottom: 80px;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 700px) {
    padding-bottom: 50px;
  }
`

const StyledContainerBottom = styled.div`
  background: ${getStyleForProp('background')};
  margin-top: -2px;
`

const ContentWrap = styled.div`
  position: relative;
`

const EmptyLeftContent = styled.div`
  width: 450px;
  @media only screen and (max-width: 1240px) {
    width: 100%;
    flex-direction: column;
    position: relative;
  }
`

const Trellis = styled.div`
  position: absolute;
  width: 300px;
  border: 1px solid ${getStyleForProp('border')};
  top: -25px;
  left: 120px;
  @media only screen and (max-width: 1440px) {
    left: 90px;
    width: 90%;
    max-width: 260px;
  }
  @media only screen and (max-width: 500px) {
    left: 20%;
  }

  > div {
    display: flex;
    width: 100%;
    height: 100px;
    justify-content: flex-end;
    @media only screen and (max-width: 1440px) {
      height: 80px;
    }
    @media only screen and (max-width: 457px) {
      height: auto;
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${getStyleForProp('border')};
    }
    > div {
      width: 100px;
      height: 100px;
      @media only screen and (max-width: 1440px) {
        width: 80px;
        height: 80px;
      }
      @media only screen and (max-width: 457px) {
        width: 100%;
        height: 0;
        padding-top: 35%;
      }

      &:not(:last-child) {
        border-right: 1px solid ${getStyleForProp('border')};
      }
    }
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('icons')
}))``

export default {
  StyledContainer,
  WmCustomContainer,
  WmBottomCustomContainer,
  HeaderContent,
  Title,
  Paragraph,
  ImageWrapper,
  Img,
  ControlContainer,
  LeftContent,
  RightContent,
  ContactItemWrap,
  ItemWrap,
  IconWrapper,
  A,
  BlockParagraph,
  Icon,
  Trellis,
  RhombusWrapper,
  RightElements,
  SmallRhombusWrapper,
  ButtomContentWrapper,
  StyledContainerBottom,
  EmptyLeftContent,
  Lines,
  ContentWrap
}
