export const validation = {
  headingMaxChar: 200,
  paragraphMaxChar: 400,
  blockParagraphMaxChar: 100,
  blocks: {
    min: 0,
    max: 3
  }
}

export const ADDITIONAL_URL_DEFAULT_DATA = {
  additionalText: 'Learn more',
  additionalUrl: 'http://builder.renderforestsites.com'
}
