export const validation = {
  maxTitleChar: 40,
  maxParagraphChar: 250,
  slides: {
    min: 3,
    max: 20
  },
  buttons: {
    min: 0,
    max: 2
  }
}
