/* @flow */

const SINGLE_TRACK_TYPES = ['track', 'episode']
const PLAYLIST_TYPES = ['album', 'playlist', 'artist']

const _isSingleTrack = url =>
  SINGLE_TRACK_TYPES.some(type => url.indexOf(type) !== -1)

const isPlaylist = url => PLAYLIST_TYPES.some(type => url.indexOf(type) !== -1)

export const validateUrl = (url, isSingleTrack) => {
  if (isSingleTrack) {
    return _isSingleTrack(url)
  } else {
    return isPlaylist(url)
  }
}

export const isIframe = (url: string) => {
  return url.startsWith('<iframe')
}

export const getUrlFromIframe = (iframe: string) => {
  const elem = document.createElement('div')
  elem.innerHTML = iframe
  return elem.childNodes[0].src
}

const validateSpotifyUrl = (url: string) => {
  const exp = new RegExp(/^(spotify:|https:\/\/[a-z]+\.spotify\.com\/)/)
  return exp.test(url)
}

export const checkUrlLinkValidation = (url: string) => {
  const musicUrl = isIframe(url) ? getUrlFromIframe(url) : url
  return validateSpotifyUrl(musicUrl)
}
