/* @flow */
import React, { useContext, memo } from 'react'
import S from './Portfolio12.styled'
import { validation } from './Portfolio12.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import If from '@website/common/components/Conditional'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Portfolio12 = memo(props => {
  const {
    data: {
      gallery,
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      imageLink
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <Show when={[title, paragraph]}>
        <S.Headings>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading42"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Paragraph}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.Headings>
      </Show>
      {gallery.map((galleryRow, idx) => {
        return (
          <S.RowWrapper>
            <S.Row key={idx}>
              {galleryRow.map((galleryItem: string, itemIdx: number) => {
                return (
                  <S.Item isEditing={isEditing} key={itemIdx}>
                    <S.ControlContainer>
                      <a
                        href={!isEditing && galleryItem.imageLink}
                        target="_blank"
                        onClick={e => {
                          !isEditing &&
                            !galleryItem.imageLink &&
                            e.preventDefault()
                        }}
                      >
                        <ControlsWithImageCropper
                          actions={Actions.imageActions(
                            idx,
                            itemIdx,
                            galleryItem.imageLink
                          )}
                          key={itemIdx}
                          style={{ width: '100%' }}
                        >
                          <Image
                            as={S.Img}
                            sizes={galleryItem.backgroundImgDimensions}
                            defaultImgSrc={galleryItem.backgroundImg}
                            asProps={{
                              imgCropParams: galleryItem.imgCropParams
                            }}
                            alt="Portfolio image"
                          />
                        </ControlsWithImageCropper>
                      </a>
                    </S.ControlContainer>
                    <EditableContent
                      text={galleryRow[itemIdx].title}
                      alignment={galleryRow[itemIdx].titleAlignment}
                      as={S.BlockTitle}
                      maxCount={validation.titleMaxChar}
                      className="WM_GLOBAL_paragraph18"
                      onChange={(newTitle: string) => {
                        Actions.changeBlockTitle(newTitle, idx, itemIdx)
                      }}
                      changeAlignment={(alignment: string) =>
                        Actions.changeBlockTitleAlignment(
                          alignment,
                          idx,
                          itemIdx
                        )
                      }
                    />
                  </S.Item>
                )
              })}
            </S.Row>
            <If
              condition={isEditing}
              then={() => (
                <S.AddButtonWrapper>
                  <AddButton
                    onAdd={() => Actions.addRow(idx)}
                    toShow={
                      Object.keys(gallery).length < validation.gallery.max
                    }
                    style={{
                      margin: '0 0.5rem'
                    }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_row_label')}
                  </AddButton>
                  <AddButton
                    onAdd={() => Actions.removeRow(idx)}
                    toShow={
                      Object.keys(gallery).length > validation.gallery.min
                    }
                    style={{
                      margin: '0 0.5rem'
                    }}
                  >
                    <Icon name="glyph-remove" className="icon" size="normal" />
                    {translate('remove_row_label')}
                  </AddButton>
                </S.AddButtonWrapper>
              )}
            />
          </S.RowWrapper>
        )
      })}
    </S.StyledContainer>
  )
})

export default Portfolio12
