import styled from 'styled-components'
import Icon from '@website/common/components/Icon'

export const SliderControlsContainer = styled.div`
  height: 100%;
  padding-left: 15px;
`

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 14px;
  border-right: 1px solid #c1c9e0;
`

export const InfoText = styled.p`
  color: #545f7e;
  margin-right: 10px;
`

export const LeftArrow = styled(Icon)`
  margin-right: 8px;
  cursor: pointer;
`
export const RightArrow = styled(Icon)`
  margin-left: 8px;
  margin-right: 8px;
  cursor: pointer;
`
export const ActiveSlideIdx = styled.p`
  color: #545f7e;
`

export const Divider = styled.p`
  color: #8493bd;
  padding: 0 4px;
  font-size: 12px;
  font-weight: 500;
`

export const SlideCount = styled.p`
  color: #8493bd;
`
