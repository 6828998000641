export const SLIDER_PARAMS = {
  focusOnSelect: true,
  infinite: true,
  speed: 500,
  lazyLoad: 'progressive',
  slidesToShow: 1,
  accessibility: false,
  className: 'testimonials4-slider',
  centerMode: true,
  variableWidth: true,
  centerPadding: 0
}
