/* @flow */

import { removeBlockActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeBlockTitle,
  changeBlockParagraph,
  changeTitleAlignment,
  changeParagraphAlignment,
  addBlock
} from '@website/components/CommonActions'
export default dispatcher => {
  const _changeTitle = newTitle => {
    dispatcher(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatcher(changeParagraph(newParagraph))
  }

  const _changeBlockTitle = (newBlockTitle, idx) => {
    dispatcher(changeBlockTitle(newBlockTitle, idx))
  }

  const _changeBlockParagraph = (newBlockParagraph, idx) => {
    dispatcher(changeBlockParagraph(newBlockParagraph, idx))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  const changeBlockParagraphAlignment = (alignment: string, idx: number) => {
    dispatcher({
      type: 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changeBlockTitle: _changeBlockTitle,
    changeBlockParagraph: _changeBlockParagraph,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeBlockTitleAlignment,
    changeBlockParagraphAlignment,
    addBlock: () => dispatcher(addBlock()),
    removeBlock: removeBlockActionMaker(dispatcher)
  }
}
