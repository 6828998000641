/* @flow */
import React from 'react'
import S from './Video4.styled'
import { validation } from './Video4.consts'
import ActionGetter from './Actions'
import LazyVideoPlayer from '@website/common/components/LazyVideoPlayer'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { withDispatchContext, withEditingContext } from '@contexts'

const isMobileDevice = () => global.window && global.window.innerWidth < 1025
class VideoItem extends React.PureComponent<void, void> {
  Actions = ActionGetter(this.props.componentDispatcher)

  isMobile = isMobileDevice()
  isHovered = false
  activeVideoRef = null

  state = {
    isPlaying: false
  }

  handleClick = () => {
    const { setCurrentVideoUrl, isEditing, video } = this.props
    setCurrentVideoUrl(this.isMobile && isEditing ? null : video.url)
    this.setState({
      isPlaying: false
    })
  }

  handleMouseEnter = () => {
    if (this.isMobile) {
      return
    }
    this.isHovered = true
    this.setIsPlaying(true)
  }

  handleMouseLeave = () => {
    // To make sure, that react-player updated his state
    setTimeout(() => {
      const { isPlaying } = this.state
      if (isPlaying && this.activeVideoRef) {
        this.activeVideoRef.seekTo(0)
      }
      this.setIsPlaying(false)
      this.isHovered = false
    }, 100)
  }

  onStart = () => {
    if (!this.isHovered) {
      this.setIsPlaying(false)
      this.activeVideoRef && this.activeVideoRef.seekTo(0)
    }
  }

  setIsPlaying = (isPlaying: boolean) => {
    this.setState({ isPlaying })
  }

  handlePlayerRef = ref => {
    this.activeVideoRef = ref
  }

  render() {
    const { Actions } = this
    const { isPlaying } = this.state
    const { idx, videosLength, isForBlock, video } = this.props
    return (
      <S.VideoItemWrap key={idx} isForBlock={isForBlock}>
        <ControlsWithModalOpener
          actions={Actions.videoActions(
            videosLength > validation.videos.min,
            video.url,
            idx
          )}
          style={{ width: '100%' }}
        >
          <S.PlayerWrapper>
            <S.Overlay
              onClick={this.handleClick}
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            />
            <LazyVideoPlayer
              key={video.url}
              style={{
                top: 0,
                left: 0,
                position: 'absolute'
              }}
              onStart={this.onStart}
              ref={this.handlePlayerRef}
              playing={!this.isMobile && isPlaying}
              muted
              loop
              url={video.url}
              width="100%"
              height="100%"
            />
          </S.PlayerWrapper>
          <EditableContent
            text={video.title}
            alignment={video.titleAlignment}
            as={S.BlockTitle}
            maxCount={validation.blockTitleMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={(newTitle: string) =>
              Actions.changeBlockTitle(newTitle, idx)
            }
            changeAlignment={(alignment: string) =>
              Actions.changeBlockTitleAlignment(alignment, idx)
            }
          />
        </ControlsWithModalOpener>
      </S.VideoItemWrap>
    )
  }
}

const VideoItemWithContexts = withDispatchContext(VideoItem)

export default withEditingContext(VideoItemWithContexts)
