/* @flow */
import produce from 'immer'
import { BUTTON_INITIAL_STATE } from './Header47.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions
        draft.imgCropParams = {}
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_BLOCK_PARAGRAPH': {
        const { paragraph, idx } = action.value
        draft.blocks[idx].paragraph = paragraph
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'SET_IMG_CROP_PARAMS':
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE': {
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      }
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'REMOVE_SOCIAL':
        const { idx: socialIdx } = action.value
        draft.socials.splice(socialIdx, 1)
        break
      case 'CHANGE_SOCIAL_URL':
        const { idx, link } = action.value
        draft.socials[idx].socialUrl = link
        break
      case 'CHANGE_SOCIAL_ICON': {
        const { idx: idx, iconName } = action.value
        draft.socials[idx].icon = iconName
        break
      }
      case 'CHANGE_ICON':
        {
          const { idx, iconName } = action.value
          draft.blocks[idx].iconName = iconName
        }
        break
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'REMOVE_BLOCK': {
        const { idx } = action.value
        draft.blocks.splice(idx, 1)
        break
      }
      case 'ADD_BLOCK':
        draft.blocks.push(initialState.blocks[0])
        break
      case 'CHANGE_BLOCK_PARAGRAPH_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.blocks[idx].blockParagraphAlignment = alignment
        break
      }
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({
            ...BUTTON_INITIAL_STATE[0],
            type: btnType
          })
        } else {
          draft.buttons.push({
            ...BUTTON_INITIAL_STATE[1],
            type: btnType
          })
        }
        break
    }
  })
}
