/* @flow */
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'
import { styleProvider } from '../../utils'
import { EditingContext } from '@contexts'

const getStyleForProp = styleProvider({})

const WMContainer = styled.div`
  width: 95%;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
  display: ${getStyleForProp('orientation')};
  @media only screen and (max-width: 1440px) {
    width: 90%;
    max-width: 1200px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding: 90px 40px 0 40px;
          `
        : css`
            padding-right: 40px;
            padding-left: 40px;
          `}
  }
  @media only screen and (max-width: 457px) {
    ${({ isEditing }) =>
      isEditing
        ? css`
            padding: 90px 20px 0 20px;
          `
        : css`
            padding-right: 20px;
            padding-left: 20px;
          `}
  }
`

const _WMContainer = ({ children, ...rest }) => {
  const { isEditing } = useContext(EditingContext)

  return (
    <WMContainer isEditing={isEditing} {...rest}>
      {children}
    </WMContainer>
  )
}

export default _WMContainer
