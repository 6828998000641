/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import THEME from './Header31.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  word-break: break-word;
  flex-direction: column;
  position: relative;
  ${getBackgroundStyles(THEME)};
  padding: 0;
  overflow: initial;
  .logoContainer .styled-controls span,
  .resize-icon.__rf-icons__moon {
    color: #fff;
  }
  .porjectLinksDesktop {
    nav {
      margin-right: 30px;
    }
    .control-container {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
  }
  & ${S.Container} {
    margin-bottom: 0;
    .porjectLinksDesktop {
      .link-wrapper a,
      .more-links div,
      .more-links i,
      .link-wrapper i,
      span,
      .rf-add-btn div {
        color: ${getStyleForProp('heading')};
      }
      .control-container:hover {
        box-shadow: 0px 0px 0px 1px ${getStyleForProp('heading')};
      }
      .rf-add-btn {
        border-color: ${getStyleForProp('heading')};
      }
    }
  }
`

const WmCustomContainer = styled(WMContainer)`
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  transform: translateY(70px);
  @media only screen and (max-width: 450px) {
    transform: translateY(40px);
  }

  & > .control-container {
    width: 100%;
    margin: 0 auto;
    @media only screen and (min-width: 1025px) {
      max-width: 800px;
    }
    & > h1 {
      max-width: unset;
      margin: 0;
    }
  }
`

const BottomBlock = styled.div`
  background: ${getStyleForProp('background')};
  min-height: 160px;
  z-index: -1;
  position: relative;
  @media only screen and (max-width: 450px) {
    min-height: 100px;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1025px) {
    max-width: 800px;
  }
`

const VideoControlWrapper = styled.div`
  width: 96%;
  max-width: 744px;
  margin: 50px auto 0 auto;
  display: flex;
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  @media only screen and (max-width: 768px) {
    max-width: 500px;
    margin: ${({ withoutTitle }) =>
      withoutTitle ? '-24px auto 0 auto' : '28px auto 0 auto'};
  }
  .iframe-video {
    border: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const VideoWrap = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 56.25%;
  margin: 0 auto;
`

const VideoContent = styled.div`
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border-radius: ${getStyleForProp('cardRadius')};
`

export default {
  WmCustomContainer,
  StyledContainer,
  Title,
  VideoControlWrapper,
  VideoContent,
  BottomBlock,
  VideoWrap
}
