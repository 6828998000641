/* @flow */
import React, { useContext, memo } from 'react'
import Swipe from '../Swipe'
import S from './Slider5.styled'
import { validation } from './Slider5.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import EditableContent from '@website/common/components/EditableContent'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { translate } from '@editor/common/utils/translations'

const Slider5 = memo(props => {
  const {
    data: { slides, title, paragraph, titleAlignment, paragraphAlignment }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const renderDots = (isActive: boolean) => <S.Dot isActive={isActive} />
  const renderLeftIcon = () => (
    <S.IconContainer className="left">
      <S.Icon size="xlarge" name="keyboard_arrow_left" />
    </S.IconContainer>
  )
  const renderRightIcon = () => (
    <S.IconContainer className="right">
      <S.Icon size="xlarge" name="keyboard_arrow_right" />
    </S.IconContainer>
  )
  const renderContent = (idx: number, stopAutoPlay: Function) => {
    return (
      <S.SlideContainer>
        <S.FigureContainer>
          <S.ImgFigure>
            <ControlsWithImageCropper
              toPropagate={false}
              style={{ width: '100%', height: '100%' }}
              actions={Actions.slideActions({
                keyForSizes: 'slider5',
                additionalArgs: { idx },
                showRemove: slides.length > validation.slides.min,
                clickHandlers: { change: stopAutoPlay, crop: stopAutoPlay },
                imageUrl: slides[idx].imageUrl
              })}
            >
              <Image
                alt="Slider image"
                sizes={slides[idx].imgDimensions}
                defaultImgSrc={slides[idx].imageUrl}
                asProps={{
                  className: 'figure-img',
                  imgCropParams: slides[idx].imgCropParams
                }}
              />
            </ControlsWithImageCropper>
          </S.ImgFigure>
        </S.FigureContainer>
        <Show when={[slides[idx].slideTitle]}>
          <S.TextContainer>
            <EditableContent
              as={S.SliderText}
              alignment={slides[idx].titleAlignment}
              required={validation.slideTitle.required}
              maxCount={validation.blockTitleMaxChar}
              text={slides[idx].slideTitle}
              className="WM_GLOBAL_heading20"
              onChange={(newTitle: string) => {
                Actions.changeSlideTitle(newTitle, idx)
              }}
              changeAlignment={(alignment: string) =>
                Actions.changeBlockTitleAlignment(alignment, idx)
              }
            />
          </S.TextContainer>
        </Show>
      </S.SlideContainer>
    )
  }
  return (
    <S.StyledContainer isOneSlide={slides.length === 1}>
      <S.WmCustomContainer isEditing={isEditing}>
        <S.HeaderTextsContainer>
          <EditableContent
            text={title}
            alignment={titleAlignment}
            as={S.Title}
            required={validation.title.required}
            maxCount={validation.titleMaxChar}
            className="WM_GLOBAL_heading32"
            onChange={Actions.changeTitle}
            changeAlignment={Actions.changeTitleAlignment}
          />
          <EditableContent
            text={paragraph}
            alignment={paragraphAlignment}
            as={S.Paragraph}
            maxCount={validation.paragraphMaxChar}
            className="WM_GLOBAL_paragraph18"
            onChange={Actions.changeParagraph}
            changeAlignment={Actions.changeParagraphAlignment}
          />
        </S.HeaderTextsContainer>
        <S.ImageSlideContainer>
          <Swipe
            dataArray={slides}
            renderDots={renderDots}
            renderContent={renderContent}
            renderLeftIcon={renderLeftIcon}
            renderRightIcon={renderRightIcon}
          />
        </S.ImageSlideContainer>
        <AddButton
          onAdd={Actions.addSlide}
          toShow={slides.length < validation.slides.max}
          style={{ marginTop: '60px' }}
        >
          <Icon name="glyph-add" className="icon" size="normal" />
          {translate('add_slide_label')}
        </AddButton>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Slider5
