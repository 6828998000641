/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Header41.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  flex-direction: column;
  ${getBackgroundStyles(THEME)};
  padding: 0 0 40px 0;
  overflow: hidden;
  @media only screen and (max-width: 1024px) {
    padding: 0 0 80px 0;
  }
`

const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${({ topOffset }) => `${topOffset + 40}px`};
  padding-bottom: 100px;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    padding-bottom: 0;
    padding-top: ${({ topOffset }) => `${topOffset + 60}px`};
  }
  .buttons-block {
    justify-content: center;
  }

  & > .control-container {
    max-width: 600px;
    width: 100%;
    margin: 70px auto auto auto;

    & > h2 {
      max-width: unset;
      margin: 0;
    }
  }
`

const Title = styled.h1`
  width: 50%;
  margin: 0 auto;
  color: ${getStyleForProp('heading')};
  text-align: center;
  font-weight: 700;
  margin-top: 60px;
  transform: scale(2, 2);
  ${({ isEditing }) =>
    isEditing
      ? css`
          &:after {
            transform: scale(0.7, 0.7) !important;
            top: 100% !important;
            left: -15% !important;
            @media only screen and (max-width: 768px) {
              transform: scale(0.6, 0.6) !important;
              left: -20% !important;
            }
          }
        `
      : css``};
  @media only screen and (max-width: 768px) {
    width: 49%;
    transform: scale(calc(54 / 26), calc(54 / 26));
    margin-top: 0;
  }
  @media only screen and (max-width: 650px) {
    width: 62.1%;
    transform: scale(calc(42 / 26), calc(42 / 26));
  }
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  margin-top: 70px;
  @media only screen and (max-width: 768px) {
    margin-top: 60px;
  }
  @media only screen and (max-width: 650px) {
    margin-top: 40px;
  }
`

export default {
  StyledContainer,
  HeaderContent,
  Title,
  Paragraph
}
