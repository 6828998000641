/* @flow */
import React, { memo, useCallback } from 'react'
import produce from 'immer'

import _RSVPInput from '../RSVPInput'
import _RSVPCheckboxList from '../RSVPCheckboxList'
import RSVPRadioButtonList from '../RSVPRadioButtonList'

import { TDynamicFieldProps } from './types'
import { getCheckboxComponentItems } from './utils'
import Validatable from '@website/common/lib/ValidatableHOC'

const RSVPInput = Validatable(_RSVPInput)
const RSVPCheckboxList = Validatable(_RSVPCheckboxList)

const DynamicField = ({
  idx,
  dynamicField,
  answer = dynamicField.type === 'checkbox' ? [] : '',
  setFormData,
  register,
  formErrors
}: TDynamicFieldProps) => {
  const setDynamicField = useCallback(
    value => {
      setFormData(
        produce(draft => {
          draft.dynamicFields[idx].answer = value
        })
      )
    },
    [idx, setFormData]
  )

  const onCheckboxListChange = useCallback(
    value => {
      const selectedOptions = dynamicField.options.filter(option =>
        value.includes(option.id)
      )

      setDynamicField(selectedOptions)
    },
    [dynamicField, setDynamicField]
  )

  const onRadioButtonListChange = useCallback(
    value => {
      const selectedOption = dynamicField.options.find(
        option => option.id === value
      )

      setDynamicField([selectedOption])
    },
    [dynamicField, setDynamicField]
  )

  const onInputChange = useCallback(
    value => {
      setDynamicField(value)
    },
    [setDynamicField]
  )

  return dynamicField.type === 'checkbox' && dynamicField.isMultiple ? (
    <RSVPCheckboxList
      title={dynamicField.text}
      isRequired={dynamicField.isRequired}
      items={getCheckboxComponentItems(dynamicField.options)}
      checkedItems={answer?.map(item => item.id)}
      className="rsvp-checkbox-list"
      errorMessage={formErrors[`checkbox-list-${idx}`]}
      {...register({
        fieldName: `checkbox-list-${idx}`,
        initialValue: answer?.map(item => item.id),
        onChange: onCheckboxListChange,
        validationOptions: {
          ...(dynamicField.isRequired
            ? {
                checkboxList: {
                  validator: value => !!value.length,
                  errorMessage: 'This field cannot be empty'
                }
              }
            : {})
        }
      })}
    />
  ) : dynamicField.type === 'checkbox' ? (
    <RSVPRadioButtonList
      title={dynamicField.text}
      items={getCheckboxComponentItems(dynamicField.options)}
      checkedItem={answer[0]?.id}
      onChange={onRadioButtonListChange}
      className="WM_GLOBAL_paragraph14"
      labelClassName="WM_GLOBAL_paragraph14"
    />
  ) : (
    <RSVPInput
      isRequired={dynamicField.isRequired}
      value={answer}
      label={dynamicField.text}
      className="rsvp-input"
      errorMessage={formErrors[`${dynamicField.type}-dynamicField-${idx}`]}
      {...register({
        fieldName: `${dynamicField.type}-dynamicField-${idx}`,
        initialValue: answer,
        onChange: onInputChange,
        validationOptions: {
          isRequired: dynamicField.isRequired
        }
      })}
    />
  )
}

export default memo(DynamicField)
