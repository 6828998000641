/* @flow */
import React, { PureComponent } from 'react'
import S from './Form.styled'
import { EMPTY_FIELD_MESSAGE, INVALID_EMAIL_MESSAGE } from '../Contact5.consts'
import { LabelRenderer, isRequiredField } from './Form.util'
import { withDispatchContext, withEditingContext } from '@contexts'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import { isValidEmail } from '@website/common/utils'

class PersonalDataInput extends PureComponent<void, void> {
  renderPersonalInput = (text, key) => {
    const {
      formData,
      isEditing,
      setFormValue,
      componentDispatcher,
      requiredFields,
      isMessageVisible,
      visibilityMessages,
      setVisibilityMessages
    } = this.props
    const isRequired = isRequiredField(key, requiredFields)

    const isError =
      (!isEditing && isMessageVisible && isRequired && !formData[key]) ||
      (!isEditing && isRequired && visibilityMessages[key]) ||
      (key === 'email' &&
        formData.email &&
        !isValidEmail(formData.email) &&
        visibilityMessages.email)
    return (
      <S.PersonalInfoInputContainer className="personal-info-input-container">
        <LabelRenderer
          text={text}
          label={key}
          isEditing={isEditing}
          isRequired={isRequired}
          dispatcher={componentDispatcher}
          className="WM_GLOBAL_secondary-font"
        />
        <div className="input-container">
          <S.InputField
            isError={isError}
            value={formData[key] || ''}
            onChange={e => setFormValue(e.target.value, key)}
            onBlur={() => setVisibilityMessages(key)}
            className="WM_GLOBAL_secondary-font"
          />
          {isError && (
            <S.ErrorMessage>
              {' '}
              <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
              {key === 'email' &&
              formData.email &&
              !isValidEmail(formData.email)
                ? INVALID_EMAIL_MESSAGE
                : EMPTY_FIELD_MESSAGE}
            </S.ErrorMessage>
          )}
        </div>
      </S.PersonalInfoInputContainer>
    )
  }

  render() {
    const { data: form } = this.props

    return (
      <>
        {this.renderPersonalInput(form.fullNameLabel, 'fullName')}
        {this.renderPersonalInput(form.emailLabel, 'email')}
        {this.renderPersonalInput(form.phoneLabel, 'phone')}
      </>
    )
  }
}

export default withEditingContext(withDispatchContext(PersonalDataInput))
