/* @flow */
/* eslint-disable max-statements */
import produce from 'immer'
import { buttonsInitialState } from './Header37.consts'

export default function reducer(state, action: any) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_NAVBAR_LOGO':
        {
          const { url, dimensions } = action.value
          draft.logoSrc = url
          draft.logoWidth = null
          draft.logoDimensions = dimensions
        }
        break
      case 'REMOVE_NAVBAR_LOGO':
        draft.logoSrc = ''
        draft.logoDimensions = {}
        draft.logoWidth = null
        break
      case 'CHANGE_LOGO_COLOR':
        draft.logoColor = action.value
        break
      case 'RESIZE_LOGO':
        draft.logoWidth = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_SUBTITLE':
        draft.subTitle = action.value
        break
      case 'CHANGE_SUB_TITLE_ALIGNMENT': {
        draft.subTitleAlignment = action.value
        break
      }
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NEWS_TITLE_ALIGNMENT': {
        draft.newsTitleAlignment = action.value
        break
      }
      case 'CHANGE_NEWS_PARAGRAPH_ALIGNMENT': {
        draft.newsParagraphAlignment = action.value
        break
      }
      case 'CHANGE_NEWS_TITLE':
        draft.newsTitle = action.value
        break
      case 'CHANGE_NEWS_PARAGRAPH':
        draft.newsParagraph = action.value
        break
      case 'CHANGE_LINK_DATA':
        const { linkData } = action.value
        draft.additionalUrl = linkData.link
        draft.additionalText = linkData.text
        break
      case 'REMOVE_LINK':
        draft.additionalText = ''
        draft.additionalUrl = ''
        break
      case 'ADD_LINK':
        const { additionalText, additionalUrl } = initialState
        draft.additionalText = additionalText
        draft.additionalUrl = additionalUrl
        break
      case 'CHANGE_BUTTON_DATA':
        const { btnIdx, btnData } = action.value
        draft.buttons[btnIdx].buttonUrl = btnData.link
        draft.buttons[btnIdx].buttonText = btnData.text
        break
      case 'REMOVE_BUTTON':
        draft.buttons.splice(action.value, 1)
        break
      case 'ADD_BUTTON':
        const btnType = !draft.buttons[0]
          ? 'primary'
          : draft.buttons[0].type === 'tertiary'
          ? 'primary'
          : 'tertiary'
        if (btnType === 'primary') {
          draft.buttons.unshift({
            ...buttonsInitialState[0],
            type: btnType
          })
        } else {
          draft.buttons.push({
            ...buttonsInitialState[1],
            type: btnType
          })
        }
        break
      case 'REMOVE_SOCIAL':
        {
          const { idx } = action.value
          draft.socials.splice(idx, 1)
        }
        break
      case 'ADD_SOCIAL':
        draft.socials.push(initialState.socials[0])
        break
      case 'CHANGE_SOCIAL_URL':
        {
          const { idx, link } = action.value
          draft.socials[idx].socialUrl = link
        }
        break
      case 'CHANGE_SOCIAL_ICON':
        {
          const { idx, iconName } = action.value
          draft.socials[idx].icon = iconName
        }
        break
      case 'CHANGE_SLIDER_IMAGE':
        {
          const { idx, url, dimensions } = action.value
          draft.slides[idx].url = url
          draft.slides[idx].imgDimensions = dimensions
          draft.slides[idx].imgCropParams = {}
        }
        break
      case 'REMOVE_SLIDER_IMAGE':
        {
          draft.slides.splice(action.value.idx, 1)
        }
        break
      case 'ADD_SLIDE':
        draft.slides.push(initialState.slides[0])
        break
      case 'SET_SLIDER_IMG_CROP_PARAMS': {
        const { imgCropParams, idx } = action.value
        draft.slides[idx].imgCropParams = imgCropParams
        break
      }
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions || {}
        draft.imgCropParams = {}
        break
      case 'SET_IMG_CROP_PARAMS': {
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
        break
      }
    }
  })
}
