import React, { memo, useRef, useEffect, useCallback } from 'react'
import { getMaxWidth, setWidth } from './utils'
import { getHiddenWrapper } from './styled'
import ResizeObserver from '../../utils/ResizeObserver'

const DefaultWrapperComp = React.forwardRef((props, ref) => (
  <div {...props} ref={ref} />
))

const PropotionalChildrenWrapper = ({ children, WrapperComp, ...props }) => {
  const wrapperRef = useRef(null)
  const hiddenWrapperRef = useRef(null)
  const resizeObserver = useRef([])

  const resizeHandler = useCallback(() => {
    if (
      !wrapperRef ||
      !wrapperRef.current ||
      !hiddenWrapperRef ||
      !hiddenWrapperRef.current
    ) {
      return
    }
    const childNodes = wrapperRef.current.childNodes
    const hiddenChildNodes = hiddenWrapperRef.current.childNodes
    const maxWidth = getMaxWidth(hiddenChildNodes)
    setWidth(childNodes, maxWidth)
  }, [wrapperRef.current, hiddenWrapperRef.current])

  const observeElements = useCallback(
    elements => {
      for (const element of elements) {
        resizeObserver.current.observe(element)
      }
    },
    [resizeObserver.current]
  )

  useEffect(() => {
    if (
      !wrapperRef ||
      !wrapperRef.current ||
      !hiddenWrapperRef ||
      !hiddenWrapperRef.current
    ) {
      return
    }
    const wrapperEl = wrapperRef.current
    const hiddenWrapperEl = hiddenWrapperRef.current
    if (!wrapperEl || !hiddenWrapperEl) {
      return
    }

    resizeObserver.current = new ResizeObserver(resizeHandler)
    observeElements(hiddenWrapperEl.childNodes)

    return () => {
      resizeObserver.current.disconnect()
    }
  }, [wrapperRef.current, hiddenWrapperRef.current, observeElements])

  const Wrapper = WrapperComp || DefaultWrapperComp
  const HiddenWrapper = getHiddenWrapper(Wrapper)
  return (
    <>
      <Wrapper {...props} ref={wrapperRef}>
        {children}
      </Wrapper>
      <HiddenWrapper {...props} ref={hiddenWrapperRef}>
        {children}
      </HiddenWrapper>
    </>
  )
}

export default memo(PropotionalChildrenWrapper)
