/* @flow */

module.exports = {
  id: 'Portfolio11',
  isUnique: false,
  thumbnailRatio: 0.68,
  label: 'Portfolio11',
  category: 'portfolio',
  colorMode: 'LIGHT',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/portfolio11-1.0.0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/portfolio11m-1.0.0.jpg',
  metaData: {
    title: {
      _type: 'title',
      _min: 0,
      _max: 50,
      _value: 'A journey through time'
    },
    paragraph: {
      _type: 'paragraph',
      _min: 0,
      _max: 150,
      _value: 'Good memories never fade'
    },
    titleAlignment: 'center',
    paragraphAlignment: 'center',
    galleryTab: {
      _type: 'list',
      _min: 2,
      _max: 5,
      _value: [
        {
          title: {
            _type: 'title',
            _min: 1,
            _max: 20,
            _value: 'Architecture'
          },
          gallery: {
            _type: 'list',
            _min: 1,
            _max: 6,
            _value: {
              _type: 'list',
              _value: [
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg'
                      }
                    }
                  ]
                },
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg'
                      }
                    }
                  ]
                },
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg'
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          title: {
            _type: 'title',
            _min: 1,
            _max: 20,
            _value: 'Nature'
          },
          gallery: {
            _type: 'list',
            _min: 1,
            _max: 6,
            _value: {
              _type: 'list',
              _value: [
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg'
                      }
                    }
                  ]
                },
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg'
                      }
                    }
                  ]
                },
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg'
                      }
                    }
                  ]
                }
              ]
            }
          }
        },
        {
          title: {
            _type: 'title',
            _min: 1,
            _max: 20,
            _value: 'Furniture'
          },
          gallery: {
            _type: 'list',
            _min: 1,
            _max: 6,
            _value: {
              _type: 'list',
              _value: [
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg'
                      }
                    }
                  ]
                },
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg'
                      }
                    }
                  ]
                },
                {
                  _type: 'list',
                  _min: 4,
                  _max: 4,
                  _value: [
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg'
                      }
                    },
                    {
                      imgUrl: {
                        _type: 'image',
                        _dimensionsKey: 'imgDimensions',
                        _optimizationSizes: [
                          { _width: 400 },
                          { _width: 600 },
                          { _width: 600 },
                          { _width: 600 }
                        ],
                        _min: 0,
                        _max: 2048,
                        _value:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg'
                      },
                      imgDimensions: {
                        small:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg',
                        medium:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg',
                        large:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg',
                        xlarge:
                          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg'
                      }
                    }
                  ]
                }
              ]
            }
          }
        }
      ]
    }
  },
  defaultProps: {
    title: 'A journey through time',
    paragraph: 'Good memories never fade',
    titleAlignment: 'center',
    paragraphAlignment: 'center',
    galleryTab: [
      {
        title: 'Architecture',
        gallery: [
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-1.1.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-2.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-3.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-4.0.0.jpg'
              }
            }
          ],
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-5.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-6.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-7.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-8.0.0.jpg'
              }
            }
          ],
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-9.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-10.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-11.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-12.0.0.jpg'
              }
            }
          ]
        ]
      },
      {
        title: 'Nature',
        gallery: [
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-13.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-14.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-15.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-16.0.0.jpg'
              }
            }
          ],
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-17.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-18.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-19.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-20.0.0.jpg'
              }
            }
          ],
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-21.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-22.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-23.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-24.0.0.jpg'
              }
            }
          ]
        ]
      },
      {
        title: 'Furniture',
        gallery: [
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-25.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-26.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-27.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-28.0.0.jpg'
              }
            }
          ],
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-29.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-30.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-31.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-32.0.0.jpg'
              }
            }
          ],
          [
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-33.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-34.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-35.0.0.jpg'
              }
            },
            {
              imgUrl:
                'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg',
              imgDimensions: {
                small:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg',
                medium:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg',
                large:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg',
                xlarge:
                  'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/portfolio11-36.0.0.jpg'
              }
            }
          ]
        ]
      }
    ]
  }
}
