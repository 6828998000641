/* @flow */
import React, { useContext, memo } from 'react'
import S from './Footer6.styled'
import ActionGetter from './Actions'
import Footer from '@website/common/components/Footer'
import Logo from '@website/common/components/Logo'
import { validation } from './Footer6.consts'
import { SubscribeForm } from '../SubscribeForm'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import {
  EditingContext,
  DispatchContext,
  GDPRPrivacyPolicyContext
} from '@contexts'
import THEME from './Footer6.theme'
import SocialLinks from '@website/common/components/SocialLinks'
import { Controls } from '@website/common/components/Controlled'
import LinkWrapper from '@website/common/components/LinkWrapper'

const Footer6 = memo(props => {
  const {
    data: {
      socials,
      logoSrc,
      formText,
      logoWidth,
      logoColor,
      buttonText,
      placeholder,
      copyrightText,
      logoDimensions,
      imgDimensions,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const {
    name: privacyPolicyName,
    link: privacyPolicyLink,
    showInFooter: isPrivacyPolicyVisible
  } = useContext(GDPRPrivacyPolicyContext) || {}

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      isEditing={isEditing}
      bgOverlayTransparency={bgOverlayTransparency}
      className="footer-6"
    >
      <WMContainer className="WM_GLOBAL_secondary-font">
        <Footer />
        <S.FooterContentWrap>
          <S.FooterContent>
            <Logo
              logoSrc={logoSrc}
              logoWidth={logoWidth}
              logoColor={logoColor}
              imgDimensions={logoDimensions || imgDimensions}
            />
            <S.SubscribeFormWrap>
              <EditableContent
                text={formText}
                as={S.Label}
                maxCount={validation.labelMaxChar}
                onChange={Actions.changeLabel}
                className="WM_GLOBAL_heading20"
              />
              <SubscribeForm
                actions={{
                  buttonActions: Actions.buttonActions(buttonText),
                  placeHolderAction: Actions.changePlaceHolderText
                }}
                buttonText={buttonText}
                placeholder={placeholder}
                theme={THEME}
              />
            </S.SubscribeFormWrap>
          </S.FooterContent>
        </S.FooterContentWrap>
      </WMContainer>
      <S.Divider />
      <WMContainer style={{ paddingTop: '0' }}>
        <S.Footer>
          <S.CopyRightWrapper>
            <EditableContent
              text={copyrightText}
              as={S.Copyright}
              className="WM_GLOBAL_secondary-font"
              maxCount={validation.copyrightMaxChar}
              onChange={Actions.changeCopyright}
              isPrivacyPolicyVisible={isPrivacyPolicyVisible}
            />
            {isPrivacyPolicyVisible ? (
              <S.LinkWrap isEditing={isEditing}>
                <Controls actions={Actions.privacyPolicyAction()}>
                  <LinkWrapper
                    text={privacyPolicyName}
                    url={privacyPolicyLink}
                    linkProps={{
                      className: 'privacy-policy-link WM_GLOBAL_secondary-font'
                    }}
                  />
                </Controls>
              </S.LinkWrap>
            ) : null}
          </S.CopyRightWrapper>

          <SocialLinks
            className="socials"
            socials={socials}
            socialsMaxCount={validation.socials.max}
          />
        </S.Footer>
      </WMContainer>
    </S.StyledContainer>
  )
})

export default Footer6
