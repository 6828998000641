export const DETAILS_FIELDS_META = [
  {
    key: 'subTitle',
    inputType: 'input'
  },
  {
    key: 'title',
    inputType: 'input'
  },
  {
    key: 'paragraph',
    inputType: 'textArea'
  },
  {
    key: 'button',
    inputType: 'input'
  }
]

export const POPUPS_FIELDS_META = [
  {
    key: 'yes',
    field: 'title',
    inputType: 'input'
  },
  {
    key: 'yes',
    field: 'paragraph',
    inputType: 'textArea'
  },
  {
    key: 'yes',
    field: 'buttonText',
    inputType: 'input'
  },
  {
    key: 'no',
    field: 'title',
    inputType: 'input'
  },
  {
    key: 'no',
    field: 'paragraph',
    inputType: 'textArea'
  },
  {
    key: 'closed',
    field: 'title',
    inputType: 'input'
  },
  {
    key: 'closed',
    field: 'paragraph',
    inputType: 'textArea'
  }
]

export const FORM_STATIC_FIELDS_META = [
  {
    key: 'rsvpOption',
    inputType: 'input'
  },
  {
    key: 'rsvpOption',
    field: 'yes',
    inputType: 'input'
  },
  {
    key: 'rsvpOption',
    field: 'no',
    inputType: 'input'
  },
  {
    key: 'firstName',
    inputType: 'input'
  },
  {
    key: 'lastName',
    inputType: 'input'
  },
  {
    key: 'email',
    inputType: 'input'
  },
  {
    key: 'phone',
    inputType: 'input'
  },
  {
    key: 'guestBtn',
    inputType: 'input'
  },
  {
    key: 'submitBtn',
    inputType: 'input'
  }
]

export const AVATAR_BG_OPTIONS = [
  'linear-gradient(130deg, #DFD7FD 10%, #5870AC 90%)',
  'linear-gradient(130deg, #E3ECFF 10%, #87A6EB 85%)',
  'linear-gradient(135deg, #F6F4FF -45%, #7F6AFF 205%)',
  'linear-gradient(330deg, #E8A7FF 5%, #F0E3FF 85%)',
  'linear-gradient(150deg, #FFD1D1 10%, #FEA4BF 90%)',
  'linear-gradient(130deg, #C2F4E5 10%, #7BE0E7 90%)',
  'linear-gradient(135deg, #DBE6FC -45%, #DFDEFA 90%)',
  'linear-gradient(130deg, #F9FAFF -10%, #B7C4E7 90%)',
  'linear-gradient(145deg, #D2FABC 15%, #4FC470 95%)',
  'linear-gradient(145deg, #FFE999 10%, #EDCD4B 105%)',
  'linear-gradient(135deg, #FFE0C9 15%, #FAADA0 90%)',
  'linear-gradient(130deg, #DDE4FF 0%, #74F3FB 185%)',
]
