import styled from 'styled-components'

export const TabsContainer = styled.div`
  width: 100%;
  display: flex;
`

export const CountriesDropdownWrapper = styled.div`
  margin: 20px 0 0 20px;

  .countries-search-dropdown {
    .dropdown-wrapper {
      max-height: 100%;
      height: 41px;
    }
  }
`
