export const validation = {
  headingMaxChar: 120,
  paragraphMaxChar: 1500,
  blockHeadingMaxChar: 80,
  blockParagraphMaxChar: 400,
  features: {
    min: 1,
    max: 3
  }
}
