/* @flow */
import React from 'react'
import S from './CTA11.styled'
import meta from './CTA11.meta'
import { validation } from './CTA11.consts'
import ActionGetter from './Actions'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Image from '@website/common/components/Image'
import StoreBadge from '@website/common/components/StoreBadge'
import IphoneDinamic from '@website/common/components/IphoneDinamic'
import EditableContent from '@website/common/components/EditableContent'
import { DispatchContext, EditingContext } from '@contexts'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { getCurrentScreenSize } from '@website/common/utils'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import Show from '@website/common/components/Show/Show'
import Mapper from '@website/common/components/Mapper'
import LinkWrapper from '@website/common/components/LinkWrapper'
import ProportionalChildrenWrapper from '@website/common/components/ProportionalChildrenWrapper'
import { translate } from '@editor/common/utils/translations'

// TODO: meta usage should be handled

class CTA11 extends React.Component<void, void> {
  render() {
    const {
      data: {
        backgroundImgUrl,
        backgroundImgDimensions,
        bgOverlayTransparency,
        title,
        paragraph,
        buttons,
        appleStoreLink,
        googlePlayLink,
        appleBackground,
        androidBackground,
        androidBackgroundImgCropParams,
        appleBackgroundImgCropParams,
        androidBackgroundDimensions,
        appleBackgroundDimensions
      }
    } = this.props

    const androidBackgroundUrl =
      (androidBackgroundDimensions &&
        androidBackgroundDimensions[getCurrentScreenSize()]) ||
      androidBackground
    const Actions = ActionGetter(this.context)

    return (
      <EditingContext.Consumer>
        {({ isEditing }) => {
          return (
            <S.Container
              isEditing={isEditing}
              backgroundImgUrl={backgroundImgUrl}
              backgroundImgDimensions={backgroundImgDimensions}
              bgOverlayTransparency={bgOverlayTransparency}
            >
              <S.WmCustomContainer>
                <S.Content>
                  <EditableContent
                    text={title}
                    as={S.Title}
                    required={validation.title.required}
                    maxCount={validation.titleMaxChar}
                    className="WM_GLOBAL_heading32"
                    onChange={Actions.changeTitle}
                  />
                  <EditableContent
                    text={paragraph}
                    as={S.Paragraph}
                    maxCount={validation.paragraphMaxChar}
                    className="WM_GLOBAL_paragraph18"
                    onChange={Actions.changeParagraph}
                  />
                  <S.BadgeContainer>
                    <If
                      condition={!!googlePlayLink}
                      then={() => (
                        <ControlsWithModalOpener
                          actions={Actions.storeIconActions(
                            'googlePlayLink',
                            googlePlayLink
                          )}
                        >
                          <StoreBadge
                            className="badge-item-wrap"
                            badgeName="google"
                            url={googlePlayLink}
                          />
                        </ControlsWithModalOpener>
                      )}
                      otherwise={() => (
                        <AddButton
                          onAdd={Actions.addStoreLink('googlePlayLink')}
                          toShow={true}
                          rounded
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          Google play
                        </AddButton>
                      )}
                    />
                    <If
                      condition={!!appleStoreLink}
                      then={() => (
                        <ControlsWithModalOpener
                          actions={Actions.storeIconActions(
                            'appleStoreLink',
                            appleStoreLink
                          )}
                        >
                          <StoreBadge
                            badgeName="apple"
                            url={appleStoreLink}
                            className="badge-item-wrap"
                          />
                        </ControlsWithModalOpener>
                      )}
                      otherwise={() => (
                        <AddButton
                          onAdd={Actions.addStoreLink('appleStoreLink')}
                          toShow={true}
                          rounded
                          style={{ margin: '0 0 0 10px' }}
                        >
                          <Icon
                            name="glyph-add"
                            className="icon"
                            size="normal"
                          />
                          App store
                        </AddButton>
                      )}
                    />
                  </S.BadgeContainer>
                  <Show when={[...buttons]}>
                    <S.ButtonsBlock>
                      <S.ButtonsContainer>
                        <ProportionalChildrenWrapper
                          WrapperComp={S.ButtonsWrap}
                          className="WM_GLOBAL_secondary-font"
                        >
                          <Mapper
                            data={buttons}
                            render={(btn: string, btnIdx: number) => {
                              return (
                                <S.BtnWrapper
                                  key={btnIdx}
                                  isTertiary={btn.type === 'tertiary'}
                                >
                                  <ControlsWithModalOpener
                                    actions={Actions.btnActions(btnIdx, {
                                      text: btn.buttonText,
                                      link: btn.buttonUrl
                                    })}
                                  >
                                    <LinkWrapper
                                      linkProps={{
                                        className: 'rf-comp-btn'
                                      }}
                                      text={btn.buttonText}
                                      url={btn.buttonUrl}
                                    />
                                  </ControlsWithModalOpener>
                                </S.BtnWrapper>
                              )
                            }}
                          />
                        </ProportionalChildrenWrapper>
                      </S.ButtonsContainer>
                      <AddButton
                        onAdd={Actions.addButton}
                        toShow={buttons.length < validation.buttons.max}
                        rounded
                      >
                        <Icon name="glyph-add" className="icon" size="normal" />
                        {translate('add_button_label')}
                      </AddButton>
                    </S.ButtonsBlock>
                  </Show>
                </S.Content>
                <S.MockupsWrap>
                  <S.Section className="frame-wrap apple-wrap">
                    <S.PhoneWrap>
                      <S.Phone>
                        <ControlsWithImageCropper
                          alignment="right"
                          actions={Actions.mockupActions({
                            mockupType: 'appleBackground',
                            showRemove:
                              appleBackground !==
                              meta.defaultProps.appleBackground,
                            imageUrl: appleBackground
                          })}
                        >
                          <Image
                            as={S.MobileImg}
                            asProps={{
                              imgCropParams: appleBackgroundImgCropParams
                            }}
                            defaultImgSrc={appleBackground}
                            sizes={appleBackgroundDimensions}
                            alt="Web page "
                          />
                        </ControlsWithImageCropper>
                        <S.DinamicWrap>
                          <IphoneDinamic />
                        </S.DinamicWrap>
                      </S.Phone>
                    </S.PhoneWrap>
                  </S.Section>
                  <S.Section className="frame-wrap android-wrap">
                    <S.AndroidWrap>
                      <ControlsWithImageCropper
                        actions={Actions.mockupActions({
                          showRemove:
                            androidBackground !==
                            meta.defaultProps.androidBackground,
                          mockupType: 'androidBackground',
                          imageUrl: androidBackgroundUrl
                        })}
                        alignment="right"
                        style={{ width: '100%' }}
                      >
                        <Image
                          as={S.MobileImg}
                          asProps={{
                            imgCropParams: androidBackgroundImgCropParams
                          }}
                          defaultImgSrc={androidBackgroundUrl}
                          sizes={androidBackgroundDimensions}
                          alt="Mobile mockup"
                        />
                      </ControlsWithImageCropper>
                    </S.AndroidWrap>
                  </S.Section>
                </S.MockupsWrap>
              </S.WmCustomContainer>
            </S.Container>
          )
        }}
      </EditingContext.Consumer>
    )
  }
}

CTA11.contextType = DispatchContext

export default CTA11
