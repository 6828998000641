import React, { useState, useRef, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'

import {
  Content,
  ContentHeader,
  ContentTitle,
  ContentSubTitle,
  ContentMainSection,
  MainContentContainer
} from '../../styled'
import { getPalette, selectSemrushSidebarVisibilityState } from '@selectors'
import { changePaletteColor } from '@actions/editor'
import ColorPicker from '@website/common/components/ColorPicker'
import If from '@website/common/components/Conditional'
import Scrollable from '@editor/common/components/Scrollable'
import { translate } from '@editor/common/utils/translations'
import { useResponsive } from '@shared/hooks'
import { getSemrushDependedScreenBreakpoint } from '@editor/common/utils/styled'
import { alternatePalettes } from '@editor/conf/consts'
import { BREAKPOINT_800 } from '@containers/edit/consts'

import ColorPalette from './ColorPalette'
import * as Styled from './styled'

const findIdx = (palette, altPalettes) => {
  const paletteArr = Object.keys(altPalettes).map(pal =>
    JSON.stringify(altPalettes[pal])
  )

  return paletteArr.findIndex(pal => pal === JSON.stringify(palette))
}

const ColorsContent = ({
  palette,
  isSemrushSidebarVisible,
  changePaletteColor
}) => {
  const breakpoint = getSemrushDependedScreenBreakpoint(
    BREAKPOINT_800,
    isSemrushSidebarVisible
  )
  const isSmallSizes = useResponsive(breakpoint)
  const activeIdx = findIdx(palette, alternatePalettes)

  const [selectedColor, setColor] = useState(null)
  const containerRef = useRef(null)
  const mainSectionRef = useRef(null)

  useEffect(() => {
    const elem = document.getElementById(`palette-${activeIdx}`)

    elem &&
      setTimeout(() => {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }, 300)
  }, [])

  const colorPicker = useMemo(
    () => (
      <ColorPicker
        hasExtraBackground
        containerRef={containerRef.current}
        color={palette[selectedColor]}
        closeColorPicker={() => setColor(null)}
        onColorChoose={color =>
          changePaletteColor(selectedColor, color.toUpperCase())
        }
      />
    ),
    [containerRef.current, selectedColor, palette]
  )

  return (
    <Content isContentVisible={!!selectedColor}>
      <ContentHeader>
        <ContentTitle>{translate('colors_label')}</ContentTitle>
        <ContentSubTitle>{translate('your_colors_label')}</ContentSubTitle>
        <Styled.YourColorsWrapper>
          {Object.keys(palette).map((color, idx) => {
            const isSelected = selectedColor === color

            return (
              <Styled.ColorWrap
                key={idx}
                ref={containerRef}
                isSelected={isSelected}
                onClick={() => setColor(color)}
              >
                <Styled.Color isSelected={isSelected} color={palette[color]} />
                <If
                  condition={isSelected}
                  then={() =>
                    isSmallSizes
                      ? ReactDOM.createPortal(colorPicker, document.body)
                      : colorPicker
                  }
                />
              </Styled.ColorWrap>
            )
          })}
        </Styled.YourColorsWrapper>
        <ContentSubTitle>{translate('color_presets_label')}</ContentSubTitle>
      </ContentHeader>
      <Scrollable childrenRef={mainSectionRef}>
        <ContentMainSection>
          <MainContentContainer isAlignedCenter>
            {Object.keys(alternatePalettes).map((palette, idx) => (
              <ColorPalette
                key={idx}
                id={`palette-${idx}`}
                isActive={activeIdx === idx}
                paletteName={palette}
                scrollableContainerRef={mainSectionRef}
              />
            ))}
          </MainContentContainer>
        </ContentMainSection>
      </Scrollable>
    </Content>
  )
}

const mapStateToProps = state => ({
  palette: getPalette(state),
  isSemrushSidebarVisible: selectSemrushSidebarVisibilityState(state)
})

export default connect(mapStateToProps, {
  changePaletteColor
})(ColorsContent)
