/* @flow */

module.exports = {
  id: 'Feature1',
  isUnique: false,
  thumbnailRatio: 0.83,
  label: 'Feature1',
  category: 'feature',
  colorMode: 'DARK',
  customPalette: {
    primaryDark: '#C71D12',
    primaryLight: '#FF564B',
    secondaryDark: '#263CE7',
    secondaryLight: '#81AAFC',
    tertiaryDark: '#0F28E5',
    tertiaryNormal: '#8692F1',
    tertiarySemiLight: '#C2C8F8',
    tertiaryLight: '#F1F3FD',
    tertiaryExtraLight: '#FFFFFF',
    accentWhite: '#FFFFFF'
  },
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/feature1-1.0.0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/feature1m-1.0.0.jpg',
  metaData: {
    backgroundImgUrl: {
      _type: 'backgroundImage',
      _dimensionsKey: 'backgroundImgDimensions',
      _optimizationSizes: [
        { _width: 800 },
        { _width: 1200 },
        { _width: 1600 },
        { _width: 2200 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg'
    },
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg'
    },
    bgOverlayTransparency: 0,
    title: {
      _type: 'title',
      _min: 0,
      _max: 120,
      _value: 'Website Essential Components'
    },
    description: {
      _type: 'paragraph',
      _min: 0,
      _max: 1500,
      _value:
        'Beside your product or service details your website should have some essential components to develop and work properly.'
    },
    titleAlignment: 'center',
    paragraphAlignment: 'center',
    featureIconText: {
      _type: 'list',
      _min: 1,
      _max: 12,
      _value: [
        {
          icon: 'icon-blog',
          title: {
            _type: 'title',
            _min: 0,
            _max: 60,
            _value: 'Blog'
          },
          blockContentAlignment: 'left',
          content: {
            _type: 'paragraph',
            _min: 0,
            _max: 1000,
            _value:
              'Create an engaging and helpful blog. The articles should be related with your business products or services and have a good content. Your blog would become active and attractive if your potential clients find a useful text that has the answers of their questions. Publish the necessary amount of articles. Do not present articles every single day. 2 or 3 times per week would be perfect.'
          },
          additionalUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          additionalText: {
            _type: 'linkLabel',
            _value: 'Learn more'
          }
        },
        {
          icon: 'icon-media_library',
          title: {
            _type: 'title',
            _min: 0,
            _max: 60,
            _value: 'Media Library'
          },
          blockContentAlignment: 'left',
          content: {
            _type: 'paragraph',
            _min: 0,
            _max: 1000,
            _value:
              'Have a cozy corner of useful, funny and introductory videos. People like to get informed about your services and how it works. Help them with video tutorials or some explainers so they can understand what is all about. Also do not forget to share your funny experiences creating a vlog style short videos, people would appreciate it.'
          },
          additionalUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          additionalText: {
            _type: 'linkLabel',
            _value: 'Learn more'
          }
        },
        {
          icon: 'icon-gallery',
          title: {
            _type: 'title',
            _min: 0,
            _max: 60,
            _value: 'Images'
          },
          blockContentAlignment: 'left',
          content: {
            _type: 'paragraph',
            _min: 0,
            _max: 1000,
            _value:
              'Visual memory always works effectively and the more eye-catching they are, the more attractive your website will be. Use colorful yet elegant illustrations on a complementary background. Do not fill the whole page, but only the necessary places to highlight the information next to it. Use correct sizes giving a clear panorama of all sections.'
          },
          additionalUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          additionalText: {
            _type: 'linkLabel',
            _value: 'Learn more'
          }
        },
        {
          icon: 'icon-forum',
          title: {
            _type: 'title',
            _min: 0,
            _max: 60,
            _value: 'Forum'
          },
          blockContentAlignment: 'left',
          content: {
            _type: 'paragraph',
            _min: 0,
            _max: 1000,
            _value:
              'Create a discussion page for your clients. Give them the opportunity to express themselves and share their thoughts and points of view. You may take part of it too. This will help you to understand the problems your clients face and give them a solution. You can also gather statistics and use it for your next service/product projects. Create a topic per week in addition to your clients’ ones. '
          },
          additionalUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          additionalText: {
            _type: 'linkLabel',
            _value: 'Learn more'
          }
        },
        {
          icon: 'icon-support',
          title: {
            _type: 'title',
            _min: 0,
            _max: 60,
            _value: 'Contacts/Support'
          },
          blockContentAlignment: 'left',
          content: {
            _type: 'paragraph',
            _min: 0,
            _max: 1000,
            _value:
              'Provide your clients an effective support service. Your contacts should always be updated on your website (e-mail, phone, address) and your support should be available according to its schedule. This will give people more confidence and they will trust on you. Also take into account a Live Chat especially for emergency cases, to solve problems instantly. '
          },
          additionalUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          additionalText: {
            _type: 'linkLabel',
            _value: 'Learn more'
          }
        },
        {
          icon: 'icon-link',
          title: {
            _type: 'title',
            _min: 0,
            _max: 60,
            _value: 'Social Media Links'
          },
          blockContentAlignment: 'left',
          content: {
            _type: 'paragraph',
            _min: 0,
            _max: 1000,
            _value:
              'A website without social network links is not a complete website. Nowadays social media has become an essential part to promote one’s business. They help you share information about your products and increase the number of potential clients. Register your account/channel on a few social networks. Do not create accounts on all of them, but only the necessary ones.'
          },
          additionalUrl: {
            _type: 'link',
            _min: 1,
            _max: 2048,
            _value: 'http://builder.renderforestsites.com'
          },
          additionalText: {
            _type: 'linkLabel',
            _value: 'Learn more'
          }
        }
      ]
    }
  },
  defaultProps: {
    backgroundImgUrl:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg',
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/features1-img1-1_0.jpg'
    },
    bgOverlayTransparency: 0,
    title: 'Website Essential Components',
    description:
      'Beside your product or service details your website should have some essential components to develop and work properly.',
    titleAlignment: 'center',
    paragraphAlignment: 'center',
    featureIconText: [
      {
        icon: 'icon-blog',
        title: 'Blog',
        blockContentAlignment: 'left',
        content:
          'Create an engaging and helpful blog. The articles should be related with your business products or services and have a good content. Your blog would become active and attractive if your potential clients find a useful text that has the answers of their questions. Publish the necessary amount of articles. Do not present articles every single day. 2 or 3 times per week would be perfect.',
        additionalUrl: 'http://builder.renderforestsites.com',
        additionalText: 'Learn more'
      },
      {
        icon: 'icon-media_library',
        title: 'Media Library',
        blockContentAlignment: 'left',
        content:
          'Have a cozy corner of useful, funny and introductory videos. People like to get informed about your services and how it works. Help them with video tutorials or some explainers so they can understand what is all about. Also do not forget to share your funny experiences creating a vlog style short videos, people would appreciate it.',
        additionalUrl: 'http://builder.renderforestsites.com',
        additionalText: 'Learn more'
      },
      {
        icon: 'icon-gallery',
        title: 'Images',
        blockContentAlignment: 'left',
        content:
          'Visual memory always works effectively and the more eye-catching they are, the more attractive your website will be. Use colorful yet elegant illustrations on a complementary background. Do not fill the whole page, but only the necessary places to highlight the information next to it. Use correct sizes giving a clear panorama of all sections.',
        additionalUrl: 'http://builder.renderforestsites.com',
        additionalText: 'Learn more'
      },
      {
        icon: 'icon-forum',
        title: 'Forum',
        blockContentAlignment: 'left',
        content:
          'Create a discussion page for your clients. Give them the opportunity to express themselves and share their thoughts and points of view. You may take part of it too. This will help you to understand the problems your clients face and give them a solution. You can also gather statistics and use it for your next service/product projects. Create a topic per week in addition to your clients’ ones. ',
        additionalUrl: 'http://builder.renderforestsites.com',
        additionalText: 'Learn more'
      },
      {
        icon: 'icon-support',
        title: 'Contacts/Support',
        blockContentAlignment: 'left',
        content:
          'Provide your clients an effective support service. Your contacts should always be updated on your website (e-mail, phone, address) and your support should be available according to its schedule. This will give people more confidence and they will trust on you. Also take into account a Live Chat especially for emergency cases, to solve problems instantly. ',
        additionalUrl: 'http://builder.renderforestsites.com',
        additionalText: 'Learn more'
      },
      {
        icon: 'icon-link',
        title: 'Social Media Links',
        blockContentAlignment: 'left',
        content:
          'A website without social network links is not a complete website. Nowadays social media has become an essential part to promote one’s business. They help you share information about your products and increase the number of potential clients. Register your account/channel on a few social networks. Do not create accounts on all of them, but only the necessary ones.',
        additionalUrl: 'http://builder.renderforestsites.com',
        additionalText: 'Learn more'
      }
    ]
  }
}
