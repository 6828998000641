export const validation = {
  headingMaxChar: 50,
  subTitleMaxChar: 80,
  paragraphMaxChar: 500,
  title: { required: true },
  buttons: {
    min: 0,
    max: 2
  },
  headerSocials: {
    min: 0,
    max: 7
  }
}
