export default {
  DARK: {
    border: () => 'none',
    shadow: () => 'none',
    buttonShadow: () => 'none',
    raisedShadow: () => 'none',
    heading: p => p.accentWhite,
    background: p => `${p.tertiaryDark}`
  },
  LIGHT: {
    heading: p => p.tertiaryDark,
    background: p => `${p.tertiaryLight}`
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    background: p => `${p.tertiaryExtraLight}`
  }
}
