/* @flow */
import { clientLogoActionMaker } from '@website/components/ActionMakers'
import {
  addLogo,
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'


export default dispatcher => {
  const _addLogo = () => {
    dispatcher(addLogo())
  }

  const _changeTitle = (title: string) => {
    dispatcher(changeTitle(title))
  }

  const _changeParagraph = (paragraph: string) => {
    dispatcher(changeParagraph(paragraph))
  }

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    addLogo: _addLogo,
    changeTitle: _changeTitle,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    changeParagraph: _changeParagraph,
    getClientsActions: clientLogoActionMaker(dispatcher)
  }
}
