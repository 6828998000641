import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    heading: p => p.accentWhite,
    blockHeading: p => p.tertiaryDark,
    paragraph: p => p.accentWhite,
    cardSubTitle: p => p.primaryLight,
    arrow: p => p.accentWhite,
    text: p => p.accentWhite,
    subTitle: p => p.accentWhite,
    stepsParagraph: p => p.accentWhite,
    border: p => `rgba(${hexToRgb(p.accentWhite)}, 0.5)`,
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    stepsInfo: p => p.primaryLight,
    stepsTitle: p => p.accentWhite,
    cardBackgroundColor: p => p.tertiaryDark
  },
  LIGHT: {
    heading: p => p.primaryDark,
    subTitle: p => p.primaryDark,
    cardSubTitle: p => p.primaryDark,
    arrow: p => p.tertiaryDark,
    blockHeading: p => p.tertiaryLight,
    paragraph: p => p.tertiaryDark,
    text: p => p.tertiaryDark,
    stepsParagraph: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.primaryDark)}, 0.5)`,
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    stepsInfo: p => p.primaryDark,
    stepsTitle: p => p.primaryDark,
    cardBackgroundColor: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    heading: p => p.tertiaryDark,
    subTitle: p => p.primaryLight,
    cardSubTitle: p => p.primaryDark,
    blockHeading: p => p.accentWhite,
    arrow: p => p.tertiaryDark,
    paragraph: p => p.tertiaryDark,
    text: p => p.tertiaryDark,
    stepsParagraph: p => p.tertiaryDark,
    border: p => `rgba(${hexToRgb(p.tertiaryDark)}, 0.5)`,
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    stepsInfo: p => p.primaryDark,
    stepsTitle: p => p.tertiaryDark,
    cardBackgroundColor: p => p.tertiaryExtraLight
  }
}
