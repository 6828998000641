/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Feature37.theme'
import {
  styleProvider,
  getTextStyleByAlignment,
  getBackgroundStyles
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'

const LARGE_SIZE = 1024
const TABLET_SIZE = 768
const MOBILE_SIZE = 457

export const getStyleForProp = styleProvider(THEME)

export const WmCustomContainer = styled.div`
  ${getBackgroundStyles(THEME)};
  width: 100%;
  ${({ isEditing }) =>
    isEditing ? 'padding: 130px 40px 80px' : 'padding: 80px 40px'};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    padding: 60px 20px;
  }
`

export const BlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1100px) {
    &.block-wrapper1 {
      .map-wrapper .col:nth-child(odd) {
        flex-direction: row-reverse;
      }
      .map-wrapper .col:nth-child(even) {
        flex-direction: row;
      }
    }
  }
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    &.block-wrapper1 {
      .map-wrapper .col {
        flex-direction: column !important;
      }
    }
  }
`

export const MapWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
  max-width: 1400px;
  width: 100%;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
    row-gap: 64px;
  }
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    padding-right: 0px;
    padding-left: 0px;
    row-gap: 48px;
  }
`

export const Col = styled.div`
  width: 30%;
  flex-grow: 1;
  display: flex;
  row-gap: 20px;
  flex-direction: column;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    width: 100%;
    max-width: 400px;
  }
  &:nth-child(1) {
    margin-top: 0;
    img,
    .lazy-figure {
      border-bottom-left-radius: ${getStyleForProp('cardRadius')};
      border-bottom-right-radius: ${getStyleForProp('cardRadius')};
      border-top-left-radius: 227px;
      border-top-right-radius: 227px;
    }
  }
  &:nth-child(2) {
    flex-direction: column-reverse;
    img,
    .lazy-figure {
      border-radius: 227px;
    }
    @media only screen and (max-width: ${LARGE_SIZE}px) {
      flex-direction: column;
    }
  }
  &:nth-child(3) {
    img,
    .lazy-figure {
      border-top-left-radius: ${getStyleForProp('cardRadius')};
      border-top-right-radius: ${getStyleForProp('cardRadius')};
      border-bottom-left-radius: 227px;
      border-bottom-right-radius: 227px;
    }
  }
`

export const BlockContent = styled.article`
  position: relative;
  @media only screen and (max-width: ${TABLET_SIZE}px) {
    width: 100%;
  }
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    ${({ isEditing }) => (isEditing ? 'margin-top: 40px;' : '')};
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  row-gap: 10px;

  .control-container {
    margin-bottom: 30px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
  .add-button-wrap {
    display: flex;
    width: 100%;
  }
`

export const TitleBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  max-width: 1400px;
  width: 100%;
  margin: 0 auto 50px;
  @media only screen and (max-width: ${MOBILE_SIZE}px) {
    margin-bottom: ${({ hasTitle }) => (hasTitle ? '40px' : '0')};
  }
  .control-container {
    width: 100%;
  }
`

export const ElementWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 500px;
  width: 100%;
  position: relative;
  margin-left: 50px;
  justify-content: flex-end;
  @media only screen and (max-width: ${LARGE_SIZE}px) {
    display: none;
  }
  svg {
    width: 80px;
    margin-right: 80px;
    position: relative;
    z-index: 2;
    path {
      fill: ${getStyleForProp('elementColor')};
    }
  }
`

export const ElementLine = styled.span`
  width: 100%;
  height: 1px;
  background-color: ${getStyleForProp('paragraph')};
  position: absolute;
`

export const Title = styled.h1`
  margin: 0;
  width: 100%;
  ${getTextStyleByAlignment};
  color: ${getStyleForProp('heading')};
`

export const Paragraph = styled.p`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${getStyleForProp('paragraph')};
`

export const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  .LazyLoad {
    width: 100%;
  }
  .lazy-figure {
    padding-top: 116.381%;
  }
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

export const AddButtonsWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`

export const AnchorWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-right: auto;
  margin-top: 14px;
  .link {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 16px !important;
    color: ${getStyleForProp('link')};
  }
  .learn-more-link {
    font-weight: 500;
    align-items: center;
    .arrow-right {
      margin-left: 0;
      padding-left: 5px;
      min-width: 24px;
      transition: transform linear 0.15s;
    }
    &:hover {
      opacity: 0.7;
      .arrow-right {
        opacity: 0.7;
        transform: translateX(5px);
      }
    }
  }
`

export const Icon = styled(LibIcon).attrs(({ theme }) => ({
  color: getStyleForProp('link')({ theme })
}))``
