import styled from 'styled-components'
import { globalColors } from '@renderforest/rf-ui-library/consts/colors'

export const SubSidebarWrapper = styled.div`
  @keyframes openAnim {
    0% {
      width: 0;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    }
    99% {
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    }
    100% {
      overflow: initial;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      width: 170px;
    }
  }
  @keyframes closeAnim {
    0% {
      overflow: initial;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      width: 170px;
    }
    1% {
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    }
    100% {
      width: 0;
      overflow: hidden;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
    }
  }
  border-right: 1px solid ${globalColors.secondaryExtraLight};
  width: ${({ isOpened }) => (isOpened ? '170px' : 0)};
  animation: ${({ isOpened }) =>
    isOpened ? 'openAnim 0.3s' : 'closeAnim 0.3s'};
`

export const SubSidebar = styled.div`
  width: 170px;
  height: 100%;
`
