import styled, { css } from 'styled-components'

import LibPopup from '@renderforest/rf-ui-library/organism/Popup'

export const SemrushKeywordPopup = styled(LibPopup)`
  & > div {
    padding: 0;
    max-height: 822px;
    width: 1320px;
    height: 100%;

    @media only screen and (max-width: 768px) {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      width: 100%;
    }

    & > div {
      padding: 40px 0 0;
      overflow: hidden;
    }
    & > button {
      z-index: 51;
    }
  }

  .circle-loader {
    background-color: rgba(255, 255, 255, 0.9);
    .circle {
      zoom: 0.7;
    }
  }
`

export const LoaderWrapper = styled.div`
  padding: 25px 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -3px 40px 0;

  @media only screen and (min-width: 1023px) {
    border-left: 1px solid #c1c9e0;
    border-right: 1px solid #c1c9e0;
  }

  ${({ isLoading }) =>
    isLoading
      ? css`
          display: block;
        `
      : css`
          display: none;
        `}
`
