/* @flow */
import styled from 'styled-components'

import THEME from './Team6.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import LibIcon from '@website/common/components/Icon'

const getStyleForProp = styleProvider(THEME)

export const Container = styled.div`
  word-break: break-word;
  width: 100%;
  padding: 80px 0;
  background: ${getStyleForProp('background')};
  @media only screen and (max-width: 1024px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 80px 0' : '80px 0')};
  }
  @media only screen and (max-width: 457px) {
    padding: ${({ isEditing }) => (isEditing ? '140px 0 60px 0' : '60px 0')};
  }

  .slick-list {
    margin-left: auto !important;
    margin-right: auto !important;
    width: calc(100% - 80px);
  }
  .slick-slide > div {
    margin: 0;
  }
  .team6-slick-slider {
    .slick-active {
      z-index: 1;
    }
  }
  .slick-arrow button {
    background: ${getStyleForProp('buttonBackground')};
    border-radius: ${getStyleForProp('iconRadius')};
  }
  .control-container {
    width: 100%;
  }
`

export const WmCustomContainer = styled(WMContainer)`
  position: relative;
  padding-top: 0;
  .SliderContainer {
    display: flex;
    justify-content: center;
    .slick-track {
      display: flex;
    }

    @media only screen and (max-width: 1200px) {
      .slick-prev {
        left: 0;
      }
      .slick-next {
        right: 0;
      }
    }
    .lazy-figure {
      border-radius: 50%;
      overflow: hidden;
    }
    & > div {
      width: 100%;
    }
  }
`

export const HeaderTexts = styled.div`
  text-align: center;
  width: 90%;
  max-width: 800px;
  margin: 0 auto 20px auto;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    box-sizing: border-box;
  }
  @media only screen and (max-width: 457px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .control-container {
    width: 100%;
  }
`

export const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  padding-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const Name = styled.p`
  color: ${getStyleForProp('heading')};
  width: 100%;
  ${getTextStyleByAlignment};
`

export const Position = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin: 4px 0 0 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const Slogan = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  margin: 14px 0 0 0;
`

export const Info = styled.div`
  width: 95% !important;
  max-width: 380px;
  margin-right: auto;
  margin-left: auto;
  padding: 4px 0 0 0;
  display: block !important;
  text-align: center;
  outline: none;
  &:focus {
    outline: none;
  }
  > .control-container:first-child {
    margin-top: 50px;
  }
  .control-container {
    margin-bottom: 20px;
    width: 100%;
    p {
      margin-top: 0;
    }
  }
`

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const SocialsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 14px 0 0 0;
  .link {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 5px;
  }
  .rf-add-btn {
    padding: 15px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      margin-right: initial;
    }
  }
`

export const DotS = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  &.left.light {
    left: ${({ isHidden }) => (isHidden ? '42%' : '26%')};
    top: ${({ isHidden }) => (isHidden ? '209px' : '50px')};
    background-color: ${getStyleForProp('pLight')};
    @media only screen and (max-width: 767px) {
      left: ${({ isHidden }) => (isHidden ? '36%' : '24%')};
      top: ${({ isHidden }) => (isHidden ? '219px' : '221px')};
    }
    @media only screen and (max-width: 550px) {
      left: 24%;
    }
  }
  &.right.light {
    right: 22%;
    top: 42%;
    background-color: ${getStyleForProp('pLight')};
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
  &.right.dark {
    right: 15%;
    top: 10%;
    background-color: ${getStyleForProp('pDark')};
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`

export const DotL = styled.div`
  width: 19px;
  height: 19px;
  box-sizing: border-box;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  &.dotL.left {
    border: 5px solid ${getStyleForProp('pDark')};
    left: ${({ isHidden }) => (isHidden ? '56%' : '20%')};
    top: ${({ isHidden }) => (isHidden ? '6%' : '43%')};
    @media only screen and (max-width: 767px) {
      top: 5%;
      left: initial;
      right: 24%;
    }
  }
  &.dotL.middle {
    left: 56%;
    top: 22px;
    border: 5px solid ${getStyleForProp('pLight')};
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
`
export const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('iconColor')
}))``
