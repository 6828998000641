/* @flow */

export const slickSliderSettings = {
  dots: false,
  autoplay: false,
  autoplaySpeed: 1000,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1
}

export const validation = {
  headingMaxChar: 40,
  paragraphMaxChar: 200,
  subTitleMaxChar: 20,
  newsTitleMaxChar: 20,
  newsParagraphMaxChar: 120,
  socialTextMaxChar: 20,
  title: { required: true },
  newsTitle: { required: true },
  socials: {
    min: 3,
    max: 8
  },
  slides: {
    min: 1,
    max: 10
  },
  buttons: {
    min: 0,
    max: 2
  }
}

export const buttonsInitialState = [
  {
    buttonText: 'LEARN MORE',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  },
  {
    buttonText: 'GET STARTED',
    buttonUrl: 'http://builder.renderforestsites.com',
    type: 'primary'
  }
]
