/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Header26.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'
import LibIcon from '@website/common/components/Icon'
const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles};
  flex-direction: column;
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0' : `${topOffset}px 0 0 0`};
  background-color: ${getStyleForProp('background')};
  & ${S.Container} {
    margin-bottom: 0;
  }
`
const WmCustomContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin: ${({ isEditing }) => (isEditing ? '46px 0 0 0' : '0')};
  .socials-block {
    display: inline-block;
    position: absolute;
    left: 30px;
    top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @media only screen and (max-width: 1024px) {
      left: 20px;
      top: 20px;
    }
    @media only screen and (max-width: 767px) {
      left: 10px;
    }
    .rf-add-btn {
      margin-left: initial;
    }
  }
  .socials-wrapper {
    display: inline-flex;
    flex-direction: column;
    .link {
      width: 36px;
      height: 36px;
      fill: ${getStyleForProp('socialColor')};
      border-radius: ${getStyleForProp('iconRadius')};
      border: 1px solid ${getStyleForProp('socialBorder')};
      @media only screen and (max-width: 767px) {
        width: 28px;
        height: 28px;
      }
    }
  }

  & > .control-container:nth-child(2) {
    position: absolute;
    width: 80%;
    max-width: 720px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0.3em;

    & > h1 {
      position: static;
      width: 100%;
      max-width: unset;
      transform: unset;
      margin-bottom: 0;
    }

    @media only screen and (max-width: 1024px) {
      width: 90%;
      max-width: 500px;
    }
    @media only screen and (max-width: 767px) {
      width: calc(100% - 80px);
      max-width: initial;
      h1 {
        padding-right: 0;
        padding-left: 0;
      }
    }
    @media only screen and (max-width: 457px) {
      width: calc(100% - 40px);
    }
    @media only screen and (max-width: 767px) {
      top: ${({ isEditing }) => (isEditing ? '65%' : '50%')};
      z-index: 2;
    }
  }

  & > .control-container:nth-child(3) {
    position: absolute !important;
    bottom: 30px;
    right: 30px;
    max-width: 400px;
    width: 100%;
    text-align: right;
    z-index: 2;

    & > h2 {
      position: static;
      max-width: unset;
    }

    @media only screen and (max-width: 767px) {
      width: 86%;
      bottom: 20px;
      right: 40px;
    }
    @media only screen and (max-width: 457px) {
      right: 20px;
    }
  }
`

const NavbarWrapper = styled.div`
  background-color: ${getStyleForProp('background')};
`

const ImagesContainer = styled.div`
  display: flex;
  width: 100%;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
  & + .control-container {
    position: absolute;
    width: 100%;
    max-width: 720px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -100%);

    + .control-container {
      position: static;
    }

    h1 {
      width: 100%;
    }
  }
`

const Overlay = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 12vw;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4)
  );
  @media only screen and (max-width: 767px) {
    height: 30vw;
    ${({ isBottom }) =>
    isBottom &&
    css`
        bottom: initial;
        top: 0%;
        background-image: linear-gradient(
          to top,
          rgba(0, 0, 0, 0),
          rgba(0, 0, 0, 0.4)
        );
      `};
  }
`

const ImageWrapper = styled.div`
  display: flex;
  width: 45%;
  flex-grow: 1;
  position: relative;
  @media only screen and (max-width: 767px) {
    width: 100%;
  }
  .control-container {
    width: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    position: relative;
    padding-top: 100%;
    height: initial;
  }
`

const ControlContainer = styled.div`
  position: relative;
  padding-top: 100%;
  width: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Img = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  position: absolute;
  width: 80%;
  max-width: 720px;
  top: 50%;
  left: 50%;
  transform: ${({ isEditing }) =>
    isEditing ? 'translate(-50%, 0)' : 'translate(-50%, -50%)'};
  @media only screen and (max-width: 1024px) {
    width: 90%;
    max-width: 500px;
  }

  @media only screen and (max-width: 767px) {
    top: ${({ isEditing }) => (isEditing ? '65%' : '50%')};
    z-index: 2;
    width: 100%;
    box-sizing: border-box;
    max-width: initial;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  position: absolute;
  bottom: 30px;
  right: 30px;
  max-width: 400px;
  text-align: right;
  z-index: 2;
  @media only screen and (max-width: 767px) {
    width: 100%;
    bottom: 20px;
    right: 40px;
  }
  @media only screen and (max-width: 457px) {
    right: 20px;
  }
`

const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('socialColor')
}))`
  @media only screen and (max-width: 767px) {
    position: relative;
    svg {
      position: absolute;
    }
  }
`

export default {
  WmCustomContainer,
  StyledContainer,
  NavbarWrapper,
  ImagesContainer,
  Overlay,
  ImageWrapper,
  Title,
  Paragraph,
  Img,
  ControlContainer,
  Icon
}
