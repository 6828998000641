/* @flow */
import { cropableImageModalActionMaker } from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeTitleAlignment,
  changeParagraphAlignment
} from '@website/components/CommonActions'

export default dispatcher => {
  const changePlaceHolderText = (key: string, value: string) => {
    dispatcher({
      type: 'CHANGE_PLACEHOLDER_TEXT',
      value: { key, value }
    })
  }

  const changeNameLabel = (newLabel: string) => ({
    type: 'CHANGE_NAME_LABEL',
    value: newLabel
  })

  const changeEmeailLabel = (newLabel: string) => ({
    type: 'CHANGE_EMAIL_LABEL',
    value: newLabel
  })

  const changeMessageLabel = (newLabel: string) => ({
    type: 'CHANGE_MESSAGE_LABEL',
    value: newLabel
  })

  const _changeTitle = (title: string) => dispatcher(changeTitle(title))

  const _changeParagraph = (paragraph: string) =>
    dispatcher(changeParagraph(paragraph))

  const _changeNameLabel = (newLabel: string) =>
    dispatcher(changeNameLabel(newLabel))

  const _changeEmailLabel = (newLabel: string) =>
    dispatcher(changeEmeailLabel(newLabel))

  const _changeMessageLabel = (newLabel: string) =>
    dispatcher(changeMessageLabel(newLabel))

  const _changeTitleAlignment = alignment => {
    dispatcher(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = alignment => {
    dispatcher(changeParagraphAlignment(alignment))
  }

  return {
    changeTitle: _changeTitle,
    changeParagraph: _changeParagraph,
    changePlaceHolderText,
    changeNameLabel: _changeNameLabel,
    changeEmailLabel: _changeEmailLabel,
    changeMessageLabel: _changeMessageLabel,
    changeTitleAlignment: _changeTitleAlignment,
    changeParagraphAlignment: _changeParagraphAlignment,
    getImgActions: imageUrl =>
      cropableImageModalActionMaker(dispatcher)({
        keyForSizes: 'contact10',
        showRemove: false,
        imageUrl
      })
  }
}
