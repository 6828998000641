import debounce from '@editor/common/utils/debounce'
import { setHasChangesAfterPublish, triggerApiError } from '@actions/editor'

const REVISION_DEBOUNCE_MILIS = 1000

export const getDebouncedRevisionAction = () =>
  debounce(({ siteId, data, dispatch, debouncedCb, params }) => {
    dispatch({ type: 'REVISION_SEND_START' })
    debouncedCb(siteId, data, params)
      .then(({ data }) => {
        dispatch({ type: 'REVISION_SEND_SUCCESS' })
        dispatch(setHasChangesAfterPublish(data.hasChangesAfterPublish))
      })
      .catch(err => {
        dispatch(triggerApiError(err))
        dispatch({ type: 'REVISION_SEND_FAILED' })
      })
  }, REVISION_DEBOUNCE_MILIS)
