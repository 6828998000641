/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header43.styled'
import THEME from './Header43.theme'
import { validation } from './Header43.consts'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import Icon from '@website/common/components/Icon'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import If from '@website/common/components/Conditional'
import AddButton from '@website/common/components/AddButton'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Buttons from '@website/common/components/Buttons'
import { translate } from '@editor/common/utils/translations'

const getIconsCount = blocks => {
  const iconsCount = blocks.reduce((acc, current) => {
    return acc + Number(!!current.iconName)
  }, 0)
  return iconsCount
}

const Header43 = memo(
  ({
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      blocks,
      buttons,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const Actions = ActionGetter(dispatcher)
    const { isEditing } = useContext(EditingContext)
    const [navBarHeight, setNavBarHeight] = useState(0)
    const iconsCount = getIconsCount(blocks)

    return (
      <S.StyledContainer
        backgroundImgUrl={backgroundImgUrl}
        backgroundImgDimensions={backgroundImgDimensions}
        bgOverlayTransparency={bgOverlayTransparency}
        isEditing={isEditing}
      >
        <NavBar
          handleHeightChange={setNavBarHeight}
          theme={THEME}
          logoProps={{
            logoSrc,
            logoColor,
            logoWidth,
            logoSizes: logoDimensions
          }}
        />
        <S.WMCustomContainer topOffset={navBarHeight}>
          <S.HeaderContent bgOverlayTransparency={bgOverlayTransparency}>
            <S.TopContent>
              <EditableContent
                text={title}
                alignment={titleAlignment}
                required={validation.title.required}
                maxCount={validation.headingMaxChar}
                isEditing={isEditing}
                className="WM_GLOBAL_heading42"
                data-text={title}
                as={S.Title}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
              />
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <Buttons data={buttons} />
            </S.TopContent>
            <S.BottomContent>
              <S.BlocksWrap
                isFourBlock={blocks.length === 4}
                isEditing={isEditing}
              >
                {blocks.map((block, idx) => {
                  const showRemove = blocks.length > validation.blocks.min

                  return (
                    <S.ControlContainer key={idx} isEditing={isEditing}>
                      <ControlsWithModalOpener
                        actions={Actions.blockActions({
                          idx,
                          showRemove
                        })}
                        style={{ width: '100%' }}
                      >
                        <S.ItemWrapper>
                          <If
                            condition={!!block.iconName}
                            otherwise={() => (
                              <AddButton
                                onAdd={() => Actions.addIcon(idx)}
                                style={{
                                  marginBottom: '16px'
                                }}
                                type="icon"
                                large
                                toShow
                              >
                                +
                              </AddButton>
                            )}
                            then={() => (
                              <S.IconWrapper>
                                <ControlsWithModalOpener
                                  actions={Actions.iconActions(idx)}
                                >
                                  <Icon
                                    size={45}
                                    name={block.iconName}
                                    className="blockIcon"
                                  />
                                </ControlsWithModalOpener>
                              </S.IconWrapper>
                            )}
                          />
                          <EditableContent
                            text={block.blockTitle}
                            alignment={block.blockTitleAlignment}
                            as={S.BlockTitle}
                            required={validation.blockTitle.required}
                            maxCount={validation.blockTitleMaxChar}
                            className="WM_GLOBAL_heading20"
                            onChange={(newTitle: string) => {
                              Actions.changeBlockTitle(newTitle, idx)
                            }}
                            isIcon={block.iconName}
                            isEditing={isEditing}
                            isAllIconsMissing={iconsCount === 0}
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockTitleAlignment(alignment, idx)
                            }
                          />
                          <EditableContent
                            text={block.blockParagraph}
                            alignment={block.blockParagraphAlignment}
                            as={S.BlockParagraph}
                            maxCount={validation.blockParagraphMaxChar}
                            className="WM_GLOBAL_paragraph14"
                            onChange={(newTitle: string) => {
                              Actions.changeBlockParagraph(newTitle, idx)
                            }}
                            changeAlignment={(alignment: string) =>
                              Actions.changeBlockParagraphAlignment(
                                alignment,
                                idx
                              )
                            }
                          />
                        </S.ItemWrapper>
                      </ControlsWithModalOpener>
                    </S.ControlContainer>
                  )
                })}
              </S.BlocksWrap>
              <AddButton
                onAdd={Actions.addBlock}
                toShow={blocks.length < validation.blocks.max}
                style={{
                  margin: '30px 0'
                }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_block_label')}
              </AddButton>
            </S.BottomContent>
          </S.HeaderContent>
        </S.WMCustomContainer>
      </S.StyledContainer>
    )
  }
)

Header43.contextType = DispatchContext

export default Header43
