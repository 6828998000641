import { hexToRgb } from '@website/common/utils'
export default {
  DARK: {
    background: p => `rgba(${hexToRgb(p.tertiaryDark)}, 1)`,
    navbarBg: p => p.tertiaryDark
  },
  LIGHT: {
    background: p => `rgba(${hexToRgb(p.tertiaryLight)}, 1)`,
    navbarBg: p => p.tertiaryLight
  },
  EXTRALIGHT: {
    background: p => `rgba(${hexToRgb(p.tertiaryExtraLight)}, 1)`,
    navbarBg: p => p.tertiaryExtraLight
  }
}
