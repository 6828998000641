/* @flow */
import React, { useContext, memo } from 'react'
import S from './Text14.styled'
import { validation } from './Text14.consts'
import ActionGetter from './Actions'
import Show from '@website/common/components/Show/Show'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import AdditionalLink from '@website/common/components/AdditionalLink/AdditionalLink'

const Text14 = memo(props => {
  const dispatcher = useContext(DispatchContext)
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      additionalText,
      additionalUrl,
      backgroundImgDimensions,
      backgroundImgUrl,
      bgOverlayTransparency
    }
  } = props
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer>
        <Show when={[title, paragraph, additionalText]}>
          <S.TextCompWrap>
            <S.ContentWrap>
              <EditableContent
                text={paragraph}
                alignment={paragraphAlignment}
                as={S.Description}
                maxCount={validation.maxParagraph}
                className="WM_GLOBAL_heading20"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <EditableContent
                text={title}
                alignment={titleAlignment}
                as={S.Title}
                maxCount={validation.maxTitleCount}
                className="WM_GLOBAL_heading54"
                onChange={Actions.changeTitle}
                required
                changeAlignment={Actions.changeTitleAlignment}
              />
              <AdditionalLink
                index={null}
                additionalLinkText={additionalText}
                additionalLinkUrl={additionalUrl}
              />
            </S.ContentWrap>
          </S.TextCompWrap>
        </Show>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Text14
