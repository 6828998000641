/* @flow */
/* eslint-disable max-statements */

import React, { useContext, useState, useEffect, memo } from 'react'
import S from './Contact13.styled'
import {
  validation,
  EMPTY_FIELD_MESSAGE,
  INVALID_EMAIL_MESSAGE,
  NAME,
  EMAIL,
  MESSAGE,
  defaultPlaceholders
} from './Contact13.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import WMContainer from '@website/common/components/WMContainer'
import EditableContent from '@website/common/components/EditableContent'
import { removeEmptyFields } from '@website/common/utils'
import Image from '@website/common/components/Image'
import { isValidEmail, isValidPhoneNumber } from '@website/common/utils'
import ErrorIcon from '@website/common/assets/error_icon_2.svg'
import SubmitButton from '@website/common/components/SubmitButton/SubmitButton'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import If from '@website/common/components/Conditional'
import Icon from '@website/common/components/Icon'
import { translate } from '@editor/common/utils/translations'

import {
  DispatchContext,
  EditingContext,
  SiteHashContext,
  SiteIdContext
} from '@contexts'
import { contactApi } from '@website/common/api'

const initialState = {
  name: '',
  email: '',
  message: ''
}

const Contact13 = memo(
  ({
    data: {
      title,
      titleAlignment,
      titleTextGradient,
      paragraph,
      paragraphAlignment,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency,
      blocks,
      placeholders,
      name,
      email,
      message,
      buttonText,
      imageUrl,
      imgDimensions,
      imgCropParams
    }
  }) => {
    const dispatcher = useContext(DispatchContext)
    const { isEditing } = useContext(EditingContext)
    const siteId = useContext(SiteIdContext)
    const siteHash = useContext(SiteHashContext)
    const Actions = ActionGetter(dispatcher)
    const [nameErrorMessage, setNameErrorMessage] = useState('')
    const [emailErrorMessage, setEmailErrorMessage] = useState('')

    const [inputData, setInputData] = useState(
      isEditing ? { ...placeholders } : initialState
    )
    const [successState, setSuccessState] = useState(false)
    const [timer, setTimer] = useState(null)

    useEffect(() => {
      if (timer) {
        setTimer(null)
      }
    })

    useEffect(() => {
      if (isEditing) {
        setInputData({ ...placeholders })
      }
    }, [placeholders])

    const handleNameError = () => {
      if (!inputData.name) {
        setNameErrorMessage(EMPTY_FIELD_MESSAGE)
      }
    }

    const handleEmailError = () => {
      if (!inputData.email) {
        setEmailErrorMessage(EMPTY_FIELD_MESSAGE)
      }
      if (inputData.email && !isValidEmail(inputData.email)) {
        setEmailErrorMessage(INVALID_EMAIL_MESSAGE)
      }
    }

    const evtHandlerGetter = (type: string) => {
      const evtHandlers = {
        onChange: (e: Event) => {
          setInputData({ ...inputData, [type]: e.target.value })
          if (type === NAME) {
            setNameErrorMessage('')
          }
          if (type === EMAIL) {
            setEmailErrorMessage('')
          }
        }
      }
      evtHandlers.onBlur = (e: Event) => {
        if (isEditing) {
          const value = e.target.value || defaultPlaceholders[type]
          if (value === placeholders[type]) {
            return
          }
          Actions.changePlaceHolderText(type, value)
          setInputData({ ...inputData, [type]: value })
        } else {
          if (type === NAME) {
            handleNameError()
          }
          if (type === EMAIL) {
            handleEmailError()
          }
        }
      }
      return evtHandlers
    }

    const sendData = () => {
      if (isEditing) {
        return
      }
      handleNameError()
      handleEmailError()
      if (isValidEmail(inputData.email) && inputData.name) {
        contactApi(
          siteId,
          removeEmptyFields({
            ...inputData,
            siteHash
          })
        )
          .then(() => {
            setSuccessState(true)
            setTimer(
              setTimeout(() => {
                setInputData(initialState)
                setSuccessState(false)
              }, 2000)
            )
          })
          .catch(console.warn)
      }
    }
    const getPlaceHolderText = key => (placeholders && placeholders[key]) || ''

    return (
      <S.StyledContainer
        isEditing={isEditing}
        backgroundImgUrl={backgroundImgUrl}
        bgOverlayTransparency={bgOverlayTransparency}
        backgroundImgDimensions={backgroundImgDimensions}
      >
        <WMContainer>
          <S.FormImageWrapper>
            <S.ImageWrapper>
              <S.ControlContainer>
                <ControlsWithImageCropper
                  actions={Actions.getImgActions(imageUrl)}
                  style={{ width: '100%' }}
                >
                  <Image
                    as={S.Img}
                    sizes={imgDimensions}
                    defaultImgSrc={imageUrl}
                    asProps={{
                      className: 'feature-image',
                      imgCropParams
                    }}
                    alt="Feature illustration"
                  />
                </ControlsWithImageCropper>
              </S.ControlContainer>
            </S.ImageWrapper>
            <S.RightContent>
              <EditableContent
                text={title}
                as={S.Title}
                isTextGradient={titleTextGradient}
                isGradientControlHidden={false}
                className="WM_GLOBAL_heading42"
                alignment={titleAlignment}
                required={!paragraph}
                maxCount={validation.headingMaxChar}
                onChange={Actions.changeTitle}
                changeAlignment={Actions.changeTitleAlignment}
                changeTextGradientStatus={Actions.changeTitleGradientStatus}
              />
              <EditableContent
                text={paragraph}
                as={S.Paragraph}
                className="WM_GLOBAL_paragraph18"
                alignment={paragraphAlignment}
                required={!title}
                maxCount={validation.paragraphMaxChar}
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
              <S.ContactItemWrap isEditing={isEditing}>
                <Mapper
                  data={blocks}
                  render={(block: string, idx: number) => (
                    <Show when={[block.paragraph]}>
                      <ControlsWithModalOpener
                        className="contact-item-control"
                        actions={Actions.blockActions({
                          idx,
                          showRemove: blocks.length > validation.blocks.min
                        })}
                      >
                        <S.ItemWrap key={idx} isEditing={isEditing}>
                          <If
                            condition={!!block.iconName}
                            otherwise={() => (
                              <AddButton
                                onAdd={() => Actions.addIcon(idx)}
                                style={{
                                  margin: '0px'
                                }}
                                type="icon"
                                large
                                toShow
                              >
                                +
                              </AddButton>
                            )}
                            then={() => (
                              <S.IconWrapper>
                                <ControlsWithModalOpener
                                  actions={Actions.iconActions(idx)}
                                >
                                  <S.ContactIcon
                                    size="large"
                                    name={block.iconName}
                                  />
                                </ControlsWithModalOpener>
                              </S.IconWrapper>
                            )}
                          />
                          <If
                            condition={
                              isEditing ||
                              (!isValidEmail(block.paragraph) &&
                                !isValidPhoneNumber(block.paragraph))
                            }
                            otherwise={() => (
                              <S.A
                                href={`${
                                  isValidEmail(block.paragraph)
                                    ? 'mailto:'
                                    : 'tel:'
                                }${block.paragraph}`}
                                className="WM_GLOBAL_paragraph18"
                              >
                                {block.paragraph}
                              </S.A>
                            )}
                            then={() => (
                              <EditableContent
                                areControlsHidden
                                as={S.BlockParagraph}
                                text={block.paragraph}
                                maxCount={validation.blockParagraphMaxChar}
                                isEditing={isEditing}
                                className="WM_GLOBAL_paragraph18"
                                onChange={(newParagraph: string) =>
                                  Actions.changeBlockParagraph(
                                    newParagraph,
                                    idx
                                  )
                                }
                              />
                            )}
                          />
                        </S.ItemWrap>
                      </ControlsWithModalOpener>
                    </Show>
                  )}
                />
                <AddButton
                  onAdd={Actions.addBlock}
                  toShow={blocks.length < validation.blocks.max}
                >
                  <Icon name="glyph-add" className="icon" size="normal" />
                  {translate('add_item_label')}
                </AddButton>
              </S.ContactItemWrap>
              <S.FormContainer>
                <S.InputContainer isEditing={isEditing}>
                  <EditableContent
                    text={name}
                    as={S.Label}
                    isEditing={isEditing}
                    maxCount={validation.labelMaxChar}
                    required={validation.name.required}
                    onChange={Actions.changeNameLabel}
                    className="WM_GLOBAL_paragraph14"
                    for="name"
                  />
                  <S.InputWrapper>
                    <S.RequiredIcon
                      name="glyph-asterisk"
                      className="required-icon"
                    />
                    <S.Input
                      value={inputData.name}
                      placeholder={isEditing ? '' : getPlaceHolderText(NAME)}
                      aria-label="Name input"
                      className="WM_GLOBAL_secondary-font"
                      isError={nameErrorMessage}
                      {...evtHandlerGetter(NAME)}
                      isEditing={isEditing}
                    />
                    {nameErrorMessage && (
                      <S.ErrorMessage>
                        <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                        {nameErrorMessage}
                      </S.ErrorMessage>
                    )}
                  </S.InputWrapper>
                </S.InputContainer>
                <S.InputContainer>
                  <EditableContent
                    text={email}
                    as={S.Label}
                    isEditing={isEditing}
                    required={validation.email.required}
                    maxCount={validation.labelMaxChar}
                    onChange={Actions.changeEmailLabel}
                    className="WM_GLOBAL_paragraph14"
                    for="email"
                  />
                  <S.InputWrapper>
                    <S.RequiredIcon
                      name="icon-asterisk"
                      className="required-icon"
                    />
                    <S.Input
                      value={inputData.email}
                      aria-label="Email input"
                      placeholder={isEditing ? '' : getPlaceHolderText(EMAIL)}
                      className="WM_GLOBAL_secondary-font"
                      isError={emailErrorMessage}
                      {...evtHandlerGetter(EMAIL)}
                      isEditing={isEditing}
                    />
                    {emailErrorMessage && (
                      <S.ErrorMessage>
                        <S.ErrorIcon src={ErrorIcon} alt="Error icon" />
                        {emailErrorMessage}
                      </S.ErrorMessage>
                    )}
                  </S.InputWrapper>
                </S.InputContainer>
                <S.InputContainer>
                  <EditableContent
                    text={message}
                    as={S.Label}
                    isEditing={isEditing}
                    required={validation.message.required}
                    maxCount={validation.labelMaxChar}
                    onChange={Actions.changeMessageLabel}
                    className="WM_GLOBAL_paragraph14"
                    for="message"
                  />
                  <S.TextArea
                    value={inputData.message}
                    placeholder={isEditing ? '' : getPlaceHolderText(MESSAGE)}
                    data-gramm_editor="false" // for grammarly extension
                    aria-label="Message input"
                    className="WM_GLOBAL_secondary-font"
                    {...evtHandlerGetter(MESSAGE)}
                    isEditing={isEditing}
                  />
                </S.InputContainer>
                <SubmitButton
                  buttonText={buttonText}
                  submitFormData={sendData}
                  showSubmitNotification={successState}
                />
              </S.FormContainer>
            </S.RightContent>
          </S.FormImageWrapper>
        </WMContainer>
      </S.StyledContainer>
    )
  }
)

export default Contact13
