// @flow

import React, { memo, useState, useCallback } from 'react'
import LanguagesDropdown from '../LanguagesDropdown'
import ChosenLanguage from '../ChosenLanguage'
import * as Styled from './styled'
import { CURRENT_LANGUAGE } from '@editor/conf/consts'
import { LANGUAGES } from './../LanguagesDropdown/consts'
import { TLanguagesProps } from './types'

const language = LANGUAGES.find(item => item.langCode === CURRENT_LANGUAGE).text

const Languages = ({
  closeDropdown,
  contentRef,
  hasMaxHeightAnimation
}: TLanguagesProps) => {
  const [isOpen, setOpenedState] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState(language)

  const toggle = useCallback(() => {
    setOpenedState(!isOpen)
  }, [isOpen])

  return (
    <>
      <Styled.LanguageWrapper onClick={toggle}>
        <ChosenLanguage
          isOpen={isOpen}
          contentRef={contentRef}
          currentLanguage={currentLanguage}
        />
      </Styled.LanguageWrapper>
      <Styled.LanguagesDropdownWrapper
        isOpen={isOpen}
        hasMaxHeightAnimation={hasMaxHeightAnimation}
      >
        <LanguagesDropdown
          changeCurrentLang={setCurrentLanguage}
          closeDropdown={closeDropdown}
        />
      </Styled.LanguagesDropdownWrapper>
    </>
  )
}

export default memo(Languages)
