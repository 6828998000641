// @flow

import React, { memo } from 'react'
import { UserInfo, MyProjects } from '../user-components'
import * as Styled from './styled'

const UserProfile = ({ closeSlideLeft }) => (
  <>
    <UserInfo />
    <MyProjects closeDropdown={closeSlideLeft} />
  </>
)

export default memo(UserProfile)
