/* @flow */
import styled from 'styled-components'

import THEME from './Text2.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)}
  @media only screen and (min-width: 769px) {
    padding-top: ${({ isEditing }) => isEditing && '150px'};
  }
`

const WMCustomContainer = styled(WMContainer)`
  width: 100%;
  margin: 0 auto;
  color: ${getStyleForProp('heading')};
  text-align: center;
  margin: 0 auto;
  @media only screen and (min-width: 1025px) {
    max-width: 900px;
  }
  > :first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 30px;
    width: 100%;
    h1,
    p {
      margin-top: 0;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin: 0 auto;
  @media only screen and (min-width: 1025px) {
    max-width: 900px;
  }
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
  margin: 20px auto 0 auto;
  @media only screen and (min-width: 1025px) {
    max-width: 900px;
  }
`

export default { StyledContainer, WMCustomContainer, Title, Description }
