/* @flow */
import React from 'react'
import { withTheme } from 'styled-components'

const Badge = ({}) => {
  return (
    <svg
      width="1585px"
      viewBox="0 0 1585 1585"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <linearGradient
          x1="50%"
          y1="0%"
          x2="50%"
          y2="96.0553649%"
          id="linearGradient-1"
        />
      </defs>
      <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <rect
          className="rect"
          id="Rectangle"
          transform="translate(792.316720, 792.316720) rotate(45.000000) translate(-792.316720, -792.316720) "
          x="190.81672"
          y="174.31672"
          width={1203}
          height={1236}
          rx={170}
        />
      </g>
    </svg>
  )
}

export default withTheme(Badge)
