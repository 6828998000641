import LeftAlignIcon from '@editor/common/assets/svgr-icons/Left_align.svg'
import CenterAlignIcon from '@editor/common/assets/svgr-icons/Center_align.svg'
import RightAlignIcon from '@editor/common/assets/svgr-icons/Right_align.svg'
import { translate } from '@editor/common/utils/translations'

export const LEFT = 'left'
export const CENTER = 'center'
export const RIGHT = 'right'

export const ALIGNMENTS_DATA = {
  left: {
    IconComp: LeftAlignIcon,
    tooltipText: translate('left_align_label'),
    value: 'left'
  },
  center: {
    IconComp: CenterAlignIcon,
    tooltipText: translate('center_align_label'),
    value: 'center'
  },
  right: {
    IconComp: RightAlignIcon,
    tooltipText: translate('right_align_label'),
    value: 'right'
  }
}
