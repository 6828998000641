// @flow

import React, { memo, useCallback, useState, useRef } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import { ICON_FILTER_OPTIONS } from '../consts'
import FiltersList from './FiltersList'
import Input from '@website/common/components/Input'
import { FlyOutMenu } from '@editor/common/lib/FlyOutMenu'
import FilterIcon from '@editor/common/assets/svgr-icons/filter_icon.svg'
import debounce from '@editor/common/utils/debounce'

const Header = ({ searchTerm, filterValue, setSearchTerm, setFilterValue }) => {
  const [isFilterOpened, setFilterVisibility] = useState(false)
  const [_searchTerm, _setSearchTerm] = useState(searchTerm)
  const filterBtnRef = useRef(null)

  const closeFilter = useCallback(() => {
    setFilterVisibility(false)
  }, [])

  const handleFilterButtonClick = useCallback(
    e => {
      e.stopPropagation()
      setFilterVisibility(!isFilterOpened)
    },
    [isFilterOpened]
  )

  const handleFilterChoose = useCallback(value => {
    closeFilter()
    setFilterValue(value)
  }, [])

  const debouncedSearch = useRef(debounce(setSearchTerm, 500)).current

  const handleSearchChange = useCallback(
    e => {
      const { value } = e.target
      _setSearchTerm(value)
      debouncedSearch(value)
    },
    [debouncedSearch]
  )

  const onRemoveIconClick = useCallback(() => {
    setSearchTerm('')
    _setSearchTerm('')
  }, [])

  return (
    <>
      <Styled.GlobalStyles />
      <Styled.Header>
        <Styled.Title>{translate('add_an_icon_label')}</Styled.Title>
        <Styled.SearchContainer>
          <Input
            value={_searchTerm}
            placeholder={translate('search_label')}
            iconName="search_tiny"
            borderColor="#C1C9E0"
            borderHoverColor="#8493BD"
            onChange={handleSearchChange}
            onRemoveIconClick={onRemoveIconClick}
          />

          <Styled.FilterButton
            className="filter-btn"
            ref={filterBtnRef}
            onClick={handleFilterButtonClick}
          >
            <FilterIcon />
            {filterValue !== 0 ? <Styled.SelectedSign /> : null}
          </Styled.FilterButton>
        </Styled.SearchContainer>
        <FlyOutMenu
          isOpen={isFilterOpened}
          onClose={closeFilter}
          desktopProps={{
            className: 'filter-flyout',
            alignment: 'right',
            withAnimation: false,
            anchorEl: filterBtnRef.current,
            backdropDisablingSelectors: ['.filter-btn']
          }}
        >
          <FiltersList
            filters={ICON_FILTER_OPTIONS}
            selected={filterValue}
            onChoose={handleFilterChoose}
            closeFilter={closeFilter}
          />
        </FlyOutMenu>
      </Styled.Header>
    </>
  )
}

export default memo(Header)
