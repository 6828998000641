import styled from 'styled-components'
import { secondary } from '@website/common/styles/colors'

export const Notification = styled.div`
  position: relative;
  margin-top: 30px;
  display: flex;

  svg {
    fill: ${secondary.SemiDark};
    flex-shrink: 0;
    margin-right: 5px;
  }

  & > span {
    position: absolute;
    left: 28px;
    top: 2px;
    font-size: 14px;
    color: ${secondary.SemiDark};

    & > span {
      font-weight: 600;
      margin-right: 5px;
    }
  }
`

export const PrimaryBtnText = styled.div`
  line-height: 0;
  visibility: ${({ isShown }) => (isShown ? 'visible' : 'hidden')};
`
