import { translate } from '@editor/common/utils/translations'

export const MODES = [
  {
    name: 'DARK',
    color: 'tertiaryDark',
    tooltipText: translate('dark_mode_label')
  },
  {
    name: 'LIGHT',
    color: 'tertiaryLight',
    tooltipText: translate('light_mode_label')
  },
  {
    name: 'EXTRALIGHT',
    color: 'tertiaryExtraLight',
    tooltipText: translate('extra_light_mode_label')
  }
]
