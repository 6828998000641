export const BACKGROUND_IMAGES = [
  {
    backgroundTopImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-11.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-11.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-11.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-11.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-11.0.0.png'
      }
    },
    backgroundBottomImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-12.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-12.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-12.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-12.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-12.0.0.png'
      }
    },
    backgroundThumbnail:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-bgthumbnail-1.0.0.jpg'
  },
  {
    backgroundTopImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-4.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-4.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-4.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-4.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-4.0.0.png'
      }
    },
    backgroundBottomImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-3.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-3.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-3.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-3.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-3.0.0.png'
      }
    },
    backgroundThumbnail:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-bgthumbnail-2.0.0.jpg'
  },
  {
    backgroundTopImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-5.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-5.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-5.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-5.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-5.0.0.png'
      }
    },
    backgroundBottomImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-6.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-6.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-6.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-6.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-6.0.0.png'
      }
    },
    backgroundThumbnail:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-bgthumbnail-3.0.0.jpg'
  },
  {
    backgroundTopImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-7.1.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-7.1.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-7.1.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-7.1.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-7.1.0.png'
      }
    },
    backgroundBottomImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-8.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-8.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-8.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-8.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-8.0.0.png'
      }
    },
    backgroundThumbnail:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-bgthumbnail-4.0.0.jpg'
  },
  {
    backgroundTopImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-9.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-9.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-9.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-9.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-9.0.0.png'
      }
    },
    backgroundBottomImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-10.1.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-10.1.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-10.1.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-10.1.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-10.1.0.png'
      }
    },
    backgroundThumbnail:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-bgthumbnail-5.0.0.jpg'
  },
  {
    backgroundTopImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-1.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-1.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-1.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-1.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-1.0.0.png'
      }
    },
    backgroundBottomImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-2.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-2.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-2.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-2.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-2.0.0.png'
      }
    },
    backgroundThumbnail:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-bgthumbnail-6.0.0.jpg'
  },
  {
    backgroundTopImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-13.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-13.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-13.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-13.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-13.0.0.png'
      }
    },
    backgroundBottomImage: {
      imageUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-14.0.0.png',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-14.0.0.png',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-14.0.0.png',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-14.0.0.png',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-14.0.0.png'
      }
    },
    backgroundThumbnail:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/rsvp6-bgthumbnail-7.0.0.jpg'
  }
]
