/* @flow */
import React, { useContext, memo } from 'react'
import S from './Feature11_2.styled'
import { validation } from './Feature11_2.consts'
import ActionGetter from './Actions'
import Image from '@website/common/components/Image'
import Mapper from '@website/common/components/Mapper'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import Show from '@website/common/components/Show/Show'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { DispatchContext, EditingContext } from '@contexts'
import ControlsWithImageCropper from '@website/common/components/Controlled/ControlsWithImageCropper'
import SingleButton from '@website/common/components/SingleButton'
import { translate } from '@editor/common/utils/translations'

const Feature11_2 = memo(props => {
  const {
    data: {
      title,
      titleAlignment,
      titleTextGradient,
      paragraph,
      paragraphAlignment,
      features,
      imageUrl,
      imgCropParams,
      buttonText,
      buttonUrl,
      imgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)

  return (
    <S.StyledContainer isEditing={isEditing}>
      <S.WmCustomContainer>
        <Show when={[title, paragraph, features.length > 0, buttonText]}>
          <S.FeatureContent>
            <S.ContentWrapper>
              <Show when={[title]}>
                <EditableContent
                  text={title}
                  alignment={titleAlignment}
                  as={S.Title}
                  isTextGradient={titleTextGradient}
                  isGradientControlHidden={false}
                  maxCount={validation.headingMaxChar}
                  onChange={Actions.changeTitle}
                  className="WM_GLOBAL_heading42"
                  data-text={title}
                  changeAlignment={Actions.changeTitleAlignment}
                  changeTextGradientStatus={Actions.changeTitleGradientStatus}
                />
              </Show>
              <Show when={[paragraph]}>
                <EditableContent
                  text={paragraph}
                  alignment={paragraphAlignment}
                  as={S.Paragraph}
                  maxCount={validation.paragraphMaxChar}
                  onChange={Actions.changeParagraph}
                  className="WM_GLOBAL_paragraph18"
                  changeAlignment={Actions.changeParagraphAlignment}
                />
              </Show>
              <Show when={[features.length > 0]}>
                <S.List>
                  <Mapper
                    data={features}
                    render={(feature: string, idx: number) => (
                      <Show when={[feature.paragraph]}>
                        <ControlsWithModalOpener
                          actions={Actions.featureActions(idx, features.length)}
                          key={idx}
                        >
                          <S.ListItemWrap key={idx}>
                            <S.IconWrap>
                              <ControlsWithModalOpener
                                actions={Actions.iconActions({
                                  idx,
                                  showRemove: false
                                })}
                              >
                                <S.Icon name={feature.icon} size="large" />
                              </ControlsWithModalOpener>
                            </S.IconWrap>
                            <EditableContent
                              toPropogate={false}
                              text={feature.paragraph}
                              as={S.ListItem}
                              maxCount={validation.checkmarkMaxChar}
                              onChange={(newContent: string) =>
                                Actions.changeListItem(newContent, idx)
                              }
                              className="WM_GLOBAL_paragraph18"
                            />
                          </S.ListItemWrap>
                        </ControlsWithModalOpener>
                      </Show>
                    )}
                  />
                  <AddButton
                    onAdd={Actions.addNewItem}
                    toShow={features.length < validation.features.max}
                    style={{
                      marginTop: '20px'
                    }}
                  >
                    <Icon name="glyph-add" className="icon" size="normal" />
                    {translate('add_item_label')}
                  </AddButton>
                </S.List>
              </Show>
              <SingleButton btnUrl={buttonUrl} btnText={buttonText} />
            </S.ContentWrapper>
          </S.FeatureContent>
        </Show>
        <S.ImageWrap>
          <S.ImageFigure>
            <ControlsWithImageCropper
              actions={Actions.imgActions(imageUrl)}
              style={{ width: '100%' }}
            >
              <Image
                as={S.Img}
                defaultImgSrc={imageUrl}
                sizes={imgDimensions}
                alt="Section img"
                asProps={{ imgCropParams }}
              />
            </ControlsWithImageCropper>
          </S.ImageFigure>
        </S.ImageWrap>
      </S.WmCustomContainer>
    </S.StyledContainer>
  )
})

export default Feature11_2
