import React, { memo } from 'react'
import { createPortal } from 'react-dom'
import LinkWrapper from '@website/common/components/LinkWrapper'

// replace regular a links with LinkWrapper React component
const ReplacedLinks = ({ containerEl }) => {
  if (!containerEl) return null

  const links = containerEl.querySelectorAll('.text-customization-link')

  return Array.prototype.map.call(links, link => {
    const parentEl = link.parentNode
    const spanEl = document.createElement('span')
    const url = link.getAttribute('href')
    const linkHTML = link.innerHTML

    parentEl.insertBefore(spanEl, link)
    parentEl.removeChild(link)

    return createPortal(
      <LinkWrapper url={url} className="text-customization-link-wrapper">
        <span dangerouslySetInnerHTML={{ __html: linkHTML }} />
      </LinkWrapper>,
      spanEl
    )
  })
}

export default memo(ReplacedLinks)
