/* @flow */

module.exports = {
  id: 'Header15',
  thumbnailRatio: 0.49,
  isUnique: true,
  label: 'Header15',
  category: 'header',
  colorMode: '',
  thumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header15-1.1.0.jpg',
  mobileThumbnail:
    'https://static.rfstat.com/renderforest/images/website_maker_images/components/thumbnails/header15m-1.1.0.jpg',
  metaData: {
    paragraph: {
      _type: 'paragraph',
      _min: 1,
      _max: 500,
      _value:
        'Let the professionalism leads you to the highest top of your goals.'
    },
    paragraphAlignment: 'center',
    backgroundImgUrl: {
      _type: 'backgroundImage',
      _dimensionsKey: 'backgroundImgDimensions',
      _optimizationSizes: [
        {
          _width: 400,
          _height: 730,
          _fit: 'cover'
        },
        {
          _width: 600,
          _height: 770,
          _fit: 'cover'
        },
        { _width: 1600 },
        { _width: 2200 }
      ],
      _min: 0,
      _max: 2048,
      _value:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg'
    },
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg'
    },
    contentImage: {
      imgUrl: {
        _type: 'image',
        _dimensionsKey: 'imgDimensions',
        _optimizationSizes: [
          { _width: 600 },
          { _width: 600 },
          { _width: 600 },
          { _width: 600 }
        ],
        _min: 0,
        _max: 2048,
        _value:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg'
      },
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg'
      }
    },
    socials: {
      _type: 'list',
      _min: 0,
      _max: 6,
      _value: [
        {
          icon: 'icon-facebook',
          socialUrl: {
            _type: 'link',
            _min: 0,
            _max: 2048,
            _value: 'https://www.facebook.com'
          }
        },
        {
          icon: 'icon-twitter',
          socialUrl: {
            _type: 'link',
            _min: 0,
            _max: 2048,
            _value: 'https://www.twitter.com'
          }
        },
        {
          icon: 'icon-linkedin',
          socialUrl: {
            _type: 'link',
            _min: 0,
            _max: 2048,
            _value: 'https://www.linkedin.com'
          }
        },
        {
          icon: 'icon-instagram',
          socialUrl: {
            _type: 'link',
            _min: 0,
            _max: 2048,
            _value: 'https://www.instagram.com'
          }
        }
      ]
    },
    logoSrc: {
      _type: 'logo',
      _dimensionsKey: 'logoDimensions',
      _optimizationSizes: [
        { _width: 500 },
        { _width: 500 },
        { _width: 500 },
        { _width: 500 }
      ],
      _min: 0,
      _max: 2048,
      _value: ''
    },
    logoColor: 'default',
    logoDimensions: {},
    btnText: {
      _type: 'buttonLabel',
      _min: 0,
      _max: 19,
      _value: 'GET STARTED'
    },
    btnUrl: {
      _type: 'buttonUrl',
      _min: 0,
      _max: 2048,
      _value: 'http://builder.renderforestsites.com'
    },
    type: 'tertiary'
  },
  defaultProps: {
    paragraph:
      'Let the professionalism leads you to the highest top of your goals.',
    paragraphAlignment: 'center',
    backgroundImgUrl:
      'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg',
    backgroundImgDimensions: {
      small:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg',
      medium:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg',
      large:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg',
      xlarge:
        'https://static.rfstat.com/renderforest/images/website_maker_images/components/component-images/header15-1.0.0.jpg'
    },
    contentImage: {
      imgUrl:
        'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg',
      imgDimensions: {
        small:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg',
        medium:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg',
        large:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg',
        xlarge:
          'https://static.rfstat.com/renderforest/images/website_maker_images/rf__logo.svg'
      }
    },
    socials: [
      {
        icon: 'icon-facebook',
        socialUrl: 'https://www.facebook.com'
      },
      {
        icon: 'icon-twitter',
        socialUrl: 'https://www.twitter.com'
      },
      {
        icon: 'icon-linkedin',
        socialUrl: 'https://www.linkedin.com'
      },
      {
        icon: 'icon-instagram',
        socialUrl: 'https://www.instagram.com'
      }
    ],
    logoSrc: '',
    logoColor: 'default',
    logoDimensions: {},
    btnUrl: 'http://builder.renderforestsites.com',
    btnText: 'GET STARTED',
    type: 'tertiary'
  }
}
