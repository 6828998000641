import styled, { css } from 'styled-components'

export const IntentElements = styled.div`
  display: flex;
  align-items: center;
  cursor: default;

  & > div:not(:first-child) {
    margin-left: 5px;
  }
`

export const IntentElement = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  ${({ data }) => css`
    color: ${data.color};
    background-color: ${data.bgColor};

    &:hover {
      color: white;
      background-color: ${data.bgHoverColor};
    }
  `}
`

export const NA = styled.p`
  color: #545f7e;
  font-size: 14px;
`
