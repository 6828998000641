import styled, { css } from 'styled-components'
import SemrushDependedPopup from '@editor/common/components/SemrushDependedPopup'
import { white } from '@website/common/styles/colors'

const getDisabledProps = ({ disabled }) =>
  disabled
    ? css`
        opacity: 0.5;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
      `
    : ``

export const SettingsPopup = styled(SemrushDependedPopup)`
  z-index: 50;
  & > div {
    overflow: initial;
    padding: 0;
    max-height: 800px;
    max-width: 800px;
    & > div {
      overflow: initial;
      padding: 0;
    }
  }

  .settings-loader,
  .loader {
    border-radius: 10px;
  }

  @media only screen and (max-width: 768px) {
    height: 100%;
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
      & > div {
        overflow: auto;
        padding: 0;
      }
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 889px) {
    & > div {
      width: 90%;
    }
  }

  @media only screen and (min-width: 769px) and (max-height: 850px) {
    & > div {
      max-height: 90%;
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
`

export const SettingsContainer = styled.div`
  position: relative;
  padding-top: 30px;
  width: 800px;
  height: 800px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  @media only screen and (min-width: 769px) and (max-width: 889px) {
    width: 100%;
  }
  @media only screen and (min-width: 769px) and (max-height: 850px) {
    height: 100%;
  }
`

export const Title = styled.p`
  color: #545f7e;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 15px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: auto;
  position: relative;

  .settings-customization-window {
    width: 100%;
    background: ${white};
  }
`

export const TabsWrapper = styled.div`
  padding: 0 20px;
  border-bottom: 1px solid #c1c9e0;
  ul {
    flex-wrap: nowrap;
    li {
      div:nth-child(1) {
        font-size: 14px;
        margin: 10px 10px 5px;
      }
    }
    li:not(:first-child) {
      margin-left: 15px;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 0;
    ul {
      justify-content: flex-start;
      li:not(:first-child) {
        margin-left: 10px;
      }
    }
  }
  @media only screen and (max-width: 374px) {
    ul {
      li:not(:first-child) {
        margin-left: 5px;
      }
    }
  }
`

export const Button = styled.button`
  ${getDisabledProps};
  padding: 10px 30px;
  margin-right: 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;
`
