import styled, { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  .languages-flyout_container {
    box-shadow: 0 3px 12px #387dff33;
    border-radius: 6px;
  }
  .languages-flyout > div {
      display: flex;
      flex-direction: column;
  }
`

export const Flag = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #dee3f0;
`
