/* @flow */
import styled from 'styled-components'
import Container from '@website/common/components/Container'
import { getTextStyleByAlignment } from '@website/common/utils'

const StyledContainer = styled(Container)`
  padding: ${({ isEditing }) => (isEditing ? '140px 0 0 0' : '0')};
  flex-wrap: wrap;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
  .LazyLoad {
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    height: initial;
    padding-top: 56.25%;
  }
`

const PortItem = styled.article`
  width: 45%;
  flex-grow: 1;
  position: relative;
  background: ${({ theme }) => theme.palette.primaryDark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  &.item1 {
    box-sizing: border-box;
    overflow: hidden;
    &:before {
      content: '';
      padding-top: 56.25%;
      position: relative;
    }
  }
`

const ContentWrapper = styled.div`
  padding: 0 30px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > .control-container {
    width: 100%;
    margin-bottom: 20px;
    p,
    h1 {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 450px) {
    padding-right: 20px;
    padding-left: 20px;
  }
`

const BackgroundTitle = styled.div`
  position: absolute;
  font-weight: 700;
  font-size: 200px;
  width: 100%;
  color: #fff;
  opacity: 0.05;
  word-break: keep-all;
  @media only screen and (max-width: 1300px) {
    font-size: 150px;
  }
  @media only screen and (max-width: 1024px) {
    font-size: 100px;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 100px;
  }
  &.bg-title-1 {
    top: -70px;
    right: -50%;
    @media only screen and (max-width: 1024px) {
      top: -50px;
    }
  }
  &.bg-title-2 {
    bottom: -60px;
    left: -30%;
    @media only screen and (max-width: 1024px) {
      bottom: -40px;
    }
  }
`

const Title = styled.h1`
  ${getTextStyleByAlignment};
  width: 100%;
  color: ${({ theme }) => theme.palette.accentWhite};
`

const Paragraph = styled.p`
  ${getTextStyleByAlignment};
  width: 100%;
  font-weight: 700;
  color: ${({ theme }) => theme.palette.accentWhite};
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

export default {
  StyledContainer,
  ContentWrapper,
  BackgroundTitle,
  PortItem,
  Title,
  Paragraph,
  Img
}
