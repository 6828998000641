/* @flow */
import styled, { css } from 'styled-components'
import THEME from './PrivacyPolicy1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment,
  hexToRgb
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'

const stylesLineAlignment = {
  default: '',
  left: css`
    margin-right: auto;
  `,
  center: css`
    margin-right: auto;
    margin-left: auto;
  `,
  right: css`
    margin-left: auto;
  `
}

const getLineStyleByAlignment = ({ alignment }) =>
  stylesLineAlignment[alignment]

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  position: relative;
  overflow: hidden;
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1024px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 20px;
`

const TitleLine = styled.span`
  height: 3px;
  text-align: center;
  width: 150px;
  border-radius: 2px;
  background-color: ${getStyleForProp('line')};
  ${getLineStyleByAlignment}
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment}
  margin-bottom: 20px;
  ${getTextStyleByAlignment};
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px auto;
  max-width: 960px;
  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  > :last-child {
    margin-bottom: 0;
  }
  .control-container {
    margin-bottom: 20px;
    h1,
    p {
      margin: 0;
      padding: 0;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const TextItemLine = styled.span`
  width: 100%;
  min-height: 2px;
  display: block;
  margin-top: 20px;
  background-image: ${({ theme }) =>
    theme.colorMode === 'DARK'
      ? `linear-gradient( 90deg, transparent 5%, rgba(${hexToRgb(
          theme.palette.accentWhite
        )}, 0.30) 50%, transparent 95% )`
      : `linear-gradient( 90deg, transparent 5%, rgba(${hexToRgb(
          theme.palette.tertiaryDark
        )}, 0.30) 50%, transparent 95% )`};
`

const TextItem = styled.div`
  margin-bottom: 20px;
  text-align: left;
  > :first-child {
    margin-top: 0;
  }
  .control-container {
    margin-bottom: 20px;
    h2,
    p {
      margin: 0;
      padding: 0;
    }
  }
`

const TextItemWrap = styled.article`
  max-width: 960px;
  width: 100%;
  box-sizing: border-box;
  word-break: break-word;
  margin: 0 auto;
  &:last-child {
    padding-bottom: 0;
    ${TextItemLine} {
      display: none;
    }
  }
  &:last-child {
    ${TextItem} {
      margin-bottom: 0;
    }
  }
  @media only screen and (min-width: 769px) {
    &:nth-last-child(2) {
      padding-bottom: 0;
    }
  }
`

const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  ${getTextStyleByAlignment}
  margin-bottom: 0;
`

const BlockParagraph = styled.p`
  margin-top: 20px;
  color: ${getStyleForProp('blockParagraph')};
  ${getTextStyleByAlignment}
  line-height: 1.4;
`

export default {
  StyledContainer,
  WmCustomContainer,
  Title,
  TitleLine,
  Paragraph,
  Content,
  TextItemLine,
  ContentWrapper,
  TextItemWrap,
  BlockParagraph,
  BlockTitle,
  TextItem
}
