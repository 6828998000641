/* @flow */
import React, { useContext, useCallback, useMemo, memo } from 'react'
import S from './Feature27.styled'
import { validation } from './Feature27.consts'
import ActionMaker from './Actions'
import Icon from '@website/common/components/Icon'
import Mapper from '@website/common/components/Mapper'
import Show from '@website/common/components/Show/Show'
import AddButton from '@website/common/components/AddButton'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { translate } from '@editor/common/utils/translations'

const Feature27 = memo(props => {
  const {
    data: {
      blocks,
      backgroundImgUrl,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props

  const dispatcher = useContext(DispatchContext)
  const Actions = ActionMaker(dispatcher)
  const { isEditing } = useContext(EditingContext)

  const nonEmptyBlocks = useMemo(
    () => blocks.filter(block => block.title || block.paragraph),
    [blocks]
  )

  const checkBlocksCount = useCallback(
    count => {
      return (
        (isEditing && blocks.length % count === 1) ||
        (!isEditing && nonEmptyBlocks.length % count === 1)
      )
    },
    [blocks, nonEmptyBlocks]
  )

  return (
    <S.StyledContainer
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
      isEditing={isEditing}
    >
      <S.WmCustomContainer>
        <S.BlocksWrapper
          isSevenBlock={checkBlocksCount(6)}
          isSixBlock={checkBlocksCount(5)}
          isFiveBlock={checkBlocksCount(4)}
          isFourBlock={checkBlocksCount(3)}
        >
          <Mapper
            data={blocks}
            render={(block: string, idx: number) => (
              <Show when={[block.title, block.paragraph]}>
                <S.Block key={idx}>
                  <ControlsWithModalOpener
                    actions={Actions.blockActions({
                      idx,
                      showRemove: blocks.length > validation.blocks.min
                    })}
                    style={{ height: '100%', width: '100%' }}
                  >
                    <S.BlockItemWrap>
                      <S.IconContainer>
                        <ControlsWithModalOpener
                          actions={Actions.getIconActions({
                            idx,
                            showRemove: false
                          })}
                          style={{ height: 'auto' }}
                        >
                          <S.IconWrap isEditing={isEditing}>
                            <Icon name={block.iconName} />
                          </S.IconWrap>
                        </ControlsWithModalOpener>
                      </S.IconContainer>
                      <S.BlockWrap className='block-wrap'>
                        <EditableContent
                          as={S.BlockTitle}
                          text={block.title}
                          isEditing={isEditing}
                          required={blocks.length === 2}
                          maxCount={validation.blockTitleMaxChar}
                          onChange={(newBlockTitle: string) =>
                            Actions.changeBlockTitle(newBlockTitle, idx)
                          }
                          className="WM_GLOBAL_heading32"
                        />
                        <EditableContent
                          as={S.BlockParagraph}
                          text={block.paragraph}
                          isEditing={isEditing}
                          maxCount={validation.blockParagraphMaxChar}
                          onChange={(newBlockParagraph: string) =>
                            Actions.changeBlockParagraph(newBlockParagraph, idx)
                          }
                          className="WM_GLOBAL_paragraph18"
                        />
                      </S.BlockWrap>
                    </S.BlockItemWrap>
                  </ControlsWithModalOpener>
                </S.Block>
              </Show>
            )}
          />
        </S.BlocksWrapper>
      </S.WmCustomContainer>
      <AddButton
        onAdd={Actions.addItem}
        toShow={blocks.length < validation.blocks.max}
        style={{
          marginTop: '2rem'
        }}
      >
        <Icon name="glyph-add" className="icon" size="normal" />
        {translate('add_item_label')}
      </AddButton>
    </S.StyledContainer>
  )
})

export default Feature27
