/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_BUTTON_TEXT':
        draft.buttonText = action.value
        break
      case 'CHANGE_PLACEHOLDER_TEXT':
        const { key, value } = action.value
        if (draft.placeholders) {
          draft.placeholders[key] = value
        } else {
          draft.placeholders = { [key]: value }
        }
        break
      case 'CHANGE_IMAGE':
        const { url, dimensions } = action.value
        draft.imageUrl = url
        draft.imgDimensions = dimensions
        draft.imgCropParams = {}
        break
      case 'SET_IMG_CROP_PARAMS':
        const { imgCropParams } = action.value
        draft.imgCropParams = imgCropParams
        break
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE': {
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      }
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_PLACEHOLDER_TEXT': {
        const { key, value } = action.value
        if (draft.placeholders) {
          draft.placeholders[key] = value
        } else {
          draft.placeholders = { [key]: value }
        }
        break
      }
    }
  })
}
