export const KEY_CODES = {
  ESC: 27,
  CTRL: 17,
  CMD: 91, //cmd for mac
  Z: 90,
  SHIFT: 16,
  U: 85,
  LEFT: 37,
  RIGHT: 39,
  ENTER: 13,
  SPACE: 32
}

//   --- used in template.js ---
export const RF_BRANDED_FOOTER = {
  isUnique: true,
  colorMode: '',
  id: 'RenderforestFooter',
  label: 'RenderforestFooter'
}

export const projectContainerClassName = 'rf-website-editor'

export const FONT_INFO = {
  thin: 100,
  extralight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  extrablack: 900,
  italic: 'i'
}

export const ORIENTATION = {
  LEFT: 'LEFT',
  RIGHT: 'RIGHT'
}

// Used in exporter / template.js  file
export const BUILT_ICONS_URL =
  'https://static.rfstat.com/renderforest/static/icons-js/builtIcons_4.js'

export const CURRENCIES = {
  AUD: '$',
  BRL: 'R$',
  CAD: 'C$',
  CNY: '¥',
  CZK: 'Kč',
  DKK: 'kr',
  EUR: '€',
  HKD: 'HK$',
  HUF: 'Ft',
  ILS: '₪',
  JPY: '¥',
  MYR: 'RM',
  MXN: 'MXN $',
  TWD: 'NT$',
  NZD: 'NZ$',
  NOK: 'kr',
  PHP: '₱',
  PLN: 'Zł',
  GBP: '£',
  RUB: '₽',
  SGD: 'SG$',
  SEK: 'kr',
  CHF: '₣',
  THB: '฿',
  USD: '$'
}
