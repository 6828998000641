// @flow

export const isInternalUrl = (url: string = ''): boolean => url.startsWith('/')

export const removeLastSlash = (routeUrl: string): string => {
  if (!routeUrl) {
    return ''
  }
  const lastSymbol = routeUrl.substr(routeUrl.length - 1)
  if (lastSymbol === '/') {
    return routeUrl.substr(0, routeUrl.length - 1)
  }
  return routeUrl
}

// temporary functionality
// this function will add a trailing slash to the end of page, depending on amazon s3 needs
export const addTrailingSlash = (url: string = '') => {
  const hashSymbolIndex = url.indexOf('#')
  const hasSection = hashSymbolIndex !== -1

  const page = hasSection ? url.substr(0, hashSymbolIndex) : url
  const section = hasSection ? url.substr(hashSymbolIndex) : ''

  if (page === '/') {
    return url
  }

  if (hasSection) {
    return `${page}/${section}`
  }

  return `${url}/`
}

export const getUrlWithSearch = (url: string = '', search: string): string => {
  if (!search) {
    return url
  }

  const hashSymbolIndex = url.indexOf('#')
  if (hashSymbolIndex !== -1) {
    return url.substr(0, hashSymbolIndex) + search + url.substr(hashSymbolIndex)
  }
  return `${url}${search}`
}

export const getFormattedUrl = (url: string = '', isEditing: boolean) => {
  if (!isEditing) {
    return url
  }

  if (!url) {
    return ''
  }

  if (url === '/') {
    return `/home`
  }

  if (url.startsWith('/#')) {
    return `/home/${url.substr(1)}`
  }

  return url
}
