/* @flow */
import { useContext, useEffect } from 'react'
import { isOpenedWithHash } from '@editor/common/utils'
import { connect } from 'react-redux'
import { MediaLibContext } from '@contexts'
import { getMediaType } from '@website/common/components/Controlled/ControlModals/MediaChangeModal/utils'
import { triggerApiError } from '@redux/actions/editor'

const mapDispatchToProps = {
  triggerApiError
}

//TODO: convert to hook
const MediaLib = ({ triggerApiError }) => {
  const { medialibProps, setMediaLibProps } = useContext(MediaLibContext)
  const { action = {}, mode, isMediaLibVisible } = medialibProps

  const openMediaLib = () =>
    setMediaLibProps({ ...medialibProps, isMediaLibVisible: true })

  const closeMediaLib = () =>
    setMediaLibProps({ ...medialibProps, isMediaLibVisible: false })

  const getFileLink = file => {
    if (file.stockFootageId) {
      return file.filePath
    }
    if (file.full) {
      return file.full
    }
    const postFix =
      file[0].filePath.indexOf('unsplash') !== -1 ? '&w=2000&fit=max' : ''
    return `${file[0].filePath}${postFix}`
  }
  const insertMedia = file => {
    if (!file) return closeMediaLib()
    return action.handler(getFileLink(file)), closeMediaLib()
  }

  const handleApiError = err => {
    if (isOpenedWithHash()) {
      triggerApiError(err)
    }
  }

  useEffect(() => {
    if (isMediaLibVisible) {
      window.openMediaLib &&
        window.openMediaLib({
          isVisible: true,
          lang: 'en',
          mode: mode || 'default',
          fileType: getMediaType(action.type),
          excludeFileFormats: ['image/vnd.microsoft'],
          onClose: closeMediaLib,
          setMediaLibVisible: openMediaLib,
          maxFileImportCount: 1,
          stockAllowed: !isOpenedWithHash(),
          onChoose: insertMedia,
          errorHandler: handleApiError
        })
    } else {
      window.closeMediaLib && window.closeMediaLib()
    }
  }, [isMediaLibVisible])

  return null
}

export default connect(null, mapDispatchToProps)(MediaLib)
