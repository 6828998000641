import { patchMetaSettingsAPI, renameSiteAPI } from '@api'
import { getSiteId, selectMetaSettings } from '@selectors'
import {
  setSettingsLoadingStatus,
  setSettingsSuccessMessage,
  setSiteName,
  triggerApiError,
  setHasChangesAfterPublish
} from '@actions/editor'
import { SUCCESS_SAVED_MESSAGE } from '@editor/conf/consts'
import { translate } from '@editor/common/utils/translations'

const SUCCESS_CONNECTED_MESSAGE = translate('succesfully_connected_message')
const SUCCESS_DISCONNECTED_MESSAGE = translate(
  'succesfully_disconnected_message'
)

const setMetaLoadingStatus = isLoading => ({
  type: 'SET_META_LOADING_STATUS',
  value: isLoading
})

export const setMetaSettings = settings => ({
  type: 'SET_META_SETTINGS',
  value: settings
})

const metaSettingsAction = (settings, dispatch, getState) => {
  const state = getState()
  const siteId = getSiteId(state)

  const metaSettings = selectMetaSettings(state)

  const _metaSettings = {
    ...metaSettings,
    ...settings
  }

  dispatch(setMetaLoadingStatus(true))

  return patchMetaSettingsAPI(siteId, _metaSettings).then(
    ({ data: { meta, hasChangesAfterPublish } }) => {
      dispatch(setMetaSettings(meta))
      dispatch(setHasChangesAfterPublish(hasChangesAfterPublish))
      dispatch(setMetaLoadingStatus(false))
    }
  )
}

export const changeGeneralSettings =
  (settings, hasSettingsChanges, websiteName, hasWebsiteNameChanges) =>
  (dispatch, getState) => {
    const state = getState()
    const siteId = getSiteId(state)

    dispatch(setSettingsLoadingStatus(true))

    const changeSettingsPromise =
      hasSettingsChanges && metaSettingsAction(settings, dispatch, getState)

    const renameSitePromise =
      hasWebsiteNameChanges &&
      renameSiteAPI(siteId, websiteName).then(() => {
        dispatch(setSiteName(websiteName))
      })

    Promise.all([changeSettingsPromise, renameSitePromise])
      .then(() => {
        dispatch(setSettingsLoadingStatus(false))
        dispatch(setSettingsSuccessMessage(SUCCESS_SAVED_MESSAGE))
        setTimeout(() => dispatch(setSettingsSuccessMessage('')), 5000)
      })
      .catch(e => dispatch(triggerApiError(e)))
  }

const setMarketingIntegrationSuccessMessage = (key, message) => ({
  type: `SET_${key}_SUCCESS_MESSAGE`,
  value: message
})

const connectMarketingIntegration =
  (data, key, isConnecting = true, successMessage) =>
  (dispatch, getState) => {
    const _successMessage =
      isConnecting && successMessage
        ? successMessage
        : isConnecting
        ? SUCCESS_CONNECTED_MESSAGE
        : SUCCESS_DISCONNECTED_MESSAGE

    dispatch(setSettingsLoadingStatus(true))

    metaSettingsAction(data, dispatch, getState)
      .then(() => {
        dispatch(setSettingsLoadingStatus(false))
        dispatch(setMarketingIntegrationSuccessMessage(key, _successMessage))
        setTimeout(
          () => dispatch(setMarketingIntegrationSuccessMessage(key, '')),
          5000
        )
      })
      .catch(e => dispatch(triggerApiError(e)))
  }

export const setGoogleAnalyticsId =
  (googleAnalyticsId, isConnecting) => dispatch =>
    dispatch(
      connectMarketingIntegration(
        { googleAnalyticsId },
        'GOOGLE_ANALYTICS',
        isConnecting
      )
    )

export const setGoogleTagManagerId =
  (googleTagManagerId, isConnecting) => dispatch =>
    dispatch(
      connectMarketingIntegration(
        { googleTagManagerId },
        'GOOGLE_TAG_MANAGER',
        isConnecting
      )
    )

export const setGoogleAdsenseId = (googleAdSenseId, isConnecting) => dispatch =>
  dispatch(
    connectMarketingIntegration(
      { googleAdSenseId },
      'GOOGLE_ADSENSE',
      isConnecting
    )
  )

export const setAuthDigitalSellersContent =
  authDigitalSellersContent => dispatch =>
    dispatch(
      connectMarketingIntegration(
        { authDigitalSellersContent },
        'AUTH_DIGITAL_SELLERS',
        true,
        SUCCESS_SAVED_MESSAGE
      )
    )

export const setFacebookPixelCode =
  (facebookPixelCode, isConnecting) => dispatch =>
    dispatch(
      connectMarketingIntegration(
        { facebookPixelCode },
        'FACEBOOK_PIXEL',
        isConnecting
      )
    )

export const setYandexMetricaId = (yandexMetricaId, isConnecting) => dispatch =>
  dispatch(
    connectMarketingIntegration(
      { yandexMetricaId },
      'YANDEX_METRICA',
      isConnecting
    )
  )
