// @flow

import React, { memo, useState, useCallback } from 'react'
import { translate } from '@editor/common/utils/translations'

import ValueContainer from './ValueContainer'

import * as Styled from './styled'

const PagesDropdown = () => {
  const [isOpen, setOpenedState] = useState(false)

  const toggle = useCallback(() => {
    setOpenedState(!isOpen)
  }, [isOpen])

  const closeDropdown = useCallback(() => {
    setOpenedState(false)
  }, [])

  return (
    <Styled.DropdownContainer>
      <Styled.Label>{translate('pages_label')}</Styled.Label>
      <ValueContainer isOpen={isOpen} onClick={toggle} close={closeDropdown} />
    </Styled.DropdownContainer>
  )
}

export default memo(PagesDropdown)
