import produce from 'immer'

import { COMPONENTS_INITIAL_DATA } from '../consts'

export default (state = COMPONENTS_INITIAL_DATA, action) =>
  produce(state, draft => {
    const { value, type } = action
    switch (type) {
      case 'GET_COMPONENT_CATEGORIES_SUCCESS':
        draft.componentCategoriesInfo.isLoading = false
        draft.componentCategoriesInfo.data = value
        break
      case 'GET_COMPONENT_CATEGORIES_STARTED':
        draft.componentCategoriesInfo.isLoading = true
        break
      case 'SET_COMPONENTS_LIST_LOADING':
        draft.componentsList.isLoading = value
        break
      case 'SET_COMPONENTS_LIST':
        draft.componentsList.payload = value
        break
      case 'SET_COMPONENTS_DATA_LOADING':
        draft.componentsData.isLoading = value
        break
      case 'SET_COMPONENTS_DATA':
        draft.componentsData.payload = {
          ...draft.componentsData.payload,
          ...value
        }
        break
      default:
        return state
    }
  })
