/* @flow */
import styled from 'styled-components'
import THEME from './Email1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import WMContainer from '@website/common/components/WMContainer'
import Container from '@website/common/components/Container'
import { Button } from '../SubscribeForm/styled'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  & ${Button} {
    min-width: 80px;
    flex: none;
  }
`

const WmCustomContainer = styled(WMContainer)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  ${({ isEditing }) => (isEditing ? 'margin-top: 60px' : null)};
  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 650px) {
    form {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  > :first-child {
    margin-top: 0;
  }
`

const EmailContent = styled.div`
  flex-grow: 1;
  margin-right: 20px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  @media only screen and (max-width: 1200px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 100%;
  }
  .btns-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  > :first-child {
    padding-top: 0;
  }
  .control-container {
    width: 100%;
  }
`

const SubscribeFormWrap = styled.div`
  flex-grow: 1;
  margin: 30px auto 0 auto;
  position: relative;
  @media only screen and (max-width: 1200px) {
    max-width: 642px;
  }
  @media only screen and (max-width: 1200px) and (min-width: 651px) {
    transform: translateX(-21px);
  }
  @media only screen and (max-width: 1200px) {
    width: 100%;
  }
  form {
    display: flex;
    justify-content: center;
    input {
      min-width: 240px;
    }
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Description = styled.p`
  color: ${getStyleForProp('paragraph')};
  line-height: 1.4;
  padding-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

export default {
  StyledContainer,
  WmCustomContainer,
  SubscribeFormWrap,
  EmailContent,
  Title,
  Description
}
