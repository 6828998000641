// @flow
import React from 'react'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TMobileActiveCategoryProps } from './types'
import { ICONS } from '../../../TemplatesCategories/components/Category/consts'
import {
  selectTemplatesActiveCategoryInfo,
  selectTemplateSearchTerm
} from '@selectors'

const MobileActiveCategory = ({
  activeCategoryInfo,
  searchTerm
}: TMobileActiveCategoryProps) => {
  const {
    activeCategory: { id, name: activeCategoryName },
    activeSubcategory: { name: activeSubcategoryName }
  } = activeCategoryInfo

  const IconComp = ICONS[id]

  return !searchTerm ? (
    <Styled.MobileActiveCategory>
      <Styled.ActiveCategoryWrapper>
        {id !== 1 && IconComp ? <IconComp /> : null}
        <Styled.ActiveCategory>{activeCategoryName}</Styled.ActiveCategory>
      </Styled.ActiveCategoryWrapper>
      {activeSubcategoryName ? (
        <Styled.ActiveSubcategory>
          {activeSubcategoryName}
        </Styled.ActiveSubcategory>
      ) : null}
    </Styled.MobileActiveCategory>
  ) : null
}

const mapStateToProps = state => ({
  activeCategoryInfo: selectTemplatesActiveCategoryInfo(state),
  searchTerm: selectTemplateSearchTerm(state)
})

export default connect(mapStateToProps)(MobileActiveCategory)
