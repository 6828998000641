/* @flow */
import styled, { css } from 'styled-components'

import THEME from './Text20.theme'
import Container from '@website/common/components/Container'
import {
  styleProvider,
  getBackgroundStyles,
  getTextStyleByAlignment
} from '@website/common/utils'
import LibIcon from '@website/common/components/Icon'
import WMContainer from '@website/common/components/WMContainer'

export const getStyleForProp = styleProvider(THEME)

export const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  ${({ isEditing }) =>
    isEditing
      ? css`
          display: flex;
          justify-content: center;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-top: 150px;
        `
      : css`
          padding: 40px;
          @media only screen and (max-width: 457px) {
            padding: 40px 20px;
          }
        `}
`
export const WMCustomContainer = styled(WMContainer)`
  display: flex;
  justify-content: center;
  column-gap: 20px;
  margin: 0px auto;
  width: 100%;
  @media only screen and (max-width: 1024px) {
    padding: 0px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    row-gap: 24px;
  }
`

export const ItemWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  word-break: break-word;
  & > .control-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px;
    width: 100%;

    & > .control-container {
      width: 100%;
    }
  }
`

export const BlockTitle = styled.h2`
  color: ${getStyleForProp('blockTitle')};
  margin-bottom: 0;
  min-width: 150px;
  width: 100%;
  ${getTextStyleByAlignment};
`

export const IconWrapper = styled.div`
  user-select: none;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  margin-top: -6px;
  & > .control-container > .control-container {
    width: 100%;
    min-width: 100px;
  }
`

export const Circle = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${getStyleForProp('blockTitle')};
  & > div {
    width: 36px;
    height: 36px;
  }
`

export const Icon = styled(LibIcon).attrs(() => ({
  color: getStyleForProp('iconColor')
}))``
