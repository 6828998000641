// @flow

import React from 'react'
import { connect } from 'react-redux'
import OpenIcon from '@editor/common/assets/svgr-icons/arrow_filled_top.svg'

import { selectUserAvatar } from '@selectors'

import * as Styled from './styled'
import type { TUserProfileProps } from './types'

const mapStateToProps = state => ({
  userAvatar: selectUserAvatar(state)
})

const UserProfile = ({
  isOpen,
  userAvatar,
  onClick,
  handleRef
}: TUserProfileProps) => (
  <Styled.Container
    ref={handleRef}
    onClick={onClick}
    className="user-profile-container"
  >
    <Styled.Avatar $data={userAvatar} />
    <Styled.UserNameWrapper>
      <Styled.OpenIcon isOpen={isOpen}>
        <OpenIcon />
      </Styled.OpenIcon>
    </Styled.UserNameWrapper>
  </Styled.Container>
)

export default connect(mapStateToProps)(UserProfile)
