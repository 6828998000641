/* @flow */
import {
  cropableImageModalActionMaker,
  linkActionMaker,
  iconActionMaker
} from '@website/components/ActionMakers'
import {
  changeTitle,
  changeParagraph,
  changeBlockTitle,
  changeTitleAlignment,
  changeParagraphAlignment,
  addLink
} from '@website/components/CommonActions'

export default dispatch => {
  const imageActions = (idx: number, imageUrl: string) => [
    ...cropableImageModalActionMaker(dispatch)({
      keyForSizes: 'CTA12',
      showRemove: false,
      additionalArgs: { idx },
      imageUrl
    })
  ]

  const changeInfoTitle = (newTitle: string) => ({
    type: 'CHANGE_INFO_TITLE',
    value: newTitle
  })

  const _changeTitle = newTitle => {
    dispatch(changeTitle(newTitle))
  }

  const _changeParagraph = newParagraph => {
    dispatch(changeParagraph(newParagraph))
  }

  const _changeBlockTitle = (newTitle, idx) => {
    dispatch(changeBlockTitle(newTitle, idx))
  }

  const _changeInfoTitle = newTitle => {
    dispatch(changeInfoTitle(newTitle))
  }

  const _changeTitleAlignment = (alignment: string) => {
    dispatch(changeTitleAlignment(alignment))
  }

  const _changeParagraphAlignment = (alignment: string) => {
    dispatch(changeParagraphAlignment(alignment))
  }

  const changeInfoTitleAlignment = (alignment: string) => {
    dispatch({
      type: 'CHANGE_INFO_TITLE_ALIGNMENT',
      value: alignment
    })
  }

  const changeBlockTitleAlignment = (alignment: string, idx: number) => {
    dispatch({
      type: 'CHANGE_BLOCK_TITLE_ALIGNMENT',
      value: { alignment, idx }
    })
  }

  return (
    {
      changeTitle: _changeTitle,
      changeParagraph: _changeParagraph,
      changeBlockTitle: _changeBlockTitle,
      changeInfoTitle: _changeInfoTitle,
      changeBlockTitleAlignment,
      changeInfoTitleAlignment,
      changeTitleAlignment: _changeTitleAlignment,
      changeParagraphAlignment: _changeParagraphAlignment,
      imageActions,
      addLink: () => dispatch(addLink()),
      linkActions: linkActionMaker(dispatch),
      iconActions: idx => [
        ...iconActionMaker(dispatch)({
          idx,
          showRemove: false
        })
      ]
    }
  )
}
