/* @flow */
import produce from 'immer'

export default function reducer(
  state,
  action: any
) {
  return produce(state, draft => {
    switch (action.type) {
      case 'REMOVE_BACKGROUND_IMAGE':
        draft.backgroundImgUrl = ''
        draft.backgroundImgDimensions = {}
        break
      case 'CHANGE_BACKGROUND_IMAGE':
        const { url, dimensions } = action.value
        draft.backgroundImgUrl = url
        draft.backgroundImgDimensions = dimensions
        break
      case 'CHANGE_BG_OVERLAY_TRANSPARENCY':
        draft.bgOverlayTransparency = action.value
        break
      case 'CHANGE_TITLE':
        draft.title = action.value
        break
      case 'CHANGE_PARAGRAPH1':
        draft.paragraph1 = action.value
        break
      case 'CHANGE_PARAGRAPH2':
        draft.paragraph2 = action.value
        break
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH1_ALIGNMENT': {
        draft.paragraph1Alignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH2_ALIGNMENT': {
        draft.paragraph2Alignment = action.value
        break
      }
      case 'CHANGE_IMAGE':
        {
          const { blockIdx, idx, url, dimensions } = action.value
          draft.images[blockIdx][idx].imgUrl = url
          draft.images[blockIdx][idx].imgDimensions = dimensions
          draft.images[blockIdx][idx].imgCropParams = {}
        }
        break
      case 'SET_IMG_CROP_PARAMS':
        {
          const { imgCropParams, idx, blockIdx } = action.value
          draft.images[blockIdx][idx].imgCropParams = imgCropParams
        }
        break
    }
  })
}
