export const validation = {
  maxTitleChar: 120,
  maxParagraphChar: 500,
  maxQuestionChar: 90,
  maxAnswerChar: 500,
  faqs: {
    min: 2,
    max: 20,
    question: { required: true },
    answer: { required: true }
  }
}
