/* @flow */
import React, { useContext, useState, memo } from 'react'
import S from './Header39.styled'
import THEME from './Header39.theme'
import ActionGetter from './Actions'
import NavBar from '@website/common/components/NavBar'
import { DispatchContext, EditingContext } from '@contexts'
import { validation } from './Header39.consts'
import EditableContent from '@website/common/components/EditableContent'
import Show from '@website/common/components/Show/Show'

const Header39 = memo(props => {
  const {
    data: {
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      logoSrc,
      logoColor,
      backgroundImgUrl,
      logoWidth,
      logoDimensions,
      bgOverlayTransparency,
      backgroundImgDimensions
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const [navBarHeight, setNavBarHeight] = useState(0)
  return (
    <S.StyledContainer
      isEditing={isEditing}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <NavBar
        handleHeightChange={setNavBarHeight}
        theme={THEME}
        logoProps={{
          logoSrc,
          logoColor,
          logoWidth,
          logoSizes: logoDimensions
        }}
      />
      <Show when={[title, paragraph]}>
        <S.WMCustomContainer>
          <S.HeaderContent topOffset={navBarHeight}>
            <EditableContent
              text={title}
              alignment={titleAlignment}
              required={validation.title.required}
              maxCount={validation.headingMaxChar}
              className="WM_GLOBAL_headingLarge"
              as={S.Title}
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />
            <EditableContent
              text={paragraph}
              alignment={paragraphAlignment}
              as={S.Description}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.HeaderContent>
        </S.WMCustomContainer>
      </Show>
    </S.StyledContainer>
  )
})

Header39.contextType = DispatchContext

export default Header39
