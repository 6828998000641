/* @flow */
import styled from 'styled-components'
import WMContainer from '@website/common/components/WMContainer'
import THEME from './Header37.theme'
import { styleProvider, getTextStyleByAlignment } from '@website/common/utils'
import S from '@website/common/components/NavBar/NavBar.styled'

const getStyleForProp = styleProvider(THEME)

const Container = styled.div`
  word-break: break-word;
  padding: ${({ topOffset, isEditing }) =>
    isEditing ? '0' : `${topOffset}px 0 0 0`};
  background: ${getStyleForProp('background')};
  position: relative;
  & ${S.Container} {
    margin-bottom: 0;
  }
  .slick-slide > div {
    margin: 0 !important;
    height: 100% !important;
    .slick-list {
      height: 100% !important;
    }
  }
  .mySlider {
    overflow: hidden;
    width: 100vw;
  }
`

const WmCustomContainer = styled(WMContainer)`
  width: 100%;
  max-width: initial;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 !important;
  @media only screen and (max-width: 1270px) {
    flex-direction: column;
  }
  .socials-block {
    width: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    right: 10px;
    bottom: 30px;
    box-sizing: border-box;
    color: ${getStyleForProp('heading')};
    @media only screen and (max-width: 1270px) {
      width: 100%;
      position: initial;
      bottom: initial;
      right: initial;
      margin: 40px 0 0 0;
    }
  }
  .socials-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    fill: ${getStyleForProp('heading')};
    & > .control-container:nth-child(n + 4) {
      .styled-controls {
        right: 0;
        left: initial;
      }
    }
    @media only screen and (max-width: 1270px) {
      flex-direction: row;
      width: 100%;
    }
    .link {
      fill: inherit;
    }
  }
`

const HeaderContentWrapper = styled.div`
  width: 48%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  .buttons-block {
    margin-left: -15px;
    align-items: flex-start;
  }
  @media only screen and (max-width: 1270px) {
    width: 100%;
    padding: 80px 0 50px 0;
    .buttons-block {
      justify-content: center;
      margin: 0 auto;
    }
    .buttons-wrapper {
      justify-content: center;
      margin: 0 auto;
    }
  }
  @media only screen and (max-width: 1024px) {
    padding-bottom: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-top: 60px;
  }
`

const HeaderContent = styled.div`
  width: 100%;
  max-width: 590px;
  padding: ${({ isEditing }) =>
    isEditing ? '2vw 2.2vw 0 4vw' : '2vw 2.2vw 0 8vw'};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > .control-container {
    width: 100%;
    margin-bottom: 30px;
    & > p {
      margin-bottom: ${({ isEditing }) => isEditing && '0px'};
    }
    & > h2 {
      margin-top: ${({ isEditing }) => isEditing && '0px'};
    }
  }
  @media only screen and (min-width: 1600px) {
    max-width: 790px;
  }
  @media only screen and (max-width: 1430px) {
    padding: 2vw 7vw 0 5vw;
  }
  @media only screen and (max-width: 1270px) {
    max-width: 800px;
    padding: 0;
    margin: 0 auto;
    text-align: center;
    align-items: center;
  }
  @media only screen and (max-width: 1024px) {
    max-width: initial;
    padding-right: 40px;
    padding-left: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  > :first-child {
    padding-top: 0;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  margin-bottom: 0;
  width: 100%;
  ${getTextStyleByAlignment};
`

const SubTitle = styled.p`
  color: ${getStyleForProp('paragraph')};
  margin: 0 0 10px 0;
  opacity: 0.5;
  width: 100%;
  ${getTextStyleByAlignment};
`

const Paragraph = styled.h2`
  color: ${getStyleForProp('paragraph')};
  margin-top: 20px;
  width: 100%;
  ${getTextStyleByAlignment};
`

const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  box-sizing: border-box;
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 60%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
  }
  .lazy-figure {
    padding-top: 60%;
    height: initial;
  }
`

const SliderAndNewsContainer = styled.div`
  width: 48%;
  flex-grow: 1;
  @media only screen and (max-width: 1270px) {
    width: 100%;
  }
`

const NewsContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 1270px) {
    flex-direction: column;
  }
`

const NewsBlock = styled.div`
  width: 48%;
  flex-grow: 1;
  background-color: ${getStyleForProp('newsBackground')};
  color: white;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .control-container {
    width: 100%;
    margin-bottom: 30px;
    & > h2 {
      margin-bottom: ${({ isEditing }) => !isEditing && '0px'};
    }
  }
  @media only screen and (max-width: 1430px) {
    padding: 1.5vw;
  }
  @media only screen and (max-width: 1350px) {
    padding: 1vw;
  }
  @media only screen and (max-width: 1270px) {
    width: 100%;
    text-align: center;
    padding: 40px;
  }
  @media only screen and (max-width: 457px) {
    padding-right: 20px;
    padding-left: 20px;
  }
  .add-button-wrap {
    text-align: left !important;
    margin: 10px 0 0 0;
  }
  .link-wrapper {
    margin: 20px 0 0 0;
    .learn-more-link {
      color: ${({ theme }) => theme.palette.accentWhite};
      @media only screen and (max-width: 1270px) {
        margin: 0 auto;
      }
      svg {
        fill: ${({ theme }) => theme.palette.accentWhite};
      }
    }
  }
`

const SliderItemWrapper = styled.div`
  padding: ${({ isEditing }) => (isEditing ? '50px 0 0 0' : '0')};
  height: 100%;
  position: relative;
  box-sizing: border-box;
  .control-container {
    height: 100%;
    width: 100%;
  }
  .lazy-figure {
    padding-top: 60%;
    height: initial;
  }
`

const SliderIdx = styled.div`
  color: white;
  font-size: 42px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;
  .slide-count {
    font-size: 34px;
    opacity: 0.6;
  }
  @media only screen and (max-width: 767px) {
    font-size: 22px;
    .slide-count {
      font-size: 20px;
    }
  }
`

const NewsTitle = styled.h2`
  ${getTextStyleByAlignment};
  width: 100%;
`

const NewsParagraph = styled.p`
  ${getTextStyleByAlignment};
  width: 100%;
`

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Content = styled.div`
  display: flex;
`

const SocialWrap = styled.div`
  width: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 30px;
  box-sizing: border-box;
  color: ${getStyleForProp('heading')};
  @media only screen and (max-width: 1270px) {
    width: 100%;
    position: initial;
    bottom: initial;
    right: initial;
    margin: 40px 0 0 0;
  }
`

const SocialIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  & > .control-container:nth-child(n + 4) {
    .styled-controls {
      right: 0;
      left: initial;
    }
  }
  @media only screen and (max-width: 1270px) {
    flex-direction: row;
    width: 100%;
  }
  .link {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: inherit;
  }
`

const SliderActionButtons = styled.div`
  display: flex;
  &#slider-1 {
    visibility: hidden;
  }
`

const IconContainer = styled.button`
  min-width: 50px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.1s linear;
  padding: 0;
  border: none;
  background: transparent;
  opacity: 0.6;
  transition: all 0.1s linear;
  &:hover {
    opacity: 1;
  }
  &:focus {
    outline: none !important;
  }
  @media only screen and (max-width: 768px) {
    height: 50px;
    min-width: 45px;
    width: 45px;
  }
  @media only screen and (max-width: 767px) {
    min-width: 35px;
    width: 35px;
  }
  i {
    font-size: 58px;
    @media only screen and (max-width: 767px) {
      font-size: 36px;
    }
  }
`

const AddButtonWrap = styled.div`
  opacity: 0.6;
  transition: all 0.1s linear;
  &:hover {
    opacity: 1;
  }
`

const SliderArrowsInfo = styled.div`
  background: ${getStyleForProp('sliderActionsBackground')};
  width: 100%;
  height: 100px;
  padding: 0 30px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0;
  display: ${({ isHidden }) => (isHidden ? 'none' : 'flex')};
  justify-content: space-between;
  align-items: center;
  & > .add-button-wrap {
    margin-right: auto;
    margin-left: 20px;
    @media only screen and (max-width: 767px) {
      & > div {
        padding: 10px 20px !important;
      }
    }
  }
  @media only screen and (max-width: 767px) {
    height: 50px;
    padding: 0 20px 0 30px;
  }
`

const ImageWrap = styled.div`
  width: 48%;
  flex-grow: 1;
  font-size: 0;
  @media only screen and (max-width: 1270px) {
    width: 100%;
  }
`

const ImageFigure = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .lazy-figure {
    height: 100%;
  }
`

const Img = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

export default {
  WmCustomContainer,
  SliderWrapper,
  SliderItemWrapper,
  IconContainer,
  HeaderContentWrapper,
  HeaderContent,
  Content,
  SocialWrap,
  Img,
  SocialIcons,
  Container,
  Title,
  Paragraph,
  SliderIdx,
  SliderActionButtons,
  AddButtonWrap,
  SubTitle,
  SliderArrowsInfo,
  SliderAndNewsContainer,
  NewsContainer,
  NewsBlock,
  NewsTitle,
  NewsParagraph,
  ImageWrap,
  ImageFigure,
  Image,
  ControlContainer
}
