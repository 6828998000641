import React, { memo, useCallback } from 'react'

import * as Styled from './styled'
import ArrowIcon from '@editor/common/assets/svgr-icons/arrow_small_left.svg'

export const FiltersList = ({ filters, selected, onChoose, closeFilter }) => {
  const stopPropagation = useCallback(e => e.stopPropagation(), [])

  const handleClick = useCallback((e, value) => {
    stopPropagation(e)
    onChoose(value)
  }, [])

  return (
    <Styled.FiltersList>
      <Styled.FiltersListTitle>Set Filter</Styled.FiltersListTitle>
      <ArrowIcon className="arrow-icon" onClick={closeFilter} />
      {filters.map(({ label, value }) => (
        <Styled.FilterItem
          isActive={selected === value}
          onClick={e => handleClick(e, value)}
          onMouseDown={stopPropagation}
          onTouchStart={stopPropagation}
        >
          {label}
        </Styled.FilterItem>
      ))}
    </Styled.FiltersList>
  )
}

export default memo(FiltersList)
