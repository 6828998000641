// @flow

import React, { useEffect, useCallback, useState, memo, useMemo } from 'react'
import { createPortal } from 'react-dom'

import * as Styled from './styled'
import type { TSlideLeftProps } from './types'
import { useBackdropClick } from '@hooks'

const SlideLeft = ({
  isOpen,
  isPositionFixed = false,
  shouldCloseOnBackdropClick,
  className = '',
  children,
  onClose,
  backdropDisablingSelectors = []
}: TSlideLeftProps) => {
  const [isMounted, setMounted] = useState(false)
  const ref = useBackdropClick(onClose, backdropDisablingSelectors)

  useEffect(() => {
    if (isOpen) {
      setMounted(true)
    }
  }, [isOpen])

  const onAnimationEnd = useCallback(() => {
    if (!isOpen) {
      setMounted(false)
    }
  }, [isOpen])

  const slideLeftContent = useMemo(() => {
    const _className = isPositionFixed ? `${className}_content` : className
    const _ref = shouldCloseOnBackdropClick ? ref : null
    return (
      <Styled.SlideLeft
        ref={_ref}
        isOpen={isOpen}
        isPositionFixed={isPositionFixed}
        className={_className}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </Styled.SlideLeft>
    )
  }, [isPositionFixed, onAnimationEnd, children, isOpen, className])

  return isMounted ? (
    <>
      <Styled.GlobalStyles />
      {isPositionFixed
        ? createPortal(
            <Styled.SlideLeftContainer className={className}>
              {slideLeftContent}
            </Styled.SlideLeftContainer>,
            document.body
          )
        : slideLeftContent}
    </>
  ) : null
}

export default memo(SlideLeft)
