import React, {
  useState,
  useContext,
  useCallback,
  memo,
  useRef,
  useEffect
} from 'react'
import S from './Portfolio13.styled'
import { validation } from './Portfolio13.consts'
import ActionGetter from './Actions'
import AddButton from '@website/common/components/AddButton'
import Icon from '@website/common/components/Icon'
import ImageItem from './ImageItem'
import If from '@website/common/components/Conditional'
import Arrow from './ArrowLeft'
import ArrowRight from './ArrowRight'
import { ControlsWithModalOpener } from '@website/common/components/Controlled'
import { getOffsetXByMouseX, getOffsetYByMouseY } from '@website/common/utils'
import { DispatchContext, EditingContext } from '@contexts'
import EditableContent from '@website/common/components/EditableContent'
import { translate } from '@editor/common/utils/translations'
import PortfolioLightBox from '@website/common/components/LightBox/Lightbox'

const Portfolio13 = memo(props => {
  const {
    data: {
      gallery,
      title,
      paragraph,
      titleAlignment,
      paragraphAlignment,
      leftArrow,
      rightArrow,
      sixthItemParagraph,
      sixthItemParagraphAlignment,
      seventhItemParagraph,
      seventhItemParagraphAlignment,
      backgroundImgUrl,
      backgroundImgDimensions,
      bgOverlayTransparency,
      isAnimated
    }
  } = props
  const dispatcher = useContext(DispatchContext)
  const Actions = ActionGetter(dispatcher)
  const { isEditing } = useContext(EditingContext)
  const containerRef = useRef(null)

  const [lightBoxMode, setLightBoxMode] = useState(false)
  const [lightBoxActiveImgIndex, setLightBoxActiveImgIndex] = useState(0)

  const isMobile = typeof window !== 'undefined' && window.innerWidth < 1025

  const closePreviewMode = useCallback(() => setLightBoxMode(false), [])

  const fisrtRow = gallery[0]
  const secondRow = gallery[1]
  const combinedImages = secondRow ? fisrtRow.concat(secondRow) : fisrtRow

  const openPorfolioPreviewMode = (imgIndex: number, isEditing: boolean) => {
    if (!(isMobile && isEditing)) {
      setLightBoxMode(true), setLightBoxActiveImgIndex(imgIndex)
    }
  }

  const handleMouseMove = useCallback(
    event => {
      if ((!isEditing && !isAnimated) || isEditing || window.innerWidth <= 1024)
        return

      const targetElement = containerRef.current
      const pointerClientX = event.clientX
      const pointerClientY = event.clientY
      const { width, height } = targetElement.getBoundingClientRect()

      const img1 = targetElement.getElementsByClassName('image_1')[0]
      const img2 = targetElement.getElementsByClassName('image_2')[0]
      const img3 = targetElement.getElementsByClassName('image_3')[0]
      const img4 = targetElement.getElementsByClassName('image_4')[0]
      const img5 = targetElement.getElementsByClassName('image_5')[0]
      const img6 = targetElement.getElementsByClassName('image_6')[0]
      const img7 = targetElement.getElementsByClassName('image_7')[0]

      img1 &&
        (img1.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          2.1
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 2.1)}px)`)
      img2 &&
        (img2.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          1.4
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 1.9)}px)`)
      img3 &&
        (img3.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          1.7
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 2.2)}px)`)
      img4 &&
        (img4.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          1.4
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 2)}px)`)
      img5 &&
        (img5.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          0.7
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 1.8)}px)`)
      img6 &&
        (img6.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          1.5
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 2)}px)`)
      img7 &&
        (img7.style.transform = `translate(${getOffsetXByMouseX(
          pointerClientX,
          width,
          1.1
        )}px, ${getOffsetYByMouseY(pointerClientY, height, 1.7)}px)`)
    },
    [containerRef, isAnimated]
  )

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('mousemove', handleMouseMove)
    }
    return () => {
      containerRef.current.removeEventListener('mousemove', handleMouseMove)
    }
  }, [containerRef.current, handleMouseMove])

  return (
    <S.StyledContainer
      isEditing={isEditing}
      ref={containerRef}
      backgroundImgUrl={backgroundImgUrl}
      backgroundImgDimensions={backgroundImgDimensions}
      bgOverlayTransparency={bgOverlayTransparency}
    >
      <S.FirstRow>
        <S.FirstItemBox>
          <S.Headings>
            <S.TextSizeContainer isEditing={isEditing}>
              <EditableContent
                text={paragraph}
                isEditing={isEditing}
                alignment={paragraphAlignment}
                as={S.Paragraph}
                maxCount={validation.paragraphMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeParagraph}
                changeAlignment={Actions.changeParagraphAlignment}
              />
            </S.TextSizeContainer>
            <EditableContent
              isEditing={isEditing}
              text={title}
              alignment={titleAlignment}
              as={S.Title}
              required
              maxCount={validation.titleMaxChar}
              className="WM_GLOBAL_heading42"
              onChange={Actions.changeTitle}
              changeAlignment={Actions.changeTitleAlignment}
            />

            <If
              condition={leftArrow}
              then={() => (
                <ControlsWithModalOpener
                  actions={Actions.removeArrowAction('left')}
                  style={{
                    width: ' 100%',
                    height: '100%'
                  }}
                  className="arrow-container"
                >
                  <Arrow />
                </ControlsWithModalOpener>
              )}
              otherwise={() => (
                <AddButton
                  onAdd={Actions.addLeftArrow}
                  style={{
                    margin: '0px'
                  }}
                  type="icon"
                  large
                  toShow
                  className="add-arrow-btn"
                >
                  +
                </AddButton>
              )}
            />
          </S.Headings>
          <S.ImageText
            isEditing={isEditing}
            isParagraph={paragraph !== ''}
            isLeftArrow={leftArrow}
          >
            <ImageItem
              isEditing={isEditing}
              isAnimated={isAnimated}
              galleryRowIdx={0}
              className="image_1"
              alt="image_1"
              onClick={() => openPorfolioPreviewMode(0, isEditing)}
              imageActions={Actions.imageActions(0, 0, fisrtRow[0].imgUrl)}
              {...fisrtRow[0]}
            />
            <EditableContent
              text={paragraph}
              isEditing={isEditing}
              alignment={paragraphAlignment}
              as={S.MobileParagraph}
              maxCount={validation.paragraphMaxChar}
              className="WM_GLOBAL_paragraph18"
              onChange={Actions.changeParagraph}
              changeAlignment={Actions.changeParagraphAlignment}
            />
          </S.ImageText>
        </S.FirstItemBox>
        <S.ImageContainer>
          <ImageItem
            isEditing={isEditing}
            isAnimated={isAnimated}
            galleryRowIdx={0}
            className="image_2"
            alt="image_2"
            onClick={() => openPorfolioPreviewMode(1, isEditing)}
            imageActions={Actions.imageActions(1, 0, fisrtRow[1].imgUrl)}
            {...fisrtRow[1]}
          />
        </S.ImageContainer>
        <S.ImageContainer>
          <ImageItem
            isEditing={isEditing}
            isAnimated={isAnimated}
            galleryRowIdx={0}
            className="image_3"
            alt="image_3"
            onClick={() => openPorfolioPreviewMode(2, isEditing)}
            imageActions={Actions.imageActions(2, 0, fisrtRow[2].imgUrl)}
            {...fisrtRow[2]}
          />
        </S.ImageContainer>
      </S.FirstRow>
      <S.RowWrapper>
        {secondRow && (
          <S.SecondRow>
            <S.ImageContainer>
              <ImageItem
                isEditing={isEditing}
                isAnimated={isAnimated}
                galleryRowIdx={1}
                className="image_4"
                alt="image_4"
                onClick={() => openPorfolioPreviewMode(3, isEditing)}
                imageActions={Actions.imageActions(0, 1, secondRow[0].imgUrl)}
                {...secondRow[0]}
              />
            </S.ImageContainer>
            <S.ImageContainer>
              <ImageItem
                isEditing={isEditing}
                isAnimated={isAnimated}
                galleryRowIdx={1}
                className="image_5"
                alt="image_5"
                onClick={() => openPorfolioPreviewMode(4, isEditing)}
                imageActions={Actions.imageActions(1, 1, secondRow[1].imgUrl)}
                {...secondRow[1]}
              />
            </S.ImageContainer>
            <S.SixthItemBox>
              <S.ImageContainer isEditing={isEditing}>
                <ImageItem
                  isEditing={isEditing}
                  isAnimated={isAnimated}
                  galleryRowIdx={1}
                  className="image_6"
                  alt="image_6"
                  onClick={() => openPorfolioPreviewMode(5, isEditing)}
                  imageActions={Actions.imageActions(
                    2,
                    1,

                    secondRow[2].imgUrl
                  )}
                  {...secondRow[2]}
                />
              </S.ImageContainer>
              <If
                condition={rightArrow}
                then={() => (
                  <ControlsWithModalOpener
                    actions={Actions.removeArrowAction('right')}
                    style={{
                      width: ' 100%',
                      height: '100%'
                    }}
                    className="arrow-container"
                  >
                    <ArrowRight />
                  </ControlsWithModalOpener>
                )}
                otherwise={() => (
                  <AddButton
                    onAdd={Actions.addRightArrow}
                    style={{
                      margin: '0px'
                    }}
                    type="icon"
                    large
                    toShow
                    className="add-arrow-btn"
                  >
                    +
                  </AddButton>
                )}
              />
              <EditableContent
                text={sixthItemParagraph}
                isEditing={isEditing}
                alignment={sixthItemParagraphAlignment}
                as={S.ItemParagraph}
                maxCount={validation.paragraphRightMaxChar}
                className="WM_GLOBAL_paragraph18"
                onChange={Actions.changeSixthParagraph}
                changeAlignment={Actions.changeSixthParagraphAlignment}
              />
            </S.SixthItemBox>
            <S.SeventhItemBox>
              <S.ImageContainer>
                <ImageItem
                  isEditing={isEditing}
                  isAnimated={isAnimated}
                  galleryRowIdx={1}
                  className="image_7"
                  alt="image_7"
                  onClick={() => openPorfolioPreviewMode(6, isEditing)}
                  imageActions={Actions.imageActions(3, 1, secondRow[3].imgUrl)}
                  {...secondRow[3]}
                />
              </S.ImageContainer>
              <EditableContent
                text={seventhItemParagraph}
                isEditing={isEditing}
                alignment={seventhItemParagraphAlignment}
                as={S.SecondaryHeading}
                maxCount={validation.titleMaxChar}
                className="WM_GLOBAL_heading32"
                onChange={Actions.changeSeventhParagraph}
                changeAlignment={Actions.changeSeventhParagraphAlignment}
              />
            </S.SeventhItemBox>
          </S.SecondRow>
        )}
        <If
          condition={isEditing}
          then={() => (
            <S.AddButtonWrapper>
              <AddButton
                onAdd={Actions.addRow}
                toShow={gallery.length < 2}
                style={{
                  margin: '0 0.5rem'
                }}
              >
                <Icon name="glyph-add" className="icon" size="normal" />
                {translate('add_row_label')}
              </AddButton>
              <AddButton
                onAdd={Actions.removeRow}
                toShow={gallery.length > 1}
                style={{
                  margin: '0 0.5rem'
                }}
              >
                <Icon name="glyph-remove" className="icon" size="normal" />
                {translate('remove_row_label')}
              </AddButton>
            </S.AddButtonWrapper>
          )}
        />
      </S.RowWrapper>

      <If
        condition={lightBoxMode}
        then={() => (
          <PortfolioLightBox
            portfolioImages={combinedImages}
            closePreviewMode={closePreviewMode}
            lightBoxActiveImgIndex={lightBoxActiveImgIndex}
          />
        )}
      />
    </S.StyledContainer>
  )
})

export default Portfolio13
