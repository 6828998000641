import styled, { css } from 'styled-components'

import { primary, white } from '@website/common/styles/colors'
import Icon from '@website/common/components/Icon'

const getDisabledProps = ({ disabled }) =>
  disabled
    ? css`
        opacity: 0.6;
        box-shadow: none;
        cursor: default;
        pointer-events: none;
      `
    : ``

export const Container = styled.div`
  padding: 30px 40px;
  height: calc(100% - 65px);
  box-sizing: border-box;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    background-color: transparent;
    height: 7px;
    width: 5px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
  &::-webkit-scrollbar-thumb {
    background: #c2c9e0;
    border-radius: 3px;
  }

  @media only screen and (max-width: 768px) {
    padding: 30px 20px;
  }
`

export const Title = styled.div`
  color: #252e48;
  font-size: 16px;
  font-weight: 600;
`

export const InputContainer = styled.div`
  position: relative;
  border-bottom: 1px solid #dee3f0;
  padding-bottom: 20px;
  margin-bottom: 20px;
`

export const Description = styled.p`
  color: #545f7e;
  font-size: 14px;
  padding: 20px 0 5px;
  max-width: 600px;
`

export const InputWrapper = styled.div`
  position: relative;
  width: 600px;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .left-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  .remove-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &:hover {
      svg {
        fill: #545f7e;
      }
    }
  }
`

const getInputStyles = ({
  hasError,
  isInputActive,
  borderColor
}) =>
  css`
    width: 100%;
    font-size: 14px;
    border-radius: 10px;
    padding: 10px 15px;
    outline: none;
    box-sizing: border-box;
    background-color: #ffffff;
    color: inherit;
    padding: ${({ isInputActive, isLeftIconExists }) =>
      isLeftIconExists && isInputActive
        ? '10px 35px 10px 40px'
        : isLeftIconExists
        ? '10px 15px 10px 40px'
        : isInputActive
        ? '10px 35px 10px 15px'
        : '10px 15px'};
    ::placeholder {
      color: #9aa6c8;
    }
    border: ${`1px solid ${
      hasError
        ? '#FF4C4C'
        : isInputActive
        ? '#387DFF'
        : borderColor || '#dde3f0'
    }`};

    &:disabled {
      background-color: #edeff6;
      color: #9aa6c8;
      opacity: 0.5;
    }

    &:hover {
      &:not(:disabled) {
        border: ${({ hasError, isInputActive, borderHoverColor }) =>
          `1px solid ${
            hasError
              ? '#FF4C4C'
              : isInputActive
              ? '#387DFF'
              : borderHoverColor || '#dde3f0'
          }`};
      }
    }
  `

export const Input = styled.input`
  ${getInputStyles};
`

export const TextArea = styled.textarea`
  ${getInputStyles};
  resize: none;
  height: 150px;
  width: 100%;
`

export const ErrorMessage = styled.div`
  position: absolute;
  display: flex;
  left: 0;
  right: 0;
  bottom: -15px;
  font-size: 10px;
  font-weight: 500;
  color: #ff4c4c;
`

export const ErrorIcon = styled(Icon)`
  margin-right: 5px;
`

export const Count = styled.div`
  position: absolute;
  right: 0;
  color: ${({ hasExceedLimit }) => (hasExceedLimit ? '#ff4c4c' : '#7683a7')};
  padding-top: 5px;
  font-size: 12px;
`

export const IconTextButton = styled.button`
  ${getDisabledProps};
  height: 40px;
  display: flex;
  align-items: center;
  background: ${primary.Light};
  padding: 8px 15px;
  color: ${primary.Dark};
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  outline: none;
  user-select: none;
  cursor: pointer;
  border: none;

  svg {
    fill: ${primary.Dark};
    margin-right: 10px;
  }

  &:hover {
    color: ${white};
    background: transparent
      linear-gradient(180deg, ${primary.SemiDark} 0%, ${primary.Dark} 100%)
      no-repeat padding-box;
    svg {
      fill: ${white};
    }
  }
`
