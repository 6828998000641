/* @flow */
import styled, { css } from 'styled-components'
import THEME from './Post1.theme'
import {
  styleProvider,
  getBackgroundStyles,
  hexToRgb,
  getTextStyleByAlignment
} from '@website/common/utils'
import Container from '@website/common/components/Container'
import WMContainer from '@website/common/components/WMContainer'

const getStyleForProp = styleProvider(THEME)

const StyledContainer = styled(Container)`
  ${getBackgroundStyles(THEME)};
  flex-direction: column;
  ${({ length }) => (length === 1 ? 'padding-bottom: 30px' : '')};
  .link-section {
    align-items: center;
    margin-top: 20px;
  }
  .learn-more-link {
    color: ${({ theme }) => theme.palette.primaryDark};
    svg {
      fill: ${({ theme }) => theme.palette.primaryDark};
    }
  }
  ${({ isEditing }) =>
    isEditing &&
    css`
      @media only screen and (max-width: 1200px) and (min-width: 769px) {
        padding-top: 150px;
      }
    `}
  @media only screen and (max-width: 457px) {
    ${({ length }) => (length === 1 ? 'padding-bottom: 10px' : '')};
  }
`
const WmCustomContainer = styled(WMContainer)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .slide-container {
    margin: 50px;
  }
  .slick-slide > div {
    margin: 50px;
    @media only screen and (max-width: 650px) {
      margin: 50px 25px;
    }
  }
  > :first-child {
    padding-top: 0;
  }
  > .control-container {
    width: 100%;
  }
`

const Title = styled.h1`
  color: ${getStyleForProp('heading')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : '')};
  ${getTextStyleByAlignment};
  width: 100%;
  margin-bottom: 0;
`

const Paragraph = styled.p`
  color: ${getStyleForProp('paragraph')};
  ${getTextStyleByAlignment};
  width: 100%;
  line-height: 1.2;
  padding-top: 20px;
  position: relative;
  z-index: 2;
`

const IconContainer = styled.button`
  border: none;
  padding: 0;
  width: 36px;
  min-width: 36px;
  margin: 10px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${getStyleForProp('buttonBackground')};
  border-radius: ${getStyleForProp('iconRadius')};
  .left {
    transform: translateX(-1px);
  }
  .right {
    transform: translateX(1px);
  }
  &:focus {
    outline: none;
  }
  @media only screen and (max-width: 760px) {
    display: none;
  }
`

const SliderTitle = styled.h2`
  color: ${({ theme }) => theme.palette.tertiaryDark};
  ${getTextStyleByAlignment};
  width: 100%;
  font-size: 20px;
`

const SliderParagraph = styled.p`
  color: ${getStyleForProp('sliderParagraph')};
  font-size: 16px;
  line-height: 1.4;
  ${getTextStyleByAlignment};
  width: 100%;
  padding: 14px 0;
`

const Dot = styled.button`
  padding: 0;
  border: none;
  width: 10px;
  margin: 0 5px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  ${props =>
    props.isActive
      ? getStyleForProp('buttonBackground')(props)
      : `background: rgba(${hexToRgb('#A8B6DE')}, 0.5)`};
  &:focus {
    outline: none;
  }
`

const TextContainer = styled.div`
  width: 50%;
  background: white;
  padding: 30px 30px 30px 40px;
  box-sizing: border-box;
  border-top-right-radius: ${getStyleForProp('cardRadius')};
  border-bottom-right-radius: ${getStyleForProp('cardRadius')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  word-break: break-word;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    align-items: center;
    border-top-right-radius: 0;
    border-bottom-left-radius: ${getStyleForProp('cardRadius')};
  }
  @media only screen and (max-width: 550px) {
    padding: 30px 16px;
  }
  & > .control-container {
    margin-bottom: 30px;
    width: 100%;
    p,
    h2 {
      padding: 0;
    }
  }
`

const SlideContainer = styled.article`
  box-shadow: ${getStyleForProp('raisedShadow')};
  border-radius: ${getStyleForProp('cardRadius')};
  display: flex;
  .lazy-figure {
    border-top-left-radius: ${getStyleForProp('cardRadius')};
    border-bottom-left-radius: ${getStyleForProp('cardRadius')};
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      border-bottom-left-radius: 0;
      border-top-right-radius: ${getStyleForProp('cardRadius')};
    }
    img {
      border-top-left-radius: ${getStyleForProp('cardRadius')};
      border-bottom-left-radius: ${getStyleForProp('cardRadius')};
      @media only screen and (max-width: 1024px) {
        border-bottom-left-radius: 0;
        border-top-right-radius: ${getStyleForProp('cardRadius')};
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    min-height: unset;
  }
`

const ControlContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  .control-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .LazyLoad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: initial;
    overflow: hidden;
  }
  .lazy-figure {
    padding-top: 100%;
    height: initial;
  }
`

const ImageSlideContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  position: relative;
  margin: -50px 0 -20px 0;
  padding-top: 50px;
  @media only screen and (max-width: 992px) {
    padding-top: 40px;
  }
  @media only screen and (max-width: 650px) {
    width: 100%;
  }
`

const ImageFigure = styled.div`
  width: 50%;
  font-size: 0;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
`

export default {
  Dot,
  Title,
  StyledContainer,
  WmCustomContainer,
  Paragraph,
  SliderTitle,
  TextContainer,
  IconContainer,
  SlideContainer,
  SliderParagraph,
  ControlContainer,
  ImageSlideContainer,
  ImageFigure,
  Img
}
