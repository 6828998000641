/* @flow */
import produce from 'immer'
import { generateRandomNumber } from '@website/common/utils'

export default function reducer(
  state,
  action: any
) {
  const initialState = action.__initialData.defaultProps
  return produce(state, draft => {
    switch (action.type) {
      case 'CHANGE_TITLE':
        draft.title = action.value
        return
      case 'CHANGE_PARAGRAPH':
        draft.paragraph = action.value
        return
      case 'CHANGE_NAME':
        draft.team[action.value.idx].name = action.value.newName
        return
      case 'CHANGE_POSITION':
        draft.team[action.value.idx].position = action.value.newPosition
        return
      case 'CHANGE_IMAGE':
        {
          const { memberIdx, url, dimensions } = action.value
          draft.team[memberIdx].imageUrl = url
          draft.team[memberIdx].imgDimensions = dimensions
          draft.team[memberIdx].imgCropParams = {}
        }
        return
      case 'ADD_MEMBER':
        const randomIndex = generateRandomNumber(initialState.team.length)
        draft.team.push(initialState.team[randomIndex])
        return
      case 'REMOVE_IMAGE':
        draft.team.splice(action.value.memberIdx, 1)
        return
      case 'CHANGE_MEMBER_LINK':
        {
          const { memberIdx, link } = action.value
          draft.team[memberIdx].teamRef = link
        }
        return
      case 'SET_IMG_CROP_PARAMS':
        const { imgCropParams, memberIdx } = action.value
        draft.team[memberIdx].imgCropParams = imgCropParams
      case 'CHANGE_TITLE_ALIGNMENT': {
        draft.titleAlignment = action.value
        break
      }
      case 'CHANGE_PARAGRAPH_ALIGNMENT': {
        draft.paragraphAlignment = action.value
        break
      }
      case 'CHANGE_NAME_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.team[idx].nameAlignment = alignment
        break
      }
      case 'CHANGE_POSITION_ALIGNMENT': {
        const { idx, alignment } = action.value
        draft.team[idx].positionAlignment = alignment
        break
      }
    }
  })
}
